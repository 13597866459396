import { createIcon } from "../../icon/Icon";

export const IconStatusClosedGroupRound = createIcon(
  <>
    <path d="M4.92453 18.4434V10.4835L6.33962 11.5448V17.0283H13.6509L15.5377 18.4434H4.92453Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3491 9.24528L8.46226 7.83019H9.16981V5H14.8302V7.83019H19.0755V15.7901L17.6604 14.7288V9.24528H10.3491ZM10.5849 7.83019H13.4151V6.41509H10.5849V7.83019Z"
    />
    <path d="M4.5 8.39623L18.6509 19.0094L19.5 17.8774L5.34906 7.26415L4.5 8.39623Z" />
  </>,
  "IconStatusClosedGroupRound"
);
