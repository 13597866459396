import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { getTotalUsedMinutes } from "../../../lib/entities/jobItemEntity";
import { selectPropsJobItemId } from "../index";
import { selectIsEditingJobItem } from "./ui/isEditing";
import { selectJobItem } from "./index";

export const selectShowLoggedInformation = createCachedSelector(
  [selectJobItem, selectIsEditingJobItem],
  (jobItem, isEditing) => getTotalUsedMinutes(jobItem) > 0
)(selectPropsJobItemId);

export const useShowLoggedInformation = jobItemId =>
  useSelector(state => selectShowLoggedInformation(state, { jobItemId }));
