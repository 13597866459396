import { DateReportByFilter } from "./DateReportByFilter";
import { FilterGroupSelectors } from "./FilterGroupSelectors";
import { SearchTypeSelector } from "./SearchTypeSelector";
import { SelectedTimeSelector } from "./SelectedColumnSelector";
import { subSearchHeaderCss } from "./SubSearchHeader.css";

export function SubSearchHeader() {
  return (
    <div className={subSearchHeaderCss}>
      <SelectedTimeSelector />
      <FilterGroupSelectors />
      <DateReportByFilter />
      <div style={{ flex: 1 }} />
      <SearchTypeSelector />
    </div>
  );
}
