import * as PropTypes from "prop-types";
import {
  JOB_ITEM_SUB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_SUB_ITEM_STATUS_ID_DELETED,
  JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE
} from "../constants";
import { getRelativeTimeText } from "../dates";
import { entityIdType, typeEntityType } from "../types/entityTypes";

export const jobItemSubItemEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  jobItemId: entityIdType.isRequired,
  jobItemSubItemStatus: typeEntityType.isRequired,
  orderId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  estimatedEndDate: PropTypes.string,
  estimatedStartDate: PropTypes.string,
  completedByUserId: entityIdType,
  completedDatetime: PropTypes.string
});

export const getJobItemIdPath = entity => `["${entity.jobItemId}"]`;

export const isIncomplete = ({ jobItemSubItemStatus }) =>
  jobItemSubItemStatus.id === JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE;

export const isComplete = ({ jobItemSubItemStatus }) =>
  jobItemSubItemStatus.id === JOB_ITEM_SUB_ITEM_STATUS_ID_COMPLETE;

export const isDeleted = ({ jobItemSubItemStatus }) =>
  jobItemSubItemStatus.id === JOB_ITEM_SUB_ITEM_STATUS_ID_DELETED;

export const getDescription = ({ description }) => description;

export const getCompletedByUserId = ({ completedByUserId }) =>
  completedByUserId;

export const getRelativeCreatedTime = ({ completedDatetime }) =>
  getRelativeTimeText(completedDatetime);

export const getOrderId = ({ orderId }) => orderId;

export const createNewJobItemSubItem = ({
  id = null,
  jobItemId,
  description,
  orderId = null
}) => ({
  jobItemId,
  description,
  id,
  orderId,
  jobItemSubItemStatus: { id: JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE },
  estimatedEndDate: null,
  estimatedStartDate: null,
  completedByUserId: null,
  completedDatetime: null
});
