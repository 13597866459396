import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataCurrencyId,
  getMetaDataLoggedExpenseName,
  getMetaDataPaymentAmountIncTax,
} from "../../../../../lib/entities/activityEntryEntity";
import { entityIdType } from "../../../../../lib/types/entityTypes";
import JobForeignCurrency from "../../../../modules/Currency/JobForeignCurrency";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobPurchaseOrderPaymentCreated = ({
  activityEntry,
  canEditJobs,
  jobId,
}) => {
  let expenseName;
  try {
    expenseName = getMetaDataLoggedExpenseName(activityEntry);
  } catch (e) {
    // framework exception ignored as possibility the expense name doesn't exist
  }
  return (
    <SystemMessage>
      <JobForeignCurrency
        id={jobId}
        value={getMetaDataPaymentAmountIncTax(activityEntry)}
        currencyId={getMetaDataCurrencyId(activityEntry)}
      />
      {` paid against ${expenseName ? "purchase order " : ""}`}
      <LinkToActivityEntryEntity
        activityEntry={activityEntry}
        disabled={!canEditJobs}
      >
        {expenseName || "purchase order"}
      </LinkToActivityEntryEntity>
    </SystemMessage>
  );
};

JobPurchaseOrderPaymentCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
  jobId: entityIdType.isRequired,
};

export default JobPurchaseOrderPaymentCreated;
