import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_JOB_ITEMS } from "../../../lib/constants";
import { selectPropsJobItemId } from "../index";

export const selectStateJobItems = state =>
  state.entities[ENTITY_NAME_JOB_ITEMS];

export const selectJobItem = createCachedSelector(
  [selectStateJobItems, selectPropsJobItemId],
  (jobItems, jobItemId) => get(jobItems, `byId.${jobItemId}`)
)(selectPropsJobItemId);

export const useJobItem = jobItemId =>
  useSelector(state => selectJobItem(state, { jobItemId }));
