import numeral from "numeral";
import { useSelector } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import {
  getTotalPlannedMoney,
  getTotalUsedMoney,
} from "../../../lib/entities/jobItemEntity";
import { getJobId } from "../../../lib/entities/jobPhaseEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectJobItem } from "../jobItem";
import { selectJobItemOrderByJobIdPhaseId } from "../jobItem/selectJobItemOrderByJobIdPhaseId";
import { selectJobPhase } from "./index";

export const selectJobPhaseTotal = (state, props) => {
  const { jobPhaseId } = props;
  const jobPhase = selectJobPhase(state, props);
  const jobId = getJobId(jobPhase);
  const jobPhaseItemIds = selectJobItemOrderByJobIdPhaseId(state, {
    jobId,
    jobPhaseId,
  });

  if (jobPhaseItemIds.length === 0) return "";

  const totalLoggedTimeExTax = numeral(0);
  const totalPlannedTimeExTax = numeral(0);

  jobPhaseItemIds.forEach((jobItemId) => {
    const jobItem = selectJobItem(state, { jobItemId });
    if (jobItem.isBillable) {
      totalLoggedTimeExTax.add(getTotalUsedMoney(jobItem));
      totalPlannedTimeExTax.add(getTotalPlannedMoney(jobItem));
    }
  });

  const customerCurrency = selectCustomerCurrency(state);
  const jobCurrency = selectJobCurrency(state, { jobId });

  const formattedTotalLoggedTimeExTax = formatForeignCurrency(
    customerCurrency,
    {
      value: totalLoggedTimeExTax.value(),
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    }
  );

  const formattedTotalPlannedTimeExTax = formatForeignCurrency(
    customerCurrency,
    {
      currency: jobCurrency,
      value: totalPlannedTimeExTax.value(),
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    }
  );

  return `${formattedTotalLoggedTimeExTax} / ${formattedTotalPlannedTimeExTax}`;
};

export const useJobPhaseTotal = (jobPhaseId) =>
  useSelector((state) => selectJobPhaseTotal(state, { jobPhaseId }));
