import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import {
  selectPropsCombinerEntityIdLabelTypeId,
  selectPropsEntityId,
  selectPropsLabelTypeId
} from "../index";
import { selectStateLabels } from "./index";

export const selectLabelNamesByEntityIdLabelTypeId = createCachedSelector(
  [selectStateLabels, selectPropsEntityId, selectPropsLabelTypeId],
  (labels, entityId, labelTypeId) =>
    get(
      labels.namesByEntityIdLabelTypeId,
      `["${entityId}.${labelTypeId}"]`,
      EMPTY_ARRAY
    )
)(selectPropsCombinerEntityIdLabelTypeId);

export const useLabelNamesByEntityIdLabelTypeId = (entityId, labelTypeId) =>
  useSelector(state =>
    selectLabelNamesByEntityIdLabelTypeId(state, { entityId, labelTypeId })
  );
