export const DATA_ATTRIBUTE_BLOCK = "block";
export const DATA_ATTRIBUTE_JOB = "job";
export const DATA_ATTRIBUTE_JOB_BAR = "job_bar";
export const DATA_ATTRIBUTE_JOB_ITEM = "job_item";
export const DATA_ATTRIBUTE_JOB_ITEM_BAR = "job_item_bar";
export const DATA_ATTRIBUTE_JOB_ITEM_DAYS = "job_item_days";
export const DATA_ATTRIBUTE_JOB_ITEM_USER = "job_item_user";
export const DATA_ATTRIBUTE_JOB_PHASE_BAR = "job_phase_bar";
export const DATA_ATTRIBUTE_RESIZE_HANDLE = "resize_handle";
export const DATA_ATTRIBUTE_DEPENDANCY_HANDLE = "dependancy_handle";
export const DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE = "schedule_filter_mode";
export const DATA_ATTRIBUTE_USER = "user";
export const DATA_ATTRIBUTE_USER_AVAILABILITY = "user_availability";

export const DATA_VALUE_HANDLE_TOP = "top";
export const DATA_VALUE_HANDLE_TOP_RIGHT = "topRight";
export const DATA_VALUE_HANDLE_RIGHT = "right";
export const DATA_VALUE_HANDLE_LEFT = "left";

export const DEFAULT_COORDS = { x: 0, y: 0 };

export const POINTER_CLICK_OFFSET_LIMIT = 10;
export const POINTER_CLICK_TIME_LIMIT_MS = 400;
