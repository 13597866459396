import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobItemDependancyStore } from "./JobItemDependancy.store";

export const useJobItemDependancyEntity = createUseEntityStoreEntity(
  EntityClass.JobItemDependancy
);

export const getJobItemDependancyEntity = createGetEntityStoreEntity(
  EntityClass.JobItemDependancy
);

export const useJobItemDependancy = createUseSingleEntity(
  JobItemDependancyStore
);

export function useJobItemDependancyActions() {
  return JobItemDependancyStore.useActions();
}
