import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobStore = createSingleEntityStoreContext(EntityClass.Job);

export const JobStoreProvider = createSingleEntityStoreProvider(
  EntityClass.Job,
  JobStore
);
