import { call, put } from "redux-saga/effects";
import { fetchCompanyOptionsApi } from "../../../lib/API/optionsAPI";
import { ENTITIES_RECEIVED } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

export function* watchFetchCompanyOptions() {
  try {
    const data = yield call(fetchCompanyOptionsApi);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  }
}
