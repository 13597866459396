import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobItemId } from "../index";
import { selectStateJobItemSubItems } from "./index";

export const selectJobItemSubItemIdsOrderedByJobItemId = createCachedSelector(
  [selectStateJobItemSubItems, selectPropsJobItemId],
  (jobItemSubItems, jobItemId) =>
    get(
      jobItemSubItems,
      `jobItemSubItemOrderByJobItemId.${jobItemId}`,
      EMPTY_ARRAY
    )
)(selectPropsJobItemId);

export const useJobItemSubItemIdsOrderedByJobItemId = jobItemId =>
  useSelector(state =>
    selectJobItemSubItemIdsOrderedByJobItemId(state, { jobItemId })
  );
