import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const InvoiceStore = createSingleEntityStoreContext(EntityClass.Invoice);

export const InvoiceStoreProvider = createSingleEntityStoreProvider(
  EntityClass.Invoice,
  InvoiceStore
);
