import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_ARRAY, ENTITY_NAME_ADDRESSES } from "../../lib/constants";
import { selectPropsCompanyId, selectPropsId } from "./index";

export const selectAddresses = state => state.entities[ENTITY_NAME_ADDRESSES];

export const selectAddressById = createSelector(
  selectAddresses,
  selectPropsId,
  (addresses, id) => get(addresses, `byId.${id}`)
);

export const selectAddressIdsByCompanyId = createSelector(
  selectAddresses,
  selectPropsCompanyId,
  (addresses, companyId) =>
    get(addresses, `idsByCompanyId.${companyId}`, EMPTY_ARRAY)
);

export const selectActiveAddressOptionsByCompanyId = createSelector(
  selectAddresses,
  selectPropsCompanyId,
  (addresses, companyId) =>
    get(addresses, `activeOptionsByCompanyId.${companyId}`, EMPTY_ARRAY)
);
