import type { ConstEnum } from "@streamtimefe/types";
import { pluralise } from "@streamtimefe/utils";

import type { TEnumOption } from "../../core";
import { getName } from "./JobGroupPeriodInterval.utils";

export const JobGroupPeriodIntervalEnum = {
  Months: 1,
  Quarters: 2,
} as const;

export type TJobGroupPeriodIntervalEnum = ConstEnum<
  typeof JobGroupPeriodIntervalEnum
>;

export type TJobGroupPeriodInterval = TEnumOption<TJobGroupPeriodIntervalEnum>;

export const JobGroupPeriodIntervalItems: {
  id: TJobGroupPeriodIntervalEnum;
  name: string;
}[] = [
  {
    id: JobGroupPeriodIntervalEnum.Months,
    name: pluralise(getName(JobGroupPeriodIntervalEnum.Months), 0),
  },
  {
    id: JobGroupPeriodIntervalEnum.Quarters,
    name: pluralise(getName(JobGroupPeriodIntervalEnum.Quarters), 0),
  },
];

export const JobGroupPeriodIntervalTotalItems: { id: number; name: string }[] =
  [
    { id: 2, name: String(2) },
    { id: 3, name: String(3) },
    { id: 4, name: String(4) },
    { id: 5, name: String(5) },
    { id: 6, name: String(6) },
    { id: 7, name: String(7) },
    { id: 8, name: String(8) },
    { id: 9, name: String(9) },
    { id: 10, name: String(10) },
    { id: 11, name: String(11) },
    { id: 12, name: String(12) },
  ];
