import { filter, first, get } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_QUOTE_LINE_ITEMS
} from "../../lib/constants";
import { selectQuoteByQuoteId } from "./quoteSelectors";
import { selectPropsId, selectPropsQuoteId } from "./index";

export const selectQuoteLineItems = state =>
  state.entities[ENTITY_NAME_QUOTE_LINE_ITEMS];

export const selectQuoteLineItemById = createSelector(
  selectQuoteLineItems,
  selectPropsId,
  (quoteLineItems, id) => get(quoteLineItems, `byId.${id}`)
);

export const selectQuoteLineItemQuoteIdById = createSelector(
  selectQuoteLineItemById,
  quoteLineItem => get(quoteLineItem, `quoteId`)
);

export const selectQuoteLineItemIdsByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `idsByQuoteId.${quoteId}`, EMPTY_ARRAY)
);

export const selectQuoteLineItemsByQuoteId = (state, props) => {
  const quoteLineItemIds = selectQuoteLineItemIdsByQuoteId(state, props);
  return quoteLineItemIds.map(id => selectQuoteLineItemById(state, { id }));
};

export const selectSortedQuoteLineItemIdsByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `sortedIdsByQuoteId.${quoteId}`, EMPTY_ARRAY)
);

export const selectSortedQuoteLineItemsByQuoteId = (state, props) => {
  const quoteLineItemIds = selectSortedQuoteLineItemIdsByQuoteId(state, props);
  return quoteLineItemIds.map(id => selectQuoteLineItemById(state, { id }));
};

export const selectLineItemElementsByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `lineItemElementsByQuoteId.${quoteId}`, EMPTY_ARRAY)
);

export const selectQuoteLineItemElementsById = createSelector(
  selectQuoteLineItems,
  selectPropsId,
  (quoteLineItems, id) =>
    get(quoteLineItems, `lineItemElementsByQuoteId.${id}`, EMPTY_ARRAY)
);

export const selectSubTotalsExTaxByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `subTotalsExTaxByQuoteId.${quoteId}`, EMPTY_OBJECT)
);

export const selectSubTotalsIncTaxByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `subTotalsIncTaxByQuoteId.${quoteId}`, EMPTY_OBJECT)
);

export const selectSubTotalsByQuoteId = createSelector(
  selectSubTotalsExTaxByQuoteId,
  selectSubTotalsIncTaxByQuoteId,
  selectQuoteByQuoteId,
  (subTotalsExTax, subTotalsIncTax, quote) =>
    quote.displayLineItemsInclusiveOfTax ? subTotalsIncTax : subTotalsExTax
);

export const selectSubTotalByQuoteIdQuoteLineItemId = createSelector(
  selectSubTotalsByQuoteId,
  selectPropsId,
  (subTotals, id) => get(subTotals, id)
);

export const selectTotalsByQuoteId = createSelector(
  selectQuoteLineItems,
  selectPropsQuoteId,
  (quoteLineItems, quoteId) =>
    get(quoteLineItems, `totalsByQuoteId.${quoteId}`, 0)
);

export const selectQuoteLineItemsUi = state =>
  state.ui[ENTITY_NAME_QUOTE_LINE_ITEMS];

export const selectQuoteLineItemUi = createSelector(
  selectQuoteLineItemsUi,
  selectPropsId,
  (quoteLineItemsUi, id) => get(quoteLineItemsUi, id, EMPTY_OBJECT)
);

export const selectQuoteLineItemUiIsCreating = createSelector(
  selectQuoteLineItemUi,
  quoteLineItemUi => get(quoteLineItemUi, "isCreating", false)
);

export const selectQuoteLineItemUiIsMerging = createSelector(
  selectQuoteLineItemUi,
  quoteLineItemUi => get(quoteLineItemUi, "isMerging", false)
);

export const selectSortedQuoteLineItemIdsMergingByQuoteId = (state, props) => {
  const quoteLineItemIds = selectSortedQuoteLineItemIdsByQuoteId(state, props);

  return filter(quoteLineItemIds, id =>
    get(selectQuoteLineItemUi(state, { id }), "isMerging", false)
  );
};

export const selectCountIsMergingQuoteLineItemsByQuoteId = createSelector(
  selectSortedQuoteLineItemIdsMergingByQuoteId,
  value => value.length
);

export const selectFirstIsMergingQuoteLineItemIdByQuoteId = createSelector(
  selectSortedQuoteLineItemIdsMergingByQuoteId,
  first
);

export const selectMergeParentQuoteLineItem = (state, props) => {
  const { quoteId } = selectQuoteLineItemById(state, props);
  const quoteLineItemId = selectFirstIsMergingQuoteLineItemIdByQuoteId(state, {
    quoteId
  });

  return (
    quoteLineItemId && selectQuoteLineItemById(state, { id: quoteLineItemId })
  );
};

export const selectQuoteLineItemIsMergeParent = (state, props) => {
  const { id, quoteId } = selectQuoteLineItemById(state, props);
  const firstIsMergingId = selectFirstIsMergingQuoteLineItemIdByQuoteId(state, {
    quoteId
  });

  return Boolean(firstIsMergingId && firstIsMergingId === id);
};

export const selectSortedQuoteLineItemsMergingByQuoteId = (state, props) => {
  const quoteLineItemIds = selectSortedQuoteLineItemIdsMergingByQuoteId(
    state,
    props
  );

  return quoteLineItemIds.map(id => selectQuoteLineItemById(state, { id }));
};
