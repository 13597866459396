import reduceReducers from "reduce-reducers";

import activeUserOptions from "./activeUserOptions";
import jobItemDependanciesResolutionReducer from "./jobItemDependanciesResolutionReducer";
import jobItemDependancyIdsSelected from "./jobItemDependancyIdsSelected";
import jobItemDependancySegmentsReducer from "./jobItemDependancySegmentsReducer";
import jobItemPhaseOrderByJobIdReducer from "./jobItemPhaseOrderByJobIdReducer";
import jobPhaseHasDependanciesReducer from "./jobPhaseHasDependanciesReducer";
import jobTeamSummary from "./jobTeamSummary";
import snapshotJobItemsReducer from "./snapshotJobItemsReducer";
import validateState from "./validateState";

export default (combinedReducers) =>
  reduceReducers(
    combinedReducers,
    validateState,
    activeUserOptions,
    jobItemPhaseOrderByJobIdReducer,
    snapshotJobItemsReducer,
    jobItemDependanciesResolutionReducer,
    jobItemDependancyIdsSelected,
    jobItemDependancySegmentsReducer,
    jobPhaseHasDependanciesReducer,
    jobTeamSummary
  );
