import { ReactNode } from "react";
import { AriaButton } from "st-shared/components";
import { plate } from "st-shared/external";

import { FormulaOperator, insertFormulaOperator } from "../plugins/formula";
import { operatorToolbarButtonCss } from "./OperatorToolbarButton.css";

interface Props {
  children: ReactNode;
  operator: FormulaOperator;
}

export function OperatorToolbarButton({ children, operator }: Props) {
  const editor = plate.useEditorRef();

  function onPress() {
    insertFormulaOperator(editor, operator);
  }

  return (
    <AriaButton
      excludeFromTabOrder={true}
      onPress={onPress}
      className={operatorToolbarButtonCss}
    >
      {children}
    </AriaButton>
  );
}
