import type { ConstEnum } from "@streamtimefe/types";

export const ModalTypes = {
  /** core */
  Confirm: "confirm",
  ConfirmAsync: "confirmAsync",
  KeyboardShortcuts: "keyboardShortcuts",
  ExportCsv: "exportCsv",
  TermsEditor: "termsEditor",
  UpdateExchangeRate: "updateExchangeRate",
  SelectUser: "selectUser",
  /** settings */
  ChangeDefaultBranch: "changeDefaultBranch",
  /** batch actions */
  BatchActionCompanyChangeType: "batchActionCompanyChangeType",
  /** entities */
  // reporting
  ReportingCreate: "reporting/create",
  ReportingDeleteColumn: "reporting/deleteColumn",
  // saved segments
  SavedSegmentTemplates: "savedSegment/templates",
  SavedSegmentEditAccess: "savedSegment/editAccess",
  // master job item
  MasterJobItemCreate: "masterJobItem/create",
  MasterJobItemEdit: "masterJobItem/edit",
  // happiness response
  HappinessResponseSubmit: "happinessResponse/submit",
  HappinessResponseEdit: "happinessResponse/edit",
  // job group
  JobGroupCreate: "jobGroup/create",
  JobGroupClose: "jobGroup/close",
  JobGroupLinkJobs: "jobGroup/linkJobs",
  JobGroupDelete: "jobGroup/delete",
  JobGroupSelect: "jobGroup/select",
  // job
  JobCreate: "job/create",
  JobConfirmComplete: "job/confirmComplete",
  JobDelete: "job/delete",
  JobRestore: "job/restore",
  JobArchive: "job/archive",
  JobUpdateCompletedDatetime: "job/updateCompletedDatetime",
  JobFreePlanLimitReached: "job/freePlanLimitReached",
  // job phase
  JobPhaseDelete: "jobPhase/delete",
  // job item
  JobItemConfirmComplete: "jobItem/confirmComplete",
  JobItemDelete: "jobItem/delete",
  // job item user
  JobItemUserConfirmComplete: "jobItemUser/confirmComplete",
  // rate card
  RateCardSetAsStandard: "rateCard/setAsStandard",
  // google calendar
  GoogleCalendarSettings: "googleCalendar/settings",
  GoogleCalendarInbox: "googleCalendar/inbox",
  GoogleCalendarDisconnect: "googleCalendar/disconnect",
  // priorities
  PriorityBoardCardAddCompany: "priorityBoardCard/addCompany",
  PriorityBoardCardAddContact: "priorityBoardCard/addContact",
  PriorityBoardCardAddInvoice: "priorityBoardCard/addInvoice",
  PriorityBoardCardAddJob: "priorityBoardCard/addJob",
  PriorityBoardCardAddJobItem: "priorityBoardCard/addJobItem",
  PriorityBoardCardAddLoggedExpense: "priorityBoardCard/addLoggedExpense",
  PriorityBoardCardAddQuote: "priorityBoardCard/addQuote",
  PriorityBoardCardAddTeamMember: "priorityBoardCard/addTeamMember",
} as const;

export type TModalTypes = ConstEnum<typeof ModalTypes>;

export function modalTypeExists(type: TModalTypes) {
  return Object.values(ModalTypes).includes(type);
}
