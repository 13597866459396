import Fade from "@material-ui/core/Fade";
import MuiMenuList from "@material-ui/core/MenuList";
import clsx from "clsx";
import type { MouseEventHandler, ReactNode } from "react";

import type { PopoverProps } from "../Popover";
import Popover from "../Popover";
import * as styles from "./PopoverMenu.css";

export interface PopoverMenuProps extends Partial<PopoverProps> {
  HeaderComponent?: ReactNode;
  FooterComponent?: ReactNode;
  onMouseEnter?: MouseEventHandler;
}

function PopoverMenu({
  children,
  classes,
  HeaderComponent = null,
  FooterComponent = null,
  anchorEl = null,
  onMouseEnter,
  ...props
}: PopoverMenuProps) {
  return (
    <Popover
      classes={{ ...classes, paper: clsx(styles.popoverPaper, classes?.paper) }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      elevation={2}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        square: true,
        className: "MuiPaper-root",
        onMouseEnter,
      }}
      TransitionComponent={Fade}
      transitionDuration={300}
      disableScrollLock={true}
      {...props}
    >
      {HeaderComponent}
      <MuiMenuList
        className={clsx("MenuList", styles.menuList)}
        variant="menu"
        disablePadding
      >
        {children}
      </MuiMenuList>
      {FooterComponent}
    </Popover>
  );
}

export default PopoverMenu;
