import MuiExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import StyledIcon from "../../modules/StyledIcon";

export default styled(StyledIcon(MuiExpandMoreIcon))`
  border-radius: 2px;
  transition: all 0.2s linear;
  transform: rotate(${props => props.start}deg);
  &.expanded {
    transform: rotate(${props => props.end}deg);
  }
`;
