import * as PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { ActivityEntryTypes } from "st-shared/entities/ActivityEntryType";
import { sharedEmitter } from "st-shared/lib";
import styled from "styled-components";

import {
  activityEntryEntityType,
  getActivityEntryTypeId,
  getActivityEntryTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import { FrameworkException } from "../../../../../lib/exceptions/FrameworkException";
import { entityIdType } from "../../../../../lib/types/entityTypes";
import { withErrorBoundary } from "../../../../modules/ErrorBoundary";
import Comment from "./Comment";
import InvoiceDeleted from "./InvoiceDeleted";
import JobCompanyChanged from "./JobCompanyChanged";
import JobCompletedDateUpdated from "./JobCompletedDateUpdated";
import JobCreated from "./JobCreated";
import JobInvoiceCreated from "./JobInvoiceCreated";
import JobInvoiceMarkedAsSent from "./JobInvoiceMarkedAsSent";
import JobInvoicePaid from "./JobInvoicePaid";
import JobInvoicePaymentCreated from "./JobInvoicePaymentCreated";
import JobInvoicePaymentDeleted from "./JobInvoicePaymentDeleted.jsx";
import JobInvoiceRevertedFromPaid from "./JobInvoiceRevertedFromPaid";
import JobInvoiceRevertedToDraft from "./JobInvoiceRevertedToDraft";
import JobInvoiceSent from "./JobInvoiceSent";
import JobInvoiceSentToExternalPlatform from "./JobInvoiceSentToExternalPlatform";
import JobInvoiceViewed from "./JobInvoiceViewed";
import JobIsBillableChanged from "./JobIsBillableChanged";
import JobItemCreated from "./JobItemCreated";
import JobItemDeleted from "./JobItemDeleted";
import JobItemEndDateModified from "./JobItemEndDateModified";
import JobItemIsBillableChanged from "./JobItemIsBillableChanged";
import JobItemNameChanged from "./JobItemNameChanged";
import JobItemOverBudget from "./JobItemOverBudget";
import JobItemStartDateModified from "./JobItemStartDateModified";
import JobItemStatusChanged from "./JobItemStatusChanged";
import jobLeadAssigned from "./jobLeadAssigned";
import JobLoggedExpenseApproved from "./JobLoggedExpenseApproved";
import JobLoggedExpenseCreated from "./JobLoggedExpenseCreated";
import JobLoggedExpenseDeclined from "./JobLoggedExpenseDeclined";
import JobLoggedExpensePaid from "./JobLoggedExpensePaid";
import JobLoggedExpenseRevertedToDraft from "./JobLoggedExpenseRevertedToDraft";
import JobLoggedExpenseSentToExternalPlatform from "./JobLoggedExpenseSentToExternalPlatform";
import JobMilestoneCreated from "./JobMilestoneCreated";
import JobMilestoneDeleted from "./JobMilestoneDeleted";
import JobMilestoneMoved from "./JobMilestoneMoved";
import JobNameChanged from "./JobNameChanged";
import JobOverBudget from "./JobOverBudget";
import JobPhaseCreated from "./JobPhaseCreated";
import JobPhaseDeleted from "./JobPhaseDeleted";
import JobPostCreated from "./JobPostCreated";
import JobPurchaseOrderMarkedAsSent from "./JobPurchaseOrderMarkedAsSent";
import JobPurchaseOrderPaymentCreated from "./JobPurchaseOrderPaymentCreated";
import JobPurchaseOrderSent from "./JobPurchaseOrderSent";
import JobQuoteApproved from "./JobQuoteApproved";
import JobQuoteCreated from "./JobQuoteCreated";
import JobQuoteDeclined from "./JobQuoteDeclined";
import JobQuoteDeleted from "./JobQuoteDeleted";
import JobQuoteMarkedAsSent from "./JobQuoteMarkedAsSent";
import JobQuoteRevertedToDraft from "./JobQuoteRevertedToDraft";
import JobQuoteSent from "./JobQuoteSent";
import JobStatusChanged from "./JobStatusChanged";
import LoggedExpenseDeleted from "./LoggedExpenseDeleted";
import UserAssignedToJob from "./UserAssignedToJob";
import UserRemovedFromJob from "./UserRemovedFromJob";
import JobDuplicated from "./JobDuplicated.jsx";

export const JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW =
  "jobActivityEntryScrollIntoView";

const JobActivityEntry = ({ activityEntry, canEditJobs, jobId }) => {
  const ref = useRef();
  const [flashFade, setFlashFade] = useState(false);

  const getComponent = () => {
    const activityEntryTypeId = getActivityEntryTypeId(activityEntry);

    switch (activityEntryTypeId) {
      case ActivityEntryTypes.Comment:
        return Comment;
      case ActivityEntryTypes.Post:
        return JobPostCreated;
      case ActivityEntryTypes.JobCreated:
        return JobCreated;
      case ActivityEntryTypes.JobDuplicated:
        return JobDuplicated;
      case ActivityEntryTypes.UserAssignedToJob:
        return UserAssignedToJob;
      case ActivityEntryTypes.JobItemCreated:
        return JobItemCreated;
      case ActivityEntryTypes.JobPhaseCreated:
        return JobPhaseCreated;
      case ActivityEntryTypes.JobItemStartDateModified:
        return JobItemStartDateModified;
      case ActivityEntryTypes.JobItemEndDateModified:
        return JobItemEndDateModified;
      case ActivityEntryTypes.UserRemovedFromJob:
        return UserRemovedFromJob;
      case ActivityEntryTypes.JobItemStatusChanged:
        return JobItemStatusChanged;
      case ActivityEntryTypes.JobPhaseDeleted:
        return JobPhaseDeleted;
      case ActivityEntryTypes.JobItemNameUpdated:
        return JobItemNameChanged;
      case ActivityEntryTypes.JobNameUpdated:
        return JobNameChanged;
      case ActivityEntryTypes.JobCompanyChanged:
        return JobCompanyChanged;
      case ActivityEntryTypes.JobStatusChanged:
        return JobStatusChanged;
      case ActivityEntryTypes.JobItemOverBudget:
        return JobItemOverBudget;
      case ActivityEntryTypes.JobOverBudget:
        return JobOverBudget;
      case ActivityEntryTypes.JobMilestoneCreated:
        return JobMilestoneCreated;
      case ActivityEntryTypes.JobMilestoneMoved:
        return JobMilestoneMoved;
      case ActivityEntryTypes.JobMilestoneDeleted:
        return JobMilestoneDeleted;
      case ActivityEntryTypes.QuoteCreated:
        return JobQuoteCreated;
      case ActivityEntryTypes.QuoteApproved:
        return JobQuoteApproved;
      case ActivityEntryTypes.QuoteDeclined:
        return JobQuoteDeclined;
      case ActivityEntryTypes.InvoiceCreated:
        return JobInvoiceCreated;
      case ActivityEntryTypes.InvoicePaid:
        return JobInvoicePaid;
      case ActivityEntryTypes.LoggedExpenseCreated:
        return JobLoggedExpenseCreated;
      case ActivityEntryTypes.PurchaseOrderSent:
        return JobPurchaseOrderSent;
      case ActivityEntryTypes.InvoiceSent:
        return JobInvoiceSent;
      case ActivityEntryTypes.QuoteSent:
        return JobQuoteSent;
      case ActivityEntryTypes.JobItemDeleted:
        return JobItemDeleted;
      case ActivityEntryTypes.QuoteDeleted:
        return JobQuoteDeleted;
      case ActivityEntryTypes.InvoiceDeleted:
        return InvoiceDeleted;
      case ActivityEntryTypes.LoggedExpenseDeleted:
        return LoggedExpenseDeleted;
      case ActivityEntryTypes.QuoteRevertedToDraft:
        return JobQuoteRevertedToDraft;
      case ActivityEntryTypes.QuoteMarkedAsSent:
        return JobQuoteMarkedAsSent;
      case ActivityEntryTypes.InvoicePaymentCreated:
        return JobInvoicePaymentCreated;
      case ActivityEntryTypes.InvoicePaymentDeleted:
        return JobInvoicePaymentDeleted;
      case ActivityEntryTypes.InvoiceRevertedToDraft:
        return JobInvoiceRevertedToDraft;
      case ActivityEntryTypes.InvoiceSentToExternalAccountingPlatform:
        return JobInvoiceSentToExternalPlatform;
      case ActivityEntryTypes.PurchaseOrderPaymentCreated:
        return JobPurchaseOrderPaymentCreated;
      case ActivityEntryTypes.InvoiceViewed:
        return JobInvoiceViewed;
      case ActivityEntryTypes.InvoiceMarkedAsSent:
        return JobInvoiceMarkedAsSent;
      case ActivityEntryTypes.PurchaseOrderMarkedAsSent:
        return JobPurchaseOrderMarkedAsSent;
      case ActivityEntryTypes.LoggedExpenseSentToExternalAccountingPlatform:
        return JobLoggedExpenseSentToExternalPlatform;
      case ActivityEntryTypes.LoggedExpenseApproved:
        return JobLoggedExpenseApproved;
      case ActivityEntryTypes.LoggedExpenseDeclined:
        return JobLoggedExpenseDeclined;
      case ActivityEntryTypes.LoggedExpenseRevertedToDraft:
        return JobLoggedExpenseRevertedToDraft;
      case ActivityEntryTypes.LoggedExpensePaid:
        return JobLoggedExpensePaid;
      case ActivityEntryTypes.JobCompletedDateUpdated:
        return JobCompletedDateUpdated;
      case ActivityEntryTypes.JobLeadAssigned:
        return jobLeadAssigned;
      case ActivityEntryTypes.JobItemIsBillableUpdated:
        return JobItemIsBillableChanged;
      case ActivityEntryTypes.JobIsBillableUpdated:
        return JobIsBillableChanged;
      case ActivityEntryTypes.InvoiceRevertedFromPaid:
        return JobInvoiceRevertedFromPaid;
      default:
        throw new FrameworkException(
          `Unknown ActivityEntryType: ${activityEntryTypeId} - ${getActivityEntryTypeName(
            activityEntry
          )}`
        );
    }
  };

  const doScrollIntoView = () => {
    requestAnimationFrame(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          scrollMode: "always",
          behavior: "smooth",
          block: "nearest",
        });
      }
    });

    if (flashFade) {
      setFlashFade(false);
      setTimeout(() => {
        setFlashFade(true);
      }, 200);
    } else {
      setFlashFade(true);
    }
  };

  useEffect(() => {
    function jobActivityEntryScrollIntoView(routeOptions) {
      if (
        routeOptions.jobId === jobId &&
        routeOptions.activityEntryId === activityEntry.id
      ) {
        doScrollIntoView();
      }
    }

    sharedEmitter.on(
      JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW,
      jobActivityEntryScrollIntoView
    );
    return () => {
      sharedEmitter.off(
        JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW,
        jobActivityEntryScrollIntoView
      );
    };
  }, [activityEntry.id, jobId]);

  const Component = getComponent();

  return (
    Component && (
      <Wrapper ref={ref} flashFade={flashFade}>
        <Component
          activityEntry={activityEntry}
          canEditJobs={canEditJobs}
          jobId={jobId}
        />
      </Wrapper>
    )
  );
};

JobActivityEntry.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
  jobId: entityIdType.isRequired,
};

export default withErrorBoundary(JobActivityEntry, null);

const Wrapper = styled.div`
  flex-shrink: 0;
  padding: 0 20px;
  ${(props) =>
    props.flashFade &&
    `
    animation: var(--animation-flash-fade);
  `}
`;
