import { values } from "lodash-es";
import { ReactNode, RefObject, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { createRoot, Root } from "react-dom/client";

import { ChartJS } from "../../lib";
import { positionVarients } from "./ChartTooltip.css";

const chartRenderDiv = document.createElement("div");
const chartRenderRoot = createRoot(chartRenderDiv);

const positionClassesAll = values(positionVarients);

export interface ChartTooltipProps<
  T extends ChartJS.ChartType = ChartJS.ChartType,
> {
  tooltip: ChartJS.TooltipModel<T>;
}

export function renderChartTooltip<
  T extends ChartJS.ChartType = ChartJS.ChartType,
  P extends ChartTooltipProps<T> = ChartTooltipProps<T>,
>(
  context: { chart: ChartJS.Chart; tooltip: ChartJS.TooltipModel<T> },
  tooltipDiv: HTMLDivElement | null,
  ChartTooltipComponent: (props: P) => ReactNode,
  chartTooltipProps: any
) {
  if (!context.chart || !context.chart.canvas || !tooltipDiv) return;

  if (context.tooltip.opacity === 0) {
    tooltipDiv.style.opacity = "0";
    return;
  }

  flushSync(() => {
    chartRenderRoot.render(
      <ChartTooltipComponent tooltip={context.tooltip} {...chartTooltipProps} />
    );
  });
  tooltipDiv.innerHTML = chartRenderDiv.innerHTML;

  tooltipDiv.style.opacity = `${tooltipDiv.innerHTML.length > 0 ? 1 : 0}`;
  tooltipDiv.style.left = `${context.tooltip.caretX}px`;
  tooltipDiv.style.top = `${context.tooltip.caretY}px`;

  tooltipDiv.classList.remove(...positionClassesAll);
  tooltipDiv.classList.add(
    positionVarients[`x-${context.tooltip.xAlign}-y-${context.tooltip.yAlign}`]
  );
}

export function useRenderChartTooltip<
  T extends ChartJS.ChartType = ChartJS.ChartType,
>(
  renderFunc: (tooltipModel: ChartJS.TooltipModel<T>) => ReactNode,
  options?: {
    forceAlignment?: {
      xAlign?: ChartJS.TooltipXAlignment;
      yAlign?: ChartJS.TooltipYAlignment;
    };
  }
): [
  RefObject<HTMLDivElement>,
  (context: { chart: ChartJS.Chart; tooltip: ChartJS.TooltipModel<T> }) => void,
] {
  const tooltipRef = useRef<HTMLDivElement>(null);

  type RenderRoot = { el: HTMLDivElement; root: Root };

  const [renderRoot] = useState<RenderRoot>(() => {
    const el = document.createElement("div");
    const root = createRoot(el);
    return {
      el,
      root,
    };
  });

  function renderChartTooltip(context: {
    chart: ChartJS.Chart;
    tooltip: ChartJS.TooltipModel<T>;
  }) {
    if (!context.chart || !context.chart.canvas || !tooltipRef.current) return;

    if (context.tooltip.opacity === 0) {
      tooltipRef.current.style.opacity = "0";
      return;
    }

    flushSync(() => {
      renderRoot.root.render(renderFunc(context.tooltip));
    });

    tooltipRef.current.innerHTML = renderRoot.el.innerHTML;

    tooltipRef.current.style.opacity = `${tooltipRef.current.innerHTML.length > 0 ? 1 : 0}`;
    tooltipRef.current.style.left = `${context.tooltip.caretX}px`;
    tooltipRef.current.style.top = `${context.tooltip.caretY}px`;

    tooltipRef.current.classList.remove(...positionClassesAll);
    tooltipRef.current.classList.add(
      positionVarients[
        `x-${options?.forceAlignment?.xAlign || context.tooltip.xAlign}-y-${options?.forceAlignment?.yAlign || context.tooltip.yAlign}`
      ]
    );
  }

  return [tooltipRef, renderChartTooltip];
}
