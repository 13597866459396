import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_USER,
  DATA_ATTRIBUTE_USER,
  SCHEDULE_BLOCK_PAINT,
  SCHEDULE_BLOCK_PAINT_CANCEL,
  SCHEDULE_BLOCK_PAINT_END,
  SCHEDULE_BLOCK_PAINT_START_SAGA,
} from "../../../lib/constants";
import {
  getDataIntValue,
  getPointerClientOffset,
  subtractCoords,
} from "../../../lib/dom";
import {
  getTargetBlockElement,
  getTargetBlocksContainerElement,
} from "../../../lib/eventTargets";
import { entityIdListType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  getHourHeight,
  getHoursFromHeight,
} from "../../../state/ui/schedule/lib";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";

const mapState = (state) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
});

const mapDispatch = (dispatch) => ({
  doStartPaintBlock: (paintItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_PAINT_START_SAGA, paintItem)),

  doPaintBlock: (paintItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_PAINT, paintItem)),

  doEndPaintBlock: (paintItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_PAINT_END, paintItem)),

  doCancelPaintBlock: (paintItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_PAINT_CANCEL, paintItem)),
});

const getTargetContainerData = (e) => {
  const element = getTargetBlocksContainerElement(e);
  return {
    containerNode: element,
    userId: getDataIntValue(element, DATA_ATTRIBUTE_USER),
    jobId: getDataIntValue(element, DATA_ATTRIBUTE_JOB),
    jobItemId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM),
    jobItemUserId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM_USER),
  };
};

export default (WrappedComponent) => {
  class BlockPaintHandlers extends React.PureComponent {
    paintItem = null;

    isPainting = false;

    static propTypes = {
      zoomLevel: PropTypes.number.isRequired,
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      selectedBlockKeys: entityIdListType.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      doStartPaintBlock: PropTypes.func.isRequired,
      doPaintBlock: PropTypes.func.isRequired,
      doEndPaintBlock: PropTypes.func.isRequired,
      doCancelPaintBlock: PropTypes.func.isRequired,
      doUnselectAllBlocks: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      getDayWidth: PropTypes.func.isRequired,
      getDaysFromWidth: PropTypes.func.isRequired,
      getScrollOffsetX: PropTypes.func.isRequired,
      getDateOnPosition: PropTypes.func.isRequired,
      className: PropTypes.string,
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: "",
    };

    getPointerDistance(e) {
      const { origin } = this.paintItem;
      const pointerPosition = getPointerClientOffset(e);
      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getTargetSize(e) {
      const { zoomLevel, getDaysFromWidth, getScrollOffsetX } = this.props;
      const { origin } = this.paintItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const blockSize = {
        w: origin.blockSize.w + pointerDistance.x + scrollDistanceX,
        h: origin.blockSize.h - pointerDistance.y,
      };

      const hours = getHoursFromHeight(blockSize.h, zoomLevel);
      const days = getDaysFromWidth(blockSize.w);

      return { blockSize, hours, days };
    }

    onPointerDown = (e) => {
      const {
        zoomLevel,
        getDayWidth,
        getScrollOffsetX,
        getDateOnPosition,
        canEditTeamLoggedTimes,
        isSplitting,
        selectedBlockKeys,
        onPointerDown,
        doStartPaintBlock,
      } = this.props;

      const { containerNode, userId, jobId, jobItemId, jobItemUserId } =
        getTargetContainerData(e);
      const hasSelected = selectedBlockKeys.length > 0;

      if (
        canEditTeamLoggedTimes &&
        !isSplitting &&
        containerNode &&
        !getTargetBlockElement(e) &&
        !hasSelected &&
        !(userId < 0) &&
        !(jobId < 0) &&
        !(jobItemId < 0) &&
        !(jobItemUserId < 0)
      ) {
        const pointerPosition = getPointerClientOffset(e);
        const blockKey = Entity.temporaryId();
        const date = getDateOnPosition(pointerPosition);
        const blockSize = {
          w: getDayWidth(),
          h: getHourHeight(zoomLevel),
        };
        const hours = 1;
        const days = 1;
        const scrollOffsetX = getScrollOffsetX();

        this.paintItem = {
          blockKey,
          userId,
          jobId,
          jobItemId,
          jobItemUserId,
          blockSize,
          date,
          hours,
          days,
          origin: {
            scrollOffsetX,
            pointerPosition,
            blockSize,
            hours,
            days,
          },
        };

        doStartPaintBlock(this.paintItem);

        this.isPainting = true;
        this.forceUpdate();
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = (e) => {
      const { doPaintBlock, onPointerMove } = this.props;

      if (this.isPainting) {
        const { blockSize, hours, days } = this.getTargetSize(e);
        const { paintItem: prevPaintItem } = this;

        this.paintItem = {
          ...prevPaintItem,
          blockSize,
          hours,
          days,
        };

        if (prevPaintItem.hours !== hours || prevPaintItem.days !== days)
          doPaintBlock(this.paintItem);
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = (e) => {
      const { onPointerUp, doEndPaintBlock } = this.props;

      if (this.isPainting) {
        doEndPaintBlock(this.paintItem);

        this.paintItem = null;
        this.isPainting = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = (e) => {
      const { onLostPointerCapture, doEndPaintBlock } = this.props;

      if (this.isPainting) {
        doEndPaintBlock(this.paintItem);

        this.paintItem = null;
        this.isPainting = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isPainting) classes.push("painting");

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp,
        onLostPointerCapture: this.onLostPointerCapture,
      };
    }

    render() {
      const { props, className, handlers } = this;
      const { isPainting } = this;

      return (
        <WrappedComponent
          {...{ ...props, className, isPainting, ...handlers }}
        />
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockPaintHandlers);
};
