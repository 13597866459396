import { LabelTypeEnum } from "@streamtimefe/entities";
import { create } from "zustand";

import { getAllOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";
import {
  companyOptionsStore,
  contactOptionsStore,
  itemOptionsStore,
  jobOptionsStore,
  jobPhaseOptionsStore,
  labelOptionsStore,
} from ".";

interface AllOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  loadAll: (force?: boolean) => void;
}

export const useAllOptionsStore = create<AllOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      loadAll: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const { data } = await getAllOptions();

          itemOptionsStore().setOptions(data.itemOptions);
          jobOptionsStore().setOptions(data.jobOptions);
          jobPhaseOptionsStore().setOptions(data.jobPhaseOptions);
          companyOptionsStore().setOptions(data.companyOptions);
          contactOptionsStore().setOptions(data.contactOptions);
          labelOptionsStore().setOptions(LabelTypeEnum.Job, data.jobLabels);
          labelOptionsStore().setOptions(LabelTypeEnum.Quote, data.quoteLabels);
          labelOptionsStore().setOptions(
            LabelTypeEnum.Invoice,
            data.invoiceLabels
          );
          labelOptionsStore().setOptions(
            LabelTypeEnum.LoggedExpense,
            data.loggedExpenseLabels
          );
          labelOptionsStore().setOptions(
            LabelTypeEnum.Company,
            data.companyLabels
          );
          labelOptionsStore().setOptions(
            LabelTypeEnum.Contact,
            data.contactLabels
          );
          labelOptionsStore().setOptions(LabelTypeEnum.User, data.userLabels);
        } catch (e: unknown) {
          console.error("loading all options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
    };
  }
);

export function allOptionsStoreInit() {
  addGlobalStore(["options", "allOptions"], allOptionsStore);
}

export function allOptionsStore() {
  return useAllOptionsStore.getState();
}

export function useAllOptionsLoaded() {
  return useAllOptionsStore((s) => s.loaded);
}

export function useAllOptionsLoading() {
  return useAllOptionsStore((s) => s.loading);
}
