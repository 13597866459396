import { useSelector } from "react-redux";
import {
  QUOTE_STATUS_ID_APPROVED,
  QUOTE_STATUS_ID_DECLINED,
  QUOTE_STATUS_ID_DRAFT,
  QUOTE_STATUS_ID_PENDING
} from "../../../lib/constants";
import {
  getJobCurrencyTotalAmountExTax,
  getQuoteStatusId
} from "../../../lib/entities/quoteEntity";
import { preciseAdd } from "../../../lib/math";
import { selectQuoteIdsByJobId } from "./quoteIdsByJobId";
import { selectQuote } from "./index";

export const selectQuoteStats = (state, { jobId }) => {
  const quoteIds = selectQuoteIdsByJobId(state, { jobId });

  const counts = {
    total: 0,
    issued: 0,
    draft: 0,
    pending: 0,
    approved: 0,
    declined: 0
  };

  const totals = {
    total: 0,
    issued: 0,
    draft: 0,
    pending: 0,
    approved: 0,
    declined: 0
  };

  quoteIds.forEach(quoteId => {
    const quote = selectQuote(state, { quoteId });
    counts.total += 1;
    totals.total = preciseAdd(
      totals.total,
      getJobCurrencyTotalAmountExTax(quote)
    );

    switch (getQuoteStatusId(quote)) {
      case QUOTE_STATUS_ID_DRAFT:
        counts.draft += 1;
        totals.draft = preciseAdd(
          totals.draft,
          getJobCurrencyTotalAmountExTax(quote)
        );
        break;
      case QUOTE_STATUS_ID_PENDING:
        counts.pending += 1;
        counts.issued += 1;
        totals.pending = preciseAdd(
          totals.pending,
          getJobCurrencyTotalAmountExTax(quote)
        );
        totals.issued = preciseAdd(
          totals.issued,
          getJobCurrencyTotalAmountExTax(quote)
        );
        break;
      case QUOTE_STATUS_ID_APPROVED:
        counts.approved += 1;
        counts.issued += 1;
        totals.approved = preciseAdd(
          totals.approved,
          getJobCurrencyTotalAmountExTax(quote)
        );
        totals.issued = preciseAdd(
          totals.issued,
          getJobCurrencyTotalAmountExTax(quote)
        );
        break;
      case QUOTE_STATUS_ID_DECLINED:
        counts.declined += 1;
        totals.declined = preciseAdd(
          totals.declined,
          getJobCurrencyTotalAmountExTax(quote)
        );
        break;
    }
  });

  return [counts, totals];
};

export const useQuoteStats = jobId =>
  useSelector(state => selectQuoteStats(state, { jobId }));
