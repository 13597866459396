import { ErrorBoundary } from "st-shared/module";

import { outerChartCss } from "../Chart.css";
import { EmptyColumnChart } from "../EmptyColumnChart.tsx";
import { ColumnSeriesChart } from "./ColumnSeriesChart";

export function ColumnSeriesChartContainer() {
  return (
    <ErrorBoundary
      fallback={
        <div className={outerChartCss}>
          <EmptyColumnChart />
        </div>
      }
    >
      <ColumnSeriesChart />
    </ErrorBoundary>
  );
}
