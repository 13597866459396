import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DATE_FORMAT_SHORT_DATE_FULL_MONTH } from "../../lib/constants";
import { formatDate, getStartOfWeek } from "../../lib/dates";
import Button from "../elements/Button";
import Flex from "../elements/Flex";
import ArrowBackIcon from "../elements/Icons/ArrowBackIcon";
import ArrowForwardIcon from "../elements/Icons/ArrowForwardIcon";

export const JUSTIFY_STICKY = {
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};

const isLeft = justify => justify === JUSTIFY_STICKY.LEFT;
const isRight = justify => justify === JUSTIFY_STICKY.RIGHT;

const getFormattedDate = date =>
  formatDate(date, DATE_FORMAT_SHORT_DATE_FULL_MONTH);

const getStickyDateText = (startDate, dueDate, justify) => {
  if (isLeft(justify) && dueDate) return `due ${getFormattedDate(dueDate)}`;
  if (startDate) return `starts ${getFormattedDate(startDate)}`;
  return `due ${getFormattedDate(dueDate)}`;
};

const getClickHandler = (
  startDate,
  dueDate,
  justify,
  goToDate,
  goToRHSDate
) => e => {
  e.preventDefault();
  e.stopPropagation();

  if (isLeft(justify) && dueDate) return goToRHSDate(dueDate);
  if (startDate) return goToDate(startDate);
  if (dueDate) return goToRHSDate(dueDate);
  return goToDate(getStartOfWeek());
};

const DateSticky = ({ justify, goToDate, goToRHSDate, startDate, dueDate }) =>
  (startDate || dueDate) && (
    <Sticky justify={justify} className="DateSticky">
      <Button
        disableRipple
        onClick={getClickHandler(
          startDate,
          dueDate,
          justify,
          goToDate,
          goToRHSDate
        )}
      >
        {isLeft(justify) && <ArrowBackIcon />}
        <StickyContent>
          {getStickyDateText(startDate, dueDate, justify)}
        </StickyContent>
        {isRight(justify) && <ArrowForwardIcon />}
      </Button>
    </Sticky>
  );

DateSticky.propTypes = {
  justify: PropTypes.oneOf(values(JUSTIFY_STICKY)).isRequired,
  goToDate: PropTypes.func.isRequired,
  goToRHSDate: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  dueDate: PropTypes.string
};

DateSticky.defaultProps = {
  startDate: null,
  dueDate: null
};

export default DateSticky;

const Sticky = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  ${({ justify }) => isLeft(justify) && "justify-content: flex-start"};
  ${({ justify }) => isRight(justify) && "justify-content: flex-end"};
  button {
    font-weight: 400;
    z-index: 10;
    .MuiButton-label {
      background-color: var(--color-ygrittesnow);
      border-radius: 10px;
      padding: 0 5px;
    }
    &:hover {
      background-color: transparent;
      .MuiButton-label {
        text-decoration: underline;
      }
    }
  }
`;

const StickyContent = styled.span`
  font-size: 12px;
  color: var(--color-charcoal);
  white-space: nowrap;
  padding: 2px 3px 0;
`;
