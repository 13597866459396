// deprecated

import type { Rate } from "./Rate";

export * from "./Rate";

export type Type = ItemRate;
export interface ItemRate extends Rate {
  masterJobItemId: number | null;
  masterLoggedExpenseId: number | null;
}

export function getMasterJobItemKeyPath(entity: ItemRate) {
  return entity.masterJobItemId
    ? `${entity.masterJobItemId}.${entity.rateCardId}`
    : "";
}

export function getMasterLoggedExpenseKeyPath(entity: ItemRate) {
  return entity.masterLoggedExpenseId
    ? `${entity.masterLoggedExpenseId}.${entity.rateCardId}`
    : "";
}
