import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  PrimaryUserDisplayName,
  PrimaryUserRoleName,
  UserIcon,
} from "st-shared/module";
import styled from "styled-components";

import { userEntityType } from "../../../lib/entities/userEntity";
import { cssStyleType } from "../../../lib/types/domTypes";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectUserById } from "../../../redux/selectors/userSelectors";
import Flex from "../../elements/Flex";

const mapState = (state, props) => ({
  user: selectUserById(state, props),
});

const mapDispatch = null;

class UserIconName extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    user: userEntityType.isRequired,
    size: PropTypes.number,
    fontSize: PropTypes.number,
    color: PropTypes.string,
    gap: PropTypes.number,
    showDisplayName: PropTypes.bool,
    showRole: PropTypes.bool,
    nameWidth: cssStyleType,
    isSelected: PropTypes.bool,
  };

  static defaultProps = {
    size: 30,
    fontSize: 18,
    isSelected: false,
    color: "black",
    gap: 10,
    showDisplayName: true,
    showRole: false,
    nameWidth: "calc(100% - 40px)",
  };

  render() {
    const {
      id,
      size,
      fontSize,
      color,
      gap,
      showDisplayName,
      user,
      showRole,
      nameWidth,
      isSelected,
      ...props
    } = this.props;
    return (
      <UserWrapper size={size} {...props}>
        <UserIcon
          id={id}
          name={user.displayName}
          size={size}
          disableTooltip={showDisplayName}
        />
        <Flex
          direction="column"
          justify="center"
          style={{ marginLeft: gap, paddingTop: 2, width: nameWidth }}
        >
          {showDisplayName && (
            <PrimaryUserDisplayName
              userId={id}
              fontSize={fontSize}
              isSelected={isSelected}
              color={color}
            />
          )}
          {showRole && (
            <PrimaryUserRoleName
              userId={id}
              fontSize={fontSize - 2}
              isSelected={isSelected}
              color={color}
            />
          )}
        </Flex>
      </UserWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(UserIconName);

const UserWrapper = styled(Flex)`
  width: 100%;
  height: ${(props) => props.size}px;
  align-items: center;
`;
