// deprecated

import type { Option } from "./Option";

export const ContactStatuses = {
  Active: 1,
  Archived: 2,
  Deleted: 3,
} as const;

export type TContactStatuses =
  (typeof ContactStatuses)[keyof typeof ContactStatuses];

export interface ContactStatus extends Option {
  id: TContactStatuses;
}

export function contactStatusObj(entity: ContactStatus) {
  return {
    entity,
    get isActive() {
      return entity.id === ContactStatuses.Active;
    },
    get isArchived() {
      return entity.id === ContactStatuses.Archived;
    },
    get isDeleted() {
      return entity.id === ContactStatuses.Deleted;
    },
  };
}
