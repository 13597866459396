import type {
  TEntityClass,
  TEntityClassList,
  TModelSet,
} from "@streamtimefe/entities";
import { EntityClass } from "@streamtimefe/entities";
import { isDefined, isObjectType } from "remeda";

export function isModelSet(data: unknown): data is TModelSet {
  if (
    isObjectType(data) &&
    ("__modelSet" in data ||
      "__primaryModelClassName" in data ||
      "__removedEntityIds" in data)
  )
    return true;
  else return false;
}

export function modelSetParser<T extends TEntityClass | undefined = undefined>(
  data: TModelSet<T>
): TEntityClassList {
  const entities: TEntityClassList = {};

  if (isDefined(data.__primaryModelClassName) || isDefined(data.__modelSet)) {
    const modelSet = data.__modelSet || [];

    modelSet.forEach(({ className, models }) => {
      if (className in EntityClass) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        entities![className] = models as any;
      }
    });

    if (
      isDefined(data.__primaryModelClassName) &&
      isDefined(data.__primaryModel)
    ) {
      const primaryModel = data.__primaryModel!;
      const models = (entities[data.__primaryModelClassName!] ??
        []) as (typeof primaryModel)[];

      if (
        primaryModel &&
        !models.find((model) => model.id === primaryModel.id)
      ) {
        models.push(primaryModel);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entities[data.__primaryModelClassName!] = models as any;
    }
  }
  return entities;
}
