import { createContext, useContext } from "react";

export type TMenuContext = {
  size: "small" | "medium";
  variant: "light" | "dark";
};

export const MenuContext = createContext<TMenuContext>({
  size: "medium",
  variant: "light",
});

export function useMenuContext() {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error(`Missing MenuContext`);
  }
  return context;
}
