import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectIsScheduleSearchResultsEmpty } from "../../../redux/selectors/scheduleSelectors";
import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";
import { selectJobListModelsFilteredIdsByUserId } from "../../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIdsByUserId";
import { selectScheduleUiIsSearching } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import EmptySearchResultsGroupMessage from "./EmptySearchResultsGroupMessage";
import NoJobsForUserGroupMessage from "./NoJobsForUserGroupMessage";
import ScheduleUserJobGroup, {
  UserJobGroupSearchingPulser,
} from "./ScheduleUserJobGroup";

const mapState = (state, props) => ({
  jobIds: selectJobListModelsFilteredIdsByUserId(state, { userId: props.id }),
  isCollapsed: selectIsScheduleUserCollapsed(state, props),
  isSearching: selectScheduleUiIsSearching(state),
  isEmpty: selectIsScheduleSearchResultsEmpty(state),
});

const mapDispatch = null;

const ScheduleUserJobsGroup = ({
  id,
  jobIds,
  isCollapsed,
  isSearching,
  isEmpty,
}) => {
  if (isCollapsed) return null;

  if (isSearching) return <UserJobGroupSearchingPulser />;

  if (isEmpty) return <EmptySearchResultsGroupMessage userId={id} />;

  if (!jobIds.length) return <NoJobsForUserGroupMessage userId={id} />;

  return jobIds.map((jobId) => (
    <ScheduleUserJobGroup key={jobId} id={jobId} userId={id} />
  ));
};

ScheduleUserJobsGroup.propTypes = {
  id: entityIdType.isRequired,
  jobIds: entityIdListType.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(ScheduleUserJobsGroup);
