import { EntityAction } from "../actions";

export const JOB_PHASE_ASSIGN_ROLE_TIME = "jobPhase/assignRoleTime";
export const JOB_PHASE_ADD_USERS_AND_ROLES = "jobPhase/addUsersAndRoles";

export type JobPhasesAction =
  | EntityAction
  | JobPhaseAssignRoleTimeAction
  | JobPhaseAddUsersandRolesAction;

export type JobPhaseAssignRoleTimeAction = {
  type: typeof JOB_PHASE_ASSIGN_ROLE_TIME;
  jobPhaseId: number;
  roleId: number;
  userId: number;
};

export type JobPhaseAddUsersandRolesAction = {
  type: typeof JOB_PHASE_ADD_USERS_AND_ROLES;
  jobPhaseId: number;
  userIds: number[];
  roleIds: number[];
};

export function actionJobPhaseAssignRoleTime(
  jobPhaseId: number,
  roleId: number,
  userId: number
): JobPhaseAssignRoleTimeAction {
  return { type: JOB_PHASE_ASSIGN_ROLE_TIME, jobPhaseId, roleId, userId };
}

export function actionJobPhaseAddUsersAndRoles(
  jobPhaseId: number,
  userIds: number[],
  roleIds: number[]
): JobPhaseAddUsersandRolesAction {
  return { type: JOB_PHASE_ADD_USERS_AND_ROLES, jobPhaseId, userIds, roleIds };
}
