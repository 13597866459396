import * as PropTypes from "prop-types";
import styled from "styled-components";

import OpenInNewIcon from "../../elements/Icons/OpenInNewIcon";
import { ICON_SIZE } from "../StyledIcon";
import ListItemIcon from "./ListItemIcon";
import ListItemText from "./ListItemText";
import MenuItem from "./MenuItem";

const MenuItemLink = ({ href, text }) => (
  <StyledMenuItem href={href}>
    <ListItemIcon>
      <OpenInNewIcon size={ICON_SIZE.X_LARGE} style={{ padding: 2 }} />
    </ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </StyledMenuItem>
);

MenuItemLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MenuItemLink;

const StyledMenuItem = styled(MenuItem).attrs({
  component: "a",
  target: "_top",
})`
  background-color: var(--color-ygrittesnow);
  //min-height: 56px;
  &:hover {
    background-color: var(--color-ygrittesnow);
  }
`;
