import { all } from "redux-saga/effects";
import watchFetch from "./watchFetch";
import watchOpenCreateAddress from "./watchOpenCreateAddress";
import watchOpenCreateEditContact from "./watchOpenCreateEditContact";
import watchQuoteSave from "./watchQuoteSave";
import watchQuoteSetAddress from "./watchQuoteSetAddress";
import watchQuoteSetContact from "./watchQuoteSetContact";

export default function* quoteSaga() {
  yield all([
    watchFetch(),
    watchQuoteSave(),
    watchQuoteSetAddress(),
    watchQuoteSetContact(),
    watchOpenCreateEditContact(),
    watchOpenCreateAddress()
  ]);
}
