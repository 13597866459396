import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateJobItems } from "./index";

export const selectJobItemIdsByJobId = createCachedSelector(
  [selectStateJobItems, selectPropsJobId],
  (jobItems, jobId) => get(jobItems, `idsByJobId.${jobId}`, EMPTY_ARRAY)
)(selectPropsJobId);

export const useJobItemIdsByJobId = jobId =>
  useSelector(state => selectJobItemIdsByJobId(state, { jobId }));
