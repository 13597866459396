import { Entity } from "@streamtimefe/entities";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { costingMethodObj } from "st-shared/entities";
import { itemPricingMethodObj } from "st-shared/entities/ItemPricingMethod";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { applyExchangeRate, convertNewLinesToHtml } from "st-shared/lib";

import {
  createJobItemQuoteLineItemAPI,
  createLoggedExpenseQuoteLineItemAPI,
} from "../../../lib/API/quoteLineItemAPI";
import {
  ENTITIES_RECEIVED,
  QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN,
  QUOTE_LINE_ITEM_ADD_ITEM,
  QUOTE_LINE_ITEM_ADD_LINE,
  QUOTE_LINE_ITEM_CREATE,
} from "../../../lib/constants";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../../lib/ui/accountingPlatform";
import {
  commercialDocumentLineItemDefaults,
  getDefaultName,
} from "../../../lib/ui/commercialDocumentLineItems";
import { sendSetHeadingsVisible } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import { selectMasterJobItemRate } from "../../../state/entities/itemRate/selectors/selectMasterJobItemRate";
import { selectMasterLoggedExpenseRate } from "../../../state/entities/itemRate/selectors/selectMasterLoggedExpenseRate";
import { selectMasterJobItem } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { selectMasterLoggedExpense } from "../../../state/entities/masterLoggedExpense/selectors/selectMasterLoggedExpense";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLineItemDefaultAccountCodeAndTaxRate } from "../../selectors/commercialDocumentSelectors";
import {
  selectQuoteExchangeRateByQuoteId,
  selectQuoteJobBranchIdById,
  selectQuoteJobRateCardIdByQuoteId,
} from "../../selectors/quoteSelectors";

function* addLine(action) {
  try {
    const { quoteId, orderId, isHeading } = action.payload;

    const id = Entity.temporaryId();

    const quoteLineItem = {
      ...commercialDocumentLineItemDefaults,
      id,
      quoteId,
      isHeading,
      orderId,
      name: getDefaultName(isHeading),
    };

    if (!isHeading) {
      const branchId = yield select(selectQuoteJobBranchIdById, {
        id: quoteId,
      });

      const { taxName, taxRate, externalTaxRateId } = yield select(
        selectLineItemDefaultAccountCodeAndTaxRate,
        {
          branchId,
          accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE,
        }
      );

      Object.assign(quoteLineItem, { taxName, taxRate, externalTaxRateId });
    } else {
      yield call(sendSetHeadingsVisible);
    }

    yield put(createAction(QUOTE_LINE_ITEM_CREATE, { id, quoteLineItem }));
  } catch (error) {
    sagaError(error);
  }
}

function* addItem(action) {
  try {
    const { quoteId, orderId, masterJobItemId, masterLoggedExpenseId } =
      action.payload;

    let masterJobItem = null;
    let masterLoggedExpense = null;

    if (masterJobItemId) {
      masterJobItem = yield select(selectMasterJobItem, {
        id: masterJobItemId,
      });
    } else {
      masterLoggedExpense = yield select(selectMasterLoggedExpense, {
        id: masterLoggedExpenseId,
      });
    }

    const rateCardId = yield select(selectQuoteJobRateCardIdByQuoteId, {
      quoteId,
    });

    const exchangeRate = yield select(selectQuoteExchangeRateByQuoteId, {
      quoteId,
    });

    const jobCurrencySellRate = masterJobItemId
      ? yield select(selectMasterJobItemRate, {
          masterJobItemId,
          rateCardId,
        })
      : yield select(selectMasterLoggedExpenseRate, {
          masterLoggedExpenseId,
          rateCardId,
        });

    const quoteCurrencySellRate = applyExchangeRate(
      jobCurrencySellRate,
      exchangeRate
    );

    let quantity = null;
    let unitRate = null;
    const totalAmountExTax =
      masterLoggedExpense &&
      itemPricingMethodObj(masterLoggedExpense.itemPricingMethod).isMarkup()
        ? ""
        : quoteCurrencySellRate;

    if (
      masterJobItem
        ? costingMethodObj(masterJobItem.costingMethod).isItem()
        : itemPricingMethodObj(
            masterLoggedExpense.itemPricingMethod
          ).isUnitPrice()
    ) {
      quantity = 1;
      unitRate = quoteCurrencySellRate;
    }

    const description = `<p>${convertNewLinesToHtml(
      masterJobItem
        ? masterJobItem.description
        : masterLoggedExpense.description
    )}</p>`;
    const name = masterJobItem ? masterJobItem.name : masterLoggedExpense.name;

    const branchId = yield select(selectQuoteJobBranchIdById, {
      id: quoteId,
    });

    const { taxName, taxRate, externalTaxRateId } = yield select(
      selectLineItemDefaultAccountCodeAndTaxRate,
      {
        branchId,
        masterItem: masterJobItem || masterLoggedExpense,
        accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE,
      }
    );

    const id = Entity.temporaryId();

    const quoteLineItem = {
      ...commercialDocumentLineItemDefaults,
      id,
      quoteId,
      orderId,
      name,
      description,
      quantity,
      unitRate,
      totalAmountExTax,
      taxName,
      taxRate,
      externalTaxRateId,
    };

    yield put(createAction(QUOTE_LINE_ITEM_CREATE, { id, quoteLineItem }));
  } catch (error) {
    sagaError(error);
  }
}

function* addFromJobPlan(action) {
  try {
    const { quoteId, entityId, orderId, optionType } = action.payload;

    feToWebShowPageLoadingMask(10);

    const createAPI =
      optionType === LineItemOptionTypes.JobItem
        ? createJobItemQuoteLineItemAPI
        : createLoggedExpenseQuoteLineItemAPI;

    const enitityChangeSet = yield call(createAPI, quoteId, entityId, orderId);

    yield put(createAction(ENTITIES_RECEIVED, enitityChangeSet));
  } catch (error) {
    sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchQuoteLineItemAdd() {
  yield takeLatest(QUOTE_LINE_ITEM_ADD_LINE, addLine);
  yield takeLatest(QUOTE_LINE_ITEM_ADD_ITEM, addItem);
  yield takeLatest(QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN, addFromJobPlan);
}
