import { ActivityEntryParentTypes } from "st-shared/entities/ActivityEntryParentType";

import {
  ENTITY_NAME_ACTIVITY_ENTRIES,
  ENTITY_NAME_REPEATING_LOGGED_TIMES,
} from "../constants";
import API from "./index";

export const fetchJobActivityAPI = (jobId) =>
  API.fetch(`/activity_entries`, {
    parent_entity_id: jobId,
    activity_entry_parent_type_id: ActivityEntryParentTypes.Job,
  }).then(({ data }) => ({
    [ENTITY_NAME_ACTIVITY_ENTRIES]: data,
  }));

export const fetchActivityEntryThreadAPI = (id) =>
  API.fetch(`/activity_entries`, {
    parent_entity_id: id,
    activity_entry_parent_type_id: ActivityEntryParentTypes.ActivityEntryThread,
  }).then(({ data }) => ({
    [ENTITY_NAME_ACTIVITY_ENTRIES]: data,
  }));

export const fetchJobRepeatingLoggedTimesAPI = (jobId) =>
  API.fetch(`/jobs/${jobId}/repeating_logged_times`).then(({ data }) => ({
    [ENTITY_NAME_REPEATING_LOGGED_TIMES]: data,
  }));
