import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { roundCoords, subtractCoords } from "../../../lib/dom";
import { findAngle, findDistance } from "../../../lib/math";
import { coordinateType } from "../../../lib/types/domTypes";

const NewDependency = ({
  startPosition,
  endPosition,
  scrollDistance,
  isFloatMode
}) => {
  const startCoords = roundCoords(
    subtractCoords(startPosition, scrollDistance)
  );
  const endCoords = roundCoords(endPosition);
  const rotateStart = findAngle(startCoords, endCoords);
  const rotateEnd = findAngle(endCoords, startCoords);
  const distance = findDistance(startCoords, endCoords);

  return (
    <Container className={isFloatMode ? "float" : ""}>
      <Line
        x1={startCoords.x}
        y1={startCoords.y}
        x2={endCoords.x}
        y2={endCoords.y}
      />
      <Circle cx={startCoords.x} cy={startCoords.y} r="4" />
      <Circle cx={endCoords.x} cy={endCoords.y} r="4" />
      {distance > 50 && (
        <g
          transform={`translate(${startCoords.x}, ${startCoords.y}) rotate(${rotateStart})`}
        >
          <Polyline
            points="0,0 0,6 6,6"
            transform="rotate(-135, 23, 0) translate(20, -3)"
          />
        </g>
      )}
      {distance > 20 && (
        <g
          transform={`translate(${endCoords.x}, ${endCoords.y}) rotate(${rotateEnd})`}
        >
          <Polyline
            points="0,0 0,6 6,6"
            transform="rotate(45, 18, 0) translate(15, -3)"
          />
        </g>
      )}
    </Container>
  );
};

NewDependency.propTypes = {
  startPosition: coordinateType.isRequired,
  endPosition: coordinateType.isRequired,
  scrollDistance: coordinateType.isRequired,
  isFloatMode: PropTypes.bool.isRequired
};

export default NewDependency;

const Line = styled.line`
  stroke-width: 2px;
`;

const Circle = styled.circle`
  stroke-width: 2px;
  fill: white;
`;

const Polyline = styled.polyline`
  position: absolute;
  stroke-width: 2px;
  fill: transparent;
`;

const Container = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  z-index: var(--z-index-job-timeline-create-dependancy-line);

  line,
  circle,
  polyline {
    stroke: var(--dependancy-fixed-border-color);
  }

  &.float {
    line,
    circle,
    polyline {
      stroke: var(--dependancy-float-border-color);
    }
  }
`;
