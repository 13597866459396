import * as PropTypes from "prop-types";
import React from "react";
import { getEachDay } from "../../../lib/dates";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const DatesConsumer = ({ children }) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportStartDate, viewportEndDate, getOffsetXAtDate, dayWidth }) =>
      getEachDay(viewportStartDate, viewportEndDate).map(date =>
        children({
          date,
          width: dayWidth,
          left: getOffsetXAtDate(date)
        })
      )
    }
  </JobTimelineScrollContext.Consumer>
);

DatesConsumer.propTypes = {
  children: PropTypes.func.isRequired
};

export default DatesConsumer;
