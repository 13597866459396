import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
} from "@streamtimefe/entities";

import type { TReportingEntityDescriptor } from "./ReportingEntityDescriptors";

export const ReportingDateFilterGroupTypes: Record<
  TReportingEntityDescriptor,
  readonly TFilterGroupTypeEnum[]
> = {
  [EntityDescriptorEnum.Job]: [
    FilterGroupTypeEnum.JobCreatedDate,
    FilterGroupTypeEnum.JobStartDate,
    FilterGroupTypeEnum.JobCompletedDate,
  ],
  [EntityDescriptorEnum.User]: [FilterGroupTypeEnum.TeamMember],
  [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: [
    FilterGroupTypeEnum.Date,
  ],
  [EntityDescriptorEnum.JobItemUserRolePlannedTime]: [FilterGroupTypeEnum.Date],
  [EntityDescriptorEnum.Quote]: [FilterGroupTypeEnum.IssueDate],
  [EntityDescriptorEnum.LoggedExpense]: [FilterGroupTypeEnum.Date],
  [EntityDescriptorEnum.LoggedTime]: [FilterGroupTypeEnum.Date],
  [EntityDescriptorEnum.Invoice]: [
    FilterGroupTypeEnum.IssueDate,
    FilterGroupTypeEnum.InvoiceDueDate,
    FilterGroupTypeEnum.InvoicePaidDate,
  ],
} as const;
