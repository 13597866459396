import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsKey } from "../../../helpers/selectors";
import { selectStateUserPreferences } from "./selectStateUserPreferences";

export const selectUserPreference = createCachedSelector(
  [selectStateUserPreferences, selectPropsKey],
  (userPreferences, key) => userPreferences.byKey[key]
)(selectPropsKey);

export function useUserPreference(key: string) {
  return useSelector((state: AppState) => selectUserPreference(state, { key }));
}
