import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobItemSubItemStore = createSingleEntityStoreContext(
  EntityClass.JobItemSubItem
);

export const JobItemSubItemStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobItemSubItem,
  JobItemSubItemStore
);
