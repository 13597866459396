import numeral from "numeral";
import { useSelector } from "react-redux";
import { costingMethodObj } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";

import {
  NUMBER_FORMAT_NO_DECIMAL,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../../../lib/constants";
import { getTotalPlannedMoney } from "../../../lib/entities/jobItemEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectJobItem } from "./index";
import { selectJobItemCalculatedTotalLoggedTimeExTax } from "./selectJobItemCalculatedTotalLoggedTimeExTax";
import { selectJobItemCalculatedTotalPlannedTimeExTax } from "./selectJobItemCalculatedTotalPlannedTimeExTax";
import { selectShowLoggedInformation } from "./selectShowLoggedInformation";
import { selectIsEditingJobItem } from "./ui/isEditing";

export const getInitialItemTotalInput = (jobItem) =>
  numeral(numeral(getTotalPlannedMoney(jobItem)).value()).format(
    NUMBER_FORMAT_TWO_DECIMALS
  );

export const selectJobItemTotalInput = (
  state,
  { jobId, jobItemId, isFocused, inputValue }
) => {
  const jobItem = selectJobItem(state, { jobItemId });
  const showLoggedInformation = selectShowLoggedInformation(state, {
    jobItemId,
  });
  const isEditing = selectIsEditingJobItem(state, { jobItemId });
  const customerCurrency = selectCustomerCurrency(state);
  const jobCurrency = selectJobCurrency(state, { jobId });

  let value = "";
  let placeholder = "";

  if (costingMethodObj(jobItem.costingMethod).isBasedByValue()) {
    const totalPlannedExTax = numeral(getTotalPlannedMoney(jobItem)).value();

    if (isEditing && totalPlannedExTax === 0) {
      value = "";
    } else if (isFocused) {
      value = inputValue;
    } else {
      value = formatForeignCurrency(customerCurrency, {
        value: totalPlannedExTax,
        currency: jobCurrency,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });
    }

    if (value.length === 0) {
      placeholder = formatForeignCurrency(customerCurrency, {
        value: 0,
        currency: jobCurrency,
      });
    }
  } else {
    const calculatedTotalPlannedTimeExTax =
      selectJobItemCalculatedTotalPlannedTimeExTax(state, { jobItemId });
    const formattedCalculatedTotalPlannedTimeExTax = formatForeignCurrency(
      customerCurrency,
      {
        value: calculatedTotalPlannedTimeExTax,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }
    );

    value = formattedCalculatedTotalPlannedTimeExTax;
  }

  if (showLoggedInformation && !isFocused) {
    const calculatedTotalLoggedTimeExTax =
      selectJobItemCalculatedTotalLoggedTimeExTax(state, { jobItemId });
    const formattedCalculatedTotalLoggedTimeExTax = formatForeignCurrency(
      customerCurrency,
      {
        value: calculatedTotalLoggedTimeExTax,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }
    );

    value = `${formattedCalculatedTotalLoggedTimeExTax} / ${value}`;
  }

  return [value, placeholder];
};

export const useJobItemTotalInput = (jobId, jobItemId, isFocused, inputValue) =>
  useSelector((state) =>
    selectJobItemTotalInput(state, {
      jobId,
      jobItemId,
      isFocused,
      inputValue,
    })
  );
