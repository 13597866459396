import { produce } from "immer";
import numeral from "numeral";
import { put, select } from "redux-saga/effects";

import { JobItemRole } from "st-shared/entities/JobItemRole";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import {
  actionJobItemRoleEdit,
  JOB_ITEM_ROLE_SET_RATE,
  JobItemRoleSetRateAction,
} from "../actions";
import { selectJobItemRole } from "../selectors/selectJobItemRole";

function* setRate(action: JobItemRoleSetRateAction) {
  try {
    const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
      jobItemRoleId: action.jobItemRoleId,
    });
    const newJobItemRole = produce(jobItemRole, (draft) => {
      draft.jobCurrencySellRate = numeral(action.value).value();
    });

    yield put(actionJobItemRoleEdit(jobItemRole.jobItemId, newJobItemRole));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemRoleSetRate() {
  yield takeLatestBy(
    [JOB_ITEM_ROLE_SET_RATE],
    setRate,
    (action: JobItemRoleSetRateAction) => action.jobItemRoleId
  );
}
