export const JOB_TIMELINE_ZOOM_LEVELS = [0, 1, 2, 3, 4];
export const JOB_TIMELINE_ZOOM_RATIOS = [0.04, 0.2, 0.5, 1, 2];
export const JOB_TIMELINE_ZOOM_MIN_LEVEL = 0;
export const JOB_TIMELINE_ZOOM_MAX_LEVEL = JOB_TIMELINE_ZOOM_LEVELS.length - 1;
export const JOB_TIMELINE_ZOOM_LEVEL_DEFAULT = 2;
export const JOB_TIMELINE_DAY_WIDTH = 100;
export const JOB_TIMELINE_DAYS_IN_WEEK = 7;

export const JOB_TIMELINE_CONTENT_MIN_WIDTH = 350;
export const JOB_TIMELINE_CONTENT_MIN_HEIGHT = 200;

export const JOB_TIMELINE_GROUP_COLUMN_WIDTH = 360;

export const JOB_TIMELINE_INFO_HEIGHT = 100;
export const JOB_TIMELINE_DATE_HEADER_HEIGHT = 66;

export const JOB_TIMELINE_HEADER_HEIGHT =
  JOB_TIMELINE_INFO_HEIGHT + JOB_TIMELINE_DATE_HEADER_HEIGHT;
export const JOB_TIMELINE_BOTTOM_SPACER = 50;

export const JOB_TIMELINE_PHASE_BAR_HEIGHT = 20;

/* Shared with Schedule */
export const JOB_TIMELINE_ITEM_ROW_HEIGHT = 34;
export const JOB_TIMELINE_ITEM_BAR_HEIGHT = 14;
export const JOB_TIMELINE_BAR_MIN_WIDTH = 14;
export const JOB_TIMELINE_BAR_HOVER_MIN_WIDTH = 70;
