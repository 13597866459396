import { produce } from "immer";
import { get } from "lodash-es";
import { call, put, select } from "redux-saga/effects";

import { createJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_DETAILS_FETCH_HISTORICAL_SUMMARY,
  JOB_ITEM_NEW_ITEM_DELETE,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
  JOB_ITEM_SET_EXPANDED,
  JOB_ITEM_SET_NAME,
} from "../../../lib/constants";
import { getJobName } from "../../../lib/entities/jobEntity";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
  getJobId,
  getJobItemDescription,
  getJobItemName,
  getSellRate,
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
} from "../../../lib/entities/jobItemEntity";
import { selectJobItemRolesByIds } from "../../../state/entities/jobItemRole/selectors/selectJobItemRolesByIds";
import { selectEditedJobItemRoleIds } from "../../../state/ui/jobItemRoles/selectors/selectEditedJobItemRoleIds";
import { selectEditedJobItemRoles } from "../../../state/ui/jobItemRoles/selectors/selectEditedJobItemRoles";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectIsExpandedJobItem } from "../../selectors/jobItem/ui/isExpanded";
import { selectJobItemSubItemsByIds } from "../../selectors/jobItemSubItem/selectJobItemSubItemsByIds";
import { selectEditedJobItemSubItemIds } from "../../selectors/jobItemSubItem/ui/selectEditedJobItemSubItemIds";
import { selectEditedJobItemSubItems } from "../../selectors/jobItemSubItem/ui/selectEditedJobItemSubItems";
import { selectEditedJobItemUserIds } from "../../selectors/jobItemUser/ui/selectEditedJobItemUserIds";
import { selectEditedJobItemUsers } from "../../selectors/jobItemUser/ui/selectEditedJobItemUsers";
import { selectJobItemUsersByIds } from "../../selectors/jobItemUserSelectors";
import { selectJobById } from "../../selectors/jobSelectors";

export function* saveNewJobItem(action) {
  const { jobItemId } = action.payload;
  let { jobItem } = action.payload;

  try {
    if (jobItemId > 0) return;

    const editedJobItemUserIds = yield select(selectEditedJobItemUserIds, {
      jobItemId,
    });
    const editedJobItemSubItemIds = yield select(
      selectEditedJobItemSubItemIds,
      {
        jobItemId,
      }
    );
    const editedJobItemRoleIds = yield select(selectEditedJobItemRoleIds, {
      jobItemId,
    });

    if (
      !hasMinimumRequiredInformation(
        jobItem,
        editedJobItemUserIds,
        editedJobItemSubItemIds,
        editedJobItemRoleIds
      )
    ) {
      yield put(
        createAction(JOB_ITEM_NEW_ITEM_DELETE, {
          jobItem,
        })
      );
      return;
    }

    // if not name set to the jobs name
    if (getJobItemName(jobItem).length === 0) {
      const job = yield select(selectJobById, {
        id: getJobId(jobItem),
      });
      yield put(
        createAction(JOB_ITEM_SET_NAME, {
          jobItemId,
          value: getJobName(job),
        })
      );
      jobItem = produce(jobItem, (draft) => {
        draft.name = getJobName(job);
      });
    }

    yield put(
      createAction(JOB_ITEM_SAVING, {
        jobItemId,
        jobItem,
      })
    );

    const jobItemUsers = yield select(selectJobItemUsersByIds, {
      ids: editedJobItemUserIds,
    });
    const jobItemSubItems = yield select(selectJobItemSubItemsByIds, {
      ids: editedJobItemSubItemIds,
    });
    const jobItemRoles = yield select(selectJobItemRolesByIds, {
      ids: editedJobItemRoleIds,
    });

    const { data } = yield call(
      createJobItemAPI,
      getJobId(jobItem),
      jobItem,
      jobItemUsers,
      jobItemSubItems,
      jobItemRoles
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_SAVED, {
        jobItemId,
        jobItem,
        jobItemUsers,
        jobItemSubItems,
        jobItemRoles,
        data,
      })
    );

    yield put(
      createAction(JOB_DETAILS_FETCH_HISTORICAL_SUMMARY, {
        jobId: jobItem.jobId,
      })
    );

    const isExpanded = yield select(selectIsExpandedJobItem, {
      jobItemId,
    });

    if (isExpanded && get(data, "jobItems[0]", false)) {
      const newJobItemId = get(data, "jobItems[0].id");
      yield put(
        createAction(JOB_ITEM_SET_EXPANDED, {
          jobItemId: newJobItemId,
          isExpanded: true,
        })
      );
    }
  } catch (error) {
    const previousJobItemSubItems = yield select(selectEditedJobItemSubItems, {
      jobItemId,
    });
    const previousJobItemUsers = yield select(selectEditedJobItemUsers, {
      jobItemId,
    });
    const previousJobItemRoles = yield select(selectEditedJobItemRoles, {
      jobItemId,
    });

    yield put(
      createAction(JOB_ITEM_SAVE_ERROR, {
        jobItemId,
        jobItem,
        previousJobItemSubItems,
        previousJobItemUsers,
        previousJobItemRoles,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

function hasMinimumRequiredInformation(
  jobItem,
  jobItemUserIds = [],
  jobItemSubItemIds = [],
  jobItemRoleIds = []
) {
  return (
    getJobItemName(jobItem).length > 0 ||
    getEstimatedStartDate(jobItem) !== null ||
    getEstimatedEndDate(jobItem) !== null ||
    getJobItemDescription(jobItem).length > 0 ||
    getSellRate(jobItem) > 0 ||
    getTotalPlannedMinutes(jobItem) > 0 ||
    getTotalPlannedMoney(jobItem) > 0 ||
    jobItemUserIds.length > 0 ||
    jobItemSubItemIds.length > 0 ||
    jobItemRoleIds.length > 0
  );
}

export default function* watchSaveNewJobItemCard() {
  yield takeLatestBy(
    [JOB_ITEM_SAVE_ITEM_CARD],
    saveNewJobItem,
    (action) => action.payload.jobItemId
  );
}
