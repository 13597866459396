import { get, isEmpty } from "lodash-es";
import {
  ACTIVITY_ENTRY_DELETE_ERROR,
  ACTIVITY_ENTRY_SAVE,
  ACTIVITY_ENTRY_SAVED,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_ACTIVITY_ENTRIES
} from "../../../../lib/constants";
import {
  getJobActivityEntryIdDatePath,
  getJobActivityEntryIdPath,
  getThreadActivityEntryIdPath,
  notActive
} from "../../../../lib/entities/activityEntryEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import datesByJobIdReducer from "./datesByJobIdReducer";

const idsByJobIdReducer = createEntityIndexedArrayReducer(
  getJobActivityEntryIdPath
);

const idsByJobIdDateReducer = createEntityIndexedArrayReducer(
  getJobActivityEntryIdDatePath
);

const idsByThreadIdReducer = createEntityIndexedArrayReducer(
  getThreadActivityEntryIdPath
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities),
    idsByJobIdDate: idsByJobIdDateReducer(
      state.idsByJobIdDate,
      changedEntities
    ),
    idsByThreadId: idsByThreadIdReducer(state.idsByThreadId, changedEntities)
  };

  Object.assign(
    nextState,
    datesByJobIdReducer(state, nextState, changedEntities)
  );

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_ACTIVITY_ENTRIES],
    notActive
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_ACTIVITY_ENTRIES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const saveReducer = (state, { payload }) => {
  const { activityEntry } = payload;
  return reduceChangedEntities(state, [
    {
      prevEntity: get(state, `byId.${activityEntry.id}`),
      newEntity: activityEntry
    }
  ]);
};

const saveSuccessReducer = (state, { payload }) => {
  const { activityEntry, data } = payload;

  const removeEntities =
    activityEntry.id < 0
      ? [{ prevEntity: get(state, `byId.${activityEntry.id}`) }]
      : [];

  const changedEntities = removeEntities.concat(
    parseEntityPayload(state, data[ENTITY_NAME_ACTIVITY_ENTRIES], notActive)
  );

  return reduceChangedEntities(state, changedEntities);
};

const onDeleteError = (state, { payload }) => {
  const { activityEntry } = payload;

  const changedEntities = [
    {
      newEntity: {
        ...activityEntry
      }
    }
  ];

  return reduceChangedEntities(state, changedEntities);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [ACTIVITY_ENTRY_SAVE]: saveReducer,
    [ACTIVITY_ENTRY_SAVED]: saveSuccessReducer,
    [ACTIVITY_ENTRY_DELETE_ERROR]: onDeleteError
  }
);
