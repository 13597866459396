import * as PropTypes from "prop-types";
import React from "react";
import { reactNodesType } from "../../lib/types/reactTypes";
import Tooltip from "./Tooltip";
import TooltipContentList from "./Tooltip/TooltipContentList";

const TooltipTitle = ({ messages }) => (
  <TooltipContentList contentArray={messages.map(message => ["", message])} />
);

TooltipTitle.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired
};

const ValidationMessages = ({ messages, children }) => (
  <Tooltip
    title={<TooltipTitle messages={messages} />}
    placement="top"
    enabled={Boolean(messages.length)}
  >
    <span>{children}</span>
  </Tooltip>
);

ValidationMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  children: reactNodesType.isRequired
};

ValidationMessages.defaultProps = {
  messages: []
};

export default ValidationMessages;
