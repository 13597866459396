import type { TAuthenticationBootstrapResponse } from "@streamtimefe/entities";
import { Currency } from "@streamtimefe/entities";

import type { TStoreGetter, TStoreSetter } from "../core";
import type { TCurrencyStore } from "./CurrencyStore";

export type TCurrencyActions = ReturnType<typeof createCurrencyActions>;

export function createCurrencyActions(
  set: TStoreSetter<TCurrencyStore>,
  _get: TStoreGetter<TCurrencyStore>
) {
  function authSync(response: TAuthenticationBootstrapResponse) {
    set((store) => {
      response.bootstrap.currencies.map((currency) => {
        store.currencies[currency.id] = currency;
      });

      store.searchItems = Currency.getSearchItems(
        response.bootstrap.currencies
      );

      store.sectionSearchItems = Currency.getSectionSearchItems(
        response.bootstrap.currencies
      );
    });
  }

  return { authSync };
}
