import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_JOB_PHASE_BAR,
  DATE_FORMAT_DAY_ORDINAL_DATE_MONTH,
  JOB_TIMELINE_ITEM_ROW_HEIGHT
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { getAttrPropString } from "../../../lib/dom";
import { getJobItemStatusIconClass } from "../../../lib/entities/jobItemEntity";
import { jobPhaseEntityType } from "../../../lib/entities/jobPhaseEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectPhaseTimelineEndDateByJobPhaseId,
  selectPhaseTimelineStartDateByJobPhaseId,
  selectPhaseStatusByJobPhaseId
} from "../../../redux/selectors/jobItemSelectors";
import { selectJobPhaseById } from "../../../redux/selectors/jobTimelineSelectors";
import SegmentsByJobPhase from "../../modules/JobItemDependancies/SegmentsByJobPhase";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import Tooltip from "../../modules/Tooltip";
import TooltipContentList from "../../modules/Tooltip/TooltipContentList";
import Anchor from "../JobTimelineScrollProvider/Anchor";
import JobTimelineRowConsumer from "../JobTimelineScrollProvider/JobTimelineRowConsumer";
import { JobTimelinePhaseBar } from "./JobTimelineBar";
import JobTimelineBarRect from "./JobTimelineBarRect";

const barAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_PHASE_BAR);

const mapState = (state, props) => ({
  jobPhase: selectJobPhaseById(state, props),
  jobPhaseStatusId: selectPhaseStatusByJobPhaseId(state, props),
  startDate: selectPhaseTimelineStartDateByJobPhaseId(state, props),
  endDate: selectPhaseTimelineEndDateByJobPhaseId(state, props)
});

const mapDispatch = null;

const TooltipTitle = ({ startDate, endDate }) => (
  <TooltipContentList
    contentArray={[
      ["Start", formatDate(startDate, DATE_FORMAT_DAY_ORDINAL_DATE_MONTH)],
      ["End", formatDate(endDate, DATE_FORMAT_DAY_ORDINAL_DATE_MONTH)]
    ]}
  />
);

TooltipTitle.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};

class JobTimelinePhase extends React.PureComponent {
  tooltipAnchorRef = React.createRef();

  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    jobPhase: jobPhaseEntityType.isRequired,
    jobPhaseStatusId: entityIdType.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  };

  static defaultProps = {
    startDate: null,
    endDate: null
  };

  render() {
    const { id, jobId, jobPhaseStatusId, startDate, endDate } = this.props;
    return (
      <JobTimelinePhaseWrapper>
        <JobTimelineRowConsumer
          barStartDate={startDate}
          barEndDate={endDate}
          startDate={startDate}
          dueDate={endDate}
        >
          {({ left, width }) => (
            <Anchor>
              <JobTimelineBarRect
                left={left}
                width={width}
                phase
                {...{
                  [barAttrName]: id
                }}
              >
                <Tooltip
                  title={
                    <TooltipTitle startDate={startDate} endDate={endDate} />
                  }
                  placement="top"
                  useCursorPosition={{
                    x: true,
                    y: false,
                    anchorRef: this.tooltipAnchorRef
                  }}
                >
                  <JobTimelinePhaseBar
                    ref={this.tooltipAnchorRef}
                    className={getJobItemStatusIconClass(jobPhaseStatusId)}
                  />
                </Tooltip>
              </JobTimelineBarRect>
            </Anchor>
          )}
        </JobTimelineRowConsumer>
        <Anchor>
          <SegmentsByJobPhase
            Context={JobTimelineScrollContext}
            jobId={jobId}
            jobPhaseId={id}
          />
        </Anchor>
      </JobTimelinePhaseWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelinePhase);

export const JobTimelinePhaseWrapper = styled.div`
  position: relative;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  border-bottom: var(--border-thin-bright);
`;
