import { call, put } from "redux-saga/effects";
import { fetchJobPhasesOptionsApi } from "../../../lib/API/optionsAPI";
import { ENTITIES_RECEIVED } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

export function* watchFetchJobPhasesOptions() {
  try {
    const data = yield call(fetchJobPhasesOptionsApi);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  }
}
