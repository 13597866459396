import clsx from "clsx";
import type { ReactNode } from "react";

import { largeHeaderCss, largeHeaderTitleCss } from "./LargePageHeader.css";

type Props = {
  title: ReactNode;
  children?: ReactNode;
  classNames?: { root?: string; title?: string };
};

export function LargePageHeader({ title, children, classNames }: Props) {
  return (
    <header className={clsx(largeHeaderCss, classNames?.root)}>
      <span className={clsx(largeHeaderTitleCss, classNames?.title)}>
        {title}
      </span>
      {children}
    </header>
  );
}
