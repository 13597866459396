import "draft-js/dist/Draft.css";

import styled from "styled-components";

const DraftJsContent = styled.div`
  cursor: text;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  color: black;

  .DraftEditor-editorContainer {
    border: none;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    display: none;
  }

  div:not(:first-child) > .public-DraftStyleDefault-block {
    margin-top: 4px;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 140%;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 140%;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-top: 4px;
  }

  p {
    white-space: pre-wrap;
    &:not(:first-child) {
      margin-top: 4px;
    }
    &:empty {
      padding: 1em 0 0;
      height: 1.4em;
    }
  }

  em {
    font-style: italic;
  }

  blockquote {
    border-left: 4px solid #ebebeb;
    margin: 12px 0;
    padding: 8px;
  }

  ul {
    list-style-type: disc;
    margin: 8px 4px;
    padding: 0;
  }

  li {
    margin-left: 12px;
    margin-top: 4px;
  }

  ol {
    counter-reset: ol;
    margin: 8px 4px;
    padding: 0;
    li {
      list-style-type: none;
      position: relative;
      &:before {
        content: counter(ol) ". ";
        counter-increment: ol;
        left: -36px;
        position: absolute;
        text-align: right;
        width: 30px;
      }
    }
  }

  code {
    font-family: monospace;
    background-color: #ebebeb;
    border-radius: 2px;
    padding: 2px 4px;
  }

  pre {
    font-size: 12px;
    font-weight: 500;
    line-height: 128%;
    white-space: pre-wrap;
    font-family: monospace;
    background-color: #ebebeb;
    border-radius: 2px;
    padding: 8px;
    margin: 12px 0;
    &.public-DraftStyleDefault-pre {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  img {
    max-width: 50%;
    max-height: 300px;
  }

  span.mention {
    display: inline-block;
    color: var(--color-charcoal);
    font-weight: bold;
    span.mention-user-id {
      display: none;
    }
  }
`;

export default DraftJsContent;
