import { map } from "lodash-es";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";

import {
  KeyedMatrixSeriesData,
  KeyedVectorSeriesData,
} from "../../lib/VectorMatrixSeriesData";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsOrdered,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";
import { useReportingSubSeriesExpanded } from "../../state/stores/searchSelectors";
import {
  addColumnContainer,
  columnContainer,
  loadingBlock,
  loadingColumns,
} from "./Table.css";
import { TableColumn } from "./TableColumn";
import { TableEntityText } from "./TableEntityText";
import { tableRowContainerCss, tableRowInitialColumnCss } from "./TableRow.css";
import { TableSubRow } from "./TableSubRow";

type Props = {
  index: number;
  seriesData: KeyedVectorSeriesData | KeyedMatrixSeriesData;
  hasSubFilter: boolean;
  getEnitityId: ((id: string) => string) | null;
  getSubEnitityId: ((id: string) => string) | null;
};

export function TableRow({
  index,
  seriesData,
  hasSubFilter,
  getEnitityId,
  getSubEnitityId,
}: Props) {
  const expanded = useReportingSubSeriesExpanded(seriesData.id);
  const searchType = useReportingSavedSegmentSearchType();

  return (
    <>
      <div key={seriesData.title} className={tableRowContainerCss}>
        <div className={tableRowInitialColumnCss}>
          <TableEntityText
            seriesData={seriesData}
            getEnitityId={getEnitityId}
            hasSubFilter={hasSubFilter}
          />
        </div>
        {searchType === ReportingSearchType.ColumnSeries && (
          <ColumnRow
            seriesData={seriesData as KeyedVectorSeriesData}
            expanded={expanded}
            index={index}
          />
        )}
        {searchType === ReportingSearchType.TimeSeries && (
          <TimeRow
            seriesData={seriesData as KeyedMatrixSeriesData}
            expanded={expanded}
            index={index}
          />
        )}
      </div>
      {expanded && (
        <TableSubRow
          seriesData={seriesData}
          getSubEnitityId={getSubEnitityId}
        />
      )}
    </>
  );
}

type ColumnRowProps = {
  seriesData: KeyedVectorSeriesData;
  expanded?: boolean;
  index: number;
};

function ColumnRow({ seriesData, expanded = false, index }: ColumnRowProps) {
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);

  return (
    <>
      {orderedColumns.map((column) => {
        return (
          <TableColumn
            key={column.id}
            column={column}
            value={seriesData.vector[column.id]}
            rowVectorValues={seriesData.vector}
            hide={expanded}
            indexRow={String(index)}
            indexColumn={column.id}
          />
        );
      })}
      <div className={addColumnContainer} />
    </>
  );
}

type TimeRowProps = {
  seriesData: KeyedMatrixSeriesData;
  expanded: boolean;
  index: number;
};

function TimeRow({ seriesData, expanded, index }: TimeRowProps) {
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  const columns = useReportingSavedSegmentColumns();

  if (!selectedTimeId) return null;

  return (
    <>
      {map(seriesData.matrix, (vector, key) => (
        <TableColumn
          key={key}
          column={columns[selectedTimeId]}
          value={vector[selectedTimeId]}
          rowVectorValues={vector}
          hide={expanded}
          indexRow={String(index)}
          indexColumn={key}
        />
      ))}
    </>
  );
}

export function TableRowLoading() {
  const searchType = useReportingSavedSegmentSearchType();
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);

  const fakeColumns =
    searchType === ReportingSearchType.ColumnSeries
      ? orderedColumns
      : loadingColumns;

  return (
    <div className={tableRowContainerCss}>
      <div className={tableRowInitialColumnCss}>
        <div className={loadingBlock} />
      </div>
      {fakeColumns.map((_, i) => (
        <div key={i} className={columnContainer}>
          <div className={loadingBlock} />
        </div>
      ))}
      {searchType === ReportingSearchType.ColumnSeries && (
        <div className={addColumnContainer} />
      )}
    </div>
  );
}
