import { isDefined } from "remeda";

export type AlertFunction = (
  type: "success" | "error",
  message: string
) => void;

let alertFunc: AlertFunction | undefined;

export function setAlertHandler(alert: AlertFunction) {
  alertFunc = alert;
}

export function alertHandler(type: "success" | "error", message: string) {
  if (!isDefined(alertFunc)) {
    // eslint-disable-next-line no-console
    console.warn("[Alert] used before initialisation.");
  } else {
    alertFunc(type, message);
  }
}
