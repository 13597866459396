import MuiTextField from "@material-ui/core/TextField";
import * as PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { StyledAutocomplete, StyledAutocompletePaper } from "./styles";

const FreeAutocomplete = forwardRef(
  (
    {
      options,
      placeholder,
      value,
      onInputChange,
      onChange,
      onClose,
      onOpen,
      disabled,
      AutocompleteComponent,
      PaperComponent,
      inputProps,
    },
    ref
  ) => {
    const handleInputChange = (e, newValue, reason) => {
      if (reason === "reset") return;
      if (onInputChange) onInputChange(e, newValue, reason);
    };

    const handleChange = (e, option, reason) => {
      if (reason === "clear") return;
      if (typeof option === "string") {
        option = {
          value: option,
        };
      }
      if (onChange) onChange(e, option, reason);
    };

    return (
      <AutocompleteComponent
        inputValue={value}
        options={options}
        getOptionLabel={(option) => option.value || ""}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onClose={onClose}
        onOpen={onOpen}
        disabled={disabled}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <MuiTextField
            variant="standard"
            inputRef={ref}
            placeholder={placeholder}
            {...params}
            inputProps={{ ...params.inputProps, ...inputProps }}
          />
        )}
        freeSolo
        disableClearable
        popupIcon={null}
        noOptionsText="No options..."
      ></AutocompleteComponent>
    );
  }
);

const OptionType = PropTypes.shape({
  key: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
});

FreeAutocomplete.propTypes = {
  options: PropTypes.arrayOf(OptionType).isRequired,
  value: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,

  AutocompleteComponent: reactNodesType,
  PaperComponent: reactNodesType,
};

FreeAutocomplete.defaultProps = {
  onInputChange: null,
  onChange: null,
  onClose: null,
  onOpen: null,
  disabled: false,
  placeholder: null,
  inputProps: {},

  AutocompleteComponent: StyledAutocomplete,
  PaperComponent: StyledAutocompletePaper,
};

export default FreeAutocomplete;
