import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const InvoiceDeleted = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {` deleted ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <b>{getMetaDataInvoiceNumber(activityEntry)}</b>
  </SystemMessage>
);

InvoiceDeleted.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
};

export default InvoiceDeleted;
