import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  JOB_PHASE_SET_ALL_EXPANDED,
  JOB_PHASE_SET_EXPANDED
} from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { useJobPhaseJobItemCount } from "../../../redux/selectors/jobPhase/selectJobPhaseJobItemCount";
import { useIsExpandedJobPhase } from "../../../redux/selectors/jobPhase/ui/isExpanded";
import Expander from "../../modules/Expander";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobPhaseId } from "../context/JobPhaseIdContext";

const PhaseExpander = () => {
  const jobId = useJobId();
  const jobPhaseId = useJobPhaseId();
  const isExpanded = useIsExpandedJobPhase(jobPhaseId);
  const itemCount = useJobPhaseJobItemCount(jobPhaseId);

  const dispatch = useDispatch();

  const onClick = e => {
    if (e.altKey) {
      dispatch(
        createAction(JOB_PHASE_SET_ALL_EXPANDED, {
          jobId,
          isExpanded: !isExpanded
        })
      );
    } else {
      dispatch(
        createAction(JOB_PHASE_SET_EXPANDED, {
          jobPhaseId,
          isExpanded: !isExpanded
        })
      );
    }
  };

  return (
    <CollapseExpandToggle $show={itemCount > 0}>
      <StyledExpander
        start={360}
        end={180}
        size={ICON_SIZE.XXX_LARGE}
        onToggle={onClick}
        style={{ width: 40, height: 46 }}
        isExpanded={isExpanded}
        tabIndex={-1}
      />
    </CollapseExpandToggle>
  );
};

export default PhaseExpander;

const StyledExpander = styled(Expander)`
  color: var(--color-gray-medium);
  &:hover {
    color: var(--color-charcoal);
  }
`;

const CollapseExpandToggle = styled.div`
  position: relative;
  visibility: ${props => (props.$show ? "visible" : "hidden")};
`;
