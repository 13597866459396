// deprecated

import type { Option } from "./Option";

export const ActivityEntryTypes = {
  Comment: 1,
  Post: 2,
  JobCreated: 3,
  UserAssignedToJob: 4,
  JobItemCreated: 5,
  JobPhaseCreated: 6,
  JobItemStartDateModified: 7,
  JobItemEndDateModified: 8,
  UserRemovedFromJob: 9,
  JobItemStatusChanged: 10,
  JobPhaseDeleted: 11,
  JobItemNameUpdated: 12,
  JobNameUpdated: 13,
  JobCompanyChanged: 14,
  JobStatusChanged: 15,
  JobItemOverBudget: 16,
  JobOverBudget: 18,
  JobMilestoneCreated: 19,
  JobMilestoneMoved: 20,
  JobMilestoneDeleted: 21,
  QuoteCreated: 22,
  QuoteApproved: 23,
  QuoteDeclined: 24,
  InvoiceCreated: 25,
  InvoicePaid: 26,
  LoggedExpenseCreated: 27,
  PurchaseOrderSent: 28,
  InvoiceSent: 29,
  QuoteSent: 30,
  JobItemDeleted: 31,
  QuoteDeleted: 32,
  InvoiceDeleted: 33,
  LoggedExpenseDeleted: 34,
  QuoteRevertedToDraft: 35,
  QuoteMarkedAsSent: 36,
  InvoicePaymentCreated: 37,
  InvoiceRevertedToDraft: 38,
  InvoiceSentToExternalAccountingPlatform: 39,
  PurchaseOrderPaymentCreated: 40,
  InvoiceViewed: 41,
  InvoiceMarkedAsSent: 42,
  PurchaseOrderMarkedAsSent: 43,
  LoggedExpenseSentToExternalAccountingPlatform: 44,
  LoggedExpenseApproved: 45,
  LoggedExpenseDeclined: 46,
  LoggedExpenseRevertedToDraft: 47,
  LoggedExpensePaid: 48,
  JobCompletedDateUpdated: 49,
  JobLeadAssigned: 50,
  JobItemIsBillableUpdated: 51,
  JobIsBillableUpdated: 52,
  InvoiceRevertedFromPaid: 53,
  InvoicePaymentDeleted: 54,
  JobDuplicated: 56,
} as const;

export type ActivityEntryTypesType =
  (typeof ActivityEntryTypes)[keyof typeof ActivityEntryTypes];

export type Type = ActivityEntryType;
export interface ActivityEntryType extends Option {
  id: ActivityEntryTypesType;
}

export function createActivityEntryType(id: ActivityEntryTypesType) {
  switch (id) {
    case ActivityEntryTypes.Comment:
      return { id, name: "Comment" };
    case ActivityEntryTypes.Post:
      return { id, name: "Post" };
    default:
      return { id, name: "" };
  }
}
