// deprecated

import type { TRole } from "@streamtimefe/entities";

import type { SearchOption } from "./Options/SearchOption";

export function roleObj(entity: TRole) {
  const obj = {
    entity,
    getSearchString: function () {
      return entity.name;
    },
    getAsOption: function (): SearchOption<TRole> {
      return {
        entity,
        key: entity.id,
        value: entity.name,
        searchString: obj.getSearchString(),
      };
    },
  };
  return obj;
}
