import { clsx } from "clsx";
import type { ReactNode } from "react";
import type {
  ButtonProps as AriaButtonProps,
  LinkProps as AriaLinkProps,
} from "react-aria-components";
import { Button as AriaButton, Link as AriaLink } from "react-aria-components";

import { useDisabledBoundary } from "../utils";
import { baseButtonStyle } from "./styles.css";

export type BaseButtonProps = Omit<
  AriaButtonProps,
  "aria-label" | "children"
> & {
  "aria-label": string;
  children: ReactNode;
};

/**
 * A Base Button allows a user to perform an action, with mouse, touch, and keyboard interactions.
 * The Base Button component is built on the [React Aria Button Component](https://react-spectrum.adobe.com/react-aria/Button.html).
 * A full list of props available to the Base Button component can be found in
 * the [props section of the documentation](https://react-spectrum.adobe.com/react-aria/Button.html#props).
 *
 * ### Usage
 *
 * A Base Button is used to trigger an action or event, such as submitting a form,
 * opening a modal, or navigating to another view, when you do not require a styled button.
 * Buttons should be labeled clearly to indicate the action they perform, providing
 * users with immediate understanding of their function.
 *
 * ### Accessibility
 *
 * If a visual label is not provided (e.g. an icon only button), then the `aria-label`
 * or `aria-labelledby` prop must be passed to identify the button to assistive technology.
 */
export function BaseButton({
  children,
  className,
  isDisabled,
  ...rest
}: BaseButtonProps) {
  const isDisabledBoundary = useDisabledBoundary();
  return (
    <AriaButton
      {...rest}
      isDisabled={isDisabled || isDisabledBoundary}
      className={clsx(baseButtonStyle, className)}
    >
      {children}
    </AriaButton>
  );
}

export type BaseButtonLinkProps = Omit<
  AriaLinkProps,
  "aria-label" | "children"
> & {
  "aria-label": string;
  children: ReactNode;
};

/**
 * A Base Button allows a user to perform an action, with mouse, touch, and keyboard interactions.
 * The Base Button component is built on the [React Aria Button Component](https://react-spectrum.adobe.com/react-aria/Button.html).
 * A full list of props available to the Base Button component can be found in
 * the [props section of the documentation](https://react-spectrum.adobe.com/react-aria/Button.html#props).
 *
 * ### Usage
 *
 * A Base Button is used to trigger an action or event, such as submitting a form,
 * opening a modal, or navigating to another view, when you do not require a styled button.
 * Buttons should be labeled clearly to indicate the action they perform, providing
 * users with immediate understanding of their function.
 *
 * ### Accessibility
 *
 * If a visual label is not provided (e.g. an icon only button), then the `aria-label`
 * or `aria-labelledby` prop must be passed to identify the button to assistive technology.
 */
export function BaseButtonLink({
  children,
  className,
  isDisabled,
  ...rest
}: BaseButtonLinkProps) {
  const isDisabledBoundary = useDisabledBoundary();
  return (
    <AriaLink
      {...rest}
      isDisabled={isDisabled || isDisabledBoundary}
      className={clsx(baseButtonStyle, className)}
    >
      {children}
    </AriaLink>
  );
}
