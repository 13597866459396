import type { TUserPreference } from "@streamtimefe/entities";
import { Entity, EntityClass } from "@streamtimefe/entities";

import { fetch } from "../fetch";

const root = "/preferences";

export function get() {
  return fetch()
    .get(root)
    .then(fetch.transform.models(EntityClass.UserPreference));
}

export function put({ userPreference }: { userPreference: TUserPreference }) {
  return fetch({ alert: true })
    .put(root, Entity.apiSafeTransform(userPreference))
    .then(fetch.transform.model(EntityClass.UserPreference, { sync: false }));
}
