import * as PropTypes from "prop-types";
import React from "react";

import {
  commercialDocumentLineItemEntityType,
  getDefaultName,
  getLineItemName,
} from "../../../../lib/ui/commercialDocumentLineItems";
import consumeCommercialDocument from "../CommercialDocumentContext/Consumers/consumeCommercialDocument";
import { DocumentLineField } from "./DocumentLineField";

const mapContext = ({
  document,
  documentLineItemMethods,
  actions: { doEditDocumentLineItem, doSaveDocumentLineItem },
}) => ({
  getValue: documentLineItemMethods.getName,
  setValue: documentLineItemMethods.setName,
  doEditDocumentLineItem,
  doSaveDocumentLineItem,
});

class DocumentLineName extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    isHeading: PropTypes.bool,
    documentLineItem: commercialDocumentLineItemEntityType.isRequired,
    isHiddenField: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isCreating: PropTypes.bool,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    doEditDocumentLineItem: PropTypes.func.isRequired,
    doSaveDocumentLineItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isHeading: false,
    isCreating: false,
    isHiddenField: false,
    isDisabled: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { documentLineItem, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: getValue(documentLineItem),
    };
  }

  state = {
    value: "",
    hasFocus: false,
    prevDocumentLineItem: null,
  };

  componentDidMount() {
    const { documentLineItem, isHeading, isCreating } = this.props;

    if (
      getLineItemName(documentLineItem) === getDefaultName(isHeading) &&
      !isCreating
    ) {
      requestAnimationFrame(() =>
        this.ref.current.querySelector("input").focus()
      );
    }
  }

  edit = (newValue) => {
    const { documentLineItem, setValue, doEditDocumentLineItem } = this.props;

    doEditDocumentLineItem(
      documentLineItem.id,
      setValue(documentLineItem, newValue)
    );
  };

  save = () => {
    const { documentLineItem, doSaveDocumentLineItem } = this.props;
    const { prevDocumentLineItem } = this.state;

    doSaveDocumentLineItem(
      documentLineItem.id,
      documentLineItem,
      prevDocumentLineItem
    );
  };

  handleChange = (e) => {
    const { value } = this.state;
    const newValue = String(e.target.value);

    if (value !== newValue) {
      this.setState({
        value: newValue,
      });

      this.edit(newValue);
    }
  };

  handleBlur = () => {
    this.save();

    this.setState({
      hasFocus: false,
      prevDocumentLineItem: null,
    });
  };

  handleFocus = () => {
    const { documentLineItem } = this.props;

    this.setState({
      hasFocus: true,
      prevDocumentLineItem: documentLineItem,
    });
  };

  render() {
    const { isDisabled, isHeading, isHiddenField } = this.props;
    const { value } = this.state;

    return (
      <DocumentLineField
        ref={this.ref}
        leftAlign={true}
        isHiddenField={isHiddenField}
        isHeading={isHeading}
        disabled={isDisabled}
        placeholder={getDefaultName(isHeading)}
        value={value === getDefaultName(isHeading) ? "" : value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    );
  }
}

export default consumeCommercialDocument(mapContext)(DocumentLineName);
