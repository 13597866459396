import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { costingMethodObj, CostingMethods } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_COSTING_METHOD,
  JOB_ITEM_SET_RATE,
  NUMBER_FORMAT_NO_DECIMAL,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import {
  getInitialItemRateInput,
  useJobItemRateInput,
} from "../../../redux/selectors/jobItem/selectJobItemRateInput";
import { useJobCurrency } from "../../../state/entities/currency/selectors/selectJobCurrency";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemRateContainerCss, itemRateFieldCss } from "./ItemRate.css";
import { MasterItemVariance } from "./tooltips/MasterItemVariance";

export function ItemRate() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const costingMethod = costingMethodObj(jobItem.costingMethod);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemId,
    key: FOCUS_KEYS.ITEM_RATE,
  });

  const dispatch = useDispatch();

  const [rate, setRate] = useState("");

  let [value, placeholder] = useJobItemRateInput(
    jobId,
    jobItemId,
    focused,
    rate
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!isJobEditable) return;
    const newValue = event.target.value;

    setRate(newValue);

    dispatch(
      createAction(JOB_ITEM_SET_RATE, {
        jobItemId,
        value: newValue,
      })
    );
  }

  function onBlur() {
    setFocused(false);
  }

  function onFocus() {
    setRate(getInitialItemRateInput(jobItem));
    setFocused(true);
  }

  function setCostingMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_COSTING_METHOD, {
        jobItemId,
        costingMethodId: CostingMethods.ValueCalculatedSell,
        doSetFocus: false,
      })
    );
  }

  const [hovered, setHovered] = useState(false);

  if (costingMethod.isValueUserSell()) {
    placeholder = value;
    value = "";
    if (!isJobEditable || !hovered) {
      placeholder = "";
    }
  }

  const formattedPlaceholder =
    placeholder !== ""
      ? formatForeignCurrency(customerCurrency, {
          value: Number(placeholder),
          currency: jobCurrency,
          hideLongSymbols: true,
          format: NUMBER_FORMAT_NO_DECIMAL,
        })
      : placeholder;

  const formattedValue =
    value !== "" && !focused
      ? formatForeignCurrency(customerCurrency, {
          value: Number(value),
          currency: jobCurrency,
          hideLongSymbols: true,
          format: NUMBER_FORMAT_NO_DECIMAL,
        })
      : value;

  return (
    <div
      className={itemRateContainerCss}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ItemInputField
        ref={ref}
        as="numeric"
        containerClassName={itemRateFieldCss}
        label="Rate"
        value={formattedValue}
        placeholder={formattedPlaceholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        largeText
        disabled={
          !isJobEditable || !costingMethodObj(jobItem.costingMethod).isItem()
        }
      />
      <MasterItemVariance />
      {isJobEditable && costingMethod.isValueUserSell() && (
        <MethodLock
          tooltip={
            <>
              <strong>
                The rate for this item would be {placeholder} per hour based on
                the planned hours and fixed total.
              </strong>{" "}
              Click to track all hours on this item at {placeholder}.
            </>
          }
          className={methodLockIcon}
          onClick={setCostingMethod}
        />
      )}
    </div>
  );
}
