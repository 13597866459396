import { Field, FieldArray, Formik } from "formik";
import { get, isEqual, reject } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { pluralise } from "st-shared/lib/pluralise";
import styled from "styled-components";

import {
  DATE_FORMAT_SHORT_DATE_FULL_MONTH,
  JOB_ITEM_DELETE,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START,
  JOB_ITEM_SAVE_EDIT,
  JOB_TIMELINE_ITEM_BAR_HEIGHT,
} from "../../../lib/constants";
import { getFuzzyRelativeDateText, toDate } from "../../../lib/dates";
import {
  getDependancyLinkTypeSymbol,
  getIsFloat,
  getLagDays,
  getLinkedJobItemId,
  jobItemDependancyEntityType,
} from "../../../lib/entities/jobItemDependancyEntity";
import {
  getJobItemName,
  getTotalUsedMinutes,
  isOverdue,
  jobItemEntityType,
} from "../../../lib/entities/jobItemEntity";
import { FrameworkException } from "../../../lib/exceptions/FrameworkException";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { domNodeType } from "../../../lib/types/domTypes";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { feToWebConfirmDeleteJobItem } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../../redux/helpers/createAction";
import { createSnapshotId } from "../../../redux/helpers/snapshotIds";
import { selectAllJobItemDependanciesByJobItemId } from "../../../redux/selectors/jobItemDependancySelectors";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";
import { selectUserIdsByJobItemId } from "../../../redux/selectors/jobItemUserSelectors";
import Button, {
  IconButton,
  IconButtonLight,
  PrimaryButton,
} from "../../elements/Button";
import Flex from "../../elements/Flex";
import CloseIcon from "../../elements/Icons/CloseIcon";
import UnlinkIcon from "../../elements/Icons/UnlinkIcon";
import Spacer from "../../elements/Spacer";
import JobItemConnector from "../Connectors/JobItemConnector";
import IsJobItemDependancyConflictConnector from "../JobItemDependancies/IsJobItemDependancyConflictConnector";
import InlineDatePicker from "../Pickers/InlineDatePicker";
import Popover from "../Popover";
import { ICON_SIZE } from "../StyledIcon";
import Tooltip from "../Tooltip";
import UserIconList from "../User/UserIconList";

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props),
  teamMemberIds: selectUserIdsByJobItemId(state, props),
  jobItemDependancies: selectAllJobItemDependanciesByJobItemId(state, {
    jobItemId: props.id,
  }),
});

const mapDispatch = (dispatch) => ({
  doSaveJobItem: (
    jobItem,
    prevJobItem,
    prevJobItemDependancies,
    modifiedJobItemDependancies,
    deletedJobItemDependancies,
    hasChanged
  ) =>
    dispatch(
      createAction(JOB_ITEM_SAVE_EDIT, {
        jobItemId: jobItem.id,
        jobId: jobItem.jobId,
        jobItem,
        prevJobItem,
        prevJobItemDependancies,
        modifiedJobItemDependancies,
        deletedJobItemDependancies,
        snapshotId: createSnapshotId(),
        hasChanged,
      })
    ),
  doDeleteJobItem: (jobItem, jobItemDependancies) =>
    dispatch(createAction(JOB_ITEM_DELETE, { jobItem, jobItemDependancies })),
});

export const getDependancyTypeTooltipTitle = (jobItemDependancy) => {
  const {
    dependancyTypeId,
    parentJobItemId,
    childJobItemId,
    lagDays,
    isFloat,
  } = jobItemDependancy;

  let headingText = "";
  let parentText = "";
  let childText = "";
  let floatText = "";
  let lagDaysText = "";

  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      headingText = "Start → Start";
      parentText = "start";
      childText = "starts";
      break;
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      headingText = "Start → End";
      parentText = "start";
      childText = "ends";
      break;
    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      headingText = "End → Start";
      parentText = "end";
      childText = "starts";
      break;
    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      headingText = "End → End";
      parentText = "end";
      childText = "ends";
      break;
    default:
      throw new FrameworkException(
        `Invalid Job Item Dependancy Type ${dependancyTypeId}`
      );
  }

  if (lagDays > 0) {
    floatText = isFloat ? "at least " : "exactly ";
    lagDaysText = `${lagDays} ${pluralise(lagDays, "day")} before `;
  } else if (lagDays < 0) {
    floatText = isFloat ? "at most " : "exactly ";
    lagDaysText = `${Math.abs(lagDays)} ${pluralise(
      Math.abs(lagDays),
      "day"
    )} after `;
  } else {
    floatText = isFloat ? "before " : "when ";
  }

  return (
    <TooltipTitle>
      <b>{headingText}</b>
      <br />
      <JobItemConnector id={parentJobItemId}>
        {getJobItemName}
      </JobItemConnector>{" "}
      must {parentText} {floatText} {lagDaysText}
      <JobItemConnector id={childJobItemId}>
        {getJobItemName}
      </JobItemConnector>{" "}
      {childText}
    </TooltipTitle>
  );
};

class JobItemPopover extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    id: entityIdType,
    jobItem: jobItemEntityType,
    teamMemberIds: entityIdListType.isRequired,
    jobItemDependancies: PropTypes.arrayOf(jobItemDependancyEntityType)
      .isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    doSaveJobItem: PropTypes.func.isRequired,
    doDeleteJobItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
    id: null,
    jobItem: null,
  };

  onSave = (formValues) => {
    const { jobItem, jobItemDependancies, onClose, doSaveJobItem } = this.props;
    const modifiedJobItemDependancies = [];
    const deletedJobItemDependancies = reject(
      jobItemDependancies,
      (jobItemDependancy) =>
        Boolean(
          formValues.jobItemDependancies.find(
            ({ id }) => id === jobItemDependancy.id
          )
        )
    );

    formValues.jobItemDependancies.forEach((jobItemDependancy) => {
      const prevJobItemDependancy = jobItemDependancies.find(
        ({ id }) => id === jobItemDependancy.id
      );

      if (
        getLagDays(jobItemDependancy) !== getLagDays(prevJobItemDependancy) ||
        getIsFloat(jobItemDependancy) !== getIsFloat(prevJobItemDependancy)
      )
        modifiedJobItemDependancies.push({
          ...prevJobItemDependancy,
          lagDays: getLagDays(jobItemDependancy),
          isFloat: getIsFloat(jobItemDependancy),
        });
    });

    const newJobItem = {
      ...jobItem,
      estimatedStartDate: formValues.startDate,
      estimatedEndDate: formValues.endDate,
    };

    const hasChanged =
      !isEqual(jobItem, newJobItem) ||
      deletedJobItemDependancies.length ||
      modifiedJobItemDependancies.length;

    doSaveJobItem(
      newJobItem,
      jobItem,
      jobItemDependancies,
      modifiedJobItemDependancies,
      deletedJobItemDependancies,
      hasChanged
    );

    onClose();
  };

  onDelete = () => {
    const { jobItemDependancies, jobItem } = this.props;

    const hasLoggedTime = getTotalUsedMinutes(jobItem) > 0;
    const hasDependancies =
      jobItemDependancies.filter(({ id }) => id > 0).length > 0;
    feToWebConfirmDeleteJobItem(hasLoggedTime, hasDependancies).then(
      this.onConfirmDelete
    );
  };

  onConfirmDelete = () => {
    const { jobItem, onClose, doDeleteJobItem, jobItemDependancies } =
      this.props;
    doDeleteJobItem(
      jobItem,
      jobItemDependancies.filter(({ id }) => id > 0)
    );
    onClose();
  };

  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  get initialValues() {
    const { jobItem, jobItemDependancies } = this.props;
    return {
      startDate: jobItem.estimatedStartDate,
      endDate: jobItem.estimatedEndDate,
      jobItemDependancies: jobItemDependancies.filter(({ id }) => id > 0),
    };
  }

  render() {
    const { anchorEl, jobItem, teamMemberIds, open, readOnly } = this.props;
    return (
      <Popover
        style={{ marginTop: 5 }}
        open={open}
        anchorEl={anchorEl}
        onClose={this.onCancel}
        anchorOrigin={{
          vertical: JOB_TIMELINE_ITEM_BAR_HEIGHT + 10,
          horizontal: "center",
        }}
      >
        {jobItem && (
          <Formik initialValues={this.initialValues} onSubmit={this.onSave}>
            {({ values, getFieldValue, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Header>
                  <Heading>{getJobItemName(jobItem)}</Heading>
                  <CloseAction onClick={this.onCancel}>
                    <CloseIcon size={ICON_SIZE.X_LARGE} />
                  </CloseAction>
                </Header>
                <Body>
                  <JobItemFields>
                    <tbody>
                      <tr>
                        <td>Team members</td>
                        <td>
                          {teamMemberIds.length ? (
                            <TeamMembers ids={teamMemberIds} />
                          ) : (
                            <Placeholder>No team members</Placeholder>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Used / planned (h)</td>
                        <td>
                          {convertMinutesToTimeHM(jobItem.totalLoggedMinutes)}
                          {" / "}
                          {convertMinutesToTimeHM(jobItem.totalPlannedMinutes)}
                        </td>
                      </tr>
                      <tr>
                        <td>Starts</td>
                        <td>
                          <DateField>
                            <Field name="startDate">
                              {({ field }) => (
                                <InlineDatePicker
                                  {...field}
                                  disabled={readOnly}
                                  format={DATE_FORMAT_SHORT_DATE_FULL_MONTH}
                                  onChange={(moment) => {
                                    setFieldValue("startDate", toDate(moment));
                                    if (
                                      values.endDate &&
                                      moment.isAfter(values.endDate)
                                    )
                                      setFieldValue("endDate", toDate(moment));
                                  }}
                                />
                              )}
                            </Field>
                            <RelativeDate>
                              {getFuzzyRelativeDateText(values.startDate)}
                            </RelativeDate>
                          </DateField>
                        </td>
                      </tr>
                      <tr>
                        <td>Ends</td>
                        <td>
                          <DateField>
                            <Field name="endDate">
                              {({ field }) => (
                                <InlineDatePicker
                                  {...field}
                                  disabled={readOnly}
                                  format={DATE_FORMAT_SHORT_DATE_FULL_MONTH}
                                  onChange={(moment) => {
                                    setFieldValue("endDate", toDate(moment));
                                    if (
                                      values.startDate &&
                                      moment.isBefore(values.startDate)
                                    )
                                      setFieldValue(
                                        "startDate",
                                        toDate(moment)
                                      );
                                  }}
                                />
                              )}
                            </Field>
                            <RelativeDate
                              isOverdue={isOverdue(jobItem, values.endDate)}
                            >
                              {getFuzzyRelativeDateText(values.endDate)}
                            </RelativeDate>
                          </DateField>
                        </td>
                      </tr>
                      {values.jobItemDependancies.length > 0 && (
                        <tr>
                          <td>Dependencies</td>
                        </tr>
                      )}
                    </tbody>
                  </JobItemFields>
                  {values.jobItemDependancies.length > 0 && (
                    <DependancyField>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Lag Days</th>
                          <th>Linked Item</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="jobItemDependancies">
                          {({ remove }) =>
                            values.jobItemDependancies.map(
                              (jobItemDependancy, index) => (
                                <IsJobItemDependancyConflictConnector
                                  key={jobItemDependancy.id}
                                  id={jobItemDependancy.id}
                                >
                                  {(isConflict) => (
                                    <Tooltip
                                      title={getDependancyTypeTooltipTitle(
                                        jobItemDependancy
                                      )}
                                    >
                                      <tr
                                        className={isConflict ? "conflict" : ""}
                                      >
                                        <td>
                                          {getDependancyLinkTypeSymbol(
                                            jobItemDependancy,
                                            jobItem.id
                                          )}
                                        </td>
                                        <td>
                                          {readOnly ? (
                                            <>
                                              {getIsFloat(jobItemDependancy)
                                                ? "at least"
                                                : "exactly"}
                                            </>
                                          ) : (
                                            <SelectField
                                              as="select"
                                              name={`jobItemDependancies.${index}.isFloat`}
                                              value={
                                                get(
                                                  values,
                                                  `jobItemDependancies.${index}.isFloat`
                                                )
                                                  ? "1"
                                                  : "0"
                                              }
                                              onChange={(event) =>
                                                setFieldValue(
                                                  `jobItemDependancies.${index}.isFloat`,
                                                  Boolean(
                                                    Number(event.target.value)
                                                  )
                                                )
                                              }
                                            >
                                              <option value={0}>exactly</option>
                                              <option value={1}>
                                                at least
                                              </option>
                                            </SelectField>
                                          )}
                                          {readOnly ? (
                                            getLagDays(jobItemDependancy)
                                          ) : (
                                            <LagField
                                              name={`jobItemDependancies.${index}.lagDays`}
                                              type="text"
                                            />
                                          )}
                                        </td>
                                        <td className="jobItemName">
                                          <JobItemConnector
                                            id={getLinkedJobItemId(
                                              jobItemDependancy,
                                              jobItem.id
                                            )}
                                          >
                                            {getJobItemName}
                                          </JobItemConnector>
                                        </td>
                                        {!readOnly && (
                                          <td>
                                            <IconButtonLight
                                              onClick={() => {
                                                setFieldValue(
                                                  "jobItemDependancies",
                                                  reject(
                                                    values.jobItemDependancies,
                                                    ({ id }) =>
                                                      id ===
                                                      jobItemDependancy.id
                                                  )
                                                );
                                              }}
                                            >
                                              <UnlinkIcon
                                                size={ICON_SIZE.X_LARGE}
                                              />
                                            </IconButtonLight>
                                          </td>
                                        )}
                                      </tr>
                                    </Tooltip>
                                  )}
                                </IsJobItemDependancyConflictConnector>
                              )
                            )
                          }
                        </FieldArray>
                      </tbody>
                    </DependancyField>
                  )}
                </Body>
                {!readOnly && (
                  <Footer>
                    <Button onClick={this.onDelete}>Delete</Button>
                    <Flex>
                      <Tooltip
                        enabled={Boolean(values.jobItemDependancies.length)}
                        title={
                          <TooltipTitle style={{ width: 150 }}>
                            Unlink dependencies to clear dates
                          </TooltipTitle>
                        }
                      >
                        <div>
                          <Button
                            disabled={Boolean(
                              values.jobItemDependancies.length
                            )}
                            onClick={() => {
                              setFieldValue("startDate", null);
                              setFieldValue("endDate", null);
                            }}
                          >
                            Clear dates
                          </Button>
                        </div>
                      </Tooltip>
                      <Spacer w={10} />
                      <PrimaryButton type="submit" style={{ width: 100 }}>
                        Save
                      </PrimaryButton>
                    </Flex>
                  </Footer>
                )}
              </form>
            )}
          </Formik>
        )}
      </Popover>
    );
  }
}

export default connect(mapState, mapDispatch)(JobItemPopover);

const Heading = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 2px 50px 0;
`;

const CloseAction = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
  user-select: text;
`;

const Body = styled.div`
  padding: 10px 25px;
`;

const JobItemFields = styled.table`
  width: 100%;
  td {
    height: 45px;
    border-bottom: 1px solid var(--color-gray-bright);
    padding: 9px 0 6px;
    color: black;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    user-select: text;
    &:first-child {
      width: 160px;
      color: var(--color-gray-dark);
      font-size: 14px;
      font-weight: 500;
    }
  }
  tr:last-child td {
    border: none;
  }
`;

const DependancyField = styled.table`
  width: 100%;
  margin-top: 10px;

  th {
    font-weight: bold;
    font-size: 12px;
    color: black;
    text-align: left;
    padding: 0 10px;
    &:first-child {
      padding: 0 10px 0 0;
    }
  }
  td {
    height: 32px;
    color: black;
    font-size: 12px;
    vertical-align: middle;
    user-select: text;
    padding: 0 10px;
    &:first-child {
      width: 40px;
      color: var(--color-gray-dark);
      font-size: 12px;
      font-weight: bold;
      padding: 0 10px 0 0;
    }
    &:last-child {
      width: 30px;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
    }
    &.jobItemName {
      width: 200px;
    }
  }

  tr.conflict td {
    color: var(--color-red-dark);
    &:first-child {
      color: var(--color-red-bright);
    }
  }
`;

const SelectField = styled(Field)`
  height: 20px;
  padding: 3px 0;
  font-size: 12px;
  font-weight: 500;
  border: none;
  margin-right: 10px;
`;

const LagField = styled(Field)`
  font-size: 12px;
  width: 25px;
  height: 20px;
  padding: 5px 5px 2px;
  font-weight: 500;
  background-color: var(--color-gray-light);
`;

const Footer = styled(Flex)`
  width: 100%;
  height: 60px;
  padding: 15px;
  justify-content: space-between;
  background-color: var(--color-ygrittesnow);
  > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const RelativeDate = styled.div`
  color: ${(props) =>
    props.isOverdue ? "var(--color-red-bright)" : "var(--color-gray-dark)"};
`;

const DateField = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  ${RelativeDate} {
    margin-left: 10px;
  }
`;

const TeamMembers = styled(UserIconList)`
  flex-wrap: wrap;
  max-width: 270px;
`;

const Placeholder = styled.span`
  color: var(--color-gray);
`;

const TooltipTitle = styled.div`
  width: 200px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: white;
`;
