import type { Dictionary } from "@streamtimefe/types";
import { produce } from "immer";
import type { StateCreator } from "zustand";

import type { ReportingStoreState } from "./reportingStore";

export type UiSlice = {
  ui: {
    globalFiltersOpen: boolean;
    datasetColumnsOpen: Dictionary<string, boolean>;
    datasetFiltersOpen: Dictionary<string, boolean>;
    actions: {
      reset: () => void;
      setGlobalFiltersOpen: (open: boolean) => void;
      setDatasetColumnsOpen: (dataSetId: string, open: boolean) => void;
      setDatasetFiltersOpen: (dataSetId: string, open: boolean) => void;
    };
  };
};

export const createUiSlice: StateCreator<
  ReportingStoreState,
  [],
  [],
  UiSlice
> = (set) => ({
  ui: {
    globalFiltersOpen: true,
    datasetColumnsOpen: {},
    datasetFiltersOpen: {},
    actions: {
      reset() {
        set((s) =>
          produce(s, (draft) => {
            draft.ui.globalFiltersOpen = true;
            draft.ui.datasetColumnsOpen = {};
            draft.ui.datasetFiltersOpen = {};
          })
        );
      },

      setGlobalFiltersOpen(open: boolean) {
        set((s) =>
          produce(s, (draft) => {
            draft.ui.globalFiltersOpen = open;
          })
        );
      },

      setDatasetColumnsOpen(dataSetId: string, open: boolean) {
        set((s) =>
          produce(s, (draft) => {
            draft.ui.datasetColumnsOpen[dataSetId] = open;
          })
        );
      },

      setDatasetFiltersOpen(dataSetId: string, open: boolean) {
        set((s) =>
          produce(s, (draft) => {
            draft.ui.datasetFiltersOpen[dataSetId] = open;
          })
        );
      },
    },
  },
});
