import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataQuoteApprovedBy,
  getMetaDataQuoteNumber,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobQuoteApproved = ({ activityEntry, canEditJobs }) => (
  <SystemMessage>
    {"Quote "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataQuoteNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" has been approved by "}
    {getMetaDataQuoteApprovedBy(activityEntry)}
  </SystemMessage>
);

JobQuoteApproved.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobQuoteApproved;
