import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import {
  JOB_ITEM_SET_SUB_ITEM_ORDER,
  JOB_ITEM_SUB_ITEM_EDIT
} from "../../../lib/constants";
import { getOrderId } from "../../../lib/entities/jobItemSubItemEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemSubItem } from "../../selectors/jobItemSubItem";
import { selectJobItemSubItemIdsOrderedByJobItemId } from "../../selectors/jobItemSubItem/selectJobItemSubItemIdsOrderedByJobItemId";

function* setSubItemOrder(action) {
  const { jobItemId, orderedSubItemIds } = action.payload;

  try {
    const jobItemSubItemIds = yield select(
      selectJobItemSubItemIdsOrderedByJobItemId,
      {
        jobItemId
      }
    );

    if (jobItemSubItemIds.length !== orderedSubItemIds.length) return;

    const modifiedSubItems = [];
    for (let i = 0; i < jobItemSubItemIds.length; i += 1) {
      const orderedSubItemId = orderedSubItemIds[i];
      const subItemId = jobItemSubItemIds[i];

      // eslint-disable-next-line no-continue
      if (orderedSubItemId === subItemId) continue;

      const subItem = yield select(selectJobItemSubItem, {
        jobItemSubItemId: subItemId
      });
      const orderedSubItem = yield select(selectJobItemSubItem, {
        jobItemSubItemId: orderedSubItemId
      });

      const newSubItem = produce(orderedSubItem, draft => {
        draft.orderId = getOrderId(subItem);
      });

      modifiedSubItems.push({ new: newSubItem, prev: orderedSubItem });
    }

    if (modifiedSubItems.length > 0) {
      yield put(
        createAction(JOB_ITEM_SUB_ITEM_EDIT, {
          jobItemId,
          jobItemSubItems: modifiedSubItems
        })
      );
    }
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSetSubItemOrder() {
  yield takeLatestBy(
    [JOB_ITEM_SET_SUB_ITEM_ORDER],
    setSubItemOrder,
    action => action.payload.id
  );
}
