import { EntityPageHeader } from "st-shared/module";
import { usePermissions } from "st-shared/stores";

import { RoutePages, routerGoBack } from "../../../lib/router";
import { RouteLink } from "../../router/RouteLink";
import { useJobId } from "../context/JobIdContext";
import { ArchiveBar } from "./ArchiveBar";
import { DropdownFlyout } from "./DropdownFlyout/DropdownFlyout";
import { HelpTooltip } from "./HelpTooltip/HelpTooltip";
import {
  buttonsWrapperCss,
  helpTooltipCss,
  metricsMenusCss,
  pageCallout,
  pageCalloutLink,
} from "./JobHeader.css";
import { PageTitle } from "./PageTitle";
import { SecondaryMenu } from "./SecondaryMenu/SecondaryMenu";
import { UsedPlannedMetrics } from "./UsedPlannedMetrics";

export function JobHeader() {
  function onBackClick() {
    routerGoBack({
      route: RoutePages.Jobs,
    });
  }
  const jobId = useJobId();
  const { canAccessNewJobPage } = usePermissions();

  function renderPageCallout() {
    if (!canAccessNewJobPage) return null;

    // comment out if needing to work on in dev mode
    if (import.meta.env.DEV) return null;

    return (
      <div className={pageCallout}>
        🎉 To take a sneak peek of the new job page,{" "}
        <RouteLink
          className={pageCalloutLink}
          route={RoutePages.JobsBeta}
          entityId={jobId}
        >
          click here!
        </RouteLink>{" "}
        🎉
      </div>
    );
  }

  return (
    <>
      {renderPageCallout()}
      <EntityPageHeader title={<PageTitle />} onBackClick={onBackClick}>
        <div className={metricsMenusCss}>
          <UsedPlannedMetrics />

          <div className={buttonsWrapperCss}>
            <HelpTooltip className={helpTooltipCss} />

            <DropdownFlyout />

            <SecondaryMenu />
          </div>
        </div>
      </EntityPageHeader>
      <ArchiveBar />
    </>
  );
}
