import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  PrimaryUserDisplayName,
  primaryUserDisplayNameCss,
  UserIcon,
} from "st-shared/module";
import styled from "styled-components";

import { userEntityType } from "../../../lib/entities/userEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";
import { selectUserById } from "../../../redux/selectors/userSelectors";
import { IconButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import ArrowDownwardIcon from "../../elements/Icons/ArrowDownwardIcon";
import ArrowUpwardIcon from "../../elements/Icons/ArrowUpwardIcon";
import ClearIcon from "../../elements/Icons/ClearIcon";
import OpenInNewIcon from "../../elements/Icons/OpenInNewIcon";
import UnfoldLessIcon from "../../elements/Icons/UnfoldLessIcon";
import UnfoldMoreIcon from "../../elements/Icons/UnfoldMoreIcon";

const mapState = (state, props) => ({
  user: selectUserById(state, props),
  isCollapsed: selectIsScheduleUserCollapsed(state, props),
});

const mapDispatch = null;

class ScheduleUserTodoGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    user: userEntityType.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    onClearUser: PropTypes.func.isRequired,
    onCollapseUser: PropTypes.func.isRequired,
    onExpandUser: PropTypes.func.isRequired,
    onSortUserUpward: PropTypes.func,
    onSortUserDownward: PropTypes.func,
    hideActions: PropTypes.bool,
  };

  static defaultProps = {
    onSortUserUpward: null,
    onSortUserDownward: null,
    hideActions: false,
  };

  onClickClear = (e) => {
    const { id, onClearUser } = this.props;
    onClearUser(e, id);
  };

  onClickArrowUpward = (e) => {
    const { id, onSortUserUpward } = this.props;
    onSortUserUpward(e, id);
  };

  onClickArrowDownward = (e) => {
    const { id, onSortUserDownward } = this.props;
    onSortUserDownward(e, id);
  };

  onClickUnfoldLess = (e) => {
    const { id, onCollapseUser } = this.props;

    onCollapseUser(e, id);
  };

  onClickUnfoldMore = (e) => {
    const { id, onExpandUser } = this.props;
    onExpandUser(e, id);
  };

  onClickUnfold = (e) => {
    const { isCollapsed } = this.props;
    if (isCollapsed) this.onClickUnfoldMore(e);
    else this.onClickUnfoldLess(e);
  };

  render() {
    const {
      user,
      isCollapsed,
      onSortUserUpward,
      onSortUserDownward,
      hideActions,
    } = this.props;

    return (
      <Wrapper className={isCollapsed && "collapsed"}>
        <AvatarWrapper>
          <UserIcon id={user.id} name={user.displayName} disableTooltip />
        </AvatarWrapper>
        <NameWrapper>
          {!hideActions && (
            <ActionsRow>
              <Action title="Hide user" onClick={this.onClickClear}>
                <ClearIcon />
              </Action>
              <Action
                title="Move user up"
                onClick={this.onClickArrowUpward}
                disabled={!onSortUserUpward}
              >
                <ArrowUpwardIcon />
              </Action>
              <Action
                title="Move user down"
                onClick={this.onClickArrowDownward}
                disabled={!onSortUserDownward}
              >
                <ArrowDownwardIcon />
              </Action>
              <Action title="Expand/Collapse user" onClick={this.onClickUnfold}>
                {isCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
              </Action>
              <ActionAnchor
                href={`/#todo?u=${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
                title="View user’s To Do screen"
              >
                <Action>
                  <OpenInNewIcon />
                </Action>
              </ActionAnchor>
            </ActionsRow>
          )}
          <NameRow>
            <PrimaryUserDisplayName userId={user.id} />
          </NameRow>
        </NameWrapper>
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserTodoGroup);

const AvatarWrapper = styled.div`
  width: 50px;
  padding: 10px;
`;

const NameWrapper = styled.div`
  width: calc(100% - 50px);
  border-bottom: var(--border-thin-bright);
  overflow: hidden;
`;

const NameRow = styled(Flex)`
  display: flex;
  align-items: center;
  padding: 16px 10px 13px 0;
  .${primaryUserDisplayNameCss} {
    width: 100%;
    font-weight: 700;
  }
`;

const ActionsRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: -50px;
  transition: margin-top 0.2s;
  padding: 10px 12px 10px 0;
  justify-content: flex-end;
`;

const ActionAnchor = styled.a`
  height: 30px;
`;

const Action = styled(IconButton)`
  margin-left: 10px;
  color: var(--color-gray);
  &:hover {
    color: var(--color-charcoal);
  }
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.1);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: white;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    ${ActionsRow} {
      margin-top: 0;
    }
  }
  &.collapsed {
    ${NameWrapper} {
      border-bottom: none;
    }
  }
`;
