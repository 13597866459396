import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPermissionsSnapshot } from "st-shared/stores";

import { NUMBER_FORMAT_ABBREVIATED } from "../../../../lib/constants";
import {
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
  getTotalRemainingMinutes,
  getTotalRemainingMoney,
  getTotalScheduledMinutes,
  getTotalScheduledMoney,
  getTotalUsedMinutes,
  getTotalUsedMoney,
  getTotalUsedScheduledMinutes,
  getTotalUsedScheduledMoney,
  scheduleSearchResultEntityType,
} from "../../../../lib/entities/scheduleSearchResultEntity";
import { selectJobListModel } from "../../../../state/entities/jobListModel/selectors/selectJobListModel";
import { selectScheduleUiScheduleTotalsMode } from "../../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiTotalsModes } from "../../../../state/ui/schedule/types";
import JobCurrency from "../../../modules/Currency/JobCurrency";
import Tooltip from "../../../modules/Tooltip";
import TooltipContentTable from "../../../modules/Tooltip/TooltipContentTable";
import {
  formatTotalHours,
  getTooltipExchangeRateInfo,
  TotalText,
} from "./index";

const mapState = (state, props) => ({
  scheduleSearchResult: selectJobListModel(state, { jobId: props.id }),
  scheduleTotalsMode: selectScheduleUiScheduleTotalsMode(state),
  canViewJobFinancials: getPermissionsSnapshot().canViewJobFinancials,
});

const JobTotals = ({
  scheduleSearchResult,
  scheduleTotalsMode,
  canViewJobFinancials,
}) => {
  const { id } = scheduleSearchResult;
  const footer = getTooltipExchangeRateInfo(id);
  const usedScheduledHours = getTotalUsedScheduledMinutes(scheduleSearchResult);
  const plannedHours = getTotalPlannedMinutes(scheduleSearchResult);
  const scheduledHours = getTotalScheduledMinutes(scheduleSearchResult);
  const usedHours = getTotalUsedMinutes(scheduleSearchResult);
  const remainingHours = getTotalRemainingMinutes(scheduleSearchResult);
  const usedScheduledMoney = getTotalUsedScheduledMoney(scheduleSearchResult);
  const plannedMoney = getTotalPlannedMoney(scheduleSearchResult);
  const scheduledMoney = getTotalScheduledMoney(scheduleSearchResult);
  const usedMoney = getTotalUsedMoney(scheduleSearchResult);
  const remainingMoney = getTotalRemainingMoney(scheduleSearchResult);
  const tooltipContentArray = [
    [
      "Planned",
      formatTotalHours(plannedHours),
      <JobCurrency id={id} value={plannedMoney} convertToHomeCurrency />,
      <JobCurrency id={id} value={plannedMoney} hideHomeCurrency />,
    ],
    [
      "Used",
      formatTotalHours(usedHours),
      <JobCurrency id={id} value={usedMoney} convertToHomeCurrency />,
      <JobCurrency id={id} value={usedMoney} hideHomeCurrency />,
    ],
    [
      "Scheduled",
      formatTotalHours(scheduledHours),
      <JobCurrency id={id} value={scheduledMoney} convertToHomeCurrency />,
      <JobCurrency id={id} value={scheduledMoney} hideHomeCurrency />,
    ],
    [
      "Used+Scheduled",
      formatTotalHours(usedScheduledHours),
      <JobCurrency id={id} value={usedScheduledMoney} convertToHomeCurrency />,
      <JobCurrency id={id} value={usedScheduledMoney} hideHomeCurrency />,
    ],
    [
      "Remaining",
      formatTotalHours(remainingHours),
      <JobCurrency id={id} value={remainingMoney} convertToHomeCurrency />,
      <JobCurrency id={id} value={remainingMoney} hideHomeCurrency />,
    ],
  ];

  if (scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedHours)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There are <b>{formatTotalHours(usedScheduledHours)}</b> of
                logged and incomplete To Do’s
                <br />
                out of <b>{formatTotalHours(plannedHours)}</b> planned
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={usedScheduledHours > plannedHours}>
          {formatTotalHours(usedScheduledHours, true)} /{" "}
          {formatTotalHours(plannedHours, true)}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingHours)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There are <b>{formatTotalHours(scheduledHours)}</b> of
                incomplete To Do’s
                <br />
                out of <b>{formatTotalHours(remainingHours)}</b> remaining
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={remainingHours < 0 || scheduledHours > remainingHours}>
          {formatTotalHours(scheduledHours, true)} /{" "}
          {formatTotalHours(remainingHours, true)}
        </TotalText>
      </Tooltip>
    );

  if (
    scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedMoney &&
    canViewJobFinancials
  )
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={id}
                    value={usedScheduledMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                of sell value on incomplete and logged To Do’s
                <br />
                out of{" "}
                <b>
                  <JobCurrency
                    id={id}
                    value={plannedMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                planned
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={usedScheduledMoney > plannedMoney}>
          <JobCurrency
            id={id}
            value={usedScheduledMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
          {" / "}
          <JobCurrency
            id={id}
            value={plannedMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
        </TotalText>
      </Tooltip>
    );

  if (
    scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingMoney &&
    canViewJobFinancials
  )
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={id}
                    value={scheduledMoney}
                    convertToHomeCurrency
                    format={NUMBER_FORMAT_ABBREVIATED}
                  />
                </b>{" "}
                of sell value on incomplete To Do’s
                <br />
                out of{" "}
                <b>
                  <JobCurrency
                    id={id}
                    value={remainingMoney}
                    convertToHomeCurrency
                    format={NUMBER_FORMAT_ABBREVIATED}
                  />
                </b>{" "}
                remaining
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={remainingMoney < 0 || scheduledMoney > remainingMoney}>
          <JobCurrency
            id={id}
            value={scheduledMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
          {" / "}
          <JobCurrency
            id={id}
            value={remainingMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
        </TotalText>
      </Tooltip>
    );

  return null;
};

JobTotals.propTypes = {
  scheduleSearchResult: scheduleSearchResultEntityType.isRequired,
  scheduleTotalsMode: PropTypes.oneOf(values(ScheduleUiTotalsModes)).isRequired,
  canViewJobFinancials: PropTypes.bool.isRequired,
};

export default connect(mapState)(JobTotals);
