import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchActivityEntryThreadAPI } from "../../../lib/API/jobActivityAPI";
import {
  ENTITIES_RECEIVED,
  ACTIVITY_ENTRY_THREAD_FETCH_REQUEST,
  ACTIVITY_ENTRY_THREAD_FETCH_FAILED,
  ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectActivityEntryUi } from "../../selectors/activityEntrySelectors";

function* fetchActivityEntryThread(action) {
  try {
    const { id } = action.payload;

    const { fetchedThread } = yield select(selectActivityEntryUi, { id });

    if (!fetchedThread) {
      const activityEntryData = yield call(fetchActivityEntryThreadAPI, id);

      yield put(
        createAction(ENTITIES_RECEIVED, {
          ...activityEntryData
        })
      );
    }

    yield put(
      createAction(ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED, action.payload)
    );
  } catch (error) {
    yield put(
      createAction(ACTIVITY_ENTRY_THREAD_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetchActivityEntryThread() {
  yield takeEvery(
    ACTIVITY_ENTRY_THREAD_FETCH_REQUEST,
    fetchActivityEntryThread
  );
}
