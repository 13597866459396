import MuiMenuItem from "@material-ui/core/MenuItem";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import ListItemIcon from "./ListItemIcon";
import ListItemText from "./ListItemText";
import SubMenuIcon from "./SubMenuIcon";

class MenuItem extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    className: PropTypes.string,
    hasSubMenu: PropTypes.bool,
    isSubMenuOpen: PropTypes.bool
  };

  static defaultProps = {
    hasSubMenu: false,
    isSubMenuOpen: false,
    className: ""
  };

  render() {
    const {
      children,
      className,
      hasSubMenu,
      isSubMenuOpen,
      ...props
    } = this.props;

    const classes = className ? [className] : [];
    if (isSubMenuOpen) classes.push("active");

    return (
      <StyledMuiMenuItem className={classes.join(" ")} {...props}>
        {children}
        {hasSubMenu && <SubMenuIcon />}
      </StyledMuiMenuItem>
    );
  }
}

const StyledMuiMenuItem = styled(MuiMenuItem)`
  background-color: transparent;
  min-height: 40px;
  &.active,
  &:hover {
    background-color: transparent;
    ${ListItemIcon} {
      color: var(--color-charcoal);
    }
    ${ListItemText} {
      color: black;
      text-decoration: underline;
    }
  }
`;

export default MenuItem;
