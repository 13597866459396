import { errorHandler } from "@streamtimefe/error";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      staleTime: Infinity,
      gcTime: Infinity,
      retry: false,
      throwOnError: (query) => {
        errorHandler(query, true);
        return false;
      },
    },
  },
});
