import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  expandDateToWeek,
  getDay,
  getDaysFromMonday,
} from "../../../lib/dates";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectUserWeekUtilisation } from "../../../redux/selectors/scheduleSelectors";
import { selectWorkingHours } from "../../../redux/selectors/userSelectors";
import { selectScheduleUiAvailabilityView } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  ScheduleUi,
  ScheduleUiAvailabilityViews,
} from "../../../state/ui/schedule/types";
import ScheduleUserAvailabilityChunk from "./ScheduleUserAvailabilityChunk";

const mapState = () => {
  return (state, props) => ({
    userWeekUtilisation: selectUserWeekUtilisation(state, props),
    userWorkingHours: selectWorkingHours(state, props),
    availabilityView: selectScheduleUiAvailabilityView(state),
  });
};

class ScheduleUserAvailabilityChunks extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    compactMode: PropTypes.bool,
    date: PropTypes.string.isRequired,
    dayWidth: PropTypes.number.isRequired,
    userWeekUtilisation: PropTypes.arrayOf(PropTypes.number).isRequired,
    userWorkingHours: PropTypes.arrayOf(PropTypes.number).isRequired,
    availabilityView: PropTypes.oneOf(values(ScheduleUiAvailabilityViews))
      .isRequired,
  };

  static defaultProps = {
    compactMode: false,
  };

  getIntensity(date) {
    const { compactMode, userWorkingHours, userWeekUtilisation } = this.props;

    const workingMinutes = userWorkingHours[getDay(date)] * 60;
    const utilisedMinutes = userWeekUtilisation[getDaysFromMonday(date)];

    if (workingMinutes === 0)
      return {
        minutes: 0,
        percent: 0,
        intensity: ScheduleUi.User.AvailabilityLevels[0],
      };

    const minutes = workingMinutes - utilisedMinutes;
    const utilisation = utilisedMinutes / workingMinutes;
    const percent = Math.round(utilisation * 100);

    if (compactMode && utilisedMinutes === 0)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[0],
      };

    if (utilisation < 0.25)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[1],
      };

    if (utilisation < 0.5)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[2],
      };

    if (utilisation < 0.75)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[3],
      };

    if (minutes > 15)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[4],
      };

    if (minutes >= -15)
      return {
        minutes,
        percent,
        intensity: ScheduleUi.User.AvailabilityLevels[5],
      };

    return {
      minutes,
      percent,
      intensity: ScheduleUi.User.AvailabilityLevels[6],
    };
  }

  get availabilityChunks() {
    const { compactMode, date, availabilityView } = this.props;

    const weekdays = expandDateToWeek(date);
    const chunks = [];
    let currentChunk = null;

    weekdays.forEach((weekday) => {
      const { minutes, percent, intensity } = this.getIntensity(weekday);

      const newChunk = {
        date: weekday,
        days: 1,
        minutes,
        intensity,
        percent,
      };

      if (currentChunk) {
        const isSameValue =
          availabilityView === ScheduleUiAvailabilityViews.Hours
            ? newChunk.minutes === currentChunk.minutes
            : newChunk.percent === currentChunk.percent;

        const isSameIntensity = newChunk.intensity === currentChunk.intensity;

        if (isSameIntensity && (compactMode || isSameValue)) {
          currentChunk.days += 1;
          return;
        }
      }

      currentChunk = newChunk;
      chunks.push(currentChunk);
    });

    return chunks;
  }

  render() {
    const { dayWidth, compactMode, availabilityView } = this.props;

    return this.availabilityChunks.map(({ date, ...props }) => (
      <ScheduleUserAvailabilityChunk
        key={date}
        {...props}
        compactMode={compactMode}
        dayWidth={dayWidth}
        availabilityView={availabilityView}
      />
    ));
  }
}

export default connect(mapState)(ScheduleUserAvailabilityChunks);
