import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";

export const useItemRateEntity = createUseEntityStoreEntity(
  EntityClass.ItemRate
);

export const getItemRateEntity = createGetEntityStoreEntity(
  EntityClass.ItemRate
);
