import type { TCurrency } from "@streamtimefe/entities";
import numeral from "numeral";

import type { NumberFormatsType } from "./constants/NumberFormats";
import { NumberFormats } from "./constants/NumberFormats";
import { asDecimal } from "./math";

export function formatCurrency(
  value: number,
  currencySymbol = "$",
  format: NumberFormatsType | string = NumberFormats.FullDefault,
  hideLongSymbols = false
) {
  let prefix = currencySymbol;
  if (hideLongSymbols && currencySymbol.length > 1) prefix = "";

  let negativePrefix = "";
  if (value < 0) {
    negativePrefix = "-";
    value = Math.abs(value);
  }

  return `${negativePrefix}${prefix}${numeral(value).format(format)}`;
}

export function applyNegative(value: number) {
  return numeral(asDecimal(value)).multiply(-1).value();
}

export function applyExchangeRate(value: number, exchangeRate: number) {
  return numeral(asDecimal(value)).multiply(asDecimal(exchangeRate)).value();
}

export function applyInverseExchangeRate(value: number, exchangeRate: number) {
  return numeral(asDecimal(value))
    .multiply(numeral(1).divide(asDecimal(exchangeRate)).value())
    .value();
}

export type FormatCurrencyOptions = {
  value: number;
  hideZeroValues?: boolean;
  hideLongSymbols?: boolean;
  format?: NumberFormatsType | string;
};

export function formatCustomerCurrency(
  customerCurrency: TCurrency,
  { value, hideZeroValues, hideLongSymbols, format }: FormatCurrencyOptions
) {
  if (hideZeroValues && !(value > 0 || value < 0)) return "";

  return formatCurrency(
    value,
    customerCurrency.symbol,
    format,
    hideLongSymbols
  );
}

export type FormatForeignCurrencyOptions = FormatCurrencyOptions & {
  currency?: TCurrency;
  prefixCurrencyCode?: boolean;
};

export function formatForeignCurrency(
  customerCurrency: TCurrency,
  {
    value,
    currency,
    hideZeroValues,
    hideLongSymbols,
    prefixCurrencyCode,
    format,
  }: FormatForeignCurrencyOptions
) {
  if (hideZeroValues && !(value > 0 || value < 0)) return "";

  if (currency === undefined || currency === null) {
    currency = customerCurrency;
  }

  let prefix = "";
  if (prefixCurrencyCode && currency.id !== customerCurrency.id) {
    prefix = `(${currency.id}) `;
  }

  return (
    prefix + formatCurrency(value, currency.symbol, format, hideLongSymbols)
  );
}
