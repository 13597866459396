import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { useExtraHoursTrackedHours } from "../../../redux/selectors/jobDetails/extraHoursTrackedHours";
import { useExtraHoursTrackedRate } from "../../../redux/selectors/jobDetails/extraHoursTrackedRate";
import { useExtraHoursTrackedTotal } from "../../../redux/selectors/jobDetails/extraHoursTrackedTotal";
import { useUnplannedTimeSummary } from "../../../redux/selectors/jobDetails/ui/unplannedTimeSummary";
import { useHasJobItemIdsByJobId } from "../../../redux/selectors/jobItem/hasJobItemIdsByJobId";
import StopwatchIcon from "../../elements/Icons/custom/StopwatchIcon";
import Spacer from "../../elements/Spacer";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobId } from "../context/JobIdContext";
import {
  FullWidthBaseItem,
  LargeItemText,
  TotalLargeItemText,
} from "../styles";
import ExtraHoursTrackedUser from "./ExtraHoursTrackedUser";

const ExtraHoursTracked = () => {
  const jobId = useJobId();
  const hasJobItems = useHasJobItemIdsByJobId(jobId);
  const { canViewJobFinancials } = usePermissions();

  const unplannedTimeSummary = useUnplannedTimeSummary();
  const hours = useExtraHoursTrackedHours();
  const rate = useExtraHoursTrackedRate(jobId);
  const total = useExtraHoursTrackedTotal(jobId);

  return (
    <Container>
      <MainContainer>
        <IconContainer>
          <StopwatchIcon size={22} />
        </IconContainer>
        <NameContainer>
          <Name>{hasJobItems ? "Extra hours tracked" : "Hours tracked"}</Name>
        </NameContainer>
        <LargeItemText width={ItemColumnWidths.Hours}>{hours}</LargeItemText>
        {canViewJobFinancials && (
          <>
            <LargeItemText width={ItemColumnWidths.Rate}>{rate}</LargeItemText>
            <TotalLargeItemText width={ItemColumnWidths.Total}>
              {total}
            </TotalLargeItemText>
          </>
        )}
        <Spacer h={46} w={ItemColumnWidths.Status} />
      </MainContainer>
      <UserContainer>
        {unplannedTimeSummary.map((unplannedTime) => (
          <ExtraHoursTrackedUser
            key={unplannedTime.userId}
            userId={unplannedTime.userId}
            totalMinutes={unplannedTime.totalMinutes}
            jobCurrencyTotalExTax={unplannedTime.jobCurrencyTotalExTax}
          />
        ))}
      </UserContainer>
    </Container>
  );
};

export default ExtraHoursTracked;

const Container = styled.div`
  margin-top: 40px;
`;

const MainContainer = styled(FullWidthBaseItem)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 46px;
  color: black;
  background-color: var(--color-gray-bright);
  & > *:not(:first-child) {
    margin-left: 2px;
  }
`;

const UserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 42px;
  width: calc(100% - 42px);
  & > * {
    margin-top: 2px;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-dark);
`;

const NameContainer = styled.div`
  flex-grow: 1;
  min-width: 33%;
`;

const Name = styled.div`
  color: var(--color-charcoal);
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  padding-left: 20px;
  line-height: 50px;
`;
