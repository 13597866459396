import { createIcon } from "../../icon/Icon";

export const IconStatusCompleted = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12Z"
    />
    <path d="M17.4142 10.4142L16 9L10.9142 14.0858L8.41421 11.5858L7 13L10.9142 16.9142L17.4142 10.4142Z" />
  </>,
  "IconStatusCompleted"
);
