import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import ExpandMoreIcon from "../../elements/Icons/ExpandMoreIcon";

const JumbotronStatField = ({ title, onClick, largeText, children }) => {
  return (
    <Container
      $largeText={largeText}
      onClick={onClick}
      className={onClick !== null ? "clickable" : ""}
    >
      <TitleContainer>
        <Title>{title}</Title>{" "}
        {onClick !== null && (
          <StyledExpandIcon start={270} end={270} size={24} />
        )}
      </TitleContainer>
      {children}
    </Container>
  );
};

JumbotronStatField.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  largeText: PropTypes.bool,
  children: reactNodesType
};

JumbotronStatField.defaultProps = {
  children: null,
  largeText: false,
  onClick: null
};

export default JumbotronStatField;

const Container = styled.div.attrs({ className: "JumbotronStatField" })`
  color: var(--color-charcoal);
  font-size: ${props =>
    props.$largeText
      ? "var(--font-size-even-larger)"
      : "var(--font-size-medium)"};
  font-weight: var(--font-weight-normal);

  &.clickable {
    cursor: pointer;

    &:hover {
      .StyledExpandIcon {
        color: var(--color-charcoal);
      }
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

const Title = styled.div`
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-dark);
  line-height: 26px;
  height: 24px;
  margin-right: 5px;
`;

const StyledExpandIcon = styled(ExpandMoreIcon).attrs({
  className: "StyledExpandIcon"
})`
  color: var(--color-gray);
`;
