export const NumberFormats = {
  FullDefault: "0,0.00",
  OptionalCents: "0,0[.]00",
  OptionalDecimal: "0,0[.]00",
  NoDecimal: "0,0",
  Abbreviated: "0[.]0a",
  TwoDecimals: "0.[00]",
  FourDecimals: "0.[0000]",
  SixDecimals: "0.[000000]",
  Percentage: "0.[0]%",
  OptionalDecimalRaw: "0[.]00",
} as const;

export type NumberFormatsType =
  (typeof NumberFormats)[keyof typeof NumberFormats];
