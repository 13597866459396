import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { SCHEDULE_JOB_EXPAND } from "../../../lib/constants";
import { usePrevious } from "../../../lib/hooks/usePrevious";
import createAction from "../../../redux/helpers/createAction";
import { useJobListModelsFilteredIds } from "../../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIds";
import { isJobs } from "../../../state/ui/schedule/lib";
import { useScheduleUiViewMode } from "../../../state/ui/schedule/selectors/selectScheduleUi";

function SingleJobAutoExpander() {
  useSingleJobAutoExpander();
  return null;
}

export default SingleJobAutoExpander;

function useSingleJobAutoExpander() {
  const viewMode = useScheduleUiViewMode();
  const previousViewMode = usePrevious(viewMode);
  const filteredIds = useJobListModelsFilteredIds();
  const previousFilteredIds = usePrevious(filteredIds);

  const dispatch = useDispatch();

  const openViewModeChange =
    previousViewMode !== undefined &&
    isJobs(viewMode) &&
    !isJobs(previousViewMode) &&
    filteredIds.length === 1;

  const openSearchResultsChange =
    filteredIds.length === 1 &&
    previousFilteredIds !== undefined &&
    previousFilteredIds.length !== 1;

  if (openViewModeChange || openSearchResultsChange) {
    dispatch(createAction(SCHEDULE_JOB_EXPAND, { jobId: filteredIds[0] }));
  }

  useEffect(() => {
    if (isJobs(viewMode) && filteredIds.length === 1) {
      dispatch(createAction(SCHEDULE_JOB_EXPAND, { jobId: filteredIds[0] }));
    }
  }, []);
}
