import {
  type TBranch,
  type TCurrency,
  type TRole,
  type TUser,
  type TValueMatchTypeEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { find, values } from "lodash-es";
import { Fragment, useRef, useState } from "react";

import { RadioButton } from "../../../components";
import type { SearchOption } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { defaultSort } from "../../../lib";
import {
  useActiveBranchOptions,
  useActiveRoleOptions,
  useCurrencyGroupedOptions,
  useCustomerCurrency,
  useGroupedUserOptions,
} from "../../../stores";
import { theme } from "../../../theme";
import type { FilterTypeEntityList, TConditionMatchType } from "../../../types";
import {
  ConditionMatchType,
  FilterGroupTypeProperties,
  getConditionMatchName,
  getFilterName,
} from "../../../types";
import type { SearchMenuHeaderProps } from "../..";
import {
  BranchIconName,
  SearchMenu,
  SearchMenuHeader,
  UserIconName,
} from "../..";
import { RoleIconName } from "../../Role/RoleIconName";
import type {
  FilterDisplayTextProps,
  FilterGroupFilterValue,
  FilterProps,
} from "..";
import {
  boldText,
  entityListEditCss,
  useFilterContext,
  useFilterListener,
} from "..";
import { FilterDisplay } from "./FilterDisplay";

export interface EntityListFilterProps extends FilterProps {}

export function EntityListFilter({
  uuid,
  filterGroup,
  onDelete,
  addFilter,
  deleteFilters,
  setFilterGroupConditionMatch,
  setFilterGroupValueMatch,
}: EntityListFilterProps) {
  const displayRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { filterListHeaderType } = useFilterContext();

  const { filterGroupTypeId, filters, conditionMatchTypeId } = filterGroup;
  const properties = FilterGroupTypeProperties[
    filterGroupTypeId
  ] as FilterTypeEntityList;

  const [valueMatchTypeId, setValueMatchTypeId] = useState<TValueMatchTypeEnum>(
    () => {
      if (filterListHeaderType !== "any/all" && filters.length > 0) {
        return filters[0].valueMatchTypeId;
      }
      return ValueMatchTypeEnum.Equals;
    }
  );

  const filterIndexMap: Record<FilterGroupFilterValue, number> = {};

  filters.forEach(({ value }, index) => {
    filterIndexMap[value] = index;
  });

  useFilterListener(uuid, filterGroup.id, "open", openEntityListMenu);

  function openEntityListMenu() {
    setAnchorEl(displayRef.current);
  }

  function closeEntityListMenu() {
    setAnchorEl(null);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        onDelete(filterGroup.id);
      }
    });
  }

  const [options] = useOptions(properties.optionsType);

  function onPick(key: TEntityId) {
    if (typeof filterIndexMap[key] !== "undefined") {
      deleteFilters(filterGroup.id, [filterIndexMap[key]]);
    } else {
      addFilter(filterGroup.id, {
        value: key,
        valueMatchTypeId,
      });
    }
  }

  function setConditionMatchType(type: TConditionMatchType) {
    setFilterGroupConditionMatch(filterGroup.id, type);
  }

  function setValueMatchType(type: TValueMatchTypeEnum) {
    setValueMatchTypeId(type);
    setFilterGroupValueMatch(filterGroup.id, type);
    if (type === ValueMatchTypeEnum.Equals) {
      setFilterGroupConditionMatch(filterGroup.id, ConditionMatchType.Or);
    } else if (type === ValueMatchTypeEnum.NotEquals) {
      setFilterGroupConditionMatch(filterGroup.id, ConditionMatchType.And);
    }
  }

  function renderOptionHeading(heading: string) {
    return (
      <div key={heading} className={entityListEditCss.groupedOptionHeading}>
        {heading}
      </div>
    );
  }

  function renderUserOption(
    option: SearchOption<TUser>,
    onPick: (key: TEntityId) => void
  ) {
    return (
      <div
        key={option.key}
        className={
          entityListEditCss.searchMenuItem[
            typeof filterIndexMap[option.key] !== "undefined"
              ? "selected"
              : "unselected"
          ]
        }
        onClick={() => onPick(option.key)}
      >
        <UserIconName
          id={option.key}
          size={26}
          showRole
          fontSize={12}
          color={theme.color.charcoal}
        />
      </div>
    );
  }

  function renderRoleOption(
    option: SearchOption<TRole>,
    onPick: (key: TEntityId) => void
  ) {
    return (
      <div
        key={option.key}
        className={
          entityListEditCss.searchMenuItem[
            typeof filterIndexMap[option.key] !== "undefined"
              ? "selected"
              : "unselected"
          ]
        }
        onClick={() => onPick(option.key)}
      >
        <RoleIconName
          id={option.key}
          size={26}
          fontSize={14}
          color={theme.color.charcoal}
        />
      </div>
    );
  }

  function renderBranchOption(
    option: SearchOption<TBranch>,
    onPick: (key: TEntityId) => void
  ) {
    return (
      <div
        key={option.key}
        className={
          entityListEditCss.searchMenuItem[
            typeof filterIndexMap[option.key] !== "undefined"
              ? "selected"
              : "unselected"
          ]
        }
        onClick={() => onPick(option.key)}
      >
        <BranchIconName id={option.key} size={26} fontSize={14} />
      </div>
    );
  }

  function renderCurrencyOption(
    option: SearchOption<TCurrency>,
    onPick: (key: TEntityId) => void
  ) {
    return (
      <div
        key={option.key}
        className={
          entityListEditCss.searchMenuItem[
            typeof filterIndexMap[option.key] !== "undefined"
              ? "selected"
              : "unselected"
          ]
        }
        onClick={() => onPick(option.key)}
      >
        {option.key}
      </div>
    );
  }

  function getRenderOption() {
    switch (properties.optionsType) {
      case "users":
        return renderUserOption;
      case "roles":
        return renderRoleOption;
      case "branches":
        return renderBranchOption;
      case "currencies":
        return renderCurrencyOption;
    }
  }

  return (
    <div>
      <FilterDisplay
        ref={displayRef}
        onClick={openEntityListMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <EntityListFilterDisplayText filterGroup={filterGroup} />
      </FilterDisplay>
      <SearchMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: -10,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        options={options}
        onClose={closeEntityListMenu}
        renderHeader={(props) => (
          <EntityListMatchSearchMenuHeader
            {...props}
            title={properties.title}
            placeholder={properties.searchPlaceholder || "Search by name..."}
            conditionMatchType={conditionMatchTypeId}
            setConditionMatchType={setConditionMatchType}
            valueMatchType={valueMatchTypeId}
            setValueMatchType={setValueMatchType}
          />
        )}
        paperRootClassName={entityListEditCss.searchMenuPaper}
        onPickOption={onPick}
        renderOptionHeading={renderOptionHeading}
        renderOption={getRenderOption()}
      />
    </div>
  );
}

type OptionsType = TUser | TRole | TBranch | TCurrency;

function useOptions(
  optionsType: FilterTypeEntityList["optionsType"]
): [
  SearchOption<OptionsType>[] | Record<string, SearchOption<OptionsType>[]>,
  SearchOption<OptionsType>[],
] {
  const userOptions = useGroupedUserOptions();
  const roleOptions = useActiveRoleOptions();
  const branchOptions = useActiveBranchOptions();
  const currencyOptions = useCurrencyGroupedOptions();

  switch (optionsType) {
    case "users": {
      return [userOptions, values(userOptions).flat()];
    }
    case "roles": {
      return [roleOptions, roleOptions];
    }
    case "branches": {
      return [branchOptions, branchOptions];
    }
    case "currencies": {
      return [currencyOptions, values(currencyOptions).flat()];
    }
  }
}

export function EntityListFilterDisplayText({
  filterGroup,
}: FilterDisplayTextProps) {
  const customerCurrency = useCustomerCurrency();

  const { filterGroupTypeId, filters, conditionMatchTypeId } = filterGroup;
  const properties = FilterGroupTypeProperties[
    filterGroupTypeId
  ] as FilterTypeEntityList;

  const [, optionsArray] = useOptions(properties.optionsType);

  function getOptionText(key: TEntityId) {
    const option = find(optionsArray, { key });
    if (option) {
      return option.value;
    }
    return key;
  }

  function getValueMatchText(valueMatchType: TValueMatchTypeEnum) {
    if (valueMatchType === ValueMatchTypeEnum.Equals) {
      return "is";
    } else if (valueMatchType === ValueMatchTypeEnum.NotEquals) {
      return "is not";
    }
    return "";
  }

  function getDisplayText() {
    const names = filters
      .map((filter) => getOptionText(filter.value as TEntityId))
      .sort(defaultSort);

    return (
      <>
        {filters.length > 0 &&
          ` ${getValueMatchText(filters[0].valueMatchTypeId)} `}
        {names.map((name, index) => (
          <Fragment key={name}>
            {index !== 0 && ` ${getConditionMatchName(conditionMatchTypeId)} `}
            <span className={boldText}>{name}</span>
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      <span className={boldText}>
        {getFilterName(filterGroupTypeId, customerCurrency)}
      </span>
      {getDisplayText()}
    </>
  );
}

interface EntityListSearchMenuHeaderProps extends SearchMenuHeaderProps {
  conditionMatchType: TConditionMatchType;
  setConditionMatchType: (type: TConditionMatchType) => void;
  valueMatchType: TValueMatchTypeEnum;
  setValueMatchType: (type: TValueMatchTypeEnum) => void;
}

function EntityListMatchSearchMenuHeader({
  conditionMatchType,
  setConditionMatchType,
  valueMatchType,
  setValueMatchType,
  ...props
}: EntityListSearchMenuHeaderProps) {
  const { filterListHeaderType } = useFilterContext();

  function renderRadioGroup() {
    switch (filterListHeaderType) {
      case "any/all":
        return (
          <div className={entityListEditCss.searchMenuHeaderRadioGroup}>
            <RadioButton
              checked={conditionMatchType === ConditionMatchType.Or}
              onChange={() => setConditionMatchType(ConditionMatchType.Or)}
              labelProps={{ label: "any of" }}
            />
            <RadioButton
              checked={conditionMatchType === ConditionMatchType.And}
              onChange={() => setConditionMatchType(ConditionMatchType.And)}
              labelProps={{ label: "all of" }}
            />
          </div>
        );
      case "is/not":
        return (
          <div className={entityListEditCss.searchMenuHeaderRadioGroup}>
            <RadioButton
              checked={valueMatchType === ValueMatchTypeEnum.Equals}
              onChange={() => setValueMatchType(ValueMatchTypeEnum.Equals)}
              labelProps={{ label: "is" }}
            />
            <RadioButton
              checked={valueMatchType === ValueMatchTypeEnum.NotEquals}
              onChange={() => setValueMatchType(ValueMatchTypeEnum.NotEquals)}
              labelProps={{ label: "is not" }}
            />
          </div>
        );
      case null:
        return null;
    }
  }

  return (
    <div>
      <SearchMenuHeader {...props} />
      {props.hasOptions && props.hasResults && renderRadioGroup()}
    </div>
  );
}
