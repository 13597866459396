import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import { setSum } from "../../../../lib/objects";
import {
  getIsHeading,
  getTotalAmountExTax,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    const getPurchaseOrderLineItem = (id) => nextState.byId[id];

    touchedPurchaseOrderIds.forEach((purchaseOrderId) => {
      const sortedIds = get(
        nextState.sortedIdsByPurchaseOrderId,
        purchaseOrderId
      );

      if (!sortedIds) {
        unset(draft, `subTotalsExTaxByPurchaseOrderId.${purchaseOrderId}`);
        return;
      }

      const sortedLineItems = sortedIds.map(getPurchaseOrderLineItem);
      const subTotals = {};
      let lastHeadingId = null;

      sortedLineItems.forEach((purchaseOrderLineItem) => {
        if (getIsHeading(purchaseOrderLineItem)) {
          lastHeadingId = purchaseOrderLineItem.id;
          return;
        }

        if (!lastHeadingId) return;

        const lineTotal = asDecimal(getTotalAmountExTax(purchaseOrderLineItem));

        setSum(subTotals, lastHeadingId, lineTotal);
      });

      setWith(
        draft,
        `subTotalsExTaxByPurchaseOrderId.${purchaseOrderId}`,
        subTotals,
        Object
      );
    });
  });
