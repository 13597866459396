import React from "react";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

export default WrappedComponent => props => (
  <ScheduleScrollContext.Consumer>
    {({
      getScrollOffsetX,
      getScrollOffsetY,
      getDateAtOffsetX,
      getDateOnPosition,
      getDayWidth,
      getDaysFromWidth,
      goForward,
      goBackward,
      dayWidth
    }) => (
      <WrappedComponent
        {...{
          ...props,
          getScrollOffsetX,
          getScrollOffsetY,
          getDateAtOffsetX,
          getDateOnPosition,
          getDayWidth,
          getDaysFromWidth,
          goForward,
          goBackward,
          dayWidth
        }}
      />
    )}
  </ScheduleScrollContext.Consumer>
);
