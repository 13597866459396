import type { TEntityClassMap } from "@streamtimefe/entities";
import type { Prettify } from "@streamtimefe/types";

import { createActionStoreContext } from "../../core";
import type { TEntityStoreClass } from "../EntityStore";
import type { TSingleEntityActions } from "./SingleEntityStore.actions";

export type TSingleEntityStore<C extends TEntityStoreClass> = {
  entity: TEntityClassMap[C];
  modified: TEntityClassMap[C] | null;
  updateGlobal: boolean;
};

export type TSingleEntityStoreContext<C extends TEntityStoreClass> = Prettify<
  ReturnType<typeof createSingleEntityStoreContext<C>>
>;

export function createSingleEntityStoreContext<C extends TEntityStoreClass>(
  entityClass: C
) {
  return createActionStoreContext<
    TSingleEntityStore<C>,
    TSingleEntityActions<C>
  >(entityClass);
}
