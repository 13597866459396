import reactStringReplace from "react-string-replace";

import { encodeString } from "../../lib";
import { highlightCss } from "./Highlight.css";

interface Props {
  text: string;
  query: string;
}

export function Highlight({ text, query }: Props) {
  const value = encodeString(text);
  query = encodeString(query);

  if (value && query) {
    query = query.replace(/[\\\-()[\]!^$.{}|?+*]/gi, "\\$&").trim();
    const regEx = new RegExp(`(${query})`, "ig");
    return reactStringReplace(text, regEx, (match, i) => (
      <span key={i} className={highlightCss}>
        {match}
      </span>
    ));
  }

  return text;
}
