import numeral from "numeral";
import { Tooltip } from "st-shared/components";
import { costingMethodObj } from "st-shared/entities";
import { asDecimal, formatForeignCurrency, NumberFormats } from "st-shared/lib";
import { VarianceIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";

import { getRateCardId, isArchived } from "../../../../lib/entities/jobEntity";
import {
  getJobCurrencySellRate,
  getUserId,
} from "../../../../lib/entities/jobItemUserEntity";
import { getName } from "../../../../lib/entities/roleEntity";
import { getRoleId } from "../../../../lib/entities/userEntity";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { useJobItemUser } from "../../../../redux/selectors/jobItemUser";
import { useRateCard } from "../../../../redux/selectors/rateCard";
import { useRole } from "../../../../redux/selectors/role";
import { useUserSellRate } from "../../../../redux/selectors/user/selectUserSellRate";
import { useUser } from "../../../../state/entities/user/selectors/selectUser";
import { useJobId } from "../../context/JobIdContext";
import { useJobItemId } from "../../context/JobItemIdContext";
import { useJobItemUserId } from "../../context/JobItemUserIdContext";
import { tooltipTitleCss, varianceIconAbsoluteCss } from "./styles.css";

export function UserRateVariance() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemUserId = useJobItemUserId();
  const jobItemUser = useJobItemUser(jobItemUserId);
  const jobItem = useJobItem(jobItemId);
  const job = useJob(jobId);
  const roleRate = asDecimal(useUserSellRate(getUserId(jobItemUser), jobId));
  const rateCard = useRateCard(getRateCardId(job));
  const user = useUser(getUserId(jobItemUser));
  const role = useRole(getRoleId(user!));
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const jobCurrencySellRate = asDecimal(getJobCurrencySellRate(jobItemUser));
  const formattedRate = formatForeignCurrency(customerCurrency, {
    value: roleRate,
    hideLongSymbols: true,
    currency: jobCurrency,
  });

  let variance = 0;

  if (
    isArchived(job) ||
    !costingMethodObj(jobItem.costingMethod).isBasedByUser() ||
    jobCurrencySellRate === 0
  ) {
    return null;
  }

  if (roleRate === 0) {
    variance = jobCurrencySellRate;
  } else {
    variance =
      numeral(numeral(jobCurrencySellRate).format(NumberFormats.TwoDecimals))
        .subtract(numeral(roleRate).value())
        .divide(numeral(roleRate).value())
        .value() || 0;
  }

  if (variance === 0) return null;

  const formattedVariance = numeral(Math.abs(variance)).format(
    NumberFormats.Percentage
  );

  const down = variance < 0;

  return (
    <Tooltip
      title={
        <div className={tooltipTitleCss}>
          The rate you’ve set is <strong>{formattedVariance}</strong>{" "}
          {down ? "lower" : "higher"} than{" "}
          {role && (
            <>
              the <strong>{formattedRate}</strong> rate set on the{" "}
              {rateCard.name} rate card for the <strong>{getName(role)}</strong>{" "}
              role
            </>
          )}
          {!role && (
            <>
              {user!.displayName}&apos;s <strong>{formattedRate}</strong>{" "}
              billable rate
            </>
          )}
        </div>
      }
      placement="top"
    >
      <div className={varianceIconAbsoluteCss}>
        <VarianceIcon direction={down ? "down" : "up"} size={9} />
      </div>
    </Tooltip>
  );
}
