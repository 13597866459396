import { Field, Formik } from "formik";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {
  DATE_FORMAT_DATE_FULL_MONTH,
  JOB_TIMELINE_DATE_HEADER_HEIGHT,
} from "../../../lib/constants";
import { toDate } from "../../../lib/dates";
import { jobMilestoneType } from "../../../lib/entities/jobMilestoneEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import Button, { PrimaryButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import Input from "../../elements/Input";
import InlineDatePicker from "../../modules/Pickers/InlineDatePicker";
import Popover from "../../modules/Popover";

class MilestonePopover extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    left: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    selectedMilestone: jobMilestoneType,
  };

  static defaultProps = {
    anchorEl: null,
    selectedMilestone: null,
  };

  onSave = ({ milestoneName, milestoneDate }) => {
    const { selectedMilestone, onSave } = this.props;
    onSave({
      ...selectedMilestone,
      name: milestoneName,
      date: milestoneDate,
    });
  };

  onDelete = () => {
    const { selectedMilestone, onDelete } = this.props;
    onDelete(selectedMilestone.id);
  };

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  get initialValues() {
    const { selectedMilestone } = this.props;
    return {
      milestoneName: selectedMilestone ? selectedMilestone.name : "",
      milestoneDate: selectedMilestone ? selectedMilestone.date : null,
    };
  }

  get isCreating() {
    const { selectedMilestone } = this.props;
    return selectedMilestone && selectedMilestone.id < 0;
  }

  render() {
    const { anchorEl, left, open } = this.props;
    return (
      <Popover
        arrowClass="arrow top"
        style={{ marginTop: 15 }}
        open={open}
        anchorEl={anchorEl}
        onClose={this.onCancel}
        anchorOrigin={{
          vertical: JOB_TIMELINE_DATE_HEADER_HEIGHT + 10,
          horizontal: left,
        }}
      >
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.onSave}
          validationSchema={Yup.object().shape({
            milestoneName: Yup.string().required("Required"),
          })}
          enableReinitialize
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Content>
                <Field name="milestoneName">
                  {({ field }) => (
                    <Input
                      autoFocus
                      maxLength="64"
                      placeholder="Enter a milestone"
                      {...field}
                    />
                  )}
                </Field>
              </Content>
              <Footer>
                <Field name="milestoneDate">
                  {({ field, form }) => (
                    <InlineDatePicker
                      {...field}
                      format={DATE_FORMAT_DATE_FULL_MONTH}
                      onChange={(moment) =>
                        form.setFieldValue("milestoneDate", toDate(moment))
                      }
                    />
                  )}
                </Field>
                {this.isCreating ? (
                  <Button onClick={this.onCancel}>Cancel</Button>
                ) : (
                  <Button onClick={this.onDelete}>Delete</Button>
                )}
                <PrimaryButton
                  disabled={!values.milestoneName}
                  type="submit"
                  style={{ width: 100 }}
                >
                  Save
                </PrimaryButton>
              </Footer>
            </form>
          )}
        </Formik>
      </Popover>
    );
  }
}

export default MilestonePopover;

const Content = styled.div`
  padding: 15px 15px 5px;
`;

const Footer = styled(Flex)`
  height: 60px;
  padding: 15px;
  justify-content: space-between;
  > * {
    margin: 0 5px;
  }
`;
