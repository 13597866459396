import type {
  TSavedSegmentTemplate,
  TSavedSegmentTypeEnum,
} from "@streamtimefe/entities";

import { API } from "../api";
import type { ApiConfig } from "../api/APIController";
import { WebApiRoot } from ".";

export const SavedSegmentsTemplatesRoot =
  WebApiRoot + "/saved_segment_templates";

export function getEntitites(
  savedSegmentTypes?: TSavedSegmentTypeEnum[],
  config?: ApiConfig
) {
  return API.get<TSavedSegmentTemplate[]>(
    SavedSegmentsTemplatesRoot,
    {
      saved_segment_type_ids: JSON.stringify(savedSegmentTypes),
    },
    config
  );
}
