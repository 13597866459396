import * as PropTypes from "prop-types";

export const domNodeType = PropTypes.instanceOf(Element);

export const coordinateType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
});

export const cssStyleType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);
