import {
  allOptionsStoreInit,
  authenticationStoreInit,
  branchEntityStoreInit,
  companyOptionsStoreInit,
  contactOptionsStoreInit,
  currencyEntityStoreInit,
  itemOptionsStoreInit,
  jobOptionsStoreInit,
  jobPhaseOptionsStoreInit,
  labelOptionsStoreInit,
  organisationEntityStoreInit,
  rateCardEntityStoreInit,
  roleEntityStoreInit,
  savedSegmentEntityStore,
  savedSegmentTemplateEntityStore,
  userEntityStoreInit,
  userPreferenceEntityStoreInit,
} from "st-shared/stores";
import { setSagaErrorFunction } from "st-shared/stores/sagaHelpers";

import { sagaError } from "../redux/helpers/sagaErrorHandlers";

authenticationStoreInit();

userEntityStoreInit();
currencyEntityStoreInit();
roleEntityStoreInit();
userPreferenceEntityStoreInit();
organisationEntityStoreInit();
branchEntityStoreInit();
rateCardEntityStoreInit();
savedSegmentEntityStore.init();
savedSegmentTemplateEntityStore.init();

allOptionsStoreInit();
companyOptionsStoreInit();
contactOptionsStoreInit();
itemOptionsStoreInit();
jobOptionsStoreInit();
jobPhaseOptionsStoreInit();
labelOptionsStoreInit();

setSagaErrorFunction(sagaError);
