import { get } from "lodash-es";
import { DataException } from "st-shared/lib";

import { getScheduleBlockKey } from "../../../../lib/entities/scheduleLoggedTimeEntity";

export default (nextState, changedEntities) => {
  changedEntities.forEach(({ newEntity }) => {
    const blockKey = newEntity && getScheduleBlockKey(newEntity);
    const baseScheduleLoggedTime =
      blockKey && get(nextState, `byId.${blockKey}`);
    if (blockKey && !baseScheduleLoggedTime)
      throw new DataException(
        `Schedule Block Base ScheduleLoggedTime (${blockKey}) for ScheduleLoggedTime (${newEntity.id}) not found`
      );
  });
};
