import { useEffect } from "react";

import { addKeyEventListener, removeKeyEventListener } from "../events";

export function useKeyEvent(prerequisite, type, func, ...deps) {
  useEffect(() => {
    if (prerequisite) addKeyEventListener(type, func);
    return () => removeKeyEventListener(type, func);
  }, [prerequisite, ...deps]);
}
