import { Button } from "st-shared/components";
import { JobStatuses } from "st-shared/entities/JobStatus";

import { jobPageOpenRestoreJobModal } from "../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../redux/selectors/job";
import { useJobId } from "../context/JobIdContext";
import {
  buttonCss,
  containerCss,
  textButtonWrapperCss,
} from "./ArchiveBar.css";

export function ArchiveBar() {
  const jobId = useJobId();
  const job = useJob(jobId);

  if (job.jobStatus.id !== JobStatuses.Archived) {
    return null;
  }

  return (
    <div className={containerCss}>
      <div className={textButtonWrapperCss}>
        <span>This job has been archived.</span>
        <Button
          variant="link"
          className={buttonCss}
          onClick={() => jobPageOpenRestoreJobModal(job)}
        >
          Restore job
        </Button>
      </div>
    </div>
  );
}
