import { createScrollContext } from "./ScrollContext";

export const JobTimelineScrollContext = createScrollContext();
export const ScheduleScrollContext = createScrollContext();

// TODO: get rid of this as it is only used for proptypes
export const SCROLL_CONTEXTS = [
  JobTimelineScrollContext,
  ScheduleScrollContext,
];
