import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type TTeamMemberSelectPopoverContext = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const TeamMemberSelectPopoverContext =
  createContext<TTeamMemberSelectPopoverContext>(
    undefined as unknown as TTeamMemberSelectPopoverContext
  );

export function useTeamMemberSelectPopoverContext() {
  const context = useContext(TeamMemberSelectPopoverContext);
  if (!context) {
    throw new Error(`Missing TeamMemberSelectPopoverContext`);
  }
  return context;
}

export function useOpenTeamMemberSelectPopover() {
  const { onOpenChange } = useTeamMemberSelectPopoverContext();
  return useCallback(() => {
    onOpenChange(true);
  }, [onOpenChange]);
}

export function TeamMemberSelectPopoverProvider({
  children,
}: PropsWithChildren) {
  const [isOpen, onOpenChange] = useState(false);

  const context = useMemo<TTeamMemberSelectPopoverContext>(
    () => ({
      isOpen,
      onOpenChange,
    }),
    [isOpen, onOpenChange]
  );

  return (
    <TeamMemberSelectPopoverContext.Provider value={context}>
      {children}
    </TeamMemberSelectPopoverContext.Provider>
  );
}
