import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@material-ui/core/Popover";

export interface PopoverProps extends MuiPopoverProps {
  onClose: (...args: any) => void;
}

export function Popover({
  children,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  PaperProps,
  ...props
}: PopoverProps) {
  return (
    <MuiPopover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        square: true,
        ...PaperProps,
      }}
      {...props}
    >
      {children}
    </MuiPopover>
  );
}
