import styled from "styled-components";
import { JOB_TIMELINE_GROUP_COLUMN_WIDTH } from "../../lib/constants";

const JobTimelineBodyColumnCell = styled.div`
  position: relative;
  width: calc(100% - ${JOB_TIMELINE_GROUP_COLUMN_WIDTH}px);
  height: 100%;
`;

export default JobTimelineBodyColumnCell;
