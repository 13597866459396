import numeral from "numeral";
import * as PropTypes from "prop-types";

import { addDays } from "../dates";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import { jobMilestoneType } from "./jobMilestoneEntity";

export const scheduleSearchResultEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  branch: PropTypes.string,
  budget: entityFieldDecimalType,
  companyName: PropTypes.string.isRequired,
  contact: PropTypes.string,
  estimatedEndDate: PropTypes.string,
  estimatedStartDate: PropTypes.string,
  earliestStartDate: PropTypes.string,
  latestEndDate: PropTypes.string,
  rateCardId: PropTypes.number.isRequired,
  jobExchangeRate: entityFieldDecimalType.isRequired,
  jobFullName: PropTypes.string.isRequired,
  jobName: PropTypes.string.isRequired,
  jobNumber: PropTypes.string,
  jobStatus: PropTypes.string.isRequired,
  jobStatusId: PropTypes.number.isRequired,
  totalIncompleteMinutes: entityFieldDecimalType,
  totalLoggedMinutes: entityFieldDecimalType,
  totalPlannedMinutes: entityFieldDecimalType,
  jobCurrencyTotalIncompleteTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalPlannedTimeExTax: entityFieldDecimalType,
  users: PropTypes.arrayOf(typeEntityType).isRequired,
  jobMilestones: PropTypes.arrayOf(jobMilestoneType).isRequired,
});

export const getJobFullName = ({ jobFullName }) => jobFullName;

export const getCompanyName = ({ companyName }) => companyName;

export const getJobStatusId = ({ jobStatusId }) => jobStatusId;

export const getTotalUsedScheduledMinutes = ({
  totalIncompleteMinutes,
  totalLoggedMinutes,
}) =>
  numeral(asDecimal(totalIncompleteMinutes))
    .add(asDecimal(totalLoggedMinutes))
    .value();

export const getTotalPlannedMinutes = ({ totalPlannedMinutes }) =>
  asDecimal(totalPlannedMinutes);

export const getTotalScheduledMinutes = ({ totalIncompleteMinutes }) =>
  asDecimal(totalIncompleteMinutes);

export const getTotalUsedMinutes = ({ totalLoggedMinutes }) =>
  asDecimal(totalLoggedMinutes);

export const getTotalRemainingMinutes = ({
  totalLoggedMinutes,
  totalPlannedMinutes,
}) => asDecimal(totalPlannedMinutes) - asDecimal(totalLoggedMinutes);

export const getTotalUsedScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalIncompleteTimeExTax))
    .add(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const getTotalPlannedMoney = ({ jobCurrencyTotalPlannedTimeExTax }) =>
  asDecimal(jobCurrencyTotalPlannedTimeExTax);

export const getTotalScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
}) => asDecimal(jobCurrencyTotalIncompleteTimeExTax);

export const getTotalUsedMoney = ({ jobCurrencyTotalLoggedTimeExTax }) =>
  asDecimal(jobCurrencyTotalLoggedTimeExTax);

export const getTotalRemainingMoney = ({
  jobCurrencyTotalPlannedTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalPlannedTimeExTax))
    .subtract(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const moveDays = (entity, days) => {
  const estimatedStartDate =
    entity.estimatedStartDate && addDays(entity.estimatedStartDate, days);
  const estimatedEndDate =
    entity.estimatedEndDate && addDays(entity.estimatedEndDate, days);

  return {
    ...entity,
    estimatedStartDate,
    estimatedEndDate,
  };
};
