import { produce } from "immer";
import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";

import { deleteJobPhaseAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
  JOB_PHASE_DELETE,
  JOB_PHASE_DELETE_ERROR,
  JOB_PHASE_DELETED,
  JOB_PHASE_DELETING,
  JOB_PHASE_NEW_PHASE_DELETE,
} from "../../../lib/constants";
import { getTotalUsedMinutes } from "../../../lib/entities/jobItemEntity";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import { feToWebSendDeleteJobPhase } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";
import { selectAllJobItemDependanciesByJobItemId } from "../../selectors/jobItemDependancySelectors";
import {
  selectJobItemById,
  selectJobItemOrderByJobIdPhaseId,
} from "../../selectors/jobItemSelectors";
import { selectJobPhase } from "../../selectors/jobPhase";
import { selectJobPhaseOrderByJobId } from "../../selectors/jobPhase/selectJobPhaseOrderByJobId";

function* deleteJobPhase(action) {
  const { jobPhase } = action.payload;

  const jobPhaseId = jobPhase.id;
  const jobId = jobPhase.jobId;

  let replacementJobPhaseId = null;
  let replacementJobPhaseName = null;
  let jobItemIds = [];

  try {
    jobItemIds = yield select(selectJobItemOrderByJobIdPhaseId, {
      jobId,
      id: jobPhaseId,
    });

    const orderJobPhases = yield select(selectJobPhaseOrderByJobId, {
      jobId,
    });
    const index = orderJobPhases.indexOf(jobPhaseId);
    if (index > 0) {
      replacementJobPhaseId = orderJobPhases[index - 1];

      const replacementJobPhase = yield select(selectJobPhase, {
        jobPhaseId: replacementJobPhaseId,
      });

      replacementJobPhaseName = getJobPhaseName(replacementJobPhase);
    }

    if (jobItemIds.length > 0) {
      let hasLoggedTime = false;
      let hasDependencies = false;

      for (let i = 0; i < jobItemIds.length; i += 1) {
        if (!hasLoggedTime) {
          const jobItem = yield select(selectJobItem, {
            jobItemId: jobItemIds[i],
          });

          if (getTotalUsedMinutes(jobItem) > 0) {
            hasLoggedTime = true;
          }
        }

        if (!hasDependencies) {
          const jobItemDependancies = yield select(
            selectAllJobItemDependanciesByJobItemId,
            {
              jobItemId: jobItemIds[i],
            }
          );

          if (jobItemDependancies.filter(({ id }) => id > 0).length > 0) {
            hasDependencies = true;
          }
        }
      }

      feToWebSendDeleteJobPhase(
        jobPhaseId,
        replacementJobPhaseId,
        replacementJobPhaseName,
        jobItemIds.length,
        hasLoggedTime,
        hasDependencies
      );
    } else {
      yield put(
        createAction(JOB_PHASE_DELETING, {
          jobPhaseId,
          jobPhase,
        })
      );

      const { data } = yield call(deleteJobPhaseAPI, jobPhaseId);

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));

      yield put(
        createAction(JOB_PHASE_DELETED, {
          jobPhaseId,
        })
      );
    }
  } catch (error) {
    if (jobItemIds.length === 0) {
      yield put(
        createAction(JOB_PHASE_DELETE_ERROR, {
          jobPhaseId,
          jobPhase,
          sagaType: action.type,
          error,
        })
      );
    }

    if (isError(error)) sagaError(error);
  }
}

function* deleteNewJobPhase(action) {
  const { jobPhase } = action.payload;

  const jobPhaseId = jobPhase.id;
  const jobId = jobPhase.jobId;

  let replacementJobPhaseId = null;

  try {
    const orderJobPhases = yield select(selectJobPhaseOrderByJobId, {
      jobId,
    });
    const index = orderJobPhases.indexOf(jobPhaseId);
    if (index > 0) {
      replacementJobPhaseId = orderJobPhases[index - 1];
    }
    const jobItemIds = yield select(selectJobItemOrderByJobIdPhaseId, {
      jobId,
      id: jobPhaseId,
    });

    yield put(
      createAction(ENTITIES_REMOVED, {
        entityName: ENTITY_NAME_JOB_PHASES,
        ids: [jobPhaseId],
      })
    );

    yield setJobItemsWithJobPhaseId(replacementJobPhaseId, jobItemIds);
  } catch (error) {
    sagaError(error);
  }
}

function* setJobItemsWithJobPhaseId(jobPhaseId, jobItemIds) {
  const modifiedJobItems = [];
  for (let i = 0; i < jobItemIds.length; i += 1) {
    const jobItemId = jobItemIds[i];
    const jobItem = yield select(selectJobItemById, { id: jobItemId });
    const newJobItem = produce(jobItem, (draft) => {
      draft.jobPhaseId = jobPhaseId;
    });
    modifiedJobItems.push(newJobItem);
  }

  if (modifiedJobItems.length === 0) return;

  yield put(
    createAction(ENTITIES_RECEIVED, {
      [ENTITY_NAME_JOB_ITEMS]: modifiedJobItems,
    })
  );
}

export function* watchJobPhaseDelete() {
  yield takeLatestBy(
    [JOB_PHASE_DELETE],
    deleteJobPhase,
    (action) => action.payload.jobPhase.id
  );
}

export function* watchJobPhaseDeleteNew() {
  yield takeLatestBy(
    [JOB_PHASE_NEW_PHASE_DELETE],
    deleteNewJobPhase,
    (action) => action.payload.jobPhase.id
  );
}
