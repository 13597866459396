import type {
  TApplicationEnvironment,
  TOrganisation,
  TUser,
} from "@streamtimefe/entities";

import { createActionStoreContext } from "../core";
import type { TAuthActions } from "./AuthStore.actions";

export const AuthStore = createActionStoreContext<TAuthStore, TAuthActions>(
  "Auth"
);

export type TAuthStore = {
  isAdminUser: boolean;
  user: TUser;
  organisation: TOrganisation;
  applicationEnvironment: TApplicationEnvironment;
  pusherChannelPrefix: string;
};
