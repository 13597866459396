import { put, select, takeLatest } from "redux-saga/effects";
import { INVOICE_SAVE, INVOICE_SET_ADDRESS } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectAddressById } from "../../selectors/addressSelectors";
import { selectInvoiceById } from "../../selectors/invoiceSelectors";

function* setAddress(action) {
  try {
    const { id, addressId } = action.payload;

    const invoice = yield select(selectInvoiceById, { id });

    const address = yield select(selectAddressById, { id: addressId });

    yield put(
      createAction(INVOICE_SAVE, {
        id,
        prevInvoice: invoice,
        invoice: {
          ...invoice,
          address
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchInvoiceSetAddress() {
  yield takeLatest(INVOICE_SET_ADDRESS, setAddress);
}
