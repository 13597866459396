import isError from "lodash-es/isError";
import { call, put } from "redux-saga/effects";

import { deleteJobItemUserAPI } from "../../../lib/API/jobItemUserAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEMS,
  JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY,
  JOB_ITEM_USER_DELETE,
  JOB_ITEM_USER_DELETE_CONFIRM,
  JOB_ITEM_USER_DELETE_ERROR,
  JOB_ITEM_USER_DELETED,
  JOB_ITEM_USER_DELETING,
} from "../../../lib/constants";
import {
  getTotalScheduledMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemUserEntity";
import { feToWebConfirmDeleteJobItemUser } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* deleteJobItemUser(action) {
  const { jobItemUser } = action.payload;

  const jobItemUserId = jobItemUser.id;

  if (jobItemUserId <= 0) return;

  try {
    yield put(
      createAction(JOB_ITEM_USER_DELETING, {
        jobItemUserId,
      })
    );

    const { data } = yield call(deleteJobItemUserAPI, jobItemUserId);

    if (data[ENTITY_NAME_JOB_ITEMS]) {
      delete data[ENTITY_NAME_JOB_ITEMS];
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_USER_DELETED, {
        jobItemUserId,
      })
    );

    yield put(
      createAction(JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY, {
        jobItemUser,
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_USER_DELETE_ERROR, {
        jobItemUserId,
        jobItemUser,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

function* deleteJobItemUserConfirm(action) {
  const { jobItemUser } = action.payload;

  try {
    if (
      getTotalUsedMinutes(jobItemUser) > 0 ||
      getTotalScheduledMinutes(jobItemUser) > 0
    ) {
      yield call(feToWebConfirmDeleteJobItemUser);
    }

    yield put(createAction(JOB_ITEM_USER_DELETE, action.payload));
  } catch (error) {
    if (isError(error)) sagaError(error);
  }
}

export default function* watchJobItemUserDelete() {
  yield takeLatestBy(
    JOB_ITEM_USER_DELETE_CONFIRM,
    deleteJobItemUserConfirm,
    (action) => action.payload.jobItemUser.id
  );
  yield takeLatestBy(
    JOB_ITEM_USER_DELETE,
    deleteJobItemUser,
    (action) => action.payload.jobItemUser.id
  );
}
