import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const DuplicateIcon = ({ ...props }) => (
  <SvgIcon viewBox="-3 -3 24 24" {...props}>
    <path d="M16.5 4.5v12h-12v-12h12zM18 3H3v15h15V3zM0 15.75V0h15.75v1.5H1.5v14.25H0zm13.5-6h-2.25V7.5h-1.5v2.25H7.5v1.5h2.25v2.25h1.5v-2.25h2.25v-1.5z" />
  </SvgIcon>
);

DuplicateIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

DuplicateIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(DuplicateIcon);
