import { forwardRef } from "react";
import styled from "styled-components";

import {
  useActivityEntriesByJobId,
  useActivityEntryDatesByJobId,
  useHasCommentsOrPosts,
} from "../../../../redux/selectors/jobActivitySelectors";
import { useJobActivitySystemMessagesFilter } from "../../../../redux/selectors/userPreferenceSelectors";
import { useJobId } from "../../context/JobIdContext";
import JobActivityDateEntries from "./JobActivityDateEntries";
import { JobActivityIntroduction } from "./JobActivityIntroduction";
import JobActivityNoPosts from "./JobActivityNoPosts";
import JobActivityTooManyFilters from "./JobActivityTooManyFilters";

const JobActivityEntries = forwardRef((props, ref) => {
  const jobId = useJobId();
  const jobActivityDates = useActivityEntryDatesByJobId(jobId);
  const hasCommentsOrPosts = useHasCommentsOrPosts(jobId);
  const isSystemMessagesFilterActive = useJobActivitySystemMessagesFilter();

  const jobActivityIds = useActivityEntriesByJobId(jobId);

  const getEmptyState = () => {
    if (!hasCommentsOrPosts && !isSystemMessagesFilterActive) {
      return <JobActivityNoPosts />;
    }
    if (jobActivityIds.length === 0) {
      return <JobActivityTooManyFilters />;
    }
    if (jobActivityIds.length === 1 && isSystemMessagesFilterActive) {
      return <JobActivityIntroduction />;
    }
    return null;
  };

  return (
    <Container ref={ref}>
      {jobActivityDates.map((date) => (
        <JobActivityDateEntries key={date} date={date} />
      ))}
      {getEmptyState()}
    </Container>
  );
});

JobActivityEntries.displayName = "JobActivityEntries";

export default JobActivityEntries;

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  padding: 30px 0 10px;
  flex-direction: column-reverse;
  overflow: auto;
`;
