// deprecated

import type { Option } from "./Option";

export const QuoteStatuses = {
  PendingApproval: 1,
  Approved: 2,
  Declined: 3,
  Deleted: 4,
  Draft: 5,
} as const;

export type QuoteStatusesType =
  (typeof QuoteStatuses)[keyof typeof QuoteStatuses];

export type Type = QuoteStatus;
export interface QuoteStatus extends Option {
  id: QuoteStatusesType;
}

export function isPendingApproval(entity: QuoteStatus) {
  return entity.id === QuoteStatuses.PendingApproval;
}

export function isApproved(entity: QuoteStatus) {
  return entity.id === QuoteStatuses.Approved;
}

export function isDeclined(entity: QuoteStatus) {
  return entity.id === QuoteStatuses.Declined;
}

export function isDeleted(entity: QuoteStatus) {
  return entity.id === QuoteStatuses.Deleted;
}

export function isDraft(entity: QuoteStatus) {
  return entity.id === QuoteStatuses.Draft;
}

export function isFinalised(entity: QuoteStatus) {
  return isApproved(entity) || isDeclined(entity);
}
