import Fade from "@material-ui/core/Fade";
import MuiMenuList from "@material-ui/core/MenuList";
import clsx from "clsx";
import { MouseEventHandler, ReactNode, useCallback } from "react";

import { useParentCloseOnClick } from "../../hooks";
import { Popover, PopoverProps } from "./Popover";
import { menuListCss, popoverPaperCss } from "./PopoverMenu.css";

export interface PopoverMenuProps extends Omit<PopoverProps, "open"> {
  open?: boolean;
  HeaderComponent?: ReactNode;
  FooterComponent?: ReactNode;
  onMouseEnter?: MouseEventHandler;
  menuListClassName?: string;
  paperRootClassName?: string;
}

export function PopoverMenu({
  children,
  open,
  anchorEl = null,
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps,
  HeaderComponent = null,
  FooterComponent = null,
  onMouseEnter,
  menuListClassName,
  paperRootClassName,
  onClose,
  ...props
}: PopoverMenuProps) {
  const isOpen = useCallback(() => {
    return open || Boolean(anchorEl);
  }, [open, anchorEl]);

  useParentCloseOnClick(isOpen, onClose);

  return (
    <Popover
      open={isOpen()}
      anchorEl={anchorEl}
      elevation={2}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        square: true,
        className: clsx(popoverPaperCss, paperRootClassName),
        onMouseEnter,
        ...PaperProps,
      }}
      TransitionComponent={Fade}
      transitionDuration={300}
      onClose={onClose}
      {...props}
    >
      {HeaderComponent}
      <MuiMenuList
        className={clsx(menuListCss, menuListClassName)}
        variant="menu"
        disablePadding
      >
        {children}
      </MuiMenuList>
      {FooterComponent}
    </Popover>
  );
}
