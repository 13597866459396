import { ChangeEvent, useMemo, useState } from "react";
import { Input } from "st-shared/components";
import { ReportingDataSet } from "st-shared/entities";
import { EntityDescriptorProperties } from "st-shared/types";

import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingSavedSegmentDataSets } from "../../../state/stores/savedSegmentSelectors";
import { DataSetActionMenu } from "./DataSetActionMenu";
import { headerContainer, inputCss } from "./DataSetHeader.css";

interface Props {
  dataSet: ReportingDataSet;
}

export function DataSetHeader({ dataSet }: Props) {
  const dataSets = useReportingSavedSegmentDataSets();
  const dataSetNames = useMemo(
    () =>
      Object.values(dataSets)
        .filter((dataSet) => dataSet.id !== dataSet.id)
        .map((dataSet) => dataSet.name.toLowerCase()),
    [dataSets]
  );
  const [name, setName] = useState(dataSet.name);

  function onChangeName(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onBlur() {
    const trimmedName = name.trim();
    if (
      trimmedName !== dataSet.name &&
      trimmedName !== "" &&
      !dataSetNames.includes(trimmedName.toLowerCase())
    ) {
      reportingStore().savedSegment.actions.setDatasetName(
        dataSet.id,
        trimmedName
      );
    } else {
      setName(dataSet.name);
    }
  }

  return (
    <div className={headerContainer}>
      <Input
        className={inputCss}
        value={name}
        onChange={onChangeName}
        placeholder={
          EntityDescriptorProperties[dataSet.entityDescriptorId].name
        }
        onBlur={onBlur}
      />
      <DataSetActionMenu dataSetId={dataSet.id} />
    </div>
  );
}
