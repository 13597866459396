import logo404Url from "./404.svg";
import artistGirlUrl from "./artist_girl.svg";
import castleBackgroundUrl from "./castle-background.svg";
import confusedGuyUrl from "./confused_guy.svg";
import cubeUrl from "./cube.png";
import cursorSplitUrl from "./cursor-split.svg";
import dragonUrrl from "./dragon.png";
import emptyStateGraphUrl from "./empty-state-graph.svg";
import emptyStateReportingUrl from "./empty-state-reporting.png";
import emptyStateReportingBWUrl from "./empty-state-reporting-bw.png";
import googleCalendarIconUrl from "./google-calendar-icon.svg";
import iconDotPulseLightUrl from "./icon-dot-pulse-light.svg";
import mountainsUrl from "./mountains.svg";
import newJobCreatedUrl from "./new_job_created.png";
import paperPlaneUrl from "./paper-plane.svg";
import planetUrl from "./planet.svg";
import poweredByStripeUrl from "./powered-by-stripe.svg";
import rainbowUrl from "./rainbow.png";
import skyUrl from "./sky.svg";
import spaceCarUrl from "./space-car.svg";
import spaceShipSunUrl from "./space-ship-sun.svg";
import spaceTetrisUrl from "./space-tetris.svg";
import streamtimeLogoUrl from "./streamtime-logo.png";
import streamtimeLogoTextUrl from "./streamtime-logo-text.svg";
import targetArrowUrl from "./target-arrow.svg";
import unicornUrl from "./unicorn.svg";
import wateringCanUrl from "./watering-can.svg";

export const streamtimeLogo = streamtimeLogoUrl;
export const streamtimeLogoText = streamtimeLogoTextUrl;
export const poweredByStripe = poweredByStripeUrl;
export const iconDotPulseLight = iconDotPulseLightUrl;
export const castleBackground = castleBackgroundUrl;
export const targetArrow = targetArrowUrl;
export const emptyStateGraph = emptyStateGraphUrl;
export const emptyStateReporting = emptyStateReportingUrl;
export const emptyStateReportingBW = emptyStateReportingBWUrl;
export const cube = cubeUrl;
export const mountains = mountainsUrl;
export const paperPlane = paperPlaneUrl;
export const spaceShipSun = spaceShipSunUrl;
export const sky = skyUrl;
export const spaceCar = spaceCarUrl;
export const spaceTetris = spaceTetrisUrl;
export const unicorn = unicornUrl;
export const wateringCan = wateringCanUrl;
export const cursorSplit = cursorSplitUrl;
export const artistGirl = artistGirlUrl;
export const logo404 = logo404Url;
export const planet = planetUrl;
export const confusedGuy = confusedGuyUrl;
export const googleCalendarIcon = googleCalendarIconUrl;
export const newJobCreated = newJobCreatedUrl;
export const rainbow = rainbowUrl;
export const dragon = dragonUrrl;
