import { get, keys } from "lodash-es";
import { createSelector } from "reselect";
import { authenticationStore } from "st-shared/stores";

import { EMPTY_OBJECT } from "../../lib/constants";
import {
  getBlockHeight,
  getJobCompanyName,
  getJobFullName,
  getJobId,
  getJobItemEstimatedEndDate,
  getJobItemEstimatedStartDate,
  getJobItemName,
  getJobPhaseName,
  getMinutes,
  isBilled,
  isComplete,
  isIncomplete,
  isPrivate,
} from "../../lib/entities/scheduleLoggedTimeEntity";
import { selectJobListModelsFilteredIds } from "../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIds";
import { isTodos } from "../../state/ui/schedule/lib";
import {
  selectScheduleUiActiveBlockKey,
  selectScheduleUiSplitBlockDays,
  selectScheduleUiSplittingBlockKey,
  selectScheduleUiViewMode,
} from "../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUi } from "../../state/ui/schedule/types";
import { selectPropsBlockKey } from "./index";
import {
  selectBlockKeysByUserIdDate,
  selectDaysByBlockKeys,
  selectHasFilterOrWildcardSearch,
  selectScheduleBlocksUi,
  selectScheduleLoggedTimes,
} from "./scheduleSelectors";

export const selectScheduleBlockUi = createSelector(
  selectScheduleBlocksUi,
  selectPropsBlockKey,
  (scheduleBlocks, blockKey) => get(scheduleBlocks, blockKey, EMPTY_OBJECT)
);

export const selectIsDraggingBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isDragging)
);

export const selectIsResizingBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isResizing)
);

export const selectIsPaintingBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isPainting)
);

export const selectIsSavingBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isSaving)
);

export const selectIsBulkSavingBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isSaving && blockUi.isBulkAction)
);

export const selectBaseScheduleLoggedTimeByBlockKey = createSelector(
  selectScheduleLoggedTimes,
  selectPropsBlockKey,
  (scheduleLoggedTimes, blockKey) =>
    get(scheduleLoggedTimes, `byId.${blockKey}`)
);

export const selectBaseScheduleLoggedTimesByUserIdDate = (state, props) =>
  selectBlockKeysByUserIdDate(state, props).map((blockKey) =>
    selectBaseScheduleLoggedTimeByBlockKey(state, { blockKey })
  );

export const selectScheduleLoggedTimesByBlockKey = createSelector(
  selectScheduleLoggedTimes,
  selectPropsBlockKey,
  (scheduleLoggedTimes, blockKey) =>
    get(scheduleLoggedTimes, `idsByBlockKey[${blockKey}]`, []).map((id) =>
      get(scheduleLoggedTimes, `byId[${id}]`)
    )
);

export const selectScheduleBlockHeight = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getBlockHeight(scheduleLoggedTime)
);

export const selectScheduleBlockDays = createSelector(
  selectDaysByBlockKeys,
  selectPropsBlockKey,
  (daysByBlockKey, blockKey) => get(daysByBlockKey, blockKey)
);

export const selectIsScheduleBlockPaused = createSelector(
  selectScheduleLoggedTimes,
  selectPropsBlockKey,
  (scheduleLoggedTimes, blockKey) =>
    get(scheduleLoggedTimes, `isPausedByBlockKey.${blockKey}`)
);

export const selectScheduleBlockMinutes = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getMinutes(scheduleLoggedTime)
);

export const selectScheduleBlockMinutesScheduled = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleBlockDays,
  (scheduleLoggedTime, days) => getMinutes(scheduleLoggedTime) * days
);

export const selectScheduleBlockJobItemName = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobItemName(scheduleLoggedTime)
);

export const selectScheduleBlockJobPhaseName = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobPhaseName(scheduleLoggedTime)
);

export const selectScheduleBlockJobFullName = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobFullName(scheduleLoggedTime)
);

export const selectScheduleBlockCompanyName = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobCompanyName(scheduleLoggedTime)
);

export const selectScheduleBlockJobItemEstimatedStartDate = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobItemEstimatedStartDate(scheduleLoggedTime)
);

export const selectScheduleBlockJobItemEstimatedEndDate = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => getJobItemEstimatedEndDate(scheduleLoggedTime)
);

export const selectIsScheduleBlockPrivate = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => {
    const loggedInUserId = authenticationStore().loggedInUserId;
    return isPrivate(scheduleLoggedTime, loggedInUserId);
  }
);

export const selectIsScheduleBlockBilled = createSelector(
  selectBaseScheduleLoggedTimeByBlockKey,
  (scheduleLoggedTime) => isBilled(scheduleLoggedTime)
);

export const selectActiveBlock = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiActiveBlockKey,
  (scheduleLoggedTimes, activeBlockKey) =>
    get(scheduleLoggedTimes, `byId[${activeBlockKey}]`)
);

export const selectIsRelatedBlock = createSelector(
  selectActiveBlock,
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleUiViewMode,
  (activeScheduleLoggedTime, scheduleLoggedTime, viewMode) =>
    Boolean(
      activeScheduleLoggedTime &&
        ((isTodos(viewMode) &&
          activeScheduleLoggedTime.jobId &&
          activeScheduleLoggedTime.jobId === scheduleLoggedTime.jobId) ||
          (activeScheduleLoggedTime.repeatingLoggedTimeId &&
            activeScheduleLoggedTime.repeatingLoggedTimeId ===
              scheduleLoggedTime.repeatingLoggedTimeId))
    )
);

export const selectIsActiveBlock = createSelector(
  selectScheduleUiActiveBlockKey,
  selectPropsBlockKey,
  (activeBlockKey, blockKey) => activeBlockKey === blockKey
);

export const selectIsSplittingBlock = createSelector(
  selectIsActiveBlock,
  selectScheduleUiSplittingBlockKey,
  selectPropsBlockKey,
  (isActiveBlock, splittingBlockKey, blockKey) =>
    isActiveBlock &&
    (splittingBlockKey === blockKey ||
      splittingBlockKey === ScheduleUi.SplittingBlockKeyGlobal)
);

export const selectSplittingBlockDays = createSelector(
  selectIsSplittingBlock,
  selectScheduleUiSplitBlockDays,
  (isSplitting, days) => (isSplitting ? days : null)
);

export const selectSelectedBlockKeys = createSelector(
  selectScheduleBlocksUi,
  (scheduleBlocks) =>
    keys(scheduleBlocks)
      .filter((blockKey) => scheduleBlocks[blockKey].isSelected)
      .map(Number)
);

export const selectIsSelectedBlock = createSelector(
  selectScheduleBlockUi,
  (blockUi) => Boolean(blockUi && blockUi.isSelected)
);

export const selectHasSelectedBlocks = createSelector(
  selectSelectedBlockKeys,
  (blockKeys) => blockKeys.length > 0
);

export const selectHasIncompleteSelectedBlocks = (state) =>
  Boolean(
    selectSelectedBlockKeys(state).find((blockKey) => {
      const baseScheduleLoggedTime = selectBaseScheduleLoggedTimeByBlockKey(
        state,
        { blockKey }
      );
      return baseScheduleLoggedTime && isIncomplete(baseScheduleLoggedTime);
    })
  );

export const selectHasCompleteSelectedBlocks = (state) =>
  Boolean(
    selectSelectedBlockKeys(state).find((blockKey) => {
      const baseScheduleLoggedTime = selectBaseScheduleLoggedTimeByBlockKey(
        state,
        { blockKey }
      );
      return baseScheduleLoggedTime && isComplete(baseScheduleLoggedTime);
    })
  );

export const selectHasBilledSelectedBlocks = (state) =>
  Boolean(
    selectSelectedBlockKeys(state).find((blockKey) => {
      const baseScheduleLoggedTime = selectBaseScheduleLoggedTimeByBlockKey(
        state,
        { blockKey }
      );

      return (
        baseScheduleLoggedTime &&
        isBilled(baseScheduleLoggedTime) &&
        isComplete(baseScheduleLoggedTime)
      );
    })
  );

export const selectHasPrivateSelectedBlocks = (state) =>
  Boolean(
    selectSelectedBlockKeys(state).find((blockKey) => {
      const baseScheduleLoggedTime = selectBaseScheduleLoggedTimeByBlockKey(
        state,
        { blockKey }
      );

      return baseScheduleLoggedTime && isPrivate(baseScheduleLoggedTime);
    })
  );

export const selectSelectionHasBulkSavingBlock = (state) =>
  Boolean(
    selectSelectedBlockKeys(state).find((blockKey) =>
      selectIsBulkSavingBlock(state, { blockKey })
    )
  );

export const selectIsBlockInBulkSavingSelection = (state, props) =>
  Boolean(
    selectIsSelectedBlock(state, props) &&
      selectSelectionHasBulkSavingBlock(state)
  );

export const selectIsFilteredBlock = createSelector(
  selectHasFilterOrWildcardSearch,
  selectJobListModelsFilteredIds,
  selectBaseScheduleLoggedTimeByBlockKey,
  (hasFilterOrWildcardSearch, filteredIds, scheduleLoggedTime) =>
    !hasFilterOrWildcardSearch ||
    filteredIds.includes(getJobId(scheduleLoggedTime))
);
