import { get, mapValues, omit } from "lodash-es";

import {
  ENTITIES_REMOVED,
  SCHEDULE_BLOCK_DELETE,
  SCHEDULE_BLOCK_DRAG_CANCEL,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DRAG_END_MULTIPLE,
  SCHEDULE_BLOCK_DRAG_START,
  SCHEDULE_BLOCK_DRAG_START_MULTIPLE,
  SCHEDULE_BLOCK_PAINT_CANCEL,
  SCHEDULE_BLOCK_PAINT_END,
  SCHEDULE_BLOCK_PAINT_START,
  SCHEDULE_BLOCK_REASSIGN,
  SCHEDULE_BLOCK_REASSIGN_MULTIPLE,
  SCHEDULE_BLOCK_RESIZE_CANCEL,
  SCHEDULE_BLOCK_RESIZE_END,
  SCHEDULE_BLOCK_RESIZE_START,
  SCHEDULE_BLOCK_SAVE_ERROR,
  SCHEDULE_BLOCK_SAVED,
  SCHEDULE_BLOCK_SAVING,
  SCHEDULE_BLOCK_SET_SELECTED,
  SCHEDULE_BLOCK_SPLIT_CANCEL,
  SCHEDULE_BLOCK_SPLIT_SAVE_ERROR,
  SCHEDULE_BLOCK_SPLIT_SAVED,
  SCHEDULE_BLOCK_SPLIT_SAVING,
  SCHEDULE_BLOCK_TOGGLE_SELECTED,
  SCHEDULE_BLOCK_UNSELECT_ALL,
} from "../../../lib/constants";
import { EntityNames } from "../../../state/entities/types";
import createReducer from "../../helpers/createReducer";

const isSelectedBlockAction = (state, { payload = {} }) =>
  payload.blockKey && get(state, `${payload.blockKey}.isSelected`, false);

const unselectBlocks =
  (reducer, force = false) =>
  (state, action) =>
    !force && isSelectedBlockAction(state, action)
      ? reducer(state, action)
      : reducer(
          {
            ...mapValues(state, (blockState) =>
              blockState.isSelected
                ? {
                    ...blockState,
                    isSelected: false,
                  }
                : blockState
            ),
          },
          action
        );

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) =>
  entityName === EntityNames.ScheduleLoggedTimes
    ? { ...omit(state, ids) }
    : state;

const dragStart = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isDragging: true },
});

const dragStartMultiple = (state, { payload: { selectedBlockKeys } }) => ({
  ...state,
  ...selectedBlockKeys.reduce(
    (nextState, blockKey) => ({
      ...nextState,
      [blockKey]: { ...state[blockKey], isDragging: true },
    }),
    {}
  ),
});

const dragEnd = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isDragging: false },
});

const resizeStart = unselectBlocks((state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isResizing: true },
}));

const resizeEnd = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isResizing: false },
});

const paintStart = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isPainting: true },
});

const paintEnd = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isPainting: false },
});

const onSaveRequest = (
  state,
  { payload: { blockKey, isBulkAction = false } }
) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isSaving: true, isBulkAction, error: null },
});

const onSaveSuccess = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: { ...state[blockKey], isSaving: false, isBulkAction: false },
});

const onSaveError = (state, { payload: { blockKey, error } }) => ({
  ...state,
  [blockKey]: {
    ...state[blockKey],
    isSaving: false,
    isBulkAction: false,
    error,
  },
});

const onToggleSelected = (state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: {
    ...state[blockKey],
    isSelected: !get(state, `${blockKey}.isSelected`, false),
  },
});

const onSetSelected = unselectBlocks((state, { payload: { blockKey } }) => ({
  ...state,
  [blockKey]: {
    ...state[blockKey],
    isSelected: true,
  },
}));

const onDelete = unselectBlocks(
  (state, { payload: { blockKey, blockKeys } }) => {
    if (blockKey) {
      return omit(state, blockKey);
    } else {
      return omit(state, blockKeys);
    }
  }
);

const onUnselectAll = unselectBlocks((state) => state, true);

export default createReducer(
  {},
  {
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [SCHEDULE_BLOCK_DRAG_START]: dragStart,
    [SCHEDULE_BLOCK_DRAG_START_MULTIPLE]: dragStartMultiple,
    [SCHEDULE_BLOCK_DRAG_END]: dragEnd,
    [SCHEDULE_BLOCK_DRAG_END_MULTIPLE]: dragEnd,
    [SCHEDULE_BLOCK_DRAG_CANCEL]: dragEnd,
    [SCHEDULE_BLOCK_RESIZE_START]: resizeStart,
    [SCHEDULE_BLOCK_RESIZE_END]: resizeEnd,
    [SCHEDULE_BLOCK_RESIZE_CANCEL]: resizeEnd,
    [SCHEDULE_BLOCK_PAINT_START]: paintStart,
    [SCHEDULE_BLOCK_PAINT_END]: paintEnd,
    [SCHEDULE_BLOCK_PAINT_CANCEL]: paintEnd,
    [SCHEDULE_BLOCK_SAVING]: onSaveRequest,
    [SCHEDULE_BLOCK_SAVED]: onSaveSuccess,
    [SCHEDULE_BLOCK_SAVE_ERROR]: onSaveError,
    [SCHEDULE_BLOCK_SPLIT_SAVING]: onSaveRequest,
    [SCHEDULE_BLOCK_SPLIT_SAVED]: onSaveSuccess,
    [SCHEDULE_BLOCK_SPLIT_SAVE_ERROR]: onSaveError,
    [SCHEDULE_BLOCK_TOGGLE_SELECTED]: onToggleSelected,
    [SCHEDULE_BLOCK_SET_SELECTED]: onSetSelected,
    [SCHEDULE_BLOCK_UNSELECT_ALL]: onUnselectAll,
    [SCHEDULE_BLOCK_REASSIGN]: onUnselectAll,
    [SCHEDULE_BLOCK_REASSIGN_MULTIPLE]: onUnselectAll,
    [SCHEDULE_BLOCK_SPLIT_CANCEL]: onUnselectAll,
    [SCHEDULE_BLOCK_DELETE]: onDelete,
  }
);
