import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { applyExchangeRate, formatCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_OPTIONAL_DECIMAL } from "../../../../lib/constants";
import { currencyEntityType } from "../../../../lib/entities/currencyEntity";
import { isInvoiceTypeCreditNote } from "../../../../lib/entities/invoiceEntity";
import { domNodeType } from "../../../../lib/types/domTypes";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../../lib/types/entityTypes";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import { commercialDocumentLineItemOptionType } from "../../../../lib/ui/commercialDocumentLineItems";
import { selectCommercialDocumentJobPlanOptionsByJobId } from "../../../../redux/selectors/commercialDocumentSelectors";
import NonBillableIcon from "../../../elements/Icons/NonBillableIcon";
import { ICON_SIZE } from "../../StyledIcon";
import consumeCommercialDocument from "../CommercialDocumentContext/Consumers/consumeCommercialDocument";
import AddLineSubMenu from "./AddLineSubMenu";
import AddLineSubMenuMenuItem from "./AddLineSubMenuMenuItem";
import ItemRate from "./ItemRate";

const mapContext = ({ document, commercialDocumentType }) => {
  let invoiceType;
  if (commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.INVOICE) {
    if (isInvoiceTypeCreditNote(document)) {
      invoiceType = "credit";
    } else {
      invoiceType = "invoice";
    }
  }
  return {
    invoiceType,
  };
};

const mapState = (state, props) => ({
  jobPlanOptions: selectCommercialDocumentJobPlanOptionsByJobId(state, props),
});

class AddFromJobPlanSubMenu extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    currency: currencyEntityType.isRequired,
    exchangeRate: entityFieldDecimalType.isRequired,
    jobPlanOptions: PropTypes.arrayOf(commercialDocumentLineItemOptionType)
      .isRequired,
    anchorEl: domNodeType,
    onPick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    invoiceType: PropTypes.oneOf(["invoice", "credit"]),
  };

  static defaultProps = {
    anchorEl: null,
  };

  getNoResultsMessage = (searchQuery) =>
    `Hmm, looks like no items match ‘${searchQuery}’. Perhaps try a different search term.`;

  getFormattedCurrency = (value) => {
    const { currency, exchangeRate } = this.props;

    return formatCurrency(
      applyExchangeRate(value, exchangeRate),
      currency.symbol,
      NUMBER_FORMAT_OPTIONAL_DECIMAL,
      true
    );
  };

  getRateSuffix = () => {
    const { invoiceType } = this.props;
    if (invoiceType === "invoice") {
      return " Remaining";
    }
    if (invoiceType === "credit") {
      return " Prev Invoiced";
    }
    return null;
  };

  render() {
    const { anchorEl, currency, onPick, onMouseEnter, jobPlanOptions } =
      this.props;

    return (
      <AddLineSubMenu
        anchorEl={anchorEl}
        options={jobPlanOptions}
        onMouseEnter={onMouseEnter}
        transformOrigin={{
          vertical: -10,
          horizontal: -10,
        }}
        hideBackdrop
        HeaderComponentProps={{
          title: `Job Plan (${currency.id})`,
          placeholder: "Search by name...",
          getNoResultsMessage: this.getNoResultsMessage,
        }}
      >
        {({ key, value, rate, optionType, className, isBillable = true }) => (
          <AddLineSubMenuMenuItem
            className={
              optionType === LineItemOptionTypes.OptionsHeading && "heading"
            }
            key={key}
            onClick={() => onPick(key, optionType)}
          >
            <span>{value}</span>
            {optionType !== LineItemOptionTypes.OptionsHeading &&
              (optionType === LineItemOptionTypes.LoggedExpense ||
                isBillable) && (
                <span>
                  <ItemRate>{this.getFormattedCurrency(rate)}</ItemRate>
                  {this.getRateSuffix(rate)}
                </span>
              )}
            {optionType !== LineItemOptionTypes.OptionsHeading &&
              !isBillable && (
                <NonBillableIcon size={ICON_SIZE.MEDIUM}></NonBillableIcon>
              )}
          </AddLineSubMenuMenuItem>
        )}
      </AddLineSubMenu>
    );
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(AddFromJobPlanSubMenu)
);
