import { useCallback, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Tooltip } from "st-shared/components";
import { getAvailabilityDates } from "st-shared/lib";

import {
  SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE,
  SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE,
  SCHEDULE_JOB_PHASE_COLLAPSE,
  SCHEDULE_JOB_PHASE_EXPAND,
} from "../../../lib/constants";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import createAction from "../../../redux/helpers/createAction";
import { selectJobItemOrderByJobIdPhaseId } from "../../../redux/selectors/jobItemSelectors";
import { useJobPhaseEstimateStartEndDates } from "../../../redux/selectors/jobPhase/selectJobPhaseEstimatedStartEndDates";
import {
  selectJobPhaseById,
  selectJobPhaseHasDependanciesById,
} from "../../../redux/selectors/jobPhaseSelectors";
import { selectScheduleJobPhaseUi } from "../../../redux/selectors/scheduleSelectors";
import { actionJobPhaseAddUsersAndRoles } from "../../../state/entities/jobPhase/actions";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { IconButton } from "../../elements/Button";
import PersonAddIcon from "../../elements/Icons/PersonAddIcon";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { JobItemMultiUserSelector } from "../../modules/UserRoleMenus/JobItemMultiUserSelector";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import ScheduleJobItemGroup from "./ScheduleJobItemGroup";
import {
  containerCss,
  nameCss,
  nameMultiSelectCss,
  scheduleGroupUnderlineCss,
  teamMemberButtonCss,
  wrapperCss,
} from "./ScheduleJobPhaseGroup.css";

const mapState = (state, props) => ({
  jobPhase: selectJobPhaseById(state, props),
  jobItemIds: selectJobItemOrderByJobIdPhaseId(state, props),
  isExpanded: selectScheduleJobPhaseUi(state, props).isExpanded,
  forceExpand: selectJobPhaseHasDependanciesById(state, props),
});

const mapDispatch = (dispatch) => ({
  doExpand: (id) => dispatch(createAction(SCHEDULE_JOB_PHASE_EXPAND, { id })),
  doCollapse: (id) =>
    dispatch(createAction(SCHEDULE_JOB_PHASE_COLLAPSE, { id })),
  doExpandItems: (ids) =>
    dispatch(createAction(SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE, { ids })),
  doCollapseItems: (ids) =>
    dispatch(createAction(SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE, { ids })),
});

const ScheduleJobPhaseGroup = ({
  id,
  jobPhase,
  jobItemIds,
  forceExpand,
  isExpanded,
  doExpand,
  doCollapse,
  doExpandItems,
  doCollapseItems,
  collapsePhases,
  expandPhases,
}) => {
  const [showMultiUserSelector, setShowMultiUserSelector] = useState(false);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();

  const toggleExpand = (e) => {
    if (forceExpand) return;

    if (isExpanded) e.altKey ? collapsePhases() : doCollapse(id);
    else e.altKey ? expandPhases() : doExpand(id);
  };

  const expandItems = useCallback(() => {
    doExpandItems(jobItemIds);
  }, [doExpandItems, jobItemIds]);

  const collapseItems = useCallback(() => {
    doCollapseItems(jobItemIds);
  }, [doCollapseItems, jobItemIds]);

  const [estimatedStartDate, estimatedEndDate] =
    useJobPhaseEstimateStartEndDates(jobPhase?.id);

  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    estimatedStartDate,
    estimatedEndDate
  );

  const editUsersRoles = (event) => {
    event.stopPropagation();
    setShowMultiUserSelector(true);
  };

  const closeTeamMemberMenu = () => {
    setShowMultiUserSelector(false);
  };

  function addUsersAndRoles(userIds = [], roleIds = []) {
    dispatch(actionJobPhaseAddUsersAndRoles(jobPhase.id, userIds, roleIds));
  }

  return (
    <div className={wrapperCss}>
      {jobPhase && (
        <div onClick={toggleExpand} className={containerCss}>
          {!forceExpand && jobItemIds.length > 0 ? (
            <Expander
              isExpanded={isExpanded}
              title={isExpanded ? "Collapse Phase" : "Expand Phase"}
            />
          ) : (
            <Spacer w={30} />
          )}

          <div className={scheduleGroupUnderlineCss}>
            <div
              className={nameMultiSelectCss}
              style={{
                width: `calc(100% - ${ScheduleUi.View.GroupTotalsWidth}px)`,
              }}
            >
              <div className={nameCss}>{getJobPhaseName(jobPhase)}</div>

              <div ref={buttonRef}>
                {jobItemIds.length > 0 && (
                  <div className={teamMemberButtonCss}>
                    <Tooltip title="Add users and roles" placement="bottom">
                      <IconButton onClick={editUsersRoles}>
                        <PersonAddIcon size={ICON_SIZE.MEDIUM} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}

                {showMultiUserSelector && (
                  <JobItemMultiUserSelector
                    anchorEl={buttonRef.current}
                    entityId={jobPhase?.id}
                    type="jobPhase"
                    defaultStartDate={defaultStartDate}
                    defaultEndDate={defaultEndDate}
                    addUsersAndRoles={addUsersAndRoles}
                    close={closeTeamMemberMenu}
                    canCreateNewUser={false}
                    itemCount={jobItemIds.length}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                )}
              </div>
            </div>

            <ScheduleGroupTotals />
          </div>
        </div>
      )}

      {(!jobPhase || isExpanded || forceExpand) &&
        jobItemIds.length > 0 &&
        jobItemIds.map((jobItemId) => (
          <ScheduleJobItemGroup
            key={jobItemId}
            id={jobItemId}
            collapseItems={collapseItems}
            expandItems={expandItems}
          />
        ))}
    </div>
  );
};

export default connect(mapState, mapDispatch)(ScheduleJobPhaseGroup);
