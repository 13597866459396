import type {
  TEntityClass,
  TEntityClassMap,
  TEntityId,
} from "@streamtimefe/entities";
import { EntityClass } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import { mapValues, omit } from "remeda";

import { createActionStore } from "../../core";
import {
  createEntityActions,
  type TEntityActions,
} from "./EntityStore.actions";

type TEntityExclusions = typeof EntityClass.UserPreference;

export type TEntityStoreClass = Exclude<TEntityClass, TEntityExclusions>;

export const EntityStoreClass = omit(EntityClass, [EntityClass.UserPreference]);

export type TEntityStore = {
  [T in TEntityStoreClass]: {
    byId: Dictionary<TEntityId, TEntityClassMap[T]>;
    syncKeyIndex: Dictionary<string, TEntityId>;
  };
};

export const EntityStore = createActionStore<TEntityStore, TEntityActions>(
  () => mapValues(EntityStoreClass, () => ({ byId: {}, syncKeyIndex: {} })),
  createEntityActions
);
