import { EntityDescriptorEnum } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataCurrencyId,
  getMetaDataInvoiceNumber,
  getMetaDataOtherInvoiceId,
  getMetaDataOtherInvoiceNumber,
  getMetaDataPaymentAmountIncTax,
} from "../../../../../lib/entities/activityEntryEntity";
import { entityIdType } from "../../../../../lib/types/entityTypes";
import JobForeignCurrency from "../../../../modules/Currency/JobForeignCurrency";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import LinkToEntity from "./LinkToEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobInvoicePaymentCreated = ({ activityEntry, canEditJobs, jobId }) => {
  return (
    <SystemMessage>
      <JobForeignCurrency
        id={jobId}
        value={Math.abs(getMetaDataPaymentAmountIncTax(activityEntry))}
        currencyId={getMetaDataCurrencyId(activityEntry)}
      />{" "}
      {getMetaDataPaymentAmountIncTax(activityEntry) < 0
        ? "paid out from credit note "
        : "paid on invoice "}
      <LinkToActivityEntryEntity
        activityEntry={activityEntry}
        disabled={!canEditJobs}
      >
        {getMetaDataInvoiceNumber(activityEntry)}
      </LinkToActivityEntryEntity>
      {getMetaDataOtherInvoiceNumber(activityEntry) && (
        <span>
          {" from credit note "}
          <LinkToEntity
            entityDescriptor={EntityDescriptorEnum.Invoice}
            entityId={getMetaDataOtherInvoiceId(activityEntry)}
            disabled={!canEditJobs}
          >
            {getMetaDataOtherInvoiceNumber(activityEntry)}
          </LinkToEntity>
        </span>
      )}
    </SystemMessage>
  );
};

JobInvoicePaymentCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
  jobId: entityIdType.isRequired,
};

export default JobInvoicePaymentCreated;
