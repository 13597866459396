import * as PropTypes from "prop-types";
import { asString, searchWordMatches } from "st-shared/lib";

import { entityIdListType, entityIdType } from "../types/entityTypes";

export const teamEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  userIds: entityIdListType.isRequired,
});

export const teamOptionType = PropTypes.shape({
  key: entityIdType.isRequired,
  value: PropTypes.string.isRequired,
});

export const getSearchString = (team) => asString(team.name);

export const getAsOption = (team) => ({
  key: team.id,
  value: team.name,
  searchString: getSearchString(team),
});

export const searchTeamOptions = (options, searchQuery) => {
  if (!asString(searchQuery).trim().length) return options;
  return options.filter(({ searchString }) =>
    searchWordMatches(searchString, searchQuery)
  );
};
