import isError from "lodash-es/isError";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { sendOpenCreateTaxRateModal } from "../../../lib/WebAppAPI/taxRate";
import {
  ENTITIES_RECEIVED,
  INVOICE_LINE_ITEM_SAVE,
  INVOICE_LINE_ITEM_TAX_RATE_CREATE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectInvoiceLineItemById,
  selectInvoiceLineItemInvoiceIdById,
  selectInvoiceLineItemUi
} from "../../selectors/invoiceLineItemSelectors";
import { selectInvoiceJobBranchIdById } from "../../selectors/invoiceSelectors";

function* openCreate(action) {
  try {
    const { id } = action.payload;

    const invoiceId = yield select(selectInvoiceLineItemInvoiceIdById, { id });

    const branchId = yield select(selectInvoiceJobBranchIdById, {
      id: invoiceId
    });

    const { taxRate, data } = yield call(sendOpenCreateTaxRateModal, branchId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    if (id > 0) {
      const { isDeleting } = yield select(selectInvoiceLineItemUi, { id });

      if (isDeleting) return;
    }

    const prevInvoiceLineItem = yield select(selectInvoiceLineItemById, { id });

    const invoiceLineItem = {
      ...prevInvoiceLineItem,
      taxName: taxRate.name,
      taxRate: taxRate.rate
    };

    yield put(
      createAction(INVOICE_LINE_ITEM_SAVE, {
        id,
        invoiceLineItem,
        prevInvoiceLineItem
      })
    );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchInvoiceLineItemCreateTaxRate() {
  yield takeLatest(INVOICE_LINE_ITEM_TAX_RATE_CREATE, openCreate);
}
