import { combineReducers } from "redux";

import { EntityNames } from "../../../state/entities/types";
import jobItemRolesUiReducer from "../../../state/ui/jobItemRoles/reducer";
import scheduleUiReducer from "../../../state/ui/schedule/reducer";
import accountingPlatform from "./accountingPlatform";
import activityEntries from "./activityEntries";
import branchLogos from "./branchLogos";
import invoice from "./invoice";
import invoiceLineItems from "./invoiceLineItems";
import invoices from "./invoices";
import jobActivity from "./jobActivity";
import jobDetails from "./jobDetails";
import jobItems from "./jobItems";
import jobItemSubItems from "./jobItemSubItems";
import jobItemUsers from "./jobItemUsers";
import jobMilestones from "./jobMilestones";
import jobPhases from "./jobPhases";
import jobs from "./jobs";
import jobTimeline from "./jobTimeline";
import jobTimelinePhases from "./jobTimelinePhases";
import loggedExpense from "./loggedExpense";
import loggedExpenses from "./loggedExpenses";
import companyOptions from "./options/companyOptions";
import jobPhaseOptions from "./options/jobPhaseOptions";
import labelOptions from "./options/labelOptions";
import purchaseOrderLineItems from "./purchaseOrderLineItems";
import quote from "./quote";
import quoteLineItems from "./quoteLineItems";
import quotes from "./quotes";
import scheduleBlocks from "./scheduleBlocks";
import scheduleJobItems from "./scheduleJobItems";
import scheduleJobPhases from "./scheduleJobPhases";
import scheduleJobs from "./scheduleJobs";
import scheduleUserJobs from "./scheduleUserJobs";

export default combineReducers({
  activityEntries,
  branchLogos,
  accountingPlatform,
  invoice,
  invoices,
  invoiceLineItems,
  jobDetails,
  jobActivity,
  jobTimeline,
  jobTimelinePhases,
  jobs,
  jobItems,
  jobItemSubItems,
  jobItemUsers,
  jobPhases,
  jobMilestones,
  loggedExpense,
  loggedExpenses,
  purchaseOrderLineItems,
  quote,
  quotes,
  quoteLineItems,
  scheduleBlocks,
  scheduleJobs,
  scheduleJobPhases,
  scheduleJobItems,
  scheduleUserJobs,
  companyOptions,
  jobPhaseOptions,
  labelOptions,
  schedule: scheduleUiReducer,
  [EntityNames.JobItemRoles]: jobItemRolesUiReducer,
});
