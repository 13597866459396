import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchJobRepeatingLoggedTimesAPI } from "../../../lib/API/jobActivityAPI";
import {
  ENTITIES_RECEIVED,
  JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST,
  JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED,
  JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobActivityUi } from "../../selectors/jobActivitySelectors";

function* fetch(action) {
  try {
    const { jobId } = action.payload;

    const { fetchedRepeatingLoggedTimeIds } = yield select(selectJobActivityUi);

    if (!fetchedRepeatingLoggedTimeIds.includes(jobId)) {
      const repeatingLoggedTimesData = yield call(
        fetchJobRepeatingLoggedTimesAPI,
        jobId
      );

      yield put(
        createAction(ENTITIES_RECEIVED, {
          ...repeatingLoggedTimesData
        })
      );
    }

    yield put(
      createAction(JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED, action.payload)
    );
  } catch (error) {
    yield put(
      createAction(JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST, fetch);
}
