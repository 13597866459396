import { get, set, each, reduce, unset } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED
} from "../../../../lib/constants";
import { getJobPhaseId } from "../../../../lib/entities/jobItemEntity";

export default (prevState, nextState, changedEntities) => {
  const touchedPaths = [];
  const phaseStatusByJobPhaseId = {};
  let hasChanged = false;

  const nextStatePhaseStatusByJobPhaseId = Object.assign(
    {},
    nextState.phaseStatusByJobPhaseId
  );

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    if (prevEntity && !newEntity && getJobPhaseId(prevEntity))
      pushOnce(touchedPaths, getJobPhaseId(prevEntity));

    if (newEntity && getJobPhaseId(newEntity))
      pushOnce(touchedPaths, getJobPhaseId(newEntity));
  });

  if (!touchedPaths.length) return {};

  each(touchedPaths, path => {
    const jobItemIds = get(nextState.idsByJobPhaseId, path, []);
    if (!jobItemIds.length) unset(nextStatePhaseStatusByJobPhaseId, path);
    else {
      const phaseStatus = reduce(
        jobItemIds,
        (statusId, jobItemId) => {
          const jobItem = nextState.byId[jobItemId];
          const jobItemStatusId = jobItem.jobItemStatus.id;

          if (!statusId) return jobItemStatusId;

          if (
            statusId === JOB_ITEM_STATUS_ID_SCHEDULED ||
            jobItemStatusId === JOB_ITEM_STATUS_ID_SCHEDULED
          )
            return JOB_ITEM_STATUS_ID_SCHEDULED;

          if (
            statusId === JOB_ITEM_STATUS_ID_COMPLETE ||
            jobItemStatusId === JOB_ITEM_STATUS_ID_COMPLETE
          )
            return JOB_ITEM_STATUS_ID_COMPLETE;

          return JOB_ITEM_STATUS_ID_PLANNING;
        },
        null
      );

      if (get(prevState.phaseStatusByJobPhaseId, path) !== phaseStatus) {
        set(phaseStatusByJobPhaseId, path, phaseStatus);
        hasChanged = true;
      }
    }
  });

  if (!hasChanged) return {};

  return Object.assign(nextState, {
    phaseStatusByJobPhaseId: Object.assign(
      {},
      nextStatePhaseStatusByJobPhaseId,
      phaseStatusByJobPhaseId
    )
  });
};
