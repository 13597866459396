import {
  JOB_PHASE_SET_PREVIOUS_JOB_PHASE,
  JOB_PHASE_SAVE_ERROR,
  JOB_PHASE_SAVED,
  JOB_PHASE_SAVING,
  JOB_PHASE_SAVE,
  JOB_PHASE_EDIT,
  JOB_PHASE_SET_JOB_ITEM_IDS,
  JOB_PHASE_DELETING,
  JOB_PHASE_DELETED,
  JOB_PHASE_DELETE_ERROR,
  JOB_PHASE_SET_EXPANDED,
  JOB_PHASE_LOADING_STARTED,
  JOB_PHASE_LOADING_ENDED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onSaveRequest = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isSaving: false, jobItemIds: null }
});

const onSaveError = (state, { payload: { jobPhaseId, error } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    isSaving: false,
    jobItemIds: null,
    error
  }
});

const onEdit = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    isEditing: true
  }
});

const onSave = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    isEditing: false
  }
});

const setPrevious = (state, { payload: { jobPhaseId, jobPhase } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    previousJobPhase: jobPhase
  }
});

const setJobItemIds = (state, { payload: { jobPhaseId, jobItemIds } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    jobItemIds
  }
});

const onDeleteRequest = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: {
    ...state[jobPhaseId],
    isDeleting: true,
    error: null
  }
});

const onDeleteSuccess = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isDeleting: false }
});

const onDeleteError = (state, { payload: { jobPhaseId, error } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isDeleting: false, error }
});

const setExpanded = (
  state,
  { payload: { jobPhaseId = null, jobPhaseIds = null, isExpanded } }
) => {
  const newState = { ...state };

  if (jobPhaseId !== null) {
    newState[jobPhaseId] = {
      ...state[jobPhaseId],
      isExpanded
    };
  }

  if (jobPhaseIds !== null) {
    jobPhaseIds.forEach(id => {
      newState[id] = {
        ...state[id],
        isExpanded
      };
    });
  }

  return newState;
};

const onPhaseLoadingStarted = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isSaving: true }
});

const onPhaseLoadingEnded = (state, { payload: { jobPhaseId } }) => ({
  ...state,
  [jobPhaseId]: { ...state[jobPhaseId], isSaving: false }
});

export default createReducer(
  {},
  {
    [JOB_PHASE_EDIT]: onEdit,
    [JOB_PHASE_SAVE]: onSave,
    [JOB_PHASE_SAVING]: onSaveRequest,
    [JOB_PHASE_SAVED]: onSaveSuccess,
    [JOB_PHASE_SAVE_ERROR]: onSaveError,
    [JOB_PHASE_SET_PREVIOUS_JOB_PHASE]: setPrevious,
    [JOB_PHASE_SET_JOB_ITEM_IDS]: setJobItemIds,
    [JOB_PHASE_DELETING]: onDeleteRequest,
    [JOB_PHASE_DELETED]: onDeleteSuccess,
    [JOB_PHASE_DELETE_ERROR]: onDeleteError,
    [JOB_PHASE_SET_EXPANDED]: setExpanded,
    [JOB_PHASE_LOADING_STARTED]: onPhaseLoadingStarted,
    [JOB_PHASE_LOADING_ENDED]: onPhaseLoadingEnded
  }
);
