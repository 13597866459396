import * as PropTypes from "prop-types";
import styled from "styled-components";

export const ICON_SIZE = {
  SMALL: 13,
  MEDIUM: 16,
  LARGE: 18,
  X_LARGE: 24,
  XX_LARGE: 28,
  XXX_LARGE: 32,
};

export default (WrappedMuiIcon) => {
  const StyledIcon = styled(WrappedMuiIcon).attrs(({ inline }) => ({
    inline: inline ? "true" : "false",
  }))`
    transition: var(--transition-mui-color);
    font-size: ${(props) => props.size}px;
    ${(props) => props.inline && "vertical-align: text-bottom;"}
  `;

  const Icon = (props) => <StyledIcon {...props} />;

  Icon.propTypes = {
    size: PropTypes.number,
    inline: PropTypes.bool,
  };

  Icon.defaultProps = {
    size: ICON_SIZE.LARGE,
    inline: false,
  };

  return styled(Icon)``;
};
