import clsx from "clsx";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";
import { RoleIconName } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";
import { theme } from "st-shared/theme";

import { NUMBER_FORMAT_ABBREVIATED } from "../../../lib/constants";
import { getTodayDate, maxDate } from "../../../lib/dates";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { useJobCurrency } from "../../../redux/selectors/currency/jobCurrency";
import { useJobItem } from "../../../redux/selectors/jobItem";
import {
  actionJobItemRoleAssignUsers,
  actionJobItemRoleDelete,
} from "../../../state/entities/jobItemRole/actions";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { selectScheduleUiScheduleTotalsMode } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiTotalsModes } from "../../../state/ui/schedule/types";
import { IconButton } from "../../elements/Button";
import CloseIcon from "../../elements/Icons/CloseIcon";
import RoleAddIcon from "../../elements/Icons/custom/RoleAddIcon";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import { AssignRoleMenu } from "../../modules/UserRoleMenus/AssignRoleMenu";
import * as styles from "./ScheduleJobItemRoleGroup.css";

type Props = {
  jobItemRoleId: number;
  height: number;
};

export function ScheduleJobItemRoleGroup({ jobItemRoleId, height }: Props) {
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const jobItem = useJobItem(jobItemRole.jobItemId);
  const scheduleTotalsMode = useSelector(selectScheduleUiScheduleTotalsMode);

  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobItem.jobId);

  const ref = useRef<HTMLDivElement>(null);

  const [assignUsersOpen, setAssignUsersOpen] = useState(false);

  const dispatch = useDispatch();

  function handleUnassignJobItemUser() {
    dispatch(actionJobItemRoleDelete(jobItemRoleId, "api"));
  }

  function openAssignUserMenu() {
    setAssignUsersOpen(true);
  }

  function closeAssignUserMenu() {
    setAssignUsersOpen(false);
  }

  function assignUsers(users: { userId: number; minutes: number }[]) {
    dispatch(actionJobItemRoleAssignUsers(jobItemRoleId, users, "api"));
  }

  function getDefaultStartDate(viewportStartDate: string | null) {
    return maxDate(
      jobItem.estimatedStartDate,
      viewportStartDate,
      getTodayDate()
    );
  }

  function getDefaultEndDate(
    viewportStartDate: string | null,
    viewportEndDate: string | null
  ) {
    const startDate = getDefaultStartDate(viewportStartDate);
    const endDate = jobItem.estimatedEndDate || viewportEndDate;
    return maxDate(startDate, endDate);
  }

  function getTotals() {
    switch (scheduleTotalsMode) {
      case ScheduleUiTotalsModes.UsedScheduledPlannedMoney:
      case ScheduleUiTotalsModes.ScheduledRemainingMoney:
        return (
          <div>
            {formatForeignCurrency(customerCurrency, {
              value: jobItemRole.jobCurrencyTotalPlannedTimeExTax,
              currency: jobCurrency,
              format: NUMBER_FORMAT_ABBREVIATED,
            })}
          </div>
        );
      default:
        return (
          <div>{convertMinutesToTimeHM(jobItemRole.totalPlannedMinutes)}</div>
        );
    }
  }

  return (
    <>
      <div
        style={{ height }}
        className={clsx(styles.container, "ScheduleJobItemUserGroupContainer")}
      >
        <div className={styles.roleContent} ref={ref}>
          <div className={styles.spacer}></div>
          <div className={styles.roleIconWrapper}>
            <RoleIconName
              id={jobItemRole.roleId}
              fontSize={12}
              secondaryFontSize={12}
              secondaryColor={theme.color.charcoal}
              type="both"
              nameWidth="calc(100% - 40px)"
            />
          </div>
          <div className={styles.rolesActions}>
            <IconButton
              className={styles.roleActionButton}
              onClick={openAssignUserMenu}
            >
              <RoleAddIcon />
            </IconButton>
            <IconButton
              className={styles.roleActionButton}
              onClick={handleUnassignJobItemUser}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.totalOuter}>
          <div className={styles.totalInner}>{getTotals()}</div>
        </div>
      </div>
      {assignUsersOpen && (
        <ScheduleScrollContext.Consumer>
          {({ viewportStartDate, viewportEndDate }) => (
            <AssignRoleMenu
              anchorEl={ref.current}
              assignUsers={assignUsers}
              close={closeAssignUserMenu}
              jobItemRoleId={jobItemRoleId}
              defaultStartDate={getDefaultStartDate(viewportStartDate)}
              defaultEndDate={getDefaultEndDate(
                viewportStartDate,
                viewportEndDate
              )}
              transformOrigin={{
                horizontal: -2,
                vertical: "top",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          )}
        </ScheduleScrollContext.Consumer>
      )}
    </>
  );
}
