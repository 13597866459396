import type {
  TSavedSegmentType,
  TSavedSegmentTypeEnum,
} from "./SavedSegmentType";

export function unknownIdErrorMessage(savedSegmentType: TSavedSegmentType) {
  return `Unknown Saved Segment Type: ${savedSegmentType.id} - ${savedSegmentType.name}`;
}

export function create(id: TSavedSegmentTypeEnum): TSavedSegmentType {
  return { id, name: "" };
}
