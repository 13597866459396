import numeral from "numeral";
import PropTypes from "prop-types";
import React from "react";
import {
  DATE_FORMAT_INVOICE_PAYMENT,
  NUMBER_FORMAT_FULL_DEFAULT
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { asDecimal } from "../../../lib/math";
import { entityFieldDecimalType } from "../../../lib/types/entityTypes";
import {
  formatCurrencyForCommercialDocuments,
  getCurrency
} from "../../../lib/ui/commercialDocument";
import { useCommercialDocument } from "../../modules/CommercialDocuments/CommercialDocumentContext";

const InvoiceSummaryRow = ({ summary }) => {
  const { document, documentMethods } = useCommercialDocument();
  const documentCurrency = getCurrency(document);
  const { displayCurrencySymbols } = documentMethods.getDisplayOptions(
    document
  );

  const formatValue = value => {
    if (displayCurrencySymbols)
      return formatCurrencyForCommercialDocuments(
        value,
        documentCurrency.symbol
      );

    return numeral(value).format(NUMBER_FORMAT_FULL_DEFAULT);
  };

  return (
    <tr>
      <td className="date">
        {formatDate(summary.issueDate.date, DATE_FORMAT_INVOICE_PAYMENT)}
      </td>
      <td>{summary.fullName}</td>
      <td>{summary.status}</td>
      <td>{formatValue(asDecimal(summary.totalAmountIncTax))}</td>
    </tr>
  );
};

InvoiceSummaryRow.propTypes = {
  summary: PropTypes.shape({
    issueDate: PropTypes.shape({
      date: PropTypes.string.isRequired
    }).isRequired,
    fullName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    totalAmountIncTax: entityFieldDecimalType.isRequired
  }).isRequired
};

export default InvoiceSummaryRow;
