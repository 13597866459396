import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobGroupPeriodStore = createSingleEntityStoreContext(
  EntityClass.JobGroupPeriod
);

export const JobGroupPeriodStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobGroupPeriod,
  JobGroupPeriodStore
);
