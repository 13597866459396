import { produce } from "immer";
import { get, setWith, sortBy, unset } from "lodash-es";
import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedInvoiceIds) =>
  produce(nextState, draft => {
    const getInvoiceLineItem = id => nextState.byId[id];

    touchedInvoiceIds.forEach(invoiceId => {
      const ids = get(nextState.idsByInvoiceId, invoiceId);

      if (!ids) {
        unset(draft, `sortedIdsByInvoiceId.${invoiceId}`);
        return;
      }

      const sortedIds = sortBy(ids, id => getOrderId(getInvoiceLineItem(id)));

      setWith(draft, `sortedIdsByInvoiceId.${invoiceId}`, sortedIds, Object);
    });
  });
