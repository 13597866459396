import type { TBranch } from "@streamtimefe/entities";
import { isEmpty, keys, values } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../../entities";
import { branchObj } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { defaultSort, defaultSortOptions } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { storeActionAddListener } from "../../helpers";
import { addGlobalStore } from "../../helpers/global";
import type { ChangedEntity, EntityStoreState } from "..";
import { byIdReducer, parseEntityPayload, parseRemovedEntities } from "..";

interface BranchEntityStoreState extends EntityStoreState<TBranch> {
  activeOptions: SearchOption<TBranch>[];
  getActiveBranches: () => TBranch[];
  hasMultipleBranches: () => boolean;
}

export const useBranchEntityStore = create<BranchEntityStoreState>(
  function (set, get) {
    return {
      entities: {},
      activeOptions: [],
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.Branch])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.Branch]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.Branch) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TBranch>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);

        const activeOptions = values(entities)
          .filter((branch) => branch.active)
          .map((branch) => branchObj(branch).getAsOption())
          .sort(defaultSortOptions);

        set({
          entities,
          activeOptions,
        });
      },
      getActiveBranches() {
        return values(get().entities).filter((branch) => branch.active);
      },
      hasMultipleBranches() {
        return keys(get().entities).length > 1;
      },
    };
  }
);

export function branchEntityStoreInit() {
  addGlobalStore(["entities", EntityListKey.Branch], branchEntityStore);
  storeActionAddListener(
    "entitiesReceived",
    branchEntityStore().receiveEntities
  );
  storeActionAddListener("entitiesRemoved", branchEntityStore().removeEntities);
}

export function branchEntityStore() {
  return useBranchEntityStore.getState();
}

export function useBranchEntity(entityId: TEntityId) {
  return useBranchEntityStore((s) => s.entities[entityId]);
}

export function useActiveBranches() {
  return useBranchEntityStore((s) => s.getActiveBranches());
}

export function useActiveBranchOptions() {
  return useBranchEntityStore((s) => s.activeOptions);
}

export function useHasMultipleBranches() {
  return useBranchEntityStore((s) => s.hasMultipleBranches());
}

export function defaultSortBranches(a: TBranch, b: TBranch) {
  return defaultSort(a.name.toLowerCase(), b.name.toLowerCase());
}

export function useBranchEntities(
  entityIds: TEntityId[],
  sortBranches = defaultSortBranches
) {
  return useBranchEntityStore((s) =>
    entityIds.map((entityId) => s.entities[entityId]).sort(sortBranches)
  );
}
