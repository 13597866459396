import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateJobPhases } from "./index";

export const selectJobPhaseIdsByJobId = createCachedSelector(
  [selectStateJobPhases, selectPropsJobId],
  (jobPhases, jobId) => get(jobPhases, `idsByJobId.${jobId}`, EMPTY_ARRAY)
)(selectPropsJobId);

export const useJobPhasesIdsByJobId = jobId =>
  useSelector(state => selectJobPhaseIdsByJobId(state, { jobId }));
