import * as PropTypes from "prop-types";
import styled from "styled-components";

import { domNodeType } from "../../../lib/types/domTypes";
import { entityIdListType } from "../../../lib/types/entityTypes";
import { useActiveUserOptionsGroupedByBranch } from "../../../redux/selectors/user/selectActiveUserOptionsGroupedByBranch";
import SearchMenu from "../SearchMenu";
import ClearMenuHeader from "./ClearMenuHeader";
import GroupBranchedUserOption from "./GroupBranchedUserOption";

const getNoResultsMessage = (searchQuery) =>
  `Hmm, looks like no users match ‘${searchQuery}’. Perhaps try a different search term.`;

const UserPickerWithClear = ({
  anchorEl,
  onPickUser,
  onClose,
  pickedUserId,
  onClear,
  excludeIds,
}) => {
  const activeUserOptions = useActiveUserOptionsGroupedByBranch();

  return (
    <StyledSearchMenu
      anchorEl={anchorEl}
      options={activeUserOptions}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      HeaderComponent={ClearMenuHeader}
      HeaderComponentProps={{
        placeholder: "Search by name, branch or role...",
        getNoResultsMessage,
        pickedUserId,
        onClear,
      }}
      onClose={onClose}
      excludeIds={excludeIds}
      disableScrollLock={true}
    >
      {({ key, isHeading, value }) => (
        <GroupBranchedUserOption
          key={key}
          isHeading={isHeading}
          value={value}
          UserOptionProps={
            !isHeading
              ? {
                  userId: key,
                  onPickUser,
                }
              : null
          }
        />
      )}
    </StyledSearchMenu>
  );
};

UserPickerWithClear.propTypes = {
  anchorEl: domNodeType,
  onPickUser: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  pickedUserId: PropTypes.number,
  onClear: PropTypes.func,
  excludeIds: entityIdListType,
};

UserPickerWithClear.defaultProps = {
  anchorEl: null,
  onClose: null,
  pickedUserId: null,
  onClear: null,
  excludeIds: [],
};

export default UserPickerWithClear;

const StyledSearchMenu = styled(SearchMenu)`
  .MuiPaper-root {
    min-width: 280px;
    max-width: 280px;
    max-height: 300px;
  }
`;
