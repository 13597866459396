import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import {
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START,
  JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE,
  JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE,
  JOB_ITEM_DEPENDANCY_SEGMENT_FLOW,
  JOB_ITEM_DEPENDANCY_SEGMENT_TYPE,
  DATA_VALUE_HANDLE_LEFT
} from "../constants";
import {
  isBefore,
  isAfter,
  isSameOrBefore,
  maxDate,
  minDate,
  isSameOrAfter,
  differenceInCalendarDays
} from "../dates";
import { FrameworkException } from "../exceptions/FrameworkException";
import { asDecimal } from "../math";
import { entityIdType } from "../types/entityTypes";

export const jobItemDependancyShape = {
  id: entityIdType.isRequired,
  jobId: entityIdType.isRequired,
  parentJobItemId: entityIdType.isRequired,
  childJobItemId: entityIdType.isRequired,
  dependancyTypeId: entityIdType.isRequired,
  lagDays: PropTypes.number.isRequired,
  isFloat: PropTypes.bool.isRequired
};

export const jobItemDependancySegmentShape = {
  jobItemDependancyId: entityIdType.isRequired,
  jobItemStartDate: PropTypes.string,
  jobItemEndDate: PropTypes.string,
  bridgeSide: PropTypes.oneOf(values(JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE)),
  bridgeDate: PropTypes.string.isRequired,
  isConflict: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFloat: PropTypes.bool.isRequired,
  segmentType: PropTypes.oneOf(values(JOB_ITEM_DEPENDANCY_SEGMENT_TYPE))
    .isRequired
};

export const jobItemDependancyEntityType = PropTypes.shape(
  jobItemDependancyShape
);

export const jobItemDependancySegmentType = PropTypes.shape(
  jobItemDependancySegmentShape
);

export const getJobId = entity => entity.jobId;

export const getParentJobItemId = entity => entity.parentJobItemId;

export const getChildJobItemId = entity => entity.childJobItemId;

export const getDependancyTypeId = entity => entity.dependancyTypeId;

export const getLagDays = ({ lagDays }) => asDecimal(lagDays);

export const getIsFloat = ({ isFloat }) => Boolean(isFloat);

export const calculateDependancyLagDays = (
  dependancyTypeId,
  parentJobItem,
  childJobItem
) => {
  if (!parentJobItem || !childJobItem) return 0;

  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      return differenceInCalendarDays(
        childJobItem.estimatedStartDate,
        parentJobItem.estimatedStartDate
      );
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      return differenceInCalendarDays(
        childJobItem.estimatedEndDate,
        parentJobItem.estimatedStartDate
      );
    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      return (
        differenceInCalendarDays(
          childJobItem.estimatedStartDate,
          parentJobItem.estimatedEndDate
        ) - 1
      );
    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      return differenceInCalendarDays(
        childJobItem.estimatedEndDate,
        parentJobItem.estimatedEndDate
      );
    default:
      throw new FrameworkException(
        `Unknown Job Item Dependancy Type ${dependancyTypeId}`
      );
  }
};

export const getJobItemDependancyTypeFromHandles = (startHandle, endHandle) => {
  if (startHandle === DATA_VALUE_HANDLE_LEFT) {
    if (endHandle === DATA_VALUE_HANDLE_LEFT)
      return JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START;
    return JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END;
  }

  if (endHandle === DATA_VALUE_HANDLE_LEFT)
    return JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START;
  return JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END;
};

export const getJobItemDependancySegmentBridgeDate = (
  dependancyTypeId,
  parentJobItem,
  childJobItem
) => {
  if (!parentJobItem || !childJobItem) return "";

  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      return minDate(
        parentJobItem.estimatedStartDate,
        childJobItem.estimatedStartDate
      );

    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      return minDate(
        parentJobItem.estimatedStartDate,
        childJobItem.estimatedEndDate
      );

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      return maxDate(
        parentJobItem.estimatedEndDate,
        childJobItem.estimatedStartDate
      );

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      return maxDate(
        parentJobItem.estimatedEndDate,
        childJobItem.estimatedEndDate
      );

    default:
      throw new FrameworkException(
        `Invalid Job Item Dependancy Type ${dependancyTypeId}`
      );
  }
};

export const getJobItemDependancySegmentBridgeSide = (
  dependancyTypeId,
  parentJobItem,
  childJobItem
) => {
  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.LEFT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      if (
        differenceInCalendarDays(
          parentJobItem.estimatedStartDate,
          childJobItem.estimatedEndDate
        ) < 2
      )
        return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.LEFT;

      return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.RIGHT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      if (
        differenceInCalendarDays(
          parentJobItem.estimatedEndDate,
          childJobItem.estimatedStartDate
        ) < -1
      )
        return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.LEFT;

      return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.RIGHT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      return JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.RIGHT;

    default:
      throw new FrameworkException(
        `Invalid Job Item Dependancy Type ${dependancyTypeId}`
      );
  }
};

export const getJobItemDependancySegmentCircleSide = (
  dependancyTypeId,
  parentJobItem,
  childJobItem,
  isParent
) => {
  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.LEFT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      if (isParent) return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.LEFT;

      return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.RIGHT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      if (isParent) return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.RIGHT;

      return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.LEFT;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      return JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.RIGHT;

    default:
      throw new FrameworkException(
        `Invalid Job Item Dependancy Type ${dependancyTypeId}`
      );
  }
};

export const getJobItemStartDate = (parentJobItem, childJobItem, isParent) => {
  if (isParent) return parentJobItem.estimatedStartDate;

  return childJobItem.estimatedStartDate;
};

export const getJobItemEndDate = (parentJobItem, childJobItem, isParent) => {
  if (isParent) return parentJobItem.estimatedEndDate;

  return childJobItem.estimatedEndDate;
};

export const getJobItemDays = (parentJobItem, childJobItem, isParent) => {
  if (isParent)
    return differenceInCalendarDays(
      parentJobItem.estimatedEndDate,
      parentJobItem.estimatedStartDate
    );

  return differenceInCalendarDays(
    childJobItem.estimatedEndDate,
    childJobItem.estimatedStartDate
  );
};

const getJobItemDependancySegmentType = (
  jobItemStartDate,
  jobItemEndDate,
  circleSide,
  bridgeSide,
  bridgeDate,
  direction,
  flow
) => {
  let isSnake = false;
  if (flow === JOB_ITEM_DEPENDANCY_SEGMENT_FLOW.IN) {
    if (circleSide === JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE.LEFT) {
      if (bridgeSide === JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.LEFT) {
        isSnake = isAfter(bridgeDate, jobItemStartDate);
      } else {
        isSnake = isSameOrAfter(bridgeDate, jobItemStartDate);
      }
    } else if (bridgeSide === JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.RIGHT) {
      isSnake = isBefore(bridgeDate, jobItemEndDate);
    } else {
      isSnake = isSameOrBefore(bridgeDate, jobItemEndDate);
    }
  }

  return JOB_ITEM_DEPENDANCY_SEGMENT_TYPE[
    `CIRCLE_${circleSide}_${direction}_${isSnake ? "SNAKE" : "CURVE"}_${flow}`
  ];
};

export const createCircleJobItemDependancySegment = (
  jobItemDependancy,
  parentJobItem,
  childJobItem,
  isParent,
  direction,
  isConflict
) => {
  const circleSide = getJobItemDependancySegmentCircleSide(
    getDependancyTypeId(jobItemDependancy),
    parentJobItem,
    childJobItem,
    isParent
  );

  const jobItemStartDate = getJobItemStartDate(
    parentJobItem,
    childJobItem,
    isParent
  );

  const jobItemEndDate = getJobItemEndDate(
    parentJobItem,
    childJobItem,
    isParent
  );

  const bridgeDate = getJobItemDependancySegmentBridgeDate(
    getDependancyTypeId(jobItemDependancy),
    parentJobItem,
    childJobItem
  );

  const bridgeSide = getJobItemDependancySegmentBridgeSide(
    getDependancyTypeId(jobItemDependancy),
    parentJobItem,
    childJobItem
  );

  const flow = isParent
    ? JOB_ITEM_DEPENDANCY_SEGMENT_FLOW.OUT
    : JOB_ITEM_DEPENDANCY_SEGMENT_FLOW.IN;

  const segmentType = getJobItemDependancySegmentType(
    jobItemStartDate,
    jobItemEndDate,
    circleSide,
    bridgeSide,
    bridgeDate,
    direction,
    flow
  );

  const isFloat = getIsFloat(jobItemDependancy);

  return {
    jobItemDependancyId: jobItemDependancy.id,
    jobItemStartDate,
    jobItemEndDate,
    bridgeSide,
    bridgeDate,
    segmentType,
    isConflict,
    isSelected: false,
    isFloat
  };
};

export const createBridgeJobItemDependancySegment = startDependancySegment => {
  const {
    jobItemDependancyId,
    bridgeSide,
    bridgeDate,
    isConflict,
    isFloat
  } = startDependancySegment;
  return {
    jobItemDependancyId,
    bridgeDate,
    isConflict,
    isFloat,
    isSelected: false,
    segmentType: JOB_ITEM_DEPENDANCY_SEGMENT_TYPE[`BRIDGE_${bridgeSide}`]
  };
};

export const getDependancyLinkTypeSymbol = (jobItemDependancy, jobItemId) => {
  const { dependancyTypeId, parentJobItemId } = jobItemDependancy;

  switch (dependancyTypeId) {
    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START:
      if (parentJobItemId === jobItemId) return `S→S`;
      return `S←S`;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END:
      if (parentJobItemId === jobItemId) return `S→E`;
      return `E←S`;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START:
      if (parentJobItemId === jobItemId) return `E→S`;
      return `S←E`;

    case JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END:
      if (parentJobItemId === jobItemId) return `E→E`;
      return `E←E`;

    default:
      throw new FrameworkException(
        `Invalid Job Item Dependancy Type ${dependancyTypeId}`
      );
  }
};

export const getLinkedJobItemId = (jobItemDependancy, jobItemId) => {
  if (getParentJobItemId(jobItemDependancy) === jobItemId)
    return getChildJobItemId(jobItemDependancy);

  return getParentJobItemId(jobItemDependancy);
};
