import { ENTITY_CLASS_INVOICE } from "../constants";
import { getInvoiceAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const transformInvoiceBootstrap = response => {
  const { modelSet, previousIssuedInvoiceSummary } = response;
  const { data } = createModelSetParser(ENTITY_CLASS_INVOICE)(modelSet);
  return { previousIssuedInvoiceSummary, data };
};

export const fetchInvoiceBootstrapAPI = invoiceId =>
  API.fetch(`/invoices/${invoiceId}`, { bootstrap: true })
    .then(res => res.data)
    .then(transformInvoiceBootstrap);

export const saveInvoiceAPI = invoice =>
  API.put(`/invoices/${invoice.id}`, getInvoiceAsMessage(invoice))
    .then(({ data }) => data)
    .then(createModelSetParser(ENTITY_CLASS_INVOICE))
    .then(({ data }) => data);
