import { ValueFormatEnum } from "@streamtimefe/entities";

export type TTimeSeriesFormats =
  | typeof ValueFormatEnum.Currency
  | typeof ValueFormatEnum.Minutes
  | typeof ValueFormatEnum.Number
  | typeof ValueFormatEnum.Percentage;

export const TimeSeriesFormats = [
  ValueFormatEnum.Currency,
  ValueFormatEnum.Minutes,
  ValueFormatEnum.Number,
  ValueFormatEnum.Percentage,
];
