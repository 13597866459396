import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_RATE_CARDS } from "../../../lib/constants";
import { selectPropsRateCardId } from "../index";

export const selectStateRateCards = state =>
  state.entities[ENTITY_NAME_RATE_CARDS];

export const selectRateCard = createCachedSelector(
  [selectStateRateCards, selectPropsRateCardId],
  (rateCards, rateCardId) => get(rateCards, `byId.${rateCardId}]`)
)(selectPropsRateCardId);

export const useRateCard = rateCardId =>
  useSelector(state => selectRateCard(state, { rateCardId }));
