import { isEmpty } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_SUB_ITEMS,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
  JOB_ITEM_SUB_ITEM_DELETE,
  JOB_ITEM_SUB_ITEM_DELETE_ERROR,
  JOB_ITEM_SUB_ITEM_EDIT,
} from "../../../../lib/constants";
import { isDeleted } from "../../../../lib/entities/jobItemSubItemEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import jobItemSubItemOrderByJobItemIdReducer from "./jobItemSubItemOrderByJobItemIdReducer";

const idsByJobItemIdReducer = createEntityIndexedArrayReducer(
  (entity) => entity.jobItemId
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobItemId: idsByJobItemIdReducer(
      state.idsByJobItemId,
      changedEntities
    ),
  };

  nextState = jobItemSubItemOrderByJobItemIdReducer(
    state,
    nextState,
    changedEntities
  );

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changeEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEM_SUB_ITEMS],
    isDeleted
  );
  return reduceChangedEntities(state, changeEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_ITEM_SUB_ITEMS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const editJobItemSubItemsReducer = (state, action) => {
  const { jobItemSubItems } = action.payload;

  if (jobItemSubItems && jobItemSubItems.length > 0) {
    return reduceChangedEntities(
      state,
      jobItemSubItems.map((jobItemSubItem) => ({
        prevEntity: state.byId[jobItemSubItem.new.id],
        newEntity: jobItemSubItem.new,
      }))
    );
  }

  return state;
};

const saveJobItemErrorReducer = (state, action) => {
  const { previousJobItemSubItems } = action.payload;

  let changedEntities = [];

  if (previousJobItemSubItems && previousJobItemSubItems.length > 0) {
    changedEntities = previousJobItemSubItems.map((jobItemSubItem) => {
      if (jobItemSubItem.id < 0) {
        return {
          prevEntity: state.byId[jobItemSubItem.id],
        };
      }
      return {
        prevEntity: state.byId[jobItemSubItem.id],
        newEntity: jobItemSubItem,
      };
    });
  }

  return reduceChangedEntities(state, changedEntities);
};

const saveJobItemSuccessReducer = (state, action) => {
  const { jobItemSubItems } = action.payload;

  let changedEntities = [];

  if (jobItemSubItems && jobItemSubItems.length > 0) {
    changedEntities = jobItemSubItems
      .filter((jobItemSubItem) => jobItemSubItem.id < 0)
      .map((jobItemSubItem) => ({ prevEntity: state.byId[jobItemSubItem.id] }));
  }

  return reduceChangedEntities(state, changedEntities);
};

const deleteJobItemSubItemReducer = (state, action) => {
  const { jobItemSubItem } = action.payload;
  const prevEntity = state.byId[jobItemSubItem.id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
    },
  ]);
};

const undoDeleteJobItemSubItemReducer = (state, action) => {
  const { jobItemSubItemId, jobItemSubItem } = action.payload;
  const prevEntity = state.byId[jobItemSubItemId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: jobItemSubItem,
    },
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_ITEM_SUB_ITEM_EDIT]: editJobItemSubItemsReducer,
    [JOB_ITEM_SAVED]: saveJobItemSuccessReducer,
    [JOB_ITEM_SAVE_ERROR]: saveJobItemErrorReducer,
    [JOB_ITEM_SUB_ITEM_DELETE]: deleteJobItemSubItemReducer,
    [JOB_ITEM_SUB_ITEM_DELETE_ERROR]: undoDeleteJobItemSubItemReducer,
  }
);
