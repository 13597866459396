import { get, isEmpty, isEqual } from "lodash-es";

export default (state, payload = [], subEntityFieldName) => {
  const changedEntities = [];
  const data = Array.isArray(payload) ? payload : Object.values(payload);

  data.forEach(parentEntity => {
    const newEntity = get(parentEntity, subEntityFieldName);

    if (isEmpty(newEntity)) return;

    const prevEntity = get(state, `byId.${newEntity.id}`);

    if (!isEqual(prevEntity, newEntity))
      changedEntities.push({ prevEntity, newEntity });
  });

  return changedEntities;
};
