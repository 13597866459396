import { pick } from "lodash-es";
import * as PropTypes from "prop-types";
import { applyInverseExchangeRate } from "st-shared/lib";

import { QUOTE_STATUS_ID_DELETED } from "../constants";
import { asDecimal, round2dp } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import { addressEntityType } from "./addressEntity";
import { contactEntityType } from "./contactEntity";
import { currencyEntityType } from "./currencyEntity";

export const quoteShape = {
  id: entityIdType.isRequired,
  currency: currencyEntityType,
  exchangeRate: entityFieldDecimalType,
  jobId: entityIdType.isRequired,
  contact: contactEntityType,
  address: addressEntityType,
  name: PropTypes.string,
  number: PropTypes.string,
  introduction: PropTypes.string,
  languageOptions: PropTypes.string,
  discount: entityFieldDecimalType,
  purchaseOrderNumber: PropTypes.string,
  terms: PropTypes.string,
  quoteStatus: typeEntityType.isRequired,
  quoteDate: PropTypes.string,
  quoteCurrencyTotalAmountExTax: entityFieldDecimalType,
  quoteCurrencyTotalAmountInvoicedExTax: entityFieldDecimalType,
  totalAmountExTax: PropTypes.number.isRequired,
};

export const quoteEntityType = PropTypes.shape(quoteShape);

export const isDeleted = ({ quoteStatus }) =>
  quoteStatus.id === QUOTE_STATUS_ID_DELETED;

export const getQuoteStatusId = ({ quoteStatus }) => quoteStatus.id;

export const getTotalAmountExTax = ({ totalAmountExTax }) => totalAmountExTax;

const getDisplayOptions = (quote) =>
  pick(quote, [
    "displayQuantities",
    "displayHeadings",
    "displayHeadingDescriptions",
    "displayHeadingSubtotals",
    "displayLineItems",
    "displayLineItemDescriptions",
    "displayUnitRates",
    "displaySubtotals",
    "displayJobNumber",
    "displayPurchaseOrderNumber",
    "displayTotal",
    "displayCurrencySymbols",
    "displayTeam",
    "displayIntro",
    "displayQuoteNumber",
    "displayTerms",
    "displayTaxes",
    "displayBranchExternalName",
    "displayLineItemsInclusiveOfTax",
    "displayTaxNameOnLineItems",
  ]);

const LANGUAGE_OPTION_DEFAULTS = {
  quote: "Quote",
  number: "Quote Number",
  purchaseOrderNumber: "PO Number",
  issuedOn: "Issued on",
  subtotal: "Subtotal",
  discount: "Discount",
  total: "Total",
  team: "TEAM",
  approve: "Approve",
  decline: "Decline",
  acceptThisQuote: "ACCEPT THIS QUOTE",
  agreeThisQuote: "I agree to, and accept, this quote",
  name: "NAME",
  signature: "SIGNATURE",
  acceptDate: "DATE",
  additionalComments: "ADDITIONAL COMMENTS",
  headerFrom: "Your quote from",
  from: "from",
  hasSent: "has sent you a quote.",
  view: "All you need to do is Accept or Decline using the link below.",
};

const getLanguageOptions = ({ languageOptions }) => {
  const options = JSON.parse(languageOptions);

  return Object.assign(
    {},
    LANGUAGE_OPTION_DEFAULTS,
    pick(options, [
      "quote",
      "number",
      "jobNumber",
      "purchaseOrderNumber",
      "issuedOn",
      "subtotal",
      "discount",
      "total",
      "team",
      "approve",
      "decline",
      "acceptThisQuote",
      "agreeThisQuote",
      "name",
      "signature",
      "acceptDate",
      "additionalComments",
      "headerFrom",
      "from",
      "hasSent",
      "view",
    ])
  );
};

export const quoteDocumentMethods = {
  getLineItemParentId: ({ id }) => id,
  getAddress: ({ address }) => address,
  getNumber: ({ number }) => number,
  setNumber: (document, value) => ({ ...document, number: value }),
  getName: ({ name }) => name,
  setName: (document, value) => ({ ...document, name: value }),
  getPurchaseOrderNumber: ({ purchaseOrderNumber }) =>
    purchaseOrderNumber || "",
  setPurchaseOrderNumber: (document, value) => ({
    ...document,
    purchaseOrderNumber: value,
  }),
  getIntroduction: ({ introduction }) => introduction || "",
  setIntroduction: (document, value) => ({ ...document, introduction: value }),
  getTerms: ({ terms }) => terms || "",
  setTerms: (document, value) => ({ ...document, terms: value }),
  getDiscount: ({ discount }) => asDecimal(discount || 0),
  setDiscount: (document, value) => ({
    ...document,
    discount: asDecimal(value),
  }),
  getQuoteDate: ({ quoteDate }) => quoteDate,
  setQuoteDate: (document, value) => ({ ...document, quoteDate: value }),
  getDisplayOptions,
  getLanguageOptions,
  setDisplayAndLanguageOptions: (document, data) => ({
    ...document,
    languageOptions: data.languageOptions,
    ...getDisplayOptions(data),
  }),
};

export const getQuoteCurrencyTotalAmountExTax = ({
  quoteCurrencyTotalAmountExTax,
}) => quoteCurrencyTotalAmountExTax;

export const getExchangeRate = ({ exchangeRate }) => exchangeRate;

export const getJobCurrencyTotalAmountExTax = (quote) =>
  round2dp(
    applyInverseExchangeRate(
      getQuoteCurrencyTotalAmountExTax(quote),
      getExchangeRate(quote)
    )
  );
