import { Entity } from "@streamtimefe/entities";
import { call, put, select } from "redux-saga/effects";

import { createJobItemUserAPI } from "../../../lib/API/jobItemUserAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  SCHEDULE_JOB_ITEM_ADD_USER,
  SCHEDULE_JOB_ITEM_ADD_USER_ERROR,
  SCHEDULE_JOB_ITEM_ADD_USER_SAVED,
  SCHEDULE_JOB_ITEM_ADD_USER_SAVING,
} from "../../../lib/constants";
import {
  getJobId,
  getJobItemStatusId,
} from "../../../lib/entities/jobItemEntity";
import {
  getJobItemUserStatusFromJobItemStatus,
  JOB_ITEM_USER_DEFAULTS,
} from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectDefaultJobItemUserSellRateByJobItemIdUserId } from "../../selectors/rateSelectors";

function* addJobItemUser(action) {
  const createId = Entity.temporaryId();

  try {
    const { jobItemId, userId } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });
    const jobId = getJobId(jobItem);

    const jobItemUserStatus = getJobItemUserStatusFromJobItemStatus(
      getJobItemStatusId(jobItem)
    );

    const jobCurrencySellRate = yield select(
      selectDefaultJobItemUserSellRateByJobItemIdUserId,
      {
        jobItemId,
        userId,
      }
    );

    const jobItemUser = {
      ...JOB_ITEM_USER_DEFAULTS,
      id: createId,
      jobId,
      jobItemId,
      userId,
      jobItemUserStatus,
      jobCurrencySellRate,
    };

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEM_USERS]: [jobItemUser],
      })
    );

    yield put(
      createAction(SCHEDULE_JOB_ITEM_ADD_USER_SAVING, {
        ...action.payload,
        jobItemUser,
      })
    );

    const { data } = yield call(createJobItemUserAPI, jobItemUser);

    yield put(
      createAction(SCHEDULE_JOB_ITEM_ADD_USER_SAVED, {
        ...action.payload,
        jobItemUser,
        data,
      })
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    yield put(
      createAction(SCHEDULE_JOB_ITEM_ADD_USER_ERROR, {
        ...action.payload,
        createId,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchAddJobItemUser() {
  yield takeLatestBy(
    [SCHEDULE_JOB_ITEM_ADD_USER],
    addJobItemUser,
    (action) => action.payload.jobId
  );
}
