import type { TRateCard } from "@streamtimefe/entities";
import { isEmpty, values } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../../entities";
import { rateCardObj } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { defaultSortOptions } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { storeActionAddListener } from "../../helpers";
import { addGlobalStore } from "../../helpers/global";
import type { ChangedEntity, EntityStoreState } from "..";
import { byIdReducer, parseEntityPayload, parseRemovedEntities } from "..";

interface RateCardEntityStoreState extends EntityStoreState<TRateCard> {
  options: SearchOption<TRateCard>[];
}

export const useRateCardEntityStore = create<RateCardEntityStoreState>(
  function (set, get) {
    return {
      entities: {},
      options: [],
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.RateCard])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.RateCard]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.RateCard) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TRateCard>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);

        const options = values(entities)
          .map((rateCard) => rateCardObj(rateCard).getAsOption())
          .sort(defaultSortOptions);

        set({
          entities,
          options,
        });
      },
    };
  }
);

export function rateCardEntityStoreInit() {
  addGlobalStore(["entities", EntityListKey.RateCard], rateCardEntityStore);
  storeActionAddListener(
    "entitiesReceived",
    rateCardEntityStore().receiveEntities
  );
  storeActionAddListener(
    "entitiesRemoved",
    rateCardEntityStore().removeEntities
  );
}

export function rateCardEntityStore() {
  return useRateCardEntityStore.getState();
}

export function useRateCardEntity(entityId: TEntityId) {
  return useRateCardEntityStore((s) => s.entities[entityId]);
}

export function useRateCardOptions() {
  return useRateCardEntityStore((s) => s.options);
}
