import type { ConstEnum } from "@streamtimefe/types";

export const NumericStateEnum = {
  Neutral: 1,
  Blank: 2,
  Warning: 3,
  Success: 4,
} as const;

export type TNumericStateEnum = ConstEnum<typeof NumericStateEnum>;

export const NumericStateComparatorEnum = {
  Equals: 1,
  NotEquals: 2,
  GreaterThan: 3,
  GreaterThanOrEqual: 4,
  LessThan: 5,
  LessThanOrEqual: 6,
} as const;

export type TNumericStateComparatorEnum = ConstEnum<
  typeof NumericStateComparatorEnum
>;

export type TNumericStateCalculator = {
  threshold: number;
  comparator: TNumericStateComparatorEnum;
  state: TNumericStateEnum;
};
