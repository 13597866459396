import { useEffect, useMemo } from "react";
import { ReportingColumn } from "st-shared/entities";
import { plate } from "st-shared/external";

import {
  ColumnPlugin,
  ELEMENT_COLUMN,
  getColumnOnSelectItem,
} from "../plugins/column";
import { ComboboxContent } from "./ComboboxItem";

type Props = {
  columns: ReportingColumn[];
};

export function ColumnCombobox({ columns }: Props) {
  const editor = plate.useEditorRef();

  const { trigger } = plate.getPluginOptions<ColumnPlugin>(
    editor,
    ELEMENT_COLUMN
  );

  const items = useMemo(
    () =>
      columns.map((column) => ({
        key: column.id,
        text: column.name,
        data: column,
      })),
    [columns]
  );

  return (
    <div onMouseDown={(e) => e.preventDefault()}>
      <Combobox
        id={ELEMENT_COLUMN}
        trigger={trigger!}
        controlled
        onSelectItem={(editor, item) => getColumnOnSelectItem(editor, item.key)}
        items={items}
      />
    </div>
  );
}

function Combobox({
  id,
  trigger,
  searchPattern,
  onSelectItem,
  controlled,
  maxSuggestions,
  filter,
  sort,
  disabled: _disabled,
  ...props
}: plate.ComboboxProps) {
  const storeItems = plate.useComboboxSelectors.items();
  const disabled =
    _disabled ?? (storeItems.length === 0 && !props.items?.length);

  const focusedEditorId = plate.useEventEditorSelectors.focus?.();
  const combobox = plate.useComboboxControls();
  const activeId = plate.useComboboxSelectors.activeId();
  const selectionDefined = plate.useEditorSelector(
    (editor) => !!editor.selection,
    []
  );
  const editorId = plate.usePlateSelectors().id();

  useEffect(() => {
    plate.comboboxActions.setComboboxById({
      id,
      trigger,
      searchPattern,
      controlled,
      onSelectItem,
      maxSuggestions,
      filter,
      sort,
    });
  }, [
    id,
    trigger,
    searchPattern,
    controlled,
    onSelectItem,
    maxSuggestions,
    filter,
    sort,
  ]);

  if (
    !combobox ||
    !selectionDefined ||
    focusedEditorId !== editorId ||
    activeId !== id ||
    disabled
  ) {
    return null;
  }

  return <ComboboxContent combobox={combobox} {...props} />;
}
