import { images } from "@streamtimefe/assets";
import { useRef, useState } from "react";
import { Button } from "st-shared/components";

import { openReportingTemplatesModal } from "../../lib/openReportingTemplatesModal.ts";
import { AddColumnMenu } from "../menus/AddColumnMenu.tsx";
import { emptyReportCss } from "./Report.css.ts";
import {
  emptyStateReporting,
  noDataSetsText,
  reportEmptyStateCss,
} from "./ReportEmptyState.css.ts";

export function ReportEmptyState() {
  const ref = useRef<HTMLButtonElement>(null);
  const [addAnchorEl, setAddAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  function openAddMenu() {
    setAddAnchorEl(ref.current);
  }

  function closeAddMenu() {
    setAddAnchorEl(null);
  }

  return (
    <div className={emptyReportCss}>
      <div className={reportEmptyStateCss}>
        <span>
          A blank slate. Ready for anything.
          <br />
          <br />
          <span className={noDataSetsText}>
            Ooh. Looks like there’s no data in this report.
            <br />
            Let’s remedy that.
          </span>
        </span>

        <img
          className={emptyStateReporting}
          alt=""
          src={images.emptyStateReporting}
        />
        <Button variant="primary" onClick={openReportingTemplatesModal}>
          Choose a template
        </Button>
        <Button variant="text" ref={ref} onClick={openAddMenu}>
          or add your first data source
        </Button>
        <AddColumnMenu
          anchorEl={ref.current!}
          listAnchorEl={addAnchorEl}
          onClose={closeAddMenu}
        />
      </div>
    </div>
  );
}
