import { put, select, takeEvery } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_BULK_SAVE,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DRAG_END_MULTIPLE,
} from "../../../lib/constants";
import { addDays, differenceInCalendarDays } from "../../../lib/dates";
import createAction from "../../helpers/createAction";
import { selectBaseScheduleLoggedTimeByBlockKey } from "../../selectors/scheduleBlockSelectors";

function* moveBlocks(action) {
  const { selectedBlockKeys } = action.payload;
  for (let i = 0; i < selectedBlockKeys.length; i++) {
    const blockKey = selectedBlockKeys[i];
    const { date, origin, ...payload } = action.payload;
    const daysMoved = differenceInCalendarDays(date, origin.date);
    const baseScheduleLoggedTime = yield select(
      selectBaseScheduleLoggedTimeByBlockKey,
      { blockKey }
    );

    yield put(
      createAction(SCHEDULE_BLOCK_DRAG_END, {
        ...payload,
        blockKey,
        isBulkAction: true,
        date: baseScheduleLoggedTime.date,
        origin: {
          ...origin,
          date: addDays(baseScheduleLoggedTime.date, -daysMoved),
          userId: baseScheduleLoggedTime.userId,
          jobId: baseScheduleLoggedTime.jobId,
          jobItemId: baseScheduleLoggedTime.jobItemId,
          jobItemUserId: baseScheduleLoggedTime.jobItemUserId,
        },
      })
    );
  }
  yield put(
    createAction(SCHEDULE_BLOCK_BULK_SAVE, {
      ...action.payload,
      sagaType: SCHEDULE_BLOCK_DRAG_END,
    })
  );
}

function* watchMoveMultipleBlocks() {
  yield takeEvery([SCHEDULE_BLOCK_DRAG_END_MULTIPLE], moveBlocks);
}

export default watchMoveMultipleBlocks;
