import { Entity } from "@streamtimefe/entities";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ActivityEntryParentTypes,
  createActivityEntryParentType,
} from "st-shared/entities/ActivityEntryParentType";
import {
  ActivityEntryTypes,
  createActivityEntryType,
} from "st-shared/entities/ActivityEntryType";
import { pluralise } from "st-shared/lib/pluralise";
import { useLoggedInUserId, usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT,
  ACTIVITY_ENTRY_SAVE,
  ACTIVITY_ENTRY_THREAD_FETCH_REQUEST,
} from "../../../../lib/constants";
import {
  activityEntryEntityType,
  createActivityEntry,
  getCreatedDateTimeFormatted,
  getThreadActivityEntryCount,
} from "../../../../lib/entities/activityEntryEntity";
import { entityIdType } from "../../../../lib/types/entityTypes";
import createAction from "../../../../redux/helpers/createAction";
import { useIsFetchingThread } from "../../../../redux/selectors/activityEntrySelectors";
import { useIdsByThreadId } from "../../../../redux/selectors/jobActivitySelectors";
import Flex from "../../../elements/Flex";
import LoadingSpinner from "../../../elements/LoadingSpinner";
import ActivityEntryConnector from "../../../modules/Connectors/ActivityEntryConnector";
import CommentEditor from "../../../modules/DraftJsEditor/CommentEditor";
import ErrorBoundary from "../../../modules/ErrorBoundary";
import UserIconName from "../../../modules/User/UserIconName";
import Comment, {
  CommentWrapper,
} from "../JobActivityEntries/JobActivityEntry/Comment";

const JobPostComments = ({ id, activityEntry }) => {
  const loggedInUserId = useLoggedInUserId();
  const { canAccessCollaboration } = usePermissions();
  const activityEntryIds = useIdsByThreadId(id);
  const isFetchingThread = useIsFetchingThread(id);
  const dispatch = useDispatch();

  const scrollRef = useRef();

  const [newCommentId, setNewCommentId] = useState(Entity.temporaryId());
  const [newCommentHtml, setNewCommentHtml] = useState("");
  const [mentionedUserIds, setMentionedUserIds] = useState("");

  useEffect(() => {
    dispatch(createAction(ACTIVITY_ENTRY_THREAD_FETCH_REQUEST, { id }));
  }, []);

  const doSavePostComment = (
    newActivityEntry,
    newMentionedUserIds,
    onSuccess
  ) =>
    dispatch(
      createAction(ACTIVITY_ENTRY_SAVE, {
        activityEntry: newActivityEntry,
        mentionedUserIds: newMentionedUserIds,
        onSuccess,
      })
    );

  const getThreadCount = () => {
    return isFetchingThread
      ? getThreadActivityEntryCount(activityEntry)
      : activityEntryIds.length;
  };

  const getNewCommentMetaData = () => {
    return JSON.stringify({ message: newCommentHtml });
  };

  const getIsValidMetaDataSize = () => {
    const metaDataSize = getNewCommentMetaData().length;
    return metaDataSize <= ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT;
  };

  const getFormValidationMessages = () => {
    const messages = [];

    if (!newCommentHtml) messages.push("Enter a comment");

    if (!getIsValidMetaDataSize()) messages.push("This comment is too long");

    return messages;
  };

  const onChangeNewCommentHtml = (html, newMentionedUserIds) => {
    setNewCommentHtml(html);
    setMentionedUserIds(newMentionedUserIds);
  };

  const saveComment = () => {
    doSavePostComment(
      createActivityEntry({
        id: newCommentId,
        activityEntryType: createActivityEntryType(ActivityEntryTypes.Comment),
        activityEntryParentType: createActivityEntryParentType(
          ActivityEntryParentTypes.ActivityEntryThread
        ),
        parentEntityId: id,
        metaData: getNewCommentMetaData(),
        createdUserId: loggedInUserId,
      }),
      mentionedUserIds
    );

    setNewCommentId(Entity.temporaryId());
    setNewCommentHtml("");
    setMentionedUserIds("");

    scrollToBottom();
  };

  const scrollToBottom = () => {
    window.requestAnimationFrame(() =>
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      })
    );
  };

  const hasComments = () => {
    return getThreadCount() > 0;
  };

  if (!hasComments() && !canAccessCollaboration) return null;

  return (
    <Wrapper>
      <Heading>
        {hasComments() && `${getThreadCount()} `}
        {pluralise(getThreadCount(), "Comment")}
      </Heading>
      <Comments ref={scrollRef}>
        {hasComments() && isFetchingThread && <LoadingSpinner inline />}

        {hasComments() &&
          !isFetchingThread &&
          activityEntryIds.map((activityEntryId) => (
            <ActivityEntryConnector key={activityEntryId} id={activityEntryId}>
              {(activityEntryLocal) => (
                <ErrorBoundary key={activityEntryLocal.id} Wrapper={null}>
                  <Comment
                    activityEntry={activityEntryLocal}
                    dateTimeFormatter={getCreatedDateTimeFormatted}
                  />
                </ErrorBoundary>
              )}
            </ActivityEntryConnector>
          ))}

        {!hasComments() && (
          <EmptyMessage>
            Wow, no chat. Someone address the elephant in the room, stat!
          </EmptyMessage>
        )}
      </Comments>
      {canAccessCollaboration && (
        <NewComment>
          <UserIconName
            size={25}
            nameWidth="0"
            style={{ width: "unset", height: "100%" }}
            id={loggedInUserId}
            showDisplayName={false}
          />
          <CommentEditor
            key={newCommentId}
            onChange={onChangeNewCommentHtml}
            onSave={saveComment}
            formValidationMessages={getFormValidationMessages()}
          />
        </NewComment>
      )}
    </Wrapper>
  );
};

JobPostComments.propTypes = {
  id: entityIdType.isRequired,
  activityEntry: activityEntryEntityType.isRequired,
};

export default JobPostComments;

const Wrapper = styled(Flex)`
  width: 35%;
  min-width: 400px;
  border-left: var(--border-thin-bright);
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
`;

const Heading = styled.div`
  color: var(--color-charcoal);
  font-size: 14px;
  font-weight: 700;
  line-height: 125%;
  padding: 36px 20px 0 20px;
  text-transform: uppercase;
`;

const Comments = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px 20px 20px 20px;
`;

const NewComment = styled(CommentWrapper)`
  border-top: var(--border-thin-light);
  flex-direction: row;
  padding: 10px 15px 10px 10px;
  margin-left: 10px;
`;

const EmptyMessage = styled.div`
  color: var(--color-gray-dark);
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  button:hover {
    color: var(--color-gray);
  }
`;
