import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATE_FORMAT_DATE_SHORT_MONTH,
  JOB_TIMELINE_ITEM_ROW_HEIGHT
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import {
  getJobItemName,
  jobItemEntityType
} from "../../../lib/entities/jobItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";
import Flex from "../../elements/Flex";
import Spacer from "../../elements/Spacer";
import { DateCell } from "../DateCell";
import { JobTimelineGroupUnderline } from "../JobTimelineGroupUnderline";

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props)
});

const mapDispatch = null;

class JobTimelineItemGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    jobItem: jobItemEntityType.isRequired
  };

  render() {
    const { jobItem } = this.props;
    const { estimatedStartDate, estimatedEndDate } = jobItem;

    return (
      <Wrapper>
        <Spacer w={45} />
        <JobTimelineGroupUnderline>
          <JobItemName>{getJobItemName(jobItem)}</JobItemName>
          <DateCell>
            {estimatedStartDate &&
              formatDate(estimatedStartDate, DATE_FORMAT_DATE_SHORT_MONTH)}
          </DateCell>
          <DateCell>
            {estimatedEndDate &&
              formatDate(estimatedEndDate, DATE_FORMAT_DATE_SHORT_MONTH)}
          </DateCell>
        </JobTimelineGroupUnderline>
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineItemGroup);

const Wrapper = styled(Flex)`
  position: relative;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  align-items: center;
  background-color: white;
`;

export const JobItemName = styled.div`
  width: calc(100% - 120px);
  font-size: 12px;
  color: var(--color-charcoal);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
