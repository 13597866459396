import { ReportingColumn, ReportingColumnType } from "st-shared/entities";

import {
  useReportingSavedSegmentColumnMetadataById,
  useReportingSavedSegmentDataSets,
} from "../../state/stores/savedSegmentSelectors";
import { ColumnError } from "./types";

export function useColumnError(
  column: ReportingColumn
): ColumnError | undefined {
  const columnMetadata = useReportingSavedSegmentColumnMetadataById(column.id);

  if (columnMetadata?.permission === false) {
    let text = "You dont have permission to see this column.";
    if (column.type === ReportingColumnType.Formula) {
      text =
        "This column contains a column that you dont have permission to see.";
    }
    return { type: "warn", text };
  } else if (columnMetadata?.compileFailure !== undefined) {
    let text = "";
    switch (columnMetadata.compileFailure) {
      case "circular":
        text =
          "This custom column formula includes a reference to a custom column formula that includes this column creating a circular dependancy.";
        break;
      case "deleted":
        text =
          "This custom column formula includes a references that no longer exist.";
        break;
      case "fail":
        text = "This custom column formula fails to compute.";
        break;
    }

    return { type: "fatal", text };
  }

  return undefined;
}

export function useColumnDatasetDeleted(column: ReportingColumn) {
  const dataSets = useReportingSavedSegmentDataSets();

  return (
    (column.type === ReportingColumnType.Data ||
      column.type === ReportingColumnType.Total) &&
    !dataSets[column.dataSetId]
  );
}
