import React from "react";
import { UserDisplayName } from "st-shared/module";
import styled from "styled-components";

import { entityIdType } from "../../../lib/types/entityTypes";
import { sendClearScheduleFilters } from "../../../lib/WebAppAPI/schedule";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { TextButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import EmptyMessage from "../ScheduleLayout/EmptyMessage";

class EmptySearchResultsGroupMessage extends React.PureComponent {
  static propTypes = {
    userId: entityIdType.isRequired,
  };

  clearFilters = () => sendClearScheduleFilters();

  render() {
    const { userId } = this.props;

    return (
      <Wrapper>
        <EmptyMessage>
          Hmmm, we can’t find any jobs for <UserDisplayName userId={userId} />{" "}
          with those filters. Might be worth{" "}
          <StyledTextButton onClick={this.clearFilters}>
            clearing all filters
          </StyledTextButton>
          ?
        </EmptyMessage>
      </Wrapper>
    );
  }
}

export default EmptySearchResultsGroupMessage;

const Wrapper = styled(Flex)`
  background: white;
  width: 100%;
  height: ${ScheduleUi.User.JobEmptyRowHeight}px;
  align-items: center;
  padding: 10px 15px 10px 50px;
`;

const StyledTextButton = styled(TextButton)`
  .MuiButton-label {
    padding-top: 0;
  }
`;
