import { all } from "redux-saga/effects";

import watchFetchScheduleLoggedTimes from "./sagas/watchFetchScheduleLoggedTimes";
import watchPurgeFetchScheduleLoggedTimes from "./sagas/watchPurgeFetchScheduleLoggedTimes";

export default function* scheduleUiSaga() {
  yield all([
    watchFetchScheduleLoggedTimes(),
    watchPurgeFetchScheduleLoggedTimes(),
  ]);
}
