import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import StyledIcon from "../../modules/StyledIcon";

const CalEventIcon = ({ ...props }) => (
  <SvgIcon viewBox="0 0 16 17" {...props}>
    <g clip-path="url(#clip0_1863_6814)">
      <path d="M9.99999 15.1666V13.8333H12.6667V7.16659H3.33332V9.83325H1.99999V3.16659H3.99999V1.83325H5.33332V3.16659H10.6667V1.83325H12V3.16659H14V15.1666H9.99999ZM5.33332 16.4999L4.39999 15.5666L6.11666 13.8333H0.666656V12.4999H6.11666L4.39999 10.7666L5.33332 9.83325L8.66666 13.1666L5.33332 16.4999ZM3.33332 5.83325H12.6667V4.49992H3.33332V5.83325Z" />
    </g>
    <defs>
      <clipPath id="clip0_1863_6814">
        <rect width="16" height="16" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default StyledIcon(CalEventIcon);
