import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  costingMethodObj,
  timeAllocationMethodObj,
  TimeAllocationMethods,
} from "st-shared/entities";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_HOURS,
  JOB_ITEM_SET_TIME_ALLOCATION_METHOD,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import {
  getInitialItemHoursInput,
  useJobItemHoursInput,
} from "../../../redux/selectors/jobItem/selectJobItemHoursInput";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemHoursContainerCss, itemHoursFieldCss } from "./ItemHours.css";

export function ItemHours() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const costingMethod = costingMethodObj(jobItem.costingMethod);
  const timeAllocationMethod = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  );

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemId,
    key: FOCUS_KEYS.ITEM_HOURS,
  });

  const [hours, setHours] = useState("");

  // eslint-disable-next-line prefer-const
  let [value, placeholder, warning] = useJobItemHoursInput(
    jobItemId,
    focused,
    hours
  );

  const dispatch = useDispatch();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!isJobEditable) return;
    const newValue = event.target.value;

    setHours(newValue);

    dispatch(createAction(JOB_ITEM_SET_HOURS, { jobItemId, value: newValue }));

    if (!timeAllocationMethod.isItem())
      dispatch(
        createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
          jobItemId,
          timeAllocationMethodId: TimeAllocationMethods.Item,
        })
      );
  }

  function onBlur() {
    setFocused(false);
  }

  function onFocus() {
    setHours(getInitialItemHoursInput(jobItem));
    setFocused(true);
  }

  const usedPlannedProgress =
    getTotalPlannedMinutes(jobItem) > 0 &&
    getTotalUsedMinutes(jobItem) <= getTotalPlannedMinutes(jobItem)
      ? Math.min(
          1,
          getTotalUsedMinutes(jobItem) / getTotalPlannedMinutes(jobItem)
        )
      : 0;

  const disabled = !isJobEditable || costingMethod.isBasedByUser();

  function setTimeAllocationMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_HOURS,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
        jobItemId,
        timeAllocationMethodId: TimeAllocationMethods.Item,
      })
    );
  }

  if (costingMethod.isBasedByUser()) {
    placeholder = value;
    value = "";
  }

  return (
    <div className={itemHoursContainerCss}>
      <ItemInputField
        ref={ref}
        as="time"
        containerClassName={itemHoursFieldCss}
        label="Hours"
        //width={columnWidths.hours}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        largeText
        disabled={disabled}
        disabledLook={
          !costingMethod.isBasedByUser() && !timeAllocationMethod.isItem()
        }
        warning={warning}
        progress={usedPlannedProgress}
      />
      {isJobEditable && !disabled && !timeAllocationMethod.isItem() && (
        <MethodLock
          tooltip={
            <>
              Time for this item is planned by{" "}
              <strong>individual team member.</strong> Click to change and plan
              a shared total of hours for all team members.
            </>
          }
          className={methodLockIcon}
          onClick={setTimeAllocationMethod}
          isTime
        />
      )}
    </div>
  );
}
