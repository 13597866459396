export function isMac() {
  return /mac|ipod|iphone|ipad/i.test(navigator.userAgent);
}

export function controlOrCommand() {
  return isMac() ? "cmd" : "ctrl";
}

export function altOrOption() {
  return isMac() ? "opt" : "alt";
}
