import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DATE_FORMAT_ORDINAL_DATE_MONTH } from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { entityIdListType } from "../../../lib/types/entityTypes";
import Flex from "../../elements/Flex";
import AddIcon from "../../elements/Icons/AddIcon";
import InvoiceConnector from "../../modules/Connectors/InvoiceConnector";
import JobMilestoneConnector from "../../modules/Connectors/JobMilestoneConnector";
import Currency from "../../modules/Currency";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip, { defaultStyles } from "../../modules/Tooltip";

const getFormattedInvoiceAmount = ({
  invoiceCurrencyTotalAmountIncTax,
  currency
}) => <Currency value={invoiceCurrencyTotalAmountIncTax} currency={currency} />;

class MilestonesTooltip extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    zoomLevel: PropTypes.number.isRequired,
    jobMilestoneIds: entityIdListType.isRequired,
    invoiceIds: entityIdListType.isRequired,
    editMilestone: PropTypes.func.isRequired,
    createMilestone: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    readOnly: false
  };

  get tooltipHeading() {
    const { zoomLevel } = this.props;

    if (zoomLevel === 0) return "Milestones this month";
    return "Milestones on this day";
  }

  get title() {
    const {
      date,
      readOnly,
      editMilestone,
      createMilestone,
      jobMilestoneIds,
      invoiceIds
    } = this.props;
    return (
      <Content>
        <Heading>{this.tooltipHeading}</Heading>
        <table>
          <tbody>
            {jobMilestoneIds.map(jobMilestoneId => (
              <JobMilestoneConnector
                key={`milestone-${jobMilestoneId}`}
                id={jobMilestoneId}
              >
                {jobMilestone => (
                  <tr key={jobMilestone.id}>
                    <th>
                      {"Due "}
                      {formatDate(
                        jobMilestone.date,
                        DATE_FORMAT_ORDINAL_DATE_MONTH
                      )}
                    </th>
                    <td>
                      <MilestoneName
                        onClick={() => editMilestone(jobMilestone)}
                      >
                        {jobMilestone.name}
                      </MilestoneName>
                    </td>
                    <td />
                  </tr>
                )}
              </JobMilestoneConnector>
            ))}
            {invoiceIds.map(invoiceId => (
              <InvoiceConnector key={`invoice-${invoiceId}`} id={invoiceId}>
                {invoice => (
                  <tr key={invoice.id}>
                    <th>
                      {"Issued "}
                      {formatDate(
                        invoice.invoiceDate,
                        DATE_FORMAT_ORDINAL_DATE_MONTH
                      )}
                    </th>
                    <td>{invoice.name}</td>
                    <td>{getFormattedInvoiceAmount(invoice)}</td>
                  </tr>
                )}
              </InvoiceConnector>
            ))}
            {!readOnly && (
              <tr>
                <th>{formatDate(date, DATE_FORMAT_ORDINAL_DATE_MONTH)}</th>
                <td>
                  <MilestoneName onClick={() => createMilestone(date)}>
                    <AddIcon size={ICON_SIZE.SMALL} />
                    {" Add a milestone..."}
                  </MilestoneName>
                </td>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      </Content>
    );
  }

  render() {
    const { readOnly, jobMilestoneIds, children } = this.props;

    if (readOnly && !jobMilestoneIds.length) return children;

    const styles = {
      tooltip: {
        ...defaultStyles.tooltip,
        padding: 0,
        minWidth: 300
      }
    };
    return (
      <Tooltip interactive title={this.title} placement="top" styles={styles}>
        {children}
      </Tooltip>
    );
  }
}

export default MilestonesTooltip;

const Content = styled(Flex)`
  flex-direction: column;
  color: var(--color-gray);
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;

  table {
    width: 100%;

    th {
      width: 100px;
      height: 35px;
      border-top: 1px solid var(--color-charcoal);
      font-weight: bold;
      text-align: left;
      font-weight: bold;
      padding: 0 20px;
      vertical-align: middle;
      white-space: nowrap;
    }
    td {
      height: 35px;
      border-top: 1px solid var(--color-charcoal);
      font-weight: 500;
      text-align: left;
      color: white;
      padding: 0 20px 0 0;
      vertical-align: middle;
    }
  }
`;

const MilestoneName = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled(Flex)`
  height: 32px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--color-gray);
`;
