// TODO: fix up logic to where these wont be used anymore, ie. use the costing method and expense types

export const LineItemOptionTypes = {
  UnitItem: "UNIT_ITEM",
  PeopleItem: "PEOPLE_ITEM",
  FixedItem: "FIXED_ITEM",
  UnitExpense: "UNIT_EXPENSE",
  MarkupExpense: "MARKUP_EXPENSE",
  JobItem: "JOB_ITEM",
  LoggedExpense: "LOGGED_EXPENSE",
  OptionsHeading: "OPTIONS_HEADING",
} as const;

export type LineItemOptionTypesType =
  (typeof LineItemOptionTypes)[keyof typeof LineItemOptionTypes];
