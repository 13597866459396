import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { taxRateOptionType } from "../../../lib/entities/taxRateEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  accountingPlatformStatusType,
  externalTaxRateOptionType
} from "../../../lib/ui/accountingPlatform";
import { commercialDocumentLineItemEntityType } from "../../../lib/ui/commercialDocumentLineItems";
import { selectAccountingPlatformStatus } from "../../../redux/selectors/accountingPlatformSelectors";
import { selectTaxRateOptions } from "../../../redux/selectors/taxRateSelectors";
import Flex from "../../elements/Flex";
import { InlineDotPulseIcon } from "../../elements/Icons/DotPulseIcon";
import TextLink from "../../elements/TextLink";
import consumeCommercialDocument from "../CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import SearchMenu from "../SearchMenu";
import SearchMenuCreateFooter from "../SearchMenu/SearchMenuCreateFooter";

const mapContext = ({ branchId }) => ({
  branchId
});

const mapState = (state, props) => ({
  accountingPlatformStatus: selectAccountingPlatformStatus(state, props),
  options: selectTaxRateOptions(state, props)
});

class TaxRatesSearchMenu extends React.PureComponent {
  static propTypes = {
    documentLineItem: commercialDocumentLineItemEntityType.isRequired,
    anchorEl: domNodeType,
    onPickOption: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    accountType: PropTypes.oneOf(values(ACCOUNTING_PLATFORM_ACCOUNT_TYPES))
      .isRequired,
    accountingPlatformStatus: accountingPlatformStatusType.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(taxRateOptionType),
      PropTypes.arrayOf(externalTaxRateOptionType)
    ]).isRequired
  };

  static defaultProps = {
    anchorEl: null,
    onClose: null,
    onCreate: null
  };

  getNoResultsMessage = searchQuery =>
    `Hmm, looks like no tax rates match ‘${searchQuery}’. Perhaps try a different search term.`;

  get isActive() {
    const { accountingPlatformStatus } = this.props;
    const { isActive } = accountingPlatformStatus;
    return isActive;
  }

  get externalPlatformName() {
    const { accountingPlatformStatus } = this.props;
    const { name } = accountingPlatformStatus;
    return name;
  }

  get title() {
    if (!this.isActive) return "Tax Rates";

    return `Tax Rates in ${this.externalPlatformName}`;
  }

  get noOptionsMessage() {
    if (!this.isActive) return `No tax rates available`;

    const { accountingPlatformStatus } = this.props;
    const { isFetching, isDisconnected } = accountingPlatformStatus;

    if (isFetching)
      return (
        <span>
          Loading tax rates from <b>{this.externalPlatformName}</b>{" "}
          <InlineDotPulseIcon />
        </span>
      );

    if (isDisconnected)
      return (
        <span>
          <b>{this.externalPlatformName}</b> has been disconnected. Please{" "}
          <TextLink target="_top" href="/#integrations">
            reconnect
          </TextLink>{" "}
          your account and try again.
        </span>
      );

    return (
      <span>
        No tax rates found on <b>{this.externalPlatformName}</b>.
      </span>
    );
  }

  get FooterComponent() {
    const { onCreate } = this.props;

    return (
      onCreate &&
      !this.isActive && (
        <SearchMenuCreateFooter text="Create new tax rate" onClick={onCreate} />
      )
    );
  }

  render() {
    const { anchorEl, onPickOption, onClose, options } = this.props;

    return (
      <SearchMenu
        anchorEl={anchorEl}
        options={options}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        HeaderComponentProps={{
          title: this.title,
          getNoResultsMessage: this.getNoResultsMessage,
          noOptionsMessage: this.noOptionsMessage,
          placeholder: "search by name or rate..."
        }}
        FooterComponent={this.FooterComponent}
        onClose={onClose}
      >
        {({ key, value, rate }) => (
          <TaxRateOption
            key={key}
            onClick={() => onPickOption(key, value, rate)}
            className="rainbowHover"
          >
            <TaxName>{value}</TaxName> <TaxRate>{rate}%</TaxRate>
          </TaxRateOption>
        )}
      </SearchMenu>
    );
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(TaxRatesSearchMenu)
);

const TaxRateOption = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  text-align: left;
  user-select: none;
  justify-content: space-between;
  cursor: pointer;
`;

const TaxName = styled.span`
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
`;

const TaxRate = styled.span`
  white-space: pre-wrap;
  margin-left: 10px;
  font-size: 14px;
  color: var(--color-gray-dark);
`;
