import { Extension } from "@tiptap/core";

import type { TRichTextEmitter } from "../hooks/useRichTextEmitter";
import { RichTextEvents } from "../hooks/useRichTextEmitter";

export const SaveOnEnter = Extension.create<{
  richTextEmitter: TRichTextEmitter;
}>({
  name: "saveOnEnter",
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        this.editor.commands.blur();
        this.options.richTextEmitter.emit(RichTextEvents.SaveOnEnter);
        return true;
      },
    };
  },
});
