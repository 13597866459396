import { call, put, select } from "redux-saga/effects";
import { createInvoiceLineItemAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  INVOICE_LINE_ITEM_CREATE,
  INVOICE_LINE_ITEM_CREATE_ERROR,
  INVOICE_LINE_ITEM_CREATED,
  INVOICE_LINE_ITEM_CREATING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectInvoiceLineItemById } from "../../selectors/invoiceLineItemSelectors";

function* createLine(action) {
  const { id } = action.payload;

  try {
    yield put(createAction(INVOICE_LINE_ITEM_CREATING, { id }));

    const invoiceLineItem = yield select(selectInvoiceLineItemById, { id });

    const { invoiceLineItems, ...entities } = yield call(
      createInvoiceLineItemAPI,
      invoiceLineItem
    );

    yield put(createAction(ENTITIES_RECEIVED, entities));

    yield put(
      createAction(INVOICE_LINE_ITEM_CREATED, { id, invoiceLineItems })
    );

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_CREATE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchInvoiceLineItemCreate() {
  yield takeLatestBy(
    INVOICE_LINE_ITEM_CREATE,
    createLine,
    action => action.payload.id
  );
}
