import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IconSize, Popover } from "st-shared/components";
import { JobItemUser } from "st-shared/entities";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";
import { OpenSetStatusButton, SetStatusButton } from "st-shared/module";
import { usePermissions } from "st-shared/stores";
import { StatusType } from "st-shared/types";

import { JOB_ITEM_USER_STATUS_CHANGE } from "../../../lib/constants";
import {
  createJobItemUserStatusType,
  getStatusType,
} from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../../redux/helpers/createAction";
import {
  jobItemUserStatusSwitchContainer,
  statusSwitchFlexContainer,
} from "./JobItemUserStatusSwitch.css";

interface Props {
  jobItemUser: JobItemUser;
}

export function JobItemUserStatusSwitch({ jobItemUser }: Props) {
  const { canEditJobs } = usePermissions();
  const statusType = getStatusType(jobItemUser)!;
  const disabled = !canEditJobs || Number(jobItemUser.id) < 0;

  const dispatch = useDispatch();

  function doChangeStatus(jobItemUserStatus: any) {
    dispatch(
      createAction(JOB_ITEM_USER_STATUS_CHANGE, {
        id: jobItemUser.id,
        jobItemUser,
        jobItemUserStatus,
      })
    );
  }

  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  function onOpen() {
    setAnchorEl(ref.current);
  }

  function onClose() {
    setAnchorEl(null);
  }

  function onPause() {
    doChangeStatus(createJobItemUserStatusType(JobItemUserStatuses.Planning));
    onClose();
  }

  function onPlay() {
    doChangeStatus(createJobItemUserStatusType(JobItemUserStatuses.Scheduled));
    onClose();
  }

  function onDone() {
    doChangeStatus(createJobItemUserStatusType(JobItemUserStatuses.Complete));
    onClose();
  }

  return (
    <div ref={ref} className={jobItemUserStatusSwitchContainer}>
      <OpenSetStatusButton
        onClick={onOpen}
        disabled={disabled}
        statusType={statusType}
        size={IconSize.XLarge}
      />
      {canEditJobs && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          elevation={2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={statusSwitchFlexContainer}>
            <SetStatusButton
              onClick={onPause}
              currentStatusType={statusType}
              statusType={StatusType.Planning}
              size={IconSize.XLarge}
            />
            <SetStatusButton
              onClick={onPlay}
              currentStatusType={statusType}
              statusType={StatusType.Scheduled}
              size={IconSize.XLarge}
            />
            <SetStatusButton
              onClick={onDone}
              currentStatusType={statusType}
              statusType={StatusType.Complete}
              size={IconSize.XLarge}
            />
          </div>
        </Popover>
      )}
    </div>
  );
}
