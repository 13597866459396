import { get } from "lodash-es";
import { createSelector } from "reselect";

import { EMPTY_OBJECT, ENTITY_NAME_QUOTES } from "../../lib/constants";
import {
  getBranchId,
  getJobCompany,
  getJobNumber,
  getRateCardId,
} from "../../lib/entities/jobEntity";
import {
  getCurrency,
  getExchangeRate,
  getJobId,
} from "../../lib/ui/commercialDocument";
import { selectJobListModel } from "../../state/entities/jobListModel/selectors/selectJobListModel";
import { selectPropsId, selectPropsQuoteId } from "./index";
import { selectJobById } from "./jobSelectors";

export const selectQuotes = (state) => state.entities[ENTITY_NAME_QUOTES];

export const selectQuoteById = createSelector(
  selectQuotes,
  selectPropsId,
  (quotes, id) => get(quotes, `byId.${id}`)
);

export const selectQuoteByQuoteId = (state, props) =>
  selectQuoteById(state, { id: selectPropsQuoteId(state, props) });

export const selectJobIdByQuoteId = createSelector(
  selectQuoteByQuoteId,
  getJobId
);

export const selectQuoteJobIdById = createSelector(selectQuoteById, getJobId);

export const selectQuoteJobById = (state, props) =>
  selectJobById(state, { id: selectQuoteJobIdById(state, props) });

export const selectQuoteJobByQuoteId = (state, props) =>
  selectQuoteJobById(state, { id: props.quoteId });

export const selectQuoteJobNumberById = createSelector(
  selectQuoteJobById,
  getJobNumber
);

export const selectQuoteJobCompanyById = createSelector(
  selectQuoteJobById,
  getJobCompany
);

export const selectQuoteJobBranchIdById = createSelector(
  selectQuoteJobById,
  (job) => getBranchId(job)
);

export const selectQuoteJobRateCardIdById = createSelector(
  selectQuoteJobById,
  getRateCardId
);

export const selectQuoteJobRateCardIdByQuoteId = createSelector(
  selectQuoteJobByQuoteId,
  getRateCardId
);

export const selectQuoteCurrencyById = createSelector(
  selectQuoteById,
  getCurrency
);

export const selectQuoteCurrencyByQuoteId = (state, props) =>
  selectQuoteCurrencyById(state, { id: props.quoteId });

export const selectQuoteExchangeRateById = createSelector(
  selectQuoteById,
  getExchangeRate
);

export const selectQuoteExchangeRateByQuoteId = createSelector(
  selectQuoteByQuoteId,
  getExchangeRate
);

export const selectQuoteTeamMembersById = (state, props) => {
  const quote = selectQuoteById(state, props);
  return selectJobListModel(state, { jobId: getJobId(quote) }).users;
};

export const selectQuoteUi = (state) => state.ui.quote;

export const selectQuotesUi = (state) => state.ui.quotes;

export const selectQuotesUiById = createSelector(
  selectQuotesUi,
  selectPropsId,
  (quotesUi, id) => get(quotesUi, id, EMPTY_OBJECT)
);
