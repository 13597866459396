import type { TJobPhaseOption } from "@streamtimefe/entities";
import { sortBy } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../entities";
import { getJobPhaseOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface JobPhaseOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  options: TJobPhaseOption[];
  searchOptions: SearchOption<TJobPhaseOption>[];
  load: (force?: boolean) => void;
  setOptions: (options: TJobPhaseOption[]) => void;
}

export const useJobPhaseOptionsStore = create<JobPhaseOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      options: [],
      searchOptions: [],
      load: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const response = await getJobPhaseOptions();
          get().setOptions(response.data);
        } catch (e: unknown) {
          console.error("loading job phase options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
      setOptions(options: TJobPhaseOption[]) {
        options = sortBy(options, (option) => option.name.toLowerCase());

        const searchOptions: SearchOption[] = options.map((option) => ({
          key: option.id,
          value: option.name,
          searchString: option.name,
          entity: option,
        }));

        set({ loaded: true, loading: false, options, searchOptions });
      },
    };
  }
);

export function jobPhaseOptionsStoreInit() {
  addGlobalStore(["options", "jobPhases"], jobPhaseOptionsStore);
}

export function jobPhaseOptionsStore() {
  return useJobPhaseOptionsStore.getState();
}

export function useJobPhaseOptionsLoaded() {
  return useJobPhaseOptionsStore((s) => s.loaded);
}

export function useJobPhaseOptionsLoading() {
  return useJobPhaseOptionsStore((s) => s.loading);
}

export function useJobPhaseOptions() {
  return useJobPhaseOptionsStore((s) => s.options);
}

export function useJobPhaseSearchOptions() {
  return useJobPhaseOptionsStore((s) => s.searchOptions);
}
