import scrollIntoView from "scroll-into-view-if-needed";

export const scrollToJobItem = (jobItemId) => {
  const scrollToElement = document.querySelector(`#jobItem-${jobItemId}`);
  if (scrollToElement) {
    scrollIntoView(scrollToElement, {
      scrollMode: "always",
      behavior: "smooth",
      block: "center",
    });
  }
};

export const scrollToBottomSection = (toBottom = false) => {
  const scrollToElement = document.querySelector(`#BottomSection`);
  if (scrollToElement) {
    scrollIntoView(scrollToElement, {
      scrollMode: "always",
      behavior: "smooth",
      block: toBottom ? "end" : "nearest",
    });
  }
};

export const scrollToLabels = () => {
  const scrollToElement = document.querySelector(`#Labels`);
  if (scrollToElement) {
    scrollIntoView(scrollToElement, {
      scrollMode: "always",
      behavior: "smooth",
      block: "nearest",
    });
  }
};
