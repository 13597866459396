/* eslint-disable */
/* eslint-disable react/no-array-index-key */
import React from "react";

class Toolbar extends React.Component {
  state = {
    /**
     * If this is set, the toolbar will render this instead of the regular
     * structure and will also be shown when the editor loses focus.
     * @type {Component}
     */
    overrideContent: undefined
  };

  /**
   * This can be called by a child in order to render custom content instead
   * of the regular structure. It's the responsibility of the callee to call
   * this function again with `undefined` in order to reset `overrideContent`.
   * @param {Component} overrideContent
   */
  onOverrideContent = overrideContent => this.setState({ overrideContent });

  componentDidMount() {
    this.props.store.subscribeToItem("selection", this.forceUpdate);
  }

  componentWillUnmount() {
    this.props.store.unsubscribeFromItem("selection", this.forceUpdate);
  }

  forceUpdate = () => {
    this.setState({ state: this.state });
  };

  render() {
    const { theme, store } = this.props;
    const { overrideContent: OverrideContent } = this.state;
    const childrenProps = {
      theme: theme.buttonStyles,
      getEditorState: store.getItem("getEditorState"),
      setEditorState: store.getItem("setEditorState"),
      onOverrideContent: this.onOverrideContent
    };

    return (
      <div className={theme.toolbarStyles.toolbar}>
        {OverrideContent ? (
          <OverrideContent {...childrenProps} />
        ) : (
          this.props.children(childrenProps)
        )}
      </div>
    );
  }
}

export default Toolbar;
