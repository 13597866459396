import { lotties } from "@streamtimefe/assets";
import Lottie from "lottie-react";
import React from "react";
import styled from "styled-components";

import { schedulePageOpenCreateJobModal } from "../../../lib/WebAppAPI/fePages/schedulePage";
import { PrimaryButton } from "../../elements/Button";
import Flex from "../../elements/Flex";

class EmptyJobsHeroMessage extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Container>
          <Title>
            You’ve got no jobs. <br />
            The sky’s the limit!
          </Title>
          <Body>
            Looks like you have no jobs in the system yet. Create a job to get
            cracking.
          </Body>
          <Hero>
            <Lottie animationData={lotties.flyingTrain} loop={true} />
          </Hero>
          <Action>
            <PrimaryButton
              onClick={schedulePageOpenCreateJobModal}
              style={{ width: 140 }}
            >
              Create a job
            </PrimaryButton>
          </Action>
        </Container>
      </Wrapper>
    );
  }
}

export default EmptyJobsHeroMessage;

const Wrapper = styled(Flex)`
  background: white;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  max-height: 500px;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: black;
`;

const Hero = styled.div`
  margin: 40px 0;
  div {
    width: 335px;
    margin-top: -250px;
    margin-left: -35px;
  }
`;

const Body = styled.div`
  margin-top: 20px;
  width: 300px;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: var(--color-gray-dark);
`;

const Action = styled.div``;
