import { all } from "redux-saga/effects";
import watchCreateJobItem from "./watchCreateJobItem";
import watchDeleteJobItem from "./watchDeleteJobItem";
import watchDuplicateJobItem from "./watchDuplicateJobItem";
import watchJobItem from "./watchJobItem";
import watchJobItemCheckFocus from "./watchJobItemCheckFocus";
import watchJobItemDates from "./watchJobItemDates";
import watchJobItemReorder from "./watchJobItemReorder";
import watchJobItemSetAllExpanded from "./watchJobItemSetAllExpanded";
import watchJobItemSetIsBillable from "./watchJobItemSetIsBillable";
import watchJobItemSetSubItemOrder from "./watchJobItemSetSubItemOrder";
import watchJobItemStatusChange from "./watchJobItemStatusChange";
import watchLockJobItemsByJob from "./watchLockJobItemsByJob";
import watchSaveEditJobItem from "./watchSaveEditJobItem";
import watchSaveJobItemCard from "./watchSaveJobItemCard";
import watchSaveNewJobItemCard from "./watchSaveNewJobItemCard";
import watchUpdateJobItem from "./watchUpdateJobItem";

export default function* jobItemSaga() {
  yield all([
    watchJobItem(),
    watchUpdateJobItem(),
    watchSaveEditJobItem(),
    watchLockJobItemsByJob(),
    watchDeleteJobItem(),
    watchCreateJobItem(),
    watchSaveNewJobItemCard(),
    watchSaveJobItemCard(),
    watchJobItemCheckFocus(),
    watchDuplicateJobItem(),
    watchJobItemSetSubItemOrder(),
    watchJobItemReorder(),
    watchJobItemStatusChange(),
    watchJobItemSetAllExpanded(),
    watchJobItemDates(),
    watchJobItemSetIsBillable()
  ]);
}
