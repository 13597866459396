import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { EMPTY_ARRAY, ENTITY_NAME_ROLES } from "../../../lib/constants";
import { getSearchString } from "../../../lib/entities/roleEntity";
import { keySelectorNullable, selectPropsRoleId } from "../index";

const selectStateRoles = (state) => state.entities[ENTITY_NAME_ROLES];

export const selectRole = createCachedSelector(
  [selectStateRoles, selectPropsRoleId],
  (roles, roleId) => get(roles, `byId.${roleId}]`)
)(keySelectorNullable(selectPropsRoleId));

export const useRole = (roleId) =>
  useSelector((state) => selectRole(state, { roleId }));

export const selectActiveRoleOptions = createSelector(
  [selectStateRoles],
  (roles) => get(roles, "activeOptions", EMPTY_ARRAY)
);

export const useActiveRoleOptions = () => useSelector(selectActiveRoleOptions);

export const selectRoleSearchStringByRoleId = createCachedSelector(
  [useRole],
  (role) => (role ? getSearchString(role) : "")
)(selectPropsRoleId);
