import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { DATA_ATTRIBUTE_BLOCK } from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import {
  selectBlockKeysByUserId,
  selectBlockKeysByUserIdJobId,
  selectBlockKeysByUserIdJobIdNoItem,
  selectBlockKeysByJobItemUserId,
  selectBlocksDatesByBlockKeys,
  selectBlocksDaysByBlockKeys,
  selectBlocksHeightsByBlockKeys,
  selectBlocksOffsetsByBlockKeysForUsers,
  selectBlocksOffsetsByBlockKeysForJobsWithNoItem,
  selectBlocksOffsetsByBlockKeysForJobs,
  selectBlocksOffsetsByBlockKeysForJobItemUsers
} from "../../../redux/selectors/scheduleSelectors";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

const attrBlock = getAttrPropString(DATA_ATTRIBUTE_BLOCK);

const BlockConsumer = ({
  blockKeys,
  dateByBlockKeys,
  daysByBlockKeys,
  heightsByBlockKeys,
  offsetsByBlockKeys,
  children
}) => (
  <ScheduleScrollContext.Consumer>
    {({ viewportOffsetX, viewportOffsetRightX, dayWidth, getOffsetXAtDate }) =>
      blockKeys
        .map(blockKey => {
          const date = dateByBlockKeys[blockKey];
          const days = daysByBlockKeys[blockKey];
          const left = getOffsetXAtDate(date);
          const bottom = offsetsByBlockKeys[blockKey];
          const width = days * dayWidth;
          const height = heightsByBlockKeys[blockKey];
          const right = left + width;
          const visible =
            left <= viewportOffsetRightX && right >= viewportOffsetX;

          return {
            blockKey,
            left,
            bottom,
            width,
            height,
            visible
          };
        })
        .filter(({ visible }) => visible)
        .map(({ blockKey, left, bottom, width, height }) => (
          <BlockRect
            key={blockKey}
            className="scheduleBlockRect"
            style={{ height, width, bottom, left }}
            {...{ [attrBlock]: blockKey }}
          >
            {children(blockKey)}
          </BlockRect>
        ))
    }
  </ScheduleScrollContext.Consumer>
);

BlockConsumer.propTypes = {
  children: PropTypes.func.isRequired,
  id: entityIdType.isRequired,
  jobId: entityIdType,
  userId: entityIdType,
  blockKeys: entityIdListType.isRequired,
  dateByBlockKeys: PropTypes.objectOf(PropTypes.string).isRequired,
  daysByBlockKeys: PropTypes.objectOf(PropTypes.number).isRequired,
  heightsByBlockKeys: PropTypes.objectOf(PropTypes.number).isRequired,
  offsetsByBlockKeys: PropTypes.objectOf(PropTypes.number).isRequired
};

BlockConsumer.defaultProps = {
  jobId: null,
  userId: null
};

const ConnectBlockRectsByBlockKeys = connect((state, props) => ({
  dateByBlockKeys: selectBlocksDatesByBlockKeys(state, props),
  daysByBlockKeys: selectBlocksDaysByBlockKeys(state, props),
  heightsByBlockKeys: selectBlocksHeightsByBlockKeys(state, props)
}))(BlockConsumer);

export const BlocksByUserIdConsumer = connect((state, props) => ({
  blockKeys: selectBlockKeysByUserId(state, props),
  offsetsByBlockKeys: selectBlocksOffsetsByBlockKeysForUsers(state, props)
}))(ConnectBlockRectsByBlockKeys);

export const BlocksByJobIdConsumer = connect((state, props) => ({
  blockKeys: selectBlockKeysByUserIdJobId(state, props),
  offsetsByBlockKeys: selectBlocksOffsetsByBlockKeysForJobs(state, props)
}))(ConnectBlockRectsByBlockKeys);

export const BlocksByJobIdNoItemConsumer = connect((state, props) => ({
  blockKeys: selectBlockKeysByUserIdJobIdNoItem(state, props),
  offsetsByBlockKeys: selectBlocksOffsetsByBlockKeysForJobsWithNoItem(
    state,
    props
  )
}))(ConnectBlockRectsByBlockKeys);

export const BlocksByJobItemUserIdConsumer = connect((state, props) => ({
  blockKeys: selectBlockKeysByJobItemUserId(state, props),
  offsetsByBlockKeys: selectBlocksOffsetsByBlockKeysForJobItemUsers(
    state,
    props
  )
}))(ConnectBlockRectsByBlockKeys);

export const BlockRect = styled.div`
  position: absolute;
  padding: 0 1px 0 2px;
  transition: bottom 0.2s, left 0.2s;
`;
