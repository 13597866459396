import consumeCommercialDocument from "../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DetailTableDateField from "../modules/CommercialDocuments/DetailTableDateField";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doSaveDocument }
}) => ({
  id,
  document,
  getValue: documentMethods.getInvoiceDueDate,
  setValue: documentMethods.setInvoiceDueDate,
  labelKey: "dueOn",
  doSaveDocument
});

export default consumeCommercialDocument(mapContext)(DetailTableDateField);
