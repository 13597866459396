import { useLayoutEffect, useRef, useState } from "react";
import { useResizeObserver } from "st-shared/hooks";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";
import { ErrorBoundary } from "st-shared/module";

import { useReportingSavedSegmentSearchType } from "../../state/stores/savedSegmentSelectors";
import { useReportingSeriesFetching } from "../../state/stores/searchSelectors";
import { ColumnSeriesChartContainer } from "../charts/column/ColumnSeriesChartContainer";
import { TimeSeriesChartContainer } from "../charts/time/TimeSeriesChartContainer";
import { SubHeader } from "../subheader/SubHeader";
import { SubSearchHeader } from "../subheader/SubSearchHeader";
import { TableDisplayRulesProvider } from "../table/TableDisplayRulesProvider";
import { TableFooter } from "../table/TableFooter";
import { TableHeader } from "../table/TableHeader";
import { TableRows } from "../table/TableRows";
import { chartContainerCss, reportCss } from "./Report.css";

export function Report() {
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const searchType = useReportingSavedSegmentSearchType();
  const isFetching = useReportingSeriesFetching();

  function onScrollTo() {
    ref.current?.scrollTo({
      top: chartRef.current?.clientHeight,
      behavior: "smooth",
    });
  }

  const [canScroll, setCanScroll] = useState(true);

  function checkCanScroll(el: Element) {
    if (el.clientHeight < el.scrollHeight) {
      setCanScroll(true);
    } else {
      setCanScroll(false);
    }
  }

  useResizeObserver(ref, (entry) => {
    checkCanScroll(entry.target);
  });

  useLayoutEffect(() => {
    ref.current && checkCanScroll(ref.current);
  }, [searchType, isFetching]);

  return (
    <div ref={ref} className={reportCss}>
      <SubHeader onScrollTo={onScrollTo} />
      <div ref={chartRef} className={chartContainerCss}>
        {searchType === ReportingSearchType.TimeSeries && (
          <TimeSeriesChartContainer />
        )}
        {searchType === ReportingSearchType.ColumnSeries && (
          <ColumnSeriesChartContainer />
        )}
      </div>
      <SubSearchHeader />
      <TableHeader />
      <TableDisplayRulesProvider>
        <TableRows />
        <ErrorBoundary fallback={<div />}>
          <TableFooter style={canScroll ? { bottom: 0 } : { top: "100%" }} />
        </ErrorBoundary>
      </TableDisplayRulesProvider>
    </div>
  );
}
