import { call, put, select, takeEvery } from "redux-saga/effects";
import { branchEntityStore } from "st-shared/stores";

import { fetchAccountingPlatformDetailsApi } from "../../../lib/API/externalAccountingPlatformAPI";
import {
  ACCOUNTING_PLATFORM_FETCH,
  ACCOUNTING_PLATFORM_FETCH_FAILED,
  ACCOUNTING_PLATFORM_FETCH_REQUEST,
  ACCOUNTING_PLATFORM_FETCH_SUCCEEDED,
} from "../../../lib/constants";
import {
  getAccountingPlatformId,
  getAccountingPlatformTypeName,
} from "../../../lib/entities/accountingPlatformEntity";
import {
  getAccountingPlatform,
  hasAccountingPlatform,
} from "../../../lib/entities/branchEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectAccountingPlatformUi } from "../../selectors/accountingPlatformSelectors";

function* fetch(action) {
  const { branchId } = action.payload;

  const branch = branchEntityStore().entities[branchId];

  if (!hasAccountingPlatform(branch)) return;

  const accountingPlatform = getAccountingPlatform(branch);

  try {
    const { isFetched, isFetching } = yield select(selectAccountingPlatformUi);

    if (isFetched || isFetching) return;

    yield put(createAction(ACCOUNTING_PLATFORM_FETCH_REQUEST));

    const data = yield call(
      fetchAccountingPlatformDetailsApi,
      getAccountingPlatformId(accountingPlatform),
      getAccountingPlatformTypeName(accountingPlatform)
    );

    yield put(createAction(ACCOUNTING_PLATFORM_FETCH_SUCCEEDED, { data }));
  } catch (error) {
    yield put(
      createAction(ACCOUNTING_PLATFORM_FETCH_FAILED, {
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(ACCOUNTING_PLATFORM_FETCH, fetch);
}
