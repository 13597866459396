import { sendApplyModelData } from "../WebAppAPI";
import {
  ENTITY_CLASS_ACTIVITY_ENTRY,
  ENTITY_NAME_ACTIVITY_ENTRIES
} from "../constants";
import { getActivityEntryAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const saveActivityEntryAPI = (activityEntry, mentionedUserIds = "") => {
  const { id } = activityEntry;
  const isCreate = id < 0;

  const payload = {
    activityEntry: getActivityEntryAsMessage(activityEntry),
    mentionedUserIds: mentionedUserIds
      .split(",")
      .filter(Boolean)
      .map(Number)
  };

  return (isCreate
    ? API.post("/activity_entries", payload)
    : API.put(`/activity_entries/${id}`, payload)
  )
    .then(({ data }) => data)
    .then(data => {
      sendApplyModelData(ENTITY_CLASS_ACTIVITY_ENTRY, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_ACTIVITY_ENTRY));
};

export const fetchActivityEntryAPI = id =>
  API.fetch(`/activity_entries/${id}`).then(({ data }) => ({
    [ENTITY_NAME_ACTIVITY_ENTRIES]: [data]
  }));

export const deleteActivityEntryAPI = id =>
  API.delete(`/activity_entries/${id}`)
    .then(({ data }) => data)
    .then(createModelSetParser(ENTITY_NAME_ACTIVITY_ENTRIES));
