import { Fragment, ReactNode } from "react";
import { ReportingSavedSegment } from "st-shared/entities";
import { mathjs, MathJsTypes, plate } from "st-shared/external";
import { TText } from "st-shared/external/rte/plate";

import { ColumnMetadata } from "../state/stores/savedSegmentSlice";
import {
  ELEMENT_COLUMN,
  ELEMENT_COLUMN_INPUT,
  TColumnElement,
} from "../ui/menus/formula/plugins/column";

const InsideDoubleBracketsRegex = /{{([A-Za-z]+)}}/g;
const IsColumnIdRegex = /^[A-Za-z]+$/;

export const FormulaValueType = {
  Text: 1,
  Column: 2,
} as const;

export type TFormulaValueType =
  (typeof FormulaValueType)[keyof typeof FormulaValueType];

export type FormulaValue = {
  type: TFormulaValueType;
  value: string;
};

export type FormulaEvaluate = (
  scope: Record<string, number | null>
) => number | null;

export class ReportingFormula {
  values: FormulaValue[];

  evaluate: FormulaEvaluate | null;

  private constructor(values: FormulaValue[]) {
    this.values = values;
    this.evaluate = null;
  }

  static fromString(formula: string) {
    const values: FormulaValue[] = formula
      .split(InsideDoubleBracketsRegex)
      .map((value) => {
        return {
          type: IsColumnIdRegex.test(value)
            ? FormulaValueType.Column
            : FormulaValueType.Text,
          value,
        };
      });
    return new ReportingFormula(values);
  }

  static fromPlate(plate: plate.Value) {
    const values: FormulaValue[] = [];

    plate.forEach((element) => {
      element.children.forEach((child) => {
        if (child.type === ELEMENT_COLUMN && typeof child.value === "string") {
          values.push({
            type: FormulaValueType.Column,
            value: child.value,
          });
        } else if (child.type !== ELEMENT_COLUMN_INPUT) {
          values.push({
            type: FormulaValueType.Text,
            value: (child as TText).text,
          });
        }
      });
    });

    return new ReportingFormula(values);
  }

  toPlate(): plate.Value {
    const children: plate.TDescendant[] = [];

    this.values.forEach((formulaValue) => {
      switch (formulaValue.type) {
        case FormulaValueType.Text:
          children.push({ text: formulaValue.value });
          break;
        case FormulaValueType.Column:
          children.push({
            type: ELEMENT_COLUMN,
            children: [{ text: "" }],
            value: formulaValue.value,
          } as TColumnElement);
          break;
      }
    });

    return [
      {
        type: "",
        children,
      },
    ];
  }

  toJSX(getName: (columnId: string) => ReactNode, useStyling = true) {
    return (
      <>
        {this.values.map((formulaValue, index) => {
          switch (formulaValue.type) {
            case FormulaValueType.Text:
              return <Fragment key={index}>{formulaValue.value}</Fragment>;
            case FormulaValueType.Column:
              {
                if (useStyling) {
                  return (
                    <strong key={index}>{getName(formulaValue.value)}</strong>
                  );
                }
              }
              return (
                <Fragment key={index}>{getName(formulaValue.value)}</Fragment>
              );
          }
        })}
      </>
    );
  }

  toString(wrapColumns: boolean = true): string {
    return this.values
      .reduce((formula, formulaValue) => {
        switch (formulaValue.type) {
          case FormulaValueType.Text:
            return `${formula}${formulaValue.value}`;
          case FormulaValueType.Column:
            return wrapColumns
              ? `${formula}{{${formulaValue.value}}}`
              : `${formula} ${formulaValue.value} `;
        }
      }, "")
      .trim();
  }

  compile(debug = false) {
    try {
      const node = mathjs.parse(this.toString(false));
      const compile = node.compile();
      this.evaluate = ReportingFormula.createEvaluator(compile);

      return true;
    } catch (error) {
      if (debug) {
        console.log(
          `%c formula: "${this.toString(false)}"\n${error}`,
          "background: #000; color: #f00"
        );
      }
    }
    return false;
  }

  static createEvaluator(
    evalFunction: MathJsTypes.EvalFunction
  ): FormulaEvaluate {
    return (scope) => {
      const value = evalFunction.evaluate(scope);
      if (Number.isNaN(value) || !Number.isFinite(value)) {
        return null;
      }
      return value;
    };
  }

  getColumnIds() {
    return this.values
      .filter((formulaValue) => formulaValue.type === FormulaValueType.Column)
      .map((formulaValue) => formulaValue.value);
  }

  columnsExist(columns: ReportingSavedSegment["columns"]) {
    const columnIds = this.getColumnIds();

    for (const id of columnIds) {
      if (!(id in columns)) {
        return false;
      }
    }

    return true;
  }

  static calculateScope(
    scope: Record<string, number | null>,
    metadata: Record<string, ColumnMetadata>
  ) {
    function CalculateFormula(
      columnId: string,
      formula: ReportingFormula,
      tested: Set<string>
    ) {
      if (columnId in scope) return;

      const column = metadata[columnId];

      if (
        tested.has(columnId) ||
        column.permission === false ||
        column.compileFailure !== undefined ||
        formula.evaluate === null
      ) {
        scope[columnId] = null;
        return;
      }
      tested.add(columnId);

      for (const id of formula.getColumnIds()) {
        if (!(id in scope)) {
          const nextColumn = metadata[columnId];
          if (nextColumn.formula) {
            CalculateFormula(id, nextColumn.formula, tested);
          } else {
            scope[id] = null;
          }
        }

        if (scope[id] === null) {
          scope[columnId] = null;
          return;
        }
      }

      scope[columnId] = formula.evaluate(scope);
    }

    Object.keys(metadata).forEach((id) => {
      const column = metadata[id];
      if (column.formula) {
        CalculateFormula(id, column.formula, new Set<string>());
      }
    });
  }
}
