import { get, intersection, mapValues, pick, sum, values } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { asString } from "st-shared/lib";

import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  JOB_TIMELINE_ITEM_ROW_HEIGHT,
} from "../../lib/constants";
import {
  getBlockHeight,
  getDate,
  getUserIdDateKeyPath,
} from "../../lib/entities/scheduleLoggedTimeEntity";
import { selectOrderedJobItemRoleIdsByJobItemId } from "../../state/entities/jobItemRole/selectors/selectOrderedJobItemRoleIdsByJobItemId";
import { selectJobListModel } from "../../state/entities/jobListModel/selectors/selectJobListModel";
import { selectJobListModelsFilteredIds } from "../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIds";
import { selectJobListModelsFilteredIdsByUserId } from "../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIdsByUserId";
import { EntityNames } from "../../state/entities/types";
import {
  getJobItemUserBlocksContainerHeight,
  getScheduleDayWidth,
  getUserBlocksContainerHeight,
  getUserJobBlocksContainerHeight,
  getUserJobExtraHoursBlocksContainerHeight,
} from "../../state/ui/schedule/lib";
import {
  selectScheduleUiFilterCount,
  selectScheduleUiFilteredTeamMembers,
  selectScheduleUiIsSearching,
  selectScheduleUiSearchQuery,
  selectScheduleUiSplittingBlockKey,
  selectScheduleUiViewMode,
  selectScheduleUiViewPausedLoggedTimes,
  selectScheduleUiZoomLevel,
} from "../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUi, ScheduleUiViewModes } from "../../state/ui/schedule/types";
import {
  selectPropsBlockKey,
  selectPropsBlockKeys,
  selectPropsDate,
  selectPropsId,
  selectPropsJobId,
  selectPropsJobItemUserId,
  selectPropsUserId,
  selectWeekDatesFromPropsDate,
} from "./index";
import {
  selectJobItemOrderByJobIdPhaseId,
  selectJobPhaseIdsByJobId,
} from "./jobItemSelectors";
import { selectJobItemUserIdsByJobItemId } from "./jobItemUserSelectors";
import { selectJobPhaseHasDependanciesById } from "./jobPhaseSelectors";
import {
  selectIsScheduleUserCollapsed,
  selectScheduleUnfilteredUserOrder,
} from "./userPreferenceSelectors";

export const selectScheduleLoggedTimes = (state) =>
  state.entities[EntityNames.ScheduleLoggedTimes];

export const selectScheduleLoggedTimeIdsByJobItemUserId = createSelector(
  selectScheduleLoggedTimes,
  selectPropsId,
  (scheduleLoggedTimes, jobItemUserId) =>
    get(scheduleLoggedTimes, `idsByJobItemUserId.${jobItemUserId}`, EMPTY_ARRAY)
);

export const selectScheduleLoggedTimesByIds = (state) =>
  get(selectScheduleLoggedTimes(state), "byId", EMPTY_OBJECT);

export const selectUserIdsByJobIdNoItem = createSelector(
  selectScheduleLoggedTimes,
  selectPropsId,
  (scheduleLoggedTimes, jobId) =>
    get(scheduleLoggedTimes, `userIdsByJobIdNoItem.${jobId}`, EMPTY_ARRAY)
);

export const selectDaysByBlockKeys = (state) =>
  get(selectScheduleLoggedTimes(state), "daysByBlockKey", EMPTY_OBJECT);

export const selectDaysByBlockKey = createSelector(
  selectDaysByBlockKeys,
  selectPropsBlockKey,
  (daysByBlockKeys, blockKey) => get(daysByBlockKeys, blockKey)
);

export const selectScheduleLoggedTimesByBlockKeys = createSelector(
  selectScheduleLoggedTimesByIds,
  selectPropsBlockKeys,
  (scheduleLoggedTimesByIds, blockKeys) =>
    pick(scheduleLoggedTimesByIds, blockKeys)
);

export const selectBlocksDaysByBlockKeys = createSelector(
  selectDaysByBlockKeys,
  selectPropsBlockKeys,
  (daysByBlockKeys, blockKeys) => pick(daysByBlockKeys, blockKeys)
);

export const selectBlocksDatesByBlockKeys = createSelector(
  selectScheduleLoggedTimesByBlockKeys,
  (scheduleLoggedTimesBlockKeys) =>
    mapValues(scheduleLoggedTimesBlockKeys, getDate)
);

export const selectBlocksHeightsByBlockKeys = createSelector(
  selectScheduleLoggedTimesByBlockKeys,
  (scheduleLoggedTimesBlockKeys) =>
    mapValues(scheduleLoggedTimesBlockKeys, (scheduleLoggedTime) =>
      getBlockHeight(scheduleLoggedTime)
    )
);

export const selectScheduleVisibleUserIds = createSelector(
  selectScheduleUnfilteredUserOrder,
  selectScheduleUiFilteredTeamMembers,
  (unfilteredUsers, filteredUsers) => {
    if (filteredUsers.length > 0)
      return intersection(unfilteredUsers, filteredUsers.map(String));

    return unfilteredUsers;
  }
);

export const selectScheduleDayWidth = createSelector(
  selectScheduleUiZoomLevel,
  (zoomLevel) => getScheduleDayWidth(zoomLevel)
);

export const selectIsSplittingSomeBlock = createSelector(
  selectScheduleUiSplittingBlockKey,
  (splittingBlockKey) => Boolean(splittingBlockKey)
);

export const selectHasFilterOrWildcardSearch = createSelector(
  selectScheduleUiFilterCount,
  selectScheduleUiSearchQuery,
  (filterCount, searchQuery) => filterCount > 0 || asString(searchQuery).length
);

export const selectIsScheduleSearchResultsEmpty = createSelector(
  selectJobListModelsFilteredIds,
  selectScheduleUiIsSearching,
  (scheduleSearchResultIds, isSearching) =>
    !scheduleSearchResultIds.length && !isSearching
);

export const selectScheduleBlocksUi = (state) => state.ui.scheduleBlocks;

export const selectScheduleJobsUi = (state) => state.ui.scheduleJobs;

export const selectScheduleJobPhasesUi = (state) => state.ui.scheduleJobPhases;

export const selectScheduleJobItemsUi = (state) => state.ui.scheduleJobItems;

export const selectScheduleUserJobsUi = (state) => state.ui.scheduleUserJobs;

export const selectScheduleJobUi = createSelector(
  selectScheduleJobsUi,
  selectPropsJobId,
  (scheduleJobs, jobId) => get(scheduleJobs, jobId, EMPTY_OBJECT)
);

export const selectScheduleJobPhaseUi = createSelector(
  selectScheduleJobPhasesUi,
  selectPropsId,
  (scheduleJobPhases, id) => get(scheduleJobPhases, id, EMPTY_OBJECT)
);

export const selectScheduleJobItemUi = createSelector(
  selectScheduleJobItemsUi,
  selectPropsId,
  (scheduleJobItems, id) => get(scheduleJobItems, id, EMPTY_OBJECT)
);

export const selectScheduleJobItemUiIsExpanded = createSelector(
  selectScheduleJobItemUi,
  (jobItem) => !!(jobItem && jobItem.isExpanded)
);

export const selectScheduleJobUiIsExpanded = createSelector(
  selectScheduleJobUi,
  (job) => !!(job && job.isExpanded)
);

export const selectScheduleUserJobUi = createSelector(
  selectScheduleUserJobsUi,
  selectPropsUserId,
  selectPropsId,
  (scheduleUserJobs, userId, jobId) =>
    get(scheduleUserJobs, `["${userId}.${jobId}"]`, EMPTY_OBJECT)
);

const filterPausedBlockKeys = (
  scheduleLoggedTimes,
  includePaused,
  blockKeys
) =>
  includePaused
    ? blockKeys
    : blockKeys.filter(
        (blockKey) =>
          !get(scheduleLoggedTimes, `isPausedByBlockKey.${blockKey}`)
      );

export const selectBlockKeysByUserId = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  selectPropsId,
  (scheduleLoggedTimes, includePaused, userId) =>
    filterPausedBlockKeys(
      scheduleLoggedTimes,
      includePaused,
      get(scheduleLoggedTimes, `blockKeysByUserId[${userId}]`, EMPTY_ARRAY)
    )
);

export const selectBlockKeysByUserIdJobId = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  selectPropsId,
  selectPropsJobId,
  (scheduleLoggedTimes, includePaused, userId, jobId) =>
    filterPausedBlockKeys(
      scheduleLoggedTimes,
      includePaused,
      get(
        scheduleLoggedTimes,
        `blockKeysByUserIdJobId["${userId}.${jobId}"]`,
        EMPTY_ARRAY
      )
    )
);

export const selectBlockKeysByUserIdJobIdNoItem = createSelector(
  selectScheduleLoggedTimes,
  selectPropsId,
  selectPropsJobId,
  (scheduleLoggedTimes, userId, jobId) =>
    get(
      scheduleLoggedTimes,
      `blockKeysByUserIdJobIdNoItem["${userId}.${jobId}"]`,
      EMPTY_ARRAY
    )
);

export const selectBlockKeysByJobItemUserId = createSelector(
  selectScheduleLoggedTimes,
  selectPropsJobItemUserId,
  (scheduleLoggedTimes, jobItemUserId) =>
    get(
      scheduleLoggedTimes,
      `blockKeysByJobItemUserId.${jobItemUserId}`,
      EMPTY_ARRAY
    )
);

export const selectBlockKeysByUserIdDate = createSelector(
  selectScheduleLoggedTimes,
  selectPropsId,
  selectPropsDate,
  (scheduleLoggedTimes, userId, date) =>
    get(
      scheduleLoggedTimes,
      `blockKeysByUserIdDate${getUserIdDateKeyPath({ userId, date })}`,
      EMPTY_ARRAY
    )
);

export const selectBlocksOffsetsByBlockKeysForUsers = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  selectBlockKeysByUserId,
  (scheduleLoggedTimes, includePaused, blockKeys) =>
    pick(
      get(
        scheduleLoggedTimes,
        `offsetYByBlockKey${includePaused ? "Paused" : ""}`,
        EMPTY_OBJECT
      ),
      blockKeys
    )
);

export const selectBlocksOffsetsByBlockKeysForJobs = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  selectBlockKeysByUserIdJobId,
  (scheduleLoggedTimes, includePaused, blockKeys) =>
    pick(
      get(
        scheduleLoggedTimes,
        `offsetYByBlockKeyForJobs${includePaused ? "Paused" : ""}`,
        EMPTY_OBJECT
      ),
      blockKeys
    )
);

export const selectBlocksOffsetsByBlockKeysForJobsWithNoItem = createSelector(
  selectScheduleLoggedTimes,
  selectBlockKeysByUserIdJobIdNoItem,
  (scheduleLoggedTimes, blockKeys) =>
    pick(
      get(
        scheduleLoggedTimes,
        `offsetYByBlockKeyForJobsWithNoItem`,
        EMPTY_OBJECT
      ),
      blockKeys
    )
);

export const selectBlocksOffsetsByBlockKeysForJobItemUsers = createSelector(
  selectScheduleLoggedTimes,
  selectBlockKeysByJobItemUserId,
  (scheduleLoggedTimes, blockKeys) =>
    pick(
      get(
        scheduleLoggedTimes,
        `offsetYByBlockKeyForJobItemUsers`,
        EMPTY_OBJECT
      ),
      blockKeys
    )
);

export const selectScheduleUtilisation = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  (scheduleLoggedTimes, includePaused) =>
    get(
      scheduleLoggedTimes,
      `scheduleUtilisation${includePaused ? "Paused" : ""}`,
      EMPTY_OBJECT
    )
);

export const selectScheduleUserUtilisation = createSelector(
  selectScheduleUtilisation,
  selectPropsId,
  (scheduleUtilisation, userId) =>
    get(scheduleUtilisation, userId, EMPTY_OBJECT)
);

export const selectUserWeekUtilisation = createCachedSelector(
  selectScheduleUserUtilisation,
  selectWeekDatesFromPropsDate,
  (scheduleUserUtilisation, weekDates) =>
    weekDates.map((date) => get(scheduleUserUtilisation, date, 0))
)(selectPropsDate);

export const selectMaxBlockHeightByUserIds = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  (scheduleLoggedTimes, includePaused) =>
    get(
      scheduleLoggedTimes,
      `maxBlockHeightByUserId${includePaused ? "Paused" : ""}`,
      EMPTY_OBJECT
    )
);

export const selectMaxBlockHeightByUserIdJobIds = createSelector(
  selectScheduleLoggedTimes,
  selectScheduleUiViewPausedLoggedTimes,
  (scheduleLoggedTimes, includePaused) =>
    get(
      scheduleLoggedTimes,
      `maxBlockHeightByUserIdJobId${includePaused ? "Paused" : ""}`,
      EMPTY_OBJECT
    )
);

export const selectMaxBlockHeightByUserIdJobIdNoItems = createSelector(
  selectScheduleLoggedTimes,
  (scheduleLoggedTimes) =>
    get(scheduleLoggedTimes, `maxBlockHeightByUserIdJobIdNoItem`, EMPTY_OBJECT)
);

export const selectMaxBlockHeightByJobItemUserIds = createSelector(
  selectScheduleLoggedTimes,
  (scheduleLoggedTimes) =>
    get(scheduleLoggedTimes, `maxBlockHeightByJobItemUserId`, EMPTY_OBJECT)
);

export const selectBlockContainerHeightByUserId = createSelector(
  selectMaxBlockHeightByUserIds,
  selectScheduleUiZoomLevel,
  selectPropsId,
  (maxBlockHeightByUserIds, zoomLevel, userId) =>
    getUserBlocksContainerHeight(
      zoomLevel,
      get(maxBlockHeightByUserIds, userId, 0)
    )
);

export const selectBlockContainerHeightByUserIdJobId = createSelector(
  selectMaxBlockHeightByUserIdJobIds,
  selectScheduleUiZoomLevel,
  selectPropsId,
  selectPropsJobId,
  (maxBlockHeightByUserIdJobIds, zoomLevel, userId, jobId) =>
    getUserJobBlocksContainerHeight(
      zoomLevel,
      get(maxBlockHeightByUserIdJobIds, `["${userId}.${jobId}"]`, 0)
    )
);

export const selectBlockContainerHeightByUserIdJobIdNoItem = createSelector(
  selectMaxBlockHeightByUserIdJobIdNoItems,
  selectScheduleUiZoomLevel,
  selectPropsId,
  selectPropsJobId,
  (maxBlockHeightByUserIdJobIdNoItems, zoomLevel, userId, jobId) =>
    getUserJobExtraHoursBlocksContainerHeight(
      zoomLevel,
      get(maxBlockHeightByUserIdJobIdNoItems, `["${userId}.${jobId}"]`, 0)
    )
);

export const selectBlockContainerHeightByJobItemUserId = createSelector(
  selectMaxBlockHeightByJobItemUserIds,
  selectScheduleUiZoomLevel,
  selectPropsId,
  (maxBlockHeightByJobItemUserIds, zoomLevel, jobItemUserId) =>
    getJobItemUserBlocksContainerHeight(
      zoomLevel,
      get(maxBlockHeightByJobItemUserIds, jobItemUserId, 0)
    )
);

export const selectScheduleJobItemUserRowHeightsByJobItemId = (
  state,
  props
) => {
  const rowHeights = {};
  const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, props);

  jobItemUserIds.forEach((jobItemUserId) => {
    rowHeights[jobItemUserId] = selectBlockContainerHeightByJobItemUserId(
      state,
      { id: jobItemUserId }
    );
  });

  return rowHeights;
};

export const selectScheduleJobItemRoleRowHeightsByJobItemId = (
  state,
  props
) => {
  const rowHeights = {};
  const jobItemRoleIds = selectOrderedJobItemRoleIdsByJobItemId(state, props);

  jobItemRoleIds.forEach((jobItemRoleId) => {
    rowHeights[jobItemRoleId] = ScheduleUi.Job.CompactRowHeight;
  });

  return rowHeights;
};

export const selectScheduleTeamMemberUserRowHeightsByJobId = (state, props) => {
  const rowHeights = {};
  const { id, users } = selectJobListModel(state, { jobId: props.id });

  users.forEach((user) => {
    rowHeights[user.id] = selectBlockContainerHeightByUserIdJobId(state, {
      id: user.id,
      jobId: id,
    });
  });

  return rowHeights;
};

export const selectScheduleTeamMemberRoleRowHeightsByJobId = (state, props) => {
  const rowHeights = {};
  const { roles } = selectJobListModel(state, { jobId: props.id });

  roles.forEach((role) => {
    rowHeights[role.id] = ScheduleUi.Job.CompactRowHeight;
  });

  return rowHeights;
};

export const selectJobRowHeights = (state) => {
  const viewMode = selectScheduleUiViewMode(state);
  const jobRowHeights = {};

  selectJobListModelsFilteredIds(state).forEach((jobId) => {
    let height = ScheduleUi.Job.RowHeight + 2;

    const { isExpanded: isJobExpanded = false, isFetching = false } =
      selectScheduleJobUi(state, { jobId });

    if (isJobExpanded)
      if (viewMode === ScheduleUiViewModes.ItemsByJobs) {
        if (isFetching) height += ScheduleUi.Job.LoaderHeight;
        else {
          const jobPhaseIds = selectJobPhaseIdsByJobId(state, { jobId });

          if (jobPhaseIds.length === 0)
            height += ScheduleUi.Job.EmptyItemsRowHeight;
          else
            jobPhaseIds.forEach((jobPhaseId) => {
              height += jobPhaseId > 0 ? JOB_TIMELINE_ITEM_ROW_HEIGHT : 0;

              const forceExpand = selectJobPhaseHasDependanciesById(state, {
                id: jobPhaseId,
              });
              const { isExpanded: isJobPhaseExpanded = false } =
                selectScheduleJobPhaseUi(state, { id: jobPhaseId });

              if (forceExpand || isJobPhaseExpanded || jobPhaseId === 0) {
                const jobItemIds = selectJobItemOrderByJobIdPhaseId(state, {
                  jobId,
                  id: jobPhaseId,
                });

                jobItemIds.forEach((jobItemId) => {
                  height += JOB_TIMELINE_ITEM_ROW_HEIGHT;

                  const isJobItemExpanded = selectScheduleJobItemUiIsExpanded(
                    state,
                    {
                      id: jobItemId,
                    }
                  );

                  if (isJobItemExpanded) {
                    height += sum(
                      values(
                        selectScheduleJobItemUserRowHeightsByJobItemId(state, {
                          id: jobItemId,
                        })
                      )
                    );
                    height += sum(
                      values(
                        selectScheduleJobItemRoleRowHeightsByJobItemId(state, {
                          jobItemId,
                        })
                      )
                    );
                  }
                });
              }
            });

          const userIdsWithNoItem = selectUserIdsByJobIdNoItem(state, {
            id: jobId,
          });

          if (userIdsWithNoItem.length > 0) {
            height += JOB_TIMELINE_ITEM_ROW_HEIGHT;

            const { isExtraHoursExpanded } = selectScheduleJobUi(state, {
              jobId,
            });

            if (isExtraHoursExpanded)
              height += sum(
                userIdsWithNoItem.map((userId) =>
                  selectBlockContainerHeightByUserIdJobIdNoItem(state, {
                    id: userId,
                    jobId,
                  })
                )
              );
          }
        }
      } else if (viewMode === ScheduleUiViewModes.PeopleByJobs) {
        let teamMembersHeight = sum(
          values(
            selectScheduleTeamMemberUserRowHeightsByJobId(state, { id: jobId })
          )
        );
        teamMembersHeight += sum(
          values(
            selectScheduleTeamMemberRoleRowHeightsByJobId(state, { id: jobId })
          )
        );

        height +=
          teamMembersHeight > 0
            ? teamMembersHeight
            : JOB_TIMELINE_ITEM_ROW_HEIGHT;
      }

    jobRowHeights[jobId] = height;
  });

  return jobRowHeights;
};

export const selectUserRowHeights = (state) => {
  const viewMode = selectScheduleUiViewMode(state);
  const userRowHeights = {};

  selectScheduleVisibleUserIds(state).forEach((id) => {
    let height = ScheduleUi.User.RowCollapsedHeight + 2;

    const isCollapsed = selectIsScheduleUserCollapsed(state, { id });

    if (!isCollapsed) {
      if (viewMode === ScheduleUiViewModes.Todos) {
        height += selectBlockContainerHeightByUserId(state, {
          id,
        });
      } else if (viewMode === ScheduleUiViewModes.JobsByPeople) {
        const isSearching = selectScheduleUiIsSearching(state);
        const jobIds = selectJobListModelsFilteredIdsByUserId(state, {
          userId: id,
        });

        if (isSearching) height += ScheduleUi.User.JobRowHeight;
        else if (!jobIds.length) height += ScheduleUi.User.JobEmptyRowHeight;
        else {
          jobIds.forEach((jobId) => {
            height += ScheduleUi.User.JobRowHeight;

            const { isExpanded = false } = selectScheduleUserJobUi(state, {
              id: jobId,
              userId: id,
            });

            if (isExpanded)
              height += selectBlockContainerHeightByUserIdJobId(state, {
                id,
                jobId,
              });
          });
        }
      }
    }

    userRowHeights[id] = height;
  });

  return userRowHeights;
};

export const selectScheduleContentHeight = (state) => {
  switch (selectScheduleUiViewMode(state)) {
    case ScheduleUiViewModes.ItemsByJobs:
    case ScheduleUiViewModes.PeopleByJobs:
      return selectScheduleUiIsSearching(state)
        ? ScheduleUi.Job.RowHeight
        : sum(values(selectJobRowHeights(state)));
    case ScheduleUiViewModes.Todos:
    case ScheduleUiViewModes.JobsByPeople:
    default:
      return sum(values(selectUserRowHeights(state)));
  }
};
