import { createTheme } from "@material-ui/core/styles";
import { Day as MuiDay } from "@material-ui/pickers";

MuiDay.defaultProps.disableRipple = true;

export default (theme) =>
  createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      body1: {
        ...theme.typography.body1,
        fontSize: 16,
        fontWeight: 500,
      },
    },
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          backgroundColor: "transparent",
          minWidth: "245px",
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          minHeight: "274px",
          minWidth: "245px",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "var(--color-blue-active)",
          color: "white",
          marginTop: 0,
          marginBottom: 0,
          paddingTop: "3px",
        },
        daysHeader: {
          backgroundColor: "var(--color-blue-active)",
          height: "25px",
          maxHeight: "25px",
        },
        dayLabel: {
          color: "white",
          fontWeight: 500,
          width: "30px",
        },
        iconButton: {
          backgroundColor: "transparent",
          color: "white",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          marginTop: 0,
        },
      },
      MuiPickersDay: {
        day: {
          border: "1px solid var(--color-jonsnow)",
          margin: "0 -1px -1px 0",
          color: "var(--color-charcoal)",
          "&:hover": {
            backgroundColor: "var(--color-teal-bright)",
          },
          "&:focus": {
            backgroundColor: "var(--color-teal-bright)",
          },
        },
        daySelected: {
          backgroundColor: "black",
          color: "white",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        },
        dayDisabled: {
          backgroundColor: "var(--color-ygrittesnow)",
          color: "var(--color-gray-medium)",
        },
        current: {
          color: "var(--color-teal-bright)",
          fontWeight: 700,
          "&:hover": {
            color: "var(--color-charcoal)",
          },
          "&:focus": {
            color: "var(--color-charcoal)",
          },
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: 0,
        },
      },
      MuiTouchRipple: {
        ripple: {
          color: "white",
        },
      },
      MuiInput: {
        root: {
          color: "black",
          height: 30,
          fontSize: 14,
          fontWeight: 500,
          "&:not(.Mui-disabled)": {
            cursor: "pointer",
          },
          "&.secondary": {
            height: 20,
            fontSize: 12,
            fontWeight: "bold",
            padding: "3px 4px 0",
          },
        },
        input: {
          width: 100,
          "&:not(.Mui-disabled)": {
            cursor: "pointer",
          },
          "&::placeholder": {
            color: "var(--color-gray)",
            opacity: 1,
          },
          "&.secondary": {
            width: "100%",
            padding: 0,
          },
        },
      },
      MuiInputAdornment: {
        root: {
          width: 18,
          height: 18,
          marginBottom: 2,
          "&.secondary": {
            marginRight: 2,
          },
        },
      },
    },
  });
