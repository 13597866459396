import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import {
  getJobCurrencyTotalLoggedExpensesExTax,
  getJobCurrencyTotalPlannedExpensesExTax,
} from "../../../lib/entities/jobEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobCalculatedTotalLoggedTimeExTax } from "../job/selectJobCalculatedTotalLoggedTimeExTax";
import { selectJobCalculatedTotalPlannedExTax } from "../job/selectJobCalculatedTotalPlannedExTax";
import { selectJobCalculatedUnplannedLoggedTimeExTax } from "../job/selectJobCalculatedUnplannedLoggedTimeExTax";
import { selectHasJobItemIdsByJobId } from "../jobItem/hasJobItemIdsByJobId";
import { selectJobDetailsCostVsSellMode } from "./ui/costVsSellMode";

export const selectCalculatedUsedPlannedDollarsDisplay = createCachedSelector(
  [
    selectJob,
    selectHasJobItemIdsByJobId,
    selectJobDetailsCostVsSellMode,
    selectJobCalculatedTotalLoggedTimeExTax,
    selectJobCalculatedUnplannedLoggedTimeExTax,
    selectJobCalculatedTotalPlannedExTax,
    selectCustomerCurrency,
    selectJobCurrency,
  ],
  (
    job,
    hasJobItems,
    costVsSellMode,
    calculatedTotalLoggedTimeExTax,
    calculatedUnplannedLoggedTimeExTax,
    calculatedTotalPlannedExTax,
    customerCurrency,
    jobCurrency
  ) => {
    let numerator = "";
    let denominator = "";

    const jobCurrencyTotalPlannedExTax = calculatedTotalPlannedExTax || 0;
    const jobCurrencyTotalLoggedExTax =
      numeral(calculatedTotalLoggedTimeExTax)
        .add(numeral(calculatedUnplannedLoggedTimeExTax).value())
        .add(getJobCurrencyTotalLoggedExpensesExTax(job))
        .value() || 0;

    numerator = formatForeignCurrency(customerCurrency, {
      value: jobCurrencyTotalLoggedExTax,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });

    if (
      hasJobItems ||
      numeral(getJobCurrencyTotalPlannedExpensesExTax(job)).value() > 0
    )
      denominator += formatForeignCurrency(customerCurrency, {
        value: jobCurrencyTotalPlannedExTax,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });

    return {
      numerator,
      denominator,
      jobCurrencyTotalPlannedExTax,
      jobCurrencyTotalLoggedExTax,
    };
  }
)(selectPropsJobId);

export const useCalculatedUsedPlannedDollarsDisplay = (jobId) => {
  return useSelector((state) =>
    selectCalculatedUsedPlannedDollarsDisplay(state, {
      jobId,
      includeNonBillable: false,
    })
  );
};
