import clsx from "clsx";

import { Icon, IconSize, StPriceArrow, TIconSize } from "../../components";
import { theme } from "../../theme";
import { varianceIconCss } from "./VarianceIcon.css";

interface Props {
  className?: string;
  direction: "up" | "down";
  negative?: boolean;
  size?: number | TIconSize;
}

export function VarianceIcon({
  className,
  direction,
  negative = false,
  size = IconSize.Large,
}: Props) {
  let color: string;

  switch (direction) {
    case "up":
      color = negative ? theme.color.indicatorRed : theme.color.indicatorGreen;
      break;
    case "down":
      color = negative ? theme.color.indicatorGreen : theme.color.indicatorRed;
      break;
  }

  return (
    <Icon
      icon={StPriceArrow}
      color={color}
      className={clsx(varianceIconCss[direction], className)}
      size={size}
    />
  );
}
