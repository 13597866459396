import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import { JOB_COST_VS_SELL_MODE } from "../../../lib/constants/jobDetails";
import {
  getJobCurrencyTotalLoggedExpensesCostExTax,
  getJobCurrencyTotalLoggedExpensesExTax,
  getJobCurrencyTotalPlannedExpensesExTax,
} from "../../../lib/entities/jobEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobDetailsCostVsSellMode } from "./ui/costVsSellMode";

export const jumbotronTotalExpensesDisplay = createCachedSelector(
  [
    selectJob,
    selectJobDetailsCostVsSellMode,
    selectCustomerCurrency,
    selectJobCurrency,
  ],
  (job, costVsSellMode, customerCurrency, jobCurrency) => {
    const totalExpenses =
      costVsSellMode === JOB_COST_VS_SELL_MODE.COST
        ? getJobCurrencyTotalLoggedExpensesCostExTax(job)
        : getJobCurrencyTotalLoggedExpensesExTax(job);

    return {
      totalLoggedExpenses: formatForeignCurrency(customerCurrency, {
        value: totalExpenses,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }),
      totalPlannedExpenses: formatForeignCurrency(customerCurrency, {
        value: getJobCurrencyTotalPlannedExpensesExTax(job),
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }),
    };
  }
)(selectPropsJobId);

export const useJumbotronTotalExpensesDisplay = (jobId) =>
  useSelector((state) => jumbotronTotalExpensesDisplay(state, { jobId }));
