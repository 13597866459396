import { get } from "lodash-es";
import { select, put } from "redux-saga/effects";
import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_SAVE,
  JOB_SET_PREVIOUS_JOB
} from "../../../lib/constants";
import { JOB_FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { selectJobPreviousJob } from "../../selectors/job/ui/previousJob";
import {
  selectJobDetailsUiCurrentFocusJobId,
  selectJobDetailsUiCurrentFocusKey
} from "../../selectors/jobDetails/ui/currentFocus";
import {
  selectJobDetailsUiPreviousFocusJobId,
  selectJobDetailsUiPreviousFocusKey
} from "../../selectors/jobDetails/ui/previousFocus";

function* checkFocusSetPreviousJob() {
  try {
    const currentFocusKey = yield select(selectJobDetailsUiCurrentFocusKey);
    const currentFocusJobId = yield select(selectJobDetailsUiCurrentFocusJobId);

    if (JOB_FOCUS_KEYS.includes(currentFocusKey)) {
      const previousJob = yield select(selectJobPreviousJob, {
        jobId: currentFocusJobId
      });

      if (previousJob === null) {
        const job = yield select(selectJob, {
          jobId: currentFocusJobId
        });

        yield put(
          createAction(JOB_SET_PREVIOUS_JOB, {
            jobId: currentFocusJobId,
            job
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* checkFocusSaveJob() {
  try {
    const currentFocusKey = yield select(selectJobDetailsUiCurrentFocusKey);
    const currentFocusJobId = yield select(selectJobDetailsUiCurrentFocusJobId);

    const previousFocusKey = yield select(selectJobDetailsUiPreviousFocusKey);
    const previousFocusJobId = yield select(
      selectJobDetailsUiPreviousFocusJobId
    );

    if (
      JOB_FOCUS_KEYS.includes(previousFocusKey) &&
      (!JOB_FOCUS_KEYS.includes(currentFocusKey) ||
        currentFocusJobId !== previousFocusJobId)
    ) {
      const previousJob = yield select(selectJobPreviousJob, {
        jobId: previousFocusJobId
      });

      if (previousJob !== null) {
        const job = yield select(selectJob, {
          jobId: previousFocusJobId
        });

        yield put(
          createAction(JOB_SAVE, {
            jobId: previousFocusJobId,
            job,
            previousJob
          })
        );

        yield put(
          createAction(JOB_SET_PREVIOUS_JOB, {
            jobId: previousFocusJobId,
            job: null
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobCheckFocus() {
  yield takeLatestBy(
    [JOB_DETAILS_SET_CURRENT_FOCUS],
    checkFocusSetPreviousJob,
    action => get(action.payload, "currentFocus.jobId", "default")
  );
  yield takeLatestBy(
    [JOB_DETAILS_SET_CURRENT_FOCUS],
    checkFocusSaveJob,
    action => get(action.payload, "currentFocus.jobId", "default")
  );
}
