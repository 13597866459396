import { createPortal } from "react-dom";
import { Toaster } from "react-hot-toast";

// TODO: this was just moved over, needs to be fleshed out

export function STToaster() {
  return createPortal(
    <Toaster
      position="bottom-center"
      gutter={10}
      containerStyle={{ inset: 10, zIndex: 100002 }}
      toastOptions={{
        duration: 2100,
        style: {
          fontSize: 17,
          fontWeight: 700,
          color: "white",
          boxShadow: "none",
          maxWidth: "unset",
          padding: "10px 20px",
        },
        error: {
          duration: 5300,
          icon: null,
          style: {
            backgroundColor: "#EF7C84",
            width: "100%",
          },
        },
        success: {
          icon: null,
          style: { backgroundColor: "#00BDC4" },
        },
      }}
    />,
    document.body
  );
}
