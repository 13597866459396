import type { RefObject } from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { JOB_DETAILS_SET_CURRENT_FOCUS } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsIsCurrentFocus } from "../../../redux/selectors/jobDetails/ui/IsCurrentFocus";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobId } from "../context/JobIdContext";

export function useFocusStateRef<T extends HTMLElement>(
  ids: {
    [key: string]: any;
  },
  setupRef?: (ref: RefObject<T>) => void
): [RefObject<T>, boolean, (focused: boolean) => void] {
  const ref = useRef<T>(null);
  const dispatch = useDispatch();

  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const focused = useJobDetailsIsCurrentFocus(ids);

  function setFocused(isFocused: boolean) {
    if (!isJobEditable || focused === isFocused) return;
    let currentFocus = null;
    if (isFocused) {
      currentFocus = ids;
    } else if (ids.jobItemId) {
      currentFocus = { jobItemId: ids.jobItemId, key: FOCUS_KEYS.ITEM_GENERAL };
    }
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus,
      })
    );
  }

  function focusInListener() {
    if (ref.current) setFocused(true);
  }

  function focusOutListener() {
    if (ref.current) setFocused(false);
  }

  useEffect(() => {
    setupRef?.(ref);
  }, []);

  useEffect(() => {
    if (!isJobEditable) return () => {};
    if (ref.current) {
      ref.current.addEventListener("focusin", focusInListener);
      ref.current.addEventListener("focusout", focusOutListener);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("focusin", focusInListener);
        ref.current.removeEventListener("focusout", focusOutListener);
      }
    };
  }, [isJobEditable, focusInListener, focusOutListener]);

  useEffect(() => {
    if (!isJobEditable || !ref.current) return;
    const element = ref.current;
    if (focused && element !== document.activeElement) {
      element.focus();
    } else if (!focused && element === document.activeElement) {
      element.blur();
    }
  }, [focused, isJobEditable]);

  return [ref, focused, setFocused];
}
