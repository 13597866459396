import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_JOB_ITEM_SUB_ITEMS } from "../../../lib/constants";
import { selectPropsJobItemSubItemId } from "../index";

export const selectStateJobItemSubItems = state =>
  state.entities[ENTITY_NAME_JOB_ITEM_SUB_ITEMS];

export const selectJobItemSubItem = createCachedSelector(
  [selectStateJobItemSubItems, selectPropsJobItemSubItemId],
  (jobItemSubItems, jobItemSubItemId) =>
    get(jobItemSubItems, `byId.${jobItemSubItemId}`)
)(selectPropsJobItemSubItemId);

export const useJobItemSubItem = jobItemSubItemId =>
  useSelector(state => selectJobItemSubItem(state, { jobItemSubItemId }));
