import clsx from "clsx";
import { forwardRef } from "react";
import { Input, InputProps } from "st-shared/components";

import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobId } from "../context/JobIdContext";
import { FocusProgressContainer } from "./FocusProgressContainer";
import {
  inputDisabledLookCss,
  inputEnabledLookCss,
  inputFallbackLookCss,
  inputFieldContainerCss,
  inputFieldCss,
  inputLargeTextCss,
  inputWarningCss,
  labelCss,
} from "./ItemInputField.css";

interface ItemInputFieldProps extends Omit<InputProps, "value"> {
  label?: string;
  containerClassName?: string;
  progress?: number;
  doFocusedBar?: boolean;
  largeText?: boolean;
  disabledLook?: boolean;
  fallbackLook?: boolean;
  warning?: boolean;
  value: NonNullable<InputProps["value"]>;
}

export const ItemInputField = forwardRef<HTMLInputElement, ItemInputFieldProps>(
  (
    {
      label,
      containerClassName,
      progress = 0,
      largeText = false,
      disabledLook = false,
      fallbackLook = false,
      warning = false,
      className,
      disabled,
      doFocusedBar = true,
      ...props
    },
    ref
  ) => {
    const jobId = useJobId();
    const isJobEditable = useIsJobEditable(jobId);

    return (
      <FocusProgressContainer
        className={clsx(inputFieldContainerCss, containerClassName)}
        progress={progress}
        doFocused={doFocusedBar}
      >
        {label && <span className={clsx(labelCss, "label")}>{label}</span>}
        <Input
          ref={ref}
          {...props}
          className={clsx(
            inputFieldCss,
            largeText && inputLargeTextCss,
            disabledLook && inputDisabledLookCss,
            fallbackLook && inputFallbackLookCss,
            !isJobEditable && inputEnabledLookCss,
            warning && inputWarningCss,
            className
          )}
          disabled={disabled || !isJobEditable}
          tabIndex={disabledLook ? -1 : undefined}
        />
      </FocusProgressContainer>
    );
  }
);

ItemInputField.displayName = "ItemInputField";
