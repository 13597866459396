import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_JOBS } from "../../../lib/constants";
import { selectPropsJobId } from "../index";

export const selectStateJobs = state => state.entities[ENTITY_NAME_JOBS];

export const selectJob = createCachedSelector(
  [selectStateJobs, selectPropsJobId],
  (jobs, jobId) => get(jobs, `byId.${jobId}`)
)(selectPropsJobId);

export const useJob = jobId =>
  useSelector(state => selectJob(state, { jobId }));
