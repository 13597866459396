import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import styled from "styled-components";

import { entityIdType } from "../../../lib/types/entityTypes";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import CapturePointerElement from "../../elements/CapturePointerElement";
import Anchor from "../ScheduleScrollProvider/Anchor";
import DatesConsumer from "../ScheduleScrollProvider/DatesConsumer";
import ScheduleUtilisationCells from "./ScheduleUtilisationCells";

const ScheduleUtilisation = ({ id, blockFilter, filterMode, ...props }) => (
  <Wrapper className="scheduleUtilisationContainer" {...props}>
    <Anchor>
      <DatesConsumer>
        {({ dayWidth, date, offsetX }) => (
          <ScheduleUtilisationCells
            key={date}
            date={date}
            left={offsetX}
            width={dayWidth}
            id={id}
            blockFilter={blockFilter}
            filterMode={filterMode}
          />
        )}
      </DatesConsumer>
    </Anchor>
  </Wrapper>
);

ScheduleUtilisation.propTypes = {
  id: entityIdType.isRequired,
  filterMode: PropTypes.oneOf(values(ScheduleUiBlockFilterModes)).isRequired,
  blockFilter: PropTypes.func,
};

ScheduleUtilisation.defaultProps = {
  blockFilter: () => true,
};

export default ScheduleUtilisation;

const Wrapper = styled(CapturePointerElement)`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
