import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_PHASE_OPTIONS,
  ENTITY_NAME_JOB_PHASES,
  JOB_PHASE_DELETED
} from "../../../../lib/constants";
import { getAsPhaseOption } from "../../../../lib/entities/jobPhaseEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityOptionsReducer from "../../../helpers/createEntityOptionsReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import { getAsOption } from "./index";

const optionsReducer = createEntityOptionsReducer(getAsOption);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    options: optionsReducer(state.options, changedEntities)
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_PHASE_OPTIONS]
  );

  const changedEntitiesJobPhases = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_PHASES]
      ? action.payload[ENTITY_NAME_JOB_PHASES].map(jobPhase =>
          getAsPhaseOption(jobPhase)
        )
      : undefined
  );
  return reduceChangedEntities(state, [
    ...changedEntities,
    ...changedEntitiesJobPhases
  ]);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_PHASE_OPTIONS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const deletePhaseReducer = (state, { payload: { jobPhaseId } }) => {
  return reduceChangedEntities(
    state,
    parseRemovedEntities(state, [jobPhaseId])
  );
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_PHASE_DELETED]: deletePhaseReducer
  }
);
