import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { EMPTY_ARRAY } from "../../../../lib/constants";
import { AppState } from "../../../AppState";
import { selectPropsUserId } from "../../../helpers/selectors";
import { selectStateJobListModels } from "./selectStateJobListModels";

export const selectJobListModelsFilteredIdsByUserId = createCachedSelector(
  [selectStateJobListModels, selectPropsUserId],
  (jobListModels, userId) =>
    jobListModels.filteredIdsByUserId[userId] || EMPTY_ARRAY
)(selectPropsUserId);

export function useJobListModelsFilteredIdsByUserId(userId: number) {
  return useSelector((state: AppState) =>
    selectJobListModelsFilteredIdsByUserId(state, { userId })
  );
}
