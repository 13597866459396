import { produce } from "immer";
import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";
import { sharedEmitter } from "st-shared/lib";

import {
  DATE_FORMAT_DEFAULT,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP,
  JOB_ITEM_SET_END_DATE,
  JOB_ITEM_SET_START_DATE,
} from "../../../lib/constants";
import { EVENT_JOB_ITEM_OPEN_END_DATE_PICKER } from "../../../lib/constants/events";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { isBefore, toDate } from "../../../lib/dates";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
} from "../../../lib/entities/jobItemEntity";
import { feToWebConfirmClearDateJobItem } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectJobItemDependanciesByJobItemIdEndDate,
  selectJobItemDependanciesByJobItemIdStartDate,
} from "../../selectors/jobItemDependancySelectors";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { saveJobItemImmediate } from "./saveJobItemImmediate";

function* setStartDate(action) {
  try {
    const { jobItemId, date } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const estimatedStartDate = getEstimatedStartDate(jobItem);
    const estimatedEndDate = getEstimatedEndDate(jobItem);

    let newJobItem = jobItem;
    let promptEstimatedEndPicker = false;

    if (date) {
      const newEstimatedStartDate = toDate(date, DATE_FORMAT_DEFAULT);

      newJobItem = produce(newJobItem, (draft) => {
        draft.estimatedStartDate = newEstimatedStartDate;
      });

      // prompt end date picker if both start and end date starts with NULL
      if (!estimatedEndDate && !estimatedStartDate) {
        promptEstimatedEndPicker = true;
      }
      // prompt end date picker if the start date is after end date
      else if (
        estimatedEndDate &&
        isBefore(estimatedEndDate, newEstimatedStartDate)
      ) {
        newJobItem = produce(newJobItem, (draft) => {
          draft.estimatedEndDate = newEstimatedStartDate;
        });
        promptEstimatedEndPicker = true;
      }
    } else {
      newJobItem = produce(newJobItem, (draft) => {
        draft.estimatedStartDate = null;
      });
    }

    let removeJobItemDependancyIds = [];
    if (!date) {
      removeJobItemDependancyIds = yield select(
        selectJobItemDependanciesByJobItemIdStartDate,
        {
          jobItemId,
        }
      );

      if (removeJobItemDependancyIds.length > 0) {
        yield call(feToWebConfirmClearDateJobItem);
      }
    }

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    if (promptEstimatedEndPicker)
      sharedEmitter.emit(EVENT_JOB_ITEM_OPEN_END_DATE_PICKER, { jobItemId });

    if (jobItemId > 0) {
      yield saveJobItemImmediate(newJobItem);
      yield put(
        createAction(JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP, {
          jobItemId,
        })
      );
    } else {
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
          currentFocus: { jobItemId, key: FOCUS_KEYS.ITEM_NAME },
        })
      );
    }
  } catch (error) {
    if (isError(error)) sagaError(error);
  }
}

function* setEndDate(action) {
  try {
    const { jobItemId, date } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.estimatedEndDate = date ? toDate(date, DATE_FORMAT_DEFAULT) : null;
    });

    let removeJobItemDependancyIds = [];
    if (!date) {
      removeJobItemDependancyIds = yield select(
        selectJobItemDependanciesByJobItemIdEndDate,
        {
          jobItemId,
        }
      );

      if (removeJobItemDependancyIds.length > 0) {
        yield call(feToWebConfirmClearDateJobItem);
      }
    }

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    if (jobItemId > 0) {
      yield saveJobItemImmediate(newJobItem);
      yield put(
        createAction(JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP, {
          jobItemId,
        })
      );
    } else {
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
          currentFocus: { jobItemId, key: FOCUS_KEYS.ITEM_NAME },
        })
      );
    }
  } catch (error) {
    if (isError(error)) sagaError(error);
  }
}

export default function* watchJobItemDates() {
  yield takeLatestBy(
    [JOB_ITEM_SET_START_DATE],
    setStartDate,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_END_DATE],
    setEndDate,
    (action) => action.payload.jobItemId
  );
}
