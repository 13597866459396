import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobItemRoleStore = createSingleEntityStoreContext(
  EntityClass.JobItemRole
);

export const JobItemRoleStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobItemRole,
  JobItemRoleStore
);
