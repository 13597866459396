import { produce } from "immer";
import { get, isEmpty, isEqual, set, unset } from "lodash-es";

import {
  getIsHeading,
  getOrderId,
} from "../../../../lib/ui/commercialDocumentLineItems";
import { LINE_ITEM_ELEMENTS } from "../../../../lib/ui/lineItemElements";

export default (state, nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    touchedPurchaseOrderIds.forEach((purchaseOrderId) => {
      const prevPurchaseOrderLineItems = get(
        state.sortedIdsByPurchaseOrderId,
        purchaseOrderId,
        []
      ).map((id) => state.byId[id]);
      const purchaseOrderLineItems = get(
        nextState.sortedIdsByPurchaseOrderId,
        purchaseOrderId,
        []
      ).map((id) => nextState.byId[id]);

      const prevOrder = prevPurchaseOrderLineItems.map(({ id, orderId }) => ({
        id,
        orderId,
      }));
      const nextOrder = purchaseOrderLineItems.map(({ id, orderId }) => ({
        id,
        orderId,
      }));
      if (isEqual(prevOrder, nextOrder)) return;

      if (isEmpty(purchaseOrderLineItems)) {
        unset(draft, `lineItemElementsByPurchaseOrderId.${purchaseOrderId}`);
        return;
      }

      const lineItemElements = [];
      let lastHeadingId;
      let lastLineItem;
      let lastAddLine;

      function createSubTotal() {
        if (!lastLineItem) return;

        lastAddLine.className = "subtotal";

        if (getIsHeading(lastLineItem)) return;

        if (lastHeadingId) {
          lineItemElements.push({
            type: LINE_ITEM_ELEMENTS.SUBTOTAL,
            isDraggable: false,
            id: lastHeadingId,
          });
        }
      }

      function createAddLine(className = "") {
        const orderId = lastLineItem ? getOrderId(lastLineItem) + 0.5 : 0.5;

        lastAddLine = {
          type: LINE_ITEM_ELEMENTS.ADD_LINE,
          id: orderId,
          isDraggable: false,
          className,
        };

        lineItemElements.push(lastAddLine);
      }

      function createHeading(purchaseOrderLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.HEADING,
          id: purchaseOrderLineItem.id,
          isDraggable: true,
          orderId: getOrderId(purchaseOrderLineItem),
        });

        lastLineItem = purchaseOrderLineItem;
        lastHeadingId = purchaseOrderLineItem.id;
      }

      function createItem(purchaseOrderLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.ITEM,
          id: purchaseOrderLineItem.id,
          isDraggable: true,
          orderId: getOrderId(purchaseOrderLineItem),
        });

        lastLineItem = purchaseOrderLineItem;
      }

      createAddLine();

      purchaseOrderLineItems.forEach((purchaseOrderLineItem) => {
        if (getIsHeading(purchaseOrderLineItem)) {
          createSubTotal();

          createHeading(purchaseOrderLineItem);

          createAddLine("heading");

          return;
        }

        createItem(purchaseOrderLineItem);

        createAddLine("separator");
      });

      createSubTotal();

      set(
        draft,
        `lineItemElementsByPurchaseOrderId.${purchaseOrderId}`,
        lineItemElements
      );
    });
  });
