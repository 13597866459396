import { Extension } from "@tiptap/core";

import {
  RichTextEvents,
  type TRichTextEmitter,
} from "../hooks/useRichTextEmitter";

export const SaveOnBlur = Extension.create<
  { richTextEmitter: TRichTextEmitter },
  { timeout?: ReturnType<typeof setTimeout> }
>({
  name: "saveOnBlur",
  addStorage() {
    return {
      timeout: undefined,
    };
  },
  onBlur() {
    clearTimeout(this.storage.timeout);

    this.storage.timeout = setTimeout(() => {
      if (!this.editor.isFocused) {
        this.options.richTextEmitter.emit(RichTextEvents.SaveOnBlur);
      }
    }, 50);
  },
  onFocus() {
    clearTimeout(this.storage.timeout);
  },
});
