import styled from "styled-components";
import SearchMenuItem from "../../SearchMenu/SearchMenuItem";

const AddLineSubMenuMenuItem = styled(SearchMenuItem)`
  padding: 6px 12px;
  color: var(--color-charcoal);
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  cursor: pointer;
  &.heading {
    background-color: var(--color-ygrittesnow);
    color: var(--color-gray-dark);
    pointer-events: none;
    text-transform: uppercase;
  }
`;

export default AddLineSubMenuMenuItem;
