import { produce } from "immer";
import { isEmpty } from "lodash-es";
import { JobItemRole } from "st-shared/entities";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
} from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../helpers/createEntityIndexedArrayReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";
import { ChangedEntity, EntityNames } from "../types";
import { JOB_ITEM_ROLE_EDIT, JobItemRolesAction } from "./actions";
import { JobItemRolesState } from "./types";

const initialState: JobItemRolesState = {
  byId: {},
  idsByJobItemId: {},
};

const idsByJobItemIdReducer = createEntityIndexedArrayReducer<
  JobItemRole.Type,
  number
>(
  (entity) => entity.jobItemId,
  (entity) => entity && entity.active && entity.id
);

function reduceChangedEntities(
  state: JobItemRolesState,
  changedEntities: ChangedEntity<JobItemRole.Type>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.idsByJobItemId = idsByJobItemIdReducer(
      state.idsByJobItemId,
      changedEntities
    );
  });
}

function jobItemRolesReducer(
  state: JobItemRolesState = initialState,
  action: JobItemRolesAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return reduceChangedEntities(
        state,
        parseEntityPayload(
          state,
          action.payload.jobItemRoles,
          JobItemRole.isDeleted
        )
      );
    case ENTITIES_REMOVED:
      if (action.payload.entityName !== EntityNames.JobItemRoles) {
        return state;
      }
      return reduceChangedEntities(
        state,
        parseRemovedEntities(state, action.payload.ids)
      );
    case JOB_ITEM_ROLE_EDIT:
      return reduceChangedEntities(state, [
        {
          prevEntity: state.byId[action.jobItemRole.id],
          newEntity: action.jobItemRole,
        },
      ]);
    case JOB_ITEM_SAVED: {
      const jobItemRoles = action.payload.jobItemRoles as JobItemRole.Type[];

      if (jobItemRoles && jobItemRoles.length > 0) {
        return reduceChangedEntities(
          state,
          jobItemRoles
            .filter((jobItemRole) => jobItemRole.id < 0)
            .map((jobItemRole) => ({
              prevEntity: state.byId[jobItemRole.id],
            }))
        );
      }

      return state;
    }
    case JOB_ITEM_SAVE_ERROR: {
      const previousJobItemRoles = action.payload
        .jobItemRoles as JobItemRole.Type[];

      if (previousJobItemRoles && previousJobItemRoles.length > 0) {
        return reduceChangedEntities(
          state,
          previousJobItemRoles.map((jobItemRole) => {
            if (jobItemRole.id < 0) {
              return {
                prevEntity: state.byId[jobItemRole.id],
              };
            }
            return {
              prevEntity: state.byId[jobItemRole.id],
              newEntity: jobItemRole,
            };
          })
        );
      }

      return state;
    }
    default:
      return state;
  }
}

export default jobItemRolesReducer;
