import { EnumOption } from "../../core";
import type {
  TCalendarEventCreationMode,
  TCalendarEventCreationModeEnum,
} from "./CalendarEventCreationMode";
import { CalendarEventCreationModeEnum } from "./CalendarEventCreationMode";

export function create(
  id: TCalendarEventCreationModeEnum
): TCalendarEventCreationMode {
  return { id, name: getName(id) };
}

export function isAlwaysCreate(
  calendarEventCreationMode:
    | TCalendarEventCreationModeEnum
    | TCalendarEventCreationMode
) {
  return (
    EnumOption.getId(calendarEventCreationMode) ===
    CalendarEventCreationModeEnum.AlwaysCreate
  );
}

export function isNeverCreate(
  calendarEventCreationMode:
    | TCalendarEventCreationModeEnum
    | TCalendarEventCreationMode
) {
  return (
    EnumOption.getId(calendarEventCreationMode) ===
    CalendarEventCreationModeEnum.NeverCreate
  );
}

export function isSendToInbox(
  calendarEventCreationMode:
    | TCalendarEventCreationModeEnum
    | TCalendarEventCreationMode
) {
  return (
    EnumOption.getId(calendarEventCreationMode) ===
    CalendarEventCreationModeEnum.SendToInbox
  );
}

export function getName(
  calendarEventCreationMode:
    | TCalendarEventCreationModeEnum
    | TCalendarEventCreationMode
) {
  switch (EnumOption.getId(calendarEventCreationMode)) {
    case CalendarEventCreationModeEnum.AlwaysCreate:
      return "Always Create";
    case CalendarEventCreationModeEnum.NeverCreate:
      return "Never Create";
    case CalendarEventCreationModeEnum.SendToInbox:
      return "Create Manually";
    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Unknown Calendar Event Creation Mode: ${calendarEventCreationMode}`
      );
      return "";
  }
}
