import {
  JOB_EDIT,
  JOB_ITEM_LOCK_BY_JOB,
  JOB_ITEM_UNLOCK_BY_JOB,
  JOB_SAVE,
  JOB_SAVE_ERROR,
  JOB_SAVED,
  JOB_SAVING,
  JOB_SET_PREVIOUS_JOB
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onLockJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isJobItemsLocked: true
  }
});

const onUnlockJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isJobItemsLocked: false
  }
});

const onEdit = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isEditing: true
  }
});

const onSave = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isEditing: false
  }
});

const onSaveRequest = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: { ...state[jobId], isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: { ...state[jobId], isSaving: false }
});

const onSaveError = (state, { payload: { jobId, error } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isSaving: false,
    error
  }
});

const setPrevious = (state, { payload: { jobId, job } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    previousJob: job
  }
});

export default createReducer(
  {},
  {
    [JOB_ITEM_LOCK_BY_JOB]: onLockJob,
    [JOB_ITEM_UNLOCK_BY_JOB]: onUnlockJob,
    [JOB_EDIT]: onEdit,
    [JOB_SAVE]: onSave,
    [JOB_SAVING]: onSaveRequest,
    [JOB_SAVED]: onSaveSuccess,
    [JOB_SAVE_ERROR]: onSaveError,
    [JOB_SET_PREVIOUS_JOB]: setPrevious
  }
);
