import { isEqual } from "lodash-es";

import { Entity, TEntityId } from "../../../entities/Entity";
import { ChangedEntity } from "../types";

export function parseEntityPayload<T extends Entity>(
  entities: Partial<Record<TEntityId, T>>,
  payload: T[] = [],
  shouldRemove: (entity: T) => boolean = () => false,
  mergeAttrs: any = {}
) {
  const changedEntities: ChangedEntity<T>[] = [];

  payload.forEach((newEntity) => {
    const prevEntity = entities[newEntity.id];

    Object.assign(newEntity, mergeAttrs);

    if (shouldRemove(newEntity)) {
      if (prevEntity) changedEntities.push({ prevEntity });
    } else if (!isEqual(prevEntity, newEntity)) {
      changedEntities.push({ prevEntity, newEntity });
    }
  });

  return changedEntities;
}
