import { takeEvery, call, put } from "redux-saga/effects";
import { deleteJobItemDependancyAPI } from "../../../lib/API/jobItemDependancyAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_DEPENDANCY_DELETE,
  JOB_ITEM_DEPENDANCY_DELETING,
  JOB_ITEM_DEPENDANCY_DELETED,
  JOB_ITEM_DEPENDANCY_DELETE_ERROR
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* deleteJobItemDependancy(action) {
  try {
    const jobItemDependancy = action.payload;

    yield put(createAction(JOB_ITEM_DEPENDANCY_DELETING, jobItemDependancy));

    const { data } = yield call(deleteJobItemDependancyAPI, jobItemDependancy);

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(JOB_ITEM_DEPENDANCY_DELETED, {
        jobItemDependancy,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_DEPENDANCY_DELETE_ERROR, {
        jobItemDependancy: action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchDeleteJobItemDependancy() {
  yield takeEvery([JOB_ITEM_DEPENDANCY_DELETE], deleteJobItemDependancy);
}
