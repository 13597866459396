import numeral from "numeral";

import { NumberFormats } from ".";
import { asDecimal } from "./math";

export function padNumber(number: number, length: number) {
  return `0000000000${number}`.slice(-length);
}

export function convertMinutesToTimeHM(
  totalMinutes: number,
  padMinutes = false,
  unit: string | null = null,
  padHours = true
) {
  if (!totalMinutes) {
    if (padMinutes) return "0h00m";
    return "0h";
  }

  if (unit === "h" || unit === "hour")
    return `${numeral(Math.round(totalMinutes / 60)).format(
      NumberFormats.NoDecimal
    )}h`;

  if (unit === "m" || unit === "minute")
    return `${numeral(Math.round(totalMinutes % 60)).format(
      NumberFormats.NoDecimal
    )}m`;

  const isNegative = totalMinutes < 0;

  totalMinutes = Math.abs(totalMinutes);

  const totalMinutesRounded = Math.round(totalMinutes);
  const h =
    totalMinutesRounded >= 60 || padHours
      ? `${numeral(Math.floor(totalMinutesRounded / 60)).format(
          NumberFormats.NoDecimal
        )}h`
      : "";
  const minutes = Math.abs(totalMinutesRounded) % 60;
  const m = minutes > 0 || padMinutes ? `${padNumber(minutes, 2)}m` : "";

  return `${isNegative ? "-" : ""}${h}${m}`;
}

export function convertHoursMinutesToDecimal(
  hours: number | string,
  minutes: number | string
) {
  return asDecimal(hours) + asDecimal(minutes) / 60;
}

export function convertMinutesToHours(minutes: number | string) {
  const roundedMinutes = numeral(numeral(minutes).format("0")).value();
  if (roundedMinutes === 0) return "";
  return numeral(roundedMinutes).divide(60).format(NumberFormats.TwoDecimals);
}

export function convertMinutesToHoursRaw(minutes: number | string) {
  const roundedMinutes = numeral(numeral(minutes).format("0")).value();
  return numeral(roundedMinutes).divide(60).format(NumberFormats.TwoDecimals);
}
