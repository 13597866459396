import consumeCommercialDocument from "../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DetailTableDateField from "../modules/CommercialDocuments/DetailTableDateField";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doSaveDocument }
}) => ({
  id,
  document,
  getValue: documentMethods.getDate,
  setValue: documentMethods.setDate,
  labelKey: "date",
  doSaveDocument
});

export default consumeCommercialDocument(mapContext)(DetailTableDateField);
