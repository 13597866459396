import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataExternalAccountingPlatformName,
  getMetaDataLoggedExpenseName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobLoggedExpenseSentToExternalPlatform = ({
  activityEntry,
  canEditJobs,
}) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" has sent "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataLoggedExpenseName(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to "}
    {getMetaDataExternalAccountingPlatformName(activityEntry)}
  </SystemMessage>
);

JobLoggedExpenseSentToExternalPlatform.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobLoggedExpenseSentToExternalPlatform;
