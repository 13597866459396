import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";

import { jobPhaseBillableAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  JOB_PHASE_LOADING_ENDED,
  JOB_PHASE_LOADING_STARTED,
  JOB_PHASE_SET_IS_BILLABLE,
} from "../../../lib/constants";
import { feToWebConfirmJobPhaseSetBillable } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemIdsByJobPhaseId } from "../../selectors/jobItem/selectJobItemIdsByJobPhaseId";

function* setIsBillable(action) {
  const { jobPhaseId, isBillable } = action.payload;

  let jobItemIds = [];
  try {
    jobItemIds = yield select(selectJobItemIdsByJobPhaseId, { jobPhaseId });
  } catch (error) {
    sagaError(error);
  }

  try {
    yield call(feToWebConfirmJobPhaseSetBillable, isBillable);

    yield put(
      createAction(JOB_PHASE_LOADING_STARTED, { jobPhaseId, jobItemIds })
    );

    const { data } = yield call(jobPhaseBillableAPI, jobPhaseId, isBillable);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    if (isError(error)) sagaError(error);
  } finally {
    yield put(
      createAction(JOB_PHASE_LOADING_ENDED, { jobPhaseId, jobItemIds })
    );
  }
}

export default function* watchJobPhaseSetIsBillable() {
  yield takeLatestBy(
    JOB_PHASE_SET_IS_BILLABLE,
    setIsBillable,
    (action) => action.payload.jobPhaseId
  );
}
