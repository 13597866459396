import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";

import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";
import { selectJobItemCalculatedTotalPlannedMinutes } from "../jobItem/selectJobItemCalculatedTotalPlannedMinutes";

export const selectJobCalculatedTotalPlannedMinutes = (state, { jobId }) => {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

  return reduce(
    jobItemIds,
    (total, jobItemId) => {
      const totalPlannedMinutes = selectJobItemCalculatedTotalPlannedMinutes(
        state,
        {
          jobItemId,
        }
      );
      return numeral(total).add(totalPlannedMinutes).value();
    },
    0
  );
};

export const useJobCalculatedTotalPlannedMinutes = (jobId) =>
  useSelector((state) =>
    selectJobCalculatedTotalPlannedMinutes(state, { jobId })
  );
