import { useMemo } from "react";
import { getSubFilterGroupTypes } from "st-shared/entities";

import { getOrderedMap } from "../lib/orderedMap";
import {
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentFilterGroupType,
} from "../state/stores/savedSegmentSelectors";

export function useHasSubFilter() {
  const dataSets = useReportingSavedSegmentDataSets();
  const filterGroupType = useReportingSavedSegmentFilterGroupType();

  return useMemo(() => {
    const subFilterGroupTypes = getSubFilterGroupTypes(
      getOrderedMap(dataSets),
      filterGroupType
    );
    return subFilterGroupTypes.length > 0;
  }, [dataSets, filterGroupType]);
}
