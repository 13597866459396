import { get } from "lodash-es";
import reduceChangedEntities from "../reduceChangedEntities";

const undoSplitBlockReducer = (state, action) => {
  const { scheduleLoggedTimes } = action.payload;

  const changedEntities = scheduleLoggedTimes.map(newEntity => ({
    prevEntity: get(state, `byId[${newEntity.id}]`),
    newEntity
  }));

  return reduceChangedEntities(state, changedEntities);
};

export default undoSplitBlockReducer;
