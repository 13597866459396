import { transformInvoiceBootstrap } from "../API/invoiceAPI";
import { transformLoggedExpenseBootstrap } from "../API/loggedExpenseAPI";
import { transformQuoteBootstrap } from "../API/quoteAPI";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, {
  SEND_GET_ACCOUNTING_PLATFORM_DETAILS,
  SEND_GET_INVOICE_BOOTSTRAP,
  SEND_GET_PURCHASE_ORDER_BOOTSTRAP,
  SEND_GET_QUOTE_BOOTSTRAP,
} from "./index";

export const webAppInvoiceBootstrap = () =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(SEND_GET_INVOICE_BOOTSTRAP);
    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_GET_INVOICE_BOOTSTRAP}`
        )
      );
  }).then(transformInvoiceBootstrap);

export const webAppQuoteBootstrap = () =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(SEND_GET_QUOTE_BOOTSTRAP);
    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_GET_QUOTE_BOOTSTRAP}`
        )
      );
  }).then(transformQuoteBootstrap);

export const webAppLoggedExpenseBootstrap = () =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_GET_PURCHASE_ORDER_BOOTSTRAP
    );
    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_GET_PURCHASE_ORDER_BOOTSTRAP}`
        )
      );
  }).then(transformLoggedExpenseBootstrap);

export const webAppAccountingPlatformDetails = (accountingPlatformId) =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_GET_ACCOUNTING_PLATFORM_DETAILS,
      accountingPlatformId
    );
    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_GET_ACCOUNTING_PLATFORM_DETAILS}`
        )
      );
  });
