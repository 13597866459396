import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { JOB_TIMELINE_DAYS_IN_WEEK } from "../../../lib/constants";
import {
  differenceInCalendarDays,
  getDaysInMonth,
  getStartOfMonthDatesInRange,
  getStartOfWeek
} from "../../../lib/dates";
import GridBackground from "../../elements/GridBackground";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const BackgroundConsumer = ({ zoomLevel }) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportStartDate, viewportEndDate, dayWidth, getOffsetXAtDate }) => {
      if (zoomLevel === 0) {
        return getStartOfMonthDatesInRange(
          viewportStartDate,
          viewportEndDate
        ).map(date => (
          <MonthCell
            key={date}
            style={{
              width: dayWidth * getDaysInMonth(date),
              left: getOffsetXAtDate(date)
            }}
          />
        ));
      }

      const days =
        differenceInCalendarDays(viewportEndDate, viewportStartDate) + 1;
      const width = days * dayWidth;

      if (zoomLevel === 1) {
        return (
          <GridBackground
            cellWidth={dayWidth * JOB_TIMELINE_DAYS_IN_WEEK}
            style={{
              left: getOffsetXAtDate(getStartOfWeek(viewportStartDate)),
              width
            }}
          />
        );
      }

      return (
        <GridBackground
          cellWidth={dayWidth}
          style={{
            left: getOffsetXAtDate(viewportStartDate),
            width
          }}
        />
      );
    }}
  </JobTimelineScrollContext.Consumer>
);

BackgroundConsumer.propTypes = {
  zoomLevel: PropTypes.number.isRequired
};

export default BackgroundConsumer;

const MonthCell = styled.div`
  position: absolute;
  height: 100%;
  background: var(--color-ygrittesnow);
  border-left: var(--border-thin-bright);
`;
