import {
  ENTITY_NAME_ADDRESSES,
  ENTITY_NAME_COMPANIES,
  ENTITY_NAME_CONTACTS
} from "../constants";
import API from "./index";

export const fetchCompanyContactsApi = companyId =>
  API.fetch(`/companies/${companyId}/contacts`).then(({ data }) => ({
    [ENTITY_NAME_CONTACTS]: data
  }));

export const fetchCompanyAddressesApi = companyId =>
  API.fetch(`/companies/${companyId}/addresses`).then(({ data }) => ({
    [ENTITY_NAME_ADDRESSES]: data
  }));

export const fetchCompanyApi = companyId =>
  API.fetch(`/companies/${companyId}`).then(({ data }) => ({
    [ENTITY_NAME_COMPANIES]: [data]
  }));
