import type { ConstEnum } from "@streamtimefe/types";

export const HappinessScoreEnum = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
} as const;

export type THappinessScoreEnum = ConstEnum<typeof HappinessScoreEnum>;

export const HappinessScoreArray: THappinessScoreEnum[] =
  Object.values(HappinessScoreEnum);
