import { connect } from "react-redux";

import {
  QUOTE_ADDRESS_CREATE,
  QUOTE_CONTACT_CREATE_EDIT,
  QUOTE_EDIT,
  QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN,
  QUOTE_LINE_ITEM_ADD_ITEM,
  QUOTE_LINE_ITEM_ADD_LINE,
  QUOTE_LINE_ITEM_DELETE,
  QUOTE_LINE_ITEM_EDIT,
  QUOTE_LINE_ITEM_SAVE,
  QUOTE_LINE_ITEM_SET_IS_MERGING,
  QUOTE_LINE_ITEM_SORT_MOVE,
  QUOTE_LINE_ITEM_TAX_RATE_CREATE,
  QUOTE_LINE_ITEM_UNDO_MERGE,
  QUOTE_SAVE,
  QUOTE_SET_ADDRESS,
  QUOTE_SET_CONTACT,
} from "../../../../lib/constants";
import { quoteDocumentMethods } from "../../../../lib/entities/quoteEntity";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import { documentLineItemMethods } from "../../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../../../redux/helpers/createAction";
import {
  selectQuoteById,
  selectQuoteJobBranchIdById,
  selectQuoteJobCompanyById,
  selectQuoteJobNumberById,
  selectQuoteJobRateCardIdById,
  selectQuotesUiById,
  selectQuoteUi,
} from "../../../../redux/selectors/quoteSelectors";
import CommercialDocumentContextProvider from "./CommercialDocumentContextProvider";

const mapState = (state, props) => ({
  commercialDocumentType: COMMERCIAL_DOCUMENT_TYPES.QUOTE,
  pageUi: selectQuoteUi(state),
  document: selectQuoteById(state, props),
  documentUi: selectQuotesUiById(state, props),
  branchId: selectQuoteJobBranchIdById(state, props),
  rateCardId: selectQuoteJobRateCardIdById(state, props),
  jobCompany: selectQuoteJobCompanyById(state, props),
  jobNumber: selectQuoteJobNumberById(state, props),
  documentMethods: quoteDocumentMethods,
  documentLineItemMethods,
});

const mapDispatch = (dispatch) => ({
  doEditDocument: (id, quote, prevQuote) => {
    dispatch(createAction(QUOTE_EDIT, { id, quote, prevQuote }));
  },
  doSaveDocument: (id, quote, prevQuote) => {
    dispatch(createAction(QUOTE_SAVE, { id, quote, prevQuote }));
  },
  doSetAddress: (id, addressId) => {
    dispatch(createAction(QUOTE_SET_ADDRESS, { id, addressId }));
  },
  doSetContact: (id, contactId) => {
    dispatch(createAction(QUOTE_SET_CONTACT, { id, contactId }));
  },
  doCreateContact: (id, company) => {
    dispatch(createAction(QUOTE_CONTACT_CREATE_EDIT, { id, company }));
  },
  doEditContact: (id, contact) => {
    dispatch(createAction(QUOTE_CONTACT_CREATE_EDIT, { id, contact }));
  },
  doCreateAddress: (id, company) => {
    dispatch(createAction(QUOTE_ADDRESS_CREATE, { id, company }));
  },
  doMergeLineItemsUndo: (quoteId, snapshotId) =>
    dispatch(createAction(QUOTE_LINE_ITEM_UNDO_MERGE, { quoteId, snapshotId })),
  doSortLineItemsMove: (quoteId, sourceOrderId, destinationOrderId) =>
    dispatch(
      createAction(QUOTE_LINE_ITEM_SORT_MOVE, {
        quoteId,
        sourceOrderId,
        destinationOrderId,
      })
    ),
  doEditDocumentLineItem: (id, quoteLineItem) => {
    dispatch(createAction(QUOTE_LINE_ITEM_EDIT, { id, quoteLineItem }));
  },
  doSaveDocumentLineItem: (id, quoteLineItem, prevQuoteLineItem) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_SAVE, {
        id,
        quoteLineItem,
        prevQuoteLineItem,
      })
    );
  },
  doDeleteDocumentLineItem: (id, quoteLineItem) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_DELETE, {
        id,
        quoteLineItem,
      })
    );
  },
  doToggleMergeDocumentLineItem: (id, isMerging) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_SET_IS_MERGING, {
        id,
        isMerging,
      })
    );
  },
  doDocumentLineItemCreateTaxRate: (id) => {
    dispatch(createAction(QUOTE_LINE_ITEM_TAX_RATE_CREATE, { id }));
  },
  doAddLine: (quoteId, orderId, isHeading) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_ADD_LINE, { quoteId, orderId, isHeading })
    );
  },
  doAddMasterJobItem: (quoteId, orderId, masterJobItemId) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_ADD_ITEM, {
        quoteId,
        orderId,
        masterJobItemId,
      })
    );
  },
  doAddMasterLoggedExpense: (quoteId, orderId, masterLoggedExpenseId) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_ADD_ITEM, {
        quoteId,
        orderId,
        masterLoggedExpenseId,
      })
    );
  },
  doAddFromJobPlan: (quoteId, orderId, entityId, optionType) => {
    dispatch(
      createAction(QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN, {
        quoteId,
        orderId,
        entityId,
        optionType,
      })
    );
  },
});

const QuoteContextProvider = connect(
  mapState,
  mapDispatch
)(CommercialDocumentContextProvider);

export default QuoteContextProvider;
