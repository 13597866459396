import { flatMap, get, isEmpty, reject } from "lodash-es";
import { JobListModel } from "st-shared/entities";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_MILESTONES,
  JOB_MILESTONE_DELETE,
  JOB_MILESTONE_SAVE,
  JOB_MILESTONE_SAVED,
} from "../../../../lib/constants";
import {
  getJobIdDateKeyPath,
  getJobIdMonthKeyPath,
  getUpcomingDates,
} from "../../../../lib/entities/jobMilestoneEntity";
import { JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED } from "../../../../state/entities/jobListModel/actions";
import { EntityNames } from "../../../../state/entities/types";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createEntityVariationReducer from "../../../helpers/createEntityVariationReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import dateRangeByJobIdReducer from "./dateRangeByJobIdReducer";

const idsByJobIdReducer = createEntityIndexedArrayReducer(
  (entity) => entity.jobId
);

const idsByJobIdDateReducer = createEntityIndexedArrayReducer((entity) =>
  getJobIdDateKeyPath(entity)
);

const idsByJobIdMonthReducer = createEntityIndexedArrayReducer((entity) =>
  getJobIdMonthKeyPath(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities),
    idsByJobIdDate: idsByJobIdDateReducer(
      state.idsByJobIdDate,
      changedEntities
    ),
    idsByJobIdMonth: idsByJobIdMonthReducer(
      state.idsByJobIdMonth,
      changedEntities
    ),
    upcomingDatesById: createEntityVariationReducer(
      state.upcomingDatesById,
      changedEntities,
      getUpcomingDates
    ),
  };

  nextState = dateRangeByJobIdReducer(nextState, changedEntities);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_MILESTONES]
  );

  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_MILESTONES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const saveJobMilestoneReducer = (state, action) => {
  const { id, jobId, date, name } = action.payload;
  const prevEntity = get(state, `byId.${id}`);
  const newEntity = { id, jobId, date, name };

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity,
    },
  ]);
};

const savedJobMilestoneReducer = (state, action) => {
  const { id, jobMilestones } = action.payload;
  const prevEntity = get(state, `byId.${id}`);

  const changedEntities = [{ prevEntity }].concat(
    jobMilestones.map((newEntity) => ({ newEntity }))
  );

  return reduceChangedEntities(state, changedEntities);
};

const deleteJobMilestoneReducer = (state, action) => {
  const { id } = action.payload;
  const prevEntity = state.byId[id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
    },
  ]);
};

const scheduleFilterSearchResultsReducer = (state, action) => {
  const payload = action.payload[EntityNames.JobListModels] || [];

  const jobListModels = reject(payload, JobListModel.isDeleted);

  const jobMilestones = flatMap(
    jobListModels,
    (entity) => entity.jobMilestones
  );

  const changedEntities = parseEntityPayload(state, jobMilestones);

  return reduceChangedEntities(state, changedEntities);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_MILESTONE_SAVE]: saveJobMilestoneReducer,
    [JOB_MILESTONE_SAVED]: savedJobMilestoneReducer,
    [JOB_MILESTONE_DELETE]: deleteJobMilestoneReducer,
    [JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED]:
      scheduleFilterSearchResultsReducer,
  }
);
