import { isEmpty } from "lodash-es";
import { ChangeEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEntityId } from "st-shared/entities/Entity";
import { useBranchEntity } from "st-shared/stores";
import styled from "styled-components";

import { BRANCH_LOGO_UPLOAD_REQUEST } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { selectBranchLogoById } from "../../../redux/selectors/branchLogoSelectors";
import { PrimaryButton } from "../../elements/Button";
import Flex from "../../elements/Flex";

interface Props {
  id: TEntityId;
}

export function BranchLogo({ id }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const branch = useBranchEntity(id);
  const url = branch.branchLogoURL;
  const isUploading = useSelector((state) =>
    selectBranchLogoById(state, { id })
  ).isUploading;

  const dispatch = useDispatch();

  function doUploadBranchLogo(fileData: any) {
    dispatch(createAction(BRANCH_LOGO_UPLOAD_REQUEST, { id, fileData }));
  }

  function handleUpload(event: ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files && !isEmpty(event.currentTarget.files)) {
      doUploadBranchLogo(event.currentTarget.files[0]);
    }
  }

  function triggerUpload() {
    ref.current?.click();
  }

  return (
    <Wrapper>
      <LogoContainer>
        {url && <Logo src={url} alt="Logo" />}
        {!url && (
          <LogoPlaceholder
            className={isUploading && "uploading"}
            onClick={triggerUpload}
          >
            Logo
          </LogoPlaceholder>
        )}
      </LogoContainer>
      {!url && (
        <Form>
          <PrimaryButton onClick={triggerUpload}>Upload Logo</PrimaryButton>
          <input ref={ref} type="file" hidden onChange={handleUpload} />
        </Form>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-width: 90px;
`;

const LogoContainer = styled(Flex)`
  max-width: 350px;
  max-height: 90px;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 90px;
`;

const LogoPlaceholder = styled(Flex)`
  height: 90px;
  width: 90px;
  background-color: black;
  border-radius: 50%;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  cursor: pointer;
  &.uploading {
    animation: var(--animation-pulsate);
  }
`;

const Form = styled.form`
  margin-top: 10px;
`;
