import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { sharedEmitter } from "st-shared/lib";
import styled from "styled-components";

import {
  DATE_FORMAT_DATE_SHORT_MONTH_YEAR,
  JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
  JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED,
  JOB_PAGE_SET_DATE_DRAWER_OPEN,
} from "../../../lib/constants";
import {
  FOCUS_KEYS,
  JOB_BOTTOM_SECTION_TABS,
} from "../../../lib/constants/jobDetails";
import { formatDate } from "../../../lib/dates";
import {
  scrollToBottomSection,
  scrollToJobItem,
} from "../../../lib/ui/jobDetails";
import { routeToInvoice } from "../../../lib/WebAppAPI/invoice";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useUpcomingDates } from "../../../redux/selectors/upcomingDates";
import { ellipsisElement } from "../../elements/cssElements";
import MoneyIcon from "../../elements/Icons/custom/MoneyIcon";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import RhombusCircleIcon from "../../elements/Icons/RhombusCircleIcon";
import { isDrawerContext } from "../context/isDrawerContext";
import { useJobId } from "../context/JobIdContext";
import JobDatesHeader from "./JobDatesHeader";
import JobDatesIntroduction from "./JobDatesIntroduction";
import JobDatesTooManyFilters from "./JobDatesTooManyFilters";

const JobDates = ({ isDrawer }) => {
  const jobId = useJobId();
  const [dates, unfilteredDatesLength] = useUpcomingDates(jobId);
  const dispatch = useDispatch();
  const isJobEditable = useIsJobEditable(jobId);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const scrollToElement = ref.current.querySelector(".futureDate");
      if (scrollToElement) {
        ref.current.scrollTo({
          top: scrollToElement.offsetTop,
          behavior: "instant",
        });
      }
    }
  }, []);

  const goToType = (row) => {
    if (isDrawer) {
      dispatch(createAction(JOB_PAGE_SET_DATE_DRAWER_OPEN, false));
    }

    switch (row.type) {
      case "item": {
        dispatch(
          createAction(
            JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
            JOB_BOTTOM_SECTION_TABS.JOB_PLAN
          )
        );
        requestAnimationFrame(() => scrollToJobItem(row.entityId));
        dispatch(
          createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
            currentFocus: {
              key: FOCUS_KEYS.ITEM_GENERAL,
              jobItemId: row.entityId,
            },
          })
        );
        break;
      }
      case "milestone": {
        scrollToBottomSection();
        dispatch(
          createAction(
            JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
            JOB_BOTTOM_SECTION_TABS.TIMELINE
          )
        );
        requestAnimationFrame(() =>
          sharedEmitter.emit("scrollContextCenterOnDate", row.date)
        );
        break;
      }
      case "invoice": {
        routeToInvoice(row.entityId);
        break;
      }
    }
  };

  const getEmptyState = () => {
    if (dates.length === 0) {
      if (unfilteredDatesLength > 0) return <JobDatesTooManyFilters />;
      return <JobDatesIntroduction />;
    }
    return null;
  };

  return (
    <isDrawerContext.Provider value={isDrawer}>
      <Container>
        <JobDatesHeader />
        <RowContainer ref={ref}>
          {getEmptyState()}
          {dates.map((row) => {
            const inPast = moment(row.date).isBefore(moment(), "day");
            return (
              <DateRow
                key={row.id}
                className={inPast ? "inPast" : "futureDate"}
                onClick={isJobEditable ? () => goToType(row) : null}
                $isClickable={isJobEditable}
              >
                <IconWrapper>
                  {row.type === "item" && <DatePickerIcon size={20} />}
                  {row.type === "milestone" && (
                    <RhombusCircleIcon size={20} color="var(--color-jonsnow)" />
                  )}
                  {row.type === "invoice" && <MoneyIcon size={20} />}
                </IconWrapper>
                <MainText $inPast={inPast}>
                  <Name>{row.name}</Name>
                  <Date>
                    {row.secondary}{" "}
                    {formatDate(row.date, DATE_FORMAT_DATE_SHORT_MONTH_YEAR)}
                  </Date>
                </MainText>
              </DateRow>
            );
          })}
        </RowContainer>
      </Container>
    </isDrawerContext.Provider>
  );
};

JobDates.propTypes = {
  isDrawer: PropTypes.bool,
};

JobDates.defaultProps = {
  isDrawer: false,
};

export default JobDates;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-jonsnow);
  position: relative;
  padding-top: 40px;
`;

const RowContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  & > *:not(:last-child) {
    border-bottom: 1px solid var(--color-gray);
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const DateRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  color: black;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  align-items: center;
  padding: 0 20px;

  ${(props) =>
    props.$isClickable &&
    "&:hover { cursor: pointer; background-color: var(--color-gray-bright); }"}

  &.inPast {
    color: var(--color-gray);

    ${IconWrapper} {
      ${RhombusCircleIcon} {
        & > *:first-child {
          background-color: var(--color-gray);
        }
      }
    }
  }
`;

const MainText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Name = styled.div`
  flex-grow: 1;
  width: 1px;
  ${ellipsisElement}
`;

const Date = styled.div`
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  width: fit-content;
  flex-shrink: 0;
  text-align: end;
  padding-left: 10px;
`;
