import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { EmitterSubscriber, sharedEmitter } from "st-shared/lib";
import { PermissionsConsumer } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_MILESTONE_DELETE,
  JOB_MILESTONE_SAVE,
} from "../../../lib/constants";
import { getTodayDate } from "../../../lib/dates";
import { entityIdType } from "../../../lib/types/entityTypes";
import { reactRefType } from "../../../lib/types/reactTypes";
import createAction from "../../../redux/helpers/createAction";
import Flex from "../../elements/Flex";
import JobMilestones from "../../modules/JobMilestone";
import DateConsumer from "../JobTimelineScrollProvider/DateConsumer";
import DailyMilestones from "./DailyMilestones";
import InvoiceMilestones from "./InvoiceMilestones";
import MilestonePopover from "./MilestonePopover";
import MilestonesTooltip from "./MilestonesTooltip";
import MonthlyMilestones from "./MonthlyMilestones";

const mapState = null;

const mapDispatch = (dispatch) => ({
  doSaveMilestone: (payload) =>
    dispatch(createAction(JOB_MILESTONE_SAVE, payload)),
  doDeleteMilestone: (id) =>
    dispatch(createAction(JOB_MILESTONE_DELETE, { id })),
});

const MilestonesConsumer = ({ zoomLevel, children, ...props }) =>
  zoomLevel === 0 ? (
    <MonthlyMilestones {...props}>{children}</MonthlyMilestones>
  ) : (
    <DailyMilestones {...props}>{children}</DailyMilestones>
  );

MilestonesConsumer.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
};

class JobTimelineMilestones extends React.PureComponent {
  static propTypes = {
    anchorRef: reactRefType.isRequired,
    jobId: entityIdType.isRequired,
    readOnly: PropTypes.bool,
    zoomLevel: PropTypes.number.isRequired,
    doSaveMilestone: PropTypes.func.isRequired,
    doDeleteMilestone: PropTypes.func.isRequired,
  };

  static defaultProps = {
    readOnly: false,
  };

  state = {
    selectedMilestone: null,
  };

  onOpenCreateMilestone = (date) => {
    const { jobId } = this.props;
    const id = Entity.temporaryId();
    const name = "";

    this.openMilestone({ id, jobId, name, date });
  };

  onOpenEditMilestone = (jobMilestone) => {
    this.openMilestone(jobMilestone);
  };

  onCloseMilestone = () => {
    this.closeMilestone();
  };

  onSaveMilestone = (jobMilestone) => {
    const { doSaveMilestone } = this.props;

    doSaveMilestone(jobMilestone);

    this.closeMilestone();
  };

  onDeleteMilestone = (id) => {
    const { doDeleteMilestone } = this.props;

    doDeleteMilestone(id);

    this.closeMilestone();
  };

  get isMilestoneOpen() {
    const { selectedMilestone } = this.state;

    return !!selectedMilestone;
  }

  scrollToAndCreateMilestone = (date) => {
    sharedEmitter.emit("scrollContextCenterOnDate", date);
    this.onOpenCreateMilestone(date);
  };

  scrollToAndEditMilestone = (id, name, date) => {
    const { jobId } = this.props;
    sharedEmitter.emit("scrollContextCenterOnDate", date);
    this.onOpenEditMilestone({ id, jobId, name, date });
  };

  openMilestone(jobMilestone) {
    this.setState({
      selectedMilestone: jobMilestone,
    });
  }

  closeMilestone() {
    this.setState({
      selectedMilestone: null,
    });
  }

  render() {
    const { anchorRef, jobId, readOnly, zoomLevel } = this.props;

    const { selectedMilestone } = this.state;
    const popoverDate = this.isMilestoneOpen
      ? selectedMilestone.date
      : getTodayDate();

    return (
      <>
        <EmitterSubscriber
          event="scrollToAndCreateMilestone"
          callback={this.scrollToAndCreateMilestone}
        />
        <EmitterSubscriber
          event="scrollToAndEditMilestone"
          callback={this.scrollToAndEditMilestone}
        />
        <MilestonesConsumer jobId={jobId} zoomLevel={zoomLevel}>
          {({ date, left, width, jobMilestoneIds, invoiceIds }) => (
            <MilestonesTooltip
              date={date}
              jobMilestoneIds={jobMilestoneIds}
              invoiceIds={invoiceIds}
              readOnly={readOnly}
              zoomLevel={zoomLevel}
              editMilestone={this.onOpenEditMilestone}
              createMilestone={this.onOpenCreateMilestone}
            >
              <MilestoneGroup style={{ left, width }}>
                <JobMilestones date={date} jobMilestoneIds={jobMilestoneIds} />
                <PermissionsConsumer>
                  {({ canViewJobFinancials }) =>
                    canViewJobFinancials && (
                      <InvoiceMilestones date={date} invoiceIds={invoiceIds} />
                    )
                  }
                </PermissionsConsumer>
              </MilestoneGroup>
            </MilestonesTooltip>
          )}
        </MilestonesConsumer>
        {!readOnly && (
          <DateConsumer date={popoverDate}>
            {({ left, width }) => (
              <MilestonePopover
                open={this.isMilestoneOpen}
                selectedMilestone={selectedMilestone}
                onCancel={this.onCloseMilestone}
                onSave={this.onSaveMilestone}
                onDelete={this.onDeleteMilestone}
                anchorEl={anchorRef.current}
                left={left + width / 2}
              />
            )}
          </DateConsumer>
        )}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineMilestones);

export const MilestoneGroup = styled(Flex)`
  position: absolute;
  top: 35px;
  height: 30px;
  padding-top: 25px;
  border: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > div {
    display: none;
    &:first-child {
      display: block;
    }
    &:nth-last-child(-n + 2) {
      display: block;
    }
  }
`;
