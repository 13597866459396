import { isEqual } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectJobDetailsCurrentFocus } from "./currentFocus";

export const selectJobDetailsUiIsCurrentFocus = createSelector(
  [selectJobDetailsCurrentFocus, (state, props) => props.ids],
  (currentFocus, ids) => {
    if (!currentFocus) return false;
    return isEqual(currentFocus, ids);
  }
);

export const useJobDetailsIsCurrentFocus = ids =>
  useSelector(state =>
    selectJobDetailsUiIsCurrentFocus(state, {
      ids
    })
  );
