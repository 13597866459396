import { CSV } from "@streamtimefe/utils";
import { isNil } from "lodash-es";
import {
  CReportingSavedSegment,
  ReportingFilterGroupTypeNullName,
} from "st-shared/entities";
import {
  ReportingSearchType,
  searchColumnSeries,
  searchTimeSeries,
} from "st-shared/lib/webapi/reporting";
import { organisationEntityStore } from "st-shared/stores";
import { sagaError } from "st-shared/stores/sagaHelpers";
import {
  formatStatisticValue,
  formatStatisticValueCSV,
  getFilterName,
} from "st-shared/types";

import {
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import { reportingStore } from "../state/stores/reportingStore";
import {
  getCalculatedMatrixSeriesData,
  getCalculatedMatrixSeriesTotals,
  getCalculatedVectorSeriesData,
  getCalculatedVectorSeriesTotals,
  sortMatrixSeriesData,
  sortVectorSeriesData,
} from "./VectorMatrixSeriesData";

export async function reportingExportCsv(
  isFormatted: boolean = false,
  isSecondaryGrouping: boolean = false
) {
  try {
    feToWebShowPageLoadingMask();

    const formatter = isFormatted
      ? formatStatisticValue
      : formatStatisticValueCSV;

    const reportingSavedSegment = CReportingSavedSegment.fromObject(
      reportingStore().savedSegment.value
    );
    const searchType = reportingSavedSegment.object.searchType;
    const customerCurrency =
      organisationEntityStore().helpers.getCustomerCurrency();

    let name = "Untitled Report";
    if (reportingStore().savedSegment.name.length > 0) {
      name = reportingStore().savedSegment.name;
    }
    if (searchType === ReportingSearchType.TimeSeries) {
      const selectedColumn = reportingSavedSegment.getSelectedTimeColumn();
      if (selectedColumn) {
        name += ` - ${selectedColumn.name}`;
      }
    }

    const csv = new CSV(name);

    if (searchType === ReportingSearchType.TimeSeries) {
      // time series
      const selectedTimeColumn = reportingSavedSegment.getSelectedTimeColumn();
      if (!selectedTimeColumn) return;

      // rows
      let seriesData = reportingStore().search.timeSeries.calculatedData;
      let totals = reportingStore().search.timeSeries.totals.matrix;
      const sortTime = reportingSavedSegment.object.sortTime;

      if (isSecondaryGrouping) {
        const dualSearch = await timeDualSearch();
        seriesData = dualSearch.seriesData;
        totals = dualSearch.totals;
      }

      if (seriesData.length === 0) return;

      const matrixSeriesData = sortMatrixSeriesData(
        seriesData,
        sortTime,
        selectedTimeColumn.id
      );

      // header row

      csv.addValue(
        reportingSavedSegment.object.filterGroupType
          ? getFilterName(
              reportingSavedSegment.object.filterGroupType,
              customerCurrency
            )
          : ReportingFilterGroupTypeNullName
      );

      if (isSecondaryGrouping) {
        csv.addValue(
          getFilterName(
            reportingSavedSegment.object.subFilterGroupType,
            customerCurrency
          )
        );
      }

      Object.keys(seriesData[0].matrix!).map((name) => {
        csv.addValue(name);
      });

      csv.addNewline();

      // rows

      matrixSeriesData.forEach((seriesData) => {
        csv.addValue(seriesData.title);

        if (isSecondaryGrouping) {
          csv.addValue(seriesData.childTitle || "");
        }

        Object.values(seriesData.matrix!).forEach((vector) => {
          const format =
            CReportingSavedSegment.getColumnFormat(selectedTimeColumn);
          const value = vector[selectedTimeColumn.id];

          csv.addValue(
            !isNil(value) ? formatter(value, format, customerCurrency) : ""
          );
        });

        csv.addNewline();
      });

      const selectedTotalsTime = totals[selectedTimeColumn.id];
      const format = CReportingSavedSegment.getColumnFormat(selectedTimeColumn);
      const canShowTotal =
        CReportingSavedSegment.canTimeSeriesColumnShowTotal(selectedTimeColumn);

      if (canShowTotal) {
        csv.addNewline();
        csv.addValue("Grand Total");
        if (isSecondaryGrouping) {
          csv.addValue("");
        }

        Object.values(selectedTotalsTime).forEach((value) => {
          csv.addValue(
            !isNil(value) ? formatter(value, format, customerCurrency) : ""
          );
        });
      }
    } else {
      // columns
      const orderedColumns = reportingSavedSegment.getOrderedColumns();

      // header row
      csv.addValue(
        reportingSavedSegment.object.filterGroupType
          ? getFilterName(
              reportingSavedSegment.object.filterGroupType,
              customerCurrency
            )
          : ReportingFilterGroupTypeNullName
      );

      if (isSecondaryGrouping) {
        csv.addValue(
          getFilterName(
            reportingSavedSegment.object.subFilterGroupType,
            customerCurrency
          )
        );
      }

      orderedColumns.forEach((column) => {
        csv.addValue(column.name);
      });

      csv.addNewline();

      // rows
      let seriesData = reportingStore().search.columnSeries.calculatedData;
      let totals = reportingStore().search.columnSeries.totals.vector;
      const sortColumn = reportingSavedSegment.object.sortColumn;

      if (isSecondaryGrouping) {
        const dualSearch = await columnDualSearch();
        seriesData = dualSearch.seriesData;
        totals = dualSearch.totals;
      }

      if (seriesData.length === 0) return;

      const vectorSeriesData = sortVectorSeriesData(seriesData, sortColumn);

      vectorSeriesData.forEach((seriesData) => {
        csv.addValue(seriesData.title);

        if (isSecondaryGrouping) {
          csv.addValue(seriesData.childTitle || "");
        }

        orderedColumns.forEach((column) => {
          const format = CReportingSavedSegment.getColumnFormat(column);
          const value = seriesData.vector![column.id];
          csv.addValue(
            !isNil(value) ? formatter(value, format, customerCurrency) : ""
          );
        });

        csv.addNewline();
      });

      // total

      csv.addNewline();
      csv.addValue("Grand Total");
      if (isSecondaryGrouping) {
        csv.addValue("");
      }

      orderedColumns.forEach((column) => {
        const format = CReportingSavedSegment.getColumnFormat(column);
        const value = totals[column.id];
        csv.addValue(
          !isNil(value) ? formatter(value, format, customerCurrency) : ""
        );
      });
    }

    csv.download();
  } catch (error) {
    sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

async function columnDualSearch() {
  const savedSegmentValue = reportingStore().savedSegment.value;
  const reportingSavedSegment =
    CReportingSavedSegment.fromObject(savedSegmentValue);
  const columnMetadata = reportingStore().savedSegment.columnMetadata;

  const searchData = reportingSavedSegment.createDualSearchData();

  const response = await searchColumnSeries(searchData);

  const seriesData = response.data.results;
  const seriesTotals = response.data.seriesTotals;

  const calculatedData = getCalculatedVectorSeriesData(
    seriesData,
    savedSegmentValue,
    savedSegmentValue.filterGroupType,
    columnMetadata
  );

  const vectorTotals = getCalculatedVectorSeriesTotals(
    seriesTotals,
    savedSegmentValue,
    columnMetadata
  );

  return {
    seriesData: calculatedData,
    totals: vectorTotals,
  };
}

async function timeDualSearch() {
  const savedSegmentValue = reportingStore().savedSegment.value;
  const reportingSavedSegment =
    CReportingSavedSegment.fromObject(savedSegmentValue);
  const columnMetadata = reportingStore().savedSegment.columnMetadata;

  const searchData = reportingSavedSegment.createDualSearchData();

  const response = await searchTimeSeries(searchData);

  const seriesData = response.data.results;
  const bucketKeys = Object.values(response.data.bucketKeys);

  const calculatedData = getCalculatedMatrixSeriesData(
    seriesData,
    savedSegmentValue,
    savedSegmentValue.filterGroupType,
    bucketKeys,
    columnMetadata
  );

  const matrixTotals = getCalculatedMatrixSeriesTotals(
    calculatedData,
    savedSegmentValue,
    bucketKeys,
    columnMetadata
  );

  return {
    seriesData: calculatedData,
    totals: matrixTotals,
  };
}
