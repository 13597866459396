import type { TCurrency, TOrganisation } from "@streamtimefe/entities";
import { first, isArray, isEmpty } from "lodash-es";
import { create } from "zustand";

import type { TEntityList } from "../../../types";
import { EntityListKey } from "../../../types";
import { addGlobalStore, storeActionAddListener } from "../..";
import type { EntityStoreState } from "..";
import { useUserEntity } from "..";

interface OrganisationEntityStoreState
  extends Pick<EntityStoreState<TOrganisation>, "receiveEntities"> {
  entity: TOrganisation;
  helpers: {
    getCustomerCurrency: () => TCurrency;
  };
}

export const useOrganisationEntityStore = create<OrganisationEntityStoreState>(
  function (set, get) {
    return {
      entity: null as unknown as TOrganisation,
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        const organisationData = entityData[EntityListKey.Organisation];

        if (isEmpty(organisationData)) return;

        const organisation = isArray(organisationData)
          ? first(organisationData)!
          : organisationData;

        set({ entity: organisation });
      },

      helpers: {
        getCustomerCurrency() {
          return get().entity.customerCurrency;
        },
      },
    };
  }
);

export function organisationEntityStoreInit() {
  addGlobalStore(
    ["entities", EntityListKey.Organisation],
    organisationEntityStore
  );
  storeActionAddListener(
    "entitiesReceived",
    organisationEntityStore().receiveEntities
  );
}

export function organisationEntityStore() {
  return useOrganisationEntityStore.getState();
}

export function useOrganisation() {
  return useOrganisationEntityStore((s) => s.entity);
}

export function useCustomerCurrency() {
  return useOrganisation()?.customerCurrency;
}

export function useOrganisationSubscriberUser() {
  const userId = useOrganisation().subscriberUserId;
  return useUserEntity(userId);
}
