import { isEmpty } from "lodash-es";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { undoMergeInvoiceLineItemsAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
  INVOICE_LINE_ITEM_UNDO_MERGE,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVED,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVING
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import { undoMergeLineItems } from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectSortedInvoiceLineItemsByInvoiceId } from "../../selectors/invoiceLineItemSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* undoMerge(action) {
  const { invoiceId, snapshotId } = action.payload;

  const prevInvoiceLineItems = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  if (isEmpty(prevInvoiceLineItems)) return;

  const [baseInvoiceLineItem, ...otherInvoiceLineItems] = prevInvoiceLineItems;

  const currentInvoiceLineItems = yield select(
    selectSortedInvoiceLineItemsByInvoiceId,
    { invoiceId }
  );

  try {
    yield put(
      createAction(INVOICE_LINE_ITEM_UNDO_MERGE_SAVING, { id: invoiceId })
    );

    const newInvoiceLineItems = undoMergeLineItems(
      baseInvoiceLineItem,
      otherInvoiceLineItems,
      currentInvoiceLineItems
    );

    if (newInvoiceLineItems.length)
      yield put(
        createAction(ENTITIES_RECEIVED, {
          [ENTITY_NAME_INVOICE_LINE_ITEMS]: newInvoiceLineItems
        })
      );

    const data = yield call(
      undoMergeInvoiceLineItemsAPI,
      baseInvoiceLineItem,
      otherInvoiceLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(INVOICE_LINE_ITEM_UNDO_MERGE_SAVED, {
        id: invoiceId,
        snapshotId
      })
    );

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR, {
        id: invoiceId,
        prevInvoiceLineItems: currentInvoiceLineItems,
        mergedInvoiceLineItems: otherInvoiceLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchUndoMergeLineItems() {
  yield takeLatest(INVOICE_LINE_ITEM_UNDO_MERGE, undoMerge);
}
