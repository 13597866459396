import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { entityIdType } from "../../../lib/types/entityTypes";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../lib/ui/commercialDocument";
import {
  getLineItemElementType,
  LINE_ITEM_ELEMENTS,
  lineItemElementType
} from "../../../lib/ui/lineItemElements";
import { selectQuoteLineItemElementsById } from "../../../redux/selectors/quoteLineItemSelectors";
import consumeCommercialDocument from "../../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DocumentLineItems from "../../modules/CommercialDocuments/DocumentLineItems";
import AddDocumentLine from "../../modules/CommercialDocuments/DocumentLineItems/AddDocumentLine";
import QuoteHeadingSubtotal from "./QuoteHeadingSubtotal";
import QuoteLineHeading from "./QuoteLineHeading";
import QuoteLineItem from "./QuoteLineItem";

const mapContext = ({
  id,
  commercialDocumentType,
  document,
  documentMethods,
  pageUi: { isMergeMode, isSortMode },
  actions: { doSortLineItemsMove }
}) => ({
  id,
  commercialDocumentType,
  isMergeMode,
  isSortMode,
  displayOptions: documentMethods.getDisplayOptions(document),
  doSortLineItemsMove
});

const mapState = (state, props) => ({
  sortedLineItemElements: selectQuoteLineItemElementsById(state, props)
});

const QuoteLineItems = ({
  id,
  isMergeMode,
  isSortMode,
  displayOptions,
  commercialDocumentType,
  doSortLineItemsMove,
  sortedLineItemElements
}) => (
  <DocumentLineItems
    id={id}
    isMergeMode={isMergeMode}
    isSortMode={isSortMode}
    displayOptions={displayOptions}
    commercialDocumentType={commercialDocumentType}
    doSortLineItemsMove={doSortLineItemsMove}
    sortedLineItemElements={sortedLineItemElements}
  >
    {lineItemElement => {
      const {
        displayHeadings,
        displayHeadingDescriptions,
        displayHeadingSubtotals,
        displayLineItems,
        displayLineItemDescriptions,
        displayTaxNameOnLineItems,
        displayQuantities,
        displayUnitRates,
        displaySubtotals,
        displayCurrencySymbols,
        displayLineItemsInclusiveOfTax
      } = displayOptions;

      switch (getLineItemElementType(lineItemElement)) {
        case LINE_ITEM_ELEMENTS.HEADING:
          return (
            displayHeadings && (
              <QuoteLineHeading
                id={lineItemElement.id}
                isSortMode={isSortMode}
                displayOptions={displayOptions}
                displayHeadingDescriptions={displayHeadingDescriptions}
              />
            )
          );
        case LINE_ITEM_ELEMENTS.ITEM:
          return (
            <QuoteLineItem
              id={lineItemElement.id}
              isMergeMode={isMergeMode}
              isSortMode={isSortMode}
              displayLineItems={displayLineItems}
              displayLineItemDescriptions={displayLineItemDescriptions}
              displayTaxNameOnLineItems={displayTaxNameOnLineItems}
              displayQuantities={displayQuantities}
              displayUnitRates={displayUnitRates}
              displaySubtotals={displaySubtotals}
              displayCurrencySymbols={displayCurrencySymbols}
              displayLineItemsInclusiveOfTax={displayLineItemsInclusiveOfTax}
            />
          );
        case LINE_ITEM_ELEMENTS.SUBTOTAL:
          return (
            displayHeadings &&
            displayHeadingSubtotals && (
              <QuoteHeadingSubtotal
                quoteId={id}
                displayCurrencySymbols={displayCurrencySymbols}
                id={lineItemElement.id}
              />
            )
          );
        case LINE_ITEM_ELEMENTS.ADD_LINE:
          return (
            <AddDocumentLine
              orderId={lineItemElement.id}
              className={lineItemElement.className}
              displayHeadings={displayHeadings}
              displayHeadingSubtotals={displayHeadingSubtotals}
              displayLineItems={displayLineItems}
              displayLineItemDescriptions={displayLineItemDescriptions}
            />
          );
        default:
          return null;
      }
    }}
  </DocumentLineItems>
);

QuoteLineItems.propTypes = {
  id: entityIdType.isRequired,
  commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
    .isRequired,
  isMergeMode: PropTypes.bool.isRequired,
  isSortMode: PropTypes.bool.isRequired,
  displayOptions: PropTypes.shape({
    displayHeadings: PropTypes.bool.isRequired,
    displayHeadingDescriptions: PropTypes.bool.isRequired,
    displayHeadingSubtotals: PropTypes.bool.isRequired,
    displayLineItems: PropTypes.bool.isRequired,
    displayLineItemDescriptions: PropTypes.bool.isRequired,
    displayTaxNameOnLineItems: PropTypes.bool.isRequired,
    displayQuantities: PropTypes.bool.isRequired,
    displayUnitRates: PropTypes.bool.isRequired,
    displaySubtotals: PropTypes.bool.isRequired,
    displayCurrencySymbols: PropTypes.bool.isRequired,
    displayLineItemsInclusiveOfTax: PropTypes.bool.isRequired
  }).isRequired,
  doSortLineItemsMove: PropTypes.func.isRequired,
  sortedLineItemElements: PropTypes.arrayOf(lineItemElementType).isRequired
};

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(QuoteLineItems)
);
