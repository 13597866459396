import type { Dictionary } from "@streamtimefe/types";
import type { TSearchItem } from "@streamtimefe/utils";
import { SearchItem } from "@streamtimefe/utils";

import { EntityDescriptorEnum, type TEntityDescriptorEnum } from "../../enums";
import { ColumnTooltipEnum, type TColumnTooltipEnum } from "../ColumnTooltip";
import { NumericState, type TNumericStateCalculator } from "../NumericState";
import {
  StatisticMode,
  StatisticModeEnum,
  type TStatisticModeEnum,
} from "../StatisticMode";
import { type TValueFormatEnum, ValueFormatEnum } from "../ValueFormat";
import { StatisticFieldEnum, type TStatisticFieldEnum } from "./StatisticField";

type TProperty = {
  name: string;
  format: TValueFormatEnum;
  descriptiveName?: string;
  containsFinancial?: true;
  containsCostRate?: true;
  tooltip?: TColumnTooltipEnum;
  forceMode?: TStatisticModeEnum;
  numericStateOverride?: TNumericStateCalculator[];
  nameOverrides?: Dictionary<TEntityDescriptorEnum, string>;
};

const properties: Record<TStatisticFieldEnum, TProperty> = {
  [StatisticFieldEnum.Count]: { name: "Count", format: ValueFormatEnum.Number },
  [StatisticFieldEnum.LoggedTimeTotalExTax]: {
    name: "Total Sell",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Time in sell value",
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.UsedSell,
  },
  [StatisticFieldEnum.QuoteInvoicedExTax]: {
    name: "Amount Invoiced",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Invoiced ex tax",
    containsFinancial: true,
  },
  [StatisticFieldEnum.QuoteInvoicedPercentage]: {
    name: "Amount Invoiced (%)",
    format: ValueFormatEnum.Percentage,
    containsFinancial: true,
  },
  [StatisticFieldEnum.QuoteInvoicedBalanceRemaining]: {
    name: "Balance To Invoice",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedExpenseInvoicedBalanceRemaining]: {
    name: "Balance To Invoice",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedExpenseInvoicedExTax]: {
    name: "Amount Invoiced",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Invoiced ex tax",
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedExpenseInvoicedPercentage]: {
    name: "Amount Invoiced (%)",
    format: ValueFormatEnum.Percentage,
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedTimeMinutes]: {
    name: "Duration",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.LoggedTimeTotalCostExTax]: {
    name: "Total Cost",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Time in cost value",
    containsFinancial: true,
    containsCostRate: true,
    tooltip: ColumnTooltipEnum.UsedCost,
  },
  [StatisticFieldEnum.LoggedExpenseTotalCostExTax]: {
    name: "Total Cost",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Cost in expenses",
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedExpenseTotalAmountExTax]: {
    name: "Total Sell",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Value in expenses",
    containsFinancial: true,
  },
  [StatisticFieldEnum.PurchaseOrderAmountPaidIncTax]: {
    name: "Amount Paid",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.InvoiceAmountExTax]: {
    name: "Invoiced Ex Tax",
    format: ValueFormatEnum.Currency,
    descriptiveName: "Invoiced",
    containsFinancial: true,
  },
  [StatisticFieldEnum.QuoteTotalAmountExTax]: {
    name: "Quoted Ex Tax",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobProfitMargin]: {
    name: "Profit Margin",
    format: ValueFormatEnum.Percentage,
    containsFinancial: true,
    containsCostRate: true,
    tooltip: ColumnTooltipEnum.ProfitMargin,
  },
  [StatisticFieldEnum.JobProfit]: {
    name: "Total Used Profit",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
    tooltip: ColumnTooltipEnum.TotalProfit,
  },
  [StatisticFieldEnum.HappinessScore]: {
    name: "Happiness",
    format: ValueFormatEnum.Happiness,
  },
  [StatisticFieldEnum.InvoiceAmountIncTax]: {
    name: "Invoiced Inc Tax",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobFinalBudget]: {
    name: "Budget",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.Budget,
  },
  [StatisticFieldEnum.JobBudgetVariancePercentage]: {
    name: "Budget Variance (%)",
    format: ValueFormatEnum.Percentage,
    containsFinancial: true,
    numericStateOverride: NumericState.Calculators.PositiveWarning,
  },
  [StatisticFieldEnum.JobBudgetVariance]: {
    name: "Budget Variance ($)",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    numericStateOverride: NumericState.Calculators.PositiveWarning,
  },
  [StatisticFieldEnum.JobTotalLoggedCostExTax]: {
    name: "Total Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
    tooltip: ColumnTooltipEnum.TotalCost,
  },
  [StatisticFieldEnum.JobTotalPlannedExTax]: {
    name: "Total Planned Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.TotalPlannedSell,
  },
  [StatisticFieldEnum.JobTotalLoggedExTax]: {
    name: "Total Used Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.TotalUsedSell,
  },
  [StatisticFieldEnum.JobTotalPlannedExpensesExTax]: {
    name: "Expenses Planned Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.PlannedExpensesSell,
  },
  [StatisticFieldEnum.JobTotalLoggedMinutes]: {
    name: "Used (H)",
    format: ValueFormatEnum.Minutes,
    tooltip: ColumnTooltipEnum.UsedHours,
  },
  [StatisticFieldEnum.JobTotalPlannedMinutes]: {
    name: "Planned (H)",
    format: ValueFormatEnum.Minutes,
    tooltip: ColumnTooltipEnum.PlannedHours,
  },
  [StatisticFieldEnum.JobTotalNonBillableLoggedMinutes]: {
    name: "NB Time Used (H)",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.JobTotalNonBillablePlannedMinutes]: {
    name: "NB Time Planned (H)",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.JobTotalNonBillablePlannedTimeExTax]: {
    name: "NB Time Planned Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobTotalNonBillableLoggedTimeExTax]: {
    name: "NB Time Used Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobTotalLoggedTimeExTax]: {
    name: "Time Used Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobTotalLoggedTimeCostExTax]: {
    name: "Time Used Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
  },
  [StatisticFieldEnum.JobTotalLoggedExpensesCostExTax]: {
    name: "Expenses Used Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.UsedExpensesCost,
  },
  [StatisticFieldEnum.JobTotalLoggedExpensesExTax]: {
    name: "Expenses Used Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.UsedExpensesSell,
  },
  [StatisticFieldEnum.JobTotalLoggedTimeProfitExTax]: {
    name: "Time Used Profit",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
    tooltip: ColumnTooltipEnum.UsedProfit,
  },
  [StatisticFieldEnum.JobTotalLoggedExpensesProfitExTax]: {
    name: "Expenses Used Profit",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.UsedExpensesProfit,
  },
  [StatisticFieldEnum.JobTotalPlannedExpensesCostExTax]: {
    name: "Expenses Planned Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.PlannedExpensesCost,
  },
  [StatisticFieldEnum.JobTotalInvoicedExTax]: {
    name: "Total Invoiced",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.JobDaysUntilDueDate]: {
    name: "Days Until Due Date",
    format: ValueFormatEnum.Days,
    forceMode: StatisticModeEnum.Average,
  },
  [StatisticFieldEnum.PurchaseOrderBalance]: {
    name: "Balance To Pay",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.LoggedExpenseProfitExTax]: {
    name: "Total Profit",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.QuoteTotalAmountIncTax]: {
    name: "Quoted Inc Tax",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.InvoiceAmountPaidIncTax]: {
    name: "Amount Paid",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.InvoiceBalanceIncTax]: {
    name: "Balance To Pay",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
  },
  [StatisticFieldEnum.AvailabilityMinutes]: {
    name: "Availability",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.JobItemUserRolePlannedMinutes]: {
    name: "Duration (H)",
    format: ValueFormatEnum.Minutes,
    nameOverrides: {
      [EntityDescriptorEnum.JobItemUserRolePlannedTime]: "Planned (H)",
      [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: "Unscheduled (H)",
    },
  },
  [StatisticFieldEnum.JobItemUserRolePlannedTimeExTax]: {
    name: "Total Sell",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    nameOverrides: {
      [EntityDescriptorEnum.JobItemUserRolePlannedTime]: "Planned Sell",
      [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: "Unscheduled Sell",
    },
  },
  [StatisticFieldEnum.JobItemUserRolePlannedTimeCostExTax]: {
    name: "Total Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
    nameOverrides: {
      [EntityDescriptorEnum.JobItemUserRolePlannedTime]: "Planned Cost",
      [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: "Unscheduled Cost",
    },
  },
  [StatisticFieldEnum.CalculationCurrency]: {
    name: "Total",
    format: ValueFormatEnum.Currency,
  },
  [StatisticFieldEnum.CalculationDays]: {
    name: "Total",
    format: ValueFormatEnum.Days,
  },
  [StatisticFieldEnum.CalculationMinutes]: {
    name: "Total",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.CalculationNumber]: {
    name: "Total",
    format: ValueFormatEnum.Number,
  },
  [StatisticFieldEnum.CalculationPercentage]: {
    name: "Total",
    format: ValueFormatEnum.Percentage,
  },
  [StatisticFieldEnum.UserAvailability]: {
    name: "Work Hours (H)",
    format: ValueFormatEnum.Minutes,
  },
  [StatisticFieldEnum.JobTotalPlannedTimeExTax]: {
    name: "Time Planned Sell",
    format: ValueFormatEnum.Currency,
  },
  [StatisticFieldEnum.JobTotalLoggedNonBillableTimeCostExTax]: {
    name: "NB Time Used Cost",
    format: ValueFormatEnum.Currency,
    containsFinancial: true,
    containsCostRate: true,
  },
} as const;

export function getFormat(statisticField: TStatisticFieldEnum) {
  return properties[statisticField].format;
}

export function getName(
  statisticField: TStatisticFieldEnum,
  statisticMode?: TStatisticModeEnum,
  entityDescriptor?: TEntityDescriptorEnum
) {
  let name = properties[statisticField].name;

  if (
    entityDescriptor &&
    properties[statisticField].nameOverrides?.[entityDescriptor]
  ) {
    name = properties[statisticField].nameOverrides![entityDescriptor]!;
  }

  if (statisticMode && StatisticMode.getColumnPrefix(statisticMode)) {
    name = `${StatisticMode.getColumnPrefix(statisticMode)} ${name}`;
  }

  return name;
}

export function getDescriptiveName(
  statisticField: TStatisticFieldEnum,
  statisticMode?: TStatisticModeEnum,
  entityDescriptor?: TEntityDescriptorEnum
) {
  if (properties[statisticField].descriptiveName) {
    return properties[statisticField].descriptiveName;
  }
  return getName(statisticField, statisticMode, entityDescriptor);
}

export function getNumericStateOverride(statisticField: TStatisticFieldEnum) {
  return properties[statisticField].numericStateOverride;
}

export function getForceMode(statisticField: TStatisticFieldEnum) {
  return properties[statisticField].forceMode;
}

export function getTooltip(statisticField: TStatisticFieldEnum) {
  return properties[statisticField].tooltip;
}

export function containsCostRate(statisticField: TStatisticFieldEnum) {
  return Boolean(properties[statisticField].containsCostRate);
}

export function containsFinancial(statisticField: TStatisticFieldEnum) {
  return Boolean(properties[statisticField].containsFinancial);
}

export function getGroupedSearchItems(
  statisticFields: TStatisticFieldEnum[],
  entityDescriptor: TEntityDescriptorEnum | undefined = undefined,
  access: {
    canAccessCostRates: boolean;
    canViewJobFinancials: boolean;
  }
) {
  const groups: Record<string, TStatisticFieldEnum[]> = {};

  statisticFields
    .filter((statisticField) => {
      if (containsCostRate(statisticField) && !access.canAccessCostRates) {
        return false;
      }
      if (containsFinancial(statisticField) && !access.canViewJobFinancials) {
        return false;
      }
      return true;
    })
    .forEach((statisticField) => {
      switch (properties[statisticField].format) {
        case ValueFormatEnum.Percentage:
          if (!groups.Percentages) groups.Percentages = [];
          groups.Percentages.push(statisticField);
          break;
        case ValueFormatEnum.Minutes:
          if (!groups.Hours) groups.Hours = [];
          groups.Hours.push(statisticField);
          break;
        case ValueFormatEnum.Currency:
          if (!groups.Financial) groups.Financial = [];
          groups.Financial.push(statisticField);
          break;
        default:
          if (!groups.Other) groups.Other = [];
          groups.Other.push(statisticField);
      }
    });

  const groupedSearchItems: Record<string, TSearchItem<TStatisticFieldEnum>[]> =
    {};

  Object.keys(groups).forEach((key) => {
    groupedSearchItems[key] = getSearchItems(
      groups[key],
      entityDescriptor,
      key
    );
  });

  return groupedSearchItems;
}

export function getSearchItem(
  statisticField: TStatisticFieldEnum,
  entityDescriptor?: TEntityDescriptorEnum,
  group?: string
) {
  const name = getName(statisticField, undefined, entityDescriptor);
  return SearchItem.create(
    statisticField,
    name,
    group
      ? `${getName(statisticField, undefined, entityDescriptor)} ${group}`
      : name,
    statisticField
  );
}

export function getSearchItems(
  statisticFields: TStatisticFieldEnum[],
  entityDescriptor?: TEntityDescriptorEnum,
  group?: string
) {
  return SearchItem.createSortedArray(statisticFields, (statisticField) =>
    getSearchItem(statisticField, entityDescriptor, group)
  );
}
