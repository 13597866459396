import clsx from "clsx";
import { forwardRef } from "react";
import { Input, InputProps } from "st-shared/components";

import {
  headingCss,
  hiddenFieldCss,
  inputCss,
  leftAlignCss,
} from "./DocumentLineField.css";

interface Props extends Omit<InputProps, "variant"> {
  isHiddenField?: boolean;
  isHeading?: boolean;
  leftAlign?: boolean;
}

export const DocumentLineField = forwardRef<HTMLInputElement, Props>(
  ({ className, isHiddenField, isHeading, leftAlign, ...props }, ref) => {
    return (
      <Input
        {...props}
        ref={ref}
        className={clsx(
          inputCss,
          className,
          isHiddenField && hiddenFieldCss,
          isHeading && headingCss,
          leftAlign && leftAlignCss
        )}
      />
    );
  }
);

DocumentLineField.displayName = "DocumentLineField";
