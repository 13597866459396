import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataLoggedExpenseName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobLoggedExpenseCreated = ({
  activityEntry,

  canEditJobs,
}) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" created the expense "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataLoggedExpenseName(activityEntry)}
    </LinkToActivityEntryEntity>
  </SystemMessage>
);

JobLoggedExpenseCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobLoggedExpenseCreated;
