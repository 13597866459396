import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { sortBy } from "lodash-es";
import { useMemo } from "react";
import type { SelectKey } from "st-shared/components";
import { Select, SelectItem } from "st-shared/components";
import { ReportingFilterGroupTypeNullName } from "st-shared/entities";
import { useCustomerCurrency } from "st-shared/stores";
import { FilterGroupTypeProperties, getFilterName } from "st-shared/types";

import {
  selectCss,
  subHeaderSelectorCss,
  subHeaderSelectorDesciptionCss,
} from "./FilterGroupSelector.css";

type FilterGroupSelectorProps = {
  title: string;
  setFilterGroupType: (filterGroupType: TFilterGroupTypeEnum | null) => void;
  filterGroupType: TFilterGroupTypeEnum | null;
  filterGroupTypes: readonly TFilterGroupTypeEnum[];
};

export function FilterGroupSelector({
  title,
  filterGroupType,
  setFilterGroupType,
  filterGroupTypes,
}: FilterGroupSelectorProps) {
  const customerCurrency = useCustomerCurrency();

  const selectItems = useMemo(
    () =>
      sortBy(
        filterGroupTypes,
        (filterGroupTypeId) => FilterGroupTypeProperties[filterGroupTypeId].name
      ).map((filterGroupType) => ({
        id: filterGroupType,
        name: getFilterName(filterGroupType, customerCurrency),
      })),
    [customerCurrency, filterGroupTypes]
  );

  function onSelectionChange(value: SelectKey) {
    if (value === "all") {
      if (filterGroupType !== null) {
        setFilterGroupType(null);
      }
      return;
    }
    const newFilterGroupType = Number(value) as TFilterGroupTypeEnum;
    if (newFilterGroupType !== filterGroupType) {
      setFilterGroupType(newFilterGroupType);
    }
  }

  return (
    <div className={subHeaderSelectorCss}>
      <span className={subHeaderSelectorDesciptionCss}>{title}</span>
      <Select
        aria-label="Filter Group Selector"
        items={selectItems}
        selectedKey={filterGroupType ?? ReportingFilterGroupTypeNullName}
        onSelectionChange={onSelectionChange}
        buttonProps={{ className: selectCss }}
      >
        {(filterGroupType === null || selectItems.length === 0) && (
          <SelectItem key={null} id={ReportingFilterGroupTypeNullName}>
            {ReportingFilterGroupTypeNullName}
          </SelectItem>
        )}
        {selectItems.map((item) => (
          <SelectItem key={item.id} id={item.id}>
            {item.name}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
}

type SubFilterGroupSelectorProps = {
  title: string;
  setFilterGroupType: (filterGroupType: TFilterGroupTypeEnum) => void;
  filterGroupType: TFilterGroupTypeEnum | null;
  filterGroupTypes: readonly TFilterGroupTypeEnum[];
};

export function SubFilterGroupSelector({
  title,
  filterGroupType,
  setFilterGroupType,
  filterGroupTypes,
}: SubFilterGroupSelectorProps) {
  const customerCurrency = useCustomerCurrency();

  const selectItems = useMemo(
    () =>
      sortBy(
        filterGroupTypes,
        (filterGroupTypeId) => FilterGroupTypeProperties[filterGroupTypeId].name
      ).map((filterGroupType) => ({
        id: filterGroupType,
        name: getFilterName(filterGroupType, customerCurrency),
      })),
    [customerCurrency, filterGroupTypes]
  );

  function onSelectionChange(value: SelectKey) {
    const newFilterGroupType = Number(value) as TFilterGroupTypeEnum;
    if (newFilterGroupType !== filterGroupType) {
      setFilterGroupType(newFilterGroupType);
    }
  }

  return (
    <div className={subHeaderSelectorCss}>
      <span className={subHeaderSelectorDesciptionCss}>{title}</span>
      <Select
        aria-label="Filter Group Selector"
        items={selectItems}
        selectedKey={filterGroupType}
        onSelectionChange={onSelectionChange}
        buttonProps={{ className: selectCss }}
      >
        {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
      </Select>
    </div>
  );
}
