import { useSelector } from "react-redux";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";

import { AppState } from "../../../AppState";
import { selectMasterJobItemRate } from "../../itemRate/selectors/selectMasterJobItemRate";
import { selectMasterJobItemGroupedOptions } from "./selectMasterJobItemGroupedOptions";

export function selectMasterJobItemGroupedOptionsWithRate(
  state: AppState,
  props: { rateCardId: number }
) {
  return selectMasterJobItemGroupedOptions(state).map((option) => {
    if (option.optionType === LineItemOptionTypes.OptionsHeading) {
      return option;
    }
    return {
      ...option,
      rate: selectMasterJobItemRate(state, {
        masterJobItemId: option.key,
        rateCardId: props.rateCardId,
      }),
    };
  });
}

export function useMasterJobItemGroupedOptionsWithRate(rateCardId: number) {
  return useSelector((state: AppState) =>
    selectMasterJobItemGroupedOptionsWithRate(state, { rateCardId })
  );
}
