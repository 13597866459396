import { ThemeProvider } from "@material-ui/core";
import type { PopoverProps as MuiPopoverProps } from "@material-ui/core/Popover";
import MuiPopover from "@material-ui/core/Popover";

import popoversTheme from "../../lib/themes/popovers";

export interface PopoverProps extends MuiPopoverProps {
  arrowClass?: string;
}

function Popover({ children = null, arrowClass = "", ...props }: PopoverProps) {
  return (
    <ThemeProvider theme={popoversTheme}>
      <MuiPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          square: true,
          classes: { root: arrowClass },
        }}
        {...props}
      >
        {children}
      </MuiPopover>
    </ThemeProvider>
  );
}

export default Popover;
