import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_USER,
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE,
  DATA_ATTRIBUTE_USER,
  DEFAULT_COORDS,
  KEY_ALT_LEFT,
  KEY_ALT_RIGHT,
  SCHEDULE_BLOCK_DRAG_CANCEL,
  SCHEDULE_BLOCK_DRAG_END_MULTIPLE,
  SCHEDULE_BLOCK_DRAG_END_SAGA,
  SCHEDULE_BLOCK_DRAG_MULTIPLE,
  SCHEDULE_BLOCK_DRAG_START,
  SCHEDULE_BLOCK_DRAG_START_MULTIPLE,
} from "../../../lib/constants";
import {
  getDataIntValue,
  getDataValue,
  getElementClientOffset,
  getElementHtml,
  getElementOffset,
  getElementSize,
  getPointerClientOffset,
  isDisabledElement,
  subtractCoords,
} from "../../../lib/dom";
import {
  addKeyEventListener,
  removeKeyEventListener,
} from "../../../lib/events";
import {
  getTargetBlockElement,
  getTargetBlockKey,
  getTargetBlocksContainerElement,
  getTargetResizeHandle,
} from "../../../lib/eventTargets";
import { entityIdListType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { BlockRect } from "../ScheduleScrollProvider/BlockConsumer";

const mapState = null;

const mapDispatch = (dispatch) => ({
  doStartDragBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_START, payload)),

  doEndDragBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_END_SAGA, payload)),

  doCancelDragBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_CANCEL, payload)),

  doStartDragMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_START_MULTIPLE, payload)),

  doDragMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_MULTIPLE, payload)),

  doEndDragMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DRAG_END_MULTIPLE, payload)),
});

const getTargetContainerData = (e) => {
  const element = getTargetBlocksContainerElement(e);
  return {
    userId: getDataIntValue(element, DATA_ATTRIBUTE_USER),
    jobId: getDataIntValue(element, DATA_ATTRIBUTE_JOB),
    jobItemId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM),
    jobItemUserId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM_USER),
    scheduleFilterMode: getDataValue(
      element,
      DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE
    ),
  };
};

export default (WrappedComponent) => {
  class BlockDragHandlers extends React.PureComponent {
    dragItem = null;

    isDragging = false;

    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      selectedBlockKeys: entityIdListType.isRequired,
      doStartDragBlock: PropTypes.func.isRequired,
      doEndDragBlock: PropTypes.func.isRequired,
      doCancelDragBlock: PropTypes.func.isRequired,
      doStartDragMultipleBlocks: PropTypes.func.isRequired,
      doDragMultipleBlocks: PropTypes.func.isRequired,
      doEndDragMultipleBlocks: PropTypes.func.isRequired,
      doUnselectAllBlocks: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      getScrollOffsetX: PropTypes.func.isRequired,
      getDateAtOffsetX: PropTypes.func.isRequired,
      className: PropTypes.string,
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: "",
    };

    state = {
      ghostPosition: DEFAULT_COORDS,
      isCopyMode: false,
    };

    componentDidMount() {
      addKeyEventListener("keydown", this.onKeyDown);
      addKeyEventListener("keyup", this.onKeyUp);
    }

    componentWillUnmount() {
      removeKeyEventListener("keydown", this.onKeyDown);
      removeKeyEventListener("keyup", this.onKeyUp);
    }

    getPointerDistance(e) {
      const { origin } = this.dragItem;
      const pointerPosition = getPointerClientOffset(e);
      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getMovedPosition(e) {
      const { origin } = this.dragItem;
      const pointerDistance = this.getPointerDistance(e);
      return {
        x: origin.blockPosition.x + pointerDistance.x,
        y: origin.blockPosition.y + pointerDistance.y,
      };
    }

    getTargetDate(e) {
      const { getScrollOffsetX, getDateAtOffsetX } = this.props;
      const { origin } = this.dragItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const newBlockOffsetX =
        origin.blockOffset.x + pointerDistance.x + scrollDistanceX;

      return getDateAtOffsetX(newBlockOffsetX);
    }

    onKeyDown = (e) => {
      switch (e.code) {
        case KEY_ALT_LEFT:
        case KEY_ALT_RIGHT:
          if (!this.isMultiSelectMode) this.setState({ isCopyMode: true });
          break;
        default:
      }
    };

    onKeyUp = (e) => {
      switch (e.code) {
        case KEY_ALT_LEFT:
        case KEY_ALT_RIGHT:
          this.setState({ isCopyMode: false });
          break;
        default:
      }
    };

    onPointerDown = (e) => {
      const {
        getScrollOffsetX,
        getDateAtOffsetX,
        canEditTeamLoggedTimes,
        isSplitting,
        selectedBlockKeys,
        onPointerDown,
        doStartDragBlock,
        doStartDragMultipleBlocks,
      } = this.props;
      const blockKey = getTargetBlockKey(e);
      const blockNode = blockKey && getTargetBlockElement(e);
      const isDisabled =
        blockNode &&
        isDisabledElement(blockNode.querySelector(".scheduleBlock"));
      const isResizeEvent = blockKey && !!getTargetResizeHandle(e);

      if (
        !this.isUnselectAction(e, blockKey) &&
        canEditTeamLoggedTimes &&
        !isSplitting &&
        blockNode &&
        !isDisabled &&
        !isResizeEvent
      ) {
        const blockOffset = getElementOffset(blockNode);
        const blockPosition = getElementClientOffset(blockNode);
        const pointerPosition = getPointerClientOffset(e);
        const blockSize = getElementSize(blockNode);
        const blockHtml = getElementHtml(blockNode);
        const date = getDateAtOffsetX(blockOffset.x);
        const scrollOffsetX = getScrollOffsetX();

        const { userId, jobId, jobItemId, jobItemUserId, scheduleFilterMode } =
          getTargetContainerData(e);

        this.dragItem = {
          blockKey,
          userId,
          jobId,
          jobItemId,
          jobItemUserId,
          date,
          selectedBlockKeys,
          scheduleFilterMode,
          origin: {
            scrollOffsetX,
            blockOffset,
            blockPosition,
            pointerPosition,
            blockSize,
            blockHtml,
            userId,
            jobId,
            jobItemId,
            jobItemUserId,
            date,
            scheduleFilterMode,
          },
        };

        if (this.isMultiSelectMode) {
          doStartDragMultipleBlocks(this.dragItem);
        } else {
          doStartDragBlock(this.dragItem);
        }

        this.isDragging = true;
        this.setState({ ghostPosition: blockPosition });
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = (e) => {
      const { doDragMultipleBlocks, onPointerMove } = this.props;

      if (this.isDragging) {
        this.setState({
          ghostPosition: this.getMovedPosition(e),
        });

        if (this.isMultiSelectMode) {
          const prevDragItem = this.dragItem;
          const date = this.getTargetDate(e) || prevDragItem.date;
          const hasMoved = date !== prevDragItem.date;

          this.moveBlock(
            date,
            this.dragItem.origin.userId,
            this.dragItem.origin.jobId,
            this.dragItem.origin.jobItemId,
            this.dragItem.origin.jobItemUserId,
            this.dragItem.origin.scheduleFilterMode
          );

          if (hasMoved) doDragMultipleBlocks(this.dragItem);
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = (e) => {
      const { doEndDragMultipleBlocks, doEndDragBlock, onPointerUp } =
        this.props;

      if (this.isDragging) {
        const prevDragItem = this.dragItem;
        const date = this.getTargetDate(e) || prevDragItem.date;

        if (this.isMultiSelectMode) {
          this.moveBlock(
            date,
            this.dragItem.origin.userId,
            this.dragItem.origin.jobId,
            this.dragItem.origin.jobItemId,
            this.dragItem.origin.jobItemUserId,
            this.dragItem.origin.scheduleFilterMode
          );

          doEndDragMultipleBlocks(this.dragItem);
        } else {
          const {
            userId,
            jobId,
            jobItemId,
            jobItemUserId,
            scheduleFilterMode,
          } = getTargetContainerData(e);

          this.moveBlock(
            date,
            userId || prevDragItem.userId,
            jobId || prevDragItem.jobId,
            jobItemId || prevDragItem.jobItemId,
            jobItemUserId || prevDragItem.jobItemUserId,
            scheduleFilterMode || prevDragItem.scheduleFilterMode
          );

          doEndDragBlock(this.dragItem);
        }

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = (e) => {
      const { onLostPointerCapture, doCancelDragBlock } = this.props;

      if (this.isDragging) {
        doCancelDragBlock(this.dragItem);

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className, canEditTeamLoggedTimes } = this.props;
      const { isCopyMode } = this.state;
      const classes = className ? [className] : [];

      if (this.isDragging) classes.push("dragging");
      if (canEditTeamLoggedTimes && isCopyMode) classes.push("copy");

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp,
        onLostPointerCapture: this.onLostPointerCapture,
      };
    }

    get ghostStyle() {
      const { ghostPosition } = this.state;

      if (!this.isDragging) return { display: "none" };

      const { origin } = this.dragItem;

      return {
        left: ghostPosition.x,
        top: ghostPosition.y,
        width: origin.blockSize.w,
        height: origin.blockSize.h,
      };
    }

    get isMultiSelectMode() {
      const { selectedBlockKeys } = this.props;
      const isMultipleSelected = selectedBlockKeys.length > 1;

      return (
        isMultipleSelected &&
        (!this.dragItem || selectedBlockKeys.includes(this.dragItem.blockKey))
      );
    }

    isUnselectAction(e, blockKey) {
      const { selectedBlockKeys } = this.props;
      return (
        !e.metaKey &&
        selectedBlockKeys.length > 0 &&
        !selectedBlockKeys.includes(blockKey)
      );
    }

    moveBlock(
      date,
      userId,
      jobId,
      jobItemId,
      jobItemUserId,
      scheduleFilterMode
    ) {
      const { isCopyMode } = this.state;
      const { selectedBlockKeys } = this.props;
      const duplicate = isCopyMode;
      const hasChanged =
        userId !== this.dragItem.origin.userId ||
        jobId !== this.dragItem.origin.jobId ||
        jobItemId !== this.dragItem.origin.jobItemId ||
        jobItemUserId !== this.dragItem.origin.jobItemUserId ||
        date !== this.dragItem.origin.date ||
        scheduleFilterMode !== this.dragItem.origin.scheduleFilterMode;

      this.dragItem = {
        ...this.dragItem,
        userId,
        jobId,
        jobItemId,
        jobItemUserId,
        date,
        scheduleFilterMode,
        hasChanged,
        selectedBlockKeys: this.isMultiSelectMode ? selectedBlockKeys : [],
        duplicate,
        newBlockKey: duplicate && Entity.temporaryId(),
      };
    }

    render() {
      const { props, className, handlers, dragItem } = this;
      const { isDragging } = this;

      return (
        <>
          <WrappedComponent
            {...{ ...props, className, isDragging, ...handlers }}
          />
          {dragItem && !this.isMultiSelectMode && (
            <GhostContainer
              style={this.ghostStyle}
              dangerouslySetInnerHTML={{
                __html: dragItem.origin.blockHtml,
              }}
            />
          )}
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockDragHandlers);
};

const GhostContainer = styled(BlockRect)`
  position: fixed;
  background-color: transparent;
  z-index: var(--z-index-schedule-ghost-block);
  animation: wobble 2s ease 0.3s infinite;
  transition: none;
  pointer-events: none;
  > .scheduleBlock {
    box-shadow: var(--box-shadow-8dp);
    animation: none !important;
    &.filtered {
      opacity: 1;
    }
    &:not(.filtered) {
      opacity: 0.3;
    }
  }
`;
