import type {
  TCurrency,
  THappinessScoreEnum,
  TValueFormatEnum,
} from "@streamtimefe/entities";
import { HappinessScore, ValueFormatEnum } from "@streamtimefe/entities";
import numeral from "numeral";

import {
  convertMinutesToHoursRaw,
  convertMinutesToTimeHM,
  formatCustomerCurrency,
  formatPercentage,
  formatPercentageRaw,
  NumberFormats,
  pluralizeDay,
} from "../lib";

// deprecated: use ValueFormat

export function formatStatisticValue(
  value: number,
  format: TValueFormatEnum,
  customerCurrency?: TCurrency,
  loose: boolean = false
) {
  switch (format) {
    case ValueFormatEnum.Percentage:
      return formatPercentage(value);
    case ValueFormatEnum.Currency:
      if (customerCurrency === undefined) return "";
      return formatCustomerCurrency(customerCurrency, {
        value,
        format: loose ? NumberFormats.Abbreviated : NumberFormats.NoDecimal,
      });
    case ValueFormatEnum.Minutes:
      if (loose) return convertMinutesToTimeHM(value, false, "h");
      return convertMinutesToTimeHM(value, false);
    case ValueFormatEnum.Happiness:
      if (loose) return HappinessScore.format(value as THappinessScoreEnum);
      return HappinessScore.format(Math.floor(value) as THappinessScoreEnum);
    case ValueFormatEnum.Days:
      return `${numeral(Math.round(value)).format(
        loose ? NumberFormats.Abbreviated : NumberFormats.NoDecimal
      )} ${pluralizeDay(value)}`;
    case ValueFormatEnum.Number:
      return numeral(value).format(NumberFormats.OptionalDecimal);
    case ValueFormatEnum.Value:
    default:
      return value;
  }
}

export function formatStatisticValueCSV(
  value: number,
  format: TValueFormatEnum
) {
  switch (format) {
    case ValueFormatEnum.Percentage:
      return formatPercentageRaw(value);
    case ValueFormatEnum.Minutes:
      return convertMinutesToHoursRaw(value);
    case ValueFormatEnum.Currency:
    case ValueFormatEnum.Days:
    case ValueFormatEnum.Number:
      return numeral(value).format(NumberFormats.OptionalDecimalRaw);
    case ValueFormatEnum.Value:
    default:
      return value;
  }
}
