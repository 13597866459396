import React from "react";
import { useDispatch } from "react-redux";
import { JOB_PHASE_SET_ALL_EXPANDED } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { useCanExpandAndCollapsePhases } from "../../../redux/selectors/jobDetails/canExpandAndCollapsePhases";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useAreAllCollapsedJobPhase } from "../../../redux/selectors/jobPhase/ui/areAllCollapsed";
import { IconButton } from "../../elements/Button";
import UnfoldLessIcon from "../../elements/Icons/UnfoldLessIcon";
import UnfoldMoreIcon from "../../elements/Icons/UnfoldMoreIcon";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import { useJobId } from "../context/JobIdContext";
import { CenteredDiv } from "../styles";

const ExpandCollapsePhaseButton = () => {
  const jobId = useJobId();
  const canExpandAndCollapse = useCanExpandAndCollapsePhases(jobId);
  const allCollapsed = useAreAllCollapsedJobPhase(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const expandCollapseAllPhases = isExpanded => {
    dispatch(
      createAction(JOB_PHASE_SET_ALL_EXPANDED, {
        jobId,
        isExpanded
      })
    );
  };

  if (!canExpandAndCollapse || !isJobEditable) return null;

  return (
    <>
      {allCollapsed && (
        <Tooltip
          title={<CenteredDiv>Expand all phases</CenteredDiv>}
          placement="top"
        >
          <IconButton onClick={() => expandCollapseAllPhases(true)}>
            <UnfoldMoreIcon size={ICON_SIZE.X_LARGE} />
          </IconButton>
        </Tooltip>
      )}
      {!allCollapsed && (
        <Tooltip
          title={<CenteredDiv>Collapse all phases</CenteredDiv>}
          placement="top"
        >
          <IconButton onClick={() => expandCollapseAllPhases(false)}>
            <UnfoldLessIcon size={ICON_SIZE.X_LARGE} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ExpandCollapsePhaseButton;
