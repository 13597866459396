import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../../index";
import { selectJobUi } from "./index";

export const selectJobPreviousJob = createCachedSelector([selectJobUi], job =>
  get(job, `previousJob`, null)
)(selectPropsJobId);

export const useJobPreviousJob = jobId =>
  useSelector(state => selectJobPreviousJob(state, { jobId }));
