import type { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import type { ChangeEventHandler } from "react";

import type { CheckboxProps } from ".";
import { Checkbox } from ".";
import {
  labelCheckboxBoxCss,
  labelCheckboxCss,
  labelCheckboxLabelCheckedCss,
  labelCheckboxLabelCss,
} from "./LabelCheckbox.css";

export interface LabelCheckboxProps
  extends Omit<FormControlLabelProps, "control" | "onChange"> {
  label: string;
  className?: string;
  labelClassName?: string;
  checkboxProps?: CheckboxProps;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export function LabelCheckbox({
  label,
  className,
  labelClassName,
  checked,
  disabled,
  checkboxProps,
  onChange,
  classes,
  ...props
}: LabelCheckboxProps) {
  return (
    <FormControlLabel
      className={clsx(labelCheckboxCss, className)}
      checked={checked}
      disabled={disabled}
      control={
        <Checkbox
          {...checkboxProps}
          className={clsx(labelCheckboxBoxCss, checkboxProps?.className)}
          inputProps={{ onChange, ...checkboxProps?.inputProps }}
          checked={checked}
          name={label}
        />
      }
      label={label}
      classes={{
        ...classes,
        label: clsx(
          labelCheckboxLabelCss,
          labelClassName,
          checked && labelCheckboxLabelCheckedCss
        ),
      }}
      {...props}
    />
  );
}
