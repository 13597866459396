import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getDisplayQuoteAcceptance,
  organisationAccountingPreferencesEntityType
} from "../../../lib/entities/organisationAccountingPreferencesEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectOrganisationAccountingPreferences } from "../../../redux/selectors/organisationAccountingPreferencesSelectors";
import Checkbox from "../../elements/Checkbox";
import LanguageOption from "../../modules/CommercialDocuments/LanguageOption";

const mapState = (state, props) => ({
  organisationAccountingPreferences: selectOrganisationAccountingPreferences(
    state
  )
});

class QuoteAcceptance extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    organisationAccountingPreferences:
      organisationAccountingPreferencesEntityType.isRequired
  };

  render() {
    const { organisationAccountingPreferences } = this.props;

    if (!getDisplayQuoteAcceptance(organisationAccountingPreferences))
      return null;

    return (
      <Wrapper>
        <Label>
          <LanguageOption optionKey="acceptThisQuote" />
        </Label>
        <Agreement>
          <AgreeCheckbox disabled />
          <AgreeLabel>
            <LanguageOption optionKey="agreeThisQuote" />
          </AgreeLabel>
        </Agreement>
        <Field>
          <LanguageOption optionKey="name" />
        </Field>
        <Row>
          <Field style={{ width: 220 }}>
            <LanguageOption optionKey="signature" />
          </Field>
          <Field style={{ width: 140 }}>
            <LanguageOption optionKey="acceptDate" />
          </Field>
        </Row>
        <Field>
          <LanguageOption optionKey="additionalComments" />
        </Field>
      </Wrapper>
    );
  }
}

export default connect(mapState)(QuoteAcceptance);

const Wrapper = styled.div`
  margin-top: 20px;
  width: 460px;
`;

const Label = styled.div`
  color: var(--color-gray-dark);
  font-size: 12px;
`;

const Agreement = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const AgreeCheckbox = styled(Checkbox)`
  margin-left: -8px;
`;

const AgreeLabel = styled.div`
  margin-left: 5px;
  padding-top: 3px;
  color: black;
  font-weight: bold;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Field = styled(Label)`
  margin-top: 25px;
  border-top: 1px solid var(--color-gray-dark);
  padding-top: 4px;
`;
