import { call, takeLatest } from "redux-saga/effects";
import { sendSetScheduleFilterTeamMembers } from "../../../lib/WebAppAPI/schedule";
import { SCHEDULE_SET_FILTER_TEAM_MEMBERS } from "../../../lib/constants";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* setScheduleFilterTeamMembers(action) {
  try {
    yield call(sendSetScheduleFilterTeamMembers, action.payload);
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchSetScheduleFilterTeamMembers() {
  yield takeLatest(
    [SCHEDULE_SET_FILTER_TEAM_MEMBERS],
    setScheduleFilterTeamMembers
  );
}
