import numeral from "numeral";
import { useSelector } from "react-redux";
import {
  LOGGED_EXPENSE_STATUS_ID_APPROVED,
  LOGGED_EXPENSE_STATUS_ID_DRAFT,
  LOGGED_EXPENSE_STATUS_ID_PAID
} from "../../../lib/constants";
import {
  getJobCurrencyTotalCostExTax,
  getJobCurrencyTotalExTax,
  getLoggedExpenseStatusId
} from "../../../lib/entities/loggedExpenseEntity";
import { selectLoggedExpenseIdsByJobId } from "./loggedExpenseIdsByJobId";
import { selectLoggedExpense } from "./index";

export const selectLoggedExpenseStats = (state, { jobId }) => {
  const loggedExpenseIds = selectLoggedExpenseIdsByJobId(state, { jobId });

  const counts = {
    draft: 0,
    approved: 0,
    paid: 0
  };

  const totals = {
    draft: 0,
    approved: 0,
    paid: 0
  };

  const totalCosts = {
    draft: 0,
    approved: 0,
    paid: 0
  };

  loggedExpenseIds.forEach(loggedExpenseId => {
    const loggedExpense = selectLoggedExpense(state, { loggedExpenseId });

    switch (getLoggedExpenseStatusId(loggedExpense)) {
      case LOGGED_EXPENSE_STATUS_ID_DRAFT:
        counts.draft += 1;
        totals.draft += numeral(
          getJobCurrencyTotalExTax(loggedExpense)
        ).value();
        totalCosts.draft += numeral(
          getJobCurrencyTotalCostExTax(loggedExpense)
        ).value();
        break;
      case LOGGED_EXPENSE_STATUS_ID_APPROVED:
        counts.approved += 1;
        totals.approved += numeral(
          getJobCurrencyTotalExTax(loggedExpense)
        ).value();
        totalCosts.approved += numeral(
          getJobCurrencyTotalCostExTax(loggedExpense)
        ).value();
        break;
      case LOGGED_EXPENSE_STATUS_ID_PAID:
        counts.paid += 1;
        totals.paid += numeral(getJobCurrencyTotalExTax(loggedExpense)).value();
        totalCosts.paid += numeral(
          getJobCurrencyTotalCostExTax(loggedExpense)
        ).value();
        break;
    }
  });

  return [counts, totals, totalCosts];
};

export const useLoggedExpenseStats = jobId =>
  useSelector(state => selectLoggedExpenseStats(state, { jobId }));
