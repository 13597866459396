import { get } from "lodash-es";
import {
  ACTIVITY_ENTRY_SAVING,
  ACTIVITY_ENTRY_SAVED,
  ACTIVITY_ENTRY_SAVE_ERROR,
  ACTIVITY_ENTRY_DELETING,
  ACTIVITY_ENTRY_DELETE_ERROR,
  ACTIVITY_ENTRY_DELETED,
  ACTIVITY_ENTRY_THREAD_FETCH_REQUEST,
  ACTIVITY_ENTRY_THREAD_FETCH_FAILED,
  ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onSaveRequest = (state, { payload: { activityEntry } }) => ({
  ...state,
  [activityEntry.id]: {
    ...state[activityEntry.id],
    isSaving: true,
    error: null
  }
});

const onSaveSuccess = (state, { payload: { activityEntry } }) => ({
  ...state,
  [activityEntry.id]: {
    ...state[activityEntry.id],
    isSaving: false,
    error: null
  }
});

const onSaveError = (state, { payload: { activityEntry, error } }) => ({
  ...state,
  [activityEntry.id]: { ...state[activityEntry.id], isSaving: false, error }
});

const onDeleteRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isDeleting: true, error: null }
});

const onDeleteSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isDeleting: false, error: null }
});

const onDeleteError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isDeleting: false, error }
});

const onFetchThreadRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    fetchedThread: get(state, `${id}.fetchedThread`, false),
    isFetchingThread: true,
    error: null
  }
});

const onFetchThreadSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    fetchedThread: true,
    isFetchingThread: false,
    error: null
  }
});

const onFetchThreadError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: {
    ...state[id],
    fetchedThread: false,
    isFetchingThread: false,
    error
  }
});

export default createReducer(
  {},
  {
    [ACTIVITY_ENTRY_SAVING]: onSaveRequest,
    [ACTIVITY_ENTRY_SAVED]: onSaveSuccess,
    [ACTIVITY_ENTRY_SAVE_ERROR]: onSaveError,
    [ACTIVITY_ENTRY_DELETING]: onDeleteRequest,
    [ACTIVITY_ENTRY_DELETED]: onDeleteSuccess,
    [ACTIVITY_ENTRY_DELETE_ERROR]: onDeleteError,
    [ACTIVITY_ENTRY_THREAD_FETCH_REQUEST]: onFetchThreadRequest,
    [ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED]: onFetchThreadSuccess,
    [ACTIVITY_ENTRY_THREAD_FETCH_FAILED]: onFetchThreadError
  }
);
