import { ChangeEvent, ReactNode, useRef } from "react";
import { Input } from "react-aria-components";
import { IconType } from "react-icons";

import {
  IconButton,
  IconSize,
  MdKeyboardDoubleArrowRight,
  MdSearch,
} from "../../components";
import {
  content,
  drawerOpenerButton,
  drawerOpenerIcon,
  searchBarHeight,
  searchContainer,
  searchContent,
  searchIcon,
  searchInput,
  sideBarContainer,
  sideBarMainContainer,
  sideBarTabWidth,
  tabButton,
  tabsContainer,
} from "./Sidebar.css";

export type SidebarProps<T> = {
  contentWidth?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  tabs: T[];
  selectedTab: T;
  setSelectedTab: (tab: T) => void;
  getTabIcon: (tab: T) => IconType;
  children: (tab: T) => ReactNode;
  search?: SearchValueProps;
};

export function Sidebar<T extends string | number>({
  open,
  setOpen,
  tabs,
  selectedTab,
  setSelectedTab,
  getTabIcon,
  children,
  contentWidth = 275,
  search,
}: SidebarProps<T>) {
  return (
    <div
      style={{ width: open ? contentWidth + sideBarTabWidth : sideBarTabWidth }}
      className={sideBarContainer}
    >
      {search && (
        <SidebarSearch
          open={open}
          setOpen={setOpen}
          contentWidth={contentWidth}
          {...search}
        />
      )}
      <div className={sideBarMainContainer}>
        <div className={tabsContainer}>
          {tabs.map((tab) => (
            <IconButton
              key={String(tab)}
              className={
                selectedTab === tab ? tabButton.selected : tabButton.base
              }
              onClick={() => {
                setSelectedTab(tab);
                setOpen(true);
              }}
              iconProps={{
                icon: getTabIcon(tab),
                size: IconSize.XLarge,
              }}
            />
          ))}
          <IconButton
            className={drawerOpenerButton}
            onClick={() => setOpen(!open)}
            iconProps={{
              icon: MdKeyboardDoubleArrowRight,
              size: IconSize.XLarge,
              className: open ? drawerOpenerIcon.open : drawerOpenerIcon.close,
            }}
          />
        </div>
        <div
          style={{ width: contentWidth, top: search ? searchBarHeight : 0 }}
          className={content}
        >
          {children(selectedTab)}
        </div>
      </div>
    </div>
  );
}

type SearchValueProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
};

type SidebarSearchProps = SearchValueProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
  contentWidth: number;
};

function SidebarSearch({
  open,
  setOpen,
  contentWidth,
  value,
  setValue,
  placeholder,
}: SidebarSearchProps) {
  const ref = useRef<HTMLInputElement>(null);

  function onIconClick() {
    if (!open) setOpen(true);
    setTimeout(() => {
      ref.current?.focus();
    }, 200);
  }

  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  return (
    <div className={searchContainer}>
      <IconButton
        className={searchIcon}
        onClick={onIconClick}
        iconProps={{
          icon: MdSearch,
          size: IconSize.XLarge,
        }}
      />
      <div style={{ width: contentWidth, top: 0 }} className={searchContent}>
        <Input
          ref={ref}
          className={searchInput}
          value={value}
          onChange={onSearchChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
