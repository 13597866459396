import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";

export const UserStatusEnum = {
  Active: 1,
  Hibernated: 2,
  Deleted: 3,
} as const;

export type TUserStatusEnum = ConstEnum<typeof UserStatusEnum>;

export type TUserStatus = TEnumOption<TUserStatusEnum>;
