import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const SideDrawerIcon = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M0,2v20h24V2H0z M21.6,19.4h-7.2V4.5h7.2V19.4z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default StyledIcon(SideDrawerIcon);
