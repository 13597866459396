import { IconBase, IconBaseProps } from "react-icons";

export function StExpandCircleOutline(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "-3 -3 36 36" }} {...props}>
      <path d="M15 0C6.75 0 0 6.75 0 15s6.75 15 15 15 15-6.75 15-15S23.25 0 15 0zm0 27C8.4 27 3 21.6 3 15S8.4 3 15 3s12 5.4 12 12-5.4 12-12 12z" />
      <path d="M9.633 18.88L15 13.36l5.367 5.52L22 17.2 15 10l-7 7.2z" />
    </IconBase>
  );
}
