import React from "react";
import styled from "styled-components";
import { DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR } from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { useJobDateRange } from "../../../redux/selectors/jobTimelineSelectors";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import CallBackConsumer from "../../modules/ScrollContexts/CallBackConsumer";
import { useJobId } from "../context/JobIdContext";

const JobTimelineInfoHeader = () => {
  const jobId = useJobId();
  const jobDateRange = useJobDateRange(jobId);

  return (
    <CallBackConsumer Context={JobTimelineScrollContext}>
      {({ goToDate, goToRHSDate }) => (
        <Container>
          {jobDateRange.startDate ? (
            <Date>
              Starts on
              <Clickable onClick={() => goToDate(jobDateRange.startDate)}>
                {formatDate(
                  jobDateRange.startDate,
                  DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                )}
              </Clickable>
            </Date>
          ) : (
            <EmptyDate>No start date</EmptyDate>
          )}

          {jobDateRange.endDate ? (
            <Date>
              Due on
              <Clickable onClick={() => goToRHSDate(jobDateRange.endDate)}>
                {formatDate(
                  jobDateRange.endDate,
                  DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                )}
              </Clickable>
            </Date>
          ) : (
            <EmptyDate>No end date</EmptyDate>
          )}
        </Container>
      )}
    </CallBackConsumer>
  );
};

export default JobTimelineInfoHeader;

const Container = styled.div`
  position: absolute;
  top: -39px;
  right: calc(28px + 60px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Date = styled.div`
  display: flex;
  color: black;
  font-size: 16px;
  margin-right: 20px;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const EmptyDate = styled(Date)`
  color: var(--color-gray-dark);
`;

const Clickable = styled.span`
  color: var(--color-blue-active);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;
