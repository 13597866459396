import { useJobPhaseTotal } from "../../../redux/selectors/jobPhase/selectJobPhaseTotal";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobPhaseId } from "../context/JobPhaseIdContext";
import { TotalLargeItemText } from "../styles";

const PhaseTotal = () => {
  const jobPhaseId = useJobPhaseId();
  const total = useJobPhaseTotal(jobPhaseId);

  return (
    <TotalLargeItemText
      width={ItemColumnWidths.Total + ItemColumnWidths.Rate + 2}
    >
      {total}
    </TotalLargeItemText>
  );
};

export default PhaseTotal;
