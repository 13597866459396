import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import {
  selectPropsCombinerJobIdJobPhaseId,
  selectPropsJobId,
  selectPropsJobPhaseId
} from "../index";
import { selectJobItems } from "../jobItemSelectors";

export const selectJobItemOrderByJobIdPhaseId = createCachedSelector(
  [selectJobItems, selectPropsJobId, selectPropsJobPhaseId],
  (jobItems, jobId, jobPhaseId) =>
    get(
      jobItems.jobItemOrderByJobIdPhaseId,
      `["${jobId}.${jobPhaseId}"]`,
      EMPTY_ARRAY
    )
)(selectPropsCombinerJobIdJobPhaseId);

export const useJobItemOrderByJobIdPhaseId = (jobId, jobPhaseId) =>
  useSelector(state =>
    selectJobItemOrderByJobIdPhaseId(state, { jobId, jobPhaseId })
  );
