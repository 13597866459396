import styled from "styled-components";

const Separator = styled.div`
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
`;

export default Separator;
