import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobListModel } from "st-shared/entities/JobListModel";
import { ScheduleLoggedTime } from "st-shared/entities/ScheduleLoggedTime";
import styled from "styled-components";

import {
  SCHEDULE_BLOCK_SPLIT_CANCEL,
  SCHEDULE_BLOCK_UNSELECT_ALL,
} from "../../lib/constants";
import { getClosestElementFromPoint } from "../../lib/dom";
import WebAppAPI, {
  RECEIVE_SCHEDULE_AVAILABILITY_VIEW,
  RECEIVE_SCHEDULE_FILTER_COUNT,
  RECEIVE_SCHEDULE_FILTER_IS_SEARCHING,
  RECEIVE_SCHEDULE_FILTER_SEARCH_RESULTS,
  RECEIVE_SCHEDULE_FILTER_TEAM_MEMBERS,
  RECEIVE_SCHEDULE_HAS_NO_ACTIVE_JOBS,
  RECEIVE_SCHEDULE_JOB_PROGRESS_MODE,
  RECEIVE_SCHEDULE_LOGGED_TIME_DATA,
  RECEIVE_SCHEDULE_SEARCH_QUERY,
  RECEIVE_SCHEDULE_TODO_VISIBILITY_OPTIONS,
  RECEIVE_SCHEDULE_VIEW_MODE,
  RECEIVE_SCHEDULE_VIEW_PAUSED_LOGGED_TIMES,
} from "../../lib/WebAppAPI";
import createAction from "../../redux/helpers/createAction";
import { selectHasSelectedBlocks } from "../../redux/selectors/scheduleBlockSelectors";
import { selectIsSplittingSomeBlock } from "../../redux/selectors/scheduleSelectors";
import { actionEntitiesReceived } from "../../state/entities/actions";
import { actionJobListModelFilterResultsReceived } from "../../state/entities/jobListModel/actions";
import { EntityNames } from "../../state/entities/types";
import {
  actionScheduleFilterCount,
  actionScheduleFilterIsSearching,
  actionScheduleFilterTeamMembersReceived,
  actionScheduleHasNoActiveJobs,
  actionScheduleSetAvailabilityView,
  actionScheduleSetScheduleTotalsMode,
  actionScheduleSetSearchQuery,
  actionScheduleSetTodoVisibilityOptions,
  actionScheduleSetViewMode,
  actionScheduleSetViewPausedLoggedTimes,
} from "../../state/ui/schedule/actions";
import { useScheduleUiPending } from "../../state/ui/schedule/selectors/selectScheduleUi";
import {
  ScheduleUi,
  ScheduleUiAvailabilityViewsType,
  ScheduleUiTodoVisibilityOptionsType,
  ScheduleUiTotalsModesType,
  ScheduleUiViewModesType,
} from "../../state/ui/schedule/types";
import LoadingSpinner from "../elements/LoadingSpinner";
import ScheduleBodyColumns from "./ScheduleBodyColumns";
import ScheduleEventHandlers from "./ScheduleEventHandlers";
import ScheduleGroupColumns from "./ScheduleGroupColumns";
import { ScheduleHeader } from "./ScheduleHeader/ScheduleHeader";
import { ResizableScheduleGroupColumnCell } from "./ScheduleLayout/ResizableScheduleGroupColumnCell";
import { ScheduleBodyColumnCell } from "./ScheduleLayout/ScheduleBodyColumnCell";
import SingleJobAutoExpander from "./ScheduleLayout/SingleJobAutoExpander";
import ScheduleScrollProvider from "./ScheduleScrollProvider";
import BackgroundConsumer from "./ScheduleScrollProvider/BackgroundConsumer";

function SchedulePage() {
  const pending = useScheduleUiPending();
  const hasSelectedBlocks = useSelector(selectHasSelectedBlocks);
  const isSplittingBlock = useSelector(selectIsSplittingSomeBlock);

  const dispatch = useDispatch();

  function doUnselectAllBlocks() {
    dispatch(createAction(SCHEDULE_BLOCK_UNSELECT_ALL));
    return {};
  }

  function doCancelSplitBlock() {
    dispatch(createAction(SCHEDULE_BLOCK_SPLIT_CANCEL));
    return {};
  }

  function doSetScheduleLoggedTimeData(
    scheduleLoggedTimes: ScheduleLoggedTime[]
  ) {
    dispatch(
      actionEntitiesReceived({
        [EntityNames.ScheduleLoggedTimes]: scheduleLoggedTimes,
      })
    );
    return {};
  }

  function doSetFilterCount(filterCount: number) {
    dispatch(actionScheduleFilterCount(filterCount));
    return {};
  }

  function doSetAvailabilityView(
    availabilityView: ScheduleUiAvailabilityViewsType
  ) {
    dispatch(actionScheduleSetAvailabilityView(availabilityView));
    return {};
  }

  function doSetFilterIsSearching(isSearching: boolean) {
    dispatch(actionScheduleFilterIsSearching(isSearching));
    return {};
  }

  function doSetFilterTeamMembers(userIds: number[]) {
    dispatch(actionScheduleFilterTeamMembersReceived(userIds));
    return {};
  }

  function doSetHasNoActiveJobs(hasNoActiveJobs: boolean) {
    dispatch(actionScheduleHasNoActiveJobs(hasNoActiveJobs));
    return {};
  }

  function doSetTotalsMode(scheduleTotalsMode: ScheduleUiTotalsModesType) {
    dispatch(actionScheduleSetScheduleTotalsMode(scheduleTotalsMode));
    return {};
  }

  function doSetSearchQuery(searchQuery: string) {
    dispatch(actionScheduleSetSearchQuery(searchQuery));
    return {};
  }

  function doSetTodoVisibilityOptions(
    todoVisibilityOptions: ScheduleUiTodoVisibilityOptionsType[]
  ) {
    dispatch(actionScheduleSetTodoVisibilityOptions(todoVisibilityOptions));
    return {};
  }

  function doSetViewMode(newViewMode: ScheduleUiViewModesType) {
    dispatch(actionScheduleSetViewMode(newViewMode));
    return {};
  }

  function doSetViewPausedLoggedTimes(viewPausedLoggedTimes: boolean) {
    dispatch(actionScheduleSetViewPausedLoggedTimes(viewPausedLoggedTimes));
    return {};
  }

  function doSetScheduleFilterSearchResults(scheduleResults: JobListModel[]) {
    dispatch(
      actionJobListModelFilterResultsReceived({
        [EntityNames.JobListModels]: scheduleResults,
      })
    );
    return {};
  }

  useEffect(() => {
    window.document.addEventListener("mousedown", onMouseDown);
    if (window.top && window !== window.top)
      window.top.document.addEventListener("mousedown", onMouseDown);

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_LOGGED_TIME_DATA,
      doSetScheduleLoggedTimeData
    );

    WebAppAPI.registerReceiver(RECEIVE_SCHEDULE_FILTER_COUNT, doSetFilterCount);

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_AVAILABILITY_VIEW,
      doSetAvailabilityView
    );

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_FILTER_IS_SEARCHING,
      doSetFilterIsSearching
    );

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_FILTER_TEAM_MEMBERS,
      doSetFilterTeamMembers
    );

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_HAS_NO_ACTIVE_JOBS,
      doSetHasNoActiveJobs
    );

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_JOB_PROGRESS_MODE,
      doSetTotalsMode
    );

    WebAppAPI.registerReceiver(RECEIVE_SCHEDULE_SEARCH_QUERY, doSetSearchQuery);

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_TODO_VISIBILITY_OPTIONS,
      doSetTodoVisibilityOptions
    );

    WebAppAPI.registerReceiver(RECEIVE_SCHEDULE_VIEW_MODE, doSetViewMode);

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_VIEW_PAUSED_LOGGED_TIMES,
      doSetViewPausedLoggedTimes
    );

    WebAppAPI.registerReceiver(
      RECEIVE_SCHEDULE_FILTER_SEARCH_RESULTS,
      doSetScheduleFilterSearchResults
    );

    return () => {
      window.document.removeEventListener("mousedown", onMouseDown);
      if (window.top && window !== window.top)
        window.top.document.removeEventListener("mousedown", onMouseDown);

      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_LOGGED_TIME_DATA);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_FILTER_COUNT);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_AVAILABILITY_VIEW);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_FILTER_IS_SEARCHING);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_FILTER_TEAM_MEMBERS);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_HAS_NO_ACTIVE_JOBS);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_JOB_PROGRESS_MODE);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_SEARCH_QUERY);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_TODO_VISIBILITY_OPTIONS);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_VIEW_MODE);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_VIEW_PAUSED_LOGGED_TIMES);
      WebAppAPI.unregisterReceiver(RECEIVE_SCHEDULE_FILTER_SEARCH_RESULTS);
    };
  }, []);

  function onMouseDown(e: MouseEvent) {
    if (getClosestElementFromPoint(e, ".scheduleBody")) return;

    if (getClosestElementFromPoint(e, ".scheduleDatePicker")) return;

    if (getClosestElementFromPoint(e, ".MuiMenu-paper")) return;

    if (getClosestElementFromPoint(e, ".MuiPaper-root")) return;

    if (getClosestElementFromPoint(e, ".MenuBackdrop")) return;

    if (hasSelectedBlocks) {
      doUnselectAllBlocks();

      e.preventDefault();
    }

    if (isSplittingBlock) {
      doCancelSplitBlock();

      e.preventDefault();
    }
  }

  return (
    <Wrapper>
      {pending && <LoadingSpinner />}
      {!pending && (
        <ScheduleScrollProvider>
          <ScheduleHeader />
          <ScheduleContainer>
            <ResizableScheduleGroupColumnCell>
              <ScheduleGroupColumns />
            </ResizableScheduleGroupColumnCell>

            <ScheduleBodyColumnCell>
              <BackgroundConsumer />
              <ScheduleEventHandlers>
                <ScheduleBodyColumns />
              </ScheduleEventHandlers>
            </ScheduleBodyColumnCell>
          </ScheduleContainer>
          <SingleJobAutoExpander />
        </ScheduleScrollProvider>
      )}
    </Wrapper>
  );
}

export default SchedulePage;

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const ScheduleContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - ${ScheduleUi.View.HeaderHeight}px);
  z-index: var(--z-index-schedule-body);
`;
