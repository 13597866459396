import { memoize } from "lodash-es";

export function memoizeArgs<T extends (...args: any) => any>(func: T) {
  return memoize(func, (...args) => JSON.stringify([...args]));
}

export function memoizeWithTodayDate<T extends (...args: any) => any>(func: T) {
  return memoize(func, () => String(new Date().getDate()));
}

export function memoizeArgsWithTodayDate<T extends (...args: any) => any>(
  func: T
) {
  return memoize(func, (...args) =>
    JSON.stringify([...args, String(new Date().getDate())])
  );
}
