import type { TOrganisation } from "@streamtimefe/entities";
import { runtimeEnv } from "@streamtimefe/environment";
import {
  bugsnagSetSessionOrganisation,
  bugsnagSetSessionUser,
} from "@streamtimefe/error";
import { getModalBridgeClient } from "@streamtimefe/modal-bridge";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  authenticationStore,
  useIsAuthenticated,
  useIsAuthenticating,
} from "st-shared/stores";

import { baseUrl, history, RoutePages } from "../lib/router";
import { ReportingPage } from "../pages/reporting/ui/ReportingPage";
import createAction from "../redux/helpers/createAction";
import { WEB_APP_API_SAGA_START } from "../redux/sagas/webAppAPISaga";
import { actionEntitiesReceived } from "../state/entities/actions";
import LoadingSpinner from "./elements/LoadingSpinner";
import InvoicePage from "./Invoice/InvoicePage";
import JobPage from "./Job/JobPage";
import JobTimelineExportPage from "./JobTimeline/JobTimelineExportPage";
import JobTimelinePage from "./JobTimeline/JobTimelinePage";
import JobTimelineSummaryPage from "./JobTimeline/JobTimelineSummaryPage";
import PurchaseOrderPage from "./PurchaseOrder/PurchaseOrderPage";
import QuotePage from "./Quote/QuotePage";
import { CustomRouter } from "./router/CustomRouter";
import SchedulePage from "./Schedule/SchedulePage";

function DefaultRoute() {
  const route = runtimeEnv()?.VITE_DEV_DEFAULT_ROUTE;
  if (!route) {
    return null;
  }
  return <Navigate to={route} replace />;
}

export function AppRouter() {
  const isAuthenticating = useIsAuthenticating();
  const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();

  useEffect(() => {
    authenticationStore().actions.bootstrap((response) => {
      bugsnagSetSessionUser(response.authToken.user);
      bugsnagSetSessionOrganisation(
        response.bootstrap.organisation as TOrganisation
      );

      dispatch(actionEntitiesReceived(response.bootstrap));
      dispatch(createAction(WEB_APP_API_SAGA_START));

      if (import.meta.env.DEV) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getModalBridgeClient()?.setAuthBootstrap(response as any);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomRouter basename={baseUrl} history={history}>
      {!isAuthenticated && isAuthenticating && <LoadingSpinner />}
      {isAuthenticated && (
        <Routes>
          <Route path="/" element={<DefaultRoute />} />
          <Route path={RoutePages.Schedule} element={<SchedulePage />} />
          <Route path={RoutePages.Reporting}>
            <Route index element={<ReportingPage />} />
            <Route path=":entityId" element={<ReportingPage />} />
          </Route>
          <Route path={RoutePages.Jobs}>
            <Route path=":entityId">
              <Route index element={<JobPage />} />
              <Route path="quotes" element={<JobPage routeAction="quotes" />} />
              <Route
                path="invoices"
                element={<JobPage routeAction="invoices" />}
              />
              <Route
                path="expenses"
                element={<JobPage routeAction="expenses" />}
              />
              <Route
                path="time_expenses"
                element={<JobPage routeAction="time_expenses" />}
              />
              <Route
                path="repeating_todos"
                element={<JobPage routeAction="repeating_todos" />}
              />
              <Route
                path="timeline"
                element={<JobPage routeAction="timeline" />}
              />
              <Route
                path="activity/:activityEntryId"
                element={<JobPage routeAction="activity" />}
              />
              <Route
                path="activity/post/create"
                element={<JobPage routeAction="post" postMode="create" />}
              />
              <Route
                path="activity/post/:activityEntryId"
                element={<JobPage routeAction="post" postMode="view" />}
              />
              <Route
                path="activity/post/:activityEntryId/edit"
                element={<JobPage routeAction="post" postMode="edit" />}
              />
            </Route>
          </Route>
          <Route path={RoutePages.Quotes}>
            <Route path=":entityId" element={<QuotePage />} />
          </Route>
          <Route path={RoutePages.Invoices}>
            <Route path=":entityId" element={<InvoicePage />} />
          </Route>
          <Route path={RoutePages.PurchaseOrders}>
            <Route path=":entityId" element={<PurchaseOrderPage />} />
          </Route>

          <Route path="job_timeline/:jobId">
            <Route index element={<JobTimelinePage />} />
            <Route path="export" element={<JobTimelineExportPage />} />
            <Route path="summary" element={<JobTimelineSummaryPage />} />
          </Route>
        </Routes>
      )}
    </CustomRouter>
  );
}
