import type { TEntityId, TLabel, TLabelTypeEnum } from "@streamtimefe/entities";
import { createContext, useContext } from "react";

export type TLabelCollectionContext = {
  labelTypeId: TLabelTypeEnum;
  isLoading: boolean;
  labels: TLabel[];
  onAddLabel: (name: string, createMasterLabel: boolean) => void;
  onDeleteLabel: (labelId: TEntityId) => void;
  isReadOnly: boolean;
};

export const LabelCollectionContext = createContext<TLabelCollectionContext>(
  undefined as unknown as TLabelCollectionContext
);

export function useLabelCollection() {
  const context = useContext(LabelCollectionContext);
  if (!context) {
    throw new Error(`Missing LabelCollectionContext`);
  }
  return context;
}
