import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsCurrentFocus = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.currentFocus
);

export const useJobDetailsCurrentFocus = () =>
  useSelector(selectJobDetailsCurrentFocus);

export const selectJobDetailsUiCurrentFocusKey = createSelector(
  [selectJobDetailsCurrentFocus],
  currentFocus => get(currentFocus, `key`, null)
);

export const useJobDetailsCurrentFocusKey = () =>
  useSelector(selectJobDetailsUiCurrentFocusKey);

export const selectJobDetailsUiCurrentFocusJobId = createSelector(
  [selectJobDetailsCurrentFocus],
  currentFocus => get(currentFocus, `jobId`, null)
);

export const useJobDetailsCurrentFocusJobId = () =>
  useSelector(selectJobDetailsUiCurrentFocusJobId);

export const selectJobDetailsUiCurrentFocusJobItemId = createSelector(
  [selectJobDetailsCurrentFocus],
  currentFocus => get(currentFocus, `jobItemId`, null)
);

export const useJobDetailsCurrentFocusJobItemId = () =>
  useSelector(selectJobDetailsUiCurrentFocusJobItemId);

export const selectJobDetailsUiCurrentFocusJobPhaseId = createSelector(
  [selectJobDetailsCurrentFocus],
  currentFocus => get(currentFocus, `jobPhaseId`, null)
);

export const useJobDetailsCurrentFocusJobPhaseId = () =>
  useSelector(selectJobDetailsUiCurrentFocusJobPhaseId);
