import React from "react";
import GenericContent from "./GenericContent";

const DefaultErrorContent = error => {
  switch (error) {
    default:
      return <GenericContent />;
  }
};

export default DefaultErrorContent;
