import { call, put, select } from "redux-saga/effects";
import { updateJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_DRAG_END,
  JOB_ITEM_RESIZE_END,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_PAINT_END
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUi } from "../../selectors/jobItemUiSelectors";

function* saveJobItemUpdate(action) {
  try {
    const { jobItemId, hasChanged } = action.payload;

    if (!hasChanged) return;

    yield put(createAction(JOB_ITEM_SAVING, action.payload));

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const { data } = yield call(updateJobItemAPI, jobItem);

    const { isDragging, isResizing, isDeleting } = yield select(
      selectJobItemUi,
      { id: jobItemId }
    );

    if (isDragging || isResizing || isDeleting) return;

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(JOB_ITEM_SAVED, action.payload));
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_SAVE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchUpdateJobItem() {
  yield takeLatestBy(
    [JOB_ITEM_DRAG_END, JOB_ITEM_RESIZE_END, JOB_ITEM_PAINT_END],
    saveJobItemUpdate,
    action => action.payload.jobItemId
  );
}
