import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

export const Curve = styled(SegmentPart).attrs(
  mapPropsToClassName(["conflict", "snake", "selected", "float"])
)`
  position: absolute;
  width: 17px;
  height: 17px;
  &.snake {
    width: 6px;
    height: 6px;
  }
`;

export const CurveLeftDown = styled(Curve)`
  left: 0;
  bottom: 0;
  border-radius: 4px 0 0 0;
  border-width: 2px 0 0 2px;
`;

export const CurveLeftUp = styled(Curve)`
  top: 0;
  left: 0;
  height: 18px;
  border-radius: 0 0 0 4px;
  border-width: 0 0 2px 2px;
`;

export const CurveRightDown = styled(Curve)`
  right: 0;
  bottom: 0;
  border-radius: 0 4px 0 0;
  border-width: 2px 2px 0 0;
`;

export const CurveRightUp = styled(Curve)`
  top: 0;
  right: 0;
  height: 18px;
  border-radius: 0 0 4px 0;
  border-width: 0 2px 2px 0;
`;
