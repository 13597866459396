import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { purchaseOrderLineItemEntityType } from "../../../lib/entities/purchaseOrderLineItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  accountingPlatformStatusType,
  hasAccountingPlatformStatusExpenseCode,
  isAccountingPlatformStatusActive
} from "../../../lib/ui/accountingPlatform";
import { selectAccountingPlatformStatus } from "../../../redux/selectors/accountingPlatformSelectors";
import {
  selectPurchaseOrderLineItemById,
  selectPurchaseOrderLineItemIsMergeParent,
  selectPurchaseOrderLineItemUiIsCreating,
  selectPurchaseOrderLineItemUiIsMerging
} from "../../../redux/selectors/purchaseOrderLineItemSelectors";
import LoadingOverlay from "../../elements/LoadingOverlay";
import consumeCommercialDocument from "../../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DocumentLineDescription from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineDescription";
import DocumentLineLeftMargin from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineLeftMargin";
import DocumentLineName from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineName";
import DocumentLineQuantity from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineQuantity";
import DocumentLineRate from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineRate";
import DocumentLineRightMargin from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineRightMargin";
import DocumentLineTaxRate from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineTaxRate";
import DocumentLineTotal from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineTotal";
import PurchaseOrderLineItemAccountCode from "./PurchaseOrderLineItemAccountCode";
import PurchaseOrderLineItemMarkup from "./PurchaseOrderLineItemMarkup";
import PurchaseOrderLineItemTotalSell from "./PurchaseOrderLineItemTotalSell";
import PurchaseOrderLineSellRate from "./PurchaseOrderLineSellRate";

const mapContext = ({ branchId }) => ({
  branchId
});

const mapState = (state, props) => ({
  purchaseOrderLineItem: selectPurchaseOrderLineItemById(state, props),
  isCreating: selectPurchaseOrderLineItemUiIsCreating(state, props),
  isChecked: selectPurchaseOrderLineItemUiIsMerging(state, props),
  isMergeParent: selectPurchaseOrderLineItemIsMergeParent(state, props),
  accountingPlatformStatus: selectAccountingPlatformStatus(state, props)
});

const PurchaseOrderLineItem = ({
  id,
  purchaseOrderLineItem,
  isCreating,
  isMergeMode,
  isSortMode,
  isChecked,
  isMergeParent,
  accountingPlatformStatus,
  displayLineItems,
  displayLineItemDescriptions,
  displayTaxNameOnLineItems,
  displayQuantities,
  displayUnitRates,
  displaySubtotals,
  displayCurrencySymbols,
  displayLineItemsInclusiveOfTax
}) => (
  <Wrapper className={isSortMode ? "sorting" : ""}>
    {isMergeMode && (
      <DocumentLineLeftMargin
        id={id}
        isChecked={isChecked}
        isMergeParent={isMergeParent}
      />
    )}
    <Table>
      <tbody>
        <tr>
          <td>
            <DocumentLineName
              documentLineItem={purchaseOrderLineItem}
              isHiddenField={!displayLineItems}
              isDisabled={isSortMode}
              isCreating={isCreating}
            />
          </td>
          {isAccountingPlatformStatusActive(accountingPlatformStatus) &&
            hasAccountingPlatformStatusExpenseCode(
              accountingPlatformStatus
            ) && (
              <td>
                <PurchaseOrderLineItemAccountCode
                  documentLineItem={purchaseOrderLineItem}
                  isDisabled={isSortMode}
                />
              </td>
            )}
          <td>
            <DocumentLineTaxRate
              documentLineItem={purchaseOrderLineItem}
              isHiddenField={!displayLineItems || !displayTaxNameOnLineItems}
              isDisabled={isSortMode}
              accountType={ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES}
            />
          </td>
          <td>
            <DocumentLineQuantity
              documentLineItem={purchaseOrderLineItem}
              isHiddenField={!displayLineItems || !displayQuantities}
              isDisabled={isSortMode}
            />
          </td>
          <td>
            <DocumentLineRate
              documentLineItem={purchaseOrderLineItem}
              isHiddenField={!displayLineItems || !displayUnitRates}
              isDisabled={isSortMode}
              displayCurrencySymbols={displayCurrencySymbols}
            />
          </td>
          <td className="total">
            <DocumentLineTotal
              documentLineItem={purchaseOrderLineItem}
              isHiddenField={!displayLineItems || !displaySubtotals}
              isDisabled={isSortMode}
              displayLineItemsInclusiveOfTax={displayLineItemsInclusiveOfTax}
              displayCurrencySymbols={displayCurrencySymbols}
            />
          </td>
          <td>
            <PurchaseOrderLineItemMarkup
              documentLineItem={purchaseOrderLineItem}
              isDisabled={isSortMode}
            />
          </td>
          <td>
            <PurchaseOrderLineSellRate
              documentLineItem={purchaseOrderLineItem}
              isDisabled={isSortMode}
            />
          </td>
          <td className="total">
            <PurchaseOrderLineItemTotalSell
              documentLineItem={purchaseOrderLineItem}
              isDisabled={isSortMode}
            />
          </td>
        </tr>
        {displayLineItems && displayLineItemDescriptions && (
          <tr>
            <td
              colSpan={
                isAccountingPlatformStatusActive(accountingPlatformStatus)
                  ? 3
                  : 2
              }
            >
              <DocumentLineDescription
                isDisabled={isSortMode}
                documentLineItem={purchaseOrderLineItem}
                placeholder="Add a description..."
              />
            </td>
            <td colSpan={5} />
          </tr>
        )}
      </tbody>
    </Table>
    {!isCreating && !isSortMode && (
      <DocumentLineRightMargin documentLineItem={purchaseOrderLineItem} />
    )}
    {isCreating && <LoadingOverlay />}
  </Wrapper>
);

PurchaseOrderLineItem.propTypes = {
  id: entityIdType.isRequired,
  purchaseOrderLineItem: purchaseOrderLineItemEntityType.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isMergeMode: PropTypes.bool.isRequired,
  isSortMode: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isMergeParent: PropTypes.bool.isRequired,
  accountingPlatformStatus: accountingPlatformStatusType.isRequired,
  displayLineItems: PropTypes.bool.isRequired,
  displayLineItemDescriptions: PropTypes.bool.isRequired,
  displayTaxNameOnLineItems: PropTypes.bool.isRequired,
  displayQuantities: PropTypes.bool.isRequired,
  displayUnitRates: PropTypes.bool.isRequired,
  displaySubtotals: PropTypes.bool.isRequired,
  displayCurrencySymbols: PropTypes.bool.isRequired,
  displayLineItemsInclusiveOfTax: PropTypes.bool.isRequired
};

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(PurchaseOrderLineItem)
);

const Wrapper = styled.div.attrs({
  className: "PurchaseOrderLineItem"
})`
  position: relative;
  width: 100%;
  &.sorting {
    background-color: white;
    border: 1px dashed var(--color-gray-medium);
    padding-top: 5px;
  }
  &:hover {
    .DocumentLineDeleteButton {
      opacity: 1;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  line-height: 0;
  td {
    min-width: 70px;
    height: 26px;
    vertical-align: top;
    border: 0 solid transparent;
    background-clip: padding-box;
    &:first-child {
      width: 100%;
    }
    &.total {
      min-width: 100px;
    }
  }
  tr > td + td {
    border-left-width: 2px;
  }

  tr + tr > td {
    border-top-width: 2px;
  }
`;
