import type { DataRef } from "@dnd-kit/core";
import type { ConstEnum } from "@streamtimefe/types";

export const SortTypes = {
  Segment: "segment",
  Folder: "folder",
  FolderSegment: "folderSegment",
  UncategorisedSegment: "uncategorisedSegment",
} as const;

export type TSortTypes = ConstEnum<typeof SortTypes>;

export const DropTypes = {
  SegmentsDroppable: "segmentsDroppable",
} as const;

export type TDropTypes = ConstEnum<typeof DropTypes>;

export type SortData = DataRef<
  | {
      type:
        | typeof SortTypes.Segment
        | typeof SortTypes.Folder
        | typeof SortTypes.UncategorisedSegment
        | typeof DropTypes.SegmentsDroppable;
    }
  | { type: typeof SortTypes.FolderSegment; folderId: string }
>;
