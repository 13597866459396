import { sendApplyModelData } from "../WebAppAPI";
import { ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM } from "../constants";
import { getOrderId } from "../ui/commercialDocumentLineItems";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const savePurchaseOrderLineItemAPI = purchaseOrderLineItem =>
  API.put(
    `/purchase_order_line_items/${purchaseOrderLineItem.id}`,
    purchaseOrderLineItem
  )
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM))
    .then(({ data }) => data);

export const createPurchaseOrderLineItemAPI = purchaseOrderLineItem =>
  API.post(
    `/purchase_orders/${purchaseOrderLineItem.purchaseOrderId}/purchase_order_line_items`,
    {
      ...purchaseOrderLineItem,
      orderId: getOrderId(purchaseOrderLineItem) - 0.5,
      id: null
    }
  )
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM))
    .then(({ data }) => data);

export const deletePurchaseOrderLineItemAPI = id =>
  API.delete(`/purchase_order_line_items/${id}`)
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM))
    .then(({ data }) => data);

export const mergePurchaseOrderLineItemsAPI = (
  purchaseOrderLineItem,
  otherIds
) =>
  API.put(
    `/purchase_order_line_items/${purchaseOrderLineItem.id}/merge`,
    purchaseOrderLineItem,
    {
      other_line_item_ids: otherIds.join(",")
    }
  )
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM))
    .then(({ data }) => data);

export const undoMergePurchaseOrderLineItemsAPI = (
  purchaseOrderLineItem,
  otherIds
) =>
  API.post(
    `/purchase_order_line_items/${purchaseOrderLineItem.id}/undo_merge`,
    purchaseOrderLineItem,
    {
      other_line_item_ids: otherIds.join(",")
    }
  )
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM))
    .then(({ data }) => data);

export const sortPurchaseOrderLineItemsAPI = (
  purchaseOrderId,
  purchaseOrderLineItemIds
) => {
  return API.put(
    `/purchase_orders/${purchaseOrderId}/purchase_order_line_items/reorder`,
    null,
    {
      ids: purchaseOrderLineItemIds.join(",")
    }
  )
    .then(({ data }) => data)
    .then(createModelSetParser())
    .then(({ data }) => data);
};
