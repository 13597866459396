import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_OBJECT } from "../../../../lib/constants";
import { selectPropsInvoiceId } from "../../index";

export const selectStateInvoiceUi = state => state.ui.invoice;

export const selectStateInvoicesUi = state => state.ui.invoices;

export const selectInvoicesUi = createCachedSelector(
  [selectStateInvoicesUi, selectPropsInvoiceId],
  (invoicesUi, invoiceId) => get(invoicesUi, invoiceId, EMPTY_OBJECT)
)(selectPropsInvoiceId);

export const useInvoicesUi = invoiceId =>
  useSelector(state => selectInvoicesUi(state, { invoiceId }));
