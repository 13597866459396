import React, { forwardRef } from "react";

const withForwardedRef = WrappedComponent => {
  const handle = (props, ref) => (
    <WrappedComponent {...props} forwardedRef={ref} />
  );

  const name = WrappedComponent.displayName || WrappedComponent.name;
  handle.displayName = `withForwardedRef(${name})`;

  return forwardRef(handle);
};

export default withForwardedRef;
