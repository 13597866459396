import type {
  TAuthenticationBootstrapResponse,
  TUserPreference,
} from "@streamtimefe/entities";
import { UserPreference } from "@streamtimefe/entities";
import { runtimeEnv } from "@streamtimefe/environment";
import { webapi } from "@streamtimefe/state/api";
import { debounce } from "remeda";

import type { TStoreGetter, TStoreSetter } from "../core";
import { type TUserPreferenceStore } from "./UserPreferenceStore";
import type { TUserPreferenceKeyEnum } from "./UserPreferenceStore.keys";

export type TUserPreferenceActions = ReturnType<
  typeof createUserPreferenceActions
>;

export function createUserPreferenceActions(
  set: TStoreSetter<TUserPreferenceStore>,
  get: TStoreGetter<TUserPreferenceStore>
) {
  function authSync(response: TAuthenticationBootstrapResponse) {
    set((store) => {
      response.bootstrap.userPreferences.map((userPreference) => {
        store.entities[userPreference.key] = userPreference;
      });
      if (
        response.authToken.isAdminUser &&
        runtimeEnv()?.VITE_BUILD_ENV === "production"
      ) {
        store.shouldSave = false;
      }
    });
  }

  function bulkUpdate(userPreferences: TUserPreference[]) {
    set((store) => {
      userPreferences.map((userPreference) => {
        store.entities[userPreference.key] = userPreference;
      });
    });
  }

  function getUserPreference(key: TUserPreferenceKeyEnum) {
    return get().entities[key];
  }

  function setUserPreference(
    key: TUserPreferenceKeyEnum,
    value: string | null
  ) {
    const previous = getUserPreference(key);

    // if the same value dont update
    if (previous && previous.value === value) {
      return;
    }

    // update store
    set((store) => {
      if (store.entities[key]) {
        store.entities[key].value = value;
      } else {
        store.entities[key] = UserPreference.create(key, value);
      }
    });

    // save to backend
    const userPreference = getUserPreference(key);
    if (get().shouldSave && userPreference) {
      let saveDebounce = get().saveDebounce[key];

      if (!saveDebounce) {
        saveDebounce = createSaveDebounce();
        set((store) => {
          store.saveDebounce[key] = saveDebounce;
        });
      }

      saveDebounce.call(key, userPreference);
    }
  }

  function createSaveDebounce() {
    return debounce(
      async (key: TUserPreferenceKeyEnum, userPreference: TUserPreference) => {
        set((store) => {
          delete store.saveDebounce[key];
        });

        try {
          await webapi.userPreferences.put({ userPreference });
        } catch (_: unknown) {
          /* empty */
        }
      },
      { waitMs: 500 }
    );
  }

  return {
    authSync,
    bulkUpdate,
    getUserPreference,
    setUserPreference,
    createSaveDebounce,
  };
}
