import styled from "styled-components";

const Spacer = styled.div<{ h?: number; w?: number }>`
  flex-shrink: 0;
  flex-grow: 0;
  ${(props) => props.h && `height: ${props.h}px;`}
  ${(props) => props.w && `width: ${props.w}px;`}
`;

export default Spacer;
