import { useRef, useState } from "react";
import { MdDelete, MdEdit, MdMoreVert } from "react-icons/md";

import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverMenu,
} from "../../../components";
import { useSavedSegmentSidebarActions } from "../state/savedSegmentSidebarStore";
import { TSegmentFolder } from "../state/savedSegmentSidebarUserPreference";
import { actionMenuCss, rowHoverIconCss } from "./styles.css";

type Props = { folder: TSegmentFolder; onRename: () => void };

export function FolderActionMenu({ folder, onRename }: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function openMenu() {
    setAnchorEl(ref.current);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        ref={ref}
        iconProps={{ icon: MdMoreVert }}
        onClick={openMenu}
        className={rowHoverIconCss}
      />
      <PopoverMenu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        paperRootClassName={actionMenuCss.root}
      >
        <RenameAction closeMenu={closeMenu} onRename={onRename} />
        <DeleteAction folder={folder} closeMenu={closeMenu} />
      </PopoverMenu>
    </>
  );
}

type DeleteActionProps = {
  folder: TSegmentFolder;
  closeMenu: () => void;
};

function DeleteAction({ folder, closeMenu }: DeleteActionProps) {
  const sidebarActions = useSavedSegmentSidebarActions();

  function onDelete() {
    closeMenu();
    sidebarActions.removeFolder(folder.id);
  }

  return (
    <MenuItem className={actionMenuCss.item} onClick={onDelete}>
      <ListItemIcon className={actionMenuCss.listItemIcon}>
        <Icon className={actionMenuCss.icon} icon={MdDelete} size={18} />
      </ListItemIcon>
      <ListItemText className={actionMenuCss.text}>Delete</ListItemText>
    </MenuItem>
  );
}

type RenameActionProps = {
  onRename: Props["onRename"];
  closeMenu: () => void;
};

function RenameAction({ onRename, closeMenu }: RenameActionProps) {
  function onClick() {
    closeMenu();
    onRename();
  }

  return (
    <MenuItem className={actionMenuCss.item} onClick={onClick}>
      <ListItemIcon className={actionMenuCss.listItemIcon}>
        <Icon className={actionMenuCss.icon} icon={MdEdit} size={18} />
      </ListItemIcon>
      <ListItemText className={actionMenuCss.text}>Rename</ListItemText>
    </MenuItem>
  );
}
