import { get, sumBy, values } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPermissionsSnapshot } from "st-shared/stores";

import {
  EMPTY_OBJECT,
  ENTITY_NAME_JOB_PHASES,
  JOB_TIMELINE_CONTENT_MIN_HEIGHT,
  JOB_TIMELINE_CONTENT_MIN_WIDTH,
  JOB_TIMELINE_DAY_WIDTH,
  JOB_TIMELINE_ITEM_ROW_HEIGHT,
  JOB_TIMELINE_ZOOM_LEVEL_DEFAULT,
  JOB_TIMELINE_ZOOM_LEVELS,
  JOB_TIMELINE_ZOOM_RATIOS,
} from "../../lib/constants";
import {
  addDays,
  differenceInCalendarDays,
  getEndOfWeek,
  getStartOfWeek,
  getTodayDate,
  maxDate,
  minDate,
} from "../../lib/dates";
import { isArchived, isDeleted } from "../../lib/entities/jobEntity";
import { selectPropsId, selectPropsJobId } from "./index";
import { selectInvoiceDateRangeByJobId } from "./invoiceSelectors";
import {
  selectIsJobItemDependancySelectedById,
  selectJobItemDependancySegmentsByJobIdJobItemId,
  selectJobItemDependancySegmentsByJobIdJobPhaseId,
} from "./jobItemDependancySelectors";
import {
  selectJobItemDateRangeByJobId,
  selectJobItemOrderByJobIdPhaseId,
  selectJobPhaseIdsByJobId,
} from "./jobItemSelectors";
import { selectJobMilestoneDateRangeByJobId } from "./jobMilestoneSelectors";
import { selectJobPhaseHasDependanciesById } from "./jobPhaseSelectors";
import { selectJobByJobId } from "./jobSelectors";
import { selectIsJobItemsLockedByJobId } from "./jobUiSelectors";

export const selectPhaseRowHeightsByJobId = (state, props) => {
  const jobId = selectPropsJobId(state, props);
  const jobPhaseIds = selectJobPhaseIdsByJobId(state, props);

  const phaseRowHeights = {};

  jobPhaseIds.forEach((id) => {
    let height = id > 0 ? JOB_TIMELINE_ITEM_ROW_HEIGHT : 0;

    const forceExpand = selectJobPhaseHasDependanciesById(state, { id });
    const { isCollapsed } = selectJobTimelinePhaseUi(state, { id });

    if (forceExpand || !isCollapsed) {
      const jobItemsIds = selectJobItemOrderByJobIdPhaseId(state, {
        jobId,
        id,
      });
      height += jobItemsIds.length * JOB_TIMELINE_ITEM_ROW_HEIGHT;
    }

    phaseRowHeights[id] = {
      height,
    };
  });

  return phaseRowHeights;
};

export const usePhaseRowHeightsByJobId = (jobId) =>
  useSelector((state) => selectPhaseRowHeightsByJobId(state, { jobId }));

export const selectTotalPhaseRowsHeight = createSelector(
  selectPhaseRowHeightsByJobId,
  (phaseRowHeights) => sumBy(values(phaseRowHeights), "height")
);

export const selectJobDateRange = createSelector(
  selectJobItemDateRangeByJobId,
  selectJobMilestoneDateRangeByJobId,
  (jobItemDateRange, jobMilestoneDateRange) => ({
    startDate: minDate(
      [jobItemDateRange.startDate, jobMilestoneDateRange.startDate].filter(
        Boolean
      )
    ),
    endDate: maxDate(
      [jobItemDateRange.endDate, jobMilestoneDateRange.endDate].filter(Boolean)
    ),
  })
);

export const useJobDateRange = (jobId) =>
  useSelector((state) => selectJobDateRange(state, { jobId }));

export const selectJobTimelineDateRange = createSelector(
  selectJobItemDateRangeByJobId,
  selectJobMilestoneDateRangeByJobId,
  selectInvoiceDateRangeByJobId,
  (jobItemDateRange, jobMilestoneDateRange, invoiceDateRange) => ({
    startDate: minDate([
      jobItemDateRange.startDate,
      jobMilestoneDateRange.startDate,
      invoiceDateRange.startDate,
    ]),
    endDate: maxDate([
      jobItemDateRange.endDate,
      jobMilestoneDateRange.endDate,
      invoiceDateRange.endDate,
    ]),
  })
);

export const selectJobTimelineUi = (state) => state.ui.jobTimeline;

export const selectJobTimelineZoomLevelIndex = createSelector(
  selectJobTimelineUi,
  ({ zoomLevelIndex }) => zoomLevelIndex
);

export const selectJobTimelineZoomLevel = createSelector(
  selectJobTimelineZoomLevelIndex,
  (zoomLevelIndex) => JOB_TIMELINE_ZOOM_LEVELS[zoomLevelIndex]
);

export const selectJobTimelineDayWidth = createSelector(
  selectJobTimelineZoomLevelIndex,
  (zoomLevelIndex) =>
    JOB_TIMELINE_ZOOM_RATIOS[zoomLevelIndex] * JOB_TIMELINE_DAY_WIDTH
);

export const selectJobPhases = (state) =>
  state.entities[ENTITY_NAME_JOB_PHASES];

export const selectJobPhaseById = createSelector(
  selectJobPhases,
  selectPropsId,
  (jobPhases, jobPhaseId) => get(jobPhases, `byId[${jobPhaseId}]`)
);

export const selectJobTimelineExportDateRange = createSelector(
  selectJobTimelineDateRange,
  ({ startDate, endDate }) => ({
    startDate: getStartOfWeek(startDate || getTodayDate()),
    endDate: getEndOfWeek(addDays(endDate || getTodayDate(), 7)),
  })
);

export const selectJobTimelineExportDays = createSelector(
  selectJobTimelineExportDateRange,
  ({ startDate, endDate }) => differenceInCalendarDays(endDate, startDate) + 1
);

export const selectJobTimelineHeight = createSelector(
  selectTotalPhaseRowsHeight,
  (height) => Math.max(height, JOB_TIMELINE_CONTENT_MIN_HEIGHT)
);

export const selectJobTimelineExportWidth = createSelector(
  selectJobTimelineExportDays,
  (days) =>
    Math.max(
      days *
        JOB_TIMELINE_ZOOM_RATIOS[JOB_TIMELINE_ZOOM_LEVEL_DEFAULT] *
        JOB_TIMELINE_DAY_WIDTH,
      JOB_TIMELINE_CONTENT_MIN_WIDTH
    )
);

export const selectJobTimelinePhasesUi = (state) => state.ui.jobTimelinePhases;

export const selectJobTimelinePhaseUi = createSelector(
  selectJobTimelinePhasesUi,
  selectPropsId,
  (jobTimelinePhases, id) => get(jobTimelinePhases, id, EMPTY_OBJECT)
);

const mapIsJobItemDependancySelected = (state) => (jobItemDependancy) => ({
  ...jobItemDependancy,
  isSelected: selectIsJobItemDependancySelectedById(state, {
    id: jobItemDependancy.jobItemDependancyId,
  }),
});

export const selectVisibleJobItemDependancySegmentsByJobIdJobItemId = (
  state,
  props
) =>
  selectJobItemDependancySegmentsByJobIdJobItemId(state, props).map(
    mapIsJobItemDependancySelected(state)
  );

export const selectVisibleJobItemDependancySegmentsByJobIdJobPhaseId = (
  state,
  props
) =>
  selectJobItemDependancySegmentsByJobIdJobPhaseId(state, props).map(
    mapIsJobItemDependancySelected(state)
  );

export const selectIsReadOnlyByJobId = createSelector(
  selectJobByJobId,
  selectIsJobItemsLockedByJobId,
  (job, isJobItemsLocked) => {
    const { canEditJobs } = getPermissionsSnapshot();
    return (
      (job && (isArchived(job) || isDeleted(job))) ||
      !canEditJobs ||
      isJobItemsLocked
    );
  }
);

export const useIsReadOnlyByJobId = (jobId) =>
  useSelector((state) => selectIsReadOnlyByJobId(state, { jobId }));
