import { combineReducers } from "redux";

import {
  ENTITY_NAME_ACCOUNTING_PLATFORMS,
  ENTITY_NAME_ACTIVITY_ENTRIES,
  ENTITY_NAME_ADDRESSES,
  ENTITY_NAME_COMPANIES,
  ENTITY_NAME_CONTACTS,
  ENTITY_NAME_CURRENCIES,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
  ENTITY_NAME_INVOICE_PAYMENTS,
  ENTITY_NAME_INVOICES,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  ENTITY_NAME_JOB_ITEM_SUB_ITEMS,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_MILESTONES,
  ENTITY_NAME_JOB_PHASES,
  ENTITY_NAME_JOBS,
  ENTITY_NAME_LABELS,
  ENTITY_NAME_LOGGED_EXPENSES,
  ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  ENTITY_NAME_PURCHASE_ORDERS,
  ENTITY_NAME_QUOTE_LINE_ITEMS,
  ENTITY_NAME_QUOTES,
  ENTITY_NAME_RATE_CARDS,
  ENTITY_NAME_REPEATING_LOGGED_TIMES,
  ENTITY_NAME_ROLE_RATES,
  ENTITY_NAME_ROLES,
  ENTITY_NAME_TAX_RATES,
  ENTITY_NAME_TEAMS,
  ENTITY_NAME_USERS,
} from "../../../lib/constants";
import { entityStoreListenerReducers } from "../../../state/entities/entityStoreListenerReducer";
import itemRatesReducer from "../../../state/entities/itemRate/reducer";
import jobItemRolesReducer from "../../../state/entities/jobItemRole/reducer";
import jobListModelsReducer from "../../../state/entities/jobListModel/reducer";
import masterJobItemsReducer from "../../../state/entities/masterJobItem/reducer";
import masterLoggedExpensesReducer from "../../../state/entities/masterLoggedExpense/reducer";
import organisationReducer from "../../../state/entities/organisation/reducer";
import { EntityNames } from "../../../state/entities/types";
import userPreferencesReducer from "../../../state/entities/userPreferences/reducer";
import accountingPlatforms from "./accountingPlatforms";
import activityEntries from "./activityEntries";
import addresses from "./addresses";
import companies from "./companies";
import contacts from "./contacts";
import currencies from "./currencies";
import invoiceLineItems from "./invoiceLineItems";
import invoicePayments from "./invoicePayments";
import invoices from "./invoices";
import jobItemDependancies from "./jobItemDependancies";
import jobItems from "./jobItems";
import jobItemSubItems from "./jobItemSubItems";
import jobItemUsers from "./jobItemUsers";
import jobMilestones from "./jobMilestones";
import jobPhases from "./jobPhases";
import jobs from "./jobs";
import labels from "./labels";
import loggedExpenses from "./loggedExpenses";
import organisationAccountingPreferences from "./organisationAccountingPreferences";
import purchaseOrderLineItems from "./purchaseOrderLineItems";
import purchaseOrders from "./purchaseOrders";
import quoteLineItems from "./quoteLineItems";
import quotes from "./quotes";
import rateCards from "./rateCards";
import repeatingLoggedTimes from "./repeatingLoggedTimes";
import roleRates from "./roleRates";
import roles from "./roles";
import scheduleLoggedTimes from "./scheduleLoggedTimes";
import taxRates from "./taxRates";
import teams from "./teams";
import users from "./users";

export default combineReducers({
  [ENTITY_NAME_ACCOUNTING_PLATFORMS]: accountingPlatforms,
  [ENTITY_NAME_ACTIVITY_ENTRIES]: activityEntries,
  [ENTITY_NAME_ADDRESSES]: addresses,
  [ENTITY_NAME_COMPANIES]: companies,
  [ENTITY_NAME_CONTACTS]: contacts,
  [ENTITY_NAME_CURRENCIES]: currencies,
  [ENTITY_NAME_INVOICE_LINE_ITEMS]: invoiceLineItems,
  [ENTITY_NAME_INVOICES]: invoices,
  [ENTITY_NAME_INVOICE_PAYMENTS]: invoicePayments,
  [ENTITY_NAME_JOBS]: jobs,
  [ENTITY_NAME_JOB_ITEMS]: jobItems,
  [ENTITY_NAME_JOB_ITEM_DEPENDANCIES]: jobItemDependancies,
  [ENTITY_NAME_JOB_ITEM_USERS]: jobItemUsers,
  [ENTITY_NAME_JOB_ITEM_SUB_ITEMS]: jobItemSubItems,
  [ENTITY_NAME_JOB_PHASES]: jobPhases,
  [ENTITY_NAME_JOB_MILESTONES]: jobMilestones,
  [ENTITY_NAME_LOGGED_EXPENSES]: loggedExpenses,
  [ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES]:
    organisationAccountingPreferences,
  [ENTITY_NAME_PURCHASE_ORDERS]: purchaseOrders,
  [ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS]: purchaseOrderLineItems,
  [ENTITY_NAME_QUOTES]: quotes,
  [ENTITY_NAME_QUOTE_LINE_ITEMS]: quoteLineItems,
  [ENTITY_NAME_RATE_CARDS]: rateCards,
  [ENTITY_NAME_REPEATING_LOGGED_TIMES]: repeatingLoggedTimes,
  [ENTITY_NAME_ROLE_RATES]: roleRates,
  [ENTITY_NAME_ROLES]: roles,
  [EntityNames.ScheduleLoggedTimes]: scheduleLoggedTimes,
  [ENTITY_NAME_TAX_RATES]: taxRates,
  [ENTITY_NAME_TEAMS]: teams,
  [ENTITY_NAME_USERS]: users,
  [ENTITY_NAME_LABELS]: labels,
  [EntityNames.MasterJobItems]: masterJobItemsReducer,
  [EntityNames.MasterLoggedExpenses]: masterLoggedExpensesReducer,
  [EntityNames.ItemRates]: itemRatesReducer,
  [EntityNames.JobItemRoles]: jobItemRolesReducer,
  [EntityNames.UserPreferences]: userPreferencesReducer,
  [EntityNames.JobListModels]: jobListModelsReducer,
  [EntityNames.Organisation]: organisationReducer,
  entityStoreListener: entityStoreListenerReducers,
});
