import clsx from "clsx";
import { useMemo } from "react";
import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MdDelete,
  MdEdit,
  MdTimeline,
  MdVisibility,
  MdVisibilityOff,
  MenuItem,
  PopoverMenu,
  StSortArrow,
} from "st-shared/components";
import { ReportingColumn } from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";
import { rotate180 } from "st-shared/theme";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumnsAllowedTimeTable,
  useReportingSavedSegmentSortColumn,
} from "../../state/stores/savedSegmentSelectors";
import { menuListCss } from "../styles.css";
import * as styles from "./ColumnActionMenu.css";

type Props = {
  anchorEl: Element | null;
  onClose: () => void;
  column: ReportingColumn;
  onEdit: () => void;
};

export function ColumnActionMenu({ anchorEl, onClose, column, onEdit }: Props) {
  const sortColumn = useReportingSavedSegmentSortColumn();

  const canGoToTimeSeries = useCanGoToTimeSeries(column.id);

  function onDelete() {
    onClose();

    reportingStore().savedSegment.actions.deleteColumn(column.id);
  }

  function onToggleVisibility() {
    onClose();

    reportingStore().savedSegment.actions.setColumnHidden(
      column.id,
      !column.hidden
    );
  }

  function onSortAscending() {
    onClose();

    reportingStore().savedSegment.actions.setSortColumn({
      id: column.id,
      order: "asc",
    });
  }

  function onSortDescending() {
    onClose();

    reportingStore().savedSegment.actions.setSortColumn({
      id: column.id,
      order: "desc",
    });
  }

  function onViewTimeSeries() {
    onClose();

    reportingStore().savedSegment.actions.setSearchType(
      ReportingSearchType.TimeSeries
    );

    reportingStore().savedSegment.actions.setSelectedTimeId(column.id);
  }

  return (
    <PopoverMenu
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      paperRootClassName={styles.actionMenu}
    >
      <MenuItem className={menuListCss.menuItem} onClick={onEdit}>
        <ListItemIcon className={menuListCss.listItemIcon}>
          <Icon
            className={menuListCss.listItemIcon_icon}
            icon={MdEdit}
            size={18}
          />
        </ListItemIcon>
        <ListItemText className={menuListCss.listItemText}>
          Edit column
        </ListItemText>
      </MenuItem>
      <MenuItem className={menuListCss.menuItem} onClick={onToggleVisibility}>
        <ListItemIcon className={menuListCss.listItemIcon}>
          <Icon
            className={menuListCss.listItemIcon_icon}
            icon={column.hidden ? MdVisibility : MdVisibilityOff}
            size={18}
          />
        </ListItemIcon>
        <ListItemText className={menuListCss.listItemText}>
          {column.hidden ? "Show column" : "Hide column"}
        </ListItemText>
      </MenuItem>
      {!(sortColumn.id === column.id && sortColumn.order === "asc") && (
        <MenuItem className={menuListCss.menuItem} onClick={onSortAscending}>
          <ListItemIcon className={menuListCss.listItemIcon}>
            <Icon
              className={clsx(menuListCss.listItemIcon_icon, rotate180)}
              icon={StSortArrow}
              size={18}
            />
          </ListItemIcon>
          <ListItemText className={menuListCss.listItemText}>
            Sort by largest
          </ListItemText>
        </MenuItem>
      )}
      {!(sortColumn.id === column.id && sortColumn.order === "desc") && (
        <MenuItem className={menuListCss.menuItem} onClick={onSortDescending}>
          <ListItemIcon className={menuListCss.listItemIcon}>
            <Icon
              className={menuListCss.listItemIcon_icon}
              icon={StSortArrow}
              size={18}
            />
          </ListItemIcon>
          <ListItemText className={menuListCss.listItemText}>
            Sort by smallest
          </ListItemText>
        </MenuItem>
      )}

      {canGoToTimeSeries && (
        <MenuItem className={menuListCss.menuItem} onClick={onViewTimeSeries}>
          <IconButton
            className={(menuListCss.viewTimeSeries, menuListCss.listItemIcon)}
            iconProps={{
              icon: MdTimeline,
              size: 20,
            }}
          />

          <ListItemText className={menuListCss.listItemText}>
            View time series
          </ListItemText>
        </MenuItem>
      )}

      <MenuItem className={menuListCss.menuItem} onClick={onDelete}>
        <ListItemIcon className={menuListCss.listItemIcon}>
          <Icon
            className={menuListCss.listItemIcon_icon}
            icon={MdDelete}
            size={18}
          />
        </ListItemIcon>
        <ListItemText className={menuListCss.listItemText}>Delete</ListItemText>
      </MenuItem>
    </PopoverMenu>
  );
}

function useCanGoToTimeSeries(columnId: string) {
  const tableColumns = useReportingSavedSegmentColumnsAllowedTimeTable();

  return useMemo(
    () =>
      tableColumns.findIndex((tableColumn) => tableColumn.id === columnId) > -1,
    [tableColumns, columnId]
  );
}
