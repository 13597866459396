import styled from "styled-components";

const SegmentPart = styled.div`
  border-color: var(--dependancy-fixed-border-color);
  border-style: solid;
  border-width: 0;
  &.conflict {
    border-color: var(--dependancy-conflict-border-color);
    z-index: 2;
  }
  &.selected {
    border-color: var(--dependancy-active-border-color);
    z-index: 3;
  }
  &.float {
    border-color: var(--dependancy-float-border-color);
  }
`;

export default SegmentPart;
