import { intersection } from "lodash-es";
import { useSelector } from "react-redux";

import { selectJobItemIdsByJobPhaseId } from "../../../../redux/selectors/jobItem/selectJobItemIdsByJobPhaseId";
import { AppState } from "../../../AppState";
import { selectRoleIdsByJobItemId } from "./selectRoleIdsByJobItemId";

export function selectRoleIdsInAllJobItemsByPhaseId(
  state: AppState,
  { jobPhaseId }: { jobPhaseId: number }
) {
  const jobItemIds: number[] = selectJobItemIdsByJobPhaseId(state, {
    jobPhaseId,
  });

  const roleIdsByJobItemId = jobItemIds.map((jobItemId) =>
    selectRoleIdsByJobItemId(state, { jobItemId })
  );

  return intersection(...roleIdsByJobItemId);
}

export function useRoleIdsInAllJobItemsByPhaseId(jobPhaseId: number) {
  return useSelector((state: AppState) =>
    selectRoleIdsInAllJobItemsByPhaseId(state, { jobPhaseId })
  );
}
