// deprecated

import type { Option } from "./Option";

export const JobStatuses = {
  Scheduled: 1,
  Complete: 2,
  Deleted: 3,
  Archived: 4,
  Planning: 5,
} as const;

export type JobStatusesType = (typeof JobStatuses)[keyof typeof JobStatuses];

export type Type = JobStatus;
export interface JobStatus extends Option {
  id: JobStatusesType;
}

export function isScheduled(entity: JobStatus) {
  return entity.id === JobStatuses.Scheduled;
}

export function isComplete(entity: JobStatus) {
  return entity.id === JobStatuses.Complete;
}

export function isDeleted(entity: JobStatus) {
  return entity.id === JobStatuses.Deleted;
}

export function isArchived(entity: JobStatus) {
  return entity.id === JobStatuses.Archived;
}

export function isPlanning(entity: JobStatus) {
  return entity.id === JobStatuses.Planning;
}
