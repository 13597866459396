import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { entityIdType } from "../types/entityTypes";
import { accountingPlatformEntityType } from "./accountingPlatformEntity";
import { taxRateEntityType } from "./taxRateEntity";

export const branchShape = {
  id: entityIdType.isRequired,
  active: PropTypes.bool,
  address: PropTypes.string,
  branchLogoURL: PropTypes.string,
  defaultTaxRate: taxRateEntityType,
  externalName: PropTypes.string,
  name: PropTypes.string,
  paperSizeId: entityIdType,
  quoteTerms: PropTypes.string,
  taxNumber: PropTypes.string,
  accountingPlatform: accountingPlatformEntityType,
};

export const branchEntityType = PropTypes.shape(branchShape);

export const isActive = ({ active }) => active;

export const getName = ({ name }) => name;

export const getExternalName = ({ externalName }) => externalName;

export const getAddress = ({ address }) => address;

export const getTaxNumber = ({ taxNumber }) => taxNumber;

export const getDefaultTaxRate = ({ defaultTaxRate }) => defaultTaxRate;

export const getBranchLogoUrl = ({ branchLogoURL }) => branchLogoURL;

export const hasAccountingPlatform = ({ accountingPlatform }) =>
  !!accountingPlatform;

export const getAccountingPlatform = ({ accountingPlatform }) =>
  accountingPlatform;

export const getActiveAccountingPlatformRevenueAccountCode = (branch) => {
  if (hasAccountingPlatform(branch))
    return getAccountingPlatform(branch).revenueAccountCode;
  return null;
};

export const getActiveAccountingPlatformExpenseAccountCode = (branch) => {
  if (hasAccountingPlatform(branch))
    return getAccountingPlatform(branch).expenseAccountCode;
  return null;
};

export const getSearchString = (entity) => asString(getName(entity));
