import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const ReassignIcon = ({ ...props }) => (
  <SvgIcon viewBox="-2 -2 24 20" {...props}>
    <path d="M16.968 8.174l-.806.8 1.56 1.547h-3.784c-1.827 0-2.351-.23-3.303-2.492-1.3-3.201-2.927-3.201-4.361-3.201H1v1.131h5.274c1.33 0 2.29 0 3.306 2.5 1.08 2.567 1.935 3.194 4.358 3.194h3.783l-1.56 1.547.807.8 2.937-2.913-2.937-2.913z" />
    <path d="M10.895 6.172c.885-1.833 1.355-1.834 3.109-1.832h3.706L16.1 5.806l.84.765L20 3.786 16.94 1l-.84.765 1.64 1.493h-3.642c-2.137 0-3.038.17-4.02 1.97l-.03.054.758 1.075.09-.185zM8.388 9.124c-.57.64-1.064.628-1.748.61-.113-.002-.23-.005-.351-.005H1v1.266h5.289c.093 0 .183.001.27.003l.248.002c.666 0 1.247-.08 2.121-.87L9 10.066 8.498 9l-.11.124z" />
  </SvgIcon>
);

ReassignIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

ReassignIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(ReassignIcon);
