import { isRouteErrorResponse } from "react-router";

import * as styles from "./styles.css";

type Props = {
  error: unknown;
};

export function ErrorFallback({ error }: Props) {
  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : JSON.stringify(error);
  const stack = error instanceof Error ? error.stack : null;

  if (import.meta.env.PROD) {
    return <div>Something went wrong... please refresh</div>;
  }

  return (
    <div className={styles.container}>
      <h2>Unexpected Application Error!</h2>
      <h3>{message}</h3>
      {stack && <pre className={styles.stack}>{stack}</pre>}
    </div>
  );
}
