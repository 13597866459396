import { all, put, select, takeEvery } from "redux-saga/effects";
import {
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVING,
  JOB_ITEM_SAVED,
  JOB_ITEM_LOCK_BY_JOB,
  JOB_ITEM_UNLOCK_BY_JOB
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import {
  selectJobItemDependancyIdsByChildJobItemId,
  selectJobItemDependancyIdsByParentJobItemId
} from "../../selectors/jobItemDependancySelectors";
import { selectJobItemById } from "../../selectors/jobItemSelectors";

function* getShouldLock(action) {
  const { jobItemId } = action.payload;

  const childJobItemDependancies = yield select(
    selectJobItemDependancyIdsByParentJobItemId,
    { jobItemId }
  );

  const parentJobItemDependancies = yield select(
    selectJobItemDependancyIdsByChildJobItemId,
    { jobItemId }
  );

  return childJobItemDependancies.length || parentJobItemDependancies.length;
}

function* isSaving(action) {
  const { jobItemId } = action.payload;

  const shouldLock = yield getShouldLock(action);

  if (shouldLock) {
    const { jobId } = yield select(selectJobItemById, { id: jobItemId });

    yield put(createAction(JOB_ITEM_LOCK_BY_JOB, { jobId }));
  }
}

function* hasSaved(action) {
  const { jobItemId } = action.payload;

  const shouldLock = yield getShouldLock(action);

  if (shouldLock) {
    const { jobId } = yield select(selectJobItemById, { id: jobItemId });

    yield put(createAction(JOB_ITEM_UNLOCK_BY_JOB, { jobId }));
  }
}

function* hasErrored(action) {
  const { jobItemId } = action.payload;

  const shouldLock = yield getShouldLock(action);

  if (shouldLock) {
    const { jobId } = yield select(selectJobItemById, { id: jobItemId });

    yield put(createAction(JOB_ITEM_UNLOCK_BY_JOB, { jobId }));
  }
}

function* watchSaving() {
  yield takeEvery([JOB_ITEM_SAVING], isSaving);
}

function* watchSaved() {
  yield takeEvery([JOB_ITEM_SAVED], hasSaved);
}

function* watchError() {
  yield takeEvery([JOB_ITEM_SAVE_ERROR], hasErrored);
}

export default function* watchLockJobItemsByJob() {
  yield all([watchSaving(), watchSaved(), watchError()]);
}
