export const IconSize = {
  mini: 12,
  Small: 13,
  Medium: 16,
  Large: 18,
  XLarge: 24,
  XXLarge: 28,
  XXXLarge: 32,
  OffSizing14: 14,
  OffSizing20: 20,
  OffSizing22: 22,
  OffSizing40: 40,
} as const;

export type TIconSize = (typeof IconSize)[keyof typeof IconSize];

export const defaultIconSize: TIconSize = IconSize.Large;
