import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { ReactNode } from "react";
import { useEffect, useMemo, useRef } from "react";

import { useSavedSegmentsEntityIdsByType } from "../../../stores";
import type { TSavedSegmentSidebarStore } from "../state/savedSegmentSidebarStore";
import { createSavedSegmentSidebarStore } from "../state/savedSegmentSidebarStore";
import { useSavedSegmentSidebarUserPreference } from "../state/savedSegmentSidebarUserPreference";
import type { SavedSegmentSidebarProps } from "./SavedSegmentSidebarContext";
import { SavedSegmentSidebarContext } from "./SavedSegmentSidebarContext";

type Props = {
  children: ReactNode;
} & SavedSegmentSidebarProps;

export function SavedSegmentSidebarContextProvider({
  children,
  ...props
}: Props) {
  const useStore = useCreateStore(props.savedSegmentType);

  useCheckSavedSegments(props.savedSegmentType, useStore);
  useLoadUserPreference(props.savedSegmentType, useStore);

  const providerValue = useMemo(() => {
    return { ...props, useStore };
  }, [props]);

  return (
    <SavedSegmentSidebarContext.Provider value={providerValue}>
      {children}
    </SavedSegmentSidebarContext.Provider>
  );
}

function useCreateStore(
  savedSegmentType: TSavedSegmentTypeEnum
): TSavedSegmentSidebarStore {
  const useStoreRef = useRef<TSavedSegmentSidebarStore>(
    undefined as unknown as TSavedSegmentSidebarStore
  );

  if (!useStoreRef.current) {
    useStoreRef.current = createSavedSegmentSidebarStore(savedSegmentType);
  }

  return useStoreRef.current;
}

function useCheckSavedSegments(
  savedSegmentType: TSavedSegmentTypeEnum,
  useStore: TSavedSegmentSidebarStore
) {
  const segmentIds = useSavedSegmentsEntityIdsByType(savedSegmentType);

  useEffect(() => {
    useStore.getState().actions.recheckSavedSegments();
  }, [JSON.stringify(segmentIds)]);
}

function useLoadUserPreference(
  savedSegmentType: TSavedSegmentTypeEnum,
  useStore: TSavedSegmentSidebarStore
) {
  const userPreference = useSavedSegmentSidebarUserPreference(savedSegmentType);

  useEffect(() => {
    useStore.getState().actions.loadUserPreference(userPreference);
  }, [userPreference]);
}
