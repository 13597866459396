import styled from "styled-components";
import { StyledAutocomplete } from "../../elements/Autocomplete/styles";
import Input from "../../elements/Input";

export const TooltipHeading = styled.div`
  color: white;
  font-weight: var(--font-weight-bold);
`;

export const TooltipTitle = styled.td`
  color: white !important;
  font-weight: var(--font-weight-bold);
  ${props => props.$padded && "padding-bottom: 12px !important;"}
`;

export const TooltipValue = styled.td`
  font-weight: var(--font-weight-bold);
  padding-left: 10px;
  ${props => props.$padded && "padding-bottom: 12px !important;"}
`;

export const JumbotronTooltipTable = styled.table`
  margin: -15px;

  tr:first-of-type td {
    padding-top: 15px;
  }

  tr:last-of-type td {
    padding-bottom: 15px;
  }

  td {
    padding: 0 15px;
    color: var(--tooltip-title-color);
  }

  td.legend-display-cell {
    padding-left: 0;
  }

  tr.divider td {
    padding-top: 7px;
    border-bottom: 1px solid var(--color-gray-dark);
  }

  tr.divider + tr td {
    padding-top: 7px;
  }

  tr.nonBillable td {
    font-weight: var(--font-weight-bold);
  }

  tr.dividerBlank td {
    padding-top: 7px;
  }

  tr.dividerBlank + tr td {
    padding-top: 7px;
  }
`;

export const LegendDisplay = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  margin-left: 5px;
  background-color: ${props => props.$color};
`;

export const InlineText = styled.div`
  ${props => props.$warning && "color: var(--color-red-dark);"}
  ${props => props.$fitContent && "width: fit-content;"}
`;

export const InlineTextSpan = styled.span`
  vertical-align: text-bottom;
  ${props => props.$warning && "color: var(--color-red-dark);"}
  ${props => props.$light && "color: var(--color-gray-dark);"}
  ${props => props.$bold && "font-weight:  var(--font-weight-bold);"}
  white-space: nowrap;
`;

const StyledInput = styled(Input)`
  color: black;
  background-color: transparent;
  input {
    background-color: transparent;
    &:hover {
      background-color: white;
    }
    &:focus {
      background-color: white;
    }
  }
  &.Mui-disabled {
    color: black;
    background-color: transparent;
    input {
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const BudgetInput = styled(StyledInput)`
  font-size: var(--font-size-even-larger);
  font-weight: var(--font-weight-bold);
  color: var(--color-charcoal);
  width: 300px;
  height: 30px;
  margin: -2px 0 -5px 0;
  padding: 0;
  input {
    padding: 0 4px 0 0;
    height: 100%;
  }
`;

export const SettingsInput = styled(StyledInput)`
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  min-width: 250px;
  padding: 0;
  height: 30px;
  input {
    padding: 5px 0;
  }
`;

export const CustomStyledAutocomplete = styled(StyledAutocomplete)`
  min-width: 250px;

  input {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-normal);
    &:first-child {
      padding: 6px 0 5px 0 !important;
    }
  }

  .MuiAutocomplete-inputRoot {
    height: 30px;
    background-color: transparent;
    &:hover {
      background-color: white;
    }
    &.Mui-focused {
      background-color: white;
    }
    &.Mui-disabled {
      color: black;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const SettingsField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
  min-width: 250px;
  color: ${props => (props.$placeholder ? "var(--color-gray-dark)" : "black")};
  ${props =>
    !props.$disabled && "cursor: pointer; &:hover { background: white; }"}
`;
