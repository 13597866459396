import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { JOB_SET_COMPANY } from "../../../../lib/constants";
import {
  getJobCompany,
  getJobCompanyName
} from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import CompaniesSearchMenu from "../../../modules/Company/CompaniesSearchMenu";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";
import { SettingsField } from "../styles";

const JobCompany = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const company = getJobCompany(job);
  const companyName = getJobCompanyName(job);

  const ref = useRef();
  const [companyAnchorEl, setCompanyAnchorEl] = useState(null);

  const editCompany = () => {
    if (!isJobEditable) return;
    setCompanyAnchorEl(ref.current);
  };

  const closeCompanyMenu = () => {
    setCompanyAnchorEl(null);
  };

  const handlePickCompany = companyId => {
    if (!isJobEditable) return;
    dispatch(createAction(JOB_SET_COMPANY, { jobId, companyId }));
    closeCompanyMenu();
  };

  const createNewCompany = () => {
    if (!isJobEditable) return;
    // TODO: Create new company
    closeCompanyMenu();
  };

  return (
    <JumbotronSettingsField title="COMPANY">
      <SettingsField
        ref={ref}
        $placeholder={company === null}
        $disabled={!isJobEditable}
        onClick={editCompany}
      >
        {isJobEditable && (companyName || "Add a company")}
        {!isJobEditable && companyName}
      </SettingsField>
      <CompaniesSearchMenu
        anchorEl={companyAnchorEl}
        onPickOption={handlePickCompany}
        onClose={closeCompanyMenu}
        onCreate={createNewCompany}
        excludeIds={company && [company.id]}
      />
    </JumbotronSettingsField>
  );
};

export default JobCompany;
