import { feToWebViewSchedule } from "../../../lib/WebAppAPI/fePages/genericWeb";
import { IconButton } from "../../elements/Button";
import ScheduleIcon from "../../elements/Icons/custom/ScheduleIcon";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import { useJobId } from "../context/JobIdContext";
import { CenteredDiv } from "../styles";

const ViewScheduleButton = () => {
  const jobId = useJobId();
  const viewSchedule = () => {
    feToWebViewSchedule([jobId]);
  };

  return (
    <Tooltip title={<CenteredDiv>View schedule</CenteredDiv>} placement="top">
      <IconButton onClick={viewSchedule}>
        <ScheduleIcon size={ICON_SIZE.LARGE} />
      </IconButton>
    </Tooltip>
  );
};

export default ViewScheduleButton;
