import React from "react";
import { DATE_FORMAT_DATE_MONTH_YEAR } from "../../../../../lib/constants";
import { formatDate } from "../../../../../lib/dates";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataEstimatedStartDate,
  getMetaDataJobItemName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobItemStartDateModified = ({ activityEntry }) => {
  const estimatedStartDate = getMetaDataEstimatedStartDate(activityEntry);
  const jobItemName = getMetaDataJobItemName(activityEntry);
  return (
    <SystemMessage userId={getCreatedByUserId(activityEntry)}>
      {estimatedStartDate ? (
        <>
          {" set the start date for "}
          <b>{jobItemName}</b>
          {" to "}
          <b>{formatDate(estimatedStartDate, DATE_FORMAT_DATE_MONTH_YEAR)}</b>
        </>
      ) : (
        <>
          {" cleared the start date for "}
          <b>{jobItemName}</b>
        </>
      )}
    </SystemMessage>
  );
};

JobItemStartDateModified.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemStartDateModified;
