import MuiIcon from "@material-ui/core/Icon";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";
import CircleIcon from "./CircleIcon";

const Icon = StyledIcon(MuiIcon);

const CurrencyCircleIcon = ({ color, fill, ...props }) => (
  <Icon {...props}>
    <CircleIcon color={color} fill={fill} className="currency">
      <Content>$</Content>
    </CircleIcon>
  </Icon>
);

CurrencyCircleIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  fill: PropTypes.string
};

CurrencyCircleIcon.defaultProps = {
  size: ICON_SIZE.X_LARGE,
  color: "transparent",
  fill: "black"
};

const Content = styled.span`
  font-size: 45%;
  font-weight: bold;
  padding-top: 2px;
  line-height: 1;
`;

export default styled(CurrencyCircleIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
