import { getRateCardId } from "../../../../lib/entities/jobEntity";
import { getName } from "../../../../lib/entities/rateCardEntity";
import { jobPageChangeJobRateCard } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useRateCard } from "../../../../redux/selectors/rateCard";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";

const JobRateCard = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const rateCard = useRateCard(getRateCardId(job));

  return (
    <JumbotronSettingsField
      title="RATE CARD"
      onClick={() => jobPageChangeJobRateCard(job)}
      disabled={!isJobEditable}
    >
      <div>{getName(rateCard)}</div>
    </JumbotronSettingsField>
  );
};

export default JobRateCard;
