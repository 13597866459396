import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateMasterJobItems } from "./selectStateMasterJobItems";

export const selectMasterJobItemOptions = createSelector(
  [selectStateMasterJobItems],
  (masterJobItems) => masterJobItems.options
);

export function useMasterJobItemOptions() {
  return useSelector(selectMasterJobItemOptions);
}
