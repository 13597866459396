import { useEffect, useRef } from "react";
import styled from "styled-components";

import {
  JOB_TIMELINE_DATE_HEADER_HEIGHT,
  JOB_TIMELINE_GROUP_COLUMN_WIDTH,
} from "../../../lib/constants";
import { useIsReadOnlyByJobId } from "../../../redux/selectors/jobTimelineSelectors";
import Flex from "../../elements/Flex";
import Spacer from "../../elements/Spacer";
import JobTimelineBackground from "../../JobTimeline/JobTimelineBackground";
import JobTimelineBodyColumnCell from "../../JobTimeline/JobTimelineBodyColumnCell";
import JobTimelineDatesHeader from "../../JobTimeline/JobTimelineDatesHeader";
import JobTimelineEventHandlers from "../../JobTimeline/JobTimelineEventHandlers";
import JobTimelineGroupColumnCell from "../../JobTimeline/JobTimelineGroupColumnCell";
import JobTimelineNavigation from "../../JobTimeline/JobTimelineHeader/JobTimelineNavigation";
import JobTimelinePhaseGroupRow from "../../JobTimeline/JobTimelinePhaseGroupRow";
import JobTimelinePhaseRow from "../../JobTimeline/JobTimelinePhaseRow";
import JobTimelineScrollProvider, {
  DEFAULT_OFFSET,
} from "../../JobTimeline/JobTimelineScrollProvider";
import { useJobId } from "../context/JobIdContext";
import CustomPhaseRowsConsumer from "./CustomPhaseRowsConsumer";
import JobTimelineInfoHeader from "./JobTimelineInfoHeader";

const JobTimeline = () => {
  const jobId = useJobId();
  const readOnly = useIsReadOnlyByJobId(jobId);

  const fakeHeaderRef = useRef();
  const headerRef = useRef();

  const checkHeaderPosition = () => {
    if (!fakeHeaderRef.current || !headerRef.current) return;

    const clientRect = fakeHeaderRef.current.getBoundingClientRect();

    if (clientRect.top < 0) {
      headerRef.current.classList.add("fixed");
    } else {
      headerRef.current.classList.remove("fixed");
    }
  };

  useEffect(() => {
    checkHeaderPosition();
    window.addEventListener("scroll", checkHeaderPosition);
    return () => window.removeEventListener("scroll", checkHeaderPosition);
  }, []);

  return (
    <Container>
      <JobTimelineScrollProvider
        jobId={jobId}
        offset={{
          ...DEFAULT_OFFSET,
          left: JOB_TIMELINE_GROUP_COLUMN_WIDTH + 60,
          right: 60,
        }}
      >
        <JobTimelineInfoHeader />
        <FakeHeader ref={fakeHeaderRef} />
        <Header ref={headerRef}>
          <JobTimelineGroupColumnCell
            style={{
              display: "flex",
              borderRight: "var(--border-thick-bright)",
            }}
          >
            <JobTimelineNavigation />
          </JobTimelineGroupColumnCell>
          <JobTimelineBodyColumnCell>
            <JobTimelineDatesHeader jobId={jobId} readOnly={readOnly} />
          </JobTimelineBodyColumnCell>
        </Header>
        <TimelineContainer>
          <PhasesColumn>
            <CustomPhaseRowsConsumer jobId={jobId}>
              {({ jobPhaseId, top, height }) => (
                <JobTimelinePhaseGroupRow
                  key={jobPhaseId}
                  id={jobPhaseId}
                  jobId={jobId}
                  top={top}
                  height={height}
                  readOnly={readOnly}
                />
              )}
            </CustomPhaseRowsConsumer>
            <Spacer h={200} />
          </PhasesColumn>
          <StyledJobTimelineBodyColumnCell>
            <JobTimelineBackground jobId={jobId} bottomPadding={200} />
            <JobTimelineEventHandlers readOnly={readOnly} jobId={jobId}>
              <CustomPhaseRowsConsumer jobId={jobId}>
                {({ jobPhaseId, top, height }) => (
                  <JobTimelinePhaseRow
                    key={jobPhaseId}
                    id={jobPhaseId}
                    jobId={jobId}
                    top={top}
                    height={height}
                    readOnly={readOnly}
                  />
                )}
              </CustomPhaseRowsConsumer>
            </JobTimelineEventHandlers>
          </StyledJobTimelineBodyColumnCell>
        </TimelineContainer>
      </JobTimelineScrollProvider>
    </Container>
  );
};

export default JobTimeline;

const Container = styled.div`
  position: relative;
  margin-bottom: 30px;

  .scrollContextContainer {
    position: relative;
  }
`;

export const FakeHeader = styled.div`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_DATE_HEADER_HEIGHT}px;
`;

export const Header = styled.div`
  width: 100%;
  height: ${JOB_TIMELINE_DATE_HEADER_HEIGHT}px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gray-light);
  z-index: 1000;

  &.fixed {
    position: fixed;
    margin: 0 60px 0 60px;
    width: calc(100% - 120px);
  }
`;

const TimelineContainer = styled(Flex)`
  position: relative;
  width: 100%;
  height: auto;
`;

const PhasesColumn = styled.div`
  position: relative;
  width: ${JOB_TIMELINE_GROUP_COLUMN_WIDTH}px;
  background-color: white;
  border-right: var(--border-thick-bright);
  height: auto;

  .JobTimelineRowCell {
    position: unset;
  }
`;

const StyledJobTimelineBodyColumnCell = styled(JobTimelineBodyColumnCell)`
  height: auto;
  overflow: hidden;
`;
