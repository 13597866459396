import { usePermissions } from "st-shared/stores";

import { isArchived, isDeleted } from "../../../../lib/entities/jobEntity";
import { useJob } from "../../job";

export function useIsJobEditable(jobId) {
  const { canEditJobs } = usePermissions();
  const job = useJob(jobId);
  return Boolean(job && !isArchived(job) && !isDeleted(job) && canEditJobs);
}
