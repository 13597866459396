import type { TEntityDescriptorEnum } from "@streamtimefe/entities";
import { EntityDescriptorEnum } from "@streamtimefe/entities";
import type { IconType } from "react-icons";
import { MdCreditCard, MdPersonOutline, MdSchedule } from "react-icons/md";

import { StInvoice, StJob, StQuote } from "../components";

type EntityDescriptorProperty = {
  name: string;
  icon: IconType;
};

export const EntityDescriptorProperties: Record<
  TEntityDescriptorEnum,
  EntityDescriptorProperty
> = {
  [EntityDescriptorEnum.User]: {
    name: "Availability",
    icon: MdPersonOutline,
  },
  [EntityDescriptorEnum.Job]: {
    name: "Job Totals",
    icon: StJob,
  },
  [EntityDescriptorEnum.Quote]: {
    name: "Quotes",
    icon: StQuote,
  },
  [EntityDescriptorEnum.LoggedExpense]: {
    name: "Expenses",
    icon: MdCreditCard,
  },
  [EntityDescriptorEnum.Invoice]: {
    name: "Invoices",
    icon: StInvoice,
  },
  [EntityDescriptorEnum.LoggedTime]: {
    name: "To Do’s",
    icon: MdSchedule,
  },
  [EntityDescriptorEnum.JobItemUserRolePlannedTime]: {
    name: "Planned",
    icon: MdSchedule,
  },
  [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: {
    name: "Unscheduled",
    icon: MdSchedule,
  },
} as const;
