import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { costingMethodObj } from "st-shared/entities";

import {
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
} from "../../../lib/entities/jobItemEntity";
import { selectPropsJobItemId } from "../index";
import { selectJobItem } from "./index";
import { selectCalculatedSellRate } from "./selectCalculatedSellRate";

export const selectCalculatedPlannedCost = createCachedSelector(
  [selectJobItem, selectCalculatedSellRate],
  (jobItem, calculatedSellRate) => {
    if (costingMethodObj(jobItem.costingMethod).isBasedByValue()) {
      return getTotalPlannedMoney(jobItem);
    }

    if (calculatedSellRate !== null) {
      return numeral(calculatedSellRate)
        .multiply(numeral(getTotalPlannedMinutes(jobItem)).divide(60).value())
        .value();
    }
    return "";
  }
)(selectPropsJobItemId);
