import { RefObject, useState } from "react";

import {
  addYears,
  isAfter,
  isBefore,
  isDateEqual,
  subtractYears,
  toDate,
} from "../../lib";
import { TDateRange } from "../../types";
import { AriaPopover } from "../aria";
import { DayPicker } from "./DayPicker";
import {
  dayRangePickerContainer,
  dayRangePickersSeparator,
  dayRangePickerTitle,
} from "./DayRangePicker.css";

interface Props {
  isOpen: boolean;
  triggerRef: RefObject<Element>;
  onClose: () => void;
  dateRange: TDateRange;
  setDateRange: (dateRange: TDateRange) => void;
  restrictToYear?: boolean;
}

export function DayRangePicker({
  isOpen,
  triggerRef,
  onClose,
  dateRange,
  setDateRange,
  restrictToYear = false,
}: Props) {
  const [newStartDate, setNewStartDate] = useState<string | null>(null);
  const [newEndDate, setNewEndDate] = useState<string | null>(null);

  const startValue = newStartDate || dateRange.startDate;
  const endValue = newEndDate || dateRange.endDate;

  const startValueDate = new Date(startValue);
  const endValueDate = new Date(endValue);

  function onOpenChange(open: boolean) {
    if (!open) {
      onPopoverClose();
    }
  }

  function onPopoverClose() {
    onClose();

    if (
      !isDateEqual(startValue, dateRange.startDate) ||
      !isDateEqual(endValue, dateRange.endDate)
    ) {
      setDateRange({ startDate: startValue, endDate: endValue });
    }

    setNewStartDate(null);
    setNewEndDate(null);
  }

  function onChangeStart(date: Date | undefined) {
    const newDate = toDate(date);
    setNewStartDate(newDate);
    if (isAfter(newDate, endValue)) {
      setNewEndDate(newDate);
    } else if (restrictToYear && isAfter(endValue, addYears(newDate, 1))) {
      setNewEndDate(addYears(newDate, 1));
    }
  }

  function onChangeEnd(date: Date | undefined) {
    const newDate = toDate(date);
    setNewEndDate(newDate);
    if (restrictToYear && isBefore(startValue, subtractYears(newDate, 1))) {
      setNewStartDate(subtractYears(newDate, 1));
    }
  }

  return (
    <AriaPopover
      isOpen={isOpen}
      triggerRef={triggerRef}
      onOpenChange={onOpenChange}
    >
      <div className={dayRangePickerContainer}>
        <div>
          <div className={dayRangePickerTitle}>Start</div>
          <DayPicker
            required={true}
            selected={startValueDate}
            onSelect={onChangeStart}
          />
        </div>
        <div className={dayRangePickersSeparator} />
        <div>
          <div className={dayRangePickerTitle}>End</div>
          <DayPicker
            required={true}
            selected={endValueDate}
            onSelect={onChangeEnd}
            disabled={{ before: startValueDate }}
            fromDate={startValueDate}
          />
        </div>
      </div>
    </AriaPopover>
  );
}
