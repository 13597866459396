import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  JOB_ITEM_DEPENDANCIES_SELECTED,
  JOB_ITEM_DEPENDANCY_DELETE
} from "../../../lib/constants";
import {
  getTargetDependancyHandle,
  getTargetDependancyHandleType,
  getTargetJobItemBarId
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import JobItemDependanciesContextMenu from "./JobItemDependanciesContextMenu";

const mapState = null;

const mapDispatch = dispatch => ({
  doSetSelectedDependancy: payload =>
    dispatch(createAction(JOB_ITEM_DEPENDANCIES_SELECTED, payload)),
  doRemoveDependancy: jobItemDependancy =>
    dispatch(createAction(JOB_ITEM_DEPENDANCY_DELETE, jobItemDependancy))
});

export default WrappedComponent => {
  class DependancyContextMenuHandlers extends React.PureComponent {
    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      doSetSelectedDependancy: PropTypes.func.isRequired,
      doRemoveDependancy: PropTypes.func.isRequired,
      onContextMenu: PropTypes.func
    };

    static defaultProps = {
      onContextMenu: null
    };

    state = {
      jobItemId: null,
      dependancyHandleType: null,
      anchorPosition: null
    };

    get handlers() {
      return {
        onContextMenu: this.onContextMenu
      };
    }

    onContextMenu = e => {
      const { readOnly, onContextMenu, doSetSelectedDependancy } = this.props;
      const jobItemId = getTargetJobItemBarId(e);
      const isDependancyEvent = jobItemId && !!getTargetDependancyHandle(e);

      if (!readOnly && jobItemId && isDependancyEvent) {
        const dependancyHandleType =
          jobItemId && getTargetDependancyHandleType(e);

        this.setState({
          jobItemId,
          dependancyHandleType,
          anchorPosition: {
            left: e.clientX + 2,
            top: e.clientY + 2
          }
        });

        doSetSelectedDependancy({ jobItemId, dependancyHandleType });

        e.preventDefault();
      }

      return onContextMenu && onContextMenu(e);
    };

    closeContextMenu = () => {
      const { doSetSelectedDependancy } = this.props;

      doSetSelectedDependancy({ jobItemId: null });

      this.setState({
        jobItemId: null,
        dependancyHandleType: null,
        anchorPosition: null
      });
    };

    removeDependancy = jobItemDependancy => {
      const { doRemoveDependancy } = this.props;

      this.closeContextMenu();

      doRemoveDependancy(jobItemDependancy);
    };

    render() {
      const { props, handlers } = this;
      const { jobItemId, dependancyHandleType, anchorPosition } = this.state;
      const isContextMenuOpen = Boolean(jobItemId);

      if (props.readOnly) return <WrappedComponent {...props} />;

      return (
        <>
          <WrappedComponent {...{ ...props, ...handlers, isContextMenuOpen }} />
          <JobItemDependanciesContextMenu
            jobItemId={jobItemId}
            dependancyHandleType={dependancyHandleType}
            anchorPosition={anchorPosition}
            onClose={this.closeContextMenu}
            onRemoveDependancy={this.removeDependancy}
          />
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(DependancyContextMenuHandlers);
};
