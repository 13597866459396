import { images } from "@streamtimefe/assets";

import * as styles from "./ColumnsEmptyState.css";

type Props = {
  variant: "columns" | "datasets";
};

export function ColumnsEmptyState({ variant }: Props) {
  return (
    <div className={styles.container}>
      <div>
        You currently have no{" "}
        {variant === "datasets" ? "filter sets" : "columns"}. Try adding a
        column.
      </div>
      <img src={images.artistGirl} className={styles.image} alt="logo" />
    </div>
  );
}
