import { produce } from "immer";

import {
  JOB_ITEM_DELETE_ERROR,
  JOB_ITEM_DELETED,
  JOB_ITEM_DELETING,
  JOB_ITEM_DEPENDANCY_CREATE_END,
  JOB_ITEM_DEPENDANCY_CREATE_START,
  JOB_ITEM_DRAG_CANCEL,
  JOB_ITEM_DRAG_END,
  JOB_ITEM_DRAG_START,
  JOB_ITEM_DUPLICATE_ENDED,
  JOB_ITEM_DUPLICATE_STARTED,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP,
  JOB_ITEM_RESIZE_CANCEL,
  JOB_ITEM_RESIZE_END,
  JOB_ITEM_RESIZE_START,
  JOB_ITEM_REVERT,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
  JOB_ITEM_SET_EXPANDED,
  JOB_ITEM_SET_PREVIOUS_JOB_ITEM,
  JOB_ITEM_SET_SAVING,
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_USER_EDIT,
  JOB_PHASE_LOADING_ENDED,
  JOB_PHASE_LOADING_STARTED,
} from "../../../lib/constants";
import { JOB_ITEM_ROLE_EDIT } from "../../../state/entities/jobItemRole/actions";
import createReducer from "../../helpers/createReducer";

const dragStart = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isDragging: true },
});

const dragEnd = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isDragging: false },
});

const resizeStart = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isResizing: true },
});

const resizeEnd = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isResizing: false },
});

const onSaveRequest = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: true, error: null },
});

const onSaveSuccess = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: false },
});

const onSaveError = (state, { payload: { jobItemId, error } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: false, error },
});

const onDeleteRequest = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isDeleting: true, error: null },
});

const onDeleteSuccess = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isDeleting: false },
});

const onDeleteError = (state, { payload: { jobItemId, error } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isDeleting: false, error },
});

const onCreateDependencyStart = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isCreatingDependancy: true },
});

const onCreateDependencyEnd = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isCreatingDependancy: false },
});

const onEdit = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: {
    ...state[jobItemId],
    isEditing: true,
  },
});

function onEditJobItemRole(state, action) {
  return produce(state, (draft) => {
    draft[action.jobItemId].isEditing = true;
  });
}

const onSave = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: {
    ...state[jobItemId],
    isEditing: false,
  },
});

const setPreviousJobItem = (state, { payload: { jobItemId, jobItem } }) => ({
  ...state,
  [jobItemId]: {
    ...state[jobItemId],
    previousJobItem: jobItem,
  },
});

const setExpanded = (
  state,
  { payload: { jobItemId = null, jobItemIds = null, isExpanded } }
) => {
  const newState = { ...state };

  if (jobItemId !== null) {
    newState[jobItemId] = {
      ...state[jobItemId],
      isExpanded,
    };
  }

  if (jobItemIds !== null) {
    jobItemIds.forEach((id) => {
      newState[id] = {
        ...state[id],
        isExpanded,
      };
    });
  }

  return newState;
};

const onDuplicateStarted = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: true },
});

const onDuplicateEnded = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: false },
});

const onPhaseLoadingStarted = (state, { payload: { jobItemIds } }) => {
  return produce(state, (draft) => {
    jobItemIds.forEach((jobItemId) => {
      draft[jobItemId] = { ...draft[jobItemId], isSaving: true };
    });
  });
};

const onPhaseLoadingEnded = (state, { payload: { jobItemIds } }) => {
  return produce(state, (draft) => {
    jobItemIds.forEach((jobItemId) => {
      draft[jobItemId] = { ...draft[jobItemId], isSaving: false };
    });
  });
};

const onSetSaving = (state, { payload: { jobItemId, saving } }) => ({
  ...state,
  [jobItemId]: { ...state[jobItemId], isSaving: saving },
});

const onJobItemRevert = (state, { payload: { jobItem } }) => {
  return produce(state, (draft) => {
    delete draft[jobItem.id];
  });
};

export default createReducer(
  {},
  {
    [JOB_ITEM_EDIT_ITEM_CARD]: onEdit,
    [JOB_ITEM_SUB_ITEM_EDIT]: onEdit,
    [JOB_ITEM_USER_EDIT]: onEdit,
    [JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP]: onSave,
    [JOB_ITEM_SAVE_ITEM_CARD]: onSave,
    [JOB_ITEM_DRAG_START]: dragStart,
    [JOB_ITEM_DRAG_END]: dragEnd,
    [JOB_ITEM_DRAG_CANCEL]: dragEnd,
    [JOB_ITEM_RESIZE_START]: resizeStart,
    [JOB_ITEM_RESIZE_END]: resizeEnd,
    [JOB_ITEM_RESIZE_CANCEL]: resizeEnd,
    [JOB_ITEM_SAVING]: onSaveRequest,
    [JOB_ITEM_SAVED]: onSaveSuccess,
    [JOB_ITEM_SAVE_ERROR]: onSaveError,
    [JOB_ITEM_DELETING]: onDeleteRequest,
    [JOB_ITEM_DELETED]: onDeleteSuccess,
    [JOB_ITEM_DELETE_ERROR]: onDeleteError,
    [JOB_ITEM_DEPENDANCY_CREATE_START]: onCreateDependencyStart,
    [JOB_ITEM_DEPENDANCY_CREATE_END]: onCreateDependencyEnd,
    [JOB_ITEM_SET_PREVIOUS_JOB_ITEM]: setPreviousJobItem,
    [JOB_ITEM_SET_EXPANDED]: setExpanded,
    [JOB_ITEM_DUPLICATE_STARTED]: onDuplicateStarted,
    [JOB_ITEM_DUPLICATE_ENDED]: onDuplicateEnded,
    [JOB_PHASE_LOADING_STARTED]: onPhaseLoadingStarted,
    [JOB_PHASE_LOADING_ENDED]: onPhaseLoadingEnded,
    [JOB_ITEM_REVERT]: onJobItemRevert,
    [JOB_ITEM_ROLE_EDIT]: onEditJobItemRole,
    [JOB_ITEM_SET_SAVING]: onSetSaving,
  }
);
