import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATE_FORMAT_DATE_OF_MONTH,
  DATE_FORMAT_DATE_MONTH_FULL_YEAR,
  DATE_FORMAT_FULL_MONTH,
  DATE_FORMAT_DAY_OF_WEEK_LETTER,
  DATE_FORMAT_DATE_DAY,
  DATE_FORMAT_DATE_FULL_DAY,
  DATE_FORMAT_DATE_FULL_MONTH,
  JOB_TIMELINE_DATE_HEADER_HEIGHT,
  DATE_FORMAT_FULL_MONTH_YEAR,
  DATE_FORMAT_YEAR
} from "../../lib/constants";
import {
  getMonth,
  getYear,
  formatDate,
  getEndOfWeek,
  getTodayDate,
  isToday,
  isThisWeek,
  isThisMonth
} from "../../lib/dates";
import { memoizeArgs } from "../../lib/memoizers";
import { entityIdType } from "../../lib/types/entityTypes";
import { selectJobTimelineZoomLevel } from "../../redux/selectors/jobTimelineSelectors";
import JobTimelineMilestones from "./JobTimelineMilestones";
import Anchor from "./JobTimelineScrollProvider/Anchor";
import DatesConsumer from "./JobTimelineScrollProvider/DatesConsumer";
import MonthsConsumer from "./JobTimelineScrollProvider/MonthsConsumer";
import WeeksConsumer from "./JobTimelineScrollProvider/WeeksConsumer";
import YearsConsumer from "./JobTimelineScrollProvider/YearsConsumer";

const mapState = state => ({
  zoomLevel: selectJobTimelineZoomLevel(state)
});

const mapDispatch = null;

const getYearText = date => formatDate(date, DATE_FORMAT_YEAR);

const getMonthText = (date, hideYears = false) =>
  getYear(date) === getYear(getTodayDate()) || hideYears
    ? formatDate(date, DATE_FORMAT_FULL_MONTH)
    : formatDate(date, DATE_FORMAT_FULL_MONTH_YEAR);

const getWeekText = memoizeArgs((startOfWeek, zoomLevel) => {
  let startOfWeekText;
  let endOfWeekText;
  const endOfWeek = getEndOfWeek(startOfWeek);

  if (zoomLevel < 2) {
    startOfWeekText = formatDate(startOfWeek, DATE_FORMAT_DATE_OF_MONTH);
    endOfWeekText = formatDate(endOfWeek, DATE_FORMAT_DATE_OF_MONTH);
  } else {
    startOfWeekText =
      getMonth(startOfWeek) !== getMonth(endOfWeek)
        ? formatDate(startOfWeek, DATE_FORMAT_DATE_FULL_MONTH)
        : formatDate(startOfWeek, DATE_FORMAT_DATE_OF_MONTH);
    endOfWeekText =
      getYear(endOfWeek) === getYear(getTodayDate())
        ? formatDate(endOfWeek, DATE_FORMAT_DATE_FULL_MONTH)
        : formatDate(endOfWeek, DATE_FORMAT_DATE_MONTH_FULL_YEAR);
  }

  return `${startOfWeekText} - ${endOfWeekText}`;
});

const getDateText = memoizeArgs((date, zoomLevel) => {
  if (zoomLevel === 2) return formatDate(date, DATE_FORMAT_DAY_OF_WEEK_LETTER);
  if (zoomLevel === 3) return formatDate(date, DATE_FORMAT_DATE_DAY);
  return formatDate(date, DATE_FORMAT_DATE_FULL_DAY);
});

const getTodayText = zoomLevel => {
  if (zoomLevel === 0) return "This Month";
  if (zoomLevel === 1) return "This Week";
  return "Today";
};

const renderYearsRow = () => {
  return (
    <YearsConsumer>
      {({ date, width, left }) => (
        <TopCell key={date} style={{ left, width }}>
          {getYearText(date)}
        </TopCell>
      )}
    </YearsConsumer>
  );
};

const renderMonthsRow = zoomLevel => {
  return (
    <MonthsConsumer>
      {({ date, width, left }) => {
        if (zoomLevel === 0)
          return isThisMonth(date) ? (
            <TodayDate key={date} style={{ left, width: width + 1 }}>
              {getTodayText(zoomLevel)}
            </TodayDate>
          ) : (
            <BottomCell key={date} style={{ left, width }}>
              {getMonthText(date, true)}
            </BottomCell>
          );

        return (
          <TopCell key={date} style={{ left, width }}>
            {getMonthText(date)}
          </TopCell>
        );
      }}
    </MonthsConsumer>
  );
};

const renderWeeksRow = zoomLevel => {
  return (
    <WeeksConsumer>
      {({ date, width, left }) => {
        if (zoomLevel < 2)
          return isThisWeek(date) ? (
            <TodayDate key={date} style={{ left, width: width + 1 }}>
              {getTodayText(zoomLevel)}
            </TodayDate>
          ) : (
            <BottomCell key={date} style={{ left, width }}>
              {getWeekText(date, zoomLevel)}
            </BottomCell>
          );

        return (
          <TopCell key={date} style={{ left, width }}>
            {getWeekText(date, zoomLevel)}
          </TopCell>
        );
      }}
    </WeeksConsumer>
  );
};

const renderDatesRow = zoomLevel => {
  return (
    <DatesConsumer>
      {({ date, left, width }) =>
        isToday(date) ? (
          <TodayDate key={date} style={{ left, width: width + 1 }}>
            {getTodayText(zoomLevel)}
          </TodayDate>
        ) : (
          <BottomCell key={date} style={{ left, width }}>
            {getDateText(date, zoomLevel)}
          </BottomCell>
        )
      }
    </DatesConsumer>
  );
};

class JobTimelineDatesHeader extends React.PureComponent {
  anchorRef = React.createRef();

  static propTypes = {
    jobId: entityIdType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    readOnly: PropTypes.bool
  };

  static defaultProps = {
    readOnly: false
  };

  renderJobMilestones() {
    const { jobId, readOnly, zoomLevel } = this.props;

    return (
      <JobTimelineMilestones
        anchorRef={this.anchorRef}
        jobId={jobId}
        readOnly={readOnly}
        zoomLevel={zoomLevel}
      />
    );
  }

  render() {
    const { zoomLevel } = this.props;

    return (
      <DatesHeaderWrapper>
        <DatesHeaderContainer>
          <DatesHeaderGrid>
            <Anchor ref={this.anchorRef}>
              {zoomLevel === 0 && renderYearsRow()}
              {zoomLevel === 0 && renderMonthsRow(zoomLevel)}
              {zoomLevel === 1 && renderMonthsRow(zoomLevel)}
              {zoomLevel > 0 && renderWeeksRow(zoomLevel)}
              {zoomLevel > 1 && renderDatesRow(zoomLevel)}
              {this.renderJobMilestones()}
            </Anchor>
          </DatesHeaderGrid>
        </DatesHeaderContainer>
      </DatesHeaderWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineDatesHeader);

export const DatesHeaderWrapper = styled.div`
  position: relative;
  height: ${JOB_TIMELINE_DATE_HEADER_HEIGHT}px;
  border-bottom: 0.5px solid var(--color-gray);
  box-shadow: 0 5px 5px -8px rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-job-timeline-dates-header);
`;

export const DatesHeaderContainer = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  bottom: -12px;
  overflow: hidden;
  padding-top: 24px;
`;

export const DatesHeaderGrid = styled.div`
  position: relative;
  height: 50px;
`;

export const DatesHeaderGridCell = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  border-left: var(--border-thin-gray);
  align-items: start;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  will-change: left;
`;

const TopCell = styled(DatesHeaderGridCell)`
  height: 23px;
  padding-left: 11px;
  justify-content: start;
  background-color: var(--color-charcoal);
  color: white;
  font-size: 10px;
  font-weight: bold;
  line-height: 25px;
`;

const BottomCell = styled(DatesHeaderGridCell)`
  top: 23px;
  height: 25px;
  line-height: 32px;
  color: var(--color-charcoal);
  font-size: 10px;
  font-weight: bold;
  line-height: 25px;
`;

export const TodayDate = styled(BottomCell)`
  height: 42px;
  border: none;
  background-color: black;
  color: white;
  font-weight: 700;
  transition: color 0.2s;
  z-index: 1;
  pointer-events: none;
`;
