import { get, isEqual } from "lodash-es";

const defaultShouldRemove = () => false;

export default (
  state,
  payload = [],
  shouldRemove = defaultShouldRemove,
  mergeAttrs = {}
) => {
  const changedEntities = [];
  const data = Array.isArray(payload) ? payload : Object.values(payload);

  data.forEach(newEntity => {
    const prevEntity = get(state, `byId.${newEntity.id}`);

    Object.assign(newEntity, mergeAttrs);

    if (shouldRemove(newEntity)) {
      if (prevEntity) changedEntities.push({ prevEntity });
    } else if (!isEqual(prevEntity, newEntity)) {
      changedEntities.push({ prevEntity, newEntity });
    }
  });

  return changedEntities;
};
