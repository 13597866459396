import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectIsScheduleSearchResultsEmpty } from "../../../redux/selectors/scheduleSelectors";
import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";
import { selectJobListModelsFilteredIdsByUserId } from "../../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIdsByUserId";
import { selectScheduleUiIsSearching } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import ScheduleUserJob, { UserJobSearchingPulser } from "./ScheduleUserJob";

const mapState = (state, props) => ({
  jobIds: selectJobListModelsFilteredIdsByUserId(state, { userId: props.id }),
  isCollapsed: selectIsScheduleUserCollapsed(state, props),
  isSearching: selectScheduleUiIsSearching(state),
  isEmpty: selectIsScheduleSearchResultsEmpty(state),
});

const ScheduleUserJobs = ({
  id,
  jobIds,
  isCollapsed,
  isSearching,
  isEmpty,
}) => {
  if (isCollapsed) return null;

  if (isSearching) return <UserJobSearchingPulser />;

  if (isEmpty) return <EmptyRow />;

  return jobIds.map((jobId) => (
    <ScheduleUserJob key={jobId} id={jobId} userId={id} />
  ));
};

ScheduleUserJobs.propTypes = {
  id: entityIdType.isRequired,
  jobIds: entityIdListType.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default connect(mapState)(ScheduleUserJobs);

const EmptyRow = styled.div`
  width: 100%;
  height: ${ScheduleUi.User.JobEmptyRowHeight}px;
  border-top: var(--border-thin-bright);
`;
