import React from "react";
import { DATE_FORMAT_DATE_MONTH_YEAR } from "../../../../../lib/constants";
import { formatDate } from "../../../../../lib/dates";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataDate,
  getMetaDataJobMilestoneName,
  getMetaDataPreviousDate
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobMilestoneMoved = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" moved the job milestone "}
    <b>{getMetaDataJobMilestoneName(activityEntry)}</b>
    {" from "}
    {formatDate(
      getMetaDataPreviousDate(activityEntry),
      DATE_FORMAT_DATE_MONTH_YEAR
    )}
    {" to "}
    <b>
      {formatDate(getMetaDataDate(activityEntry), DATE_FORMAT_DATE_MONTH_YEAR)}
    </b>
  </SystemMessage>
);

JobMilestoneMoved.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobMilestoneMoved;
