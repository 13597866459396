import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  CONTACT_GROUPED_OPTION_TYPE,
  contactOptionType
} from "../../../lib/entities/contactEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectGroupedContactOptionsByCompanyId } from "../../../redux/selectors/contactSelectors";
import Flex from "../../elements/Flex";
import SearchMenu from "../SearchMenu";
import SearchMenuCreateFooter from "../SearchMenu/SearchMenuCreateFooter";

const mapState = (state, props) => ({
  groupedContactOptions: selectGroupedContactOptionsByCompanyId(state, props)
});

const mapDispatch = null;

class CompanyContactsSearchMenu extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    companyId: entityIdType.isRequired,
    onPickOption: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    groupedContactOptions: PropTypes.arrayOf(contactOptionType).isRequired,
    excludeIds: entityIdListType
  };

  static defaultProps = {
    anchorEl: null,
    excludeIds: [],
    onClose: null,
    onCreate: null
  };

  getNoResultsMessage = searchQuery =>
    `Hmm, looks like no contacts match ‘${searchQuery}’. Perhaps try a different search term.`;

  render() {
    const {
      anchorEl,
      onPickOption,
      onClose,
      onCreate,
      groupedContactOptions,
      excludeIds
    } = this.props;

    return (
      <SearchMenu
        anchorEl={anchorEl}
        options={groupedContactOptions}
        excludeIds={excludeIds}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        HeaderComponentProps={{
          title: "Company Contacts",
          noOptionsMessage: "There are no other contacts for this company",
          getNoResultsMessage: this.getNoResultsMessage
        }}
        FooterComponent={
          onCreate && (
            <SearchMenuCreateFooter
              text="Create new contact"
              onClick={onCreate}
            />
          )
        }
        onClose={onClose}
      >
        {({ key, value, optionType }) => {
          const classes = ["rainbowHover"];
          if (optionType === CONTACT_GROUPED_OPTION_TYPE.OPTIONS_HEADING)
            classes.push("heading");
          if (optionType === CONTACT_GROUPED_OPTION_TYPE.ARCHIVED)
            classes.push("archived");
          return (
            <ContactOption
              className={classes.join(" ")}
              key={key}
              onClick={() => onPickOption(key)}
            >
              {value}
            </ContactOption>
          );
        }}
      </SearchMenu>
    );
  }
}

export default connect(mapState, mapDispatch)(CompanyContactsSearchMenu);

const ContactOption = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  user-select: none;
  align-items: center;
  cursor: pointer;
  &.heading {
    background-color: var(--color-ygrittesnow);
    color: var(--color-gray-dark);
    pointer-events: none;
    text-transform: uppercase;
    font-size: 12px;
  }
  &.archived {
    color: var(--tooltip-title-color);
  }
`;
