import { EmptyUserIcon } from "st-shared/module";

import { convertMinutesToTimeHM } from "../../../lib/time";
import { IconButton } from "../../elements/Button";
import Tooltip from "../../modules/Tooltip";
import * as styles from "./JobRoleIcon.css";

type Props = {
  roles: { id: number; name: string; planned: number }[];
  size?: number;
  onClick: () => void;
  onRoleSelect: (roleId: number) => void;
  disabled: boolean;
};

export function JobRoleIcon({
  roles,
  size = 30,
  onClick,
  onRoleSelect,
  disabled,
}: Props) {
  const totalPlanned = roles.reduce((total, roles) => total + roles.planned, 0);
  const topRoles = roles.slice(0, 5);
  const otherRoles = roles.slice(topRoles.length);

  return (
    <Tooltip
      title={
        <div className={styles.tooltip}>
          <div className={styles.tooltipRow}>
            <span>Unassigned Time</span>
            <span>{convertMinutesToTimeHM(totalPlanned)}</span>
          </div>
          <div className={styles.tooltipDivider}></div>
          {topRoles.map((role) => {
            return (
              <div key={role.id} className={styles.tooltipRow}>
                <span
                  className={styles.tooltipRowName}
                  onClick={() => onRoleSelect(role.id)}
                >
                  {role.name}
                </span>
                <span className={styles.tooltipRowPlanned}>
                  {convertMinutesToTimeHM(role.planned)}
                </span>
              </div>
            );
          })}
          {otherRoles.length > 0 && (
            <div className={styles.tooltipRow}>
              <span>and {otherRoles.length} more</span>
            </div>
          )}
          {!disabled && (
            <div className={styles.tooltipAssignRow}>
              <span>Click to assign time</span>
            </div>
          )}
        </div>
      }
      placement="bottom"
      interactive
    >
      <div className={styles.container}>
        {disabled && (
          <>
            <EmptyUserIcon size={size} />
            <div className={styles.iconBadge}>{roles.length}</div>
          </>
        )}
        {!disabled && (
          <IconButton onClick={onClick}>
            <EmptyUserIcon size={size} />
            <div className={styles.iconBadge}>{roles.length}</div>
          </IconButton>
        )}
      </div>
    </Tooltip>
  );
}
