import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { entityIdType } from "../types/entityTypes";

export const addressShape = {
  id: entityIdType.isRequired,
  companyId: entityIdType.isRequired,
  nickname: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  countryName: PropTypes.string,
  postCode: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

export const addressEntityType = PropTypes.shape(addressShape);

export const addressOptionType = PropTypes.shape({
  key: entityIdType.isRequired,
  value: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  searchString: PropTypes.string,
});

export const isActive = ({ active }) => active;

export const getSearchString = (address) =>
  [
    asString(address.nickname),
    asString(address.line1),
    asString(address.line2),
    asString(address.line3),
    asString(address.city),
    asString(address.region),
    asString(address.countryName),
    asString(address.postCode),
  ].join(" ");

export const getNickName = ({ nickname }) => nickname;

export const getFullAddress = ({
  line1,
  line2,
  line3,
  city,
  region,
  countryName,
  postCode,
}) => {
  let line4 = [asString(city), asString(region)].filter(Boolean).join(" ");
  if (line4 && postCode) line4 = line4.concat(", ");
  if (postCode) line4 = line4.concat(asString(postCode));

  return [
    asString(line1),
    asString(line2),
    asString(line3),
    line4,
    asString(countryName),
  ]
    .filter(Boolean)
    .join("\r\n");
};

export const getAsOption = (contact) => ({
  key: contact.id,
  value: getFullAddress(contact),
  nickname: getNickName(contact),
  searchString: getSearchString(contact),
});
