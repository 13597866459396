import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { BatchActionStore } from "./BatchAction.store";

export const useBatchActionEntity = createUseEntityStoreEntity(
  EntityClass.BatchAction
);

export const getBatchActionEntity = createGetEntityStoreEntity(
  EntityClass.BatchAction
);

export const useBatchAction = createUseSingleEntity(BatchActionStore);

export function useBatchActionStore() {
  return BatchActionStore.useStore();
}

export function useBatchActionActions() {
  return BatchActionStore.useActions();
}
