import { isNullish } from "remeda";

import type {
  TNumericStateCalculator,
  TNumericStateComparatorEnum,
} from "./NumericState";
import { NumericStateComparatorEnum, NumericStateEnum } from "./NumericState";

export const Calculators = {
  DefaultSingle: {
    threshold: 0,
    comparator: NumericStateComparatorEnum.Equals,
    state: NumericStateEnum.Blank,
  } as TNumericStateCalculator,
  Default: [
    {
      threshold: 0,
      comparator: NumericStateComparatorEnum.Equals,
      state: NumericStateEnum.Blank,
    },
    {
      threshold: 0,
      comparator: NumericStateComparatorEnum.LessThan,
      state: NumericStateEnum.Warning,
    },
  ] as TNumericStateCalculator[],
  PositiveWarning: [
    {
      threshold: 0,
      comparator: NumericStateComparatorEnum.Equals,
      state: NumericStateEnum.Blank,
    },
    {
      threshold: 0,
      comparator: NumericStateComparatorEnum.GreaterThan,
      state: NumericStateEnum.Warning,
    },
  ] as TNumericStateCalculator[],
} as const;

export function getComparatorText(comparator: TNumericStateComparatorEnum) {
  switch (comparator) {
    case NumericStateComparatorEnum.Equals:
      return "equal to";
    case NumericStateComparatorEnum.NotEquals:
      return "does not equal";
    case NumericStateComparatorEnum.GreaterThan:
      return "greater than";
    case NumericStateComparatorEnum.GreaterThanOrEqual:
      return "greater than or equal to";
    case NumericStateComparatorEnum.LessThan:
      return "less than";
    case NumericStateComparatorEnum.LessThanOrEqual:
      return "less than or equal to";
    default:
      return "";
  }
}

function calculateSingle(value: number, calculator: TNumericStateCalculator) {
  switch (calculator.comparator) {
    case NumericStateComparatorEnum.Equals:
      if (value === calculator.threshold) {
        return calculator.state;
      }
      break;
    case NumericStateComparatorEnum.NotEquals:
      if (value !== calculator.threshold) {
        return calculator.state;
      }
      break;
    case NumericStateComparatorEnum.GreaterThan:
      if (value > calculator.threshold) {
        return calculator.state;
      }
      break;
    case NumericStateComparatorEnum.GreaterThanOrEqual:
      if (value >= calculator.threshold) {
        return calculator.state;
      }
      break;
    case NumericStateComparatorEnum.LessThan:
      if (value < calculator.threshold) {
        return calculator.state;
      }
      break;
    case NumericStateComparatorEnum.LessThanOrEqual:
      if (value <= calculator.threshold) {
        return calculator.state;
      }
      break;
  }
  return null;
}

export function calculate(
  value: number | null | undefined,
  calculators: TNumericStateCalculator[]
) {
  if (isNullish(value)) return NumericStateEnum.Blank;

  let state = null;

  for (const calculator of calculators) {
    const calculatedState = calculateSingle(value, calculator);
    if (calculatedState !== null) {
      state = calculatedState;
    }
  }

  if (state !== null) {
    return state;
  }

  return NumericStateEnum.Neutral;
}
