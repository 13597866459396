import { images } from "@streamtimefe/assets";

import { emptyChartCss } from "./Chart.css";

export function LoadingChart() {
  return (
    <div className={emptyChartCss}>
      <img
        src={images.iconDotPulseLight}
        alt="dot-pulse"
        width={60}
        height={60}
      />
    </div>
  );
}
