import {
  SCHEDULE_JOB_ITEM_EXPAND,
  SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE,
  SCHEDULE_JOB_ITEM_COLLAPSE,
  SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onExpandJobItem = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isExpanded: true
  }
});

const onCollapseJobItem = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isExpanded: false
  }
});

const onExpandJobItems = (state, { payload: { ids } }) => ({
  ...state,
  ...ids.reduce((acc, id) => {
    acc[id] = {
      ...state[id],
      isExpanded: true
    };
    return acc;
  }, {})
});

const onCollapseJobItems = (state, { payload: { ids } }) => ({
  ...state,
  ...ids.reduce((acc, id) => {
    acc[id] = {
      ...state[id],
      isExpanded: false
    };
    return acc;
  }, {})
});

export default createReducer(
  {},
  {
    [SCHEDULE_JOB_ITEM_EXPAND]: onExpandJobItem,
    [SCHEDULE_JOB_ITEM_COLLAPSE]: onCollapseJobItem,
    [SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE]: onExpandJobItems,
    [SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE]: onCollapseJobItems
  }
);
