import { ReportingSearchType } from "st-shared/lib/webapi/reporting";

import {
  sortMatrixSeriesData,
  sortVectorSeriesData,
} from "../../lib/VectorMatrixSeriesData";
import { useReportingStore } from "./reportingStore";
import {
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedColumnId,
  useReportingSavedSegmentSelectedTimeId,
  useReportingSavedSegmentSortColumn,
  useReportingSavedSegmentSortTime,
} from "./savedSegmentSelectors";

export function useReportingSeriesFetching() {
  return useReportingStore((s) => {
    switch (s.savedSegment.value.searchType) {
      case ReportingSearchType.TimeSeries:
        return s.search.timeSeries.fetching;
      case ReportingSearchType.ColumnSeries:
        return s.search.columnSeries.fetching;
      default:
        return true;
    }
  });
}

export function useReportingSeriesCalculatedColumn() {
  return useReportingStore((s) => s.search.columnSeries.calculatedData);
}

export function useReportingSeriesCalculatedTime() {
  return useReportingStore((s) => s.search.timeSeries.calculatedData);
}

export function useReportingSeriesTotalsColumnVector() {
  return useReportingStore((s) => s.search.columnSeries.totals.vector);
}

export function useReportingSeriesTotalsTimeVector() {
  return useReportingStore((s) => s.search.timeSeries.totals.vector);
}

export function useReportingSeriesTotalsTimeMatrix() {
  return useReportingStore((s) => s.search.timeSeries.totals.matrix);
}

export function useReportingSeriesSortedColumn() {
  const seriesData = useReportingSeriesCalculatedColumn();
  const sortColumn = useReportingSavedSegmentSortColumn();
  return sortVectorSeriesData(seriesData, sortColumn);
}

export function useReportingSeriesSortedSelectedColumn() {
  const selectedColumnId = useReportingSavedSegmentSelectedColumnId();
  const seriesData = useReportingSeriesCalculatedColumn();
  return sortVectorSeriesData(seriesData, {
    id: selectedColumnId,
    order: "asc",
  });
}

export function useReportingSeriesSortedTime() {
  const seriesData = useReportingSeriesCalculatedTime();
  const sortTime = useReportingSavedSegmentSortTime();
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  return sortMatrixSeriesData(seriesData, sortTime, selectedTimeId);
}

export function useReportingSelectedSeriesData() {
  const searchType = useReportingSavedSegmentSearchType();
  const sortedTime = useReportingSeriesSortedTime();
  const sortedColumn = useReportingSeriesSortedColumn();

  return searchType === ReportingSearchType.TimeSeries
    ? sortedTime
    : sortedColumn;
}

// sub row series

export function useReportingSubSeriesFetching(id: string) {
  return useReportingStore((s) => {
    switch (s.savedSegment.value.searchType) {
      case ReportingSearchType.TimeSeries:
        return id in s.search.timeSubSeries
          ? s.search.timeSubSeries[id].fetching
          : true;
      case ReportingSearchType.ColumnSeries:
        return id in s.search.columnSubSeries
          ? s.search.columnSubSeries[id].fetching
          : true;
    }
  });
}

export function useReportingSubSeriesExpanded(id: string) {
  return useReportingStore((s) => {
    switch (s.savedSegment.value.searchType) {
      case ReportingSearchType.TimeSeries:
        return id in s.search.timeSubSeries
          ? s.search.timeSubSeries[id].expanded
          : false;
      case ReportingSearchType.ColumnSeries:
        return id in s.search.columnSubSeries
          ? s.search.columnSubSeries[id].expanded
          : false;
    }
  });
}

function useReportingSubSeriesTimeCalculatedData(id: string) {
  return useReportingStore((s) =>
    id in s.search.timeSubSeries
      ? s.search.timeSubSeries[id].calculatedData
      : []
  );
}

function useReportingSubSeriesColumnCalculatedData(id: string) {
  return useReportingStore((s) =>
    id in s.search.columnSubSeries
      ? s.search.columnSubSeries[id].calculatedData
      : []
  );
}

export function useReportingSubSeriesSortedTime(id: string) {
  const seriesData = useReportingSubSeriesTimeCalculatedData(id);
  const sortTime = useReportingSavedSegmentSortColumn();
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  return sortMatrixSeriesData(seriesData, sortTime, selectedTimeId);
}

export function useReportingSubSeriesSortedColumn(id: string) {
  const seriesData = useReportingSubSeriesColumnCalculatedData(id);
  const sortColumn = useReportingSavedSegmentSortColumn();
  return sortVectorSeriesData(seriesData, sortColumn);
}
