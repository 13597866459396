import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { JOB_COST_VS_SELL_MODE } from "../../../../lib/constants/jobDetails";
import {
  getJobCurrencyTotalLoggedCostExTax,
  getJobCurrencyTotalLoggedExpensesCostExTax,
  getJobCurrencyTotalLoggedExpensesExTax,
  getJobCurrencyTotalLoggedExTax,
  getJobCurrencyTotalLoggedTimeCostExTax,
  getJobCurrencyTotalLoggedTimeExTax,
  getJobCurrencyTotalPlannedExpensesExTax,
  getJobCurrencyTotalPlannedExTax,
  getJobCurrencyTotalPlannedTimeExTax,
} from "../../../../lib/entities/jobEntity";
import { jobPageOpenLoggedItemsModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJumbotronIsUsedOverPlanned } from "../../../../redux/selectors/jobDetails/jumbotronIsUsedOverTime";
import { useJumbotronUsedPlannedDollarsDisplay } from "../../../../redux/selectors/jobDetails/jumbotronUsedPlannedDollarsDisplay";
import { useJobDetailsCostVsSellMode } from "../../../../redux/selectors/jobDetails/ui/costVsSellMode";
import { useHasJobItemIdsByJobId } from "../../../../redux/selectors/jobItem/hasJobItemIdsByJobId";
import Tooltip from "../../../modules/Tooltip";
import JumbotronStatField from "../../components/JumbotronStatField";
import { useJobId } from "../../context/JobIdContext";
import {
  InlineTextSpan,
  JumbotronTooltipTable,
  TooltipTitle,
  TooltipValue,
} from "../styles";

const JumbotronTotal = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const costVsSellMode = useJobDetailsCostVsSellMode();
  const customerCurrency = useCustomerCurrency();
  const hasJobItems = useHasJobItemIdsByJobId(jobId);
  const jobCurrency = useJobCurrency(jobId);
  const isUsedOverPlanned = useJumbotronIsUsedOverPlanned(jobId);
  const usedPlannedDollarsDisplay =
    useJumbotronUsedPlannedDollarsDisplay(jobId);

  return (
    <JumbotronStatField
      title={`TOTAL USED${
        costVsSellMode === JOB_COST_VS_SELL_MODE.COST ? " COST" : ""
      }${hasJobItems ? ` / PLANNED` : ""} (${jobCurrency.id})`}
      onClick={() => jobPageOpenLoggedItemsModal(job)}
      largeText
    >
      <Tooltip
        title={getTotalTooltip(
          job,
          jobCurrency,
          costVsSellMode,
          customerCurrency
        )}
        placement="left"
      >
        <InlineTextSpan
          $warning={isUsedOverPlanned}
          className="totalUsedPlanned"
        >
          <InlineTextSpan $bold>
            {usedPlannedDollarsDisplay.numerator}
          </InlineTextSpan>

          {usedPlannedDollarsDisplay.denominator.length > 0 && (
            <InlineTextSpan $light>
              {" "}
              / {usedPlannedDollarsDisplay.denominator}
            </InlineTextSpan>
          )}
        </InlineTextSpan>
      </Tooltip>
    </JumbotronStatField>
  );
};

export default JumbotronTotal;

const getTotalTooltip = (
  job,
  jobCurrency,
  costVsSellMode,
  customerCurrency
) => {
  return (
    <JumbotronTooltipTable>
      <tbody>
        <tr>
          <td></td>
          <TooltipTitle>
            Used{" "}
            {costVsSellMode === JOB_COST_VS_SELL_MODE.COST ? "Cost" : "Sell"}
          </TooltipTitle>
          <TooltipTitle>Planned</TooltipTitle>
        </tr>
        <tr className="divider">
          <td colSpan="3"></td>
        </tr>
        <tr className="dividerBlank">
          <td colSpan="3"></td>
        </tr>
        <tr>
          <TooltipValue>Time</TooltipValue>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? getJobCurrencyTotalLoggedTimeCostExTax(job)
                  : getJobCurrencyTotalLoggedTimeExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalPlannedTimeExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipValue $padded>Expenses</TooltipValue>
          <TooltipValue $padded>
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? getJobCurrencyTotalLoggedExpensesCostExTax(job)
                  : getJobCurrencyTotalLoggedExpensesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
          <TooltipValue $padded>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalPlannedExpensesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipTitle>Total</TooltipTitle>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? getJobCurrencyTotalLoggedCostExTax(job)
                  : getJobCurrencyTotalLoggedExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalPlannedExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
        </tr>
      </tbody>
    </JumbotronTooltipTable>
  );
};
