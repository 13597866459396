import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  ChartType,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import {
  DataLabelsOptions,
  legendMarginPlugin,
  LegendMarginPluginOptions,
} from "./plugins";
import { dataLabelsPlugin } from "./plugins/dataLabels/plugin";

Chart.register(
  CategoryScale,
  BarElement,
  ArcElement,
  Title,
  Legend,
  LineController,
  DoughnutController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Filler,
  Tooltip,
  annotationPlugin,
  legendMarginPlugin,
  dataLabelsPlugin
);

Chart.defaults.font.family = "BwModelica";
Chart.defaults.font.weight = 500;
Chart.defaults.set("plugins.datalabels", {
  display: false,
  font: { weight: 500 },
});

import "chartjs-adapter-moment";

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ChartDatasetProperties<TType extends ChartType, TData> {
    datalabels?: DataLabelsOptions;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    legendMargin?: LegendMarginPluginOptions;
    datalabels?: DataLabelsOptions;
  }
}

export * as ChartJS from "chart.js";
export * as ChartJSAnnotation from "chartjs-plugin-annotation";
export * as ReactChartJS from "react-chartjs-2";
