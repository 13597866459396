import { plate } from "st-shared/external";

import { columnOnKeyDownHandler } from "./handlers";
import { isSelectionInColumnInput } from "./queries";
import { ColumnPlugin } from "./types";
import { withColumn } from "./withColumn";

export const ELEMENT_COLUMN = "column";
export const ELEMENT_COLUMN_INPUT = "column_input";
export const ElementColumnTrigger = "@";

export const createColumnPlugin = plate.createPluginFactory<ColumnPlugin>({
  key: ELEMENT_COLUMN,
  isElement: true,
  isInline: true,
  isVoid: true,
  isMarkableVoid: true,
  handlers: {
    onKeyDown: columnOnKeyDownHandler({ query: isSelectionInColumnInput }),
    onBlur: (editor) => () => {
      // remove column_input nodes from editor on blur
      plate.removeNodes(editor, {
        match: (n) => n.type === ELEMENT_COLUMN_INPUT,
        at: [],
      });
    },
  },
  withOverrides: withColumn,
  options: {
    trigger: ElementColumnTrigger,
  },
  plugins: [
    {
      key: ELEMENT_COLUMN_INPUT,
      isElement: true,
      isInline: true,
    },
  ],
  then: (_editor, { key }) => ({
    options: {
      id: key,
    },
  }),
});
