import { sharedEmitter } from "st-shared/lib";

import WebAppAPI from "..";

// send

const SEND_JOB_PAGE_OPEN_QUOTES_MODAL = "openQuotesModal";
const SEND_JOB_PAGE_OPEN_INVOICES_MODAL = "openInvoicesModal";
const SEND_JOB_PAGE_OPEN_LOGGED_EXPENSES_MODAL = "openLoggedExpenses";
const SEND_JOB_PAGE_OPEN_LOGGED_ITEMS_MODAL = "openLoggedItems";
const SEND_JOB_PAGE_OPEN_REPEATING_LOGGED_TIMES_MODAL =
  "openRepeatingLoggedTimesModal";
const SEND_CHANGE_JOB_BILLABLE_STATUS = "changeJobBillableStatus";
const SEND_OPEN_DELETE_MODAL = "openDeleteModal";
const SEND_OPEN_ARCHIVE_JOB_MODAL = "openArchiveJobModal";
const SEND_OPEN_RESTORE_JOB_MODAL = "openRestoreJobModal";
const SEND_OPEN_CREATE_JOB_MODAL = "openCreateJobModal";
const SEND_JOB_PAGE_CHANGE_JOB_RATE_CARD = "changeJobRateCard";
const SEND_CREATE_LOGGED_TIME = "createLoggedTime";
const SEND_CREATE_REPEATING_LOGGED_TIME = "createRepeatingLoggedTime";
const SEND_CREATE_EXPENSE = "createExpense";
const SEND_CREATE_PURCHASE_ORDER = "createPurchaseOrder";
const SEND_CREATE_QUOTE = "createQuote";
const SEND_CREATE_INVOICE = "createInvoice";
const SEND_CREATE_CREDIT_NOTE = "createCreditNote";
const SEND_JOB_PAGE_CHANGE_JOB_EXCHANGE_RATE = "changeJobExchangeRate";
const SEND_JOB_PAGE_CHANGE_JOB_BRANCH = "changeJobBranch";

export const JOB_PAGE_REFETCH_METADATA = "jobPageRefetchMetadata";

// calls

export function jobPageOpenQuotesModal(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_OPEN_QUOTES_MODAL, job);
}

export function jobPageOpenInvoicesModal(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_OPEN_INVOICES_MODAL, job);
}

export function jobPageOpenLoggedExpensesModal(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_OPEN_LOGGED_EXPENSES_MODAL, job);
}

export function jobPageOpenLoggedItemsModal(
  job: unknown,
  viewMode?: "time" | "expenses"
) {
  const jQueryDeferred = WebAppAPI.sendAction(
    SEND_JOB_PAGE_OPEN_LOGGED_ITEMS_MODAL,
    job,
    viewMode
  );
  if (jQueryDeferred)
    jQueryDeferred.done(() => {
      sharedEmitter.emit(JOB_PAGE_REFETCH_METADATA);
    });
}

export function jobPageOpenRepeatingLoggedTimesModal(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_OPEN_REPEATING_LOGGED_TIMES_MODAL, job);
}

export function jobPageChangeJobBillableStatus(
  job: unknown,
  isBillable: boolean
) {
  WebAppAPI.sendAction(SEND_CHANGE_JOB_BILLABLE_STATUS, job, isBillable);
}

export function jobPageOpenDeleteModal(job: unknown) {
  WebAppAPI.sendAction(SEND_OPEN_DELETE_MODAL, job);
}

export function jobPageOpenArchiveJobModal(job: unknown) {
  WebAppAPI.sendAction(SEND_OPEN_ARCHIVE_JOB_MODAL, job);
}

export function jobPageOpenRestoreJobModal(job: unknown) {
  WebAppAPI.sendAction(SEND_OPEN_RESTORE_JOB_MODAL, job);
}

export function jobPageOpenCreateJobModal() {
  WebAppAPI.sendAction(SEND_OPEN_CREATE_JOB_MODAL);
}

export function jobPageOpenDuplicateJobModal(job: unknown) {
  WebAppAPI.sendAction(SEND_OPEN_CREATE_JOB_MODAL, job);
}

export function jobPageChangeJobRateCard(job: unknown) {
  const jQueryDeferred = WebAppAPI.sendAction(
    SEND_JOB_PAGE_CHANGE_JOB_RATE_CARD,
    job
  );
  if (jQueryDeferred)
    jQueryDeferred.done(() => {
      sharedEmitter.emit(JOB_PAGE_REFETCH_METADATA);
    });
}

export function jobPageCreateLoggedTime(job: unknown) {
  const jQueryDeferred = WebAppAPI.sendAction(SEND_CREATE_LOGGED_TIME, job);
  if (jQueryDeferred)
    jQueryDeferred.done(() => {
      sharedEmitter.emit(JOB_PAGE_REFETCH_METADATA);
    });
}

export function jobPageCreateRepeatingLoggedTime(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_REPEATING_LOGGED_TIME, job);
}

export function jobPageCreateExpense(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_EXPENSE, job);
}

export function jobPageCreatePurchaseOrder(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_PURCHASE_ORDER, job);
}

export function jobPageCreateQuote(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_QUOTE, job);
}

export function jobPageCreateInvoice(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_INVOICE, job);
}

export function jobPageCreateCreditNote(job: unknown) {
  WebAppAPI.sendAction(SEND_CREATE_CREDIT_NOTE, job);
}

export function jobPageChangeJobExchangeRate(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_CHANGE_JOB_EXCHANGE_RATE, job);
}

export function jobPageChangeJobBranch(job: unknown) {
  WebAppAPI.sendAction(SEND_JOB_PAGE_CHANGE_JOB_BRANCH, job);
}
