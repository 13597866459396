import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import styled from "styled-components";

export default styled(MuiButtonGroup)`
  height: 30px;
  border-radius: 15px;
  .MuiButtonGroup-grouped {
    min-width: 30px;
    height: 30px;
    margin: 0 1.5px;
    background-color: var(--color-gray-light);
    &:hover {
      svg {
        color: black;
      }
    }
  }
`;
