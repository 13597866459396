import type { ConstEnum } from "@streamtimefe/types";

export const ColumnTooltipEnum = {
  Budget: 1,
  BudgetVariance: 2,
  CreationDate: 3,
  DueDate: 4,
  DueDateRelative: 5,
  UsedExpensesCost: 6,
  UsedExpensesProfit: 7,
  UsedExpensesSell: 8,
  TotalInvoicedExTax: 9,
  ProfitMargin: 10,
  StartDate: 11,
  UsedCost: 12,
  PlannedHours: 13,
  TimePlannedSell: 14,
  UsedProfit: 15,
  UsedSell: 16,
  TotalCost: 17,
  TotalProfit: 18,
  TotalUsedSell: 19,
  UsedHours: 20,
  UsedVsPlannedHours: 21,
  TimeUsedVsPlanned: 22,
  TotalVsBudget: 23,
  TotalDraftInvoicesExTax: 27,
  TotalAwaitingPaymentInvoicesExTax: 28,
  TotalPaidInvoicesExTax: 29,
  TotalIssuedInvoicesExTax: 30,
  // ItemDueDate: 31,
  PlannedExpensesCost: 32,
  PlannedExpensesSell: 33,
  TotalPlannedSell: 34,
  TotalUsedVsPlanned: 35,
  UsedVsPlannedExpenses: 36,
  UsedVsPlannedExpensesCost: 37,
  LatestComment: 38,
} as const;

export type TColumnTooltipEnum = ConstEnum<typeof ColumnTooltipEnum>;
