import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const AddItemIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M10.02 18.93l12 .03v-4l-12-.03v4zm9-2.97h2v2h-2v-2zm-9-11L10 8.93l12.02.03v-4h-12zm11 3h-2v-2h2v2zm1 6v-4h-12l.02 3.97 11.98.03zm-3-3h2v2h-2v-2zM7 12l-1.98-.01L5 14H4v-2H2v-1l2.02-.01L4 9h1l.02 1.99L7 11v1zM-6.07 9.4" />
  </SvgIcon>
);

AddItemIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

AddItemIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(AddItemIcon);
