import { useReportingStore } from "./reportingStore";

export function useReportingGlobalFiltersOpen() {
  return useReportingStore((s) => s.ui.globalFiltersOpen);
}

export function useReportingDatasetColumnsOpen(dataSetId: string) {
  return useReportingStore((s) => {
    const open = s.ui.datasetColumnsOpen[dataSetId];
    if (typeof open !== "undefined") return open;
    return false;
  });
}

export function useReportingDatasetFiltersOpen(dataSetId: string) {
  return useReportingStore((s) => {
    const open = s.ui.datasetFiltersOpen[dataSetId];
    if (typeof open !== "undefined") return open;
    return false;
  });
}
