import { Entity } from "@streamtimefe/entities";

import { addDays } from "../../../../../lib/dates";
import reduceChangedEntities from "../reduceChangedEntities";

const dragBlockReducer = (state, action) => {
  const { blockKey, changed, date, duplicate, newBlockKey, hasChanged } =
    action.payload;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  if (duplicate)
    return reduceChangedEntities(
      state,
      prevScheduleLoggedTimeIds
        .map((id) => state.byId[id])
        .map((entity, index) => ({
          newEntity: {
            ...entity,
            ...changed,
            id: index === 0 ? newBlockKey : Entity.temporaryId(),
            billed: false,
            date: addDays(date, index),
            scheduleBlockBaseLoggedTimeId: newBlockKey,
            repeatingLoggedTimeId: null,
          },
        }))
    );

  if (!hasChanged) return state;

  const changedEntities = prevScheduleLoggedTimeIds
    .map((id) => state.byId[id])
    .map((entity, index) => ({
      prevEntity: entity,
      newEntity: {
        ...entity,
        ...changed,
        date: addDays(date, index),
        _prevBaseLoggedTimeId: blockKey,
      },
    }));

  return reduceChangedEntities(state, changedEntities);
};

export default dragBlockReducer;
