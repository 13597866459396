import { get, isEqual, set, uniq } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  getJobId,
  getJobPhaseGroupKey
} from "../../../../lib/entities/jobItemEntity";

export default (prevState, nextState, changedEntities) => {
  let hasChanged = false;
  const touchedJobIds = [];

  const jobPhaseIdsByJobId = {};

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    if (prevEntity && !newEntity) pushOnce(touchedJobIds, getJobId(prevEntity));

    if (newEntity) pushOnce(touchedJobIds, getJobId(newEntity));
  });

  if (!touchedJobIds.length) return {};

  touchedJobIds.forEach(jobId => {
    const prevJobPhaseIds = get(prevState.jobPhaseIdsByJobId, jobId, []);
    const nextJobPhaseIds = uniq(
      get(nextState.idsByJobId, jobId, [])
        .map(jobItemId => get(nextState.byId, jobItemId, []))
        .map(jobItem => getJobPhaseGroupKey(jobItem))
    );

    if (!isEqual(prevJobPhaseIds, nextJobPhaseIds)) {
      set(jobPhaseIdsByJobId, jobId, nextJobPhaseIds);
      hasChanged = true;
    }
  });

  if (!hasChanged) return {};

  return Object.assign(nextState, {
    jobPhaseIdsByJobId: Object.assign(
      {},
      nextState.jobPhaseIdsByJobId,
      jobPhaseIdsByJobId
    )
  });
};
