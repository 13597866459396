import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateQuotes } from "./index";

export const selectQuoteIdsByJobId = createCachedSelector(
  [selectStateQuotes, selectPropsJobId],
  (quotes, jobId) => get(quotes, `idsByJobId.${jobId}`, EMPTY_ARRAY)
)(selectPropsJobId);

export const useQuoteIdsByJobId = jobId =>
  useSelector(state => selectQuoteIdsByJobId(state, { jobId }));
