import type { PopoverOrigin } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { searchWordMatches } from "st-shared/lib";

import { ENTITIES_RECEIVED } from "../../../lib/constants";
import { feToWebOpenCreateTeamMemberModal } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../../redux/helpers/createAction";
import { useJobItemRoleUnselectedOptions } from "../../../state/entities/role/selectors/useJobItemRoleUnselectedOptions";
import { useJobItemUserUnselectedOptions } from "../../../state/entities/user/selectors/useJobItemUserUnselectedOptions";
import { actionUserPreferenceSet } from "../../../state/entities/userPreferences/actions";
import { useUserPreferenceValue } from "../../../state/entities/userPreferences/selectors/selectUserPreferenceValue";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import PopoverMenu from "../Menu/PopoverMenu";
import AvailabilityMenuHeader from "../UserMenus/AvailabilityMenuHeader";
import { GroupedExpander } from "./GroupedExpander";
import * as styles from "./JobItemUserRoleMenu.css";
import RoleResults from "./RoleResults";
import useCloseOnEmptyOptions from "./useCloseOnEmptyOptions";
import { UserResults } from "./UserResults";
import useUserAvailability from "./useUserAvailability";

type NewUserOrRoleMenuProps = {
  anchorEl?: HTMLElement | null;
  entityId: number;
  type: "jobItem" | "jobPhase";
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  pickUser: (userId: number) => void;
  pickRole: (roleId: number) => void;
  close: () => void;
  canCreateNewUser?: boolean;
  showTitle?: boolean;
  fullscreen?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

function JobItemUserRoleMenu({
  anchorEl,
  entityId,
  type,
  defaultStartDate,
  defaultEndDate,
  pickUser,
  pickRole,
  close,
  canCreateNewUser = true,
  showTitle = false,
  fullscreen = false,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: -5,
    horizontal: -5,
  },
}: NewUserOrRoleMenuProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [userIds, setUserIds] = useState<number[]>([]);
  const [roleIds, setRoleIds] = useState<number[]>([]);

  const userOptions = useJobItemUserUnselectedOptions(entityId, type);
  const roleOptions = useJobItemRoleUnselectedOptions(entityId, type);

  const rolesOpen: boolean = useUserPreferenceValue(
    UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES
  );
  const usersOpen: boolean = useUserPreferenceValue(
    UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS
  );

  const dispatch = useDispatch();

  function setRoleOpen(value: boolean) {
    dispatch(
      actionUserPreferenceSet(
        UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES,
        value
      )
    );
  }

  function setUsersOpen(value: boolean) {
    dispatch(
      actionUserPreferenceSet(
        UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS,
        value
      )
    );
  }

  const handleSetRoleIds = (roleId: number) => {
    setRoleIds((prevRoleIds: number[]) => {
      if (prevRoleIds.includes(roleId)) {
        return prevRoleIds.filter((id) => id !== roleId);
      }
      return [...prevRoleIds, roleId];
    });
  };

  const handleSetUserIds = (userId: number) => {
    setUserIds?.((prevUserIds: number[]) => {
      if (prevUserIds.includes(userId)) {
        return prevUserIds.filter((id) => id !== userId);
      }

      const nextUserIds = [...prevUserIds, userId];
      return nextUserIds;
    });
  };

  const userResults =
    searchQuery.length > 0
      ? userOptions.filter((option: any) =>
          searchWordMatches(option.searchString, searchQuery)
        )
      : userOptions;
  const roleResults =
    searchQuery.length > 0
      ? roleOptions.filter((option: any) =>
          searchWordMatches(option.searchString, searchQuery)
        )
      : roleOptions;

  const hasOptions = Boolean(userOptions.length + roleOptions.length);
  const hasResults = Boolean(userResults.length + roleResults.length);

  useCloseOnEmptyOptions(hasOptions, onClose);

  function createNewUser() {
    feToWebOpenCreateTeamMemberModal()
      .then((user: any) => {
        dispatch(createAction(ENTITIES_RECEIVED, { users: [user] }));
        pickUser(user.id);
      })
      .finally(() => onClose());
  }

  function onPickUsers(userIds: number[]) {
    pickUser(userIds[0]);
    setSearchQuery("");
  }

  function onPickRoles(roleIds: number[]) {
    pickRole(roleIds[0]);
    setSearchQuery("");
  }

  function onClose() {
    setSearchQuery("");
    close();
  }

  const { userAvailability, onChangeDateRange, initialFetch } =
    useUserAvailability(defaultStartDate, defaultEndDate);

  return (
    <PopoverMenu
      classes={{
        paper: clsx(
          styles.popoverPaperWidth,
          !fullscreen && styles.popoverPaperHeight
        ),
      }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      HeaderComponent={
        <AvailabilityMenuHeader
          hasOptions={hasOptions}
          hasResults={hasResults}
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search by name, branch or role..."
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          onChange={onChangeDateRange}
          unrestrictDates
          title={showTitle ? "Team Members" : ""}
        />
      }
      onClose={onClose}
      TransitionProps={{
        onEnter: initialFetch,
      }}
    >
      <>
        <GroupedExpander title="ROLES" open={rolesOpen} setOpen={setRoleOpen} />
        {rolesOpen && (
          <RoleResults
            results={roleResults}
            onPickRoles={onPickRoles}
            roleIds={roleIds}
            setRoleIds={setRoleIds}
            type={type}
            onSetRoleIds={handleSetRoleIds}
          />
        )}
        <GroupedExpander
          title="TEAM MEMBERS"
          open={usersOpen}
          setOpen={setUsersOpen}
        />
        {usersOpen && (
          <UserResults
            results={userResults}
            onPickUsers={onPickUsers}
            userIds={userIds}
            setUserIds={setUserIds}
            type={type}
            userAvailability={userAvailability}
            createNewUser={canCreateNewUser ? createNewUser : undefined}
            onSetUserIds={handleSetUserIds}
          />
        )}
      </>
    </PopoverMenu>
  );
}

export default JobItemUserRoleMenu;
