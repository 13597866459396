import { produce } from "immer";
import { put, select } from "redux-saga/effects";

import { JobItemRole } from "st-shared/entities/JobItemRole";
import { JOB_ITEM_EDIT_ITEM_CARD } from "../../../../lib/constants";
import createAction from "../../../../redux/helpers/createAction";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { selectJobItem } from "../../../../redux/selectors/jobItem";
import { selectJobItemUser } from "../../../../redux/selectors/jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../../../../redux/selectors/jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectJobItemRole } from "../../jobItemRole/selectors/selectJobItemRole";
import { selectJobItemRoleIdsByJobItemId } from "../../jobItemRole/selectors/selectJobItemRoleIdsByJobItemId";
import {
  JOB_ITEM_RECALCULATE_HOURS,
  JobItemRoleRecalculateHoursAction,
} from "../actions";

function* recalculateHours(action: JobItemRoleRecalculateHoursAction) {
  try {
    const jobItem: any = yield select(selectJobItem, {
      jobItemId: action.jobItemId,
    });
    const jobItemRoleIds: number[] = yield select(
      selectJobItemRoleIdsByJobItemId,
      { jobItemId: action.jobItemId }
    );
    const jobItemUserIds: number[] = yield select(
      selectJobItemUserIdsByJobItemId,
      { jobItemId: action.jobItemId }
    );

    let totalPlannedMinutes = 0;

    for (let i = 0; i < jobItemRoleIds.length; i++) {
      const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
        jobItemRoleId: jobItemRoleIds[i],
      });
      if (jobItemRole.active) {
        totalPlannedMinutes += jobItemRole.totalPlannedMinutes;
      }
    }

    for (let i = 0; i < jobItemUserIds.length; i++) {
      const jobItemUser: any = yield select(selectJobItemUser, {
        jobItemUserId: jobItemUserIds[i],
      });
      totalPlannedMinutes += jobItemUser.totalPlannedMinutes;
    }

    const newJobItem = produce(jobItem, (draft: any) => {
      draft.totalPlannedMinutes = totalPlannedMinutes;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId: action.jobItemId,
        jobItem: newJobItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export function* getRecalculateDeletedHoursJobItem(
  action: JobItemRoleRecalculateHoursAction,
  deletedJobItemRole: JobItemRole
) {
  try {
    const jobItem: any = yield select(selectJobItem, {
      jobItemId: action.jobItemId,
    });
    const jobItemRoleIds: number[] = yield select(
      selectJobItemRoleIdsByJobItemId,
      { jobItemId: action.jobItemId }
    );
    const jobItemUserIds: number[] = yield select(
      selectJobItemUserIdsByJobItemId,
      { jobItemId: action.jobItemId }
    );

    let totalPlannedMinutes = 0;

    for (let i = 0; i < jobItemRoleIds.length; i++) {
      const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
        jobItemRoleId: jobItemRoleIds[i],
      });
      if (jobItemRole.active && jobItemRole.id !== deletedJobItemRole.id) {
        totalPlannedMinutes += jobItemRole.totalPlannedMinutes;
      }
    }

    for (let i = 0; i < jobItemUserIds.length; i++) {
      const jobItemUser: any = yield select(selectJobItemUser, {
        jobItemUserId: jobItemUserIds[i],
      });
      totalPlannedMinutes += jobItemUser.totalPlannedMinutes;
    }

    const newJobItem = produce(jobItem, (draft: any) => {
      draft.totalPlannedMinutes = totalPlannedMinutes;
    });

    return newJobItem;
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemRecalculateHours() {
  yield takeLatestBy(
    [JOB_ITEM_RECALCULATE_HOURS],
    recalculateHours,
    (action: JobItemRoleRecalculateHoursAction) => action.jobItemId
  );
}
