import { ENTITY_CLASS_QUOTE_LINE_ITEM } from "../constants";
import { getOrderId } from "../ui/commercialDocumentLineItems";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { createModelSetParser } from "./modelSets";

export const saveQuoteLineItemAPI = (quoteLineItem) =>
  API.put(`/quote_line_items/${quoteLineItem.id}`, quoteLineItem)
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);

export const createQuoteLineItemAPI = (quoteLineItem) =>
  API.post(`/quotes/${quoteLineItem.quoteId}/quote_line_items`, {
    ...quoteLineItem,
    orderId: getOrderId(quoteLineItem) - 0.5,
    id: null,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);

export const deleteQuoteLineItemAPI = (id) =>
  API.delete(`/quote_line_items/${id}`)
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);

export const mergeQuoteLineItemsAPI = (quoteLineItem, otherIds) =>
  API.put(`/quote_line_items/${quoteLineItem.id}/merge`, quoteLineItem, {
    other_line_item_ids: otherIds.join(","),
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);

export const undoMergeQuoteLineItemsAPI = (quoteLineItem, otherIds) =>
  API.post(`/quote_line_items/${quoteLineItem.id}/undo_merge`, quoteLineItem, {
    other_line_item_ids: otherIds.join(","),
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);

export const sortQuoteLineItemsAPI = (quoteId, quoteLineItemIds) => {
  return API.put(`/quotes/${quoteId}/quote_line_items/reorder`, null, {
    ids: quoteLineItemIds.join(","),
  })
    .then(({ data }) => data)
    .then(createModelSetParser())
    .then(({ data }) => data);
};

export function createJobItemQuoteLineItemAPI(quoteId, jobItemId, orderId) {
  return API.post(`/quotes/${quoteId}/job_item_quote_line_items`, {
    job_item_id: jobItemId,
    order_id: orderId,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);
}

export function createLoggedExpenseQuoteLineItemAPI(
  quoteId,
  loggedExpenseId,
  orderId
) {
  return API.post(`/quotes/${quoteId}/logged_expense_quote_line_items`, {
    logged_expense_id: loggedExpenseId,
    order_id: orderId,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_QUOTE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_QUOTE_LINE_ITEM))
    .then(({ data }) => data);
}
