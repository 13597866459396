import { Entity } from "@streamtimefe/entities";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { itemPricingMethodObj } from "st-shared/entities/ItemPricingMethod";
import { masterLoggedExpenseObj } from "st-shared/entities/MasterLoggedExpense";
import {
  applyExchangeRate,
  applyInverseExchangeRate,
  convertNewLinesToHtml,
} from "st-shared/lib";

import {
  PURCHASE_ORDER_LINE_ITEM_ADD_ITEM,
  PURCHASE_ORDER_LINE_ITEM_ADD_LINE,
  PURCHASE_ORDER_LINE_ITEM_CREATE,
} from "../../../lib/constants";
import { purchaseOrderLineItemDefaults } from "../../../lib/entities/purchaseOrderLineItemEntity";
import { asDecimal } from "../../../lib/math";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../../lib/ui/accountingPlatform";
import {
  calculateMarkedUpTotalWithExchangeRate,
  calculateMarkupWithExchangeRate,
  calculateTotalFromQuantityByRate,
  getDefaultName,
} from "../../../lib/ui/commercialDocumentLineItems";
import { sendSetHeadingsVisible } from "../../../lib/WebAppAPI/commercialDocuments";
import { selectMasterLoggedExpenseRate } from "../../../state/entities/itemRate/selectors/selectMasterLoggedExpenseRate";
import { selectMasterLoggedExpense } from "../../../state/entities/masterLoggedExpense/selectors/selectMasterLoggedExpense";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLineItemDefaultAccountCodeAndTaxRate } from "../../selectors/commercialDocumentSelectors";
import {
  selectLoggedExpenseExchangeRateByLoggedExpenseId,
  selectLoggedExpenseJobBranchIdById,
  selectLoggedExpenseJobExchangeRateByLoggedExpenseId,
  selectLoggedExpenseJobRateCardIdByLoggedExpenseId,
  selectLoggedExpenseUi,
} from "../../selectors/loggedExpenseSelectors";

function* addLine(action) {
  try {
    const { purchaseOrderId, orderId, isHeading } = action.payload;

    const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

    const id = Entity.temporaryId();

    const purchaseOrderLineItem = {
      ...purchaseOrderLineItemDefaults,
      id,
      purchaseOrderId,
      isHeading,
      orderId,
      name: getDefaultName(isHeading),
    };

    if (!isHeading) {
      const branchId = yield select(selectLoggedExpenseJobBranchIdById, {
        id: loggedExpenseId,
      });

      const { accountCode, taxName, taxRate, externalTaxRateId } = yield select(
        selectLineItemDefaultAccountCodeAndTaxRate,
        {
          branchId,
          accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES,
        }
      );

      Object.assign(purchaseOrderLineItem, {
        accountCode,
        taxName,
        taxRate,
        externalTaxRateId,
      });
    } else {
      yield call(sendSetHeadingsVisible);
    }

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_CREATE, {
        id,
        purchaseOrderLineItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* addItem(action) {
  try {
    const { purchaseOrderId, orderId, masterLoggedExpenseId } = action.payload;

    const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

    const masterLoggedExpense = yield select(selectMasterLoggedExpense, {
      id: masterLoggedExpenseId,
    });

    const rateCardId = yield select(
      selectLoggedExpenseJobRateCardIdByLoggedExpenseId,
      {
        loggedExpenseId,
      }
    );

    const jobExchangeRate = yield select(
      selectLoggedExpenseJobExchangeRateByLoggedExpenseId,
      {
        loggedExpenseId,
      }
    );

    const loggedExpenseExchangeRate = yield select(
      selectLoggedExpenseExchangeRateByLoggedExpenseId,
      {
        loggedExpenseId,
      }
    );

    const homeCurrencyCostRate =
      masterLoggedExpenseObj(masterLoggedExpense).getCostRate();
    const jobCurrencyCostRate = applyInverseExchangeRate(
      homeCurrencyCostRate,
      jobExchangeRate
    );
    const loggedExpenseCurrencyCostRate = applyExchangeRate(
      jobCurrencyCostRate,
      loggedExpenseExchangeRate
    );
    const itemRate = yield select(selectMasterLoggedExpenseRate, {
      masterLoggedExpenseId,
      rateCardId,
    });
    const itemPricingMethodId = masterLoggedExpense.itemPricingMethod.id;
    const unitRate = loggedExpenseCurrencyCostRate || null;
    const totalAmountExTax = calculateTotalFromQuantityByRate(1, unitRate);
    let markup = 0;
    let jobCurrencySellRateExTax = 0;

    if (
      itemPricingMethodObj(masterLoggedExpense.itemPricingMethod).isUnitPrice()
    ) {
      jobCurrencySellRateExTax = itemRate;
      markup = calculateMarkupWithExchangeRate(
        unitRate,
        jobCurrencySellRateExTax,
        loggedExpenseExchangeRate
      );
    } else {
      markup = asDecimal(itemRate);
      jobCurrencySellRateExTax = calculateMarkedUpTotalWithExchangeRate(
        unitRate,
        markup,
        loggedExpenseExchangeRate
      );
    }

    const jobCurrencyTotalSellExTax = jobCurrencySellRateExTax;
    const description = `<p>${convertNewLinesToHtml(
      masterLoggedExpense.description
    )}</p>`;
    const name = masterLoggedExpense.name;

    const branchId = yield select(selectLoggedExpenseJobBranchIdById, {
      id: loggedExpenseId,
    });

    const { accountCode, taxName, taxRate, externalTaxRateId } = yield select(
      selectLineItemDefaultAccountCodeAndTaxRate,
      {
        branchId,
        masterItem: masterLoggedExpense,
        accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES,
      }
    );

    const id = Entity.temporaryId();

    const purchaseOrderLineItem = {
      ...purchaseOrderLineItemDefaults,
      id,
      purchaseOrderId,
      orderId,
      name,
      description,
      itemPricingMethodId,
      unitRate,
      markup,
      totalAmountExTax,
      jobCurrencySellRateExTax,
      jobCurrencyTotalSellExTax,
      accountCode,
      taxName,
      taxRate,
      externalTaxRateId,
    };

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_CREATE, {
        id,
        purchaseOrderLineItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchPurchaseOrderLineItemAdd() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_ADD_LINE, addLine);
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_ADD_ITEM, addItem);
}
