import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";

import {
  deleteJobItemAPI,
  updateJobItemAPI,
} from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_DETAILS_FETCH_HISTORICAL_SUMMARY,
  JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY,
  JOB_ITEM_DELETE,
  JOB_ITEM_DELETE_CONFIRM,
  JOB_ITEM_DELETE_ERROR,
  JOB_ITEM_DELETED,
  JOB_ITEM_DELETING,
} from "../../../lib/constants";
import { getTotalUsedMinutes } from "../../../lib/entities/jobItemEntity";
import { getId } from "../../../lib/objects";
import { feToWebConfirmDeleteJobItem } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectAllJobItemDependanciesByJobItemId } from "../../selectors/jobItemDependancySelectors";
import {
  selectJobItemUserById,
  selectJobItemUserIdsByJobItemId,
} from "../../selectors/jobItemUserSelectors";

function* deleteJobItem(action) {
  const { jobItem, jobItemDependancies = [] } = action.payload;

  const jobItemId = jobItem.id;

  const jobItemUsers = [];

  try {
    yield put(
      createAction(JOB_ITEM_DELETING, {
        jobItemId,
      })
    );

    if (jobItemId > 0) {
      const jobItemUserIds = yield select(selectJobItemUserIdsByJobItemId, {
        id: jobItemId,
      });

      for (let i = 0; i < jobItemUserIds.length; i += 1) {
        const jobItemUser = yield select(selectJobItemUserById, {
          id: jobItemUserIds[i],
        });

        if (getTotalUsedMinutes(jobItemUser) > 0) {
          jobItemUsers.push(jobItemUser);
        }
      }

      // if jobItemDependancies exists should delete
      if (jobItemDependancies.length > 0) {
        const { data } = yield call(
          updateJobItemAPI,
          jobItem,
          null,
          jobItemDependancies.map(getId)
        );

        yield put(
          createAction(ENTITIES_RECEIVED, { ...data, jobItems: undefined })
        );
      }
    }

    const { data } = yield call(deleteJobItemAPI, jobItemId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_DELETED, {
        jobItemId,
      })
    );

    if (jobItemId > 0) {
      for (let i = 0; i < jobItemUsers.length; i += 1) {
        const jobItemUser = jobItemUsers[i];

        if (getTotalUsedMinutes(jobItemUser) > 0) {
          yield put(
            createAction(JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY, {
              jobItemUser,
            })
          );
        }
      }

      yield put(
        createAction(JOB_DETAILS_FETCH_HISTORICAL_SUMMARY, {
          jobId: jobItem.jobId,
        })
      );
    }
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_DELETE_ERROR, {
        jobItemId,
        jobItem,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

function* deleteJobItemConfirm(action) {
  const { jobItem } = action.payload;

  try {
    const jobItemDependancies = yield select(
      selectAllJobItemDependanciesByJobItemId,
      {
        jobItemId: jobItem.id,
      }
    );

    const hasLoggedTime = getTotalUsedMinutes(jobItem) > 0;

    yield call(
      feToWebConfirmDeleteJobItem,
      hasLoggedTime,
      jobItemDependancies.filter(({ id }) => id > 0).length > 0
    );

    yield put(
      createAction(JOB_ITEM_DELETE, {
        jobItem,
        jobItemDependancies: jobItemDependancies.filter(({ id }) => id > 0),
      })
    );
  } catch (error) {
    if (isError(error)) sagaError(error);
  }
}

export default function* watchDeleteJobItem() {
  yield takeLatestBy(
    JOB_ITEM_DELETE_CONFIRM,
    deleteJobItemConfirm,
    (action) => action.payload.jobItem.id
  );
  yield takeLatestBy(
    JOB_ITEM_DELETE,
    deleteJobItem,
    (action) => action.payload.jobItem.id
  );
}
