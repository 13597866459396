import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectJobPhaseOrderByJobId } from "../../../redux/selectors/jobItemSelectors";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import ScheduleExtraHours from "./ScheduleExtraHours";
import ScheduleJobPhase from "./ScheduleJobPhase";

const mapState = (state, props) => ({
  jobPhaseIds: selectJobPhaseOrderByJobId(state, props),
});

const mapDispatch = null;

class ScheduleJobPhases extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    jobPhaseIds: entityIdListType.isRequired,
  };

  render() {
    const { jobId, jobPhaseIds } = this.props;

    return (
      <>
        {jobPhaseIds.length > 0 ? (
          jobPhaseIds.map((jobPhaseId) => (
            <ScheduleJobPhase key={jobPhaseId} id={jobPhaseId} jobId={jobId} />
          ))
        ) : (
          <EmptyRow />
        )}
        <ScheduleExtraHours id={jobId} />
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobPhases);

const EmptyRow = styled.div`
  width: 100%;
  height: ${ScheduleUi.Job.EmptyItemsRowHeight}px;
  &:not(:last-child) {
    border-bottom: var(--border-thin-bright);
  }
`;
