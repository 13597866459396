import type { IconProps } from "@streamtimefe/ui/components";
import {
  BaseButton,
  Button,
  Popover,
  PopoverTrigger,
  TextField,
  Tooltip,
  TooltipTrigger,
} from "@streamtimefe/ui/components";
import {
  IconBlockquote,
  IconBold,
  IconBulletedList,
  IconCode,
  IconCodeBlock,
  IconH1,
  IconH2,
  IconH3,
  IconItalic,
  IconLink,
  IconOrderedList,
  IconUnderline,
  IconUnlink,
} from "@streamtimefe/ui/icons";
import { altOrOption, controlOrCommand } from "@streamtimefe/utils";
import type { Editor } from "@tiptap/core";
import { AnimatePresence, motion } from "motion/react";
import prependHttp from "prepend-http";
import type { PropsWithChildren, ReactElement } from "react";
import { cloneElement, useState } from "react";

import * as styles from "./MenuActions.css";

type ActionButtonProps = {
  name: string;
  icon: ReactElement<IconProps>;
  onAction?: () => void;
  isActive?: boolean;
  isTooltipDisabled?: boolean;
  shortcut: string[] | null;
};

function ActionButton({
  name,
  icon,
  onAction,
  isActive,
  isTooltipDisabled,
  shortcut,
}: ActionButtonProps) {
  const iconElement = cloneElement(icon, { size: 16 });
  return (
    <TooltipTrigger delay={500} isDisabled={isTooltipDisabled}>
      <BaseButton
        className={styles.actionButton}
        aria-label={name}
        onPress={onAction}
        data-active={isActive}
      >
        {iconElement}
      </BaseButton>
      <Tooltip className={styles.actionButtonTooltip}>
        {name}
        {shortcut && (
          <div className={styles.actionButtonTooltipShortcuts}>
            {shortcut.map((value, index) => (
              <span key={`${value}_${index}`}>{value}</span>
            ))}
          </div>
        )}
      </Tooltip>
    </TooltipTrigger>
  );
}
export function ActionDivider() {
  return <div className={styles.divider} />;
}

type ActionProps = {
  editor: Editor | null;
};
export function BoldAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Bold"
      icon={<IconBold />}
      onAction={() => editor?.chain().focus().toggleBold().run()}
      isActive={editor?.isActive("bold")}
      shortcut={[controlOrCommand(), "b"]}
    />
  );
}

export function ItalicAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Italic"
      icon={<IconItalic />}
      onAction={() => editor?.chain().focus().toggleItalic().run()}
      isActive={editor?.isActive("italic")}
      shortcut={[controlOrCommand(), "i"]}
    />
  );
}

export function UnderlineAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Underline"
      icon={<IconUnderline />}
      onAction={() => editor?.chain().focus().toggleUnderline().run()}
      isActive={editor?.isActive("underline")}
      shortcut={[controlOrCommand(), "u"]}
    />
  );
}

export function CodeAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Code"
      icon={<IconCode />}
      onAction={() => editor?.chain().focus().toggleCode().run()}
      isActive={editor?.isActive("code")}
      shortcut={[controlOrCommand(), "e"]}
    />
  );
}

export function NumberListAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Numbered list"
      icon={<IconOrderedList />}
      onAction={() => editor?.chain().focus().toggleOrderedList().run()}
      isActive={editor?.isActive("orderedList")}
      shortcut={[controlOrCommand(), "shift", "7"]}
    />
  );
}

export function BulletListAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Bulleted list"
      icon={<IconBulletedList />}
      onAction={() => editor?.chain().focus().toggleBulletList().run()}
      isActive={editor?.isActive("bulletList")}
      shortcut={[controlOrCommand(), "shift", "8"]}
    />
  );
}

export function CodeBlockAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Code block"
      icon={<IconCodeBlock />}
      onAction={() => editor?.chain().focus().toggleCodeBlock().run()}
      isActive={editor?.isActive("codeBlock")}
      shortcut={[controlOrCommand(), altOrOption(), "c"]}
    />
  );
}

export function BlockQuoteAction({ editor }: ActionProps) {
  return (
    <ActionButton
      name="Blockquote"
      icon={<IconBlockquote />}
      onAction={() => editor?.chain().focus().toggleBlockquote().run()}
      isActive={editor?.isActive("blockquote")}
      shortcut={[controlOrCommand(), "shift", "b"]}
    />
  );
}

export function H1Action({ editor }: ActionProps) {
  return (
    <ActionButton
      name="H1"
      icon={<IconH1 />}
      onAction={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
      isActive={editor?.isActive("heading", { level: 1 })}
      shortcut={[controlOrCommand(), altOrOption(), "1"]}
    />
  );
}

export function H2Action({ editor }: ActionProps) {
  return (
    <ActionButton
      name="H2"
      icon={<IconH2 />}
      onAction={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
      isActive={editor?.isActive("heading", { level: 2 })}
      shortcut={[controlOrCommand(), altOrOption(), "2"]}
    />
  );
}

export function H3Action({ editor }: ActionProps) {
  return (
    <ActionButton
      name="H3"
      icon={<IconH3 />}
      onAction={() => editor?.chain().focus().toggleHeading({ level: 3 }).run()}
      isActive={editor?.isActive("heading", { level: 3 })}
      shortcut={[controlOrCommand(), altOrOption(), "3"]}
    />
  );
}

//////

export function LinkAction({ editor }: ActionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AnimatePresence initial={false}>
        {(!editor?.view.state.selection.empty || editor?.isActive("link")) && (
          <PopoverTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
            <AnimateDiv>
              <ActionButton
                name="Link"
                icon={<IconLink />}
                isActive={editor?.isActive("link")}
                isTooltipDisabled={isOpen}
                shortcut={null}
              />
            </AnimateDiv>
            <Popover
              placement="top"
              variant="light"
              className={styles.actionButtonLinkPopover}
              slotProps={{
                dialog: { className: styles.actionButtonLinkPopoverDialog },
              }}
            >
              <LinkPopover editor={editor} onClose={() => setIsOpen(false)} />
            </Popover>
          </PopoverTrigger>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {editor?.isActive("link") && (
          <AnimateDiv>
            <ActionButton
              name="Unlink"
              icon={<IconUnlink />}
              onAction={() => editor?.chain().focus().unsetLink().run()}
              shortcut={null}
            />
          </AnimateDiv>
        )}
      </AnimatePresence>
    </>
  );
}

function AnimateDiv({ children }: PropsWithChildren) {
  return (
    <motion.div
      initial="hide"
      animate="open"
      exit="hide"
      variants={{
        open: {
          opacity: 1,
          width: "auto",
          marginLeft: 0,
          transition: { duration: 0.2, ease: "easeInOut" },
        },
        hide: {
          opacity: 0,
          width: 0,
          marginLeft: -8,
          transition: { duration: 0.2, ease: "easeInOut" },
        },
      }}
    >
      {children}
    </motion.div>
  );
}

function LinkPopover({
  editor,
  onClose,
}: ActionProps & { onClose: () => void }) {
  const [url, setUrl] = useState(editor?.getAttributes("link").href ?? "");

  function onSaveLink() {
    if (url === "") {
      // delete link
      editor?.chain().focus().unsetLink().run();
    } else {
      // update link
      editor
        ?.chain()
        .focus()
        .setLink({ href: prependHttp(url) })
        .run();
    }
    onClose();
  }

  return (
    <>
      <TextField
        label="Link"
        placeholder="Enter a URL"
        value={url}
        onChange={setUrl}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSaveLink();
        }}
        autoFocus
      />
      <div className={styles.actionButtonLinkPopoverDialogButtons}>
        <Button onPress={onClose} variant="secondary" size="small">
          Cancel
        </Button>
        <Button onPress={onSaveLink} size="small">
          Save
        </Button>
      </div>
    </>
  );
}
