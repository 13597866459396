import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { getRoleIdRateCardIdKeyPath } from "../../../lib/entities/roleRateEntity";
import { selectPropsRateCardId, selectPropsRoleId } from "../index";
import { selectStateRoleRates } from "./index";

export const selectRoleRateByRoleIdRateCardId = createCachedSelector(
  [selectStateRoleRates, selectPropsRoleId, selectPropsRateCardId],
  (roleRates, roleId, rateCardId) =>
    get(
      roleRates,
      `rateByRoleIdRateCardId.${getRoleIdRateCardIdKeyPath({
        roleId,
        rateCardId,
      })}`
    )
)((state, props) => `${props.roleId}-${props.rateCardId}`);

export const useRoleRateByRoleIdRateCardId = (roleId, rateCardId) =>
  useSelector((state) =>
    selectRoleRateByRoleIdRateCardId(state, {
      roleId,
      rateCardId,
    })
  );
