import * as PropTypes from "prop-types";
import React from "react";
import { KEY_ARROW_LEFT, KEY_ARROW_RIGHT } from "../../../lib/constants";
import { isFormElement, isPortal } from "../../../lib/eventTargets";
import {
  addKeyEventListener,
  removeKeyEventListener
} from "../../../lib/events";

export default WrappedComponent =>
  class KeyHandlers extends React.PureComponent {
    static propTypes = {
      goForward: PropTypes.func.isRequired,
      goBackward: PropTypes.func.isRequired
    };

    componentDidMount() {
      addKeyEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
      removeKeyEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = e => {
      const { goForward, goBackward } = this.props;

      if (isPortal(e) || isFormElement(e)) return false;

      if (e.code === KEY_ARROW_LEFT) return goBackward();

      if (e.code === KEY_ARROW_RIGHT) return goForward();

      return false;
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
