import { produce } from "immer";
import { has, isEqual } from "lodash-es";
import { runSaga } from "redux-saga";
import { call } from "redux-saga/effects";
import { asyncDelay, asyncTakeLatest, isCanceledError } from "st-shared/lib";
import type {
  ReportingSearchData,
  SearchSeriesApiReturn,
  TReportingSearchType,
} from "st-shared/lib/webapi/reporting";
import {
  getReportingSearchSeriesApi,
  ReportingSearchType,
  searchColumnSeries,
  searchTimeSeries,
} from "st-shared/lib/webapi/reporting";
import type { SagaEffect } from "st-shared/stores/sagaHelpers";
import { sagaError, takeLatestBy } from "st-shared/stores/sagaHelpers";
import { sagaGlobalChannel } from "st-shared/stores/sagaHelpers/sagaGlobalChannel";
import type { SeriesData } from "st-shared/types";
import type { StateCreator } from "zustand";

import type {
  KeyedMatrixSeriesData,
  KeyedVectorSeriesData,
  MatrixSeriesData,
  VectorSeriesData,
} from "../../lib/VectorMatrixSeriesData";
import {
  getCalculatedMatrixSeriesData,
  getCalculatedMatrixSeriesTotals,
  getCalculatedVectorSeriesData,
  getCalculatedVectorSeriesTotals,
} from "../../lib/VectorMatrixSeriesData";
import type { ReportingStoreState } from "./reportingStore";
import { reportingStore } from "./reportingStore";

type SeriesSearch = {
  fetching: boolean;
  error?: unknown;
  expanded: boolean;
};

type ColumnSeriesSearch = SeriesSearch & {
  calculatedData: KeyedVectorSeriesData[];
  totals: VectorSeriesData;
};

type TimeSeriesSearch = SeriesSearch & {
  calculatedData: KeyedMatrixSeriesData[];
  totals: VectorSeriesData & MatrixSeriesData;
  bucketStartDate: string;
  bucketEndDate: string;
  bucketKeys: string[];
};

const SearchSagaActions = {
  fetchSubRowSeries: "reporting/fetchSubRowSeries",
} as const;

type SearchSagaEffects = {
  fetchSubRowSeries: SagaEffect<
    (typeof SearchSagaActions)["fetchSubRowSeries"],
    { id: string; key: string | null }
  >;
};

type SearchTimeSeriesReponse = Awaited<
  ReturnType<typeof searchTimeSeries>
>["data"];

type SearchColumnSeriesReponse = Awaited<
  ReturnType<typeof searchColumnSeries>
>["data"];

type TimeSeriesCache = {
  request: ReportingSearchData | null;
  response: SearchTimeSeriesReponse;
};

type ColumnSeriesCache = {
  request: ReportingSearchData | null;
  response: SearchColumnSeriesReponse;
};

export type SearchSlice = {
  search: {
    timeSeries: TimeSeriesSearch;
    columnSeries: ColumnSeriesSearch;
    timeSubSeries: Record<string, Omit<TimeSeriesSearch, "totals">>;
    columnSubSeries: Record<string, Omit<ColumnSeriesSearch, "totals">>;
    cache: {
      time: TimeSeriesCache;
      column: ColumnSeriesCache;
    };
    actions: {
      reset: () => void;
      resetTimeSeries: () => void;
      resetColumnSeries: () => void;
      resetSubSeries: () => void;
      completeTimeSeries: () => void;
      completeColumnSeries: () => void;
      fetchSeries: () => void;
      fetchTimeSeries: () => Promise<void>;
      fetchColumnSeries: () => Promise<void>;
      processTimeSeries: (response: SearchTimeSeriesReponse) => void;
      processColumnSeries: (response: SearchColumnSeriesReponse) => void;
      fetchTimeSeriesError: (error: unknown) => void;
      fetchColumnSeriesError: (error: unknown) => void;
      fetchSubSeries: (id: string, key: string | null) => void;
      fetchSubSeriesSuccess: (
        id: string,
        response: {
          seriesData: SeriesData[];
          seriesTotals: SeriesData["data"];
        },
        searchType: TReportingSearchType
      ) => void;
      fetchSubSeriesError: (
        id: string,
        searchType: TReportingSearchType,
        error: unknown
      ) => void;
      subSeriesExpand: (
        id: string,
        key: string | null,
        expanded: boolean
      ) => void;
    };
    sagas: {
      fetchSubRowSeries: (
        effect: SearchSagaEffects["fetchSubRowSeries"]
      ) => Generator<any, void, SearchSeriesApiReturn>;
    };
  };
};

function getColumnSeriesSearchDefault(): ColumnSeriesSearch {
  return {
    fetching: true,
    error: null,
    expanded: false,
    calculatedData: [],
    totals: { vector: {} },
  };
}

function getTimeSeriesSearchDefault(): TimeSeriesSearch {
  return {
    fetching: true,
    error: null,
    expanded: false,
    calculatedData: [],
    totals: { vector: {}, matrix: {} },
    bucketStartDate: "",
    bucketEndDate: "",
    bucketKeys: [],
  };
}

function getColumnSeriesCacheDefault(): ColumnSeriesCache {
  return {
    request: null,
    response: {
      results: [],
      seriesTotals: {},
    },
  };
}

function getTimeSeriesCacheDefault(): TimeSeriesCache {
  return {
    request: null,
    response: {
      results: [],
      seriesTotals: {},
      bucketStartDate: "",
      bucketEndDate: "",
      bucketKeys: {},
    },
  };
}

export const createSearchSlice: StateCreator<
  ReportingStoreState,
  [],
  [],
  SearchSlice
> = (set, get) => ({
  search: {
    timeSeries: getTimeSeriesSearchDefault(),
    columnSeries: getColumnSeriesSearchDefault(),
    timeSubSeries: {},
    columnSubSeries: {},
    cache: {
      time: getTimeSeriesCacheDefault(),
      column: getColumnSeriesCacheDefault(),
    },
    actions: {
      reset() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSeries = getTimeSeriesSearchDefault();
            draft.search.columnSeries = getColumnSeriesSearchDefault();
            draft.search.timeSubSeries = {};
            draft.search.columnSubSeries = {};
          })
        );
      },

      resetTimeSeries() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSeries = getTimeSeriesSearchDefault();
          })
        );
      },

      resetColumnSeries() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.columnSeries = getColumnSeriesSearchDefault();
          })
        );
      },

      resetSubSeries() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSubSeries = {};
            draft.search.columnSubSeries = {};
          })
        );
      },

      completeTimeSeries() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSeries.error = null;
            draft.search.timeSeries.fetching = false;
          })
        );
      },

      completeColumnSeries() {
        set((s) =>
          produce(s, (draft) => {
            draft.search.columnSeries.error = null;
            draft.search.columnSeries.fetching = false;
          })
        );
      },

      fetchSeries() {
        switch (get().savedSegment.value.searchType) {
          case ReportingSearchType.TimeSeries:
            get().search.actions.fetchTimeSeries();
            break;
          case ReportingSearchType.ColumnSeries:
            get().search.actions.fetchColumnSeries();
            break;
        }
      },

      fetchTimeSeries: asyncTakeLatest(async function (signal: AbortSignal) {
        try {
          get().search.actions.resetTimeSeries();

          const delayMs =
            document.activeElement instanceof HTMLInputElement ? 200 : 10;
          await asyncDelay(delayMs, signal);

          const reportingSavedSegment =
            get().savedSegment.helpers.getReportingSavedSegment();

          if (
            Object.values(reportingSavedSegment.object.dataSets).length === 0 ||
            Object.values(reportingSavedSegment.object.columns).length === 0
          ) {
            set((s) =>
              produce(s, (draft) => {
                draft.search.cache.time = getTimeSeriesCacheDefault();
              })
            );

            get().search.actions.processTimeSeries(
              get().search.cache.time.response
            );

            get().search.actions.completeTimeSeries();
            return;
          }

          const request = reportingSavedSegment.createSearchData();

          if (!isEqual(get().search.cache.time.request, request)) {
            const response = (await searchTimeSeries(request, { signal })).data;

            set((s) =>
              produce(s, (draft) => {
                draft.search.cache.time = { request, response };
              })
            );
          }

          get().search.actions.processTimeSeries(
            get().search.cache.time.response
          );

          get().search.actions.completeTimeSeries();
        } catch (error) {
          if (isCanceledError(error)) {
            return undefined as any;
          }

          get().search.actions.fetchTimeSeriesError(error);

          sagaError(error);
        }
      }),

      fetchColumnSeries: asyncTakeLatest(async function (signal: AbortSignal) {
        try {
          get().search.actions.resetColumnSeries();

          const delayMs =
            document.activeElement instanceof HTMLInputElement ? 200 : 10;
          await asyncDelay(delayMs, signal);

          const reportingSavedSegment =
            get().savedSegment.helpers.getReportingSavedSegment();

          if (
            Object.values(reportingSavedSegment.object.dataSets).length === 0 ||
            Object.values(reportingSavedSegment.object.columns).length === 0
          ) {
            set((s) =>
              produce(s, (draft) => {
                draft.search.cache.column = getColumnSeriesCacheDefault();
              })
            );

            get().search.actions.processColumnSeries(
              get().search.cache.column.response
            );

            get().search.actions.completeColumnSeries();
            return;
          }

          const request = reportingSavedSegment.createSearchData();

          if (!isEqual(get().search.cache.column.request, request)) {
            const response = (await searchColumnSeries(request, { signal }))
              .data;

            set((s) =>
              produce(s, (draft) => {
                draft.search.cache.column = { request, response };
              })
            );
          }

          get().search.actions.processColumnSeries(
            get().search.cache.column.response
          );

          get().search.actions.completeColumnSeries();
        } catch (error) {
          if (isCanceledError(error)) {
            return undefined as any;
          }

          get().search.actions.fetchColumnSeriesError(error);

          sagaError(error);
        }
      }),

      processTimeSeries(response: SearchTimeSeriesReponse) {
        get().savedSegment.actions.calculateColumnMetadata();
        const savedSegmentValue = get().savedSegment.value;
        const columnMetadata = get().savedSegment.columnMetadata;
        const bucketKeys = Object.values(response.bucketKeys);

        const calculatedData = getCalculatedMatrixSeriesData(
          response.results,
          savedSegmentValue,
          savedSegmentValue.filterGroupType,
          bucketKeys,
          columnMetadata
        );

        const vectorTotals = getCalculatedVectorSeriesTotals(
          response.seriesTotals,
          savedSegmentValue,
          columnMetadata
        );

        const matrixTotals = getCalculatedMatrixSeriesTotals(
          calculatedData,
          savedSegmentValue,
          bucketKeys,
          columnMetadata
        );

        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSeries.calculatedData = calculatedData;
            draft.search.timeSeries.totals = {
              vector: vectorTotals,
              matrix: matrixTotals,
            };
            draft.search.timeSeries.bucketStartDate = response.bucketStartDate;
            draft.search.timeSeries.bucketEndDate = response.bucketEndDate;
            draft.search.timeSeries.bucketKeys = bucketKeys;
          })
        );
      },

      processColumnSeries(response: SearchColumnSeriesReponse) {
        get().savedSegment.actions.calculateColumnMetadata();
        const savedSegmentValue = get().savedSegment.value;
        const columnMetadata = get().savedSegment.columnMetadata;

        const calculatedData = getCalculatedVectorSeriesData(
          response.results,
          savedSegmentValue,
          savedSegmentValue.filterGroupType,
          columnMetadata
        );

        const vectorTotals = getCalculatedVectorSeriesTotals(
          response.seriesTotals,
          savedSegmentValue,
          columnMetadata
        );

        set((s) =>
          produce(s, (draft) => {
            draft.search.columnSeries.calculatedData = calculatedData;
            draft.search.columnSeries.totals = { vector: vectorTotals };
          })
        );
      },

      fetchTimeSeriesError(error: unknown) {
        set((s) =>
          produce(s, (draft) => {
            draft.search.timeSeries.error = error;
            draft.search.timeSeries.fetching = false;
          })
        );
      },

      fetchColumnSeriesError(error: unknown) {
        set((s) =>
          produce(s, (draft) => {
            draft.search.columnSeries.error = error;
            draft.search.columnSeries.fetching = false;
          })
        );
      },

      fetchSubSeries(id: string, key: string | null) {
        set((s) =>
          produce(s, (draft) => {
            switch (get().savedSegment.value.searchType) {
              case ReportingSearchType.TimeSeries:
                draft.search.timeSubSeries[id] = getTimeSeriesSearchDefault();
                break;
              case ReportingSearchType.ColumnSeries:
                draft.search.columnSubSeries[id] =
                  getColumnSeriesSearchDefault();
                break;
            }
          })
        );

        sagaGlobalChannel.put({
          type: SearchSagaActions.fetchSubRowSeries,
          payload: { id, key },
        } as SearchSagaEffects["fetchSubRowSeries"]);
      },

      fetchSubSeriesSuccess(
        id: string,
        response: {
          seriesData: SeriesData[];
          seriesTotals: SeriesData["data"];
        },
        searchType: TReportingSearchType
      ) {
        const savedSegmentValue = get().savedSegment.value;
        const columnMetadata = get().savedSegment.columnMetadata;
        const bucketKeys = get().search.timeSeries.bucketKeys;

        switch (searchType) {
          case ReportingSearchType.TimeSeries:
            {
              const calculatedData = getCalculatedMatrixSeriesData(
                response.seriesData,
                savedSegmentValue,
                savedSegmentValue.subFilterGroupType,
                bucketKeys,
                columnMetadata
              );

              set((s) =>
                produce(s, (draft) => {
                  if (!has(get().search.timeSubSeries, id)) {
                    draft.search.timeSubSeries[id] =
                      getTimeSeriesSearchDefault();
                  }

                  draft.search.timeSubSeries[id].error = null;
                  draft.search.timeSubSeries[id].fetching = false;
                  draft.search.timeSubSeries[id].calculatedData =
                    calculatedData;
                })
              );
            }
            break;
          case ReportingSearchType.ColumnSeries:
            {
              const calculatedData = getCalculatedVectorSeriesData(
                response.seriesData,
                savedSegmentValue,
                savedSegmentValue.subFilterGroupType,
                columnMetadata
              );

              set((s) =>
                produce(s, (draft) => {
                  if (!has(get().search.columnSubSeries, id)) {
                    draft.search.columnSubSeries[id] =
                      getColumnSeriesSearchDefault();
                  }

                  draft.search.columnSubSeries[id].error = null;
                  draft.search.columnSubSeries[id].fetching = false;
                  draft.search.columnSubSeries[id].calculatedData =
                    calculatedData;
                })
              );
            }
            break;
        }
      },

      fetchSubSeriesError(
        id: string,
        searchType: TReportingSearchType,
        error: unknown
      ) {
        set((s) =>
          produce(s, (draft) => {
            switch (searchType) {
              case ReportingSearchType.TimeSeries:
                {
                  if (!has(get().search.timeSubSeries, id)) {
                    draft.search.timeSubSeries[id] =
                      getTimeSeriesSearchDefault();
                  }

                  draft.search.timeSubSeries[id].error = error;
                  draft.search.timeSubSeries[id].fetching = false;
                }
                break;
              case ReportingSearchType.ColumnSeries:
                {
                  if (!has(get().search.columnSubSeries, id)) {
                    draft.search.columnSubSeries[id] =
                      getColumnSeriesSearchDefault();
                  }

                  draft.search.columnSubSeries[id].error = error;
                  draft.search.columnSubSeries[id].fetching = false;
                }
                break;
            }
          })
        );
      },

      subSeriesExpand(id: string, key: string | null, expanded: boolean) {
        switch (get().savedSegment.value.searchType) {
          case ReportingSearchType.TimeSeries:
            {
              if (!has(get().search.timeSubSeries, id)) {
                get().search.actions.fetchSubSeries(id, key);
              }

              set((s) =>
                produce(s, (draft) => {
                  draft.search.timeSubSeries[id].expanded = expanded;
                })
              );
            }
            break;
          case ReportingSearchType.ColumnSeries:
            {
              if (!has(get().search.columnSubSeries, id)) {
                get().search.actions.fetchSubSeries(id, key);
              }

              set((s) =>
                produce(s, (draft) => {
                  draft.search.columnSubSeries[id].expanded = expanded;
                })
              );
            }
            break;
        }
      },
    },
    sagas: {
      fetchSubRowSeries: function* (
        effect: SearchSagaEffects["fetchSubRowSeries"]
      ) {
        const reportingSavedSegment =
          get().savedSegment.helpers.getReportingSavedSegment();
        const searchType = reportingSavedSegment.object.searchType;

        try {
          const timeSeries = get().search.timeSeries;

          const searchSeriesAPI = getReportingSearchSeriesApi(searchType);
          const subSearchData = reportingSavedSegment.createSubSearchData(
            effect.payload.key
          );

          if (
            searchType === ReportingSearchType.TimeSeries &&
            subSearchData.startDate === null &&
            subSearchData.endDate === null
          ) {
            subSearchData.startDate = timeSeries.bucketStartDate;
            subSearchData.endDate = timeSeries.bucketEndDate;
          }

          const { data } = yield call(searchSeriesAPI, subSearchData);

          get().search.actions.fetchSubSeriesSuccess(
            effect.payload.id,
            {
              seriesData: data.results,
              seriesTotals: data.seriesTotals,
            },
            searchType
          );
        } catch (error: unknown) {
          get().search.actions.fetchSubSeriesError(
            effect.payload.id,
            searchType,
            error
          );

          sagaError(error);
        }
      },
    },
  },
});

export function initSearchSagas() {
  runSaga({ channel: sagaGlobalChannel }, function* () {
    yield takeLatestBy(
      SearchSagaActions.fetchSubRowSeries,
      reportingStore().search.sagas.fetchSubRowSeries,
      (action) => action.payload.key || action.payload.id
    );
  });
}
