import { useEffect, useRef } from "react";

function useCloseOnEmptyOptions(hasOptions: boolean, onClose: () => void) {
  const previousHasOptions = useRef<boolean>(hasOptions);

  useEffect(() => {
    if (hasOptions != previousHasOptions.current && !hasOptions) {
      onClose();
    }
    previousHasOptions.current = hasOptions;
  }, [hasOptions]);
}

export default useCloseOnEmptyOptions;
