import { produce } from "immer";
import { get, set, unset } from "lodash-es";

const defaultValueGetter = (entity) => entity && entity.id;

/**
 * @param {function(object)} getKeyPath
 * @param {function(object)} getValue
 * @returns {Function}
 */
export default (getKeyPath, getValue = defaultValueGetter) =>
  (state = {}, changedEntities) =>
    produce(state, (draft) => {
      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);

        if (prevPath && !newPath) unset(draft, prevPath);

        const newValue = newPath && getValue(newEntity);

        if (newValue && newValue !== get(state, newPath))
          set(draft, newPath, newValue);
      });
    });
