import numeral from "numeral";
import { useSelector } from "react-redux";
import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes
} from "../../../../lib/entities/jobItemUserEntity";
import { selectJobItemUser } from "../../jobItemUser";
import { selectOrderedJobItemUserIdsByJobId } from "../../jobItemUser/selectOrderedJobItemUserIdsByJobId";
import { selectUser } from "../../user";
import { selectUnplannedTimeSummary } from "./unplannedTimeSummary";

export const selectJobUsedPlannedTimeHoursSummary = (state, { jobId }) => {
  const jobItemUserIds = selectOrderedJobItemUserIdsByJobId(state, { jobId });
  const unplannedTimeSummary = selectUnplannedTimeSummary(state);

  const users = new Map();

  jobItemUserIds.forEach(jobItemUserId => {
    const jobItemUser = selectJobItemUser(state, { jobItemUserId });
    addUserData(
      users,
      jobItemUser.userId,
      getTotalPlannedMinutes(jobItemUser),
      getTotalUsedMinutes(jobItemUser)
    );
  });

  unplannedTimeSummary.forEach(unplannedTime => {
    addUserData(users, unplannedTime.userId, 0, unplannedTime.totalMinutes);
  });

  const usedPlannedTimeHoursSummary = Array.from(users, ([userId, data]) => {
    const user = selectUser(state, { userId });
    return {
      ...data,
      userId,
      userDisplayName: user.displayName
    };
  });

  return usedPlannedTimeHoursSummary;
};

export const useJobUsedPlannedTimeHoursSummary = jobId =>
  useSelector(state => selectJobUsedPlannedTimeHoursSummary(state, { jobId }));

const addUserData = (users, userId, plannedMinutes, loggedMinutes) => {
  if (!(plannedMinutes > 0 || loggedMinutes > 0)) return;
  if (users.has(userId)) {
    const data = users.get(userId);
    data.totalPlannedMinutes = numeral(data.totalPlannedMinutes)
      .add(numeral(plannedMinutes).value())
      .value();
    data.totalLoggedMinutes = numeral(data.totalLoggedMinutes)
      .add(numeral(loggedMinutes).value())
      .value();
    users.set(userId, data);
  } else {
    users.set(userId, {
      totalPlannedMinutes: plannedMinutes,
      totalLoggedMinutes: loggedMinutes
    });
  }
};
