import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { useEffect } from "react";
import {
  getFilterGroupTypes,
  getSubFilterGroupTypes,
} from "st-shared/entities";

import { getOrderedMap } from "../../lib/orderedMap";
import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentFilterGroupType,
  useReportingSavedSegmentSubFilterGroupType,
} from "../../state/stores/savedSegmentSelectors";
import {
  FilterGroupSelector,
  SubFilterGroupSelector,
} from "./FilterGroupSelector";

export function FilterGroupSelectors() {
  const filterGroupType = useReportingSavedSegmentFilterGroupType();
  const subFilterGroupType = useReportingSavedSegmentSubFilterGroupType();
  const dataSets = useReportingSavedSegmentDataSets();
  const orderedDataSets = getOrderedMap(dataSets);
  const filterGroupTypes = getFilterGroupTypes(orderedDataSets);
  const subFilterGroupTypes = getSubFilterGroupTypes(
    orderedDataSets,
    filterGroupType
  );

  function setFilterGroupType(newFilterGroupType: TFilterGroupTypeEnum | null) {
    reportingStore().savedSegment.actions.setFilterGroupType(
      newFilterGroupType
    );
  }

  function setSubFilterGroupType(newSubFilterGroupType: TFilterGroupTypeEnum) {
    reportingStore().savedSegment.actions.setSubFilterGroupType(
      newSubFilterGroupType
    );
  }

  // TODO: this could inadvertently cause it to always to have saved changes
  useEffect(() => {
    if (subFilterGroupTypes.length > 0) {
      if (!subFilterGroupTypes.includes(subFilterGroupType)) {
        setSubFilterGroupType(subFilterGroupTypes[0]);
      }
    }
  }, [subFilterGroupTypes, subFilterGroupType]);

  useEffect(() => {
    if (filterGroupTypes.length > 0) {
      if (!filterGroupTypes.includes(filterGroupType as TFilterGroupTypeEnum)) {
        setFilterGroupType(filterGroupTypes[0]);
      }
    } else if (filterGroupType !== null) {
      setFilterGroupType(null);
    }
  }, [filterGroupTypes, filterGroupType]);

  if (!filterGroupType) {
    return null;
  }

  return (
    <>
      <FilterGroupSelector
        title="Grouped by"
        filterGroupType={filterGroupType}
        setFilterGroupType={setFilterGroupType}
        filterGroupTypes={filterGroupTypes}
      />
      {filterGroupType && subFilterGroupTypes.length > 0 && (
        <SubFilterGroupSelector
          title="then by"
          filterGroupType={subFilterGroupType}
          setFilterGroupType={setSubFilterGroupType}
          filterGroupTypes={subFilterGroupTypes}
        />
      )}
    </>
  );
}
