import { get } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_JOB_MILESTONES
} from "../../lib/constants";
import { getTodayDate, isAfter, simpleCompareAsc } from "../../lib/dates";
import { getJobIdMonthKeyPath } from "../../lib/entities/jobMilestoneEntity";
import { selectPropsDate, selectPropsId, selectPropsJobId } from "./index";

export const selectJobMilestones = state =>
  state.entities[ENTITY_NAME_JOB_MILESTONES];

export const selectJobMilestoneById = createSelector(
  selectJobMilestones,
  selectPropsId,
  (jobMilestones, id) => get(jobMilestones, `byId.${id}`, EMPTY_OBJECT)
);

export const selectJobMilestoneIdsByJobId = createSelector(
  selectJobMilestones,
  selectPropsJobId,
  (jobMilestones, jobId) =>
    get(jobMilestones, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectSortedJobMilestonesByJobId = createSelector(
  selectJobMilestones,
  selectJobMilestoneIdsByJobId,
  (jobMilestones, jobMilestoneIds) =>
    jobMilestoneIds
      .map(id => get(jobMilestones, `byId.${id}`))
      .slice()
      .sort((jm1, jm2) => simpleCompareAsc(jm1.date, jm2.date))
);

export const selectJobMilestoneIdsByJobIdDate = createSelector(
  selectJobMilestones,
  selectPropsJobId,
  selectPropsDate,
  (jobMilestones, jobId, date) =>
    get(jobMilestones, `idsByJobIdDate["${jobId}.${date}"]`, EMPTY_ARRAY)
);

export const selectJobMilestoneIdsByJobIdMonth = createSelector(
  selectJobMilestones,
  selectPropsJobId,
  selectPropsDate,
  (jobMilestones, jobId, date) =>
    get(
      jobMilestones,
      `idsByJobIdMonth[${getJobIdMonthKeyPath({ jobId, date })}]`,
      EMPTY_ARRAY
    )
);

const initialDateRange = {
  startDate: null,
  endDate: null
};

export const selectJobMilestoneDateRangeByJobId = createSelector(
  selectJobMilestones,
  selectPropsJobId,
  (jobMilestones, jobId) =>
    get(jobMilestones, `dateRangeByJobId.${jobId}`, initialDateRange)
);

export const selectNextJobMilestoneByJobId = createSelector(
  selectSortedJobMilestonesByJobId,
  sortedJobMilestones =>
    sortedJobMilestones.find(jobMilestone =>
      isAfter(jobMilestone.date, getTodayDate())
    )
);

export const selectJobMilestonesUi = state => state.ui.jobMilestones;

export const selectJobMilestoneUi = createSelector(
  selectJobMilestonesUi,
  selectPropsId,
  (jobTimelineMilestones, id) => jobTimelineMilestones[id]
);
