import type {
  TAuthenticationBootstrapResponse,
  TEntityClassMap,
  TEntityId,
  TSyncKeyReplacementSet,
} from "@streamtimefe/entities";
import { EntityClass } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import { uuidAlphaMedium } from "@streamtimefe/utils";
import type { Draft } from "immer";
import { produce } from "immer";
import { isDefined } from "remeda";

import type { TStoreGetter, TStoreSetter } from "../../core";
import type { TEntityStore, TEntityStoreClass } from "./EntityStore";

export type TEntityActions = ReturnType<typeof createEntityActions>;

export type TEntityBulkUpdate = {
  [T in TEntityStoreClass]?: TEntityClassMap[T][];
};

function getSyncKey<T extends TEntityStoreClass>(
  storeEntity?: TEntityClassMap[T],
  entity?: TEntityClassMap[T]
) {
  return (
    storeEntity?.meta_data_sync_key ??
    entity?.meta_data_sync_key ??
    uuidAlphaMedium()
  );
}

function applyEntity<T extends TEntityStoreClass>(
  entity: TEntityClassMap[T],
  syncKey: string,
  isLocal: boolean = false
) {
  let newEntity = entity;
  if (isLocal) {
    newEntity = produce(newEntity, (s) => {
      s.meta_data_is_local = true;
    });
  }

  if (!entity.meta_data_sync_key) {
    newEntity = produce(newEntity, (s) => {
      s.meta_data_sync_key = syncKey;
    });
  }

  return newEntity as Draft<TEntityClassMap[T]>;
}

export function createEntityActions(
  set: TStoreSetter<TEntityStore>,
  _get: TStoreGetter<TEntityStore>
) {
  function authSync(response: TAuthenticationBootstrapResponse) {
    const bulkEntities: TEntityBulkUpdate = {
      [EntityClass.User]: [
        response.authToken.user,
        ...response.bootstrap.users,
      ],
      [EntityClass.Organisation]: [response.bootstrap.organisation],
      [EntityClass.Branch]: response.bootstrap.branches,
      [EntityClass.Role]: response.bootstrap.roles,
      [EntityClass.RateCard]: response.bootstrap.rateCards,
      [EntityClass.MasterJobItem]: response.bootstrap.masterJobItems,
      [EntityClass.MasterLoggedExpense]:
        response.bootstrap.masterLoggedExpenses,
      [EntityClass.TaxRate]: response.bootstrap.taxRates,
      [EntityClass.GoogleCalendar]: response.bootstrap.googleCalendars,
      [EntityClass.AccountingPlatform]: response.bootstrap.accountingPlatforms,
    };

    entityBulkUpdate(bulkEntities);
  }

  function entityBulkUpdate(
    bulkEntities: TEntityBulkUpdate,
    syncKeyReplacementSet: TSyncKeyReplacementSet = {}
  ) {
    set((s) => {
      Object.keys(bulkEntities).forEach((key) => {
        const entityClass = key as TEntityStoreClass;
        const store = s[entityClass];
        if (isDefined(store)) {
          bulkEntities[entityClass]?.forEach((entity) => {
            const replacementSyncKey =
              syncKeyReplacementSet?.[entityClass]?.[entity.id];

            if (replacementSyncKey) {
              const replacementId = store.syncKeyIndex[replacementSyncKey];
              if (replacementId) {
                delete store.syncKeyIndex[replacementSyncKey];
                delete store.byId[replacementId];
              }
            }

            const syncKey =
              replacementSyncKey ?? getSyncKey(store.byId[entity.id], entity);
            store.syncKeyIndex[syncKey] = entity.id;
            store.byId[entity.id] = applyEntity(entity, syncKey);
          });
        }
      });
    });
  }

  function entityUpdate<T extends TEntityStoreClass>(
    entityClass: T,
    entity: TEntityClassMap[T],
    isLocal: boolean
  ) {
    set((s) => {
      const store = s[entityClass];
      if (isDefined(store)) {
        const syncKey = getSyncKey(
          store.byId[entity.id] as TEntityClassMap[T],
          entity
        );
        store.syncKeyIndex[syncKey] = entity.id;
        store.byId[entity.id] = applyEntity(entity, syncKey, isLocal);
      }
    });
  }

  function entityBulkDelete(
    bulkEntities: Dictionary<TEntityStoreClass, TEntityId[]>
  ) {
    set((s) => {
      Object.keys(bulkEntities).forEach((key) => {
        const entityClass = key as TEntityStoreClass;
        const store = s[entityClass];
        if (isDefined(store)) {
          bulkEntities[entityClass]?.forEach((entityId) => {
            const syncKey = getSyncKey(store.byId[entityId]);
            delete store.syncKeyIndex[syncKey];
            delete store.byId[entityId];
          });
        }
      });
    });
  }

  function entityDelete<T extends TEntityStoreClass>(
    entityClass: T,
    entityId: TEntityId
  ) {
    set((s) => {
      const store = s[entityClass];
      if (isDefined(store)) {
        const syncKey = getSyncKey(store.byId[entityId]);
        delete store.syncKeyIndex[syncKey];
        delete store.byId[entityId];
      }
    });
  }

  return {
    authSync,
    entityBulkUpdate,
    entityCreate: entityUpdate,
    entityUpdate,
    entityBulkDelete,
    entityDelete,
  };
}
