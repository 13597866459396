import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { masterLoggedExpenseObj } from "st-shared/entities/MasterLoggedExpense";
import { applyInverseExchangeRate } from "st-shared/lib";
import { branchEntityStore } from "st-shared/stores";

import { getDefaultTaxRate } from "../../lib/entities/branchEntity";
import {
  getJobExchangeRate,
  getRateCardId,
} from "../../lib/entities/jobEntity";
import { getAsJobPhaseOption } from "../../lib/entities/jobPhaseEntity";
import { getAsLoggedExpenseOption } from "../../lib/entities/loggedExpenseEntity";
import {
  getTaxRateName,
  getTaxRateRate,
} from "../../lib/entities/taxRateEntity";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  getAccountId,
  getExpenseAccountCode,
  getExternalTaxRateName,
  getExternalTaxRateRate,
  getRevenueAccountCode,
} from "../../lib/ui/accountingPlatform";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../lib/ui/commercialDocument";
import {
  appendSearchStringToOption,
  appendSearchStringToOptions,
} from "../../lib/ui/commercialDocumentLineItems";
import { selectMasterLoggedExpenseRate } from "../../state/entities/itemRate/selectors/selectMasterLoggedExpenseRate";
import { selectMasterLoggedExpense } from "../../state/entities/masterLoggedExpense/selectors/selectMasterLoggedExpense";
import { selectMasterLoggedExpenseOptions } from "../../state/entities/masterLoggedExpense/selectors/selectMasterLoggedExpenseOptions";
import {
  selectAccountingPlatformId,
  selectAccountingPlatformIsActive,
  selectDefaultAccountingPlatformExpenseAccount,
  selectDefaultAccountingPlatformRevenueAccount,
  selectExpenseAccountDefaultTaxRateByAccountCode,
  selectRevenueAccountDefaultTaxRateByAccountCode,
} from "./accountingPlatformSelectors";
import {
  selectJobPhaseOrderByJobId,
  selectOrderedJobPlanOptionsByJobIdPhaseId,
} from "./jobItemSelectors";
import { selectJobPhaseById } from "./jobPhaseSelectors";
import { selectJobByJobId } from "./jobSelectors";
import {
  selectLoggedExpenseById,
  selectLoggedExpenseIdsByJobId,
} from "./loggedExpenseSelectors";

export const selectCommercialDocumentExpenseOptions = (
  state,
  { jobId, commercialDocumentType }
) => {
  const options = selectMasterLoggedExpenseOptions(state);
  const job = selectJobByJobId(state, { jobId });
  const rateCardId = getRateCardId(job);
  const jobExchangeRate = getJobExchangeRate(job);

  const mapCostRate = (option) => ({
    ...option,
    rate: applyInverseExchangeRate(
      masterLoggedExpenseObj(
        selectMasterLoggedExpense(state, { id: option.key })
      ).getCostRate(),
      jobExchangeRate
    ),
  });

  const mapSellRate = (option) => ({
    ...option,
    rate: selectMasterLoggedExpenseRate(state, {
      masterLoggedExpenseId: option.key,
      rateCardId,
    }),
  });

  const mapRate =
    commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER
      ? mapCostRate
      : mapSellRate;

  return options.map(mapRate);
};

export const selectCommercialDocumentJobPlanOptionsByJobId = (
  state,
  { jobId, invoiceType }
) => {
  const options = [];
  const jobPhaseIds = selectJobPhaseOrderByJobId(state, { jobId });
  const loggedExpenseIds = selectLoggedExpenseIdsByJobId(state, { jobId });

  jobPhaseIds.forEach((jobPhaseId) => {
    const jobPhase = selectJobPhaseById(state, { id: jobPhaseId });
    const jobItemOptions = selectOrderedJobPlanOptionsByJobIdPhaseId(state, {
      jobId,
      id: jobPhaseId,
      invoiceType,
    });

    if (jobPhase) {
      const jobPhaseOption = getAsJobPhaseOption(jobPhase);

      const searchStrings = jobItemOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      options.push(appendSearchStringToOption(jobPhaseOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          jobItemOptions,
          jobPhaseOption.searchString
        )
      );

      return;
    }

    options.push(...jobItemOptions);
  });

  if (loggedExpenseIds.length) {
    const allLoggedExpenseOptions = loggedExpenseIds.map((id) =>
      getAsLoggedExpenseOption(selectLoggedExpenseById(state, { id }))
    );

    const loggedExpenseOptions = allLoggedExpenseOptions.filter(
      (value) => !(value.status && value.status.name === "Declined")
    );

    const declinedLoggedExpenseOptions = allLoggedExpenseOptions.filter(
      (value) => value.status && value.status.name === "Declined"
    );

    if (loggedExpenseOptions.length) {
      const searchStrings = loggedExpenseOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const expensesHeadingOption = {
        key: "EXPENSES",
        value: "Expenses",
        optionType: LineItemOptionTypes.OptionsHeading,
        searchString: "Expenses",
      };

      options.push(
        appendSearchStringToOption(expensesHeadingOption, searchStrings)
      );

      options.push(
        ...appendSearchStringToOptions(
          loggedExpenseOptions,
          expensesHeadingOption.searchString
        )
      );
    }

    if (declinedLoggedExpenseOptions.length) {
      const searchStrings = declinedLoggedExpenseOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const expensesHeadingOption = {
        key: "DECLINED EXPENSES",
        value: "Declined Expenses",
        optionType: LineItemOptionTypes.OptionsHeading,
        searchString: "Declined Expenses",
      };

      options.push(
        appendSearchStringToOption(expensesHeadingOption, searchStrings)
      );

      options.push(
        ...appendSearchStringToOptions(
          declinedLoggedExpenseOptions,
          expensesHeadingOption.searchString
        )
      );
    }
  }

  return options;
};

export const selectLineItemDefaultExternalPlatformRevenueAccountCodeAndTaxRate =
  (state, props) => {
    let accountCode = null;
    let taxName = "";
    let taxRate = null;
    let externalTaxRateId = null;
    const { masterItem } = props;

    const accountingPlatformId = selectAccountingPlatformId(state, props);

    if (accountingPlatformId !== null) {
      accountCode =
        masterItem && getRevenueAccountCode(masterItem, accountingPlatformId);
    }

    if (!accountCode) {
      const defaultRevenueAccount =
        selectDefaultAccountingPlatformRevenueAccount(state, props);
      if (defaultRevenueAccount)
        accountCode = getAccountId(defaultRevenueAccount);
    }

    const externalTaxRate = selectRevenueAccountDefaultTaxRateByAccountCode(
      state,
      { accountCode }
    );

    if (externalTaxRate) {
      taxName = getExternalTaxRateName(externalTaxRate);
      taxRate = getExternalTaxRateRate(externalTaxRate);
      externalTaxRateId = externalTaxRate.id;
    }

    return {
      accountCode,
      taxName,
      taxRate,
      externalTaxRateId,
    };
  };

export const selectLineItemDefaultExternalPlatformExpenseAccountCodeAndTaxRate =
  (state, props) => {
    let accountCode = null;
    let taxName = "";
    let taxRate = null;
    let externalTaxRateId = null;
    const { masterItem } = props;

    const accountingPlatformId = selectAccountingPlatformId(state, props);

    if (accountingPlatformId !== null) {
      accountCode =
        masterItem && getExpenseAccountCode(masterItem, accountingPlatformId);
    }

    if (!accountCode) {
      const defaultExpenseAccount =
        selectDefaultAccountingPlatformExpenseAccount(state, props);

      if (defaultExpenseAccount)
        accountCode = getAccountId(defaultExpenseAccount);
    }

    const externalTaxRate = selectExpenseAccountDefaultTaxRateByAccountCode(
      state,
      { accountCode }
    );

    if (externalTaxRate) {
      taxName = getExternalTaxRateName(externalTaxRate);
      taxRate = getExternalTaxRateRate(externalTaxRate);
      externalTaxRateId = externalTaxRate.id;
    }

    return {
      accountCode,
      taxName,
      taxRate,
      externalTaxRateId,
    };
  };

export const selectLineItemDefaultStandardTaxRate = (state, props) => {
  let taxName = "";
  let taxRate = null;
  const { branchId } = props;

  const branch = branchEntityStore().entities[branchId];
  const defaultTaxRate = getDefaultTaxRate(branch);

  if (defaultTaxRate) {
    taxName = getTaxRateName(defaultTaxRate);
    taxRate = getTaxRateRate(defaultTaxRate);
  }

  return {
    accountCode: null,
    taxName,
    taxRate,
    externalTaxRateId: null,
  };
};

export const selectLineItemDefaultAccountCodeAndTaxRate = (state, props) => {
  const isExternalAccountingPlatformActive = selectAccountingPlatformIsActive(
    state,
    props
  );

  if (isExternalAccountingPlatformActive) {
    if (props.accountType === ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES)
      return selectLineItemDefaultExternalPlatformExpenseAccountCodeAndTaxRate(
        state,
        props
      );

    return selectLineItemDefaultExternalPlatformRevenueAccountCodeAndTaxRate(
      state,
      props
    );
  }

  return selectLineItemDefaultStandardTaxRate(state, props);
};
