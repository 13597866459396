import { IconBase, IconBaseProps } from "react-icons";

export function StDragIndicator(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "0 0 18 6" }} {...props}>
      <rect x="2" y="1" width="2" height="2" />
      <rect x="2" y="5" width="2" height="2" />
      <rect x="10" y="1" width="2" height="2" />
      <rect x="10" y="5" width="2" height="2" />
      <rect x="6" y="1" width="2" height="2" />
      <rect x="6" y="5" width="2" height="2" />
      <rect x="14" y="1" width="2" height="2" />
      <rect x="14" y="5" width="2" height="2" />
    </IconBase>
  );
}
