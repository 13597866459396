import { SvgIcon } from "@material-ui/core";

import StyledIcon from "../../../modules/StyledIcon";

function RoleAddIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path d="M35.1,25.25c1.79,0,3.31-.63,4.57-1.88s1.88-2.78,1.88-4.57-.63-3.31-1.88-4.57-2.78-1.88-4.57-1.88-3.31,.63-4.57,1.88-1.88,2.78-1.88,4.57,.63,3.31,1.88,4.57,2.78,1.88,4.57,1.88Z" />
      <path d="M47.25,31.65c-.5-.68-1.33-1.33-2.47-1.93-1.5-.79-3.06-1.37-4.67-1.75s-3.28-.56-5-.56-3.39,.19-5,.56-3.17,.96-4.67,1.75c-1.15,.61-1.97,1.25-2.47,1.93-.5,.68-.75,1.49-.75,2.42v4.08h25.79v-4.08c0-.93-.25-1.74-.75-2.42Z" />
      <polygon points="11 13.05 8.92 15.17 16.52 22.77 0 22.77 0 25.74 16.52 25.74 8.92 33.34 11 35.47 22.21 24.26 11 13.05" />
    </SvgIcon>
  );
}

export default StyledIcon(RoleAddIcon);
