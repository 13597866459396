import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";

export const LabelTypeEnum = {
  Job: 1,
  Quote: 2,
  Invoice: 3,
  LoggedExpense: 4,
  Company: 5,
  Contact: 6,
  User: 7,
} as const;

export type TLabelTypeEnum = ConstEnum<typeof LabelTypeEnum>;

export type TLabelType = TEnumOption<TLabelTypeEnum>;
