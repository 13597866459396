import styled from "styled-components";

const ScheduleBlocksWrapper = styled.div`
  position: relative;
  width: 100%;
  transition: height 0.2s;
  &:not(:last-child) {
    border-bottom: var(--border-thin-bright);
  }
`;

export default ScheduleBlocksWrapper;
