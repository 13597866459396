import isError from "lodash-es/isError";
import { call, select, takeLatest } from "redux-saga/effects";
import { sendLineItemMergeCount } from "../../../lib/WebAppAPI/commercialDocuments";
import { PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING } from "../../../lib/constants";
import { getPurchaseOrderId } from "../../../lib/entities/purchaseOrderLineItemEntity";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectCountIsMergingPurchaseOrderLineItemsByPurchaseOrderId,
  selectPurchaseOrderLineItemById
} from "../../selectors/purchaseOrderLineItemSelectors";

function* sendMergeItemCount(action) {
  try {
    const { id } = action.payload;

    const purchaseOrderLineItem = yield select(
      selectPurchaseOrderLineItemById,
      { id }
    );

    const lineItemMergeCount = yield select(
      selectCountIsMergingPurchaseOrderLineItemsByPurchaseOrderId,
      { purchaseOrderId: getPurchaseOrderId(purchaseOrderLineItem) }
    );

    yield call(sendLineItemMergeCount, lineItemMergeCount);
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchSetIsMerging() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING, sendMergeItemCount);
}
