import { useSelector } from "react-redux";
import { selectJobItem } from "../jobItem";
import { selectJobItemIdsByJobPhaseId } from "../jobItem/selectJobItemIdsByJobPhaseId";

export function selectJobPhaseJobItemBillableCount(state, { jobPhaseId }) {
  const jobItemIds = selectJobItemIdsByJobPhaseId(state, { jobPhaseId });
  let billableCount = 0;
  let nonBillableCount = 0;

  jobItemIds.forEach(jobItemId => {
    const jobItem = selectJobItem(state, { jobItemId });

    if (jobItem.isBillable) {
      billableCount += 1;
    } else {
      nonBillableCount += 1;
    }
  });

  return [billableCount, nonBillableCount];
}

export function useJobPhaseJobItemBillableCount(jobPhaseId) {
  return useSelector(state =>
    selectJobPhaseJobItemBillableCount(state, { jobPhaseId })
  );
}
