import clsx from "clsx";
import { IconBaseProps, IconType } from "react-icons";

import { iconCss } from "./Icon.css";
import { defaultIconSize, TIconSize } from "./IconSize";

export interface IconProps extends IconBaseProps {
  icon: IconType;
  size?: TIconSize | number;
}

export function Icon({
  icon,
  size = defaultIconSize,
  className,
  ...props
}: IconProps) {
  return icon({
    size,
    className: clsx(iconCss, className),
    ...props,
    focusable: false,
  });
}
