import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_LOGGED_EXPENSES,
  LOGGED_EXPENSE_EDIT,
  LOGGED_EXPENSE_SAVE,
  LOGGED_EXPENSE_SAVE_FAILED
} from "../../../../lib/constants";
import {
  getJobId,
  isDeleted
} from "../../../../lib/entities/loggedExpenseEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";

const idsByJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities)
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_LOGGED_EXPENSES],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_LOGGED_EXPENSES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const editLoggedExpenseReducer = (state, action) => {
  const { id, loggedExpense } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: state.byId[id],
      newEntity: loggedExpense
    }
  ]);
};

const editLoggedExpenseErrorReducer = (state, action) => {
  const { id, prevLoggedExpense } = action.payload;
  const prevEntity = state.byId[id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: prevLoggedExpense
    }
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [LOGGED_EXPENSE_EDIT]: editLoggedExpenseReducer,
    [LOGGED_EXPENSE_SAVE]: editLoggedExpenseReducer,
    [LOGGED_EXPENSE_SAVE_FAILED]: editLoggedExpenseErrorReducer
  }
);
