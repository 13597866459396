import { call, put, select } from "redux-saga/effects";

import { updateJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemUi } from "../../selectors/jobItemUiSelectors";

function* saveJobItemEdit(action) {
  const {
    jobItemId,
    jobItem,
    prevJobItem,
    modifiedJobItemDependancies,
    deletedJobItemDependancies = [],
    hasChanged,
  } = action.payload;

  try {
    if (!hasChanged) return;

    yield put(
      createAction(JOB_ITEM_SAVING, {
        jobItemId,
        jobItem,
      })
    );

    const { data } = yield call(
      updateJobItemAPI,
      jobItem,
      modifiedJobItemDependancies,
      deletedJobItemDependancies.map(getId)
    );

    const { isDragging, isResizing, isDeleting } = yield select(
      selectJobItemUi,
      { id: jobItemId }
    );

    if (isDragging || isResizing || isDeleting) return;

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_SAVED, {
        jobItemId,
        jobItem,
        data,
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_SAVE_ERROR, {
        jobItemId,
        jobItem,
        prevJobItem,
        deletedJobItemDependancies,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchSaveEditJobItem() {
  yield takeLatestBy(
    [JOB_ITEM_SAVE_EDIT],
    saveJobItemEdit,
    (action) => action.payload.jobItemId
  );
}
