import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateJobListModels } from "./selectStateJobListModels";

export const selectJobListModelsFilteredIds = createSelector(
  [selectStateJobListModels],
  (jobListModels) => jobListModels.filteredIds
);

export function useJobListModelsFilteredIds() {
  return useSelector(selectJobListModelsFilteredIds);
}
