import { createIcon } from "../../icon/Icon";

export const IconStatusPausedFilled = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 16H11V8H9V16ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM13 16H15V8H13V16Z"
  />,
  "IconStatusPausedFilled"
);
