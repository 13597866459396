import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobPhaseId } from "../index";
import { selectJobItems } from "../jobItemSelectors";

export const selectJobItemIdsByJobPhaseId = createCachedSelector(
  [selectJobItems, selectPropsJobPhaseId],
  (jobItems, jobPhaseId) =>
    get(jobItems.idsByJobPhaseId, jobPhaseId, EMPTY_ARRAY)
)(selectPropsJobPhaseId);

export const useJobItemIdsByJobPhaseId = jobPhaseId =>
  useSelector(state => selectJobItemIdsByJobPhaseId(state, { jobPhaseId }));
