import type {
  TEncodedSavedSegmentValue,
  TFilterGroupTypeEnum,
  TNumericStateCalculator,
  TStatisticFieldEnum,
  TStatisticModeEnum,
  TValueFormatEnum,
  TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
  NumericState,
  StatisticField,
  StatisticModeEnum,
  ValueFormatEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";
import type { ConstEnum, NullPartial } from "@streamtimefe/types";
import { uuidAlphaShort } from "@streamtimefe/utils";
import { cloneDeep, filter, findIndex, orderBy, pullAt } from "lodash-es";

import { StFunction } from "../../../components";
import type {
  FilterGroupCollection,
  ReportingSearchData,
  TReportingSearchType,
} from "../../../lib/webapi/reporting";
import { ReportingSearchType } from "../../../lib/webapi/reporting";
import type { FilterGroup, FilterGroupFilter } from "../../../module";
import { getValidFilterGroups } from "../../../module";
import type { Permissions } from "../../../stores";
import type {
  TConditionMatchType,
  TCustomDateRange,
  TDateRange,
} from "../../../types";
import {
  ConditionMatchType,
  CustomDateRange,
  EntityDescriptorProperties,
  getDatesFromRange,
} from "../../../types";
import {
  getFilterGroupTypes,
  getSearchableReportingFilters,
  getSubFilterGroupTypes,
  ReportingStatisticFields,
} from ".";
import { ReportingDateFilterGroupTypes } from "./ReportingDateFilterGroupTypes";
import type { TReportingEntityDescriptor } from "./ReportingEntityDescriptors";

export interface ReportingSavedSegment {
  searchType: TReportingSearchType;
  dates: TCustomDateRange | TDateRange | null;
  filterGroupType: TFilterGroupTypeEnum | null;
  subFilterGroupType: TFilterGroupTypeEnum;
  dataSets: Record<string, ReportingDataSet>;
  columns: Record<string, ReportingColumn>;
  selectedColumnId: string | null;
  selectedTimeId: string | null;
  selectedTimeIds: string[];
  timeChartType: TReportingTimeChartType;
  sortColumn: ReportingSortIdentifier;
  sortTime: ReportingSortIdentifier;
  globalFilters: {
    conditionMatchTypeId: TConditionMatchType;
    filterGroups: FilterGroup[];
  };
}

export const ReportingTimeChartType = {
  Regular: 1,
  Burnup: 2,
} as const;

export type TReportingTimeChartType =
  (typeof ReportingTimeChartType)[keyof typeof ReportingTimeChartType];

export interface ReportingSortIdentifier {
  id: string | null;
  order: "asc" | "desc";
}

export interface ReportingDataSet {
  id: string;
  name: string;
  orderId: number;
  entityDescriptorId: TReportingEntityDescriptor;
  conditionMatchTypeId: TConditionMatchType;
  dateFilterGroupTypeId: TFilterGroupTypeEnum;
  filterGroups: FilterGroup[];
}

export const ReportingColumnType = {
  Data: 1,
  Formula: 2,
  Total: 3,
} as const;

export type TReportingColumnType = ConstEnum<typeof ReportingColumnType>;

export type ReportingDisplayRule = Omit<
  TNumericStateCalculator,
  "threshold"
> & {
  threshold: number | string;
};

type BaseReportingColumn = {
  id: string;
  type: TReportingColumnType;
  name: string;
  orderId: number;
  hidden?: true;
  displayRules?: ReportingDisplayRule[];
};

export type ReportingDataColumn = BaseReportingColumn & {
  type: typeof ReportingColumnType.Data | typeof ReportingColumnType.Total;
  dataSetId: string;
  modeId: TStatisticModeEnum;
  columnId: TStatisticFieldEnum;
};

export type ReportingFormulaColumn = BaseReportingColumn & {
  type: typeof ReportingColumnType.Formula;
  format: TValueFormatEnum;
  formula: string;
};

export type ReportingColumn = ReportingDataColumn | ReportingFormulaColumn;

const defaultObject: ReportingSavedSegment = {
  searchType: ReportingSearchType.ColumnSeries,
  dates: null,
  filterGroupType: FilterGroupTypeEnum.CompanyId,
  subFilterGroupType: FilterGroupTypeEnum.JobId,
  dataSets: {},
  columns: {},
  selectedColumnId: null,
  selectedTimeId: null,
  selectedTimeIds: [],
  timeChartType: ReportingTimeChartType.Regular,
  sortColumn: { id: null, order: "asc" },
  sortTime: { id: null, order: "asc" },
  globalFilters: {
    conditionMatchTypeId: ConditionMatchType.And,
    filterGroups: [],
  },
};

export class CReportingSavedSegment {
  object: ReportingSavedSegment;

  private constructor(object: Partial<ReportingSavedSegment>) {
    if (!("searchType" in object))
      object.searchType = cloneDeep(defaultObject.searchType);
    if (!("dates" in object)) object.dates = cloneDeep(defaultObject.dates);
    if (!("filterGroupType" in object))
      object.filterGroupType = cloneDeep(defaultObject.filterGroupType);
    if (!("subFilterGroupType" in object))
      object.subFilterGroupType = cloneDeep(defaultObject.subFilterGroupType);
    if (!("dataSets" in object))
      object.dataSets = cloneDeep(defaultObject.dataSets);
    if (!("columns" in object))
      object.columns = cloneDeep(defaultObject.columns);
    if (!("selectedColumnId" in object))
      object.selectedColumnId = cloneDeep(defaultObject.selectedColumnId);
    if (!("selectedTimeId" in object))
      object.selectedTimeId = cloneDeep(defaultObject.selectedTimeId);
    if (!("selectedTimeIds" in object))
      object.selectedTimeIds = cloneDeep(defaultObject.selectedTimeIds);
    if (!("timeChartType" in object))
      object.timeChartType = cloneDeep(defaultObject.timeChartType);
    if (!("sortColumn" in object))
      object.sortColumn = cloneDeep(defaultObject.sortColumn);
    if (!("sortTime" in object))
      object.sortTime = cloneDeep(defaultObject.sortTime);
    if (!("globalFilters" in object))
      object.globalFilters = cloneDeep(defaultObject.globalFilters);

    this.object = object as ReportingSavedSegment;
  }

  static fromString(value: TEncodedSavedSegmentValue) {
    return new CReportingSavedSegment(JSON.parse(value));
  }

  static fromObject(object: ReportingSavedSegment) {
    return new CReportingSavedSegment(object);
  }

  toString() {
    return JSON.stringify(this.object) as TEncodedSavedSegmentValue;
  }

  static parseAndValidate(value: TEncodedSavedSegmentValue) {
    const savedSegment = CReportingSavedSegment.fromString(value);
    savedSegment.validate();
    return savedSegment.toString();
  }

  validate() {
    this.fillEmptyIds();
  }

  fillEmptyIds() {
    const dataSetIds = Object.keys(this.object.dataSets);
    const columnIds = Object.keys(this.object.columns);

    const filterGroupIds: string[] = [];
    Object.values(this.object.dataSets).forEach((dataSet) => {
      filterGroupIds.push(
        ...dataSet.filterGroups.map((filterGroup) => filterGroup.id)
      );
    });

    const globalFilterGroupIds = this.object.globalFilters.filterGroups.map(
      (filterGroup) => filterGroup.id
    );

    const ids = [
      ...dataSetIds,
      ...columnIds,
      ...filterGroupIds,
      ...globalFilterGroupIds,
    ];

    function getId() {
      let id = uuidAlphaShort();

      while (ids.includes(id)) {
        id = uuidAlphaShort();
      }

      ids.push(id);

      return id;
    }

    Object.values(this.object.dataSets).forEach((dataSet) => {
      dataSet.filterGroups.forEach((filterGroup) => {
        if (typeof filterGroup.id === "undefined") {
          filterGroup.id = getId();
        }
      });
    });

    this.object.globalFilters.filterGroups.forEach((filterGroup) => {
      if (typeof filterGroup.id === "undefined") {
        filterGroup.id = getId();
      }
    });
  }

  generateId(...extraIds: string[]): string {
    const dataSetIds = Object.keys(this.object.dataSets);
    const columnIds = Object.keys(this.object.columns);

    const filterGroupIds: string[] = [];
    Object.values(this.object.dataSets).forEach((dataSet) => {
      filterGroupIds.push(
        ...dataSet.filterGroups.map((filterGroup) => filterGroup.id)
      );
    });

    const globalFilterGroupIds = this.object.globalFilters.filterGroups.map(
      (filterGroup) => filterGroup.id
    );

    const ids = [
      ...dataSetIds,
      ...columnIds,
      ...filterGroupIds,
      ...globalFilterGroupIds,
      ...extraIds,
    ];

    let id = uuidAlphaShort();

    while (ids.includes(id)) {
      id = uuidAlphaShort();
    }

    return id;
  }

  getOrderedDataSets() {
    return orderBy(this.object.dataSets, "orderId");
  }

  getOrderedColumns() {
    return orderBy(this.object.columns, "orderId");
  }

  getSelectedColumn() {
    if (
      this.object.selectedColumnId &&
      this.object.selectedColumnId in this.object.columns
    ) {
      return this.object.columns[this.object.selectedColumnId];
    }

    const columns = this.getOrderedColumns();
    if (columns.length > 0) {
      return columns[0];
    }

    return null;
  }

  getSelectedTimeColumn() {
    if (
      this.object.selectedTimeId &&
      this.object.selectedTimeId in this.object.columns
    ) {
      return this.object.columns[this.object.selectedTimeId];
    }

    const columns = this.getOrderedColumns();
    if (columns.length > 0) {
      return columns[0];
    }

    return null;
  }

  reorderDataSets() {
    const orderedDataSetIds = this.getOrderedDataSets().map(
      (value) => value.id
    );
    orderedDataSetIds.forEach((id, index) => {
      this.object.dataSets[id].orderId = index + 1;
    });

    // check user availability and all time and reset if needed
    if (
      this.object.dates === null &&
      Object.keys(this.object.dataSets).length > 0
    ) {
      const dataSetArray = Object.values(this.object.dataSets);
      const availibilityDataSetArray = dataSetArray.filter(
        (dataSet) => dataSet.entityDescriptorId === EntityDescriptorEnum.User
      );
      if (dataSetArray.length === availibilityDataSetArray.length) {
        this.object.dates = CustomDateRange.ThisMonth;
      }
    }
  }

  reorderColumns() {
    const orderedColumnIds = this.getOrderedColumns().map((value) => value.id);
    orderedColumnIds.forEach((id, index) => {
      this.object.columns[id].orderId = index + 1;
    });
  }

  createDataSet(
    entityDescriptorId: TReportingEntityDescriptor,
    name: string,
    id?: string
  ) {
    const orderedDataSets = this.getOrderedDataSets();

    const newDataSet: ReportingDataSet = {
      id: id || this.generateId(),
      name,
      entityDescriptorId,
      conditionMatchTypeId: ConditionMatchType.And,
      dateFilterGroupTypeId:
        ReportingDateFilterGroupTypes[entityDescriptorId][0],
      filterGroups: [],
      orderId: orderedDataSets[orderedDataSets.length - 1]?.orderId + 1 || 1,
    };

    return newDataSet;
  }

  getDateRange(): NullPartial<TDateRange> {
    if (typeof this.object.dates === "string") {
      return getDatesFromRange(this.object.dates);
    } else if (this.object.dates !== null) {
      return this.object.dates;
    }
    return { startDate: null, endDate: null };
  }

  createSearchData(): ReportingSearchData {
    const { startDate, endDate } = this.getDateRange();

    const searchData: ReportingSearchData = {
      startDate: startDate,
      endDate: endDate,
      groupedFilterGroupTypeId: this.object.filterGroupType,
      dataSets: this.getOrderedDataSets().map((dataSet) => ({
        entityDescriptorId: dataSet.entityDescriptorId,
        dateFilterGroupTypeId: dataSet.dateFilterGroupTypeId,
        statisticFields: filter(
          CReportingSavedSegment.getDataColumns(this.object.columns),
          {
            dataSetId: dataSet.id,
          }
        ).map(({ columnId, modeId }) => ({ columnId, modeId })),
        filterGroupCollection: {
          conditionMatchTypeId: ConditionMatchType.And,
          filterGroupCollections: [
            {
              conditionMatchTypeId: dataSet.conditionMatchTypeId,
              filterGroups: getValidFilterGroups(dataSet.filterGroups),
            },
          ],
        },
      })),
    };

    const globalFilterGroups = getValidFilterGroups(
      this.object.globalFilters.filterGroups
    );

    // add global filters
    if (globalFilterGroups.length > 0) {
      for (const dataSet of searchData.dataSets) {
        const dataSetFilters = getSearchableReportingFilters(
          dataSet.entityDescriptorId
        );

        const filterGroups = globalFilterGroups.filter((filterGroup) =>
          dataSetFilters.includes(filterGroup.filterGroupTypeId)
        );

        if (filterGroups.length > 0) {
          dataSet.filterGroupCollection!.filterGroupCollections!.push({
            filterGroups,
            conditionMatchTypeId:
              this.object.globalFilters.conditionMatchTypeId,
          });
        }
      }
    }

    return searchData;
  }

  createSubSearchData(key: string | null): ReportingSearchData {
    const searchData = this.createSearchData();

    searchData.groupedFilterGroupTypeId = this.object.subFilterGroupType;

    let filterGroupTypeId = this.object.filterGroupType;
    let valueMatchTypeId: TValueMatchTypeEnum = ValueMatchTypeEnum.Equals;
    const value = key;

    if (filterGroupTypeId === FilterGroupTypeEnum.ItemName && value === null) {
      filterGroupTypeId = FilterGroupTypeEnum.Time;
      valueMatchTypeId = ValueMatchTypeEnum.BooleanFalse;
    }

    const subFilterGroupCollection: FilterGroupCollection = {
      conditionMatchTypeId: ConditionMatchType.And,
      filterGroups: [
        {
          filterGroupTypeId: filterGroupTypeId,
          conditionMatchTypeId: ConditionMatchType.And,
          filters: [
            {
              valueMatchTypeId: valueMatchTypeId,
              value: value as string,
            },
          ],
        } as FilterGroup,
      ],
    };

    for (const dataSet of searchData.dataSets) {
      dataSet.filterGroupCollection!.filterGroupCollections!.push(
        subFilterGroupCollection
      );
    }

    return searchData;
  }

  createDualSearchData(): ReportingSearchData {
    const searchData = this.createSearchData();

    searchData.subGroupedFilterGroupTypeId = this.object.subFilterGroupType;

    return searchData;
  }

  setDates(dates: TCustomDateRange | TDateRange | null) {
    this.object.dates = dates;
  }

  setSearchType(searchType: TReportingSearchType) {
    this.object.searchType = searchType;
  }

  setSelectedColumnId(selectedColumnId: string) {
    this.object.selectedColumnId = selectedColumnId;
  }

  setSelectedTimeId(selectedTimeId: string) {
    this.object.selectedTimeId = selectedTimeId;
  }

  setSelectedTimeIds(selectedTimeIds: string[]) {
    this.object.selectedTimeIds = selectedTimeIds;
  }

  setTimeChartType(timeChartType: TReportingTimeChartType) {
    this.object.timeChartType = timeChartType;
  }

  setSortColumn(sort: ReportingSortIdentifier) {
    this.object.sortColumn = sort;
  }

  setSortTime(sort: ReportingSortIdentifier) {
    this.object.sortTime = sort;
  }

  setFilterGroupType(filterGroupType: TFilterGroupTypeEnum | null) {
    this.object.filterGroupType = filterGroupType;

    const orderedDataSets = this.getOrderedDataSets();
    const subFilterGroupTypes = getSubFilterGroupTypes(
      orderedDataSets,
      this.object.filterGroupType
    );

    if (
      this.object.filterGroupType === this.object.subFilterGroupType ||
      !subFilterGroupTypes.includes(this.object.subFilterGroupType)
    ) {
      this.object.subFilterGroupType =
        subFilterGroupTypes[0] || this.object.filterGroupType;
    }
  }

  setSubFilterGroupType(subFilterGroupType: TFilterGroupTypeEnum) {
    this.object.subFilterGroupType = subFilterGroupType;
  }

  addDataset(
    entityDescriptorId: TReportingEntityDescriptor,
    name: string,
    id?: string
  ) {
    const newDataSet = this.createDataSet(entityDescriptorId, name, id);

    this.object.dataSets[newDataSet.id] = newDataSet;

    const orderedDataSets = this.getOrderedDataSets();

    const filterGroupTypes = getFilterGroupTypes(orderedDataSets);
    if (
      this.object.filterGroupType &&
      !filterGroupTypes.includes(this.object.filterGroupType)
    ) {
      this.object.filterGroupType = filterGroupTypes[0] ?? null;
    }

    const subFilterGroupTypes = getSubFilterGroupTypes(
      orderedDataSets,
      this.object.filterGroupType
    );

    if (
      this.object.filterGroupType === this.object.subFilterGroupType ||
      !subFilterGroupTypes.includes(this.object.subFilterGroupType)
    ) {
      this.object.subFilterGroupType =
        subFilterGroupTypes[0] || this.object.filterGroupType;
    }

    this.reorderDataSets();
  }

  duplicateDataset(dataSetId: string) {
    const newDataSet = cloneDeep(this.object.dataSets[dataSetId]);
    newDataSet.id = this.generateId();
    newDataSet.orderId += 0.5;
    newDataSet.name = CReportingSavedSegment.appendCopy(newDataSet.name);

    this.object.dataSets[newDataSet.id] = newDataSet;

    // duplicate columns
    const orderedColumns = this.getOrderedColumns();
    if (orderedColumns.length > 0) {
      const lastColumnOrderId =
        orderedColumns[orderedColumns.length - 1].orderId;

      const dataSetColumns = CReportingSavedSegment.getDataColumns(
        orderedColumns
      ).filter((column) => column.dataSetId === dataSetId);

      if (dataSetColumns.length > 0) {
        dataSetColumns.forEach((dataSetColumn, index) => {
          const newColumn: ReportingDataColumn = {
            id: this.generateId(newDataSet.id),
            type: dataSetColumn.type,
            name: CReportingSavedSegment.appendCopy(dataSetColumn.name),
            dataSetId: newDataSet.id,
            modeId: dataSetColumn.modeId,
            columnId: dataSetColumn.columnId,
            orderId: lastColumnOrderId + 1 + index,
          };

          this.object.columns[newColumn.id] = newColumn;
        });
        this.reorderColumns();
      }
    }

    this.reorderDataSets();
  }

  deleteColumn(id: string) {
    delete this.object.columns[id];

    if (id === this.object.sortColumn.id) {
      this.object.sortColumn.id = null;
    }

    this.reorderColumns();
  }

  setColumnOrderId(id: string, orderId: number) {
    this.object.columns[id].orderId = orderId;
    this.reorderColumns();
  }

  setColumnHidden(id: string, hidden: boolean) {
    if (hidden) {
      this.object.columns[id].hidden = hidden;
    } else {
      delete this.object.columns[id].hidden;
    }
  }

  addDataColumn(column: Omit<ReportingDataColumn, "id" | "orderId">) {
    this.addColumn(column, column.type);
  }

  addFormulaColumn(
    column: Omit<ReportingFormulaColumn, "id" | "orderId" | "type">
  ) {
    this.addColumn(column, ReportingColumnType.Formula);
  }

  private addColumn(
    column: Omit<ReportingColumn, "id" | "orderId" | "type">,
    type: TReportingColumnType
  ) {
    const orderedColumns = this.getOrderedColumns();

    const newColumn = {
      ...column,
      type,
      id: this.generateId(),
      orderId: orderedColumns[orderedColumns.length - 1]?.orderId + 1 || 1,
    } as ReportingColumn;

    this.object.columns[newColumn.id] = newColumn;

    this.reorderColumns();
  }

  setDatasetDateFilterGroupType(
    dataSetId: string,
    dateFilterGroupTypeId: TFilterGroupTypeEnum
  ) {
    this.object.dataSets[dataSetId].dateFilterGroupTypeId =
      dateFilterGroupTypeId;
  }

  setDatasetName(dataSetId: string, name: string) {
    this.object.dataSets[dataSetId].name = name;
  }

  setDatasetConditionMatch(
    dataSetId: string,
    conditionMatchTypeId: TConditionMatchType
  ) {
    this.object.dataSets[dataSetId].conditionMatchTypeId = conditionMatchTypeId;
  }

  deleteDataset(dataSetId: string) {
    delete this.object.dataSets[dataSetId];

    CReportingSavedSegment.getDataColumns(this.object.columns).forEach(
      (column) => {
        if (column.dataSetId === dataSetId) {
          delete this.object.columns[column.id];
          if (column.id === this.object.sortColumn.id) {
            this.object.sortColumn.id = null;
          }
        }
      }
    );

    this.reorderDataSets();
    this.reorderColumns();
  }

  addDatasetFilterGroup(dataSetId: string, filterGroup: FilterGroup) {
    this.object.dataSets[dataSetId].filterGroups.push(filterGroup);
  }

  deleteDatasetFilterGroup(dataSetId: string, filterId: string) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);
    if (index !== -1) {
      pullAt(this.object.dataSets[dataSetId].filterGroups, index);
    }
  }

  setDatasetFilterGroupConditionMatch(
    dataSetId: string,
    filterId: string,
    conditionMatchTypeId: TConditionMatchType
  ) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.dataSets[dataSetId].filterGroups[index].conditionMatchTypeId =
        conditionMatchTypeId;
    }
  }

  setDatasetFilterGroupValueMatch(
    dataSetId: string,
    filterId: string,
    valueMatchTypeId: TValueMatchTypeEnum
  ) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.dataSets[dataSetId].filterGroups[index].filters.forEach(
        (filter) => {
          filter.valueMatchTypeId = valueMatchTypeId;
        }
      );
    }
  }

  addDatasetFilterGroupFilter(
    dataSetId: string,
    filterId: string,
    filter: FilterGroupFilter
  ) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.dataSets[dataSetId].filterGroups[index].filters.push(filter);
    }
  }

  setDatasetFilterGroupFilter(
    dataSetId: string,
    filterId: string,
    filterIndex: number,
    filter: FilterGroupFilter
  ) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.dataSets[dataSetId].filterGroups[index].filters[filterIndex] =
        filter;
    }
  }

  deleteDatasetFilterGroupFilters(
    dataSetId: string,
    filterId: string,
    indexes: number[]
  ) {
    const index = findIndex(this.object.dataSets[dataSetId].filterGroups, [
      "id",
      filterId,
    ]);
    if (index !== -1) {
      pullAt(
        this.object.dataSets[dataSetId].filterGroups[index].filters,
        ...indexes
      );
    }
  }

  addGlobalFilterGroup(filterGroup: FilterGroup) {
    this.object.globalFilters.filterGroups.push(filterGroup);
  }

  setGlobalFilterConditionMatch(conditionMatchTypeId: TConditionMatchType) {
    this.object.globalFilters.conditionMatchTypeId = conditionMatchTypeId;
  }

  deleteGlobalFilterGroup(filterId: string) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);
    if (index !== -1) {
      pullAt(this.object.globalFilters.filterGroups, index);
    }
  }

  addGlobalFilterGroupFilter(filterId: string, filter: FilterGroupFilter) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.globalFilters.filterGroups[index].filters.push(filter);
    }
  }

  setGlobalFilterGroupConditionMatch(
    filterId: string,
    conditionMatchTypeId: TConditionMatchType
  ) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.globalFilters.filterGroups[index].conditionMatchTypeId =
        conditionMatchTypeId;
    }
  }

  setGlobalFilterGroupValueMatch(
    filterId: string,
    valueMatchTypeId: TValueMatchTypeEnum
  ) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.globalFilters.filterGroups[index].filters.forEach(
        (filter) => {
          filter.valueMatchTypeId = valueMatchTypeId;
        }
      );
    }
  }

  setGlobalFilterGroupFilter(
    filterId: string,
    filterIndex: number,
    filter: FilterGroupFilter
  ) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);

    if (index !== -1) {
      this.object.globalFilters.filterGroups[index].filters[filterIndex] =
        filter;
    }
  }

  deleteGlobalFilterGroupFilters(filterId: string, indexes: number[]) {
    const index = findIndex(this.object.globalFilters.filterGroups, [
      "id",
      filterId,
    ]);
    if (index !== -1) {
      pullAt(this.object.globalFilters.filterGroups[index].filters, ...indexes);
    }
  }

  getDataColumnKeys() {
    const dataColumnKeys = CReportingSavedSegment.getDataColumns(
      this.object.columns
    ).map((column) => column.id);

    return dataColumnKeys;
  }

  static createDefault() {
    return new CReportingSavedSegment({});
  }

  static switchSortOrder(order: "asc" | "desc") {
    if (order === "asc") return "desc";
    return "asc";
  }

  static getColumnIcon(
    column: ReportingColumn,
    dataSets: ReportingSavedSegment["dataSets"]
  ) {
    if (column.type === ReportingColumnType.Formula) {
      return StFunction;
    } else {
      const dataSet = dataSets[column.dataSetId];
      return EntityDescriptorProperties[dataSet.entityDescriptorId].icon;
    }
  }

  static getColumnFormat(column: ReportingColumn) {
    if (column.type === ReportingColumnType.Formula) {
      return column.format;
    } else {
      return StatisticField.getFormat(column.columnId);
    }
  }

  static getDefaultNumericStateCalculators(column: ReportingColumn) {
    if (column.type !== ReportingColumnType.Formula) {
      return CReportingSavedSegment.getDataColumnNumericStateCalculators(
        column.columnId
      );
    } else {
      return CReportingSavedSegment.getFormulaColumnNumericStateCalculators();
    }
  }

  static getDataColumnNumericStateCalculators(
    columnId: TStatisticFieldEnum
  ): TNumericStateCalculator[] {
    return (
      StatisticField.getNumericStateOverride(columnId) ??
      NumericState.Calculators.Default
    );
  }

  static getFormulaColumnNumericStateCalculators(): TNumericStateCalculator[] {
    return NumericState.Calculators.Default;
  }

  static canTimeSeriesColumnShowTotal(column: ReportingColumn) {
    return (
      column.type === ReportingColumnType.Formula ||
      (CReportingSavedSegment.getColumnFormat(column) !==
        ValueFormatEnum.Percentage &&
        !CReportingSavedSegment.isColumnAverage(column))
    );
  }

  static getDataColumns(
    columns: Record<string, ReportingColumn> | ReportingColumn[]
  ) {
    if (!Array.isArray(columns)) {
      columns = Object.values(columns);
    }
    return columns.filter(
      (column) =>
        column.type === ReportingColumnType.Data ||
        column.type === ReportingColumnType.Total
    ) as ReportingDataColumn[];
  }

  static getFormulaColumns(
    columns: Record<string, ReportingColumn> | ReportingColumn[]
  ) {
    if (!Array.isArray(columns)) {
      columns = Object.values(columns);
    }
    return columns.filter(
      (column) => column.type === ReportingColumnType.Formula
    ) as ReportingFormulaColumn[];
  }

  static getAllowedColumnIds(
    entityDescriptorId: TReportingEntityDescriptor,
    permissions: Permissions
  ) {
    return ReportingStatisticFields[entityDescriptorId].filter(
      (statisticField) => {
        if (
          !permissions.canAccessCostRates &&
          StatisticField.containsCostRate(statisticField)
        ) {
          return false;
        }

        if (
          !permissions.canViewJobFinancials &&
          StatisticField.containsFinancial(statisticField)
        ) {
          return false;
        }

        return true;
      }
    );
  }

  static appendCopy(text: string): string {
    return text + " (Copy)";
  }

  static isColumnAverage(column: ReportingColumn) {
    return (
      (column.type === ReportingColumnType.Data ||
        column.type === ReportingColumnType.Total) &&
      column.modeId === StatisticModeEnum.Average
    );
  }
}
