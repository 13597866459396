import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobMilestoneStore = createSingleEntityStoreContext(
  EntityClass.JobMilestone
);

export const JobMilestoneStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobMilestone,
  JobMilestoneStore
);
