import { all } from "redux-saga/effects";

import watchJobItemUserCreate from "./watchJobItemUserCreate";
import watchJobItemUserDelete from "./watchJobItemUserDelete";
import watchJobItemUserReassign from "./watchJobItemUserReassign";
import watchJobItemUserSetHours from "./watchJobItemUserSetHours";
import watchJobItemUserSetRate from "./watchJobItemUserSetRate";
import watchJobItemUserSetTimeAllocationMethod from "./watchJobItemUserSetTimeAllocationMethod";
import watchJobItemUserStatusChange from "./watchJobItemUserStatusChange";
import watchJobItemUserUnassign from "./watchJobItemUserUnassign";

export default function* jobItemUserSaga() {
  yield all([
    watchJobItemUserStatusChange(),
    watchJobItemUserUnassign(),
    watchJobItemUserSetHours(),
    watchJobItemUserSetRate(),
    watchJobItemUserSetTimeAllocationMethod(),
    watchJobItemUserCreate(),
    watchJobItemUserDelete(),
    watchJobItemUserReassign(),
  ]);
}
