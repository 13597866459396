import { createIcon } from "../../icon/Icon";

export const IconStrongArrowUp = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.2596 20L14.1565 20L14.1565 11.5111L20 11.5111L12.208 4L4.41603 11.5111L10.2596 11.5111L10.2596 20Z"
  />,
  "IconStrongArrowUp"
);
