import { createContext, useContext } from "react";

export type TListBoxContext = { size: "small" | "medium" };

export const ListBoxContext = createContext<TListBoxContext>({
  size: "medium",
});

export function useListBoxContext() {
  const context = useContext(ListBoxContext);
  if (!context) {
    throw new Error(`Missing ListBoxContext`);
  }
  return context;
}
