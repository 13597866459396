import MomentUtils from "@date-io/moment";
import { StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { streamtimeTheme } from "../../lib/themes/streamtime";
import { reactNodesType } from "../../lib/types/reactTypes";

class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return this.moment.weekdaysMin(true);
  }
}

const MaterialUiProviders = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={streamtimeTheme}>
      <MuiPickersUtilsProvider utils={LocalizedUtils}>
        {children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </StylesProvider>
);

MaterialUiProviders.propTypes = {
  children: reactNodesType.isRequired,
};

export default MaterialUiProviders;
