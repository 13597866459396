import isError from "lodash-es/isError";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { sendOpenSetSupplierModal } from "../../../lib/WebAppAPI/loggedExpense";
import {
  COMPANY_ADDRESSES_FETCH_REQUEST,
  COMPANY_CONTACTS_FETCH_REQUEST,
  ENTITIES_RECEIVED,
  LOGGED_EXPENSE_SET_SUPPLIER
} from "../../../lib/constants";
import { getCompany } from "../../../lib/entities/loggedExpenseEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLoggedExpenseById } from "../../selectors/loggedExpenseSelectors";

function* setSupplier(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(sendOpenSetSupplierModal);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    const loggedExpense = yield select(selectLoggedExpenseById, { id });

    const company = getCompany(loggedExpense);

    yield put(createAction(COMPANY_CONTACTS_FETCH_REQUEST, { id: company.id }));

    yield put(
      createAction(COMPANY_ADDRESSES_FETCH_REQUEST, { id: company.id })
    );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchLoggedExpenseSetSupplier() {
  yield takeLatest(LOGGED_EXPENSE_SET_SUPPLIER, setSupplier);
}
