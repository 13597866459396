import { get, keys, mergeWith, pick, pickBy, set, union } from "lodash-es";
import { EMPTY_ARRAY } from "./constants";

export const mergeWithArrayCustomizer = (objValue, srcValue = []) => {
  return Array.isArray(objValue) ? union(objValue, srcValue) : undefined;
};

/*
 * See: https://lodash.com/docs/4.17.15#mergeWith
 * var object = { 'a': [1], 'b': [2] };
 * mergeWithArrayCollection(object, 'a', 3);
 * mergeWithArrayCollection(object, 'b', 4);
 * mergeWithArrayCollection(object, 'c', 5);
 * // => { 'a': [1, 3], 'b': [2, 4], 'c': [5] }
 */
export const mergeWithArrayCollection = (src, key, value) =>
  mergeWith(src, { [key]: [value] }, mergeWithArrayCustomizer);

/*
 * initializes and pushes `value` into an array at `path`
 */
export const setPush = (src, path, value) =>
  set(src, path, union(get(src, path, EMPTY_ARRAY), [value]));

/*
 * initializes and adds `value` into a summation at `path`
 */
export const setSum = (src, path, value) =>
  set(src, path, get(src, path, 0) + value);

export const pickTruthy = (props, keyNames) =>
  pickBy(pick(props, keyNames), Boolean);

export const mapPropsToClassName = keyNames => ({
  className = "",
  ...props
}) => ({
  className: [...keys(pickTruthy(props, keyNames)), className].join(" ").trim()
});

export const getId = ({ id }) => id;
