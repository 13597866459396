import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const RoleRateStore = createSingleEntityStoreContext(
  EntityClass.RoleRate
);

export const RoleRateStoreProvider = createSingleEntityStoreProvider(
  EntityClass.RoleRate,
  RoleRateStore
);
