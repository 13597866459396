import { combineReducers } from "redux";

import crossReducers from "./crossReducers";
import entities from "./entities";
import snapshots from "./snapshots";
import ui from "./ui";

const combinedReducers = combineReducers({
  entities,
  snapshots,
  ui,
});

export default crossReducers(combinedReducers);
