import { useLayoutEffect, useRef, useState } from "react";
import { AriaPopover } from "st-shared/components";
import { plate } from "st-shared/external";

import { ELEMENT_COLUMN_INPUT } from "../plugins/column";
import { comboboxItemCss } from "./ComboboxItem.css";

const ComboboxItem = plate.withRef<"div", plate.ComboboxContentItemProps>(
  ({ combobox, index, item, onRenderItem, ...rest }, ref) => {
    const { props } = plate.useComboboxItem({
      item,
      index,
      combobox,
      onRenderItem,
    });

    return (
      <div ref={ref} className={comboboxItemCss} {...props} {...rest}>
        {props.children}
      </div>
    );
  }
);

export function ComboboxContent({
  items,
  combobox,
  onRenderItem,
}: plate.ComboboxContentProps) {
  plate.useComboboxContentState({ items, combobox });
  const filteredItems = plate.useComboboxSelectors.filteredItems();

  const ref = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    ref.current = document.querySelector(`#${ELEMENT_COLUMN_INPUT}`);
    setIsOpen(true);
  }, []);

  return (
    <>
      <AriaPopover
        triggerRef={ref}
        isOpen={isOpen}
        renderDialog={false}
        crossOffset={10}
        offset={2}
      >
        <div>
          {filteredItems.map((item, index) => (
            <ComboboxItem
              key={item.key}
              item={item}
              combobox={combobox}
              index={index}
              onRenderItem={onRenderItem}
            />
          ))}
        </div>
      </AriaPopover>
    </>
  );
}
