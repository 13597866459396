import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";

import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const JobLeadIcon = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="m9.2 12c2.4 0 4.4-2 4.4-4.4s-2-4.4-4.4-4.4-4.4 2-4.4 4.4 2 4.4 4.4 4.4zm0 2.2c-2.9 0-8.7 1.4-8.7 4.4v2.2h17.5v-2.2c-.1-3-5.9-4.4-8.8-4.4zm7.4 0 .7-3.1-2.3-2.2 3.1-.3 1.2-3 1.2 3 3.1.3-2.4 2.1.7 3.1-2.6-1.7z"
      fill="#bdc3c7"
    />
  </SvgIcon>
);

JobLeadIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE)),
};

JobLeadIcon.defaultProps = {
  size: ICON_SIZE.LARGE,
};

export default StyledIcon(JobLeadIcon);
