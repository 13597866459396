import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_COMPANIES } from "../../../lib/constants";
import { selectPropsCompanyId } from "../index";

export const selectStateCompanies = state =>
  state.entities[ENTITY_NAME_COMPANIES];

export const selectCompany = createCachedSelector(
  [selectStateCompanies, selectPropsCompanyId],
  (companies, companyId) => get(companies, `byId.${companyId}`)
)(selectPropsCompanyId || "");

export const useCompany = companyId =>
  useSelector(state => selectCompany(state, { companyId }));
