import { isEqual } from "lodash-es";
import { call, put, select } from "redux-saga/effects";
import { saveQuoteLineItemAPI } from "../../../lib/API/quoteLineItemAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  QUOTE_LINE_ITEM_SAVE,
  QUOTE_LINE_ITEM_SAVE_ERROR,
  QUOTE_LINE_ITEM_SAVED,
  QUOTE_LINE_ITEM_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectQuoteLineItemUi } from "../../selectors/quoteLineItemSelectors";

function* save(action) {
  try {
    const { id, quoteLineItem, prevQuoteLineItem } = action.payload;

    if (isEqual(quoteLineItem, prevQuoteLineItem)) return;

    yield put(createAction(QUOTE_LINE_ITEM_SAVING, { id }));

    const data = yield call(saveQuoteLineItemAPI, quoteLineItem);

    const { isDeleting, isEditing } = yield select(selectQuoteLineItemUi, {
      id
    });

    if (!isDeleting && !isEditing)
      yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(createAction(QUOTE_LINE_ITEM_SAVED, { id }));

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_LINE_ITEM_SAVE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchQuoteLineItemSave() {
  yield takeLatestBy(QUOTE_LINE_ITEM_SAVE, save, action => action.payload.id);
}
