import { find } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { costingMethodObj, timeAllocationMethodObj } from "st-shared/entities";
import { formatPercentage } from "st-shared/lib";

import {
  getJobCurrencyTotalInvoicedExTax,
  getJobCurrencyTotalLoggedCostExTax,
  getJobCurrencyTotalLoggedExpensesCostExTax,
  getJobCurrencyTotalLoggedExpensesExTax,
  getJobCurrencyTotalLoggedExTax,
  getJobCurrencyTotalLoggedTimeCostExTax as jobGetJobCurrencyTotalLoggedTimeCostExTax,
  getJobCurrencyTotalPlannedExpensesCostExTax,
  getJobCurrencyTotalPlannedExpensesExTax,
  getJobCurrencyTotalPlannedTimeExTax,
  getJobExchangeRate,
  isArchived,
  isComplete as isJobComplete,
} from "../../../../lib/entities/jobEntity";
import {
  getJobCurrencyTotalLoggedTimeCostExTax as jobItemGetJobCurrencyTotalLoggedTimeCostExTax,
  getTotalPlannedMinutes as getJobItemTotalPlannedMinutes,
  getTotalPlannedMoney,
  isComplete as isJobItemComplete,
} from "../../../../lib/entities/jobItemEntity";
import { getTotalPlannedMinutes as getJobItemUserTotalPlannedMinutes } from "../../../../lib/entities/jobItemUserEntity";
import {
  getCostRate,
  getDisplayName,
} from "../../../../lib/entities/userEntity";
import { selectJobItemRole } from "../../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { selectJobItemRoleIdsByJobItemId } from "../../../../state/entities/jobItemRole/selectors/selectJobItemRoleIdsByJobItemId";
import { selectRoleCostRate } from "../../../../state/entities/role/selectors/selectRoleCostRate";
import { selectJob } from "../../job";
import { selectJobCalculatedUnplannedLoggedTimeCostExTax } from "../../job/selectJobCalculatedUnplannedLoggedTimeCostExTax";
import { selectJobCurrencyFinalBudget } from "../../job/selectJobCurrencyFinalBudget";
import { selectJobItem } from "../../jobItem";
import { selectJobItemIdsByJobId } from "../../jobItem/jobItemIdsByJobId";
import { selectJobItemUser } from "../../jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectOrderedJobItemUserIdsByJobId } from "../../jobItemUser/selectOrderedJobItemUserIdsByJobId";
import { selectUser } from "../../user";

export const selectFinancialSummary = (state, { jobId }) => {
  let plannedProfitMarginFailureReason = null;
  let projectedProfitMarginFailureReason = null;

  const job = selectJob(state, { jobId });
  const jobItems = selectJobItemIdsByJobId(state, { jobId });
  const calculatedUnplannedLoggedTimeCostExTax =
    selectJobCalculatedUnplannedLoggedTimeCostExTax(state, { jobId });
  const jobCurrencyFinalBudget = selectJobCurrencyFinalBudget(state, { jobId });

  const jobPlannedSell = numeral(getJobCurrencyTotalPlannedTimeExTax(job)).add(
    numeral(getJobCurrencyTotalPlannedExpensesExTax(job)).value()
  );
  const jobPlannedCost = numeral(
    getJobCurrencyTotalPlannedExpensesCostExTax(job)
  );
  const jobPlannedFixedPriceItems = numeral(0);
  const jobCompleteProfit = numeral(0);
  const jobIncompleteProfit = numeral(0);
  const jobCompletePlannedSell = numeral(
    getJobCurrencyTotalLoggedExpensesExTax(job)
  );
  let jobFinalBudget;

  // Add in the expense's profit
  jobCompleteProfit
    .add(numeral(getJobCurrencyTotalLoggedExpensesExTax(job)).value())
    .subtract(numeral(getJobCurrencyTotalLoggedExpensesCostExTax(job)).value());

  // Subtract the cost of unplanned time from profit
  jobCompleteProfit.subtract(calculatedUnplannedLoggedTimeCostExTax);

  if (jobCurrencyFinalBudget > 0) {
    jobFinalBudget = numeral(jobCurrencyFinalBudget);
  } else {
    jobFinalBudget = numeral(jobPlannedSell.value());
  }

  const jobFinalBudgetLessIncompleteFixedPriceItems = numeral(
    jobFinalBudget.value()
  );

  const jobBudgetDifference = numeral(jobFinalBudget.value())
    .subtract(numeral(getJobCurrencyTotalPlannedTimeExTax(job)).value())
    .subtract(numeral(getJobCurrencyTotalPlannedExpensesExTax(job)).value());

  jobCompletePlannedSell.add(jobBudgetDifference.value());

  jobItems.forEach((jobItemId) => {
    const jobItem = selectJobItem(state, { jobItemId });
    const costingMethod = costingMethodObj(jobItem.costingMethod);
    let jobItemPlannedCost = 0;

    if (
      costingMethod.isBasedByValue() &&
      !(getJobItemTotalPlannedMinutes(jobItem) > 0)
    ) {
      jobPlannedFixedPriceItems.add(
        numeral(getTotalPlannedMoney(jobItem)).value()
      );

      if (!isJobItemComplete(jobItem)) {
        jobFinalBudgetLessIncompleteFixedPriceItems.subtract(
          numeral(getTotalPlannedMoney(jobItem)).value()
        );
      }
    }

    if (timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()) {
      const totalCostRate = numeral(0);

      const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, {
        jobItemId,
      });

      jobItemUserIds.forEach((jobItemUserId) => {
        const jobItemUser = selectJobItemUser(state, { jobItemUserId });
        const user = selectUser(state, { userId: jobItemUser.userId });
        totalCostRate.add(numeral(getCostRate(user)).value());
      });

      const jobItemRoleIds = selectJobItemRoleIdsByJobItemId(state, {
        jobItemId,
      });

      jobItemRoleIds.forEach((jobItemRoleId) => {
        const jobItemRole = selectJobItemRole(state, { jobItemRoleId });
        const roleCostRate = selectRoleCostRate(state, {
          roleId: jobItemRole.roleId,
        });
        totalCostRate.add(numeral(roleCostRate).value());
      });

      if (jobItemUserIds.length + jobItemRoleIds.length > 0) {
        const averageCostRate = totalCostRate
          .divide(jobItemUserIds.length + jobItemRoleIds.length)
          .value();
        jobItemPlannedCost = numeral(getJobItemTotalPlannedMinutes(jobItem))
          .divide(60)
          .multiply(averageCostRate)
          .value();
      }
    } else {
      const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, {
        jobItemId,
      });

      jobItemUserIds.forEach((jobItemUserId) => {
        const jobItemUser = selectJobItemUser(state, { jobItemUserId });
        const user = selectUser(state, { userId: jobItemUser.userId });

        const jobItemUserPlannedCost = numeral(getCostRate(user))
          .multiply(
            numeral(getJobItemUserTotalPlannedMinutes(jobItemUser)).value()
          )
          .divide(60);

        jobItemPlannedCost = numeral(jobItemPlannedCost)
          .add(jobItemUserPlannedCost.value())
          .value();
      });

      const jobItemRoleIds = selectJobItemRoleIdsByJobItemId(state, {
        jobItemId,
      });

      jobItemRoleIds.forEach((jobItemRoleId) => {
        const jobItemRole = selectJobItemRole(state, { jobItemRoleId });
        const roleCostRate = selectRoleCostRate(state, {
          roleId: jobItemRole.roleId,
        });

        const jobItemRolePlannedCost = numeral(roleCostRate)
          .multiply(numeral(jobItemRole.totalPlannedMinutes).value())
          .divide(60);

        jobItemPlannedCost = numeral(jobItemPlannedCost)
          .add(jobItemRolePlannedCost.value())
          .value();
      });
    }

    const jobCurrencyJobItemPlannedCost = numeral(jobItemPlannedCost).multiply(
      getJobExchangeRate(job)
    );

    let jobItemProjectedCost;

    if (
      numeral(jobItemGetJobCurrencyTotalLoggedTimeCostExTax(jobItem)).value() >
      jobCurrencyJobItemPlannedCost.value()
    ) {
      jobItemProjectedCost = numeral(
        jobItemGetJobCurrencyTotalLoggedTimeCostExTax(jobItem)
      );
    } else {
      jobItemProjectedCost = jobCurrencyJobItemPlannedCost;
    }

    const jobItemProjectedProfit = numeral(
      getTotalPlannedMoney(jobItem)
    ).subtract(jobItemProjectedCost.value());
    const jobItemProfit = numeral(getTotalPlannedMoney(jobItem)).subtract(
      numeral(jobItemGetJobCurrencyTotalLoggedTimeCostExTax(jobItem)).value()
    );

    if (isJobItemComplete(jobItem)) {
      jobCompleteProfit.add(jobItemProfit.value());
      jobCompletePlannedSell.add(
        numeral(getTotalPlannedMoney(jobItem)).value()
      );
    } else if (!costingMethod.isBasedByValue()) {
      jobIncompleteProfit.add(jobItemProjectedProfit.value());
    }

    jobPlannedCost.add(jobCurrencyJobItemPlannedCost.value());
  });

  const jobFinalBudgetLessFixedPriceItems = numeral(
    jobFinalBudget.value()
  ).subtract(jobPlannedFixedPriceItems.value());

  const jobInvoiceProjection = numeral(
    Math.max(
      jobFinalBudget.value(),
      numeral(getJobCurrencyTotalInvoicedExTax(job)).value()
    )
  );
  const jobInvoiceProjectionDifference = numeral(jobInvoiceProjection.value())
    .subtract(numeral(getJobCurrencyTotalPlannedTimeExTax(job)).value())
    .subtract(numeral(getJobCurrencyTotalPlannedExpensesExTax(job)).value());

  // Add in the invoice projection difference to the profit
  jobCompleteProfit.add(jobInvoiceProjectionDifference.value());

  const jobPlannedProfit = numeral(
    jobFinalBudgetLessFixedPriceItems.value()
  ).subtract(jobPlannedCost.value());
  const jobProjectedProfit = numeral(jobCompleteProfit).add(
    jobIncompleteProfit.value()
  );

  let jobPlannedProfitMargin;
  let jobProjectedProfitMargin;

  if (jobFinalBudgetLessFixedPriceItems.value() > 0) {
    jobPlannedProfitMargin = numeral(jobPlannedProfit.value())
      .divide(jobFinalBudgetLessFixedPriceItems.value())
      .multiply(100);
    jobProjectedProfitMargin = numeral(jobProjectedProfit.value())
      .divide(jobFinalBudgetLessIncompleteFixedPriceItems.value())
      .multiply(100);
  } else {
    jobPlannedProfitMargin = numeral(0);
    jobProjectedProfitMargin = numeral(0);
  }

  const jobPercentageComplete = numeral(jobCompletePlannedSell.value())
    .subtract(jobBudgetDifference.value())
    .divide(jobPlannedSell.value())
    .multiply(100);

  let jobProjectedProfitMarginConfidence;

  if (jobPercentageComplete.value() < 50) {
    jobProjectedProfitMarginConfidence = "Low";
  } else if (jobPercentageComplete.value() < 75) {
    jobProjectedProfitMarginConfidence = "Medium";
  } else {
    jobProjectedProfitMarginConfidence = "High";
  }

  if (!jobPlannedSell.value() > 0) {
    plannedProfitMarginFailureReason =
      "Job doesn't have any planned time or expenses";
  }

  const jobItemUserIds = selectOrderedJobItemUserIdsByJobId(state, { jobId });
  const userWithoutCostRates = find(jobItemUserIds, (jobItemUserId) => {
    const jobItemUser = selectJobItemUser(state, { jobItemUserId });
    const user = selectUser(state, { userId: jobItemUser.userId });
    return user.costRate === null || user.costRate === undefined;
  });

  if (userWithoutCostRates) {
    const jobItemUser = selectJobItemUser(state, {
      jobItemUserId: userWithoutCostRates,
    });
    const user = selectUser(state, { userId: jobItemUser.userId });
    plannedProfitMarginFailureReason = `${getDisplayName(
      user
    )} doesn't have a cost rate`;
    projectedProfitMarginFailureReason = `${getDisplayName(
      user
    )} doesn't have a cost rate`;
  }

  if (
    jobFinalBudgetLessFixedPriceItems.value() <
    jobFinalBudget.value() * 0.9
  ) {
    plannedProfitMarginFailureReason =
      "More than 10% of the job is fixed" +
      " value items without hours planned";
  }

  if (
    !(jobPlannedSell.value() > 0) &&
    !(getJobCurrencyTotalLoggedExTax(job) > 0)
  ) {
    projectedProfitMarginFailureReason = "Nothing planned/logged on this job";
  }

  let jobProfit = null;
  let jobProfitMargin = null;

  if (numeral(getJobCurrencyTotalInvoicedExTax(job)).value()) {
    jobProfit = numeral(getJobCurrencyTotalInvoicedExTax(job))
      .subtract(numeral(getJobCurrencyTotalLoggedCostExTax(job)).value())
      .value("0,0");
  }

  if (jobProfit !== null && getJobCurrencyTotalInvoicedExTax(job)) {
    jobProfitMargin = formatPercentage(
      (jobProfit / getJobCurrencyTotalInvoicedExTax(job)) * 100
    );
  }

  const financialSummary = {
    jobFinalBudget: jobFinalBudget.format("0,0"),
    jobFinalBudgetLessFixedPriceItems:
      jobFinalBudgetLessFixedPriceItems.format("0,0"),
    jobFinalBudgetLessIncompleteFixedPriceItems:
      jobFinalBudgetLessIncompleteFixedPriceItems.format("0,0"),
    jobPlannedSell: jobPlannedSell.format("0,0"),
    jobPlannedCost: jobPlannedCost.format("0,0"),
    jobPlannedProfit: jobPlannedProfit.format("0,0"),
    jobPlannedProfitMargin: formatPercentage(jobPlannedProfitMargin.value()),
    jobUnplannedHoursTrackedCostExTax: calculatedUnplannedLoggedTimeCostExTax,
    jobLoggedCostExTax: numeral(jobGetJobCurrencyTotalLoggedTimeCostExTax(job))
      .add(numeral(getJobCurrencyTotalLoggedExpensesCostExTax(job)).value())
      .format("0,0"),
    jobPercentageComplete: jobPercentageComplete.format("0,0"),
    jobCompletePlannedSell: jobCompletePlannedSell.format("0,0"),
    jobCompleteProfit: jobCompleteProfit.format("0,0"),
    jobIncompleteProfit: jobIncompleteProfit.format("0,0"),
    jobProjectedProfit: jobProjectedProfit.format("0,0"),
    jobProjectedProfitMargin: formatPercentage(
      jobProjectedProfitMargin.value()
    ),
    jobProjectedProfitMarginConfidence,
    plannedProfitMarginFailureReason,
    projectedProfitMarginFailureReason,
    jobProfit,
    jobProfitMargin,
  };

  financialSummary.displayPlannedProfitMargin =
    financialSummary.plannedProfitMarginFailureReason === null;

  financialSummary.displayProjectedProfitMargin =
    financialSummary.projectedProfitMarginFailureReason === null &&
    !isJobComplete(job) &&
    !isArchived(job);

  financialSummary.displayProfitMargin =
    financialSummary.jobProfitMargin !== null &&
    (isJobComplete(job) || isArchived(job));

  return financialSummary;
};

export const useFinancialSummary = (jobId) =>
  useSelector((state) => selectFinancialSummary(state, { jobId }));
