import { debounce, difference, first, last } from "lodash-es";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector, useStore } from "react-redux";
import { sharedEmitter } from "st-shared/lib";

import {
  addDays,
  getEachDay,
  getEndOfWeek,
  getStartOfWeek,
  getTodayDate,
  isBefore,
  subDays,
} from "../../../lib/dates";
import useDebounce from "../../../lib/hooks/useDebounce";
import {
  selectScheduleContentHeight,
  selectScheduleDayWidth,
} from "../../../redux/selectors/scheduleSelectors";
import { AppState } from "../../../state/AppState";
import {
  actionScheduleLoggedTimeFetchRequest,
  actionScheduleLoggedTimePurgeFetchRequest,
} from "../../../state/ui/schedule/actions";
import {
  useScheduleUiError,
  useScheduleUiRequestedLoggedTimes,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { selectVisibleUserIds } from "../../../state/ui/schedule/selectors/useVisibleUserIds";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import ScrollContextProvider from "../../modules/ScrollContexts/ScrollContextProvider";
import { useScheduleSideMenuWidth } from "../UserPreference/sideBarUserPreferences";

function getViewportSize(width: number) {
  return {
    width: window.innerWidth - width,
    height: window.innerHeight - ScheduleUi.View.HeaderHeight,
  };
}

interface ScheduleScrollProviderProps {
  children: ReactNode;
}

function ScheduleScrollProvider({ children }: ScheduleScrollProviderProps) {
  const requestedLoggedTimes = useScheduleUiRequestedLoggedTimes();
  const contentHeight: number = useSelector(selectScheduleContentHeight);
  const dayWidth: number = useSelector(selectScheduleDayWidth);
  const error = useScheduleUiError();
  const width = useScheduleSideMenuWidth();

  const [viewportSize, setViewportSize] = useState(getViewportSize(width));

  const dispatch = useDispatch();
  const store = useStore<AppState>();

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  function onWindowResize() {
    setViewportSize(getViewportSize(width));
  }

  useEffect(() => {
    setViewportSize(getViewportSize(width));
  }, [width]);

  const doFetch = debounce(
    (startDate: string, endDate: string, userIds: number[]) =>
      dispatch(
        actionScheduleLoggedTimeFetchRequest(startDate, endDate, userIds)
      ),
    ScheduleUi.FetchDebounce
  );

  function doPurgeFetch(startDate: string, endDate: string, userIds: number[]) {
    dispatch(
      actionScheduleLoggedTimePurgeFetchRequest(startDate, endDate, userIds)
    );
  }

  const previousDateRangeValues = useRef({
    viewportStartDate: "",
    viewportEndDate: "",
    scrollOffsetY: 0,
  });

  function checkDateRange(
    viewportStartDate: string,
    viewportEndDate: string,
    scrollOffsetY: number
  ) {
    previousDateRangeValues.current = {
      viewportStartDate,
      viewportEndDate,
      scrollOffsetY,
    };

    const startDate = subDays(viewportStartDate, 3);
    const endDate = addDays(viewportEndDate, 3);
    const viewportDates = getEachDay(startDate, endDate);

    const visibleUserIds = selectVisibleUserIds(
      store.getState(),
      Math.max(scrollOffsetY - viewportSize.height * 0.25, 0),
      viewportSize.height * 2.25
    );

    const userIdsToFetch: number[] = [];
    let fetchStartDate: string | null = null;
    let fetchEndDate: string | null = null;

    for (let i = 0; i < visibleUserIds.length; i++) {
      const userId = visibleUserIds[i];
      if (!requestedLoggedTimes.datesByUser[userId]) {
        userIdsToFetch.push(userId);
        fetchStartDate = startDate;
        fetchEndDate = endDate;
      } else {
        const newUserDates = difference(
          viewportDates,
          requestedLoggedTimes.datesByUser[userId]
        );

        if (newUserDates.length > 0) {
          userIdsToFetch.push(userId);

          const startDate = first(newUserDates)!;
          const endDate = last(newUserDates)!;

          if (!fetchStartDate || isBefore(startDate, fetchStartDate)) {
            fetchStartDate = startDate;
          }
          if (!fetchEndDate || isBefore(endDate, fetchEndDate)) {
            fetchEndDate = endDate;
          }
        }
      }
    }

    if (!error && fetchStartDate && fetchEndDate && userIdsToFetch.length > 0) {
      fetchStartDate = getStartOfWeek(fetchStartDate);
      fetchEndDate = getEndOfWeek(fetchEndDate);
      doFetch(fetchStartDate, fetchEndDate, userIdsToFetch);
    }

    sharedEmitter.emit("scheduleSelectDate", viewportStartDate);
  }

  const onNewDateRange = useDebounce(checkDateRange, 200);

  function purgeDates() {
    const { viewportStartDate, viewportEndDate, scrollOffsetY } =
      previousDateRangeValues.current;

    let fetchStartDate = subDays(viewportStartDate, 3);
    let fetchEndDate = addDays(viewportEndDate, 3);

    const userIdsToFetch = selectVisibleUserIds(
      store.getState(),
      Math.max(scrollOffsetY - viewportSize.height * 0.25, 0),
      viewportSize.height * 2.25
    );

    if (!error && fetchStartDate && fetchEndDate && userIdsToFetch.length > 0) {
      fetchStartDate = getStartOfWeek(fetchStartDate);
      fetchEndDate = getEndOfWeek(fetchEndDate);
      doPurgeFetch(fetchStartDate, fetchEndDate, userIdsToFetch);
    }

    idleTimer.reset();
  }

  const idleTimer = useIdleTimer({
    timeout: ScheduleUi.PurgeIdleTime,
    onIdle: purgeDates,
  });

  return (
    <ScrollContextProvider
      Context={ScheduleScrollContext}
      contentHeight={contentHeight + ScheduleUi.View.ContentTooltipReserveSpace}
      viewportWidth={viewportSize.width}
      viewportHeight={viewportSize.height}
      viewportOffset={{
        x: width,
        y: 0,
        b: 0,
      }}
      dayWidth={dayWidth}
      selectedDate={getTodayDate()}
      onNewDateRange={onNewDateRange}
    >
      {children}
    </ScrollContextProvider>
  );
}

export default ScheduleScrollProvider;
