import * as PropTypes from "prop-types";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { asString } from "st-shared/lib";

import { JOB_ITEM_STATUS_ID_SCHEDULED } from "../constants";
import { getTodayDate, isBefore } from "../dates";
import { entityIdType } from "../types/entityTypes";

export const jobPhaseShape = {
  id: entityIdType.isRequired,
  jobId: entityIdType.isRequired,
  orderId: PropTypes.number.isRequired,
  name: PropTypes.string,
};

export const jobPhaseEntityType = PropTypes.shape(jobPhaseShape);

export const getJobId = (entity) => entity.jobId;

export const getJobPhaseName = ({ name }) => name;

export const isOverdue = (phaseStatus, phaseEndDate) =>
  phaseStatus === JOB_ITEM_STATUS_ID_SCHEDULED &&
  phaseEndDate &&
  isBefore(phaseEndDate, getTodayDate());

export const getOrderId = ({ orderId }) => orderId;

export const getSearchString = (entity) => asString(getJobPhaseName(entity));

export const getAsJobPhaseOption = (entity) =>
  entity && {
    key: entity.id,
    value: getJobPhaseName(entity),
    optionType: LineItemOptionTypes.OptionsHeading,
    searchString: getSearchString(entity),
  };

export const createNewJobPhase = ({ id = null, jobId, orderId }) => ({
  id,
  jobId,
  orderId,
  name: "",
  visibilityPreference: null,
});

export const getAsPhaseOption = ({ id, name }) => ({
  id,
  name,
});
