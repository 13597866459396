import { call, put, takeEvery } from "redux-saga/effects";

import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { actionEntitiesReceived } from "../../../entities/actions";
import {
  actionScheduleLoggedTimeFetchError,
  actionScheduleLoggedTimeFetchSuccess,
  SCHEDULE_LOGGED_TIMES_FETCH_REQUEST,
  ScheduleLoggedTimeFetchRequestAction,
} from "../actions";
import ScheduleAPI from "../api";

function* fetch(action: ScheduleLoggedTimeFetchRequestAction) {
  try {
    const data: Awaited<
      ReturnType<typeof ScheduleAPI.fetchScheduleLoggedTimes>
    > = yield call(
      ScheduleAPI.fetchScheduleLoggedTimes,
      action.startDate,
      action.endDate,
      action.userIds
    );

    yield put(actionEntitiesReceived(data));
    yield put(
      actionScheduleLoggedTimeFetchSuccess(
        action.startDate,
        action.endDate,
        action.userIds
      )
    );
  } catch (error: unknown) {
    yield put(
      actionScheduleLoggedTimeFetchError(
        action.startDate,
        action.endDate,
        action.userIds,
        error
      )
    );

    sagaError(error);
  }
}

export default function* watchFetchScheduleLoggedTimes() {
  yield takeEvery(SCHEDULE_LOGGED_TIMES_FETCH_REQUEST, fetch);
}
