import MuiTextareaAutosize from "@material-ui/core/TextareaAutosize";
import styled from "styled-components";

const Textarea = styled(MuiTextareaAutosize)`
  width: 100%;
  color: black;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  padding: 8px;
  border: none;
  &::placeholder {
    color: var(--color-gray-dark);
  }
  &.Mui-disabled {
    background-color: var(--color-snow);
    color: var(--color-gray);
  }
`;

export default Textarea;
