import * as PropTypes from "prop-types";
import React from "react";
import { entityIdListType } from "../../../lib/types/entityTypes";
import CurrencyCircleIcon from "../../elements/Icons/CurrencyCircleIcon";
import Milestone from "../../modules/JobMilestone/Milestone";

class InvoiceMilestones extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired,
    invoiceIds: entityIdListType.isRequired
  };

  render() {
    const { invoiceIds } = this.props;
    return invoiceIds.map(id => (
      <Milestone key={`invoice-${id}`}>
        <CurrencyCircleIcon color="var(--color-gray-light)" />
      </Milestone>
    ));
  }
}

export default InvoiceMilestones;
