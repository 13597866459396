import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import { NUMBER_FORMAT_ABBREVIATED } from "../../../../lib/constants";
import {
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
  getTotalRemainingMinutes,
  getTotalRemainingMoney,
  getTotalScheduledMinutes,
  getTotalScheduledMoney,
  getTotalUsedMinutes,
  getTotalUsedMoney,
  getTotalUsedScheduledMinutes,
  getTotalUsedScheduledMoney,
  jobItemEntityType,
} from "../../../../lib/entities/jobItemEntity";
import { selectJobItemById } from "../../../../redux/selectors/jobItemSelectors";
import { selectScheduleUiScheduleTotalsMode } from "../../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiTotalsModes } from "../../../../state/ui/schedule/types";
import JobCurrency from "../../../modules/Currency/JobCurrency";
import Tooltip from "../../../modules/Tooltip";
import TooltipContentTable from "../../../modules/Tooltip/TooltipContentTable";
import {
  formatTotalHours,
  getTooltipExchangeRateInfo,
  TotalText,
} from "./index";

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props),
  scheduleTotalsMode: selectScheduleUiScheduleTotalsMode(state),
});

const JobItemTotals = ({ jobItem, scheduleTotalsMode }) => {
  const { jobId } = jobItem;
  const footer = getTooltipExchangeRateInfo(jobId);
  const usedScheduledHours = getTotalUsedScheduledMinutes(jobItem);
  const plannedHours = getTotalPlannedMinutes(jobItem);
  const scheduledHours = getTotalScheduledMinutes(jobItem);
  const usedHours = getTotalUsedMinutes(jobItem);
  const remainingHours = getTotalRemainingMinutes(jobItem);
  const usedScheduledMoney = getTotalUsedScheduledMoney(jobItem);
  const plannedMoney = getTotalPlannedMoney(jobItem);
  const scheduledMoney = getTotalScheduledMoney(jobItem);
  const usedMoney = getTotalUsedMoney(jobItem);
  const remainingMoney = getTotalRemainingMoney(jobItem);
  const tooltipContentArray = [
    [
      "Planned",
      formatTotalHours(plannedHours),
      <JobCurrency id={jobId} value={plannedMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={plannedMoney} hideHomeCurrency />,
    ],
    [
      "Used",
      formatTotalHours(usedHours),
      <JobCurrency id={jobId} value={usedMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={usedMoney} hideHomeCurrency />,
    ],
    [
      "Scheduled",
      formatTotalHours(scheduledHours),
      <JobCurrency id={jobId} value={scheduledMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={scheduledMoney} hideHomeCurrency />,
    ],
    [
      "Used+Scheduled",
      formatTotalHours(usedScheduledHours),
      <JobCurrency
        id={jobId}
        value={usedScheduledMoney}
        convertToHomeCurrency
      />,
      <JobCurrency id={jobId} value={usedScheduledMoney} hideHomeCurrency />,
    ],
    [
      "Remaining",
      formatTotalHours(remainingHours),
      <JobCurrency id={jobId} value={remainingMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={remainingMoney} hideHomeCurrency />,
    ],
  ];

  if (scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedHours)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There are <b>{formatTotalHours(usedScheduledHours)}</b> of
                logged and incomplete To Do’s
                <br />
                out of <b>{formatTotalHours(plannedHours)}</b> planned
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={usedScheduledHours > plannedHours}>
          {formatTotalHours(usedScheduledHours, true)} /{" "}
          {formatTotalHours(plannedHours, true)}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingHours)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There are <b>{formatTotalHours(scheduledHours)}</b> of
                incomplete To Do’s
                <br />
                out of <b>{formatTotalHours(remainingHours)}</b> remaining
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={remainingHours < 0 || scheduledHours > remainingHours}>
          {formatTotalHours(scheduledHours, true)} /{" "}
          {formatTotalHours(remainingHours, true)}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedMoney)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={usedScheduledMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                of sell value on incomplete and logged To Do’s
                <br />
                out of{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={plannedMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                planned
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={usedScheduledMoney > plannedMoney}>
          <JobCurrency
            id={jobId}
            value={usedScheduledMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
          {" / "}
          <JobCurrency
            id={jobId}
            value={plannedMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingMoney)
    return (
      <Tooltip
        placement="right"
        title={
          <TooltipContentTable
            contentArray={tooltipContentArray}
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={scheduledMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                of sell value on incomplete To Do’s
                <br />
                out of{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={remainingMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                remaining
              </span>
            }
            footer={footer}
          />
        }
      >
        <TotalText over={remainingMoney < 0 || scheduledMoney > remainingMoney}>
          <JobCurrency
            id={jobId}
            value={scheduledMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
          {" / "}
          <JobCurrency
            id={jobId}
            value={remainingMoney}
            convertToHomeCurrency
            format={NUMBER_FORMAT_ABBREVIATED}
          />
        </TotalText>
      </Tooltip>
    );

  return null;
};

JobItemTotals.propTypes = {
  jobItem: jobItemEntityType.isRequired,
  scheduleTotalsMode: PropTypes.oneOf(values(ScheduleUiTotalsModes)).isRequired,
};

export default connect(mapState)(JobItemTotals);
