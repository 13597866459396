import MuiTooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { minMax } from "../../../lib/math";
import { reactNodesType, reactRefType } from "../../../lib/types/reactTypes";

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

export const defaultStyles = {
  tooltip: {
    position: "relative",
    backgroundColor: "black",
    padding: 15,
    fontFamily: "inherit",
    color: "white",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 0,
    maxWidth: "initial",
    minWidth: 100
  },
  arrow: {
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  popper: arrowGenerator("black")
};

const useStyles = makeStyles({
  tooltip: props => ({
    ...defaultStyles.tooltip,
    ...(props.styles && props.styles.tooltip)
  }),
  arrow: props => ({
    ...defaultStyles.arrow,
    ...(props.styles && props.styles.arrow)
  }),
  popper: props => ({
    ...defaultStyles.popper,
    ...(props.styles && props.styles.popper)
  })
});

let mouse = { pageX: 0, pageY: 0 };

const createReferenceObject = useCursorPosition => {
  if (!useCursorPosition) return null;

  return {
    clientWidth: 0,
    clientHeight: 0,
    getBoundingClientRect() {
      const clientRect = useCursorPosition.anchorRef.current.getBoundingClientRect();
      const defaultRect = {
        bottom: clientRect.bottom,
        height: clientRect.height,
        left: clientRect.left,
        right: clientRect.right,
        top: clientRect.top,
        width: clientRect.width,
        x: clientRect.x,
        y: clientRect.y
      };

      const x = minMax(mouse.pageX, clientRect.left, clientRect.right);
      const y = minMax(mouse.pageY, clientRect.top, clientRect.bottom);
      return {
        ...defaultRect,
        ...(useCursorPosition.x && {
          width: 0,
          left: x,
          right: x,
          x
        }),
        ...(useCursorPosition.y && {
          height: 0,
          top: y,
          bottom: y,
          y
        })
      };
    }
  };
};

const Tooltip = forwardRef(
  (
    {
      title,
      useCursorPosition,
      open,
      children,
      enabled,
      interactive,
      ...props
    },
    ref
  ) => {
    const { arrow, ...classes } = useStyles(props);
    const [arrowRef, setArrowRef] = React.useState(null);
    const [isHovered, setIsHovered] = React.useState(false);
    const [isForceClosed, setIsForceClosed] = React.useState(false);

    if (!title) return children;

    const popperOptions = {
      modifiers: {
        arrow: {
          enabled: Boolean(arrowRef),
          element: arrowRef
        }
      },
      onCreate({ instance }) {
        setIsForceClosed(false);
        document.onmousemove = ({ pageX, pageY }) => {
          mouse = { pageX, pageY };
          instance.scheduleUpdate();
        };
      }
    };

    const popperAnchor = createReferenceObject(useCursorPosition);

    const popperProps = {
      popperOptions,
      ...(popperAnchor && { anchorEl: popperAnchor })
    };

    const handleTooltipClose = () => {
      setIsHovered(false);
    };

    const handleTooltipOpen = () => {
      setIsHovered(true);
    };

    const handleForceTooltipClose = () => {
      setIsForceClosed(true);
      handleTooltipClose();
    };

    const Title = (
      <div onClick={handleForceTooltipClose}>
        {title}
        <span className={arrow} ref={setArrowRef} />
      </div>
    );

    return (
      <MuiTooltip
        classes={classes}
        PopperProps={popperProps}
        title={Title}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={open || (enabled && isHovered)}
        interactive={interactive && !isForceClosed}
        {...props}
      >
        {children}
      </MuiTooltip>
    );
  }
);

Tooltip.propTypes = {
  title: reactNodesType,
  children: reactNodesType.isRequired,
  useCursorPosition: PropTypes.shape({
    x: PropTypes.bool.isRequired,
    y: PropTypes.bool.isRequired,
    anchorRef: reactRefType.isRequired
  }),
  open: PropTypes.bool,
  enabled: PropTypes.bool,
  interactive: PropTypes.bool
};

Tooltip.defaultProps = {
  title: "",
  useCursorPosition: null,
  open: false,
  enabled: true,
  interactive: false
};

export default Tooltip;
