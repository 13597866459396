import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";
import { JobStatuses } from "st-shared/entities/JobStatus";

import { updateJobStatusAPI } from "../../../lib/API/jobAPI";
import { ENTITIES_RECEIVED, JOB_STATUS_CHANGE } from "../../../lib/constants";
import {
  feToWebConfirmCompleteJob,
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { getTotalIncompleteMinutes } from "../../../lib/entities/jobEntity";

function* changeJobStatus(action) {
  const { jobId, jobStatusId } = action.payload;

  try {
    if (jobStatusId === JobStatuses.Complete) {
      const job = yield select(selectJob, {
        jobId,
      });

      const totalIncompleteMinutes = getTotalIncompleteMinutes(job);

      yield call(feToWebConfirmCompleteJob, totalIncompleteMinutes);
    }

    feToWebShowPageLoadingMask(11);

    const { data } = yield call(updateJobStatusAPI, jobId, jobStatusId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    if (isError(error)) sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchJobStatusChange() {
  yield takeLatestBy(
    [JOB_STATUS_CHANGE],
    changeJobStatus,
    (action) => action.payload.jobId
  );
}
