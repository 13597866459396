import clsx from "clsx";
import {
  ComponentPropsWithRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { Tooltip } from "../../components";
import { ellipsisText } from "../../theme";
import { tooltipTitleSmall } from "./EllipsisTooltipText.css";

export type Props = {
  children: NonNullable<ReactNode>;
} & (
  | (ComponentPropsWithRef<"span"> & { as?: "span" })
  | (ComponentPropsWithRef<"a"> & { as: "a" })
);

export function EllipsisTooltipText({
  className,
  children,
  as: Component = "span",
  ...props
}: Props) {
  const ref = useRef<HTMLSpanElement | HTMLAnchorElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setShowTooltip(entry.target.scrollWidth > entry.target.clientWidth);
      });
    });
    resizeObserver.observe(ref.current!);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Tooltip
      placement="top"
      title={children}
      tooltipClassName={tooltipTitleSmall}
      open={tooltipOpen && showTooltip}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <Component
        ref={ref as any}
        className={clsx(ellipsisText, className)}
        {...props}
      >
        {children}
      </Component>
    </Tooltip>
  );
}
