import {
  createStore,
  type TStore,
  type TStoreCreator,
  type TStoreGetter,
  type TStoreSetter,
} from "./createStore";

export type TActionStore<S, A> = {
  store: TStore<S>;
  actions: A;
};

export type TActionCreator<S, A> = (
  set: TStoreSetter<S>,
  get: TStoreGetter<S>
) => A;

export function createActionStore<S, A>(
  initializer: TStoreCreator<S>,
  actionCreator: TActionCreator<S, A>
): TActionStore<S, A> {
  const store = createStore(initializer);
  const actions = actionCreator(store.setState, store.getState);
  return { store, actions };
}
