import { useSelector } from "react-redux";
import { getUserId } from "../../../lib/entities/jobItemUserEntity";
import { selectUser } from "../user";
import { selectJobItemUser } from "./index";

export const selectUserByJobItemUserId = (state, { jobItemUserId }) => {
  const userId = getUserId(selectJobItemUser(state, { jobItemUserId }));
  return selectUser(state, { userId });
};

export const useUserByJobItemUserId = jobItemUserId =>
  useSelector(state => selectUserByJobItemUserId(state, { jobItemUserId }));
