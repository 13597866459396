import * as PropTypes from "prop-types";
import { DATE_FORMAT_MONTH_YEAR_ISO } from "../constants";
import { formatDate } from "../dates";
import { entityIdType } from "../types/entityTypes";

export const jobMilestoneShape = {
  id: entityIdType.isRequired,
  jobId: entityIdType.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string
};

export const jobMilestoneType = PropTypes.shape(jobMilestoneShape);

export const getJobIdDateKeyPath = ({ jobId, date }) => `["${jobId}.${date}"]`;

export const getJobIdMonthKeyPath = ({ jobId, date }) =>
  `["${jobId}.${formatDate(date, DATE_FORMAT_MONTH_YEAR_ISO)}"]`;

export const getDate = ({ date }) => date;

export function getUpcomingDates(entity) {
  return {
    id: `milestone-${entity.id}`,
    entityId: entity.id,
    type: "milestone",
    name: entity.name,
    date: entity.date
  };
}
