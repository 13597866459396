import {
  BoldButton,
  CodeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from "@draft-js-plugins/buttons";
import React from "react";
import Flex from "../../../../elements/Flex";
import Separator from "../Separator";
import buttonStyles from "../buttonStyles.module.css";
import createStore from "../createStore";
import Toolbar from "./Toolbar";
import toolbarStyles from "./toolbarStyles.module.css";

const createStaticToolbarPlugin = () => {
  const store = createStore({});

  const theme = { buttonStyles, toolbarStyles };

  const StaticToolbar = props => (
    <Toolbar {...props} store={store} theme={theme}>
      {externalProps => (
        <>
          <Flex align="center">
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <CodeButton {...externalProps} />
            <Separator />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
          </Flex>
        </>
      )}
    </Toolbar>
  );

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.updateItem("getEditorState", getEditorState);
      store.updateItem("setEditorState", setEditorState);
      store.updateItem("getEditorRef", getEditorRef);
    },

    // Re-Render the text-toolbar on selection change
    onChange: editorState => {
      store.updateItem("selection", editorState.getSelection());
      return editorState;
    },
    StaticToolbar
  };
};

export default createStaticToolbarPlugin;
