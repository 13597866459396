import { Select, SelectItem, SelectKey } from "st-shared/components";
import { ReportingColumn } from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumnsAllowed,
  useReportingSavedSegmentColumnsAllowedTimeTable,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedColumnId,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";

export function SelectedColumnSelector() {
  const selectedColumnId = useReportingSavedSegmentSelectedColumnId();
  const orderedColumns = useReportingSavedSegmentColumnsAllowed();

  if (orderedColumns.length === 0) return null;

  function onSelectionChange(value: SelectKey) {
    const id = value as ReportingColumn["id"];

    if (id !== selectedColumnId) {
      reportingStore().savedSegment.actions.setSelectedColumnId(id);
    }
  }

  return (
    <Select
      aria-label="Selected column selector"
      items={orderedColumns}
      placeholder="Select Column"
      selectedKey={selectedColumnId}
      onSelectionChange={onSelectionChange}
      style={{ marginRight: 8 }}
    >
      {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
    </Select>
  );
}

export function SelectedTimeSelector() {
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  const searchType = useReportingSavedSegmentSearchType();
  const tableColumns = useReportingSavedSegmentColumnsAllowedTimeTable();

  if (
    tableColumns.length === 0 ||
    searchType !== ReportingSearchType.TimeSeries
  )
    return null;

  function onSelectionChange(value: SelectKey) {
    const id = value as ReportingColumn["id"];

    if (id !== selectedTimeId) {
      reportingStore().savedSegment.actions.setSelectedTimeId(id);
    }
  }

  return (
    <Select
      aria-label="Selected column selector"
      items={tableColumns}
      placeholder="Select Column"
      selectedKey={selectedTimeId}
      onSelectionChange={onSelectionChange}
      style={{ marginRight: 8 }}
    >
      {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
    </Select>
  );
}
