import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  ENTITY_NAME_COMPANY_OPTIONS
} from "../../../lib/constants";

export const selectStateCompanyOptions = state =>
  state.ui[ENTITY_NAME_COMPANY_OPTIONS];

export const selectCompanyOptions = createSelector(
  selectStateCompanyOptions,
  companyOptions => get(companyOptions, `options`, EMPTY_ARRAY)
);

export const useCompanyOptions = () => useSelector(selectCompanyOptions);
