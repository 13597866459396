import { connect } from "react-redux";

import {
  INVOICE_ADDRESS_CREATE,
  INVOICE_CONTACT_CREATE_EDIT,
  INVOICE_EDIT,
  INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN,
  INVOICE_LINE_ITEM_ADD_ITEM,
  INVOICE_LINE_ITEM_ADD_LINE,
  INVOICE_LINE_ITEM_DELETE,
  INVOICE_LINE_ITEM_EDIT,
  INVOICE_LINE_ITEM_SAVE,
  INVOICE_LINE_ITEM_SET_IS_MERGING,
  INVOICE_LINE_ITEM_SORT_MOVE,
  INVOICE_LINE_ITEM_TAX_RATE_CREATE,
  INVOICE_LINE_ITEM_UNDO_MERGE,
  INVOICE_SAVE,
  INVOICE_SET_ADDRESS,
  INVOICE_SET_CONTACT,
} from "../../../../lib/constants";
import { invoiceDocumentMethods } from "../../../../lib/entities/invoiceEntity";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import { documentLineItemMethods } from "../../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../../../redux/helpers/createAction";
import {
  selectInvoiceById,
  selectInvoiceJobBranchIdById,
  selectInvoiceJobCompanyById,
  selectInvoiceJobNumberById,
  selectInvoiceJobRateCardIdById,
  selectInvoicesUiById,
  selectInvoiceUi,
} from "../../../../redux/selectors/invoiceSelectors";
import CommercialDocumentContextProvider from "./CommercialDocumentContextProvider";

const mapState = (state, props) => ({
  commercialDocumentType: COMMERCIAL_DOCUMENT_TYPES.INVOICE,
  pageUi: selectInvoiceUi(state),
  document: selectInvoiceById(state, props),
  documentUi: selectInvoicesUiById(state, props),
  branchId: selectInvoiceJobBranchIdById(state, props),
  rateCardId: selectInvoiceJobRateCardIdById(state, props),
  jobCompany: selectInvoiceJobCompanyById(state, props),
  jobNumber: selectInvoiceJobNumberById(state, props),
  documentMethods: invoiceDocumentMethods,
  documentLineItemMethods,
});

const mapDispatch = (dispatch) => ({
  doEditDocument: (id, invoice, prevInvoice) => {
    dispatch(createAction(INVOICE_EDIT, { id, invoice, prevInvoice }));
  },
  doSaveDocument: (id, invoice, prevInvoice) => {
    dispatch(createAction(INVOICE_SAVE, { id, invoice, prevInvoice }));
  },
  doSetAddress: (id, addressId) => {
    dispatch(createAction(INVOICE_SET_ADDRESS, { id, addressId }));
  },
  doSetContact: (id, contactId) => {
    dispatch(createAction(INVOICE_SET_CONTACT, { id, contactId }));
  },
  doCreateContact: (id, company) => {
    dispatch(createAction(INVOICE_CONTACT_CREATE_EDIT, { id, company }));
  },
  doEditContact: (id, contact) => {
    dispatch(createAction(INVOICE_CONTACT_CREATE_EDIT, { id, contact }));
  },
  doCreateAddress: (id, company) => {
    dispatch(createAction(INVOICE_ADDRESS_CREATE, { id, company }));
  },
  doMergeLineItemsUndo: (invoiceId, snapshotId) =>
    dispatch(
      createAction(INVOICE_LINE_ITEM_UNDO_MERGE, { invoiceId, snapshotId })
    ),
  doSortLineItemsMove: (invoiceId, sourceOrderId, destinationOrderId) =>
    dispatch(
      createAction(INVOICE_LINE_ITEM_SORT_MOVE, {
        invoiceId,
        sourceOrderId,
        destinationOrderId,
      })
    ),
  doEditDocumentLineItem: (id, invoiceLineItem) => {
    dispatch(createAction(INVOICE_LINE_ITEM_EDIT, { id, invoiceLineItem }));
  },
  doSaveDocumentLineItem: (id, invoiceLineItem, prevInvoiceLineItem) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_SAVE, {
        id,
        invoiceLineItem,
        prevInvoiceLineItem,
      })
    );
  },
  doDeleteDocumentLineItem: (id, invoiceLineItem) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_DELETE, {
        id,
        invoiceLineItem,
      })
    );
  },
  doToggleMergeDocumentLineItem: (id, isMerging) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_SET_IS_MERGING, {
        id,
        isMerging,
      })
    );
  },
  doDocumentLineItemCreateTaxRate: (id) => {
    dispatch(createAction(INVOICE_LINE_ITEM_TAX_RATE_CREATE, { id }));
  },
  doAddLine: (invoiceId, orderId, isHeading) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_ADD_LINE, {
        invoiceId,
        orderId,
        isHeading,
      })
    );
  },
  doAddMasterJobItem: (invoiceId, orderId, masterJobItemId) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_ADD_ITEM, {
        invoiceId,
        orderId,
        masterJobItemId,
      })
    );
  },
  doAddMasterLoggedExpense: (invoiceId, orderId, masterLoggedExpenseId) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_ADD_ITEM, {
        invoiceId,
        orderId,
        masterLoggedExpenseId,
      })
    );
  },
  doAddFromJobPlan: (invoiceId, orderId, entityId, optionType) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN, {
        invoiceId,
        orderId,
        entityId,
        optionType,
      })
    );
  },
});

const InvoiceContextProvider = connect(
  mapState,
  mapDispatch
)(CommercialDocumentContextProvider);

export default InvoiceContextProvider;
