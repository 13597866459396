import {
  getUserPreference,
  setUserPreference,
  UserPreferenceKeys,
  useUserPreference,
} from "st-shared/stores";

import {
  ReportingSidebarTabs,
  TReportingSidebarTabs,
} from "../../consts/ReportingSidebarTabs.ts";

const key = UserPreferenceKeys.ReportingSideSelectedTab;

function transformValue(value: string | undefined): TReportingSidebarTabs {
  if (typeof value === "undefined" || value.length === 0) {
    return ReportingSidebarTabs.Favourites;
  }
  return Number(value) as TReportingSidebarTabs;
}

export function useReportingSideMenuSelectedTab() {
  const userPreference = useUserPreference(key);
  return transformValue(userPreference);
}

export function getReportingSideMenuSelectedTab() {
  const userPreference = getUserPreference(key);
  return transformValue(userPreference);
}

export function setReportingSideMenuSelectedTab(
  selectedTab: TReportingSidebarTabs
) {
  setUserPreference(key, selectedTab);
}
