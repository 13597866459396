import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";

import {
  appendSearchStringToOption,
  appendSearchStringToOptions,
} from "../../../../lib/ui/commercialDocumentLineItems";
import { selectMasterJobItemOptions } from "./selectMasterJobItemOptions";

export const selectMasterJobItemGroupedOptions = createSelector(
  [selectMasterJobItemOptions],
  (masterJobItemOptions) => {
    const options = [];

    const unitRateOptions = masterJobItemOptions.filter(
      ({ optionType }) => optionType === LineItemOptionTypes.UnitItem
    );

    const fixedPriceOptions = masterJobItemOptions.filter(
      ({ optionType }) => optionType === LineItemOptionTypes.FixedItem
    );

    const priceByPersonOptions = masterJobItemOptions.filter(
      ({ optionType }) => optionType === LineItemOptionTypes.PeopleItem
    );

    if (unitRateOptions.length) {
      const searchStrings = unitRateOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: LineItemOptionTypes.UnitItem,
        value: "Price by item",
        optionType: LineItemOptionTypes.OptionsHeading,
        searchString: "Price by item",
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          unitRateOptions,
          headingOption.searchString
        )
      );
    }

    if (priceByPersonOptions.length) {
      const searchStrings = priceByPersonOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: LineItemOptionTypes.PeopleItem,
        value: "Price by person",
        optionType: LineItemOptionTypes.OptionsHeading,
        searchString: "Price by person",
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          priceByPersonOptions,
          headingOption.searchString
        )
      );
    }

    if (fixedPriceOptions.length) {
      const searchStrings = fixedPriceOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: LineItemOptionTypes.FixedItem,
        value: "Fixed price",
        optionType: LineItemOptionTypes.OptionsHeading,
        searchString: "Fixed price",
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          fixedPriceOptions,
          headingOption.searchString
        )
      );
    }

    return options;
  }
);

export function useMasterJobItemGroupedOptions() {
  return useSelector(selectMasterJobItemGroupedOptions);
}
