import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const LogIcon = ({ ...props }) => (
  <SvgIcon viewBox="-4 -4 48 48" {...props}>
    <path d="M19.908 0C8.958 0 0 9 0 20s8.959 20 19.908 20c10.95 0 19.908-9 19.908-20S30.857 0 19.908 0zm0 37.333c-9.49 0-17.254-7.8-17.254-17.333 0-9.533 7.764-17.333 17.254-17.333 9.49 0 17.253 7.8 17.253 17.333 0 9.533-7.764 17.333-17.253 17.333z" />
    <path d="M26.013 15.467L19.908 21.6l-6.105-6.133-1.858 1.866 7.963 8 7.963-8z" />
  </SvgIcon>
);

LogIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

LogIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(LogIcon);
