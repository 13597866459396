import { ValueFormatEnum } from "@streamtimefe/entities";
import { useMemo, useRef, useState } from "react";
import {
  Icon,
  IconSize,
  Md123,
  MdAttachMoney,
  MdPercent,
  MdSchedule,
  MdVisibility,
  MdVisibilityOff,
  PopoverMenu,
} from "st-shared/components";
import { CReportingSavedSegment } from "st-shared/entities";
import { pluralise } from "st-shared/lib";

import { reportingStore } from "../../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumnsTimeSeries,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentSelectedTimeIds,
} from "../../../state/stores/savedSegmentSelectors";
import {
  checkedCss,
  columnSelectorButton,
  columnSelectorIcon,
  dataSetIconCss,
  formatSelector,
  formatSelectorButton,
  formatSelectorButtonIcon,
  listItemCss,
  listItemTextCss,
  listNameCss,
  popoverCss,
  popoverMenuCss,
} from "./TimeSeriesColumnSelector.css";
import type { TTimeSeriesFormats } from "./types";
import { TimeSeriesFormats } from "./types";

export function TimeSeriesColumnSelector() {
  const dataSets = useReportingSavedSegmentDataSets();
  const { filteredColumns, groupedColumns } =
    useReportingSavedSegmentColumnsTimeSeries();
  const selectedTimeIds = useReportingSavedSegmentSelectedTimeIds();

  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [_selectedFormat, setSelectedFormat] = useState<TTimeSeriesFormats>(
    TimeSeriesFormats[0]
  );
  const selectedFormat = useMemo(() => {
    if (_selectedFormat in groupedColumns) {
      return _selectedFormat;
    }
    for (const format of TimeSeriesFormats) {
      if (format in groupedColumns) {
        return format;
      }
    }
    return _selectedFormat;
  }, [_selectedFormat, groupedColumns]);

  if (filteredColumns.length === 0) return null;

  function openMenu() {
    setAnchorEl(ref.current);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onSetColumn(columnId: string) {
    if (selectedTimeIds.includes(columnId)) {
      const newIds = selectedTimeIds.filter((id) => id !== columnId);
      reportingStore().savedSegment.actions.setSelectedTimeIds(newIds);
    } else {
      const newIds = [...selectedTimeIds, columnId];
      reportingStore().savedSegment.actions.setSelectedTimeIds(newIds);
    }
  }

  return (
    <>
      <div className={columnSelectorButton} ref={ref} onClick={openMenu}>
        <span>
          {selectedTimeIds.length === filteredColumns.length
            ? "Showing all columns"
            : pluralise(
                selectedTimeIds.length,
                `Showing ${selectedTimeIds.length} column`
              )}
        </span>
        <Icon
          icon={MdVisibility}
          className={columnSelectorIcon}
          size={IconSize.Large}
        />
      </div>
      <PopoverMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={closeMenu}
        menuListClassName={popoverMenuCss}
        paperRootClassName={popoverCss}
      >
        {Object.keys(groupedColumns).length > 1 && (
          <div className={formatSelector}>
            {ValueFormatEnum.Currency in groupedColumns && (
              <div
                className={formatSelectorButton}
                data-selected={selectedFormat === ValueFormatEnum.Currency}
                onClick={() => setSelectedFormat(ValueFormatEnum.Currency)}
              >
                <Icon
                  icon={MdAttachMoney}
                  size={IconSize.Medium}
                  className={formatSelectorButtonIcon}
                />
                <span>Currency</span>
              </div>
            )}
            {ValueFormatEnum.Minutes in groupedColumns && (
              <div
                className={formatSelectorButton}
                data-selected={selectedFormat === ValueFormatEnum.Minutes}
                onClick={() => setSelectedFormat(ValueFormatEnum.Minutes)}
              >
                <Icon
                  icon={MdSchedule}
                  size={IconSize.Medium}
                  className={formatSelectorButtonIcon}
                />
                <span>Hours</span>
              </div>
            )}
            {ValueFormatEnum.Number in groupedColumns && (
              <div
                className={formatSelectorButton}
                data-selected={selectedFormat === ValueFormatEnum.Number}
                onClick={() => setSelectedFormat(ValueFormatEnum.Number)}
              >
                <Icon
                  icon={Md123}
                  size={IconSize.Medium}
                  className={formatSelectorButtonIcon}
                  style={{ transform: "scale(1.75)", marginRight: 4 }}
                />
                <span>Numeral</span>
              </div>
            )}
            {ValueFormatEnum.Percentage in groupedColumns && (
              <div
                className={formatSelectorButton}
                data-selected={selectedFormat === ValueFormatEnum.Percentage}
                onClick={() => setSelectedFormat(ValueFormatEnum.Percentage)}
              >
                <Icon
                  icon={MdPercent}
                  size={IconSize.Medium}
                  className={formatSelectorButtonIcon}
                />
                <span>Percentage</span>
              </div>
            )}
          </div>
        )}
        {groupedColumns[selectedFormat]?.map((column) => {
          const columnName = column.name;
          const columnIcon = CReportingSavedSegment.getColumnIcon(
            column,
            dataSets
          );

          const selected = selectedTimeIds.includes(column.id);

          function setColumn() {
            onSetColumn(column.id);
          }

          return (
            <div
              key={column.id}
              className={listItemCss}
              onClick={setColumn}
              data-selected={selected}
            >
              <div className={listNameCss}>
                <Icon icon={columnIcon} size={14} className={dataSetIconCss} />
                <span className={listItemTextCss}>{columnName}</span>
              </div>
              <div className={checkedCss}>
                <Icon
                  icon={selected ? MdVisibility : MdVisibilityOff}
                  size={IconSize.Small}
                />
              </div>
            </div>
          );
        })}
      </PopoverMenu>
    </>
  );
}
