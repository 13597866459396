import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsIsActivityDrawerOpen = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.isActivityDrawerOpen
);

export const useJobDetailsIsActivityDrawerOpen = () =>
  useSelector(selectJobDetailsIsActivityDrawerOpen);
