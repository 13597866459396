import type { ReactNode } from "react";

import { useScheduleSideMenuWidth } from "../UserPreference/sideBarUserPreferences";
import { scheduleBodyColumnCellContainer } from "./ScheduleBodyColumnCell.css";

type ScheduleBodyColumnCellProps = {
  children: ReactNode;
};

export function ScheduleBodyColumnCell({
  children,
}: ScheduleBodyColumnCellProps) {
  const width = useScheduleSideMenuWidth();

  return (
    <div
      className={scheduleBodyColumnCellContainer}
      style={{ width: `calc(100% - ${width}px)` }}
    >
      {children}
    </div>
  );
}
