import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import ChecklistIcon from "../../elements/Icons/custom/ChecklistIcon";
import EditUnderlineIcon from "../../elements/Icons/custom/EditUnderlineIcon";
import PersonIcon from "../../elements/Icons/PersonIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { FullWidthBaseItem } from "../styles";

const ActionsBar = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const [teamMemberRef] = useFocusStateRef({
    jobItemId,
    key: FOCUS_KEYS.ACTION_BUTTON_TEAM_MEMBER,
  });
  const [checklistRef] = useFocusStateRef({
    jobItemId,
    key: FOCUS_KEYS.ACTION_BUTTON_CHECKLIST,
  });
  const [additionalInformationRef] = useFocusStateRef({
    jobItemId,
    key: FOCUS_KEYS.ACTION_BUTTON_ADDITIONAL_INFORMATION,
  });

  const setFieldFocused = (key) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
        currentFocus: {
          jobItemId,
          key,
        },
        delayTime: 20,
      })
    );
  };

  const clickTeamMember = () => setFieldFocused(FOCUS_KEYS.ITEM_NEW_USER);

  const clickChecklist = () => setFieldFocused(FOCUS_KEYS.ITEM_NEW_SUB_ITEM);

  const clickAdditionalInformation = () =>
    setFieldFocused(FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION);

  const onEnter = (e, callback) => {
    if (e.code === "Enter") callback();
  };

  return (
    <Container>
      <AddSpan>Add/edit</AddSpan>
      <Button
        ref={teamMemberRef}
        onKeyDown={(e) => onEnter(e, clickTeamMember)}
        onMouseDown={clickTeamMember}
      >
        <Icon>
          <PersonIcon size={22} />
        </Icon>
        <ButtonSpan>team member</ButtonSpan>
      </Button>
      <Button
        ref={checklistRef}
        onKeyDown={(e) => onEnter(e, clickChecklist)}
        onMouseDown={clickChecklist}
      >
        <Icon>
          <ChecklistIcon size={20} />
        </Icon>
        <ButtonSpan>checklist item</ButtonSpan>
      </Button>
      <Button
        ref={additionalInformationRef}
        onKeyDown={(e) => onEnter(e, clickAdditionalInformation)}
        onMouseDown={clickAdditionalInformation}
      >
        <Icon>
          <EditUnderlineIcon size={18} />
        </Icon>
        <ButtonSpan>additional information</ButtonSpan>
      </Button>
    </Container>
  );
};

export default ActionsBar;

const Container = styled(FullWidthBaseItem)`
  background-color: var(--color-gray-bright);
  color: var(--color-gray-dark);
  padding-left: 20px;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  align-items: center;

  & > * {
    margin-right: 15px;
  }
`;

const AddSpan = styled.span`
  padding-top: 2px;
`;

const Button = styled.button`
  color: var(--color-gray-dark);
  height: 30px;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-decoration: underline;
  transition: var(--transition-mui-color);

  &:hover {
    color: var(--color-charcoal);
  }
  &:focus {
    color: var(--color-charcoal);
  }
`;

const ButtonSpan = styled.span`
  transition: var(--transition-mui-color);
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 5px;
`;
