import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { getTotalLoggedMinutes } from "../../../lib/entities/jobEntity";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobCalculatedTotalPlannedMinutes } from "../job/selectJobCalculatedTotalPlannedMinutes";
import { selectHasJobItemIdsByJobId } from "../jobItem/hasJobItemIdsByJobId";

export const selectJumbotronUsedPlannedTimeHoursDisplay = createCachedSelector(
  [
    selectJob,
    selectHasJobItemIdsByJobId,
    selectJobCalculatedTotalPlannedMinutes,
  ],
  (job, hasJobItems, calculatedTotalPlannedMinutes) => {
    let unit = null;
    let plannedMinutesDisplay = null;

    // If we have planned more than 100 hours, let's switch to summary mode
    if (calculatedTotalPlannedMinutes / 60 > 100) unit = "h";

    const loggedMinutesDisplay = convertMinutesToTimeHM(
      getTotalLoggedMinutes(job),
      false,
      unit
    );

    if (hasJobItems) {
      plannedMinutesDisplay = convertMinutesToTimeHM(
        calculatedTotalPlannedMinutes,
        false,
        unit
      );
    }

    return {
      loggedMinutes: getTotalLoggedMinutes(job),
      plannedMinutes: calculatedTotalPlannedMinutes,
      loggedMinutesDisplay,
      plannedMinutesDisplay,
    };
  }
)(selectPropsJobId);

export const useJumbotronUsedPlannedTimeHoursDisplay = (jobId) =>
  useSelector((state) =>
    selectJumbotronUsedPlannedTimeHoursDisplay(state, { jobId })
  );
