import { produce } from "immer";
import { get, isEmpty, setWith, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { simpleCompareDesc } from "../../../../lib/dates";
import {
  getEndDate,
  getJobId,
} from "../../../../lib/entities/repeatingLoggedTimeEntity";

export default (prevState, nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const changedJobIds = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevJobId = prevEntity && getJobId(prevEntity);
      const newJobId = newEntity && getJobId(newEntity);

      if (prevJobId !== newJobId) {
        if (prevJobId) pushOnce(changedJobIds, prevJobId);
        if (newJobId) pushOnce(changedJobIds, newJobId);
        return;
      }

      if (newJobId && getEndDate(prevEntity) !== getEndDate(newEntity)) {
        pushOnce(changedJobIds, newJobId);
      }
    });

    changedJobIds.forEach((jobId) => {
      const nextRepeatingLoggedTimeIds = get(draft, `idsByJobId.${jobId}`);

      if (isEmpty(nextRepeatingLoggedTimeIds)) {
        unset(draft, `sortedIdsByJobId.${jobId}`);
        return;
      }

      const sortedRepeatingLoggedTimeIds = nextRepeatingLoggedTimeIds
        .map((id) => get(draft, `byId.${id}`))
        .slice()
        .sort((repeatingLoggedTime1, repeatingLoggedTime2) =>
          simpleCompareDesc(
            getEndDate(repeatingLoggedTime1),
            getEndDate(repeatingLoggedTime2)
          )
        )
        .map((repeatingLoggedTime) => repeatingLoggedTime.id);

      setWith(
        draft,
        `sortedIdsByJobId.${jobId}`,
        sortedRepeatingLoggedTimeIds,
        Object
      );
    });
  });
