import { all } from "redux-saga/effects";

import jobSaga from "../../state/entities/job/saga";
import newJobItemSaga from "../../state/entities/jobItem/saga";
import jobItemRoleSaga from "../../state/entities/jobItemRole/saga";
import newJobPhaseSaga from "../../state/entities/jobPhase/saga";
import userPreferenceSaga from "../../state/entities/userPreferences/saga";
import scheduleUiSaga from "../../state/ui/schedule/saga";
import accountingPlatformSaga from "./accountingPlatformSaga";
import activityEntrySaga from "./activityEntrySaga";
import branchLogoSaga from "./branchLogoSaga";
import companySaga from "./companySaga";
import invoiceLineItemSaga from "./invoiceLineItemSaga";
import invoiceSaga from "./invoiceSaga";
import jobActivitySaga from "./jobActivitySaga";
import jobDetailsSaga from "./jobDetailsSaga";
import jobItemDependancySaga from "./jobItemDependancySaga";
import jobItemSaga from "./jobItemSaga";
import jobItemSubItemSaga from "./jobItemSubItemSaga";
import jobItemUserSaga from "./jobItemUserSaga";
import jobPhaseSaga from "./jobPhaseSaga";
import jobRepeatingTasksSaga from "./jobRepeatingTasksSaga";
import jobTimelineSaga from "./jobTimelineSaga";
import labelsSaga from "./labelsSaga";
import loggedExpenseSaga from "./loggedExpenseSaga";
import purchaseOrderLineItemSaga from "./purchaseOrderLineItemSaga";
import quoteLineItemSaga from "./quoteLineItemSaga";
import quoteSaga from "./quoteSaga";
import scheduleSaga from "./scheduleSaga";
import webAppApiSaga from "./webAppAPISaga";

export default function* rootSaga() {
  yield all([
    activityEntrySaga(),
    branchLogoSaga(),
    companySaga(),
    accountingPlatformSaga(),
    invoiceSaga(),
    invoiceLineItemSaga(),
    jobActivitySaga(),
    jobItemDependancySaga(),
    jobItemUserSaga(),
    jobRepeatingTasksSaga(),
    jobTimelineSaga(),
    loggedExpenseSaga(),
    quoteSaga(),
    quoteLineItemSaga(),
    purchaseOrderLineItemSaga(),
    scheduleSaga(),
    userPreferenceSaga(),
    webAppApiSaga(),
    jobItemSaga(),
    jobDetailsSaga(),
    jobItemSubItemSaga(),
    jobPhaseSaga(),
    labelsSaga(),
    jobItemRoleSaga(),
    newJobItemSaga(),
    newJobPhaseSaga(),
    jobSaga(),
    scheduleUiSaga(),
  ]);
}
