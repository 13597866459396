import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";
import { MdClose } from "react-icons/md";

import { IconButton, IconSize } from "../../../components";
import {
  deleteButtonContainer,
  displayContainerCss,
} from "./FilterElementDisplay.css";

interface Props extends ComponentPropsWithRef<"div"> {
  onDelete?: (() => void) | null;
}

export const FilterElementDisplay = forwardRef<HTMLDivElement, Props>(
  ({ children, onDelete, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(displayContainerCss, className)}
        {...props}
      >
        {children}
        {onDelete && (
          <div className={deleteButtonContainer}>
            <IconButton
              iconProps={{ icon: MdClose, size: IconSize.Medium }}
              onClick={onDelete}
            />
          </div>
        )}
      </div>
    );
  }
);

FilterElementDisplay.displayName = "FilterElementDisplay";
