import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPermissionsSnapshot } from "st-shared/stores";

import { rateCardEntityType } from "../../../lib/entities/rateCardEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectRateCardById } from "../../../redux/selectors/rateCardSelectors";
import Currency from "./index";

const mapState = (state, props) => ({
  rateCard: selectRateCardById(state, props),
  canViewJobFinancials: getPermissionsSnapshot().canViewJobFinancials,
});

const RateCardCurrency = ({ rateCard, canViewJobFinancials, ...props }) => {
  if (!canViewJobFinancials) return "";

  const currency = rateCard ? rateCard.currency : null;

  return <Currency currency={currency} {...props} />;
};

RateCardCurrency.propTypes = {
  id: entityIdType,
  rateCard: rateCardEntityType.isRequired,
  canViewJobFinancials: PropTypes.bool.isRequired,
};

RateCardCurrency.defaultProps = {
  id: null,
};

export default connect(mapState)(RateCardCurrency);
