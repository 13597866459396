import MuiPaper from "@material-ui/core/Paper";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";

export const StyledAutocomplete = styled(MuiAutocomplete)`
  width: 100%;
  .MuiAutocomplete-inputRoot {
    padding-bottom: 0;
    color: black;
    background-color: white;
    &.Mui-disabled {
      color: var(--color-gray);
    }
  }
  input {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
    &:first-child {
      padding: 7px 10px !important;
    }
  }
  &::placeholder {
    color: var(--color-gray-dark);
  }
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
`;

export const StyledAutocompletePaper = styled(MuiPaper)`
  border-radius: 0;
  margin: 0;
  .MuiAutocomplete-listbox {
    overflow-x: hidden;
    padding: 0;
    max-height: 143px;
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-medium);
    color: var(--color-charcoal);
  }
  .MuiAutocomplete-option {
    padding: 7px 10px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-gray);
    }
    &:hover {
      background-color: var(--color-blue-active);
      color: white;
    }
  }
  .MuiAutocomplete-option[data-focus="true"] {
    background-color: var(--color-blue-active);
    color: white;
  }
  .MuiAutocomplete-noOptions {
    padding: 7px 10px;
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-dark);
  }
`;
