import { createTheme } from "@material-ui/core/styles";

export default (theme) =>
  createTheme({
    ...theme,
    typography: {
      ...theme.typography,
    },
    overrides: {
      MuiPopover: {
        paper: {
          overflowX: "visible",
          overflowY: "visible",
          "&.arrow.top": {
            "&:before": {
              content: "''",
              display: "block",
              width: 0,
              height: 0,
              position: "absolute",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid white",
              left: "calc(50% - 10px)",
              bottom: "100%",
            },
          },
        },
      },
    },
  });
