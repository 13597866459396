import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  JOB_ITEM_SET_MASTER_JOB_ITEM,
  JOB_ITEM_SET_NAME,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getJobItemName } from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useMasterJobItemOptions } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItemOptions";
import FreeAutocomplete from "../../elements/Autocomplete/FreeAutocomplete";
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
} from "../../elements/Autocomplete/styles";
import { FocusProgressContainer } from "../components/FocusProgressContainer";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemNameContainerCss } from "./ItemName.css";

export function ItemName() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const options = useMasterJobItemOptions();

  const [ref, , setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemId,
    key: FOCUS_KEYS.ITEM_NAME,
  });

  const inputValue = getJobItemName(jobItem);

  const dispatch = useDispatch();

  function doJobItemSetName(value: string) {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_NAME, {
        jobItemId,
        value,
      })
    );
  }

  function doJobItemSetItemOption(masterJobItemId: number) {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_MASTER_JOB_ITEM, {
        jobItemId,
        jobItem,
        masterJobItemId,
        doSetFocus: true,
      })
    );
  }

  function handleInputChange(e, newValue: string, reason) {
    doJobItemSetName(String(newValue));
  }

  function handleChange(e, option, reason) {
    if (option.key) {
      doJobItemSetItemOption(option.key);
    } else {
      doJobItemSetName(String(option.value));
    }
  }

  function handleClose(e, reason) {
    setFocused(false);
  }

  function handleOpen(e) {
    setFocused(true);
  }

  return (
    <FocusProgressContainer className={itemNameContainerCss}>
      <FreeAutocomplete
        ref={ref}
        options={options}
        placeholder="Item name"
        value={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        disabled={!isJobEditable}
        AutocompleteComponent={CustomStyledAutocomplete}
        PaperComponent={CustomStyledAutocompletePaper}
      />
    </FocusProgressContainer>
  );
}

const CustomStyledAutocomplete = styled(StyledAutocomplete)`
  .MuiAutocomplete-inputRoot {
    height: 40px;
    &.Mui-disabled {
      color: black;
    }
  }
  input {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-normal);
    &:first-child {
      padding: 9px 20px 5px 20px !important;
    }
  }
`;

const CustomStyledAutocompletePaper = styled(StyledAutocompletePaper)`
  margin: 0 -1px 0 1px;
`;
