import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { costingMethodObj, CostingMethods } from "st-shared/entities";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_COSTING_METHOD,
  JOB_ITEM_USER_SET_RATE,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { hasSellRate } from "../../../lib/entities/jobItemEntity";
import { getUserId } from "../../../lib/entities/jobItemUserEntity";
import { getDisplayName } from "../../../lib/entities/userEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import {
  getInitialItemUserRateInput,
  useJobItemUserRateInput,
} from "../../../redux/selectors/jobItemUser/selectJobItemUserRateInput";
import { useUser } from "../../../redux/selectors/user";
import Tooltip from "../../modules/Tooltip";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemUserId } from "../context/JobItemUserIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemRateFieldCss } from "../JobItem/ItemRate.css";
import { UserRateVariance } from "../JobItem/tooltips/UserRateVariance";
import { itemUserRateContainer, tooltipTitleCss } from "./ItemUserRate.css";

export function ItemUserRate() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemUserId = useJobItemUserId();
  const jobItemUser = useJobItemUser(jobItemUserId);
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const user = useUser(getUserId(jobItemUser));
  const costingMethod = costingMethodObj(jobItem.costingMethod);

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemUserId,
    jobItemId,
    key: FOCUS_KEYS.ITEM_USER_RATE,
  });

  const [rate, setRate] = useState("");

  // eslint-disable-next-line prefer-const
  let [value, placeholder, showTooltip] = useJobItemUserRateInput(
    jobItemUserId,
    focused,
    rate
  );

  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isJobEditable) return;
    const newValue = event.target.value;

    setRate(newValue);

    dispatch(
      createAction(JOB_ITEM_USER_SET_RATE, {
        jobItemId,
        jobItemUserId,
        value: newValue,
      })
    );
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setRate(getInitialItemUserRateInput(jobItemUser));
    setFocused(true);
  };

  function setCostingMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_COSTING_METHOD, {
        jobItemId,
        costingMethodId: CostingMethods.ValueUserSell,
        doSetFocus: false,
      })
    );
  }

  const [hovered, setHovered] = useState(false);

  if (costingMethod.isValueCalculatedSell()) {
    if (!isJobEditable || !hovered) {
      placeholder = "";
    }
  }

  return (
    <div
      className={itemUserRateContainer}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip
        title={
          <div className={tooltipTitleCss}>
            Time that <strong>{getDisplayName(user)}</strong> logs against this
            item will use their sell rate, currently <strong>{value}</strong>.
            This rate will update if their sell rate changes.
          </div>
        }
        placement="top"
        enabled={showTooltip}
      >
        <div>
          <ItemInputField
            ref={ref}
            as="numeric"
            className={itemRateFieldCss}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={!isJobEditable || !costingMethod.isBasedByUser()}
            fallbackLook={
              !costingMethod.isBasedByUser() && !hasSellRate(jobItem)
            }
          />
        </div>
      </Tooltip>
      <UserRateVariance />
      {isJobEditable && costingMethod.isValueCalculatedSell() && (
        <MethodLock
          tooltip={
            <>
              <strong>
                The sell rate for {getDisplayName(user)} is {placeholder} per
                hour.
              </strong>{" "}
              Click to plan and track time on this item by each person’s sell
              rate instead of item rate.
            </>
          }
          className={methodLockIcon}
          onClick={setCostingMethod}
        />
      )}
    </div>
  );
}
