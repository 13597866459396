import { createModelSetParser } from "../API/modelSets";
import { ENTITY_CLASS_LOGGED_EXPENSE } from "../constants";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, { SEND_OPEN_SET_SUPPLIER_MODAL } from "./index";

export const sendOpenSetSupplierModal = () =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(SEND_OPEN_SET_SUPPLIER_MODAL);

    if (jQueryDeferred)
      jQueryDeferred
        .then(createModelSetParser(ENTITY_CLASS_LOGGED_EXPENSE))
        .then(resolve)
        .fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_SET_SUPPLIER_MODAL}`
        )
      );
  });
