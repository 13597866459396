import { minMax, snapRound } from "../../../lib/math";
import {
  ScheduleUi,
  ScheduleUiViewModes,
  ScheduleUiViewModesType,
} from "./types";

export function getWeekWidth(dayWidth: number) {
  return dayWidth * ScheduleUi.DaysInAWeek;
}

export function isJobs(viewMode: ScheduleUiViewModesType) {
  return (
    viewMode === ScheduleUiViewModes.ItemsByJobs ||
    viewMode === ScheduleUiViewModes.PeopleByJobs
  );
}

export function isItemsByJobs(viewMode: ScheduleUiViewModesType) {
  return viewMode === ScheduleUiViewModes.ItemsByJobs;
}

export function isPeopleByJobs(viewMode: ScheduleUiViewModesType) {
  return viewMode === ScheduleUiViewModes.PeopleByJobs;
}

export function isTodos(viewMode: ScheduleUiViewModesType) {
  return viewMode === ScheduleUiViewModes.Todos;
}

export function isJobsByPeople(viewMode: ScheduleUiViewModesType) {
  return viewMode === ScheduleUiViewModes.JobsByPeople;
}

export function isMinZoomLevel(zoomLevel: number) {
  return zoomLevel === ScheduleUi.Zoom.MinLevel;
}

export function isMaxZoomLevel(zoomLevel: number) {
  return zoomLevel === ScheduleUi.Zoom.MaxLevel;
}

export function getZoomValue(
  value: number,
  zoomLevel: number = ScheduleUi.Zoom.Default
) {
  return value * ScheduleUi.Zoom.Ratios[zoomLevel];
}

export function getScheduleDayWidth(zoomLevel: number) {
  return getZoomValue(ScheduleUi.DayWidth, zoomLevel);
}

export function getHourHeight(zoomLevel?: number) {
  return getZoomValue(ScheduleUi.Block.HourHeight, zoomLevel);
}

export function getHeightFromHours(hours: number) {
  return Math.max(hours * getHourHeight(), ScheduleUi.Block.HourHeight);
}

export function getHeightFromMinutes(minutes: number) {
  return getHeightFromHours(minutes / 60);
}

export function getHoursFromHeight(height: number, zoomLevel: number) {
  return minMax(
    snapRound(
      height / getHourHeight(zoomLevel),
      ScheduleUi.Block.SnapIntervalHours
    ),
    ScheduleUi.Block.SnapIntervalHours,
    ScheduleUi.Block.MaxHours
  );
}

export function getUserBlocksMinHeight(zoomLevel: number) {
  return getHourHeight(zoomLevel) * 4;
}

export function getUserBlocksContainerHeight(
  zoomLevel: number,
  maxBlockHeight: number
) {
  return (
    ScheduleUi.Block.ExtraHeight +
    Math.max(
      getZoomValue(maxBlockHeight, zoomLevel),
      getUserBlocksMinHeight(zoomLevel)
    )
  );
}

export function getUserJobBlocksContainerHeight(
  zoomLevel: number,
  maxBlockHeight: number
) {
  return isMaxZoomLevel(zoomLevel)
    ? ScheduleUi.Block.ExtraHeight +
        Math.max(maxBlockHeight, getUserBlocksMinHeight(zoomLevel))
    : ScheduleUi.Job.CompactRowHeight;
}

export function getUserJobExtraHoursBlocksContainerHeight(
  zoomLevel: number,
  maxBlockHeight: number
) {
  return isMaxZoomLevel(zoomLevel)
    ? ScheduleUi.Block.ExtraHeight +
        Math.max(maxBlockHeight, ScheduleUi.Block.HourHeight * 2)
    : ScheduleUi.Job.CompactRowHeight;
}

export function getJobItemUserBlocksContainerHeight(
  zoomLevel: number,
  maxBlockHeight: number
) {
  return isMaxZoomLevel(zoomLevel)
    ? ScheduleUi.Block.ExtraHeight +
        Math.max(maxBlockHeight, ScheduleUi.Block.HourHeight * 2)
    : ScheduleUi.Job.CompactRowHeight;
}
