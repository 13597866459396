import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { FilterGroupTypeEnum, ValueFormatEnum } from "@streamtimefe/entities";
import { uuid } from "@streamtimefe/utils";
import { produce } from "immer";
import { find, forEach, isNil, isNull, orderBy, sortBy } from "lodash-es";
import type {
  ReportingSavedSegment,
  ReportingSortIdentifier,
} from "st-shared/entities";
import {
  CReportingSavedSegment,
  ReportingColumnType,
} from "st-shared/entities";
import { parseDate, toLowerCaseString } from "st-shared/lib";
import type { SeriesData } from "st-shared/types";
import { parseDataSetSeriesDataTitle } from "st-shared/types";

import type { ColumnMetadata } from "../state/stores/savedSegmentSlice";
import { getOrderedMap } from "./orderedMap";
import { ReportingFormula } from "./ReportingFormula";
import { isUserAvailabilityAllTime } from "./userAvailability";

export type SeriesDataValue = number | null;

export type KeyedSeriesData = {
  id: string;
  key: string | null;
  title: string;
  childTitle?: string;
  sortIndex: number;
};

export type VectorSeriesData = {
  vector: Record<string, SeriesDataValue>;
};

export type KeyedVectorSeriesData = KeyedSeriesData & VectorSeriesData;

export type MatrixSeriesData = {
  matrix: Record<string, Record<string, SeriesDataValue>>;
};

export type KeyedMatrixSeriesData = KeyedSeriesData & MatrixSeriesData;

function sortSeriesData(seriesDataList: SeriesData[], isDate?: boolean) {
  if (isDate) {
    return sortBy(seriesDataList, (seriesData) => {
      return parseDate(seriesData.key).valueOf();
    });
  }
  return sortBy(seriesDataList, (seriesData) => {
    return toLowerCaseString(seriesData.title);
  });
}

function getFormattedVectorSeriesData(
  seriesDataList: SeriesData[],
  search: ReportingSavedSegment,
  filterGroupType: TFilterGroupTypeEnum | null
): KeyedVectorSeriesData[] {
  const orderedDataSets = getOrderedMap(search.dataSets);
  const columns = CReportingSavedSegment.getDataColumns(search.columns);
  const columnIds =
    CReportingSavedSegment.fromObject(search).getDataColumnKeys();

  const isDate = filterGroupType === FilterGroupTypeEnum.Date;

  return sortSeriesData(seriesDataList, isDate).map((seriesData, index) => {
    const vectorSeriesData: KeyedVectorSeriesData = {
      id: uuid(),
      key: seriesData.key,
      title: seriesData.title,
      childTitle: seriesData.childTitle,
      sortIndex: index,
      vector: {},
    };

    columnIds.forEach((id) => {
      vectorSeriesData.vector[id] = 0;

      if (isUserAvailabilityAllTime(id, search)) {
        vectorSeriesData.vector[id] = null;
      }
    });

    forEach(seriesData.data, (value, title) => {
      const parsedTitle = parseDataSetSeriesDataTitle(title);

      const columnId = find(columns, {
        dataSetId: orderedDataSets[parsedTitle.dsid].id,
        modeId: parsedTitle.mid,
        columnId: parsedTitle.cid,
      })?.id;

      if (columnId) {
        vectorSeriesData.vector[columnId] = value;
      }
    });

    return vectorSeriesData;
  });
}

function getFormattedMatrixSeriesData(
  seriesDataList: SeriesData[],
  search: ReportingSavedSegment,
  filterGroupType: TFilterGroupTypeEnum | null,
  bucketKeys: string[]
): KeyedMatrixSeriesData[] {
  const orderedDataSets = getOrderedMap(search.dataSets);
  const columns = CReportingSavedSegment.getDataColumns(search.columns);
  const columnIds =
    CReportingSavedSegment.fromObject(search).getDataColumnKeys();

  const isDate = filterGroupType === FilterGroupTypeEnum.Date;

  return sortSeriesData(seriesDataList, isDate).map((seriesData, index) => {
    const matrixSeriesData: KeyedMatrixSeriesData = {
      id: uuid(),
      key: seriesData.key,
      title: seriesData.title,
      childTitle: seriesData.childTitle,
      sortIndex: index,
      matrix: {},
    };

    bucketKeys.forEach((bucketKey) => {
      matrixSeriesData.matrix[bucketKey] = {};
      columnIds.forEach((id) => {
        matrixSeriesData.matrix[bucketKey][id] = 0;
      });
    });

    forEach(seriesData.data, (value, title) => {
      const parsedTitle = parseDataSetSeriesDataTitle(title);

      const columnId = find(columns, {
        dataSetId: orderedDataSets[parsedTitle.dsid].id,
        modeId: parsedTitle.mid,
        columnId: parsedTitle.cid,
      })?.id;

      if (columnId && parsedTitle.ts) {
        matrixSeriesData.matrix[parsedTitle.ts][columnId] = value;
      }
    });

    return matrixSeriesData;
  });
}

export function getPermissionsVectorSeriesData<T extends VectorSeriesData>(
  vectorSeriesData: T[],
  metadata: Record<string, ColumnMetadata>
): T[] {
  return produce(vectorSeriesData, (seriesData) => {
    seriesData.forEach((row) => {
      Object.keys(row.vector).forEach((id) => {
        if (!metadata[id] || !metadata[id].permission) {
          row.vector[id] = null;
        }
      });
    });
  });
}

export function getPermissionsMatrixSeriesData<T extends MatrixSeriesData>(
  matrixSeriesData: T[],
  metadata: Record<string, ColumnMetadata>
): T[] {
  return produce(matrixSeriesData, (seriesData) => {
    seriesData.forEach((row) => {
      Object.entries(row.matrix).forEach(([matrixId, matrix]) => {
        Object.keys(matrix).forEach((id) => {
          if (!metadata[id] || !metadata[id].permission) {
            row.matrix[matrixId][id] = null;
          }
        });
      });
    });
  });
}

export function getFormulasVectorSeriesData<T extends VectorSeriesData>(
  vectorSeriesData: T[],
  metadata: Record<string, ColumnMetadata>
): T[] {
  return produce(vectorSeriesData, (seriesData) => {
    seriesData.forEach((row) => {
      const scope: Record<string, SeriesDataValue> = {};
      Object.entries(row.vector).forEach(([id, value]) => {
        scope[id] = transformScopeValue(value, id, metadata);
      });

      ReportingFormula.calculateScope(scope, metadata);

      Object.keys(scope).forEach((columnId) => {
        if (!(columnId in row.vector)) {
          row.vector[columnId] = reconstructScopeValue(
            scope[columnId],
            columnId,
            metadata
          );
        }
      });
    });
  });
}

export function getFormulasMatrixSeriesData<T extends MatrixSeriesData>(
  matrixSeriesData: T[],
  metadata: Record<string, ColumnMetadata>
): T[] {
  return produce(matrixSeriesData, (seriesData) => {
    seriesData.forEach((row) => {
      Object.values(row.matrix).forEach((vector) => {
        const scope: Record<string, SeriesDataValue> = {};

        Object.entries(vector).forEach(([id, value]) => {
          scope[id] = transformScopeValue(value, id, metadata);
        });

        ReportingFormula.calculateScope(scope, metadata);

        Object.keys(scope).forEach((columnId) => {
          if (!(columnId in vector)) {
            vector[columnId] = reconstructScopeValue(
              scope[columnId],
              columnId,
              metadata
            );
          }
        });
      });
    });
  });
}

function transformScopeValue(
  value: SeriesDataValue,
  columnId: string,
  metadata: Record<string, ColumnMetadata>
): SeriesDataValue {
  if (value === null || !(columnId in metadata)) return value;

  if (metadata[columnId].format === ValueFormatEnum.Minutes) {
    return value / 60;
  } else if (metadata[columnId].format === ValueFormatEnum.Percentage) {
    return value / 100;
  }
  return value;
}

function reconstructScopeValue(
  value: SeriesDataValue,
  columnId: string,
  metadata: Record<string, ColumnMetadata>
): SeriesDataValue {
  if (value === null || !(columnId in metadata)) return value;

  if (metadata[columnId].format === ValueFormatEnum.Minutes) {
    return value * 60;
  } else if (metadata[columnId].format === ValueFormatEnum.Percentage) {
    return value * 100;
  }
  return value;
}

export function getCalculatedVectorSeriesData(
  seriesDataList: SeriesData[],
  search: ReportingSavedSegment,
  filterGroupType: TFilterGroupTypeEnum | null,
  metadata: Record<string, ColumnMetadata>
) {
  const vectorSeriesData = getFormattedVectorSeriesData(
    seriesDataList,
    search,
    filterGroupType
  );
  const permissionsData = getPermissionsVectorSeriesData(
    vectorSeriesData,
    metadata
  );
  return getFormulasVectorSeriesData(permissionsData, metadata);
}

export function getCalculatedMatrixSeriesData(
  seriesDataList: SeriesData[],
  search: ReportingSavedSegment,
  filterGroupType: TFilterGroupTypeEnum | null,
  bucketKeys: string[],
  metadata: Record<string, ColumnMetadata>
) {
  const matrixSeriesData = getFormattedMatrixSeriesData(
    seriesDataList,
    search,
    filterGroupType,
    bucketKeys
  );
  const permissionsData = getPermissionsMatrixSeriesData(
    matrixSeriesData,
    metadata
  );
  return getFormulasMatrixSeriesData(permissionsData, metadata);
}

export function getCalculatedVectorSeriesTotals(
  seriesTotals: SeriesData["data"],
  search: ReportingSavedSegment,
  metadata: Record<string, ColumnMetadata>
) {
  const totals: Record<string, SeriesDataValue> = {};

  const orderedDataSets = getOrderedMap(search.dataSets);
  const columns = CReportingSavedSegment.getDataColumns(search.columns);
  const columnIds =
    CReportingSavedSegment.fromObject(search).getDataColumnKeys();

  columnIds.forEach((id) => {
    totals[id] = 0;

    if (isUserAvailabilityAllTime(id, search)) {
      totals[id] = null;
    }
  });

  forEach(seriesTotals, (value, title) => {
    const parsedTitle = parseDataSetSeriesDataTitle(title);

    const columnId = find(columns, {
      dataSetId: orderedDataSets[parsedTitle.dsid].id,
      modeId: parsedTitle.mid,
      columnId: parsedTitle.cid,
    })?.id;

    if (columnId) {
      totals[columnId] = value;
    }
  });

  // check for if column can show totals
  Object.keys(totals).forEach((id) => {
    const column = search.columns[id];
    if (!column || !metadata[id] || !metadata[id].permission) {
      totals[id] = null;
    }
  });

  // formulas
  const scope: Record<string, SeriesDataValue> = {};

  Object.entries(totals).forEach(([id, value]) => {
    scope[id] = transformScopeValue(value, id, metadata);
  });

  ReportingFormula.calculateScope(scope, metadata);

  Object.keys(scope).forEach((columnId) => {
    if (!(columnId in totals)) {
      totals[columnId] = reconstructScopeValue(
        scope[columnId],
        columnId,
        metadata
      );
    }
  });

  return totals;
}

export function getCalculatedMatrixSeriesTotals(
  matrixSeriesData: MatrixSeriesData[],
  search: ReportingSavedSegment,
  bucketKeys: string[],
  metadata: Record<string, ColumnMetadata>
) {
  const totals: Record<string, Record<string, SeriesDataValue>> = {};

  matrixSeriesData.forEach((seriesData) => {
    Object.entries(seriesData.matrix).forEach(([key, vector]) => {
      Object.entries(vector).forEach(([columnId, value]) => {
        const column = search.columns[columnId];
        if (column.type === ReportingColumnType.Formula) return;

        if (!totals[columnId]) {
          totals[columnId] = {};
        }
        if (value === null) {
          if (!totals[columnId][key]) {
            totals[columnId][key] = null;
          }
        } else {
          if (!totals[columnId][key]) {
            totals[columnId][key] = 0;
          }
          totals[columnId][key]! += value;
        }
      });
    });
  });

  // check for if column can show totals
  Object.keys(totals).forEach((id) => {
    const column = search.columns[id];
    if (
      !column ||
      !CReportingSavedSegment.canTimeSeriesColumnShowTotal(column) ||
      !metadata[id] ||
      !metadata[id].permission
    ) {
      Object.keys(totals[id]).forEach((timeKey) => {
        totals[id][timeKey] = null;
      });
    }
  });

  // formulas
  bucketKeys.forEach((bucketKey) => {
    const scope: Record<string, SeriesDataValue> = {};

    Object.entries(totals).forEach(([id, value]) => {
      const column = search.columns[id];
      if (column.type !== ReportingColumnType.Formula) {
        scope[id] = transformScopeValue(value[bucketKey], id, metadata);
      }
    });

    ReportingFormula.calculateScope(scope, metadata);

    Object.keys(scope).forEach((columnId) => {
      if (!totals[columnId]) {
        totals[columnId] = {};
      }
      if (!(bucketKey in totals[columnId])) {
        totals[columnId][bucketKey] = reconstructScopeValue(
          scope[columnId],
          columnId,
          metadata
        );
      }
    });
  });

  return totals;
}

export function sortVectorSeriesData(
  seriesData: KeyedVectorSeriesData[],
  sortIdentifier: ReportingSortIdentifier
) {
  let sortId: string | null = null;

  if (
    sortIdentifier.id &&
    seriesData.length > 0 &&
    Object.keys(seriesData[0].vector!).includes(sortIdentifier.id)
  ) {
    sortId = sortIdentifier.id;
  }

  let ordered: typeof seriesData;

  if (sortId !== null) {
    ordered = orderBy(
      seriesData,
      [
        (seriesData) => {
          return isNil(seriesData.vector![sortId!])
            ? -1
            : seriesData.vector![sortId!];
        },
        (seriesData) => {
          return isNull(seriesData.key);
        },
        (seriesData) => {
          return seriesData.sortIndex;
        },
      ],
      [
        CReportingSavedSegment.switchSortOrder(sortIdentifier.order),
        sortIdentifier.order,
        sortIdentifier.order,
      ]
    );
  } else {
    ordered = orderBy(
      seriesData,
      [
        (seriesData) => {
          return isNull(seriesData.key);
        },
        (seriesData) => {
          return seriesData.sortIndex;
        },
      ],
      [sortIdentifier.order, sortIdentifier.order]
    );
  }

  return ordered;
}

export function sortMatrixSeriesData(
  seriesData: KeyedMatrixSeriesData[],
  sortIdentifier: ReportingSortIdentifier,
  selectedId: string | null
) {
  let sortId: string | null = null;

  if (
    selectedId &&
    sortIdentifier.id &&
    seriesData.length > 0 &&
    Object.keys(seriesData[0].matrix!).includes(sortIdentifier.id) &&
    selectedId in seriesData[0].matrix![sortIdentifier.id]
  ) {
    sortId = sortIdentifier.id;
  }

  let ordered: typeof seriesData;

  if (sortId !== null) {
    ordered = orderBy(
      seriesData,
      [
        (seriesData) => {
          return isNil(seriesData.matrix![sortId!][selectedId!])
            ? -1
            : seriesData.matrix![sortId!][selectedId!];
        },
        (seriesData) => {
          return isNull(seriesData.key);
        },
        (seriesData) => {
          return seriesData.sortIndex;
        },
      ],
      [
        CReportingSavedSegment.switchSortOrder(sortIdentifier.order),
        sortIdentifier.order,
        sortIdentifier.order,
      ]
    );
  } else {
    ordered = orderBy(
      seriesData,
      [
        (seriesData) => {
          return isNull(seriesData.key);
        },
        (seriesData) => {
          return seriesData.sortIndex;
        },
      ],
      [sortIdentifier.order, sortIdentifier.order]
    );
  }

  return ordered;
}
