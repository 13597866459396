import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import { setSum } from "../../../../lib/objects";
import {
  calculateTotalIncTax,
  getIsHeading,
  getTaxRate,
  getTotalAmountExTax,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    const getQuoteLineItem = (id) => nextState.byId[id];

    touchedQuoteIds.forEach((quoteId) => {
      const sortedIds = get(nextState.sortedIdsByQuoteId, quoteId);

      if (!sortedIds) {
        unset(draft, `subTotalsIncTaxByQuoteId.${quoteId}`);
        return;
      }

      const sortedLineItems = sortedIds.map(getQuoteLineItem);
      const subTotals = {};
      let lastHeadingId = null;

      sortedLineItems.forEach((quoteLineItem) => {
        if (getIsHeading(quoteLineItem)) {
          lastHeadingId = quoteLineItem.id;
          return;
        }

        if (!lastHeadingId) return;

        const totalExTax = asDecimal(getTotalAmountExTax(quoteLineItem));
        const taxRate = getTaxRate(quoteLineItem);
        const lineTotal = calculateTotalIncTax(totalExTax, taxRate);

        setSum(subTotals, lastHeadingId, lineTotal);
      });

      setWith(draft, `subTotalsIncTaxByQuoteId.${quoteId}`, subTotals, Object);
    });
  });
