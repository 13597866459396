import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateJobMilestones } from "./index";

export const selectJobMilestoneIdsByJobId = createCachedSelector(
  [selectStateJobMilestones, selectPropsJobId],
  (jobMilestones, jobId) =>
    get(jobMilestones, `idsByJobId.${jobId}`, EMPTY_ARRAY)
)(selectPropsJobId);

export const useJobMilestoneIdsByJobId = jobId =>
  useSelector(state => selectJobMilestoneIdsByJobId(state, { jobId }));
