import React from "react";
import styled from "styled-components";
import DraftJsContent from "./DraftJsContent";
import { createRichTextFieldPlugins } from "./plugins";
import DraftJsEditor from "./index";

// only used for documents
class RichTextFieldEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const { InlineToolbar, plugins } = createRichTextFieldPlugins(
      props.simplified
    );

    this.InlineToolbar = InlineToolbar;
    this.plugins = plugins;
  }

  render() {
    return (
      <Wrapper>
        <DraftJsEditor plugins={this.plugins} {...this.props}>
          <this.InlineToolbar />
        </DraftJsEditor>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  ${DraftJsContent} {
    padding: 12px;
    background: white;
    min-height: 60px;

    /** changes until redesign */
    font-weight: 400;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 14px;
    }
  }
`;

export default RichTextFieldEditor;
