import type { TEntityClass, TModelSet } from "@streamtimefe/entities";
import type { Entity, TEntityId } from "st-shared/entities/Entity";

import WebAppAPI, {
  SEND_APPLY_MODEL_DATA,
  SEND_REMOVE_MODEL,
} from "./WebAppAPI";

declare global {
  interface Window {
    App?: any;
  }
}

export function hasOpenModals() {
  if (window.parent.App) {
    return window.parent.App.pageManager.pageModalStack().length !== 0;
  }
  return false;
}

export function sendApplyModelData(
  primaryEntityClassName: TEntityClass | undefined,
  data: TModelSet | Entity
) {
  WebAppAPI.sendAction(SEND_APPLY_MODEL_DATA, data);
}

export function sendRemoveModel(
  primaryEntityClassName: TEntityClass,
  id: TEntityId
) {
  WebAppAPI.sendAction(SEND_REMOVE_MODEL, primaryEntityClassName, id);
}
