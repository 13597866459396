import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobPhaseId } from "../index";
import { selectJobItems } from "../jobItemSelectors";
import { selectJobItemIdsByJobPhaseId } from "./selectJobItemIdsByJobPhaseId";

export const selectJobItemsByJobPhaseId = createCachedSelector(
  [selectJobItems, selectJobItemIdsByJobPhaseId],
  (jobItems, jobItemIds) =>
    jobItemIds.map(jobItemId => get(jobItems, `byId.${jobItemId}`))
)(selectPropsJobPhaseId);

export const useJobItemsByJobPhaseId = jobPhaseId =>
  useSelector(state => selectJobItemsByJobPhaseId(state, { jobPhaseId }));
