import { createIcon } from "../../icon/Icon";

export const IconStatusClosedGroup = createIcon(
  <>
    <path d="M2 21V9.75L4 11.25V19H14.3333L17 21H2Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66667 8L7 6H8V2H16V6H22V17.25L20 15.75V8H9.66667ZM10 6H14V4H10V6Z"
    />
    <path d="M1.4 6.8L21.4 21.8L22.6 20.2L2.6 5.2L1.4 6.8Z" />
  </>,
  "IconStatusClosedGroup"
);
