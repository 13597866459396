import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const RateCardStore = createSingleEntityStoreContext(
  EntityClass.RateCard
);

export const RateCardStoreProvider = createSingleEntityStoreProvider(
  EntityClass.RateCard,
  RateCardStore
);
