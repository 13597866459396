import { produce } from "immer";
import { get, isEmpty, isEqual, set, unset } from "lodash-es";
import {
  getIsHeading,
  getOrderId
} from "../../../../lib/ui/commercialDocumentLineItems";
import { LINE_ITEM_ELEMENTS } from "../../../../lib/ui/lineItemElements";

export default (state, nextState, touchedInvoiceIds) =>
  produce(nextState, draft => {
    touchedInvoiceIds.forEach(invoiceId => {
      const prevInvoiceLineItems = get(
        state.sortedIdsByInvoiceId,
        invoiceId,
        []
      ).map(id => state.byId[id]);
      const invoiceLineItems = get(
        nextState.sortedIdsByInvoiceId,
        invoiceId,
        []
      ).map(id => nextState.byId[id]);

      const prevOrder = prevInvoiceLineItems.map(({ id, orderId }) => ({
        id,
        orderId
      }));
      const nextOrder = invoiceLineItems.map(({ id, orderId }) => ({
        id,
        orderId
      }));
      if (isEqual(prevOrder, nextOrder)) return;

      if (isEmpty(invoiceLineItems)) {
        unset(draft, `lineItemElementsByInvoiceId.${invoiceId}`);
        return;
      }

      const lineItemElements = [];
      let lastHeadingId;
      let lastLineItem;
      let lastAddLine;

      function createSubTotal() {
        if (!lastLineItem) return;

        lastAddLine.className = "subtotal";

        if (getIsHeading(lastLineItem)) return;

        if (lastHeadingId) {
          lineItemElements.push({
            type: LINE_ITEM_ELEMENTS.SUBTOTAL,
            isDraggable: false,
            id: lastHeadingId
          });
        }
      }

      function createAddLine(className = "") {
        const orderId = lastLineItem ? getOrderId(lastLineItem) + 0.5 : 0.5;

        lastAddLine = {
          type: LINE_ITEM_ELEMENTS.ADD_LINE,
          id: orderId,
          isDraggable: false,
          className
        };

        lineItemElements.push(lastAddLine);
      }

      function createHeading(invoiceLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.HEADING,
          id: invoiceLineItem.id,
          isDraggable: true,
          orderId: getOrderId(invoiceLineItem)
        });

        lastLineItem = invoiceLineItem;
        lastHeadingId = invoiceLineItem.id;
      }

      function createItem(invoiceLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.ITEM,
          id: invoiceLineItem.id,
          isDraggable: true,
          orderId: getOrderId(invoiceLineItem)
        });

        lastLineItem = invoiceLineItem;
      }

      createAddLine();

      invoiceLineItems.forEach(invoiceLineItem => {
        if (getIsHeading(invoiceLineItem)) {
          createSubTotal();

          createHeading(invoiceLineItem);

          createAddLine("heading");

          return;
        }

        createItem(invoiceLineItem);

        createAddLine("separator");
      });

      createSubTotal();

      set(draft, `lineItemElementsByInvoiceId.${invoiceId}`, lineItemElements);
    });
  });
