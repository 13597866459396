import { produce } from "immer";
import { Entity } from "st-shared/entities/Entity";

import { ChangedEntity, EntityById } from "../entities/types";

function byIdReducer<T extends Entity>(
  state: EntityById<T>,
  changedEntities: ChangedEntity<T>[]
) {
  return produce(state, (draft: EntityById<T>) => {
    changedEntities.forEach((changedEntity) => {
      if (changedEntity.prevEntity && !changedEntity.newEntity) {
        delete draft[changedEntity.prevEntity.id];
      }
      if (changedEntity.newEntity) {
        draft[changedEntity.newEntity.id] = changedEntity.newEntity;
      }
    });
  });
}

export default byIdReducer;
