import type { TEntityList } from "st-shared/types";

import API from "../../../lib/api";

async function fetchScheduleLoggedTimes(
  startDate: string,
  endDate: string,
  userIds: number[]
) {
  const response = await API.get<TEntityList>("/schedule/logged_times", {
    start_date: startDate,
    end_date: endDate,
    schedule_mode: true,
    user_ids: JSON.stringify(userIds),
  });
  return response.data;
}

const ScheduleAPI = {
  fetchScheduleLoggedTimes,
};

export default ScheduleAPI;
