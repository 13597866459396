import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { JOB_DETAILS_SET_CURRENT_FOCUS } from "../../../lib/constants";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import { useOutsideClick } from "../../../lib/hooks/useOutsideClick";
import { getPhaseId } from "../../../lib/sortingHelpers";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  useJobDetailsCurrentFocusJobItemId,
  useJobDetailsCurrentFocusJobPhaseId,
} from "../../../redux/selectors/jobDetails/ui/currentFocus";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPhaseStatusClass } from "../../../redux/selectors/jobPhase/selectJobPhaseStatusClass";
import { useIsExpandedJobPhase } from "../../../redux/selectors/jobPhase/ui/isExpanded";
import { useIsSavingJobPhase } from "../../../redux/selectors/jobPhase/ui/isSaving";
import { IconButton } from "../../elements/Button";
import DragIndicatorIcon from "../../elements/Icons/custom/DragIndicatorIcon";
import DotPulseIcon from "../../elements/Icons/DotPulseIcon";
import { useJobId } from "../context/JobIdContext";
import { JobPhaseIdContext } from "../context/JobPhaseIdContext";
import AddNewButton from "../JobPlan/AddNewButton";
import { FullWidthBaseItem, LoadingPulse } from "../styles";
import PhaseCardActions from "./PhaseCardActions";
import PhaseExpander from "./PhaseExpander";
import PhaseHours from "./PhaseHours";
import PhaseName from "./PhaseName";
import PhaseStatus from "./PhaseStatus";
import PhaseTeamMembers from "./PhaseTeamMembers";
import PhaseTotal from "./PhaseTotal";

const JobPhase = ({ id }) => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const { canViewJobFinancials } = usePermissions();
  const isSaving = useIsSavingJobPhase(id);
  const isExpanded = useIsExpandedJobPhase(id);
  const statusClass = useJobPhaseStatusClass(id);
  const currentFocusJobPhaseId = useJobDetailsCurrentFocusJobPhaseId();
  const currentFocusJobItemId = useJobDetailsCurrentFocusJobItemId();

  const dispatch = useDispatch();

  const disableFocus = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: null,
      })
    );
  };

  useKeyEvent(
    isJobEditable && currentFocusJobPhaseId === id,
    "keydown",
    (e) => {
      if (e.ctrlKey && e.code === "KeyS") disableFocus();
    },
    [disableFocus]
  );

  const outsideClickRef = useOutsideClick(
    isJobEditable && currentFocusJobPhaseId === id,
    disableFocus,
    [disableFocus]
  );

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    attributes,
    listeners,
  } = useSortable({
    id: getPhaseId(id),
  });

  const showDragHandle = (!isSorting || isDragging) && isJobEditable && id > 0;

  const outOfFocus = currentFocusJobItemId !== null;

  return (
    <JobPhaseIdContext.Provider value={id}>
      <DragContainer
        ref={setNodeRef}
        style={{
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        $dragging={isDragging}
      >
        <Container
          ref={outsideClickRef}
          $isExpanded={isExpanded}
          $showHoverButtons={!isDragging && !isSorting && !isSaving}
          $showDragHandle={showDragHandle}
          $saving={isSaving}
          animate={{ opacity: isSaving || outOfFocus ? 0.4 : 1 }}
          transition={{
            duration: isSaving ? 0.5 : 0.25,
            ease: "easeInOut",
          }}
          initial={false}
        >
          {showDragHandle && (
            <DragHandle $dragging={isDragging} {...listeners} {...attributes}>
              <DragIndicatorIcon />
            </DragHandle>
          )}
          {isJobEditable && id > 0 && <AddNewButton fromJobPhaseId={id} />}
          <MainPhaseContainer className={statusClass}>
            <PhaseExpander />
            <PhaseName />
            <PhaseTeamMembers />
            <PhaseHours />
            {canViewJobFinancials && <PhaseTotal />}
            <PhaseStatus />
            {isJobEditable && <PhaseCardActions />}
          </MainPhaseContainer>
        </Container>
        {isSaving && (
          <LoadingPulse>
            <DotPulseIcon />
          </LoadingPulse>
        )}
      </DragContainer>
    </JobPhaseIdContext.Provider>
  );
};

JobPhase.propTypes = {
  id: entityIdType.isRequired,
};

export default JobPhase;

const Container = styled(motion.div)`
  position: relative;

  pointer-events: ${(props) => (props.$saving ? "none" : "auto")};

  .AddLineButtonContainer {
    top: -11px;
    height: 20px;
  }

  ${(props) =>
    props.$showHoverButtons &&
    `
  &:hover {
    .ActionsButton {
      opacity: 1;
    }
    .AddLineButton {
      opacity: 1;
    }
  }
  `}

  ${(props) =>
    props.$showDragHandle &&
    `
  &:hover {
    .DragHandle {
      opacity: 1;
    }
  }
  `}

  ${(props) => !props.$isExpanded && "margin-bottom: 10px;"}
`;

const MainPhaseContainer = styled(FullWidthBaseItem)`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 46px;
  color: black;
  background-color: white;

  & > *:not(:first-child):not(:last-child) {
    margin-left: 2px;
  }

  .MuiAutocomplete-inputRoot {
    input {
      color: var(--color-charcoal);
    }
  }

  &.scheduled,
  &.planning,
  &.complete {
    .MuiAutocomplete-inputRoot {
      input {
        color: black;
      }
    }
    color: black;
  }

  &.scheduled {
    border-bottom: 2px solid var(--color-status-play);
  }
  &.planning {
    border-bottom: 2px solid var(--color-status-pause);
  }
  &.complete {
    border-bottom: 2px solid var(--color-status-done);
  }
`;

export const DragContainer = styled.div`
  position: relative;
  ${(props) => props.$dragging && "z-index: 10000;"};
`;

const DragHandle = styled(IconButton).attrs({
  className: "DragHandle",
})`
  width: 30px;
  height: 20px;
  position: absolute;
  left: -35px;
  top: 10px;
  align-self: center;
  color: var(--color-gray-medium);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  opacity: 0;
  ${(props) => props.$dragging && "opacity: 1 !important;"}
`;
