import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { domNodeType } from "../../../lib/types/domTypes";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  accountingPlatformStatusType,
  accountingPlatformOptionType
} from "../../../lib/ui/accountingPlatform";
import {
  selectAccountingPlatformAccountOptions,
  selectAccountingPlatformStatus
} from "../../../redux/selectors/accountingPlatformSelectors";
import Flex from "../../elements/Flex";
import { InlineDotPulseIcon } from "../../elements/Icons/DotPulseIcon";
import TextLink from "../../elements/TextLink";
import consumeCommercialDocument from "../CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import SearchMenu from "../SearchMenu";

const mapContext = ({ branchId }) => ({
  branchId
});

const mapState = (state, props) => ({
  accountingPlatformStatus: selectAccountingPlatformStatus(state, props),
  options: selectAccountingPlatformAccountOptions(state, props)
});

class AccountCodesSearchMenu extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    onPickOption: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    accountType: PropTypes.oneOf(values(ACCOUNTING_PLATFORM_ACCOUNT_TYPES))
      .isRequired,
    accountingPlatformStatus: accountingPlatformStatusType.isRequired,
    options: PropTypes.arrayOf(accountingPlatformOptionType).isRequired
  };

  static defaultProps = {
    anchorEl: null,
    onClose: null
  };

  getNoResultsMessage = searchQuery =>
    `Hmm, looks like no account codes match ‘${searchQuery}’. Perhaps try a different search term.`;

  get isActive() {
    const { accountingPlatformStatus } = this.props;
    const { isActive } = accountingPlatformStatus;
    return isActive;
  }

  get externalPlatformName() {
    const { accountingPlatformStatus } = this.props;
    const { name } = accountingPlatformStatus;
    return name;
  }

  get title() {
    if (!this.isActive) return "Account Codes";

    return `Account codes in ${this.externalPlatformName}`;
  }

  get noOptionsMessage() {
    if (!this.isActive) return null;

    const { accountingPlatformStatus } = this.props;
    const { isFetching, isDisconnected } = accountingPlatformStatus;

    if (isFetching)
      return (
        <span>
          Loading account codes from <b>{this.externalPlatformName}</b>{" "}
          <InlineDotPulseIcon />
        </span>
      );

    if (isDisconnected)
      return (
        <span>
          <b>{this.externalPlatformName}</b> has been disconnected. Please{" "}
          <TextLink target="_top" href="/#integrations">
            reconnect
          </TextLink>{" "}
          your account and try again.
        </span>
      );

    return (
      <span>
        No account codes found on <b>{this.externalPlatformName}</b>.
      </span>
    );
  }

  render() {
    if (!this.isActive) return null;

    const { anchorEl, onPickOption, onClose, options } = this.props;

    return (
      <SearchMenu
        anchorEl={anchorEl}
        options={options}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        HeaderComponentProps={{
          title: this.title,
          getNoResultsMessage: this.getNoResultsMessage,
          noOptionsMessage: this.noOptionsMessage,
          placeholder: "search by account code or name..."
        }}
        onClose={onClose}
      >
        {({ key, value, taxRef, isHeading, code }) => (
          <AccountCodeOption
            key={key}
            onClick={() => onPickOption(key, value, taxRef)}
            className={isHeading ? "heading" : "rainbowHover"}
          >
            <AccountName className={isHeading && "heading"}>
              {value}
            </AccountName>
            {!isHeading && <AccountCode>{code}</AccountCode>}
          </AccountCodeOption>
        )}
      </SearchMenu>
    );
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(AccountCodesSearchMenu)
);

const AccountCodeOption = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  text-align: left;
  user-select: none;
  justify-content: space-between;
  cursor: pointer;
  &.heading {
    background-color: var(--color-ygrittesnow);
    color: var(--color-gray-dark);
    pointer-events: none;
    text-transform: uppercase;
  }
`;

const AccountName = styled.span`
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  &.heading {
    color: var(--color-gray-dark);
  }
`;

const AccountCode = styled.span`
  white-space: pre-wrap;
  margin-left: 10px;
  font-size: 14px;
  color: var(--color-gray-dark);
`;
