import { CompanyStatusEnum } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { entityIdType } from "../types/entityTypes";

export const COMPANY_GROUPED_OPTION_TYPE = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  OPTIONS_HEADING: "OPTIONS_HEADING",
};

export const companyOptionShape = {
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  companyStatusId: entityIdType.isRequired,
  branchId: entityIdType,
  branchName: PropTypes.string,
  rateCardId: entityIdType,
  rateCardName: PropTypes.string,
};

export const companyOptionEntityType = PropTypes.shape(companyOptionShape);

const getOptionType = (companyStatusId) => {
  if (companyStatusId === CompanyStatusEnum.Active) {
    return COMPANY_GROUPED_OPTION_TYPE.ACTIVE;
  }
  if (companyStatusId === CompanyStatusEnum.Archived) {
    return COMPANY_GROUPED_OPTION_TYPE.ARCHIVED;
  }
  return COMPANY_GROUPED_OPTION_TYPE.DELETED;
};

export const getAsCompanyOption = ({ id, name, companyStatusId }) => ({
  key: id,
  value: name,
  optionType: getOptionType(Number(companyStatusId)),
  searchString: [asString(name)].join(" "),
});
