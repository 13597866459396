import type { WebBridgeClient } from "./WebBridgeClient";

let webBridgeClient: WebBridgeClient | undefined;

export function setWebBridgeClient(client: WebBridgeClient) {
  webBridgeClient = client;
}

export function getWebBridgeClient() {
  if (!webBridgeClient && import.meta.env.PROD) {
    // eslint-disable-next-line no-console
    console.warn(`"[WebBridge:client] client used before initialisation."`);
  }
  return webBridgeClient;
}
