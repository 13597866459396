import clsx from "clsx";
import type { ComponentType, ReactNode } from "react";

import { RAC } from "../../../external";
import { theme } from "../../../theme";
import { Icon, StPriceArrow } from "../..";
import { AriaButton } from "../Button";
import {
  matchButtonWidthCss,
  selectButtonCss,
  selectButtonIconCss,
  selectButtonTextCss,
  selectItemCss,
  selectItemHeaderCss,
  selectListCss,
  selectPopoverCss,
} from "./Select.css";

export interface SelectProps<T extends object = object, SBP = SelectButtonProps>
  extends Omit<RAC.SelectProps<T>, "children"> {
  children: React.ReactNode | ((item: T) => React.ReactNode);
  items?: Iterable<T>;
  buttonComponent?: ComponentType<SBP>;
  buttonProps?: SBP;
  popoverProps?: RAC.PopoverProps;
  "aria-label": string;
  matchWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange?: (key: SelectKey) => any;
}

export function Select<
  T extends object,
  SBP extends SelectButtonProps = SelectButtonProps,
>({
  children,
  items,
  buttonComponent: ButtonComponent = SelectButton,
  buttonProps,
  popoverProps,
  matchWidth = true,
  ...props
}: SelectProps<T, SBP>) {
  return (
    <RAC.Select placeholder=" " {...props}>
      <ButtonComponent {...(buttonProps as SBP)} />
      <RAC.Popover
        offset={0}
        crossOffset={0}
        {...popoverProps}
        className={clsx(
          selectPopoverCss,
          matchWidth && matchButtonWidthCss,
          popoverProps?.className
        )}
      >
        <RAC.ListBox items={items} className={selectListCss}>
          {children}
        </RAC.ListBox>
      </RAC.Popover>
    </RAC.Select>
  );
}

export interface SelectItemProps extends RAC.ListBoxItemProps {}

export function SelectItem({ className, ...props }: SelectItemProps) {
  return (
    <RAC.ListBoxItem {...props} className={clsx(selectItemCss, className)} />
  );
}

export interface SelectButtonProps {
  className?: string;
  textClassName?: string;
  iconClassName?: string;
}

export function SelectButton({
  className,
  textClassName,
  iconClassName,
}: SelectButtonProps) {
  return (
    <AriaButton className={clsx(selectButtonCss, className)}>
      <RAC.SelectValue className={clsx(selectButtonTextCss, textClassName)} />
      <Icon
        icon={StPriceArrow}
        size={7}
        color={theme.color.charcoal}
        className={clsx(selectButtonIconCss, iconClassName)}
      />
    </AriaButton>
  );
}

export interface SelectHeaderProps {
  className?: string;
  children?: ReactNode;
}

export function SelectHeader({ className, children }: SelectHeaderProps) {
  return (
    <RAC.Header className={clsx(selectItemHeaderCss, className)}>
      {children}
    </RAC.Header>
  );
}

export const SelectValue = RAC.SelectValue;
export const SelectSection = RAC.Section;
export const SelectionCollection = RAC.Collection;

export type SelectKey = string | number;
