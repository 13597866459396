import type { TNumericStateCalculator } from "@streamtimefe/entities";
import type { PropsWithChildren } from "react";
import { useCallback, useMemo } from "react";
import type { ReportingDisplayRule } from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";

import type { SeriesDataValue } from "../../lib/VectorMatrixSeriesData";
import { useReportingSavedSegmentColumns } from "../../state/stores/savedSegmentSelectors";
import type { TTableDisplayRulesContext } from "./TableDisplayRulesContext";
import { TableDisplayRulesContext } from "./TableDisplayRulesContext";

type Props = PropsWithChildren;

type RulesConfig =
  | {
      displayRules: ReportingDisplayRule[];
      columnIndexes: number[];
    }
  | {
      comparators: TNumericStateCalculator[];
    };

export function TableDisplayRulesProvider({ children }: Props) {
  const columns = useReportingSavedSegmentColumns();

  const rules = useMemo(() => {
    const rules: Record<string, RulesConfig> = {};

    Object.keys(columns).map((key) => {
      const column = columns[key];

      if (!column.displayRules) {
        rules[key] = {
          comparators:
            CReportingSavedSegment.getDefaultNumericStateCalculators(column),
        };
      } else {
        const columnIndexes: number[] = [];
        column.displayRules.forEach((displayRule, index) => {
          if (typeof displayRule.threshold === "string") {
            columnIndexes.push(index);
          }
        });

        if (columnIndexes.length === 0) {
          rules[key] = {
            comparators: column.displayRules as TNumericStateCalculator[],
          };
        } else {
          rules[key] = {
            displayRules: column.displayRules,
            columnIndexes,
          };
        }
      }
    });

    return rules;
  }, [columns]);

  const getNumericStateCalculators = useCallback<
    TTableDisplayRulesContext["getNumericStateCalculators"]
  >(
    (columnId: string, rowVectorValues?: Record<string, SeriesDataValue>) => {
      if (columnId in rules) {
        const rule = rules[columnId];

        if ("comparators" in rule) {
          return rule.comparators;
        } else {
          const calculators: TNumericStateCalculator[] = [];
          rule.displayRules.forEach((displayRule) => {
            if (typeof displayRule.threshold === "number") {
              calculators.push({
                comparator: displayRule.comparator,
                state: displayRule.state,
                threshold: displayRule.threshold,
              });
            } else {
              if (rowVectorValues && displayRule.threshold in rowVectorValues) {
                const thresholdValue = rowVectorValues[displayRule.threshold];
                if (typeof thresholdValue === "number") {
                  calculators.push({
                    comparator: displayRule.comparator,
                    state: displayRule.state,
                    threshold: thresholdValue,
                  });
                }
              }
            }
          });

          return calculators;
        }
      }

      return [];
    },
    [rules]
  );

  return (
    <TableDisplayRulesContext.Provider value={{ getNumericStateCalculators }}>
      {children}
    </TableDisplayRulesContext.Provider>
  );
}
