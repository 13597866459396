import type {
  TStatisticFieldEnum,
  TStatisticModeEnum,
} from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  StatisticField,
  StatisticFieldEnum,
  StatisticMode,
  StatisticModeEnum,
  ValueFormatEnum,
} from "@streamtimefe/entities";
import { isEqual } from "lodash-es";
import type { ChangeEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import type { PopoverMenuProps, SelectKey } from "st-shared/components";
import {
  Button,
  Icon,
  Input,
  PopoverMenu,
  RTooltip,
  Select,
  SelectItem,
  StFilterAlt,
} from "st-shared/components";
import type {
  ReportingDataColumn,
  ReportingDataSet,
  ReportingDisplayRule,
  TReportingEntityDescriptor,
} from "st-shared/entities";
import {
  CReportingSavedSegment,
  ReportingColumnType,
  ReportingFiltersDefaultDescriptionsOverride,
} from "st-shared/entities";
import { FilterDisplayText } from "st-shared/module";
import { usePermissions } from "st-shared/stores";
import { boldText } from "st-shared/theme";
import { EntityDescriptorProperties } from "st-shared/types";

import { getOrderedMap } from "../../lib/orderedMap";
import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentDataSets,
} from "../../state/stores/savedSegmentSelectors";
import { ColumnSearchDropdown } from "../table/ColumnSearchDropdown";
import { DisplayRulesButton } from "./DisplayRulesButton";
import {
  columnModeSelector,
  columnModeSelectorButton,
  dataSetNameCss,
  dataSetSelector,
  dataSetSelectorButton,
  dataSetSelectorContainer,
  dataSetTooltipConentCss,
  dataSetTooltipCss,
  editColumnContent,
  editColumnFooter,
  editColumnTitle,
  footerButtonBase,
  footerButtonPrimary,
  popoverCss,
  saveTooltipCss,
  tooltipCss,
} from "./EditDataColumn.css";

type EditProps = {
  columnId: string;
  type: "edit";
};

type AddProps = {
  entityDescriptorId: TReportingEntityDescriptor;
  dataSetId?: string;
  type: "add";
};

export interface EditDataColumnProps {
  anchorEl?: Element | null;
  onClose: (...args: any) => void;
  anchorOrigin?: PopoverMenuProps["anchorOrigin"];
  transformOrigin?: PopoverMenuProps["transformOrigin"];
  init: EditProps | AddProps;
}

export function EditDataColumn({
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  init,
}: EditDataColumnProps) {
  const dataSets = useReportingSavedSegmentDataSets();
  const columns = useReportingSavedSegmentColumns();
  const permissions = usePermissions();

  const inputRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [entityDescriptorId] = useState(() => {
    switch (init.type) {
      case "edit": {
        const column = columns[init.columnId] as ReportingDataColumn;
        return dataSets[column.dataSetId].entityDescriptorId;
      }
      case "add": {
        return init.entityDescriptorId;
      }
    }
  });

  const [filteredDataSets] = useState(() => {
    return getOrderedMap(dataSets).filter(
      (dataSet) => dataSet.entityDescriptorId === entityDescriptorId
    );
  });

  const [newDataSet, setNewDataSet] = useState(() =>
    reportingStore()
      .savedSegment.helpers.getReportingSavedSegment()
      .createDataSet(entityDescriptorId, "")
  );

  const [columnData, setColumnData] = useState<{
    dataSet: ReportingDataSet | null;
    modeId: TStatisticModeEnum;
    columnId: TStatisticFieldEnum | null;
    name: string;
    displayRules?: ReportingDisplayRule[];
  }>(() => {
    let dataSet: ReportingDataSet | null = null;
    let modeId: TStatisticModeEnum = StatisticModeEnum.Sum;
    let columnId: TStatisticFieldEnum | null = null;
    let name: string = "";
    let displayRules: ReportingDisplayRule[] | undefined = undefined;

    switch (init.type) {
      case "edit": {
        const column = columns[init.columnId] as ReportingDataColumn;

        dataSet = dataSets[column.dataSetId];
        modeId = column.modeId;
        columnId = column.columnId;
        name = column.name;
        displayRules = column.displayRules;
        break;
      }
      case "add": {
        if (init.dataSetId) {
          dataSet = dataSets[init.dataSetId];
        } else if (filteredDataSets.length > 0) {
          dataSet = filteredDataSets[0];
        } else {
          setNewDataSet((data) => {
            return {
              ...data,
              name: EntityDescriptorProperties[entityDescriptorId].name,
            };
          });
        }
        break;
      }
    }

    return {
      dataSet,
      modeId,
      columnId,
      name,
      displayRules,
    };
  });

  const allowedColumnIds = useMemo(() => {
    return CReportingSavedSegment.getAllowedColumnIds(
      entityDescriptorId,
      permissions
    );
  }, [permissions, entityDescriptorId]);

  function columnNameExists(name: string) {
    let filteredColumns = Object.values(columns);
    if (init.type === "edit") {
      filteredColumns = filteredColumns.filter(
        (column) => column.id !== init.columnId
      );
    }
    const findIndex = filteredColumns.findIndex(
      (column) => column.name.toLowerCase() === name.toLowerCase()
    );
    return findIndex !== -1;
  }

  const validationError = useMemo(() => {
    if (!columnData.columnId) {
      return "Choose a data field for this column";
    }

    if (
      columnData.modeId === StatisticModeEnum.Sum &&
      StatisticField.getFormat(columnData.columnId) ===
        ValueFormatEnum.Percentage
    ) {
      return `Percentage field must be set to ${StatisticMode.getName(StatisticModeEnum.Average).toLowerCase()}`;
    }

    const forceMode = StatisticField.getForceMode(columnData.columnId);
    if (forceMode && columnData.modeId !== forceMode) {
      return `${StatisticField.getName(columnData.columnId, undefined, columnData.dataSet?.entityDescriptorId)} must be set to ${StatisticMode.getName(forceMode).toLowerCase()}`;
    }

    if (columnData.dataSet) {
      let dataColumns = CReportingSavedSegment.getDataColumns(columns);
      if (init.type === "edit") {
        dataColumns = dataColumns.filter(
          (column) => column.id !== init.columnId
        );
      }
      const findIndex = dataColumns.findIndex(
        (column) =>
          column.dataSetId === columnData.dataSet?.id &&
          column.modeId === columnData.modeId &&
          column.columnId === columnData.columnId
      );
      if (findIndex !== -1) {
        return "This column already exists. Change filter set or data field to save";
      }
    }

    if (!columnData.dataSet) {
      if (newDataSet.name === "") {
        return "Set a name for the new filter set";
      }

      let filteredDataSets = Object.values(dataSets);
      if (init.type === "edit") {
        const column = columns[init.columnId] as ReportingDataColumn;
        filteredDataSets = filteredDataSets.filter(
          (dataSet) => dataSet.id !== column.dataSetId
        );
      }

      const findIndex = filteredDataSets.findIndex(
        (dataSet) =>
          dataSet.name.toLowerCase() === newDataSet.name.toLowerCase()
      );
      if (findIndex !== -1) {
        return "The filter set name already exists. Change filter set name to save";
      }
    }

    if (columnData.name === "") {
      return "Set a name for the data field";
    }

    if (columnData.name) {
      if (columnNameExists(columnData.name)) {
        return "The data field name already exists. Change data field name to save";
      }
    }

    return undefined;
  }, [init, columnData, newDataSet, columns]);

  function onChangeMode(modeId: TStatisticModeEnum) {
    setColumnData((data) => {
      let columnId = data.columnId;
      if (modeId === StatisticModeEnum.Count) {
        columnId = StatisticFieldEnum.Count;
      }
      if (
        data.modeId === StatisticModeEnum.Count &&
        modeId !== StatisticModeEnum.Count
      ) {
        columnId = null;
      }
      return {
        ...data,
        modeId,
        columnId,
      };
    });
  }

  function onPickColumn(columnId: TStatisticFieldEnum) {
    setColumnData((data) => {
      let name = data.name;
      let modeId = data.modeId;

      function getColumnNamePrefixed(value: string) {
        const dataSetName = data.dataSet?.name || newDataSet.name;
        return `${dataSetName} ${value}`;
      }

      if (data.columnId) {
        const currentDefault = StatisticField.getName(
          data.columnId,
          data.modeId,
          data.dataSet?.entityDescriptorId
        );
        if (
          name === currentDefault ||
          name === getColumnNamePrefixed(currentDefault)
        ) {
          name = StatisticField.getName(
            columnId,
            data.modeId,
            data.dataSet?.entityDescriptorId
          );
        }
      }

      if (!name) {
        name = StatisticField.getName(
          columnId,
          data.modeId,
          data.dataSet?.entityDescriptorId
        );
      }

      if (columnNameExists(name)) {
        name = getColumnNamePrefixed(name);
      }

      let forceMode: TStatisticModeEnum | undefined;

      if (StatisticField.getFormat(columnId) === ValueFormatEnum.Percentage) {
        forceMode = StatisticModeEnum.Average;
      }

      if (StatisticField.getForceMode(columnId)) {
        forceMode = StatisticField.getForceMode(columnId);
      }

      if (forceMode) {
        modeId = forceMode;
      }

      return {
        ...data,
        columnId,
        modeId,
        name,
      };
    });
  }

  function onDataSetNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    setNewDataSet((data) => ({
      ...data,
      name: event.target.value,
    }));
  }

  function onNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    setColumnData((data) => ({
      ...data,
      name: event.target.value,
    }));
  }

  const defaultDisplayRules = useMemo(
    () =>
      columnData.columnId
        ? CReportingSavedSegment.getDataColumnNumericStateCalculators(
            columnData.columnId
          )
        : [],
    [columnData.columnId]
  );

  function onSetDisplayRules(displayRules?: ReportingDisplayRule[]) {
    if (displayRules && !isEqual(displayRules, defaultDisplayRules)) {
      setColumnData((data) => ({
        ...data,
        displayRules,
      }));
    } else {
      setColumnData((data) => ({
        ...data,
        displayRules: undefined,
      }));
    }
  }

  function onSave() {
    const { dataSet, modeId, columnId, name, displayRules } = columnData;
    if (validationError || !columnId) return;

    let dataSetId: string;
    let type: ReportingDataColumn["type"] = ReportingColumnType.Data;

    if (dataSet) {
      dataSetId = dataSet.id;
      if (dataSet.entityDescriptorId === EntityDescriptorEnum.Job) {
        type = ReportingColumnType.Total;
      }
    } else {
      dataSetId = newDataSet.id;
      if (newDataSet.entityDescriptorId === EntityDescriptorEnum.Job) {
        type = ReportingColumnType.Total;
      }

      reportingStore().savedSegment.actions.addDataset(
        newDataSet.entityDescriptorId,
        newDataSet.name,
        newDataSet.id
      );
    }

    switch (init.type) {
      case "edit":
        reportingStore().savedSegment.actions.updateDataColumn({
          id: init.columnId,
          dataSetId,
          modeId,
          columnId,
          name,
          type,
          displayRules,
        });
        break;
      case "add":
        reportingStore().savedSegment.actions.addDataColumn({
          dataSetId,
          modeId,
          columnId,
          name,
          type,
          displayRules,
        });
        break;
    }

    onClose();
  }

  function onSelectionChange(value: SelectKey) {
    const dataSet = value === null ? null : dataSets[value];

    setColumnData((data) => {
      return {
        ...data,
        dataSet,
      };
    });

    if (dataSet) {
      setNewDataSet((data) => {
        return {
          ...data,
          name: "",
        };
      });
    }
  }

  function renderDatSetTooltip(dataSetId: string) {
    const dataSet = dataSets[dataSetId];

    if (!dataSet) return null;

    return (
      <div className={dataSetTooltipConentCss}>
        {dataSet.filterGroups.map((filterGroup) => (
          <div key={filterGroup.id}>
            <FilterDisplayText
              filterGroup={filterGroup}
              defaultDescriptions={ReportingFiltersDefaultDescriptionsOverride}
            />
          </div>
        ))}
        <div>Edit this filter set in the sidebar</div>
      </div>
    );
  }

  useEffect(() => {
    if (entityDescriptorId === EntityDescriptorEnum.User) {
      onPickColumn(allowedColumnIds[0]);
    }

    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopoverMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      classes={{
        paper: popoverCss,
      }}
    >
      <div className={editColumnTitle} ref={titleRef}>
        {init.type === "edit" ? "Edit" : "Add"}{" "}
        {EntityDescriptorProperties[entityDescriptorId].name} Column
      </div>
      <div className={editColumnContent}>
        {filteredDataSets.length > 0 && (
          <div className={dataSetSelectorContainer}>
            <Icon icon={StFilterAlt} size={18} />
            <Select
              aria-label="Set Dataset Selector"
              items={filteredDataSets}
              selectedKey={columnData.dataSet?.id || null}
              onSelectionChange={onSelectionChange}
              placeholder="Create new empty set"
              className={dataSetSelector}
              buttonProps={{ className: dataSetSelectorButton }}
            >
              {filteredDataSets.map((item) => (
                <SelectItem
                  key={item.id}
                  id={item.id}
                  data-tooltip-id="select-filter-set-tooltip"
                  data-tooltip-content={String(item.id)}
                >
                  {item.name}
                </SelectItem>
              ))}
              <SelectItem id={null as unknown as SelectKey}>
                Create new empty set
              </SelectItem>
            </Select>
            <RTooltip
              id="select-filter-set-tooltip"
              place="right"
              className={dataSetTooltipCss}
              positionStrategy="fixed"
              render={({ content }) => renderDatSetTooltip(content || "")}
            />
          </div>
        )}
        {!columnData.dataSet && (
          <div className={dataSetSelectorContainer}>
            {filteredDataSets.length === 0 && (
              <Icon icon={StFilterAlt} size={18} />
            )}
            <Input
              variant="secondary"
              placeholder={"Name new filter set..."}
              value={newDataSet.name}
              onChange={onDataSetNameInputChange}
              className={dataSetNameCss}
            />
          </div>
        )}
        {entityDescriptorId !== EntityDescriptorEnum.User && (
          <div className={columnModeSelector}>
            <ModeButton
              modeId={StatisticModeEnum.Sum}
              selectedModeId={columnData.modeId}
              onChange={onChangeMode}
            />
            <ModeButton
              modeId={StatisticModeEnum.Average}
              selectedModeId={columnData.modeId}
              onChange={onChangeMode}
            />
            <ModeButton
              modeId={StatisticModeEnum.Count}
              selectedModeId={columnData.modeId}
              onChange={onChangeMode}
            />
            <RTooltip
              id="mode-tooltip"
              place="top"
              className={tooltipCss}
              positionStrategy="fixed"
              render={({ content }) => {
                if (!content) return null;
                const modeId = Number(content) as TStatisticModeEnum;
                let tooltipText = "";
                switch (modeId) {
                  case StatisticModeEnum.Sum:
                    tooltipText =
                      "adds up and displays the total of the selected field from the data set.";
                    break;
                  case StatisticModeEnum.Average:
                    tooltipText =
                      "displays the average value of the selected field from the data set.";
                    break;
                  case StatisticModeEnum.Count:
                    tooltipText =
                      "displays the number of records from the data set.";
                    break;
                }
                return (
                  <>
                    <span className={boldText}>
                      {StatisticMode.getName(modeId)}
                    </span>{" "}
                    {tooltipText}
                  </>
                );
              }}
            />
          </div>
        )}
        {columnData.modeId &&
          columnData.modeId !== StatisticModeEnum.Count &&
          entityDescriptorId !== EntityDescriptorEnum.User && (
            <ColumnSearchDropdown
              dataSetEntityDescriptorId={entityDescriptorId}
              columnId={columnData.columnId}
              columns={allowedColumnIds}
              onPick={onPickColumn}
            />
          )}
        <div>
          <Input
            variant="secondary"
            placeholder={"Name this column..."}
            value={columnData.name}
            onChange={onNameInputChange}
            ref={inputRef}
          />
        </div>
        {columnData.columnId && (
          <DisplayRulesButton
            containerRef={titleRef}
            displayRules={columnData.displayRules}
            defaultDisplayRules={defaultDisplayRules}
            setDisplayRules={onSetDisplayRules}
            columnId={init.type === "edit" ? init.columnId : undefined}
            format={StatisticField.getFormat(columnData.columnId)}
          />
        )}
      </div>
      <div className={editColumnFooter}>
        <Button className={footerButtonBase} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className={footerButtonPrimary}
          disabled={Boolean(validationError)}
          onClick={onSave}
          data-tooltip-id="button-disabled-tooltip"
        >
          Save
        </Button>
        <RTooltip
          id="button-disabled-tooltip"
          place="top"
          className={saveTooltipCss}
          content={validationError}
          positionStrategy="fixed"
        />
      </div>
    </PopoverMenu>
  );
}

interface ModeButtonProps {
  modeId: TStatisticModeEnum;
  selectedModeId: TStatisticModeEnum | null;
  onChange: (modeId: TStatisticModeEnum) => void;
}

function ModeButton({ modeId, selectedModeId, onChange }: ModeButtonProps) {
  return (
    <Button
      variant="primary"
      className={
        columnModeSelectorButton[
          selectedModeId === modeId ? "selected" : "base"
        ]
      }
      onClick={() => onChange(modeId)}
      data-tooltip-id="mode-tooltip"
      data-tooltip-content={String(modeId)}
    >
      {StatisticMode.getName(modeId)}
    </Button>
  );
}
