import { ActionPattern, cancel, fork, take } from "redux-saga/effects";

import { SagaEffect } from "./types";

export function takeLatestBy<T extends SagaEffect>(
  pattern: ActionPattern,
  saga: (effect: T) => any,
  iteratee: (effect: T) => string | number
) {
  return fork(function* effect() {
    const concurrentTasks: Record<string | number, any> = {};

    while (true) {
      const action: T = yield take(pattern);
      const concurrencyKey = iteratee(action);
      const lastTask: any = concurrentTasks[concurrencyKey];

      if (lastTask) yield cancel(lastTask);

      concurrentTasks[concurrencyKey] = yield fork(saga, action);
    }
  });
}
