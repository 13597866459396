import type { TRole } from "@streamtimefe/entities";

import type { TEntityId } from "../../../entities/Entity";
import { useRoleEntity, useUserEntity } from "..";

export function useRoleFromUserEntity(entityId: TEntityId) {
  const user = useUserEntity(entityId);
  const roleId = user?.roleId;
  const role: TRole = useRoleEntity(roleId!);
  if (role) {
    return role;
  }
  return null;
}
