import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  accountingPlatformStatusType,
  isAccountingPlatformStatusActive
} from "../../../../lib/ui/accountingPlatform";
import {
  commercialDocumentLineItemEntityType,
  getTaxName,
  getTaxRate
} from "../../../../lib/ui/commercialDocumentLineItems";
import { selectAccountingPlatformStatus } from "../../../../redux/selectors/accountingPlatformSelectors";
import Spacer from "../../../elements/Spacer";
import Ellipsis from "../../../elements/Typography/Ellipsis";
import TaxRateTooltip from "../../TaxRate/TaxRateTooltip";
import TaxRatesSearchMenu from "../../TaxRate/TaxRatesSearchMenu";
import DocumentLineItemEditConsumer from "../CommercialDocumentContext/Consumers/DocumentLineItemEditConsumer";

const mapState = (state, props) => ({
  accountingPlatformStatus: selectAccountingPlatformStatus(state, props)
});

class DocumentLineTaxRate extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    documentLineItem: commercialDocumentLineItemEntityType.isRequired,
    isHiddenField: PropTypes.bool,
    isDisabled: PropTypes.bool,
    accountType: PropTypes.oneOf(values(ACCOUNTING_PLATFORM_ACCOUNT_TYPES))
      .isRequired,
    accountingPlatformStatus: accountingPlatformStatusType.isRequired,
    doSaveDocumentLineItem: PropTypes.func.isRequired,
    doDocumentLineItemCreateTaxRate: PropTypes.func.isRequired
  };

  static defaultProps = {
    isHiddenField: false,
    isDisabled: false
  };

  state = {
    anchorEl: null
  };

  get className() {
    const { isHiddenField } = this.props;
    const classes = [];

    if (isHiddenField) classes.push("hiddenField");

    return classes.join(" ");
  }

  get taxRateName() {
    const { documentLineItem } = this.props;

    const taxName = getTaxName(documentLineItem);
    const taxRate = getTaxRate(documentLineItem);

    if (!taxName) return "No Tax";

    if (taxName.includes(`${taxRate}%`)) return taxName;

    return `${taxRate}% ${taxName}`;
  }

  editTaxRate = () => {
    this.setState({
      anchorEl: this.ref.current
    });
  };

  closeTaxRateMenu = () => {
    this.setState({ anchorEl: null });
  };

  createTaxRate = () => {
    const { documentLineItem, doDocumentLineItemCreateTaxRate } = this.props;

    doDocumentLineItemCreateTaxRate(documentLineItem.id);

    this.closeTaxRateMenu();
  };

  save = (id, taxName, taxRate) => {
    const {
      documentLineItem,
      accountingPlatformStatus,
      doSaveDocumentLineItem
    } = this.props;

    const externalTaxRateId = isAccountingPlatformStatusActive(
      accountingPlatformStatus
    )
      ? id
      : "";

    if (
      taxName !== getTaxName(documentLineItem) ||
      taxRate !== getTaxRate(documentLineItem)
    )
      doSaveDocumentLineItem(
        documentLineItem.id,
        {
          ...documentLineItem,
          externalTaxRateId,
          taxName,
          taxRate
        },
        documentLineItem
      );
  };

  handleChange = (id, taxName, taxRate) => {
    if (!id) this.save(null, "", null);
    else this.save(id, taxName, taxRate);

    this.closeTaxRateMenu();
  };

  render() {
    const { accountType, documentLineItem, isDisabled } = this.props;
    const { anchorEl } = this.state;

    if (isDisabled) return null;

    const taxName = getTaxName(documentLineItem);
    const taxRate = getTaxRate(documentLineItem);

    return (
      <Wrapper className={this.className}>
        <DisplayTaxRate
          disabled={isDisabled}
          onClick={this.editTaxRate}
          ref={this.ref}
        >
          <TaxRateTooltip taxName={taxName} taxRate={taxRate}>
            <TaxName>{this.taxRateName}</TaxName>
          </TaxRateTooltip>
          <Spacer w={2} />
        </DisplayTaxRate>
        {!isDisabled && (
          <TaxRatesSearchMenu
            anchorEl={anchorEl}
            onPickOption={this.handleChange}
            onClose={this.closeTaxRateMenu}
            onCreate={this.createTaxRate}
            accountType={accountType}
            documentLineItem={documentLineItem}
          />
        )}
      </Wrapper>
    );
  }
}

export default DocumentLineItemEditConsumer(
  connect(mapState)(DocumentLineTaxRate)
);

const Wrapper = styled.div`
  width: 100%;
  height: 26px;
  background: white;
  color: black;
  &.hiddenField {
    background-color: var(--color-gray-bright);
    color: var(--color-gray-dark);
  }
`;

const DisplayTaxRate = styled.div`
  height: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const TaxName = styled(Ellipsis)`
  padding-top: 1px;
  font-size: 11px;
  font-weight: 500;
  line-height: initial;
  width: 80px;
`;
