import { AppState } from "../AppState";

export function selectPropsId(_state: AppState, props: { id: number }) {
  return props.id;
}

export function selectPropsKey(_state: AppState, props: { key: string }) {
  return props.key;
}

export function selectPropsRateCardId(
  _state: AppState,
  props: { rateCardId: number }
) {
  return props.rateCardId;
}

export function selectPropsMasterJobItemId(
  _state: AppState,
  props: { masterJobItemId: number }
) {
  return props.masterJobItemId;
}

export function selectPropsMasterLoggedExpenseId(
  _state: AppState,
  props: { masterLoggedExpenseId: number }
) {
  return props.masterLoggedExpenseId;
}

export function selectPropsJobItemRoleId(
  _state: AppState,
  props: { jobItemRoleId: number }
) {
  return props.jobItemRoleId;
}

export function selectPropsJobItemId(
  _state: AppState,
  props: { jobItemId: number }
) {
  return props.jobItemId;
}

export function selectPropsJobPhaseId(
  _state: AppState,
  props: { jobPhaseId: number }
) {
  return props.jobPhaseId;
}

export function selectPropsRoleId(_state: AppState, props: { roleId: number }) {
  return props.roleId;
}

export function selectPropsJobId(_state: AppState, props: { jobId: number }) {
  return props.jobId;
}

export function selectPropsIds(_state: AppState, props: { ids: number[] }) {
  return props.ids;
}

export function selectPropsUserId(_state: AppState, props: { userId: number }) {
  return props.userId;
}
