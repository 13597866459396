import { NumericState } from "@streamtimefe/entities";
import clsx from "clsx";
import { isNil } from "lodash-es";
import { useMemo } from "react";
import type { ReportingColumn } from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";
import { useCustomerCurrency } from "st-shared/stores";
import { formatStatisticValue } from "st-shared/types";

import type { SeriesDataValue } from "../../lib/VectorMatrixSeriesData";
import { columnContainer, numericStateCss } from "./Table.css";
import { useTableDisplayRulesContext } from "./TableDisplayRulesContext";

interface Props {
  column: ReportingColumn;
  value: number | null;
  hide?: boolean;
  className?: string;
  indexRow?: string;
  indexColumn?: string;
  rowVectorValues?: Record<string, SeriesDataValue>;
}

export function TableColumn({
  column,
  value,
  hide = false,
  className = columnContainer,
  indexRow,
  indexColumn,
  rowVectorValues,
}: Props) {
  const customerCurrency = useCustomerCurrency();
  const format = CReportingSavedSegment.getColumnFormat(column);

  const { getNumericStateCalculators } = useTableDisplayRulesContext();

  const numericState = useMemo(
    () =>
      NumericState.calculate(
        value,
        getNumericStateCalculators(column.id, rowVectorValues)
      ),
    [column.id, value, getNumericStateCalculators, rowVectorValues]
  );

  return (
    <div
      className={clsx(className, numericStateCss[numericState])}
      data-tooltip-id="reporting-table-cell"
      data-table-cell-row={indexRow}
      data-table-cell-column={indexColumn}
    >
      {!hide &&
        (!isNil(value)
          ? formatStatisticValue(value, format, customerCurrency)
          : "—")}
    </div>
  );
}
