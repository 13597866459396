// deprecated

import type { TLabel } from "@streamtimefe/entities";
import { produce } from "immer";

import { addDays } from "../lib/dates";
import type { ActivityEntry } from "./ActivityEntry";
import * as Contact from "./Contact";
import type { Entity } from "./Entity";
import * as Job from "./Job";
import type { JobMilestone } from "./JobMilestone";
import type { JobStatusesType } from "./JobStatus";
import { JobStatuses } from "./JobStatus";
import type { NameEntity } from "./NameEntity";

export type Type = JobListModel;
export interface JobListModel extends Entity {
  companyName: string;
  branch: string;
  jobLeadUserId: number | null;
  jobLeadUserDisplayName: string | null;
  companyLeadUserId: number | null;
  companyLeadUserDisplayName: string | null;
  jobCurrencyCode: string;
  jobExchangeRate: string | number;
  isBillable: boolean;
  jobStatus: string;
  jobStatusId: JobStatusesType;
  jobNumber: string | null;
  jobPurchaseOrderNumber: string | null;
  jobName: string;
  jobFullName: string;
  rateCardName: string;
  createdUser: string;
  jobCreatedDate: string;
  estimatedStartDate: string | null;
  estimatedEndDate: string | null;
  earliestStartDate: string | null;
  latestEndDate: string | null;
  completedDate: string | null;
  rateCardId: number;
  contact: string | null;
  jobCurrencyBudget: string | number | null;
  jobCurrencyFinalBudget: string | null;
  totalPlannedMinutes: number | number;
  totalLoggedMinutes: number | number;
  totalIncompleteMinutes: number | number;
  jobCurrencyTotalPlannedTimeExTax: string | number | null;
  jobCurrencyTotalPlannedExTax: string | number | null;
  jobCurrencyTotalIncompleteTimeExTax: string | number | null;
  jobCurrencyTotalLoggedCostExTax: string | number | null;
  jobCurrencyTotalLoggedExTax: string | number | null;
  jobCurrencyTotalPlannedExpensesCostExTax: string | number | null;
  jobCurrencyTotalPlannedExpensesExTax: string | number | null;
  jobCurrencyTotalLoggedExpensesCostExTax: string | number | null;
  jobCurrencyTotalLoggedExpensesExTax: string | number | null;
  jobCurrencyTotalLoggedTimeCostExTax: string | number | null;
  jobCurrencyTotalLoggedTimeExTax: string | number | null;
  jobCurrencyTotalInvoicedExTax: string | number | null;
  jobCurrencyTotalDraftInvoicesExTax: string | number | null;
  jobCurrencyTotalAwaitingPaymentInvoicesExTax: string | number | null;
  jobCurrencyTotalPaidInvoicesExTax: string | number | null;
  jobCurrencyTotalIssuedInvoicesExTax: string | number | null;
  jobCurrencyTotalApprovedQuoteExTax: string | number | null;
  totalNonBillablePlannedMinutes: number | null;
  jobCurrencyTotalNonBillablePlannedTimeExTax: number | null;
  totalNonBillableLoggedMinutes: number | null;
  jobCurrencyTotalNonBillableLoggedTimeExTax: number | null;
  latestCommentActivityEntry: ActivityEntry | null;
  users: NameEntity[];
  roles: (NameEntity & { totalPlannedMinutes: number })[];
  labels: TLabel[];
  jobMilestones: JobMilestone[];
}

export function isDeleted(entity: JobListModel) {
  return entity.jobStatusId === JobStatuses.Deleted;
}

export function updateWithJobValues(jobListModel: JobListModel, job: Job.Type) {
  return produce(jobListModel, (draft) => {
    draft.jobCurrencyBudget = job.jobCurrencyBudget;
    draft.companyName = job.company ? job.company.name : "";
    draft.contact = job.contact ? Contact.getFullName(job.contact) : "";
    draft.jobFullName = Job.getFullName(job);
    draft.jobName = job.name;
    draft.jobNumber = job.number;
    draft.jobStatusId = job.jobStatus.id;
    draft.jobStatus = Job.getStatusName(job.jobStatus.id);
    draft.estimatedEndDate = job.estimatedEndDate;
    draft.estimatedStartDate = job.estimatedStartDate;
    draft.earliestStartDate = job.earliestStartDate;
    draft.latestEndDate = job.latestEndDate;
    draft.rateCardId = job.rateCardId;
    draft.jobExchangeRate = job.exchangeRate;
    draft.totalIncompleteMinutes = job.totalIncompleteMinutes;
    draft.totalLoggedMinutes = job.totalLoggedMinutes;
    draft.totalPlannedMinutes = job.totalPlannedMinutes;
    draft.jobCurrencyTotalIncompleteTimeExTax =
      job.jobCurrencyTotalIncompleteTimeExTax;
    draft.jobCurrencyTotalLoggedTimeExTax = job.jobCurrencyTotalLoggedTimeExTax;
    draft.jobCurrencyTotalPlannedTimeExTax =
      job.jobCurrencyTotalPlannedTimeExTax;
  });
}

export function moveDays(entity: JobListModel, days: number) {
  return produce(entity, (draft) => {
    if (entity.estimatedStartDate) {
      draft.estimatedStartDate = addDays(entity.estimatedStartDate, days);
    }
    if (entity.estimatedEndDate) {
      draft.estimatedEndDate = addDays(entity.estimatedEndDate, days);
    }
  });
}
