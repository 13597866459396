import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const UnlinkIcon = ({ ...props }) => (
  <SvgIcon viewBox="-2 0 24 15" {...props}>
    <path d="M13.586 8.586l-2-2H14v2h-.414zm-5.1-5.1l-1.9-1.9H9v1.9h-.514zM3 0l13.968 13.968-1 1-1.408-1.408c-.185.017-.37.026-.56.026h-3v-1.9h1.686l-3.1-3.1H6v-2h1.586L4.69 3.69C3.053 4.226 1.9 5.747 1.9 7.587c0 2.3 1.8 4.1 4.1 4.1h3v1.9H6c-3.3 0-6-2.7-6-6 0-2.313 1.326-4.33 3.255-5.33L2 1l1-1zm14.472 12.472l-1.356-1.356c1.197-.708 1.984-2.01 1.984-3.53 0-2.3-1.8-4.1-4.1-4.1h-3v-1.9h3c3.3 0 6 2.7 6 6 0 2.01-1 3.795-2.528 4.886z" />
  </SvgIcon>
);

UnlinkIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

UnlinkIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(UnlinkIcon);
