import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchInvoiceBootstrapAPI } from "../../../lib/API/invoiceAPI";
import WebAppAPI from "../../../lib/WebAppAPI";
import { webAppInvoiceBootstrap } from "../../../lib/WebAppAPI/bootstrap";
import {
  ENTITIES_RECEIVED,
  INVOICE_FETCH_SUCCEEDED,
  INVOICE_FETCH_FAILED,
  INVOICE_FETCH_REQUEST,
  INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectInvoiceUi } from "../../selectors/invoiceSelectors";

function* fetch(action) {
  try {
    const { invoiceId } = action.payload;

    const { fetchedIds } = yield select(selectInvoiceUi);

    if (!fetchedIds.includes(invoiceId)) {
      const { data, previousIssuedInvoiceSummary } = yield call(
        WebAppAPI.isConnected()
          ? webAppInvoiceBootstrap
          : fetchInvoiceBootstrapAPI,
        invoiceId
      );

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));

      yield put(
        createAction(INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY, {
          invoiceId,
          previousIssuedInvoiceSummary
        })
      );
    }

    yield put(createAction(INVOICE_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(INVOICE_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(INVOICE_FETCH_REQUEST, fetch);
}
