import { call, put } from "redux-saga/effects";
import { deleteJobItemSubItemAPI } from "../../../lib/API/jobItemSubItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_SUB_ITEM_DELETING,
  JOB_ITEM_SUB_ITEM_DELETED,
  JOB_ITEM_SUB_ITEM_DELETE_ERROR,
  JOB_ITEM_SUB_ITEM_DELETE,
  ENTITY_NAME_JOB_ITEMS
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* deleteJobItemSubItem(action) {
  const { jobItemSubItem } = action.payload;

  const jobItemSubItemId = jobItemSubItem.id;

  try {
    yield put(
      createAction(JOB_ITEM_SUB_ITEM_DELETING, {
        jobItemSubItemId
      })
    );

    const { data } = yield call(deleteJobItemSubItemAPI, jobItemSubItemId);

    if (data[ENTITY_NAME_JOB_ITEMS]) {
      delete data[ENTITY_NAME_JOB_ITEMS];
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_SUB_ITEM_DELETED, {
        jobItemSubItemId
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_SUB_ITEM_DELETE_ERROR, {
        jobItemSubItemId,
        jobItemSubItem,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchJobItemSubItemDelete() {
  yield takeLatestBy(
    [JOB_ITEM_SUB_ITEM_DELETE],
    deleteJobItemSubItem,
    action => action.payload.jobItemSubItem.id
  );
}
