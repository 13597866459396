import { eventChannel } from "redux-saga";
import WebAppAPI from "../../../lib/WebAppAPI";

const createWebAPIChannel = eventName =>
  eventChannel(emit => {
    WebAppAPI.registerReceiver(eventName, (...args) => emit([...args]) || true);
    return () => WebAppAPI.unregisterReceiver(eventName);
  });

export default createWebAPIChannel;
