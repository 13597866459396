import { useEffect, useRef, useState } from "react";

import {
  doFetchAvailability,
  parseAvailability,
} from "../../../lib/ui/userPicker";

function useUserAvailability(defaultStartDate: any, defaultEndDate: any) {
  const [userAvailability, setUserAvailability] = useState<{
    [key: number]: any;
  }>({});
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  function fetchAvailability(startDate: string, endDate: string) {
    setUserAvailability({});

    doFetchAvailability(startDate, endDate)
      .then((users) => parseAvailability(users))
      .then((newUserAvailability) => {
        if (mounted.current) {
          setUserAvailability(newUserAvailability);
        }
      });
  }

  function onChangeDateRange({
    selectedStartDate,
    selectedEndDate,
  }: {
    selectedStartDate: string;
    selectedEndDate: string;
  }) {
    fetchAvailability(selectedStartDate, selectedEndDate);
  }

  function initialFetch() {
    fetchAvailability(defaultStartDate, defaultEndDate);
  }

  return {
    defaultStartDate,
    defaultEndDate,
    userAvailability,
    fetchAvailability,
    onChangeDateRange,
    initialFetch,
  };
}

export default useUserAvailability;
