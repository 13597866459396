import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { isAfter, isBefore } from "../../../lib/dates";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
} from "../../../lib/entities/jobItemEntity";
import { selectPropsJobPhaseId } from "../index";
import { selectJobItemsByJobPhaseId } from "../jobItem/selectJobItemsByJobPhaseId";

export const selectJobPhaseEstimateStartEndDates = createCachedSelector(
  [selectJobItemsByJobPhaseId],
  (jobItems) => {
    let startDate = null;
    let endDate = null;
    jobItems.forEach((jobItem) => {
      const estimatedStartDate = getEstimatedStartDate(jobItem);
      const estimatedEndDate = getEstimatedEndDate(jobItem);

      if (estimatedStartDate) {
        if (!startDate || isAfter(startDate, estimatedStartDate)) {
          startDate = estimatedStartDate;
        }
      }

      if (estimatedEndDate) {
        if (!endDate || isBefore(endDate, estimatedEndDate)) {
          endDate = estimatedEndDate;
        }
      }
    });

    if (isBefore(endDate, startDate)) {
      return [endDate, startDate];
    }
    return [startDate, endDate];
  }
)(selectPropsJobPhaseId);

export const useJobPhaseEstimateStartEndDates = (jobPhaseId) => {
  return useSelector((state) => {
    // If jobPhaseId is undefined, return default values
    if (!jobPhaseId) return [null, null];

    return selectJobPhaseEstimateStartEndDates(state, { jobPhaseId });
  });
};
