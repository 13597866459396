import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import { setSum } from "../../../../lib/objects";
import {
  getIsHeading,
  getTotalAmountExTax,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    const getQuoteLineItem = (id) => nextState.byId[id];

    touchedQuoteIds.forEach((quoteId) => {
      const sortedIds = get(nextState.sortedIdsByQuoteId, quoteId);

      if (!sortedIds) {
        unset(draft, `subTotalsExTaxByQuoteId.${quoteId}`);
        return;
      }

      const sortedLineItems = sortedIds.map(getQuoteLineItem);
      const subTotals = {};
      let lastHeadingId = null;

      sortedLineItems.forEach((quoteLineItem) => {
        if (getIsHeading(quoteLineItem)) {
          lastHeadingId = quoteLineItem.id;
          return;
        }

        if (!lastHeadingId) return;

        const lineTotal = asDecimal(getTotalAmountExTax(quoteLineItem));

        setSum(subTotals, lastHeadingId, lineTotal);
      });

      setWith(draft, `subTotalsExTaxByQuoteId.${quoteId}`, subTotals, Object);
    });
  });
