import type { TNumericStateCalculator } from "@streamtimefe/entities";
import { createContext, useContext } from "react";

import type { SeriesDataValue } from "../../lib/VectorMatrixSeriesData";

export type TTableDisplayRulesContext = {
  getNumericStateCalculators: (
    columnId: string,
    rowVectorValues?: Record<string, SeriesDataValue>
  ) => TNumericStateCalculator[];
};

export const TableDisplayRulesContext =
  createContext<TTableDisplayRulesContext>(null!);

export function useTableDisplayRulesContext() {
  const context = useContext(TableDisplayRulesContext);

  if (!context) {
    console.error(
      "useTableDisplayRulesContext has to be used within <TableDisplayRulesContext.Provider>"
    );
  }

  return context;
}
