import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { JobItemRole } from "st-shared/entities/JobItemRole";
import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectJobItemRolesByJobItemId = createCachedSelector(
  [selectStateJobItemRoles, selectPropsJobItemId],
  (jobItemRoles, jobItemId) => {
    const ids = jobItemRoles.idsByJobItemId[jobItemId] ?? [];
    return ids.map((id) => jobItemRoles.byId[id]) as JobItemRole[];
  }
)(selectPropsJobItemId);

export function useJobItemRolesByJobItemId(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectJobItemRolesByJobItemId(state, { jobItemId })
  );
}
