// deprecated

import type { Option } from "./Option";

export const JobItemStatuses = {
  Planning: 1,
  Scheduled: 4,
  Complete: 2,
  Deleted: 3,
} as const;

export type JobItemStatusesType =
  (typeof JobItemStatuses)[keyof typeof JobItemStatuses];

export type Type = JobItemStatus;
export interface JobItemStatus extends Option {
  id: JobItemStatusesType;
}

export function isPlanning(entity: JobItemStatus) {
  return entity.id === JobItemStatuses.Planning;
}

export function isScheduled(entity: JobItemStatus) {
  return entity.id === JobItemStatuses.Scheduled;
}

export function isComplete(entity: JobItemStatus) {
  return entity.id === JobItemStatuses.Complete;
}

export function isDeleted(entity: JobItemStatus) {
  return entity.id === JobItemStatuses.Deleted;
}
