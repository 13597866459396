import { produce } from "immer";
import { useReducer } from "react";

interface AssignUserRoleState {
  totalPlannedMinutes: number;
  currentPlannedMinutes: number;
  userIds: number[];
  users: { [key: number]: { userId: number; minutes: number } };
}

type AssignUserRoleAction =
  | {
      type: "addUser";
      userId: number;
    }
  | {
      type: "removeUser";
      userId: number;
    }
  | {
      type: "updateMinutes";
      userId: number;
      minutes: number;
    }
  | { type: "clearMinutes" };

function assignUserRolesReducer(
  state: AssignUserRoleState,
  action: AssignUserRoleAction
) {
  switch (action.type) {
    case "addUser":
      return produce(state, (draft) => {
        draft.userIds.push(action.userId);
        let minutes = 0;
        if (state.userIds.length == 0) {
          minutes = state.totalPlannedMinutes;
          draft.currentPlannedMinutes = 0;
        }
        draft.users[action.userId] = { userId: action.userId, minutes };
      });
    case "removeUser":
      return produce(state, (draft) => {
        draft.currentPlannedMinutes += draft.users[action.userId].minutes;
        delete draft.users[action.userId];
        draft.userIds.splice(draft.userIds.indexOf(action.userId), 1);
      });
    case "updateMinutes":
      return produce(state, (draft) => {
        draft.currentPlannedMinutes +=
          state.users[action.userId].minutes - action.minutes;
        draft.users[action.userId].minutes = action.minutes;
      });
    case "clearMinutes":
      return produce(state, (draft) => {
        draft.currentPlannedMinutes = state.totalPlannedMinutes;
        draft.userIds.forEach((userId) => {
          draft.users[userId].minutes = 0;
        });
      });
    default:
      return state;
  }
}

function useAssignUserRolesReducer(totalPlannedMinutes: number) {
  return useReducer(assignUserRolesReducer, {
    userIds: [],
    users: {},
    totalPlannedMinutes,
    currentPlannedMinutes: totalPlannedMinutes,
  });
}

export default useAssignUserRolesReducer;
