import { isNull, sum, values } from "lodash-es";
import numeral from "numeral";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { NUMBER_FORMAT_FULL_DEFAULT } from "../../../lib/constants";
import { currencyEntityType } from "../../../lib/entities/currencyEntity";
import { calculateInstalment } from "../../../lib/entities/invoiceEntity";
import { asDecimal, round } from "../../../lib/math";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../lib/types/entityTypes";
import {
  calculateDiscount,
  calculateEffectiveTotal,
  calculateEffectiveTotalWithDiscount,
  COMMERCIAL_DOCUMENT_TYPES,
  commercialDocumentEntityType,
  documentTotalsType,
  formatCurrencyForCommercialDocuments,
  getCurrency,
  getExchangeRate,
  getJobId,
} from "../../../lib/ui/commercialDocument";
import {
  calculateMarkupWithExchangeRate,
  getTotalsTaxKey,
} from "../../../lib/ui/commercialDocumentLineItems";
import Flex from "../../elements/Flex";
import JobCurrency from "../Currency/JobCurrency";
import Tooltip from "../Tooltip";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DocumentDiscountField from "./DocumentDiscountField";
import DocumentInstalmentField from "./DocumentInstalmentField";
import LanguageOption from "./LanguageOption";

const MarkupTooltip = () => (
  <p>
    <b>Average markup</b>
    <br />
    Rounding may cause discrepancies in some instances
  </p>
);

const mapContext = ({
  id,
  document,
  commercialDocumentType,
  documentMethods,
}) => ({
  id,
  document,
  commercialDocumentType,
  jobId: getJobId(document),
  documentCurrency: getCurrency(document),
  documentExchangeRate: getExchangeRate(document),
  getDiscount: documentMethods.getDiscount,
  getInstalment: documentMethods.getInstalment,
  displayOptions: documentMethods.getDisplayOptions(document),
});

class DocumentTotals extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    document: commercialDocumentEntityType.isRequired,
    commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
      .isRequired,
    jobId: entityIdType.isRequired,
    documentCurrency: currencyEntityType.isRequired,
    documentExchangeRate: entityFieldDecimalType.isRequired,
    getDiscount: PropTypes.func,
    getInstalment: PropTypes.func,
    documentTotals: documentTotalsType.isRequired,
    displayOptions: PropTypes.shape({
      displayCurrencySymbols: PropTypes.bool.isRequired,
      displayTaxes: PropTypes.bool,
      displayTotal: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    getDiscount: () => 0,
    getInstalment: () => "",
  };

  get totalExTax() {
    const { documentTotals } = this.props;
    const { totalExTax } = documentTotals;

    return totalExTax;
  }

  get invoiceCurrencyAmountPaidIncTax() {
    const { documentTotals } = this.props;
    const { invoiceCurrencyAmountPaidIncTax } = documentTotals;

    return invoiceCurrencyAmountPaidIncTax;
  }

  get balanceIncTax() {
    return this.grandTotal - this.invoiceCurrencyAmountPaidIncTax;
  }

  get totalJobCurrencySellExTax() {
    const { documentTotals } = this.props;
    const { totalJobCurrencySellExTax } = documentTotals;

    return asDecimal(totalJobCurrencySellExTax);
  }

  get totalMarkup() {
    const { commercialDocumentType, documentExchangeRate, documentTotals } =
      this.props;
    const { totalExTax, totalJobCurrencySellExTax } = documentTotals;

    if (commercialDocumentType !== COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER)
      return 0;

    return round(
      calculateMarkupWithExchangeRate(
        totalExTax,
        totalJobCurrencySellExTax,
        documentExchangeRate
      )
    );
  }

  get totalTaxAmounts() {
    const { documentTotals } = this.props;
    const { totalTaxes } = documentTotals;

    return values(totalTaxes).map(({ taxName, taxRate, taxAmount }) => ({
      taxName,
      taxRate,
      taxAmount: calculateEffectiveTotalWithDiscount(
        this.discountPc,
        taxAmount
      ),
    }));
  }

  get instalmentPc() {
    const { getInstalment, document } = this.props;
    const instalment = getInstalment(document);
    return instalment === "" ? null : asDecimal(instalment);
  }

  get instalment() {
    const { documentTotals } = this.props;
    const { totalExTaxLineItems } = documentTotals;

    if (totalExTaxLineItems) {
      let instalment = 0;

      totalExTaxLineItems.forEach((total) => {
        instalment += calculateInstalment(this.instalmentPc, total);
      });

      return instalment;
    }

    return calculateInstalment(this.instalmentPc, this.totalExTax);
  }

  get discountPc() {
    const { getDiscount, document } = this.props;
    return getDiscount(document);
  }

  get discount() {
    return calculateDiscount(this.discountPc, this.instalment);
  }

  get showSubtotal() {
    const { displayOptions } = this.props;
    const { displayTaxes = true } = displayOptions;

    return displayTaxes || this.discountPc > 0 || !isNull(this.instalmentPc);
  }

  get grandTotal() {
    const { displayOptions, documentTotals } = this.props;
    const { displayTaxes = true } = displayOptions;
    const { totalExTaxLineItems } = documentTotals;

    let totalExTax = 0;

    if (totalExTaxLineItems) {
      totalExTaxLineItems.forEach((total) => {
        totalExTax += calculateEffectiveTotal(
          this.discountPc,
          this.instalmentPc,
          total
        );
      });
    } else {
      totalExTax = calculateEffectiveTotal(
        this.discountPc,
        this.instalmentPc,
        this.totalExTax
      );
    }

    if (!displayTaxes) return totalExTax;

    return numeral(totalExTax)
      .add(sum(values(this.totalTaxAmounts).map(({ taxAmount }) => taxAmount)))
      .value();
  }

  formatValue = (value) => {
    const { displayOptions, documentCurrency } = this.props;
    const { displayCurrencySymbols } = displayOptions;

    if (displayCurrencySymbols)
      return formatCurrencyForCommercialDocuments(
        value,
        documentCurrency.symbol
      );

    return numeral(value).format(NUMBER_FORMAT_FULL_DEFAULT);
  };

  formatJobCurrencyValue = (value) => {
    const { jobId } = this.props;

    return <JobCurrency id={jobId} value={value} />;
  };

  handleClickMarkup = (e) => {};

  render() {
    const { commercialDocumentType, displayOptions, documentCurrency } =
      this.props;
    const { displayTotal = true, displayTaxes = true } = displayOptions;

    if (!displayTotal) return null;

    return (
      <Wrapper>
        {this.showSubtotal && (
          <Container className="subTotal">
            <Label>
              <LanguageOption optionKey="subtotal" />
            </Label>
            <Value>{this.formatValue(this.totalExTax)}</Value>
            {commercialDocumentType ===
              COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && (
              <SellContainer>
                <Tooltip title={<MarkupTooltip />} placement="top">
                  <MarkupValue onClick={this.handleClickMarkup}>
                    <span>{this.totalMarkup}%</span>
                  </MarkupValue>
                </Tooltip>
                <SellValue>
                  {this.formatJobCurrencyValue(this.totalJobCurrencySellExTax)}
                </SellValue>
              </SellContainer>
            )}
          </Container>
        )}
        {commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.INVOICE && (
          <Container className={isNull(this.instalmentPc) ? "hiddenField" : ""}>
            <Label>
              <LanguageOption optionKey="instalment" />{" "}
              <DocumentInstalmentField />%
            </Label>
            <Value>
              {!isNull(this.instalmentPc) && this.formatValue(this.instalment)}
            </Value>
            {commercialDocumentType ===
              COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && <SellContainer />}
          </Container>
        )}
        {commercialDocumentType !==
          COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && (
          <Container className={this.discountPc === 0 ? "hiddenField" : ""}>
            <Label>
              <LanguageOption optionKey="discount" /> <DocumentDiscountField />%
            </Label>
            <Value>
              {this.discountPc > 0 && this.formatValue(this.discount)}
            </Value>
          </Container>
        )}
        {displayTaxes &&
          this.totalTaxAmounts.map(({ taxName, taxRate, taxAmount }) => (
            <Container key={getTotalsTaxKey(taxName, taxRate)}>
              <Label>{`${taxName} ${taxRate}%`}</Label>
              <Value>{this.formatValue(taxAmount)}</Value>
              {commercialDocumentType ===
                COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && <SellContainer />}
            </Container>
          ))}
        <Flex>
          <Container className="grandTotal">
            <Label>
              <LanguageOption optionKey="total" /> ({documentCurrency.id})
            </Label>
            <Value>{this.formatValue(this.grandTotal)}</Value>
          </Container>
          {commercialDocumentType ===
            COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && <SellContainer />}
        </Flex>
        {commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.INVOICE &&
          this.invoiceCurrencyAmountPaidIncTax > 0 && (
            <>
              <Container>
                <Label>
                  <LanguageOption optionKey="lessPaid" /> ({documentCurrency.id}
                  )
                </Label>
                <Value>
                  {this.formatValue(this.invoiceCurrencyAmountPaidIncTax)}
                </Value>
                {commercialDocumentType ===
                  COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && <SellContainer />}
              </Container>
              <Flex>
                <Container className="grandTotal">
                  <Label>
                    <LanguageOption optionKey="amountDue" /> (
                    {documentCurrency.id})
                  </Label>
                  <Value>{this.formatValue(this.balanceIncTax)}</Value>
                  {commercialDocumentType ===
                    COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && (
                    <SellContainer />
                  )}
                </Container>
              </Flex>
            </>
          )}
      </Wrapper>
    );
  }
}

export default consumeCommercialDocument(mapContext)(DocumentTotals);

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled(Flex)`
  padding: 3px 5px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  color: black;
`;

const Value = styled(Flex)`
  width: 240px;
  padding: 3px 10px 0;
  font-size: 12px;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  color: black;
  font-weight: bold;
  overflow: hidden;
`;

const Container = styled(Flex)`
  width: 100%;
  height: 32px;
  justify-content: flex-end;
  align-items: center;
  margin: 2px 0;
  &.grandTotal {
    background-color: black;
    ${Label} {
      color: white;
    }
    ${Value} {
      color: white;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &.hiddenField {
    ${Label} {
      color: var(--color-gray-dark);
    }
    ${Value} {
      color: var(--color-gray-dark);
    }
  }
`;

const SellContainer = styled(Flex)`
  width: 240px;
  flex-shrink: 0;
  justify-content: space-between;
`;

const SellValue = styled(Value)`
  width: 160px;
  color: var(--color-gray-dark);
`;

const MarkupValue = styled(Value)`
  width: 70px;
  color: var(--color-gray-dark);
  cursor: pointer;
  span {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
`;
