import type { TSearchSection } from "@streamtimefe/utils";
import { asString, SearchItem } from "@streamtimefe/utils";

import type { TCurrency } from "./Currency";

export function getSearchItem(currency: TCurrency) {
  return SearchItem.create(
    currency.id,
    currency.id,
    getSearchString(currency),
    currency
  );
}

export function getSearchString(currency: TCurrency) {
  return [asString(currency.id), asString(currency.name)].join(" ");
}

export function getSearchItems(currencies: TCurrency[]) {
  return SearchItem.createSortedArray(currencies, getSearchItem);
}

export function getSectionSearchItems(
  currencies: TCurrency[]
): TSearchSection<TCurrency>[] {
  return [
    {
      name: "Commonly Used",
      items: SearchItem.createSortedArray(
        currencies.filter((currency) => currency.important),
        getSearchItem
      ),
    },
    {
      name: "Others",
      items: SearchItem.createSortedArray(
        currencies.filter((currency) => !currency.important),
        getSearchItem
      ),
    },
  ].filter((section) => section.items.length > 0);
}
