import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectJobItemRoleUi } from "./selectJobItemRoleUi";

export const selectEditedJobItemRoles = createCachedSelector(
  [selectJobItemRoleUi],
  (jobItemRoleUi) => get(jobItemRoleUi, `editedJobItemRoles`, [])
)(selectPropsJobItemId);

export function useEditedJobItemRoles(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectEditedJobItemRoles(state, { jobItemId })
  );
}
