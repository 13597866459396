import { isEqual, setWith, unset } from "lodash-es";

export default function createEntityVariationReducer(
  state,
  changedEntities,
  variationGetter
) {
  let hasChanged = false;

  const nextState = {
    ...state
  };

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    const prevPath = prevEntity && `[${prevEntity.id}]`;
    const prevValue = prevEntity && variationGetter(prevEntity);

    const newPath = newEntity && `[${newEntity.id}]`;
    const newValue = newEntity && variationGetter(newEntity);

    if (prevPath && prevValue && prevPath !== newPath) {
      unset(nextState, prevPath);
      hasChanged = true;
    }

    if (
      newPath &&
      newValue &&
      (!isEqual(prevPath, newPath) || !isEqual(prevValue, newValue))
    ) {
      setWith(nextState, newPath, newValue, Object);
      hasChanged = true;
    }
  });

  if (!hasChanged) return state;

  return nextState;
}
