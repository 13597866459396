import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { ActivityEntryStore } from "./ActivityEntry.store";

export * from "./selectors";

export const useActivityEntryEntity = createUseEntityStoreEntity(
  EntityClass.ActivityEntry
);

export const getActivityEntryEntity = createGetEntityStoreEntity(
  EntityClass.ActivityEntry
);

export const useActivityEntry = createUseSingleEntity(ActivityEntryStore);

export function useActivityEntryStore() {
  return ActivityEntryStore.useStore();
}

export function useActivityEntryActions() {
  return ActivityEntryStore.useActions();
}
