import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

export const Circle = styled(SegmentPart).attrs(
  mapPropsToClassName(["conflict", "selected", "float"])
)`
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-width: 2px;
  border-radius: 50%;
  background: white;
  z-index: 1;
`;

export const CircleLeft = styled(Circle)`
  right: -5px;
`;

export const CircleRight = styled(Circle)`
  left: -5px;
`;

export const CircleLeftSnake = styled(Circle)`
  left: 15px;
`;

export const CircleRightSnake = styled(Circle)`
  right: 15px;
`;
