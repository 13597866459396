import type { ConstEnum, Dictionary } from "@streamtimefe/types";

import type { Entity, TEntityId } from "../../entities/Entity";

export const EntityCrudState = {
  Loading: 1,
  Deleting: 2,
  Saving: 3,
  Creating: 4,
} as const;

export type TEntityCrudState = ConstEnum<typeof EntityCrudState>;

export type CrudActionReturn<T extends Entity> =
  | { entity: T }
  | { success: true }
  | { error: unknown }
  | null;

export type CrudEntityStoreState<T extends Entity> = {
  editedEntitiesById: Dictionary<TEntityId, T>;
  entitesCrudState: Dictionary<TEntityId, TEntityCrudState>;
  helpers: {
    getEntity: (entityId: TEntityId) => T | undefined;
    getEntities: () => Dictionary<TEntityId, T>;
  };
  actions: {
    editEntity: (entity: T) => void;
    clearEditedEntity: (entityId: TEntityId) => void;
    loadEntity: (
      entityId: TEntityId,
      silent?: boolean
    ) => Promise<CrudActionReturn<T>>;
    deleteEntity: (
      entityId: TEntityId,
      silent?: boolean
    ) => Promise<CrudActionReturn<T>>;
    saveEntity: (
      entityOrEntityId: TEntityId | T,
      silent?: boolean
    ) => Promise<CrudActionReturn<T>>;
  };
};
