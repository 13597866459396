import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const JobsIcon = props => (
  <SvgIcon {...props} viewBox="0 0 14 15">
    <path d="M0.538086 14.2451V0.245117H9.04039L13.4612 4.62281V14.2451H0.538086ZM7.89347 5.70512V1.98435H2.28809V12.5059H11.7112V5.70512H7.89347Z" />
  </SvgIcon>
);

export default StyledIcon(JobsIcon);
