import { EntityDescriptorEnum } from "@streamtimefe/entities";
import type { MouseEventHandler } from "react";
import { useMemo, useRef, useState } from "react";
import {
  Icon,
  ListItemIcon,
  ListItemText,
  MdSchedule,
  MenuItem,
  PopoverMenu,
  StFunction,
} from "st-shared/components";
import type { TReportingEntityDescriptor } from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";
import { useDebounceValue } from "st-shared/hooks/useDebounceValue";
import { usePermissions } from "st-shared/stores";
import { pointerEventsNone } from "st-shared/theme";
import { EntityDescriptorProperties } from "st-shared/types";

import { useReportingSavedSegmentColumns } from "../../state/stores/savedSegmentSelectors";
import { menuListCss } from "../styles.css";
import {
  addColumnPaperCss,
  addSubMenuColumnPaperCss,
} from "./AddColumnMenu.css";
import type { EditDataColumnProps } from "./EditDataColumn";
import { EditDataColumn } from "./EditDataColumn";
import type { EditFormulaColumnProps } from "./EditFormulaColumn";
import { EditFormulaColumn } from "./EditFormulaColumn";

interface Props {
  listAnchorEl: null | Element;
  anchorEl: Element;
  onClose: (...args: any) => void;
}

export function AddColumnMenu({ listAnchorEl, anchorEl, onClose }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const columns = useReportingSavedSegmentColumns();
  const dataColumns = CReportingSavedSegment.getDataColumns(columns);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<Element | null>(null);
  const debounceSubMenuAnchorEl = useDebounceValue(subMenuAnchorEl);

  const [dataColumnInit, setDataColumnInit] = useState<
    EditDataColumnProps["init"] | null
  >(null);

  const [formulaColumnInit, setFormulaColumnInit] = useState<
    EditFormulaColumnProps["init"] | null
  >(null);

  function closeAddMenu() {
    onClose();
    closeSubMenu();
  }

  function openSubMenu() {
    setSubMenuAnchorEl(ref.current);
  }

  function closeSubMenu() {
    setSubMenuAnchorEl(null);
  }

  function closeEditDataColumn() {
    setDataColumnInit(null);
  }

  function closeEditFormulaColumn() {
    setFormulaColumnInit(null);
  }

  function onAddDataColumn(entityDescriptorId: TReportingEntityDescriptor) {
    closeAddMenu();
    setDataColumnInit({ type: "add", entityDescriptorId });
  }

  function onAddFormulaColumn() {
    closeAddMenu();
    setFormulaColumnInit({ type: "add" });
  }

  return (
    <>
      <PopoverMenu
        anchorEl={listAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closeAddMenu}
        paperRootClassName={addColumnPaperCss}
      >
        <div ref={ref} />
        <MenuItem
          className={menuListCss.menuItem}
          hasSubMenu
          isSubMenuOpen={Boolean(debounceSubMenuAnchorEl)}
          subMenuIconSize={20}
          onMouseEnter={openSubMenu}
        >
          <ListItemIcon className={menuListCss.listItemIcon}>
            <Icon
              icon={MdSchedule}
              size={14}
              className={menuListCss.listItemIcon_icon}
            />
          </ListItemIcon>
          <ListItemText className={menuListCss.listItemText}>Time</ListItemText>
        </MenuItem>
        <EntityDescriptorMenuItem
          entityDescriptorId={EntityDescriptorEnum.LoggedExpense}
          onAddDataColumn={onAddDataColumn}
          onMouseEnter={closeSubMenu}
        />
        <EntityDescriptorMenuItem
          entityDescriptorId={EntityDescriptorEnum.Quote}
          onAddDataColumn={onAddDataColumn}
          onMouseEnter={closeSubMenu}
        />
        <EntityDescriptorMenuItem
          entityDescriptorId={EntityDescriptorEnum.Invoice}
          onAddDataColumn={onAddDataColumn}
          onMouseEnter={closeSubMenu}
        />
        <EntityDescriptorMenuItem
          entityDescriptorId={EntityDescriptorEnum.Job}
          onAddDataColumn={onAddDataColumn}
          onMouseEnter={closeSubMenu}
        />
        <EntityDescriptorMenuItem
          entityDescriptorId={EntityDescriptorEnum.User}
          onAddDataColumn={onAddDataColumn}
          onMouseEnter={closeSubMenu}
        />
        {dataColumns.length > 0 && (
          <MenuItem
            className={menuListCss.menuItem}
            onMouseEnter={closeSubMenu}
            onClick={onAddFormulaColumn}
          >
            <ListItemIcon className={menuListCss.listItemIcon}>
              <Icon
                icon={StFunction}
                size={14}
                className={menuListCss.listItemIcon_icon}
              />
            </ListItemIcon>
            <ListItemText className={menuListCss.listItemText}>
              Formula Column
            </ListItemText>
          </MenuItem>
        )}
      </PopoverMenu>
      {listAnchorEl && (
        <PopoverMenu
          anchorEl={debounceSubMenuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: -4,
          }}
          onClose={closeSubMenu}
          paperRootClassName={addSubMenuColumnPaperCss}
          className={pointerEventsNone}
        >
          <EntityDescriptorMenuItem
            entityDescriptorId={EntityDescriptorEnum.LoggedTime}
            onAddDataColumn={onAddDataColumn}
          />
          <EntityDescriptorMenuItem
            entityDescriptorId={EntityDescriptorEnum.JobItemUserRolePlannedTime}
            onAddDataColumn={onAddDataColumn}
          />
          <EntityDescriptorMenuItem
            entityDescriptorId={
              EntityDescriptorEnum.JobItemUserRoleUnscheduledTime
            }
            onAddDataColumn={onAddDataColumn}
          />
        </PopoverMenu>
      )}
      {dataColumnInit && (
        <EditDataColumn
          anchorEl={anchorEl}
          onClose={closeEditDataColumn}
          init={dataColumnInit}
        />
      )}
      {formulaColumnInit && (
        <EditFormulaColumn
          anchorEl={anchorEl}
          onClose={closeEditFormulaColumn}
          init={formulaColumnInit}
        />
      )}
    </>
  );
}

type EntityDescriptorMenuItemProps = {
  entityDescriptorId: TReportingEntityDescriptor;
  onMouseEnter?: MouseEventHandler;
  onAddDataColumn: (entityDescriptorId: TReportingEntityDescriptor) => void;
};

function EntityDescriptorMenuItem({
  entityDescriptorId,
  onMouseEnter,
  onAddDataColumn,
}: EntityDescriptorMenuItemProps) {
  const permissions = usePermissions();

  const allowedColumnIds = useMemo(() => {
    return CReportingSavedSegment.getAllowedColumnIds(
      entityDescriptorId,
      permissions
    );
  }, [permissions, entityDescriptorId]);

  if (allowedColumnIds.length === 0) {
    return null;
  }

  return (
    <MenuItem
      className={menuListCss.menuItem}
      onMouseEnter={onMouseEnter}
      onClick={() => onAddDataColumn(entityDescriptorId)}
    >
      <ListItemIcon className={menuListCss.listItemIcon}>
        <Icon
          icon={EntityDescriptorProperties[entityDescriptorId].icon}
          size={14}
          className={menuListCss.listItemIcon_icon}
        />
      </ListItemIcon>
      <ListItemText className={menuListCss.listItemText}>
        {EntityDescriptorProperties[entityDescriptorId].name}
      </ListItemText>
    </MenuItem>
  );
}
