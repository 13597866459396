import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import { reactNodesType } from "../../../lib/types/reactTypes";

function AnimateWrapper({ initial, children, type }) {
  return (
    <motion.div
      initial={initial ? "hidden" : false}
      animate="show"
      exit="hidden"
      variants={VARIANTS[type]}
    >
      {children}
    </motion.div>
  );
}

AnimateWrapper.propTypes = {
  children: reactNodesType.isRequired,
  initial: PropTypes.bool,
  type: PropTypes.oneOf(["fade", "fadeHeight"]).isRequired,
};

AnimateWrapper.defaultProps = {
  initial: true,
};

export default AnimateWrapper;

const VARIANTS = {
  fade: {
    show: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  },
  fadeHeight: {
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  },
};
