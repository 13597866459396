import React from "react";
import { entityIdType } from "../../../lib/types/entityTypes";
import { usePreviousIssuedInvoiceSummary } from "../../../redux/selectors/invoices/ui/previousIssuedInvoiceSummary";
import { useCommercialDocument } from "../../modules/CommercialDocuments/CommercialDocumentContext";
import InvoiceSummaryRow from "./InvoiceSummaryRow";
import { PaymentSummaryContainer } from "./styles";

const InvoicePreviousInvoiceSummary = ({ invoiceId }) => {
  const previousInvoiceSummary = usePreviousIssuedInvoiceSummary(invoiceId);

  const { document, documentMethods } = useCommercialDocument();
  const { displayPreviousInvoiceSummary } = documentMethods.getDisplayOptions(
    document
  );

  if (!displayPreviousInvoiceSummary || previousInvoiceSummary.length === 0)
    return null;

  return (
    <PaymentSummaryContainer>
      <thead>
        <tr>
          <th colSpan="4">Previous Invoices</th>
        </tr>
      </thead>
      <tbody>
        {previousInvoiceSummary.map(summary => (
          <InvoiceSummaryRow key={summary.number} summary={summary} />
        ))}
      </tbody>
    </PaymentSummaryContainer>
  );
};

InvoicePreviousInvoiceSummary.propTypes = {
  invoiceId: entityIdType.isRequired
};

export default InvoicePreviousInvoiceSummary;
