import { get } from "lodash-es";
import { createSelector } from "reselect";
import { ENTITY_NAME_CURRENCIES } from "../../lib/constants";
import { selectPropsCurrencyId } from "./index";

export const selectCurrencies = state => state.entities[ENTITY_NAME_CURRENCIES];

export const selectCurrencyById = createSelector(
  selectCurrencies,
  selectPropsCurrencyId,
  (jobs, id) => get(jobs, `byId.${id}`)
);
