import type { TValueFormatEnum } from "@streamtimefe/entities";
import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { Icon, MdChevronRight } from "st-shared/components";
import type { ReportingDisplayRule } from "st-shared/entities";
import { usePrevious } from "st-shared/hooks";

import { displayRulesMenuButtonCss } from "./DisplayRulesButton.css";
import { DisplayRulesMenu } from "./DisplayRulesMenu";

type Props = {
  containerRef: RefObject<Element>;
  displayRules?: ReportingDisplayRule[];
  defaultDisplayRules: ReportingDisplayRule[];
  setDisplayRules: (displayRules?: ReportingDisplayRule[]) => void;
  columnId?: string;
  format: TValueFormatEnum;
};

export function DisplayRulesButton({
  containerRef,
  displayRules,
  defaultDisplayRules,
  setDisplayRules,
  columnId,
  format,
}: Props) {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  function openMenu() {
    setAnchorElement(containerRef.current);
  }

  function closeMenu() {
    setAnchorElement(null);
  }

  const previousFormat = usePrevious(format);

  useEffect(() => {
    if (previousFormat && format !== previousFormat) {
      setDisplayRules(undefined);
    }
  }, [format, previousFormat, setDisplayRules]);

  return (
    <>
      <div className={displayRulesMenuButtonCss} onClick={openMenu}>
        <span>Display rules</span>
        <Icon icon={MdChevronRight} size={24} />
      </div>
      {anchorElement && (
        <DisplayRulesMenu
          anchorElement={anchorElement}
          close={closeMenu}
          displayRules={displayRules}
          defaultDisplayRules={defaultDisplayRules}
          setDisplayRules={setDisplayRules}
          columnId={columnId}
          format={format}
        />
      )}
    </>
  );
}
