import { EntityClass, User } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";

import { createStore } from "../../../core";
import { EntityStore } from "../../EntityStore";

export type TUserIndexStore = {
  userNameIndex: User.calculate.TUserNameIndex;
};

export const useUserIndexStore = createStore<TUserIndexStore>(() => ({
  userNameIndex: {},
}));

EntityStore.store.subscribe(
  (store) => store[EntityClass.User].byId,
  (users) => {
    const userNameIndex = User.calculate.createUserNameIndex(
      dictionaryToArray(users)
    );

    useUserIndexStore.setState({ userNameIndex });
  }
);
