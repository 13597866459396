import {
  closestCenter,
  DndContext,
  DragEndEvent,
  Modifiers,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Fragment } from "react";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsOrdered,
} from "../../state/stores/savedSegmentSelectors";
import { Column } from "./Column";
import * as styles from "./SortableColumnList.css";
import { ColumnVariant } from "./types";

type Props = {
  variant: ColumnVariant;
};

export function SortableColumnList({ variant }: Props) {
  const columns = useReportingSavedSegmentColumns();
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(
    variant === "table"
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeColumn = columns[active.id];
      const overColumn = columns[over.id];

      const orderId =
        overColumn.orderId +
        (overColumn.orderId > activeColumn.orderId ? 0.5 : -0.5);

      reportingStore().savedSegment.actions.setColumnOrderId(
        activeColumn.id,
        orderId
      );
    }
  }

  function getModifers() {
    const modifiers: Modifiers = [restrictToParentElement];

    switch (variant) {
      case "table":
        modifiers.push(restrictToHorizontalAxis);
        break;
      case "sidebar":
        modifiers.push(restrictToVerticalAxis);
        break;
    }

    return modifiers;
  }

  function getSortingStrategy() {
    switch (variant) {
      case "table":
        return horizontalListSortingStrategy;
      case "sidebar":
        return verticalListSortingStrategy;
    }
  }

  return (
    <div className={styles.listContainer[variant]}>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={getModifers()}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={orderedColumns.map((column) => column.id)}
          strategy={getSortingStrategy()}
          disabled={orderedColumns.length <= 1}
        >
          {orderedColumns.map((column) => (
            <Fragment key={column.id}>
              <Column variant={variant} column={column} />
              {variant === "table" && <div className={styles.tableDivider} />}
            </Fragment>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
