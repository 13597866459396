import { usePermissions } from "st-shared/stores";

import { useCalculatedUsedPlannedDollarsDisplay } from "../../../redux/selectors/jobDetails/calculatedUsedPlannedDollarsDisplay";
import { useJumbotronUsedPlannedTimeHoursDisplay } from "../../../redux/selectors/jobDetails/jumbotronUsedPlannedTimeHoursDisplay";
import { useJobId } from "../context/JobIdContext";
import {
  usedPlannedDollarsCss,
  usedPlannedHoursCss,
  usedPlannedMetricsContainerCss,
} from "./UsedPlannedMetrics.css";

export function UsedPlannedMetrics() {
  const jobId = useJobId();

  const { canViewJobFinancials } = usePermissions();

  const usedPlannedTimeHoursDisplay =
    useJumbotronUsedPlannedTimeHoursDisplay(jobId);
  const usedPlannedDollarsDisplay =
    useCalculatedUsedPlannedDollarsDisplay(jobId);

  const displaySummaryUsedPlannedHours =
    usedPlannedTimeHoursDisplay.loggedMinutes > 0 ||
    usedPlannedTimeHoursDisplay.plannedMinutes > 0;

  const displaySummaryUsedPlannedDollars =
    usedPlannedDollarsDisplay.jobCurrencyTotalLoggedExTax > 0 ||
    usedPlannedDollarsDisplay.jobCurrencyTotalPlannedExTax > 0;

  return (
    <div className={usedPlannedMetricsContainerCss}>
      {displaySummaryUsedPlannedHours && (
        <div className={usedPlannedHoursCss}>
          <span>{usedPlannedTimeHoursDisplay.loggedMinutesDisplay}</span>

          {usedPlannedTimeHoursDisplay.plannedMinutes > 0 && (
            <span> / {usedPlannedTimeHoursDisplay.plannedMinutesDisplay}</span>
          )}
        </div>
      )}

      {canViewJobFinancials && displaySummaryUsedPlannedDollars && (
        <div className={usedPlannedDollarsCss}>
          {usedPlannedDollarsDisplay.numerator}
          {usedPlannedDollarsDisplay.jobCurrencyTotalPlannedExTax > 0 && (
            <span> / {usedPlannedDollarsDisplay.denominator}</span>
          )}
        </div>
      )}
    </div>
  );
}
