import { useSelector } from "react-redux";

import { JobItemRole } from "st-shared/entities/JobItemRole";
import { selectJobItemIdsByJobId } from "../../../../redux/selectors/jobItemSelectors";
import { AppState } from "../../../AppState";
import { selectJobItemRolesByJobItemId } from "./selectJobItemRolesByJobItemId";

export function selectJobItemRolesByJobId(
  state: AppState,
  { jobId }: { jobId: number }
) {
  const jobItemIds: number[] = selectJobItemIdsByJobId(state, { jobId });
  if (!jobItemIds) return [];
  const jobItemRoles: JobItemRole[] = [];
  jobItemIds.forEach((jobItemId) => {
    const roles = selectJobItemRolesByJobItemId(state, { jobItemId });
    if (roles) {
      jobItemRoles.push(...roles);
    }
  });
  return jobItemRoles;
}

export function useJobItemRolesByJobId(jobId: number) {
  return useSelector((state: AppState) =>
    selectJobItemRolesByJobId(state, { jobId })
  );
}
