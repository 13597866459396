import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOrganisation } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_SET_JOB_NUMBER,
} from "../../../../lib/constants";
import { FOCUS_KEYS } from "../../../../lib/constants/jobDetails";
import {
  getJobCompanyLastJobNumber,
  getJobNumber,
} from "../../../../lib/entities/jobEntity";
import {
  getIsCustomJobNumberingMethod,
  getLastJobNumber,
} from "../../../../lib/entities/organisationEntity";
import { incrementAlphabeticNumber } from "../../../../lib/math";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobDetailsIsJobNumberDuplicated } from "../../../../redux/selectors/jobDetails/ui/isJobNumberDuplicated";
import { InlineTextButton } from "../../../elements/Button";
import AnimateWrapper from "../../components/AnimateWrapper";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";
import { useFocusStateRef } from "../../hooks/useFocusStateInputRef";
import { SettingsInput } from "../styles";

const JobNumber = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const isJobNumberDuplicated = useJobDetailsIsJobNumberDuplicated();
  const lastUsedCompanyJobNumber = getJobCompanyLastJobNumber(job);
  const organisation = useOrganisation();
  const lastUsedOrganisationJobNumber = getLastJobNumber(organisation);
  const isCustomJobNumberingMethod =
    getIsCustomJobNumberingMethod(organisation);
  const dispatch = useDispatch();

  const [ref, focused, setFocused] = useFocusStateRef(
    {
      jobId,
      key: FOCUS_KEYS.JOB_JOB_NUMBER,
    }
    // [".MuiInputBase-input"]
  );

  const handleChange = (e) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_SET_JOB_NUMBER, {
        jobId,
        value: String(e.target.value),
      })
    );
  };

  const setNextJobNumber = (value) => {
    dispatch(
      createAction(JOB_SET_JOB_NUMBER, {
        jobId,
        value: incrementAlphabeticNumber(value),
      })
    );
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobId,
          key: FOCUS_KEYS.JOB_JOB_NUMBER,
        },
      })
    );
  };

  const handleBlur = (e) => {
    setFocused(false);
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  const [hovered, setHovered] = useState(false);

  return (
    <JumbotronSettingsField title="JOB NUMBER">
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <SettingsInput
          ref={ref}
          value={getJobNumber(job) || ""}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={!isJobEditable || !isCustomJobNumberingMethod}
          placeholder={isJobEditable ? "Add a job number" : ""}
        />
        <AnimatePresence>
          {isJobEditable &&
            isCustomJobNumberingMethod &&
            (focused || hovered) && (
              <AnimateWrapper type="fadeHeight">
                {!isJobNumberDuplicated &&
                  (lastUsedCompanyJobNumber ||
                    lastUsedOrganisationJobNumber) && (
                    <LastUsedText>
                      <span>Last used </span>
                      {lastUsedCompanyJobNumber && (
                        <>
                          <span>
                            for this company:{" "}
                            <span className="number">
                              {lastUsedCompanyJobNumber}
                            </span>
                            <StyledInlineTextButton
                              onClick={() =>
                                setNextJobNumber(lastUsedCompanyJobNumber)
                              }
                            >
                              Use next
                            </StyledInlineTextButton>
                          </span>
                          <br />
                        </>
                      )}
                      {lastUsedOrganisationJobNumber && (
                        <span>
                          Overall:{" "}
                          <span className="number">
                            {lastUsedOrganisationJobNumber}
                          </span>
                          <StyledInlineTextButton
                            onClick={() =>
                              setNextJobNumber(lastUsedOrganisationJobNumber)
                            }
                          >
                            Use next
                          </StyledInlineTextButton>
                        </span>
                      )}
                    </LastUsedText>
                  )}
                {isJobNumberDuplicated && (
                  <LastUsedText>
                    Job number “<span>{getJobNumber(job)}</span>” has already
                    been used.
                    <br />
                    If you save, your jobs will share a number.
                  </LastUsedText>
                )}
              </AnimateWrapper>
            )}
        </AnimatePresence>
      </div>
    </JumbotronSettingsField>
  );
};

export default JobNumber;

const LastUsedText = styled.div`
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-dark);
  padding-top: 5px;
  user-select: text;

  .number {
    font-weight: var(--font-weight-normal);
  }
`;

const StyledInlineTextButton = styled(InlineTextButton)`
  position: relative;
  top: -1px;
`;
