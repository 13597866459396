import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import { JOB_SAVE, JOB_SET_CONTACT } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectContact } from "../../selectors/contact";
import { selectJob } from "../../selectors/job";

function* setContact(action) {
  try {
    const { jobId, contactId } = action.payload;
    let { contact } = action.payload;

    const job = yield select(selectJob, { jobId });

    if (!contact) {
      if (contactId) contact = yield select(selectContact, { contactId });
      else contact = null;
    }

    const newJob = produce(job, draft => {
      draft.contact = contact;
    });

    yield put(
      createAction(JOB_SAVE, {
        jobId,
        job: newJob,
        previousJob: job
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetContact() {
  yield takeLatestBy(
    [JOB_SET_CONTACT],
    setContact,
    action => action.payload.jobId
  );
}
