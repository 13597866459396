import clsx from "clsx";
import { isNil } from "lodash-es";
import { ReactNode } from "react";
import { RTooltip } from "st-shared/components";
import {
  CReportingSavedSegment,
  ReportingColumn,
  ReportingColumnType,
} from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";
import { useCustomerCurrency } from "st-shared/stores";
import { theme } from "st-shared/theme";
import { formatStatisticValue } from "st-shared/types";

import { SeriesDataValue } from "../../lib/VectorMatrixSeriesData";
import {
  useReportingSavedSegmentColumnMetadata,
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsAllowedTimeTable,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";
import {
  useReportingSeriesSortedColumn,
  useReportingSeriesSortedTime,
} from "../../state/stores/searchSelectors";
import {
  timeSeriesTooltipCss,
  timeSeriesTooltipDividerCss,
  timeSeriesTooltipFormulaCss,
  timeSeriesTooltipRowCss,
  timeSeriesTooltipTitleCss,
  timeSeriesTooltipTitleSelectedCss,
} from "./TableCellTooltip.css";

export function TableCellTooltip() {
  const searchType = useReportingSavedSegmentSearchType();

  switch (searchType) {
    case ReportingSearchType.TimeSeries:
      return <TimeSeriesCellTooltip />;
    case ReportingSearchType.ColumnSeries:
      return <ColumnSeriesCellTooltip />;
  }

  return null;
}

function TimeSeriesCellTooltip() {
  const selectedSeriesData = useReportingSeriesSortedTime();
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  const columns = useReportingSavedSegmentColumns();
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  const tableColumns = useReportingSavedSegmentColumnsAllowedTimeTable().filter(
    (column) => column.id !== selectedTimeId
  );

  if (
    tableColumns.length === 0 ||
    !selectedTimeId ||
    !(selectedTimeId in columns) ||
    !(selectedTimeId in columnMetadata)
  )
    return null;

  const selectedColumn = columns[selectedTimeId];
  const selectedColumnMetadata = columnMetadata[selectedTimeId];
  const depandancyColumns =
    selectedColumnMetadata.formula?.getColumnIds() || [];

  const formulaName =
    selectedColumnMetadata.formula?.toJSX(
      (columnId: string) =>
        columns[columnId] ? (
          columns[columnId].name
        ) : (
          <span style={{ color: theme.color.red }}>Deleted Column</span>
        ),
      false
    ) || "";

  return (
    <RTooltip
      id="reporting-table-cell"
      place="left"
      noPortal={true}
      render={(renderProps) => {
        const el = renderProps.activeAnchor;

        if (!el) {
          return null;
        }

        const cellRow = el.getAttribute("data-table-cell-row");
        const cellColumn = el.getAttribute("data-table-cell-column");

        if (!cellRow || !cellColumn) {
          return null;
        }

        const index = Number(cellRow);

        if (index >= selectedSeriesData.length) {
          return null;
        }

        const seriesData = selectedSeriesData[index];

        if (!(cellColumn in seriesData.matrix)) {
          return null;
        }

        const vector = seriesData.matrix[cellColumn];

        if (selectedColumn.type === ReportingColumnType.Formula) {
          return formulaCellTooltip({
            vector: vector,
            columns: tableColumns,
            selectedColumn: selectedColumn,
            formulaName: formulaName,
            depandancyColumns: depandancyColumns,
          });
        }
        return dataCellTooltip({
          vector: vector,
          columns: tableColumns,
          selectedColumn: selectedColumn,
        });
      }}
    />
  );
}

function ColumnSeriesCellTooltip() {
  const selectedSeriesData = useReportingSeriesSortedColumn();
  const columns = useReportingSavedSegmentColumns();
  const columnMetadata = useReportingSavedSegmentColumnMetadata();

  return (
    <RTooltip
      id="reporting-table-cell"
      place="left"
      noPortal={true}
      render={(renderProps) => {
        const el = renderProps.activeAnchor;

        if (!el) {
          return null;
        }

        const cellRow = el.getAttribute("data-table-cell-row");
        const cellColumn = el.getAttribute("data-table-cell-column");

        if (!cellRow || !cellColumn) {
          return null;
        }

        const index = Number(cellRow);

        if (index >= selectedSeriesData.length) {
          return null;
        }

        const seriesData = selectedSeriesData[index];

        if (!(cellColumn in columns)) {
          return null;
        }

        const selectedColumn = columns[cellColumn];

        if (selectedColumn.type !== ReportingColumnType.Formula) {
          return null;
        }

        seriesData.vector;

        const tableColumns = Object.values(columns).filter(
          (column) => column.id !== cellColumn
        );

        const selectedColumnMetadata = columnMetadata[cellColumn];
        const depandancyColumns =
          selectedColumnMetadata.formula?.getColumnIds() || [];

        const formulaName =
          selectedColumnMetadata.formula?.toJSX(
            (columnId: string) =>
              columns[columnId] ? (
                columns[columnId].name
              ) : (
                <span style={{ color: theme.color.red }}>Deleted Column</span>
              ),
            false
          ) || "";

        return formulaCellTooltip({
          vector: seriesData.vector,
          columns: tableColumns,
          selectedColumn: selectedColumn,
          formulaName: formulaName,
          depandancyColumns: depandancyColumns,
        });
      }}
    />
  );
}

type FormulaCellTooltipProps = {
  vector: Record<string, SeriesDataValue>;
  columns: ReportingColumn[];
  selectedColumn: ReportingColumn;
  formulaName: ReactNode;
  depandancyColumns: string[];
};

function formulaCellTooltip({
  vector,
  columns,
  selectedColumn,
  formulaName,
  depandancyColumns,
}: FormulaCellTooltipProps) {
  const filteredColumns = columns.filter((column) =>
    depandancyColumns.includes(column.id)
  );

  if (
    isNil(vector[selectedColumn.id]) ||
    vector[selectedColumn.id] === 0 ||
    filteredColumns.length === 0
  )
    return null;

  return (
    <div className={timeSeriesTooltipCss}>
      <div className={timeSeriesTooltipFormulaCss}>{formulaName}</div>
      <CellTooltipRow
        id={selectedColumn.id}
        name={selectedColumn.name}
        value={vector[selectedColumn.id]}
        isTitle={true}
      />
      <div className={timeSeriesTooltipDividerCss} />
      {filteredColumns.map((column) => (
        <CellTooltipRow
          key={column.id}
          id={column.id}
          name={column.name}
          value={vector[column.id]}
        />
      ))}
    </div>
  );
}

type DataCellTooltipProps = {
  vector: Record<string, SeriesDataValue>;
  columns: ReportingColumn[];
  selectedColumn: ReportingColumn;
};

function dataCellTooltip({
  vector,
  columns,
  selectedColumn,
}: DataCellTooltipProps) {
  const filteredColumns = columns.filter(
    (column) => column.id in vector && !isNil(vector[column.id])
  );
  if (filteredColumns.length === 0) return null;
  return (
    <div className={timeSeriesTooltipCss}>
      <CellTooltipRow
        id={selectedColumn.id}
        name={selectedColumn.name}
        value={vector[selectedColumn.id]}
        isTitle={true}
      />
      <div className={timeSeriesTooltipDividerCss} />
      {filteredColumns.map((column) => (
        <CellTooltipRow
          key={column.id}
          id={column.id}
          name={column.name}
          value={vector[column.id]}
        />
      ))}
    </div>
  );
}

type CellTooltipRowProps = {
  id: string;
  name: ReactNode;
  value: SeriesDataValue;
  isTitle?: boolean;
};

function CellTooltipRow({
  id,
  name,
  value,
  isTitle = false,
}: CellTooltipRowProps) {
  const columns = useReportingSavedSegmentColumns();
  const customerCurrency = useCustomerCurrency();

  return (
    <div className={timeSeriesTooltipRowCss}>
      <span
        className={clsx(
          timeSeriesTooltipTitleCss,
          isTitle && timeSeriesTooltipTitleSelectedCss
        )}
      >
        {name}
      </span>
      <span>
        {!isNil(value)
          ? formatStatisticValue(
              value,
              CReportingSavedSegment.getColumnFormat(columns[id]),
              customerCurrency
            )
          : "—"}
      </span>
    </div>
  );
}
