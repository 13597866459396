import * as PropTypes from "prop-types";
import styled from "styled-components";

import { entityIdListType } from "../../../lib/types/entityTypes";
import Flex from "../../elements/Flex";
import UserIconName from "./UserIconName";

const UserIconList = ({ ids, size, ...props }) => (
  <Flex {...props}>
    {ids.map((id) => (
      <User key={id} id={id} size={size} showDisplayName={false} />
    ))}
  </Flex>
);

UserIconList.propTypes = {
  ids: entityIdListType.isRequired,
  size: PropTypes.number,
};

UserIconList.defaultProps = {
  size: 25,
};

export default UserIconList;

const User = styled(UserIconName)`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin: 2px;
`;
