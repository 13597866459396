import clsx from "clsx";
import { ReactNode } from "react";

import { RAC } from "../../../external";
import { popoverCss } from "./Popover.css";

export interface AriaPopoverProps extends Omit<RAC.PopoverProps, "children"> {
  children: ReactNode;
  renderDialog?: boolean;
}

export function AriaPopover({
  children,
  className,
  renderDialog = true,
  ...props
}: AriaPopoverProps) {
  return (
    <RAC.Popover offset={0} {...props} className={clsx(popoverCss, className)}>
      {renderDialog ? (
        <RAC.Dialog aria-label="ST Popover">{children}</RAC.Dialog>
      ) : (
        children
      )}
    </RAC.Popover>
  );
}
