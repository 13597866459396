import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { timeAllocationMethodObj } from "st-shared/entities";

import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemEntity";
import {
  convertMinutesToHours,
  convertMinutesToTimeHM,
} from "../../../lib/time";
import { selectJobItem } from "./index";
import { selectShowLoggedInformation } from "./selectShowLoggedInformation";

export const getInitialItemHoursInput = (jobItem) =>
  convertMinutesToHours(getTotalPlannedMinutes(jobItem));

export const selectJobItemHoursInput = createCachedSelector(
  [
    selectJobItem,
    selectShowLoggedInformation,
    (state, props) => props.isFocused,
    (state, props) => props.inputValue,
  ],
  (jobItem, showLoggedInformation, isFocused, inputValue) => {
    const totalUsedMinutes = convertMinutesToTimeHM(
      getTotalUsedMinutes(jobItem)
    );
    const totalPlanedMinutes = convertMinutesToTimeHM(
      getTotalPlannedMinutes(jobItem)
    );

    let value = "";
    let placeholder = "";
    let warning = false;

    if (isFocused) {
      value = inputValue;
      if (inputValue.length === 0) {
        placeholder = "0h";
      }
    } else if (
      timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem() ||
      showLoggedInformation
    ) {
      value = `${totalUsedMinutes} / ${totalPlanedMinutes}`;
      warning =
        getTotalPlannedMinutes(jobItem) > 0 &&
        getTotalUsedMinutes(jobItem) > getTotalPlannedMinutes(jobItem);
    } else {
      value = totalPlanedMinutes;
    }

    return [value, placeholder, warning];
  }
)(
  (state, props) => `${props.jobItemId}-${props.isFocused}-${props.inputValue}`
);

export const useJobItemHoursInput = (jobItemId, isFocused, inputValue) =>
  useSelector((state) =>
    selectJobItemHoursInput(state, {
      jobItemId,
      isFocused,
      inputValue,
    })
  );
