import reduceChangedEntities from "../reduceChangedEntities";

const undoDeleteBlockReducer = (state, action) => {
  const { deletedScheduleLoggedTimes } = action.payload;

  const changedEntities = deletedScheduleLoggedTimes.map(newEntity => ({
    newEntity
  }));
  return reduceChangedEntities(state, changedEntities);
};

export default undoDeleteBlockReducer;
