import { orderBy } from "lodash-es";
import { ChartTooltip, useRenderChartTooltip } from "st-shared/components";
import { CReportingSavedSegment, ReportingColumn } from "st-shared/entities";
import { ChartJS, ReactChartJS } from "st-shared/lib";

import {
  ReportinChartOtherColor,
  ReportingBarChartColors,
} from "../../../consts/ReportingChartColors";
import { useValueFormatter } from "../../../hooks/useValueFormatter";
import {
  KeyedVectorSeriesData,
  SeriesDataValue,
} from "../../../lib/VectorMatrixSeriesData.ts";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsAllNoPermission,
  useReportingSavedSegmentColumnsAllowed,
  useReportingSavedSegmentSelectedColumnId,
  useReportingSavedSegmentSortColumn,
} from "../../../state/stores/savedSegmentSelectors";
import {
  useReportingSeriesFetching,
  useReportingSeriesSortedSelectedColumn,
} from "../../../state/stores/searchSelectors";
import { SelectedColumnSelector } from "../../subheader/SelectedColumnSelector.tsx";
import {
  canvasContainerCss,
  chartContainerCss,
  chartHeaderCss,
  chartTooltipCss,
  columnChartContainerCss,
  outerChartCss,
} from "../Chart.css";
import { renderReportingTooltip } from "../components/ReportingTooltip";
import { EmptyChartNoPermissions } from "../EmptyChart.tsx";
import { EmptyColumnChart } from "../EmptyColumnChart.tsx";
import { LoadingChart } from "../LoadingChart";
import {
  ReportingChartData,
  ReportingChartDataset,
} from "../ReportingChartData";

export function ColumnSeriesChart() {
  const isFetching = useReportingSeriesFetching();
  const columns = useReportingSavedSegmentColumnsAllowed();
  const noPermission = useReportingSavedSegmentColumnsAllNoPermission();
  const selectedColumnId = useReportingSavedSegmentSelectedColumnId();

  if (isFetching) {
    return <LoadingChart />;
  }

  if (noPermission) {
    return <EmptyChartNoPermissions />;
  }

  if (columns.length === 0 || !selectedColumnId) {
    return <EmptyColumnChart />;
  }

  return (
    <div className={outerChartCss}>
      <div className={chartHeaderCss}>
        <SelectedColumnSelector />
      </div>
      <Chart />
    </div>
  );
}

function Chart() {
  const columns = useReportingSavedSegmentColumns();
  const selectedColumnId = useReportingSavedSegmentSelectedColumnId()!;
  const sortedData = useReportingSeriesSortedSelectedColumn();
  const sortColumn = useReportingSavedSegmentSortColumn();

  const order =
    sortColumn.id === selectedColumnId
      ? CReportingSavedSegment.switchSortOrder(sortColumn.order)
      : "desc";

  const valueFormatter = useValueFormatter();
  const [tooltipRef, renderChartTooltip] = useRenderChartTooltip(
    renderReportingTooltip<"bar">(valueFormatter),
    {
      forceAlignment: { xAlign: "left" },
    }
  );

  const [doughnutTooltipRef, renderChartTooltipDoughnut] =
    useRenderChartTooltip(renderReportingTooltip<"doughnut">(valueFormatter), {
      forceAlignment: { xAlign: "right" },
    });

  const barChartData = getBarChartData(
    selectedColumnId,
    columns,
    sortedData,
    order
  );
  const doughnutChartData = getDoughnutChartData(
    selectedColumnId,
    columns,
    sortedData,
    order
  );

  if (barChartData.datasets.length === 0) {
    return <EmptyColumnChart />;
  }

  const barChartOptions: ChartJS.ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    indexAxis: "y",
    interaction: {
      intersect: false,
      mode: "y",
    },
    scales: {
      y: {
        stacked: true,
        position: "left",
        grid: {
          display: false,
        },
        ticks: {
          callback: function () {
            return "";
          },
        },
      },
      x: {
        stacked: true,
        ticks: {
          callback: function (val) {
            return valueFormatter(val, barChartData.datasets[0]?.format, true);
          },
          maxTicksLimit: 6,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        external: renderChartTooltip,
      },
      datalabels: {
        display: true,
        anchor: "start",
        align: "start-top",
        padding: {
          top: 10,
          left: -5,
        },
        formatter: function (_value, context) {
          return context!.chart!.data!.labels?.[context.dataIndex];
        },
      },
    },
  };

  const doughnutChartOptions: ChartJS.ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    cutout: "55%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        external: renderChartTooltipDoughnut,
      },
    },
  };

  return (
    <div className={columnChartContainerCss}>
      <div style={{ height: "100%", flex: 1, minWidth: 0 }}>
        <div className={chartContainerCss}>
          <ReactChartJS.Bar
            className={canvasContainerCss}
            options={barChartOptions}
            data={barChartData}
          />
          <ChartTooltip ref={tooltipRef} className={chartTooltipCss} />
        </div>
      </div>
      <div style={{ height: "90%" }}>
        <div className={chartContainerCss}>
          <ReactChartJS.Doughnut
            className={canvasContainerCss}
            options={doughnutChartOptions}
            data={doughnutChartData}
          />
          <ChartTooltip ref={doughnutTooltipRef} className={chartTooltipCss} />
        </div>
      </div>
    </div>
  );
}

function getBarChartData(
  selectedColumnId: string,
  columns: Record<string, ReportingColumn>,
  vectorSeriesData: KeyedVectorSeriesData[],
  order: "asc" | "desc"
): ReportingChartData<"bar"> {
  const labels: string[] = [];
  const values: SeriesDataValue[] = [];
  const datasets: ReportingChartDataset<"bar">[] = [];

  const filteredData = vectorSeriesData
    .filter((seriesData) => {
      const value = seriesData.vector![selectedColumnId];
      return typeof value === "number" && value > 0;
    })
    .map((seriesData) => ({
      title: seriesData.title,
      value: seriesData.vector![selectedColumnId],
    }));

  const orderedData = orderBy(filteredData, "value", order);

  const topSeriesData = orderedData.slice(0, 5);

  topSeriesData.forEach((data) => {
    labels.push(data.title);
    values.push(data.value);
  });

  if (values.length > 0) {
    datasets.push({
      id: selectedColumnId,
      label: columns[selectedColumnId].name,
      format: CReportingSavedSegment.getColumnFormat(columns[selectedColumnId]),
      type: "bar",
      data: values,
      borderColor: ReportingBarChartColors,
      backgroundColor: ReportingBarChartColors,
      barThickness: 15,
    });
  }

  return { labels, datasets };
}

function getDoughnutChartData(
  selectedColumnId: string,
  columns: Record<string, ReportingColumn>,
  vectorSeriesData: KeyedVectorSeriesData[],
  order: "asc" | "desc"
): ReportingChartData<"doughnut"> {
  const labels: string[] = [];
  const values: SeriesDataValue[] = [];
  const colors: string[] = [];
  const datasets: ReportingChartDataset<"doughnut">[] = [];

  const filteredData = vectorSeriesData
    .filter((seriesData) => {
      const value = seriesData.vector![selectedColumnId];
      return typeof value === "number" && value > 0;
    })
    .map((seriesData) => ({
      title: seriesData.title,
      value: seriesData.vector![selectedColumnId],
    }));

  const orderedData = orderBy(filteredData, "value", order);

  const topSeriesData = orderedData.slice(0, 5);

  topSeriesData.forEach((data, index) => {
    labels.push(data.title);
    values.push(data.value);
    colors.push(ReportingBarChartColors[index]);
  });

  const other = orderedData.slice(topSeriesData.length);

  if (other.length > 0) {
    let value = 0;
    other.forEach((data) => {
      value += data.value || 0;
    });

    if (value > 0) {
      labels.push("Other");
      values.push(value);
      colors.push(ReportinChartOtherColor);
    }
  }

  if (values.length > 0) {
    datasets.push({
      id: selectedColumnId,
      label: columns[selectedColumnId].name,
      format: CReportingSavedSegment.getColumnFormat(columns[selectedColumnId]),
      type: "doughnut",
      data: values,
      borderColor: colors,
      backgroundColor: colors,
      hoverBorderColor: colors,
      hoverBackgroundColor: colors,
    });
  }

  return { labels, datasets };
}
