import {
  BRIDGE_DISTANCE,
  JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE,
  JOB_TIMELINE_BAR_MIN_WIDTH
} from "../constants";
import { differenceInCalendarDays } from "../dates";

const getJobItemBarRightOffset = (
  getOffsetXAtDate,
  jobItemStartDate,
  jobItemEndDate,
  dayWidth
) =>
  jobItemStartDate
    ? getOffsetXAtDate(jobItemStartDate) +
      Math.max(
        (differenceInCalendarDays(jobItemEndDate, jobItemStartDate) + 1) *
          dayWidth,
        JOB_TIMELINE_BAR_MIN_WIDTH
      )
    : getOffsetXAtDate(jobItemEndDate) + dayWidth;

const getJobItemBarLeftOffset = (getOffsetXAtDate, jobItemStartDate) =>
  getOffsetXAtDate(jobItemStartDate);

const getBridgeOffset = (getOffsetXAtDate, bridgeDate, bridgeSide, dayWidth) =>
  getOffsetXAtDate(bridgeDate) +
  (bridgeSide === JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE.LEFT
    ? -BRIDGE_DISTANCE
    : dayWidth + BRIDGE_DISTANCE);

export const getCircleLeftCurveSize = (
  getOffsetXAtDate,
  bridgeDate,
  bridgeSide,
  jobItemStartDate,
  dayWidth
) => {
  const left = getBridgeOffset(
    getOffsetXAtDate,
    bridgeDate,
    bridgeSide,
    dayWidth
  );
  const right = getJobItemBarLeftOffset(getOffsetXAtDate, jobItemStartDate);

  return { left, right };
};

export const getCircleRightCurveSize = (
  getOffsetXAtDate,
  bridgeDate,
  bridgeSide,
  jobItemStartDate,
  jobItemEndDate,
  dayWidth
) => {
  const left = getJobItemBarRightOffset(
    getOffsetXAtDate,
    jobItemStartDate,
    jobItemEndDate,
    dayWidth
  );

  const right =
    getBridgeOffset(getOffsetXAtDate, bridgeDate, bridgeSide, dayWidth) + 2;

  return { left, right };
};

export const getCircleLeftSnakeSize = (
  getOffsetXAtDate,
  bridgeDate,
  bridgeSide,
  jobItemStartDate,
  dayWidth
) => {
  const left =
    getJobItemBarLeftOffset(getOffsetXAtDate, jobItemStartDate) -
    BRIDGE_DISTANCE;

  const right =
    getBridgeOffset(getOffsetXAtDate, bridgeDate, bridgeSide, dayWidth) + 2;

  return { left, right };
};

export const getCircleRightSnakeSize = (
  getOffsetXAtDate,
  bridgeDate,
  bridgeSide,
  jobItemStartDate,
  jobItemEndDate,
  dayWidth
) => {
  const left = getBridgeOffset(
    getOffsetXAtDate,
    bridgeDate,
    bridgeSide,
    dayWidth
  );

  const right =
    getJobItemBarRightOffset(
      getOffsetXAtDate,
      jobItemStartDate,
      jobItemEndDate,
      dayWidth
    ) + BRIDGE_DISTANCE;

  return { left, right };
};
