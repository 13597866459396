import { Blockquote } from "@tiptap/extension-blockquote";
import { Bold } from "@tiptap/extension-bold";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Code } from "@tiptap/extension-code";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Document } from "@tiptap/extension-document";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Heading } from "@tiptap/extension-heading";
import { History } from "@tiptap/extension-history";
import { Italic } from "@tiptap/extension-italic";
import { Link } from "@tiptap/extension-link";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Text } from "@tiptap/extension-text";
import { Underline } from "@tiptap/extension-underline";
import type { Extension } from "@tiptap/react";
import { useMemo } from "react";
import { isDefined } from "remeda";

import * as styles from "../components/BaseRichTextEditor.css";
import { SaveOnBlur } from "../extensions/SaveOnBlur";
import { SaveOnEnter } from "../extensions/SaveOnEnter";
import { UserMention } from "../extensions/UserMention";
import type { ExtensionType } from "../types/ExtensionType";
import type { RichTextEditorRef } from "./useRichTextEditorRef";
import type { TRichTextEmitter } from "./useRichTextEmitter";

const simple = [
  Document,
  Paragraph,
  Text,
  History,
  HardBreak,
  Bold,
  Italic,
  Underline,
  ListItem,
  OrderedList,
  BulletList,
  Code,
] as Extension[];

const base = [
  ...simple,
  Link.configure({
    defaultProtocol: "https",
  }),
  Heading.configure({
    levels: [1, 2, 3],
  }),
  Blockquote,
  CodeBlock,
] as Extension[];

const thread = [...simple, UserMention] as Extension[];

type RichTextExtensionsProps = {
  extensionType: ExtensionType;
  richTextEmitter: TRichTextEmitter;
  placeholder?: string;
  saveOnEnter?: (editorRef: RichTextEditorRef) => void;
  saveOnBlur?: (editorRef: RichTextEditorRef) => void;
};

export function useRichTextExtensions({
  extensionType,
  richTextEmitter,
  placeholder,
  saveOnEnter,
  saveOnBlur,
}: RichTextExtensionsProps) {
  return useMemo(() => {
    let extensions: Extension[] = [];

    switch (extensionType) {
      case "base":
        extensions = [...base];
        break;
      case "simple":
        extensions = [...simple];
        break;
      case "thread":
        extensions = [...thread];
        break;
    }

    if (isDefined(placeholder)) {
      extensions.push(
        Placeholder.configure({
          placeholder,
          emptyEditorClass: styles.editorPlaceholder,
        })
      );
    }

    if (isDefined(saveOnEnter)) {
      extensions.push(
        SaveOnEnter.configure({
          richTextEmitter,
        })
      );
    }

    if (isDefined(saveOnBlur)) {
      extensions.push(
        SaveOnBlur.configure({
          richTextEmitter,
        })
      );
    }
    return extensions;
  }, [extensionType, richTextEmitter, placeholder, saveOnEnter, saveOnBlur]);
}
