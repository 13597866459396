import {
  Organisation,
  type TOrganisation,
  type TUser,
  User,
} from "@streamtimefe/entities";
import memoizeOne from "memoize-one";
import { useMemo } from "react";
import {
  fromKeys,
  intersection,
  isObjectType,
  keys,
  merge,
  pipe,
} from "remeda";

import { organisationEntityStore, useOrganisation, userEntityStore } from "..";
import { authenticationStore, useLoggedInUser } from "./authenticationStore";

export type Permissions = ReturnType<typeof getPermissions>;

export function getPermissions(user?: TUser, organisation?: TOrganisation) {
  const userPermissions = isObjectType(user)
    ? User.getPermissions(user)
    : ({} as ReturnType<typeof User.getPermissions>);

  const organisationPermissions = isObjectType(organisation)
    ? Organisation.get.permissions(organisation)
    : ({} as ReturnType<typeof Organisation.get.permissions>);

  const intersectionKeys = intersection(
    keys(userPermissions),
    keys(organisationPermissions)
  );
  const intersectionPermissions = fromKeys(
    intersectionKeys,
    (key) => userPermissions[key] && organisationPermissions[key]
  );

  return pipe(
    userPermissions,
    merge(organisationPermissions),
    merge(intersectionPermissions)
  );
}

export const getPermissionsMemo = memoizeOne(
  (user: TUser, organisation: TOrganisation) =>
    getPermissions(user, organisation)
);

export function getPermissionsSnapshot() {
  const loggedInUserId = authenticationStore().loggedInUserId!;
  const user = userEntityStore().entities[loggedInUserId];
  const organisation = organisationEntityStore().entity;
  return getPermissionsMemo(user, organisation);
}

export function usePermissions() {
  const user = useLoggedInUser();
  const organisation = useOrganisation();

  return useMemo(() => {
    return getPermissions(user, organisation);
  }, [user, organisation]);
}
