import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobItemSubItemStore } from "./JobItemSubItem.store";

export const useJobItemSubItemEntity = createUseEntityStoreEntity(
  EntityClass.JobItemSubItem
);

export const getJobItemSubItemEntity = createGetEntityStoreEntity(
  EntityClass.JobItemSubItem
);

export const useJobItemSubItem = createUseSingleEntity(JobItemSubItemStore);

export function useJobItemSubItemStore() {
  return JobItemSubItemStore.useStore();
}

export function useJobItemSubItemActions() {
  return JobItemSubItemStore.useActions();
}
