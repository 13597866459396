import styled from "styled-components";

import { useJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleRoleBlankCell } from "../ScheduleLayout/ScheduleRoleBlankCell";
import JobTeamMemberRowsConsumer from "../ScheduleScrollProvider/JobTeamMemberRowsConsumer";
import ScheduleJobTeamMemberTodos from "./ScheduleJobTeamMemberTodos";

interface ScheduleJobTeamMembersProps {
  id: number;
  offsetTop: number;
}

function ScheduleJobTeamMembers({
  id,
  offsetTop,
}: ScheduleJobTeamMembersProps) {
  const jobListModel = useJobListModel(id);

  if (
    !jobListModel ||
    (jobListModel.users.length === 0 && jobListModel.roles.length === 0)
  )
    return null;

  return (
    <TeamMembersContainer>
      <JobTeamMemberRowsConsumer
        id={id}
        offsetTop={offsetTop}
        renderUser={(userId) => (
          <ScheduleJobTeamMemberTodos id={userId} jobId={id} showAvailability />
        )}
        renderRole={() => <ScheduleRoleBlankCell />}
      />
    </TeamMembersContainer>
  );
}

export default ScheduleJobTeamMembers;

const TeamMembersContainer = styled.div`
  position: relative;
  width: 100%;
`;
