import { useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_USER_CREATE,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getAvailabilityDates } from "../../../lib/dates";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
} from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsIsCurrentFocus } from "../../../redux/selectors/jobDetails/ui/IsCurrentFocus";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { actionJobItemRoleCreate } from "../../../state/entities/jobItemRole/actions";
import JobItemUserRoleMenu from "../../modules/UserRoleMenus/JobItemUserRoleMenu";
import { useJobItemId } from "../context/JobItemIdContext";
import { useGroupedJobItemUsersByRoleIds } from "../hooks/useGroupedJobItemUsers";
import { JobItemUserGroup } from "./JobItemUserGoup";

const JobItemUsers = () => {
  const jobItemId = useJobItemId();
  const groupedJobItemUsers = useGroupedJobItemUsersByRoleIds(jobItemId);

  const ref = useRef(null);
  const newUserFocused = useJobDetailsIsCurrentFocus({
    jobItemId,
    key: FOCUS_KEYS.ITEM_NEW_USER,
  });

  const jobItem = useJobItem(jobItemId);
  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    getEstimatedStartDate(jobItem),
    getEstimatedEndDate(jobItem)
  );

  const dispatch = useDispatch();

  function pickUser(userId) {
    dispatch(
      createAction(JOB_ITEM_USER_CREATE, {
        jobItemId,
        userId,
        doSetFocus: false,
      })
    );
  }

  function pickRole(roleId) {
    dispatch(actionJobItemRoleCreate(jobItemId, roleId, "jobItem"));
  }

  function close() {
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
  }

  return (
    <>
      <Container $empty={groupedJobItemUsers.length === 0}>
        <div ref={ref} />
        {groupedJobItemUsers.map((info) => (
          <JobItemUserGroup
            key={info.roleId}
            info={info}
            showHeader={groupedJobItemUsers.length > 1}
          />
        ))}
      </Container>
      {ref.current && newUserFocused && (
        <JobItemUserRoleMenu
          anchorEl={ref.current}
          entityId={jobItemId}
          type="jobItem"
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          pickUser={pickUser}
          pickRole={pickRole}
          close={close}
        />
      )}
    </>
  );
};

export default JobItemUsers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:first-child):not(:nth-child(2)) {
    margin-top: 2px;
  }
  ${(props) => props.$empty && "margin-top: 0 !important;"}
`;
