import isError from "lodash-es/isError";
import { takeLatest, call } from "redux-saga/effects";
import { sendAssignInvoiceNumber } from "../../../lib/WebAppAPI/invoice";
import { INVOICE_ASSIGN_NUMBER } from "../../../lib/constants";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* assignNumber(action) {
  try {
    yield call(sendAssignInvoiceNumber);
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchInvoiceAssignNumber() {
  yield takeLatest(INVOICE_ASSIGN_NUMBER, assignNumber);
}
