import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobItemName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobItemCreated = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" created the item "}
    <b>{getMetaDataJobItemName(activityEntry)}</b>
  </SystemMessage>
);

JobItemCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemCreated;
