import * as PropTypes from "prop-types";

export const entityIdType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const entityIdListType = PropTypes.arrayOf(entityIdType);

export const typeEntityType = PropTypes.shape({
  id: entityIdType,
  name: PropTypes.string,
});

export const entityFieldDecimalType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
