import styled from "styled-components";

export const Label = styled.div`
  display: inline-block;
  color: var(--color-charcoal);
  height: 25px;
  line-height: 25px;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  padding: 0 10px;
  margin: 2.5px 0;
  white-space: nowrap;
  vertical-align: top;
  position: relative;
  top: 0;
`;

export const getLabelId = labelId => `ActiveLabel-${labelId}`;
