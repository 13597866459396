import * as PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import styled from "styled-components";

import {
  DATA_ATTRIBUTE_RESIZE_HANDLE,
  DATA_ATTRIBUTE_USER,
  DATA_VALUE_HANDLE_RIGHT,
  DATA_VALUE_HANDLE_TOP,
  DATA_VALUE_HANDLE_TOP_RIGHT,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { isCapturePointerElement } from "../../../lib/eventTargets";
import JobItemBarDragHandlers from "../../modules/JobItem/JobItemBarDragHandlers";
import JobItemBarResizeHandlers from "../../modules/JobItem/JobItemBarResizeHandlers";
import JobItemPaintHandlers from "../../modules/JobItem/JobItemPaintHandlers";
import DependancyContextMenuHandlers from "../../modules/JobItemDependancies/DependancyContextMenuHandlers";
import DependancyHandlers from "../../modules/JobItemDependancies/DependancyHandlers";
import AvailabilityClickHandlers from "./AvailabilityClickHandlers";
import BlockClickHandlers from "./BlockClickHandlers";
import BlockContextMenuHandlers from "./BlockContextMenuHandlers";
import BlockDragHandlers from "./BlockDragHandlers";
import BlockHoverHandlers from "./BlockHoverHandlers";
import BlockPaintHandlers from "./BlockPaintHandlers";
import BlockResizeHandlers from "./BlockResizeHandlers";
import BlockSplitHandlers from "./BlockSplitHandlers";
import JobBarDragHandlers from "./JobBarDragHandlers";
import JobItemRowClickHandlers from "./JobItemRowClickHandlers";
import KeyHandlers from "./KeyHandlers";
import SchedulePermissions from "./SchedulePermissions";
import ScrollContextHandler from "./ScrollContextHandler";
import UtilisationClickHandlers from "./UtilisationClickHandlers";

class ScheduleEventHandlers extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    canEditTeamLoggedTimes: PropTypes.bool.isRequired,
    onPointerDown: PropTypes.func,
    onPointerMove: PropTypes.func,
    onPointerUp: PropTypes.func,
    onLostPointerCapture: PropTypes.func,
    isContextMenuOpen: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    onPointerDown: null,
    onPointerMove: null,
    onPointerUp: null,
    onLostPointerCapture: null,
    isContextMenuOpen: false,
    className: "",
  };

  onPointerDown = (e) => {
    const { isContextMenuOpen, onPointerDown } = this.props;

    if (!isCapturePointerElement(e)) return false;

    if (e.button !== 0) return false;

    if (isContextMenuOpen) return false;

    if (e.target.closest("button")) return false;

    this.ref.current.setPointerCapture(e.pointerId);

    return onPointerDown && onPointerDown(e);
  };

  onPointerMove = (e) => {
    const { onPointerMove } = this.props;

    return onPointerMove && onPointerMove(e);
  };

  onPointerUp = (e) => {
    const { onPointerUp } = this.props;

    this.ref.current.releasePointerCapture(e.pointerId);

    return onPointerUp && onPointerUp(e);
  };

  onLostPointerCapture = (e) => {
    const { onLostPointerCapture } = this.props;

    return onLostPointerCapture && onLostPointerCapture(e);
  };

  get handlers() {
    return {
      onPointerDown: this.onPointerDown,
      onPointerMove: this.onPointerMove,
      onPointerUp: this.onPointerUp,
      onLostPointerCapture: this.onLostPointerCapture,
    };
  }

  render() {
    const { props, ref, handlers } = this;

    const className = `scheduleBody ${props.className}`.trim();

    return <Wrapper {...{ ...props, className, ref, ...handlers }} />;
  }
}

export default compose(
  ScrollContextHandler,
  KeyHandlers,
  SchedulePermissions,
  BlockSplitHandlers,
  BlockClickHandlers,
  BlockContextMenuHandlers,
  BlockPaintHandlers,
  BlockResizeHandlers,
  BlockDragHandlers,
  BlockHoverHandlers,
  AvailabilityClickHandlers,
  UtilisationClickHandlers,
  DependancyContextMenuHandlers,
  DependancyHandlers,
  JobItemRowClickHandlers,
  JobBarDragHandlers,
  JobItemBarDragHandlers,
  JobItemBarResizeHandlers,
  JobItemPaintHandlers
)(ScheduleEventHandlers);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &.dragging {
    cursor: grabbing;
  }
  &.resizing.${DATA_VALUE_HANDLE_TOP} {
    cursor: ns-resize;
  }
  &.resizing.${DATA_VALUE_HANDLE_RIGHT} {
    cursor: ew-resize;
  }
  &.resizing.${DATA_VALUE_HANDLE_TOP_RIGHT} {
    cursor: nesw-resize;
  }
  &.painting {
    cursor: pointer;
  }
  &.dragging,
  &.resizing,
  &.painting {
    .scheduleBlocksContainer,
    .scheduleBlockRect {
      transition: none;
    }
  }
  &.copy.dragging,
  &.copy .scheduleBlock {
    cursor: copy;
  }
  &.splitting * {
    cursor: var(--cursor-split);
  }
  &.creatingDependancy {
    cursor: cell;
  }
  &.invalidDependancy {
    cursor: no-drop;
  }
  ${(props) =>
    !props.canEditTeamLoggedTimes &&
    `
    [${getAttrPropString(DATA_ATTRIBUTE_USER)}] {
      cursor: default;
    } 
    .scheduleBlock {
      cursor: pointer;
    };
    [${getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE)}] {
      display: none;
    };
  `};
`;
