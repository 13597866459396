import { findIndex, keys } from "lodash-es";
import numeral from "numeral";
import { subDays } from "st-shared/lib";

import {
  JOB_DETAILS_FETCH_FAILED,
  JOB_DETAILS_FETCH_REQUEST,
  JOB_DETAILS_FETCH_SUCCEEDED,
  JOB_DETAILS_SET_ACTIVITY_SECTION_TAB,
  JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
  JOB_DETAILS_SET_BURN_UP_MODE,
  JOB_DETAILS_SET_COST_VS_SELL_MODE,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_DETAILS_SET_HISTORICAL_SUMMARY,
  JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED,
  JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB,
  JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY,
  JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY,
  JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN,
  JOB_PAGE_SET_DATE_DRAWER_OPEN,
} from "../../../lib/constants";
import {
  JOB_ACTIVITY_SECTION_TABS,
  JOB_BOTTOM_SECTION_TABS,
  JOB_BURN_UP_MODE,
  JOB_COST_VS_SELL_MODE,
  JOB_JUMBOTRON_SECTION_TABS,
} from "../../../lib/constants/jobDetails";
import {
  getTotalUsedMinutes,
  getTotalUsedMoney,
  getUserId,
} from "../../../lib/entities/jobItemUserEntity";
import { preciseAdd, preciseDecimals } from "../../../lib/math";
import createReducer from "../../helpers/createReducer";

const initialState = {
  jobId: null,
  pending: true,
  error: "",
  currentFocus: null,
  previousFocus: null,
  unplannedTimeSummary: [],
  historicalSummary: {},
  bottomSectionTab: JOB_BOTTOM_SECTION_TABS.JOB_PLAN,
  jumbotronSectionTab: JOB_JUMBOTRON_SECTION_TABS.OVERVIEW,
  activitySectionTab: JOB_ACTIVITY_SECTION_TABS.DATES,
  costVsSellMode: JOB_COST_VS_SELL_MODE.SELL,
  burnUpMode: JOB_BURN_UP_MODE.USED_PLANNED_HOURS,
  burnUpData: calculateBurnUpData(JOB_BURN_UP_MODE.USED_PLANNED_HOURS, {}),
  isJobNumberDuplicated: false,
  isActivityDrawerOpen: false,
  isDatesDrawerOpen: false,
};

const onRequest = (state, { payload: { jobId } }) => ({
  ...state,
  jobId,
  pending: true,
  error: initialState.error,
});

const onSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  pending: false,
  error: initialState.error,
});

const onError = (state, { payload: { error } }) => ({
  ...state,
  pending: true,
  error,
});

const setCurrentFocus = (state, { payload: { currentFocus } }) => ({
  ...state,
  previousFocus: state.currentFocus !== null ? { ...state.currentFocus } : null,
  currentFocus: currentFocus !== null ? { ...currentFocus } : null,
});

const setUnplannedTimeSummary = (
  state,
  { payload: { unplannedTimeSummary } }
) => ({
  ...state,
  unplannedTimeSummary,
});

const setHistoricalSummary = (state, { payload: { historicalSummary } }) => ({
  ...state,
  historicalSummary,
  burnUpData: calculateBurnUpData(state.burnUpMode, historicalSummary),
});

const updateUnplannedTimeSummary = (state, { payload: { jobItemUser } }) => {
  const unplannedTimeSummary = [...state.unplannedTimeSummary];
  const totalMinutes = getTotalUsedMinutes(jobItemUser);
  const jobCurrencyTotalExTax = getTotalUsedMoney(jobItemUser);
  const userId = getUserId(jobItemUser);

  if (!totalMinutes || !userId) return { ...state };

  // Find existing user's unplanned time summary
  const userSummaryIndex = findIndex(
    unplannedTimeSummary,
    (summary) => summary.userId === userId
  );

  // Update existing user's unplanned time summary
  if (userSummaryIndex !== -1) {
    const userSummary = { ...unplannedTimeSummary[userSummaryIndex] };

    userSummary.totalMinutes = numeral(userSummary.totalMinutes)
      .add(totalMinutes)
      .value();
    userSummary.jobCurrencyTotalExTax = preciseAdd(
      userSummary.jobCurrencyTotalExTax,
      jobCurrencyTotalExTax
    );

    unplannedTimeSummary[userSummaryIndex] = userSummary;
  } else {
    // Else create new row for it
    unplannedTimeSummary.push({
      totalMinutes,
      jobCurrencyTotalExTax,
      jobCurrencyTotalCostExTax: 0,
      userId,
    });
  }

  return { ...state, unplannedTimeSummary };
};

const setCostVsSellMode = (state, { payload: costVsSellMode }) => ({
  ...state,
  costVsSellMode,
});

const setBottomSectionTab = (state, { payload: bottomSectionTab }) => ({
  ...state,
  bottomSectionTab,
});

const setJumbotronSectionTab = (state, { payload: jumbotronSectionTab }) => ({
  ...state,
  jumbotronSectionTab,
});

const setActivitySectionTab = (state, { payload: activitySectionTab }) => ({
  ...state,
  activitySectionTab,
});

const setActivityDrawerOpen = (state, { payload: isActivityDrawerOpen }) => ({
  ...state,
  isActivityDrawerOpen,
});

const setDateDrawerOpen = (state, { payload: isDatesDrawerOpen }) => ({
  ...state,
  isDatesDrawerOpen,
});

const setBurnUpMode = (state, { payload: burnUpMode }) => ({
  ...state,
  burnUpMode,
  burnUpData: calculateBurnUpData(burnUpMode, state.historicalSummary),
});

const setIsJobNumberDuplicated = (
  state,
  { payload: isJobNumberDuplicated }
) => ({
  ...state,
  isJobNumberDuplicated,
});

function calculateBurnUpData(burnUpMode, historicalSummary) {
  const labels = [];
  const usedData = [];
  const plannedData = [];
  const optimalData = [];

  let usedDataPointRadius = null;
  let highestUsedDataPoint = 0;

  keys(historicalSummary).forEach((key, index) => {
    const dataPoint = historicalSummary[key];

    let usedDataPoint = null;
    let plannedDataPoint = null;
    let optimalDataPoint = null;

    switch (burnUpMode) {
      case JOB_BURN_UP_MODE.USED_PLANNED_HOURS:
        if (dataPoint.loggedMinutes !== null)
          usedDataPoint = preciseDecimals(dataPoint.loggedMinutes / 60);

        if (dataPoint.incompleteMinutes !== null)
          plannedDataPoint = preciseDecimals(dataPoint.incompleteMinutes / 60);

        if (dataPoint.optimalMinutes !== null)
          optimalDataPoint = preciseDecimals(dataPoint.optimalMinutes / 60);

        break;
      case JOB_BURN_UP_MODE.USED_PLANNED_COST:
        if (dataPoint.loggedJobCurrencyTotalExTax !== null)
          usedDataPoint = dataPoint.loggedJobCurrencyTotalCostExTax;

        if (dataPoint.incompleteJobCurrencyTotalCostExTax !== null)
          plannedDataPoint = dataPoint.incompleteJobCurrencyTotalCostExTax;

        if (dataPoint.optimalMinutes !== null)
          optimalDataPoint = dataPoint.optimalJobCurrencyTotalExTax;

        break;
      case JOB_BURN_UP_MODE.USED_PLANNED_SELL:
        if (dataPoint.loggedJobCurrencyTotalExTax !== null)
          usedDataPoint = dataPoint.loggedJobCurrencyTotalExTax;

        if (dataPoint.incompleteJobCurrencyTotalExTax !== null)
          plannedDataPoint = dataPoint.incompleteJobCurrencyTotalExTax;

        if (dataPoint.optimalMinutes !== null)
          optimalDataPoint = dataPoint.optimalJobCurrencyTotalExTax;

        break;
    }

    if (usedDataPoint !== null && usedDataPointRadius === null) {
      usedDataPointRadius = 5;
    } else {
      usedDataPointRadius = 0;
    }

    if (index === 0) {
      labels.push(subDays(key, 1));
      usedData.push(typeof usedDataPoint === "number" ? 0 : null);
      plannedData.push(typeof plannedDataPoint === "number" ? 0 : null);
      optimalData.push(typeof optimalDataPoint === "number" ? 0 : null);
    }

    labels.push(key);
    usedData.push(usedDataPoint);
    plannedData.push(plannedDataPoint);
    optimalData.push(optimalDataPoint);

    highestUsedDataPoint = Math.max(highestUsedDataPoint, usedDataPoint);
  });

  return {
    labels,
    usedData,
    plannedData,
    optimalData,
    usedDataPointRadius,
    highestUsedDataPoint,
  };
}

export default createReducer(initialState, {
  [JOB_DETAILS_FETCH_REQUEST]: onRequest,
  [JOB_DETAILS_FETCH_SUCCEEDED]: onSuccess,
  [JOB_DETAILS_FETCH_FAILED]: onError,
  [JOB_DETAILS_SET_CURRENT_FOCUS]: setCurrentFocus,
  [JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY]: setUnplannedTimeSummary,
  [JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY]: updateUnplannedTimeSummary,
  [JOB_DETAILS_SET_HISTORICAL_SUMMARY]: setHistoricalSummary,
  [JOB_DETAILS_SET_BOTTOM_SECTION_TAB]: setBottomSectionTab,
  [JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB]: setJumbotronSectionTab,
  [JOB_DETAILS_SET_ACTIVITY_SECTION_TAB]: setActivitySectionTab,
  [JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN]: setActivityDrawerOpen,
  [JOB_PAGE_SET_DATE_DRAWER_OPEN]: setDateDrawerOpen,
  [JOB_DETAILS_SET_COST_VS_SELL_MODE]: setCostVsSellMode,
  [JOB_DETAILS_SET_BURN_UP_MODE]: setBurnUpMode,
  [JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED]: setIsJobNumberDuplicated,
});
