import { filter, includes } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { selectActiveRoleOptions } from "../../../../redux/selectors/roleSelectors";
import {
  selectPropsJobId,
  selectPropsJobItemId,
  selectPropsJobPhaseId,
} from "../../../helpers/selectors";
import { selectRoleIdsByJobItemId } from "../../jobItemRole/selectors/selectRoleIdsByJobItemId";
import { selectRoleIdsInAllJobItemsByJobId } from "../../jobItemRole/selectors/selectRoleIdsInAllJobItemsByJobId";
import { selectRoleIdsInAllJobItemsByPhaseId } from "../../jobItemRole/selectors/selectRoleIdsInAllJobItemsByJobPhaseId";

export const selectJobItemRoleUnselectedOptionsJobItem = createCachedSelector(
  [selectActiveRoleOptions, selectRoleIdsByJobItemId],
  (roleOptions, roleIds) => {
    return filter(
      roleOptions,
      (roleOption) => !includes(roleIds, roleOption.key)
    );
  }
)(selectPropsJobItemId);

export const selectJobItemRoleUnselectedOptionsJobPhase = createCachedSelector(
  [selectActiveRoleOptions, selectRoleIdsInAllJobItemsByPhaseId],
  (roleOptions, roleIds) => {
    return filter(
      roleOptions,
      (roleOption) => !includes(roleIds, roleOption.key)
    );
  }
)(selectPropsJobPhaseId);

export const selectJobItemRoleUnselectedOptionsJob = createCachedSelector(
  [selectActiveRoleOptions, selectRoleIdsInAllJobItemsByJobId],
  (roleOptions, roleIds) => {
    return filter(
      roleOptions,
      (roleOption) => !includes(roleIds, roleOption.key)
    );
  }
)(selectPropsJobId);

export function useJobItemRoleUnselectedOptions(
  entityId: number,
  type: "jobItem" | "jobPhase" | "job"
) {
  return useSelector((state: any) => {
    if (type === "jobItem") {
      return selectJobItemRoleUnselectedOptionsJobItem(state, {
        jobItemId: entityId,
      });
    } else if (type === "jobPhase") {
      return selectJobItemRoleUnselectedOptionsJobPhase(state, {
        jobPhaseId: entityId,
      });
    } else if (type === "job") {
      return selectJobItemRoleUnselectedOptionsJob(state, { jobId: entityId });
    }
    return [];
  });
}
