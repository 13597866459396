// deprecated

import { FrameworkException } from "../lib";
import type { SearchOption } from ".";
import type { CostingMethod } from "./CostingMethod";
import { costingMethodObj, CostingMethods } from "./CostingMethod";
import { LineItemOptionTypes } from "./LineItemOptionType";
import type { MasterItem, MasterItemOption } from "./MasterItem";

export interface MasterJobItem extends MasterItem {
  costingMethod: CostingMethod;
  isBillable: boolean;
}

export interface MasterJobItemOption extends MasterItemOption {
  isBillable: boolean;
}

export function masterJobItemObj(entity: MasterJobItem) {
  const obj = {
    entity,
    getSearchString: function () {
      return entity.name;
    },
    isDeleted: function () {
      return !entity.active;
    },
    getAsOptionNew: function (): SearchOption<MasterJobItem> {
      return {
        entity,
        key: entity.id,
        value: entity.name,
        searchString: obj.getSearchString(),
      };
    },
    // TODO replace this with getAsOptionNew
    getAsOption: function (): MasterJobItemOption {
      return {
        key: entity.id,
        value: entity.name,
        optionType: obj.getOptionType(),
        searchString: obj.getSearchString(),
        isBillable: entity.isBillable,
      };
    },
    getOptionType: function () {
      switch (entity.costingMethod.id) {
        case CostingMethods.Item:
          return LineItemOptionTypes.UnitItem;
        case CostingMethods.People:
          return LineItemOptionTypes.PeopleItem;
        case CostingMethods.ValueCalculatedSell:
        case CostingMethods.ValueUserSell:
          return LineItemOptionTypes.FixedItem;
        default:
          throw new FrameworkException(
            costingMethodObj(entity.costingMethod).getUnknownIdError()
          );
      }
    },
  };
  return obj;
}
