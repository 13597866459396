import type { TUserPreference } from "@streamtimefe/entities";
import { CostingMethods } from "st-shared/entities";

import {
  JOB_BURN_UP_MODE,
  JOB_COST_VS_SELL_MODE,
} from "../../../lib/constants/jobDetails";
import type { KeyEntityState } from "../types";

export type UserPreferencesState = KeyEntityState<TUserPreference>;

export const UserPreferenceKeys = {
  SCHEDULE_FILTER_SHOW_TEAM_MEMBERS: "SCHEDULE_FILTER_SHOW_TEAM_MEMBERS",
  SCHEDULE_TEAM_MEMBER_ORDER: "SCHEDULE_TEAM_MEMBER_ORDER",
  USER_PREFERENCE_COLLAPSED_USERS: "USER_PREFERENCE_COLLAPSED_USERS",
  USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER:
    "USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER",
  USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER:
    "USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER",
  USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER:
    "USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER",
  USER_PREFERENCE_JOB_DATES_ITEMS_FILTER:
    "USER_PREFERENCE_JOB_DATES_ITEMS_FILTER",
  USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER:
    "USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER",
  USER_PREFERENCE_JOB_DATES_INVOICES_FILTER:
    "USER_PREFERENCE_JOB_DATES_INVOICES_FILTER",
  USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD:
    "USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD",
  USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE:
    "USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE",
  USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE:
    "USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE",
  USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS:
    "USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS",
  USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES:
    "USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES",
} as const;

export type TUserPreferenceKeys =
  (typeof UserPreferenceKeys)[keyof typeof UserPreferenceKeys];

export const UserPreferenceDefaults: Record<TUserPreferenceKeys, any> = {
  [UserPreferenceKeys.SCHEDULE_FILTER_SHOW_TEAM_MEMBERS]: "",
  [UserPreferenceKeys.SCHEDULE_TEAM_MEMBER_ORDER]: "",
  [UserPreferenceKeys.USER_PREFERENCE_COLLAPSED_USERS]: "",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER]: true,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER]: true,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER]:
    true,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_ITEMS_FILTER]: true,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER]: true,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_INVOICES_FILTER]: true,
  [UserPreferenceKeys.USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD]:
    CostingMethods.Item,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE]:
    JOB_COST_VS_SELL_MODE.SELL,
  [UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE]:
    JOB_BURN_UP_MODE.USED_PLANNED_HOURS,
  [UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS]: true,
  [UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES]: true,
};

export const UserPreferenceValueTypes: Record<
  TUserPreferenceKeys,
  "string" | "number" | "boolean" | ""
> = {
  [UserPreferenceKeys.SCHEDULE_FILTER_SHOW_TEAM_MEMBERS]: "string",
  [UserPreferenceKeys.SCHEDULE_TEAM_MEMBER_ORDER]: "string",
  [UserPreferenceKeys.USER_PREFERENCE_COLLAPSED_USERS]: "string",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER]:
    "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_ITEMS_FILTER]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_INVOICES_FILTER]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD]: "number",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE]: "number",
  [UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE]: "number",
  [UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_USERS]: "boolean",
  [UserPreferenceKeys.USER_PREFERENCE_TEAM_MEMBER_MODAL_SHOW_ROLES]: "boolean",
};
