import { EntityDescriptorEnum } from "@streamtimefe/entities";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { useRef, useState } from "react";
import {
  Button,
  Icon,
  IconSize,
  MdEventAvailable,
  MdMoneyOff,
  MdMoreVert,
  MdReplay,
} from "st-shared/components";
import type { IconBaseProps } from "st-shared/components/icons";
import {
  StActionDuplicateOutline,
  StActionLinkOutline,
  StFinanceVoid,
  StFolderClosedOutline,
  StStarOutline,
  StTrashOutline,
} from "st-shared/components/icons";
import { isComplete } from "st-shared/entities/JobItemStatus";
import { isArchived } from "st-shared/entities/JobStatus";
import { theme } from "st-shared/theme";

import { getJobFullName } from "../../../../lib/entities/jobEntity";
import { createWebEntityRoute, RoutePages } from "../../../../lib/router";
import { feToWebStarEntity } from "../../../../lib/WebAppAPI/fePages/genericWeb";
import {
  jobPageChangeJobBillableStatus,
  jobPageChangeJobRateCard,
  jobPageOpenArchiveJobModal,
  jobPageOpenDeleteModal,
  jobPageOpenDuplicateJobModal,
  jobPageOpenRestoreJobModal,
} from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import Menu from "../../../modules/Menu";
import { useJobId } from "../../context/JobIdContext";
import {
  buttonCss,
  iconCss,
  itemContentWrapperCss,
  itemNameCss,
  menuItemCss,
  secondaryMenuIconCss,
} from "./SecondaryMenu.css";

type SecondaryMenuItem = {
  id: number;
  icon: (props: IconBaseProps) => JSX.Element;
  onClickHandler: () => void;
  label: string;
  shouldHide?: () => boolean;
};

export function SecondaryMenu() {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const menuRef = useRef(null);

  const jobId = useJobId();
  const job = useJob(jobId);
  const isBillable = job.isBillable as boolean;
  const jobStatus = job.jobStatus ?? "";

  function onActionCompletedDatetime() {
    if (!job.completedDatetime) return;

    getModalBridgeClient()?.openModal(
      ModalTypes.JobUpdateCompletedDatetime,
      {
        jobId,
        completedDatetime: job.completedDatetime,
      },
      {}
    );
  }

  const SecondaryMenuOptionItems: SecondaryMenuItem[] = [
    {
      id: 1,
      icon: StActionLinkOutline,
      onClickHandler: () => jobPageChangeJobRateCard(job),
      label: "Change rate card",
    },
    {
      id: 2,
      icon: StActionDuplicateOutline,
      onClickHandler: () => jobPageOpenDuplicateJobModal(job),
      label: "Duplicate job",
    },
    {
      id: 3,
      icon: MdEventAvailable,
      onClickHandler: onActionCompletedDatetime,
      label: "Edit completion date",
      shouldHide: () => !isArchived(jobStatus) && !isComplete(jobStatus),
    },
    {
      id: 4,
      icon: StFolderClosedOutline,
      onClickHandler: () => jobPageOpenArchiveJobModal(job),
      label: "Archive job",
      shouldHide: () => isArchived(jobStatus),
    },
    {
      id: 5,
      icon: MdReplay,
      onClickHandler: () => jobPageOpenRestoreJobModal(job),
      label: "Restore job",
      shouldHide: () => !isArchived(jobStatus),
    },
    {
      id: 6,
      icon: StStarOutline,
      onClickHandler: () =>
        feToWebStarEntity(
          EntityDescriptorEnum.Job,
          getJobFullName(job),
          createWebEntityRoute(RoutePages.Jobs, jobId)
        ),
      label: "Star",
    },
    {
      id: 7,
      icon: MdMoneyOff,
      onClickHandler: () => jobPageChangeJobBillableStatus(job, !isBillable),
      label: "Mark job as non-billable",
      shouldHide: () => !isBillable,
    },
    {
      id: 8,
      icon: StFinanceVoid,
      onClickHandler: () => jobPageChangeJobBillableStatus(job, !isBillable),
      label: "Mark job as billable",
      shouldHide: () => isBillable,
    },
    {
      id: 9,
      icon: StTrashOutline,
      onClickHandler: () => jobPageOpenDeleteModal(job),
      label: "Delete job",
    },
  ];

  const menuButtonIcon = (
    <Icon
      icon={MdMoreVert}
      size={IconSize.XXLarge}
      className={secondaryMenuIconCss}
    />
  );

  const filteredItems = SecondaryMenuOptionItems.filter(
    (item) => !item.shouldHide || item.shouldHide() === false
  );

  const openJobActionsMenu = () => {
    setMenuAnchorEl(menuRef.current);
  };

  const closeJobActionsMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <button
        className={buttonCss}
        type="button"
        ref={menuRef}
        onClick={openJobActionsMenu}
      >
        {menuButtonIcon}
      </button>

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeJobActionsMenu}
        transformOrigin={{
          vertical: 20,
          horizontal: 225,
        }}
      >
        {filteredItems.map((SecondaryMenuOptionItem) => (
          <div key={SecondaryMenuOptionItem.id} className={menuItemCss}>
            <Button
              variant="inlineLink"
              className={itemContentWrapperCss}
              onClick={() => {
                if (SecondaryMenuOptionItem.onClickHandler) {
                  SecondaryMenuOptionItem.onClickHandler();
                }
                closeJobActionsMenu();
              }}
            >
              <Icon
                icon={SecondaryMenuOptionItem.icon}
                size={24}
                className={iconCss}
                fill={theme.color.graydark}
              />
              <span className={itemNameCss}>
                {SecondaryMenuOptionItem.label}
              </span>
            </Button>
          </div>
        ))}
      </Menu>
    </>
  );
}
