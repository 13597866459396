import type { Client } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import type { TAppType } from "@streamtimefe/environment";
import { buildEnvironments, runtimeEnv } from "@streamtimefe/environment";
import React from "react";

import { bugsnagStartErrorBoundary } from "../components/ErrorBoundary";
import { sessionOrganisation, sessionUser } from "./session";

export let bugsnagClient: Client | undefined;

export function bugsnagStart(appType: TAppType) {
  const apiKey = runtimeEnv()?.VITE_BUGSNAG_API_KEY;
  const appVersion = runtimeEnv()?.VITE_APP_VERSION;
  const buildEnv = runtimeEnv()?.VITE_BUILD_ENV;

  if (
    apiKey &&
    appVersion &&
    buildEnv &&
    buildEnvironments.includes(buildEnv)
  ) {
    bugsnagClient = Bugsnag.start({
      apiKey,
      appVersion,
      appType,
      releaseStage: buildEnv,
      enabledReleaseStages: buildEnvironments,
      enabledBreadcrumbTypes: ["error", "navigation", "request", "user"],
      plugins: [new BugsnagPluginReact(React)],
      onError: (event) => {
        if (sessionUser) {
          event.addMetadata("user", sessionUser);
        }
        if (sessionOrganisation) {
          event.addMetadata("organisation", sessionOrganisation);
        }
      },
    });

    bugsnagStartErrorBoundary();
  }
}
