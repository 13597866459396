import type { ConstEnum, EncodedDate } from "@streamtimefe/types";
import { entries } from "remeda";

export type TDateRange = { startDate: Date; endDate: Date };

export type TDateRangeEncoded = {
  startDate: EncodedDate;
  endDate: EncodedDate;
};

export const DateRangeInterval = {
  ThisWeek: "this-week",
  LastWeek: "last-week",
  ThisMonth: "this-month",
  LastMonth: "last-month",
  ThisQuarter: "this-quarter",
  LastQuarter: "last-quarter",
  ThisYear: "this-year",
  LastYear: "last-year",
} as const;

export type TDateRangeInterval = ConstEnum<typeof DateRangeInterval>;

export const DateRangeIntervalItems = entries(DateRangeInterval).map(
  ([key, value]) => ({ key, value })
);
