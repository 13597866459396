import { produce } from "immer";
import { get, set, union, unset } from "lodash-es";

import {
  EMPTY_ARRAY,
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
} from "../../../lib/constants";
import { getOrderId } from "../../../lib/entities/jobPhaseEntity";
import createCrossReducer from "../../helpers/createCrossReducer";

const receiveEntitiesReducer = (state, action) =>
  produce(state, (draft) => {
    const touchedJobIds = union(
      get(action.payload, ENTITY_NAME_JOB_ITEMS, []).map(
        (entity) => entity.jobId
      ),
      get(action.payload, ENTITY_NAME_JOB_PHASES, []).map(
        (entity) => entity.jobId
      )
    );

    touchedJobIds.forEach((jobId) => {
      const jobPhaseIds = get(
        state,
        `entities.jobItems.jobPhaseIdsByJobId.${jobId}`,
        EMPTY_ARRAY
      );
      if (!jobPhaseIds.length)
        unset(draft, `entities.jobItems.phaseOrderByJobId.${jobId}`);
      else {
        const orderedJobPhaseIds = jobPhaseIds
          .slice()
          .sort((jobPhaseId1, jobPhaseId2) => {
            if (jobPhaseId1 === 0) return -1;
            if (jobPhaseId2 === 0) return 1;

            const jobPhase1 = get(
              state,
              `entities.jobPhases.byId.${jobPhaseId1}`
            );
            const jobPhase2 = get(
              state,
              `entities.jobPhases.byId.${jobPhaseId2}`
            );

            if (!jobPhase1 || !jobPhase2) return 0;

            return getOrderId(jobPhase1) - getOrderId(jobPhase2);
          });

        set(
          draft,
          `entities.jobItems.phaseOrderByJobId.${jobId}`,
          orderedJobPhaseIds
        );
      }
    });
  });

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntitiesReducer,
});
