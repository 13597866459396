import type { TVitallyEnum } from "@streamtimefe/entities";
import { errorHandler, VitallyError } from "@streamtimefe/error";

import { fetch } from "../fetch";

const root = "vitally";

export async function events({ event }: { event: TVitallyEnum }) {
  try {
    await fetch().post(`${root}/events`, { event });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    errorHandler(new VitallyError(error), true);
  }
}
