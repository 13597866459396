import { Power3, TweenLite } from "gsap/all";
import { memoize, throttle } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { EmitterSubscriber } from "st-shared/lib";
import styled from "styled-components";

import { KEY_SPACE_BAR, KEY_T } from "../../../lib/constants";
import {
  addDays,
  differenceInCalendarDays,
  getStartOfWeek,
  getTodayDate,
  subDays,
} from "../../../lib/dates";
import { subtractCoords } from "../../../lib/dom";
import {
  addKeyEventListener,
  getClientCoords,
  removeKeyEventListener,
} from "../../../lib/events";
import { coordinateType } from "../../../lib/types/domTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { SCROLL_CONTEXTS } from "./index";

const getInitialAnchorPositionX = memoize(
  (dayWidth, date) =>
    differenceInCalendarDays(getTodayDate(), getStartOfWeek(date)) * dayWidth,
  (dayWidth, date) => JSON.stringify([dayWidth, date])
);

function getTweenDuration(delta) {
  if (!delta) return 0;
  if (delta < 100) return 0.1;
  if (delta < 300) return 0.3;
  if (delta < 500) return 0.5;
  if (delta < 800) return 0.8;
  return 1;
}

class ScrollContextProvider extends React.PureComponent {
  rafs = [];

  tweenX = null;

  lastMouseCoords = null;

  static propTypes = {
    Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
    children: reactNodesType.isRequired,
    contentHeight: PropTypes.number.isRequired,
    viewportWidth: PropTypes.number.isRequired,
    viewportHeight: PropTypes.number.isRequired,
    viewportOffset: coordinateType.isRequired,
    dayWidth: PropTypes.number.isRequired,
    initialDate: PropTypes.string,
    onNewDateRange: PropTypes.func,
    noScroll: PropTypes.bool,
  };

  static defaultProps = {
    initialDate: getTodayDate(),
    onNewDateRange: null,
    noScroll: false,
  };

  state = {
    scrollOffsetX: 0,
    scrollOffsetY: 0,
    isTweening: false,
    panMode: false,
    isPanning: false,
  };

  componentDidMount() {
    addKeyEventListener("keydown", this.onKeyDown);
    addKeyEventListener("keyup", this.onKeyUp);
    addKeyEventListener("mouseup", this.onMouseUp);

    this.contextValue();
  }

  componentDidUpdate(prevProps) {
    const { contentHeight: prevContentHeight } = prevProps;
    const { contentHeight, viewportHeight } = this.props;

    if (prevContentHeight !== contentHeight) {
      const { scrollOffsetY } = this.state;
      const scrollHeight = this.getScrollHeight();

      const maxY = scrollHeight - viewportHeight;

      if (scrollOffsetY > maxY)
        this.requestDomUpdate(() => {
          this.setState({
            scrollOffsetY: maxY,
          });
        });
    }
  }

  componentWillUnmount() {
    this.rafs.forEach(window.cancelAnimationFrame);
    this.rafs = [];

    removeKeyEventListener("keydown", this.onKeyDown);
    removeKeyEventListener("keyup", this.onKeyUp);
    removeKeyEventListener("mouseup", this.onKeyUp);
  }

  onKeyDown = (e) => {
    const { panMode } = this.state;
    switch (e.code) {
      case KEY_SPACE_BAR:
        if (!panMode) this.setState({ panMode: true });
        break;
      case KEY_T:
        if (e.shiftKey) this.goToToday();
        break;
    }
  };

  onKeyUp = (e) => {
    if (e.code === KEY_SPACE_BAR)
      this.setState({
        panMode: false,
      });
  };

  onMouseDown = (e) => {
    const { panMode } = this.state;

    if (!panMode) return;

    if (!e.target.closest(".scrollContextContainer")) return;

    this.lastMouseCoords = getClientCoords(e);

    this.setState({
      isPanning: true,
    });
  };

  onMouseUp = () => {
    this.setState({
      isPanning: false,
    });
  };

  onMouseMove = throttle((e) => {
    const { panMode, isPanning } = this.state;

    if (!panMode || !isPanning) return;

    const { viewportHeight } = this.props;
    const { scrollOffsetX, scrollOffsetY } = this.state;
    const scrollHeight = this.getScrollHeight();
    const minY = 0;
    const maxY = scrollHeight - viewportHeight;

    const lastCoords = this.lastMouseCoords;
    const newCoords = getClientCoords(e);
    this.lastMouseCoords = newCoords;

    const { x: dX, y: dY } = subtractCoords(lastCoords, newCoords);

    const nextScrollOffsetX = scrollOffsetX + dX;
    let nextScrollOffsetY = scrollOffsetY + dY;

    if (nextScrollOffsetY < minY) nextScrollOffsetY = minY;
    if (nextScrollOffsetY > maxY) nextScrollOffsetY = maxY;

    if (
      scrollOffsetX === nextScrollOffsetX &&
      scrollOffsetY === nextScrollOffsetY
    )
      return;

    this.setState({
      scrollOffsetX: nextScrollOffsetX,
      scrollOffsetY: nextScrollOffsetY,
    });
  }, 0);

  onWheel = (e) => {
    if (!e.target.closest(".scrollContextContainer")) return;

    const { viewportHeight } = this.props;
    const { scrollOffsetX, scrollOffsetY } = this.state;
    const scrollHeight = this.getScrollHeight();
    const minY = 0;
    const maxY = scrollHeight - viewportHeight;

    const { deltaX, deltaY } = e;
    const { wheelDelta, shiftKey } = e.nativeEvent;
    const dX = shiftKey ? -wheelDelta || deltaX : deltaX;
    const dY = shiftKey ? 0 : deltaY;

    const nextScrollOffsetX = scrollOffsetX + dX;
    let nextScrollOffsetY = scrollOffsetY + dY;

    if (nextScrollOffsetY < minY) nextScrollOffsetY = minY;
    if (nextScrollOffsetY > maxY) nextScrollOffsetY = maxY;

    if (
      scrollOffsetX === nextScrollOffsetX &&
      scrollOffsetY === nextScrollOffsetY
    )
      return;

    this.setState({
      scrollOffsetX: nextScrollOffsetX,
      scrollOffsetY: nextScrollOffsetY,
    });
  };

  getScrollHeight = () => {
    const { contentHeight, viewportHeight, viewportOffset } = this.props;
    return Math.max(
      contentHeight + viewportOffset.y + viewportOffset.b,
      viewportHeight
    );
  };

  getScrollOffsetX = () => {
    const { scrollOffsetX } = this.state;
    return scrollOffsetX;
  };

  getScrollOffsetY = () => {
    const { scrollOffsetY } = this.state;
    return scrollOffsetY;
  };

  getDateAtOffsetX = (offsetX) => {
    const { dayWidth } = this.props;
    const daysFromToday = Math.round(offsetX / dayWidth);
    return addDays(getTodayDate(), daysFromToday);
  };

  getOffsetXAtDate = (date) => {
    const { dayWidth } = this.props;
    const daysFromToday = differenceInCalendarDays(date, getTodayDate());
    return daysFromToday * dayWidth;
  };

  getDateOnPosition = (pointerPosition) => {
    const { viewportOffset, dayWidth } = this.props;
    const { anchorPositionX } = this.contextValue();
    const pointerOffsetX =
      pointerPosition.x - viewportOffset.x - anchorPositionX;
    const daysFromToday = Math.floor(pointerOffsetX / dayWidth);
    return addDays(getTodayDate(), daysFromToday);
  };

  getDayWidth = () => {
    const { dayWidth } = this.props;
    return dayWidth;
  };

  getDaysFromWidth = (width) =>
    Math.max(Math.round(width / this.getDayWidth()), 1);

  contextValue = () => {
    const {
      getScrollOffsetX,
      getScrollOffsetY,
      getDateAtOffsetX,
      getDateOnPosition,
      getOffsetXAtDate,
      getDaysFromWidth,
      goToToday,
      goToDate,
      goToRHSDate,
      goForward,
      goBackward,
      getDayWidth,
      beforeZoom,
    } = this;
    const {
      viewportWidth,
      viewportHeight,
      contentHeight,
      dayWidth,
      initialDate,
      onNewDateRange,
    } = this.props;
    const { scrollOffsetX, scrollOffsetY, isTweening } = this.state;

    const today = getTodayDate();
    const initialAnchorPositionX = getInitialAnchorPositionX(
      dayWidth,
      initialDate
    );
    const anchorPositionX = initialAnchorPositionX - scrollOffsetX;
    const viewportOffsetX = scrollOffsetX - initialAnchorPositionX;
    const viewportOffsetRightX = viewportOffsetX + viewportWidth;
    const viewportStartDate = addDays(
      today,
      Math.floor(viewportOffsetX / dayWidth)
    );
    const viewportEndDate = addDays(
      today,
      Math.floor(viewportOffsetRightX / dayWidth)
    );

    if (onNewDateRange && !isTweening)
      onNewDateRange(viewportStartDate, viewportEndDate, scrollOffsetY);

    return {
      scrollOffsetX,
      scrollOffsetY,
      dayWidth,
      anchorPositionX,
      viewportHeight,
      contentHeight,
      viewportOffsetX,
      viewportOffsetRightX,
      viewportStartDate,
      viewportEndDate,
      getScrollOffsetX,
      getScrollOffsetY,
      getDateAtOffsetX,
      getDateOnPosition,
      getOffsetXAtDate,
      getDayWidth,
      getDaysFromWidth,
      goToToday,
      goToDate,
      goToRHSDate,
      goForward,
      goBackward,
      beforeZoom,
    };
  };

  scrollToTop = () => this.requestDomUpdate(() => this.tweenToScrollOffsetY(0));

  goToDate = (date) => this.tweenToDate(date);

  goToToday = () => this.goToDate(getTodayDate());

  goForward = () => {
    const { viewportEndDate } = this.contextValue();
    return this.goToDate(getStartOfWeek(viewportEndDate));
  };

  goBackward = () => {
    const { viewportStartDate, viewportEndDate } = this.contextValue();
    const firstMonday = getStartOfWeek(viewportStartDate);
    const days = differenceInCalendarDays(
      getStartOfWeek(viewportEndDate),
      firstMonday
    );
    const prevMonday = subDays(firstMonday, days);
    return this.goToDate(prevMonday);
  };

  centerOnDate = (date, animate = true) => {
    const { viewportWidth, dayWidth } = this.props;
    const targetDate = subDays(date, Math.floor(viewportWidth / 2 / dayWidth));

    if (animate) return this.tweenToDate(targetDate);

    this.setState(({ scrollOffsetX }) => ({
      scrollOffsetX:
        scrollOffsetX + this.getScrollDistanceToTargetDate(targetDate),
    }));

    return new Promise((resolve) => {
      this.requestDomUpdate(() => resolve());
    });
  };

  goToRHSDate = (date) => {
    const { viewportWidth, dayWidth } = this.props;
    const targetDate = subDays(date, Math.floor(viewportWidth / dayWidth) - 1);

    return this.tweenToDate(targetDate);
  };

  getCenterDate = () => {
    const { viewportWidth, dayWidth } = this.props;
    const { viewportStartDate } = this.contextValue();
    return addDays(viewportStartDate, Math.floor(viewportWidth / 2 / dayWidth));
  };

  getScrollDistanceToTargetDate = (targetDate) => {
    const { viewportOffsetX } = this.contextValue();
    return this.getOffsetXAtDate(targetDate) - viewportOffsetX;
  };

  getScrollDistanceToTargetOffsetY = (targetScrollOffsetY) => {
    const { scrollOffsetY } = this.state;
    return targetScrollOffsetY - scrollOffsetY;
  };

  tweenScrollOffset = (distance, onUpdate, onComplete) => {
    const tweenData = {
      _x: 0,
      tx: 0,
      dx: 0,
      get x() {
        return this._x;
      },
      set x(val) {
        const x = Math.round(val);
        this.dx = x - this.x;
        this.tx += this.dx;
        this._x = x;
      },
      // adjustment for rounding error
      get errorX() {
        return tweenData.x - tweenData.tx;
      },
    };

    if (this.tweenX) this.tweenX.kill();

    return new Promise((resolve, reject) => {
      try {
        // noinspection JSUnusedGlobalSymbols
        this.tweenX = new TweenLite(
          tweenData,
          getTweenDuration(Math.abs(distance)),
          {
            x: distance,
            ease: Power3.easeOut,
            onUpdate: () => {
              onUpdate(tweenData.dx);
            },
            onComplete: () => {
              onComplete(tweenData.errorX);
              resolve();
            },
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  tweenToDate = (targetDate) => {
    const distance = this.getScrollDistanceToTargetDate(targetDate);

    const onUpdate = (dx) => {
      this.setState(({ scrollOffsetX }) => ({
        scrollOffsetX: scrollOffsetX + dx,
        isTweening: true,
      }));
    };

    const onComplete = (dx) => {
      this.setState(({ scrollOffsetX }) => ({
        scrollOffsetX: scrollOffsetX + dx,
        isTweening: false,
      }));
    };

    return this.tweenScrollOffset(distance, onUpdate, onComplete);
  };

  tweenToScrollOffsetY = (targetScrollOffsetY) => {
    const distance = this.getScrollDistanceToTargetOffsetY(targetScrollOffsetY);

    const onUpdate = (dx) => {
      this.setState(({ scrollOffsetY }) => ({
        scrollOffsetY: scrollOffsetY + dx,
        isTweening: true,
      }));
    };

    const onComplete = (dx) => {
      this.setState(({ scrollOffsetY }) => ({
        scrollOffsetY: scrollOffsetY + dx,
        isTweening: false,
      }));
    };

    return this.tweenScrollOffset(distance, onUpdate, onComplete);
  };

  beforeZoom = () => {
    const centerDate = this.getCenterDate();
    this.requestDomUpdate(() => this.centerOnDate(centerDate, false));
  };

  requestDomUpdate(callBack) {
    this.rafs.push(window.requestAnimationFrame(callBack));
  }

  renderNoScrollContext() {
    const { Context, children } = this.props;
    return (
      <NoScrollWrapper>
        <Context.Provider value={this.contextValue()}>
          {children}
        </Context.Provider>
      </NoScrollWrapper>
    );
  }

  render() {
    const { Context, children, noScroll } = this.props;
    const { panMode, isPanning } = this.state;
    const classes = ["scrollContextContainer"];

    if (panMode) classes.push("panMode");
    if (isPanning) classes.push("isPanning");

    if (noScroll) return this.renderNoScrollContext();

    return (
      <Wrapper
        className={classes.join(" ")}
        onWheel={this.onWheel}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
      >
        <EmitterSubscriber
          event="scrollContextCenterOnDate"
          callback={this.centerOnDate}
        />
        <EmitterSubscriber
          event="scrollContextScrollToTop"
          callback={this.scrollToTop}
        />
        <Context.Provider value={this.contextValue()}>
          {children}
        </Context.Provider>
      </Wrapper>
    );
  }
}

export default ScrollContextProvider;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &.panMode {
    > * {
      pointer-events: none;
    }
    cursor: grab;
    &.isPanning {
      cursor: grabbing;
    }
  }
  user-select: none;
`;

const NoScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
