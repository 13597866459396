import { Entity } from "@streamtimefe/entities";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { costingMethodObj } from "st-shared/entities";
import { itemPricingMethodObj } from "st-shared/entities/ItemPricingMethod";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import {
  applyExchangeRate,
  applyNegative,
  convertNewLinesToHtml,
} from "st-shared/lib";

import {
  createJobItemInvoiceLineItemAPI,
  createLoggedExpenseInvoiceLineItemAPI,
} from "../../../lib/API/invoiceLineItemAPI";
import {
  ENTITIES_RECEIVED,
  INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN,
  INVOICE_LINE_ITEM_ADD_ITEM,
  INVOICE_LINE_ITEM_ADD_LINE,
  INVOICE_LINE_ITEM_CREATE,
} from "../../../lib/constants";
import { isInvoiceTypeCreditNote } from "../../../lib/entities/invoiceEntity";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../../lib/ui/accountingPlatform";
import {
  commercialDocumentLineItemDefaults,
  getDefaultName,
} from "../../../lib/ui/commercialDocumentLineItems";
import { sendSetHeadingsVisible } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import { selectMasterJobItemRate } from "../../../state/entities/itemRate/selectors/selectMasterJobItemRate";
import { selectMasterLoggedExpenseRate } from "../../../state/entities/itemRate/selectors/selectMasterLoggedExpenseRate";
import { selectMasterJobItem } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { selectMasterLoggedExpense } from "../../../state/entities/masterLoggedExpense/selectors/selectMasterLoggedExpense";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLineItemDefaultAccountCodeAndTaxRate } from "../../selectors/commercialDocumentSelectors";
import { selectInvoice } from "../../selectors/invoices";
import {
  selectInvoiceExchangeRateByInvoiceId,
  selectInvoiceJobBranchIdById,
  selectInvoiceJobRateCardIdByInvoiceId,
} from "../../selectors/invoiceSelectors";

function* addLine(action) {
  try {
    const { invoiceId, orderId, isHeading } = action.payload;

    const id = Entity.temporaryId();

    const invoiceLineItem = {
      ...commercialDocumentLineItemDefaults,
      id,
      invoiceId,
      isHeading,
      orderId,
      name: getDefaultName(isHeading),
    };

    if (!isHeading) {
      const branchId = yield select(selectInvoiceJobBranchIdById, {
        id: invoiceId,
      });

      const { accountCode, taxName, taxRate, externalTaxRateId } = yield select(
        selectLineItemDefaultAccountCodeAndTaxRate,
        {
          branchId,
          accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE,
        }
      );

      Object.assign(invoiceLineItem, {
        accountCode,
        taxName,
        taxRate,
        externalTaxRateId,
      });
    } else {
      yield call(sendSetHeadingsVisible);
    }

    yield put(createAction(INVOICE_LINE_ITEM_CREATE, { id, invoiceLineItem }));
  } catch (error) {
    sagaError(error);
  }
}

function* addItem(action) {
  try {
    const { invoiceId, orderId, masterJobItemId, masterLoggedExpenseId } =
      action.payload;

    const invoice = yield select(selectInvoice, { invoiceId });

    let masterJobItem = null;
    let masterLoggedExpense = null;

    if (masterJobItemId) {
      masterJobItem = yield select(selectMasterJobItem, {
        id: masterJobItemId,
      });
    } else {
      masterLoggedExpense = yield select(selectMasterLoggedExpense, {
        id: masterLoggedExpenseId,
      });
    }

    const rateCardId = yield select(selectInvoiceJobRateCardIdByInvoiceId, {
      invoiceId,
    });

    const exchangeRate = yield select(selectInvoiceExchangeRateByInvoiceId, {
      invoiceId,
    });

    const jobCurrencySellRate = masterJobItemId
      ? yield select(selectMasterJobItemRate, {
          masterJobItemId,
          rateCardId,
        })
      : yield select(selectMasterLoggedExpenseRate, {
          masterLoggedExpenseId,
          rateCardId,
        });

    const invoiceCurrencySellRate = applyExchangeRate(
      jobCurrencySellRate,
      exchangeRate
    );

    let quantity = null;
    let unitRate = null;
    const totalAmountExTax = isInvoiceTypeCreditNote(invoice)
      ? applyNegative(invoiceCurrencySellRate)
      : invoiceCurrencySellRate;

    if (
      masterJobItem
        ? costingMethodObj(masterJobItem.costingMethod).isItem()
        : itemPricingMethodObj(
            masterLoggedExpense.itemPricingMethod
          ).isUnitPrice()
    ) {
      quantity = 1;
      unitRate = isInvoiceTypeCreditNote(invoice)
        ? applyNegative(invoiceCurrencySellRate)
        : invoiceCurrencySellRate;
    }

    const description = `<p>${convertNewLinesToHtml(
      masterJobItem
        ? masterJobItem.description
        : masterLoggedExpense.description
    )}</p>`;
    const name = masterJobItem ? masterJobItem.name : masterLoggedExpense.name;

    const branchId = yield select(selectInvoiceJobBranchIdById, {
      id: invoiceId,
    });

    const { accountCode, taxName, taxRate, externalTaxRateId } = yield select(
      selectLineItemDefaultAccountCodeAndTaxRate,
      {
        branchId,
        masterItem: masterJobItem || masterLoggedExpense,
        accountType: ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE,
      }
    );

    const id = Entity.temporaryId();

    const invoiceLineItem = {
      ...commercialDocumentLineItemDefaults,
      id,
      invoiceId,
      orderId,
      name,
      description,
      quantity,
      unitRate,
      totalAmountExTax,
      accountCode,
      taxName,
      taxRate,
      externalTaxRateId,
    };

    yield put(createAction(INVOICE_LINE_ITEM_CREATE, { id, invoiceLineItem }));
  } catch (error) {
    sagaError(error);
  }
}

function* addFromJobPlan(action) {
  try {
    const { invoiceId, orderId, entityId, optionType } = action.payload;

    feToWebShowPageLoadingMask(10);

    const createAPI =
      optionType === LineItemOptionTypes.JobItem
        ? createJobItemInvoiceLineItemAPI
        : createLoggedExpenseInvoiceLineItemAPI;

    const enitityChangeSet = yield call(
      createAPI,
      invoiceId,
      entityId,
      orderId
    );

    yield put(createAction(ENTITIES_RECEIVED, enitityChangeSet));
  } catch (error) {
    sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchInvoiceLineItemAdd() {
  yield takeLatest(INVOICE_LINE_ITEM_ADD_LINE, addLine);
  yield takeLatest(INVOICE_LINE_ITEM_ADD_ITEM, addItem);
  yield takeLatest(INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN, addFromJobPlan);
}
