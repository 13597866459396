import { Entity } from "@streamtimefe/entities";
import { put, select } from "redux-saga/effects";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED,
  JOB_ITEM_USER_CREATE,
  JOB_ITEM_USER_EDIT,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  getJobId,
  getJobItemStatusId,
} from "../../../lib/entities/jobItemEntity";
import {
  createNewJobItemUser,
  getJobItemUserStatusFromJobItemStatus,
} from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectDefaultJobItemUserSellRateByJobItemIdUserId } from "../../selectors/rateSelectors";

function* create(action) {
  const createId = Entity.temporaryId();

  try {
    const { jobItemId, userId, doSetFocus } = action.payload;

    if (doSetFocus) {
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
          currentFocus: {
            jobItemId,
            key: FOCUS_KEYS.ITEM_GENERAL,
          },
        })
      );
    }

    const jobItem = yield select(selectJobItemById, { id: jobItemId });
    const jobId = getJobId(jobItem);

    const jobItemUserStatus = getJobItemUserStatusFromJobItemStatus(
      getJobItemStatusId(jobItem)
    );

    let jobCurrencySellRate = yield select(
      selectDefaultJobItemUserSellRateByJobItemIdUserId,
      {
        jobItemId,
        userId,
      }
    );

    const newJobItemUser = createNewJobItemUser({
      id: createId,
      jobId,
      jobItemId,
      userId,
      jobCurrencySellRate,
      jobItemUserStatus,
    });

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEM_USERS]: [newJobItemUser],
      })
    );

    yield put(
      createAction(JOB_ITEM_USER_EDIT, {
        jobItemId,
        jobItemUsers: [{ new: newJobItemUser }],
      })
    );

    if (doSetFocus) {
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
          currentFocus: {
            jobItemId,
            key: FOCUS_KEYS.ITEM_NEW_USER,
          },
        })
      );
    }
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemUserCreate() {
  yield takeLatestBy(
    [JOB_ITEM_USER_CREATE],
    create,
    (action) => action.payload.jobItemId
  );
}
