import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_QUOTES,
  QUOTE_EDIT,
  QUOTE_SAVE,
  QUOTE_SAVE_FAILED
} from "../../../../lib/constants";
import { getJobId } from "../../../../lib/entities/jobItemEntity";
import { isDeleted } from "../../../../lib/entities/quoteEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";

const idsByJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities)
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_QUOTES],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_QUOTES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const editQuoteReducer = (state, action) => {
  const { id, quote } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: state.byId[id],
      newEntity: quote
    }
  ]);
};

const editQuoteErrorReducer = (state, action) => {
  const { id, prevQuote } = action.payload;
  const prevEntity = state.byId[id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: prevQuote
    }
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [QUOTE_EDIT]: editQuoteReducer,
    [QUOTE_SAVE]: editQuoteReducer,
    [QUOTE_SAVE_FAILED]: editQuoteErrorReducer
  }
);
