import { createIcon } from "../../icon/Icon";

export const IconStatusClosedGroupFilled = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66667 8L7 6H8V2H16V6H22V17.25L20 15.75L19.98 15.7375L9.6811 8.01333L9.66667 8ZM10 6H14V4H10V6Z"
    />
    <path d="M3.1312 5.5984L2.6 5.2L1.4 6.8L2.94878 7.96159L2.95 7.95996L21.4012 21.7984L22.5988 20.2016L3.13 5.6L3.1312 5.5984Z" />
    <path d="M4.02539 11.2665L14.3062 18.9771L14.3333 19L17 21H2V9.75L4 11.25L4.02539 11.2665Z" />
  </>,
  "IconStatusClosedGroupFilled"
);
