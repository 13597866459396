import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { jobItemEntityType } from "../../../lib/entities/jobItemEntity";
import { jobPhaseEntityType } from "../../../lib/entities/jobPhaseEntity";
import { selectOrderedJobItemsByJobIdPhaseId } from "../../../redux/selectors/jobItemSelectors";
import { selectJobPhaseById } from "../../../redux/selectors/jobTimelineSelectors";

const mapState = (state, props) => ({
  jobPhase: selectJobPhaseById(state, props),
  jobItems: selectOrderedJobItemsByJobIdPhaseId(state, props)
});

const mapDispatch = null;

const JobTimelineSummaryPhaseConnector = ({ jobPhase, jobItems, children }) =>
  children({
    jobPhase,
    jobItems
  });

JobTimelineSummaryPhaseConnector.propTypes = {
  jobPhase: jobPhaseEntityType,
  jobItems: PropTypes.arrayOf(jobItemEntityType).isRequired,
  children: PropTypes.func.isRequired
};

export default connect(mapState, mapDispatch)(JobTimelineSummaryPhaseConnector);
