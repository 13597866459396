import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoggedInUserId } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SUB_ITEM_DELETE,
  JOB_ITEM_SUB_ITEM_SET_CHECKED,
  JOB_ITEM_SUB_ITEM_SET_DESCRIPTION,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  getCompletedByUserId,
  getDescription,
  getRelativeCreatedTime,
  isComplete,
} from "../../../lib/entities/jobItemSubItemEntity";
import { getDisplayName } from "../../../lib/entities/userEntity";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemSubItem } from "../../../redux/selectors/jobItemSubItem";
import { useUser } from "../../../redux/selectors/user";
import { IconButton } from "../../elements/Button";
import DragIndicatorIcon from "../../elements/Icons/custom/DragIndicatorIcon";
import DeleteIcon from "../../elements/Icons/DeleteIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import {
  FullWidthBaseItem,
  SubItemCheckedBox,
  SubItemTextArea,
} from "../styles";

const SubItem = ({ id, nextFocus }) => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const subItem = useJobItemSubItem(id);
  const jobItem = useJobItem(jobItemId);
  const completedUser = useUser(getCompletedByUserId(subItem));
  const loggedInUserId = useLoggedInUserId();
  const isJobEditable = useIsJobEditable(jobId);
  const isChecked = isComplete(subItem);

  const [ref, focused, setFocused] = useFocusStateRef({
    jobItemSubItemId: id,
    jobItemId: subItem.jobItemId,
    key: FOCUS_KEYS.ITEM_SUB_ITEM,
  });

  const [description, setDescription] = useState(getDescription(subItem));

  useEffect(() => {
    setDescription(getDescription(subItem));
  }, [subItem]);

  const completedUserText = (() => {
    if (isChecked) {
      if (
        getCompletedByUserId(subItem) &&
        getCompletedByUserId(subItem) !== loggedInUserId
      ) {
        return getDisplayName(completedUser);
      }
      return "you";
    }
    return "";
  })();
  const completedDateText = isChecked ? getRelativeCreatedTime(subItem) : "";

  const dispatch = useDispatch();

  const setNextFocus = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: nextFocus,
      })
    );
  };

  const setGeneralFocus = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
  };

  const doJobItemSubItemSetDescription = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SUB_ITEM_SET_DESCRIPTION, {
        jobItemId: jobItem.id,
        jobItemSubItemId: id,
        value: description,
      })
    );
  };

  const doJobItemSubItemSetChecked = (checked) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SUB_ITEM_SET_CHECKED, {
        jobItemId: jobItem.id,
        jobItemSubItemId: id,
        checked,
      })
    );
  };

  const doJobItemSubItemDelete = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SUB_ITEM_DELETE, { jobItemSubItem: subItem })
    );
  };

  const onToggleCheckbox = (e) => {
    setGeneralFocus();
    doJobItemSubItemSetChecked(Boolean(e.target.checked));
  };

  const handleDescriptionChange = (e) => {
    if (!isJobEditable) return;
    setDescription(String(e.target.value));
  };

  const handleDescriptionBlur = (e) => {
    setFocused(false);
    doJobItemSubItemSetDescription();
  };

  const handleDescriptionFocus = (e) => {
    setFocused(true);
  };

  const handleDelete = () => {
    doJobItemSubItemDelete();
  };

  useKeyEvent(
    isJobEditable && focused,
    "keydown",
    (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        setNextFocus();
      }
    },
    setNextFocus
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  // prevent tab when dragging
  useKeyEvent(isJobEditable && isDragging, "keydown", (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  useKeyEvent(isJobEditable && focused, "keydown", (e) => {
    if (e.keyCode === 13) {
      if (!e.shiftKey) {
        // scroll by height of the sub item
        window.requestAnimationFrame(() => window.scrollBy(0, 31));
      } else {
        // scroll by the line height of the text area
        window.requestAnimationFrame(() => window.scrollBy(0, 17));
      }
    }
  });

  return (
    <Container
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      $dragging={isDragging}
    >
      <SubItemCheckedBox
        onChange={onToggleCheckbox}
        checked={isChecked}
        tabIndex={-1}
        disabled={!isJobEditable}
      />
      <SubItemTextArea
        ref={ref}
        checked={isChecked}
        value={focused ? description : getDescription(subItem)}
        onChange={handleDescriptionChange}
        onFocus={handleDescriptionFocus}
        onBlur={handleDescriptionBlur}
        disabled={!isJobEditable}
        maxLength="256"
      ></SubItemTextArea>
      {isChecked && (
        <StyledCompletion>
          Completed by <b>{completedUserText}</b> {completedDateText}
        </StyledCompletion>
      )}
      {isJobEditable && (
        <DragHandle {...listeners} {...attributes} tabIndex={-1}>
          <DragIndicatorIcon />
        </DragHandle>
      )}
      {isJobEditable && (
        <StyledDeleteButton tabIndex={-1} onClick={handleDelete}>
          <DeleteIcon />
        </StyledDeleteButton>
      )}
    </Container>
  );
};

SubItem.propTypes = {
  id: entityIdType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  nextFocus: PropTypes.object.isRequired,
};

export default SubItem;

const Container = styled(FullWidthBaseItem)`
  height: fit-content;
  min-height: 30px;
  &:hover {
    .StyledDeleteButton {
      opacity: ${(props) => (props.$dragging ? "0" : "1")};
    }
    .DragHandle {
      opacity: 1;
    }
    background-color: var(--color-jonsnow);
  }

  ${(props) =>
    props.$dragging &&
    `
    background-color: var(--color-jonsnow);
    z-index: 101;
    .DragHandle {
      opacity: 1;
    }
  `};
`;

const StyledCompletion = styled.div`
  align-self: center;
  font-size: 10px;
  margin: 0 20px;
  white-space: nowrap;
  line-height: 10px;
  top: 1px;
  position: relative;
`;

const StyledDeleteButton = styled(IconButton).attrs({
  className: "StyledDeleteButton",
})`
  align-self: center;
  position: absolute;
  right: -32px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

const DragHandle = styled(IconButton).attrs({
  className: "DragHandle",
})`
  align-self: center;
  margin: 0 8px;
  color: var(--color-gray-medium);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  opacity: 0;
`;
