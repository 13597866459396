import { EntityClass } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";
import { defaultSortDate } from "@streamtimefe/utils";
import { useMemo } from "react";
import { groupBy, pipe, sort } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";

export const useGoogleCalendarInboxEventEntity = createUseEntityStoreEntity(
  EntityClass.GoogleCalendarInboxEvent
);

export const getGoogleCalendarInboxEventEntity = createGetEntityStoreEntity(
  EntityClass.GoogleCalendarInboxEvent
);

function useGoogleCalendarInboxEventEntities() {
  return useEntityStoreEntities(EntityClass.GoogleCalendarInboxEvent);
}

export function useGroupedGoogleCalendarInboxEvents() {
  const googleCalendarInboxEvents = useGoogleCalendarInboxEventEntities();
  return useMemo(
    () =>
      pipe(
        dictionaryToArray(googleCalendarInboxEvents),
        sort((event1, event2) =>
          defaultSortDate(event1.startDate, event2.startDate)
        ),
        groupBy((x) => {
          return x.ignored ? "dismissed" : "active";
        })
      ),
    [googleCalendarInboxEvents]
  );
}
