import { put, call } from "redux-saga/effects";
import { updateJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

export function* saveJobItemImmediate(
  jobItem,
  removeJobItemDependancyIds = []
) {
  try {
    if (jobItem.id < 0) return;

    const { data } = yield call(
      updateJobItemAPI,
      jobItem,
      null,
      removeJobItemDependancyIds,
      [],
      []
    );

    if (removeJobItemDependancyIds.length > 0) {
      yield put(
        createAction(ENTITIES_REMOVED, {
          entityName: ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
          ids: removeJobItemDependancyIds
        })
      );
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  }
}
