import type { AnimatePresenceProps } from "motion/react";
import { AnimatePresence, motion } from "motion/react";
import type { PropsWithChildren } from "react";

export type AnimatableDivProps = PropsWithChildren & {
  open: boolean;
  initial?: AnimatePresenceProps["initial"];
  variant?: keyof typeof AnimationVariants;
  className?: string;
};

export function AnimatableDiv({
  open,
  initial = false,
  variant = "height",
  className,
  children,
}: AnimatableDivProps) {
  return (
    <AnimatePresence initial={initial}>
      {open && (
        <motion.div
          initial="hide"
          animate="open"
          exit="hide"
          variants={AnimationVariants[variant]}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const AnimationVariants = {
  opacity: {
    open: {
      opacity: 1,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    hide: {
      opacity: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  },
  height: {
    open: {
      opacity: 1,
      height: "auto",
      transition: { duration: 0.2, ease: "easeInOut" },
    },
    hide: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.2, ease: "easeInOut" },
    },
  },
} as const;
