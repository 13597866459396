import styled from "styled-components";
import { JOB_TIMELINE_GROUP_COLUMN_WIDTH } from "../../lib/constants";

const JobTimelineGroupColumnCell = styled.div`
  position: relative;
  width: ${JOB_TIMELINE_GROUP_COLUMN_WIDTH}px;
  height: 100%;
  background-color: white;
  border-right: var(--border-thick-bright);
`;

export default JobTimelineGroupColumnCell;
