import { produce } from "immer";
import { groupBy, isEmpty, set } from "lodash-es";
import { branchEntityStore } from "st-shared/stores";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_ROLES,
  ENTITY_NAME_USERS,
} from "../../../lib/constants";
import { getName } from "../../../lib/entities/branchEntity";
import createCrossReducer from "../../helpers/createCrossReducer";
import { selectRoleSearchStringByRoleId } from "../../selectors/roleSelectors";
import { selectActiveUserOptions } from "../../selectors/userSelectors";

const receiveEntitiesReducer = (state, action) =>
  produce(state, (draft) => {
    if (
      isEmpty(action.payload[ENTITY_NAME_USERS]) &&
      isEmpty(action.payload[ENTITY_NAME_ROLES])
    )
      return;

    const newOptions = selectActiveUserOptions(state).map((userOption) => {
      const roleSearchString = selectRoleSearchStringByRoleId(state, {
        id: userOption.roleId,
      });

      return {
        ...userOption,
        searchString: [userOption.searchString, roleSearchString].join(" "),
      };
    });

    set(draft, "entities.users.activeOptions", newOptions);

    const groupedActiveOptions = groupBy(
      newOptions,
      (userOption) => userOption.branchId
    );

    const activeOptionsGroupedByBranch = [];

    Object.keys(groupedActiveOptions).forEach((id) => {
      const branch = branchEntityStore().entities[id];
      const headingOption = {
        key: `branch_${id}`,
        value: getName(branch),
        searchString: getName(branch),
        isHeading: true,
      };

      activeOptionsGroupedByBranch.push(headingOption);

      activeOptionsGroupedByBranch.push(
        ...appendSearchStringToOptions(
          groupedActiveOptions[id],
          headingOption.searchString
        )
      );
    });

    set(
      draft,
      "entities.users.activeOptionsGroupedByBranch",
      activeOptionsGroupedByBranch
    );
  });

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntitiesReducer,
});

const appendSearchStringToOption = (option, searchString) => ({
  ...option,
  searchString: [option.searchString, searchString].join(" "),
});

const appendSearchStringToOptions = (options, searchString) =>
  options.map((option) => appendSearchStringToOption(option, searchString));
