import { PropsWithChildren } from "react";

import { FilterContext, TFilterContext } from "./FilterContext";

type Props = PropsWithChildren & TFilterContext;

export function FilterContextProvider({ children, ...props }: Props) {
  return (
    <FilterContext.Provider value={props}>{children}</FilterContext.Provider>
  );
}
