import styled from "styled-components";

export const PaymentSummaryContainer = styled.table`
  margin-top: 35px;
  width: 100%;
  font-size: 12px;

  th,
  td {
    height: 30px;
    vertical-align: middle;
  }

  th {
    text-align: left;
    border-bottom: 1px solid black;
    font-weight: bold;
  }

  td {
    border-bottom: 1px dashed var(--color-gray);

    span.gray-dark {
      color: var(--color-gray-dark);
    }
  }

  td.date {
    width: 130px;
  }

  td:last-child {
    text-align: right;
    font-weight: bold;
  }
`;
