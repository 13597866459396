import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataQuoteNumber,
  getMetaDataQuoteRecipients,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import ListRecipients from "./ListRecipients";
import SystemMessage from "./SystemMessage";

const JobQuoteSent = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" sent quote "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataQuoteNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to "}
    <ListRecipients recipients={getMetaDataQuoteRecipients(activityEntry)} />
  </SystemMessage>
);

JobQuoteSent.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobQuoteSent;
