import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { AriaComboBoxStateContext } from "../../exports/aria";

export const ComboBoxInputValueContext = createContext<string>("");

export function useComboBoxInputValueContext() {
  const context = useContext(ComboBoxInputValueContext);
  return context ?? "";
}

export function ComboBoxInputValueProvider({ children }: PropsWithChildren) {
  const state = useContext(AriaComboBoxStateContext);
  return (
    <ComboBoxInputValueContext.Provider value={state?.inputValue ?? ""}>
      {children}
    </ComboBoxInputValueContext.Provider>
  );
}
