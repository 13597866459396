import { put, select, takeLatest } from "redux-saga/effects";
import {
  PURCHASE_ORDER_LINE_ITEM_SAVE,
  PURCHASE_ORDER_LINE_ITEM_SET_ACCOUNT_CODE
} from "../../../lib/constants";
import {
  getExternalTaxRateName,
  getExternalTaxRateRate
} from "../../../lib/ui/accountingPlatform";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectExpenseAccountDefaultTaxRateByAccountCode } from "../../selectors/accountingPlatformSelectors";
import { selectPurchaseOrderLineItemById } from "../../selectors/purchaseOrderLineItemSelectors";

function* setAccountCode(action) {
  try {
    const { id, accountCode } = action.payload;

    const prevPurchaseOrderLineItem = yield select(
      selectPurchaseOrderLineItemById,
      { id }
    );

    const purchaseOrderLineItem = {
      ...prevPurchaseOrderLineItem,
      accountCode
    };

    const externalTaxRate = yield select(
      selectExpenseAccountDefaultTaxRateByAccountCode,
      { accountCode }
    );

    if (externalTaxRate) {
      const taxName = getExternalTaxRateName(externalTaxRate);
      const taxRate = getExternalTaxRateRate(externalTaxRate);
      const externalTaxRateId = externalTaxRate.id;

      Object.assign(purchaseOrderLineItem, {
        taxName,
        taxRate,
        externalTaxRateId
      });
    }

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SAVE, {
        id,
        purchaseOrderLineItem,
        prevPurchaseOrderLineItem
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchPurchaseOrderLineItemSetAccountCode() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_SET_ACCOUNT_CODE, setAccountCode);
}
