import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobGroupStore = createSingleEntityStoreContext(
  EntityClass.JobGroup
);

export const JobGroupStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobGroup,
  JobGroupStore
);
