import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_ADDRESSES,
  ENTITY_NAME_COMPANIES,
  ENTITY_NAME_INVOICES,
  ENTITY_NAME_LOGGED_EXPENSES,
  ENTITY_NAME_QUOTES
} from "../../../../lib/constants";
import { getAsOption, isActive } from "../../../../lib/entities/addressEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createIndexedOptionsReducer from "../../../helpers/createIndexedOptionsReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import parseSubEntityPayload from "../../../helpers/parseSubEntityPayload";

const idsByCompanyIdIndexer = createEntityIndexedArrayReducer(
  entity => entity.companyId
);

const activeOptionsReducer = createIndexedOptionsReducer(
  entity => (entity && isActive(entity) && entity.companyId) || null,
  getAsOption
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByCompanyId: idsByCompanyIdIndexer(
      state.idsByCompanyId,
      changedEntities
    ),
    activeOptionsByCompanyId: activeOptionsReducer(
      state.activeOptionsByCompanyId,
      changedEntities
    )
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_ADDRESSES]
  )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_COMPANIES],
        "defaultAddress"
      )
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_QUOTES],
        "address"
      )
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_INVOICES],
        "address"
      )
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_LOGGED_EXPENSES],
        "purchaseOrder.address"
      )
    );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_ADDRESSES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer
  }
);
