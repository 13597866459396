import Image from "./Image";

const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "IMAGE"
    );
  }, callback);
};

const imageHtmlPlugin = {
  decorators: [
    {
      strategy: findImageEntities,
      component: Image
    }
  ]
};

export default imageHtmlPlugin;
