import * as PropTypes from "prop-types";

export const reactRefType = PropTypes.oneOfType([
  // Either a function
  PropTypes.func,
  // Or the instance of a DOM native element (see the note about SSR)
  PropTypes.shape({ current: PropTypes.instanceOf(Element) })
]);

export const reactNodesType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.element,
  PropTypes.elementType
]);

export const reactContentType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.element,
  PropTypes.elementType,
  PropTypes.string,
  PropTypes.number
]);

export const reactWrapperType = PropTypes.elementType;
