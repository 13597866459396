import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  getJobCurrencyTotalInvoicedExTax,
  getJobCurrencyTotalLoggedCostExTax,
} from "../../../../lib/entities/jobEntity";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobCurrencyFinalBudget } from "../../../../redux/selectors/job/selectJobCurrencyFinalBudget";
import { useFinancialSummary } from "../../../../redux/selectors/jobDetails/ui/financialSummary";
import Spacer from "../../../elements/Spacer";
import Tooltip from "../../../modules/Tooltip";
import JumbotronStatField from "../../components/JumbotronStatField";
import { useJobId } from "../../context/JobIdContext";
import {
  InlineTextSpan,
  TooltipHeading,
  TooltipTitle,
  TooltipValue,
} from "../styles";

const JumbotronMargin = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const jobCurrencyFinalBudget = useJobCurrencyFinalBudget(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const financialSummary = useFinancialSummary(jobId);

  if (
    financialSummary.displayPlannedProfitMargin ||
    financialSummary.displayProjectedProfitMargin ||
    financialSummary.displayProfitMargin
  ) {
    return (
      <JumbotronStatField title="PROFIT MARGIN">
        {financialSummary.displayPlannedProfitMargin && (
          <Tooltip
            title={getPlannedProfitMarginTooltip(
              financialSummary,
              jobCurrencyFinalBudget,
              customerCurrency,
              jobCurrency
            )}
            placement="left"
          >
            <InlineTextSpan $fitContent>
              <InlineTextSpan $bold>
                {financialSummary.jobPlannedProfitMargin}
              </InlineTextSpan>
              <InlineTextSpan $light> planned</InlineTextSpan>
            </InlineTextSpan>
          </Tooltip>
        )}
        {financialSummary.displayProjectedProfitMargin && (
          <>
            {financialSummary.displayPlannedProfitMargin && (
              <InlineTextSpan>
                <InlineTextSpan $light> / </InlineTextSpan>
              </InlineTextSpan>
            )}
            <Tooltip
              title={getProjectedProfitMarginTooltip(financialSummary)}
              placement="left"
            >
              <InlineTextSpan $fitContent>
                <InlineTextSpan $bold>
                  {financialSummary.jobProjectedProfitMargin}
                </InlineTextSpan>
                <InlineTextSpan $light> projected</InlineTextSpan>
              </InlineTextSpan>
            </Tooltip>
          </>
        )}
        {financialSummary.displayProfitMargin && (
          <>
            {(financialSummary.displayPlannedProfitMargin ||
              financialSummary.displayProjectedProfitMargin) && (
              <InlineTextSpan>
                <InlineTextSpan $light> / </InlineTextSpan>
              </InlineTextSpan>
            )}
            <Tooltip
              title={getProfitMarginTooltip(
                financialSummary,
                job,
                customerCurrency,
                jobCurrency
              )}
              placement="left"
            >
              <InlineTextSpan $fitContent>
                <InlineTextSpan $bold>
                  {financialSummary.jobProfitMargin}
                </InlineTextSpan>
                <InlineTextSpan $light> actual</InlineTextSpan>
              </InlineTextSpan>
            </Tooltip>
          </>
        )}
      </JumbotronStatField>
    );
  }
  return null;
};

export default JumbotronMargin;

const getPlannedProfitMarginTooltip = (
  financialSummary,
  jobCurrencyFinalBudget,
  customerCurrency,
  jobCurrency
) => (
  <div>
    <TooltipHeading>Planned Profit Margin</TooltipHeading>
    Profit margin on this job based
    <br />
    on your planned revenue less
    <br />
    planned costs on time and expenses.
    <Spacer h={15} />
    <table>
      <tbody>
        <tr>
          <TooltipTitle>
            {jobCurrencyFinalBudget ? "Budget" : "Total Planned Sell"}
          </TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: financialSummary.jobFinalBudget,
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipTitle>Planned Cost</TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: financialSummary.jobPlannedCost,
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipTitle>Planned Profit</TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: financialSummary.jobPlannedProfit,
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
      </tbody>
    </table>
  </div>
);

const getProjectedProfitMarginTooltip = (financialSummary) => (
  <div>
    <TooltipHeading>Projected Profit Margin</TooltipHeading>
    Predicted profit margin on this job
    <br />
    based on your planned revenue less
    <br />
    predicted costs on time and expenses.
    <Spacer h={30} />
    <TooltipHeading>
      {financialSummary.jobProjectedProfitMarginConfidence} Confidence
    </TooltipHeading>
  </div>
);

const getProfitMarginTooltip = (
  financialSummary,
  job,
  customerCurrency,
  jobCurrency
) => (
  <div>
    <TooltipHeading>Actual Profit Margin</TooltipHeading>
    Actual profit margin on this job based
    <br />
    on the total profit divided by
    <br />
    the amount invoiced on the job.
    <Spacer h={15} />
    <table>
      <tbody>
        <tr>
          <TooltipTitle>Invoiced</TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalInvoicedExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipTitle>Total Cost</TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalLoggedCostExTax(job),
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
        <tr>
          <TooltipTitle>Profit</TooltipTitle>
          <TooltipValue>
            {formatForeignCurrency(customerCurrency, {
              value: financialSummary.jobProfit,
              currency: jobCurrency,
            })}
          </TooltipValue>
        </tr>
      </tbody>
    </table>
  </div>
);
