import { produce } from "immer";
import { get, setWith, sum, unset } from "lodash-es";

import {
  getMinutes,
  getUserIdDateKeyPath,
  isActive,
  isPaused,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getPath = ({ userId, date }) => `${userId}.${date}`;

const getEntityValue = (entity, includePaused) =>
  entity && isActive(entity) && (includePaused || !isPaused(entity))
    ? getMinutes(entity)
    : 0;

const getUtilisation = (state, entity, includePaused) =>
  sum(
    get(state.blockKeysByUserIdDate, getUserIdDateKeyPath(entity), [])
      .map((blockKey) => state.byId[blockKey])
      .map((baseScheduleLoggedTime) =>
        getEntityValue(baseScheduleLoggedTime, includePaused)
      )
  );

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath = prevEntity && getPath(prevEntity);
      const newPath = newEntity && getPath(newEntity);

      if (prevPath && prevPath !== newPath) {
        const utilisation = getUtilisation(nextState, prevEntity, false);
        if (utilisation > 0)
          setWith(
            draft,
            `scheduleUtilisation.${prevPath}`,
            utilisation,
            Object
          );
        else unset(draft, `scheduleUtilisation.${prevPath}`);

        const pausedUtilisation = getUtilisation(nextState, prevEntity, true);
        if (pausedUtilisation > 0)
          setWith(
            draft,
            `scheduleUtilisationPaused.${prevPath}`,
            pausedUtilisation,
            Object
          );
        else unset(draft, `scheduleUtilisationPaused.${prevPath}`);
      }

      if (newPath) {
        const utilisation = getUtilisation(nextState, newEntity, false);
        if (utilisation > 0)
          setWith(draft, `scheduleUtilisation.${newPath}`, utilisation, Object);
        else unset(draft, `scheduleUtilisation.${newPath}`);

        const pausedUtilisation = getUtilisation(nextState, newEntity, true);
        if (pausedUtilisation > 0)
          setWith(
            draft,
            `scheduleUtilisationPaused.${newPath}`,
            pausedUtilisation,
            Object
          );
        else unset(draft, `scheduleUtilisationPaused.${newPath}`);
      }
    });
  });
