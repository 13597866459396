import { get } from "lodash-es";
import reduceChangedEntities from "../reduceChangedEntities";

const splitBlockReducer = (state, action) => {
  const { blockKey, splitDays } = action.payload;

  const scheduleLoggedTimes = get(
    state,
    `idsByBlockKey[${blockKey}]`,
    []
  ).map(id => get(state, `byId[${id}]`));

  const changedEntities = [];

  if (splitDays === 1) {
    const baseScheduleLoggedTime = scheduleLoggedTimes[0];

    changedEntities.push({
      prevEntity: baseScheduleLoggedTime,
      newEntity: {
        ...baseScheduleLoggedTime,
        scheduleBlockBaseLoggedTimeId: null
      }
    });
  }

  const newBlockScheduleLoggedTimes = scheduleLoggedTimes.slice(splitDays);

  const newBlockBaseScheduleLoggedTime = newBlockScheduleLoggedTimes[0];

  if (newBlockScheduleLoggedTimes.length === 1) {
    changedEntities.push({
      prevEntity: newBlockBaseScheduleLoggedTime,
      newEntity: {
        ...newBlockBaseScheduleLoggedTime,
        scheduleBlockBaseLoggedTimeId: null
      }
    });
  } else
    newBlockScheduleLoggedTimes.forEach(prevEntity =>
      changedEntities.push({
        prevEntity,
        newEntity: {
          ...prevEntity,
          scheduleBlockBaseLoggedTimeId: newBlockBaseScheduleLoggedTime.id
        }
      })
    );

  return reduceChangedEntities(state, changedEntities);
};

export default splitBlockReducer;
