import * as PropTypes from "prop-types";
import { entityIdType } from "../types/entityTypes";

export const accountingPlatformEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  revenueAccountCode: PropTypes.string,
  expenseAccountCode: PropTypes.string,
  accountingPlatformType: PropTypes.shape({
    id: entityIdType.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
});

export const getAccountingPlatformId = ({ id }) => id;

export const getAccountingPlatformRevenueAccountCode = ({
  revenueAccountCode
}) => revenueAccountCode;

export const getAccountingPlatformExpenseAccountCode = ({
  expenseAccountCode
}) => expenseAccountCode;

export const getAccountingPlatformTypeName = ({ accountingPlatformType }) =>
  accountingPlatformType.name;
