import React from "react";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

export default WrappedComponent => props => (
  <JobTimelineScrollContext.Consumer>
    {({
      getScrollOffsetX,
      getScrollOffsetY,
      getDateAtOffsetX,
      getDateOnPosition,
      goForward,
      goBackward,
      dayWidth
    }) => (
      <WrappedComponent
        {...{
          ...props,
          getScrollOffsetX,
          getScrollOffsetY,
          getDateAtOffsetX,
          getDateOnPosition,
          goForward,
          goBackward,
          dayWidth
        }}
      />
    )}
  </JobTimelineScrollContext.Consumer>
);
