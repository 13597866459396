import type { ConstEnum } from "@streamtimefe/types";
import EventEmitter from "eventemitter3";

export const SharedEvents = {
  ShowMaintenancePage: "showMaintenancePage",
  ShowLoadingOverlay: "showLoadingOverlay",
  HideLoadingOverlay: "hideLoadingOverlay",
  JobGroupOpenSettings: "jobGroupOpenSettings",
  JobOpenSettings: "jobOpenSettings",
  JobInvalidateMetadata: "jobInvalidateMetadata",
  JobOpenTimeline: "jobOpenTimeline",
  JobItemSubItemFocus: "jobItemSubItemFocus",
  LabelAlreadyExists: "labelAlreadyExists",
  ModalFocused: "modalFocused",
} as const;

export type TSharedEvents = ConstEnum<typeof SharedEvents>;

export const sharedEmitter = new EventEmitter<TSharedEvents>();
