import { EntityClass } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";
import { useMemo } from "react";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";

export const useAccountingPlatformEntity = createUseEntityStoreEntity(
  EntityClass.AccountingPlatform
);

export const getAccountingPlatformEntity = createGetEntityStoreEntity(
  EntityClass.AccountingPlatform
);

export function useAccountingPlatformEntities() {
  const accountingPlatforms = useEntityStoreEntities(
    EntityClass.AccountingPlatform
  );
  return useMemo(
    () => dictionaryToArray(accountingPlatforms),
    [accountingPlatforms]
  );
}
