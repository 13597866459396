import { produce } from "immer";
import { get, setWith, sortBy } from "lodash-es";

import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    touchedPurchaseOrderIds.forEach((purchaseOrderId) => {
      const purchaseOrderLineItems = get(
        nextState,
        `idsByPurchaseOrderId.${purchaseOrderId}`,
        []
      ).map((id) => nextState.byId[id]);
      const orderedPurchaseOrderLineItems = sortBy(
        purchaseOrderLineItems,
        "orderId"
      );

      let count = 0;
      orderedPurchaseOrderLineItems.forEach((purchaseOrderLineItem) => {
        count += 1;

        if (getOrderId(purchaseOrderLineItem) !== count) {
          const newPurchaseOrderLineItem = {
            ...purchaseOrderLineItem,
            orderId: count,
          };

          setWith(
            draft,
            `byId.${purchaseOrderLineItem.id}`,
            newPurchaseOrderLineItem,
            Object
          );
        }
      });
    });
  });
