import { map } from "lodash-es";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";

import {
  KeyedMatrixSeriesData,
  KeyedVectorSeriesData,
} from "../../lib/VectorMatrixSeriesData";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsOrdered,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";
import {
  useReportingSubSeriesFetching,
  useReportingSubSeriesSortedColumn,
  useReportingSubSeriesSortedTime,
} from "../../state/stores/searchSelectors";
import { addColumnContainer, columnContainer, loadingBlock } from "./Table.css";
import { TableColumn } from "./TableColumn";
import { SubTableEntityText } from "./TableEntityText";
import {
  secondLevelInitialColumn,
  secondLevelRowContainer,
  secondLevelTotalRowContainer,
} from "./TableSubRow.css";

type Props = {
  seriesData: KeyedVectorSeriesData | KeyedMatrixSeriesData;
  getSubEnitityId: ((id: string) => string) | null;
};

export function TableSubRow({ seriesData, getSubEnitityId }: Props) {
  const isFetching = useReportingSubSeriesFetching(seriesData.id);
  const searchType = useReportingSavedSegmentSearchType();

  if (isFetching) {
    return <TableSubRowLoading seriesData={seriesData} />;
  }

  switch (searchType) {
    case ReportingSearchType.ColumnSeries:
      return (
        <ColumnSecondRow
          seriesData={seriesData as KeyedVectorSeriesData}
          getSubEnitityId={getSubEnitityId}
        />
      );
    case ReportingSearchType.TimeSeries:
      return (
        <TimeSecondRow
          seriesData={seriesData as KeyedMatrixSeriesData}
          getSubEnitityId={getSubEnitityId}
        />
      );
    default:
      return null;
  }
}

type ColumnSecondRowProps = {
  seriesData: KeyedVectorSeriesData;
  getSubEnitityId: ((id: string) => string) | null;
};

function ColumnSecondRow({
  seriesData,
  getSubEnitityId,
}: ColumnSecondRowProps) {
  const subRowSortedData = useReportingSubSeriesSortedColumn(seriesData.id);
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);

  return (
    <>
      {subRowSortedData &&
        subRowSortedData.map((subRowSeriesData) => {
          return (
            <div key={subRowSeriesData.id} className={secondLevelRowContainer}>
              <div className={secondLevelInitialColumn}>
                <SubTableEntityText
                  seriesData={subRowSeriesData}
                  getEnitityId={getSubEnitityId}
                />
              </div>
              {orderedColumns.map((column) => (
                <TableColumn
                  key={column.id}
                  column={column}
                  value={subRowSeriesData.vector![column.id]}
                  rowVectorValues={subRowSeriesData.vector}
                />
              ))}
              <div className={addColumnContainer} />
            </div>
          );
        })}
      <div className={secondLevelTotalRowContainer}>
        <div className={secondLevelInitialColumn}>Total</div>
        {orderedColumns.map((column) => (
          <TableColumn
            key={column.id}
            column={column}
            value={seriesData.vector[column.id]}
            rowVectorValues={seriesData.vector}
          />
        ))}
        <div className={addColumnContainer} />
      </div>
    </>
  );
}

type TimeSecondRowProps = {
  seriesData: KeyedMatrixSeriesData;
  getSubEnitityId: ((id: string) => string) | null;
};

function TimeSecondRow({ seriesData, getSubEnitityId }: TimeSecondRowProps) {
  const subRowSortedData = useReportingSubSeriesSortedTime(seriesData.id);
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  const columns = useReportingSavedSegmentColumns();

  if (!selectedTimeId) return null;

  return (
    <>
      {subRowSortedData &&
        subRowSortedData.map((subRowSeriesData) => {
          return (
            <div key={subRowSeriesData.id} className={secondLevelRowContainer}>
              <div className={secondLevelInitialColumn}>
                <SubTableEntityText
                  seriesData={subRowSeriesData}
                  getEnitityId={getSubEnitityId}
                />
              </div>
              {map(subRowSeriesData.matrix, (vector, key) => (
                <TableColumn
                  key={key}
                  column={columns[selectedTimeId]}
                  value={vector[selectedTimeId]}
                  rowVectorValues={vector}
                />
              ))}
            </div>
          );
        })}
      <div className={secondLevelTotalRowContainer}>
        <div className={secondLevelInitialColumn}>Total</div>
        {map(seriesData.matrix, (vector, key) => (
          <TableColumn
            key={key}
            column={columns[selectedTimeId]}
            value={vector[selectedTimeId]}
            rowVectorValues={vector}
          />
        ))}
      </div>
    </>
  );
}

type TableSubRowLoadingProps = {
  seriesData: KeyedVectorSeriesData | KeyedMatrixSeriesData;
};

function TableSubRowLoading({ seriesData }: TableSubRowLoadingProps) {
  const searchType = useReportingSavedSegmentSearchType();

  function getLoadingRow(data: Record<string, any>) {
    return (
      <>
        {Object.keys(data).map((key) => (
          <div key={key} className={columnContainer}>
            <div className={loadingBlock} />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={secondLevelRowContainer}>
      <div className={secondLevelInitialColumn}>
        <div className={loadingBlock} />
      </div>
      {searchType === ReportingSearchType.ColumnSeries && (
        <>
          {getLoadingRow((seriesData as KeyedVectorSeriesData).vector)}
          <div className={addColumnContainer} />
        </>
      )}
      {searchType === ReportingSearchType.TimeSeries && (
        <>{getLoadingRow((seriesData as KeyedMatrixSeriesData).matrix)}</>
      )}
    </div>
  );
}
