import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataUserDisplayName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const UserRemovedFromJob = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" removed "}
    <b>{getMetaDataUserDisplayName(activityEntry)}</b>
    {" from this job"}
  </SystemMessage>
);

UserRemovedFromJob.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default UserRemovedFromJob;
