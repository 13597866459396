import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectJobItemRoleIdsByJobItemId = createCachedSelector(
  [selectStateJobItemRoles, selectPropsJobItemId],
  (jobItemRoles, jobItemId) => jobItemRoles.idsByJobItemId[jobItemId] ?? []
)(selectPropsJobItemId);

export function useJobItemRoleIdsByJobItemId(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectJobItemRoleIdsByJobItemId(state, { jobItemId })
  );
}
