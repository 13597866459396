import { call, put } from "redux-saga/effects";
import { deleteInvoiceLineItemAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  INVOICE_LINE_ITEM_DELETE,
  INVOICE_LINE_ITEM_DELETE_ERROR,
  INVOICE_LINE_ITEM_DELETED,
  INVOICE_LINE_ITEM_DELETING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* remove(action) {
  try {
    const { id } = action.payload;

    if (id < 0) return;

    yield put(createAction(INVOICE_LINE_ITEM_DELETING, { id }));

    const data = yield call(deleteInvoiceLineItemAPI, id);

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(createAction(INVOICE_LINE_ITEM_DELETED, { id }));

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_DELETE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchInvoiceLineItemDelete() {
  yield takeLatestBy(
    INVOICE_LINE_ITEM_DELETE,
    remove,
    action => action.payload.id
  );
}
