import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { currencyEntityType } from "../../../../lib/entities/currencyEntity";
import { isInvoiceTypeCreditNote } from "../../../../lib/entities/invoiceEntity";
import {
  entityFieldDecimalType,
  entityIdType
} from "../../../../lib/types/entityTypes";
import {
  COMMERCIAL_DOCUMENT_TYPES,
  getCurrency,
  getExchangeRate,
  getJobId
} from "../../../../lib/ui/commercialDocument";
import { IconButton } from "../../../elements/Button";
import AddCircleIcon from "../../../elements/Icons/AddCircleIcon";
import consumeCommercialDocument from "../CommercialDocumentContext/Consumers/consumeCommercialDocument";
import AddLineMenu from "./index";

const mapContext = ({
  id,
  document,
  documentMethods,
  rateCardId,
  commercialDocumentType,
  actions: {
    doAddLine,
    doAddMasterJobItem,
    doAddMasterLoggedExpense,
    doAddFromJobPlan
  }
}) => ({
  commercialDocumentType,
  lineItemParentId: documentMethods.getLineItemParentId(document),
  jobId: getJobId(document),
  documentCurrency: getCurrency(document),
  exchangeRate: getExchangeRate(document),
  rateCardId,
  doAddLine,
  doAddMasterJobItem,
  doAddMasterLoggedExpense,
  doAddFromJobPlan,
  negativeValue: Boolean(
    commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.INVOICE &&
      isInvoiceTypeCreditNote(document)
  )
});

class AddLineButton extends React.PureComponent {
  static propTypes = {
    commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
      .isRequired,
    lineItemParentId: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    rateCardId: entityIdType.isRequired,
    documentCurrency: currencyEntityType.isRequired,
    exchangeRate: entityFieldDecimalType.isRequired,
    orderId: PropTypes.number.isRequired,
    large: PropTypes.bool,
    bottom: PropTypes.bool,
    doAddLine: PropTypes.func.isRequired,
    doAddMasterJobItem: PropTypes.func.isRequired,
    doAddMasterLoggedExpense: PropTypes.func.isRequired,
    doAddFromJobPlan: PropTypes.func.isRequired,
    negativeValue: PropTypes.bool
  };

  static defaultProps = {
    large: false,
    bottom: false,
    negativeValue: false
  };

  state = {
    anchorEl: null
  };

  openMenu = e => {
    this.setState({
      anchorEl: e.target.closest("button")
    });
  };

  closeMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  addHeading = () => {
    const { lineItemParentId, orderId, doAddLine } = this.props;

    doAddLine(lineItemParentId, orderId, true);
  };

  addLine = () => {
    const { lineItemParentId, orderId, doAddLine } = this.props;

    doAddLine(lineItemParentId, orderId, false);
  };

  addItem = masterJobItemId => {
    const { lineItemParentId, orderId, doAddMasterJobItem } = this.props;

    doAddMasterJobItem(lineItemParentId, orderId, masterJobItemId);
  };

  addExpense = masterLoggedExpenseId => {
    const { lineItemParentId, orderId, doAddMasterLoggedExpense } = this.props;

    doAddMasterLoggedExpense(lineItemParentId, orderId, masterLoggedExpenseId);
  };

  addFromJobPlan = (entityId, optionType) => {
    const { lineItemParentId, orderId, doAddFromJobPlan } = this.props;

    doAddFromJobPlan(lineItemParentId, orderId, entityId, optionType);
  };

  render() {
    const {
      jobId,
      commercialDocumentType,
      rateCardId,
      documentCurrency,
      exchangeRate,
      large,
      bottom,
      negativeValue
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        <Button
          className={anchorEl ? "active" : ""}
          onClick={this.openMenu}
          style={large ? { width: 50, height: 50 } : null}
        >
          <AddCircleIcon size={large ? 24 : null} />
        </Button>
        <AddLineMenu
          anchorEl={anchorEl}
          commercialDocumentType={commercialDocumentType}
          jobId={jobId}
          rateCardId={rateCardId}
          currency={documentCurrency}
          exchangeRate={exchangeRate}
          negativeValue={negativeValue}
          onClose={this.closeMenu}
          onPickHeading={this.addHeading}
          onPickNewLine={this.addLine}
          onPickItem={this.addItem}
          onPickExpense={this.addExpense}
          onPickFromJobPlan={this.addFromJobPlan}
          PopoverProps={
            bottom
              ? {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  }
                }
              : null
          }
        />
      </>
    );
  }
}

export default consumeCommercialDocument(mapContext)(AddLineButton);

const Button = styled(IconButton).attrs({
  className: "AddLineButton"
})`
  color: var(--color-gray);
  opacity: 1;
  transition: var(--transition-mui-opacity);
  &:hover {
    color: var(--color-gray-medium);
  }
  &.active {
    color: var(--color-charcoal);
  }
`;
