import clsx from "clsx";
import { isNil } from "lodash-es";
import { useDispatch } from "react-redux";
import { Tooltip } from "st-shared/components";
import { costingMethodObj } from "st-shared/entities";
import {
  formatForeignCurrency,
  num,
  NumberFormats,
  NumFormats,
  numValue,
} from "st-shared/lib";
import { VarianceIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";
import { theme } from "st-shared/theme";

import { JOB_ITEM_SET_TOTAL } from "../../../../lib/constants";
import { getRateCardId, isArchived } from "../../../../lib/entities/jobEntity";
import {
  getMasterJobItemId,
  getTotalPlannedMoney,
} from "../../../../lib/entities/jobItemEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobDetailsCurrentFocusJobItemId } from "../../../../redux/selectors/jobDetails/ui/currentFocus";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { useRateCard } from "../../../../redux/selectors/rateCard";
import { useMasterJobItemRate } from "../../../../state/entities/itemRate/selectors/selectMasterJobItemRate";
import { useMasterJobItem } from "../../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { useJobId } from "../../context/JobIdContext";
import { useJobItemId } from "../../context/JobItemIdContext";
import {
  tooltipTitleCss,
  varianceButtonTextCss,
  varianceButtonTextFocusedCss,
  varianceContainerCss,
  varianceIconCss,
} from "./styles.css";

interface Props {
  containerHovered: boolean;
}

export function MasterItemValueCalculatedSellVariance({
  containerHovered,
}: Props) {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const job = useJob(jobId);
  const masterJobItemId = getMasterJobItemId(jobItem);
  const masterJobItem = useMasterJobItem(masterJobItemId);
  const itemRate = useMasterJobItemRate(masterJobItemId, getRateCardId(job));
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const rateCard = useRateCard(getRateCardId(job));

  const jobItemTotal = numValue(getTotalPlannedMoney(jobItem));
  const masterItemTotal = numValue(itemRate);

  const focusedJobItemId = useJobDetailsCurrentFocusJobItemId();
  const isFocused = jobItemId === focusedJobItemId;

  const dispatch = useDispatch();

  function setMasterItemTotal() {
    if (isFocused) {
      dispatch(
        createAction(JOB_ITEM_SET_TOTAL, {
          jobItemId,
          value: masterItemTotal,
        })
      );
    }
  }

  if (
    isArchived(job) ||
    !costingMethodObj(jobItem.costingMethod).isValueCalculatedSell() ||
    masterItemTotal === 0 ||
    jobItemTotal === 0 ||
    isNil(masterJobItem)
  ) {
    return null;
  }

  let totalValue = 0;
  let down = false;
  let percentage = 0;

  if (masterItemTotal > jobItemTotal) {
    totalValue = masterItemTotal - jobItemTotal;
    down = true;
    percentage = Math.abs((masterItemTotal - jobItemTotal) / jobItemTotal);
  } else {
    totalValue = jobItemTotal - masterItemTotal;
    percentage = Math.abs((jobItemTotal - masterItemTotal) / masterItemTotal);
  }

  if (percentage === 0) return null;

  const masterItemTotalFormatted = formatForeignCurrency(customerCurrency, {
    value: masterItemTotal,
    hideLongSymbols: true,
    currency: jobCurrency,
    format: NumberFormats.NoDecimal,
  });

  const jobItemTotalFormatted = formatForeignCurrency(customerCurrency, {
    value: totalValue,
    hideLongSymbols: true,
    currency: jobCurrency,
    format: NumberFormats.NoDecimal,
  });

  if (percentage > 1) {
    percentage = Number(num(percentage).format(NumFormats.TwoDecimals));
  }
  let formattedPercentage = num(percentage).format(NumFormats.Percentage);
  if (percentage > 9.99) {
    formattedPercentage = `>${num(9.99).format(NumFormats.Percentage)}`;
  }

  const direction = down ? "down" : "up";
  const color =
    direction === "up" ? theme.color.indicatorGreen : theme.color.indicatorRed;

  function renderTooltip() {
    return (
      <>
        <strong>
          The fixed item total is {jobItemTotalFormatted}{" "}
          {down ? "less" : "more"}
        </strong>{" "}
        than the <strong>{masterItemTotalFormatted}</strong> rate set on the{" "}
        {rateCard.name} rate card.
        {isFocused && (
          <>
            <br />
            <br />
            Click to update your fixed price to{" "}
            <strong>{masterItemTotalFormatted}</strong>.
          </>
        )}
      </>
    );
  }

  function renderComponent() {
    if (isFocused || containerHovered) {
      return (
        <span
          className={clsx(
            varianceButtonTextCss,
            isFocused && varianceButtonTextFocusedCss
          )}
          style={{ color, left: 24 }}
          onClick={setMasterItemTotal}
        >
          {formattedPercentage}
        </span>
      );
    }
    return null;
  }

  return (
    <Tooltip
      title={<div className={tooltipTitleCss}>{renderTooltip()}</div>}
      placement="top"
    >
      <div className={varianceContainerCss}>
        <div className={varianceIconCss}>
          <VarianceIcon direction={direction} size={9} />
        </div>
        {renderComponent()}
      </div>
    </Tooltip>
  );
}
