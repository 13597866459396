import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const ScheduleIcon = props => (
  <SvgIcon {...props} viewBox="0 0 21.74 20.1">
    <path d="M12,6.68V0h9.74V6.68ZM0,12V0H9.71V12ZM12,20.1V8.94h9.74V20.08Zm-12,0V14.33H9.71V20.1Z" />
  </SvgIcon>
);

export default StyledIcon(ScheduleIcon);
