import { Entity } from "@streamtimefe/entities";
import { useIsJobEditable, useJobPhaseActions } from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { useMutation } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type TJobPhaseSaveContext = {
  saveJobPhase: () => void;
  isJobPhaseSaving: boolean;
};

export const JobPhaseSaveContext = createContext<TJobPhaseSaveContext>(
  undefined as unknown as TJobPhaseSaveContext
);

export function useJobPhaseSave() {
  const context = useContext(JobPhaseSaveContext);
  if (!context) {
    throw new Error(`Missing JobPhaseSaveContext`);
  }
  return context;
}

export function JobPhaseSaveProvider({ children }: PropsWithChildren) {
  const { getJobPhase, unsetModifiedJobPhase } = useJobPhaseActions();

  const isJobEditable = useIsJobEditable();

  const [queueUpdate, setQueueUpdate] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState(false);

  function onSuccess() {
    if (!queueUpdate) {
      unsetModifiedJobPhase();
    } else {
      setQueueUpdate(false);
      setTimeout(() => {
        setSaveTimeout(true);
      }, 50);
    }
  }

  const { mutate: mutateCreate, isPending: isCreatePending } = useMutation({
    mutationFn: webapi.jobPhases.create,
    onSuccess,
  });

  const { mutate: mutateUpdate, isPending: isUpdatePending } = useMutation({
    mutationFn: webapi.jobPhases.update,
    onSuccess,
  });

  const save = useCallback(() => {
    const jobPhase = getJobPhase();
    if (!jobPhase) return;
    if (!isJobEditable) return;

    if (isCreatePending || isUpdatePending) {
      setQueueUpdate(true);
      return;
    }

    if (Entity.isNew(jobPhase)) {
      mutateCreate({ jobPhase });
    } else {
      mutateUpdate({ jobPhase });
    }
  }, [
    getJobPhase,
    isCreatePending,
    isJobEditable,
    isUpdatePending,
    mutateCreate,
    mutateUpdate,
  ]);

  useEffect(() => {
    if (saveTimeout) {
      setSaveTimeout(false);
      save();
    }
  }, [save, saveTimeout]);

  const context = useMemo(() => {
    return {
      saveJobPhase: save,
      isJobPhaseSaving: isCreatePending || isUpdatePending,
    };
  }, [isCreatePending, isUpdatePending, save]);

  return (
    <JobPhaseSaveContext.Provider value={context}>
      {children}
    </JobPhaseSaveContext.Provider>
  );
}
