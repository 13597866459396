import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import { JOB_PHASE_EDIT, JOB_PHASE_SET_NAME } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* setName(action) {
  try {
    const { jobPhaseId, value } = action.payload;

    const jobPhase = yield select(selectJobPhaseById, { id: jobPhaseId });

    const newJobPhase = produce(jobPhase, draft => {
      draft.name = value;
    });

    yield put(
      createAction(JOB_PHASE_EDIT, {
        jobPhaseId,
        jobPhase: newJobPhase
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobPhaseSetName() {
  yield takeLatestBy(
    [JOB_PHASE_SET_NAME],
    setName,
    action => action.payload.jobPhaseId
  );
}
