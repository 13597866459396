import { PermissionsConsumer } from "st-shared/stores";

export default (WrappedComponent) => (props) => (
  <PermissionsConsumer>
    {({ canEditTeamLoggedTimes, canEditJobs }) => (
      <WrappedComponent
        {...{
          ...props,
          canEditTeamLoggedTimes,
          canEditJobs,
          readOnly: !canEditJobs,
        }}
      />
    )}
  </PermissionsConsumer>
);
