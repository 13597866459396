import * as PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import styled from "styled-components";
import {
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT
} from "../../../lib/constants";
import { isCapturePointerElement } from "../../../lib/eventTargets";
import JobItemBarDragHandlers from "../../modules/JobItem/JobItemBarDragHandlers";
import JobItemBarResizeHandlers from "../../modules/JobItem/JobItemBarResizeHandlers";
import JobItemPaintHandlers from "../../modules/JobItem/JobItemPaintHandlers";
import DependancyContextMenuHandlers from "../../modules/JobItemDependancies/DependancyContextMenuHandlers";
import DependancyHandlers from "../../modules/JobItemDependancies/DependancyHandlers";
import ClickHandlers from "./ClickHandlers";
import KeyHandlers from "./KeyHandlers";
import ScrollContextHandler from "./ScrollContextHandler";

class JobTimelineEventHandlers extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    onPointerDown: PropTypes.func,
    onPointerMove: PropTypes.func,
    onPointerUp: PropTypes.func,
    onLostPointerCapture: PropTypes.func
  };

  static defaultProps = {
    onPointerDown: null,
    onPointerMove: null,
    onPointerUp: null,
    onLostPointerCapture: null
  };

  onPointerDown = e => {
    const { onPointerDown } = this.props;

    if (!isCapturePointerElement(e)) return false;

    if (e.button !== 0) return false;

    if (e.target.closest("button")) return false;

    this.ref.current.setPointerCapture(e.pointerId);
    return onPointerDown && onPointerDown(e);
  };

  onPointerMove = e => {
    const { onPointerMove } = this.props;

    return onPointerMove && onPointerMove(e);
  };

  onPointerUp = e => {
    const { onPointerUp } = this.props;

    this.ref.current.releasePointerCapture(e.pointerId);

    return onPointerUp && onPointerUp(e);
  };

  onLostPointerCapture = e => {
    const { onLostPointerCapture } = this.props;

    return onLostPointerCapture && onLostPointerCapture(e);
  };

  get handlers() {
    return {
      onPointerDown: this.onPointerDown,
      onPointerMove: this.onPointerMove,
      onPointerUp: this.onPointerUp,
      onLostPointerCapture: this.onLostPointerCapture
    };
  }

  render() {
    const { props, ref, handlers } = this;

    return <Wrapper {...{ ...props, ref, ...handlers }} />;
  }
}

export default compose(
  ScrollContextHandler,
  KeyHandlers,
  ClickHandlers,
  DependancyContextMenuHandlers,
  DependancyHandlers,
  JobItemBarResizeHandlers,
  JobItemBarDragHandlers,
  JobItemPaintHandlers
)(JobTimelineEventHandlers);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &.dragging {
    cursor: grabbing;
  }
  &.resizing {
    &.${DATA_VALUE_HANDLE_LEFT}, .${DATA_VALUE_HANDLE_RIGHT} {
      cursor: ew-resize;
    }
  }
  &.dragging,
  &.resizing {
    .jobTimelineItemRect {
      transition: none;
    }
  }
  &.creatingDependancy {
    cursor: cell;
  }
  &.invalidDependancy {
    cursor: no-drop;
  }
`;
