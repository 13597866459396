import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateJobDetailsUi } from "./index";

export const selectJobTeamSummary = createSelector(
  [selectStateJobDetailsUi],
  (jobDetails) => ({
    users: get(jobDetails, `teamSummary.jobUsers`, []),
    roles: get(jobDetails, `teamSummary.jobRoles`, []),
  })
);

export const useJobTeamSummary = () => useSelector(selectJobTeamSummary);
