import numbro from "numbro";

import { NumberFormat } from "./NumberFormat";

numbro.setDefaults(NumberFormat.Default);

export { numbro };

export * from "./currency";
export * from "./math";
export * from "./NumberFormat";
export * from "./pad";
export * from "./percentage";
