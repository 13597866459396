import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { userOptionType } from "../../../lib/entities/userEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import { selectActiveUserOptionsGroupedByBranch } from "../../../redux/selectors/userSelectors";
import SearchMenu from "../SearchMenu";
import GroupBranchedUserOption from "./GroupBranchedUserOption";

const mapState = (state) => ({
  activeUserOptions: selectActiveUserOptionsGroupedByBranch(state),
});

const mapDispatch = null;

class UserPickerSubMenu extends React.PureComponent {
  static propTypes = {
    activeUserOptions: PropTypes.arrayOf(userOptionType).isRequired,
    anchorEl: domNodeType,
    onPickUser: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
  };

  getNoResultsMessage = (searchQuery) =>
    `Hmm, looks like no users match ‘${searchQuery}’. Perhaps try a different search term.`;

  render() {
    const { anchorEl, onPickUser, onMouseEnter, activeUserOptions } =
      this.props;

    return (
      <StyledSearchMenu
        anchorEl={anchorEl}
        options={activeUserOptions}
        onMouseEnter={onMouseEnter}
        transformOrigin={{
          vertical: -10,
          horizontal: -10,
        }}
        hideBackdrop
        HeaderComponentProps={{
          placeholder: "Search by name, branch or role...",
          getNoResultsMessage: this.getNoResultsMessage,
        }}
      >
        {({ key, isHeading, value }) => (
          <GroupBranchedUserOption
            key={key}
            isHeading={isHeading}
            value={value}
            UserOptionProps={
              !isHeading
                ? {
                    userId: key,
                    onPickUser,
                  }
                : null
            }
          />
        )}
      </StyledSearchMenu>
    );
  }
}

export default connect(mapState, mapDispatch)(UserPickerSubMenu);

const StyledSearchMenu = styled(SearchMenu)`
  pointer-events: none;
  .MuiPaper-root {
    min-width: 280px;
    max-width: 280px;
    max-height: 300px;
    pointer-events: auto;
  }
`;
