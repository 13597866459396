import { Entity } from "@streamtimefe/entities";

import reduceChangedEntities from "../reduceChangedEntities";

const duplicateBlockReducer = (state, action) => {
  const { newBlockKey, blockKey } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  return reduceChangedEntities(
    state,
    prevScheduleLoggedTimeIds
      .map((id) => state.byId[id])
      .map((entity, index) => ({
        newEntity: {
          ...entity,
          id: index === 0 ? newBlockKey : Entity.temporaryId(),
          billed: false,
          scheduleBlockBaseLoggedTimeId: newBlockKey,
          repeatingLoggedTimeId: null,
        },
      }))
  );
};

export default duplicateBlockReducer;
