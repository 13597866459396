import { keyBy } from "lodash-es";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  LINE_ITEMS_SORT_START,
  QUOTE_LINE_ITEM_SORT_START
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import { selectQuoteLineItemsByQuoteId } from "../../selectors/quoteLineItemSelectors";
import { selectQuoteUi } from "../../selectors/quoteSelectors";

function* startSortLineItems(action) {
  const { quoteId } = yield select(selectQuoteUi);

  if (!quoteId) return;

  try {
    const quoteLineItems = yield select(selectQuoteLineItemsByQuoteId, {
      quoteId
    });

    const snapshotId = createSnapshotId();
    const snapshot = keyBy(quoteLineItems, "id");

    yield put(
      createAction(QUOTE_LINE_ITEM_SORT_START, {
        quoteId,
        snapshotId,
        data: snapshot
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLineItemsSortStart() {
  yield takeLatest(LINE_ITEMS_SORT_START, startSortLineItems);
}
