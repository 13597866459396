import * as styles from "./CommentViewer.css";
import DraftJsContent from "./DraftJsContent";

type Props = {
  html: string;
};

export function CommentViewer({ html, ...props }: Props) {
  return (
    <DraftJsContent
      className={styles.container}
      {...props}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
