import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatCurrency } from "st-shared/lib";
import { getPermissionsSnapshot } from "st-shared/stores";

import { currencyEntityType } from "../../../lib/entities/currencyEntity";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../lib/types/entityTypes";
import { selectCurrencyById } from "../../../redux/selectors/currenciesSelectors";
import {
  selectJobCurrencyById,
  selectJobExchangeRateById,
} from "../../../redux/selectors/rateCardSelectors";

const mapState = (state, props) => ({
  jobCurrency: selectJobCurrencyById(state, props),
  jobExchangeRate: selectJobExchangeRateById(state, props),
  foreignCurrency: selectCurrencyById(state, props),
  canViewJobFinancials: getPermissionsSnapshot().canViewJobFinancials,
});

const JobForeignCurrency = ({
  jobCurrency,
  jobExchangeRate,
  foreignCurrency,
  canViewJobFinancials,
  value,
  hideLongSymbols,
  hideCurrencyCode,
  format,
  ...props
}) => {
  if (!canViewJobFinancials) return "";

  const formattedValue = formatCurrency(
    value,
    foreignCurrency.symbol,
    format,
    hideLongSymbols
  );

  if (hideCurrencyCode || jobCurrency.id === foreignCurrency.id)
    return formattedValue;

  return `(${foreignCurrency.id}) ${formattedValue}`;
};

JobForeignCurrency.propTypes = {
  id: entityIdType.isRequired,
  jobCurrency: currencyEntityType.isRequired,
  jobExchangeRate: entityFieldDecimalType.isRequired,
  value: entityFieldDecimalType.isRequired,
  currencyId: PropTypes.string.isRequired,
  foreignCurrency: currencyEntityType.isRequired,
  canViewJobFinancials: PropTypes.bool.isRequired,
  format: PropTypes.string,
  hideLongSymbols: PropTypes.bool,
  hideCurrencyCode: PropTypes.bool,
};

JobForeignCurrency.defaultProps = {
  format: undefined,
  hideLongSymbols: false,
  hideCurrencyCode: false,
};

export default connect(mapState)(JobForeignCurrency);
