import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectInvoiceIdsByJobIdDate } from "../../../redux/selectors/invoiceSelectors";
import { selectJobMilestoneIdsByJobIdDate } from "../../../redux/selectors/jobMilestoneSelectors";
import DatesConsumer from "../JobTimelineScrollProvider/DatesConsumer";

const SelectMilestoneIds = connect((state, props) => ({
  jobMilestoneIds: selectJobMilestoneIdsByJobIdDate(state, props),
  invoiceIds: selectInvoiceIdsByJobIdDate(state, props)
}))(({ jobMilestoneIds, invoiceIds, children }) =>
  children(jobMilestoneIds, invoiceIds)
);

class DailyMilestones extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    children: PropTypes.func.isRequired
  };

  render() {
    const { jobId, children } = this.props;

    return (
      <DatesConsumer>
        {({ date, left, width }) => (
          <SelectMilestoneIds key={date} date={date} jobId={jobId}>
            {(jobMilestoneIds, invoiceIds) =>
              children({ date, left, width, jobMilestoneIds, invoiceIds })
            }
          </SelectMilestoneIds>
        )}
      </DatesConsumer>
    );
  }
}

export default DailyMilestones;
