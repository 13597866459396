import {
  ENTITY_NAME_COMPANY_OPTIONS,
  ENTITY_NAME_JOB_PHASE_OPTIONS,
  ENTITY_NAME_LABEL_OPTIONS
} from "../constants";
import API from "./index";

export const fetchCompanyOptionsApi = () =>
  API.fetch(`/options/companies`).then(({ data }) => ({
    [ENTITY_NAME_COMPANY_OPTIONS]: data
  }));

export const fetchJobPhasesOptionsApi = () =>
  API.fetch(`/options/job_phases`).then(({ data }) => ({
    [ENTITY_NAME_JOB_PHASE_OPTIONS]: data
  }));

export const fetchLabelOptionsApi = labelTypeId =>
  API.fetch(`/options/labels?label_type_id=${labelTypeId}`).then(
    ({ data }) => ({
      [ENTITY_NAME_LABEL_OPTIONS]: data
    })
  );
