import type { TValueMatchTypeEnum } from "./ValueMatchType";
import { ValueMatchTypeEnum } from "./ValueMatchType";

export function requiresValue(valueMatchType: TValueMatchTypeEnum) {
  switch (valueMatchType) {
    case ValueMatchTypeEnum.Equals:
    case ValueMatchTypeEnum.NotEquals:
    case ValueMatchTypeEnum.Contains:
    case ValueMatchTypeEnum.NotContains:
    case ValueMatchTypeEnum.GreaterThan:
    case ValueMatchTypeEnum.LessThan:
    case ValueMatchTypeEnum.Between:
    case ValueMatchTypeEnum.GreaterThanOrEquals:
    case ValueMatchTypeEnum.LessThanOrEquals:
      return true;
    default:
      return false;
  }
}
