import type { TUser } from "@streamtimefe/entities";
import { runtimeEnv } from "@streamtimefe/environment";
import type { AxiosResponse } from "axios";

import type { TEntityList } from "../../types";
import { API, getAuthToken } from "../api";
import { WebApiRoot } from "./webApiRoot";

export const AuthenticationRoot = WebApiRoot + "/authentication";

export interface AuthResponse {
  authToken: AuthToken;
}

export interface AuthBootstrapResponse extends AuthResponse {
  bootstrap: TEntityList;
}

export interface AuthToken {
  expiresAt: string;
  isAdminUser: boolean;
  token: string;
  user: TUser;
}

export async function get<B extends boolean>(
  bootstrap: B
): Promise<
  B extends true
    ? AxiosResponse<AuthBootstrapResponse, any>
    : AxiosResponse<AuthResponse, any>
>;
export async function get(bootstrap: boolean) {
  if (bootstrap) {
    return API.get<AuthBootstrapResponse>(AuthenticationRoot, {
      bootstrap: true,
    });
  }
  return API.get<AuthResponse>(AuthenticationRoot);
}

export async function login(
  domain: string,
  email: string,
  password: string,
  remember: boolean,
  twoFactorAuthenticationCode?: string
) {
  const body: any = {
    domain,
    email,
    password,
    remember,
  };

  if (twoFactorAuthenticationCode) {
    body["twoFactorAuthenticationCode"] = twoFactorAuthenticationCode;
  }

  return API.post<AuthBootstrapResponse>(AuthenticationRoot, body);
}

export async function bootstrap() {
  if (import.meta.env.DEV) {
    if (!getAuthToken()) return await bootstrapDev();
    try {
      return await get(true);
    } catch (error) {
      return await bootstrapDev();
    }
  }
  return await get(true);
}

async function bootstrapDev() {
  return login(
    runtimeEnv()?.VITE_DEV_AUTH_DOMAIN!,
    runtimeEnv()?.VITE_DEV_AUTH_EMAIL!,
    runtimeEnv()?.VITE_DEV_AUTH_PASSWORD!,
    true
  );
}
