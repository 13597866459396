import { produce } from "immer";
import { get, setWith, union, unset } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
  JOB_ITEM_DELETE,
  JOB_ITEM_DELETE_ERROR,
  JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR,
  JOB_ITEM_DEPENDANCY_CREATE_SAVED,
  JOB_ITEM_DEPENDANCY_DELETE,
  JOB_ITEM_DEPENDANCY_DELETE_ERROR,
  JOB_ITEM_DEPENDANCY_SEGMENT_DIRECTION,
  JOB_ITEM_DRAG,
  JOB_ITEM_DRAG_CANCEL,
  JOB_ITEM_RESIZE,
  JOB_ITEM_RESIZE_CANCEL,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVE_ERROR,
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR,
} from "../../../lib/constants";
import {
  createBridgeJobItemDependancySegment,
  createCircleJobItemDependancySegment,
} from "../../../lib/entities/jobItemDependancyEntity";
import { setPush } from "../../../lib/objects";
import createCrossReducer from "../../helpers/createCrossReducer";
import {
  selectIsDependancyConflictById,
  selectJobItemDependancyById,
  selectJobItemDependancyChildJobItemById,
  selectJobItemDependancyIdsByJobId,
  selectJobItemDependancyParentJobItemById,
} from "../../selectors/jobItemDependancySelectors";
import {
  selectJobItemById,
  selectOrderedJobPhaseJobItemIdsByJobId,
} from "../../selectors/jobItemSelectors";

const reduceTouchedJobIds = (state, touchedJobIds) =>
  produce(state, (draft) => {
    touchedJobIds.forEach((jobId) => {
      const jobItemDependancyIds = selectJobItemDependancyIdsByJobId(state, {
        jobId,
      });

      if (!jobItemDependancyIds.length) {
        unset(
          draft,
          `entities.jobItemDependancies.jobItemDependancySegments.byJobId.${jobId}`
        );
        return;
      }

      setWith(
        draft,
        `entities.jobItemDependancies.jobItemDependancySegments.byJobId.${jobId}`,
        { byJobPhaseId: {}, byJobItemId: {} },
        Object
      );

      const orderedJobPhaseJobItemIds = selectOrderedJobPhaseJobItemIdsByJobId(
        state,
        { jobId }
      );

      jobItemDependancyIds
        .map((id) => selectJobItemDependancyById(state, { id }))
        .forEach((jobItemDependancy) => {
          const { id } = jobItemDependancy;
          const isConflict = selectIsDependancyConflictById(state, { id });
          const parentJobItem = selectJobItemDependancyParentJobItemById(
            state,
            { id }
          );
          const childJobItem = selectJobItemDependancyChildJobItemById(state, {
            id,
          });

          let startDependancySegment = null;
          let endDependancySegment = null;

          // traverse ordered items on job and set dependancy segments
          orderedJobPhaseJobItemIds.forEach(({ jobPhaseId, jobItemIds }) => {
            if (endDependancySegment) return;

            if (jobPhaseId !== 0) {
              if (startDependancySegment) {
                setPush(
                  draft,
                  `entities.jobItemDependancies.jobItemDependancySegments.byJobId.${jobId}.byJobPhaseId.${jobPhaseId}`,
                  createBridgeJobItemDependancySegment(startDependancySegment)
                );
              }
            }

            jobItemIds.forEach((jobItemId) => {
              if (endDependancySegment) return;

              const isParent = parentJobItem
                ? jobItemId === parentJobItem.id
                : false;
              const isChild = childJobItem
                ? jobItemId === childJobItem.id
                : false;

              if (!isParent && !isChild) {
                if (startDependancySegment) {
                  setPush(
                    draft,
                    `entities.jobItemDependancies.jobItemDependancySegments.byJobId.${jobId}.byJobItemId.${jobItemId}`,
                    createBridgeJobItemDependancySegment(startDependancySegment)
                  );
                }
                return;
              }

              const direction = startDependancySegment
                ? JOB_ITEM_DEPENDANCY_SEGMENT_DIRECTION.UP
                : JOB_ITEM_DEPENDANCY_SEGMENT_DIRECTION.DOWN;

              const segment = createCircleJobItemDependancySegment(
                jobItemDependancy,
                parentJobItem,
                childJobItem,
                isParent,
                direction,
                isConflict
              );

              setPush(
                draft,
                `entities.jobItemDependancies.jobItemDependancySegments.byJobId.${jobId}.byJobItemId.${jobItemId}`,
                segment
              );

              if (!startDependancySegment) startDependancySegment = segment;
              else endDependancySegment = segment;
            });
          });
        });
    });
  });

const receiveEntitiesReducer = (state, action) => {
  const touchedJobIds = union(
    get(action.payload, ENTITY_NAME_JOB_ITEMS, []).map(
      (entity) => entity.jobId
    ),
    get(action.payload, ENTITY_NAME_JOB_PHASES, []).map(
      (entity) => entity.jobId
    ),
    get(action.payload, ENTITY_NAME_JOB_ITEM_DEPENDANCIES, []).map(
      (entity) => entity.jobId
    )
  );

  return reduceTouchedJobIds(state, touchedJobIds);
};

const changeJobItemReducer = (state, action) => {
  const { jobItemId } = action.payload;

  if (jobItemId) {
    const jobItem = selectJobItemById(state, { id: jobItemId });
    if (jobItem && jobItem.jobId) {
      return reduceTouchedJobIds(state, [jobItem.jobId]);
    }
  }

  return state;
};

const removeJobItemReducer = (state, action) => {
  const { jobItem } = action.payload;

  if (jobItem) {
    const { jobId } = jobItem;

    return reduceTouchedJobIds(state, [jobId]);
  }

  return state;
};

const changeJobReducer = (state, action) => {
  const { jobId } = action.payload;

  if (jobId) return reduceTouchedJobIds(state, [jobId]);

  return state;
};

const undoDeleteJobItemDependencyReducer = (state, action) => {
  const { jobItemDependancy } = action.payload;

  return changeJobReducer(state, { payload: jobItemDependancy });
};

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntitiesReducer,
  [JOB_ITEM_DEPENDANCY_CREATE_SAVED]: changeJobReducer,
  [JOB_ITEM_DRAG]: changeJobItemReducer,
  [JOB_ITEM_RESIZE]: changeJobItemReducer,
  [JOB_ITEM_SAVE_EDIT]: changeJobItemReducer,
  [JOB_ITEM_DRAG_CANCEL]: changeJobItemReducer,
  [JOB_ITEM_RESIZE_CANCEL]: changeJobItemReducer,
  [JOB_ITEM_DELETE]: removeJobItemReducer,
  [JOB_ITEM_DELETE_ERROR]: removeJobItemReducer,
  [SCHEDULE_JOB_BAR_DRAG]: changeJobReducer,
  [SCHEDULE_JOB_BAR_DRAG_CANCEL]: changeJobReducer,
  [SCHEDULE_JOB_SHIFT_DURATION_ERROR]: changeJobReducer,
  [JOB_ITEM_SAVE_ERROR]: changeJobItemReducer,
  [JOB_ITEM_DEPENDANCY_DELETE]: changeJobReducer,
  [JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR]: changeJobReducer,
  [JOB_ITEM_DEPENDANCY_DELETE_ERROR]: undoDeleteJobItemDependencyReducer,
});
