import numeral from "numeral";
import { Tooltip } from "st-shared/components";
import { costingMethodObj } from "st-shared/entities";
import { asDecimal, formatForeignCurrency, NumberFormats } from "st-shared/lib";
import { VarianceIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";

import { isArchived } from "../../../../lib/entities/jobEntity";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { useRoleRateByRoleIdRateCardId } from "../../../../redux/selectors/rate/selectRoleRateByRoleIdRateCardId";
import { useRateCard } from "../../../../redux/selectors/rateCard";
import { useRole } from "../../../../redux/selectors/role";
import { useJobItemRole } from "../../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { useJobId } from "../../context/JobIdContext";
import { useJobItemId } from "../../context/JobItemIdContext";
import { useJobItemRoleId } from "../../context/JobItemRoleIdContext";
import { tooltipTitleCss, varianceIconAbsoluteCss } from "./styles.css";

export function UserRoleVariance() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemRoleId = useJobItemRoleId();
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const jobItem = useJobItem(jobItemId);
  const job = useJob(jobId);
  const roleRate = asDecimal(
    useRoleRateByRoleIdRateCardId(jobItemRole.roleId, job.rateCardId)
  );
  const rateCard = useRateCard(job.rateCardId);
  const role = useRole(jobItemRole.roleId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const jobCurrencySellRate = asDecimal(jobItemRole.jobCurrencySellRate);
  const formattedRate = formatForeignCurrency(customerCurrency, {
    value: roleRate,
    hideLongSymbols: true,
    currency: jobCurrency,
  });

  let variance = 0;

  if (
    isArchived(job) ||
    !costingMethodObj(jobItem.costingMethod).isBasedByUser() ||
    jobCurrencySellRate === 0
  ) {
    return null;
  }

  if (roleRate === 0) {
    variance = jobCurrencySellRate;
  } else {
    variance =
      numeral(numeral(jobCurrencySellRate).format(NumberFormats.TwoDecimals))
        .subtract(numeral(roleRate).value())
        .divide(numeral(roleRate).value())
        .value() || 0;
  }

  if (variance === 0) return null;

  const formattedVariance = numeral(Math.abs(variance)).format(
    NumberFormats.Percentage
  );

  const down = variance < 0;

  return (
    <Tooltip
      title={
        <div className={tooltipTitleCss}>
          The rate you’ve set is <strong>{formattedVariance}</strong>{" "}
          {down ? "lower" : "higher"} than the <strong>{formattedRate}</strong>{" "}
          rate set on the {rateCard.name} rate card for the{" "}
          <strong>{role.name}</strong> role
        </div>
      }
      placement="top"
    >
      <div className={varianceIconAbsoluteCss}>
        <VarianceIcon direction={down ? "down" : "up"} size={9} />
      </div>
    </Tooltip>
  );
}
