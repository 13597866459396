import { put, select, takeLatest } from "redux-saga/effects";
import {
  LINE_ITEMS_SORT_CANCEL,
  INVOICE_LINE_ITEM_SORT_CANCEL
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectInvoiceUi } from "../../selectors/invoiceSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* cancelSortLineItems(action) {
  const { invoiceId, sortModeSnapshotId: snapshotId } = yield select(
    selectInvoiceUi
  );

  if (!invoiceId) return;

  try {
    const snapshot = yield select(selectSnapshotBySnapshotId, {
      snapshotId
    });

    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_CANCEL, {
        invoiceId,
        snapshotId,
        snapshot
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLineItemsSortCancel() {
  yield takeLatest(LINE_ITEMS_SORT_CANCEL, cancelSortLineItems);
}
