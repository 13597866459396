import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { JOB_TIMELINE_DAYS_IN_WEEK } from "../../../lib/constants";
import {
  getDaysInMonth,
  getStartOfMonth,
  getStartOfWeek,
  getTodayDate
} from "../../../lib/dates";
import { selectJobTimelineZoomLevel } from "../../../redux/selectors/jobTimelineSelectors";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const mapState = state => ({
  zoomLevel: selectJobTimelineZoomLevel(state)
});

const mapDispatch = null;

class TodayConsumer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    zoomLevel: PropTypes.number.isRequired
  };

  render() {
    const { children, zoomLevel } = this.props;
    let days = 1;
    let date = getTodayDate();

    if (zoomLevel === 0) {
      days = getDaysInMonth();
      date = getStartOfMonth();
    }

    if (zoomLevel === 1) {
      days = JOB_TIMELINE_DAYS_IN_WEEK;
      date = getStartOfWeek();
    }

    return (
      <JobTimelineScrollContext.Consumer>
        {({ dayWidth, getOffsetXAtDate }) =>
          children({
            left: getOffsetXAtDate(date),
            width: dayWidth * days + 1
          })
        }
      </JobTimelineScrollContext.Consumer>
    );
  }
}

export default connect(mapState, mapDispatch)(TodayConsumer);
