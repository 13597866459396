import { produce } from "immer";
import { isNull, set } from "lodash-es";

import {
  DATA_VALUE_HANDLE_LEFT,
  JOB_ITEM_DEPENDANCIES_SELECTED,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START,
} from "../../../lib/constants";
import createCrossReducer from "../../helpers/createCrossReducer";
import {
  selectJobItemDependancyById,
  selectJobItemDependancyIdsByChildJobItemId,
  selectJobItemDependancyIdsByParentJobItemId,
} from "../../selectors/jobItemDependancySelectors";

const setJobItemDependanciesSelected = (state, action) =>
  produce(state, (draft) => {
    const { jobItemId, dependancyHandleType } = action.payload;

    if (isNull(jobItemId)) {
      set(
        draft,
        `entities.jobItemDependancies.jobItemDependancyIdsSelected`,
        []
      );

      return;
    }

    const childJobItemDependancyIds =
      selectJobItemDependancyIdsByParentJobItemId(state, { jobItemId }).filter(
        (id) => {
          const { dependancyTypeId } = selectJobItemDependancyById(state, {
            id,
          });

          if (dependancyHandleType === DATA_VALUE_HANDLE_LEFT)
            return (
              dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START ||
              dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END
            );

          return (
            dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START ||
            dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END
          );
        }
      );

    const parentJobItemDependancyIds =
      selectJobItemDependancyIdsByChildJobItemId(state, { jobItemId }).filter(
        (id) => {
          const { dependancyTypeId } = selectJobItemDependancyById(state, {
            id,
          });

          if (dependancyHandleType === DATA_VALUE_HANDLE_LEFT)
            return (
              dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START ||
              dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START
            );

          return (
            dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END ||
            dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END
          );
        }
      );

    const jobItemDependancyIds = parentJobItemDependancyIds.concat(
      childJobItemDependancyIds
    );

    set(
      draft,
      `entities.jobItemDependancies.jobItemDependancyIdsSelected`,
      jobItemDependancyIds
    );
  });

export default createCrossReducer({
  [JOB_ITEM_DEPENDANCIES_SELECTED]: setJobItemDependanciesSelected,
});
