import React from "react";
import { compose } from "redux";
import CommercialDocumentContext from "../index";

const makeConsumer = mapContext => WrappedComponent => props => (
  <CommercialDocumentContext.Consumer>
    {context => (
      <WrappedComponent
        {...{
          ...props,
          ...mapContext(context)
        }}
      />
    )}
  </CommercialDocumentContext.Consumer>
);

const consumeCommercialDocument = mapContext => WrappedComponent =>
  compose(makeConsumer(mapContext))(WrappedComponent);

export default consumeCommercialDocument;
