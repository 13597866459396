import { SCHEDULE_BLOCK_RESIZE } from "../../../../../lib/constants";
import resizeBlockReducer from "./resizeBlockReducer";

const undoResizeBlockReducer = (state, action) => {
  const { hasChanged, origin } = action.payload;

  if (!hasChanged) return state;

  const reverseAction = {
    type: SCHEDULE_BLOCK_RESIZE,
    payload: {
      ...action.payload,
      hours: origin.hours,
      days: origin.days
    }
  };

  return resizeBlockReducer(state, reverseAction);
};

export default undoResizeBlockReducer;
