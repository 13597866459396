import type { TEntityId } from "@streamtimefe/entities";
import type { TEntityList, TEntityListKey } from "st-shared/types";

import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from "../../lib/constants";

export interface EntityRemovedPayload {
  entityName: TEntityListKey;
  ids: TEntityId[];
}

export type EntityAction = EntityReceivedAction | EntityRemovedAction;

export type EntityReceivedAction = {
  type: typeof ENTITIES_RECEIVED;
  payload: TEntityList;
};

// TODO: replace all createActions with this
export function actionEntitiesReceived(
  payload: TEntityList
): EntityReceivedAction {
  return { type: ENTITIES_RECEIVED, payload };
}

export type EntityRemovedAction = {
  type: typeof ENTITIES_REMOVED;
  payload: EntityRemovedPayload;
};

// TODO: replace all createActions with this
export function actionEntitiesRemoved(
  payload: EntityRemovedPayload
): EntityRemovedAction {
  return { type: ENTITIES_REMOVED, payload };
}
