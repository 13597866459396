import { plate } from "st-shared/external";

import { FormulaOperator } from "../types";

export function insertFormulaOperator(
  editor: plate.PlateEditor,
  operator: FormulaOperator
) {
  plate.withoutNormalizing(editor, () => {
    plate.insertNodes(editor, [{ text: operator }, { text: " " }]);
    plate.focusEditor(editor, plate.getPointFromLocation(editor));
  });
}
