import numeral from "numeral";

import { NumberFormats } from ".";

export function formatPercentage(value: number) {
  return `${numeral(Math.round(value)).format(NumberFormats.NoDecimal)}%`;
}

export function formatPercentageRaw(value: number) {
  return `${numeral(Math.round(value)).format(NumberFormats.NoDecimal)}`;
}
