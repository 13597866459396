import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { DATA_ATTRIBUTE_USER_AVAILABILITY } from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { entityIdType } from "../../../lib/types/entityTypes";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import CapturePointerElement from "../../elements/CapturePointerElement";
import Anchor from "../ScheduleScrollProvider/Anchor";
import AvailabilityWeeksConsumer from "../ScheduleScrollProvider/AvailabilityWeeksConsumer";
import ScheduleUserAvailabilityChunks from "./ScheduleUserAvailabilityChunks";

const attrName = getAttrPropString(DATA_ATTRIBUTE_USER_AVAILABILITY);

class ScheduleUserAvailability extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    compactMode: PropTypes.bool,
  };

  static defaultProps = {
    compactMode: false,
  };

  render() {
    const { id, compactMode } = this.props;
    return (
      <Wrapper {...{ [attrName]: id }} className={compactMode ? "compact" : ""}>
        <Anchor>
          <AvailabilityWeeksConsumer>
            {({ date, left, dayWidth }) => (
              <AvailabilityWeek key={date} style={{ left }}>
                <ScheduleUserAvailabilityChunks
                  id={id}
                  date={date}
                  compactMode={compactMode}
                  dayWidth={dayWidth}
                />
              </AvailabilityWeek>
            )}
          </AvailabilityWeeksConsumer>
        </Anchor>
      </Wrapper>
    );
  }
}

export default ScheduleUserAvailability;

const Wrapper = styled(CapturePointerElement)`
  display: flex;
  position: relative;
  height: ${ScheduleUi.User.AvailabilityRowHeight}px;
  width: 100%;
  user-select: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  &.compact {
    position: absolute;
    height: ${ScheduleUi.User.AvailabilityCompactRowHeight}px;
    pointer-events: none;
  }
  &:not(.compact) {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: var(--border-thin-bright);
    }
  }
`;

const AvailabilityWeek = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
`;
