import { produce } from "immer";
import { reduce } from "lodash-es";
import numeral from "numeral";
import { put, select } from "redux-saga/effects";
import {
  costingMethodObj,
  CostingMethods,
  createCostingMethod,
  timeAllocationMethodObj,
  TimeAllocationMethods,
} from "st-shared/entities";
import { FrameworkException, num } from "st-shared/lib";

import { selectGroupedJobItemUsersByRoleIds } from "../../../components/Job/hooks/useGroupedJobItemUsers";
import {
  JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_SET_COSTING_METHOD,
  JOB_ITEM_SET_DESCRIPTION,
  JOB_ITEM_SET_EXPANDED,
  JOB_ITEM_SET_HOURS,
  JOB_ITEM_SET_MASTER_JOB_ITEM,
  JOB_ITEM_SET_NAME,
  JOB_ITEM_SET_RATE,
  JOB_ITEM_SET_TOTAL,
  JOB_ITEM_USER_EDIT,
  NUMBER_FORMAT_TWO_DECIMALS,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getRateCardId } from "../../../lib/entities/jobEntity";
import {
  getFormattedTotalPlannedMoney,
  getJobId,
  getSellRate,
} from "../../../lib/entities/jobItemEntity";
import {
  getTotalPlannedMinutes,
  getUserId,
} from "../../../lib/entities/jobItemUserEntity";
import { selectMasterJobItemRate } from "../../../state/entities/itemRate/selectors/selectMasterJobItemRate";
import { actionJobItemRoleSetRate } from "../../../state/entities/jobItemRole/actions";
import { selectJobItemRolesByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRolesByJobItemId";
import { selectMasterJobItem } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { selectIsExpandedJobItem } from "../../selectors/jobItem/ui/isExpanded";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUsersByJobItemId } from "../../selectors/jobItemUserSelectors";
import { selectJobByJobId } from "../../selectors/jobSelectors";
import { selectRoleRateByRoleIdRateCardId } from "../../selectors/rateSelectors";
import { selectUserSellRate } from "../../selectors/user/selectUserSellRate";
import { selectNewJobItemCostingMethod } from "../../selectors/userPreferenceSelectors";
import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";

function* setName(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.name = value;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* setMasterJobItem(action) {
  try {
    const { jobItemId, jobItem, masterJobItemId, doSetFocus } = action.payload;

    const masterJobItem = yield select(selectMasterJobItem, {
      id: masterJobItemId,
    });
    const job = yield select(selectJobByJobId, {
      jobId: jobItem.jobId,
    });
    const itemRate = yield select(selectMasterJobItemRate, {
      masterJobItemId,
      rateCardId: getRateCardId(job),
    });

    const jobItemUsers = yield select(selectJobItemUsersByJobItemId, {
      id: jobItemId,
    });

    const newJobItem = produce(jobItem, (draft) => {
      draft.name = masterJobItem.name;
      draft.masterJobItemId = masterJobItemId;

      if (!draft.description || draft.description === "") {
        draft.description = masterJobItem.description;
      }

      switch (masterJobItem.costingMethod.id) {
        case CostingMethods.Item:
          draft.costingMethod = createCostingMethod(CostingMethods.Item);
          draft.jobCurrencySellRate = itemRate;
          break;
        case CostingMethods.People:
          draft.costingMethod = createCostingMethod(CostingMethods.People);
          draft.timeAllocationMethod.id = TimeAllocationMethods.People;
          draft.totalPlannedMinutes = reduce(
            jobItemUsers,
            (total, jobItemUser) => total + getTotalPlannedMinutes(jobItemUser),
            0
          );
          break;
        case CostingMethods.ValueCalculatedSell:
          draft.costingMethod = createCostingMethod(
            CostingMethods.ValueCalculatedSell
          );
          draft.jobCurrencyTotalPlannedTimeExTax =
            getFormattedTotalPlannedMoney(itemRate);
          break;
        case CostingMethods.ValueUserSell:
          draft.costingMethod = createCostingMethod(
            CostingMethods.ValueUserSell
          );
          draft.timeAllocationMethod.id = TimeAllocationMethods.People;
          draft.totalPlannedMinutes = reduce(
            jobItemUsers,
            (total, jobItemUser) => total + getTotalPlannedMinutes(jobItemUser),
            0
          );

          draft.jobCurrencyTotalPlannedTimeExTax =
            getFormattedTotalPlannedMoney(itemRate);
          break;
        default:
          throw new FrameworkException(
            costingMethodObj(masterJobItem.costingMethod).getUnknownIdError()
          );
      }

      if (job.isBillable) {
        draft.isBillable = masterJobItem.isBillable;
      }
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    if (costingMethodObj(newJobItem.costingMethod).isBasedByUser()) {
      const newJobItemUsers = [];

      for (let i = 0; i < jobItemUsers.length; i += 1) {
        const jobItemUser = jobItemUsers[i];
        const sellRate = yield select(selectUserSellRate, {
          userId: getUserId(jobItemUser),
          jobId: getJobId(jobItem),
        });

        const newJobItemUser = produce(jobItemUser, (draft) => {
          draft.jobCurrencySellRate = numeral(sellRate).value();
        });

        newJobItemUsers.push({ new: newJobItemUser, prev: jobItemUser });
      }

      if (newJobItemUsers.length > 0) {
        yield put(
          createAction(JOB_ITEM_USER_EDIT, {
            jobItemId,
            jobItemUsers: newJobItemUsers,
          })
        );
      }
    }

    if (doSetFocus) {
      if (timeAllocationMethodObj(newJobItem).isItem()) {
        yield put(
          createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
            currentFocus: {
              jobItemId,
              key: FOCUS_KEYS.ITEM_HOURS,
            },
          })
        );
      } else {
        yield setFocusForCostingMethod(jobItemId, newJobItem.costingMethod);
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* setCostingMethod(action) {
  try {
    const { jobItemId, costingMethodId, doSetFocus } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const job = yield select(selectJob, { jobId: jobItem.jobId });

    const jobItemUsers = yield select(selectJobItemUsersByJobItemId, {
      id: jobItemId,
    });

    const jobItemRoles = yield select(selectJobItemRolesByJobItemId, {
      jobItemId,
    });

    const newJobItem = produce(jobItem, (draft) => {
      draft.costingMethod.id = costingMethodId;
      if (
        costingMethodId === CostingMethods.People ||
        costingMethodId === CostingMethods.ValueUserSell
      ) {
        draft.timeAllocationMethod.id = TimeAllocationMethods.People;
        draft.totalPlannedMinutes =
          reduce(
            jobItemUsers,
            (total, jobItemUser) => total + getTotalPlannedMinutes(jobItemUser),
            0
          ) +
          reduce(
            jobItemRoles,
            (total, jobItemRole) => total + jobItemRole.totalPlannedMinutes,
            0
          );
      }

      if (
        jobItem.costingMethod.id === CostingMethods.Item &&
        costingMethodId === CostingMethods.ValueCalculatedSell
      ) {
        const value = num(jobItem.jobCurrencySellRate)
          .multiply(num(jobItem.totalPlannedMinutes).divide(60).value())
          .value();

        draft.jobCurrencyTotalPlannedTimeExTax =
          getFormattedTotalPlannedMoney(value);
      } else if (
        jobItem.costingMethod.id === CostingMethods.People &&
        costingMethodId === CostingMethods.ValueUserSell
      ) {
        const value =
          reduce(
            jobItemUsers,
            (total, jobItemUser) =>
              total +
              num(jobItemUser.jobCurrencySellRate)
                .multiply(
                  num(jobItemUser.totalPlannedMinutes).divide(60).value()
                )
                .value(),
            0
          ) +
          reduce(
            jobItemRoles,
            (total, jobItemRole) =>
              total +
              num(jobItemRole.jobCurrencySellRate)
                .multiply(
                  num(jobItemRole.totalPlannedMinutes).divide(60).value()
                )
                .value(),
            0
          );

        if (value > 0) {
          draft.jobCurrencyTotalPlannedTimeExTax =
            getFormattedTotalPlannedMoney(value);
        }
      }
    });

    const userPreferenceCostingMethodId = yield select(
      selectNewJobItemCostingMethod
    );

    if (costingMethodId !== userPreferenceCostingMethodId) {
      yield put(
        createAction(SET_USER_PREFERENCE, {
          key: UserPreferenceKeys.USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD,
          value: costingMethodId,
        })
      );
    }

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    if (
      !(
        jobItem.costingMethod.id === CostingMethods.People ||
        jobItem.costingMethod.id === CostingMethods.ValueUserSell
      ) &&
      (costingMethodId === CostingMethods.People ||
        costingMethodId === CostingMethods.ValueUserSell)
    ) {
      const newJobItemUsers = [];

      for (let i = 0; i < jobItemUsers.length; i += 1) {
        const jobItemUser = jobItemUsers[i];
        const sellRate = yield select(selectUserSellRate, {
          userId: getUserId(jobItemUser),
          jobId: getJobId(jobItem),
        });

        const newJobItemUser = produce(jobItemUser, (draft) => {
          draft.jobCurrencySellRate = numeral(sellRate).value();
        });

        newJobItemUsers.push({ new: newJobItemUser, prev: jobItemUser });
      }

      if (newJobItemUsers.length > 0) {
        yield put(
          createAction(JOB_ITEM_USER_EDIT, {
            jobItemId,
            jobItemUsers: newJobItemUsers,
          })
        );
      }

      for (let i = 0; i < jobItemRoles.length; i += 1) {
        const jobItemRole = jobItemRoles[i];

        const rate = yield select(selectRoleRateByRoleIdRateCardId, {
          roleId: jobItemRole.roleId,
          rateCardId: job.rateCardId,
        });

        yield put(actionJobItemRoleSetRate(jobItemRole.id, rate));
      }
    }

    if (doSetFocus)
      yield setFocusForCostingMethod(jobItemId, newJobItem.costingMethod);
  } catch (error) {
    sagaError(error);
  }
}

function* setFocusForCostingMethod(jobItemId, costingMethod) {
  switch (costingMethod.id) {
    case CostingMethods.Item:
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
          currentFocus: {
            jobItemId,
            key: FOCUS_KEYS.ITEM_RATE,
          },
        })
      );
      break;
    case CostingMethods.ValueCalculatedSell:
    case CostingMethods.ValueUserSell:
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
          currentFocus: {
            jobItemId,
            key: FOCUS_KEYS.ITEM_TOTAL,
          },
        })
      );
      break;
    case CostingMethods.People:
      {
        // open if not expanded
        const isExpanded = yield select(selectIsExpandedJobItem, {
          jobItemId,
        });
        if (!isExpanded) {
          yield put(
            createAction(JOB_ITEM_SET_EXPANDED, { jobItemId, isExpanded: true })
          );
        }

        // if users than set hours to first one else set add team member open
        const groupedJobItemUsersInfo = yield select(
          selectGroupedJobItemUsersByRoleIds,
          jobItemId
        );

        if (
          groupedJobItemUsersInfo.length > 0 &&
          groupedJobItemUsersInfo[0].jobItemUserIds.length > 0
        ) {
          yield put(
            createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
              currentFocus: {
                jobItemId,
                jobItemUserId: groupedJobItemUsersInfo[0].jobItemUserIds[0],
                key: FOCUS_KEYS.ITEM_USER_HOURS,
              },
            })
          );
        } else {
          yield put(
            createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
              currentFocus: {
                jobItemId,
                key: FOCUS_KEYS.ITEM_NEW_USER,
              },
            })
          );
        }
      }
      break;
    default:
      throw new FrameworkException(
        costingMethodObj(costingMethod).getUnknownIdError()
      );
  }
}

function* setHours(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.totalPlannedMinutes = convertFlexibleDurationToMinutes(value);
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    // Update all existing team member's sell rate if it's cost by item
    if (costingMethodObj(newJobItem.costingMethod).isItem()) {
      const newJobItemUsers = [];

      const jobItemUsers = yield select(selectJobItemUsersByJobItemId, {
        id: jobItemId,
      });

      for (let i = 0; i < jobItemUsers.length; i += 1) {
        const jobItemUser = jobItemUsers[i];
        let sellRate = getSellRate(newJobItem);
        if (sellRate === null) {
          sellRate = yield select(selectUserSellRate, {
            userId: getUserId(jobItemUser),
            jobId: getJobId(jobItem),
          });
        }

        const newJobItemUser = produce(jobItemUser, (draft) => {
          draft.jobCurrencySellRate = numeral(sellRate).value();
        });

        newJobItemUsers.push({ new: newJobItemUser, prev: jobItemUser });
      }

      if (newJobItemUsers.length > 0) {
        yield put(
          createAction(JOB_ITEM_USER_EDIT, {
            jobItemId,
            jobItemUsers: newJobItemUsers,
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* setRate(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.jobCurrencySellRate =
        value.length > 0
          ? numeral(value).format(NUMBER_FORMAT_TWO_DECIMALS)
          : null;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    // Update all existing team member's sell rate if it's cost by item
    if (costingMethodObj(newJobItem.costingMethod).isItem()) {
      const newJobItemUsers = [];

      const jobItemUsers = yield select(selectJobItemUsersByJobItemId, {
        id: jobItemId,
      });

      for (let i = 0; i < jobItemUsers.length; i += 1) {
        const jobItemUser = jobItemUsers[i];
        let sellRate = getSellRate(newJobItem);
        if (sellRate === null) {
          sellRate = yield select(selectUserSellRate, {
            userId: getUserId(jobItemUser),
            jobId: getJobId(jobItem),
          });
        }

        const newJobItemUser = produce(jobItemUser, (draft) => {
          draft.jobCurrencySellRate = numeral(sellRate).value();
        });

        newJobItemUsers.push({ new: newJobItemUser, prev: jobItemUser });
      }

      if (newJobItemUsers.length > 0) {
        yield put(
          createAction(JOB_ITEM_USER_EDIT, {
            jobItemId,
            jobItemUsers: newJobItemUsers,
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* setTotal(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.jobCurrencyTotalPlannedTimeExTax =
        getFormattedTotalPlannedMoney(value);
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* setDescription(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.description = value;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItem() {
  yield takeLatestBy(
    [JOB_ITEM_SET_NAME],
    setName,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_MASTER_JOB_ITEM],
    setMasterJobItem,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_COSTING_METHOD],
    setCostingMethod,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_HOURS],
    setHours,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_RATE],
    setRate,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_TOTAL],
    setTotal,
    (action) => action.payload.jobItemId
  );
  yield takeLatestBy(
    [JOB_ITEM_SET_DESCRIPTION],
    setDescription,
    (action) => action.payload.jobItemId
  );
}
