import type {
  TColumnTooltipEnum,
  TCurrency,
  TFilterGroupTypeEnum,
  TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import {
  ColumnTooltipEnum,
  CompanyStatusEnum,
  FilterGroupTypeEnum,
  UserStatusEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";

import type { SearchOption } from "../entities";
import { ContactStatuses } from "../entities";
import { InvoiceStatuses } from "../entities/InvoiceStatus";
import { InvoiceTypes } from "../entities/InvoiceType";
import { JobItemStatuses } from "../entities/JobItemStatus";
import { JobItemUserStatuses } from "../entities/JobItemUserStatus";
import { JobStatuses } from "../entities/JobStatus";
import { LoggedExpenseStatuses } from "../entities/LoggedExpenseStatus";
import { LoggedTimeStatuses } from "../entities/LoggedTimeStatus";
import { QuoteStatuses } from "../entities/QuoteStatus";
import type { FilterGroupFilterValue } from "../module";

export const FilterType = {
  Text: 1,
  Date: 2,
  Checkbox: 3,
  Minutes: 4,
  Number: 5,
  Dropdown: 6, // Deprecated, doesn't get used
  System: 7,
  EntityList: 8,
} as const;

export type TFilterType = (typeof FilterType)[keyof typeof FilterType];

export type FilterTypeCheckboxItem = {
  text: string;
  displayText?: string;
} & (
  | { value: FilterGroupFilterValue }
  | { valueMatchTypeId: TValueMatchTypeEnum }
);
export type FilterTypeBase = {
  name: string;
  simpleName?: string;
  tooltip?: TColumnTooltipEnum;
  containsFinancial?: true;
  containsCostRate?: true;
  suffix?: "currencySymbol" | "currencyId" | "hours";
};
export type FilterTypeText = FilterTypeBase & {
  type: typeof FilterType.Text;
  disableAnyValue?: true;
  optionsType?:
    | "jobs"
    | "jobPhases"
    | "companies"
    | "contacts"
    | "items"
    | "companyLabels"
    | "contactLabels"
    | "jobLabels"
    | "loggedExpenseLabels"
    | "quoteLabels"
    | "invoiceLabels"
    | "userLabels"
    | "branches"
    | "roles"
    | "rateCards"
    | "users";
};
export type FilterTypeDate = FilterTypeBase & {
  type: typeof FilterType.Date;
};
export type FilterTypeCheckbox = FilterTypeBase & {
  type: typeof FilterType.Checkbox;
  defaultDescription?: { negated?: boolean; value: string };
  allDescription: { negated?: boolean; value: string };
  valuesOnly?: boolean;
  forceTitle?: boolean;
  items: FilterTypeCheckboxItem[];
};
export type FilterTypeMinutes = FilterTypeBase & {
  type: typeof FilterType.Minutes;
};
export type FilterTypeNumber = FilterTypeBase & {
  type: typeof FilterType.Number;
};
export type FilterTypeDropdown = FilterTypeBase & {
  type: typeof FilterType.Dropdown;
  optionsType: "users" | "currencies";
  placeholder: string;
};
export type FilterTypeSystem = FilterTypeBase & {
  type: typeof FilterType.System;
};
export type FilterTypeEntityList = FilterTypeBase & {
  type: typeof FilterType.EntityList;
  optionsType: "users" | "roles" | "branches" | "currencies";
  title: string;
  searchPlaceholder?: string;
};

export type FilterGroupTypesProperty =
  | FilterTypeText
  | FilterTypeDate
  | FilterTypeCheckbox
  | FilterTypeMinutes
  | FilterTypeNumber
  | FilterTypeDropdown
  | FilterTypeSystem
  | FilterTypeEntityList;

export const FilterGroupTypeProperties: Record<
  TFilterGroupTypeEnum,
  FilterGroupTypesProperty
> = {
  [FilterGroupTypeEnum.CompanyName]: { name: "Company", type: FilterType.Text },
  [FilterGroupTypeEnum.JobName]: {
    name: "Job Name",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.JobStatus]: {
    name: "Job Status",
    type: FilterType.Checkbox,
    defaultDescription: { value: "Active Jobs" },
    allDescription: { value: "All Jobs" },
    items: [
      {
        text: "Paused",
        value: JobStatuses.Planning,
      },
      {
        text: "In Play",
        value: JobStatuses.Scheduled,
      },
      {
        text: "Completed",
        value: JobStatuses.Complete,
      },
      {
        text: "Archived",
        value: JobStatuses.Archived,
      },
    ],
  },
  [FilterGroupTypeEnum.TeamMember]: {
    name: "Team Member",
    type: FilterType.EntityList,
    optionsType: "users",
    title: "Team Members",
    searchPlaceholder: "Search by name or role...",
  },
  [FilterGroupTypeEnum.Date]: { name: "Date", type: FilterType.Date },
  [FilterGroupTypeEnum.Minutes]: { name: "Duration", type: FilterType.Minutes },
  [FilterGroupTypeEnum.Amount]: {
    name: "Total Sell",
    type: FilterType.Number,
    containsFinancial: true,
    suffix: "currencySymbol",
  },
  [FilterGroupTypeEnum.ItemName]: { name: "Item Name", type: FilterType.Text },
  [FilterGroupTypeEnum.ExpenseName]: {
    name: "Expense Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobItemStatus]: {
    name: "Item Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All Items" },
    items: [
      {
        text: "Paused",
        value: JobItemStatuses.Planning,
      },
      {
        text: "In Play",
        value: JobItemStatuses.Scheduled,
      },
      {
        text: "Completed",
        value: JobItemStatuses.Complete,
      },
    ],
  },
  [FilterGroupTypeEnum.Time]: {
    name: "Planned",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Planned",
        displayText: "Was Planned",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Not Planned",
        displayText: "Was Not Planned",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.ContactName]: { name: "Contact", type: FilterType.Text },
  [FilterGroupTypeEnum.JobStartDate]: {
    name: "Job Start Date",
    type: FilterType.Date,
    tooltip: ColumnTooltipEnum.StartDate,
    simpleName: "Start Date",
  },
  [FilterGroupTypeEnum.JobDueDate]: {
    name: "Job Due Date",
    type: FilterType.Date,
    tooltip: ColumnTooltipEnum.DueDate,
  },
  [FilterGroupTypeEnum.JobTotalLoggedExTax]: {
    name: "Total Used Sell",
    type: FilterType.Number,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.TotalUsedSell,
  },
  [FilterGroupTypeEnum.JobTotalPlannedTimeExTax]: {
    name: "Time Planned Sell",
    type: FilterType.Number,
    containsFinancial: true,
    tooltip: ColumnTooltipEnum.TotalPlannedSell,
  },
  [FilterGroupTypeEnum.JobTotalLoggedMinutes]: {
    name: "Used",
    type: FilterType.Minutes,
    tooltip: ColumnTooltipEnum.UsedHours,
    suffix: "hours",
  },
  [FilterGroupTypeEnum.JobTotalPlannedMinutes]: {
    name: "Planned",
    type: FilterType.Minutes,
    tooltip: ColumnTooltipEnum.PlannedHours,
    suffix: "hours",
  },
  [FilterGroupTypeEnum.JobBudget]: {
    name: "Budget",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.Budget,
    suffix: "currencySymbol",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalInvoicedExTax]: {
    name: "All Invoices Ex Tax",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalInvoicedExTax,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobDaysPastDueDate]: {
    name: "Days Overdue",
    type: FilterType.Number,
  },
  [FilterGroupTypeEnum.JobDaysUntilDueDate]: {
    name: "Days Left",
    type: FilterType.Number,
  },
  [FilterGroupTypeEnum.JobBudgetVariance]: {
    name: "Budget Variance",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.BudgetVariance,
    suffix: "currencySymbol",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobNumber]: {
    name: "Job Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.InvoiceStatus]: {
    name: "Invoice Status",
    type: FilterType.Checkbox,
    defaultDescription: { negated: true, value: "Voided" },
    allDescription: { value: "All Invoices" },
    items: [
      {
        text: "Draft",
        value: InvoiceStatuses.Draft,
      },
      {
        text: "Awaiting Payment",
        value: InvoiceStatuses.AwaitingPayment,
      },
      {
        text: "Paid",
        value: InvoiceStatuses.Paid,
      },
      {
        text: "Voided",
        value: InvoiceStatuses.Voided,
      },
    ],
  },
  [FilterGroupTypeEnum.InvoiceNumber]: {
    name: "Invoice Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.InvoicePaidDate]: {
    name: "Paid Date",
    type: FilterType.Date,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceTotalAmountExTax]: {
    name: "Invoiced Ex Tax",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceTotalAmountIncTax]: {
    name: "Invoiced Inc Tax",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceAmountPaidIncTax]: {
    name: "Amount Paid",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceReference]: {
    name: "Invoice Reference",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.InvoiceDueDate]: {
    name: "Due Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.InvoiceBalance]: {
    name: "Balance To Pay",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceInvoiceDate]: {
    name: "Invoice Issue Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.IssueDate]: {
    name: "Issue Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.QuoteStatus]: {
    name: "Quote Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All Quotes" },
    items: [
      {
        text: "Draft",
        value: QuoteStatuses.Draft,
      },
      {
        text: "Pending",
        value: QuoteStatuses.PendingApproval,
      },
      {
        text: "Approved",
        value: QuoteStatuses.Approved,
      },
      {
        text: "Declined",
        value: QuoteStatuses.Declined,
      },
    ],
  },
  [FilterGroupTypeEnum.QuoteNumber]: {
    name: "Quote Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.QuoteQuoteDate]: {
    name: "Quote Issue Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.QuoteTotalAmountExTax]: {
    name: "Quoted Ex Tax",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.CreatedUser]: {
    name: "Created By",
    type: FilterType.EntityList,
    optionsType: "users",
    title: "Team Members",
    searchPlaceholder: "Search by name or role...",
  },
  [FilterGroupTypeEnum.ExpenseCompany]: {
    name: "Supplier",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ExpenseReference]: {
    name: "Expense Reference",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.Reference]: {
    name: "Reference",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ExpenseQuantity]: {
    name: "Quantity",
    type: FilterType.Number,
  },
  [FilterGroupTypeEnum.ExpenseContactName]: {
    name: "Supplier Contact Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ExpenseContactEmail]: {
    name: "Supplier Contact Email",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ExpenseDescription]: {
    name: "Description",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ExpenseTotalCost]: {
    name: "Cost",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.ExpenseTotalSell]: {
    name: "Sell",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoicedExTax]: {
    name: "Amount Invoiced",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoicedPercentage]: {
    name: "Amount Invoiced (%)",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoicedBalanceRemaining]: {
    name: "Balance To Invoice",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.BilledStatus]: {
    name: "Fully invoiced",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Invoiced",
        displayText: "Fully Invoiced",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Not Invoiced",
        displayText: "Not Fully Invoiced",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobLabel]: {
    name: "Job Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.JobCreatedDate]: {
    name: "Job Creation Date",
    type: FilterType.Date,
    tooltip: ColumnTooltipEnum.CreationDate,
  },
  [FilterGroupTypeEnum.JobTotalLoggedCostExTax]: {
    name: "Total Cost",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalCost,
    containsFinancial: true,
    containsCostRate: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedTimeExTax]: {
    name: "Time Used Sell",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedSell,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedTimeCostExTax]: {
    name: "Time Used Cost",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedCost,
    containsFinancial: true,
    containsCostRate: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedExpensesCostExTax]: {
    name: "Expenses Used Cost",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedExpensesCost,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedExpensesExTax]: {
    name: "Expenses Used Sell",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedExpensesSell,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedProfitExTax]: {
    name: "Total Profit",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalProfit,
    containsFinancial: true,
    containsCostRate: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedTimeProfitExTax]: {
    name: "Time Used Profit",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedProfit,
    containsFinancial: true,
    containsCostRate: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedExpensesProfitExTax]: {
    name: "Expenses Profit",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.UsedExpensesProfit,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.QuoteTotalAmountIncTax]: {
    name: "Quoted Inc Tax",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.LoggedExpenseLabel]: {
    name: "Expense Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.QuoteLabel]: {
    name: "Quote Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.ExpenseType]: {
    name: "Expense Type",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    forceTitle: true,
    items: [
      {
        text: "Expense",
        valueMatchTypeId: ValueMatchTypeEnum.LoggedExpense,
      },
      {
        text: "Purchase Order",
        valueMatchTypeId: ValueMatchTypeEnum.PurchaseOrder,
      },
    ],
  },
  [FilterGroupTypeEnum.ExpenseStatus]: {
    name: "Expense Status",
    type: FilterType.Checkbox,
    defaultDescription: { negated: true, value: "Declined" },
    allDescription: { value: "All Expenses" },
    items: [
      {
        text: "Draft",
        value: LoggedExpenseStatuses.Draft,
      },
      {
        text: "Approved",
        value: LoggedExpenseStatuses.Approved,
      },
      {
        text: "Paid",
        value: LoggedExpenseStatuses.Paid,
      },
      {
        text: "Declined",
        value: LoggedExpenseStatuses.Declined,
      },
    ],
  },
  [FilterGroupTypeEnum.PurchaseOrderRequiredDate]: {
    name: "Date Required",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.PurchaseOrderNumber]: {
    name: "Purchase Order Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.CurrencyCode]: {
    name: "Currency",
    type: FilterType.EntityList,
    optionsType: "currencies",
    title: "Currencies",
    searchPlaceholder: "Search by currency...",
  },
  [FilterGroupTypeEnum.PurchaseOrderAmountPaidIncTax]: {
    name: "Amount Paid",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.PurchaseOrderBalance]: {
    name: "Balance To Pay",
    type: FilterType.Number,
    suffix: "currencyId",
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.PurchaseOrderCurrencyAmountPaidIncTax]: {
    name: "Amount Paid",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.PurchaseOrderCurrencyBalance]: {
    name: "Balance To Pay",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceCurrencyAmountPaidIncTax]: {
    name: "Amount Paid",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.InvoiceCurrencyBalance]: {
    name: "Balance To Pay",
    type: FilterType.Number,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalLoggedProfitMargin]: {
    name: "Profit Margin",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.ProfitMargin,
    containsFinancial: true,
    containsCostRate: true,
  },
  [FilterGroupTypeEnum.JobId]: { name: "Job", type: FilterType.System },
  [FilterGroupTypeEnum.ContactEmail]: { name: "Email", type: FilterType.Text },
  [FilterGroupTypeEnum.ContactPhoneNumber]: {
    name: "Phone Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ContactPosition]: {
    name: "Position",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.CompanyTaxNumber]: {
    name: "Tax Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.WebsiteAddress]: {
    name: "Website",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobItemEstimatedStartDate]: {
    name: "Item Estimated Start Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobItemEstimatedEndDate]: {
    name: "Item Estimated End Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobItemUserStatus]: {
    name: "Item Team Member Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All Item Team Members" },
    items: [
      {
        text: "Paused",
        value: JobItemUserStatuses.Planning,
      },
      {
        text: "In Play",
        value: JobItemUserStatuses.Scheduled,
      },
      {
        text: "Completed",
        value: JobItemUserStatuses.Complete,
      },
    ],
  },
  [FilterGroupTypeEnum.LoggedTimeStatus]: {
    name: "To Do Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All To Do’s" },
    items: [
      { text: "Scheduled", value: LoggedTimeStatuses.Incomplete },
      { text: "Logged", value: LoggedTimeStatuses.Complete },
    ],
  },
  [FilterGroupTypeEnum.JobItemId]: { name: "Item", type: FilterType.System },
  [FilterGroupTypeEnum.JobItemUserId]: {
    name: "Item Team Member",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.InvoiceSeenStatus]: {
    name: "Viewed",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Viewed",
        displayText: "Has Been Viewed",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Not Viewed",
        displayText: "Has Not Been Viewed",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.InvoiceLabel]: {
    name: "Invoice Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.JobPhaseName]: {
    name: "Phase Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobItemCompletedDate]: {
    name: "Item Completed Date",
    type: FilterType.Date,
    simpleName: "Completed Date",
  },
  [FilterGroupTypeEnum.RateCardName]: {
    name: "Rate Card",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobPurchaseOrderNumber]: {
    name: "Purchase Order Number",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobBranchName]: {
    name: "Job Branch",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.TotalDraftInvoicesExTax]: {
    name: "Draft Invoices Ex Tax",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalDraftInvoicesExTax,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.TotalAwaitingPaymentInvoicesExTax]: {
    name: "Awaiting Payment Invoices",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalAwaitingPaymentInvoicesExTax,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.TotalPaidInvoicesExTax]: {
    name: "Paid Invoices Ex Tax",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalPaidInvoicesExTax,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.TotalIssuedInvoicesExTax]: {
    name: "Issued Invoices Ex Tax",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalIssuedInvoicesExTax,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobMilestoneId]: {
    name: "Milestone",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.JobMilestoneDate]: {
    name: "Milestone Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobHasInvoiceIssuedWithinDate]: {
    name: "Invoice Issue Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobHasInvoiceDueWithinDate]: {
    name: "Invoice Due Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobHasItemDueWithinDate]: {
    name: "Item Due Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobMilestoneName]: {
    name: "Milestone Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.JobItemDueDate]: {
    name: "Item Due Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.LoggedTimeScheduled]: {
    name: "To Do Scheduled",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.LoggedTimeCompleted]: {
    name: "To Do Logged",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.JobCompletedDate]: {
    name: "Job Completed Date",
    type: FilterType.Date,
    simpleName: "Completed Date",
  },
  [FilterGroupTypeEnum.QuoteApprovedDate]: {
    name: "Approved Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.QuoteDeclinedDate]: {
    name: "Declined Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.SentToExternalPlatform]: {
    name: "Sent To Accounts Package",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    forceTitle: true,
    items: [
      {
        text: "Has Been Sent",
        displayText: "Sent",
        valueMatchTypeId: ValueMatchTypeEnum.AnyValue,
      },
      {
        text: "Has Not Been Sent",
        displayText: "Not Sent",
        valueMatchTypeId: ValueMatchTypeEnum.NoValue,
      },
    ],
  },
  [FilterGroupTypeEnum.CommercialDocumentSent]: {
    name: "Sent to Contact",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    forceTitle: true,
    items: [
      {
        text: "Has Been Sent",
        displayText: "Sent",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Has Not Been Sent",
        displayText: "Not Sent",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.CommercialDocumentSentDate]: {
    name: "Sent Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.ContactFullName]: {
    name: "Full Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ContactFirstName]: {
    name: "First Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.ContactLastName]: {
    name: "Last Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.CompanyStatus]: {
    name: "Company Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All Companies" },
    items: [
      {
        text: "Active",
        value: CompanyStatusEnum.Active,
      },
      {
        text: "Archived",
        value: CompanyStatusEnum.Archived,
      },
    ],
  },
  [FilterGroupTypeEnum.ContactStatus]: {
    name: "Contact Status",
    type: FilterType.Checkbox,
    allDescription: { value: "All Contacts" },
    items: [
      {
        text: "Active",
        value: ContactStatuses.Active,
      },
      {
        text: "Archived",
        value: ContactStatuses.Archived,
      },
    ],
  },
  [FilterGroupTypeEnum.JobBudgetVariancePercentage]: {
    name: "Budget Variance (%)",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.BudgetVariance,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalPlannedExpensesCostExTax]: {
    name: "Expenses Planned Cost",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.PlannedExpensesCost,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.JobTotalPlannedExpensesExTax]: {
    name: "Expenses Planned Sell",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.PlannedExpensesSell,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.CompanyLabel]: {
    name: "Company Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.ContactLabel]: {
    name: "Contact Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.JobTotalPlannedExTax]: {
    name: "Total Planned Sell",
    type: FilterType.Number,
    tooltip: ColumnTooltipEnum.TotalPlannedSell,
    containsFinancial: true,
  },
  [FilterGroupTypeEnum.UserDisplayName]: {
    name: "Name",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.UserEmail]: { name: "Email", type: FilterType.Text },
  [FilterGroupTypeEnum.UserBranchName]: {
    name: "Team Member Branch",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.UserRoleName]: {
    name: "Role",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.UserStatus]: {
    name: "User Status",
    type: FilterType.Checkbox,
    defaultDescription: { value: "Active" },
    allDescription: { value: "All Users" },
    items: [
      {
        text: "Active",
        value: UserStatusEnum.Active,
      },
      {
        text: "Hibernated",
        value: UserStatusEnum.Hibernated,
      },
    ],
  },
  [FilterGroupTypeEnum.UserLabel]: {
    name: "Team Member Label",
    type: FilterType.Text,
    disableAnyValue: true,
  },
  [FilterGroupTypeEnum.ExchangeRate]: {
    name: "Exchange Rate",
    type: FilterType.Number,
  },
  [FilterGroupTypeEnum.JobLeadUserDisplayName]: {
    name: "Job Lead",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.CompanyLeadUserDisplayName]: {
    name: "Company Lead",
    type: FilterType.Text,
  },
  [FilterGroupTypeEnum.InvoiceType]: {
    name: "Invoice Type",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    forceTitle: true,
    items: [
      {
        text: "Invoice",
        value: InvoiceTypes.Invoice,
      },
      {
        text: "Credit Note",
        value: InvoiceTypes.CreditNote,
      },
    ],
  },
  [FilterGroupTypeEnum.Billable]: {
    name: "Billable",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Billable",
        displayText: "Is Billable",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Not Billable",
        displayText: "Is Not Billable",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobArchivedDate]: {
    name: "Archived Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.NonBillablePlannedMinutes]: {
    name: "Non-billable Planned",
    type: FilterType.Minutes,
    suffix: "hours",
  },
  [FilterGroupTypeEnum.NonBillableLoggedMinutes]: {
    name: "Non-billable Used",
    type: FilterType.Minutes,
    suffix: "hours",
  },
  [FilterGroupTypeEnum.NonBillablePlannedTimeExTax]: {
    name: "Non-billable Planned",
    type: FilterType.Number,
    containsFinancial: true,
    suffix: "currencySymbol",
  },
  [FilterGroupTypeEnum.NonBillableLoggedTimeExTax]: {
    name: "Non-billable Used",
    type: FilterType.Number,
    containsFinancial: true,
    suffix: "currencySymbol",
  },
  [FilterGroupTypeEnum.CompanyId]: { name: "Company", type: FilterType.System },
  [FilterGroupTypeEnum.UserBranchId]: {
    name: "Team Member Branch",
    type: FilterType.EntityList,
    optionsType: "branches",
    title: "Team Member Branches",
  },
  [FilterGroupTypeEnum.HappinessPillarId]: {
    name: "Happiness Pillar",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.HappinessResponseDate]: {
    name: "Happiness Response Date",
    type: FilterType.Date,
  },
  [FilterGroupTypeEnum.LoggedTimePersonal]: {
    name: "Personal",
    type: FilterType.Checkbox,
    defaultDescription: { value: "Is Not Personal" },
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Personal",
        displayText: "Is Personal",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Not Personal",
        displayText: "Is Not Personal",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.HappinessScore]: {
    name: "Happiness Score",
    type: FilterType.Checkbox,
    allDescription: { value: "" },
    valuesOnly: true,
    forceTitle: true,
    items: [
      {
        text: "😱",
        value: 1,
      },
      {
        text: "🙁",
        value: 2,
      },
      {
        text: "😐",
        value: 3,
      },
      {
        text: "😊",
        value: 4,
      },
      {
        text: "😍",
        value: 5,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemSubItemId]: {
    name: "Checklist Item",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.QuoteId]: {
    name: "Quote",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.InvoiceId]: {
    name: "Invoice",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.UserId]: {
    name: "User",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.RoleId]: {
    name: "Role",
    type: FilterType.EntityList,
    optionsType: "roles",
    title: "Roles",
  },
  [FilterGroupTypeEnum.JobBranchId]: {
    name: "Job Branch",
    type: FilterType.EntityList,
    optionsType: "branches",
    title: "Job Branches",
  },
  [FilterGroupTypeEnum.RateCardId]: {
    name: "Rate Card",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.LoggedExpenseId]: {
    name: "Expense",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.JobLeadUserId]: {
    name: "Job Lead",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.CompanyLeadUserId]: {
    name: "Company Lead",
    type: FilterType.System,
  },
  [FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes]: {
    name: "Job Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "hours",
    items: [
      {
        text: "Job Overtime",
        displayText: "Job Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Job Not Overtime",
        displayText: "Job Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobUsedExceedsPlannedDollars]: {
    name: "Job Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "currencySymbol",
    items: [
      {
        text: "Job Overtime",
        displayText: "Job Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Job Not Overtime",
        displayText: "Job Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemUsedExceedsPlannedMinutes]: {
    name: "Item Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "hours",
    items: [
      {
        text: "Item Overtime",
        displayText: "Item Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Item Not Overtime",
        displayText: "Item Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemUsedExceedsPlannedDollars]: {
    name: "Item Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "currencySymbol",
    items: [
      {
        text: "Item Overtime",
        displayText: "Item Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Item Not Overtime",
        displayText: "Item Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedMinutes]: {
    name: "Team Member Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "hours",
    items: [
      {
        text: "Team Member Overtime",
        displayText: "Team Member Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Team Member Not Overtime",
        displayText: "Team Member Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedDollars]: {
    name: "Team Member Overtime",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    suffix: "currencySymbol",
    items: [
      {
        text: "Item Overtime",
        displayText: "Item Is Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Item Not Overtime",
        displayText: "Item Is Not Overtime",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
  [FilterGroupTypeEnum.JobItemOverBudget]: {
    name: "Item Over Budget",
    type: FilterType.Checkbox,
    containsFinancial: true,
    allDescription: { value: "" },
    valuesOnly: true,
    items: [
      {
        text: "Item Over Budget",
        displayText: "Item Is Over Budget",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanTrue,
      },
      {
        text: "Item Not Over Budget",
        displayText: "Item Is Not Over Budget",
        valueMatchTypeId: ValueMatchTypeEnum.BooleanFalse,
      },
    ],
  },
};

export type FilterSearchOption = SearchOption<FilterGroupTypesProperty>;

export function getFilterName(
  filterGroupTypeId: TFilterGroupTypeEnum,
  customerCurrency: TCurrency,
  simple?: boolean
) {
  let { name } = FilterGroupTypeProperties[filterGroupTypeId];
  const { suffix, simpleName } = FilterGroupTypeProperties[filterGroupTypeId];

  if (simple && simpleName) {
    name = simpleName;
  }

  switch (suffix) {
    case "currencyId":
      return `${name} (${customerCurrency.id})`;
    case "currencySymbol":
      return `${name} (${customerCurrency.symbol})`;
    case "hours":
      return `${name} (H)`;
    default:
      return name;
  }
}
