import { reduce } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { preciseAdd } from "../../../../lib/math";
import { selectUnplannedTimeSummary } from "./unplannedTimeSummary";

export const selectUnplannedTimeJobCurrencyTotalExTax = createSelector(
  [selectUnplannedTimeSummary],
  unplannedTimeSummary =>
    reduce(
      unplannedTimeSummary,
      (total, userSummary) =>
        preciseAdd(total, userSummary.jobCurrencyTotalExTax),
      0
    )
);

export const useSelectUnplannedTimeJobCurrencyTotalExTax = () =>
  useSelector(selectUnplannedTimeJobCurrencyTotalExTax);
