import { plate } from "st-shared/external";

import { ELEMENT_COLUMN } from "./createColumnPlugin";
import { isNodeColumnInput } from "./queries";
import { TColumnElement } from "./types";

export function getColumnOnSelectItem<V extends plate.Value = plate.Value>(
  editor: plate.PlateEditor<V>,
  value: string
) {
  const targetRange = plate.comboboxSelectors.targetRange();
  if (!targetRange) return;

  const pathAbove = plate.getBlockAbove(editor)?.[1];
  const isBlockEnd = () =>
    editor.selection &&
    pathAbove &&
    plate.isEndPoint(editor, editor.selection.anchor, pathAbove);

  plate.withoutNormalizing(editor, () => {
    plate.select(editor, targetRange);

    plate.withoutMergingHistory(editor, () =>
      plate.removeNodes(editor, {
        match: (node) => isNodeColumnInput(editor, node),
      })
    );

    plate.insertNodes<TColumnElement>(editor, {
      type: ELEMENT_COLUMN,
      children: [{ text: "" }],
      value,
    } as TColumnElement);

    // move the selection after the element
    plate.moveSelection(editor, { unit: "offset" });

    if (isBlockEnd()) {
      plate.insertText(editor, " ");
    }
  });

  return plate.comboboxActions.reset();
}
