import { get, isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOBS,
  JOB_EDIT,
  JOB_SAVE,
  JOB_SAVE_ERROR,
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR
} from "../../../../lib/constants";
import { isDeleted } from "../../../../lib/entities/jobEntity";
import { moveDays } from "../../../../lib/entities/scheduleSearchResultEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities)
  };
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOBS],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const dragJobBarReducer = (state, action) => {
  const { jobId, daysMoved } = action.payload;
  const prevEntity = get(state, `byId.${jobId}`);

  if (!prevEntity || daysMoved === 0) return state;

  const changedEntities = [
    {
      prevEntity,
      newEntity: moveDays(prevEntity, daysMoved)
    }
  ];

  return reduceChangedEntities(state, changedEntities);
};

const undoDragJobBarReducer = (state, action) => {
  const { jobId, daysMoved, hasChanged } = action.payload;

  if (!hasChanged) return state;

  const prevEntity = get(state, `byId.${jobId}`);

  if (!prevEntity) return state;

  const changedEntities = [
    {
      prevEntity,
      newEntity: moveDays(prevEntity, -daysMoved)
    }
  ];

  return reduceChangedEntities(state, changedEntities);
};

const editJobReducer = (state, action) => {
  const { jobId, job } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: get(state, `byId.${jobId}`),
      newEntity: job
    }
  ]);
};

const undoSaveJobReducer = (state, action) => {
  const { jobId, previousJob } = action.payload;
  const prevEntity = state.byId[jobId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: previousJob
    }
  ]);
};

const saveJobErrorReducer = (state, action) => {
  if (action.payload.sagaType === JOB_SAVE)
    return undoSaveJobReducer(state, action);

  return state;
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [SCHEDULE_JOB_BAR_DRAG]: dragJobBarReducer,
    [SCHEDULE_JOB_BAR_DRAG_CANCEL]: undoDragJobBarReducer,
    [SCHEDULE_JOB_SHIFT_DURATION_ERROR]: undoDragJobBarReducer,
    [JOB_EDIT]: editJobReducer,
    [JOB_SAVE]: editJobReducer,
    [JOB_SAVE_ERROR]: saveJobErrorReducer
  }
);
