import React from "react";
import { DATE_FORMAT_DATE_MONTH_YEAR } from "../../../../../lib/constants";
import { formatDate } from "../../../../../lib/dates";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataEstimatedEndDate,
  getMetaDataJobItemName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobItemEndDateModified = ({ activityEntry }) => {
  const estimatedEndDate = getMetaDataEstimatedEndDate(activityEntry);
  const jobItemName = getMetaDataJobItemName(activityEntry);
  return (
    <SystemMessage userId={getCreatedByUserId(activityEntry)}>
      {estimatedEndDate ? (
        <>
          {" set the end date for "}
          <b>{jobItemName}</b>
          {" to "}
          <b>{formatDate(estimatedEndDate, DATE_FORMAT_DATE_MONTH_YEAR)}</b>
        </>
      ) : (
        <>
          {" cleared the end date for "}
          <b>{jobItemName}</b>
        </>
      )}
    </SystemMessage>
  );
};

JobItemEndDateModified.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemEndDateModified;
