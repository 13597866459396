import { produce } from "immer";
import { Entity } from "st-shared/entities/Entity";

import { ChangedEntity } from "../entities/types";

function createKeyValueReducer<T extends Entity, U>(
  getKey: (entity: T) => string,
  getValue: (entity: T) => U,
  allowFalseyValues = false
) {
  return function (
    state: { [key: string]: U } = {},
    changedEntities: ChangedEntity<T>[]
  ) {
    return produce(state, (draft: { [key: string]: U }) => {
      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKey(prevEntity);
        const newPath = newEntity && getKey(newEntity);

        if (prevPath && !newPath) {
          delete draft[prevPath];
        }

        if (newPath) {
          const newValue = getValue(newEntity);

          if (!allowFalseyValues && !newValue) {
            delete draft[newPath];
          } else if (newValue !== draft[newPath]) {
            draft[newPath] = newValue;
          }
        }
      });
    });
  };
}

export default createKeyValueReducer;
