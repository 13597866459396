import { get } from "lodash-es";
import { createSelector } from "reselect";
import { ENTITY_NAME_JOBS } from "../../lib/constants";
import { getJobId } from "../../lib/entities/jobItemEntity";
import { selectJobItemById } from "./jobItemSelectors";
import { selectPropsId, selectPropsJobId } from "./index";

export const selectJobs = state => state.entities[ENTITY_NAME_JOBS];

export const selectJobById = createSelector(
  selectJobs,
  selectPropsId,
  (jobs, id) => get(jobs, `byId.${id}`)
);

export const selectJobByJobId = (state, props) =>
  selectJobById(state, { id: selectPropsJobId(state, props) });

export const selectJobByJobItemId = (state, props) => {
  const jobItem = selectJobItemById(state, props);
  return selectJobById(state, { id: getJobId(jobItem) });
};
