import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";

import { updateJobItemStatusAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  JOB_ITEM_STATUS_CHANGE,
  JOB_ITEM_STATUS_CHANGE_CANCEL,
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  JOB_ITEM_STATUS_SAVED,
  JOB_ITEM_STATUS_SAVING,
} from "../../../lib/constants";
import { isJobItemUserComplete } from "../../../lib/entities/jobItemUserEntity";
import { feToWebConfirmCompleteJobItem } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUsersByJobItemId } from "../../selectors/jobItemUserSelectors";

function* changeJobItemStatus(action) {
  const { id, jobItem, jobItemStatus, jobItemUserStatus } = action.payload;
  let removeOverdueIncompleteTodos = false;

  const updatedJobItem = {
    ...jobItem,
    jobItemStatus,
  };
  const updatedJobItemUsers = [];
  const jobItemUsers = yield select(selectJobItemUsersByJobItemId, { id });

  if (jobItemUsers) {
    jobItemUsers.forEach((jobItemUser) => {
      if (isJobItemUserComplete(jobItemUser)) return;
      updatedJobItemUsers.push({
        ...jobItemUser,
        jobItemUserStatus,
      });
    });
  }

  yield put(
    createAction(ENTITIES_RECEIVED, {
      [ENTITY_NAME_JOB_ITEMS]: [updatedJobItem],
      [ENTITY_NAME_JOB_ITEM_USERS]: updatedJobItemUsers,
    })
  );

  try {
    const newJobItem = yield select(selectJobItemById, { id });

    switch (jobItemStatus.id) {
      case JOB_ITEM_STATUS_ID_SCHEDULED:
        removeOverdueIncompleteTodos = true;
        break;
      case JOB_ITEM_STATUS_ID_COMPLETE:
        yield call(feToWebConfirmCompleteJobItem, jobItem);
        break;
      default:
    }

    if (newJobItem.id < 0) return;

    yield put(createAction(JOB_ITEM_STATUS_SAVING, { id }));

    const { data } = yield call(
      updateJobItemStatusAPI,
      newJobItem,
      updatedJobItemUsers,
      removeOverdueIncompleteTodos
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(JOB_ITEM_STATUS_SAVED, { id }));
  } catch (error) {
    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEMS]: [jobItem],
        [ENTITY_NAME_JOB_ITEM_USERS]: jobItemUsers,
      })
    );

    yield put(
      createAction(JOB_ITEM_STATUS_CHANGE_CANCEL, {
        ...action.payload,
        error,
      })
    );

    if (isError(error)) sagaError(error);
  }
}

export default function* watchJobItemStatusChange() {
  yield takeLatestBy(
    [JOB_ITEM_STATUS_CHANGE],
    changeJobItemStatus,
    (action) => action.payload.id
  );
}
