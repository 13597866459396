import { Button } from "st-shared/components";

import { useScheduleSideMenuWidth } from "../UserPreference/sideBarUserPreferences";
import {
  emptyJobMessageContainerCss,
  emptyJobMessageCss,
  emptyJobMessageSpacerCss,
} from "./EmptyJobMessage.css";
import { scehduleGroupTotalsContainerCss } from "./ScheduleGroupTotals/ScheduleGroupTotals.css";

type Props = {
  onClick: () => void;
  canEditJobs: boolean;
};

export function EmptyJobMessage({ onClick, canEditJobs }: Props) {
  const sideMenuWidth = useScheduleSideMenuWidth();

  return (
    <div className={emptyJobMessageContainerCss}>
      <div className={emptyJobMessageSpacerCss} />
      <div className={emptyJobMessageCss}>
        {sideMenuWidth > 330 ? (
          <span>Looks like there are no items on this job. </span>
        ) : (
          <span>No items on this job. </span>
        )}
        {canEditJobs && (
          <Button variant="inlineText" onClick={onClick} style={{ padding: 0 }}>
            Add an item
          </Button>
        )}
      </div>
      <div className={scehduleGroupTotalsContainerCss} />
    </div>
  );
}
