import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  DATA_ATTRIBUTE_JOB_PHASE_BAR,
  JOB_TIMELINE_ITEM_ROW_HEIGHT,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { getJobItemStatusIconClass } from "../../../lib/entities/jobItemEntity";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import {
  selectJobItemOrderByJobIdPhaseId,
  selectPhaseScheduleEndDateByJobPhaseId,
  selectPhaseScheduleStartDateByJobPhaseId,
  selectPhaseStatusByJobPhaseId,
} from "../../../redux/selectors/jobItemSelectors";
import { selectJobPhaseHasDependanciesById } from "../../../redux/selectors/jobPhaseSelectors";
import { selectIsJobItemsLockedByJobId } from "../../../redux/selectors/jobUiSelectors";
import { selectScheduleJobPhaseUi } from "../../../redux/selectors/scheduleSelectors";
import SegmentsByJobPhase from "../../modules/JobItemDependancies/SegmentsByJobPhase";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import Anchor from "../ScheduleScrollProvider/Anchor";
import ScheduleBarConsumer from "../ScheduleScrollProvider/ScheduleBarConsumer";
import { ScheduleBarRect, ScheduleJobPhaseBar } from "./ScheduleBars";
import ScheduleJobItem from "./ScheduleJobItem";

const barAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_PHASE_BAR);

const mapState = (state, props) => ({
  startDate: selectPhaseScheduleStartDateByJobPhaseId(state, props),
  endDate: selectPhaseScheduleEndDateByJobPhaseId(state, props),
  jobPhaseStatusId: selectPhaseStatusByJobPhaseId(state, props),
  jobItemIds: selectJobItemOrderByJobIdPhaseId(state, props),
  isExpanded: selectScheduleJobPhaseUi(state, props).isExpanded,
  forceExpand: selectJobPhaseHasDependanciesById(state, props),
  isJobItemsLocked: selectIsJobItemsLockedByJobId(state, props),
});

const mapDispatch = null;

class ScheduleJobPhase extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    jobPhaseStatusId: entityIdType,
    jobItemIds: entityIdListType.isRequired,
    forceExpand: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool,
    isJobItemsLocked: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isExpanded: false,
    startDate: null,
    endDate: null,
    jobPhaseStatusId: null,
  };

  render() {
    const {
      id,
      jobId,
      startDate,
      endDate,
      jobPhaseStatusId,
      isExpanded,
      forceExpand,
      jobItemIds,
      isJobItemsLocked,
    } = this.props;

    const jobPhaseStatusClassName = getJobItemStatusIconClass(jobPhaseStatusId);

    return (
      <>
        {id > 0 && (
          <JobPhaseContainer>
            <ScheduleBarConsumer
              barStartDate={startDate}
              barEndDate={endDate}
              startDate={startDate}
              dueDate={endDate}
            >
              {({ inner }) => (
                <Anchor>
                  {inner && (
                    <ScheduleBarRect
                      style={{ left: inner.left, width: inner.width }}
                      {...{
                        [barAttrName]: id,
                      }}
                    >
                      <ScheduleJobPhaseBar
                        inner
                        className={jobPhaseStatusClassName}
                      />
                    </ScheduleBarRect>
                  )}
                </Anchor>
              )}
            </ScheduleBarConsumer>
            <Anchor>
              <SegmentsByJobPhase
                Context={ScheduleScrollContext}
                jobId={jobId}
                jobPhaseId={id}
              />
            </Anchor>
          </JobPhaseContainer>
        )}
        {(id === 0 || forceExpand || isExpanded) &&
          jobItemIds.map((jobItemId) => (
            <ScheduleJobItem
              key={jobItemId}
              id={jobItemId}
              isLocked={isJobItemsLocked}
            />
          ))}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobPhase);

const JobPhaseContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  &:not(:last-child) {
    border-bottom: var(--border-thin-bright);
  }
`;
