import { call, put, takeEvery } from "redux-saga/effects";
import { fetchLabelOptionsApi } from "../../../lib/API/optionsAPI";
import {
  ENTITIES_RECEIVED,
  LABELS_FETCH_REQUEST
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetch(action) {
  try {
    const { labelTypeId } = action.payload;
    const data = yield call(fetchLabelOptionsApi, labelTypeId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchFetchLabelOptions() {
  yield takeEvery(LABELS_FETCH_REQUEST, fetch);
}
