import { uuid } from "@streamtimefe/utils";
import { useState } from "react";

export function useUniqueKey(): [string, () => void] {
  const [uniqueKey, setUniqueKey] = useState(uuid());

  function generateNewKey() {
    setUniqueKey(uuid());
  }

  return [uniqueKey, generateNewKey];
}
