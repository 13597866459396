import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  timeAllocationMethodObj,
  TimeAllocationMethods,
} from "st-shared/entities";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_TIME_ALLOCATION_METHOD,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  convertMinutesToHours,
  convertMinutesToTimeHM,
} from "../../../lib/time";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { actionJobItemRoleSetHours } from "../../../state/entities/jobItemRole/actions";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemRoleId } from "../context/JobItemRoleIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import {
  itemHoursContainerCss,
  itemHoursFieldCss,
} from "../JobItem/ItemHours.css";

export function JobItemRoleHours() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemRoleId = useJobItemRoleId();
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const timeAllocationMethod = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  );

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemRoleId,
    jobItemId,
    key: FOCUS_KEYS.ITEM_ROLE_HOURS,
  });

  const [hours, setHours] = useState("");

  const value = focused
    ? hours
    : convertMinutesToTimeHM(jobItemRole.totalPlannedMinutes);

  const dispatch = useDispatch();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (!isJobEditable) return;
    const newValue = event.target.value;

    setHours(newValue);

    dispatch(actionJobItemRoleSetHours(jobItemRoleId, newValue));

    if (!timeAllocationMethod.isPeople())
      dispatch(
        createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
          jobItemId,
          timeAllocationMethodId: TimeAllocationMethods.People,
        })
      );
  }

  function handleBlur() {
    setFocused(false);
  }

  function handleFocus() {
    setHours(convertMinutesToHours(jobItemRole.totalPlannedMinutes));
    setFocused(true);
  }

  function setTimeAllocationMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          jobItemRoleId,
          key: FOCUS_KEYS.ITEM_ROLE_HOURS,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
        jobItemId,
        timeAllocationMethodId: TimeAllocationMethods.People,
      })
    );
  }

  return (
    <div className={itemHoursContainerCss}>
      <ItemInputField
        ref={ref}
        as="time"
        containerClassName={itemHoursFieldCss}
        value={value}
        placeholder="0h"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabledLook={!timeAllocationMethod.isPeople()}
        disabled={!isJobEditable}
      />
      {isJobEditable && !timeAllocationMethod.isPeople() && (
        <MethodLock
          tooltip={
            <>
              Planned time for this item is a{" "}
              <strong>
                shared total of hours for all assigned team members.
              </strong>{" "}
              Click to change and plan hours by each team member.
            </>
          }
          className={methodLockIcon}
          onClick={setTimeAllocationMethod}
          isTime
        />
      )}
    </div>
  );
}
