import { produce } from "immer";
import { get, setWith, sortBy } from "lodash-es";
import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedInvoiceIds) =>
  produce(nextState, draft => {
    touchedInvoiceIds.forEach(invoiceId => {
      const invoiceLineItems = get(
        nextState,
        `idsByInvoiceId.${invoiceId}`,
        []
      ).map(id => nextState.byId[id]);
      const orderedInvoiceLineItems = sortBy(invoiceLineItems, "orderId");

      let count = 0;
      orderedInvoiceLineItems.forEach(invoiceLineItem => {
        count += 1;

        if (getOrderId(invoiceLineItem) !== count) {
          const newInvoiceLineItem = {
            ...invoiceLineItem,
            orderId: count
          };

          setWith(
            draft,
            `byId.${invoiceLineItem.id}`,
            newInvoiceLineItem,
            Object
          );
        }
      });
    });
  });
