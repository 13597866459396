import * as PropTypes from "prop-types";
import { currencyEntityType } from "./currencyEntity";

export const rateCardEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currency: currencyEntityType.isRequired,
  exchangeRate: PropTypes.number.isRequired,
  archived: PropTypes.bool.isRequired
});

export const getName = ({ name }) => name;
