import * as PropTypes from "prop-types";

import { entityIdType } from "../types/entityTypes";
import { countryEntityType } from "./countryEntity";
import { currencyEntityType } from "./currencyEntity";

export const organisationEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  subscriberUserId: entityIdType.isRequired,
  billingCurrency: currencyEntityType.isRequired,
  customerCurrency: currencyEntityType.isRequired,
  address: PropTypes.string,
  country: countryEntityType.isRequired,
  canAccessCollaboration: PropTypes.bool.isRequired,
  canAccessSchedule: PropTypes.bool.isRequired,
  canIntegrateWithAccountingPackage: PropTypes.bool.isRequired,
  lastJobNumber: PropTypes.string,
});

export const getLastJobNumber = ({ lastJobNumber }) => lastJobNumber;

export const getIsCustomJobNumberingMethod = ({ jobNumberingMethod }) =>
  jobNumberingMethod.id === 3;
