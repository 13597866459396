import { isEqual, keys } from "lodash-es";

import {
  JOB_EDIT,
  JOB_SAVE,
  JOB_SAVE_ERROR,
  JOB_SAVED,
  JOB_SAVING,
} from "../../../../../lib/constants";
import { updateEditMode } from "./updateEditMode";

const actions = [JOB_EDIT, JOB_SAVE, JOB_SAVING, JOB_SAVED, JOB_SAVE_ERROR];

export function jobCheck(action: any, previousState: any, nextState: any) {
  if (
    actions.includes(action.type) &&
    !isEqual(previousState.ui.jobs, nextState.ui.jobs)
  ) {
    const jobs = nextState.ui.jobs;

    let isEditing = false;

    for (const key of keys(jobs)) {
      if (jobs[key].isEditing || jobs[key].isSaving) {
        isEditing = true;
        break;
      }
    }

    updateEditMode(isEditing);
  }
}
