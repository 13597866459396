import { isEqual, keys } from "lodash-es";

import {
  JOB_PHASE_EDIT,
  JOB_PHASE_SAVE,
  JOB_PHASE_SAVE_ERROR,
  JOB_PHASE_SAVED,
  JOB_PHASE_SAVING,
} from "../../../../../lib/constants";
import { updateEditMode } from "./updateEditMode";

const actions = [
  JOB_PHASE_EDIT,
  JOB_PHASE_SAVE,
  JOB_PHASE_SAVING,
  JOB_PHASE_SAVED,
  JOB_PHASE_SAVE_ERROR,
];

export function jobPhaseCheck(action: any, previousState: any, nextState: any) {
  if (
    actions.includes(action.type) &&
    !isEqual(previousState.ui.jobPhases, nextState.ui.jobPhases)
  ) {
    const jobPhases = nextState.ui.jobPhases;

    let isEditing = false;

    for (const key of keys(jobPhases)) {
      if (jobPhases[key].isEditing || jobPhases[key].isSaving) {
        isEditing = true;
        break;
      }
    }

    updateEditMode(isEditing);
  }
}
