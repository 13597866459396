import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { IconSize, Tooltip } from "st-shared/components";
import { ReactAria } from "st-shared/external/react-aria";
import { useOnClickOutside } from "st-shared/external/usehooks";
import { OpenSetStatusButton, SetStatusButton } from "st-shared/module";
import { StatusType, TStatusType } from "st-shared/types";
import styled from "styled-components";

import {
  openButtonHoverCss,
  setButtonsContainerCss,
  switcherButtonCss,
} from "./StatusComponent.css";

interface Props {
  statusId: TStatusType;
  setPlanning: () => void;
  setSchedule: () => void;
  setComplete: () => void;
  locked?: boolean;
  lockedTooltipText?: string;
  disabled?: boolean;
  iconSize?: number;
  horizontalPadding?: number;
}

export function StatusComponent({
  statusId,
  setPlanning,
  setSchedule,
  setComplete,
  locked = false,
  lockedTooltipText = "",
  disabled = false,
  iconSize = IconSize.XLarge,
  horizontalPadding = 13,
}: Props) {
  const [open, setOpen] = useState(false);

  function setStatus(status: TStatusType) {
    if (disabled) return;

    switch (status) {
      case StatusType.Planning:
        setPlanning();
        break;
      case StatusType.Complete:
        setComplete();
        break;
      case StatusType.Scheduled:
        setSchedule();
        break;
    }
  }

  function onClose() {
    if (open) {
      setOpen(false);
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  const { pressProps } = ReactAria.usePress({
    onPress: onClose,
  });

  useOnClickOutside(ref, onClose);

  return (
    <Container
      animate={{
        width: horizontalPadding * 2 + (open ? iconSize * 3 : iconSize),
      }}
      transition={{ ease: "easeInOut", delay: open ? 0 : 0.4, duration: 0.4 }}
      initial={false}
    >
      {disabled && (
        <OpenSetStatusButton
          disabled={true}
          statusType={statusId}
          size={iconSize}
        />
      )}
      {!disabled && (
        <>
          <AbsoluteMotionDiv
            animate={{ opacity: open ? 0 : 1 }}
            transition={{
              ease: "easeInOut",
              delay: open ? 0 : 0.4,
              duration: 0.4,
            }}
            initial={false}
            style={{ pointerEvents: open ? "none" : "auto" }}
          >
            {!locked && (
              <OpenSetStatusButton
                onClick={() => setOpen(true)}
                disabled={disabled}
                statusType={statusId}
                size={iconSize}
                hoveredClassName={openButtonHoverCss}
              />
            )}
            {locked && (
              <Tooltip title={lockedTooltipText} placement="left">
                <div>
                  <OpenSetStatusButton
                    disabled={true}
                    statusType={statusId}
                    size={iconSize}
                  />
                </div>
              </Tooltip>
            )}
          </AbsoluteMotionDiv>
          <AbsoluteMotionDiv
            animate={{ opacity: open ? 1 : 0 }}
            transition={{
              ease: "easeInOut",
              delay: open ? 0.4 : 0,
              duration: 0.4,
            }}
            initial={false}
            style={{ pointerEvents: open ? "auto" : "none" }}
          >
            <div className={setButtonsContainerCss} ref={ref} {...pressProps}>
              <SetStatusButton
                onClick={() => setStatus(StatusType.Planning)}
                currentStatusType={statusId}
                statusType={StatusType.Planning}
                size={iconSize}
                className={switcherButtonCss}
              />
              <SetStatusButton
                onClick={() => setStatus(StatusType.Scheduled)}
                currentStatusType={statusId}
                statusType={StatusType.Scheduled}
                size={iconSize}
                className={switcherButtonCss}
              />
              <SetStatusButton
                onClick={() => setStatus(StatusType.Complete)}
                currentStatusType={statusId}
                statusType={StatusType.Complete}
                size={iconSize}
                className={switcherButtonCss}
              />
            </div>
          </AbsoluteMotionDiv>
        </>
      )}
    </Container>
  );
}

const Container = styled(motion.div).attrs({
  className: "StatusComponentContainer",
})`
  position: relative;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsoluteMotionDiv = styled(motion.div)`
  position: absolute;
`;
