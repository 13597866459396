/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosResponse } from "axios";

export class ApiError extends Error {
  response: AxiosResponse<unknown, any>;

  constructor(message: string, response: AxiosResponse<unknown, any>) {
    super(message);
    this.response = response;
  }
}
