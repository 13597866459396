import { RawAxiosRequestHeaders } from "axios";

import { getUserTimeZone } from "../intl";
import { getAuthToken } from "./getAuthToken";

export function getRequestHeaders(): RawAxiosRequestHeaders {
  const headers: RawAxiosRequestHeaders = {
    Authentication: getAuthToken(),
  };
  if (getUserTimeZone()) headers.Timezone = getUserTimeZone();
  return headers;
}
