import numeral from "numeral";
import { useSelector } from "react-redux";
import { costingMethodObj } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";

import {
  NUMBER_FORMAT_NO_DECIMAL,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../../../lib/constants";
import { getJobId } from "../../../lib/entities/jobItemEntity";
import {
  getJobCurrencySellRate,
  getUserId,
} from "../../../lib/entities/jobItemUserEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectJobItem } from "../jobItem";
import { selectJobItemRate } from "../jobItem/selectJobItemRateInput";
import { selectIsEditingJobItem } from "../jobItem/ui/isEditing";
import { selectUserSellRate } from "../user/selectUserSellRate";
import { selectJobItemUser } from "./index";

export const getInitialItemUserRateInput = (jobItemUser) =>
  numeral(numeral(getJobCurrencySellRate(jobItemUser)).value()).format(
    NUMBER_FORMAT_TWO_DECIMALS
  );

export const selectJobItemUserRateInput = (
  state,
  { jobItemUserId, isFocused, inputValue }
) => {
  const jobItemUser = selectJobItemUser(state, { jobItemUserId });
  const { jobItemId } = jobItemUser;
  const jobItem = selectJobItem(state, { jobItemId });
  const isEditing = selectIsEditingJobItem(state, { jobItemId });
  const customerCurrency = selectCustomerCurrency(state);
  const jobCurrency = selectJobCurrency(state, { jobId: getJobId(jobItem) });
  const costingMethod = costingMethodObj(jobItem.costingMethod);

  let value = "";
  let placeholder = "";
  let showTooltip = false;

  if (costingMethod.isBasedByUser()) {
    const jobCurrencySellRate = numeral(
      getJobCurrencySellRate(jobItemUser)
    ).value();

    if (jobCurrencySellRate === 0) {
      value = "";
    } else if (isFocused) {
      value = inputValue;
    } else {
      value = formatForeignCurrency(customerCurrency, {
        value: jobCurrencySellRate,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });
    }
  } else {
    // the rates should only show for costing method people
    const jobCurrencySellRate = selectJobItemRate(state, {
      jobId: getJobId(jobItem),
      jobItemId,
    });

    // fallback rate
    if (jobCurrencySellRate.length === 0) {
      const roleRate = selectUserSellRate(state, {
        userId: getUserId(jobItemUser),
        jobId: getJobId(jobItem),
      });

      value = formatForeignCurrency(customerCurrency, {
        value: roleRate,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });

      showTooltip = true;
    }
  }

  if (!costingMethod.isBasedByUser()) {
    placeholder = "";
  } else if (!isEditing) {
    placeholder = "Add rate";
  } else if (value.length > 0) {
    placeholder = "";
  } else {
    placeholder = formatForeignCurrency(customerCurrency, {
      value: 0,
      currency: jobCurrency,
    });
  }

  if (costingMethod.isValueCalculatedSell()) {
    const roleRate = selectUserSellRate(state, {
      userId: getUserId(jobItemUser),
      jobId: getJobId(jobItem),
    });

    placeholder = formatForeignCurrency(customerCurrency, {
      value: roleRate,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }

  return [value, placeholder, showTooltip];
};

export const useJobItemUserRateInput = (jobItemUserId, isFocused, inputValue) =>
  useSelector((state) =>
    selectJobItemUserRateInput(state, {
      jobItemUserId,
      isFocused,
      inputValue,
    })
  );
