import { isNull, pick, round } from "lodash-es";
import numeral from "numeral";
import * as PropTypes from "prop-types";
import { applyInverseExchangeRate } from "st-shared/lib";

import {
  DATE_FORMAT_MONTH_YEAR_ISO,
  EMPTY_ARRAY,
  INVOICE_PAYMENT_TERM_ID_CUSTOM,
  INVOICE_STATUS_ID_DELETED,
  INVOICE_STATUS_ID_VOIDED,
  INVOICE_TYPE_CREDIT_NOTE,
} from "../constants";
import { formatDate } from "../dates";
import { asDecimal, round2dp } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import { addressEntityType } from "./addressEntity";
import { contactEntityType } from "./contactEntity";
import { currencyEntityType } from "./currencyEntity";

export const invoiceShape = {
  id: entityIdType.isRequired,
  currency: currencyEntityType,
  exchangeRate: entityFieldDecimalType,
  jobId: entityIdType.isRequired,
  contact: contactEntityType,
  address: addressEntityType,
  name: PropTypes.string,
  number: PropTypes.string,
  introduction: PropTypes.string,
  languageOptions: PropTypes.string,
  discount: entityFieldDecimalType,
  purchaseOrderNumber: PropTypes.string,
  terms: PropTypes.string,
  invoiceType: typeEntityType.isRequired,
  invoiceStatus: typeEntityType.isRequired,
  invoiceCurrencyTotalAmountExTax: entityFieldDecimalType,
  invoiceCurrencyTotalAmountIncTax: entityFieldDecimalType,
  invoiceDate: PropTypes.string,
  dueDate: PropTypes.string,
  paymentDetails: PropTypes.string,
  paymentTerm: typeEntityType.isRequired,
  instalment: entityFieldDecimalType,
  reference: PropTypes.string,
};

export const invoiceEntityType = PropTypes.shape(invoiceShape);

export const getInvoiceStatusId = ({ invoiceStatus }) => invoiceStatus.id;

export const getInvoiceTypeId = ({ invoiceType }) => invoiceType.id;

export const isInvoiceTypeCreditNote = ({ invoiceType }) =>
  invoiceType && invoiceType.id === INVOICE_TYPE_CREDIT_NOTE;

export const getPaymentTermId = ({ paymentTerm }) => paymentTerm.id;

export const getJobIdInvoiceDateKeyPath = ({ jobId, invoiceDate }) =>
  `["${jobId}.${invoiceDate}"]`;

export const getInvoiceFullName = ({ name, number }) =>
  `${number ? `[${number}] ` : ""}${name}`;

export const getJobIdInvoiceMonthKeyPath = ({ jobId, invoiceDate }) =>
  `["${jobId}.${formatDate(invoiceDate, DATE_FORMAT_MONTH_YEAR_ISO)}"]`;

export const isDeleted = ({ invoiceStatus }) =>
  invoiceStatus.id === INVOICE_STATUS_ID_DELETED ||
  invoiceStatus.id === INVOICE_STATUS_ID_VOIDED;

export const calculateInstalment = (instalmentPc, totalExTax) =>
  isNull(instalmentPc)
    ? totalExTax
    : round(
        numeral(asDecimal(totalExTax))
          .multiply(asDecimal(instalmentPc))
          .divide(100)
          .value(),
        2
      );

const getDisplayOptions = (invoice) =>
  pick(invoice, [
    "displayQuantities",
    "displayHeadings",
    "displayHeadingDescriptions",
    "displayHeadingSubtotals",
    "displayLineItems",
    "displayLineItemDescriptions",
    "displayUnitRates",
    "displaySubtotals",
    "displayJobNumber",
    "displayPurchaseOrderNumber",
    "displayCurrencySymbols",
    "displayIntro",
    "displayTerms",
    "displayBranchExternalName",
    "displayLineItemsInclusiveOfTax",
    "displayTaxNameOnLineItems",
    "displayPaymentDetails",
    "displayProgressDetails",
    "displayReference",
    "displayPaymentSummary",
    "displayPreviousInvoiceSummary",
  ]);

const LANGUAGE_OPTION_DEFAULTS = {
  invoice: "Tax Invoice",
  creditNote: "Credit Note",
  purchaseOrderNumber: "PO Number",
  reference: "Reference",
  issuedOn: "Issued on",
  dueOn: "Due on",
  subtotal: "Subtotal",
  discount: "Discount",
  instalment: "Instalment",
  total: "Total",
  headerFrom: "Your invoice from",
  from: "from",
  hasSent: "has sent you an invoice.",
  view: "Use the link below to check it out.",
  number: "Invoice Number",
};

const getLanguageOptions = ({ languageOptions, ...rest }) => {
  const options = JSON.parse(languageOptions);

  return Object.assign(
    {},
    LANGUAGE_OPTION_DEFAULTS,
    pick(options, [
      "invoice",
      "creditNote",
      "jobNumber",
      "number",
      "purchaseOrderNumber",
      "reference",
      "issuedOn",
      "dueOn",
      "subtotal",
      "discount",
      "instalment",
      "total",
      "lessPaid",
      "amountDue",
      "sourceTotal",
      "previouslyInvoiced",
      "currentInvoice",
    ])
  );
};

export const invoiceDocumentMethods = {
  getLineItemParentId: ({ id }) => id,
  getAddress: ({ address }) => address,
  getNumber: ({ number }) => number,
  setNumber: (document, value) => ({ ...document, number: value }),
  getName: ({ name }) => name,
  setName: (document, value) => ({ ...document, name: value }),
  getPurchaseOrderNumber: ({ purchaseOrderNumber }) =>
    purchaseOrderNumber || "",
  setPurchaseOrderNumber: (document, value) => ({
    ...document,
    purchaseOrderNumber: value,
  }),
  getIntroduction: ({ introduction }) => introduction || "",
  setIntroduction: (document, value) => ({ ...document, introduction: value }),
  getTerms: ({ terms }) => terms || "",
  setTerms: (document, value) => ({ ...document, terms: value }),
  getInstalment: ({ instalment }) =>
    isNull(instalment) ? "" : String(instalment),
  setInstalment: (document, value) => ({
    ...document,
    instalment: isNull(value) || value.trim() === "" ? null : asDecimal(value),
  }),
  getDiscount: ({ discount }) => asDecimal(discount || 0),
  setDiscount: (document, value) => ({
    ...document,
    discount: asDecimal(value),
  }),
  getInvoiceDate: ({ invoiceDate }) => invoiceDate,
  setInvoiceDate: (document, value) => ({ ...document, invoiceDate: value }),
  getInvoiceDueDate: ({ dueDate }) => dueDate,
  setInvoiceDueDate: (document, value) => ({
    ...document,
    dueDate: value,
    paymentTerm: { id: INVOICE_PAYMENT_TERM_ID_CUSTOM, name: "Custom" },
  }),
  getInvoiceReference: ({ reference }) => reference,
  setInvoiceReference: (document, value) => ({ ...document, reference: value }),
  getPaymentDetails: ({ paymentDetails }) => paymentDetails,
  setPaymentDetails: (document, value) => ({
    ...document,
    paymentDetails: value,
  }),
  getDisplayOptions,
  getLanguageOptions,
  setDisplayAndLanguageOptions: (document, data) => ({
    ...document,
    languageOptions: data.languageOptions,
    ...getDisplayOptions(data),
  }),
  getInvoiceCurrencyAmountPaidIncTax: ({ invoiceCurrencyAmountPaidIncTax }) =>
    invoiceCurrencyAmountPaidIncTax,
};

export const getInvoiceCurrencyTotalAmountExTax = ({
  invoiceCurrencyTotalAmountExTax,
}) => invoiceCurrencyTotalAmountExTax;

export const getExchangeRate = ({ exchangeRate }) => exchangeRate;

export const getJobCurrencyTotalAmountExTax = (invoice) =>
  round2dp(
    applyInverseExchangeRate(
      getInvoiceCurrencyTotalAmountExTax(invoice),
      getExchangeRate(invoice)
    )
  );

export function getUpcomingDates(entity) {
  if (entity.invoiceDate === null) return EMPTY_ARRAY;
  return [
    {
      id: `invoice-${entity.id}`,
      entityId: entity.id,
      type: "invoice",
      name: getInvoiceFullName(entity),
      secondary: "issued",
      date: entity.invoiceDate,
    },
  ];
}
