import { put, select, takeLatest } from "redux-saga/effects";
import {
  LOGGED_EXPENSE_SET_CONTACT,
  LOGGED_EXPENSE_SAVE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectContactById } from "../../selectors/contactSelectors";
import { selectLoggedExpenseById } from "../../selectors/loggedExpenseSelectors";

function* setContact(action) {
  try {
    const { id, contactId } = action.payload;

    const loggedExpense = yield select(selectLoggedExpenseById, { id });

    const contact = yield select(selectContactById, { id: contactId });

    yield put(
      createAction(LOGGED_EXPENSE_SAVE, {
        id,
        prevLoggedExpense: loggedExpense,
        loggedExpense: {
          ...loggedExpense,
          contact
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLoggedExpenseSetContact() {
  yield takeLatest(LOGGED_EXPENSE_SET_CONTACT, setContact);
}
