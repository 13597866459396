import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Checkbox from "../../elements/Checkbox";

const ChecklistLabelItem = ({ id, name, checked, disabled, onChange }) => {
  return (
    <Container>
      <StyledCheckedBox
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        tabIndex={-1}
      />
      <CheckboxLabel htmlFor={id} $disabled={disabled}>
        {name}
      </CheckboxLabel>
    </Container>
  );
};

ChecklistLabelItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChecklistLabelItem;

const Container = styled.div`
  color: black;
  font-weight: 600;
  font-size: 12px;
  height: 36px;
  width: 240px;

  display: flex;
  align-items: center;
  padding-left: 9px;
`;

const CheckboxLabel = styled.label`
  margin: 2px 0 0 2px;

  ${props => !props.$disabled && "&:hover { cursor: pointer; }"}
`;

const StyledCheckedBox = styled(Checkbox)`
  align-self: center;
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;
