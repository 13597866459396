import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsBottomSectionTab = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.bottomSectionTab
);

export const useJobDetailsBottomSectionTab = () =>
  useSelector(selectJobDetailsBottomSectionTab);
