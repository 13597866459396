/* eslint-disable @typescript-eslint/no-explicit-any */
import { Exception } from "./Exception";

export class ApiException extends Exception {
  response: any;

  constructor(message: string, response: any, stack?: string) {
    super(message, stack);
    this.response = response;
  }
}
