import { IconButton } from "../../elements/Button";
import ExpandMoreIcon from "../../elements/Icons/ExpandMoreIcon";
import { ICON_SIZE } from "../StyledIcon";
import * as styles from "./GroupedExpander.css";

type Props = {
  title: string;
  open: boolean;
  setOpen: (v: boolean) => void;
};

export function GroupedExpander({ title, open, setOpen }: Props) {
  return (
    <div className={styles.container}>
      <span>{title}</span>
      <IconButton onClick={() => setOpen(!open)} tabIndex="-1">
        <ExpandMoreIcon
          start={360}
          end={180}
          className={open ? "expanded" : ""}
          size={ICON_SIZE.X_LARGE}
        />
      </IconButton>
    </div>
  );
}
