import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMemo } from "react";

import { TSegmentFolder } from "../state/savedSegmentSidebarUserPreference";
import { SortTypes } from "../types";
import { folderSegmentsCss, noSegmentsCss } from "./FolderSegments.css";
import { Segment } from "./Segment";
import { SortId } from "./SortId";
type Props = {
  folder: TSegmentFolder;
  isSortable: boolean;
};

export function FolderSegments({ folder, isSortable }: Props) {
  const folderSortItems = useMemo(
    () =>
      folder.segmentIds.map((segmentId) =>
        SortId.fromEntity(segmentId, SortTypes.FolderSegment).getValue()
      ),
    [folder.segmentIds]
  );

  if (folder.segmentIds.length === 0) {
    return (
      <div className={noSegmentsCss}>
        This folder is empty. Pin a report by dragging it from All reports
      </div>
    );
  }

  if (isSortable) {
    return (
      <div className={folderSegmentsCss}>
        <SortableContext
          items={folderSortItems}
          strategy={verticalListSortingStrategy}
        >
          {folder.segmentIds.map((segmentId) => (
            <Segment
              key={segmentId}
              segmentId={segmentId}
              folderId={folder.id}
            />
          ))}
        </SortableContext>
      </div>
    );
  }

  return (
    <div className={folderSegmentsCss}>
      {folder.segmentIds.map((segmentId) => (
        <Segment key={segmentId} segmentId={segmentId} folderId={folder.id} />
      ))}
    </div>
  );
}
