// deprecated

import type { TSearchItem } from "@streamtimefe/utils";

import { searchWordMatches } from "../../lib";
import type { Entity, TEntityId } from "../Entity";

export interface SearchOption<T = any> {
  key: TEntityId;
  value: string;
  searchString: string;
  entity: T;
}

export function defaultExcludeOptions<
  E extends Entity,
  SO extends SearchOption<E> = SearchOption<E>,
>(options: SO[], excludeIds: TEntityId[]) {
  return options.filter(({ key }) => !excludeIds.includes(key));
}

export function defaultSearchOptions<
  E extends Entity,
  SO extends SearchOption<E> = SearchOption<E>,
>(options: SO[], query: string) {
  return options.filter(({ searchString }) =>
    searchWordMatches(searchString, query)
  );
}

export function transformToOldSearchOptions<T>(
  searchItems: TSearchItem<T>[]
): SearchOption<T>[] {
  return searchItems.map((searchItem) => ({
    key: searchItem.id as TEntityId,
    value: searchItem.value,
    searchString: searchItem.search,
    entity: searchItem.item,
  }));
}
