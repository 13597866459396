import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "../../../AppState";
import { selectJobItemRolesByJobId } from "../../jobItemRole/selectors/selectJobItemRolesByJobId";

export const selectJobTotalUnassignedTime = createSelector(
  [selectJobItemRolesByJobId],
  (jobItemRoles) => {
    return jobItemRoles
      .map((jobItemRole) => jobItemRole.totalPlannedMinutes)
      .reduce((value, total) => total + value, 0);
  }
);

export function useJobTotalUnassignedTime(jobId: number) {
  return useSelector((state: AppState) =>
    selectJobTotalUnassignedTime(state, { jobId })
  );
}
