import { EntityDescriptorEnum } from "@streamtimefe/entities";
import type { ReportingSavedSegment } from "st-shared/entities";

export function isUserAvailabilityAllTime(
  columnId: string,
  search: ReportingSavedSegment
) {
  const column = search.columns[columnId];
  if (search.dates === null && column && "dataSetId" in column) {
    const dataset = search.dataSets[column.dataSetId];
    if (dataset.entityDescriptorId === EntityDescriptorEnum.User) {
      return true;
    }
  }
  return false;
}
