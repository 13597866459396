import { pluralise } from "./pluralise";

export function asString(value: any) {
  return String(value || "").trim();
}

export function toLowerCaseString(value: any) {
  return asString(value).toLowerCase();
}

export function searchWordMatches(searchString: any, searchQuery: any) {
  return toLowerCaseString(searchString).includes(
    toLowerCaseString(searchQuery)
  );
}

export function anySearchWordMatches(searchString: any, searchQuery: any) {
  return asString(searchQuery)
    .split(" ")
    .some((word) => word.length > 0 && searchWordMatches(searchString, word));
}

export function encodeString(value?: string) {
  if (value) {
    return value.replace(
      /[\u00A0-\u9999<>&]/gim,
      (i: string) => `&#${i.charCodeAt(0)};`
    );
  }
  return "";
}

export function defaultSort(a: any, b: any) {
  return toLowerCaseString(a).localeCompare(toLowerCaseString(b));
}

export function defaultSortOptions(a: { value: any }, b: { value: any }) {
  return toLowerCaseString(a.value).localeCompare(toLowerCaseString(b.value));
}

export function wordsEqual(word1: string, word2: string) {
  return (
    pluralise(2, toLowerCaseString(word1)) ===
    pluralise(2, toLowerCaseString(word2))
  );
}

export function convertNewLinesToHtml(string: string) {
  return asString(string).replace(/\n/g, "<br/>");
}

export function uppercaseFirstLetter(word: string) {
  if (word.length === 0) return word;
  return word[0].toUpperCase() + word.slice(1);
}

export function areOrIs(value: number) {
  return Math.round(value) == 1 ? "is" : "are";
}

export function pluralizeJob(value: number) {
  return Math.round(value) == 1 ? "job" : "jobs";
}

export function pluralizeDay(value: number) {
  return Math.round(value) == 1 ? "day" : "days";
}

export function pluralizeItem(value: number) {
  return Math.round(value) == 1 ? "item" : "items";
}
