import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemOrderByJobIdPhaseId } from "../../../redux/selectors/jobItemSelectors";
import { selectJobPhaseHasDependanciesById } from "../../../redux/selectors/jobPhaseSelectors";
import { selectJobTimelinePhaseUi } from "../../../redux/selectors/jobTimelineSelectors";
import JobTimelineItemGroup from "./JobTimelineItemGroup";
import JobTimelinePhaseGroup from "./JobTimelinePhaseGroup";

const mapState = (state, props) => ({
  jobItemIdOrder: selectJobItemOrderByJobIdPhaseId(state, props),
  isCollapsed: selectJobTimelinePhaseUi(state, props).isCollapsed,
  forceExpand: selectJobPhaseHasDependanciesById(state, props)
});

const mapDispatch = null;

class JobTimelinePhaseGroupRow extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    height: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    readOnly: PropTypes.bool.isRequired,
    jobItemIdOrder: entityIdListType.isRequired,
    forceExpand: PropTypes.bool.isRequired,
    isCollapsed: PropTypes.bool
  };

  static defaultProps = {
    isCollapsed: false
  };

  render() {
    const {
      id,
      jobId,
      height,
      top,
      jobItemIdOrder,
      readOnly,
      forceExpand,
      isCollapsed
    } = this.props;

    const jobItemIds = isCollapsed && !forceExpand ? [] : jobItemIdOrder;
    return (
      <JobTimelineRowCell style={{ height, top }}>
        {id > 0 && (
          <JobTimelinePhaseGroup id={id} jobId={jobId} readOnly={readOnly} />
        )}
        {jobItemIds.map(jobItemId => (
          <JobTimelineItemGroup key={jobItemId} id={jobItemId} />
        ))}
      </JobTimelineRowCell>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelinePhaseGroupRow);

export const JobTimelineRowCell = styled.div.attrs({
  className: "JobTimelineRowCell"
})`
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: var(--border-thin-bright);
`;
