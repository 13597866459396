import { useRef, useState } from "react";
import { Icon, IconSize, MdAdd } from "st-shared/components";

import { AddColumnMenu } from "../menus/AddColumnMenu";
import { headerAddColumnContainer, headerColumnText } from "./TableHeader.css";

interface Props {
  zIndex: number;
}

export function AddColumn({ zIndex }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [addAnchorEl, setAddAnchorEl] = useState<HTMLDivElement | null>(null);

  function openAddMenu() {
    setAddAnchorEl(ref.current);
  }

  function closeAddMenu() {
    setAddAnchorEl(null);
  }

  return (
    <>
      <div
        className={headerAddColumnContainer}
        onClick={openAddMenu}
        ref={ref}
        style={{ zIndex }}
      >
        <Icon icon={MdAdd} size={IconSize.Large} />
        <span className={headerColumnText}>Add Column</span>
      </div>
      <AddColumnMenu
        anchorEl={ref.current!}
        listAnchorEl={addAnchorEl}
        onClose={closeAddMenu}
      />
    </>
  );
}
