import { delay, put, select, takeEvery } from "redux-saga/effects";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_REVERT,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SET_PREVIOUS_JOB_ITEM,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobDetailsUiCurrentFocusJobItemId } from "../../selectors/jobDetails/ui/currentFocus";
import {
  selectJobDetailsUiPreviousFocusJobItemId,
  selectJobDetailsUiPreviousFocusKey,
} from "../../selectors/jobDetails/ui/previousFocus";
import { selectJobItemPreviousJobItem } from "../../selectors/jobItem/ui/previousJobItem";
import { selectJobItemById } from "../../selectors/jobItemSelectors";

function* setCheckFocus(action) {
  try {
    if (action.payload.reason === "revert") {
      yield revertJobItem();
      return;
    }

    const currentFocusJobItemId = yield select(
      selectJobDetailsUiCurrentFocusJobItemId
    );

    const previousFocusKey = yield select(selectJobDetailsUiPreviousFocusKey);
    const previousFocusJobItemId = yield select(
      selectJobDetailsUiPreviousFocusJobItemId
    );

    if (currentFocusJobItemId !== null) {
      const previousJobItem = yield select(selectJobItemPreviousJobItem, {
        jobItemId: currentFocusJobItemId,
      });

      if (previousJobItem === null) {
        const jobItem = yield select(selectJobItemById, {
          id: currentFocusJobItemId,
        });

        yield put(
          createAction(JOB_ITEM_SET_PREVIOUS_JOB_ITEM, {
            jobItemId: currentFocusJobItemId,
            jobItem,
          })
        );
      }
    }

    // debounce for focus states
    yield delay(200);
    yield setCheckFocusAfterDelay(previousFocusKey, previousFocusJobItemId);
  } catch (error) {
    sagaError(error);
  }
}

function* setCheckFocusAfterDelay(previousFocusKey, previousFocusJobItemId) {
  try {
    const currentFocusJobItemId = yield select(
      selectJobDetailsUiCurrentFocusJobItemId
    );

    if (
      previousFocusJobItemId !== null &&
      (currentFocusJobItemId === null ||
        previousFocusJobItemId !== currentFocusJobItemId)
    ) {
      const previousJobItem = yield select(selectJobItemPreviousJobItem, {
        jobItemId: previousFocusJobItemId,
      });

      if (previousJobItem !== null) {
        const jobItem = yield select(selectJobItemById, {
          id: previousFocusJobItemId,
        });

        if (jobItem) {
          yield put(
            createAction(JOB_ITEM_SAVE_ITEM_CARD, {
              jobItemId: previousFocusJobItemId,
              jobItem,
              prevJobItem: previousJobItem,
            })
          );
        }

        yield put(
          createAction(JOB_ITEM_SET_PREVIOUS_JOB_ITEM, {
            jobItemId: previousFocusJobItemId,
            jobItem: null,
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* revertJobItem() {
  const previousFocusJobItemId = yield select(
    selectJobDetailsUiPreviousFocusJobItemId
  );

  if (previousFocusJobItemId !== null) {
    const previousJobItem = yield select(selectJobItemPreviousJobItem, {
      jobItemId: previousFocusJobItemId,
    });

    if (previousJobItem !== null) {
      yield put(createAction(JOB_ITEM_REVERT, { jobItem: previousJobItem }));
      yield put(
        createAction(JOB_ITEM_SET_PREVIOUS_JOB_ITEM, {
          jobItemId: previousFocusJobItemId,
          jobItem: null,
        })
      );
    }
  }
}

export default function* watchJobItemCheckFocus() {
  yield takeEvery(JOB_DETAILS_SET_CURRENT_FOCUS, setCheckFocus);
}
