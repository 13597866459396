import MuiCheckbox from "@material-ui/core/Checkbox";
import styled from "styled-components";

export default styled(MuiCheckbox)`
  width: 30px;
  height: 30px;
  color: var(--color-gray-dark);
  &:hover {
    background-color: transparent;
  }
  &.Mui-checked {
    color: black;
    &.primary {
      color: var(--color-blue-active);
    }
    &:hover {
      background-color: transparent;
    }
  }
  &.Mui-disabled {
    color: var(--color-gray-dark);
  }
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`;
