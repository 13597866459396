import { forwardRef } from "react";

import { BaseInput, BaseInputProps } from "./BaseInput";
import { NumericInput, NumericInputProps } from "./NumericInput";
import { TimeInput, TimeInputProps } from "./TimeInput";

export type InputProps =
  | ({ as?: "base" | undefined } & BaseInputProps)
  | ({ as: "numeric" } & NumericInputProps)
  | ({ as: "time" } & TimeInputProps);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ as = "base", ...props }, ref) => {
    let Component = BaseInput;
    switch (as) {
      case "numeric":
        Component = NumericInput;
        break;
      case "time":
        Component = TimeInput;
        break;
    }
    return <Component {...props} ref={ref} />;
  }
);

Input.displayName = "Input";
