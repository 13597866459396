import isError from "lodash-es/isError";
import { put, select, takeLatest, call } from "redux-saga/effects";
import { openScheduleLoggedTimeModal } from "../../../lib/WebAppAPI/schedule";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_OPEN_EDIT,
  SCHEDULE_BLOCK_PAINT_CANCEL,
  SCHEDULE_BLOCK_PAINT_END,
  SCHEDULE_BLOCK_SAVE_ERROR,
  SCHEDULE_BLOCK_SAVED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleBlockDays
} from "../../selectors/scheduleBlockSelectors";

function* openBlock(action) {
  try {
    const { blockKey } = action.payload;

    const baseScheduleLoggedTime = yield select(
      selectBaseScheduleLoggedTimeByBlockKey,
      { blockKey }
    );

    if (!baseScheduleLoggedTime) return;

    const days = yield select(selectScheduleBlockDays, { blockKey });

    const scheduleLoggedTime = {
      ...baseScheduleLoggedTime,
      ...(blockKey < 0 && { id: null })
    };

    const { scheduleLoggedTimes, ...data } = yield call(
      openScheduleLoggedTimeModal,
      scheduleLoggedTime,
      days
    );

    yield put(
      createAction(SCHEDULE_BLOCK_SAVED, {
        ...action.payload,
        blockKey,
        scheduleLoggedTimes
      })
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    if (action.type === SCHEDULE_BLOCK_PAINT_END)
      yield put(
        createAction(SCHEDULE_BLOCK_PAINT_CANCEL, {
          ...action.payload,
          error
        })
      );
    else if (isError(error)) {
      yield put(
        createAction(SCHEDULE_BLOCK_SAVE_ERROR, {
          ...action.payload,
          sagaType: action.type,
          error
        })
      );
    }
    sagaError(error);
  }
}

export default function* watchOpenBlock() {
  yield takeLatest(
    [SCHEDULE_BLOCK_OPEN_EDIT, SCHEDULE_BLOCK_PAINT_END],
    openBlock
  );
}
