import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_ARRAY, ENTITY_NAME_CONTACTS } from "../../lib/constants";
import { CONTACT_GROUPED_OPTION_TYPE } from "../../lib/entities/contactEntity";
import { selectPropsCompanyId, selectPropsId } from "./index";

export const selectContacts = state => state.entities[ENTITY_NAME_CONTACTS];

export const selectContactById = createSelector(
  selectContacts,
  selectPropsId,
  (contacts, id) => get(contacts, `byId.${id}`)
);

export const selectContactIdsByCompanyId = createSelector(
  selectContacts,
  selectPropsCompanyId,
  (contacts, companyId) =>
    get(contacts, `idsByCompanyId.${companyId}`, EMPTY_ARRAY)
);

export const selectGroupedContactOptionsByCompanyId = createSelector(
  selectContacts,
  selectPropsCompanyId,
  (contacts, companyId) => {
    const contactOptions = get(
      contacts,
      `groupedOptionsByCompanyId.${companyId}`,
      EMPTY_ARRAY
    );

    const activeOptions = contactOptions.filter(
      ({ optionType }) => optionType === CONTACT_GROUPED_OPTION_TYPE.ACTIVE
    );

    const archivedOptions = contactOptions.filter(
      ({ optionType }) => optionType === CONTACT_GROUPED_OPTION_TYPE.ARCHIVED
    );

    const options = [];

    if (activeOptions.length) {
      const searchStrings = activeOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: "ACTIVE",
        value: "Active",
        optionType: CONTACT_GROUPED_OPTION_TYPE.OPTIONS_HEADING,
        searchString: "Active"
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          activeOptions,
          headingOption.searchString
        )
      );
    }

    if (archivedOptions.length) {
      const searchStrings = archivedOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: "ARCHIVED",
        value: "Archived",
        optionType: CONTACT_GROUPED_OPTION_TYPE.OPTIONS_HEADING,
        searchString: "Archived"
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          archivedOptions,
          headingOption.searchString
        )
      );
    }

    return options;
  }
);

export const appendSearchStringToOption = (option, searchString) => ({
  ...option,
  searchString: [option.searchString, searchString].join(" ")
});

export const appendSearchStringToOptions = (options, searchString) =>
  options.map(option => appendSearchStringToOption(option, searchString));
