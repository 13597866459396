import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "../../../AppState";
import { selectPropsIds } from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectJobItemRolesByIds = createSelector(
  [selectStateJobItemRoles, selectPropsIds],
  (jobItemRoles, ids) => ids.map((id) => jobItemRoles.byId[id])
);

export function useJobItemRolesByIds(ids: number[]) {
  return useSelector((state: AppState) =>
    selectJobItemRolesByIds(state, { ids })
  );
}
