// deprecated

import type { TCompany } from "@streamtimefe/entities";

import { FrameworkException } from "../lib/exception";
import type { ActivityEntry } from "./ActivityEntry";
import type { Contact } from "./Contact";
import type { Entity } from "./Entity";
import * as JobStatus from "./JobStatus";

export type Type = Job;
export interface Job extends Entity {
  company: TCompany;
  contact: Contact;
  jobLeadUserId: number | null;
  rateCardId: number;
  exchangeRate: string | number;
  isBillable: boolean;
  branchId: number;
  jobStatus: JobStatus.Type;
  number: string | null;
  name: string;
  purchaseOrderNumber: string | null;
  notes: string | null;
  createdUserId: number;
  createdDatetime: string;
  completedDatetime: string | null;
  updatedDatetime: string;
  estimatedStartDate: string | null;
  estimatedEndDate: string | null;
  earliestStartDate: string | null;
  latestEndDate: string | null;
  totalIncompleteMinutes: number;
  totalLoggedMinutes: number;
  totalPlannedMinutes: number;
  jobCurrencyBudget: string | number | null;
  jobCurrencyTotalIncompleteTimeExTax: string | number | null;
  jobCurrencyTotalLoggedTimeExTax: string | number | null;
  jobCurrencyTotalPlannedTimeExTax: string | number | null;
  jobCurrencyTotalDraftInvoicesExTax: string | number | null;
  jobCurrencyTotalAwaitingPaymentInvoicesExTax: string | number | null;
  jobCurrencyTotalPaidInvoicesExTax: string | number | null;
  jobCurrencyTotalLoggedTimeCostExTax: string | number | null;
  jobCurrencyTotalLoggedExpensesExTax: string | number | null;
  jobCurrencyTotalLoggedExpensesCostExTax: string | number | null;
  jobCurrencyTotalPlannedExpensesExTax: string | number | null;
  jobCurrencyTotalPlannedExpensesCostExTax: string | number | null;
  jobCurrencyTotalPlannedExTax: string | number | null;
  jobCurrencyTotalLoggedExTax: string | number | null;
  jobCurrencyTotalLoggedCostExTax: string | number | null;
  jobCurrencyTotalInvoicedExTax: string | number | null;
  jobCurrencyTotalApprovedQuoteExTax: string | number | null;
  totalNonBillablePlannedMinutes: number | null;
  jobCurrencyTotalNonBillablePlannedTimeExTax: number | null;
  totalNonBillableLoggedMinutes: number | null;
  jobCurrencyTotalNonBillableLoggedTimeExTax: number | null;
  latestCommentActivityEntry: ActivityEntry | null;
}

export function isDeleted(entity: Job) {
  return JobStatus.isDeleted(entity.jobStatus);
}

export function getFullName(entity: Job) {
  return `${entity.number ? `[${entity.number}] ` : ""}${entity.name}`;
}

export function getStatusName(jobStatusId: JobStatus.JobStatusesType) {
  switch (jobStatusId) {
    case JobStatus.JobStatuses.Scheduled:
      return "In Play";
    case JobStatus.JobStatuses.Complete:
      return "Complete";
    case JobStatus.JobStatuses.Deleted:
      return "Deleted";
    case JobStatus.JobStatuses.Archived:
      return "Archived";
    case JobStatus.JobStatuses.Planning:
      return "Paused";
    default:
      throw new FrameworkException(`Unknown job status id '${jobStatusId}'`);
  }
}
