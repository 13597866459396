import * as PropTypes from "prop-types";
import React from "react";
import SearchMenuCreateFooter from "../SearchMenu/SearchMenuCreateFooter";

const CreateNewTeamMember = ({ onClick, hasResults }) => {
  if (hasResults) return null;
  return (
    <SearchMenuCreateFooter text="Create a team member" onClick={onClick} />
  );
};

export default CreateNewTeamMember;

CreateNewTeamMember.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasResults: PropTypes.bool.isRequired
};
