import * as PropTypes from "prop-types";
import React from "react";
import { getDaysInYear, getStartOfYearDatesInRange } from "../../../lib/dates";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const YearsConsumer = ({ children }) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportStartDate, viewportEndDate, getOffsetXAtDate, dayWidth }) =>
      getStartOfYearDatesInRange(viewportStartDate, viewportEndDate).map(date =>
        children({
          date,
          width: dayWidth * getDaysInYear(date),
          left: getOffsetXAtDate(date)
        })
      )
    }
  </JobTimelineScrollContext.Consumer>
);

YearsConsumer.propTypes = {
  children: PropTypes.func.isRequired
};

export default YearsConsumer;
