import { get } from "lodash-es";
import {
  JOB_ITEM_SUB_ITEM_DELETING,
  JOB_ITEM_SUB_ITEM_DELETED,
  JOB_ITEM_SUB_ITEM_DELETE_ERROR,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_SUB_ITEM_DELETE
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onEdit = (state, { payload: { jobItemId, jobItemSubItems } }) => {
  const editedJobItemSubItems = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemSubItems`, [])
  );
  const editedJobItemSubItemIds = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemSubItemIds`, [])
  );

  jobItemSubItems.forEach(jobItemSubItem => {
    const subItem = jobItemSubItem.prev
      ? jobItemSubItem.prev
      : jobItemSubItem.new;
    if (!editedJobItemSubItemIds.includes(subItem.id)) {
      editedJobItemSubItems.push(subItem);
      editedJobItemSubItemIds.push(subItem.id);
    }
  });

  return {
    ...state,
    [jobItemId]: {
      ...state[jobItemId],
      editedJobItemSubItems,
      editedJobItemSubItemIds
    }
  };
};

const onDelete = (state, { payload: { jobItemSubItem } }) => {
  const jobItemSubItemId = jobItemSubItem.id;
  const jobItemId = jobItemSubItem.jobItemId;

  const editedJobItemSubItems = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemSubItems`, [])
  );
  const editedJobItemSubItemIds = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemSubItemIds`, [])
  );
  const index = editedJobItemSubItemIds.indexOf(jobItemSubItemId);
  if (index !== -1) {
    editedJobItemSubItems.splice(index, 1);
    editedJobItemSubItemIds.splice(index, 1);
  }

  return {
    ...state,
    [jobItemId]: {
      ...state[jobItemId],
      editedJobItemSubItems,
      editedJobItemSubItemIds
    }
  };
};

const onSavedOrError = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: {
    ...state[jobItemId],
    editedJobItemSubItems: [],
    editedJobItemSubItemIds: []
  }
});

const onDeleteRequest = (state, { payload: { jobItemSubItemId } }) => ({
  ...state,
  [jobItemSubItemId]: {
    ...state[jobItemSubItemId],
    isDeleting: true,
    error: null
  }
});

const onDeleteSuccess = (state, { payload: { jobItemSubItemId } }) => ({
  ...state,
  [jobItemSubItemId]: { ...state[jobItemSubItemId], isDeleting: false }
});

const onDeleteError = (state, { payload: { jobItemSubItemId, error } }) => ({
  ...state,
  [jobItemSubItemId]: { ...state[jobItemSubItemId], isDeleting: false, error }
});

export default createReducer(
  {},
  {
    [JOB_ITEM_SUB_ITEM_EDIT]: onEdit,
    [JOB_ITEM_SAVED]: onSavedOrError,
    [JOB_ITEM_SAVE_ERROR]: onSavedOrError,
    [JOB_ITEM_SUB_ITEM_DELETE]: onDelete,
    [JOB_ITEM_SUB_ITEM_DELETING]: onDeleteRequest,
    [JOB_ITEM_SUB_ITEM_DELETED]: onDeleteSuccess,
    [JOB_ITEM_SUB_ITEM_DELETE_ERROR]: onDeleteError
  }
);
