import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_ALL_EXPANDED,
  JOB_ITEM_SET_EXPANDED,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsCurrentFocusJobItemId } from "../../../redux/selectors/jobDetails/ui/currentFocus";
import { useJobDetailsIsCurrentFocus } from "../../../redux/selectors/jobDetails/ui/IsCurrentFocus";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useIsExpandedJobItem } from "../../../redux/selectors/jobItem/ui/isExpanded";
import { useJobItemUserIdsByJobItemId } from "../../../redux/selectors/jobItemUser/selectJobItemUserIdsByJobItemId";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useExtraComponentsIsHidden } from "../hooks/useExtraComponentsIsHidden";
import { BaseItem } from "../styles";

const ItemExpander = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const isExpanded = useIsExpandedJobItem(jobItemId);
  const extraComponentsIsHidden = useExtraComponentsIsHidden();
  const jobItemUserIds = useJobItemUserIdsByJobItemId(jobItemId);
  const currentFocusJobItemId = useJobDetailsCurrentFocusJobItemId();

  const dispatch = useDispatch();

  const onClick = (e) => {
    const expanded = !isExpanded;

    if (e.altKey) {
      dispatch(
        createAction(JOB_ITEM_SET_ALL_EXPANDED, {
          jobId,
          isExpanded: expanded,
        })
      );

      if (isJobEditable) {
        dispatch(
          createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
            currentFocus: null,
          })
        );
      }
    } else {
      dispatch(
        createAction(JOB_ITEM_SET_EXPANDED, {
          jobItemId,
          isExpanded: expanded,
        })
      );
      if (isJobEditable && currentFocusJobItemId !== jobItemId) {
        dispatch(
          createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
            currentFocus: null,
          })
        );
      }
    }
  };

  const hideExpander =
    !isJobEditable && extraComponentsIsHidden && jobItemUserIds.length === 0;

  const ref = useRef();
  const newUserFocused = useJobDetailsIsCurrentFocus({
    jobItemId,
    key: FOCUS_KEYS.ITEM_NEW_USER,
  });

  useEffect(() => {
    if (newUserFocused)
      requestAnimationFrame(() => {
        if (ref.current)
          scrollIntoView(ref.current, {
            scrollMode: "if-needed",
            behavior: "smooth",
            block: "nearest",
          });
      });
  }, [newUserFocused]);

  return (
    <BaseItem ref={ref}>
      {!hideExpander && (
        <Expander
          start={360}
          end={180}
          size={ICON_SIZE.XXX_LARGE}
          onToggle={onClick}
          style={{ width: 40, height: 40 }}
          isExpanded={isExpanded}
          tabIndex={-1}
        />
      )}
      {hideExpander && <Spacer w={40} h={40} />}
    </BaseItem>
  );
};

export default ItemExpander;
