import { Entity } from "@streamtimefe/entities";

import { addDays, simpleCompareAsc } from "../../../../../lib/dates";
import reduceChangedEntities from "../reduceChangedEntities";

const resizeBlockReducer = (state, action) => {
  const { blockKey, hours, days } = action.payload;
  const minutes = hours * 60;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];
  const prevDays = state.daysByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  if (prevBaseScheduleLoggedTime.minutes === minutes && prevDays === days)
    return state;

  const scheduleBlockBaseLoggedTimeId = days > 1 ? blockKey : null;
  const maxDays = Math.max(prevDays, days);
  const prevEntities = prevScheduleLoggedTimeIds
    .map((id) => state.byId[id])
    .slice()
    .sort(({ startDateA }, { startDateB }) =>
      simpleCompareAsc(startDateA, startDateB)
    );

  const changedEntities = [];
  for (let i = 0; i < maxDays; i += 1) {
    const prevEntity = prevEntities[i];
    if (prevEntity) {
      const newEntity = {
        ...prevEntity,
        minutes,
        scheduleBlockBaseLoggedTimeId,
      };

      changedEntities.push({
        prevEntity,
        ...(i < days && { newEntity }),
      });
    } else {
      changedEntities.push({
        newEntity: {
          ...prevBaseScheduleLoggedTime,
          id: Entity.temporaryId(),
          minutes,
          date: addDays(prevBaseScheduleLoggedTime.date, i),
          scheduleBlockBaseLoggedTimeId,
        },
      });
    }
  }

  return reduceChangedEntities(state, changedEntities);
};

export default resizeBlockReducer;
