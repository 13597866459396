import { produce } from "immer";
import moment from "moment";
import { put, select } from "redux-saga/effects";
import { authenticationStore } from "st-shared/stores";

import {
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_SUB_ITEM_SET_CHECKED,
  JOB_ITEM_SUB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemSubItem } from "../../selectors/jobItemSubItem";

function* setChecked(action) {
  try {
    const { jobItemId, jobItemSubItemId, checked } = action.payload;

    const jobItemSubItem = yield select(selectJobItemSubItem, {
      jobItemSubItemId,
    });

    const loggedInUserId = authenticationStore().loggedInUserId;

    const newJobItemSubItem = produce(jobItemSubItem, (draft) => {
      if (checked) {
        draft.jobItemSubItemStatus.id = JOB_ITEM_SUB_ITEM_STATUS_ID_COMPLETE;
        draft.completedByUserId = loggedInUserId;
        draft.completedDatetime = moment.utc().format();
      } else {
        draft.jobItemSubItemStatus.id = JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE;
        draft.completedByUserId = null;
        draft.completedDatetime = null;
      }
    });

    yield put(
      createAction(JOB_ITEM_SUB_ITEM_EDIT, {
        jobItemId,
        jobItemSubItems: [{ new: newJobItemSubItem, prev: jobItemSubItem }],
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSubItemSetChecked() {
  yield takeLatestBy(
    [JOB_ITEM_SUB_ITEM_SET_CHECKED],
    setChecked,
    (action) => action.payload.jobItemSubItemId
  );
}
