import { images } from "@streamtimefe/assets";
import { Icon, MdLock } from "st-shared/components/index.ts";
import { useOrganisationSubscriberUser } from "st-shared/stores/index.ts";
import { theme } from "st-shared/theme/index.css.ts";

import {
  emptyChartCss,
  emptyChartNoPermissionCss,
  emptyChartNoPermissionTitleCss,
  emptyColumnChartCss,
  emptyColumnChartImgCss,
} from "./Chart.css.ts";

export function EmptyColumnChart() {
  return (
    <div className={emptyColumnChartCss}>
      <div>
        <img
          className={emptyColumnChartImgCss}
          alt=""
          src={images.emptyStateReportingBW}
        />
      </div>
      Ooh, there’s no data available for your graph.
    </div>
  );
}

export function EmptyColumnChartNoPermissions() {
  const subscriber = useOrganisationSubscriberUser();

  return (
    <div className={emptyChartCss}>
      <div className={emptyChartNoPermissionCss}>
        <Icon icon={MdLock} size={30} color={theme.color.black} />
        <span className={emptyChartNoPermissionTitleCss}>
          Sorry, you don’t have permission to see this report.
        </span>
        <span>
          If you believe there’s a problem, have a chat to{" "}
          <span style={{ fontWeight: theme.font.weight.bold }}>
            {subscriber.displayName}.
          </span>
        </span>
      </div>
    </div>
  );
}
