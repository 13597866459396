import {
  EMPTY_ARRAY,
  JOB_TIMELINE_FETCH_FAILED,
  JOB_TIMELINE_FETCH_REQUEST,
  JOB_TIMELINE_FETCH_SUCCEEDED,
  JOB_TIMELINE_ZOOM_IN,
  JOB_TIMELINE_ZOOM_OUT,
  JOB_TIMELINE_ZOOM_LEVEL_DEFAULT,
  JOB_TIMELINE_ZOOM_LEVELS
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const initialState = {
  jobId: null,
  pending: true,
  fetchedIds: EMPTY_ARRAY,
  error: "",
  zoomLevelIndex: JOB_TIMELINE_ZOOM_LEVEL_DEFAULT
};

const onRequest = (state, { payload: { jobId } }) => ({
  ...state,
  jobId,
  pending: true,
  error: initialState.error
});

const onSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  pending: false,
  fetchedIds: state.fetchedIds.concat([jobId]),
  error: initialState.error
});

const onError = (state, { payload: { error } }) => ({
  ...state,
  pending: true,
  error
});

const onZoomIn = state => ({
  ...state,
  ...(state.zoomLevelIndex < JOB_TIMELINE_ZOOM_LEVELS.length - 1 && {
    zoomLevelIndex: state.zoomLevelIndex + 1
  })
});

const onZoomOut = state => ({
  ...state,
  ...(state.zoomLevelIndex > 0 && {
    zoomLevelIndex: state.zoomLevelIndex - 1
  })
});

export default createReducer(initialState, {
  [JOB_TIMELINE_FETCH_REQUEST]: onRequest,
  [JOB_TIMELINE_FETCH_SUCCEEDED]: onSuccess,
  [JOB_TIMELINE_FETCH_FAILED]: onError,
  [JOB_TIMELINE_ZOOM_IN]: onZoomIn,
  [JOB_TIMELINE_ZOOM_OUT]: onZoomOut
});
