import reduceChangedEntities from "../reduceChangedEntities";

const undoPaintBlockReducer = (state, action) => {
  const { blockKey } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  const changedEntities = prevScheduleLoggedTimeIds.map(id => ({
    prevEntity: state.byId[id]
  }));

  return reduceChangedEntities(state, changedEntities);
};

export default undoPaintBlockReducer;
