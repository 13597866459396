import { ENTITY_CLASS_JOB_ITEM_USER } from "../constants";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { getJobItemUserAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";

export const createJobItemUserAPI = (jobItemUser) =>
  API.post(`/job_items/${jobItemUser.jobItemId}/job_item_users`, {
    ...getJobItemUserAsMessage(jobItemUser),
    id: null,
  })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM_USER, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_USER));

export const updateJobItemUserStatusAPI = (
  jobItemUser,
  removeOverdueIncompleteTodos = false
) =>
  API.put(
    `/job_item_users/${jobItemUser.id}`,
    getJobItemUserAsMessage(jobItemUser),
    removeOverdueIncompleteTodos
      ? { remove_overdue_incomplete_todos: true }
      : null
  )
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM_USER, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_USER));

export const deleteJobItemUserAPI = (id) =>
  API.delete(`/job_item_users/${id}`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM_USER, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_USER));

export function reassignJobItemUserAPI(jobItemUserId, userId) {
  return API.post(
    `/job_item_users/${jobItemUserId}/reassign?user_id=${
      userId !== null ? userId : ""
    }`
  )
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM_USER, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_USER));
}
