import { produce } from "immer";
import { difference, union } from "lodash-es";

import {
  SCHEDULE_BLOCK_DRAG_CANCEL,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DRAG_START,
  SCHEDULE_BLOCK_DRAG_START_MULTIPLE,
  SCHEDULE_BLOCK_RESIZE_END,
  SCHEDULE_BLOCK_RESIZE_START,
  SCHEDULE_BLOCK_SET_ACTIVE,
  SCHEDULE_BLOCK_SET_SELECTED,
  SCHEDULE_BLOCK_SPLIT_CANCEL,
  SCHEDULE_BLOCK_SPLIT_DAYS,
  SCHEDULE_BLOCK_SPLIT_SAVE_ERROR,
  SCHEDULE_BLOCK_SPLIT_SAVED,
  SCHEDULE_BLOCK_SPLIT_START,
} from "../../../lib/constants";
import { getEachDay } from "../../../lib/dates";
import { JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED } from "../../entities/jobListModel/actions";
import {
  SCHEDULE_FILTER_COUNT,
  SCHEDULE_FILTER_IS_SEARCHING,
  SCHEDULE_FILTER_TEAM_MEMBERS_RECEIVED,
  SCHEDULE_HAS_NO_ACTIVE_JOBS,
  SCHEDULE_LOGGED_TIMES_FETCH_ERROR,
  SCHEDULE_LOGGED_TIMES_FETCH_REQUEST,
  SCHEDULE_LOGGED_TIMES_FETCH_SUCCESS,
  SCHEDULE_LOGGED_TIMES_PURGE,
  SCHEDULE_SET_AVAILABILITY_VIEW,
  SCHEDULE_SET_SCHEDULE_TOTALS_MODE,
  SCHEDULE_SET_SEARCH_QUERY,
  SCHEDULE_SET_TODO_VISIBILITY_OPTIONS,
  SCHEDULE_SET_VIEW_MODE,
  SCHEDULE_SET_VIEW_PAUSED_LOGGED_TIMES,
  SCHEDULE_SIDEBAR_HOVER,
  SCHEDULE_ZOOM_IN,
  SCHEDULE_ZOOM_OUT,
  ScheduleLoggedTimeFetchErrorAction,
  ScheduleLoggedTimeFetchRequestAction,
  ScheduleLoggedTimeFetchSuccessAction,
  ScheduleUiAction,
} from "./actions";
import {
  ScheduleUi,
  ScheduleUiAvailabilityViews,
  ScheduleUiState,
  ScheduleUiTodoVisibilityOptions,
  ScheduleUiTotalsModes,
  ScheduleUiViewModes,
} from "./types";

const initialState: ScheduleUiState = {
  pending: true,
  error: null,
  isSearching: true,
  isFetching: false,
  fetchedLoggedTimes: {
    datesByUser: {},
    usersByDate: {},
  },
  fetchingLoggedTimes: {
    datesByUser: {},
    usersByDate: {},
  },
  requestedLoggedTimes: {
    datesByUser: {},
    usersByDate: {},
  },
  activeBlockKey: null,
  splittingBlockKey: null,
  splitBlockDays: null,
  viewMode: ScheduleUiViewModes.ItemsByJobs,
  availabilityView: ScheduleUiAvailabilityViews.Percentage,
  viewPausedLoggedTimes: false,
  filteredTeamMembers: [],
  filterCount: 0,
  hasNoActiveJobs: false,
  todoVisibilityOptions: [
    ScheduleUiTodoVisibilityOptions.Notes,
    ScheduleUiTodoVisibilityOptions.JobName,
    ScheduleUiTodoVisibilityOptions.JobPhaseName,
    ScheduleUiTodoVisibilityOptions.JobItemName,
  ],
  scheduleTotalsMode: ScheduleUiTotalsModes.UsedScheduledPlannedHours,
  zoomLevel: ScheduleUi.Zoom.Default,
  searchQuery: "",
  sidebarHover: false,
};

function onInitJobListModelLoad(state: ScheduleUiState) {
  if (state.pending) {
    return produce(state, (draft) => {
      draft.pending = false;
    });
  }
  return state;
}

function onRequest(
  state: ScheduleUiState,
  action: ScheduleLoggedTimeFetchRequestAction
) {
  const fetchingDates: string[] = getEachDay(action.startDate, action.endDate);
  return produce(state, (draft) => {
    draft.isFetching = true;
    draft.error = null;

    action.userIds.forEach((userId) => {
      draft.requestedLoggedTimes.datesByUser[userId] = union(
        state.requestedLoggedTimes.datesByUser[userId],
        fetchingDates
      );
      draft.fetchingLoggedTimes.datesByUser[userId] = union(
        state.fetchingLoggedTimes.datesByUser[userId],
        fetchingDates
      );
    });
    fetchingDates.forEach((date) => {
      draft.requestedLoggedTimes.usersByDate[date] = union(
        state.requestedLoggedTimes.usersByDate[date],
        action.userIds
      );
      draft.fetchingLoggedTimes.usersByDate[date] = union(
        state.fetchingLoggedTimes.usersByDate[date],
        action.userIds
      );
    });
  });
}

function onSuccess(
  state: ScheduleUiState,
  action: ScheduleLoggedTimeFetchSuccessAction
) {
  const fetchingDates: string[] = getEachDay(action.startDate, action.endDate);
  return produce(state, (draft) => {
    draft.isFetching = false;
    draft.error = null;

    action.userIds.forEach((userId) => {
      draft.fetchedLoggedTimes.datesByUser[userId] = union(
        state.fetchedLoggedTimes.datesByUser[userId],
        fetchingDates
      );
      draft.fetchingLoggedTimes.datesByUser[userId] = difference(
        state.fetchingLoggedTimes.datesByUser[userId],
        fetchingDates
      );
    });
    fetchingDates.forEach((date) => {
      draft.fetchedLoggedTimes.usersByDate[date] = union(
        state.fetchedLoggedTimes.usersByDate[date],
        action.userIds
      );
      draft.fetchingLoggedTimes.usersByDate[date] = difference(
        state.fetchingLoggedTimes.usersByDate[date],
        action.userIds
      );
    });
  });
}

function onError(
  state: ScheduleUiState,
  action: ScheduleLoggedTimeFetchErrorAction
) {
  const fetchingDates: string[] = getEachDay(action.startDate, action.endDate);
  return produce(state, (draft) => {
    draft.isFetching = false;
    draft.error = action.error;

    action.userIds.forEach((userId) => {
      draft.requestedLoggedTimes.datesByUser[userId] = difference(
        state.requestedLoggedTimes.datesByUser[userId],
        fetchingDates
      );
      draft.fetchingLoggedTimes.datesByUser[userId] = difference(
        state.fetchingLoggedTimes.datesByUser[userId],
        fetchingDates
      );
    });
    fetchingDates.forEach((date) => {
      draft.requestedLoggedTimes.usersByDate[date] = difference(
        state.requestedLoggedTimes.usersByDate[date],
        action.userIds
      );
      draft.fetchingLoggedTimes.usersByDate[date] = difference(
        state.fetchingLoggedTimes.usersByDate[date],
        action.userIds
      );
    });
  });
}

function onPurge(state: ScheduleUiState) {
  return produce(state, (draft) => {
    draft.fetchedLoggedTimes = {
      datesByUser: {},
      usersByDate: {},
    };
    draft.fetchedLoggedTimes = {
      datesByUser: {},
      usersByDate: {},
    };
    draft.fetchedLoggedTimes = {
      datesByUser: {},
      usersByDate: {},
    };
  });
}

function onZoomIn(state: ScheduleUiState) {
  if (state.zoomLevel < ScheduleUi.Zoom.MaxLevel) {
    return produce(state, (draft) => {
      draft.zoomLevel = state.zoomLevel + 1;
    });
  }
  return state;
}

function onZoomOut(state: ScheduleUiState) {
  if (state.zoomLevel > ScheduleUi.Zoom.MinLevel) {
    return produce(state, (draft) => {
      draft.zoomLevel = state.zoomLevel - 1;
    });
  }
  return state;
}

const dragStart = (state, { payload: { blockKey } }) => ({
  ...state,
  activeBlockKey: blockKey,
});

const dragEnd = (state) => ({
  ...state,
  activeBlockKey: null,
});

const resizeStart = (state, { payload: { blockKey } }) => ({
  ...state,
  activeBlockKey: blockKey,
});

const resizeEnd = (state) => ({
  ...state,
  activeBlockKey: null,
});

const setSelected = (state) => ({
  ...state,
  activeBlockKey: null,
});

const setActive = (state, { payload: { blockKey } }) => {
  if (state.activeBlockKey === blockKey) return state;
  return {
    ...state,
    activeBlockKey: blockKey,
  };
};

const setSplittingBlock = (state, { payload: { blockKey } }) => ({
  ...state,
  splittingBlockKey: blockKey,
});

const setSplitBlockDays = (state, { payload }) => ({
  ...state,
  splitBlockDays: payload,
});

const cancelSplitBlock = (state) => ({
  ...state,
  splittingBlockKey: null,
  splitBlockDays: null,
});

function scheduleUiReducer(
  state: ScheduleUiState = initialState,
  action: ScheduleUiAction
) {
  switch (action.type) {
    case JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED:
      return onInitJobListModelLoad(state);
    case SCHEDULE_LOGGED_TIMES_FETCH_REQUEST:
      return onRequest(state, action);
    case SCHEDULE_LOGGED_TIMES_FETCH_SUCCESS:
      return onSuccess(state, action);
    case SCHEDULE_LOGGED_TIMES_FETCH_ERROR:
      return onError(state, action);
    case SCHEDULE_LOGGED_TIMES_PURGE:
      return onPurge(state);
    case SCHEDULE_ZOOM_IN:
      return onZoomIn(state);
    case SCHEDULE_ZOOM_OUT:
      return onZoomOut(state);
    case SCHEDULE_SET_VIEW_MODE:
      return produce(state, (draft) => {
        draft.viewMode = action.viewMode;
      });
    case SCHEDULE_SET_AVAILABILITY_VIEW:
      return produce(state, (draft) => {
        draft.availabilityView = action.availabilityView;
      });
    case SCHEDULE_SET_VIEW_PAUSED_LOGGED_TIMES:
      return produce(state, (draft) => {
        draft.viewPausedLoggedTimes = action.viewPausedLoggedTimes;
      });
    case SCHEDULE_SET_TODO_VISIBILITY_OPTIONS:
      return produce(state, (draft) => {
        draft.todoVisibilityOptions = action.todoVisibilityOptions;
      });
    case SCHEDULE_SET_SCHEDULE_TOTALS_MODE:
      return produce(state, (draft) => {
        draft.scheduleTotalsMode = action.scheduleTotalsMode;
      });
    case SCHEDULE_SET_SEARCH_QUERY:
      return produce(state, (draft) => {
        draft.searchQuery = action.searchQuery;
      });
    case SCHEDULE_FILTER_IS_SEARCHING:
      return produce(state, (draft) => {
        draft.isSearching = action.isSearching;
      });
    case SCHEDULE_FILTER_TEAM_MEMBERS_RECEIVED:
      return produce(state, (draft) => {
        draft.filteredTeamMembers = action.filteredTeamMembers;
      });
    case SCHEDULE_FILTER_COUNT:
      return produce(state, (draft) => {
        draft.filterCount = action.filterCount;
      });
    case SCHEDULE_HAS_NO_ACTIVE_JOBS:
      return produce(state, (draft) => {
        draft.hasNoActiveJobs = action.hasNoActiveJobs;
      });
    case SCHEDULE_SIDEBAR_HOVER:
      return produce(state, (draft) => {
        draft.sidebarHover = action.hover;
      });

    case SCHEDULE_BLOCK_DRAG_START:
      return dragStart(state, action);
    case SCHEDULE_BLOCK_DRAG_START_MULTIPLE:
      return dragStart(state, action);
    case SCHEDULE_BLOCK_DRAG_END:
      return dragEnd(state, action);
    case SCHEDULE_BLOCK_DRAG_CANCEL:
      return dragEnd(state, action);
    case SCHEDULE_BLOCK_RESIZE_START:
      return resizeStart(state, action);
    case SCHEDULE_BLOCK_RESIZE_END:
      return resizeEnd(state, action);
    case SCHEDULE_BLOCK_SET_SELECTED:
      return setSelected(state, action);
    case SCHEDULE_BLOCK_SET_ACTIVE:
      return setActive(state, action);
    case SCHEDULE_BLOCK_SPLIT_START:
      return setSplittingBlock(state, action);
    case SCHEDULE_BLOCK_SPLIT_DAYS:
      return setSplitBlockDays(state, action);
    case SCHEDULE_BLOCK_SPLIT_CANCEL:
      return cancelSplitBlock(state, action);
    case SCHEDULE_BLOCK_SPLIT_SAVED:
      return cancelSplitBlock(state, action);
    case SCHEDULE_BLOCK_SPLIT_SAVE_ERROR:
      return cancelSplitBlock(state, action);
    default:
      return state;
  }
}

// TODO: redo the block actions

export default scheduleUiReducer;
