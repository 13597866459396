import * as PropTypes from "prop-types";
import { SearchMenuHeader } from "st-shared/module";
import styled from "styled-components";

import { IconButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";
import { ICON_SIZE } from "../StyledIcon";
import UserIconName from "../User/UserIconName";

const ClearMenuHeader = ({ pickedUserId, onClear, ...props }) => {
  return (
    <SearchMenuHeader {...props}>
      {pickedUserId && (
        <UserWrapper>
          <UserIconName
            id={pickedUserId}
            size={26}
            showRole
            fontSize={12}
            color="var(--color-charcoal)"
          />
          <StyledIconButton onClick={onClear}>
            <ClearIcon size={ICON_SIZE.LARGE} />
          </StyledIconButton>
        </UserWrapper>
      )}
    </SearchMenuHeader>
  );
};

ClearMenuHeader.propTypes = {
  pickedUserId: PropTypes.number,
  onClear: PropTypes.func,
};

ClearMenuHeader.defaultProps = {
  pickedUserId: null,
  onClear: null,
};

export default ClearMenuHeader;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)``;
