import type {
  TEntityId,
  THappinessResponse,
  TModelSet,
} from "@streamtimefe/entities";
import { Entity, EntityClass } from "@streamtimefe/entities";

import { fetch } from "../fetch";

const root = "/users";

export async function getHappinessResponses({ userId }: { userId: TEntityId }) {
  return fetch()
    .get(`${root}/${userId}/happiness_responses`)
    .then(fetch.transform.models(EntityClass.HappinessResponse));
}

export async function createHappinessResponse({
  userId,
  happinessResponse,
}: {
  userId: TEntityId;
  happinessResponse: THappinessResponse;
}) {
  return fetch({ alert: true })
    .post(
      `${root}/${userId}/happiness_responses`,
      Entity.apiSafeTransform(happinessResponse)
    )
    .then(
      fetch.transform.modelSet(EntityClass.HappinessResponse, {
        primarySyncKeyReplacement: happinessResponse.meta_data_sync_key,
      })
    );
}

export async function updateHappinessResponse({
  userId,
  happinessResponse,
}: {
  userId: TEntityId;
  happinessResponse: THappinessResponse;
}) {
  return fetch({ alert: true })
    .put<
      TModelSet<typeof EntityClass.HappinessResponse>
    >(`${root}/${userId}/happiness_responses/${happinessResponse.id}`, Entity.apiSafeTransform(happinessResponse))
    .then(fetch.transform.modelSet(EntityClass.HappinessResponse));
}

export function getProfileImagesUrl({ userId }: { userId: TEntityId }) {
  const token = (userId + 15) * userId;
  return `${fetch().defaults.baseURL}${root}/${userId}/profile_images?t=${token}&u=${userId}`;
}
