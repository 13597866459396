import { useEffect } from "react";
import { LoadingSpinner } from "st-shared/module";
import { allOptionsStore } from "st-shared/stores";

import { useParamEntityId } from "../../../components/router/useParamEntityId";
import { useReportingFetching } from "../state/stores/reportingSelectors";
import { reportingStore } from "../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentFetching,
} from "../state/stores/savedSegmentSelectors";
import { Header } from "./header/Header";
import { Report } from "./report/Report";
import { ReportEmptyState } from "./report/ReportEmptyState";
import { container, content } from "./ReportingPage.css";
import { ReportingSidebar } from "./sidebar/ReportingSidebar";

reportingStore.init();

export function ReportingPage() {
  const entityId = useParamEntityId();
  const fetching = useReportingFetching();

  useEffect(() => {
    allOptionsStore().loadAll();
    reportingStore().actions.bootstrap();
  }, []);

  useEffect(() => {
    reportingStore().actions.bootstrapSavedSegment(entityId);
  }, [entityId]);

  function renderContent() {
    if (fetching) {
      return <LoadingSpinner />;
    }

    return (
      <div className={content}>
        <Content />
        <ReportingSidebar />
      </div>
    );
  }

  return (
    <div className={container}>
      <Header />
      {renderContent()}
    </div>
  );
}

function Content() {
  const fetching = useReportingSavedSegmentFetching();
  const dataSets = useReportingSavedSegmentDataSets();
  const columns = useReportingSavedSegmentColumns();

  if (fetching) {
    return <LoadingSpinner />;
  }

  if (
    Object.values(dataSets).length === 0 &&
    Object.values(columns).length === 0
  ) {
    return <ReportEmptyState />;
  }

  return <Report />;
}
