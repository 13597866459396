import { EntityAction } from "../actions";

export const JOB_ASSIGN_ROLE_TIME = "job/assignRoleTime";
export const JOB_ADD_USERS_AND_ROLES = "job/addUsersAndRoles";

export type JobsAction =
  | EntityAction
  | JobAssignRoleTimeAction
  | JobAddUsersAndRolesAction;

export type JobAssignRoleTimeAction = {
  type: typeof JOB_ASSIGN_ROLE_TIME;
  jobId: number;
  roleId: number;
  userId: number;
};

export type JobAddUsersAndRolesAction = {
  type: typeof JOB_ADD_USERS_AND_ROLES;
  jobId: number;
  userIds: number[];
  roleIds: number[];
};

export function actionJobAssignRoleTime(
  jobId: number,
  roleId: number,
  userId: number
): JobAssignRoleTimeAction {
  return { type: JOB_ASSIGN_ROLE_TIME, jobId, roleId, userId };
}

export function actionJobAddUsersAndRolesAction(
  jobId: number,
  userIds: number[],
  roleIds: number[]
): JobAddUsersAndRolesAction {
  return { type: JOB_ADD_USERS_AND_ROLES, jobId, userIds, roleIds };
}
