import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, { SEND_OPEN_REPEATING_LOGGED_TIME_MODAL } from "./index";

export const openRepeatingLoggedTimeModal = repeatingLoggedTimeId =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_REPEATING_LOGGED_TIME_MODAL,
      repeatingLoggedTimeId
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_REPEATING_LOGGED_TIME_MODAL}`
        )
      );
  });
