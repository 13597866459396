import { groupBy, orderBy } from "lodash-es";
import { Fragment, useMemo } from "react";
import { Icon, IconSize } from "st-shared/components";
import type { TReportingEntityDescriptor } from "st-shared/entities";
import { EntityDescriptorProperties } from "st-shared/types";

import { useReportingSavedSegmentDataSets } from "../../../state/stores/savedSegmentSelectors";
import { AddColumnFooter } from "../AddColumnFooter";
import { ColumnsEmptyState } from "../ColumnsEmptyState";
import * as sidebarStyles from "../sidebar.css";
import { DataSet } from "./DataSet";
import { DataSetActionMenu } from "./DataSetActionMenu";
import {
  dataSetContainer as dataSetlist,
  dataSetGroupContainer,
  dataSetGroupDividerCss,
  dataSetGroupHeader,
  dataSetGroupHeaderText,
} from "./DataSets.css";
import { GlobalFilters } from "./GlobalFilters";

export function DataSets() {
  const dataSets = useReportingSavedSegmentDataSets();

  const datasSetsMinimal = Object.values(dataSets).map(
    ({ id, orderId, entityDescriptorId }) => ({
      id,
      orderId,
      entityDescriptorId,
    })
  );

  const groupedDataSets = useMemo(() => {
    const ordered = orderBy(datasSetsMinimal, (dataSet) => dataSet.orderId);
    const grouped = groupBy(ordered, (dataSet) => dataSet.entityDescriptorId);
    return orderBy(grouped, (dataSets) => dataSets[0].orderId);
  }, [JSON.stringify(datasSetsMinimal)]);

  return (
    <div className={sidebarStyles.container}>
      <div className={sidebarStyles.header}>
        Filter Sets
        <GlobalFilters />
      </div>
      {Object.keys(dataSets).length === 0 && (
        <ColumnsEmptyState variant="datasets" />
      )}
      <div className={dataSetlist}>
        <div style={{ width: "100%" }} />
        {groupedDataSets.map((dataSets) => {
          const entityDescriptorId = dataSets[0].entityDescriptorId;
          return (
            <DataSetGroup
              key={entityDescriptorId}
              dataSets={dataSets}
              entityDescriptorId={entityDescriptorId}
            />
          );
        })}
      </div>
      <AddColumnFooter />
    </div>
  );
}

type DataSetGroupProps = {
  entityDescriptorId: TReportingEntityDescriptor;
  dataSets: { id: string }[];
};

function DataSetGroup({ entityDescriptorId, dataSets }: DataSetGroupProps) {
  if (dataSets.length <= 0) return null;
  const showIndividualHeader = dataSets.length > 1;

  return (
    <div>
      <div className={dataSetGroupHeader}>
        <Icon
          icon={EntityDescriptorProperties[entityDescriptorId].icon}
          size={IconSize.Medium}
        />
        <span className={dataSetGroupHeaderText}>
          {EntityDescriptorProperties[entityDescriptorId].name}
        </span>
        {!showIndividualHeader && (
          <DataSetActionMenu dataSetId={dataSets[0].id} />
        )}
      </div>
      <div className={dataSetGroupContainer}>
        {dataSets.map((dataSet, index) => (
          <Fragment key={dataSet.id}>
            <DataSet dataSetId={dataSet.id} showHeader={showIndividualHeader} />
            {index !== dataSets.length - 1 && (
              <div className={dataSetGroupDividerCss} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
