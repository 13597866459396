import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_RESIZE_HANDLE,
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import Flex from "../../elements/Flex";

const resizeHandleAttrName = getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE);

class JobItemBarResizeHandle extends React.PureComponent {
  static propTypes = {
    left: PropTypes.bool,
    right: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    left: false,
    right: false,
    className: ""
  };

  get resizeHandleValue() {
    const { left } = this.props;
    if (left) return DATA_VALUE_HANDLE_LEFT;
    return DATA_VALUE_HANDLE_RIGHT;
  }

  get className() {
    const { left, right, className } = this.props;
    const classes = className ? [className] : [];

    if (left) classes.push("left");
    else if (right) classes.push("right");

    return classes.join(" ");
  }

  render() {
    const { className, resizeHandleValue } = this;
    return (
      <ResizeHandleWrapper
        {...{ className, [resizeHandleAttrName]: resizeHandleValue }}
      >
        |||
      </ResizeHandleWrapper>
    );
  }
}

export default JobItemBarResizeHandle;

export const ResizeHandleWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 30px;
  align-items: center;
  z-index: 2;
  opacity: 0;
  cursor: ew-resize;
  color: white;
  letter-spacing: 1.5px;
  font-size: 6px;
  font-weight: bold;
  &.left {
    left: 0;
    border-right: 1px solid var(--color-gray-light);
    padding-right: 5px;
    justify-content: flex-end;
  }
  &.right {
    right: 0;
    padding-left: 5px;
    justify-content: flex-start;
    border-left: 1px solid var(--color-gray-light);
  }
`;
