import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

const Bridge = styled(SegmentPart).attrs(
  mapPropsToClassName(["conflict", "selected", "float"])
)`
  position: absolute;
  top: -1px;
  bottom: -1px;
  width: 100%;
`;

export const BridgeLeft = styled(Bridge)`
  border-left-width: 2px;
`;

export const BridgeRight = styled(Bridge)`
  border-right-width: 2px;
`;
