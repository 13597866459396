import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const LabelIcon = props => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path d="M15.1.8c-.3-.5-.9-.8-1.6-.8h-11C1.4 0 .5.9.5 2v10c0 1.1.9 2 2 2h11c.7 0 1.3-.3 1.6-.8L19.5 7 15.1.8zM13.5 12h-11V2h11L17 7l-3.5 5z" />
  </SvgIcon>
);

export default StyledIcon(LabelIcon);
