import styled from "styled-components";

export default styled.div<{ cellWidth?: number }>`
  position: absolute;
  height: 100%;
  background: repeating-linear-gradient(
    90deg,
    var(--color-gray-bright) 0,
    var(--color-gray-bright) 1px,
    var(--color-ygrittesnow) 1px,
    var(--color-ygrittesnow) ${({ cellWidth = 100 }) => cellWidth}px
  );
`;
