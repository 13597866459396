import Anchor from "../ScheduleScrollProvider/Anchor";
import DatesConsumer from "../ScheduleScrollProvider/DatesConsumer";
import * as styles from "./ScheduleRoleBlankCell.css";

export function ScheduleRoleBlankCell() {
  return (
    <div className={styles.container}>
      <Anchor>
        <DatesConsumer>
          {({ dayWidth, date, offsetX }) => (
            <div
              className={styles.cell}
              key={date}
              style={{ left: offsetX, width: dayWidth }}
            />
          )}
        </DatesConsumer>
      </Anchor>
    </div>
  );
}
