import Bugsnag from "@bugsnag/js";
import type { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import type { PropsWithChildren } from "react";
import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "./ErrorFallback";

let BugsnagErrorBoundaryComponent: BugsnagErrorBoundary | undefined = undefined;

export function bugsnagStartErrorBoundary() {
  BugsnagErrorBoundaryComponent =
    Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

export function ErrorBoundary({ children }: PropsWithChildren) {
  const ErrorBoundaryComponent =
    BugsnagErrorBoundaryComponent ?? ReactErrorBoundary;

  return (
    <ErrorBoundaryComponent FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundaryComponent>
  );
}
