import { useIsMounted } from "st-shared/external/usehooks";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { isArchived } from "../../../../lib/entities/jobEntity";
import { getTotalIncompleteMinutes } from "../../../../lib/entities/jobItemUserEntity";
import { useHasInvoices } from "../../../../redux/selectors/invoices/hasInvoices";
import { useJob } from "../../../../redux/selectors/job";
import { useJumbotronUsedPlannedTimeHoursDisplay } from "../../../../redux/selectors/jobDetails/jumbotronUsedPlannedTimeHoursDisplay";
import { useHasLoggedExpenses } from "../../../../redux/selectors/loggedExpense/hasLoggedExpenses";
import { useHasQuotes } from "../../../../redux/selectors/quote/hasQuotes";
import Spacer from "../../../elements/Spacer";
import AnimateWrapper from "../../components/AnimateWrapper";
import { useJobId } from "../../context/JobIdContext";
import EmptyJobJumbotronMessage from "./EmptyJobJumbotronMessage";
import JumbotronBudget from "./JumbotronBudget";
import JumbotronExpenses from "./JumbotronExpenses";
import JumbotronInvoices from "./JumbotronInvoices";
import JumbotronMargin from "./JumbotronMargin";
import JumbotronQuotes from "./JumbotronQuotes";
import JumbotronScheduled from "./JumbotronScheduled";
import JumbotronStatus from "./JumbotronStatus";
import JumbotronTeamMembers from "./JumbotronTeamMembers";
import JumbotronTimeDollars from "./JumbotronTimeDollars";
import JumbotronTimeHours from "./JumbotronTimeHours";
import JumbotronTotal from "./JumbotronTotal";

const JumbotronOverview = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const jumbotronUsedPlannedTimeHoursDisplay =
    useJumbotronUsedPlannedTimeHoursDisplay(jobId);
  const hasQuotes = useHasQuotes(jobId);
  const hasInvoices = useHasInvoices(jobId);
  const hasExpenses = useHasLoggedExpenses(jobId);
  const { canAccessCostRates, canEditJobs, canViewJobFinancials } =
    usePermissions();

  const hasIncompleteMinutes = getTotalIncompleteMinutes(job) > 0;

  const hasTime =
    jumbotronUsedPlannedTimeHoursDisplay.loggedMinutes > 0 ||
    jumbotronUsedPlannedTimeHoursDisplay.plannedMinutes > 0;

  const mounted = useIsMounted()();

  return (
    <Container>
      <LeftColumn>
        <JumbotronTeamMembers />
        {!isArchived(job) && <JumbotronStatus />}
        {canViewJobFinancials && <JumbotronBudget />}
        {canViewJobFinancials && <JumbotronTotal />}
        {canViewJobFinancials && canAccessCostRates && <JumbotronMargin />}
      </LeftColumn>
      <Spacer w={30}></Spacer>
      <RightColumn>
        {!hasTime &&
          !hasExpenses &&
          !hasQuotes &&
          !hasInvoices &&
          !hasIncompleteMinutes && (
            <AnimateWrapper type="fade" initial={mounted}>
              <EmptyJobJumbotronMessage canEditJobs={canEditJobs} />
            </AnimateWrapper>
          )}
        {hasTime && (
          <>
            <AnimateWrapper type="fade" initial={mounted}>
              <JumbotronTimeHours />
            </AnimateWrapper>
            {canViewJobFinancials && job.isBillable && (
              <AnimateWrapper type="fade" initial={mounted}>
                <JumbotronTimeDollars />
              </AnimateWrapper>
            )}
          </>
        )}
        {(hasTime || hasIncompleteMinutes) && (
          <AnimateWrapper type="fade" initial={mounted}>
            <JumbotronScheduled />
          </AnimateWrapper>
        )}

        {hasExpenses && canViewJobFinancials && (
          <AnimateWrapper type="fade" initial={mounted}>
            <JumbotronExpenses />
          </AnimateWrapper>
        )}
        {hasQuotes && canViewJobFinancials && (
          <AnimateWrapper type="fade" initial={mounted}>
            <JumbotronQuotes />
          </AnimateWrapper>
        )}
        {hasInvoices && canViewJobFinancials && (
          <AnimateWrapper type="fade" initial={mounted}>
            <JumbotronInvoices />
          </AnimateWrapper>
        )}
      </RightColumn>
    </Container>
  );
};

export default JumbotronOverview;

const Container = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
`;

const LeftColumn = styled.div`
  width: calc(50% - 15px);
  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RightColumn = styled.div`
  width: calc(50% - 15px);
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;
