import { isError } from "lodash-es";
import { handleWarning } from "../errorHandlers";
import WebAppAPI, { SEND_GET_PUSHER_SOCKET_ID } from "./index";

let pusherSocketId = null;

export const getPusherSocketId = () => {
  if (isError(pusherSocketId)) return "";

  try {
    pusherSocketId = WebAppAPI.sendAction(SEND_GET_PUSHER_SOCKET_ID);
    return pusherSocketId;
  } catch (error) {
    pusherSocketId = error;
    handleWarning(error.message);
    return "";
  }
};
