import { max } from "lodash-es";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import useDebounce from "../../../lib/hooks/useDebounce";
import { useFocusRef } from "../../../lib/hooks/useFocusRef";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import { entityIdType } from "../../../lib/types/entityTypes";
import { scrollToLabels } from "../../../lib/ui/jobDetails";
import { hotkeyEmitter, Hotkeys } from "../../../lib/WebAppAPI/fePages/hotkey";
import { useLabelOptions } from "../../../redux/selectors/options/labelOptions";
import FreeAutocomplete from "../../elements/Autocomplete/FreeAutocomplete";
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
} from "../../elements/Autocomplete/styles";
import { IconButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";
import LabelIcon from "../../elements/Icons/custom/LabelIcon";
import { Label } from "./styles";

const AddNewLabel = ({ entityId, labelTypeId, onCreate }) => {
  const [value, setValue] = useState("");
  const [ref, focused, setFocused] = useFocusRef();
  const blockBlurSaveRef = useRef(false);

  const labelOptions = useLabelOptions(entityId, labelTypeId);

  const handleInputChange = (e, newValue, reason) => setValue(String(newValue));

  const handleChange = (e, option, reason) => {
    onCreate(String(option.value));
    blockBlurSaveRef.current = true;
    reset();
  };

  const reset = () => {
    setValue("");
    setFocused(false);
  };

  const handleBlur = (e) => {
    if (value.length > 0 && !blockBlurSaveRef.current) onCreate(value);
    blockBlurSaveRef.current = false;
    reset();
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  const debounceHandleFocus = useDebounce(handleFocus, 200);

  const addLabel = () => {
    requestAnimationFrame(() => {
      scrollToLabels();
    });
    ref.current.focus();
  };

  useEffect(() => {
    hotkeyEmitter.on(Hotkeys.L, addLabel);
    return () => {
      hotkeyEmitter.off(Hotkeys.L, addLabel);
    };
  }, []);

  useKeyEvent(
    focused,
    "keydown",
    (e) => {
      // enter
      if (e.keyCode === 13) {
        handleBlur();
        if (value.length > 0) debounceHandleFocus();
      }
      // tab
      if (e.keyCode === 9) {
        if (value.length > 0) debounceHandleFocus();
      }
    },
    [debounceHandleFocus, handleFocus, handleBlur]
  );

  return (
    <StyledLabel $focused={focused}>
      <Container>
        <FocusContainer onClick={!focused ? handleFocus : null}>
          <LabelIconWrapper>
            <LabelIcon size={12}></LabelIcon>
          </LabelIconWrapper>
          <FreeAutocomplete
            ref={ref}
            options={labelOptions}
            value={value}
            onInputChange={handleInputChange}
            onChange={handleChange}
            onClose={handleBlur}
            onOpen={handleFocus}
            AutocompleteComponent={CustomStyledAutocomplete}
            PaperComponent={CustomStyledAutocompletePaper}
            inputProps={{
              size: max([value.length, 8]),
              maxLength: 32,
            }}
          ></FreeAutocomplete>
        </FocusContainer>
        <StyledIconButton $show={value.length > 0} onMouseDown={reset}>
          <ClearIcon size={12}></ClearIcon>
        </StyledIconButton>
      </Container>
    </StyledLabel>
  );
};

AddNewLabel.propTypes = {
  entityId: entityIdType.isRequired,
  labelTypeId: entityIdType.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default AddNewLabel;

export const focusedCSS = css`
  background-color: white;

  .LabelIcon {
    color: black;
  }
`;

export const unFocusedCSS = css`
  &:hover {
    background-color: white;

    .LabelIcon {
      color: black;
    }
  }

  cursor: pointer;

  .StyledInput {
    cursor: pointer;
  }

  .MuiInputBase-input {
    cursor: pointer;
  }
`;

const StyledLabel = styled(Label)`
  ${(props) => (props.$focused ? focusedCSS : unFocusedCSS)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FocusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelIconWrapper = styled.div.attrs({ className: "LabelIcon" })`
  color: var(--color-gray);
`;

const StyledIconButton = styled(IconButton).attrs({
  className: "StyledIconButton",
})`
  width: 15px;
  height: 15px;
  visibility: ${(props) => (props.$show ? "visible" : "hidden")};
`;

const CustomStyledAutocomplete = styled(StyledAutocomplete).attrs({
  className: "StyledInput",
})`
  .MuiAutocomplete-inputRoot {
    background-color: transparent;
    height: 25px;
  }
  input {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    &:first-child {
      padding: 0 0 0 10px !important;
    }
    width: 100% !important;
  }
`;

const CustomStyledAutocompletePaper = styled(StyledAutocompletePaper)`
  margin: 0 -27px 0 -21px;
  .MuiAutocomplete-listbox {
    max-height: 107px;
    font-size: var(--font-size-small);
  }
  .MuiAutocomplete-option {
    padding: 4px 10px;
  }
  .MuiAutocomplete-noOptions {
    padding: 7px 10px;
    font-size: var(--font-size-small);
  }
`;
