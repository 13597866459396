import { reduce } from "lodash-es";
import * as PropTypes from "prop-types";
import {
  ITEM_PRICING_METHOD_TYPE_ID_FIXED,
  ITEM_PRICING_METHOD_TYPE_ID_MARKUP,
  ITEM_PRICING_METHOD_TYPE_ID_UNIT
} from "../constants";
import { asDecimal } from "../math";
import { entityFieldDecimalType, entityIdType } from "../types/entityTypes";
import {
  calculateMarkedUpTotalWithExchangeRate,
  calculateMarkupWithExchangeRate,
  calculateTotalFromQuantityByRate,
  calculateUnitRate,
  commercialDocumentLineItemDefaults,
  commercialDocumentLineItemShape,
  documentLineItemMethods,
  getQuantity,
  getTotalAmountExTax,
  getUnitRate
} from "../ui/commercialDocumentLineItems";

export const purchaseOrderLineItemShape = {
  ...commercialDocumentLineItemShape,
  purchaseOrderId: entityIdType.isRequired,
  itemPricingMethodId: entityIdType.isRequired,
  markup: entityFieldDecimalType,
  jobCurrencySellRateExTax: entityFieldDecimalType,
  jobCurrencyTotalSellExTax: entityFieldDecimalType
};

export const purchaseOrderLineItemEntityType = PropTypes.shape(
  purchaseOrderLineItemShape
);

export const purchaseOrderLineItemDefaults = {
  ...commercialDocumentLineItemDefaults,
  itemPricingMethodId: ITEM_PRICING_METHOD_TYPE_ID_MARKUP,
  markup: 0,
  jobCurrencySellRateExTax: 0,
  jobCurrencyTotalSellExTax: 0
};

export const getPurchaseOrderId = ({ purchaseOrderId }) => purchaseOrderId;

export const getItemPricingMethodId = ({ itemPricingMethodId }) =>
  itemPricingMethodId;

export const getMarkup = ({ markup }) => markup;

export const getJobCurrencySellRateExTax = ({ jobCurrencySellRateExTax }) =>
  jobCurrencySellRateExTax;

export const getJobCurrencyTotalSellExTax = ({ jobCurrencyTotalSellExTax }) =>
  jobCurrencyTotalSellExTax;

export const mergeJobCurrencySellTotals = lineItems =>
  reduce(
    lineItems,
    (total, lineItem) =>
      total + asDecimal(getJobCurrencyTotalSellExTax(lineItem)),
    0
  );

const setMarkup = (lineItem, value, exchangeRate) => {
  const markup = asDecimal(value);
  const quantity = getQuantity(lineItem);
  const totalAmountExTax = getTotalAmountExTax(lineItem);
  let unitRate = getUnitRate(lineItem);

  if (!unitRate) unitRate = calculateUnitRate(quantity, totalAmountExTax);

  const jobCurrencySellRateExTax = calculateMarkedUpTotalWithExchangeRate(
    unitRate,
    markup,
    exchangeRate
  );

  const jobCurrencyTotalSellExTax = calculateTotalFromQuantityByRate(
    quantity,
    jobCurrencySellRateExTax
  );

  return {
    ...lineItem,
    itemPricingMethodId: ITEM_PRICING_METHOD_TYPE_ID_MARKUP,
    unitRate,
    markup,
    jobCurrencySellRateExTax,
    jobCurrencyTotalSellExTax
  };
};

const setJobCurrencySellRateExTax = (lineItem, value, exchangeRate) => {
  const jobCurrencySellRateExTax = asDecimal(value);
  const quantity = getQuantity(lineItem);
  const totalAmountExTax = getTotalAmountExTax(lineItem);
  let unitRate = getUnitRate(lineItem);

  if (!unitRate) unitRate = calculateUnitRate(quantity, totalAmountExTax);

  const markup = calculateMarkupWithExchangeRate(
    unitRate,
    jobCurrencySellRateExTax,
    exchangeRate
  );

  const jobCurrencyTotalSellExTax = calculateTotalFromQuantityByRate(
    quantity,
    jobCurrencySellRateExTax
  );

  return {
    ...lineItem,
    itemPricingMethodId: ITEM_PRICING_METHOD_TYPE_ID_UNIT,
    unitRate,
    markup,
    jobCurrencySellRateExTax,
    jobCurrencyTotalSellExTax
  };
};

const setJobCurrencyTotalSellExTax = (lineItem, value, exchangeRate) => {
  const jobCurrencyTotalSellExTax = asDecimal(value);
  const quantity = getQuantity(lineItem);
  const totalAmountExTax = getTotalAmountExTax(lineItem);
  let unitRate = getUnitRate(lineItem);

  if (!unitRate) unitRate = calculateUnitRate(quantity, totalAmountExTax);

  const jobCurrencySellRateExTax = calculateUnitRate(
    quantity,
    jobCurrencyTotalSellExTax
  );
  const markup = calculateMarkupWithExchangeRate(
    unitRate,
    jobCurrencySellRateExTax,
    exchangeRate
  );

  return {
    ...lineItem,
    unitRate,
    markup,
    itemPricingMethodId: ITEM_PRICING_METHOD_TYPE_ID_FIXED,
    jobCurrencySellRateExTax,
    jobCurrencyTotalSellExTax
  };
};

export const updateSellValues = updateCostValue => (
  lineItem,
  value,
  exchangeRate
) => {
  const newLineItem = updateCostValue(lineItem, value);

  if (
    getItemPricingMethodId(newLineItem) === ITEM_PRICING_METHOD_TYPE_ID_MARKUP
  ) {
    if (getTotalAmountExTax(newLineItem) >= 0)
      return setMarkup(newLineItem, getMarkup(newLineItem), exchangeRate);

    return setJobCurrencySellRateExTax(
      newLineItem,
      getJobCurrencySellRateExTax(newLineItem),
      exchangeRate
    );
  }

  if (getItemPricingMethodId(newLineItem) === ITEM_PRICING_METHOD_TYPE_ID_UNIT)
    return setJobCurrencySellRateExTax(
      newLineItem,
      getJobCurrencySellRateExTax(newLineItem),
      exchangeRate
    );

  return setJobCurrencyTotalSellExTax(
    newLineItem,
    getJobCurrencyTotalSellExTax(lineItem),
    exchangeRate
  );
};

export const purchaseOrderLineItemMethods = {
  ...documentLineItemMethods,
  setQuantity: updateSellValues(documentLineItemMethods.setQuantity),
  setUnitRate: updateSellValues(documentLineItemMethods.setUnitRate),
  setTotalAmountExTax: updateSellValues(
    documentLineItemMethods.setTotalAmountExTax
  ),
  getMarkup: lineItem => asDecimal(getMarkup(lineItem)),
  setMarkup,
  getJobCurrencySellRateExTax: lineItem =>
    asDecimal(getJobCurrencySellRateExTax(lineItem)),
  setJobCurrencySellRateExTax,
  getJobCurrencyTotalSellExTax: lineItem =>
    asDecimal(getJobCurrencyTotalSellExTax(lineItem)),
  setJobCurrencyTotalSellExTax
};
