import { call, put, takeEvery } from "redux-saga/effects";

import { saveInvoiceAPI } from "../../../lib/API/invoiceAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_CLASS_INVOICE,
  INVOICE_SAVE,
  INVOICE_SAVE_FAILED,
  INVOICE_SAVED,
  INVOICE_SAVING,
} from "../../../lib/constants";
import { sendApplyModelData } from "../../../lib/WebAppAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import { entitySaving } from "../../../lib/WebAppAPI/entity";
import { EntityClasses } from "../../../state/entities/types";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* save(action) {
  const { id, invoice } = action.payload;

  try {
    entitySaving(EntityClasses.Invoice, id, true);

    yield put(
      createAction(INVOICE_SAVING, {
        id,
      })
    );

    const data = yield call(saveInvoiceAPI, invoice);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(INVOICE_SAVED, { id }));

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_SAVE_FAILED, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  } finally {
    entitySaving(EntityClasses.Invoice, id, false);
  }
}

function sendModelData(action) {
  try {
    const { invoice } = action.payload;

    sendApplyModelData(ENTITY_CLASS_INVOICE, invoice);
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchInvoiceSave() {
  yield takeLatestBy(INVOICE_SAVE, save, (action) => action.payload.id);
  yield takeEvery(INVOICE_SAVE, sendModelData);
}
