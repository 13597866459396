import numeral from "numeral";

import { NUMBER_FORMAT_TWO_DECIMALS } from "./constants";
import { asDecimal } from "./math";

export const padNumber = (number: number, length: number) =>
  `0000000000${number}`.slice(-length);

export const convertMinutesToTimeHM = (
  totalMinutes: number,
  padMinutes = false,
  unit: string | null = null,
  padHours = true
) => {
  if (!totalMinutes) {
    if (padMinutes) return "0h00m";
    return "0h";
  }

  if (unit === "h" || unit === "hour")
    return `${Math.round(totalMinutes / 60)}h`;

  if (unit === "m" || unit === "minute")
    return `${Math.round(totalMinutes) % 60}m`;

  const isNegative = totalMinutes < 0;

  totalMinutes = Math.abs(totalMinutes);

  const totalMinutesRounded = Math.round(totalMinutes);
  const h =
    totalMinutesRounded >= 60 || padHours
      ? `${Math.floor(totalMinutesRounded / 60)}h`
      : "";
  const minutes = Math.abs(totalMinutesRounded) % 60;
  const m = minutes > 0 || padMinutes ? `${padNumber(minutes, 2)}m` : "";

  return `${isNegative ? "-" : ""}${h}${m}`;
};

export const convertHoursMinutesToDecimal = (
  hours: number | string,
  minutes: number | string
) => asDecimal(hours) + asDecimal(minutes) / 60;

export const convertMinutesToHours = (minutes: number | string) => {
  const roundedMinutes = numeral(numeral(minutes).format("0")).value();
  if (roundedMinutes === 0) return "";
  return numeral(roundedMinutes).divide(60).format(NUMBER_FORMAT_TWO_DECIMALS);
};
