import { produce } from "immer";
import { get, isEmpty, isEqual, pull, set, unset } from "lodash-es";
import { Entity } from "st-shared/entities/Entity";

import { pushOnce } from "../../lib/arrays";
import { isNilOrFalse } from "../../lib/lang";
import { ChangedEntity } from "../entities/types";

type Key = number | string;

function createEntityIndexedArrayReducer<T extends Entity, U>(
  getKeyPath: (entity: T) => Key,
  getValue: (entity: T) => U = (entity) => entity && (entity.id as U)
) {
  return function (
    state: { [key: Key]: U[] } = {},
    changedEntities: ChangedEntity<T>[]
  ) {
    return produce(state, (draft: { [key: Key]: U[] }) => {
      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const prevValue = prevEntity && getValue(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);
        const newValue = newEntity && getValue(newEntity);
        const hasChanged =
          !isEqual(prevPath, newPath) || !isEqual(prevValue, newValue);

        if (!isNilOrFalse(prevPath) && !isNilOrFalse(prevValue) && hasChanged) {
          const indexes = get(draft, prevPath as Key);

          pull(indexes, prevValue);

          if (isEmpty(indexes)) unset(draft, prevPath as Key);
          else set(draft, prevPath as Key, indexes);
        }
      });

      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const prevValue = prevEntity && getValue(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);
        const newValue = newEntity && getValue(newEntity);
        const hasChanged =
          !isEqual(prevPath, newPath) || !isEqual(prevValue, newValue);

        if (!isNilOrFalse(newPath) && !isNilOrFalse(newValue) && hasChanged) {
          const indexes = get(draft, newPath as Key, []);

          pushOnce(indexes, newValue);

          set(draft, newPath as Key, indexes);
        }
      });
    });
  };
}

export default createEntityIndexedArrayReducer;
