import { ENTITIES_RECEIVED } from "../../../../lib/constants";
import createCrossReducer from "../../../helpers/createCrossReducer";
import validateJobItemDependancies from "./validateJobItemDependancies";
import validateJobItems from "./validateJobItems";

const receiveEntitiesReducer = (state, action) => {
  validateJobItems(state, action);
  validateJobItemDependancies(state, action);

  return state;
};

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntitiesReducer
});
