import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { selectPropsJobItemId } from "../../index";

export const selectStateJobItemSubItemsUi = state => state.ui.jobItemSubItems;

export const selectJobItemSubItemsFromJobItemId = createCachedSelector(
  [selectStateJobItemSubItemsUi, selectPropsJobItemId],
  (jobItemSubItemsUi, jobItemId) => get(jobItemSubItemsUi, `${jobItemId}`)
)(selectPropsJobItemId);
