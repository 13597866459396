import clsx from "clsx";
import { ReactNode } from "react";
import { IconButton, MdLock, MdSchedule, Tooltip } from "st-shared/components";

import { lockIconContainer, tooltipTitleCss } from "./MethodLock.css";

interface Props {
  tooltip: ReactNode;
  className?: string;
  onClick: () => void;
  isTime?: boolean;
}

export function MethodLock({
  tooltip,
  className,
  onClick,
  isTime = false,
}: Props) {
  return (
    <Tooltip
      title={<div className={tooltipTitleCss}>{tooltip}</div>}
      placement="top"
    >
      <IconButton
        className={clsx(lockIconContainer, className)}
        iconProps={{
          size: 18,
          icon: isTime ? MdSchedule : MdLock,
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
}
