import { ENTITY_CLASS_LOGGED_EXPENSE } from "../constants";
import { getLoggedExpenseAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const transformLoggedExpenseBootstrap = modelSet => {
  return createModelSetParser(ENTITY_CLASS_LOGGED_EXPENSE)(modelSet);
};

export const fetchLoggedExpenseBootstrapAPI = loggedExpenseId =>
  API.fetch(`/logged_expenses/${loggedExpenseId}`, { bootstrap: true })
    .then(res => res.data)
    .then(transformLoggedExpenseBootstrap);
export const saveLoggedExpenseAPI = loggedExpense =>
  API.put(
    `/logged_expenses/${loggedExpense.id}`,
    getLoggedExpenseAsMessage(loggedExpense)
  )
    .then(({ data }) => data)
    .then(createModelSetParser(ENTITY_CLASS_LOGGED_EXPENSE))
    .then(({ data }) => data);
