import { isEmpty } from "lodash-es";
import {
  getJobIdKeyPath,
  getJobItemUserIdKeyPath,
  getScheduleBlockKey,
  getUserIdDateKeyPath,
  getUserIdJobIdKeyPath,
  getUserIdKeyPath,
  isBaseLoggedTime,
  getJobIdIfNoItemBaseLoggedTime,
  getJobIdIfBaseLoggedTime,
  getJobItemUserIdIfBaseLoggedTime,
  getJobItemUserId,
  getJobItemId,
  getJobId,
  getRepeatingLoggedTimeId
} from "../../../../lib/entities/scheduleLoggedTimeEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import daysByBlockKeyReducer from "./daysByBlockKeyReducer";
import isPausedByBlockKeyReducer from "./isPausedByBlockKeyReducer";
import maxBlockHeightByJobItemUserIdReducer from "./maxBlockHeightByJobItemUserIdReducer";
import maxBlockHeightByUserIdJobIdNoItemReducer from "./maxBlockHeightByUserIdJobIdNoItemReducer";
import maxBlockHeightByUserIdJobIdReducer from "./maxBlockHeightByUserIdJobIdReducer";
import maxBlockHeightByUserIdReducer from "./maxBlockHeightByUserIdReducer";
import offsetYByBlockKeyReducer from "./offsetYByBlockKeyReducer";
import scheduleUtilisationReducer from "./scheduleUtilisationReducer";
import sortedBlockKeysByUserIdDateReducer from "./sortedBlockKeysByUserIdDateReducer";
import userIdsByJobIdNoItemReducer from "./userIdsByJobIdNoItemReducer";
import validateChangedEntities from "./validateChangedEntities";

const idsByBlockKeyReducer = createEntityIndexedArrayReducer(entity =>
  getScheduleBlockKey(entity)
);

const idsByJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const idsByJobItemIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobItemId(entity)
);

const idsByJobItemUserIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobItemUserId(entity)
);

const idsByRepeatingLoggedTimeIdReducer = createEntityIndexedArrayReducer(
  entity => getRepeatingLoggedTimeId(entity)
);

const blockKeysByUserIdReducer = createEntityIndexedArrayReducer(
  getUserIdKeyPath,
  entity => (isBaseLoggedTime(entity) ? getScheduleBlockKey(entity) : null)
);

const blockKeysByJobIdReducer = createEntityIndexedArrayReducer(
  getJobIdKeyPath,
  entity =>
    getJobIdIfBaseLoggedTime(entity) ? getScheduleBlockKey(entity) : null
);

const blockKeysByUserIdDateReducer = createEntityIndexedArrayReducer(
  getUserIdDateKeyPath,
  entity => (entity ? getScheduleBlockKey(entity) : null)
);

const blockKeysByUserIdJobIdReducer = createEntityIndexedArrayReducer(
  getUserIdJobIdKeyPath,
  entity =>
    getJobIdIfBaseLoggedTime(entity) ? getScheduleBlockKey(entity) : null
);

const blockKeysByJobItemUserIdReducer = createEntityIndexedArrayReducer(
  getJobItemUserIdKeyPath,
  entity =>
    getJobItemUserIdIfBaseLoggedTime(entity)
      ? getScheduleBlockKey(entity)
      : null
);

const blockKeysByUserIdJobIdNoItemReducer = createEntityIndexedArrayReducer(
  getUserIdJobIdKeyPath,
  entity =>
    getJobIdIfNoItemBaseLoggedTime(entity) ? getScheduleBlockKey(entity) : null
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByBlockKey: idsByBlockKeyReducer(state.idsByBlockKey, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities),
    idsByJobItemId: idsByJobItemIdReducer(
      state.idsByJobItemId,
      changedEntities
    ),
    idsByJobItemUserId: idsByJobItemUserIdReducer(
      state.idsByJobItemUserId,
      changedEntities
    ),
    idsByRepeatingLoggedTimeId: idsByRepeatingLoggedTimeIdReducer(
      state.idsByRepeatingLoggedTimeId,
      changedEntities
    ),
    blockKeysByUserId: blockKeysByUserIdReducer(
      state.blockKeysByUserId,
      changedEntities
    ),
    blockKeysByJobId: blockKeysByJobIdReducer(
      state.blockKeysByJobId,
      changedEntities
    ),
    blockKeysByUserIdDate: blockKeysByUserIdDateReducer(
      state.blockKeysByUserIdDate,
      changedEntities
    ),
    blockKeysByUserIdJobId: blockKeysByUserIdJobIdReducer(
      state.blockKeysByUserIdJobId,
      changedEntities
    ),
    blockKeysByJobItemUserId: blockKeysByJobItemUserIdReducer(
      state.blockKeysByJobItemUserId,
      changedEntities
    ),
    blockKeysByUserIdJobIdNoItem: blockKeysByUserIdJobIdNoItemReducer(
      state.blockKeysByUserIdJobIdNoItem,
      changedEntities
    )
  };

  validateChangedEntities(nextState, changedEntities);

  nextState = isPausedByBlockKeyReducer(nextState, changedEntities);

  nextState = daysByBlockKeyReducer(nextState, changedEntities);

  nextState = sortedBlockKeysByUserIdDateReducer(nextState, changedEntities);

  nextState = userIdsByJobIdNoItemReducer(nextState, changedEntities);

  nextState = scheduleUtilisationReducer(nextState, changedEntities);

  nextState = offsetYByBlockKeyReducer(nextState, changedEntities);

  nextState = maxBlockHeightByUserIdReducer(nextState, changedEntities);

  nextState = maxBlockHeightByUserIdJobIdReducer(nextState, changedEntities);

  nextState = maxBlockHeightByJobItemUserIdReducer(nextState, changedEntities);

  nextState = maxBlockHeightByUserIdJobIdNoItemReducer(
    nextState,
    changedEntities
  );

  return nextState;
};

export default reduceChangedEntities;
