import { createIcon } from "../../icon/Icon";

export const IconStrongArrowDown = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.1565 4L10.2596 4L10.2596 12.4889L4.41602 12.4889L12.208 20L20 12.4889L14.1565 12.4889L14.1565 4Z"
  />,
  "IconStrongArrowDown"
);
