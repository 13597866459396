import { get } from "lodash-es";
import { call, put, select, delay } from "redux-saga/effects";
import { duplicateJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_DUPLICATE,
  JOB_ITEM_DUPLICATE_ENDED,
  JOB_ITEM_DUPLICATE_STARTED,
  JOB_ITEM_SET_EXPANDED
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItems } from "../../selectors/jobItemSelectors";

function* duplicate(action) {
  const { jobItemId } = action.payload;

  try {
    yield put(createAction(JOB_ITEM_DUPLICATE_STARTED, { jobItemId }));

    const previousJobItems = yield select(selectJobItems);
    const { data } = yield call(duplicateJobItemAPI, jobItemId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    if (data && data.jobItems) {
      for (let i = 0; i < data.jobItems.length; i += 1) {
        const id = data.jobItems[i].id;

        if (get(previousJobItems, `byId[${id}]`, null) === null) {
          yield delay(200);

          yield put(
            createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
              currentFocus: {
                jobItemId: id,
                key: FOCUS_KEYS.ITEM_NAME
              }
            })
          );

          yield put(
            createAction(JOB_ITEM_SET_EXPANDED, {
              jobItemId: id,
              isExpanded: true
            })
          );

          break;
        }
      }
    }
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(createAction(JOB_ITEM_DUPLICATE_ENDED, { jobItemId }));
  }
}

export default function* watchDuplicateJobItem() {
  yield takeLatestBy(
    [JOB_ITEM_DUPLICATE],
    duplicate,
    action => action.payload.jobItemId
  );
}
