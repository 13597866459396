import { createIcon } from "../../icon/Icon";

export const IconAddItem = createIcon(
  <>
    <path d="M6 7.65V5.65H8V7.65H6Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 21V3H21V13H19V10.325H5V13.675H16V15.675H5V19H13V21H3ZM5 8.325H19V5H5V8.325Z"
    />
    <path d="M6 13V11H8V13H6Z" />
    <path d="M6 18.35V16.35H8V18.35H6Z" />
    <path d="M18 15V18H15V20H18V23H20V20H23V18H20V15H18Z" />
  </>,
  "IconAddItem"
);
