import { SavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import { SavedSegmentSidebar } from "st-shared/module";
import { useLoggedInUserId } from "st-shared/stores";

import { RouteLink } from "../../../../../components/router/RouteLink";
import { RoutePages, routerPush } from "../../../../../lib/router";
import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingSavedSegmentEntityId } from "../../../state/stores/savedSegmentSelectors";

export function Favourites() {
  const currentSegmentId = useReportingSavedSegmentEntityId();
  const loggedInUserId = useLoggedInUserId() || undefined;

  function onDeleteSavedSegment(segmentId: TEntityId) {
    if (currentSegmentId === segmentId) {
      reportingStore().savedSegment.actions.unsetUserPreference();
      routerPush(RoutePages.Reporting);
    }
  }

  return (
    <SavedSegmentSidebar
      name="Report"
      savedSegmentType={SavedSegmentTypeEnum.Reporting}
      currentSegmentId={currentSegmentId}
      loggedInUserId={loggedInUserId}
      onDeleteSavedSegment={onDeleteSavedSegment}
      RouteComponent={({ segmentId, className, children, ...props }) => (
        <RouteLink
          route={RoutePages.Reporting}
          entityId={segmentId}
          className={className}
          {...props}
        >
          {children}
        </RouteLink>
      )}
    />
  );
}
