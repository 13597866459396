import { get } from "lodash-es";
import { createSelector } from "reselect";

import { ENTITY_NAME_RATE_CARDS } from "../../lib/constants";
import { selectJobListModel } from "../../state/entities/jobListModel/selectors/selectJobListModel";
import { selectPropsId } from "./index";
import { selectJobById } from "./jobSelectors";

const selectRateCards = (state) => state.entities[ENTITY_NAME_RATE_CARDS];

export const selectRateCardById = createSelector(
  selectRateCards,
  selectPropsId,
  (rateCards, id) => get(rateCards, `byId.${id}]`)
);

export const selectJobCurrencyById = (state, props) => {
  const job =
    selectJobById(state, props) ||
    selectJobListModel(state, { jobId: props.id });
  const rateCard = selectRateCardById(state, { id: job.rateCardId });
  return rateCard.currency;
};

export const selectJobExchangeRateById = (state, props) => {
  const job = selectJobById(state, props);
  const scheduleSearchResult = selectJobListModel(state, { jobId: props.id });
  return job ? job.exchangeRate : scheduleSearchResult.jobExchangeRate;
};
