export const ENTITY_NAME_ACCOUNTING_PLATFORMS = "accountingPlatforms";
export const ENTITY_NAME_ACTIVITY_ENTRIES = "activityEntries";
export const ENTITY_NAME_ADDRESSES = "addresses";
export const ENTITY_NAME_COMPANIES = "companies";
export const ENTITY_NAME_CONTACTS = "contacts";
export const ENTITY_NAME_CURRENCIES = "currencies";
export const ENTITY_NAME_INVOICES = "invoices";
export const ENTITY_NAME_INVOICE_LINE_ITEMS = "invoiceLineItems";
export const ENTITY_NAME_INVOICE_PAYMENTS = "invoicePayments";
export const ENTITY_NAME_JOB_ITEM_DEPENDANCIES = "jobItemDependancies";
export const ENTITY_NAME_JOB_ITEM_USERS = "jobItemUsers";
export const ENTITY_NAME_JOB_ITEM_SUB_ITEMS = "jobItemSubItems";
export const ENTITY_NAME_JOB_ITEMS = "jobItems";
export const ENTITY_NAME_JOB_MILESTONES = "jobMilestones";
export const ENTITY_NAME_JOB_PHASES = "jobPhases";
export const ENTITY_NAME_JOBS = "jobs";
export const ENTITY_NAME_LOGGED_EXPENSES = "loggedExpenses";
export const ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES =
  "organisationAccountingPreferences";
export const ENTITY_NAME_PURCHASE_ORDERS = "purchaseOrders";
export const ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS = "purchaseOrderLineItems";
export const ENTITY_NAME_QUOTES = "quotes";
export const ENTITY_NAME_QUOTE_LINE_ITEMS = "quoteLineItems";
export const ENTITY_NAME_TEAMS = "teams";
export const ENTITY_NAME_USERS = "users";
export const ENTITY_NAME_REPEATING_LOGGED_TIMES = "repeatingLoggedTimes";
export const ENTITY_NAME_ROLES = "roles";
export const ENTITY_NAME_ROLE_RATES = "roleRates";
export const ENTITY_NAME_RATE_CARDS = "rateCards";
export const ENTITY_NAME_TAX_RATES = "taxRates";
export const ENTITY_NAME_LABELS = "labels";

export const ENTITY_NAME_COMPANY_OPTIONS = "companyOptions";
export const ENTITY_NAME_JOB_PHASE_OPTIONS = "jobPhaseOptions";
export const ENTITY_NAME_LABEL_OPTIONS = "labelOptions";
