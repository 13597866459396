import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { FilterGroupTypeEnum } from "@streamtimefe/entities";
import clsx from "clsx";
import { isNull } from "lodash-es";
import { IconButton, IconSize, MdChevronRight } from "st-shared/components";
import { EllipsisTooltipText } from "st-shared/module";
import { cursorPointer, zIndex10 } from "st-shared/theme";

import type { KeyedSeriesData } from "../../lib/VectorMatrixSeriesData";
import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentFilterGroupType,
  useReportingSavedSegmentSubFilterGroupType,
} from "../../state/stores/savedSegmentSelectors";
import { useReportingSubSeriesExpanded } from "../../state/stores/searchSelectors";
import {
  entityLink,
  expanderIcon,
  expanderIconButton,
  fallbackTitle,
} from "./TableEntityText.css";

type Props = {
  hasSubFilter: boolean;
  seriesData: KeyedSeriesData;
  getEnitityId: ((id: string) => string) | null;
};

export function TableEntityText({
  seriesData,
  getEnitityId,
  hasSubFilter,
}: Props) {
  const filterGroupType = useReportingSavedSegmentFilterGroupType();
  const expanded = useReportingSubSeriesExpanded(seriesData.id);

  const canExpand = hasSubFilter && filterGroupType;

  function onExpanded() {
    reportingStore().search.actions.subSeriesExpand(
      seriesData.id,
      seriesData.key,
      !expanded
    );
  }

  function renderText() {
    if (seriesData.key && getEnitityId) {
      return (
        <EllipsisTooltipText
          as="a"
          target="_parent"
          href={getEnitityId(seriesData.key)}
          className={clsx(zIndex10, entityLink)}
        >
          {seriesData.title}
        </EllipsisTooltipText>
      );
    }

    return (
      <EllipsisTooltipText
        className={clsx(
          zIndex10,
          (isNull(seriesData.key) ||
            isUnassigned(filterGroupType, seriesData)) &&
            fallbackTitle,
          canExpand && cursorPointer
        )}
        onClick={canExpand ? onExpanded : undefined}
      >
        {seriesData.title}
      </EllipsisTooltipText>
    );
  }

  return (
    <>
      {canExpand && (
        <IconButton
          className={expanderIconButton}
          iconProps={{
            icon: MdChevronRight,
            size: IconSize.XLarge,
            className: expanded ? expanderIcon.open : expanderIcon.close,
          }}
          onClick={onExpanded}
        />
      )}
      {renderText()}
    </>
  );
}

type SubProps = {
  seriesData: KeyedSeriesData;
  getEnitityId: ((id: string) => string) | null;
};

export function SubTableEntityText({ seriesData, getEnitityId }: SubProps) {
  const subFilterGroupType = useReportingSavedSegmentSubFilterGroupType();

  if (isUnassigned(subFilterGroupType, seriesData)) {
    return (
      <EllipsisTooltipText className={fallbackTitle}>
        Unassigned
      </EllipsisTooltipText>
    );
  }

  if (seriesData.key && getEnitityId) {
    return (
      <EllipsisTooltipText
        as="a"
        target="_parent"
        href={getEnitityId(seriesData.key)}
        className={clsx(zIndex10, entityLink)}
      >
        {seriesData.title}
      </EllipsisTooltipText>
    );
  }

  return (
    <EllipsisTooltipText
      className={isNull(seriesData.key) ? fallbackTitle : undefined}
    >
      {seriesData.title}
    </EllipsisTooltipText>
  );
}

function isUnassigned(
  filterGroupType: TFilterGroupTypeEnum | null,
  seriesData: KeyedSeriesData
) {
  return (
    (filterGroupType === FilterGroupTypeEnum.RoleId ||
      filterGroupType === FilterGroupTypeEnum.TeamMember) &&
    seriesData.key === "unassigned"
  );
}
