import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const MasterJobItemStore = createSingleEntityStoreContext(
  EntityClass.MasterJobItem
);

export const MasterJobItemStoreProvider = createSingleEntityStoreProvider(
  EntityClass.MasterJobItem,
  MasterJobItemStore
);
