import { plate, slate } from "st-shared/external";

import { KeyboardEventHandler } from "./KeyboardEventHandler";

export interface MoveSelectionByOffsetOptions<
  V extends plate.Value = plate.Value,
> {
  query?: (editor: plate.PlateEditor<V>) => boolean;
}

export const moveSelectionByOffset: <V extends plate.Value>(
  editor: plate.PlateEditor<V>,
  options?: MoveSelectionByOffsetOptions<V>
) => KeyboardEventHandler =
  (editor, { query = () => true } = {}) =>
  (event) => {
    const { selection } = editor;

    if (!selection || slate.Range.isExpanded(selection) || !query(editor)) {
      return false;
    }

    if (plate.isHotkey("left", event)) {
      event.preventDefault();
      plate.moveSelection(editor, { unit: "offset", reverse: true });
      return true;
    }

    if (plate.isHotkey("right", event)) {
      event.preventDefault();
      plate.moveSelection(editor, { unit: "offset" });
      return true;
    }

    return;
  };
