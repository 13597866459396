import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { LoggedExpenseStore } from "./LoggedExpense.store";

export * from "./selectors";

export const useLoggedExpenseEntity = createUseEntityStoreEntity(
  EntityClass.LoggedExpense
);

export const getLoggedExpenseEntity = createGetEntityStoreEntity(
  EntityClass.LoggedExpense
);

export const useLoggedExpense = createUseSingleEntity(LoggedExpenseStore);

export function useLoggedExpenseStore() {
  return LoggedExpenseStore.useStore();
}

export function useLoggedExpenseActions() {
  return LoggedExpenseStore.useActions();
}
