import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import DetailTableField from "./Layout/DetailTableField";
import DetailTableLabel from "./Layout/DetailTableLabel";

const DetailTable = ({ children }) => (
  <Table>
    <tbody>{children}</tbody>
  </Table>
);

DetailTable.propTypes = {
  children: reactNodesType.isRequired
};

export default DetailTable;

const Table = styled.table`
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 2px;
  tr {
    margin-top: 2px;
    &.hiddenField {
      ${DetailTableLabel} {
        color: var(--color-gray-dark);
      }
      ${DetailTableField} {
        &:not(.Mui-disabled) {
          color: var(--color-gray-dark);
          background-color: var(--color-gray-bright);
        }
      }
    }
  }
  th {
    text-align: left;
    vertical-align: baseline;
    display: inline-flex;
    align-items: center;
  }
  td {
    padding-left: 30px;
  }
`;
