import type { ComponentPropsWithRef } from "react";
import { Link } from "react-router-dom";
import type { TEntityId } from "st-shared/entities/Entity";

import type { TRoutePages } from "../../lib/router";
import { createRouteLink } from "../../lib/router";

type Props = {
  route: TRoutePages;
  entityId?: TEntityId;
  suffix?: string;
} & Omit<ComponentPropsWithRef<"a">, "href" | "target">;

export function RouteLink({
  route,
  entityId,
  suffix,
  children,
  ...props
}: Props) {
  const link = createRouteLink(route, entityId, suffix);
  if (import.meta.env.PROD) {
    return (
      <a {...props} target="_parent" href={link}>
        {children}
      </a>
    );
  }
  return (
    <Link {...props} to={link}>
      {children}
    </Link>
  );
}
