import { filter, includes, sortBy } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { selectUserIdsInAllJobItemsByJobId } from "../../../../redux/selectors/job/selectUserIdsInAllJobItemsByJobId";
import { selectUserIdsByJobItemId } from "../../../../redux/selectors/jobItemUser/selectUserIdsByJobItemId";
import { selectUserIdsInAllJobItemsByPhaseId } from "../../../../redux/selectors/jobPhase/selectUserIdsInAllJobItemsByPhaseId";
import { selectActiveUserOptionsGroupedByBranch } from "../../../../redux/selectors/userSelectors";
import {
  selectPropsJobId,
  selectPropsJobItemId,
  selectPropsJobPhaseId,
} from "../../../helpers/selectors";

export const selectJobItemUserUnselectedOptionsJobItem = createCachedSelector(
  [selectActiveUserOptionsGroupedByBranch, selectUserIdsByJobItemId],
  (userOptions, userIds) => userOptionsFiltered(userOptions, userIds)
)(selectPropsJobItemId);

export const selectJobItemUserUnselectedOptionsJobPhase = createCachedSelector(
  [selectActiveUserOptionsGroupedByBranch, selectUserIdsInAllJobItemsByPhaseId],
  (userOptions, userIds) => userOptionsFiltered(userOptions, userIds)
)(selectPropsJobPhaseId);

export const selectJobItemUserUnselectedOptionsJob = createCachedSelector(
  [selectActiveUserOptionsGroupedByBranch, selectUserIdsInAllJobItemsByJobId],
  (userOptions, userIds) => userOptionsFiltered(userOptions, userIds)
)(selectPropsJobId);

export function useJobItemUserUnselectedOptions(
  entityId: number,
  type: "jobItem" | "jobPhase" | "job"
) {
  return useSelector((state: any) => {
    if (type === "jobItem") {
      return selectJobItemUserUnselectedOptionsJobItem(state, {
        jobItemId: entityId,
      });
    } else if (type === "jobPhase") {
      return selectJobItemUserUnselectedOptionsJobPhase(state, {
        jobPhaseId: entityId,
      });
    } else if (type === "job") {
      return selectJobItemUserUnselectedOptionsJob(state, {
        jobId: entityId,
      });
    }
    return [];
  });
}

function userOptionsFiltered(userOptions: any[], userIds: number[]) {
  const options = sortBy(
    filter(userOptions, (userOption) => !includes(userIds, userOption.key)),
    ["name", "userStatusId"]
  );
  if (!options.find((option) => !option.isHeading)) return [];
  return options;
}
