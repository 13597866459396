import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataQuoteDeclinedBy,
  getMetaDataQuoteNumber,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobQuoteDeclined = ({
  activityEntry,

  canEditJobs,
}) => (
  <SystemMessage>
    {"Quote "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataQuoteNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" has been declined by "}
    {getMetaDataQuoteDeclinedBy(activityEntry)}
  </SystemMessage>
);

JobQuoteDeclined.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobQuoteDeclined;
