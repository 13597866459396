import { produce } from "immer";
import { select, put, delay, call } from "redux-saga/effects";
import { fetchJobNumberCheck } from "../../../lib/API/jobAPI";
import {
  JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED,
  JOB_EDIT,
  JOB_SET_JOB_NUMBER,
  JOB_SET_PREVIOUS_JOB
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { selectJobPreviousJob } from "../../selectors/job/ui/previousJob";

function* setJobNumber(action) {
  try {
    const { jobId, value } = action.payload;

    const job = yield select(selectJob, { jobId });

    const previousJob = yield select(selectJobPreviousJob, {
      jobId
    });

    if (previousJob === null) {
      yield put(
        createAction(JOB_SET_PREVIOUS_JOB, {
          jobId,
          job
        })
      );
    }

    const newJob = produce(job, draft => {
      draft.number = value;
    });

    yield put(
      createAction(JOB_EDIT, {
        jobId,
        job: newJob
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

let checkNumberAbortController = null;

function* checkForDuplicateJobNumber(action) {
  try {
    const { jobId, value } = action.payload;

    yield put(createAction(JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED, false));

    if (checkNumberAbortController) {
      checkNumberAbortController.abort();
      checkNumberAbortController = null;
    }

    if (jobId && value) {
      yield delay(400);

      checkNumberAbortController = new AbortController();

      yield call(fetchJobNumberCheck, jobId, value, checkNumberAbortController);
    }
  } catch (error) {
    const { response } = error;
    if (response) {
      switch (response.status) {
        case 422:
          yield put(
            createAction(JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED, true)
          );
          break;
        case 0:
          break;
        default:
          sagaError(error);
      }
    } else {
      sagaError(error);
    }
  } finally {
    checkNumberAbortController = null;
  }
}

export default function* watchJobSetJobNumber() {
  yield takeLatestBy(
    [JOB_SET_JOB_NUMBER],
    setJobNumber,
    action => action.payload.jobId
  );
  yield takeLatestBy(
    [JOB_SET_JOB_NUMBER],
    checkForDuplicateJobNumber,
    action => action.payload.jobId
  );
}
