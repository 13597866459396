import isError from "lodash-es/isError";
import { call, select, takeLatest } from "redux-saga/effects";
import { sendLineItemMergeCount } from "../../../lib/WebAppAPI/commercialDocuments";
import { INVOICE_LINE_ITEM_SET_IS_MERGING } from "../../../lib/constants";
import { getInvoiceId } from "../../../lib/entities/invoiceLineItemEntity";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectCountIsMergingInvoiceLineItemsByInvoiceId,
  selectInvoiceLineItemById
} from "../../selectors/invoiceLineItemSelectors";

function* sendMergeItemCount(action) {
  try {
    const { id } = action.payload;

    const invoiceLineItem = yield select(selectInvoiceLineItemById, { id });

    const lineItemMergeCount = yield select(
      selectCountIsMergingInvoiceLineItemsByInvoiceId,
      { invoiceId: getInvoiceId(invoiceLineItem) }
    );

    yield call(sendLineItemMergeCount, lineItemMergeCount);
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchSetIsMerging() {
  yield takeLatest(INVOICE_LINE_ITEM_SET_IS_MERGING, sendMergeItemCount);
}
