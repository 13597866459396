import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  COLOR_CHARCOAL,
  COLOR_CYAN,
  COLOR_CYAN_LIGHT,
  COLOR_GRAY,
  COLOR_YELLOW,
  COLOR_YELLOW_DARK,
  JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB,
} from "../../lib/constants";
import { JOB_JUMBOTRON_SECTION_TABS } from "../../lib/constants/jobDetails";
import createAction from "../../redux/helpers/createAction";
import { useJobDetailsJumbotronSectionTab } from "../../redux/selectors/jobDetails/ui/jumbotronSectionTab";
import DashboardIcon from "../elements/Icons/custom/DashboardIcon";
import HomeIcon from "../elements/Icons/custom/HomeIcon";
import SettingsIcon from "../elements/Icons/custom/SettingsIcon";
import JobTabs from "./components/JobTabs";
import { BurnUpModeSelector } from "./Jumbotron/BurnUpModeSelector";
import CostVsSellSelector from "./Jumbotron/CostVsSellSelector";
import JumbotronGraph from "./Jumbotron/graph/JumbotronGraph";
import JumbotronOverview from "./Jumbotron/JumbotronOverview";
import JumbotronSettings from "./Jumbotron/JumbotronSettings";

const JumbotronSection = () => {
  const dispatch = useDispatch();

  const tab = useJobDetailsJumbotronSectionTab();

  const setTab = (newTab) =>
    dispatch(createAction(JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB, newTab));

  return (
    <Container className="uiHelperJobJumbotron">
      <TabHeader>
        <JobTabs
          selected={tab}
          setTab={setTab}
          tabs={[
            {
              name: "Overview",
              value: JOB_JUMBOTRON_SECTION_TABS.OVERVIEW,
              activeColor: COLOR_CYAN_LIGHT,
              borderColor: COLOR_CYAN,
              icon: HomeIcon,
              iconSize: 14,
            },
            {
              name: "Burnup",
              value: JOB_JUMBOTRON_SECTION_TABS.BURN_UP,
              activeColor: COLOR_YELLOW,
              borderColor: COLOR_YELLOW_DARK,
              icon: DashboardIcon,
              iconSize: 18,
            },
            {
              name: "Settings",
              value: JOB_JUMBOTRON_SECTION_TABS.SETTINGS,
              activeColor: COLOR_GRAY,
              borderColor: COLOR_CHARCOAL,
              icon: SettingsIcon,
              iconSize: 16,
            },
          ]}
        />
        <TabHeaderSelectors>
          {tab === JOB_JUMBOTRON_SECTION_TABS.OVERVIEW && (
            <CostVsSellSelector />
          )}
          {tab === JOB_JUMBOTRON_SECTION_TABS.BURN_UP && <BurnUpModeSelector />}
        </TabHeaderSelectors>
      </TabHeader>
      <Display>
        {tab === JOB_JUMBOTRON_SECTION_TABS.OVERVIEW && <JumbotronOverview />}
        {tab === JOB_JUMBOTRON_SECTION_TABS.BURN_UP && <JumbotronGraph />}
        {tab === JOB_JUMBOTRON_SECTION_TABS.SETTINGS && <JumbotronSettings />}
      </Display>
    </Container>
  );
};

export default JumbotronSection;

const Container = styled.div`
  color: black;
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const TabHeader = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 2px solid var(--color-gray);
`;

const TabHeaderSelectors = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-end;
`;

const Display = styled.div`
  background-color: var(--color-snow);
  width: 100%;
  height: 400px;
  padding: 20px;

  .JumbotronStatField {
    min-height: 76px;
  }

  .JumbotronSettingsField {
    min-height: 65px;
  }
`;
