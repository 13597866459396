import * as PropTypes from "prop-types";
import React from "react";
import { asString } from "st-shared/lib";

import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { commercialDocumentEntityType } from "../../../lib/ui/commercialDocument";
import LanguageOption from "./LanguageOption";
import DetailTableField from "./Layout/DetailTableField";
import DetailTableLabel from "./Layout/DetailTableLabel";

class DetailTableTextField extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isDisplayed: PropTypes.bool,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    labelKey: reactNodesType.isRequired,
    doSaveDocument: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDisplayed: true,
  };

  static getDerivedStateFromProps(props, state) {
    const { document, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: asString(getValue(document)),
    };
  }

  state = {
    value: "",
    hasFocus: false,
  };

  save = (value) => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;

    if (value !== getValue(document))
      doSaveDocument(id, setValue(document, value), document);
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleBlur = () => {
    const { value } = this.state;

    this.save(value);

    this.setState({
      hasFocus: false,
    });
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  render() {
    const { isDisplayed, labelKey } = this.props;
    const { value } = this.state;

    return (
      <tr className={isDisplayed ? "" : "hiddenField"}>
        <th>
          <DetailTableLabel>
            <LanguageOption optionKey={labelKey} />
          </DetailTableLabel>
        </th>
        <td>
          <DetailTableField
            value={value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
          />
        </td>
      </tr>
    );
  }
}

export default DetailTableTextField;
