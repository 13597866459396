import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import { NUMBER_FORMAT_ABBREVIATED } from "../../../../lib/constants";
import {
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
  getTotalRemainingMinutes,
  getTotalRemainingMoney,
  getTotalScheduledMinutes,
  getTotalScheduledMoney,
  getTotalUsedMinutes,
  getTotalUsedMoney,
  getTotalUsedScheduledMinutes,
  getTotalUsedScheduledMoney,
  jobItemUserEntityType,
} from "../../../../lib/entities/jobItemUserEntity";
import { entityIdType } from "../../../../lib/types/entityTypes";
import {
  selectJobIdByJobItemUserId,
  selectJobItemUserById,
  selectUserNameByJobItemUserId,
} from "../../../../redux/selectors/jobItemUserSelectors";
import { selectScheduleUiScheduleTotalsMode } from "../../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiTotalsModes } from "../../../../state/ui/schedule/types";
import JobCurrency from "../../../modules/Currency/JobCurrency";
import Tooltip from "../../../modules/Tooltip";
import TooltipContentTable from "../../../modules/Tooltip/TooltipContentTable";
import {
  formatTotalHours,
  getTooltipExchangeRateInfo,
  TotalText,
} from "./index";

const mapState = (state, props) => ({
  userName: selectUserNameByJobItemUserId(state, props),
  jobItemUser: selectJobItemUserById(state, props),
  jobId: selectJobIdByJobItemUserId(state, props),
  scheduleTotalsMode: selectScheduleUiScheduleTotalsMode(state),
});

const JobItemUserTotals = ({
  userName,
  jobItemUser,
  jobId,
  isTimeByPeople,
  scheduleTotalsMode,
}) => {
  const footer = getTooltipExchangeRateInfo(jobId);
  const usedScheduledHours = getTotalUsedScheduledMinutes(jobItemUser);
  const plannedHours = getTotalPlannedMinutes(jobItemUser);
  const scheduledHours = getTotalScheduledMinutes(jobItemUser);
  const usedHours = getTotalUsedMinutes(jobItemUser);
  const remainingHours = getTotalRemainingMinutes(jobItemUser);
  const usedScheduledMoney = getTotalUsedScheduledMoney(jobItemUser);
  const plannedMoney = getTotalPlannedMoney(jobItemUser);
  const scheduledMoney = getTotalScheduledMoney(jobItemUser);
  const usedMoney = getTotalUsedMoney(jobItemUser);
  const remainingMoney = getTotalRemainingMoney(jobItemUser);

  const tooltipContentArray = [
    [
      "Used",
      formatTotalHours(usedHours),
      <JobCurrency id={jobId} value={usedMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={usedMoney} hideHomeCurrency />,
    ],
    [
      "Scheduled",
      formatTotalHours(scheduledHours),
      <JobCurrency id={jobId} value={scheduledMoney} convertToHomeCurrency />,
      <JobCurrency id={jobId} value={scheduledMoney} hideHomeCurrency />,
    ],
    [
      "Used+Scheduled",
      formatTotalHours(usedScheduledHours),
      <JobCurrency
        id={jobId}
        value={usedScheduledMoney}
        convertToHomeCurrency
      />,
      <JobCurrency id={jobId} value={usedScheduledMoney} hideHomeCurrency />,
    ],
  ];

  if (isTimeByPeople) {
    tooltipContentArray.concat([
      [
        "Planned",
        formatTotalHours(plannedHours),
        <JobCurrency id={jobId} value={plannedMoney} convertToHomeCurrency />,
        <JobCurrency id={jobId} value={plannedMoney} hideHomeCurrency />,
      ],
      [
        "Remaining",
        formatTotalHours(remainingHours),
        <JobCurrency id={jobId} value={remainingMoney} convertToHomeCurrency />,
        <JobCurrency id={jobId} value={remainingMoney} hideHomeCurrency />,
      ],
    ]);
  }

  if (scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedHours)
    return (
      <Tooltip
        title={
          <TooltipContentTable
            description={
              <span>
                There are <b>{formatTotalHours(usedScheduledHours)}</b> of
                logged and incomplete To Do’s <br />
                for <b>{userName}</b>{" "}
                {isTimeByPeople && (
                  <span>
                    out of <b>{formatTotalHours(plannedHours)}</b> planned
                  </span>
                )}
              </span>
            }
            contentArray={tooltipContentArray}
            footer={footer}
          />
        }
        placement="right"
      >
        <TotalText over={isTimeByPeople && usedScheduledHours > plannedHours}>
          {formatTotalHours(usedScheduledHours, true)}
          {isTimeByPeople && (
            <>
              {" / "}
              {formatTotalHours(plannedHours, true)}
            </>
          )}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingHours)
    return (
      <Tooltip
        title={
          <TooltipContentTable
            description={
              <span>
                There are <b>{formatTotalHours(scheduledHours)}</b> of
                incomplete To Do’s
                <br />
                for <b>{userName}</b>{" "}
                {isTimeByPeople && (
                  <span>
                    out of <b>{formatTotalHours(remainingHours)}</b> remaining
                  </span>
                )}
              </span>
            }
            contentArray={tooltipContentArray}
            footer={footer}
          />
        }
        placement="right"
      >
        <TotalText
          over={
            isTimeByPeople &&
            (remainingHours < 0 || scheduledHours > remainingHours)
          }
        >
          {formatTotalHours(scheduledHours, true)}
          {isTimeByPeople && (
            <>
              {" / "}
              {formatTotalHours(remainingHours, true)}
            </>
          )}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.UsedScheduledPlannedMoney)
    return (
      <Tooltip
        title={
          <TooltipContentTable
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={usedScheduledMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                of sell value on incomplete and logged To Do’s
                <br />
                for <b>{userName}</b>{" "}
                {isTimeByPeople && (
                  <span>
                    out of{" "}
                    <b>
                      <JobCurrency
                        id={jobId}
                        value={plannedMoney}
                        convertToHomeCurrency
                      />
                    </b>{" "}
                    planned
                  </span>
                )}
              </span>
            }
            contentArray={tooltipContentArray}
            footer={footer}
          />
        }
        placement="right"
      >
        <TotalText over={isTimeByPeople && usedScheduledMoney > plannedMoney}>
          <JobCurrency
            id={jobId}
            value={usedScheduledMoney}
            format={NUMBER_FORMAT_ABBREVIATED}
            convertToHomeCurrency
          />
          {isTimeByPeople && (
            <>
              {" / "}
              <JobCurrency
                id={jobId}
                value={plannedMoney}
                format={NUMBER_FORMAT_ABBREVIATED}
                convertToHomeCurrency
              />
            </>
          )}
        </TotalText>
      </Tooltip>
    );

  if (scheduleTotalsMode === ScheduleUiTotalsModes.ScheduledRemainingMoney)
    return (
      <Tooltip
        title={
          <TooltipContentTable
            description={
              <span>
                There is{" "}
                <b>
                  <JobCurrency
                    id={jobId}
                    value={scheduledMoney}
                    convertToHomeCurrency
                  />
                </b>{" "}
                of sell value on incomplete To Do’s
                <br />
                for <b>{userName}</b>{" "}
                {isTimeByPeople && (
                  <span>
                    out of{" "}
                    <b>
                      <JobCurrency
                        id={jobId}
                        value={remainingMoney}
                        convertToHomeCurrency
                      />
                    </b>{" "}
                    remaining
                  </span>
                )}
              </span>
            }
            contentArray={tooltipContentArray}
            footer={footer}
          />
        }
        placement="right"
      >
        <TotalText
          over={
            isTimeByPeople &&
            (remainingMoney < 0 || scheduledMoney > remainingMoney)
          }
        >
          <JobCurrency
            id={jobId}
            value={scheduledMoney}
            format={NUMBER_FORMAT_ABBREVIATED}
            convertToHomeCurrency
          />
          {isTimeByPeople && (
            <>
              {" / "}
              <JobCurrency
                id={jobId}
                value={remainingMoney}
                format={NUMBER_FORMAT_ABBREVIATED}
                convertToHomeCurrency
              />
            </>
          )}
        </TotalText>
      </Tooltip>
    );

  return null;
};

JobItemUserTotals.propTypes = {
  userName: PropTypes.string.isRequired,
  jobItemUser: jobItemUserEntityType.isRequired,
  jobId: entityIdType.isRequired,
  isTimeByPeople: PropTypes.bool.isRequired,
  scheduleTotalsMode: PropTypes.oneOf(values(ScheduleUiTotalsModes)).isRequired,
};

export default connect(mapState)(JobItemUserTotals);
