import { useSelector } from "react-redux";
import { getRateCardId } from "../../../lib/entities/jobEntity";
import { selectJob } from "../job";
import { selectRateCard } from "../rateCard";

export const selectJobCurrency = (state, { jobId }) => {
  const job = selectJob(state, { jobId });
  const rateCard = selectRateCard(state, { rateCardId: getRateCardId(job) });
  return rateCard.currency;
};

export const useJobCurrency = jobId =>
  useSelector(state => selectJobCurrency(state, { jobId }));
