import { AnimatePresence, motion } from "motion/react";
import {
  createContext,
  type PropsWithChildren,
  type ReactNode,
  useState,
} from "react";

import * as styles from "./StatusField.css";
import type { TStatusIconSize } from "./StatusIcon";

export type StatusFieldProps = PropsWithChildren & {
  size?: TStatusIconSize;
  textSlot?: ReactNode;
};

export function StatusField({ size, children, textSlot }: StatusFieldProps) {
  const [hideText, setHideText] = useState(false);

  return (
    <StatusSetHideTextContext.Provider value={setHideText}>
      <div className={styles.container({ size })}>
        {children}
        {textSlot && (
          <AnimatePresence initial={false}>
            {!hideText && (
              <motion.div
                style={{
                  display: "inline-flex",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                initial="hide"
                animate="open"
                exit="hide"
                variants={{
                  open: { opacity: 1, width: "auto", marginLeft: 0 },
                  hide: { opacity: 0, width: 0, marginLeft: -8 },
                }}
                transition={{ duration: 0.25, ease: "easeInOut" }}
              >
                {textSlot}
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
    </StatusSetHideTextContext.Provider>
  );
}

export const StatusSetHideTextContext = createContext<
  ((hideText: boolean) => void) | undefined
>(undefined);
