import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobMilestoneIdsByJobId } from "./jobMilestoneIdsByJobId";
import { selectStateJobMilestones } from "./index";

export const selectJobMilestoneUpcomingDates = createCachedSelector(
  [selectStateJobMilestones, selectJobMilestoneIdsByJobId],
  (jobMilestones, jobMilestoneIds) =>
    jobMilestoneIds.map(id => get(jobMilestones, `upcomingDatesById.${id}`))
)(selectPropsJobId);

export const useJobMilestoneUpcomingDates = jobId =>
  useSelector(state => selectJobMilestoneUpcomingDates(state, { jobId }));
