import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

const Loop = styled(SegmentPart).attrs(
  mapPropsToClassName(["conflict", "down", "up", "selected", "float"])
)`
  position: absolute;
  &.down {
    bottom: 4px;
    height: 13px;
  }
  &.up {
    top: 4px;
    height: 14px;
  }
`;

export const LoopLeft = styled(Loop)`
  left: 0;
  width: 16px;
  border-radius: 4px 0 0 4px;
  border-width: 2px 0 2px 2px;
`;

export const LoopRight = styled(Loop)`
  right: -2px;
  width: 18px;
  border-radius: 0 4px 4px 0;
  border-width: 2px 2px 2px 0;
`;
