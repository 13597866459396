import { isNull, round, startCase } from "lodash-es";
import numeral from "numeral";
import * as PropTypes from "prop-types";
import { formatCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_OPTIONAL_DECIMAL } from "../constants";
import {
  calculateInstalment,
  invoiceEntityType,
} from "../entities/invoiceEntity";
import { loggedExpenseEntityType } from "../entities/loggedExpenseEntity";
import { quoteEntityType } from "../entities/quoteEntity";
import { handleError } from "../errorHandlers";
import { FrameworkException } from "../exceptions/FrameworkException";
import { asDecimal } from "../math";

export const COMMERCIAL_DOCUMENT_TYPES = {
  QUOTE: "QUOTE",
  INVOICE: "INVOICE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
};

export const commercialDocumentEntityType = PropTypes.oneOfType([
  quoteEntityType,
  invoiceEntityType,
  loggedExpenseEntityType,
]);

export const documentTotalsShape = {
  totalExTax: PropTypes.number,
  totalExTaxLineItems: PropTypes.arrayOf(PropTypes.number),
  totalTaxes: PropTypes.objectOf(
    PropTypes.shape({
      taxName: PropTypes.string,
      taxRate: PropTypes.number,
      taxAmount: PropTypes.number,
    })
  ),
};

export const documentTotalsType = PropTypes.shape(documentTotalsShape);

export const getCommercialDocumentTypeName = (commercialDocumentType) => {
  switch (commercialDocumentType) {
    case COMMERCIAL_DOCUMENT_TYPES.QUOTE:
      return "Quote";
    case COMMERCIAL_DOCUMENT_TYPES.INVOICE:
      return "Invoice";
    case COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER:
      return "Purchase Order";
    default:
      handleError(
        new FrameworkException(`Unknown documentType ${commercialDocumentType}`)
      );
      return startCase(commercialDocumentType);
  }
};

export const getJobId = ({ jobId }) => jobId;

export const getContact = ({ contact }) => contact;

export const getCurrency = ({ currency }) => currency;

export const getCurrencyId = ({ currency }) => currency && currency.id;

export const getExchangeRate = ({ exchangeRate }) => exchangeRate;

export const formatCurrencyForCommercialDocuments = (value, currencySymbol) =>
  formatCurrency(value, currencySymbol, NUMBER_FORMAT_OPTIONAL_DECIMAL, true);

export const calculateDiscount = (discountPc, totalExTax) =>
  round(
    numeral(asDecimal(totalExTax))
      .multiply(asDecimal(discountPc))
      .divide(100)
      .value(),
    2
  );

export const calculateDiscountedAmount = (discountPc, amount) =>
  round(
    numeral(asDecimal(amount))
      .subtract(calculateDiscount(discountPc, amount))
      .value(),
    2
  );

export const calculateEffectiveTotal = (discountPc, instalmentPc, amount) => {
  let total = amount;

  if (!isNull(instalmentPc)) total = calculateInstalment(instalmentPc, total);

  if (discountPc > 0) total = calculateDiscountedAmount(discountPc, total);

  return total;
};

export const calculateEffectiveTotalWithDiscount = (discountPc, amount) => {
  let total = amount;
  if (discountPc > 0) total = calculateDiscountedAmount(discountPc, total);
  return total;
};

export const calculateEffectiveTotalWithInstalment = (instalmentPc, amount) => {
  let total = amount;
  if (!isNull(instalmentPc)) total = calculateInstalment(instalmentPc, total);
  return total;
};
