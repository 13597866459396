import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  DATE_FORMAT_DATE_SHORT_MONTH_YEAR,
  JOB_ITEM_SET_END_DATE,
  JOB_ITEM_SET_START_DATE,
} from "../../../lib/constants";
import {
  EVENT_JOB_ITEM_OPEN_END_DATE_PICKER,
  EVENT_JOB_ITEM_OPEN_START_DATE_PICKER,
} from "../../../lib/constants/events";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { formatDate } from "../../../lib/dates";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
} from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { BaseItemExtended } from "../styles";
import EstimatedDatePicker from "./EstimatedDatePicker";

const ItemDates = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);

  const estimatedStartDate = getEstimatedStartDate(jobItem);
  const estimatedEndDate = getEstimatedEndDate(jobItem);

  const dispatch = useDispatch();

  const doJobItemSetStartDate = (date) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_START_DATE, {
        jobItemId,
        date,
      })
    );
  };

  const doJobItemSetEndDate = (date) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_END_DATE, {
        jobItemId,
        date,
      })
    );
  };

  const onStartDateChange = (date) => {
    doJobItemSetStartDate(date);
  };

  const onEndDateChange = (date) => {
    doJobItemSetEndDate(date);
  };

  const getDisabledText = () => {
    if (estimatedStartDate && estimatedEndDate) {
      return `${formatDate(
        estimatedStartDate,
        DATE_FORMAT_DATE_SHORT_MONTH_YEAR
      )}  -  ${formatDate(
        estimatedEndDate,
        DATE_FORMAT_DATE_SHORT_MONTH_YEAR
      )}`;
    }
    if (estimatedStartDate) {
      return `Starts ${formatDate(
        estimatedStartDate,
        DATE_FORMAT_DATE_SHORT_MONTH_YEAR
      )}`;
    }
    if (estimatedEndDate) {
      return `Ends ${formatDate(
        estimatedEndDate,
        DATE_FORMAT_DATE_SHORT_MONTH_YEAR
      )}`;
    }
    return "";
  };

  return (
    <Container>
      {(isJobEditable || estimatedStartDate || estimatedEndDate) && (
        <DatePickerIconWrapper>
          <DatePickerIcon
            size={ICON_SIZE.LARGE}
            htmlColor="var(--color-charcoal)"
          />
        </DatePickerIconWrapper>
      )}
      {!isJobEditable && <DisabledText>{getDisabledText()}</DisabledText>}
      {isJobEditable && (
        <>
          <EstimatedDatePicker
            focusKey={FOCUS_KEYS.ITEM_START_DATE}
            placeholder="Start"
            value={estimatedStartDate}
            onChange={onStartDateChange}
            openListenerEvent={EVENT_JOB_ITEM_OPEN_START_DATE_PICKER}
          />
          <Spacer>-</Spacer>
          <EstimatedDatePicker
            focusKey={FOCUS_KEYS.ITEM_END_DATE}
            placeholder="End"
            value={estimatedEndDate}
            onChange={onEndDateChange}
            openListenerEvent={EVENT_JOB_ITEM_OPEN_END_DATE_PICKER}
            pickerProps={{
              minDate: estimatedStartDate || undefined,
              initialFocusedDate: estimatedStartDate || undefined,
            }}
          />
        </>
      )}
    </Container>
  );
};

export default ItemDates;

const Container = styled(BaseItemExtended)`
  margin-left: 0 !important;
  padding: 0 10px;
  width: 215px;
`;

const DatePickerIconWrapper = styled.div`
  padding: 1px 6px 0 0;
`;

const Spacer = styled.span`
  padding: 0 6px;
`;

const DisabledText = styled.span`
  white-space: pre-wrap;
`;
