import type { StateCreator } from "zustand";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type TStore<S> = ReturnType<typeof createStore<S>>;

export type TStoreSetter<S> = TStore<S>["setState"];

export type TStoreGetter<S> = TStore<S>["getState"];

export type TStoreCreator<S> = StateCreator<S, [["zustand/immer", never]], []>;

export function createStore<S>(initializer: TStoreCreator<S>) {
  return create<S>()(immer(subscribeWithSelector(initializer)));
}
