import { isFunction } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { reactNodesType } from "../../../lib/types/reactTypes";
import DefaultErrorContent from "./DefaultErrorContent";
import DefaultWrapper from "./DefaultWrapper";

export default class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    Wrapper: PropTypes.func,
    ErrorContent: PropTypes.func
  };

  static defaultProps = {
    Wrapper: DefaultWrapper,
    ErrorContent: DefaultErrorContent
  };

  state = {
    hasError: false,
    hidden: false
  };

  get ErrorContent() {
    const { ErrorContent } = this.props;
    const { error } = this.state;

    if (isFunction(ErrorContent)) return ErrorContent(error);

    return ErrorContent;
  }

  handleClose = e => {
    e.stopPropagation();
    this.setState({ hidden: true });
  };

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: { error, info }
    });
  }

  render() {
    const { Wrapper, children } = this.props;
    const { hidden, hasError } = this.state;

    if (hasError) {
      if (Wrapper)
        return (
          <Wrapper hidden={hidden} onClose={this.handleClose}>
            {this.ErrorContent}
          </Wrapper>
        );

      return null;
    }

    return children;
  }
}

export const withErrorBoundary = (Component, Wrapper) => {
  const wrappedComponent = props => {
    return (
      <ErrorBoundary {...{ Wrapper }}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
  wrappedComponent.displayName = `${Component.displayName || Component.name}`;
  return wrappedComponent;
};
