import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Flex from "../../../elements/Flex";
import AddLineButton from "../AddLineMenu/AddLineButton";

class AddDocumentLine extends React.PureComponent {
  static propTypes = {
    orderId: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    displayLineItems: PropTypes.bool.isRequired,
    displayLineItemDescriptions: PropTypes.bool.isRequired,
    displayHeadings: PropTypes.bool.isRequired,
    displayHeadingSubtotals: PropTypes.bool.isRequired
  };

  get className() {
    const {
      className,
      displayLineItems,
      displayLineItemDescriptions,
      displayHeadings,
      displayHeadingSubtotals
    } = this.props;

    if (className === "subtotal") {
      if (!displayHeadings) return "separator";
      if (!displayHeadingSubtotals) return "large";
    }

    if (className === "heading" && !displayHeadings) return "hidden";

    if (
      className === "separator" &&
      displayLineItems &&
      displayLineItemDescriptions
    )
      return "separator";

    return "";
  }

  render() {
    const { orderId } = this.props;

    return (
      <Wrapper className={this.className}>
        <NegativeOffset>
          <AddLineButton orderId={orderId} />
        </NegativeOffset>
        <SeparatorLine />
      </Wrapper>
    );
  }
}

export default AddDocumentLine;

const SeparatorLine = styled.div.attrs({
  className: "SeparatorLine"
})`
  width: 100%;
  height: 1px;
  border: none;
  border-bottom: var(--border-thin-gray);
  display: none;
`;

const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  height: 15px;
  align-items: center;
  &.large,
  &.separator {
    height: 30px;
  }
  &.separator .SeparatorLine {
    display: block;
  }
  &.hidden {
    display: none;
  }
`;

const NegativeOffset = styled.div`
  position: absolute;
  left: -30px;
`;
