import WebAppAPI, {
  SEND_COMMERCIAL_DOCUMENT_EDITING,
  SEND_LINE_ITEM_MERGE_COUNT,
  SEND_REFRESH_INVOICE_HTML,
  SEND_REFRESH_PURCHASE_ORDER_HTML,
  SEND_REFRESH_QUOTE_HTML,
  SEND_SET_HEADINGS_VISIBLE,
} from "./index";

export const sendLineItemMergeCount = (lineItemMergeCount) =>
  WebAppAPI.sendAction(SEND_LINE_ITEM_MERGE_COUNT, lineItemMergeCount);

export const sendCommericialDocumentEditing = (commericialDocumentEditing) =>
  WebAppAPI.sendAction(
    SEND_COMMERCIAL_DOCUMENT_EDITING,
    commericialDocumentEditing
  );

export const sendRefreshQuoteHtml = () =>
  WebAppAPI.sendAction(SEND_REFRESH_QUOTE_HTML);

export const sendRefreshInvoiceHtml = () =>
  WebAppAPI.sendAction(SEND_REFRESH_INVOICE_HTML);

export const sendRefreshPurchaseOrderHtml = () =>
  WebAppAPI.sendAction(SEND_REFRESH_PURCHASE_ORDER_HTML);

export const sendSetHeadingsVisible = () =>
  WebAppAPI.sendAction(SEND_SET_HEADINGS_VISIBLE);
