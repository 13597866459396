import { SCHEDULE_BLOCK_REASSIGN } from "../../../../../lib/constants";
import reassignBlockReducer from "./reassignBlockReducer";

const undoReassignBlockReducer = (state, action) => {
  const { hasChanged, baseScheduleLoggedTime } = action.payload;

  if (!hasChanged) return state;

  const { userId, jobItemUserId, jobItemUserStatusId } = baseScheduleLoggedTime;

  const reverseAction = {
    type: SCHEDULE_BLOCK_REASSIGN,
    payload: {
      ...action.payload,
      userId,
      jobItemUserId,
      jobItemUserStatusId
    }
  };

  return reassignBlockReducer(state, reverseAction);
};

export default undoReassignBlockReducer;
