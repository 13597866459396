import { IconButton, IconSize, StBurnup, Tooltip } from "st-shared/components";
import { ReportingTimeChartType } from "st-shared/entities";

import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingSavedSegmentTimeChartType } from "../../../state/stores/savedSegmentSelectors";
import { timeChartTypeButtonCss } from "./TimeChartTypeSelector.css";

export function TimeChartTypeSelector() {
  const timeChartType = useReportingSavedSegmentTimeChartType();

  const title =
    timeChartType === ReportingTimeChartType.Regular
      ? "Switch to burnup view"
      : "Switch to regular view";

  function toggleType() {
    switch (timeChartType) {
      case ReportingTimeChartType.Regular:
        reportingStore().savedSegment.actions.setTimeChartType(
          ReportingTimeChartType.Burnup
        );
        break;
      case ReportingTimeChartType.Burnup:
        reportingStore().savedSegment.actions.setTimeChartType(
          ReportingTimeChartType.Regular
        );
        break;
    }
  }

  return (
    <Tooltip title={title} placement="top">
      <IconButton
        onClick={toggleType}
        iconProps={{ icon: StBurnup, size: IconSize.Large }}
        className={timeChartTypeButtonCss}
        data-selected={timeChartType === ReportingTimeChartType.Burnup}
      />
    </Tooltip>
  );
}
