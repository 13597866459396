import { get } from "lodash-es";
import { useSelector } from "react-redux";
import {
  EMPTY_ARRAY,
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED
} from "../../../lib/constants";
import {
  getJobItemStatusIconClass,
  isComplete,
  isPlanning,
  isScheduled
} from "../../../lib/entities/jobItemEntity";
import { selectJobItem } from "../jobItem";
import { selectJobItems } from "../jobItemSelectors";
import { selectJobPhase } from "./index";

export const selectJobPhaseStatusClass = (state, props) => {
  const jobPhase = selectJobPhase(state, props);
  const jobItems = selectJobItems(state);

  const jobItemIds = get(
    jobItems.jobItemOrderByJobIdPhaseId,
    `["${jobPhase.jobId}.${jobPhase.id}"]`,
    EMPTY_ARRAY
  );

  let status;
  let planningItems = 0;
  let scheduledItems = 0;
  let completeItems = 0;

  for (let i = 0; i < jobItemIds.length; i += 1) {
    const jobItem = selectJobItem(state, { jobItemId: jobItemIds[i] });
    if (isScheduled(jobItem)) {
      scheduledItems += 1;
    } else if (isPlanning(jobItem)) {
      planningItems += 1;
    } else if (isComplete(jobItem)) {
      completeItems += 1;
    }
  }

  if (scheduledItems > 0) {
    status = JOB_ITEM_STATUS_ID_SCHEDULED;
  } else if (planningItems > 0) {
    status = JOB_ITEM_STATUS_ID_PLANNING;
  } else if (completeItems > 0) {
    status = JOB_ITEM_STATUS_ID_COMPLETE;
  }

  return getJobItemStatusIconClass(status);
};

export const useJobPhaseStatusClass = jobPhaseId =>
  useSelector(state => selectJobPhaseStatusClass(state, { jobPhaseId }));
