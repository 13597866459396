import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { INVOICE_LINE_ITEM_SET_ACCOUNT_CODE } from "../../../lib/constants";
import {
  getAccountCode,
  invoiceLineItemEntityType
} from "../../../lib/entities/invoiceLineItemEntity";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../../lib/ui/accountingPlatform";
import createAction from "../../../redux/helpers/createAction";
import Spacer from "../../elements/Spacer";
import Ellipsis from "../../elements/Typography/Ellipsis";
import AccountCodesSearchMenu from "../../modules/AccountCode/AccountCodesSearchMenu";
import RevenueAccountName from "../../modules/AccountCode/RevenueAccountName";
import RevenueAccountTooltip from "../../modules/AccountCode/RevenueAccountTooltip";

const mapDispatch = dispatch => ({
  doSetDocumentLineItemAccountCode: (id, accountCode) => {
    dispatch(
      createAction(INVOICE_LINE_ITEM_SET_ACCOUNT_CODE, {
        id,
        accountCode
      })
    );
  }
});

class InvoiceLineItemAccountCode extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    documentLineItem: invoiceLineItemEntityType.isRequired,
    isDisabled: PropTypes.bool,
    doSetDocumentLineItemAccountCode: PropTypes.func.isRequired
  };

  static defaultProps = {
    isDisabled: false
  };

  state = {
    anchorEl: null
  };

  editTaxRate = () => {
    this.setState({
      anchorEl: this.ref.current
    });
  };

  closeTaxRateMenu = () => {
    this.setState({ anchorEl: null });
  };

  save = accountCode => {
    const { documentLineItem, doSetDocumentLineItemAccountCode } = this.props;

    doSetDocumentLineItemAccountCode(documentLineItem.id, accountCode);
  };

  handleChange = accountCode => {
    this.save(accountCode);

    this.closeTaxRateMenu();
  };

  render() {
    const { documentLineItem, isDisabled } = this.props;
    const { anchorEl } = this.state;

    if (isDisabled) return null;

    const accountCode = getAccountCode(documentLineItem);

    return (
      <Wrapper>
        <DisplayAccountCode onClick={this.editTaxRate} ref={this.ref}>
          <RevenueAccountTooltip accountCode={accountCode}>
            <AccountName>
              {accountCode ? (
                <RevenueAccountName accountCode={accountCode} />
              ) : (
                "None"
              )}
            </AccountName>
          </RevenueAccountTooltip>
          <Spacer w={2} />
        </DisplayAccountCode>
        {!isDisabled && (
          <AccountCodesSearchMenu
            anchorEl={anchorEl}
            onPickOption={this.handleChange}
            onClose={this.closeTaxRateMenu}
            accountType={ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(null, mapDispatch)(InvoiceLineItemAccountCode);

const Wrapper = styled.div`
  width: 100%;
  height: 26px;
  background-color: var(--color-gray-bright);
  color: var(--color-gray-dark);
`;

const DisplayAccountCode = styled.div`
  height: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccountName = styled(Ellipsis)`
  padding-top: 1px;
  font-size: 11px;
  font-weight: 500;
  line-height: initial;
  width: 80px;
`;
