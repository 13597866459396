import { has } from "lodash-es";
import type { Entity } from "st-shared/entities/Entity";
import type { TEntityList } from "st-shared/types";

import type { EntityClassesType, ModelSet } from "../state/entities/types";
import { EntityClassNameMap } from "../state/entities/types";
import { EMPTY_OBJECT } from "./constants";
import { FrameworkException } from "./exceptions/FrameworkException";

function modelParser(
  entityClassName: EntityClassesType,
  models: Entity[]
): TEntityList {
  const entityName = EntityClassNameMap[entityClassName];

  if (entityName) return { [entityName]: models };

  return EMPTY_OBJECT;
}

export function createModelSetParser(primaryModelClassName: EntityClassesType) {
  return function (data: ModelSet | Entity) {
    const entities: TEntityList = {};

    if ("__primaryModel" in data || "__modelSet" in data) {
      const primaryModel = data.__primaryModel;
      const modelSet = data.__modelSet || [];

      modelSet.forEach(({ className, models }) => {
        Object.assign(entities, modelParser(className, models));
      });

      if (primaryModelClassName) {
        const entityName = EntityClassNameMap[primaryModelClassName];
        const models: Entity[] =
          entityName && has(entities, entityName) ? entities[entityName]! : [];

        if (
          primaryModel &&
          !models.find((model) => model.id === primaryModel.id)
        ) {
          models.unshift(primaryModel);
        }

        Object.assign(entities, modelParser(primaryModelClassName, models));
      }
    } else {
      if (!primaryModelClassName)
        throw new FrameworkException(
          "primary model name required to parse model data"
        );

      Object.assign(
        entities,
        modelParser(primaryModelClassName, [data as Entity])
      );
    }
    return entities;
  };
}
