import { all } from "redux-saga/effects";
import watchJobItemSubItemCreate from "./watchJobItemSubItemCreate";
import watchJobItemSubItemDelete from "./watchJobItemSubItemDelete";
import watchJobItemSubItemSetChecked from "./watchJobItemSubItemSetChecked";
import watchJobItemSubItemSetDescription from "./watchJobItemSubItemSetDescription";

export default function* jobItemSubItemSaga() {
  yield all([
    watchJobItemSubItemSetDescription(),
    watchJobItemSubItemSetChecked(),
    watchJobItemSubItemCreate(),
    watchJobItemSubItemDelete()
  ]);
}
