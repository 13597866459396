import type {
  TEntityClass,
  TEntityClassMap,
  TModelSet,
  TSyncKeyReplacementSet,
} from "@streamtimefe/entities";
import { ModelSet } from "@streamtimefe/entities";
import { runtimeEnv } from "@streamtimefe/environment";
import {
  Api503Error,
  ApiError,
  DeletedEntityError,
  errorHandler,
} from "@streamtimefe/error";
import {
  getLocalStorageAuthToken,
  getSearchParam,
  getTimeZone,
  sharedEmitter,
  SharedEvents,
} from "@streamtimefe/utils";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { isDefined } from "remeda";

import { storeSyncModel, storeSyncModelData, storeSyncModels } from "../sync";

export function createFetchInstance() {
  const fetch = axios.create({
    baseURL: `${runtimeEnv()?.VITE_WEB_API_ROOT ?? ""}/webapi`,
  });

  fetch.interceptors.request.use((config) => {
    const token = getSearchParam("token") ?? getLocalStorageAuthToken();
    config.headers.Authentication = token;

    const timeZone = getTimeZone();
    if (timeZone) {
      config.headers.Timezone = timeZone;
    }

    return config;
  });

  fetch.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
        case 401:
        case 422:
        case 403:
        case 500:
          return Promise.reject(
            new ApiError(error.response.data as string, error.response)
          );
        case 404:
          return Promise.reject(
            new ApiError("404 resource not found", error.response)
          );
        case 425:
        case 503:
          sharedEmitter.emit(SharedEvents.ShowMaintenancePage);
          return Promise.reject(new Api503Error());
        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  });

  return fetch;
}

const baseFetch = createFetchInstance();

const fetchWithAlert = createFetchInstance();

fetchWithAlert.interceptors.response.use(undefined, (error: AxiosError) => {
  errorHandler(error, false);
  return Promise.reject(error);
});

export function fetch(options?: { alert?: boolean }) {
  const alert = options?.alert ?? false;
  if (alert) {
    return fetchWithAlert;
  }
  return baseFetch;
}

function fetchTransform<T, D>(response: AxiosResponse<T, D>) {
  return response.data;
}

fetch.transform = fetchTransform;

function fetchTransformModel<T extends TEntityClass>(
  entityClass: T,
  options?: {
    sync?: false;
    primarySyncKeyReplacement?: string;
    deletedEntityCheck?: (entity: TEntityClassMap[T]) => boolean;
  }
) {
  const sync = options?.sync ?? true;
  const deletedEntityCheck = options?.deletedEntityCheck;
  const primarySyncKeyReplacement = options?.primarySyncKeyReplacement;

  return function (response: AxiosResponse<TEntityClassMap[T]>) {
    if (isDefined(deletedEntityCheck)) {
      if (deletedEntityCheck(response.data)) {
        throw new DeletedEntityError();
      }
    }
    if (sync) {
      let syncKeyReplacementSet: TSyncKeyReplacementSet | undefined;
      if (isDefined(primarySyncKeyReplacement) && isDefined(response.data)) {
        syncKeyReplacementSet = ModelSet.createSyncKeyReplacement(
          entityClass,
          primarySyncKeyReplacement,
          response.data.id
        );
      }

      storeSyncModel(entityClass, response.data, false, syncKeyReplacementSet);
    }

    return response.data;
  };
}

fetchTransform.model = fetchTransformModel;

function fetchTransformModels<T extends TEntityClass>(
  entityClass: T,
  options?: { sync?: false }
) {
  const sync = options?.sync ?? true;

  return function (response: AxiosResponse<TEntityClassMap[T][]>) {
    if (sync) {
      storeSyncModels(entityClass, response.data);
    }

    return response.data;
  };
}

fetchTransform.models = fetchTransformModels;

type TransformModelSetOptions<T extends TEntityClass> = {
  sync?: false;
  deletedEntityCheck?: (entity: TEntityClassMap[T]) => boolean;
  primarySyncKeyReplacement?: string;
};

function baseTransformModelSet<T extends TEntityClass>(
  modelSet: TModelSet<T>,
  entityClass: T,
  options?: TransformModelSetOptions<T>
) {
  const sync = options?.sync ?? true;
  const primarySyncKeyReplacement = options?.primarySyncKeyReplacement;
  const deletedEntityCheck = options?.deletedEntityCheck;

  if (isDefined(deletedEntityCheck) && isDefined(modelSet.__primaryModel)) {
    if (
      deletedEntityCheck(
        modelSet.__primaryModel as TEntityClassMap[typeof entityClass]
      )
    ) {
      throw new DeletedEntityError();
    }
  }

  if (sync) {
    if (
      isDefined(primarySyncKeyReplacement) &&
      isDefined(modelSet.__primaryModel)
    ) {
      modelSet = ModelSet.addSyncKeyReplacement(
        modelSet,
        entityClass,
        primarySyncKeyReplacement,
        modelSet.__primaryModel.id
      );
    }

    storeSyncModelData(modelSet);
  }
}

function fetchTransformModelSet<T extends TEntityClass>(
  entityClass: T,
  options?: TransformModelSetOptions<T>
) {
  return function (response: AxiosResponse<TModelSet<T>>) {
    baseTransformModelSet(response.data, entityClass, options);
    return response.data;
  };
}

fetchTransform.modelSet = fetchTransformModelSet;

function fetchTransformModelSetBootstrap<T extends TEntityClass>(
  entityClass: T,
  options?: TransformModelSetOptions<T>
) {
  return function <D extends { modelSet: TModelSet<T> }>(
    response: AxiosResponse<D>
  ) {
    baseTransformModelSet(response.data.modelSet, entityClass, options);
    return response.data;
  };
}

fetchTransform.modelSetBootstrap = fetchTransformModelSetBootstrap;
