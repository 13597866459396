// deprecated

import type { TBranch } from "@streamtimefe/entities";

import type { SearchOption } from ".";

export function branchObj(entity: TBranch) {
  const obj = {
    entity,
    getSearchString: function () {
      return entity.name;
    },
    getAsOption: function (): SearchOption<TBranch> {
      return {
        entity,
        key: entity.id,
        value: entity.name,
        searchString: obj.getSearchString(),
      };
    },
  };
  return obj;
}
