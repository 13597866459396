import type { DateArg, Interval } from "date-fns";

import type { TDateTimeFormat } from "./DateTimeFormat";
import { DateTimeFormat } from "./DateTimeFormat";
import { datetime } from "./export";

export type DateType = DateArg<Date>;

export function formatDate(
  date: DateType,
  format: TDateTimeFormat = DateTimeFormat.Default,
  options?: datetime.FormatOptions
) {
  return datetime.format(date, format, options);
}

export function isDateEqual(dateLeft: DateType, dateRight: DateType) {
  return datetime.isSameDay(dateLeft, dateRight);
}

export function isDateEqualOrBefore(date: DateType, dateToCompare: DateType) {
  return (
    isDateEqual(date, dateToCompare) || datetime.isBefore(date, dateToCompare)
  );
}

export function isDateEqualOrAfter(date: DateType, dateToCompare: DateType) {
  return (
    isDateEqual(date, dateToCompare) || datetime.isAfter(date, dateToCompare)
  );
}

export function isDateBefore(date: DateType, dateToCompare: DateType) {
  return (
    !isDateEqual(date, dateToCompare) && datetime.isBefore(date, dateToCompare)
  );
}

export function isDateAfter(date: DateType, dateToCompare: DateType) {
  return (
    !isDateEqual(date, dateToCompare) && datetime.isAfter(date, dateToCompare)
  );
}

export function isDateEqualOrWithin(date: DateType, interval: Interval) {
  return (
    isDateEqual(date, interval.start) ||
    isDateEqual(date, interval.end) ||
    datetime.isWithinInterval(date, interval)
  );
}

export function timeOfDayGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "morning";
  } else if (currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
}

export const StreamtimeEpoch = {
  start: datetime.startOfYear(new Date(2016, 1, 1)),
  end: datetime.addYears(datetime.endOfYear(Date.now()), 3),
};

export function getDateNow() {
  return new Date();
}

export function formatMonthFromRange(startDate: DateType, endDate: DateType) {
  const totalDays = datetime.differenceInDays(endDate, startDate);
  const middleDate = datetime.addDays(startDate, totalDays / 2);
  return formatDate(middleDate, DateTimeFormat.ShortMonthYear);
}

export function formatQuarterFromRange(startDate: DateType, endDate: DateType) {
  const totalDays = datetime.differenceInDays(endDate, startDate);
  const middleDate = datetime.addDays(startDate, totalDays / 2);
  return formatDate(middleDate, DateTimeFormat.ShortQuarterYear);
}

export function convertToTimeScaleValue(date: DateType) {
  return new Date(date).valueOf();
}

export function formatDateRange(
  startDate: DateType,
  endDate: DateType,
  format: TDateTimeFormat = DateTimeFormat.Default
) {
  return `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;
}

export function formatRelateTime(date: DateType) {
  const now = getDateNow();
  const diffInMinutes = datetime.differenceInMinutes(date, now);
  const diffInHours = datetime.differenceInHours(date, now);
  const diffInDays = datetime.differenceInCalendarDays(date, now);

  if (diffInMinutes > -1) {
    return "less than a minute ago";
  } else if (diffInMinutes === -1) {
    return `a minute ago`;
  } else if (diffInMinutes < -1 && diffInMinutes > -60) {
    return `${Math.abs(diffInMinutes)} minutes ago`;
  } else if (diffInDays === 0) {
    if (diffInHours === -1) {
      return `an hours ago`;
    } else if (diffInHours < -1 && diffInHours > -24) {
      return `${Math.abs(diffInHours)} hours ago`;
    }
  }

  return datetime.formatRelative(date, now);
}

export function formatRelativeDate(
  date: DateType,
  options?: { hideFuture?: boolean; hidePast?: boolean }
) {
  const now = getDateNow();
  const diffInDays = datetime.differenceInCalendarDays(date, now);
  const diffInWeeks = datetime.differenceInCalendarWeeks(date, now);
  const diffInMonths = datetime.differenceInCalendarMonths(date, now);
  const diffInYears = datetime.differenceInCalendarYears(date, now);

  if (diffInDays === 0) {
    return "today";
  }

  // past
  if (!options?.hidePast) {
    if (diffInDays === -1) {
      return "yesterday";
    } else if (diffInDays < -1 && diffInDays > -7) {
      return `${Math.abs(diffInDays)} days ago`;
    } else if (diffInWeeks === -1) {
      return "last week";
    } else if (diffInWeeks < -1 && diffInWeeks > -5) {
      return `${Math.abs(diffInWeeks)} weeks ago`;
    } else if (diffInMonths === -1) {
      return "last month";
    } else if (diffInMonths < -1 && diffInMonths > -7) {
      return `${Math.abs(diffInMonths)} months ago`;
    } else if (diffInMonths < 0 && diffInYears === 0) {
      return formatDate(date, DateTimeFormat.ShortDayMonth);
    } else if (diffInYears < 0) {
      return formatDate(date, DateTimeFormat.ShortDayMonthYear);
    }
  }

  // future
  if (!options?.hideFuture) {
    if (diffInDays === 1) {
      return "tomorrow";
    } else if (diffInDays > 1 && diffInDays < 7) {
      return `in ${Math.abs(diffInDays)} days`;
    }

    if (diffInDays === 1) {
      return "tomorrow";
    } else if (diffInDays > 1 && diffInDays < 7) {
      return `in ${Math.abs(diffInDays)} days`;
    } else if (diffInWeeks === 1) {
      return "next week";
    } else if (diffInWeeks > 1 && diffInWeeks < 5) {
      return `in ${Math.abs(diffInWeeks)} weeks`;
    } else if (diffInMonths === 1) {
      return "next month";
    } else if (diffInMonths > 1 && diffInMonths < 7) {
      return `${Math.abs(diffInMonths)} months ago`;
    } else if (diffInMonths > 0 && diffInYears === 0) {
      return formatDate(date, DateTimeFormat.ShortDayMonth);
    } else if (diffInYears > 0) {
      return formatDate(date, DateTimeFormat.ShortDayMonthYear);
    }
  }

  return "";
}

export function formatRelativeThreadDate(date: DateType) {
  const now = getDateNow();
  const diffInDays = datetime.differenceInCalendarDays(date, now);
  const diffInWeeks = datetime.differenceInCalendarWeeks(date, now);
  const diffInYears = datetime.differenceInCalendarYears(date, now);

  if (diffInDays === 0) {
    return "today";
  } else if (diffInDays === -1) {
    return "yesterday";
  } else if (diffInWeeks < 1 && diffInWeeks > -2) {
    return diffInYears === 0
      ? formatDate(date, DateTimeFormat.WeekdayDayMonth)
      : formatDate(date, DateTimeFormat.WeekdayDayMonthYear);
  } else {
    return diffInYears === 0
      ? formatDate(date, DateTimeFormat.ShortDayMonth)
      : formatDate(date, DateTimeFormat.ShortDayMonthYear);
  }
}
