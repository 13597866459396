import { every, findIndex, isNumber, reduce, values } from "lodash-es";
import numeral from "numeral";
import * as PropTypes from "prop-types";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { applyInverseExchangeRate, wordsEqual } from "st-shared/lib";

import {
  REGEX_GENERIC_QUANTITY,
  REGEX_NUMBER,
  REGEX_QUANTITY_POSTFIX,
  REGEX_QUANTITY_PREFIX,
  REGEX_TIME_HOURS_MINUTES,
  REGEX_TIME_MINUTES,
} from "../constants";
import { FrameworkException } from "../exceptions/FrameworkException";
import { asDecimal, round } from "../math";
import { convertHoursMinutesToDecimal, convertMinutesToTimeHM } from "../time";
import { entityFieldDecimalType, entityIdType } from "../types/entityTypes";

export const commercialDocumentLineItemShape = {
  id: entityIdType.isRequired,
  active: PropTypes.bool.isRequired,
  isHeading: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  quantity: entityFieldDecimalType,
  unitRate: entityFieldDecimalType,
  totalAmountExTax: entityFieldDecimalType,
  orderId: PropTypes.number.isRequired,
  accountCode: PropTypes.string,
  taxName: PropTypes.string,
  taxRate: entityFieldDecimalType,
  externalTaxRateId: PropTypes.string,
};

export const commercialDocumentLineItemEntityType = PropTypes.shape(
  commercialDocumentLineItemShape
);

export const commercialDocumentLineItemDefaults = {
  active: true,
  isHeading: false,
  name: "",
  description: "",
  quantity: "",
  unitRate: null,
  totalAmountExTax: null,
  accountCode: "",
  taxName: "",
  taxRate: null,
  externalTaxRateId: null,
};

export const getOrderId = ({ orderId }) => orderId;

export const isDeleted = ({ active }) => !active;

export const getIsHeading = ({ isHeading }) => isHeading;

export const getLineItemName = ({ name }) => name;

export const getLineItemDescription = ({ description }) => description || "";

export const getQuantity = ({ quantity }) => quantity || "";

export const getTotalAmountExTax = ({ totalAmountExTax }) => totalAmountExTax;

export const getTaxName = ({ taxName }) => taxName;

export const getTaxRate = ({ taxRate }) => asDecimal(taxRate);

export const getUnitRate = ({ unitRate }) => unitRate;

export const getTaxAmount = ({ totalAmountExTax, taxRate }) =>
  calculateTaxAmount(totalAmountExTax, taxRate);

export const getTotalsTaxKey = (name, rate) =>
  `${name} ${rate}`.replace(/[\s.%]+/g, "_");

export const getDefaultName = (isHeading) =>
  isHeading ? "Enter a heading name..." : "Enter an item name...";

export const parseQuantity = (quantity) => {
  if (isNumber(quantity)) return quantity;

  let result;

  result = String(quantity).match(REGEX_TIME_MINUTES);
  if (result) return convertHoursMinutesToDecimal(0, result[1]);

  result = String(quantity).match(REGEX_TIME_HOURS_MINUTES);
  if (result) return convertHoursMinutesToDecimal(result[1], result[2]);

  result = String(quantity).match(REGEX_GENERIC_QUANTITY);
  if (result) return asDecimal(result[1]);

  return 1;
};

export const calculateUnitRate = (quantity, total) => {
  const parsedQuantity = parseQuantity(quantity);

  if (parsedQuantity === 0) return 0;

  return round(numeral(asDecimal(total)).divide(parsedQuantity).value(), 2);
};

export const calculateTotalFromQuantityByRate = (quantity, unitRate) => {
  const parsedQuantity = parseQuantity(quantity);
  const parsedUnitRate = asDecimal(unitRate);

  return round(numeral(parsedUnitRate).multiply(parsedQuantity).value(), 2);
};

export const calculateMarkupWithExchangeRate = (cost, sell, exchangeRate) => {
  const parsedCost = round(applyInverseExchangeRate(cost, exchangeRate), 2);
  const parsedSell = asDecimal(sell);

  if (parsedCost === 0) return null;

  return round(
    numeral(parsedSell)
      .subtract(parsedCost)
      .divide(parsedCost)
      .multiply(100)
      .value(),
    2
  );
};

export const calculateMarkedUpTotalWithExchangeRate = (
  cost,
  markup,
  exchangeRate
) => {
  const parsedCost = round(applyInverseExchangeRate(cost, exchangeRate), 2);
  const parsedMarkup = asDecimal(markup);

  return round(
    numeral(parsedCost)
      .add(numeral(parsedCost).multiply(parsedMarkup).divide(100).value())
      .value(),
    2
  );
};

export const calculateTaxAmount = (totalExTax, taxRate) =>
  numeral(asDecimal(totalExTax))
    .multiply(asDecimal(taxRate))
    .divide(100)
    .value();

export const calculateTotalIncTax = (totalExTax, taxRate) =>
  round(
    numeral(asDecimal(totalExTax))
      .add(calculateTaxAmount(totalExTax, taxRate))
      .value(),
    2
  );

export const documentLineItemMethods = {
  getName: (lineItem) => getLineItemName(lineItem),
  setName: (lineItem, value) => ({ ...lineItem, name: value }),
  getQuantity: (lineItem) => getQuantity(lineItem),
  setQuantity: (lineItem, value) => {
    const totalAmountExTax =
      getUnitRate(lineItem) == null
        ? lineItem.totalAmountExTax
        : calculateTotalFromQuantityByRate(value, getUnitRate(lineItem));
    return {
      ...lineItem,
      quantity: value,
      totalAmountExTax,
    };
  },
  getUnitRate: (lineItem) => asDecimal(getUnitRate(lineItem)),
  setUnitRate: (lineItem, value) => {
    const unitRate = asDecimal(value) || null;
    const totalAmountExTax =
      unitRate == null
        ? getTotalAmountExTax(lineItem)
        : calculateTotalFromQuantityByRate(getQuantity(lineItem), value);

    return {
      ...lineItem,
      unitRate,
      totalAmountExTax,
    };
  },
  getTotalAmountExTax: (lineItem) => asDecimal(getTotalAmountExTax(lineItem)),
  setTotalAmountExTax: (lineItem, value) => {
    const unitRate =
      getUnitRate(lineItem) == null
        ? null
        : calculateUnitRate(getQuantity(lineItem), value);
    return {
      ...lineItem,
      unitRate,
      totalAmountExTax: asDecimal(value),
    };
  },
};

export const commercialDocumentLineItemOptionType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string.isRequired,
  optionType: PropTypes.oneOf(values(LineItemOptionTypes)).isRequired,
  rate: entityFieldDecimalType,
  searchString: PropTypes.string,
});

export const appendSearchStringToOption = (option, searchString) => ({
  ...option,
  searchString: [option.searchString, searchString].join(" "),
});

export const appendSearchStringToOptions = (options, searchString) =>
  options.map((option) => appendSearchStringToOption(option, searchString));

export const getLineItemAsHTMLSummary = (lineItem) => {
  let description = "<p>";

  if (getIsHeading(lineItem))
    description += `<b>${getLineItemName(lineItem)}</b>`;
  else description += `${getLineItemName(lineItem)}`;

  if (getQuantity(lineItem)) description += `: ${getQuantity(lineItem)}`;

  if (getLineItemDescription(lineItem))
    description += `<br/>${getLineItemDescription(lineItem)}`;

  description += "</p>";
  return description;
};

export const mergeDescriptions = (lineItems) => {
  const parts = [];
  const [parent, ...children] = lineItems;

  parts.push(getLineItemDescription(parent));

  parts.push(
    ...children.map((lineItem) => {
      const name = getLineItemName(lineItem);
      const quantity = getQuantity(lineItem);
      const description = getLineItemDescription(lineItem);
      const lineItemName = quantity ? `${name}: ${quantity}` : name;
      return `<h2>${lineItemName}</h2>${description}`;
    })
  );

  return parts.join("");
};

export const mergeQuantities = (lineItems) => {
  const totalQuantity = reduce(
    lineItems,
    (total, lineItem) => total + parseQuantity(getQuantity(lineItem)),
    0
  );

  if (
    every(
      lineItems,
      (lineItem) =>
        String(getQuantity(lineItem)).match(REGEX_TIME_MINUTES) ||
        String(getQuantity(lineItem)).match(REGEX_TIME_HOURS_MINUTES)
    )
  )
    return convertMinutesToTimeHM(totalQuantity * 60, false, null, false);

  const [parent, ...children] = lineItems;

  const prefixResult = String(getQuantity(parent)).match(REGEX_QUANTITY_PREFIX);
  const prefix = prefixResult && prefixResult[1];

  if (
    prefix &&
    every(children, (lineItem) => {
      const result = String(getQuantity(lineItem)).match(REGEX_QUANTITY_PREFIX);
      return result && result[1] === prefix;
    })
  )
    return `${prefix} ${totalQuantity}`;

  const postfixResult = String(getQuantity(parent)).match(
    REGEX_QUANTITY_POSTFIX
  );
  const postfix = postfixResult && postfixResult[1];

  if (
    postfix &&
    every(children, (lineItem) => {
      const result = String(getQuantity(lineItem)).match(
        REGEX_QUANTITY_POSTFIX
      );
      return result && wordsEqual(result[1], postfix);
    })
  )
    return `${totalQuantity} ${postfix}`;

  if (
    every(lineItems, (lineItem) => {
      const result = String(getQuantity(lineItem)).match(REGEX_NUMBER);
      return result && asDecimal(result[1]) > 0;
    })
  )
    return totalQuantity;

  return getQuantity(parent);
};

export const mergeUnitRates = (parentLineItem, quantity, totalAmountExTax) => {
  const unitRate = asDecimal(getUnitRate(parentLineItem));
  const calculatedUnitRate = calculateUnitRate(quantity, totalAmountExTax);

  if (calculatedUnitRate === unitRate) return unitRate;
  return null;
};

export const mergeTotals = (lineItems) =>
  reduce(
    lineItems,
    (total, lineItem) => total + asDecimal(getTotalAmountExTax(lineItem)),
    0
  );

export const undoMergeLineItems = (
  baseLineItem,
  prevLineItems,
  currentLineItems
) => {
  const newLineItems = [baseLineItem];
  const baseOrderId = Number(getOrderId(baseLineItem));
  let orderCount = baseOrderId + 1;
  let pIndex = 0;
  let cIndex = baseOrderId;

  while (prevLineItems[pIndex] || currentLineItems[cIndex]) {
    const prevLineItem = prevLineItems[pIndex];
    const currentLineItem = currentLineItems[cIndex];

    if (prevLineItem && orderCount >= Number(getOrderId(prevLineItem))) {
      newLineItems.push(prevLineItem);
      pIndex += 1;
    } else if (currentLineItem) {
      newLineItems.push({
        ...currentLineItem,
        orderId: orderCount,
      });
      cIndex += 1;
    }

    orderCount += 1;
  }

  return newLineItems;
};

export const moveLineItemOrder = (
  lineItems,
  sourceOrderId,
  destinationOrderId
) => {
  const startIndex = findIndex(lineItems, { orderId: sourceOrderId });
  const endIndex = findIndex(lineItems, { orderId: destinationOrderId });

  if (startIndex < 0)
    throw new FrameworkException(
      `Could not reorder items - source order id: ${sourceOrderId} doesn't exist`
    );

  if (endIndex < 0)
    throw new FrameworkException(
      `Could not reorder items - destination order id: ${destinationOrderId} doesn't exist`
    );

  const result = Array.from(lineItems);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((lineItem, index) => ({
    ...lineItem,
    orderId: index + 1,
  }));
};

export const getAccountCode = ({ accountCode }) => accountCode;
