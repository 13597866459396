import * as PropTypes from "prop-types";
import { uppercaseFirstLetter } from "st-shared/lib/index";

import {
  activityEntryEntityType,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
  getMetaDataName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobInvoiceViewed = ({ activityEntry, canEditJobs }) => (
  <SystemMessage>
    {`${uppercaseFirstLetter(getMetaDataInvoiceTypeName(activityEntry))} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataInvoiceNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" viewed by "}
    {getMetaDataName(activityEntry)}
  </SystemMessage>
);

JobInvoiceViewed.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoiceViewed;
