import { produce } from "immer";
import { get, setWith, sortBy, unset } from "lodash-es";

import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    const getQuoteLineItem = (id) => nextState.byId[id];

    touchedQuoteIds.forEach((quoteId) => {
      const ids = get(nextState.idsByQuoteId, quoteId);

      if (!ids) {
        unset(draft, `sortedIdsByQuoteId.${quoteId}`);
        return;
      }

      const sortedIds = sortBy(ids, (id) => getOrderId(getQuoteLineItem(id)));

      setWith(draft, `sortedIdsByQuoteId.${quoteId}`, sortedIds, Object);
    });
  });
