import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import {
  applyExchangeRate,
  applyNegative,
  formatCurrency,
} from "st-shared/lib";

import { NUMBER_FORMAT_OPTIONAL_DECIMAL } from "../../../../lib/constants";
import { currencyEntityType } from "../../../../lib/entities/currencyEntity";
import { domNodeType } from "../../../../lib/types/domTypes";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../../lib/types/entityTypes";
import { commercialDocumentLineItemOptionType } from "../../../../lib/ui/commercialDocumentLineItems";
import { selectMasterJobItemGroupedOptionsWithRate } from "../../../../state/entities/masterJobItem/selectors/selectMasterJobItemGroupedOptionsWithRate";
import NonBillableIcon from "../../../elements/Icons/NonBillableIcon";
import { ICON_SIZE } from "../../StyledIcon";
import AddLineSubMenu from "./AddLineSubMenu";
import AddLineSubMenuMenuItem from "./AddLineSubMenuMenuItem";
import ItemRate from "./ItemRate";

const mapState = (state, props) => ({
  options: selectMasterJobItemGroupedOptionsWithRate(state, props),
});

class AddItemSubMenu extends React.PureComponent {
  static propTypes = {
    rateCardId: entityIdType.isRequired,
    currency: currencyEntityType.isRequired,
    exchangeRate: entityFieldDecimalType.isRequired,
    negativeValue: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(commercialDocumentLineItemOptionType).isRequired,
    anchorEl: domNodeType,
    onPick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchorEl: null,
  };

  getNoResultsMessage = (searchQuery) =>
    `Hmm, looks like no items match ‘${searchQuery}’. Perhaps try a different search term.`;

  getFormattedCurrency = (value) => {
    const { currency, exchangeRate, negativeValue } = this.props;

    return formatCurrency(
      applyExchangeRate(
        negativeValue ? applyNegative(value) : value,
        exchangeRate
      ),
      currency.symbol,
      NUMBER_FORMAT_OPTIONAL_DECIMAL,
      true
    );
  };

  render() {
    const { anchorEl, currency, onPick, onMouseEnter, options } = this.props;

    return (
      <AddLineSubMenu
        anchorEl={anchorEl}
        options={options}
        onMouseEnter={onMouseEnter}
        transformOrigin={{
          vertical: -10,
          horizontal: -10,
        }}
        hideBackdrop
        HeaderComponentProps={{
          title: `Items (${currency.id})`,
          placeholder: "Search by name...",
          getNoResultsMessage: this.getNoResultsMessage,
        }}
      >
        {({ key, value, optionType, rate, isBillable }) => (
          <AddLineSubMenuMenuItem
            className={
              optionType === LineItemOptionTypes.OptionsHeading && "heading"
            }
            key={key}
            onClick={() => onPick(key)}
          >
            <span>{value}</span>
            {optionType !== LineItemOptionTypes.OptionsHeading &&
              optionType !== LineItemOptionTypes.PeopleItem &&
              isBillable && (
                <ItemRate>{this.getFormattedCurrency(rate)}</ItemRate>
              )}
            {optionType !== LineItemOptionTypes.OptionsHeading &&
              !isBillable && (
                <NonBillableIcon size={ICON_SIZE.MEDIUM}></NonBillableIcon>
              )}
          </AddLineSubMenuMenuItem>
        )}
      </AddLineSubMenu>
    );
  }
}

export default connect(mapState)(AddItemSubMenu);
