import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateJobPhases } from "./index";

export const selectJobPhaseOrderByJobId = createCachedSelector(
  [selectStateJobPhases, selectPropsJobId],
  (jobPhases, jobId) => {
    return get(jobPhases, `orderByJobId.${jobId}`, EMPTY_ARRAY);
  }
)(selectPropsJobId);

export const useJobPhaseOrderByJobId = jobId =>
  useSelector(state => selectJobPhaseOrderByJobId(state, { jobId }));
