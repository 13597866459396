import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsCostVsSellMode = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.costVsSellMode
);

export const useJobDetailsCostVsSellMode = () =>
  useSelector(selectJobDetailsCostVsSellMode);
