import {
  getCommercialDocumentTypeName,
  getCurrency,
  getExchangeRate,
  getJobId
} from "../../../../../lib/ui/commercialDocument";
import consumeCommercialDocument from "./consumeCommercialDocument";

const mapContext = ({
  branchId,
  document,
  documentLineItemMethods,
  commercialDocumentType,
  actions: {
    doEditDocumentLineItem,
    doSaveDocumentLineItem,
    doDeleteDocumentLineItem,
    doToggleMergeDocumentLineItem,
    doDocumentLineItemCreateTaxRate
  }
}) => ({
  branchId,
  jobId: getJobId(document),
  documentCurrency: getCurrency(document),
  documentExchangeRate: getExchangeRate(document),
  documentTypeName: getCommercialDocumentTypeName(commercialDocumentType),
  documentLineItemMethods,
  doEditDocumentLineItem,
  doSaveDocumentLineItem,
  doDeleteDocumentLineItem,
  doToggleMergeDocumentLineItem,
  doDocumentLineItemCreateTaxRate
});

const DocumentLineItemEditConsumer = consumeCommercialDocument(mapContext);

export default DocumentLineItemEditConsumer;
