import { find, get } from "lodash-es";
import { call, put, select, take } from "redux-saga/effects";

import { createModelSetParser } from "../../../lib/API/modelSets";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
} from "../../../lib/constants";
import { getJobPhaseId } from "../../../lib/entities/jobItemEntity";
import { RECEIVE_APPLY_MODEL_DATA } from "../../../lib/WebAppAPI";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";
import createWebAPIChannel from "./createWebAPIChannel";

function* filterJobItems(entities) {
  if (!entities[ENTITY_NAME_JOB_ITEMS]) return entities;

  const jobItems = [];
  for (const jobItem of entities[ENTITY_NAME_JOB_ITEMS]) {
    const jobPhaseId = getJobPhaseId(jobItem);

    if (!jobPhaseId) jobItems.push(jobItem);
    else if (
      find(get(entities, ENTITY_NAME_JOB_PHASES, []), { id: jobPhaseId })
    )
      jobItems.push(jobItem);
    else {
      const jobPhase = yield select(selectJobPhaseById, {
        id: jobPhaseId,
      });
      if (jobPhase) jobItems.push(jobItem);
    }
  }

  entities[ENTITY_NAME_JOB_ITEMS] = jobItems;

  return entities;
}

export default function* watchWebAPIApplyModelData() {
  const channel = yield call(createWebAPIChannel, RECEIVE_APPLY_MODEL_DATA);

  while (true) {
    try {
      const [data] = yield take(channel);

      let { data: entities } = createModelSetParser()(data);

      // Filter out data with missing dependancies
      entities = yield filterJobItems(entities);

      yield put(createAction(ENTITIES_RECEIVED, entities));
    } catch (error) {
      sagaError(error);
    }
  }
}
