import { useEffect } from "react";

import { sharedEmitter } from "../../lib";

export type FilterListenerType = "open" | "openDate";

export function createFilterEvent(
  uuid: string,
  filterId: string,
  listenerType: FilterListenerType,
  ...args: any
) {
  sharedEmitter.emit(uuid, filterId, listenerType, ...args);
}

export function useFilterListener(
  uuid: string,
  filterId: string,
  listenerType: FilterListenerType,
  callback: (...args: any) => any
) {
  function onListen(id: string, type: FilterListenerType, ...args: any) {
    if (filterId === id && listenerType === type) {
      callback(args);
    }
  }

  useEffect(() => {
    if (uuid) sharedEmitter.addListener(uuid, onListen);
    return () => {
      if (uuid) sharedEmitter.removeListener(uuid, onListen);
    };
  }, []);
}
