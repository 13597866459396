import { type TValueFormatEnum, ValueFormatEnum } from "@streamtimefe/entities";
import clsx from "clsx";
import { isEqual, uniq } from "lodash-es";
import type { ChangeEvent, KeyboardEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import type { PopoverMenuProps } from "st-shared/components";
import {
  Button,
  IconButton,
  IconSize,
  Input,
  Md123,
  MdAttachMoney,
  MdPercent,
  MdSchedule,
  Popover,
  RTooltip,
} from "st-shared/components";
import type {
  ReportingColumn,
  ReportingDisplayRule,
  ReportingFormulaColumn,
  ReportingSavedSegment,
} from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";
import { plate } from "st-shared/external";
import { useUniqueKey } from "st-shared/hooks";

import { getOrderedMap } from "../../lib/orderedMap";
import { ReportingFormula } from "../../lib/ReportingFormula";
import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumnMetadata,
  useReportingSavedSegmentColumns,
} from "../../state/stores/savedSegmentSelectors";
import { DisplayRulesButton } from "./DisplayRulesButton";
import {
  editColumnFooter,
  editColumnTitle,
  footerButtonBase,
  footerButtonPrimary,
} from "./EditDataColumn.css";
import {
  editFormulaContent,
  editFormulaTopSection,
  editorContainerCss,
  editorCss,
  editorHeaderCss,
  formatButtonCss,
  formatButtonSelectedCss,
  formatTooltipCss,
  nameInputCss,
} from "./EditFormulaColumn.css";
import { ColumnCombobox } from "./formula/elements/ColumnCombobox";
import { ColumnElement } from "./formula/elements/ColumnElement";
import { ColumnInputElement } from "./formula/elements/ColumnInputElement";
import { ColumnSelector } from "./formula/elements/ColumnSelector";
import { OperatorToolbarButton } from "./formula/elements/OperatorToolbarButton";
import {
  createColumnPlugin,
  ELEMENT_COLUMN,
  ELEMENT_COLUMN_INPUT,
} from "./formula/plugins/column";
import { createFormulaPlugin } from "./formula/plugins/formula";

type EditProps = {
  column: ReportingFormulaColumn;
  type: "edit";
};

type AddProps = {
  type: "add";
};

const allowedFormats = [
  ValueFormatEnum.Percentage,
  ValueFormatEnum.Number,
  ValueFormatEnum.Minutes,
  ValueFormatEnum.Currency,
];

export type EditFormulaColumnProps = {
  anchorEl?: Element | null;
  onClose: (...args: any) => void;
  anchorOrigin?: PopoverMenuProps["anchorOrigin"];
  transformOrigin?: PopoverMenuProps["transformOrigin"];
  init: EditProps | AddProps;
};

export function EditFormulaColumn({
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  init,
}: EditFormulaColumnProps) {
  const nameInputRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [uniqueKey, generateNewKey] = useUniqueKey();

  useEffect(() => {
    requestAnimationFrame(() => {
      nameInputRef.current?.focus();
    });
  }, [init.type]);

  const columns = useReportingSavedSegmentColumns();
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  const [filteredColumns, columnNames] = useMemo(() => {
    let orderedColumns = getOrderedMap(columns);
    if (init.type === "edit") {
      orderedColumns = orderedColumns.filter(
        (column) => column.id !== init.column.id
      );
    }
    const names = orderedColumns.map((column) => column.name.toLowerCase());

    const circularIds: string[] = [];
    if (circularIds.length > 0) {
      orderedColumns = orderedColumns.filter(
        (column) => !circularIds.includes(column.id)
      );
    }

    return [orderedColumns, names];
  }, [columns]);

  const [columnName, setColumnName] = useState<ReportingFormulaColumn["name"]>(
    () => (init.type === "edit" ? init.column.name : "")
  );

  const [columnFormula, setColumnFormula] = useState<
    ReportingFormulaColumn["formula"]
  >(() => (init.type === "edit" ? init.column.formula : ""));

  const [columnFormat, setColumnFormat] = useState<
    ReportingFormulaColumn["format"] | null
  >(() => (init.type === "edit" ? init.column.format : null));

  const [columnDisplayRules, setColumnDisplayRules] = useState<
    ReportingFormulaColumn["displayRules"]
  >(() => (init.type === "edit" ? init.column.displayRules : undefined));

  const [defaultDisplayRules] = useState(() =>
    CReportingSavedSegment.getFormulaColumnNumericStateCalculators()
  );

  function onSetDisplayRules(displayRules?: ReportingDisplayRule[]) {
    if (displayRules && !isEqual(displayRules, defaultDisplayRules)) {
      setColumnDisplayRules(displayRules);
    } else {
      setColumnDisplayRules(undefined);
    }
  }

  const [
    reportingFormula,
    formulaCompiles,
    formulaColumnsExist,
    formulaAggregateFormat,
  ] = useMemo(() => {
    const formula = ReportingFormula.fromString(columnFormula);

    let aggregateFormat: ReportingFormulaColumn["format"] | null = null;

    const columnFormats = uniq(
      formula
        .getColumnIds()
        .filter((id) => id in columnMetadata)
        .map((id) => columnMetadata[id].format)
        .filter((format) => allowedFormats.includes(format as any))
    );

    if (columnFormats.length === 1) {
      aggregateFormat = columnFormats[0];
    }

    return [
      formula,
      formula.compile(true),
      formula.columnsExist(columns),
      aggregateFormat,
    ];
  }, [columnFormula, columns]);

  const columnFormatComputted: ReportingFormulaColumn["format"] =
    columnFormat || formulaAggregateFormat || ValueFormatEnum.Number;

  const validationError = useMemo<string | undefined>(() => {
    if (columnName.length <= 0) {
      return "Enter a name for this column";
    }
    if (columnNames.includes(columnName.toLowerCase())) {
      return "Name for this column already exists";
    }
    if (columnFormula.length <= 0) {
      return "Can't save an empty formula";
    }
    if (!formulaCompiles) {
      return "Formula is not valid";
    }
    if (!formulaColumnsExist) {
      return "Remove references to deleted columns";
    }
    return undefined;
  }, [
    columnNames,
    columnName,
    columnFormula,
    formulaCompiles,
    formulaColumnsExist,
  ]);

  const saveDisabled = Boolean(validationError);

  function onNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    setColumnName(event.target.value);
  }

  function onFormatChange(format: TValueFormatEnum) {
    setColumnFormat(format);
  }

  function onFormulaChange(formula: ReportingFormula) {
    setColumnFormula(formula.toString());
  }

  function onSave() {
    switch (init.type) {
      case "edit":
        reportingStore().savedSegment.actions.updateFormulaColumn({
          id: init.column.id,
          name: columnName,
          formula: columnFormula,
          format: columnFormatComputted,
          displayRules: columnDisplayRules,
        });
        break;
      case "add":
        reportingStore().savedSegment.actions.addFormulaColumn({
          name: columnName,
          formula: columnFormula,
          format: columnFormatComputted,
          displayRules: columnDisplayRules,
        });
        break;
    }

    onClose();
  }

  function onClear() {
    setColumnFormula("");
    generateNewKey();
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && event.key === "Enter" && !saveDisabled) {
      onSave();
      return;
    }
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <div onKeyDown={onKeyDown}>
        <div className={editColumnTitle} ref={titleRef}>
          {init.type === "edit" ? "Edit" : "Add"} Formula Column
        </div>
        <div className={editFormulaContent}>
          <div className={editFormulaTopSection}>
            <Input
              ref={nameInputRef}
              variant="secondary"
              placeholder={"Name this column..."}
              value={columnName}
              onChange={onNameInputChange}
              className={nameInputCss}
            />
            <IconButton
              className={clsx(
                formatButtonCss,
                columnFormatComputted === ValueFormatEnum.Percentage &&
                  formatButtonSelectedCss
              )}
              iconProps={{ icon: MdPercent, size: IconSize.Large }}
              onClick={() => onFormatChange(ValueFormatEnum.Percentage)}
              data-tooltip-id="format-tooltip"
              data-tooltip-content="Format as percentage"
              tabIndex={-1}
            />
            <IconButton
              className={clsx(
                formatButtonCss,
                columnFormatComputted === ValueFormatEnum.Number &&
                  formatButtonSelectedCss
              )}
              iconProps={{ icon: Md123, size: IconSize.XXLarge }}
              onClick={() => onFormatChange(ValueFormatEnum.Number)}
              data-tooltip-id="format-tooltip"
              data-tooltip-content="Format as numeral"
              tabIndex={-1}
            />
            <IconButton
              className={clsx(
                formatButtonCss,
                columnFormatComputted === ValueFormatEnum.Minutes &&
                  formatButtonSelectedCss
              )}
              iconProps={{ icon: MdSchedule, size: IconSize.Large }}
              onClick={() => onFormatChange(ValueFormatEnum.Minutes)}
              data-tooltip-id="format-tooltip"
              data-tooltip-content="Format as hours and minutes"
              tabIndex={-1}
            />
            <IconButton
              className={clsx(
                formatButtonCss,
                columnFormatComputted === ValueFormatEnum.Currency &&
                  formatButtonSelectedCss
              )}
              iconProps={{ icon: MdAttachMoney, size: IconSize.Large }}
              onClick={() => onFormatChange(ValueFormatEnum.Currency)}
              data-tooltip-id="format-tooltip"
              data-tooltip-content="Format as currency"
              tabIndex={-1}
            />
            <RTooltip
              id="format-tooltip"
              place="top"
              positionStrategy="fixed"
              className={formatTooltipCss}
            />
          </div>
          <FormulaEditor
            key={uniqueKey}
            formula={reportingFormula}
            setForumla={onFormulaChange}
            columns={columns}
            filteredColumns={filteredColumns}
          />
          <DisplayRulesButton
            containerRef={titleRef}
            displayRules={columnDisplayRules}
            defaultDisplayRules={defaultDisplayRules}
            setDisplayRules={onSetDisplayRules}
            columnId={init.type === "edit" ? init.column.id : undefined}
            format={columnFormatComputted}
          />
        </div>
        <div className={editColumnFooter}>
          <Button className={footerButtonBase} onClick={onClose}>
            Cancel
          </Button>
          <div style={{ flex: 1 }}></div>
          <Button className={footerButtonBase} onClick={onClear}>
            Clear
          </Button>
          <Button
            variant="primary"
            className={footerButtonPrimary}
            onClick={onSave}
            disabled={saveDisabled}
            data-tooltip-id="button-disabled-tooltip"
          >
            Save
          </Button>
          <RTooltip
            id="button-disabled-tooltip"
            place="top"
            content={validationError}
            positionStrategy="fixed"
          />
        </div>
      </div>
    </Popover>
  );
}

type FormulaEditorProps = {
  formula: ReportingFormula;
  setForumla: (formula: ReportingFormula) => void;
  columns: ReportingSavedSegment["columns"];
  filteredColumns: ReportingColumn[];
};

const plugins = plate.createPlugins(
  [createFormulaPlugin(), plate.createComboboxPlugin(), createColumnPlugin()],
  {
    components: {
      [ELEMENT_COLUMN]: ColumnElement,
      [ELEMENT_COLUMN_INPUT]: ColumnInputElement,
    },
  }
);

export function FormulaEditor({
  formula,
  setForumla,
  filteredColumns,
}: FormulaEditorProps) {
  const ref = useRef<plate.PlateEditor>(null);

  const [value, setValue] = useState<plate.Value>(formula.toPlate());

  function onChange(value: plate.Value) {
    setValue(value);
    setForumla(ReportingFormula.fromPlate(value));
  }

  return (
    <div className={editorContainerCss}>
      <plate.Plate
        editorRef={ref}
        onChange={onChange}
        initialValue={value}
        plugins={plugins}
      >
        <div className={editorHeaderCss}>
          <ColumnSelector columns={filteredColumns} />
          <OperatorToolbarButton operator="+">+</OperatorToolbarButton>
          <OperatorToolbarButton operator="-">–</OperatorToolbarButton>
          <OperatorToolbarButton operator="*">×</OperatorToolbarButton>
          <OperatorToolbarButton operator="/">÷</OperatorToolbarButton>
          <OperatorToolbarButton operator="(">❨</OperatorToolbarButton>
          <OperatorToolbarButton operator=")">❩</OperatorToolbarButton>
        </div>
        <div style={{ position: "relative" }}>
          <plate.PlateContent
            className={editorCss}
            placeholder="Type @ to insert a column..."
          />
          <ColumnCombobox columns={filteredColumns} />
        </div>
      </plate.Plate>
    </div>
  );
}
