import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemUserTotal } from "../../../redux/selectors/jobItemUser/selectJobItemUserTotal";
import { ItemInputField } from "../components/ItemInputField";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemUserId } from "../context/JobItemUserIdContext";
import { UserLoggedTimeVariance } from "../JobItem/tooltips/UserLoggedTimeVariance";
import {
  itemUserTotalContainerCss,
  itemUserTotalFieldCss,
} from "./ItemUserTotal.css";

export function ItemUserTotal() {
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const jobItemUserId = useJobItemUserId();
  const value = useJobItemUserTotal(jobItemUserId);

  return (
    <div className={itemUserTotalContainerCss}>
      <ItemInputField
        value={value}
        disabled
        containerClassName={itemUserTotalFieldCss}
        fallbackLook={!jobItem.isBillable}
      />
      <UserLoggedTimeVariance />
    </div>
  );
}
