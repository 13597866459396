import { get } from "lodash-es";
import { createSelector } from "reselect";

import { EMPTY_ARRAY, ENTITY_NAME_ROLES } from "../../lib/constants";
import { getSearchString } from "../../lib/entities/roleEntity";
import { selectPropsId } from "./index";

const selectRoles = (state) => state.entities[ENTITY_NAME_ROLES];

export const selectRoleById = createSelector(
  selectRoles,
  selectPropsId,
  (roles, id) => get(roles, `byId.${id}]`)
);

export const selectActiveRoleOptions = createSelector(selectRoles, (roles) =>
  get(roles, "activeOptions", EMPTY_ARRAY)
);

export const selectRoleSearchStringByRoleId = createSelector(
  selectRoleById,
  (role) => (role ? getSearchString(role) : "")
);
