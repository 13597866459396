import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import Button, { PrimaryButton } from "../../elements/Button";

const ConfirmDialog = ({
  children,
  open,
  title,
  confirmText,
  onCancel,
  onConfirm,
  ...props
}) => {
  return (
    <MuiDialog
      open={open}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        square: true
      }}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        <PrimaryButton autoFocus onClick={onConfirm}>
          {confirmText}
        </PrimaryButton>
      </DialogActions>
    </MuiDialog>
  );
};

ConfirmDialog.propTypes = {
  children: reactNodesType.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

ConfirmDialog.defaultProps = {
  onCancel: null
};

export default ConfirmDialog;

const DialogTitle = styled(MuiDialogTitle)`
  text-align: center;
  padding: 31px 0 23px;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 100%;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  padding-bottom: 60px;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 15px 25px;
  background-color: #f2f2f2;
  justify-content: space-between;
`;
