import * as PropTypes from "prop-types";
import styled from "styled-components";

import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import BranchIcon from "../../elements/Icons/custom/BranchIcon";
import UserOption from "./UserOption";

const GroupBranchedUserOption = ({
  isHeading,
  value,
  UserOptionComponent,
  UserOptionProps,
}) => {
  if (isHeading) {
    return (
      <BranchContainer className="BranchHeading">
        <BranchIconContainer>
          <BranchIcon size={20} />
        </BranchIconContainer>
        <BranchName>{value}</BranchName>
        <BranchLine></BranchLine>
      </BranchContainer>
    );
  }
  return <UserOptionComponent {...UserOptionProps} />;
};

GroupBranchedUserOption.propTypes = {
  isHeading: PropTypes.bool,
  value: PropTypes.string,
  UserOptionComponent: PropTypes.oneOfType([PropTypes.func, reactNodesType]),
  UserOptionProps: PropTypes.shape({
    userId: entityIdType,
    onPickUser: PropTypes.func,
    children: reactNodesType,
  }),
};

GroupBranchedUserOption.defaultProps = {
  isHeading: false,
  value: "",
  UserOptionComponent: UserOption,
  UserOptionProps: {},
};

export default GroupBranchedUserOption;

const BranchContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
`;

const BranchIconContainer = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
`;

const BranchName = styled.div`
  margin-left: 10px;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  color: var(--color-charcoal);
`;

const BranchLine = styled.div`
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
`;
