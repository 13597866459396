import { useRef, useState } from "react";
import {
  Icon,
  IconButton,
  IconSize,
  MdClose,
  MdViewColumn,
} from "st-shared/components";
import type { ReportingDataColumn } from "st-shared/entities";
import { theme } from "st-shared/theme";

import { reportingStore } from "../../../state/stores/reportingStore";
import { EditDataColumn } from "../../menus/EditDataColumn";
import {
  columnDisplayContainer,
  dataColumnDeleteContainer,
  dataColumnDeleteIcon,
  dataColumnName,
} from "./DataSetColumn.css";

interface Props {
  column: ReportingDataColumn;
}

export function DataSetColumn({ column }: Props) {
  const columnTitle = column.name;

  const ref = useRef<HTMLDivElement>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLDivElement | null>(null);

  function openEditMenu() {
    setEditAnchorEl(ref.current);
  }

  function closeEditMenu() {
    setEditAnchorEl(null);
  }

  function onDelete(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    reportingStore().savedSegment.actions.deleteColumn(column.id);
  }

  return (
    <>
      <div className={columnDisplayContainer} ref={ref} onClick={openEditMenu}>
        <Icon icon={MdViewColumn} color={theme.color.graydark} />
        <div className={dataColumnName}>{columnTitle}</div>
        <div className={dataColumnDeleteContainer}>
          <IconButton
            className={dataColumnDeleteIcon}
            iconProps={{ icon: MdClose, size: IconSize.Medium }}
            onClick={onDelete}
          />
        </div>
      </div>
      {editAnchorEl && (
        <EditDataColumn
          anchorEl={editAnchorEl}
          onClose={closeEditMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: -6,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          init={{ type: "edit", columnId: column.id }}
        />
      )}
    </>
  );
}
