import type { TEntityId, TJob, TJobGroup } from "@streamtimefe/entities";
import { EntityClass, Job } from "@streamtimefe/entities";
import type { FormatCurrencyOptions } from "@streamtimefe/utils";
import { formatCurrency } from "@streamtimefe/utils";
import { useCallback, useState } from "react";

import { useAuthOrganisation, usePermissions } from "../../../auth";
import { useCurrency } from "../../../currency";
import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import {
  createUsePreviousSingleEntity,
  createUseSingleEntity,
} from "../../SingleEntityStore";
import { useRateCardEntity } from "../RateCard";
import { JobStore } from "./Job.store";

export * from "./selectors";

export const useJobEntity = createUseEntityStoreEntity(EntityClass.Job);

export const getJobEntity = createGetEntityStoreEntity(EntityClass.Job);

export const useJob = createUseSingleEntity(JobStore);

export const usePreviousJob = createUsePreviousSingleEntity(JobStore);

export function useJobStore() {
  return JobStore.useStore();
}

export function useJobActions() {
  return JobStore.useActions();
}

export function useNewJob(duplicateFromJob?: TJob, linkedJobGroup?: TJobGroup) {
  const organisation = useAuthOrganisation();

  return useState(() => {
    const job = Job.create({ organisation, duplicateFromJob, linkedJobGroup });
    return job;
  })[0];
}

export function useJobEntityCurrency(id: TEntityId | null | undefined) {
  const rateCardId = useJobEntity(id, (s) => s.rateCardId);
  const rateCard = useRateCardEntity(rateCardId);
  return rateCard?.currency;
}

export function useJobCurrency() {
  const rateCardId = useJob((s) => s.rateCardId);
  const rateCard = useRateCardEntity(rateCardId);
  return rateCard?.currency;
}

export function useIsJobEntityEditable(id: TEntityId | null | undefined) {
  const { canEditJobs } = usePermissions();
  const isEditable = useJobEntity(id, Job.get.isEditable);

  return canEditJobs && isEditable;
}

export function useIsJobEditable() {
  const { canEditJobs } = usePermissions();
  const isEditable = useJob(Job.get.isEditable);

  return canEditJobs && isEditable;
}

export function useJobEntityCurrencyFormatter(
  id: TEntityId | null | undefined,
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const symbol = useJobEntityCurrency(id)?.symbol;

  return useCallback(
    (value: number) => {
      return formatCurrency(value, {
        ...options,
        symbol,
      });
    },
    [symbol, options]
  );
}

export function useJobCurrencyFormatter(
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const symbol = useJobCurrency()?.symbol;

  return useCallback(
    (value: number) => {
      return formatCurrency(value, {
        ...options,
        symbol,
      });
    },
    [symbol, options]
  );
}

export function useJobForeignCurrencyFormatter(
  foreignCurrencyId: string,
  hideCurrencyCode: boolean = false,
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const foreignCurrency = useCurrency(foreignCurrencyId);
  const jobCurrency = useJobCurrency();
  const symbol = foreignCurrency?.symbol ?? jobCurrency?.symbol;

  let prefix = "";
  if (
    !hideCurrencyCode &&
    jobCurrency &&
    foreignCurrencyId !== jobCurrency.id
  ) {
    prefix = `(${foreignCurrencyId}) `;
  }

  return useCallback(
    (value: number) => {
      return (
        prefix +
        formatCurrency(value, {
          ...options,
          symbol,
        })
      );
    },
    [prefix, options, symbol]
  );
}
