// deprecated

import type { Option } from "./Option";

export const LoggedExpenseStatuses = {
  Draft: 1,
  Approved: 2,
  Paid: 3,
  Declined: 6,
  Deleted: 4,
} as const;

export type LoggedExpenseStatusesType =
  (typeof LoggedExpenseStatuses)[keyof typeof LoggedExpenseStatuses];

export type Type = LoggedExpenseStatus;
export interface LoggedExpenseStatus extends Option {
  id: LoggedExpenseStatusesType;
}

export function isDraft(entity: LoggedExpenseStatus) {
  return entity.id === LoggedExpenseStatuses.Draft;
}

export function isApproved(entity: LoggedExpenseStatus) {
  return entity.id === LoggedExpenseStatuses.Approved;
}

export function isPaid(entity: LoggedExpenseStatus) {
  return entity.id === LoggedExpenseStatuses.Paid;
}

export function isDeclined(entity: LoggedExpenseStatus) {
  return entity.id === LoggedExpenseStatuses.Declined;
}

export function isDeleted(entity: LoggedExpenseStatus) {
  return entity.id === LoggedExpenseStatuses.Deleted;
}
