import type { TValueFormatEnum } from "@streamtimefe/entities";
import { useCallback } from "react";
import { useCustomerCurrency } from "st-shared/stores";
import { formatStatisticValue } from "st-shared/types";

export function useValueFormatter() {
  const customerCurrency = useCustomerCurrency();

  return useCallback(
    function (
      value: string | number,
      format: TValueFormatEnum,
      looseFormat = false
    ) {
      return formatStatisticValue(
        value as number,
        format,
        customerCurrency,
        looseFormat
      );
    },
    [customerCurrency]
  );
}
