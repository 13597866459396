import { images } from "@streamtimefe/assets";
import * as PropTypes from "prop-types";
import styled from "styled-components";

import {
  jobPageCreateExpense,
  jobPageCreatePurchaseOrder,
} from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import { InlineTextButton } from "../../../elements/Button";
import Spacer from "../../../elements/Spacer";
import { useJobId } from "../../context/JobIdContext";

const EmptyJobJumbotronMessage = (canEditJobs) => {
  const jobId = useJobId();
  const job = useJob(jobId);

  return (
    <Wrapper>
      <strong>A blank canvas.</strong>
      <br />
      <strong>Ready for your masterpiece.</strong>
      {canEditJobs && (
        <>
          <Spacer h={10} />
          Create items and phases below, or start with
          <br /> a new{" "}
          <StyledInlineTextButton onClick={() => jobPageCreateExpense(job)}>
            expense
          </StyledInlineTextButton>{" "}
          or{" "}
          <StyledInlineTextButton
            onClick={() => jobPageCreatePurchaseOrder(job)}
          >
            purchase order
          </StyledInlineTextButton>
          .
        </>
      )}
      <Spacer h={22} />
      <CubeIMG />
    </Wrapper>
  );
};

EmptyJobJumbotronMessage.propTypes = {
  canEditJobs: PropTypes.bool.isRequired,
};

export default EmptyJobJumbotronMessage;

const Wrapper = styled.div`
  margin-top: 75px;
  text-align: center;
  color: var(--color-gray-dark);
  font-size: 14px;
  font-weight: 500;

  strong {
    font-size: 16px;
    font-weight: 700;
  }
`;

const CubeIMG = styled.img.attrs({
  alt: "cube",
  src: images.cube,
})`
  width: 80px;
`;

const StyledInlineTextButton = styled(InlineTextButton)`
  margin-top: -1px;
`;
