import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  POINTER_CLICK_OFFSET_LIMIT,
  POINTER_CLICK_TIME_LIMIT_MS,
  SCHEDULE_BLOCK_OPEN_EDIT,
  SCHEDULE_BLOCK_TOGGLE_SELECTED,
  SCHEDULE_BLOCK_UNSELECT_ALL,
} from "../../../lib/constants";
import {
  getPointerClientOffset,
  isDisabledElement,
  subtractCoords,
} from "../../../lib/dom";
import {
  getTargetBlockElement,
  getTargetBlockKey,
} from "../../../lib/eventTargets";
import { entityIdListType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectSelectedBlockKeys } from "../../../redux/selectors/scheduleBlockSelectors";

const mapState = (state) => ({
  selectedBlockKeys: selectSelectedBlockKeys(state),
});

const mapDispatch = (dispatch) => ({
  doOpenScheduleLoggedTimeModal: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_OPEN_EDIT, payload)),

  doToggleSelectedBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_TOGGLE_SELECTED, payload)),

  doUnselectAllBlocks: () =>
    dispatch(createAction(SCHEDULE_BLOCK_UNSELECT_ALL)),
});

export default (WrappedComponent) => {
  class BlockClickHandlers extends React.PureComponent {
    clickItem = null;

    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      selectedBlockKeys: entityIdListType.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      onClick: PropTypes.func,
      onPointerDown: PropTypes.func,
      doOpenScheduleLoggedTimeModal: PropTypes.func.isRequired,
      doToggleSelectedBlock: PropTypes.func.isRequired,
      doUnselectAllBlocks: PropTypes.func.isRequired,
    };

    static defaultProps = {
      onClick: null,
      onPointerDown: null,
    };

    state = { isEditModalOpen: false };

    onPointerDown = (e) => {
      const {
        selectedBlockKeys,
        isSplitting,
        onPointerDown,
        doUnselectAllBlocks,
      } = this.props;

      if (!isSplitting) {
        const blockKey = getTargetBlockKey(e);
        const blockNode = blockKey && getTargetBlockElement(e);
        const isDisabled =
          blockNode &&
          isDisabledElement(blockNode.querySelector(".scheduleBlock"));
        const isSelectedBlock =
          blockKey && selectedBlockKeys.includes(blockKey);
        const isUnselectAction =
          !e.metaKey && selectedBlockKeys.length > 0 && !isSelectedBlock;

        if (isUnselectAction) doUnselectAllBlocks();
        else if (blockNode)
          this.clickItem = {
            blockKey,
            origin: {
              pointerOffset: getPointerClientOffset(e),
              startTime: Date.now(),
            },
            isDisabled,
          };
      }

      return onPointerDown && onPointerDown(e);
    };

    onClick = (e) => {
      const { canEditTeamLoggedTimes, onClick } = this.props;

      if (!this.shouldCancelClick(e)) {
        const { isDisabled } = this.clickItem;

        if (canEditTeamLoggedTimes && !isDisabled && e.metaKey)
          this.toggleSelectBlock(this.clickItem);
        else {
          this.openScheduleLoggedTimeModal(this.clickItem);
        }
      }

      this.clickItem = null;

      return onClick && onClick(e);
    };

    get handlers() {
      return {
        onClick: this.onClick,
        onPointerDown: this.onPointerDown,
      };
    }

    shouldCancelClick(e) {
      const { clickItem } = this;

      if (!clickItem) return true;

      const deltaT = Date.now() - clickItem.origin.startTime;
      if (deltaT >= POINTER_CLICK_TIME_LIMIT_MS) return true;

      const pointerOffset = getPointerClientOffset(e);
      const deltaXY = subtractCoords(
        pointerOffset,
        clickItem.origin.pointerOffset
      );

      // noinspection JSSuspiciousNameCombination
      return (
        Math.abs(deltaXY.x) >= POINTER_CLICK_OFFSET_LIMIT ||
        Math.abs(deltaXY.y) >= POINTER_CLICK_OFFSET_LIMIT
      );
    }

    openScheduleLoggedTimeModal(clickItem) {
      const { doOpenScheduleLoggedTimeModal } = this.props;

      this.setState({ isEditModalOpen: true });

      doOpenScheduleLoggedTimeModal(clickItem);
    }

    toggleSelectBlock(clickItem) {
      const { doToggleSelectedBlock } = this.props;
      doToggleSelectedBlock(clickItem);
    }

    render() {
      const { props, handlers } = this;
      const { isEditModalOpen } = this.state;

      return (
        <WrappedComponent {...{ ...props, ...handlers, isEditModalOpen }} />
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockClickHandlers);
};
