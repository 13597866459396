import * as PropTypes from "prop-types";
import React from "react";
import { PermissionsConsumer } from "st-shared/stores";
import styled from "styled-components";

import {
  addressEntityType,
  getFullAddress,
} from "../../../lib/entities/addressEntity";
import {
  companyEntityType,
  getName,
  getTaxNumber,
} from "../../../lib/entities/companyEntity";
import {
  contactEntityType,
  getFullName,
} from "../../../lib/entities/contactEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { getContact } from "../../../lib/ui/commercialDocument";
import Flex from "../../elements/Flex";
import OpenInNewIcon from "../../elements/Icons/OpenInNewIcon";
import CompanyAddressesSearchMenu from "../Address/CompanyAddressesSearchMenu";
import CompanyContactsSearchMenu from "../Contact/CompanyContactsSearchMenu";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({
  id,
  document,
  jobCompany,
  documentMethods,
  actions: {
    doSetAddress,
    doSetContact,
    doCreateContact,
    doEditContact,
    doCreateAddress,
  },
}) => ({
  id,
  jobCompany,
  contact: getContact(document),
  address: documentMethods.getAddress(document),
  doSetAddress,
  doSetContact,
  doCreateContact,
  doEditContact,
  doCreateAddress,
});

class CompanyDetails extends React.PureComponent {
  contactFieldRef = React.createRef();

  addressFieldRef = React.createRef();

  static propTypes = {
    id: entityIdType.isRequired,
    jobCompany: companyEntityType.isRequired,
    contact: contactEntityType,
    address: addressEntityType,
    doSetAddress: PropTypes.func.isRequired,
    doSetContact: PropTypes.func.isRequired,
    doCreateContact: PropTypes.func.isRequired,
    doEditContact: PropTypes.func.isRequired,
    doCreateAddress: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contact: null,
    address: null,
  };

  state = {
    contactAnchorEl: null,
    addressAnchorEl: null,
  };

  editContact = () => {
    this.setState({ contactAnchorEl: this.contactFieldRef.current });
  };

  editAddress = () => {
    this.setState({ addressAnchorEl: this.addressFieldRef.current });
  };

  closeContactMenu = () => {
    this.setState({ contactAnchorEl: null });
  };

  closeAddressMenu = () => {
    this.setState({ addressAnchorEl: null });
  };

  handlePickContact = (contactId) => {
    const { id, doSetContact } = this.props;

    doSetContact(id, contactId);

    this.closeContactMenu();
  };

  handlePickAddress = (addressId) => {
    const { id, doSetAddress } = this.props;

    doSetAddress(id, addressId);

    this.closeAddressMenu();
  };

  createNewContact = () => {
    const { id, jobCompany, doCreateContact } = this.props;

    doCreateContact(id, jobCompany);

    this.closeContactMenu();
  };

  createNewAddress = () => {
    const { id, jobCompany, doCreateAddress } = this.props;

    doCreateAddress(id, jobCompany);

    this.closeAddressMenu();
  };

  openContact = (e) => {
    e.stopPropagation();

    const { id, contact, doEditContact } = this.props;

    doEditContact(id, contact);
  };

  render() {
    const { jobCompany, contact, address } = this.props;
    const { contactAnchorEl, addressAnchorEl } = this.state;
    const companyId = jobCompany.id;
    const contactName = contact && getFullName(contact);
    const fullAddress = address && getFullAddress(address);
    const taxNumber = getTaxNumber(jobCompany);

    return (
      <Wrapper>
        <CompanyName>{getName(jobCompany)}</CompanyName>
        <ContactWrapper>
          <Field
            ref={this.contactFieldRef}
            onClick={this.editContact}
            className={contactName ? "" : "placeholder"}
          >
            {contactName || "Add a contact"}
          </Field>
          {contactName && (
            <PermissionsConsumer>
              {({ canAccessContacts }) =>
                canAccessContacts && (
                  <OpenContactButton>
                    <OpenInNewIcon onClick={this.openContact} />
                  </OpenContactButton>
                )
              }
            </PermissionsConsumer>
          )}
        </ContactWrapper>
        <AddressField
          ref={this.addressFieldRef}
          onClick={this.editAddress}
          className={fullAddress ? "" : "placeholder"}
        >
          {fullAddress || "Add an address"}
        </AddressField>
        {taxNumber && <CompanyTaxNumber>{taxNumber}</CompanyTaxNumber>}
        <CompanyContactsSearchMenu
          companyId={companyId}
          anchorEl={contactAnchorEl}
          onPickOption={this.handlePickContact}
          onClose={this.closeContactMenu}
          onCreate={this.createNewContact}
          excludeIds={contact && [contact.id]}
        />
        <CompanyAddressesSearchMenu
          companyId={companyId}
          anchorEl={addressAnchorEl}
          onPickOption={this.handlePickAddress}
          onClose={this.closeAddressMenu}
          onCreate={this.createNewAddress}
          excludeIds={address && [address.id]}
        />
      </Wrapper>
    );
  }
}

export default consumeCommercialDocument(mapContext)(CompanyDetails);

const Wrapper = styled.div`
  color: black;
  font-size: 12px;
  line-height: 16px;
  margin-left: 25px;
  min-width: 150px;
`;

const CompanyName = styled.div`
  padding: 5px;
  font-weight: bold;
`;

const CompanyTaxNumber = styled.div`
  padding: 5px;
`;

const ContactWrapper = styled(Flex)``;

const OpenContactButton = styled.div`
  padding: 3px 5px;
  cursor: pointer;
  ${OpenInNewIcon} {
    margin-top: 0px;
    color: var(--color-gray-dark);
  }
  &:hover {
    ${OpenInNewIcon} {
      color: var(--color-charcoal);
    }
  }
`;

const Field = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
  cursor: pointer;
  width: 100%;
  &.placeholder {
    color: var(--color-gray-dark);
  }
  &:hover {
    background: white;
  }
`;

const AddressField = styled(Field)`
  white-space: pre-wrap;
`;
