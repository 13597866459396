import { produce } from "immer";
import { get, setWith } from "lodash-es";

import { getJobCurrencyTotalSellExTax } from "../../../../lib/entities/purchaseOrderLineItemEntity";
import { asDecimal } from "../../../../lib/math";
import {
  getIsHeading,
  getTaxAmount,
  getTaxName,
  getTaxRate,
  getTotalAmountExTax,
  getTotalsTaxKey,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    const getPurchaseOrderLineItem = (id) => nextState.byId[id];

    touchedPurchaseOrderIds.forEach((purchaseOrderId) => {
      let totalExTax = 0;
      let totalJobCurrencySellExTax = 0;
      const totalTaxes = {};

      get(nextState.idsByPurchaseOrderId, purchaseOrderId, [])
        .map(getPurchaseOrderLineItem)
        .forEach((lineItem) => {
          if (getIsHeading(lineItem)) return;

          const taxName = getTaxName(lineItem);
          const taxRate = getTaxRate(lineItem);
          const taxKey = getTotalsTaxKey(taxName, taxRate);
          const taxAmount = getTaxAmount(lineItem);

          totalExTax += asDecimal(getTotalAmountExTax(lineItem));
          totalJobCurrencySellExTax += asDecimal(
            getJobCurrencyTotalSellExTax(lineItem)
          );
          if (taxAmount > 0 || taxAmount < 0) {
            totalTaxes[taxKey] = {
              taxName,
              taxRate,
              taxAmount: get(totalTaxes, `${taxKey}.taxAmount`, 0) + taxAmount,
            };
          }
        });

      const totals = {
        totalExTax,
        totalJobCurrencySellExTax,
        totalTaxes,
      };

      setWith(
        draft,
        `totalsByPurchaseOrderId.${purchaseOrderId}`,
        totals,
        Object
      );
    });
  });
