import { isEqual } from "lodash-es";
import { useMemo } from "react";
import { CReportingSavedSegment } from "st-shared/entities";

import { useReportingSavedSegmentValue } from "../state/stores/savedSegmentSelectors";

export function useCanSaveNewReport() {
  const savedSegment = useReportingSavedSegmentValue();

  const defaultSavedSegment = useMemo(() => {
    return CReportingSavedSegment.createDefault().object;
  }, []);

  const isEqualToDefault = useMemo(() => {
    return isEqual(savedSegment, defaultSavedSegment);
  }, [defaultSavedSegment, savedSegment]);

  return !isEqualToDefault;
}
