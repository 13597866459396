import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectStateUsers } from "./index";

export const selectActiveUserOptionsGroupedByBranch = createSelector(
  selectStateUsers,
  (users) => get(users, `activeOptionsGroupedByBranch`, EMPTY_ARRAY)
);

export const useActiveUserOptionsGroupedByBranch = () =>
  useSelector(selectActiveUserOptionsGroupedByBranch);
