import numeral from "numeral";
import { useSelector } from "react-redux";

import {
  getTotalPlannedMoney as getJobItemTotalPlannedMoney,
  getTotalUsedCostMoney as getJobItemTotalUsedCostMoney,
  getTotalUsedMoney as getJobItemTotalUsedMoney,
} from "../../../../lib/entities/jobItemEntity";
import {
  getTotalPlannedMoney as getJobItemUserTotalPlannedMoney,
  getTotalUsedCostMoney as getJobItemUserTotalUsedCostMoney,
  getTotalUsedMoney as getJobItemUserTotalUsedMoney,
} from "../../../../lib/entities/jobItemUserEntity";
import { selectJob } from "../../job";
import { selectJobItem } from "../../jobItem";
import { selectJobItemIdsByJobId } from "../../jobItem/jobItemIdsByJobId";
import { selectJobItemUser } from "../../jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectUser } from "../../user";
import { selectUnplannedTimeSummary } from "./unplannedTimeSummary";

export const selectJobUsedPlannedTimeDollarsSummary = (state, { jobId }) => {
  const job = selectJob(state, { jobId });
  const unplannedTimeSummary = selectUnplannedTimeSummary(state);
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

  const users = new Map();
  const nonBillable = new Map();

  jobItemIds.forEach((jobItemId) => {
    const jobItem = selectJobItem(state, { jobItemId });

    if (jobItem.isBillable) {
      const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, {
        jobItemId,
      });

      jobItemUserIds.forEach((jobItemUserId) => {
        const jobItemUser = selectJobItemUser(state, { jobItemUserId });

        addData(
          users,
          jobItemUser.userId,
          getJobItemUserTotalPlannedMoney(jobItemUser),
          getJobItemUserTotalUsedMoney(jobItemUser),
          getJobItemUserTotalUsedCostMoney(jobItemUser)
        );
      });
    } else {
      addData(
        nonBillable,
        "nonBillable",
        getJobItemTotalPlannedMoney(jobItem),
        getJobItemTotalUsedMoney(jobItem),
        getJobItemTotalUsedCostMoney(jobItem)
      );
    }
  });

  if (job.isBillable) {
    unplannedTimeSummary.forEach((unplannedTime) => {
      addData(
        users,
        unplannedTime.userId,
        0,
        unplannedTime.jobCurrencyTotalExTax,
        unplannedTime.jobCurrencyTotalCostExTax
      );
    });
  } else {
    unplannedTimeSummary.forEach((unplannedTime) => {
      addData(
        nonBillable,
        "nonBillable",
        0,
        unplannedTime.jobCurrencyTotalExTax,
        unplannedTime.jobCurrencyTotalCostExTax
      );
    });
  }

  const usedPlannedTimeDollarsSummary = Array.from(users, ([userId, data]) => {
    const user = selectUser(state, { userId });
    return {
      ...data,
      userId,
      userDisplayName: user.displayName,
    };
  });

  return [usedPlannedTimeDollarsSummary, nonBillable.get("nonBillable")];
};

export const useJobUsedPlannedTimeDollarsSummary = (jobId) =>
  useSelector((state) =>
    selectJobUsedPlannedTimeDollarsSummary(state, { jobId })
  );

const addData = (
  dataMap,
  id,
  plannedDollars,
  loggedDollars,
  loggedCostDollars
) => {
  if (!(plannedDollars > 0 || loggedDollars > 0)) return;
  if (dataMap.has(id)) {
    const data = dataMap.get(id);
    data.totalPlannedDollars = numeral(data.totalPlannedDollars)
      .add(numeral(plannedDollars).value())
      .value();
    data.totalLoggedDollars = numeral(data.totalLoggedDollars)
      .add(numeral(loggedDollars).value())
      .value();
    data.totalLoggedCostDollars = numeral(data.totalLoggedCostDollars)
      .add(numeral(loggedCostDollars).value())
      .value();
    dataMap.set(id, data);
  } else {
    dataMap.set(id, {
      totalPlannedDollars: plannedDollars,
      totalLoggedDollars: loggedDollars,
      totalLoggedCostDollars: loggedCostDollars,
    });
  }
};
