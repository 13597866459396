import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import {
  selectPropsMasterLoggedExpenseId,
  selectPropsRateCardId,
} from "../../../helpers/selectors";
import { selectStateItemRates } from "./selectStateItemRates";

function keyCombiner(
  _state: AppState,
  props: { masterLoggedExpenseId: number; rateCardId: number }
) {
  return `${props.masterLoggedExpenseId}-${props.rateCardId}`;
}

export const selectMasterLoggedExpenseRate = createCachedSelector(
  [
    selectStateItemRates,
    selectPropsMasterLoggedExpenseId,
    selectPropsRateCardId,
  ],
  (itemRates, masterLoggedExpenseId, rateCardId) =>
    itemRates.masterLoggedExpenseRates[
      `${masterLoggedExpenseId}.${rateCardId}`
    ] || 0
)(keyCombiner);

export function useMasterLoggedExpenseRate(
  masterLoggedExpenseId: number,
  rateCardId: number
) {
  return useSelector((state: AppState) =>
    selectMasterLoggedExpenseRate(state, { masterLoggedExpenseId, rateCardId })
  );
}
