import type {
  TSavedSegment,
  TSavedSegmentAccessTypeEnum,
  TSavedSegmentTypeEnum,
  TUser,
} from "@streamtimefe/entities";
import { SavedSegment, SavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import { produce } from "immer";
import { isEmpty } from "lodash-es";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { CReportingSavedSegment } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { defaultSort, WebAPI } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import {
  addGlobalStore,
  authenticationStore,
  storeActionAddListener,
} from "../..";
import { sagaError } from "../../sagaHelpers";
import type { CrudActionReturn, CrudEntityStoreState } from "../crudStore";
import { EntityCrudState } from "../crudStore";
import {
  byIdReducer,
  parseEntityPayload,
  parseRemovedEntities,
} from "../helpers";
import type {
  BaseEntityStoreState,
  IndexesEntityStoreState,
} from "../storeTypes";
import type { ChangedEntity } from "../types";

type IndexEntityIdsByType = Dictionary<TSavedSegmentTypeEnum, TEntityId[]>;

type SavedSegmentEntityStoreState = BaseEntityStoreState<TSavedSegment> &
  CrudEntityStoreState<TSavedSegment> &
  IndexesEntityStoreState<TSavedSegment> & {
    indexes: {
      entityIdsByType: IndexEntityIdsByType;
    };
    helpers: {
      getEntityIdsByType: (
        savedSegmentType: TSavedSegmentTypeEnum
      ) => TEntityId[];
      getSavedSegmentAccessType: (
        entityId: TEntityId
      ) => TSavedSegmentAccessTypeEnum | null;
      validateEntites: (entities: TSavedSegment[]) => TSavedSegment[];
    };
    actions: {
      load: (savedSegmentTypes?: TSavedSegmentTypeEnum[]) => Promise<void>;
    };
  };

export const useSavedSegmentEntityStore =
  create<SavedSegmentEntityStoreState>()(
    immer(function (set, get) {
      return {
        entitiesById: {},
        editedEntitiesById: {},
        entitesCrudState: {},
        indexes: {
          entityIdsByType: {},
        },

        helpers: {
          getEntity(entityId: TEntityId) {
            return (
              get().editedEntitiesById[entityId] || get().entitiesById[entityId]
            );
          },

          getEntities() {
            return {
              ...get().entitiesById,
              ...get().editedEntitiesById,
            };
          },

          getEntityIdsByType(
            savedSegmentType: TSavedSegmentTypeEnum
          ): TEntityId[] {
            return get().indexes.entityIdsByType[savedSegmentType] || [];
          },

          getSavedSegmentAccessType(entityId: TEntityId) {
            const loggedInUser =
              authenticationStore().helpers.getLoggedInUser();
            const savedSegment = get().helpers.getEntity(entityId);

            if (!savedSegment) return null;

            return SavedSegment.getUserAccessType(
              savedSegment,
              loggedInUser as TUser
            );
          },

          validateEntites(entities: TSavedSegment[]) {
            return entities.map((entity) => {
              switch (entity.savedSegmentType.id) {
                case SavedSegmentTypeEnum.Reporting:
                  return produce(entity, (draft) => {
                    draft.value = CReportingSavedSegment.parseAndValidate(
                      draft.value
                    );
                  });
              }
              return entity;
            });
          },
        },

        listeners: {
          receiveEntities({ entityData }: { entityData: TEntityList }) {
            if (isEmpty(entityData[EntityListKey.SavedSegment])) return;

            const loggedInUser =
              authenticationStore().helpers.getLoggedInUser();

            const shouldRemove = (savedSegment: TSavedSegment) => {
              const savedSegmentAccessType = SavedSegment.getUserAccessType(
                savedSegment,
                loggedInUser as TUser
              );
              return savedSegmentAccessType === null;
            };

            const changedEntities = parseEntityPayload(
              get().entitiesById,
              get().helpers.validateEntites(
                entityData[EntityListKey.SavedSegment] || []
              ),
              shouldRemove
            );

            get().actions.reduceChangedEntities(changedEntities);
          },

          removeEntities({
            entityName,
            ids,
          }: {
            entityName: TEntityListKey;
            ids: TEntityId[];
          }) {
            if (isEmpty(ids) || entityName !== EntityListKey.SavedSegment)
              return;

            const changedEntities = parseRemovedEntities(
              get().entitiesById,
              ids
            );

            get().actions.reduceChangedEntities(changedEntities);
          },
        },

        actions: {
          reduceChangedEntities(
            changedEntities: ChangedEntity<TSavedSegment>[]
          ) {
            if (isEmpty(changedEntities)) return;

            const entitiesById = byIdReducer(
              get().entitiesById,
              changedEntities
            );

            set((s) => {
              s.entitiesById = entitiesById;
            });

            get().actions.updateIndexes(changedEntities);
          },

          purge() {
            set((s) => {
              s.entitiesById = {};
              s.editedEntitiesById = {};
              s.entitesCrudState = {};
              s.indexes.entityIdsByType = {};
            });
          },

          updateIndexes() {
            const entities = Object.values(
              get().helpers.getEntities()
            ) as TSavedSegment[];

            const orderedEntitites = entities.sort((a, b) =>
              defaultSort(a.name, b.name)
            );

            const entitiesByType: IndexEntityIdsByType = {};

            orderedEntitites.forEach((entity) => {
              const type = entity.savedSegmentType.id;
              if (type in entitiesByType) {
                entitiesByType[type]?.push(entity.id);
              } else {
                entitiesByType[type] = [entity.id];
              }
            });

            set({
              indexes: {
                entityIdsByType: entitiesByType,
              },
            });
          },

          async load(savedSegmentTypes?: TSavedSegmentTypeEnum[]) {
            try {
              const response =
                await WebAPI.SavedSegments.getEntitites(savedSegmentTypes);
              get().listeners.receiveEntities({
                entityData: { savedSegments: response.data },
              });
            } catch (e: unknown) {
              sagaError(e);
            }
          },

          editEntity(entity: TSavedSegment) {
            set((s) => {
              s.editedEntitiesById[entity.id] = entity;
            });
            get().actions.updateIndexes();
          },

          clearEditedEntity(entityId: TEntityId) {
            set((s) => {
              delete s.editedEntitiesById[entityId];
            });
            get().actions.updateIndexes();
          },

          async loadEntity(
            entityId: TEntityId,
            silent: boolean = false
          ): Promise<CrudActionReturn<TSavedSegment>> {
            let returnValue: CrudActionReturn<TSavedSegment> = null;

            if (entityId in get().entitesCrudState) {
              return null;
            }

            try {
              set((s) => {
                s.entitesCrudState[entityId] = EntityCrudState.Loading;
              });

              const response = await WebAPI.SavedSegments.getEntity(entityId);

              get().listeners.receiveEntities({
                entityData: { savedSegments: [response.data] },
              });

              const entity = get().helpers.getEntity(entityId);

              if (entity) {
                returnValue = { entity };
              }
            } catch (error: unknown) {
              if (!silent) {
                sagaError(error);
              }
              returnValue = { error };
            } finally {
              set((s) => {
                delete s.entitesCrudState[entityId];
              });
            }

            return returnValue;
          },

          async deleteEntity(
            entityId: TEntityId,
            silent: boolean = false
          ): Promise<CrudActionReturn<TSavedSegment>> {
            let returnValue: CrudActionReturn<TSavedSegment> = null;

            if (entityId in get().entitesCrudState) {
              return null;
            }

            const entity = get().entitiesById[entityId];

            try {
              set((s) => {
                s.entitesCrudState[entityId] = EntityCrudState.Deleting;
              });

              await WebAPI.SavedSegments.deleteEntity(entityId);

              get().actions.reduceChangedEntities([{ prevEntity: entity }]);

              returnValue = { success: true };
            } catch (error: unknown) {
              if (!silent) {
                sagaError(error);
              }
              returnValue = { error };
            } finally {
              set((s) => {
                delete s.entitesCrudState[entityId];
              });
            }

            return returnValue;
          },

          async saveEntity(
            entityOrEntityId: TEntityId | TSavedSegment,
            silent?: boolean
          ): Promise<CrudActionReturn<TSavedSegment>> {
            let returnValue: CrudActionReturn<TSavedSegment> = null;

            const entity =
              typeof entityOrEntityId === "object"
                ? entityOrEntityId
                : get().helpers.getEntity(entityOrEntityId);

            if (!entity) {
              return { error: `Entity not found: ${entityOrEntityId}` };
            }

            if (entity.id in get().entitesCrudState) {
              return null;
            }

            try {
              set((s) => {
                s.entitesCrudState[entity.id] = EntityCrudState.Saving;
              });

              const response = await WebAPI.SavedSegments.saveEntity(entity);

              get().listeners.receiveEntities({
                entityData: { savedSegments: [response.data] },
              });

              const newEntity = get().helpers.getEntity(entity.id);

              if (newEntity) {
                returnValue = { entity: newEntity };
              }

              get().actions.clearEditedEntity(entity.id);
            } catch (error: unknown) {
              if (!silent) {
                sagaError(error);
              }
              returnValue = { error };
            } finally {
              set((s) => {
                delete s.entitesCrudState[entity.id];
              });
            }

            return returnValue;
          },
        },
      };
    })
  );

export function savedSegmentEntityStore() {
  return useSavedSegmentEntityStore.getState();
}

savedSegmentEntityStore.init = function () {
  addGlobalStore(
    ["entities", EntityListKey.SavedSegment],
    savedSegmentEntityStore
  );
  storeActionAddListener(
    "entitiesReceived",
    savedSegmentEntityStore().listeners.receiveEntities
  );
  storeActionAddListener(
    "entitiesRemoved",
    savedSegmentEntityStore().listeners.removeEntities
  );
};
