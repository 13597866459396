import { Plugin } from "chart.js";

export interface LegendMarginPluginOptions {
  margin?: number;
}

export const legendMarginPlugin: Plugin<
  "line" | "bar",
  LegendMarginPluginOptions
> = {
  id: "legendMargin",
  beforeInit(chart, _args, options) {
    if (chart?.legend && (chart.legend as any).fit) {
      const originalFit = (chart.legend as any).fit;
      (chart.legend as any).fit = function fit() {
        originalFit.bind(chart.legend)();

        if (this.options.display) {
          this.height += options.margin ?? 0;
        }
      };
    }
  },
};
