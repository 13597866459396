import styled from "styled-components";
import Flex from "../../elements/Flex";

const SearchMenuItem = styled(Flex).attrs({
  className: "rainbowHover"
})`
  width: 100%;
  padding: 6px 12px;
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  user-select: none;
  align-items: center;
  cursor: pointer;
`;

export default SearchMenuItem;
