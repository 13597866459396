import { images } from "@streamtimefe/assets";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { feToWebOpenIntercomChat } from "../../../../lib/WebAppAPI/fePages/genericWeb";
import Button from "../../../elements/Button";
import * as styles from "./JobActivityIntroduction.css";

export function JobActivityIntroduction() {
  const { canAccessCollaboration } = usePermissions();

  return (
    <div className={styles.container}>
      <span>All you need to know about this job.</span>
      <br />
      <img className={styles.unicorn} alt="" src={images.unicorn} />
      <br />
      {canAccessCollaboration && (
        <span>
          See a running log of actions for the job. Keep briefs, meeting notes,
          or anything else by adding a post, or comment and tag a team member to
          get them across it.
        </span>
      )}
      {!canAccessCollaboration && (
        <div className={styles.column}>
          <span>
            See a running log of actions for the job. You can also upgrade to
            keep briefs, meeting notes, or anything else by adding a post. Or
            simply comment and tag a team member to get them across it. Sounds
            interesting?
          </span>
          <div>
            <StyledButton onClick={feToWebOpenIntercomChat}>
              Get in touch
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  );
}

const StyledButton = styled(Button)`
  margin-top: 15px;
  background-color: var(--color-charcoal);
  color: white;
  font-weight: var(--font-weight-normal);
  &:hover {
    background-color: var(--color-gray-dark);
  }
`;
