import { produce } from "immer";
import { get, isEqual, set, values } from "lodash-es";

import {
  JOB_ITEM_DRAG,
  JOB_ITEM_DRAG_CANCEL,
  JOB_ITEM_RESIZE,
  JOB_ITEM_RESIZE_CANCEL,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVE_ERROR,
} from "../../../lib/constants";
import {
  calculateDependancyLagDays,
  getChildJobItemId,
  getDependancyTypeId,
  getIsFloat,
  getLagDays,
  getParentJobItemId,
} from "../../../lib/entities/jobItemDependancyEntity";
import { moveDays } from "../../../lib/entities/jobItemEntity";
import createCrossReducer from "../../helpers/createCrossReducer";
import {
  selectJobItemDependancyById,
  selectJobItemDependancyIdsByChildJobItemId,
  selectJobItemDependancyIdsByParentJobItemId,
} from "../../selectors/jobItemDependancySelectors";

const resolveJobItemDependancies = (state, snapshot, initialJobItemId) =>
  produce(state, (draft) => {
    const resolvedJobItemIds = [];

    const getJobItemSnapshot = (id) => get(snapshot, id);
    const getJobItem = (id) => get(draft, `entities.jobItems.byId.${id}`);
    const setJobItem = (id, jobItem) =>
      set(draft, `entities.jobItems.byId.${id}`, jobItem);

    const resolveJobItemDependancy = (jobItemDependancy, moveChild) => {
      const { parentJobItemId, childJobItemId } = jobItemDependancy;

      if (!moveChild && resolvedJobItemIds.includes(parentJobItemId))
        return false;

      if (moveChild && resolvedJobItemIds.includes(childJobItemId))
        return false;

      if (moveChild)
        setJobItem(childJobItemId, getJobItemSnapshot(childJobItemId));
      else setJobItem(parentJobItemId, getJobItemSnapshot(parentJobItemId));

      const parentJobItem = getJobItem(parentJobItemId);
      const childJobItem = getJobItem(childJobItemId);
      const lagDays = getLagDays(jobItemDependancy);
      const isFloat = getIsFloat(jobItemDependancy);
      const currentLagDays = calculateDependancyLagDays(
        getDependancyTypeId(jobItemDependancy),
        parentJobItem,
        childJobItem
      );
      const daysToMove = lagDays - currentLagDays;

      if (!isFloat || daysToMove > 0) {
        if (moveChild)
          setJobItem(childJobItemId, moveDays(childJobItem, daysToMove));
        else setJobItem(parentJobItemId, moveDays(parentJobItem, -daysToMove));
      }

      return true;
    };

    const resolveJobItem = (jobItemId) => {
      resolvedJobItemIds.push(jobItemId);

      selectJobItemDependancyIdsByParentJobItemId(state, {
        jobItemId,
      }).forEach((id) => {
        const jobItemDependancy = selectJobItemDependancyById(state, { id });

        const didUpdate = resolveJobItemDependancy(jobItemDependancy, true);

        if (didUpdate) resolveJobItem(getChildJobItemId(jobItemDependancy));
      });

      selectJobItemDependancyIdsByChildJobItemId(state, {
        jobItemId,
      }).forEach((id) => {
        const jobItemDependancy = selectJobItemDependancyById(state, { id });

        const didUpdate = resolveJobItemDependancy(jobItemDependancy, false);

        if (didUpdate) resolveJobItem(getParentJobItemId(jobItemDependancy));
      });
    };

    resolveJobItem(initialJobItemId);
  });

const dragJobItemReducer = (state, action) => {
  const { jobItemId, snapshotId } = action.payload;
  const snapshot = get(state, `snapshots.${snapshotId}`);

  return resolveJobItemDependancies(state, snapshot, jobItemId);
};

const resizeJobItemReducer = (state, action) => {
  const { jobItemId, snapshotId } = action.payload;
  const snapshot = get(state, `snapshots.${snapshotId}`);

  return resolveJobItemDependancies(state, snapshot, jobItemId);
};

const editJobItemReducer = (state, action) => {
  const { jobItemId, snapshotId } = action.payload;
  const snapshot = get(state, `snapshots.${snapshotId}`);

  return resolveJobItemDependancies(state, snapshot, jobItemId);
};

const undoEditJobItemReducer = (state, action) =>
  produce(state, (draft) => {
    const { snapshotId } = action.payload;
    const snapshot = get(state, `snapshots.${snapshotId}`);

    values(snapshot).forEach((jobItem) => {
      const prevJobItem = get(state, `entities.jobItems.byId.${jobItem.id}`);

      if (!isEqual(prevJobItem, jobItem))
        set(draft, `entities.jobItems.byId.${jobItem.id}`, jobItem);
    });
  });

export default createCrossReducer({
  [JOB_ITEM_DRAG]: dragJobItemReducer,
  [JOB_ITEM_RESIZE]: resizeJobItemReducer,
  [JOB_ITEM_SAVE_EDIT]: editJobItemReducer,
  [JOB_ITEM_DRAG_CANCEL]: undoEditJobItemReducer,
  [JOB_ITEM_RESIZE_CANCEL]: undoEditJobItemReducer,
  [JOB_ITEM_SAVE_ERROR]: undoEditJobItemReducer,
});
