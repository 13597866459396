import { useSelector } from "react-redux";
import {
  INVOICE_STATUS_ID_AWAITING_PAYMENT,
  INVOICE_STATUS_ID_DRAFT,
  INVOICE_STATUS_ID_PAID
} from "../../../lib/constants";
import {
  getInvoiceStatusId,
  getJobCurrencyTotalAmountExTax
} from "../../../lib/entities/invoiceEntity";
import { preciseAdd } from "../../../lib/math";
import { selectInvoiceIdsByJobId } from "./invoiceIdsByJobId";
import { selectInvoice } from "./index";

export const selectInvoiceStats = (state, { jobId }) => {
  const invoiceIds = selectInvoiceIdsByJobId(state, { jobId });

  const counts = {
    paid: 0,
    awaitingPayment: 0,
    issued: 0,
    draft: 0
  };

  let issuedTotal = 0;

  invoiceIds.forEach(invoiceId => {
    const invoice = selectInvoice(state, { invoiceId });

    switch (getInvoiceStatusId(invoice)) {
      case INVOICE_STATUS_ID_DRAFT:
        counts.draft += 1;
        break;
      case INVOICE_STATUS_ID_AWAITING_PAYMENT:
        counts.awaitingPayment += 1;
        counts.issued += 1;
        issuedTotal = preciseAdd(
          issuedTotal,
          getJobCurrencyTotalAmountExTax(invoice)
        );
        break;
      case INVOICE_STATUS_ID_PAID:
        counts.paid += 1;
        counts.issued += 1;
        issuedTotal = preciseAdd(
          issuedTotal,
          getJobCurrencyTotalAmountExTax(invoice)
        );
        break;
    }
  });
  return [counts, issuedTotal];
};

export const useInvoiceStats = jobId =>
  useSelector(state => selectInvoiceStats(state, { jobId }));
