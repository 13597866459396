import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectInvoiceIdsByJobId } from "./invoiceIdsByJobId";

export const selectHasInvoices = createCachedSelector(
  [selectInvoiceIdsByJobId],
  invoiceIds => invoiceIds.length > 0
)(selectPropsJobId);

export const useHasInvoices = jobId =>
  useSelector(state => selectHasInvoices(state, { jobId }));
