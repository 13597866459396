import { createIcon } from "../../icon/Icon";

export const IconReverse = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.2051 5.58974V2L7.07692 7.12821L12.2051 12.2564V7.64103C15.5897 7.64103 18.359 10.4103 18.359 13.7949C18.359 17.1795 15.5897 19.9487 12.2051 19.9487C8.82051 19.9487 6.05128 17.1795 6.05128 13.7949H4C4 18.3077 7.69231 22 12.2051 22C16.7179 22 20.4103 18.3077 20.4103 13.7949C20.4103 9.28205 16.7179 5.58974 12.2051 5.58974Z"
  />,
  "IconReverse"
);
