import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobId } from "../index";
import { selectStateLoggedExpenses } from "./index";

export const selectLoggedExpenseIdsByJobId = createCachedSelector(
  [selectStateLoggedExpenses, selectPropsJobId],
  (loggedExpenses, jobId) =>
    get(loggedExpenses, `idsByJobId.${jobId}`, EMPTY_ARRAY)
)(selectPropsJobId);

export const useLoggedExpenseIdsByJobId = jobId =>
  useSelector(state => selectLoggedExpenseIdsByJobId(state, { jobId }));
