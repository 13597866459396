import API from "../../../lib/api";
import { createModelSetParser } from "../../../lib/modelParser";
import { sendApplyModelData } from "../../../lib/WebAppAPI";
import { EntityClasses, ModelSet } from "../types";

async function assignRoleTime(jobId: number, roleId: number, userId: number) {
  const response = await API.post<ModelSet>(`/jobs/${jobId}/assign_role_time`, {
    roleId,
    userId,
  });
  sendApplyModelData(EntityClasses.Job, response.data);
  return createModelSetParser(EntityClasses.Job)(response.data);
}

async function addUsersAndRoles(
  jobId: number,
  userIds: number[],
  roleIds: number[]
) {
  const response = await API.post<ModelSet>(
    `/jobs/${jobId}/assign_users_and_roles`,
    {
      userIds,
      roleIds,
    }
  );

  sendApplyModelData(EntityClasses.Job, response.data);
  return createModelSetParser(EntityClasses.Job)(response.data);
}

const JobAPI = {
  assignRoleTime,
  addUsersAndRoles,
};

export default JobAPI;
