import { EntityAction } from "../actions";

export const JOB_ITEM_RECALCULATE_HOURS = "jobItem/recalculate/hours";

export type JobItemAction = EntityAction | JobItemRoleRecalculateHoursAction;

export type JobItemRoleRecalculateHoursAction = {
  type: typeof JOB_ITEM_RECALCULATE_HOURS;
  jobItemId: number;
};

export function actionJobItemRoleRecalculateHours(
  jobItemId: number
): JobItemRoleRecalculateHoursAction {
  return { type: JOB_ITEM_RECALCULATE_HOURS, jobItemId };
}
