import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ActivityEntryTypes } from "st-shared/entities/ActivityEntryType";
import { getPermissionsSnapshot } from "st-shared/stores";

import { EMPTY_ARRAY } from "../../lib/constants";
import { simpleCompareDesc } from "../../lib/dates";
import {
  getActivityEntryCreatedDatetime,
  getActivityEntryTypeId,
  hasViewPermission,
  matchesFilter,
} from "../../lib/entities/activityEntryEntity";
import { selectActivityEntries } from "./activityEntrySelectors";
import { selectPropsDate, selectPropsId, selectPropsJobId } from "./index";
import {
  selectJobActivityCommentsFilter,
  selectJobActivityPostsFilter,
  selectJobActivitySystemMessagesFilter,
} from "./userPreferenceSelectors";

export const selectActivityEntryDatesByJobId = createSelector(
  selectActivityEntries,
  selectPropsJobId,
  (activityEntries, jobId) =>
    get(activityEntries, `datesByJobId.${jobId}`, EMPTY_ARRAY)
);

export const useActivityEntryDatesByJobId = (jobId) =>
  useSelector((state) => selectActivityEntryDatesByJobId(state, { jobId }));

export const selectActivityEntryIdsByJobId = createSelector(
  selectActivityEntries,
  selectPropsJobId,
  (activityEntries, jobId) =>
    get(activityEntries, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectIdsByJobIdDate = createSelector(
  selectActivityEntries,
  selectPropsJobId,
  selectPropsDate,
  (activityEntries, jobId, date) =>
    get(activityEntries, `idsByJobIdDate["${jobId}.${date}"]`, EMPTY_ARRAY)
);

export const selectIdsByThreadId = createSelector(
  selectActivityEntries,
  selectPropsId,
  (activityEntries, id) =>
    get(activityEntries, `idsByThreadId.${id}`, EMPTY_ARRAY)
);

export const useIdsByThreadId = (id) =>
  useSelector((state) => selectIdsByThreadId(state, { id }));

export const selectJobActivityUi = (state) => state.ui.jobActivity;

export const selectActivityEntriesFetchedJobIds = createSelector(
  [selectJobActivityUi],
  (jobActivityUi) => jobActivityUi.activityEntriesFetchedJobIds
);

export const useActivityEntriesFetchedJobIds = () =>
  useSelector(selectActivityEntriesFetchedJobIds);

export const selectActivityEntriesByJobIdDate = createSelector(
  selectActivityEntries,
  selectIdsByJobIdDate,
  selectJobActivityCommentsFilter,
  selectJobActivityPostsFilter,
  selectJobActivitySystemMessagesFilter,
  (
    activityEntries,
    activityEntryIds,
    isCommentsFilterActive,
    isPostsFilterActive,
    isSystemMessagesFilterActive
  ) => {
    const permissions = getPermissionsSnapshot();
    return activityEntryIds
      .map((id) => get(activityEntries, `byId.${id}`))
      .filter((activityEntry) => hasViewPermission(activityEntry, permissions))
      .filter((activityEntry) => {
        return matchesFilter(
          activityEntry,
          isCommentsFilterActive,
          isPostsFilterActive,
          isSystemMessagesFilterActive
        );
      })
      .sort(
        (a, b) =>
          simpleCompareDesc(
            getActivityEntryCreatedDatetime(a),
            getActivityEntryCreatedDatetime(b)
          ) || b.id - a.id
      );
  }
);

export const useActivityEntriesByJobIdDate = (jobId, date) =>
  useSelector((state) =>
    selectActivityEntriesByJobIdDate(state, { jobId, date })
  );

export const selectHasCommentsOrPosts = createCachedSelector(
  [selectActivityEntries, selectPropsJobId],
  (activityEntries, jobId) => {
    const activityEntryIds = get(
      activityEntries,
      `idsByJobId.${jobId}`,
      EMPTY_ARRAY
    );

    let hasCommentsOrPosts = false;

    activityEntryIds
      .map((id) => get(activityEntries, `byId.${id}`))
      .forEach((activityEntry) => {
        if (!activityEntry) return;
        const activityEntryTypeId = getActivityEntryTypeId(activityEntry);
        if (
          activityEntryTypeId === ActivityEntryTypes.Comment ||
          activityEntryTypeId === ActivityEntryTypes.Post
        ) {
          hasCommentsOrPosts = true;
        }
      });

    return hasCommentsOrPosts;
  }
)(selectPropsJobId);

export const useHasCommentsOrPosts = (jobId) =>
  useSelector((state) => selectHasCommentsOrPosts(state, { jobId }));

export const selectActivityEntriesByJobId = createCachedSelector(
  [
    selectActivityEntries,
    selectActivityEntryIdsByJobId,
    selectJobActivityCommentsFilter,
    selectJobActivityPostsFilter,
    selectJobActivitySystemMessagesFilter,
  ],
  (
    activityEntries,
    activityEntryIds,
    isCommentsFilterActive,
    isPostsFilterActive,
    isSystemMessagesFilterActive
  ) => {
    const permissions = getPermissionsSnapshot();
    return activityEntryIds
      .map((id) => get(activityEntries, `byId.${id}`))
      .filter((activityEntry) => hasViewPermission(activityEntry, permissions))
      .filter((activityEntry) => {
        return matchesFilter(
          activityEntry,
          isCommentsFilterActive,
          isPostsFilterActive,
          isSystemMessagesFilterActive
        );
      })
      .sort(
        (a, b) =>
          simpleCompareDesc(
            getActivityEntryCreatedDatetime(a),
            getActivityEntryCreatedDatetime(b)
          ) || b.id - a.id
      );
  }
)(selectPropsJobId);

export const useActivityEntriesByJobId = (jobId) =>
  useSelector((state) => selectActivityEntriesByJobId(state, { jobId }));
