import { UserPreference } from "@streamtimefe/entities";
import { get, intersection, union } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CostingMethods } from "st-shared/entities";

import { EntityNames } from "../../state/entities/types";
import {
  UserPreferenceDefaults,
  UserPreferenceKeys,
} from "../../state/entities/userPreferences/types";
import {
  createUserPreferenceProp,
  selectPropsId,
  selectPropsUserPreferenceKey,
} from "./index";
import { selectOrderedActiveUsersString } from "./userSelectors";

const selectUserPreferences = (state) =>
  state.entities[EntityNames.UserPreferences];

export const selectUserPreference = createSelector(
  selectUserPreferences,
  selectPropsUserPreferenceKey,
  (userPreferences, userPreferenceKey) =>
    get(userPreferences, `byKey.${userPreferenceKey}`)
);

export const selectUserPreferenceValue = createSelector(
  selectUserPreference,
  selectPropsUserPreferenceKey,
  (userPreference, userPreferenceKey) =>
    userPreference
      ? userPreference.value
      : UserPreferenceDefaults[userPreferenceKey]
);

export const selectScheduleUsersCollapsedString = (state) =>
  selectUserPreferenceValue(
    state,
    createUserPreferenceProp(UserPreferenceKeys.USER_PREFERENCE_COLLAPSED_USERS)
  );

export const selectScheduleCollapsedUserIds = createSelector(
  selectOrderedActiveUsersString,
  selectScheduleUsersCollapsedString,
  (usersActiveString, usersCollapsedString) => {
    const active = UserPreference.splitListValue(usersActiveString);
    const collapsed = UserPreference.splitListValue(usersCollapsedString);
    return intersection(collapsed, active);
  }
);

export const selectIsScheduleUserCollapsed = createSelector(
  [selectScheduleCollapsedUserIds, selectPropsId],
  (collapsedUserId, id) => collapsedUserId.includes(id)
);

export const selectScheduleUserOrderString = (state) =>
  selectUserPreferenceValue(
    state,
    createUserPreferenceProp(UserPreferenceKeys.SCHEDULE_TEAM_MEMBER_ORDER)
  );

export const selectScheduleUnfilteredUserOrder = createSelector(
  selectOrderedActiveUsersString,
  selectScheduleUserOrderString,
  (usersActiveString, userOrderString) => {
    const activeUsers = UserPreference.splitListValue(usersActiveString);
    const preferredOrder = UserPreference.splitListValue(userOrderString);
    return union(intersection(preferredOrder, activeUsers), activeUsers);
  }
);

export const selectJobActivityCommentsFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER
      )
    )
  );

export const useJobActivityCommentsFilter = () =>
  useSelector(selectJobActivityCommentsFilter);

export const selectJobActivityPostsFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER
      )
    )
  );

export const useJobActivityPostsFilter = () =>
  useSelector(selectJobActivityPostsFilter);

export const selectJobActivitySystemMessagesFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER
      )
    )
  );

export const useJobActivitySystemMessagesFilter = () =>
  useSelector(selectJobActivitySystemMessagesFilter);

export const selectJobDatesItemsFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_ITEMS_FILTER
      )
    )
  );

export const useJobDatesItemsFilter = () =>
  useSelector(selectJobDatesItemsFilter);

export const selectJobDatesMilestonesFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER
      )
    )
  );

export const useJobDatesMilestonesFilter = () =>
  useSelector(selectJobDatesMilestonesFilter);

export const selectJobDatesInvoicesFilter = (state) =>
  Boolean(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_INVOICES_FILTER
      )
    )
  );

export const useJobDatesInvoicesFilter = () =>
  useSelector(selectJobDatesInvoicesFilter);

export const selectNewJobItemCostingMethod = (state) =>
  Number(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD
      )
    )
  ) || CostingMethods.Item;

export const selectUserPreferenceJobPageCostVsSellMode = (state) =>
  Number(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE
      )
    )
  );

export const useUserPreferenceJobPageCostVsSellMode = () =>
  useSelector(selectUserPreferenceJobPageCostVsSellMode);

export const selectUserPreferenceJobPageBurnUpMode = (state) =>
  Number(
    selectUserPreferenceValue(
      state,
      createUserPreferenceProp(
        UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE
      )
    )
  );

export const useUserPreferenceJobPageBurnUpMode = () =>
  useSelector(selectUserPreferenceJobPageBurnUpMode);
