import type { TAuthenticationBootstrapResponse } from "@streamtimefe/entities";
import {
  bugsnagSetSessionOrganisation,
  bugsnagSetSessionUser,
} from "@streamtimefe/error";
import { getModalBridgeClient } from "@streamtimefe/modal-bridge";
import { storeSyncAuthBootstrap } from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { setLocalStorageAuthToken } from "@streamtimefe/utils";
import { webBridgeActions } from "@streamtimefe/web-bridge";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { type PropsWithChildren } from "react";
import { createContext, useContext } from "react";

type TAuthenticationQueryContext = UseQueryResult<
  TAuthenticationBootstrapResponse,
  Error
>;

const AuthenticationQueryContext = createContext<TAuthenticationQueryContext>(
  null as unknown as TAuthenticationQueryContext
);

export function useAuthenticationQuery() {
  const context = useContext(AuthenticationQueryContext);
  if (!context) {
    throw new Error(`Missing AuthenticationQueryContext Provider`);
  }
  return context;
}

export function AuthenticationQueryProvider({ children }: PropsWithChildren) {
  const query = useQuery({
    queryKey: ["authentication"],
    queryFn: async () => {
      let data: TAuthenticationBootstrapResponse;
      if (import.meta.env.PROD) {
        const authBoostrap = await webBridgeActions.getAuthBootstrap();

        if (authBoostrap) {
          data = authBoostrap;
        } else {
          data = await webapi.authentication.bootstrap();
        }
      } else {
        data = await webapi.authentication.bootstrap();
      }

      // modals
      if (import.meta.env.DEV) {
        getModalBridgeClient()?.setAuthBootstrap(data);
      }

      // tokens
      setLocalStorageAuthToken(data.authToken.token);

      // bugsnag
      bugsnagSetSessionUser(data.authToken.user);
      bugsnagSetSessionOrganisation(data.bootstrap.organisation);

      // bootstrap
      storeSyncAuthBootstrap(data);

      return data;
    },
  });

  return (
    <AuthenticationQueryContext.Provider value={query}>
      {children}
    </AuthenticationQueryContext.Provider>
  );
}
