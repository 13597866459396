import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobItemId } from "../index";
import { selectStateJobItemUsers } from "./index";

export const selectJobItemUserIdsByJobItemId = createCachedSelector(
  [selectStateJobItemUsers, selectPropsJobItemId],
  (jobItemUsers, jobItemId) =>
    get(jobItemUsers, `idsByJobItemId.${jobItemId}`, EMPTY_ARRAY)
)(selectPropsJobItemId);

export const useJobItemUserIdsByJobItemId = jobItemId =>
  useSelector(state => selectJobItemUserIdsByJobItemId(state, { jobItemId }));
