import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { JOB_TIMELINE_ITEM_ROW_HEIGHT } from "../../../lib/constants";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import {
  selectScheduleJobUi,
  selectUserIdsByJobIdNoItem
} from "../../../redux/selectors/scheduleSelectors";
import ScheduleExtraHoursUserTodos from "./ScheduleExtraHoursUserTodos";

const mapState = (state, props) => ({
  isExpanded: selectScheduleJobUi(state, { jobId: props.id })
    .isExtraHoursExpanded,
  extraHoursUserIds: selectUserIdsByJobIdNoItem(state, props)
});

const mapDispatch = null;

class ScheduleExtraHours extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isExpanded: PropTypes.bool,
    extraHoursUserIds: entityIdListType.isRequired
  };

  static defaultProps = {
    isExpanded: false
  };

  render() {
    const { id, isExpanded, extraHoursUserIds } = this.props;

    if (!extraHoursUserIds.length) return null;

    return (
      <>
        <ExtraHoursContainer />
        {isExpanded &&
          extraHoursUserIds.map(extraHoursUserId => (
            <ScheduleExtraHoursUserTodos
              key={extraHoursUserId}
              id={id}
              userId={extraHoursUserId}
            />
          ))}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleExtraHours);

const ExtraHoursContainer = styled.div`
  width: 100%;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  &:not(:last-child) {
    border-bottom: var(--border-thin-bright);
  }
`;
