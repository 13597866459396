import { useParams } from "react-router-dom";
import { TEntityId } from "st-shared/entities/Entity";

export function useParamEntityId() {
  const params = useParams();
  if (
    typeof params.entityId === "undefined" ||
    String(params.entityId).length === 0
  ) {
    return null;
  }
  return Number(params.entityId) as TEntityId;
}
