import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { LOGGED_EXPENSE_SET_SUPPLIER } from "../../lib/constants";
import {
  addressEntityType,
  getFullAddress
} from "../../lib/entities/addressEntity";
import {
  companyEntityType,
  getName,
  getTaxNumber
} from "../../lib/entities/companyEntity";
import {
  contactEntityType,
  getFullName
} from "../../lib/entities/contactEntity";
import { getCompany } from "../../lib/entities/loggedExpenseEntity";
import { entityIdType } from "../../lib/types/entityTypes";
import { getContact } from "../../lib/ui/commercialDocument";
import createAction from "../../redux/helpers/createAction";
import Flex from "../elements/Flex";
import OpenInNewIcon from "../elements/Icons/OpenInNewIcon";
import CompanyAddressesSearchMenu from "../modules/Address/CompanyAddressesSearchMenu";
import consumeCommercialDocument from "../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import CompanyContactsSearchMenu from "../modules/Contact/CompanyContactsSearchMenu";

const mapContext = ({
  id,
  document,
  jobCompany,
  documentMethods,
  actions: {
    doSetAddress,
    doSetContact,
    doCreateContact,
    doEditContact,
    doCreateAddress
  }
}) => ({
  id,
  company: getCompany(document),
  contact: getContact(document),
  address: documentMethods.getAddress(document),
  doSetAddress,
  doSetContact,
  doCreateContact,
  doEditContact,
  doCreateAddress
});

const mapDispatch = dispatch => ({
  doSetSupplier: id => {
    dispatch(createAction(LOGGED_EXPENSE_SET_SUPPLIER, { id }));
  }
});

class SupplierDetails extends React.PureComponent {
  contactFieldRef = React.createRef();

  addressFieldRef = React.createRef();

  static propTypes = {
    id: entityIdType.isRequired,
    company: companyEntityType,
    contact: contactEntityType,
    address: addressEntityType,
    doSetSupplier: PropTypes.func.isRequired,
    doSetAddress: PropTypes.func.isRequired,
    doSetContact: PropTypes.func.isRequired,
    doCreateContact: PropTypes.func.isRequired,
    doEditContact: PropTypes.func.isRequired,
    doCreateAddress: PropTypes.func.isRequired
  };

  static defaultProps = {
    company: null,
    contact: null,
    address: null
  };

  state = {
    contactAnchorEl: null,
    addressAnchorEl: null
  };

  editSupplier = () => {
    const { id, doSetSupplier } = this.props;

    doSetSupplier(id);
  };

  editContact = () => {
    this.setState({ contactAnchorEl: this.contactFieldRef.current });
  };

  editAddress = () => {
    this.setState({ addressAnchorEl: this.addressFieldRef.current });
  };

  closeContactMenu = () => {
    this.setState({ contactAnchorEl: null });
  };

  closeAddressMenu = () => {
    this.setState({ addressAnchorEl: null });
  };

  handlePickContact = contactId => {
    const { id, doSetContact } = this.props;

    doSetContact(id, contactId);

    this.closeContactMenu();
  };

  handlePickAddress = addressId => {
    const { id, doSetAddress } = this.props;

    doSetAddress(id, addressId);

    this.closeAddressMenu();
  };

  createNewContact = () => {
    const { id, company, doCreateContact } = this.props;

    doCreateContact(id, company);

    this.closeContactMenu();
  };

  createNewAddress = () => {
    const { id, company, doCreateAddress } = this.props;

    doCreateAddress(id, company);

    this.closeAddressMenu();
  };

  openContact = e => {
    e.stopPropagation();

    const { id, contact, doEditContact } = this.props;

    doEditContact(id, contact);
  };

  render() {
    const { company, contact, address } = this.props;
    const { contactAnchorEl, addressAnchorEl } = this.state;
    const companyId = company && company.id;
    const companyName = company && getName(company);
    const contactName = contact && getFullName(contact);
    const fullAddress = address && getFullAddress(address);
    const taxNumber = company && getTaxNumber(company);

    return (
      <Wrapper>
        <SupplierField
          onClick={this.editSupplier}
          className={company ? "" : "placeholder"}
        >
          {companyName || "Add a supplier"}
        </SupplierField>
        {company && (
          <>
            <Field
              ref={this.contactFieldRef}
              onClick={this.editContact}
              className={contactName ? "" : "placeholder"}
            >
              {contactName || "Add a contact"}
              {contactName && <OpenInNewIcon onClick={this.openContact} />}
            </Field>
            <AddressField
              ref={this.addressFieldRef}
              onClick={this.editAddress}
              className={fullAddress ? "" : "placeholder"}
            >
              {fullAddress || "Add an address"}
            </AddressField>
            {taxNumber && <CompanyTaxNumber>{taxNumber}</CompanyTaxNumber>}
            <CompanyContactsSearchMenu
              companyId={companyId}
              anchorEl={contactAnchorEl}
              onPickOption={this.handlePickContact}
              onClose={this.closeContactMenu}
              onCreate={this.createNewContact}
              excludeIds={contact && [contact.id]}
            />
            <CompanyAddressesSearchMenu
              companyId={companyId}
              anchorEl={addressAnchorEl}
              onPickOption={this.handlePickAddress}
              onClose={this.closeAddressMenu}
              onCreate={this.createNewAddress}
              excludeIds={address && [address.id]}
            />
          </>
        )}
      </Wrapper>
    );
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(null, mapDispatch)(SupplierDetails)
);

const Wrapper = styled.div`
  color: black;
  font-size: 12px;
  line-height: 16px;
  margin-left: 25px;
  min-width: 150px;
`;

const Field = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
  cursor: pointer;
  &.placeholder {
    color: var(--color-gray-dark);
  }
  ${OpenInNewIcon} {
    margin-top: 0px;
    margin-left: 10px;
    color: var(--color-gray-dark);
    &:hover {
      color: var(--color-charcoal);
    }
  }
  &:hover {
    background: white;
  }
`;

const SupplierField = styled(Field)`
  font-weight: bold;
`;

const AddressField = styled(Field)`
  white-space: pre-wrap;
`;

const CompanyTaxNumber = styled.div`
  padding: 5px;
`;
