import { useSelector } from "react-redux";

import { getRateCardId } from "../../../../lib/entities/jobEntity";
import { selectJob } from "../../../../redux/selectors/job";
import { selectRateCard } from "../../../../redux/selectors/rateCard";
import { AppState } from "../../../AppState";

export function selectJobCurrency(
  state: AppState,
  { jobId }: { jobId: number }
) {
  const job = selectJob(state, { jobId });
  const rateCard = selectRateCard(state, { rateCardId: getRateCardId(job) });
  return rateCard.currency;
}

export function useJobCurrency(jobId: number) {
  return useSelector((state: AppState) => selectJobCurrency(state, { jobId }));
}
