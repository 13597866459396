import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { JOB_ITEM_USER_DELETE_CONFIRM } from "../../../lib/constants";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import { IconButton } from "../../elements/Button";
import DeleteIcon from "../../elements/Icons/DeleteIcon";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobId } from "../context/JobIdContext";
import { JobItemUserIdContext } from "../context/JobItemUserIdContext";
import { ItemUserHours } from "./ItemUserHours";
import ItemUserInfo from "./ItemUserInfo";
import { ItemUserRate } from "./ItemUserRate";
import ItemUserStatus from "./ItemUserStatus";
import { ItemUserTotal } from "./ItemUserTotal";

const ItemUser = ({ id }) => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const { canViewJobFinancials } = usePermissions();
  const jobItemUser = useJobItemUser(id);

  const dispatch = useDispatch();

  const doJobItemUserDelete = () => {
    if (!isJobEditable) return;
    dispatch(createAction(JOB_ITEM_USER_DELETE_CONFIRM, { jobItemUser }));
  };

  const handleDelete = () => {
    doJobItemUserDelete();
  };

  return (
    <JobItemUserIdContext.Provider value={id}>
      <Container>
        <ItemUserInfo />
        <ItemUserHours />
        {canViewJobFinancials && (
          <>
            <ItemUserRate />
            <TotalStatusWrapper>
              <ItemUserTotal />
              <ItemUserStatus />
            </TotalStatusWrapper>
          </>
        )}
        {!canViewJobFinancials && <ItemUserStatus />}
        {isJobEditable && (
          <StyledIconButton tabIndex={-1} onClick={handleDelete}>
            <DeleteIcon />
          </StyledIconButton>
        )}
      </Container>
    </JobItemUserIdContext.Provider>
  );
};

ItemUser.propTypes = {
  id: entityIdType.isRequired,
};

export default ItemUser;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  & > *:not(:first-child) {
    margin-left: 2px;
  }
  &:hover {
    .StyledIconButton {
      opacity: 1;
    }
  }
`;

const StyledIconButton = styled(IconButton).attrs({
  className: "StyledIconButton",
})`
  height: 40px;
  position: absolute;
  right: -32px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

const TotalStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(:first-child) {
    margin-left: 2px;
  }

  width: ${ItemColumnWidths.Total + 52}px;
  @media (max-width: 1200px) {
    width: ${ItemColumnWidths.Total -
    ItemColumnWidths.TotalSmallScreenDiff +
    52}px;
  }
`;
