import createAnchorPlugin from "@draft-js-plugins/anchor";
import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from "@draft-js-plugins/buttons";
import React from "react";

import Flex from "../../../../elements/Flex";
import buttonStyles from "../buttonStyles.module.css";
import createStore from "../createStore";
import Separator from "../Separator";
import linkStyles from "./linkStyles.module.css";
import Toolbar from "./Toolbar";
import toolbarStyles from "./toolbarStyles.module.css";

const createInlineToolbarPlugin = (simplified) => {
  const store = createStore({
    isVisible: false,
  });

  const theme = { buttonStyles, toolbarStyles };

  const anchorPlugin = createAnchorPlugin({
    theme: linkStyles,
  });

  const { LinkButton } = anchorPlugin;

  const InlineToolbar = (props) => (
    <Toolbar {...props} store={store} theme={theme}>
      {(externalProps) => (
        <>
          <Flex align="center" justify="center">
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            {!simplified && <CodeButton {...externalProps} />}
            {!simplified && <LinkButton {...externalProps} />}
            <Separator />
            {!simplified && <HeadlineOneButton {...externalProps} />}
            {!simplified && <HeadlineTwoButton {...externalProps} />}
            {!simplified && <HeadlineThreeButton {...externalProps} />}
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            {!simplified && <BlockquoteButton {...externalProps} />}
            {!simplified && <CodeBlockButton {...externalProps} />}
          </Flex>
        </>
      )}
    </Toolbar>
  );

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.updateItem("getEditorState", getEditorState);
      store.updateItem("setEditorState", setEditorState);
      store.updateItem("getEditorRef", getEditorRef);
    },
    // Re-Render the text-toolbar on selection change
    onChange: (editorState) => {
      store.updateItem("selection", editorState.getSelection());

      return editorState;
    },
    InlineToolbar,
    anchorPlugin,
  };
};

export default createInlineToolbarPlugin;
