import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "../../elements/Icons/ChevronRightIcon";
import { ICON_SIZE } from "../StyledIcon";
import ListItemIcon from "./ListItemIcon";

const SubMenuIcon = props => (
  <StyledListItemIcon {...props}>
    <ChevronRightIcon size={ICON_SIZE.X_LARGE} />
  </StyledListItemIcon>
);

export default SubMenuIcon;

const StyledListItemIcon = styled(ListItemIcon)`
  justify-content: "flex-end";
  min-width: 24px;
`;
