import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { entityIdType } from "../../../lib/types/entityTypes";
import { TextButton } from "../../elements/Button";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";
import Section from "./Layout/Section";

const mapContext = ({
  id,
  documentUi: { canUndo = false, undoMergeSnapshotId },
  actions: { doMergeLineItemsUndo }
}) => ({
  id,
  canUndo,
  undoMergeSnapshotId,
  doMergeLineItemsUndo
});

class UndoMerge extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    canUndo: PropTypes.bool.isRequired,
    undoMergeSnapshotId: PropTypes.number,
    doMergeLineItemsUndo: PropTypes.func.isRequired
  };

  static defaultProps = {
    undoMergeSnapshotId: null
  };

  undoMerge = () => {
    const { id, undoMergeSnapshotId, doMergeLineItemsUndo } = this.props;
    doMergeLineItemsUndo(id, undoMergeSnapshotId);
  };

  render() {
    const { canUndo } = this.props;

    if (!canUndo) return null;

    return (
      <Section justify="center">
        <UndoMergeContainer>
          <TextButton className="underline" onClick={this.undoMerge}>
            Undo last merge
          </TextButton>
        </UndoMergeContainer>
      </Section>
    );
  }
}

export default consumeCommercialDocument(mapContext)(UndoMerge);

const UndoMergeContainer = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
  color: black;
`;
