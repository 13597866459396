import type { Prettify } from "@streamtimefe/types";
import { clsx } from "clsx";
import { cloneElement, type PropsWithChildren, type ReactElement } from "react";
import type { ButtonProps as AriaButtonProps } from "react-aria-components";
import { Button as AriaButton } from "react-aria-components";

import type { IconProps } from "../icon";
import { useDisabledBoundary } from "../utils";
import type { ButtonVariants } from "./styles.css";
import { buttonStyle } from "./styles.css";

export type ButtonProps = Prettify<
  PropsWithChildren<
    AriaButtonProps &
      ButtonVariants & {
        /** The icon to display within the button. */
        icon?: ReactElement<IconProps>;
        /** Positions a provided Icon before or after the button's children. */
        iconPosition?: "start" | "end";
      }
  >
>;

/**
 * A Button allows a user to perform an action, with mouse, touch, and keyboard interactions.
 * The Button component is built on the [React Aria Button Component](https://react-spectrum.adobe.com/react-aria/Button.html).
 * A full list of props available to the Button component can be found in the [props section of the documentation](https://react-spectrum.adobe.com/react-aria/Button.html#props).
 *
 * ### Usage
 *
 * A Button is used to trigger an action or event, such as submitting a form,
 * opening a modal, or navigating to another view. Buttons should be labeled clearly
 * to indicate the action they perform, providing users with immediate understanding
 * of their function.
 *
 * ### Accessibility
 *
 * If a visual label is not provided (e.g. an icon only button), then the `aria-label`
 * or `aria-labelledby` prop must be passed to identify the button to assistive technology.
 */
export function Button({
  children,
  icon,
  iconPosition = "start",
  size,
  stretch,
  variant,
  className,
  isDisabled,
  ...rest
}: ButtonProps) {
  const iconElement =
    icon && cloneElement(icon, { size: size === "small" ? 12 : 20 });
  const isDisabledBoundary = useDisabledBoundary();

  return (
    <AriaButton
      {...rest}
      isDisabled={isDisabled || isDisabledBoundary}
      className={clsx(buttonStyle({ size, variant, stretch }), className)}
    >
      {iconPosition === "start" && iconElement}
      {children}
      {iconPosition === "end" && iconElement}
    </AriaButton>
  );
}
