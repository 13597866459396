import type { PopoverOrigin } from "@material-ui/core";
import clsx from "clsx";
import { filter, includes } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { searchWordMatches } from "st-shared/lib";
import { RoleIconName } from "st-shared/module";
import { theme } from "st-shared/theme";

import { convertMinutesToTimeHM } from "../../../lib/time";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useRole } from "../../../redux/selectors/role";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { useJobItemUserOptions } from "../../../state/entities/user/selectors/useJobItemUserOptions";
import Button, { PrimaryButton } from "../../elements/Button";
import Switch from "../../elements/Switch";
import PopoverMenu from "../Menu/PopoverMenu";
import AvailabilityMenuHeader from "../UserMenus/AvailabilityMenuHeader";
import * as styles from "./AssignRoleMenu.css";
import { AssignUserOption } from "./AssignUserOption";
import useAssignUserRolesReducer from "./AssignUserRolesReducer";
import { UserResults } from "./UserResults";
import useUserAvailability from "./useUserAvailability";

interface AssignRoleMenuProps {
  anchorEl?: any;
  jobItemRoleId: number;
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  assignUsers: (users: { userId: number; minutes: number }[]) => void;
  close: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export function AssignRoleMenu({
  anchorEl,
  jobItemRoleId,
  defaultStartDate,
  defaultEndDate,
  assignUsers,
  close,
  anchorOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: -5,
    horizontal: -5,
  },
}: AssignRoleMenuProps) {
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const jobItem = useJobItem(jobItemRole.jobItemId);
  const isJobEditable = useIsJobEditable(jobItem.jobId);
  const role = useRole(jobItemRole.roleId);

  const [roleOnly, setRoleOnly] = useState(true);

  const [assignUserState, assignUserDispatch] = useAssignUserRolesReducer(
    jobItemRole.totalPlannedMinutes
  );

  const options = useJobItemUserOptions();

  let userOptions = options;

  if (roleOnly) {
    userOptions = filter(
      userOptions,
      (option) => option.roleId === jobItemRole.roleId
    );
  }

  useEffect(() => {
    if (userOptions.length === 0) {
      setRoleOnly(false);
    }
  }, []);

  userOptions = filter(
    userOptions,
    (option) => !includes(assignUserState.userIds, option.key)
  );

  const [searchQuery, setSearchQuery] = useState("");

  const userResults =
    searchQuery.length > 0
      ? userOptions.filter((option: any) =>
          searchWordMatches(option.searchString, searchQuery)
        )
      : userOptions;

  function onPickUsers(userIds: number[]) {
    const userId = userIds[0];
    assignUserDispatch({ type: "addUser", userId });
    requestAnimationFrame(() => {
      listRef.current?.lastElementChild?.scrollIntoView();
    });
  }

  function onClearMinutes() {
    assignUserDispatch({ type: "clearMinutes" });
  }

  function onRemoveUser(userId: number) {
    assignUserDispatch({ type: "removeUser", userId });
  }

  function onUpdateUserMinutes(userId: number, minutes: number) {
    assignUserDispatch({ type: "updateMinutes", userId, minutes });
  }

  function onAssignUsers() {
    assignUsers(Object.values(assignUserState.users));
    onClose();
  }

  function onClose() {
    setSearchQuery("");
    close();
  }

  const { userAvailability, onChangeDateRange, initialFetch } =
    useUserAvailability(defaultStartDate, defaultEndDate);

  const listRef = useRef<HTMLDivElement>(null);

  if (!isJobEditable) return null;

  return (
    <PopoverMenu
      classes={{ paper: styles.popoverPaper }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      TransitionProps={{
        onEnter: initialFetch,
      }}
      HeaderComponent={
        <div>
          <AvailabilityMenuHeader
            hasOptions={Boolean(userOptions.length)}
            hasResults={Boolean(userResults.length)}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder="Search by name, branch or role..."
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            onChange={onChangeDateRange}
            unrestrictDates
            title={role.name}
            noOptionsMessage="Looks like there aren’t any team members to select. Toggle to show all team members."
          />
          <div className={styles.roleOnlySelector}>
            <div>
              <span className={styles.roleTitle}>{role.name}</span>
              <span> only</span>
            </div>
            <Switch
              checked={roleOnly}
              onChange={() => setRoleOnly(!roleOnly)}
            />
          </div>
        </div>
      }
      FooterComponent={
        <div className={styles.footer}>
          <div
            className={styles.unassignedHeader}
            style={{
              borderBottomWidth: assignUserState.userIds.length > 0 ? 1 : 0,
            }}
          >
            <RoleIconName
              id={jobItemRole.roleId}
              size={26}
              fontSize={14}
              color={theme.color.charcoal}
              type="unassigned"
              className={styles.unassignedIcon}
            />
            <div
              className={clsx(
                styles.unassignedValue,
                assignUserState.currentPlannedMinutes === 0 &&
                  styles.unassignedValueExact,
                assignUserState.currentPlannedMinutes < 0 &&
                  styles.unassignedValueWarning
              )}
            >
              {convertMinutesToTimeHM(assignUserState.currentPlannedMinutes)}
            </div>
          </div>
          <div ref={listRef} className={styles.menuList}>
            {assignUserState.userIds.map((userId) => {
              return (
                <AssignUserOption
                  key={userId}
                  userId={userId}
                  minutes={assignUserState.users[userId].minutes}
                  removeUser={onRemoveUser}
                  updateMinutes={onUpdateUserMinutes}
                />
              );
            })}
          </div>
          <div className={styles.footerActions}>
            <Button className={styles.footerButton} onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={styles.footerButton}
              onClick={onClearMinutes}
              disabled={assignUserState.userIds.length === 0}
            >
              Clear all
            </Button>
            <PrimaryButton
              className={styles.footerButton}
              onClick={onAssignUsers}
              disabled={assignUserState.userIds.length === 0}
            >
              Assign
            </PrimaryButton>
          </div>
        </div>
      }
    >
      <UserResults
        results={userResults}
        onPickUsers={onPickUsers}
        userAvailability={userAvailability}
      />
    </PopoverMenu>
  );
}
