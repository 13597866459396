import numeral from "numeral";
import { createSelector } from "reselect";
import { costingMethodObj, timeAllocationMethodObj } from "st-shared/entities";

import {
  getTotalPlannedMinutes as getJobItemTotalPlannedMinutes,
  hasSellRate,
} from "../../../lib/entities/jobItemEntity";
import {
  getTotalPlannedMinutes as getJobItemUserTotalPlannedMinutes,
  getTotalUsedMinutes,
  getTotalUsedMoney,
} from "../../../lib/entities/jobItemUserEntity";
import { selectJobItem } from "../jobItem";
import { selectJobItemUser } from "./index";
import { selectJobItemUserCalculatedSellRate } from "./selectJobItemUserCalculatedSellRate";

export const selectJobItemUserCalculatedTotalLoggedTimeExTax = createSelector(
  [selectJobItem, selectJobItemUser, selectJobItemUserCalculatedSellRate],
  (jobItem, jobItemUser, calculatedSellRate) => {
    const costingMethod = costingMethodObj(jobItem.costingMethod);
    const isUsingItemFallbackRate =
      !hasSellRate(jobItem) && costingMethod.isItem();

    const isUsingValueFallbackRate =
      !hasSellRate(jobItem) &&
      costingMethod.isValueCalculatedSell() &&
      (timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()
        ? getJobItemTotalPlannedMinutes(jobItem) <= 0
        : getJobItemUserTotalPlannedMinutes(jobItemUser) <= 0);

    if (isUsingItemFallbackRate || isUsingValueFallbackRate) {
      return getTotalUsedMoney(jobItemUser);
    }

    return numeral(calculatedSellRate)
      .multiply(numeral(getTotalUsedMinutes(jobItemUser)).divide(60).value())
      .value();
  }
);
