import { call, put, take } from "redux-saga/effects";

import { ENTITIES_REMOVED } from "../../../lib/constants";
import { RECEIVE_REMOVE_MODEL } from "../../../lib/WebAppAPI";
import { EntityClassNameMap } from "../../../state/entities/types";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import createWebAPIChannel from "./createWebAPIChannel";

export default function* watchWebAPIRemoveModel() {
  const channel = yield call(createWebAPIChannel, RECEIVE_REMOVE_MODEL);

  while (true) {
    try {
      const [modelName, id] = yield take(channel);

      const entityName = EntityClassNameMap[modelName];

      if (entityName) {
        yield put(
          createAction(ENTITIES_REMOVED, {
            entityName,
            ids: [id],
          })
        );
      }
    } catch (error) {
      sagaError(error);
    }
  }
}
