import type { TEntityId } from "@streamtimefe/entities";
import { EntityClass, RateCard } from "@streamtimefe/entities";
import type { FormatCurrencyOptions } from "@streamtimefe/utils";
import { formatCurrency } from "@streamtimefe/utils";
import { useCallback, useMemo } from "react";
import { isDefined } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { RateCardStore } from "./RateCard.store";

export const useRateCardEntity = createUseEntityStoreEntity(
  EntityClass.RateCard
);

export const getRateCardEntity = createGetEntityStoreEntity(
  EntityClass.RateCard
);

export const useRateCard = createUseSingleEntity(RateCardStore);

function useRateCardEntities() {
  return useEntityStoreEntities(EntityClass.RateCard);
}

export function useActiveRateCards() {
  const rateCards = useRateCardEntities();
  return useMemo(
    () =>
      Object.values(rateCards)
        .filter(isDefined)
        .filter((rateCard) => !rateCard.archived),
    [rateCards]
  );
}

export function useActiveRateCardSearchItems() {
  const rateCards = useActiveRateCards();
  return useMemo(() => RateCard.getSearchItems(rateCards), [rateCards]);
}

export function useRateCardEntityCurrency(id: TEntityId | null | undefined) {
  const rateCard = useRateCardEntity(id);
  return rateCard?.currency;
}

export function useRateCardCurrency() {
  const rateCard = useRateCard();
  return rateCard.currency;
}

export function useRateCardEntityCurrencyFormatter(
  id: TEntityId | null | undefined,
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const symbol = useRateCardEntityCurrency(id)?.symbol;

  return useCallback(
    (value: number) => {
      return formatCurrency(value, {
        ...options,
        symbol,
      });
    },
    [symbol, options]
  );
}

export function useRateCardCurrencyFormatter(
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const symbol = useRateCardCurrency().symbol;

  return useCallback(
    (value: number) => {
      return formatCurrency(value, {
        ...options,
        symbol,
      });
    },
    [symbol, options]
  );
}
