import { UserPreference } from "@streamtimefe/entities";
import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { EMPTY_ARRAY, ENTITY_NAME_USERS } from "../../lib/constants";
import { getRoleId, userOptionToMention } from "../../lib/entities/userEntity";
import { selectPropsId } from "./index";
import { selectRoleById } from "./roleSelectors";

const selectUsers = (state) => state.entities[ENTITY_NAME_USERS];

export const selectUserById = createSelector(
  selectUsers,
  selectPropsId,
  (users, id) => get(users, `byId[${id}]`)
);

export const selectWorkingHours = createSelector(
  selectUsers,
  selectPropsId,
  (users, id) => get(users, `userWorkingHours[${id}]`)
);

export const selectRoleByUserId = (state, props) =>
  selectRoleById(state, {
    id: getRoleId(selectUserById(state, props)),
  });

export const selectActiveUserOptions = createSelector(selectUsers, (users) =>
  get(users, `activeOptions`, EMPTY_ARRAY)
);

export const selectActiveUserOptionsGroupedByBranch = createSelector(
  selectUsers,
  (users) => get(users, `activeOptionsGroupedByBranch`, EMPTY_ARRAY)
);

export const selectOrderedActiveUsersString = createSelector(
  selectActiveUserOptions,
  (userOptions) =>
    UserPreference.joinListValue(userOptions.map((option) => option.key))
);

export const selectMentions = createSelector(selectActiveUserOptions, (users) =>
  users.map(userOptionToMention)
);

export const useMentions = () => useSelector(selectMentions);
