import { produce } from "immer";
import { get, set, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import {
  getJobItemIdPath,
  getOrderId,
} from "../../../../lib/entities/jobItemSubItemEntity";

export default (prevState, nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      if (prevEntity) pushOnce(touchedPaths, getJobItemIdPath(prevEntity));
      if (newEntity) pushOnce(touchedPaths, getJobItemIdPath(newEntity));
    });

    if (touchedPaths.length === 0) return;

    touchedPaths.forEach((path) => {
      const jobItemSubItemIds = get(nextState, `idsByJobItemId${path}`, []);

      if (!jobItemSubItemIds.length)
        unset(draft, `jobItemSubItemOrderByJobItemId${path}`);
      else
        set(
          draft,
          `jobItemSubItemOrderByJobItemId${path}`,
          jobItemSubItemIds
            .slice()
            .sort((jobItemSubItemId1, jobItemSubItemId2) => {
              const jobItemSubItem1 = get(nextState.byId, jobItemSubItemId1);
              const jobItemSubItem2 = get(nextState.byId, jobItemSubItemId2);
              return getOrderId(jobItemSubItem1) - getOrderId(jobItemSubItem2);
            })
        );
    });
  });
