import { call, put, select } from "redux-saga/effects";
import { duplicateJobPhaseAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  JOB_PHASE_LOADING_ENDED,
  JOB_PHASE_LOADING_STARTED,
  JOB_PHASE_DUPLICATE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemIdsByJobPhaseId } from "../../selectors/jobItem/selectJobItemIdsByJobPhaseId";

function* duplicate(action) {
  const { jobPhaseId } = action.payload;

  let jobItemIds = [];
  try {
    jobItemIds = yield select(selectJobItemIdsByJobPhaseId, { jobPhaseId });
  } catch (error) {
    sagaError(error);
  }

  try {
    yield put(
      createAction(JOB_PHASE_LOADING_STARTED, { jobPhaseId, jobItemIds })
    );

    const { data } = yield call(duplicateJobPhaseAPI, jobPhaseId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(
      createAction(JOB_PHASE_LOADING_ENDED, { jobPhaseId, jobItemIds })
    );
  }
}

export default function* watchJobPhaseDuplicate() {
  yield takeLatestBy(
    [JOB_PHASE_DUPLICATE],
    duplicate,
    action => action.payload.jobPhaseId
  );
}
