import { get, isNull } from "lodash-es";
import { round } from "./math";

export const roundCoords = ({ x, y }, decimals = 1) => ({
  x: round(x, decimals),
  y: round(y, decimals)
});

export const addCoords = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => ({
  x: x1 + x2,
  y: y1 + y2
});

export const subtractCoords = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => ({
  x: x1 - x2,
  y: y1 - y2
});

export const getElementOffset = element => ({
  x: element.offsetLeft,
  y: element.offsetTop
});

export const getElementClientOffset = element => ({
  x: element.getBoundingClientRect().x,
  y: element.getBoundingClientRect().y
});

export const getElementSize = element => ({
  w: element.getBoundingClientRect().width,
  h: element.getBoundingClientRect().height
});

export const getElementClientCenterOffset = element => ({
  x:
    element.getBoundingClientRect().x +
    element.getBoundingClientRect().width / 2,
  y:
    element.getBoundingClientRect().y +
    element.getBoundingClientRect().height / 2
});

export const getAttrPropString = attr => `data-${attr}`;

export const getDataValue = (element, attr) =>
  element && get(element.dataset, attr, null);

export const getDataIntValue = (element, attr) =>
  isNull(getDataValue(element, attr))
    ? null
    : parseInt(getDataValue(element, attr), 10);

export const getClosestElementFromPoint = (event, selector) => {
  const element = document.elementFromPoint(event.clientX, event.clientY);

  return element && element.closest(selector);
};

export const getPointerClientOffset = pointerEvent => ({
  x: pointerEvent.clientX,
  y: pointerEvent.clientY
});

export const getElementHtml = (
  element,
  cleanInlineStyles = false,
  outerElement = false
) => {
  if (cleanInlineStyles) {
    const clone = element.cloneNode(true);
    clone.style = null;
    return outerElement ? clone.outerHTML : clone.innerHTML;
  }

  return outerElement ? element.outerHTML : element.innerHTML;
};

export const isDisabledElement = element =>
  Boolean(element.hasAttribute("disabled"));

export const getElementHasClass = (element, className) =>
  Array.from(element.classList).includes(className);

export const getChildElement = (parent, path) => {
  let element = parent;
  if (path !== null) {
    for (let i = 0; i < path.length; i += 1) {
      const childElement = element.querySelector(path[i]);
      if (childElement === null) break;
      element = childElement;
    }
  }
  return element;
};
