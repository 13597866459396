import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { entityIdListType } from "../../../lib/types/entityTypes";
import Flex from "../../elements/Flex";
import JobMilestoneConnector from "../../modules/Connectors/JobMilestoneConnector";
import Tooltip from "../../modules/Tooltip";

class MilestonesTooltip extends React.PureComponent {
  static propTypes = {
    jobMilestoneIds: entityIdListType.isRequired,
    children: PropTypes.node.isRequired
  };

  get title() {
    const { jobMilestoneIds } = this.props;

    return (
      <Content>
        <Heading>Milestones on this day</Heading>
        {jobMilestoneIds.map(jobMilestoneId => (
          <JobMilestoneConnector
            key={`milestone-${jobMilestoneId}`}
            id={jobMilestoneId}
          >
            {jobMilestone => (
              <div key={jobMilestone.id}>{jobMilestone.name} </div>
            )}
          </JobMilestoneConnector>
        ))}
      </Content>
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Tooltip title={this.title} placement="top">
        {children}
      </Tooltip>
    );
  }
}

export default MilestonesTooltip;

const Content = styled(Flex)`
  flex-direction: column;
  color: var(--color-gray);
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
`;

const Heading = styled(Flex)`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 5px;
`;
