import type {
  TAuthenticationBootstrapResponse,
  TEntityClass,
  TEntityClassMap,
  TEntityId,
  TModelSet,
  TSyncKeyReplacementSet,
} from "@streamtimefe/entities";
import { webBridgeActions } from "@streamtimefe/web-bridge";

import { CurrencyStore } from "../stores/currency";
import { EntityStore } from "../stores/entity";
import { UserPreferenceStore } from "../stores/userPreference";
import { modalBridgeApplyModelData } from "./modalBridgeStoreSync";
import { modelSetParser } from "./modelSetParser";

export function storeSyncAuthBootstrap(
  response: TAuthenticationBootstrapResponse
) {
  EntityStore.actions.authSync(response);
  UserPreferenceStore.actions.authSync(response);
  CurrencyStore.actions.authSync(response);
}

export function storeSyncModelData<
  T extends TEntityClass | undefined = undefined,
>(data: TModelSet<T>, isFromExternalSource = false) {
  const entityList = modelSetParser<T>(data);

  EntityStore.actions.entityBulkUpdate(
    entityList,
    data.__syncKeyReplacementSet
  );

  if ("__removedEntityIds" in data && data.__removedEntityIds)
    EntityStore.actions.entityBulkDelete(data.__removedEntityIds);

  if (entityList.UserPreference) {
    UserPreferenceStore.actions.bulkUpdate(entityList.UserPreference);
  }

  if (!isFromExternalSource) {
    // send to web
    webBridgeActions.applyModelData(data as TModelSet);
    // send through modal bridge
    modalBridgeApplyModelData(data as TModelSet);
  }
}

export function storeSyncModels<T extends TEntityClass>(
  primaryModelClass: T,
  data: TEntityClassMap[T][],
  isFromExternalSource = false,
  syncKeyReplacementSet?: TSyncKeyReplacementSet
) {
  const modelSet: TModelSet = {
    __modelSet: [{ className: primaryModelClass, models: data }],
    __syncKeyReplacementSet: syncKeyReplacementSet,
  };

  storeSyncModelData(modelSet, isFromExternalSource);
}

export function storeSyncModel<T extends TEntityClass>(
  primaryModelClass: T,
  data: TEntityClassMap[T],
  isFromExternalSource = false,
  syncKeyReplacementSet?: TSyncKeyReplacementSet
) {
  const modelSet: TModelSet = {
    __modelSet: [{ className: primaryModelClass, models: [data] }],
    __syncKeyReplacementSet: syncKeyReplacementSet,
  };

  storeSyncModelData(modelSet, isFromExternalSource);
}

export function storeSyncRemoveModels<T extends TEntityClass>(
  modelClass: T,
  entityIds: TEntityId[],
  isFromExternalSource = false
) {
  const modelSet: TModelSet = {
    __removedEntityIds: { [modelClass]: entityIds },
  };

  storeSyncModelData(modelSet, isFromExternalSource);
}

export function storeSyncRemoveModel<T extends TEntityClass>(
  modelClass: T,
  entityId: TEntityId,
  isFromExternalSource = false
) {
  const modelSet: TModelSet = {
    __removedEntityIds: { [modelClass]: [entityId] },
  };

  storeSyncModelData(modelSet, isFromExternalSource);
}
