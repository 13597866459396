import type { PopoverOrigin } from "@material-ui/core";
import { filter } from "lodash-es";
import { useEffect, useState } from "react";
import { searchWordMatches } from "st-shared/lib";

import { useJobItemUserOptions } from "../../../state/entities/user/selectors/useJobItemUserOptions";
import Switch from "../../elements/Switch";
import PopoverMenu from "../Menu/PopoverMenu";
import AvailabilityMenuHeader from "../UserMenus/AvailabilityMenuHeader";
import * as styles from "./AssignAllRoleMenu.css";
import { UserResults } from "./UserResults";
import useUserAvailability from "./useUserAvailability";

interface Role {
  id: number;
  name: string;
}

type Props = {
  anchorEl?: any;
  role: Role;
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  assignUser: (userId: number) => void;
  close: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

export function AssignAllRoleMenu({
  anchorEl,
  role,
  defaultStartDate,
  defaultEndDate,
  assignUser,
  close,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: -10,
    horizontal: 0,
  },
}: Props) {
  const [roleOnly, setRoleOnly] = useState(true);

  const options = useJobItemUserOptions();

  let userOptions = options;

  if (roleOnly) {
    userOptions = filter(userOptions, (option) => option.roleId === role.id);
  }

  useEffect(() => {
    if (userOptions.length === 0) {
      setRoleOnly(false);
    }
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const userResults =
    searchQuery.length > 0
      ? userOptions.filter((option: any) =>
          searchWordMatches(option.searchString, searchQuery)
        )
      : userOptions;

  function onPickUsers(userIds: number[]) {
    const userId = userIds[0];
    assignUser(userId);
    onClose();
  }

  function onClose() {
    setSearchQuery("");
    close();
  }

  const { userAvailability, onChangeDateRange, initialFetch } =
    useUserAvailability(defaultStartDate, defaultEndDate);

  return (
    <PopoverMenu
      classes={{ paper: styles.popoverPaper }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      TransitionProps={{
        onEnter: initialFetch,
      }}
      disableScrollLock={true}
      HeaderComponent={
        <div>
          <AvailabilityMenuHeader
            hasOptions={Boolean(userOptions.length)}
            hasResults={Boolean(userResults.length)}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder="Search by name, branch or role..."
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            onChange={onChangeDateRange}
            unrestrictDates
            title={`Assign ${role.name}`}
            noOptionsMessage="Looks like there aren’t any team members to select. Toggle to show all team members."
          />
          <div className={styles.roleOnlySelector}>
            <div>
              <span className={styles.roleTitle}>{role.name}</span>
              <span> only</span>
            </div>
            <Switch
              checked={roleOnly}
              onChange={() => setRoleOnly(!roleOnly)}
            />
          </div>
        </div>
      }
    >
      <UserResults
        results={userResults}
        onPickUsers={onPickUsers}
        userAvailability={userAvailability}
      />
    </PopoverMenu>
  );
}
