import { mapValues, omit } from "lodash-es";
import {
  PURCHASE_ORDER_LINE_ITEM_SAVING,
  PURCHASE_ORDER_LINE_ITEM_SAVED,
  PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_CREATING,
  PURCHASE_ORDER_LINE_ITEM_CREATED,
  PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_DELETING,
  PURCHASE_ORDER_LINE_ITEM_DELETED,
  PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING,
  LINE_ITEMS_MERGE_START,
  PURCHASE_ORDER_LINE_ITEM_EDIT,
  PURCHASE_ORDER_LINE_ITEM_SAVE
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onCreateRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isCreating: true, error: null }
});

const onCreateSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isCreating: false }
});

const onCreateError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isCreating: false, error }
});

const onDeleteRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isDeleting: true, error: null }
});

const onDeleted = (state, { payload: { id } }) => omit(state, id);

const onDeleteError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isDeleting: false, error }
});

const onSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false }
});

const onSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false, error }
});

const setIsMerging = (state, { payload: { id, isMerging } }) => ({
  ...state,
  [id]: { ...state[id], isMerging }
});

const clearIsMerging = state =>
  mapValues(state, subState => omit(subState, "isMerging"));

const onEdit = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isEditing: true }
});

const onSave = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isEditing: false }
});

export default createReducer(
  {},
  {
    [PURCHASE_ORDER_LINE_ITEM_EDIT]: onEdit,
    [PURCHASE_ORDER_LINE_ITEM_SAVE]: onSave,
    [PURCHASE_ORDER_LINE_ITEM_SAVING]: onSaveRequest,
    [PURCHASE_ORDER_LINE_ITEM_SAVED]: onSaveSuccess,
    [PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR]: onSaveError,
    [PURCHASE_ORDER_LINE_ITEM_CREATING]: onCreateRequest,
    [PURCHASE_ORDER_LINE_ITEM_CREATED]: onCreateSuccess,
    [PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR]: onCreateError,
    [PURCHASE_ORDER_LINE_ITEM_DELETING]: onDeleteRequest,
    [PURCHASE_ORDER_LINE_ITEM_DELETED]: onDeleted,
    [PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR]: onDeleteError,
    [PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING]: setIsMerging,
    [LINE_ITEMS_MERGE_START]: clearIsMerging
  }
);
