// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../vite-env.d.ts"/>

import { z } from "zod";

import { BuildEnvironment } from "./BuildEnvironment";
import { nodeEnvSchema } from "./nodeEnvSchema";

export const preRuntimeEnvSchema = z.object({
  // dev only
  VITE_DEV_WEB_API_OVERRIDE: z
    .enum([BuildEnvironment.Demo, BuildEnvironment.Dev])
    .optional(),
  VITE_DEV_CLIENT_API_OVERRIDE: z
    .enum([BuildEnvironment.Demo, BuildEnvironment.Dev])
    .optional(),
});

export type TPreRuntimeEnv = z.infer<typeof preRuntimeEnvSchema>;

export const runtimeEnvSchema = z.object({
  VITE_APP_VERSION: nodeEnvSchema.shape.VITE_APP_VERSION,
  VITE_BUILD_ENV: nodeEnvSchema.shape.VITE_BUILD_ENV,
  VITE_BUGSNAG_API_KEY: nodeEnvSchema.shape.VITE_BUGSNAG_API_KEY,
  VITE_WEB_API_ROOT: z.string(),
  VITE_CLIENT_API_ROOT: z.string(),
  // dev only
  VITE_DEV_REDUX_LOGGER: z
    .string()
    .optional()
    .transform((value) => (import.meta.env.DEV ? Boolean(value) : false)),
  VITE_DEV_DEFAULT_ROUTE: z
    .string()
    .optional()
    .transform((value) => (import.meta.env.DEV ? value : undefined)),
  VITE_DEV_AUTH_DOMAIN: z
    .string()
    .optional()
    .transform((value) => (import.meta.env.DEV ? value : undefined)),
  VITE_DEV_AUTH_EMAIL: z
    .string()
    .optional()
    .transform((value) => (import.meta.env.DEV ? value : undefined)),
  VITE_DEV_AUTH_PASSWORD: z
    .string()
    .optional()
    .transform((value) => (import.meta.env.DEV ? value : undefined)),
});

export type TRuntimeEnv = z.infer<typeof runtimeEnvSchema>;
