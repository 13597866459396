import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { RoutePages, routerReplace } from "../../../lib/router";
import {
  feToWebOpenPremiumOnlyModal,
  PremiumOnlyModalMode,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../../redux/helpers/createAction";
import { useJobActivityPostsFilter } from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { IconButton } from "../../elements/Button";
import AddIcon from "../../elements/Icons/AddIcon";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import { useIsDrawer } from "../context/isDrawerContext";
import { useJobId } from "../context/JobIdContext";

const JobActivityCreatePostButton = () => {
  const { canAccessCollaboration } = usePermissions();
  const isPostsFilterActive = useJobActivityPostsFilter();
  const dispatch = useDispatch();
  const jobId = useJobId();

  const isDrawer = useIsDrawer();

  const createPost = () => {
    if (canAccessCollaboration) {
      routerReplace(RoutePages.Jobs, jobId, `activity/post/create`);
    } else {
      feToWebOpenPremiumOnlyModal(PremiumOnlyModalMode.Collaboration);
    }

    if (isDrawer) {
      dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, false));
    }

    if (!isPostsFilterActive)
      dispatch(
        createAction(SET_USER_PREFERENCE, {
          key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER,
          value: true,
        })
      );
  };

  return (
    <Tooltip title={<TooltipTitle>Create a post</TooltipTitle>} placement="top">
      <CreateIconButton onClick={createPost}>
        <Circle>
          <AddIcon size={ICON_SIZE.X_LARGE} />
        </Circle>
      </CreateIconButton>
    </Tooltip>
  );
};

export default JobActivityCreatePostButton;

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-charcoal);
  color: white;
  display: flex;
`;

const CreateIconButton = styled(IconButton)`
  margin: 0 3px;

  &:hover {
    ${Circle} {
      background-color: var(--color-blue-active);
    }
  }
`;

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
`;
