import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { getTotalLoggedMinutes } from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobCalculatedTotalPlannedMinutes } from "../job/selectJobCalculatedTotalPlannedMinutes";

export const selectJumbotronIsUsedTimeOverPlannedTimeHours = createCachedSelector(
  [selectJobCalculatedTotalPlannedMinutes, selectJob],
  (calculatedTotalPlannedMinutes, job) => {
    if (numeral(calculatedTotalPlannedMinutes).value() > 0)
      return (
        numeral(getTotalLoggedMinutes(job))
          .subtract(numeral(calculatedTotalPlannedMinutes).value())
          .value() > 0
      );
    return false;
  }
)(selectPropsJobId);

export const useJumbotronIsUsedTimeOverPlannedTimeHours = jobId =>
  useSelector(state =>
    selectJumbotronIsUsedTimeOverPlannedTimeHours(state, { jobId })
  );
