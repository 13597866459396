import { Entity } from "@streamtimefe/entities";
import { call, put } from "redux-saga/effects";

import { createLabelAPI } from "../../../lib/API/labelsAPI";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_LABELS,
  LABELS_CREATE,
  LABELS_CREATE_ERROR,
  LABELS_CREATED,
  LABELS_CREATING,
} from "../../../lib/constants";
import { createNewLabel } from "../../../lib/entities/labelEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* createLabel(action) {
  const { entityId, labelTypeId, value } = action.payload;

  const labelId = Entity.temporaryId();

  try {
    const label = createNewLabel({
      id: labelId,
      entityId,
      labelTypeId,
      value,
    });

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_LABELS]: [label],
      })
    );

    yield put(
      createAction(LABELS_CREATING, {
        label,
      })
    );

    const { data } = yield call(createLabelAPI, label, labelTypeId);

    yield put(
      createAction(ENTITIES_REMOVED, {
        entityName: ENTITY_NAME_LABELS,
        ids: [labelId],
      })
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(LABELS_CREATED, {
        data,
      })
    );
  } catch (error) {
    yield put(
      createAction(LABELS_CREATE_ERROR, {
        labelId,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchCreateLabel() {
  yield takeLatestBy(
    [LABELS_CREATE],
    createLabel,
    ({ payload: { entityId, labelTypeId, value } }) =>
      `${entityId}-${labelTypeId}-${value}`
  );
}
