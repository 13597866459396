import { call, put, select, takeLatest } from "redux-saga/effects";
import { mergePurchaseOrderLineItemsAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  ITEM_PRICING_METHOD_TYPE_ID_FIXED,
  LINE_ITEMS_MERGE_CONFIRM,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING
} from "../../../lib/constants";
import {
  getPurchaseOrderId,
  isPurchaseOrder
} from "../../../lib/entities/loggedExpenseEntity";
import { mergeJobCurrencySellTotals } from "../../../lib/entities/purchaseOrderLineItemEntity";
import { getId } from "../../../lib/objects";
import { getExchangeRate } from "../../../lib/ui/commercialDocument";
import {
  calculateMarkupWithExchangeRate,
  calculateUnitRate,
  mergeDescriptions,
  mergeQuantities,
  mergeTotals,
  mergeUnitRates
} from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import {
  selectLoggedExpenseByLoggedExpenseId,
  selectLoggedExpenseUi
} from "../../selectors/loggedExpenseSelectors";
import { selectSortedPurchaseOrderLineItemsMergingByPurchaseOrderId } from "../../selectors/purchaseOrderLineItemSelectors";

function* mergeLineItems(action) {
  const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

  if (!loggedExpenseId) return;

  const loggedExpense = yield select(selectLoggedExpenseByLoggedExpenseId, {
    loggedExpenseId
  });

  if (!isPurchaseOrder(loggedExpense)) return;

  const purchaseOrderId = getPurchaseOrderId(loggedExpense);

  if (!purchaseOrderId) return;

  const purchaseOrderLineItems = yield select(
    selectSortedPurchaseOrderLineItemsMergingByPurchaseOrderId,
    { purchaseOrderId }
  );

  if (!purchaseOrderLineItems.length) return;

  try {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING, {
        id: loggedExpenseId,
        purchaseOrderId
      })
    );

    const [
      parentPurchaseOrderLineItem,
      ...childPurchaseOrderLineItems
    ] = purchaseOrderLineItems;

    const description = mergeDescriptions(purchaseOrderLineItems);
    const quantity = mergeQuantities(purchaseOrderLineItems);
    const totalAmountExTax = mergeTotals(purchaseOrderLineItems);
    const unitRate = mergeUnitRates(
      parentPurchaseOrderLineItem,
      quantity,
      totalAmountExTax
    );
    const jobCurrencyTotalSellExTax = mergeJobCurrencySellTotals(
      purchaseOrderLineItems
    );
    const jobCurrencySellRateExTax = calculateUnitRate(
      quantity,
      jobCurrencyTotalSellExTax
    );
    const markup = calculateMarkupWithExchangeRate(
      unitRate,
      jobCurrencySellRateExTax,
      getExchangeRate(loggedExpense)
    );

    const mergedPurchaseOrderLineItem = {
      ...parentPurchaseOrderLineItem,
      itemPricingMethodId: ITEM_PRICING_METHOD_TYPE_ID_FIXED,
      description,
      quantity,
      unitRate,
      totalAmountExTax,
      markup,
      jobCurrencySellRateExTax,
      jobCurrencyTotalSellExTax
    };

    const mergedPurchaseOrderLineItems = [
      mergedPurchaseOrderLineItem,
      ...childPurchaseOrderLineItems.map(lineItem => ({
        ...lineItem,
        active: false
      }))
    ];

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS]: mergedPurchaseOrderLineItems
      })
    );

    const data = yield call(
      mergePurchaseOrderLineItemsAPI,
      mergedPurchaseOrderLineItem,
      childPurchaseOrderLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    const snapshotId = createSnapshotId();

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED, {
        id: loggedExpenseId,
        purchaseOrderId,
        snapshotId,
        data: purchaseOrderLineItems
      })
    );

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR, {
        id: loggedExpenseId,
        purchaseOrderId,
        prevPurchaseOrderLineItems: purchaseOrderLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsMergeConfirm() {
  yield takeLatest(LINE_ITEMS_MERGE_CONFIRM, mergeLineItems);
}
