import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { DATE_FORMAT_DATE_MONTH_YEAR } from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectScheduleBlockCompanyName,
  selectScheduleBlockJobFullName,
  selectScheduleBlockJobItemEstimatedEndDate,
  selectScheduleBlockJobItemEstimatedStartDate,
  selectScheduleBlockJobItemName,
  selectScheduleBlockJobPhaseName,
  selectScheduleBlockMinutes,
  selectScheduleBlockMinutesScheduled,
} from "../../../redux/selectors/scheduleBlockSelectors";

const mapState = (state, props) => ({
  jobItemName: selectScheduleBlockJobItemName(state, props),
  jobPhaseName: selectScheduleBlockJobPhaseName(state, props),
  jobFullName: selectScheduleBlockJobFullName(state, props),
  jobCompanyName: selectScheduleBlockCompanyName(state, props),
  jobItemStartDate: selectScheduleBlockJobItemEstimatedStartDate(state, props),
  jobItemDueDate: selectScheduleBlockJobItemEstimatedEndDate(state, props),
  scheduledMinutes: selectScheduleBlockMinutesScheduled(state, props),
  blockMinutes: selectScheduleBlockMinutes(state, props),
});

const mapDispatch = null;

const ScheduleBlockTooltip = ({
  jobItemName,
  jobPhaseName,
  jobFullName,
  jobCompanyName,
  jobItemStartDate,
  jobItemDueDate,
  scheduledMinutes,
  blockMinutes,
}) => (
  <BlockTooltip className="block-tooltip">
    {jobItemName && <BlockDescription>{jobItemName}</BlockDescription>}
    {jobPhaseName && <BlockDescription>{jobPhaseName}</BlockDescription>}
    {jobFullName && <BlockDescription>{jobFullName}</BlockDescription>}
    {jobCompanyName && <BlockDescription>{jobCompanyName}</BlockDescription>}
    <table>
      <tbody>
        <tr>
          <td>H / Day</td>
          <td>{convertMinutesToTimeHM(blockMinutes)}</td>
        </tr>
        <tr>
          <td>Scheduled</td>
          <td>{convertMinutesToTimeHM(scheduledMinutes)}</td>
        </tr>
        {jobItemStartDate && (
          <tr>
            <td>Start</td>
            <td>{formatDate(jobItemStartDate, DATE_FORMAT_DATE_MONTH_YEAR)}</td>
          </tr>
        )}
        {jobItemDueDate && (
          <tr>
            <td>Due</td>
            <td>{formatDate(jobItemDueDate, DATE_FORMAT_DATE_MONTH_YEAR)}</td>
          </tr>
        )}
      </tbody>
    </table>
  </BlockTooltip>
);

ScheduleBlockTooltip.propTypes = {
  blockKey: entityIdType.isRequired,
  jobItemName: PropTypes.string,
  jobPhaseName: PropTypes.string,
  jobFullName: PropTypes.string,
  jobCompanyName: PropTypes.string,
  jobItemStartDate: PropTypes.string,
  jobItemDueDate: PropTypes.string,
  scheduledMinutes: PropTypes.number.isRequired,
  blockMinutes: PropTypes.number.isRequired,
};

ScheduleBlockTooltip.defaultProps = {
  jobItemName: null,
  jobPhaseName: null,
  jobFullName: null,
  jobCompanyName: null,
  jobItemStartDate: null,
  jobItemDueDate: null,
};

export default connect(mapState, mapDispatch)(ScheduleBlockTooltip);

const BlockTooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 2px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: var(--z-index-schedule-block-tooltip);
  padding: 8px 8px 5px;
  background-color: black;
  color: white;
  font-size: 12px;
  line-height: 14px;
  pointer-events: none;
  box-shadow: var(--box-shadow-1dp);

  td {
    font-weight: bold;
    white-space: nowrap;
    &:first-child {
      color: var(--color-gray);
      padding-right: 10px;
    }
  }
`;

const BlockDescription = styled.div`
  &:first-of-type {
    font-weight: bold;
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
`;
