import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Textarea from "../../../elements/Textarea";

const JobPostTitle = ({ value, readOnly, onChange }) => {
  const handleOnChange = (event) => {
    if (onChange) onChange(event.target.value);
  };

  return readOnly ? (
    <ViewPostTitle>{value}</ViewPostTitle>
  ) : (
    <EditPostTitle
      autoFocus
      defaultValue={value}
      onChange={handleOnChange}
      placeholder="This is your title..."
    />
  );
};

JobPostTitle.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

JobPostTitle.defaultProps = {
  value: "",
  readOnly: false,
  onChange: null,
};

export default JobPostTitle;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  border: none;
  resize: none;
  display: block;
  &::placeholder {
    color: var(--color-gray-dark);
  }
`;

const postTitleCss = `
  color: var(--color-charcoal);
  font-size: 44px;
  font-weight: 700;
  line-height: 125%;
  flex-shrink: 0;
  padding: 20px 20px 0;
`;

const EditPostTitle = styled(StyledTextarea)`
  padding: 0;
  ${postTitleCss};
`;

const ViewPostTitle = styled.div`
  user-select: text;
  ${postTitleCss};
`;
