import { call, put } from "redux-saga/effects";
import { deleteJobMilestoneAPI } from "../../../lib/API/jobMilestoneAPI";
import {
  ENTITIES_RECEIVED,
  JOB_MILESTONE_DELETE,
  JOB_MILESTONE_DELETING,
  JOB_MILESTONE_DELETED,
  JOB_MILESTONE_DELETE_ERROR
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* deleteMilestone(action) {
  try {
    const { id } = action.payload;

    yield put(createAction(JOB_MILESTONE_DELETING, action.payload));

    const { data } = yield call(deleteJobMilestoneAPI, id);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(JOB_MILESTONE_DELETED, action.payload));
  } catch (error) {
    yield put(
      createAction(JOB_MILESTONE_DELETE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchDeleteMilestone() {
  yield takeLatestBy(
    [JOB_MILESTONE_DELETE],
    deleteMilestone,
    action => action.payload.id
  );
}
