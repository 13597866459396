import { useSelector } from "react-redux";
import { timeAllocationMethodObj } from "st-shared/entities";

import { getTotalUsedMinutes as getJobItemTotalUsedMinutes } from "../../../lib/entities/jobItemEntity";
import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes as getJobItemUserTotalUsedMinutes,
} from "../../../lib/entities/jobItemUserEntity";
import {
  convertMinutesToHours,
  convertMinutesToTimeHM,
} from "../../../lib/time";
import { selectJobItem } from "../jobItem";
import { selectJobItemUser } from "./index";

export const getInitialItemUserHoursInput = (jobItemUser) =>
  convertMinutesToHours(getTotalPlannedMinutes(jobItemUser));

export const selectJobItemUserHoursInput = (
  state,
  { jobItemUserId, isFocused, inputValue }
) => {
  const jobItemUser = selectJobItemUser(state, { jobItemUserId });
  const { jobItemId } = jobItemUser;
  const jobItem = selectJobItem(state, { jobItemId });
  const timeAllocationMethod = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  );

  const totalUsedMinutes = convertMinutesToTimeHM(
    getJobItemUserTotalUsedMinutes(jobItemUser)
  );
  const totalPlanedMinutes = convertMinutesToTimeHM(
    getTotalPlannedMinutes(jobItemUser)
  );

  let value = "";
  let placeholder = "";
  let warning = false;

  if (isFocused) {
    value = inputValue;
    if (timeAllocationMethod.isPeople() && inputValue.length === 0) {
      placeholder = "0h";
    }
  } else if (timeAllocationMethod.isPeople()) {
    if (getJobItemTotalUsedMinutes(jobItem) > 0) {
      value = `${totalUsedMinutes} / ${totalPlanedMinutes}`;
      warning =
        getTotalPlannedMinutes(jobItemUser) > 0 &&
        getJobItemUserTotalUsedMinutes(jobItemUser) >
          getTotalPlannedMinutes(jobItemUser);
    } else {
      value = totalPlanedMinutes;
    }
  } else if (getJobItemTotalUsedMinutes(jobItem) > 0) {
    value = totalUsedMinutes;
  } else {
    value = "";
  }

  return [value, placeholder, warning];
};

export const useJobItemUserHoursInput = (
  jobItemUserId,
  isFocused,
  inputValue
) =>
  useSelector((state) =>
    selectJobItemUserHoursInput(state, {
      jobItemUserId,
      isFocused,
      inputValue,
    })
  );
