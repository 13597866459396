import { produce } from "immer";
import { get, setWith } from "lodash-es";

import { pushOnce } from "../../../lib/arrays";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
} from "../../../lib/constants";
import { getJobPhaseId } from "../../../lib/entities/jobItemEntity";
import createCrossReducer from "../../helpers/createCrossReducer";
import {
  selectJobItemDependancyChildJobItemById,
  selectJobItemDependancyIdsByChildJobItemId,
  selectJobItemDependancyIdsByParentJobItemId,
  selectJobItemDependancyParentJobItemById,
} from "../../selectors/jobItemDependancySelectors";
import { selectJobItemIdsByJobPhaseId } from "../../selectors/jobItemSelectors";

const receiveEntitiesReducer = (state, action) =>
  produce(state, (draft) => {
    const touchedJobPhaseIds = get(
      action.payload,
      ENTITY_NAME_JOB_PHASES,
      []
    ).map((entity) => entity.id);

    get(action.payload, ENTITY_NAME_JOB_ITEMS, []).forEach((jobItem) => {
      if (getJobPhaseId(jobItem))
        pushOnce(touchedJobPhaseIds, getJobPhaseId(jobItem));
    });

    get(action.payload, ENTITY_NAME_JOB_ITEM_DEPENDANCIES, []).forEach(
      ({ id }) => {
        const parentJobItem = selectJobItemDependancyParentJobItemById(state, {
          id,
        });
        const childJobItem = selectJobItemDependancyChildJobItemById(state, {
          id,
        });

        if (getJobPhaseId(parentJobItem))
          pushOnce(touchedJobPhaseIds, getJobPhaseId(parentJobItem));

        if (getJobPhaseId(childJobItem))
          pushOnce(touchedJobPhaseIds, getJobPhaseId(childJobItem));
      }
    );

    touchedJobPhaseIds.forEach((id) => {
      const jobItemIds = selectJobItemIdsByJobPhaseId(state, { id });

      let hasDependancies = false;

      jobItemIds.forEach((jobItemId) => {
        const childJobItemDependancies =
          selectJobItemDependancyIdsByChildJobItemId(state, { jobItemId });

        if (childJobItemDependancies.length) {
          hasDependancies = true;
          return;
        }

        const parentJobItemDependancies =
          selectJobItemDependancyIdsByParentJobItemId(state, { jobItemId });

        if (parentJobItemDependancies.length) hasDependancies = true;
      });

      setWith(
        draft,
        `entities.jobPhases.hasDependancies.${id}`,
        hasDependancies,
        Object
      );
    });
  });

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntitiesReducer,
});
