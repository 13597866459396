import type { TSavedSegment } from "@streamtimefe/entities";
import { EntityClass } from "@streamtimefe/entities";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import {
  Button,
  Icon,
  IconSize,
  MdPersonAdd,
  Tooltip,
} from "st-shared/components";
import type { TEntityId } from "st-shared/entities/Entity";
import { modelSetParser } from "st-shared/lib/webapi";
import { BranchIcon, UserIcon } from "st-shared/module";
import {
  savedSegmentEntityStore,
  useBranchEntities,
  useSavedSegmentEntity,
  useSavedSegmentHasEditAccess,
  useSavedSegmentHasViewAccessLists,
  useUserEntities,
  useUserEntity,
} from "st-shared/stores";

import { IconButton } from "../../../../components/elements/Button";
import PersonAddIcon from "../../../../components/elements/Icons/PersonAddIcon";
import { ICON_SIZE } from "../../../../components/modules/StyledIcon";
import { RoutePages, routerReplace } from "../../../../lib/router";
import { sendApplyModelData } from "../../../../lib/webAppAPISender";
import { reportingStore } from "../../state/stores/reportingStore";
import { useReportingSavedSegmentEntityId } from "../../state/stores/savedSegmentSelectors";
import {
  avatarListContainer,
  avatarListWrapperCss,
  iconButtonCss,
  numberIconCss,
  overflowListCss,
  reportOwnerIconCss,
  shareAvatarIconCss,
  shareReportButtonCss,
  shareReportIconCss,
} from "./ShareReport.css";

export function ShareReport() {
  const savedSegmentId = useReportingSavedSegmentEntityId();

  if (!savedSegmentId) return null;

  return <ShareReportButton savedSegmentId={savedSegmentId} />;
}

type ShareReportButtonProps = {
  savedSegmentId: TEntityId;
};

function ShareReportButton({ savedSegmentId }: ShareReportButtonProps) {
  const savedSegment = useSavedSegmentEntity(savedSegmentId);
  const hasEditAccess = useSavedSegmentHasEditAccess(savedSegmentId);

  const accessLists = useSavedSegmentHasViewAccessLists(savedSegmentId);

  if (!savedSegment || !hasEditAccess) return null;

  if (
    accessLists &&
    (accessLists.users.length > 0 || accessLists.branches.length > 0)
  ) {
    return (
      <UsersAvatarList
        savedSegment={savedSegment}
        userIds={accessLists.users}
        branchIds={accessLists.branches}
      />
    );
  }

  return (
    <Button
      variant="primary"
      type="button"
      onClick={() => onShareClick(savedSegment)}
      className={shareReportButtonCss}
    >
      Share
      <Icon
        icon={MdPersonAdd}
        size={IconSize.Large}
        className={shareReportIconCss}
      />
    </Button>
  );
}

type UsersAvatarListProps = {
  savedSegment: TSavedSegment;
  userIds: TEntityId[];
  branchIds: TEntityId[];
};

function UsersAvatarList({
  savedSegment,
  userIds,
  branchIds,
}: UsersAvatarListProps) {
  const reportOwnerUser = useUserEntity(savedSegment.userId);

  const users = useUserEntities(userIds);
  const branches = useBranchEntities(branchIds);

  const accessList = [
    ...branches.map((branch) => ({ branch })),
    ...users.map((user) => ({ user })),
  ];

  const topAccessList = accessList.slice(0, 6);
  const overflowAccessList = accessList.slice(6);

  return (
    <div className={avatarListContainer}>
      <div className={avatarListWrapperCss}>
        {reportOwnerUser && (
          <UserIcon
            id={reportOwnerUser.id}
            name={reportOwnerUser.displayName}
            size={32}
            className={reportOwnerIconCss}
          />
        )}
        {topAccessList.map((access) => {
          if ("user" in access) {
            const { user } = access;
            return (
              <UserIcon
                key={user.id}
                id={user.id}
                name={user.displayName}
                size={30}
                className={shareAvatarIconCss}
              />
            );
          }
          if ("branch" in access) {
            const { branch } = access;
            return (
              <BranchIcon
                key={branch.id}
                id={branch.id}
                name={branch.name}
                size={16}
                className={shareAvatarIconCss}
              />
            );
          }
          return null;
        })}

        {overflowAccessList.length > 0 && (
          <Tooltip
            title={
              <ul className={overflowListCss}>
                {overflowAccessList.map((access) => {
                  if ("user" in access) {
                    return (
                      <li key={access.user.id}>{access.user.displayName}</li>
                    );
                  }
                  if ("branch" in access) {
                    return <li key={access.branch.id}>{access.branch.name}</li>;
                  }
                  return null;
                })}
              </ul>
            }
            placement="bottom"
          >
            <li className={numberIconCss}>+{overflowAccessList.length}</li>
          </Tooltip>
        )}
      </div>

      <Tooltip title="Add users" placement="bottom">
        <IconButton
          className={iconButtonCss}
          onClick={() => onShareClick(savedSegment)}
        >
          <PersonAddIcon size={ICON_SIZE.X_LARGE} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

function onShareClick(savedSegment: TSavedSegment) {
  getModalBridgeClient()?.openModal(
    ModalTypes.SavedSegmentEditAccess,
    {
      savedSegment,
    },
    {
      success(_, { modelSet }) {
        sendApplyModelData(EntityClass.SavedSegment, modelSet as any);
        const entityData = modelSetParser(
          EntityClass.SavedSegment,
          modelSet as any
        );

        savedSegmentEntityStore().listeners.receiveEntities({ entityData });

        const updatedSavedSegmentAccessType =
          savedSegmentEntityStore().helpers.getSavedSegmentAccessType(
            savedSegment.id
          );
        if (!updatedSavedSegmentAccessType) {
          reportingStore().savedSegment.actions.unsetUserPreference();
          routerReplace(RoutePages.Reporting);
        }
      },
    }
  );
}
