import { createTemporaryAuthCookie } from "@streamtimefe/utils";

import type { TEntityId } from "../../entities/Entity";
import { API } from "../api";
import { WebApiRoot } from "./webApiRoot";

export const JobsRoot = WebApiRoot + "/jobs";

export function openDownloadJobCostReportLink(jobId: TEntityId) {
  createTemporaryAuthCookie();

  const url = API.makeURL(`${JobsRoot}/${jobId}/job_cost_report`);

  window.open(url, "_blank");
}

export function openExportTimelineSummaryLink(jobId: TEntityId) {
  createTemporaryAuthCookie();

  const url = API.makeURL(`${JobsRoot}/${jobId}/job_timeline/summary_pdf`);

  window.open(url, "_blank");
}

export function openExportTimelineLink(
  jobId: TEntityId,
  contentSize: { width: number; height: number }
) {
  createTemporaryAuthCookie();

  const url = API.makeURL(
    `${JobsRoot}/${jobId}/job_timeline/timeline_pdf?timeline_width=${contentSize.width}&timeline_height=${contentSize.height}`
  );

  window.open(url, "_blank");
}
