import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiPaper from "@material-ui/core/Paper";
import { Entity } from "@streamtimefe/entities";
import { isEmpty } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ActivityEntryParentTypes,
  createActivityEntryParentType,
} from "st-shared/entities/ActivityEntryParentType";
import {
  ActivityEntryTypes,
  createActivityEntryType,
} from "st-shared/entities/ActivityEntryType";
import type { TEntityId } from "st-shared/entities/Entity";
import { useLoggedInUserId, usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  ACTIVITY_ENTRY_DELETE,
  ACTIVITY_ENTRY_FETCH_REQUEST,
  ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT,
  ACTIVITY_ENTRY_SAVE,
} from "../../../../lib/constants";
import {
  createActivityEntry,
  getMetaDataHtml,
  getMetaDataTitle,
  getParsedMetaData,
} from "../../../../lib/entities/activityEntryEntity";
import { RoutePages, routerReplace } from "../../../../lib/router";
import { entityIdType } from "../../../../lib/types/entityTypes";
import createAction from "../../../../redux/helpers/createAction";
import {
  useActivityEntryById,
  useIsActivityEntryCreatedByLoggedInUser,
  useIsDeletingActivityEntry,
  useIsSavingActivityEntry,
} from "../../../../redux/selectors/activityEntrySelectors";
import Button, { IconButton, PrimaryButton } from "../../../elements/Button";
import Flex from "../../../elements/Flex";
import CloseIcon from "../../../elements/Icons/CloseIcon";
import LoadingSpinner from "../../../elements/LoadingSpinner";
import ConfirmDialog from "../../../modules/ConfirmDialog";
import PostEditor from "../../../modules/DraftJsEditor/PostEditor";
import PostViewer from "../../../modules/DraftJsEditor/PostViewer";
import ValidationMessages from "../../../modules/ValidationMessages";
import JobPostComments from "./JobPostComments";
import JobPostTitle from "./JobPostTitle";

export type JobPostMode = "create" | "view" | "edit";

type Props = {
  jobId: TEntityId;
  activityEntryId: TEntityId;
  mode?: JobPostMode;
};

export function JobPost({ jobId, activityEntryId, mode }: Props) {
  const dispatch = useDispatch();

  const editMode = mode === "edit";

  const id = useMemo(() => {
    if (mode === "create") {
      return Entity.temporaryId();
    }
    return Entity.id(activityEntryId);
  }, [mode, activityEntryId]);

  const { canAccessCollaboration } = usePermissions();

  useEffect(() => {
    if (mode === "create" && !canAccessCollaboration) {
      close();
    }
  }, [mode]);

  const isSaving = useIsSavingActivityEntry(id);
  const isDeleting = useIsDeletingActivityEntry(id);
  const activityEntry = useActivityEntryById(id) || null;
  const loggedInUserId = useLoggedInUserId();
  const isCreatedByLoggedInUser = useIsActivityEntryCreatedByLoggedInUser(id);

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [post, setPost] = useState(null);

  const isValidMetaDataSize = useMemo(() => {
    const metaDataSize = JSON.stringify(post).length;
    return metaDataSize <= ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT;
  }, [post]);

  const formValidationMessages = (() => {
    const messages = [];

    if (!(post && post.title)) messages.push("Enter a Title for this post");

    if (!isValidMetaDataSize)
      messages.push("The content of this post is too long");

    return messages;
  })();

  const isNewPost = id < 0;

  const isReadOnly = !isNewPost && !editMode;

  const isFetching = !isNewPost && !activityEntry;

  const isFormValidated = formValidationMessages.length === 0;

  const canSave = isFormValidated && !isSaving && !isDeleting;

  const canDelete = isCreatedByLoggedInUser && !isSaving && !isDeleting;

  useEffect(() => {
    if (editMode && activityEntry && isEmpty(post)) {
      const { title, html } = getParsedMetaData(activityEntry);
      setPost({
        title,
        html,
      });
    }
  }, [editMode, activityEntry, post, setPost]);

  useEffect(() => {
    if (!isNewPost && !activityEntry) {
      dispatch(createAction(ACTIVITY_ENTRY_FETCH_REQUEST, { id }));
    }
  }, []);

  const onChangeHtml = (html) => {
    setPost((value) => ({ ...value, html }));
  };

  const onChangeTitle = (title) => {
    setPost((value) => ({ ...value, title }));
  };

  const getJobPostTitle = () => {
    return activityEntry && getMetaDataTitle(activityEntry);
  };

  const getJobPostHTML = () => {
    return activityEntry && getMetaDataHtml(activityEntry);
  };

  const save = () => {
    const metaData = JSON.stringify(post);
    const onSuccess = !isNewPost && cancel;
    const newActivityEntry = activityEntry
      ? {
          ...activityEntry,
          metaData,
          lastModifiedDatetime: new Date().toISOString(),
          lastModifiedByUserId: loggedInUserId,
        }
      : createActivityEntry({
          id,
          activityEntryType: createActivityEntryType(ActivityEntryTypes.Post),
          activityEntryParentType: createActivityEntryParentType(
            ActivityEntryParentTypes.Job
          ),
          parentEntityId: jobId,
          metaData,
          createdUserId: loggedInUserId,
        });

    if (isNewPost) close();

    dispatch(
      createAction(ACTIVITY_ENTRY_SAVE, {
        activityEntry: newActivityEntry,
        onSuccess,
      })
    );
  };

  const onDelete = () => {
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    dispatch(createAction(ACTIVITY_ENTRY_DELETE, { id, onSuccess: close }));
    setIsConfirmDeleteOpen(false);
  };

  const cancelDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const cancel = () => {
    if (editMode) view();
    else close();
  };

  const edit = () => {
    routerReplace(
      RoutePages.Jobs,
      jobId,
      `activity/post/${activityEntry.id}/edit`
    );
  };

  const view = () => {
    routerReplace(RoutePages.Jobs, jobId, `activity/post/${activityEntry.id}`);
  };

  const close = () => {
    routerReplace(RoutePages.Jobs, jobId);
  };

  const renderDialogActions = () => {
    return (
      <DialogActions>
        {isReadOnly && isCreatedByLoggedInUser && (
          <Button onClick={onDelete} disabled={!canDelete}>
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        )}
        {!isReadOnly && (
          <Button onClick={cancel} disabled={isDeleting || isSaving}>
            Cancel
          </Button>
        )}
        {isReadOnly && (
          <span>
            <Button
              onClick={edit}
              disabled={isDeleting}
              style={{ marginRight: 20 }}
            >
              Edit
            </Button>
            <PrimaryButton onClick={close} disabled={isDeleting || isSaving}>
              Close
            </PrimaryButton>
          </span>
        )}
        {!isReadOnly && (
          <ValidationMessages messages={formValidationMessages}>
            <PrimaryButton onClick={save} disabled={!canSave}>
              {isSaving ? "Saving..." : "Save"}
            </PrimaryButton>
          </ValidationMessages>
        )}
      </DialogActions>
    );
  };

  return (
    <StyledDialog PaperComponent={isNewPost ? Paper : PaperWithComments} open>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <DialogTitle>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <JobPostContent>
              <JobPostTitle
                value={getJobPostTitle()}
                readOnly={isReadOnly}
                onChange={onChangeTitle}
              />
              {isReadOnly ? (
                <PostViewer html={getJobPostHTML()} />
              ) : (
                <PostEditor
                  onChange={onChangeHtml}
                  defaultHtml={getJobPostHTML()}
                  placeholder="Start typing your post..."
                />
              )}
              {renderDialogActions()}
            </JobPostContent>
            {!isNewPost && (
              <JobPostComments key={id} id={id} activityEntry={activityEntry} />
            )}
          </DialogContent>
        </>
      )}
      {isCreatedByLoggedInUser && (
        <ConfirmDialog
          open={isConfirmDeleteOpen}
          title="Delete Post"
          confirmText="Delete"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        >
          This can’t be undone. Are you sure?
        </ConfirmDialog>
      )}
    </StyledDialog>
  );
}

JobPost.propTypes = {
  jobId: entityIdType.isRequired,
};

const Paper = styled(MuiPaper).attrs({ square: true })`
  width: 600px;
  overflow: hidden;
`;

const PaperWithComments = styled(MuiPaper).attrs({ square: true })`
  width: 80vw;
  max-width: 80vw;
  overflow: visible;
`;

const DialogTitle = styled(MuiDialogTitle)`
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background-color: var(--color-yellow-light);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  h1 {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  overflow: hidden;
  display: flex;
  padding: 0;
  border-top: var(--border-thin-bright);
`;

const JobPostContent = styled(Flex)`
  flex-direction: column;
  width: 65%;
  flex-grow: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
`;

const StyledDialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
