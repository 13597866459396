import { mapValues } from "lodash-es";
import numeral from "numeral";
import { fetchScheduleAvailabilityAPI } from "../API/scheduleAPI";
import { sendAlertError } from "../WebAppAPI";
import { handleWarning } from "../errorHandlers";

export const doFetchAvailability = async (startDate, endDate) => {
  let users = {};

  try {
    users = await fetchScheduleAvailabilityAPI(startDate, endDate);
  } catch (error) {
    try {
      sendAlertError(error.message);
    } catch (e) {
      handleWarning(e.message);
    }
  }

  return users;
};

export const parseAvailability = (
  users,
  includePaused = false,
  includeUnplanned = false
) =>
  mapValues(users, user =>
    numeral(user.workingMinutes)
      .subtract(user.personalMinutes)
      .subtract(user.completeWorkMinutes)
      .subtract(user.incompleteScheduledMinutes)
      .subtract(includePaused ? user.incompletePlanningMinutes : 0)
      .subtract(includeUnplanned ? user.incompleteUnplannedMinutes : 0)
      .value()
  );
