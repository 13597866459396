import { addDays, differenceInCalendarDays } from "../../../../../lib/dates";
import reduceChangedEntities from "../reduceChangedEntities";

const dragBlockReducer = (state, action) => {
  const { blockKey, date, hasChanged, selectedBlockKeys } = action.payload;
  let prevBaseScheduleLoggedTime = state.byId[blockKey];
  let prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;
  if (!hasChanged) return state;

  let prevStartDate = prevBaseScheduleLoggedTime.date;
  const daysToMove = differenceInCalendarDays(date, prevStartDate);
  const changedEntities = [];

  selectedBlockKeys.forEach(selectedBlockKey => {
    prevBaseScheduleLoggedTime = state.byId[selectedBlockKey];
    prevScheduleLoggedTimeIds = state.idsByBlockKey[selectedBlockKey];

    if (!prevBaseScheduleLoggedTime) return;

    prevStartDate = prevBaseScheduleLoggedTime.date;

    const targetStartDate = addDays(prevStartDate, daysToMove);

    if (prevStartDate === targetStartDate) return;

    changedEntities.push(
      ...prevScheduleLoggedTimeIds
        .map(id => state.byId[id])
        .map((entity, index) => ({
          prevEntity: entity,
          newEntity: {
            ...entity,
            date: addDays(targetStartDate, index),
            _prevBaseLoggedTimeId: selectedBlockKey
          }
        }))
    );
  });

  return reduceChangedEntities(state, changedEntities);
};

export default dragBlockReducer;
