import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  JOB_TIMELINE_FETCH_REQUEST,
  JOB_TIMELINE_HEADER_HEIGHT,
} from "../../lib/constants";
import createAction from "../../redux/helpers/createAction";
import {
  selectIsReadOnlyByJobId,
  selectJobTimelineUi,
} from "../../redux/selectors/jobTimelineSelectors";
import Flex from "../elements/Flex";
import LoadingSpinner from "../elements/LoadingSpinner";
import PageWrapper from "../elements/PageWrapper";
import JobTimelineBackground from "./JobTimelineBackground";
import JobTimelineBodyColumnCell from "./JobTimelineBodyColumnCell";
import JobTimelineEventHandlers from "./JobTimelineEventHandlers";
import JobTimelineGroupColumnCell from "./JobTimelineGroupColumnCell";
import JobTimelineHeader from "./JobTimelineHeader";
import JobTimelinePhaseGroupRow from "./JobTimelinePhaseGroupRow";
import JobTimelinePhaseRow from "./JobTimelinePhaseRow";
import JobTimelineScrollProvider from "./JobTimelineScrollProvider";
import PhaseRowsConsumer from "./JobTimelineScrollProvider/PhaseRowsConsumer";

function JobTimelinePage() {
  const params = useParams();
  const jobId = Number(params.jobId);

  const pending = useSelector(selectJobTimelineUi).pending;
  const readOnly = useSelector((state) =>
    selectIsReadOnlyByJobId(state, { jobId })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction(JOB_TIMELINE_FETCH_REQUEST, { jobId }));
  }, [jobId]);

  return (
    <PageWrapper>
      {pending && <LoadingSpinner />}
      {!pending && (
        <JobTimelineScrollProvider key={jobId} jobId={jobId}>
          <JobTimelineHeader jobId={jobId} readOnly={readOnly} />
          <JobTimelineContainer>
            <JobTimelineGroupColumnCell style={{ overflow: "hidden" }}>
              <PhaseRowsConsumer jobId={jobId}>
                {({ jobPhaseId, top, height }) => (
                  <JobTimelinePhaseGroupRow
                    key={jobPhaseId}
                    id={jobPhaseId}
                    jobId={jobId}
                    top={top}
                    height={height}
                    readOnly={readOnly}
                  />
                )}
              </PhaseRowsConsumer>
            </JobTimelineGroupColumnCell>
            <JobTimelineBodyColumnCell style={{ overflow: "hidden" }}>
              <JobTimelineBackground jobId={jobId} />
              <JobTimelineEventHandlers readOnly={readOnly} jobId={jobId}>
                <PhaseRowsConsumer jobId={jobId}>
                  {({ jobPhaseId, top, height }) => (
                    <JobTimelinePhaseRow
                      key={jobPhaseId}
                      id={jobPhaseId}
                      jobId={jobId}
                      top={top}
                      height={height}
                      readOnly={readOnly}
                    />
                  )}
                </PhaseRowsConsumer>
              </JobTimelineEventHandlers>
            </JobTimelineBodyColumnCell>
          </JobTimelineContainer>
        </JobTimelineScrollProvider>
      )}
    </PageWrapper>
  );
}

export default JobTimelinePage;

const JobTimelineContainer = styled(Flex)`
  position: relative;
  width: 100%;
  height: calc(100% - ${JOB_TIMELINE_HEADER_HEIGHT}px);
`;
