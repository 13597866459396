import moment from "moment";
import { DATE_FORMAT_DEFAULT, DAY_OF_WEEK_START } from "../constants";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "%d day",
    dd: "%d days",
    M: "%d month",
    MM: "%d months",
    y: "%d year",
    yy: "%d years"
  },
  calendar: {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "[this] dddd",
    sameElse: "DD/MM/YYYY"
  },
  week: {
    dow: DAY_OF_WEEK_START
  }
});

moment.defaultFormat = DATE_FORMAT_DEFAULT;
