import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import DraftJsContent from "./DraftJsContent";

const PostViewer = ({ html }) => (
  <ContentViewer
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

PostViewer.propTypes = {
  html: PropTypes.string.isRequired,
};

export default PostViewer;

const ContentViewer = styled(DraftJsContent)`
  min-height: 30vh;
  user-select: text;
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;
