import { isEqual } from "lodash-es";
import { call, put, select } from "redux-saga/effects";
import { saveInvoiceLineItemAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  INVOICE_LINE_ITEM_SAVE,
  INVOICE_LINE_ITEM_SAVE_ERROR,
  INVOICE_LINE_ITEM_SAVED,
  INVOICE_LINE_ITEM_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectInvoiceLineItemUi } from "../../selectors/invoiceLineItemSelectors";

function* save(action) {
  try {
    const { id, invoiceLineItem, prevInvoiceLineItem } = action.payload;

    if (isEqual(invoiceLineItem, prevInvoiceLineItem)) return;

    yield put(createAction(INVOICE_LINE_ITEM_SAVING, { id }));

    const data = yield call(saveInvoiceLineItemAPI, invoiceLineItem);

    const { isDeleting, isEditing } = yield select(selectInvoiceLineItemUi, {
      id
    });

    if (!isDeleting && !isEditing)
      yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(createAction(INVOICE_LINE_ITEM_SAVED, { id }));

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_SAVE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchInvoiceLineItemSave() {
  yield takeLatestBy(INVOICE_LINE_ITEM_SAVE, save, action => action.payload.id);
}
