import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import PersonIcon from "../../elements/Icons/PersonIcon";
import ChecklistIcon from "../../elements/Icons/custom/ChecklistIcon";
import EditUnderlineIcon from "../../elements/Icons/custom/EditUnderlineIcon";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";

const MenuActions = ({ anchorEl, closeMenu }) => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const setMenuFocused = key => {
    closeMenu();
    if (!isJobEditable) return;

    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, {
        currentFocus: {
          jobItemId,
          key
        }
      })
    );
  };

  return (
    <>
      <StyledMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: -16
        }}
      >
        <MenuHeader>
          <span>Add/Edit</span>
          <Shortcut>ctrl /</Shortcut>
        </MenuHeader>
        <StyledMenuItem
          onClick={() => setMenuFocused(FOCUS_KEYS.ITEM_NEW_USER)}
        >
          <ListItemIcon>
            <PersonIcon size={22} />
          </ListItemIcon>
          <ListItemText>Team Member</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => setMenuFocused(FOCUS_KEYS.ITEM_NEW_SUB_ITEM)}
        >
          <ListItemIcon>
            <ChecklistIcon size={20} />
          </ListItemIcon>
          <ListItemText>Checklist</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => setMenuFocused(FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION)}
        >
          <ListItemIcon>
            <EditUnderlineIcon size={18} />
          </ListItemIcon>
          <ListItemText>Additional Information</ListItemText>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

MenuActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func.isRequired
};

MenuActions.defaultProps = {
  anchorEl: null
};

export default MenuActions;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 11px;
  padding-right: 8px;
  min-width: 220px;
`;

const StyledMenu = styled(Menu)``;

const MenuHeader = styled.div`
  background-color: var(--background-edit-colour);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-gray-medium);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  height: 40px;
`;

const Shortcut = styled.div`
  font-size: 12px;
  background-color: var(--color-gray-bright);
  color: var(--color-charcoal);
  height: 24px;
  width: 42px;
  line-height: 24px;
  border-radius: 2px;
  align-self: center;
  text-align: center;
  text-transform: lowercase;
`;
