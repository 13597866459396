import { Icon, IconSize, MdErrorOutline, Tooltip } from "st-shared/components";
import { theme } from "st-shared/theme";

import { useLoggedSellRateIsNotConsistent } from "../../../../redux/selectors/jobItemUser/selectLoggedSellRateIsNotConsistent";
import { useJobItemUserId } from "../../context/JobItemUserIdContext";
import { tooltipWideTitleCss, varianceIconAbsoluteCss } from "./styles.css";

export function UserLoggedTimeVariance() {
  const jobItemUserId = useJobItemUserId();
  const notConsistent = useLoggedSellRateIsNotConsistent(jobItemUserId);

  if (!notConsistent) return null;

  return (
    <Tooltip
      title={
        <div className={tooltipWideTitleCss}>
          Time logged for this team member has been logged at a different sell
          rate than currently specified. Check your time entries for more
          information.
        </div>
      }
      placement="top"
    >
      <div className={varianceIconAbsoluteCss}>
        <Icon
          size={IconSize.Medium}
          icon={MdErrorOutline}
          color={theme.color.gray}
        />
      </div>
    </Tooltip>
  );
}
