import { JobOption, type TJobOption } from "@streamtimefe/entities";
import { keyBy } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../entities";
import { transformToOldSearchOptions } from "../../entities";
import type { TEntityId } from "../../entities/Entity";
import { getJobOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface JobOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  options: TJobOption[];
  optionsMap: Record<TEntityId, TJobOption>;
  searchOptions: SearchOption<TJobOption>[];
  load: (force?: boolean) => void;
  setOptions: (options: TJobOption[]) => void;
}

export const useJobOptionsStore = create<JobOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      options: [],
      optionsMap: {},
      searchOptions: [],
      load: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const response = await getJobOptions();
          get().setOptions(response.data);
        } catch (e: unknown) {
          console.error("loading job options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
      setOptions(options: TJobOption[]) {
        const optionsMap: Record<TEntityId, TJobOption> = keyBy(options, "id");
        const searchOptions = transformToOldSearchOptions(
          JobOption.getSearchItems(options)
        );

        set({
          loaded: true,
          loading: false,
          options,
          optionsMap,
          searchOptions,
        });
      },
    };
  }
);

export function jobOptionsStoreInit() {
  addGlobalStore(["options", "jobs"], jobOptionsStore);
}

export function jobOptionsStore() {
  return useJobOptionsStore.getState();
}

export function useJobOptionsLoaded() {
  return useJobOptionsStore((s) => s.loaded);
}

export function useJobOptionsLoading() {
  return useJobOptionsStore((s) => s.loading);
}

export function useJobOptions() {
  return useJobOptionsStore((s) => s.options);
}

export function useJobSearchOptions() {
  return useJobOptionsStore((s) => s.searchOptions);
}

export function useJobOption(entityId: TEntityId) {
  return useJobOptionsStore((s) => s.optionsMap[entityId]);
}
