import type { TColumnTooltipEnum } from "./ColumnTooltip";
import { ColumnTooltipEnum } from "./ColumnTooltip";

type TProperty = {
  description: string;
  calculation?: string;
};

const properties: Record<TColumnTooltipEnum, TProperty> = {
  [ColumnTooltipEnum.Budget]: {
    description: "The amount your client has agreed to pay for the job.",
    calculation:
      "Total of all approved quotes on the job or if no quotes are approved, the budget set for the job.",
  },
  [ColumnTooltipEnum.BudgetVariance]: {
    description: "How far the job is from the budget.",
    calculation:
      "Comparing the Total Used Sell of time and expenses with the Budget.",
  },
  [ColumnTooltipEnum.CreationDate]: {
    description: "Date the job was created.",
  },
  [ColumnTooltipEnum.DueDate]: {
    description: "Date the job is due.",
    calculation: "Latest due date of all the items on the job.",
  },
  [ColumnTooltipEnum.DueDateRelative]: {
    description: "Date the job is due.",
    calculation: "Latest due date of all the items on the job.",
  },
  [ColumnTooltipEnum.UsedExpensesCost]: {
    description: "How much the job’s expenses have cost so far.",
    calculation:
      "Total cost price for all paid and approved expenses on the job.",
  },
  [ColumnTooltipEnum.UsedExpensesProfit]: {
    description: "How much profit the job is making on expenses.",
    calculation:
      "(Total sell price for all approved and paid expenses on the job) - (Total cost price for approved and paid expenses on the job)",
  },
  [ColumnTooltipEnum.UsedExpensesSell]: {
    description: "How much we have sold our expenses for.",
    calculation: "Total sell price for approved and paid expenses on the job.",
  },
  [ColumnTooltipEnum.TotalInvoicedExTax]: {
    description: "Total amount excluding tax of all invoices on the job.",
  },
  [ColumnTooltipEnum.ProfitMargin]: {
    description: "The amount of profit on the job expressed as a percentage.",
    calculation:
      "Total Profit divided by the total of all invoices on the job.",
  },
  [ColumnTooltipEnum.StartDate]: {
    description: "The date the job started.",
    calculation: "Earliest start date for all the items on the job.",
  },
  [ColumnTooltipEnum.UsedCost]: {
    description: "How much the time logged on the job has cost so far.",
    calculation:
      "Number of hours logged on the job multiplied by team members' hourly cost rates.",
  },
  [ColumnTooltipEnum.PlannedHours]: {
    description: "Total hours for all planned items on the job.",
    calculation: "Total hours planned of paused, play and complete items.",
  },
  [ColumnTooltipEnum.TimePlannedSell]: {
    description:
      "The price you are selling all the planned time on the job for.",
    calculation:
      "Sell price for all items on the job including paused, play and done items.",
  },
  [ColumnTooltipEnum.UsedProfit]: {
    description: "The profit for the time component of the job.",
    calculation:
      "(Total hours logged x sell rate) - (Total cost of all team member hours logged based on their individual cost rates)",
  },
  [ColumnTooltipEnum.UsedSell]: {
    description: "Total sell price for all time logged on the job.",
    calculation:
      "Total hours logged x the sell rates chosen (whether by item or team member).",
  },
  [ColumnTooltipEnum.TotalCost]: {
    description: "How much the job has cost so far.",
    calculation:
      "Hours logged x team member hourly cost rates plus the cost rates for the approved and paid expenses.",
  },
  [ColumnTooltipEnum.TotalProfit]: {
    description:
      "How much profit this job has made so far, based on time and expenses.",
    calculation:
      "Total of all issued invoices ex tax for the job less Total Used Cost.",
  },
  [ColumnTooltipEnum.TotalUsedSell]: {
    description:
      "How much the job’s used sell price is, based on time and expenses.",
    calculation:
      "Time logged x hourly sell rates plus the sell price of approved and paid expenses.",
  },
  [ColumnTooltipEnum.UsedHours]: {
    description: "Total hours logged on the entire job.",
  },
  [ColumnTooltipEnum.UsedVsPlannedHours]: {
    description: "Total hours used shown alongside the planned hours.",
  },
  [ColumnTooltipEnum.TimeUsedVsPlanned]: {
    description:
      "Total hours used x billable rate shown alongside the planned hours x billable rates.",
  },
  [ColumnTooltipEnum.TotalVsBudget]: {
    description:
      "The sellable price for all the time logged and expenses used shown next to the Budget.",
    calculation:
      "Time logged x hourly sell rates plus the sell price of approved and paid expenses alongside.",
  },
  [ColumnTooltipEnum.TotalDraftInvoicesExTax]: {
    description: "Total amount excluding tax of all draft invoices on the job.",
  },
  [ColumnTooltipEnum.TotalAwaitingPaymentInvoicesExTax]: {
    description:
      "Total amount excluding tax of all awaiting payment invoices on the job.",
  },
  [ColumnTooltipEnum.TotalPaidInvoicesExTax]: {
    description: "Total amount excluding tax of all paid invoices on the job.",
  },
  [ColumnTooltipEnum.TotalIssuedInvoicesExTax]: {
    description:
      "Total amount ex tax of all invoices issued to your client on the job.",
    calculation:
      "The total of all Awaiting Payment invoices plus all Paid invoices.",
  },
  [ColumnTooltipEnum.PlannedExpensesCost]: {
    description: "How much the job’s expenses are planned to cost.",
    calculation:
      "Total cost price for all draft, paid and approved expenses on the job.",
  },
  [ColumnTooltipEnum.PlannedExpensesSell]: {
    description: "How much we plan to sell our expenses for.",
    calculation:
      "Total sell price for all expenses on the job, excluding declined.",
  },
  [ColumnTooltipEnum.TotalPlannedSell]: {
    description:
      "How much the job’s planned sell price is, based on items and expenses.",
    calculation:
      "The total sell price on the job for all items and all expenses, excluding declined.",
  },
  [ColumnTooltipEnum.TotalUsedVsPlanned]: {
    description:
      "Total used sell price based on time and expenses, alongside total planned sell price based on time and expenses.",
  },
  [ColumnTooltipEnum.UsedVsPlannedExpenses]: {
    description:
      "Total used sell price of expenses, alongside total planned sell price of expenses.",
  },
  [ColumnTooltipEnum.UsedVsPlannedExpensesCost]: {
    description:
      "Total used cost price of expenses, alongside total planned cost price of expenses.",
  },
  [ColumnTooltipEnum.LatestComment]: {
    description: "Latest comment in the job activity log.",
  },
};

export function getDescription(columnTooltip: TColumnTooltipEnum) {
  return properties[columnTooltip].description;
}

export function getCalculation(columnTooltip: TColumnTooltipEnum) {
  return properties[columnTooltip].calculation;
}
