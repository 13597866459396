import type { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import MuiTooltip from "@material-ui/core/Tooltip";
import { clsx } from "clsx";

import { tooltipArrowCss, tooltipCss } from "./Tooltip.css";

export interface TooltipProps extends MuiTooltipProps {
  tooltipClassName?: string;
  arrowClassName?: string;
}

export function Tooltip({
  tooltipClassName,
  arrowClassName,
  classes,
  ...props
}: TooltipProps) {
  return (
    <MuiTooltip
      arrow
      classes={{
        tooltip: clsx(tooltipCss, tooltipClassName),
        arrow: clsx(tooltipArrowCss, arrowClassName),
        ...classes,
      }}
      {...props}
    />
  );
}
