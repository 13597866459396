export default (state, changedEntities) => {
  const nextState = {
    ...state
  };

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    if (prevEntity && !newEntity) delete nextState[prevEntity.id];
    if (newEntity) nextState[newEntity.id] = newEntity;
  });

  return nextState;
};
