import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { JOB_TIMELINE_BAR_MIN_WIDTH } from "../../../lib/constants";
import { selectJobTimelineZoomLevel } from "../../../redux/selectors/jobTimelineSelectors";
import Flex from "../../elements/Flex";

const mapState = state => ({
  zoomLevel: selectJobTimelineZoomLevel(state)
});

const JobTimelineBarRect = ({
  zoomLevel,
  left,
  width,
  className,
  ...props
}) => (
  <Wrapper
    style={{
      left,
      width,
      ...(zoomLevel < 2 && {
        padding: 0
      })
    }}
    className={className}
    {...props}
  />
);

JobTimelineBarRect.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  className: PropTypes.string,
  phase: PropTypes.bool
};

JobTimelineBarRect.defaultProps = {
  className: "",
  phase: false
};

export default connect(mapState)(JobTimelineBarRect);

const Wrapper = styled(Flex)`
  position: absolute;
  min-width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
  margin: ${props => (props.phase ? 7 : 10)}px 0;
  padding: 0;
  transition: left 0.2s, width 0.2s, padding 0.2s;
`;
