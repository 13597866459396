import type { DependencyList, RefObject } from "react";
import { useEffect } from "react";

export function useResizeObserver<T extends Element>(
  ref: RefObject<T>,
  cb: (entry: ResizeObserverEntry) => void,
  deps: DependencyList | undefined = []
) {
  useEffect(() => {
    let resizeObserver: ResizeObserver | undefined;
    if (ref.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        cb(entries[0]);
      });
      resizeObserver.observe(ref.current);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, deps);
}
