import isError from "lodash-es/isError";
import { put, takeLatest, call } from "redux-saga/effects";
import { openCreateJobItemModal } from "../../../lib/WebAppAPI/schedule";
import {
  SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL,
  SCHEDULE_JOB_PHASE_EXPAND
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* openCreateJobItem(action) {
  try {
    const { jobId } = action.payload;

    const jobItemEntity = yield call(openCreateJobItemModal, jobId);

    if (jobItemEntity.jobPhaseId)
      yield put(
        createAction(SCHEDULE_JOB_PHASE_EXPAND, {
          id: jobItemEntity.jobPhaseId
        })
      );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchOpenCreateJobItem() {
  yield takeLatest(
    [SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL],
    openCreateJobItem
  );
}
