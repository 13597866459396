import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_BAR,
  DATA_ATTRIBUTE_JOB_ITEM_DAYS,
  JOB_TIMELINE_BAR_HOVER_MIN_WIDTH,
  JOB_TIMELINE_BAR_MIN_WIDTH,
  JOB_TIMELINE_ITEM_BAR_HEIGHT,
  JOB_TIMELINE_ITEM_ROW_HEIGHT
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import {
  getJobItemStatusIconClass,
  getJobItemStatusId,
  getTimelineEndDate,
  getTimelineStartDate,
  jobItemEntityType
} from "../../../lib/entities/jobItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";
import {
  selectIsCreatingDependancyForJobItem,
  selectIsDraggingJobItem,
  selectIsResizingJobItem
} from "../../../redux/selectors/jobItemUiSelectors";
import CapturePointerElement from "../../elements/CapturePointerElement";
import SegmentsByJobItem from "../../modules/JobItemDependancies/SegmentsByJobItem";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import Anchor from "../JobTimelineScrollProvider/Anchor";
import JobTimelineRowConsumer from "../JobTimelineScrollProvider/JobTimelineRowConsumer";
import JobItemBarDependancyHandle, {
  DependancyHandle
} from "./JobItemBarDependancyHandle";
import JobItemBarResizeHandle, {
  ResizeHandleWrapper
} from "./JobItemBarResizeHandle";
import { JobTimelineItemBar } from "./JobTimelineBar";
import JobTimelineBarRect from "./JobTimelineBarRect";

const jobAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB);
const jobItemAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM);
const barAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_BAR);
const daysAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_DAYS);

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props),
  isDragging: selectIsDraggingJobItem(state, props),
  isResizing: selectIsResizingJobItem(state, props),
  isCreatingDependancy: selectIsCreatingDependancyForJobItem(state, props)
});

const mapDispatch = null;

class JobTimelineItem extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isResizing: PropTypes.bool.isRequired,
    isCreatingDependancy: PropTypes.bool.isRequired,
    jobItem: jobItemEntityType.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired
  };

  get hasStartAndEndDate() {
    const { jobItem } = this.props;
    return jobItem.estimatedStartDate && jobItem.estimatedEndDate;
  }

  get canAddStartDateDependancies() {
    const { jobItem, readOnly, isLocked } = this.props;
    return !readOnly && !isLocked && jobItem.estimatedStartDate;
  }

  get canAddEndDateDependancies() {
    const { jobItem, readOnly, isLocked } = this.props;
    return !readOnly && !isLocked && jobItem.estimatedEndDate;
  }

  get canResizeLeft() {
    const { jobItem, readOnly, isLocked } = this.props;
    return (
      !readOnly &&
      !isLocked &&
      (this.hasStartAndEndDate || !jobItem.estimatedStartDate)
    );
  }

  get canResizeRight() {
    const { jobItem, readOnly, isLocked } = this.props;
    return (
      !readOnly &&
      !isLocked &&
      (this.hasStartAndEndDate || !jobItem.estimatedEndDate)
    );
  }

  get canMove() {
    const { jobItem, readOnly, isLocked } = this.props;
    return (
      !readOnly &&
      !isLocked &&
      (jobItem.estimatedStartDate || jobItem.estimatedEndDate)
    );
  }

  get wrapperClassName() {
    const {
      jobItem,
      isDragging,
      isResizing,
      isCreatingDependancy,
      readOnly
    } = this.props;
    const classes = [];
    if (isDragging) classes.push("dragging");
    if (isResizing) classes.push("resizing");
    if (isCreatingDependancy) classes.push("isCreatingDependancy");
    if (readOnly) classes.push("readOnly");
    if (!readOnly && !jobItem.estimatedStartDate && !jobItem.estimatedEndDate)
      classes.push("paintable");
    return classes.join(" ");
  }

  getBarContainerClassName(width, classes) {
    if (this.canMove) classes.push("canMove");
    if (width <= JOB_TIMELINE_BAR_HOVER_MIN_WIDTH) classes.push("short");
    return classes.join(" ");
  }

  get barClassName() {
    const { jobItem, isLocked } = this.props;
    const classes = [getJobItemStatusIconClass(getJobItemStatusId(jobItem))];

    if (isLocked) classes.push("locked");

    return classes.join(" ");
  }

  render() {
    const { id, jobItem } = this.props;
    const { jobId } = jobItem;

    return (
      <JobTimelineItemWrapper
        className={this.wrapperClassName}
        {...{
          [jobItemAttrName]: id
        }}
      >
        <JobTimelineRowConsumer
          barStartDate={getTimelineStartDate(jobItem)}
          barEndDate={getTimelineEndDate(jobItem)}
          startDate={jobItem.estimatedStartDate}
          dueDate={jobItem.estimatedEndDate}
        >
          {({ left, days, width, classes }) => (
            <Anchor>
              <JobTimelineBarRect
                left={left}
                width={width}
                className="jobTimelineItemRect"
              >
                <JobItemBarContainer
                  className={this.getBarContainerClassName(width, classes)}
                  {...{
                    [jobAttrName]: jobId,
                    [barAttrName]: id,
                    [daysAttrName]: days
                  }}
                >
                  <JobTimelineItemBar id={id} className={this.barClassName} />
                  {this.canResizeLeft && <JobItemBarResizeHandle left />}
                  {this.canResizeRight && <JobItemBarResizeHandle right />}
                  {this.canAddStartDateDependancies && (
                    <JobItemBarDependancyHandle left />
                  )}
                  {this.canAddEndDateDependancies && (
                    <JobItemBarDependancyHandle right />
                  )}
                </JobItemBarContainer>
              </JobTimelineBarRect>
            </Anchor>
          )}
        </JobTimelineRowConsumer>
        <Anchor>
          <SegmentsByJobItem
            Context={JobTimelineScrollContext}
            jobId={jobId}
            jobItemId={id}
          />
        </Anchor>
      </JobTimelineItemWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineItem);

const JobItemBarContainer = styled(CapturePointerElement)`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_ITEM_BAR_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.startOnly {
    justify-content: flex-start;
    ${JobTimelineItemBar} {
      width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
    }
  }

  &.endOnly {
    justify-content: flex-end;
    ${JobTimelineItemBar} {
      width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
    }
  }
`;

export const JobTimelineItemWrapper = styled(CapturePointerElement)`
  position: relative;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  border-bottom: var(--border-thin-bright);
  cursor: pointer;

  &.readOnly ${JobItemBarContainer} {
    pointer-events: none;
  }

  &:not(.readOnly) {
    &.isCreatingDependancy,
    &.dragging,
    &.resizing,
    &:hover {
      &.paintable {
        cursor: none;
      }
      .canMove ${JobTimelineItemBar} {
        cursor: grab;
      }
      ${ResizeHandleWrapper} {
        opacity: 1;
      }
      ${DependancyHandle} {
        opacity: 1;
      }
      ${JobItemBarContainer} {
        z-index: 5;
        &.short {
          min-width: ${JOB_TIMELINE_BAR_HOVER_MIN_WIDTH}px;
          left: calc((100% - ${JOB_TIMELINE_BAR_HOVER_MIN_WIDTH}px) / 2);
        }
        &.startOnly,
        &.endOnly {
          ${JobTimelineItemBar} {
            width: 100%;
          }
        }
      }
    }
  }
`;
