import { getJobItemDescription } from "../../../lib/entities/jobItemEntity";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemSubItemIdsOrderedByJobItemId } from "../../../redux/selectors/jobItemSubItem/selectJobItemSubItemIdsOrderedByJobItemId";
import { useJobItemId } from "../context/JobItemIdContext";

export function useExtraComponentsIsHidden() {
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const additionalInformation = getJobItemDescription(jobItem) || "";
  const subItemIds = useJobItemSubItemIdsOrderedByJobItemId(jobItemId);

  return additionalInformation.length === 0 && subItemIds.length === 0;
}
