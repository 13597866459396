import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_ITEM_SET_DESCRIPTION } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getJobItemDescription } from "../../../lib/entities/jobItemEntity";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import EditUnderlineIcon from "../../elements/Icons/custom/EditUnderlineIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import useIsAdditionalInformationHidden from "../hooks/useIsAdditionalInformationHidden";
import { FullWidthBaseItem, hiddenElement, ItemTextArea } from "../styles";
import RichTextFieldEditor from "../../modules/DraftJsEditor/RichTextFieldEditor.jsx";
import DraftJsContent from "../../modules/DraftJsEditor/DraftJsContent.jsx";

const ItemAdditionalInformation = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const isHidden = useIsAdditionalInformationHidden(jobItemId);

  const [ref, focused, setFocused] = useFocusStateRef(
    {
      jobItemId,
      key: FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION,
    },
    (ref) => {
      const element = document.querySelector(
        `#${getContainerId()} .public-DraftEditor-content`
      );

      if (element) {
        ref.current = element;
      }
    }
  );

  const [description, setDescription] = useState(
    getJobItemDescription(jobItem) || ""
  );

  useEffect(() => {
    setDescription(getJobItemDescription(jobItem) || "");
  }, [jobItem]);

  const dispatch = useDispatch();

  const doJobItemDescription = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_DESCRIPTION, {
        jobItemId,
        value: description,
      })
    );
  };

  const handleChange = (newValue) => {
    if (!isJobEditable) return;
    setDescription(String(newValue));
  };

  const handleBlur = () => {
    setFocused(false);
    doJobItemDescription();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  useKeyEvent(focused, "keydown", (e) => {
    // enter
    if (e.keyCode === 13) {
      // scroll by the line height of the text area
      window.requestAnimationFrame(() => window.scrollBy(0, 20));
    }
  });

  if (!isJobEditable && description.length === 0) return null;

  function getContainerId() {
    return `job-item-${jobItemId}-additional-information`;
  }

  return (
    <Container id={getContainerId()} hidden={isHidden}>
      <IconWrapper>
        <EditUnderlineIcon size={16} />
      </IconWrapper>
      <RichTextFieldEditor
        placeholder="Add additional information"
        defaultHtml={getJobItemDescription(jobItem) || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        simplified
      />
    </Container>
  );
};

export default ItemAdditionalInformation;

const Container = styled(FullWidthBaseItem)`
  height: fit-content;
  min-height: 30px;

  ${hiddenElement}
  ${DraftJsContent} {
    font-size: 14px;
    padding: 8px 20px 6px 0;
    min-height: unset;
  }
`;

const IconWrapper = styled.div`
  height: 29px;
  padding: 1px 11px;
  line-height: 32px;
`;

const StyledItemTextArea = styled(ItemTextArea)`
  background-color: transparent;
  padding: 8px 20px 6px 0;
`;
