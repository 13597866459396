import { createModelSetParser } from "../API/modelSets";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, {
  SEND_CLEAR_SCHEDULE_FILTERS,
  SEND_CONFIRM_UNLINK_REPEATING_LOGGED_TIME,
  SEND_GO_TO_SCHEDULE_TODOS,
  SEND_OPEN_CREATE_JOB_ITEM_MODAL,
  SEND_OPEN_SCHEDULE_LOGGED_TIME_MODAL,
  SEND_SCHEDULE_VIEW_MODE,
  SEND_SET_SCHEDULE_FILTER_TEAM_MEMBERS,
} from "./index";

export const openScheduleLoggedTimeModal = (scheduleLoggedTime, days) =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_SCHEDULE_LOGGED_TIME_MODAL,
      scheduleLoggedTime,
      days
    );

    if (jQueryDeferred)
      jQueryDeferred
        .then(createModelSetParser())
        .then(({ data }) => data)
        .then(resolve)
        .fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_SCHEDULE_LOGGED_TIME_MODAL}`
        )
      );
  });

export const openCreateJobItemModal = (jobId) =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_CREATE_JOB_ITEM_MODAL,
      jobId
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_CREATE_JOB_ITEM_MODAL}`
        )
      );
  });

export const confirmUnlinkRepeatingLoggedTime = (
  repeatingLoggedTimeId,
  repeatingTaskTitle
) =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_CONFIRM_UNLINK_REPEATING_LOGGED_TIME,
      repeatingLoggedTimeId,
      repeatingTaskTitle
    );

    if (jQueryDeferred) jQueryDeferred.done(resolve).fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_CONFIRM_UNLINK_REPEATING_LOGGED_TIME}`
        )
      );
  });

export const sendSetScheduleFilterTeamMembers = (userIds) =>
  WebAppAPI.sendAction(SEND_SET_SCHEDULE_FILTER_TEAM_MEMBERS, userIds);

export const sendGoToScheduleTodos = (jobId, userIds) =>
  WebAppAPI.sendAction(SEND_GO_TO_SCHEDULE_TODOS, { jobId, userIds });

export const sendSetScheduleViewMode = (viewMode) =>
  WebAppAPI.sendAction(SEND_SCHEDULE_VIEW_MODE, viewMode);

export const sendClearScheduleFilters = () =>
  WebAppAPI.sendAction(SEND_CLEAR_SCHEDULE_FILTERS);
