import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";

export const EntityDescriptorEnum = {
  User: 4,
  Contact: 7,
  Subscription: 8,
  Company: 13,
  Job: 14,
  Quote: 15,
  JobItem: 22,
  JobItemUser: 23,
  LoggedExpense: 32,
  LoggedTime: 33,
  Invoice: 37,
  PurchaseOrder: 41,
  PriorityBoard: 55,
  JobItemRole: 61,
  JobGroup: 62,
  JobItemUserRoleUnscheduledTime: 998,
  JobItemUserRolePlannedTime: 999,
} as const;

export type TEntityDescriptorEnum = ConstEnum<typeof EntityDescriptorEnum>;

export type TEntityDescriptor = TEnumOption<TEntityDescriptorEnum>;
