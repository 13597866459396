import type { TEntityClass, TModelSet } from "@streamtimefe/entities";

import type { Entity } from "../../entities/Entity";
import type { TEntityList } from "../../types";
import { EntityListKey } from "../../types";
import { FrameworkException } from "../exception";

export function modelParser(
  entityClass: TEntityClass,
  models: Entity[]
): TEntityList {
  const name = EntityListKey[entityClass];

  if (name) return { [name]: models };

  return {};
}

export function modelSetParser(
  entityClass: TEntityClass,
  data: TModelSet | Entity
) {
  const entities: TEntityList = {};

  if ("__primaryModelClassName" in data || "__modelSet" in data) {
    const __primaryModelClassName = data.__primaryModelClassName;
    const primaryModel = data.__primaryModel;
    const modelSet = data.__modelSet || [];

    modelSet.forEach(({ className, models }) => {
      Object.assign(entities, modelParser(className, models));
    });

    if (__primaryModelClassName) {
      const entityName = EntityListKey[__primaryModelClassName];
      const models = (entities[entityName] as Entity[]) || [];

      if (
        primaryModel &&
        !models.find((model) => model.id === primaryModel.id)
      ) {
        models.unshift(primaryModel);
      }

      Object.assign(entities, modelParser(__primaryModelClassName, models));
    }
  } else {
    if (!entityClass)
      throw new FrameworkException(
        "primary model name required to parse model data"
      );

    Object.assign(entities, modelParser(entityClass, [data as Entity]));
  }
  return entities;
}

export function createModelSetParser(entityClass: TEntityClass) {
  return function (data: TModelSet | Entity) {
    return modelSetParser(entityClass, data);
  };
}
