import styled from "styled-components";

const DocumentLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: var(--color-gray-dark);
`;

export default DocumentLabel;
