import { FilterGroupTypeProperties, FilterType } from "../../types";
import { CheckboxFilter } from "./components/CheckboxFilter";
import { DateFilter } from "./components/DateFilter";
import { DropdownFilter } from "./components/DropdownFilter";
import { EntityListFilter } from "./components/EntityListFilter";
import { NumberFilter } from "./components/NumberFilter";
import { SystemFilter } from "./components/SystemFilter";
import { TextFilter } from "./components/TextFilter";
import type { FilterProps } from "./types";

export function Filter(props: FilterProps) {
  switch (FilterGroupTypeProperties[props.filterGroup.filterGroupTypeId].type) {
    case FilterType.Checkbox:
      return <CheckboxFilter {...props} />;
    case FilterType.Number:
      return <NumberFilter type="number" {...props} />;
    case FilterType.Minutes:
      return <NumberFilter type="minutes" {...props} />;
    case FilterType.Text:
      return <TextFilter {...props} />;
    case FilterType.Date:
      return <DateFilter {...props} />;
    case FilterType.Dropdown:
      return <DropdownFilter {...props} />;
    case FilterType.System:
      return <SystemFilter {...props} />;
    case FilterType.EntityList:
      return <EntityListFilter {...props} />;
  }

  return null;
}
