import { isEqual, keys } from "lodash-es";

import {
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_USER_EDIT,
} from "../../../../../lib/constants";
import { JOB_ITEM_ROLE_EDIT } from "../../../../entities/jobItemRole/actions";
import { updateEditMode } from "./updateEditMode";

const actions = [
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_USER_EDIT,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVING,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP,
  JOB_ITEM_ROLE_EDIT,
];

export function jobItemCheck(action: any, previousState: any, nextState: any) {
  if (
    actions.includes(action.type) &&
    !isEqual(previousState.ui.jobItems, nextState.ui.jobItems)
  ) {
    const jobItems = nextState.ui.jobItems;

    let isEditing = false;

    for (const key of keys(jobItems)) {
      if (jobItems[key].isEditing || jobItems[key].isSaving) {
        isEditing = true;
        break;
      }
    }

    updateEditMode(isEditing);
  }
}
