import { produce } from "immer";
import { intersection, isEmpty, mapValues, omitBy } from "lodash-es";
import { Job, JobListModel } from "st-shared/entities";

import {
  ENTITIES_RECEIVED,
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR,
} from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import { EntityReceivedAction } from "../actions";
import { ChangedEntity } from "../types";
import {
  JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED,
  JobListModelFilterResultsReceivedAction,
  JobListModelsAction,
  ScheduleJobBarDragAction,
  ScheduleJobBarDragCancelAction,
  ScheduleJobShiftDurationErrorAction,
} from "./actions";
import idsByUserIdReducer from "./reducers/idsByUserIdReducer";
import { JobListModelsState } from "./types";

const initialState: JobListModelsState = {
  byId: {},
  idsByUserId: {},
  filteredIds: [],
  filteredIdsByUserId: {},
};

function reduceChangedEntities(
  state: JobListModelsState,
  changedEntities: ChangedEntity<JobListModel.Type>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.idsByUserId = idsByUserIdReducer(state.idsByUserId, changedEntities);
  });
}

function receiveEntitiesReducer(
  state: JobListModelsState,
  action: EntityReceivedAction | JobListModelFilterResultsReceivedAction
) {
  const changedEntities = parseEntityPayload(
    state,
    action.payload.jobListModels,
    JobListModel.isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
}

function receiveJobEntitiesReducer(
  state: JobListModelsState,
  action: EntityReceivedAction
) {
  const nextState = receiveEntitiesReducer(state, action);

  const changedJobEntities = parseEntityPayload(
    nextState as any,
    action.payload.jobs,
    Job.isDeleted
  ) as {
    prevEntity?: JobListModel.Type;
    newEntity?: Job.Type;
  }[];

  const changedEntities = changedJobEntities.map(
    ({ prevEntity, newEntity }) => {
      return {
        prevEntity: (prevEntity && nextState.byId[prevEntity.id]) || undefined,
        newEntity:
          (newEntity &&
            nextState.byId[newEntity.id] &&
            JobListModel.updateWithJobValues(
              nextState.byId[newEntity.id]!,
              newEntity
            )) ||
          undefined,
      };
    }
  );

  return reduceChangedEntities(state, changedEntities);
}

function scheduleFilterSearchResultsReducer(
  state: JobListModelsState,
  action: JobListModelFilterResultsReceivedAction
) {
  const nextState = receiveEntitiesReducer(state, action);

  const filteredIds = action.payload.jobListModels.map(
    (jobListModel) => jobListModel.id
  );

  const filteredIdsByUserId = omitBy(
    mapValues(nextState.idsByUserId, (jobIds) =>
      intersection(jobIds, filteredIds)
    ),
    isEmpty
  );

  return produce(nextState, (draft) => {
    draft.filteredIds = filteredIds;
    draft.filteredIdsByUserId = filteredIdsByUserId;
  });
}

function dragJobBarReducer(
  state: JobListModelsState,
  action: ScheduleJobBarDragAction
) {
  const { jobId, daysMoved } = action.payload;
  const prevEntity = state.byId[jobId];

  if (!prevEntity || daysMoved === 0) return state;

  const changedEntities = [
    {
      prevEntity,
      newEntity: JobListModel.moveDays(prevEntity, daysMoved),
    },
  ];

  return reduceChangedEntities(state, changedEntities);
}

function undoDragJobBarReducer(
  state: JobListModelsState,
  action: ScheduleJobBarDragCancelAction | ScheduleJobShiftDurationErrorAction
) {
  const { jobId, daysMoved, hasChanged } = action.payload;

  if (!hasChanged) return state;

  const prevEntity = state.byId[jobId];

  if (!prevEntity) return state;

  const changedEntities = [
    {
      prevEntity,
      newEntity: JobListModel.moveDays(prevEntity, -daysMoved),
    },
  ];

  return reduceChangedEntities(state, changedEntities);
}

function jobListModelsReducer(
  state: JobListModelsState = initialState,
  action: JobListModelsAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return receiveJobEntitiesReducer(state, action);
    case JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED:
      return scheduleFilterSearchResultsReducer(state, action);
    case SCHEDULE_JOB_BAR_DRAG:
      return dragJobBarReducer(state, action);
    case SCHEDULE_JOB_BAR_DRAG_CANCEL:
      return undoDragJobBarReducer(state, action);
    case SCHEDULE_JOB_SHIFT_DURATION_ERROR:
      return undoDragJobBarReducer(state, action);
    default:
      return state;
  }
}

export default jobListModelsReducer;
