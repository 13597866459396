import PropTypes from "prop-types";
import { formatForeignCurrency } from "st-shared/lib";
import { UserIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import { getName } from "../../../lib/entities/roleEntity";
import { getDisplayName, getRoleId } from "../../../lib/entities/userEntity";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { entityFieldDecimalType } from "../../../lib/types/entityTypes";
import { useJobCurrency } from "../../../redux/selectors/currency/jobCurrency";
import { useRole } from "../../../redux/selectors/role";
import { useUser } from "../../../redux/selectors/user";
import Spacer from "../../elements/Spacer";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobId } from "../context/JobIdContext";
import { FullWidthBaseItem } from "../styles";

const ExtraHoursTrackedUser = ({
  userId,
  totalMinutes,
  jobCurrencyTotalExTax,
}) => {
  const jobId = useJobId();
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const { canViewJobFinancials } = usePermissions();
  const user = useUser(userId);
  const role = useRole(getRoleId(user));

  const hours = convertMinutesToTimeHM(totalMinutes);
  const rate = formatForeignCurrency(customerCurrency, {
    value: jobCurrencyTotalExTax / (totalMinutes / 60),
    currency: jobCurrency,
    format: NUMBER_FORMAT_NO_DECIMAL,
  });
  const total = formatForeignCurrency(customerCurrency, {
    value: jobCurrencyTotalExTax,
    currency: jobCurrency,
  });

  return (
    <Container>
      <UserContainer>
        <IconWrapper>
          <UserIcon
            id={user.id}
            name={user.displayName}
            size={28}
            disableTooltip
          />
        </IconWrapper>
        <UserName>
          <span>{getDisplayName(user)}</span>
        </UserName>
        {role && (
          <UserRole>
            <span>{getName(role)}</span>
          </UserRole>
        )}
      </UserContainer>
      <ItemText width={ItemColumnWidths.Hours}>{hours}</ItemText>
      {canViewJobFinancials && (
        <>
          <ItemText width={ItemColumnWidths.Rate}>{rate}</ItemText>
          <ItemTextTotal width={ItemColumnWidths.Total}>{total}</ItemTextTotal>
        </>
      )}
      <StyledSpacer h={40} w={ItemColumnWidths.Status} />
    </Container>
  );
};

ExtraHoursTrackedUser.propTypes = {
  userId: PropTypes.number.isRequired,
  totalMinutes: entityFieldDecimalType.isRequired,
  jobCurrencyTotalExTax: entityFieldDecimalType.isRequired,
};

export default ExtraHoursTrackedUser;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  & > *:not(:first-child) {
    margin-left: 2px;
  }
  &:hover {
    .StyledIconButton {
      opacity: 1;
    }
  }
`;

const UserContainer = styled(FullWidthBaseItem)`
  flex-grow: 1;
  width: unset;
  min-width: 33%;
  background-color: transparent;
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-dark);
`;

const SmallContainer = styled.div`
  position: relative;
  background-color: var(--color-jonsnow);
`;

const IconWrapper = styled(SmallContainer)`
  width: 46px;
  padding-top: 5px;
  padding-left: 10px;
`;

const UserName = styled(SmallContainer)`
  padding-left: 5px;
  flex: 1 1 0;
`;

const UserRole = styled(SmallContainer)`
  margin-left: 2px;
  flex: 1 1 0;
  padding-left: 10px;
`;

export const ItemText = styled.div`
  background-color: var(--color-jonsnow);
  width: ${(props) => props.width}px;
  padding: 3px 15px 0 5px;
  text-align: right;
  line-height: 38px;
  color: var(--color-gray-dark);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);

  width: ${(props) => props.width}px;
  @media (max-width: 1200px) {
    width: ${(props) => props.width - ItemColumnWidths.TotalSmallScreenDiff}px;
  }
`;

export const ItemTextTotal = styled(ItemText)`
  font-weight: var(--font-weight-normal);
`;

export const StyledSpacer = styled(Spacer)`
  margin-left: 0 !important;
  background-color: var(--color-jonsnow);
`;
