import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import StyledIcon from "../../modules/StyledIcon";

const RepeatIcon = ({ ...props }) => (
  <SvgIcon viewBox="-3 -1.5 24 24" {...props}>
    <defs>
      <path id="a" d="M0 .08h14.126V13.89H.001z" />
      <path id="c" d="M.05.063h15.19v13.132H.05z" />
    </defs>
    <g>
      <g transform="translate(.6 .7)">
        <path
          d="M2.088 10.255c0-3.624 2.776-6.571 6.186-6.571a5.84 5.84 0 011.842.309l-1.25.399.6 2.125 4.66-1.486L12.727.079l-2 .637.363 1.279a7.843 7.843 0 00-2.816-.531c-4.562 0-8.273 3.944-8.273 8.79 0 1.267.249 2.49.74 3.637l1.9-.917a6.894 6.894 0 01-.553-2.72"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(2.2 7.2)">
        <path
          d="M14.282.063l-1.926.981a6.14 6.14 0 01.713 2.896c0 3.483-2.883 6.317-6.427 6.317a6.486 6.486 0 01-2.59-.546l1.384-.272-.426-2.09-4.96.975.995 4.871 2.128-.418-.256-1.255c1.152.548 2.409.866 3.725.866 4.74 0 8.598-3.79 8.598-8.448 0-1.347-.331-2.689-.958-3.877"
          mask="url(#d)"
        />
      </g>
    </g>{" "}
  </SvgIcon>
);
export default StyledIcon(RepeatIcon);
