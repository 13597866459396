import React, { useRef, useState } from "react";
import { useCanReorderItemsAndPhases } from "../../../../redux/selectors/jobDetails/canReorderItemsAndPhases";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { IconButton } from "../../../elements/Button";
import ReorderIcon from "../../../elements/Icons/ReorderIcon";
import { ICON_SIZE } from "../../../modules/StyledIcon";
import Tooltip from "../../../modules/Tooltip";
import { useJobId } from "../../context/JobIdContext";
import { CenteredDiv } from "../../styles";
import ReorderItemsMenu from "./ReorderItemsMenu";

const ReorderItems = () => {
  const jobId = useJobId();
  const canReorder = useCanReorderItemsAndPhases(jobId);
  const isJobEditable = useIsJobEditable(jobId);

  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = () => setAnchorEl(ref.current);

  const closeMenu = () => setAnchorEl(null);

  if (!canReorder || !isJobEditable) return null;

  return (
    <>
      <Tooltip title={<CenteredDiv>Reorder</CenteredDiv>} placement="top">
        <IconButton ref={ref} onClick={openMenu}>
          <ReorderIcon size={ICON_SIZE.X_LARGE} />
        </IconButton>
      </Tooltip>
      <ReorderItemsMenu anchorEl={anchorEl} closeMenu={closeMenu} />
    </>
  );
};

export default ReorderItems;
