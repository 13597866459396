import type { TEntityId } from "../../entities/Entity";
import { API } from "../api";
import { WebApiRoot } from "./webApiRoot";

export const UsersRoot = WebApiRoot + "/users";

export function getUserProfileImageToken(userId: TEntityId) {
  userId = Number(userId);
  return (userId + 15) * userId;
}

export function getUserProfileImageUrl(userId: TEntityId) {
  const token = getUserProfileImageToken(userId);
  return API.makeURL(
    `${UsersRoot}/${userId}/profile_images?t=${token}&u=${userId}`
  );
}
