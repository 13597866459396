import styled from "styled-components";
import {
  JOB_TIMELINE_BAR_MIN_WIDTH,
  JOB_TIMELINE_ITEM_BAR_HEIGHT,
  JOB_TIMELINE_PHASE_BAR_HEIGHT
} from "../../../lib/constants";
import Flex from "../../elements/Flex";

const JobTimelineBar = styled(Flex)`
  position: relative;
  width: 100%;
  min-width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
  border-radius: 15px;
  z-index: 1;
  transition: padding 0.2s;
  justify-content: space-between;
  align-items: center;
`;

export const JobTimelineItemBar = styled(JobTimelineBar)`
  height: ${JOB_TIMELINE_ITEM_BAR_HEIGHT}px;
  &.planning {
    background-color: var(--color-status-pause);
  }
  &.complete {
    background-color: var(--color-status-done);
  }
  &.scheduled {
    background-color: var(--color-status-play);
  }
  &.locked {
    animation: var(--animation-pulsate);
  }
`;

export const JobTimelinePhaseBar = styled(JobTimelineBar)`
  height: ${JOB_TIMELINE_PHASE_BAR_HEIGHT}px;
  &.planning {
    background-color: var(--color-status-pause-faded);
  }
  &.complete {
    background-color: var(--color-status-done-faded);
  }
  &.scheduled {
    background-color: var(--color-status-play-faded);
  }
`;
