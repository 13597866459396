import { get } from "lodash-es";
import { createSelector } from "reselect";
import { getOrderId } from "../../../lib/entities/jobItemSubItemEntity";
import { selectJobItemSubItemIdsOrderedByJobItemId } from "./selectJobItemSubItemIdsOrderedByJobItemId";
import { selectStateJobItemSubItems } from "./index";

export const selectNextOrderIdByJobItemId = createSelector(
  [selectJobItemSubItemIdsOrderedByJobItemId, selectStateJobItemSubItems],
  (orderedSubItemIds, jobItemSubItems) => {
    if (orderedSubItemIds.length > 0) {
      const jobItemSubItem = get(
        jobItemSubItems,
        `byId.${orderedSubItemIds[orderedSubItemIds.length - 1]}`
      );
      if (jobItemSubItem) return getOrderId(jobItemSubItem) + 1;
    }
    return 1;
  }
);
