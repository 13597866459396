import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import type { ReactNode } from "react";

import type { ColorKey } from "./styles.css";
import { iconStyle, size } from "./styles.css";

export type IconProps = {
  /** The color key for the icon. */
  color?: ColorKey;
  /** The size in px for the icon. */
  size?: 64 | 30 | 24 | 20 | 16 | 14 | 12;
  /**
   * Applies a CSS class to the component, use cautiously as it may bypass style
   * encapsulation and maintainability.
   */
  className?: string;
};

export function createIcon(children: ReactNode, name: string) {
  const Icon = ({ color, size: propsSize = 24, className }: IconProps) => {
    return (
      <svg
        aria-hidden="true"
        className={clsx(iconStyle({ color }), className)}
        style={assignInlineVars({ [size]: `${propsSize}px` })}
        focusable="false"
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    );
  };

  Icon.displayName = name;

  return Icon;
}

export type IconReturnType = ReturnType<typeof createIcon>;
