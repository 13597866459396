import { call, put, select, takeLatest } from "redux-saga/effects";
import { sortInvoiceLineItemsAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  LINE_ITEMS_SORT_CONFIRM,
  INVOICE_LINE_ITEM_SORT_CONFIRM,
  INVOICE_LINE_ITEM_SORT_SAVE_ERROR,
  INVOICE_LINE_ITEM_SORT_SAVED,
  INVOICE_LINE_ITEM_SORT_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectSortedInvoiceLineItemIdsByInvoiceId } from "../../selectors/invoiceLineItemSelectors";
import { selectInvoiceUi } from "../../selectors/invoiceSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* confirmSortLineItems(action) {
  const { invoiceId, sortModeSnapshotId: snapshotId } = yield select(
    selectInvoiceUi
  );

  if (!invoiceId) return;

  const snapshot = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  try {
    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_CONFIRM, {
        invoiceId,
        snapshotId
      })
    );

    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_SAVING, {
        id: invoiceId
      })
    );

    const invoiceLineItemIds = yield select(
      selectSortedInvoiceLineItemIdsByInvoiceId,
      { invoiceId }
    );

    const data = yield call(
      sortInvoiceLineItemsAPI,
      invoiceId,
      invoiceLineItemIds
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_SAVED, {
        id: invoiceId
      })
    );

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_SAVE_ERROR, {
        id: invoiceId,
        invoiceId,
        snapshot
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsSortConfirm() {
  yield takeLatest(LINE_ITEMS_SORT_CONFIRM, confirmSortLineItems);
}
