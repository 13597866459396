import MuiGrow from "@material-ui/core/Grow";
import MuiPaper from "@material-ui/core/Paper";
import MuiPopper from "@material-ui/core/Popper";
import React from "react";
import { domNodeType } from "../../../lib/types/domTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";

const Popper = ({ children, anchorEl, ...props }) => (
  <MuiPopper
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    transition
    disablePortal
    {...props}
  >
    {({ TransitionProps }) => (
      <MuiGrow {...TransitionProps} style={{ transformOrigin: "center top" }}>
        <MuiPaper square>{children}</MuiPaper>
      </MuiGrow>
    )}
  </MuiPopper>
);

Popper.propTypes = {
  children: reactNodesType.isRequired,
  anchorEl: domNodeType
};

Popper.defaultProps = {
  anchorEl: null
};

export default Popper;
