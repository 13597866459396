import EventEmitter from "eventemitter3";

import { sharedEmitter } from "./sharedEmitter";
import { useEmitterSubscriber } from "./useEmitterSubscriber";

interface Props {
  event: string;
  callback: EventEmitter.ListenerFn;
  emitter?: EventEmitter;
}

export function EmitterSubscriber({
  event,
  callback,
  emitter = sharedEmitter,
}: Props) {
  useEmitterSubscriber(event, callback, emitter);
  return null;
}
