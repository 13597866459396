import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_EXPANDED,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import useDebounce from "../../../lib/hooks/useDebounce";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import { useOutsideClick } from "../../../lib/hooks/useOutsideClick";
import { getItemId } from "../../../lib/sortingHelpers";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  useJobDetailsCurrentFocusJobItemId,
  useJobDetailsCurrentFocusKey,
} from "../../../redux/selectors/jobDetails/ui/currentFocus";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useIsExpandedJobItem } from "../../../redux/selectors/jobItem/ui/isExpanded";
import { useIsSavingJobItem } from "../../../redux/selectors/jobItem/ui/isSaving";
import { IconButton } from "../../elements/Button";
import DragIndicatorIcon from "../../elements/Icons/custom/DragIndicatorIcon";
import DotPulseIcon from "../../elements/Icons/DotPulseIcon";
import { useJobId } from "../context/JobIdContext";
import { JobItemIdContext } from "../context/JobItemIdContext";
import { JobItemRoles } from "../JobItemRole/JobItemRoles";
import JobItemUsers from "../JobItemUser";
import AddNewButton from "../JobPlan/AddNewButton";
import { LoadingPulse } from "../styles";
import ActionsBar from "./ActionsBar";
import ExtraComponentsContainer from "./ExtraComponentsContainer";
import ItemAdditionalInformation from "./ItemAdditionalInformation";
import ItemCardActions from "./ItemCardActions";
import ItemChecklist from "./ItemChecklist";
import ItemDates from "./ItemDates";
import ItemExpander from "./ItemExpander";
import { ItemHours } from "./ItemHours";
import { ItemName } from "./ItemName";
import { ItemRate } from "./ItemRate";
import ItemStatus from "./ItemStatus";
import { ItemTotalContainer } from "./ItemTotalContainer";
import MenuActions from "./MenuActions";
import MenuActionsButton from "./MenuActionsButton";

const JobItem = ({ id }) => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const { canViewJobFinancials } = usePermissions();
  const isSaving = useIsSavingJobItem(id);
  const expanded = useIsExpandedJobItem(id);
  const currentFocusJobItemId = useJobDetailsCurrentFocusJobItemId();
  const currentFocusKey = useJobDetailsCurrentFocusKey();

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);

  const actionMenuRef = useRef();

  const openActionMenu = () => {
    if (!isJobEditable) return;
    setActionsMenuAnchorEl(actionMenuRef.current);
  };
  const debounceOpenActionMenu = useDebounce(openActionMenu, 200);

  const dispatch = useDispatch();

  const closeActionMenu = () => {
    if (!isJobEditable) return;
    setActionsMenuAnchorEl(null);
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: id,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
  };

  const disableFocus = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: null,
      })
    );
  };

  const forceExpand = () =>
    dispatch(
      createAction(JOB_ITEM_SET_EXPANDED, {
        jobItemId: id,
        isExpanded: true,
      })
    );

  useKeyEvent(
    isJobEditable && currentFocusJobItemId === id,
    "keydown",
    (e) => {
      if (e.ctrlKey && e.code === "KeyS") disableFocus();
      if (e.ctrlKey && e.code === "Slash") {
        if (!expanded) {
          forceExpand();
          debounceOpenActionMenu();
        } else {
          openActionMenu();
        }
      }
    },
    [
      disableFocus,
      openActionMenu,
      expanded,
      forceExpand,
      debounceOpenActionMenu,
    ]
  );

  const outsideClickRef = useOutsideClick(
    isJobEditable &&
      currentFocusJobItemId === id &&
      currentFocusKey !== FOCUS_KEYS.ITEM_NEW_USER &&
      currentFocusKey !== FOCUS_KEYS.ITEM_ROLE_ASSIGN,
    disableFocus,
    [disableFocus]
  );

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    attributes,
    listeners,
  } = useSortable({
    id: getItemId(id),
  });

  const showDragHandle = (!isSorting || isDragging) && isJobEditable && id > 0;

  const outOfFocus =
    currentFocusJobItemId !== null && currentFocusJobItemId !== id;

  return (
    <JobItemIdContext.Provider value={id}>
      <DragContainer
        ref={setNodeRef}
        style={{
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        $dragging={isDragging}
      >
        <Container
          id={`jobItem-${id}`}
          ref={outsideClickRef}
          $saving={isSaving}
          $showHoverButtons={!isDragging && !isSorting && !isSaving}
          $showDragHandle={showDragHandle}
          animate={{ opacity: isSaving || outOfFocus ? 0.4 : 1 }}
          transition={{
            duration: isSaving ? 0.5 : 0.25,
            ease: "easeInOut",
          }}
          initial={false}
        >
          {showDragHandle && (
            <DragHandle $dragging={isDragging} {...listeners} {...attributes}>
              <DragIndicatorIcon />
            </DragHandle>
          )}
          {isJobEditable && id > 0 && <AddNewButton fromJobItemId={id} />}
          <MainItemContainer>
            <ItemExpander />
            <ItemName />
            <ItemDates />
            <ItemHours />
            {canViewJobFinancials && (
              <>
                <ItemRate />
                <ItemTotalContainer />
              </>
            )}
            {!canViewJobFinancials && <ItemStatus />}
            {isJobEditable && <ItemCardActions />}
          </MainItemContainer>
          {expanded && (
            <ExpandedWrapper>
              <ClickOutDiv onClick={disableFocus} />
              <ExpandedContainer>
                {isJobEditable && (
                  <MenuActionsButton
                    openMenu={openActionMenu}
                    actionMenuRef={actionMenuRef}
                  />
                )}
                <JobItemUsers />
                <JobItemRoles />
                <ExtraComponentsContainer>
                  <ItemChecklist />
                  <ItemAdditionalInformation />
                </ExtraComponentsContainer>
                {isJobEditable && (
                  <>
                    <ActionsBar />
                    <MenuActions
                      anchorEl={actionsMenuAnchorEl}
                      closeMenu={closeActionMenu}
                    />
                  </>
                )}
              </ExpandedContainer>
            </ExpandedWrapper>
          )}
        </Container>
        {isSaving && (
          <LoadingPulse>
            <DotPulseIcon />
          </LoadingPulse>
        )}
      </DragContainer>
    </JobItemIdContext.Provider>
  );
};

JobItem.propTypes = {
  id: entityIdType.isRequired,
};

export default JobItem;

export const DragContainer = styled.div`
  position: relative;
  ${(props) => props.$dragging && "z-index: 10000;"};
`;

const Container = styled(motion.div)`
  position: relative;

  pointer-events: ${(props) => (props.$saving ? "none" : "auto")};

  .AddLineButtonContainer {
    top: -10px;
    height: 20px;
  }

  ${(props) =>
    props.$showHoverButtons &&
    `
  &:hover {
    .ActionsButton {
      opacity: 1;
    }
    .AddLineButton {
      opacity: 1;
    }
  }
  `}

  ${(props) =>
    props.$showDragHandle &&
    `
  &:hover {
    .DragHandle {
      opacity: 1;
    }
  }
  `}

  .StatusComponentContainer {
    background-color: white;
  }
`;

const MainItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  & > *:not(:first-child) {
    margin-left: 2px;
  }
`;

const ExpandedWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const ExpandedContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-top: 2px;
  }
`;

const ClickOutDiv = styled.div`
  width: 43px;
  height: auto;
`;

const DragHandle = styled(IconButton).attrs({
  className: "DragHandle",
})`
  width: 30px;
  height: 20px;
  position: absolute;
  left: -35px;
  top: 10px;
  align-self: center;
  color: var(--color-gray-medium);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  opacity: 0;
  ${(props) => props.$dragging && "opacity: 1 !important;"}
`;
