import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectUnplannedTimeSummary } from "./unplannedTimeSummary";

export const selectUnplannedTimeTotalMinutes = createSelector(
  [selectUnplannedTimeSummary],
  unplannedTimeSummary =>
    reduce(
      unplannedTimeSummary,
      (total, userSummary) =>
        numeral(total)
          .add(numeral(userSummary.totalMinutes).value())
          .value(),
      0
    )
);

export const useUnplannedTimeTotalMinutes = () =>
  useSelector(selectUnplannedTimeTotalMinutes);
