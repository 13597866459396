import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobGroupBreakdownStore = createSingleEntityStoreContext(
  EntityClass.JobGroupBreakdown
);

export const JobGroupBreakdownStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobGroupBreakdown,
  JobGroupBreakdownStore
);
