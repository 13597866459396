import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_INVOICES } from "../../../lib/constants";
import { selectPropsInvoiceId } from "../index";

export const selectStateInvoices = state =>
  state.entities[ENTITY_NAME_INVOICES];

export const selectInvoice = createCachedSelector(
  [selectStateInvoices, selectPropsInvoiceId],
  (invoices, invoiceId) => get(invoices, `byId.${invoiceId}`)
)(selectPropsInvoiceId);

export const useInvoice = invoiceId =>
  useSelector(state => selectInvoice(state, { invoiceId }));
