import { ReactNode, useContext } from "react";

import {
  getDaysInMonth,
  getStartOfMonthDatesInRange,
} from "../../../lib/dates";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

interface MonthsConsumerProps {
  children: (props: { date: string; left: number; width: number }) => ReactNode;
}

function MonthsConsumer({ children }: MonthsConsumerProps) {
  const {
    viewportStartDate,
    viewportEndDate,
    viewportOffsetX,
    getOffsetXAtDate,
    dayWidth,
  } = useContext(ScheduleScrollContext);

  return (
    <>
      {getStartOfMonthDatesInRange(viewportStartDate, viewportEndDate).map(
        (date: string) => {
          const left = getOffsetXAtDate(date);
          const width = getDaysInMonth(date) * dayWidth;
          const right = left + width;
          const stickyLeft = Math.max(viewportOffsetX, left);
          const stickyWidth = right - stickyLeft;
          return children({
            date,
            left: stickyLeft,
            width: stickyWidth,
          });
        }
      )}
    </>
  );
}

export default MonthsConsumer;
