import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const TextLink = ({ children, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
);

TextLink.propTypes = {
  children: PropTypes.node.isRequired
};

export default styled(TextLink)`
  color: black;
  &:hover {
    color: var(--color-charcoal);
  }
`;
