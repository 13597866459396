import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { entityIdType } from "../../../lib/types/entityTypes";
import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";
import { selectWorkingHours } from "../../../redux/selectors/userSelectors";
import Flex from "../../elements/Flex";
import Anchor from "../ScheduleScrollProvider/Anchor";
import DatesConsumer from "../ScheduleScrollProvider/DatesConsumer";

const mapState = (state, props) => ({
  isCollapsed: selectIsScheduleUserCollapsed(state, props),
  userWorkingHours: selectWorkingHours(state, props),
});

const mapDispatch = null;

class ScheduleUserNonWorkingDays extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    userWorkingHours: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  render() {
    const { userWorkingHours } = this.props;
    return (
      <Wrapper style={this.style}>
        <Anchor>
          <DatesConsumer>
            {({ dayWidth, dayOfWeek, date, offsetX }) =>
              userWorkingHours[dayOfWeek] === 0 && (
                <NonWorkingDayCell
                  key={date}
                  style={{ left: offsetX, width: dayWidth }}
                />
              )
            }
          </DatesConsumer>
        </Anchor>
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserNonWorkingDays);

const Wrapper = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const NonWorkingDayCell = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid var(--color-gray-bright);
  pointer-events: none;
  background: repeating-linear-gradient(
    45deg,
    var(--color-ygrittesnow) 0,
    var(--color-ygrittesnow) 4.7px,
    var(--color-gray) 5.3px,
    var(--color-ygrittesnow) 5.9px
  );
`;
