import { images } from "@streamtimefe/assets";
import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";
import moment from "moment";
import { useRef } from "react";
import { ChartTooltip, renderChartTooltip } from "st-shared/components";
import type { ChartJS, ChartJSAnnotation } from "st-shared/lib";
import { formatForeignCurrency, ReactChartJS } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";
import { themeRaw } from "st-shared/theme";

import { COLOR_GRAY_DARK } from "../../../../lib/constants";
import { JOB_BURN_UP_MODE } from "../../../../lib/constants/jobDetails";
import {
  getEstimatedEndDate,
  getTotalLoggedMinutes,
  isNotFinalised,
} from "../../../../lib/entities/jobEntity";
import { convertMinutesToTimeHM } from "../../../../lib/time";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobCurrencyFinalBudget } from "../../../../redux/selectors/job/selectJobCurrencyFinalBudget";
import { useJobDetailsBurnUpData } from "../../../../redux/selectors/jobDetails/ui/burnUpData";
import { useJobDetailsBurnUpMode } from "../../../../redux/selectors/jobDetails/ui/burnUpMode";
import { useJobId } from "../../context/JobIdContext";
import JumbotronChartTooltip from "./JumbotronChartTooltip";
import {
  castle,
  chartTooltipCss,
  container,
  emptyContainer,
  emptyGraph,
  relativeContainer,
} from "./JumbotronGraph.css";

function JumbotronGraph() {
  const burnUpMode = useJobDetailsBurnUpMode();

  const jobId = useJobId();
  const job = useJob(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const jobCurrencyFinalBudget = useJobCurrencyFinalBudget(jobId);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const annotations: ChartJSAnnotation.AnnotationOptions[] = [];
  const {
    labels,
    usedData,
    plannedData,
    optimalData,
    usedDataPointRadius,
    highestUsedDataPoint,
  } = useJobDetailsBurnUpData();

  if (
    (burnUpMode === JOB_BURN_UP_MODE.USED_PLANNED_SELL ||
      burnUpMode === JOB_BURN_UP_MODE.USED_PLANNED_COST) &&
    jobCurrencyFinalBudget > 0
  ) {
    annotations.push({
      type: "line",
      scaleID: "y",
      value: jobCurrencyFinalBudget,
      borderColor: themeRaw.color.graymedium,
      borderWidth: 1,
      label: {
        display: true,
        backgroundColor: themeRaw.color.graylight,
        color: themeRaw.color.charcoal,
        content: "Budget",
        position: "start",
        xAdjust: 20,
      },
    });
  }

  if (
    isNotFinalised(job) &&
    moment().isBefore(moment(labels[labels.length - 1]), "day") &&
    getTotalLoggedMinutes(job) === null
  ) {
    annotations.push({
      type: "line",
      scaleID: "x",
      value: moment().startOf("day").valueOf(),
      borderColor: "rgb(0, 188, 212)",
      borderWidth: 1.5,
    });
  }

  if (
    getEstimatedEndDate(job) &&
    moment(getEstimatedEndDate(job)).isBefore(moment(), "day")
  ) {
    annotations.push({
      type: "line",
      scaleID: "x",
      value: moment(getEstimatedEndDate(job)).valueOf(),
      borderColor: "rgb(208, 2, 27)",
      borderWidth: 1.5,
    });
  }

  const chartRef = useRef();

  const data: ChartJS.ChartData<"line"> = {
    labels: [...labels],
    datasets: [
      {
        label: "used",
        data: [...usedData],
        borderColor: "rgba(161, 210, 222, 1)",
        backgroundColor: "rgba(0, 188, 212, 0.5)",
        borderWidth: 1,
        fill: true,
        pointHitRadius: 0,
        pointHoverBackgroundColor: "#4A4A4A",
        pointHoverBorderColor: "#4A4A4A",
        pointRadius: usedDataPointRadius,
      },
      {
        label: "planned",
        data: [...plannedData],
        spanGaps: true,
        borderColor: "#868C8D",
        borderWidth: 1,
        borderDash: [3, 3],
        fill: false,
        pointRadius: 0,
      },
      {
        label: "optimal",
        data: [...optimalData],
        spanGaps: true,
        borderColor: "rgba(226,226,226, 1)",
        backgroundColor: "rgba(226,226,226, 0.7)",
        borderWidth: 2,
        fill: true,
        pointHitRadius: 0,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartJS.ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    layout: {
      padding: {
        right: 0,
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        type: "time",
        ticks: {
          padding: 9,
          maxTicksLimit: 6,
          color: COLOR_GRAY_DARK,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
        },
        time: {
          minUnit: "day",
          displayFormats: {
            month: "MMM YY",
            day: "D MMM",
          },
        },
      },
      y: {
        ticks: {
          padding: 7,
          maxTicksLimit: 6,
          color: COLOR_GRAY_DARK,
          callback: function (value) {
            if (burnUpMode === JOB_BURN_UP_MODE.USED_PLANNED_HOURS) {
              return convertMinutesToTimeHM(
                convertFlexibleDurationToMinutes(value)!
              );
            }
            return formatForeignCurrency(customerCurrency, {
              value,
              currency: jobCurrency,
            });
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        mode: "index",
        external: function (context) {
          renderChartTooltip(
            context,
            tooltipRef.current,
            JumbotronChartTooltip,
            {
              highestUsedDataPoint,
              job,
              burnUpMode,
              customerCurrency,
              jobCurrency,
            }
          );
        },
      },
    },
  };

  return (
    <div className={container}>
      {labels.length === 0 && (
        <div className={emptyContainer}>
          <img className={emptyGraph} alt="" src={images.emptyStateGraph} />
          <div>No data yet. Get busy!</div>
          <img className={castle} alt="" src={images.castleBackground} />
        </div>
      )}
      {labels.length > 0 && (
        <div className={relativeContainer}>
          <ReactChartJS.Line ref={chartRef} options={options} data={data} />
          <ChartTooltip ref={tooltipRef} className={chartTooltipCss} />
        </div>
      )}
    </div>
  );
}

export default JumbotronGraph;
