import moment from "moment";

import { DateFormats } from "../lib";

export type TDateRange = { startDate: string; endDate: string };

export const CustomDateRange = {
  ThisWeek: "this-week",
  LastWeek: "last-week",
  ThisMonth: "this-month",
  LastMonth: "last-month",
  ThisQuarter: "this-quarter",
  LastQuarter: "last-quarter",
  ThisYear: "this-year",
  LastYear: "last-year",
} as const;

export type TCustomDateRange =
  (typeof CustomDateRange)[keyof typeof CustomDateRange];

export function getDatesFromRange(range: TCustomDateRange): TDateRange {
  let startDate = "";
  let endDate = "";
  switch (range) {
    case CustomDateRange.ThisWeek:
      startDate = moment().startOf("week").format(DateFormats.Default);
      endDate = moment().endOf("week").format(DateFormats.Default);
      break;
    case CustomDateRange.LastWeek:
      startDate = moment()
        .subtract(1, "week")
        .startOf("week")
        .format(DateFormats.Default);
      endDate = moment()
        .subtract(1, "week")
        .endOf("week")
        .format(DateFormats.Default);
      break;
    case CustomDateRange.ThisMonth:
      startDate = moment().startOf("month").format(DateFormats.Default);
      endDate = moment().endOf("month").format(DateFormats.Default);
      break;
    case CustomDateRange.LastMonth:
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format(DateFormats.Default);
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .format(DateFormats.Default);
      break;
    case CustomDateRange.ThisQuarter:
      startDate = moment().startOf("quarter").format(DateFormats.Default);
      endDate = moment().endOf("quarter").format(DateFormats.Default);
      break;
    case CustomDateRange.LastQuarter:
      startDate = moment()
        .subtract(1, "quarter")
        .startOf("quarter")
        .format(DateFormats.Default);
      endDate = moment()
        .subtract(1, "quarter")
        .endOf("quarter")
        .format(DateFormats.Default);
      break;
    case CustomDateRange.ThisYear:
      startDate = moment().startOf("year").format(DateFormats.Default);
      endDate = moment().endOf("year").format(DateFormats.Default);
      break;
    case CustomDateRange.LastYear:
      startDate = moment()
        .subtract(1, "years")
        .startOf("year")
        .format(DateFormats.Default);
      endDate = moment()
        .subtract(1, "years")
        .endOf("year")
        .format(DateFormats.Default);
      break;
  }
  return { startDate, endDate };
}

export function getCustomDateRangeText(
  range: TCustomDateRange | TDateRange,
  lowercase = false,
  preText = "between"
) {
  let text = "";
  if (typeof range === "string") {
    switch (range) {
      case CustomDateRange.ThisWeek:
        text = "This week";
        break;
      case CustomDateRange.LastWeek:
        text = "Last week";
        break;
      case CustomDateRange.ThisMonth:
        text = "This month";
        break;
      case CustomDateRange.LastMonth:
        text = "Last month";
        break;
      case CustomDateRange.ThisQuarter:
        text = "This quarter";
        break;
      case CustomDateRange.LastQuarter:
        text = "Last quarter";
        break;
      case CustomDateRange.ThisYear:
        text = "This year";
        break;
      case CustomDateRange.LastYear:
        text = "Last year";
        break;
    }
    if (lowercase) {
      text = text.toLowerCase();
    }
  } else {
    text = `${preText} ${moment(range.startDate).format(
      DateFormats.DateShortMonthYear
    )} and ${moment(range.endDate).format(DateFormats.DateShortMonthYear)}`;
  }

  return text;
}
