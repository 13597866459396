import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobItemsUi } from "../../jobItem/ui";
import { selectStateJobPhasesUi } from "../../jobPhase/ui";

export const selectJobDetailsIsMakingChanges = createSelector(
  [selectStateJobItemsUi, selectStateJobPhasesUi], //   selectJobItemsWorkingByJobId, selectJobPhasesWorkingByJobId],
  // (jobItemsWorking, jobPhasesWorking) => jobItemsWorking || jobPhasesWorking
  (jobItems, jobPhases) => {
    const jobItemIds = Object.keys(jobItems);
    for (let i = 0; i < jobItemIds.length; i += 1) {
      const jobItemId = jobItemIds[i];
      const jobItem = jobItems[jobItemId];

      if (
        get(jobItem, `previousJobItem`, null) !== null ||
        get(jobItem, `isEditing`, false) ||
        get(jobItem, `isSaving`, false)
      ) {
        return true;
      }
    }

    const jobPhaseIds = Object.keys(jobPhases);
    for (let i = 0; i < jobPhaseIds.length; i += 1) {
      const jobPhaseId = jobPhaseIds[i];
      const jobPhase = jobPhases[jobPhaseId];

      if (
        get(jobPhase, `previousJobPhase`, null) !== null ||
        get(jobPhase, `isEditing`, false) ||
        get(jobPhase, `isSaving`, false)
      ) {
        return true;
      }
    }

    return false;
  }
);

export const useJobDetailsIsMakingChanges = () =>
  useSelector(selectJobDetailsIsMakingChanges);
