import React from "react";
import withForwardedRef from "../modules/withForwardedRef";

export default withForwardedRef(
  ({ forwardedRef, disabled = false, ...props }) => (
    <div
      {...(!disabled && { capturepointer: "" })}
      {...(forwardedRef && { ref: forwardedRef })}
      {...props}
    />
  )
);
