import type { TSearchSection } from "@streamtimefe/utils";
import { SearchItem } from "@streamtimefe/utils";
import { groupBy, prop } from "remeda";

import type { TJobOption } from "./JobOption";

export function getSearchItem(jobOption: TJobOption) {
  return SearchItem.create(
    jobOption.id,
    jobOption.name,
    `${jobOption.name} ${jobOption.companyName}`,
    jobOption
  );
}

export function getSearchItems(jobOptions: TJobOption[]) {
  return SearchItem.createSortedArray(jobOptions, getSearchItem);
}

export function getSectionSearchItems(
  jobOptions: TJobOption[]
): TSearchSection<TJobOption>[] {
  return Object.entries(groupBy(jobOptions, prop("companyName")))
    .map(([companyName, options]) => {
      return {
        name: companyName,
        items: getSearchItems(options),
      };
    })
    .filter((section) => section.items.length > 0);
}
