import { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { RoleIconName } from "st-shared/module";
import styled from "styled-components";

import { getTodayDate, maxDate } from "../../../lib/dates";
import { actionJobAssignRoleTime } from "../../../state/entities/job/actions";
import { IconButton } from "../../elements/Button";
import RoleAddIcon from "../../elements/Icons/custom/RoleAddIcon";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import { AssignAllRoleMenu } from "../../modules/UserRoleMenus/AssignAllRoleMenu";

interface ScheduleJobTeamMemberRoleGroupProps {
  jobId: number;
  roleId: number;
  roleName: string;
}

function ScheduleJobTeamMemberRoleGroup({
  jobId,
  roleId,
  roleName,
}: ScheduleJobTeamMemberRoleGroupProps) {
  const dispatch = useDispatch();
  const { viewportStartDate, viewportEndDate } = useContext(
    ScheduleScrollContext
  );
  const ref = useRef(null);
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);

  function openAssignMenu() {
    setAssignMenuOpen(true);
  }

  function closeAssignMenu() {
    setAssignMenuOpen(false);
  }

  function getDefaultStartDate() {
    return maxDate(viewportStartDate, getTodayDate());
  }

  function getDefaultEndDate() {
    return maxDate(getDefaultStartDate(), viewportEndDate);
  }

  function assignUser(userId: number) {
    dispatch(actionJobAssignRoleTime(jobId, roleId, userId));
    closeAssignMenu();
  }

  return (
    <>
      <Wrapper ref={ref}>
        <RoleIconName id={roleId} fontSize={14} />
        <Actions>
          <IconButton onClick={openAssignMenu}>
            <RoleAddIcon />
          </IconButton>
        </Actions>
      </Wrapper>
      {assignMenuOpen && (
        <AssignAllRoleMenu
          anchorEl={ref.current}
          assignUser={assignUser}
          close={closeAssignMenu}
          role={{ id: roleId, name: roleName }}
          defaultStartDate={getDefaultStartDate()}
          defaultEndDate={getDefaultEndDate()}
          transformOrigin={{
            horizontal: 29,
            vertical: "top",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}
    </>
  );
}

export default ScheduleJobTeamMemberRoleGroup;

const Actions = styled.div`
  display: flex;
  width: 0;
  opacity: 0;
  transition:
    opacity 0.2s,
    width 0.2s;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  padding: 10px 30px 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:hover {
    ${Actions} {
      width: 30px;
      opacity: 1;
    }
  }
`;
