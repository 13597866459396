import { connect } from "react-redux";
import {
  selectPurchaseOrderLineItemById,
  selectSubTotalByPurchaseOrderIdPurchaseOrderLineItemId
} from "../../../redux/selectors/purchaseOrderLineItemSelectors";
import DocumentHeadingSubtotal from "../../modules/CommercialDocuments/DocumentLineItems/DocumentHeadingSubtotal";

const mapState = (state, props) => ({
  documentLineItem: selectPurchaseOrderLineItemById(state, props),
  subTotal: selectSubTotalByPurchaseOrderIdPurchaseOrderLineItemId(state, props)
});

export default connect(mapState)(DocumentHeadingSubtotal);
