import { UserDisplayName } from "st-shared/module";
import styled from "styled-components";

import { entityIdType } from "../../../../../lib/types/entityTypes";
import { reactNodesType } from "../../../../../lib/types/reactTypes";
import Flex from "../../../../elements/Flex";
import SystemMessageText from "../../../../elements/Typography/SystemMessageText";

const SystemMessage = ({ userId, children }) => (
  <Wrapper>
    <SystemMessageText>
      {userId && (
        <b>
          <UserDisplayName userId={userId} />
        </b>
      )}
      {children}
    </SystemMessageText>
  </Wrapper>
);

SystemMessage.propTypes = {
  children: reactNodesType.isRequired,
  userId: entityIdType,
};

SystemMessage.defaultProps = {
  userId: null,
};

export default SystemMessage;

const Wrapper = styled(Flex)`
  width: 100%;
  padding: 2px 0;
  flex-shrink: 0;
`;
