import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobItemId } from "../../index";

export const selectStateJobItemsUi = state => state.ui.jobItems;

export const selectJobItemUi = createCachedSelector(
  [selectStateJobItemsUi, selectPropsJobItemId],
  (jobItems, jobItemId) => jobItems[jobItemId]
)(selectPropsJobItemId);

export const useJobItemUi = jobItemId =>
  useSelector(state => selectJobItemUi(state, { jobItemId }));
