import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const BranchIcon = props => (
  <SvgIcon {...props} viewBox="0 0 48 48">
    <path
      d="M29.7,22.4V9.5L24,4l-5.7,5.5v4.6H8V42h32V22.4H29.7z M17,38.6h-4.5v-4.5H17V38.6z M17,30.3h-4.5v-4.5H17V30.3z M17,22.4
	h-4.5v-4.5H17V22.4z M26.3,38.6h-4.5v-4.5h4.5V38.6z M26.3,30.3h-4.5v-4.5h4.5V30.3z M26.3,22.4h-4.5v-4.5h4.5V22.4z M26.3,14.1
	h-4.5V9.6h4.5V14.1z M35.5,38.6H31v-4.5h4.5V38.6z M35.5,30.3H31v-4.5h4.5V30.3z"
    />
  </SvgIcon>
);

export default StyledIcon(BranchIcon);
