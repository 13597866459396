export const extractId = id => Number(id.slice(2));

export const isItem = id => id.charAt(0) === "i";

export const isPhase = id => id.charAt(0) === "p";

export const getItemId = id => `i_${id}`;

export const getPhaseId = id => `p_${id}`;

export const getNext = (array, index, check) => {
  for (let i = index + 1; i < array.length; i += 1) {
    if (check(array[i])) return extractId(array[i]);
  }
  return null;
};

export const getPrevious = (array, index, check) => {
  for (let i = index - 1; i >= 0; i -= 1) {
    if (check(array[i])) return extractId(array[i]);
  }
  return null;
};

export const getNextItem = (array, index) => getNext(array, index, isItem);

export const getPreviousItem = (array, index) =>
  getPrevious(array, index, isItem);

export const getNextPhase = (array, index) => getNext(array, index, isPhase);

export const getPreviousPhase = (array, index) =>
  getPrevious(array, index, isPhase);
