import isError from "lodash-es/isError";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { sendOpenCreateTaxRateModal } from "../../../lib/WebAppAPI/taxRate";
import {
  ENTITIES_RECEIVED,
  QUOTE_LINE_ITEM_SAVE,
  QUOTE_LINE_ITEM_TAX_RATE_CREATE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectQuoteLineItemById,
  selectQuoteLineItemQuoteIdById,
  selectQuoteLineItemUi
} from "../../selectors/quoteLineItemSelectors";
import { selectQuoteJobBranchIdById } from "../../selectors/quoteSelectors";

function* openCreate(action) {
  try {
    const { id } = action.payload;

    const quoteId = yield select(selectQuoteLineItemQuoteIdById, { id });

    const branchId = yield select(selectQuoteJobBranchIdById, { id: quoteId });

    const { taxRate, data } = yield call(sendOpenCreateTaxRateModal, branchId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    if (id > 0) {
      const { isDeleting } = yield select(selectQuoteLineItemUi, { id });

      if (isDeleting) return;
    }

    const prevQuoteLineItem = yield select(selectQuoteLineItemById, { id });

    const quoteLineItem = {
      ...prevQuoteLineItem,
      taxName: taxRate.name,
      taxRate: taxRate.rate
    };

    yield put(
      createAction(QUOTE_LINE_ITEM_SAVE, {
        id,
        quoteLineItem,
        prevQuoteLineItem
      })
    );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchQuoteLineItemCreateTaxRate() {
  yield takeLatest(QUOTE_LINE_ITEM_TAX_RATE_CREATE, openCreate);
}
