import { useParams } from "react-router-dom";
import { TEntityId } from "st-shared/entities/Entity";

export function useParamActivityEntryId() {
  const params = useParams();
  if (
    typeof params.activityEntryId === "undefined" ||
    String(params.activityEntryId).length === 0
  ) {
    return null;
  }
  return Number(params.activityEntryId) as TEntityId;
}
