import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";

const ListItemIcon = ({ children, ...props }) => (
  <MuiListItemIcon {...props}>{children}</MuiListItemIcon>
);

ListItemIcon.propTypes = {
  children: reactNodesType.isRequired
};

ListItemIcon.defaultProps = {};

export default styled(ListItemIcon)`
  color: var(--color-gray-dark);
  min-width: 38px;
`;
