import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectInvoiceIdsByJobId } from "./invoiceIdsByJobId";
import { selectStateInvoices } from "./index";

export const selectInvoiceUpcomingDates = createCachedSelector(
  [selectStateInvoices, selectInvoiceIdsByJobId],
  (invoices, invoiceIds) => {
    const upcomingDates = [];

    invoiceIds
      .map(id => get(invoices, `upcomingDatesById.${id}`))
      .forEach(invoiceUpcomingDate => {
        invoiceUpcomingDate.forEach(upcomingDate => {
          upcomingDates.push(upcomingDate);
        });
      });

    return upcomingDates;
  }
)(selectPropsJobId);

export const useInvoiceUpcomingDates = jobId =>
  useSelector(state => selectInvoiceUpcomingDates(state, { jobId }));
