import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatCurrency } from "st-shared/lib";

import { currencyEntityType } from "../../../lib/entities/currencyEntity";
import { entityFieldDecimalType } from "../../../lib/types/entityTypes";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";

const mapState = (state) => ({
  customerCurrency: selectCustomerCurrency(state),
});

const Currency = ({
  customerCurrency,
  value,
  currency,
  hideLongSymbols,
  hideCurrencyCode,
  format,
}) => {
  if (currency) {
    const formattedValue = formatCurrency(
      value,
      currency.symbol,
      format,
      hideLongSymbols
    );

    if (hideCurrencyCode || currency.id === customerCurrency.id)
      return formattedValue;

    return `(${currency.id}) ${formattedValue}`;
  }

  return formatCurrency(
    value,
    customerCurrency.symbol,
    format,
    hideLongSymbols
  );
};

Currency.propTypes = {
  customerCurrency: currencyEntityType.isRequired,
  value: entityFieldDecimalType.isRequired,
  currency: currencyEntityType,
  format: PropTypes.string,
  hideLongSymbols: PropTypes.bool,
  hideCurrencyCode: PropTypes.bool,
};

Currency.defaultProps = {
  currency: null,
  format: undefined,
  hideLongSymbols: false,
  hideCurrencyCode: false,
};

export default connect(mapState)(Currency);
