import { difference, isPlainObject, unique } from "remeda";

import type { TEntityId } from "../../core";
import type { TUser } from "../../entities";
import {
  SavedSegmentAccessTypeEnum,
  type TEncodedSavedSegmentAccess,
  type TSavedSegmentAccess,
  type TSavedSegmentAccessTypeEnum,
} from "./SavedSegmentAccess";

type Encoded = {
  uv?: TEntityId[];
  ue?: TEntityId[];
  bv?: TEntityId[];
  be?: TEntityId[];
};

export function encode(
  savedSegmentAccess: TSavedSegmentAccess
): TEncodedSavedSegmentAccess {
  const encoded: Encoded = {
    uv: savedSegmentAccess.viewUserIds,
    ue: savedSegmentAccess.editUserIds,
    bv: savedSegmentAccess.viewBranchIds,
    be: savedSegmentAccess.editBranchIds,
  };

  return JSON.stringify(encoded) as TEncodedSavedSegmentAccess;
}

export function decode(
  encodedSavedSegmentAccess: TEncodedSavedSegmentAccess
): TSavedSegmentAccess {
  if (encodedSavedSegmentAccess) {
    try {
      const parsed: unknown = JSON.parse(encodedSavedSegmentAccess);

      if (isPlainObject(parsed)) {
        const encoded = parsed as Encoded;
        return {
          viewUserIds: encoded?.uv ?? [],
          editUserIds: encoded?.ue ?? [],
          viewBranchIds: encoded?.bv ?? [],
          editBranchIds: encoded?.be ?? [],
        };
      }
    } catch (_) {
      /* empty */
    }
  }

  return {
    viewUserIds: [],
    editUserIds: [],
    viewBranchIds: [],
    editBranchIds: [],
  };
}

export function userHasViewAccess(
  savedSegmentAccess: TSavedSegmentAccess,
  user: TUser
): boolean {
  if (savedSegmentAccess.viewUserIds.includes(user.id)) {
    return true;
  }

  if (savedSegmentAccess.editUserIds.includes(user.id)) {
    return true;
  }

  if (savedSegmentAccess.viewBranchIds.includes(user.branchId)) {
    return true;
  }

  if (savedSegmentAccess.editBranchIds.includes(user.branchId)) {
    return true;
  }

  return false;
}

export function userHasEditAccess(
  savedSegmentAccess: TSavedSegmentAccess,
  user: TUser
): boolean {
  if (savedSegmentAccess.editUserIds.includes(user.id)) {
    return true;
  }

  if (savedSegmentAccess.editBranchIds.includes(user.branchId)) {
    return true;
  }

  return false;
}

export function removeUser(
  savedSegmentAccess: TSavedSegmentAccess,
  userId: TEntityId
) {
  savedSegmentAccess.viewUserIds = difference(savedSegmentAccess.viewUserIds, [
    userId,
  ]);
  savedSegmentAccess.editUserIds = difference(savedSegmentAccess.editUserIds, [
    userId,
  ]);
}

export function removeBranch(
  savedSegmentAccess: TSavedSegmentAccess,
  branchId: TEntityId
) {
  savedSegmentAccess.viewBranchIds = difference(
    savedSegmentAccess.viewBranchIds,
    [branchId]
  );
  savedSegmentAccess.editBranchIds = difference(
    savedSegmentAccess.editBranchIds,
    [branchId]
  );
}

export function addUsers(
  savedSegmentAccess: TSavedSegmentAccess,
  accessType: TSavedSegmentAccessTypeEnum,
  userIds: TEntityId[]
) {
  switch (accessType) {
    case SavedSegmentAccessTypeEnum.View:
      savedSegmentAccess.viewUserIds = unique([
        ...savedSegmentAccess.viewUserIds,
        ...userIds,
      ]);
      savedSegmentAccess.editUserIds = difference(
        savedSegmentAccess.editUserIds,
        savedSegmentAccess.viewUserIds
      );
      break;
    case SavedSegmentAccessTypeEnum.Edit:
      savedSegmentAccess.editUserIds = unique([
        ...savedSegmentAccess.editUserIds,
        ...userIds,
      ]);
      savedSegmentAccess.viewUserIds = difference(
        savedSegmentAccess.viewUserIds,
        savedSegmentAccess.editUserIds
      );
      break;
  }
}

export function addUser(
  savedSegmentAccess: TSavedSegmentAccess,
  accessType: TSavedSegmentAccessTypeEnum,
  userId: TEntityId
) {
  addUsers(savedSegmentAccess, accessType, [userId]);
}

export function addBranches(
  savedSegmentAccess: TSavedSegmentAccess,
  accessType: TSavedSegmentAccessTypeEnum,
  branchIds: TEntityId[]
) {
  switch (accessType) {
    case SavedSegmentAccessTypeEnum.View:
      savedSegmentAccess.viewBranchIds = unique([
        ...savedSegmentAccess.viewBranchIds,
        ...branchIds,
      ]);
      savedSegmentAccess.editBranchIds = difference(
        savedSegmentAccess.editBranchIds,
        savedSegmentAccess.viewBranchIds
      );
      break;
    case SavedSegmentAccessTypeEnum.Edit:
      savedSegmentAccess.editBranchIds = unique([
        ...savedSegmentAccess.editBranchIds,
        ...branchIds,
      ]);
      savedSegmentAccess.viewBranchIds = difference(
        savedSegmentAccess.viewBranchIds,
        savedSegmentAccess.editBranchIds
      );
      break;
  }
}

export function addBranch(
  savedSegmentAccess: TSavedSegmentAccess,
  accessType: TSavedSegmentAccessTypeEnum,
  branchId: TEntityId
) {
  addBranches(savedSegmentAccess, accessType, [branchId]);
}

export function getUsersHasViewAccess(savedSegmentAccess: TSavedSegmentAccess) {
  return [...savedSegmentAccess.viewUserIds, ...savedSegmentAccess.editUserIds];
}

export function getBranchesHasViewAccess(
  savedSegmentAccess: TSavedSegmentAccess
) {
  return [
    ...savedSegmentAccess.viewBranchIds,
    ...savedSegmentAccess.editBranchIds,
  ];
}
