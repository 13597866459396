import { produce } from "immer";
import numeral from "numeral";
import { select, put } from "redux-saga/effects";
import { JOB_EDIT, JOB_SET_BUDGET } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";

function* setBudget(action) {
  try {
    const { jobId, value } = action.payload;

    const job = yield select(selectJob, { jobId });

    const newJob = produce(job, draft => {
      draft.jobCurrencyBudget = numeral(value).value();
    });

    yield put(
      createAction(JOB_EDIT, {
        jobId,
        job: newJob
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetBudget() {
  yield takeLatestBy(
    [JOB_SET_BUDGET],
    setBudget,
    action => action.payload.jobId
  );
}
