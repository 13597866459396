import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DATE_FORMAT_ORDINAL_DATE_MONTH } from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { jobMilestoneType } from "../../../lib/entities/jobMilestoneEntity";
import Flex from "../../elements/Flex";
import Tooltip, { defaultStyles } from "../../modules/Tooltip";

class MilestoneCountTooltip extends React.PureComponent {
  static propTypes = {
    editMilestone: PropTypes.func.isRequired,
    jobMilestones: PropTypes.arrayOf(jobMilestoneType).isRequired,
    children: PropTypes.node.isRequired
  };

  get title() {
    const { editMilestone, jobMilestones } = this.props;
    return (
      <Content>
        <Heading>Milestones on this job</Heading>
        <table>
          <tbody>
            {jobMilestones.map(jobMilestone => (
              <tr key={jobMilestone.id}>
                <th>
                  {formatDate(
                    jobMilestone.date,
                    DATE_FORMAT_ORDINAL_DATE_MONTH
                  )}
                </th>
                <td>
                  <MilestoneName onClick={() => editMilestone(jobMilestone)}>
                    {jobMilestone.name}
                  </MilestoneName>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    );
  }

  render() {
    const { children } = this.props;
    const styles = {
      tooltip: {
        ...defaultStyles.tooltip,
        padding: 0,
        minWidth: 300
      }
    };
    return (
      <Tooltip interactive title={this.title} styles={styles}>
        {children}
      </Tooltip>
    );
  }
}

export default MilestoneCountTooltip;

const Content = styled(Flex)`
  flex-direction: column;
  color: var(--color-gray);
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;

  table {
    width: 100%;

    th {
      width: 100px;
      height: 35px;
      border-top: 1px solid var(--color-charcoal);
      font-weight: bold;
      text-align: left;
      font-weight: bold;
      padding: 0 20px;
      vertical-align: middle;
      white-space: nowrap;
    }
    td {
      height: 35px;
      border-top: 1px solid var(--color-charcoal);
      font-weight: 500;
      text-align: left;
      color: white;
      padding: 0 20px 0 0;
      vertical-align: middle;
      cursor: pointer;
    }
  }
`;

const MilestoneName = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled(Flex)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--color-gray);
`;
