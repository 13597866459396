import { get } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectActiveJobPhaseIdsByJobId } from "../../../redux/selectors/jobItemSelectors";
import { selectPhaseRowHeightsByJobId } from "../../../redux/selectors/jobTimelineSelectors";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const mapState = (state, props) => ({
  orderedJobPhaseIds: selectActiveJobPhaseIdsByJobId(state, props),
  phaseRowHeights: selectPhaseRowHeightsByJobId(state, props)
});

const mapDispatch = null;

const PhaseRowsConsumer = ({
  orderedJobPhaseIds,
  phaseRowHeights,
  children
}) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportHeight, scrollOffsetY }) => {
      const rows = [];
      let currentOffsetY = -scrollOffsetY;

      orderedJobPhaseIds.forEach(jobPhaseId => {
        const height = get(phaseRowHeights, `${jobPhaseId}.height`, 0);
        const top = currentOffsetY;
        const bottom = top + height;

        if (top <= viewportHeight && bottom >= 0)
          rows.push({
            jobPhaseId,
            top,
            height
          });

        currentOffsetY = bottom;
      });

      return rows.map(children);
    }}
  </JobTimelineScrollContext.Consumer>
);

PhaseRowsConsumer.propTypes = {
  children: PropTypes.func.isRequired,
  jobId: entityIdType.isRequired,
  orderedJobPhaseIds: entityIdListType.isRequired,
  phaseRowHeights: PropTypes.objectOf(
    PropTypes.shape({
      height: PropTypes.number.isRequired
    })
  ).isRequired
};

export default connect(mapState, mapDispatch)(PhaseRowsConsumer);
