import "st-shared/css";
import "../styles/index.css";
import "../lib/includes";
import "../lib/devtools";
import "../lib/WebAppAPI";
import "../lib/stores";

import { Toaster } from "@streamtimefe/components";
import { ErrorBoundary } from "@streamtimefe/error";
import { Provider } from "react-redux";

import store from "../redux/store";
import { AppRouter } from "./AppRouter";
import MaterialUiProviders from "./modules/MaterialUiProviders";

export function App() {
  return (
    <ErrorBoundary>
      <Provider store={store} stabilityCheck={"never"}>
        <MaterialUiProviders>
          <AppRouter />
        </MaterialUiProviders>
      </Provider>
      <Toaster />
    </ErrorBoundary>
  );
}
