import clsx from "clsx";
import { ReactNode } from "react";
import { forwardRef } from "react";
import {
  primaryUserDisplayNameCss,
  primaryUserRoleNameCss,
} from "st-shared/module";
import styled from "styled-components";

import UserIconName from "../User/UserIconName";
import { userIconNameCss } from "../User/UserIconName.css";

interface UserOptionProps {
  userId: number;
  onPickUser: (userId: number) => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  isSelected?: boolean;
  className?: string;
}

const UserOption = forwardRef<HTMLDivElement, UserOptionProps>(
  function UserOption(
    { userId, onPickUser, children, isSelected, style, className },
    ref
  ) {
    function onClick() {
      onPickUser(userId);
    }

    return (
      <Wrapper
        ref={ref}
        onClick={onClick}
        className={clsx("rainbowHover", className)}
        style={style}
      >
        <UserIconName
          className={userIconNameCss}
          id={userId}
          size={26}
          showRole
          fontSize={12}
          color="var(--color-charcoal)"
          isSelected={isSelected}
        />
        {children}
      </Wrapper>
    );
  }
);

export default UserOption;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  &:hover .${primaryUserDisplayNameCss} {
    color: black;
  }
  &:hover .${primaryUserRoleNameCss} {
    color: black;
  }
`;
