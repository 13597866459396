import { isEqual } from "lodash-es";
import { useMemo } from "react";
import { CReportingSavedSegment } from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import { useSavedSegmentEntity } from "st-shared/stores";

import {
  useReportingSavedSegmentName,
  useReportingSavedSegmentValue,
} from "../state/stores/savedSegmentSelectors";

export function useCanSaveExistingReport(entityId: TEntityId) {
  const value = useReportingSavedSegmentValue();
  const name = useReportingSavedSegmentName();
  const savedSegement = useSavedSegmentEntity(entityId);

  const reportingSavedSegement = useMemo(() => {
    if (!savedSegement) return null;

    return CReportingSavedSegment.fromString(savedSegement.value).object;
  }, [savedSegement]);

  const hasChanged = useMemo(() => {
    if (!savedSegement) return false;

    return (
      name !== savedSegement.name || !isEqual(value, reportingSavedSegement)
    );
  }, [name, value, savedSegement, reportingSavedSegement]);

  return reportingSavedSegement !== null && hasChanged;
}
