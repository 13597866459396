import { createContext, useContext } from "react";

export type TThemeContext = {
  theme: string;
};

export const ThemeContext = createContext<TThemeContext>(
  {} as unknown as TThemeContext
);

export function useThemeContext() {
  const theme = useContext(ThemeContext);
  if (!theme) {
    // eslint-disable-next-line no-console
    console.error("useTheme has to be used within <StreamtimeStyleProvider>");
  }
  return theme;
}

export function useTheme() {
  return useThemeContext().theme;
}
