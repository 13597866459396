import { JobListModel } from "st-shared/entities/JobListModel";

import {
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR,
} from "../../../lib/constants";
import { EntityAction } from "../actions";
import { EntityNames } from "../types";

export const JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED =
  "jobListModel/filterResultsReceived";

export type JobListModelsAction =
  | EntityAction
  | JobListModelFilterResultsReceivedAction
  | ScheduleJobBarDragAction
  | ScheduleJobBarDragCancelAction
  | ScheduleJobShiftDurationErrorAction;

export type JobListModelFilterResultsReceivedAction = {
  type: typeof JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED;
  payload: { [EntityNames.JobListModels]: JobListModel[] };
};

export function actionJobListModelFilterResultsReceived(payload: {
  [EntityNames.JobListModels]: JobListModel[];
}): JobListModelFilterResultsReceivedAction {
  return { type: JOB_LIST_MODEL_FILTER_RESULTS_RECEIVED, payload };
}

export type ScheduleJobBarDragAction = {
  type: typeof SCHEDULE_JOB_BAR_DRAG;
  payload: { jobId: number; daysMoved: number };
};

export type ScheduleJobBarDragCancelAction = {
  type: typeof SCHEDULE_JOB_BAR_DRAG_CANCEL;
  payload: { jobId: number; daysMoved: number; hasChanged: boolean };
};

export type ScheduleJobShiftDurationErrorAction = {
  type: typeof SCHEDULE_JOB_SHIFT_DURATION_ERROR;
  payload: { jobId: number; daysMoved: number; hasChanged: boolean };
};
