import { ChangeEvent, forwardRef, useState } from "react";
import { mergeProps } from "react-aria";

import { BaseInput, BaseInputProps } from "./BaseInput";

export interface TimeInputProps extends BaseInputProps {}

export const TimeInput = forwardRef<HTMLInputElement, TimeInputProps>(
  (props, ref) => {
    const [, setIsPaste] = useState(false);

    function modifyValue(value: string) {
      value = value.replace(/[^0-9.humHUM]/g, "");
      return value;
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
      event.target.value = modifyValue(event.target.value);
    }

    function onPaste() {
      setIsPaste(true);
      requestAnimationFrame(() => {
        setIsPaste(false);
      });
    }

    const inputProps: BaseInputProps = { onChange, onPaste };

    return <BaseInput ref={ref} {...mergeProps(inputProps, props)} />;
  }
);

TimeInput.displayName = "TimeInput";
