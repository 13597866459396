import { createBrowserHistory } from "history";
import type { TEntityId } from "st-shared/entities/Entity";

import { fePageNavigate, fePageNavigateBack } from "./WebAppAPI/fePages";

export const history = createBrowserHistory();

export const baseUrl = import.meta.env.BASE_URL;

export const RoutePages = {
  Reporting: "reporting_beta",
  Schedule: "schedule",
  Jobs: "jobs",
  JobsBeta: "jobs_beta",
  Quotes: "quotes",
  Invoices: "invoices",
  PurchaseOrders: "purchase_orders",
} as const;

export type TRoutePages = (typeof RoutePages)[keyof typeof RoutePages];

export function createRouteLink(
  route: TRoutePages,
  entityId?: TEntityId,
  suffix?: string
) {
  let pre = "/";
  if (import.meta.env.PROD) {
    pre += "#";
  }
  return pre + createRoute(route, entityId, suffix);
}

export function createWebEntityRoute(route: TRoutePages, entityId: TEntityId) {
  return `#${route}/${entityId}`;
}

export function createRoute(
  route: TRoutePages,
  entityId?: TEntityId,
  suffix?: string
) {
  let link = route;
  if (typeof entityId !== "undefined") {
    link += `/${entityId}`;
  }
  if (typeof suffix !== "undefined") {
    link += `/${suffix}`;
  }
  return link;
}

export function routerPush(
  route: TRoutePages,
  entityId?: TEntityId,
  suffix?: string
) {
  if (import.meta.env.PROD) {
    fePageNavigate(route, false, entityId, suffix);
  }
  history.push(`${baseUrl}${createRoute(route, entityId, suffix)}`);
}

export function routerReplace(
  route: TRoutePages,
  entityId?: TEntityId,
  suffix?: string
) {
  if (import.meta.env.PROD) {
    fePageNavigate(route, true, entityId, suffix);
  }
  history.replace(`${baseUrl}${createRoute(route, entityId, suffix)}`);
}

export function routerGoBack(fallback?: {
  route: TRoutePages;
  entityId?: TEntityId;
}) {
  const fallbackRoute =
    fallback && createRoute(fallback.route, fallback.entityId);
  if (import.meta.env.PROD) {
    fePageNavigateBack(fallbackRoute);
  } else {
    if (window.history.length < 2 && fallbackRoute) {
      history.replace(`${baseUrl}${fallbackRoute}`);
    } else {
      history.back();
    }
  }
}

export function routerFromWeb(route: string) {
  history.replace(`${baseUrl}${route}`);
}
