import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { sharedEmitter } from "st-shared/lib";
import { pluralise } from "st-shared/lib/pluralise";
import styled from "styled-components";

import {
  DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR,
  DATE_FORMAT_ORDINAL_DATE_MONTH,
  JOB_TIMELINE_INFO_HEIGHT,
  JOB_TIMELINE_ZOOM_IN,
  JOB_TIMELINE_ZOOM_MIN_LEVEL,
} from "../../../lib/constants";
import { formatDate, getTodayDate } from "../../../lib/dates";
import { jobMilestoneType } from "../../../lib/entities/jobMilestoneEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  selectNextJobMilestoneByJobId,
  selectSortedJobMilestonesByJobId,
} from "../../../redux/selectors/jobMilestoneSelectors";
import {
  selectJobDateRange,
  selectJobTimelineZoomLevel,
} from "../../../redux/selectors/jobTimelineSelectors";
import Flex from "../../elements/Flex";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import CallBackConsumer from "../../modules/ScrollContexts/CallBackConsumer";
import MilestoneCountTooltip from "./MilestoneCountTooltip";

const mapState = (state, props) => ({
  jobDateRange: selectJobDateRange(state, props),
  jobMilestones: selectSortedJobMilestonesByJobId(state, props),
  nextJobMilestone: selectNextJobMilestoneByJobId(state, props),
  zoomLevel: selectJobTimelineZoomLevel(state),
});

const mapDispatch = (dispatch) => ({
  doZoomIn: () => {
    dispatch(createAction(JOB_TIMELINE_ZOOM_IN));
  },
});

class JobTimelineInfo extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    readOnly: PropTypes.bool.isRequired,
    jobDateRange: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
    jobMilestones: PropTypes.arrayOf(jobMilestoneType).isRequired,
    nextJobMilestone: jobMilestoneType,
    zoomLevel: PropTypes.number.isRequired,
    doZoomIn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    nextJobMilestone: null,
  };

  get isMinZoom() {
    const { zoomLevel } = this.props;
    return zoomLevel === JOB_TIMELINE_ZOOM_MIN_LEVEL;
  }

  addMilestone = () => {
    const { doZoomIn } = this.props;
    if (this.isMinZoom) doZoomIn();
    window.requestAnimationFrame(() => {
      sharedEmitter.emit("scrollToAndCreateMilestone", getTodayDate());
    });
  };

  editMilestone = ({ id, name, date }) => {
    const { doZoomIn } = this.props;
    if (this.isMinZoom) doZoomIn();
    window.requestAnimationFrame(() => {
      sharedEmitter.emit("scrollToAndEditMilestone", id, name, date);
    });
  };

  editNextMilestone = () => {
    const {
      doZoomIn,
      nextJobMilestone: { id, name, date },
    } = this.props;
    if (this.isMinZoom) doZoomIn();
    window.requestAnimationFrame(() => {
      sharedEmitter.emit("scrollToAndEditMilestone", id, name, date);
    });
  };

  render() {
    const { jobDateRange, jobMilestones, nextJobMilestone } = this.props;

    return (
      <CallBackConsumer Context={JobTimelineScrollContext}>
        {({ goToDate, goToRHSDate }) => (
          <JobTimelineInfoWrapper>
            <DateRange>
              {jobDateRange.startDate ? (
                <Date>
                  Starts on
                  <ClickableDate
                    onClick={() => goToDate(jobDateRange.startDate)}
                  >
                    {formatDate(
                      jobDateRange.startDate,
                      DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                    )}
                  </ClickableDate>
                </Date>
              ) : (
                <EmptyDate>No start date</EmptyDate>
              )}

              {jobDateRange.endDate ? (
                <Date>
                  Due on
                  <ClickableDate
                    onClick={() => goToRHSDate(jobDateRange.endDate)}
                  >
                    {formatDate(
                      jobDateRange.endDate,
                      DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                    )}
                  </ClickableDate>
                </Date>
              ) : (
                <EmptyDate>No end date</EmptyDate>
              )}
            </DateRange>

            <Milestones>
              {jobMilestones.length > 0 ? (
                <MilestonesSentence>
                  <MilestoneSentencePart>
                    There {pluralise(jobMilestones.length, "is")}{" "}
                    <MilestoneCountTooltip
                      editMilestone={this.editMilestone}
                      jobMilestones={jobMilestones}
                    >
                      <MilestoneCount>
                        {jobMilestones.length}{" "}
                        {pluralise(jobMilestones.length, "milestone")}
                      </MilestoneCount>
                    </MilestoneCountTooltip>
                    .{" "}
                  </MilestoneSentencePart>
                  <MilestoneSentencePart>
                    {nextJobMilestone && (
                      <NextMilestone>
                        Next is on the{" "}
                        <NextMilestoneDate onClick={this.editNextMilestone}>
                          {formatDate(
                            nextJobMilestone.date,
                            DATE_FORMAT_ORDINAL_DATE_MONTH
                          )}
                        </NextMilestoneDate>
                      </NextMilestone>
                    )}
                  </MilestoneSentencePart>
                </MilestonesSentence>
              ) : (
                <AddMilestone onClick={this.addMilestone}>
                  Create your first milestone
                </AddMilestone>
              )}
            </Milestones>
          </JobTimelineInfoWrapper>
        )}
      </CallBackConsumer>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineInfo);

export const JobTimelineInfoWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_INFO_HEIGHT}px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 38px;
`;

const Clickable = styled.span`
  color: var(--color-blue-active);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const DateRange = styled(Flex)`
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
`;

const Date = styled(Flex)`
  color: black;
  font-size: 16px;
  margin-right: 40px;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const ClickableDate = styled(Clickable)`
  margin-left: 5px;
`;

const EmptyDate = styled(Date)`
  color: var(--color-gray-dark);
`;

const Milestones = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
  padding-right: 16px;
  color: black;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
`;

const MilestonesSentence = styled(Flex)`
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const MilestoneSentencePart = styled.span`
  white-space: nowrap;
  margin-left: 5px;
`;

export const AddMilestone = styled(Clickable)``;

const MilestoneCount = styled(Clickable)``;

const NextMilestone = styled.span``;

const NextMilestoneDate = styled(Clickable)``;
