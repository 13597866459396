import { useReportingSavedSegmentColumnsOrdered } from "../../state/stores/savedSegmentSelectors";
import { SortableColumnList } from "../column/SortableColumnList";
import { AddColumn } from "./AddColumn";

export function TableHeaderColumns() {
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);
  return (
    <>
      <SortableColumnList variant="table" />
      <AddColumn zIndex={10 + orderedColumns.length * 2} />
    </>
  );
}
