import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  JOB_ITEM_DRAG_START,
  JOB_ITEM_DRAG,
  JOB_ITEM_DRAG_END,
  JOB_ITEM_DRAG_CANCEL
} from "../../../lib/constants";
import { differenceInCalendarDays } from "../../../lib/dates";
import {
  getElementClientOffset,
  getElementOffset,
  getPointerClientOffset,
  subtractCoords
} from "../../../lib/dom";
import {
  getTargetDependancyHandle,
  getTargetJobItemBarElement,
  getTargetJobItemBarId,
  getTargetJobItemBarJobId,
  getTargetResizeHandle
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import { createSnapshotId } from "../../../redux/helpers/snapshotIds";

const mapState = null;

const mapDispatch = dispatch => ({
  doStartDragBar: payload =>
    dispatch(createAction(JOB_ITEM_DRAG_START, payload)),
  doDragBar: payload => dispatch(createAction(JOB_ITEM_DRAG, payload)),
  doEndDragBar: payload => dispatch(createAction(JOB_ITEM_DRAG_END, payload)),
  doCancelDragBar: payload =>
    dispatch(createAction(JOB_ITEM_DRAG_CANCEL, payload))
});

export default WrappedComponent => {
  class JobItemBarDragHandlers extends React.PureComponent {
    dragItem = null;

    isDragging = false;

    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      getScrollOffsetX: PropTypes.func.isRequired,
      getDateAtOffsetX: PropTypes.func.isRequired,
      doStartDragBar: PropTypes.func.isRequired,
      doDragBar: PropTypes.func.isRequired,
      doEndDragBar: PropTypes.func.isRequired,
      doCancelDragBar: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      className: PropTypes.string
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: ""
    };

    getPointerDistance(e) {
      const { origin } = this.dragItem;
      const pointerPosition = getPointerClientOffset(e);
      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getTargetDate(e) {
      const { getScrollOffsetX, getDateAtOffsetX } = this.props;
      const { origin } = this.dragItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const newOffsetX =
        origin.barOffset.x + pointerDistance.x + scrollDistanceX;

      return getDateAtOffsetX(newOffsetX);
    }

    onPointerDown = e => {
      const {
        readOnly,
        getScrollOffsetX,
        getDateAtOffsetX,
        doStartDragBar,
        onPointerDown
      } = this.props;
      const jobId = getTargetJobItemBarJobId(e);
      const jobItemId = getTargetJobItemBarId(e);
      const barNode = jobItemId && getTargetJobItemBarElement(e);
      const canMove = barNode && barNode.classList.contains("canMove");
      const isOtherEvent =
        jobItemId && (getTargetResizeHandle(e) || getTargetDependancyHandle(e));

      if (!readOnly && barNode && canMove && !isOtherEvent) {
        const barOffset = getElementOffset(barNode);
        const barPosition = getElementClientOffset(barNode);
        const pointerPosition = getPointerClientOffset(e);
        const date = getDateAtOffsetX(barOffset.x);
        const scrollOffsetX = getScrollOffsetX();

        this.dragItem = {
          jobItemId,
          date,
          jobId,
          snapshotId: createSnapshotId(),
          origin: {
            scrollOffsetX,
            barOffset,
            barPosition,
            pointerPosition,
            date
          }
        };

        doStartDragBar(this.dragItem);
        this.isDragging = true;
        this.forceUpdate();
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = e => {
      const { dragItem: prevDragItem } = this;
      const { doDragBar, onPointerMove } = this.props;

      if (this.isDragging) {
        const date = this.getTargetDate(e) || prevDragItem.origin.date;
        if (prevDragItem.date !== date) {
          this.dragItem = {
            ...prevDragItem,
            date,
            daysMoved: differenceInCalendarDays(date, prevDragItem.date)
          };

          doDragBar(this.dragItem);
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = e => {
      const { dragItem } = this;
      const { doEndDragBar, onPointerUp } = this.props;

      if (this.isDragging) {
        doEndDragBar({
          ...dragItem,
          hasChanged: dragItem.origin.date !== dragItem.date
        });

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = e => {
      const { dragItem } = this;
      const { doCancelDragBar, onLostPointerCapture } = this.props;

      if (this.isDragging) {
        doCancelDragBar({ ...dragItem });

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isDragging) classes.push("dragging");

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp
        // onLostPointerCapture: this.onLostPointerCapture
      };
    }

    render() {
      const { props, className, handlers } = this;

      return (
        <>
          <WrappedComponent {...{ ...props, className, ...handlers }} />
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(JobItemBarDragHandlers);
};
