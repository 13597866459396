import { all, takeLatest } from "redux-saga/effects";

import watchWebAPIApplyModelData from "./watchWebAPIApplyModelData";
import watchWebAPIRemoveModel from "./watchWebAPIRemoveModel";
import watchWebAPIScheduleSearchResults from "./watchWebAPIScheduleSearchResults";

export const WEB_APP_API_SAGA_START = "webAppApiSagaStart";

function* watchAuthenticationSuccess() {
  yield takeLatest(WEB_APP_API_SAGA_START, watchWebAPIApplyModelData);
  yield takeLatest(WEB_APP_API_SAGA_START, watchWebAPIRemoveModel);
  yield takeLatest(WEB_APP_API_SAGA_START, watchWebAPIScheduleSearchResults);
}

export default function* webAppApiSaga() {
  yield all([watchAuthenticationSuccess()]);
}
