import { Option } from "./Option";

export const InvoiceTypes = {
  Invoice: 1,
  CreditNote: 2,
} as const;

export type InvoiceTypesType = (typeof InvoiceTypes)[keyof typeof InvoiceTypes];

export type Type = InvoiceType;
export interface InvoiceType extends Option {
  id: InvoiceTypesType;
}

export function isInvoice(entity: InvoiceType) {
  return entity.id === InvoiceTypes.Invoice;
}

export function isCreditNote(entity: InvoiceType) {
  return entity.id === InvoiceTypes.CreditNote;
}
