import { all } from "redux-saga/effects";
import watchFetch from "./watchFetch";
import watchLoggedExpenseSave from "./watchLoggedExpenseSave";
import watchLoggedExpenseSetAddress from "./watchLoggedExpenseSetAddress";
import watchLoggedExpenseSetContact from "./watchLoggedExpenseSetContact";
import watchLoggedExpenseSetSupplier from "./watchLoggedExpenseSetSupplier";
import watchOpenCreateAddress from "./watchOpenCreateAddress";
import watchOpenCreateEditContact from "./watchOpenCreateEditContact";

export default function* loggedExpenseSaga() {
  yield all([
    watchFetch(),
    watchLoggedExpenseSave(),
    watchLoggedExpenseSetAddress(),
    watchLoggedExpenseSetContact(),
    watchOpenCreateEditContact(),
    watchOpenCreateAddress(),
    watchLoggedExpenseSetSupplier()
  ]);
}
