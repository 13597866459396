import { call, put, takeEvery } from "redux-saga/effects";
import { fetchJobUnplannedTimeSummaryAPI } from "../../../lib/API/jobAPI";
import {
  JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY,
  JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetchUnplannedTimeSummary(action) {
  try {
    const { jobId } = action.payload;

    const unplannedTimeSummary = yield call(
      fetchJobUnplannedTimeSummaryAPI,
      jobId
    );

    yield put(
      createAction(JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY, {
        unplannedTimeSummary
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchFetchUnplannedTimeSummary() {
  yield takeEvery(
    JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY,
    fetchUnplannedTimeSummary
  );
}
