import { DndContext, closestCenter } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove
} from "@dnd-kit/sortable";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_SUB_ITEM_ORDER
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItemSubItemIdsOrderedByJobItemId } from "../../../redux/selectors/jobItemSubItem/selectJobItemSubItemIdsOrderedByJobItemId";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import useIsChecklistHidden from "../hooks/useIsChecklistHidden";
import { BaseItem, hiddenElement } from "../styles";
import NewSubItem from "./NewSubItem";
import SubItem from "./SubItem";

const ItemChecklist = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const subItemIds = useJobItemSubItemIdsOrderedByJobItemId(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const isHidden = useIsChecklistHidden(jobItemId);
  const dispatch = useDispatch();

  const doJobItemSetSubItemOrder = orderedSubItemIds => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_SUB_ITEM_ORDER, {
        jobItemId,
        orderedSubItemIds
      })
    );
  };

  const handleDragStart = event => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL
        }
      })
    );
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (isJobEditable && active.id !== over.id) {
      const oldIndex = subItemIds.indexOf(active.id);
      const newIndex = subItemIds.indexOf(over.id);

      doJobItemSetSubItemOrder(arrayMove(subItemIds, oldIndex, newIndex));
    }
  };

  return (
    <Container hidden={isHidden}>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={subItemIds}
          strategy={verticalListSortingStrategy}
        >
          {subItemIds.map((subItemId, index) => {
            const nextFocus =
              index === subItemIds.length - 1
                ? {
                    jobItemId,
                    key: FOCUS_KEYS.ITEM_NEW_SUB_ITEM
                  }
                : {
                    jobItemSubItemId: subItemIds[index + 1],
                    jobItemId,
                    key: FOCUS_KEYS.ITEM_SUB_ITEM
                  };
            return (
              <SubItem
                key={`${subItemId}subItem`}
                id={subItemId}
                nextFocus={nextFocus}
              ></SubItem>
            );
          })}
        </SortableContext>
      </DndContext>
      <NewSubItem />
    </Container>
  );
};

export default ItemChecklist;

const Container = styled(BaseItem)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  z-index: 101;

  ${hiddenElement}
`;
