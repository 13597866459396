import { produce } from "immer";
import { put, select } from "redux-saga/effects";
import {
  JOB_ITEM_SUB_ITEM_EDIT,
  JOB_ITEM_SUB_ITEM_SET_DESCRIPTION
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemSubItem } from "../../selectors/jobItemSubItem";

function* setDescription(action) {
  try {
    const { jobItemId, jobItemSubItemId, value } = action.payload;

    const jobItemSubItem = yield select(selectJobItemSubItem, {
      jobItemSubItemId
    });

    const newJobItemSubItem = produce(jobItemSubItem, draft => {
      draft.description = value;
    });

    yield put(
      createAction(JOB_ITEM_SUB_ITEM_EDIT, {
        jobItemId,
        jobItemSubItems: [{ new: newJobItemSubItem, prev: jobItemSubItem }]
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSubItemSetDescription() {
  yield takeLatestBy(
    [JOB_ITEM_SUB_ITEM_SET_DESCRIPTION],
    setDescription,
    action => action.payload.jobItemSubItemId
  );
}
