import { useReportingSavedSegmentColumns } from "../../../state/stores/savedSegmentSelectors";
import {
  ReportingChartAnnotation,
  ReportingChartData,
} from "../ReportingChartData";
import {
  legendBoxCss,
  legendContainerCss,
  legendDividerCss,
  legendElementCss,
} from "./TimeSeriesLegend.css";

type Props = {
  data: ReportingChartData<"line">;
  annotations: ReportingChartAnnotation[];
};

export function TimeSeriesLegend({ data, annotations }: Props) {
  if (data.datasets.length === 0) {
    return <div className={legendContainerCss} style={{ flex: 1 }}></div>;
  }

  return (
    <div className={legendContainerCss}>
      {data.datasets.map((dataset) => (
        <TimeSeriesLegendElement
          key={dataset.id}
          id={dataset.id}
          color={dataset.borderColor as string}
        />
      ))}
      {annotations && annotations?.length > 0 && data.datasets.length > 0 && (
        <div className={legendDividerCss} />
      )}
      {annotations.map((annotation) => (
        <TimeSeriesLegendElement
          key={annotation.id}
          id={annotation.id}
          color={annotation.borderColor as string}
        />
      ))}
    </div>
  );
}

type TimeSeriesLegendElementProps = {
  id: string;
  color: string;
};

function TimeSeriesLegendElement({ id, color }: TimeSeriesLegendElementProps) {
  const columns = useReportingSavedSegmentColumns();
  return (
    <div className={legendElementCss}>
      <div className={legendBoxCss} style={{ backgroundColor: color }} />
      {columns[id].name}
    </div>
  );
}
