import { useJobPhaseHours } from "../../../redux/selectors/jobPhase/selectJobPhaseHours";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobPhaseId } from "../context/JobPhaseIdContext";
import { LargeItemText } from "../styles";

const PhaseHours = () => {
  const jobPhaseId = useJobPhaseId();
  const [hours, warning] = useJobPhaseHours(jobPhaseId);

  return (
    <LargeItemText width={ItemColumnWidths.Hours} warning={warning}>
      {hours}
    </LargeItemText>
  );
};

export default PhaseHours;
