import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import {
  feToWebOpenPremiumOnlyModal,
  PremiumOnlyModalMode,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import { jobPageOpenRepeatingLoggedTimesModal } from "../../../lib/WebAppAPI/fePages/jobPage";
import createAction from "../../../redux/helpers/createAction";
import { useJob } from "../../../redux/selectors/job";
import {
  useJobActivityCommentsFilter,
  useJobActivityPostsFilter,
  useJobActivitySystemMessagesFilter,
} from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { IconButton } from "../../elements/Button";
import ActivityIcon from "../../elements/Icons/custom/ActivityIcon";
import FilterIcon from "../../elements/Icons/custom/FilterIcon";
import SideDrawerIcon from "../../elements/Icons/custom/SideDrawerIcon";
import Repeat2Icon from "../../elements/Icons/Repeat2Icon";
import Menu from "../../modules/Menu";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import ChecklistLabelItem from "../components/ChecklistLabelItem";
import { useIsDrawer } from "../context/isDrawerContext";
import { useJobId } from "../context/JobIdContext";

const JobActivityHeader = () => {
  const dispatch = useDispatch();
  const jobId = useJobId();
  const job = useJob(jobId);

  let isCommentsFilterActive = useJobActivityCommentsFilter();
  let isPostsFilterActive = useJobActivityPostsFilter();
  const isSystemMessagesFilterActive = useJobActivitySystemMessagesFilter();

  const { canAccessCollaboration } = usePermissions();

  if (!canAccessCollaboration) {
    isCommentsFilterActive = false;
    isPostsFilterActive = false;
  }

  const ref = useRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = () => {
    setMenuAnchorEl(ref.current);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const toggleCommentsFilter = () => {
    if (!canAccessCollaboration) {
      feToWebOpenPremiumOnlyModal(PremiumOnlyModalMode.Collaboration);
      return;
    }
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER,
        value: !isCommentsFilterActive,
      })
    );
  };

  const togglePostsFilter = () => {
    if (!canAccessCollaboration) {
      feToWebOpenPremiumOnlyModal(PremiumOnlyModalMode.Collaboration);
      return;
    }
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER,
        value: !isPostsFilterActive,
      })
    );
  };

  const toggleSystemMessagesFilter = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER,
        value: !isSystemMessagesFilterActive,
      })
    );
  };

  const openActivityDrawer = () =>
    dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, true));

  const isDrawer = useIsDrawer();

  const filterText = (() => {
    const viewing = [];
    if (isPostsFilterActive) viewing.push("posts");
    if (isCommentsFilterActive) viewing.push("comments");
    if (isSystemMessagesFilterActive) viewing.push("activity");

    switch (viewing.length) {
      case 3:
        return "Viewing everything";
      case 2:
        return `Viewing ${viewing[0]} and ${viewing[1]}`;
      case 1:
        return `Viewing ${viewing[0]} only`;
    }

    return "";
  })();

  return (
    <Container ref={ref}>
      {isDrawer && (
        <DrawerHeading>
          <ActivityIcon size={ICON_SIZE.MEDIUM} />
          <span>Activity</span>
        </DrawerHeading>
      )}
      <FilterButton onClick={openMenu} $isDrawer={isDrawer}>
        <FilterIcon size={ICON_SIZE.MEDIUM} />
        {!isDrawer && <span className="label">{filterText}</span>}
        {isDrawer &&
          (!isSystemMessagesFilterActive ||
            (canAccessCollaboration &&
              (!isCommentsFilterActive || !isPostsFilterActive))) && (
            <span className="notificationIcon"></span>
          )}
      </FilterButton>
      {!isDrawer && (
        <div>
          <Tooltip title="View repeating To Do’s" placement="top">
            <IconButton
              onClick={() => jobPageOpenRepeatingLoggedTimesModal(job)}
            >
              <Repeat2Icon size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open in drawer" placement="top">
            <IconButton onClick={openActivityDrawer}>
              <SideDrawerIcon size={ICON_SIZE.MEDIUM} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: isDrawer ? "right" : "left",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: -4,
        }}
      >
        <MenuHeader>
          <FilterIcon size={ICON_SIZE.MEDIUM} />
          <span>Filter visibility</span>
        </MenuHeader>
        <ChecklistLabelItem
          id="postsFilter"
          name="Posts"
          checked={isPostsFilterActive}
          disabled={
            !isCommentsFilterActive &&
            isPostsFilterActive &&
            !isSystemMessagesFilterActive
          }
          onChange={togglePostsFilter}
        ></ChecklistLabelItem>
        <ChecklistLabelItem
          id="commentsFilter"
          name="Comments"
          checked={isCommentsFilterActive}
          disabled={
            isCommentsFilterActive &&
            !isPostsFilterActive &&
            !isSystemMessagesFilterActive
          }
          onChange={toggleCommentsFilter}
        ></ChecklistLabelItem>
        <ChecklistLabelItem
          id="activityFilter"
          name="Activity log"
          checked={isSystemMessagesFilterActive}
          disabled={
            !isCommentsFilterActive &&
            !isPostsFilterActive &&
            isSystemMessagesFilterActive
          }
          onChange={toggleSystemMessagesFilter}
        ></ChecklistLabelItem>
      </Menu>
    </Container>
  );
};

export default JobActivityHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-bottom: 1px solid var(--color-gray-bright);

  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 2px 3px -3px var(--color-gray-medium);
`;

const FilterButton = styled(IconButton)`
  ${(props) => !props.$isDrawer && "width: unset;"}

  .label {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 10px;
    margin-top: 2px;
    color: black;
  }

  .notificationIcon {
    position: absolute;
    right: 4px;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #00bcd4;
  }
`;

const MenuHeader = styled.div`
  color: black;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  width: 240px;
  border-bottom: 1px solid black;

  display: flex;
  align-items: center;
  padding: 0 16px 4px 16px;

  span {
    width: 100%;
    text-align: center;
    padding-top: 4px;
  }
`;

const DrawerHeading = styled.div`
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);

  svg {
    margin-right: 5px;
  }
`;
