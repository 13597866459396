export function getLocationSearchParam(
  name: string,
  location: Location = window.location
) {
  return new URLSearchParams(location.search).get(name);
}

export function getNonDomainUrl(startPage = "") {
  const index = window.location.hostname.indexOf(".app.");
  let hostname = window.location.hostname;
  if (index > -1) {
    hostname = window.location.hostname.slice(index + 1);
  }

  let url = window.location.protocol + "//" + hostname;

  if (startPage) {
    url += "/#" + startPage;
  }

  return url;
}
