import { useBranchEntity } from "st-shared/stores";
import styled from "styled-components";

import Flex from "../../elements/Flex";
import { BranchLogo } from "../Branch/BranchLogo";
import { useCommercialDocument } from "./CommercialDocumentContext";

export default function BranchDetails() {
  const { document, branchId, documentMethods } =
    useCommercialDocument() as any;

  const { displayBranchExternalName } = documentMethods.getDisplayOptions(
    document
  ) as any;

  const branch = useBranchEntity(branchId);
  const taxNumber = branch.taxNumber;

  return (
    <Wrapper>
      <BranchLogo id={branchId} />
      <Container>
        <BranchNameRow
          className={displayBranchExternalName ? "" : "hiddenField"}
        >
          <BranchName>{branch.externalName}</BranchName>
        </BranchNameRow>
        <BranchAddress>{branch.address}</BranchAddress>
        {taxNumber && <BranchTax>{taxNumber}</BranchTax>}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)``;

const Container = styled.div`
  color: black;
  font-size: 12px;
  line-height: 16px;
  margin-left: 30px;
  min-width: 150px;
`;

const BranchNameRow = styled(Flex)`
  align-items: center;
  &.hiddenField {
    color: var(--color-gray);
  }
`;

const BranchName = styled.div`
  padding: 6px 5px 4px;
  font-weight: bold;
`;

const BranchAddress = styled.div`
  padding: 5px;
  white-space: pre-wrap;
`;

const BranchTax = styled.div`
  padding: 5px;
  white-space: pre-wrap;
`;
