import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { jobItemEntityType } from "../../../lib/entities/jobItemEntity";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props)
});

const JobItemConnector = ({ jobItem, children }) => children(jobItem);

JobItemConnector.propTypes = {
  jobItem: jobItemEntityType.isRequired,
  children: PropTypes.func.isRequired
};

export default connect(mapState)(JobItemConnector);
