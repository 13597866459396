import { type TUserPreference, UserPreference } from "@streamtimefe/entities";
import { produce } from "immer";
import { isEmpty, isEqual } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import byKeyReducer from "../../helpers/byKeyReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";
import type { ChangedEntity } from "../types";
import { EntityNames } from "../types";
import type { UserPreferencesAction } from "./actions";
import type { UserPreferencesState } from "./types";

const initialState: UserPreferencesState = {
  byId: {},
  byKey: {},
};

function reduceChangedEntities(
  state: UserPreferencesState,
  changedEntities: ChangedEntity<TUserPreference>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.byKey = byKeyReducer(state.byKey, changedEntities);
  });
}

function userPreferencesReducer(
  state: UserPreferencesState = initialState,
  action: UserPreferencesAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return reduceChangedEntities(
        state,
        parseEntityPayload(state, action.payload.userPreferences)
      );
    case ENTITIES_REMOVED:
      if (action.payload.entityName !== EntityNames.UserPreferences) {
        return state;
      }
      return reduceChangedEntities(
        state,
        parseRemovedEntities(state, action.payload.ids)
      );
    case SET_USER_PREFERENCE: {
      const prevEntity = state.byKey[action.payload.key];

      if (prevEntity && isEqual(prevEntity.value, action.payload.value))
        return state;

      let newEntity: TUserPreference;

      if (prevEntity) {
        newEntity = produce<TUserPreference>(prevEntity, (draft) => {
          draft.value = action.payload.value;
        });
      } else {
        newEntity = UserPreference.create(
          action.payload.key,
          action.payload.value
        );
      }

      return reduceChangedEntities(state, [{ prevEntity, newEntity }]);
    }
    default:
      return state;
  }
}

export default userPreferencesReducer;
