import * as PropTypes from "prop-types";
import React from "react";
import { SCROLL_CONTEXTS } from ".";

const CallBackConsumer = ({ Context, children }) => (
  <Context.Consumer>
    {({
      getScrollOffsetX,
      getScrollOffsetY,
      getDateAtOffsetX,
      getDateOnPosition,
      getOffsetXAtDate,
      getDayWidth,
      getDaysFromWidth,
      goToToday,
      goToDate,
      goToRHSDate,
      goForward,
      goBackward,
      beforeZoom
    }) =>
      children({
        getScrollOffsetX,
        getScrollOffsetY,
        getDateAtOffsetX,
        getDateOnPosition,
        getOffsetXAtDate,
        getDayWidth,
        getDaysFromWidth,
        goToToday,
        goToDate,
        goToRHSDate,
        goForward,
        goBackward,
        beforeZoom
      })
    }
  </Context.Consumer>
);

CallBackConsumer.propTypes = {
  Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
  children: PropTypes.func.isRequired
};

export default CallBackConsumer;
