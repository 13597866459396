import * as PropTypes from "prop-types";
import React from "react";
import { searchWordMatches } from "st-shared/lib";
import { SearchMenuHeader } from "st-shared/module";

import { entityIdListType } from "../../../lib/types/entityTypes";
import {
  reactContentType,
  reactNodesType,
} from "../../../lib/types/reactTypes";
import PopoverMenu from "../Menu/PopoverMenu";

const defaultSearchOptions = (options, searchQuery) => {
  let matchedOptions = options;

  if (searchQuery)
    matchedOptions = matchedOptions.filter(({ searchString }) =>
      searchWordMatches(searchString, searchQuery)
    );

  return matchedOptions;
};

const defaultExcludeOptions = (options, excludeIds = []) =>
  options.filter(({ key }) => !(excludeIds || []).includes(Number(key)));

class SearchMenu extends React.PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchOptions: PropTypes.func,
    excludeOptions: PropTypes.func,
    excludeIds: entityIdListType,
    onClose: PropTypes.func,
    children: PropTypes.func.isRequired,
    HeaderComponent: PropTypes.func,
    HeaderComponentProps: PropTypes.shape({
      title: PropTypes.string,
      placeholder: PropTypes.string,
      getNoResultsMessage: PropTypes.func,
      noOptionsMessage: reactContentType,
      children: reactNodesType,
    }),
    FooterComponent: PropTypes.oneOfType([PropTypes.func, reactNodesType]),
    FooterComponentProps: PropTypes.shape({}),
  };

  static defaultProps = {
    searchOptions: defaultSearchOptions,
    excludeOptions: defaultExcludeOptions,
    excludeIds: [],
    onClose: null,
    HeaderComponent: SearchMenuHeader,
    HeaderComponentProps: {},
    FooterComponent: null,
    FooterComponentProps: {},
  };

  state = {
    searchQuery: "",
  };

  onClose = () => {
    const { onClose } = this.props;
    this.setState({ searchQuery: "" });
    onClose && onClose();
  };

  onSearch = (searchQuery) => {
    this.setState({ searchQuery });
  };

  onCheckClearSearch = () => {
    const { options, searchOptions, excludeOptions, excludeIds } = this.props;
    const { searchQuery } = this.state;
    const availableOptions = excludeOptions(options, excludeIds);
    const searchResults = searchOptions(availableOptions, searchQuery);

    if (searchResults.length <= 1) {
      this.setState({ searchQuery: "" });
    }
  };

  render() {
    const {
      options,
      searchOptions,
      excludeOptions,
      excludeIds,
      children,
      HeaderComponent,
      HeaderComponentProps,
      FooterComponent,
      FooterComponentProps,
      onClose,
      ...props
    } = this.props;
    const { searchQuery } = this.state;
    const availableOptions = excludeOptions(options, excludeIds);
    const searchResults = searchOptions(availableOptions, searchQuery);

    return (
      <PopoverMenu
        onClose={this.onClose}
        HeaderComponent={
          <HeaderComponent
            hasOptions={Boolean(availableOptions.length)}
            hasResults={Boolean(searchResults.length)}
            searchQuery={searchQuery}
            onSearch={this.onSearch}
            {...HeaderComponentProps}
          />
        }
        FooterComponent={
          FooterComponent !== null &&
          (typeof FooterComponent === "function" ? (
            <FooterComponent
              hasResults={Boolean(searchResults.length)}
              {...FooterComponentProps}
            />
          ) : (
            FooterComponent
          ))
        }
        {...props}
      >
        {searchResults.map(children)}
      </PopoverMenu>
    );
  }
}

export default SearchMenu;
