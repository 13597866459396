import { get, set, unset } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  getJobIdPhaseIdPath,
  getOrderId
} from "../../../../lib/entities/jobItemEntity";

export default (prevState, nextState, changedEntities) => {
  const touchedPaths = [];

  const jobItemOrderByJobIdPhaseId = {};

  const nextStateJobItemOrderByJobIdPhaseId = Object.assign(
    {},
    nextState.jobItemOrderByJobIdPhaseId
  );

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    if (prevEntity) pushOnce(touchedPaths, getJobIdPhaseIdPath(prevEntity));

    if (newEntity) pushOnce(touchedPaths, getJobIdPhaseIdPath(newEntity));
  });

  if (!touchedPaths.length) return {};

  touchedPaths.forEach(path => {
    const jobItemIds = get(nextState.idsByJobIdJobPhaseId, path, []);

    if (!jobItemIds.length) unset(nextStateJobItemOrderByJobIdPhaseId, path);
    else
      set(
        jobItemOrderByJobIdPhaseId,
        path,
        jobItemIds.slice().sort((jobItemId1, jobItemId2) => {
          const jobItem1 = get(nextState.byId, jobItemId1);
          const jobItem2 = get(nextState.byId, jobItemId2);
          return getOrderId(jobItem1) - getOrderId(jobItem2);
        })
      );
  });

  return Object.assign(nextState, {
    jobItemOrderByJobIdPhaseId: Object.assign(
      {},
      nextStateJobItemOrderByJobIdPhaseId,
      jobItemOrderByJobIdPhaseId
    )
  });
};
