import clsx from "clsx";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { primaryUserRoleNameCss } from "st-shared/module";
import styled from "styled-components";

import { SCHEDULE_JOB_ITEM_USER_UNASSIGN } from "../../../lib/constants";
import {
  getTotalUsedMinutes,
  jobItemUserEntityType,
} from "../../../lib/entities/jobItemUserEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectJobItemUserById } from "../../../redux/selectors/jobItemUserSelectors";
import { selectScheduleUiScheduleTotalsMode } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  ScheduleUi,
  ScheduleUiTotalsModes,
} from "../../../state/ui/schedule/types";
import { IconButton } from "../../elements/Button";
import CloseIcon from "../../elements/Icons/CloseIcon";
import { JobItemUserStatusSwitch } from "../../modules/JobItemUser/JobItemUserStatusSwitch";
import UserIconName from "../../modules/User/UserIconName";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import JobItemUserTotals from "./ScheduleGroupTotals/JobItemUserTotals";
import {
  containerCss,
  unassignButtonContainerCss,
} from "./ScheduleJobItemUserGroup.css";

const mapState = (state, props) => ({
  jobItemUser: selectJobItemUserById(state, props),
  scheduleTotalsMode: selectScheduleUiScheduleTotalsMode(state),
});

const mapDispatch = (dispatch) => ({
  doJobItemUserUnassign: (id, jobItemUser) =>
    dispatch(
      createAction(SCHEDULE_JOB_ITEM_USER_UNASSIGN, { id, jobItemUser })
    ),
});

class ScheduleJobItemUserGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    height: PropTypes.number.isRequired,
    jobItemUser: jobItemUserEntityType.isRequired,
    isTimeByPeople: PropTypes.bool.isRequired,
    scheduleTotalsMode: PropTypes.oneOf(values(ScheduleUiTotalsModes))
      .isRequired,
    doJobItemUserUnassign: PropTypes.func.isRequired,
  };

  static defaultProps = null;

  get showUnassignButton() {
    const { jobItemUser } = this.props;

    return !getTotalUsedMinutes(jobItemUser);
  }

  handleUnassignJobItemUser = () => {
    const { id, jobItemUser, doJobItemUserUnassign } = this.props;

    doJobItemUserUnassign(id, jobItemUser);
  };

  render() {
    const { id, jobItemUser, isTimeByPeople, height } = this.props;

    return (
      <div
        style={{ height }}
        className={clsx("ScheduleJobItemUserGroupContainer", containerCss)}
      >
        <JobItemUserContent>
          <div style={{ marginLeft: -4 }}>
            <JobItemUserStatusSwitch jobItemUser={jobItemUser} />
          </div>
          <UserIconName
            id={jobItemUser.userId}
            fontSize={12}
            style={{ marginLeft: 10, width: "calc(100% - 66px)" }}
            showRole
          />
          {this.showUnassignButton && (
            <div className={unassignButtonContainerCss}>
              <IconButton onClick={this.handleUnassignJobItemUser}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </JobItemUserContent>
        <ScheduleGroupTotals style={{ paddingTop: 10 }}>
          <JobItemUserTotals id={id} isTimeByPeople={isTimeByPeople} />
        </ScheduleGroupTotals>
      </div>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobItemUserGroup);

export const JobItemUserContent = styled.div`
  display: flex;
  width: calc(100% - ${ScheduleUi.View.GroupTotalsWidth}px);
  height: 50px;
  align-items: center;
  padding: 10px 0;
  position: relative;
  overflow: hidden;

  .${primaryUserRoleNameCss} {
    font-size: 12px;
    color: var(--color-charcoal);
  }

  &:hover .${unassignButtonContainerCss} {
    right: 0px;
  }
`;
