import { type TUserPreference } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";

import { createActionStore } from "../core";
import type { TUserPreferenceActions } from "./UserPreferenceStore.actions";
import { createUserPreferenceActions } from "./UserPreferenceStore.actions";

export type TUserPreferenceStore = {
  shouldSave: boolean;
  entities: Dictionary<string, TUserPreference>;
  saveDebounce: Dictionary<
    string,
    ReturnType<TUserPreferenceActions["createSaveDebounce"]>
  >;
};

export const UserPreferenceStore = createActionStore<
  TUserPreferenceStore,
  TUserPreferenceActions
>(
  () => ({ shouldSave: true, entities: {}, saveDebounce: {} }),
  createUserPreferenceActions
);
