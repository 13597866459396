import * as PropTypes from "prop-types";
import React from "react";
import { differenceInCalendarDays, simpleCompareAsc } from "../../../lib/dates";
import DateSticky, { JUSTIFY_STICKY } from "../../modules/DateSticky";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const JobTimelineRowConsumer = ({
  barStartDate,
  barEndDate,
  startDate,
  dueDate,
  children
}) => (
  <JobTimelineScrollContext.Consumer>
    {({
      viewportStartDate,
      viewportEndDate,
      getOffsetXAtDate,
      dayWidth,
      goToDate,
      goToRHSDate
    }) => {
      if (!barStartDate || !barEndDate) return null;

      const isRightOfViewport =
        simpleCompareAsc(barStartDate, viewportEndDate) > 0;
      const isLeftOfViewport =
        simpleCompareAsc(barEndDate, viewportStartDate) < 0;

      if (isLeftOfViewport)
        return (
          <DateSticky
            justify={JUSTIFY_STICKY.LEFT}
            goToDate={goToDate}
            goToRHSDate={goToRHSDate}
            startDate={startDate}
            dueDate={dueDate}
          />
        );

      if (isRightOfViewport)
        return (
          <DateSticky
            justify={JUSTIFY_STICKY.RIGHT}
            goToDate={goToDate}
            goToRHSDate={goToRHSDate}
            startDate={startDate}
            dueDate={dueDate}
          />
        );

      const classes = [];
      const left = getOffsetXAtDate(barStartDate);
      const days = differenceInCalendarDays(barEndDate, barStartDate) + 1;
      const width = days * dayWidth;

      if (startDate && !dueDate) classes.push("startOnly");
      if (!startDate && dueDate) classes.push("endOnly");

      return children({ left, days, width, classes });
    }}
  </JobTimelineScrollContext.Consumer>
);

JobTimelineRowConsumer.propTypes = {
  barStartDate: PropTypes.string,
  barEndDate: PropTypes.string,
  startDate: PropTypes.string,
  dueDate: PropTypes.string,
  children: PropTypes.func.isRequired
};

JobTimelineRowConsumer.defaultProps = {
  barStartDate: null,
  barEndDate: null,
  startDate: null,
  dueDate: null
};

export default JobTimelineRowConsumer;
