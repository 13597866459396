import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobItemId } from "../../index";
import { selectJobItemUi } from "./index";

export const selectIsExpandedJobItem = createCachedSelector(
  [selectJobItemUi],
  jobItem => get(jobItem, `isExpanded`, false)
)(selectPropsJobItemId);

export const useIsExpandedJobItem = jobItemId =>
  useSelector(state => selectIsExpandedJobItem(state, { jobItemId }));
