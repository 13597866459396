import { getUserId } from "../../../lib/entities/jobItemUserEntity";
import { getDisplayName } from "../../../lib/entities/userEntity";
import { selectUser } from "../user";
import { selectJobItemUser } from "./index";

export const selectUserNameByJobItemUserId = (state, { jobItemUserId }) => {
  const userId = getUserId(selectJobItemUser(state, { jobItemUserId }));
  const user = selectUser(state, { userId });
  return getDisplayName(user);
};
