import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { authenticationStore } from "st-shared/stores";

import {
  EMPTY_OBJECT,
  ENTITY_NAME_ACTIVITY_ENTRIES,
} from "../../lib/constants";
import { selectPropsId } from "./index";

export const selectActivityEntries = (state) =>
  state.entities[ENTITY_NAME_ACTIVITY_ENTRIES];

export const selectActivityEntryById = createSelector(
  selectActivityEntries,
  selectPropsId,
  (activityEntries, id) => get(activityEntries, `byId.${id}`)
);

export const selectIsActivityEntryCreatedByLoggedInUser = createSelector(
  selectActivityEntryById,
  (activityEntry) => {
    const loggedInUserId = authenticationStore().loggedInUserId;
    return Boolean(
      activityEntry && activityEntry.createdUserId === loggedInUserId
    );
  }
);

export const selectActivityEntriesUi = (state) => state.ui.activityEntries;

export const selectActivityEntryUi = createSelector(
  selectActivityEntriesUi,
  selectPropsId,
  (activityEntries, id) => get(activityEntries, id, EMPTY_OBJECT)
);

export const selectIsSavingActivityEntry = createSelector(
  selectActivityEntryUi,
  (activityEntryUi) => Boolean(activityEntryUi && activityEntryUi.isSaving)
);

export const selectIsDeletingActivityEntry = createSelector(
  selectActivityEntryUi,
  (activityEntryUi) => Boolean(activityEntryUi && activityEntryUi.isDeleting)
);

export const selectIsFetchingThread = createSelector(
  [selectActivityEntryUi],
  (activityEntryUi) => get(activityEntryUi, "isFetchingThread", true)
);

export const selectActivityEntryError = createSelector(
  [selectActivityEntryUi],
  (activityEntryUi) => get(activityEntryUi, "error", null)
);

export const useIsActivityEntryCreatedByLoggedInUser = (id) =>
  useSelector((state) =>
    selectIsActivityEntryCreatedByLoggedInUser(state, { id })
  );

export const useActivityEntryById = (id) =>
  useSelector((state) => selectActivityEntryById(state, { id }));

export const useIsFetchingThread = (id) =>
  useSelector((state) => selectIsFetchingThread(state, { id }));

export const useIsSavingActivityEntry = (id) =>
  useSelector((state) => selectIsSavingActivityEntry(state, { id }));

export const useIsDeletingActivityEntry = (id) =>
  useSelector((state) => selectIsDeletingActivityEntry(state, { id }));

export const useActivityEntryError = (id) =>
  useSelector((state) => selectActivityEntryError(state, { id }));
