import type {
  TFilterGroupTypeEnum,
  TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import {
  FilterGroupTypeEnum,
  LabelTypeEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { values } from "lodash-es";
import { Fragment, useRef, useState } from "react";

import type { AutoCompleteProps, SelectKey } from "../../../components";
import { AutoComplete, IconButton, MdAdd, Popover } from "../../../components";
import type { SearchOption } from "../../../entities";
import {
  useActiveBranchOptions,
  useActiveRoleOptions,
  useAllUserOptions,
  useCompanySearchOptions,
  useContactSearchOptions,
  useCustomerCurrency,
  useItemSearchOptions,
  useJobPhaseSearchOptions,
  useJobSearchOptions,
  useLabelSearchOptions,
  useRateCardOptions,
} from "../../../stores";
import type { FilterTypeText } from "../../../types";
import {
  FilterGroupTypeProperties,
  getConditionMatchName,
  getFilterName,
  getNextCondition,
} from "../../../types";
import { useFilterListener } from "..";
import { addButtonCss, boldText, inputCss, numberEditCss } from "../Filter.css";
import { OperatorButton } from "../OperatorButton";
import type { FilterDisplayTextProps, FilterProps } from "../types";
import { FilterDisplay } from "./FilterDisplay";
import { FilterElementDisplay } from "./FilterElementDisplay";
import type { FilterSelectProps } from "./FilterSelect";
import { FilterSelect } from "./FilterSelect";

const ValueMatchTypes = [
  { id: ValueMatchTypeEnum.Equals, name: "is" },
  { id: ValueMatchTypeEnum.NotEquals, name: "is not" },
  { id: ValueMatchTypeEnum.Contains, name: "contains" },
  { id: ValueMatchTypeEnum.NotContains, name: "does not contain" },
];
const ValueMatchTypesAnyNo = [
  { id: ValueMatchTypeEnum.AnyValue, name: "has any value" },
  { id: ValueMatchTypeEnum.NoValue, name: "has no value" },
];

const ValueMatchTypesAnyNoIds: TValueMatchTypeEnum[] = [
  ValueMatchTypeEnum.AnyValue,
  ValueMatchTypeEnum.NoValue,
];

export interface TextFilterProps extends FilterProps {}

export function TextFilter({
  uuid,
  filterGroup,
  onDelete,
  addFilter,
  setFilterGroupConditionMatch,
  setFilter,
  deleteFilters,
}: TextFilterProps) {
  const displayRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { filterGroupTypeId, filters, conditionMatchTypeId } = filterGroup;
  const properties = FilterGroupTypeProperties[
    filterGroupTypeId
  ] as FilterTypeText;

  const availableValueMatchTypes = properties.disableAnyValue
    ? ValueMatchTypes
    : [...ValueMatchTypes, ...ValueMatchTypesAnyNo];

  useFilterListener(uuid, filterGroup.id, "open", openEditMenu);

  function openEditMenu() {
    setAnchorEl(displayRef.current);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        addEmptyFilter();
      }
    });
  }

  function closeEditMenu() {
    setAnchorEl(null);
    requestAnimationFrame(() => {
      const deleteFilterIndexes: number[] = [];
      filters.forEach((filter, index) => {
        if (
          filter.value === "" &&
          !ValueMatchTypesAnyNoIds.includes(filter.valueMatchTypeId)
        ) {
          deleteFilterIndexes.push(index);
        }
      });

      if (
        filters.length === 0 ||
        filters.length === deleteFilterIndexes.length
      ) {
        onDelete(filterGroup.id);
      } else if (deleteFilterIndexes.length > 0) {
        deleteFilters(filterGroup.id, deleteFilterIndexes);
      }
    });
  }

  function onSetConditionMatch() {
    setFilterGroupConditionMatch(
      filterGroup.id,
      getNextCondition(conditionMatchTypeId)
    );
  }

  function onSelectChange(value: SelectKey, filterIndex: number) {
    let currentValue = filters[filterIndex].value;
    const valueMatchTypeId = value as TValueMatchTypeEnum;
    if (ValueMatchTypesAnyNoIds.includes(valueMatchTypeId)) {
      currentValue = "";
    }
    setFilter(filterGroup.id, filterIndex, {
      value: currentValue,
      valueMatchTypeId,
    });

    setTimeout(() => {
      const el = document.querySelector<HTMLInputElement>(
        `#filterElement${filterIndex}`
      );
      el?.focus();
    }, 30);
  }

  function onInputChange(value: string, filterIndex: number) {
    setFilter(filterGroup.id, filterIndex, {
      value,
      valueMatchTypeId: filters[filterIndex].valueMatchTypeId,
    });
  }

  function addEmptyFilter() {
    addFilter(filterGroup.id, {
      value: "",
      valueMatchTypeId: availableValueMatchTypes[0].id,
    });
    const nextFilterIndex = filters.length;
    setTimeout(() => {
      const el = document.querySelector<HTMLInputElement>(
        `#filterElement${nextFilterIndex}`
      );
      el?.focus();
    }, 30);
  }

  const options = useTextFilterOptions(filterGroupTypeId);

  return (
    <div>
      <FilterDisplay
        ref={displayRef}
        onClick={openEditMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <TextFilterDisplayText filterGroup={filterGroup} />
      </FilterDisplay>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: -36, horizontal: "center" }}
        PaperProps={{ className: numberEditCss.root }}
      >
        {filters.map((filter, index) => {
          return (
            <Fragment key={index}>
              <FilterElement
                selectValue={filter.valueMatchTypeId}
                selectOnChange={(value: SelectKey) =>
                  onSelectChange(value, index)
                }
                items={availableValueMatchTypes}
                inputId={`filterElement${index}`}
                inputValue={String(filter.value)}
                inputOnChange={(value: string) => onInputChange(value, index)}
                onDelete={
                  filters.length > 1
                    ? () => deleteFilters(filterGroup.id, [index])
                    : null
                }
                options={options}
              />
              {index !== filters.length - 1 && (
                <OperatorButton
                  onClick={onSetConditionMatch}
                  conditionMatchTypeId={conditionMatchTypeId}
                />
              )}
            </Fragment>
          );
        })}
        {filters.length > 0 &&
          (filters[filters.length - 1].value !== "" ||
            ValueMatchTypesAnyNoIds.includes(
              filters[filters.length - 1].valueMatchTypeId
            )) && (
            <IconButton
              className={addButtonCss}
              iconProps={{ icon: MdAdd }}
              onClick={addEmptyFilter}
            />
          )}
      </Popover>
    </div>
  );
}

interface FilterElementProps {
  items: FilterSelectProps["items"];
  selectValue: TValueMatchTypeEnum;
  selectOnChange: FilterSelectProps["onSelectionChange"];
  inputId: string;
  inputValue: AutoCompleteProps["value"];
  inputOnChange: AutoCompleteProps["onInputValueChange"];
  onDelete?: (() => void) | null;
  options: SearchOption[];
}

export function FilterElement({
  items,
  selectValue,
  selectOnChange,
  inputId,
  inputValue,
  inputOnChange,
  onDelete,
  options,
}: FilterElementProps) {
  return (
    <FilterElementDisplay onDelete={onDelete} className={numberEditCss.filter}>
      <FilterSelect
        aria-label="Text Filter Selector"
        items={items}
        selectedKey={selectValue}
        onSelectionChange={selectOnChange}
      />
      {!ValueMatchTypesAnyNoIds.includes(selectValue) && (
        <AutoComplete
          id={inputId}
          variant="secondary"
          type={"text"}
          className={inputCss}
          value={inputValue}
          onInputValueChange={inputOnChange}
          options={
            selectValue === ValueMatchTypeEnum.Equals ||
            selectValue === ValueMatchTypeEnum.NotEquals
              ? options
              : []
          }
        />
      )}
    </FilterElementDisplay>
  );
}

function useTextFilterOptions(
  filterGroupTypeId: TFilterGroupTypeEnum
): SearchOption[] {
  const companyOptions = useCompanySearchOptions();
  const companyLabelOptions = useLabelSearchOptions(LabelTypeEnum.Company);
  const jobOptions = useJobSearchOptions();
  const jobLabelOptions = useLabelSearchOptions(LabelTypeEnum.Job);
  const branchOptions = useActiveBranchOptions();
  const roleOptions = useActiveRoleOptions();
  const contactOptions = useContactSearchOptions();
  const contactLabelOptions = useLabelSearchOptions(LabelTypeEnum.Contact);
  const rateCardOptions = useRateCardOptions();
  const loggedExpenseLabelOptions = useLabelSearchOptions(
    LabelTypeEnum.LoggedExpense
  );
  const userLabelOptions = useLabelSearchOptions(LabelTypeEnum.User);
  const quoteLabelOptions = useLabelSearchOptions(LabelTypeEnum.Quote);
  const invoiceLabelOptions = useLabelSearchOptions(LabelTypeEnum.Invoice);
  const jobPhaseOptions = useJobPhaseSearchOptions();
  const itemOptions = useItemSearchOptions();
  const userOptions = useAllUserOptions();

  switch (filterGroupTypeId) {
    case FilterGroupTypeEnum.CompanyName:
    case FilterGroupTypeEnum.ExpenseCompany:
      return companyOptions;
    case FilterGroupTypeEnum.CompanyLabel:
      return companyLabelOptions;
    case FilterGroupTypeEnum.JobName:
      return jobOptions;
    case FilterGroupTypeEnum.JobLabel:
      return jobLabelOptions;
    case FilterGroupTypeEnum.JobBranchName:
    case FilterGroupTypeEnum.UserBranchName:
      return branchOptions;
    case FilterGroupTypeEnum.UserRoleName:
      return roleOptions;
    case FilterGroupTypeEnum.ContactName:
      return contactOptions;
    case FilterGroupTypeEnum.ContactLabel:
      return contactLabelOptions;
    case FilterGroupTypeEnum.RateCardName:
      return rateCardOptions;
    case FilterGroupTypeEnum.LoggedExpenseLabel:
      return loggedExpenseLabelOptions;
    case FilterGroupTypeEnum.UserLabel:
      return userLabelOptions;
    case FilterGroupTypeEnum.QuoteLabel:
      return quoteLabelOptions;
    case FilterGroupTypeEnum.InvoiceLabel:
      return invoiceLabelOptions;
    case FilterGroupTypeEnum.JobPhaseName:
      return jobPhaseOptions;
    case FilterGroupTypeEnum.ItemName:
      return itemOptions;
    case FilterGroupTypeEnum.UserDisplayName:
    case FilterGroupTypeEnum.JobLeadUserDisplayName:
    case FilterGroupTypeEnum.CompanyLeadUserDisplayName:
      return userOptions;
  }
  return [];
}

export function TextFilterDisplayText({ filterGroup }: FilterDisplayTextProps) {
  const customerCurrency = useCustomerCurrency();

  const { filterGroupTypeId, filters, conditionMatchTypeId } = filterGroup;
  const properties = FilterGroupTypeProperties[
    filterGroupTypeId
  ] as FilterTypeText;

  const availableValueMatchTypes = properties.disableAnyValue
    ? ValueMatchTypes
    : [...ValueMatchTypes, ...ValueMatchTypesAnyNo];

  function getDisplayText() {
    const filterValues = values(availableValueMatchTypes)
      .map((valueMatchType) => ({
        ...valueMatchType,
        values: filters
          .filter(
            ({ valueMatchTypeId }) => valueMatchTypeId === valueMatchType.id
          )
          .map(({ value }) => value),
      }))
      .filter(({ values }) => values.length)
      .map((filterValue) => {
        if (ValueMatchTypesAnyNoIds.includes(filterValue.id)) {
          return {
            id: filterValue.id,
            name: "has",
            values: [filterValue.name.slice(3)],
          };
        }
        return filterValue;
      });

    return (
      <>
        {filterValues.map((filterValue, filterValueIndex) => {
          return (
            <Fragment key={`${filterValue.name}${filterValueIndex}`}>
              {" "}
              {filterValue.name}
              {filterValue.values.map((value, index) => {
                return (
                  <Fragment key={`${filterValue.name}${index}`}>
                    {" "}
                    <span className={boldText}>{value}</span>
                    {index !== filterValue.values.length - 1 &&
                      ` ${getConditionMatchName(conditionMatchTypeId)}`}
                  </Fragment>
                );
              })}
              {filterValueIndex !== filterValues.length - 1 &&
                ` ${getConditionMatchName(conditionMatchTypeId)}`}
            </Fragment>
          );
        })}
      </>
    );
  }

  return (
    <>
      <span className={boldText}>
        {getFilterName(filterGroupTypeId, customerCurrency)}
      </span>
      {getDisplayText()}
    </>
  );
}
