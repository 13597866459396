import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE,
  SCHEDULE_JOB_EXTRA_HOURS_EXPAND,
  JOB_TIMELINE_ITEM_ROW_HEIGHT
} from "../../../lib/constants";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  selectScheduleJobUi,
  selectUserIdsByJobIdNoItem
} from "../../../redux/selectors/scheduleSelectors";
import Expander from "../../modules/Expander";
import ScheduleExtraHoursUserGroup from "./ScheduleExtraHoursUserGroup";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import { ScheduleGroupUnderline } from "./ScheduleGroupUnderline";

const mapState = (state, props) => ({
  isExpanded: selectScheduleJobUi(state, { jobId: props.id })
    .isExtraHoursExpanded,
  extraHoursUserIds: selectUserIdsByJobIdNoItem(state, props)
});

const mapDispatch = dispatch => ({
  doExpand: jobId =>
    dispatch(createAction(SCHEDULE_JOB_EXTRA_HOURS_EXPAND, { jobId })),
  doCollapse: jobId =>
    dispatch(createAction(SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE, { jobId }))
});

class ScheduleExtraHoursGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
    extraHoursUserIds: entityIdListType.isRequired
  };

  static defaultProps = {
    isExpanded: false
  };

  toggleExpand = () => {
    const { id, isExpanded, doExpand, doCollapse } = this.props;

    if (isExpanded) doCollapse(id);
    else doExpand(id);
  };

  render() {
    const { id, isExpanded, extraHoursUserIds } = this.props;

    if (!extraHoursUserIds.length) return null;

    return (
      <Wrapper>
        <Container onClick={this.toggleExpand}>
          <Expander
            isExpanded={isExpanded}
            title={
              isExpanded
                ? "Collapse extra hours scheduled"
                : "Expand extra hours scheduled"
            }
          />
          <ScheduleGroupUnderline flex>
            <Name>Extra hours scheduled</Name>
            <ScheduleGroupTotals />
          </ScheduleGroupUnderline>
        </Container>
        {isExpanded && (
          <div style={{ marginLeft: 30 }}>
            {extraHoursUserIds.map(extraHoursUserId => (
              <ScheduleExtraHoursUserGroup
                key={extraHoursUserId}
                id={id}
                userId={extraHoursUserId}
              />
            ))}
          </div>
        )}
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleExtraHoursGroup);

const Container = styled.div`
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  display: flex;
  cursor: pointer;
  &:hover ${Expander} {
    opacity: 1;
  }
  ${ScheduleGroupUnderline} {
    width: calc(100% - 30px);
  }
  &:last-child ${ScheduleGroupUnderline} {
    border-bottom: none;
  }
`;

const Name = styled.div`
  color: var(--color-gray-dark);
  font-size: 12px;
  font-weight: bold;
  padding: 7px 5px 0 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
`;

export const ExtraHoursUserGroup = styled(ScheduleGroupUnderline)`
  background: white;
  overflow: hidden;
  padding-left: 15px;
  align-items: flex-start;
`;
