import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  SCHEDULE_USER_JOB_COLLAPSE,
  SCHEDULE_USER_JOB_EXPAND,
} from "../../../lib/constants";
import {
  getCompanyName,
  getJobFullName,
  scheduleSearchResultEntityType,
} from "../../../lib/entities/scheduleSearchResultEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  selectBlockContainerHeightByUserIdJobId,
  selectScheduleUserJobUi,
} from "../../../redux/selectors/scheduleSelectors";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import Flex from "../../elements/Flex";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";

const mapState = (state, props) => {
  const { isExpanded = false } = selectScheduleUserJobUi(state, props);
  return {
    scheduleJob: selectJobListModel(state, { jobId: props.id }),
    blocksHeight: selectBlockContainerHeightByUserIdJobId(state, {
      id: props.userId,
      jobId: props.id,
    }),
    isExpanded,
  };
};

const mapDispatch = (dispatch) => ({
  doExpand: (userId, jobId) =>
    dispatch(createAction(SCHEDULE_USER_JOB_EXPAND, { userId, jobId })),
  doCollapse: (userId, jobId) =>
    dispatch(createAction(SCHEDULE_USER_JOB_COLLAPSE, { userId, jobId })),
});

class ScheduleUserJobGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    userId: entityIdType.isRequired,
    scheduleJob: scheduleSearchResultEntityType.isRequired,
    blocksHeight: PropTypes.number.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
  };

  toggleExpand = () => {
    const { id, userId, isExpanded, doExpand, doCollapse } = this.props;
    if (isExpanded) doCollapse(userId, id);
    else doExpand(userId, id);
  };

  render() {
    const { scheduleJob, isExpanded, blocksHeight } = this.props;

    return (
      <Wrapper>
        <Container onClick={this.toggleExpand}>
          <Expander
            isExpanded={isExpanded}
            title={isExpanded ? "Collapse Job" : "Expand Job"}
            style={{
              marginTop: 4,
              width: 50,
              alignSelf: "flex-start",
              height: 24,
            }}
          />
          <Underline>
            <JobName title={getJobFullName(scheduleJob)}>
              {getJobFullName(scheduleJob)}
            </JobName>
            <CompanyName title={getCompanyName(scheduleJob)}>
              {getCompanyName(scheduleJob)}
            </CompanyName>
          </Underline>
        </Container>
        {isExpanded && (
          <Spacer
            h={blocksHeight}
            style={{
              transition: "height 0.2s",
            }}
          />
        )}
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserJobGroup);

const Wrapper = styled.div`
  width: 100%;
  background: white;
`;

const Container = styled.div`
  width: 100%;
  height: ${ScheduleUi.User.JobRowHeight}px;
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover ${Expander} {
    opacity: 1;
  }
`;

const JobName = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

const CompanyName = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-gray-medium);
`;

const Underline = styled(Flex)`
  width: calc(100% - 50px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border-top: var(--border-thin-bright);
`;

export const UserJobGroupSearchingPulser = () => (
  <Container
    style={{ borderTop: "var(--border-thin-bright)", paddingLeft: 50 }}
  >
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "80%",
          height: 16,
          animation: "var(--animation-pulsate)",
          backgroundColor: "var(--color-gray-bright)",
          marginBottom: 4,
        }}
      />
      <div
        style={{
          width: "30%",
          height: 14,
          animation: "var(--animation-pulsate)",
          backgroundColor: "var(--color-gray-bright)",
          marginBottom: 2,
        }}
      />
    </div>
  </Container>
);
