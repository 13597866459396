import { get } from "lodash-es";
import {
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
  JOB_ITEM_USER_DELETE,
  JOB_ITEM_USER_DELETE_ERROR,
  JOB_ITEM_USER_DELETED,
  JOB_ITEM_USER_DELETING,
  JOB_ITEM_USER_EDIT
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onEdit = (state, { payload: { jobItemId, jobItemUsers } }) => {
  const editedJobItemUsers = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemUsers`, [])
  );
  const editedJobItemUserIds = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemUserIds`, [])
  );

  jobItemUsers.forEach(jobItemUser => {
    const user = jobItemUser.prev ? jobItemUser.prev : jobItemUser.new;
    if (!editedJobItemUserIds.includes(user.id)) {
      editedJobItemUsers.push(user);
      editedJobItemUserIds.push(user.id);
    }
  });

  return {
    ...state,
    [jobItemId]: {
      ...state[jobItemId],
      editedJobItemUsers,
      editedJobItemUserIds
    }
  };
};

const onSavedOrError = (state, { payload: { jobItemId } }) => ({
  ...state,
  [jobItemId]: {
    ...state[jobItemId],
    editedJobItemUsers: [],
    editedJobItemUserIds: []
  }
});

const onDelete = (state, { payload: { jobItemUser } }) => {
  const jobItemUserId = jobItemUser.id;
  const jobItemId = jobItemUser.jobItemId;

  const editedJobItemUsers = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemUsers`, [])
  );
  const editedJobItemUserIds = Object.assign(
    [],
    get(state, `${jobItemId}.editedJobItemUserIds`, [])
  );

  const index = editedJobItemUserIds.indexOf(jobItemUserId);
  if (index !== -1) {
    editedJobItemUsers.splice(index, 1);
    editedJobItemUserIds.splice(index, 1);
  }

  return {
    ...state,
    [jobItemId]: {
      ...state[jobItemId],
      editedJobItemUsers,
      editedJobItemUserIds
    }
  };
};

const onDeleteRequest = (state, { payload: { jobItemUserId } }) => ({
  ...state,
  [jobItemUserId]: {
    ...state[jobItemUserId],
    isDeleting: true,
    error: null
  }
});

const onDeleteSuccess = (state, { payload: { jobItemUserId } }) => ({
  ...state,
  [jobItemUserId]: { ...state[jobItemUserId], isDeleting: false }
});

const onDeleteError = (state, { payload: { jobItemUserId, error } }) => ({
  ...state,
  [jobItemUserId]: { ...state[jobItemUserId], isDeleting: false, error }
});

export default createReducer(
  {},
  {
    [JOB_ITEM_USER_EDIT]: onEdit,
    [JOB_ITEM_SAVED]: onSavedOrError,
    [JOB_ITEM_SAVE_ERROR]: onSavedOrError,
    [JOB_ITEM_USER_DELETE]: onDelete,
    [JOB_ITEM_USER_DELETING]: onDeleteRequest,
    [JOB_ITEM_USER_DELETED]: onDeleteSuccess,
    [JOB_ITEM_USER_DELETE_ERROR]: onDeleteError
  }
);
