import { Drawer } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsIsActivityDrawerOpen } from "../../../redux/selectors/jobDetails/ui/isActivityDrawerOpen";
import JobActivity from "./index";

const ActivityDrawer = () => {
  const dispatch = useDispatch();

  const open = useJobDetailsIsActivityDrawerOpen();

  const closeDrawer = () =>
    dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, false));

  return (
    <StyledDrawer
      anchor="right"
      elevation={0}
      open={open}
      onClose={closeDrawer}
    >
      <Container>
        <JobActivity isDrawer />
      </Container>
    </StyledDrawer>
  );
};

export default ActivityDrawer;

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Container = styled.div`
  background-color: var(--color-snow);
  width: 435px;
  height: 100%;
`;
