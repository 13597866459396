import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { JOB_ITEM_DEPENDANCY_SEGMENT_TYPE } from "../../../lib/constants";
import { jobItemDependancySegmentType } from "../../../lib/entities/jobItemDependancyEntity";
import { FrameworkException } from "../../../lib/exceptions/FrameworkException";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectVisibleJobItemDependancySegmentsByJobIdJobItemId } from "../../../redux/selectors/jobTimelineSelectors";
import { SCROLL_CONTEXTS } from "../ScrollContexts";
import SegmentBridgeLeft from "./SegmentTypes/SegmentBridgeLeft";
import SegmentBridgeRight from "./SegmentTypes/SegmentBridgeRight";
import SegmentCircleLeftDownCurveIn from "./SegmentTypes/SegmentCircleLeftDownCurveIn";
import SegmentCircleLeftDownCurveOut from "./SegmentTypes/SegmentCircleLeftDownCurveOut";
import SegmentCircleLeftDownSnakeIn from "./SegmentTypes/SegmentCircleLeftDownSnakeIn";
import SegmentCircleLeftUpCurveIn from "./SegmentTypes/SegmentCircleLeftUpCurveIn";
import SegmentCircleLeftUpCurveOut from "./SegmentTypes/SegmentCircleLeftUpCurveOut";
import SegmentCircleLeftUpSnakeIn from "./SegmentTypes/SegmentCircleLeftUpSnakeIn";
import SegmentCircleRightDownCurveIn from "./SegmentTypes/SegmentCircleRightDownCurveIn";
import SegmentCircleRightDownCurveOut from "./SegmentTypes/SegmentCircleRightDownCurveOut";
import SegmentCircleRightDownSnakeIn from "./SegmentTypes/SegmentCircleRightDownSnakeIn";
import SegmentCircleRightUpCurveIn from "./SegmentTypes/SegmentCircleRightUpCurveIn";
import SegmentCircleRightUpCurveOut from "./SegmentTypes/SegmentCircleRightUpCurveOut";
import SegmentCircleRightUpSnakeIn from "./SegmentTypes/SegmentCircleRightUpSnakeIn";

const {
  CIRCLE_LEFT_DOWN_CURVE_IN,
  CIRCLE_LEFT_DOWN_SNAKE_IN,
  CIRCLE_LEFT_DOWN_CURVE_OUT,
  CIRCLE_RIGHT_DOWN_CURVE_IN,
  CIRCLE_RIGHT_DOWN_SNAKE_IN,
  CIRCLE_RIGHT_DOWN_CURVE_OUT,
  CIRCLE_LEFT_UP_CURVE_IN,
  CIRCLE_LEFT_UP_SNAKE_IN,
  CIRCLE_LEFT_UP_CURVE_OUT,
  CIRCLE_RIGHT_UP_CURVE_IN,
  CIRCLE_RIGHT_UP_SNAKE_IN,
  CIRCLE_RIGHT_UP_CURVE_OUT,
  BRIDGE_LEFT,
  BRIDGE_RIGHT
} = JOB_ITEM_DEPENDANCY_SEGMENT_TYPE;

const mapState = (state, props) => ({
  jobItemDependancySegments: selectVisibleJobItemDependancySegmentsByJobIdJobItemId(
    state,
    props
  )
});

const SegmentsByJobItem = ({ Context, jobItemId, jobItemDependancySegments }) =>
  jobItemDependancySegments.map(jobItemDependancySegment => {
    const { jobItemDependancyId, segmentType } = jobItemDependancySegment;
    const componentProps = {
      key: jobItemDependancyId,
      Context,
      jobItemDependancySegment
    };

    switch (segmentType) {
      case CIRCLE_LEFT_DOWN_CURVE_IN:
        return <SegmentCircleLeftDownCurveIn {...componentProps} />;
      case CIRCLE_LEFT_DOWN_SNAKE_IN:
        return <SegmentCircleLeftDownSnakeIn {...componentProps} />;
      case CIRCLE_LEFT_DOWN_CURVE_OUT:
        return <SegmentCircleLeftDownCurveOut {...componentProps} />;
      case CIRCLE_RIGHT_DOWN_CURVE_IN:
        return <SegmentCircleRightDownCurveIn {...componentProps} />;
      case CIRCLE_RIGHT_DOWN_SNAKE_IN:
        return <SegmentCircleRightDownSnakeIn {...componentProps} />;
      case CIRCLE_RIGHT_DOWN_CURVE_OUT:
        return <SegmentCircleRightDownCurveOut {...componentProps} />;
      case CIRCLE_LEFT_UP_CURVE_IN:
        return <SegmentCircleLeftUpCurveIn {...componentProps} />;
      case CIRCLE_LEFT_UP_SNAKE_IN:
        return <SegmentCircleLeftUpSnakeIn {...componentProps} />;
      case CIRCLE_LEFT_UP_CURVE_OUT:
        return <SegmentCircleLeftUpCurveOut {...componentProps} />;
      case CIRCLE_RIGHT_UP_CURVE_IN:
        return <SegmentCircleRightUpCurveIn {...componentProps} />;
      case CIRCLE_RIGHT_UP_SNAKE_IN:
        return <SegmentCircleRightUpSnakeIn {...componentProps} />;
      case CIRCLE_RIGHT_UP_CURVE_OUT:
        return <SegmentCircleRightUpCurveOut {...componentProps} />;
      case BRIDGE_LEFT:
        return <SegmentBridgeLeft {...componentProps} />;
      case BRIDGE_RIGHT:
        return <SegmentBridgeRight {...componentProps} />;
      default:
        throw new FrameworkException(
          `Unknown Job Item Dependancy Segment Type ${segmentType} for Job Item ${jobItemId}`
        );
    }
  });

SegmentsByJobItem.propTypes = {
  Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
  jobId: entityIdType.isRequired,
  jobItemId: entityIdType.isRequired,
  jobItemDependancySegments: PropTypes.arrayOf(jobItemDependancySegmentType)
    .isRequired
};

export default connect(mapState)(SegmentsByJobItem);
