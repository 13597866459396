import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { sharedEmitter } from "st-shared/lib";
import styled from "styled-components";

import { DATE_FORMAT_DATE_SHORT_MONTH_YEAR } from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import PopoverDatePicker from "../../modules/Pickers/PopoverDatePicker";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";

const EstimatedDatePicker = ({
  focusKey,
  value,
  placeholder,
  onChange,
  pickerProps,
  openListenerEvent,
}) => {
  const jobItemId = useJobItemId();

  const [anchorEl, setAnchorEl] = useState(null);
  const blockChange = useRef(false);

  const [ref, focused] = useFocusStateRef({
    jobItemId,
    key: focusKey,
  });

  const onOpen = () => {
    setAnchorEl(ref.current);
    blockChange.current = true;
    setTimeout(() => {
      blockChange.current = false;
    }, 100);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onDateChange = (date) => {
    if (blockChange.current) return;

    onClose();
    onChange(date);
  };

  useEffect(() => {
    const open = (e) => {
      if (e.jobItemId === jobItemId) onOpen();
    };

    if (openListenerEvent) sharedEmitter.on(openListenerEvent, open);
    return () => {
      if (openListenerEvent) sharedEmitter.off(openListenerEvent, open);
    };
  }, [onOpen, jobItemId]);

  return (
    <>
      <DatePickerButton
        empty={!value}
        onClick={onOpen}
        ref={ref}
        focused={focused}
      >
        {value
          ? formatDate(value, DATE_FORMAT_DATE_SHORT_MONTH_YEAR)
          : placeholder}
      </DatePickerButton>
      <PopoverDatePicker
        popoverProps={{
          open: !!anchorEl,
          anchorEl,
          onClose,
          style: {},
        }}
        onChange={onDateChange}
        value={value}
        hasClear
        pickerProps={pickerProps}
      />
    </>
  );
};

EstimatedDatePicker.propTypes = {
  focusKey: PropTypes.number.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pickerProps: PropTypes.shape({}),
  openListenerEvent: PropTypes.string,
};

EstimatedDatePicker.defaultProps = {
  value: null,
  pickerProps: {},
  openListenerEvent: null,
};

export default EstimatedDatePicker;

const DatePickerButton = styled.button`
  cursor: pointer;
  color: black;
  text-decoration: underline;
  span {
    color: @font-color-black;
  }
  ${(props) => props.empty && `color: var(--color-gray-dark);`}
  &:hover {
    color: var(--color-charcoal);
  }
  transition: var(--transition-mui-color);
  ${(props) => props.focused && `color: var(--color-blue-active);`}
  white-space: nowrap;
`;
