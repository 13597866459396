import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_USER,
  DATA_ATTRIBUTE_USER,
  SCHEDULE_BLOCK_CREATE
} from "../../../lib/constants";
import { getDataIntValue, getPointerClientOffset } from "../../../lib/dom";
import { getTargetUtilisationContainerElement } from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";

const mapState = null;

const mapDispatch = dispatch => ({
  doCreateScheduleBlockModal: (date, userId, jobId, jobItemId, jobItemUserId) =>
    dispatch(
      createAction(SCHEDULE_BLOCK_CREATE, {
        date,
        userId,
        jobId,
        jobItemId,
        jobItemUserId
      })
    )
});

const getTargetContainerData = e => {
  const element = getTargetUtilisationContainerElement(e);
  return {
    containerNode: element,
    userId: getDataIntValue(element, DATA_ATTRIBUTE_USER),
    jobId: getDataIntValue(element, DATA_ATTRIBUTE_JOB),
    jobItemId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM),
    jobItemUserId: getDataIntValue(element, DATA_ATTRIBUTE_JOB_ITEM_USER)
  };
};

export default WrappedComponent => {
  class UtilisationClickHandlers extends React.PureComponent {
    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      doCreateScheduleBlockModal: PropTypes.func.isRequired,
      onClick: PropTypes.func,
      getDateOnPosition: PropTypes.func.isRequired
    };

    static defaultProps = {
      onClick: null
    };

    onClick = e => {
      const {
        canEditTeamLoggedTimes,
        doCreateScheduleBlockModal,
        getDateOnPosition,
        onClick
      } = this.props;

      const {
        containerNode,
        userId,
        jobId,
        jobItemId,
        jobItemUserId
      } = getTargetContainerData(e);

      if (canEditTeamLoggedTimes && containerNode && userId) {
        const pointerPosition = getPointerClientOffset(e);
        const date = getDateOnPosition(pointerPosition);
        doCreateScheduleBlockModal(
          date,
          userId,
          jobId,
          jobItemId,
          jobItemUserId
        );
      }
      return onClick && onClick(e);
    };

    get handlers() {
      return {
        onClick: this.onClick
      };
    }

    render() {
      const { props, handlers } = this;

      return <WrappedComponent {...{ ...props, ...handlers }} />;
    }
  }

  return connect(mapState, mapDispatch)(UtilisationClickHandlers);
};
