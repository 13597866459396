import { runtimeEnv } from "@streamtimefe/environment";
import { getLocalStorageAuthToken } from "@streamtimefe/utils";
import axios from "axios";
import {
  ApiException,
  getLocationSearchParam,
  getUserTimeZone,
} from "st-shared/lib";

import { getPusherSocketId } from "../WebAppAPI/pusher";

export const getAuthToken = () =>
  getLocationSearchParam("token") || getLocalStorageAuthToken();

const API_ROOT = (runtimeEnv()?.VITE_WEB_API_ROOT || "") + "/webapi";

const getHeaders = () => {
  const headers = {
    Authentication: getAuthToken(),
  };

  if (getPusherSocketId()) headers["Pusher-Socket-Id"] = getPusherSocketId();

  if (getUserTimeZone()) headers.Timezone = getUserTimeZone();

  return headers;
};

const catchResponse = (promise) =>
  promise.catch((error) => {
    const { response } = error;

    if (response)
      switch (response.status) {
        case 400:
        case 401:
        case 422:
        case 403:
        case 500:
          throw new ApiException(response.data, response);
        case 404:
          throw new ApiException("404 resource not found", response);
        default:
          throw error;
      }

    throw error;
  });

const fetch = (endpoint, params, config) =>
  catchResponse(
    axios.get(API_ROOT + endpoint, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );

const post = (endpoint, data, params, config) =>
  catchResponse(
    axios.post(API_ROOT + endpoint, data, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );

const put = (endpoint, data, params, config) =>
  catchResponse(
    axios.put(API_ROOT + endpoint, data, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );

const _delete = (endpoint, params, config) =>
  catchResponse(
    axios.delete(API_ROOT + endpoint, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );

const makeURL = (url) => API_ROOT + url;

const API = {
  delete: _delete,
  fetch,
  post,
  put,
  makeURL,
};

export default API;
