import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { forEach, isArray } from "lodash-es";
import { useMemo } from "react";

import type { TEntityId } from "../../entities/Entity";
import { useCustomerCurrency, usePermissions } from "../../stores";
import type { FilterSearchOption } from "../../types";
import { getFilterName } from "../../types";
import { ColumnTooltip, Highlight } from "..";
import { SearchMenu, searchMenuItem } from "../SearchMenu";
import { filterMenuPaperCss } from "./FilterSearchMenu.css";

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: FilterSearchOption[] | Record<string, FilterSearchOption[]>;
  onPick: (key: TFilterGroupTypeEnum) => void;
  title?: string;
}

export function FilterSearchMenu({
  anchorEl,
  onClose,
  options,
  onPick,
  title = "Add Filter",
}: Props) {
  const customerCurrency = useCustomerCurrency();
  const { canAccessCostRates, canViewJobFinancials } = usePermissions();

  function permissionFilter(option: FilterSearchOption): boolean {
    if (option.entity.containsCostRate && !canAccessCostRates) {
      return false;
    }
    if (option.entity.containsFinancial && !canViewJobFinancials) {
      return false;
    }
    return true;
  }

  const filteredOptions = useMemo(() => {
    if (isArray(options)) {
      return options.filter(permissionFilter);
    }
    const filtered: Record<string, FilterSearchOption[]> = {};
    forEach(options, (value, key) => {
      filtered[key] = value.filter(permissionFilter);
    });
    return filtered;
  }, [options, canAccessCostRates, canViewJobFinancials]);

  function RenderOption(
    option: FilterSearchOption,
    onPick: (key: TEntityId) => void,
    query: string
  ) {
    function renderRow() {
      return (
        <div
          key={option.key}
          className={searchMenuItem}
          onClick={() => onPick(option.key as TFilterGroupTypeEnum)}
        >
          <Highlight
            text={getFilterName(
              option.key as TFilterGroupTypeEnum,
              customerCurrency
            )}
            query={query}
          />
        </div>
      );
    }

    if (option.entity.tooltip) {
      return (
        <ColumnTooltip
          key={option.key}
          type={option.entity.tooltip}
          title={option.value}
        >
          {renderRow()}
        </ColumnTooltip>
      );
    }

    return renderRow();
  }

  return (
    <SearchMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -20,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      options={filteredOptions}
      onClose={onClose}
      headerProps={{ title, placeholder: "Start typing..." }}
      paperRootClassName={filterMenuPaperCss}
      onPickOption={onPick as (key: TEntityId) => void}
      renderOption={RenderOption}
    />
  );
}
