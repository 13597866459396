import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin";
import createLinkifyPlugin from "draft-js-link-detection-plugin";
import imageHtmlPlugin from "./ImageHtmlPlugin";
import createInlineToolbarPlugin from "./inlineToolbarPlugin";
import createMentionPlugin from "./mentionPlugin";
import createSoftNewLinePlugin from "./softNewLinePlugin";
import createStaticToolbarPlugin from "./staticToolbarPlugin";

export const createPostPlugins = () => {
  const inlineToolbarPlugin = createInlineToolbarPlugin();
  const blockBreakoutPlugin = createBlockBreakoutPlugin();
  const linkifyPlugin = createLinkifyPlugin();
  const softNewLinePlugin = createSoftNewLinePlugin();

  const { InlineToolbar, anchorPlugin } = inlineToolbarPlugin;

  const plugins = [
    inlineToolbarPlugin,
    anchorPlugin,
    linkifyPlugin,
    softNewLinePlugin,
    blockBreakoutPlugin,
    imageHtmlPlugin,
  ];

  return {
    plugins,
    InlineToolbar,
  };
};

export const createCommentPlugins = () => {
  const staticToolbarPlugin = createStaticToolbarPlugin();
  const blockBreakoutPlugin = createBlockBreakoutPlugin();
  const linkifyPlugin = createLinkifyPlugin();
  const softNewLinePlugin = createSoftNewLinePlugin();
  const { mentionPlugin, MentionSuggestions } = createMentionPlugin();

  const { StaticToolbar } = staticToolbarPlugin;

  const plugins = [
    staticToolbarPlugin,
    linkifyPlugin,
    softNewLinePlugin,
    blockBreakoutPlugin,
    mentionPlugin,
    imageHtmlPlugin,
  ];

  return {
    plugins,
    StaticToolbar,
    MentionSuggestions,
  };
};

export const createRichTextFieldPlugins = (simplified) => {
  const inlineToolbarPlugin = createInlineToolbarPlugin(simplified);
  const blockBreakoutPlugin = createBlockBreakoutPlugin();
  const linkifyPlugin = createLinkifyPlugin();
  const softNewLinePlugin = createSoftNewLinePlugin();

  const { InlineToolbar, anchorPlugin } = inlineToolbarPlugin;

  const plugins = [
    inlineToolbarPlugin,
    anchorPlugin,
    linkifyPlugin,
    softNewLinePlugin,
    blockBreakoutPlugin,
  ];

  return {
    plugins,
    InlineToolbar,
  };
};
