import numeral from "numeral";
import * as PropTypes from "prop-types";
import { JobStatuses } from "st-shared/entities/JobStatus";

import { addDays } from "../dates";
import { FrameworkException } from "../exceptions/FrameworkException";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import { companyEntityType, getLastJobNumber, getName } from "./companyEntity";
import { contactEntityType, getFullName } from "./contactEntity";

export const jobShape = {
  id: entityIdType.isRequired,
  branchId: entityIdType.isRequired,
  budget: entityFieldDecimalType,
  company: companyEntityType.isRequired,
  contact: contactEntityType,
  jobStatus: typeEntityType.isRequired,
  isBillable: PropTypes.bool.isRequired,
  name: PropTypes.string,
  number: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  estimatedStartDate: PropTypes.string,
  estimatedEndDate: PropTypes.string,
  earliestStartDate: PropTypes.string,
  latestEndDate: PropTypes.string,
  exchangeRate: entityFieldDecimalType.isRequired,
  rateCardId: PropTypes.number.isRequired,
  totalIncompleteMinutes: entityFieldDecimalType,
  totalLoggedMinutes: entityFieldDecimalType,
  totalPlannedMinutes: entityFieldDecimalType,
  jobCurrencyTotalIncompleteTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalPlannedTimeExTax: entityFieldDecimalType,
  completedDatetime: PropTypes.string,
  jobCurrencyTotalDraftInvoicesExTax: entityFieldDecimalType,
  jobCurrencyTotalAwaitingPaymentInvoicesExTax: entityFieldDecimalType,
  jobCurrencyTotalPaidInvoicesExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeCostExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedExpensesExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedExpensesCostExTax: entityFieldDecimalType,
  jobCurrencyTotalPlannedExpensesExTax: entityFieldDecimalType,
  jobCurrencyBudget: entityFieldDecimalType,
  jobLeadUserId: entityIdType.isRequired,
};

export const jobEntityType = PropTypes.shape(jobShape);

export const getBranchId = ({ branchId }) => branchId;

export const getJobLeadUserId = ({ jobLeadUserId }) => jobLeadUserId;

export const getJobStatusId = ({ jobStatus }) => jobStatus.id;

export const getCompletedDatetime = ({ completedDatetime }) =>
  completedDatetime;

export const getEstimatedEndDate = ({ estimatedEndDate }) => estimatedEndDate;

export const isDeleted = ({ jobStatus }) =>
  jobStatus.id === JobStatuses.Deleted;

export const isArchived = ({ jobStatus }) =>
  jobStatus.id === JobStatuses.Archived;

export const isPlanning = ({ jobStatus }) =>
  jobStatus.id === JobStatuses.Planning;

export const isComplete = ({ jobStatus }) =>
  jobStatus.id === JobStatuses.Complete;

export const isNotFinalised = ({ jobStatus }) =>
  jobStatus.id === JobStatuses.Planning ||
  jobStatus.id === JobStatuses.Scheduled;

export const getJobFullName = ({ name, number }) =>
  `${number ? `[${number}] ` : ""}${name}`;

export const getJobName = ({ name }) => name;

export const getJobNumber = ({ number }) => number;

export const getJobCompany = ({ company }) => company;

export const getJobCompanyId = ({ company }) => company && company.id;

export const getJobCompanyName = ({ company }) =>
  company ? getName(company) : "";

export const getJobCompanyLastJobNumber = ({ company }) =>
  company && getLastJobNumber(company);

export const getJobContact = ({ contact }) => contact;

export const getContactFullName = ({ contact }) =>
  contact ? getFullName(contact) : "";

export const getJobPurchaseOrderNumber = ({ purchaseOrderNumber }) =>
  purchaseOrderNumber || "";

export const getJobExchangeRate = ({ exchangeRate }) => exchangeRate;

export const getJobStatusName = (jobStatusId) => {
  switch (jobStatusId) {
    case JobStatuses.Scheduled:
      return "In Play";
    case JobStatuses.Complete:
      return "Complete";
    case JobStatuses.Deleted:
      return "Deleted";
    case JobStatuses.Archived:
      return "Archived";
    case JobStatuses.Planning:
      return "Paused";
    default:
      throw new FrameworkException(`Unknown job status id '${jobStatusId}'`);
  }
};

export const getJobNormalizedStatus = ({ jobStatus }) => {
  switch (jobStatus.id) {
    case JobStatuses.Planning:
      return "planning";
    case JobStatuses.Complete:
      return "complete";
    case JobStatuses.Scheduled:
      return "scheduled";
    default:
      return "";
  }
};

export const getJobStatusIconClass = (jobStatusId) => {
  switch (jobStatusId) {
    case JobStatuses.Planning:
      return "planning";
    case JobStatuses.Scheduled:
      return "scheduled";
    case JobStatuses.Complete:
      return "complete";
    case JobStatuses.Archived:
    case JobStatuses.Deleted:
      return "archived";
    default:
      return "";
  }
};

export const moveDays = (entity, days) => {
  const estimatedStartDate =
    entity.estimatedStartDate && addDays(entity.estimatedStartDate, days);
  const estimatedEndDate =
    entity.estimatedEndDate && addDays(entity.estimatedEndDate, days);

  return {
    ...entity,
    estimatedStartDate,
    estimatedEndDate,
  };
};

export const getRateCardId = ({ rateCardId }) => rateCardId;

export const getPageUrl = (jobId) => `/#job/${jobId}`;

export const mergeScheduleLoggedTimeWithJob = (scheduleLoggedTime, job) => ({
  ...scheduleLoggedTime,
  companyName: getJobCompanyName(job),
  jobFullName: getJobFullName(job),
  jobStatusId: getJobStatusId(job),
});

export const getJobCurrencyTotalDraftInvoicesExTax = ({
  jobCurrencyTotalDraftInvoicesExTax,
}) => asDecimal(jobCurrencyTotalDraftInvoicesExTax);

export const getJobCurrencyTotalAwaitingPaymentInvoicesExTax = ({
  jobCurrencyTotalAwaitingPaymentInvoicesExTax,
}) => asDecimal(jobCurrencyTotalAwaitingPaymentInvoicesExTax);

export const getJobCurrencyTotalPaidInvoicesExTax = ({
  jobCurrencyTotalPaidInvoicesExTax,
}) => asDecimal(jobCurrencyTotalPaidInvoicesExTax);

export const getJobCurrencyTotalLoggedTimeExTax = ({
  jobCurrencyTotalLoggedTimeExTax,
}) => asDecimal(jobCurrencyTotalLoggedTimeExTax);

export const getJobCurrencyTotalLoggedTimeCostExTax = ({
  jobCurrencyTotalLoggedTimeCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedTimeCostExTax);

export const getJobCurrencyTotalLoggedExpensesExTax = ({
  jobCurrencyTotalLoggedExpensesExTax,
}) => asDecimal(jobCurrencyTotalLoggedExpensesExTax);

export const getJobCurrencyTotalLoggedExpensesCostExTax = ({
  jobCurrencyTotalLoggedExpensesCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedExpensesCostExTax);

export const getJobCurrencyTotalPlannedExpensesExTax = ({
  jobCurrencyTotalPlannedExpensesExTax,
}) => asDecimal(jobCurrencyTotalPlannedExpensesExTax);

export const getJobCurrencyTotalPlannedExTax = ({
  jobCurrencyTotalPlannedExTax,
}) => asDecimal(jobCurrencyTotalPlannedExTax);

export const getJobCurrencyTotalPlannedTimeExTax = ({
  jobCurrencyTotalPlannedTimeExTax,
}) => asDecimal(jobCurrencyTotalPlannedTimeExTax);

export const getJobCurrencyTotalPlannedExpensesCostExTax = ({
  jobCurrencyTotalPlannedExpensesCostExTax,
}) => asDecimal(jobCurrencyTotalPlannedExpensesCostExTax);

export const getTotalLoggedMinutes = ({ totalLoggedMinutes }) =>
  asDecimal(totalLoggedMinutes);

export const getTotalUsedScheduledMinutes = ({
  totalIncompleteMinutes,
  totalLoggedMinutes,
}) =>
  numeral(asDecimal(totalIncompleteMinutes))
    .add(asDecimal(totalLoggedMinutes))
    .value();

export const getTotalIncompleteMinutes = ({ totalIncompleteMinutes }) =>
  asDecimal(totalIncompleteMinutes);

export const getTotalPlannedMinutes = ({ totalPlannedMinutes }) =>
  asDecimal(totalPlannedMinutes);

export const getJobCurrencyBudget = ({ jobCurrencyBudget }) =>
  jobCurrencyBudget;

export const getJobCurrencyTotalApprovedQuoteExTax = ({
  jobCurrencyTotalApprovedQuoteExTax,
}) => asDecimal(jobCurrencyTotalApprovedQuoteExTax);

export const getJobCurrencyTotalInvoicedExTax = ({
  jobCurrencyTotalInvoicedExTax,
}) => asDecimal(jobCurrencyTotalInvoicedExTax);

export const getJobCurrencyTotalLoggedExTax = ({
  jobCurrencyTotalLoggedExTax,
}) => asDecimal(jobCurrencyTotalLoggedExTax);

export const getJobCurrencyTotalLoggedCostExTax = ({
  jobCurrencyTotalLoggedCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedCostExTax);
