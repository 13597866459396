import type { MouseEvent } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { timeAllocationMethodObj } from "st-shared/entities";
import { getPermissionsSnapshot } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_TIMELINE_ITEM_ROW_HEIGHT,
  SCHEDULE_JOB_ITEM_ADD_USER,
  SCHEDULE_JOB_ITEM_COLLAPSE,
  SCHEDULE_JOB_ITEM_EXPAND,
} from "../../../lib/constants";
import { getTodayDate, maxDate } from "../../../lib/dates";
import { getJobItemName } from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";
import {
  selectJobItemUserIdsByJobItemId,
  selectUserIdsByJobItemId,
} from "../../../redux/selectors/jobItemUserSelectors";
import {
  selectScheduleJobItemRoleRowHeightsByJobItemId,
  selectScheduleJobItemUiIsExpanded,
  selectScheduleJobItemUserRowHeightsByJobItemId,
} from "../../../redux/selectors/scheduleSelectors";
import { actionJobItemRoleCreate } from "../../../state/entities/jobItemRole/actions";
import { selectOrderedJobItemRoleIdsByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectOrderedJobItemRoleIdsByJobItemId";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { IconButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import PersonAddIcon from "../../elements/Icons/PersonAddIcon";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import JobItemUserRoleMenu from "../../modules/UserRoleMenus/JobItemUserRoleMenu";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import JobItemTotals from "./ScheduleGroupTotals/JobItemTotals";
import { ScheduleGroupUnderline } from "./ScheduleGroupUnderline";
import { ScheduleJobItemRoleGroup } from "./ScheduleJobItemRoleGroup";
import ScheduleJobItemUserGroup from "./ScheduleJobItemUserGroup";

interface ScheduleJobItemGroupProps {
  id: number;
  collapseItems: () => void;
  expandItems: () => void;
}

function ScheduleJobItemGroup({
  id,
  collapseItems,
  expandItems,
}: ScheduleJobItemGroupProps) {
  const jobItem = useSelector((state) => selectJobItemById(state, { id }));

  const jobItemUserIds = useSelector((state) =>
    selectJobItemUserIdsByJobItemId(state, { id })
  );
  const jobItemUserHeightsById = useSelector((state) =>
    selectScheduleJobItemUserRowHeightsByJobItemId(state, { id })
  );
  const jobItemRoleIds = useSelector((state) =>
    selectOrderedJobItemRoleIdsByJobItemId(state, {
      jobItemId: id,
    })
  );
  const jobItemRoleHeightsById = useSelector((state) =>
    selectScheduleJobItemRoleRowHeightsByJobItemId(state, { jobItemId: id })
  );
  const userIds = useSelector((state) =>
    selectUserIdsByJobItemId(state, { id })
  );
  const canEditJobs = useSelector(
    (state) => getPermissionsSnapshot().canEditJobs
  );
  const isExpanded =
    useSelector((state) => selectScheduleJobItemUiIsExpanded(state, { id })) ||
    false;

  const dispatch = useDispatch();

  function doExpand(id: number) {
    dispatch(createAction(SCHEDULE_JOB_ITEM_EXPAND, { id }));
  }

  function doCollapse(id: number) {
    dispatch(createAction(SCHEDULE_JOB_ITEM_COLLAPSE, { id }));
  }

  function addJobItemUser(jobItemId: number, userId: number) {
    dispatch(createAction(SCHEDULE_JOB_ITEM_ADD_USER, { jobItemId, userId }));
  }

  function addJobItemRole(jobItemId: number, roleId: number) {
    dispatch(actionJobItemRoleCreate(jobItemId, roleId, "api"));
  }

  const [addTeamMemberAnchorEl, setAddTeamMemberAnchorEl] =
    useState<HTMLElement | null>(null);

  function getDefaultStartDate(viewportStartDate: string | null) {
    return maxDate(
      jobItem.estimatedStartDate,
      viewportStartDate,
      getTodayDate()
    );
  }

  function getDefaultEndDate(
    viewportStartDate: string | null,
    viewportEndDate: string | null
  ) {
    const startDate = getDefaultStartDate(viewportStartDate);
    const endDate = jobItem.estimatedEndDate || viewportEndDate;
    return maxDate(startDate, endDate);
  }

  function toggleExpand(e: MouseEvent<HTMLElement>) {
    if (!canToggle) return;

    if (isExpanded) e.altKey ? collapseItems() : doCollapse(id);
    else e.altKey ? expandItems() : doExpand(id);
  }

  function openAddTeamMemberMenu(e: MouseEvent<HTMLElement>) {
    setAddTeamMemberAnchorEl(
      (e.target as HTMLElement)!.closest(
        ".ScheduleJobItemGroupNameContainer"
      ) as HTMLElement
    );
  }

  function closeAddTeamMemberMenu() {
    setAddTeamMemberAnchorEl(null);
  }

  function onPickUser(userId: number) {
    addJobItemUser(id, userId);
    doExpand(id);
  }

  function onPickRole(roleId: number) {
    addJobItemRole(id, roleId);
    doExpand(id);
  }

  const isTimeByPeople = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  ).isPeople();
  const canToggle = jobItemUserIds.length > 0 || jobItemRoleIds.length > 0;

  return (
    <Wrapper className="ScheduleJobItemGroupWrapper">
      <Container className="ScheduleJobItemGroupContainer">
        <Spacer w={30} />
        <ScheduleGroupUnderline flex>
          <div style={{ marginLeft: -8 }}>
            {canToggle ? (
              <Expander
                onClick={toggleExpand}
                isExpanded={isExpanded}
                title={isExpanded ? "Collapse Item" : "Expand Item"}
              />
            ) : (
              <Spacer w={30} />
            )}
          </div>
          <NameContainer className="ScheduleJobItemGroupNameContainer">
            <Name
              onClick={toggleExpand}
              className={canToggle ? "canToggle" : ""}
            >
              {getJobItemName(jobItem)}
            </Name>
            <Icons>
              {!jobItem.isBillable && (
                <Tooltip title="This is a non-billable item" placement="top">
                  <NonBillableIconWrapper>
                    <NonBillableIcon size={ICON_SIZE.MEDIUM} />
                  </NonBillableIconWrapper>
                </Tooltip>
              )}
              {canEditJobs && (
                <JobItemActions>
                  <IconButton onClick={openAddTeamMemberMenu}>
                    <PersonAddIcon />
                  </IconButton>
                </JobItemActions>
              )}
            </Icons>
          </NameContainer>
          <ScheduleGroupTotals>
            <JobItemTotals id={id} />
          </ScheduleGroupTotals>
        </ScheduleGroupUnderline>
        {canEditJobs && addTeamMemberAnchorEl && (
          <ScheduleScrollContext.Consumer>
            {({ viewportStartDate, viewportEndDate }) => (
              <JobItemUserRoleMenu
                anchorEl={addTeamMemberAnchorEl}
                entityId={jobItem.id}
                type="jobItem"
                defaultStartDate={getDefaultStartDate(viewportStartDate)}
                defaultEndDate={getDefaultEndDate(
                  viewportStartDate,
                  viewportEndDate
                )}
                pickUser={onPickUser}
                pickRole={onPickRole}
                close={closeAddTeamMemberMenu}
                showTitle={true}
                canCreateNewUser={false}
                fullscreen={true}
                transformOrigin={{
                  horizontal: -2,
                  vertical: "top",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            )}
          </ScheduleScrollContext.Consumer>
        )}
      </Container>
      {isExpanded && (
        <div style={{ marginLeft: 30 }}>
          {jobItemUserIds.map((jobItemUserId: number) => (
            <ScheduleJobItemUserGroup
              key={jobItemUserId}
              id={jobItemUserId}
              height={jobItemUserHeightsById[jobItemUserId]}
              isTimeByPeople={isTimeByPeople}
            />
          ))}
          {jobItemRoleIds.map((jobItemRoleId) => (
            <ScheduleJobItemRoleGroup
              key={jobItemRoleId}
              jobItemRoleId={jobItemRoleId}
              height={jobItemRoleHeightsById[jobItemRoleId]}
            />
          ))}
        </div>
      )}
    </Wrapper>
  );
}

export default ScheduleJobItemGroup;

const Container = styled.div`
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  display: flex;
  &:hover ${Expander} {
    opacity: 1;
  }
  ${ScheduleGroupUnderline} {
    width: calc(100% - 30px);
  }
`;
const Name = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 7px 5px 0 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &.canToggle {
    cursor: pointer;
  }
`;

const Icons = styled.div`
  display: flex;
`;

const NonBillableIconWrapper = styled.div`
  padding-top: 3px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    fill: var(--color-gray-dark);
  }
`;

const JobItemActions = styled(Flex)`
  width: 0;
  opacity: 0;
  transition:
    opacity 0.2s,
    width 0.2s;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const NameContainer = styled(Flex)`
  width: calc(100% - ${ScheduleUi.View.GroupTotalsWidth}px);
  height: 100%;
  overflow: hidden;
  &:hover {
    ${JobItemActions} {
      width: 30px;
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
`;
