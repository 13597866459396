import { plate } from "st-shared/external";

import { ELEMENT_COLUMN_INPUT } from "../createColumnPlugin";
import { TColumnInputElement } from "../types";

export const isNodeColumnInput = <V extends plate.Value>(
  editor: plate.PlateEditor<V>,
  node: plate.TNode
): node is TColumnInputElement => {
  return node.type === plate.getPluginType(editor, ELEMENT_COLUMN_INPUT);
};
