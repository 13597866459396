import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";
import { SavedSegmentTypeEnum } from "@streamtimefe/entities";

export const UserPreferenceKeys = {
  ReportingSideMenuOpen: "REPORTING_SIDE_MENU_OPEN",
  ReportingSideSelectedTab: "REPORTING_SIDE_MENU_SELECTED_TAB",
  ScheduleLeftSidebarWidth: "SCHEDULE_LEFT_SIDEBAR_WIDTH",
  ...createSavedSegmentSidebarUserPreferences(),
  ...createEncodedSearchUserPreferences(),
} as const;

export type TUserPreferenceKeys =
  (typeof UserPreferenceKeys)[keyof typeof UserPreferenceKeys];

function createSavedSegmentSidebarUserPreferences() {
  type SavedSegmentSidebarKeys = Record<
    `SavedSegmentSidebar_${TSavedSegmentTypeEnum}`,
    `SAVED_SEGMENT_SIDEBAR_${TSavedSegmentTypeEnum}`
  >;
  const keys: SavedSegmentSidebarKeys = {} as SavedSegmentSidebarKeys;
  Object.values(SavedSegmentTypeEnum).map((type) => {
    keys[`SavedSegmentSidebar_${type}`] = `SAVED_SEGMENT_SIDEBAR_${type}`;
  });
  return keys;
}

function createEncodedSearchUserPreferences() {
  type SavedSegmentSidebarKeys = Record<
    `EncodedSearch_${TSavedSegmentTypeEnum}`,
    `ENCODED_SEARCH_${TSavedSegmentTypeEnum}`
  >;
  const keys: SavedSegmentSidebarKeys = {} as SavedSegmentSidebarKeys;
  Object.values(SavedSegmentTypeEnum).map((type) => {
    keys[`EncodedSearch_${type}`] = `ENCODED_SEARCH_${type}`;
  });
  return keys;
}
