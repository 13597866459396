import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_CONTACTS } from "../../../lib/constants";
import { selectPropsContactId } from "../index";

export const selectStateContacts = state =>
  state.entities[ENTITY_NAME_CONTACTS];

export const selectContact = createCachedSelector(
  [selectStateContacts, selectPropsContactId],
  (contacts, contactId) => get(contacts, `byId.${contactId}`)
)(selectPropsContactId);

export const useContact = contactId =>
  useSelector(state => selectContact(state, { contactId }));
