import clsx from "clsx";
import { ReactNode, useState } from "react";
import { useFocusWithinElement } from "st-shared/hooks";

import {
  containerCss,
  labelCss,
  labelVisibleCss,
} from "./DocumentLineFieldLabel.css";

interface Props {
  label: string;
  children: ReactNode;
}

export function DocumentLineFieldLabel({ label, children }: Props) {
  const [focused, setFocused] = useState(false);
  const { focusWithinProps } = useFocusWithinElement({
    onBlur: () => setFocused(false),
    onFocus: () => setFocused(true),
  });

  return (
    <div className={containerCss} {...focusWithinProps}>
      <div className={clsx(labelCss, focused && labelVisibleCss)}>{label}</div>
      {children}
    </div>
  );
}
