import { useDispatch } from "react-redux";
import { pluralise } from "st-shared/lib/pluralise";
import styled from "styled-components";

import { JOB_PHASE_SET_NAME } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPhase } from "../../../redux/selectors/jobPhase";
import { useJobPhaseJobItemCount } from "../../../redux/selectors/jobPhase/selectJobPhaseJobItemCount";
import { useJobPhaseStatusClass } from "../../../redux/selectors/jobPhase/selectJobPhaseStatusClass";
import { useIsExpandedJobPhase } from "../../../redux/selectors/jobPhase/ui/isExpanded";
import { useJobPhaseOptions } from "../../../redux/selectors/options/jobPhaseOptions";
import FreeAutocomplete from "../../elements/Autocomplete/FreeAutocomplete";
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
} from "../../elements/Autocomplete/styles";
import { FocusProgressContainer } from "../components/FocusProgressContainer";
import { useJobId } from "../context/JobIdContext";
import { useJobPhaseId } from "../context/JobPhaseIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { BaseItem } from "../styles";

const PhaseName = () => {
  const jobId = useJobId();
  const jobPhaseId = useJobPhaseId();
  const isJobEditable = useIsJobEditable(jobId);
  const jobPhase = useJobPhase(jobPhaseId);
  const isExpanded = useIsExpandedJobPhase(jobPhaseId);
  const itemCount = useJobPhaseJobItemCount(jobPhaseId);
  const statusClass = useJobPhaseStatusClass(jobPhaseId);

  const jobPhaseOptions = useJobPhaseOptions();

  const [ref, focused, setFocused] = useFocusStateRef({
    jobPhaseId,
    key: FOCUS_KEYS.PHASE_NAME,
  });

  const dispatch = useDispatch();

  const setName = (value) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_PHASE_SET_NAME, {
        jobPhaseId,
        value,
      })
    );
  };

  const handleInputChange = (e, newValue, reason) => setName(String(newValue));

  const handleChange = (e, option, reason) => setName(String(option.value));

  const handleClose = (e, reason) => setFocused(false);

  const handleOpen = (e) => setFocused(true);

  const phaseNameText = getJobPhaseName(jobPhase);

  const focusedColor = (() => {
    if (statusClass === "scheduled") return "var(--color-status-play-accent)";
    if (statusClass === "planning") return "var(--color-status-pause-accent)";
    if (statusClass === "complete") return "var(--color-status-done-accent)";
    return "var(--color-gray)";
  })();

  return (
    <Container>
      <FocusProgressContainer color={focusedColor}>
        <FreeAutocomplete
          ref={ref}
          options={jobPhaseOptions}
          placeholder="Phase Name"
          value={phaseNameText}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          disabled={!isJobEditable}
          AutocompleteComponent={CustomStyledAutocomplete}
          PaperComponent={CustomStyledAutocompletePaper}
        ></FreeAutocomplete>
        <CountContainer show={!focused && !isExpanded && itemCount > 0}>
          <NameDuplicateText>{phaseNameText}</NameDuplicateText>
          <CountText>
            <span>{`${itemCount} ${pluralise(itemCount, "item")}`}</span>
          </CountText>
        </CountContainer>
      </FocusProgressContainer>
    </Container>
  );
};

export default PhaseName;

const Container = styled(BaseItem)`
  flex-grow: 1.75;
  min-width: 25%;
  background-color: transparent;
`;

const CountContainer = styled.div`
  position: absolute;
  left: 60px;
  display: flex;
  flex-direction: row;
  height: 46px;
  align-items: end;
  pointer-events: none;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};

  left: 20px;
  top: 0;
  width: calc(100% - 40px);
`;

const NameDuplicateText = styled.div`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  visibility: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CountText = styled.div`
  position: relative;
  height: 100%;

  span {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-bold);
    padding: 7px 0 0 15px;
    white-space: nowrap;
    position: absolute;
  }
`;

const CustomStyledAutocomplete = styled(StyledAutocomplete)`
  .MuiAutocomplete-inputRoot {
    background-color: transparent;
    height: 46px;
  }
  input {
    font-size: var(--font-size-x-large);
    font-weight: var(--font-weight-bold);
    &:first-child {
      padding: 8px 20px 5px 20px !important;
    }
  }
`;

const CustomStyledAutocompletePaper = styled(StyledAutocompletePaper)`
  margin: 0 -1px 0 1px;
`;
