import {
  getUserPreference,
  setUserPreference,
  UserPreferenceKeys,
  useUserPreference,
} from "st-shared/stores";

const key = UserPreferenceKeys.ReportingSideMenuOpen;

function transformValue(value: string | undefined): boolean {
  if (typeof value === "undefined") {
    return true;
  }
  return Boolean(value);
}

export function useReportingSideMenuOpen() {
  const userPreference = useUserPreference(key);
  return transformValue(userPreference);
}

export function getReportingSideMenuOpen() {
  const userPreference = getUserPreference(key);
  return transformValue(userPreference);
}

export function setReportingSideMenuOpen(open: boolean) {
  setUserPreference(key, open);
}
