import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_ARRAY, ENTITY_NAME_JOB_PHASES } from "../../lib/constants";
import { selectPropsId, selectPropsJobId } from "./index";

export const selectJobPhases = state => state.entities[ENTITY_NAME_JOB_PHASES];

export const selectJobPhaseById = createSelector(
  selectJobPhases,
  selectPropsId,
  (jobPhases, id) => get(jobPhases, `byId.${id}`)
);

export const selectJobPhaseHasDependanciesById = createSelector(
  selectJobPhases,
  selectPropsId,
  (jobPhases, id) => Boolean(id) && get(jobPhases, `hasDependancies.${id}`)
);

export const selectJobPhaseIdsByJobId = createSelector(
  selectJobPhases,
  selectPropsJobId,
  (jobPhases, jobId) => get(jobPhases, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);
