import { has } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_CURRENCIES,
} from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import createReducer from "../../helpers/createReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";

const receiveEntityReducer = (state, action) => {
  if (!has(action.payload, ENTITY_NAME_CURRENCIES)) return state;

  return {
    ...state,
    byId: byIdReducer(
      {},
      parseEntityPayload(state, action.payload[ENTITY_NAME_CURRENCIES])
    ),
  };
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntityReducer,
  }
);
