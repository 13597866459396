import { memoize } from "lodash-es";

export const memoizeArgs = <T extends (...args: any) => any>(func: T) =>
  memoize(func, (...args) => JSON.stringify([...args]));

export const memoizeWithTodayDate = <T extends (...args: any) => any>(
  func: T
) => memoize(func, () => String(new Date().getDate()));

export const memoizeArgsWithTodayDate = <T extends (...args: any) => any>(
  func: T
) =>
  memoize(func, (...args) =>
    JSON.stringify([...args, String(new Date().getDate())])
  );
