import { produce } from "immer";

export const getLabelAsMessage = ({ labelType, ...label }) => ({
  labelTypeId: labelType.id,
  ...label,
});

export const getActivityEntryAsMessage = ({
  id,
  activityEntryType,
  activityEntryParentType,
  ...activityEntry
}) => ({
  id: id < 0 ? null : id,
  activityEntryTypeId: activityEntryType.id,
  activityEntryParentTypeId: activityEntryParentType.id,
  ...activityEntry,
});

export const getLoggedExpenseAsMessage = ({
  address,
  currency,
  labels,
  loggedExpenseStatus,
  ...loggedExpense
}) => ({
  loggedExpense: {
    address: address ? address.id : null,
    loggedExpenseStatus: loggedExpenseStatus.id,
    currency: currency ? currency.id : null,
    labels: labels ? labels.map(getLabelAsMessage) : null,
    ...loggedExpense,
  },
});

export const getInvoiceAsMessage = ({
  address,
  contact,
  invoiceStatus,
  paymentTerm,
  currency,
  ...invoice
}) => ({
  address: address ? address.id : null,
  contact: contact ? contact.id : null,
  invoiceStatus: invoiceStatus.id,
  paymentTerm: paymentTerm.id,
  currency: currency ? currency.id : null,
  ...invoice,
});

export const getJobItemUserAsMessage = ({
  id,
  jobItemUserStatus,
  jobItemUserSnoozeStatus,
  ...jobItem
}) => ({
  id: id < 0 ? null : id,
  jobItemUserStatus: jobItemUserStatus.id,
  jobItemUserSnoozeStatus: jobItemUserSnoozeStatus.id,
  ...jobItem,
});

export const getJobItemSubItemAsMessage = (jobItemSubItem) =>
  produce(jobItemSubItem, (draft) => {
    draft.id = jobItemSubItem.id < 0 ? null : jobItemSubItem.id;
    draft.jobItemSubItemStatus = jobItemSubItem.jobItemSubItemStatus.id;
  });

export const getQuoteAsMessage = ({
  address,
  quoteStatus,
  currency,
  ...quote
}) => ({
  address: address ? address.id : null,
  quoteStatus: quoteStatus.id,
  currency: currency ? currency.id : null,
  ...quote,
});

export const getJobItemAsMessage = (jobItem, isNew = false) =>
  produce(jobItem, (draft) => {
    if (isNew) {
      draft.id = null;
    }
  });

export const getNewJobPhaseAsMessage = (jobPhase) =>
  produce(jobPhase, (draft) => {
    draft.id = null;
  });

export const getJobAsMessage = (job) =>
  produce(job, (draft) => {
    if (draft.company !== null && typeof draft.company === "object") {
      draft.company = draft.company.id;
    }
    draft.jobStatus = draft.jobStatus.id;
  });

export const getNewLabelAsMessage = (label) =>
  produce(label, (draft) => {
    draft.id = null;
    draft.labelTypeId = label.labelType.id;
    draft.active = undefined;
    draft.labelType = undefined;
  });

export const getJobItemRoleAsMessage = (jobItemRole) =>
  produce(jobItemRole, (draft) => {
    draft.id = jobItemRole.id < 0 ? null : jobItemRole.id;
  });
