import { keyBy } from "lodash-es";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  LINE_ITEMS_SORT_START,
  PURCHASE_ORDER_LINE_ITEM_SORT_START
} from "../../../lib/constants";
import {
  getPurchaseOrderId,
  isPurchaseOrder
} from "../../../lib/entities/loggedExpenseEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import {
  selectLoggedExpenseByLoggedExpenseId,
  selectLoggedExpenseUi
} from "../../selectors/loggedExpenseSelectors";
import { selectPurchaseOrderLineItemsByPurchaseOrderId } from "../../selectors/purchaseOrderLineItemSelectors";

function* startSortLineItems(action) {
  const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

  if (!loggedExpenseId) return;

  const loggedExpense = yield select(selectLoggedExpenseByLoggedExpenseId, {
    loggedExpenseId
  });

  if (!isPurchaseOrder(loggedExpense)) return;

  const purchaseOrderId = getPurchaseOrderId(loggedExpense);

  if (!purchaseOrderId) return;

  try {
    const purchaseOrderLineItems = yield select(
      selectPurchaseOrderLineItemsByPurchaseOrderId,
      {
        purchaseOrderId
      }
    );

    const snapshotId = createSnapshotId();
    const snapshot = keyBy(purchaseOrderLineItems, "id");

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_START, {
        purchaseOrderId,
        snapshotId,
        data: snapshot
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLineItemsSortStart() {
  yield takeLatest(LINE_ITEMS_SORT_START, startSortLineItems);
}
