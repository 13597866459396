import { plate, slate } from "st-shared/external";

import { ELEMENT_COLUMN_INPUT } from "./createColumnPlugin";
import {
  findColumnInput,
  isNodeColumnInput,
  isSelectionInColumnInput,
} from "./queries";
import { removeColumnInput } from "./transforms";
import { ColumnPlugin, TColumnInputElement } from "./types";

export const withColumn = <
  V extends plate.Value = plate.Value,
  E extends plate.PlateEditor<V> = plate.PlateEditor<V>,
>(
  editor: E,
  {
    options: { id, trigger, query, inputCreation },
  }: plate.WithPlatePlugin<ColumnPlugin, V, E>
) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const { type } = plate.getPlugin<{}, V>(editor, ELEMENT_COLUMN_INPUT);

  const {
    apply,
    insertBreak,
    insertText,
    deleteBackward,
    insertFragment,
    insertTextData,
    insertNode,
  } = editor;

  const stripNewLineAndTrim: (text: string) => string = (text) => {
    return text
      .split(/\r\n|\r|\n/)
      .map((line) => line.trim())
      .join("");
  };

  editor.insertFragment = (fragment) => {
    const inColumnInput = findColumnInput(editor) !== undefined;
    if (!inColumnInput) {
      return insertFragment(fragment);
    }

    return insertText(
      fragment
        .map((node) => stripNewLineAndTrim(plate.getNodeString(node)))
        .join("")
    );
  };

  editor.insertTextData = (data) => {
    const inColumnInput = findColumnInput(editor) !== undefined;
    if (!inColumnInput) {
      return insertTextData(data);
    }

    const text = data.getData("text/plain");
    if (!text) {
      return false;
    }

    editor.insertText(stripNewLineAndTrim(text));

    return true;
  };

  editor.deleteBackward = (unit) => {
    const currentColumnInput = findColumnInput(editor);
    if (
      currentColumnInput &&
      plate.getNodeString(currentColumnInput[0]) === ""
    ) {
      return removeColumnInput(editor, currentColumnInput[1]);
    }

    return deleteBackward(unit);
  };

  editor.insertBreak = () => {
    if (isSelectionInColumnInput(editor)) {
      return;
    }

    insertBreak();
  };

  editor.insertText = (text) => {
    if (
      !editor.selection ||
      text !== trigger ||
      (query && !query(editor as plate.PlateEditor)) ||
      isSelectionInColumnInput(editor)
    ) {
      return insertText(text);
    }

    if (text === trigger) {
      const data: TColumnInputElement = {
        type,
        children: [{ text: "" }],
        trigger,
      };
      if (inputCreation) {
        data[inputCreation.key] = inputCreation.value;
      }
      return insertNode(data);
    }

    return insertText(text);
  };

  editor.apply = (operation) => {
    apply(operation);

    if (operation.type === "insert_text" || operation.type === "remove_text") {
      const currentColumnInput = findColumnInput(editor);
      if (currentColumnInput) {
        plate.comboboxActions.text(plate.getNodeString(currentColumnInput[0]));
      }
    } else if (operation.type === "set_selection") {
      const previousColumnInputPath = slate.Range.isRange(operation.properties)
        ? findColumnInput(editor, { at: operation.properties })?.[1]
        : undefined;

      const currentColumnInputPath = slate.Range.isRange(
        operation.newProperties
      )
        ? findColumnInput(editor, { at: operation.newProperties })?.[1]
        : undefined;

      if (previousColumnInputPath && !currentColumnInputPath) {
        removeColumnInput(editor, previousColumnInputPath);
      }

      if (currentColumnInputPath) {
        plate.comboboxActions.targetRange(editor.selection);
      }
    } else if (
      operation.type === "insert_node" &&
      isNodeColumnInput(editor, operation.node as plate.TNode)
    ) {
      if ((operation.node as TColumnInputElement).trigger !== trigger) {
        return;
      }

      const text =
        ((operation.node as TColumnInputElement).children as plate.TText[])[0]
          ?.text ?? "";

      if (
        inputCreation === undefined ||
        operation.node[inputCreation.key] === inputCreation.value
      ) {
        // Needed for undo - after an undo a column insert we only receive
        // an insert_node with the column input, i.e. nothing indicating that it
        // was an undo.
        plate.setSelection(editor, {
          anchor: { path: operation.path.concat([0]), offset: text.length },
          focus: { path: operation.path.concat([0]), offset: text.length },
        });

        plate.comboboxActions.open({
          activeId: id!,
          text,
          targetRange: editor.selection,
        });
      }
    } else if (
      operation.type === "remove_node" &&
      isNodeColumnInput(editor, operation.node as plate.TNode)
    ) {
      if ((operation.node as TColumnInputElement).trigger !== trigger) {
        return;
      }

      plate.comboboxActions.reset();
    }
  };

  return editor;
};
