import * as PropTypes from "prop-types";
import React from "react";
import { SearchMenuHeader } from "st-shared/module";
import styled from "styled-components";

import {
  DATE_FORMAT_DATE_MONTH_YEAR,
  SPECIAL_CHARACTER_EN_DASH,
} from "../../../lib/constants";
import { formatDate, getTodayDate, maxDate } from "../../../lib/dates";
import { reactContentType } from "../../../lib/types/reactTypes";
import Button from "../../elements/Button";
import Flex from "../../elements/Flex";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import Spacer from "../../elements/Spacer";
import PopoverDateRangePicker, {
  SELECTED_VALUE,
} from "../Pickers/PopoverDateRangePicker";
import { ICON_SIZE } from "../StyledIcon";

class AvailabilityMenuHeader extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    hasResults: PropTypes.bool.isRequired,
    searchQuery: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
    defaultStartDate: PropTypes.string.isRequired,
    defaultEndDate: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    unrestrictDates: PropTypes.bool,
    noOptionsMessage: reactContentType,
  };

  static defaultProps = {
    title: "Team Members",
    noOptionsMessage: "No options available",
    unrestrictDates: false,
  };

  state = {
    anchorEl: null,
    selectedStartDate: this.initialDateRange.startDate,
    selectedEndDate: this.initialDateRange.endDate,
  };

  get initialDateRange() {
    const { defaultStartDate, defaultEndDate, unrestrictDates } = this.props;
    if (unrestrictDates) {
      return {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      };
    }
    return {
      startDate: maxDate(defaultStartDate, getTodayDate()),
      endDate: maxDate(defaultEndDate, getTodayDate()),
    };
  }

  open = () => {
    this.setState({ anchorEl: this.ref.current });
  };

  close = () => {
    const { onChange } = this.props;
    const { selectedStartDate, selectedEndDate } = this.state;
    this.setState({ anchorEl: null });
    onChange({ selectedStartDate, selectedEndDate });
  };

  onChange = ({ selectedStartDate, selectedEndDate, selectedValue }) => {
    const { onChange } = this.props;
    if (selectedValue === SELECTED_VALUE.END) {
      this.setState({ anchorEl: null, selectedStartDate, selectedEndDate });
      onChange({ selectedStartDate, selectedEndDate });
    } else {
      this.setState({ selectedStartDate, selectedEndDate });
    }
  };

  formatSelectedDate = (date) =>
    date ? formatDate(date, DATE_FORMAT_DATE_MONTH_YEAR) : "Not Set";

  getNoResultsMessage = (searchQuery) =>
    `Hmm, looks like no users match ‘${searchQuery}’. Perhaps try a different search term.`;

  render() {
    const { defaultStartDate, defaultEndDate, title, ...props } = this.props;
    const { anchorEl, selectedStartDate, selectedEndDate } = this.state;

    return (
      <SearchMenuHeader
        title={title}
        placeholder="Search by name, branch or role..."
        getNoResultsMessage={this.getNoResultsMessage}
        hasOptions
        {...props}
      >
        <AvailabilityContainer ref={this.ref}>
          <span>Availability</span>
          <Spacer w={5} />
          <DatePickerButton onClick={this.open}>
            <DatePickerIcon size={ICON_SIZE.SMALL} />{" "}
            {this.formatSelectedDate(selectedStartDate)}{" "}
            {SPECIAL_CHARACTER_EN_DASH}{" "}
            {this.formatSelectedDate(selectedEndDate)}
          </DatePickerButton>
        </AvailabilityContainer>
        <PopoverDateRangePicker
          popoverProps={{
            open: !!anchorEl,
            anchorEl,
            onClose: this.close,
          }}
          onChange={this.onChange}
          startValue={selectedStartDate}
          endValue={selectedEndDate}
          onClose={this.close}
        />
      </SearchMenuHeader>
    );
  }
}

export default AvailabilityMenuHeader;

const AvailabilityContainer = styled(Flex)`
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  svg {
    margin: 0 4px 2px 0;
  }
`;

const DatePickerButton = styled(Button)`
  border-radius: 15px;
  color: var(--color-charcoal);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 8px 2px;
  text-transform: none;
  white-space: nowrap;
  &:hover {
    svg {
      color: black;
    }
  }
`;
