import { UserPreference } from "@streamtimefe/entities";
import { first, last, union, without } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { swapValuePositions } from "../../../lib/arrays";
import {
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_ESCAPE,
  SCHEDULE_BLOCK_SPLIT_CANCEL,
  SCHEDULE_BLOCK_SPLIT_START,
  SCHEDULE_SET_FILTER_TEAM_MEMBERS,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import {
  addKeyEventListener,
  removeKeyEventListener,
} from "../../../lib/events";
import { entityIdListType } from "../../../lib/types/entityTypes";
import { hasOpenModals } from "../../../lib/webAppAPISender";
import createAction from "../../../redux/helpers/createAction";
import {
  selectIsSplittingSomeBlock,
  selectScheduleVisibleUserIds,
} from "../../../redux/selectors/scheduleSelectors";
import {
  selectScheduleCollapsedUserIds,
  selectScheduleUnfilteredUserOrder,
} from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { selectScheduleUiFilteredTeamMembers } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { hotkeyEmitter, Hotkeys } from "../../../lib/WebAppAPI/fePages/hotkey";

const mapState = (state) => ({
  userIds: selectScheduleVisibleUserIds(state),
  filteredUserIds: selectScheduleUiFilteredTeamMembers(state),
  unfilteredUserOrder: selectScheduleUnfilteredUserOrder(state),
  visibleUserIds: selectScheduleVisibleUserIds(state),
  collapsedUserIds: selectScheduleCollapsedUserIds(state),
  isSplittingBlock: selectIsSplittingSomeBlock(state),
});

const mapDispatch = (dispatch) => ({
  doSetScheduleFilterTeamMembers: (list) =>
    dispatch(createAction(SCHEDULE_SET_FILTER_TEAM_MEMBERS, list)),
  doSetUserOrderPreference: (list) =>
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.SCHEDULE_TEAM_MEMBER_ORDER,
        value: UserPreference.joinListValue(list),
      })
    ),
  doSetCollapsedUsersPreference: (list) =>
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_COLLAPSED_USERS,
        value: UserPreference.joinListValue(list),
      })
    ),
  doSplitBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_SPLIT_START, payload)),
  doCancelSplitBlock: () => dispatch(createAction(SCHEDULE_BLOCK_SPLIT_CANCEL)),
});

class ScheduleUserActionHandlers extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    userIds: entityIdListType.isRequired,
    filteredUserIds: entityIdListType.isRequired,
    unfilteredUserOrder: entityIdListType.isRequired,
    visibleUserIds: entityIdListType.isRequired,
    collapsedUserIds: entityIdListType.isRequired,
    doSetScheduleFilterTeamMembers: PropTypes.func.isRequired,
    doSetUserOrderPreference: PropTypes.func.isRequired,
    doSetCollapsedUsersPreference: PropTypes.func.isRequired,
    doSplitBlock: PropTypes.func.isRequired,
    doCancelSplitBlock: PropTypes.func.isRequired,
    isSplittingBlock: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    addKeyEventListener("keydown", this.onKeyDown);
    hotkeyEmitter.on(Hotkeys.Z, this.globalSplitBlock);
  }

  componentWillUnmount() {
    removeKeyEventListener("keydown", this.onKeyDown);
    hotkeyEmitter.off(Hotkeys.Z, this.globalSplitBlock);
  }

  onKeyDown = (e) => {
    if (hasOpenModals()) return;

    switch (e.code) {
      case KEY_ARROW_UP:
        this.expandAllUsers();
        break;
      case KEY_ARROW_DOWN:
        this.collapseAllUsers();
        break;
      case KEY_ESCAPE:
        this.cancelSplitBlock();
        break;
    }
  };

  onClearUser = (e, userId) => {
    const {
      unfilteredUserOrder,
      filteredUserIds,
      visibleUserIds,
      doSetScheduleFilterTeamMembers,
    } = this.props;

    if (!filteredUserIds.length)
      return doSetScheduleFilterTeamMembers(
        without(unfilteredUserOrder, String(userId))
      );

    return doSetScheduleFilterTeamMembers(
      without(visibleUserIds, String(userId))
    );
  };

  onSortUserUpward = (e, userId) => {
    const { unfilteredUserOrder, visibleUserIds, doSetUserOrderPreference } =
      this.props;
    const srcIndex = visibleUserIds.indexOf(String(userId));
    const targetUserId = visibleUserIds[Math.max(srcIndex - 1, 0)];

    return doSetUserOrderPreference(
      swapValuePositions(
        unfilteredUserOrder,
        String(userId),
        String(targetUserId)
      )
    );
  };

  onSortUserDownward = (e, userId) => {
    const { unfilteredUserOrder, visibleUserIds, doSetUserOrderPreference } =
      this.props;
    const srcIndex = visibleUserIds.indexOf(String(userId));
    const targetUserId =
      visibleUserIds[Math.min(srcIndex + 1, visibleUserIds.length - 1)];

    return doSetUserOrderPreference(
      swapValuePositions(
        unfilteredUserOrder,
        String(userId),
        String(targetUserId)
      )
    );
  };

  onCollapseUser = (e, userId) => {
    const { userIds, collapsedUserIds, doSetCollapsedUsersPreference } =
      this.props;

    const newUserIds = e.altKey ? userIds : [String(userId)];

    doSetCollapsedUsersPreference(union(collapsedUserIds, newUserIds));
  };

  onExpandUser = (e, userId) => {
    const { userIds, collapsedUserIds, doSetCollapsedUsersPreference } =
      this.props;

    const newUserIds = e.altKey ? userIds : [String(userId)];

    doSetCollapsedUsersPreference(without(collapsedUserIds, ...newUserIds));
  };

  collapseAllUsers = () => {
    const { userIds, collapsedUserIds, doSetCollapsedUsersPreference } =
      this.props;

    doSetCollapsedUsersPreference(union(collapsedUserIds, userIds));
  };

  expandAllUsers = () => {
    const { userIds, collapsedUserIds, doSetCollapsedUsersPreference } =
      this.props;

    doSetCollapsedUsersPreference(without(collapsedUserIds, ...userIds));
  };

  globalSplitBlock = () => {
    const { doSplitBlock } = this.props;
    doSplitBlock({ blockKey: ScheduleUi.SplittingBlockKeyGlobal });
  };

  cancelSplitBlock = () => {
    const { isSplittingBlock, doCancelSplitBlock } = this.props;

    if (isSplittingBlock) {
      doCancelSplitBlock();
    }
  };

  getUserActionHandlers = (userId) => {
    const { onClearUser, onCollapseUser, onExpandUser } = this;
    const onSortUserUpward = !this.isUserFirst(userId)
      ? this.onSortUserUpward
      : null;
    const onSortUserDownward = !this.isUserLast(userId)
      ? this.onSortUserDownward
      : null;

    return {
      onClearUser,
      onSortUserUpward,
      onSortUserDownward,
      onCollapseUser,
      onExpandUser,
    };
  };

  isUserFirst(userId) {
    const { userIds } = this.props;
    return userId === first(userIds);
  }

  isUserLast(userId) {
    const { userIds } = this.props;
    return userId === last(userIds);
  }

  render() {
    const { children } = this.props;
    return children(this.getUserActionHandlers);
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserActionHandlers);
