import { produce } from "immer";
import { isEmpty } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_LABELS,
  LABELS_CREATE_ERROR,
  LABELS_DELETE_ERROR,
  LABELS_DELETING,
} from "../../../../lib/constants";
import {
  getEntityIdLabelTypeIdPath,
  getName,
  isActive,
} from "../../../../lib/entities/labelEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";

const idsByEntityIdLabelTypeIdIndexer = createEntityIndexedArrayReducer(
  (entity) =>
    (entity && isActive(entity) && getEntityIdLabelTypeIdPath(entity)) || null
);

const namesByEntityIdLabelTypeIdIndexer = createEntityIndexedArrayReducer(
  (entity) =>
    (entity && isActive(entity) && getEntityIdLabelTypeIdPath(entity)) || null,
  (entity) => entity && getName(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByEntityIdLabelTypeId: idsByEntityIdLabelTypeIdIndexer(
      state.idsByEntityIdLabelTypeId,
      changedEntities
    ),
    namesByEntityIdLabelTypeId: namesByEntityIdLabelTypeIdIndexer(
      state.namesByEntityIdLabelTypeId,
      changedEntities
    ),
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_LABELS]
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_LABELS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const deleteLabelsReducer = (state, action) => {
  const { labelId } = action.payload;
  const prevEntity = state.byId[labelId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: produce(prevEntity, (draft) => {
        draft.active = false;
      }),
    },
  ]);
};

const undoDeleteLabelsReducer = (state, action) => {
  const { labelId } = action.payload;
  const prevEntity = state.byId[labelId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: produce(prevEntity, (draft) => {
        draft.active = true;
      }),
    },
  ]);
};

const undoCreateLabelsReducer = (state, action) => {
  const { labelId } = action.payload;
  const prevEntity = state.byId[labelId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
    },
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [LABELS_DELETING]: deleteLabelsReducer,
    [LABELS_DELETE_ERROR]: undoDeleteLabelsReducer,
    [LABELS_CREATE_ERROR]: undoCreateLabelsReducer,
  }
);
