import { EntityClass, Role } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";

import { createStore } from "../../../core";
import { EntityStore } from "../../EntityStore";

export type TRoleIndexStore = {
  roleNameIndex: Role.calculate.TRoleNameIndex;
};

export const useRoleIndexStore = createStore<TRoleIndexStore>(() => ({
  roleNameIndex: {},
}));

EntityStore.store.subscribe(
  (store) => store[EntityClass.Role].byId,
  (roles) => {
    const roleNameIndex = Role.calculate.createRoleNameIndex(
      dictionaryToArray(roles)
    );

    useRoleIndexStore.setState({ roleNameIndex });
  }
);
