import {
  EMPTY_ARRAY,
  JOB_ACTIVITY_FETCH_FAILED,
  JOB_ACTIVITY_FETCH_REQUEST,
  JOB_ACTIVITY_FETCH_SUCCEEDED,
  JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED,
  JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST,
  JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const initialState = {
  jobId: null,
  isActivityEntriesPending: true,
  activityEntriesError: "",
  activityEntriesFetchedJobIds: EMPTY_ARRAY,
  isRepeatingLoggedTimesPending: true,
  repeatingLoggedTimesError: "",
  fetchedRepeatingLoggedTimeIds: EMPTY_ARRAY
};

const onActivityEntriesRequest = (state, { payload: { jobId } }) => ({
  ...state,
  jobId,
  isActivityEntriesPending: true,
  activityEntriesError: initialState.activityEntriesError
});

const onActivityEntriesSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  isActivityEntriesPending: false,
  activityEntriesError: initialState.activityEntriesError,
  activityEntriesFetchedJobIds: state.activityEntriesFetchedJobIds.concat([
    jobId
  ])
});

const onActivityEntriesError = (state, { payload: { error } }) => ({
  ...state,
  isActivityEntriesPending: false,
  activityEntriesError: error
});

const onRepeatingLoggedTimesRequest = (state, { payload: { jobId } }) => ({
  ...state,
  jobId,
  isRepeatingLoggedTimesPending: true,
  repeatingLoggedTimesError: initialState.repeatingLoggedTimesError
});

const onRepeatingLoggedTimesSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  isRepeatingLoggedTimesPending: false,
  repeatingLoggedTimesError: initialState.repeatingLoggedTimesError,
  fetchedRepeatingLoggedTimeIds: state.fetchedRepeatingLoggedTimeIds.concat([
    jobId
  ])
});

const onRepeatingLoggedTimesError = (state, { payload: { error } }) => ({
  ...state,
  isRepeatingLoggedTimesPending: false,
  repeatingLoggedTimesError: error
});

export default createReducer(initialState, {
  [JOB_ACTIVITY_FETCH_REQUEST]: onActivityEntriesRequest,
  [JOB_ACTIVITY_FETCH_SUCCEEDED]: onActivityEntriesSuccess,
  [JOB_ACTIVITY_FETCH_FAILED]: onActivityEntriesError,
  [JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST]: onRepeatingLoggedTimesRequest,
  [JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED]: onRepeatingLoggedTimesSuccess,
  [JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED]: onRepeatingLoggedTimesError
});
