import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { ICON_SIZE } from "../../modules/StyledIcon";

const CircleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.size * 5) / 6}px;
  height: ${props => (props.size * 5) / 6}px;
  border-radius: 50%;
  color: ${props => props.color};
  border: 1px solid ${props => props.color};
  background-color: ${props => props.fill};
  transition: background-color 0.2s, color 0.2s;
`;

CircleIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE)),
  color: PropTypes.string,
  fill: PropTypes.string
};

CircleIcon.defaultProps = {
  size: ICON_SIZE.X_LARGE,
  color: "transparent",
  fill: "black"
};

export default CircleIcon;
