import {
  EMPTY_ARRAY,
  QUOTE_FETCH_FAILED,
  QUOTE_FETCH_SUCCEEDED,
  QUOTE_FETCH_REQUEST,
  LINE_ITEMS_MERGE_START,
  LINE_ITEMS_MERGE_CONFIRM,
  LINE_ITEMS_MERGE_CANCEL,
  QUOTE_LINE_ITEM_SORT_START,
  QUOTE_LINE_ITEM_SORT_CONFIRM,
  QUOTE_LINE_ITEM_SORT_CANCEL
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const initialState = {
  quoteId: null,
  pending: true,
  fetchedIds: EMPTY_ARRAY,
  error: "",
  isMergeMode: false,
  isSortMode: false
};

const onRequest = (state, { payload: { quoteId } }) => ({
  ...state,
  quoteId,
  pending: true,
  error: initialState.error
});

const onSuccess = (state, { payload: { quoteId } }) => ({
  ...state,
  pending: false,
  fetchedIds: state.fetchedIds.concat([quoteId]),
  error: initialState.error
});

const onError = (state, { payload: { error } }) => ({
  ...state,
  pending: true,
  error
});

const setMergeModeTrue = state => ({
  ...state,
  isMergeMode: true
});

const setMergeModeFalse = state => ({
  ...state,
  isMergeMode: false
});

const setSortModeTrue = (state, { payload: { snapshotId } }) => ({
  ...state,
  isSortMode: true,
  sortModeSnapshotId: snapshotId
});

const setSortModeFalse = state => ({
  ...state,
  isSortMode: false
});

export default createReducer(initialState, {
  [QUOTE_FETCH_REQUEST]: onRequest,
  [QUOTE_FETCH_SUCCEEDED]: onSuccess,
  [QUOTE_FETCH_FAILED]: onError,
  [LINE_ITEMS_MERGE_START]: setMergeModeTrue,
  [LINE_ITEMS_MERGE_CONFIRM]: setMergeModeFalse,
  [LINE_ITEMS_MERGE_CANCEL]: setMergeModeFalse,
  [QUOTE_LINE_ITEM_SORT_START]: setSortModeTrue,
  [QUOTE_LINE_ITEM_SORT_CONFIRM]: setSortModeFalse,
  [QUOTE_LINE_ITEM_SORT_CANCEL]: setSortModeFalse
});
