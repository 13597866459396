import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import {
  JOB_DETAILS_PHASE_REORDER,
  JOB_PHASE_EDIT,
  JOB_PHASE_SAVE
} from "../../../lib/constants";
import { getOrderId } from "../../../lib/entities/jobItemEntity";
import {
  extractId,
  isPhase,
  getNextPhase,
  getPreviousPhase,
  isItem
} from "../../../lib/sortingHelpers";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* jobPhaseReorder(action) {
  const { activeId, overId, sortedIds } = action.payload;

  try {
    const active = {
      id: extractId(activeId),
      index: sortedIds.indexOf(activeId)
    };
    const over = {
      id: extractId(overId),
      index: sortedIds.indexOf(overId)
    };
    const after = active.index < over.index;

    const jobPhase = yield select(selectJobPhaseById, { id: active.id });
    let orderId = null;

    if (isPhase(overId)) {
      const overPhase = yield select(selectJobPhaseById, { id: over.id });

      orderId = getOrderId(overPhase) + (after ? 0.5 : -0.5);
    } else if (after) {
      const previousPhaseId = getPreviousPhase(sortedIds, over.index);
      if (previousPhaseId !== null && previousPhaseId !== active.id) {
        const previousPhase = yield select(selectJobPhaseById, {
          id: previousPhaseId
        });
        orderId = getOrderId(previousPhase) + 0.5;
      }
    } else {
      const nextPhaseId = getNextPhase(sortedIds, over.index);
      if (nextPhaseId !== null && nextPhaseId !== active.id) {
        const nextPhase = yield select(selectJobPhaseById, {
          id: nextPhaseId
        });
        orderId = getOrderId(nextPhase) - 0.5;
      }
    }

    // if absorbing un-phased items
    const addJobItemsToPhase = [];
    if (isItem(overId) && (orderId === 0.5 || getOrderId(jobPhase) === 1)) {
      let found = false;
      for (let i = 0; i < sortedIds.length; i += 1) {
        if (isItem(sortedIds[i])) {
          if (overId === sortedIds[i]) found = true;
          if (found) addJobItemsToPhase.push(extractId(sortedIds[i]));
        } else {
          break;
        }
      }
    }

    if (orderId !== null || addJobItemsToPhase.length > 0) {
      const newJobPhase = produce(jobPhase, draft => {
        if (orderId !== null) {
          draft.orderId = orderId;
        }
      });

      yield put(
        createAction(JOB_PHASE_EDIT, {
          jobPhaseId: jobPhase.id,
          jobPhase: newJobPhase
        })
      );

      yield put(
        createAction(JOB_PHASE_SAVE, {
          jobPhaseId: jobPhase.id,
          jobPhase: newJobPhase,
          previousJobPhase: jobPhase,
          addJobItemsToPhase:
            addJobItemsToPhase.length > 0 ? addJobItemsToPhase : null
        })
      );
    }
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobPhaseReorder() {
  yield takeLatestBy(
    [JOB_DETAILS_PHASE_REORDER],
    jobPhaseReorder,
    action => action.payload.jobid
  );
}
