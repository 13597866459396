import { produce } from "immer";
import { isEmpty } from "lodash-es";
import {
  MasterJobItem,
  masterJobItemObj,
  MasterJobItemOption,
} from "st-shared/entities/MasterJobItem";

import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityOptionsReducer from "../../helpers/createEntityOptionsReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";
import { ChangedEntity, EntityNames } from "../types";
import { MasterJobItemsAction } from "./actions";
import { MasterJobItemsState } from "./types";

const initialState: MasterJobItemsState = {
  byId: {},
  options: [],
};

const optionsReducer = createEntityOptionsReducer<
  MasterJobItem,
  MasterJobItemOption
>(
  (entity) => entity && entity.active && masterJobItemObj(entity).getAsOption()
);

function reduceChangedEntities(
  state: MasterJobItemsState,
  changedEntities: ChangedEntity<MasterJobItem>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.options = optionsReducer(state.options, changedEntities);
  });
}

function masterJobItemsReducer(
  state: MasterJobItemsState = initialState,
  action: MasterJobItemsAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return reduceChangedEntities(
        state,
        parseEntityPayload<MasterJobItem>(
          state,
          action.payload.masterJobItems,
          (entity) => masterJobItemObj(entity).isDeleted()
        )
      );
    case ENTITIES_REMOVED:
      if (action.payload.entityName !== EntityNames.MasterJobItems) {
        return state;
      }
      return reduceChangedEntities(
        state,
        parseRemovedEntities(state, action.payload.ids)
      );
    default:
      return state;
  }
}

export default masterJobItemsReducer;
