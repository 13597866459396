import type { TUser } from "@streamtimefe/entities";
import { runtimeEnv } from "@streamtimefe/environment";
import { setLocalStorageAuthToken } from "@streamtimefe/utils";
import type { AxiosResponse } from "axios";
import { runSaga } from "redux-saga";
import { call, takeLatest } from "redux-saga/effects";
import { create } from "zustand";

import type { TEntityId } from "../../entities/Entity";
import { WebAPI } from "../../lib";
import type {
  AuthBootstrapResponse,
  AuthToken,
} from "../../lib/webapi/authentication";
import { addGlobalStore, userEntityStore, useUserEntity } from "..";
import type { SagaEffect } from "../sagaHelpers";
import { sagaGlobalChannel } from "../sagaHelpers/sagaGlobalChannel";

export const AuthenticationSagaActions = {
  bootstrap: "authentication/bootstrap",
} as const;

export type AuthenticationSagaEffects = {
  bootstrap: SagaEffect<
    (typeof AuthenticationSagaActions)["bootstrap"],
    { successCallback: (response: AuthBootstrapResponse) => void }
  >;
};

export interface AuthenticationStoreState {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  loggedInUserId: TEntityId | null;
  authToken: AuthToken | null;
  helpers: {
    getLoggedInUser: () => TUser;
    isAdminUser: () => boolean;
    isProdAdminUser: () => boolean;
  };
  actions: {
    bootstrap: (
      successCallback: (response: AuthBootstrapResponse) => void
    ) => void;
  };
  sagas: {
    bootstrap: (
      effect: AuthenticationSagaEffects["bootstrap"]
    ) => Generator<any, void, AxiosResponse<AuthBootstrapResponse, any>>;
  };
}

export const useAuthenticationStore = create<AuthenticationStoreState>(
  function (set, get) {
    return {
      isAuthenticating: false,
      isAuthenticated: false,
      loggedInUserId: null,
      authToken: null,
      helpers: {
        getLoggedInUser() {
          return userEntityStore().entities[get().loggedInUserId!];
        },
        isAdminUser() {
          return get().authToken?.isAdminUser ?? false;
        },
        isProdAdminUser() {
          return (
            get().helpers.isAdminUser() &&
            runtimeEnv()?.VITE_BUILD_ENV === "production"
          );
        },
      },
      actions: {
        bootstrap: function (
          successCallback: (response: AuthBootstrapResponse) => void
        ) {
          sagaGlobalChannel.put({
            type: AuthenticationSagaActions.bootstrap,
            payload: { successCallback },
          } as AuthenticationSagaEffects["bootstrap"]);
        },
      },
      sagas: {
        bootstrap: function* (effect: AuthenticationSagaEffects["bootstrap"]) {
          try {
            set({ isAuthenticating: true, isAuthenticated: false });
            const { data } = yield call(WebAPI.Authentication.bootstrap);

            setLocalStorageAuthToken(data.authToken.token);

            effect.payload.successCallback(data);

            set({
              isAuthenticated: true,
              loggedInUserId: data.authToken.user.id,
              authToken: data.authToken,
            });
          } catch (e: unknown) {
            set({ isAuthenticated: false });
            console.error(e);
          }
        },
      },
    };
  }
);

export function authenticationStoreInit() {
  addGlobalStore("authentication", authenticationStore);

  runSaga({ channel: sagaGlobalChannel }, function* () {
    yield takeLatest(
      AuthenticationSagaActions.bootstrap,
      authenticationStore().sagas.bootstrap
    );
  });
}

export function authenticationStore() {
  return useAuthenticationStore.getState();
}

export function useIsAuthenticating() {
  return useAuthenticationStore((s) => s.isAuthenticating);
}

export function useIsAuthenticated() {
  return useAuthenticationStore((s) => s.isAuthenticated);
}

export function useLoggedInUserId() {
  return useAuthenticationStore((s) => s.loggedInUserId);
}

export function useIsAdminUser() {
  return useAuthenticationStore((s) => s.helpers.isAdminUser());
}

export function useIsProdAdminUser() {
  return useAuthenticationStore((s) => s.helpers.isProdAdminUser());
}

export function useLoggedInUser() {
  const userId = useLoggedInUserId();
  return useUserEntity(userId!);
}
