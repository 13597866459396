import { createContext, type PropsWithChildren, useContext } from "react";

type DisabledGroupProps = PropsWithChildren & {
  isDisabled: boolean;
};

const DisabledBoundaryContext = createContext<undefined | true>(undefined);

export function useDisabledBoundary() {
  return useContext(DisabledBoundaryContext);
}

export function DisabledBoundary({ isDisabled, children }: DisabledGroupProps) {
  return (
    <DisabledBoundaryContext.Provider value={isDisabled ? true : undefined}>
      {children}
    </DisabledBoundaryContext.Provider>
  );
}
