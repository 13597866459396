import { connect } from "react-redux";

import {
  LOGGED_EXPENSE_ADDRESS_CREATE,
  LOGGED_EXPENSE_CONTACT_CREATE_EDIT,
  LOGGED_EXPENSE_EDIT,
  LOGGED_EXPENSE_SAVE,
  LOGGED_EXPENSE_SET_ADDRESS,
  LOGGED_EXPENSE_SET_CONTACT,
  PURCHASE_ORDER_LINE_ITEM_ADD_ITEM,
  PURCHASE_ORDER_LINE_ITEM_ADD_LINE,
  PURCHASE_ORDER_LINE_ITEM_DELETE,
  PURCHASE_ORDER_LINE_ITEM_EDIT,
  PURCHASE_ORDER_LINE_ITEM_SAVE,
  PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING,
  PURCHASE_ORDER_LINE_ITEM_SORT_MOVE,
  PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE,
} from "../../../../lib/constants";
import { purchaseOrderDocumentMethods } from "../../../../lib/entities/loggedExpenseEntity";
import { purchaseOrderLineItemMethods } from "../../../../lib/entities/purchaseOrderLineItemEntity";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import createAction from "../../../../redux/helpers/createAction";
import {
  selectLoggedExpenseById,
  selectLoggedExpenseJobBranchIdById,
  selectLoggedExpenseJobCompanyById,
  selectLoggedExpenseJobNumberById,
  selectLoggedExpenseJobRateCardIdById,
  selectLoggedExpensesUiById,
  selectLoggedExpenseUi,
} from "../../../../redux/selectors/loggedExpenseSelectors";
import CommercialDocumentContextProvider from "./CommercialDocumentContextProvider";

const mapState = (state, props) => ({
  commercialDocumentType: COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER,
  pageUi: selectLoggedExpenseUi(state),
  document: selectLoggedExpenseById(state, props),
  documentUi: selectLoggedExpensesUiById(state, props),
  branchId: selectLoggedExpenseJobBranchIdById(state, props),
  rateCardId: selectLoggedExpenseJobRateCardIdById(state, props),
  jobCompany: selectLoggedExpenseJobCompanyById(state, props),
  jobNumber: selectLoggedExpenseJobNumberById(state, props),
  documentMethods: purchaseOrderDocumentMethods,
  documentLineItemMethods: purchaseOrderLineItemMethods,
});

const mapDispatch = (dispatch) => ({
  doEditDocument: (id, loggedExpense, prevLoggedExpense) => {
    dispatch(
      createAction(LOGGED_EXPENSE_EDIT, {
        id,
        loggedExpense,
        prevLoggedExpense,
      })
    );
  },
  doSaveDocument: (id, loggedExpense, prevLoggedExpense) => {
    dispatch(
      createAction(LOGGED_EXPENSE_SAVE, {
        id,
        loggedExpense,
        prevLoggedExpense,
      })
    );
  },
  doSetAddress: (id, addressId) => {
    dispatch(createAction(LOGGED_EXPENSE_SET_ADDRESS, { id, addressId }));
  },
  doSetContact: (id, contactId) => {
    dispatch(createAction(LOGGED_EXPENSE_SET_CONTACT, { id, contactId }));
  },
  doCreateContact: (id, company) => {
    dispatch(createAction(LOGGED_EXPENSE_CONTACT_CREATE_EDIT, { id, company }));
  },
  doEditContact: (id, contact) => {
    dispatch(createAction(LOGGED_EXPENSE_CONTACT_CREATE_EDIT, { id, contact }));
  },
  doCreateAddress: (id, company) => {
    dispatch(createAction(LOGGED_EXPENSE_ADDRESS_CREATE, { id, company }));
  },
  doMergeLineItemsUndo: (purchaseOrderId, snapshotId) =>
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE, {
        purchaseOrderId,
        snapshotId,
      })
    ),
  doSortLineItemsMove: (purchaseOrderId, sourceOrderId, destinationOrderId) =>
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_MOVE, {
        purchaseOrderId,
        sourceOrderId,
        destinationOrderId,
      })
    ),
  doEditDocumentLineItem: (id, purchaseOrderLineItem) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_EDIT, { id, purchaseOrderLineItem })
    );
  },
  doSaveDocumentLineItem: (
    id,
    purchaseOrderLineItem,
    prevPurchaseOrderLineItem
  ) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_SAVE, {
        id,
        purchaseOrderLineItem,
        prevPurchaseOrderLineItem,
      })
    );
  },
  doDeleteDocumentLineItem: (id, purchaseOrderLineItem) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_DELETE, {
        id,
        purchaseOrderLineItem,
      })
    );
  },
  doToggleMergeDocumentLineItem: (id, isMerging) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING, {
        id,
        isMerging,
      })
    );
  },
  doDocumentLineItemCreateTaxRate: (id) => {
    dispatch(createAction(PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE, { id }));
  },
  doAddLine: (purchaseOrderId, orderId, isHeading) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_ADD_LINE, {
        purchaseOrderId,
        orderId,
        isHeading,
      })
    );
  },
  doAddMasterJobItem: () => null,
  doAddMasterLoggedExpense: (
    purchaseOrderId,
    orderId,
    masterLoggedExpenseId
  ) => {
    dispatch(
      createAction(PURCHASE_ORDER_LINE_ITEM_ADD_ITEM, {
        purchaseOrderId,
        orderId,
        masterLoggedExpenseId,
      })
    );
  },
  doAddFromJobPlan: () => null,
});

const PurchaseOrderContextProvider = connect(
  mapState,
  mapDispatch
)(CommercialDocumentContextProvider);

export default PurchaseOrderContextProvider;
