import clsx from "clsx";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { actionScheduleSidebarHover } from "../../../state/ui/schedule/actions";
import { useScheduleSidebarHover } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  setScheduleSideMenuWidth,
  useScheduleSideMenuWidth,
} from "../UserPreference/sideBarUserPreferences";
import {
  resizeHandleCenter,
  resizeHandleContainer,
  resizeHandleLine,
  resizeHandleLineHover,
  scheduleGroupColumnCellContainer,
} from "./ResizableScheduleGroupColumnCell.css";

type ResizableScheduleGroupColumnCellProps = {
  children: ReactNode;
};

export function ResizableScheduleGroupColumnCell({
  children,
}: ResizableScheduleGroupColumnCellProps) {
  const [dragging, setDragging] = useState(false);
  const [hover, setHover] = useState(false);

  const width = useScheduleSideMenuWidth();
  const activeHover = useScheduleSidebarHover();

  const dispatch = useDispatch();

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setScheduleSideMenuWidth(event.clientX);
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  useEffect(() => {
    dispatch(actionScheduleSidebarHover(hover || dragging));
  }, [hover, dragging]);

  return (
    <div className={scheduleGroupColumnCellContainer} style={{ width }}>
      {children}
      <div className={resizeHandleContainer}>
        <div
          className={resizeHandleCenter}
          onMouseDown={() => setDragging(true)}
        >
          <div
            className={clsx(
              resizeHandleLine,
              activeHover && resizeHandleLineHover
            )}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        </div>
      </div>
    </div>
  );
}
