import { createIcon } from "../../icon/Icon";

export const IconRepeatingGroup = createIcon(
  <>
    <path d="M17 22C15.4667 22 14.1306 21.4917 12.9917 20.475C11.8528 19.4583 11.2 18.1889 11.0333 16.6667H12.4C12.5556 17.8222 13.0694 18.7778 13.9417 19.5333C14.8139 20.2889 15.8333 20.6667 17 20.6667C18.3 20.6667 19.4028 20.2139 20.3083 19.3083C21.2139 18.4028 21.6667 17.3 21.6667 16C21.6667 14.7 21.2139 13.5972 20.3083 12.6917C19.4028 11.7861 18.3 11.3333 17 11.3333C16.2333 11.3333 15.5167 11.5111 14.85 11.8667C14.1833 12.2222 13.6222 12.7111 13.1667 13.3333H15V14.6667H11V10.6667H12.3333V12.2333C12.9 11.5222 13.5917 10.9722 14.4083 10.5833C15.225 10.1944 16.0889 10 17 10C17.8333 10 18.6139 10.1583 19.3417 10.475C20.0695 10.7917 20.7028 11.2194 21.2417 11.7583C21.7805 12.2972 22.2083 12.9306 22.525 13.6583C22.8417 14.3861 23 15.1667 23 16C23 16.8333 22.8417 17.6139 22.525 18.3417C22.2083 19.0695 21.7805 19.7028 21.2417 20.2417C20.7028 20.7805 20.0695 21.2083 19.3417 21.525C18.6139 21.8417 17.8333 22 17 22ZM18.8667 18.8L16.3333 16.2667V12.6667H17.6667V15.7333L19.8 17.8667L18.8667 18.8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6V21H12.101C11.5151 20.4259 11.0297 19.7496 10.6736 19H4V8H20V9.67363C20.7496 10.0297 21.4259 10.5151 22 11.101V6H16V2H8V6H2ZM14 6H10V4H14V6Z"
    />
    <path d="M22 20.899C21.9667 20.933 21.933 20.9667 21.899 21H22V20.899Z" />
  </>,
  "IconRepeatingGroup"
);
