import { cloneDeep } from "lodash-es";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { saveScheduledBlockBulkAPI } from "../../../lib/API/scheduleAPI";
import { SCHEDULE_BLOCK_BULK_SAVE } from "../../../lib/constants";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID,
  SCHEDULE_BLOCK_SAVE_ERROR,
  SCHEDULE_BLOCK_SAVED,
  SCHEDULE_BLOCK_SAVING,
  SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID,
} from "../../../lib/constants";
import { isRepeatingLoggedTime } from "../../../lib/entities/scheduleLoggedTimeEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleBlockDays,
  selectScheduleBlockUi,
} from "../../selectors/scheduleBlockSelectors";
import validateCanModifyBlock from "./validateCanModifyBlock";

function* bulkSave(action) {
  const {
    selectedBlockKeys: blockKeys,
    duplicate,
    newBlockKeys,
  } = action.payload;

  const selectedBlockKeys = duplicate ? newBlockKeys : blockKeys;

  const baseScheduleLoggedTimes = [];

  for (let i = 0; i < selectedBlockKeys.length; i++) {
    const blockKey = selectedBlockKeys[i];

    const baseScheduleLoggedTime = yield select(
      selectBaseScheduleLoggedTimeByBlockKey,
      { blockKey }
    );

    if (!baseScheduleLoggedTime) continue;

    const shouldClearRepeatingLoggedTimeId = isRepeatingLoggedTime(
      baseScheduleLoggedTime
    );

    baseScheduleLoggedTimes.push({
      blockKey,
      baseScheduleLoggedTime,
      shouldClearRepeatingLoggedTimeId,
    });
  }

  if (baseScheduleLoggedTimes.length === 0) return;

  try {
    for (let i = 0; i < baseScheduleLoggedTimes.length; i++) {
      const scheduleLoggedTime = baseScheduleLoggedTimes[i];
      yield validateCanModifyBlock(scheduleLoggedTime.blockKey);
    }

    const scheduleLoggedTimesData = [];

    for (let i = 0; i < baseScheduleLoggedTimes.length; i++) {
      const scheduleLoggedTime = baseScheduleLoggedTimes[i];

      if (scheduleLoggedTime.shouldClearRepeatingLoggedTimeId) {
        yield put(
          createAction(SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID, {
            baseScheduleLoggedTime: scheduleLoggedTime.baseScheduleLoggedTime,
          })
        );
      }

      yield put(
        createAction(SCHEDULE_BLOCK_SAVING, {
          ...action.payload,
          blockKey: scheduleLoggedTime.blockKey,
          isBulkAction: true,
        })
      );

      const days = yield select(selectScheduleBlockDays, {
        blockKey: scheduleLoggedTime.blockKey,
      });

      const data = {
        scheduleLoggedTime: {
          ...scheduleLoggedTime.baseScheduleLoggedTime,
        },
        days,
        jobItemId: scheduleLoggedTime.baseScheduleLoggedTime.jobItemId,
      };

      if (data.scheduleLoggedTime.id < 0) {
        data.scheduleLoggedTime.id = null;
        data.scheduleLoggedTime.scheduleBlockBaseLoggedTimeId = null;
        data.includeNonWorkingDays = true;
      }

      scheduleLoggedTimesData.push(data);
    }

    const { scheduleLoggedTimes, ...data } = yield call(
      saveScheduledBlockBulkAPI,
      scheduleLoggedTimesData
    );

    for (let i = 0; i < baseScheduleLoggedTimes.length; i++) {
      const scheduleLoggedTime = baseScheduleLoggedTimes[i];

      const { isDragging, isResizing } = yield select(selectScheduleBlockUi, {
        blockKey: scheduleLoggedTime.blockKey,
      });

      if (isDragging || isResizing) return;

      yield put(
        createAction(SCHEDULE_BLOCK_SAVED, {
          ...action.payload,
          blockKey: scheduleLoggedTime.blockKey,
          scheduleLoggedTimes: cloneDeep(scheduleLoggedTimes),
          isBulkAction: true,
        })
      );
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    for (let i = 0; i < baseScheduleLoggedTimes.length; i++) {
      const scheduleLoggedTime = baseScheduleLoggedTimes[i];

      if (scheduleLoggedTime.shouldClearRepeatingLoggedTimeId) {
        yield put(
          createAction(SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID, {
            baseScheduleLoggedTime: scheduleLoggedTime.baseScheduleLoggedTime,
          })
        );
      }

      yield put(
        createAction(SCHEDULE_BLOCK_SAVE_ERROR, {
          ...action.payload,
          isBulkAction: true,
          blockKey: scheduleLoggedTime.blockKey,
          error,
        })
      );
    }

    sagaError(error);
  }
}

function* watchBlockBulkSave() {
  yield takeEvery([SCHEDULE_BLOCK_BULK_SAVE], bulkSave);
}

export default watchBlockBulkSave;
