import clsx from "clsx";

import { Tooltip } from "../../components";
import type { TEntityId } from "../../entities/Entity";
import { getUserProfileImageUrl } from "../../lib/webapi/users";
import * as styles from "./UserIcon.css";

type Props = {
  id: TEntityId;
  name: string;
  size?: number;
  disableTooltip?: boolean;
  className?: string;
};

export function UserIcon({
  id,
  name,
  size = 30,
  disableTooltip = false,
  className,
}: Props) {
  function getUserImg() {
    return (
      <img
        className={styles.userIconImg}
        alt={name}
        src={getUserProfileImageUrl(id)}
      />
    );
  }

  return (
    <div
      className={clsx(styles.userIconContainer, className)}
      style={{ width: size, height: size }}
    >
      {disableTooltip && getUserImg()}
      {!disableTooltip && (
        <Tooltip title={<div className={styles.userIconTooltip}>{name}</div>}>
          {getUserImg()}
        </Tooltip>
      )}
    </div>
  );
}
