import { createIcon } from "../../icon/Icon";

export const IconStatusInPlay = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 16.5L16 12L10 7.49998V16.5ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 3.99999 16.4 3.99999 12C3.99999 7.60002 7.6 4.00002 12 4.00002C16.4 4.00002 20 7.60002 20 12C20 16.4 16.4 20 12 20Z"
  />,
  "IconStatusInPlay"
);
