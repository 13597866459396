import styled from "styled-components";

export const JobTimelineGroupUnderline = styled.div.attrs({
  className: "JobTimelineGroupUnderline"
})`
  height: 100%;
  border-bottom: var(--border-thin-bright);
  display: flex;
  align-items: center;
  width: calc(100% - 45px);
`;
