import reduceChangedEntities from "../reduceChangedEntities";

const clearRepeatingLoggedTimeIdReducer = (state, action) => {
  const { baseScheduleLoggedTime } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[baseScheduleLoggedTime.id];
  const prevScheduleLoggedTimeIds =
    state.idsByBlockKey[baseScheduleLoggedTime.id];

  if (!prevBaseScheduleLoggedTime) return state;

  const changedEntities = prevScheduleLoggedTimeIds
    .map(id => state.byId[id])
    .map(entity => ({
      prevEntity: entity,
      newEntity: {
        ...entity,
        repeatingLoggedTimeId: null
      }
    }));

  return reduceChangedEntities(state, changedEntities);
};

export default clearRepeatingLoggedTimeIdReducer;
