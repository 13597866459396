import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import styled from "styled-components";

import { getJobPhaseName } from "../../../../lib/entities/jobPhaseEntity";
import { getPhaseId } from "../../../../lib/sortingHelpers";
import {
  entityIdListType,
  entityIdType,
} from "../../../../lib/types/entityTypes";
import { useJobPhase } from "../../../../redux/selectors/jobPhase";
import { ellipsisElement } from "../../../elements/cssElements";
import { IconButton } from "../../../elements/Button";
import DragIndicatorIcon from "../../../elements/Icons/custom/DragIndicatorIcon";
import Spacer from "../../../elements/Spacer";
import { FakeReorderItemMenuItem } from "./ReorderItemMenuItem";

const ReorderPhaseMenuItem = ({ id, jobItemIds, activeIsPhase }) => {
  const jobPhase = useJobPhase(id);

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    attributes,
    listeners,
  } = useSortable({
    id: getPhaseId(id),
  });

  const showDragHandle = !isSorting || isDragging;

  return (
    <Container
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      $dragging={isDragging}
    >
      <Phase $dragging={isDragging}>
        <Name>{getJobPhaseName(jobPhase)}</Name>
        <RightSide>
          <Count $dragging={isDragging}>
            <Circle>
              <CountText>{jobItemIds.length}</CountText>
            </Circle>
          </Count>
          {showDragHandle && (
            <DragHandle {...listeners} {...attributes}>
              <DragIndicatorIcon />
            </DragHandle>
          )}
          {!showDragHandle && <Spacer w={46} />}
        </RightSide>
      </Phase>
      {isSorting &&
        !isDragging &&
        activeIsPhase &&
        jobItemIds.map((jobItemId) => {
          return (
            <FakeReorderItemMenuItem key={`i_${jobItemId}`} id={jobItemId} />
          );
        })}
    </Container>
  );
};

ReorderPhaseMenuItem.propTypes = {
  id: entityIdType.isRequired,
  jobItemIds: entityIdListType.isRequired,
  activeIsPhase: PropTypes.bool.isRequired,
};

export default ReorderPhaseMenuItem;

const Container = styled.div`
  background-color: var(--bg-color-white);
  position: relative;
  width: 100%;
  min-width: 250px;
  max-width: 400px;

  ${(props) =>
    props.$dragging &&
    `
    z-index: 1000;
  `};
`;

const Phase = styled.div`
  background-color: var(--bg-color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  line-height: 41px;
  padding-left: 16px;
  border-bottom: 2px solid var(--color-gray);

  ${(props) =>
    props.$dragging &&
    `
    border-bottom: unset;
    background-color: var(--color-green-light);
  `};
`;

const Name = styled.div`
  color: var(--font-color-black);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  ${ellipsisElement}
`;

const DragHandle = styled(IconButton)`
  align-self: center;
  margin: 0 8px;
  color: var(--color-gray-medium);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-items: center;
`;

const Count = styled.div`
  visibility: ${(props) => (props.$dragging ? "visible" : "hidden")};
`;

const CountText = styled.div`
  padding-top: 2px;
  color: white;
  font-size: 10px;
  font-weight: var(--font-weight-bold);
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: black;
`;
