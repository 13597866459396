import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { commercialDocumentLineItemEntityType } from "../../../../lib/ui/commercialDocumentLineItems";
import { IconButtonLight } from "../../../elements/Button";
import DeleteIcon from "../../../elements/Icons/DeleteIcon";
import DocumentLineItemEditConsumer from "../CommercialDocumentContext/Consumers/DocumentLineItemEditConsumer";

class DocumentLineRightMargin extends React.PureComponent {
  static propTypes = {
    documentLineItem: commercialDocumentLineItemEntityType.isRequired,
    doDeleteDocumentLineItem: PropTypes.func.isRequired
  };

  handleDelete = () => {
    const { documentLineItem, doDeleteDocumentLineItem } = this.props;

    doDeleteDocumentLineItem(documentLineItem.id, documentLineItem);
  };

  render() {
    return (
      <Wrapper>
        <DeleteButton onClick={this.handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </Wrapper>
    );
  }
}

export default DocumentLineItemEditConsumer(DocumentLineRightMargin);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 27px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const DeleteButton = styled(IconButtonLight).attrs({
  className: "DocumentLineDeleteButton"
})`
  opacity: 0;
`;
