import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";

import { defaultIconSize, Icon, IconProps } from "../icons";
import { iconButtonCss, textButtonCss, textCss } from "./IconButton.css";

type Props = {
  iconProps: IconProps;
  text?: string;
  className?: string;
  width?: number;
  height?: number;
  variant?: "base" | "reverse" | "light" | "dark";
};

export type IconButtonProps = Props &
  (
    | (ComponentPropsWithRef<"button"> & { as?: "button" })
    | (ComponentPropsWithRef<"a"> & { as: "a" })
  );

export const IconButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(
  (
    {
      iconProps,
      text,
      className,
      width,
      height,
      variant = "base",
      as: Component = "button",
      ...props
    }: IconButtonProps,
    ref
  ) => {
    return (
      <Component
        ref={ref as any}
        className={clsx(
          iconButtonCss[variant],
          className,
          text && textButtonCss
        )}
        {...(props as any)}
        style={{ width, height, ...props.style }}
      >
        <Icon {...iconProps}></Icon>
        {text && (
          <span
            className={textCss}
            style={{
              fontSize: Math.round((iconProps.size || defaultIconSize) * 0.6),
            }}
          >
            {text}
          </span>
        )}
      </Component>
    );
  }
);

IconButton.displayName = "IconButton";
