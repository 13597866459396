import {
  type TFilterGroupTypeEnum,
  type TValueMatchTypeEnum,
  ValueMatchType,
} from "@streamtimefe/entities";

import type { TConditionMatchType } from "../../types";

export interface FilterProps {
  filterGroup: FilterGroup;
  uuid: string;
  onDelete: (filterId: string) => void;
  defaultDescriptions?: Partial<
    Record<TFilterGroupTypeEnum, { negated?: boolean; value: string }>
  >;
  addFilter: (filterId: string, filter: FilterGroupFilter) => void;
  setFilterGroupConditionMatch: (
    filterId: string,
    conditionMatchTypeId: TConditionMatchType
  ) => void;
  setFilterGroupValueMatch: (
    filterId: string,
    valueMatchTypeId: TValueMatchTypeEnum
  ) => void;
  setFilter: (
    filterId: string,
    filterIndex: number,
    filter: FilterGroupFilter
  ) => void;
  deleteFilters: (filterId: string, indexes: number[]) => void;
}

export type FilterDisplayTextProps = Pick<
  FilterProps,
  "filterGroup" | "defaultDescriptions"
>;

export type FilterGroup = {
  id: string;
  conditionMatchTypeId: TConditionMatchType;
  filterGroupTypeId: TFilterGroupTypeEnum;
  filters: FilterGroupFilter[];
};

export type FilterGroupFilter = {
  valueMatchTypeId: TValueMatchTypeEnum;
  value: FilterGroupFilterValue;
};

export type FilterGroupFilterValue = string | number;

export function getValidFilterGroups(
  filterGroups: FilterGroup[]
): FilterGroup[] {
  return filterGroups
    .map((filterGroup) => {
      return {
        ...filterGroup,
        filters: filterGroup.filters.filter((filter) => {
          if (
            ValueMatchType.requiresValue(filter.valueMatchTypeId) &&
            String(filter.value).length === 0
          ) {
            return false;
          }
          return true;
        }),
      };
    })
    .filter((filterGroup) => filterGroup.filters.length > 0);
}
