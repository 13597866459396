import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobPhaseId } from "../../index";
import { selectJobPhaseUi } from "./index";

export const selectIsExpandedJobPhase = createCachedSelector(
  [selectJobPhaseUi],
  jobPhase => get(jobPhase, `isExpanded`, true)
)(selectPropsJobPhaseId);

export const useIsExpandedJobPhase = jobPhaseId =>
  useSelector(state => selectIsExpandedJobPhase(state, { jobPhaseId }));
