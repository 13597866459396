import { createIcon } from "../../icon/Icon";

export const IconDrag = createIcon(
  <>
    <path d="M19 11V9H17V11L19 11Z" />
    <path d="M19 15V13H17V15H19Z" />
    <path d="M15 13V15H13V13H15Z" />
    <path d="M15 11V9H13V11L15 11Z" />
    <path d="M11 13V15H9V13H11Z" />
    <path d="M11 11V9H9V11L11 11Z" />
    <path d="M7 13L7 15H5V13H7Z" />
    <path d="M7 11L7 9H5V11L7 11Z" />
  </>,
  "IconDrag"
);
