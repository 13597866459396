import { useDispatch } from "react-redux";
import { JobStatuses } from "st-shared/entities/JobStatus";
import styled from "styled-components";

import {
  ENTITIES_RECEIVED,
  JOB_STATUS_CHANGE,
} from "../../../../lib/constants";
import { getRelativeJobEstimatedEndDate } from "../../../../lib/dates";
import {
  getCompletedDatetime,
  getEstimatedEndDate,
  getJobNormalizedStatus,
  getJobStatusId,
  isComplete,
} from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useJobLockStatusChange } from "../../../../redux/selectors/job/selectJobLockStatusChange";
import { useIsSavingJob } from "../../../../redux/selectors/job/ui/isSaving";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { ICON_SIZE } from "../../../modules/StyledIcon";
import JumbotronStatField from "../../components/JumbotronStatField";
import { StatusComponent } from "../../components/StatusComponent";
import { useJobId } from "../../context/JobIdContext";
import { createModelSetParser } from "../../../../lib/API/modelSets";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { EntityClass } from "@streamtimefe/entities";

const JumbotronStatus = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const isSavingJob = useIsSavingJob(jobId);
  const lockStatusChange = useJobLockStatusChange(jobId);
  const statusId = getJobNormalizedStatus(job);

  const dispatch = useDispatch();

  const doStatusChange = (jobStatusId) => {
    dispatch(createAction(JOB_STATUS_CHANGE, { jobId, jobStatusId }));
  };

  const setPlanning = () => {
    if (
      !isJobEditable ||
      isSavingJob ||
      lockStatusChange ||
      getJobStatusId(job) === JobStatuses.Planning
    )
      return;
    doStatusChange(JobStatuses.Planning);
  };

  const setSchedule = () => {
    if (!isJobEditable || isSavingJob || lockStatusChange) return;
    doStatusChange(JobStatuses.Scheduled);
  };

  const setComplete = () => {
    if (!isJobEditable || isSavingJob) return;
    doStatusChange(JobStatuses.Complete);
  };

  function onActionCompletedDatetime() {
    if (!job.completedDatetime) return;

    getModalBridgeClient()?.openModal(
      ModalTypes.JobUpdateCompletedDatetime,
      {
        jobId,
        completedDatetime: job.completedDatetime,
      },
      {}
    );
  }

  return (
    <JumbotronStatField title="STATUS" largeText>
      <PositionContainer>
        <StatusContainer>
          <StatusComponent
            statusId={statusId}
            setPlanning={setPlanning}
            setSchedule={setSchedule}
            setComplete={setComplete}
            disabled={!isJobEditable}
            locked={lockStatusChange}
            lockedTooltipText="Want to keep working on this job? Change the status of an item or team member."
            iconSize={ICON_SIZE.XX_LARGE}
            horizontalPadding={0}
          />
        </StatusContainer>
        {isComplete(job) && (
          <CompleteDateText onClick={onActionCompletedDatetime}>
            Completed
            <span>
              {" "}
              {getRelativeJobEstimatedEndDate(
                getCompletedDatetime(job),
                getJobStatusId(job)
              )}
            </span>
          </CompleteDateText>
        )}
        {!isComplete(job) && (
          <DateText>
            {`${
              getEstimatedEndDate(job) ? "Due " : ""
            }${getRelativeJobEstimatedEndDate(
              getEstimatedEndDate(job),
              getJobStatusId(job)
            )}`}
          </DateText>
        )}
      </PositionContainer>
    </JumbotronStatField>
  );
};

export default JumbotronStatus;

const StatusContainer = styled.div`
  display: inline-block;
  position: relative;
  top: -3px;
  .StatusComponentContainer {
    height: ${ICON_SIZE.XX_LARGE}px;
    margin-right: 5px;
  }
`;

const PositionContainer = styled.div``;

const DateText = styled.span`
  position: relative;
  top: -8px;
`;

const CompleteDateText = styled(DateText)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  span {
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;
