import { useBranchEntity } from "st-shared/stores";

import { getName } from "../../../../lib/entities/branchEntity";
import { getBranchId } from "../../../../lib/entities/jobEntity";
import { jobPageChangeJobBranch } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";

const JobBranch = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const branch = useBranchEntity(getBranchId(job));

  return (
    <JumbotronSettingsField
      title="BRANCH"
      onClick={() => jobPageChangeJobBranch(job)}
      disabled={!isJobEditable}
    >
      <div>{getName(branch)}</div>
    </JumbotronSettingsField>
  );
};

export default JobBranch;
