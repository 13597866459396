import { IconBase, IconBaseProps } from "react-icons";

export function StQuote(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "0 0 24 24" }} {...props}>
      <path
        d="M22.3,8.3L15.4,1H4.9v10.1l0,0c-1.7,1.1-3.1,3.1-3.1,5.5c0,3.6,2.9,6.4,6.4,6.4c1.7,0,3.5-0.7,4.6-2h9.5V8.3z M15.8,4.1
	l3.3,3.6h-3.3V4.1z M8.2,21.3c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7S10.7,21.3,8.2,21.3z M20.5,19.1h-6.4l0,0
	c0.4-0.7,0.6-1.6,0.6-2.6c0-3.6-2.9-6.4-6.4-6.4c-0.5,0-1,0.1-1.5,0.2V2.9h7.2v6.7h6.7L20.5,19.1L20.5,19.1z M9.7,16.9
	c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.5,0.2V20H7.3
	v-0.9c-0.3,0-0.6-0.1-0.7-0.2S6.2,18.8,6,18.7L6,18.6l0.5-1.1l0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0.1,0.5,0.1,0.7,0.1
	c0.4,0,0.5-0.1,0.6-0.1c0.1-0.1,0.1-0.1,0.1-0.2s0-0.1,0-0.2s-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
	c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6
	c0.1-0.2,0.1-0.3,0.3-0.5C6.7,14.1,6.9,14,7,13.9c0.2-0.1,0.3-0.1,0.5-0.2v-0.8h1.2v0.7c0.2,0,0.4,0.1,0.6,0.1
	c0.2,0.1,0.4,0.1,0.5,0.2L9.8,14l-0.3,1.2l-0.2-0.1C9.2,15,9,15,8.8,14.9c-0.2-0.1-0.5-0.1-0.6-0.1c-0.3,0-0.4,0.1-0.5,0.1L7.6,15
	v0.1c0,0.1,0,0.1,0,0.1l0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c0.2,0.1,0.5,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4
	C9.5,16.7,9.6,16.8,9.7,16.9z"
      />
    </IconBase>
  );
}
