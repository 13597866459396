import * as PropTypes from "prop-types";
import React from "react";
import { JOB_TIMELINE_BAR_MIN_WIDTH } from "../../../lib/constants";
import {
  differenceInCalendarDays,
  isAfter,
  isBefore,
  simpleCompareAsc
} from "../../../lib/dates";
import DateSticky, { JUSTIFY_STICKY } from "../../modules/DateSticky";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

const ScheduleBarConsumer = ({
  barStartDate,
  barEndDate,
  startDate,
  dueDate,
  children,
  renderStickies
}) =>
  (barStartDate || barEndDate) && (
    <ScheduleScrollContext.Consumer>
      {({
        viewportStartDate,
        viewportEndDate,
        getOffsetXAtDate,
        dayWidth,
        goToDate,
        goToRHSDate
      }) => {
        const isRightOfViewport =
          simpleCompareAsc(barStartDate, viewportEndDate) > 0;
        const isLeftOfViewport =
          simpleCompareAsc(barEndDate, viewportStartDate) < 0;

        if (isLeftOfViewport)
          return (
            renderStickies && (
              <DateSticky
                justify={JUSTIFY_STICKY.LEFT}
                goToDate={goToDate}
                goToRHSDate={goToRHSDate}
                startDate={startDate}
                dueDate={dueDate}
              />
            )
          );

        if (isRightOfViewport)
          return (
            renderStickies && (
              <DateSticky
                justify={JUSTIFY_STICKY.RIGHT}
                goToDate={goToDate}
                goToRHSDate={goToRHSDate}
                startDate={startDate}
                dueDate={dueDate}
              />
            )
          );

        let outer = null;
        let inner = null;

        if (startDate !== barStartDate || dueDate !== barEndDate) {
          const dayDiff = differenceInCalendarDays(
            barEndDate || dueDate,
            barStartDate || startDate
          );
          const days = dayDiff + 1;
          let left = getOffsetXAtDate(barStartDate);
          let right = left + days * dayWidth;

          if (startDate && !dueDate && barEndDate === startDate)
            right = left + dayDiff * dayWidth + JOB_TIMELINE_BAR_MIN_WIDTH;

          if (!startDate && dueDate && barStartDate === dueDate)
            left = left + dayWidth - JOB_TIMELINE_BAR_MIN_WIDTH;

          outer = { left, width: right - left };
        }

        if (startDate || dueDate) {
          let width;
          let days;
          let left;
          const classes = [];
          if (startDate && dueDate) {
            const innerDays = differenceInCalendarDays(dueDate, startDate) + 1;
            if (isBefore(barStartDate, startDate)) classes.push("squareLeft");
            if (isAfter(barEndDate, dueDate)) classes.push("squareRight");
            left = getOffsetXAtDate(startDate);
            width = innerDays * dayWidth;
            days = innerDays;
          } else {
            left = getOffsetXAtDate(startDate || dueDate);
            width = dayWidth;
            days = 0;
          }

          if (startDate && !dueDate) classes.push("startOnly");
          if (!startDate && dueDate) classes.push("endOnly");

          inner = {
            left,
            width,
            days,
            classes
          };
        }

        return children({ outer, inner });
      }}
    </ScheduleScrollContext.Consumer>
  );

ScheduleBarConsumer.propTypes = {
  barStartDate: PropTypes.string,
  barEndDate: PropTypes.string,
  startDate: PropTypes.string,
  dueDate: PropTypes.string,
  children: PropTypes.func.isRequired,
  renderStickies: PropTypes.bool
};

ScheduleBarConsumer.defaultProps = {
  barStartDate: null,
  barEndDate: null,
  startDate: null,
  dueDate: null,
  renderStickies: true
};

export default ScheduleBarConsumer;
