import { createModelSetParser } from "../API/modelSets";
import { ENTITY_CLASS_INVOICE } from "../constants";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, {
  SEND_ASSIGN_INVOICE_NUMBER,
  SEND_ROUTE_TO_INVOICE
} from "./index";

export const sendAssignInvoiceNumber = () =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(SEND_ASSIGN_INVOICE_NUMBER);

    if (jQueryDeferred)
      jQueryDeferred
        .then(createModelSetParser(ENTITY_CLASS_INVOICE))
        .then(resolve)
        .fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_ASSIGN_INVOICE_NUMBER}`
        )
      );
  });

export const routeToInvoice = invoiceId =>
  WebAppAPI.sendAction(SEND_ROUTE_TO_INVOICE, invoiceId);
