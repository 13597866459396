import { call, put, takeEvery } from "redux-saga/effects";
import { fetchJobHistoricalSummaryAPI } from "../../../lib/API/jobAPI";
import {
  JOB_DETAILS_FETCH_HISTORICAL_SUMMARY,
  JOB_DETAILS_SET_HISTORICAL_SUMMARY
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetchHistoricalSummary(action) {
  try {
    const { jobId } = action.payload;

    const historicalSummary = yield call(fetchJobHistoricalSummaryAPI, jobId);

    yield put(
      createAction(JOB_DETAILS_SET_HISTORICAL_SUMMARY, {
        historicalSummary
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchFetchHistoricalSummary() {
  yield takeEvery(JOB_DETAILS_FETCH_HISTORICAL_SUMMARY, fetchHistoricalSummary);
}
