import type { ConstEnum } from "@streamtimefe/types";
import type numbro from "numbro";

export const NumberFormat = {
  Currency: format({
    mantissa: 2,
    trimMantissa: false,
    thousandSeparated: false,
  }),
  Default: format({ thousandSeparated: true, mantissa: 2 }),
  Optional: format({
    thousandSeparated: true,
    mantissa: 2,
    trimMantissa: true,
  }),
  OptionalNoSeparater: format({
    mantissa: 2,
    trimMantissa: true,
    thousandSeparated: false,
  }),
  Rounded: format({ thousandSeparated: true, mantissa: 0 }),
  Abbreviated: format({
    average: true,
    totalLength: 2,
    trimMantissa: true,
  }),
  OptionalDecimal: format({
    mantissa: 2,
    trimMantissa: true,
  }),
  NoDecimal: format({ mantissa: 0 }),
  OptionalSixDecimals: format({ mantissa: 6, trimMantissa: true }),
  Percentage: format({ output: "percent", mantissa: 0 }),
  PercentageOptionalDecimal: format({
    output: "percent",
    mantissa: 2,
    trimMantissa: true,
  }),
} as const;

export type TNumberFormat = ConstEnum<typeof NumberFormat>;

function format(format: numbro.Format) {
  return format;
}
