import numeral from "numeral";

import {
  NUMBER_FORMAT_FOUR_DECIMALS,
  NUMBER_FORMAT_SIX_DECIMALS,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "./constants";

export const snapRound = (x, snap) => Math.round(x / snap) * snap;

export const snapTrunc = (x, snap) => Math.trunc(x / snap) * snap;

export const snapFloor = (x, snap) => Math.floor(x / snap) * snap;

export const snapCeil = (x, snap) => Math.ceil(x / snap) * snap;

export const minMax = (x, min, max) => Math.min(Math.max(x, min), max);

export const asDecimal = (x) => numeral(x).value() || 0;

export const round = (num, decimals = 0) => {
  return Number(numeral(num).format(`0[.]${"0".repeat(decimals)}`));
};

export const findAngle = ({ x: x1, y: y1 }, { x: x2, y: y2 }) =>
  (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;

export const findDistance = ({ x: x1, y: y1 }, { x: x2, y: y2 }) =>
  Math.hypot(y2 - y1, x2 - x1);

export const countDecimalPlaces = (value) => {
  if (value && Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
};

export const preciseDecimals = (value, decimals = 1) => {
  if (value % 1 !== 0) {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
  return value;
};

export const preciseAdd = (a, b) => {
  const aDecimal = countDecimalPlaces(numeral(a).value());
  const bDecimal = countDecimalPlaces(numeral(b).value());

  const decimals = aDecimal > bDecimal ? aDecimal : bDecimal;

  const aInteger = preciseDecimals(
    numeral(a).multiply(Math.pow(10, decimals)).value(),
    0
  );
  const bInteger = preciseDecimals(
    numeral(b).multiply(Math.pow(10, decimals)).value(),
    0
  );

  const result = aInteger + bInteger;

  return numeral(result).divide(Math.pow(10, decimals)).value();
};

export const round2dp = (value) =>
  numeral(value).format(NUMBER_FORMAT_TWO_DECIMALS);

export const round4dp = (value) =>
  numeral(value).format(NUMBER_FORMAT_FOUR_DECIMALS);

export const round6dp = (value) =>
  numeral(value).format(NUMBER_FORMAT_SIX_DECIMALS);

export const incrementAlphabeticNumber = (value) => {
  const number = value.match(/\d+$/);
  if (number) {
    return value.replace(
      /\d+$/,
      String(Number(number[0]) + 1).padStart(number[0].length, "0")
    );
  }
  return `${value}1`;
};
