import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";

import type { TEntityId } from "../../../entities/Entity";
import {
  getUserPreference,
  setUserPreference,
  UserPreferenceKeys,
  useUserPreference,
} from "../../../stores";

export type TSegmentFolder = {
  id: string;
  name: string;
  segmentIds: TEntityId[];
  open: boolean;
};

export type SavedSegmentSidebarUserPreference = {
  segmentIds: TEntityId[];
  folders: TSegmentFolder[];
};

function transformValue(
  value: string | undefined
): SavedSegmentSidebarUserPreference | undefined {
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
}

export function useSavedSegmentSidebarUserPreference(
  savedSegmentType: TSavedSegmentTypeEnum
) {
  const key = UserPreferenceKeys[`SavedSegmentSidebar_${savedSegmentType}`];
  const userPreference = useUserPreference(key);
  return transformValue(userPreference);
}

export function getSavedSegmentSidebarUserPreference(
  savedSegmentType: TSavedSegmentTypeEnum
) {
  const key = UserPreferenceKeys[`SavedSegmentSidebar_${savedSegmentType}`];
  const userPreference = getUserPreference(key);
  return transformValue(userPreference);
}

export function setSavedSegmentSidebarUserPreference(
  savedSegmentType: TSavedSegmentTypeEnum,
  savedSegmentSidebarState?: SavedSegmentSidebarUserPreference
) {
  const key = UserPreferenceKeys[`SavedSegmentSidebar_${savedSegmentType}`];
  setUserPreference(
    key,
    savedSegmentSidebarState
      ? JSON.stringify(savedSegmentSidebarState)
      : undefined
  );
}
