import { SortTypes, TSortTypes } from "../types";

export class SortId {
  entityId: string | number;
  type: TSortTypes;

  private constructor(entityId: string | number, type: TSortTypes) {
    this.entityId = entityId;
    this.type = type;
  }

  static fromEntity(entityId: string | number, type: TSortTypes) {
    return new SortId(entityId, type);
  }

  static fromValue(value: string) {
    const valueSplit = value.split("_");
    const type = valueSplit[0] as TSortTypes;
    const entityd = SortId.convertType(valueSplit[1], type);
    return new SortId(entityd, type);
  }

  getEntityNumber() {
    return Number(this.entityId);
  }

  getEntityString() {
    return String(this.entityId);
  }

  static convertType(value: string, type: TSortTypes) {
    switch (type) {
      case SortTypes.Segment:
      case SortTypes.FolderSegment:
      case SortTypes.UncategorisedSegment:
        return Number(value);
    }
    return value;
  }

  getValue() {
    return `${this.type}_${this.entityId}`;
  }
}
