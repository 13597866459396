import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectRoleIdsByJobItemId = createCachedSelector(
  [selectStateJobItemRoles, selectPropsJobItemId],
  (jobItemRoles, jobItemId) => {
    const ids = jobItemRoles.idsByJobItemId[jobItemId] ?? [];
    return ids
      .map((id) => jobItemRoles.byId[id]!)
      .map((jobItemRole) => jobItemRole.roleId);
  }
)(selectPropsJobItemId);

export function useRoleIdsByJobItemId(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectRoleIdsByJobItemId(state, { jobItemId })
  );
}
