import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const StopwatchIcon = props => (
  <SvgIcon {...props} viewBox="0 0 25 28">
    <g transform="translate(.6 4)">
      <g>
        <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 21.6c-5.3 0-9.6-4.3-9.6-9.6S6.7 2.4 12 2.4s9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6z" />
      </g>
    </g>
    <path d="M5.6 15.8h6.6V9c3.6 0 6.6 3 6.6 6.8s-3 6.8-6.6 6.8-6.6-3.1-6.6-6.8zm8.8-13.3V0H10v2.5c1.5-.3 2.8-.3 4.4 0zm7.3 4L23.2 5l-1.6-1.6L20.1 5c.6.4 1.1.9 1.6 1.5z" />
  </SvgIcon>
);

export default StyledIcon(StopwatchIcon);
