import { JobItemRole } from "st-shared/entities/JobItemRole";

import { JOB_ITEM_SAVE_ERROR, JOB_ITEM_SAVED } from "../../../lib/constants";
import { EntityAction } from "../actions";

export const JOB_ITEM_ROLE_CREATE = "jobItemRole/create";
export const JOB_ITEM_ROLE_EDIT = "jobItemRole/edit";
export const JOB_ITEM_ROLE_DELETE = "jobItemRole/delete";
export const JOB_ITEM_ROLE_SET_HOURS = "jobItemRole/set/hours";
export const JOB_ITEM_ROLE_SET_RATE = "jobItemRole/set/rate";
export const JOB_ITEM_ROLE_ASSIGN_USERS = "jobItemRole/assignUsers";

export type JobItemRolesAction =
  | EntityAction
  | JobItemRoleCreateAction
  | JobItemRoleEditAction
  | JobItemRoleDeleteAction
  | JobItemRoleSetHoursAction
  | JobItemRoleAssignUsersAction
  | { type: typeof JOB_ITEM_SAVED; payload: any }
  | { type: typeof JOB_ITEM_SAVE_ERROR; payload: any };

type SaveType = "api" | "jobItem";

export type JobItemRoleCreateAction = {
  type: typeof JOB_ITEM_ROLE_CREATE;
  jobItemId: number;
  roleId: number;
  save: SaveType;
};

export function actionJobItemRoleCreate(
  jobItemId: number,
  roleId: number,
  save: SaveType
): JobItemRoleCreateAction {
  return { type: JOB_ITEM_ROLE_CREATE, jobItemId, roleId, save };
}

export type JobItemRoleEditAction = {
  type: typeof JOB_ITEM_ROLE_EDIT;
  jobItemId: number;
  jobItemRole: JobItemRole;
};

export function actionJobItemRoleEdit(
  jobItemId: number,
  jobItemRole: JobItemRole
): JobItemRoleEditAction {
  return { type: JOB_ITEM_ROLE_EDIT, jobItemId, jobItemRole };
}

export type JobItemRoleDeleteAction = {
  type: typeof JOB_ITEM_ROLE_DELETE;
  jobItemRoleId: number;
  save: SaveType;
};

export function actionJobItemRoleDelete(
  jobItemRoleId: number,
  save: SaveType
): JobItemRoleDeleteAction {
  return { type: JOB_ITEM_ROLE_DELETE, jobItemRoleId, save };
}

export type JobItemRoleSetHoursAction = {
  type: typeof JOB_ITEM_ROLE_SET_HOURS;
  jobItemRoleId: number;
  value: string;
};

export function actionJobItemRoleSetHours(
  jobItemRoleId: number,
  value: string
): JobItemRoleSetHoursAction {
  return { type: JOB_ITEM_ROLE_SET_HOURS, jobItemRoleId, value };
}

export type JobItemRoleSetRateAction = {
  type: typeof JOB_ITEM_ROLE_SET_RATE;
  jobItemRoleId: number;
  value: string;
};

export function actionJobItemRoleSetRate(
  jobItemRoleId: number,
  value: string
): JobItemRoleSetRateAction {
  return { type: JOB_ITEM_ROLE_SET_RATE, jobItemRoleId, value };
}

export type JobItemRoleAssignUsersAction = {
  type: typeof JOB_ITEM_ROLE_ASSIGN_USERS;
  jobItemRoleId: number;
  users: { userId: number; minutes: number }[];
  save: SaveType;
};

export function actionJobItemRoleAssignUsers(
  jobItemRoleId: number,
  users: { userId: number; minutes: number }[],
  save: SaveType
): JobItemRoleAssignUsersAction {
  return { type: JOB_ITEM_ROLE_ASSIGN_USERS, jobItemRoleId, users, save };
}
