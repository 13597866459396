import { all } from "redux-saga/effects";
import watchDelayedCurrentFocus from "./watchDelayedCurrentFocus";
import watchFetch from "./watchFetch";
import watchFetchHistoricalSummary from "./watchFetchHistoricalSummary";
import watchFetchUnplannedTimeSummary from "./watchFetchUnplannedTimeSummary";
import watchJobCheckForceSave from "./watchForceSave";
import watchJobCheckFocus from "./watchJobCheckFocus";
import watchJobOpenCreateEditContact from "./watchJobOpenCreateEditContact";
import watchJobSave from "./watchJobSave";
import watchJobSetBudget from "./watchJobSetBudget";
import watchJobSetCompany from "./watchJobSetCompany";
import watchJobSetContact from "./watchJobSetContact";
import watchJobSetJobLead from "./watchJobSetJobLead";
import watchJobSetJobNumber from "./watchJobSetJobNumber";
import watchJobSetName from "./watchJobSetName";
import watchJobSetPONumber from "./watchJobSetPONumber";
import watchJobStatusChange from "./watchJobStatusChange";

export default function* jobDetailsSaga() {
  yield all([
    watchFetch(),
    watchDelayedCurrentFocus(),
    watchJobSetName(),
    watchJobCheckFocus(),
    watchJobSave(),
    watchJobStatusChange(),
    watchJobSetBudget(),
    watchJobSetContact(),
    watchJobOpenCreateEditContact(),
    watchJobSetPONumber(),
    watchJobSetJobNumber(),
    watchJobSetJobLead(),
    watchJobSetCompany(),
    watchJobCheckForceSave(),
    watchFetchHistoricalSummary(),
    watchFetchUnplannedTimeSummary()
  ]);
}
