import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsInvoiceId } from "../index";
import { selectStateInvoicePayments } from "./index";

export const selectInvoicePaymentIdsByInvoice = createCachedSelector(
  [selectStateInvoicePayments, selectPropsInvoiceId],
  (invoicePayments, invoiceId) =>
    get(invoicePayments, `idsByInvoiceId.${invoiceId}`, EMPTY_ARRAY)
)(selectPropsInvoiceId);

export const useInvoicePaymentIdsByInvoice = invoiceId =>
  useSelector(state => selectInvoicePaymentIdsByInvoice(state, { invoiceId }));
