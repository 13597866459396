import { call, put, select, takeLatest } from "redux-saga/effects";
import { mergeQuoteLineItemsAPI } from "../../../lib/API/quoteLineItemAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_QUOTE_LINE_ITEMS,
  LINE_ITEMS_MERGE_CONFIRM,
  QUOTE_LINE_ITEM_MERGE_SAVE_ERROR,
  QUOTE_LINE_ITEM_MERGE_SAVED,
  QUOTE_LINE_ITEM_MERGE_SAVING
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import {
  mergeDescriptions,
  mergeQuantities,
  mergeTotals,
  mergeUnitRates
} from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import { selectSortedQuoteLineItemsMergingByQuoteId } from "../../selectors/quoteLineItemSelectors";
import { selectQuoteUi } from "../../selectors/quoteSelectors";

function* mergeLineItems(action) {
  const { quoteId } = yield select(selectQuoteUi);

  if (!quoteId) return;

  const quoteLineItems = yield select(
    selectSortedQuoteLineItemsMergingByQuoteId,
    { quoteId }
  );

  if (!quoteLineItems.length) return;

  try {
    yield put(createAction(QUOTE_LINE_ITEM_MERGE_SAVING, { id: quoteId }));

    const [parentQuoteLineItem, ...childQuoteLineItems] = quoteLineItems;

    const description = mergeDescriptions(quoteLineItems);
    const quantity = mergeQuantities(quoteLineItems);
    const totalAmountExTax = mergeTotals(quoteLineItems);
    const unitRate = mergeUnitRates(
      parentQuoteLineItem,
      quantity,
      totalAmountExTax
    );

    const mergedQuoteLineItem = {
      ...parentQuoteLineItem,
      description,
      quantity,
      unitRate,
      totalAmountExTax
    };

    const mergedQuoteLineItems = [
      mergedQuoteLineItem,
      ...childQuoteLineItems.map(lineItem => ({ ...lineItem, active: false }))
    ];

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_QUOTE_LINE_ITEMS]: mergedQuoteLineItems
      })
    );

    const data = yield call(
      mergeQuoteLineItemsAPI,
      mergedQuoteLineItem,
      childQuoteLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    const snapshotId = createSnapshotId();

    yield put(
      createAction(QUOTE_LINE_ITEM_MERGE_SAVED, {
        id: quoteId,
        snapshotId,
        data: quoteLineItems
      })
    );

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_LINE_ITEM_MERGE_SAVE_ERROR, {
        id: quoteId,
        prevQuoteLineItems: quoteLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsMergeConfirm() {
  yield takeLatest(LINE_ITEMS_MERGE_CONFIRM, mergeLineItems);
}
