import { debounce } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { sharedEmitter } from "st-shared/lib";

import {
  JOB_TIMELINE_BOTTOM_SPACER,
  JOB_TIMELINE_DATE_HEADER_HEIGHT,
  JOB_TIMELINE_GROUP_COLUMN_WIDTH,
  JOB_TIMELINE_HEADER_HEIGHT,
} from "../../../lib/constants";
import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import {
  selectJobTimelineDayWidth,
  selectJobTimelineExportWidth,
  selectJobTimelineHeight,
} from "../../../redux/selectors/jobTimelineSelectors";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import ScrollContextProvider from "../../modules/ScrollContexts/ScrollContextProvider";

const mapState = (state, props) => ({
  dayWidth: selectJobTimelineDayWidth(state),
  contentHeight: selectJobTimelineHeight(state, props),
  exportWidth: selectJobTimelineExportWidth(state, props),
});

const mapDispatch = null;

export const DEFAULT_OFFSET = {
  top: JOB_TIMELINE_HEADER_HEIGHT,
  bottom: JOB_TIMELINE_BOTTOM_SPACER,
  left: JOB_TIMELINE_GROUP_COLUMN_WIDTH,
  right: 0,
};

const getViewportSize = (offset = DEFAULT_OFFSET) => ({
  viewportWidth: window.innerWidth - offset.left - offset.right,
  viewportHeight: window.innerHeight - offset.top - offset.bottom,
});

class JobTimelineScrollProvider extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    jobId: entityIdType.isRequired,
    dayWidth: PropTypes.number.isRequired,
    contentHeight: PropTypes.number.isRequired,
    exportWidth: PropTypes.number.isRequired,
    offset: PropTypes.shape({
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
    }),
  };

  static defaultProps = {
    offset: DEFAULT_OFFSET,
  };

  state = {
    ...getViewportSize(),
  };

  componentDidMount() {
    const { offset } = this.props;
    this.setState(getViewportSize(offset));
    if (window.top && window !== window.top)
      window.top.addEventListener("resize", this.onWindowResize);
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    if (window.top && window !== window.top)
      window.top.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const { offset } = this.props;
    if (window) this.setState(getViewportSize(offset));
  };

  onNewDateRange = debounce((startDate) => {
    sharedEmitter.emit("jobTimelineSelectDate", startDate);
  }, 200);

  getExportSize = () => {
    const { contentHeight, exportWidth } = this.props;
    return {
      width: exportWidth,
      height: contentHeight + JOB_TIMELINE_DATE_HEADER_HEIGHT,
    };
  };

  render() {
    const { children, dayWidth, contentHeight, offset } = this.props;
    const { viewportWidth, viewportHeight } = this.state;

    return (
      <ScrollContextProvider
        Context={JobTimelineScrollContext}
        contentHeight={contentHeight}
        viewportWidth={viewportWidth}
        viewportHeight={viewportHeight}
        viewportOffset={{
          x: offset.left,
          y: offset.top,
          b: offset.bottom,
        }}
        dayWidth={dayWidth}
        onNewDateRange={this.onNewDateRange}
      >
        {children}
      </ScrollContextProvider>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineScrollProvider);
