import type { Editor } from "@tiptap/react";
import { BubbleMenu } from "@tiptap/react";
import { AnimatePresence, motion } from "motion/react";

import { useRichTextEditorFocus } from "../hooks/useRichTextEditorFocus";
import type { ExtensionType } from "../types/ExtensionType";
import {
  ActionDivider,
  BlockQuoteAction,
  BoldAction,
  BulletListAction,
  CodeAction,
  CodeBlockAction,
  H1Action,
  H2Action,
  H3Action,
  ItalicAction,
  LinkAction,
  NumberListAction,
  UnderlineAction,
} from "./MenuActions";
import { usePopoverContainerContext } from "./PopoverContainer";
import * as styles from "./RichTextMenu.css";

export type RichTextMenuProps = {
  editor: Editor | null;
  extensionType: ExtensionType;
};

export function RichTextBubbleMenu({
  editor,
  extensionType,
}: RichTextMenuProps) {
  return (
    <BubbleMenu
      className={styles.bubbleMenu}
      editor={editor}
      tippyOptions={{ duration: 200, placement: "top" }}
    >
      <RichTextMenu editor={editor} extensionType={extensionType} />
    </BubbleMenu>
  );
}

export function RichTextFixedMenu({
  editor,
  extensionType,
}: RichTextMenuProps) {
  return (
    <div className={styles.fixedMenu}>
      <RichTextMenu editor={editor} extensionType={extensionType} />
    </div>
  );
}

export function RichTextFloatingMenu({
  editor,
  extensionType,
}: RichTextMenuProps) {
  const hasFocus = useRichTextEditorFocus(editor);
  const { isOpen: isPopoverOpen } = usePopoverContainerContext();

  return (
    <AnimatePresence initial={false}>
      {hasFocus && !isPopoverOpen && (
        <motion.div
          className={styles.floatingMenu}
          initial="hide"
          animate="open"
          exit="hide"
          variants={{
            open: {
              opacity: 1,
              transition: { duration: 0.2, ease: "easeInOut" },
            },
            hide: {
              opacity: 0,
              transition: { duration: 0.2, ease: "easeInOut" },
            },
          }}
        >
          <RichTextMenu editor={editor} extensionType={extensionType} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function RichTextMenu({ editor, extensionType }: RichTextMenuProps) {
  switch (extensionType) {
    case "base":
      return (
        <div className={styles.menu}>
          <BoldAction editor={editor} />
          <ItalicAction editor={editor} />
          <UnderlineAction editor={editor} />
          <LinkAction editor={editor} />
          <ActionDivider />
          <H1Action editor={editor} />
          <H2Action editor={editor} />
          <H3Action editor={editor} />
          <ActionDivider />
          <BlockQuoteAction editor={editor} />
          <NumberListAction editor={editor} />
          <BulletListAction editor={editor} />
          <ActionDivider />
          <CodeAction editor={editor} />
          <CodeBlockAction editor={editor} />
        </div>
      );
    case "simple":
    case "thread":
      return (
        <div className={styles.menu}>
          <BoldAction editor={editor} />
          <ItalicAction editor={editor} />
          <UnderlineAction editor={editor} />
          <CodeAction editor={editor} />
          <ActionDivider />
          <NumberListAction editor={editor} />
          <BulletListAction editor={editor} />
        </div>
      );
  }
}
