import numeral from "numeral";
import { costingMethodObj, CostingMethods } from "st-shared/entities";
import { FrameworkException } from "st-shared/lib";

import { NUMERAL_OPTIONAL_CENTS } from "../../../lib/constants";
import {
  getSellRate,
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
  hasSellRate,
} from "../../../lib/entities/jobItemEntity";
import { getJobCurrencySellRate } from "../../../lib/entities/jobItemUserEntity";
import { asDecimal } from "../../../lib/math";
import { selectJobItem } from "../jobItem";
import { selectJobItemUser } from "./index";

export const selectJobItemUserCalculatedSellRate = (
  state,
  { jobItemUserId }
) => {
  const jobItemUser = selectJobItemUser(state, { jobItemUserId });
  const { jobItemId } = jobItemUser;
  const jobItem = selectJobItem(state, { jobItemId });

  let value = "";

  switch (jobItem.costingMethod.id) {
    case CostingMethods.Item:
      if (hasSellRate(jobItem)) {
        value = String(getSellRate(jobItem));
      } else {
        value = getJobCurrencySellRate(jobItemUser);
      }
      break;
    case CostingMethods.People:
    case CostingMethods.ValueUserSell:
      value = getJobCurrencySellRate(jobItemUser);
      break;
    case CostingMethods.ValueCalculatedSell:
      if (getTotalPlannedMinutes(jobItem) > 0) {
        value = numeral(getTotalPlannedMoney(jobItem))
          .divide(numeral(getTotalPlannedMinutes(jobItem)).divide(60).value())
          .format(NUMERAL_OPTIONAL_CENTS);
      }
      break;
    default:
      throw new FrameworkException(
        costingMethodObj(jobItem.costingMethod).getUnknownIdError()
      );
  }

  return asDecimal(value);
};
