import { ScheduleUi } from "../../../state/ui/schedule/types";
import ScheduleBlocksWrapper from "../ScheduleLayout/ScheduleBlocksWrapper";
import { ScheduleRoleBlankCell } from "../ScheduleLayout/ScheduleRoleBlankCell";

function ScheduleJobItemRoleTodos() {
  return (
    <ScheduleBlocksWrapper style={{ height: ScheduleUi.Job.CompactRowHeight }}>
      <ScheduleRoleBlankCell />
    </ScheduleBlocksWrapper>
  );
}

export default ScheduleJobItemRoleTodos;
