import { get } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_INVOICES
} from "../../lib/constants";
import { simpleCompareAsc } from "../../lib/dates";
import {
  getJobIdInvoiceDateKeyPath,
  getJobIdInvoiceMonthKeyPath
} from "../../lib/entities/invoiceEntity";
import {
  getBranchId,
  getJobCompany,
  getJobNumber,
  getRateCardId
} from "../../lib/entities/jobEntity";
import { getJobId, getExchangeRate } from "../../lib/ui/commercialDocument";
import { selectJobById } from "./jobSelectors";
import {
  selectPropsDate,
  selectPropsId,
  selectPropsInvoiceId,
  selectPropsJobId
} from "./index";

export const selectInvoices = state => state.entities[ENTITY_NAME_INVOICES];

export const selectInvoiceById = createSelector(
  selectInvoices,
  selectPropsId,
  (invoices, id) => get(invoices, `byId.${id}`)
);

export const selectInvoiceByInvoiceId = (state, props) =>
  selectInvoiceById(state, { id: selectPropsInvoiceId(state, props) });

export const selectInvoiceJobIdById = createSelector(
  selectInvoiceById,
  getJobId
);

export const selectInvoiceJobById = (state, props) =>
  selectJobById(state, { id: selectInvoiceJobIdById(state, props) });

export const selectInvoiceJobByInvoiceId = (state, props) =>
  selectInvoiceJobById(state, { id: props.invoiceId });

export const selectInvoiceJobCompanyById = createSelector(
  selectInvoiceJobById,
  getJobCompany
);

export const selectInvoiceJobNumberById = createSelector(
  selectInvoiceJobById,
  getJobNumber
);

export const selectInvoiceJobBranchIdById = createSelector(
  selectInvoiceJobById,
  job => getBranchId(job)
);

export const selectInvoiceJobRateCardIdById = createSelector(
  selectInvoiceJobById,
  getRateCardId
);

export const selectInvoiceJobRateCardIdByInvoiceId = createSelector(
  selectInvoiceJobByInvoiceId,
  getRateCardId
);

export const selectInvoiceExchangeRateByInvoiceId = createSelector(
  selectInvoiceByInvoiceId,
  getExchangeRate
);

export const selectInvoiceIdsByJobId = createSelector(
  selectInvoices,
  selectPropsJobId,
  (invoices, jobId) => get(invoices, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectSortedInvoicesByJobId = createSelector(
  selectInvoices,
  selectInvoiceIdsByJobId,
  (invoices, invoiceIds) =>
    invoiceIds
      .map(id => get(invoices, `byId.${id}`))
      .slice()
      .sort((i1, i2) => simpleCompareAsc(i1.invoiceDate, i2.invoiceDate))
);

export const selectInvoiceIdsByJobIdDate = createSelector(
  selectInvoices,
  selectPropsJobId,
  selectPropsDate,
  (invoices, jobId, invoiceDate) =>
    get(
      invoices,
      `idsByJobIdInvoiceDate${getJobIdInvoiceDateKeyPath({
        jobId,
        invoiceDate
      })}`,
      EMPTY_ARRAY
    )
);

export const selectInvoiceIdsByJobIdMonth = createSelector(
  selectInvoices,
  selectPropsJobId,
  selectPropsDate,
  (invoices, jobId, invoiceDate) =>
    get(
      invoices,
      `idsByJobIdInvoiceMonth${getJobIdInvoiceMonthKeyPath({
        jobId,
        invoiceDate
      })}`,
      EMPTY_ARRAY
    )
);

const initialDateRange = {
  startDate: null,
  endDate: null
};

export const selectInvoiceDateRangeByJobId = createSelector(
  selectInvoices,
  selectPropsJobId,
  (invoices, jobId) =>
    get(invoices, `dateRangeByJobId.${jobId}`, initialDateRange)
);

export const selectInvoiceUi = state => state.ui.invoice;

export const selectInvoicesUi = state => state.ui.invoices;

export const selectInvoicesUiById = createSelector(
  selectInvoicesUi,
  selectPropsId,
  (invoicesUi, id) => get(invoicesUi, id, EMPTY_OBJECT)
);
