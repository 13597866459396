import { select } from "redux-saga/effects";
import { Exception } from "st-shared/lib";

import {
  selectIsScheduleBlockBilled,
  selectIsScheduleBlockPrivate,
} from "../../selectors/scheduleBlockSelectors";

function* validateCanModifyBlock(blockKey) {
  const isPrivate = yield select(selectIsScheduleBlockPrivate, { blockKey });
  const isBilled = yield select(selectIsScheduleBlockBilled, { blockKey });

  if (isPrivate) throw new Exception("Sorry, you can't modify a private To Do");

  if (isBilled) throw new Exception("Sorry, you can't modify a billed To Do");
}

export default validateCanModifyBlock;
