import { isEmpty } from "lodash-es";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { undoMergePurchaseOrderLineItemsAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import { undoMergeLineItems } from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLoggedExpenseUi } from "../../selectors/loggedExpenseSelectors";
import { selectSortedPurchaseOrderLineItemsByPurchaseOrderId } from "../../selectors/purchaseOrderLineItemSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* undoMerge(action) {
  const { purchaseOrderId, snapshotId } = action.payload;

  const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

  if (!loggedExpenseId) return;

  const prevPurchaseOrderLineItems = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  if (isEmpty(prevPurchaseOrderLineItems)) return;

  const [
    basePurchaseOrderLineItem,
    ...otherPurchaseOrderLineItems
  ] = prevPurchaseOrderLineItems;

  const currentPurchaseOrderLineItems = yield select(
    selectSortedPurchaseOrderLineItemsByPurchaseOrderId,
    { purchaseOrderId }
  );

  try {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING, {
        id: loggedExpenseId,
        purchaseOrderId
      })
    );

    const newPurchaseOrderLineItems = undoMergeLineItems(
      basePurchaseOrderLineItem,
      otherPurchaseOrderLineItems,
      currentPurchaseOrderLineItems
    );

    if (newPurchaseOrderLineItems.length)
      yield put(
        createAction(ENTITIES_RECEIVED, {
          [ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS]: newPurchaseOrderLineItems
        })
      );

    const data = yield call(
      undoMergePurchaseOrderLineItemsAPI,
      basePurchaseOrderLineItem,
      otherPurchaseOrderLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED, {
        id: loggedExpenseId,
        purchaseOrderId,
        snapshotId
      })
    );

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR, {
        id: loggedExpenseId,
        purchaseOrderId,
        prevPurchaseOrderLineItems: currentPurchaseOrderLineItems,
        mergedPurchaseOrderLineItems: otherPurchaseOrderLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchUndoMergeLineItems() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE, undoMerge);
}
