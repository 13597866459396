import { isEqual, pick, values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import WebAppAPI, {
  RECEIVE_CANCEL_MERGE_LINE_ITEMS,
  RECEIVE_CANCEL_SORT_LINE_ITEMS,
  RECEIVE_COMMERCIAL_DOCUMENT_DISPLAY_OPTIONS,
  RECEIVE_CONFIRM_MERGE_LINE_ITEMS,
  RECEIVE_CONFIRM_SORT_LINE_ITEMS,
  RECEIVE_START_MERGE_LINE_ITEMS,
  RECEIVE_START_SORT_LINE_ITEMS
} from "../../../../lib/WebAppAPI";
import {
  ACCOUNTING_PLATFORM_FETCH,
  LINE_ITEMS_MERGE_CANCEL,
  LINE_ITEMS_MERGE_CONFIRM,
  LINE_ITEMS_MERGE_START,
  LINE_ITEMS_SORT_CANCEL,
  LINE_ITEMS_SORT_CONFIRM,
  LINE_ITEMS_SORT_START
} from "../../../../lib/constants";
import { companyEntityType } from "../../../../lib/entities/companyEntity";
import { entityIdType } from "../../../../lib/types/entityTypes";
import { reactNodesType } from "../../../../lib/types/reactTypes";
import {
  COMMERCIAL_DOCUMENT_TYPES,
  commercialDocumentEntityType
} from "../../../../lib/ui/commercialDocument";
import createAction from "../../../../redux/helpers/createAction";
import CommercialDocumentContext from "./index";

const mapDispatch = dispatch => ({
  doMergeLineItemsStart: () => dispatch(createAction(LINE_ITEMS_MERGE_START)),
  doMergeLineItemsConfirm: () =>
    dispatch(createAction(LINE_ITEMS_MERGE_CONFIRM)),
  doMergeLineItemsCancel: () => dispatch(createAction(LINE_ITEMS_MERGE_CANCEL)),
  doSortLineItemsStart: () => dispatch(createAction(LINE_ITEMS_SORT_START)),
  doSortLineItemsConfirm: () => dispatch(createAction(LINE_ITEMS_SORT_CONFIRM)),
  doSortLineItemsCancel: () => dispatch(createAction(LINE_ITEMS_SORT_CANCEL)),
  doFetchAccountingPlatform: branchId =>
    dispatch(createAction(ACCOUNTING_PLATFORM_FETCH, { branchId }))
});

class CommercialDocumentContextProvider extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
      .isRequired,
    document: commercialDocumentEntityType.isRequired,
    pageUi: PropTypes.shape({
      isMergeMode: PropTypes.bool.isRequired,
      isSortMode: PropTypes.bool.isRequired
    }).isRequired,
    documentUi: PropTypes.shape({
      isSaving: PropTypes.bool,
      isMergeSaving: PropTypes.bool,
      canUndo: PropTypes.bool,
      undoMergeSnapshotId: PropTypes.number,
      isUndoMergeSaving: PropTypes.bool,
      isSortSaving: PropTypes.bool
    }),
    documentMethods: PropTypes.objectOf(PropTypes.func).isRequired,
    documentLineItemMethods: PropTypes.objectOf(PropTypes.func).isRequired,
    branchId: entityIdType.isRequired,
    rateCardId: entityIdType.isRequired,
    jobNumber: PropTypes.string.isRequired,
    jobCompany: companyEntityType.isRequired,
    doEditDocument: PropTypes.func.isRequired,
    doSaveDocument: PropTypes.func.isRequired,
    doSetAddress: PropTypes.func.isRequired,
    doSetContact: PropTypes.func.isRequired,
    doCreateContact: PropTypes.func.isRequired,
    doEditContact: PropTypes.func.isRequired,
    doCreateAddress: PropTypes.func.isRequired,
    doMergeLineItemsStart: PropTypes.func.isRequired,
    doMergeLineItemsConfirm: PropTypes.func.isRequired,
    doMergeLineItemsCancel: PropTypes.func.isRequired,
    doSortLineItemsStart: PropTypes.func.isRequired,
    doSortLineItemsConfirm: PropTypes.func.isRequired,
    doSortLineItemsCancel: PropTypes.func.isRequired,
    doMergeLineItemsUndo: PropTypes.func.isRequired,
    doSortLineItemsMove: PropTypes.func.isRequired,
    doEditDocumentLineItem: PropTypes.func.isRequired,
    doSaveDocumentLineItem: PropTypes.func.isRequired,
    doDeleteDocumentLineItem: PropTypes.func.isRequired,
    doToggleMergeDocumentLineItem: PropTypes.func.isRequired,
    doDocumentLineItemCreateTaxRate: PropTypes.func.isRequired,
    doAddLine: PropTypes.func.isRequired,
    doAddMasterJobItem: PropTypes.func.isRequired,
    doAddMasterLoggedExpense: PropTypes.func.isRequired,
    doAddFromJobPlan: PropTypes.func.isRequired,
    doFetchAccountingPlatform: PropTypes.func.isRequired,
    children: reactNodesType.isRequired
  };

  static defaultProps = {
    documentUi: {
      isSaving: false,
      isMergeSaving: false,
      canUndo: false,
      undoMergeSnapshotId: false,
      isUndoMergeSaving: false,
      isSortSaving: false
    }
  };

  componentDidMount() {
    const {
      doMergeLineItemsStart,
      doMergeLineItemsCancel,
      doMergeLineItemsConfirm,
      doSortLineItemsStart,
      doSortLineItemsConfirm,
      doSortLineItemsCancel,
      branchId,
      doFetchAccountingPlatform
    } = this.props;

    doFetchAccountingPlatform(branchId);

    WebAppAPI.registerReceiver(
      RECEIVE_START_MERGE_LINE_ITEMS,
      doMergeLineItemsStart
    );
    WebAppAPI.registerReceiver(
      RECEIVE_CONFIRM_MERGE_LINE_ITEMS,
      doMergeLineItemsConfirm
    );
    WebAppAPI.registerReceiver(
      RECEIVE_CANCEL_MERGE_LINE_ITEMS,
      doMergeLineItemsCancel
    );
    WebAppAPI.registerReceiver(
      RECEIVE_START_SORT_LINE_ITEMS,
      doSortLineItemsStart
    );
    WebAppAPI.registerReceiver(
      RECEIVE_CONFIRM_SORT_LINE_ITEMS,
      doSortLineItemsConfirm
    );
    WebAppAPI.registerReceiver(
      RECEIVE_CANCEL_SORT_LINE_ITEMS,
      doSortLineItemsCancel
    );
    WebAppAPI.registerReceiver(
      RECEIVE_COMMERCIAL_DOCUMENT_DISPLAY_OPTIONS,
      this.receiveDisplayOptions
    );
  }

  componentWillUnmount() {
    WebAppAPI.unregisterReceiver(RECEIVE_START_MERGE_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_CONFIRM_MERGE_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_CANCEL_MERGE_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_START_SORT_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_CONFIRM_SORT_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_CANCEL_SORT_LINE_ITEMS);
    WebAppAPI.unregisterReceiver(RECEIVE_COMMERCIAL_DOCUMENT_DISPLAY_OPTIONS);
  }

  get actions() {
    return pick(this.props, [
      "doEditDocument",
      "doSaveDocument",
      "doMergeLineItemsUndo",
      "doSetAddress",
      "doSetContact",
      "doCreateContact",
      "doEditContact",
      "doCreateAddress",
      "doSortLineItemsMove",
      "doEditDocumentLineItem",
      "doSaveDocumentLineItem",
      "doDeleteDocumentLineItem",
      "doToggleMergeDocumentLineItem",
      "doDocumentLineItemCreateTaxRate",
      "doAddLine",
      "doAddMasterJobItem",
      "doAddMasterLoggedExpense",
      "doAddFromJobPlan"
    ]);
  }

  receiveDisplayOptions = data => {
    const { document, documentMethods, doSaveDocument } = this.props;
    const {
      getDisplayOptions,
      getLanguageOptions,
      setDisplayAndLanguageOptions
    } = documentMethods;
    const { id } = data;

    if (document.id !== data.id) return false;

    const newDocument = setDisplayAndLanguageOptions(document, data);

    if (
      !isEqual(getDisplayOptions(newDocument), getDisplayOptions(document)) ||
      !isEqual(getLanguageOptions(newDocument), getLanguageOptions(document))
    )
      doSaveDocument(id, newDocument, document);

    return true;
  };

  render() {
    const {
      id,
      commercialDocumentType,
      pageUi,
      document,
      jobNumber,
      branchId,
      rateCardId,
      jobCompany,
      documentUi,
      documentMethods,
      documentLineItemMethods,
      children
    } = this.props;

    return (
      <CommercialDocumentContext.Provider
        value={{
          id,
          commercialDocumentType,
          pageUi,
          document,
          jobNumber,
          branchId,
          documentMethods,
          documentLineItemMethods,
          rateCardId,
          jobCompany,
          documentUi,
          actions: this.actions
        }}
      >
        {children}
      </CommercialDocumentContext.Provider>
    );
  }
}

export default connect(null, mapDispatch)(CommercialDocumentContextProvider);
