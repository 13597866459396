import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const QuoteStore = createSingleEntityStoreContext(EntityClass.Quote);

export const QuoteStoreProvider = createSingleEntityStoreProvider(
  EntityClass.Quote,
  QuoteStore
);
