import { call, put, select, takeLatest } from "redux-saga/effects";
import { mergeInvoiceLineItemsAPI } from "../../../lib/API/invoiceLineItemAPI";
import { sendRefreshInvoiceHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
  LINE_ITEMS_MERGE_CONFIRM,
  INVOICE_LINE_ITEM_MERGE_SAVE_ERROR,
  INVOICE_LINE_ITEM_MERGE_SAVED,
  INVOICE_LINE_ITEM_MERGE_SAVING
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import {
  mergeDescriptions,
  mergeQuantities,
  mergeTotals,
  mergeUnitRates
} from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import { selectSortedInvoiceLineItemsMergingByInvoiceId } from "../../selectors/invoiceLineItemSelectors";
import { selectInvoiceUi } from "../../selectors/invoiceSelectors";

function* mergeLineItems(action) {
  const { invoiceId } = yield select(selectInvoiceUi);

  if (!invoiceId) return;

  const invoiceLineItems = yield select(
    selectSortedInvoiceLineItemsMergingByInvoiceId,
    { invoiceId }
  );

  if (!invoiceLineItems.length) return;

  try {
    yield put(createAction(INVOICE_LINE_ITEM_MERGE_SAVING, { id: invoiceId }));

    const [parentInvoiceLineItem, ...childInvoiceLineItems] = invoiceLineItems;

    const description = mergeDescriptions(invoiceLineItems);
    const quantity = mergeQuantities(invoiceLineItems);
    const totalAmountExTax = mergeTotals(invoiceLineItems);
    const unitRate = mergeUnitRates(
      parentInvoiceLineItem,
      quantity,
      totalAmountExTax
    );

    const mergedInvoiceLineItem = {
      ...parentInvoiceLineItem,
      description,
      quantity,
      unitRate,
      totalAmountExTax
    };

    const mergedInvoiceLineItems = [
      mergedInvoiceLineItem,
      ...childInvoiceLineItems.map(lineItem => ({ ...lineItem, active: false }))
    ];

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_INVOICE_LINE_ITEMS]: mergedInvoiceLineItems
      })
    );

    const data = yield call(
      mergeInvoiceLineItemsAPI,
      mergedInvoiceLineItem,
      childInvoiceLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    const snapshotId = createSnapshotId();

    yield put(
      createAction(INVOICE_LINE_ITEM_MERGE_SAVED, {
        id: invoiceId,
        snapshotId,
        data: invoiceLineItems
      })
    );

    yield call(sendRefreshInvoiceHtml);
  } catch (error) {
    yield put(
      createAction(INVOICE_LINE_ITEM_MERGE_SAVE_ERROR, {
        id: invoiceId,
        prevInvoiceLineItems: invoiceLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsMergeConfirm() {
  yield takeLatest(LINE_ITEMS_MERGE_CONFIRM, mergeLineItems);
}
