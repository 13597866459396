import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import { ContactStatuses } from "st-shared/entities/ContactStatus";
import { asString } from "st-shared/lib";

import { entityIdType } from "../types/entityTypes";
import { getInitials } from "./userEntity";

export const CONTACT_GROUPED_OPTION_TYPE = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  OPTIONS_HEADING: "OPTIONS_HEADING",
};

export const contactShape = {
  id: entityIdType.isRequired,
  companyId: entityIdType.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  mobileNumber: PropTypes.string,
  department: PropTypes.string,
  position: PropTypes.string,
  notes: PropTypes.string,
  contactStatus: PropTypes.shape({
    id: entityIdType.isRequired,
    name: PropTypes.string,
  }),
};

export const contactEntityType = PropTypes.shape(contactShape);

export const contactOptionType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string.isRequired,
  optionType: PropTypes.oneOf(values(CONTACT_GROUPED_OPTION_TYPE)).isRequired,
  searchString: PropTypes.string,
});

export const getFullName = ({ firstName, lastName }) =>
  `${firstName}${lastName ? ` ${lastName}` : ""}`;

export const getSearchString = (contact) =>
  [
    asString(contact.firstName),
    asString(contact.lastName),
    asString(contact.email),
    asString(contact.department),
    asString(contact.position),
    asString(contact.contactStatus.name),
    getInitials(contact),
  ].join(" ");

export const getOptionType = ({ contactStatus }) => {
  if (contactStatus.id === ContactStatuses.Active) {
    return CONTACT_GROUPED_OPTION_TYPE.ACTIVE;
  }
  if (contactStatus.id === ContactStatuses.Archived) {
    return CONTACT_GROUPED_OPTION_TYPE.ARCHIVED;
  }
  return CONTACT_GROUPED_OPTION_TYPE.DELETED;
};

export const getAsOption = (contact) => ({
  key: contact.id,
  value: getFullName(contact),
  optionType: getOptionType(contact),
  searchString: getSearchString(contact),
});
