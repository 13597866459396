import { sendApplyModelData, sendRemoveModel } from "../WebAppAPI";
import { ENTITY_CLASS_LABEL, ENTITY_NAME_LABELS } from "../constants";
import { getNewLabelAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const fetchLabelsAPI = (entityId, labelTypeId) =>
  API.fetch(`/labels?entity_id=${entityId}&label_type_id=${labelTypeId}`).then(
    ({ data }) => ({
      [ENTITY_NAME_LABELS]: data
    })
  );

export const deleteLabelAPI = labelId =>
  labelId > 0
    ? API.delete(`/labels/${labelId}`)
        .then(({ data }) => data)
        .then(data => {
          sendRemoveModel(ENTITY_CLASS_LABEL, labelId);
          sendApplyModelData(ENTITY_CLASS_LABEL, data);
          return data;
        })
        .then(createModelSetParser(ENTITY_CLASS_LABEL))
    : Promise.resolve({});

export const createLabelAPI = (label, labelTypeId) =>
  API.post(`/labels?label_type_id=${labelTypeId}`, getNewLabelAsMessage(label))
    .then(({ data }) => data)
    .then(data => {
      sendApplyModelData(ENTITY_CLASS_LABEL, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_LABEL));
