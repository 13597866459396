import { UserStatusEnum } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { getUserProfileImageUrl } from "../API/usersAPI";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";

export const userEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  branchId: entityIdType,
  billableRate: entityFieldDecimalType,
  canAccessContacts: PropTypes.bool.isRequired,
  canAccessCostRates: PropTypes.bool.isRequired,
  canAccessReporting: PropTypes.bool.isRequired,
  canAccessItemsAndExpenses: PropTypes.bool.isRequired,
  canAccessJobs: PropTypes.bool.isRequired,
  canAccessToDoUserToggle: PropTypes.bool.isRequired,
  canAccessSettings: PropTypes.bool.isRequired,
  canAccessSubscription: PropTypes.bool.isRequired,
  canAccessTeam: PropTypes.bool.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
  canEditTeamLoggedTimes: PropTypes.bool.isRequired,
  canViewJobFinancials: PropTypes.bool.isRequired,
  costRate: entityFieldDecimalType,
  createdAt: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  hoursWorkedFriday: PropTypes.string.isRequired,
  hoursWorkedMonday: PropTypes.string.isRequired,
  hoursWorkedSaturday: PropTypes.string.isRequired,
  hoursWorkedSunday: PropTypes.string.isRequired,
  hoursWorkedThursday: PropTypes.string.isRequired,
  hoursWorkedTuesday: PropTypes.string.isRequired,
  hoursWorkedWednesday: PropTypes.string.isRequired,
  invitationSent: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  onboardingStage: PropTypes.number,
  organisationId: entityIdType.isRequired,
  phoneNumber: PropTypes.string,
  refereeRewardPending: PropTypes.number.isRequired,
  referralClicks: PropTypes.number.isRequired,
  referralPoints: PropTypes.number.isRequired,
  roleId: entityIdType,
  shortenedReferralLink: PropTypes.string,
  userStatus: typeEntityType.isRequired,
  isSystemUser: PropTypes.bool.isRequired,
});

export const userOptionType = PropTypes.shape({
  key: entityFieldDecimalType.isRequired,
  value: PropTypes.string.isRequired,
  roleId: entityIdType,
  searchString: PropTypes.string,
});

export const getDisplayName = ({ displayName }) => displayName;

export const getWorkingHours = (user) => [
  Number(user.hoursWorkedSunday),
  Number(user.hoursWorkedMonday),
  Number(user.hoursWorkedTuesday),
  Number(user.hoursWorkedWednesday),
  Number(user.hoursWorkedThursday),
  Number(user.hoursWorkedFriday),
  Number(user.hoursWorkedSaturday),
];

export const isActive = ({ userStatus }) =>
  userStatus.id === UserStatusEnum.Active;

export const getInitials = ({ firstName, lastName }) =>
  asString(firstName)
    .substring(0, 1)
    .concat(asString(lastName).substring(0, 1));

export const getSearchString = (user) =>
  [
    asString(user.displayName),
    asString(user.firstName),
    asString(user.lastName),
    getInitials(user),
  ].join(" ");

export const getAsOption = (user) => ({
  key: user.id,
  value: user.displayName,
  roleId: user.roleId,
  branchId: user.branchId,
  searchString: getSearchString(user),
});

export const userOptionToMention = (userOption) => ({
  ...userOption,
  name: userOption.value,
  avatar: getUserProfileImageUrl(userOption.key),
});

export const getRoleId = ({ roleId }) => roleId;

export const getBillableRate = ({ billableRate }) => asDecimal(billableRate);

export const getIsSystemUser = ({ isSystemUser }) => isSystemUser;

export const getCostRate = ({ costRate }) => asDecimal(costRate);
