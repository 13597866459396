// deprecated

import { asDecimal } from "../lib/math";
import type { Entity } from "./Entity";

export type Type = Rate;
export interface Rate extends Entity {
  archived: boolean;
  rateCardId: number;
  rate: string | null;
}

export function isDeleted(entity: Rate) {
  return entity.archived;
}

export function getRate(entity: Rate) {
  return asDecimal(entity.rate);
}
