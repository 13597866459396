import type { TEntityClassMap, TEntityId } from "@streamtimefe/entities";
import { useShallow } from "zustand/shallow";

import type { TEntityStoreClass } from "./EntityStore";
import { EntityStore } from "./EntityStore";

export function useEntityStore() {
  return EntityStore.store;
}

export function useEntityStoreActions() {
  return EntityStore.actions;
}

export function useEntityStoreEntity<T extends TEntityStoreClass>(
  entityClass: T,
  id?: TEntityId | null
) {
  return useEntityStore()((s) => (id ? s[entityClass].byId[id] : undefined));
}

export function getEntityStoreEntity<T extends TEntityStoreClass>(
  entityClass: T,
  id: TEntityId
) {
  return EntityStore.store.getState()[entityClass].byId[id];
}

export function useEntityStoreEntityBySyncKey<T extends TEntityStoreClass>(
  entityClass: T,
  syncKey?: string | null
) {
  return useEntityStore()((s) => {
    if (syncKey) {
      const id = s[entityClass].syncKeyIndex[syncKey];
      if (id) {
        return s[entityClass].byId[id];
      }
    }
    return undefined;
  });
}

export function getEntityStoreEntityBySyncKey<T extends TEntityStoreClass>(
  entityClass: T,
  syncKey: string
) {
  const id = EntityStore.store.getState()[entityClass].syncKeyIndex[syncKey];
  if (id) {
    return EntityStore.store.getState()[entityClass].byId[id];
  }
  return undefined;
}

export function createUseEntityStoreEntity<C extends TEntityStoreClass>(
  entityClass: C
) {
  function useEntityStoreEntity(
    id: TEntityId | null | undefined
  ): TEntityClassMap[C] | undefined;
  function useEntityStoreEntity<U>(
    id: TEntityId | null | undefined,
    selector: (state: TEntityClassMap[C]) => U
  ): U | undefined;
  function useEntityStoreEntity<U>(
    id: TEntityId | null | undefined,
    selector?: (state: TEntityClassMap[C]) => U
  ) {
    return useEntityStore()(
      useShallow((s) => {
        if (!id || !(entityClass in s)) return undefined;
        const entity = s[entityClass].byId[id];
        return entity && selector ? selector(entity) : entity;
      })
    );
  }
  return useEntityStoreEntity;
}

export function createGetEntityStoreEntity<C extends TEntityStoreClass>(
  entityClass: C
) {
  function getEntityStoreEntity(
    id: TEntityId | null | undefined
  ): TEntityClassMap[C] | undefined;
  function getEntityStoreEntity<U>(
    id: TEntityId | null | undefined,
    selector: (state: TEntityClassMap[C]) => U
  ): U | undefined;
  function getEntityStoreEntity<U>(
    id: TEntityId | null | undefined,
    selector?: (state: TEntityClassMap[C]) => U
  ) {
    const s = EntityStore.store.getState();
    if (!id || !(entityClass in s)) return undefined;
    const entity = s[entityClass].byId[id];
    return entity && selector ? selector(entity) : entity;
  }
  return getEntityStoreEntity;
}

export function useEntityStoreEntities<T extends TEntityStoreClass>(
  entityClass: T
) {
  return useEntityStore()((s) => s[entityClass].byId);
}

export function getEntityStoreEntities<T extends TEntityStoreClass>(
  entityClass: T
) {
  return EntityStore.store.getState()[entityClass].byId;
}
