import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const LoggedExpenseStore = createSingleEntityStoreContext(
  EntityClass.LoggedExpense
);

export const LoggedExpenseStoreProvider = createSingleEntityStoreProvider(
  EntityClass.LoggedExpense,
  LoggedExpenseStore
);
