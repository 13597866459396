import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../../lib/constants";
import { selectPropsInvoiceId } from "../../index";
import { selectInvoicesUi } from "./index";

export const selectPreviousIssuedInvoiceSummary = createCachedSelector(
  [selectInvoicesUi],
  invoicesUi => get(invoicesUi, "previousIssuedInvoiceSummary", EMPTY_ARRAY)
)(selectPropsInvoiceId);

export const usePreviousIssuedInvoiceSummary = invoiceId =>
  useSelector(state =>
    selectPreviousIssuedInvoiceSummary(state, { invoiceId })
  );
