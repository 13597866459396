import { clsx } from "clsx";
import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import { cloneElement, useRef, useState } from "react";
import type {
  TooltipProps as AriaTooltipProps,
  TooltipTriggerComponentProps as AriaTooltipTriggerComponentProps,
} from "react-aria-components";
import {
  OverlayArrow,
  Tooltip as AriaTooltip,
  TooltipTrigger as AriaTooltipTrigger,
} from "react-aria-components";

import { useTheme } from "../core";
import {
  overlayArrowStyle,
  overlayArrowSVGStyle,
  tooltipStyle,
} from "./styles.css";

type TooltipProps = Omit<AriaTooltipProps, "children"> & {
  children: ReactNode;
};

/**
 * A Tooltip displays a description of an element on hover or focus.
 * The Tooltip component is built on the [React Aria Tooltip Component](https://react-spectrum.adobe.com/react-aria/Tooltip.html).
 * A full list of props available to the Tooltip component can be found in the
 * [props section of the documentation](https://react-spectrum.adobe.com/react-aria/Tooltip.html#props).
 *
 * ### Usage
 *
 * A Tooltip provides brief, context-specific information when a user hovers over
 * an element, enhancing understanding without requiring interaction. It is typically
 * used for short descriptions, labels, or additional details that appear temporarily
 * and disappear once the user moves the pointer away.
 *
 * You may want to use a Tooltip when providing quick tips, descriptions of icons,
 * or extra information for form fields.
 *
 * ### Accessibility
 *
 * Tooltip triggers must be focusable and hoverable in order to ensure that all
 * users can activate them. When displayed, TooltipTrigger automatically associates
 * the tooltip with the trigger element so that it is described by the tooltip content.
 * Tooltips are hover-triggered to avoid unnecessary distractions and maintain focus
 * on primary content. Screen readers can announce tooltips on focus for non-mouse users.
 *
 * **Note:** tooltips are not shown on touch screen interactions. Ensure that your
 * UI is usable without tooltips, or use an alternative component such as Popover
 * to show information in an adjacent element.
 */
export function Tooltip({ children, className, ...rest }: TooltipProps) {
  // The theme is needed because a tooltip is a portal and is not a child of the parent component.
  const theme = useTheme();

  return (
    <AriaTooltip {...rest} className={clsx(theme, tooltipStyle, className)}>
      <OverlayArrow className={overlayArrowStyle}>
        <svg
          width={11}
          height={11}
          viewBox="0 0 11 11"
          className={overlayArrowSVGStyle}
        >
          <path d="M0 0L5.5 6L11 0H0Z" />
        </svg>
      </OverlayArrow>
      {children}
    </AriaTooltip>
  );
}

export function TooltipTrigger({
  children,
  delay,
  closeDelay,
  ...rest
}: AriaTooltipTriggerComponentProps) {
  return (
    <AriaTooltipTrigger
      delay={delay ?? 0}
      closeDelay={closeDelay ?? 0}
      {...rest}
    >
      {children}
    </AriaTooltipTrigger>
  );
}

type NonButtonTooltipTriggerProps = PropsWithChildren & {
  tooltip: ReactElement<TooltipProps>;
};

export function NonButtonTooltipTrigger({
  children,
  tooltip,
}: NonButtonTooltipTriggerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  // eslint-disable-next-line react-compiler/react-compiler
  const tooltipElement = cloneElement(tooltip, { triggerRef });

  return (
    <TooltipTrigger isOpen={isOpen}>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
      </div>
      {tooltipElement}
    </TooltipTrigger>
  );
}
