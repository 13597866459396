import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";

const JobTabs = ({ selected, setTab, tabs }) => {
  return (
    <Tabs className="uiHelperJobJumbotronTabs">
      {tabs.map(tab => (
        <TabButton
          key={tab.value}
          className={tab.name}
          onClick={() => setTab(tab.value)}
          $active={selected === tab.value}
          tabIndex={-1}
          $activeColor={tab.activeColor}
          $borderColor={tab.borderColor}
          $hoverColor={tab.hoverColor}
        >
          <tab.icon size={tab.iconSize} />
          <TabName>{tab.name}</TabName>
        </TabButton>
      ))}
    </Tabs>
  );
};

JobTabs.propTypes = {
  selected: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      activeColor: PropTypes.string,
      borderColor: PropTypes.string,
      hoverColor: PropTypes.string,
      icon: reactNodesType,
      iconSize: PropTypes.number.isRequired
    })
  ).isRequired
};

export default JobTabs;

const Tabs = styled.div.attrs({ className: "JobTabs" })`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  flex: 1;
`;

const TabButton = styled.button`
  --active-color: ${props => props.$activeColor};
  --hover-color: ${props => props.$hoverColor};
  --border-color: ${props => props.$borderColor};
  flex: 1;
  height: 100%;
  max-width: 180px;
  ${props => props.$active && "border-bottom: 2px solid var(--active-color);"}
  ${props =>
    props.$active &&
    "background-color: var(--active-color);"}
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  box-sizing: content-box;
  border-bottom: 2px solid
    ${props => (props.$active ? "var(--border-color)" : "var(--color-gray)")};
  color: ${props => (props.$active ? "black" : "var(--color-gray)")};
  transition: var(--transition-mui);

  &:hover {
    ${props =>
      !props.$active &&
      `
      color: black;
      border-bottom-color: var(--border-color);
    `}
  }

  svg {
    margin-right: 5px;
  }
`;

const TabName = styled.span`
  transition: var(--transition-mui-color);
`;
