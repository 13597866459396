import { useRef, useState } from "react";
import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MdContentCopy,
  MdDelete,
  MdMoreVert,
  MenuItem,
  PopoverMenu,
} from "st-shared/components";

import { reportingStore } from "../../../state/stores/reportingStore";
import { menuListCss } from "../../styles.css";
import { actionMenuCss } from "./DataSetActionMenu.css";

type Props = {
  dataSetId: string;
};

export function DataSetActionMenu({ dataSetId }: Props) {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  function openThreeDotMenu() {
    setMenuAnchorEl(menuRef.current);
  }

  function closeThreeDotMenu() {
    setMenuAnchorEl(null);
  }

  function onDuplicate() {
    closeThreeDotMenu();
    reportingStore().savedSegment.actions.duplicateDataset(dataSetId);
  }

  function onDelete() {
    closeThreeDotMenu();
    reportingStore().savedSegment.actions.deleteDataset(dataSetId);
  }

  return (
    <>
      <IconButton
        ref={menuRef}
        iconProps={{ icon: MdMoreVert }}
        onClick={openThreeDotMenu}
      />
      <PopoverMenu
        anchorEl={menuAnchorEl}
        onClose={closeThreeDotMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        paperRootClassName={actionMenuCss}
      >
        <MenuItem className={menuListCss.menuItem} onClick={onDuplicate}>
          <ListItemIcon className={menuListCss.listItemIcon}>
            <Icon
              className={menuListCss.listItemIcon_icon}
              icon={MdContentCopy}
              size={16}
            />
          </ListItemIcon>
          <ListItemText className={menuListCss.listItemText}>
            Duplicate
          </ListItemText>
        </MenuItem>
        <MenuItem className={menuListCss.menuItem} onClick={onDelete}>
          <ListItemIcon className={menuListCss.listItemIcon}>
            <Icon
              className={menuListCss.listItemIcon_icon}
              icon={MdDelete}
              size={18}
            />
          </ListItemIcon>
          <ListItemText className={menuListCss.listItemText}>
            Delete
          </ListItemText>
        </MenuItem>
      </PopoverMenu>
    </>
  );
}
