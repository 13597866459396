import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";

import { JOB_COST_VS_SELL_MODE } from "../../../lib/constants/jobDetails";
import {
  getJobCurrencyTotalLoggedTimeCostExTax,
  getJobCurrencyTotalLoggedTimeExTax,
} from "../../../lib/entities/jobEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobDetailsCostVsSellMode } from "./ui/costVsSellMode";

export const selectTotalLoggedTimeByCostVsSellMode = createCachedSelector(
  [
    selectJob,
    selectJobDetailsCostVsSellMode,
    selectCustomerCurrency,
    selectJobCurrency,
  ],
  (job, costVsSellMode, customerCurrency, jobCurrency) => {
    if (costVsSellMode === JOB_COST_VS_SELL_MODE.COST) {
      return formatForeignCurrency(customerCurrency, {
        value: getJobCurrencyTotalLoggedTimeCostExTax(job),
        currency: jobCurrency,
      });
    }

    return getJobCurrencyTotalLoggedTimeExTax(job);
  }
)(selectPropsJobId);

export const useTotalLoggedTimeByCostVsSellMode = (jobId) =>
  useSelector((state) =>
    selectTotalLoggedTimeByCostVsSellMode(state, { jobId })
  );
