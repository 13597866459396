// deprecated

import type { TLabel } from "@streamtimefe/entities";

import type { ContactStatus } from "./ContactStatus";
import type { Entity } from "./Entity";

export type Type = Contact;
export interface Contact extends Entity {
  companyId: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  position: string | null;
  notes: string | null;
  contactStatus: ContactStatus;
  labels: TLabel[];
}

export function getFullName(enitity: Contact) {
  return `${enitity.firstName}${
    enitity.lastName ? ` ${enitity.lastName}` : ""
  }`;
}
