import styled from "styled-components";

const DetailTableLabel = styled.div`
  min-width: 90px;
  color: black;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 0 0;
  line-height: 1;
`;

export default DetailTableLabel;
