export const DateFormats = {
  Default: "YYYY-MM-DD",
  MonthYearIso: "YYYY-MM",
  DayDate: "ddd D",
  DayDateMonth: "ddd D MMM",
  OrdinalDateMonth: "Do MMM",
  DayOrdinalDateMonth: "ddd Do MMM",
  DateMonthYear: "D MMM YY",
  DateMonthFullYear: "D MMM YYYY",
  DateShortMonthYear: "DD MMM YY",
  DateShortMonth: "DD MMM",
  DateFullMonth: "DD MMMM",
  ShortDateFullMonth: "D MMMM",
  DateDay: "DD ddd",
  DateFullDay: "DD dddd",
  DateFullMonthFullYear: "DD MMMM YYYY",
  FullMonth: "MMMM",
  ShortMonthYear: "MMM YY",
  FullMonthYear: "MMMM YYYY",
  Year: "YYYY",
  DayOfWeekLetter: "dd",
  DateOfMonth: "DD",
  Invoice: "DD MMM, YY",
  InvoicePayment: "D MMM, YYYY",
  TimeDotDate: "H:mm • D MMM YY",
  TimeOnly: "h:mma",
} as const;
