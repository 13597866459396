import Fade from "@material-ui/core/Fade";
import MuiMenu from "@material-ui/core/Menu";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";

class Menu extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func
  };

  static defaultProps = {
    onClose: null
  };

  componentDidMount() {
    if (window.top && window !== window.top)
      window.top.document.addEventListener("click", this.onClickParentDocument);
  }

  componentWillUnmount() {
    if (window.top && window !== window.top)
      window.top.document.removeEventListener(
        "click",
        this.onClickParentDocument
      );
  }

  onClickParentDocument = e => {
    const { onClose, open } = this.props;
    if (open && onClose) {
      onClose();
      e.preventDefault();
      return false;
    }

    return true;
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <StyledMenu
        elevation={2}
        variant="menu"
        disableAutoFocusItem
        MenuListProps={{ disablePadding: true }}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          square: true
        }}
        BackdropProps={{
          className: "MenuBackdrop",
          invisible: true
        }}
        {...props}
      >
        {children}
      </StyledMenu>
    );
  }
}

export default Menu;

const StyledMenu = styled(MuiMenu)`
  .MuiList-root {
    background-color: var(--color-snow);
  }
`;
