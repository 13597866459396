import type { Dictionary } from "@streamtimefe/types";

import type { TEntityId } from "../../core";
import type { TItemRate } from "./ItemRate";

export type TMasterJobItemIdRateCardIdIndex = Dictionary<
  `${TEntityId}_${TEntityId}`,
  TEntityId
>;

export function createMasterJobItemIdRateCardIdIndex(itemRates: TItemRate[]) {
  const index: TMasterJobItemIdRateCardIdIndex = {};

  for (const itemRate of itemRates) {
    if (itemRate.masterJobItemId !== null) {
      index[`${itemRate.masterJobItemId}_${itemRate.rateCardId}`] = itemRate.id;
    }
  }

  return index;
}

export type TMasterLoggedExpenseIdRateCardIdIndex = Dictionary<
  `${TEntityId}_${TEntityId}`,
  TEntityId
>;

export function createMasterLoggedExpenseIdRateCardIdIndex(
  itemRates: TItemRate[]
) {
  const index: TMasterLoggedExpenseIdRateCardIdIndex = {};

  for (const itemRate of itemRates) {
    if (itemRate.masterLoggedExpenseId !== null) {
      index[`${itemRate.masterLoggedExpenseId}_${itemRate.rateCardId}`] =
        itemRate.id;
    }
  }

  return index;
}
