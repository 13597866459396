import type { TUserPreference } from "@streamtimefe/entities";
import { Entity } from "@streamtimefe/entities";

import { API } from "../api";
import { WebApiRoot } from ".";

export const UserPreferencesRoot = WebApiRoot + "/preferences";

export function getAll() {
  return API.get<TUserPreference>(UserPreferencesRoot);
}

export function save(userPreference: TUserPreference) {
  return API.put<TUserPreference>(
    UserPreferencesRoot,
    Entity.apiSafeTransform(userPreference)
  );
}

export function get(userPreferenceId: TUserPreference["id"]) {
  return API.get<TUserPreference>(`${UserPreferencesRoot}/${userPreferenceId}`);
}
