import { pick } from "lodash-es";
import * as PropTypes from "prop-types";
import { entityIdType } from "../types/entityTypes";
import { addressEntityType } from "./addressEntity";

export const purchaseOrderShape = {
  id: entityIdType.isRequired,
  address: addressEntityType,
  name: PropTypes.string,
  number: PropTypes.string,
  languageOptions: PropTypes.string,
  requiredDate: PropTypes.string
};

export const purchaseOrderEntityType = PropTypes.shape(purchaseOrderShape);

export const getDisplayOptions = purchaseOrder =>
  pick(purchaseOrder, [
    "displayBranchExternalName",
    "displayCurrencySymbols",
    "displayHeadingDescriptions",
    "displayHeadingSubtotals",
    "displayHeadings",
    "displayIntro",
    "displayLineItemDescriptions",
    "displayLineItems",
    "displayLineItemsInclusiveOfTax",
    "displayJobNumber",
    "displayQuantities",
    "displayReference",
    "displaySubtotals",
    "displayTaxNameOnLineItems",
    "displayUnitRates",
    "displayTerms"
  ]);

const LANGUAGE_OPTION_DEFAULTS = {
  purchaseOrder: "Purchase Order",
  reference: "Reference",
  date: "Date",
  requiredDate: "Required",
  total: "Total",
  subtotal: "Subtotal",
  headerFrom: "Your purchase order from",
  from: "from",
  hasSent: "has sent you a purchase order.",
  view: "Use the link below to check it out.",
  number: "Purchase Order Number"
};

export const getLanguageOptions = ({ languageOptions }) => {
  const options = JSON.parse(languageOptions);
  return Object.assign(
    {},
    LANGUAGE_OPTION_DEFAULTS,
    pick(options, [
      "purchaseOrder",
      "jobNumber",
      "reference",
      "date",
      "requiredDate",
      "total",
      "subtotal",
      "headerFrom",
      "from",
      "hasSent",
      "view",
      "number",
      "lessPaid",
      "amountDue"
    ])
  );
};

export const getPurchaseOrderRequiredDate = ({ requiredDate }) => requiredDate;
