import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { entityIdType } from "../../../../lib/types/entityTypes";
import Checkbox from "../../../elements/Checkbox";
import DocumentLineItemEditConsumer from "../CommercialDocumentContext/Consumers/DocumentLineItemEditConsumer";

class DocumentLineLeftMargin extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isMergeParent: PropTypes.bool.isRequired,
    doToggleMergeDocumentLineItem: PropTypes.func.isRequired
  };

  toggle = e => {
    const { id, doToggleMergeDocumentLineItem } = this.props;

    doToggleMergeDocumentLineItem(id, e.target.checked);
  };

  render() {
    const { isChecked, isMergeParent } = this.props;

    return (
      <Wrapper>
        <Checkbox
          className={isMergeParent ? "primary" : ""}
          onChange={this.toggle}
          checked={isChecked}
        />
      </Wrapper>
    );
  }
}

export default DocumentLineItemEditConsumer(DocumentLineLeftMargin);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  height: 27px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;
