import { call, put, select } from "redux-saga/effects";
import { shiftJobDurationAPI } from "../../../lib/API/scheduleAPI";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_JOB_BAR_DRAG_END,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR,
  SCHEDULE_JOB_SHIFT_DURATION_SAVED,
  SCHEDULE_JOB_SHIFT_DURATION_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectScheduleJobUi } from "../../selectors/scheduleSelectors";

function* shiftJobDuration(action) {
  const { jobId, daysMoved, hasChanged } = action.payload;

  try {
    if (!hasChanged) return;

    yield put(
      createAction(SCHEDULE_JOB_SHIFT_DURATION_SAVING, {
        ...action.payload,
        jobId
      })
    );

    const { data } = yield call(shiftJobDurationAPI, jobId, daysMoved);

    const { isDragging } = yield select(selectScheduleJobUi, {
      jobId
    });

    if (isDragging) return;

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(SCHEDULE_JOB_SHIFT_DURATION_SAVED, {
        ...action.payload,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(SCHEDULE_JOB_SHIFT_DURATION_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchShiftJobDuration() {
  yield takeLatestBy(
    [SCHEDULE_JOB_BAR_DRAG_END],
    shiftJobDuration,
    action => action.payload.jobId
  );
}
