import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_INVOICE_PAYMENTS } from "../../../lib/constants";
import { selectPropsInvoicePaymentId } from "../index";

export const selectStateInvoicePayments = state =>
  state.entities[ENTITY_NAME_INVOICE_PAYMENTS];

export const selectInvoicePayment = createCachedSelector(
  [selectStateInvoicePayments, selectPropsInvoicePaymentId],
  (invoicePayments, invoicePaymentId) =>
    get(invoicePayments, `byId.${invoicePaymentId}`)
)(selectPropsInvoicePaymentId);

export const useInvoicePayment = invoicePaymentId =>
  useSelector(state => selectInvoicePayment(state, { invoicePaymentId }));
