import { unique } from "remeda";

import { Entity } from "../../core";
import type { TUserPreference } from "./UserPreference";

export function create(
  key: string,
  value: string | null,
  channel: string | null = null
) {
  return Entity.create<TUserPreference>({
    key,
    value,
    channel,
  });
}

const listSeperator = ",";

export function splitListValue(
  value: string,
  asUnique = true,
  asNumber = true
) {
  if (!value || value.length === 0) return [];
  let list: string[] | number[] = value.split(listSeperator);
  if (asUnique) return unique(list);
  if (asNumber) list = list.map(Number);
  return list;
}

export function joinListValue(
  list: string[],
  asUnique = true,
  asNumber = true
) {
  let newValues: string[] | number[] = list;

  if (asUnique) newValues = unique(newValues);
  if (asNumber) newValues = newValues.map(Number);

  return newValues.join(listSeperator);
}
