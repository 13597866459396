import MuiInputBase from "@material-ui/core/InputBase";
import styled from "styled-components";

export default styled(MuiInputBase)`
  height: 30px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: var(--color-gray-light);
  &::placeholder {
    color: var(--color-gray-dark);
  }
  &.Mui-disabled {
    background-color: var(--color-snow);
    color: var(--color-gray);
  }
`;
