import { get, reduce } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { EMPTY_ARRAY, ENTITY_NAME_JOB_ITEMS } from "../../lib/constants";
import { isAfter, isBefore } from "../../lib/dates";
import {
  getAsJobItemOption,
  getJobIdPhaseIdPath,
  getJobPhaseId,
  getScheduleBarEndDate,
  getScheduleBarStartDate,
  getTimelineEndDate,
  getTimelineStartDate,
} from "../../lib/entities/jobItemEntity";
import { selectPropsId, selectPropsJobId } from "./index";
import { selectJobPhaseById } from "./jobPhaseSelectors";

export const selectJobItems = (state) => state.entities[ENTITY_NAME_JOB_ITEMS];

export const selectFocusLossReason = (state) => {
  return state.ui.jobDetails.focusLossReason;
};

export const selectJobItemById = createSelector(
  selectJobItems,
  selectPropsId,
  (jobItems, jobItemId) => get(jobItems, `byId[${jobItemId}]`)
);

export const selectJobItemIdsByJobId = createSelector(
  selectJobItems,
  selectPropsJobId,
  (jobItems, jobId) => get(jobItems, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectJobItemIdsByJobPhaseId = createSelector(
  selectJobItems,
  selectPropsId,
  (jobItems, jobPhaseId) =>
    get(jobItems.idsByJobPhaseId, getJobPhaseId({ jobPhaseId }), EMPTY_ARRAY)
);

export const selectJobItemIdsByJobIdJobPhaseId = createSelector(
  selectJobItems,
  selectPropsJobId,
  selectPropsId,
  (jobItems, jobId, jobPhaseId) =>
    get(
      jobItems.idsByJobIdJobPhaseId,
      getJobIdPhaseIdPath({ jobId, jobPhaseId }),
      EMPTY_ARRAY
    )
);

export const selectJobPhaseIdsByJobId = createSelector(
  selectJobItems,
  selectPropsJobId,
  (jobItems, jobId) => get(jobItems, `jobPhaseIdsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectJobPhaseOrderByJobId = createSelector(
  selectJobItems,
  selectPropsJobId,
  (jobItems, jobId) => get(jobItems, `phaseOrderByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectActiveJobPhaseIdsByJobId = (state, props) =>
  selectJobPhaseOrderByJobId(state, props).filter((id) =>
    id > 0 ? Boolean(selectJobPhaseById(state, { id })) : true
  );

export const useActiveJobPhaseIdsByJobId = (jobId) =>
  useSelector((state) => selectActiveJobPhaseIdsByJobId(state, { jobId }));

export const selectJobItemOrderByJobIdPhaseId = createSelector(
  selectJobItems,
  selectPropsJobId,
  selectPropsId,
  (jobItems, jobId, jobPhaseId) =>
    get(
      jobItems.jobItemOrderByJobIdPhaseId,
      `["${jobId}.${jobPhaseId}"]`,
      EMPTY_ARRAY
    )
);

export const selectOrderedJobItemsByJobIdPhaseId = createSelector(
  selectJobItems,
  selectJobItemOrderByJobIdPhaseId,
  (jobItems, jobItemIds) =>
    jobItemIds.map((jobItemId) => get(jobItems.byId, jobItemId))
);

export const selectPhaseTimelineStartDateByJobPhaseId = (state, props) =>
  reduce(
    selectJobItemIdsByJobPhaseId(state, props),
    (earliestDate, id) => {
      const jobItem = selectJobItemById(state, { id });
      const startDate = getTimelineStartDate(jobItem);

      if (!earliestDate) return startDate;
      if (!startDate) return earliestDate;

      return isAfter(earliestDate, startDate) ? startDate : earliestDate;
    },
    null
  );

export const selectPhaseTimelineEndDateByJobPhaseId = (state, props) =>
  reduce(
    selectJobItemIdsByJobPhaseId(state, props),
    (latestDate, id) => {
      const jobItem = selectJobItemById(state, { id });
      const endDate = getTimelineEndDate(jobItem);

      if (!latestDate) return endDate;
      if (!endDate) return latestDate;

      return isBefore(latestDate, endDate) ? endDate : latestDate;
    },
    null
  );

export const selectPhaseScheduleStartDateByJobPhaseId = (state, props) =>
  reduce(
    selectJobItemIdsByJobPhaseId(state, props),
    (earliestDate, id) => {
      const jobItem = selectJobItemById(state, { id });
      const startDate = getScheduleBarStartDate(jobItem);

      if (!earliestDate) return startDate;
      if (!startDate) return earliestDate;

      return isBefore(startDate, earliestDate) ? startDate : earliestDate;
    },
    null
  );

export const selectPhaseScheduleEndDateByJobPhaseId = (state, props) =>
  reduce(
    selectJobItemIdsByJobPhaseId(state, props),
    (latestDate, id) => {
      const jobItem = selectJobItemById(state, { id });
      const endDate = getScheduleBarEndDate(jobItem);

      if (!latestDate) return endDate;
      if (!endDate) return latestDate;

      return isAfter(endDate, latestDate) ? endDate : latestDate;
    },
    null
  );

export const selectPhaseStatusByJobPhaseId = createSelector(
  selectJobItems,
  selectPropsId,
  (jobItems, jobPhaseId) => get(jobItems.phaseStatusByJobPhaseId, jobPhaseId)
);

export const selectJobItemDateRangeByJobId = (state, props) => {
  let earliestDate = null;
  let latestDate = null;

  selectJobItemIdsByJobId(state, props).forEach((id) => {
    const jobItem = selectJobItemById(state, { id });
    const startDate = getTimelineStartDate(jobItem);
    const endDate = getTimelineEndDate(jobItem);

    if (startDate && (!earliestDate || isBefore(startDate, earliestDate)))
      earliestDate = startDate;

    if (endDate && (!latestDate || isAfter(endDate, latestDate)))
      latestDate = endDate;
  });

  return {
    startDate: earliestDate,
    endDate: latestDate,
  };
};

export const selectOrderedJobPhaseJobItemIdsByJobId = (state, { jobId }) =>
  selectJobPhaseOrderByJobId(state, {
    jobId,
  }).map((jobPhaseId) => ({
    jobPhaseId,
    jobItemIds: selectJobItemOrderByJobIdPhaseId(state, {
      jobId,
      id: jobPhaseId,
    }),
  }));

export const selectOrderedJobPlanOptionsByJobIdPhaseId = createSelector(
  selectOrderedJobItemsByJobIdPhaseId,
  (_, props) => props.invoiceType,
  (jobItems, invoiceType) =>
    jobItems.map((item) => getAsJobItemOption(item, invoiceType))
);
