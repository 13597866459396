import isError from "lodash-es/isError";
import { call, put, select } from "redux-saga/effects";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";

import { updateJobItemUserStatusAPI } from "../../../lib/API/jobItemUserAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  JOB_ITEM_USER_STATUS_CHANGE,
  JOB_ITEM_USER_STATUS_CHANGE_CANCEL,
  JOB_ITEM_USER_STATUS_SAVED,
  JOB_ITEM_USER_STATUS_SAVING,
} from "../../../lib/constants";
import { createJobItemStatusType } from "../../../lib/entities/jobItemEntity";
import { feToWebConfirmCompleteJobItemUser } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";
import { selectJobItemUserIdsByJobItemId } from "../../selectors/jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";

function* changeJobItemUserStatus(action) {
  const { id, jobItemUser, jobItemUserStatus } = action.payload;
  let removeOverdueIncompleteTodos = false;

  const jobItem = yield select(selectJobItem, {
    jobItemId: jobItemUser.jobItemId,
  });

  const jobItemStatus = yield getNewJobItemStatus(
    jobItem.id,
    jobItemUser.id,
    jobItemUserStatus
  );

  yield put(
    createAction(ENTITIES_RECEIVED, {
      [ENTITY_NAME_JOB_ITEM_USERS]: [
        {
          ...jobItemUser,
          jobItemUserStatus,
        },
      ],
      [ENTITY_NAME_JOB_ITEMS]: [
        {
          ...jobItem,
          jobItemStatus,
        },
      ],
    })
  );

  try {
    const newJobItemUser = yield select(selectJobItemUserById, { id });

    switch (jobItemUserStatus.id) {
      case JobItemUserStatuses.Scheduled:
        removeOverdueIncompleteTodos = true;
        break;
      case JobItemUserStatuses.Complete:
        yield call(feToWebConfirmCompleteJobItemUser, jobItemUser);
        break;
      default:
    }

    if (newJobItemUser.id < 0) return;

    yield put(createAction(JOB_ITEM_USER_STATUS_SAVING, { id }));

    const { data } = yield call(
      updateJobItemUserStatusAPI,
      newJobItemUser,
      removeOverdueIncompleteTodos
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(JOB_ITEM_USER_STATUS_SAVED, { id }));
  } catch (error) {
    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEM_USERS]: [jobItemUser],
        [ENTITY_NAME_JOB_ITEMS]: [jobItem],
      })
    );

    yield put(
      createAction(JOB_ITEM_USER_STATUS_CHANGE_CANCEL, {
        ...action.payload,
        error,
      })
    );

    if (isError(error)) sagaError(error);
  }
}

function* getNewJobItemStatus(jobItemId, jobItemUserId, newJobItemUserStatus) {
  const jobItemUserIds = yield select(selectJobItemUserIdsByJobItemId, {
    jobItemId,
  });

  let status;
  let planningItems = 0;
  let scheduledItems = 0;
  let completeItems = 0;

  for (let i = 0; i < jobItemUserIds.length; i += 1) {
    const jobItemUser = yield select(selectJobItemUserById, {
      id: jobItemUserIds[i],
    });

    const jobItemUserStatusId =
      jobItemUser.id === jobItemUserId
        ? newJobItemUserStatus.id
        : jobItemUser.jobItemUserStatus.id;

    if (jobItemUserStatusId === JobItemUserStatuses.Scheduled) {
      scheduledItems += 1;
    } else if (jobItemUserStatusId === JobItemUserStatuses.Planning) {
      planningItems += 1;
    } else if (jobItemUserStatusId === JobItemUserStatuses.Complete) {
      completeItems += 1;
    }
  }

  if (scheduledItems > 0) {
    status = JOB_ITEM_STATUS_ID_SCHEDULED;
  } else if (planningItems > 0) {
    status = JOB_ITEM_STATUS_ID_PLANNING;
  } else if (completeItems > 0) {
    status = JOB_ITEM_STATUS_ID_COMPLETE;
  }

  return createJobItemStatusType(status);
}

export default function* watchJobItemUserStatusChange() {
  yield takeLatestBy(
    [JOB_ITEM_USER_STATUS_CHANGE],
    changeJobItemUserStatus,
    (action) => action.payload.id
  );
}
