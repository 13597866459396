import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";
import { selectJobItemCalculatedTotalPlannedTimeExTax } from "../jobItem/selectJobItemCalculatedTotalPlannedTimeExTax";

export const selectJobCalculatedTotalPlannedTimeExTax = (
  state,
  { jobId, includeNonBillable = true }
) => {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

  return reduce(
    jobItemIds,
    (total, jobItemId) => {
      const totalPlannedTimeExTax = selectJobItemCalculatedTotalPlannedTimeExTax(
        state,
        {
          jobItemId,
          includeNonBillable
        }
      );
      return numeral(total)
        .add(totalPlannedTimeExTax)
        .value();
    },
    0
  );
};

export const useJobCalculatedTotalPlannedTimeExTax = jobId =>
  useSelector(state =>
    selectJobCalculatedTotalPlannedTimeExTax(state, { jobId })
  );
