import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobPhaseId } from "../../index";
import { selectJobPhaseUi } from "./index";

export const selectIsSavingJobPhase = createCachedSelector(
  [selectJobPhaseUi],
  jobPhase => !!(jobPhase && jobPhase.isSaving)
)(selectPropsJobPhaseId);

export const useIsSavingJobPhase = jobPhaseId =>
  useSelector(state => selectIsSavingJobPhase(state, { jobPhaseId }));
