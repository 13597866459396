import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_USERS } from "../../../lib/constants";
import { selectPropsUserId, keySelectorNullable } from "../index";

export const selectStateUsers = state => state.entities[ENTITY_NAME_USERS];

export const selectUser = createCachedSelector(
  [selectStateUsers, selectPropsUserId],
  (users, userId) => get(users, `byId.${userId}`)
)(keySelectorNullable(selectPropsUserId));

export const useUser = userId =>
  useSelector(state => selectUser(state, { userId }));
