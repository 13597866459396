import { findIndex } from "lodash-es";
import type { MouseEvent, ReactNode } from "react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Highlight } from "st-shared/module";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  SCHEDULE_JOB_COLLAPSE,
  SCHEDULE_JOB_EXPAND,
  SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL,
} from "../../../lib/constants";
import { getAvailabilityDates } from "../../../lib/dates";
import { getPageUrl } from "../../../lib/entities/jobEntity";
import {
  sendGoToScheduleTodos,
  sendSetScheduleViewMode,
} from "../../../lib/WebAppAPI/schedule";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleJobUi } from "../../../redux/selectors/scheduleSelectors";
import { actionJobAssignRoleTime } from "../../../state/entities/job/actions";
import { useJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { isItemsByJobs, isPeopleByJobs } from "../../../state/ui/schedule/lib";
import {
  selectScheduleUiSearchQuery,
  useScheduleUiViewMode,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  ScheduleUi,
  ScheduleUiViewModes,
} from "../../../state/ui/schedule/types";
import { IconButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import AddItemIcon from "../../elements/Icons/AddItemIcon";
import RoleAddIcon from "../../elements/Icons/custom/RoleAddIcon";
import GroupByItemIcon from "../../elements/Icons/GroupByItemIcon";
import GroupByTeamMemberIcon from "../../elements/Icons/GroupByTeamMemberIcon";
import KebabIcon from "../../elements/Icons/KebabIcon";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import OpenInNewIcon from "../../elements/Icons/OpenInNewIcon";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import MenuItemLink from "../../modules/Menu/MenuItemLink";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import { AssignAllRoleMenu } from "../../modules/UserRoleMenus/AssignAllRoleMenu";
import { SelectRoleMenu } from "../../modules/UserRoleMenus/SelectRoleMenu";
import JobGroupLoadingPulser from "./JobGroupLoadingPulser";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import JobTotals from "./ScheduleGroupTotals/JobTotals";

interface Props {
  jobId: number;
  children?: ReactNode;
}

function ScheduleJobGroup({ jobId, children }: Props) {
  const {
    isExpanded = false,
    isFetching = false,
    isFetched = false,
  } = useSelector((state) =>
    selectScheduleJobUi(state, {
      jobId,
    })
  );
  const searchQuery = useSelector(selectScheduleUiSearchQuery);

  const { canEditJobs, canAccessJobs } = usePermissions();

  const jobListModel = useJobListModel(jobId)!;

  const viewMode = useScheduleUiViewMode();

  const [jobActionsAnchorEl, setJobActionsAnchorEl] = useState<Element | null>(
    null
  );

  const dispatch = useDispatch();

  function doExpand() {
    dispatch(createAction(SCHEDULE_JOB_EXPAND, { jobId }));
  }

  function doCollapse() {
    dispatch(createAction(SCHEDULE_JOB_COLLAPSE, { jobId }));
  }

  function toggleExpand() {
    if (isExpanded) doCollapse();
    else doExpand();
  }

  function openJobActionsMenu(e: MouseEvent<HTMLElement>) {
    setJobActionsAnchorEl(e.target.closest(".ScheduleJobGroupNameContainer"));
  }

  function closeJobActionsMenu() {
    setJobActionsAnchorEl(null);
  }

  function addItem() {
    closeJobActionsMenu();

    dispatch(createAction(SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL, { jobId }));

    if (viewMode !== ScheduleUiViewModes.ItemsByJobs) {
      sendSetScheduleViewMode(ScheduleUiViewModes.ItemsByJobs);
    }

    doExpand();
  }

  function goToTodos() {
    closeJobActionsMenu();

    sendSetScheduleViewMode(ScheduleUiViewModes.Todos);

    const userIds = jobListModel.users.map((user) => user.id);

    sendGoToScheduleTodos(jobId, userIds);
  }

  function groupByItemsViewMode() {
    closeJobActionsMenu();

    sendSetScheduleViewMode(ScheduleUiViewModes.ItemsByJobs);
  }

  function groupByTeamMembersViewMode() {
    closeJobActionsMenu();

    sendSetScheduleViewMode(ScheduleUiViewModes.PeopleByJobs);
  }
  const showLoader = isItemsByJobs(viewMode);
  const showTotals = isItemsByJobs(viewMode);

  interface Role {
    id: number;
    name: string;
    planned: number;
  }
  const roles: Role[] = jobListModel.roles.map((entity) => ({
    ...entity,
    planned: entity.totalPlannedMinutes,
  }));

  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    jobListModel.estimatedStartDate,
    jobListModel.estimatedEndDate
  );

  const roleRef = useRef(null);
  const [selectRoleMenuOpen, setSelectRoleMenuOpen] = useState(false);
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  function assignRoleTime() {
    closeJobActionsMenu();
    setSelectRoleMenuOpen(true);
  }

  function closeRoleMenu() {
    setSelectedRole(null);
    setSelectRoleMenuOpen(false);
  }

  function selectRole(roleId: number) {
    closeRoleMenu();
    const index = findIndex(roles, ["id", roleId]);
    if (index !== -1) {
      setSelectedRole(roles[index]);
      setAssignMenuOpen(true);
    }
  }

  function closeAssignMenu() {
    setSelectedRole(null);
    setAssignMenuOpen(false);
  }

  function assignUser(userId: number) {
    dispatch(actionJobAssignRoleTime(jobId, selectedRole!.id, userId));
    closeAssignMenu();
  }

  return (
    <Wrapper>
      <Spacer w={15} />
      <Div ref={roleRef}>
        <Container>
          <Expander
            onClick={toggleExpand}
            isExpanded={isExpanded}
            title={isExpanded ? "Collapse Job" : "Expand Job"}
            style={{ marginTop: 4 }}
          />
          <Underline className={isExpanded ? "expanded" : ""}>
            <NameContainer className="ScheduleJobGroupNameContainer">
              <Names onClick={toggleExpand}>
                <JobName title={jobListModel.jobName}>
                  <Highlight text={jobListModel.jobName} query={searchQuery} />
                </JobName>
                <CompanyName title={jobListModel.companyName}>
                  <Highlight
                    text={jobListModel.companyName}
                    query={searchQuery}
                  />
                </CompanyName>
              </Names>
              <Icons>
                {!jobListModel.isBillable && (
                  <Tooltip title="This is a non-billable job" placement="top">
                    <NonBillableIconWrapper>
                      <NonBillableIcon size={ICON_SIZE.MEDIUM} />
                    </NonBillableIconWrapper>
                  </Tooltip>
                )}
                <JobActions>
                  <IconButton onClick={openJobActionsMenu}>
                    <KebabIcon />
                  </IconButton>
                </JobActions>
              </Icons>
            </NameContainer>
            {showTotals && (
              <ScheduleGroupTotals>
                <JobTotals id={jobId} />
              </ScheduleGroupTotals>
            )}
          </Underline>
          <Menu
            open={Boolean(jobActionsAnchorEl)}
            anchorEl={jobActionsAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              horizontal: -2,
              vertical: "top",
            }}
            onClose={closeJobActionsMenu}
          >
            {isItemsByJobs(viewMode) && (
              <MenuItem onClick={groupByTeamMembersViewMode}>
                <ListItemIcon>
                  <GroupByTeamMemberIcon size={ICON_SIZE.X_LARGE} />
                </ListItemIcon>
                <ListItemText>Group by team member</ListItemText>
              </MenuItem>
            )}
            {isPeopleByJobs(viewMode) && (
              <MenuItem onClick={groupByItemsViewMode}>
                <ListItemIcon>
                  <GroupByItemIcon size={ICON_SIZE.X_LARGE} />
                </ListItemIcon>
                <ListItemText>Group by item</ListItemText>
              </MenuItem>
            )}
            {canEditJobs && (
              <MenuItem onClick={addItem}>
                <ListItemIcon>
                  <AddItemIcon size={ICON_SIZE.X_LARGE} />
                </ListItemIcon>
                <ListItemText>Add an item</ListItemText>
              </MenuItem>
            )}
            {jobListModel.roles.length > 0 && (
              <MenuItem onClick={assignRoleTime}>
                <ListItemIcon>
                  <RoleAddIcon size={ICON_SIZE.LARGE} />
                </ListItemIcon>
                <ListItemText>Assign Role Time</ListItemText>
              </MenuItem>
            )}
            <StyledMenuItem onClick={goToTodos}>
              <ListItemIcon>
                <OpenInNewIcon
                  size={ICON_SIZE.X_LARGE}
                  style={{ padding: 2 }}
                />
              </ListItemIcon>
              <ListItemText>Go To To Do&apos;s</ListItemText>
            </StyledMenuItem>
            {canAccessJobs && (
              <MenuItemLink href={getPageUrl(jobId)} text="Go To Job" />
            )}
          </Menu>
        </Container>
        {selectRoleMenuOpen && (
          <SelectRoleMenu
            anchorEl={roleRef.current}
            close={closeRoleMenu}
            roles={roles}
            selectRole={selectRole}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: -2,
            }}
          />
        )}
        {assignMenuOpen && selectedRole && (
          <AssignAllRoleMenu
            anchorEl={roleRef.current}
            assignUser={assignUser}
            close={closeAssignMenu}
            role={selectedRole}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: -2,
            }}
          />
        )}
        {isExpanded && showLoader && isFetching && <JobGroupLoadingPulser />}
        {isExpanded && (showLoader ? isFetched : true) && children}
      </Div>
    </Wrapper>
  );
}

export default ScheduleJobGroup;

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  user-select: none;
  flex-direction: row;
`;

const Div = styled.div`
  width: calc(100% - 15px);
`;

const Container = styled.div`
  height: ${ScheduleUi.Job.RowHeight}px;
  width: 100%;
  display: flex;
  cursor: pointer;
  &:hover ${Expander} {
    opacity: 1;
  }
`;

const Underline = styled(Flex)`
  width: calc(100% - 30px);
  &.expanded {
    border-bottom: var(--border-thick-bright);
  }
`;

const Icons = styled.div`
  display: flex;
  padding-top: 2px;
`;

const NonBillableIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;

  svg {
    fill: var(--color-gray-dark);
  }
`;

const JobActions = styled.div`
  display: flex;
  width: 0;
  opacity: 0;
  transition:
    opacity 0.2s,
    width 0.2s;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
`;

const NameContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  &:hover {
    ${JobActions} {
      width: 30px;
      opacity: 1;
    }
  }
`;

const Names = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 0;
  overflow: hidden;
`;

const JobName = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

const CompanyName = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: var(--color-ygrittesnow);
  &:hover {
    background-color: var(--color-ygrittesnow);
  }
`;
