import { capitalize } from "remeda";

export function asString(value: unknown) {
  return String(value || "").trim();
}

export function toLowerCaseString(value: unknown) {
  return asString(value).toLowerCase();
}

export function searchWordMatches(searchString: string, searchQuery: string) {
  return toLowerCaseString(searchString).includes(
    toLowerCaseString(searchQuery)
  );
}

export function encodeString(value?: string) {
  if (value) {
    return value.replace(
      /[\u00A0-\u9999<>&]/gim,
      (i: string) => `&#${i.charCodeAt(0)};`
    );
  }
  return "";
}

export function areOrIs(value: number) {
  return Math.round(value) == 1 ? "is" : "are";
}

export function capitalizeAll(value: string) {
  return value
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}
