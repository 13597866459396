import styled from "styled-components";

import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { useJobDetailsUiAreKeysCurrentFocus } from "../../../redux/selectors/jobDetails/ui/areKeysCurrentFocus";
import { FocusProgressContainer } from "../components/FocusProgressContainer";
import { useJobItemId } from "../context/JobItemIdContext";
import { useExtraComponentsIsHidden } from "../hooks/useExtraComponentsIsHidden";
import { hiddenElement } from "../styles";

const ExtraComponentsContainer = ({ children }) => {
  const jobItemId = useJobItemId();
  const focused = useJobDetailsUiAreKeysCurrentFocus(jobItemId, [
    FOCUS_KEYS.ITEM_NEW_SUB_ITEM,
    FOCUS_KEYS.ITEM_SUB_ITEM,
    FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION,
  ]);
  const extraComponentsIsHidden = useExtraComponentsIsHidden();

  return (
    <Container hidden={extraComponentsIsHidden && !focused}>
      <FocusProgressContainer>{children}</FocusProgressContainer>
    </Container>
  );
};

ExtraComponentsContainer.propTypes = {
  children: reactNodesType.isRequired,
};

export default ExtraComponentsContainer;

const Container = styled.div`
  position: relative;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  & > * {
    margin-bottom: 5px;
  }

  ${hiddenElement}
`;
