import numbro from "numbro";

import { NumberFormat, padNumber } from "../number";
import { datetime } from "./export";

export function convertMinutesToTimeHM(
  minutes: number,
  unit: "hour" | "minute" | null = null,
  padMinutes = false,
  padHours = true
) {
  if (!minutes) {
    if (padMinutes) return "0h00m";
    return "0h";
  }

  if (unit === "hour") {
    return `${numbro(Math.round(minutes / 60)).format(NumberFormat.Rounded)}h`;
  }

  if (unit === "minute") {
    return `${numbro(Math.round(minutes % 60)).format(NumberFormat.Rounded)}m`;
  }

  const isNegative = minutes < 0;

  minutes = Math.abs(minutes);

  const minutesRounded = Math.round(minutes);

  const hourValue =
    minutesRounded >= 60 || padHours
      ? `${numbro(Math.floor(minutesRounded / 60)).format(
          NumberFormat.Rounded
        )}h`
      : "";

  const minutesAbsolute = Math.abs(minutesRounded) % 60;

  const minutesValue =
    minutesAbsolute > 0 || padMinutes
      ? `${padNumber(minutesAbsolute, 2)}m`
      : "";

  return `${isNegative ? "-" : ""}${hourValue}${minutesValue}`;
}

export function convertFlexibleDurationToMinutes(
  duration: number | string,
  treatLargeNumbersAsHours = true
) {
  if (!duration || duration === ".") {
    return 0;
  }

  duration = String(duration).trim();

  // match value, unit, value, unit. ',' and 'u' are foreign notations
  const matched = duration.match(/^([\d,.]+)([humHUM:]?)(\d*)([mM]?)$/);

  if (!matched) {
    return 0;
  }
  // eslint-disable-next-line prefer-const
  let [, value1, unit1, value2] = matched;

  let hours: string;
  let minutes: string;

  // replace , with . to account for foreign notations
  value1 = value1?.replace(/,/, ".");

  switch (unit1?.toLowerCase()) {
    case "m": {
      hours = "0";
      minutes = value1;
      break;
    }
    case "h": {
      hours = value1;
      minutes = value2;
      break;
    }
    default: {
      if (treatLargeNumbersAsHours || Number(value1) < 10) {
        hours = value1;
        minutes = value2;
      } else {
        hours = "0";
        minutes = value1;
      }
    }
  }

  return Math.floor(datetime.hoursToMinutes(Number(hours)) + Number(minutes));
}

export function convertMinutesToHours(minutes: number | string) {
  const roundedMinutes = numbro(numbro(minutes).format("0")).value();
  if (roundedMinutes === 0) return "";
  return numbro(roundedMinutes)
    .divide(60)
    .format(NumberFormat.OptionalNoSeparater);
}

export function convertMinutesToHoursRaw(minutes: number | string) {
  const roundedMinutes = numbro(numbro(minutes).format("0")).value();
  return numbro(roundedMinutes)
    .divide(60)
    .format(NumberFormat.OptionalNoSeparater);
}
