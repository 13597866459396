import clsx from "clsx";
import type { ReactNode } from "react";

import { BackButton } from "./BackButton";
import {
  childWrapperCss,
  entityHeaderCss,
  entityHeaderTitleCss,
} from "./EntityPageHeader.css";

type Props = {
  title: ReactNode;
  children?: ReactNode;
  classNames?: { root?: string; title?: string };
  onBackClick: () => void;
};

export function EntityPageHeader({
  title,
  children,
  classNames,
  onBackClick,
}: Props) {
  return (
    <header className={clsx(entityHeaderCss, classNames?.root)}>
      <BackButton onClick={onBackClick} />
      <span className={clsx(entityHeaderTitleCss, classNames?.title)}>
        {title}
      </span>
      <div className={childWrapperCss}>{children}</div>
    </header>
  );
}
