import * as PropTypes from "prop-types";
import React from "react";
import {
  getDaysInMonth,
  getStartOfMonthDatesInRange
} from "../../../lib/dates";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const MonthsConsumer = ({ children }) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportStartDate, viewportEndDate, getOffsetXAtDate, dayWidth }) =>
      getStartOfMonthDatesInRange(viewportStartDate, viewportEndDate).map(
        date =>
          children({
            date,
            width: dayWidth * getDaysInMonth(date),
            left: getOffsetXAtDate(date)
          })
      )
    }
  </JobTimelineScrollContext.Consumer>
);

MonthsConsumer.propTypes = {
  children: PropTypes.func.isRequired
};

export default MonthsConsumer;
