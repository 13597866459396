import { createIcon } from "../../icon/Icon";

export const IconStatusCompletedFilled = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM16 9L17.4142 10.4142L10.9142 16.9142L7 13L8.41421 11.5858L10.9142 14.0858L16 9Z"
  />,
  "IconStatusCompletedFilled"
);
