import PropTypes from "prop-types";
import React from "react";

const Image = ({ contentState, entityKey }) => {
  const { alt, height, src, width } = contentState
    .getEntity(entityKey)
    .getData();

  return (
    <img
      alt={alt}
      src={src}
      height={height}
      width={width}
      style={{ maxWidth: "50%", maxHeight: "300px" }}
    />
  );
};

Image.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired
};

export default Image;
