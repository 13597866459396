//import "react-day-picker/dist/style.css";

import { useEffect, useState } from "react";
import {
  DayPicker as ReactDayPicker,
  DayPickerSingleProps,
} from "react-day-picker-8";

import { MdChevronLeft, MdChevronRight } from "..";
import {
  captionCss,
  captionLabelCss,
  cellCss,
  dayCss,
  dayDisabledCss,
  daySelectedCss,
  dayTodayCss,
  headCellCss,
  headCss,
  navButtonCss,
  navCss,
  navIconCss,
  rootCss,
  rowCss,
} from "./DayPicker.css";

export interface DayPickerProps
  extends Omit<DayPickerSingleProps, "mode" | "ISOWeek"> {
  listenToMonthChange?: boolean;
}

export function DayPicker({
  selected,
  month,
  onMonthChange,
  listenToMonthChange = true,
  ...props
}: DayPickerProps) {
  const [monthLocal, setMonthLocal] = useState<Date | undefined>(
    month || selected
  );

  useEffect(() => {
    if (listenToMonthChange && !onMonthChange && selected) {
      setMonthLocal(selected);
    }
  }, [selected, listenToMonthChange]);

  function setMonth(m: Date) {
    onMonthChange?.(m);
    setMonthLocal(m);
  }

  return (
    <ReactDayPicker
      {...props}
      mode="single"
      ISOWeek={true}
      selected={selected}
      month={month || monthLocal}
      onMonthChange={setMonth}
      classNames={{
        root: rootCss,
        caption: captionCss,
        caption_label: captionLabelCss,
        nav: navCss,
        nav_button: navButtonCss,
        nav_icon: navIconCss,
        head: headCss,
        head_cell: headCellCss,
        cell: cellCss,
        day: dayCss,
        row: rowCss,
        day_selected: daySelectedCss,
        day_disabled: dayDisabledCss,
        day_today: dayTodayCss,
      }}
      components={{ IconLeft: MdChevronLeft, IconRight: MdChevronRight }}
    />
  );
}
