import { select, put } from "redux-saga/effects";
import {
  JOB_ITEM_SET_ALL_EXPANDED,
  JOB_ITEM_SET_EXPANDED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectFullOrderedJobPhaseJobItemIdsByJobId } from "../../selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";
import { selectIsExpandedJobPhase } from "../../selectors/jobPhase/ui/isExpanded";

function* setAllExpanded(action) {
  try {
    const { jobId, isExpanded } = action.payload;

    const ids = [];

    const orderedJobPhaseJobItemIds = yield select(
      selectFullOrderedJobPhaseJobItemIdsByJobId,
      { jobId }
    );

    for (let i = 0; i < orderedJobPhaseJobItemIds.length; i += 1) {
      const { jobPhaseId, jobItemIds } = orderedJobPhaseJobItemIds[i];
      const isPhaseExpanded =
        jobPhaseId === 0
          ? true
          : yield select(selectIsExpandedJobPhase, { jobPhaseId });

      if (isPhaseExpanded) ids.push(...jobItemIds);
    }

    yield put(
      createAction(JOB_ITEM_SET_EXPANDED, {
        jobItemIds: ids,
        isExpanded
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSetAllExpanded() {
  yield takeLatestBy(
    [JOB_ITEM_SET_ALL_EXPANDED],
    setAllExpanded,
    action => action.payload.jobId
  );
}
