import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import { setSum } from "../../../../lib/objects";
import {
  calculateTotalIncTax,
  getIsHeading,
  getTaxRate,
  getTotalAmountExTax,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    const getPurchaseOrderLineItem = (id) => nextState.byId[id];

    touchedPurchaseOrderIds.forEach((purchaseOrderId) => {
      const sortedIds = get(
        nextState.sortedIdsByPurchaseOrderId,
        purchaseOrderId
      );

      if (!sortedIds) {
        unset(draft, `subTotalsIncTaxByPurchaseOrderId.${purchaseOrderId}`);
        return;
      }

      const sortedLineItems = sortedIds.map(getPurchaseOrderLineItem);
      const subTotals = {};
      let lastHeadingId = null;

      sortedLineItems.forEach((purchaseOrderLineItem) => {
        if (getIsHeading(purchaseOrderLineItem)) {
          lastHeadingId = purchaseOrderLineItem.id;
          return;
        }

        if (!lastHeadingId) return;

        const totalExTax = asDecimal(
          getTotalAmountExTax(purchaseOrderLineItem)
        );
        const taxRate = getTaxRate(purchaseOrderLineItem);
        const lineTotal = calculateTotalIncTax(totalExTax, taxRate);

        setSum(subTotals, lastHeadingId, lineTotal);
      });

      setWith(
        draft,
        `subTotalsIncTaxByPurchaseOrderId.${purchaseOrderId}`,
        subTotals,
        Object
      );
    });
  });
