import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import { JOB_SAVE, JOB_SET_JOB_LEAD } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";

function* setJobLead(action) {
  try {
    const { jobId, jobLeadUserId } = action.payload;

    const job = yield select(selectJob, { jobId });

    const newJob = produce(job, draft => {
      draft.jobLeadUserId = jobLeadUserId;
    });

    yield put(
      createAction(JOB_SAVE, {
        jobId,
        job: newJob,
        previousJob: job
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetJobLead() {
  yield takeLatestBy(
    [JOB_SET_JOB_LEAD],
    setJobLead,
    action => action.payload.jobId
  );
}
