import { ThemeProvider } from "@material-ui/core";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { getStartOfWeek, toDate } from "../../../lib/dates";
import pickersTheme from "../../../lib/themes/pickers";
import Button from "../../elements/Button";
import Flex from "../../elements/Flex";
import Popover from "../Popover";

class PopoverDatePicker extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    popoverProps: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }).isRequired,
    pickerProps: PropTypes.shape({}),
    hasClear: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    hasClear: false,
    pickerProps: {},
  };

  onChange = (moment) => {
    const { onChange } = this.props;
    onChange(toDate(moment));
  };

  onClear = () => {
    const { onChange } = this.props;
    onChange(null);
  };

  goToThisWeek = () => {
    this.onChange(getStartOfWeek());
  };

  render() {
    const { popoverProps, pickerProps, value, hasClear } = this.props;
    return (
      <Popover {...popoverProps}>
        <ThemeProvider theme={pickersTheme}>
          <MuiDatePicker
            disableToolbar
            variant="static"
            onChange={this.onChange}
            value={value}
            leftArrowButtonProps={{ size: "small" }}
            rightArrowButtonProps={{ size: "small" }}
            {...pickerProps}
          />
        </ThemeProvider>
        <Actions>
          {hasClear ? (
            <Action onClick={this.onClear}>Clear</Action>
          ) : (
            <Action onClick={this.goToThisWeek}>Show this Week</Action>
          )}
        </Actions>
      </Popover>
    );
  }
}

export default PopoverDatePicker;

const Actions = styled(Flex)`
  justify-content: center;
  padding: 10px;
`;

const Action = styled(Button)`
  width: 100%;
  &:hover {
    background-color: var(--color-teal-bright);
  }
`;
