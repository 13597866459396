import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  JOB_PHASE_DELETE,
  JOB_PHASE_DUPLICATE,
  JOB_PHASE_SET_IS_BILLABLE,
} from "../../../lib/constants";
import { getAvailabilityDates } from "../../../lib/dates";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPhase } from "../../../redux/selectors/jobPhase";
import { useJobPhaseEstimateStartEndDates } from "../../../redux/selectors/jobPhase/selectJobPhaseEstimatedStartEndDates";
import { useJobPhaseJobItemBillableCount } from "../../../redux/selectors/jobPhase/selectJobPhaseJobItemBillableCount";
import { useJobPhaseJobItemCount } from "../../../redux/selectors/jobPhase/selectJobPhaseJobItemCount";
import { useIsSavingJobPhase } from "../../../redux/selectors/jobPhase/ui/isSaving";
import { actionJobPhaseAddUsersAndRoles } from "../../../state/entities/jobPhase/actions";
import { IconButton } from "../../elements/Button";
import BillableIcon from "../../elements/Icons/BillableIcon";
import DeleteIcon from "../../elements/Icons/DeleteIcon";
import DuplicateIcon from "../../elements/Icons/DuplicateIcon";
import KebabIcon from "../../elements/Icons/KebabIcon";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import PersonIcon from "../../elements/Icons/PersonIcon";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { JobItemMultiUserSelector } from "../../modules/UserRoleMenus/JobItemMultiUserSelector";
import { useJobId } from "../context/JobIdContext";
import { useJobPhaseId } from "../context/JobPhaseIdContext";

const PhaseCardActions = () => {
  const jobId = useJobId();
  const jobPhaseId = useJobPhaseId();
  const isJobEditable = useIsJobEditable(jobId);
  const jobPhase = useJobPhase(jobPhaseId);
  const isSaving = useIsSavingJobPhase(jobPhaseId);
  const itemCount = useJobPhaseJobItemCount(jobPhaseId);
  const [billableCount, nonBillableCount] =
    useJobPhaseJobItemBillableCount(jobPhaseId);

  const dispatch = useDispatch();

  const menuRef = useRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openActionsMenu = () => {
    setMenuAnchorEl(menuRef.current);
  };

  const closeActionsMenu = () => {
    setMenuAnchorEl(null);
  };

  const [teamMemberMenuOpen, setTeamMemberMenuOpen] = useState(false);

  const openTeamMemberMenu = () => {
    setTeamMemberMenuOpen(true);
  };

  const closeTeamMemberMenu = () => {
    setTeamMemberMenuOpen(false);
  };

  const addTeamMember = () => {
    closeActionsMenu();
    if (!isJobEditable) return;
    openTeamMemberMenu();
  };

  const markAllItemsAsBillable = () => {
    closeActionsMenu();
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_PHASE_SET_IS_BILLABLE, { jobPhaseId, isBillable: true })
    );
  };

  const markAllItemsAsNonBillable = () => {
    closeActionsMenu();
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_PHASE_SET_IS_BILLABLE, { jobPhaseId, isBillable: false })
    );
  };

  const deletePhase = () => {
    closeActionsMenu();
    if (!isJobEditable) return;
    dispatch(createAction(JOB_PHASE_DELETE, { jobPhase }));
  };

  const duplicatePhase = () => {
    closeActionsMenu();
    if (!isJobEditable) return;
    dispatch(createAction(JOB_PHASE_DUPLICATE, { jobPhaseId }));
  };

  const [estimatedStartDate, estimatedEndDate] =
    useJobPhaseEstimateStartEndDates(jobPhaseId);

  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    estimatedStartDate,
    estimatedEndDate
  );

  function addUsersAndRoles(userIds = [], roleIds = []) {
    dispatch(actionJobPhaseAddUsersAndRoles(jobPhaseId, userIds, roleIds));
  }

  return (
    <Container>
      <ActionsMenuButton ref={menuRef}>
        <StyledIconButton
          tabIndex={-1}
          onClick={openActionsMenu}
          disabled={isSaving}
        >
          <KebabIcon />
        </StyledIconButton>
      </ActionsMenuButton>
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeActionsMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {itemCount > 0 && (
          <StyledMenuItem onClick={addTeamMember}>
            <StyledListItemIcon>
              <PersonIcon size={ICON_SIZE.X_LARGE} />
            </StyledListItemIcon>
            <ListItemText>Add team member</ListItemText>
          </StyledMenuItem>
        )}
        {itemCount > 0 && billableCount < itemCount && (
          <StyledMenuItem onClick={markAllItemsAsBillable}>
            <StyledListItemIcon>
              <BillableIcon size={ICON_SIZE.X_LARGE} />
            </StyledListItemIcon>
            <ListItemText>Mark all items billable</ListItemText>
          </StyledMenuItem>
        )}
        {itemCount > 0 && nonBillableCount < itemCount && (
          <StyledMenuItem onClick={markAllItemsAsNonBillable}>
            <StyledListItemIcon>
              <NonBillableIcon size={ICON_SIZE.X_LARGE} />
            </StyledListItemIcon>
            <ListItemText>Mark all items non-billable</ListItemText>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={duplicatePhase}>
          <StyledListItemIcon>
            <DuplicateIcon size={ICON_SIZE.X_LARGE} />
          </StyledListItemIcon>
          <ListItemText>Duplicate phase</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={deletePhase}>
          <StyledListItemIcon>
            <DeleteIcon size={ICON_SIZE.X_LARGE} />
          </StyledListItemIcon>
          <ListItemText>Delete phase</ListItemText>
        </StyledMenuItem>
      </Menu>
      {menuRef.current && teamMemberMenuOpen && (
        <JobItemMultiUserSelector
          anchorEl={menuRef.current}
          entityId={jobPhaseId}
          type="jobPhase"
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          addUsersAndRoles={addUsersAndRoles}
          close={closeTeamMemberMenu}
          canCreateNewUser={false}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          itemCount={itemCount}
        />
      )}
    </Container>
  );
};

export default PhaseCardActions;

const Container = styled.div`
  position: absolute;
  right: -32px;
`;

const ActionsMenuButton = styled.div`
  padding-left: 2px;
`;

const StyledIconButton = styled(IconButton).attrs({
  className: "ActionsButton",
})`
  height: 46px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 12px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`;
