import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";
import { JobGroupStatus } from ".";

export const JobGroupStatusEnum = {
  Open: 2,
  Closed: 3,
  Deleted: 4,
} as const;

export type TJobGroupStatusEnum = ConstEnum<typeof JobGroupStatusEnum>;

export type TJobGroupStatus = TEnumOption<TJobGroupStatusEnum>;

export const JobGroupStatusItems: { id: TJobGroupStatusEnum; name: string }[] =
  [
    {
      id: JobGroupStatusEnum.Open,
      name: JobGroupStatus.getNameAction(JobGroupStatusEnum.Open),
    },
    {
      id: JobGroupStatusEnum.Closed,
      name: JobGroupStatus.getNameAction(JobGroupStatusEnum.Closed),
    },
  ];
