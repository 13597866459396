import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const FilterIcon = props => (
  <SvgIcon {...props} viewBox="0 0 16 12">
    <path d="M6.222 11.3332H9.778V9.55517H6.222V11.3332ZM0 0.666504V2.4445H16V0.666504H0ZM2.66667 6.8885H13.3333V5.11117H2.66667V6.8885Z" />
  </SvgIcon>
);

export default StyledIcon(FilterIcon);
