import {
  JOB_MILESTONE_SAVING,
  JOB_MILESTONE_SAVED,
  JOB_MILESTONE_SAVE_ERROR,
  JOB_MILESTONE_DELETING,
  JOB_MILESTONE_DELETED,
  JOB_MILESTONE_DELETE_ERROR
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { isSaving: false }
});

const onSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { isSaving: false, error }
});

const onDeleteRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { isDeleting: true, error: null }
});

const onDeleteSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { isDeleting: false }
});

const onDeleteError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { isDeleting: false, error }
});

export default createReducer(
  {},
  {
    [JOB_MILESTONE_SAVING]: onSaveRequest,
    [JOB_MILESTONE_SAVED]: onSaveSuccess,
    [JOB_MILESTONE_SAVE_ERROR]: onSaveError,
    [JOB_MILESTONE_DELETING]: onDeleteRequest,
    [JOB_MILESTONE_DELETED]: onDeleteSuccess,
    [JOB_MILESTONE_DELETE_ERROR]: onDeleteError
  }
);
