import { SCHEDULE_BLOCK_DRAG_END_SAGA } from "../../../../../lib/constants";
import reduceChangedEntities from "../reduceChangedEntities";
import dragBlockReducer from "./dragBlockReducer";

const undoDragBlockReducer = (state, action) => {
  const { hasChanged, origin, duplicate, newBlockKey } = action.payload;

  if (duplicate) {
    const prevScheduleLoggedTimeIds = state.idsByBlockKey[newBlockKey];
    const changedEntities = prevScheduleLoggedTimeIds.map(id => ({
      prevEntity: state.byId[id]
    }));

    return reduceChangedEntities(state, changedEntities);
  }

  if (!hasChanged) return state;

  const reverseAction = {
    type: SCHEDULE_BLOCK_DRAG_END_SAGA,
    payload: {
      ...action.payload,
      date: origin.date,
      userId: origin.userId,
      jobId: origin.jobId,
      jobItemId: origin.jobItemId,
      jobItemUserId: origin.jobItemUserId,
      scheduleFilterMode: origin.scheduleFilterMode
    }
  };

  return dragBlockReducer(state, reverseAction);
};

export default undoDragBlockReducer;
