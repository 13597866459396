import { isEmpty, isEqual, setWith, unset } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_USERS,
} from "../../../lib/constants";
import {
  getAsOption,
  getIsSystemUser,
  getWorkingHours,
  isActive,
} from "../../../lib/entities/userEntity";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../helpers/createEntityIndexedArrayReducer";
import createEntityOptionsReducer from "../../helpers/createEntityOptionsReducer";
import createReducer from "../../helpers/createReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";

const statusIdReducer = createEntityIndexedArrayReducer(
  (entity) => `[${entity.userStatus.id}]`
);

const activeOptionsReducer = createEntityOptionsReducer(
  (entity) =>
    entity &&
    !getIsSystemUser(entity) &&
    isActive(entity) &&
    getAsOption(entity)
);

const userWorkingHoursReducer = (state, changedEntities) => {
  let hasChanged = false;

  const nextState = {
    ...state,
  };

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    const prevPath = prevEntity && `[${prevEntity.id}]`;
    const prevValue = prevEntity && getWorkingHours(prevEntity);

    const newPath = newEntity && `[${newEntity.id}]`;
    const newValue = newEntity && getWorkingHours(newEntity);

    if (prevPath && prevValue && prevPath !== newPath) {
      unset(nextState, prevPath);
      hasChanged = true;
    }

    if (
      newPath &&
      newValue &&
      (!isEqual(prevPath, newPath) || !isEqual(prevValue, newValue))
    ) {
      setWith(nextState, newPath, newValue, Object);
      hasChanged = true;
    }
  });

  if (!hasChanged) return state;

  return nextState;
};

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    statusId: statusIdReducer(state.statusId, changedEntities),
    userWorkingHours: userWorkingHoursReducer(
      state.userWorkingHours,
      changedEntities
    ),
    activeOptions: activeOptionsReducer(state.activeOptions, changedEntities),
  };
};

const receiveEntitiesReducer = (state, action) => {
  return reduceChangedEntities(
    state,
    parseEntityPayload(state, action.payload[ENTITY_NAME_USERS])
  );
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_USERS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
  }
);
