import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataExternalAccountingPlatformName,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobInvoiceSentToExternalPlatform = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {` has sent the ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataInvoiceNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to "}
    {getMetaDataExternalAccountingPlatformName(activityEntry)}
  </SystemMessage>
);

JobInvoiceSentToExternalPlatform.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoiceSentToExternalPlatform;
