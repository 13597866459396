import type { TUserPreference } from "@streamtimefe/entities";
import { all, call, delay, select } from "redux-saga/effects";
import { WebAPI } from "st-shared/lib";
import { authenticationStore } from "st-shared/stores";

import { SET_USER_PREFERENCE } from "../../../lib/constants";
import { takeLatestBy } from "../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../redux/helpers/sagaErrorHandlers";
import type { UserPreferenceSetAction } from "./actions";
import { selectUserPreference } from "./selectors/selectUserPreference";

export const debounceMS = 2000;

function* save(action: UserPreferenceSetAction) {
  if (authenticationStore().helpers.isProdAdminUser()) return;

  yield delay(debounceMS);
  try {
    const userPreference: TUserPreference = yield select(selectUserPreference, {
      key: action.payload.key,
    });

    yield call(WebAPI.UserPererences.save, userPreference);
  } catch (error) {
    sagaError(error);
  }
}

function* watchSaveUserPreference() {
  yield takeLatestBy(
    [SET_USER_PREFERENCE],
    save,
    (action: UserPreferenceSetAction) => action.payload.key
  );
}

export default function* userPreferenceSaga() {
  yield all([watchSaveUserPreference()]);
}
