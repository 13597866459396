import { first } from "lodash-es";
import { CSSProperties, useContext } from "react";
import styled from "styled-components";

import { getStartOfWeekDatesInRange } from "../../../lib/dates";
import { getWeekWidth } from "../../../state/ui/schedule/lib";
import GridBackground from "../../elements/GridBackground";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import Anchor from "./Anchor";

function BackgroundConsumer() {
  const { viewportStartDate, viewportEndDate, dayWidth, getOffsetXAtDate } =
    useContext(ScheduleScrollContext);

  const viewportWeeks: string[] = getStartOfWeekDatesInRange(
    viewportStartDate,
    viewportEndDate
  );
  const style: CSSProperties = {};

  if (viewportWeeks.length > 0) {
    style.left = getOffsetXAtDate(first(viewportWeeks)!);
    style.width = viewportWeeks.length * getWeekWidth(dayWidth);
  }

  return (
    <Anchor>
      <GridBackground cellWidth={dayWidth} style={style} />
      <TodayCell style={{ width: dayWidth }} />
    </Anchor>
  );
}

export default BackgroundConsumer;

const TodayCell = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--color-gray-dark);
  opacity: 0.05;
  pointer-events: none;
`;
