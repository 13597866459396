import type { Dictionary } from "@streamtimefe/types";

import type { TEntityId } from "../../core";
import { type TUser, User } from "../User";
import type { TRole } from "./Role";

export function costRate(roleId: TEntityId, users: TUser[]) {
  const usersWithRoleId = User.get.filterByActiveUsersByRoleId(users, roleId);
  if (usersWithRoleId.length === 0) {
    return 0;
  }

  const totalCostRate = usersWithRoleId
    .map(User.get.costRate)
    .reduce((value, total) => total + value, 0);

  return Math.round(totalCostRate / usersWithRoleId.length);
}

export type TRoleNameIndex = Dictionary<TEntityId, string>;
export function createRoleNameIndex(roles: TRole[]) {
  const index: TRoleNameIndex = {};
  for (const role of roles) {
    index[role.id] = role.name;
  }
  return index;
}
