import * as PropTypes from "prop-types";

interface LoadingSpinnerProps {
  inline?: boolean;
}

function LoadingSpinner({ inline = false }: LoadingSpinnerProps) {
  return (
    <div className={`loading-spinner ${!inline && "center-screen"}`}>
      <div className="loader1" />
      <div className="loader2" />
    </div>
  );
}

LoadingSpinner.propTypes = {
  inline: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  inline: false,
};

export default LoadingSpinner;
