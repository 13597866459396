import { runtimeEnv } from "@streamtimefe/environment";
import { getLocalStorageAuthToken } from "@streamtimefe/utils";
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from "axios";
import axios from "axios";
import {
  ApiException,
  getLocationSearchParam,
  getUserTimeZone,
} from "st-shared/lib";

import { getPusherSocketId } from "./WebAppAPI/pusher";

const API_ROOT = (runtimeEnv()?.VITE_WEB_API_ROOT ?? "") + "/webapi";

function getAuthToken() {
  return getLocationSearchParam("token") || getLocalStorageAuthToken();
}

function makeURL(url: string) {
  return API_ROOT + url;
}

function getHeaders(): RawAxiosRequestHeaders {
  const headers: RawAxiosRequestHeaders = {
    Authentication: getAuthToken(),
  };

  if (getPusherSocketId()) headers["Pusher-Socket-Id"] = getPusherSocketId();

  if (getUserTimeZone()) headers.Timezone = getUserTimeZone();

  return headers;
}

function catchResponse<T>(promise: Promise<AxiosResponse<T>>) {
  return promise.catch((error: AxiosError) => {
    if (error.response)
      switch (error.response.status) {
        case 400:
        case 401:
        case 422:
        case 403:
        case 500:
          throw new ApiException(error.response.data as string, error.response);
        case 404:
          throw new ApiException("404 resource not found", error.response);
        default:
          throw error;
      }

    throw error;
  });
}

async function get<T>(
  endpoint: string,
  params?: any,
  config?: AxiosRequestConfig<any>
) {
  return catchResponse<T>(
    axios.get<T>(API_ROOT + endpoint, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );
}

async function post<T>(
  endpoint: string,
  data?: any,
  params?: any,
  config?: AxiosRequestConfig<any>
) {
  return catchResponse<T>(
    axios.post<T>(API_ROOT + endpoint, data, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );
}

async function put<T>(
  endpoint: string,
  data: any,
  params?: any,
  config?: AxiosRequestConfig<any>
) {
  return catchResponse<T>(
    axios.put<T>(API_ROOT + endpoint, data, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );
}

async function _delete<T>(
  endpoint: string,
  params?: any,
  config?: AxiosRequestConfig<any>
) {
  return catchResponse<T>(
    axios.delete<T>(API_ROOT + endpoint, {
      headers: getHeaders(),
      params,
      ...config,
    })
  );
}

const API = {
  API_ROOT,
  getAuthToken,
  makeURL,
  get,
  post,
  put,
  delete: _delete,
};

export default API;
