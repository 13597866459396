import { formatCustomerCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../../lib/constants";
import { getJobExchangeRate } from "../../../../lib/entities/jobEntity";
import { round6dp } from "../../../../lib/math";
import { jobPageChangeJobExchangeRate } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";

const JobExchangeRate = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);

  const formattedCustomerCurrency = formatCustomerCurrency(customerCurrency, {
    value: 1,
    format: NUMBER_FORMAT_NO_DECIMAL,
  });
  const formattedExchangeRate = round6dp(getJobExchangeRate(job));

  return (
    <JumbotronSettingsField
      title="EXCHANGE RATE"
      onClick={() => jobPageChangeJobExchangeRate(job)}
      disabled={!isJobEditable}
    >
      <div>{`${formattedCustomerCurrency} ${customerCurrency.id} = ${formattedExchangeRate} ${jobCurrency.id}`}</div>
    </JumbotronSettingsField>
  );
};

export default JobExchangeRate;
