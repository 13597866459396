import type { TSavedSegmentTypeEnum, TUser } from "@streamtimefe/entities";
import { SavedSegment } from "@streamtimefe/entities";
import { useMemo } from "react";

import type { TEntityId } from "../../../entities/Entity";
import { useLoggedInUser } from "../../authentication/authenticationStore";
import { useSavedSegmentEntityStore } from "./savedSegmentStore";

export function useSavedSegmentEntity(entityId: TEntityId) {
  return useSavedSegmentEntityStore(
    (s) => s.editedEntitiesById[entityId] || s.entitiesById[entityId]
  );
}

export function useSavedSegmentsEntityIdsByType(
  savedSegmentType: TSavedSegmentTypeEnum
) {
  return useSavedSegmentEntityStore(
    (s) => s.indexes.entityIdsByType[savedSegmentType] || []
  );
}

export function useSavedSegmentEntityCrudState(entityId: TEntityId) {
  return useSavedSegmentEntityStore((s) => s.entitesCrudState[entityId]);
}

export function useSavedSegmentUserAccessType(entityId: TEntityId) {
  const loggedInUser = useLoggedInUser();
  const segment = useSavedSegmentEntity(entityId);

  return useMemo(() => {
    if (!segment || !loggedInUser) return false;

    return SavedSegment.getUserAccessType(segment, loggedInUser as TUser);
  }, [loggedInUser, segment]);
}

export function useSavedSegmentHasEditAccess(entityId: TEntityId) {
  const loggedInUser = useLoggedInUser();
  const segment = useSavedSegmentEntity(entityId);

  return useMemo(() => {
    if (!segment || !loggedInUser) return false;

    return SavedSegment.userHasEditAccess(segment, loggedInUser as TUser);
  }, [loggedInUser, segment]);
}

export function useSavedSegmentHasViewAccess(entityId: TEntityId) {
  const loggedInUser = useLoggedInUser();
  const segment = useSavedSegmentEntity(entityId);

  return useMemo(() => {
    if (!segment || !loggedInUser) return false;

    return SavedSegment.userHasViewAccess(segment, loggedInUser as TUser);
  }, [loggedInUser, segment]);
}

export function useSavedSegmentHasViewAccessLists(entityId: TEntityId) {
  const loggedInUser = useLoggedInUser();
  const segment = useSavedSegmentEntity(entityId);

  return useMemo(() => {
    if (!segment || !loggedInUser) return null;
    return SavedSegment.getHasViewAccessLists(segment);
  }, [loggedInUser, segment]);
}
