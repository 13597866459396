import type { PropsWithChildren, RefObject } from "react";
import { createContext, useContext, useMemo, useRef, useState } from "react";

type PopoverContainerProps = PropsWithChildren & {
  className?: string;
  height?: number;
};

export function PopoverContainer({
  children,
  className,
  height,
}: PopoverContainerProps) {
  const attachRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const context = useMemo<TPopoverContainerContext>(() => {
    return {
      attachRef,
      isOpen,
      setIsOpen,
    };
  }, [isOpen]);

  return (
    <PopoverContainerContext.Provider value={context}>
      <div className={className} style={{ height }} ref={attachRef}>
        {children}
      </div>
    </PopoverContainerContext.Provider>
  );
}

type TPopoverContainerContext = {
  attachRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const PopoverContainerContext = createContext<TPopoverContainerContext>(
  null as unknown as TPopoverContainerContext
);

export function usePopoverContainerContext() {
  const context = useContext(PopoverContainerContext);
  if (!context) {
    throw new Error(`Missing PopoverContainerContextProvider`);
  }
  return context;
}
