import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
} from "@streamtimefe/entities";
import { intersection, without } from "lodash-es";

import type { ReportingDataSet, TReportingEntityDescriptor } from ".";

const UniversalReportingFilterGroupTypes = [
  FilterGroupTypeEnum.CompanyId,
  FilterGroupTypeEnum.JobId,
  FilterGroupTypeEnum.JobBranchId,
  FilterGroupTypeEnum.RateCardId,
  FilterGroupTypeEnum.JobLeadUserId,
  FilterGroupTypeEnum.CompanyLeadUserId,
];

const ReportingFilterGroupTypes: Record<
  TReportingEntityDescriptor,
  readonly TFilterGroupTypeEnum[]
> = {
  [EntityDescriptorEnum.Job]: [...UniversalReportingFilterGroupTypes],
  [EntityDescriptorEnum.Invoice]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.InvoiceId,
  ],
  [EntityDescriptorEnum.Quote]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.QuoteId,
  ],
  [EntityDescriptorEnum.LoggedExpense]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.LoggedExpenseId,
    FilterGroupTypeEnum.JobPhaseName,
  ],
  [EntityDescriptorEnum.LoggedTime]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.ItemName,
    FilterGroupTypeEnum.JobPhaseName,
    FilterGroupTypeEnum.RoleId,
    FilterGroupTypeEnum.TeamMember,
    FilterGroupTypeEnum.UserBranchId,
    FilterGroupTypeEnum.Date,
  ],
  [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.ItemName,
    FilterGroupTypeEnum.JobPhaseName,
    FilterGroupTypeEnum.RoleId,
    FilterGroupTypeEnum.TeamMember,
  ],
  [EntityDescriptorEnum.JobItemUserRolePlannedTime]: [
    ...UniversalReportingFilterGroupTypes,
    FilterGroupTypeEnum.ItemName,
    FilterGroupTypeEnum.JobPhaseName,
    FilterGroupTypeEnum.RoleId,
    FilterGroupTypeEnum.TeamMember,
  ],
  [EntityDescriptorEnum.User]: [
    FilterGroupTypeEnum.UserBranchId,
    FilterGroupTypeEnum.RoleId,
    FilterGroupTypeEnum.TeamMember,
  ],
};

const SubDataExclusionFilterGroupTypes: Partial<
  Record<TFilterGroupTypeEnum, TFilterGroupTypeEnum[]>
> = {
  [FilterGroupTypeEnum.CompanyId]: [FilterGroupTypeEnum.CompanyLeadUserId],
  [FilterGroupTypeEnum.JobId]: UniversalReportingFilterGroupTypes,
  [FilterGroupTypeEnum.TeamMember]: [
    FilterGroupTypeEnum.UserBranchId,
    FilterGroupTypeEnum.RoleId,
    FilterGroupTypeEnum.JobLeadUserId,
    FilterGroupTypeEnum.CompanyLeadUserId,
  ],
  [FilterGroupTypeEnum.QuoteId]: UniversalReportingFilterGroupTypes,
  [FilterGroupTypeEnum.InvoiceId]: UniversalReportingFilterGroupTypes,
  [FilterGroupTypeEnum.LoggedExpenseId]: UniversalReportingFilterGroupTypes,
};

export function getFilterGroupTypes(
  dataSets: ReportingDataSet[]
): TFilterGroupTypeEnum[] {
  return [
    ...intersection(
      ...dataSets.map((d) => ReportingFilterGroupTypes[d.entityDescriptorId])
    ),
  ];
}

export function getSubFilterGroupTypes(
  dataSets: ReportingDataSet[],
  filterGroupType: TFilterGroupTypeEnum | null
): TFilterGroupTypeEnum[] {
  let exclusions: TFilterGroupTypeEnum[] = [];

  if (filterGroupType) {
    exclusions = [
      filterGroupType,
      ...(SubDataExclusionFilterGroupTypes[filterGroupType] || []),
    ];
  }

  return without(getFilterGroupTypes(dataSets), ...exclusions);
}

export const ReportingFilterGroupTypeNullName = "All";
