import React from "react";
import styled from "styled-components";

const DotPulseIcon = props => (
  <svg
    viewBox="0 0 32 32"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <circle transform="translate(8)" cy={16} r={0.456}>
      <animate
        attributeName="r"
        values="0; 4; 0; 0"
        dur="1.2s"
        repeatCount="indefinite"
        begin={0}
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
        calcMode="spline"
      />
    </circle>
    <circle transform="translate(16)" cy={16} r={0}>
      <animate
        attributeName="r"
        values="0; 4; 0; 0"
        dur="1.2s"
        repeatCount="indefinite"
        begin={0.3}
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
        calcMode="spline"
      />
    </circle>
    <circle transform="translate(24)" cy={16} r={0.686}>
      <animate
        attributeName="r"
        values="0; 4; 0; 0"
        dur="1.2s"
        repeatCount="indefinite"
        begin={0.6}
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
        calcMode="spline"
      />
    </circle>
  </svg>
);

export default DotPulseIcon;

export const InlineDotPulseIcon = styled(DotPulseIcon)`
  display: inline;
  color: var(--color-gray-dark);
  font-size: 1.2em;
  vertical-align: bottom;
`;
