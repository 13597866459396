import { Select, SelectItem, SelectKey } from "st-shared/components";
import { ReportingColumn } from "st-shared/entities";
import { plate } from "st-shared/external";

import { insertColumn } from "../plugins/column/transforms/insertColumn";
import {
  columnButtonSelectCss,
  columnButtonTextCss,
  columnSelectItemCss,
} from "./ColumnSelector.css";

type Props = {
  columns: ReportingColumn[];
};

export function ColumnSelector({ columns }: Props) {
  const editor = plate.useEditorRef();

  function onSelectionChange(value: SelectKey) {
    const key = value as ReportingColumn["id"];

    insertColumn(editor, key);
  }

  return (
    <Select
      aria-label="Add Column Selector"
      items={columns}
      selectedKey={null}
      onSelectionChange={onSelectionChange}
      placeholder="Add a column"
      buttonProps={{
        className: columnButtonSelectCss,
        textClassName: columnButtonTextCss,
      }}
    >
      {(item) => (
        <SelectItem className={columnSelectItemCss} id={item.id}>
          {item.name}
        </SelectItem>
      )}
    </Select>
  );
}
