import { isEqual, remove } from "lodash-es";
import { defaultSortOptions } from "st-shared/lib";

/**
 * @param {function(object)} getOption
 * @param {function(object)} sortOptions
 * @returns {Function}
 */
export default (getOption, sortOptions = defaultSortOptions) =>
  (state = [], changedEntities) => {
    let hasChanged = false;

    const nextState = [...state];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevOption = prevEntity && getOption(prevEntity);

      const newOption = newEntity && getOption(newEntity);

      if (!isEqual(prevOption, newOption)) {
        if (prevOption) {
          remove(nextState, (option) => option.key === prevOption.key);
          hasChanged = true;
        }

        if (newOption) {
          nextState.push(newOption);
          hasChanged = true;
        }
      }
    });

    if (!hasChanged) return state;

    return nextState.sort(sortOptions);
  };
