import { Entity, TEntityId } from "../../../entities/Entity";
import { ChangedEntity } from "../types";

export function parseRemovedEntities<T extends Entity>(
  entities: Partial<Record<TEntityId, T>>,
  ids: TEntityId[]
): ChangedEntity<T>[] {
  return ids
    .map((id) => entities[id])
    .filter(Boolean)
    .map((prevEntity) => ({ prevEntity }));
}
