import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { useMemo } from "react";
import type { SelectKey } from "st-shared/components";
import { Select, SelectItem } from "st-shared/components";
import type { ReportingDataSet } from "st-shared/entities";
import {
  ReportingColumnType,
  ReportingDateFilterGroupTypes,
} from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";
import { useCustomerCurrency } from "st-shared/stores";
import { getFilterName } from "st-shared/types";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentDates,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";
import {
  subHeaderSelectorCss,
  subHeaderSelectorDesciptionCss,
} from "./FilterGroupSelector.css";

export function DateReportByFilter() {
  const dates = useReportingSavedSegmentDates();
  const columnId = useReportingSavedSegmentSelectedTimeId();
  const columns = useReportingSavedSegmentColumns();
  const dataSets = useReportingSavedSegmentDataSets();
  const searchType = useReportingSavedSegmentSearchType();

  if (searchType !== ReportingSearchType.TimeSeries) {
    return null;
  }

  if (dates === null || columnId == null || !(columnId in columns)) return null;

  const column = columns[columnId];

  if (
    !(
      column.type === ReportingColumnType.Data ||
      column.type === ReportingColumnType.Total
    ) ||
    !(column.dataSetId in dataSets)
  )
    return null;

  const dataSet = dataSets[column.dataSetId];

  return <ReportByFilter dataSet={dataSet} />;
}

type ReportByFilterProps = {
  dataSet: ReportingDataSet;
};

export function ReportByFilter({ dataSet }: ReportByFilterProps) {
  const customerCurrency = useCustomerCurrency();

  const selectItems = useMemo(
    () =>
      ReportingDateFilterGroupTypes[dataSet.entityDescriptorId].map(
        (filterGroupType) => ({
          id: filterGroupType,
          name: getFilterName(filterGroupType, customerCurrency, true),
        })
      ),
    [dataSet, customerCurrency]
  );

  if (selectItems.length <= 1) return null;

  function onSelectionChange(value: SelectKey) {
    const dateFilterGroupTypeId = value as TFilterGroupTypeEnum;
    if (dateFilterGroupTypeId !== dataSet.dateFilterGroupTypeId) {
      reportingStore().savedSegment.actions.setDatasetDateFilterGroupType(
        dataSet.id,
        dateFilterGroupTypeId
      );
    }
  }

  return (
    <div className={subHeaderSelectorCss}>
      <span className={subHeaderSelectorDesciptionCss}>time filtered by</span>
      <Select
        aria-label="Report By Filter Selector"
        items={selectItems}
        selectedKey={dataSet.dateFilterGroupTypeId}
        onSelectionChange={onSelectionChange}
      >
        {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
      </Select>
    </div>
  );
}
