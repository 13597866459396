import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  primaryUserDisplayNameCss,
  primaryUserRoleNameCss,
} from "st-shared/module";
import styled from "styled-components";

import {
  quoteDocumentMethods,
  quoteEntityType,
} from "../../../lib/entities/quoteEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectQuoteById,
  selectQuoteTeamMembersById,
} from "../../../redux/selectors/quoteSelectors";
import Flex from "../../elements/Flex";
import LanguageOption from "../../modules/CommercialDocuments/LanguageOption";
import UserIconName from "../../modules/User/UserIconName";

const mapState = (state, props) => ({
  quote: selectQuoteById(state, props),
  users: selectQuoteTeamMembersById(state, props),
});

class QuoteTeamMembers extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    quote: quoteEntityType.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: entityIdType,
        name: PropTypes.string,
      })
    ).isRequired,
  };

  render() {
    const { users, quote } = this.props;
    const { displayTeam } = quoteDocumentMethods.getDisplayOptions(quote);

    if (!displayTeam || !users.length) return null;

    return (
      <Wrapper>
        <Heading>
          <LanguageOption optionKey="team" />
        </Heading>
        <TeamMembers>
          {users.map((user) => (
            <TeamMember key={user.id} id={user.id} showRole />
          ))}
        </TeamMembers>
      </Wrapper>
    );
  }
}

export default connect(mapState)(QuoteTeamMembers);

const Wrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Heading = styled(Flex)`
  color: black;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  align-items: center;
  border-bottom: var(--border-thin-black);
`;

const TeamMembers = styled(Flex)`
  flex-wrap: wrap;
`;

const TeamMember = styled(UserIconName).attrs({
  size: 26,
  nameWidth: "calc(100% - 36px)",
})`
  width: 32%;
  margin: 15px 8px 0 0;
  .${primaryUserDisplayNameCss} {
    font-size: 12px;
    line-height: 1.5;
  }
  .${primaryUserRoleNameCss} {
    font-style: italic;
    font-size: 12px;
  }
`;
