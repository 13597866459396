import React from "react";
import styled from "styled-components";
import Flex from "./Flex";
import DotPulseIcon from "./Icons/DotPulseIcon";

const LoadingOverlay = () => (
  <Wrapper>
    <DotPulseIcon />
  </Wrapper>
);

const Wrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 236, 236, 0.5);
  font-size: 24px;
  color: var(--color-gray-dark);
  z-index: 1;
`;

export default LoadingOverlay;
