import { createContext, useContext as useReactContext, useState } from "react";

import {
  createActionStore,
  type TActionCreator,
  type TActionStore,
} from "./createActionStore";
import type { TStoreCreator } from "./createStore";

export function createActionStoreContext<S, A>(name: string) {
  const context = createContext<TActionStore<S, A>>(
    null as unknown as TActionStore<S, A>
  );

  context.displayName = `${name}StoreContext`;

  function useContext() {
    const storeContext = useReactContext(context);
    if (!storeContext) {
      throw new Error(`Missing ${name}StoreProvider`);
    }
    return storeContext;
  }

  return {
    Provider: context.Provider,
    useStore: () => useContext().store,
    useActions: () => useContext().actions,
  };
}

export function useCreateActionStore<S, A>(
  initializer: TStoreCreator<S>,
  actionCreator: TActionCreator<S, A>
) {
  const [context] = useState(() =>
    createActionStore<S, A>(initializer, actionCreator)
  );
  return context;
}
