import { FilterGroupTypeProperties, FilterType } from "../../types";
import { CheckboxFilterDisplayText } from "./components/CheckboxFilter";
import { DateFilterDisplayText } from "./components/DateFilter";
import { DropdownFilterDisplayText } from "./components/DropdownFilter";
import { EntityListFilterDisplayText } from "./components/EntityListFilter";
import { NumberFilterDisplayText } from "./components/NumberFilter";
import { SystemFilterDisplayText } from "./components/SystemFilter";
import { TextFilterDisplayText } from "./components/TextFilter";
import { FilterDisplayTextProps } from "./types";

export function FilterDisplayText(props: FilterDisplayTextProps) {
  switch (FilterGroupTypeProperties[props.filterGroup.filterGroupTypeId].type) {
    case FilterType.Checkbox:
      return <CheckboxFilterDisplayText {...props} />;
    case FilterType.Number:
      return <NumberFilterDisplayText type="number" {...props} />;
    case FilterType.Minutes:
      return <NumberFilterDisplayText type="minutes" {...props} />;
    case FilterType.Text:
      return <TextFilterDisplayText {...props} />;
    case FilterType.Date:
      return <DateFilterDisplayText {...props} />;
    case FilterType.Dropdown:
      return <DropdownFilterDisplayText {...props} />;
    case FilterType.System:
      return <SystemFilterDisplayText {...props} />;
    case FilterType.EntityList:
      return <EntityListFilterDisplayText {...props} />;
  }

  return null;
}
