import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

const Flow = styled(SegmentPart).attrs(
  mapPropsToClassName([
    "conflict",
    "snake",
    "left",
    "right",
    "selected",
    "float"
  ])
)`
  position: absolute;
  width: 6px;
  height: 6px;
  border-width: 2px 2px 0 0;
`;

export const FlowLeft = styled(Flow)`
  top: 14px;
  transform: rotate(-135deg);
  ${props => (props.snake ? `right: 6px` : `left: 8px`)};
  &.snake {
    right: 6px;
  }
  &:not(.snake) {
    left: 8px;
  }
`;

export const FlowRight = styled(Flow)`
  top: 14px;
  transform: rotate(45deg);
  &.snake {
    left: 6px;
  }
  &:not(.snake) {
    right: 8px;
  }
`;

export const FlowDown = styled(Flow)`
  bottom: 8px;
  transform: rotate(135deg);
  &.left {
    left: -2px;
  }
  &.right {
    right: -2px;
  }
`;

export const FlowUp = styled(Flow)`
  top: 9px;
  transform: rotate(-45deg);
  &.left {
    left: -2px;
  }
  &.right {
    right: -2px;
  }
`;
