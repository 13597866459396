import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { JOB_DETAILS_SET_BOTTOM_SECTION_TAB } from "../../../lib/constants";
import { JOB_BOTTOM_SECTION_TABS } from "../../../lib/constants/jobDetails";
import { scrollToBottomSection } from "../../../lib/ui/jobDetails";
import createAction from "../../../redux/helpers/createAction";

export function useGoToTimeline() {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(
      createAction(
        JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
        JOB_BOTTOM_SECTION_TABS.TIMELINE
      )
    );

    requestAnimationFrame(() => {
      scrollToBottomSection();
    });
  }, []);
}
