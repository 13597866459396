import { createContext } from "react";

import { theme } from "../../../theme";

export type TFilterListHeaderType = "is/not" | "any/all" | null;

export type TFilterContext = {
  filterListHeaderType: TFilterListHeaderType;
  displayBackgroundColor?: string;
  warningTooltip?: React.ReactNode;
};

export const FilterContext = createContext<TFilterContext>({
  filterListHeaderType: null,
  displayBackgroundColor: theme.color.white,
});
