import { reduce, sortBy } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { TEntityId } from "st-shared/entities/Entity";

import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemUserEntity";
import { selectJobItemUser } from "../../../redux/selectors/jobItemUser";
import { selectJobItemUserCalculatedPlannedCost } from "../../../redux/selectors/jobItemUser/selectJobItemUserCalculatedPlannedCost";
import { selectJobItemUserCalculatedTotalLoggedTimeExTax } from "../../../redux/selectors/jobItemUser/selectJobItemUserCalculatedTotalLoggedTimeExTax";
import { selectJobItemUserIdsByJobItemId } from "../../../redux/selectors/jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectUserNameByJobItemUserId } from "../../../redux/selectors/jobItemUser/selectUserNameByJobItemUserId";
import { selectRole } from "../../../redux/selectors/role";
import { selectUser } from "../../../redux/selectors/user";

export type GroupedJobItemUsers = {
  roleId: TEntityId | null;
  roleName: string;
  jobItemUserIds: TEntityId[];
};

export function selectGroupedJobItemUsersByRoleIds(
  state: any,
  jobItemId: TEntityId
) {
  const jobItemUserIds: TEntityId[] = selectJobItemUserIdsByJobItemId(state, {
    jobItemId,
  });

  if (!jobItemUserIds) return [];

  const groupedRoles: Record<TEntityId, TEntityId[]> = {};
  const groupedJobItemUsers: GroupedJobItemUsers[] = [];
  const otherRoles: GroupedJobItemUsers = {
    roleId: null,
    roleName: "Other Roles",
    jobItemUserIds: [],
  };

  jobItemUserIds.forEach((jobItemUserId) => {
    const jobItemUser = selectJobItemUser(state, { jobItemUserId });
    const user = selectUser(state, { userId: jobItemUser.userId });
    if (user && user.roleId) {
      if (groupedRoles[user.roleId]) {
        groupedRoles[user.roleId].push(jobItemUserId);
      } else {
        groupedRoles[user.roleId] = [jobItemUserId];
      }
    } else {
      otherRoles.jobItemUserIds.push(jobItemUserId);
    }
  });

  Object.keys(groupedRoles).forEach((key) => {
    const groupedRole = groupedRoles[key];
    if (groupedRole.length < 2) {
      otherRoles.jobItemUserIds.push(...groupedRole);
    } else {
      const roleId = Number(key);
      const role = selectRole(state, { roleId });
      groupedJobItemUsers.push({
        roleId,
        roleName: role.name,
        jobItemUserIds: groupedRole,
      });
    }
  });

  const allGrouped = [
    ...sortBy(groupedJobItemUsers, (group) => group.roleName.toLowerCase()),
    otherRoles,
  ];

  allGrouped.forEach((group) => {
    group.jobItemUserIds = sortBy(group.jobItemUserIds, (jobItemUserId) =>
      selectUserNameByJobItemUserId(state, {
        jobItemUserId,
      })
    );
  });

  return allGrouped;
}

export function useGroupedJobItemUsersByRoleIds(jobItemId: TEntityId) {
  return useSelector((state) =>
    selectGroupedJobItemUsersByRoleIds(state, jobItemId)
  );
}

function selectGroupedJobItemUsersHours(
  state: any,
  jobItemUserIds: TEntityId[]
) {
  const jobItemUsers = jobItemUserIds.map((jobItemUserId) =>
    selectJobItemUser(state, { jobItemUserId })
  );

  const totalLoggedMinutes = reduce(
    jobItemUsers,
    (total, jobItemUser) => total + getTotalUsedMinutes(jobItemUser),
    0
  );

  const totalPlannedMinutes = reduce(
    jobItemUsers,
    (total, jobItemUser) => total + getTotalPlannedMinutes(jobItemUser),
    0
  );

  const totalRemainingMinutes = numeral(totalPlannedMinutes)
    .subtract(numeral(totalLoggedMinutes).value())
    .value()!;

  return {
    totalLoggedMinutes,
    totalPlannedMinutes,
    totalRemainingMinutes,
  };
}

export function useGroupedJobItemUsersHours(jobItemUserIds: TEntityId[]) {
  return useSelector((state) =>
    selectGroupedJobItemUsersHours(state, jobItemUserIds)
  );
}

function selectGroupedJobItemUsersDollars(
  state: any,
  jobItemUserIds: TEntityId[]
) {
  const jobItemUsers = jobItemUserIds.map((jobItemUserId) =>
    selectJobItemUser(state, { jobItemUserId })
  );

  const jobCurrencyTotalLoggedTimeExTax = reduce(
    jobItemUsers,
    (total, jobItemUser) =>
      total +
      selectJobItemUserCalculatedTotalLoggedTimeExTax(state, {
        jobItemId: jobItemUser.jobItemId,
        jobItemUserId: jobItemUser.id,
      }),
    0
  );

  const jobCurrencyTotalPlannedTimeExTax = reduce(
    jobItemUsers,
    (total, jobItemUser) =>
      total +
      selectJobItemUserCalculatedPlannedCost(state, {
        jobItemUserId: jobItemUser.id,
      }),
    0
  );

  return {
    jobCurrencyTotalLoggedTimeExTax,
    jobCurrencyTotalPlannedTimeExTax,
  };
}

export function useGroupedJobItemUsersDollars(jobItemUserIds: TEntityId[]) {
  return useSelector((state) =>
    selectGroupedJobItemUsersDollars(state, jobItemUserIds)
  );
}
