import { produce } from "immer";
import {
  difference,
  each,
  findKey,
  get,
  indexOf,
  setWith,
  union,
  unset,
} from "lodash-es";

import { isTruthyEqual } from "../../../../lib/comparators";
import { isBefore, minDate, simpleCompareAsc } from "../../../../lib/dates";
import {
  getBlockHeight,
  getJobIdIfBaseLoggedTime,
  getJobIdIfNoItemBaseLoggedTime,
  getJobItemUserIdIfBaseLoggedTime,
  getUserIdDateKeyPath,
  isBaseLoggedTime,
  isPaused,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";
import { mergeWithArrayCollection } from "../../../../lib/objects";

const getEntityById = (state, blockKey) => state.byId[blockKey];

const getDateById = (state, blockKey) => getEntityById(state, blockKey).date;

const getHeight = (state, blockKey) => getBlockHeight(state.byId[blockKey]);

const updateOffsets = (draft, touchedUserDates, path, getFilterValue) => {
  const changedBlockKeys = [];

  const findCollisionBlockKey = (
    positionedBlocks,
    currentBlockKey,
    currentOffsetY
  ) =>
    findKey(positionedBlocks, (otherOffsetY, otherKey) => {
      // don't check collision with itself
      if (otherKey === currentBlockKey) return false;

      // check if blocks are in the same collision group
      if (
        !isTruthyEqual(
          getFilterValue(getEntityById(draft, otherKey)),
          getFilterValue(getEntityById(draft, currentBlockKey))
        )
      )
        return false;

      // check if blocks are overlapping
      return !(
        otherOffsetY >= currentOffsetY + getHeight(draft, currentBlockKey) ||
        currentOffsetY >= otherOffsetY + getHeight(draft, otherKey)
      );
    });

  const getSortedBlockKeys = (userId, date) =>
    get(
      draft.sortedBlockKeysByUserIdDate,
      getUserIdDateKeyPath({ userId, date }),
      []
    ).filter((blockKey) =>
      Boolean(getFilterValue(getEntityById(draft, blockKey)))
    );

  const getExpandedTouchedDates = (initialTouchedDates, userId) => {
    const expandedTouchedDates = [];
    let touchedDates = initialTouchedDates.slice();

    while (touchedDates.length) {
      touchedDates.slice().sort(simpleCompareAsc);

      const date = touchedDates.shift();

      expandedTouchedDates.push(date);

      const blockKeysOnDate = getSortedBlockKeys(userId, date);
      for (const blockKey of blockKeysOnDate) {
        const relatedDates = get(draft.idsByBlockKey, blockKey, []).map((id) =>
          getDateById(draft, id)
        );

        touchedDates = difference(
          union(relatedDates, touchedDates),
          expandedTouchedDates
        );
      }
    }

    return expandedTouchedDates;
  };

  each(touchedUserDates, (initialTouchedDates, userId) => {
    const earliestTouchedDate = minDate(initialTouchedDates);
    getExpandedTouchedDates(initialTouchedDates, userId)
      .sort(simpleCompareAsc)
      .forEach((date) => {
        const positionedBlocks = {};

        getSortedBlockKeys(userId, date).forEach((blockKey) => {
          // if the block precedes the modified range, OR
          // if the block has already been positioned,
          // Cache its current position and don't move the block
          if (
            (getEntityById(draft, blockKey) &&
              isBefore(getDateById(draft, blockKey), earliestTouchedDate)) ||
            indexOf(changedBlockKeys, blockKey) > -1
          ) {
            positionedBlocks[blockKey] = draft[path][blockKey];
            return;
          }

          // position this block, resolving conflicts with
          // preceding/positioned blocks
          let offsetY = 0;
          let collisionBlockKey;

          // eslint-disable-next-line no-cond-assign
          while (
            (collisionBlockKey = findCollisionBlockKey(
              positionedBlocks,
              blockKey,
              offsetY
            ))
          )
            offsetY =
              positionedBlocks[collisionBlockKey] +
              getHeight(draft, collisionBlockKey);

          changedBlockKeys.push(blockKey);
          positionedBlocks[blockKey] = offsetY;
          setWith(draft, `${path}.${blockKey}`, offsetY, Object);
        });
      });
  });
};

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedUserDates = {};

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const isPrevBaseLoggedTime = prevEntity && isBaseLoggedTime(prevEntity);
      const isNextBaseLoggedTime = newEntity && isBaseLoggedTime(newEntity);

      if (isPrevBaseLoggedTime && !isNextBaseLoggedTime) {
        unset(draft, `offsetYByBlockKey.${prevEntity.id}`);
        unset(draft, `offsetYByBlockKeyPaused.${prevEntity.id}`);
        unset(draft, `offsetYByBlockKeyForJobs.${prevEntity.id}`);
        unset(draft, `offsetYByBlockKeyForJobsPaused.${prevEntity.id}`);
        unset(draft, `offsetYByBlockKeyForJobItemUsers.${prevEntity.id}`);
        unset(draft, `offsetYByBlockKeyForJobsWithNoItem.${prevEntity.id}`);
      }

      if (prevEntity)
        mergeWithArrayCollection(
          touchedUserDates,
          prevEntity.userId,
          prevEntity.date
        );

      if (newEntity)
        mergeWithArrayCollection(
          touchedUserDates,
          newEntity.userId,
          newEntity.date
        );
    });

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKey",
      (entity) => entity && !isPaused(entity) && isBaseLoggedTime(entity)
    );

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKeyPaused",
      isBaseLoggedTime
    );

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKeyForJobs",
      (entity) =>
        entity && !isPaused(entity) && getJobIdIfBaseLoggedTime(entity)
    );

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKeyForJobsPaused",
      getJobIdIfBaseLoggedTime
    );

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKeyForJobItemUsers",
      getJobItemUserIdIfBaseLoggedTime
    );

    updateOffsets(
      draft,
      touchedUserDates,
      "offsetYByBlockKeyForJobsWithNoItem",
      (entity) => entity && getJobIdIfNoItemBaseLoggedTime(entity)
    );
  });
