import { produce } from "immer";
import { get, reduce, setWith } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import {
  getBlockHeight,
  getUserIdKeyPath,
  isBaseLoggedTime,
  isPaused,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getMaxBlockHeight = (state, blockKeys, includePaused) =>
  reduce(
    blockKeys,
    (max, key) => {
      const scheduleLoggedTime = state.byId[key];
      const height = getBlockHeight(scheduleLoggedTime);
      const offsetY = get(
        state,
        `offsetYByBlockKey${includePaused ? "Paused" : ""}.${key}`,
        0
      );

      if (!includePaused && isPaused(scheduleLoggedTime)) return max;

      return Math.max(max, height + offsetY);
    },
    0
  );

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath =
        isBaseLoggedTime(prevEntity) && getUserIdKeyPath(prevEntity);
      const newPath =
        isBaseLoggedTime(newEntity) && getUserIdKeyPath(newEntity);

      if (prevPath) pushOnce(touchedPaths, prevPath);
      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((keyPath) => {
      const blockKeys = get(nextState.blockKeysByUserId, keyPath, []);

      setWith(
        draft,
        `maxBlockHeightByUserId.${keyPath}`,
        getMaxBlockHeight(nextState, blockKeys, false),
        Object
      );

      setWith(
        draft,
        `maxBlockHeightByUserIdPaused.${keyPath}`,
        getMaxBlockHeight(nextState, blockKeys, true),
        Object
      );
    });
  });
