import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
} from "@streamtimefe/entities";
import { intersectionBy, mapValues } from "lodash-es";

import { defaultSortOptions } from "../../../lib";
import type { FilterSearchOption } from "../../../types";
import { FilterGroupTypeProperties } from "../../../types";
import type { TReportingEntityDescriptor } from "./ReportingEntityDescriptors";

const Categories = {
  General: "General",
  Dates: "Dates",
  Financial: "Financial",
  Time: "Time",
  NonBillable: "Non-billable",
  Expenses: "Expenses",
  Invoices: "Invoices",
} as const;

type TCategories = (typeof Categories)[keyof typeof Categories];
type ReportingFilters = (
  | {
      filter: TFilterGroupTypeEnum;
      category: TCategories;
      deprecated?: false;
    }
  | { filter: TFilterGroupTypeEnum; deprecated: true; category?: undefined }
)[];

const reportingFilters: Record<TReportingEntityDescriptor, ReportingFilters> = {
  [EntityDescriptorEnum.Job]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.ContactLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.ContactName, category: Categories.General },
    { filter: FilterGroupTypeEnum.RateCardName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobPurchaseOrderNumber,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.TeamMember, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserBranchId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobMilestoneName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    // Dates
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobItemDueDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobDaysPastDueDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.JobDaysUntilDueDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.JobMilestoneDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.JobHasInvoiceIssuedWithinDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.JobHasInvoiceDueWithinDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.JobHasItemDueWithinDate,
      category: Categories.Dates,
    },
    // Financial
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedProfitMargin,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.ExchangeRate,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.JobBudget, category: Categories.Financial },
    {
      filter: FilterGroupTypeEnum.JobBudgetVariance,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.JobBudgetVariancePercentage,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedCostExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedProfitExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalPlannedExTax,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    // Time
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedMinutes,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalPlannedMinutes,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedTimeCostExTax,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedTimeProfitExTax,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedTimeExTax,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalPlannedTimeExTax,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.LoggedTimeScheduled,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.LoggedTimeCompleted,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes,
      category: Categories.Time,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedDollars,
      category: Categories.Time,
    },
    // Non-billable
    {
      filter: FilterGroupTypeEnum.NonBillablePlannedMinutes,
      category: Categories.NonBillable,
    },
    {
      filter: FilterGroupTypeEnum.NonBillableLoggedMinutes,
      category: Categories.NonBillable,
    },
    {
      filter: FilterGroupTypeEnum.NonBillablePlannedTimeExTax,
      category: Categories.NonBillable,
    },
    {
      filter: FilterGroupTypeEnum.NonBillableLoggedTimeExTax,
      category: Categories.NonBillable,
    },
    // Expenses
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedExpensesCostExTax,
      category: Categories.Expenses,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalPlannedExpensesExTax,
      category: Categories.Expenses,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalPlannedExpensesCostExTax,
      category: Categories.Expenses,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedExpensesExTax,
      category: Categories.Expenses,
    },
    {
      filter: FilterGroupTypeEnum.JobTotalLoggedExpensesProfitExTax,
      category: Categories.Expenses,
    },
    // Invoices
    {
      filter: FilterGroupTypeEnum.JobTotalInvoicedExTax,
      category: Categories.Invoices,
    },
    {
      filter: FilterGroupTypeEnum.TotalDraftInvoicesExTax,
      category: Categories.Invoices,
    },
    {
      filter: FilterGroupTypeEnum.TotalAwaitingPaymentInvoicesExTax,
      category: Categories.Invoices,
    },
    {
      filter: FilterGroupTypeEnum.TotalPaidInvoicesExTax,
      category: Categories.Invoices,
    },
    {
      filter: FilterGroupTypeEnum.TotalIssuedInvoicesExTax,
      category: Categories.Invoices,
    },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserBranchName,
      deprecated: true,
    },
  ],
  [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobItemStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.RateCardName, category: Categories.General },
    { filter: FilterGroupTypeEnum.TeamMember, category: Categories.General },
    { filter: FilterGroupTypeEnum.ItemName, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobPhaseName, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.RoleId, category: Categories.General },
    // Financial
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    // Dates
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserRoleName,
      deprecated: true,
    },
  ],
  [EntityDescriptorEnum.JobItemUserRolePlannedTime]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.RateCardName, category: Categories.General },
    { filter: FilterGroupTypeEnum.TeamMember, category: Categories.General },
    { filter: FilterGroupTypeEnum.ItemName, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobItemStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobPhaseName, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.RoleId, category: Categories.General },
    // Financial
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    // Dates
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserRoleName,
      deprecated: true,
    },
  ],
  [EntityDescriptorEnum.Quote]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.ContactLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderNumber,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.QuoteLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.QuoteNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.CreatedUser, category: Categories.General },
    { filter: FilterGroupTypeEnum.QuoteStatus, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSent,
      category: Categories.General,
    },
    // Dates
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSentDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.IssueDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.QuoteApprovedDate,
      category: Categories.Dates,
    },
    {
      filter: FilterGroupTypeEnum.QuoteDeclinedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    // Financial
    {
      filter: FilterGroupTypeEnum.QuoteTotalAmountExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.CurrencyCode,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.ExchangeRate,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    {
      filter: FilterGroupTypeEnum.InvoicedExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoicedPercentage,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoicedBalanceRemaining,
      category: Categories.Financial,
    },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    { filter: FilterGroupTypeEnum.QuoteQuoteDate, deprecated: true },
  ],
  [EntityDescriptorEnum.Invoice]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.ContactLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.InvoiceLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.InvoiceNumber, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderNumber,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.Reference, category: Categories.General },
    { filter: FilterGroupTypeEnum.CreatedUser, category: Categories.General },
    { filter: FilterGroupTypeEnum.InvoiceType, category: Categories.General },
    { filter: FilterGroupTypeEnum.InvoiceStatus, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.InvoiceSeenStatus,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.SentToExternalPlatform,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSent,
      category: Categories.General,
    },
    // Dates
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSentDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.InvoicePaidDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.InvoiceDueDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.IssueDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    // Financial
    {
      filter: FilterGroupTypeEnum.InvoiceTotalAmountExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoiceTotalAmountIncTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoiceCurrencyAmountPaidIncTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoiceAmountPaidIncTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoiceCurrencyBalance,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoiceBalance,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.CurrencyCode,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.ExchangeRate,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    { filter: FilterGroupTypeEnum.InvoiceReference, deprecated: true },
    { filter: FilterGroupTypeEnum.InvoiceInvoiceDate, deprecated: true },
  ],
  [EntityDescriptorEnum.LoggedExpense]: [
    // General
    {
      filter: FilterGroupTypeEnum.PurchaseOrderNumber,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.ExpenseName, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.ExpenseDescription,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseQuantity,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.Reference, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.ExpenseCompany,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseContactName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseContactEmail,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.ContactLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.LoggedExpenseLabel,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.ExpenseType, category: Categories.General },
    { filter: FilterGroupTypeEnum.ExpenseStatus, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.SentToExternalPlatform,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSent,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.ExchangeRate, category: Categories.General },
    // Dates
    {
      filter: FilterGroupTypeEnum.CommercialDocumentSentDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.Date, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderRequiredDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    // Financial
    {
      filter: FilterGroupTypeEnum.PurchaseOrderCurrencyAmountPaidIncTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderAmountPaidIncTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderCurrencyBalance,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.PurchaseOrderBalance,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseTotalCost,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseTotalSell,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.CurrencyCode,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.BilledStatus,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    {
      filter: FilterGroupTypeEnum.InvoicedExTax,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoicedPercentage,
      category: Categories.Financial,
    },
    {
      filter: FilterGroupTypeEnum.InvoicedBalanceRemaining,
      category: Categories.Financial,
    },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.ExpenseReference,
      deprecated: true,
    },
  ],
  [EntityDescriptorEnum.LoggedTime]: [
    // General
    { filter: FilterGroupTypeEnum.CompanyName, category: Categories.General },
    { filter: FilterGroupTypeEnum.CompanyLabel, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.CompanyLeadUserDisplayName,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobLeadUserDisplayName,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.JobBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobNumber, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.RateCardName, category: Categories.General },
    { filter: FilterGroupTypeEnum.TeamMember, category: Categories.General },
    { filter: FilterGroupTypeEnum.Minutes, category: Categories.General },
    { filter: FilterGroupTypeEnum.ItemName, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobItemStatus, category: Categories.General },
    { filter: FilterGroupTypeEnum.JobPhaseName, category: Categories.General },
    { filter: FilterGroupTypeEnum.Time, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.LoggedTimeStatus,
      category: Categories.General,
    },
    { filter: FilterGroupTypeEnum.UserBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.RoleId, category: Categories.General },
    {
      filter: FilterGroupTypeEnum.LoggedTimePersonal,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedMinutes,
      category: Categories.General,
    },
    {
      filter: FilterGroupTypeEnum.JobItemUserUsedExceedsPlannedDollars,
      category: Categories.General,
    },
    // Dates
    { filter: FilterGroupTypeEnum.JobCreatedDate, category: Categories.Dates },
    {
      filter: FilterGroupTypeEnum.JobCompletedDate,
      category: Categories.Dates,
    },
    { filter: FilterGroupTypeEnum.JobStartDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.JobDueDate, category: Categories.Dates },
    { filter: FilterGroupTypeEnum.Date, category: Categories.Dates },
    // Financial
    { filter: FilterGroupTypeEnum.Amount, category: Categories.Financial },
    {
      filter: FilterGroupTypeEnum.BilledStatus,
      category: Categories.Financial,
    },
    { filter: FilterGroupTypeEnum.Billable, category: Categories.Financial },
    // deprecated
    {
      filter: FilterGroupTypeEnum.JobBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserRoleName,
      deprecated: true,
    },
  ],
  [EntityDescriptorEnum.User]: [
    // General
    { filter: FilterGroupTypeEnum.TeamMember, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserBranchId, category: Categories.General },
    { filter: FilterGroupTypeEnum.UserLabel, category: Categories.General },
    { filter: FilterGroupTypeEnum.RoleId, category: Categories.General },
    // deprecated
    {
      filter: FilterGroupTypeEnum.UserBranchName,
      deprecated: true,
    },
    {
      filter: FilterGroupTypeEnum.UserRoleName,
      deprecated: true,
    },
  ],
};

export const ReportingFiltersDefaultDescriptionsOverride: Partial<
  Record<TFilterGroupTypeEnum, { negated?: boolean; value: string }>
> = {
  [FilterGroupTypeEnum.JobStatus]: { value: "All Jobs" },
};

const searchableReportingFilters = mapValues(reportingFilters, (grouped) => {
  return grouped.map((group) => group.filter);
});

export function getSearchableReportingFilters(
  entityDescriptor: TReportingEntityDescriptor
) {
  return searchableReportingFilters[entityDescriptor];
}

export function getGlobalReportingFilterSearchOptions(
  entityDescriptors: TReportingEntityDescriptor[]
) {
  let filters: ReportingFilters = [];

  // User Availibility Descriptor is excluded from the interesection
  if (
    entityDescriptors.length === 1 &&
    entityDescriptors[0] === EntityDescriptorEnum.User
  ) {
    filters = reportingFilters[entityDescriptors[0]];
  } else {
    const entityDescriptorFilters = entityDescriptors
      .filter(
        (entityDescriptor) => entityDescriptor !== EntityDescriptorEnum.User
      )
      .map((entityDescriptor) => {
        return reportingFilters[entityDescriptor];
      });

    filters = intersectionBy(...entityDescriptorFilters, "filter");
  }

  return getSearchOptions(filters);
}

export function getReportingFilterSearchOptions(
  entityDescriptor: TReportingEntityDescriptor
) {
  return getSearchOptions(reportingFilters[entityDescriptor]);
}

function getSearchOptions(
  filters: ReportingFilters
): Record<string, FilterSearchOption[]> {
  let options: Record<string, FilterSearchOption[]> = {};

  filters.forEach((filter) => {
    if (filter.deprecated) {
      return;
    } else {
      if (!options[filter.category]) {
        options[filter.category] = [];
      }
      options[filter.category].push({
        key: filter.filter,
        searchString: `${FilterGroupTypeProperties[filter.filter].name} ${filter.category}`,
        value: FilterGroupTypeProperties[filter.filter].name,
        entity: FilterGroupTypeProperties[filter.filter],
      });
    }
  });

  options = mapValues(options, (grouped) => {
    return grouped.sort(defaultSortOptions);
  });

  return options;
}
