import { all } from "redux-saga/effects";

import watchJobItemRoleAssignUsers from "./sagas/watchJobItemRoleAssignUsers";
import watchJobItemRoleCreate from "./sagas/watchJobItemRoleCreate";
import watchJobItemRoleDelete from "./sagas/watchJobItemRoleDelete";
import watchJobItemRoleSetHours from "./sagas/watchJobItemRoleSetHours";
import watchJobItemRoleSetRate from "./sagas/watchJobItemRoleSetRate";

export default function* jobItemRoleSaga() {
  yield all([
    watchJobItemRoleCreate(),
    watchJobItemRoleSetHours(),
    watchJobItemRoleSetRate(),
    watchJobItemRoleAssignUsers(),
    watchJobItemRoleDelete(),
  ]);
}
