import { connect } from "react-redux";
import {
  selectQuoteLineItemById,
  selectSubTotalByQuoteIdQuoteLineItemId
} from "../../../redux/selectors/quoteLineItemSelectors";
import DocumentHeadingSubtotal from "../../modules/CommercialDocuments/DocumentLineItems/DocumentHeadingSubtotal";

const mapState = (state, props) => ({
  documentLineItem: selectQuoteLineItemById(state, props),
  subTotal: selectSubTotalByQuoteIdQuoteLineItemId(state, props)
});

export default connect(mapState)(DocumentHeadingSubtotal);
