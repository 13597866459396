import * as PropTypes from "prop-types";
import React from "react";
import { BRIDGE_DISTANCE } from "../../../../lib/constants";
import { jobItemDependancySegmentType } from "../../../../lib/entities/jobItemDependancyEntity";
import { SCROLL_CONTEXTS } from "../../ScrollContexts";
import { BridgeLeft } from "./SegmentParts/Bridge";
import SegmentWrapper from "./SegmentParts/SegmentWrapper";

const SegmentBridgeLeft = ({ Context, jobItemDependancySegment }) => (
  <Context.Consumer>
    {({ viewportOffsetX, viewportOffsetRightX, getOffsetXAtDate }) => {
      const {
        bridgeDate,
        isConflict,
        isSelected,
        isFloat
      } = jobItemDependancySegment;
      const left = getOffsetXAtDate(bridgeDate) - BRIDGE_DISTANCE;
      const right = left + BRIDGE_DISTANCE;
      const width = right - left;

      if (left > viewportOffsetRightX) return null;
      if (right < viewportOffsetX) return null;

      const modifiers = {
        conflict: isConflict,
        selected: isSelected,
        float: isFloat
      };

      return (
        <SegmentWrapper style={{ left, width }}>
          <BridgeLeft {...modifiers} />
        </SegmentWrapper>
      );
    }}
  </Context.Consumer>
);

SegmentBridgeLeft.propTypes = {
  Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
  jobItemDependancySegment: jobItemDependancySegmentType.isRequired
};

export default SegmentBridgeLeft;
