import { useSelector } from "react-redux";
import { costingMethodObj } from "st-shared/entities";

import { AppState } from "../../../AppState";
import { selectMasterJobItem } from "../../masterJobItem/selectors/selectMasterJobItem";
import { selectStateItemRates } from "./selectStateItemRates";

export function selectMasterJobItemRate(
  state: AppState,
  props: { masterJobItemId: number; rateCardId: number }
) {
  if (!props.masterJobItemId) {
    return 0;
  }

  const itemRates = selectStateItemRates(state);
  const masterJobItem = selectMasterJobItem(state, {
    id: props.masterJobItemId,
  });

  if (!masterJobItem) {
    return 0;
  }

  if (costingMethodObj(masterJobItem.costingMethod).isPeople()) {
    return 0;
  }

  return (
    itemRates.masterJobItemRates[
      `${props.masterJobItemId}.${props.rateCardId}`
    ] || 0
  );
}

export function useMasterJobItemRate(
  masterJobItemId: number,
  rateCardId: number
) {
  return useSelector((state: AppState) =>
    selectMasterJobItemRate(state, { masterJobItemId, rateCardId })
  );
}
