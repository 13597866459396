import { IconBase, IconBaseProps } from "react-icons";

export function StActionDuplicateOutline(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "0 0 24 24" }} {...props}>
      <g>
        <path d="m2 2v18h2v-16h16v-2z" />
        <path d="m13 15h-3v-2h3v-3h2v3h3v2h-3v3h-1.9474z" />
        <path
          clipRule="evenodd"
          d="m22 6h-16v16h16zm-14 2h12v12h-12z"
          fillRule="evenodd"
        />
      </g>
    </IconBase>
  );
}
