import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  JOB_ITEM_DELETE_CONFIRM,
  JOB_ITEM_DUPLICATE,
  JOB_ITEM_SET_IS_BILLABLE,
} from "../../../lib/constants";
import { feToWebViewSchedule } from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useIsSavingJobItem } from "../../../redux/selectors/jobItem/ui/isSaving";
import { IconButton } from "../../elements/Button";
import BillableIcon from "../../elements/Icons/BillableIcon";
import ScheduleIcon from "../../elements/Icons/custom/ScheduleIcon";
import DeleteIcon from "../../elements/Icons/DeleteIcon";
import DuplicateIcon from "../../elements/Icons/DuplicateIcon";
import KebabIcon from "../../elements/Icons/KebabIcon";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";

const ItemCardActions = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const jobItem = useJobItem(jobItemId);
  const isSaving = useIsSavingJobItem(jobItemId);

  const menuRef = useRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const doJobItemToggleBillable = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SET_IS_BILLABLE, {
        jobItemId,
        value: !jobItem.isBillable,
      })
    );
  };

  const openJobActionsMenu = (e) => {
    setMenuAnchorEl(menuRef.current);
  };

  const closeJobActionsMenu = () => {
    setMenuAnchorEl(null);
  };

  const deleteItem = () => {
    closeJobActionsMenu();
    if (!isJobEditable) return;
    dispatch(createAction(JOB_ITEM_DELETE_CONFIRM, { jobItem }));
  };

  const toggleBillableStatus = () => {
    closeJobActionsMenu();
    if (!isJobEditable) return;
    doJobItemToggleBillable();
  };

  const duplicateItem = () => {
    closeJobActionsMenu();
    if (!isJobEditable) return;
    dispatch(createAction(JOB_ITEM_DUPLICATE, { jobItemId }));
  };

  const viewSchedule = () => {
    closeJobActionsMenu();
    if (!isJobEditable) return;
    feToWebViewSchedule([jobId]);
  };

  return (
    <Container>
      <ActionsMenuButton ref={menuRef}>
        <StyledIconButton
          tabIndex={-1}
          onClick={openJobActionsMenu}
          disabled={isSaving}
        >
          <KebabIcon />
        </StyledIconButton>
      </ActionsMenuButton>
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeJobActionsMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledMenuItem onClick={toggleBillableStatus}>
          <StyledListItemIcon>
            {jobItem.isBillable && (
              <NonBillableIcon size={ICON_SIZE.X_LARGE}></NonBillableIcon>
            )}
            {!jobItem.isBillable && (
              <BillableIcon size={ICON_SIZE.X_LARGE}></BillableIcon>
            )}
          </StyledListItemIcon>
          <ListItemText>
            Mark as {jobItem.isBillable ? "non-billable" : "billable"}
          </ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={duplicateItem}>
          <StyledListItemIcon>
            <DuplicateIcon size={ICON_SIZE.X_LARGE} />
          </StyledListItemIcon>
          <ListItemText>Duplicate item</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={deleteItem}>
          <StyledListItemIcon>
            <DeleteIcon size={ICON_SIZE.X_LARGE} />
          </StyledListItemIcon>
          <ListItemText>Delete item</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem onClick={viewSchedule}>
          <StyledListItemIcon>
            <ScheduleIcon size={ICON_SIZE.MEDIUM} />
          </StyledListItemIcon>
          <ListItemText>View schedule</ListItemText>
        </StyledMenuItem>
      </Menu>
    </Container>
  );
};

export default ItemCardActions;

const Container = styled.div`
  position: absolute;
  right: -32px;
`;

const ActionsMenuButton = styled.div`
  padding-left: 2px;
`;

const StyledIconButton = styled(IconButton).attrs({
  className: "ActionsButton",
})`
  height: 40px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 12px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`;
