import { get } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_LOGGED_EXPENSES
} from "../../lib/constants";
import {
  getBranchId,
  getJobCompany,
  getJobNumber,
  getRateCardId
} from "../../lib/entities/jobEntity";
import { getExchangeRate, getJobId } from "../../lib/ui/commercialDocument";
import { selectJobById } from "./jobSelectors";
import {
  selectPropsId,
  selectPropsJobId,
  selectPropsLoggedExpenseId
} from "./index";

export const selectLoggedExpenses = state =>
  state.entities[ENTITY_NAME_LOGGED_EXPENSES];

export const selectLoggedExpenseById = createSelector(
  selectLoggedExpenses,
  selectPropsId,
  (jobItems, jobItemId) => get(jobItems, `byId[${jobItemId}]`)
);

export const selectLoggedExpenseByLoggedExpenseId = (state, props) =>
  selectLoggedExpenseById(state, {
    id: selectPropsLoggedExpenseId(state, props)
  });

export const selectLoggedExpenseJobIdById = createSelector(
  selectLoggedExpenseById,
  getJobId
);

export const selectLoggedExpenseJobById = (state, props) =>
  selectJobById(state, { id: selectLoggedExpenseJobIdById(state, props) });

export const selectLoggedExpenseJobByLoggedExpenseId = (state, props) =>
  selectLoggedExpenseJobById(state, { id: props.loggedExpenseId });

export const selectLoggedExpenseJobNumberById = createSelector(
  selectLoggedExpenseJobById,
  getJobNumber
);

export const selectLoggedExpenseJobCompanyById = createSelector(
  selectLoggedExpenseJobById,
  getJobCompany
);

export const selectLoggedExpenseJobBranchIdById = createSelector(
  selectLoggedExpenseJobById,
  job => getBranchId(job)
);

export const selectLoggedExpenseJobRateCardIdById = createSelector(
  selectLoggedExpenseJobById,
  getRateCardId
);

export const selectLoggedExpenseJobRateCardIdByLoggedExpenseId = createSelector(
  selectLoggedExpenseJobByLoggedExpenseId,
  getRateCardId
);

export const selectLoggedExpenseJobExchangeRateByLoggedExpenseId = createSelector(
  selectLoggedExpenseJobByLoggedExpenseId,
  getExchangeRate
);

export const selectLoggedExpenseExchangeRateByLoggedExpenseId = createSelector(
  selectLoggedExpenseByLoggedExpenseId,
  getExchangeRate
);

export const selectLoggedExpenseIdsByJobId = createSelector(
  selectLoggedExpenses,
  selectPropsJobId,
  (loggedExpenses, jobId) =>
    get(loggedExpenses, `idsByJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectLoggedExpenseUi = state => state.ui.loggedExpense;

export const selectLoggedExpensesUi = state => state.ui.loggedExpenses;

export const selectLoggedExpensesUiById = createSelector(
  selectLoggedExpensesUi,
  selectPropsId,
  (loggedExpensesUi, id) => get(loggedExpensesUi, id, EMPTY_OBJECT)
);
