import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

import type { TEntityId } from "../../../entities/Entity";
import type { TSavedSegmentSidebarStore } from "../state/savedSegmentSidebarStore";

type OnRouteProp = {
  onRoute: (segmentId: TEntityId) => void;
  RouteComponent?: undefined;
};

type RouteComponentProp = {
  onRoute?: undefined;
  RouteComponent: (props: {
    segmentId: TEntityId;
    className?: string;
    children: ReactNode;
  }) => ReactNode;
};

export type SavedSegmentSidebarProps = {
  name: string;
  savedSegmentType: TSavedSegmentTypeEnum;
  currentSegmentId?: TEntityId;
  loggedInUserId?: TEntityId;
  onDeleteSavedSegment: (segmentId: TEntityId) => void;
} & (OnRouteProp | RouteComponentProp);

export type TSavedSegmentSidebarContext = SavedSegmentSidebarProps & {
  useStore: TSavedSegmentSidebarStore;
};

export const SavedSegmentSidebarContext =
  createContext<TSavedSegmentSidebarContext>(
    undefined as unknown as TSavedSegmentSidebarContext
  );

export function useSavedSegmentSidebarContext() {
  return useContext(SavedSegmentSidebarContext);
}
