import { expandDateToWeek } from "../../lib/dates";

export const selectPropsId = (state, props) => props.id;

export const selectPropsAccountCode = (state, props) => props.accountCode;

export const selectPropsBranchId = (state, props) => props.branchId;

export const selectPropsCompanyId = (state, props) => props.companyId;

export const selectPropsJobId = (state, props) => props.jobId;

export const selectPropsQuoteId = (state, props) => props.quoteId;

export const selectPropsInvoiceId = (state, props) => props.invoiceId;

export const selectPropsContactId = (state, props) => props.contactId;

export const selectPropsLoggedExpenseId = (state, props) =>
  props.loggedExpenseId;

export const selectPropsPurchaseOrderId = (state, props) =>
  props.purchaseOrderId;

export const selectPropsUserId = (state, props) => props.userId;

export const selectPropsJobItemId = (state, props) => props.jobItemId;

export const selectPropsJobItemUserId = (state, props) => props.jobItemUserId;

export const selectPropsJobPhaseId = (state, props) => props.jobPhaseId;

export const selectPropsJobItemSubItemId = (state, props) =>
  props.jobItemSubItemId;

export const selectPropsRoleId = (state, props) => props.roleId;

export const selectPropsMasterJobItemId = (state, props) =>
  props.masterJobItemId;

export const selectPropsMasterLoggedExpenseId = (state, props) =>
  props.masterLoggedExpenseId;

export const selectPropsRateCardId = (state, props) => props.rateCardId;

export const selectPropsInvoicePaymentId = (state, props) =>
  props.invoicePaymentId;

export const selectPropsJobMilestoneId = (state, props) => props.jobMilestoneId;

export const selectPropsDate = (state, props) => props.date;

export const selectPropsBlockKey = (state, props) => props.blockKey;

export const selectPropsBlockKeys = (state, props) => props.blockKeys;

export const selectPropsBlockFilterMode = (state, props) => props.filterMode;

export const selectPropsUserPreferenceKey = (state, props) =>
  props.userPreferenceKey;

export const createUserPreferenceProp = (key) => ({
  userPreferenceKey: key,
});

export const selectWeekDatesFromPropsDate = (state, props) =>
  expandDateToWeek(props.date);

export const selectPropsCurrencyId = (state, props) => props.currencyId;

export const selectPropsCombinerJobIdJobItemId = (state, props) =>
  `${props.jobId}-${props.jobItemId}`;

export const selectPropsCombinerJobIdJobPhaseId = (state, props) =>
  `${props.jobId}-${props.jobPhaseId}`;

export const selectPropsLabelId = (state, props) => props.labelId;

export const selectPropsEntityId = (state, props) => props.entityId;

export const selectPropsLabelTypeId = (state, props) => props.labelTypeId;

export const selectPropsCombinerEntityIdLabelTypeId = (state, props) =>
  `${props.entityId}-${props.labelTypeId}`;

export const selectPropsRepeatingLoggedTimeId = (state, props) =>
  props.repeatingLoggedTimeId;

export const keySelectorNullable = (keySelector) => (state, props) => {
  return keySelector(state, props) || "";
};
