import clsx from "clsx";
import { plate, slate } from "st-shared/external";

import { ELEMENT_COLUMN_INPUT } from "../plugins/column";
import { columnCss } from "./ColumnElement.css";

export const ColumnInputElement = plate.withRef<
  typeof plate.PlateElement,
  {
    onClick?: (columnNode: any) => void;
  }
>(({ className, onClick, ...props }, ref) => {
  const { children, element } = props;

  const selected = slate.useSelected();
  const focused = slate.useFocused();

  return (
    <plate.PlateElement
      ref={ref}
      asChild
      data-slate-value={element.value}
      id={ELEMENT_COLUMN_INPUT}
      className={clsx(columnCss, className)}
      data-selected={selected && focused}
      onClick={plate.getHandler(onClick, element)}
      {...props}
    >
      <span>{children}</span>
    </plate.PlateElement>
  );
});
