import { ENTITY_CLASS_QUOTE } from "../constants";
import { getQuoteAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const transformQuoteBootstrap = modelSet => {
  return createModelSetParser(ENTITY_CLASS_QUOTE)(modelSet);
};

export const fetchQuoteBootstrapAPI = quoteId =>
  API.fetch(`/quotes/${quoteId}`, { bootstrap: true })
    .then(res => res.data)
    .then(transformQuoteBootstrap);

export const saveQuoteAPI = quote =>
  API.put(`/quotes/${quote.id}`, getQuoteAsMessage(quote))
    .then(({ data }) => data)
    .then(createModelSetParser(ENTITY_CLASS_QUOTE))
    .then(({ data }) => data);
