import { produce } from "immer";
import { put, select } from "redux-saga/effects";
import { timeAllocationMethodObj } from "st-shared/entities";

import {
  JOB_ITEM_USER_DELETE,
  JOB_ITEM_USER_DELETE_ERROR,
  JOB_ITEM_USER_EDIT,
  JOB_ITEM_USER_SET_HOURS,
} from "../../../lib/constants";
import { actionJobItemRoleRecalculateHours } from "../../../state/entities/jobItem/actions";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";
import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";

function* setHours(action) {
  try {
    const { jobItemUserId, value } = action.payload;

    const jobItemUser = yield select(selectJobItemUserById, {
      id: jobItemUserId,
    });
    const newJobItemUser = produce(jobItemUser, (draft) => {
      draft.totalPlannedMinutes = convertFlexibleDurationToMinutes(value);
    });

    yield put(
      createAction(JOB_ITEM_USER_EDIT, {
        jobItemId: jobItemUser.jobItemId,
        jobItemUsers: [{ new: newJobItemUser, prev: jobItemUser }],
      })
    );

    yield put(actionJobItemRoleRecalculateHours(jobItemUser.jobItemId));
  } catch (error) {
    sagaError(error);
  }
}

function* checkSetItemHours(action) {
  const { jobItemUser } = action.payload;

  try {
    const jobItem = yield select(selectJobItemById, {
      id: jobItemUser.jobItemId,
    });

    if (timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()) return;

    yield put(actionJobItemRoleRecalculateHours(jobItemUser.jobItemId));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemUserSetHours() {
  yield takeLatestBy(
    [JOB_ITEM_USER_SET_HOURS],
    setHours,
    (action) => action.payload.jobItemUserId
  );
  yield takeLatestBy(
    [JOB_ITEM_USER_DELETE],
    checkSetItemHours,
    (action) => action.payload.jobItemUser.id
  );
  yield takeLatestBy(
    [JOB_ITEM_USER_DELETE_ERROR],
    checkSetItemHours,
    (action) => action.payload.jobItemUser.id
  );
}
