import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { quoteLineItemEntityType } from "../../../lib/entities/quoteLineItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../../lib/ui/accountingPlatform";
import {
  selectQuoteLineItemById,
  selectQuoteLineItemIsMergeParent,
  selectQuoteLineItemUiIsCreating,
  selectQuoteLineItemUiIsMerging
} from "../../../redux/selectors/quoteLineItemSelectors";
import LoadingOverlay from "../../elements/LoadingOverlay";
import DocumentLineDescription from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineDescription";
import DocumentLineLeftMargin from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineLeftMargin";
import DocumentLineName from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineName";
import DocumentLineQuantity from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineQuantity";
import DocumentLineRate from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineRate";
import DocumentLineRightMargin from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineRightMargin";
import DocumentLineTaxRate from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineTaxRate";
import DocumentLineTotal from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineTotal";

const mapState = (state, props) => ({
  quoteLineItem: selectQuoteLineItemById(state, props),
  isCreating: selectQuoteLineItemUiIsCreating(state, props),
  isChecked: selectQuoteLineItemUiIsMerging(state, props),
  isMergeParent: selectQuoteLineItemIsMergeParent(state, props)
});

const QuoteLineItem = ({
  id,
  quoteLineItem,
  isCreating,
  isMergeMode,
  isSortMode,
  isChecked,
  isMergeParent,
  displayLineItems,
  displayLineItemDescriptions,
  displayTaxNameOnLineItems,
  displayQuantities,
  displayUnitRates,
  displaySubtotals,
  displayCurrencySymbols,
  displayLineItemsInclusiveOfTax
}) => (
  <Wrapper className={isSortMode ? "sorting" : ""}>
    {isMergeMode && (
      <DocumentLineLeftMargin
        id={id}
        isChecked={isChecked}
        isMergeParent={isMergeParent}
      />
    )}
    <Table>
      <tbody>
        <tr>
          <td>
            <DocumentLineName
              documentLineItem={quoteLineItem}
              isHiddenField={!displayLineItems}
              isDisabled={isSortMode}
              isCreating={isCreating}
            />
          </td>
          <td>
            <DocumentLineTaxRate
              documentLineItem={quoteLineItem}
              isHiddenField={!displayLineItems || !displayTaxNameOnLineItems}
              isDisabled={isSortMode}
              accountType={ACCOUNTING_PLATFORM_ACCOUNT_TYPES.REVENUE}
            />
          </td>
          <td>
            <DocumentLineQuantity
              documentLineItem={quoteLineItem}
              isHiddenField={!displayLineItems || !displayQuantities}
              isDisabled={isSortMode}
            />
          </td>
          <td>
            <DocumentLineRate
              documentLineItem={quoteLineItem}
              isHiddenField={!displayLineItems || !displayUnitRates}
              isDisabled={isSortMode}
              displayCurrencySymbols={displayCurrencySymbols}
            />
          </td>
          <td>
            <DocumentLineTotal
              documentLineItem={quoteLineItem}
              isHiddenField={!displayLineItems || !displaySubtotals}
              isDisabled={isSortMode}
              displayLineItemsInclusiveOfTax={displayLineItemsInclusiveOfTax}
              displayCurrencySymbols={displayCurrencySymbols}
            />
          </td>
        </tr>
        {displayLineItems && displayLineItemDescriptions && (
          <tr>
            <td colSpan={2}>
              <DocumentLineDescription
                isDisabled={isSortMode}
                documentLineItem={quoteLineItem}
                placeholder="Add a description..."
              />
            </td>
            <td />
            <td />
            <td />
          </tr>
        )}
      </tbody>
    </Table>
    {!isCreating && !isSortMode && (
      <DocumentLineRightMargin documentLineItem={quoteLineItem} />
    )}
    {isCreating && <LoadingOverlay />}
  </Wrapper>
);

QuoteLineItem.propTypes = {
  id: entityIdType.isRequired,
  quoteLineItem: quoteLineItemEntityType.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isMergeMode: PropTypes.bool.isRequired,
  isSortMode: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isMergeParent: PropTypes.bool.isRequired,
  displayLineItems: PropTypes.bool.isRequired,
  displayLineItemDescriptions: PropTypes.bool.isRequired,
  displayTaxNameOnLineItems: PropTypes.bool.isRequired,
  displayQuantities: PropTypes.bool.isRequired,
  displayUnitRates: PropTypes.bool.isRequired,
  displaySubtotals: PropTypes.bool.isRequired,
  displayCurrencySymbols: PropTypes.bool.isRequired,
  displayLineItemsInclusiveOfTax: PropTypes.bool.isRequired
};

export default connect(mapState)(QuoteLineItem);

const Wrapper = styled.div.attrs({
  className: "QuoteLineItem"
})`
  position: relative;
  width: 100%;
  &.sorting {
    background-color: white;
    border: 1px dashed var(--color-gray-medium);
    padding-top: 5px;
  }
  &:hover {
    .DocumentLineDeleteButton {
      opacity: 1;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  line-height: 0;
  td {
    min-width: 70px;
    height: 26px;
    vertical-align: top;
    border: 0 solid transparent;
    background-clip: padding-box;
    &:first-child {
      width: 100%;
    }
    &:last-child {
      min-width: 100px;
    }
  }
  tr > td + td {
    border-left-width: 2px;
  }

  tr + tr > td {
    border-top-width: 2px;
  }
`;
