import { useDroppable } from "@dnd-kit/core";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

import { DropTypes, SortData, SortTypes } from "../types";
import { folderWrapperCss, folderWrapperOverCss } from "./Folder.css";

type Props = {
  children?: ReactNode;
};

export function SegmentsDroppable({ children }: Props) {
  const { setNodeRef, isOver, active } = useDroppable({
    id: DropTypes.SegmentsDroppable,
    data: {
      type: DropTypes.SegmentsDroppable,
    },
  });

  const isFolderSegmentOver = useMemo(() => {
    if (!isOver || !active) return false;
    const activeData = active?.data as SortData;
    return (
      activeData.current?.type === SortTypes.FolderSegment ||
      activeData.current?.type === SortTypes.UncategorisedSegment
    );
  }, [isOver, active]);

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        folderWrapperCss,
        isFolderSegmentOver && folderWrapperOverCss
      )}
    >
      {children}
    </div>
  );
}
