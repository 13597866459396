import { theme } from "st-shared/theme";

import { RoleOption } from "../UserMenus/RoleOption";
import { roleOptionCss } from "./RoleResults.css";

type RoleResultsProps = {
  results: any[];
  onPickRoles: (roleIds: number[]) => void;
  roleIds: number[];
  setRoleIds: (roleIds: number[]) => void;
  type: "jobItem" | "jobPhase" | "job";
  onSetRoleIds?: (roleId: number) => void;
};

function RoleResults({
  results,
  onPickRoles,
  onSetRoleIds,
  roleIds,
  type,
}: RoleResultsProps) {
  function addOrRemoveRole(roleId: number) {
    if (type === "jobPhase" || type === "job") {
      onSetRoleIds?.(roleId);
    } else {
      onPickRoles([roleId]);
    }
  }

  const roleIdsSet = new Set(roleIds);

  return (
    <>
      {results.map((result: any) => {
        const isSelected = roleIdsSet.has(result.key);

        return (
          <RoleOption
            className={roleOptionCss}
            key={result.key}
            roleId={result.key}
            onPickRoles={addOrRemoveRole}
            isSelected={isSelected}
            style={{
              backgroundColor: isSelected ? theme.color.graylight : "",
            }}
          />
        );
      })}
    </>
  );
}

export default RoleResults;
