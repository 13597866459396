import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const AddHeadingIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M7,12l-2,0l0,2H4v-2H2v-1l2,0l0-2h1l0,2l2,0V12z M17.4,7.3V19h-2.9V7.3H10V5h12v2.4H17.4z" />
  </SvgIcon>
);

AddHeadingIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

AddHeadingIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(AddHeadingIcon);
