import { orderBy } from "lodash-es";

export function getOrderedMap<
  T extends {
    id: string;
    orderId: number;
  },
>(map: Record<string, T>) {
  return orderBy(map, "orderId");
}
