import { MdFavorite, MdFilterList, StColumns } from "st-shared/components";
import { Sidebar } from "st-shared/module";

import {
  ReportingSidebarTabs,
  TReportingSidebarTabs,
} from "../../consts/ReportingSidebarTabs";
import {
  setReportingSideMenuOpen,
  useReportingSideMenuOpen,
} from "../../state/userPreferences/reportingSideMenuOpen";
import {
  setReportingSideMenuSelectedTab,
  useReportingSideMenuSelectedTab,
} from "../../state/userPreferences/reportingSideSelectedTab.ts";
import { Columns } from "./columns/Columns.tsx";
import { DataSets } from "./datasets/DataSets";
import { Favourites } from "./favourites/Favourites";

export function ReportingSidebar() {
  const open = useReportingSideMenuOpen();
  const selectedTab = useReportingSideMenuSelectedTab();

  function setOpen(open: boolean) {
    setReportingSideMenuOpen(open);
  }

  function setSelectedTab(tab: TReportingSidebarTabs) {
    setReportingSideMenuSelectedTab(tab);
  }

  return (
    <Sidebar
      open={open}
      setOpen={setOpen}
      tabs={Object.values(ReportingSidebarTabs)}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      getTabIcon={(tab) => {
        switch (tab) {
          case ReportingSidebarTabs.Favourites:
            return MdFavorite;
          case ReportingSidebarTabs.Datasets:
            return MdFilterList;
          case ReportingSidebarTabs.Columns:
            return StColumns;
        }
      }}
    >
      {(tab) => {
        switch (tab) {
          case ReportingSidebarTabs.Favourites:
            return <Favourites />;
          case ReportingSidebarTabs.Datasets:
            return <DataSets />;
          case ReportingSidebarTabs.Columns:
            return <Columns />;
        }
      }}
    </Sidebar>
  );
}
