import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { LABELS_CREATE, LABELS_DELETE } from "../../../lib/constants";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { useLabelIdsByEntityIdLabelTypeId } from "../../../redux/selectors/labels/selectLabelIdsByEntityIdLabelTypeId";
import { useLabelNamesByEntityIdLabelTypeId } from "../../../redux/selectors/labels/selectLabelNamesByEntityIdLabelTypeId";
import ActiveLabel from "./ActiveLabel";
import AddNewLabel from "./AddNewLabel";
import { getLabelId } from "./styles";

const Labels = ({ entityId, labelTypeId, readOnly }) => {
  const labelIds = useLabelIdsByEntityIdLabelTypeId(entityId, labelTypeId);
  const labelNames = useLabelNamesByEntityIdLabelTypeId(entityId, labelTypeId);
  const dispatch = useDispatch();

  const onDelete = (labelId) => {
    if (readOnly) return;
    dispatch(createAction(LABELS_DELETE, { labelId }));
  };

  const onCreate = (value) => {
    if (readOnly) return;

    // check if already in the list of values
    for (let i = 0; i < labelNames.length; i += 1) {
      if (labelNames[i] === value) {
        const activeLabel = document.querySelector(
          `#${getLabelId(labelIds[i])}`
        );
        if (activeLabel !== null) {
          activeLabel.classList.remove("bouncing");
          requestAnimationFrame(() => {
            activeLabel.classList.add("bouncing");
          });
        }
        return;
      }
    }

    dispatch(createAction(LABELS_CREATE, { entityId, labelTypeId, value }));
  };

  return (
    <Container id="Labels">
      {labelIds.map((labelId) => (
        <ActiveLabel
          key={labelId}
          labelId={labelId}
          readOnly={readOnly}
          onDelete={onDelete}
        />
      ))}
      {!readOnly && (
        <AddNewLabel
          entityId={entityId}
          labelTypeId={labelTypeId}
          onCreate={onCreate}
        />
      )}
    </Container>
  );
};

Labels.propTypes = {
  entityId: entityIdType.isRequired,
  labelTypeId: entityIdType.isRequired,
  readOnly: PropTypes.bool,
};

Labels.defaultProps = {
  readOnly: false,
};

export default Labels;

const Container = styled.div`
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;
