import numbro from "numbro";

export function num(value?: any) {
  return numbro(value || 0);
}

export function numValue(value: any) {
  return num(value).value();
}

// TODO change these to NUMBRO objects
export const NumFormats: { [key: string]: string | numbro.Format } = {
  FullDefault: "0,0.00",
  OptionalCents: "0,0[.]00",
  OptionalDecimal: "0,0[.]00",
  NoDecimal: "0,0",
  Abbreviated: "0[.]0a",
  TwoDecimals: "0.[00]",
  FourDecimals: "0.[0000]",
  SixDecimals: "0.[000000]",
  Percentage: "0.[0]%",
  OptionalMantissa: { optionalMantissa: true },
} as const;

export type TNumFormats = string | numbro.Format;
