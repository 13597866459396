import { useOrderedJobItemRoleIdsByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectOrderedJobItemRoleIdsByJobItemId";
import { useJobItemId } from "../context/JobItemIdContext";
import { JobItemRoleRow } from "./JobItemRoleRow";
import * as styles from "./JobItemRoles.css";

export function JobItemRoles() {
  const jobItemId = useJobItemId();
  const jobItemRoleIds = useOrderedJobItemRoleIdsByJobItemId(jobItemId);

  if (jobItemRoleIds.length === 0) return null;

  return (
    <div className={styles.container}>
      {jobItemRoleIds.map((jobItemRoleId) => {
        return (
          <JobItemRoleRow key={jobItemRoleId} jobItemRoleId={jobItemRoleId} />
        );
      })}
    </div>
  );
}
