import type { TEntityId, TOrganisation, TUser } from "@streamtimefe/entities";

type TSessionUser = {
  userId: TEntityId;
  name: string;
};

type TSessionOrganisation = {
  organisationId: TEntityId;
  name: string;
};

export let sessionUser: TSessionUser | null = null;
export let sessionOrganisation: TSessionOrganisation | null = null;

export function bugsnagSetSessionUser(user: TUser) {
  sessionUser = {
    userId: user.id,
    name: user.displayName ?? "",
  };
}

export function bugsnagSetSessionOrganisation(organisation: TOrganisation) {
  sessionOrganisation = {
    organisationId: organisation.id,
    name: organisation.name ?? "",
  };
}
