declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FE: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FE: any = {};

try {
  if (window) {
    window.FE = FE;
    if (window.top && window !== window.top) {
      window.top.FE = window.FE;
    }
  }
} catch (_) {
  /* empty */
}

export function addToConsole(path: string | string[], value: unknown) {
  if (typeof path === "string") {
    FE[path] = value;
  } else {
    let current = FE;
    path.forEach((key, index) => {
      if (!(key in current)) {
        current[key] = {};
      }
      if (index < path.length - 1) {
        current = current[key];
      } else {
        current[key] = value;
      }
    });
  }
}
