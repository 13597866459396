import { ENTITY_CLASS_JOB } from "../constants";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { getJobAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";

export const transformJobBootstrap = (response) => {
  const { modelSet, unplannedTimeSummary, historicalSummary } = response;
  const { data } = createModelSetParser(ENTITY_CLASS_JOB)(modelSet);
  return {
    unplannedTimeSummary,
    historicalSummary,
    data,
    primaryEntity: modelSet.__primaryModel,
  };
};

export const fetchJobBootstrapAPI = (jobId, sendToWeb = false) =>
  API.fetch(`/jobs/${jobId}`, { bootstrap: true })
    .then((res) => res.data)
    .then((data) => {
      if (sendToWeb) {
        sendApplyModelData(ENTITY_CLASS_JOB, data.modelSet);
      }
      return data;
    })
    .then(transformJobBootstrap);

export const updateJobAPI = (job) =>
  API.put(`/jobs/${job.id}`, getJobAsMessage(job))
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB));

export const updateJobStatusAPI = (jobId, jobStatusId) =>
  API.put(`/jobs/${jobId}/job_status?job_status_id=${jobStatusId}`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB));

export const fetchJobNumberCheck = (jobId, number, abortController) =>
  API.fetch(
    "/jobs/number_check",
    { jobId, number },
    { signal: abortController.signal }
  );

export const fetchJobHistoricalSummaryAPI = (jobId) =>
  API.fetch(`/jobs/${jobId}/historical_data_summary`).then(
    (response) => response.data
  );

export const fetchJobUnplannedTimeSummaryAPI = (jobId) =>
  API.fetch(`/jobs/${jobId}/unplanned_time`).then((response) => response.data);
