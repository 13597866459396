import { useSelector } from "react-redux";
import { costingMethodObj, timeAllocationMethodObj } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import {
  getJobId,
  getTotalUsedMinutes,
  hasSellRate,
} from "../../../lib/entities/jobItemEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectJobItem } from "../jobItem";
import { selectJobItemUser } from "./index";
import { selectJobItemUserCalculatedPlannedCost } from "./selectJobItemUserCalculatedPlannedCost";
import { selectJobItemUserCalculatedTotalLoggedTimeExTax } from "./selectJobItemUserCalculatedTotalLoggedTimeExTax";

export const selectJobItemUserTotal = (state, { jobItemUserId }) => {
  const jobItemUser = selectJobItemUser(state, { jobItemUserId });
  const jobItem = selectJobItem(state, { jobItemId: jobItemUser.jobItemId });
  const customerCurrency = selectCustomerCurrency(state);
  const jobCurrency = selectJobCurrency(state, { jobId: getJobId(jobItem) });

  if (timeAllocationMethodObj(jobItem.timeAllocationMethod).isPeople()) {
    const calculatedPlannedCost = selectJobItemUserCalculatedPlannedCost(
      state,
      { jobItemUserId: jobItemUser.id }
    );

    const isFallbackRate =
      costingMethodObj(jobItem.costingMethod).isItem() && !hasSellRate(jobItem);

    let value = formatForeignCurrency(customerCurrency, {
      value: isFallbackRate ? 0 : calculatedPlannedCost,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });

    if (getTotalUsedMinutes(jobItem) > 0) {
      const calculatedTotalLoggedTimeExTax =
        selectJobItemUserCalculatedTotalLoggedTimeExTax(state, {
          jobItemId: jobItemUser.jobItemId,
          jobItemUserId: jobItemUser.id,
        });
      const formattedCalculatedTotalLoggedTimeExTax = formatForeignCurrency(
        customerCurrency,
        {
          value: calculatedTotalLoggedTimeExTax,
          currency: jobCurrency,
          hideLongSymbols: true,
          format: NUMBER_FORMAT_NO_DECIMAL,
        }
      );

      value = `${formattedCalculatedTotalLoggedTimeExTax} / ${value}`;
    }

    return value;
  }

  return "";
};

export const useJobItemUserTotal = (jobItemUserId) =>
  useSelector((state) => selectJobItemUserTotal(state, { jobItemUserId }));
