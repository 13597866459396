import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobItemName,
  getMetaDataJobItemStatusId
} from "../../../../../lib/entities/activityEntryEntity";
import { getJobItemStatusName } from "../../../../../lib/entities/jobItemEntity";
import SystemMessage from "./SystemMessage";

const JobItemStatusChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" marked "}
    <b>{getMetaDataJobItemName(activityEntry)}</b>
    {" as "}
    <b>{getJobItemStatusName(getMetaDataJobItemStatusId(activityEntry))}</b>
  </SystemMessage>
);

JobItemStatusChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemStatusChanged;
