import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const NonBillableIcon = ({ ...props }) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path d="M8.28292 4.6C9.46958 4.6 9.90958 5.16667 9.94958 6H11.4229C11.3763 4.85333 10.6763 3.8 9.28292 3.46V2H7.28292V3.44C7.02292 3.49333 6.78292 3.58 6.54958 3.68L7.55625 4.68667C7.76958 4.63333 8.01625 4.6 8.28292 4.6ZM3.59625 2.61333L2.65625 3.55333L4.94958 5.84667C4.94958 7.23333 5.98958 7.99333 7.55625 8.45333L9.89625 10.7933C9.66958 11.12 9.19625 11.4 8.28292 11.4C6.90958 11.4 6.36958 10.7867 6.29625 10H4.82958C4.90958 11.46 6.00292 12.28 7.28292 12.5533V14H9.28292V12.5667C9.92292 12.4467 10.5029 12.2 10.9229 11.82L12.4029 13.3L13.3429 12.36L3.59625 2.61333Z" />
  </SvgIcon>
);

NonBillableIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

NonBillableIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(NonBillableIcon);
