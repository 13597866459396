import type {
  TFilterGroupTypeEnum,
  TStatisticFieldEnum,
  TStatisticModeEnum,
} from "@streamtimefe/entities";

import type { TReportingEntityDescriptor } from "../../entities";
import type { FilterGroup } from "../../module";
import type { SeriesData, TConditionMatchType } from "../../types";
import { API } from "../api";
import type { ApiConfig } from "../api/APIController";
import { WebApiRoot } from "./webApiRoot";

export const ReportingRoot = WebApiRoot + "/reports";

export function searchTimeSeries(
  data: ReportingSearchData,
  config?: ApiConfig
) {
  return API.post<{
    results: SeriesData[];
    seriesTotals: SeriesData["data"];
    bucketStartDate: string;
    bucketEndDate: string;
    bucketKeys: Record<string, string>;
  }>(ReportingRoot + "/search/time_series", data, undefined, config);
}

export function searchColumnSeries(
  data: ReportingSearchData,
  config?: ApiConfig
) {
  return API.post<{ results: SeriesData[]; seriesTotals: SeriesData["data"] }>(
    ReportingRoot + "/search/column_series",
    data,
    undefined,
    config
  );
}

export type SearchSeriesApiReturn = Awaited<
  ReturnType<ReturnType<typeof getReportingSearchSeriesApi>>
>;

export function getReportingSearchSeriesApi(searchType: TReportingSearchType) {
  switch (searchType) {
    case ReportingSearchType.TimeSeries:
      return searchTimeSeries;
    case ReportingSearchType.ColumnSeries:
      return searchColumnSeries;
  }
}

export const ReportingSearchType = {
  TimeSeries: 1,
  ColumnSeries: 2,
} as const;

export type TReportingSearchType =
  (typeof ReportingSearchType)[keyof typeof ReportingSearchType];

export type FilterGroupCollection = {
  conditionMatchTypeId: TConditionMatchType;
  filterGroups?: FilterGroup[];
  filterGroupCollections?: FilterGroupCollection[];
};

export type ReportingSearchData = {
  startDate: string | null;
  endDate: string | null;
  groupedFilterGroupTypeId: TFilterGroupTypeEnum | null;
  subGroupedFilterGroupTypeId?: TFilterGroupTypeEnum;
  dataSets: {
    entityDescriptorId: TReportingEntityDescriptor;
    dateFilterGroupTypeId: TFilterGroupTypeEnum;
    statisticFields: {
      modeId: TStatisticModeEnum;
      columnId: TStatisticFieldEnum;
    }[];
    filterGroupCollection: FilterGroupCollection;
  }[];
};
