import { get } from "lodash-es";
import { DataException } from "st-shared/lib";

import { ENTITY_NAME_JOB_ITEMS } from "../../../../lib/constants";
import {
  getJobPhaseId,
  isDeleted,
} from "../../../../lib/entities/jobItemEntity";
import parseEntityPayload from "../../../helpers/parseEntityPayload";

export default (state, action) => {
  parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEMS],
    isDeleted
  ).forEach(({ newEntity }) => {
    const jobPhaseId = newEntity && getJobPhaseId(newEntity);
    const jobPhase =
      jobPhaseId && get(state, `entities.jobPhases.byId.${jobPhaseId}`);

    if (jobPhaseId && !jobPhase)
      throw new DataException(
        `JobPhase (${jobPhaseId}) for JobItem (${newEntity.id}) not found`
      );
  });
};
