import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_BAR_DRAG_END,
  SCHEDULE_JOB_BAR_DRAG_START
} from "../../../lib/constants";
import { differenceInCalendarDays } from "../../../lib/dates";
import {
  getElementClientOffset,
  getElementOffset,
  getPointerClientOffset,
  isDisabledElement,
  subtractCoords
} from "../../../lib/dom";
import {
  getTargetJobBarElement,
  getTargetJobBarId
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";

const mapState = null;

const mapDispatch = dispatch => ({
  doStartDragJobBar: payload =>
    dispatch(createAction(SCHEDULE_JOB_BAR_DRAG_START, payload)),

  doDragJobBar: payload =>
    dispatch(createAction(SCHEDULE_JOB_BAR_DRAG, payload)),

  doEndDragJobBar: payload =>
    dispatch(createAction(SCHEDULE_JOB_BAR_DRAG_END, payload)),

  doCancelDragJobBar: payload =>
    dispatch(createAction(SCHEDULE_JOB_BAR_DRAG_CANCEL, payload))
});

export default WrappedComponent => {
  class JobBarDragHandlers extends React.PureComponent {
    dragItem = null;

    isDragging = false;

    static propTypes = {
      canEditJobs: PropTypes.bool.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      doStartDragJobBar: PropTypes.func.isRequired,
      doDragJobBar: PropTypes.func.isRequired,
      doEndDragJobBar: PropTypes.func.isRequired,
      doCancelDragJobBar: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      getScrollOffsetX: PropTypes.func.isRequired,
      getDateAtOffsetX: PropTypes.func.isRequired,
      className: PropTypes.string
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: ""
    };

    getPointerDistance(e) {
      const { origin } = this.dragItem;
      const pointerPosition = getPointerClientOffset(e);
      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getMovedPosition(e) {
      const { origin } = this.dragItem;
      const pointerDistance = this.getPointerDistance(e);
      return {
        x: origin.barPosition.x + pointerDistance.x,
        y: origin.barPosition.y + pointerDistance.y
      };
    }

    getTargetDate(e) {
      const { getScrollOffsetX, getDateAtOffsetX } = this.props;
      const { origin } = this.dragItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const newBarOffsetX =
        origin.barOffset.x + pointerDistance.x + scrollDistanceX;

      return getDateAtOffsetX(newBarOffsetX);
    }

    onPointerDown = e => {
      const {
        getScrollOffsetX,
        getDateAtOffsetX,
        canEditJobs,
        isSplitting,
        onPointerDown,
        doStartDragJobBar
      } = this.props;
      const jobId = getTargetJobBarId(e);
      const jobBarNode = jobId && getTargetJobBarElement(e);
      const isDisabled = jobBarNode && isDisabledElement(jobBarNode);

      if (canEditJobs && !isSplitting && jobId && !isDisabled) {
        const barOffset = getElementOffset(jobBarNode);
        const barPosition = getElementClientOffset(jobBarNode);
        const pointerPosition = getPointerClientOffset(e);
        const date = getDateAtOffsetX(barOffset.x);
        const scrollOffsetX = getScrollOffsetX();

        this.dragItem = {
          jobId,
          date,
          daysMoved: 0,
          hasChanged: false,
          origin: {
            scrollOffsetX,
            barOffset,
            barPosition,
            pointerPosition,
            date
          }
        };

        doStartDragJobBar(this.dragItem);

        this.isDragging = true;
        this.forceUpdate();
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = e => {
      const { doDragJobBar, onPointerMove } = this.props;

      if (this.isDragging) {
        const prevDragItem = this.dragItem;
        const date = this.getTargetDate(e) || prevDragItem.date;
        const daysMoved = differenceInCalendarDays(date, prevDragItem.date);

        this.dragItem = {
          ...this.dragItem,
          date,
          daysMoved
        };

        if (date !== prevDragItem.date) doDragJobBar(this.dragItem);
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = e => {
      const { doEndDragJobBar, onPointerUp } = this.props;

      if (this.isDragging) {
        const hasChanged = this.dragItem.date !== this.dragItem.origin.date;
        const daysMoved = differenceInCalendarDays(
          this.dragItem.date,
          this.dragItem.origin.date
        );

        this.dragItem = {
          ...this.dragItem,
          daysMoved,
          hasChanged
        };

        doEndDragJobBar(this.dragItem);

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = e => {
      const { onLostPointerCapture, doCancelDragJobBar } = this.props;

      if (this.isDragging) {
        doCancelDragJobBar(this.dragItem);

        this.dragItem = null;
        this.isDragging = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isDragging) classes.push("dragging");

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp,
        onLostPointerCapture: this.onLostPointerCapture
      };
    }

    render() {
      const { props, className, handlers } = this;
      const { isDragging } = this;

      return (
        <WrappedComponent
          {...{ ...props, className, isDragging, ...handlers }}
        />
      );
    }
  }

  return connect(mapState, mapDispatch)(JobBarDragHandlers);
};
