import { Icon, MdMoneyOff } from "st-shared/components";
import { theme } from "st-shared/theme";

import { getJobCompanyName } from "../../../lib/entities/jobEntity";
import { useJob } from "../../../redux/selectors/job";
import { useJobId } from "../context/JobIdContext";
import {
  jobCompanyNameCss,
  jobCompanyNameTextCss,
  pageTitleContainerCss,
} from "./PageTitle.css";

export function PageTitle() {
  const jobId = useJobId();
  const job = useJob(jobId);

  return (
    <div className={pageTitleContainerCss}>
      {!job.isBillable && (
        <Icon icon={MdMoneyOff} size={32} color={theme.color.white} />
      )}

      <div className={jobCompanyNameCss}>
        <p className={jobCompanyNameTextCss}>
          {job.number && `[${job.number}] `}
          {job.name} - {getJobCompanyName(job)}
        </p>
      </div>
    </div>
  );
}
