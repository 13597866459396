import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Icon,
  IconButton,
  IconSize,
  Input,
  StDragIndicator,
  StFolderClosed,
  StFolderOpen,
  StFolderOpenOutline,
} from "../../../components";
import { TEntityId } from "../../../entities/Entity";
import { theme } from "../../../theme";
import { useSavedSegmentSidebarContext } from "../context/SavedSegmentSidebarContext";
import {
  useSavedSegmentSidebarActions,
  useSavedSegmentSidebarFolder,
  useSavedSegmentSidebarFolderOpen,
  useSavedSegmentSidebarHelpers,
} from "../state/savedSegmentSidebarStore";
import { TSegmentFolder } from "../state/savedSegmentSidebarUserPreference";
import { SortData, SortTypes } from "../types";
import {
  clickableFolderButtonCss,
  clickableFolderCss,
  folderCss,
  folderInputCss,
  folderNameCss,
  folderWrapperCss,
  folderWrapperOverCss,
} from "./Folder.css";
import { FolderActionMenu } from "./FolderActionMenu";
import { FolderSegments } from "./FolderSegments";
import { SortId } from "./SortId";
import {
  rowDraggingCss,
  rowDraggingIconCss,
  rowDragIconCss,
  rowHoverIconCss,
} from "./styles.css";

type Props = {
  folder: TSegmentFolder;
};

export function Folder({ folder }: Props) {
  const [inputFocused, setInputFocused] = useState(false);

  const sidebarActions = useSavedSegmentSidebarActions();

  const isFolderOpen = useSavedSegmentSidebarFolderOpen(folder.id);
  const holdsCurrentSegment = useHoldsCurrentSegment(folder.segmentIds);

  function onToggleFolderOpen() {
    sidebarActions.setFolderOpen(folder.id, !isFolderOpen);
  }

  function onRename() {
    setInputFocused(true);
  }

  const sortId = SortId.fromEntity(folder.id, SortTypes.Folder).getValue();

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    attributes,
    listeners,
    isOver,
    active,
  } = useSortable({
    id: sortId,
    data: {
      type: SortTypes.Folder,
    },
  });

  const isOutsideSegmentOver = useMemo(() => {
    if (!isOver || !active) return false;
    const activeData = active?.data as SortData;
    return (
      activeData.current?.type === SortTypes.Segment ||
      activeData.current?.type === SortTypes.UncategorisedSegment ||
      (activeData.current?.type === SortTypes.FolderSegment &&
        activeData.current?.folderId !== folder.id)
    );
  }, [isOver, active, folder.id]);

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
      }}
      className={clsx(
        folderWrapperCss,
        isOutsideSegmentOver && folderWrapperOverCss
      )}
    >
      <div className={clsx(folderCss, clickableFolderCss)}>
        <div onClick={onToggleFolderOpen} className={clickableFolderButtonCss}>
          <Icon
            icon={getFolderIcon(isFolderOpen, holdsCurrentSegment)}
            size={IconSize.Medium}
            style={{ marginTop: -2 }}
            color={holdsCurrentSegment ? theme.color.blueactive : undefined}
          />
          {inputFocused && (
            <FolderNameInput
              folder={folder}
              focused={inputFocused}
              setFocused={setInputFocused}
            />
          )}
          {!inputFocused && <div className={folderNameCss}>{folder.name}</div>}
        </div>
        {!inputFocused && (
          <>
            <IconButton
              iconProps={{ icon: StDragIndicator, size: IconSize.Medium }}
              className={clsx(rowHoverIconCss, rowDragIconCss)}
              {...listeners}
              {...attributes}
            />
            <FolderActionMenu folder={folder} onRename={onRename} />
          </>
        )}
      </div>
      {isFolderOpen && !isDragging && (
        <FolderSegments
          folder={folder}
          isSortable={
            !isSorting ||
            !(
              (active?.data as SortData)?.current?.type !==
              SortTypes.FolderSegment
            )
          }
        />
      )}
    </div>
  );
}

type FolderNameInputProps = Props & {
  focused: boolean;
  setFocused: (focused: boolean) => void;
};

export function FolderNameInput({
  folder,
  focused,
  setFocused,
}: FolderNameInputProps) {
  const [inputValue, setInputValue] = useState(folder.name);
  const ref = useRef<HTMLInputElement>(null);

  const sidebarActions = useSavedSegmentSidebarActions();
  const sidebarHelpers = useSavedSegmentSidebarHelpers();

  useEffect(() => {
    setInputValue(folder.name);
  }, [folder.name]);

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function onInputBlur() {
    if (inputValue.length > 0 && !sidebarHelpers.folderNameExists(inputValue)) {
      sidebarActions.setFolderName(folder.id, inputValue);
    } else {
      setInputValue(folder.name);
    }
    setFocused(false);
  }

  function onInputKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      setInputValue(folder.name);
      requestAnimationFrame(() => {
        ref.current?.blur();
      });
    } else if (event.key === "Enter") {
      ref.current?.blur();
    }
  }

  useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused]);

  return (
    <Input
      className={folderInputCss}
      ref={ref}
      value={inputValue}
      onChange={onInputChange}
      placeholder="folder name..."
      onBlur={onInputBlur}
      onKeyDown={onInputKeyDown}
    />
  );
}

type FolderOverlayProps = {
  folderId: string;
};

export function FolderOverlay({ folderId }: FolderOverlayProps) {
  const folder = useSavedSegmentSidebarFolder(folderId);

  if (!folder) return null;

  return <FolderOverlayInner folder={folder} />;
}

function FolderOverlayInner({ folder }: Props) {
  const isFolderOpen = useSavedSegmentSidebarFolderOpen(folder.id);
  const holdsCurrentSegment = useHoldsCurrentSegment(folder.segmentIds);

  return (
    <div className={clsx(folderCss, rowDraggingCss)}>
      <Icon
        icon={getFolderIcon(isFolderOpen, holdsCurrentSegment)}
        size={IconSize.Medium}
        style={{ marginTop: -2 }}
        color={holdsCurrentSegment ? theme.color.blueactive : undefined}
      />
      <div className={folderNameCss}>{folder.name}</div>
      <IconButton
        iconProps={{ icon: StDragIndicator, size: IconSize.Medium }}
        className={rowDraggingIconCss}
      />
      <div style={{ width: 30 }} />
    </div>
  );
}

function getFolderIcon(open: boolean, selected: boolean) {
  if (open) {
    if (selected) {
      return StFolderOpen;
    }
    return StFolderOpenOutline;
  }
  return StFolderClosed;
}

function useHoldsCurrentSegment(segmentIds: TEntityId[]) {
  const { currentSegmentId } = useSavedSegmentSidebarContext();

  return Boolean(currentSegmentId && segmentIds.includes(currentSegmentId));
}
