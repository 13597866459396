import React from "react";
import {
  activityEntryEntityType,
  getMetaDataJobItemName,
  getMetaDataJobCurrencyTotalPlannedTimeExTax,
  getMetaDataRateCardId
} from "../../../../../lib/entities/activityEntryEntity";
import RateCardCurrency from "../../../../modules/Currency/RateCardCurrency";
import SystemMessage from "./SystemMessage";

const JobItemOverBudget = ({ activityEntry }) => (
  <SystemMessage>
    {"The total logged amount on the item "}
    <b>{getMetaDataJobItemName(activityEntry)}</b>
    {" has gone over planned budget of "}
    <RateCardCurrency
      id={getMetaDataRateCardId(activityEntry)}
      value={getMetaDataJobCurrencyTotalPlannedTimeExTax(activityEntry)}
    />
  </SystemMessage>
);

JobItemOverBudget.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemOverBudget;
