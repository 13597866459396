import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "../../../AppState";
import { selectPropsRoleId } from "../../../helpers/selectors";
import { selectActiveUsers } from "./selectActiveUsers";

export const selectActiveUsersWithRoleId = createSelector(
  [selectActiveUsers, selectPropsRoleId],
  (users, roleId) => {
    return users.filter((user) => user.roleId === roleId);
  }
);

export function useActiveUsersWithRoleId(roleId: number) {
  return useSelector((state: AppState) =>
    selectActiveUsersWithRoleId(state, { roleId })
  );
}
