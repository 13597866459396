import { takeLatest, put, select } from "redux-saga/effects";
import {
  LOGGED_EXPENSE_SAVE,
  LOGGED_EXPENSE_SET_ADDRESS
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectAddressById } from "../../selectors/addressSelectors";
import { selectLoggedExpenseById } from "../../selectors/loggedExpenseSelectors";

function* setAddress(action) {
  try {
    const { id, addressId } = action.payload;

    const loggedExpense = yield select(selectLoggedExpenseById, { id });

    const address = yield select(selectAddressById, { id: addressId });

    yield put(
      createAction(LOGGED_EXPENSE_SAVE, {
        id,
        prevLoggedExpense: loggedExpense,
        loggedExpense: {
          ...loggedExpense,
          purchaseOrder: {
            ...loggedExpense.purchaseOrder,
            address
          }
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLoggedExpenseSetAddress() {
  yield takeLatest(LOGGED_EXPENSE_SET_ADDRESS, setAddress);
}
