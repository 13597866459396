import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { UserDisplayName } from "st-shared/module";
import styled from "styled-components";

import {
  ACTIVITY_ENTRY_DELETE,
  ACTIVITY_ENTRY_SAVE,
  JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN,
} from "../../../../../lib/constants";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getCreatedTimeFormatted,
  getLastModifiedByUserId,
  getMetaDataTitle,
  getRelativeLastModifiedTime,
} from "../../../../../lib/entities/activityEntryEntity";
import { getDisplayName } from "../../../../../lib/entities/userEntity";
import {
  RoutePages,
  routerPush,
  routerReplace,
} from "../../../../../lib/router";
import createAction from "../../../../../redux/helpers/createAction";
import {
  useActivityEntryError,
  useIsActivityEntryCreatedByLoggedInUser,
} from "../../../../../redux/selectors/activityEntrySelectors";
import { useUser } from "../../../../../redux/selectors/user";
import { IconButton } from "../../../../elements/Button";
import Flex from "../../../../elements/Flex";
import CancelIcon from "../../../../elements/Icons/CancelIcon";
import JobsIcon from "../../../../elements/Icons/custom/JobsIcon";
import DeleteIcon from "../../../../elements/Icons/DeleteIcon";
import EditIcon from "../../../../elements/Icons/EditIcon";
import RetryIcon from "../../../../elements/Icons/RetryIcon";
import SystemMessageText from "../../../../elements/Typography/SystemMessageText";
import ConfirmDialog from "../../../../modules/ConfirmDialog";
import Popper from "../../../../modules/Popper";
import UserIconName from "../../../../modules/User/UserIconName";
import { useIsDrawer } from "../../../context/isDrawerContext";
import { useJobId } from "../../../context/JobIdContext";

const JobPostCreated = ({ activityEntry }) => {
  const jobId = useJobId();
  const createdByUser = useUser(activityEntry.createdUserId);
  const isCreatedByLoggedInUser = useIsActivityEntryCreatedByLoggedInUser(
    activityEntry.id
  );
  const error = useActivityEntryError(activityEntry.id);

  const actionsAnchorRef = useRef();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const isDrawer = useIsDrawer();

  const onOpenPost = () => {
    if (isDrawer) {
      dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, false));
    }
    routerPush(RoutePages.Jobs, jobId, `activity/post/${activityEntry.id}`);
  };

  const onEditPost = () => {
    if (isDrawer) {
      dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, false));
    }
    routerReplace(
      RoutePages.Jobs,
      jobId,
      `activity/post/${activityEntry.id}/edit`
    );
  };

  const retrySend = () => {
    dispatch(createAction(ACTIVITY_ENTRY_SAVE, { activityEntry }));
  };

  const cancelSend = () => {
    dispatch(createAction(ACTIVITY_ENTRY_DELETE, { id: activityEntry.id }));
  };

  const onDeletePost = () => {
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    dispatch(createAction(ACTIVITY_ENTRY_DELETE, { id: activityEntry.id }));
    setIsConfirmDeleteOpen(false);
  };

  const cancelDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const onMouseEnter = () => {
    setActionsAnchorEl(actionsAnchorRef.current);
  };

  const onMouseLeave = () => {
    setActionsAnchorEl(null);
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <PostContainer>
        <PostHeader>
          <Author>
            <UserIconName
              id={getCreatedByUserId(activityEntry)}
              showDisplayName={false}
              size={25}
              nameWidth="0"
              style={{ width: "unset" }}
            />
            <AuthorName>{getDisplayName(createdByUser)}</AuthorName>
            <PostDateTime>
              {getCreatedTimeFormatted(activityEntry)}
            </PostDateTime>
          </Author>
          {error && (
            <ErrorMessage>
              Post could not be sent.
              <IconButton onClick={retrySend}>
                <RetryIcon />
              </IconButton>
              <IconButton onClick={cancelSend}>
                <CancelIcon />
              </IconButton>
            </ErrorMessage>
          )}
        </PostHeader>
        <PostBody isCreatedByLoggedInUser={isCreatedByLoggedInUser}>
          <PostSummary ref={actionsAnchorRef}>
            <PostTitle onClick={onOpenPost}>
              <JobsIcon />
              {getMetaDataTitle(activityEntry)}
            </PostTitle>
            {activityEntry.threadActivityEntryCount > 0 && (
              <div>
                <SystemMessageText>
                  {`${activityEntry.threadActivityEntryCount} comments`}
                </SystemMessageText>
              </div>
            )}
            {activityEntry.lastModifiedDatetime && (
              <div>
                <SystemMessageText>
                  {"Last edited by "}
                  <UserDisplayName
                    userId={getLastModifiedByUserId(activityEntry)}
                  />{" "}
                  {getRelativeLastModifiedTime(activityEntry)}
                </SystemMessageText>
              </div>
            )}
          </PostSummary>
          <StyledPopper anchorEl={actionsAnchorEl} placement="top-end">
            <Flex>
              <IconButton onClick={onEditPost}>
                <EditIcon />
              </IconButton>
              {isCreatedByLoggedInUser && (
                <IconButton onClick={onDeletePost}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Flex>
          </StyledPopper>
        </PostBody>
      </PostContainer>
      {isCreatedByLoggedInUser && (
        <ConfirmDialog
          open={isConfirmDeleteOpen}
          title="Delete Post"
          confirmText="Delete"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        >
          This can’t be undone. Are you sure?
        </ConfirmDialog>
      )}
    </Wrapper>
  );
};

JobPostCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
};

export default JobPostCreated;

const Wrapper = styled(Flex)`
  width: 100%;
  padding: 10px 0;
  flex-shrink: 0;
`;

const PostContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const PostHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AuthorName = styled.span`
  color: var(--color-charcoal);
  font-size: 12px;
  font-weight: 500;
`;

const PostDateTime = styled(SystemMessageText)`
  padding-top: 1px;
  margin: 0 10px;
  font-weight: 600;
  align-self: center;
`;

const PostTitle = styled.div`
  color: var(--color-charcoal);
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 6px;
  &:hover {
    color: black;
    cursor: pointer;
  }

  & > * :first-child {
    margin-right: 5px;
  }
`;

const PostBody = styled(Flex)`
  margin: 10px 0 15px;
  width: 100%;
  border-radius: 4px;
  justify-content: space-between;

  background-color: ${(props) =>
    props.isCreatedByLoggedInUser ? "var(--color-yellow-light);" : "white"};
`;

const PostSummary = styled.div`
  width: 100%;
  padding: 15px 15px 10px;
  overflow: hidden;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-weight: normal;
  white-space: nowrap;
  color: var(--color-red-dark);
  ${IconButton} {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  ${RetryIcon},
  ${CancelIcon} {
    font-size: 15px;
    color: var(--color-charcoal);
    &:hover {
      color: var(--color-gray);
    }
  }
`;

const StyledPopper = styled(Popper)`
  .MuiPaper-root {
    margin-bottom: -5px;
  }
`;
