import {
  BRANCH_LOGO_UPLOAD_FAILED,
  BRANCH_LOGO_UPLOAD_REQUEST,
  BRANCH_LOGO_UPLOAD_SUCCEEDED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onUploadRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isUploading: true,
    isUploaded: false
  }
});

const onUploadSucceeded = (state, { payload: { id, data } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isUploading: false,
    isUploaded: true
  }
});

const onUploadFailed = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isUploading: false,
    isUploaded: false
  }
});

export default createReducer(
  {},
  {
    [BRANCH_LOGO_UPLOAD_REQUEST]: onUploadRequest,
    [BRANCH_LOGO_UPLOAD_SUCCEEDED]: onUploadSucceeded,
    [BRANCH_LOGO_UPLOAD_FAILED]: onUploadFailed
  }
);
