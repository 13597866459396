import { produce } from "immer";
import { get, set, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../lib/constants";
import { isAfter, isBefore } from "../../../../lib/dates";

export default (prevState, nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedJobIds = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      if (prevEntity && !newEntity) pushOnce(touchedJobIds, prevEntity.jobId);

      if (newEntity) pushOnce(touchedJobIds, newEntity.jobId);
    });

    touchedJobIds.forEach((jobId) => {
      const ids = get(nextState.idsByJobId, jobId, EMPTY_ARRAY);

      if (!ids.length) {
        unset(draft, `dateRangeByJobId.${jobId}`);
      } else {
        const prevStartDate = get(
          prevState.dateRangeByJobId,
          `${jobId}.startDate`,
          null
        );
        const prevEndDate = get(
          prevState.dateRangeByJobId,
          `${jobId}.endDate`,
          null
        );
        let leftStartDate = null;
        let rightEndDate = null;

        const invoices = ids.map((id) =>
          get(nextState, `byId.${id}`, EMPTY_OBJECT)
        );

        invoices
          .map((invoice) => (invoice && invoice.invoiceDate) || null)
          .filter(Boolean)
          .forEach((date) => {
            if (!leftStartDate || isBefore(date, leftStartDate))
              leftStartDate = date;

            if (!rightEndDate || isAfter(date, rightEndDate))
              rightEndDate = date;
          });

        if (prevStartDate !== leftStartDate || prevEndDate !== rightEndDate) {
          set(draft, `dateRangeByJobId.${jobId}`, {
            startDate: leftStartDate,
            endDate: rightEndDate,
          });
        }
      }
    });
  });
