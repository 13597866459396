import {
  Icon,
  IconSize,
  MdAdd,
  RTooltip,
  StActionRepeat,
  StCreditCardOutline,
  StDatatableOutline,
  StFinanceDocumentOutline,
  StFinanceMoneyOutline,
  StTablerowsOutline,
  StTimelineOutline,
} from "st-shared/components";
import { JobStatuses } from "st-shared/entities/JobStatus";
import { WebAPI } from "st-shared/lib";
import { theme } from "st-shared/theme";

import {
  jobPageCreateCreditNote,
  jobPageCreateExpense,
  jobPageCreateInvoice,
  jobPageCreateLoggedTime,
  jobPageCreatePurchaseOrder,
  jobPageCreateQuote,
  jobPageCreateRepeatingLoggedTime,
  jobPageOpenCreateJobModal,
} from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import { useJobId } from "../../context/JobIdContext";
import { useCreateNewJobItem } from "../../hooks/useCreateNewJobItem";
import { useJobTimelineExportSize } from "../../hooks/useJobTimelineExportSize";
import {
  buttonCss,
  createJobButtonCss,
  flyoutCss,
  iconButtonWrapperCss,
  iconCss,
  iconTextWrapperCss,
  textCss,
} from "./DropdownFlyout.css";
import type { CreateOptionItem } from "./DropdownFlyoutItems";

export function DropdownFlyout() {
  const jobId = useJobId();
  const job = useJob(jobId);

  const createJobItem = useCreateNewJobItem(jobId);

  const timelineContentSize = useJobTimelineExportSize(jobId);

  const createOptionItems: CreateOptionItem[] = [
    {
      id: 1,
      icon: StDatatableOutline,
      label: "Item",
      onClickHandler: createJobItem,
    },
    {
      id: 2,
      icon: StTablerowsOutline,
      onClickHandler: () => jobPageCreateLoggedTime(job),
      label: "To Do",
    },
    {
      id: 3,
      icon: StActionRepeat,
      onClickHandler: () => jobPageCreateRepeatingLoggedTime(job),
      label: "Repeating To Do",
    },
    {
      id: 4,
      icon: StCreditCardOutline,
      onClickHandler: () => jobPageCreateExpense(job),
      label: "Expense",
    },
    {
      id: 5,
      icon: StFinanceDocumentOutline,
      onClickHandler: () => jobPageCreatePurchaseOrder(job),
      label: "Purchase Order",
    },
    {
      id: 6,
      icon: StFinanceDocumentOutline,
      onClickHandler: () => jobPageCreateQuote(job),
      label: "Quote",
    },
    {
      id: 7,
      icon: StFinanceDocumentOutline,
      onClickHandler: () => jobPageCreateInvoice(job),
      label: "Invoice",
    },
    {
      id: 8,
      icon: StFinanceDocumentOutline,
      onClickHandler: () => jobPageCreateCreditNote(job),
      label: "Credit Note",
    },
    {
      id: 9,
      icon: StFinanceMoneyOutline,
      onClickHandler: () => WebAPI.Jobs.openDownloadJobCostReportLink(jobId),
      label: "Job Cost Report",
    },
    {
      id: 10,
      icon: StTimelineOutline,
      onClickHandler: () => WebAPI.Jobs.openExportTimelineSummaryLink(jobId),
      label: "Timeline Summary",
    },
    {
      id: 11,
      icon: StTimelineOutline,
      onClickHandler: () =>
        WebAPI.Jobs.openExportTimelineLink(jobId, timelineContentSize),
      label: "Timeline PDF",
    },
    {
      id: 12,
      icon: MdAdd,
      onClickHandler: () => jobPageOpenCreateJobModal(),
      label: "New Job",
    },
  ];

  const archivedCreateOptionItems: CreateOptionItem[] = [
    {
      id: 1,
      icon: StTimelineOutline,
      onClickHandler: () => WebAPI.Jobs.openExportTimelineSummaryLink(jobId),
      label: "Timeline Summary",
    },
    {
      id: 2,
      icon: StTimelineOutline,
      onClickHandler: () =>
        WebAPI.Jobs.openExportTimelineLink(jobId, timelineContentSize),
      label: "Timeline PDF",
    },
    {
      id: 3,
      icon: MdAdd,
      onClickHandler: () => jobPageOpenCreateJobModal(),
      label: "New Job",
    },
  ];

  const showOptionItemList =
    job.jobStatus.id === JobStatuses.Archived
      ? archivedCreateOptionItems
      : createOptionItems;

  return (
    <div
      className={createJobButtonCss}
      data-tooltip-id="dropdown-flyout-tooltip"
    >
      <div className={iconTextWrapperCss}>
        <Icon icon={MdAdd} size={IconSize.XLarge} />
        <p className={textCss}>Create</p>

        <RTooltip
          id="dropdown-flyout-tooltip"
          place="bottom"
          className={flyoutCss}
          render={() => (
            <>
              {showOptionItemList.map((item) => (
                <button
                  key={item.id}
                  className={iconButtonWrapperCss}
                  onClick={item.onClickHandler}
                >
                  <Icon
                    icon={item.icon}
                    size={24}
                    color={theme.color.graymediumish}
                    className={iconCss}
                  />

                  <span className={buttonCss}>{item.label}</span>
                </button>
              ))}
            </>
          )}
          clickable
          positionStrategy="fixed"
        />
      </div>
    </div>
  );
}
