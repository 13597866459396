import { CompanyStatusEnum } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";

import { entityIdType } from "../types/entityTypes";
import { addressEntityType } from "./addressEntity";

export const companyShape = {
  id: entityIdType.isRequired,
  name: PropTypes.string,
  branchId: entityIdType.isRequired,
  rateCardId: entityIdType,
  taxNumber: PropTypes.string,
  createdDatetime: PropTypes.string,
  phone1: PropTypes.string,
  phone2: PropTypes.string,
  websiteAddress: PropTypes.string,
  defaultAddress: addressEntityType,
  notes: PropTypes.string,
  lastJobNumber: PropTypes.string,
  companyStatus: PropTypes.shape({
    id: entityIdType.isRequired,
    name: PropTypes.string,
  }),
};

export const companyEntityType = PropTypes.shape(companyShape);

export const getName = ({ name }) => name;

export const getTaxNumber = ({ taxNumber }) => taxNumber;

export const isActive = ({ companyStatus }) =>
  companyStatus.id === CompanyStatusEnum.Active;

export const getLastJobNumber = ({ lastJobNumber }) => lastJobNumber;
