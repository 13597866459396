import { put, select, takeLatest } from "redux-saga/effects";
import { QUOTE_SET_CONTACT, QUOTE_SAVE } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectContactById } from "../../selectors/contactSelectors";
import { selectQuoteById } from "../../selectors/quoteSelectors";

function* setContact(action) {
  try {
    const { id, contactId } = action.payload;

    const quote = yield select(selectQuoteById, { id });

    const contact = yield select(selectContactById, { id: contactId });

    yield put(
      createAction(QUOTE_SAVE, {
        id,
        prevQuote: quote,
        quote: {
          ...quote,
          contact
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchQuoteSetContact() {
  yield takeLatest(QUOTE_SET_CONTACT, setContact);
}
