import { Button, Icon, MdAdd } from "st-shared/components";

import { openReportingTemplatesModal } from "../../lib/openReportingTemplatesModal";
import { newReportButtonCss, newReportIconCss } from "./NewReport.css";

export function NewReport() {
  return (
    <Button
      variant="primary"
      onClick={openReportingTemplatesModal}
      className={newReportButtonCss}
    >
      <Icon icon={MdAdd} size={20} className={newReportIconCss} />
      New Report
    </Button>
  );
}
