import { produce } from "immer";
import { get, reduce, setWith } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import {
  getBlockHeight,
  getJobIdIfNoItemBaseLoggedTime,
  getUserIdJobIdKeyPath,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getMaxBlockHeight = (state, blockKeys) =>
  reduce(
    blockKeys,
    (max, key) => {
      const scheduleLoggedTime = state.byId[key];
      const height = getBlockHeight(scheduleLoggedTime);
      const offsetY = get(
        state,
        `offsetYByBlockKeyForJobsWithNoItem.${key}`,
        0
      );

      return Math.max(max, height + offsetY);
    },
    0
  );

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath =
        getJobIdIfNoItemBaseLoggedTime(prevEntity) &&
        getUserIdJobIdKeyPath(prevEntity);
      const newPath =
        getJobIdIfNoItemBaseLoggedTime(newEntity) &&
        getUserIdJobIdKeyPath(newEntity);

      if (prevPath) pushOnce(touchedPaths, prevPath);
      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((keyPath) => {
      const blockKeys = get(
        nextState.blockKeysByUserIdJobIdNoItem,
        keyPath,
        []
      );

      setWith(
        draft,
        `maxBlockHeightByUserIdJobIdNoItem${keyPath}`,
        getMaxBlockHeight(nextState, blockKeys, false),
        Object
      );
    });
  });
