import * as PropTypes from "prop-types";
import styled from "styled-components";

import { reactNodesType } from "../../../../../lib/types/reactTypes";
import { feToWebNavigateToEntity } from "../../../../../lib/WebAppAPI/fePages/genericWeb";

const LinkToEntity = ({ children, disabled, entityDescriptor, entityId }) => {
  const onClick = () => {
    if (!disabled) {
      feToWebNavigateToEntity(entityDescriptor, entityId);
    }
  };

  return (
    <Span onClick={onClick} disabled={disabled}>
      hello
      {children}
    </Span>
  );
};

LinkToEntity.propTypes = {
  children: reactNodesType,
  disabled: PropTypes.bool,
  entityDescriptor: PropTypes.number.isRequired,
  entityId: PropTypes.number.isRequired,
};

LinkToEntity.defaultProps = {
  children: null,
  disabled: false,
};

export default LinkToEntity;

const Span = styled.span`
  font-weight: bold;
  &:not([disabled]) {
    text-decoration: underline;
    cursor: pointer;
  }
`;
