import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobItemId } from "../../index";
import { selectJobItemUi } from "./index";

export const selectIsSavingJobItem = createCachedSelector(
  [selectJobItemUi],
  jobItem => !!(jobItem && jobItem.isSaving)
)(selectPropsJobItemId);

export const useIsSavingJobItem = jobItemId =>
  useSelector(state => selectIsSavingJobItem(state, { jobItemId }));
