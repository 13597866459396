import { produce } from "immer";

import { JOB_ITEM_SAVE_ERROR, JOB_ITEM_SAVED } from "../../../lib/constants";
import { JOB_ITEM_ROLE_EDIT } from "../../entities/jobItemRole/actions";
import { JobItemRolesUiAction } from "./actions";
import { JobItemRolesUiState } from "./types";

const initialState: JobItemRolesUiState = {};

function jobItemRolesUiReducer(
  state: JobItemRolesUiState = initialState,
  action: JobItemRolesUiAction
) {
  switch (action.type) {
    case JOB_ITEM_ROLE_EDIT:
      return produce(state, (draft) => {
        if (!draft[action.jobItemId]) {
          draft[action.jobItemId] = {
            editedJobItemRoleIds: [],
            editedJobItemRoles: [],
          };
        }
        if (
          !draft[action.jobItemId].editedJobItemRoleIds.includes(
            action.jobItemRole.id
          )
        ) {
          draft[action.jobItemId].editedJobItemRoles.push(action.jobItemRole);
          draft[action.jobItemId].editedJobItemRoleIds.push(
            action.jobItemRole.id
          );
        }
      });
    case JOB_ITEM_SAVED:
    case JOB_ITEM_SAVE_ERROR:
      return produce(state, (draft) => {
        if (draft[action.payload.jobItemId]) {
          delete draft[action.payload.jobItemId];
        }
      });
    default:
      return state;
  }
}

export default jobItemRolesUiReducer;
