import { without } from "lodash-es";

export const moveValueLeft = (array, value) => {
  const index = array.indexOf(value);
  if (index < 1) return array;

  return [
    ...array.slice(0, index - 1),
    array[index],
    array[index - 1],
    ...array.slice(index + 1),
  ];
};

export const moveValueRight = (array, value) => {
  const index = array.indexOf(value);
  if (index < 0 || index === array.length - 1) return array;

  return [
    ...array.slice(0, index),
    array[index + 1],
    array[index],
    ...array.slice(index + 2),
  ];
};

export const moveValueTo = (array, value, targetIndex) => {
  const before = array.slice(0, targetIndex);
  const after = array.slice(targetIndex);
  return [...without(before, value), value, ...without(after, value)];
};

export const swapValuePositions = (array, value1, value2) => {
  const index1 = array.indexOf(value1);
  const index2 = array.indexOf(value2);
  const newArray = [...array];
  newArray[index1] = value2;
  newArray[index2] = value1;
  return newArray;
};

export const pushOnce = (srcArray, value) => {
  if (srcArray.indexOf(value) < 0) srcArray.push(value);
};
