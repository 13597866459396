import { select, put } from "redux-saga/effects";
import {
  JOB_PHASE_SET_ALL_EXPANDED,
  JOB_PHASE_SET_EXPANDED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobPhaseIdsByJobId } from "../../selectors/jobPhaseSelectors";

function* setAllExpanded(action) {
  try {
    const { jobId, isExpanded } = action.payload;

    const jobPhaseIds = yield select(selectJobPhaseIdsByJobId, { jobId });

    yield put(
      createAction(JOB_PHASE_SET_EXPANDED, {
        jobPhaseIds,
        isExpanded
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobPhaseSetAllExpanded() {
  yield takeLatestBy(
    [JOB_PHASE_SET_ALL_EXPANDED],
    setAllExpanded,
    action => action.payload.jobId
  );
}
