import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";

import { AllSegments } from "../allSegments/AllSegments";
import {
  useSavedSegmentSidebarActions,
  useSavedSegmentSidebarFolders,
  useSavedSegmentSidebarSegmentIds,
} from "../state/savedSegmentSidebarStore";
import { DropTypes, SortData, SortTypes, TSortTypes } from "../types";
import { AddFolder } from "./AddFolder";
import { dividerBarCss } from "./CatergorisedSegments.css";
import { Folder, FolderOverlay } from "./Folder";
import { Segment, SegmentOverlay } from "./Segment";
import { SegmentsDroppable } from "./SegmentsDroppable";
import { SortId } from "./SortId";

export function CatergorisedSegments() {
  const segmentIds = useSavedSegmentSidebarSegmentIds();
  const folders = useSavedSegmentSidebarFolders();
  const folderIds = folders.map((folder) => folder.id);
  const sidebarActions = useSavedSegmentSidebarActions();

  const segmentSortItems = useMemo(
    () =>
      segmentIds.map((id) =>
        SortId.fromEntity(id, SortTypes.Segment).getValue()
      ),
    [segmentIds]
  );

  const folderSortItems = useMemo(
    () =>
      folderIds.map((id) => SortId.fromEntity(id, SortTypes.Folder).getValue()),
    [folderIds]
  );

  const [activeSortId, setActiveSortId] = useState<SortId | null>(null);
  const [overlayDropType, setOverlayDropType] = useState<TSortTypes | null>(
    null
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const data = active.data as SortData;

    setOverlayDropType(null);

    if (!data.current) return;

    if (
      data.current?.type &&
      data.current?.type !== DropTypes.SegmentsDroppable
    ) {
      const sortId = SortId.fromValue(String(active.id));
      setActiveSortId(sortId);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    setActiveSortId(null);

    if (!active || !over) return;
    if (active.id === over.id) return;

    const activeData = active.data as SortData;
    const overData = over.data as SortData;

    if (!activeData.current || !overData.current) return;

    const activeSortId = SortId.fromValue(String(active.id));
    const overSortId = SortId.fromValue(String(over.id));

    setOverlayDropType(activeSortId.type);

    if (
      activeData.current.type === SortTypes.Segment &&
      overData.current.type === SortTypes.Segment
    ) {
      sidebarActions.swapSegments(
        activeSortId.getEntityNumber(),
        overSortId.getEntityNumber()
      );
      return;
    }

    if (
      activeData.current.type === SortTypes.Folder &&
      overData.current.type === SortTypes.Folder
    ) {
      sidebarActions.swapFolders(
        activeSortId.getEntityString(),
        overSortId.getEntityString()
      );
      return;
    }

    if (
      activeData.current.type === SortTypes.FolderSegment &&
      overData.current.type === SortTypes.FolderSegment
    ) {
      sidebarActions.swapFolderSegments(
        activeData.current?.folderId,
        activeSortId.getEntityNumber(),
        overSortId.getEntityNumber()
      );
      return;
    }

    if (
      activeData.current.type === SortTypes.Segment &&
      overData.current.type === SortTypes.Folder
    ) {
      sidebarActions.moveSegmentToFolder(
        overSortId.getEntityString(),
        activeSortId.getEntityNumber()
      );
      return;
    }

    if (
      activeData.current.type === SortTypes.FolderSegment &&
      overData.current.type === SortTypes.Folder &&
      activeData.current.folderId !== overSortId.getEntityString()
    ) {
      sidebarActions.moveSegmentToFolder(
        overSortId.getEntityString(),
        activeSortId.getEntityNumber()
      );
      return;
    }

    if (
      activeData.current.type === SortTypes.FolderSegment &&
      overData.current.type === DropTypes.SegmentsDroppable
    ) {
      sidebarActions.addSegment(activeSortId.getEntityNumber());
      return;
    }

    if (
      activeData.current.type === SortTypes.UncategorisedSegment &&
      overData.current.type === DropTypes.SegmentsDroppable
    ) {
      sidebarActions.addSegment(activeSortId.getEntityNumber());
      return;
    }

    if (
      activeData.current.type === SortTypes.UncategorisedSegment &&
      overData.current.type === SortTypes.Folder
    ) {
      sidebarActions.moveSegmentToFolder(
        overSortId.getEntityString(),
        activeSortId.getEntityNumber()
      );
      sidebarActions.setFolderOpen(overSortId.getEntityString(), true);
      return;
    }
  }

  function handleDragCancel() {
    setActiveSortId(null);
  }

  function renderOverlay() {
    if (activeSortId) {
      switch (activeSortId.type) {
        case SortTypes.Segment:
        case SortTypes.FolderSegment:
        case SortTypes.UncategorisedSegment:
          return <SegmentOverlay segmentId={activeSortId.getEntityNumber()} />;
        case SortTypes.Folder:
          return <FolderOverlay folderId={activeSortId.getEntityString()} />;
      }
    }
    return null;
  }

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SegmentsDroppable>
          <SortableContext
            items={segmentSortItems}
            strategy={verticalListSortingStrategy}
          >
            {segmentIds.map((segmentId) => (
              <Segment key={segmentId} segmentId={segmentId} />
            ))}
          </SortableContext>
        </SegmentsDroppable>
        <SortableContext
          items={folderSortItems}
          strategy={verticalListSortingStrategy}
        >
          {folders.map((folder) => (
            <Folder key={folder.id} folder={folder} />
          ))}
        </SortableContext>
        <AddFolder />
        <div className={dividerBarCss} />
        <AllSegments />
        {createPortal(
          <DragOverlay
            dropAnimation={
              overlayDropType &&
              overlayDropType === SortTypes.UncategorisedSegment
                ? null
                : undefined
            }
          >
            {renderOverlay()}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </>
  );
}
