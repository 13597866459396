import { ENTITY_CLASS_INVOICE_LINE_ITEM } from "../constants";
import { getOrderId } from "../ui/commercialDocumentLineItems";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { createModelSetParser } from "./modelSets";

export const saveInvoiceLineItemAPI = (invoiceLineItem) =>
  API.put(`/invoice_line_items/${invoiceLineItem.id}`, invoiceLineItem)
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);

export const createInvoiceLineItemAPI = (invoiceLineItem) =>
  API.post(`/invoices/${invoiceLineItem.invoiceId}/invoice_line_items`, {
    ...invoiceLineItem,
    orderId: getOrderId(invoiceLineItem) - 0.5,
    id: null,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);

export const deleteInvoiceLineItemAPI = (id) =>
  API.delete(`/invoice_line_items/${id}`)
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);

export const mergeInvoiceLineItemsAPI = (invoiceLineItem, otherIds) =>
  API.put(`/invoice_line_items/${invoiceLineItem.id}/merge`, invoiceLineItem, {
    other_line_item_ids: otherIds.join(","),
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);

export const undoMergeInvoiceLineItemsAPI = (invoiceLineItem, otherIds) =>
  API.post(
    `/invoice_line_items/${invoiceLineItem.id}/undo_merge`,
    invoiceLineItem,
    {
      other_line_item_ids: otherIds.join(","),
    }
  )
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);

export const sortInvoiceLineItemsAPI = (invoiceId, invoiceLineItemIds) => {
  return API.put(`/invoices/${invoiceId}/invoice_line_items/reorder`, null, {
    ids: invoiceLineItemIds.join(","),
  })
    .then(({ data }) => data)
    .then(createModelSetParser())
    .then(({ data }) => data);
};

export function createJobItemInvoiceLineItemAPI(invoiceId, jobItemId, orderId) {
  return API.post(`/invoices/${invoiceId}/job_item_invoice_line_items`, {
    job_item_id: jobItemId,
    order_id: orderId,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);
}

export function createLoggedExpenseInvoiceLineItemAPI(
  invoiceId,
  loggedExpenseId,
  orderId
) {
  return API.post(`/invoices/${invoiceId}/logged_expense_invoice_line_items`, {
    logged_expense_id: loggedExpenseId,
    order_id: orderId,
  })
    .then(({ data }) => {
      sendApplyModelData(ENTITY_CLASS_INVOICE_LINE_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_INVOICE_LINE_ITEM))
    .then(({ data }) => data);
}
