import * as PropTypes from "prop-types";
import React from "react";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const DateConsumer = ({ date, children }) => (
  <JobTimelineScrollContext.Consumer>
    {({ getOffsetXAtDate, dayWidth }) =>
      children({
        width: dayWidth,
        left: getOffsetXAtDate(date)
      })
    }
  </JobTimelineScrollContext.Consumer>
);

DateConsumer.propTypes = {
  date: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default DateConsumer;
