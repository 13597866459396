import { SET_USER_PREFERENCE } from "../../../lib/constants";
import { EntityAction } from "../actions";
import { TUserPreferenceKeys } from "./types";

// TODO: replace this for SET_USER_PREFERENCE and add all actions
export const USER_PREFERENCE_SET = "userPreference/set";

export type UserPreferencesAction = EntityAction | UserPreferenceSetAction;

export type UserPreferenceSetAction = {
  type: typeof SET_USER_PREFERENCE;
  payload: {
    key: TUserPreferenceKeys;
    value: any;
  };
};

export function actionUserPreferenceSet(
  key: TUserPreferenceKeys,
  value: any
): UserPreferenceSetAction {
  return { type: SET_USER_PREFERENCE, payload: { key, value } };
}
