import { all } from "redux-saga/effects";
import watchCreateLabel from "./watchCreateLabel";
import watchDeleteLabel from "./watchDeleteLabel";
import watchFetchLabelOptions from "./watchFetchLabelOptions";
import watchFetchLabels from "./watchFetchLabels";

export default function* labelsSaga() {
  yield all([
    watchFetchLabels(),
    watchFetchLabelOptions(),
    watchDeleteLabel(),
    watchCreateLabel()
  ]);
}
