import { isFunction } from "lodash-es";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { deleteActivityEntryAPI } from "../../../lib/API/activityEntryAPI";
import {
  ACTIVITY_ENTRY_DELETE,
  ACTIVITY_ENTRY_DELETE_ERROR,
  ACTIVITY_ENTRY_DELETED,
  ACTIVITY_ENTRY_DELETING,
  ENTITIES_RECEIVED,
  ENTITY_NAME_ACTIVITY_ENTRIES
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectActivityEntryById } from "../../selectors/activityEntrySelectors";

function* remove(action) {
  const { id, onSuccess } = action.payload;
  const activityEntry = yield select(selectActivityEntryById, { id });

  try {
    yield put(createAction(ACTIVITY_ENTRY_DELETING, action.payload));

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_ACTIVITY_ENTRIES]: [{ ...activityEntry, active: false }]
      })
    );

    if (id > 0) {
      yield call(deleteActivityEntryAPI, id);
    }

    yield put(createAction(ACTIVITY_ENTRY_DELETED, action.payload));

    if (isFunction(onSuccess)) yield call(onSuccess);
  } catch (error) {
    error.displayMessage = "Message could not be deleted";
    error.action = ACTIVITY_ENTRY_DELETE_ERROR;

    yield put(
      createAction(ACTIVITY_ENTRY_DELETE_ERROR, {
        ...action.payload,
        activityEntry,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchDelete() {
  yield takeEvery(ACTIVITY_ENTRY_DELETE, remove);
}
