import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  reactContentType,
  reactNodesType
} from "../../../lib/types/reactTypes";
import Flex from "../../elements/Flex";

const TooltipContentTable = ({ description, footer, contentArray }) => (
  <Content>
    {description && <Description>{description}</Description>}
    <table>
      <tbody>
        {contentArray.map(([first, ...rest], i) => (
          <Row key={i}>
            {rest.length ? <Heading>{first}</Heading> : <Value>{first}</Value>}
            {rest.map((value, j) => value && <Value key={j}>{value}</Value>)}
          </Row>
        ))}
      </tbody>
    </table>
    {footer && <Footer>{footer}</Footer>}
  </Content>
);

TooltipContentTable.propTypes = {
  description: reactNodesType,
  footer: reactNodesType,
  contentArray: PropTypes.arrayOf(PropTypes.arrayOf(reactContentType))
    .isRequired
};

TooltipContentTable.defaultProps = {
  description: null,
  footer: null
};

export default TooltipContentTable;

const Content = styled(Flex)`
  flex-direction: column;
`;

const Description = styled.div`
  color: var(--color-snow);
  font-size: 11px;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const Footer = styled(Description)`
  margin-bottom: 0;
`;

const Row = styled.tr`
  margin-bottom: 15px;
  &:last-child {
    margin: 0;
  }
`;

const Heading = styled.th`
  color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  padding-right: 15px;
`;

const Value = styled.td`
  color: var(--color-gray);
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  padding-right: 5px;
`;
