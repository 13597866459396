import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY, ENTITY_NAME_JOB_ITEMS } from "../../../../lib/constants";
import { selectPropsJobId } from "../../index";
import { selectJobPhaseIdsByJobId } from "../jobPhaseIdsByJobId";
import { selectStateJobPhasesUi } from "./index";

export const selectAreAllCollapsedJobPhase = createCachedSelector(
  [
    selectJobPhaseIdsByJobId,
    selectStateJobPhasesUi,
    selectPropsJobId,
    state =>
      get(
        state.entities[ENTITY_NAME_JOB_ITEMS],
        "jobItemOrderByJobIdPhaseId",
        null
      )
  ],
  (jobPhaseIds, jobPhaseUi, jobId, jobItemOrderByJobIdPhaseId) => {
    let allCollapsed = true;
    jobPhaseIds.forEach(jobPhaseId => {
      const isExpanded = get(jobPhaseUi[jobPhaseId], `isExpanded`, true);
      const jobItems = get(
        jobItemOrderByJobIdPhaseId,
        `["${jobId}.${jobPhaseId}"]`,
        EMPTY_ARRAY
      );
      if (jobItems.length > 0 && isExpanded) allCollapsed = false;
    });
    return allCollapsed;
  }
)(selectPropsJobId);

export const useAreAllCollapsedJobPhase = jobId =>
  useSelector(state => selectAreAllCollapsedJobPhase(state, { jobId }));
