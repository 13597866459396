import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  JOB_ITEM_PAINT,
  JOB_ITEM_PAINT_CANCEL,
  JOB_ITEM_PAINT_END,
  JOB_ITEM_PAINT_START
} from "../../../lib/constants";
import { maxDate, minDate } from "../../../lib/dates";
import {
  getElementClientCenterOffset,
  getElementHasClass,
  getPointerClientOffset
} from "../../../lib/dom";
import {
  getTargetJobItemElement,
  getTargetJobItemId
} from "../../../lib/eventTargets";
import {
  addKeyEventListener,
  removeKeyEventListener
} from "../../../lib/events";
import createAction from "../../../redux/helpers/createAction";

const mapState = null;

const mapDispatch = dispatch => ({
  doStartPaintBar: payload =>
    dispatch(createAction(JOB_ITEM_PAINT_START, payload)),
  doPaintBar: payload => dispatch(createAction(JOB_ITEM_PAINT, payload)),
  doEndPaintBar: payload => dispatch(createAction(JOB_ITEM_PAINT_END, payload)),
  doCancelPaintBar: payload =>
    dispatch(createAction(JOB_ITEM_PAINT_CANCEL, payload))
});

export default WrappedComponent => {
  class JobItemPaintHandlers extends React.PureComponent {
    paintItem = null;

    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      isCreatingDependancy: PropTypes.bool.isRequired,
      doStartPaintBar: PropTypes.func.isRequired,
      doPaintBar: PropTypes.func.isRequired,
      doEndPaintBar: PropTypes.func.isRequired,
      doCancelPaintBar: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      getDateOnPosition: PropTypes.func.isRequired,
      getScrollOffsetX: PropTypes.func.isRequired,
      getDateAtOffsetX: PropTypes.func.isRequired,
      className: PropTypes.string
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: ""
    };

    state = {
      cursorPosition: null
    };

    componentDidMount() {
      addKeyEventListener("keydown", this.onKeyDown);
      addKeyEventListener("keyup", this.onKeyUp);
    }

    componentWillUnmount() {
      removeKeyEventListener("keydown", this.onKeyDown);
      removeKeyEventListener("keyup", this.onKeyUp);
    }

    onPointerDown = e => {
      const {
        getScrollOffsetX,
        getDateOnPosition,
        readOnly,
        onPointerDown,
        doStartPaintBar
      } = this.props;
      const jobItemId = getTargetJobItemId(e);
      const containerNode = jobItemId && getTargetJobItemElement(e);
      const isPaintable =
        containerNode && getElementHasClass(containerNode, "paintable");

      if (!readOnly && containerNode && isPaintable) {
        const pointerPosition = getPointerClientOffset(e);
        const date = getDateOnPosition(pointerPosition);
        const scrollOffsetX = getScrollOffsetX();

        this.paintItem = {
          jobItemId,
          startDate: date,
          endDate: date,
          origin: {
            scrollOffsetX,
            pointerPosition,
            date
          }
        };

        doStartPaintBar(this.paintItem);
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = e => {
      const {
        isCreatingDependancy,
        getDateOnPosition,
        onPointerMove,
        doPaintBar
      } = this.props;

      if (this.paintItem) {
        const pointerPosition = getPointerClientOffset(e);
        const date = getDateOnPosition(pointerPosition);
        const { paintItem: prevPaintItem } = this;

        this.paintItem = {
          ...prevPaintItem,
          startDate: minDate(date, prevPaintItem.origin.date),
          endDate: maxDate(date, prevPaintItem.origin.date)
        };

        if (
          prevPaintItem.startDate !== this.paintItem.startDate ||
          prevPaintItem.endDate !== this.paintItem.endDate
        )
          doPaintBar(this.paintItem);

        this.setState({
          cursorPosition: false
        });
      } else {
        const jobItemId = getTargetJobItemId(e);
        const containerNode = jobItemId && getTargetJobItemElement(e);
        const isPaintable =
          containerNode && getElementHasClass(containerNode, "paintable");

        if (isPaintable && !isCreatingDependancy) {
          const { x } = getPointerClientOffset(e);
          const { y } = getElementClientCenterOffset(containerNode);

          this.setState({
            cursorPosition: { x, y }
          });
        } else {
          this.setState({
            cursorPosition: null
          });
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = e => {
      const { onPointerUp, doEndPaintBar } = this.props;

      if (this.paintItem) {
        doEndPaintBar({
          ...this.paintItem,
          hasChanged: true
        });

        this.paintItem = null;
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = e => {
      const { onLostPointerCapture, doCancelPaintBar } = this.props;

      if (this.paintItem) {
        doCancelPaintBar(this.paintItem);

        this.paintItem = null;
        this.setState({ cursorPosition: null });
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp
        // onLostPointerCapture: this.onLostPointerCapture
      };
    }

    render() {
      const { props, handlers } = this;
      const { cursorPosition } = this.state;

      return (
        <>
          <WrappedComponent {...{ ...props, ...handlers }} />
          {cursorPosition && (
            <CustomCursorCircle
              style={{
                left: cursorPosition.x,
                top: cursorPosition.y
              }}
            />
          )}
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(JobItemPaintHandlers);
};

const CustomCursorCircle = styled.div`
  position: fixed;
  background-color: black;
  z-index: var(--z-index-schedule-cursor);
  pointer-events: none;
  width: 14px;
  height: 14px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  will-change: left;
`;
