import { ENTITY_CLASS_JOB_PHASE } from "../constants";
import { sendApplyModelData, sendRemoveModel } from "../WebAppAPI";
import API from "./index";
import { getNewJobPhaseAsMessage } from "./messages";
import { createModelSetParser } from "./modelSets";

export const updateJobPhaseAPI = (jobPhase, addJobItemsToPhase = null) =>
  API.put(
    `/job_phases/${jobPhase.id}${
      addJobItemsToPhase && addJobItemsToPhase.length > 0
        ? `?add_job_item_ids=${addJobItemsToPhase.join(",")}`
        : ""
    }`,
    jobPhase
  )
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));

export const createJobPhaseAPI = (jobId, jobPhase, jobItemIds = []) => {
  return API.post(`/job_phases`, {
    jobItemIds,
    jobPhase: getNewJobPhaseAsMessage(jobPhase),
  })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));
};

export const deleteJobPhaseAPI = (
  jobPhaseId,
  deleteJobItems = false,
  replacementJobPhaseId = null
) => {
  if (jobPhaseId > 0) {
    let url = `/job_phases/${jobPhaseId}?delete_job_items=${deleteJobItems}`;

    if (replacementJobPhaseId !== null)
      url += `&replacement_job_phase_id=${replacementJobPhaseId}`;

    return API.delete(url)
      .then(({ data }) => data)
      .then((data) => {
        sendRemoveModel(ENTITY_CLASS_JOB_PHASE, jobPhaseId);
        sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
        return data;
      })
      .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));
  }

  return Promise.resolve({});
};

export const duplicateJobPhaseAPI = (jobPhaseId) => {
  return API.post(`/job_phases/${jobPhaseId}/duplicate`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));
};

export const updateJobPhaseStatusAPI = (jobPhaseId, jobItemStatusId) =>
  API.post(
    `/job_phases/${jobPhaseId}/job_phase_status?job_item_status_id=${jobItemStatusId}`
  )
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));

export const jobPhaseBillableAPI = (jobPhaseId, isBillable) => {
  return API.post(`/job_phases/${jobPhaseId}/billable?isBillable=${isBillable}`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_PHASE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_PHASE));
};
