import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobItemId } from "../../index";
import { selectJobItemUi } from "./index";

export const selectJobItemPreviousJobItem = createCachedSelector(
  [selectJobItemUi],
  jobItem => get(jobItem, `previousJobItem`, null)
)(selectPropsJobItemId);

export const useJobItemPreviousJobItem = jobItemId =>
  useSelector(state => selectJobItemPreviousJobItem(state, { jobItemId }));
