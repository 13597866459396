import { useDispatch } from "react-redux";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";

import {
  JOB_ITEM_STATUS_CHANGE,
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
} from "../../../lib/constants";
import {
  createJobItemStatusType,
  getJobItemNormalizedStatus,
} from "../../../lib/entities/jobItemEntity";
import { createJobItemUserStatusType } from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemLockCompleteItem } from "../../../redux/selectors/jobItem/selectJobItemLockCompleteItem";
import { StatusComponent } from "../components/StatusComponent";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";

const ItemStatus = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const lockCompleteItem = useJobItemLockCompleteItem(jobItemId);
  const statusId = getJobItemNormalizedStatus(jobItem);

  const dispatch = useDispatch();

  const doStatusChange = (status, userStatus) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_STATUS_CHANGE, {
        id: jobItemId,
        jobItem,
        jobItemStatus: createJobItemStatusType(status),
        jobItemUserStatus: createJobItemUserStatusType(userStatus),
      })
    );
  };

  const setPlanning = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_PLANNING, JobItemUserStatuses.Planning);

  const setSchedule = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_SCHEDULED, JobItemUserStatuses.Scheduled);

  const setComplete = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_COMPLETE, JobItemUserStatuses.Complete);

  return (
    <StatusComponent
      statusId={statusId}
      setPlanning={setPlanning}
      setSchedule={setSchedule}
      setComplete={setComplete}
      disabled={!isJobEditable}
      locked={lockCompleteItem}
      lockedTooltipText="Want to keep working on this item? Change the status of a team member."
    />
  );
};

export default ItemStatus;
