import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_JOB_PHASES } from "../../../lib/constants";
import { selectPropsJobPhaseId } from "../index";

export const selectStateJobPhases = state =>
  state.entities[ENTITY_NAME_JOB_PHASES];

export const selectJobPhase = createCachedSelector(
  [selectStateJobPhases, selectPropsJobPhaseId],
  (jobPhases, jobPhaseId) => get(jobPhases, `byId.${jobPhaseId}`)
)(selectPropsJobPhaseId);

export const useJobPhase = jobPhaseId =>
  useSelector(state => selectJobPhase(state, { jobPhaseId }));
