import numeral from "numeral";
import * as PropTypes from "prop-types";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";
import { StatusType } from "st-shared/types";

import {
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  JOB_ITEM_USER_SNOOZE_STATUS_ID_NONE,
} from "../constants";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";

export const jobItemUserShape = {
  id: entityIdType.isRequired,
  jobItemId: entityIdType.isRequired,
  jobItemUserStatus: typeEntityType.isRequired,
  jobItemUserSnoozeStatus: typeEntityType.isRequired,
  jobCurrencySellRate: entityFieldDecimalType,
  totalIncompleteMinutes: entityFieldDecimalType,
  totalLoggedMinutes: entityFieldDecimalType,
  totalPlannedMinutes: entityFieldDecimalType,
  jobCurrencyTotalIncompleteTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalPlannedTimeExTax: entityFieldDecimalType,
  userId: entityIdType.isRequired,
};

export const JOB_ITEM_USER_DEFAULTS = {
  jobCurrencySellRate: "",
  jobItemUserSnoozeStatus: {
    id: JOB_ITEM_USER_SNOOZE_STATUS_ID_NONE,
    name: "None",
  },
  snoozeDatetime: null,
  totalIncompleteMinutes: 0,
  totalLoggedMinutes: 0,
  totalPlannedMinutes: 0,
  jobCurrencyTotalIncompleteTimeExTax: "",
  jobCurrencyTotalLoggedTimeExTax: "",
  jobCurrencyTotalPlannedTimeExTax: "",
  jobCurrencyTotalLoggedTimeCostExTax: "",
};

export const jobItemUserEntityType = PropTypes.shape(jobItemUserShape);

export const getJobItemIdUserIdPath = (entity) =>
  `["${entity.jobItemId}.${entity.userId}"]`;

export const isDeleted = ({ jobItemUserStatus }) =>
  jobItemUserStatus.id === JobItemUserStatuses.Deleted;

export const isJobItemUserComplete = ({ jobItemUserStatus }) =>
  jobItemUserStatus.id === JobItemUserStatuses.Complete;

export const getTotalIncompleteMinutes = ({ totalIncompleteMinutes }) =>
  asDecimal(totalIncompleteMinutes);

export const getTotalUsedScheduledMinutes = ({
  totalIncompleteMinutes,
  totalLoggedMinutes,
}) =>
  numeral(asDecimal(totalIncompleteMinutes))
    .add(asDecimal(totalLoggedMinutes))
    .value();

export const getUserId = ({ userId }) => userId;

export const getTotalScheduledMinutes = ({ totalIncompleteMinutes }) =>
  asDecimal(totalIncompleteMinutes);

export const getTotalUsedMinutes = ({ totalLoggedMinutes }) =>
  asDecimal(totalLoggedMinutes);

export const getTotalPlannedMinutes = ({ totalPlannedMinutes }) =>
  asDecimal(totalPlannedMinutes);

export const getTotalRemainingMinutes = ({
  totalLoggedMinutes,
  totalPlannedMinutes,
}) =>
  numeral(asDecimal(totalPlannedMinutes))
    .subtract(asDecimal(totalLoggedMinutes))
    .value();

export const getTotalUsedScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalIncompleteTimeExTax))
    .add(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const getTotalScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
}) => asDecimal(jobCurrencyTotalIncompleteTimeExTax);

export const getTotalUsedMoney = ({ jobCurrencyTotalLoggedTimeExTax }) =>
  asDecimal(jobCurrencyTotalLoggedTimeExTax);

export const getTotalUsedCostMoney = ({
  jobCurrencyTotalLoggedTimeCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedTimeCostExTax);

export const getTotalPlannedMoney = ({ jobCurrencyTotalPlannedTimeExTax }) =>
  asDecimal(jobCurrencyTotalPlannedTimeExTax);

export const getTotalRemainingMoney = ({
  jobCurrencyTotalPlannedTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalPlannedTimeExTax))
    .subtract(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const getJobItemUserStatusId = ({ jobItemUserStatus }) =>
  jobItemUserStatus.id;

export const getJobItemUserSnoozeStatusId = ({ jobItemUserSnoozeStatus }) =>
  jobItemUserSnoozeStatus.id;

export const getStatusType = (jobItemUser) => {
  switch (getJobItemUserStatusId(jobItemUser)) {
    case JobItemUserStatuses.Planning:
      return StatusType.Planning;
    case JobItemUserStatuses.Complete:
      return StatusType.Complete;
    case JobItemUserStatuses.Scheduled:
      return StatusType.Scheduled;
    default:
      return null;
  }
};

export const getJobItemUserNormalizedStatus = ({ jobItemUserStatus }) => {
  switch (jobItemUserStatus.id) {
    case JobItemUserStatuses.Planning:
      return "planning";
    case JobItemUserStatuses.Complete:
      return "complete";
    case JobItemUserStatuses.Scheduled:
      return "scheduled";
    default:
      return "";
  }
};

export const getJobCurrencySellRate = ({ jobCurrencySellRate }) =>
  asDecimal(jobCurrencySellRate);

export const createJobItemUserStatusType = (id) => {
  switch (id) {
    case JobItemUserStatuses.Planning:
      return { id, name: "Paused" };
    case JobItemUserStatuses.Complete:
      return { id, name: "Complete" };
    case JobItemUserStatuses.Scheduled:
      return { id, name: "In Play" };
    case JobItemUserStatuses.Deleted:
      return { id, name: "Deleted" };
    default:
      return { id };
  }
};

export const getJobItemUserStatusFromJobItemStatus = (jobItemStatusId) => {
  switch (jobItemStatusId) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return createJobItemUserStatusType(JobItemUserStatuses.Planning);
    case JOB_ITEM_STATUS_ID_SCHEDULED:
    default:
      return createJobItemUserStatusType(JobItemUserStatuses.Scheduled);
  }
};

export const mergeScheduleLoggedTimeWithJobItemUser = (
  scheduleLoggedTime,
  jobItemUser
) => ({
  ...scheduleLoggedTime,
  jobItemUserStatusId: getJobItemUserStatusId(jobItemUser),
});

export const createNewJobItemUser = ({
  id = null,
  jobId,
  jobItemId,
  userId,
  jobItemUserStatus,
  jobCurrencySellRate = "",
  totalPlannedMinutes = 0,
}) => ({
  id,
  jobId,
  jobItemId,
  userId,
  jobItemUserStatus,
  jobCurrencySellRate,
  jobItemUserSnoozeStatus: {
    id: JOB_ITEM_USER_SNOOZE_STATUS_ID_NONE,
    name: "None",
  },
  snoozeDatetime: null,
  totalIncompleteMinutes: 0,
  totalLoggedMinutes: 0,
  totalPlannedMinutes,
  jobCurrencyTotalIncompleteTimeExTax: "",
  jobCurrencyTotalLoggedTimeExTax: "",
  jobCurrencyTotalPlannedTimeExTax: "",
});
