import { call, put } from "redux-saga/effects";

import API from "../../../lib/API";
import { SCHEDULE_FILTER_SAVED_SEGMENTS } from "../../../lib/constants/development";
import { actionJobListModelFilterResultsReceived } from "../../../state/entities/jobListModel/actions";
import { EntityNames } from "../../../state/entities/types";
import { actionScheduleFilterIsSearching } from "../../../state/ui/schedule/actions";

export default function* watchWebAPIScheduleSearchResults() {
  if (import.meta.env.DEV && window.location.pathname.includes("schedule")) {
    yield put(actionScheduleFilterIsSearching(true));

    const { data } = yield call(
      API.post,
      "/jobs/search",
      SCHEDULE_FILTER_SAVED_SEGMENTS["ACTIVE_JOBS"],
      {
        s: true,
      }
    );

    const { searchResults } = data;

    yield put(
      actionJobListModelFilterResultsReceived({
        [EntityNames.JobListModels]: searchResults,
      })
    );

    yield put(actionScheduleFilterIsSearching(false));
  }
}
