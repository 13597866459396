import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";

import { ellipsisText, theme } from "../../theme";
import { Icon, StPriceArrow } from "..";
import {
  dropdownButtonCss,
  dropdownDefaultTextCss,
  dropdownIconCss,
} from "./DropdownButton.css";

export interface DropdownButtonProps extends ComponentPropsWithRef<"div"> {
  className?: string;
  defaultTextClassName?: string;
  value: string | null;
  defaultValue?: string;
}

export const DropdownButton = forwardRef<HTMLDivElement, DropdownButtonProps>(
  ({ className, defaultTextClassName, value, defaultValue, ...props }, ref) => {
    const text = value || defaultValue || "";
    return (
      <div {...props} className={clsx(dropdownButtonCss, className)} ref={ref}>
        <div
          className={clsx(
            ellipsisText,
            value === null &&
              defaultValue &&
              clsx(dropdownDefaultTextCss, defaultTextClassName)
          )}
        >
          {text}
        </div>
        <Icon
          icon={StPriceArrow}
          size={7}
          color={theme.color.charcoal}
          className={dropdownIconCss}
        />
      </div>
    );
  }
);

DropdownButton.displayName = "DropdownButton";
