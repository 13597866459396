import { produce } from "immer";
import { isEqual, remove } from "lodash-es";
import { Entity } from "st-shared/entities/Entity";
import { EntityOption } from "st-shared/entities/EntityOption";
import { defaultSortOptions } from "st-shared/lib";

import { ChangedEntity } from "../entities/types";

function createEntityOptionsReducer<T extends Entity, U extends EntityOption>(
  getOption: (entity: T) => U | false,
  sortOptions: (a: U, b: U) => number = defaultSortOptions
) {
  return function (state: U[] = [], changedEntities: ChangedEntity<T>[]) {
    return produce(state, (draft: U[]) => {
      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevOption = prevEntity && getOption(prevEntity);
        const newOption = newEntity && getOption(newEntity);

        if (!isEqual(prevOption, newOption)) {
          if (prevOption) {
            remove(draft, (option) => option.key === prevOption.key);
          }

          if (newOption) {
            draft.push(newOption);
          }
        }
      });

      draft.sort(sortOptions);
    });
  };
}

export default createEntityOptionsReducer;
