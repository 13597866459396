import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobItemIdsByJobId } from "./jobItemIdsByJobId";
import { selectStateJobItems } from "./index";

export const selectJobItemUpcomingDates = createCachedSelector(
  [selectStateJobItems, selectJobItemIdsByJobId],
  (jobItems, jobItemIds) => {
    const upcomingDates = [];

    jobItemIds
      .map(id => get(jobItems, `upcomingDatesById.${id}`))
      .forEach(jobItemUpcomingDate => {
        jobItemUpcomingDate.forEach(upcomingDate => {
          upcomingDates.push(upcomingDate);
        });
      });

    return upcomingDates;
  }
)(selectPropsJobId);

export const useJobItemUpcomingDates = jobId =>
  useSelector(state => selectJobItemUpcomingDates(state, { jobId }));
