import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { isComplete } from "../../../lib/entities/jobItemEntity";
import { selectPropsJobItemId } from "../index";
import { selectJobItemUserIdsByJobItemId } from "../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectJobItem } from "./index";

export const selectJobItemLockCompleteItem = createCachedSelector(
  [selectJobItem, selectJobItemUserIdsByJobItemId],
  (jobItem, jobItemUsers) => jobItemUsers.length > 0 && isComplete(jobItem)
)(selectPropsJobItemId);

export const useJobItemLockCompleteItem = jobItemId =>
  useSelector(state => selectJobItemLockCompleteItem(state, { jobItemId }));
