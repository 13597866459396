import clsx from "clsx";
import { forwardRef, ReactNode } from "react";
import { MdClose, MdInfoOutline } from "react-icons/md";

import { Icon, IconButton, IconSize, Tooltip } from "../../../components";
import { usePermissions } from "../../../stores";
import { FilterGroupTypeProperties } from "../../../types";
import { useFilterContext } from "../FilterContext";
import { FilterGroup } from "../types";
import {
  deleteButtonContainer,
  displayContainerCss,
  displayContainerNoAccessCss,
} from "./FilterDisplay.css";

interface Props {
  filterGroup: FilterGroup;
  onDelete: (filterId: string) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  className?: string;
}

export const FilterDisplay = forwardRef<HTMLDivElement, Props>(
  ({ filterGroup, onDelete, onClick, children, className }, ref) => {
    const { canAccessCostRates, canViewJobFinancials } = usePermissions();
    const { containsCostRate, containsFinancial } =
      FilterGroupTypeProperties[filterGroup.filterGroupTypeId];
    const noPermissionAccess =
      (!canAccessCostRates && containsCostRate) ||
      (!canViewJobFinancials && containsFinancial);

    const { displayBackgroundColor, warningTooltip } = useFilterContext();

    return (
      <Tooltip
        title={warningTooltip || ""}
        placement="left"
        style={{ maxWidth: 100 }}
      >
        <div
          className={clsx(
            displayContainerCss,
            noPermissionAccess && displayContainerNoAccessCss,
            className
          )}
          style={{ backgroundColor: displayBackgroundColor }}
          ref={ref}
          onClick={noPermissionAccess ? undefined : onClick}
        >
          <div>{children}</div>
          {Boolean(warningTooltip) && (
            <Icon icon={MdInfoOutline} size={IconSize.Medium} />
          )}
          <div
            className={deleteButtonContainer}
            style={{
              background: `linear-gradient(90deg, transparent, ${displayBackgroundColor} 20%)`,
            }}
          >
            <IconButton
              iconProps={{ icon: MdClose, size: IconSize.Medium }}
              onClick={() => onDelete(filterGroup.id)}
            />
          </div>
        </div>
      </Tooltip>
    );
  }
);

FilterDisplay.displayName = "FilterDisplay";
