// deprecated

import type { Option } from "./Option";

export const ActivityEntryParentTypes = {
  Job: 1,
  ActivityEntryThread: 2,
  Quote: 3,
  Invoice: 4,
  LoggedExpense: 5,
} as const;

export type ActivityEntryParentTypesType =
  (typeof ActivityEntryParentTypes)[keyof typeof ActivityEntryParentTypes];

export type Type = ActivityEntryParentType;
export interface ActivityEntryParentType extends Option {
  id: ActivityEntryParentTypesType;
}

export function isJob(entity: ActivityEntryParentType) {
  return entity.id === ActivityEntryParentTypes.Job;
}

export function isActivityEntryThread(entity: ActivityEntryParentType) {
  return entity.id === ActivityEntryParentTypes.ActivityEntryThread;
}

export function isQuote(entity: ActivityEntryParentType) {
  return entity.id === ActivityEntryParentTypes.Quote;
}

export function isInvoice(entity: ActivityEntryParentType) {
  return entity.id === ActivityEntryParentTypes.Invoice;
}

export function isLoggedExpense(entity: ActivityEntryParentType) {
  return entity.id === ActivityEntryParentTypes.LoggedExpense;
}

export function createActivityEntryParentType(
  id: ActivityEntryParentTypesType
) {
  switch (id) {
    case ActivityEntryParentTypes.Job:
      return { id, name: "Job" };
    case ActivityEntryParentTypes.ActivityEntryThread:
      return { id, name: "ActivityEntryThread" };
    case ActivityEntryParentTypes.Quote:
      return { id, name: "Quote" };
    case ActivityEntryParentTypes.Invoice:
      return { id, name: "Invoice" };
    case ActivityEntryParentTypes.LoggedExpense:
      return { id, name: "LoggedExpense" };
  }
}
