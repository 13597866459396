import { createIcon } from "../../icon/Icon";

export const IconPersonStarFilled = createIcon(
  <>
    <path d="M16.3388 13L16.9075 10.4118L15 8.67105L17.52 8.44079L18.5 6L19.48 8.44079L22 8.67105L20.0925 10.4118L20.6612 13L18.5 11.6276L16.3388 13Z" />
    <path d="M10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12Z" />
    <path d="M2 20V17.2C2 16.6333 2.14583 16.1125 2.4375 15.6375C2.72917 15.1625 3.11667 14.8 3.6 14.55C4.63333 14.0333 5.68333 13.6458 6.75 13.3875C7.81667 13.1292 8.9 13 10 13C11.1 13 12.1833 13.1292 13.25 13.3875C14.3167 13.6458 15.3667 14.0333 16.4 14.55C16.8833 14.8 17.2708 15.1625 17.5625 15.6375C17.8542 16.1125 18 16.6333 18 17.2V20H2Z" />
  </>,
  "IconPersonStarFilled"
);
