import type {
  TEntityDescriptorEnum,
  TFilterGroupTypeEnum,
  TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { FilterGroupTypeEnum } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import { Fragment, useMemo } from "react";
import {
  getSearchableReportingFilters,
  ReportingFiltersDefaultDescriptionsOverride,
} from "st-shared/entities";
import { pluralise } from "st-shared/lib";
import type {
  FilterGroupFilter,
  TFilterListHeaderType,
} from "st-shared/module";
import {
  Filter,
  FilterContextProvider,
  OperatorButton,
} from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";
import { theme } from "st-shared/theme";
import type { TConditionMatchType } from "st-shared/types";
import {
  EntityDescriptorProperties,
  getFilterName,
  getNextCondition,
} from "st-shared/types";

import { reportingStore } from "../../../state/stores/reportingStore";
import {
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentGlobalFilters,
  useReportingSavedSegmentGlobalFiltersConditionMatchType,
} from "../../../state/stores/savedSegmentSelectors";
import { filterWarningCss } from "./GlobalFilters.css";

type Props = {
  uuid: string;
};

type DatasetWarnings = Dictionary<TEntityDescriptorEnum, boolean>;

type FilterWarning = { hasWarning: boolean; datasetWarnings: DatasetWarnings };

type FilterWarnings = Dictionary<TFilterGroupTypeEnum, FilterWarning>;

export function GlobalFiltersList({ uuid }: Props) {
  const datasets = useReportingSavedSegmentDataSets();
  const globalFilters = useReportingSavedSegmentGlobalFilters();
  const conditionMatchTypeId =
    useReportingSavedSegmentGlobalFiltersConditionMatchType();

  const filterWarnings = useMemo<FilterWarnings>(() => {
    const entityDescriptorIds = Object.values(datasets).map(
      (dataset) => dataset.entityDescriptorId
    );

    const warnings: FilterWarnings = {};

    globalFilters.forEach((filterGroup) => {
      const warning: FilterWarning = { hasWarning: false, datasetWarnings: {} };

      entityDescriptorIds.forEach((entityDescriptorId) => {
        const datasetFilters =
          getSearchableReportingFilters(entityDescriptorId);
        const datasetWarning = !datasetFilters.includes(
          filterGroup.filterGroupTypeId
        );
        warning.datasetWarnings[entityDescriptorId] = datasetWarning;

        if (datasetWarning) {
          warning.hasWarning = true;
        }
      });

      warnings[filterGroup.filterGroupTypeId] = warning;
    });

    return warnings;
  }, [datasets, globalFilters]);

  function onSetGlobalFilterConditionMatch() {
    reportingStore().savedSegment.actions.setGlobalFilterConditionMatch(
      getNextCondition(conditionMatchTypeId)
    );
  }

  function onFilterDelete(filterId: string) {
    reportingStore().savedSegment.actions.deleteGlobalFilterGroup(filterId);
  }

  function onAddGlobalFilterGroupFilter(
    filterId: string,
    filter: FilterGroupFilter
  ) {
    reportingStore().savedSegment.actions.addGlobalFilterGroupFilter(
      filterId,
      filter
    );
  }

  function onSetFilterGroupConditionMatch(
    filterId: string,
    conditionMatchTypeId: TConditionMatchType
  ) {
    reportingStore().savedSegment.actions.setGlobalFilterGroupConditionMatch(
      filterId,
      conditionMatchTypeId
    );
  }

  function onSetFilterGroupValueMatch(
    filterId: string,
    valueMatchTypeId: TValueMatchTypeEnum
  ) {
    reportingStore().savedSegment.actions.setGlobalFilterGroupValueMatch(
      filterId,
      valueMatchTypeId
    );
  }

  function onSetFilterGroupFilter(
    filterId: string,
    filterIndex: number,
    filter: FilterGroupFilter
  ) {
    reportingStore().savedSegment.actions.setGlobalFilterGroupFilter(
      filterId,
      filterIndex,
      filter
    );
  }

  function onDeleteFilterGroupFilters(filterId: string, indexes: number[]) {
    reportingStore().savedSegment.actions.deleteGlobalFilterGroupFilters(
      filterId,
      indexes
    );
  }

  return (
    <>
      {globalFilters.map((filterGroup, index) => {
        return (
          <FilterContextProvider
            key={filterGroup.id}
            filterListHeaderType={getFilterListHeaderType(
              filterGroup.filterGroupTypeId
            )}
            displayBackgroundColor={theme.color.yellowlight}
            warningTooltip={
              filterWarnings[filterGroup.filterGroupTypeId]?.hasWarning ? (
                <WarningTooltip
                  filterGroupTypeId={filterGroup.filterGroupTypeId}
                  warning={filterWarnings[filterGroup.filterGroupTypeId]!}
                />
              ) : undefined
            }
          >
            {index !== 0 && (
              <OperatorButton
                onClick={onSetGlobalFilterConditionMatch}
                conditionMatchTypeId={conditionMatchTypeId}
              />
            )}
            <Filter
              uuid={uuid}
              filterGroup={filterGroup}
              onDelete={onFilterDelete}
              addFilter={onAddGlobalFilterGroupFilter}
              setFilterGroupConditionMatch={onSetFilterGroupConditionMatch}
              setFilterGroupValueMatch={onSetFilterGroupValueMatch}
              setFilter={onSetFilterGroupFilter}
              deleteFilters={onDeleteFilterGroupFilters}
              defaultDescriptions={ReportingFiltersDefaultDescriptionsOverride}
            />
          </FilterContextProvider>
        );
      })}
    </>
  );
}

function getFilterListHeaderType(
  filterGroupTypeId: TFilterGroupTypeEnum
): TFilterListHeaderType {
  if (
    (
      [
        FilterGroupTypeEnum.TeamMember,
        FilterGroupTypeEnum.RoleId,
        FilterGroupTypeEnum.UserBranchId,
      ] as TFilterGroupTypeEnum[]
    ).includes(filterGroupTypeId)
  ) {
    return null;
  }
  return "is/not";
}

type WarningTooltipProps = {
  filterGroupTypeId: TFilterGroupTypeEnum;
  warning: FilterWarning;
};

function WarningTooltip({ filterGroupTypeId, warning }: WarningTooltipProps) {
  const customerCurrency = useCustomerCurrency();

  const names = Object.keys(warning.datasetWarnings)
    .filter(
      (entityDescriptorId) =>
        warning.datasetWarnings[
          Number(entityDescriptorId) as TEntityDescriptorEnum
        ] === false
    )
    .map(
      (entityDescriptorId) =>
        EntityDescriptorProperties[
          Number(entityDescriptorId) as TEntityDescriptorEnum
        ].name
    );

  if (names.length === 0) {
    return (
      <div className={filterWarningCss}>
        <b>{getFilterName(filterGroupTypeId, customerCurrency)}</b> does not
        apply to any current filter sets.
      </div>
    );
  }

  return (
    <div className={filterWarningCss}>
      <b>{getFilterName(filterGroupTypeId, customerCurrency)}</b> applies only
      to{" "}
      {names.map((name, index) => {
        return (
          <Fragment key={name}>
            <b>{name}</b>
            {names.length > 2 && index < names.length - 2 && <span>, </span>}
            {names.length > 1 && index === names.length - 2 && (
              <span> and </span>
            )}
          </Fragment>
        );
      })}{" "}
      filter {pluralise(names.length, "set")}.
    </div>
  );
}
