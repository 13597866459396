import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  SCHEDULE_USER_JOB_COLLAPSE,
  SCHEDULE_USER_JOB_EXPAND,
} from "../../../lib/constants";
import { getJobStatusIconClass } from "../../../lib/entities/jobEntity";
import { scheduleSearchResultEntityType } from "../../../lib/entities/scheduleSearchResultEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleUserJobUi } from "../../../redux/selectors/scheduleSelectors";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import JobMilestones from "../../modules/JobMilestone";
import MilestonesTooltip from "../ScheduleLayout/MilestonesTooltip";
import ScheduleJobMilestoneGroup from "../ScheduleLayout/ScheduleJobMilestoneGroup";
import Anchor from "../ScheduleScrollProvider/Anchor";
import ScheduleBarConsumer from "../ScheduleScrollProvider/ScheduleBarConsumer";
import ScheduleJobMilestoneDatesConsumer from "../ScheduleScrollProvider/ScheduleJobMilestoneDatesConsumer";
import { ScheduleBarRect, ScheduleJobBar } from "./ScheduleBars";
import ScheduleJobTeamMemberTodos from "./ScheduleJobTeamMemberTodos";

const mapState = (state, props) => {
  const { isExpanded = false } = selectScheduleUserJobUi(state, props);
  return {
    scheduleJob: selectJobListModel(state, { jobId: props.id }),
    isExpanded,
  };
};

const mapDispatch = (dispatch) => ({
  doExpand: (userId, jobId) =>
    dispatch(createAction(SCHEDULE_USER_JOB_EXPAND, { userId, jobId })),
  doCollapse: (userId, jobId) =>
    dispatch(createAction(SCHEDULE_USER_JOB_COLLAPSE, { userId, jobId })),
});

class ScheduleUserJob extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    userId: entityIdType.isRequired,
    scheduleJob: scheduleSearchResultEntityType.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
  };

  toggleExpand = () => {
    const { id, userId, isExpanded, doExpand, doCollapse } = this.props;
    if (isExpanded) doCollapse(userId, id);
    else doExpand(userId, id);
  };

  render() {
    const { id, userId, scheduleJob, isExpanded } = this.props;
    const {
      estimatedStartDate,
      estimatedEndDate,
      earliestStartDate,
      latestEndDate,
      jobStatusId,
    } = scheduleJob;

    return (
      <Wrapper>
        <JobContainer onClick={this.toggleExpand}>
          <ScheduleBarConsumer
            barStartDate={earliestStartDate}
            barEndDate={latestEndDate}
            startDate={estimatedStartDate}
            dueDate={estimatedEndDate}
          >
            {({ outer, inner }) => (
              <Anchor>
                {outer && (
                  <ScheduleBarRect
                    disabled
                    style={{ left: outer.left, width: outer.width }}
                  >
                    <ScheduleJobBar
                      className={getJobStatusIconClass(jobStatusId)}
                    />
                  </ScheduleBarRect>
                )}
                {inner && (
                  <ScheduleBarRect
                    disabled
                    style={{ left: inner.left, width: inner.width }}
                  >
                    <ScheduleJobBar
                      inner
                      className={[getJobStatusIconClass(jobStatusId)]
                        .concat(inner.classes)
                        .join(" ")}
                    />
                  </ScheduleBarRect>
                )}
              </Anchor>
            )}
          </ScheduleBarConsumer>
          <Anchor>
            <ScheduleJobMilestoneDatesConsumer jobId={id}>
              {({ date, left, width, jobMilestoneIds }) => (
                <MilestonesTooltip key={date} jobMilestoneIds={jobMilestoneIds}>
                  <ScheduleJobMilestoneGroup key={date} style={{ left, width }}>
                    <JobMilestones
                      jobMilestoneIds={jobMilestoneIds}
                      date={date}
                    />
                  </ScheduleJobMilestoneGroup>
                </MilestonesTooltip>
              )}
            </ScheduleJobMilestoneDatesConsumer>
          </Anchor>
        </JobContainer>
        {isExpanded && <ScheduleJobTeamMemberTodos id={userId} jobId={id} />}
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserJob);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const JobContainer = styled.div`
  height: ${ScheduleUi.User.JobRowHeight}px;
  border-top: var(--border-thin-bright);
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const UserJobSearchingPulser = () => (
  <JobContainer>
    <div
      style={{
        width: 800,
        height: 8,
        borderRadius: 4,
        marginTop: 11,
        animation: "var(--animation-pulsate)",
        backgroundColor: "var(--color-gray-bright)",
      }}
    />
  </JobContainer>
);
