import { get } from "lodash-es";
import { ReactNode, useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  selectScheduleTeamMemberRoleRowHeightsByJobId,
  selectScheduleTeamMemberUserRowHeightsByJobId,
} from "../../../redux/selectors/scheduleSelectors";
import { useJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

interface JobTeamMemberRowsConsumerProps {
  id: number;
  offsetTop: number;
  renderUser: (userId: number) => ReactNode;
  renderRole: (roleId: number, name: string) => ReactNode;
}

interface Row {
  id: number;
  height: number;
  top: number;
}

function JobTeamMemberRowsConsumer({
  id,
  offsetTop,
  renderUser,
  renderRole,
}: JobTeamMemberRowsConsumerProps) {
  const { viewportHeight } = useContext(ScheduleScrollContext);
  const jobListModel = useJobListModel(id)!;
  const userHeightsById = useSelector((state) =>
    selectScheduleTeamMemberUserRowHeightsByJobId(state, { id })
  );
  const roleHeightsById = useSelector((state) =>
    selectScheduleTeamMemberRoleRowHeightsByJobId(state, { id })
  );

  const userRows: Row[] = [];
  const roleRows: (Row & { name: string })[] = [];
  let currentOffsetY = offsetTop + ScheduleUi.Job.RowHeight + 1;
  let relativeOffsetY = 0;

  jobListModel.users.forEach(({ id }) => {
    const height = get(userHeightsById, id);
    const visibleTop = currentOffsetY;
    const visibleBottom = visibleTop + height;

    if (visibleTop <= viewportHeight && visibleBottom >= 0) {
      userRows.push({
        id,
        height,
        top: relativeOffsetY,
      });
    }

    currentOffsetY += height;
    relativeOffsetY += height;
  });

  jobListModel.roles.forEach(({ id, name }) => {
    const height = get(roleHeightsById, id);
    const visibleTop = currentOffsetY;
    const visibleBottom = visibleTop + height;

    if (visibleTop <= viewportHeight && visibleBottom >= 0) {
      roleRows.push({
        id,
        height,
        top: relativeOffsetY,
        name,
      });
    }

    currentOffsetY += height;
    relativeOffsetY += height;
  });

  return (
    <>
      {userRows.map(({ id, height, top }) => (
        <RowCell key={id} style={{ height, top }}>
          {renderUser(id)}
        </RowCell>
      ))}
      {roleRows.map(({ id, height, top, name }) => (
        <RowCell key={id} style={{ height, top }}>
          {renderRole(id, name)}
        </RowCell>
      ))}
    </>
  );
}

export default JobTeamMemberRowsConsumer;

const RowCell = styled.div`
  width: 100%;
  border-bottom: var(--border-thin-bright);
  position: absolute;
  &:last-child {
    border: none;
  }
`;
