import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { applyInverseExchangeRate } from "st-shared/lib";
import { getPermissionsSnapshot } from "st-shared/stores";

import { currencyEntityType } from "../../../lib/entities/currencyEntity";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../lib/types/entityTypes";
import {
  selectJobCurrencyById,
  selectJobExchangeRateById,
} from "../../../redux/selectors/rateCardSelectors";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import Currency from "./index";

const mapState = (state, props) => ({
  jobCurrency: selectJobCurrencyById(state, props),
  jobExchangeRate: selectJobExchangeRateById(state, props),
  customerCurrency: selectCustomerCurrency(state),
  canViewJobFinancials: getPermissionsSnapshot().canViewJobFinancials,
});

const JobCurrency = ({
  jobCurrency,
  jobExchangeRate,
  customerCurrency,
  canViewJobFinancials,
  convertToHomeCurrency,
  hideHomeCurrency,
  value,
  ...props
}) => {
  if (!canViewJobFinancials) return "";

  const isHomeCurrency = jobCurrency.id === customerCurrency.id;

  if (isHomeCurrency && hideHomeCurrency) return "";

  if (!isHomeCurrency && convertToHomeCurrency)
    return (
      <Currency
        value={applyInverseExchangeRate(value, jobExchangeRate)}
        {...props}
      />
    );

  return <Currency currency={jobCurrency} value={value} {...props} />;
};

JobCurrency.propTypes = {
  id: entityIdType.isRequired,
  jobCurrency: currencyEntityType.isRequired,
  jobExchangeRate: entityFieldDecimalType.isRequired,
  value: entityFieldDecimalType.isRequired,
  customerCurrency: currencyEntityType.isRequired,
  canViewJobFinancials: PropTypes.bool.isRequired,
  convertToHomeCurrency: PropTypes.bool,
  hideHomeCurrency: PropTypes.bool,
};

JobCurrency.defaultProps = {
  convertToHomeCurrency: false,
  hideHomeCurrency: false,
};

export default connect(mapState)(JobCurrency);
