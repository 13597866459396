import reduceChangedEntities from "../reduceChangedEntities";

const paintBlockReducer = (state, action) => {
  const changedEntities = [
    {
      newEntity: action.payload.scheduleLoggedTime
    }
  ];

  return reduceChangedEntities(state, changedEntities);
};

export default paintBlockReducer;
