import { createIcon } from "../../icon/Icon";

export const IconStatusOpenGroupRoundFilled = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.5 19.25V8H9V5H15V8H19.5V19.25H4.5ZM10.5 8H13.5V6.5H10.5V8ZM10.125 17L15.375 13.625L10.125 10.25V17Z"
  />,
  "IconStatusOpenGroupRoundFilled"
);
