import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { simpleCompareAsc } from "../../../lib/dates";
import { selectPropsJobId } from "../index";
import { selectInvoiceUpcomingDates } from "../invoices/selectInvoiceUpcomingDates";
import { selectJobItemUpcomingDates } from "../jobItem/selectJobItemUpcomingDates";
import { selectJobMilestoneUpcomingDates } from "../jobMilestone/selectJobMilestoneUpcomingDates";
import {
  selectJobDatesInvoicesFilter,
  selectJobDatesItemsFilter,
  selectJobDatesMilestonesFilter
} from "../userPreferenceSelectors";

export const selectUpcomingDates = createCachedSelector(
  [
    selectJobItemUpcomingDates,
    selectJobMilestoneUpcomingDates,
    selectInvoiceUpcomingDates,
    selectJobDatesItemsFilter,
    selectJobDatesMilestonesFilter,
    selectJobDatesInvoicesFilter
  ],
  (
    jobItemUpcomingDates,
    jobMilestoneUpcomingDates,
    invoiceUpcomingDates,
    isItemsFilterActive,
    isMilestonesFilterActive,
    isInvoicesFilterActive
  ) => {
    const upcomingDates = [];
    if (isItemsFilterActive) upcomingDates.push(...jobItemUpcomingDates);
    if (isMilestonesFilterActive)
      upcomingDates.push(...jobMilestoneUpcomingDates);
    if (isInvoicesFilterActive) upcomingDates.push(...invoiceUpcomingDates);

    upcomingDates.sort((i1, i2) => simpleCompareAsc(i1.date, i2.date));

    const unfilteredDatesLength =
      jobItemUpcomingDates.length +
      jobMilestoneUpcomingDates.length +
      invoiceUpcomingDates.length;

    return [upcomingDates, unfilteredDatesLength];
  }
)(selectPropsJobId);

export const useUpcomingDates = jobId =>
  useSelector(state => selectUpcomingDates(state, { jobId }));
