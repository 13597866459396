import { isEmpty } from "lodash-es";

import { ENTITY_CLASS_JOB_ITEM } from "../constants";
import { sendApplyModelData, sendRemoveModel } from "../WebAppAPI";
import API from "./index";
import {
  getJobItemAsMessage,
  getJobItemRoleAsMessage,
  getJobItemSubItemAsMessage,
  getJobItemUserAsMessage,
} from "./messages";
import { createModelSetParser } from "./modelSets";

export const updateJobItemAPI = (
  jobItem,
  modifiedJobItemDependancies,
  deletedJobItemDependancyIds,
  jobItemUsers,
  jobItemSubItems,
  jobItemRoles = []
) => {
  jobItem = getJobItemAsMessage(jobItem);
  const modifyJobItemDependancies = isEmpty(modifiedJobItemDependancies)
    ? null
    : modifiedJobItemDependancies;
  const removeJobItemDependancyIds = isEmpty(deletedJobItemDependancyIds)
    ? null
    : deletedJobItemDependancyIds;
  jobItemUsers = isEmpty(jobItemUsers)
    ? null
    : jobItemUsers.map((jobItemUser) => getJobItemUserAsMessage(jobItemUser));
  jobItemSubItems = isEmpty(jobItemSubItems)
    ? null
    : jobItemSubItems.map((jobItemSubItem) =>
        getJobItemSubItemAsMessage(jobItemSubItem)
      );
  jobItemRoles = isEmpty(jobItemRoles)
    ? null
    : jobItemRoles
        .filter((jobItemRole) => jobItemRole.id > 0 || jobItemRole.active)
        .map((jobItemRole) => getJobItemRoleAsMessage(jobItemRole));

  return API.put(`/job_items/${jobItem.id}`, {
    jobItem,
    modifyJobItemDependancies,
    removeJobItemDependancyIds,
    jobItemUsers,
    jobItemSubItems,
    jobItemRoles,
  })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM));
};

export const updateJobItemStatusAPI = (
  jobItem,
  jobItemUsers,
  removeOverdueIncompleteTodos = false
) => {
  jobItem = getJobItemAsMessage(jobItem);
  jobItemUsers = isEmpty(jobItemUsers)
    ? null
    : jobItemUsers.map((jobItemUser) => getJobItemUserAsMessage(jobItemUser));

  return API.put(
    `/job_items/${jobItem.id}`,
    {
      jobItem,
      jobItemUsers,
    },
    removeOverdueIncompleteTodos
      ? { remove_overdue_incomplete_todos: true }
      : null
  )
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM));
};

export const deleteJobItemAPI = (jobItemId) =>
  jobItemId > 0
    ? API.delete(`/job_items/${jobItemId}`)
        .then(({ data }) => data)
        .then((data) => {
          sendRemoveModel(ENTITY_CLASS_JOB_ITEM, jobItemId);
          sendApplyModelData(ENTITY_CLASS_JOB_ITEM, data);
          return data;
        })
        .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM))
    : Promise.resolve({});

export const createJobItemAPI = (
  jobId,
  jobItem,
  jobItemUsers,
  jobItemSubItems,
  jobItemRoles = []
) => {
  jobItem = getJobItemAsMessage(jobItem, true);
  jobItemUsers = isEmpty(jobItemUsers)
    ? null
    : jobItemUsers.map((jobItemUser) => getJobItemUserAsMessage(jobItemUser));
  jobItemSubItems = isEmpty(jobItemSubItems)
    ? null
    : jobItemSubItems.map((jobItemSubItem) =>
        getJobItemSubItemAsMessage(jobItemSubItem)
      );

  jobItemRoles = isEmpty(jobItemRoles)
    ? null
    : jobItemRoles
        .filter((jobItemRole) => jobItemRole.active)
        .map((jobItemRole) => getJobItemRoleAsMessage(jobItemRole));

  return API.post(`/jobs/${jobId}/job_items`, {
    jobItem,
    jobItemUsers,
    jobItemSubItems,
    jobItemRoles,
  })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM));
};

export const duplicateJobItemAPI = (jobItemId) => {
  return API.post(`/job_items/${jobItemId}/duplicate`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB_ITEM, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM));
};
