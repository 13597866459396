export const COLOR_SNOW = "#fafafa";
export const COLOR_JONSNOW_LIGHT = "#F5F5F5";
export const COLOR_JONSNOW = "#f7f7f7";
export const COLOR_YGRITTESNOW = "#f2f2f2";
export const COLOR_GRAY_LIGHT = "#ececec";
export const COLOR_GRAY_BRIGHT = "#e2e2e2";
export const COLOR_GRAY = "#bdc3c7";
export const COLOR_GRAY_MEDIUM = "#979797";
export const COLOR_GRAY_DARK = "#868c8d";
export const COLOR_CHARCOAL = "#4a4a4a";

export const COLOR_GREEN_LIGHT = "#c8e6c9";
export const COLOR_GREEN_LIGHT_SECONDARY = "#D1E6CE";
export const COLOR_GREEN_BRIGHT = "#81c784";
export const COLOR_GREEN = "#4caf50";
export const COLOR_GREEN_DARK = "#1b5e20";
export const COLOR_GREEN_DARK_SECONDARY = "#507E71";
export const COLOR_GREEN_2 = "#75B36A";

export const COLOR_YELLOW_LIGHT = "#fff9c4";
export const COLOR_YELLOW_BRIGHT = "#fff176";
export const COLOR_YELLOW = "#ffeb3b";
export const COLOR_YELLOW_DARK = "#feda10";

export const COLOR_CYAN_LIGHT = "#b2ebf2";
export const COLOR_CYAN_BRIGHT = " #4dd0e1";
export const COLOR_CYAN = "#00bdc4";
export const COLOR_CYAN_DARK = "#006673";

export const COLOR_BLUE_LIGHT = "#8cdffb";
export const COLOR_BLUE_BRIGHT = "#3accfb";
export const COLOR_BLUE_ACTIVE = "#00bcd4";
export const COLOR_BLUE = "#288eaf";
export const COLOR_BLUE_DARK = "#1c647b";

export const COLOR_TEAL_LIGHT = "#94e9d7";
export const COLOR_TEAL_BRIGHT = "#50e3c2";
export const COLOR_TEAL = "#297362";
export const COLOR_TEAL_DARK = "#154037";

export const COLOR_RED_LIGHT = "#ffb9b9";
export const COLOR_RED_BRIGHT = "#ef7c84";
export const COLOR_RED = "#e03f50";
export const COLOR_RED_DARK = "#d0021b";
