import type { PopoverOrigin } from "@material-ui/core";
import { filter } from "lodash-es";
import { useEffect, useState } from "react";
import { searchWordMatches } from "st-shared/lib";

import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import { useUserByJobItemUserId } from "../../../redux/selectors/jobItemUser/selectUserByJobItemUserId";
import { useRoleByUserId } from "../../../redux/selectors/role/selectRoleByUserId";
import { useJobItemUserOptions } from "../../../state/entities/user/selectors/useJobItemUserOptions";
import Switch from "../../elements/Switch";
import PopoverMenu from "../Menu/PopoverMenu";
import AvailabilityMenuHeader from "../UserMenus/AvailabilityMenuHeader";
import * as styles from "./ReassignUserMenu.css";
import { UserResults } from "./UserResults";
import useUserAvailability from "./useUserAvailability";

interface Props {
  anchorEl?: any;
  jobItemUserId: number;
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  reassignUser: (userId: number) => void;
  close: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

function ReassignUserMenu({
  anchorEl,
  jobItemUserId,
  defaultStartDate,
  defaultEndDate,
  reassignUser,
  close,
  anchorOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: -5,
    horizontal: -5,
  },
}: Props) {
  const jobItemUser = useJobItemUser(jobItemUserId)!;
  const jobItem = useJobItem(jobItemUser.jobItemId);
  const isJobEditable = useIsJobEditable(jobItem.jobId);
  const user = useUserByJobItemUserId(jobItemUserId);
  const role = useRoleByUserId(user.id);

  const [roleOnly, setRoleOnly] = useState(Boolean(role));

  const options = useJobItemUserOptions();

  let userOptions = filter(options, (option) => option.key !== user.id);

  if (roleOnly && role) {
    userOptions = filter(userOptions, (option) => option.roleId === role.id);
  }

  useEffect(() => {
    if (userOptions.length === 0) {
      setRoleOnly(false);
    }
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const userResults =
    searchQuery.length > 0
      ? userOptions.filter((option: any) =>
          searchWordMatches(option.searchString, searchQuery)
        )
      : userOptions;

  function onClose() {
    setSearchQuery("");
    close();
  }

  function onPickUsers(userIds: number[]) {
    const userId = userIds[0];
    reassignUser(userId);
    onClose();
  }

  const { userAvailability, onChangeDateRange, initialFetch } =
    useUserAvailability(defaultStartDate, defaultEndDate);

  if (!isJobEditable) return null;

  return (
    <PopoverMenu
      classes={{ paper: styles.popoverPaper }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      TransitionProps={{
        onEnter: initialFetch,
      }}
      HeaderComponent={
        <div>
          <AvailabilityMenuHeader
            hasOptions={Boolean(userOptions.length)}
            hasResults={Boolean(userResults.length)}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder="Search by name, branch or role..."
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            onChange={onChangeDateRange}
            unrestrictDates
            title={`Reassign User`}
            noOptionsMessage="Looks like there aren’t any team members to select. Toggle to show all team members."
          />
          {role && (
            <div className={styles.roleOnlySelector}>
              <div>
                <span className={styles.roleTitle}>{role.name}</span>
                <span> only</span>
              </div>
              <Switch
                checked={roleOnly}
                onChange={() => setRoleOnly(!roleOnly)}
              />
            </div>
          )}
        </div>
      }
    >
      <UserResults
        results={userResults}
        onPickUsers={onPickUsers}
        userAvailability={userAvailability}
      />
    </PopoverMenu>
  );
}

export default ReassignUserMenu;
