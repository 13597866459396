import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { JOB_TIMELINE_HEADER_HEIGHT } from "../../../lib/constants";
import { entityIdType } from "../../../lib/types/entityTypes";
import Flex from "../../elements/Flex";
import JobTimelineBodyColumnCell from "../JobTimelineBodyColumnCell";
import JobTimelineDatesHeader from "../JobTimelineDatesHeader";
import JobTimelineGroupColumnCell from "../JobTimelineGroupColumnCell";
import JobTimelineInfo from "./JobTimelineInfo";
import JobTimelineNavigation from "./JobTimelineNavigation";

const JobTimelineHeader = ({ jobId, readOnly }) => (
  <JobTimelineHeaderWrapper>
    <JobTimelineInfo jobId={jobId} readOnly={readOnly} />
    <Flex>
      <JobTimelineGroupColumnCell
        style={{ display: "flex", borderRight: "var(--border-thick-bright)" }}
      >
        <JobTimelineNavigation />
      </JobTimelineGroupColumnCell>
      <JobTimelineBodyColumnCell>
        <JobTimelineDatesHeader jobId={jobId} readOnly={readOnly} />
      </JobTimelineBodyColumnCell>
    </Flex>
  </JobTimelineHeaderWrapper>
);

export default JobTimelineHeader;

JobTimelineHeader.propTypes = {
  jobId: entityIdType.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export const JobTimelineHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_HEADER_HEIGHT}px;
`;
