import { keyBy } from "lodash-es";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  LINE_ITEMS_SORT_START,
  INVOICE_LINE_ITEM_SORT_START
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { createSnapshotId } from "../../helpers/snapshotIds";
import { selectInvoiceLineItemsByInvoiceId } from "../../selectors/invoiceLineItemSelectors";
import { selectInvoiceUi } from "../../selectors/invoiceSelectors";

function* startSortLineItems(action) {
  const { invoiceId } = yield select(selectInvoiceUi);

  if (!invoiceId) return;

  try {
    const invoiceLineItems = yield select(selectInvoiceLineItemsByInvoiceId, {
      invoiceId
    });

    const snapshotId = createSnapshotId();
    const snapshot = keyBy(invoiceLineItems, "id");

    yield put(
      createAction(INVOICE_LINE_ITEM_SORT_START, {
        invoiceId,
        snapshotId,
        data: snapshot
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLineItemsSortStart() {
  yield takeLatest(LINE_ITEMS_SORT_START, startSortLineItems);
}
