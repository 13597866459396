import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { PermissionsConsumer } from "st-shared/stores";
import styled from "styled-components";

import {
  COLOR_GRAY,
  DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR,
  DATE_FORMAT_DAY_ORDINAL_DATE_MONTH,
  DATE_FORMAT_INVOICE,
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { invoiceEntityType } from "../../../lib/entities/invoiceEntity";
import {
  getContactFullName,
  getJobCompanyName,
  jobEntityType,
} from "../../../lib/entities/jobEntity";
import {
  getFormattedJobItemDateRange,
  getJobItemName,
} from "../../../lib/entities/jobItemEntity";
import { jobMilestoneType } from "../../../lib/entities/jobMilestoneEntity";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectSortedInvoicesByJobId } from "../../../redux/selectors/invoiceSelectors";
import { selectJobPhaseOrderByJobId } from "../../../redux/selectors/jobItemSelectors";
import { selectSortedJobMilestonesByJobId } from "../../../redux/selectors/jobMilestoneSelectors";
import { selectJobByJobId } from "../../../redux/selectors/jobSelectors";
import { withPDFPageWrapper } from "../../modules/PDFPageWrapper";
import JobTimelineSummaryPhaseConnector from "./JobTimelineSummaryPhaseConnector";

const mapState = (state, props) => ({
  job: selectJobByJobId(state, props),
  orderedJobPhaseIds: selectJobPhaseOrderByJobId(state, props),
  jobMilestones: selectSortedJobMilestonesByJobId(state, props),
  invoices: selectSortedInvoicesByJobId(state, props),
});

const mapDispatch = null;

class JobTimelineSummary extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    job: jobEntityType.isRequired,
    orderedJobPhaseIds: entityIdListType.isRequired,
    jobMilestones: PropTypes.arrayOf(jobMilestoneType).isRequired,
    invoices: PropTypes.arrayOf(invoiceEntityType).isRequired,
  };

  componentDidMount() {
    // hack to fix for the pdf generated fix in the future so that the main body
    // isn't grey in general
    document.body.style.backgroundColor = "white";
  }

  render() {
    const { jobId, job, orderedJobPhaseIds, jobMilestones, invoices } =
      this.props;
    return (
      <Wrapper>
        <Container>
          <Table>
            <Tbody>
              {job.number && (
                <Tr className="heading">
                  <Th colSpan={6}>{job.number}</Th>
                </Tr>
              )}

              <Tr>
                <td colSpan={6}>
                  <JobName>{job.name}</JobName>
                </td>
              </Tr>

              <Tr>
                <td colSpan="6" style={{ height: 8 }} />
              </Tr>

              <Tr className="heading">
                <Th>Client</Th>
                <Td spacer />
                <Th>Client Contact</Th>
                <Td spacer />
                <Td />
                <Td spacer />
              </Tr>
              <Tr>
                <Td style={{ minHeight: 36 }}>{getJobCompanyName(job)}</Td>
                <Td spacer />
                <Td style={{ minHeight: 36 }}>{getContactFullName(job)}</Td>
                <Td spacer />
                <Td />
                <Td spacer />
              </Tr>

              <Tr>
                <td colSpan="6" style={{ height: 35 }} />
              </Tr>

              <Tr className="heading">
                <Th width="125px">Start Date</Th>
                <Td spacer />
                <Th>End Date</Th>
                <Td spacer />
                <Td />
                <Td spacer />
              </Tr>
              <Tr>
                <Td>
                  {job.estimatedStartDate ? (
                    formatDate(
                      job.estimatedStartDate,
                      DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                    )
                  ) : (
                    <span>No start date</span>
                  )}
                </Td>
                <Td spacer />
                <Td>
                  {job.estimatedStartDate ? (
                    formatDate(
                      job.estimatedEndDate,
                      DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                    )
                  ) : (
                    <span>No end date</span>
                  )}
                </Td>
                <Td spacer />
                <Td />
                <Td spacer />
              </Tr>
            </Tbody>
            {orderedJobPhaseIds.length > 0 && (
              <Tbody>
                <Tr className="heading">
                  <Th colSpan={6} style={{ paddingTop: 20 }}>
                    Timings
                  </Th>
                </Tr>
                {orderedJobPhaseIds.map((id) => (
                  <JobTimelineSummaryPhaseConnector
                    jobId={jobId}
                    id={id}
                    key={id}
                  >
                    {({ jobPhase, jobItems }) => (
                      <>
                        {jobPhase && (
                          <Tr className="listItem">
                            <Th colSpan={6}>{jobPhase.name}</Th>
                          </Tr>
                        )}
                        {jobItems.map((jobItem) => (
                          <Tr className="listItem" key={jobItem.id}>
                            <Td>
                              {getFormattedJobItemDateRange(
                                jobItem,
                                DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR
                              )}
                            </Td>
                            <Td spacer />
                            <Th colSpan={4}>{getJobItemName(jobItem)}</Th>
                          </Tr>
                        ))}
                      </>
                    )}
                  </JobTimelineSummaryPhaseConnector>
                ))}
              </Tbody>
            )}
            {jobMilestones.length > 0 && (
              <Tbody>
                <Tr className="heading">
                  <Th colSpan={6} style={{ paddingTop: 20 }}>
                    Key Milestones
                  </Th>
                </Tr>
                {jobMilestones.map((jobMilestone) => (
                  <Tr className="listItem" key={jobMilestone.id}>
                    <Td>
                      {formatDate(
                        jobMilestone.date,
                        DATE_FORMAT_DAY_ORDINAL_DATE_MONTH
                      )}
                    </Td>
                    <Td spacer />
                    <Th colSpan={4}>{jobMilestone.name}</Th>
                  </Tr>
                ))}
              </Tbody>
            )}
            <PermissionsConsumer>
              {({ canEditJobs }) =>
                canEditJobs &&
                invoices.length > 0 && (
                  <Tbody>
                    <Tr className="heading">
                      <Th colSpan={6} style={{ paddingTop: 20 }}>
                        Invoices Issued
                      </Th>
                    </Tr>
                    {invoices.map((invoice) => (
                      <Tr className="listItem" key={invoice.id}>
                        <Td>
                          {formatDate(invoice.invoiceDate, DATE_FORMAT_INVOICE)}
                        </Td>
                        <Td spacer />
                        <Th colSpan={4}>
                          ‘{invoice.name}’ due{" "}
                          {formatDate(invoice.dueDate, DATE_FORMAT_INVOICE)}
                        </Th>
                      </Tr>
                    ))}
                    <Tr>
                      <td colSpan="6" style={{ height: 35 }} />
                    </Tr>
                  </Tbody>
                )
              }
            </PermissionsConsumer>
          </Table>
        </Container>
      </Wrapper>
    );
  }
}

export default withPDFPageWrapper(
  connect(mapState, mapDispatch)(JobTimelineSummary)
);

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
`;

const Tbody = styled.tbody``;

const Th = styled.th`
  color: black;
  font-size: 8px;
  font-weight: bold;
  line-height: 10px;
  padding: 6px 0;
  ${(props) => !props.colSpan && `width: 125px`};
`;

const Td = styled.td`
  color: black;
  font-size: 8px;
  line-height: 10px;
  padding: 6px 0;
  ${({ spacer }) => spacer && `width: 30px`};
`;

const Tr = styled.tr`
  page-break-inside: avoid !important;
  &.heading {
    th {
      border-bottom: 1px solid ${COLOR_GRAY};
    }
  }
  &.listItem {
    border-bottom: 1px dashed ${COLOR_GRAY};
    th,
    td {
      padding: 10px 0;
    }
  }
`;

const JobName = styled.h1`
  color: black;
  font-size: 32.5px;
  font-weight: bold;
  line-height: 39px;
  margin: 6px 0;
`;
