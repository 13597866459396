import { Exception, WebAPI } from "st-shared/lib";

import { webAppAccountingPlatformDetails } from "../WebAppAPI/bootstrap";

export async function fetchAccountingPlatformDetailsApi(
  id: number,
  name: string
) {
  try {
    const details = await webAppAccountingPlatformDetails(id);
    return details;
  } catch (error) {
    if (error instanceof Exception) {
      console.warn(error.message);
    } else {
      console.warn("Error retrieving accounting platform details from web");
    }
  }

  return WebAPI.AccountingPlatform.details(id, name).then(({ data }) => data);
}
