import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DetailTableReadOnlyTextField from "./DetailTableReadOnlyTextField";

const mapContext = ({ id, document, jobNumber, documentMethods }) => ({
  id,
  value: jobNumber,
  isDisplayed: documentMethods.getDisplayOptions(document).displayJobNumber,
  labelKey: "jobNumber"
});

export default consumeCommercialDocument(mapContext)(
  DetailTableReadOnlyTextField
);
