import { createModelSetParser } from "../API/modelSets";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, { SEND_OPEN_CREATE_EDIT_CONTACT_MODAL } from "./index";

export const sendOpenCreateEditContactModal = (
  companyData = null,
  contactData = null
) =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_CREATE_EDIT_CONTACT_MODAL,
      companyData,
      contactData
    );

    if (jQueryDeferred)
      jQueryDeferred
        .then(({ contact, response }) => {
          return {
            contact,
            ...createModelSetParser(response)
          };
        })
        .then(resolve)
        .fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_CREATE_EDIT_CONTACT_MODAL}`
        )
      );
  });
