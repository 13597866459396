import {
  ACCOUNTING_PLATFORM_FETCH_FAILED,
  ACCOUNTING_PLATFORM_FETCH_REQUEST,
  ACCOUNTING_PLATFORM_FETCH_SUCCEEDED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const initialState = {
  isFetching: false,
  isFetched: false,
  isDisconnected: false,
  data: null
};

const onFetchRequest = state => ({
  ...state,
  isFetching: true,
  data: null
});

const onFetchSucceeded = (state, { payload: { data } }) => ({
  ...state,
  isFetching: false,
  isFetched: true,
  isDisconnected: false,
  data
});

const onFetchFailed = state => ({
  ...state,
  isFetching: false,
  isFetched: false,
  isDisconnected: true,
  data: null
});

export default createReducer(initialState, {
  [ACCOUNTING_PLATFORM_FETCH_REQUEST]: onFetchRequest,
  [ACCOUNTING_PLATFORM_FETCH_SUCCEEDED]: onFetchSucceeded,
  [ACCOUNTING_PLATFORM_FETCH_FAILED]: onFetchFailed
});
