import { produce } from "immer";
import numeral from "numeral";
import { select, put } from "redux-saga/effects";
import {
  JOB_ITEM_USER_EDIT,
  JOB_ITEM_USER_SET_RATE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";

function* setRate(action) {
  try {
    const { jobItemId, jobItemUserId, value } = action.payload;

    const jobItemUser = yield select(selectJobItemUserById, {
      id: jobItemUserId
    });

    const newJobItemUser = produce(jobItemUser, draft => {
      draft.jobCurrencySellRate = numeral(value).value();
    });

    yield put(
      createAction(JOB_ITEM_USER_EDIT, {
        jobItemId,
        jobItemUsers: [{ new: newJobItemUser, prev: jobItemUser }]
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemUserSetRate() {
  yield takeLatestBy(
    [JOB_ITEM_USER_SET_RATE],
    setRate,
    action => action.payload.jobItemUserId
  );
}
