import { useEffect, useRef, useState } from "react";

export function useObjectWithCount(
  size: number | undefined,
  padding: number,
  dividerSpace: number | undefined
) {
  const ref = useRef();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const trueWidth = entries[0].contentRect.width - padding - (size || 0);

      if (trueWidth <= 0) {
        setCount(1);
      } else {
        setCount(
          Math.floor(trueWidth / ((size || 0) + (dividerSpace || 0))) + 1
        );
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, [setCount]);

  return [ref, count];
}
