import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_INVOICES,
  INVOICE_EDIT,
  INVOICE_SAVE,
  INVOICE_SAVE_FAILED
} from "../../../../lib/constants";
import {
  getJobIdInvoiceDateKeyPath,
  getJobIdInvoiceMonthKeyPath,
  getUpcomingDates,
  isDeleted
} from "../../../../lib/entities/invoiceEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createEntityVariationReducer from "../../../helpers/createEntityVariationReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import dateRangeByJobIdReducer from "./dateRangeByJobIdReducer";

const idsByJobIdIndexer = createEntityIndexedArrayReducer(
  entity => entity.jobId
);

const idsByJobIdInvoiceDateReducer = createEntityIndexedArrayReducer(entity =>
  getJobIdInvoiceDateKeyPath(entity)
);

const idsByJobIdInvoiceMonthReducer = createEntityIndexedArrayReducer(entity =>
  getJobIdInvoiceMonthKeyPath(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdIndexer(state.idsByJobId, changedEntities),
    idsByJobIdInvoiceDate: idsByJobIdInvoiceDateReducer(
      state.idsByJobIdInvoiceDate,
      changedEntities
    ),
    idsByJobIdInvoiceMonth: idsByJobIdInvoiceMonthReducer(
      state.idsByJobIdInvoiceMonth,
      changedEntities
    ),
    upcomingDatesById: createEntityVariationReducer(
      state.upcomingDatesById,
      changedEntities,
      getUpcomingDates
    )
  };

  nextState = dateRangeByJobIdReducer(state, nextState, changedEntities);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_INVOICES],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_INVOICES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const editInvoiceReducer = (state, action) => {
  const { id, invoice } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: state.byId[id],
      newEntity: invoice
    }
  ]);
};

const editInvoiceErrorReducer = (state, action) => {
  const { id, prevInvoice } = action.payload;
  const prevEntity = state.byId[id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: prevInvoice
    }
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [INVOICE_EDIT]: editInvoiceReducer,
    [INVOICE_SAVE]: editInvoiceReducer,
    [INVOICE_SAVE_FAILED]: editInvoiceErrorReducer
  }
);
