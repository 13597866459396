import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_ITEM_CREATE, JOB_PHASE_CREATE } from "../../../lib/constants";
import useDebounceValue from "../../../lib/hooks/useDebounceValue";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { IconButton } from "../../elements/Button";
import AddCircleIcon from "../../elements/Icons/AddCircleIcon";
import AddHeadingIcon from "../../elements/Icons/AddHeadingIcon";
import AddItemIcon from "../../elements/Icons/AddItemIcon";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import PopoverMenu from "../../modules/Menu/PopoverMenu";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useJobId } from "../context/JobIdContext";
import AddNewBlankItemSubMenu from "./AddNewBlankItemSubMenu";
import AddNewMasterItemSubMenu from "./AddNewMasterItemSubMenu";

const AddNewButton = ({ fromJobItemId, fromJobPhaseId }) => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);

  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const openMenu = (e) => setAnchorEl(ref.current);
  const closeMenu = () => setAnchorEl(null);

  const handleAddPhase = () => {
    closeMenu();
    if (!isJobEditable) return;

    dispatch(
      createAction(JOB_PHASE_CREATE, {
        jobId,
        fromJobItemId,
        fromJobPhaseId,
      })
    );
  };

  const handleAddMasterJobItem = (masterJobItemId) => {
    closeMenu();
    if (!isJobEditable) return;

    dispatch(
      createAction(JOB_ITEM_CREATE, {
        jobId,
        fromJobItemId,
        fromJobPhaseId,
        masterJobItemId,
      })
    );
  };

  const handleAddBlankItem = (costingMethodId) => {
    closeMenu();
    if (!isJobEditable) return;

    dispatch(
      createAction(JOB_ITEM_CREATE, {
        jobId,
        fromJobItemId,
        fromJobPhaseId,
        costingMethodId,
      })
    );
  };

  const [masterItemSubMenuAnchorEl, setMasterItemSubMenuAnchorEl] =
    useState(null);
  const debouncedMasterItemSubMenuAnchorEl = useDebounceValue(
    masterItemSubMenuAnchorEl
  );

  const openMasterItemSubMenu = (e) => {
    closeSubMenus();
    setMasterItemSubMenuAnchorEl(e.target.closest(".MenuItem-MasterItem"));
  };

  const [blankItemSubMenuAnchorEl, setBlankItemSubMenuAnchorEl] =
    useState(null);
  const debouncedBlankItemSubMenuAnchorEl = useDebounceValue(
    blankItemSubMenuAnchorEl
  );

  const openBlankItemSubMenu = (e) => {
    closeSubMenus();
    setBlankItemSubMenuAnchorEl(e.target.closest(".MenuItem-BlankItem"));
  };

  const closeSubMenus = () => {
    setMasterItemSubMenuAnchorEl(null);
    setBlankItemSubMenuAnchorEl(null);
  };

  useEffect(() => {
    if (!anchorEl) {
      closeSubMenus();
    }
  }, [anchorEl]);

  if (!isJobEditable) return null;

  return (
    <Container>
      <Button
        tabIndex={-1}
        ref={ref}
        className={anchorEl ? "active" : ""}
        onClick={openMenu}
      >
        <AddCircleIcon size={null} />
      </Button>
      <PopoverMenu anchorEl={anchorEl} onClose={closeMenu}>
        <MenuItem onClick={handleAddPhase} onMouseEnter={closeSubMenus}>
          <ListItemIcon>
            <AddHeadingIcon size={ICON_SIZE.X_LARGE} />
          </ListItemIcon>
          <ListItemText>Add phase</ListItemText>
        </MenuItem>
        <MenuItem
          hasSubMenu
          isSubMenuOpen={Boolean(debouncedMasterItemSubMenuAnchorEl)}
          className="MenuItem-MasterItem"
          onMouseEnter={openMasterItemSubMenu}
        >
          <ListItemIcon>
            <AddItemIcon size={ICON_SIZE.X_LARGE} />
          </ListItemIcon>
          <ListItemText>Add item</ListItemText>
        </MenuItem>
        <MenuItem
          hasSubMenu
          isSubMenuOpen={Boolean(debouncedBlankItemSubMenuAnchorEl)}
          className="MenuItem-BlankItem"
          onMouseEnter={openBlankItemSubMenu}
        >
          <ListItemIcon>
            <AddItemIcon size={ICON_SIZE.X_LARGE} />
          </ListItemIcon>
          <ListItemText>Add blank item</ListItemText>
        </MenuItem>
      </PopoverMenu>
      {anchorEl && debouncedMasterItemSubMenuAnchorEl && (
        <AddNewMasterItemSubMenu
          anchorEl={debouncedMasterItemSubMenuAnchorEl}
          onSetItem={handleAddMasterJobItem}
        />
      )}
      {anchorEl && debouncedBlankItemSubMenuAnchorEl && (
        <AddNewBlankItemSubMenu
          anchorEl={debouncedBlankItemSubMenuAnchorEl}
          onSetItem={handleAddBlankItem}
        />
      )}
    </Container>
  );
};

AddNewButton.propTypes = {
  fromJobItemId: PropTypes.number,
  fromJobPhaseId: PropTypes.number,
};

AddNewButton.defaultProps = {
  fromJobItemId: null,
  fromJobPhaseId: null,
};

export default AddNewButton;

const Container = styled.div.attrs({
  className: "AddLineButtonContainer",
})`
  position: absolute;
  left: -35px;
`;

const Button = styled(IconButton).attrs({
  className: "AddLineButton",
})`
  width: 30px;
  height: 20px;
  color: var(--color-gray);
  opacity: 0;
  transition: var(--transition-mui-opacity);
  &:hover {
    color: var(--color-gray-medium);
    opacity: 1;
  }
  &.active {
    color: var(--color-charcoal);
    opacity: 1;
  }
`;
