import { useSelector } from "react-redux";
import { timeAllocationMethodObj } from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import { isComplete as isJobItemComplete } from "st-shared/entities/JobItemStatus";
import { isComplete as isJobItemUserComplete } from "st-shared/entities/JobItemUserStatus";

import { selectJobItemRolesByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRolesByJobItemId";
import { selectJobItem } from "../jobItem";
import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";
import { selectJobItemUsersByJobItemId } from "../jobItemUser/selectJobItemUsersByJobItemId";

const selectJobCalculatedUnscheduledMinutes = (
  state: unknown,
  { jobId }: { jobId: TEntityId }
) => {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

  let totalUnscheduledMinutes = 0;

  for (const jobItemId of jobItemIds) {
    const jobItem = selectJobItem(state, { jobItemId });

    if (isJobItemComplete(jobItem.jobItemStatus)) {
      continue;
    }

    const timeAllocationMethod = timeAllocationMethodObj(
      jobItem.timeAllocationMethod
    );

    if (timeAllocationMethod.isItem()) {
      const jobItemUnscheduledMinutes = Math.max(
        jobItem.totalPlannedMinutes -
          jobItem.totalIncompleteMinutes -
          jobItem.totalLoggedMinutes,
        0
      );

      totalUnscheduledMinutes += jobItemUnscheduledMinutes;
    } else if (timeAllocationMethod.isPeople()) {
      const jobItemUsers = selectJobItemUsersByJobItemId(state, { jobItemId });
      const jobItemRoles = selectJobItemRolesByJobItemId(state, { jobItemId });

      for (const jobItemUser of jobItemUsers) {
        if (isJobItemUserComplete(jobItemUser.jobItemUserStatus)) {
          continue;
        }

        const jobItemUserUnscheduledMinutes = Math.max(
          jobItemUser.totalPlannedMinutes -
            jobItemUser.totalIncompleteMinutes -
            jobItemUser.totalLoggedMinutes,
          0
        );

        totalUnscheduledMinutes += jobItemUserUnscheduledMinutes;
      }

      for (const jobItemRole of jobItemRoles) {
        totalUnscheduledMinutes += jobItemRole.totalPlannedMinutes;
      }
    }
  }

  return totalUnscheduledMinutes;
};

export const useJobCalculatedUnscheduledMinutes = (jobId: TEntityId) => {
  return useSelector((state) =>
    selectJobCalculatedUnscheduledMinutes(state, { jobId })
  );
};
