import { mapKeys } from "lodash-es";

import { ENTITY_CLASS_JOB } from "../constants";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { createModelSetParser } from "./modelSets";

export const saveScheduledBlockAPI = (baseScheduleLoggedTime, days) => {
  const { id, jobItemId } = baseScheduleLoggedTime;
  const params = {
    days,
    job_item_id: jobItemId,
  };

  const response =
    id < 0
      ? API.post(
          `/schedule_logged_times`,
          {
            ...baseScheduleLoggedTime,
            id: null,
            scheduleBlockBaseLoggedTimeId: null,
          },
          {
            ...params,
            include_non_working_days: true,
          }
        )
      : API.put(`/schedule_logged_times/${id}`, baseScheduleLoggedTime, params);

  return response
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser())
    .then(({ data }) => data);
};

export const saveScheduledBlockBulkAPI = (scheduleLoggedTimes) => {
  return API.post(`/schedule_logged_times/bulk_update`, {
    scheduleLoggedTimes,
  })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser())
    .then(({ data }) => data);
};

export const deleteScheduledBlockAPI = (id) =>
  id > 0 &&
  API.delete(`/schedule_logged_times/${id}`)
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser());

export function deleteScheduledBlockBulkAPI(ids) {
  ids = ids.filter((id) => id > 0);
  return API.post(`/schedule_logged_times/bulk_delete`, { ids })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser());
}

export const shiftJobDurationAPI = (id, days) =>
  API.post(`/jobs/${id}/shift_duration`, { days })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(ENTITY_CLASS_JOB, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB));

export const splitScheduleBlockAPI = (blockKey, days) =>
  API.put(`/schedule_logged_times/${blockKey}/split`, null, { days })
    .then(({ data }) => data)
    .then((data) => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser());

export const fetchScheduleAvailabilityAPI = (startDate, endDate) =>
  API.fetch(`/schedule/availability`, {
    start_date: startDate,
    end_date: endDate,
    schedule_mode: true,
  })
    .then(({ data }) => data)
    .then(({ users }) => mapKeys(users, (val, key) => Number(key)));
