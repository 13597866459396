import { LabelTypeEnum } from "@streamtimefe/entities";

import { useIsJobEditable } from "../../redux/selectors/jobDetails/ui/isJobEditable";
import Spacer from "../elements/Spacer";
import Labels from "../modules/Labels";
import ActivitySection from "./ActivitySection";
import { useJobId } from "./context/JobIdContext";
import JobName from "./Jumbotron/JobName";
import JumbotronSection from "./JumbotronSection";
import { containerCss, mainContainerCss } from "./TopSection.css";

export function TopSection() {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);

  return (
    <div className={containerCss}>
      <JobName />
      <Spacer h={15} />
      <Labels
        entityId={jobId}
        labelTypeId={LabelTypeEnum.Job}
        readOnly={!isJobEditable}
      />
      <Spacer h={40} />
      <main className={mainContainerCss}>
        <JumbotronSection />
        <Spacer w={30} />
        <ActivitySection></ActivitySection>
      </main>
    </div>
  );
}
