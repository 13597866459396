import { produce } from "immer";
import { isEmpty } from "lodash-es";
import { ItemRate } from "st-shared/entities";

import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import createKeyValueReducer from "../../helpers/createKeyValueReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";
import { ChangedEntity, EntityNames } from "../types";
import { ItemRatesAction } from "./actions";
import { ItemRatesState } from "./types";

const initialState: ItemRatesState = {
  byId: {},
  masterJobItemRates: {},
  masterLoggedExpenseRates: {},
};

const masterJobItemRatesReducer = createKeyValueReducer<ItemRate.Type, number>(
  ItemRate.getMasterJobItemKeyPath,
  (entity) => ItemRate.getRate(entity)
);

const masterLoggedExpenseRatesReducer = createKeyValueReducer<
  ItemRate.Type,
  number
>(ItemRate.getMasterLoggedExpenseKeyPath, (entity) => ItemRate.getRate(entity));

function reduceChangedEntities(
  state: ItemRatesState,
  changedEntities: ChangedEntity<ItemRate.Type>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.masterJobItemRates = masterJobItemRatesReducer(
      state.masterJobItemRates,
      changedEntities
    );
    draft.masterLoggedExpenseRates = masterLoggedExpenseRatesReducer(
      state.masterLoggedExpenseRates,
      changedEntities
    );
  });
}

function itemRatesReducer(
  state: ItemRatesState = initialState,
  action: ItemRatesAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return reduceChangedEntities(
        state,
        parseEntityPayload<ItemRate.Type>(
          state,
          action.payload.itemRates,
          ItemRate.isDeleted
        )
      );
    case ENTITIES_REMOVED:
      if (action.payload.entityName !== EntityNames.ItemRates) {
        return state;
      }
      return reduceChangedEntities(
        state,
        parseRemovedEntities(state, action.payload.ids)
      );
    default:
      return state;
  }
}

export default itemRatesReducer;
