import { findIndex } from "lodash-es";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_SET_JOB_LEAD } from "../../../../lib/constants";
import { getAvailabilityDates } from "../../../../lib/dates";
import { getJobLeadUserId } from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobTeamSummary } from "../../../../redux/selectors/jobDetails/ui/selectJobTeamSummary";
import { useJobItemIdsByJobId } from "../../../../redux/selectors/jobItem/jobItemIdsByJobId";
import { useUser } from "../../../../redux/selectors/user";
import {
  actionJobAddUsersAndRolesAction,
  actionJobAssignRoleTime,
} from "../../../../state/entities/job/actions";
import { IconButton } from "../../../elements/Button";
import JobLeadIcon from "../../../elements/Icons/JobLeadIcon";
import PersonAddIcon from "../../../elements/Icons/PersonAddIcon";
import { ICON_SIZE } from "../../../modules/StyledIcon";
import Tooltip from "../../../modules/Tooltip";
import UserPickerWithClear from "../../../modules/UserMenus/UserPickerWithClear";
import { AssignAllRoleMenu } from "../../../modules/UserRoleMenus/AssignAllRoleMenu";
import { JobItemMultiUserSelector } from "../../../modules/UserRoleMenus/JobItemMultiUserSelector";
import { SelectRoleMenu } from "../../../modules/UserRoleMenus/SelectRoleMenu";
import { JobRoleIcon } from "../../components/JobRoleIcon";
import {
  JobLeadUserIcon,
  JobUserIcon,
  OtherUsersIcon,
} from "../../components/JobUserIcons";
import { useJobId } from "../../context/JobIdContext";
import { useObjectWithCount } from "../../hooks/useObjectWithCount";
import {
  avatarListWrappCss,
  containerCss,
  iconButtonCss,
  jobLeadCss,
  jobRoleIconCss,
  wrapperCss,
} from "./JumbotronTeamMembers.css";

const iconSize = 30;
const iconPadding = -12;

const JumbotronTeamMembers = () => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  let { users, roles } = useJobTeamSummary();
  const job = useJob(jobId);
  const jobLeadUser = useUser(getJobLeadUserId(job));
  const jobItemsCount = useJobItemIdsByJobId(jobId).length;

  const dispatch = useDispatch();

  const ref = useRef();
  const roleRef = useRef(null);
  const multiSelectRef = useRef(null);
  const [jobLeadAnchorEl, setJobLeadAnchorEl] = useState(null);
  const [multiUserRoleAnchorEl, setMultiUserRoleAnchorEl] = useState(null);
  const [teamMemberMenuOpen, setTeamMemberMenuOpen] = useState(false);

  const editJobLead = () => {
    if (!isJobEditable) return;
    setJobLeadAnchorEl(ref.current);
  };

  const editUsersRoles = () => {
    if (!isJobEditable) return;
    setMultiUserRoleAnchorEl(multiSelectRef.current);
    setTeamMemberMenuOpen(true);
  };

  const closeJobLeadMenu = () => {
    setJobLeadAnchorEl(null);
  };

  const handlePickJobLead = (jobLeadUserId) => {
    if (!isJobEditable) return;
    dispatch(createAction(JOB_SET_JOB_LEAD, { jobId, jobLeadUserId }));
    closeJobLeadMenu();
  };

  let jobLead = null;
  if (jobLeadUser) {
    jobLead = {
      id: jobLeadUser.id,
      name: jobLeadUser.displayName,
      items: [],
      unplannedTime: 0,
      logged: 0,
      planned: 0,
    };

    if (users.length > 0) {
      const index = findIndex(users, ["id", jobLeadUser.id]);

      if (index !== -1) {
        jobLead = users[index];
        users = [
          ...users.slice(0, index),
          ...users.slice(index + 1, users.length),
        ];
      }
    }
  }

  const [jobUsersRef, count] = useObjectWithCount(iconSize, 0, iconPadding);

  const topUsers = users.slice(0, count - 1);
  const otherUsers = users.slice(count - 1, users.length);

  const [selectRoleMenuOpen, setSelectRoleMenuOpen] = useState(false);
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    job.estimatedStartDate,
    job.estimatedEndDate
  );

  function openRoleMenu() {
    if (!isJobEditable) return;
    setSelectRoleMenuOpen(true);
  }

  function closeRoleMenu() {
    setSelectedRole(null);
    setSelectRoleMenuOpen(false);
  }

  function selectRole(roleId) {
    closeRoleMenu();
    const index = findIndex(roles, ["id", roleId]);
    if (index !== -1) {
      setSelectedRole(roles[index]);
      setAssignMenuOpen(true);
    }
  }

  function closeAssignMenu() {
    setSelectedRole(null);
    setAssignMenuOpen(false);
  }

  const closeTeamMemberMenu = () => {
    setTeamMemberMenuOpen(false);
  };

  function assignUser(userId) {
    dispatch(actionJobAssignRoleTime(jobId, selectedRole.id, userId));
    closeAssignMenu();
  }

  function addUsersAndRoles(userIds = [], roleIds = []) {
    dispatch(actionJobAddUsersAndRolesAction(jobId, userIds, roleIds));
  }

  return (
    <div className={containerCss}>
      {!(!isJobEditable && !jobLead) && (
        <div className={jobLeadCss} ref={ref}>
          {jobLead && (
            <JobLeadUserIcon
              user={jobLead}
              isJobLead
              onClick={editJobLead}
              disabled={!isJobEditable}
              size={iconSize}
            />
          )}
          {!jobLead && isJobEditable && (
            <Tooltip title="Add a job lead" placement="bottom">
              <IconButton onClick={editJobLead}>
                <JobLeadIcon size={ICON_SIZE.X_LARGE} />
              </IconButton>
            </Tooltip>
          )}
          {isJobEditable && (
            <UserPickerWithClear
              anchorEl={jobLeadAnchorEl}
              onPickUser={handlePickJobLead}
              onClose={closeJobLeadMenu}
              pickedUserId={jobLead ? jobLead.id : null}
              onClear={() => handlePickJobLead(null)}
              excludeIds={jobLead ? [jobLead.id] : undefined}
            />
          )}
        </div>
      )}
      <div className={wrapperCss}>
        <div className={avatarListWrappCss}>
          {roles.length > 0 && (
            <>
              {selectRoleMenuOpen && (
                <SelectRoleMenu
                  anchorEl={roleRef.current}
                  close={closeRoleMenu}
                  roles={roles}
                  selectRole={selectRole}
                />
              )}
              {assignMenuOpen && (
                <AssignAllRoleMenu
                  anchorEl={roleRef.current}
                  assignUser={assignUser}
                  close={closeAssignMenu}
                  role={selectedRole}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                />
              )}
            </>
          )}
          <JobUsers ref={jobUsersRef}>
            <div ref={roleRef} className={jobRoleIconCss}>
              {roles.length > 0 && (
                <JobRoleIcon
                  roles={roles}
                  size={iconSize}
                  onClick={openRoleMenu}
                  onRoleSelect={selectRole}
                  disabled={!isJobEditable}
                />
              )}
            </div>
            {topUsers.map((user) => (
              <JobUserIcon key={user.id} user={user} size={iconSize} />
            ))}
            <OtherUsersIcon users={otherUsers} size={iconSize} />
          </JobUsers>
        </div>

        <div ref={multiSelectRef}>
          <Tooltip title="Add users and roles" placement="bottom">
            <IconButton onClick={editUsersRoles} className={iconButtonCss}>
              <PersonAddIcon size={ICON_SIZE.X_LARGE} />
            </IconButton>
          </Tooltip>

          {multiUserRoleAnchorEl && teamMemberMenuOpen && (
            <JobItemMultiUserSelector
              entityId={jobId}
              anchorEl={multiUserRoleAnchorEl}
              type="job"
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              addUsersAndRoles={addUsersAndRoles}
              close={closeTeamMemberMenu}
              canCreateNewUser={false}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              itemCount={jobItemsCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default JumbotronTeamMembers;

const JobUsers = styled.div`
  display: flex;
  width: calc(100% - 100px);
  max-width: 220px;
  justify-content: end;
  & > *:not(:last-child) {
    margin-right: ${iconPadding}px;
  }
`;
