// deprecated

import type { Option } from "./Option";

export const JobItemUserStatuses = {
  Planning: 1,
  Complete: 2,
  Deleted: 3,
  Scheduled: 4,
} as const;

export type JobItemUserStatusesType =
  (typeof JobItemUserStatuses)[keyof typeof JobItemUserStatuses];

export type Type = JobItemUserStatus;
export interface JobItemUserStatus extends Option {
  id: JobItemUserStatusesType;
}

export function isScheduled(entity: JobItemUserStatus) {
  return entity.id === JobItemUserStatuses.Scheduled;
}

export function isComplete(entity: JobItemUserStatus) {
  return entity.id === JobItemUserStatuses.Complete;
}

export function isDeleted(entity: JobItemUserStatus) {
  return entity.id === JobItemUserStatuses.Deleted;
}

export function isPlanning(entity: JobItemUserStatus) {
  return entity.id === JobItemUserStatuses.Planning;
}

export function isIncomplete(entity: JobItemUserStatus) {
  return isPlanning(entity) || isScheduled(entity);
}
