import { produce } from "immer";
import { get, set, union, unset, without } from "lodash-es";

import { JobListModel } from "st-shared/entities/JobListModel";
import { ChangedEntity } from "../../types";

function idsByUserIdReducer(
  state: { [key: number]: number[] },
  changedEntities: ChangedEntity<JobListModel>[]
) {
  return produce(state, (draft) => {
    changedEntities.forEach(({ prevEntity, newEntity }) => {
      let prevUserIds: number[] = [];
      let newUserIds: number[] = [];

      if (prevEntity) prevUserIds = prevEntity.users.map((user) => user.id);
      if (newEntity) newUserIds = newEntity.users.map((user) => user.id);

      const removedUserIds = without(prevUserIds, ...newUserIds);
      const addedUserIds = without(newUserIds, ...prevUserIds);

      if (removedUserIds.length > 0) {
        removedUserIds.forEach((userId) => {
          const newJobsIds = without(get(draft, userId, []), prevEntity!.id);
          if (newJobsIds.length) {
            set(draft, userId, newJobsIds);
          } else {
            unset(draft, userId);
          }
        });
      }

      if (addedUserIds.length > 0) {
        addedUserIds.forEach((userId) => {
          set(draft, userId, union(get(draft, userId, []), [newEntity!.id]));
        });
      }
    });
  });
}

export default idsByUserIdReducer;
