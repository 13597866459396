import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { getJobCurrencyTotalLoggedTimeCostExTax } from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectJobCalculatedTotalLoggedTimeCostExTax } from "./selectJobCalculatedTotalLoggedTimeCostExTax";
import { selectJob } from "./index";

export const selectJobCalculatedUnplannedLoggedTimeCostExTax = createCachedSelector(
  [selectJob, selectJobCalculatedTotalLoggedTimeCostExTax],
  (job, calculatedTotalLoggedTimeCostExTax) => {
    return numeral(getJobCurrencyTotalLoggedTimeCostExTax(job))
      .subtract(numeral(calculatedTotalLoggedTimeCostExTax).value())
      .value();
  }
)(selectPropsJobId);

export const useCalculatedUnplannedLoggedTimeCostExTax = jobId =>
  useSelector(state =>
    selectJobCalculatedUnplannedLoggedTimeCostExTax(state, { jobId })
  );
