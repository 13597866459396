import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { asDecimal } from "../math";
import { entityFieldDecimalType, entityIdType } from "../types/entityTypes";

export const taxRateShape = {
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  rate: entityFieldDecimalType,
};

export const taxRateEntityType = PropTypes.shape(taxRateShape);

export const taxRateOptionType = PropTypes.shape({
  key: entityIdType,
  value: PropTypes.string.isRequired,
  rate: entityFieldDecimalType,
  searchString: PropTypes.string,
});

export const getTaxRateName = ({ name }) => name;

export const getTaxRateRate = ({ rate }) => asDecimal(rate);

export const getSearchString = (taxRate) =>
  [asString(getTaxRateName(taxRate)), asString(getTaxRateRate(taxRate))].join(
    " "
  );

export const getAsOption = (taxRate) => ({
  key: taxRate.id,
  value: getTaxRateName(taxRate),
  rate: getTaxRateRate(taxRate),
  searchString: getSearchString(taxRate),
});

export const NO_TAX_OPTION = {
  key: null,
  value: "No Tax",
  rate: "0",
  searchString: "No Tax 0",
};
