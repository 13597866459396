import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";
import { selectJobItemUsersByJobItemId } from "./selectJobItemUsersByJobItemId";

export const selectJobItemUsersByJobId = (state, { jobId }) => {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });
  if (!jobItemIds) return EMPTY_ARRAY;
  const jobItemUsers = [];
  jobItemIds.forEach(jobItemId => {
    const users = selectJobItemUsersByJobItemId(state, { jobItemId });
    if (users) {
      jobItemUsers.push(...users);
    }
  });
  return jobItemUsers;
};
