import {
  IconSize,
  IconType,
  MdLock,
  MdPerson,
  StItem,
} from "st-shared/components";
import { CostingMethods, TCostingMethods } from "st-shared/entities";

interface CostingMethodUiProperty {
  ids: TCostingMethods[];
  text: string;
  size: number;
  icon: IconType;
  tooltipTitle: string;
  tooltipText: string;
  setCostingMethod: (currentCostingMethod: TCostingMethods) => TCostingMethods;
}

const itemProperties: CostingMethodUiProperty = {
  ids: [CostingMethods.Item],
  icon: StItem,
  text: "Price by item rate",
  size: 18,
  tooltipTitle: "This item is priced by item rate.",
  tooltipText:
    "The total is made up of the total hours multiplied by the unit rate of the item.",
  setCostingMethod() {
    return CostingMethods.Item;
  },
};

const peopleProperties: CostingMethodUiProperty = {
  ids: [CostingMethods.People],
  icon: MdPerson,
  text: "Price by person",
  size: IconSize.XLarge,
  tooltipTitle: "This item is priced by person.",
  tooltipText:
    "The total is made up of the total hours multiplied by individual sell rates.",
  setCostingMethod() {
    return CostingMethods.People;
  },
};

const valueProperties: CostingMethodUiProperty = {
  ids: [CostingMethods.ValueCalculatedSell, CostingMethods.ValueUserSell],
  icon: MdLock,
  text: "Fixed price",
  size: 20,
  tooltipTitle: "This is a fixed price item.",
  tooltipText:
    "The total sell rate for the item is fixed no matter how many hours are logged.",
  setCostingMethod: (currentCostingMethod: TCostingMethods) => {
    if (currentCostingMethod === CostingMethods.People) {
      return CostingMethods.ValueUserSell;
    }
    return CostingMethods.ValueCalculatedSell;
  },
};

export function getCostingMethodUiProperties(
  costingMethodId: TCostingMethods
): CostingMethodUiProperty {
  switch (costingMethodId) {
    case CostingMethods.Item:
      return itemProperties;
    case CostingMethods.People:
      return peopleProperties;
    case CostingMethods.ValueCalculatedSell:
    case CostingMethods.ValueUserSell:
      return valueProperties;
  }
}
