import type { TOrganisation } from "@streamtimefe/entities";
import { first, isArray } from "lodash-es";

import { ENTITIES_RECEIVED } from "../../../lib/constants";
import type { EntityReceivedAction } from "../actions";

function organisationReducer(
  state: TOrganisation = {} as TOrganisation,
  action: EntityReceivedAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED: {
      if (!action.payload.organisation) return state;

      const organisation = isArray(action.payload.organisation)
        ? first(action.payload.organisation)
        : action.payload.organisation;

      return { ...state, ...organisation };
    }
    default:
      return state;
  }
}

export default organisationReducer;
