import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataLoggedExpenseName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const LoggedExpenseDeleted = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" deleted the expense "}
    <b>{getMetaDataLoggedExpenseName(activityEntry)}</b>
  </SystemMessage>
);

LoggedExpenseDeleted.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default LoggedExpenseDeleted;
