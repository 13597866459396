import { uniq } from "lodash-es";
import { useContext } from "react";
import { useSelector } from "react-redux";

import { ScheduleScrollContext } from "../../../../components/modules/ScrollContexts";
import {
  selectJobRowHeights,
  selectScheduleJobUiIsExpanded,
  selectScheduleVisibleUserIds,
  selectUserRowHeights,
} from "../../../../redux/selectors/scheduleSelectors";
import { AppState } from "../../../AppState";
import { selectJobListModel } from "../../../entities/jobListModel/selectors/selectJobListModel";
import { selectJobListModelsFilteredIds } from "../../../entities/jobListModel/selectors/selectJobListModelsFilteredIds";
import { isJobs } from "../lib";
import { selectScheduleUiViewMode } from "./selectScheduleUi";
import { rowIdGenerator } from "./useScheduleRows";

export function selectVisibleUserIds(
  state: AppState,
  scrollOffsetY: number,
  viewportHeight: number
): number[] {
  const viewMode = selectScheduleUiViewMode(state);

  if (isJobs(viewMode)) {
    const jobIds = selectJobListModelsFilteredIds(state);
    const jobHeightsById = selectJobRowHeights(state) as {
      [key: number]: number;
    };

    const visibleJobIds = rowIdGenerator(
      jobIds,
      jobHeightsById,
      scrollOffsetY,
      viewportHeight
    );

    const visibleUserIds: number[] = [];

    visibleJobIds.forEach((jobId) => {
      const isExpanded = selectScheduleJobUiIsExpanded(state, { jobId });
      if (isExpanded) {
        const jobListModel = selectJobListModel(state, { jobId });
        if (jobListModel && jobListModel.users) {
          visibleUserIds.push(...jobListModel.users.map((user) => user.id));
        }
      }
    });

    return uniq(visibleUserIds);
  } else {
    const userIds = selectScheduleVisibleUserIds(state).map(Number);
    const userHeightsById = selectUserRowHeights(state) as {
      [key: number]: number;
    };

    return rowIdGenerator(
      userIds,
      userHeightsById,
      scrollOffsetY,
      viewportHeight
    );
  }
}

export function useVisibleUserIds() {
  const scrollContext = useContext(ScheduleScrollContext);
  return useSelector((state: AppState) =>
    selectVisibleUserIds(
      state,
      scrollContext.scrollOffsetY,
      scrollContext.viewportHeight
    )
  );
}
