import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateMasterLoggedExpenses } from "./selectStateMasterLoggedExpenses";

export const selectMasterLoggedExpenseOptions = createSelector(
  [selectStateMasterLoggedExpenses],
  (masterLoggedExpenses) => masterLoggedExpenses.options
);

export function useMasterLoggedExpenseOptions() {
  return useSelector(selectMasterLoggedExpenseOptions);
}
