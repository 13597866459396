import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_LOGGED_EXPENSES } from "../../../lib/constants";
import { selectPropsLoggedExpenseId } from "../index";

export const selectStateLoggedExpenses = state =>
  state.entities[ENTITY_NAME_LOGGED_EXPENSES];

export const selectLoggedExpense = createCachedSelector(
  [selectStateLoggedExpenses, selectPropsLoggedExpenseId],
  (loggedExpenses, loggedExpenseId) =>
    get(loggedExpenses, `byId.${loggedExpenseId}`)
)(selectPropsLoggedExpenseId);

export const useLoggedExpense = loggedExpenseId =>
  useSelector(state => selectLoggedExpense(state, { loggedExpenseId }));
