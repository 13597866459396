import { has } from "lodash-es";

import { EntityClassNameMap } from "../../state/entities/types";
import { EMPTY_OBJECT } from "../constants";
import { FrameworkException } from "../exceptions/FrameworkException";

export const modelParser = (entityClassName, models) => {
  const entityName = EntityClassNameMap[entityClassName];

  if (entityName) return { [entityName]: models };

  return EMPTY_OBJECT;
};

export const isModelSet = (data) =>
  has(data, "__modelSet") || has(data, "__primaryModelClassName");

export const createModelSetParser = (primaryModelClassName) => (data) => {
  const entities = {};
  let primaryEntity = undefined;

  if (isModelSet(data)) {
    const { __primaryModelClassName, __primaryModel, __modelSet = [] } = data;
    primaryEntity = __primaryModel;

    __modelSet.forEach(({ className, models }) => {
      Object.assign(entities, modelParser(className, models));
    });

    if (__primaryModelClassName) {
      const entityName = EntityClassNameMap[__primaryModelClassName];
      const models =
        entityName && has(entities, entityName) ? entities[entityName] : [];

      if (
        __primaryModel &&
        !models.find((model) => model.id === __primaryModel.id)
      )
        models.unshift(__primaryModel);

      Object.assign(entities, modelParser(__primaryModelClassName, models));
    }
  } else {
    if (!primaryModelClassName)
      throw new FrameworkException(
        "primary model name required to parse model data"
      );

    Object.assign(entities, modelParser(primaryModelClassName, [data]));
  }
  return { data: entities, primaryEntity };
};
