import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const PriorityBoardCardStore = createSingleEntityStoreContext(
  EntityClass.PriorityBoardCard
);

export const PriorityBoardCardStoreProvider = createSingleEntityStoreProvider(
  EntityClass.PriorityBoardCard,
  PriorityBoardCardStore
);
