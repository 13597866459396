import {
  JOB_TIMELINE_PHASE_COLLAPSE,
  JOB_TIMELINE_PHASE_EXPAND
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onExpandJobPhase = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isCollapsed: false
  }
});

const onCollapseJobPhase = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isCollapsed: true
  }
});

export default createReducer(
  {},
  {
    [JOB_TIMELINE_PHASE_EXPAND]: onExpandJobPhase,
    [JOB_TIMELINE_PHASE_COLLAPSE]: onCollapseJobPhase
  }
);
