import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { JOB_TIMELINE_FETCH_REQUEST } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { selectJobTimelineUi } from "../../../redux/selectors/jobTimelineSelectors";
import LoadingSpinner from "../../elements/LoadingSpinner";
import JobTimelineSummary from "./JobTimelineSummary";

function JobTimelineSummaryPage() {
  const params = useParams();
  const jobId = Number(params.jobId);

  const pending = useSelector(selectJobTimelineUi).pending;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction(JOB_TIMELINE_FETCH_REQUEST, { jobId }));
  }, [jobId]);

  if (pending) return <LoadingSpinner />;

  return <JobTimelineSummary jobId={jobId} />;
}

export default JobTimelineSummaryPage;
