import { useRef } from "react";

export function useInit(callback) {
  const mounted = useRef(false);
  const returnValue = useRef(null);
  if (!mounted.current) {
    mounted.current = true;
    returnValue.current = callback();
  }
  return returnValue.current;
}
