import * as PropTypes from "prop-types";
import React from "react";

import { addKeyEventListener, removeKeyEventListener } from "../../lib/events";
import { hasOpenModals } from "../../lib/webAppAPISender";
import Button from "./Button";

export default class ShortcutButton extends React.PureComponent {
  static propTypes = {
    key: PropTypes.string,
    keycode: PropTypes.number,
    code: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    key: null,
    keycode: null,
    code: null,
    disabled: false,
    onClick: null,
  };

  componentDidMount() {
    addKeyEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    removeKeyEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (hasOpenModals()) return;

    const { key, keycode, code, disabled, onClick } = this.props;

    if (disabled || onClick === null) return;

    if (
      (key !== null && e.key === key) ||
      (keycode !== null && e.keyCode === keycode) ||
      (code !== null && e.code === code)
    ) {
      onClick();
    }
  };

  render() {
    return <Button {...this.props} style={{ padding: 0 }} />;
  }
}
