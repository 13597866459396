import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { useRef, useState } from "react";
import { MdDelete, MdMoreVert } from "react-icons/md";

import {
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverMenu,
  StPin,
  StPinOff,
} from "../../../components";
import type { TEntityId } from "../../../entities/Entity";
import {
  savedSegmentEntityStore,
  useSavedSegmentEntity,
  useSavedSegmentHasEditAccess,
} from "../../../stores";
import { useSavedSegmentSidebarContext } from "../context/SavedSegmentSidebarContext";
import {
  useSavedSegmentSidebarActions,
  useSavedSegmentSidebarSegmentPinned,
} from "../state/savedSegmentSidebarStore";
import { actionMenuCss, rowHoverIconCss } from "./styles.css";

type Props = { segmentId: TEntityId };

export function SegmentActionMenu({ segmentId }: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const segment = useSavedSegmentEntity(segmentId);

  if (!segment) return null;

  function openMenu() {
    setAnchorEl(ref.current);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        ref={ref}
        iconProps={{ icon: MdMoreVert }}
        onClick={openMenu}
        className={rowHoverIconCss}
      />
      <PopoverMenu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        paperRootClassName={actionMenuCss.root}
      >
        <PinAction segmentId={segmentId} closeMenu={closeMenu} />
        <DeleteAction segmentId={segmentId} closeMenu={closeMenu} />
      </PopoverMenu>
    </>
  );
}

type DeleteActionProps = {
  segmentId: TEntityId;
  closeMenu: () => void;
};

function DeleteAction({ segmentId, closeMenu }: DeleteActionProps) {
  const { onDeleteSavedSegment } = useSavedSegmentSidebarContext();
  const sidebarActions = useSavedSegmentSidebarActions();
  const hasEditAccess = useSavedSegmentHasEditAccess(segmentId);

  if (!hasEditAccess) return null;

  const onDelete = () => {
    closeMenu();

    getModalBridgeClient()?.openModal(
      ModalTypes.Confirm,
      {
        title: "Delete Report",
        message:
          "Are you sure you want to delete this report? This can’t be undone.",
        primaryText: "Delete",
      },
      {
        async success() {
          const deleteResponse =
            await savedSegmentEntityStore().actions.deleteEntity(segmentId);

          if (deleteResponse && "success" in deleteResponse) {
            sidebarActions.removeSegment(segmentId);
            onDeleteSavedSegment(segmentId);
          }
        },
      }
    );
  };

  return (
    <MenuItem className={actionMenuCss.item} onClick={onDelete}>
      <ListItemIcon className={actionMenuCss.listItemIcon}>
        <Icon className={actionMenuCss.icon} icon={MdDelete} size={18} />
      </ListItemIcon>
      <ListItemText className={actionMenuCss.text}>Delete</ListItemText>
    </MenuItem>
  );
}

type PinActionProps = {
  segmentId: TEntityId;
  closeMenu: () => void;
};

function PinAction({ segmentId, closeMenu }: PinActionProps) {
  const sidebarActions = useSavedSegmentSidebarActions();

  const isPinned = useSavedSegmentSidebarSegmentPinned(segmentId);

  function onPinSegment() {
    closeMenu();
    if (!isPinned) {
      sidebarActions.addSegment(segmentId);
    } else {
      sidebarActions.removeSegment(segmentId);
    }
  }

  return (
    <MenuItem className={actionMenuCss.item} onClick={onPinSegment}>
      <ListItemIcon className={actionMenuCss.listItemIcon}>
        <Icon
          className={actionMenuCss.icon}
          icon={isPinned ? StPinOff : StPin}
          size={18}
        />
      </ListItemIcon>
      <ListItemText className={actionMenuCss.text}>
        {isPinned ? "Un pin" : "Pin"}
      </ListItemText>
    </MenuItem>
  );
}
