import { Entity } from "@streamtimefe/entities";
import { debounce } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  SCHEDULE_BLOCK_DUPLICATE,
  SCHEDULE_BLOCK_DUPLICATE_MULTIPLE,
  SCHEDULE_BLOCK_LOG,
  SCHEDULE_BLOCK_LOG_MULTIPLE,
  SCHEDULE_BLOCK_REASSIGN_MULTIPLE,
  SCHEDULE_BLOCK_REASSIGN_SAGA,
  SCHEDULE_BLOCK_SPLIT_START,
  SCHEDULE_BLOCK_START_DELETE,
  SCHEDULE_BLOCK_START_DELETE_MULTIPLE,
  SCHEDULE_BLOCK_UNLOG,
  SCHEDULE_BLOCK_UNLOG_MULTIPLE,
} from "../../../lib/constants";
import {
  getJobPageUrl,
  scheduleLoggedTimeType,
} from "../../../lib/entities/scheduleLoggedTimeEntity";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectHasBilledSelectedBlocks,
  selectHasCompleteSelectedBlocks,
  selectHasIncompleteSelectedBlocks,
  selectHasPrivateSelectedBlocks,
  selectSelectedBlockKeys,
} from "../../../redux/selectors/scheduleBlockSelectors";
import { selectDaysByBlockKey } from "../../../redux/selectors/scheduleSelectors";
import DeleteIcon from "../../elements/Icons/DeleteIcon";
import DuplicateIcon from "../../elements/Icons/DuplicateIcon";
import LogIcon from "../../elements/Icons/LogIcon";
import ReassignIcon from "../../elements/Icons/ReassignIcon";
import SplitIcon from "../../elements/Icons/SplitIcon";
import UnlogIcon from "../../elements/Icons/UnlogIcon";
import ContextMenu from "../../modules/Menu/ContextMenu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import MenuItemLink from "../../modules/Menu/MenuItemLink";
import SubMenu from "../../modules/Menu/SubMenu";
import { ICON_SIZE } from "../../modules/StyledIcon";
import UserPickerSubMenu from "../../modules/UserMenus/UserPickerSubMenu";

const mapState = (state, props) => ({
  baseScheduleLoggedTime: selectBaseScheduleLoggedTimeByBlockKey(state, props),
  selectedBlockKeys: selectSelectedBlockKeys(state),
  hasIncompleteSelectedBlocks: selectHasIncompleteSelectedBlocks(state),
  hasCompleteSelectedBlocks: selectHasCompleteSelectedBlocks(state),
  hasBilledSelectedBlocks: selectHasBilledSelectedBlocks(state),
  hasPrivateSelectedBlocks: selectHasPrivateSelectedBlocks(state),
  days: selectDaysByBlockKey(state, props),
});

const mapDispatch = (dispatch) => ({
  doReassignBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_REASSIGN_SAGA, payload)),

  doReassignMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_REASSIGN_MULTIPLE, payload)),

  doDuplicateBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DUPLICATE, payload)),

  doDuplicateMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_DUPLICATE_MULTIPLE, payload)),

  doSplitBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_SPLIT_START, payload)),

  doLogBlock: (payload) => dispatch(createAction(SCHEDULE_BLOCK_LOG, payload)),

  doLogMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_LOG_MULTIPLE, payload)),

  doUnlogBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_UNLOG, payload)),

  doUnlogMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_UNLOG_MULTIPLE, payload)),

  doDeleteBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_START_DELETE, payload)),

  doDeleteMultipleBlocks: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_START_DELETE_MULTIPLE, payload)),
});

class ScheduleBlockContextMenu extends React.PureComponent {
  static propTypes = {
    blockKey: entityIdType,
    baseScheduleLoggedTime: scheduleLoggedTimeType,
    days: PropTypes.number,
    anchorPosition: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
    }),
    onClose: PropTypes.func.isRequired,
    selectedBlockKeys: entityIdListType.isRequired,
    hasIncompleteSelectedBlocks: PropTypes.bool.isRequired,
    hasCompleteSelectedBlocks: PropTypes.bool.isRequired,
    hasBilledSelectedBlocks: PropTypes.bool.isRequired,
    hasPrivateSelectedBlocks: PropTypes.bool.isRequired,
    doReassignBlock: PropTypes.func.isRequired,
    doReassignMultipleBlocks: PropTypes.func.isRequired,
    doDuplicateBlock: PropTypes.func.isRequired,
    doDuplicateMultipleBlocks: PropTypes.func.isRequired,
    doSplitBlock: PropTypes.func.isRequired,
    doLogBlock: PropTypes.func.isRequired,
    doLogMultipleBlocks: PropTypes.func.isRequired,
    doUnlogBlock: PropTypes.func.isRequired,
    doUnlogMultipleBlocks: PropTypes.func.isRequired,
    doDeleteBlock: PropTypes.func.isRequired,
    doDeleteMultipleBlocks: PropTypes.func.isRequired,
  };

  static defaultProps = {
    blockKey: null,
    days: null,
    baseScheduleLoggedTime: null,
    anchorPosition: null,
  };

  state = {
    activeSubMenuAnchorEl: null,
  };

  constructor(props) {
    super(props);

    this.toggleSubMenu = debounce((e, menuItemSelector) => {
      const { activeSubMenuAnchorEl } = this.state;

      if (!this.isOpen || !e || !menuItemSelector) {
        this.setState({
          activeSubMenuAnchorEl: null,
        });

        return;
      }

      if (
        !activeSubMenuAnchorEl ||
        !activeSubMenuAnchorEl.closest(menuItemSelector)
      ) {
        this.setState({
          activeSubMenuAnchorEl: e.target.closest(menuItemSelector),
        });
      }
    }, 200);
  }

  componentWillUnmount() {
    this.toggleSubMenu.cancel();
  }

  get isOpen() {
    const { blockKey } = this.props;
    return Boolean(blockKey);
  }

  get reassignSubMenuAnchorEl() {
    const { activeSubMenuAnchorEl } = this.state;

    return (
      this.isOpen &&
      activeSubMenuAnchorEl &&
      activeSubMenuAnchorEl.closest(".MenuItem-Reassign")
    );
  }

  get deleteSubMenuAnchorEl() {
    const { activeSubMenuAnchorEl } = this.state;

    return (
      this.isOpen &&
      activeSubMenuAnchorEl &&
      activeSubMenuAnchorEl.closest(".MenuItem-Delete")
    );
  }

  get isMultiSelectMode() {
    const { blockKey, selectedBlockKeys } = this.props;
    const isMultipleSelected = selectedBlockKeys.length > 1;

    return isMultipleSelected && selectedBlockKeys.includes(blockKey);
  }

  get jobPageHREF() {
    const { baseScheduleLoggedTime } = this.props;
    if (!baseScheduleLoggedTime) return "";

    return getJobPageUrl(baseScheduleLoggedTime);
  }

  close = () => {
    const { onClose } = this.props;

    this.setState({
      activeSubMenuAnchorEl: null,
    });

    onClose();
  };

  closeSubMenus = () => this.toggleSubMenu();

  openReassignSubMenu = (e) => {
    this.toggleSubMenu({ ...e }, ".MenuItem-Reassign");
  };

  openConfirmDeleteSubMenu = (e) => {
    this.toggleSubMenu({ ...e }, ".MenuItem-Delete");
  };

  reassignToUser = (userId) => {
    const {
      blockKey,
      selectedBlockKeys,
      baseScheduleLoggedTime,
      doReassignBlock,
      doReassignMultipleBlocks,
    } = this.props;

    if (!this.isOpen) return;

    const hasChanged = baseScheduleLoggedTime.userId !== userId;

    if (this.isMultiSelectMode) {
      doReassignMultipleBlocks({
        blockKey,
        userId,
        hasChanged,
        selectedBlockKeys,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    } else {
      doReassignBlock({
        blockKey,
        userId,
        hasChanged,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    }

    this.close();
  };

  duplicateBlock = () => {
    const {
      blockKey,
      selectedBlockKeys,
      baseScheduleLoggedTime,
      doDuplicateBlock,
      doDuplicateMultipleBlocks,
    } = this.props;

    if (!this.isOpen) return;

    if (this.isMultiSelectMode) {
      doDuplicateMultipleBlocks({
        selectedBlockKeys,
        duplicate: true,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    } else {
      doDuplicateBlock({
        blockKey,
        duplicate: true,
        newBlockKey: Entity.temporaryId(),
        origin: {
          baseScheduleLoggedTime,
        },
      });
    }

    this.close();
  };

  splitBlock = () => {
    const { blockKey, baseScheduleLoggedTime, doSplitBlock } = this.props;

    doSplitBlock({
      blockKey,
      baseScheduleLoggedTime,
    });

    this.close();
  };

  logBlock = () => {
    const {
      blockKey,
      selectedBlockKeys,
      baseScheduleLoggedTime,
      doLogBlock,
      doLogMultipleBlocks,
    } = this.props;

    if (!this.isOpen) return;

    if (this.isMultiSelectMode) {
      doLogMultipleBlocks({
        selectedBlockKeys,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    } else {
      doLogBlock({
        blockKey,
        hasChanged: true,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    }

    this.close();
  };

  unlogBlock = () => {
    const {
      blockKey,
      selectedBlockKeys,
      baseScheduleLoggedTime,
      doUnlogBlock,
      doUnlogMultipleBlocks,
    } = this.props;

    if (!this.isOpen) return;

    if (this.isMultiSelectMode) {
      doUnlogMultipleBlocks({
        selectedBlockKeys,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    } else {
      doUnlogBlock({
        blockKey,
        hasChanged: true,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    }

    this.close();
  };

  deleteBlock = () => {
    const {
      blockKey,
      selectedBlockKeys,
      baseScheduleLoggedTime,
      doDeleteBlock,
      doDeleteMultipleBlocks,
    } = this.props;

    if (!this.isOpen) return;

    if (this.isMultiSelectMode) {
      doDeleteMultipleBlocks({
        selectedBlockKeys,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    } else {
      doDeleteBlock({
        blockKey,
        origin: {
          baseScheduleLoggedTime,
        },
      });
    }

    this.close();
  };

  render() {
    const {
      anchorPosition,
      hasIncompleteSelectedBlocks,
      hasCompleteSelectedBlocks,
      hasBilledSelectedBlocks,
      hasPrivateSelectedBlocks,
      days,
    } = this.props;
    const hasReadOnlySelectedBlocks =
      hasBilledSelectedBlocks && hasPrivateSelectedBlocks;

    return (
      <>
        <ContextMenu
          open={this.isOpen}
          anchorPosition={anchorPosition}
          onClose={this.close}
          marginThreshold={60}
        >
          {!hasPrivateSelectedBlocks && (
            <MenuItem
              onClick={this.duplicateBlock}
              onMouseEnter={this.closeSubMenus}
            >
              <ListItemIcon>
                <DuplicateIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
          )}
          {!hasReadOnlySelectedBlocks && (
            <MenuItem
              hasSubMenu
              isSubMenuOpen={Boolean(this.reassignSubMenuAnchorEl)}
              className="MenuItem-Reassign"
              onMouseEnter={this.openReassignSubMenu}
            >
              <ListItemIcon>
                <ReassignIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Reassign</ListItemText>
            </MenuItem>
          )}
          {!hasReadOnlySelectedBlocks &&
            !this.isMultiSelectMode &&
            Number(days) > 1 && (
              <MenuItem
                onClick={this.splitBlock}
                onMouseEnter={this.closeSubMenus}
              >
                <ListItemIcon>
                  <SplitIcon size={ICON_SIZE.X_LARGE} />
                </ListItemIcon>
                <ListItemText>Split Block</ListItemText>
              </MenuItem>
            )}
          {!hasReadOnlySelectedBlocks && hasIncompleteSelectedBlocks && (
            <MenuItem onClick={this.logBlock} onMouseEnter={this.closeSubMenus}>
              <ListItemIcon>
                <LogIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Log</ListItemText>
            </MenuItem>
          )}
          {!hasReadOnlySelectedBlocks && hasCompleteSelectedBlocks && (
            <MenuItem
              onClick={this.unlogBlock}
              onMouseEnter={this.closeSubMenus}
            >
              <ListItemIcon>
                <UnlogIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Unlog</ListItemText>
            </MenuItem>
          )}
          {!hasReadOnlySelectedBlocks && (
            <MenuItem
              hasSubMenu
              isSubMenuOpen={Boolean(this.deleteSubMenuAnchorEl)}
              className="MenuItem-Delete"
              onMouseEnter={this.openConfirmDeleteSubMenu}
            >
              <ListItemIcon>
                <DeleteIcon size={ICON_SIZE.X_LARGE} style={{ padding: 1 }} />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
          {this.jobPageHREF && (
            <MenuItemLink href={this.jobPageHREF} text="Go To Job" />
          )}
        </ContextMenu>
        {this.reassignSubMenuAnchorEl && (
          <UserPickerSubMenu
            anchorEl={this.reassignSubMenuAnchorEl}
            onPickUser={this.reassignToUser}
            onMouseEnter={this.openReassignSubMenu}
          />
        )}
        {this.deleteSubMenuAnchorEl && (
          <SubMenu
            anchorEl={this.deleteSubMenuAnchorEl}
            onMouseEnter={this.openConfirmDeleteSubMenu}
          >
            <MenuItem onClick={this.deleteBlock}>
              <ListItemText>Confirm</ListItemText>
            </MenuItem>
          </SubMenu>
        )}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleBlockContextMenu);
