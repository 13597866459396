import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";
import { selectJobPhaseIdsByJobId } from "../jobPhase/jobPhaseIdsByJobId";

export const selectCanReorderItemsAndPhases = createCachedSelector(
  [selectJobItemIdsByJobId, selectJobPhaseIdsByJobId],
  (jobItemIds, jobPhaseIds) => jobItemIds.length > 1 || jobPhaseIds.length > 1
)(selectPropsJobId);

export const useCanReorderItemsAndPhases = jobId =>
  useSelector(state => selectCanReorderItemsAndPhases(state, { jobId }));
