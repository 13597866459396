import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_SET_NAME } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getJobName } from "../../../lib/entities/jobEntity";
import createAction from "../../../redux/helpers/createAction";
import { useJob } from "../../../redux/selectors/job";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import Textarea from "../../elements/Textarea";
import { useJobId } from "../context/JobIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";

const JobName = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);

  const [ref, , setFocused] = useFocusStateRef({
    jobId,
    key: FOCUS_KEYS.JOB_NAME,
  });

  const inputValue = getJobName(job);

  const dispatch = useDispatch();

  const doJobSetName = (value) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_SET_NAME, {
        jobId,
        value,
      })
    );
  };

  const handleChange = (e) => {
    doJobSetName(String(e.target.value));
  };

  const handleBlur = (e) => {
    setFocused(false);
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <Container>
      <StyledTextArea
        ref={ref}
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!isJobEditable}
      />
    </Container>
  );
};

export default JobName;

const Container = styled.div``;

export const StyledTextArea = styled(Textarea)`
  color: black;
  font-size: 52px;
  font-weight: var(--font-weight-bold);
  resize: none;
  padding: 0;

  background-color: transparent;
  &:hover {
    background-color: white;
  }
  &:focus {
    background-color: white;
  }
  &:disabled {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
`;
