import { customAlphabet, nanoid } from "nanoid";

export function uuid() {
  return nanoid();
}

const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function uuidAlphaShort() {
  return customAlphabet(letters, 3)();
}

export function uuidAlphaMedium() {
  return customAlphabet(letters, 8)();
}
