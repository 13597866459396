// deprecated

import { produce } from "immer";

import type { Entity } from "./Entity";

export type Type = JobItemRole;
export interface JobItemRole extends Entity {
  jobItemId: number;
  roleId: number;
  active: boolean;
  totalPlannedMinutes: number;
  jobCurrencySellRate: string | number | null;
  jobCurrencyTotalPlannedTimeExTax: string | null;
}

export function isDeleted(entity: JobItemRole) {
  return !entity.active;
}

export function createJobItemRole(
  id: number,
  jobItemId: number,
  roleId: number,
  rate: string
): JobItemRole {
  return {
    id,
    jobItemId,
    roleId,
    active: true,
    totalPlannedMinutes: 0,
    jobCurrencySellRate: rate,
    jobCurrencyTotalPlannedTimeExTax: "0.00",
  };
}

export function asApiPayload(entity: JobItemRole): any {
  return produce(entity, (draft: any) => {
    if (draft.id < 0) draft.id = null;
  });
}
