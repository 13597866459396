import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVED,
  JOB_ITEM_USER_DELETE,
  JOB_ITEM_USER_DELETE_ERROR,
  JOB_ITEM_USER_EDIT,
  SCHEDULE_JOB_ITEM_ADD_USER_ERROR,
  SCHEDULE_JOB_ITEM_ADD_USER_SAVED,
  SCHEDULE_JOB_ITEM_USER_DELETE,
  SCHEDULE_JOB_ITEM_USER_DELETE_ERROR
} from "../../../../lib/constants";
import {
  getJobItemIdUserIdPath,
  isDeleted
} from "../../../../lib/entities/jobItemUserEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createKeyValueReducer from "../../../helpers/createKeyValueReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";

const idsByJobItemIdReducer = createEntityIndexedArrayReducer(
  entity => entity.jobItemId
);

const idByJobItemIdUserIdReducer = createKeyValueReducer(
  getJobItemIdUserIdPath
);

const userIdsByJobItemIdReducer = createEntityIndexedArrayReducer(
  entity => entity.jobItemId,
  entity => entity.userId
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobItemId: idsByJobItemIdReducer(
      state.idsByJobItemId,
      changedEntities
    ),
    idByJobItemIdUserId: idByJobItemIdUserIdReducer(
      state.idByJobItemIdUserId,
      changedEntities
    ),
    userIdsByJobItemId: userIdsByJobItemIdReducer(
      state.userIdsByJobItemId,
      changedEntities
    )
  };
};

export const parseJobItemUserEntityPayload = (state, action) =>
  parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEM_USERS],
    isDeleted
  );

const receiveEntitiesReducer = (state, action) =>
  reduceChangedEntities(state, parseJobItemUserEntityPayload(state, action));

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_ITEM_USERS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const saveSuccessReducer = (state, { payload }) => {
  const { jobItemUser, data } = payload;

  const removeEntities =
    jobItemUser.id < 0 ? [{ prevEntity: state.byId[jobItemUser.id] }] : [];

  const changedEntities = removeEntities.concat(
    parseEntityPayload(state, data[ENTITY_NAME_JOB_ITEM_USERS], isDeleted)
  );

  return reduceChangedEntities(state, changedEntities);
};

const undoAddJobItemUserReducer = (state, { payload: { createId } }) =>
  reduceChangedEntities(state, parseRemovedEntities(state, [createId]));

const deleteScheduleJobItemUserReducer = (state, { payload: { id } }) =>
  reduceChangedEntities(state, parseRemovedEntities(state, [id]));

const undoDeleteScheduleJobItemUserReducer = (state, { payload }) => {
  const { id, jobItemUser } = payload;
  const changedEntities = [
    { prevEntity: state.byId[id], newEntity: jobItemUser }
  ];

  return reduceChangedEntities(state, changedEntities);
};

const editJobItemUserReducer = (state, action) => {
  const { jobItemUsers } = action.payload;

  if (jobItemUsers && jobItemUsers.length > 0) {
    return reduceChangedEntities(
      state,
      jobItemUsers.map(jobItemUser => ({
        prevEntity: state.byId[jobItemUser.new.id],
        newEntity: jobItemUser.new
      }))
    );
  }

  return state;
};

const saveJobItemErrorReducer = (state, action) => {
  const { previousJobItemUsers } = action.payload;

  let changedEntities = [];

  if (previousJobItemUsers && previousJobItemUsers.length > 0) {
    changedEntities = previousJobItemUsers.map(jobItemUser => {
      if (jobItemUser.id < 0) {
        return {
          prevEntity: state.byId[jobItemUser.id]
        };
      }
      return {
        prevEntity: state.byId[jobItemUser.id],
        newEntity: jobItemUser
      };
    });
  }

  return reduceChangedEntities(state, changedEntities);
};

const saveJobItemSuccessReducer = (state, action) => {
  const { jobItemUsers } = action.payload;

  let changedEntities = [];

  if (jobItemUsers && jobItemUsers.length > 0) {
    changedEntities = jobItemUsers
      .filter(jobItemUser => jobItemUser.id < 0)
      .map(jobItemUser => ({ prevEntity: state.byId[jobItemUser.id] }));
  }

  return reduceChangedEntities(state, changedEntities);
};

const deleteJobItemUserReducer = (state, action) => {
  const { jobItemUser } = action.payload;
  const prevEntity = state.byId[jobItemUser.id];

  return reduceChangedEntities(state, [
    {
      prevEntity
    }
  ]);
};

const undoDeleteJobItemUserReducer = (state, action) => {
  const { jobItemUserId, jobItemUser } = action.payload;
  const prevEntity = state.byId[jobItemUserId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: jobItemUser
    }
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [SCHEDULE_JOB_ITEM_ADD_USER_SAVED]: saveSuccessReducer,
    [SCHEDULE_JOB_ITEM_ADD_USER_ERROR]: undoAddJobItemUserReducer,
    [SCHEDULE_JOB_ITEM_USER_DELETE]: deleteScheduleJobItemUserReducer,
    [SCHEDULE_JOB_ITEM_USER_DELETE_ERROR]: undoDeleteScheduleJobItemUserReducer,
    [JOB_ITEM_USER_EDIT]: editJobItemUserReducer,
    [JOB_ITEM_SAVED]: saveJobItemSuccessReducer,
    [JOB_ITEM_SAVE_ERROR]: saveJobItemErrorReducer,
    [JOB_ITEM_USER_DELETE]: deleteJobItemUserReducer,
    [JOB_ITEM_USER_DELETE_ERROR]: undoDeleteJobItemUserReducer
  }
);
