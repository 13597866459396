import { IconBase, IconBaseProps } from "react-icons";

export function StDoneCircleOutline(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "-3 -3 36 36" }} {...props}>
      <path d="M15 0C6.75 0 0 6.75 0 15s6.75 15 15 15 15-6.75 15-15S23.25 0 15 0zM3 15C3 8.4 8.4 3 15 3s12 5.4 12 12-5.4 12-12 12S3 21.6 3 15z" />
      <path d="M12.812 21.835l.31.31L23.143 12.12 21.023 10l-7.95 7.95L9.12 14 7 16.121l5.763 5.763.049-.05z" />
    </IconBase>
  );
}
