import { createSelector } from "reselect";
import { selectPropsId } from "./index";

export const selectJobItemsUi = state => state.ui.jobItems;

export const selectJobItemUi = createSelector(
  selectJobItemsUi,
  selectPropsId,
  (jobItems, jobItemId) => jobItems[jobItemId]
);

export const selectIsDraggingJobItem = createSelector(
  selectJobItemUi,
  jobItem => !!(jobItem && jobItem.isDragging)
);

export const selectIsResizingJobItem = createSelector(
  selectJobItemUi,
  jobItem => !!(jobItem && jobItem.isResizing)
);

export const selectIsCreatingDependancyForJobItem = createSelector(
  selectJobItemUi,
  jobItem => !!(jobItem && jobItem.isCreatingDependancy)
);
