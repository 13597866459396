import clsx from "clsx";
import { ChangeEvent, ReactNode } from "react";

import { Input } from "../../components";
import {
  noOptionsMessageCss,
  noResultsMessageCss,
  searchContainerCss,
  searchMenuHeaderContainerCss,
  searchMenuHeaderInput,
  searchMenuHeaderTitle,
} from "./SearchMenuHeader.css";

export interface SearchMenuHeaderProps {
  searchQuery: string;
  onSearch: (query: string) => void;
  hasOptions: boolean;
  hasResults: boolean;
  title?: string;
  placeholder?: string;
  noOptionsMessage?: ReactNode;
  getNoResultsMessage?: (query: string) => ReactNode;
  children?: ReactNode;
  className?: string;
}

export function SearchMenuHeader({
  searchQuery,
  onSearch,
  hasOptions,
  hasResults,
  title = "",
  placeholder = "Search by name...",
  noOptionsMessage = "No options available",
  getNoResultsMessage = defaultGetNoResultsMessage,
  children = null,
  className,
}: SearchMenuHeaderProps) {
  const noResults = Boolean(searchQuery) && !hasResults;

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    onSearch(event.target.value);
  }

  return (
    <div className={clsx(searchMenuHeaderContainerCss, className)}>
      {title && <div className={searchMenuHeaderTitle}>{title}</div>}

      {hasOptions && (
        <div className={searchContainerCss}>
          <Input
            type="search"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={searchMenuHeaderInput}
            value={searchQuery}
            onChange={onChange}
            placeholder={placeholder}
            variant="secondary"
          />
        </div>
      )}

      {hasOptions && children}

      {!hasOptions && (
        <div className={noOptionsMessageCss}>{noOptionsMessage}</div>
      )}

      {noResults && (
        <div className={noResultsMessageCss}>
          {getNoResultsMessage(searchQuery)}
        </div>
      )}
    </div>
  );
}

function defaultGetNoResultsMessage(query: string) {
  return `Hmm, looks like nothing matches ‘${query}’. Perhaps try a different search term.`;
}
