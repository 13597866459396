import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const GroupByTeamMemberIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M4.94 11.03L6.7 12.7H4.27c-.66 0-1.24-.5-1.24-1.16V8.06H2v3.41c0 1.16 1.03 2.18 2.27 2.18H6.7l-1.76 1.67.73.72 2.94-2.83-2.94-2.9c-.15.14-.59.58-.73.72z" />
    <path d="M12.1 7.81l-.68.47-.45.69-.16.85.16.85.45.69.68.47.88.17.88-.17.69-.47.45-.69.16-.85-.17-.85-.47-.69-.69-.47-.85-.17-.88.17z" />
    <path d="M18.32 13.2c-.98 0-1.88.38-2.56.9.53.61.83 1.43.83 2.26H22c0-1.73-1.58-3.16-3.68-3.16" />
    <path d="M18.62 7.64c-1.2 0-2.18.98-2.18 2.18 0 1.2.98 2.18 2.18 2.18 1.2 0 2.18-.98 2.18-2.18-.08-1.2-1.05-2.18-2.18-2.18" />
    <path d="M15.76 16.36H9c0-1.73 1.5-3.08 3.38-3.08 1.88-.08 3.38 1.35 3.38 3.08" />
  </SvgIcon>
);

GroupByTeamMemberIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

GroupByTeamMemberIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(GroupByTeamMemberIcon);
