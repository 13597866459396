import * as PropTypes from "prop-types";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";

import { domNodeType } from "../../../lib/types/domTypes";
import { useMasterJobItemGroupedOptions } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItemGroupedOptions";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import AddLineSubMenu from "../../modules/CommercialDocuments/AddLineMenu/AddLineSubMenu";
import AddLineSubMenuMenuItem from "../../modules/CommercialDocuments/AddLineMenu/AddLineSubMenuMenuItem";
import { ICON_SIZE } from "../../modules/StyledIcon";

const AddNewMasterItemSubMenu = ({ anchorEl, onSetItem }) => {
  const options = useMasterJobItemGroupedOptions();

  const getNoResultsMessage = (searchQuery) =>
    `Hmm, looks like no items match ‘${searchQuery}’. Perhaps try a different search term.`;

  return (
    <AddLineSubMenu
      anchorEl={anchorEl}
      options={options}
      transformOrigin={{
        vertical: -10,
        horizontal: -10,
      }}
      hideBackdrop
      HeaderComponentProps={{
        title: `Items`,
        placeholder: "Search by name...",
        getNoResultsMessage,
      }}
    >
      {({ key, value, optionType, isBillable }) => (
        <AddLineSubMenuMenuItem
          className={
            optionType === LineItemOptionTypes.OptionsHeading && "heading"
          }
          key={key}
          onClick={() => onSetItem(key)}
        >
          <span>{value}</span>
          {optionType !== LineItemOptionTypes.OptionsHeading &&
            !isBillable && (
              <NonBillableIcon size={ICON_SIZE.MEDIUM}></NonBillableIcon>
            )}
        </AddLineSubMenuMenuItem>
      )}
    </AddLineSubMenu>
  );
};

AddNewMasterItemSubMenu.propTypes = {
  anchorEl: domNodeType,
  onSetItem: PropTypes.func.isRequired,
};

AddNewMasterItemSubMenu.defaultProps = {
  anchorEl: null,
};

export default AddNewMasterItemSubMenu;
