import { pick } from "remeda";

import { NumberingMethod } from "../../enums";
import type { TOrganisation } from "./Organisation";

export function permissions(organisation: TOrganisation) {
  return pick(organisation, [
    "canAccessSchedule",
    "canIntegrateWithAccountingPackage",
    "canAccessRateCards",
    "canAccessBranches",
    "canAccessCollaboration",
    "canAccessCustomTemplates",
    "canAccessHappinessCoach",
    "canAccessNewJobPage",
  ]);
}

// extra computed

export function isJobNumberingMethodNone(organisation: TOrganisation) {
  return NumberingMethod.isNone(organisation.jobNumberingMethod);
}

export function isJobNumberingMethodAuto(organisation: TOrganisation) {
  return NumberingMethod.isAuto(organisation.jobNumberingMethod);
}

export function isJobNumberingMethodCustom(organisation: TOrganisation) {
  return NumberingMethod.isCustom(organisation.jobNumberingMethod);
}

export function isJobGroupNumberingMethodCustom(organisation: TOrganisation) {
  return NumberingMethod.isCustom(organisation.jobGroupNumberingMethod);
}
