import { get, startCase, values } from "lodash-es";
import * as PropTypes from "prop-types";
import { handleError } from "../../../../lib/errorHandlers";
import { FrameworkException } from "../../../../lib/exceptions/FrameworkException";
import {
  COMMERCIAL_DOCUMENT_TYPES,
  commercialDocumentEntityType,
  getCommercialDocumentTypeName
} from "../../../../lib/ui/commercialDocument";
import consumeCommercialDocument from "../CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({ document, commercialDocumentType, documentMethods }) => ({
  document,
  commercialDocumentType,
  getLanguageOptions: documentMethods.getLanguageOptions
});

const LanguageOption = ({
  optionKey,
  document,
  commercialDocumentType,
  getLanguageOptions
}) => {
  const value = get(getLanguageOptions(document), optionKey);

  try {
    const documentTypeName = getCommercialDocumentTypeName(
      commercialDocumentType
    );

    if (!value)
      throw new FrameworkException(
        `${documentTypeName} Language option '${optionKey}' not found`
      );

    return value;
  } catch (error) {
    handleError(error);

    return startCase(optionKey);
  }
};

LanguageOption.propTypes = {
  optionKey: PropTypes.string.isRequired,
  document: commercialDocumentEntityType.isRequired,
  commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
    .isRequired,
  getLanguageOptions: PropTypes.func.isRequired
};

export default consumeCommercialDocument(mapContext)(LanguageOption);
