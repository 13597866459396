export const JOB_ITEM_DEPENDANCY_SEGMENT_TYPE = {
  CIRCLE_LEFT_DOWN_CURVE_IN: "CIRCLE_LEFT_DOWN_CURVE_IN",
  CIRCLE_LEFT_DOWN_SNAKE_IN: "CIRCLE_LEFT_DOWN_SNAKE_IN",
  CIRCLE_LEFT_DOWN_CURVE_OUT: "CIRCLE_LEFT_DOWN_CURVE_OUT",
  CIRCLE_RIGHT_DOWN_CURVE_IN: "CIRCLE_RIGHT_DOWN_CURVE_IN",
  CIRCLE_RIGHT_DOWN_SNAKE_IN: "CIRCLE_RIGHT_DOWN_SNAKE_IN",
  CIRCLE_RIGHT_DOWN_CURVE_OUT: "CIRCLE_RIGHT_DOWN_CURVE_OUT",
  CIRCLE_LEFT_UP_CURVE_IN: "CIRCLE_LEFT_UP_CURVE_IN",
  CIRCLE_LEFT_UP_SNAKE_IN: "CIRCLE_LEFT_UP_SNAKE_IN",
  CIRCLE_LEFT_UP_CURVE_OUT: "CIRCLE_LEFT_UP_CURVE_OUT",
  CIRCLE_RIGHT_UP_CURVE_IN: "CIRCLE_RIGHT_UP_CURVE_IN",
  CIRCLE_RIGHT_UP_SNAKE_IN: "CIRCLE_RIGHT_UP_SNAKE_IN",
  CIRCLE_RIGHT_UP_CURVE_OUT: "CIRCLE_RIGHT_UP_CURVE_OUT",
  BRIDGE_LEFT: "BRIDGE_LEFT",
  BRIDGE_RIGHT: "BRIDGE_RIGHT"
};

export const JOB_ITEM_DEPENDANCY_SEGMENT_CIRCLE_SIDE = {
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};

export const JOB_ITEM_DEPENDANCY_SEGMENT_BRIDGE_SIDE = {
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};

export const JOB_ITEM_DEPENDANCY_SEGMENT_DIRECTION = {
  DOWN: "DOWN",
  UP: "UP"
};

export const JOB_ITEM_DEPENDANCY_SEGMENT_FLOW = {
  IN: "IN",
  OUT: "OUT"
};

export const BRIDGE_DISTANCE = 20;
