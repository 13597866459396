import { toastAlert } from "@streamtimefe/components";
import { Exception } from "st-shared/lib";

import { WebAppApiException } from "../exceptions/WebAppApiException";
import { routerFromWeb } from "../router";
import { hotkeyFromWeb, RECEIVE_WEB_HOTKEY } from "./fePages/hotkey";
import { RECEIVE_WEB_NAVIGATE } from "./fePages/index.consts";

export const RECEIVE_APPLY_MODEL_DATA = "applyModelData";
export const RECEIVE_REMOVE_MODEL = "removeModel";
export const RECEIVE_SCHEDULE_LOGGED_TIME_DATA = "scheduleLoggedTimeData";
export const RECEIVE_SCHEDULE_FILTER_SEARCH_RESULTS =
  "scheduleFilterSearchResults";
export const RECEIVE_SCHEDULE_FILTER_IS_SEARCHING = "scheduleFilterIsSearching";
export const RECEIVE_SCHEDULE_FILTER_TEAM_MEMBERS = "scheduleFilterTeamMembers";
export const RECEIVE_SCHEDULE_VIEW_MODE = "scheduleViewMode";
export const RECEIVE_SCHEDULE_AVAILABILITY_VIEW = "scheduleAvailabilityView";
export const RECEIVE_SCHEDULE_VIEW_PAUSED_LOGGED_TIMES =
  "scheduleViewPausedLoggedTimes";
export const RECEIVE_SCHEDULE_TODO_VISIBILITY_OPTIONS =
  "scheduleToDoVisibilityOptions";
export const RECEIVE_SCHEDULE_JOB_PROGRESS_MODE = "scheduleJobProgressMode";
export const RECEIVE_SCHEDULE_HAS_NO_ACTIVE_JOBS = "scheduleHasNoActiveJobs";
export const RECEIVE_SCHEDULE_SEARCH_QUERY = "scheduleSearchQuery";
export const RECEIVE_SCHEDULE_FILTER_COUNT = "scheduleFilterCount";
export const RECEIVE_START_MERGE_LINE_ITEMS = "startMergeLineItems";
export const RECEIVE_CONFIRM_MERGE_LINE_ITEMS = "confirmMergeLineItems";
export const RECEIVE_CANCEL_MERGE_LINE_ITEMS = "cancelMergeLineItems";
export const RECEIVE_START_SORT_LINE_ITEMS = "startSortLineItems";
export const RECEIVE_CONFIRM_SORT_LINE_ITEMS = "confirmSortLineItems";
export const RECEIVE_CANCEL_SORT_LINE_ITEMS = "cancelSortLineItems";
export const RECEIVE_COMMERCIAL_DOCUMENT_DISPLAY_OPTIONS =
  "commercialDocumentDisplayOptions";

export const SEND_ALERT_ERROR = "alertError";
export const SEND_APPLY_MODEL_DATA = "applyModelData";
export const SEND_ENTITY_SAVING = "entitySaving";
export const SEND_REMOVE_MODEL = "removeModel";
export const SEND_OPEN_SCHEDULE_LOGGED_TIME_MODAL =
  "openScheduleLoggedTimeModal";
export const SEND_OPEN_REPEATING_LOGGED_TIME_MODAL =
  "openRepeatingLoggedTimeModal";
export const SEND_CONFIRM_UNLINK_REPEATING_LOGGED_TIME =
  "confirmUnlinkRepeatingLoggedTime";
export const SEND_GET_PUSHER_SOCKET_ID = "getPusherSocketId";
export const SEND_SET_SCHEDULE_FILTER_TEAM_MEMBERS =
  "setScheduleFilterTeamMembers";
export const SEND_GO_TO_SCHEDULE_TODOS = "goToScheduleTodos";
export const SEND_OPEN_CREATE_JOB_ITEM_MODAL = "openCreateJobItemModal";
export const SEND_SCHEDULE_VIEW_MODE = "scheduleViewMode";
export const SEND_CLEAR_SCHEDULE_FILTERS = "clearScheduleFilters";
export const SEND_OPEN_CREATE_EDIT_CONTACT_MODAL = "openCreateEditContactModal";
export const SEND_OPEN_CREATE_ADDRESS_MODAL = "openCreateAddressModal";
export const SEND_OPEN_CREATE_TAX_RATE_MODAL = "openCreateTaxRateModal";
export const SEND_LINE_ITEM_MERGE_COUNT = "lineItemMergeCount";
export const SEND_COMMERCIAL_DOCUMENT_EDITING = "commercialDocumentEditing";
export const SEND_REFRESH_QUOTE_HTML = "refreshQuoteHtml";
export const SEND_REFRESH_INVOICE_HTML = "refreshInvoiceHtml";
export const SEND_REFRESH_PURCHASE_ORDER_HTML = "refreshPurchaseOrderHtml";
export const SEND_OPEN_SET_SUPPLIER_MODAL = "openSetSupplierModal";
export const SEND_SET_HEADINGS_VISIBLE = "setHeadingsVisible";
export const SEND_ASSIGN_INVOICE_NUMBER = "assignInvoiceNumber";

export const SEND_GET_QUOTE_BOOTSTRAP = "getQuoteBootstrap";
export const SEND_GET_INVOICE_BOOTSTRAP = "getInvoiceBootstrap";
export const SEND_GET_PURCHASE_ORDER_BOOTSTRAP = "getPurchaseOrderBootstrap";
export const SEND_GET_ACCOUNTING_PLATFORM_DETAILS =
  "getAccountingPlatformDetails";

export const SEND_ROUTE_TO_INVOICE = "routeToInvoice";
export const SEND_ROUTE_TO_QUOTE = "routeToQuote";
export const SEND_ROUTE_TO_JOB = "routeToJob";
export const SEND_ROUTE_TO_WIP = "routeToWIP";
export const SEND_ROUTE_TO_AVAILABILITY_MODAL = "openAvailabilityModal";

class WebAppAPIConnector {
  parentReceiver = null;

  receivers = {};

  constructor() {
    try {
      // noinspection JSUnresolvedVariable, JSUnresolvedFunction
      this.parentReceiver =
        window.parent.App.Framework.WebAppAPI.connectFEReceiver(
          this.receiveAction
        );

      window.addEventListener("beforeunload", () => {
        // noinspection JSUnresolvedVariable, JSUnresolvedFunction
        window.parent.App.Framework.WebAppAPI.disconnectFEReceiver();
      });

      this.registerReceiver(RECEIVE_WEB_NAVIGATE, routerFromWeb);
      this.registerReceiver(RECEIVE_WEB_HOTKEY, hotkeyFromWeb);
    } catch (error) {
      console.warn("Error connecting to parent WebAppAPI");
    }
  }

  registerReceiver(actionName, callback) {
    this.receivers[actionName] = callback;
  }

  unregisterReceiver(actionName) {
    delete this.receivers[actionName];
  }

  isRegistered(actionName) {
    return Boolean(this.receivers[actionName]);
  }

  receiveAction = (actionName, ...args) => {
    if (this.isRegistered(actionName))
      return this.receivers[actionName](...args);

    return null;
  };

  sendAction(actionName, ...args) {
    try {
      return this.parentReceiver(actionName, ...args);
    } catch (error) {
      if (!this.parentReceiver) {
        if (import.meta.env.PROD)
          throw new WebAppApiException(
            `could not send action ${actionName}: Parent Receiver not connected`
          );
        return null;
      }
      throw new WebAppApiException(error.message, error.stack);
    }
  }

  waitForDeferredAction = (actionName, wait = 60) => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(
          new Exception(`Deferred action timed out at after ${wait} seconds.`)
        );
        this.unregisterReceiver(actionName);
      }, wait * 1000);

      this.registerReceiver(actionName, (result) => {
        clearTimeout(timeout);
        this.unregisterReceiver(actionName);
        resolve(result);
      });
    });
  };

  isConnected = () => {
    return !!this.parentReceiver;
  };
}

const WebAppAPI = new WebAppAPIConnector();

export const sendApplyModelData = (primaryEntityClassName, data) => {
  if (
    "__primaryModelClassName" in data ||
    "__primaryModel" in data ||
    "__modelSet" in data ||
    "__removedEntityIds" in data
  ) {
    WebAppAPI.sendAction(SEND_APPLY_MODEL_DATA, data);
  } else {
    WebAppAPI.sendAction(SEND_APPLY_MODEL_DATA, {
      __primaryModelClassName: primaryEntityClassName,
      __primaryModel: data,
    });
  }
};

export const sendRemoveModel = (primaryEntityClassName, id) =>
  WebAppAPI.sendAction(SEND_REMOVE_MODEL, primaryEntityClassName, id);

export function sendAlertError(error) {
  if (import.meta.env.DEV) {
    toastAlert("error", error);
  } else {
    WebAppAPI.sendAction(SEND_ALERT_ERROR, error);
  }
}

export default WebAppAPI;
