import { sortBy } from "lodash-es";
import { useSelector } from "react-redux";

import { selectRole } from "../../../../redux/selectors/role";
import { AppState } from "../../../AppState";
import { selectJobItemRolesByJobItemId } from "./selectJobItemRolesByJobItemId";

export function selectOrderedJobItemRoleIdsByJobItemId(
  state: AppState,
  { jobItemId }: { jobItemId: number }
) {
  const jobItemRoles = selectJobItemRolesByJobItemId(state, { jobItemId });

  const normalized = jobItemRoles.map((jobItemRole) => {
    const role = selectRole(state, { roleId: jobItemRole.roleId });
    return {
      jobItemRoleId: jobItemRole.id,
      name: role.name,
    };
  });

  return sortBy(normalized, "name").map((obj) => obj.jobItemRoleId);
}

export function useOrderedJobItemRoleIdsByJobItemId(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectOrderedJobItemRoleIdsByJobItemId(state, { jobItemId })
  );
}
