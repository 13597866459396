import { TEntityId } from "../../entities/Entity";
import { useRoleFromUserEntity } from "../../stores";
import { primaryUserRoleNameCss } from "./PrimaryUserRoleName.css";

type Props = {
  userId: TEntityId;
  color?: string;
  fontSize?: number;
  isSelected?: boolean;
};

export function PrimaryUserRoleName({
  userId,
  color = "black",
  fontSize = 18,
  isSelected,
}: Props) {
  const role = useRoleFromUserEntity(userId);

  return (
    <div
      className={primaryUserRoleNameCss}
      style={{ color, fontSize }}
      data-selected={isSelected}
    >
      {role && role.name}
    </div>
  );
}
