import { sendApplyModelData } from "../WebAppAPI";
import { ENTITY_CLASS_JOB_MILESTONE } from "../constants";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const saveJobMilestoneAPI = jobMilestone =>
  (jobMilestone.id < 0
    ? API.post(`/job_milestones`, {
        ...jobMilestone,
        id: null
      })
    : API.put(`/job_milestones/${jobMilestone.id}`, jobMilestone)
  )
    .then(({ data }) => data)
    .then(data => {
      sendApplyModelData(ENTITY_CLASS_JOB_MILESTONE, data);
      return data;
    })
    .then(createModelSetParser(ENTITY_CLASS_JOB_MILESTONE));

export const deleteJobMilestoneAPI = id =>
  id > 0 &&
  API.delete(`/job_milestones/${id}`)
    .then(({ data }) => data)
    .then(data => {
      sendApplyModelData(null, data);
      return data;
    })
    .then(createModelSetParser());
