import styled from "styled-components";
import CapturePointerElement from "../../elements/CapturePointerElement";

const ScheduleBlocksContainer = styled(CapturePointerElement).attrs({
  className: "scheduleBlocksContainer"
})`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export default ScheduleBlocksContainer;
