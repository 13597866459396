import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceRecipients,
  getMetaDataInvoiceTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import ListRecipients from "./ListRecipients";
import SystemMessage from "./SystemMessage";

const JobInvoiceSent = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {` sent ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataInvoiceNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to "}
    <ListRecipients recipients={getMetaDataInvoiceRecipients(activityEntry)} />
  </SystemMessage>
);

JobInvoiceSent.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoiceSent;
