import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataPurchaseOrderRecipients,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import ListRecipients from "./ListRecipients";
import SystemMessage from "./SystemMessage";

const JobPurchaseOrderSent = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" sent a "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      purchase order
    </LinkToActivityEntryEntity>
    {" to "}
    <ListRecipients
      recipients={getMetaDataPurchaseOrderRecipients(activityEntry)}
    />
  </SystemMessage>
);

JobPurchaseOrderSent.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobPurchaseOrderSent;
