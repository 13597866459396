import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { useJobDetailsIsCurrentFocus } from "../../../redux/selectors/jobDetails/ui/IsCurrentFocus";
import { useJobItemSubItemIdsOrderedByJobItemId } from "../../../redux/selectors/jobItemSubItem/selectJobItemSubItemIdsOrderedByJobItemId";

export default function useIsChecklistHidden(jobItemId) {
  const subItemIds = useJobItemSubItemIdsOrderedByJobItemId(jobItemId);
  const isFocused = useJobDetailsIsCurrentFocus({
    jobItemId,
    key: FOCUS_KEYS.ITEM_NEW_SUB_ITEM
  });
  return subItemIds.length === 0 && !isFocused;
}
