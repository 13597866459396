import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobPhaseIdsByJobId } from "../jobPhase/jobPhaseIdsByJobId";

export const selectCanExpandAndCollapsePhases = createCachedSelector(
  [selectJobPhaseIdsByJobId],
  jobPhaseIds => jobPhaseIds.length > 0
)(selectPropsJobId);

export const useCanExpandAndCollapsePhases = jobId =>
  useSelector(state => selectCanExpandAndCollapsePhases(state, { jobId }));
