import React from "react";
import {
  activityEntryEntityType,
  getMetaDataJobStatusId
} from "../../../../../lib/entities/activityEntryEntity";
import { getJobStatusName } from "../../../../../lib/entities/jobEntity";
import SystemMessage from "./SystemMessage";

const JobStatusChanged = ({ activityEntry }) => (
  <SystemMessage>
    {"The job’s status has been changed to "}
    <b>{getJobStatusName(getMetaDataJobStatusId(activityEntry))}</b>
  </SystemMessage>
);

JobStatusChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobStatusChanged;
