import type { TCurrency } from "@streamtimefe/entities";
import { forEach, isEmpty } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../../entities";
import { currencyObj } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { storeActionAddListener } from "../..";
import { addGlobalStore } from "../../helpers/global";
import type { ChangedEntity, EntityStoreState } from "..";
import { byIdReducer, parseEntityPayload, parseRemovedEntities } from "..";

interface CurrencyEntityStoreState extends EntityStoreState<TCurrency> {
  options: SearchOption<TCurrency>[];
  groupedOptions: Record<string, SearchOption<TCurrency>[]>;
}

export const useCurrencyEntityStore = create<CurrencyEntityStoreState>(
  function (set, get) {
    return {
      entities: {},
      options: [],
      groupedOptions: {},
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.Currency])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.Currency]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.Currency) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TCurrency>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);

        const options: SearchOption<TCurrency>[] = [];

        const groupedOptions: Record<string, SearchOption<TCurrency>[]> = {
          "Commonly Used": [],
          Others: [],
        };

        forEach(entities, (entity) => {
          const option = currencyObj(entity).getAsOption();
          options.push(option);
          if (entity.important) {
            groupedOptions["Commonly Used"].push(option);
          } else {
            groupedOptions["Others"].push(option);
          }
        });

        set({
          entities,
          options,
          groupedOptions,
        });
      },
    };
  }
);

export function currencyEntityStoreInit() {
  addGlobalStore(["entities", EntityListKey.Currency], currencyEntityStore);
  storeActionAddListener(
    "entitiesReceived",
    currencyEntityStore().receiveEntities
  );
  storeActionAddListener(
    "entitiesRemoved",
    currencyEntityStore().removeEntities
  );
}

export function currencyEntityStore() {
  return useCurrencyEntityStore.getState();
}

export function useCurrencyEntity(entityId: TEntityId) {
  return useCurrencyEntityStore((s) => s.entities[entityId]);
}

export function useCurrencyOptions() {
  return useCurrencyEntityStore((s) => s.options);
}

export function useCurrencyGroupedOptions() {
  return useCurrencyEntityStore((s) => s.groupedOptions);
}
