import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobInvoicePaid = ({ activityEntry, canEditJobs }) => (
  <SystemMessage>
    {`The ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataInvoiceNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" has been marked as paid"}
  </SystemMessage>
);

JobInvoicePaid.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoicePaid;
