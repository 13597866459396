import { bugsnagClient } from "@streamtimefe/error";
import { keys } from "lodash-es";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  authenticationStore,
  organisationEntityStore,
  userEntityStore,
} from "st-shared/stores";

import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { selectScheduleLoggedTimesByIds } from "../../../../redux/selectors/scheduleSelectors";
import { actionEntitiesReceived } from "../../../entities/actions";
import type { ScheduleLoggedTimePurgeFetchRequestAction } from "../actions";
import {
  actionScheduleLoggedTimeFetchSuccess,
  actionScheduleLoggedTimePurge,
  SCHEDULE_LOGGED_TIMES_PURGE_FETCH_REQUEST,
} from "../actions";
import ScheduleAPI from "../api";
import { ScheduleUi } from "../types";

function* fetch(action: ScheduleLoggedTimePurgeFetchRequestAction) {
  try {
    const scheduleLoggedTimes: any = yield select(
      selectScheduleLoggedTimesByIds
    );
    const scheduleLoggedTimesCount = keys(scheduleLoggedTimes).length;

    if (scheduleLoggedTimesCount < ScheduleUi.PurgeThreshold) return;

    const userId = authenticationStore().loggedInUserId!;
    const loggedInUser = userEntityStore().entities[userId];
    const organisation = organisationEntityStore().entity;

    bugsnagClient?.notify({
      name: "Schedule Purge",
      message: `count: ${scheduleLoggedTimesCount}, user: ${loggedInUser.displayName}(${loggedInUser.id}), org: ${organisation.name}(${organisation.id})`,
    });

    const data: Awaited<
      ReturnType<typeof ScheduleAPI.fetchScheduleLoggedTimes>
    > = yield call(
      ScheduleAPI.fetchScheduleLoggedTimes,
      action.startDate,
      action.endDate,
      action.userIds
    );

    yield put(actionScheduleLoggedTimePurge());
    yield put(actionEntitiesReceived(data));
    yield put(
      actionScheduleLoggedTimeFetchSuccess(
        action.startDate,
        action.endDate,
        action.userIds
      )
    );
  } catch (error: unknown) {
    sagaError(error);
  }
}

export default function* watchPurgeFetchScheduleLoggedTimes() {
  yield takeEvery(SCHEDULE_LOGGED_TIMES_PURGE_FETCH_REQUEST, fetch);
}
