import { filter } from "lodash-es";
import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { asDecimal } from "../math";
import { entityFieldDecimalType } from "../types/entityTypes";

export const ACCOUNTING_PLATFORM_ACCOUNT_TYPES = {
  REVENUE: "REVENUE",
  EXPENSES: "EXPENSES",
  PAYMENTS: "PAYMENTS",
};

export const accountingPlatformStatusType = PropTypes.shape({
  name: PropTypes.string,
  hasRevenueAccountCode: PropTypes.bool,
  hasExpenseAccountCode: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isDisconnected: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetched: PropTypes.bool.isRequired,
});

export const accountingPlatformTaxRateType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: entityFieldDecimalType.isRequired,
  canApplyToRevenue: PropTypes.bool.isRequired,
  canApplyToExpenses: PropTypes.bool.isRequired,
});

export const accountType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  taxRef: PropTypes.string,
});

export const accountingPlatformOptionType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  taxRef: PropTypes.string,
  code: PropTypes.string,
  searchString: PropTypes.string,
  isHeading: PropTypes.bool,
});

export const isAccountingPlatformStatusActive = (accountingPlatformStatus) =>
  accountingPlatformStatus.isActive;

export const hasAccountingPlatformStatusRevenueCode = (
  accountingPlatformStatus
) => accountingPlatformStatus.hasRevenueAccountCode;

export const hasAccountingPlatformStatusExpenseCode = (
  accountingPlatformStatus
) => accountingPlatformStatus.hasExpenseAccountCode;

export const filterRevenueTaxRates = (externalTaxRates) =>
  filter(externalTaxRates, "canApplyToRevenue");

export const filterRevenueTaxRatesAndExternalRateId = (externalTaxRates, id) =>
  filter(
    externalTaxRates,
    (taxRate) => taxRate.canApplyToRevenue || taxRate.id === id
  );

export const filterExpenseTaxRates = (externalTaxRates) =>
  filter(externalTaxRates, "canApplyToExpenses");

export const filterExpenseTaxRatesAndExternalRateId = (externalTaxRates, id) =>
  filter(
    externalTaxRates,
    (taxRate) => taxRate.canApplyToExpenses || taxRate.id === id
  );

export const getExternalTaxRateName = ({ name }) => name;

export const getExternalTaxRateRate = ({ rate }) => asDecimal(rate);

export const getAccountId = ({ id }) => id;

export const getAccountName = ({ name }) => name;

export const getAccountCode = ({ code }) => code;

export const getAccountTaxRef = ({ taxRef }) => taxRef;

export const getAccountSearchString = (revenueAccount) =>
  [
    asString(getAccountCode(revenueAccount)),
    asString(getAccountName(revenueAccount)),
  ].join(" ");

export const getAccountAsOption = (revenueAccount) => ({
  key: getAccountId(revenueAccount),
  value: getAccountName(revenueAccount),
  taxRef: getAccountTaxRef(revenueAccount),
  code: getAccountCode(revenueAccount),
  type: revenueAccount.class,
  searchString: getAccountSearchString(revenueAccount),
});

export const externalTaxRateOptionType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rate: entityFieldDecimalType,
  searchString: PropTypes.string,
});

export const getTaxRateSearchString = (taxRate) =>
  [
    asString(getExternalTaxRateName(taxRate)),
    asString(getExternalTaxRateRate(taxRate)),
  ].join(" ");

export const getTaxRateAsOption = (taxRate) => ({
  key: taxRate.id,
  value: getExternalTaxRateName(taxRate),
  rate: getExternalTaxRateRate(taxRate),
  searchString: getTaxRateSearchString(taxRate),
});

export const getAccountingPlatformAccountCode = (
  masterItem,
  accountingPlatformId,
  type
) => {
  if (!masterItem.accountingPlatformData) return null;

  const data = JSON.parse(masterItem.accountingPlatformData);
  if (data && data[accountingPlatformId] && data[accountingPlatformId][type]) {
    return data[accountingPlatformId][type];
  }

  return null;
};

export const getRevenueAccountCode = (masterItem, accountingPlatformId) => {
  return getAccountingPlatformAccountCode(
    masterItem,
    accountingPlatformId,
    "revenue"
  );
};

export const getExpenseAccountCode = (masterItem, accountingPlatformId) => {
  return getAccountingPlatformAccountCode(
    masterItem,
    accountingPlatformId,
    "expense"
  );
};
