import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getJobItemDescription } from "../../../lib/entities/jobItemEntity";
import { useJobDetailsIsCurrentFocus } from "../../../redux/selectors/jobDetails/ui/IsCurrentFocus";
import { useJobItem } from "../../../redux/selectors/jobItem";

export default function useIsAdditionalInformationHidden(jobItemId) {
  const jobItem = useJobItem(jobItemId);
  const additionalInformation = getJobItemDescription(jobItem) || "";
  const isFocused = useJobDetailsIsCurrentFocus({
    jobItemId,
    key: FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION
  });
  return additionalInformation.length === 0 && !isFocused;
}
