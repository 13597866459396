import { isEqual } from "lodash-es";
import { put, call } from "redux-saga/effects";
import { updateJobAPI } from "../../../lib/API/jobAPI";
import {
  ENTITIES_RECEIVED,
  JOB_SAVE,
  JOB_SAVE_ERROR,
  JOB_SAVED,
  JOB_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

export function* saveJob(action) {
  const { jobId, job, previousJob } = action.payload;

  try {
    if (isEqual(job, previousJob)) return;

    yield put(
      createAction(JOB_SAVING, {
        jobId,
        job
      })
    );

    const { data } = yield call(updateJobAPI, job);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_SAVED, {
        jobId,
        job,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_SAVE_ERROR, {
        jobId,
        job,
        previousJob,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchJobSave() {
  yield takeLatestBy([JOB_SAVE], saveJob, action => action.payload.jobId);
}
