import { LOGGED_TIME_STATUS_ID_COMPLETE } from "../../../../../lib/constants";
import reduceChangedEntities from "../reduceChangedEntities";

const logBlockReducer = (state, action) => {
  const { blockKey } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  if (prevBaseScheduleLoggedTime === LOGGED_TIME_STATUS_ID_COMPLETE)
    return state;

  const changedEntities = prevScheduleLoggedTimeIds
    .map(id => state.byId[id])
    .map(entity => ({
      prevEntity: entity,
      newEntity: {
        ...entity,
        loggedTimeStatusId: LOGGED_TIME_STATUS_ID_COMPLETE
      }
    }));

  return reduceChangedEntities(state, changedEntities);
};

export default logBlockReducer;
