import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import styled from "styled-components";

import {
  COMMERCIAL_DOCUMENT_TYPES,
  getCommercialDocumentTypeName,
} from "../../../lib/ui/commercialDocument";
import Flex from "../../elements/Flex";
import AddLineButton from "./AddLineMenu/AddLineButton";

const LineItemsEmptyState = ({ commercialDocumentType }) => (
  <Wrapper
    className={
      commercialDocumentType === COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER
        ? "PurchaseOrder"
        : ""
    }
  >
    <Heading>Uh oh. It’s feeling pretty quiet in here.</Heading>
    <Message>
      Add a heading, item or expense to this{" "}
      {getCommercialDocumentTypeName(
        commercialDocumentType
      ).toLocaleLowerCase()}
      .
    </Message>
    <AddLineButton orderId={1} large bottom />
  </Wrapper>
);

LineItemsEmptyState.propTypes = {
  commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
    .isRequired,
};

export default LineItemsEmptyState;

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 70px 30px 50px;
  margin-top: 15px;
  &.PurchaseOrder {
    width: calc(100% - 240px);
  }
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: medium;
  text-align: center;
  color: var(--color-gray-dark);
  margin-top: 20px;
`;
