import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { TEntityId } from "st-shared/entities/Entity";

import {
  JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
  JOB_PHASE_CREATE,
} from "../../../lib/constants";
import { JOB_BOTTOM_SECTION_TABS } from "../../../lib/constants/jobDetails";
import { scrollToBottomSection } from "../../../lib/ui/jobDetails";
import createAction from "../../../redux/helpers/createAction";

export function useCreateNewJobPhase(jobId: TEntityId) {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(
      createAction(
        JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
        JOB_BOTTOM_SECTION_TABS.JOB_PLAN
      )
    );
    requestAnimationFrame(() => {
      scrollToBottomSection(true);
      dispatch(createAction(JOB_PHASE_CREATE, { jobId }));
    });
  }, [jobId]);
}
