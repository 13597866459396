import { useSelector } from "react-redux";
import { getRoleId } from "../../../lib/entities/userEntity";
import { selectUser } from "../user";
import { selectRole } from "./index";

export const selectRoleByUserId = (state, { userId }) =>
  selectRole(state, {
    roleId: getRoleId(selectUser(state, { userId }))
  });

export const useRoleByUserId = userId =>
  useSelector(state => selectRoleByUserId(state, { userId }));
