import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { JOB_COST_VS_SELL_MODE } from "../../../../lib/constants/jobDetails";
import {
  getJobCurrencyTotalLoggedExpensesCostExTax,
  getJobCurrencyTotalLoggedExpensesExTax,
  getJobCurrencyTotalPlannedExpensesExTax,
} from "../../../../lib/entities/jobEntity";
import { jobPageOpenLoggedExpensesModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJumbotronTotalExpensesDisplay } from "../../../../redux/selectors/jobDetails/jumbotronTotalExpensesDisplay";
import { useJobDetailsCostVsSellMode } from "../../../../redux/selectors/jobDetails/ui/costVsSellMode";
import { useLoggedExpenseStats } from "../../../../redux/selectors/loggedExpense/loggedExpenseStats";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import {
  JumbotronTooltipTable,
  LegendDisplay,
  TooltipTitle,
  TooltipValue,
} from "../styles";

const JumbotronExpenses = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const costVsSellMode = useJobDetailsCostVsSellMode();
  const totalExpensesDisplay = useJumbotronTotalExpensesDisplay(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const [counts, totals, totalCosts] = useLoggedExpenseStats(jobId);

  return (
    <Tooltip
      title={getExpenseTooltip(
        job,
        counts,
        totals,
        totalCosts,
        jobCurrency,
        customerCurrency,
        costVsSellMode
      )}
      placement="right"
    >
      <JumbotronProgressField
        name="EXPENSES"
        onClick={() => jobPageOpenLoggedExpensesModal(job)}
        progressColor="var(--color-blue)"
        progressPercentage={getProgressPercentage(job, costVsSellMode)}
      >
        <JumbotronProgressFieldValue>
          <span className="bold">
            {totalExpensesDisplay.totalLoggedExpenses}
          </span>

          <span> / {totalExpensesDisplay.totalPlannedExpenses}</span>
        </JumbotronProgressFieldValue>
      </JumbotronProgressField>
    </Tooltip>
  );
};

export default JumbotronExpenses;

const getProgressPercentage = (job, costVsSellMode) => {
  const plannedExpensesExTax = getJobCurrencyTotalPlannedExpensesExTax(job);
  let numerator = 0;

  if (costVsSellMode === JOB_COST_VS_SELL_MODE.COST) {
    numerator = getJobCurrencyTotalLoggedExpensesCostExTax(job);
  } else {
    numerator = getJobCurrencyTotalLoggedExpensesExTax(job);
  }

  if (numerator > 0) {
    return Math.round((numerator / plannedExpensesExTax) * 100);
  }

  return 0;
};

const getExpenseTooltip = (
  job,
  counts,
  totals,
  totalCosts,
  jobCurrency,
  customerCurrency,
  costVsSellMode
) => {
  return (
    <JumbotronTooltipTable>
      <tbody>
        <tr>
          <td></td>
          <TooltipTitle>
            Used{" "}
            {costVsSellMode === JOB_COST_VS_SELL_MODE.COST ? "Cost" : "Sell"}
            <LegendDisplay $color="var(--color-blue)"></LegendDisplay>
          </TooltipTitle>
          <TooltipTitle>
            Planned
            <LegendDisplay $color="var(--color-gray)"></LegendDisplay>
          </TooltipTitle>
        </tr>
        <tr className="divider">
          <td colSpan="3"></td>
        </tr>
        <tr className="dividerBlank">
          <td colSpan="3"></td>
        </tr>
        <tr>
          <TooltipTitle>Total</TooltipTitle>
          <TooltipTitle $padded>
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? getJobCurrencyTotalLoggedExpensesCostExTax(job)
                  : getJobCurrencyTotalLoggedExpensesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <TooltipTitle $padded>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalPlannedExpensesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
        </tr>
        {counts.draft > 0 && (
          <tr>
            <TooltipValue>Draft</TooltipValue>
            <TooltipValue>-</TooltipValue>
            <TooltipValue>
              {formatForeignCurrency(customerCurrency, {
                value: totals.draft,
                currency: jobCurrency,
              })}
            </TooltipValue>
          </tr>
        )}
        {counts.approved > 0 && (
          <tr>
            <TooltipValue>Approved</TooltipValue>
            <TooltipValue>
              {formatForeignCurrency(customerCurrency, {
                value:
                  costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                    ? totalCosts.approved
                    : totals.approved,
                currency: jobCurrency,
              })}
            </TooltipValue>
            <TooltipValue>
              {formatForeignCurrency(customerCurrency, {
                value: totals.approved,
                currency: jobCurrency,
              })}
            </TooltipValue>
          </tr>
        )}
        {counts.paid > 0 && (
          <tr>
            <TooltipValue>Paid</TooltipValue>
            <TooltipValue>
              {formatForeignCurrency(customerCurrency, {
                value:
                  costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                    ? totalCosts.paid
                    : totals.paid,
                currency: jobCurrency,
              })}
            </TooltipValue>
            <TooltipValue>
              {formatForeignCurrency(customerCurrency, {
                value: totals.paid,
                currency: jobCurrency,
              })}
            </TooltipValue>
          </tr>
        )}
      </tbody>
    </JumbotronTooltipTable>
  );
};
