import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getSpecificRelativeDateText } from "../../../../lib/dates";

const JobActivityDateSeparator = ({ date }) => (
  <Wrapper>
    <Line />
    <DateWrapper>
      <Date>{getSpecificRelativeDateText(date)}</Date>
    </DateWrapper>
  </Wrapper>
);

JobActivityDateSeparator.propTypes = {
  date: PropTypes.string.isRequired
};

export default JobActivityDateSeparator;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 20px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: var(--border-thin-less-bright);
`;

const DateWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Date = styled.div`
  background-color: var(--color-jonsnow);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
  color: var(--color-gray-dark);
  text-transform: uppercase;
`;
