import {
  addDependencies,
  create,
  divideDependencies,
  evaluateDependencies,
  multiplyDependencies,
  subtractDependencies,
  unaryMinusDependencies,
  unaryPlusDependencies,
} from "mathjs/number";

export const mathjs = create({
  evaluateDependencies,
  addDependencies,
  subtractDependencies,
  multiplyDependencies,
  divideDependencies,
  unaryMinusDependencies,
  unaryPlusDependencies,
});

export type * as MathJsTypes from "mathjs";
