import { produce } from "immer";
import { get, isEmpty, setWith, uniq, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { EMPTY_ARRAY } from "../../../../lib/constants";
import {
  getJobIdIfNoItemBaseLoggedTime,
  getJobIdKeyPath,
  getUserId,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath =
        getJobIdIfNoItemBaseLoggedTime(prevEntity) &&
        getJobIdKeyPath(prevEntity);
      const newPath =
        getJobIdIfNoItemBaseLoggedTime(newEntity) && getJobIdKeyPath(newEntity);

      if (prevPath) pushOnce(touchedPaths, prevPath);
      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((path) => {
      const userIds = uniq(
        get(nextState, `blockKeysByJobId.${path}`, EMPTY_ARRAY)
          .map((blockKey) => get(nextState, `byId.${blockKey}`))
          .filter(getJobIdIfNoItemBaseLoggedTime)
          .map(getUserId)
      );

      if (!isEmpty(userIds))
        setWith(draft, `userIdsByJobIdNoItem.${path}`, userIds, Object);
      else unset(draft, `userIdsByJobIdNoItem.${path}`);
    });
  });
