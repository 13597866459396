import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../../lib/constants";
import { jobPageOpenQuotesModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useQuoteStats } from "../../../../redux/selectors/quote/quoteStats";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import { JumbotronTooltipTable, LegendDisplay, TooltipTitle } from "../styles";

const JumbotronQuotes = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const [counts, totals] = useQuoteStats(jobId);

  const text = (() => {
    let value = null;
    let description = null;

    if (counts.approved > 0 && counts.pending === 0) {
      value = totals.approved;
      description = "approved";
    } else if (counts.pending > 0) {
      value = totals.pending;
      description = "pending";
    } else if (counts.draft > 0) {
      value = totals.draft;
      description = "draft";
    } else if (counts.declined > 0) {
      value = totals.declined;
      description = "declined";
    }

    return {
      value: formatForeignCurrency(customerCurrency, {
        value,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }),
      description,
    };
  })();

  return (
    <Tooltip
      title={getQuoteTooltip(counts, totals, customerCurrency, jobCurrency)}
      placement="right"
    >
      <JumbotronProgressField
        name="QUOTES"
        onClick={() => jobPageOpenQuotesModal(job)}
        progressColor="var(--color-green)"
        progressPercentage={getProgressPercentage(totals)}
      >
        <div>
          <JumbotronProgressFieldValue>
            <span className="bold">{text.value}</span>
            <span> {text.description}</span>
          </JumbotronProgressFieldValue>
        </div>
      </JumbotronProgressField>
    </Tooltip>
  );
};

const getQuoteTooltip = (counts, totals, customerCurrency, jobCurrency) => (
  <JumbotronTooltipTable>
    <tbody>
      {counts.draft > 0 && (
        <tr>
          <td>Draft</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: totals.draft,
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell"></td>
        </tr>
      )}
      {counts.pending > 0 && (
        <tr>
          <td>Pending</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: totals.pending,
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell"></td>
        </tr>
      )}
      {counts.approved > 0 && (
        <tr>
          <td>Approved</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: totals.approved,
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell">
            <LegendDisplay $color="var(--color-green)"></LegendDisplay>
          </td>
        </tr>
      )}
      {counts.declined > 0 && (
        <tr>
          <td>Declined</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: totals.declined,
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell"></td>
        </tr>
      )}
      <tr className="divider">
        <td colSpan="3"></td>
      </tr>
      <tr className="dividerBlank">
        <td colSpan="3"></td>
      </tr>
      <tr>
        <td>Sent</td>
        <TooltipTitle>
          {formatForeignCurrency(customerCurrency, {
            value: totals.issued,
            currency: jobCurrency,
          })}
        </TooltipTitle>
        <td className="legend-display-cell">
          <LegendDisplay $color="var(--color-gray)"></LegendDisplay>
        </td>
      </tr>
    </tbody>
  </JumbotronTooltipTable>
);

const getProgressPercentage = (totals) => {
  const approvedQuotesExTax = totals.approved;
  const sentQuotesExTax = totals.approved + totals.pending;

  if (approvedQuotesExTax > 0) {
    return Math.round((approvedQuotesExTax / sentQuotesExTax) * 100);
  }

  return 0;
};

export default JumbotronQuotes;
