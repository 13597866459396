import { values } from "lodash-es";

export const ConditionMatchType = {
  And: 1,
  Or: 2,
} as const;

export type TConditionMatchType =
  (typeof ConditionMatchType)[keyof typeof ConditionMatchType];

export const ConditionMatchTypeProperties: Record<
  TConditionMatchType,
  { name: string }
> = {
  [ConditionMatchType.And]: { name: "and" },
  [ConditionMatchType.Or]: { name: "or" },
} as const;

export function getNextCondition(
  current: TConditionMatchType
): TConditionMatchType {
  const vals = values(ConditionMatchType);
  const index = vals.indexOf(current);
  if (index === -1 || index + 1 >= vals.length) {
    return vals[0];
  }
  return vals[index + 1];
}

export function getConditionMatchName(
  conditionMatchTypeId: TConditionMatchType
) {
  return ConditionMatchTypeProperties[conditionMatchTypeId].name;
}
