export const addKeyEventListener = (type, callback) => {
  window.addEventListener(type, callback);
  if (window !== window.top) window.top.addEventListener(type, callback);
};

export const removeKeyEventListener = (type, callback) => {
  window.removeEventListener(type, callback);
  if (window !== window.top) window.top.removeEventListener(type, callback);
};

export const getClientCoords = (e) => ({
  x: e.clientX,
  y: e.clientY,
});
