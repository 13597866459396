import { produce } from "immer";
import { setWith } from "lodash-es";

import {
  JOB_ITEM_DRAG_START,
  JOB_ITEM_RESIZE_START,
  JOB_ITEM_SAVE_EDIT,
} from "../../../lib/constants";
import createCrossReducer from "../../helpers/createCrossReducer";
import {
  selectJobItemById,
  selectJobItemIdsByJobId,
} from "../../selectors/jobItemSelectors";

const snapshotJobItemsReducer = (state, action) =>
  produce(state, (draft) => {
    const { jobId, snapshotId } = action.payload;

    const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

    const snapshot = Object.assign(
      {},
      ...jobItemIds.map((id) => ({ [id]: selectJobItemById(state, { id }) }))
    );

    setWith(draft, `snapshots.${snapshotId}`, snapshot, Object);
  });

const snapshotEditJobItemsReducer = (state, action) =>
  produce(state, (draft) => {
    const { jobId, prevJobItem, snapshotId } = action.payload;

    const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

    const snapshot = Object.assign(
      {},
      ...jobItemIds.map((id) => ({ [id]: selectJobItemById(state, { id }) })),
      { [prevJobItem.id]: prevJobItem }
    );

    setWith(draft, `snapshots.${snapshotId}`, snapshot, Object);
  });

export default createCrossReducer({
  [JOB_ITEM_DRAG_START]: snapshotJobItemsReducer,
  [JOB_ITEM_RESIZE_START]: snapshotJobItemsReducer,
  [JOB_ITEM_SAVE_EDIT]: snapshotEditJobItemsReducer,
});
