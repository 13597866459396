import clsx from "clsx";
import { Icon, IconSize, MdHelp, RTooltip } from "st-shared/components";
import { theme } from "st-shared/theme";

import {
  buttonCss,
  helpButtonCss,
  helpOptionHeaderCss,
  helpTooltipCss,
  helpTooltipIconCss,
  iconCss,
  iconLabelWrapperCss,
  linkCss,
  optionWrapperCss,
} from "./HelpTooltip.css";
import { HelpOption, helpOptionItems } from "./HelpTooltipItems";

type Props = {
  className?: string;
};

export function HelpTooltip({ className }: Props) {
  function renderLinkOrButton(item: HelpOption) {
    if (item.url) {
      return (
        <a href={item.url} target="_blank" rel="noreferrer" className={linkCss}>
          {item.label}
        </a>
      );
    }

    return (
      <button className={buttonCss} onClick={item.onClickHandler}>
        {item.label}
      </button>
    );
  }

  return (
    <>
      <div
        className={clsx(helpButtonCss, className)}
        data-tooltip-id="help-tooltip"
      >
        <Icon
          icon={MdHelp}
          size={IconSize.XLarge}
          className={helpTooltipIconCss}
        />
      </div>
      <RTooltip
        id="help-tooltip"
        className={helpTooltipCss}
        place="bottom"
        positionStrategy="fixed"
        clickable
        offset={25}
        render={() => (
          <>
            {helpOptionItems.map((optionGroup) => (
              <div key={optionGroup.title} className={optionWrapperCss}>
                <h4 className={helpOptionHeaderCss}>{optionGroup.title}</h4>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {optionGroup.options.map((item) => (
                    <li key={item.id} className={iconLabelWrapperCss}>
                      {item.icon !== undefined && (
                        <Icon
                          icon={item.icon}
                          size={16}
                          color={theme.color.gray}
                          className={iconCss}
                        />
                      )}
                      {renderLinkOrButton(item)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        )}
      />
    </>
  );
}
