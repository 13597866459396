import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectIsScheduleSearchResultsEmpty } from "../../../redux/selectors/scheduleSelectors";
import {
  isItemsByJobs,
  isJobs,
  isJobsByPeople,
  isPeopleByJobs,
  isTodos,
} from "../../../state/ui/schedule/lib";
import {
  useScheduleUiHasNoActiveJobs,
  useScheduleUiIsSearching,
  useScheduleUiViewMode,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  JobGroupSearchingPulser,
  JobRowsConsumer,
  UserRowsConsumer,
} from "../ScheduleScrollProvider/ScheduleRowConsumer";
import EmptyJobsHeroMessage from "./EmptyJobsHeroMessage";
import EmptySearchResultsHeroMessage from "./EmptySearchResultsHeroMessage";
import ScheduleJobGroup from "./ScheduleJobGroup";
import ScheduleJobPhasesGroup from "./ScheduleJobPhasesGroup";
import ScheduleJobTeamMembersGroup from "./ScheduleJobTeamMembersGroup";
import ScheduleUserActionHandlers from "./ScheduleUserActionHandlers";
import ScheduleUserJobsGroup from "./ScheduleUserJobsGroup";
import ScheduleUserRole from "./ScheduleUserRole";
import ScheduleUserTodoGroup from "./ScheduleUserTodoGroup";

function ScheduleGroupColumns() {
  const viewMode = useScheduleUiViewMode();
  const isSearching = useScheduleUiIsSearching();
  const isEmpty = useSelector(selectIsScheduleSearchResultsEmpty);
  const hasNoActiveJobs = useScheduleUiHasNoActiveJobs();

  if (isJobs(viewMode)) {
    if (isSearching) return <JobGroupSearchingPulser />;

    if (hasNoActiveJobs) return <EmptyJobsHeroMessage />;

    if (isEmpty) return <EmptySearchResultsHeroMessage />;

    return (
      <JobRowsConsumer>
        {(jobId, offsetTop) => (
          <ScheduleJobGroup jobId={jobId}>
            {isItemsByJobs(viewMode) && (
              <ScheduleJobPhasesGroup jobId={jobId} />
            )}
            {isPeopleByJobs(viewMode) && (
              <ScheduleJobTeamMembersGroup id={jobId} offsetTop={offsetTop} />
            )}
          </ScheduleJobGroup>
        )}
      </JobRowsConsumer>
    );
  }

  return (
    <ScheduleUserActionHandlers>
      {(getUserActionHandlers) => (
        <UserRowsConsumer>
          {(userId) => {
            return (
              <GroupWrapper>
                <ScheduleUserTodoGroup
                  id={userId}
                  {...getUserActionHandlers(userId)}
                />
                {isTodos(viewMode) && <ScheduleUserRole userId={userId} />}
                {isJobsByPeople(viewMode) && (
                  <ScheduleUserJobsGroup
                    id={userId}
                    isSearching={isSearching}
                  />
                )}
              </GroupWrapper>
            );
          }}
        </UserRowsConsumer>
      )}
    </ScheduleUserActionHandlers>
  );
}

export default ScheduleGroupColumns;

const GroupWrapper = styled.div`
  height: 100%;
`;
