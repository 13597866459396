import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Flex from "../../elements/Flex";
import Tooltip from "../Tooltip";
import ExpenseAccountName from "./ExpenseAccountName";

const mapState = (state, props) => ({});

class ExpenseAccountTooltip extends React.PureComponent {
  static propTypes = {
    accountCode: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    accountCode: ""
  };

  get title() {
    const { accountCode } = this.props;

    if (!accountCode) return null;

    return (
      <Content>
        <div>
          <ExpenseAccountName accountCode={accountCode} />
        </div>
        <div>{accountCode}</div>
      </Content>
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Tooltip title={this.title} placement="top">
        {children}
      </Tooltip>
    );
  }
}

export default connect(mapState)(ExpenseAccountTooltip);

const Content = styled(Flex)`
  font-size: 12px;
  > :first-child {
    color: white;
    font-weight: 500;
  }
  > :last-child {
    color: var(--color-gray);
    font-weight: bold;
    margin-left: 10px;
  }
`;
