import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobPhaseStore = createSingleEntityStoreContext(
  EntityClass.JobPhase
);

export const JobPhaseStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobPhase,
  JobPhaseStore
);
