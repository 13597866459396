import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { TEntityId } from "st-shared/entities/Entity";
import {
  formatCustomerCurrency,
  num,
  NumberFormats,
  NumFormats,
} from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  selectJobCurrencyById,
  selectJobExchangeRateById,
} from "../../../redux/selectors/rateCardSelectors";

interface Props {
  id: TEntityId;
  prefix?: ReactNode;
}

export function JobCurrencyConversionInfo({ id, prefix }: Props) {
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useSelector((s) => selectJobCurrencyById(s, { id }));
  const jobExchangeRate = useSelector((s) =>
    selectJobExchangeRateById(s, { id })
  );

  if (jobCurrency.id === customerCurrency.id) return null;

  return (
    <span>
      {prefix}
      {formatCustomerCurrency(customerCurrency, {
        value: 1,
        format: NumberFormats.NoDecimal,
      })}{" "}
      {customerCurrency.id}
      {" = "}
      {num(jobExchangeRate).format(NumFormats.OptionalMantissa)}{" "}
      {jobCurrency.id}
    </span>
  );
}
