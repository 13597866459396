import React from "react";
import {
  activityEntryEntityType,
  getMetaDataJobCurrencyTotalPlannedTimeExTax,
  getMetaDataRateCardId
} from "../../../../../lib/entities/activityEntryEntity";
import RateCardCurrency from "../../../../modules/Currency/RateCardCurrency";
import SystemMessage from "./SystemMessage";

const JobOverBudget = ({ activityEntry }) => (
  <SystemMessage>
    {"The total logged amount on the job has gone over planned budget of "}
    <RateCardCurrency
      id={getMetaDataRateCardId(activityEntry)}
      value={getMetaDataJobCurrencyTotalPlannedTimeExTax(activityEntry)}
    />
  </SystemMessage>
);

JobOverBudget.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobOverBudget;
