import type {
  TSavedSegment,
  TSavedSegmentTypeEnum,
} from "@streamtimefe/entities";
import { Entity as NEW_Entity } from "@streamtimefe/entities";

import { API } from "../api";
import { WebApiRoot } from ".";

export const SavedSegmentsRoot = WebApiRoot + "/saved_segments";

export function getEntitites(savedSegmentTypes?: TSavedSegmentTypeEnum[]) {
  return API.get<TSavedSegment[]>(SavedSegmentsRoot, {
    saved_segment_type_ids: JSON.stringify(savedSegmentTypes),
  });
}

export function createEntity(savedSegment: TSavedSegment) {
  return API.post<TSavedSegment>(
    SavedSegmentsRoot,
    NEW_Entity.apiSafeTransform(savedSegment)
  );
}

export function saveEntity(savedSegment: TSavedSegment) {
  const apiSafeEntity = NEW_Entity.apiSafeTransform(savedSegment);
  if (apiSafeEntity.id === null) {
    return createEntity(savedSegment);
  }
  return API.put<TSavedSegment>(
    `${SavedSegmentsRoot}/${apiSafeEntity.id}`,
    apiSafeEntity
  );
}

export function getEntity(savedSegmentId: TSavedSegment["id"]) {
  return API.get<TSavedSegment>(`${SavedSegmentsRoot}/${savedSegmentId}`);
}

export function deleteEntity(savedSegmentId: TSavedSegment["id"]) {
  return API.delete<null>(`${SavedSegmentsRoot}/${savedSegmentId}`);
}
