import EventEmitter from "eventemitter3";
import { useEffect } from "react";

import { sharedEmitter } from "./sharedEmitter";

export function useEmitterSubscriber(
  event: string,
  callback: EventEmitter.ListenerFn,
  emitter: EventEmitter = sharedEmitter
) {
  useEffect(() => {
    emitter.on(event, callback);
    return () => {
      emitter.off(event, callback);
    };
  }, []);
}
