import reduceChangedEntities from "../reduceChangedEntities";

const reassignBlockReducer = (state, action) => {
  const {
    blockKey,
    userId,
    jobItemUserId,
    jobItemUserStatusId,
    hasChanged
  } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[blockKey];
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[blockKey];

  if (!prevBaseScheduleLoggedTime) return state;

  if (!hasChanged) return state;

  const prevUserId = prevBaseScheduleLoggedTime.userId;

  if (prevUserId === userId) return state;

  const changedEntities = prevScheduleLoggedTimeIds
    .map(id => state.byId[id])
    .map(entity => ({
      prevEntity: entity,
      newEntity: {
        ...entity,
        userId,
        jobItemUserId,
        jobItemUserStatusId,
        _prevBaseLoggedTimeId: blockKey
      }
    }));

  return reduceChangedEntities(state, changedEntities);
};

export default reassignBlockReducer;
