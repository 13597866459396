import type { TSearchItem, TSearchSection } from "@streamtimefe/utils";
import { useMemo, useState } from "react";
import { useFilter } from "react-aria";

export function useSearchSectionsFilter<T>(sections: TSearchSection<T>[]) {
  const [query, setQuery] = useState("");
  const { contains } = useFilter({ sensitivity: "base" });

  const filteredSections = useMemo(
    () =>
      sections
        .map((section) => ({
          ...section,
          items: section.items.filter((item) => contains(item.search, query)),
        }))
        .filter((section) => section.items.length > 0),
    [sections, query, contains]
  );

  return {
    query,
    setQuery,
    filteredSections,
  };
}

export function useSearchItemsFilter<T>(items: TSearchItem<T>[]) {
  const [query, setQuery] = useState("");
  const { contains } = useFilter({ sensitivity: "base" });

  const filteredItems = useMemo(
    () => items.filter((item) => contains(item.search, query)),
    [items, query, contains]
  );

  return {
    query,
    setQuery,
    filteredItems,
  };
}

export function useSearchItemsFilterControlled<T>(
  items: TSearchItem<T>[],
  query: string
) {
  const { contains } = useFilter({ sensitivity: "base" });

  return useMemo(
    () => items.filter((item) => contains(item.search, query)),
    [items, query, contains]
  );
}
