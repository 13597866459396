import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const ActivityEntryStore = createSingleEntityStoreContext(
  EntityClass.ActivityEntry
);

export const ActivityEntryStoreProvider = createSingleEntityStoreProvider(
  EntityClass.ActivityEntry,
  ActivityEntryStore
);
