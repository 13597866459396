import { produce } from "immer";
import { get, set, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { getOrderId } from "../../../../lib/entities/jobPhaseEntity";

const jobPhaseOrderByJobIdReducer = (state, nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      if (prevEntity) pushOnce(touchedPaths, prevEntity.jobId);
      if (newEntity) pushOnce(touchedPaths, newEntity.jobId);
    });

    if (touchedPaths.length === 0) return;

    touchedPaths.forEach((path) => {
      const jobPhaseIds = get(nextState, `idsByJobId.${path}`, []);

      if (!jobPhaseIds.length) unset(draft, `orderByJobId.${path}`);
      else
        set(
          draft,
          `orderByJobId.${path}`,
          [...jobPhaseIds].sort((jobPhaseId1, jobPhaseId2) => {
            const jobPhase1 = get(nextState.byId, jobPhaseId1);
            const jobPhase2 = get(nextState.byId, jobPhaseId2);
            return getOrderId(jobPhase1) - getOrderId(jobPhase2);
          })
        );
    });
  });

export default jobPhaseOrderByJobIdReducer;
