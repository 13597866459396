import numeral from "numeral";
import styled from "styled-components";

import {
  getTotalLoggedMinutes,
  getTotalUsedScheduledMinutes,
} from "../../../../lib/entities/jobEntity";
import { getTotalIncompleteMinutes } from "../../../../lib/entities/jobItemUserEntity";
import { convertMinutesToTimeHM } from "../../../../lib/time";
import { feToWebViewSchedule } from "../../../../lib/WebAppAPI/fePages/genericWeb";
import { useJob } from "../../../../redux/selectors/job";
import { useJobCalculatedTotalPlannedMinutes } from "../../../../redux/selectors/job/selectJobCalculatedTotalPlannedMinutes";
import { useJobCalculatedUnscheduledMinutes } from "../../../../redux/selectors/job/selectJobCalculatedUnscheduledMinutes";
import { useJumbotronIsUsedTimeOverPlannedTimeHours } from "../../../../redux/selectors/jobDetails/jumbotronIsUsedTimeOverPlannedTimeHours";
import { useJobTotalUnassignedTime } from "../../../../state/entities/job/selectors/selectJobTotalUnassignedTime";
import { useJobItemRolesByJobId } from "../../../../state/entities/jobItemRole/selectors/selectJobItemRolesByJobId";
import { IconButton } from "../../../elements/Button";
import OpenInNewIcon from "../../../elements/Icons/OpenInNewIcon";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import { InlineTextSpan, JumbotronTooltipTable, TooltipTitle } from "../styles";

const JumbotronScheduled = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isScheduledTimeOverPlannedTimeHours =
    useJumbotronIsUsedTimeOverPlannedTimeHours(jobId);

  const unscheduledMinutes = useJobCalculatedUnscheduledMinutes(jobId);

  const calculatedTotalPlannedMinutes =
    useJobCalculatedTotalPlannedMinutes(jobId);

  const hasPlannedTime = calculatedTotalPlannedMinutes > 0;
  const unnassignedTime = useJobTotalUnassignedTime(jobId);
  const jobItemRoleIds = useJobItemRolesByJobId(jobId);

  const progressPercetage = getProgressPercentage(
    job,
    calculatedTotalPlannedMinutes
  );

  const secondaryProgressPercantage = getSecondaryProgressPercentage(
    job,
    calculatedTotalPlannedMinutes,
    unnassignedTime
  );

  return (
    <Tooltip
      title={getScheduledTooltip(
        job,
        calculatedTotalPlannedMinutes,
        unnassignedTime,
        jobItemRoleIds.length > 0,
        unscheduledMinutes
      )}
      placement="right"
    >
      <JumbotronProgressField
        name="SCHEDULED"
        progressColor="var(--color-gray-dark)"
        progressPercentage={progressPercetage}
        secondaryProgressColor="var(--color-gray)"
        secondaryProgressPercentage={secondaryProgressPercantage}
        noOverrun={calculatedTotalPlannedMinutes === 0}
      >
        <Wrapper>
          <StyledIconButton onClick={() => feToWebViewSchedule([jobId])}>
            <OpenInNewIcon size={20} />
          </StyledIconButton>
          <JumbotronProgressFieldValue
            $warning={isScheduledTimeOverPlannedTimeHours}
          >
            {!hasPlannedTime && (
              <InlineTextSpan>
                <span className="bold">
                  {convertMinutesToTimeHM(getTotalUsedScheduledMinutes(job))}
                </span>{" "}
                scheduled
              </InlineTextSpan>
            )}
            {hasPlannedTime && (
              <>
                {!isOverScheduled(job, calculatedTotalPlannedMinutes) && (
                  <InlineTextSpan>
                    <span className="bold">
                      {convertMinutesToTimeHM(unscheduledMinutes)}
                    </span>{" "}
                    unscheduled
                  </InlineTextSpan>
                )}
                {isOverScheduled(job, calculatedTotalPlannedMinutes) && (
                  <InlineTextSpan className="warning">
                    <span className="bold">
                      {convertMinutesToTimeHM(
                        getOverScheduledValue(
                          job,
                          calculatedTotalPlannedMinutes
                        )
                      )}
                    </span>{" "}
                    over
                  </InlineTextSpan>
                )}
              </>
            )}
          </JumbotronProgressFieldValue>
        </Wrapper>
      </JumbotronProgressField>
    </Tooltip>
  );
};

export default JumbotronScheduled;

const getProgressPercentage = (job, calculatedTotalPlannedMinutes) => {
  const totalScheduledMinutes = getTotalUsedScheduledMinutes(job);

  if (totalScheduledMinutes > 0) {
    return Math.round(
      (totalScheduledMinutes / calculatedTotalPlannedMinutes) * 100
    );
  }

  return 0;
};

const getSecondaryProgressPercentage = (
  job,
  calculatedTotalPlannedMinutes,
  unassignedTime
) => {
  const progressPercentage = getProgressPercentage(
    job,
    calculatedTotalPlannedMinutes
  );

  const unscheduledTime = getUnscheduledValue(
    job,
    calculatedTotalPlannedMinutes
  );

  const percentage =
    Math.round((unassignedTime / unscheduledTime) * 100) + progressPercentage;

  return Math.min(percentage, 100);
};

const isOverScheduled = (job, calculatedTotalPlannedMinutes) => {
  return (
    numeral(calculatedTotalPlannedMinutes).value() > 0 &&
    numeral(getTotalUsedScheduledMinutes(job)).value() >
      numeral(calculatedTotalPlannedMinutes).value()
  );
};

const getUnscheduledValue = (job, calculatedTotalPlannedMinutes) => {
  return Math.max(
    0,
    numeral(calculatedTotalPlannedMinutes)
      .subtract(getTotalUsedScheduledMinutes(job))
      .value()
  );
};

const getOverScheduledValue = (job, calculatedTotalPlannedMinutes) => {
  return Math.max(
    0,
    numeral(calculatedTotalPlannedMinutes)
      .subtract(getTotalUsedScheduledMinutes(job))
      .value() * -1
  );
};

const getScheduledTooltip = (
  job,
  calculatedTotalPlannedMinutes,
  unassignedTime,
  hasJobItemRoles,
  unscheduledMinutes
) => (
  <JumbotronTooltipTable>
    <tbody>
      <tr>
        <td>Planned</td>
        <TooltipTitle>
          {convertMinutesToTimeHM(calculatedTotalPlannedMinutes)}
        </TooltipTitle>
      </tr>
      <tr>
        <td>Used</td>
        <TooltipTitle>
          {convertMinutesToTimeHM(getTotalLoggedMinutes(job))}
        </TooltipTitle>
      </tr>
      <tr>
        <td>Scheduled</td>
        <TooltipTitle>
          {convertMinutesToTimeHM(getTotalIncompleteMinutes(job))}
        </TooltipTitle>
      </tr>
      <tr className="divider">
        <td colSpan="3"></td>
      </tr>
      <tr className="dividerBlank">
        <td colSpan="3"></td>
      </tr>
      <tr>
        <td>Unscheduled</td>
        <TooltipTitle>
          {convertMinutesToTimeHM(unscheduledMinutes)}
        </TooltipTitle>
      </tr>
      {(unassignedTime > 0 || hasJobItemRoles) && (
        <tr>
          <td>Unassigned</td>
          <TooltipTitle>{convertMinutesToTimeHM(unassignedTime)}</TooltipTitle>
        </tr>
      )}
    </tbody>
  </JumbotronTooltipTable>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 4px;
  height: 18px;
`;
