import { get } from "lodash-es";
import { createSelector } from "reselect";
import { costingMethodObj } from "st-shared/entities";

import { ENTITY_NAME_ROLE_RATES } from "../../lib/constants";
import { getRateCardId } from "../../lib/entities/jobEntity";
import { getSellRate } from "../../lib/entities/jobItemEntity";
import { getRoleIdRateCardIdKeyPath } from "../../lib/entities/roleRateEntity";
import { getBillableRate, getRoleId } from "../../lib/entities/userEntity";
import { selectPropsRateCardId, selectPropsRoleId } from "./index";
import { selectJobItemById } from "./jobItemSelectors";
import { selectJobByJobItemId } from "./jobSelectors";
import { selectUserById } from "./userSelectors";

export const selectRoleRates = (state) =>
  state.entities[ENTITY_NAME_ROLE_RATES];

export const selectRoleRateByRoleIdRateCardId = createSelector(
  selectRoleRates,
  selectPropsRoleId,
  selectPropsRateCardId,
  (roleRates, roleId, rateCardId) =>
    get(
      roleRates,
      `rateByRoleIdRateCardId.${getRoleIdRateCardIdKeyPath({
        roleId,
        rateCardId,
      })}`
    )
);

export const selectSellRateByUserIdRateCardId = (state, props) => {
  const user = selectUserById(state, { id: props.userId });
  const roleId = getRoleId(user);

  if (roleId)
    return selectRoleRateByRoleIdRateCardId(state, {
      roleId,
      rateCardId: props.rateCardId,
    });

  return getBillableRate(user);
};

export const selectDefaultJobItemUserSellRateByJobItemIdUserId = (
  state,
  { jobItemId, userId }
) => {
  const jobItem = selectJobItemById(state, { id: jobItemId });
  const job = selectJobByJobItemId(state, { id: jobItemId });

  if (costingMethodObj(jobItem.costingMethod).isBasedByUser()) {
    return selectSellRateByUserIdRateCardId(state, {
      userId,
      rateCardId: getRateCardId(job),
    });
  }

  return getSellRate(jobItem);
};
