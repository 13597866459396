import {
  type TValueMatchTypeEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { find, uniq } from "lodash-es";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";

import type { SelectKey } from "../../../components";
import {
  Icon,
  IconButton,
  IconSize,
  MdAdd,
  MdToday,
  Popover,
  PopoverDatePicker,
} from "../../../components";
import { useUniqueKey } from "../../../hooks";
import { DateFormats, getTodayDate } from "../../../lib";
import { useCustomerCurrency } from "../../../stores";
import {
  getConditionMatchName,
  getFilterName,
  getNextCondition,
} from "../../../types";
import { createFilterEvent, useFilterListener } from "..";
import { addButtonCss, boldText, dateEditCss } from "../Filter.css";
import { OperatorButton } from "../OperatorButton";
import type { FilterDisplayTextProps, FilterProps } from "../types";
import { FilterDisplay } from "./FilterDisplay";
import { FilterElementDisplay } from "./FilterElementDisplay";
import { FilterSelect } from "./FilterSelect";

const ValueMatchTypes: {
  id: TValueMatchTypeEnum;
  name: string;
  displayName: string;
}[] = [
  {
    id: ValueMatchTypeEnum.InTheFuture,
    name: "in the future",
    displayName: "in the future",
  },
  {
    id: ValueMatchTypeEnum.InThePast,
    name: "in the past",
    displayName: "in the past",
  },
  { id: ValueMatchTypeEnum.Equals, name: "on", displayName: "" },
  {
    id: ValueMatchTypeEnum.GreaterThanOrEquals,
    name: "on or after",
    displayName: "on or after",
  },
  {
    id: ValueMatchTypeEnum.LessThanOrEquals,
    name: "on or before",
    displayName: "on or before",
  },
  { id: ValueMatchTypeEnum.DateLastWeek, name: "last", displayName: "" },
  { id: ValueMatchTypeEnum.DateThisWeek, name: "this", displayName: "" },
  { id: ValueMatchTypeEnum.DateNextWeek, name: "next", displayName: "" },
];

export const SecondaryDate = {
  Week: 0,
  Month: 1,
  Quarter: 2,
  Year: 3,
} as const;

export type TSecondaryDate = (typeof SecondaryDate)[keyof typeof SecondaryDate];

const SecondDateValueMatchTypes = [
  { id: SecondaryDate.Week, name: "week" },
  { id: SecondaryDate.Month, name: "month" },
  { id: SecondaryDate.Quarter, name: "quarter" },
  { id: SecondaryDate.Year, name: "year" },
];

const SecondDateMap: Partial<
  Record<TValueMatchTypeEnum, TValueMatchTypeEnum[]>
> = {
  [ValueMatchTypeEnum.DateLastWeek]: [
    ValueMatchTypeEnum.DateLastWeek,
    ValueMatchTypeEnum.DateLastMonth,
    ValueMatchTypeEnum.DateLastQuarter,
    ValueMatchTypeEnum.DateLastYear,
  ],
  [ValueMatchTypeEnum.DateThisWeek]: [
    ValueMatchTypeEnum.DateThisWeek,
    ValueMatchTypeEnum.DateThisMonth,
    ValueMatchTypeEnum.DateThisQuarter,
    ValueMatchTypeEnum.DateThisYear,
  ],
  [ValueMatchTypeEnum.DateNextWeek]: [
    ValueMatchTypeEnum.DateNextWeek,
    ValueMatchTypeEnum.DateNextMonth,
    ValueMatchTypeEnum.DateNextQuarter,
    ValueMatchTypeEnum.DateNextYear,
  ],
};

function getFirstValue(valueMatchTypeId: TValueMatchTypeEnum) {
  switch (valueMatchTypeId) {
    case ValueMatchTypeEnum.DateLastWeek:
    case ValueMatchTypeEnum.DateLastMonth:
    case ValueMatchTypeEnum.DateLastQuarter:
    case ValueMatchTypeEnum.DateLastYear:
      return ValueMatchTypeEnum.DateLastWeek;
    case ValueMatchTypeEnum.DateThisWeek:
    case ValueMatchTypeEnum.DateThisMonth:
    case ValueMatchTypeEnum.DateThisQuarter:
    case ValueMatchTypeEnum.DateThisYear:
      return ValueMatchTypeEnum.DateThisWeek;
    case ValueMatchTypeEnum.DateNextWeek:
    case ValueMatchTypeEnum.DateNextMonth:
    case ValueMatchTypeEnum.DateNextQuarter:
    case ValueMatchTypeEnum.DateNextYear:
      return ValueMatchTypeEnum.DateNextWeek;
    default:
      return valueMatchTypeId;
  }
}

function getSecondValue(valueMatchTypeId: TValueMatchTypeEnum) {
  switch (valueMatchTypeId) {
    case ValueMatchTypeEnum.DateLastWeek:
    case ValueMatchTypeEnum.DateThisWeek:
    case ValueMatchTypeEnum.DateNextWeek:
      return SecondaryDate.Week;
    case ValueMatchTypeEnum.DateLastMonth:
    case ValueMatchTypeEnum.DateThisMonth:
    case ValueMatchTypeEnum.DateNextMonth:
      return SecondaryDate.Month;
    case ValueMatchTypeEnum.DateLastQuarter:
    case ValueMatchTypeEnum.DateThisQuarter:
    case ValueMatchTypeEnum.DateNextQuarter:
      return SecondaryDate.Quarter;
    case ValueMatchTypeEnum.DateLastYear:
    case ValueMatchTypeEnum.DateThisYear:
    case ValueMatchTypeEnum.DateNextYear:
      return SecondaryDate.Year;
  }
  return undefined;
}

const ValueMatchTypesHasDateValue: TValueMatchTypeEnum[] = [
  ValueMatchTypeEnum.Equals,
  ValueMatchTypeEnum.GreaterThanOrEquals,
  ValueMatchTypeEnum.LessThanOrEquals,
];

const ValueMatchTypesHasNoValue: TValueMatchTypeEnum[] = [
  ValueMatchTypeEnum.InThePast,
  ValueMatchTypeEnum.InTheFuture,
];

export interface DateFilterProps extends FilterProps {}

export function DateFilter({
  uuid,
  filterGroup,
  onDelete,
  addFilter,
  setFilterGroupConditionMatch,
  setFilter,
  deleteFilters,
}: DateFilterProps) {
  const displayRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { filters, conditionMatchTypeId } = filterGroup;

  useFilterListener(uuid, filterGroup.id, "open", openEditMenu);

  const [dateListenerUuid] = useUniqueKey();

  function openEditMenu() {
    setAnchorEl(displayRef.current);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        addEmptyFilter();
      }
    });
  }

  function addEmptyFilter() {
    addFilter(filterGroup.id, {
      value: getTodayDate(),
      valueMatchTypeId: ValueMatchTypes[0].id,
    });
  }

  function closeEditMenu() {
    setAnchorEl(null);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        onDelete(filterGroup.id);
      }
    });
  }

  function onSetConditionMatch() {
    setFilterGroupConditionMatch(
      filterGroup.id,
      getNextCondition(conditionMatchTypeId)
    );
  }

  function onDateChange(value: string, filterIndex: number) {
    const valueMatchTypeId = filters[filterIndex].valueMatchTypeId;
    setFilter(filterGroup.id, filterIndex, { value, valueMatchTypeId });
  }

  function onSelectChange(
    valueMatchTypeId: TValueMatchTypeEnum,
    filterIndex: number
  ) {
    let value = filters[filterIndex].value;
    if (ValueMatchTypesHasDateValue.includes(valueMatchTypeId)) {
      if (value === "") {
        value = getTodayDate();
      }
      requestAnimationFrame(() => {
        createFilterEvent(dateListenerUuid, filterGroup.id, "openDate");
      });
    } else {
      value = "";
    }
    setFilter(filterGroup.id, filterIndex, { value, valueMatchTypeId });
  }

  return (
    <div>
      <FilterDisplay
        ref={displayRef}
        onClick={openEditMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <DateFilterDisplayText filterGroup={filterGroup} />
      </FilterDisplay>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: -36, horizontal: "center" }}
        PaperProps={{ className: dateEditCss.root }}
      >
        {filters.map((filter, index) => {
          return (
            <Fragment key={index}>
              <FilterElement
                filterGroupId={filterGroup.id}
                uuid={dateListenerUuid}
                value={filter.value as string}
                onDateChange={(value) => onDateChange(value, index)}
                selectValue={filter.valueMatchTypeId}
                selectOnChange={(value) => onSelectChange(value, index)}
                onDelete={
                  filters.length > 1
                    ? () => deleteFilters(filterGroup.id, [index])
                    : null
                }
              />
              {index !== filters.length - 1 && (
                <OperatorButton
                  onClick={onSetConditionMatch}
                  conditionMatchTypeId={conditionMatchTypeId}
                />
              )}
            </Fragment>
          );
        })}
        {
          <IconButton
            className={addButtonCss}
            iconProps={{ icon: MdAdd }}
            onClick={addEmptyFilter}
          />
        }
      </Popover>
    </div>
  );
}

interface FilterElementProps {
  uuid: string;
  filterGroupId: string;
  value: string;
  onDateChange: (date: string) => void;
  selectValue: TValueMatchTypeEnum;
  selectOnChange: (valueMatchTypeId: TValueMatchTypeEnum) => void;
  onDelete?: (() => void) | null;
}

export function FilterElement({
  uuid,
  filterGroupId,
  value,
  onDateChange,
  selectValue,
  selectOnChange,
  onDelete,
}: FilterElementProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  useFilterListener(uuid, filterGroupId, "openDate", openPicker);

  function openPicker() {
    setAnchorEl(ref.current);
  }

  function closePicker() {
    setAnchorEl(null);
  }

  function onChange(date: string) {
    closePicker();
    onDateChange(date);
  }

  const hasDateValue = ValueMatchTypesHasDateValue.includes(selectValue);
  const hasNoValue = ValueMatchTypesHasNoValue.includes(selectValue);
  const [secondValue, setSecondValue] = useState<TSecondaryDate>(
    SecondaryDate.Week
  );

  useEffect(() => {
    const initialSecondValue = getSecondValue(selectValue);
    if (typeof initialSecondValue !== "undefined") {
      setSecondValue(initialSecondValue);
    }
  }, []);

  const firstValue = getFirstValue(selectValue);

  function firstSelectOnChange(value: SelectKey) {
    let valueMatchTypeId = value as TValueMatchTypeEnum;

    if (
      !ValueMatchTypesHasNoValue.includes(valueMatchTypeId) &&
      !ValueMatchTypesHasDateValue.includes(valueMatchTypeId)
    ) {
      valueMatchTypeId =
        SecondDateMap[getFirstValue(valueMatchTypeId)]![secondValue];
    }
    selectOnChange(valueMatchTypeId);
  }

  function secondSelectOnChange(value: SelectKey) {
    const nextSecondValue = value as TSecondaryDate;
    setSecondValue(nextSecondValue);

    const nextValueMatchTypeId: TValueMatchTypeEnum =
      SecondDateMap[firstValue]![nextSecondValue];

    selectOnChange(nextValueMatchTypeId);
  }

  return (
    <>
      <FilterElementDisplay
        onDelete={onDelete}
        className={dateEditCss.filter}
        ref={ref}
      >
        <FilterSelect
          aria-label="Date Filter Selector"
          items={ValueMatchTypes}
          selectedKey={firstValue}
          onSelectionChange={firstSelectOnChange}
        />
        {hasDateValue && (
          <div className={dateEditCss.dateButton} onClick={openPicker}>
            <Icon icon={MdToday} size={IconSize.Medium} />
            <span className={dateEditCss.dateText}>
              {moment(value).format(DateFormats.DateShortMonthYear)}
            </span>
          </div>
        )}
        {!hasDateValue && !hasNoValue && (
          <FilterSelect
            aria-label="Date Filter Selector"
            items={SecondDateValueMatchTypes}
            selectedKey={secondValue}
            onSelectionChange={secondSelectOnChange}
          />
        )}
      </FilterElementDisplay>
      {value && (
        <PopoverDatePicker
          actionButton="today"
          DatePickerProps={{ value, onChange }}
          PopoverProps={{
            open: Boolean(anchorEl),
            anchorEl: anchorEl,
            onClose: closePicker,
            anchorOrigin: {
              vertical: "top",
              horizontal: -10,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
        />
      )}
    </>
  );
}

export function DateFilterDisplayText({ filterGroup }: FilterDisplayTextProps) {
  const customerCurrency = useCustomerCurrency();

  const { filterGroupTypeId, filters, conditionMatchTypeId } = filterGroup;

  function getDisplayText() {
    const filterValues = ValueMatchTypes.filter(({ id }) =>
      ValueMatchTypesHasDateValue.includes(id)
    )
      .map((valueMatchType) => ({
        ...valueMatchType,
        values: uniq(
          filters
            .filter(
              ({ valueMatchTypeId }) => valueMatchTypeId === valueMatchType.id
            )
            .map(({ value }) => value)
        ),
      }))
      .filter(({ values }) => values.length);

    let secondFilterValues: string[] = [];

    filters
      .filter(
        ({ valueMatchTypeId }) =>
          !ValueMatchTypesHasDateValue.includes(valueMatchTypeId) ||
          ValueMatchTypesHasNoValue.includes(valueMatchTypeId)
      )
      .forEach(({ valueMatchTypeId }) => {
        const first =
          find(ValueMatchTypes, {
            id: getFirstValue(valueMatchTypeId),
          })?.name || "";

        if (ValueMatchTypesHasNoValue.includes(valueMatchTypeId))
          secondFilterValues.push(first);
        else {
          const second =
            find(SecondDateValueMatchTypes, {
              id: getSecondValue(valueMatchTypeId),
            })?.name || "";

          secondFilterValues.push(`${first} ${second}`);
        }
      });

    secondFilterValues = uniq(secondFilterValues);

    return (
      <>
        {filterValues.map((filterValue, filterValueIndex) => {
          return (
            <Fragment key={filterValue.name}>
              {" is "}
              {filterValue.displayName}
              {filterValue.values.map((value, index) => {
                return (
                  <Fragment key={`${filterValue.name}${index}`}>
                    {" "}
                    <span className={boldText}>
                      {moment(value as string).format(
                        DateFormats.DateShortMonthYear
                      )}
                    </span>
                    {index !== filterValue.values.length - 1 &&
                      ` ${getConditionMatchName(conditionMatchTypeId)}`}
                  </Fragment>
                );
              })}
              {filterValueIndex !== filterValues.length - 1 &&
                ` ${getConditionMatchName(conditionMatchTypeId)}`}
            </Fragment>
          );
        })}
        {secondFilterValues.map((value, index) => {
          return (
            <Fragment key={value}>
              {index === 0 && (
                <>
                  {filterValues.length > 0 &&
                    ` ${getConditionMatchName(conditionMatchTypeId)}`}
                  {" is "}
                </>
              )}{" "}
              <span className={boldText}>{value}</span>
              {index !== secondFilterValues.length - 1 &&
                ` ${getConditionMatchName(conditionMatchTypeId)}`}
            </Fragment>
          );
        })}
      </>
    );
  }

  return (
    <>
      <span className={boldText}>
        {getFilterName(filterGroupTypeId, customerCurrency)}
      </span>
      {getDisplayText()}
    </>
  );
}
