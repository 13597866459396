import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { activityEntryEntityType } from "../../../lib/entities/activityEntryEntity";
import { selectActivityEntryById } from "../../../redux/selectors/activityEntrySelectors";

const mapState = (state, props) => ({
  activityEntry: selectActivityEntryById(state, props)
});

const ActivityEntryConnector = ({ activityEntry, children }) =>
  children(activityEntry);

ActivityEntryConnector.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  children: PropTypes.func.isRequired
};

export default connect(mapState)(ActivityEntryConnector);
