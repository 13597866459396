import { plate } from "st-shared/external";

import { ElementColumnTrigger, isSelectionInColumnInput } from "../column";
import { FormulaOperators, FormulaPlugin } from "./types";

export const KEY_FORMULA = "formula";

export const formulaEventKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Backspace",
  ".",
  " ",
  ...FormulaOperators,
  ElementColumnTrigger,
];

export const createFormulaPlugin = plate.createPluginFactory<FormulaPlugin>({
  key: KEY_FORMULA,
  handlers: {
    onKeyDown: (editor) => (event) => {
      // set to start if meta + left
      if (event.metaKey && event.key == "ArrowLeft") {
        event.preventDefault();
        plate.setSelection(editor, {
          anchor: editor.start([]),
          focus: editor.start([]),
        });
        return true;
      }

      // set to end if meta + right
      if (event.metaKey && event.key == "ArrowRight") {
        event.preventDefault();
        plate.setSelection(editor, {
          anchor: editor.end([]),
          focus: editor.end([]),
        });
        return true;
      }

      // only add keys that are allowed
      if (
        !isSelectionInColumnInput(editor) &&
        !formulaEventKeys.includes(event.key)
      ) {
        event.preventDefault();
      }

      return;
    },
  },
});
