import { first, has } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const receiveEntityReducer = (state, action) => {
  if (!has(action.payload, ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES))
    return state;

  const newEntity = first(
    action.payload[ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES]
  );

  return {
    ...state,
    ...newEntity
  };
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntityReducer
  }
);
