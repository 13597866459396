import { produce } from "immer";
import { get, isEmpty, isEqual, set, unset } from "lodash-es";

import {
  getIsHeading,
  getOrderId,
} from "../../../../lib/ui/commercialDocumentLineItems";
import { LINE_ITEM_ELEMENTS } from "../../../../lib/ui/lineItemElements";

export default (state, nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    touchedQuoteIds.forEach((quoteId) => {
      const prevQuoteLineItems = get(state.sortedIdsByQuoteId, quoteId, []).map(
        (id) => state.byId[id]
      );
      const quoteLineItems = get(nextState.sortedIdsByQuoteId, quoteId, []).map(
        (id) => nextState.byId[id]
      );

      const prevOrder = prevQuoteLineItems.map(({ id, orderId }) => ({
        id,
        orderId,
      }));
      const nextOrder = quoteLineItems.map(({ id, orderId }) => ({
        id,
        orderId,
      }));
      if (isEqual(prevOrder, nextOrder)) return;

      if (isEmpty(quoteLineItems)) {
        unset(draft, `lineItemElementsByQuoteId.${quoteId}`);
        return;
      }

      const lineItemElements = [];
      let lastHeadingId;
      let lastLineItem;
      let lastAddLine;

      function createSubTotal() {
        if (!lastLineItem) return;

        lastAddLine.className = "subtotal";

        if (getIsHeading(lastLineItem)) return;

        if (lastHeadingId) {
          lineItemElements.push({
            type: LINE_ITEM_ELEMENTS.SUBTOTAL,
            isDraggable: false,
            id: lastHeadingId,
          });
        }
      }

      function createAddLine(className = "") {
        const orderId = lastLineItem ? getOrderId(lastLineItem) + 0.5 : 0.5;

        lastAddLine = {
          type: LINE_ITEM_ELEMENTS.ADD_LINE,
          id: orderId,
          isDraggable: false,
          className,
        };

        lineItemElements.push(lastAddLine);
      }

      function createHeading(quoteLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.HEADING,
          id: quoteLineItem.id,
          isDraggable: true,
          orderId: getOrderId(quoteLineItem),
        });

        lastLineItem = quoteLineItem;
        lastHeadingId = quoteLineItem.id;
      }

      function createItem(quoteLineItem) {
        lineItemElements.push({
          type: LINE_ITEM_ELEMENTS.ITEM,
          id: quoteLineItem.id,
          isDraggable: true,
          orderId: getOrderId(quoteLineItem),
        });

        lastLineItem = quoteLineItem;
      }

      createAddLine();

      quoteLineItems.forEach((quoteLineItem) => {
        if (getIsHeading(quoteLineItem)) {
          createSubTotal();

          createHeading(quoteLineItem);

          createAddLine("heading");

          return;
        }

        createItem(quoteLineItem);

        createAddLine("separator");
      });

      createSubTotal();

      set(draft, `lineItemElementsByQuoteId.${quoteId}`, lineItemElements);
    });
  });
