import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_ARRAY, ENTITY_NAME_TAX_RATES } from "../../lib/constants";
import { NO_TAX_OPTION } from "../../lib/entities/taxRateEntity";
import { ACCOUNTING_PLATFORM_ACCOUNT_TYPES } from "../../lib/ui/accountingPlatform";
import { getAccountCode } from "../../lib/ui/commercialDocumentLineItems";
import {
  selectAccountingPlatformExpenseTaxRateOptions,
  selectAccountingPlatformRevenueTaxRateOptions,
  selectAccountingPlatformStatus
} from "./accountingPlatformSelectors";
import { selectPropsId } from "./index";

const selectTaxRates = state => state.entities[ENTITY_NAME_TAX_RATES];

export const selectTaxRateById = createSelector(
  selectTaxRates,
  selectPropsId,
  (taxRates, id) => get(taxRates, `byId.${id}]`)
);

export const selectStandardTaxRateOptions = createSelector(
  selectTaxRates,
  taxRates => [NO_TAX_OPTION, ...get(taxRates, "options", EMPTY_ARRAY)]
);

export const selectTaxRateOptions = (state, props) => {
  const accountingPlatformStatus = selectAccountingPlatformStatus(state, props);
  const accountCode = getAccountCode(props.documentLineItem);

  if (!accountingPlatformStatus.isActive)
    return selectStandardTaxRateOptions(state, props);

  if (props.accountType === ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES)
    return selectAccountingPlatformExpenseTaxRateOptions(state, {
      accountCode
    });

  return selectAccountingPlatformRevenueTaxRateOptions(state, {
    accountCode
  });
};
