import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsJumbotronSectionTab = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.jumbotronSectionTab
);

export const useJobDetailsJumbotronSectionTab = () =>
  useSelector(selectJobDetailsJumbotronSectionTab);
