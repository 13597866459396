import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  SCHEDULE_BLOCK_SPLIT_CANCEL,
  SCHEDULE_BLOCK_SPLIT_DAYS,
  SCHEDULE_BLOCK_SPLIT_END,
} from "../../../lib/constants";
import {
  getElementClientOffset,
  getElementSize,
  getPointerClientOffset,
} from "../../../lib/dom";
import {
  getTargetBlockElement,
  getTargetBlockKey,
} from "../../../lib/eventTargets";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleUiSplittingBlockKey } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUi } from "../../../state/ui/schedule/types";

const mapState = (state) => ({
  splittingBlockKey: selectScheduleUiSplittingBlockKey(state),
});

const mapDispatch = (dispatch) => ({
  doSetSplitBlockDays: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_SPLIT_DAYS, payload)),
  doSplitBlock: (blockKey, splitDays) =>
    dispatch(createAction(SCHEDULE_BLOCK_SPLIT_END, { blockKey, splitDays })),
  doCancelSplitBlock: () => dispatch(createAction(SCHEDULE_BLOCK_SPLIT_CANCEL)),
});

export default (WrappedComponent) => {
  class BlockSplitHandlers extends React.PureComponent {
    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      splittingBlockKey: entityIdType,
      getDayWidth: PropTypes.func.isRequired,
      doSetSplitBlockDays: PropTypes.func.isRequired,
      doSplitBlock: PropTypes.func.isRequired,
      doCancelSplitBlock: PropTypes.func.isRequired,
      onPointerMove: PropTypes.func,
      onClick: PropTypes.func,
      className: PropTypes.string,
    };

    static defaultProps = {
      onClick: null,
      onPointerMove: null,
      splittingBlockKey: null,
      className: "",
    };

    state = {
      splitDays: null,
    };

    onPointerMove = (e) => {
      const {
        splittingBlockKey,
        getDayWidth,
        doSetSplitBlockDays,
        onPointerMove,
      } = this.props;

      const { splitDays } = this.state;
      const blockKey = getTargetBlockKey(e);
      let newSplitDays = null;

      if (
        blockKey &&
        splittingBlockKey &&
        (splittingBlockKey === blockKey ||
          splittingBlockKey === ScheduleUi.SplittingBlockKeyGlobal)
      ) {
        const blockNode = blockKey && getTargetBlockElement(e);
        const blockPositionX = getElementClientOffset(blockNode).x;
        const blockWidth = getElementSize(blockNode).w;
        const blockDays = Math.round(blockWidth / getDayWidth());
        const pointerPositionX = getPointerClientOffset(e).x;
        const pointerDistance = pointerPositionX - blockPositionX;
        const closestPointerDays = Math.round(pointerDistance / getDayWidth());

        if (closestPointerDays > 0 && closestPointerDays < blockDays)
          newSplitDays = closestPointerDays;
      }

      if (splitDays !== newSplitDays) {
        this.setState({ splitDays: newSplitDays });
        doSetSplitBlockDays(newSplitDays);
      }

      return onPointerMove && onPointerMove(e);
    };

    onClick = (e) => {
      const { splittingBlockKey, onClick, doSplitBlock, doCancelSplitBlock } =
        this.props;
      const { splitDays } = this.state;

      if (splittingBlockKey) {
        const blockKey = getTargetBlockKey(e);

        if (
          blockKey &&
          (splittingBlockKey === blockKey ||
            splittingBlockKey === ScheduleUi.SplittingBlockKeyGlobal) &&
          Number(splitDays) > 0
        )
          doSplitBlock(blockKey, splitDays);
        else doCancelSplitBlock();
      }

      return onClick && onClick(e);
    };

    get isSplitting() {
      const { splittingBlockKey } = this.props;
      return Boolean(splittingBlockKey);
    }

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isSplitting) classes.push("splitting");

      return classes.join(" ");
    }

    get handlers() {
      return {
        onClick: this.onClick,
        onPointerMove: this.onPointerMove,
      };
    }

    render() {
      const { props, className, handlers, isSplitting } = this;

      return (
        <>
          <WrappedComponent
            {...{ ...props, className, isSplitting, ...handlers }}
          />
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockSplitHandlers);
};
