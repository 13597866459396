import type { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import MuiCheckbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

import type { TIconSize } from "..";
import {
  Icon,
  IconSize,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,
} from "..";
import { checkboxCss } from ".";

export interface CheckboxProps extends Omit<MuiCheckboxProps, "size"> {
  variant?: "default" | "primary";
  size?: TIconSize;
}

export function Checkbox({
  className,
  variant = "default",
  size = IconSize.OffSizing22,
  ...props
}: CheckboxProps) {
  return (
    <MuiCheckbox
      className={clsx(checkboxCss[variant], className)}
      disableRipple={true}
      icon={<Icon icon={MdCheckBoxOutlineBlank} size={size} />}
      checkedIcon={<Icon icon={MdCheckBox} size={size} />}
      indeterminateIcon={<Icon icon={MdIndeterminateCheckBox} size={size} />}
      {...props}
    />
  );
}
