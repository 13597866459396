import React from "react";
import { DATE_FORMAT_DATE_MONTH_YEAR } from "../../../../../lib/constants";
import { formatDate } from "../../../../../lib/dates";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataCompletedDatetime
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobCompletedDateUpdated = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" changed the completion date for the job to "}
    <b>
      {formatDate(
        getMetaDataCompletedDatetime(activityEntry),
        DATE_FORMAT_DATE_MONTH_YEAR
      )}
    </b>
  </SystemMessage>
);

JobCompletedDateUpdated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobCompletedDateUpdated;
