import { put, delay, takeLatest } from "redux-saga/effects";
import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* sendDelayedFocus(action) {
  try {
    const { currentFocus, delayTime, callback } = action.payload;

    yield delay(delayTime || 200);

    yield put(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus
      })
    );

    if (callback) callback();
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchDelayedCurrentFocus() {
  yield takeLatest(JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED, sendDelayedFocus);
}
