import { produce } from "immer";
import { KeyEntity } from "st-shared/entities/Entity";

import { ChangedEntity, EntityByKey } from "../entities/types";

function byKeyReducer<T extends KeyEntity>(
  state: EntityByKey<T>,
  changedEntities: ChangedEntity<T>[]
) {
  return produce(state, (draft: EntityByKey<T>) => {
    changedEntities.forEach((changedEntity) => {
      if (changedEntity.prevEntity && !changedEntity.newEntity) {
        delete draft[changedEntity.prevEntity.key];
      }
      if (changedEntity.newEntity) {
        draft[changedEntity.newEntity.key] = changedEntity.newEntity;
      }
    });
  });
}

export default byKeyReducer;
