import { createIcon } from "../../icon/Icon";

export const IconReverseSmall = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 5.69231V3L8.25 6.84615L12 10.6923V7.23077C14.475 7.23077 16.5 9.30769 16.5 11.8462C16.5 14.3846 14.475 16.4615 12 16.4615C9.525 16.4615 7.5 14.3846 7.5 11.8462H6C6 15.2308 8.7 18 12 18C15.3 18 18 15.2308 18 11.8462C18 8.46154 15.3 5.69231 12 5.69231Z"
  />,
  "IconReverseSmall"
);
