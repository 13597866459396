import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsId } from "../../../helpers/selectors";
import { selectStateMasterLoggedExpenses } from "./selectStateMasterLoggedExpenses";

export const selectMasterLoggedExpense = createCachedSelector(
  [selectStateMasterLoggedExpenses, selectPropsId],
  (masterLoggedExpenses, id) => masterLoggedExpenses.byId[id]
)(selectPropsId);

export function useMasterLoggedExpense(id: number) {
  return useSelector((state: AppState) =>
    selectMasterLoggedExpense(state, { id })
  );
}
