import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  reactContentType,
  reactNodesType
} from "../../../lib/types/reactTypes";
import Flex from "../../elements/Flex";

const TooltipContentList = ({ description, contentArray }) => (
  <Content>
    {description && <Description>{description}</Description>}
    {contentArray.map(([heading, ...values]) => (
      <Row key={heading}>
        <Heading>{heading}</Heading>
        {values.map(value => (
          <Value key={value}>{value}</Value>
        ))}
      </Row>
    ))}
  </Content>
);

TooltipContentList.propTypes = {
  description: reactNodesType,
  contentArray: PropTypes.arrayOf(PropTypes.arrayOf(reactContentType))
    .isRequired
};

TooltipContentList.defaultProps = {
  description: null
};

export default TooltipContentList;

const Content = styled(Flex)`
  flex-direction: column;
`;

const Description = styled.div`
  color: white;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const Row = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin: 0;
  }
`;

const Heading = styled.div`
  color: white;
  font-size: 12px;
  em {
    font-style: italic;
  }
`;

const Value = styled.div`
  color: white;
  font-size: 12px;
`;
