import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { BatchActionOperationStore } from "./BatchActionOperation.store";

export const useBatchActionOperationEntity = createUseEntityStoreEntity(
  EntityClass.BatchActionOperation
);

export const getBatchActionOperationEntity = createGetEntityStoreEntity(
  EntityClass.BatchActionOperation
);

export const useBatchActionOperation = createUseSingleEntity(
  BatchActionOperationStore
);

export function useBatchActionOperationStore() {
  return BatchActionOperationStore.useStore();
}

export function useBatchActionOperationActions() {
  return BatchActionOperationStore.useActions();
}
