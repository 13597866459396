import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { getJobCurrencyTotalPlannedExpensesExTax } from "../../../lib/entities/jobEntity";
import { preciseAdd } from "../../../lib/math";
import { selectPropsJobId } from "../index";
import { selectJobCalculatedTotalPlannedTimeExTax } from "./selectJobCalculatedTotalPlannedTimeExTax";
import { selectJob } from "./index";

export const selectJobCalculatedTotalPlannedExTax = createCachedSelector(
  [selectJob, selectJobCalculatedTotalPlannedTimeExTax],
  (job, calculatedTotalPlannedTimeExTax) =>
    preciseAdd(
      calculatedTotalPlannedTimeExTax,
      getJobCurrencyTotalPlannedExpensesExTax(job)
    )
)(selectPropsJobId);

export const useJobCalculatedTotalPlannedExTax = jobId =>
  useSelector(state => selectJobCalculatedTotalPlannedExTax(state, { jobId }));
