import styled from "styled-components";

export const ScheduleGroupUnderline = styled.div.attrs({
  className: "ScheduleGroupUnderline"
})`
  border-bottom: var(--border-thin-bright);
  ${props =>
    props.flex &&
    `
    display: flex;
    align-items: center;
    width: 100%;
  `}
`;
