import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Icon, Tooltip } from "st-shared/components";
import {
  costingMethodObj,
  CostingMethods,
  TCostingMethods,
} from "st-shared/entities";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_COSTING_METHOD,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useIsSavingJobItem } from "../../../redux/selectors/jobItem/ui/isSaving";
import { IconButton } from "../../elements/Button";
import NonBillableIcon from "../../elements/Icons/NonBillableIcon";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { CostingMethodTooltip } from "../components/CostingMethodTooltip";
import { getCostingMethodUiProperties } from "../consts/CostingMethodUiProperties";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { BaseItem } from "../styles";
import { MasterItemValueCalculatedSellVariance } from "./tooltips/MasterItemValueCalculatedSellVariance";
import { ValueUserSellVariance } from "./tooltips/ValueUserSellVariance";

interface Props {
  containerHovered: boolean;
}

export function ItemPricingMethod({ containerHovered }: Props) {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const jobItem = useJobItem(jobItemId);
  const isSaving = useIsSavingJobItem(jobItemId);
  const dispatch = useDispatch();

  const properties = getCostingMethodUiProperties(jobItem.costingMethod.id);

  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const openActionsMenu = () => {
    setMenuAnchorEl(menuRef?.current || null);
  };

  const closeActionsMenu = () => {
    setMenuAnchorEl(null);
  };

  function setCostingMethod(costingMethodId: TCostingMethods) {
    closeActionsMenu();
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_COSTING_METHOD, {
        jobItemId,
        costingMethodId,
        doSetFocus: true,
      })
    );
  }

  return (
    <Container
      $disabled={
        !isJobEditable ||
        !costingMethodObj(jobItem.costingMethod).isBasedByValue()
      }
      $enabledLook={!isJobEditable}
    >
      <Tooltip
        title={
          <CostingMethodTooltip
            title={properties.tooltipTitle}
            text={properties.tooltipText}
          />
        }
        placement="top"
      >
        <div>
          {isJobEditable && (
            <IconButton
              ref={menuRef}
              tabIndex={-1}
              onClick={openActionsMenu}
              disabled={!isJobEditable || isSaving}
            >
              <Icon icon={properties.icon} size={properties.size}></Icon>
            </IconButton>
          )}
          {!isJobEditable && (
            <DisabledIcon>
              <Icon icon={properties.icon} size={properties.size}></Icon>
            </DisabledIcon>
          )}
        </div>
      </Tooltip>
      {!jobItem.isBillable && (
        <Tooltip
          title={
            <CostingMethodTooltip
              title={"This is a non-billable item."}
              text={
                "Figures will not be included in used or planned sell totals."
              }
            />
          }
          placement="top"
        >
          <NonBillableIconWrapper>
            <NonBillableIcon size={ICON_SIZE.LARGE}></NonBillableIcon>
          </NonBillableIconWrapper>
        </Tooltip>
      )}
      <MasterItemValueCalculatedSellVariance
        containerHovered={containerHovered}
      />
      <ValueUserSellVariance containerHovered={containerHovered} />
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeActionsMenu}
        transformOrigin={{
          vertical: -5,
          horizontal: -5,
        }}
      >
        {[
          CostingMethods.Item,
          CostingMethods.People,
          CostingMethods.ValueCalculatedSell,
        ].map((costingMethodId) => {
          const properties = getCostingMethodUiProperties(costingMethodId);
          return (
            <Tooltip
              key={costingMethodId}
              title={
                <CostingMethodTooltip
                  hideTitle
                  title={properties.tooltipTitle}
                  text={properties.tooltipText}
                />
              }
              placement="left"
            >
              <div>
                <StyledMenuItem
                  key={costingMethodId}
                  onClick={() =>
                    setCostingMethod(
                      properties.setCostingMethod(jobItem.costingMethod.id)
                    )
                  }
                  disabled={properties.ids.includes(jobItem.costingMethod.id)}
                >
                  <StyledListItemIcon>
                    <Icon icon={properties.icon} size={properties.size}></Icon>
                  </StyledListItemIcon>
                  <ListItemText>{properties.text}</ListItemText>
                </StyledMenuItem>
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </Container>
  );
}

const Container = styled(BaseItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) =>
    props.$disabled &&
    !props.$enabledLook &&
    `background-color: var(--color-jonsnow);`}
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 12px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`;

const DisabledIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NonBillableIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  svg {
    color: var(--color-gray-dark);
  }
`;
