import { has } from "lodash-es";
import { theme } from "st-shared/theme";

import { convertMinutesToTimeHM } from "../../../lib/time";
import DotPulseIcon from "../../elements/Icons/DotPulseIcon";
import { BranchHeading } from "../UserMenus/BranchHeading";
import CreateNewTeamMember from "../UserMenus/CreateNewTeamMemberFooter";
import UserOption from "../UserMenus/UserOption";
import * as styles from "./UserResults.css";

type Props = {
  results: any[];
  onPickUsers: (userIds: number[]) => void;
  createNewUser?: (userId: number) => void;
  userAvailability: { [key: number]: any };
  userIds?: number[];
  setUserIds?: (userIds: number[]) => void;
  type?: "jobItem" | "jobPhase" | "job";
  onSetUserIds?: (userId: number) => void;
};

export function UserResults({
  results,
  onPickUsers,
  createNewUser,
  userAvailability,
  onSetUserIds,
  userIds,
  type,
}: Props) {
  function addOrRemoveUser(userId: number) {
    if (type === "jobPhase" || type === "job") {
      onSetUserIds?.(userId);
    } else {
      onPickUsers([userId]);
    }
  }

  const userIdsSet = new Set(userIds);

  return (
    <>
      {results.map((result: any) => {
        if (result.isHeading) {
          return (
            <BranchHeading
              key={result.key}
              title={result.value}
            ></BranchHeading>
          );
        }

        const isSelected = userIdsSet.has(result.key);

        return (
          <UserOption
            className={styles.userOption}
            key={result.key}
            userId={result.key}
            onPickUser={addOrRemoveUser}
            isSelected={isSelected}
            style={{
              backgroundColor: isSelected ? theme.color.graylight : "",
            }}
          >
            {has(userAvailability, result.key) && (
              <div
                className={styles.availableHours}
                style={{
                  fontWeight: isSelected ? 700 : "normal",
                }}
              >
                {convertMinutesToTimeHM(
                  userAvailability[result.key],
                  false,
                  "h"
                )}
              </div>
            )}

            {!has(userAvailability, result.key) && (
              <div className={styles.loader}>
                <DotPulseIcon />
              </div>
            )}
          </UserOption>
        );
      })}
      {createNewUser && (
        <CreateNewTeamMember
          onClick={createNewUser}
          hasResults={Boolean(results.length)}
        />
      )}
    </>
  );
}
