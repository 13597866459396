import isError from "lodash-es/isError";
import { put, select, call } from "redux-saga/effects";
import { updateJobPhaseStatusAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  JOB_PHASE_STATUS_CHANGE
} from "../../../lib/constants";
import { isComplete } from "../../../lib/entities/jobItemEntity";
import { isJobItemUserComplete } from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";
import { selectJobItemIdsByJobPhaseId } from "../../selectors/jobItem/selectJobItemIdsByJobPhaseId";
import { selectJobItemUsersByJobItemId } from "../../selectors/jobItemUserSelectors";

function* changeJobPhaseStatus(action) {
  const { jobPhaseId, jobItemStatus, jobItemUserStatus } = action.payload;

  const previousJobItems = [];
  const previousJobItemUsers = [];

  const jobItemIds = yield select(selectJobItemIdsByJobPhaseId, { jobPhaseId });

  for (let i = 0; i < jobItemIds.length; i += 1) {
    const jobItemId = jobItemIds[i];
    const jobItem = yield select(selectJobItem, { jobItemId });

    if (!isComplete(jobItem)) {
      previousJobItems.push(jobItem);

      const jobItemUsers = yield select(selectJobItemUsersByJobItemId, {
        id: jobItemId
      });

      for (let j = 0; j < jobItemUsers.length; j += 1) {
        const jobItemUser = jobItemUsers[j];

        if (!isJobItemUserComplete(jobItemUser)) {
          previousJobItemUsers.push(jobItemUser);
        }
      }
    }
  }

  const updatedJobItems = previousJobItems.map(jobItem => ({
    ...jobItem,
    jobItemStatus
  }));
  const updatedJobItemUsers = previousJobItemUsers.map(jobItemUser => ({
    ...jobItemUser,
    jobItemUserStatus
  }));

  yield put(
    createAction(ENTITIES_RECEIVED, {
      [ENTITY_NAME_JOB_ITEMS]: updatedJobItems,
      [ENTITY_NAME_JOB_ITEM_USERS]: updatedJobItemUsers
    })
  );

  try {
    const { data } = yield call(
      updateJobPhaseStatusAPI,
      jobPhaseId,
      jobItemStatus.id
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEMS]: previousJobItems,
        [ENTITY_NAME_JOB_ITEM_USERS]: previousJobItemUsers
      })
    );

    if (isError(error)) sagaError(error);
  }
}

export default function* watchJobPhaseStatusChange() {
  yield takeLatestBy(
    JOB_PHASE_STATUS_CHANGE,
    changeJobPhaseStatus,
    action => action.payload.jobPhaseId
  );
}
