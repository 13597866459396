import { produce } from "immer";
import { put, select } from "redux-saga/effects";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP,
  JOB_ITEM_SET_IS_BILLABLE,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";
import { saveJobItemImmediate } from "./saveJobItemImmediate";

function* setIsBillable(action) {
  try {
    const { jobItemId, value } = action.payload;

    const jobItem = yield select(selectJobItem, { jobItemId });

    const newJobItem = produce(jobItem, (draft) => {
      draft.isBillable = value;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );

    if (jobItemId > 0) {
      yield saveJobItemImmediate(newJobItem);
      yield put(
        createAction(JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP, {
          jobItemId,
        })
      );
    } else {
      yield put(
        createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
          currentFocus: { jobItemId, key: FOCUS_KEYS.ITEM_GENERAL },
        })
      );
    }

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP, {
        jobItemId,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSetIsBillable() {
  yield takeLatestBy(
    JOB_ITEM_SET_IS_BILLABLE,
    setIsBillable,
    (action) => action.payload.jobItemId
  );
}
