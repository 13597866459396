import {
  getLocalStorageAuthToken,
  setLocalStorageAuthToken,
} from "@streamtimefe/utils";
import { keys, set } from "lodash-es";

import { getUserPreference, setUserPreference } from "..";

type ObjectFunctionMap = { [key: string]: ObjectFunctionMap | (() => unknown) };
type SnapshotMap = { [key: string]: SnapshotMap | unknown };

declare global {
  interface Window {
    Streamtime: {
      stores: ObjectFunctionMap;
      storesSnapshot: SnapshotMap;
      getUserPreference: typeof getUserPreference;
      setUserPreference: typeof setUserPreference;
      setLocalStorageAuthToken: typeof setLocalStorageAuthToken;
      getLocalStorageAuthToken: typeof getLocalStorageAuthToken;
    };
  }
}

const stores: Record<string, () => unknown> = {};

window.Streamtime = {
  stores,
  get storesSnapshot() {
    return getFunctionMapSnapshot(stores);
  },
  getUserPreference: getUserPreference,
  setUserPreference,
  setLocalStorageAuthToken,
  getLocalStorageAuthToken,
};

if (window.top && window !== window.top) {
  try {
    window.top.Streamtime = window.Streamtime;
  } catch (e) {
    // cors error
  }
}

export function addGlobalStore(
  path: string | string[],
  getStore: () => unknown
) {
  set(window.Streamtime.stores, path, getStore);
}

export function getFunctionMapSnapshot(object: ObjectFunctionMap): SnapshotMap {
  const snapshot: SnapshotMap = {};
  keys(object).map((key) => {
    const x = object[key];
    if (typeof x === "function") {
      snapshot[key] = x();
    } else {
      snapshot[key] = getFunctionMapSnapshot(x);
    }
  });
  return snapshot;
}
