import moment from "moment";
import numeral from "numeral";
import * as PropTypes from "prop-types";
import {
  CostingMethods,
  createTimeAllocationMethod,
  TimeAllocationMethods,
} from "st-shared/entities";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { asString } from "st-shared/lib";
import { StatusType } from "st-shared/types";

import {
  EMPTY_ARRAY,
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_DELETED,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../constants";
import {
  addDays,
  getFormattedDateRange,
  getTodayDate,
  isBefore,
  maxDate,
  minDate,
  subDays,
} from "../dates";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import {
  getJobItemEstimatedEndDate,
  getJobItemEstimatedStartDate,
} from "./scheduleLoggedTimeEntity";

export const jobItemEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  earliestStartDate: PropTypes.string,
  estimatedEndDate: PropTypes.string,
  estimatedStartDate: PropTypes.string,
  masterJobItemId: entityIdType,
  name: PropTypes.string,
  description: PropTypes.string,
  jobCurrencySelLRate: entityFieldDecimalType,
  isBillable: PropTypes.bool,
  jobId: entityIdType.isRequired,
  jobItemStatus: typeEntityType.isRequired,
  jobPhaseId: entityIdType,
  latestEndDate: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  totalIncompleteMinutes: entityFieldDecimalType,
  totalLoggedMinutes: entityFieldDecimalType,
  totalPlannedMinutes: entityFieldDecimalType,
  jobCurrencyTotalIncompleteTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalPlannedTimeExTax: entityFieldDecimalType,
  jobCurrencyTotalLoggedTimeCostExTax: entityFieldDecimalType,
  timeAllocationMethod: typeEntityType.isRequired,
  costingMethod: typeEntityType.isRequired,
});

export const getJobId = (entity) => entity.jobId;

export const getMasterJobItemId = (entity) => entity.masterJobItemId;

export const getJobItemName = (entity) => entity.name;

export const getJobItemDescription = (entity) => entity.description;

export const getJobPhaseId = ({ jobPhaseId }) => jobPhaseId;

export const getOrderId = ({ orderId }) => orderId;

export const getTotalUsedScheduledMinutes = ({
  totalIncompleteMinutes,
  totalLoggedMinutes,
}) =>
  numeral(asDecimal(totalIncompleteMinutes))
    .add(asDecimal(totalLoggedMinutes))
    .value();

export const getTotalScheduledMinutes = ({ totalIncompleteMinutes }) =>
  asDecimal(totalIncompleteMinutes);

export const getTotalUsedMinutes = ({ totalLoggedMinutes }) =>
  asDecimal(totalLoggedMinutes);

export const getTotalPlannedMinutes = ({ totalPlannedMinutes }) =>
  asDecimal(totalPlannedMinutes);

export const getTotalRemainingMinutes = ({
  totalLoggedMinutes,
  totalPlannedMinutes,
}) =>
  numeral(asDecimal(totalPlannedMinutes))
    .subtract(asDecimal(totalLoggedMinutes))
    .value();

export const getTotalUsedScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalIncompleteTimeExTax))
    .add(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const getTotalScheduledMoney = ({
  jobCurrencyTotalIncompleteTimeExTax,
}) => asDecimal(jobCurrencyTotalIncompleteTimeExTax);

export const getTotalUsedMoney = ({ jobCurrencyTotalLoggedTimeExTax }) =>
  asDecimal(jobCurrencyTotalLoggedTimeExTax);

export const getTotalUsedCostMoney = ({
  jobCurrencyTotalLoggedTimeCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedTimeCostExTax);

export const getTotalPlannedMoney = ({ jobCurrencyTotalPlannedTimeExTax }) =>
  asDecimal(jobCurrencyTotalPlannedTimeExTax);

export const getTotalInvoicedMoney = ({
  jobCurrencyTotalAmountInvoicedExTax,
}) => asDecimal(jobCurrencyTotalAmountInvoicedExTax);

export const getTotalInvoicedRemainingMoney = (jobItem) =>
  Math.max(
    numeral(getTotalRemainingMoney(jobItem))
      .subtract(asDecimal(jobItem.jobCurrencyTotalAmountInvoicedExTax))
      .value(),
    0
  );

export const getTotalRemainingMoney = ({
  jobCurrencyTotalPlannedTimeExTax,
  jobCurrencyTotalLoggedTimeExTax,
}) =>
  numeral(asDecimal(jobCurrencyTotalPlannedTimeExTax))
    .subtract(asDecimal(jobCurrencyTotalLoggedTimeExTax))
    .value();

export const getFormattedTotalPlannedMoney = (value) => {
  value = asDecimal(value);
  if (numeral(value).value() < 0) {
    value *= -1;
  }
  return numeral(value).format(NUMBER_FORMAT_TWO_DECIMALS);
};

export const getJobPhaseGroupKey = (entity) => entity.jobPhaseId || 0;

export const getJobIdPhaseIdPath = (entity) =>
  `["${entity.jobId}.${getJobPhaseGroupKey(entity)}"]`;

export const isScheduled = ({ jobItemStatus }) =>
  jobItemStatus.id === JOB_ITEM_STATUS_ID_SCHEDULED;

export const isDeleted = ({ jobItemStatus }) =>
  jobItemStatus.id === JOB_ITEM_STATUS_ID_DELETED;

export const isPlanning = ({ jobItemStatus }) =>
  jobItemStatus.id === JOB_ITEM_STATUS_ID_PLANNING;

export const isComplete = ({ jobItemStatus }) =>
  jobItemStatus.id === JOB_ITEM_STATUS_ID_COMPLETE;

export const getJobItemStatusName = (jobItemStatusId) => {
  switch (jobItemStatusId) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return "Paused";
    case JOB_ITEM_STATUS_ID_COMPLETE:
      return "Complete";
    case JOB_ITEM_STATUS_ID_DELETED:
      return "Deleted";
    case JOB_ITEM_STATUS_ID_SCHEDULED:
      return "In Play";
    default:
      return null;
  }
};

export const createJobItemStatusType = (id) => {
  switch (id) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return { id, name: "Paused" };
    case JOB_ITEM_STATUS_ID_COMPLETE:
      return { id, name: "Complete" };
    case JOB_ITEM_STATUS_ID_SCHEDULED:
      return { id, name: "In Play" };
    case JOB_ITEM_STATUS_ID_DELETED:
      return { id, name: "Deleted" };
    default:
      return { id };
  }
};

export const getStatusType = (jobItemStatusId) => {
  switch (jobItemStatusId) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return StatusType.Planning;
    case JOB_ITEM_STATUS_ID_COMPLETE:
      return StatusType.Complete;
    case JOB_ITEM_STATUS_ID_SCHEDULED:
      return StatusType.Scheduled;
    default:
      return null;
  }
};

export const getJobItemStatusId = ({ jobItemStatus }) => jobItemStatus.id;

export const getJobItemNormalizedStatus = ({ jobItemStatus }) => {
  switch (jobItemStatus.id) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return "planning";
    case JOB_ITEM_STATUS_ID_COMPLETE:
      return "complete";
    case JOB_ITEM_STATUS_ID_SCHEDULED:
      return "scheduled";
    default:
      return "";
  }
};

export const getJobItemStatusIconClass = (jobItemStatusId) => {
  switch (jobItemStatusId) {
    case JOB_ITEM_STATUS_ID_PLANNING:
      return "planning";
    case JOB_ITEM_STATUS_ID_COMPLETE:
      return "complete";
    case JOB_ITEM_STATUS_ID_SCHEDULED:
      return "scheduled";
    default:
      return "";
  }
};

export const getSellRateNullable = ({ jobCurrencySellRate }) =>
  jobCurrencySellRate !== null ? asDecimal(jobCurrencySellRate) : null;

export const hasSellRate = ({ jobCurrencySellRate }) =>
  jobCurrencySellRate !== null;

export const getSellRate = ({ jobCurrencySellRate }) =>
  asDecimal(jobCurrencySellRate);

export const isOverdue = (jobItem, dueDate = jobItem.estimatedEndDate) =>
  isScheduled(jobItem) && dueDate && isBefore(dueDate, getTodayDate());

export const getEstimatedStartDate = ({ estimatedStartDate }) =>
  estimatedStartDate;

export const getEstimatedEndDate = ({ estimatedEndDate }) => estimatedEndDate;

export const getTimelineStartDate = ({
  estimatedStartDate,
  estimatedEndDate,
}) => estimatedStartDate || estimatedEndDate;

export const getTimelineEndDate = ({ estimatedStartDate, estimatedEndDate }) =>
  estimatedEndDate || estimatedStartDate;

export const getScheduleBarStartDate = ({
  earliestStartDate,
  estimatedStartDate,
  estimatedEndDate,
}) =>
  earliestStartDate && estimatedStartDate
    ? minDate([earliestStartDate, estimatedStartDate])
    : minDate([earliestStartDate, estimatedEndDate]);

export const getScheduleBarEndDate = ({
  latestEndDate,
  estimatedStartDate,
  estimatedEndDate,
}) =>
  latestEndDate && estimatedEndDate
    ? maxDate([latestEndDate, estimatedEndDate])
    : maxDate([latestEndDate, estimatedStartDate]);

export const moveDays = (entity, days) => {
  const estimatedStartDate =
    entity.estimatedStartDate && addDays(entity.estimatedStartDate, days);
  const estimatedEndDate =
    entity.estimatedEndDate && addDays(entity.estimatedEndDate, days);

  return {
    ...entity,
    estimatedStartDate,
    estimatedEndDate,
  };
};

export const resizeLeftDays = (entity, days) => {
  const estimatedEndDate = entity.estimatedEndDate || getTodayDate();
  return {
    ...entity,
    estimatedStartDate: minDate([
      estimatedEndDate,
      subDays(estimatedEndDate, days - 1),
    ]),
    estimatedEndDate,
  };
};

export const resizeRightDays = (entity, days) => {
  const estimatedStartDate = entity.estimatedStartDate || getTodayDate();
  return {
    ...entity,
    estimatedStartDate,
    estimatedEndDate: maxDate([
      estimatedStartDate,
      addDays(estimatedStartDate, days - 1),
    ]),
  };
};

export const getFormattedJobItemDateRange = (
  jobItem,
  format,
  emptyState = ""
) => {
  const { estimatedStartDate: startDate, estimatedEndDate: endDate } = jobItem;
  return getFormattedDateRange(startDate, endDate, format, emptyState);
};

export const mergeScheduleLoggedTimeWithJobItem = (
  scheduleLoggedTime,
  jobItem
) => ({
  ...scheduleLoggedTime,
  jobItemName: getJobItemName(jobItem),
  jobItemEstimatedStartDate: getJobItemEstimatedStartDate(jobItem),
  jobItemEstimatedEndDate: getJobItemEstimatedEndDate(jobItem),
  jobPhaseId: getJobPhaseId(jobItem),
});

export const getSearchString = (entity) => asString(getJobItemName(entity));

export const getAsJobItemOption = (entity, invoiceType) => {
  let rate = getTotalPlannedMoney(entity);
  if (invoiceType === "invoice") {
    rate = getTotalInvoicedRemainingMoney(entity);
  } else if (invoiceType === "credit") {
    rate = getTotalInvoicedMoney(entity);
  }
  return {
    key: entity.id,
    value: getJobItemName(entity),
    optionType: LineItemOptionTypes.JobItem,
    rate: rate,
    isBillable: entity.isBillable,
    searchString: getSearchString(entity),
  };
};

export const createNewJobItem = ({
  id = null,
  jobId,
  jobPhaseId,
  orderId,
  jobItemStatusId,
  timeAllocationMethodId = TimeAllocationMethods.Item,
  costingMethodId = CostingMethods.Item,
  isBillable = true,
}) => ({
  id,
  jobId,
  jobPhaseId,
  orderId,
  isBillable,
  jobItemStatus: { id: jobItemStatusId },
  masterJobItemId: null,
  name: "",
  description: "",
  jobCurrencySellRate: null,
  earliestStartDate: null,
  estimatedEndDate: null,
  estimatedStartDate: null,
  latestEndDate: null,
  totalIncompleteMinutes: 0,
  totalLoggedMinutes: 0,
  totalPlannedMinutes: 0,
  jobCurrencyTotalIncompleteTimeExTax: 0,
  jobCurrencyTotalLoggedTimeExTax: 0,
  jobCurrencyTotalPlannedTimeExTax: 0,
  jobCurrencyTotalLoggedTimeCostExTax: 0,
  timeAllocationMethod: createTimeAllocationMethod(timeAllocationMethodId),
  costingMethod: { id: costingMethodId },
});

export const getJobCurrencyTotalLoggedTimeCostExTax = ({
  jobCurrencyTotalLoggedTimeCostExTax,
}) => asDecimal(jobCurrencyTotalLoggedTimeCostExTax);

export function getUpcomingDates(entity) {
  const upcomingDates = [];
  const isSameDay = moment(entity.estimatedStartDate).isSame(
    moment(entity.estimatedEndDate),
    "day"
  );

  if (entity.estimatedStartDate !== null) {
    upcomingDates.push({
      id: `item-${entity.id}-starts`,
      entityId: entity.id,
      type: "item",
      name: entity.name,
      secondary: isSameDay
        ? ""
        : moment(entity.estimatedStartDate).isBefore(moment())
          ? "started"
          : "starts",
      date: entity.estimatedStartDate,
    });
  }

  if (entity.estimatedEndDate !== null && !isSameDay) {
    upcomingDates.push({
      id: `item-${entity.id}-ends`,
      entityId: entity.id,
      type: "item",
      name: entity.name,
      secondary: moment(entity.estimatedEndDate).isBefore(moment())
        ? "ended"
        : "ends",
      date: entity.estimatedEndDate,
    });
  }

  return upcomingDates.length > 0 ? upcomingDates : EMPTY_ARRAY;
}
