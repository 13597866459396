import { find } from "lodash-es";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Select, SelectItem, SelectKey } from "st-shared/components";
import { usePermissions } from "st-shared/stores";

import {
  JOB_DETAILS_SET_BURN_UP_MODE,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { JOB_BURN_UP_MODE } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsBurnUpMode } from "../../../redux/selectors/jobDetails/ui/burnUpMode";
import { useUserPreferenceJobPageBurnUpMode } from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { container, customSelectCss } from "./BurnUpModeSelector.css";

export function BurnUpModeSelector() {
  const burnUpMode = useJobDetailsBurnUpMode();
  const userPreferenceBurnUpMode = useUserPreferenceJobPageBurnUpMode();
  const { canAccessCostRates, canViewJobFinancials } = usePermissions();
  const dispatch = useDispatch();

  function onSelectionChange(value: SelectKey) {
    const mode = Number(value);
    if (mode === burnUpMode) return;
    dispatch(createAction(JOB_DETAILS_SET_BURN_UP_MODE, mode));
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_BURN_UP_MODE,
        value: mode,
      })
    );
  }

  const burnUpTypes: { id: number; name: string }[] = [
    {
      id: JOB_BURN_UP_MODE.USED_PLANNED_HOURS,
      name: "Used / Planned (H)",
    },
  ];

  if (canViewJobFinancials) {
    burnUpTypes.push({
      id: JOB_BURN_UP_MODE.USED_PLANNED_SELL,
      name: "Used / Planned ($)",
    });
  }

  if (canAccessCostRates) {
    burnUpTypes.push({
      id: JOB_BURN_UP_MODE.USED_PLANNED_COST,
      name: "Used Cost / Planned Sell ($)",
    });
  }

  useEffect(() => {
    if (
      burnUpMode !== userPreferenceBurnUpMode &&
      find(burnUpTypes, { id: userPreferenceBurnUpMode })
    ) {
      dispatch(
        createAction(JOB_DETAILS_SET_BURN_UP_MODE, userPreferenceBurnUpMode)
      );
    }
  }, []);

  if (burnUpTypes.length === 1) return null;

  return (
    <div className={container}>
      <Select
        aria-label="Burn up mode Selector"
        items={burnUpTypes}
        selectedKey={burnUpMode}
        onSelectionChange={onSelectionChange}
        buttonProps={{ className: customSelectCss }}
      >
        {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
      </Select>
    </div>
  );
}
