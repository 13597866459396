import { call, put, select, takeLatest } from "redux-saga/effects";
import { sortQuoteLineItemsAPI } from "../../../lib/API/quoteLineItemAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  LINE_ITEMS_SORT_CONFIRM,
  QUOTE_LINE_ITEM_SORT_CONFIRM,
  QUOTE_LINE_ITEM_SORT_SAVE_ERROR,
  QUOTE_LINE_ITEM_SORT_SAVED,
  QUOTE_LINE_ITEM_SORT_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectSortedQuoteLineItemIdsByQuoteId } from "../../selectors/quoteLineItemSelectors";
import { selectQuoteUi } from "../../selectors/quoteSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* confirmSortLineItems(action) {
  const { quoteId, sortModeSnapshotId: snapshotId } = yield select(
    selectQuoteUi
  );

  if (!quoteId) return;

  const snapshot = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  try {
    yield put(
      createAction(QUOTE_LINE_ITEM_SORT_CONFIRM, {
        quoteId,
        snapshotId
      })
    );

    yield put(
      createAction(QUOTE_LINE_ITEM_SORT_SAVING, {
        id: quoteId
      })
    );

    const quoteLineItemIds = yield select(
      selectSortedQuoteLineItemIdsByQuoteId,
      { quoteId }
    );

    const data = yield call(sortQuoteLineItemsAPI, quoteId, quoteLineItemIds);

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(QUOTE_LINE_ITEM_SORT_SAVED, {
        id: quoteId
      })
    );

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_LINE_ITEM_SORT_SAVE_ERROR, {
        id: quoteId,
        quoteId,
        snapshot
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsSortConfirm() {
  yield takeLatest(LINE_ITEMS_SORT_CONFIRM, confirmSortLineItems);
}
