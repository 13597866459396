import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobItemStore = createSingleEntityStoreContext(EntityClass.JobItem);

export const JobItemStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobItem,
  JobItemStore
);
