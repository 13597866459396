import { produce } from "immer";
import { select, call, put } from "redux-saga/effects";
import { fetchCompanyApi } from "../../../lib/API/companyApi";
import {
  COMPANY_CONTACTS_FETCH_REQUEST,
  ENTITIES_RECEIVED,
  JOB_SAVE,
  JOB_SET_COMPANY
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectCompany } from "../../selectors/company";
import { selectJob } from "../../selectors/job";

function* setCompany(action) {
  try {
    const { jobId, companyId } = action.payload;

    const job = yield select(selectJob, { jobId });

    if (job.company && job.company.id === companyId) return;

    let company = yield select(selectCompany, { companyId });

    if (company === undefined) {
      const data = yield call(fetchCompanyApi, companyId);

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));

      company = yield select(selectCompany, { companyId });
    }

    const newJob = produce(job, draft => {
      draft.company = company;
      draft.contact = null;
    });

    yield put(
      createAction(JOB_SAVE, {
        jobId,
        job: newJob,
        previousJob: job
      })
    );

    yield put(createAction(COMPANY_CONTACTS_FETCH_REQUEST, { id: companyId }));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetCompany() {
  yield takeLatestBy(
    [JOB_SET_COMPANY],
    setCompany,
    action => action.payload.jobId
  );
}
