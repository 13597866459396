import { useDispatch } from "react-redux";
import { IconButton, MdDelete } from "st-shared/components";
import { usePermissions } from "st-shared/stores";

import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { actionJobItemRoleDelete } from "../../../state/entities/jobItemRole/actions";
import { useJobId } from "../context/JobIdContext";
import { JobItemRoleIdContext } from "../context/JobItemRoleIdContext";
import { JobItemRoleHours } from "./JobItemRoleHours";
import { JobItemRoleName } from "./JobItemRoleName";
import { JobItemRoleRate } from "./JobItemRoleRate";
import * as styles from "./JobItemRoleRow.css";
import { JobItemRoleTotal } from "./JobItemRoleTotal";

type Props = {
  jobItemRoleId: number;
};

export function JobItemRoleRow({ jobItemRoleId }: Props) {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const { canViewJobFinancials } = usePermissions();

  const dispatch = useDispatch();

  function handleDelete() {
    if (!isJobEditable) return;
    dispatch(actionJobItemRoleDelete(jobItemRoleId, "jobItem"));
  }

  return (
    <JobItemRoleIdContext.Provider value={jobItemRoleId}>
      <div className={styles.container}>
        <JobItemRoleName />
        <JobItemRoleHours />
        {canViewJobFinancials && (
          <>
            <JobItemRoleRate />
            <div className={styles.totalAndStatus}>
              <JobItemRoleTotal />
              <div className={styles.statusSpacer} />
            </div>
          </>
        )}
        {!canViewJobFinancials && <div className={styles.statusSpacer} />}
        {isJobEditable && (
          <IconButton
            className={styles.icon}
            tabIndex={-1}
            onClick={handleDelete}
            iconProps={{ icon: MdDelete }}
          />
        )}
      </div>
    </JobItemRoleIdContext.Provider>
  );
}
