import { SavedSegmentTypeEnum } from "@streamtimefe/entities";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { savedSegmentTemplateEntityStore } from "st-shared/stores";

import { reportingStore } from "../state/stores/reportingStore";

export function openReportingTemplatesModal() {
  const templates = savedSegmentTemplateEntityStore().helpers.getEntitiesByType(
    SavedSegmentTypeEnum.Reporting
  );

  getModalBridgeClient()?.openModal(
    ModalTypes.SavedSegmentTemplates,
    {
      title: "Reporting",
      templates,
      defaultAction: "Blank Report",
    },
    {
      success: (_, { template }) => {
        if (template) {
          reportingStore().actions.saveTemplateSegment(template);
        } else {
          reportingStore().actions.startDefaultSegment();
        }
      },
    }
  );
}
