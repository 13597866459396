import { UserPreference } from "@streamtimefe/entities";
import { union, without } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  POINTER_CLICK_OFFSET_LIMIT,
  POINTER_CLICK_TIME_LIMIT_MS,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { getPointerClientOffset, subtractCoords } from "../../../lib/dom";
import { getTargetUserAvailabilityId } from "../../../lib/eventTargets";
import { entityIdListType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleVisibleUserIds } from "../../../redux/selectors/scheduleSelectors";
import { selectScheduleCollapsedUserIds } from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";

const mapState = (state) => ({
  visibleUserIds: selectScheduleVisibleUserIds(state),
  collapsedUserIds: selectScheduleCollapsedUserIds(state),
});

const mapDispatch = (dispatch) => ({
  doSetCollapsedUsersPreference: (list) =>
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_COLLAPSED_USERS,
        value: UserPreference.joinListValue(list),
      })
    ),
});

export default (WrappedComponent) => {
  class AvailabilityClickHandlers extends React.PureComponent {
    clickItem = null;

    static propTypes = {
      visibleUserIds: entityIdListType.isRequired,
      collapsedUserIds: entityIdListType.isRequired,
      doSetCollapsedUsersPreference: PropTypes.func.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      onClick: PropTypes.func,
      onPointerDown: PropTypes.func,
    };

    static defaultProps = {
      onClick: null,
      onPointerDown: null,
    };

    onToggleCollapseUser = (e) => {
      const { userId } = this.clickItem;
      const {
        visibleUserIds,
        collapsedUserIds,
        doSetCollapsedUsersPreference,
      } = this.props;

      const userIds = e.altKey ? visibleUserIds : [String(userId)];

      if (collapsedUserIds.includes(String(userId))) {
        doSetCollapsedUsersPreference(without(collapsedUserIds, ...userIds));
      } else {
        doSetCollapsedUsersPreference(union(collapsedUserIds, userIds));
      }
    };

    onClick = (e) => {
      const { onClick } = this.props;

      if (!this.shouldCancelClick(e)) this.onToggleCollapseUser(e);

      this.clickItem = null;

      return onClick && onClick(e);
    };

    onPointerDown = (e) => {
      const { isSplitting, onPointerDown } = this.props;

      if (!isSplitting) {
        const userId = getTargetUserAvailabilityId(e);
        if (userId)
          this.clickItem = {
            userId,
            origin: {
              pointerOffset: getPointerClientOffset(e),
              startTime: Date.now(),
            },
          };
      }

      return onPointerDown && onPointerDown(e);
    };

    get handlers() {
      return {
        onClick: this.onClick,
        onPointerDown: this.onPointerDown,
      };
    }

    shouldCancelClick(e) {
      const { clickItem } = this;

      if (!clickItem) return true;

      const deltaT = Date.now() - clickItem.origin.startTime;
      if (deltaT >= POINTER_CLICK_TIME_LIMIT_MS) return true;

      const pointerOffset = getPointerClientOffset(e);
      const deltaXY = subtractCoords(
        pointerOffset,
        clickItem.origin.pointerOffset
      );

      // noinspection JSSuspiciousNameCombination
      return (
        Math.abs(deltaXY.x) >= POINTER_CLICK_OFFSET_LIMIT ||
        Math.abs(deltaXY.y) >= POINTER_CLICK_OFFSET_LIMIT
      );
    }

    render() {
      const { props, handlers } = this;

      return <WrappedComponent {...{ ...props, ...handlers }} />;
    }
  }

  return connect(mapState, mapDispatch)(AvailabilityClickHandlers);
};
