import { z } from "zod";

import { BuildEnvironment } from "./BuildEnvironment";

export const nodeEnvSchema = z.object({
  VITE_APP_VERSION: z.string().optional(),
  VITE_BUILD_ENV: z
    .enum([
      BuildEnvironment.Production,
      BuildEnvironment.Demo,
      BuildEnvironment.Dev,
    ])
    .optional(),
  VITE_BUGSNAG_API_KEY: z.string().optional(),
});

export type TNodeEnv = z.infer<typeof nodeEnvSchema>;
