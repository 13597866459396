/* eslint-disable */
import React from "react";
import { getVisibleSelectionRect } from "draft-js";

class Toolbar extends React.PureComponent {
  state = {
    isVisible: false,
    position: undefined,

    /**
     * If this is set, the toolbar will render this instead of the children
     * prop and will also be shown when the editor loses focus.
     * @type {Component}
     */
    overrideContent: undefined
  };

  UNSAFE_componentWillMount() {
    this.props.store.subscribeToItem("selection", this.onSelectionChanged);
  }

  componentWillUnmount() {
    this.props.store.unsubscribeFromItem("selection", this.onSelectionChanged);
  }

  /**
   * This can be called by a child in order to render custom content instead
   * of the children prop. It's the responsibility of the callee to call
   * this function again with `undefined` in order to reset `overrideContent`.
   * @param {Component} overrideContent
   */
  onOverrideContent = overrideContent => this.setState({ overrideContent });

  getPosition = () => {
    const { store } = this.props;
    const { position } = this.state;
    // The editor root should be two levels above the node from
    // `getEditorRef`. In case this changes in the future, we
    // attempt to find the node dynamically by traversing upwards.
    const editorRef = store.getItem("getEditorRef")();
    if (!editorRef) return position;

    // This keeps backwards compatibility with React 15
    let editorRoot =
      editorRef.refs && editorRef.refs.editor
        ? editorRef.refs.editor
        : editorRef.editor;
    while (editorRoot.className.indexOf("DraftEditor-root") === -1) {
      editorRoot = editorRoot.parentNode;
    }
    const editorRootRect = editorRoot.getBoundingClientRect();

    const selectionRect = getVisibleSelectionRect(window);
    if (!selectionRect) return position;

    // The toolbar shouldn't be positioned directly on top of the selected text,
    // but rather with a small offset so the caret doesn't overlap with the text.
    const extraTopOffset = -5;

    const top =
      editorRoot.offsetTop -
      this.toolbar.offsetHeight +
      (selectionRect.top - editorRootRect.top) +
      extraTopOffset;

    const left =
      editorRoot.offsetLeft +
      (selectionRect.left - editorRootRect.left) +
      selectionRect.width / 2;

    const transformWidth = this.toolbar.offsetWidth / 2;

    if (left - transformWidth < 0) return { top, left: transformWidth };

    if (left + transformWidth > editorRootRect.width)
      return { top, left: "initial", right: -transformWidth };

    return { top, left };
  };

  getIsVisible = () => {
    const { store } = this.props;
    const { overrideContent } = this.state;

    const selection = store
      .getItem("getEditorState")()
      .getSelection();

    // overrideContent could for example contain a text input, hence we always show overrideContent
    return (
      (!selection.isCollapsed() && selection.getHasFocus()) ||
      Boolean(overrideContent)
    );
  };

  onSelectionChanged = () => {
    // need to wait a tick for window.getSelection() to be accurate
    // when focusing editor with already present selection
    setTimeout(() => {
      if (!this.toolbar) return;

      this.setState({
        position: this.getPosition(),
        isVisible: this.getIsVisible()
      });
    });
  };

  getStyle() {
    const { isVisible, position } = this.state;

    const style = { ...position };

    if (isVisible) {
      style.visibility = "visible";
      style.transform = "translate(-50%) scale(1)";
      style.transition = "transform 0.15s cubic-bezier(.3,1.2,.2,1)";
    } else {
      style.transform = "translate(-50%) scale(0)";
      style.visibility = "hidden";
    }

    return style;
  }

  handleToolbarRef = node => {
    this.toolbar = node;
  };

  render() {
    const { theme, store } = this.props;
    const { overrideContent: OverrideContent } = this.state;
    const childrenProps = {
      theme: theme.buttonStyles,
      getEditorState: store.getItem("getEditorState"),
      setEditorState: store.getItem("setEditorState"),
      onOverrideContent: this.onOverrideContent
    };

    return (
      <div
        className={theme.toolbarStyles.toolbar}
        style={this.getStyle()}
        ref={this.handleToolbarRef}
      >
        {OverrideContent ? (
          <OverrideContent {...childrenProps} />
        ) : (
          this.props.children(childrenProps)
        )}
      </div>
    );
  }
}

export default Toolbar;
