import { produce } from "immer";
import { call, put, select } from "redux-saga/effects";

import { reassignJobItemUserAPI } from "../../../lib/API/jobItemUserAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_SET_SAVING,
  JOB_ITEM_USER_DELETE,
  JOB_ITEM_USER_EDIT,
  JOB_ITEM_USER_REASSIGN,
} from "../../../lib/constants";
import { convertMinutesToHours } from "../../../lib/time";
import {
  actionJobItemRoleCreate,
  actionJobItemRoleSetHours,
} from "../../../state/entities/jobItemRole/actions";
import { createJobItemRoleGenerator } from "../../../state/entities/jobItemRole/sagas/watchJobItemRoleCreate";
import { selectJobItemRoleByJobItemIdRoleId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRoleByJobItemIdRoleId";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemUser } from "../../selectors/jobItemUser";
import { selectIdByJobItemIdUserId } from "../../selectors/jobItemUserSelectors";
import { selectUser } from "../../selectors/user";
import { checkSaveJobItem } from "../jobDetailsSaga/watchForceSave";

function* reassign(action) {
  const { jobItemUserId, userId } = action.payload;

  if (jobItemUserId <= 0) return;

  const jobItemUser = yield select(selectJobItemUser, { jobItemUserId });

  try {
    yield checkSaveJobItem();

    yield put(
      createAction(JOB_ITEM_SET_SAVING, {
        jobItemId: jobItemUser.jobItemId,
        saving: true,
      })
    );

    const response = yield call(reassignJobItemUserAPI, jobItemUserId, userId);

    yield put(createAction(ENTITIES_RECEIVED, response.data));
  } catch (error) {
    sagaError(error);
  } finally {
    yield put(
      createAction(JOB_ITEM_SET_SAVING, {
        jobItemId: jobItemUser.jobItemId,
        saving: false,
      })
    );
  }
}

function* reassignNew(action) {
  const { jobItemUserId, userId } = action.payload;
  if (jobItemUserId > 0) return;

  const jobItemUser = yield select(selectJobItemUser, {
    jobItemUserId,
  });

  if (userId !== null) {
    const existingJobItemUserId = yield select(selectIdByJobItemIdUserId, {
      id: jobItemUser.jobItemId,
      userId,
    });

    if (!existingJobItemUserId) {
      const newJobItemUser = produce(jobItemUser, (draft) => {
        draft.userId = userId;
      });

      yield put(
        createAction(JOB_ITEM_USER_EDIT, {
          jobItemId: jobItemUser.jobItemId,
          jobItemUsers: [{ new: newJobItemUser, prev: jobItemUser }],
        })
      );
    } else {
      const existingJobItemUser = yield select(selectJobItemUser, {
        jobItemUserId: existingJobItemUserId,
      });

      const newJobItemUser = produce(existingJobItemUser, (draft) => {
        draft.totalPlannedMinutes += jobItemUser.totalPlannedMinutes;
      });

      yield put(
        createAction(JOB_ITEM_USER_EDIT, {
          jobItemId: jobItemUser.jobItemId,
          jobItemUsers: [{ new: newJobItemUser, prev: existingJobItemUser }],
        })
      );

      yield put(createAction(JOB_ITEM_USER_DELETE, { jobItemUser }));
    }
  } else {
    const user = yield select(selectUser, {
      userId: jobItemUser.userId,
    });

    let existingJobItemRole = yield select(selectJobItemRoleByJobItemIdRoleId, {
      jobItemId: jobItemUser.jobItemId,
      roleId: user.roleId,
    });

    if (!existingJobItemRole) {
      yield createJobItemRoleGenerator(
        actionJobItemRoleCreate(jobItemUser.jobItemId, user.roleId, "jobItem")
      );

      existingJobItemRole = yield select(selectJobItemRoleByJobItemIdRoleId, {
        jobItemId: jobItemUser.jobItemId,
        roleId: user.roleId,
      });
    }

    yield put(
      actionJobItemRoleSetHours(
        existingJobItemRole.id,
        convertMinutesToHours(
          existingJobItemRole.totalPlannedMinutes +
            jobItemUser.totalPlannedMinutes
        )
      )
    );

    yield put(createAction(JOB_ITEM_USER_DELETE, { jobItemUser }));
  }
}

export default function* watchJobItemUserReassign() {
  yield takeLatestBy(
    [JOB_ITEM_USER_REASSIGN],
    reassign,
    (action) => action.payload.jobItemUserId
  );
  yield takeLatestBy(
    [JOB_ITEM_USER_REASSIGN],
    reassignNew,
    (action) => action.payload.jobItemUserId
  );
}
