import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobPhaseName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobPhaseDeleted = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" removed the phase "}
    <b>{getMetaDataJobPhaseName(activityEntry)}</b>
  </SystemMessage>
);

JobPhaseDeleted.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobPhaseDeleted;
