import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ENTITY_NAME_JOB_ITEM_USERS } from "../../../lib/constants";
import { selectPropsJobItemUserId } from "../index";

export const selectStateJobItemUsers = state =>
  state.entities[ENTITY_NAME_JOB_ITEM_USERS];

export const selectJobItemUsers = createSelector(
  selectStateJobItemUsers,
  jobItemUsers => get(jobItemUsers, `byId`)
);

export const selectJobItemUser = createCachedSelector(
  [selectStateJobItemUsers, selectPropsJobItemUserId],
  (jobItemUsers, jobItemUserId) => get(jobItemUsers, `byId.${jobItemUserId}`)
)(selectPropsJobItemUserId);

export const useJobItemUser = jobItemUserId =>
  useSelector(state => selectJobItemUser(state, { jobItemUserId }));
