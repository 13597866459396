import { all } from "redux-saga/effects";
import watchLineItemsMergeConfirm from "./watchLineItemsMergeConfirm";
import watchLineItemsSortCancel from "./watchLineItemsSortCancel";
import watchLineItemsSortConfirm from "./watchLineItemsSortConfirm";
import watchLineItemsSortStart from "./watchLineItemsSortStart";
import watchPurchaseOrderLineItemAdd from "./watchPurchaseOrderLineItemAdd";
import watchPurchaseOrderLineItemCreate from "./watchPurchaseOrderLineItemCreate";
import watchPurchaseOrderLineItemCreateTaxRate from "./watchPurchaseOrderLineItemCreateTaxRate";
import watchPurchaseOrderLineItemDelete from "./watchPurchaseOrderLineItemDelete";
import watchPurchaseOrderLineItemSave from "./watchPurchaseOrderLineItemSave";
import watchPurchaseOrderLineItemSetAccountCode from "./watchPurchaseOrderLineItemSetAccountCode";
import watchSetIsMerging from "./watchSetIsMerging";
import watchUndoMergeLineItems from "./watchUndoMergeLineItems";

export default function* purchaseOrderLineItemSaga() {
  yield all([
    watchPurchaseOrderLineItemCreateTaxRate(),
    watchPurchaseOrderLineItemAdd(),
    watchPurchaseOrderLineItemCreate(),
    watchPurchaseOrderLineItemDelete(),
    watchPurchaseOrderLineItemSave(),
    watchPurchaseOrderLineItemSetAccountCode(),
    watchSetIsMerging(),
    watchLineItemsMergeConfirm(),
    watchUndoMergeLineItems(),
    watchLineItemsSortStart(),
    watchLineItemsSortCancel(),
    watchLineItemsSortConfirm()
  ]);
}
