import { call, put, takeEvery } from "redux-saga/effects";
import { fetchCompanyContactsApi } from "../../../lib/API/companyApi";
import {
  COMPANY_CONTACTS_FETCH_FAILED,
  COMPANY_CONTACTS_FETCH_REQUEST,
  COMPANY_CONTACTS_FETCH_SUCCEEDED,
  ENTITIES_RECEIVED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetch(action) {
  try {
    const { id } = action.payload;

    const data = yield call(fetchCompanyContactsApi, id);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(COMPANY_CONTACTS_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(COMPANY_CONTACTS_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetchCompanyContacts() {
  yield takeEvery(COMPANY_CONTACTS_FETCH_REQUEST, fetch);
}
