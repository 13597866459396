export * from "./catchResponse";
export * from "./getAuthToken";
export * from "./getRequestHeaders";

import { runtimeEnv } from "@streamtimefe/environment";
import { APIController } from "./APIController";

export const API = new APIController(runtimeEnv()?.VITE_WEB_API_ROOT ?? "");

export const CLIENTAPI = new APIController(
  runtimeEnv()?.VITE_CLIENT_API_ROOT ?? ""
);
