import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobNameChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" changed the job name to "}
    <b>{getMetaDataJobName(activityEntry)}</b>
  </SystemMessage>
);

JobNameChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobNameChanged;
