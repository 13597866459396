import { produce } from "immer";

import { Entity, TEntityId } from "../../../entities/Entity";
import { ChangedEntity } from "../types";

export function byIdReducer<T extends Entity>(
  state: Partial<Record<TEntityId, T>>,
  changedEntities: ChangedEntity<T>[]
) {
  return produce(state, (draft: Partial<Record<TEntityId, T>>) => {
    changedEntities.forEach((changedEntity) => {
      if (changedEntity.prevEntity && !changedEntity.newEntity) {
        delete draft[changedEntity.prevEntity.id];
      }
      if (changedEntity.newEntity) {
        draft[changedEntity.newEntity.id] = changedEntity.newEntity;
      }
    });
  });
}
