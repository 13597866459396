import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsIsJobNumberDuplicated = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.isJobNumberDuplicated
);

export const useJobDetailsIsJobNumberDuplicated = () =>
  useSelector(selectJobDetailsIsJobNumberDuplicated);
