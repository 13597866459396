import { produce } from "immer";
import { get, isEmpty, isEqual, pull, set, unset } from "lodash-es";

import { pushOnce } from "../../lib/arrays";
import { isNilOrFalse } from "../../lib/lang";

const defaultValueGetter = (entity) => entity && entity.id;

/**
 * @param {function(object)} getKeyPath
 * @param {function(object)} getValue
 * @returns {Function}
 */
export default (getKeyPath, getValue = defaultValueGetter) =>
  (state = {}, changedEntities) =>
    produce(state, (draft) => {
      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const prevValue = prevEntity && getValue(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);
        const newValue = newEntity && getValue(newEntity);
        const hasChanged =
          !isEqual(prevPath, newPath) || !isEqual(prevValue, newValue);

        if (!isNilOrFalse(prevPath) && !isNilOrFalse(prevValue) && hasChanged) {
          const indexes = get(draft, prevPath);

          pull(indexes, prevValue);

          if (isEmpty(indexes)) unset(draft, prevPath);
          else set(draft, prevPath, indexes);
        }
      });

      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const prevValue = prevEntity && getValue(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);
        const newValue = newEntity && getValue(newEntity);
        const hasChanged =
          !isEqual(prevPath, newPath) || !isEqual(prevValue, newValue);

        if (!isNilOrFalse(newPath) && !isNilOrFalse(newValue) && hasChanged) {
          const indexes = get(draft, newPath, []);

          pushOnce(indexes, newValue);

          set(draft, newPath, indexes);
        }
      });
    });
