import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobItemUserStore = createSingleEntityStoreContext(
  EntityClass.JobItemUser
);

export const JobItemUserStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobItemUser,
  JobItemUserStore
);
