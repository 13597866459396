// deprecated

import type { TCurrency } from "@streamtimefe/entities";

import { asString } from "../lib";
import type { SearchOption } from ".";

export function currencyObj(entity: TCurrency) {
  const obj = {
    entity,
    getAsOption: function (): SearchOption<TCurrency> {
      return {
        entity,
        key: entity.id,
        value: entity.id,
        searchString: obj.getSearchString(),
      };
    },
    getSearchString: function () {
      return [asString(entity.id), asString(entity.name)].join(" ");
    },
  };
  return obj;
}
