import {
  LOGGED_EXPENSE_SAVING,
  LOGGED_EXPENSE_SAVED,
  LOGGED_EXPENSE_SAVE_FAILED,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVING,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVED,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false }
});

const onSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false, error }
});

const onMergeRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isMergeSaving: true, error: null }
});

const onMergeSuccess = (state, { payload: { id, snapshotId } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isMergeSaving: false,
    canUndo: true,
    undoMergeSnapshotId: snapshotId
  }
});

const onMergeError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isMergeSaving: false, error }
});

const onUndoMergeRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: true, error: null, canUndo: false }
});

const onUndoMergeSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: false, undoMergeSnapshotId: null }
});

const onUndoMergeError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: false, error, canUndo: true }
});

const onSortSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: true, error: null }
});

const onSortSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: false }
});

const onSortSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: false, error }
});

export default createReducer(
  {},
  {
    [LOGGED_EXPENSE_SAVING]: onSaveRequest,
    [LOGGED_EXPENSE_SAVED]: onSaveSuccess,
    [LOGGED_EXPENSE_SAVE_FAILED]: onSaveError,
    [PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING]: onMergeRequest,
    [PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED]: onMergeSuccess,
    [PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR]: onMergeError,
    [PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING]: onUndoMergeRequest,
    [PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED]: onUndoMergeSuccess,
    [PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR]: onUndoMergeError,
    [PURCHASE_ORDER_LINE_ITEM_SORT_SAVING]: onSortSaveRequest,
    [PURCHASE_ORDER_LINE_ITEM_SORT_SAVED]: onSortSaveSuccess,
    [PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR]: onSortSaveError
  }
);
