import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectJobItemRoleUi } from "./selectJobItemRoleUi";

export const selectEditedJobItemRoleIds = createCachedSelector(
  [selectJobItemRoleUi],
  (jobItemRoleUi) => get(jobItemRoleUi, `editedJobItemRoleIds`, [])
)(selectPropsJobItemId);

export function useEditedJobItemRoleIds(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectEditedJobItemRoleIds(state, { jobItemId })
  );
}
