import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { getJobCurrencyTotalLoggedTimeCostExTax } from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectJob } from "../job";
import { selectJobCalculatedTotalLoggedTimeExTax } from "../job/selectJobCalculatedTotalLoggedTimeExTax";
import { selectJobCalculatedTotalPlannedTimeExTax } from "../job/selectJobCalculatedTotalPlannedTimeExTax";
import { selectJobCalculatedUnplannedLoggedTimeExTax } from "../job/selectJobCalculatedUnplannedLoggedTimeExTax";
import { selectHasJobItemIdsByJobId } from "../jobItem/hasJobItemIdsByJobId";
import { selectJobDetailsCostVsSellMode } from "./ui/costVsSellMode";

export const selectJumbotronUsedPlannedTimeDollarsDisplay =
  createCachedSelector(
    [
      selectJob,
      selectHasJobItemIdsByJobId,
      selectJobDetailsCostVsSellMode,
      selectJobCalculatedTotalLoggedTimeExTax,
      selectJobCalculatedUnplannedLoggedTimeExTax,
      selectJobCalculatedTotalPlannedTimeExTax,
    ],
    (
      job,
      hasJobItems,
      costVsSellMode,
      calculatedTotalLoggedTimeExTax,
      calculatedUnplannedLoggedTimeExTax,
      calculatedTotalPlannedTimeExTax
    ) => {
      return {
        jobCurrencyTotalLoggedTimeCostExTax:
          getJobCurrencyTotalLoggedTimeCostExTax(job),
        jobCurrencyTotalLoggedTimeExTax: numeral(calculatedTotalLoggedTimeExTax)
          .add(numeral(calculatedUnplannedLoggedTimeExTax).value())
          .value(),
        jobCurrencyTotalPlannedTimeExTax: calculatedTotalPlannedTimeExTax,
      };
    }
  )(selectPropsJobId);

export const useJumbotronUsedPlannedTimeDollarsDisplay = (jobId) =>
  useSelector((state) =>
    selectJumbotronUsedPlannedTimeDollarsDisplay(state, {
      jobId,
      includeNonBillable: false,
    })
  );
