import {
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_BAR_DRAG_END,
  SCHEDULE_JOB_BAR_DRAG_START,
  SCHEDULE_JOB_BOOTSTRAP_FAILED,
  SCHEDULE_JOB_BOOTSTRAP_REQUEST,
  SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED,
  SCHEDULE_JOB_COLLAPSE,
  SCHEDULE_JOB_EXPAND,
  SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE,
  SCHEDULE_JOB_EXTRA_HOURS_EXPAND
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onExpandJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isExpanded: true
  }
});

const onCollapseJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isExpanded: false
  }
});

const onFetchRequest = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isFetching: true,
    isFetched: false,
    error: null
  }
});

const onFetchSuccess = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isFetching: false,
    isFetched: true
  }
});

const onFetchError = (state, { payload: { jobId, error } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isFetching: false,
    isFetched: false,
    error
  }
});

const onStartDraggingJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isDragging: true
  }
});

const onEndDraggingJob = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isDragging: false
  }
});

const onExpandJobExtraHours = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isExtraHoursExpanded: true
  }
});

const onCollapseJobExtraHours = (state, { payload: { jobId } }) => ({
  ...state,
  [jobId]: {
    ...state[jobId],
    isExtraHoursExpanded: false
  }
});

export default createReducer(
  {},
  {
    [SCHEDULE_JOB_EXPAND]: onExpandJob,
    [SCHEDULE_JOB_COLLAPSE]: onCollapseJob,
    [SCHEDULE_JOB_EXTRA_HOURS_EXPAND]: onExpandJobExtraHours,
    [SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE]: onCollapseJobExtraHours,
    [SCHEDULE_JOB_BAR_DRAG_START]: onStartDraggingJob,
    [SCHEDULE_JOB_BAR_DRAG_END]: onEndDraggingJob,
    [SCHEDULE_JOB_BAR_DRAG_CANCEL]: onEndDraggingJob,
    [SCHEDULE_JOB_BOOTSTRAP_REQUEST]: onFetchRequest,
    [SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED]: onFetchSuccess,
    [SCHEDULE_JOB_BOOTSTRAP_FAILED]: onFetchError
  }
);
