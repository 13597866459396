import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { flatten, uniq } from "lodash-es";
import { useMemo, useRef, useState } from "react";
import {
  Button,
  Icon,
  IconSize,
  MdAdd,
  MdChevronRight,
  MdFilterList,
} from "st-shared/components";
import { getGlobalReportingFilterSearchOptions } from "st-shared/entities";
import { useUniqueKey } from "st-shared/hooks";
import {
  addFilterGroup,
  createFilterEvent,
  FilterSearchMenu,
} from "st-shared/module";

import { reportingStore } from "../../../state/stores/reportingStore";
import {
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentGlobalFilters,
} from "../../../state/stores/savedSegmentSelectors";
import { useReportingGlobalFiltersOpen } from "../../../state/stores/uiSelectors";
import { textButton, textButtonIcon } from "./DataSet.css";
import {
  expanderIcon,
  globalFilterDropShadow,
  globalFiltersButtons,
  globalFiltersContainer,
  globalFiltersCount,
  globalFiltersHeader,
  globalFiltersHeaderText,
} from "./GlobalFilters.css";
import { GlobalFiltersList } from "./GlobalFiltersList";

export function GlobalFilters() {
  const ref = useRef<HTMLDivElement>(null);
  const globalFilters = useReportingSavedSegmentGlobalFilters();
  const globalFiltersOpen = useReportingGlobalFiltersOpen();
  const dataSets = useReportingSavedSegmentDataSets();

  const dataSetEntityDescriptors = useMemo(() => {
    return uniq(
      Object.values(dataSets).map((dataSet) => dataSet.entityDescriptorId)
    );
  }, [dataSets]);

  const globalFilterOptions = useMemo(
    () => getGlobalReportingFilterSearchOptions(dataSetEntityDescriptors),
    [dataSetEntityDescriptors]
  );

  const globalFilterOptionsCount = useMemo(
    () => flatten(Object.values(globalFilterOptions)).length,
    [globalFilterOptions]
  );

  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<HTMLDivElement | null>(null);

  function openAddFilterMenu() {
    setAddFilterAnchorEl(ref.current);
  }

  function closeAddFilterMenu() {
    setAddFilterAnchorEl(null);
  }

  const [filterListenerUuid] = useUniqueKey();

  function onFilterSearchPick(filterGroupTypeId: TFilterGroupTypeEnum) {
    closeAddFilterMenu();

    const filterGroup = addFilterGroup(
      filterGroupTypeId,
      reportingStore()
        .savedSegment.helpers.getReportingSavedSegment()
        .generateId()
    );

    reportingStore().savedSegment.actions.addGlobalFilterGroup(filterGroup);

    requestAnimationFrame(() => {
      createFilterEvent(filterListenerUuid, filterGroup.id, "open");
    });
  }

  function toggleOpen() {
    reportingStore().ui.actions.setGlobalFiltersOpen(!globalFiltersOpen);
  }

  const showGlobalFilters =
    globalFilters.length > 0 || globalFilterOptionsCount > 0;

  return (
    <>
      <div ref={ref} className={globalFiltersContainer}>
        {showGlobalFilters && (
          <>
            <div className={globalFiltersHeader} onClick={toggleOpen}>
              <Icon icon={MdFilterList} size={IconSize.Medium} />
              <span className={globalFiltersHeaderText}>
                Global Filters{" "}
                {!globalFiltersOpen && (
                  <span className={globalFiltersCount}>
                    {globalFilters.length}
                  </span>
                )}
              </span>
              <Icon
                className={
                  globalFiltersOpen
                    ? expanderIcon["open"]
                    : expanderIcon["close"]
                }
                icon={MdChevronRight}
                size={22}
              />
            </div>
            {globalFiltersOpen && (
              <>
                <GlobalFiltersList uuid={filterListenerUuid} />
                {globalFilterOptionsCount > 0 && (
                  <div>
                    <div className={globalFiltersButtons}>
                      <Button
                        variant="link"
                        className={textButton}
                        onClick={openAddFilterMenu}
                      >
                        <Icon
                          icon={MdAdd}
                          size={18}
                          className={textButtonIcon}
                        />
                        Add global filter
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {addFilterAnchorEl && (
          <FilterSearchMenu
            anchorEl={addFilterAnchorEl}
            onClose={closeAddFilterMenu}
            options={globalFilterOptions}
            onPick={onFilterSearchPick}
            title="Add Global Filter"
          />
        )}
      </div>
      {showGlobalFilters && <div className={globalFilterDropShadow} />}
    </>
  );
}
