import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsIsDatesDrawerOpen = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.isDatesDrawerOpen
);

export const useJobDetailsIsDatesDrawerOpen = () =>
  useSelector(selectJobDetailsIsDatesDrawerOpen);
