import {
  SCHEDULE_JOB_PHASE_COLLAPSE,
  SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE,
  SCHEDULE_JOB_PHASE_EXPAND,
  SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onExpandJobPhase = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isExpanded: true
  }
});

const onCollapseJobPhase = (state, { payload: { id } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isExpanded: false
  }
});

const onExpandJobPhases = (state, { payload: { ids } }) => ({
  ...state,
  ...ids.reduce((acc, id) => {
    acc[id] = {
      ...state[id],
      isExpanded: true
    };
    return acc;
  }, {})
});

const onCollapseJobPhases = (state, { payload: { ids } }) => ({
  ...state,
  ...ids.reduce((acc, id) => {
    acc[id] = {
      ...state[id],
      isExpanded: false
    };
    return acc;
  }, {})
});

export default createReducer(
  {},
  {
    [SCHEDULE_JOB_PHASE_EXPAND]: onExpandJobPhase,
    [SCHEDULE_JOB_PHASE_COLLAPSE]: onCollapseJobPhase,
    [SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE]: onExpandJobPhases,
    [SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE]: onCollapseJobPhases
  }
);
