import { EnumOption } from "../../core";
import type { TUserStatus, TUserStatusEnum } from "./UserStatus";
import { UserStatusEnum } from "./UserStatus";

export function isActiveOrHibernated(
  userStatus: TUserStatus | TUserStatusEnum
) {
  return isActive(userStatus) || isHibernated(userStatus);
}

export function isActive(userStatus: TUserStatus | TUserStatusEnum) {
  return EnumOption.getId(userStatus) === UserStatusEnum.Active;
}

export function isHibernated(userStatus: TUserStatus | TUserStatusEnum) {
  return EnumOption.getId(userStatus) === UserStatusEnum.Hibernated;
}

export function isDeleted(userStatus: TUserStatus | TUserStatusEnum) {
  return EnumOption.getId(userStatus) === UserStatusEnum.Deleted;
}

export function create(id: TUserStatusEnum): TUserStatus {
  return { id, name: getName(id) };
}

export function getName(userStatus: TUserStatus | TUserStatusEnum) {
  switch (EnumOption.getId(userStatus)) {
    case UserStatusEnum.Active:
      return "Active";
    case UserStatusEnum.Hibernated:
      return "Hibernated";
    case UserStatusEnum.Deleted:
      return "Deleted";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown User Status: ${userStatus}`);
      return "";
  }
}
