import styled from "styled-components";

const Milestone = styled.div`
  cursor: pointer;
  margin-top: -20px;
  transition: margin 0.2s;
  z-index: 1;
`;

export default Milestone;
