import type { ConstEnum } from "@streamtimefe/types";

import type {
  TAccountingPlatform,
  TActivityEntry,
  TBatchAction,
  TBatchActionOperation,
  TBranch,
  TCompany,
  TGoogleCalendar,
  TGoogleCalendarInboxEvent,
  THappinessResponse,
  TInvoice,
  TItemRate,
  TJob,
  TJobGroup,
  TJobGroupBreakdown,
  TJobGroupPeriod,
  TJobItem,
  TJobItemDependancy,
  TJobItemRole,
  TJobItemSubItem,
  TJobItemUser,
  TJobMilestone,
  TJobPhase,
  TLabel,
  TLoggedExpense,
  TMasterJobItem,
  TMasterLoggedExpense,
  TOrganisation,
  TPriorityBoardCard,
  TPurchaseOrder,
  TQuote,
  TRateCard,
  TRole,
  TRoleRate,
  TSavedSegment,
  TSavedSegmentTemplate,
  TTaxRate,
  TUser,
  TUserPreference,
} from "../../entities";

export const EntityClass = {
  User: "User",
  Role: "Role",
  RoleRate: "RoleRate",
  Organisation: "Organisation",
  UserPreference: "UserPreference",
  Branch: "Branch",
  RateCard: "RateCard",
  HappinessResponse: "HappinessResponse",
  SavedSegment: "SavedSegment",
  SavedSegmentTemplate: "SavedSegmentTemplate",
  MasterJobItem: "MasterJobItem",
  MasterLoggedExpense: "MasterLoggedExpense",
  TaxRate: "TaxRate",
  JobGroup: "JobGroup",
  JobGroupPeriod: "JobGroupPeriod",
  JobGroupBreakdown: "JobGroupBreakdown",
  Job: "Job",
  JobItem: "JobItem",
  JobItemUser: "JobItemUser",
  JobItemRole: "JobItemRole",
  JobItemSubItem: "JobItemSubItem",
  JobMilestone: "JobMilestone",
  JobPhase: "JobPhase",
  GoogleCalendar: "GoogleCalendar",
  GoogleCalendarInboxEvent: "GoogleCalendarInboxEvent",
  LoggedExpense: "LoggedExpense",
  PriorityBoardCard: "PriorityBoardCard",
  PurchaseOrder: "PurchaseOrder",
  Quote: "Quote",
  Invoice: "Invoice",
  Company: "Company",
  Label: "Label",
  BatchAction: "BatchAction",
  BatchActionOperation: "BatchActionOperation",
  ActivityEntry: "ActivityEntry",
  ItemRate: "ItemRate",
  JobItemDependancy: "JobItemDependancy",
  AccountingPlatform: "AccountingPlatform",
} as const;

export type TEntityClass = ConstEnum<typeof EntityClass>;

export type TEntityClassMap = {
  [EntityClass.User]: TUser;
  [EntityClass.Role]: TRole;
  [EntityClass.RoleRate]: TRoleRate;
  [EntityClass.Organisation]: TOrganisation;
  [EntityClass.UserPreference]: TUserPreference;
  [EntityClass.Branch]: TBranch;
  [EntityClass.RateCard]: TRateCard;
  [EntityClass.HappinessResponse]: THappinessResponse;
  [EntityClass.SavedSegment]: TSavedSegment;
  [EntityClass.SavedSegmentTemplate]: TSavedSegmentTemplate;
  [EntityClass.MasterJobItem]: TMasterJobItem;
  [EntityClass.MasterLoggedExpense]: TMasterLoggedExpense;
  [EntityClass.TaxRate]: TTaxRate;
  [EntityClass.JobGroup]: TJobGroup;
  [EntityClass.JobGroupPeriod]: TJobGroupPeriod;
  [EntityClass.JobGroupBreakdown]: TJobGroupBreakdown;
  [EntityClass.Job]: TJob;
  [EntityClass.JobItem]: TJobItem;
  [EntityClass.JobItemUser]: TJobItemUser;
  [EntityClass.JobItemRole]: TJobItemRole;
  [EntityClass.JobItemSubItem]: TJobItemSubItem;
  [EntityClass.JobMilestone]: TJobMilestone;
  [EntityClass.JobPhase]: TJobPhase;
  [EntityClass.GoogleCalendar]: TGoogleCalendar;
  [EntityClass.GoogleCalendarInboxEvent]: TGoogleCalendarInboxEvent;
  [EntityClass.LoggedExpense]: TLoggedExpense;
  [EntityClass.PriorityBoardCard]: TPriorityBoardCard;
  [EntityClass.PurchaseOrder]: TPurchaseOrder;
  [EntityClass.Quote]: TQuote;
  [EntityClass.Invoice]: TInvoice;
  [EntityClass.Company]: TCompany;
  [EntityClass.Label]: TLabel;
  [EntityClass.BatchAction]: TBatchAction;
  [EntityClass.BatchActionOperation]: TBatchActionOperation;
  [EntityClass.ActivityEntry]: TActivityEntry;
  [EntityClass.ItemRate]: TItemRate;
  [EntityClass.JobItemDependancy]: TJobItemDependancy;
  [EntityClass.AccountingPlatform]: TAccountingPlatform;
};

export type TEntityClassList = {
  [T in TEntityClass]?: TEntityClassMap[T][];
};
