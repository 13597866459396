import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DATE_FORMAT_DATE_MONTH_FULL_YEAR } from "../../../lib/constants";
import { toDate } from "../../../lib/dates";
import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { commercialDocumentEntityType } from "../../../lib/ui/commercialDocument";
import InlineDatePicker, {
  createInputProps
} from "../Pickers/InlineDatePicker";
import { ICON_SIZE } from "../StyledIcon";
import LanguageOption from "./LanguageOption";
import DetailTableLabel from "./Layout/DetailTableLabel";

class DetailTableDateField extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    labelKey: reactNodesType.isRequired,
    doSaveDocument: PropTypes.func.isRequired
  };

  save = value => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;

    if (value !== getValue(document))
      doSaveDocument(id, setValue(document, value), document);
  };

  handleChange = moment => {
    const value = toDate(moment);

    this.save(value);
  };

  render() {
    const { labelKey, document, getValue } = this.props;

    return (
      <tr>
        <StyledTh>
          <DetailTableLabel>
            <LanguageOption optionKey={labelKey} />
          </DetailTableLabel>
        </StyledTh>
        <td>
          <InlineDatePickerField
            value={getValue(document)}
            format={DATE_FORMAT_DATE_MONTH_FULL_YEAR}
            InputProps={createInputProps({
              className: "secondary",
              iconSize: ICON_SIZE.SMALL
            })}
            onChange={this.handleChange}
          />
        </td>
      </tr>
    );
  }
}

export default DetailTableDateField;

const StyledTh = styled.th`
  vertical-align: middle !important;
`;

const InlineDatePickerField = styled(InlineDatePicker)`
  background: white;
  width: 100px;
  height: 20px;
`;
