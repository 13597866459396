import clsx from "clsx";

import { Icon, StBranch, Tooltip } from "../../components";
import { TEntityId } from "../../entities/Entity";
import {
  branchIconContainer,
  branchIconTooltip,
  iconCss,
} from "./BranchIcon.css";

type Props = {
  id: TEntityId;
  size?: number;
  color?: string;
  className?: string;
  name?: string;
  disableTooltip?: boolean;
};

export function BranchIcon({
  name,
  size = 30,
  color = "black",
  disableTooltip = false,
  className,
}: Props) {
  return (
    <div
      className={clsx(branchIconContainer, className)}
      style={{ height: size, color }}
    >
      {disableTooltip && <Icon icon={StBranch} size={size} />}
      {!disableTooltip && (
        <Tooltip title={<div className={branchIconTooltip}>{name}</div>}>
          <div className={iconCss}>
            <Icon icon={StBranch} size={size} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
