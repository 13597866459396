import styled from "styled-components";
import SearchMenu from "../../SearchMenu";

export default styled(SearchMenu)`
  pointer-events: none;
  .MuiPaper-root {
    min-width: 200px;
    max-width: 500px;
    max-height: 300px;
    pointer-events: auto;
  }
`;
