import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_OBJECT } from "../../lib/constants";
import { selectPropsJobId } from "./index";

export const selectJobsUi = state => state.ui.jobs;

export const selectJobUi = createSelector(
  selectJobsUi,
  selectPropsJobId,
  (scheduleJobs, jobId) => get(scheduleJobs, jobId, EMPTY_OBJECT)
);

export const selectIsJobItemsLockedByJobId = createSelector(
  selectJobUi,
  jobUi => get(jobUi, "isJobItemsLocked", false)
);
