// deprecated

import type { TCompany } from "@streamtimefe/entities";

import { FrameworkException } from "../lib";
import { asDecimal } from "../lib/math";
import type { SearchOption } from ".";
import type { Contact } from "./Contact";
import type { ItemPricingMethod } from "./ItemPricingMethod";
import { ItemPricingMethods } from "./ItemPricingMethod";
import { LineItemOptionTypes } from "./LineItemOptionType";
import type { MasterItem, MasterItemOption } from "./MasterItem";

export interface MasterLoggedExpense extends MasterItem {
  costRate: number | string | null;
  itemPricingMethod: ItemPricingMethod;
  company: TCompany | null;
  contact: Contact | null;
}

export type MasterLoggedExpenseOption = MasterItemOption;

export function masterLoggedExpenseObj(entity: MasterLoggedExpense) {
  const obj = {
    entity,
    getSearchString: function () {
      return entity.name;
    },
    isDeleted: function () {
      return !entity.active;
    },
    getCostRate: function () {
      return asDecimal(entity.costRate);
    },
    getAsOptionNew: function (): SearchOption<MasterLoggedExpense> {
      return {
        entity,
        key: entity.id,
        value: entity.name,
        searchString: obj.getSearchString(),
      };
    },
    // TODO replace this with getAsOptionNew
    getAsOption: function (): MasterLoggedExpenseOption {
      return {
        key: entity.id,
        value: entity.name,
        optionType: obj.getOptionType(),
        searchString: obj.getSearchString(),
      };
    },
    getOptionType: function () {
      switch (entity.itemPricingMethod.id) {
        case ItemPricingMethods.UnitPrice:
          return LineItemOptionTypes.UnitItem;
        case ItemPricingMethods.Markup:
        case ItemPricingMethods.FixedPrice:
          return LineItemOptionTypes.MarkupExpense;
        default:
          throw new FrameworkException(
            `Unknown Item Pricing Method: ${entity.itemPricingMethod.id}`
          );
      }
    },
  };
  return obj;
}
