import { images } from "@streamtimefe/assets";
import { useDispatch } from "react-redux";
import { sharedEmitter } from "st-shared/lib";
import styled from "styled-components";

import { JOB_DETAILS_SET_BOTTOM_SECTION_TAB } from "../../../lib/constants";
import { JOB_BOTTOM_SECTION_TABS } from "../../../lib/constants/jobDetails";
import { getTodayDate } from "../../../lib/dates";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { InlineTextButton } from "../../elements/Button";
import { useJobId } from "../context/JobIdContext";

const JobDatesIntroduction = () => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();
  const createMilestone = () => {
    dispatch(
      createAction(
        JOB_DETAILS_SET_BOTTOM_SECTION_TAB,
        JOB_BOTTOM_SECTION_TABS.TIMELINE
      )
    );
    requestAnimationFrame(() => {
      sharedEmitter.emit("scrollToAndCreateMilestone", getTodayDate());
    });
  };

  return (
    <Container>
      <strong>You’re on. It’s a date.</strong>
      <br />
      <StyledImage alt="space ship sun" src={images.spaceShipSun} />
      <br />
      {isJobEditable && (
        <StyledSpan>
          Looks like you haven’t set up any dates for this job. Set some item
          dates or{" "}
          <StyledInlineTextButton onClick={createMilestone}>
            create your first milestone
          </StyledInlineTextButton>{" "}
          to see them appear here.
        </StyledSpan>
      )}
      {!isJobEditable && (
        <span>
          Looks like there aren’t any items, milestones or invoices. Once there
          are dates on the job, you’ll see them here.
        </span>
      )}
    </Container>
  );
};

export default JobDatesIntroduction;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 20px;

  padding: 0 20px;

  color: var(--color-gray-dark);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
`;

const StyledSpan = styled.span`
  padding: 0 10px;
`;

const StyledInlineTextButton = styled(InlineTextButton)`
  margin-top: -1px;
`;
