import React from "react";
import { UserDisplayName } from "st-shared/module";
import styled from "styled-components";

import { entityIdType } from "../../../lib/types/entityTypes";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import Flex from "../../elements/Flex";
import EmptyMessage from "../ScheduleLayout/EmptyMessage";

class NoJobsForUserGroupMessage extends React.PureComponent {
  static propTypes = {
    userId: entityIdType.isRequired,
  };

  render() {
    const { userId } = this.props;

    return (
      <Wrapper>
        <EmptyMessage>
          Looks like <UserDisplayName userId={userId} /> isn’t assigned to any
          jobs you’ve filtered.
        </EmptyMessage>
      </Wrapper>
    );
  }
}

export default NoJobsForUserGroupMessage;

const Wrapper = styled(Flex)`
  background: white;
  width: 100%;
  height: ${ScheduleUi.User.JobEmptyRowHeight}px;
  align-items: center;
  padding: 10px 15px 10px 50px;
`;
