import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataQuoteNumber,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobQuoteRevertedToDraft = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" reverted quote "}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataQuoteNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to draft "}
  </SystemMessage>
);

JobQuoteRevertedToDraft.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobQuoteRevertedToDraft;
