import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteScheduledBlockAPI,
  deleteScheduledBlockBulkAPI,
} from "../../../lib/API/scheduleAPI";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_DELETE,
  SCHEDULE_BLOCK_DELETE_ERROR,
  SCHEDULE_BLOCK_START_DELETE,
  SCHEDULE_BLOCK_START_DELETE_MULTIPLE,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleLoggedTimesByBlockKey,
} from "../../selectors/scheduleBlockSelectors";
import validateCanModifyBlock from "./validateCanModifyBlock";

function* startDeleteBlock(action) {
  try {
    const { blockKey } = action.payload;

    const baseScheduleLoggedTime = yield select(
      selectBaseScheduleLoggedTimeByBlockKey,
      { blockKey }
    );

    if (!baseScheduleLoggedTime) return;

    yield validateCanModifyBlock(blockKey);

    const deletedScheduleLoggedTimes = yield select(
      selectScheduleLoggedTimesByBlockKey,
      { blockKey }
    );

    yield put(
      createAction(SCHEDULE_BLOCK_DELETE, {
        ...action.payload,
        deletedScheduleLoggedTimes,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* deleteBlock(action) {
  try {
    const { blockKey, blockKeys } = action.payload;

    let response;

    if (blockKey) {
      response = yield call(deleteScheduledBlockAPI, blockKey);
    } else {
      response = yield call(deleteScheduledBlockBulkAPI, blockKeys);
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...response.data }));
  } catch (error) {
    yield put(
      createAction(SCHEDULE_BLOCK_DELETE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

function* deleteMultipleBlock(action) {
  try {
    const { selectedBlockKeys } = action.payload;

    const deletedScheduleLoggedTimes = [];
    const deletedSelectedBlockKeys = [];

    for (let i = 0; i < selectedBlockKeys.length; i++) {
      const blockKey = selectedBlockKeys[i];

      const baseScheduleLoggedTime = yield select(
        selectBaseScheduleLoggedTimeByBlockKey,
        { blockKey }
      );

      if (!baseScheduleLoggedTime) continue;

      yield validateCanModifyBlock(blockKey);

      const scheduleLoggedTimesByBlockKey = yield select(
        selectScheduleLoggedTimesByBlockKey,
        { blockKey }
      );
      deletedScheduleLoggedTimes.push(...scheduleLoggedTimesByBlockKey);
      deletedSelectedBlockKeys.push(blockKey);
    }

    yield put(
      createAction(SCHEDULE_BLOCK_DELETE, {
        ...action.payload,
        deletedScheduleLoggedTimes,
        blockKeys: deletedSelectedBlockKeys,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* watchBlockDelete() {
  yield takeEvery([SCHEDULE_BLOCK_START_DELETE_MULTIPLE], deleteMultipleBlock);
  yield takeEvery([SCHEDULE_BLOCK_START_DELETE], startDeleteBlock);
  yield takeEvery([SCHEDULE_BLOCK_DELETE], deleteBlock);
}

export default watchBlockDelete;
