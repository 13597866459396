import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const JobItemDependancyStore = createSingleEntityStoreContext(
  EntityClass.JobItemDependancy
);

export const JobItemDependancyStoreProvider = createSingleEntityStoreProvider(
  EntityClass.JobItemDependancy,
  JobItemDependancyStore
);
