import type { ZodError } from "zod";

export function formatEnvParsingErrors<Input>(error: ZodError<Input>) {
  return Object.entries(error.format())
    .map(([name, value]) => {
      if (value && "_errors" in value)
        return `${name}: ${value._errors.join(", ")}\n`;
      return null;
    })
    .filter(Boolean);
}
