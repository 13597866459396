import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsKey } from "../../../helpers/selectors";
import {
  UserPreferenceDefaults,
  TUserPreferenceKeys,
  UserPreferenceValueTypes,
} from "../types";
import { selectUserPreference } from "./selectUserPreference";

export const selectUserPreferenceValue = createCachedSelector(
  [selectUserPreference, selectPropsKey],
  (userPreference, key) =>
    userPreference ? userPreference.value : UserPreferenceDefaults[key]
)(selectPropsKey);

export function useUserPreferenceValue(
  key: TUserPreferenceKeys,
  defaultValue?: any
) {
  const value = useSelector((state: AppState) =>
    selectUserPreferenceValue(state, { key })
  );

  if (!value) {
    return defaultValue;
  }

  switch (UserPreferenceValueTypes[key]) {
    case "boolean":
      return Boolean(value);
    case "string":
      return String(value);
    case "number":
      return Number(value);
    default:
      return value;
  }
}
