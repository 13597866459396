import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import { JOB_EDIT, JOB_SET_NAME } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";

function* setName(action) {
  try {
    const { jobId, value } = action.payload;

    const job = yield select(selectJob, { jobId });

    const newJob = produce(job, draft => {
      draft.name = value;
    });

    yield put(
      createAction(JOB_EDIT, {
        jobId,
        job: newJob
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetName() {
  yield takeLatestBy([JOB_SET_NAME], setName, action => action.payload.jobId);
}
