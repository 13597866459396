import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobItemStore } from "./JobItem.store";

export const useJobItemEntity = createUseEntityStoreEntity(EntityClass.JobItem);

export const getJobItemEntity = createGetEntityStoreEntity(EntityClass.JobItem);

export const useJobItem = createUseSingleEntity(JobItemStore);

export function useJobItemActions() {
  return JobItemStore.useActions();
}
