import { has } from "lodash-es";
import PropTypes from "prop-types";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";
import { UserIcon } from "st-shared/module";
import styled from "styled-components";

import { convertMinutesToTimeHM } from "../../../lib/time";
import { IconButton } from "../../elements/Button";
import { ellipsisElement } from "../../elements/cssElements";
import StarIcon from "../../elements/Icons/StarIcon";
import Tooltip from "../../modules/Tooltip";

const DEFAULT_ICON_SIZE = 30;

export function JobLeadUserIcon({ user, size, onClick, disabled }) {
  return (
    <Tooltip title={getTooltip(user, true)} placement="bottom">
      <IconButton onClick={onClick} disabled={disabled}>
        <UserIcon id={user.id} name={user.name} size={size} disableTooltip />
        <StyledStarIcon size={14} />
      </IconButton>
    </Tooltip>
  );
}

JobLeadUserIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

JobLeadUserIcon.defaultProps = {
  size: DEFAULT_ICON_SIZE,
};

export function JobUserIcon({ user, size }) {
  return (
    <Tooltip title={getTooltip(user, false)} placement="bottom">
      <div>
        <UserIcon id={user.id} name={user.name} size={size} disableTooltip />
      </div>
    </Tooltip>
  );
}

JobUserIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  size: PropTypes.number,
};

JobUserIcon.defaultProps = {
  size: DEFAULT_ICON_SIZE,
};

export function OtherUsersIcon({ users, size }) {
  if (users.length <= 0) return null;

  return (
    <Tooltip
      title={
        <TooltipTable>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="name gray">{user.name}</td>
                <td>
                  {convertMinutesToTimeHM(user.logged)} /{" "}
                  {convertMinutesToTimeHM(user.planned)}
                </td>
              </tr>
            ))}
          </tbody>
        </TooltipTable>
      }
      placement="bottom"
    >
      <NumberIcon>+{users.length}</NumberIcon>
    </Tooltip>
  );
}

OtherUsersIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array.isRequired,
  size: PropTypes.number,
};

OtherUsersIcon.defaultProps = {
  size: DEFAULT_ICON_SIZE,
};

function getTooltip(user, isJobLead) {
  const showHours = !isJobLead || user.logged > 0 || user.planned > 0;

  const topItems = user.items.slice(0, 5);
  const otherItems = user.items.slice(topItems.length);

  return (
    <TooltipTable>
      <tbody>
        <tr>
          <td className="name">{user.name}</td>
          {showHours && (
            <td>
              {convertMinutesToTimeHM(user.logged)} /{" "}
              {convertMinutesToTimeHM(user.planned)}
            </td>
          )}
        </tr>
        {isJobLead && (
          <tr>
            <td className="name">
              <span role="img" aria-label="star">
                ⭐
              </span>{" "}
              Job Lead
            </td>
            {showHours && <td></td>}
          </tr>
        )}
        {user.items.length > 0 && (
          <>
            <tr className="divider">
              <td colSpan="2"></td>
            </tr>
            <tr className="dividerBlank">
              <td colSpan="3"></td>
            </tr>
          </>
        )}
        {topItems.map((item) => {
          let value = item.planned - item.logged;
          let text = "remaining";
          let className = "";

          if (item.planned === 0) {
            value = item.logged;
            text = "logged";
          } else if (value < 0) {
            value = item.logged - item.planned;
            text = "over";
            className = "red";
          } else if (
            value > 0 &&
            item.statusId === JobItemUserStatuses.Complete
          ) {
            text = "under";
            className = "green";
          }

          return (
            <tr key={item.id}>
              <td className="name gray">{item.name}</td>
              <td className={className}>
                {convertMinutesToTimeHM(value)} {text}
              </td>
            </tr>
          );
        })}
        {otherItems.length > 0 && (
          <tr>
            <td colSpan="2" className="gray">
              and {otherItems.length} more
            </td>
          </tr>
        )}
        {has(user, "unplannedTime") && user.unplannedTime > 0 && (
          <>
            <tr className="unplanned">
              <td colSpan="3"></td>
            </tr>
            <tr>
              <td className="name gray">Extra Hours</td>
              <td>{convertMinutesToTimeHM(user.unplannedTime)}</td>
            </tr>
          </>
        )}
      </tbody>
    </TooltipTable>
  );
}

const TooltipTable = styled.table`
  color: white;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;

  margin: -15px;

  tr:first-of-type td {
    padding-top: 15px;
  }

  tr:last-of-type td {
    padding-bottom: 15px;
  }

  td {
    padding: 0 15px;
  }

  tr.unplanned td {
    padding-top: 7px;
    border-bottom: 1px solid var(--color-gray-dark);
  }

  tr.unplanned + tr td {
    padding-top: 7px;
  }

  .name {
    padding-right: 20px;
    max-width: 150px;
    ${ellipsisElement}
  }

  .gray {
    color: var(--color-gray);
  }

  .red {
    color: var(--color-red-bright);
  }

  .green {
    color: var(--color-green-bright);
  }

  tr.divider td {
    padding-top: 15px;
  }
`;

const StyledStarIcon = styled(StarIcon)`
  color: black;
  position: absolute;
  top: -3px;
  right: -3px;
`;

const NumberIcon = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  font-size: 14px;
  line-height: 28px;
  padding: 0 4px;
`;
