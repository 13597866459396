import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { timeAllocationMethodObj } from "st-shared/entities";

import { getTotalPlannedMinutes } from "../../../lib/entities/jobItemEntity";
import { getTotalPlannedMinutes as getJobItemUserTotalPlannedMinutes } from "../../../lib/entities/jobItemUserEntity";
import { selectJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { selectJobItemRoleIdsByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRoleIdsByJobItemId";
import { selectJobItemUser } from "../jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectJobItem } from "./index";

export const selectJobItemCalculatedTotalPlannedMinutes = (
  state,
  { jobItemId }
) => {
  const jobItem = selectJobItem(state, { jobItemId });

  if (timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()) {
    return getTotalPlannedMinutes(jobItem);
  }

  const jobItemUserHoursTotal = reduce(
    selectJobItemUserIdsByJobItemId(state, { jobItemId }),
    (totalPlannedMinutes, jobItemUserId) => {
      const jobItemUser = selectJobItemUser(state, { jobItemUserId });
      return numeral(totalPlannedMinutes)
        .add(getJobItemUserTotalPlannedMinutes(jobItemUser))
        .value();
    },
    0
  );

  const jobItemRoleHoursTotal = reduce(
    selectJobItemRoleIdsByJobItemId(state, { jobItemId }),
    (totalPlannedMinutes, jobItemRoleId) => {
      const jobItemRole = selectJobItemRole(state, { jobItemRoleId });
      return numeral(totalPlannedMinutes)
        .add(jobItemRole.totalPlannedMinutes)
        .value();
    },
    0
  );

  return jobItemUserHoursTotal + jobItemRoleHoursTotal;
};

export const useJobItemCalculatedTotalPlannedMinutes = (jobItemId) =>
  useSelector((state) =>
    selectJobItemCalculatedTotalPlannedMinutes(state, { jobItemId })
  );
