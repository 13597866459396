import type {
  TSavedSegment,
  TSavedSegmentTemplate,
} from "@streamtimefe/entities";
import { SavedSegment, SavedSegmentTypeEnum } from "@streamtimefe/entities";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { produce } from "immer";
import type { ReportingSavedSegment } from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import { WebAPI } from "st-shared/lib";
import {
  addGlobalStore,
  authenticationStore,
  savedSegmentEntityStore,
  savedSegmentTemplateEntityStore,
} from "st-shared/stores";
import type { CrudActionReturn } from "st-shared/stores/entities/crudStore";
import { sagaError } from "st-shared/stores/sagaHelpers";
import { create } from "zustand";

import { RoutePages, routerPush, routerReplace } from "../../../../lib/router";
import { fePageSetPageTitle } from "../../../../lib/WebAppAPI/fePages";
import { getReportingEncodedSearch } from "../userPreferences/reportingEncodedSearch";
import type { SavedSegmentSlice } from "./savedSegmentSlice";
import { createSavedSegmentSlice } from "./savedSegmentSlice";
import type { SearchSlice } from "./searchSlice";
import { createSearchSlice, initSearchSagas } from "./searchSlice";
import type { UiSlice } from "./uiSlice";
import { createUiSlice } from "./uiSlice";

export type ReportingStoreState = {
  fetching: boolean;
  error?: unknown;
  actions: {
    bootstrap: () => Promise<void>;
    bootstrapSavedSegment: (entityId: TEntityId | null) => Promise<void>;
    loadSavedSegment: (entityId: TEntityId) => Promise<TSavedSegment | null>;
    startDefaultSegment: () => void;
    saveTemplateSegment: (
      savedSegmentTemplate: TSavedSegmentTemplate
    ) => Promise<void>;
    revertSavedSegment: () => void;
    saveSavedSegment: () => void;
    saveNewSavedSegment: () => void;
  };
} & SearchSlice &
  SavedSegmentSlice &
  UiSlice;

export const useReportingStore = create<ReportingStoreState>()(function (
  ...args
) {
  const [set, get] = args;
  return {
    ...createSearchSlice(...args),
    ...createSavedSegmentSlice(...args),
    ...createUiSlice(...args),
    fetching: true,
    error: undefined,
    actions: {
      async bootstrap() {
        // reset defaults
        get().ui.actions.reset();
        get().search.actions.reset();
        get().savedSegment.actions.reset();
        set({ fetching: true, error: undefined });

        try {
          savedSegmentEntityStore().actions.purge();
          savedSegmentTemplateEntityStore().actions.purge();

          await Promise.all([
            savedSegmentEntityStore().actions.load([
              SavedSegmentTypeEnum.Reporting,
            ]),
            savedSegmentTemplateEntityStore().actions.load([
              SavedSegmentTypeEnum.Reporting,
            ]),
          ]);

          set({ fetching: false });
        } catch (error: unknown) {
          set({ fetching: false, error });
        }
      },

      async bootstrapSavedSegment(entityId: TEntityId | null) {
        // reset
        get().ui.actions.reset();
        get().search.actions.reset();
        get().savedSegment.actions.reset();

        const userPreference = getReportingEncodedSearch();

        if (entityId === null) {
          if (userPreference) {
            // load user preference
            if (userPreference.entityId) {
              const savedSegment = await get().actions.loadSavedSegment(
                userPreference.entityId
              );
              if (savedSegment) {
                routerReplace(RoutePages.Reporting, userPreference.entityId);
              } else {
                set((s) =>
                  produce(s, (draft) => {
                    draft.savedSegment.entityId = userPreference.entityId;
                    draft.savedSegment.value =
                      CReportingSavedSegment.fromObject(
                        userPreference.value as ReportingSavedSegment
                      ).object;
                    draft.savedSegment.name = userPreference.name;
                  })
                );
                fePageSetPageTitle(userPreference.name);
                get().search.actions.fetchSeries();
              }
            } else {
              set((s) =>
                produce(s, (draft) => {
                  draft.savedSegment.value = CReportingSavedSegment.fromObject(
                    userPreference.value as ReportingSavedSegment
                  ).object;
                  draft.savedSegment.name = userPreference.name;
                })
              );
              fePageSetPageTitle(userPreference.name);
              get().search.actions.fetchSeries();
            }
          } else {
            // empty state
            get().actions.startDefaultSegment();
          }
        } else {
          // load segment
          const savedSegment = await get().actions.loadSavedSegment(entityId);

          if (savedSegment !== null) {
            if (userPreference && userPreference.entityId === entityId) {
              set((s) =>
                produce(s, (draft) => {
                  draft.savedSegment.entityId = userPreference.entityId;
                  draft.savedSegment.value = CReportingSavedSegment.fromObject(
                    userPreference.value as ReportingSavedSegment
                  ).object;
                  draft.savedSegment.name = userPreference.name;
                })
              );
              fePageSetPageTitle(userPreference.name);
              get().search.actions.fetchSeries();
            } else {
              set((s) =>
                produce(s, (draft) => {
                  draft.savedSegment.entityId = entityId;
                  draft.savedSegment.value = CReportingSavedSegment.fromString(
                    savedSegment.value
                  ).object;
                  draft.savedSegment.name = savedSegment.name;
                })
              );
              fePageSetPageTitle(savedSegment.name);
              get().savedSegment.actions.saveUserPreference();
              get().search.actions.fetchSeries();
            }
          } else if (userPreference?.entityId === entityId) {
            set((s) =>
              produce(s, (draft) => {
                draft.savedSegment.entityId = undefined;
                draft.savedSegment.value = CReportingSavedSegment.fromObject(
                  userPreference.value as ReportingSavedSegment
                ).object;
                draft.savedSegment.name = userPreference.name;
              })
            );
            get().savedSegment.actions.saveUserPreference();
            routerReplace(RoutePages.Reporting);
          } else {
            // not found
            get().actions.startDefaultSegment();
            routerReplace(RoutePages.Reporting);
          }
        }
      },

      async loadSavedSegment(entityId: TEntityId) {
        const savedSegment =
          savedSegmentEntityStore().helpers.getEntity(entityId);

        if (savedSegment) return savedSegment;

        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.fetching = true;
          })
        );

        let response: CrudActionReturn<TSavedSegment> = null;

        try {
          response = await savedSegmentEntityStore().actions.loadEntity(
            entityId,
            true
          );
        } finally {
          set((s) =>
            produce(s, (draft) => {
              draft.savedSegment.fetching = false;
            })
          );
        }

        if (response && "entity" in response) {
          return response.entity;
        }
        return null;
      },

      startDefaultSegment() {
        // reset
        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.entityId = undefined;
            draft.savedSegment.value =
              CReportingSavedSegment.createDefault().object;
            draft.savedSegment.name = "";
          })
        );
        fePageSetPageTitle("");
        get().savedSegment.actions.saveUserPreference();
        routerReplace(RoutePages.Reporting);
      },

      revertSavedSegment() {
        const entityId = get().savedSegment.entityId;

        if (!entityId) return;

        const savedSegment =
          savedSegmentEntityStore().helpers.getEntity(entityId);

        if (!savedSegment) return;

        const value = CReportingSavedSegment.fromString(
          savedSegment.value
        ).object;

        const name = savedSegment.name;

        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.value = value;
            draft.savedSegment.name = name;
          })
        );
        fePageSetPageTitle(name);
        get().search.actions.fetchSeries();
      },

      saveSavedSegment() {
        const entityId = get().savedSegment.entityId;

        if (!entityId) return;

        const savedSegment =
          savedSegmentEntityStore().helpers.getEntity(entityId);

        if (!savedSegment) return;

        const newSavedSegment = produce(savedSegment, (draft) => {
          draft.name = get().savedSegment.name;
          draft.value = CReportingSavedSegment.fromObject(
            get().savedSegment.value
          ).toString();
        });
        savedSegmentEntityStore().actions.saveEntity(newSavedSegment);
      },

      async saveTemplateSegment(savedSegmentTemplate: TSavedSegmentTemplate) {
        const loggedInUserId = authenticationStore().loggedInUserId!;

        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.fetching = true;
          })
        );

        try {
          const savedSegment = SavedSegment.create(
            loggedInUserId,
            SavedSegmentTypeEnum.Reporting,
            savedSegmentTemplate.name,
            savedSegmentTemplate.value
          );
          const response =
            await WebAPI.SavedSegments.createEntity(savedSegment);

          savedSegmentEntityStore().listeners.receiveEntities({
            entityData: { savedSegments: [response.data] },
          });
          routerPush(RoutePages.Reporting, response.data.id);
        } catch (error) {
          sagaError(error);
        } finally {
          set((s) =>
            produce(s, (draft) => {
              draft.savedSegment.fetching = false;
            })
          );
        }
      },

      saveNewSavedSegment() {
        const { entityId, name, value: savedSegmentValue } = get().savedSegment;
        const loggedInUserId = authenticationStore().loggedInUserId!;

        let defaultName = name;

        if (entityId) {
          const entity = savedSegmentEntityStore().helpers.getEntity(entityId);

          if (entity && entity.name === name) {
            defaultName += " (Copy)";
          }
        }

        getModalBridgeClient()?.openModal(
          ModalTypes.ReportingCreate,
          {
            userId: loggedInUserId,
            value:
              CReportingSavedSegment.fromObject(savedSegmentValue).toString(),
            defaultName,
          },
          {
            success(_, { savedSegment }) {
              savedSegmentEntityStore().listeners.receiveEntities({
                entityData: { savedSegments: [savedSegment] },
              });
              routerPush(RoutePages.Reporting, savedSegment.id);
            },
          }
        );
      },
    },
  };
});

export function reportingStore() {
  return useReportingStore.getState();
}

reportingStore.init = function () {
  addGlobalStore(["pages", RoutePages.Reporting], reportingStore);

  initSearchSagas();
};
