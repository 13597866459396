import { put, call, select } from "redux-saga/effects";
import { createJobPhaseAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  JOB_PHASE_NEW_PHASE_DELETE,
  JOB_PHASE_SAVE,
  JOB_PHASE_SAVE_ERROR,
  JOB_PHASE_SAVED,
  JOB_PHASE_SAVING
} from "../../../lib/constants";
import {
  getJobId,
  getJobPhaseName
} from "../../../lib/entities/jobPhaseEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobPhaseJobItemIds } from "../../selectors/jobPhase/ui/jobItemIds";

export function* saveNewJobPhase(action) {
  const { jobPhaseId, jobPhase } = action.payload;

  try {
    if (jobPhaseId > 0) return;

    if (getJobPhaseName(jobPhase).length === 0) {
      yield put(
        createAction(JOB_PHASE_NEW_PHASE_DELETE, {
          jobPhase
        })
      );
      return;
    }

    yield put(
      createAction(JOB_PHASE_SAVING, {
        jobPhaseId,
        jobPhase
      })
    );

    const jobItemIds = yield select(selectJobPhaseJobItemIds, { jobPhaseId });

    const { data } = yield call(
      createJobPhaseAPI,
      getJobId(jobPhase),
      jobPhase,
      jobItemIds
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_PHASE_SAVED, {
        jobPhaseId,
        jobPhase,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_PHASE_SAVE_ERROR, {
        jobPhaseId,
        jobPhase,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchJobPhaseSaveNew() {
  yield takeLatestBy(
    [JOB_PHASE_SAVE],
    saveNewJobPhase,
    action => action.payload.jobPhaseId
  );
}
