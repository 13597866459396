import * as PropTypes from "prop-types";
import { entityIdType, typeEntityType } from "../types/entityTypes";

export const labelEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  labelType: typeEntityType,
  entityId: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
});

export const labelsSubEntityType = PropTypes.arrayOf(labelEntityType);

export const getLabelTypeId = ({ labelType }) => labelType.id;

export const getId = ({ id }) => id;

export const getName = ({ name }) => name;

export const getEntityId = ({ entityId }) => entityId;

export const isActive = ({ active }) => active;

export const getEntityIdLabelTypeIdPath = entity =>
  `["${getEntityId(entity)}.${getLabelTypeId(entity)}"]`;

export const createNewLabel = ({
  id = null,
  labelTypeId,
  entityId,
  value
}) => ({
  id,
  labelType: { id: labelTypeId },
  entityId,
  name: value,
  active: true
});

export const getAsNewLabelOption = ({ id, name, labelType }) => ({
  id,
  labelType,
  name
});
