import styled from "styled-components";
import Input from "../../../elements/Input";

const DetailTableField = styled(Input)`
  width: 100px;
  height: 20px;
  background: white;
  color: black;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 4px 2px;
  .MuiInputBase-input {
    padding: 0;
  }
  &.Mui-disabled {
    background: none;
    background-color: transparent;
    color: black;
  }
`;

export default DetailTableField;
