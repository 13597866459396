import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE,
  DATA_ATTRIBUTE_USER,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { getJobIdIfBaseLoggedTime } from "../../../lib/entities/scheduleLoggedTimeEntity";
import { scheduleSearchResultEntityType } from "../../../lib/entities/scheduleSearchResultEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectBlockContainerHeightByUserIdJobId } from "../../../redux/selectors/scheduleSelectors";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { isMaxZoomLevel } from "../../../state/ui/schedule/lib";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import ScheduleBlock from "../ScheduleBlocks/ScheduleBlock";
import ScheduleBlocksContainer from "../ScheduleLayout/ScheduleBlocksContainer";
import ScheduleBlocksWrapper from "../ScheduleLayout/ScheduleBlocksWrapper";
import ScheduleUserAvailability from "../ScheduleLayout/ScheduleUserAvailability";
import ScheduleUserNonWorkingDays from "../ScheduleLayout/ScheduleUserNonWorkingDays";
import ScheduleUtilisation from "../ScheduleLayout/ScheduleUtilisation";
import Anchor from "../ScheduleScrollProvider/Anchor";
import { BlocksByJobIdConsumer } from "../ScheduleScrollProvider/BlockConsumer";

const mapState = (state, props) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
  scheduleSearchResult: selectJobListModel(state, props),
  blocksHeight: selectBlockContainerHeightByUserIdJobId(state, props),
});

const mapDispatch = null;

const attrUserId = getAttrPropString(DATA_ATTRIBUTE_USER);
const attrJobId = getAttrPropString(DATA_ATTRIBUTE_JOB);
const attrScheduleFilterMode = getAttrPropString(
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE
);

class ScheduleJobTeamMemberTodos extends React.PureComponent {
  filterMode = ScheduleUiBlockFilterModes.UserJobTodos;

  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    scheduleSearchResult: scheduleSearchResultEntityType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    blocksHeight: PropTypes.number.isRequired,
    showAvailability: PropTypes.bool,
  };

  static defaultProps = {
    showAvailability: false,
  };

  blockFilter = (scheduleLoggedTime) => {
    const { jobId } = this.props;
    return getJobIdIfBaseLoggedTime(scheduleLoggedTime) === jobId;
  };

  render() {
    const { id, jobId, blocksHeight, zoomLevel, showAvailability } = this.props;
    const pointerEventAttrs = {
      [attrUserId]: id,
      [attrJobId]: jobId,
      [attrScheduleFilterMode]: this.filterMode,
    };

    return (
      <ScheduleBlocksWrapper style={{ height: blocksHeight }}>
        <ScheduleUserNonWorkingDays id={id} />
        {showAvailability && <ScheduleUserAvailability id={id} compactMode />}
        {isMaxZoomLevel(zoomLevel) ? (
          <ScheduleBlocksContainer {...pointerEventAttrs}>
            <Anchor>
              <BlocksByJobIdConsumer id={id} jobId={jobId}>
                {(blockKey) => (
                  <ScheduleBlock key={blockKey} blockKey={blockKey} />
                )}
              </BlocksByJobIdConsumer>
            </Anchor>
          </ScheduleBlocksContainer>
        ) : (
          <ScheduleUtilisation
            id={id}
            filterMode={this.filterMode}
            blockFilter={this.blockFilter}
            {...pointerEventAttrs}
          />
        )}
      </ScheduleBlocksWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobTeamMemberTodos);
