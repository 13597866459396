import { Drawer } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_PAGE_SET_DATE_DRAWER_OPEN } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsIsDatesDrawerOpen } from "../../../redux/selectors/jobDetails/ui/isDatesDrawerOpen";
import JobDates from "./index";

const DatesDrawer = () => {
  const dispatch = useDispatch();

  const open = useJobDetailsIsDatesDrawerOpen();

  const closeDrawer = () =>
    dispatch(createAction(JOB_PAGE_SET_DATE_DRAWER_OPEN, false));

  return (
    <StyledDrawer
      anchor="right"
      elevation={0}
      open={open}
      onClose={closeDrawer}
    >
      <Container>
        <JobDates isDrawer />
      </Container>
    </StyledDrawer>
  );
};

export default DatesDrawer;

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Container = styled.div`
  background-color: var(--color-snow);
  width: 435px;
  height: 100%;
`;
