import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  ${({ align }) => align && `align-items: ${align}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ grow }) => grow && `flex-grow: ${grow}`};
  ${({ shrink }) => shrink && `flex-grow: ${shrink}`};
`;

export default Flex;
