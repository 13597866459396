import isError from "lodash-es/isError";
import { call, put } from "redux-saga/effects";

import {
  feToWebConfirmJobAssignRoleTime,
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../../lib/WebAppAPI/fePages/genericWeb";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { actionEntitiesReceived } from "../../actions";
import { JOB_ASSIGN_ROLE_TIME, JobAssignRoleTimeAction } from "../actions";
import JobAPI from "../api";

function* assignRoleTime(action: JobAssignRoleTimeAction) {
  try {
    yield call(feToWebConfirmJobAssignRoleTime, {
      jobId: action.jobId,
      roleId: action.roleId,
      userId: action.userId,
    });

    feToWebShowPageLoadingMask(11);

    const data: Awaited<ReturnType<typeof JobAPI.assignRoleTime>> = yield call(
      JobAPI.assignRoleTime,
      action.jobId,
      action.roleId,
      action.userId
    );

    yield put(actionEntitiesReceived(data));
  } catch (error) {
    if (isError(error)) sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchJobAssignRoleTime() {
  yield takeLatestBy(
    [JOB_ASSIGN_ROLE_TIME],
    assignRoleTime,
    (action: JobAssignRoleTimeAction) => action.jobId
  );
}
