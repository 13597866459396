import { produce } from "immer";
import { get, set, setWith } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import {
  getIsHeading,
  getTaxAmount,
  getTaxName,
  getTaxRate,
  getTotalAmountExTax,
  getTotalsTaxKey,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedInvoiceIds) =>
  produce(nextState, (draft) => {
    const getInvoiceLineItem = (id) => nextState.byId[id];

    touchedInvoiceIds.forEach((invoiceId) => {
      let totalExTax = 0;
      const totalTaxes = {};

      get(nextState.idsByInvoiceId, invoiceId, [])
        .map(getInvoiceLineItem)
        .forEach((lineItem) => {
          if (getIsHeading(lineItem)) return;

          const amountExTax = asDecimal(getTotalAmountExTax(lineItem));
          const taxName = getTaxName(lineItem);
          const taxRate = getTaxRate(lineItem);
          const taxKey = getTotalsTaxKey(taxName, taxRate);
          const taxAmount = getTaxAmount(lineItem);

          totalExTax += amountExTax;
          if (taxAmount > 0 || taxAmount < 0) {
            set(totalTaxes, taxKey, {
              taxName,
              taxRate,
              taxAmount: get(totalTaxes, `${taxKey}.taxAmount`, 0) + taxAmount,
            });
          }
        });

      const totals = {
        totalExTax,
        totalTaxes,
      };

      setWith(draft, `totalsByInvoiceId.${invoiceId}`, totals, Object);
    });
  });
