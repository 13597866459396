import { isEqual } from "lodash-es";
import { call, put, select } from "redux-saga/effects";

import { updateJobItemAPI } from "../../../lib/API/jobItemAPI";
import {
  ENTITIES_RECEIVED,
  JOB_DETAILS_FETCH_HISTORICAL_SUMMARY,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVED,
  JOB_ITEM_SAVING,
} from "../../../lib/constants";
import { selectJobItemRolesByIds } from "../../../state/entities/jobItemRole/selectors/selectJobItemRolesByIds";
import { selectEditedJobItemRoleIds } from "../../../state/ui/jobItemRoles/selectors/selectEditedJobItemRoleIds";
import { selectEditedJobItemRoles } from "../../../state/ui/jobItemRoles/selectors/selectEditedJobItemRoles";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemSubItemsByIds } from "../../selectors/jobItemSubItem/selectJobItemSubItemsByIds";
import { selectEditedJobItemSubItemIds } from "../../selectors/jobItemSubItem/ui/selectEditedJobItemSubItemIds";
import { selectEditedJobItemSubItems } from "../../selectors/jobItemSubItem/ui/selectEditedJobItemSubItems";
import { selectEditedJobItemUserIds } from "../../selectors/jobItemUser/ui/selectEditedJobItemUserIds";
import { selectEditedJobItemUsers } from "../../selectors/jobItemUser/ui/selectEditedJobItemUsers";
import { selectJobItemUsersByIds } from "../../selectors/jobItemUserSelectors";

export function* saveJobItem(action) {
  const { jobItemId, jobItem, prevJobItem } = action.payload;

  try {
    if (jobItemId < 0) return;

    const editedJobItemUserIds = yield select(selectEditedJobItemUserIds, {
      jobItemId,
    });
    const editedJobItemSubItemIds = yield select(
      selectEditedJobItemSubItemIds,
      {
        jobItemId,
      }
    );
    const editedJobItemRoleIds = yield select(selectEditedJobItemRoleIds, {
      jobItemId,
    });

    if (
      isEqual(jobItem, prevJobItem) &&
      editedJobItemUserIds.length === 0 &&
      editedJobItemSubItemIds.length === 0 &&
      editedJobItemRoleIds.length === 0
    )
      return;

    yield put(
      createAction(JOB_ITEM_SAVING, {
        jobItemId,
        jobItem,
      })
    );

    const jobItemUsers = yield select(selectJobItemUsersByIds, {
      ids: editedJobItemUserIds,
    });
    const jobItemSubItems = yield select(selectJobItemSubItemsByIds, {
      ids: editedJobItemSubItemIds,
    });
    const jobItemRoles = yield select(selectJobItemRolesByIds, {
      ids: editedJobItemRoleIds,
    });

    const { data } = yield call(
      updateJobItemAPI,
      jobItem,
      null,
      null,
      jobItemUsers,
      jobItemSubItems,
      jobItemRoles
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_ITEM_SAVED, {
        jobItemId,
        jobItem,
        jobItemUsers,
        jobItemSubItems,
        jobItemRoles,
        data,
      })
    );

    yield put(
      createAction(JOB_DETAILS_FETCH_HISTORICAL_SUMMARY, {
        jobId: jobItem.jobId,
      })
    );
  } catch (error) {
    const previousJobItemSubItems = yield select(selectEditedJobItemSubItems, {
      jobItemId,
    });
    const previousJobItemUsers = yield select(selectEditedJobItemUsers, {
      jobItemId,
    });
    const previousJobItemRoles = yield select(selectEditedJobItemRoles, {
      jobItemId,
    });

    yield put(
      createAction(JOB_ITEM_SAVE_ERROR, {
        jobItemId,
        jobItem,
        prevJobItem,
        previousJobItemSubItems,
        previousJobItemUsers,
        previousJobItemRoles,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchSaveJobItemCard() {
  yield takeLatestBy(
    [JOB_ITEM_SAVE_ITEM_CARD],
    saveJobItem,
    (action) => action.payload.jobItemId
  );
}
