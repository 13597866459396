import type {
  TAuthenticationBootstrapResponse,
  TEntityDescriptorEnum,
  TEntityId,
  TJob,
  TModelSet,
} from "@streamtimefe/entities";

import type { WebBridgeErrorTypes } from "../global";
import { getWebBridgeClient } from "./client";

export function navigateBack(fallbackRoute: string) {
  getWebBridgeClient()?.parent.receiveNavigateBack(fallbackRoute);
}

export function pageTitleUpdate(title: string) {
  getWebBridgeClient()?.parent.receivePageTitleUpdate(title);
}

export function openAvailabilityModal() {
  getWebBridgeClient()?.parent.receiveOpenAvailabilityModal();
}

export function getAuthBootstrap() {
  return new Promise<TAuthenticationBootstrapResponse | null | undefined>(
    (resolve) => resolve(getWebBridgeClient()?.parent.receiveGetAuthBootstrap())
  );
}

export function applyModelData(data: TModelSet) {
  getWebBridgeClient()?.parent.receiveApplyModelData(data);
}

export function displayError(type: WebBridgeErrorTypes) {
  getWebBridgeClient()?.parent.receiveDisplayError(type);
}

export function alert(type: "success" | "error", message: string) {
  getWebBridgeClient()?.parent.receiveAlert(type, message);
}

export function showMaintenancePage() {
  getWebBridgeClient()?.parent.receiveShowMaintenancePage();
}

export function showLoadingOverlay(zIndex?: number) {
  getWebBridgeClient()?.parent.receiveShowLoadingOverlay(zIndex);
}

export function hideLoadingOverlay() {
  getWebBridgeClient()?.parent.receiveHideLoadingOverlay();
}

export function openIntercomChat() {
  getWebBridgeClient()?.parent.receiveOpenIntercomChat();
}

export function sendIntercomPageVisit(pageName: string) {
  getWebBridgeClient()?.parent.receiveIntercomPageVisit(pageName);
}

export function sendIntercomEvent(name: string, data?: unknown) {
  getWebBridgeClient()?.parent.receiveIntercomSendEvent(name, data);
}

export function openProductOnBoarding() {
  getWebBridgeClient()?.parent.receiveOpenProductOnBoarding();
}

export function validateNewActiveJobLimit() {
  if (import.meta.env.DEV) return true;
  return (
    getWebBridgeClient()?.parent.receiveValidateNewActiveJobLimit() ?? false
  );
}

export function validateNewArchivedJobLimit() {
  if (import.meta.env.DEV) return true;
  return (
    getWebBridgeClient()?.parent.receiveValidateNewArchivedJobLimit() ?? false
  );
}

export function openJobLoggedItemsModal(
  job: TJob,
  viewMode?: "time" | "expenses"
) {
  getWebBridgeClient()?.parent.receiveOpenJobLoggedItemsModal(job, viewMode);
}

export function openJobLoggedExpensesModal(job: TJob) {
  getWebBridgeClient()?.parent.receiveOpenJobLoggedExpensesModal(job);
}

export function openJobInvoicesModal(job: TJob) {
  getWebBridgeClient()?.parent.receiveOpenJobInvoicesModal(job);
}

export function openJobQuotesModal(job: TJob) {
  getWebBridgeClient()?.parent.receiveOpenJobQuotesModal(job);
}

export function openLoggedExpense(id: TEntityId) {
  getWebBridgeClient()?.parent.receiveOpenLoggedExpense(id);
}

export function createLoggedTime(job: TJob) {
  getWebBridgeClient()?.parent.receiveCreateLoggedTime(job);
}

export function createRepeatingLoggedTime(job: TJob) {
  getWebBridgeClient()?.parent.receiveCreateRepeatingLoggedTime(job);
}

export function createExpense(job: TJob) {
  getWebBridgeClient()?.parent.receiveCreateExpense(job);
}

export function createQuote(job: TJob) {
  getWebBridgeClient()?.parent.receiveCreateQuote(job);
}

export function createInvoice(job: TJob) {
  getWebBridgeClient()?.parent.receiveCreateInvoice(job);
}

export function addHistory(
  entityDescriptorId: TEntityDescriptorEnum,
  entityId: TEntityId,
  entityName: string
) {
  getWebBridgeClient()?.parent.receiveAddHistory(
    entityDescriptorId,
    entityId,
    entityName
  );
}

export function starEntity(
  entityDescriptorId: TEntityDescriptorEnum,
  entityId: TEntityId,
  entityName: string
) {
  getWebBridgeClient()?.parent.receiveStarEntity(
    entityDescriptorId,
    entityId,
    entityName
  );
}
