import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { selectPropsJobPhaseId } from "../../index";
import { selectStateJobDetailsUi } from "./index";

export const selectJobPhaseTeamSummary = createCachedSelector(
  [selectStateJobDetailsUi, selectPropsJobPhaseId],
  (jobDetails, jobPhaseId) => ({
    users: get(jobDetails, `teamSummary.phaseUsers.${jobPhaseId}`, []),
    roles: get(jobDetails, `teamSummary.phaseRoles.${jobPhaseId}`, []),
  })
)(selectPropsJobPhaseId);

export const useJobPhaseTeamSummary = (jobPhaseId) =>
  useSelector((state) => selectJobPhaseTeamSummary(state, { jobPhaseId }));
