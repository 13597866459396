// deprecated

import type { Option } from "./Option";

export const ItemPricingMethods = {
  UnitPrice: 1,
  Markup: 2,
  FixedPrice: 3,
} as const;

export type TItemPricingMethods =
  (typeof ItemPricingMethods)[keyof typeof ItemPricingMethods];

export interface ItemPricingMethod extends Option {
  id: TItemPricingMethods;
}

export function itemPricingMethodObj(entity: ItemPricingMethod) {
  const obj = {
    entity,
    isUnitPrice: function () {
      return entity.id === ItemPricingMethods.UnitPrice;
    },
    isMarkup: function () {
      return entity.id === ItemPricingMethods.Markup;
    },
    isFixedPrice: function () {
      return entity.id === ItemPricingMethods.FixedPrice;
    },
  };
  return obj;
}
