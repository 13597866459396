import { call, put, takeEvery } from "redux-saga/effects";
import { fetchActivityEntryAPI } from "../../../lib/API/activityEntryAPI";
import {
  ACTIVITY_ENTRY_FETCH_FAILED,
  ACTIVITY_ENTRY_FETCH_REQUEST,
  ACTIVITY_ENTRY_FETCH_SUCCEEDED,
  ENTITIES_RECEIVED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetch(action) {
  try {
    const { id } = action.payload;

    const data = yield call(fetchActivityEntryAPI, id);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
    yield put(createAction(ACTIVITY_ENTRY_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(ACTIVITY_ENTRY_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(ACTIVITY_ENTRY_FETCH_REQUEST, fetch);
}
