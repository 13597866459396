import type { TValueMatchTypeEnum } from "@streamtimefe/entities";
import { findIndex, without } from "lodash-es";
import { Fragment, useRef, useState } from "react";

import { IconSize, LabelCheckbox, Popover } from "../../../components";
import { useCustomerCurrency } from "../../../stores";
import type {
  FilterTypeCheckbox,
  FilterTypeCheckboxItem,
} from "../../../types/FilterGroupType";
import {
  FilterGroupTypeProperties,
  getFilterName,
} from "../../../types/FilterGroupType";
import { getDefaultValueMatchType } from "..";
import { boldText, checkBoxEditCss } from "../Filter.css";
import type {
  FilterDisplayTextProps,
  FilterGroupFilter,
  FilterGroupFilterValue,
  FilterProps,
} from "../types";
import { useFilterListener } from "../useFilterListener";
import { FilterDisplay } from "./FilterDisplay";

export function CheckboxFilter({
  uuid,
  filterGroup,
  onDelete,
  defaultDescriptions,
  addFilter,
  deleteFilters,
}: FilterProps) {
  const properties = FilterGroupTypeProperties[
    filterGroup.filterGroupTypeId
  ] as FilterTypeCheckbox;

  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  function openEditMenu() {
    setAnchorEl(ref.current);
  }

  function closeEditMenu() {
    setAnchorEl(null);
  }

  function isChecked(item: FilterTypeCheckboxItem) {
    if ("valueMatchTypeId" in item) {
      for (const filter of filterGroup.filters) {
        if (filter.valueMatchTypeId === item.valueMatchTypeId) return true;
      }
    } else {
      for (const filter of filterGroup.filters) {
        if (filter.value === item.value) return true;
      }
    }
    return false;
  }

  function getValueFromText(
    text: string
  ): FilterGroupFilterValue | TValueMatchTypeEnum | null {
    for (const item of properties.items) {
      if (item.text === text) return getItemValue(item);
    }
    return null;
  }

  function onCheckboxClick(event: React.ChangeEvent<HTMLInputElement>) {
    const value = getValueFromText(event.target.name);
    if (value === null) return;

    if (event.target.checked) {
      addFilter(filterGroup.id, {
        valueMatchTypeId: isValueMatchTypeId()
          ? (value as TValueMatchTypeEnum)
          : getDefaultValueMatchType(filterGroup.filterGroupTypeId),
        value: isValueMatchTypeId() ? "" : value,
      });
    } else {
      const searchParam = isValueMatchTypeId() ? "valueMatchTypeId" : "value";
      const index = findIndex(filterGroup.filters, [searchParam, value]);
      if (index != -1) {
        deleteFilters(filterGroup.id, [index]);
      }
    }
  }

  function getItemValue(item: FilterTypeCheckboxItem) {
    if ("value" in item) {
      return item.value;
    } else {
      return item.valueMatchTypeId;
    }
  }

  function isValueMatchTypeId() {
    return "valueMatchTypeId" in properties.items[0];
  }

  useFilterListener(uuid, filterGroup.id, "open", openEditMenu);

  return (
    <div>
      <FilterDisplay
        ref={ref}
        onClick={openEditMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <CheckboxFilterDisplayText
          filterGroup={filterGroup}
          defaultDescriptions={defaultDescriptions}
        />
      </FilterDisplay>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: -36, horizontal: "center" }}
        PaperProps={{ className: checkBoxEditCss.root }}
      >
        {properties.items.map((item) => (
          <LabelCheckbox
            key={item.text}
            label={item.text}
            checked={isChecked(item)}
            onChange={onCheckboxClick}
            checkboxProps={{
              size: IconSize.Medium,
              className: checkBoxEditCss.checkbox,
            }}
            labelClassName={checkBoxEditCss.label}
          />
        ))}
      </Popover>
    </div>
  );
}

export function CheckboxFilterDisplayText({
  filterGroup,
  defaultDescriptions,
}: FilterDisplayTextProps) {
  const properties = FilterGroupTypeProperties[
    filterGroup.filterGroupTypeId
  ] as FilterTypeCheckbox;

  const customerCurrency = useCustomerCurrency();

  function nameText() {
    return (
      <span className={boldText}>
        {getFilterName(filterGroup.filterGroupTypeId, customerCurrency)}
      </span>
    );
  }

  function isText(negated: boolean = false) {
    return negated ? " is not " : " is ";
  }

  function getDisplayTextFromValue(filter: FilterGroupFilter): string | null {
    for (const item of properties.items) {
      let found = false;
      if ("value" in item) {
        found = item.value === filter.value;
      } else {
        found = item.valueMatchTypeId === filter.valueMatchTypeId;
      }
      if (found) return item.displayText || item.text;
    }
    return null;
  }

  function renderDefault(
    description: { negated?: boolean; value: string },
    showIsText = true
  ) {
    return (
      <>
        {showIsText && isText(description.negated)}
        <span className={boldText}>{description.value}</span>
      </>
    );
  }

  function renderValuesOnlyDisplay() {
    if (filterGroup.filters.length === 0) {
      if (
        defaultDescriptions &&
        defaultDescriptions[filterGroup.filterGroupTypeId]
      ) {
        return renderDefault(
          defaultDescriptions[filterGroup.filterGroupTypeId]!,
          false
        );
      }
      if (properties.defaultDescription) {
        return renderDefault(properties.defaultDescription, false);
      }
    }

    let filterTexts = filterGroup.filters.map((filter) =>
      getDisplayTextFromValue(filter)
    );

    if (filterGroup.filters.length === 0) {
      filterTexts = properties.items.map(
        (item) => item.displayText || item.text
      );
    }

    return (
      <>
        {properties.forceTitle && (
          <>
            {nameText()}
            {isText()}
          </>
        )}
        {filterTexts.map((filterText, index) => (
          <Fragment key={index}>
            {index !== 0 && " or "}
            <span className={boldText}>{filterText}</span>
          </Fragment>
        ))}
      </>
    );
  }

  function renderDisplay() {
    if (filterGroup.filters.length === properties.items.length) {
      return renderDefault(properties.allDescription);
    }
    if (filterGroup.filters.length === 0) {
      if (
        defaultDescriptions &&
        defaultDescriptions[filterGroup.filterGroupTypeId]
      ) {
        return renderDefault(
          defaultDescriptions[filterGroup.filterGroupTypeId]!
        );
      }
      if (properties.defaultDescription) {
        return renderDefault(properties.defaultDescription);
      }
      return renderDefault(properties.allDescription);
    }

    let negated = false;
    let filterTexts = filterGroup.filters.map((filter) =>
      getDisplayTextFromValue(filter)
    );

    if (filterGroup.filters.length / properties.items.length > 0.5) {
      negated = true;
      const itemTexts = properties.items.map(
        (item) => item.displayText || item.text
      );
      filterTexts = without(itemTexts, ...filterTexts);
    }

    return (
      <>
        {isText(negated)}
        {filterTexts.map((filterText, index) => (
          <Fragment key={index}>
            {index !== 0 && " or "}
            <span className={boldText}>{filterText}</span>
          </Fragment>
        ))}
      </>
    );
  }

  if (properties.valuesOnly) {
    return renderValuesOnlyDisplay();
  }

  return (
    <>
      {nameText()}
      {renderDisplay()}
    </>
  );
}
