import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { convertMinutesToTimeHM } from "../../../lib/time";
import {
  ScheduleUi,
  ScheduleUiAvailabilityViews,
} from "../../../state/ui/schedule/types";

class ScheduleUserAvailabilityChunk extends React.PureComponent {
  static propTypes = {
    days: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    intensity: PropTypes.oneOf(ScheduleUi.User.AvailabilityLevels).isRequired,
    compactMode: PropTypes.bool.isRequired,
    dayWidth: PropTypes.number.isRequired,
    availabilityView: PropTypes.oneOf(values(ScheduleUiAvailabilityViews))
      .isRequired,
  };

  get width() {
    const { days, dayWidth } = this.props;
    return dayWidth * days - 1;
  }

  get formattedMinutes() {
    const { minutes, intensity } = this.props;
    let unit = null;
    let padHours = false;
    let overSuffix = "";
    let availableSuffix = "";

    if (this.width < 40) unit = "h";

    if (this.width > 40) {
      padHours = true;
      availableSuffix = "/d";
    }

    if (this.width > 80) overSuffix = "over";

    if (this.width > 100) overSuffix = "/d over";

    if (this.width > 140) availableSuffix = `/d available`;

    const value = convertMinutesToTimeHM(
      Math.abs(Number(minutes)),
      false,
      unit,
      padHours
    );

    switch (intensity) {
      case ScheduleUi.User.AvailabilityLevels[0]:
        return null;
      case ScheduleUi.User.AvailabilityLevels[5]:
        return "Full";
      case ScheduleUi.User.AvailabilityLevels[6]:
        return `${value} ${overSuffix}`;
      default:
        return `${value} ${availableSuffix}`;
    }
  }

  get formattedPercentage() {
    const { intensity, percent } = this.props;
    switch (intensity) {
      case ScheduleUi.User.AvailabilityLevels[0]:
        return null;
      default:
        return `${Math.abs(Number(percent))}%`;
    }
  }

  get chunkContent() {
    const { compactMode, availabilityView } = this.props;

    if (compactMode) return null;

    if (availabilityView === ScheduleUiAvailabilityViews.Percentage)
      return this.formattedPercentage;

    return this.formattedMinutes;
  }

  render() {
    const { intensity, compactMode } = this.props;

    const backgroundColor = `var(--color-heatmap-level-${intensity})`;

    return (
      <AvailabilityChunk
        style={{ width: this.width, backgroundColor }}
        className={compactMode && "compact"}
      >
        {this.chunkContent}
      </AvailabilityChunk>
    );
  }
}

export default ScheduleUserAvailabilityChunk;

const AvailabilityChunk = styled.div`
  height: 25px;
  margin: 0 0 0 1px;
  padding: 3px 5px 2px;
  color: white;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.2s;
  &.compact {
    height: 100%;
    padding: 0;
  }
`;
