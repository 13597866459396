import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATE_FORMAT_DATE_SHORT_MONTH,
  JOB_TIMELINE_PHASE_COLLAPSE,
  JOB_TIMELINE_PHASE_EXPAND,
  JOB_TIMELINE_ITEM_ROW_HEIGHT
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import {
  getJobPhaseName,
  jobPhaseEntityType
} from "../../../lib/entities/jobPhaseEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import {
  selectPhaseTimelineEndDateByJobPhaseId,
  selectPhaseTimelineStartDateByJobPhaseId
} from "../../../redux/selectors/jobItemSelectors";
import { selectJobPhaseHasDependanciesById } from "../../../redux/selectors/jobPhaseSelectors";
import {
  selectJobPhaseById,
  selectJobTimelinePhaseUi
} from "../../../redux/selectors/jobTimelineSelectors";
import Flex from "../../elements/Flex";
import Spacer from "../../elements/Spacer";
import Expander from "../../modules/Expander";
import { DateCell } from "../DateCell";
import { JobTimelineGroupUnderline } from "../JobTimelineGroupUnderline";

const mapState = (state, props) => ({
  jobPhase: selectJobPhaseById(state, props),
  startDate: selectPhaseTimelineStartDateByJobPhaseId(state, props),
  endDate: selectPhaseTimelineEndDateByJobPhaseId(state, props),
  forceExpand: selectJobPhaseHasDependanciesById(state, props),
  isCollapsed: selectJobTimelinePhaseUi(state, props).isCollapsed
});

const mapDispatch = dispatch => ({
  doExpand: id => dispatch(createAction(JOB_TIMELINE_PHASE_EXPAND, { id })),
  doCollapse: id => dispatch(createAction(JOB_TIMELINE_PHASE_COLLAPSE, { id }))
});

class JobTimelinePhaseGroup extends React.PureComponent {
  tooltipAnchorRef = React.createRef();

  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    jobPhase: jobPhaseEntityType.isRequired,
    readOnly: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
    forceExpand: PropTypes.bool.isRequired,
    isCollapsed: PropTypes.bool
  };

  static defaultProps = {
    isCollapsed: false,
    startDate: null,
    endDate: null
  };

  get canToggleExpand() {
    const { readOnly, forceExpand } = this.props;
    return !(readOnly || forceExpand);
  }

  toggleExpand = () => {
    const { id, isCollapsed, doExpand, doCollapse } = this.props;

    if (!this.canToggleExpand) return;

    if (isCollapsed) doExpand(id);
    else doCollapse(id);
  };

  render() {
    const { jobPhase, startDate, endDate, isCollapsed } = this.props;

    return (
      <Wrapper
        onClick={this.toggleExpand}
        className={this.canToggleExpand ? "canToggle" : ""}
      >
        {!this.canToggleExpand ? (
          <Spacer w={45} />
        ) : (
          <Expander
            style={{ width: 45, height: "100%" }}
            isExpanded={!isCollapsed}
            title={isCollapsed ? "Expand Item" : "Collapse Item"}
          />
        )}
        <JobTimelineGroupUnderline>
          <JobPhaseName>{getJobPhaseName(jobPhase)}</JobPhaseName>
          <DateCell>
            {startDate && formatDate(startDate, DATE_FORMAT_DATE_SHORT_MONTH)}
          </DateCell>
          <DateCell>
            {endDate && formatDate(endDate, DATE_FORMAT_DATE_SHORT_MONTH)}
          </DateCell>
        </JobTimelineGroupUnderline>
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelinePhaseGroup);

const Wrapper = styled(Flex)`
  position: relative;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  align-items: center;
  background-color: white;
  overflow: hidden;
  &.canToggle {
    cursor: pointer;
  }
`;

export const JobPhaseName = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-charcoal);
`;
