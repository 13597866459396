import numeral from "numeral";
import { useSelector } from "react-redux";
import {
  getJobExchangeRate,
  getRateCardId
} from "../../../lib/entities/jobEntity";
import { getBillableRate, getRoleId } from "../../../lib/entities/userEntity";
import { selectJob } from "../job";
import { selectRoleRateByRoleIdRateCardId } from "../rate/selectRoleRateByRoleIdRateCardId";
import { selectUser } from "./index";

export const selectUserSellRate = (state, { userId, jobId }) => {
  const user = selectUser(state, { userId });
  const job = selectJob(state, { jobId });
  const roleId = getRoleId(user);

  if (roleId) {
    return selectRoleRateByRoleIdRateCardId(state, {
      roleId,
      rateCardId: getRateCardId(job)
    });
  }

  return numeral(getBillableRate(user))
    .multiply(numeral(getJobExchangeRate(job)).value())
    .value();
};

export const useUserSellRate = (userId, jobId) =>
  useSelector(state =>
    selectUserSellRate(state, {
      userId,
      jobId
    })
  );
