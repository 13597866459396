import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_DETAILS_SET_CURRENT_FOCUS } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { IconButton } from "../../elements/Button";
import AddCircleIcon from "../../elements/Icons/AddCircleIcon";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";

const MenuActionsButton = ({ openMenu, actionMenuRef }) => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const click = () => {
    if (!isJobEditable) return;
    openMenu();
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL
        }
      })
    );
  };

  return (
    <Container ref={actionMenuRef} onClick={click} tabIndex={-1}>
      <AddCircleIcon size={null} />
    </Container>
  );
};

MenuActionsButton.propTypes = {
  openMenu: PropTypes.func.isRequired,
  actionMenuRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired
};

export default MenuActionsButton;

const Container = styled(IconButton)`
  position: absolute;
  left: -37px;
  top: 5px;
  color: var(--color-gray);
  &:hover {
    color: var(--color-gray-medium);
  }
`;
