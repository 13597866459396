import PropTypes from "prop-types";
import React from "react";
import styled, { css, keyframes } from "styled-components";

import { getName } from "../../../lib/entities/labelEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { useLabel } from "../../../redux/selectors/labels";
import { IconButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";
import { getLabelId, Label } from "./styles";

const ActiveLabel = ({ labelId, onDelete, readOnly }) => {
  const label = useLabel(labelId);

  return (
    <StyledLabel id={getLabelId(labelId)}>
      <span>{getName(label)}</span>
      {!readOnly && (
        <StyledIconButton onClick={() => onDelete(labelId)}>
          <ClearIcon size={12}></ClearIcon>
        </StyledIconButton>
      )}
    </StyledLabel>
  );
};

ActiveLabel.propTypes = {
  labelId: entityIdType.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ActiveLabel.defaultProps = {
  readOnly: true,
};

export default ActiveLabel;

const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-25px);
    }
    60% {
        transform: translateY(-8px);
    }
`;

const bounceAnimation = css`
  animation: ${bounce} 1.25s ease;
`;

const StyledLabel = styled(Label)`
  background-color: white;

  .bouncing {
    ${bounceAnimation}
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 15px;
  height: 15px;
  margin-left: 4px;
`;
