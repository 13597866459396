/**
 * https://draftjs.org/
 * https://www.draft-js-plugins.com/
 */
import Editor from "@draft-js-plugins/editor";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  RichUtils,
  convertFromRaw,
  getDefaultKeyBinding,
} from "draft-js";
import {
  onDraftEditorCopy,
  onDraftEditorCut,
  handleDraftEditorPastedText,
} from "draftjs-conductor";
import draftToHtml from "draftjs-to-html";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { REGEX_HTML_INS_TAGS } from "../../../lib/constants";
import { reactNodesType } from "../../../lib/types/reactTypes";
import DraftJsContent from "./DraftJsContent";
import {
  getMentionUserIdsFromRawContent,
  parseMentionEntities,
  transformMentionEntityToHtml,
} from "./plugins/mentionPlugin";

const transformBlocksForHtml = (blocks) =>
  blocks.map((block) => {
    if (block.type === "code-block") return { ...block, type: "code" };

    // Use a zero-width character to prevent empty lines being removed
    if (block.type === "unstyled" && block.text.trim().length === 0)
      return { ...block, text: "\u200C" };

    return block;
  });

const transformEntityForHtml = (entity, text) => {
  if (entity.type === "LINK")
    return `<a href="${entity.data.url}" target="_blank">${text}</a>`;

  if (entity.type === "mention") return transformMentionEntityToHtml(entity);

  return null;
};

const convertRawContentToHtml = ({ blocks, entityMap }, htmlParser) => {
  const rawContent = {
    blocks: transformBlocksForHtml(blocks),
    entityMap,
  };
  return htmlParser(
    draftToHtml(rawContent, null, false, transformEntityForHtml)
  ).trim();
};

const convertHtmlToRawContent = (html) => {
  const rawContent = convertToRaw(
    ContentState.createFromBlockArray(convertFromHTML(html))
  );

  parseMentionEntities(rawContent);

  return rawContent;
};

const convertHtmlToEditorState = (html) => {
  if (!html) return EditorState.createEmpty();

  return EditorState.createWithContent(
    convertFromRaw(convertHtmlToRawContent(html))
  );
};

export const defaultHtmlParser = (html) =>
  html.replace(REGEX_HTML_INS_TAGS, "<$1u>");

const styleMap = {
  CODE: {
    fontFamily: "monospace",
    backgroundColor: "#ebebeb",
    borderRadius: "2px",
    padding: "2px 4px",
  },
};

class DraftJsEditor extends React.PureComponent {
  ref = React.createRef();

  ignoreNextChangeEvent = false;

  static propTypes = {
    children: reactNodesType,
    // eslint-disable-next-line react/forbid-prop-types
    plugins: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultHtml: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    htmlParser: PropTypes.func,
    saveOnEnter: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    defaultHtml: null,
    onFocus: null,
    onBlur: null,
    placeholder: "Start typing...",
    htmlParser: defaultHtmlParser,
    saveOnEnter: null,
  };

  constructor(props) {
    super(props);

    const tabPlugin = () => ({
      keyBindingFn: this.tabKeyBindingFn,
    });

    const enterPlugin = () => ({
      keyBindingFn: this.enterKeyBindingFn,
    });

    this.plugins = [...props.plugins, tabPlugin()];

    if (props.saveOnEnter) {
      this.plugins.push(enterPlugin());
    }

    this.state = {
      prevHtml: "",
      editorState: convertHtmlToEditorState(props.defaultHtml),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { defaultHtml } = props;

    if (defaultHtml === state.prevHtml) return state;

    return {
      ...state,
      prevHtml: defaultHtml,
      editorState: convertHtmlToEditorState(defaultHtml),
    };
  }

  handleChange = (editorState, editor, regenerateState = false) => {
    if (this.ignoreNextChangeEvent) {
      this.ignoreNextChangeEvent = false;
      return;
    }

    const { onChange, htmlParser } = this.props;
    const currentContent = editorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    const html = currentContent.hasText()
      ? convertRawContentToHtml(rawContent, htmlParser)
      : "";
    const mentionUserIds = getMentionUserIdsFromRawContent(rawContent);

    onChange(html, mentionUserIds);

    if (regenerateState) {
      this.ignoreNextChangeEvent = true;
      this.setState({
        editorState: convertHtmlToEditorState(html),
      });
    } else {
      this.setState({
        editorState,
      });
    }
  };

  handleFocus = (e) => {
    const { onFocus } = this.props;

    onFocus && onFocus(e);
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    onBlur && onBlur(e);
  };

  handlePastedText = (text, html, editorState, editor) => {
    const newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      this.handleChange(newState, editor, true);
      return true;
    }

    return false;
  };

  tabKeyBindingFn = (e, { getEditorState, setEditorState }) => {
    if (e.keyCode !== 9) return;

    e.preventDefault();

    const editorState = getEditorState();

    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    if (
      blockType === "unordered-list-item" ||
      blockType === "ordered-list-item"
    ) {
      setEditorState(RichUtils.onTab(e, editorState, 3));
    }
  };

  enterKeyBindingFn = (e, { getEditorState, setEditorState }) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      return "save_on_enter_command";
    }
    return getDefaultKeyBinding(e);
  };

  handleKeyCommand = (command, editorState) => {
    if (command === "save_on_enter_command") {
      const { saveOnEnter } = this.props;
      saveOnEnter();
      return "handled";
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.handleChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  focus = () => {
    const { editorState } = this.state;
    if (!editorState.getSelection().getHasFocus())
      window.requestAnimationFrame(() => {
        this.ref.current.focus();
      });
  };

  render() {
    const { placeholder, children } = this.props;
    const { editorState } = this.state;

    return (
      <Wrapper>
        <DraftJsContent onClick={this.focus}>
          <Editor
            editorState={editorState}
            customStyleMap={styleMap}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onCopy={onDraftEditorCopy}
            onCut={onDraftEditorCut}
            handlePastedText={this.handlePastedText}
            handleKeyCommand={this.handleKeyCommand}
            plugins={this.plugins}
            spellCheck
            placeholder={placeholder}
            ref={this.ref}
          />
          {children}
        </DraftJsContent>
      </Wrapper>
    );
  }
}

export default DraftJsEditor;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
