import styled from "styled-components";

const PageBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px 50px;
  overflow-y: auto;
  background-color: var(--color-gray-light);
  color: black;
`;

export default PageBody;
