import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { QUOTE_FETCH_REQUEST } from "../../lib/constants";
import createAction from "../../redux/helpers/createAction";
import {
  selectQuoteByQuoteId,
  selectQuoteUi,
} from "../../redux/selectors/quoteSelectors";
import LoadingSpinner from "../elements/LoadingSpinner";
import PageWrapper from "../elements/PageWrapper";
import BranchDetails from "../modules/CommercialDocuments/BranchDetails";
import QuoteContextProvider from "../modules/CommercialDocuments/CommercialDocumentContext/QuoteContextProvider";
import CompanyDetails from "../modules/CommercialDocuments/CompanyDetails";
import DetailTable from "../modules/CommercialDocuments/DetailTable";
import DocumentIntro from "../modules/CommercialDocuments/DocumentIntro";
import DocumentJobNumberRow from "../modules/CommercialDocuments/DocumentJobNumberRow";
import DocumentName from "../modules/CommercialDocuments/DocumentName";
import DocumentPurchaseOrderNumberRow from "../modules/CommercialDocuments/DocumentPurchaseOrderNumberRow";
import DocumentTerms from "../modules/CommercialDocuments/DocumentTerms";
import { useCommercialDocumentEditing } from "../modules/CommercialDocuments/hooks/useCommercialDocumentEditing";
import LanguageOption from "../modules/CommercialDocuments/LanguageOption";
import DocumentLabel from "../modules/CommercialDocuments/Layout/DocumentLabel";
import PageBody from "../modules/CommercialDocuments/Layout/PageBody";
import PageContent from "../modules/CommercialDocuments/Layout/PageContent";
import Section from "../modules/CommercialDocuments/Layout/Section";
import UndoMerge from "../modules/CommercialDocuments/UndoMerge";
import { useParamEntityId } from "../router/useParamEntityId";
import QuoteAcceptance from "./QuoteAcceptance";
import QuoteDateRow from "./QuoteDateRow";
import QuoteLineItems from "./QuoteLineItems";
import QuoteNumberRow from "./QuoteNumberRow";
import QuoteTeamMembers from "./QuoteTeamMembers";
import QuoteTotals from "./QuoteTotals";

function QuotePage() {
  const entityId = useParamEntityId();

  const pending = useSelector(selectQuoteUi).pending;
  const quote = useSelector((state) =>
    selectQuoteByQuoteId(state, { quoteId: entityId })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction(QUOTE_FETCH_REQUEST, { quoteId: entityId }));
  }, [entityId]);

  const { focusWithinProps } = useCommercialDocumentEditing();

  return (
    <PageWrapper {...focusWithinProps}>
      {pending && <LoadingSpinner />}
      {!pending && quote && (
        <PageBody>
          <PageContent>
            <QuoteContextProvider key={entityId} id={entityId}>
              <UndoMerge />
              <Section>
                <DocumentLabel>
                  <LanguageOption optionKey="quote" />
                </DocumentLabel>
              </Section>
              <Section justify="space-between">
                <BranchDetails />
                <CompanyDetails />
              </Section>
              <Section>
                <DetailTable>
                  <DocumentJobNumberRow />
                  <QuoteNumberRow />
                  <DocumentPurchaseOrderNumberRow />
                  <QuoteDateRow />
                </DetailTable>
              </Section>
              <Section direction="column">
                <DocumentName />
                <DocumentIntro />
              </Section>
              <QuoteLineItems />
              <QuoteTotals quoteId={entityId} />
              <QuoteTeamMembers id={entityId} />
              <QuoteAcceptance id={entityId} />
              <DocumentTerms />
            </QuoteContextProvider>
          </PageContent>
        </PageBody>
      )}
    </PageWrapper>
  );
}

export default QuotePage;
