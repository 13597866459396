import { ChangeEvent, FocusEvent, forwardRef, useState } from "react";
import { mergeProps } from "react-aria";

import { limitDecimalPlaces, roundDecimalPlaces } from "../../lib";
import { BaseInput, BaseInputProps } from "./BaseInput";

export interface NumericInputProps extends BaseInputProps {
  precision?: number;
}

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
  ({ precision = 2, ...props }, ref) => {
    const [isPaste, setIsPaste] = useState(false);

    function modifyValue(value: string) {
      const isNegative = value.charAt(0) === "-";
      value = value.replace(/[^0-9.]/g, ""); //.replace(/^0+(?!$)/g, "");

      if (value?.length > 0) {
        const split = value.split(".");

        if (precision == 0) {
          value = split[0];
        } else if (split.length > 2) {
          value = value.slice(0, split[0].length + split[1].length + 1);
        }

        if (isPaste) {
          value = roundDecimalPlaces(value, precision);
        } else {
          value = limitDecimalPlaces(value, precision);
        }
      }

      value = value.replace(/^0+/, "0");

      return `${isNegative ? "-" : ""}${value}`;
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
      event.target.value = modifyValue(event.target.value);
    }

    function onPaste() {
      setIsPaste(true);
      requestAnimationFrame(() => {
        setIsPaste(false);
      });
    }

    function onFocus(event: FocusEvent<HTMLInputElement>) {
      if (event.target.value === "0") {
        requestAnimationFrame(() => {
          event.target.setSelectionRange(1, 1);
        });
      }
    }

    const inputProps: BaseInputProps = { onChange, onPaste, onFocus };

    return <BaseInput ref={ref} {...mergeProps(inputProps, props)} />;
  }
);

NumericInput.displayName = "NumericInput";
