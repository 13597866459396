import * as PropTypes from "prop-types";
import { usePermissions } from "st-shared/stores";

import { useActivityEntriesByJobIdDate } from "../../../../redux/selectors/jobActivitySelectors";
import { useJobId } from "../../context/JobIdContext";
import JobActivityDateSeparator from "./JobActivityDateSeparator";
import JobActivityEntry from "./JobActivityEntry";

const JobActivityDateEntries = ({ date }) => {
  const jobId = useJobId();
  const { canEditJobs } = usePermissions();
  const activityEntries = useActivityEntriesByJobIdDate(jobId, date);

  if (activityEntries.length === 0) return null;

  return (
    <>
      {activityEntries.map((activityEntry) => (
        <JobActivityEntry
          key={activityEntry.id}
          activityEntry={activityEntry}
          canEditJobs={canEditJobs}
          jobId={jobId}
        />
      ))}
      <JobActivityDateSeparator key={date} date={date} />
    </>
  );
};

JobActivityDateEntries.propTypes = {
  date: PropTypes.string.isRequired,
};

export default JobActivityDateEntries;
