import * as PropTypes from "prop-types";
import { entityIdType } from "../types/entityTypes";
import {
  commercialDocumentLineItemShape,
  documentLineItemMethods
} from "../ui/commercialDocumentLineItems";

export const invoiceLineItemShape = {
  ...commercialDocumentLineItemShape,
  accountCode: PropTypes.string,
  invoiceId: entityIdType.isRequired
};

export const invoiceLineItemEntityType = PropTypes.shape(invoiceLineItemShape);

export const getInvoiceId = ({ invoiceId }) => invoiceId;

export const getAccountCode = ({ accountCode }) => accountCode;

export const invoiceLineItemMethods = {
  ...documentLineItemMethods
};
