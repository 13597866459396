import { InputAdornment, ThemeProvider } from "@material-ui/core";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import * as PropTypes from "prop-types";
import React from "react";
import pickersTheme from "../../../lib/themes/pickers";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";

export const createInputProps = ({
  className = "",
  iconSize,
  ...props
} = {}) => ({
  disableUnderline: true,
  placeholder: "No date set",
  className,
  inputProps: {
    className
  },
  startAdornment: (
    <InputAdornment position="start" className={className}>
      <DatePickerIcon size={iconSize} />
    </InputAdornment>
  ),
  ...props
});

const InlineDatePicker = ({ InputProps, ...props }) => (
  <ThemeProvider theme={pickersTheme}>
    <MuiDatePicker
      autoOk
      disableToolbar
      variant="inline"
      leftArrowButtonProps={{ size: "small" }}
      rightArrowButtonProps={{ size: "small" }}
      InputProps={InputProps}
      {...props}
    />
  </ThemeProvider>
);

InlineDatePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.objectOf(PropTypes.any)
};

InlineDatePicker.defaultProps = {
  InputProps: createInputProps()
};

export default InlineDatePicker;
