import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectUnplannedTimeSummary } from "./unplannedTimeSummary";

export const selectHasUnplannedTime = createSelector(
  [selectUnplannedTimeSummary],
  unplannedTimeSummary => unplannedTimeSummary.length > 0
);

export const useHasUnplannedTime = () => useSelector(selectHasUnplannedTime);
