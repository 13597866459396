import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobPhaseName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobPhaseCreated = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" created the phase "}
    <b>{getMetaDataJobPhaseName(activityEntry)}</b>
  </SystemMessage>
);

JobPhaseCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobPhaseCreated;
