import { JobListModelFilterResultsReceivedAction } from "../../entities/jobListModel/actions";
import {
  ScheduleUiAvailabilityViewsType,
  ScheduleUiTodoVisibilityOptionsType,
  ScheduleUiTotalsModesType,
  ScheduleUiViewModesType,
} from "./types";

export const SCHEDULE_LOGGED_TIMES_FETCH_REQUEST =
  "scheduleLoggedTimes/fetch/request";
export const SCHEDULE_LOGGED_TIMES_PURGE_FETCH_REQUEST =
  "scheduleLoggedTimes/purge/fetch/request";
export const SCHEDULE_LOGGED_TIMES_FETCH_SUCCESS =
  "scheduleLoggedTimes/fetch/success";
export const SCHEDULE_LOGGED_TIMES_FETCH_ERROR =
  "scheduleLoggedTimes/fetch/error";
export const SCHEDULE_LOGGED_TIMES_PURGE = "scheduleLoggedTimes/purge";
export const SCHEDULE_SET_VIEW_MODE = "schedule/set/viewMode";
export const SCHEDULE_SET_AVAILABILITY_VIEW = "schedule/set/availabilityView";
export const SCHEDULE_SET_VIEW_PAUSED_LOGGED_TIMES =
  "schedule/set/viewPausedLoggedTimes";
export const SCHEDULE_SET_TODO_VISIBILITY_OPTIONS =
  "schedule/set/todoVisibilityOptions";
export const SCHEDULE_SET_SCHEDULE_TOTALS_MODE =
  "schedule/set/scheduleTotalsMode";
export const SCHEDULE_SET_SEARCH_QUERY = "schedule/set/searchQuery";
export const SCHEDULE_FILTER_IS_SEARCHING = "schedule/filterIsSearching";
export const SCHEDULE_FILTER_TEAM_MEMBERS_RECEIVED =
  "schedule/filterTeamMembersReceived";
export const SCHEDULE_FILTER_COUNT = "schedule/filterCount";
export const SCHEDULE_HAS_NO_ACTIVE_JOBS = "schedule/hasNoActiveJobs";
export const SCHEDULE_ZOOM_IN = "schedule/zoomIn";
export const SCHEDULE_ZOOM_OUT = "schedule/zoomOut";
export const SCHEDULE_SIDEBAR_HOVER = "schedule/sidebarHover";

export type ScheduleUiAction =
  | ScheduleLoggedTimeFetchRequestAction
  | ScheduleLoggedTimeFetchSuccessAction
  | ScheduleLoggedTimeFetchErrorAction
  | ScheduleLoggedTimePurgeFetchRequestAction
  | ScheduleLoggedTimePurgeAction
  | JobListModelFilterResultsReceivedAction
  | ScheduleSetViewModeAction
  | ScheduleSetAvailabilityViewAction
  | ScheduleSetViewPausedLoggedTimesAction
  | ScheduleSetTodoVisibilityOptionsAction
  | ScheduleSetScheduleTotalsModeAction
  | ScheduleSetSearchQueryAction
  | ScheduleFilterIsSearchingAction
  | ScheduleFilterTeamMembersReceivedAction
  | ScheduleFilterCountAction
  | ScheduleHasNoActiveJobsAction
  | ScheduleZoomInAction
  | ScheduleZoomOutAction
  | ScheduleSidebarHoverAction;

export type ScheduleLoggedTimeFetchRequestAction = {
  type: typeof SCHEDULE_LOGGED_TIMES_FETCH_REQUEST;
  startDate: string;
  endDate: string;
  userIds: number[];
};

export function actionScheduleLoggedTimeFetchRequest(
  startDate: string,
  endDate: string,
  userIds: number[]
): ScheduleLoggedTimeFetchRequestAction {
  return {
    type: SCHEDULE_LOGGED_TIMES_FETCH_REQUEST,
    startDate,
    endDate,
    userIds,
  };
}

export type ScheduleLoggedTimePurgeFetchRequestAction = {
  type: typeof SCHEDULE_LOGGED_TIMES_PURGE_FETCH_REQUEST;
  startDate: string;
  endDate: string;
  userIds: number[];
};

export function actionScheduleLoggedTimePurgeFetchRequest(
  startDate: string,
  endDate: string,
  userIds: number[]
): ScheduleLoggedTimePurgeFetchRequestAction {
  return {
    type: SCHEDULE_LOGGED_TIMES_PURGE_FETCH_REQUEST,
    startDate,
    endDate,
    userIds,
  };
}

export type ScheduleLoggedTimePurgeAction = {
  type: typeof SCHEDULE_LOGGED_TIMES_PURGE;
};

export function actionScheduleLoggedTimePurge(): ScheduleLoggedTimePurgeAction {
  return {
    type: SCHEDULE_LOGGED_TIMES_PURGE,
  };
}

export type ScheduleLoggedTimeFetchSuccessAction = {
  type: typeof SCHEDULE_LOGGED_TIMES_FETCH_SUCCESS;
  startDate: string;
  endDate: string;
  userIds: number[];
};

export function actionScheduleLoggedTimeFetchSuccess(
  startDate: string,
  endDate: string,
  userIds: number[]
): ScheduleLoggedTimeFetchSuccessAction {
  return {
    type: SCHEDULE_LOGGED_TIMES_FETCH_SUCCESS,
    startDate,
    endDate,
    userIds,
  };
}

export type ScheduleLoggedTimeFetchErrorAction = {
  type: typeof SCHEDULE_LOGGED_TIMES_FETCH_ERROR;
  startDate: string;
  endDate: string;
  userIds: number[];
  error: unknown;
};

export function actionScheduleLoggedTimeFetchError(
  startDate: string,
  endDate: string,
  userIds: number[],
  error: unknown
): ScheduleLoggedTimeFetchErrorAction {
  return {
    type: SCHEDULE_LOGGED_TIMES_FETCH_ERROR,
    startDate,
    endDate,
    userIds,
    error,
  };
}

export type ScheduleSetViewModeAction = {
  type: typeof SCHEDULE_SET_VIEW_MODE;
  viewMode: ScheduleUiViewModesType;
};

export function actionScheduleSetViewMode(
  viewMode: ScheduleUiViewModesType
): ScheduleSetViewModeAction {
  return {
    type: SCHEDULE_SET_VIEW_MODE,
    viewMode,
  };
}

export type ScheduleSetAvailabilityViewAction = {
  type: typeof SCHEDULE_SET_AVAILABILITY_VIEW;
  availabilityView: ScheduleUiAvailabilityViewsType;
};

export function actionScheduleSetAvailabilityView(
  availabilityView: ScheduleUiAvailabilityViewsType
): ScheduleSetAvailabilityViewAction {
  return {
    type: SCHEDULE_SET_AVAILABILITY_VIEW,
    availabilityView,
  };
}

export type ScheduleSetViewPausedLoggedTimesAction = {
  type: typeof SCHEDULE_SET_VIEW_PAUSED_LOGGED_TIMES;
  viewPausedLoggedTimes: boolean;
};

export function actionScheduleSetViewPausedLoggedTimes(
  viewPausedLoggedTimes: boolean
): ScheduleSetViewPausedLoggedTimesAction {
  return {
    type: SCHEDULE_SET_VIEW_PAUSED_LOGGED_TIMES,
    viewPausedLoggedTimes,
  };
}

export type ScheduleSetTodoVisibilityOptionsAction = {
  type: typeof SCHEDULE_SET_TODO_VISIBILITY_OPTIONS;
  todoVisibilityOptions: ScheduleUiTodoVisibilityOptionsType[];
};

export function actionScheduleSetTodoVisibilityOptions(
  todoVisibilityOptions: ScheduleUiTodoVisibilityOptionsType[]
): ScheduleSetTodoVisibilityOptionsAction {
  return {
    type: SCHEDULE_SET_TODO_VISIBILITY_OPTIONS,
    todoVisibilityOptions,
  };
}

export type ScheduleSetScheduleTotalsModeAction = {
  type: typeof SCHEDULE_SET_SCHEDULE_TOTALS_MODE;
  scheduleTotalsMode: ScheduleUiTotalsModesType;
};

export function actionScheduleSetScheduleTotalsMode(
  scheduleTotalsMode: ScheduleUiTotalsModesType
): ScheduleSetScheduleTotalsModeAction {
  return {
    type: SCHEDULE_SET_SCHEDULE_TOTALS_MODE,
    scheduleTotalsMode,
  };
}

export type ScheduleSetSearchQueryAction = {
  type: typeof SCHEDULE_SET_SEARCH_QUERY;
  searchQuery: string;
};

export function actionScheduleSetSearchQuery(
  searchQuery: string
): ScheduleSetSearchQueryAction {
  return {
    type: SCHEDULE_SET_SEARCH_QUERY,
    searchQuery,
  };
}

export type ScheduleFilterIsSearchingAction = {
  type: typeof SCHEDULE_FILTER_IS_SEARCHING;
  isSearching: boolean;
};

export function actionScheduleFilterIsSearching(
  isSearching: boolean
): ScheduleFilterIsSearchingAction {
  return {
    type: SCHEDULE_FILTER_IS_SEARCHING,
    isSearching,
  };
}

export type ScheduleFilterTeamMembersReceivedAction = {
  type: typeof SCHEDULE_FILTER_TEAM_MEMBERS_RECEIVED;
  filteredTeamMembers: number[];
};

export function actionScheduleFilterTeamMembersReceived(
  filteredTeamMembers: number[]
): ScheduleFilterTeamMembersReceivedAction {
  return {
    type: SCHEDULE_FILTER_TEAM_MEMBERS_RECEIVED,
    filteredTeamMembers,
  };
}

export type ScheduleFilterCountAction = {
  type: typeof SCHEDULE_FILTER_COUNT;
  filterCount: number;
};

export function actionScheduleFilterCount(
  filterCount: number
): ScheduleFilterCountAction {
  return {
    type: SCHEDULE_FILTER_COUNT,
    filterCount,
  };
}

export type ScheduleHasNoActiveJobsAction = {
  type: typeof SCHEDULE_HAS_NO_ACTIVE_JOBS;
  hasNoActiveJobs: boolean;
};

export function actionScheduleHasNoActiveJobs(
  hasNoActiveJobs: boolean
): ScheduleHasNoActiveJobsAction {
  return {
    type: SCHEDULE_HAS_NO_ACTIVE_JOBS,
    hasNoActiveJobs,
  };
}

export type ScheduleZoomInAction = {
  type: typeof SCHEDULE_ZOOM_IN;
};

export function actionScheduleZoomIn(): ScheduleZoomInAction {
  return {
    type: SCHEDULE_ZOOM_IN,
  };
}

export type ScheduleZoomOutAction = {
  type: typeof SCHEDULE_ZOOM_OUT;
};

export function actionScheduleZoomOut(): ScheduleZoomOutAction {
  return {
    type: SCHEDULE_ZOOM_OUT,
  };
}

export type ScheduleSidebarHoverAction = {
  type: typeof SCHEDULE_SIDEBAR_HOVER;
  hover: boolean;
};

export function actionScheduleSidebarHover(
  hover: boolean
): ScheduleSidebarHoverAction {
  return {
    type: SCHEDULE_SIDEBAR_HOVER,
    hover,
  };
}
