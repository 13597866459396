import { JOB_TIMELINE_ITEM_ROW_HEIGHT } from "../../../lib/constants";

export interface ScheduleUiState {
  pending: boolean;
  error?: unknown | null;
  isSearching: boolean;
  isFetching: boolean;
  fetchedLoggedTimes: {
    datesByUser: { [key: number]: string[] };
    usersByDate: { [key: string]: number[] };
  };
  fetchingLoggedTimes: {
    datesByUser: { [key: number]: string[] };
    usersByDate: { [key: string]: number[] };
  };
  requestedLoggedTimes: {
    datesByUser: { [key: number]: string[] };
    usersByDate: { [key: string]: number[] };
  };
  activeBlockKey: number | null;
  splittingBlockKey: number | null;
  splitBlockDays: number | null;
  viewMode: ScheduleUiViewModesType;
  availabilityView: ScheduleUiAvailabilityViewsType;
  viewPausedLoggedTimes: boolean;
  filteredTeamMembers: number[];
  filterCount: number;
  hasNoActiveJobs: boolean;
  todoVisibilityOptions: ScheduleUiTodoVisibilityOptionsType[];
  scheduleTotalsMode: ScheduleUiTotalsModesType;
  zoomLevel: number;
  searchQuery: string;
  sidebarHover: boolean;
}

export const ScheduleUi = {
  Zoom: {
    Levels: [0, 1],
    Ratios: [0.4, 1],
    MinLevel: 0,
    MaxLevel: 1,
    Default: 1,
  },
  Block: {
    HourHeight: 20,
    SnapIntervalHours: 0.25,
    MaxHours: 24,
    ContentLineHeight: 15,
    ContentPaddingY: 4,
    ExtraHeight: 24,
  },
  User: {
    AvailabilityRowHeight: 50,
    AvailabilityCompactRowHeight: 4,
    RowCollapsedHeight: 50,
    AvailabilityLevels: [0, 1, 2, 3, 4, 5, 6],
    JobRowHeight: 50,
    JobEmptyRowHeight: 80,
  },
  Job: {
    RowHeight: 60,
    EmptyItemsRowHeight: 60,
    CompactRowHeight: 50,
    LoaderHeight: JOB_TIMELINE_ITEM_ROW_HEIGHT,
  },
  View: {
    HeaderHeight: 52,
    DatesRowHeight: 25,
    ContentTooltipReserveSpace: 200,
    GroupColumnWidth: 360,
    GroupTotalsWidth: 100,
  },
  DayWidth: 100,
  DaysInAWeek: 7,
  FetchDebounce: 200,
  PurgeIdleTime: 300_000,
  PurgeThreshold: 1500,
  SplittingBlockKeyGlobal: -9999,
} as const;

export const ScheduleUiAvailabilityViews = {
  Hours: 1,
  Percentage: 2,
} as const;

export type ScheduleUiAvailabilityViewsType =
  (typeof ScheduleUiAvailabilityViews)[keyof typeof ScheduleUiAvailabilityViews];

export const ScheduleUiTodoVisibilityOptions = {
  Notes: 1,
  JobItemName: 2,
  JobName: 3,
  JobPhaseName: 4,
} as const;

export type ScheduleUiTodoVisibilityOptionsType =
  (typeof ScheduleUiTodoVisibilityOptions)[keyof typeof ScheduleUiTodoVisibilityOptions];

export const ScheduleUiBlockFilterModes = {
  UserTodos: "UserTodos",
  UserJobTodos: "UserJobTodos",
  UserJobNoItemTodos: "UserJobNoItemTodos",
  JobItemUserTodos: "JobItemUserTodos",
} as const;

export type ScheduleUiBlockFilterModesType =
  (typeof ScheduleUiBlockFilterModes)[keyof typeof ScheduleUiBlockFilterModes];

export const ScheduleUiTotalsModes = {
  UsedScheduledPlannedHours: 1,
  UsedScheduledPlannedMoney: 2,
  ScheduledRemainingHours: 3,
  ScheduledRemainingMoney: 4,
} as const;

export type ScheduleUiTotalsModesType =
  (typeof ScheduleUiTotalsModes)[keyof typeof ScheduleUiTotalsModes];

export const ScheduleUiViewModes = {
  Todos: 1,
  JobsByPeople: 2,
  ItemsByJobs: 3,
  PeopleByJobs: 4,
} as const;

export type ScheduleUiViewModesType =
  (typeof ScheduleUiViewModes)[keyof typeof ScheduleUiViewModes];
