import { put, select, takeLatest } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_PAINT_CANCEL,
  SCHEDULE_BLOCK_PAINT_START,
  SCHEDULE_BLOCK_PAINT_START_SAGA,
} from "../../../lib/constants";
import {
  getJobItemName,
  getJobPhaseId,
} from "../../../lib/entities/jobItemEntity";
import { getJobItemUserStatusId } from "../../../lib/entities/jobItemUserEntity";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import { SCHEDULE_LOGGED_TIME_ENTITY_DEFAULTS } from "../../../lib/entities/scheduleLoggedTimeEntity";
import {
  getCompanyName,
  getJobFullName,
  getJobStatusId,
} from "../../../lib/entities/scheduleSearchResultEntity";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* paintStart(action) {
  let jobFullName = "";
  let companyName = "";
  let jobStatusId = null;
  let jobItemName = "";
  let jobPhaseId = null;
  let jobPhaseName = "";
  let jobItemUserStatusId = null;

  try {
    const { blockKey, userId, jobId, jobItemId, jobItemUserId, date, hours } =
      action.payload;

    if (jobId) {
      const job = yield select(selectJobListModel, { jobId });
      jobFullName = getJobFullName(job);
      companyName = getCompanyName(job);
      jobStatusId = getJobStatusId(job);
    }

    if (jobItemId) {
      const jobItem = yield select(selectJobItemById, { id: jobItemId });
      jobItemName = getJobItemName(jobItem);
      jobPhaseId = getJobPhaseId(jobItem);
    }

    if (jobPhaseId) {
      const jobPhase = yield select(selectJobPhaseById, { id: jobPhaseId });
      jobPhaseName = getJobPhaseName(jobPhase);
    }

    if (jobItemUserId) {
      const jobItemUser = yield select(selectJobItemUserById, {
        id: jobItemUserId,
      });
      jobItemUserStatusId = getJobItemUserStatusId(jobItemUser);
    }

    yield put(
      createAction(SCHEDULE_BLOCK_PAINT_START, {
        ...action.payload,
        scheduleLoggedTime: {
          ...SCHEDULE_LOGGED_TIME_ENTITY_DEFAULTS,
          id: blockKey,
          date,
          minutes: hours * 60,
          scheduleBlockBaseLoggedTimeId: blockKey,
          userId,
          jobId,
          jobItemId,
          jobItemUserId,
          jobFullName,
          companyName,
          jobStatusId,
          jobItemName,
          jobPhaseId,
          jobPhaseName,
          jobItemUserStatusId,
        },
      })
    );
  } catch (error) {
    yield put(
      createAction(SCHEDULE_BLOCK_PAINT_CANCEL, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchPaintStart() {
  yield takeLatest([SCHEDULE_BLOCK_PAINT_START_SAGA], paintStart);
}
