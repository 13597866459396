import { all } from "redux-saga/effects";
import watchFetch from "./watchFetch";
import watchInvoiceAssignNumber from "./watchInvoiceAssignNumber";
import watchInvoiceSave from "./watchInvoiceSave";
import watchInvoiceSetAddress from "./watchInvoiceSetAddress";
import watchInvoiceSetContact from "./watchInvoiceSetContact";
import watchOpenCreateAddress from "./watchOpenCreateAddress";
import watchOpenCreateEditContact from "./watchOpenCreateEditContact";

export default function* invoiceSaga() {
  yield all([
    watchFetch(),
    watchInvoiceSave(),
    watchInvoiceSetAddress(),
    watchInvoiceSetContact(),
    watchOpenCreateEditContact(),
    watchOpenCreateAddress(),
    watchInvoiceAssignNumber()
  ]);
}
