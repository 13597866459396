import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";
import { getName } from "./CalendarEventCreationMode.utils";

export const CalendarEventCreationModeEnum = {
  AlwaysCreate: 1,
  NeverCreate: 2,
  SendToInbox: 3,
} as const;

export type TCalendarEventCreationModeEnum = ConstEnum<
  typeof CalendarEventCreationModeEnum
>;

export type TCalendarEventCreationMode =
  TEnumOption<TCalendarEventCreationModeEnum>;

export const CalendarEventCreationModeItems: {
  id: TCalendarEventCreationModeEnum;
  name: string;
}[] = [
  {
    id: CalendarEventCreationModeEnum.AlwaysCreate,
    name: getName(CalendarEventCreationModeEnum.AlwaysCreate),
  },
  {
    id: CalendarEventCreationModeEnum.NeverCreate,
    name: getName(CalendarEventCreationModeEnum.NeverCreate),
  },
  {
    id: CalendarEventCreationModeEnum.SendToInbox,
    name: getName(CalendarEventCreationModeEnum.SendToInbox),
  },
];
