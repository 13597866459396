import { EntityClasses } from "../../state/entities/types";
import { sendApplyModelData } from "../WebAppAPI";
import API from "./index";
import { createModelSetParser } from "./modelSets";

export const uploadBranchLogoApi = (branchId, formData) =>
  API.post(`/branches/${branchId}/logo`, formData)
    .then(({ data }) => {
      sendApplyModelData(EntityClasses.Branch, data);
      return data;
    })
    .then(createModelSetParser(EntityClasses.Branch))
    .then(({ data }) => data);
