import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const BatchActionStore = createSingleEntityStoreContext(
  EntityClass.BatchAction
);

export const BatchActionStoreProvider = createSingleEntityStoreProvider(
  EntityClass.BatchAction,
  BatchActionStore
);
