import { put, select, takeLatest } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_DRAG_CANCEL,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DRAG_END_SAGA,
} from "../../../lib/constants";
import {
  getJobItemName,
  getJobPhaseId,
} from "../../../lib/entities/jobItemEntity";
import { getJobItemUserStatusId } from "../../../lib/entities/jobItemUserEntity";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import {
  getCompanyName,
  getJobFullName,
} from "../../../lib/entities/scheduleSearchResultEntity";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* dragEnd(action) {
  try {
    const {
      userId,
      jobId,
      jobItemId,
      jobItemUserId,
      scheduleFilterMode,
      origin,
    } = action.payload;
    const changed = { userId };

    if (jobId) {
      const scheduleSearchResult = yield select(selectJobListModel, { jobId });
      const jobFullName = getJobFullName(scheduleSearchResult);
      const companyName = getCompanyName(scheduleSearchResult);
      Object.assign(changed, {
        jobId,
        jobFullName,
        companyName,
      });
    }

    if (jobItemId) {
      const jobItem = yield select(selectJobItemById, { id: jobItemId });
      const jobItemName = getJobItemName(jobItem);
      const jobPhaseId = getJobPhaseId(jobItem);
      Object.assign(changed, {
        jobItemId,
        jobItemName,
      });

      if (jobPhaseId) {
        const jobPhase = yield select(selectJobPhaseById, { id: jobPhaseId });
        const jobPhaseName = getJobPhaseName(jobPhase);
        Object.assign(changed, {
          jobPhaseId,
          jobPhaseName,
        });
      }
    }

    if (jobItemUserId) {
      const jobItemUser = yield select(selectJobItemUserById, {
        id: jobItemUserId,
      });
      const jobItemUserStatusId = getJobItemUserStatusId(jobItemUser);
      Object.assign(changed, {
        jobItemUserId,
        jobItemUserStatusId,
      });
    }

    if (
      origin.jobId &&
      (jobId !== origin.jobId ||
        scheduleFilterMode ===
          ScheduleUiBlockFilterModes.UserJobNoItemTodos)
    ) {
      Object.assign(changed, {
        jobItemId: null,
        jobItemName: "",
        jobPhaseId: null,
        jobPhaseName: "",
        jobItemUserId: null,
        jobItemUserStatusId: null,
      });
    }

    yield put(
      createAction(SCHEDULE_BLOCK_DRAG_END, {
        ...action.payload,
        changed,
      })
    );
  } catch (error) {
    yield put(
      createAction(SCHEDULE_BLOCK_DRAG_CANCEL, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchDragEnd() {
  yield takeLatest([SCHEDULE_BLOCK_DRAG_END_SAGA], dragEnd);
}
