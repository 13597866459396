import { all } from "redux-saga/effects";

import watchAddJobItemUser from "./watchAddJobItemUser";
import watchBlockDelete from "./watchBlockDelete";
import watchBlockBulkSave from "./watchBlockSaveBulk";
import watchDragEnd from "./watchDragEnd";
import watchDuplicateMultipleBlocks from "./watchDuplicateMultipleBlocks";
import watchExpandJob from "./watchExpandJob";
import watchLogMultipleBlocks from "./watchLogMultipleBlocks";
import watchMoveMultipleBlocks from "./watchMoveMultipleBlocks";
import watchOpenBlock from "./watchOpenBlock";
import watchOpenCreateBlock from "./watchOpenCreateBlock";
import watchOpenCreateJobItem from "./watchOpenCreateJobItem";
import watchPaintStart from "./watchPaintStart";
import watchReassignBlock from "./watchReassignBlock";
import watchSavedBlock from "./watchSavedBlock";
import watchSetScheduleFilterTeamMembers from "./watchSetScheduleFilterTeamMembers";
import watchShiftJobDuration from "./watchShiftJobDuration";
import watchSplitBlock from "./watchSplitBlock";
import watchUnlogMultipleBlocks from "./watchUnlogMultipleBlocks";
import watchUpdateBlock from "./watchUpdateBlock";

export default function* scheduleSaga() {
  yield all([
    watchUpdateBlock(),
    watchDuplicateMultipleBlocks(),
    watchLogMultipleBlocks(),
    watchUnlogMultipleBlocks(),
    watchOpenBlock(),
    watchOpenCreateBlock(),
    watchPaintStart(),
    watchDragEnd(),
    watchExpandJob(),
    watchSetScheduleFilterTeamMembers(),
    watchShiftJobDuration(),
    watchSplitBlock(),
    watchAddJobItemUser(),
    watchOpenCreateJobItem(),
    watchReassignBlock(),
    watchSavedBlock(),
    watchBlockDelete(),
    watchBlockBulkSave(),
    watchMoveMultipleBlocks(),
  ]);
}
