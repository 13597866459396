import { pluralise } from "../../../lib";
import { useCustomerCurrency } from "../../../stores";
import { getFilterName } from "../../../types";
import { boldText, systemDisplayCss } from "../Filter.css";
import { FilterDisplayTextProps, FilterProps } from "../types";
import { FilterDisplay } from "./FilterDisplay";

export function SystemFilter({ filterGroup, onDelete }: FilterProps) {
  return (
    <FilterDisplay
      onDelete={onDelete}
      filterGroup={filterGroup}
      className={systemDisplayCss}
    >
      <SystemFilterDisplayText filterGroup={filterGroup} />
    </FilterDisplay>
  );
}

export function SystemFilterDisplayText({
  filterGroup,
}: FilterDisplayTextProps) {
  const customerCurrency = useCustomerCurrency();

  return (
    <span className={boldText}>
      {filterGroup.filters.length}{" "}
      {pluralise(
        filterGroup.filters.length,
        getFilterName(filterGroup.filterGroupTypeId, customerCurrency)
      )}
    </span>
  );
}
