import { put, takeEvery } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_BULK_SAVE,
  SCHEDULE_BLOCK_UNLOG,
  SCHEDULE_BLOCK_UNLOG_MULTIPLE,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";

function* unlogBlocks(action) {
  const { selectedBlockKeys } = action.payload;
  for (let i = 0; i < selectedBlockKeys.length; i++) {
    const blockKey = selectedBlockKeys[i];
    yield put(
      createAction(SCHEDULE_BLOCK_UNLOG, {
        ...action.payload,
        blockKey,
        isBulkAction: true,
      })
    );
  }
  yield put(
    createAction(SCHEDULE_BLOCK_BULK_SAVE, {
      ...action.payload,
      sagaType: SCHEDULE_BLOCK_UNLOG,
    })
  );
}

function* watchUnlogMultipleBlocks() {
  yield takeEvery([SCHEDULE_BLOCK_UNLOG_MULTIPLE], unlogBlocks);
}

export default watchUnlogMultipleBlocks;
