import { IconBase, IconBaseProps } from "react-icons";

export function StActionRepeat(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "0 0 24 24" }} {...props}>
      <clipPath id="a">
        <path d="m0 0h24v24h-24z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path d="m12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26l1.46-1.46c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74-1.46 1.46c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
      </g>
    </IconBase>
  );
}
