import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobItemId } from "../../index";
import { selectJobItemPreviousJobItem } from "./previousJobItem";
import { selectJobItemUi } from "./index";

export const selectIsEditingJobItem = createCachedSelector(
  [selectJobItemUi, selectJobItemPreviousJobItem],
  (jobItem, previousJobItem) =>
    !!(jobItem && jobItem.isEditing) || previousJobItem !== null
)(selectPropsJobItemId);

export const useIsEditingJobItem = jobItemId =>
  useSelector(state => selectIsEditingJobItem(state, { jobItemId }));
