import type { ConstEnum, Nominal } from "@streamtimefe/types";

import type { TEntityId } from "../../core";

export type TEncodedSavedSegmentAccess = Nominal<
  string,
  "SavedSegmentAccess"
> | null;

export const SavedSegmentAccessTypeEnum = {
  View: 1,
  Edit: 2,
} as const;

export type TSavedSegmentAccessTypeEnum = ConstEnum<
  typeof SavedSegmentAccessTypeEnum
>;

export type TSavedSegmentAccess = {
  viewUserIds: TEntityId[];
  editUserIds: TEntityId[];
  viewBranchIds: TEntityId[];
  editBranchIds: TEntityId[];
};
