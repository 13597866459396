import PropTypes from "prop-types";
import React, { useRef } from "react";
import styled from "styled-components";
import { isDrawerContext } from "../context/isDrawerContext";
import JobActivityEntries from "./JobActivityEntries";
import JobActivityFooter from "./JobActivityFooter";
import JobActivityHeader from "./JobActivityHeader";

const JobActivity = ({ isDrawer }) => {
  const scrollRef = useRef();

  const scrollToBottom = () => {
    window.requestAnimationFrame(
      () =>
        scrollRef.current &&
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: "smooth"
        })
    );
  };

  return (
    <isDrawerContext.Provider value={isDrawer}>
      <Container>
        <JobActivityHeader />
        <JobActivityEntries ref={scrollRef} />
        <JobActivityFooter onScrollToBottom={scrollToBottom} />
      </Container>
    </isDrawerContext.Provider>
  );
};

JobActivity.propTypes = {
  isDrawer: PropTypes.bool
};

JobActivity.defaultProps = {
  isDrawer: false
};

export default JobActivity;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-jonsnow);
  position: relative;
  padding-top: 40px;
`;
