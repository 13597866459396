import { get } from "lodash-es";
import { useContext } from "react";
import { useSelector } from "react-redux";

import { ScheduleScrollContext } from "../../../../components/modules/ScrollContexts";
import {
  selectJobRowHeights,
  selectScheduleVisibleUserIds,
  selectUserRowHeights,
} from "../../../../redux/selectors/scheduleSelectors";
import { selectJobListModelsFilteredIds } from "../../../entities/jobListModel/selectors/selectJobListModelsFilteredIds";

export type ScheduleRow = { id: number; height: number; top: number };

export function rowIdGenerator(
  ids: number[],
  heightsById: { [key: number]: number },
  scrollOffsetY: number,
  viewportHeight: number
) {
  const rows: number[] = [];
  let currentOffsetY = -scrollOffsetY;

  ids.forEach((id) => {
    const height = get(heightsById, id);
    const top = currentOffsetY;
    const bottom = top + height;

    if (top <= viewportHeight && bottom >= 0) {
      rows.push(id);
    }

    currentOffsetY = bottom;
  });

  return rows;
}

export function rowGenerator(
  ids: number[],
  heightsById: { [key: number]: number },
  scrollOffsetY: number,
  viewportHeight: number
) {
  const rows: ScheduleRow[] = [];
  let currentOffsetY = -scrollOffsetY;

  ids.forEach((id) => {
    const height = get(heightsById, id);
    const top = currentOffsetY;
    const bottom = top + height;

    if (top <= viewportHeight && bottom >= 0) {
      rows.push({
        id,
        height,
        top,
      });
    }

    currentOffsetY = bottom;
  });

  return rows;
}

export function useScheduleRows(
  ids: number[],
  heightsById: { [key: number]: number }
) {
  const { scrollOffsetY, viewportHeight } = useContext(ScheduleScrollContext);
  return rowGenerator(ids, heightsById, scrollOffsetY, viewportHeight);
}

export function useUserScheduleRows() {
  const ids = useSelector(selectScheduleVisibleUserIds) as unknown as number[];
  const heightsById = useSelector(selectUserRowHeights) as {
    [key: number]: number;
  };
  return useScheduleRows(ids, heightsById);
}

export function useJobScheduleRows() {
  const ids = useSelector(selectJobListModelsFilteredIds);
  const heightsById = useSelector(selectJobRowHeights) as {
    [key: number]: number;
  };
  return useScheduleRows(ids, heightsById);
}
