import type {
  TBranch,
  TOrganisation,
  TUserPreference,
} from "@streamtimefe/entities";
import type { Entity, KeyEntity } from "st-shared/entities/Entity";
import type { ItemRate } from "st-shared/entities/ItemRate";
import type { Job } from "st-shared/entities/Job";
import type { JobItemRole } from "st-shared/entities/JobItemRole";
import type { JobListModel } from "st-shared/entities/JobListModel";
import type { MasterJobItem } from "st-shared/entities/MasterJobItem";
import type { MasterLoggedExpense } from "st-shared/entities/MasterLoggedExpense";
import type { ScheduleLoggedTime } from "st-shared/entities/ScheduleLoggedTime";

import {
  ENTITY_CLASS_ACTIVITY_ENTRY,
  ENTITY_CLASS_ADDRESS,
  ENTITY_CLASS_COMPANY,
  ENTITY_CLASS_CONTACT,
  ENTITY_CLASS_INVOICE,
  ENTITY_CLASS_INVOICE_LINE_ITEM,
  ENTITY_CLASS_INVOICE_PAYMENT,
  ENTITY_CLASS_JOB,
  ENTITY_CLASS_JOB_ITEM,
  ENTITY_CLASS_JOB_ITEM_DEPENDANCY,
  ENTITY_CLASS_JOB_ITEM_SUB_ITEM,
  ENTITY_CLASS_JOB_ITEM_USER,
  ENTITY_CLASS_JOB_MILESTONE,
  ENTITY_CLASS_JOB_PHASE,
  ENTITY_CLASS_LABEL,
  ENTITY_CLASS_LOGGED_EXPENSE,
  ENTITY_CLASS_ORGANISATION_ACCOUNTING_PREFERENCES,
  ENTITY_CLASS_PURCHASE_ORDER,
  ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM,
  ENTITY_CLASS_QUOTE,
  ENTITY_CLASS_QUOTE_LINE_ITEM,
  ENTITY_CLASS_RATE_CARD,
  ENTITY_CLASS_REPEATING_LOGGED_TIME,
  ENTITY_CLASS_ROLE,
  ENTITY_CLASS_ROLE_RATE,
  ENTITY_CLASS_TAX_RATE,
  ENTITY_CLASS_TEAM,
  ENTITY_CLASS_USER,
  ENTITY_NAME_ACTIVITY_ENTRIES,
  ENTITY_NAME_ADDRESSES,
  ENTITY_NAME_COMPANIES,
  ENTITY_NAME_CONTACTS,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
  ENTITY_NAME_INVOICE_PAYMENTS,
  ENTITY_NAME_INVOICES,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  ENTITY_NAME_JOB_ITEM_SUB_ITEMS,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_MILESTONES,
  ENTITY_NAME_JOB_PHASES,
  ENTITY_NAME_JOBS,
  ENTITY_NAME_LABELS,
  ENTITY_NAME_LOGGED_EXPENSES,
  ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  ENTITY_NAME_PURCHASE_ORDERS,
  ENTITY_NAME_QUOTE_LINE_ITEMS,
  ENTITY_NAME_QUOTES,
  ENTITY_NAME_RATE_CARDS,
  ENTITY_NAME_REPEATING_LOGGED_TIMES,
  ENTITY_NAME_ROLE_RATES,
  ENTITY_NAME_ROLES,
  ENTITY_NAME_TAX_RATES,
  ENTITY_NAME_TEAMS,
  ENTITY_NAME_USERS,
} from "../../lib/constants";

export const EntityNames = {
  MasterJobItems: "masterJobItems",
  MasterLoggedExpenses: "masterLoggedExpenses",
  ItemRates: "itemRates",
  JobItemRoles: "jobItemRoles",
  UserPreferences: "userPreferences",
  Organisation: "organisation",
  Users: "users",
  JobListModels: "jobListModels",
  Jobs: "jobs",
  ScheduleLoggedTimes: "scheduleLoggedTimes",
  Branches: "branches",
  HappinessResponse: "happinessResponses",
} as const;

export type EntityNamesType = (typeof EntityNames)[keyof typeof EntityNames];

export const EntityClasses = {
  MasterJobItem: "MasterJobItem",
  MasterLoggedExpense: "MasterLoggedExpense",
  ItemRate: "ItemRate",
  JobItemRole: "JobItemRole",
  Organisation: "Organisation",
  Job: "Job",
  JobPhase: "JobPhase",
  JobListModel: "JobListModel",
  ScheduleLoggedTime: "ScheduleLoggedTime",
  Branch: "Branch",
  Invoice: "Invoice",
  Quote: "Quote",
  PurchaseOrder: "PurchaseOrder",
  LoggedExpense: "LoggedExpense",
  HappinessResponse: "HappinessResponse",
} as const;

export type EntityClassesType =
  (typeof EntityClasses)[keyof typeof EntityClasses];

export const EntityClassNameMap = {
  [ENTITY_CLASS_ACTIVITY_ENTRY]: ENTITY_NAME_ACTIVITY_ENTRIES,
  [ENTITY_CLASS_ADDRESS]: ENTITY_NAME_ADDRESSES,
  [ENTITY_CLASS_COMPANY]: ENTITY_NAME_COMPANIES,
  [ENTITY_CLASS_CONTACT]: ENTITY_NAME_CONTACTS,
  [ENTITY_CLASS_INVOICE]: ENTITY_NAME_INVOICES,
  [ENTITY_CLASS_INVOICE_LINE_ITEM]: ENTITY_NAME_INVOICE_LINE_ITEMS,
  [ENTITY_CLASS_INVOICE_PAYMENT]: ENTITY_NAME_INVOICE_PAYMENTS,
  [ENTITY_CLASS_JOB]: ENTITY_NAME_JOBS,
  [ENTITY_CLASS_JOB_ITEM]: ENTITY_NAME_JOB_ITEMS,
  [ENTITY_CLASS_JOB_ITEM_DEPENDANCY]: ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  [ENTITY_CLASS_JOB_ITEM_USER]: ENTITY_NAME_JOB_ITEM_USERS,
  [ENTITY_CLASS_JOB_ITEM_SUB_ITEM]: ENTITY_NAME_JOB_ITEM_SUB_ITEMS,
  [ENTITY_CLASS_JOB_PHASE]: ENTITY_NAME_JOB_PHASES,
  [ENTITY_CLASS_JOB_MILESTONE]: ENTITY_NAME_JOB_MILESTONES,
  [ENTITY_CLASS_LOGGED_EXPENSE]: ENTITY_NAME_LOGGED_EXPENSES,
  [ENTITY_CLASS_ORGANISATION_ACCOUNTING_PREFERENCES]:
    ENTITY_NAME_ORGANISATION_ACCOUNTING_PREFERENCES,
  [ENTITY_CLASS_PURCHASE_ORDER]: ENTITY_NAME_PURCHASE_ORDERS,
  [ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM]:
    ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  [ENTITY_CLASS_QUOTE_LINE_ITEM]: ENTITY_NAME_QUOTE_LINE_ITEMS,
  [ENTITY_CLASS_QUOTE]: ENTITY_NAME_QUOTES,
  [ENTITY_CLASS_REPEATING_LOGGED_TIME]: ENTITY_NAME_REPEATING_LOGGED_TIMES,
  [ENTITY_CLASS_TEAM]: ENTITY_NAME_TEAMS,
  [ENTITY_CLASS_USER]: ENTITY_NAME_USERS,
  [ENTITY_CLASS_ROLE]: ENTITY_NAME_ROLES,
  [ENTITY_CLASS_ROLE_RATE]: ENTITY_NAME_ROLE_RATES,
  [ENTITY_CLASS_RATE_CARD]: ENTITY_NAME_RATE_CARDS,
  [ENTITY_CLASS_TAX_RATE]: ENTITY_NAME_TAX_RATES,
  [ENTITY_CLASS_LABEL]: ENTITY_NAME_LABELS,
  [EntityClasses.MasterJobItem]: EntityNames.MasterJobItems,
  [EntityClasses.MasterLoggedExpense]: EntityNames.MasterLoggedExpenses,
  [EntityClasses.ItemRate]: EntityNames.ItemRates,
  [EntityClasses.JobItemRole]: EntityNames.JobItemRoles,
  [EntityClasses.JobListModel]: EntityNames.JobListModels,
  [EntityClasses.ScheduleLoggedTime]: EntityNames.ScheduleLoggedTimes,
  [EntityClasses.Organisation]: EntityNames.Organisation,
  [EntityClasses.Branch]: EntityNames.Branches,
  [EntityClasses.HappinessResponse]: EntityNames.HappinessResponse,
} as const;

// renamed to TEntityList in entities library
export interface EntityData {
  [EntityNames.MasterJobItems]?: MasterJobItem[];
  [EntityNames.MasterLoggedExpenses]?: MasterLoggedExpense[];
  [EntityNames.ItemRates]?: ItemRate[];
  [EntityNames.JobItemRoles]?: JobItemRole[];
  [EntityNames.UserPreferences]?: TUserPreference[];
  [EntityNames.Organisation]?: TOrganisation[] | TOrganisation;
  [EntityNames.JobListModels]?: JobListModel[];
  [EntityNames.Jobs]?: Job[];
  [EntityNames.ScheduleLoggedTimes]?: ScheduleLoggedTime[];
  [EntityNames.Branches]?: TBranch[];
}

export interface ModelSet {
  __primaryModel?: Entity;
  __modelSet?: { className: EntityClassesType; models: Entity[] }[];
}

export type EntityById<T extends Entity> = Record<number, T | undefined>;

export type EntityByKey<T extends KeyEntity> = Record<string, T | undefined>;

export interface EntityState<T extends Entity> {
  byId: EntityById<T>;
}

export interface KeyEntityState<T extends KeyEntity> extends EntityState<T> {
  byKey: EntityByKey<T>;
}

export interface ChangedEntity<T extends Entity> {
  prevEntity?: T;
  newEntity?: T;
}
