import { find, get } from "lodash-es";
import { createSelector } from "reselect";
import { branchEntityStore } from "st-shared/stores";

import { EMPTY_ARRAY } from "../../lib/constants";
import {
  getAccountingPlatformExpenseAccountCode,
  getAccountingPlatformId,
  getAccountingPlatformRevenueAccountCode,
  getAccountingPlatformTypeName,
} from "../../lib/entities/accountingPlatformEntity";
import {
  getAccountingPlatform,
  getActiveAccountingPlatformExpenseAccountCode,
  getActiveAccountingPlatformRevenueAccountCode,
  hasAccountingPlatform,
} from "../../lib/entities/branchEntity";
import {
  ACCOUNTING_PLATFORM_ACCOUNT_TYPES,
  filterExpenseTaxRates,
  filterExpenseTaxRatesAndExternalRateId,
  filterRevenueTaxRates,
  filterRevenueTaxRatesAndExternalRateId,
  getAccountAsOption,
  getTaxRateAsOption,
  isAccountingPlatformStatusActive,
} from "../../lib/ui/accountingPlatform";
import { appendSearchStringToOptions } from "../../lib/ui/commercialDocumentLineItems";
import { selectPropsAccountCode } from "./index";

export const selectAccountingPlatformUi = (state) =>
  state.ui.accountingPlatform;

export const selectAccountingPlatformUiData = (state) =>
  state.ui.accountingPlatform.data;

export const selectAccountingPlatformTaxRates = createSelector(
  selectAccountingPlatformUiData,
  (data) => get(data, "taxRates", EMPTY_ARRAY)
);

export const selectAccountingPlatformRevenueAccounts = createSelector(
  selectAccountingPlatformUiData,
  (data) => get(data, "revenueAccounts", EMPTY_ARRAY)
);

export const selectAccountingPlatformPaymentAccounts = createSelector(
  selectAccountingPlatformUiData,
  (data) => get(data, "paymentAccounts", EMPTY_ARRAY)
);

export const selectAccountingPlatformExpenseAccounts = createSelector(
  selectAccountingPlatformUiData,
  (data) => get(data, "expenseAccounts", EMPTY_ARRAY)
);

export const selectAccountingPlatformExpenseAccountOptions = createSelector(
  selectAccountingPlatformExpenseAccounts,
  (expenseAccounts) => expenseAccounts.map(getAccountAsOption)
);

export const selectAccountingPlatformRevenueAccountOptions = createSelector(
  selectAccountingPlatformRevenueAccounts,
  (revenueAccounts) => revenueAccounts.map(getAccountAsOption)
);

export const selectAccountingPlatformAccountOptions = (state, props) => {
  let options;

  if (props.accountType === ACCOUNTING_PLATFORM_ACCOUNT_TYPES.EXPENSES) {
    options = selectAccountingPlatformExpenseAccountOptions(state, props);
  } else {
    options = selectAccountingPlatformRevenueAccountOptions(state, props);
  }

  const groupedTypes = {};
  const groupedOptions = [];

  options.forEach((value) => {
    if (groupedTypes[value.type] === undefined) {
      groupedTypes[value.type] = [];
    }
    groupedTypes[value.type].push(value);
  });

  const typeKeys = Object.keys(groupedTypes);

  typeKeys.forEach((key) => {
    const headingOption = {
      key,
      value: key,
      searchString: key,
      isHeading: true,
      taxRef: "",
    };

    groupedOptions.push(headingOption);

    groupedOptions.push(
      ...appendSearchStringToOptions(
        groupedTypes[key],
        headingOption.searchString
      )
    );
  });

  return groupedOptions;
};

export function selectAccountingPlatformStatus(state, props) {
  const accountingPlatformUi = selectAccountingPlatformUi(state);
  const branch = branchEntityStore().entities[props.branchId];

  let accountingPlatform = null;
  let hasRevenueAccountCode = null;
  let hasExpenseAccountCode = null;
  let name = null;
  let id = null;
  if (hasAccountingPlatform(branch)) {
    accountingPlatform = getAccountingPlatform(branch);
    id = getAccountingPlatformId(accountingPlatform);
    name = getAccountingPlatformTypeName(accountingPlatform);
    hasRevenueAccountCode =
      !!getAccountingPlatformRevenueAccountCode(accountingPlatform);
    hasExpenseAccountCode =
      !!getAccountingPlatformExpenseAccountCode(accountingPlatform);
  }
  return {
    name,
    id,
    hasRevenueAccountCode,
    hasExpenseAccountCode,
    isActive: hasAccountingPlatform(branch),
    isDisconnected: accountingPlatformUi.isDisconnected,
    isFetching: accountingPlatformUi.isFetching,
    isFetched: accountingPlatformUi.isFetched,
  };
}

export const selectAccountingPlatformIsActive = createSelector(
  selectAccountingPlatformStatus,
  isAccountingPlatformStatusActive
);

export const selectAccountingPlatformId = createSelector(
  selectAccountingPlatformStatus,
  (accountingPlatformStatus) => accountingPlatformStatus.id
);

export const selectAccountingPlatformRevenueAccountByAccountCode =
  createSelector(
    selectAccountingPlatformRevenueAccounts,
    selectPropsAccountCode,
    (revenueAccounts, accountCode) => {
      if (!accountCode) return null;

      if (!revenueAccounts.length) return null;

      return find(revenueAccounts, (o) => {
        return o.id === accountCode || o.id === String(accountCode);
      });
    }
  );

export const selectAccountingPlatformExpenseAccountByAccountCode =
  createSelector(
    selectAccountingPlatformExpenseAccounts,
    selectPropsAccountCode,
    (expenseAccounts, accountCode) => {
      if (!accountCode) return null;

      if (!expenseAccounts.length) return null;

      return find(expenseAccounts, (o) => {
        return o.id === accountCode || o.id === String(accountCode);
      });
    }
  );

export const selectDefaultAccountingPlatformRevenueAccount = (state, props) => {
  const branch = branchEntityStore().entities[props.branchId];
  const accountCode = getActiveAccountingPlatformRevenueAccountCode(branch);

  return selectAccountingPlatformRevenueAccountByAccountCode(state, {
    accountCode,
  });
};

export const selectDefaultAccountingPlatformExpenseAccount = (state, props) => {
  const branch = branchEntityStore().entities[props.branchId];
  const accountCode = getActiveAccountingPlatformExpenseAccountCode(branch);

  return selectAccountingPlatformExpenseAccountByAccountCode(state, {
    accountCode,
  });
};

export const selectRevenueAccountDefaultTaxRateByAccountCode = createSelector(
  selectAccountingPlatformRevenueAccountByAccountCode,
  selectAccountingPlatformTaxRates,
  (revenueAccount, externalTaxRates) => {
    if (revenueAccount && externalTaxRates.length) {
      return find(externalTaxRates, {
        id: revenueAccount.taxRef,
      });
    }

    return null;
  }
);

export const selectExpenseAccountDefaultTaxRateByAccountCode = createSelector(
  selectAccountingPlatformExpenseAccountByAccountCode,
  selectAccountingPlatformTaxRates,
  (expenseAccount, externalTaxRates) => {
    if (expenseAccount && externalTaxRates.length)
      return find(externalTaxRates, {
        id: expenseAccount.taxRef,
      });

    return null;
  }
);

export const selectAccountingPlatformRevenueTaxRates = createSelector(
  selectAccountingPlatformTaxRates,
  selectRevenueAccountDefaultTaxRateByAccountCode,
  (taxRates, externalTaxRate) => {
    if (externalTaxRate) {
      return filterRevenueTaxRatesAndExternalRateId(
        taxRates,
        externalTaxRate.id
      );
    }
    return filterRevenueTaxRates(taxRates);
  }
);

export const selectAccountingPlatformExpenseTaxRates = createSelector(
  selectAccountingPlatformTaxRates,
  selectExpenseAccountDefaultTaxRateByAccountCode,
  (taxRates, externalTaxRate) => {
    if (externalTaxRate) {
      return filterExpenseTaxRatesAndExternalRateId(
        taxRates,
        externalTaxRate.id
      );
    }
    return filterExpenseTaxRates(taxRates);
  }
);

export const selectAccountingPlatformTaxRateOptions = createSelector(
  selectAccountingPlatformTaxRates,
  (taxRates) => taxRates.map(getTaxRateAsOption)
);

export const selectAccountingPlatformRevenueTaxRateOptions = createSelector(
  selectAccountingPlatformRevenueTaxRates,
  (taxRates) => taxRates.map(getTaxRateAsOption)
);

export const selectAccountingPlatformExpenseTaxRateOptions = createSelector(
  selectAccountingPlatformExpenseTaxRates,
  (taxRates) => taxRates.map(getTaxRateAsOption)
);
