import { call, put, takeEvery } from "redux-saga/effects";

import { saveQuoteAPI } from "../../../lib/API/quoteAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_CLASS_QUOTE,
  QUOTE_SAVE,
  QUOTE_SAVE_FAILED,
  QUOTE_SAVED,
  QUOTE_SAVING,
} from "../../../lib/constants";
import { sendApplyModelData } from "../../../lib/WebAppAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import { entitySaving } from "../../../lib/WebAppAPI/entity";
import { EntityClasses } from "../../../state/entities/types";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* save(action) {
  const { id, quote } = action.payload;

  try {
    entitySaving(EntityClasses.Quote, id, true);

    yield put(
      createAction(QUOTE_SAVING, {
        id,
      })
    );

    const data = yield call(saveQuoteAPI, quote);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(QUOTE_SAVED, { id }));

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_SAVE_FAILED, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  } finally {
    entitySaving(EntityClasses.Quote, id, false);
  }
}

function sendModelData(action) {
  try {
    const { quote } = action.payload;

    sendApplyModelData(ENTITY_CLASS_QUOTE, quote);
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchQuoteSave() {
  yield takeLatestBy(QUOTE_SAVE, save, (action) => action.payload.id);
  yield takeEvery(QUOTE_SAVE, sendModelData);
}
