import { images } from "@streamtimefe/assets";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { SET_USER_PREFERENCE } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { InlineTextButton } from "../../elements/Button";

const JobDatesTooManyFilters = () => {
  const dispatch = useDispatch();

  const turnAllFiltersOn = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_ITEMS_FILTER,
        value: true,
      })
    );
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER,
        value: true,
      })
    );
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_INVOICES_FILTER,
        value: true,
      })
    );
  };

  return (
    <Container>
      <strong>Something’s not right. </strong>
      <br />
      <StyledImage alt="space tetris" src={images.spaceTetris} />
      <br />
      <span>We know you’ve got some dates set somewhere.</span>
      <br />
      <InlineTextButton onClick={turnAllFiltersOn}>
        Clear all filters
      </InlineTextButton>
    </Container>
  );
};

export default JobDatesTooManyFilters;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 20px;

  padding: 0 20px;

  color: var(--color-gray-dark);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
`;
