import { asString, SearchItem, toLowerCaseString } from "@streamtimefe/utils";
import { pick } from "remeda";

import { UserStatus } from "../../enums";
import type { TUser } from "./User";

export * as calculate from "./User.calculations";
export * as get from "./User.getters";

export function getSearchItem(user: TUser) {
  return SearchItem.create(
    user.id,
    user.displayName ?? "",
    getSearchString(user),
    user
  );
}

export function getSearchItems(users: TUser[]) {
  return SearchItem.createSortedArray(users, getSearchItem);
}

export function sort(a: TUser, b: TUser) {
  return toLowerCaseString(a.displayName).localeCompare(
    toLowerCaseString(b.displayName)
  );
}

export function getSearchString(user: TUser): string {
  return [
    asString(user.displayName).trim(),
    asString(user.firstName).trim(),
    asString(user.lastName).trim(),
    getInitials(user),
  ].join(" ");
}

export function getInitials(user: TUser): string {
  return asString(user.firstName)
    .trim()
    .substring(0, 1)
    .concat(asString(user.lastName).trim().substring(0, 1));
}

export function getPermissions(user: TUser) {
  return pick(user, [
    "canEditPermissions",
    "canAccessReporting",
    "canAccessToDoUserToggle",
    "canSubmitHappinessCoachReport",
    "canViewHappinessCoachReports",
    "canEditTeamLoggedTimes",
    "canAccessJobs",
    "canViewJobFinancials",
    "canEditJobs",
    "canAccessContacts",
    "canAccessTeam",
    "canAccessSettings",
    "canAccessItemsAndExpenses",
    "canAccessCostRates",
    "canAccessSubscription",
    "canExportData",
  ]);
}

export function getWorkingHours(
  user: TUser
): [number, number, number, number, number, number, number] {
  return [
    Number(user.hoursWorkedSunday),
    Number(user.hoursWorkedMonday),
    Number(user.hoursWorkedTuesday),
    Number(user.hoursWorkedWednesday),
    Number(user.hoursWorkedThursday),
    Number(user.hoursWorkedFriday),
    Number(user.hoursWorkedSaturday),
  ];
}

export function getWeekAvailableMinutes(entity: TUser): number {
  return getWorkingHours(entity).reduce((a, b) => a + b, 0) * 60;
}

export function isNonSystem(entity: TUser) {
  return !entity.isSystemUser;
}

export function isActiveOrHibernated(entity: TUser) {
  return UserStatus.isActiveOrHibernated(entity.userStatus);
}

export function isActive(entity: TUser) {
  return UserStatus.isActive(entity.userStatus);
}

export function isHibernated(entity: TUser) {
  return UserStatus.isHibernated(entity.userStatus);
}

export function isDeleted(entity: TUser) {
  return UserStatus.isDeleted(entity.userStatus);
}
