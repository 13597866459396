import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { SCHEDULE_BLOCK_SET_SELECTED } from "../../../lib/constants";
import { isDisabledElement } from "../../../lib/dom";
import {
  getTargetBlockElement,
  getTargetBlockKey
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import ScheduleBlockContextMenu from "../ScheduleBlocks/ScheduleBlockContextMenu";

const mapState = null;

const mapDispatch = dispatch => ({
  doSetSelectedBlock: payload =>
    dispatch(createAction(SCHEDULE_BLOCK_SET_SELECTED, payload))
});

export default WrappedComponent => {
  class BlockContextMenuHandlers extends React.PureComponent {
    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      doSetSelectedBlock: PropTypes.func.isRequired,
      onContextMenu: PropTypes.func
    };

    static defaultProps = {
      onContextMenu: null
    };

    state = {
      blockKey: null,
      anchorPosition: null
    };

    get handlers() {
      return {
        onContextMenu: this.onContextMenu
      };
    }

    onContextMenu = e => {
      const { doSetSelectedBlock, onContextMenu } = this.props;
      const blockKey = getTargetBlockKey(e);
      const blockNode = blockKey && getTargetBlockElement(e);
      const isDisabled =
        blockNode &&
        isDisabledElement(blockNode.querySelector(".scheduleBlock"));

      if (blockKey && !isDisabled) {
        this.setState({
          blockKey,
          anchorPosition: {
            left: e.clientX + 2,
            top: e.clientY + 2
          }
        });

        doSetSelectedBlock({ blockKey });

        e.preventDefault();
      }

      return onContextMenu && onContextMenu(e);
    };

    closeContextMenu = () => {
      this.setState({
        blockKey: null,
        anchorPosition: null
      });
    };

    render() {
      const { props, handlers } = this;
      const { blockKey, anchorPosition } = this.state;
      const isContextMenuOpen = Boolean(blockKey);

      if (!props.canEditTeamLoggedTimes || props.isSplitting) {
        return <WrappedComponent {...props} />;
      }

      return (
        <>
          <WrappedComponent {...{ ...props, ...handlers, isContextMenuOpen }} />
          <ScheduleBlockContextMenu
            blockKey={blockKey}
            anchorPosition={anchorPosition}
            onClose={this.closeContextMenu}
          />
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockContextMenuHandlers);
};
