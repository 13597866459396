import { all } from "redux-saga/effects";
import watchInvoiceLineItemAdd from "./watchInvoiceLineItemAdd";
import watchInvoiceLineItemCreate from "./watchInvoiceLineItemCreate";
import watchInvoiceLineItemCreateTaxRate from "./watchInvoiceLineItemCreateTaxRate";
import watchInvoiceLineItemDelete from "./watchInvoiceLineItemDelete";
import watchInvoiceLineItemSave from "./watchInvoiceLineItemSave";
import watchInvoiceLineItemSetAccountCode from "./watchInvoiceLineItemSetAccountCode";
import watchLineItemsMergeConfirm from "./watchLineItemsMergeConfirm";
import watchLineItemsSortCancel from "./watchLineItemsSortCancel";
import watchLineItemsSortConfirm from "./watchLineItemsSortConfirm";
import watchLineItemsSortStart from "./watchLineItemsSortStart";
import watchSetIsMerging from "./watchSetIsMerging";
import watchUndoMergeLineItems from "./watchUndoMergeLineItems";

export default function* invoiceLineItemSaga() {
  yield all([
    watchInvoiceLineItemCreateTaxRate(),
    watchInvoiceLineItemAdd(),
    watchInvoiceLineItemCreate(),
    watchInvoiceLineItemDelete(),
    watchInvoiceLineItemSave(),
    watchInvoiceLineItemSetAccountCode(),
    watchSetIsMerging(),
    watchLineItemsMergeConfirm(),
    watchUndoMergeLineItems(),
    watchLineItemsSortStart(),
    watchLineItemsSortCancel(),
    watchLineItemsSortConfirm()
  ]);
}
