import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { sharedEmitter } from "st-shared/lib";

import {
  JOB_DETAILS_FETCH_HISTORICAL_SUMMARY,
  JOB_DETAILS_FETCH_REQUEST,
  JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY,
  JOB_DETAILS_FORCE_SAVE,
  JOB_DETAILS_SET_ACTIVITY_SECTION_TAB,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  SET_USER_PREFERENCE,
} from "../../lib/constants";
import { JOB_ACTIVITY_SECTION_TABS } from "../../lib/constants/jobDetails";
import { useKeyEvent } from "../../lib/hooks/useKeyEvent";
import { useUnload } from "../../lib/hooks/useUnload";
import { RoutePages, routerReplace } from "../../lib/router";
import WebAppAPI from "../../lib/WebAppAPI";
import { fePageSetPageTitle } from "../../lib/WebAppAPI/fePages";
import { RECEIVE_FORCE_SAVE } from "../../lib/WebAppAPI/fePages/genericWeb";
import {
  JOB_PAGE_REFETCH_METADATA,
  jobPageOpenInvoicesModal,
  jobPageOpenLoggedExpensesModal,
  jobPageOpenLoggedItemsModal,
  jobPageOpenQuotesModal,
  jobPageOpenRepeatingLoggedTimesModal,
} from "../../lib/WebAppAPI/fePages/jobPage";
import createAction from "../../redux/helpers/createAction";
import { useJob } from "../../redux/selectors/job";
import { useJobDetailsCurrentFocus } from "../../redux/selectors/jobDetails/ui/currentFocus";
import { useIsJobEditable } from "../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobDetailsIsMakingChanges } from "../../redux/selectors/jobDetails/ui/isMakingChanges";
import { useIsPendingJobDetails } from "../../redux/selectors/jobDetails/ui/isPending";
import { UserPreferenceKeys } from "../../state/entities/userPreferences/types";
import LoadingSpinner from "../elements/LoadingSpinner";
import { useParamActivityEntryId } from "../router/useParamActivityEntryId";
import { useParamEntityId } from "../router/useParamEntityId";
import BottomSection from "./BottomSection";
import { JobIdContext, useJobId } from "./context/JobIdContext";
import { useGoToTimeline } from "./hooks/useGoToTimelime";
import ActivityDrawer from "./JobActivity/ActivityDrawer";
import { JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW } from "./JobActivity/JobActivityEntries/JobActivityEntry";
import type { JobPostMode } from "./JobActivity/JobPost/JobPost";
import { JobPost } from "./JobActivity/JobPost/JobPost";
import DatesDrawer from "./JobDates/DatesDrawer";
import { JobHeader } from "./JobHeader/JobHeader";
import { containerCss, pageContentContainerCss } from "./JobPage.css";
import { TopSection } from "./TopSection";
import { useJobPageHotkeys } from "./useJobPageHotkeys";

type PostRouteAction =
  | "quotes"
  | "invoices"
  | "expenses"
  | "time_expenses"
  | "repeating_todos"
  | "timeline"
  | "activity"
  | "post";

type Props = {
  routeAction?: PostRouteAction;
  postMode?: JobPostMode;
};

function JobPage({ routeAction, postMode }: Props) {
  const entityId = useParamEntityId()!;

  const isPending = useIsPendingJobDetails(entityId);
  const isJobEditable = useIsJobEditable(entityId);
  const dispatch = useDispatch();
  const isMakingChanges = useJobDetailsIsMakingChanges();
  const currentFocus = useJobDetailsCurrentFocus();

  useEffect(() => {
    dispatch(
      createAction(JOB_DETAILS_FETCH_REQUEST, {
        jobId: entityId,
      })
    );
  }, [entityId]);

  useUnload(isMakingChanges);

  const disableFocus = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: null,
        reason: "revert",
      })
    );
  };

  useKeyEvent(
    !isPending && isJobEditable && currentFocus !== null,
    "keydown",
    (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        disableFocus();
      }
    },
    [disableFocus]
  );

  return (
    <JobIdContext.Provider value={entityId}>
      <div className={containerCss}>
        {isPending && <LoadingSpinner />}
        {!isPending && (
          <Content routeAction={routeAction} postMode={postMode} />
        )}
      </div>
    </JobIdContext.Provider>
  );
}

function Content({ routeAction, postMode }: Props) {
  const jobId = useJobId();
  const job = useJob(jobId);
  const activityEntryId = useParamActivityEntryId();

  const goToTimeline = useGoToTimeline();

  const dispatch = useDispatch();

  useEffect(() => {
    fePageSetPageTitle(job.name);
  }, [job.name]);

  function actionTimeline() {
    goToTimeline();
    routerReplace(RoutePages.Jobs, jobId);
  }

  function actionActivity() {
    dispatch(
      createAction(
        JOB_DETAILS_SET_ACTIVITY_SECTION_TAB,
        JOB_ACTIVITY_SECTION_TABS.ACTIVITY
      )
    );

    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER,
        value: true,
      })
    );

    requestAnimationFrame(() => {
      sharedEmitter.emit(JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW, {
        activityEntryId,
        jobId,
      });
    });

    routerReplace(RoutePages.Jobs, jobId);
  }

  function actionPost() {
    dispatch(
      createAction(
        JOB_DETAILS_SET_ACTIVITY_SECTION_TAB,
        JOB_ACTIVITY_SECTION_TABS.ACTIVITY
      )
    );

    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER,
        value: true,
      })
    );

    requestAnimationFrame(() => {
      sharedEmitter.emit(JOB_ACTIVITY_ENTRY_SCROLL_INTO_VIEW, {
        activityEntryId,
        jobId,
      });
    });
  }

  useEffect(() => {
    switch (routeAction) {
      case "quotes":
        jobPageOpenQuotesModal(job);
        break;
      case "invoices":
        jobPageOpenInvoicesModal(job);
        break;
      case "expenses":
        jobPageOpenLoggedExpensesModal(job);
        break;
      case "time_expenses":
        jobPageOpenLoggedItemsModal(job);
        break;
      case "repeating_todos":
        jobPageOpenRepeatingLoggedTimesModal(job);
        break;
      case "timeline":
        actionTimeline();
        break;
      case "activity":
        actionActivity();
        break;
      case "post":
        actionPost();
        break;
    }
  }, [routeAction]);

  useEffect(() => {
    function refetchMetadata() {
      dispatch(
        createAction(JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY, {
          jobId,
        })
      );
      dispatch(createAction(JOB_DETAILS_FETCH_HISTORICAL_SUMMARY, { jobId }));
    }

    function forceSave() {
      dispatch(createAction(JOB_DETAILS_FORCE_SAVE));
    }

    sharedEmitter.on(JOB_PAGE_REFETCH_METADATA, refetchMetadata);

    WebAppAPI.registerReceiver(RECEIVE_FORCE_SAVE, forceSave);

    return () => {
      sharedEmitter.off(JOB_PAGE_REFETCH_METADATA, refetchMetadata);
      WebAppAPI.unregisterReceiver(RECEIVE_FORCE_SAVE);
    };
  }, [jobId]);

  useJobPageHotkeys(jobId);

  useIdleTimer({
    timeout: 20_000,
    onIdle: () => dispatch(createAction(JOB_DETAILS_FORCE_SAVE)),
  });

  return (
    <>
      <JobHeader />
      <div className={pageContentContainerCss}>
        <TopSection />
        <BottomSection />
        <ActivityDrawer />
        <DatesDrawer />

        {routeAction === "post" && (
          <JobPost
            jobId={jobId}
            activityEntryId={activityEntryId!}
            mode={postMode!}
          />
        )}
      </div>
    </>
  );
}

export default JobPage;
