import { useState } from "react";
import { Button, Tooltip } from "st-shared/components";
import { pluralise } from "st-shared/lib/pluralise";

import { cancelButtonCss, containerCss } from "./AvailabilityMenuFooter.css";

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  disabled: boolean;
  itemCount?: number;
};

export function AvailabilityMenuFooter({
  onClose,
  onSubmit,
  disabled,
  itemCount = 0,
}: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className={containerCss}>
      <Button variant="link" className={cancelButtonCss} onClick={onClose}>
        Cancel
      </Button>

      <Tooltip
        title="Select a role or team member"
        placement="bottom"
        open={tooltipOpen && disabled}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
      >
        <Button variant="primary" onClick={onSubmit} disabled={disabled}>
          {`Add to ${itemCount} ${pluralise(itemCount, "item")}`}
        </Button>
      </Tooltip>
    </div>
  );
}
