import clsx from "clsx";
import type { PopoverProps as AriaPopoverProps } from "react-aria-components";
import { Popover as AriaPopover } from "react-aria-components";

import { useTheme } from "../core";
import { pickerPopoverStyle } from "../list-box/styles.css";

export type PickerPopoverProps = AriaPopoverProps;

export function PickerPopover(props: PickerPopoverProps) {
  const theme = useTheme();
  return (
    <AriaPopover
      {...props}
      className={clsx(theme, pickerPopoverStyle, props.className)}
    />
  );
}
