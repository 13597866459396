import {
  SCHEDULE_USER_JOB_COLLAPSE,
  SCHEDULE_USER_JOB_EXPAND
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onExpandUserJob = (state, { payload: { userId, jobId } }) => ({
  ...state,
  [`${userId}.${jobId}`]: {
    ...state[`${userId}.${jobId}`],
    isExpanded: true
  }
});

const onCollapseUserJob = (state, { payload: { userId, jobId } }) => ({
  ...state,
  [`${userId}.${jobId}`]: {
    ...state[`${userId}.${jobId}`],
    isExpanded: false
  }
});

export default createReducer(
  {},
  {
    [SCHEDULE_USER_JOB_EXPAND]: onExpandUserJob,
    [SCHEDULE_USER_JOB_COLLAPSE]: onCollapseUserJob
  }
);
