import {
  MdPauseCircleOutline,
  MdPlayCircleOutline,
  StDoneCircleOutline,
} from "../../components";
import { theme } from "../../theme";
import { StatusType, TStatusType } from "../../types";

export function getIcon(statusType: TStatusType) {
  switch (statusType) {
    case StatusType.Planning:
      return MdPauseCircleOutline;
    case StatusType.Complete:
      return StDoneCircleOutline;
    case StatusType.Scheduled:
      return MdPlayCircleOutline;
  }
}

export function getColor(statusType: TStatusType) {
  switch (statusType) {
    case StatusType.Planning:
      return theme.color.statusPause;
    case StatusType.Complete:
      return theme.color.statusDone;
    case StatusType.Scheduled:
      return theme.color.statusPlay;
  }
}
