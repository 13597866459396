import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobItemUserStore } from "./JobItemUser.store";

export const useJobItemUserEntity = createUseEntityStoreEntity(
  EntityClass.JobItemUser
);

export const getJobItemUserEntity = createGetEntityStoreEntity(
  EntityClass.JobItemUser
);

export const useJobItemUser = createUseSingleEntity(JobItemUserStore);

export function useJobItemUserStore() {
  return JobItemUserStore.useStore();
}

export function useJobItemUserActions() {
  return JobItemUserStore.useActions();
}
