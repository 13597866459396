/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { createPostStateMiddleware } from "./middleware/postStateMiddleware";
import { postStateListeners } from "./postStateListeners";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { runtimeEnv } from "@streamtimefe/environment";

const middleware: any[] = [];
const enhancers: any[] = [];

if (runtimeEnv()?.VITE_DEV_REDUX_LOGGER) {
  middleware.push(logger);
}

const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

const postStateMiddleware = createPostStateMiddleware(postStateListeners);
middleware.push(postStateMiddleware);

// noinspection JSUnresolvedVariable
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers)
);

sagaMiddleware.run(rootSaga);

export default store;
