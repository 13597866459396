import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { IconButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";

const ClearableSettingsField = forwardRef(
  ({ value, disabled, placeholder, onClick, onClear }, ref) => {
    return (
      <Container $disabled={disabled} ref={ref}>
        <Field
          $placeholder={value === ""}
          onClick={!disabled ? onClick : undefined}
        >
          {value || placeholder}
        </Field>
        {onClear && !disabled && value !== "" && (
          <StyledIconButton onClick={onClear}>
            <ClearIcon />
          </StyledIconButton>
        )}
      </Container>
    );
  }
);

ClearableSettingsField.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onClear: PropTypes.func
};

ClearableSettingsField.defaultProps = {
  value: "",
  disabled: false,
  placeholder: "",
  onClick: null,
  onClear: null
};

export default ClearableSettingsField;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 250px;
  height: 30px;
  align-items: center;
  ${props =>
    !props.$disabled &&
    "cursor: pointer; &:hover { background: white;" +
      " .StyledIconButton{ visibility: visible; } }"}
`;

const Field = styled.div`
  flex: 1;
  color: ${props => (props.$placeholder ? "var(--color-gray-dark)" : "black")};
`;

export const StyledIconButton = styled(IconButton).attrs({
  className: "StyledIconButton"
})`
  visibility: hidden;
`;
