import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobMilestoneName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobMilestoneDeleted = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" removed the job milestone "}
    <b>{getMetaDataJobMilestoneName(activityEntry)}</b>
  </SystemMessage>
);

JobMilestoneDeleted.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobMilestoneDeleted;
