import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobInvoiceRevertedToDraft = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {` reverted ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataInvoiceNumber(activityEntry)}
    </LinkToActivityEntryEntity>
    {" to draft "}
  </SystemMessage>
);

JobInvoiceRevertedToDraft.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoiceRevertedToDraft;
