import { CanceledError as AxiosCanceledError } from "axios";

export class AsyncCanceledError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = this.constructor.name;
  }
}

export function isCanceledError(error: unknown) {
  return (
    error instanceof AsyncCanceledError || error instanceof AxiosCanceledError
  );
}

export async function asyncDelay(ms: number, signal?: AbortSignal) {
  return new Promise<void>((resolve, reject) => {
    if (signal?.aborted) {
      reject(new AsyncCanceledError(signal.reason));
    }

    setTimeout(() => {
      resolve();
    }, ms);

    signal?.addEventListener("abort", () => {
      reject(new AsyncCanceledError(signal.reason));
    });
  });
}

export function asyncTakeLatest<T, R>(
  caller: (signal: AbortSignal, args?: T) => Promise<R>
) {
  let controller: { ac: AbortController; promise: Promise<R> } | null = null;

  const runner = async function (args?: T) {
    if (controller) {
      controller.ac.abort("asyncTakeLatest: recieved newer function call");
    }

    const ac = new AbortController();
    const promise = caller(ac.signal, args);

    controller = {
      ac,
      promise,
    };

    try {
      await promise;
      return await controller.promise;
    } finally {
      if (promise === controller.promise) {
        controller = null;
      }
    }
  };

  return runner;
}
