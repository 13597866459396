import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { entityIdType } from "../../../lib/types/entityTypes";
import { commercialDocumentEntityType } from "../../../lib/ui/commercialDocument";
import Input from "../../elements/Input";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doEditDocument, doSaveDocument }
}) => ({
  id,
  document,
  getValue: documentMethods.getInstalment,
  setValue: documentMethods.setInstalment,
  doEditDocument,
  doSaveDocument
});

class DocumentInstalmentField extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    doEditDocument: PropTypes.func.isRequired,
    doSaveDocument: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { document, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: getValue(document)
    };
  }

  state = {
    value: "",
    hasFocus: false,
    prevDocument: null
  };

  get isHiddenField() {
    const { value } = this.state;

    return value === "";
  }

  edit = newValue => {
    const { id, document, setValue, doEditDocument } = this.props;

    doEditDocument(id, setValue(document, newValue), document);
  };

  save = () => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;
    const { prevDocument } = this.state;

    if (getValue(document) !== getValue(prevDocument))
      doSaveDocument(id, document, setValue(document, getValue(prevDocument)));
  };

  handleChange = e => {
    const { value } = this.state;
    const newValue = String(e.target.value);

    if (value !== newValue) {
      this.setState({
        value: newValue
      });

      this.edit(newValue);
    }
  };

  handleBlur = () => {
    this.save();

    this.setState({
      hasFocus: false,
      prevDocument: null
    });
  };

  handleFocus = () => {
    const { document } = this.props;

    this.setState({
      hasFocus: true,
      prevDocument: document
    });
  };

  render() {
    const { value } = this.state;

    return (
      <Field
        className={this.isHiddenField ? "hiddenField" : ""}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    );
  }
}

export default consumeCommercialDocument(mapContext)(DocumentInstalmentField);

const Field = styled(Input)`
  width: 30px;
  height: 20px;
  background: white;
  color: black;
  font-size: 12px;
  font-weight: 500;
  margin: 0 3px 0 7px;
  padding: 2px 4px;
  .MuiInputBase-input {
    padding: 0;
    text-align: right;
  }
  &.hiddenField {
    background-color: var(--color-gray-bright);
    color: var(--color-gray-dark);
  }
`;
