import type { ConstEnum } from "@streamtimefe/types";

export const StatisticFieldEnum = {
  Count: 1,
  LoggedTimeTotalExTax: 2,
  LoggedTimeMinutes: 3,
  LoggedTimeTotalCostExTax: 4,
  LoggedExpenseTotalCostExTax: 5,
  LoggedExpenseTotalAmountExTax: 6,
  PurchaseOrderAmountPaidIncTax: 7,
  InvoiceAmountExTax: 8,
  QuoteTotalAmountExTax: 9,
  JobProfitMargin: 10,
  JobProfit: 11,
  HappinessScore: 12,
  InvoiceAmountIncTax: 13,
  JobFinalBudget: 14,
  JobBudgetVariancePercentage: 15,
  JobTotalLoggedCostExTax: 16,
  JobTotalPlannedExTax: 17,
  JobTotalLoggedExTax: 18,
  JobTotalPlannedExpensesExTax: 19,
  JobTotalLoggedMinutes: 20,
  JobTotalPlannedMinutes: 21,
  JobTotalNonBillableLoggedMinutes: 22,
  JobTotalNonBillablePlannedMinutes: 23,
  JobTotalNonBillablePlannedTimeExTax: 24,
  JobTotalNonBillableLoggedTimeExTax: 25,
  JobTotalLoggedTimeExTax: 26,
  JobTotalLoggedTimeCostExTax: 27,
  JobTotalLoggedExpensesCostExTax: 28,
  JobTotalLoggedExpensesExTax: 29,
  JobTotalLoggedTimeProfitExTax: 30,
  JobTotalLoggedExpensesProfitExTax: 31,
  JobTotalPlannedExpensesCostExTax: 32,
  PurchaseOrderBalance: 33,
  LoggedExpenseProfitExTax: 34,
  QuoteTotalAmountIncTax: 35,
  InvoiceAmountPaidIncTax: 36,
  InvoiceBalanceIncTax: 37,
  JobBudgetVariance: 38,
  AvailabilityMinutes: 39,
  JobItemUserRolePlannedMinutes: 40,
  JobItemUserRolePlannedTimeExTax: 41,
  JobItemUserRolePlannedTimeCostExTax: 42,
  CalculationCurrency: 43,
  JobTotalInvoicedExTax: 44,
  CalculationDays: 45,
  CalculationMinutes: 46,
  CalculationNumber: 47,
  CalculationPercentage: 48,
  JobDaysUntilDueDate: 49,
  LoggedExpenseInvoicedExTax: 50,
  LoggedExpenseInvoicedPercentage: 51,
  QuoteInvoicedExTax: 52,
  QuoteInvoicedPercentage: 53,
  QuoteInvoicedBalanceRemaining: 54,
  LoggedExpenseInvoicedBalanceRemaining: 55,
  UserAvailability: 56,
  JobTotalPlannedTimeExTax: 57,
  JobTotalLoggedNonBillableTimeCostExTax: 58,
} as const;

export type TStatisticFieldEnum = ConstEnum<typeof StatisticFieldEnum>;
