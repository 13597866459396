import { plate } from "st-shared/external";

import { findColumnInput } from "../queries";
import { removeColumnInput } from "../transforms";
import { KeyboardEventHandler } from "./KeyboardEventHandler";
import {
  moveSelectionByOffset,
  MoveSelectionByOffsetOptions,
} from "./moveSelectionByOffset";

export const columnOnKeyDownHandler: <V extends plate.Value>(
  options?: MoveSelectionByOffsetOptions<V>
) => (editor: plate.PlateEditor<V>) => KeyboardEventHandler =
  (options) => (editor) => (event) => {
    if (plate.isHotkey("escape", event)) {
      const currentColumnInput = findColumnInput(editor)!;
      if (currentColumnInput) {
        event.preventDefault();
        removeColumnInput(editor, currentColumnInput[1]);
        return true;
      }
      return false;
    }

    return moveSelectionByOffset(editor, options)(event);
  };
