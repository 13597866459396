import * as PropTypes from "prop-types";
import React from "react";
import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import LanguageOption from "./LanguageOption";
import DetailTableField from "./Layout/DetailTableField";
import DetailTableLabel from "./Layout/DetailTableLabel";

class DetailTableReadOnlyTextField extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isDisplayed: PropTypes.bool,
    value: PropTypes.string.isRequired,
    labelKey: reactNodesType.isRequired
  };

  static defaultProps = {
    isDisplayed: true
  };

  render() {
    const { isDisplayed, value, labelKey } = this.props;

    return (
      <tr className={isDisplayed ? "" : "hiddenField"}>
        <th>
          <DetailTableLabel>
            <LanguageOption optionKey={labelKey} />
          </DetailTableLabel>
        </th>
        <td>
          <DetailTableField value={value} disabled />
        </td>
      </tr>
    );
  }
}

export default DetailTableReadOnlyTextField;
