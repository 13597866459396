import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_USER,
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE,
  DATA_ATTRIBUTE_USER,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { jobItemUserEntityType } from "../../../lib/entities/jobItemUserEntity";
import { getJobItemUserIdIfBaseLoggedTime } from "../../../lib/entities/scheduleLoggedTimeEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemUserById } from "../../../redux/selectors/jobItemUserSelectors";
import { selectBlockContainerHeightByJobItemUserId } from "../../../redux/selectors/scheduleSelectors";
import { isMaxZoomLevel } from "../../../state/ui/schedule/lib";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import ScheduleBlock from "../ScheduleBlocks/ScheduleBlock";
import ScheduleBlocksContainer from "../ScheduleLayout/ScheduleBlocksContainer";
import ScheduleBlocksWrapper from "../ScheduleLayout/ScheduleBlocksWrapper";
import ScheduleUserAvailability from "../ScheduleLayout/ScheduleUserAvailability";
import ScheduleUserNonWorkingDays from "../ScheduleLayout/ScheduleUserNonWorkingDays";
import ScheduleUtilisation from "../ScheduleLayout/ScheduleUtilisation";
import Anchor from "../ScheduleScrollProvider/Anchor";
import { BlocksByJobItemUserIdConsumer } from "../ScheduleScrollProvider/BlockConsumer";

const mapState = (state, props) => ({
  jobItemUser: selectJobItemUserById(state, props),
  zoomLevel: selectScheduleUiZoomLevel(state),
  blocksHeight: selectBlockContainerHeightByJobItemUserId(state, props),
});

const mapDispatch = null;

const attrUserId = getAttrPropString(DATA_ATTRIBUTE_USER);
const attrJobId = getAttrPropString(DATA_ATTRIBUTE_JOB);
const attrJobItemId = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM);
const attrJobItemUserId = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_USER);
const attrScheduleFilterMode = getAttrPropString(
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE
);

class ScheduleJobItemUserTodos extends React.PureComponent {
  filterMode = ScheduleUiBlockFilterModes.JobItemUserTodos;

  static propTypes = {
    id: entityIdType.isRequired,
    jobId: entityIdType.isRequired,
    jobItemUser: jobItemUserEntityType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    blocksHeight: PropTypes.number.isRequired,
  };

  blockFilter = (scheduleLoggedTime) => {
    const { id } = this.props;
    return getJobItemUserIdIfBaseLoggedTime(scheduleLoggedTime) === id;
  };

  render() {
    const { id, jobId, jobItemUser, blocksHeight, zoomLevel } = this.props;
    const { userId, jobItemId } = jobItemUser;
    const pointerEventAttrs = {
      [attrJobId]: jobId,
      [attrUserId]: userId,
      [attrJobItemId]: jobItemId,
      [attrJobItemUserId]: id,
      [attrScheduleFilterMode]: this.filterMode,
    };

    return (
      <ScheduleBlocksWrapper style={{ height: blocksHeight }}>
        <ScheduleUserNonWorkingDays id={userId} />
        <ScheduleUserAvailability id={userId} compactMode />
        {isMaxZoomLevel(zoomLevel) ? (
          <ScheduleBlocksContainer {...pointerEventAttrs}>
            <Anchor>
              <BlocksByJobItemUserIdConsumer id={userId} jobItemUserId={id}>
                {(blockKey) => (
                  <ScheduleBlock key={blockKey} blockKey={blockKey} />
                )}
              </BlocksByJobItemUserIdConsumer>
            </Anchor>
          </ScheduleBlocksContainer>
        ) : (
          <ScheduleUtilisation
            id={userId}
            filterMode={this.filterMode}
            blockFilter={this.blockFilter}
            {...pointerEventAttrs}
          />
        )}
      </ScheduleBlocksWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobItemUserTodos);
