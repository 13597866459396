import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../../index";

export const selectStateJobUi = state => state.ui.jobs;

export const selectJobUi = createCachedSelector(
  [selectStateJobUi, selectPropsJobId],
  (jobs, jobId) => jobs[jobId]
)(selectPropsJobId);

export const useJobUi = jobId =>
  useSelector(state => selectJobUi(state, { jobId }));
