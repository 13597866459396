import { MdViewColumn } from "st-shared/components";

import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingSavedSegmentDatasetColumns } from "../../../state/stores/savedSegmentSelectors";
import { useReportingDatasetColumnsOpen } from "../../../state/stores/uiSelectors";
import { DataSetColumn } from "./DataSetColumn";
import { dataSetColumnsCss } from "./DataSetColumns.css";
import { FiltersAndColumnsExpander } from "./FiltersAndColumnsExpander";

interface Props {
  dataSetId: string;
  openAddColumnMenu: () => void;
}

export function DataSetColumns({ dataSetId, openAddColumnMenu }: Props) {
  const dataSetColumns = useReportingSavedSegmentDatasetColumns(dataSetId);
  const datasetColumnsOpen = useReportingDatasetColumnsOpen(dataSetId);

  function toggleOpen() {
    reportingStore().ui.actions.setDatasetColumnsOpen(
      dataSetId,
      !datasetColumnsOpen
    );
  }

  return (
    <div className={dataSetColumnsCss}>
      <FiltersAndColumnsExpander
        text="column"
        open={datasetColumnsOpen}
        toggleOpen={toggleOpen}
        openAddMenu={openAddColumnMenu}
        length={dataSetColumns.length}
        icon={MdViewColumn}
      />
      {datasetColumnsOpen &&
        dataSetColumns.map((column) => {
          return <DataSetColumn key={column.id} column={column} />;
        })}
    </div>
  );
}
