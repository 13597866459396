// noinspection SpellCheckingInspection
const EXPRESSIONS: Record<string, string> = {
  "(To Do)$": "To Do’s",
  "(hasn't)$": "haven't",
  "(person)$": "people",
  "(this)$": "these",
  "(is)$": "are",
  "(it)$": "them",
  "(has)$": "have",
  "(was)$": "were",
  "(quiz)$": "$1zes",
  "^(ox)$": "$1en",
  "([m|l])ouse$": "$1ice",
  "(matr|vert|ind)ix|ex$": "$1ices",
  "(x|ch|ss|sh)$": "$1es",
  "([^aeiouy]|qu)y$": "$1ies",
  "(hive)$": "$1s",
  "(?:([^f])fe|([lr])f)$": "$1$2ves",
  "(shea|lea|loa|thie)f$": "$1ves",
  sis$: "ses",
  "([ti])um$": "$1a",
  "(tomat|potat|ech|her|vet)o$": "$1oes",
  "(bu)s$": "$1ses",
  "(alias)$": "$1es",
  "(octop)us$": "$1i",
  "(ax|test)is$": "$1es",
  "(us)$": "$1es",
  "([^s]+)$": "$1s",
};

export function pluralise(number: number, word: string) {
  if (Number(number) === 1 || !word) return word;

  for (const test in EXPRESSIONS) {
    if (Object.prototype.hasOwnProperty.call(EXPRESSIONS, test)) {
      const pattern = new RegExp(test, "i");
      const replace = EXPRESSIONS[test];
      if (pattern.test(word)) return word.replace(pattern, replace);
    }
  }

  return word;
}
