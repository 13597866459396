import styled from "styled-components";
import {
  JOB_TIMELINE_ITEM_BAR_HEIGHT,
  JOB_TIMELINE_BAR_MIN_WIDTH
} from "../../../lib/constants";
import CapturePointerElement from "../../elements/CapturePointerElement";

export const SCHEDULE_BAR_BACKGROUND_STYLES = `
  &.planning { background-color: var(--color-status-pause); }
  &.complete { background-color: var(--color-status-done); }
  &.scheduled { background-color: var(--color-status-play); }
  &.archived { background-color: var(--color-status-neutral); }
`;

export const SCHEDULE_BAR_BORDER_STYLES = `
  &.planning { border: 1px solid var(--color-status-pause); }
  &.complete { border: 1px solid var(--color-status-done); }
  &.scheduled { border: 1px solid var(--color-status-play); }
  &.archived { border: 1px solid var(--color-status-neutral); }
`;

const ScheduleBar = styled.div`
  width: 100%;

  &.squareLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.squareRight {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${props =>
    props.inner ? SCHEDULE_BAR_BACKGROUND_STYLES : SCHEDULE_BAR_BORDER_STYLES}
`;

export const ScheduleJobBar = styled(ScheduleBar)`
  height: 20px;
  margin: 10px 0;
  border-radius: 10px;
  &.startOnly,
  &.endOnly {
    width: 20px;
  }
  ${props => props.inner && props.canEdit && `cursor: grab;`}
`;

export const ScheduleJobItemBar = styled(ScheduleBar)`
  height: ${JOB_TIMELINE_ITEM_BAR_HEIGHT}px;
  margin: 10px 0;
  border-radius: 7px;
  transition: padding 0.2s;
  &.locked {
    animation: var(--animation-pulsate);
  }
`;

export const ScheduleJobPhaseBar = styled(ScheduleBar)`
  height: 20px;
  margin: 7px 0;
  border-radius: 12px;
  &.planning {
    background-color: var(--color-status-pause-faded);
  }
  &.complete {
    background-color: var(--color-status-done-faded);
  }
  &.scheduled {
    background-color: var(--color-status-play-faded);
  }
`;

export const ScheduleBarRect = styled(CapturePointerElement)`
  position: absolute;
  display: flex;
  justify-content: center;

  &.startOnly {
    justify-content: flex-start;
    ${ScheduleBar} {
      width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
    }
  }

  &.endOnly {
    justify-content: flex-end;
    ${ScheduleBar} {
      width: ${JOB_TIMELINE_BAR_MIN_WIDTH}px;
    }
  }
`;
