import { Option } from "./Option";

export const InvoiceStatuses = {
  Draft: 1,
  AwaitingPayment: 2,
  Paid: 3,
  Deleted: 4,
  Voided: 5,
} as const;

export type InvoiceStatusesType =
  (typeof InvoiceStatuses)[keyof typeof InvoiceStatuses];

export type Type = InvoiceStatus;
export interface InvoiceStatus extends Option {
  id: InvoiceStatusesType;
}

export function isDraft(entity: InvoiceStatus) {
  return entity.id === InvoiceStatuses.Draft;
}

export function isAwaitingPayment(entity: InvoiceStatus) {
  return entity.id === InvoiceStatuses.AwaitingPayment;
}

export function isPaid(entity: InvoiceStatus) {
  return entity.id === InvoiceStatuses.Paid;
}

export function isDeleted(entity: InvoiceStatus) {
  return entity.id === InvoiceStatuses.Deleted;
}

export function isVoided(entity: InvoiceStatus) {
  return entity.id === InvoiceStatuses.Voided;
}
