import { produce } from "immer";
import { get, set, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../lib/constants";
import { isAfter, isBefore } from "../../../../lib/dates";

function dateRangeByJobIdReducer(state, changedEntities) {
  return produce(state, (draft) => {
    const touchedJobIds = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      if (prevEntity && !newEntity) pushOnce(touchedJobIds, prevEntity.jobId);

      if (newEntity) pushOnce(touchedJobIds, newEntity.jobId);
    });

    touchedJobIds.forEach((jobId) => {
      const ids = get(state.idsByJobId, jobId, EMPTY_ARRAY);

      if (!ids.length) {
        unset(draft.dateRangeByJobId, jobId);
      } else {
        const prevStartDate = get(
          state.dateRangeByJobId,
          `${jobId}.startDate`,
          null
        );
        const prevEndDate = get(
          state.dateRangeByJobId,
          `${jobId}.endDate`,
          null
        );
        let leftStartDate = null;
        let rightEndDate = null;

        const jobMilestones = ids.map((id) =>
          get(state, `byId.${id}`, EMPTY_OBJECT)
        );

        jobMilestones
          .map((jobMilestone) => (jobMilestone && jobMilestone.date) || null)
          .filter(Boolean)
          .forEach((date) => {
            if (!leftStartDate || isBefore(date, leftStartDate))
              leftStartDate = date;

            if (!rightEndDate || isAfter(date, rightEndDate))
              rightEndDate = date;
          });

        if (prevStartDate !== leftStartDate || prevEndDate !== rightEndDate) {
          set(draft.dateRangeByJobId, jobId, {
            startDate: leftStartDate,
            endDate: rightEndDate,
          });
        }
      }
    });
  });
}

export default dateRangeByJobIdReducer;
