export class CSV {
  name: string;
  data: string;

  private prependComma: boolean = false;

  constructor(name: string) {
    this.name = name;
    this.data = "";
  }

  /**
   * Adds a new value into the list
   * @param value string | number
   */
  addValue(value: string | number) {
    if (this.prependComma) {
      this.data += ",";
    }
    this.data += `"${String(value).replaceAll('"', "'")}"`;
    this.prependComma = true;
  }

  addNewline() {
    this.data += `\n`;
    this.prependComma = false;
  }

  download() {
    const blob = new Blob([this.data], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", `${this.name || "untitled"}.csv`);

    a.click();
  }
}
