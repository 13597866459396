import * as PropTypes from "prop-types";
import React from "react";
import { jobItemDependancySegmentType } from "../../../../lib/entities/jobItemDependancyEntity";
import { getCircleRightCurveSize } from "../../../../lib/ui/dependancySegments";
import { SCROLL_CONTEXTS } from "../../ScrollContexts";
import { CircleRight } from "./SegmentParts/Circle";
import { CurveRightUp } from "./SegmentParts/Curve";
import { FlowUp } from "./SegmentParts/Flow";
import { LineRight } from "./SegmentParts/Line";
import SegmentWrapper from "./SegmentParts/SegmentWrapper";

const SegmentCircleRightUpCurveOut = ({
  Context,
  jobItemDependancySegment
}) => (
  <Context.Consumer>
    {({
      viewportOffsetX,
      viewportOffsetRightX,
      getOffsetXAtDate,
      dayWidth
    }) => {
      const {
        jobItemStartDate,
        jobItemEndDate,
        bridgeSide,
        bridgeDate,
        isConflict,
        isSelected,
        isFloat
      } = jobItemDependancySegment;

      const { left, right } = getCircleRightCurveSize(
        getOffsetXAtDate,
        bridgeDate,
        bridgeSide,
        jobItemStartDate,
        jobItemEndDate,
        dayWidth
      );

      const width = right - left;

      if (left > viewportOffsetRightX) return null;
      if (right < viewportOffsetX) return null;

      const modifiers = {
        conflict: isConflict,
        selected: isSelected,
        float: isFloat
      };

      return (
        <SegmentWrapper style={{ left, width }}>
          <CircleRight {...modifiers} />
          <CurveRightUp {...modifiers} />
          <LineRight curve {...modifiers} />
          <FlowUp right {...modifiers} />
        </SegmentWrapper>
      );
    }}
  </Context.Consumer>
);

SegmentCircleRightUpCurveOut.propTypes = {
  Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
  jobItemDependancySegment: jobItemDependancySegmentType.isRequired
};

export default SegmentCircleRightUpCurveOut;
