export const KEY_ALT_LEFT = "AltLeft";
export const KEY_ALT_RIGHT = "AltRight";
export const KEY_ARROW_LEFT = "ArrowLeft";
export const KEY_ARROW_RIGHT = "ArrowRight";
export const KEY_ARROW_UP = "ArrowUp";
export const KEY_ARROW_DOWN = "ArrowDown";
export const KEY_SHIFT_LEFT = "ShiftLeft";
export const KEY_SHIFT_RIGHT = "ShiftRight";
export const KEY_SPACE_BAR = "Space";
export const KEY_ESCAPE = "Escape";
export const KEY_T = "KeyT";
