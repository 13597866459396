import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const BatchActionOperationStore = createSingleEntityStoreContext(
  EntityClass.BatchActionOperation
);

export const BatchActionOperationStoreProvider =
  createSingleEntityStoreProvider(
    EntityClass.BatchActionOperation,
    BatchActionOperationStore
  );
