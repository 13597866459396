import { feToWebSendIsInEditingMode } from "../../../../../lib/WebAppAPI/fePages/genericWeb";

let isJobPageEditing = false;
let stopEditingTimeout: ReturnType<typeof setTimeout> | null = null;

export function updateEditMode(isEditing: boolean) {
  if (isEditing !== isJobPageEditing) {
    isJobPageEditing = isEditing;

    if (isJobPageEditing === false) {
      stopEditingTimeout = setTimeout(
        () => feToWebSendIsInEditingMode(isJobPageEditing),
        200
      );
    } else {
      if (stopEditingTimeout !== null) {
        clearTimeout(stopEditingTimeout);
        stopEditingTimeout = null;
      }
      feToWebSendIsInEditingMode(isJobPageEditing);
    }
  }
}
