import { sendApplyModelData, sendRemoveModel } from "../WebAppAPI";
import { ENTITY_CLASS_JOB_ITEM_SUB_ITEM } from "../constants";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const deleteJobItemSubItemAPI = jobItemSubItemId =>
  jobItemSubItemId > 0
    ? API.delete(`/job_item_sub_items/${jobItemSubItemId}`)
        .then(({ data }) => data)
        .then(data => {
          sendRemoveModel(ENTITY_CLASS_JOB_ITEM_SUB_ITEM, jobItemSubItemId);
          sendApplyModelData(ENTITY_CLASS_JOB_ITEM_SUB_ITEM, data);
          return data;
        })
        .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_SUB_ITEM))
    : Promise.resolve({});
