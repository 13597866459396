import { IconBase, IconBaseProps } from "react-icons";

export function StBackArrow(props: IconBaseProps) {
  return (
    <IconBase attr={{ viewBox: "0 0 8 12" }} {...props}>
      <path
        className="icon-vector"
        d="M2 0L.6 1.4 5.2 6 .6 10.6 2 12l6-6-6-6z"
        // style={{ transform: "translate(6px, 6px)" }} // Adjust this transformation as needed
      ></path>
    </IconBase>
  );
}
