import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";

import {
  Icon,
  IconSize,
  Input,
  MdAdd,
  StFolderClosed,
} from "../../../components";
import { useSavedSegmentSidebarActions } from "../state/savedSegmentSidebarStore";
import { addFolderButton, addFolderInputContainer } from "./AddFolder.css";
import { folderInputCss, folderNameCss } from "./Folder.css";

export function AddFolder() {
  const [inputMode, setInputMode] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  const sidebarActions = useSavedSegmentSidebarActions();

  useEffect(() => {
    setInputValue("");
  }, [inputMode]);

  function onAddClick() {
    setInputMode(true);
    requestAnimationFrame(() => {
      ref.current?.focus();
    });
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function onInputBlur() {
    if (inputValue.length > 0) {
      sidebarActions.addFolder(inputValue);
    }
    setInputMode(false);
  }

  function onInputKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      setInputMode(false);
    } else if (event.key === "Enter") {
      ref.current?.blur();
    }
  }

  return (
    <>
      {!inputMode && (
        <div className={addFolderButton} onClick={onAddClick}>
          <Icon icon={MdAdd} size={IconSize.Medium} style={{ marginTop: -2 }} />
          <div className={folderNameCss}>ADD A FOLDER</div>
        </div>
      )}
      {inputMode && (
        <div className={addFolderInputContainer}>
          <Icon
            icon={StFolderClosed}
            size={IconSize.Medium}
            style={{ marginTop: -2 }}
          />
          <Input
            className={folderInputCss}
            ref={ref}
            value={inputValue}
            onChange={onInputChange}
            placeholder="folder name..."
            onBlur={onInputBlur}
            onKeyDown={onInputKeyDown}
          />
        </div>
      )}
    </>
  );
}
