import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobMilestoneStore } from "./JobMilestone.store";

export * from "./selectors";

export const useJobMilestoneEntity = createUseEntityStoreEntity(
  EntityClass.JobMilestone
);

export const getJobMilestoneEntity = createGetEntityStoreEntity(
  EntityClass.JobMilestone
);

export const useJobMilestone = createUseSingleEntity(JobMilestoneStore);

export function useJobMilestoneStore() {
  return JobMilestoneStore.useStore();
}

export function useJobMilestoneActions() {
  return JobMilestoneStore.useActions();
}
