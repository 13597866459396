import type {
  TStatisticFieldEnum,
  TStatisticModeEnum,
} from "@streamtimefe/entities";

export interface SeriesData {
  key: string;
  title: string;
  childTitle?: string;
  data: Record<string, number>;
}

export interface DataSetSeriesDataTitle {
  cid: TStatisticFieldEnum;
  mid: TStatisticModeEnum;
  dsid: number;
  ts?: string;
}

export function parseDataSetSeriesDataTitle(
  title: string
): DataSetSeriesDataTitle {
  const { dsid, cid, mid, ts } = JSON.parse(title);
  return {
    dsid: Number(dsid),
    cid: Number(cid) as TStatisticFieldEnum,
    mid: Number(mid) as TStatisticModeEnum,
    ts,
  };
}
