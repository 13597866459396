import { concat } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getPhaseId, getItemId } from "../../../lib/sortingHelpers";
import { selectPropsJobId } from "../index";
import { selectJobItemOrderByJobIdPhaseId } from "../jobItem/selectJobItemOrderByJobIdPhaseId";
import { selectJobPhaseOrderByJobId } from "./selectJobPhaseOrderByJobId";
import { selectIsExpandedJobPhase } from "./ui/isExpanded";

export const selectFullOrderedJobPhaseJobItemIdsByJobId = (
  state,
  { jobId }
) => {
  const orderJobPhases = selectJobPhaseOrderByJobId(state, {
    jobId
  });

  return concat([0], orderJobPhases).map(jobPhaseId => ({
    jobPhaseId,
    jobItemIds: selectJobItemOrderByJobIdPhaseId(state, {
      jobId,
      jobPhaseId
    }),
    isExpanded: selectIsExpandedJobPhase(state, { jobPhaseId })
  }));
};

export const useFullOrderedJobPhaseJobItemIdsByJobId = jobId =>
  useSelector(state =>
    selectFullOrderedJobPhaseJobItemIdsByJobId(state, { jobId })
  );

export const selectJobPlanSortedIds = createCachedSelector(
  [selectFullOrderedJobPhaseJobItemIdsByJobId],
  fullOrderJobPhases => {
    const sortedIds = [];
    const activeIds = [];

    fullOrderJobPhases.forEach(({ jobPhaseId, jobItemIds, isExpanded }) => {
      if (jobPhaseId !== 0) {
        sortedIds.push(getPhaseId(jobPhaseId));
        activeIds.push(getPhaseId(jobPhaseId));
      }
      jobItemIds.forEach(jobItemId => {
        sortedIds.push(getItemId(jobItemId));
        if (isExpanded) activeIds.push(getItemId(jobItemId));
      });
    });

    return [sortedIds, activeIds, fullOrderJobPhases];
  }
)(selectPropsJobId);

export const useJobPlanSortedIds = jobId =>
  useSelector(state => selectJobPlanSortedIds(state, { jobId }));

export const selectPreviousJobPhaseId = createSelector(
  [
    selectFullOrderedJobPhaseJobItemIdsByJobId,
    (state, props) => props.fromJobItemId || null,
    (state, props) => props.fromJobPhaseId || null
  ],
  (fullOrderJobPhases, fromJobItemId, fromJobPhaseId) => {
    if (fullOrderJobPhases.length === 0) return null;

    // this broke specific occurrence of adding the first phase
    // if (
    //   fullOrderJobPhases.length === 1 &&
    //   fullOrderJobPhases[0].jobPhaseId <= 0
    // )
    //   return null;

    if (fromJobPhaseId !== null) {
      for (let i = 0; i < fullOrderJobPhases.length; i += 1) {
        if (fullOrderJobPhases[i].jobPhaseId === fromJobPhaseId) {
          if (i !== 0) return fullOrderJobPhases[i - 1].jobPhaseId;
          return null;
        }
      }
    }
    if (fromJobItemId !== null) {
      const jobItemIdList = getJobItemIdList(fullOrderJobPhases);
      for (let i = 0; i < jobItemIdList.length; i += 1) {
        if (jobItemIdList[i].jobItemId === fromJobItemId) {
          return jobItemIdList[i].jobPhaseId;
        }
      }
    }
    if (fromJobItemId === null && fromJobPhaseId === null) {
      const lastPhaseGroup = fullOrderJobPhases[fullOrderJobPhases.length - 1];
      return lastPhaseGroup.jobPhaseId;
    }

    return null;
  }
);

export const selectPreviousJobItemId = createSelector(
  [
    selectFullOrderedJobPhaseJobItemIdsByJobId,
    (state, props) => props.fromJobItemId || null,
    (state, props) => props.fromJobPhaseId || null
  ],
  (fullOrderJobPhases, fromJobItemId, fromJobPhaseId) => {
    const jobItemIdList = getJobItemIdList(fullOrderJobPhases);
    if (jobItemIdList.length === 0) return null;

    if (fromJobPhaseId !== null) {
      for (let i = 0; i < jobItemIdList.length; i += 1) {
        if (jobItemIdList[i].jobPhaseId === fromJobPhaseId) {
          if (i === 0) return null;
          return jobItemIdList[i - 1].jobItemId;
        }
      }
    }
    if (fromJobItemId !== null) {
      for (let i = 0; i < jobItemIdList.length; i += 1) {
        if (jobItemIdList[i].jobItemId === fromJobItemId) {
          if (i === 0) return null;
          return jobItemIdList[i - 1].jobItemId;
        }
      }
    }
    if (fromJobItemId === null && fromJobPhaseId === null) {
      return jobItemIdList[jobItemIdList.length - 1].jobItemId;
    }

    // Specific occurrence
    if (
      fromJobItemId === null &&
      fromJobPhaseId !== null &&
      fullOrderJobPhases.length === 2 &&
      fullOrderJobPhases[1].jobItemIds.length === 0
    ) {
      return jobItemIdList[jobItemIdList.length - 1].jobItemId;
    }

    return null;
  }
);

const getJobItemIdList = fullOrderJobPhases => {
  const jobItemIdList = [];
  fullOrderJobPhases.forEach(({ jobPhaseId, jobItemIds }) => {
    jobItemIds.forEach(jobItemId => {
      jobItemIdList.push({ jobPhaseId, jobItemId });
    });
  });
  return jobItemIdList;
};
