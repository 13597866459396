import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_JOB_MILESTONES } from "../../../lib/constants";
import { selectPropsJobMilestoneId } from "../index";

export const selectStateJobMilestones = state =>
  state.entities[ENTITY_NAME_JOB_MILESTONES];

export const selectJobMilestone = createCachedSelector(
  [selectStateJobMilestones, selectPropsJobMilestoneId],
  (jobMilestones, jobMilestoneId) =>
    get(jobMilestones, `byId.${jobMilestoneId}`)
)(selectPropsJobMilestoneId);

export const useJobMilestone = jobMilestoneId =>
  useSelector(state => selectJobMilestone(state, { jobMilestoneId }));
