import * as PropTypes from "prop-types";
import React from "react";
import { JOB_TIMELINE_DAYS_IN_WEEK } from "../../../lib/constants";
import { getStartOfWeekDatesInRange } from "../../../lib/dates";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";

const WeeksConsumer = ({ children }) => (
  <JobTimelineScrollContext.Consumer>
    {({ viewportStartDate, viewportEndDate, getOffsetXAtDate, dayWidth }) =>
      getStartOfWeekDatesInRange(viewportStartDate, viewportEndDate).map(date =>
        children({
          date,
          width: dayWidth * JOB_TIMELINE_DAYS_IN_WEEK,
          left: getOffsetXAtDate(date)
        })
      )
    }
  </JobTimelineScrollContext.Consumer>
);

WeeksConsumer.propTypes = {
  children: PropTypes.func.isRequired
};

export default WeeksConsumer;
