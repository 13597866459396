import type { PopoverOrigin } from "@material-ui/core";
import { useState } from "react";
import { searchWordMatches } from "st-shared/lib";
import { SearchMenuHeader } from "st-shared/module";

import { convertMinutesToTimeHM } from "../../../lib/time";
import PopoverMenu from "../Menu/PopoverMenu";
import { RoleOption } from "../UserMenus/RoleOption";
import * as styles from "./SelectRoleMenu.css";

type Role = {
  id: number;
  name: string;
  planned: number;
};

type Props = {
  anchorEl?: any;
  roles: Role[];
  selectRole: (roleId: number) => void;
  close: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

export function SelectRoleMenu({
  anchorEl,
  roles,
  selectRole,
  close,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: -10,
    horizontal: 0,
  },
}: Props) {
  const [searchQuery, setSearchQuery] = useState("");

  const roleResults =
    searchQuery.length > 0
      ? roles.filter((role: Role) => searchWordMatches(role.name, searchQuery))
      : roles;

  function onClose() {
    setSearchQuery("");
    close();
  }

  return (
    <PopoverMenu
      classes={{ paper: styles.popoverPaper }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      disableScrollLock={true}
      HeaderComponent={
        <SearchMenuHeader
          hasOptions={Boolean(roles.length)}
          hasResults={Boolean(roleResults.length)}
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Search by name..."
          title="Assign Team Member"
        />
      }
    >
      <div>
        {roleResults.map((role: Role) => {
          return (
            <RoleOption
              key={role.id}
              roleId={role.id}
              onPickRoles={selectRole}
              className={styles.roleOption}
            >
              <div className={styles.roleOptionValue}>
                {convertMinutesToTimeHM(role.planned)}
              </div>
            </RoleOption>
          );
        })}
      </div>
    </PopoverMenu>
  );
}

export default SelectRoleMenu;
