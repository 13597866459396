import type { TItemOption } from "@streamtimefe/entities";
import { sortBy } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../entities";
import { getItemOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface ItemOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  options: TItemOption[];
  searchOptions: SearchOption<TItemOption>[];
  load: (force?: boolean) => void;
  setOptions: (options: TItemOption[]) => void;
}

export const useItemOptionsStore = create<ItemOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      options: [],
      searchOptions: [],
      load: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const response = await getItemOptions();
          get().setOptions(response.data);
        } catch (e: unknown) {
          console.error("loading item options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
      setOptions(options: TItemOption[]) {
        options = sortBy(options, (option) => option.toLowerCase());

        const searchOptions: SearchOption[] = options.map((option, index) => ({
          key: index,
          value: option,
          searchString: option,
          entity: option,
        }));

        set({ loaded: true, loading: false, options, searchOptions });
      },
    };
  }
);

export function itemOptionsStoreInit() {
  addGlobalStore(["options", "items"], itemOptionsStore);
}

export function itemOptionsStore() {
  return useItemOptionsStore.getState();
}

export function useItemOptionsLoaded() {
  return useItemOptionsStore((s) => s.loaded);
}

export function useItemOptionsLoading() {
  return useItemOptionsStore((s) => s.loading);
}

export function useItemOptions() {
  return useItemOptionsStore((s) => s.options);
}

export function useItemSearchOptions() {
  return useItemOptionsStore((s) => s.searchOptions);
}
