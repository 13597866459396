import {
  costingMethodObj,
  CostingMethods,
  timeAllocationMethodObj,
} from "st-shared/entities";
import type { JobItemRole } from "st-shared/entities/JobItemRole";
import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  NUMBER_FORMAT_NO_DECIMAL,
  NUMERAL_OPTIONAL_CENTS,
} from "../../../lib/constants";
import {
  getSellRate,
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
  hasSellRate,
} from "../../../lib/entities/jobItemEntity";
import { asDecimal } from "../../../lib/math";
import numeralJs from "../../../lib/numeralJs";
import { useJobCurrency } from "../../../redux/selectors/currency/jobCurrency";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { ItemInputField } from "../components/ItemInputField";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemRoleId } from "../context/JobItemRoleIdContext";
import {
  itemUserTotalContainerCss,
  itemUserTotalFieldCss,
} from "../JobItemUser/ItemUserTotal.css";

export function JobItemRoleTotal() {
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const jobItemRoleId = useJobItemRoleId();
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobItem.jobId);

  let value = "";

  if (!timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()) {
    const isFallbackRate =
      costingMethodObj(jobItem.costingMethod).isItem() && !hasSellRate(jobItem);

    value = formatForeignCurrency(customerCurrency, {
      value: isFallbackRate ? 0 : getPlannedCost(jobItemRole, jobItem),
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }

  return (
    <div className={itemUserTotalContainerCss}>
      <ItemInputField
        value={value}
        disabled
        containerClassName={itemUserTotalFieldCss}
        fallbackLook={!jobItem.isBillable}
      />
    </div>
  );
}

function getPlannedCost(jobItemRole: JobItemRole, jobItem: any) {
  return numeralJs(getCalculatedSellRate(jobItemRole, jobItem))
    .multiply(numeralJs(jobItemRole.totalPlannedMinutes).divide(60).value())
    .value();
}

function getCalculatedSellRate(jobItemRole: JobItemRole, jobItem: any) {
  let value: any = "";

  switch (jobItem.costingMethod.id) {
    case CostingMethods.People:
    case CostingMethods.ValueUserSell:
      value = jobItemRole.jobCurrencySellRate;
      break;
    case CostingMethods.ValueCalculatedSell:
      if (getTotalPlannedMinutes(jobItem) > 0) {
        value = numeralJs(getTotalPlannedMoney(jobItem))
          .divide(numeralJs(getTotalPlannedMinutes(jobItem)).divide(60).value())
          .format(NUMERAL_OPTIONAL_CENTS);
      }
      break;
    default:
      if (hasSellRate(jobItem)) {
        value = String(getSellRate(jobItem));
      } else {
        value = jobItemRole.jobCurrencySellRate;
      }
  }

  return asDecimal(value);
}
