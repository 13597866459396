import { Entity } from "@streamtimefe/entities";
import { produce } from "immer";
import { includes } from "lodash-es";
import { delay, put, select } from "redux-saga/effects";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOB_PHASES,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_PHASE_CREATE,
  JOB_PHASE_SET_JOB_ITEM_IDS,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  createNewJobPhase,
  getOrderId,
} from "../../../lib/entities/jobPhaseEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectJobItemById,
  selectJobItemOrderByJobIdPhaseId,
} from "../../selectors/jobItemSelectors";
import { selectJobPhase } from "../../selectors/jobPhase";
import {
  selectPreviousJobItemId,
  selectPreviousJobPhaseId,
} from "../../selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";

function* create(action) {
  const createId = Entity.temporaryId();

  try {
    const {
      jobId,
      fromJobItemId = null,
      fromJobPhaseId = null,
    } = action.payload;

    const previousJobItemId = yield select(selectPreviousJobItemId, {
      jobId,
      fromJobItemId,
      fromJobPhaseId,
    });

    const previousJobPhaseId = yield select(selectPreviousJobPhaseId, {
      jobId,
      fromJobItemId,
      fromJobPhaseId,
    });

    let orderId = 0.5;
    if (previousJobPhaseId !== null && previousJobPhaseId !== 0) {
      const previousJobPhase = yield select(selectJobPhase, {
        jobPhaseId: previousJobPhaseId,
      });

      orderId =
        getOrderId(previousJobPhase) + (previousJobPhaseId > 0 ? 0.5 : 1);
    }

    const newJobPhase = createNewJobPhase({
      id: createId,
      jobId,
      orderId,
    });

    const modifiedJobItems = [];

    if (previousJobPhaseId !== null) {
      let orderJobItemIds = yield select(selectJobItemOrderByJobIdPhaseId, {
        jobId,
        id: previousJobPhaseId,
      });

      if (
        previousJobItemId !== null &&
        includes(orderJobItemIds, previousJobItemId)
      ) {
        orderJobItemIds = orderJobItemIds.slice(
          orderJobItemIds.indexOf(previousJobItemId) + 1
        );
      }

      for (let i = 0; i < orderJobItemIds.length; i += 1) {
        const jobItemId = orderJobItemIds[i];

        const jobItem = yield select(selectJobItemById, { id: jobItemId });

        const newJobItem = produce(jobItem, (draft) => {
          draft.jobPhaseId = createId;
        });

        modifiedJobItems.push(newJobItem);
      }

      if (modifiedJobItems.length > 0) {
        yield put(
          createAction(JOB_PHASE_SET_JOB_ITEM_IDS, {
            jobPhaseId: createId,
            jobItemIds: modifiedJobItems.map((jobItem) => jobItem.id),
          })
        );
      }
    }

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_PHASES]: [newJobPhase],
        [ENTITY_NAME_JOB_ITEMS]: modifiedJobItems,
      })
    );

    yield delay(200);

    yield put(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobPhaseId: createId,
          key: FOCUS_KEYS.PHASE_NAME,
        },
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobPhaseCreate() {
  yield takeLatestBy(
    [JOB_PHASE_CREATE],
    create,
    (action) => action.payload.jobId
  );
}
