import clsx from "clsx";

import { Icon, StBranch } from "../../components";
import { TEntityId } from "../../entities/Entity";
import { useBranchEntity } from "../../stores";
import {
  branchIconNameContainer,
  branchIconNamePrimary,
  branchIconNameTextContainer,
} from "./BranchIconName.css";

interface Props {
  id: TEntityId;
  size?: number;
  fontSize?: number;
  color?: string;
  gap?: number;
  className?: string;
}

export function BranchIconName({
  id,
  size = 30,
  fontSize = 18,
  color = "black",
  gap = 10,
  className,
}: Props) {
  const branch = useBranchEntity(id);

  return (
    <div
      style={{ height: size, color }}
      className={clsx(branchIconNameContainer, className)}
    >
      <Icon icon={StBranch} size={size * 0.75} />
      <div className={branchIconNameTextContainer} style={{ marginLeft: gap }}>
        <span className={branchIconNamePrimary} style={{ fontSize }}>
          {branch.name}
        </span>
      </div>
    </div>
  );
}
