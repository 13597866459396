import type {
  TEntityId,
  TJobItem,
  TJobItemRole,
  TJobItemSubItem,
  TJobItemUser,
} from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";

import { createActionStoreContext } from "../../../../core";
import type { TJobItemPlanActions } from "./JobItemPlan.actions";

export const JobItemPlanStore = createActionStoreContext<
  TJobItemPlanStore,
  TJobItemPlanActions
>("JobItemPlan");

export type TJobItemPlanStore = {
  jobItem: TJobItem;
  updateJobItem: (jobItem: TJobItem) => void;
  jobItemUsers: Dictionary<
    TEntityId,
    { entity: TJobItemUser; modified: TJobItemUser | null }
  >;
  jobItemRoles: Dictionary<
    TEntityId,
    { entity: TJobItemRole; modified: TJobItemRole | null }
  >;
  jobItemSubItems: Dictionary<
    TEntityId,
    { entity: TJobItemSubItem; modified: TJobItemSubItem | null }
  >;
  removeJobItemDependancyIds?: TEntityId[];
  removeOverdueIncompleteTodos?: boolean;
};
