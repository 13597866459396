import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchLoggedExpenseBootstrapAPI } from "../../../lib/API/loggedExpenseAPI";
import WebAppAPI from "../../../lib/WebAppAPI";
import { webAppLoggedExpenseBootstrap } from "../../../lib/WebAppAPI/bootstrap";
import {
  ENTITIES_RECEIVED,
  LOGGED_EXPENSE_FETCH_SUCCEEDED,
  LOGGED_EXPENSE_FETCH_FAILED,
  LOGGED_EXPENSE_FETCH_REQUEST
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectLoggedExpenseUi } from "../../selectors/loggedExpenseSelectors";

function* fetch(action) {
  try {
    const { loggedExpenseId } = action.payload;

    const { fetchedIds } = yield select(selectLoggedExpenseUi);

    if (!fetchedIds.includes(loggedExpenseId)) {
      const { data } = yield call(
        WebAppAPI.isConnected()
          ? webAppLoggedExpenseBootstrap
          : fetchLoggedExpenseBootstrapAPI,
        loggedExpenseId
      );

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));
    }

    yield put(createAction(LOGGED_EXPENSE_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(LOGGED_EXPENSE_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(LOGGED_EXPENSE_FETCH_REQUEST, fetch);
}
