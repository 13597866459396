import type {
  TCompanyOption,
  TContactOption,
  TItemOption,
  TJobOption,
  TJobPhaseOption,
  TLabelTypeEnum,
} from "@streamtimefe/entities";

import type { MasterLabel } from "../../entities";
import type { TEntityId } from "../../entities/Entity";
import { API } from "../api";
import { WebApiRoot } from "./webApiRoot";

export const OptionsRoot = WebApiRoot + "/options";

export function getAllOptions() {
  return API.get<{
    itemOptions: TItemOption[];
    jobOptions: TJobOption[];
    jobPhaseOptions: TJobPhaseOption[];
    companyOptions: TCompanyOption[];
    contactOptions: TContactOption[];
    jobLabels: MasterLabel[];
    quoteLabels: MasterLabel[];
    invoiceLabels: MasterLabel[];
    loggedExpenseLabels: MasterLabel[];
    companyLabels: MasterLabel[];
    contactLabels: MasterLabel[];
    userLabels: MasterLabel[];
  }>(OptionsRoot + "/side_menu_filter");
}

export function getItemOptions() {
  return API.get<TItemOption[]>(OptionsRoot + "/items");
}

export function getJobOptions(
  loggedTimeFromDate?: string,
  loggedTimeToDate?: string,
  loggedTimeUserId?: TEntityId
) {
  return API.get<TJobOption[]>(OptionsRoot + "/jobs", {
    logged_time_from_date: loggedTimeFromDate,
    logged_time_to_date: loggedTimeToDate,
    user_id: loggedTimeUserId,
  });
}

export function getJobPhaseOptions() {
  return API.get<TJobPhaseOption[]>(OptionsRoot + "/job_phases");
}

export function getCompanyOptions() {
  return API.get<TCompanyOption[]>(OptionsRoot + "/companies");
}

export function getContactOptions() {
  return API.get<TContactOption[]>(OptionsRoot + "/contacts");
}

export function getLabelOptions(labelType: TLabelTypeEnum) {
  return API.get<MasterLabel[]>(
    OptionsRoot + `/labels?label_type_id=${labelType}`
  );
}
