import { get, uniqBy } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  ENTITY_NAME_JOB_PHASE_OPTIONS
} from "../../../lib/constants";

export const selectStateJobPhaseOptions = state =>
  state.ui[ENTITY_NAME_JOB_PHASE_OPTIONS];

export const selectJobPhaseOptions = createSelector(
  selectStateJobPhaseOptions,
  jobPhaseOptions =>
    uniqBy(get(jobPhaseOptions, `options`, EMPTY_ARRAY), "value")
);

export const useJobPhaseOptions = () => useSelector(selectJobPhaseOptions);
