import {
  IconButton,
  IconSize,
  RTooltip,
  StScrollTo,
} from "st-shared/components";

import { useReportingSavedSegmentEntityId } from "../../state/stores/savedSegmentSelectors";
import { DateSelector } from "./DateSelector";
import { NameInput } from "./NameInput";
import { ShareReport } from "./ShareReport";
import { subHeaderCss } from "./SubHeader.css";

type Props = {
  onScrollTo: () => void;
};

export function SubHeader({ onScrollTo }: Props) {
  const entityId = useReportingSavedSegmentEntityId();

  return (
    <div className={subHeaderCss}>
      {entityId && <NameInput />}
      <div style={{ flex: 1 }} />
      <ShareReport />
      <DateSelector />
      <IconButton
        onClick={onScrollTo}
        iconProps={{ icon: StScrollTo, size: IconSize.Large }}
        data-tooltip-id="scroll-to-tooltip"
      />
      <RTooltip id="scroll-to-tooltip" place="top" content="Scroll To Table" />
    </div>
  );
}
