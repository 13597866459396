import { arrayMove } from "@dnd-kit/sortable";
import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import {
  JOB_DETAILS_ITEM_REORDER,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_SAVE_ITEM_CARD
} from "../../../lib/constants";
import { getJobPhaseId, getOrderId } from "../../../lib/entities/jobItemEntity";
import {
  getNextItem,
  getPreviousItem,
  isItem,
  extractId,
  getPreviousPhase
} from "../../../lib/sortingHelpers";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";

function* jobItemReorder(action) {
  const { activeId, overId, sortedIds } = action.payload;

  try {
    const active = {
      id: extractId(activeId),
      index: sortedIds.indexOf(activeId)
    };
    const over = {
      id: extractId(overId),
      index: sortedIds.indexOf(overId)
    };
    const newSortingIds = arrayMove(sortedIds, over.index, active.index);
    const after = active.index < over.index;

    const jobItem = yield select(selectJobItemById, { id: active.id });

    let orderId;
    let jobPhaseId;

    if (isItem(overId)) {
      const overItem = yield select(selectJobItemById, { id: over.id });

      orderId = getOrderId(overItem) + (after ? 0.5 : -0.5);
      jobPhaseId = getJobPhaseId(overItem);
    } else {
      const nextItemId = getNextItem(newSortingIds, over.index);
      const previousItemId = getPreviousItem(newSortingIds, over.index);
      if (nextItemId !== null) {
        const nextItem = yield select(selectJobItemById, { id: nextItemId });
        orderId = getOrderId(nextItem) - 0.5;
      } else if (previousItemId !== null) {
        const previousItem = yield select(selectJobItemById, {
          id: previousItemId
        });
        orderId = getOrderId(previousItem) + 0.5;
      } else {
        orderId = getOrderId(jobItem);
      }

      jobPhaseId = after
        ? over.id
        : getPreviousPhase(newSortingIds, over.index);
    }

    const newJobItem = produce(jobItem, draft => {
      draft.orderId = orderId;
      draft.jobPhaseId = jobPhaseId;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId: jobItem.id,
        jobItem: newJobItem
      })
    );

    yield put(
      createAction(JOB_ITEM_SAVE_ITEM_CARD, {
        jobItemId: jobItem.id,
        jobItem: newJobItem,
        prevJobItem: jobItem
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemReorder() {
  yield takeLatestBy(
    [JOB_DETAILS_ITEM_REORDER],
    jobItemReorder,
    action => action.payload.jobid
  );
}
