import BranchIcon from "../../elements/Icons/custom/BranchIcon";
import * as styles from "./BranchHeading.css";

type Props = {
  title: string;
};

export function BranchHeading({ title }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <BranchIcon size={20} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.line} />
    </div>
  );
}
