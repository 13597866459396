import { themeRaw } from "st-shared/theme";

export const ReportingChartColors = [
  themeRaw.color.blueactive,
  themeRaw.color.greenbright,
  themeRaw.color.bluelight,
  themeRaw.color.greendark,
  themeRaw.color.greenlightsecondary,
  themeRaw.color.greendarksecondary,
] as const;

export const ReportingChartAnnotationColors = [
  themeRaw.color.charcoal,
  themeRaw.color.graymediumish,
  themeRaw.color.gray,
] as const;

export const ReportingBarChartColors = [
  themeRaw.color.bluelight,
  themeRaw.color.cyan,
  themeRaw.color.teal,
  themeRaw.color.yellow,
  themeRaw.color.tealbright,
] as const;

export const ReportinChartOtherColor = themeRaw.color.graydark;
