import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY, ENTITY_NAME_LABEL_OPTIONS } from "../../../lib/constants";
import { selectPropsLabelTypeId } from "../index";
import { selectLabelNamesByEntityIdLabelTypeId } from "../labels/selectLabelNamesByEntityIdLabelTypeId";

export const selectStateLabelOptions = state =>
  state.ui[ENTITY_NAME_LABEL_OPTIONS];

export const selectLabelOptions = createCachedSelector(
  [
    selectStateLabelOptions,
    selectPropsLabelTypeId,
    selectLabelNamesByEntityIdLabelTypeId
  ],
  (labelOptions, labelTypeId, currentLabelNames) => {
    const options = get(
      labelOptions,
      `optionsByLabelType[${labelTypeId}]`,
      EMPTY_ARRAY
    );
    return currentLabelNames.length > 0
      ? options.filter(option => !currentLabelNames.includes(option.value))
      : options;
  }
)(selectPropsLabelTypeId);

export const useLabelOptions = (entityId, labelTypeId) =>
  useSelector(state => selectLabelOptions(state, { entityId, labelTypeId }));
