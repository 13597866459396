import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_VALUE_HANDLE_RIGHT,
  DATA_VALUE_HANDLE_TOP,
  SCHEDULE_BLOCK_RESIZE,
  SCHEDULE_BLOCK_RESIZE_CANCEL,
  SCHEDULE_BLOCK_RESIZE_END,
  SCHEDULE_BLOCK_RESIZE_START,
} from "../../../lib/constants";
import {
  getElementSize,
  getPointerClientOffset,
  isDisabledElement,
  subtractCoords,
} from "../../../lib/dom";
import {
  getTargetBlockElement,
  getTargetBlockKey,
  getTargetResizeHandle,
  getTargetResizeHandleType,
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import {
  getHeightFromHours,
  getHoursFromHeight,
} from "../../../state/ui/schedule/lib";

const mapState = null;

const mapDispatch = (dispatch) => ({
  doStartResizeBlock: (resizeItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_RESIZE_START, resizeItem)),

  doResizeBlock: (resizeItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_RESIZE, resizeItem)),

  doEndResizeBlock: (resizeItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_RESIZE_END, resizeItem)),

  doCancelResizeBlock: (resizeItem) =>
    dispatch(createAction(SCHEDULE_BLOCK_RESIZE_CANCEL, resizeItem)),
});

export default (WrappedComponent) => {
  class BlockResizeHandlers extends React.PureComponent {
    resizeItem = null;

    isResizing = false;

    static propTypes = {
      canEditTeamLoggedTimes: PropTypes.bool.isRequired,
      isSplitting: PropTypes.bool.isRequired,
      doStartResizeBlock: PropTypes.func.isRequired,
      doResizeBlock: PropTypes.func.isRequired,
      doEndResizeBlock: PropTypes.func.isRequired,
      doCancelResizeBlock: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      getDaysFromWidth: PropTypes.func.isRequired,
      getDayWidth: PropTypes.func.isRequired,
      getScrollOffsetX: PropTypes.func,
      className: PropTypes.string,
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      getScrollOffsetX: null,
      className: "",
    };

    getPointerDistance(e) {
      const { origin } = this.resizeItem;
      const pointerPosition = getPointerClientOffset(e);

      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getTargetSize(e) {
      const { getDaysFromWidth, getScrollOffsetX } = this.props;
      const { resizeType, origin } = this.resizeItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const blockSize = {
        w: origin.blockSize.w + pointerDistance.x + scrollDistanceX,
        h: origin.blockSize.h - pointerDistance.y,
      };

      const hours = getHoursFromHeight(blockSize.h);
      const days = getDaysFromWidth(blockSize.w);

      switch (resizeType) {
        case DATA_VALUE_HANDLE_TOP:
          return { hours, days: origin.days };

        case DATA_VALUE_HANDLE_RIGHT:
          return { hours: origin.hours, days };

        default:
          return { hours, days };
      }
    }

    onPointerDown = (e) => {
      const {
        getDaysFromWidth,
        getScrollOffsetX,
        canEditTeamLoggedTimes,
        isSplitting,
        onPointerDown,
        doStartResizeBlock,
      } = this.props;

      const blockKey = getTargetBlockKey(e);
      const blockNode = blockKey && getTargetBlockElement(e);
      const isDisabled =
        blockNode &&
        isDisabledElement(blockNode.querySelector(".scheduleBlock"));
      const isResizeEvent = blockKey && !!getTargetResizeHandle(e);

      if (
        canEditTeamLoggedTimes &&
        !isSplitting &&
        blockNode &&
        !e.metaKey &&
        !isDisabled &&
        isResizeEvent
      ) {
        const resizeType = getTargetResizeHandleType(e);
        const pointerPosition = getPointerClientOffset(e);
        const blockSize = getElementSize(blockNode);
        const hours = getHoursFromHeight(blockSize.h);
        const days = getDaysFromWidth(blockSize.w);
        const scrollOffsetX = getScrollOffsetX();

        this.resizeItem = {
          resizeType,
          blockKey,
          hours,
          days,
          hasChanged: false,
          origin: {
            scrollOffsetX,
            blockNode,
            pointerPosition,
            blockSize,
            hours,
            days,
          },
        };

        blockNode.style.zIndex = 1;

        doStartResizeBlock(this.resizeItem);
        this.isResizing = true;
        this.forceUpdate();
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = (e) => {
      const { props, resizeItem: prevResizeItem } = this;
      const { onPointerMove, doResizeBlock, getDayWidth } = props;

      if (this.isResizing) {
        const { hours, days } = this.getTargetSize(e);

        if (prevResizeItem.hours !== hours || prevResizeItem.days !== days) {
          const { blockNode, prevHours, prevDays } = prevResizeItem.origin;
          const hasChanged = prevHours !== hours || prevDays !== days;

          this.resizeItem = {
            ...prevResizeItem,
            hours,
            days,
            hasChanged,
          };

          blockNode.style.width = `${days * getDayWidth()}px`;
          blockNode.style.height = `${getHeightFromHours(hours)}px`;

          doResizeBlock(this.resizeItem);
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = (e) => {
      const { onPointerUp, doEndResizeBlock } = this.props;

      if (this.isResizing) {
        doEndResizeBlock(this.resizeItem);

        this.resizeItem.origin.blockNode.style.zIndex = null;
        this.resizeItem = null;
        this.isResizing = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = (e) => {
      const { onLostPointerCapture, doEndResizeBlock } = this.props;

      if (this.isResizing) {
        doEndResizeBlock(this.resizeItem);

        this.resizeItem.origin.blockNode.style.zIndex = null;
        this.resizeItem = null;
        this.isResizing = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isResizing) {
        const { resizeType } = this.resizeItem;

        classes.push("resizing");
        classes.push(resizeType);
      }

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp,
        onLostPointerCapture: this.onLostPointerCapture,
      };
    }

    render() {
      const { props, className, handlers } = this;
      const { isResizing } = this;

      return (
        <WrappedComponent
          {...{ ...props, className, isResizing, ...handlers }}
        />
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockResizeHandlers);
};
