import { produce } from "immer";
import { select, put } from "redux-saga/effects";
import { JOB_EDIT, JOB_SET_PO_NUMBER } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";

function* setPONumber(action) {
  try {
    const { jobId, value } = action.payload;

    const job = yield select(selectJob, { jobId });

    const newJob = produce(job, draft => {
      draft.purchaseOrderNumber = value;
    });

    yield put(
      createAction(JOB_EDIT, {
        jobId,
        job: newJob
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobSetPONumber() {
  yield takeLatestBy(
    [JOB_SET_PO_NUMBER],
    setPONumber,
    action => action.payload.jobId
  );
}
