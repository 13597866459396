import type { TContactOption } from "@streamtimefe/entities";
import { sortBy } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../entities";
import { ContactStatuses } from "../../entities";
import { getContactOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface ContactOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  options: TContactOption[];
  searchOptions: SearchOption<TContactOption>[];
  load: (force?: boolean) => void;
  setOptions: (options: TContactOption[]) => void;
}

export const useContactOptionsStore = create<ContactOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      options: [],
      searchOptions: [],
      load: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const response = await getContactOptions();
          get().setOptions(response.data);
        } catch (e: unknown) {
          console.error("loading contact options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
      setOptions(options: TContactOption[]) {
        options = sortBy(options, (option) => option.name.toLowerCase());

        const searchOptions: SearchOption[] = options.map((option) => ({
          key: option.id,
          value: option.name,
          searchString: option.name,
          entity: option,
        }));

        set({ loaded: true, loading: false, options, searchOptions });
      },
    };
  }
);

export function contactOptionsStoreInit() {
  addGlobalStore(["options", "contact"], contactOptionsStore);
}

export function contactOptionsStore() {
  return useContactOptionsStore.getState();
}

export function useContactOptionsLoaded() {
  return useContactOptionsStore((s) => s.loaded);
}

export function useContactOptionsLoading() {
  return useContactOptionsStore((s) => s.loading);
}

export function useContactOptions() {
  return useContactOptionsStore((s) => s.options);
}

export function useContactSearchOptions(includeArchived = false) {
  return useContactOptionsStore((s) => {
    if (includeArchived) return s.searchOptions;

    return s.searchOptions.filter(
      (searchOption) =>
        searchOption.entity.contactStatusId !== ContactStatuses.Archived
    );
  });
}
