import { JobItemUserShowRoleContext } from "../context/JobItemUserShowRoleContext";
import { GroupedJobItemUsers } from "../hooks/useGroupedJobItemUsers";
import ItemUser from "./ItemUser";
import { JobItemUserGroupHeader } from "./JobItemUserGroupHeader";

type Props = {
  info: GroupedJobItemUsers;
  showHeader: boolean;
};

export function JobItemUserGroup({ info, showHeader }: Props) {
  const showUserRole = !showHeader || info.roleId === null;
  return (
    <JobItemUserShowRoleContext.Provider value={showUserRole}>
      {showHeader && <JobItemUserGroupHeader info={info} />}
      {info.jobItemUserIds.map((jobItemUserId) => {
        return <ItemUser key={jobItemUserId} id={jobItemUserId} />;
      })}
    </JobItemUserShowRoleContext.Provider>
  );
}
