import React from "react";
import { useDispatch } from "react-redux";
import { JOB_SET_PO_NUMBER } from "../../../../lib/constants";
import { FOCUS_KEYS } from "../../../../lib/constants/jobDetails";
import { getJobPurchaseOrderNumber } from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";
import { useFocusStateRef } from "../../hooks/useFocusStateInputRef";
import { SettingsInput } from "../styles";

const JobPONumber = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const [ref, , setFocused] = useFocusStateRef({
    jobId,
    key: FOCUS_KEYS.JOB_PO_NUMBER
  });

  const handleChange = e => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_SET_PO_NUMBER, {
        jobId,
        value: String(e.target.value)
      })
    );
  };

  const handleBlur = e => {
    setFocused(false);
  };

  const handleFocus = e => {
    setFocused(true);
  };

  return (
    <JumbotronSettingsField title="PO NUMBER">
      <SettingsInput
        ref={ref}
        value={getJobPurchaseOrderNumber(job)}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!isJobEditable}
        placeholder={isJobEditable ? "Add a PO number" : ""}
      />
    </JumbotronSettingsField>
  );
};

export default JobPONumber;
