import { createIcon } from "../../icon/Icon";

export const IconStatusPaused = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 16H11V8H9V16ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20ZM13 16H15V8H13V16Z"
  />,
  "IconStatusPaused"
);
