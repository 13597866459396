import { useRef, useState } from "react";
import { Button } from "st-shared/components";

import { AddColumnMenu } from "../menus/AddColumnMenu";
import * as styles from "./AddColumnFooter.css";

export function AddColumnFooter() {
  const ref = useRef<HTMLButtonElement>(null);
  const [addAnchorEl, setAddAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  function openAddMenu() {
    setAddAnchorEl(ref.current);
  }

  function closeAddMenu() {
    setAddAnchorEl(null);
  }

  return (
    <div className={styles.footer}>
      <Button
        variant="primary"
        ref={ref}
        className={styles.button}
        onClick={openAddMenu}
      >
        Add column
      </Button>
      <AddColumnMenu
        anchorEl={ref.current!}
        listAnchorEl={addAnchorEl}
        onClose={closeAddMenu}
      />
    </div>
  );
}
