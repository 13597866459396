import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { getTotalPlannedMinutes } from "../../../lib/entities/jobItemUserEntity";
import { selectPropsJobItemUserId } from "../index";
import { selectJobItemUserCalculatedSellRate } from "./selectJobItemUserCalculatedSellRate";
import { selectJobItemUser } from "./index";

export const selectJobItemUserCalculatedPlannedCost = createCachedSelector(
  [selectJobItemUser, selectJobItemUserCalculatedSellRate],
  (jobItemUser, calculatedSellRate) =>
    numeral(calculatedSellRate)
      .multiply(
        numeral(getTotalPlannedMinutes(jobItemUser))
          .divide(60)
          .value()
      )
      .value()
)(selectPropsJobItemUserId);
