import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { getJobCurrencyTotalLoggedTimeCostExTax } from "../../../lib/entities/jobItemEntity";
import { selectJobItem } from "../jobItem";
import { selectJobItemIdsByJobId } from "../jobItem/jobItemIdsByJobId";

export const selectJobCalculatedTotalLoggedTimeCostExTax = (
  state,
  { jobId }
) => {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });

  return reduce(
    jobItemIds,
    (total, jobItemId) => {
      const jobItem = selectJobItem(state, { jobItemId });
      return numeral(total)
        .add(getJobCurrencyTotalLoggedTimeCostExTax(jobItem))
        .value();
    },
    0
  );
};

export const useJobCalculatedTotalLoggedTimeCostExTax = jobId =>
  useSelector(state =>
    selectJobCalculatedTotalLoggedTimeCostExTax(state, { jobId })
  );
