import { Entity, type TEntityId } from "../../core";
import type { TSavedSegmentTypeEnum } from "../../enums";
import { SavedSegmentType } from "../../enums";
import type {
  TEncodedSavedSegmentAccess,
  TEncodedSavedSegmentValue,
  TSavedSegmentAccessTypeEnum,
} from "../../serializable";
import {
  SavedSegmentAccess,
  SavedSegmentAccessTypeEnum,
} from "../../serializable";
import type { TUser } from "../User";
import type { TSavedSegment } from "./SavedSegment";

export function create(
  userId: TEntityId,
  savedSegmentTypeId: TSavedSegmentTypeEnum,
  name: string,
  value: TEncodedSavedSegmentValue
) {
  return Entity.create<TSavedSegment>({
    userId,
    savedSegmentType: SavedSegmentType.create(savedSegmentTypeId),
    name,
    value,
    access: "" as TEncodedSavedSegmentAccess,
    shared: false,
    orderId: 0,
  });
}

export function getUserAccessType(
  savedSegment: TSavedSegment,
  user: TUser
): TSavedSegmentAccessTypeEnum | null {
  if (user.id === savedSegment.userId) {
    return SavedSegmentAccessTypeEnum.Edit;
  }

  const savedSegmentAccess = SavedSegmentAccess.decode(savedSegment.access);

  if (SavedSegmentAccess.userHasEditAccess(savedSegmentAccess, user)) {
    return SavedSegmentAccessTypeEnum.Edit;
  }

  if (SavedSegmentAccess.userHasViewAccess(savedSegmentAccess, user)) {
    return SavedSegmentAccessTypeEnum.View;
  }

  return null;
}

export function userHasEditAccess(savedSegment: TSavedSegment, user: TUser) {
  if (user.id === savedSegment.userId) {
    return true;
  }
  const savedSegmentAccess = SavedSegmentAccess.decode(savedSegment.access);
  return SavedSegmentAccess.userHasEditAccess(savedSegmentAccess, user);
}

export function userHasViewAccess(savedSegment: TSavedSegment, user: TUser) {
  const savedSegmentAccess = SavedSegmentAccess.decode(savedSegment.access);
  return SavedSegmentAccess.userHasViewAccess(savedSegmentAccess, user);
}

export function getHasViewAccessLists(savedSegment: TSavedSegment) {
  const savedSegmentAccess = SavedSegmentAccess.decode(savedSegment.access);
  return {
    users: SavedSegmentAccess.getUsersHasViewAccess(savedSegmentAccess),
    branches: SavedSegmentAccess.getBranchesHasViewAccess(savedSegmentAccess),
  };
}
