import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { COMPANY_GROUPED_OPTION_TYPE } from "../../../lib/entities/companyOptionsEntity";
import { selectCompanyOptions } from "./companyOptions";

export const selectCompanyOptionsGroupedByStatus = createSelector(
  [selectCompanyOptions],
  (companyOptions, companyId) => {
    const activeOptions = companyOptions.filter(
      ({ optionType }) => optionType === COMPANY_GROUPED_OPTION_TYPE.ACTIVE
    );

    const archivedOptions = companyOptions.filter(
      ({ optionType }) => optionType === COMPANY_GROUPED_OPTION_TYPE.ARCHIVED
    );

    const options = [];

    if (activeOptions.length) {
      const searchStrings = activeOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: "ACTIVE",
        value: "Active",
        optionType: COMPANY_GROUPED_OPTION_TYPE.OPTIONS_HEADING,
        searchString: "Active"
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          activeOptions,
          headingOption.searchString
        )
      );
    }

    if (archivedOptions.length) {
      const searchStrings = archivedOptions
        .map(({ searchString }) => searchString)
        .join(" ");

      const headingOption = {
        key: "ARCHIVED",
        value: "Archived",
        optionType: COMPANY_GROUPED_OPTION_TYPE.OPTIONS_HEADING,
        searchString: "Archived"
      };

      options.push(appendSearchStringToOption(headingOption, searchStrings));

      options.push(
        ...appendSearchStringToOptions(
          archivedOptions,
          headingOption.searchString
        )
      );
    }

    return options;
  }
);

const appendSearchStringToOption = (option, searchString) => ({
  ...option,
  searchString: [option.searchString, searchString].join(" ")
});

const appendSearchStringToOptions = (options, searchString) =>
  options.map(option => appendSearchStringToOption(option, searchString));

export const useCompanyOptionsGroupedByStatus = () =>
  useSelector(selectCompanyOptionsGroupedByStatus);
