import clsx from "clsx";
import { createPortal } from "react-dom";
import * as ReactTooltip from "react-tooltip";

import { tooltipArrowCss, tooltipCss } from "./Tooltip.css";

export type RTooltipProps = Omit<
  ReactTooltip.ITooltip,
  "disableStyleInjection"
> & {
  noPortal?: boolean;
};

export function RTooltip({
  className,
  classNameArrow,
  openEvents,
  closeEvents,
  noPortal = false,
  ...props
}: RTooltipProps) {
  function render() {
    return (
      <ReactTooltip.Tooltip
        className={clsx(tooltipCss, className)}
        classNameArrow={clsx(tooltipArrowCss, classNameArrow)}
        {...props}
        disableStyleInjection={true}
        openEvents={openEvents ?? { mouseenter: true }}
        closeEvents={closeEvents ?? { mouseleave: true }}
      />
    );
  }

  if (noPortal) {
    return render();
  }

  return createPortal(render(), document.body);
}

/// New implmenetation if ever needed

/* 
import { clsx } from "clsx";
import { cloneElement, type ReactElement, useId } from "react";
import { createPortal } from "react-dom";
import type { ITooltip as ReactTooltipProps } from "react-tooltip";
import { Tooltip } from "react-tooltip";

import * as styles from "./styles.css";

export type TooltipProps = Omit<
  ReactTooltipProps,
  "disableStyleInjection" | "children" | "id" | "wrapper"
> & {
  children: ReactElement;
  portal?: boolean;
  wrap?: boolean;
};

export function ReactTooltip({
  className,
  classNameArrow,
  openEvents,
  closeEvents,
  children,
  portal = true,
  wrap = false,
  ...props
}: TooltipProps) {
  const id = useId();

  const childElement = wrap ? (
    <div data-tooltip-id={id}>{children}</div>
  ) : (
    cloneElement(children, { "data-tooltip-id": id })
  );

  function render() {
    return (
      <Tooltip
        id={id}
        className={clsx(styles.tooltip, className)}
        classNameArrow={clsx(styles.tooltipArrow, classNameArrow)}
        {...props}
        disableStyleInjection={true}
        openEvents={openEvents ?? { mouseenter: true }}
        closeEvents={closeEvents ?? { mouseleave: true }}
      />
    );
  }

  return (
    <>
      {childElement}
      {portal ? render() : createPortal(render(), document.body)}
    </>
  );
}

 */
