import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import {
  Icon,
  IconButton,
  IconSize,
  MdInfoOutline,
  MdMoreVert,
  MdVisibilityOff,
  StDragIndicator,
  StSortArrow,
} from "st-shared/components";
import { CReportingSavedSegment, ReportingColumn } from "st-shared/entities";
import { mergeRefs } from "st-shared/lib";

import {
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentSortColumn,
} from "../../state/stores/savedSegmentSelectors";
import * as styles from "./Column.css";
import { useColumnDatasetDeleted, useColumnError } from "./Column.hooks";
import { ColumnActionMenu } from "./ColumnActionMenu";
import { ColumnEditMenu } from "./ColumnEditMenu";
import { ColumnTooltip } from "./ColumnTooltip";
import { ColumnError, ColumnVariant } from "./types";

type Props = {
  variant: ColumnVariant;
  column: ReportingColumn;
};

export function Column({ variant, column }: Props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({ id: column.id });

  const error = useColumnError(column);

  const ref = useRef<HTMLDivElement>(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLDivElement | null>(null);

  function openActionMenu() {
    setMenuAnchorEl(ref.current);
  }

  function closeActionMenu() {
    setMenuAnchorEl(null);
  }

  function openEditMenu() {
    closeActionMenu();
    setEditAnchorEl(ref.current);
  }

  function closeEditMenu() {
    setEditAnchorEl(null);
  }

  // fix for column being scroll when opening menus when it shouldn't
  useEffect(() => {
    requestAnimationFrame(() => {
      ref.current?.scrollTo({ left: 0 });
    });
  }, [menuAnchorEl, editAnchorEl]);

  if (useColumnDatasetDeleted(column)) {
    return null;
  }

  return (
    <>
      <ColumnTooltip column={column} error={error} isDragging={isDragging}>
        <div
          ref={mergeRefs([setNodeRef, ref])}
          style={{
            transform: CSS.Transform.toString(transform),
            transition,
          }}
          className={styles.column[variant]}
          data-dragging={isDragging}
          data-sorting={isSorting}
          data-error={error?.type}
          data-hidden={column.hidden}
        >
          <div className={styles.columnInner[variant]}>
            <ColumnIcon column={column} error={error} />
            <span className={styles.columnName}>{column.name}</span>
            <SortIcon column={column} />
          </div>
          <div className={styles.actionsSlideOut}>
            <HiddenIcon column={column} />
            <IconButton
              className={clsx(
                styles.actionsSlideOutButton[variant],
                styles.dragButton
              )}
              iconProps={{ icon: StDragIndicator, size: IconSize.Large }}
              onClick={openActionMenu}
              {...listeners}
              {...attributes}
            />
            <IconButton
              className={styles.actionsSlideOutButton[variant]}
              iconProps={{ icon: MdMoreVert, size: IconSize.Large }}
              onClick={openActionMenu}
            />
          </div>
        </div>
      </ColumnTooltip>
      <ColumnActionMenu
        anchorEl={menuAnchorEl}
        onClose={closeActionMenu}
        column={column}
        onEdit={openEditMenu}
      />
      <ColumnEditMenu
        anchorEl={editAnchorEl}
        onClose={closeEditMenu}
        column={column}
      />
    </>
  );
}

type ColumnIconProps = {
  column: ReportingColumn;
  error?: ColumnError;
};

function ColumnIcon({ column, error }: ColumnIconProps) {
  const dataSets = useReportingSavedSegmentDataSets();

  return (
    <Icon
      icon={
        error
          ? MdInfoOutline
          : CReportingSavedSegment.getColumnIcon(column, dataSets)
      }
      size={14}
      className={styles.columnIcon}
    />
  );
}

type SortIconProps = {
  column: ReportingColumn;
};

function SortIcon({ column }: SortIconProps) {
  const sortColumn = useReportingSavedSegmentSortColumn();

  if (sortColumn.id !== column.id) {
    return null;
  }

  return (
    <Icon
      className={styles.columnSortIcon[sortColumn.order]}
      icon={StSortArrow}
      size={11}
    />
  );
}

type HiddenIconProps = {
  column: ReportingColumn;
};

function HiddenIcon({ column }: HiddenIconProps) {
  if (!column.hidden) {
    return null;
  }

  return (
    <div className={styles.columnHiddenIcon}>
      <Icon icon={MdVisibilityOff} size={IconSize.Medium} />
    </div>
  );
}
