import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_CONTACTS,
  ENTITY_NAME_INVOICES,
  ENTITY_NAME_JOBS,
  ENTITY_NAME_LOGGED_EXPENSES,
  ENTITY_NAME_QUOTES
} from "../../../../lib/constants";
import { getAsOption } from "../../../../lib/entities/contactEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createIndexedOptionsReducer from "../../../helpers/createIndexedOptionsReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import parseSubEntityPayload from "../../../helpers/parseSubEntityPayload";

const idsByCompanyIdIndexer = createEntityIndexedArrayReducer(
  entity => entity.companyId
);

const groupedOptionsReducer = createIndexedOptionsReducer(
  entity => (entity && entity.companyId) || null,
  getAsOption
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByCompanyId: idsByCompanyIdIndexer(
      state.idsByCompanyId,
      changedEntities
    ),
    groupedOptionsByCompanyId: groupedOptionsReducer(
      state.groupedOptionsByCompanyId,
      changedEntities
    )
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_CONTACTS]
  )
    .concat(
      parseSubEntityPayload(state, action.payload[ENTITY_NAME_JOBS], "contact")
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_QUOTES],
        "contact"
      )
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_INVOICES],
        "contact"
      )
    )
    .concat(
      parseSubEntityPayload(
        state,
        action.payload[ENTITY_NAME_LOGGED_EXPENSES],
        "contact"
      )
    );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_CONTACTS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer
  }
);
