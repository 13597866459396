import "../../global.d.ts";

import toast from "react-hot-toast";

import type { TClientActionAlert } from "../actions";
import { ClientActionType } from "../actions";
import { setModalBridgeClient } from "../client";
import { ModalBridgeClient } from "../ModalBridgeClient";

export function modalBridgeClientStart() {
  try {
    if (import.meta.env.DEV) {
      const client = new ModalBridgeClient("https://localhost:3002", "/");
      setModalBridgeClient(client);

      function onAlert(payload: TClientActionAlert["payload"]) {
        switch (payload.type) {
          case "error":
            toast.error(payload.message);
            break;
          case "success":
            toast.success(payload.message);
            break;
        }
      }

      client.on(ClientActionType.Alert, onAlert);

      window.addEventListener("beforeunload", () => {
        client.off(ClientActionType.Alert, onAlert);
      });
    } else {
      const client = window.parent.ModalBridge?.getClient?.();

      if (!client) {
        throw new Error(
          "Modal Bridge Error: couldnt connect to parents instance of modal bridge."
        );
      } else {
        setModalBridgeClient(client);
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    } else {
      // eslint-disable-next-line no-console
      console.error("Modal Bridge Error:", error);
    }
  }
}
