import type { ConstEnum } from "@streamtimefe/types";

export const ValueMatchTypeEnum = {
  Equals: 1,
  NotEquals: 2,
  Contains: 3,
  NotContains: 4,
  GreaterThan: 5,
  LessThan: 6,
  Between: 7,
  AnyValue: 8,
  DateThisWeek: 15,
  DateLastWeek: 16,
  DateThisMonth: 17,
  DateLastMonth: 18,
  ZeroOrNull: 21,
  LoggedExpense: 23,
  PurchaseOrder: 24,
  NoValue: 27,
  BooleanTrue: 28,
  BooleanFalse: 29,
  DateNextWeek: 30,
  DateNextMonth: 31,
  DateLastQuarter: 32,
  DateThisQuarter: 33,
  DateNextQuarter: 34,
  DateLastYear: 35,
  DateThisYear: 36,
  DateNextYear: 37,
  GreaterThanOrEquals: 38,
  LessThanOrEquals: 39,
  InTheFuture: 40,
  InThePast: 41,
} as const;

export type TValueMatchTypeEnum = ConstEnum<typeof ValueMatchTypeEnum>;
