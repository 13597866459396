import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";

export const CompanyStatusEnum = {
  Active: 1,
  Archived: 2,
  Deleted: 3,
} as const;

export type TCompanyStatusEnum = ConstEnum<typeof CompanyStatusEnum>;

export type TCompanyStatusEnumNotDeleted = Exclude<
  TCompanyStatusEnum,
  typeof CompanyStatusEnum.Deleted
>;

export type TCompanyStatus = TEnumOption<TCompanyStatusEnum>;
