import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Tooltip } from "st-shared/components";
import { costingMethodObj } from "st-shared/entities";
import {
  formatForeignCurrency,
  num,
  NumberFormats,
  NumFormats,
  numValue,
} from "st-shared/lib";
import { VarianceIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";
import { theme } from "st-shared/theme";

import { JOB_ITEM_SET_TOTAL } from "../../../../lib/constants";
import { isArchived } from "../../../../lib/entities/jobEntity";
import { getTotalPlannedMoney } from "../../../../lib/entities/jobItemEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobDetailsCurrentFocusJobItemId } from "../../../../redux/selectors/jobDetails/ui/currentFocus";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { useJobItemUserCalcaulatedTotal } from "../../../../redux/selectors/jobItem/selectJobItemCalculatedTotalPlannedTimeExTax";
import { useJobId } from "../../context/JobIdContext";
import { useJobItemId } from "../../context/JobItemIdContext";
import {
  tooltipTitleCss,
  varianceButtonTextCss,
  varianceButtonTextFocusedCss,
  varianceContainerCss,
  varianceIconCss,
} from "./styles.css";

interface Props {
  containerHovered: boolean;
}

export function ValueUserSellVariance({ containerHovered }: Props) {
  const jobId = useJobId();
  const job = useJob(jobId);
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);

  const jobItemTotal = numValue(getTotalPlannedMoney(jobItem));
  const jobItemUsersTotal = useJobItemUserCalcaulatedTotal(jobItemId);

  const focusedJobItemId = useJobDetailsCurrentFocusJobItemId();
  const isFocused = jobItemId === focusedJobItemId;

  const dispatch = useDispatch();

  function setItemUserTotal() {
    if (isFocused) {
      dispatch(
        createAction(JOB_ITEM_SET_TOTAL, {
          jobItemId,
          value: jobItemUsersTotal,
        })
      );
    }
  }

  if (
    isArchived(job) ||
    !costingMethodObj(jobItem.costingMethod).isValueUserSell()
  ) {
    return null;
  }

  if (jobItemTotal === jobItemUsersTotal) return null;

  let totalValue = 0;
  let down = false;
  let percentage = 0;

  if (jobItemUsersTotal > jobItemTotal) {
    totalValue = jobItemUsersTotal - jobItemTotal;
    down = true;
    percentage = Math.abs((jobItemUsersTotal - jobItemTotal) / jobItemTotal);
  } else {
    totalValue = jobItemTotal - jobItemUsersTotal;
    percentage = Math.abs(
      (jobItemTotal - jobItemUsersTotal) / jobItemUsersTotal
    );
  }

  const jobItemUsersTotalFormatted = formatForeignCurrency(customerCurrency, {
    value: jobItemUsersTotal,
    hideLongSymbols: true,
    currency: jobCurrency,
    format: NumberFormats.NoDecimal,
  });

  const jobItemTotalFormatted = formatForeignCurrency(customerCurrency, {
    value: totalValue,
    hideLongSymbols: true,
    currency: jobCurrency,
    format: NumberFormats.NoDecimal,
  });

  if (percentage > 1) {
    percentage = Number(num(percentage).format(NumFormats.TwoDecimals));
  }
  let formattedPercentage = num(percentage).format(NumFormats.Percentage);
  if (percentage > 9.99) {
    formattedPercentage = `>${num(9.99).format(NumFormats.Percentage)}`;
  }

  const direction = down ? "down" : "up";
  const color =
    direction === "up" ? theme.color.indicatorGreen : theme.color.indicatorRed;

  function renderTooltip() {
    if (jobItemTotal === 0) {
      return (
        <>
          The price by person total for this item is{" "}
          {jobItemUsersTotalFormatted}.
          {isFocused && (
            <>
              <br />
              <br /> Click to update your fixed price to{" "}
              <strong>{jobItemUsersTotalFormatted}</strong>.
            </>
          )}
        </>
      );
    }
    return (
      <>
        <strong>
          The fixed item total is {jobItemTotalFormatted}{" "}
          {down ? "less" : "more"}
        </strong>{" "}
        than the price by person total of{" "}
        <strong>{jobItemUsersTotalFormatted}</strong>.
        {isFocused && (
          <>
            <br />
            <br />
            Click to update your fixed price to{" "}
            <strong>{jobItemUsersTotalFormatted}</strong>.
          </>
        )}
      </>
    );
  }

  function renderComponent() {
    if (isFocused || containerHovered) {
      if (jobItemTotal === 0) {
        return (
          <span
            className={clsx(
              varianceButtonTextCss,
              isFocused && varianceButtonTextFocusedCss
            )}
            onClick={setItemUserTotal}
          >
            {jobItemUsersTotalFormatted}
          </span>
        );
      }
      return (
        <span
          className={clsx(
            varianceButtonTextCss,
            isFocused && varianceButtonTextFocusedCss
          )}
          style={{ color, left: 24 }}
          onClick={setItemUserTotal}
        >
          {formattedPercentage}
        </span>
      );
    }
    return null;
  }

  if (jobItemUsersTotal === 0) return null;

  return (
    <Tooltip
      title={<div className={tooltipTitleCss}>{renderTooltip()}</div>}
      placement="top"
    >
      <div className={varianceContainerCss}>
        {jobItemTotal !== 0 && (
          <div className={varianceIconCss}>
            <VarianceIcon direction={direction} size={9} />
          </div>
        )}
        {renderComponent()}
      </div>
    </Tooltip>
  );
}
