import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";

import {
  Icon,
  IconButton,
  IconSize,
  StDragIndicator,
  StPin,
} from "../../../components";
import { TEntityId } from "../../../entities/Entity";
import { useSavedSegmentEntity, useUserEntity } from "../../../stores";
import { UserIcon } from "../..";
import { useSavedSegmentSidebarContext } from "../context/SavedSegmentSidebarContext";
import { useSavedSegmentSidebarSegmentPinned } from "../state/savedSegmentSidebarStore";
import { SortTypes } from "../types";
import {
  noUserIconCss,
  pinCss,
  segmentRowCss,
  segmentRowRouteComponentClickableCss,
  segmentRowRouteComponentCss,
  userIconCss,
} from "./Segment.css";
import { SegmentActionMenu } from "./SegmentActionMenu";
import { SortId } from "./SortId";
import {
  rowDraggingCss,
  rowDraggingIconCss,
  rowDragIconCss,
  rowHoverIconCss,
} from "./styles.css";

type Props = { segmentId: TEntityId; folderId?: string };

export function Segment({ segmentId, folderId }: Props) {
  const segment = useSavedSegmentEntity(segmentId);

  const sortId = SortId.fromEntity(
    segmentId,
    folderId ? SortTypes.FolderSegment : SortTypes.Segment
  ).getValue();

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    attributes,
    listeners,
  } = useSortable({
    id: sortId,
    data: folderId
      ? {
          type: SortTypes.FolderSegment,
          folderId,
        }
      : {
          type: SortTypes.Segment,
        },
  });

  if (!segment) return null;

  return (
    <div
      className={clsx(segmentRowCss)}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <CreatedUserIcon userId={segment.userId} />
      <SegmentLink segmentId={segmentId} />
      <IconButton
        iconProps={{ icon: StDragIndicator, size: IconSize.Medium }}
        className={clsx(rowHoverIconCss, rowDragIconCss)}
        {...listeners}
        {...attributes}
      />
      <SegmentActionMenu segmentId={segmentId} />
    </div>
  );
}

export function SegmentOverlay({ segmentId }: Props) {
  const segment = useSavedSegmentEntity(segmentId);
  if (!segment) return null;

  return (
    <div className={clsx(segmentRowCss, rowDraggingCss)}>
      <CreatedUserIcon userId={segment.userId} />
      <SegmentLink segmentId={segmentId} clickable={false} />
      <IconButton
        iconProps={{ icon: StDragIndicator, size: IconSize.Medium }}
        className={rowDraggingIconCss}
      />
      <div style={{ width: 30 }} />
    </div>
  );
}

type UncategorisedSegmentProps = { segmentId: TEntityId };

export function UncategorisedSegment({ segmentId }: UncategorisedSegmentProps) {
  const segment = useSavedSegmentEntity(segmentId);
  const isPinned = useSavedSegmentSidebarSegmentPinned(segmentId);

  const sortId = SortId.fromEntity(
    segmentId,
    SortTypes.UncategorisedSegment
  ).getValue();

  const { setNodeRef, transform, transition, attributes, listeners } =
    useSortable({
      id: sortId,
      data: {
        type: SortTypes.UncategorisedSegment,
      },
    });

  if (!segment) return null;

  return (
    <div
      className={clsx(segmentRowCss)}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        opacity: 1,
      }}
    >
      <CreatedUserIcon userId={segment.userId} />
      <SegmentLink segmentId={segmentId} />
      {!isPinned && (
        <IconButton
          iconProps={{ icon: StDragIndicator, size: IconSize.Medium }}
          className={clsx(rowHoverIconCss, rowDragIconCss)}
          {...listeners}
          {...attributes}
        />
      )}
      {isPinned && (
        <div className={pinCss}>
          <Icon icon={StPin} size={IconSize.Small} />
        </div>
      )}
      <SegmentActionMenu segmentId={segmentId} />
    </div>
  );
}

type SegmentLinkProps = { segmentId: TEntityId; clickable?: boolean };

function SegmentLink({ segmentId, clickable = true }: SegmentLinkProps) {
  const segment = useSavedSegmentEntity(segmentId);
  const { currentSegmentId, onRoute, RouteComponent } =
    useSavedSegmentSidebarContext();

  if (!segment) return null;

  if (RouteComponent) {
    return (
      <RouteComponent
        className={clsx(
          clickable && segmentRowRouteComponentClickableCss,
          segmentRowRouteComponentCss
        )}
        segmentId={segmentId}
        data-selected={currentSegmentId === segmentId}
      >
        {segment.name}
      </RouteComponent>
    );
  }

  function onClick() {
    onRoute?.(segmentId);
  }

  return (
    <div
      className={clsx(
        clickable && segmentRowRouteComponentClickableCss,
        segmentRowRouteComponentCss
      )}
      data-selected={currentSegmentId === segmentId}
      onClick={clickable ? () => onClick : undefined}
    >
      {segment.name}
    </div>
  );
}

type CreatedUserIconProps = { userId: TEntityId };

function CreatedUserIcon({ userId }: CreatedUserIconProps) {
  const user = useUserEntity(userId);
  const { loggedInUserId } = useSavedSegmentSidebarContext();

  if (!user || userId === loggedInUserId)
    return <div className={noUserIconCss}></div>;

  return (
    <UserIcon
      id={userId}
      name={user.displayName}
      disableTooltip
      size={18}
      className={userIconCss}
    />
  );
}
