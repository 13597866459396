import type { TUser, TUserStatusEnum } from "@streamtimefe/entities";
import { UserStatusEnum } from "@streamtimefe/entities";
import { isEmpty, values } from "lodash-es";
import { useMemo } from "react";
import { create } from "zustand";

import type { SearchOption } from "../../../entities";
import { roleObj } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { userObj } from "../../../entities/User";
import { defaultSort, defaultSortOptions } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { storeActionAddListener } from "../../helpers";
import { addGlobalStore } from "../../helpers/global";
import type { ChangedEntity, EntityStoreState } from "..";
import {
  byIdReducer,
  parseEntityPayload,
  parseRemovedEntities,
  roleEntityStore,
} from "..";

interface UserEntityStoreState extends EntityStoreState<TUser> {
  nonSystemUserIds: TEntityId[];
  groupedByStatusIds: Record<TUserStatusEnum, TEntityId[]>;
  groupedByStatusOptions: Record<TUserStatusEnum, SearchOption<TUser>[]>;
  options: SearchOption<TUser>[];
  postChangedEntities: () => void;
}

export const useUserEntityStore = create<UserEntityStoreState>(
  function (set, get) {
    return {
      entities: {},
      nonSystemUserIds: [],
      groupedByStatusIds: {
        [UserStatusEnum.Active]: [],
        [UserStatusEnum.Hibernated]: [],
        [UserStatusEnum.Deleted]: [],
      },
      groupedByStatusOptions: {
        [UserStatusEnum.Active]: [],
        [UserStatusEnum.Hibernated]: [],
        [UserStatusEnum.Deleted]: [],
      },
      options: [],
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.User])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.User]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.User) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TUser>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);

        const nonSystemUserIds = values(entities)
          .filter((user) => !user.isSystemUser)
          .map((user) => user.id);

        const groupedByStatusIds = {
          [UserStatusEnum.Active]: nonSystemUserIds.filter(
            (id) => entities[id].userStatus.id === UserStatusEnum.Active
          ),
          [UserStatusEnum.Hibernated]: nonSystemUserIds.filter(
            (id) => entities[id].userStatus.id === UserStatusEnum.Hibernated
          ),
          [UserStatusEnum.Deleted]: nonSystemUserIds.filter(
            (id) => entities[id].userStatus.id === UserStatusEnum.Deleted
          ),
        };

        set({
          entities,
          nonSystemUserIds,
          groupedByStatusIds,
        });

        requestAnimationFrame(get().postChangedEntities);
      },
      postChangedEntities() {
        function mapToUserOption(id: TEntityId) {
          const option = userObj(get().entities[id]).getAsOption();
          const roleId = option.entity.roleId;
          if (roleId) {
            const role = roleEntityStore().entities[roleId];
            if (role) {
              option.searchString = [
                option.searchString,
                roleObj(role).getSearchString(),
              ].join(" ");
            }
          }
          return option;
        }

        const groupedByStatusOptions = {
          [UserStatusEnum.Active]: get()
            .groupedByStatusIds[UserStatusEnum.Active].map(mapToUserOption)
            .sort(defaultSortOptions),
          [UserStatusEnum.Hibernated]: get()
            .groupedByStatusIds[UserStatusEnum.Hibernated].map(mapToUserOption)
            .sort(defaultSortOptions),
          [UserStatusEnum.Deleted]: get()
            .groupedByStatusIds[UserStatusEnum.Deleted].map(mapToUserOption)
            .sort(defaultSortOptions),
        };

        set({ groupedByStatusOptions });
      },
    };
  }
);

export function userEntityStoreInit() {
  addGlobalStore(["entities", EntityListKey.User], userEntityStore);
  storeActionAddListener("entitiesReceived", userEntityStore().receiveEntities);
  storeActionAddListener("entitiesRemoved", userEntityStore().removeEntities);
}

export function userEntityStore() {
  return useUserEntityStore.getState();
}

export function useUserEntity(entityId: TEntityId) {
  return useUserEntityStore((s) => s.entities[entityId]);
}

export function useGroupedUserOptions() {
  return useUserEntityStore((s) => ({
    Active: s.groupedByStatusOptions[UserStatusEnum.Active],
    Hibernated: s.groupedByStatusOptions[UserStatusEnum.Hibernated],
    Deleted: s.groupedByStatusOptions[UserStatusEnum.Deleted],
  }));
}

export function useAllUserOptions() {
  const groupedUserOptions = useGroupedUserOptions();
  return useMemo(() => {
    return values(groupedUserOptions).flatMap((options) => options);
  }, [groupedUserOptions]);
}

export function defaultSortUsers(a: TUser, b: TUser) {
  return defaultSort(a.displayName.toLowerCase(), b.displayName.toLowerCase());
}

export function useUserEntities(
  entityIds: TEntityId[],
  sortUsers = defaultSortUsers
) {
  return useUserEntityStore((s) =>
    entityIds.map((entityId) => s.entities[entityId]).sort(sortUsers)
  );
}
