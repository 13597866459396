import clsx from "clsx";
import type { ReactNode } from "react";
import { Text } from "react-aria-components";

import { AnimatableDiv } from "../animatable";
import { IconHelp } from "../icons/action";
import { IconError } from "../icons/notification";
import { fieldDescriptionIconStyle, fieldDescriptionStyle } from "./styles.css";

export type FieldDescriptionAndErrorProps = {
  description?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  hideIcon?: boolean;
  className?: string;
  errorSlot?: ReactNode;
};

export function FieldDescriptionAndError({
  description = "",
  isInvalid = false,
  errorMessage = "",
  hideIcon = false,
  errorSlot,
  className,
}: FieldDescriptionAndErrorProps) {
  const hasDescription = Boolean(description);
  const hasError = Boolean(isInvalid);

  return (
    <AnimatableDiv
      open={hasDescription || hasError}
      className={clsx(
        fieldDescriptionStyle({
          variant: hasError ? "error" : "description",
        }),
        className
      )}
    >
      {hasDescription && !hasError && (
        <>
          {!hideIcon && (
            <IconHelp size={12} className={fieldDescriptionIconStyle} />
          )}
          <Text slot="description">{description}</Text>
        </>
      )}
      {hasError && (
        <>
          {!hideIcon && (
            <IconError size={12} className={fieldDescriptionIconStyle} />
          )}
          <Text slot="errorMessage">{errorMessage}</Text>
          {errorSlot}
        </>
      )}
    </AnimatableDiv>
  );
}
