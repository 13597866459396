import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getParsedMetaData
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const jobLeadAssigned = ({ activityEntry }) => {
  const { createdUserId } = activityEntry;
  const { userDisplayName, userId } = getParsedMetaData(activityEntry);
  return (
    <SystemMessage userId={getCreatedByUserId(activityEntry)}>
      {" assigned "}
      {createdUserId === userId ? "themself" : <b>{userDisplayName}</b>}
      {" as the job lead"}
    </SystemMessage>
  );
};

jobLeadAssigned.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default jobLeadAssigned;
