import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { AppState } from "../../../AppState";
import {
  selectPropsJobItemId,
  selectPropsRoleId,
} from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectJobItemRoleByJobItemIdRoleId = createSelector(
  [selectStateJobItemRoles, selectPropsJobItemId, selectPropsRoleId],
  (jobItemRoles, jobItemId, roleId) => {
    const ids = jobItemRoles.idsByJobItemId[jobItemId] ?? [];
    for (const id of ids) {
      const jobItemRole = jobItemRoles.byId[id];
      if (jobItemRole && jobItemRole.roleId === roleId) {
        return jobItemRole;
      }
    }
    return null;
  }
);

export function useJobItemRoleByJobItemIdRoleId(
  jobItemId: number,
  roleId: number
) {
  return useSelector((state: AppState) =>
    selectJobItemRoleByJobItemIdRoleId(state, { jobItemId, roleId })
  );
}
