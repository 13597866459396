import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  JOB_PAGE_SET_DATE_DRAWER_OPEN,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { jobPageOpenRepeatingLoggedTimesModal } from "../../../lib/WebAppAPI/fePages/jobPage";
import createAction from "../../../redux/helpers/createAction";
import { useJob } from "../../../redux/selectors/job";
import {
  useJobDatesInvoicesFilter,
  useJobDatesItemsFilter,
  useJobDatesMilestonesFilter,
} from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import { IconButton } from "../../elements/Button";
import FilterIcon from "../../elements/Icons/custom/FilterIcon";
import SideDrawerIcon from "../../elements/Icons/custom/SideDrawerIcon";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import Repeat2Icon from "../../elements/Icons/Repeat2Icon";
import Menu from "../../modules/Menu";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import ChecklistLabelItem from "../components/ChecklistLabelItem";
import { useIsDrawer } from "../context/isDrawerContext";
import { useJobId } from "../context/JobIdContext";

const JobDatesHeader = () => {
  const dispatch = useDispatch();
  const jobId = useJobId();
  const job = useJob(jobId);

  const isItemsFilterActive = useJobDatesItemsFilter();
  const isMilestonesFilterActive = useJobDatesMilestonesFilter();
  const isInvoicesFilterActive = useJobDatesInvoicesFilter();

  const ref = useRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = () => {
    setMenuAnchorEl(ref.current);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const toggleItemsFilter = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_ITEMS_FILTER,
        value: !isItemsFilterActive,
      })
    );
  };

  const toggleMilestonesFilter = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_MILESTONES_FILTER,
        value: !isMilestonesFilterActive,
      })
    );
  };

  const toggleInvoicesFilter = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_DATES_INVOICES_FILTER,
        value: !isInvoicesFilterActive,
      })
    );
  };

  const openDatesDrawer = () =>
    dispatch(createAction(JOB_PAGE_SET_DATE_DRAWER_OPEN, true));

  const isDrawer = useIsDrawer();

  const filterText = (() => {
    const viewing = [];
    if (isItemsFilterActive) viewing.push("items");
    if (isMilestonesFilterActive) viewing.push("milestones");
    if (isInvoicesFilterActive) viewing.push("invoices");

    switch (viewing.length) {
      case 3:
        return "Viewing everything";
      case 2:
        return `Viewing ${viewing[0]} and ${viewing[1]}`;
      case 1:
        return `Viewing ${viewing[0]} only`;
    }

    return "";
  })();

  return (
    <Container ref={ref}>
      {isDrawer && (
        <DrawerHeading>
          <DatePickerIcon size={ICON_SIZE.LARGE} />
          <span>Dates</span>
        </DrawerHeading>
      )}
      <FilterButton onClick={openMenu} $isDrawer={isDrawer}>
        <FilterIcon size={ICON_SIZE.MEDIUM} />
        {!isDrawer && <span className="label">{filterText}</span>}
        {isDrawer &&
          (!isItemsFilterActive ||
            !isMilestonesFilterActive ||
            !isInvoicesFilterActive) && (
            <span className="notificationIcon"></span>
          )}
      </FilterButton>
      {!isDrawer && (
        <div>
          <Tooltip title="View repeating To Do’s" placement="top">
            <IconButton
              onClick={() => jobPageOpenRepeatingLoggedTimesModal(job)}
            >
              <Repeat2Icon size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open in drawer" placement="top">
            <IconButton onClick={openDatesDrawer}>
              <SideDrawerIcon size={ICON_SIZE.MEDIUM} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: isDrawer ? "right" : "left",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: -4,
        }}
      >
        <MenuHeader>
          <FilterIcon size={ICON_SIZE.MEDIUM} />
          <span>Filter visibility</span>
        </MenuHeader>
        <ChecklistLabelItem
          id="itemsFilter"
          name="Items"
          checked={isItemsFilterActive}
          disabled={
            isItemsFilterActive &&
            !isMilestonesFilterActive &&
            !isInvoicesFilterActive
          }
          onChange={toggleItemsFilter}
        ></ChecklistLabelItem>
        <ChecklistLabelItem
          id="milestonesFilter"
          name="Milestones"
          checked={isMilestonesFilterActive}
          disabled={
            !isItemsFilterActive &&
            isMilestonesFilterActive &&
            !isInvoicesFilterActive
          }
          onChange={toggleMilestonesFilter}
        ></ChecklistLabelItem>
        <ChecklistLabelItem
          id="invoicesFilter"
          name="Invoices"
          checked={isInvoicesFilterActive}
          disabled={
            !isItemsFilterActive &&
            !isMilestonesFilterActive &&
            isInvoicesFilterActive
          }
          onChange={toggleInvoicesFilter}
        ></ChecklistLabelItem>
      </Menu>
    </Container>
  );
};

export default JobDatesHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-bottom: 1px solid var(--color-gray-bright);

  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 2px 3px -3px var(--color-gray-medium);
`;

const FilterButton = styled(IconButton)`
  ${(props) => !props.$isDrawer && "width: unset;"}

  .label {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 10px;
    margin-top: 2px;
    color: black;
  }

  .notificationIcon {
    position: absolute;
    right: 4px;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #00bcd4;
  }
`;

const MenuHeader = styled.div`
  color: black;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  width: 240px;
  border-bottom: 1px solid black;

  display: flex;
  align-items: center;
  padding: 0 16px 4px 16px;

  span {
    width: 100%;
    text-align: center;
    padding-top: 4px;
  }
`;

const DrawerHeading = styled.div`
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);

  svg {
    margin-right: 5px;
  }
`;
