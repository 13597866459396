import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { isComplete } from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectHasJobItemIdsByJobId } from "../jobItem/hasJobItemIdsByJobId";
import { selectJob } from "./index";

export const selectJobLockStatusChange = createCachedSelector(
  [selectJob, selectHasJobItemIdsByJobId],
  (job, hasJobItems) => hasJobItems && isComplete(job)
)(selectPropsJobId);

export const useJobLockStatusChange = jobId =>
  useSelector(state => selectJobLockStatusChange(state, { jobId }));
