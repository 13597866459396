import { ReactNode, useContext } from "react";

import { getDay, getEachDay, isToday } from "../../../lib/dates";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

interface DatesConsumerProps {
  children: (props: {
    date: string;
    dayWidth: number;
    dayOfWeek: number;
    offsetX: number;
    isToday: boolean;
  }) => ReactNode;
}

function DatesConsumer({ children }: DatesConsumerProps) {
  const { viewportStartDate, viewportEndDate, dayWidth, getOffsetXAtDate } =
    useContext(ScheduleScrollContext);

  return (
    <>
      {getEachDay(viewportStartDate, viewportEndDate).map((date: string) =>
        children({
          dayWidth,
          date,
          dayOfWeek: getDay(date),
          offsetX: getOffsetXAtDate(date),
          isToday: isToday(date),
        })
      )}
    </>
  );
}

export default DatesConsumer;
