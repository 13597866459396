import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_ITEM_SUB_ITEM_CREATE } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { useKeyEvent } from "../../../lib/hooks/useKeyEvent";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import {
  FullWidthBaseItem,
  hiddenElement,
  SubItemCheckedBox,
  SubItemTextArea
} from "../styles";

const NewSubItem = () => {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const isJobEditable = useIsJobEditable(jobId);

  const [description, setDescription] = useState("");

  const [ref, focused, setFocused] = useFocusStateRef({
    jobItemId,
    key: FOCUS_KEYS.ITEM_NEW_SUB_ITEM
  });

  const dispatch = useDispatch();

  const doJobItemSubItemCreate = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_SUB_ITEM_CREATE, {
        jobItemId,
        value: description
      })
    );
  };

  const handleChange = e => {
    setDescription(String(e.target.value));
  };

  const handleBlur = e => {
    setFocused(false);
    create();
  };

  const handleFocus = e => {
    setFocused(true);
  };

  const create = () => {
    doJobItemSubItemCreate();
    setDescription("");
  };

  useKeyEvent(
    isJobEditable && focused,
    "keydown",
    e => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        create();
      }
    },
    create
  );

  useKeyEvent(isJobEditable && focused, "keydown", e => {
    if (e.keyCode === 13) {
      if (!e.shiftKey) {
        // scroll by height of the sub item
        window.requestAnimationFrame(() => window.scrollBy(0, 31));
      } else {
        // scroll by the line height of the text area
        window.requestAnimationFrame(() => window.scrollBy(0, 17));
      }
    }
  });

  return (
    <Container hidden={!focused || !isJobEditable}>
      <SubItemCheckedBox checked={false} disabled />
      <SubItemTextArea
        ref={ref}
        value={description}
        placeholder="Add checklist item"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      ></SubItemTextArea>
    </Container>
  );
};

export default NewSubItem;

const Container = styled(FullWidthBaseItem)`
  height: fit-content;
  min-height: 30px;
  ${hiddenElement}
`;
