import type { TStatisticFieldEnum } from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  StatisticFieldEnum,
} from "@streamtimefe/entities";

import type { TReportingEntityDescriptor } from "./ReportingEntityDescriptors";

export const ReportingStatisticFields: Record<
  TReportingEntityDescriptor,
  readonly TStatisticFieldEnum[]
> = {
  [EntityDescriptorEnum.Job]: [
    StatisticFieldEnum.JobProfitMargin,
    StatisticFieldEnum.JobProfit,
    StatisticFieldEnum.JobFinalBudget,
    StatisticFieldEnum.JobBudgetVariancePercentage,
    StatisticFieldEnum.JobBudgetVariance,
    StatisticFieldEnum.JobTotalLoggedCostExTax,
    StatisticFieldEnum.JobTotalPlannedExTax,
    StatisticFieldEnum.JobTotalLoggedExTax,
    StatisticFieldEnum.JobTotalPlannedExpensesExTax,
    StatisticFieldEnum.JobTotalLoggedMinutes,
    StatisticFieldEnum.JobTotalPlannedMinutes,
    StatisticFieldEnum.JobTotalNonBillableLoggedMinutes,
    StatisticFieldEnum.JobTotalNonBillablePlannedMinutes,
    StatisticFieldEnum.JobTotalNonBillablePlannedTimeExTax,
    StatisticFieldEnum.JobTotalNonBillableLoggedTimeExTax,
    StatisticFieldEnum.JobTotalLoggedTimeExTax,
    StatisticFieldEnum.JobTotalLoggedTimeCostExTax,
    StatisticFieldEnum.JobTotalLoggedExpensesCostExTax,
    StatisticFieldEnum.JobTotalLoggedExpensesExTax,
    StatisticFieldEnum.JobTotalLoggedTimeProfitExTax,
    StatisticFieldEnum.JobTotalLoggedExpensesProfitExTax,
    StatisticFieldEnum.JobTotalPlannedExpensesCostExTax,
    StatisticFieldEnum.JobDaysUntilDueDate,
    StatisticFieldEnum.JobTotalPlannedTimeExTax,
    StatisticFieldEnum.JobTotalLoggedNonBillableTimeCostExTax,
  ],
  [EntityDescriptorEnum.JobItemUserRoleUnscheduledTime]: [
    StatisticFieldEnum.JobItemUserRolePlannedMinutes,
    StatisticFieldEnum.JobItemUserRolePlannedTimeExTax,
    StatisticFieldEnum.JobItemUserRolePlannedTimeCostExTax,
  ],
  [EntityDescriptorEnum.JobItemUserRolePlannedTime]: [
    StatisticFieldEnum.JobItemUserRolePlannedMinutes,
    StatisticFieldEnum.JobItemUserRolePlannedTimeExTax,
    StatisticFieldEnum.JobItemUserRolePlannedTimeCostExTax,
  ],
  [EntityDescriptorEnum.User]: [StatisticFieldEnum.UserAvailability],
  [EntityDescriptorEnum.Quote]: [
    StatisticFieldEnum.QuoteTotalAmountExTax,
    StatisticFieldEnum.QuoteTotalAmountIncTax,
    StatisticFieldEnum.QuoteInvoicedExTax,
    StatisticFieldEnum.QuoteInvoicedPercentage,
    StatisticFieldEnum.QuoteInvoicedBalanceRemaining,
  ],
  [EntityDescriptorEnum.LoggedExpense]: [
    StatisticFieldEnum.LoggedExpenseTotalCostExTax,
    StatisticFieldEnum.LoggedExpenseTotalAmountExTax,
    StatisticFieldEnum.LoggedExpenseProfitExTax,
    StatisticFieldEnum.LoggedExpenseInvoicedExTax,
    StatisticFieldEnum.LoggedExpenseInvoicedPercentage,
    StatisticFieldEnum.LoggedExpenseInvoicedBalanceRemaining,
  ],
  [EntityDescriptorEnum.LoggedTime]: [
    StatisticFieldEnum.LoggedTimeTotalExTax,
    StatisticFieldEnum.LoggedTimeMinutes,
    StatisticFieldEnum.LoggedTimeTotalCostExTax,
  ],
  [EntityDescriptorEnum.Invoice]: [
    StatisticFieldEnum.InvoiceAmountExTax,
    StatisticFieldEnum.InvoiceAmountIncTax,
    StatisticFieldEnum.InvoiceAmountPaidIncTax,
    StatisticFieldEnum.InvoiceBalanceIncTax,
  ],
} as const;
