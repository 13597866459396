import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_TAX_RATES
} from "../../../lib/constants";
import { isDeleted } from "../../../lib/entities/roleRateEntity";
import {
  getAsOption,
  getTaxRateName
} from "../../../lib/entities/taxRateEntity";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityOptionsReducer from "../../helpers/createEntityOptionsReducer";
import createKeyValueReducer from "../../helpers/createKeyValueReducer";
import createReducer from "../../helpers/createReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";

const byNameReducer = createKeyValueReducer(getTaxRateName);

const optionsReducer = createEntityOptionsReducer(
  entity => entity && getAsOption(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    byName: byNameReducer(state.byName, changedEntities),
    options: optionsReducer(state.options, changedEntities)
  };
};

const receiveEntitiesReducer = (state, action) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, action.payload[ENTITY_NAME_TAX_RATES]),
    isDeleted
  );

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_TAX_RATES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer
  }
);
