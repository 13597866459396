import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getPermissionsSnapshot } from "st-shared/stores";
import styled from "styled-components";

import {
  SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL,
  SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE,
  SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE,
} from "../../../lib/constants";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectJobPhaseOrderByJobId } from "../../../redux/selectors/jobItemSelectors";
import { EmptyJobMessage } from "./EmptyJobMessage";
import ScheduleExtraHoursGroup from "./ScheduleExtraHoursGroup";
import ScheduleJobPhaseGroup from "./ScheduleJobPhaseGroup";

const mapState = (state, props) => ({
  jobPhaseIds: selectJobPhaseOrderByJobId(state, props),
  canEditJobs: getPermissionsSnapshot().canEditJobs,
});

const mapDispatch = (dispatch) => ({
  doOpenCreateJobItemModal: (jobId) =>
    dispatch(createAction(SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL, { jobId })),
  doCollapsePhases: (ids) =>
    dispatch(createAction(SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE, { ids })),
  doExpandPhases: (ids) =>
    dispatch(createAction(SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE, { ids })),
});

class ScheduleJobPhasesGroup extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    jobPhaseIds: entityIdListType.isRequired,
    canEditJobs: PropTypes.bool.isRequired,
    doOpenCreateJobItemModal: PropTypes.func.isRequired,
    doCollapsePhases: PropTypes.func.isRequired,
    doExpandPhases: PropTypes.func.isRequired,
  };

  addItem = () => {
    const { jobId, doOpenCreateJobItemModal } = this.props;
    doOpenCreateJobItemModal(jobId);
  };

  expandPhases = () => {
    const { jobPhaseIds, doExpandPhases } = this.props;
    doExpandPhases(jobPhaseIds);
  };

  collapsePhases = () => {
    const { jobPhaseIds, doCollapsePhases } = this.props;
    doCollapsePhases(jobPhaseIds);
  };

  render() {
    const { jobId, jobPhaseIds, canEditJobs } = this.props;

    return (
      <Wrapper>
        {jobPhaseIds.length > 0 ? (
          jobPhaseIds.map((jobPhaseId) => (
            <ScheduleJobPhaseGroup
              id={jobPhaseId}
              jobId={jobId}
              key={jobPhaseId}
              collapsePhases={this.collapsePhases}
              expandPhases={this.expandPhases}
            />
          ))
        ) : (
          <EmptyJobMessage onClick={this.addItem} canEditJobs={canEditJobs} />
        )}
        <ScheduleExtraHoursGroup id={jobId} />
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobPhasesGroup);

const Wrapper = styled.div`
  width: 100%;
  background: white;
  overflow: hidden;

  .ScheduleJobPhaseGroupWrapper:last-child
    .ScheduleJobItemGroupWrapper:last-child
    .ScheduleJobItemUserGroupContainer:last-child {
    border-bottom: none;
  }
`;
