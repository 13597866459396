import { ReactNode } from "react";

import { useScheduleUiFetchedLoggedTimes } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import WeeksConsumer from "./WeeksConsumer";

interface AvailabilityWeeksConsumerProps {
  children: (props: {
    date: string;
    left: number;
    dayWidth: number;
  }) => ReactNode;
}

function AvailabilityWeeksConsumer({
  children,
}: AvailabilityWeeksConsumerProps) {
  const fetchedLoggedTimes = useScheduleUiFetchedLoggedTimes();

  return (
    <WeeksConsumer>
      {({ date, left, dayWidth }) => {
        if (fetchedLoggedTimes.usersByDate[date]) {
          return children({ date, left, dayWidth });
        }
        return null;
      }}
    </WeeksConsumer>
  );
}

export default AvailabilityWeeksConsumer;
