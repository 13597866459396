import { call, put, takeEvery } from "redux-saga/effects";
import { FormInputException } from "st-shared/lib";

import { uploadBranchLogoApi } from "../../../lib/API/branchLogoAPI";
import {
  BRANCH_LOGO_UPLOAD_FAILED,
  BRANCH_LOGO_UPLOAD_REQUEST,
  BRANCH_LOGO_UPLOAD_SUCCEEDED,
  ENTITIES_RECEIVED,
} from "../../../lib/constants";
import { TWENTY_MEGABYTES } from "../../../lib/constants/numbers";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* upload(action) {
  try {
    const { id, fileData } = action.payload;

    if (fileData.size > TWENTY_MEGABYTES)
      throw new FormInputException("File must be smaller than 20MB");

    const formData = new FormData();
    formData.append("branchLogo", fileData);

    const data = yield call(uploadBranchLogoApi, id, formData);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(BRANCH_LOGO_UPLOAD_SUCCEEDED, { id }));
  } catch (error) {
    yield put(
      createAction(BRANCH_LOGO_UPLOAD_FAILED, {
        ...action.payload,
      })
    );

    sagaError(error);
  }
}

export default function* watchUpload() {
  yield takeEvery(BRANCH_LOGO_UPLOAD_REQUEST, upload);
}
