import clsx from "clsx";

import { Button } from "../../components";
import { getConditionMatchName, TConditionMatchType } from "../../types";
import { buttonCss, containerCss, dividerCss } from "./OperatorButton.css";

type Props = {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  conditionMatchTypeId: TConditionMatchType;
};

export function OperatorButton({
  className,
  onClick,
  conditionMatchTypeId,
}: Props) {
  return (
    <div className={clsx(containerCss, className)}>
      <div className={dividerCss} />
      <Button onClick={onClick} className={buttonCss}>
        {getConditionMatchName(conditionMatchTypeId).toUpperCase()}
      </Button>
    </div>
  );
}
