import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import {
  getScheduleBlockKey,
  isBaseLoggedTime,
  isPaused,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getPath = (blockKey) => `isPausedByBlockKey.${blockKey}`;

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedBlockKeys = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevBlockKey =
        isBaseLoggedTime(prevEntity) && getScheduleBlockKey(prevEntity);
      const newBlockKey =
        isBaseLoggedTime(newEntity) && getScheduleBlockKey(newEntity);

      if (prevBlockKey) pushOnce(touchedBlockKeys, prevBlockKey);
      if (newBlockKey) pushOnce(touchedBlockKeys, newBlockKey);
    });

    touchedBlockKeys.forEach((blockKey) => {
      const baseLoggedTime = get(nextState, `byId.${blockKey}`);

      if (baseLoggedTime) {
        setWith(draft, getPath(blockKey), isPaused(baseLoggedTime), Object);
      } else unset(draft, getPath(blockKey));
    });
  });
