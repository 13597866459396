import consumeCommercialDocument from "../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import DetailTableTextField from "../modules/CommercialDocuments/DetailTableTextField";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doSaveDocument }
}) => ({
  id,
  document,
  getValue: documentMethods.getInvoiceReference,
  setValue: documentMethods.setInvoiceReference,
  isDisplayed: documentMethods.getDisplayOptions(document).displayReference,
  labelKey: "reference",
  doSaveDocument
});

export default consumeCommercialDocument(mapContext)(DetailTableTextField);
