import styled from "styled-components";
import Flex from "../../elements/Flex";
import Milestone from "../../modules/JobMilestone/Milestone";

const ScheduleJobMilestoneGroup = styled(Flex)`
  position: absolute;
  top: 8px;
  border: none;
  justify-content: center;
  pointer-events: none;
  ${Milestone} {
    margin: 0;
    pointer-events: all;
  }
  > div {
    display: none;
    &:first-child {
      display: block;
    }
    &:nth-last-child(-n + 2) {
      display: block;
    }
  }
`;

export default ScheduleJobMilestoneGroup;
