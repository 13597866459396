import { pluralise, SearchItem, type TSearchItem } from "@streamtimefe/utils";

import { EnumOption } from "../../core";
import type {
  TJobGroupPeriodInterval,
  TJobGroupPeriodIntervalEnum,
} from "../../enums";
import { JobGroupPeriodIntervalEnum } from "../../enums";

export function create(
  id: TJobGroupPeriodIntervalEnum
): TJobGroupPeriodInterval {
  return { id, name: getName(id) };
}

export function isMonths(
  jobGroupPeriodInterval: TJobGroupPeriodInterval | TJobGroupPeriodIntervalEnum
) {
  return (
    EnumOption.getId(jobGroupPeriodInterval) ===
    JobGroupPeriodIntervalEnum.Months
  );
}

export function isQuarters(
  jobGroupPeriodInterval: TJobGroupPeriodInterval | TJobGroupPeriodIntervalEnum
) {
  return (
    EnumOption.getId(jobGroupPeriodInterval) ===
    JobGroupPeriodIntervalEnum.Quarters
  );
}

export function getName(
  jobGroupPeriodInterval: TJobGroupPeriodInterval | TJobGroupPeriodIntervalEnum
) {
  const id = EnumOption.getId(jobGroupPeriodInterval);
  switch (id) {
    case JobGroupPeriodIntervalEnum.Months:
      return "Month";
    case JobGroupPeriodIntervalEnum.Quarters:
      return "Quarter";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown Job Group Period Interval Id: ${id}`);
      return "";
  }
}

export function getSearchItem(
  jobGroupPeriodInterval: TJobGroupPeriodIntervalEnum
): TSearchItem<TJobGroupPeriodIntervalEnum> {
  const name = pluralise(getName(jobGroupPeriodInterval), 0);
  return SearchItem.create(
    jobGroupPeriodInterval,
    name,
    name,
    jobGroupPeriodInterval
  );
}
