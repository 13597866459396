import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobPhaseId } from "../index";
import { selectJobItemIdsByJobPhaseId } from "../jobItem/selectJobItemIdsByJobPhaseId";

export const selectJobPhaseJobItemCount = createCachedSelector(
  [selectJobItemIdsByJobPhaseId],
  jobItemIds => jobItemIds.length
)(selectPropsJobPhaseId);

export const useJobPhaseJobItemCount = jobPhaseId =>
  useSelector(state => selectJobPhaseJobItemCount(state, { jobPhaseId }));
