import { useEffect } from "react";

export function useParentCloseOnClick(
  isOpen: boolean | (() => boolean),
  onClose: (...args: any) => void
) {
  useEffect(() => {
    function onClickParentDocument(e: MouseEvent) {
      const open = typeof isOpen === "boolean" ? isOpen : isOpen();
      if (open && onClose) {
        onClose();
        e.preventDefault();
        return false;
      }
      return true;
    }

    if (window.top && window !== window.top) {
      window.top.document.addEventListener("click", onClickParentDocument);
    }

    return () => {
      if (window.top && window !== window.top) {
        window.top.document.removeEventListener("click", onClickParentDocument);
      }
    };
  }, [isOpen, onClose]);
}
