// deprecated

import { CEntity } from "./Entity";
import type { Option } from "./Option";

export const CostingMethods = {
  Item: 1,
  People: 2,
  ValueCalculatedSell: 3,
  ValueUserSell: 4,
} as const;

export type TCostingMethods =
  (typeof CostingMethods)[keyof typeof CostingMethods];

export interface CostingMethod extends Option {
  id: TCostingMethods;
}

export function costingMethodObj(entity: CostingMethod) {
  const obj = {
    entity,
    isItem: function () {
      return entity.id === CostingMethods.Item;
    },
    isPeople: function () {
      return entity.id === CostingMethods.People;
    },
    isValueCalculatedSell: function () {
      return entity.id === CostingMethods.ValueCalculatedSell;
    },
    isValueUserSell: function () {
      return entity.id === CostingMethods.ValueUserSell;
    },
    isBasedByValue: function () {
      return (
        entity.id === CostingMethods.ValueCalculatedSell ||
        entity.id === CostingMethods.ValueUserSell
      );
    },
    isBasedByUser: function () {
      return (
        entity.id === CostingMethods.People ||
        entity.id === CostingMethods.ValueUserSell
      );
    },
    getUnknownIdError: function () {
      return `Unknown Costing Method: ${entity.id} - ${entity.name}`;
    },
  };
  return obj;
}

export function createCostingMethod(id: TCostingMethods): CostingMethod {
  return { id, name: "" };
}

export class CCostingMethod extends CEntity<CostingMethod> {
  get isItem() {
    return this.entity.id === CostingMethods.Item;
  }

  get isPeople() {
    return this.entity.id === CostingMethods.People;
  }

  get isValueCalculatedSell() {
    return this.entity.id === CostingMethods.ValueCalculatedSell;
  }

  get isValueUserSell() {
    return this.entity.id === CostingMethods.ValueUserSell;
  }

  get isBasedByValue() {
    return (
      this.entity.id === CostingMethods.ValueCalculatedSell ||
      this.entity.id === CostingMethods.ValueUserSell
    );
  }

  get isBasedByUser() {
    return (
      this.entity.id === CostingMethods.People ||
      this.entity.id === CostingMethods.ValueUserSell
    );
  }

  getUnknownIdError() {
    return `Unknown Costing Method: ${this.entity.id} - ${this.entity.name}`;
  }

  static createCostingMethod(id: TCostingMethods): CostingMethod {
    return { id, name: "" };
  }
}
