import "@streamtimefe/polyfill";

import { bugsnagStart } from "@streamtimefe/error";
import { modalBridgeClientStart } from "@streamtimefe/modal-bridge";
import { createRoot } from "react-dom/client";

import { App } from "./components/App";

bugsnagStart("oldapp");
modalBridgeClientStart();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
