import isError from "lodash-es/isError";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { deleteJobItemUserAPI } from "../../../lib/API/jobItemUserAPI";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  SCHEDULE_JOB_ITEM_USER_DELETE,
  SCHEDULE_JOB_ITEM_USER_DELETE_ERROR,
  SCHEDULE_JOB_ITEM_USER_UNASSIGN,
} from "../../../lib/constants";
import { getTotalIncompleteMinutes } from "../../../lib/entities/jobItemUserEntity";
import { feToWebConfirmUnassignJobItemUser } from "../../../lib/WebAppAPI/fePages/genericWeb";
import { EntityNames } from "../../../state/entities/types";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectScheduleLoggedTimeIdsByJobItemUserId } from "../../selectors/scheduleSelectors";

function* jobItemUserUnassign(action) {
  const { id, jobItemUser } = action.payload;

  try {
    const totalIncompleteMinutes = getTotalIncompleteMinutes(jobItemUser);

    if (totalIncompleteMinutes > 0)
      yield call(feToWebConfirmUnassignJobItemUser, jobItemUser);

    yield put(createAction(SCHEDULE_JOB_ITEM_USER_DELETE, { id }));

    const { data } = yield call(deleteJobItemUserAPI, id);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    const scheduleLoggedTimeIds = yield select(
      selectScheduleLoggedTimeIdsByJobItemUserId,
      { id }
    );

    yield put(
      createAction(ENTITIES_REMOVED, {
        entityName: EntityNames.ScheduleLoggedTimes,
        ids: scheduleLoggedTimeIds,
      })
    );
  } catch (error) {
    yield put(
      createAction(SCHEDULE_JOB_ITEM_USER_DELETE_ERROR, {
        ...action.payload,
        error,
      })
    );
    if (isError(error)) sagaError(error);
  }
}

export default function* watchJobItemUserUnassign() {
  yield takeLatest([SCHEDULE_JOB_ITEM_USER_UNASSIGN], jobItemUserUnassign);
}
