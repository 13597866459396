import { EntityClass, MasterJobItem } from "@streamtimefe/entities";
import { useMemo } from "react";
import { isDefined } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { MasterJobItemStore } from "./MasterJobItem.store";

export const useMasterJobItemEntity = createUseEntityStoreEntity(
  EntityClass.MasterJobItem
);

export const getMasterJobItemEntity = createGetEntityStoreEntity(
  EntityClass.MasterJobItem
);

export const useMasterJobItem = createUseSingleEntity(MasterJobItemStore);

function useMasterJobItemEntities() {
  return useEntityStoreEntities(EntityClass.MasterJobItem);
}

export function useMasterJobItemStore() {
  return MasterJobItemStore.useStore();
}

export function useMasterJobItemActions() {
  return MasterJobItemStore.useActions();
}

export function useActiveMasterJobItems() {
  const masterJobItems = useMasterJobItemEntities();
  return useMemo(
    () =>
      Object.values(masterJobItems)
        .filter(isDefined)
        .filter((branch) => branch.active),
    [masterJobItems]
  );
}

export function useActiveMasterJobItemSearchItems() {
  const masterJobItems = useActiveMasterJobItems();
  return useMemo(
    () => MasterJobItem.getSearchItems(masterJobItems),
    [masterJobItems]
  );
}
