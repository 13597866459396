import { createContext, useContext } from "react";

export type TGridListContext = { size: "small" | "medium"; query: string };

export const GridListContext = createContext<TGridListContext>({
  size: "medium",
  query: "",
});

export function useGridListContext() {
  const context = useContext(GridListContext);
  if (!context) {
    throw new Error(`Missing GridListContext`);
  }
  return context;
}
