import { createIcon } from "../../icon/Icon";

export const IconDuplicate = createIcon(
  <>
    <path d="M2 2V20H4V4H20V2H2Z" />
    <path d="M13 15H10V13H13V10H15V13H18V15H15V18H13.0526L13 15Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6H6V22H22V6ZM8 8H20V20H8V8Z"
    />
  </>,
  "IconDuplicate"
);
