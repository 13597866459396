import numeral from "numeral";
import { useSelector } from "react-redux";

import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemEntity";
import { getJobId } from "../../../lib/entities/jobPhaseEntity";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { selectJobItem } from "../jobItem";
import { selectJobItemOrderByJobIdPhaseId } from "../jobItem/selectJobItemOrderByJobIdPhaseId";
import { selectJobPhase } from "./index";

export const selectJobPhaseHours = (state, props) => {
  const { jobPhaseId } = props;
  const jobPhase = selectJobPhase(state, props);
  const jobId = getJobId(jobPhase);
  const jobPhaseItemIds = selectJobItemOrderByJobIdPhaseId(state, {
    jobId,
    jobPhaseId,
  });

  if (jobPhaseItemIds.length === 0) return "";

  const totalLoggedMinutes = numeral(0);
  const totalPlannedMinutes = numeral(0);

  jobPhaseItemIds.forEach((jobItemId) => {
    const jobItem = selectJobItem(state, { jobItemId });
    totalLoggedMinutes.add(getTotalUsedMinutes(jobItem));
    totalPlannedMinutes.add(getTotalPlannedMinutes(jobItem));
  });

  const formattedTotalLoggedMinutes = convertMinutesToTimeHM(
    totalLoggedMinutes.value()
  );
  const formattedTotalPlannedMinutes = convertMinutesToTimeHM(
    totalPlannedMinutes.value()
  );

  const warning =
    totalPlannedMinutes.value() > 0 &&
    totalLoggedMinutes.value() > totalPlannedMinutes.value();

  return [
    `${formattedTotalLoggedMinutes} / ${formattedTotalPlannedMinutes}`,
    warning,
  ];
};

export const useJobPhaseHours = (jobPhaseId) =>
  useSelector((state) => selectJobPhaseHours(state, { jobPhaseId }));
