import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobCalculatedTotalPlannedExTax } from "../job/selectJobCalculatedTotalPlannedExTax";
import { selectTotalLoggedTimeByCostVsSellMode } from "./totalLoggedTimeByCostVsSellMode";

export const selectJumbotronIsUsedOverPlanned = createCachedSelector(
  [selectJobCalculatedTotalPlannedExTax, selectTotalLoggedTimeByCostVsSellMode],
  (calculatedTotalPlannedExTax, totalLoggedTimeByCostVsSellMode) => {
    if (numeral(calculatedTotalPlannedExTax).value() > 0)
      return (
        numeral(totalLoggedTimeByCostVsSellMode)
          .subtract(numeral(calculatedTotalPlannedExTax).value())
          .value() > 0
      );

    return false;
  }
)(selectPropsJobId);

export const useJumbotronIsUsedOverPlanned = jobId =>
  useSelector(state => selectJumbotronIsUsedOverPlanned(state, { jobId }));
