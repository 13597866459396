import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobItemRoleStore } from "./JobItemRole.store";

export const useJobItemRoleEntity = createUseEntityStoreEntity(
  EntityClass.JobItemRole
);

export const getJobItemRoleEntity = createGetEntityStoreEntity(
  EntityClass.JobItemRole
);

export const useJobItemRole = createUseSingleEntity(JobItemRoleStore);

export function useJobItemRoleStore() {
  return JobItemRoleStore.useStore();
}

export function useJobItemRoleActions() {
  return JobItemRoleStore.useActions();
}
