import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { QuoteStore } from "./Quote.store";

export * from "./selectors";

export const useQuoteEntity = createUseEntityStoreEntity(EntityClass.Quote);

export const getQuoteEntity = createGetEntityStoreEntity(EntityClass.Quote);

export const useQuote = createUseSingleEntity(QuoteStore);

export function useQuoteStore() {
  return QuoteStore.useStore();
}

export function useQuoteActions() {
  return QuoteStore.useActions();
}
