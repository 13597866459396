import { call, put, select } from "redux-saga/effects";
import { saveJobMilestoneAPI } from "../../../lib/API/jobMilestoneAPI";
import {
  ENTITIES_RECEIVED,
  JOB_MILESTONE_SAVE,
  JOB_MILESTONE_SAVING,
  JOB_MILESTONE_SAVED,
  JOB_MILESTONE_SAVE_ERROR
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectJobMilestoneById,
  selectJobMilestoneUi
} from "../../selectors/jobMilestoneSelectors";

function* saveMilestone(action) {
  try {
    const { id } = action.payload;

    yield put(createAction(JOB_MILESTONE_SAVING, action.payload));

    const jobMilestone = yield select(selectJobMilestoneById, { id });

    const { data } = yield call(saveJobMilestoneAPI, jobMilestone);

    const { isDeleting } = yield select(selectJobMilestoneUi, {
      id
    });

    if (isDeleting) return;

    const { jobMilestones, ...entities } = data;

    yield put(createAction(ENTITIES_RECEIVED, entities));

    yield put(
      createAction(JOB_MILESTONE_SAVED, {
        ...action.payload,
        jobMilestones
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_MILESTONE_SAVE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchSaveMilestone() {
  yield takeLatestBy(
    [JOB_MILESTONE_SAVE],
    saveMilestone,
    action => action.payload.id
  );
}
