import type { DateType } from "../datetime";
import { datetime } from "../datetime";
import type { TSearchItem } from "../search";
import { toLowerCaseString } from "../strings";

export function defaultSort(a: string, b: string) {
  return toLowerCaseString(a).localeCompare(toLowerCaseString(b));
}

export function defaultSortName(a: { name: string }, b: { name: string }) {
  return toLowerCaseString(a.name).localeCompare(toLowerCaseString(b.name));
}

export function defaultSortOrderId(
  a: { orderId: number },
  b: { orderId: number }
) {
  return a.orderId < b.orderId ? -1 : 1;
}

export function defaultSortDisplayName(
  a: { displayName: string },
  b: { displayName: string }
) {
  return toLowerCaseString(a.displayName).localeCompare(
    toLowerCaseString(b.displayName)
  );
}

export function defaultSortSearchItems<T>(
  a: TSearchItem<T>,
  b: TSearchItem<T>
) {
  return toLowerCaseString(a.value).localeCompare(toLowerCaseString(b.value));
}

export function defaultSortDate(date1: DateType, date2: DateType) {
  return datetime.isBefore(date1, date2) ? -1 : 1;
}
