import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { entityIdType } from "../../lib/types/entityTypes";
import {
  selectJobTimelineZoomLevel,
  selectJobTimelineHeight
} from "../../redux/selectors/jobTimelineSelectors";
import Anchor from "./JobTimelineScrollProvider/Anchor";
import BackgroundConsumer from "./JobTimelineScrollProvider/BackgroundConsumer";
import TodayConsumer from "./JobTimelineScrollProvider/TodayConsumer";
import WeekendsConsumer from "./JobTimelineScrollProvider/WeekendsConsumer";

const mapState = (state, props) => ({
  zoomLevel: selectJobTimelineZoomLevel(state),
  contentHeight: selectJobTimelineHeight(state, props)
});

const mapDispatch = null;

class JobTimelineBackground extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    contentHeight: PropTypes.number.isRequired,
    bottomPadding: PropTypes.number
  };

  static defaultProps = {
    bottomPadding: 0
  };

  render() {
    const { zoomLevel, contentHeight, bottomPadding } = this.props;

    return (
      <Wrapper style={{ height: contentHeight + bottomPadding }}>
        <Anchor>
          <BackgroundConsumer zoomLevel={zoomLevel} />
          {zoomLevel > 1 && (
            <WeekendsConsumer>
              {({ date, width, left }) => (
                <Weekend key={date} style={{ left, width }} />
              )}
            </WeekendsConsumer>
          )}
          <TodayConsumer>
            {({ width, left }) => <Today style={{ left, width }} />}
          </TodayConsumer>
        </Anchor>
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineBackground);

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 100%;
`;

export const Weekend = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  border-left: 1px solid var(--color-gray-bright);
  background: var(--background-non-working-day);
`;

const Today = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--color-gray);
  opacity: 0.4;
  pointer-events: none;
`;
