import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { InvoiceStore } from "./Invoice.store";

export * from "./selectors";

export const useInvoiceEntity = createUseEntityStoreEntity(EntityClass.Invoice);

export const getInvoiceEntity = createGetEntityStoreEntity(EntityClass.Invoice);

export const useInvoice = createUseSingleEntity(InvoiceStore);

export function useInvoiceStore() {
  return InvoiceStore.useStore();
}

export function useInvoiceActions() {
  return InvoiceStore.useActions();
}
