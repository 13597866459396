import React from "react";
import styled from "styled-components";
import { domNodeType } from "../../../lib/types/domTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import Menu from "./index";

class SubMenu extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    anchorEl: domNodeType
  };

  static defaultProps = {
    anchorEl: null
  };

  render() {
    const { children, anchorEl, ...props } = this.props;
    return (
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: -10
        }}
        hideBackdrop
        {...props}
      >
        {children}
      </StyledMenu>
    );
  }
}

export default SubMenu;

const StyledMenu = styled(Menu)`
  pointer-events: none;
  .MuiPaper-root {
    pointer-events: auto;
  }
`;
