import MuiListItemText from "@material-ui/core/ListItemText";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";

const ListItemText = ({ ...props }) => <MuiListItemText {...props} />;

ListItemText.propTypes = {
  children: reactNodesType.isRequired
};

ListItemText.defaultProps = {};

export default styled(ListItemText)`
  color: var(--color-charcoal);
  margin: 0;

  span[class*="MuiTypography"] {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;
