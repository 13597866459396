import { put, select, takeLatest } from "redux-saga/effects";

import {
  JOB_DETAILS_FORCE_SAVE,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SET_PREVIOUS_JOB_ITEM,
  JOB_PHASE_SAVE,
  JOB_PHASE_SET_PREVIOUS_JOB_PHASE,
  JOB_SAVE,
  JOB_SET_PREVIOUS_JOB,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { selectJobPreviousJob } from "../../selectors/job/ui/previousJob";
import {
  selectJobDetailsUiCurrentFocusJobId,
  selectJobDetailsUiCurrentFocusJobItemId,
  selectJobDetailsUiCurrentFocusJobPhaseId,
} from "../../selectors/jobDetails/ui/currentFocus";
import { selectJobItem } from "../../selectors/jobItem";
import { selectJobItemPreviousJobItem } from "../../selectors/jobItem/ui/previousJobItem";
import { selectJobPhasePreviousJobPhase } from "../../selectors/jobPhase/ui/previousJobPhase";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";
import { saveJobItem } from "../jobItemSaga/watchSaveJobItemCard";
import { saveNewJobItem } from "../jobItemSaga/watchSaveNewJobItemCard";
import { saveJobPhase } from "../jobPhaseSaga/watchJobPhaseSave";
import { saveNewJobPhase } from "../jobPhaseSaga/watchJobPhaseSaveNew";
import { saveJob } from "./watchJobSave";

function* checkForceSave() {
  try {
    yield put(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: null,
      })
    );

    yield checkSaveJob();

    yield checkSaveJobItem();

    yield checkSaveJobPhase();
  } catch (error) {
    sagaError(error);
  }
}

function* checkSaveJob() {
  const jobId = yield select(selectJobDetailsUiCurrentFocusJobId);
  const previousJob = yield select(selectJobPreviousJob, {
    jobId,
  });

  if (previousJob) {
    const job = yield select(selectJob, {
      jobId,
    });

    yield saveJob(
      createAction(JOB_SAVE, {
        jobId,
        job,
        previousJob,
      })
    );

    yield put(
      createAction(JOB_SET_PREVIOUS_JOB, {
        jobId,
        job: null,
      })
    );
  }
}

export function* checkSaveJobItem() {
  const jobItemId = yield select(selectJobDetailsUiCurrentFocusJobItemId);
  const previousJobItem = yield select(selectJobItemPreviousJobItem, {
    jobItemId,
  });

  if (previousJobItem) {
    const jobItem = yield select(selectJobItem, {
      jobItemId,
    });

    if (jobItemId > 0) {
      yield saveJobItem(
        createAction(JOB_ITEM_SAVE_ITEM_CARD, {
          jobItemId,
          jobItem,
          prevJobItem: previousJobItem,
        })
      );
    } else {
      yield saveNewJobItem(
        createAction(JOB_ITEM_SAVE_ITEM_CARD, {
          jobItemId,
          jobItem,
          prevJobItem: previousJobItem,
        })
      );
    }

    yield put(
      createAction(JOB_ITEM_SET_PREVIOUS_JOB_ITEM, {
        jobItemId,
        jobItem: null,
      })
    );
  }
}

function* checkSaveJobPhase() {
  const jobPhaseId = yield select(selectJobDetailsUiCurrentFocusJobPhaseId);
  const previousJobPhase = yield select(selectJobPhasePreviousJobPhase, {
    jobPhaseId,
  });

  if (previousJobPhase) {
    const jobPhase = yield select(selectJobPhaseById, {
      id: jobPhaseId,
    });

    if (jobPhaseId > 0) {
      yield saveJobPhase(
        createAction(JOB_PHASE_SAVE, {
          jobPhaseId,
          jobPhase,
          previousJobPhase,
        })
      );
    } else {
      yield saveNewJobPhase(
        createAction(JOB_PHASE_SAVE, {
          jobPhaseId,
          jobPhase,
          previousJobPhase,
        })
      );
    }

    yield put(
      createAction(JOB_PHASE_SET_PREVIOUS_JOB_PHASE, {
        jobPhaseId,
        jobPhase: null,
      })
    );
  }
}

export default function* watchJobCheckForceSave() {
  yield takeLatest(JOB_DETAILS_FORCE_SAVE, checkForceSave);
}
