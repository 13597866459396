import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE,
  DATA_ATTRIBUTE_USER,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { getJobIdIfNoItemBaseLoggedTime } from "../../../lib/entities/scheduleLoggedTimeEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectBlockContainerHeightByUserIdJobIdNoItem } from "../../../redux/selectors/scheduleSelectors";
import { isMaxZoomLevel } from "../../../state/ui/schedule/lib";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import ScheduleBlock from "../ScheduleBlocks/ScheduleBlock";
import ScheduleBlocksContainer from "../ScheduleLayout/ScheduleBlocksContainer";
import ScheduleBlocksWrapper from "../ScheduleLayout/ScheduleBlocksWrapper";
import ScheduleUserAvailability from "../ScheduleLayout/ScheduleUserAvailability";
import ScheduleUserNonWorkingDays from "../ScheduleLayout/ScheduleUserNonWorkingDays";
import ScheduleUtilisation from "../ScheduleLayout/ScheduleUtilisation";
import Anchor from "../ScheduleScrollProvider/Anchor";
import { BlocksByJobIdNoItemConsumer } from "../ScheduleScrollProvider/BlockConsumer";

const mapState = (state, props) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
  blocksHeight: selectBlockContainerHeightByUserIdJobIdNoItem(state, {
    id: props.userId,
    jobId: props.id,
  }),
});

const mapDispatch = null;

const attrUserId = getAttrPropString(DATA_ATTRIBUTE_USER);
const attrJobId = getAttrPropString(DATA_ATTRIBUTE_JOB);
const attrScheduleFilterMode = getAttrPropString(
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE
);

class ScheduleExtraHoursUserTodos extends React.PureComponent {
  filterMode = ScheduleUiBlockFilterModes.UserJobNoItemTodos;

  static propTypes = {
    id: entityIdType.isRequired,
    userId: entityIdType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    blocksHeight: PropTypes.number.isRequired,
  };

  blockFilter = (scheduleLoggedTime) => {
    const { id } = this.props;
    return getJobIdIfNoItemBaseLoggedTime(scheduleLoggedTime) === id;
  };

  render() {
    const { id, userId, blocksHeight, zoomLevel } = this.props;
    const pointerEventAttrs = {
      [attrJobId]: id,
      [attrUserId]: userId,
      [attrScheduleFilterMode]: this.filterMode,
    };

    return (
      <ScheduleBlocksWrapper style={{ height: blocksHeight }}>
        <ScheduleUserNonWorkingDays id={userId} />
        <ScheduleUserAvailability id={userId} compactMode />
        {isMaxZoomLevel(zoomLevel) ? (
          <ScheduleBlocksContainer {...pointerEventAttrs}>
            <Anchor>
              <BlocksByJobIdNoItemConsumer id={userId} jobId={id}>
                {(blockKey) => (
                  <ScheduleBlock key={blockKey} blockKey={blockKey} />
                )}
              </BlocksByJobIdNoItemConsumer>
            </Anchor>
          </ScheduleBlocksContainer>
        ) : (
          <ScheduleUtilisation
            id={userId}
            filterMode={this.filterMode}
            blockFilter={this.blockFilter}
            {...pointerEventAttrs}
          />
        )}
      </ScheduleBlocksWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleExtraHoursUserTodos);
