import clsx from "clsx";
import { keys } from "lodash-es";
import { Icon, StSortArrow } from "st-shared/components";
import {
  CReportingSavedSegment,
  ReportingFilterGroupTypeNullName,
} from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";
import { useCustomerCurrency } from "st-shared/stores";
import { rotate180 } from "st-shared/theme";
import { getFilterName } from "st-shared/types";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentFilterGroupType,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSortColumn,
  useReportingSavedSegmentSortTime,
} from "../../state/stores/savedSegmentSelectors";
import {
  useReportingSeriesCalculatedTime,
  useReportingSeriesFetching,
} from "../../state/stores/searchSelectors";
import { loadingBlockInvert, loadingColumns } from "./Table.css";
import {
  headerInitialColumn,
  headerSortIcon,
  headerText,
  tableHeaderCss,
  tableHeaderTimeCss,
} from "./TableHeader.css";
import { TableHeaderColumns } from "./TableHeaderColumns";

export function TableHeader() {
  const filterGroupType = useReportingSavedSegmentFilterGroupType();
  const customerCurrency = useCustomerCurrency();
  const searchType = useReportingSavedSegmentSearchType();
  const sortColumn = useReportingSavedSegmentSortColumn();
  const sortTime = useReportingSavedSegmentSortTime();
  const sortInfo =
    searchType === ReportingSearchType.ColumnSeries ? sortColumn : sortTime;

  function setNewSortOrder() {
    const order =
      sortInfo.id === null
        ? CReportingSavedSegment.switchSortOrder(sortInfo.order)
        : sortInfo.order;

    if (searchType === ReportingSearchType.ColumnSeries) {
      reportingStore().savedSegment.actions.setSortColumn({ id: null, order });
    } else {
      reportingStore().savedSegment.actions.setSortTime({ id: null, order });
    }
  }

  return (
    <div className={tableHeaderCss}>
      <div className={headerInitialColumn} onClick={setNewSortOrder}>
        <span className={headerText}>
          {filterGroupType
            ? getFilterName(filterGroupType, customerCurrency)
            : ReportingFilterGroupTypeNullName}
        </span>
        {sortInfo.id === null && (
          <Icon
            className={clsx(
              headerSortIcon,
              sortInfo.order === "asc" && rotate180
            )}
            icon={StSortArrow}
            size={11}
          />
        )}
      </div>
      {searchType === ReportingSearchType.ColumnSeries && (
        <TableHeaderColumns />
      )}
      {searchType === ReportingSearchType.TimeSeries && <HeaderTime />}
    </div>
  );
}

function HeaderTime() {
  const formattedTime = useReportingSeriesCalculatedTime();
  const isFetching = useReportingSeriesFetching();

  const sortTime = useReportingSavedSegmentSortTime();

  function setNewSortOrder(name: string) {
    const order =
      sortTime.id === name
        ? CReportingSavedSegment.switchSortOrder(sortTime.order)
        : sortTime.order;

    reportingStore().savedSegment.actions.setSortTime({ id: name, order });
  }

  if (isFetching) {
    return (
      <>
        {loadingColumns.map((_, i) => (
          <div key={i} className={tableHeaderTimeCss}>
            <div className={loadingBlockInvert} />
          </div>
        ))}
      </>
    );
  }

  if (formattedTime.length === 0) return null;

  return (
    <>
      {keys(formattedTime[0].matrix).map((name) => (
        <div
          key={name}
          className={tableHeaderTimeCss}
          onClick={() => setNewSortOrder(name)}
        >
          <span className={headerText}>{name}</span>
          {sortTime.id === name && (
            <Icon
              className={clsx(
                headerSortIcon,
                sortTime.order === "asc" && rotate180
              )}
              icon={StSortArrow}
              size={11}
            />
          )}
        </div>
      ))}
    </>
  );
}
