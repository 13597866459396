import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { costingMethodObj } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";

import {
  NUMBER_FORMAT_NO_DECIMAL,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../../../lib/constants";
import {
  getSellRateNullable,
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
} from "../../../lib/entities/jobItemEntity";
import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectJobItem } from "./index";
import { selectIsEditingJobItem } from "./ui/isEditing";

const calculateSellRate = (jobItem) => {
  if (costingMethodObj(jobItem.costingMethod).isBasedByValue()) {
    if (getTotalPlannedMinutes(jobItem) > 0) {
      return numeral(getTotalPlannedMoney(jobItem))
        .divide(numeral(getTotalPlannedMinutes(jobItem)).divide(60).value())
        .format(NUMBER_FORMAT_NO_DECIMAL);
    }
  } else {
    return getSellRateNullable(jobItem);
  }
  return null;
};

export const getInitialItemRateInput = (jobItem) => {
  const sellRate = calculateSellRate(jobItem);
  if (sellRate === null) {
    return "";
  }
  return numeral(sellRate).format(NUMBER_FORMAT_TWO_DECIMALS);
};

export const selectJobItemRate = createCachedSelector(
  [selectJobItem, selectCustomerCurrency, selectJobCurrency],
  (jobItem, customerCurrency, jobCurrency) => {
    const sellRate = calculateSellRate(jobItem);

    if (sellRate === null) {
      return "";
    }
    return formatForeignCurrency(customerCurrency, {
      value: sellRate,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }
)((state, props) => `${props.jobId}-${props.jobItemId}`);

export const selectJobItemRateInput = createCachedSelector(
  [
    selectJobItem,
    selectIsEditingJobItem,
    selectCustomerCurrency,
    selectJobCurrency,
    (state, props) => props.isFocused,
    (state, props) => props.inputValue,
  ],
  (
    jobItem,
    isEditing,
    customerCurrency,
    jobCurrency,
    isFocused,
    inputValue
  ) => {
    let value = "";
    let placeholder = "";

    const sellRate = calculateSellRate(jobItem);

    if (sellRate === null) {
      value = "";
    } else if (isFocused) {
      value = inputValue;
    } else {
      value = sellRate;
    }

    const costingMethod = costingMethodObj(jobItem.costingMethod);

    if ((isEditing || isFocused) && costingMethod.isItem()) {
      placeholder = formatForeignCurrency(customerCurrency, {
        value: 0,
        currency: jobCurrency,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });
    }

    if (!costingMethod.isItem() && !costingMethod.isBasedByValue()) {
      return ["", ""];
    }

    return [value, placeholder];
  }
)(
  (state, props) =>
    `${props.jobId}-${props.jobItemId}-${props.isFocused}-${props.inputValue}`
);

export const useJobItemRateInput = (jobId, jobItemId, isFocused, inputValue) =>
  useSelector((state) =>
    selectJobItemRateInput(state, {
      jobId,
      jobItemId,
      isFocused,
      inputValue,
    })
  );
