import { LargePageHeader } from "st-shared/module";

import { useReportingFetching } from "../../state/stores/reportingSelectors";
import { headerActionsCss } from "./Header.css";
import { NewReport } from "./NewReport";
import { SaveReport } from "./SaveReport";

export function Header() {
  const fetching = useReportingFetching();

  return (
    <LargePageHeader title="Reports">
      {!fetching && (
        <div className={headerActionsCss}>
          <SaveReport />
          <NewReport />
        </div>
      )}
    </LargePageHeader>
  );
}
