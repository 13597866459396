import type { TCurrency } from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import type { TSearchItem, TSearchSection } from "@streamtimefe/utils";

import { createActionStore } from "../core";
import type { TCurrencyActions } from "./CurrencyStore.actions";
import { createCurrencyActions } from "./CurrencyStore.actions";

export type TCurrencyStore = {
  currencies: Dictionary<string, TCurrency>;
  searchItems: TSearchItem<TCurrency>[];
  sectionSearchItems: TSearchSection<TCurrency>[];
};

export const CurrencyStore = createActionStore<
  TCurrencyStore,
  TCurrencyActions
>(
  () => ({
    currencies: {},
    searchItems: [],
    sectionSearchItems: [],
  }),
  createCurrencyActions
);
