import type { TValueFormatEnum } from "@streamtimefe/entities";
import { ChartTooltip, useRenderChartTooltip } from "st-shared/components";
import type { ReportingColumn } from "st-shared/entities";
import {
  CReportingSavedSegment,
  ReportingColumnType,
  ReportingTimeChartType,
} from "st-shared/entities";
import type { ChartJS } from "st-shared/lib";
import { ReactChartJS } from "st-shared/lib";
import { themeRaw } from "st-shared/theme";

import {
  ReportingChartAnnotationColors,
  ReportingChartColors,
} from "../../../consts/ReportingChartColors";
import { useValueFormatter } from "../../../hooks/useValueFormatter";
import type { SeriesDataValue } from "../../../lib/VectorMatrixSeriesData";
import {
  useReportingSavedSegmentColumnMetadata,
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsAllNoPermission,
  useReportingSavedSegmentColumnTypeDataOnly,
  useReportingSavedSegmentSelectedTimeIds,
  useReportingSavedSegmentTimeChartType,
} from "../../../state/stores/savedSegmentSelectors";
import {
  useReportingSeriesFetching,
  useReportingSeriesTotalsTimeMatrix,
  useReportingSeriesTotalsTimeVector,
} from "../../../state/stores/searchSelectors";
import {
  canvasContainerCss,
  chartContainerCss,
  chartHeaderActionsCss,
  chartHeaderCss,
  chartTooltipCss,
  outerChartCss,
} from "../Chart.css";
import { renderReportingTooltip } from "../components/ReportingTooltip";
import { EmptyChart, EmptyChartNoPermissions } from "../EmptyChart";
import { LoadingChart } from "../LoadingChart";
import type {
  ReportingChartAnnotation,
  ReportingChartData,
  ReportingChartDataset,
} from "../ReportingChartData";
import { TimeChartTypeSelector } from "./TimeChartTypeSelector";
import { TimeSeriesColumnSelector } from "./TimeSeriesColumnSelector";
import { TimeSeriesLegend } from "./TimeSeriesLegend";

export function TimeSeriesChart() {
  const isFetching = useReportingSeriesFetching();
  const columns = useReportingSavedSegmentColumns();
  const noPermission = useReportingSavedSegmentColumnsAllNoPermission();
  const totals = useTotalsData();
  const timeChartType = useReportingSavedSegmentTimeChartType();

  const data = getLineChartData(
    totals.data,
    columns,
    timeChartType === ReportingTimeChartType.Burnup
  );
  const annotations = getLineChartAnnotations(totals.annotations, columns);
  const dataColumns = useReportingSavedSegmentColumnTypeDataOnly();

  if (isFetching) {
    return <LoadingChart />;
  }

  if (noPermission) {
    return <EmptyChartNoPermissions />;
  }

  if (dataColumns.length === 0) {
    return (
      <div className={outerChartCss}>
        <EmptyChart />
      </div>
    );
  }

  return (
    <div className={outerChartCss}>
      <div className={chartHeaderCss}>
        <TimeSeriesLegend data={data} annotations={annotations} />
        <div className={chartHeaderActionsCss}>
          <TimeChartTypeSelector />
          <TimeSeriesColumnSelector />
        </div>
      </div>
      <Chart data={data} annotations={annotations} />
    </div>
  );
}

function useTotalsData() {
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  const selectedTimeIds = useReportingSavedSegmentSelectedTimeIds();
  const timeTotalsMatrix = useReportingSeriesTotalsTimeMatrix();
  const timeTotalsVector = useReportingSeriesTotalsTimeVector();

  const data: typeof timeTotalsMatrix = {};
  const annotations: typeof timeTotalsVector = {};

  Object.entries(timeTotalsMatrix).forEach(([key, vector]) => {
    if (!selectedTimeIds.includes(key)) return;

    const metadata = columnMetadata[key];

    if (
      !metadata ||
      !metadata.show ||
      metadata.baseType !== ReportingColumnType.Data
    )
      return;

    data[key] = vector;
  });

  Object.entries(timeTotalsVector).forEach(([key, value]) => {
    if (value === null) return;
    if (!selectedTimeIds.includes(key)) return;

    const metadata = columnMetadata[key];

    if (
      !metadata ||
      !metadata.show ||
      metadata.baseType !== ReportingColumnType.Total
    )
      return;

    annotations[key] = value;
  });

  return {
    data,
    annotations,
  };
}

type Props = {
  data: ReportingChartData<"line">;
  annotations: ReportingChartAnnotation[];
};

function Chart({ data, annotations }: Props) {
  const valueFormatter = useValueFormatter();
  const [tooltipRef, renderChartTooltip] = useRenderChartTooltip(
    renderReportingTooltip<"line">(valueFormatter, annotations)
  );

  if (data.datasets.length === 0) {
    return <EmptyChart />;
  }

  const options: ChartJS.ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    interaction: {
      intersect: false,
      mode: "index",
    },
    spanGaps: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 12,
          padding: 6,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        mode: "index",
        external: renderChartTooltip,
      },
    },
  };

  function getYAxis(format: TValueFormatEnum) {
    const scale: ChartJS.ChartOptions<"line">["scales"] = {
      y: {
        ticks: {
          callback: function (val) {
            return valueFormatter(val, format, true);
          },
          padding: 6,
          precision: 0,
          maxTicksLimit: 8,
        },
      },
    };
    return scale.y;
  }

  let yAxisCount = 0;

  function addScale(key: string, format: TValueFormatEnum) {
    if (!options.scales![key]) {
      options.scales![key] = getYAxis(format);
      options.scales![key]!.position = "left";
      if (yAxisCount > 0) {
        options.scales![key]!.position = "right";
        options.scales![key]!.grid = {
          display: false,
        };
      }
      yAxisCount += 1;
    }
  }

  data.datasets.forEach((dataset) => {
    const format = dataset.format;
    const key = `y-${format}`;
    dataset.yAxisID = key;

    addScale(key, format);
  });

  annotations.forEach((annotation) => {
    const format = annotation.format;
    const key = `y-${format}`;
    annotation.scaleID = key;

    addScale(key, format);
  });

  return (
    <div className={chartContainerCss}>
      <ReactChartJS.Line
        className={canvasContainerCss}
        options={options}
        data={data}
      />
      <ChartTooltip ref={tooltipRef} className={chartTooltipCss} />
    </div>
  );
}

function getLineChartData(
  matrix: Record<string, Record<string, SeriesDataValue>>,
  columns: Record<string, ReportingColumn>,
  isCumulative: boolean
): ReportingChartData<"line"> {
  let labels: string[] = [];
  const datasets: ReportingChartDataset<"line">[] = [];

  const columnKeys = Object.keys(matrix);
  if (columnKeys.length > 0) {
    labels = Object.keys(matrix[columnKeys[0]]);
  }

  columnKeys.forEach((id, index) => {
    const color = ReportingChartColors[index % ReportingChartColors.length];
    datasets.push({
      id,
      label: columns[id].name,
      format: CReportingSavedSegment.getColumnFormat(columns[id]),
      type: "line",
      data: Object.values(matrix[id] ?? {}),

      fill: true,
      borderColor: color,
      pointBackgroundColor: color,
      backgroundColor: color + "1E",
      tension: 0.25,
    });
  });

  if (isCumulative) {
    datasets.forEach((dataset) => {
      let currentSum = 0;
      const data: number[] = [];

      dataset.data.forEach((value) => {
        value = value || 0;
        data.push(currentSum + value);
        currentSum += value;
      });

      dataset.data = data;
    });
  }

  return { labels, datasets };
}

function getLineChartAnnotations(
  vector: Record<string, SeriesDataValue>,
  columns: Record<string, ReportingColumn>
): ReportingChartAnnotation[] {
  const annotations: ReportingChartAnnotation[] = [];

  Object.keys(vector).forEach((id, index) => {
    const color =
      ReportingChartAnnotationColors[
        index % ReportingChartAnnotationColors.length
      ];
    annotations.push({
      id,
      name: columns[id].name,
      format: CReportingSavedSegment.getColumnFormat(columns[id]),
      type: "line",
      value: vector[id] || 0,
      borderColor: color,
      borderWidth: 1,
      label: {
        display: true,
        content: columns[id].name,
        position: `${index * 20}%`,
        color: themeRaw.color.charcoal,
        backgroundColor: themeRaw.color.graylight,
        xAdjust: 20,
      },
    });
  });

  return annotations;
}
