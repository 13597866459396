import { get, isEmpty } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS,
  PURCHASE_ORDER_LINE_ITEM_CREATE,
  PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_CREATED,
  PURCHASE_ORDER_LINE_ITEM_DELETE,
  PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_EDIT,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SAVE,
  PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL,
  PURCHASE_ORDER_LINE_ITEM_SORT_MOVE,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR
} from "../../../../lib/constants";
import { getPurchaseOrderId } from "../../../../lib/entities/purchaseOrderLineItemEntity";
import {
  getOrderId,
  isDeleted,
  moveLineItemOrder
} from "../../../../lib/ui/commercialDocumentLineItems";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import replaceTemporaryEntity from "../../../helpers/replaceTemporaryEntity";
import lineItemElementsByPurchaseOrderIdReducer from "./lineItemElementsByPurchaseOrderIdReducer";
import reorderByPurchaseOrderIdReducer from "./reorderByPurchaseOrderIdReducer";
import sortedIdsByPurchaseOrderIdReducer from "./sortedIdsByPurchaseOrderIdReducer";
import subTotalsExTaxByPurchaseOrderIdReducer from "./subTotalsExTaxByPurchaseOrderIdReducer";
import subTotalsIncTaxByPurchaseOrderIdReducer from "./subTotalsIncTaxByPurchaseOrderIdReducer";
import totalsByPurchaseOrderIdReducer from "./totalsByPurchaseOrderIdReducer";

const idsByPurchaseOrderIdReducer = createEntityIndexedArrayReducer(entity =>
  getPurchaseOrderId(entity)
);

const getTouchedPurchaseOrderIds = changedEntities => {
  const touchedQuoteIds = [];

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    const prevPath = prevEntity && getPurchaseOrderId(prevEntity);
    const newPath = newEntity && getPurchaseOrderId(newEntity);

    if (prevPath && prevPath !== newPath) pushOnce(touchedQuoteIds, prevPath);

    if (newPath) pushOnce(touchedQuoteIds, newPath);
  });

  return touchedQuoteIds;
};

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByPurchaseOrderId: idsByPurchaseOrderIdReducer(
      state.idsByPurchaseOrderId,
      changedEntities
    )
  };

  const touchedPurchaseOrderIds = getTouchedPurchaseOrderIds(changedEntities);

  nextState = reorderByPurchaseOrderIdReducer(
    nextState,
    touchedPurchaseOrderIds
  );

  nextState = sortedIdsByPurchaseOrderIdReducer(
    nextState,
    touchedPurchaseOrderIds
  );

  nextState = lineItemElementsByPurchaseOrderIdReducer(
    state,
    nextState,
    touchedPurchaseOrderIds
  );

  nextState = subTotalsExTaxByPurchaseOrderIdReducer(
    nextState,
    touchedPurchaseOrderIds
  );

  nextState = subTotalsIncTaxByPurchaseOrderIdReducer(
    nextState,
    touchedPurchaseOrderIds
  );

  nextState = totalsByPurchaseOrderIdReducer(
    nextState,
    touchedPurchaseOrderIds
  );

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const createPurchaseOrderLineItemReducer = (
  state,
  { payload: { purchaseOrderLineItem } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [purchaseOrderLineItem])
  );

const createdPurchaseOrderLineItemReducer = (
  state,
  { payload: { id, purchaseOrderLineItems } }
) =>
  reduceChangedEntities(
    state,
    replaceTemporaryEntity(state, id, purchaseOrderLineItems)
  );

const createPurchaseOrderLineItemErrorReducer = (
  state,
  { payload: { purchaseOrderLineItem } }
) => reduceChangedEntities(state, [{ prevEntity: purchaseOrderLineItem }]);

const deletePurchaseOrderLineItemReducer = (state, { payload: { id } }) =>
  reduceChangedEntities(state, parseRemovedEntities(state, [id]));

const deletePurchaseOrderLineItemErrorReducer = (
  state,
  { payload: { id, purchaseOrderLineItem } }
) =>
  reduceChangedEntities(state, [
    {
      prevEntity: state.byId[id],
      newEntity: state.byId[id] || purchaseOrderLineItem
    }
  ]);

const editPurchaseOrderLineItemReducer = (
  state,
  { payload: { purchaseOrderLineItem } }
) => {
  const prevEntity = get(state, `byId.${purchaseOrderLineItem.id}`);
  const newEntity = purchaseOrderLineItem;

  return reduceChangedEntities(state, [{ prevEntity, newEntity }]);
};

const savePurchaseOrderLineItemErrorReducer = (
  state,
  { payload: { id, prevPurchaseOrderLineItem } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [prevPurchaseOrderLineItem], isDeleted)
  );

const saveMergePurchaseOrderLineItemErrorReducer = (
  state,
  { payload: { prevPurchaseOrderLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, prevPurchaseOrderLineItems, isDeleted)
  );

const saveUndoMergePurchaseOrderLineItemErrorReducer = (
  state,
  { payload: { prevPurchaseOrderLineItems, mergedPurchaseOrderLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(
      state,
      [
        ...prevPurchaseOrderLineItems,
        ...mergedPurchaseOrderLineItems.map(lineItem => ({
          ...lineItem,
          active: false
        }))
      ],
      isDeleted
    )
  );

const sortPurchaseOrderLineItemMoveReducer = (state, { payload }) => {
  const { purchaseOrderId, sourceOrderId, destinationOrderId } = payload;
  const sortedPurchaseOrderLineItems = get(
    state,
    `sortedIdsByPurchaseOrderId.${purchaseOrderId}`,
    []
  ).map(id => state.byId[id]);

  const purchaseOrderLinesItems = moveLineItemOrder(
    sortedPurchaseOrderLineItems,
    sourceOrderId,
    destinationOrderId
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, purchaseOrderLinesItems)
  );
};

const undoSortPurchaseOrderLineItemMoveReducer = (state, { payload }) => {
  const { purchaseOrderId, snapshot } = payload;

  const ids = get(state, `idsByPurchaseOrderId.${purchaseOrderId}`, []);
  const purchaseOrderLineItems = ids.map(id => state.byId[id]);

  const prevPurchaseOrderLineItems = purchaseOrderLineItems.map(
    purchaseOrderLineItem =>
      snapshot[purchaseOrderLineItem.id]
        ? {
            ...purchaseOrderLineItem,
            orderId: getOrderId(snapshot[purchaseOrderLineItem.id])
          }
        : purchaseOrderLineItem
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, prevPurchaseOrderLineItems)
  );
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [PURCHASE_ORDER_LINE_ITEM_CREATE]: createPurchaseOrderLineItemReducer,
    [PURCHASE_ORDER_LINE_ITEM_DELETE]: deletePurchaseOrderLineItemReducer,
    [PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR]: deletePurchaseOrderLineItemErrorReducer,
    [PURCHASE_ORDER_LINE_ITEM_EDIT]: editPurchaseOrderLineItemReducer,
    [PURCHASE_ORDER_LINE_ITEM_SAVE]: editPurchaseOrderLineItemReducer,
    [PURCHASE_ORDER_LINE_ITEM_CREATED]: createdPurchaseOrderLineItemReducer,
    [PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR]: createPurchaseOrderLineItemErrorReducer,
    [PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR]: savePurchaseOrderLineItemErrorReducer,
    [PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR]: saveMergePurchaseOrderLineItemErrorReducer,
    [PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR]: saveUndoMergePurchaseOrderLineItemErrorReducer,
    [PURCHASE_ORDER_LINE_ITEM_SORT_MOVE]: sortPurchaseOrderLineItemMoveReducer,
    [PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL]: undoSortPurchaseOrderLineItemMoveReducer,
    [PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR]: undoSortPurchaseOrderLineItemMoveReducer
  }
);
