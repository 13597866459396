import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const ActivityIcon = props => (
  <SvgIcon {...props} viewBox="0 0 20 30">
    <path d="M6.28,30,8.1,18.46H0L11.91,0h1.86L11.91,11.49H20L8.18,30Z" />
  </SvgIcon>
);

export default StyledIcon(ActivityIcon);
