import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsBurnUpMode = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.burnUpMode
);

export const useJobDetailsBurnUpMode = () =>
  useSelector(selectJobDetailsBurnUpMode);
