import { isEqual } from "lodash-es";
import { Entity } from "st-shared/entities/Entity";

import { ChangedEntity, EntityState } from "../entities/types";

function parseEntityPayload<T extends Entity>(
  state: EntityState<T>,
  payload: T[] = [],
  shouldRemove: (entity: T) => boolean = () => false,
  mergeAttrs: any = {}
) {
  const changedEntities: ChangedEntity<T>[] = [];

  payload.forEach((newEntity) => {
    const prevEntity = state.byId[newEntity.id];

    Object.assign(newEntity, mergeAttrs);

    if (shouldRemove(newEntity)) {
      if (prevEntity) changedEntities.push({ prevEntity });
    } else if (!isEqual(prevEntity, newEntity)) {
      changedEntities.push({ prevEntity, newEntity });
    }
  });

  return changedEntities;
}

export default parseEntityPayload;
