import { produce } from "immer";
import { get, setWith, sortBy, unset } from "lodash-es";

import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedPurchaseOrderIds) =>
  produce(nextState, (draft) => {
    const getPurchaseOrderItem = (id) => nextState.byId[id];

    touchedPurchaseOrderIds.forEach((quoteId) => {
      const ids = get(nextState.idsByPurchaseOrderId, quoteId);

      if (!ids) {
        unset(draft, `sortedIdsByPurchaseOrderId.${quoteId}`);
        return;
      }

      const sortedIds = sortBy(ids, (id) =>
        getOrderId(getPurchaseOrderItem(id))
      );

      setWith(
        draft,
        `sortedIdsByPurchaseOrderId.${quoteId}`,
        sortedIds,
        Object
      );
    });
  });
