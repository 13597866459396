import {
  EMPTY_ARRAY,
  LINE_ITEMS_MERGE_START,
  LINE_ITEMS_MERGE_CONFIRM,
  LINE_ITEMS_MERGE_CANCEL,
  INVOICE_FETCH_REQUEST,
  INVOICE_FETCH_SUCCEEDED,
  INVOICE_FETCH_FAILED,
  INVOICE_LINE_ITEM_SORT_CANCEL,
  INVOICE_LINE_ITEM_SORT_START,
  INVOICE_LINE_ITEM_SORT_CONFIRM
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const initialState = {
  invoiceId: null,
  pending: true,
  fetchedIds: EMPTY_ARRAY,
  error: "",
  isMergeMode: false,
  isSortMode: false
};

const onRequest = (state, { payload: { invoiceId } }) => ({
  ...state,
  invoiceId,
  pending: true,
  error: initialState.error
});

const onSuccess = (state, { payload: { invoiceId } }) => ({
  ...state,
  pending: false,
  fetchedIds: state.fetchedIds.concat([invoiceId]),
  error: initialState.error
});

const onError = (state, { payload: { error } }) => ({
  ...state,
  pending: true,
  error
});

const setMergeModeTrue = state => ({
  ...state,
  isMergeMode: true
});

const setMergeModeFalse = state => ({
  ...state,
  isMergeMode: false
});

const setSortModeTrue = (state, { payload: { snapshotId } }) => ({
  ...state,
  isSortMode: true,
  sortModeSnapshotId: snapshotId
});

const setSortModeFalse = state => ({
  ...state,
  isSortMode: false
});

export default createReducer(initialState, {
  [INVOICE_FETCH_REQUEST]: onRequest,
  [INVOICE_FETCH_SUCCEEDED]: onSuccess,
  [INVOICE_FETCH_FAILED]: onError,
  [LINE_ITEMS_MERGE_START]: setMergeModeTrue,
  [LINE_ITEMS_MERGE_CONFIRM]: setMergeModeFalse,
  [LINE_ITEMS_MERGE_CANCEL]: setMergeModeFalse,
  [INVOICE_LINE_ITEM_SORT_START]: setSortModeTrue,
  [INVOICE_LINE_ITEM_SORT_CONFIRM]: setSortModeFalse,
  [INVOICE_LINE_ITEM_SORT_CANCEL]: setSortModeFalse
});
