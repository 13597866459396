export const ACTIVITY_ENTRY_FETCH_FAILED = "ACTIVITY_ENTRY_FETCH_FAILED";
export const ACTIVITY_ENTRY_FETCH_REQUEST = "ACTIVITY_ENTRY_FETCH_REQUEST";
export const ACTIVITY_ENTRY_FETCH_SUCCEEDED = "ACTIVITY_ENTRY_FETCH_SUCCEEDED";
export const ACTIVITY_ENTRY_SAVE = "ACTIVITY_ENTRY_SAVE";
export const ACTIVITY_ENTRY_SAVE_ERROR = "ACTIVITY_ENTRY_SAVE_ERROR";
export const ACTIVITY_ENTRY_SAVED = "ACTIVITY_ENTRY_SAVED";
export const ACTIVITY_ENTRY_SAVING = "ACTIVITY_ENTRY_SAVING";
export const ACTIVITY_ENTRY_DELETE = "ACTIVITY_ENTRY_DELETE";
export const ACTIVITY_ENTRY_DELETE_ERROR = "ACTIVITY_ENTRY_DELETE_ERROR";
export const ACTIVITY_ENTRY_DELETED = "ACTIVITY_ENTRY_DELETED";
export const ACTIVITY_ENTRY_DELETING = "ACTIVITY_ENTRY_DELETING";
export const ACTIVITY_ENTRY_THREAD_FETCH_FAILED =
  "ACTIVITY_ENTRY_THREAD_FETCH_FAILED";
export const ACTIVITY_ENTRY_THREAD_FETCH_REQUEST =
  "ACTIVITY_ENTRY_THREAD_FETCH_REQUEST";
export const ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED =
  "ACTIVITY_ENTRY_THREAD_FETCH_SUCCEEDED";

export const BRANCH_LOGO_UPLOAD_FAILED = "BRANCH_LOGO_UPLOAD_FAILED";
export const BRANCH_LOGO_UPLOAD_REQUEST = "BRANCH_LOGO_UPLOAD_REQUEST";
export const BRANCH_LOGO_UPLOAD_SUCCEEDED = "BRANCH_LOGO_UPLOAD_SUCCEEDED";

export const COMPANY_CONTACTS_FETCH_REQUEST = "COMPANY_CONTACTS_FETCH_REQUEST";
export const COMPANY_CONTACTS_FETCH_FAILED = "COMPANY_CONTACTS_FETCH_FAILED";
export const COMPANY_CONTACTS_FETCH_SUCCEEDED =
  "COMPANY_CONTACTS_FETCH_SUCCEEDED";
export const COMPANY_ADDRESSES_FETCH_REQUEST =
  "COMPANY_ADDRESSES_FETCH_REQUEST";
export const COMPANY_ADDRESSES_FETCH_FAILED = "COMPANY_ADDRESSES_FETCH_FAILED";
export const COMPANY_ADDRESSES_FETCH_SUCCEEDED =
  "COMPANY_ADDRESSES_FETCH_SUCCEEDED";

export const ENTITIES_RECEIVED = "ENTITIES_RECEIVED";
export const ENTITIES_REMOVED = "ENTITIES_REMOVED";

export const ACCOUNTING_PLATFORM_FETCH = "ACCOUNTING_PLATFORM_FETCH";
export const ACCOUNTING_PLATFORM_FETCH_FAILED =
  "ACCOUNTING_PLATFORM_FETCH_FAILED";
export const ACCOUNTING_PLATFORM_FETCH_REQUEST =
  "ACCOUNTING_PLATFORM_FETCH_REQUEST";
export const ACCOUNTING_PLATFORM_FETCH_SUCCEEDED =
  "ACCOUNTING_PLATFORM_FETCH_SUCCEEDED";

export const INVOICE_FETCH_REQUEST = "INVOICE_FETCH_REQUEST";
export const INVOICE_FETCH_FAILED = "INVOICE_FETCH_FAILED";
export const INVOICE_FETCH_SUCCEEDED = "INVOICE_FETCH_SUCCEEDED";
export const INVOICE_EDIT = "INVOICE_EDIT";
export const INVOICE_SAVE = "INVOICE_SAVE";
export const INVOICE_SAVING = "INVOICE_SAVING";
export const INVOICE_SAVED = "INVOICE_SAVED";
export const INVOICE_SAVE_FAILED = "INVOICE_SAVE_FAILED";
export const INVOICE_ADDRESS_CREATE = "INVOICE_ADDRESS_CREATE";
export const INVOICE_CONTACT_CREATE_EDIT = "INVOICE_CONTACT_CREATE_EDIT";
export const INVOICE_SET_ADDRESS = "INVOICE_SET_ADDRESS";
export const INVOICE_SET_CONTACT = "INVOICE_SET_CONTACT";
export const INVOICE_ASSIGN_NUMBER = "INVOICE_ASSIGN_NUMBER";

export const INVOICE_LINE_ITEM_MERGE_SAVING = "INVOICE_LINE_ITEM_MERGE_SAVING";
export const INVOICE_LINE_ITEM_MERGE_SAVED = "INVOICE_LINE_ITEM_MERGE_SAVED";
export const INVOICE_LINE_ITEM_MERGE_SAVE_ERROR =
  "INVOICE_LINE_ITEM_MERGE_SAVE_ERROR";
export const INVOICE_LINE_ITEM_UNDO_MERGE = "INVOICE_LINE_ITEM_UNDO_MERGE";
export const INVOICE_LINE_ITEM_UNDO_MERGE_SAVING =
  "INVOICE_LINE_ITEM_UNDO_MERGE_SAVING";
export const INVOICE_LINE_ITEM_UNDO_MERGE_SAVED =
  "INVOICE_LINE_ITEM_UNDO_MERGE_SAVED";
export const INVOICE_LINE_ITEM_SORT_MOVE = "INVOICE_LINE_ITEM_SORT_MOVE";
export const INVOICE_LINE_ITEM_SORT_START = "INVOICE_LINE_ITEM_SORT_START";
export const INVOICE_LINE_ITEM_SORT_CONFIRM = "INVOICE_LINE_ITEM_SORT_CONFIRM";
export const INVOICE_LINE_ITEM_SORT_CANCEL = "INVOICE_LINE_ITEM_SORT_CANCEL";
export const INVOICE_LINE_ITEM_SORT_SAVING = "INVOICE_LINE_ITEM_SORT_SAVING";
export const INVOICE_LINE_ITEM_SORT_SAVED = "INVOICE_LINE_ITEM_SORT_SAVED";
export const INVOICE_LINE_ITEM_SORT_SAVE_ERROR =
  "INVOICE_LINE_ITEM_SORT_SAVE_ERROR";
export const INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR =
  "INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR";
export const INVOICE_LINE_ITEM_EDIT = "INVOICE_LINE_ITEM_EDIT";
export const INVOICE_LINE_ITEM_SET_ACCOUNT_CODE =
  "INVOICE_LINE_ITEM_SET_ACCOUNT_CODE";
export const INVOICE_LINE_ITEM_SAVE = "INVOICE_LINE_ITEM_SAVE";
export const INVOICE_LINE_ITEM_SAVING = "INVOICE_LINE_ITEM_SAVING";
export const INVOICE_LINE_ITEM_SAVE_ERROR = "INVOICE_LINE_ITEM_SAVE_ERROR";
export const INVOICE_LINE_ITEM_SAVED = "INVOICE_LINE_ITEM_SAVED";
export const INVOICE_LINE_ITEM_DELETE = "INVOICE_LINE_ITEM_DELETE";
export const INVOICE_LINE_ITEM_DELETING = "INVOICE_LINE_ITEM_DELETING";
export const INVOICE_LINE_ITEM_DELETED = "INVOICE_LINE_ITEM_DELETED";
export const INVOICE_LINE_ITEM_DELETE_ERROR = "INVOICE_LINE_ITEM_DELETE_ERROR";
export const INVOICE_LINE_ITEM_SET_IS_MERGING =
  "INVOICE_LINE_ITEM_SET_IS_MERGING";
export const INVOICE_LINE_ITEM_TAX_RATE_CREATE =
  "INVOICE_LINE_ITEM_TAX_RATE_CREATE";
export const INVOICE_LINE_ITEM_ADD_LINE = "INVOICE_LINE_ITEM_ADD_LINE";
export const INVOICE_LINE_ITEM_ADD_ITEM = "INVOICE_LINE_ITEM_ADD_ITEM";
export const INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN =
  "INVOICE_LINE_ITEM_ADD_FROM_JOB_PLAN";
export const INVOICE_LINE_ITEM_CREATE = "INVOICE_LINE_ITEM_CREATE";
export const INVOICE_LINE_ITEM_CREATING = "INVOICE_LINE_ITEM_CREATING";
export const INVOICE_LINE_ITEM_CREATE_ERROR = "INVOICE_LINE_ITEM_CREATE_ERROR";
export const INVOICE_LINE_ITEM_CREATED = "INVOICE_LINE_ITEM_CREATED";

export const INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY =
  "INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY";

export const JOB_ACTIVITY_FETCH_FAILED = "JOB_ACTIVITY_FETCH_FAILED";
export const JOB_ACTIVITY_FETCH_REQUEST = "JOB_ACTIVITY_FETCH_REQUEST";
export const JOB_ACTIVITY_FETCH_SUCCEEDED = "JOB_ACTIVITY_FETCH_SUCCEEDED";

export const JOB_EDIT = "JOB_EDIT";
export const JOB_SET_PREVIOUS_JOB = "JOB_SET_PREVIOUS_JOB";
export const JOB_SAVE = "JOB_SAVE";
export const JOB_SAVING = "JOB_SAVING";
export const JOB_SAVED = "JOB_SAVED";
export const JOB_SAVE_ERROR = "JOB_SAVE_ERROR";

export const JOB_SET_NAME = "JOB_SET_NAME";
export const JOB_SET_BUDGET = "JOB_SET_BUDGET";
export const JOB_STATUS_CHANGE = "JOB_STATUS_CHANGE";
export const JOB_SET_CONTACT = "JOB_SET_CONTACT";
export const JOB_CONTACT_CREATE_EDIT = "JOB_CONTACT_CREATE_EDIT";
export const JOB_SET_JOB_NUMBER = "JOB_SET_JOB_NUMBER";
export const JOB_SET_PO_NUMBER = "JOB_SET_PO_NUMBER";
export const JOB_SET_JOB_LEAD = "JOB_SET_JOB_LEAD";
export const JOB_SET_COMPANY = "JOB_SET_COMPANY";

export const JOB_ITEM_SAVE_EDIT = "JOB_ITEM_SAVE_EDIT";
export const JOB_ITEM_SAVE_ERROR = "JOB_ITEM_SAVE_ERROR";
export const JOB_ITEM_SAVED = "JOB_ITEM_SAVED";
export const JOB_ITEM_SAVING = "JOB_ITEM_SAVING";
export const JOB_ITEM_CREATE = "JOB_ITEM_CREATE";
export const JOB_ITEM_EDIT_ITEM_CARD = "JOB_ITEM_EDIT_ITEM_CARD";
export const JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP =
  "JOB_ITEM_EDIT_ITEM_CARD_FORCE_STOP";
export const JOB_ITEM_SAVE_ITEM_CARD = "JOB_ITEM_SAVE_ITEM_CARD";
export const JOB_ITEM_SET_SAVING = "JOB_ITEM_SET_SAVING";

export const JOB_ITEM_SET_NAME = "JOB_ITEM_SET_NAME";
export const JOB_ITEM_SET_MASTER_JOB_ITEM = "JOB_ITEM_SET_ITEM_OPTION";
export const JOB_ITEM_SET_COSTING_METHOD = "JOB_ITEM_SET_COSTING_METHOD";
export const JOB_ITEM_SET_TIME_ALLOCATION_METHOD =
  "JOB_ITEM_SET_TIME_ALLOCATION_METHOD";
export const JOB_ITEM_SET_START_DATE = "JOB_ITEM_SET_START_DATE";
export const JOB_ITEM_SET_END_DATE = "JOB_ITEM_SET_END_DATE";
export const JOB_ITEM_SET_HOURS = "JOB_ITEM_SET_HOURS";
export const JOB_ITEM_SET_RATE = "JOB_ITEM_SET_RATE";
export const JOB_ITEM_SET_TOTAL = "JOB_ITEM_SET_TOTAL";
export const JOB_ITEM_SET_DESCRIPTION = "JOB_ITEM_SET_DESCRIPTION";
export const JOB_ITEM_SET_PREVIOUS_JOB_ITEM = "JOB_ITEM_SET_PREVIOUS_JOB_ITEM";
export const JOB_ITEM_SET_EXPANDED = "JOB_ITEM_SET_EXPANDED";
export const JOB_ITEM_SET_SUB_ITEM_ORDER = "JOB_ITEM_SET_SUB_ITEM_ORDER";
export const JOB_ITEM_STATUS_CHANGE = "JOB_ITEM_STATUS_CHANGE";
export const JOB_ITEM_STATUS_CHANGE_CANCEL = "JOB_ITEM_STATUS_CHANGE_CANCEL";
export const JOB_ITEM_STATUS_SAVED = "JOB_ITEM_STATUS_SAVED";
export const JOB_ITEM_STATUS_SAVING = "JOB_ITEM_STATUS_SAVING";
export const JOB_ITEM_NEW_ITEM_DELETE = "JOB_ITEM_NEW_ITEM_DELETE";
export const JOB_ITEM_REVERT = "JOB_ITEM_REVERT";

export const JOB_ITEM_SUB_ITEM_EDIT = "JOB_ITEM_SUB_ITEM_EDIT";
export const JOB_ITEM_SUB_ITEM_SET_DESCRIPTION =
  "JOB_ITEM_SUB_ITEM_SET_DESCRIPTION";
export const JOB_ITEM_SUB_ITEM_SET_CHECKED = "JOB_ITEM_SUB_ITEM_SET_CHECKED";
export const JOB_ITEM_SUB_ITEM_CREATE = "JOB_ITEM_SUB_ITEM_CREATE";

export const JOB_ITEM_SUB_ITEM_DELETE = "JOB_ITEM_SUB_ITEM_DELETE";
export const JOB_ITEM_SUB_ITEM_DELETING = "JOB_ITEM_SUB_ITEM_DELETING";
export const JOB_ITEM_SUB_ITEM_DELETE_ERROR = "JOB_ITEM_SUB_ITEM_DELETE_ERROR";
export const JOB_ITEM_SUB_ITEM_DELETED = "JOB_ITEM_SUB_ITEM_DELETED";

export const JOB_ITEM_USER_EDIT = "JOB_ITEM_USER_EDIT";
export const JOB_ITEM_USER_STATUS_CHANGE = "JOB_ITEM_USER_STATUS_CHANGE";
export const JOB_ITEM_USER_STATUS_CHANGE_CANCEL =
  "JOB_ITEM_USER_STATUS_CHANGE_CANCEL";
export const JOB_ITEM_USER_STATUS_SAVED = "JOB_ITEM_USER_STATUS_SAVED";
export const JOB_ITEM_USER_STATUS_SAVING = "JOB_ITEM_USER_STATUS_SAVING";
export const JOB_ITEM_USER_SET_HOURS = "JOB_ITEM_USER_SET_HOURS";
export const JOB_ITEM_USER_SET_RATE = "JOB_ITEM_USER_SET_RATE";
export const JOB_ITEM_USER_CREATE = "JOB_ITEM_USER_CREATE";
export const JOB_ITEM_USER_REASSIGN = "JOB_ITEM_USER_REASSIGN";

export const JOB_ITEM_USER_DELETE_CONFIRM = "JOB_ITEM_USER_DELETE_CONFIRM";
export const JOB_ITEM_USER_DELETE = "JOB_ITEM_USER_DELETE";
export const JOB_ITEM_USER_DELETING = "JOB_ITEM_USER_DELETING";
export const JOB_ITEM_USER_DELETE_ERROR = "JOB_ITEM_USER_DELETE_ERROR";
export const JOB_ITEM_USER_DELETED = "JOB_ITEM_USER_DELETED";

export const JOB_ITEM_DRAG = "JOB_ITEM_DRAG";
export const JOB_ITEM_DRAG_CANCEL = "JOB_ITEM_DRAG_CANCEL";
export const JOB_ITEM_DRAG_END = "JOB_ITEM_DRAG_END";
export const JOB_ITEM_DRAG_START = "JOB_ITEM_DRAG_START";
export const JOB_ITEM_PAINT = "JOB_ITEM_PAINT";
export const JOB_ITEM_PAINT_CANCEL = "JOB_ITEM_PAINT_CANCEL";
export const JOB_ITEM_PAINT_END = "JOB_ITEM_PAINT_END";
export const JOB_ITEM_PAINT_START = "JOB_ITEM_PAINT_START";
export const JOB_ITEM_RESIZE = "JOB_ITEM_RESIZE";
export const JOB_ITEM_RESIZE_CANCEL = "JOB_ITEM_RESIZE_CANCEL";
export const JOB_ITEM_RESIZE_END = "JOB_ITEM_RESIZE_END";
export const JOB_ITEM_RESIZE_START = "JOB_ITEM_RESIZE_START";
export const JOB_ITEM_LOCK_BY_JOB = "JOB_ITEM_LOCK_BY_JOB";
export const JOB_ITEM_UNLOCK_BY_JOB = "JOB_ITEM_UNLOCK_BY_JOB";
export const JOB_ITEM_DELETE_CONFIRM = "JOB_ITEM_DELETE_CONFIRM";
export const JOB_ITEM_DELETE = "JOB_ITEM_DELETE";
export const JOB_ITEM_DELETE_ERROR = "JOB_ITEM_DELETE_ERROR";
export const JOB_ITEM_DELETED = "JOB_ITEM_DELETED";
export const JOB_ITEM_DELETING = "JOB_ITEM_DELETING";

export const JOB_ITEM_DUPLICATE = "JOB_ITEM_DUPLICATE";
export const JOB_ITEM_DUPLICATE_STARTED = "JOB_ITEM_DUPLICATE_STARTED";
export const JOB_ITEM_DUPLICATE_ENDED = "JOB_ITEM_DUPLICATE_ENDED";
export const JOB_ITEM_DUPLICATING = "JOB_ITEM_IS_DUPLICATING";

export const JOB_ITEM_SET_IS_BILLABLE = "JOB_ITEM_SET_IS_BILLABLE";

export const JOB_ITEM_DEPENDANCIES_SELECTED = "JOB_ITEM_DEPENDANCIES_SELECTED";
export const JOB_ITEM_DEPENDANCY_CREATE_START =
  "JOB_ITEM_DEPENDANCY_CREATE_START";
export const JOB_ITEM_DEPENDANCY_CREATE_END = "JOB_ITEM_DEPENDANCY_CREATE_END";
export const JOB_ITEM_DEPENDANCY_CREATE_SAVE =
  "JOB_ITEM_DEPENDANCY_CREATE_SAVE";
export const JOB_ITEM_DEPENDANCY_CREATE_SAVED =
  "JOB_ITEM_DEPENDANCY_CREATE_SAVED";
export const JOB_ITEM_DEPENDANCY_CREATE_SAVING =
  "JOB_ITEM_DEPENDANCY_CREATE_SAVING";
export const JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR =
  "JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR";
export const JOB_ITEM_DEPENDANCY_DELETE = "JOB_ITEM_DEPENDANCY_DELETE";
export const JOB_ITEM_DEPENDANCY_DELETING = "JOB_ITEM_DEPENDANCY_DELETING";
export const JOB_ITEM_DEPENDANCY_DELETED = "JOB_ITEM_DEPENDANCY_DELETED";
export const JOB_ITEM_DEPENDANCY_DELETE_ERROR =
  "JOB_ITEM_DEPENDANCY_DELETE_ERROR";

export const JOB_MILESTONE_DELETE = "JOB_MILESTONE_DELETE";
export const JOB_MILESTONE_DELETE_ERROR = "JOB_MILESTONE_DELETE_ERROR";
export const JOB_MILESTONE_DELETED = "JOB_MILESTONE_DELETED";
export const JOB_MILESTONE_DELETING = "JOB_MILESTONE_DELETING";
export const JOB_MILESTONE_SAVE = "JOB_MILESTONE_SAVE";
export const JOB_MILESTONE_SAVE_ERROR = "JOB_MILESTONE_SAVE_ERROR";
export const JOB_MILESTONE_SAVED = "JOB_MILESTONE_SAVED";
export const JOB_MILESTONE_SAVING = "JOB_MILESTONE_SAVING";

export const JOB_TIMELINE_FETCH_FAILED = "JOB_TIMELINE_FETCH_FAILED";
export const JOB_TIMELINE_FETCH_REQUEST = "JOB_TIMELINE_FETCH_REQUEST";
export const JOB_TIMELINE_FETCH_SUCCEEDED = "JOB_TIMELINE_FETCH_SUCCEEDED";

export const JOB_DETAILS_FETCH_FAILED = "JOB_DETAILS_FETCH_FAILED";
export const JOB_DETAILS_FETCH_REQUEST = "JOB_DETAILS_FETCH_REQUEST";
export const JOB_DETAILS_FETCH_SUCCEEDED = "JOB_DETAILS_FETCH_SUCCEEDED";

export const JOB_DETAILS_SET_CURRENT_FOCUS = "JOB_DETAILS_SET_CURRENT_FOCUS";
export const JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED =
  "JOB_DETAILS_SET_CURRENT_FOCUS_DELAYED";
export const JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY =
  "JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY";
export const JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY =
  "JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY";
export const JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY =
  "JOB_DETAILS_FETCH_UNPLANNED_TIME_SUMMARY";
export const JOB_DETAILS_SET_BOTTOM_SECTION_TAB =
  "JOB_DETAILS_SET_BOTTOM_SECTION_TAB";
export const JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB =
  "JOB_DETAILS_SET_JUMBOTRON_SECTION_TAB";
export const JOB_DETAILS_SET_ACTIVITY_SECTION_TAB =
  "JOB_DETAILS_SET_ACTIVITY_SECTION_TAB";
export const JOB_DETAILS_SET_COST_VS_SELL_MODE =
  "JOB_DETAILS_SET_COST_VS_SELL_MODE";
export const JOB_DETAILS_SET_BURN_UP_MODE = "JOB_DETAILS_SET_BURN_UP_MODE";
export const JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED =
  "JOB_DETAILS_SET_IS_JOB_NUMBER_DUPLICATED";
export const JOB_DETAILS_SET_HISTORICAL_SUMMARY =
  "JOB_DETAILS_SET_HISTORICAL_SUMMARY";
export const JOB_DETAILS_FORCE_SAVE = "JOB_DETAILS_FORCE_SAVE";
export const JOB_DETAILS_FETCH_HISTORICAL_SUMMARY =
  "JOB_DETAILS_FETCH_HISTORICAL_SUMMARY";

export const JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN =
  "JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN";
export const JOB_PAGE_SET_DATE_DRAWER_OPEN = "JOB_PAGE_SET_DATE_DRAWER_OPEN";

export const JOB_DETAILS_ITEM_REORDER = "JOB_DETAILS_ITEM_REORDER";
export const JOB_DETAILS_PHASE_REORDER = "JOB_DETAILS_PHASE_REORDER";

export const JOB_ITEM_SET_ALL_EXPANDED = "JOB_ITEM_SET_ALL_EXPANDED";
export const JOB_PHASE_SET_ALL_EXPANDED = "JOB_PHASE_SET_ALL_EXPANDED";

export const JOB_PHASE_EDIT = "JOB_PHASE_EDIT";
export const JOB_PHASE_SAVE = "JOB_PHASE_SAVE";
export const JOB_PHASE_SAVING = "JOB_PHASE_SAVING";
export const JOB_PHASE_SAVED = "JOB_PHASE_SAVED";
export const JOB_PHASE_SAVE_ERROR = "JOB_PHASE_SAVE_ERROR";
export const JOB_PHASE_CREATE = "JOB_PHASE_CREATE";
export const JOB_PHASE_SET_PREVIOUS_JOB_PHASE =
  "JOB_PHASE_SET_PREVIOUS_JOB_PHASE";
export const JOB_PHASE_SET_NAME = "JOB_PHASE_SET_NAME";
export const JOB_PHASE_SET_JOB_ITEM_IDS = "JOB_PHASE_SET_JOB_ITEM_IDS";
export const JOB_PHASE_SET_EXPANDED = "JOB_PHASE_SET_EXPANDED";

export const JOB_PHASE_DELETE = "JOB_PHASE_DELETE";
export const JOB_PHASE_DELETING = "JOB_PHASE_DELETING";
export const JOB_PHASE_DELETE_ERROR = "JOB_PHASE_DELETE_ERROR";
export const JOB_PHASE_DELETED = "JOB_PHASE_DELETED";
export const JOB_PHASE_NEW_PHASE_DELETE = "JOB_PHASE_NEW_PHASE_DELETE";
export const JOB_PHASE_DUPLICATE = "JOB_PHASE_DUPLICATE";

export const JOB_PHASE_LOADING_STARTED = "JOB_PHASE_LOADING_STARTED";
export const JOB_PHASE_LOADING_ENDED = "JOB_PHASE_LOADING_ENDED";

export const JOB_PHASE_STATUS_CHANGE = "JOB_PHASE_STATUS_CHANGE";
export const JOB_PHASE_SET_IS_BILLABLE = "JOB_PHASE_SET_IS_BILLABLE";

export const JOB_TIMELINE_PHASE_EXPAND = "JOB_TIMELINE_PHASE_EXPAND";
export const JOB_TIMELINE_PHASE_COLLAPSE = "JOB_TIMELINE_PHASE_COLLAPSE";

export const JOB_TIMELINE_ZOOM_IN = "JOB_TIMELINE_ZOOM_IN";
export const JOB_TIMELINE_ZOOM_OUT = "JOB_TIMELINE_ZOOM_OUT";

export const JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED =
  "JOB_REPEATING_LOGGED_TIMES_FETCH_FAILED";
export const JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST =
  "JOB_REPEATING_LOGGED_TIMES_FETCH_REQUEST";
export const JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED =
  "JOB_REPEATING_LOGGED_TIMES_FETCH_SUCCEEDED";

export const LINE_ITEMS_MERGE_START = "LINE_ITEMS_MERGE_START";
export const LINE_ITEMS_MERGE_CONFIRM = "LINE_ITEMS_MERGE_CONFIRM";
export const LINE_ITEMS_MERGE_CANCEL = "LINE_ITEMS_MERGE_CANCEL";
export const LINE_ITEMS_SORT_START = "LINE_ITEMS_SORT_START";
export const LINE_ITEMS_SORT_CONFIRM = "LINE_ITEMS_SORT_CONFIRM";
export const LINE_ITEMS_SORT_CANCEL = "LINE_ITEMS_SORT_CANCEL";

export const LOGGED_EXPENSE_FETCH_REQUEST = "LOGGED_EXPENSE_FETCH_REQUEST";
export const LOGGED_EXPENSE_FETCH_FAILED = "LOGGED_EXPENSE_FETCH_FAILED";
export const LOGGED_EXPENSE_FETCH_SUCCEEDED = "LOGGED_EXPENSE_FETCH_SUCCEEDED";
export const LOGGED_EXPENSE_EDIT = "LOGGED_EXPENSE_EDIT";
export const LOGGED_EXPENSE_SAVE = "LOGGED_EXPENSE_SAVE";
export const LOGGED_EXPENSE_SAVING = "LOGGED_EXPENSE_SAVING";
export const LOGGED_EXPENSE_SAVED = "LOGGED_EXPENSE_SAVED";
export const LOGGED_EXPENSE_SAVE_FAILED = "LOGGED_EXPENSE_SAVE_FAILED";
export const LOGGED_EXPENSE_SET_SUPPLIER = "LOGGED_EXPENSE_SET_SUPPLIER";
export const LOGGED_EXPENSE_ADDRESS_CREATE = "LOGGED_EXPENSE_ADDRESS_CREATE";
export const LOGGED_EXPENSE_SET_ADDRESS = "LOGGED_EXPENSE_SET_ADDRESS";
export const LOGGED_EXPENSE_CONTACT_CREATE_EDIT =
  "LOGGED_EXPENSE_CONTACT_CREATE_EDIT";
export const LOGGED_EXPENSE_SET_CONTACT = "LOGGED_EXPENSE_SET_CONTACT";

export const PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING =
  "PURCHASE_ORDER_LINE_ITEM_MERGE_SAVING";
export const PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED =
  "PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED";
export const PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_MERGE_SAVE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE =
  "PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE";
export const PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING =
  "PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVING";
export const PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED =
  "PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED";
export const PURCHASE_ORDER_LINE_ITEM_SORT_MOVE =
  "PURCHASE_ORDER_LINE_ITEM_SORT_MOVE";
export const PURCHASE_ORDER_LINE_ITEM_SORT_START =
  "PURCHASE_ORDER_LINE_ITEM_SORT_START";
export const PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM =
  "PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM";
export const PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL =
  "PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL";
export const PURCHASE_ORDER_LINE_ITEM_SORT_SAVING =
  "PURCHASE_ORDER_LINE_ITEM_SORT_SAVING";
export const PURCHASE_ORDER_LINE_ITEM_SORT_SAVED =
  "PURCHASE_ORDER_LINE_ITEM_SORT_SAVED";
export const PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_EDIT = "PURCHASE_ORDER_LINE_ITEM_EDIT";
export const PURCHASE_ORDER_LINE_ITEM_SET_ACCOUNT_CODE =
  "PURCHASE_ORDER_LINE_ITEM_SET_ACCOUNT_CODE";
export const PURCHASE_ORDER_LINE_ITEM_SAVE = "PURCHASE_ORDER_LINE_ITEM_SAVE";
export const PURCHASE_ORDER_LINE_ITEM_SAVING =
  "PURCHASE_ORDER_LINE_ITEM_SAVING";
export const PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_SAVED = "PURCHASE_ORDER_LINE_ITEM_SAVED";
export const PURCHASE_ORDER_LINE_ITEM_DELETE =
  "PURCHASE_ORDER_LINE_ITEM_DELETE";
export const PURCHASE_ORDER_LINE_ITEM_DELETING =
  "PURCHASE_ORDER_LINE_ITEM_DELETING";
export const PURCHASE_ORDER_LINE_ITEM_DELETED =
  "PURCHASE_ORDER_LINE_ITEM_DELETED";
export const PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING =
  "PURCHASE_ORDER_LINE_ITEM_SET_IS_MERGING";
export const PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE =
  "PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE";
export const PURCHASE_ORDER_LINE_ITEM_ADD_LINE =
  "PURCHASE_ORDER_LINE_ITEM_ADD_LINE";
export const PURCHASE_ORDER_LINE_ITEM_ADD_ITEM =
  "PURCHASE_ORDER_LINE_ITEM_ADD_ITEM";
export const PURCHASE_ORDER_LINE_ITEM_CREATE =
  "PURCHASE_ORDER_LINE_ITEM_CREATE";
export const PURCHASE_ORDER_LINE_ITEM_CREATING =
  "PURCHASE_ORDER_LINE_ITEM_CREATING";
export const PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR =
  "PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR";
export const PURCHASE_ORDER_LINE_ITEM_CREATED =
  "PURCHASE_ORDER_LINE_ITEM_CREATED";

export const QUOTE_EDIT = "QUOTE_EDIT";
export const QUOTE_SAVE = "QUOTE_SAVE";
export const QUOTE_SAVING = "QUOTE_SAVING";
export const QUOTE_SAVED = "QUOTE_SAVED";
export const QUOTE_SAVE_FAILED = "QUOTE_SAVE_FAILED";
export const QUOTE_FETCH_REQUEST = "QUOTE_FETCH_REQUEST";
export const QUOTE_FETCH_FAILED = "QUOTE_FETCH_FAILED";
export const QUOTE_FETCH_SUCCEEDED = "QUOTE_FETCH_SUCCEEDED";
export const QUOTE_ADDRESS_CREATE = "QUOTE_ADDRESS_CREATE";
export const QUOTE_CONTACT_CREATE_EDIT = "QUOTE_CONTACT_CREATE_EDIT";
export const QUOTE_SET_ADDRESS = "QUOTE_SET_ADDRESS";
export const QUOTE_SET_CONTACT = "QUOTE_SET_CONTACT";

export const QUOTE_LINE_ITEM_ADD_HEADING = "QUOTE_LINE_ITEM_ADD_HEADING";
export const QUOTE_LINE_ITEM_ADD_LINE = "QUOTE_LINE_ITEM_ADD_LINE";
export const QUOTE_LINE_ITEM_ADD_ITEM = "QUOTE_LINE_ITEM_ADD_ITEM";
export const QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN =
  "QUOTE_LINE_ITEM_ADD_FROM_JOB_PLAN";
export const QUOTE_LINE_ITEM_CREATE = "QUOTE_LINE_ITEM_CREATE";
export const QUOTE_LINE_ITEM_CREATING = "QUOTE_LINE_ITEM_CREATING";
export const QUOTE_LINE_ITEM_CREATED = "QUOTE_LINE_ITEM_CREATED";
export const QUOTE_LINE_ITEM_CREATE_ERROR = "QUOTE_LINE_ITEM_CREATE_ERROR";
export const QUOTE_LINE_ITEM_DELETE = "QUOTE_LINE_ITEM_DELETE";
export const QUOTE_LINE_ITEM_DELETING = "QUOTE_LINE_ITEM_DELETING";
export const QUOTE_LINE_ITEM_DELETED = "QUOTE_LINE_ITEM_DELETED";
export const QUOTE_LINE_ITEM_DELETE_ERROR = "QUOTE_LINE_ITEM_DELETE_ERROR";
export const QUOTE_LINE_ITEM_EDIT = "QUOTE_LINE_ITEM_EDIT";
export const QUOTE_LINE_ITEM_SAVE = "QUOTE_LINE_ITEM_SAVE";
export const QUOTE_LINE_ITEM_SAVING = "QUOTE_LINE_ITEM_SAVING";
export const QUOTE_LINE_ITEM_SAVED = "QUOTE_LINE_ITEM_SAVED";
export const QUOTE_LINE_ITEM_SAVE_ERROR = "QUOTE_LINE_ITEM_SAVE_ERROR";
export const QUOTE_LINE_ITEM_TAX_RATE_CREATE =
  "QUOTE_LINE_ITEM_TAX_RATE_CREATE";
export const QUOTE_LINE_ITEM_SET_IS_MERGING = "QUOTE_LINE_ITEM_SET_IS_MERGING";
export const QUOTE_LINE_ITEM_MERGE_SAVING = "QUOTE_LINE_ITEM_MERGE_SAVING";
export const QUOTE_LINE_ITEM_MERGE_SAVED = "QUOTE_LINE_ITEM_MERGE_SAVED";
export const QUOTE_LINE_ITEM_MERGE_SAVE_ERROR =
  "QUOTE_LINE_ITEM_MERGE_SAVE_ERROR";
export const QUOTE_LINE_ITEM_UNDO_MERGE = "QUOTE_LINE_ITEM_UNDO_MERGE";
export const QUOTE_LINE_ITEM_UNDO_MERGE_SAVING =
  "QUOTE_LINE_ITEM_UNDO_MERGE_SAVING";
export const QUOTE_LINE_ITEM_UNDO_MERGE_SAVED =
  "QUOTE_LINE_ITEM_UNDO_MERGE_SAVED";
export const QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR =
  "QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR";
export const QUOTE_LINE_ITEM_SORT_START = "QUOTE_LINE_ITEM_SORT_START";
export const QUOTE_LINE_ITEM_SORT_MOVE = "QUOTE_LINE_ITEM_SORT_MOVE";
export const QUOTE_LINE_ITEM_SORT_CANCEL = "QUOTE_LINE_ITEM_SORT_CANCEL";
export const QUOTE_LINE_ITEM_SORT_CONFIRM = "QUOTE_LINE_ITEM_SORT_CONFIRM";
export const QUOTE_LINE_ITEM_SORT_SAVING = "QUOTE_LINE_ITEM_SORT_SAVING";
export const QUOTE_LINE_ITEM_SORT_SAVED = "QUOTE_LINE_ITEM_SORT_SAVED";
export const QUOTE_LINE_ITEM_SORT_SAVE_ERROR =
  "QUOTE_LINE_ITEM_SORT_SAVE_ERROR";

export const REPEATING_LOGGED_TIME_OPEN_EDIT =
  "REPEATING_LOGGED_TIME_OPEN_EDIT";

export const SCHEDULE_BLOCK_CREATE = "SCHEDULE_BLOCK_CREATE";
export const SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID =
  "SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID";
export const SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID =
  "SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID";
export const SCHEDULE_BLOCK_DRAG_CANCEL = "SCHEDULE_BLOCK_DRAG_CANCEL";
export const SCHEDULE_BLOCK_DRAG_END = "SCHEDULE_BLOCK_DRAG_END";
export const SCHEDULE_BLOCK_DRAG_END_SAGA = "SCHEDULE_BLOCK_DRAG_END_SAGA";
export const SCHEDULE_BLOCK_DRAG_END_MULTIPLE =
  "SCHEDULE_BLOCK_DRAG_END_MULTIPLE";
export const SCHEDULE_BLOCK_DRAG_MULTIPLE = "SCHEDULE_BLOCK_DRAG_MULTIPLE";
export const SCHEDULE_BLOCK_DRAG_START = "SCHEDULE_BLOCK_DRAG_START";
export const SCHEDULE_BLOCK_DRAG_START_MULTIPLE =
  "SCHEDULE_BLOCK_DRAG_START_MULTIPLE";
export const SCHEDULE_BLOCK_OPEN_EDIT = "SCHEDULE_BLOCK_OPEN_EDIT";
export const SCHEDULE_BLOCK_PAINT = "SCHEDULE_BLOCK_PAINT";
export const SCHEDULE_BLOCK_PAINT_CANCEL = "SCHEDULE_BLOCK_PAINT_CANCEL";
export const SCHEDULE_BLOCK_PAINT_START_SAGA =
  "SCHEDULE_BLOCK_PAINT_START_SAGA";
export const SCHEDULE_BLOCK_PAINT_END = "SCHEDULE_BLOCK_PAINT_END";
export const SCHEDULE_BLOCK_PAINT_START = "SCHEDULE_BLOCK_PAINT_START";
export const SCHEDULE_BLOCK_RESIZE = "SCHEDULE_BLOCK_RESIZE";
export const SCHEDULE_BLOCK_RESIZE_CANCEL = "SCHEDULE_BLOCK_RESIZE_CANCEL";
export const SCHEDULE_BLOCK_RESIZE_END = "SCHEDULE_BLOCK_RESIZE_END";
export const SCHEDULE_BLOCK_RESIZE_START = "SCHEDULE_BLOCK_RESIZE_START";
export const SCHEDULE_BLOCK_SET_ACTIVE = "SCHEDULE_BLOCK_SET_ACTIVE";
export const SCHEDULE_BLOCK_SET_SELECTED = "SCHEDULE_BLOCK_SET_SELECTED";
export const SCHEDULE_BLOCK_TOGGLE_SELECTED = "SCHEDULE_BLOCK_TOGGLE_SELECTED";
export const SCHEDULE_BLOCK_UNSELECT_ALL = "SCHEDULE_BLOCK_UNSELECT_ALL";
export const SCHEDULE_BLOCK_REASSIGN = "SCHEDULE_BLOCK_REASSIGN";
export const SCHEDULE_BLOCK_REASSIGN_MULTIPLE =
  "SCHEDULE_BLOCK_REASSIGN_MULTIPLE";
export const SCHEDULE_BLOCK_DUPLICATE = "SCHEDULE_BLOCK_DUPLICATE";
export const SCHEDULE_BLOCK_DUPLICATE_MULTIPLE =
  "SCHEDULE_BLOCK_DUPLICATE_MULTIPLE";
export const SCHEDULE_BLOCK_REASSIGN_SAGA = "SCHEDULE_BLOCK_REASSIGN_SAGA";
export const SCHEDULE_BLOCK_LOG = "SCHEDULE_BLOCK_LOG";
export const SCHEDULE_BLOCK_LOG_MULTIPLE = "SCHEDULE_BLOCK_LOG_MULTIPLE";
export const SCHEDULE_BLOCK_UNLOG = "SCHEDULE_BLOCK_UNLOG";
export const SCHEDULE_BLOCK_UNLOG_MULTIPLE = "SCHEDULE_BLOCK_UNLOG_MULTIPLE";
export const SCHEDULE_BLOCK_START_DELETE = "SCHEDULE_BLOCK_START_DELETE";
export const SCHEDULE_BLOCK_START_DELETE_MULTIPLE =
  "SCHEDULE_BLOCK_START_DELETE_MULTIPLE";
export const SCHEDULE_BLOCK_DELETE = "SCHEDULE_BLOCK_DELETE";
export const SCHEDULE_BLOCK_DELETE_ERROR = "SCHEDULE_BLOCK_DELETE_ERROR";
export const SCHEDULE_BLOCK_SAVE_ERROR = "SCHEDULE_BLOCK_SAVE_ERROR";
export const SCHEDULE_BLOCK_SAVED = "SCHEDULE_BLOCK_SAVED";
export const SCHEDULE_BLOCK_SAVING = "SCHEDULE_BLOCK_SAVING";
export const SCHEDULE_BLOCK_SPLIT_CANCEL = "SCHEDULE_BLOCK_SPLIT_CANCEL";
export const SCHEDULE_BLOCK_SPLIT_DAYS = "SCHEDULE_BLOCK_SPLIT_DAYS";
export const SCHEDULE_BLOCK_SPLIT_END = "SCHEDULE_BLOCK_SPLIT_END";
export const SCHEDULE_BLOCK_SPLIT_START = "SCHEDULE_BLOCK_SPLIT_START";
export const SCHEDULE_BLOCK_SPLIT_SAVING = "SCHEDULE_BLOCK_SPLIT_SAVING";
export const SCHEDULE_BLOCK_SPLIT_SAVED = "SCHEDULE_BLOCK_SPLIT_SAVED";
export const SCHEDULE_BLOCK_SPLIT_SAVE_ERROR =
  "SCHEDULE_BLOCK_SPLIT_SAVE_ERROR";
export const SCHEDULE_BLOCK_BULK_SAVE = "SCHEDULE_BLOCK_BULK_SAVE";

export const SCHEDULE_JOB_EXPAND = "SCHEDULE_JOB_EXPAND";
export const SCHEDULE_JOB_COLLAPSE = "SCHEDULE_JOB_COLLAPSE";
export const SCHEDULE_JOB_BOOTSTRAP_REQUEST = "SCHEDULE_JOB_BOOTSTRAP_REQUEST";
export const SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED =
  "SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED";
export const SCHEDULE_JOB_BOOTSTRAP_FAILED = "SCHEDULE_JOB_BOOTSTRAP_FAILED";

export const SCHEDULE_JOB_PHASE_EXPAND = "SCHEDULE_JOB_PHASE_EXPAND";
export const SCHEDULE_JOB_PHASE_COLLAPSE = "SCHEDULE_JOB_PHASE_COLLAPSE";
export const SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE =
  "SCHEDULE_JOB_PHASE_EXPAND_MULTIPLE";
export const SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE =
  "SCHEDULE_JOB_PHASE_COLLAPSE_MULTIPLE";

export const SCHEDULE_JOB_ITEM_EXPAND = "SCHEDULE_JOB_ITEM_EXPAND";
export const SCHEDULE_JOB_ITEM_COLLAPSE = "SCHEDULE_JOB_ITEM_COLLAPSE";
export const SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE =
  "SCHEDULE_JOB_ITEM_EXPAND_MULTIPLE";
export const SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE =
  "SCHEDULE_JOB_ITEM_COLLAPSE_MULTIPLE";

export const SCHEDULE_JOB_EXTRA_HOURS_EXPAND =
  "SCHEDULE_JOB_EXTRA_HOURS_EXPAND";
export const SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE =
  "SCHEDULE_JOB_EXTRA_HOURS_COLLAPSE";

export const SCHEDULE_JOB_BAR_DRAG_CANCEL = "SCHEDULE_JOB_BAR_DRAG_CANCEL";
export const SCHEDULE_JOB_BAR_DRAG_END = "SCHEDULE_JOB_BAR_DRAG_END";
export const SCHEDULE_JOB_BAR_DRAG_START = "SCHEDULE_JOB_BAR_DRAG_START";
export const SCHEDULE_JOB_BAR_DRAG = "SCHEDULE_JOB_BAR_DRAG";

export const SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL =
  "SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL";
export const SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL_ERROR =
  "SCHEDULE_JOB_OPEN_CREATE_JOB_ITEM_MODAL_ERROR";

export const SCHEDULE_JOB_SHIFT_DURATION_SAVING =
  "SCHEDULE_JOB_SHIFT_DURATION_SAVING";
export const SCHEDULE_JOB_SHIFT_DURATION_SAVED =
  "SCHEDULE_JOB_SHIFT_DURATION_SAVED";
export const SCHEDULE_JOB_SHIFT_DURATION_ERROR =
  "SCHEDULE_JOB_SHIFT_DURATION_ERROR";

export const SCHEDULE_JOB_ITEM_ADD_USER = "SCHEDULE_JOB_ITEM_ADD_USER";
export const SCHEDULE_JOB_ITEM_ADD_USER_SAVING =
  "SCHEDULE_JOB_ITEM_ADD_USER_SAVING";
export const SCHEDULE_JOB_ITEM_ADD_USER_SAVED =
  "SCHEDULE_JOB_ITEM_ADD_USER_SAVED";
export const SCHEDULE_JOB_ITEM_ADD_USER_ERROR =
  "SCHEDULE_JOB_ITEM_ADD_USER_ERROR";

export const SCHEDULE_JOB_ITEM_USER_UNASSIGN =
  "SCHEDULE_JOB_ITEM_USER_UNASSIGN";
export const SCHEDULE_JOB_ITEM_USER_DELETE = "SCHEDULE_JOB_ITEM_USER_DELETE";
export const SCHEDULE_JOB_ITEM_USER_DELETE_ERROR =
  "SCHEDULE_JOB_ITEM_USER_DELETE_ERROR";

export const SCHEDULE_SET_FILTER_TEAM_MEMBERS =
  "SCHEDULE_SET_FILTER_TEAM_MEMBERS";

export const SCHEDULE_USER_JOB_EXPAND = "SCHEDULE_USER_JOB_EXPAND";
export const SCHEDULE_USER_JOB_COLLAPSE = "SCHEDULE_USER_JOB_COLLAPSE";

export const SET_USER_PREFERENCE = "SET_USER_PREFERENCE";

export const LABELS_FETCH_REQUEST = "LABELS_FETCH_REQUEST";

export const LABELS_DELETE = "LABELS_DELETE";
export const LABELS_DELETE_ERROR = "LABELS_DELETE_ERROR";
export const LABELS_DELETED = "LABELS_DELETED";
export const LABELS_DELETING = "LABELS_DELETING";

export const LABELS_CREATE = "LABELS_CREATE";
export const LABELS_CREATE_ERROR = "LABELS_CREATE_ERROR";
export const LABELS_CREATED = "LABELS_CREATED";
export const LABELS_CREATING = "LABELS_CREATING";
