import MuiSwitch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const offColor = "var(--color-gray-medium)";
const onColor = "var(--color-charcoal)";

const Switch = withStyles(theme => ({
  root: {
    width: 24,
    height: 14,
    padding: 0,
    margin: 5,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(10px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: onColor,
        borderColor: onColor
      }
    }
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${offColor}`,
    borderRadius: 14 / 2,
    opacity: 1,
    backgroundColor: offColor
  },
  checked: {}
}))(MuiSwitch);

export default Switch;
