import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";
import { asDecimal } from "../../../../lib/math";
import { setSum } from "../../../../lib/objects";
import {
  calculateTotalIncTax,
  getIsHeading,
  getTaxRate,
  getTotalAmountExTax
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedInvoiceIds) =>
  produce(nextState, draft => {
    const getInvoiceLineItem = id => nextState.byId[id];

    touchedInvoiceIds.forEach(invoiceId => {
      const sortedIds = get(nextState.sortedIdsByInvoiceId, invoiceId);

      if (!sortedIds) {
        unset(draft, `subTotalsIncTaxByInvoiceId.${invoiceId}`);
        return;
      }

      const sortedLineItems = sortedIds.map(getInvoiceLineItem);
      const subTotals = {};
      let lastHeadingId = null;

      sortedLineItems.forEach(invoiceLineItem => {
        if (getIsHeading(invoiceLineItem)) {
          lastHeadingId = invoiceLineItem.id;
          return;
        }

        if (!lastHeadingId) return;

        const totalExTax = asDecimal(getTotalAmountExTax(invoiceLineItem));
        const taxRate = getTaxRate(invoiceLineItem);
        const lineTotal = calculateTotalIncTax(totalExTax, taxRate);

        setSum(subTotals, lastHeadingId, lineTotal);
      });

      setWith(
        draft,
        `subTotalsIncTaxByInvoiceId.${invoiceId}`,
        subTotals,
        Object
      );
    });
  });
