import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getPermissionsSnapshot } from "st-shared/stores";
import styled from "styled-components";

import {
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_BAR,
  DATA_ATTRIBUTE_JOB_ITEM_DAYS,
  JOB_TIMELINE_BAR_HOVER_MIN_WIDTH,
  JOB_TIMELINE_ITEM_ROW_HEIGHT,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import {
  getJobItemStatusIconClass,
  getJobItemStatusId,
  getScheduleBarEndDate,
  getScheduleBarStartDate,
  jobItemEntityType,
} from "../../../lib/entities/jobItemEntity";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemById } from "../../../redux/selectors/jobItemSelectors";
import {
  selectIsCreatingDependancyForJobItem,
  selectIsDraggingJobItem,
  selectIsResizingJobItem,
} from "../../../redux/selectors/jobItemUiSelectors";
import { selectJobItemUserIdsByJobItemId } from "../../../redux/selectors/jobItemUserSelectors";
import { selectScheduleJobItemUiIsExpanded } from "../../../redux/selectors/scheduleSelectors";
import { selectOrderedJobItemRoleIdsByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectOrderedJobItemRoleIdsByJobItemId";
import CapturePointerElement from "../../elements/CapturePointerElement";
import JobItemBarDependancyHandle, {
  DependancyHandle,
} from "../../JobTimeline/JobTimelinePhaseRow/JobItemBarDependancyHandle";
import SegmentsByJobItem from "../../modules/JobItemDependancies/SegmentsByJobItem";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import Anchor from "../ScheduleScrollProvider/Anchor";
import ScheduleBarConsumer from "../ScheduleScrollProvider/ScheduleBarConsumer";
import ScheduleBarResizeHandle, {
  ResizeHandleWrapper,
} from "./ScheduleBarResizeHandle";
import { ScheduleBarRect, ScheduleJobItemBar } from "./ScheduleBars";
import ScheduleJobItemRoleTodos from "./ScheduleJobItemRoleTodos";
import ScheduleJobItemUserTodos from "./ScheduleJobItemUserTodos";

const jobAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB);
const jobItemAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM);
const barAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_BAR);
const daysAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_DAYS);

const mapState = (state, props) => ({
  jobItem: selectJobItemById(state, props),
  jobItemUserIds: selectJobItemUserIdsByJobItemId(state, props),
  jobItemRoleIds: selectOrderedJobItemRoleIdsByJobItemId(state, {
    jobItemId: props.id,
  }),
  canEditJobs: getPermissionsSnapshot().canEditJobs,
  isExpanded: selectScheduleJobItemUiIsExpanded(state, props),
  isDragging: selectIsDraggingJobItem(state, props),
  isResizing: selectIsResizingJobItem(state, props),
  isCreatingDependancy: selectIsCreatingDependancyForJobItem(state, props),
});

const mapDispatch = null;

class ScheduleJobItem extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    jobItem: jobItemEntityType.isRequired,
    jobItemUserIds: entityIdListType.isRequired,
    jobItemRoleIds: entityIdListType.isRequired,
    canEditJobs: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isResizing: PropTypes.bool.isRequired,
    isCreatingDependancy: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
  };

  get hasStartAndEndDate() {
    const { jobItem } = this.props;
    return jobItem.estimatedStartDate && jobItem.estimatedEndDate;
  }

  get canAddStartDateDependancies() {
    const { jobItem, canEditJobs } = this.props;
    return canEditJobs && jobItem.estimatedStartDate;
  }

  get canAddEndDateDependancies() {
    const { jobItem, canEditJobs } = this.props;
    return canEditJobs && jobItem.estimatedEndDate;
  }

  get canResizeLeft() {
    const { jobItem, canEditJobs, isLocked } = this.props;
    return (
      canEditJobs &&
      !isLocked &&
      (this.hasStartAndEndDate || !jobItem.estimatedStartDate)
    );
  }

  get canResizeRight() {
    const { jobItem, canEditJobs, isLocked } = this.props;
    return (
      canEditJobs &&
      !isLocked &&
      (this.hasStartAndEndDate || !jobItem.estimatedEndDate)
    );
  }

  get canMove() {
    const { jobItem, canEditJobs, isLocked } = this.props;
    return Boolean(
      canEditJobs &&
        !isLocked &&
        (jobItem.estimatedStartDate || jobItem.estimatedEndDate)
    );
  }

  get barContainerClassName() {
    const {
      jobItem,
      isDragging,
      isResizing,
      isCreatingDependancy,
      canEditJobs,
      isLocked,
    } = this.props;
    const classes = [];
    if (isDragging) classes.push("dragging");
    if (isResizing) classes.push("resizing");
    if (isCreatingDependancy) classes.push("isCreatingDependancy");
    if (!canEditJobs || isLocked) classes.push("readOnly");
    if (canEditJobs && !jobItem.estimatedStartDate && !jobItem.estimatedEndDate)
      classes.push("paintable");
    return classes.join(" ");
  }

  getBarRectClassName(width, classes = []) {
    const { isLocked } = this.props;
    if (this.canMove) classes.push("canMove");
    if (isLocked) classes.push("locked");
    if (width <= JOB_TIMELINE_BAR_HOVER_MIN_WIDTH) classes.push("short");
    return classes.join(" ");
  }

  getBarClassName(classes = []) {
    const { jobItem, isLocked } = this.props;

    const jobItemStatusId = getJobItemStatusId(jobItem);
    const jobItemStatusClassName = getJobItemStatusIconClass(jobItemStatusId);

    return [jobItemStatusClassName, ...classes]
      .concat(isLocked ? "locked" : [])
      .join(" ");
  }

  render() {
    const { id, jobItem, jobItemUserIds, jobItemRoleIds, isExpanded } =
      this.props;
    const { jobId, estimatedStartDate, estimatedEndDate } = jobItem;

    return (
      <>
        <JobItemContainer
          className={this.barContainerClassName}
          {...{
            [jobItemAttrName]: id,
          }}
        >
          <ScheduleBarConsumer
            barStartDate={getScheduleBarStartDate(jobItem)}
            barEndDate={getScheduleBarEndDate(jobItem)}
            startDate={estimatedStartDate}
            dueDate={estimatedEndDate}
          >
            {({ inner, outer }) => (
              <Anchor>
                {outer && (
                  <ScheduleBarRect
                    style={{ left: outer.left, width: outer.width }}
                  >
                    <ScheduleJobItemBar className={this.getBarClassName()} />
                  </ScheduleBarRect>
                )}
                {inner && (
                  <ScheduleBarRect
                    style={{ left: inner.left, width: inner.width }}
                    {...{
                      [jobAttrName]: jobId,
                      [barAttrName]: id,
                      [daysAttrName]: inner.days,
                    }}
                    className={this.getBarRectClassName(
                      inner.width,
                      inner.classes
                    )}
                    disabled={!this.canMove}
                  >
                    {this.canResizeLeft && (
                      <ScheduleBarResizeHandle
                        left
                        className={this.getBarClassName(inner.classes)}
                      />
                    )}
                    <ScheduleJobItemBar
                      inner
                      canEdit={this.canMove}
                      className={this.getBarClassName(inner.classes)}
                    />
                    {this.canResizeRight && (
                      <ScheduleBarResizeHandle
                        right
                        className={this.getBarClassName(inner.classes)}
                      />
                    )}
                    {this.canAddStartDateDependancies && (
                      <JobItemBarDependancyHandle left />
                    )}
                    {this.canAddEndDateDependancies && (
                      <JobItemBarDependancyHandle right />
                    )}
                  </ScheduleBarRect>
                )}
              </Anchor>
            )}
          </ScheduleBarConsumer>
          <Anchor>
            <SegmentsByJobItem
              Context={ScheduleScrollContext}
              jobId={jobId}
              jobItemId={id}
            />
          </Anchor>
        </JobItemContainer>
        {isExpanded && (
          <>
            {jobItemUserIds.map((jobItemUserId) => (
              <ScheduleJobItemUserTodos
                key={jobItemUserId}
                id={jobItemUserId}
                jobId={jobId}
              />
            ))}
            {jobItemRoleIds.map((jobItemRoleId) => (
              <ScheduleJobItemRoleTodos key={jobItemRoleId} />
            ))}
          </>
        )}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJobItem);

const JobItemContainer = styled(CapturePointerElement)`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: var(--border-thin-bright);
  }
  &.readOnly ${ScheduleBarRect} {
    pointer-events: none;
  }

  &:not(.readOnly) {
    &.isCreatingDependancy,
    &.dragging,
    &.resizing,
    &:hover {
      &.paintable {
        cursor: none;
      }
      .canMove ${ScheduleJobItemBar} {
        cursor: grab;
      }
      ${ResizeHandleWrapper} {
        opacity: 1;
      }
      ${DependancyHandle} {
        opacity: 1;
      }
      ${ScheduleBarRect} {
        z-index: 5;
        &.short {
          min-width: ${JOB_TIMELINE_BAR_HOVER_MIN_WIDTH}px;
          transform: translateX(-15px);
        }
        &.startOnly,
        &.endOnly {
          ${ScheduleJobItemBar} {
            width: 100%;
          }
        }
      }
    }
  }
`;
