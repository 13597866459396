import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectPropsJobItemId } from "../../index";
import { selectJobDetailsCurrentFocus } from "./currentFocus";

export const selectJobDetailsUiAreKeysCurrentFocus = createSelector(
  [
    selectJobDetailsCurrentFocus,
    selectPropsJobItemId,
    (state, props) => props.keys
  ],
  (currentFocus, jobItemId, keys) => {
    if (!currentFocus) return false;
    if (currentFocus.jobItemId !== jobItemId) return false;
    return keys.includes(currentFocus.key);
  }
);

export const useJobDetailsUiAreKeysCurrentFocus = (jobItemId, keys) =>
  useSelector(state =>
    selectJobDetailsUiAreKeysCurrentFocus(state, {
      jobItemId,
      keys
    })
  );
