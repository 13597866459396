import { Branch, EntityClass, type TEntityId } from "@streamtimefe/entities";
import { useMemo } from "react";
import { isDefined } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import { useActiveOrHibernatedUsers } from "../User";

export const useBranchEntity = createUseEntityStoreEntity(EntityClass.Branch);

export const getBranchEntity = createGetEntityStoreEntity(EntityClass.Branch);

function useBranchEntities() {
  return useEntityStoreEntities(EntityClass.Branch);
}

export function useActiveBranches() {
  const branches = useBranchEntities();
  return useMemo(
    () =>
      Object.values(branches)
        .filter(isDefined)
        .filter((branch) => branch.active),
    [branches]
  );
}

export function useActiveBranchSearchItems() {
  const branches = useActiveBranches();
  return useMemo(() => Branch.getSearchItems(branches), [branches]);
}

export function useBranchUsers(id: TEntityId) {
  const users = useActiveOrHibernatedUsers();
  return useMemo(
    () =>
      Object.values(users)
        .filter(isDefined)
        .filter((user) => user.branchId === id),
    [users, id]
  );
}

export function useBranchUsersCount(id: TEntityId) {
  return useBranchUsers(id).length;
}
