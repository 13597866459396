import { ENTITY_CLASS_JOB_ITEM_DEPENDANCY } from "../constants";
import { createModelSetParser } from "./modelSets";
import API from "./index";

export const createJobItemDependancyAPI = jobItemDependancy =>
  API.post(`/job_item_dependancies`, {
    ...jobItemDependancy,
    id: null
  })
    .then(({ data }) => data)
    .then(createModelSetParser(ENTITY_CLASS_JOB_ITEM_DEPENDANCY));

export const deleteJobItemDependancyAPI = jobItemDependancy =>
  API.delete(`/job_item_dependancies/${jobItemDependancy.id}`);
