import React from "react";
import styled from "styled-components";
import { JOB_TIMELINE_ITEM_ROW_HEIGHT } from "../../../lib/constants";

const JobLoadingPulser = () => (
  <div>
    <Pulser />
  </div>
);

export default JobLoadingPulser;

const Pulser = styled.div`
  position: relative;
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  display: flex;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -100px;
    top: 10px;
    width: 900px;
    height: 14px;
    border-radius: 7px;
    animation: var(--animation-pulsate);
    background-color: var(--color-gray);
  }
`;
