import { produce } from "immer";
import { get, setWith, sortBy } from "lodash-es";

import { getOrderId } from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    touchedQuoteIds.forEach((quoteId) => {
      const quoteLineItems = get(nextState, `idsByQuoteId.${quoteId}`, []).map(
        (id) => nextState.byId[id]
      );
      const orderedQuoteLineItems = sortBy(quoteLineItems, "orderId");

      let count = 0;
      orderedQuoteLineItems.forEach((quoteLineItem) => {
        count += 1;

        if (getOrderId(quoteLineItem) !== count) {
          const newQuoteLineItem = {
            ...quoteLineItem,
            orderId: count,
          };

          setWith(draft, `byId.${quoteLineItem.id}`, newQuoteLineItem, Object);
        }
      });
    });
  });
