import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  JOB_DETAILS_ITEM_REORDER,
  JOB_DETAILS_PHASE_REORDER,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_ALL_EXPANDED,
  JOB_PHASE_SET_ALL_EXPANDED
} from "../../../lib/constants";
import { extractId, isItem, isPhase } from "../../../lib/sortingHelpers";
import createAction from "../../../redux/helpers/createAction";
import { useHasUnplannedTime } from "../../../redux/selectors/jobDetails/ui/hasUnplannedTime";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPlanSortedIds } from "../../../redux/selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";
import Spacer from "../../elements/Spacer";
import ExtraHoursTracked from "../ExtraHoursTracked";
import JobItem from "../JobItem";
import JobPhase from "../JobPhase";
import { useJobId } from "../context/JobIdContext";
import AddNewLargeButton from "./AddNewLargeButton";

const JobPlan = () => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const hasUnplannedTimeSummary = useHasUnplannedTime();
  const [sortedIds, activeIds] = useJobPlanSortedIds(jobId);

  const dispatch = useDispatch();

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);

    if (isPhase(active.id)) {
      dispatch(
        createAction(JOB_PHASE_SET_ALL_EXPANDED, {
          jobId,
          isExpanded: false
        })
      );
    }

    dispatch(
      createAction(JOB_ITEM_SET_ALL_EXPANDED, {
        jobId,
        isExpanded: false
      })
    );

    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: null
      })
    );
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (isJobEditable && active && over && active.id !== over.id) {
      dispatch(
        createAction(
          isItem(activeId)
            ? JOB_DETAILS_ITEM_REORDER
            : JOB_DETAILS_PHASE_REORDER,
          {
            jobId,
            activeId: active.id,
            overId: over.id,
            sortedIds
          }
        )
      );
    }

    setActiveId(null);
  };

  return (
    <Container>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={activeIds}
          strategy={verticalListSortingStrategy}
        >
          {activeIds.map((id, index) => (
            <React.Fragment key={id}>
              {isPhase(id) && <JobPhase id={extractId(id)}></JobPhase>}
              {isItem(id) && <JobItem id={extractId(id)}></JobItem>}
              {isItem(id) &&
                index < activeIds.length - 1 &&
                isPhase(activeIds[index + 1]) && <Spacer h={26} />}
            </React.Fragment>
          ))}
        </SortableContext>
      </DndContext>
      {isJobEditable && <AddNewLargeButton />}
      {hasUnplannedTimeSummary && <ExtraHoursTracked />}
    </Container>
  );
};

export default JobPlan;

const Container = styled.div`
  position: relative;
  min-height: 100px;

  & > * {
    margin-bottom: 2px;
  }
`;
