import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsPreviousFocus = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.previousFocus
);

export const useJobDetailsPreviousFocus = () =>
  useSelector(selectJobDetailsPreviousFocus);

export const selectJobDetailsUiPreviousFocusKey = createSelector(
  [selectJobDetailsPreviousFocus],
  previousFocus => get(previousFocus, `key`, null)
);

export const useJobDetailsPreviousFocusKey = () =>
  useSelector(selectJobDetailsUiPreviousFocusKey);

export const selectJobDetailsUiPreviousFocusJobId = createSelector(
  [selectJobDetailsPreviousFocus],
  previousFocus => get(previousFocus, `jobId`, null)
);

export const useJobDetailsPreviousFocusJobId = () =>
  useSelector(selectJobDetailsUiPreviousFocusJobId);

export const selectJobDetailsUiPreviousFocusJobItemId = createSelector(
  [selectJobDetailsPreviousFocus],
  previousFocus => get(previousFocus, `jobItemId`, null)
);

export const useJobDetailsPreviousFocusJobItemId = () =>
  useSelector(selectJobDetailsUiPreviousFocusJobItemId);

export const selectJobDetailsUiPreviousFocusJobPhaseId = createSelector(
  [selectJobDetailsPreviousFocus],
  previousFocus => get(previousFocus, `jobPhaseId`, null)
);

export const useJobDetailsPreviousFocusJobPhaseId = () =>
  useSelector(selectJobDetailsUiPreviousFocusJobPhaseId);
