import { EntityDescriptorEnum, LabelTypeEnum } from "@streamtimefe/entities";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { ApiException, DeletedEntityException } from "st-shared/lib";

import { fetchJobActivityAPI } from "../../../lib/API/jobActivityAPI";
import { fetchJobBootstrapAPI } from "../../../lib/API/jobAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ACTIVITY_FETCH_SUCCEEDED,
  JOB_DETAILS_FETCH_FAILED,
  JOB_DETAILS_FETCH_REQUEST,
  JOB_DETAILS_FETCH_SUCCEEDED,
  JOB_DETAILS_SET_HISTORICAL_SUMMARY,
  JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY,
  LABELS_FETCH_REQUEST,
} from "../../../lib/constants";
import { getJobFullName, isDeleted } from "../../../lib/entities/jobEntity";
import { createWebEntityRoute, RoutePages } from "../../../lib/router";
import {
  feToWebAddHistory,
  feToWebDisplay404Error,
  feToWebDisplayDeletedEntityError,
} from "../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJob } from "../../selectors/job";
import { selectJobActivityUi } from "../../selectors/jobActivitySelectors";
import { watchFetchCompanyOptions } from "../options/watchFetchCompanyOptions";
import { watchFetchJobPhasesOptions } from "../options/watchFetchJobPhasesOptions";

function* fetch(action) {
  try {
    const { jobId } = action.payload;

    const { data, primaryEntity, unplannedTimeSummary, historicalSummary } =
      yield call(fetchJobBootstrapAPI, jobId, true);

    if (isDeleted(primaryEntity)) {
      throw new DeletedEntityException();
    }

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY, {
        unplannedTimeSummary,
      })
    );
    yield put(
      createAction(JOB_DETAILS_SET_HISTORICAL_SUMMARY, {
        historicalSummary,
      })
    );

    const { activityEntriesFetchedJobIds } = yield select(selectJobActivityUi);

    if (!activityEntriesFetchedJobIds.includes(jobId)) {
      const activityEntryData = yield call(fetchJobActivityAPI, jobId);

      yield put(
        createAction(ENTITIES_RECEIVED, {
          ...activityEntryData,
        })
      );
    }

    yield put(createAction(JOB_DETAILS_FETCH_SUCCEEDED, action.payload));
    yield put(createAction(JOB_ACTIVITY_FETCH_SUCCEEDED, action.payload));

    const job = yield select(selectJob, {
      jobId,
    });

    feToWebAddHistory(
      EntityDescriptorEnum.Job,
      getJobFullName(job),
      createWebEntityRoute(RoutePages.Jobs, jobId)
    );
  } catch (error) {
    yield put(
      createAction(JOB_DETAILS_FETCH_FAILED, {
        ...action.payload,
        error,
      })
    );

    if (error instanceof DeletedEntityException) {
      feToWebDisplayDeletedEntityError();
      return;
    }

    if (error instanceof ApiException && error.response.status === 404) {
      feToWebDisplay404Error();
      return;
    }

    sagaError(error);
  }
}

function* fetchLabels(action) {
  try {
    const { jobId } = action.payload;
    yield put(
      createAction(LABELS_FETCH_REQUEST, {
        entityId: jobId,
        labelTypeId: LabelTypeEnum.Job,
      })
    );
  } catch (error) {
    if (!(error instanceof ApiException && error.response.status === 404)) {
      sagaError(error);
    }
  }
}

export default function* watchFetch() {
  yield takeEvery(JOB_DETAILS_FETCH_REQUEST, fetch);
  yield takeEvery(JOB_DETAILS_FETCH_REQUEST, watchFetchCompanyOptions);
  yield takeEvery(JOB_DETAILS_FETCH_REQUEST, watchFetchJobPhasesOptions);
  yield takeEvery(JOB_DETAILS_FETCH_REQUEST, fetchLabels);
}
