import isError from "lodash-es/isError";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_CREATE,
  SCHEDULE_BLOCK_SAVED,
} from "../../../lib/constants";
import {
  getJobItemName,
  getJobPhaseId,
} from "../../../lib/entities/jobItemEntity";
import { getJobItemUserStatusId } from "../../../lib/entities/jobItemUserEntity";
import { getJobPhaseName } from "../../../lib/entities/jobPhaseEntity";
import {
  getScheduleBlockKey,
  SCHEDULE_LOGGED_TIME_ENTITY_DEFAULTS,
} from "../../../lib/entities/scheduleLoggedTimeEntity";
import {
  getCompanyName,
  getJobFullName,
} from "../../../lib/entities/scheduleSearchResultEntity";
import { openScheduleLoggedTimeModal } from "../../../lib/WebAppAPI/schedule";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";
import { selectJobItemUserById } from "../../selectors/jobItemUserSelectors";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* openCreateBlock(action) {
  let jobFullName = "";
  let companyName = "";
  let jobItemName = "";
  let jobPhaseId = null;
  let jobPhaseName = "";
  let jobItemUserStatusId = null;

  try {
    const { date, userId, jobId, jobItemId, jobItemUserId } = action.payload;

    if (jobId) {
      const job = yield select(selectJobListModel, { jobId });
      jobFullName = getJobFullName(job);
      companyName = getCompanyName(job);
    }

    if (jobItemId) {
      const jobItem = yield select(selectJobItemById, { id: jobItemId });
      jobItemName = getJobItemName(jobItem);
      jobPhaseId = getJobPhaseId(jobItem);
    }

    if (jobPhaseId) {
      const jobPhase = yield select(selectJobPhaseById, { id: jobPhaseId });
      jobPhaseName = getJobPhaseName(jobPhase);
    }

    if (jobItemUserId) {
      const jobItemUser = yield select(selectJobItemUserById, {
        id: jobItemUserId,
      });
      jobItemUserStatusId = getJobItemUserStatusId(jobItemUser);
    }

    const scheduleLoggedTime = {
      ...SCHEDULE_LOGGED_TIME_ENTITY_DEFAULTS,
      id: null,
      date,
      userId,
      jobId,
      jobItemId,
      jobItemUserId,
      jobFullName,
      companyName,
      jobItemName,
      jobPhaseId,
      jobPhaseName,
      jobItemUserStatusId,
    };

    const { scheduleLoggedTimes, ...data } = yield call(
      openScheduleLoggedTimeModal,
      scheduleLoggedTime,
      1
    );

    yield put(
      createAction(SCHEDULE_BLOCK_SAVED, {
        ...action.payload,
        blockKey: getScheduleBlockKey(scheduleLoggedTimes[0]),
        scheduleLoggedTimes,
      })
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchOpenCreateBlock() {
  yield takeLatest([SCHEDULE_BLOCK_CREATE], openCreateBlock);
}
