import { sortBy } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectActiveUserOptionsGroupedByBranch } from "../../../../redux/selectors/userSelectors";

export const selectJobItemUserOptions = createSelector(
  [selectActiveUserOptionsGroupedByBranch],
  (userOptions) => {
    const options = sortBy(userOptions, ["name", "userStatusId"]);
    if (!options.find((option) => !option.isHeading)) return [];
    return options;
  }
);

export function useJobItemUserOptions() {
  return useSelector(selectJobItemUserOptions);
}
