import { clsx } from "clsx";
import type { RefObject } from "react";
import type { TextFieldProps as AriaTextFieldProps } from "react-aria-components";
import {
  Input,
  TextArea,
  TextField as AriaTextField,
} from "react-aria-components";
import { isDefined } from "remeda";

import { BaseButton } from "../button";
import type { IconProps } from "../icon";
import { IconCancel, IconEdit } from "../icons/action";
import { useDisabledBoundary } from "../utils";
import { FieldDescriptionAndError } from "./FieldDescriptionAndError";
import { FieldLabel } from "./FieldLabel";
import {
  editFieldHiddenTextStyle,
  editFieldIconStyle,
  fieldClearButtonStyle,
  fieldInputWrapperStyle,
  fieldStyle,
  textFieldInputStyle,
} from "./styles.css";

export type TextFieldProps = AriaTextFieldProps & {
  /** The label for the input field. If a visual label is not provided, then the `aria-label` or `aria-labelledby` prop must be passed to identify the field to assistive technology. */
  label?: string;
  /** The helper text for the input field. */
  description?: string;
  /** The error text for the input field. Displayed when `isInvalid` is true. */
  errorMessage?: string;
  /** If true, the input will be a textarea. */
  isMultiline?: boolean;
  /** If true, the input will be a resizable, only affects multiline input. */
  isResizable?: boolean;
  /** If true, the input will be display an "optional" tag alongside the label. */
  isOptional?: boolean;
  /** The placeholder text for the input field. */
  placeholder?: string;
  componentRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  inlineVariant?: "inline" | "inlineEditIcon";
  slotProps?: {
    input?: { className?: string };
  };
  inlineEditIconSize?: IconProps["size"];
};

/**
 * A Text Field allows a user to enter a plain text value with a keyboard.
 * The Text Field component is built on the [React Aria TextField Component](https://react-spectrum.adobe.com/react-aria/TextField.html).
 * A full list of props available to the Text Field component can be found in the
 * [props section of the documentation](https://react-spectrum.adobe.com/react-aria/TextField.html#props).
 *
 * Multi-line input fields are fluid and expand with the text until the max height
 * is met. Once the field hits max height, a scroll bar is triggered.
 * Multi-line input fields have a base height of 72px and a max height of 120px.
 * Note: [browser support](https://caniuse.com/?search=field-sizing) for resizing is limited.
 *
 * ### Accessibility
 *
 * If a visual label is not provided, then the `aria-label` or `aria-labelledby`
 * prop must be passed to identify the field to assistive technology.
 */
export function TextField({
  label,
  description,
  isInvalid,
  errorMessage,
  isMultiline,
  isResizable = false,
  isOptional,
  isDisabled,
  placeholder,
  className,
  componentRef,
  inlineVariant,
  slotProps,
  inlineEditIconSize = 14,
  ...rest
}: TextFieldProps) {
  const hasClear = isOptional && isDefined(rest.value) && rest.value.length > 0;
  const isDisabledBoundary = useDisabledBoundary();

  return (
    <AriaTextField
      {...rest}
      isDisabled={isDisabled || isDisabledBoundary}
      isInvalid={isInvalid}
      className={clsx(fieldStyle, className)}
    >
      {label && (
        <FieldLabel
          text={label}
          isOptional={isOptional}
          isRequired={rest.isRequired}
        />
      )}
      <div className={fieldInputWrapperStyle}>
        {!isMultiline ? (
          <>
            <Input
              className={clsx(
                textFieldInputStyle({
                  hasClear,
                  inline: isDefined(inlineVariant),
                }),
                slotProps?.input?.className
              )}
              placeholder={placeholder}
              ref={componentRef as RefObject<HTMLInputElement>}
            />
            {inlineVariant === "inlineEditIcon" && (
              <div className={editFieldIconStyle}>
                <span
                  className={clsx(
                    textFieldInputStyle({
                      hasClear,
                      inline: isDefined(inlineVariant),
                    }),
                    editFieldHiddenTextStyle,
                    slotProps?.input?.className
                  )}
                >
                  {rest.value || placeholder}
                </span>
                <IconEdit size={inlineEditIconSize} color="tertiary" />
              </div>
            )}
          </>
        ) : (
          <TextArea
            className={clsx(
              textFieldInputStyle({
                type: "textarea",
                hasClear,
                inline: isDefined(inlineVariant),
              }),
              slotProps?.input?.className
            )}
            placeholder={placeholder}
            rows={3}
            ref={componentRef as RefObject<HTMLTextAreaElement>}
            data-resizable={isResizable}
          />
        )}
        {hasClear && (
          <BaseButton
            aria-label="Clear"
            onPress={() => rest.onChange?.("")}
            className={fieldClearButtonStyle}
            excludeFromTabOrder
          >
            <IconCancel size={16} />
          </BaseButton>
        )}
      </div>
      <FieldDescriptionAndError
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        description={description}
      />
    </AriaTextField>
  );
}
