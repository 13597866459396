import numeral from "numeral";
import React from "react";
import {
  DATE_FORMAT_INVOICE_PAYMENT,
  NUMBER_FORMAT_FULL_DEFAULT
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import {
  getAmountPaidIncTax,
  getNotes,
  getPaymentDate
} from "../../../lib/entities/invoicePaymentEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  formatCurrencyForCommercialDocuments,
  getCurrency
} from "../../../lib/ui/commercialDocument";
import { useInvoicePayment } from "../../../redux/selectors/invoicePayment";
import { useCommercialDocument } from "../../modules/CommercialDocuments/CommercialDocumentContext";

const InvoicePaymentRow = ({ invoicePaymentId }) => {
  const invoicePayment = useInvoicePayment(invoicePaymentId);

  const { document, documentMethods } = useCommercialDocument();
  const documentCurrency = getCurrency(document);
  const { displayCurrencySymbols } = documentMethods.getDisplayOptions(
    document
  );

  const formatValue = value => {
    if (displayCurrencySymbols)
      return formatCurrencyForCommercialDocuments(
        value,
        documentCurrency.symbol
      );

    return numeral(value).format(NUMBER_FORMAT_FULL_DEFAULT);
  };

  return (
    <tr>
      <td className="date">
        {formatDate(
          getPaymentDate(invoicePayment),
          DATE_FORMAT_INVOICE_PAYMENT
        )}
      </td>
      <td>
        {getNotes(invoicePayment).length > 0 && getNotes(invoicePayment)}
        {getNotes(invoicePayment).length === 0 && (
          <span className="gray-dark">Payment recorded</span>
        )}
      </td>
      <td>{formatValue(getAmountPaidIncTax(invoicePayment))}</td>
    </tr>
  );
};

InvoicePaymentRow.propTypes = {
  invoicePaymentId: entityIdType.isRequired
};

export default InvoicePaymentRow;
