import { call, put, select, takeEvery } from "redux-saga/effects";
import { splitScheduleBlockAPI } from "../../../lib/API/scheduleAPI";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_SPLIT_END,
  SCHEDULE_BLOCK_SPLIT_SAVE_ERROR,
  SCHEDULE_BLOCK_SPLIT_SAVED,
  SCHEDULE_BLOCK_SPLIT_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectScheduleLoggedTimesByBlockKey } from "../../selectors/scheduleBlockSelectors";

function* splitBlock(action) {
  const { blockKey, splitDays } = action.payload;

  const scheduleLoggedTimes = yield select(
    selectScheduleLoggedTimesByBlockKey,
    { blockKey }
  );

  try {
    yield put(createAction(SCHEDULE_BLOCK_SPLIT_SAVING, action.payload));

    const { data } = yield call(splitScheduleBlockAPI, blockKey, splitDays);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(SCHEDULE_BLOCK_SPLIT_SAVED, {
        ...action.payload,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(SCHEDULE_BLOCK_SPLIT_SAVE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        scheduleLoggedTimes,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchSplitBlock() {
  yield takeEvery([SCHEDULE_BLOCK_SPLIT_END], splitBlock);
}
