import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ProgressBar = ({
  progressColor,
  progressPercentage,
  secondaryProgressPercentage,
  secondaryProgressColor,
  noOverrun,
  secondaryNoOverrun
}) => (
  <Wrapper>
    <Bar
      $progressColor={progressColor}
      $isOverrun={isOverrun(progressPercentage) && !noOverrun}
      $progressPercentage={getCappedProgressPercentage(progressPercentage)}
    ></Bar>

    {secondaryProgressPercentage > 0 && (
      <SecondaryBar
        $progressColor={secondaryProgressColor}
        $isOverrun={
          isOverrun(secondaryProgressPercentage) && !secondaryNoOverrun
        }
        $progressPercentage={getCappedProgressPercentage(
          secondaryProgressPercentage
        )}
      ></SecondaryBar>
    )}
  </Wrapper>
);

ProgressBar.propTypes = {
  progressColor: PropTypes.string.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  secondaryProgressPercentage: PropTypes.number,
  secondaryProgressColor: PropTypes.string,
  noOverrun: PropTypes.bool,
  secondaryNoOverrun: PropTypes.bool
};

ProgressBar.defaultProps = {
  secondaryProgressPercentage: 0,
  secondaryProgressColor: "var(--color-gray-bright)",
  noOverrun: false,
  secondaryNoOverrun: false
};

const getCappedProgressPercentage = progressPercentage => {
  return Math.min(progressPercentage, 100);
};

const isOverrun = progressPercentage => {
  return progressPercentage > 100;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: var(--color-gray-bright);
`;

const Bar = styled.div`
  --progress-color: ${props => props.$progressColor};
  --progress-percentage: ${props => String(props.$progressPercentage)}%;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: var(--progress-percentage);
  height: 100%;
  background-color: var(--progress-color);
  background-color: ${props =>
    props.$isOverrun ? "var(--color-red)" : "var(--progress-color)"};
`;

const SecondaryBar = styled(Bar)`
  --progress-color: ${props => props.$secondaryProgressColor};

  z-index: 1;
`;

export default ProgressBar;
