import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_LABEL_OPTIONS,
  ENTITY_NAME_LABELS,
  LABELS_CREATED
} from "../../../../lib/constants";
import { getAsNewLabelOption } from "../../../../lib/entities/labelEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createIndexedOptionsReducer from "../../../helpers/createIndexedOptionsReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import { getAsOption } from "./index";

const optionsReducer = createIndexedOptionsReducer(
  entity => (entity && entity.labelType && entity.labelType.id) || null,
  getAsOption
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    optionsByLabelType: optionsReducer(
      state.optionsByLabelType,
      changedEntities
    )
  };

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_LABEL_OPTIONS]
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_LABEL_OPTIONS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const addLabelOption = (state, { payload: { data } }) => {
  if (data[ENTITY_NAME_LABELS] && data[ENTITY_NAME_LABELS].length > 0) {
    const changedEntities = parseEntityPayload(state, [
      getAsNewLabelOption(data[ENTITY_NAME_LABELS][0])
    ]);
    return reduceChangedEntities(state, changedEntities);
  }
  return state;
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [LABELS_CREATED]: addLabelOption
  }
);
