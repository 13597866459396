import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import ExpandMoreIcon from "../../elements/Icons/ExpandMoreIcon";
import OpenInNewIcon from "../../elements/Icons/OpenInNewIcon";

const JumbotronSettingsField = ({
  title,
  onClick,
  disabled,
  children,
  isContact
}) => {
  const clickable = !disabled && onClick !== null;
  return (
    <Container>
      <ClickContainer
        onClick={!disabled ? onClick : null}
        className={clickable ? "clickable" : ""}
      >
        <TitleContainer>
          <Title>{title}</Title>{" "}
          {clickable && !isContact && (
            <StyledExpandIcon start={270} end={270} size={24} />
          )}
          {clickable && isContact && <StyledOpenInNewIcon size={20} />}
        </TitleContainer>
        {!isContact && children}
      </ClickContainer>
      {isContact && children}
    </Container>
  );
};

JumbotronSettingsField.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: reactNodesType,
  isContact: PropTypes.bool
};

JumbotronSettingsField.defaultProps = {
  children: null,
  onClick: null,
  disabled: false,
  isContact: false
};

export default JumbotronSettingsField;

const Container = styled.div.attrs({ className: "JumbotronSettingsField" })`
  color: black;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
`;

const ClickContainer = styled.div`
  &.clickable {
    cursor: pointer;

    &:hover {
      .StyledIcon {
        color: var(--color-charcoal);
      }
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

const Title = styled.div`
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-dark);
  line-height: 26px;
  height: 24px;
  margin-right: 5px;
`;

const StyledExpandIcon = styled(ExpandMoreIcon).attrs({
  className: "StyledIcon"
})`
  color: var(--color-gray);
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon).attrs({
  className: "StyledIcon"
})`
  margin-left: 4px;
  color: var(--color-gray);
`;
