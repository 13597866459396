import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { saveScheduledBlockAPI } from "../../../lib/API/scheduleAPI";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DUPLICATE,
  SCHEDULE_BLOCK_LOG,
  SCHEDULE_BLOCK_REASSIGN,
  SCHEDULE_BLOCK_RESIZE_END,
  SCHEDULE_BLOCK_SAVE_ERROR,
  SCHEDULE_BLOCK_SAVED,
  SCHEDULE_BLOCK_SAVING,
  SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID,
  SCHEDULE_BLOCK_UNLOG,
} from "../../../lib/constants";
import {
  getRepeatingLoggedTimeId,
  getRepeatingLoggedTimeTitle,
  isRepeatingLoggedTime,
} from "../../../lib/entities/scheduleLoggedTimeEntity";
import { confirmUnlinkRepeatingLoggedTime } from "../../../lib/WebAppAPI/schedule";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectScheduleBlockDays,
  selectScheduleBlockUi,
} from "../../selectors/scheduleBlockSelectors";
import validateCanModifyBlock from "./validateCanModifyBlock";

function* saveBlockUpdate(action) {
  const {
    blockKey: key,
    hasChanged,
    duplicate,
    newBlockKey,
    selectedBlockKeys,
    isBulkAction = false,
  } = action.payload;

  if (isBulkAction) return;
  if (!duplicate && !hasChanged) return;

  const blockKey = duplicate ? newBlockKey : key;

  const baseScheduleLoggedTime = yield select(
    selectBaseScheduleLoggedTimeByBlockKey,
    { blockKey }
  );

  if (!baseScheduleLoggedTime) return;

  const shouldClearRepeatingLoggedTimeId =
    isRepeatingLoggedTime(baseScheduleLoggedTime) && hasChanged;

  const shouldConfirmClearRepeatingLoggedTimeId =
    action.type === SCHEDULE_BLOCK_DRAG_END &&
    !duplicate &&
    selectedBlockKeys.length < 2;

  try {
    yield validateCanModifyBlock(blockKey);

    if (shouldClearRepeatingLoggedTimeId) {
      yield put(
        createAction(SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID, {
          baseScheduleLoggedTime,
        })
      );

      if (shouldConfirmClearRepeatingLoggedTimeId) {
        yield call(
          confirmUnlinkRepeatingLoggedTime,
          getRepeatingLoggedTimeId(baseScheduleLoggedTime),
          getRepeatingLoggedTimeTitle(baseScheduleLoggedTime)
        );
      }
    }

    yield put(
      createAction(SCHEDULE_BLOCK_SAVING, { ...action.payload, blockKey })
    );

    const days = yield select(selectScheduleBlockDays, { blockKey });

    const { scheduleLoggedTimes, ...data } = yield call(
      saveScheduledBlockAPI,
      baseScheduleLoggedTime,
      days
    );

    const { isDragging, isResizing } = yield select(selectScheduleBlockUi, {
      blockKey,
    });

    if (isDragging || isResizing) return;

    yield put(
      createAction(SCHEDULE_BLOCK_SAVED, {
        ...action.payload,
        blockKey,
        scheduleLoggedTimes,
      })
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    if (shouldClearRepeatingLoggedTimeId) {
      yield put(
        createAction(SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID, {
          baseScheduleLoggedTime,
        })
      );
    }

    yield put(
      createAction(SCHEDULE_BLOCK_SAVE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error,
      })
    );

    sagaError(error);
  }
}

function* watchSaveBlock() {
  yield takeLatestBy(
    [
      SCHEDULE_BLOCK_DRAG_END,
      SCHEDULE_BLOCK_RESIZE_END,
      SCHEDULE_BLOCK_REASSIGN,
      SCHEDULE_BLOCK_LOG,
      SCHEDULE_BLOCK_UNLOG,
    ],
    saveBlockUpdate,
    (action) => action.payload.blockKey
  );
}

function* watchDuplicateBlock() {
  yield takeEvery([SCHEDULE_BLOCK_DUPLICATE], saveBlockUpdate);
}

export default function* watchUpdateBlock() {
  yield all([watchSaveBlock(), watchDuplicateBlock()]);
}
