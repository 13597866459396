import { AxiosError } from "axios";

import { AccountingPlatformConnectionException } from "..";
import { API } from "../api";
import { WebApiRoot } from "./webApiRoot";

export const AccountingPlatformRoot = WebApiRoot + "/accounting_platforms";

export async function details(id: number, name: string) {
  try {
    const response = await API.get<AccountingPlatformDetails>(
      `${AccountingPlatformRoot}/${id}/details`
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError && error.status === 403) {
      throw new AccountingPlatformConnectionException(
        `There was a problem connecting to ${name}`
      );
    }
    throw error;
  }
}

export interface Account {
  class: string;
  code: string;
  id: string;
  name: string;
  taxRef: string;
}

export interface TaxRate {
  canApplyToExpenses: boolean;
  canApplyToRevenue: boolean;
  id: string;
  name: string;
  rate: number;
}

export interface TrackingCategory {
  id: string;
  name: string;
  options: TrackingOption[];
}

export interface TrackingOption {
  id: string;
  name: string;
}

export interface AccountingPlatformDetails {
  expenseAccounts: Account[];
  paymentAccounts: Account[];
  revenueAccounts: Account[];
  taxRates: TaxRate[];
  trackingCategories: TrackingCategory[];
}
