import { EntityClass, ItemRate } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";

import { createStore } from "../../../core";
import { EntityStore } from "../../EntityStore";

export type TItemRateIndexStore = {
  masterJobItemIdRateCardIdIndex: ItemRate.calculate.TMasterJobItemIdRateCardIdIndex;
  masterLoggedExpenseIdRateCardIdIndex: ItemRate.calculate.TMasterLoggedExpenseIdRateCardIdIndex;
};

export const useItemRateIndexStore = createStore<TItemRateIndexStore>(() => ({
  masterJobItemIdRateCardIdIndex: {},
  masterLoggedExpenseIdRateCardIdIndex: {},
}));

EntityStore.store.subscribe(
  (store) => store[EntityClass.ItemRate].byId,
  (itemRates) => {
    const masterJobItemIdRateCardIdIndex =
      ItemRate.calculate.createMasterJobItemIdRateCardIdIndex(
        dictionaryToArray(itemRates)
      );

    const masterLoggedExpenseIdRateCardIdIndex =
      ItemRate.calculate.createMasterLoggedExpenseIdRateCardIdIndex(
        dictionaryToArray(itemRates)
      );

    useItemRateIndexStore.setState({
      masterJobItemIdRateCardIdIndex,
      masterLoggedExpenseIdRateCardIdIndex,
    });
  }
);
