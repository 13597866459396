// deprecated

import type { Option } from "./Option";

export const LoggedTimeStatuses = {
  Incomplete: 1,
  Complete: 2,
  Deleted: 3,
} as const;

export type LoggedTimeStatusesType =
  (typeof LoggedTimeStatuses)[keyof typeof LoggedTimeStatuses];

export type Type = LoggedTimeStatus;
export interface LoggedTimeStatus extends Option {
  id: LoggedTimeStatusesType;
}

export function isIncomplete(entity: LoggedTimeStatus) {
  return entity.id === LoggedTimeStatuses.Incomplete;
}

export function isComplete(entity: LoggedTimeStatus) {
  return entity.id === LoggedTimeStatuses.Complete;
}

export function isDeleted(entity: LoggedTimeStatus) {
  return entity.id === LoggedTimeStatuses.Deleted;
}
