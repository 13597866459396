import { costingMethodTooltipCss } from "./CostingMethodTooltip.css";

interface Props {
  hideTitle?: boolean;
  title: string;
  text: string;
}

export function CostingMethodTooltip({
  hideTitle = false,
  title,
  text,
}: Props) {
  return (
    <div className={costingMethodTooltipCss}>
      {!hideTitle && <strong>{title}</strong>} {text}
    </div>
  );
}
