import clsx from "clsx";

import type { TEntityId } from "../../entities/Entity";
import { useRoleEntity } from "../../stores";
import { EmptyUserIcon } from "..";
import {
  roleIconNameContainer,
  roleIconNamePrimary,
  roleIconNameSecondary,
  roleIconNameTextContainer,
} from "./RoleIconName.css";

type Props = {
  id: TEntityId;
  size?: number;
  fontSize?: number;
  color?: string;
  gap?: number;
  secondaryColor?: string;
  secondaryFontSize?: number;
  className?: string;
  type?: "role" | "unassigned" | "both";
  nameWidth?: string | number;
  isSelected?: boolean;
};

export function RoleIconName({
  id,
  size = 30,
  fontSize = 18,
  color = "black",
  gap = 10,
  type = "role",
  secondaryColor,
  secondaryFontSize,
  className,
  nameWidth,
  isSelected,
}: Props) {
  const role = useRoleEntity(id);

  if (!secondaryColor) {
    secondaryColor = color;
  }
  if (!secondaryFontSize) {
    secondaryFontSize = fontSize - 2;
  }
  return (
    <div
      className={clsx(roleIconNameContainer, className)}
      style={{ height: size, color }}
    >
      <EmptyUserIcon size={size} />
      <div
        className={roleIconNameTextContainer}
        style={{ marginLeft: gap, width: nameWidth }}
      >
        <span
          className={roleIconNamePrimary}
          style={{ fontSize }}
          data-selected={isSelected}
        >
          {type === "role" ? role.name : "Unassigned"}
        </span>
        {type === "both" && (
          <span
            className={roleIconNameSecondary}
            style={{ fontSize: secondaryFontSize, color: secondaryColor }}
          >
            {role.name}
          </span>
        )}
      </div>
    </div>
  );
}
