import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobGroupPeriodStore } from "./JobGroupPeriod.store";

export const useJobGroupPeriodEntity = createUseEntityStoreEntity(
  EntityClass.JobGroupPeriod
);

export const getJobGroupPeriodEntity = createGetEntityStoreEntity(
  EntityClass.JobGroupPeriod
);

export const useJobGroupPeriod = createUseSingleEntity(JobGroupPeriodStore);
