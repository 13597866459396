import {
  StatisticField,
  type TFilterGroupTypeEnum,
  type TStatisticFieldEnum,
  type TValueFormatEnum,
  type TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import { produce } from "immer";
import type {
  ReportingDataColumn,
  ReportingFormulaColumn,
  ReportingSavedSegment,
  ReportingSortIdentifier,
  TReportingEntityDescriptor,
  TReportingTimeChartType,
} from "st-shared/entities";
import {
  CReportingSavedSegment,
  ReportingColumnType,
} from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import type { TReportingSearchType } from "st-shared/lib/webapi/reporting";
import type { FilterGroup, FilterGroupFilter } from "st-shared/module";
import { getPermissionsSnapshot } from "st-shared/stores";
import type {
  TConditionMatchType,
  TCustomDateRange,
  TDateRange,
} from "st-shared/types";
import type { StateCreator } from "zustand";

import { fePageSetPageTitle } from "../../../../lib/WebAppAPI/fePages";
import { ReportingFormula } from "../../lib/ReportingFormula";
import { setReportingEncodedSearch } from "../userPreferences/reportingEncodedSearch";
import type { ReportingStoreState } from "./reportingStore";

export type ColumnMetadata = {
  id: string;
  show: boolean;
  permission: boolean;
  baseType: typeof ReportingColumnType.Data | typeof ReportingColumnType.Total;
  format: TValueFormatEnum;
  formula?: ReportingFormula;
  compileFailure?: "fail" | "circular" | "deleted";
  dependencies?: string[];
};

export type SavedSegmentSlice = {
  savedSegment: {
    entityId?: TEntityId;
    value: ReportingSavedSegment;
    name: string;
    fetching: boolean;
    columnMetadata: Record<string, ColumnMetadata>;
    helpers: {
      getReportingSavedSegment(): CReportingSavedSegment;
    };
    actions: {
      reset: () => void;
      saveUserPreference: () => void;
      unsetUserPreference: () => void;
      calculateColumnMetadata: () => void;
      setName: (name: string) => void;
      setDates: (dates: TCustomDateRange | TDateRange | null) => void;
      setSearchType: (searchType: TReportingSearchType) => void;
      setSelectedColumnId: (selectedColumnId: string) => void;
      setSelectedTimeId: (selectedTimeId: string) => void;
      setSelectedTimeIds: (ids: string[]) => void;
      setTimeChartType: (timeChartType: TReportingTimeChartType) => void;
      setSortColumn: (sort: ReportingSortIdentifier) => void;
      setSortTime: (sort: ReportingSortIdentifier) => void;
      setFilterGroupType: (
        filterGroupType: TFilterGroupTypeEnum | null
      ) => void;
      setSubFilterGroupType: (subFilterGroupType: TFilterGroupTypeEnum) => void;
      addDataset: (
        entityDescriptorId: TReportingEntityDescriptor,
        name: string,
        id?: string
      ) => void;
      duplicateDataset: (dataSetId: string) => void;
      deleteDataset: (dataSetId: string) => void;
      setDatasetName: (dataSetId: string, name: string) => void;
      setDatasetConditionMatch: (
        dataSetId: string,
        conditionMatchTypeId: TConditionMatchType
      ) => void;
      setDatasetDateFilterGroupType: (
        dataSetId: string,
        dateFilterGroupTypeId: TFilterGroupTypeEnum
      ) => void;
      addDataColumn: (
        dataColumn: Omit<ReportingDataColumn, "id" | "orderId">
      ) => void;
      updateDataColumn: (
        dataColumn: Omit<ReportingDataColumn, "orderId">
      ) => void;
      addFormulaColumn: (
        formulaColumn: Omit<ReportingFormulaColumn, "id" | "orderId" | "type">
      ) => void;
      updateFormulaColumn: (
        formulaColumn: Omit<ReportingFormulaColumn, "orderId" | "type">
      ) => void;
      setColumnOrderId: (id: string, orderId: number) => void;
      setColumnHidden: (id: string, hidden: boolean) => void;
      deleteColumn: (id: string) => void;
      addDatasetFilterGroup: (
        dataSetId: string,
        filterGroup: FilterGroup
      ) => void;
      deleteDatasetFilterGroup: (dataSetId: string, filterId: string) => void;
      setDatasetFilterGroupConditionMatch: (
        dataSetId: string,
        filterId: string,
        conditionMatchTypeId: TConditionMatchType
      ) => void;
      setDatasetFilterGroupValueMatch: (
        dataSetId: string,
        filterId: string,
        valueMatchTypeId: TValueMatchTypeEnum
      ) => void;
      addDatasetFilterGroupFilter: (
        dataSetId: string,
        filterId: string,
        filter: FilterGroupFilter
      ) => void;
      setDatasetFilterGroupFilter: (
        dataSetId: string,
        filterId: string,
        filterIndex: number,
        filter: FilterGroupFilter
      ) => void;
      deleteDatasetFilterGroupFilters: (
        dataSetId: string,
        filterId: string,
        indexes: number[]
      ) => void;
      addGlobalFilterGroup: (filterGroup: FilterGroup) => void;
      setGlobalFilterConditionMatch: (
        conditionMatchTypeId: TConditionMatchType
      ) => void;
      deleteGlobalFilterGroup: (filterId: string) => void;
      addGlobalFilterGroupFilter: (
        filterId: string,
        filter: FilterGroupFilter
      ) => void;
      setGlobalFilterGroupConditionMatch: (
        filterId: string,
        conditionMatchTypeId: TConditionMatchType
      ) => void;
      setGlobalFilterGroupValueMatch: (
        filterId: string,
        valueMatchTypeId: TValueMatchTypeEnum
      ) => void;
      setGlobalFilterGroupFilter: (
        filterId: string,
        filterIndex: number,
        filter: FilterGroupFilter
      ) => void;
      deleteGlobalFilterGroupFilters: (
        filterId: string,
        indexes: number[]
      ) => void;
    };
  };
};

export const createSavedSegmentSlice: StateCreator<
  ReportingStoreState,
  [],
  [],
  SavedSegmentSlice
> = (set, get) => ({
  savedSegment: {
    entityId: undefined,
    name: "",
    value: CReportingSavedSegment.createDefault().object,
    fetching: false,
    columnMetadata: {},
    helpers: {
      getReportingSavedSegment(): CReportingSavedSegment {
        return CReportingSavedSegment.fromObject(get().savedSegment.value);
      },
    },
    actions: {
      reset() {
        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.entityId = undefined;
            draft.savedSegment.name = "";
            draft.savedSegment.value =
              CReportingSavedSegment.createDefault().object;
            draft.savedSegment.fetching = false;
          })
        );
      },

      saveUserPreference() {
        setReportingEncodedSearch({
          entityId: get().savedSegment.entityId,
          value: get().savedSegment.value,
          name: get().savedSegment.name,
        });
      },

      unsetUserPreference() {
        setReportingEncodedSearch(undefined);
      },

      calculateColumnMetadata() {
        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.columnMetadata = {};

            const hasPermission = getDataColumnHasPermissionFunction();

            const columns = draft.savedSegment.value.columns;

            // data meta data

            CReportingSavedSegment.getDataColumns(columns).forEach((column) => {
              const permission = hasPermission(column.columnId);
              draft.savedSegment.columnMetadata[column.id] = {
                id: column.id,
                permission,
                show: permission,
                baseType: column.type,
                format: CReportingSavedSegment.getColumnFormat(column),
              };
            });

            // formulas

            const formulas: Record<string, ReportingFormula> = {};
            CReportingSavedSegment.getFormulaColumns(
              get().savedSegment.value.columns
            ).forEach((column) => {
              formulas[column.id] = ReportingFormula.fromString(column.formula);
              formulas[column.id].compile();
            });

            // formulas metadata

            CReportingSavedSegment.getFormulaColumns(columns).forEach(
              (column) => {
                let baseType: ColumnMetadata["baseType"] =
                  ReportingColumnType.Data;

                const dependencies = new Set<string>();

                let permission = true;
                let compileFailure: ColumnMetadata["compileFailure"] =
                  undefined;

                function findDependants(
                  formulaColumn: ReportingFormulaColumn,
                  tested: Set<string>
                ) {
                  if (tested.has(formulaColumn.id)) {
                    if (formulaColumn === column) {
                      compileFailure = "circular";
                    }
                    return;
                  }
                  tested.add(formulaColumn.id);

                  const formula = formulas[formulaColumn.id];

                  formula.getColumnIds().forEach((id) => {
                    const dependantColumn = columns[id];

                    if (dependantColumn) {
                      switch (dependantColumn.type) {
                        case ReportingColumnType.Formula:
                          findDependants(dependantColumn, tested);
                          break;
                        case ReportingColumnType.Data:
                          dependencies.add(dependantColumn.id);
                          break;
                        case ReportingColumnType.Total:
                          dependencies.add(dependantColumn.id);
                          baseType = ReportingColumnType.Total;
                          break;
                      }
                    } else {
                      compileFailure = "deleted";
                    }
                  });
                }

                findDependants(column, new Set<string>());

                dependencies.forEach((dependency) => {
                  const metadata =
                    draft.savedSegment.columnMetadata[dependency];
                  if (metadata?.permission === false) {
                    permission = false;
                  }
                });

                if (!compileFailure) {
                  if (!permission || !formulas[column.id].evaluate) {
                    compileFailure = "fail";
                  }
                }

                draft.savedSegment.columnMetadata[column.id] = {
                  id: column.id,
                  permission,
                  show: permission && !compileFailure,
                  baseType,
                  format: CReportingSavedSegment.getColumnFormat(column),
                  compileFailure,
                  dependencies: Array.from(dependencies),
                  formula: formulas[column.id],
                };
              }
            );
          })
        );
      },

      setName(name: string) {
        set((s) =>
          produce(s, (draft) => {
            draft.savedSegment.name = name;
          })
        );
        fePageSetPageTitle(name);
        get().savedSegment.actions.saveUserPreference();
      },

      setDates(dates: TCustomDateRange | TDateRange | null) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDates(dates);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setSearchType(searchType: TReportingSearchType) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSearchType(searchType);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setSelectedColumnId(selectedColumnId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSelectedColumnId(selectedColumnId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setSelectedTimeId(selectedTimeId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSelectedTimeId(selectedTimeId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setSelectedTimeIds(ids: string[]) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSelectedTimeIds(ids);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setTimeChartType(timeChartType: TReportingTimeChartType) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setTimeChartType(timeChartType);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setSortColumn(sort: ReportingSortIdentifier) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSortColumn(sort);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setSortTime(sort: ReportingSortIdentifier) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSortTime(sort);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setFilterGroupType(filterGroupType: TFilterGroupTypeEnum | null) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setFilterGroupType(filterGroupType);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setSubFilterGroupType(subFilterGroupType: TFilterGroupTypeEnum) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setSubFilterGroupType(subFilterGroupType);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.resetSubSeries();
      },

      addDataset(
        entityDescriptorId: TReportingEntityDescriptor,
        name: string,
        id?: string
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addDataset(entityDescriptorId, name, id);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      duplicateDataset(dataSetId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).duplicateDataset(dataSetId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      deleteDataset(dataSetId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).deleteDataset(dataSetId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setDatasetName(dataSetId: string, name: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetName(dataSetId, name);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setDatasetConditionMatch(
        dataSetId: string,
        conditionMatchTypeId: TConditionMatchType
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetConditionMatch(dataSetId, conditionMatchTypeId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setDatasetDateFilterGroupType(
        dataSetId: string,
        dateFilterGroupTypeId: TFilterGroupTypeEnum
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetDateFilterGroupType(dataSetId, dateFilterGroupTypeId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      addDataColumn(dataColumn: Omit<ReportingDataColumn, "id" | "orderId">) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addDataColumn(dataColumn);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      updateDataColumn(dataColumn: Omit<ReportingDataColumn, "orderId">) {
        set((s) =>
          produce(s, (draft) => {
            const column = draft.savedSegment.value.columns[
              dataColumn.id
            ] as ReportingDataColumn;
            column.dataSetId = dataColumn.dataSetId;
            column.modeId = dataColumn.modeId;
            column.columnId = dataColumn.columnId;
            column.name = dataColumn.name;
            column.type = dataColumn.type;
            column.displayRules = dataColumn.displayRules;
          })
        );

        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      addFormulaColumn(
        formulaColumn: Omit<ReportingFormulaColumn, "id" | "orderId" | "type">
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addFormulaColumn(formulaColumn);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      updateFormulaColumn(
        formulaColumn: Omit<ReportingFormulaColumn, "orderId" | "type">
      ) {
        set((s) =>
          produce(s, (draft) => {
            const column = draft.savedSegment.value.columns[
              formulaColumn.id
            ] as ReportingFormulaColumn;
            column.format = formulaColumn.format;
            column.formula = formulaColumn.formula;
            column.name = formulaColumn.name;
            column.displayRules = formulaColumn.displayRules;
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setColumnOrderId(id: string, orderId: number) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setColumnOrderId(id, orderId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      setColumnHidden(id: string, hidden: boolean) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setColumnHidden(id, hidden);
          })
        );
        get().savedSegment.actions.saveUserPreference();
      },

      deleteColumn(id: string) {
        const deleteColumnAndFetch = () => {
          set((s) =>
            produce(s, (draft) => {
              CReportingSavedSegment.fromObject(
                draft.savedSegment.value
              ).deleteColumn(id);
            })
          );
          get().savedSegment.actions.saveUserPreference();
          get().search.actions.fetchSeries();
        };

        const column = get().savedSegment.value.columns[id];

        if (
          column.type === ReportingColumnType.Data ||
          column.type === ReportingColumnType.Total
        ) {
          const dataSetId = column.dataSetId;

          const dataSetColumns = CReportingSavedSegment.getDataColumns(
            get().savedSegment.value.columns
          ).filter((column) => column.dataSetId === dataSetId);
          const isLastColumn = dataSetColumns.length === 1;

          const filterGroups =
            get().savedSegment.value.dataSets[dataSetId].filterGroups;

          if (isLastColumn) {
            if (filterGroups.length > 0) {
              getModalBridgeClient()?.openModal(
                ModalTypes.ReportingDeleteColumn,
                {},
                {
                  success: (_, { type }) => {
                    switch (type) {
                      case "dataset":
                        get().savedSegment.actions.deleteDataset(dataSetId);
                        break;
                      case "column":
                      default:
                        deleteColumnAndFetch();
                        break;
                    }
                  },
                }
              );
            } else {
              get().savedSegment.actions.deleteDataset(dataSetId);
            }
          } else {
            deleteColumnAndFetch();
          }
        } else {
          deleteColumnAndFetch();
        }
      },

      addDatasetFilterGroup(dataSetId: string, filterGroup: FilterGroup) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addDatasetFilterGroup(dataSetId, filterGroup);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      deleteDatasetFilterGroup(dataSetId: string, filterId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).deleteDatasetFilterGroup(dataSetId, filterId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setDatasetFilterGroupConditionMatch(
        dataSetId: string,
        filterId: string,
        conditionMatchTypeId: TConditionMatchType
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetFilterGroupConditionMatch(
              dataSetId,
              filterId,
              conditionMatchTypeId
            );
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setDatasetFilterGroupValueMatch(
        dataSetId: string,
        filterId: string,
        valueMatchTypeId: TValueMatchTypeEnum
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetFilterGroupValueMatch(
              dataSetId,
              filterId,
              valueMatchTypeId
            );
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      addDatasetFilterGroupFilter(
        dataSetId: string,
        filterId: string,
        filter: FilterGroupFilter
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addDatasetFilterGroupFilter(dataSetId, filterId, filter);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setDatasetFilterGroupFilter(
        dataSetId: string,
        filterId: string,
        filterIndex: number,
        filter: FilterGroupFilter
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setDatasetFilterGroupFilter(
              dataSetId,
              filterId,
              filterIndex,
              filter
            );
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      deleteDatasetFilterGroupFilters(
        dataSetId: string,
        filterId: string,
        indexes: number[]
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).deleteDatasetFilterGroupFilters(dataSetId, filterId, indexes);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      addGlobalFilterGroup(filterGroup: FilterGroup) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addGlobalFilterGroup(filterGroup);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setGlobalFilterConditionMatch(conditionMatchTypeId: TConditionMatchType) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setGlobalFilterConditionMatch(conditionMatchTypeId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      deleteGlobalFilterGroup(filterId: string) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).deleteGlobalFilterGroup(filterId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      addGlobalFilterGroupFilter(filterId: string, filter: FilterGroupFilter) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).addGlobalFilterGroupFilter(filterId, filter);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setGlobalFilterGroupConditionMatch(
        filterId: string,
        conditionMatchTypeId: TConditionMatchType
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setGlobalFilterGroupConditionMatch(
              filterId,
              conditionMatchTypeId
            );
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setGlobalFilterGroupValueMatch(
        filterId: string,
        valueMatchTypeId: TValueMatchTypeEnum
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setGlobalFilterGroupValueMatch(filterId, valueMatchTypeId);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      setGlobalFilterGroupFilter(
        filterId: string,
        filterIndex: number,
        filter: FilterGroupFilter
      ) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).setGlobalFilterGroupFilter(filterId, filterIndex, filter);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },

      deleteGlobalFilterGroupFilters(filterId: string, indexes: number[]) {
        set((s) =>
          produce(s, (draft) => {
            CReportingSavedSegment.fromObject(
              draft.savedSegment.value
            ).deleteGlobalFilterGroupFilters(filterId, indexes);
          })
        );
        get().savedSegment.actions.saveUserPreference();
        get().search.actions.fetchSeries();
      },
    },
  },
});

function getDataColumnHasPermissionFunction() {
  const { canAccessCostRates, canViewJobFinancials } = getPermissionsSnapshot();

  return function (columnId: TStatisticFieldEnum) {
    const hasCostRatePermission = !(
      !canAccessCostRates && StatisticField.containsCostRate(columnId)
    );
    const hasFincancialPermission = !(
      !canViewJobFinancials && StatisticField.containsFinancial(columnId)
    );
    return hasCostRatePermission && hasFincancialPermission;
  };
}
