import WebAppAPI from "..";

// send
const SEND_FE_PAGE_TITLE = "fe_page_title";
const SEND_FE_NAVIGATE_BACK = "fe_navigate_back";
const SEND_FE_NAVIGATE = "fe_navigate";

// calls

export function fePageSetPageTitle(name: string) {
  WebAppAPI.sendAction(SEND_FE_PAGE_TITLE, name);
}

export function fePageNavigateBack(fallbackRoute?: string) {
  WebAppAPI.sendAction(SEND_FE_NAVIGATE_BACK, fallbackRoute);
}

export function fePageNavigate(
  routePage: string,
  replace: boolean = false,
  id?: number | string,
  suffix?: string
) {
  WebAppAPI.sendAction(SEND_FE_NAVIGATE, routePage, replace, id, suffix);
}
