import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DATA_VALUE_HANDLE_RIGHT,
  DATA_VALUE_HANDLE_LEFT
} from "../../../lib/constants";
import {
  getDependancyLinkTypeSymbol,
  getLinkedJobItemId,
  jobItemDependancyEntityType
} from "../../../lib/entities/jobItemDependancyEntity";
import { getJobItemName } from "../../../lib/entities/jobItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectJobItemDependanciesSelected } from "../../../redux/selectors/jobItemDependancySelectors";
import UnlinkIcon from "../../elements/Icons/UnlinkIcon";
import JobItemConnector from "../Connectors/JobItemConnector";
import ContextMenu from "../Menu/ContextMenu";
import ListItemIcon from "../Menu/ListItemIcon";
import ListItemText from "../Menu/ListItemText";
import MenuItem from "../Menu/MenuItem";
import { ICON_SIZE } from "../StyledIcon";
import IsJobItemDependancyConflictConnector from "./IsJobItemDependancyConflictConnector";

const mapState = state => ({
  selectedJobItemDependancies: selectJobItemDependanciesSelected(state)
});

class JobItemDependanciesContextMenu extends React.PureComponent {
  static propTypes = {
    jobItemId: entityIdType,
    dependancyHandleType: PropTypes.oneOf([
      DATA_VALUE_HANDLE_RIGHT,
      DATA_VALUE_HANDLE_LEFT
    ]),
    anchorPosition: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number
    }),
    selectedJobItemDependancies: PropTypes.arrayOf(jobItemDependancyEntityType)
      .isRequired,
    onClose: PropTypes.func.isRequired,
    onRemoveDependancy: PropTypes.func.isRequired
  };

  static defaultProps = {
    jobItemId: null,
    dependancyHandleType: null,
    anchorPosition: null
  };

  get isOpen() {
    const { selectedJobItemDependancies, jobItemId } = this.props;
    return selectedJobItemDependancies.length > 0 && Boolean(jobItemId);
  }

  getLinkedJobItemId(jobItemDependancy) {
    const { jobItemId } = this.props;
    return getLinkedJobItemId(jobItemDependancy, jobItemId);
  }

  getDependancyLinkTypeSymbol(jobItemDependancy) {
    const { jobItemId } = this.props;
    return getDependancyLinkTypeSymbol(jobItemDependancy, jobItemId);
  }

  render() {
    const {
      anchorPosition,
      onRemoveDependancy,
      selectedJobItemDependancies,
      onClose
    } = this.props;

    return (
      <ContextMenu
        open={this.isOpen}
        anchorPosition={anchorPosition}
        onClose={onClose}
        marginThreshold={60}
      >
        {selectedJobItemDependancies.map(jobItemDependancy => (
          <div key={jobItemDependancy.id}>
            <IsJobItemDependancyConflictConnector id={jobItemDependancy.id}>
              {isConflict => (
                <DependancyMenuItem
                  className={isConflict ? "conflict" : ""}
                  onClick={() => onRemoveDependancy(jobItemDependancy)}
                >
                  <ListItemIcon>
                    <UnlinkIcon size={ICON_SIZE.X_LARGE} />
                  </ListItemIcon>
                  <ListItemText>
                    <JobItemConnector
                      id={this.getLinkedJobItemId(jobItemDependancy)}
                    >
                      {jobItem => `Unlink from ${getJobItemName(jobItem)}`}
                    </JobItemConnector>
                  </ListItemText>
                  <DependencyType>
                    {this.getDependancyLinkTypeSymbol(jobItemDependancy)}
                  </DependencyType>
                </DependancyMenuItem>
              )}
            </IsJobItemDependancyConflictConnector>
          </div>
        ))}
      </ContextMenu>
    );
  }
}

export default connect(mapState)(JobItemDependanciesContextMenu);

const DependencyType = styled.div`
  text-align: right;
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
  color: var(--color-gray);
`;

const DependancyMenuItem = styled(MenuItem)`
  justify-content: "space-between";
  &.conflict {
    ${ListItemText} {
      color: var(--color-red-dark);
    }
    ${DependencyType} {
      color: var(--color-red-bright);
    }
  }
`;
