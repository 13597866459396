import clsx from "clsx";
import { ComponentPropsWithoutRef, useState } from "react";
import { useFocusWithinElement } from "st-shared/hooks";
import { theme } from "st-shared/theme";

import {
  progressBarCss,
  progressBarOuterCss,
} from "./FocusProgressContainer.css";

interface Props extends ComponentPropsWithoutRef<"div"> {
  color?: string;
  progress?: number;
  doFocused?: boolean;
}

export function FocusProgressContainer({
  color = theme.color.blueactive,
  progress = 0,
  doFocused = true,
  className,
  children,
  ...props
}: Props) {
  const [focused, setFocused] = useState(false);
  const { focusWithinProps } = useFocusWithinElement({
    onBlur: () => setFocused(false),
    onFocus: () => setFocused(true),
  });

  const focusedValue = doFocused ? 1 : 0;
  const scale = focused ? focusedValue : Math.min(1, Math.max(0, progress));

  return (
    <div
      className={clsx(progressBarOuterCss, className)}
      {...props}
      {...focusWithinProps}
    >
      {children}
      <div
        className={progressBarCss}
        style={{ backgroundColor: color, transform: `scaleX(${scale})` }}
      />
    </div>
  );
}
