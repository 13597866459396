import { takeEvery, call, put } from "redux-saga/effects";
import { fetchLabelsAPI } from "../../../lib/API/labelsAPI";
import {
  ENTITIES_RECEIVED,
  LABELS_FETCH_REQUEST
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* fetch(action) {
  try {
    const { entityId, labelTypeId } = action.payload;
    const data = yield call(fetchLabelsAPI, entityId, labelTypeId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchFetchLabels() {
  yield takeEvery(LABELS_FETCH_REQUEST, fetch);
}
