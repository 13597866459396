import clsx from "clsx";

import { useScheduleSideMenuWidth } from "../UserPreference/sideBarUserPreferences";
import ScheduleDates from "./ScheduleDates";
import {
  scheduleGroupHeader,
  scheduleHeaderWrapper,
} from "./ScheduleHeader.css";
import ScheduleNavigation from "./ScheduleNavigation";

export function ScheduleHeader() {
  const width = useScheduleSideMenuWidth();

  return (
    <div className={scheduleHeaderWrapper}>
      <div className={clsx(scheduleGroupHeader)} style={{ width }}>
        <ScheduleNavigation />
      </div>
      <ScheduleDates />
    </div>
  );
}
