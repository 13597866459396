import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectQuoteIdsByJobId } from "./quoteIdsByJobId";

export const selectHasQuotes = createCachedSelector(
  [selectQuoteIdsByJobId],
  quoteIds => quoteIds.length > 0
)(selectPropsJobId);

export const useHasQuotes = jobId =>
  useSelector(state => selectHasQuotes(state, { jobId }));
