import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";
import { produce } from "immer";
import { put, select } from "redux-saga/effects";
import type { JobItemRole } from "st-shared/entities/JobItemRole";

import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { actionJobItemRoleRecalculateHours } from "../../jobItem/actions";
import type { JobItemRoleSetHoursAction } from "../actions";
import { actionJobItemRoleEdit, JOB_ITEM_ROLE_SET_HOURS } from "../actions";
import { selectJobItemRole } from "../selectors/selectJobItemRole";

function* setHours(action: JobItemRoleSetHoursAction) {
  try {
    const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
      jobItemRoleId: action.jobItemRoleId,
    });
    const newJobItemRole = produce(jobItemRole, (draft) => {
      draft.totalPlannedMinutes = convertFlexibleDurationToMinutes(
        action.value
      )!;
    });

    yield put(actionJobItemRoleEdit(jobItemRole.jobItemId, newJobItemRole));
    yield put(actionJobItemRoleRecalculateHours(jobItemRole.jobItemId));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemRoleSetHours() {
  yield takeLatestBy(
    [JOB_ITEM_ROLE_SET_HOURS],
    setHours,
    (action: JobItemRoleSetHoursAction) => action.jobItemRoleId
  );
}
