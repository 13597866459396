import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { UserIcon } from "st-shared/module";
import styled from "styled-components";

import {
  COLOR_CHARCOAL,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_USER_REASSIGN,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getAvailabilityDates } from "../../../lib/dates";
import { getEstimatedEndDate } from "../../../lib/entities/jobEntity";
import { getEstimatedStartDate } from "../../../lib/entities/jobItemEntity";
import { getName } from "../../../lib/entities/roleEntity";
import { getDisplayName } from "../../../lib/entities/userEntity";
import createAction from "../../../redux/helpers/createAction";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import { useUserByJobItemUserId } from "../../../redux/selectors/jobItemUser/selectUserByJobItemUserId";
import { useRoleByUserId } from "../../../redux/selectors/role/selectRoleByUserId";
import { IconButton, TextButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";
import { ICON_SIZE } from "../../modules/StyledIcon";
import Tooltip from "../../modules/Tooltip";
import ReassignUserMenu from "../../modules/UserRoleMenus/ReassignUserMenu";
import { useJobItemUserId } from "../context/JobItemUserIdContext";
import { useJobItemUserShowRole } from "../context/JobItemUserShowRoleContext";
import { FullWidthBaseItem } from "../styles";

const ItemUserInfo = () => {
  const jobItemUserId = useJobItemUserId();
  const jobItemUser = useJobItemUser(jobItemUserId);
  const user = useUserByJobItemUserId(jobItemUserId);
  const role = useRoleByUserId(user.id);
  const showUserRole = useJobItemUserShowRole();

  function getRoleClearTooltip() {
    if (jobItemUser.totalLoggedMinutes > 0) {
      return (
        <div>
          Unassign {getDisplayName(user)} from remaining planned role time
        </div>
      );
    } else {
      return <div>Unassign {getDisplayName(user)} from planned role time</div>;
    }
  }

  const ref = useRef(null);
  const [hover, setHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const jobItem = useJobItem(jobItemUser.jobItemId);
  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    getEstimatedStartDate(jobItem),
    getEstimatedEndDate(jobItem)
  );

  const dispatch = useDispatch();

  function reassignUser(userId) {
    dispatch(
      createAction(JOB_ITEM_USER_REASSIGN, {
        jobItemUserId,
        userId,
      })
    );
  }

  function unassignUser() {
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: jobItemUser.jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_USER_REASSIGN, {
        jobItemUserId,
        userId: null,
      })
    );
  }

  function openMenu() {
    setMenuOpen(true);
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: jobItemUser.jobItemId,
          jobItemUserId: jobItemUser.id,
          key: FOCUS_KEYS.ITEM_ROLE_ASSIGN,
        },
      })
    );
  }

  function closeMenu() {
    setMenuOpen(false);
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: jobItemUser.jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
  }

  function getClearIcon() {
    if (role && hover) {
      return (
        <Tooltip title={getRoleClearTooltip()} placement="top">
          <ClearIconWrapper>
            <IconButton onClick={unassignUser}>
              <ClearIcon size={ICON_SIZE.MEDIUM} />
            </IconButton>
          </ClearIconWrapper>
        </Tooltip>
      );
    }

    return <BlankIconWrapper />;
  }

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <IconWrapper>
        <UserIcon
          id={user.id}
          name={user.displayName}
          size={25}
          disableTooltip
        />
      </IconWrapper>
      <UserName ref={ref}>
        <StyledTextButton onClick={openMenu}>
          {getDisplayName(user)}
        </StyledTextButton>
      </UserName>
      {getClearIcon()}
      {role && showUserRole && (
        <UserRole>
          <span>{getName(role)}</span>
        </UserRole>
      )}
      {menuOpen && (
        <ReassignUserMenu
          anchorEl={ref.current}
          reassignUser={reassignUser}
          close={closeMenu}
          jobItemUserId={jobItemUserId}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      )}
    </Container>
  );
};

export default ItemUserInfo;

const Container = styled(FullWidthBaseItem)`
  flex-grow: 1;
  width: unset;
  min-width: 33%;
  background-color: transparent;
  font-weight: 400;
`;

const SmallContainer = styled.div`
  position: relative;
  background-color: white;
`;

const IconWrapper = styled(SmallContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const UserName = styled(SmallContainer)`
  padding-left: 5px;
  flex: 1 1 0;
`;

const UserRole = styled(SmallContainer)`
  margin-left: 2px;
  flex: 1 1 0;
  padding-left: 10px;
`;

const ClearIconWrapper = styled(SmallContainer)`
  display: flex;
  align-items: center;
`;

const BlankIconWrapper = styled(ClearIconWrapper)`
  width: 30px;
`;

const StyledTextButton = styled(TextButton)`
  color: "black";
  &:hover {
    color: ${COLOR_CHARCOAL};
  }
  .MuiButton-label {
    padding-top: 0px;
    justify-content: start;
  }
`;
