import { useFocusWithinElement } from "st-shared/hooks";

import { sendCommericialDocumentEditing } from "../../../../lib/WebAppAPI/commercialDocuments";

export function useCommercialDocumentEditing() {
  function onBlur() {
    sendCommericialDocumentEditing(false);
  }

  function onFocus() {
    sendCommericialDocumentEditing(true);
  }

  return useFocusWithinElement({
    onBlur,
    onFocus,
  });
}
