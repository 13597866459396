import type {
  TEntityDescriptorEnum,
  TFilterGroupTypeEnum,
  TValueMatchTypeEnum,
} from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
} from "@streamtimefe/entities";
import { MdFilterList } from "st-shared/components";
import type { ReportingDataSet } from "st-shared/entities";
import { ReportingFiltersDefaultDescriptionsOverride } from "st-shared/entities";
import type {
  FilterGroupFilter,
  TFilterListHeaderType,
} from "st-shared/module";
import { Filter, FilterContextProvider } from "st-shared/module";
import { OperatorButton } from "st-shared/module/Filters/OperatorButton";
import { theme } from "st-shared/theme";
import type { TConditionMatchType } from "st-shared/types";
import { getNextCondition } from "st-shared/types";

import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingDatasetFiltersOpen } from "../../../state/stores/uiSelectors";
import { dataSetColumnsCss } from "./DataSetColumns.css";
import { filterGroupContainer } from "./DataSetFilters.css";
import { FiltersAndColumnsExpander } from "./FiltersAndColumnsExpander";

interface Props {
  dataSet: ReportingDataSet;
  uuid: string;
  openAddFilterMenu: () => void;
}

export function DataSetFilters({ dataSet, uuid, openAddFilterMenu }: Props) {
  function onFilterDelete(filterId: string) {
    reportingStore().savedSegment.actions.deleteDatasetFilterGroup(
      dataSet.id,
      filterId
    );
  }

  function onSetDatasetConditionMatch() {
    reportingStore().savedSegment.actions.setDatasetConditionMatch(
      dataSet.id,
      getNextCondition(dataSet.conditionMatchTypeId)
    );
  }

  function onAddFilterGroupFilter(filterId: string, filter: FilterGroupFilter) {
    reportingStore().savedSegment.actions.addDatasetFilterGroupFilter(
      dataSet.id,
      filterId,
      filter
    );
  }

  function onSetFilterGroupFilter(
    filterId: string,
    filterIndex: number,
    filter: FilterGroupFilter
  ) {
    reportingStore().savedSegment.actions.setDatasetFilterGroupFilter(
      dataSet.id,
      filterId,
      filterIndex,
      filter
    );
  }

  function onSetFilterGroupConditionMatch(
    filterId: string,
    conditionMatchTypeId: TConditionMatchType
  ) {
    reportingStore().savedSegment.actions.setDatasetFilterGroupConditionMatch(
      dataSet.id,
      filterId,
      conditionMatchTypeId
    );
  }

  function onSetFilterGroupValueMatch(
    filterId: string,
    valueMatchTypeId: TValueMatchTypeEnum
  ) {
    reportingStore().savedSegment.actions.setDatasetFilterGroupValueMatch(
      dataSet.id,
      filterId,
      valueMatchTypeId
    );
  }

  function onDeleteFilterGroupFilters(filterId: string, indexes: number[]) {
    reportingStore().savedSegment.actions.deleteDatasetFilterGroupFilters(
      dataSet.id,
      filterId,
      indexes
    );
  }

  const datasetFiltersOpen = useReportingDatasetFiltersOpen(dataSet.id);

  function toggleOpen() {
    reportingStore().ui.actions.setDatasetFiltersOpen(
      dataSet.id,
      !datasetFiltersOpen
    );
  }

  function emptyTextOverride() {
    if (dataSet.filterGroups.length === 0) {
      switch (dataSet.entityDescriptorId) {
        case EntityDescriptorEnum.LoggedExpense:
          return "Expense is not Declined";
        case EntityDescriptorEnum.Invoice:
          return "Invoice is not Voided";
      }
    }
    return undefined;
  }

  return (
    <div className={dataSetColumnsCss}>
      <FiltersAndColumnsExpander
        text="filter"
        emptyTextOverride={emptyTextOverride()}
        open={datasetFiltersOpen}
        toggleOpen={toggleOpen}
        openAddMenu={openAddFilterMenu}
        length={dataSet.filterGroups.length}
        icon={MdFilterList}
      />
      {datasetFiltersOpen && dataSet.filterGroups.length > 0 && (
        <div className={filterGroupContainer}>
          {dataSet.filterGroups.map((filterGroup, index) => {
            return (
              <FilterContextProvider
                key={filterGroup.id}
                filterListHeaderType={getFilterListHeaderType(
                  filterGroup.filterGroupTypeId,
                  dataSet.entityDescriptorId
                )}
                displayBackgroundColor={theme.color.ygrittesnow}
              >
                {index !== 0 && (
                  <OperatorButton
                    onClick={onSetDatasetConditionMatch}
                    conditionMatchTypeId={dataSet.conditionMatchTypeId}
                  />
                )}
                <Filter
                  uuid={uuid}
                  filterGroup={filterGroup}
                  onDelete={onFilterDelete}
                  addFilter={onAddFilterGroupFilter}
                  setFilterGroupConditionMatch={onSetFilterGroupConditionMatch}
                  setFilterGroupValueMatch={onSetFilterGroupValueMatch}
                  setFilter={onSetFilterGroupFilter}
                  deleteFilters={onDeleteFilterGroupFilters}
                  defaultDescriptions={
                    ReportingFiltersDefaultDescriptionsOverride
                  }
                />
              </FilterContextProvider>
            );
          })}
        </div>
      )}
    </div>
  );
}

function getFilterListHeaderType(
  filterGroupTypeId: TFilterGroupTypeEnum,
  entityDescriptorId: TEntityDescriptorEnum
): TFilterListHeaderType {
  if (
    entityDescriptorId === EntityDescriptorEnum.Job &&
    (
      [
        FilterGroupTypeEnum.TeamMember,
        FilterGroupTypeEnum.RoleId,
        FilterGroupTypeEnum.UserBranchId,
      ] as TFilterGroupTypeEnum[]
    ).includes(filterGroupTypeId)
  ) {
    return "any/all";
  }
  return "is/not";
}
