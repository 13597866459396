import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const EditUnderlineIcon = props => (
  <SvgIcon {...props} viewBox="0 0 17 17">
    <path d="M0 12.92V16.15H3.23L12.58 6.71501L9.35 3.48501L0 12.92ZM15.045 4.25001C15.385 3.91001 15.385 3.40001 15.045 3.06001L13.09 1.10501C12.75 0.765006 12.24 0.765006 11.9 1.10501L10.37 2.63501L13.6 5.86501L15.045 4.25001ZM7.65 14.45L5.95 16.15H17V14.45H7.65Z" />
  </SvgIcon>
);

export default StyledIcon(EditUnderlineIcon);
