import { createIcon } from "../../icon/Icon";

export const IconStatusOpenGroupFilled = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 21V6H8V2H16V6H22V21H2ZM10 6H14V4H10V6ZM9.5 18L16.5 13.5L9.5 9V18Z"
  />,
  "IconStatusOpenGroupFilled"
);
