import {
  INVOICE_SAVING,
  INVOICE_SAVED,
  INVOICE_SAVE_FAILED,
  INVOICE_LINE_ITEM_MERGE_SAVING,
  INVOICE_LINE_ITEM_MERGE_SAVED,
  INVOICE_LINE_ITEM_MERGE_SAVE_ERROR,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVING,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVED,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR,
  INVOICE_LINE_ITEM_SORT_SAVING,
  INVOICE_LINE_ITEM_SORT_SAVED,
  INVOICE_LINE_ITEM_SORT_SAVE_ERROR,
  INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const onSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: true, error: null }
});

const onSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false }
});

const onSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isSaving: false, error }
});

const onMergeRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isMergeSaving: true, error: null }
});

const onMergeSuccess = (state, { payload: { id, snapshotId } }) => ({
  ...state,
  [id]: {
    ...state[id],
    isMergeSaving: false,
    canUndo: true,
    undoMergeSnapshotId: snapshotId
  }
});

const onMergeError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isMergeSaving: false, error }
});

const onUndoMergeRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: true, error: null, canUndo: false }
});

const onUndoMergeSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: false, undoMergeSnapshotId: null }
});

const onUndoMergeError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isUndoMergeSaving: false, error, canUndo: true }
});

const onSortSaveRequest = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: true, error: null }
});

const onSortSaveSuccess = (state, { payload: { id } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: false }
});

const onSortSaveError = (state, { payload: { id, error } }) => ({
  ...state,
  [id]: { ...state[id], isSortSaving: false, error }
});

const onSetPreviousIssuedInvoiceSummary = (
  state,
  { payload: { invoiceId, previousIssuedInvoiceSummary } }
) => ({
  ...state,
  [invoiceId]: { ...state[invoiceId], previousIssuedInvoiceSummary }
});

export default createReducer(
  {},
  {
    [INVOICE_SAVING]: onSaveRequest,
    [INVOICE_SAVED]: onSaveSuccess,
    [INVOICE_SAVE_FAILED]: onSaveError,
    [INVOICE_LINE_ITEM_MERGE_SAVING]: onMergeRequest,
    [INVOICE_LINE_ITEM_MERGE_SAVED]: onMergeSuccess,
    [INVOICE_LINE_ITEM_MERGE_SAVE_ERROR]: onMergeError,
    [INVOICE_LINE_ITEM_UNDO_MERGE_SAVING]: onUndoMergeRequest,
    [INVOICE_LINE_ITEM_UNDO_MERGE_SAVED]: onUndoMergeSuccess,
    [INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR]: onUndoMergeError,
    [INVOICE_LINE_ITEM_SORT_SAVING]: onSortSaveRequest,
    [INVOICE_LINE_ITEM_SORT_SAVED]: onSortSaveSuccess,
    [INVOICE_LINE_ITEM_SORT_SAVE_ERROR]: onSortSaveError,
    [INVOICE_SET_PREVIOUS_ISSUED_INVOICE_SUMMARY]: onSetPreviousIssuedInvoiceSummary
  }
);
