import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { formatForeignCurrency, NumberFormats } from "st-shared/lib";

import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectJobCurrency } from "../currency/jobCurrency";
import { selectPropsJobId } from "../index";
import { selectUnplannedTimeJobCurrencyTotalExTax } from "./ui/unplannedTimeJobCurrencyTotalExTax";
import { selectUnplannedTimeTotalMinutes } from "./ui/unplannedTimeTotalMinutes";

export const selectExtraHoursTrackedRate = createCachedSelector(
  [
    selectUnplannedTimeTotalMinutes,
    selectUnplannedTimeJobCurrencyTotalExTax,
    selectCustomerCurrency,
    selectJobCurrency,
  ],
  (totalMinutes, jobCurrencyTotalExTax, customerCurrency, jobCurrency) =>
    formatForeignCurrency(customerCurrency, {
      value: jobCurrencyTotalExTax / (totalMinutes / 60),
      currency: jobCurrency,
      format: NumberFormats.NoDecimal,
    })
)(selectPropsJobId);

export const useExtraHoursTrackedRate = (jobId) =>
  useSelector((state) => selectExtraHoursTrackedRate(state, { jobId }));
