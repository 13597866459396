import type { TRole } from "@streamtimefe/entities";
import { isEmpty, values } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../../entities";
import { roleObj } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { defaultSortOptions } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { storeActionAddListener } from "../../helpers";
import { addGlobalStore } from "../../helpers/global";
import type { ChangedEntity, EntityStoreState } from "..";
import { byIdReducer, parseEntityPayload, parseRemovedEntities } from "..";

interface RoleEntityStoreState extends EntityStoreState<TRole> {
  activeOptions: SearchOption<TRole>[];
}

export const useRoleEntityStore = create<RoleEntityStoreState>(
  function (set, get) {
    return {
      entities: {},
      activeOptions: [],
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.Role])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.Role]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.Role) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TRole>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);

        const activeOptions = values(entities)
          .filter((role) => role.active)
          .map((role) => roleObj(role).getAsOption())
          .sort(defaultSortOptions);

        set({
          entities,
          activeOptions,
        });
      },
    };
  }
);

export function roleEntityStoreInit() {
  addGlobalStore(["entities", EntityListKey.Role], roleEntityStore);
  storeActionAddListener("entitiesReceived", roleEntityStore().receiveEntities);
  storeActionAddListener("entitiesRemoved", roleEntityStore().removeEntities);
}

export function roleEntityStore() {
  return useRoleEntityStore.getState();
}

export function useRoleEntity(entityId: TEntityId) {
  return useRoleEntityStore((s) => s.entities[entityId]);
}

export function useActiveRoleOptions() {
  return useRoleEntityStore((s) => s.activeOptions);
}
