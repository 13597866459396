import type {
  TSavedSegmentTemplate,
  TSavedSegmentTypeEnum,
} from "@streamtimefe/entities";
import type { Dictionary } from "@streamtimefe/types";
import { isEmpty } from "lodash-es";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import type { TEntityId } from "../../../entities/Entity";
import {
  asyncTakeLatest,
  defaultSort,
  isCanceledError,
  WebAPI,
} from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import { addGlobalStore, storeActionAddListener } from "../..";
import { sagaError } from "../../sagaHelpers";
import {
  byIdReducer,
  parseEntityPayload,
  parseRemovedEntities,
} from "../helpers";
import type {
  BaseEntityStoreState,
  IndexesEntityStoreState,
} from "../storeTypes";
import type { ChangedEntity } from "../types";

type IndexEntityIdsByType = Dictionary<TSavedSegmentTypeEnum, TEntityId[]>;

type SavedSegmentEntityStoreState =
  BaseEntityStoreState<TSavedSegmentTemplate> &
    IndexesEntityStoreState<TSavedSegmentTemplate> & {
      indexes: {
        entityIdsByType: IndexEntityIdsByType;
      };
      helpers: {
        getEntityIdsByType: (
          savedSegmentType: TSavedSegmentTypeEnum
        ) => TEntityId[];
        getEntitiesByType: (
          savedSegmentType: TSavedSegmentTypeEnum
        ) => TSavedSegmentTemplate[];
      };
      actions: {
        load: (savedSegmentTypes?: TSavedSegmentTypeEnum[]) => Promise<void>;
      };
    };

export const useSavedSegmentTemplateEntityStore =
  create<SavedSegmentEntityStoreState>()(
    immer(function (set, get) {
      return {
        entitiesById: {},
        indexes: {
          entityIdsByType: {},
        },

        helpers: {
          getEntityIdsByType(
            savedSegmentType: TSavedSegmentTypeEnum
          ): TEntityId[] {
            return get().indexes.entityIdsByType[savedSegmentType] || [];
          },

          getEntitiesByType(savedSegmentType: TSavedSegmentTypeEnum) {
            const entityIds =
              get().helpers.getEntityIdsByType(savedSegmentType);

            return entityIds.map(
              (id) => get().entitiesById[id]
            ) as TSavedSegmentTemplate[];
          },
        },

        listeners: {
          receiveEntities({ entityData }: { entityData: TEntityList }) {
            if (isEmpty(entityData[EntityListKey.SavedSegmentTemplate])) return;

            const changedEntities = parseEntityPayload(
              get().entitiesById,
              entityData[EntityListKey.SavedSegmentTemplate]
            );

            get().actions.reduceChangedEntities(changedEntities);
          },

          removeEntities({
            entityName,
            ids,
          }: {
            entityName: TEntityListKey;
            ids: TEntityId[];
          }) {
            if (
              isEmpty(ids) ||
              entityName !== EntityListKey.SavedSegmentTemplate
            )
              return;

            const changedEntities = parseRemovedEntities(
              get().entitiesById,
              ids
            );

            get().actions.reduceChangedEntities(changedEntities);
          },
        },

        actions: {
          reduceChangedEntities(
            changedEntities: ChangedEntity<TSavedSegmentTemplate>[]
          ) {
            if (isEmpty(changedEntities)) return;

            const entitiesById = byIdReducer(
              get().entitiesById,
              changedEntities
            );

            set((s) => {
              s.entitiesById = entitiesById;
            });

            get().actions.updateIndexes(changedEntities);
          },

          purge() {
            set((s) => {
              s.entitiesById = {};
              s.indexes.entityIdsByType = {};
            });
          },

          updateIndexes() {
            const entities = Object.values(
              get().entitiesById
            ) as TSavedSegmentTemplate[];

            const orderedEntitites = entities.sort((a, b) =>
              defaultSort(a.name, b.name)
            );

            const entitiesByType: IndexEntityIdsByType = {};

            orderedEntitites.forEach((entity) => {
              const type = entity.savedSegmentType.id;
              if (type in entitiesByType) {
                entitiesByType[type]?.push(entity.id);
              } else {
                entitiesByType[type] = [entity.id];
              }
            });

            set({
              indexes: {
                entityIdsByType: entitiesByType,
              },
            });
          },

          load: asyncTakeLatest(async function (
            signal: AbortSignal,
            savedSegmentTypes?: TSavedSegmentTypeEnum[]
          ) {
            try {
              const response = await WebAPI.SavedSegmentTemplates.getEntitites(
                savedSegmentTypes,
                { signal }
              );

              get().listeners.receiveEntities({
                entityData: { savedSegmentTemplates: response.data },
              });
            } catch (error: unknown) {
              if (isCanceledError(error)) {
                return undefined as any;
              }
              sagaError(error);
            }
          }),
        },
      };
    })
  );

export function savedSegmentTemplateEntityStore() {
  return useSavedSegmentTemplateEntityStore.getState();
}

savedSegmentTemplateEntityStore.init = function () {
  addGlobalStore(
    ["entities", EntityListKey.SavedSegmentTemplate],
    savedSegmentTemplateEntityStore
  );
  storeActionAddListener(
    "entitiesReceived",
    savedSegmentTemplateEntityStore().listeners.receiveEntities
  );
  storeActionAddListener(
    "entitiesRemoved",
    savedSegmentTemplateEntityStore().listeners.removeEntities
  );
};
