import { ReactNode, useContext } from "react";

import { getStartOfWeekDatesInRange } from "../../../lib/dates";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

interface WeeksConsumerProps {
  children: (props: {
    date: string;
    left: number;
    dayWidth: number;
  }) => ReactNode;
}

function WeeksConsumer({ children }: WeeksConsumerProps) {
  const { viewportStartDate, viewportEndDate, getOffsetXAtDate, dayWidth } =
    useContext(ScheduleScrollContext);

  return (
    <>
      {getStartOfWeekDatesInRange(viewportStartDate, viewportEndDate).map(
        (date: string) =>
          children({ date, left: getOffsetXAtDate(date), dayWidth })
      )}
    </>
  );
}

export default WeeksConsumer;
