import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { selectCustomerCurrency } from "../../../state/entities/organisation/selectors/selectCustomerCurrency";
import { selectPropsJobId } from "../index";
import { selectJobCurrency } from "./jobCurrency";

export const selectIsForeignCurrency = createCachedSelector(
  [selectCustomerCurrency, selectJobCurrency],
  (customerCurrency, jobCurrency) => customerCurrency.id !== jobCurrency.id
)(selectPropsJobId);

export const useIsForeignCurrency = (jobId) =>
  useSelector((state) => selectIsForeignCurrency(state, { jobId }));
