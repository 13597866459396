import { filter, first, get } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS
} from "../../lib/constants";
import { selectPurchaseOrderByPurchaseOrderId } from "./purchaseOrderSelectors";
import { selectPropsId, selectPropsPurchaseOrderId } from "./index";

export const selectPurchaseOrderLineItems = state =>
  state.entities[ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS];

export const selectPurchaseOrderLineItemById = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsId,
  (purchaseOrderLineItems, id) => get(purchaseOrderLineItems, `byId.${id}`)
);

export const selectPurchaseOrderLineItemPurchaseOrderIdById = createSelector(
  selectPurchaseOrderLineItemById,
  purchaseOrderLineItem => get(purchaseOrderLineItem, `purchaseOrderId`)
);

export const selectPurchaseOrderLineItemIdsByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, quoteId) =>
    get(purchaseOrderLineItems, `idsByPurchaseOrderId.${quoteId}`, EMPTY_ARRAY)
);

export const selectPurchaseOrderLineItemsByPurchaseOrderId = (state, props) => {
  const purchaseOrderLineItemIds = selectPurchaseOrderLineItemIdsByPurchaseOrderId(
    state,
    props
  );
  return purchaseOrderLineItemIds.map(id =>
    selectPurchaseOrderLineItemById(state, { id })
  );
};

export const selectSortedPurchaseOrderLineItemIdsByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, purchaseOrderId) =>
    get(
      purchaseOrderLineItems,
      `sortedIdsByPurchaseOrderId.${purchaseOrderId}`,
      EMPTY_ARRAY
    )
);

export const selectSortedPurchaseOrderLineItemsByPurchaseOrderId = (
  state,
  props
) => {
  const ids = selectSortedPurchaseOrderLineItemIdsByPurchaseOrderId(
    state,
    props
  );

  return ids.map(id => selectPurchaseOrderLineItemById(state, { id }));
};

export const selectLineItemElementsByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, purchaseOrderId) =>
    get(
      purchaseOrderLineItems,
      `lineItemElementsByPurchaseOrderId.${purchaseOrderId}`,
      EMPTY_ARRAY
    )
);

export const selectPurchaseOrderLineItemElementsById = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsId,
  (purchaseOrderLineItems, id) =>
    get(
      purchaseOrderLineItems,
      `lineItemElementsByPurchaseOrderId.${id}`,
      EMPTY_ARRAY
    )
);

export const selectSubTotalsExTaxByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, purchaseOrderId) =>
    get(
      purchaseOrderLineItems,
      `subTotalsExTaxByPurchaseOrderId.${purchaseOrderId}`,
      EMPTY_OBJECT
    )
);

export const selectSubTotalsIncTaxByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, purchaseOrderId) =>
    get(
      purchaseOrderLineItems,
      `subTotalsIncTaxByPurchaseOrderId.${purchaseOrderId}`,
      EMPTY_OBJECT
    )
);

export const selectSubTotalsByPurchaseOrderId = createSelector(
  selectSubTotalsExTaxByPurchaseOrderId,
  selectSubTotalsIncTaxByPurchaseOrderId,
  selectPurchaseOrderByPurchaseOrderId,
  (subTotalsExTax, subTotalsIncTax, purchaseOrder) =>
    purchaseOrder.displayLineItemsInclusiveOfTax
      ? subTotalsIncTax
      : subTotalsExTax
);

export const selectSubTotalByPurchaseOrderIdPurchaseOrderLineItemId = createSelector(
  selectSubTotalsByPurchaseOrderId,
  selectPropsId,
  (subTotals, id) => get(subTotals, id)
);

export const selectTotalsByPurchaseOrderId = createSelector(
  selectPurchaseOrderLineItems,
  selectPropsPurchaseOrderId,
  (purchaseOrderLineItems, purchaseOrderId) =>
    get(
      purchaseOrderLineItems,
      `totalsByPurchaseOrderId.${purchaseOrderId}`,
      EMPTY_OBJECT
    )
);

export const selectPurchaseOrderLineItemsUi = state =>
  state.ui[ENTITY_NAME_PURCHASE_ORDER_LINE_ITEMS];

export const selectPurchaseOrderLineItemUi = createSelector(
  selectPurchaseOrderLineItemsUi,
  selectPropsId,
  (purchaseOrderLineItemsUi, id) =>
    get(purchaseOrderLineItemsUi, id, EMPTY_OBJECT)
);

export const selectPurchaseOrderLineItemUiIsCreating = createSelector(
  selectPurchaseOrderLineItemUi,
  purchaseOrderLineItemUi => get(purchaseOrderLineItemUi, "isCreating", false)
);

export const selectPurchaseOrderLineItemUiIsMerging = createSelector(
  selectPurchaseOrderLineItemUi,
  purchaseOrderLineItemUi => get(purchaseOrderLineItemUi, "isMerging", false)
);

export const selectSortedPurchaseOrderLineItemIdsMergingByPurchaseOrderId = (
  state,
  props
) => {
  const purchaseOrderLineItemIds = selectSortedPurchaseOrderLineItemIdsByPurchaseOrderId(
    state,
    props
  );

  return filter(purchaseOrderLineItemIds, id =>
    get(selectPurchaseOrderLineItemUi(state, { id }), "isMerging", false)
  );
};

export const selectCountIsMergingPurchaseOrderLineItemsByPurchaseOrderId = createSelector(
  selectSortedPurchaseOrderLineItemIdsMergingByPurchaseOrderId,
  value => value.length
);

export const selectFirstIsMergingPurchaseOrderLineItemIdByPurchaseOrderId = createSelector(
  selectSortedPurchaseOrderLineItemIdsMergingByPurchaseOrderId,
  first
);

export const selectMergeParentPurchaseOrderLineItem = (state, props) => {
  const { purchaseOrderId } = selectPurchaseOrderLineItemById(state, props);
  const purchaseOrderLineItemId = selectFirstIsMergingPurchaseOrderLineItemIdByPurchaseOrderId(
    state,
    {
      purchaseOrderId
    }
  );

  return (
    purchaseOrderLineItemId &&
    selectPurchaseOrderLineItemById(state, { id: purchaseOrderLineItemId })
  );
};

export const selectPurchaseOrderLineItemIsMergeParent = (state, props) => {
  const { id, purchaseOrderId } = selectPurchaseOrderLineItemById(state, props);
  const firstIsMergingId = selectFirstIsMergingPurchaseOrderLineItemIdByPurchaseOrderId(
    state,
    {
      purchaseOrderId
    }
  );

  return Boolean(firstIsMergingId && firstIsMergingId === id);
};

export const selectSortedPurchaseOrderLineItemsMergingByPurchaseOrderId = (
  state,
  props
) => {
  const purchaseOrderLineItemIds = selectSortedPurchaseOrderLineItemIdsMergingByPurchaseOrderId(
    state,
    props
  );

  return purchaseOrderLineItemIds.map(id =>
    selectPurchaseOrderLineItemById(state, { id })
  );
};
