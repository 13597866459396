import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectPropsJobId } from "../../index";
import { selectStateJobDetailsUi } from "./index";

export const selectIsPendingJobDetails = createSelector(
  [selectStateJobDetailsUi, selectPropsJobId],
  (jobDetails, jobId) => jobDetails.pending || jobDetails.jobId !== jobId
);

export const useIsPendingJobDetails = jobId =>
  useSelector(state => selectIsPendingJobDetails(state, { jobId }));
