import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { JOB_ITEM_CREATE, JOB_PHASE_CREATE } from "../../../lib/constants";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobId } from "../context/JobIdContext";
import { FullWidthBaseItemButton } from "../styles";

const AddNewLargeButton = () => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);

  const dispatch = useDispatch();

  const handleAddPhase = () => {
    if (!isJobEditable) return;

    dispatch(createAction(JOB_PHASE_CREATE, { jobId }));
  };

  const handleAddItem = () => {
    if (!isJobEditable) return;

    dispatch(createAction(JOB_ITEM_CREATE, { jobId }));
  };

  return (
    <Container>
      <Button onClick={handleAddItem}>New item</Button>
      <Button onClick={handleAddPhase}>New phase</Button>
    </Container>
  );
};

export default AddNewLargeButton;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > *:not(:first-child) {
    margin-left: 2px;
  }
  margin-top: 40px;
`;

const Button = styled(FullWidthBaseItemButton)`
  justify-content: center;
  cursor: pointer;
`;
