import * as PropTypes from "prop-types";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";
import { JobStatuses } from "st-shared/entities/JobStatus";

import { getHeightFromMinutes } from "../../state/ui/schedule/lib";
import {
  LOGGED_TIME_STATUS_ID_COMPLETE,
  LOGGED_TIME_STATUS_ID_DELETED,
  LOGGED_TIME_STATUS_ID_INCOMPLETE,
} from "../constants";
import { entityIdType } from "../types/entityTypes";
import { getPageUrl } from "./jobEntity";

export const scheduleLoggedTimeShape = {
  id: entityIdType.isRequired,
  billed: PropTypes.bool.isRequired,
  companyName: PropTypes.string,
  date: PropTypes.string.isRequired,
  jobFullName: PropTypes.string,
  jobId: entityIdType,
  jobItemId: entityIdType,
  jobItemName: PropTypes.string,
  jobItemEstimatedStartDate: PropTypes.string,
  jobItemEstimatedEndDate: PropTypes.string,
  jobItemUserId: entityIdType,
  jobItemUserStatusId: entityIdType,
  jobPhaseId: entityIdType,
  jobPhaseName: PropTypes.string,
  jobStatusId: entityIdType,
  loggedTimeStatusId: entityIdType,
  minutes: PropTypes.number.isRequired,
  notes: PropTypes.string,
  private: PropTypes.bool.isRequired,
  repeatingLoggedTimeId: entityIdType,
  repeatingLoggedTimeTitle: PropTypes.string,
  scheduleNotes: PropTypes.string,
  scheduleBlockBaseLoggedTimeId: entityIdType,
  userId: entityIdType.isRequired,
  _prevBaseLoggedTimeId: entityIdType,
};

export const scheduleLoggedTimeType = PropTypes.shape(scheduleLoggedTimeShape);

export const SCHEDULE_LOGGED_TIME_ENTITY_DEFAULTS = {
  id: null,
  billed: false,
  companyName: "",
  date: "",
  jobFullName: "",
  jobId: null,
  jobItemId: null,
  jobItemName: "",
  jobItemEstimatedStartDate: null,
  jobItemEstimatedEndDate: null,
  jobItemUserId: null,
  jobItemUserStatusId: null,
  jobPhaseId: null,
  jobPhaseName: "",
  jobStatusId: null,
  loggedTimeStatusId: LOGGED_TIME_STATUS_ID_INCOMPLETE,
  minutes: 60,
  notes: "",
  private: false,
  repeatingLoggedTimeId: null,
  repeatingLoggedTimeTitle: "",
  scheduleNotes: "",
  scheduleBlockBaseLoggedTimeId: null,
  userId: null,
};

export const getScheduleBlockKey = (entity) =>
  entity.scheduleBlockBaseLoggedTimeId || entity.id;

export const isBaseLoggedTime = (entity) =>
  entity &&
  (!entity.scheduleBlockBaseLoggedTimeId ||
    entity.scheduleBlockBaseLoggedTimeId === entity.id);

export const getMinutes = ({ minutes }) => minutes;
export const getJobItemName = ({ jobItemName }) => jobItemName;
export const getJobPhaseName = ({ jobPhaseName }) => jobPhaseName;
export const getJobFullName = ({ jobFullName }) => jobFullName;
export const getJobCompanyName = ({ companyName }) => companyName;
export const getJobItemEstimatedStartDate = ({ jobItemEstimatedStartDate }) =>
  jobItemEstimatedStartDate;
export const getJobItemEstimatedEndDate = ({ jobItemEstimatedEndDate }) =>
  jobItemEstimatedEndDate;

export const getNotes = ({ scheduleNotes, notes }) => scheduleNotes || notes;

export const isDeleted = ({ loggedTimeStatusId }) =>
  loggedTimeStatusId === LOGGED_TIME_STATUS_ID_DELETED;

export const isIncomplete = ({ loggedTimeStatusId }) =>
  loggedTimeStatusId === LOGGED_TIME_STATUS_ID_INCOMPLETE;

export const isComplete = ({ loggedTimeStatusId }) =>
  loggedTimeStatusId === LOGGED_TIME_STATUS_ID_COMPLETE;

export const isPersonal = ({ jobId }) => !jobId;

export const hasJobItemUser = ({ jobItemUserId }) => Boolean(jobItemUserId);

export const hasJob = ({ jobId }) => Boolean(jobId);

export const isJobItemUserComplete = ({ jobItemUserStatusId }) =>
  jobItemUserStatusId === JobItemUserStatuses.Complete;

export const isJobItemUserScheduled = ({ jobItemUserStatusId }) =>
  jobItemUserStatusId === JobItemUserStatuses.Scheduled;

export const isJobItemUserPlanning = ({ jobItemUserStatusId }) =>
  jobItemUserStatusId === JobItemUserStatuses.Planning;

export const isJobItemUserDeleted = ({ jobItemUserStatusId }) =>
  jobItemUserStatusId === JobItemUserStatuses.Deleted;

export const isJobPlanning = ({ jobStatusId }) =>
  jobStatusId === JobStatuses.Planning;

export const isActive = (entity) => {
  if (isDeleted(entity)) return false;
  return !(hasJobItemUser(entity) && isJobItemUserDeleted(entity));
};

export const notActive = (entity) => !isActive(entity);

export const isPaused = (entity) =>
  isIncomplete(entity) &&
  (hasJobItemUser(entity)
    ? isJobItemUserPlanning(entity)
    : hasJob(entity) && isJobPlanning(entity));

export const isPrivate = (scheduleLoggedTime, loggedInUserId) =>
  isPersonal(scheduleLoggedTime) &&
  scheduleLoggedTime.private &&
  loggedInUserId !== scheduleLoggedTime.userId;

export const isBilled = ({ billed }) => billed;

export const getUserId = ({ userId }) => userId;

export const getJobId = ({ jobId }) => jobId;

export const getJobItemId = ({ jobItemId }) => jobItemId;

export const getJobItemUserId = ({ jobItemUserId }) => jobItemUserId;

export const getUserIdKeyPath = getUserId;

export const getJobIdKeyPath = getJobId;

export const getUserIdDateKeyPath = ({ userId, date }) =>
  `["${userId}.${date}"]`;

export const getUserIdJobIdKeyPath = ({ userId, jobId }) =>
  `["${userId}.${jobId || 0}"]`;

export const getJobItemUserIdKeyPath = ({ jobItemUserId }) =>
  jobItemUserId || 0;

export const getDate = ({ date }) => date;

export const getBlockHeight = (scheduleLoggedTime) =>
  getHeightFromMinutes(getMinutes(scheduleLoggedTime));

export const getJobPageUrl = ({ jobId }) => (jobId ? getPageUrl(jobId) : "");

export const getRepeatingLoggedTimeId = ({ repeatingLoggedTimeId }) =>
  repeatingLoggedTimeId;

export const getRepeatingLoggedTimeTitle = ({ repeatingLoggedTimeTitle }) =>
  repeatingLoggedTimeTitle;

export const isRepeatingLoggedTime = ({ repeatingLoggedTimeId }) =>
  Boolean(repeatingLoggedTimeId);

export const getJobIdIfBaseLoggedTime = (entity) =>
  isBaseLoggedTime(entity) && getJobId(entity);

export const getJobItemUserIdIfBaseLoggedTime = (entity) =>
  isBaseLoggedTime(entity) && getJobItemUserId(entity);

export const getJobIdIfNoItemBaseLoggedTime = (entity) =>
  entity && !getJobItemId(entity) && getJobIdIfBaseLoggedTime(entity);
