import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Flex from "../../elements/Flex";
import AddIcon from "../../elements/Icons/AddIcon";

const SearchMenuCreateFooter = ({ text, ...props }) => (
  <Create {...props}>
    <AddIcon />
    {text}
  </Create>
);

SearchMenuCreateFooter.propTypes = {
  text: PropTypes.string
};

SearchMenuCreateFooter.defaultProps = {
  text: "Create New"
};

export default SearchMenuCreateFooter;

const Create = styled(Flex)`
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  svg {
    margin-right: 5px;
    margin-top: -3px;
  }
  &:hover {
    background-color: var(--color-gray-light);
  }
`;
