import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import Menu from "./index";

class ContextMenu extends React.PureComponent {
  static propTypes = {
    children: reactNodesType.isRequired,
    open: PropTypes.bool.isRequired,
    anchorPosition: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number
    }),
    onClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    anchorPosition: null
  };

  render() {
    const { children, ...props } = this.props;
    return (
      <StyledMenu
        className="ContextMenu"
        anchorReference="anchorPosition"
        {...props}
      >
        {children}
      </StyledMenu>
    );
  }
}

export default ContextMenu;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 200px;
  }
`;
