import { connect } from "react-redux";
import {
  selectInvoiceLineItemById,
  selectSubTotalByInvoiceIdInvoiceLineItemId
} from "../../../redux/selectors/invoiceLineItemSelectors";
import DocumentHeadingSubtotal from "../../modules/CommercialDocuments/DocumentLineItems/DocumentHeadingSubtotal";

const mapState = (state, props) => ({
  documentLineItem: selectInvoiceLineItemById(state, props),
  subTotal: selectSubTotalByInvoiceIdInvoiceLineItemId(state, props)
});

export default connect(mapState)(DocumentHeadingSubtotal);
