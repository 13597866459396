import { ReactNode } from "react";

import { Permissions, usePermissions } from "./permissions";

interface Props {
  children: (obj: Permissions) => ReactNode;
}

// TODO: this should not be used in normal classes, onces changed get rid of this
export function PermissionsConsumer({ children }: Props) {
  const permissions = usePermissions();
  return children(permissions);
}
