import clsx from "clsx";
import { values } from "lodash-es";
import moment from "moment";
import { useMemo, useRef, useState } from "react";

import type { SelectButtonProps, SelectKey } from "../../components";
import {
  AriaButton,
  DayRangePicker,
  Icon,
  IconSize,
  MdToday,
  Select,
  SelectItem,
} from "../../components";
import { DateFormats, getTodayDate } from "../../lib";
import { theme } from "../../theme";
import type { TCustomDateRange, TDateRange } from "../../types";
import {
  CustomDateRange,
  getCustomDateRangeText,
  getDatesFromRange,
} from "../../types";
import {
  dateRangeSelectorButton,
  dateRangeSelectorButtonIcon,
} from "./DateRangeSelector.css";

interface Props {
  range: TCustomDateRange | TDateRange | null;
  setRange: (range: TCustomDateRange | TDateRange | null) => void;
  buttonClassName?: string;
  includeAllTime?: boolean;
  restrictToYear?: boolean;
}

export function DateRangeSelector({
  range,
  setRange,
  buttonClassName,
  includeAllTime = false,
  restrictToYear = false,
}: Props) {
  const triggerRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const currentValue = useMemo(() => {
    if (range === null) {
      return "all";
    }
    if (typeof range === "string") {
      return range;
    }
    return "custom";
  }, [range]);

  const dates: TDateRange = useMemo(() => {
    if (range === null) {
      return { startDate: getTodayDate(), endDate: getTodayDate() };
    }
    if (typeof range === "string") {
      return getDatesFromRange(range);
    }
    return range;
  }, [range]);

  const formattedValue = useMemo(() => {
    if (range === null) {
      return "All Time";
    }
    if (typeof range === "string") {
      const dateRange = getDatesFromRange(range);

      switch (range) {
        case CustomDateRange.ThisMonth:
        case CustomDateRange.LastMonth:
          return formatDate(dateRange.startDate, DateFormats.ShortMonthYear);
        case CustomDateRange.ThisQuarter:
        case CustomDateRange.LastQuarter:
          return `${formatDate(
            dateRange.startDate,
            DateFormats.ShortMonthYear
          )} - ${formatDate(dateRange.endDate, DateFormats.ShortMonthYear)}`;
        case CustomDateRange.ThisYear:
        case CustomDateRange.LastYear:
          return formatDate(dateRange.startDate, DateFormats.Year);
        default:
          return `${formatDate(dateRange.startDate)} - ${formatDate(
            dateRange.endDate
          )}`;
      }
    } else {
      return `${formatDate(range.startDate)} - ${formatDate(range.endDate)}`;
    }
  }, [range]);

  function onSelectionChange(value: SelectKey) {
    const newRange = value as typeof currentValue;
    if (newRange === "all") {
      setRange(null);
    } else if (newRange === "custom") {
      if (typeof range === "string") {
        setRange(getDatesFromRange(range));
      }
      requestAnimationFrame(() => {
        setPopoverOpen(true);
      });
    } else if (newRange !== range) {
      setRange(newRange);
    }
  }

  function setDateRange(dateRange: TDateRange) {
    setRange(dateRange);
  }

  function onCloseDatePicker() {
    setPopoverOpen(false);
  }

  const selectItems = values(CustomDateRange).map((key) => ({
    key,
    name: getCustomDateRangeText(key),
  }));

  return (
    <div ref={triggerRef}>
      <Select
        aria-label="Date Range Selector"
        selectedKey={currentValue}
        onSelectionChange={onSelectionChange}
        buttonComponent={CustomSelectButton}
        buttonProps={{ className: buttonClassName, value: formattedValue }}
      >
        {includeAllTime && <SelectItem id={"all"}>All Time</SelectItem>}
        {selectItems.map((item) => (
          <SelectItem key={item.key} id={item.key}>
            {item.name}
          </SelectItem>
        ))}
        <SelectItem id={"custom"}>Custom</SelectItem>
      </Select>
      <DayRangePicker
        isOpen={popoverOpen}
        triggerRef={triggerRef}
        onClose={onCloseDatePicker}
        dateRange={dates}
        setDateRange={setDateRange}
        restrictToYear={restrictToYear}
      />
    </div>
  );
}

function formatDate(
  date: string,
  format: string = DateFormats.DateShortMonthYear
) {
  return moment(date).format(format);
}

interface CustomSelectButtonProps extends SelectButtonProps {
  value: string;
}

function CustomSelectButton({ className, value }: CustomSelectButtonProps) {
  return (
    <AriaButton className={clsx(dateRangeSelectorButton, className)}>
      <Icon
        className={dateRangeSelectorButtonIcon}
        icon={MdToday}
        size={IconSize.Medium}
        color={theme.color.charcoal}
      />
      {value}
    </AriaButton>
  );
}
