import {
  type TFilterGroupTypeEnum,
  type TValueMatchTypeEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";

import type { TConditionMatchType } from "../../types";
import {
  ConditionMatchType,
  FilterGroupTypeProperties,
  FilterType,
} from "../../types";
import type { FilterGroup } from ".";

export function addFilterGroup(
  filterGroupTypeId: TFilterGroupTypeEnum,
  id: string
): FilterGroup {
  let conditionMatchTypeId: TConditionMatchType;

  switch (FilterGroupTypeProperties[filterGroupTypeId].type) {
    case FilterType.Checkbox:
    case FilterType.Dropdown:
    case FilterType.System:
    case FilterType.EntityList:
      conditionMatchTypeId = ConditionMatchType.Or;
      break;
    default:
      conditionMatchTypeId = ConditionMatchType.And;
  }

  return {
    id,
    conditionMatchTypeId,
    filterGroupTypeId,
    filters: [],
  };
}

export function getDefaultValueMatchType(
  filterGroupTypeId: TFilterGroupTypeEnum
): TValueMatchTypeEnum {
  switch (FilterGroupTypeProperties[filterGroupTypeId].type) {
    case FilterType.Text:
    case FilterType.Checkbox:
    case FilterType.Dropdown:
    case FilterType.EntityList:
    case FilterType.System:
      return ValueMatchTypeEnum.Equals;
    case FilterType.Number:
    case FilterType.Minutes:
      return ValueMatchTypeEnum.GreaterThan;
    case FilterType.Date:
      return ValueMatchTypeEnum.DateThisWeek;
  }
}
