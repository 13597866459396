import { entries, groupBy } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  getDate,
  jobMilestoneType
} from "../../../lib/entities/jobMilestoneEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectSortedJobMilestonesByJobId } from "../../../redux/selectors/jobMilestoneSelectors";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";

const mapState = (state, props) => ({
  jobMilestones: selectSortedJobMilestonesByJobId(state, props)
});

const ScheduleJobMilestoneDatesConsumer = ({ jobMilestones, children }) => (
  <ScheduleScrollContext.Consumer>
    {({ viewportOffsetX, viewportOffsetRightX, dayWidth, getOffsetXAtDate }) =>
      entries(groupBy(jobMilestones, getDate))
        .map(([date, jobMilestonesForDate]) => ({
          date,
          left: getOffsetXAtDate(date),
          right: getOffsetXAtDate(date) + dayWidth,
          width: dayWidth,
          jobMilestoneIds: jobMilestonesForDate.map(entity => entity.id)
        }))
        .filter(
          ({ left, right }) =>
            left <= viewportOffsetRightX && right >= viewportOffsetX
        )
        .map(({ date, left, width, jobMilestoneIds }) =>
          children({
            date,
            left,
            width,
            jobMilestoneIds
          })
        )
    }
  </ScheduleScrollContext.Consumer>
);

ScheduleJobMilestoneDatesConsumer.propTypes = {
  jobId: entityIdType.isRequired,
  jobMilestones: PropTypes.arrayOf(jobMilestoneType).isRequired,
  children: PropTypes.func.isRequired
};

export default connect(mapState)(ScheduleJobMilestoneDatesConsumer);
