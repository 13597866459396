import { isEmpty } from "lodash-es";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { undoMergeQuoteLineItemsAPI } from "../../../lib/API/quoteLineItemAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_QUOTE_LINE_ITEMS,
  QUOTE_LINE_ITEM_UNDO_MERGE,
  QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR,
  QUOTE_LINE_ITEM_UNDO_MERGE_SAVED,
  QUOTE_LINE_ITEM_UNDO_MERGE_SAVING
} from "../../../lib/constants";
import { getId } from "../../../lib/objects";
import { undoMergeLineItems } from "../../../lib/ui/commercialDocumentLineItems";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectSortedQuoteLineItemsByQuoteId } from "../../selectors/quoteLineItemSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* undoMerge(action) {
  const { quoteId, snapshotId } = action.payload;

  const prevQuoteLineItems = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  if (isEmpty(prevQuoteLineItems)) return;

  const [baseQuoteLineItem, ...otherQuoteLineItems] = prevQuoteLineItems;

  const currentQuoteLineItems = yield select(
    selectSortedQuoteLineItemsByQuoteId,
    { quoteId }
  );

  try {
    yield put(createAction(QUOTE_LINE_ITEM_UNDO_MERGE_SAVING, { id: quoteId }));

    const newQuoteLineItems = undoMergeLineItems(
      baseQuoteLineItem,
      otherQuoteLineItems,
      currentQuoteLineItems
    );

    if (newQuoteLineItems.length)
      yield put(
        createAction(ENTITIES_RECEIVED, {
          [ENTITY_NAME_QUOTE_LINE_ITEMS]: newQuoteLineItems
        })
      );

    const data = yield call(
      undoMergeQuoteLineItemsAPI,
      baseQuoteLineItem,
      otherQuoteLineItems.map(getId)
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(QUOTE_LINE_ITEM_UNDO_MERGE_SAVED, {
        id: quoteId,
        snapshotId
      })
    );

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR, {
        id: quoteId,
        prevQuoteLineItems: currentQuoteLineItems,
        mergedQuoteLineItems: otherQuoteLineItems,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchUndoMergeLineItems() {
  yield takeLatest(QUOTE_LINE_ITEM_UNDO_MERGE, undoMerge);
}
