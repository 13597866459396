import { IconButton } from "../../components/buttons/IconButton";
import { StBackArrow } from "../../components/icons";
import { backButtonCss } from "./BackButton.css";

type Props = {
  onClick: () => void;
};

export function BackButton({ onClick }: Props) {
  return (
    <IconButton
      className={backButtonCss}
      onClick={onClick}
      iconProps={{
        icon: StBackArrow,
        size: 26,
        title: "Back",
      }}
    />
  );
}
