import { bugsnagClient } from "@streamtimefe/error";
import {
  AccountingPlatformConnectionException,
  ApiException,
  DataException,
  FormInputException,
} from "st-shared/lib";

import { handleError, handleWarning } from "../../lib/errorHandlers";
import { WebAppApiException } from "../../lib/exceptions/WebAppApiException";
import { sendAlertError } from "../../lib/WebAppAPI";

export const sagaError = (error) => {
  if (!error) return;

  switch (error.constructor) {
    case ApiException:
      handleWarning("ApiException", error.response);
      try {
        sendAlertError(error.message);
      } catch (e) {
        handleWarning(e.message);
      }
      break;
    case DataException:
      handleWarning(error.message);
      bugsnagClient?.notify(error.message);
      break;
    case FormInputException:
      try {
        sendAlertError(error.message);
      } catch (e) {
        handleWarning(e.message);
      }
      break;
    case WebAppApiException:
      handleWarning(error.message);
      bugsnagClient?.notify(error.message);
      break;
    case AccountingPlatformConnectionException:
      handleWarning(error.message);
      break;
    default:
      try {
        sendAlertError(
          "Oops. Something went wrong. This is not the droid you’re looking for. Don’t worry though, we've notified the Streamtime Jedis."
        );
      } finally {
        handleError(error);
      }
  }
};
