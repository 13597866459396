import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { costingMethodObj, CostingMethods } from "st-shared/entities";
import { JobItemRole } from "st-shared/entities/JobItemRole";
import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_COSTING_METHOD,
  NUMBER_FORMAT_NO_DECIMAL,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { hasSellRate } from "../../../lib/entities/jobItemEntity";
import numeralJs from "../../../lib/numeralJs";
import createAction from "../../../redux/helpers/createAction";
import { useJobCurrency } from "../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../redux/selectors/job";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { selectJobItemRate } from "../../../redux/selectors/jobItem/selectJobItemRateInput";
import { useIsEditingJobItem } from "../../../redux/selectors/jobItem/ui/isEditing";
import { selectRoleRateByRoleIdRateCardId } from "../../../redux/selectors/rateSelectors";
import { useRole } from "../../../redux/selectors/role";
import { actionJobItemRoleSetRate } from "../../../state/entities/jobItemRole/actions";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemRoleId } from "../context/JobItemRoleIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemRateFieldCss } from "../JobItem/ItemRate.css";
import { UserRoleVariance } from "../JobItem/tooltips/UserRoleVariance";
import { jobItemRoleRateContainer } from "./JobItemRoleRate.css";

export function JobItemRoleRate() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemRoleId = useJobItemRoleId();
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const costingMethod = costingMethodObj(jobItem.costingMethod);
  const role = useRole(jobItemRole.roleId);

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemRoleId,
    jobItemId,
    key: FOCUS_KEYS.ITEM_ROLE_RATE,
  });

  const [rate, setRate] = useState("");

  // eslint-disable-next-line prefer-const
  let { value, placeholder } = useRateInput(
    focused,
    rate,
    jobItemRole,
    jobItem
  );

  const dispatch = useDispatch();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!isJobEditable) return;
    const newValue = e.target.value;

    setRate(newValue);

    dispatch(actionJobItemRoleSetRate(jobItemRoleId, newValue));
  }

  function handleBlur() {
    setFocused(false);
  }

  function handleFocus() {
    setRate(
      numeralJs(jobItemRole.jobCurrencySellRate).format(
        NUMBER_FORMAT_TWO_DECIMALS
      )
    );
    setFocused(true);
  }

  function setCostingMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_COSTING_METHOD, {
        jobItemId,
        costingMethodId: CostingMethods.ValueUserSell,
        doSetFocus: false,
      })
    );
  }

  const [hovered, setHovered] = useState(false);

  if (costingMethod.isValueCalculatedSell()) {
    if (!isJobEditable || !hovered) {
      placeholder = "";
    }
  }

  return (
    <div
      className={jobItemRoleRateContainer}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ItemInputField
        ref={ref}
        as="numeric"
        containerClassName={itemRateFieldCss}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!isJobEditable || !costingMethod.isBasedByUser()}
        fallbackLook={!costingMethod.isBasedByUser() && !hasSellRate(jobItem)}
      />
      <UserRoleVariance />
      {isJobEditable && costingMethod.isValueCalculatedSell() && (
        <MethodLock
          tooltip={
            <>
              <strong>
                The sell rate for {role.name} is {placeholder} per hour.
              </strong>{" "}
              Click to plan and track time on this item by each person’s sell
              rate instead of item rate.
            </>
          }
          className={methodLockIcon}
          onClick={setCostingMethod}
        />
      )}
    </div>
  );
}

function useRateInput(
  isFocused: boolean,
  inputValue: string,
  jobItemRole: JobItemRole,
  jobItem: any
) {
  let value = "";
  let placeholder = "";

  const isEditing = useIsEditingJobItem(jobItem.id);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobItem.jobId);
  const costingMethod = costingMethodObj(jobItem.costingMethod);

  const jobCurrencySellRate = numeralJs(
    jobItemRole.jobCurrencySellRate
  ).value();
  const jobItemSellRate = useSelector((state) =>
    selectJobItemRate(state, {
      jobId: jobItem.jobId,
      jobItemId: jobItem.id,
    })
  );
  const job = useJob(jobItem.jobId);
  const roleRate = useSelector((state) =>
    selectRoleRateByRoleIdRateCardId(state, {
      roleId: jobItemRole.roleId,
      rateCardId: job.rateCardId,
    })
  );

  if (costingMethod.isBasedByUser()) {
    if (jobCurrencySellRate === 0 || jobCurrencySellRate === null) {
      value = "";
    } else if (isFocused) {
      value = inputValue;
    } else {
      value = formatForeignCurrency(customerCurrency, {
        value: jobCurrencySellRate,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });
    }
  } else if (jobItemSellRate.length === 0) {
    value = formatForeignCurrency(customerCurrency, {
      value: roleRate,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }

  if (!costingMethod.isBasedByUser()) {
    placeholder = "";
  } else if (!isEditing) {
    placeholder = "Add rate";
  } else if (value.length > 0) {
    placeholder = "";
  } else {
    placeholder = formatForeignCurrency(customerCurrency, {
      value: 0,
      currency: jobCurrency,
    });
  }

  if (costingMethod.isValueCalculatedSell()) {
    placeholder = formatForeignCurrency(customerCurrency, {
      value: roleRate,
      currency: jobCurrency,
      hideLongSymbols: true,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }

  return {
    value,
    placeholder,
  };
}
