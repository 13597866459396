import { Entity } from "@streamtimefe/entities";
import { put, takeEvery } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_BULK_SAVE,
  SCHEDULE_BLOCK_DUPLICATE,
  SCHEDULE_BLOCK_DUPLICATE_MULTIPLE,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";

function* duplicateBlocks(action) {
  const { selectedBlockKeys } = action.payload;
  const newBlockKeys = [];
  for (let i = 0; i < selectedBlockKeys.length; i++) {
    const blockKey = selectedBlockKeys[i];
    const newBlockKey = Entity.temporaryId();
    newBlockKeys.push(newBlockKey);
    yield put(
      createAction(SCHEDULE_BLOCK_DUPLICATE, {
        ...action.payload,
        blockKey,
        newBlockKey,
        isBulkAction: true,
      })
    );
  }
  yield put(
    createAction(SCHEDULE_BLOCK_BULK_SAVE, {
      ...action.payload,
      newBlockKeys,
      sagaType: SCHEDULE_BLOCK_DUPLICATE,
    })
  );
}

function* watchDuplicateMultipleBlocks() {
  yield takeEvery([SCHEDULE_BLOCK_DUPLICATE_MULTIPLE], duplicateBlocks);
}

export default watchDuplicateMultipleBlocks;
