import { keys } from "lodash-es";
import type { ReactNode } from "react";
import { useState } from "react";

import type { PopoverMenuProps } from "../../components";
import { PopoverMenu } from "../../components";
import type { SearchOption } from "../../entities";
import { defaultExcludeOptions, defaultSearchOptions } from "../../entities";
import type { TEntityId } from "../../entities/Entity";
import { Highlight } from "..";
import { searchMenuItem, searchMenuItemHeading } from ".";
import type { SearchMenuHeaderProps } from "./SearchMenuHeader";
import { SearchMenuHeader } from "./SearchMenuHeader";

export interface SearchMenuProps
  extends Omit<
    PopoverMenuProps,
    "children" | "HeaderComponent" | "FooterComponent"
  > {
  options: SearchOption[] | Record<string, SearchOption[]>;
  excludeIds?: number[];
  excludeOptions?: typeof defaultExcludeOptions;
  searchOptions?: typeof defaultSearchOptions;
  onPickOption?: (key: TEntityId) => void;
  renderOption?: (
    option: SearchOption,
    onPick: (key: TEntityId) => void,
    query: string
  ) => ReactNode;
  renderOptionHeading?: (heading: string, query: string) => ReactNode;
  renderHeader?: (props: SearchMenuHeaderProps) => ReactNode;
  headerProps?: Partial<SearchMenuHeaderProps>;
  renderFooter?: (hasResults?: boolean) => ReactNode;
}

export function SearchMenu({
  options,
  excludeIds,
  excludeOptions = defaultExcludeOptions,
  onPickOption = () => null,
  searchOptions = defaultSearchOptions,
  renderOption = defaultRenderOption,
  renderOptionHeading = defaultRenderOptionHeading,
  renderHeader = SearchMenuHeader,
  headerProps,
  renderFooter,
  TransitionProps,
  ...props
}: SearchMenuProps) {
  const [searchQuery, setSearchQuery] = useState("");

  function onExited(node: HTMLElement) {
    setSearchQuery("");
    TransitionProps?.onExited?.(node);
  }

  function onSearch(query: string) {
    setSearchQuery(query);
  }

  let hasOptions: boolean = false;
  let hasResults: boolean = false;
  let renderResults: () => ReactNode;

  if (Array.isArray(options)) {
    const availableOptions = excludeOptions(options, excludeIds || []);
    const searchResults = searchOptions(availableOptions, searchQuery);

    hasOptions = Boolean(availableOptions.length);
    hasResults = Boolean(searchResults.length);

    renderResults = () =>
      searchResults.map((result) =>
        renderOption(result, onPickOption, searchQuery)
      );
  } else {
    const results: (string | SearchOption)[] = [];

    keys(options).forEach((key) => {
      const availableOptions = excludeOptions(options[key], excludeIds || []);
      const searchResults = searchOptions(availableOptions, searchQuery);

      if (availableOptions.length) hasOptions = true;
      if (searchResults.length) results.push(key, ...searchResults);
    });

    hasResults = Boolean(results.length);

    renderResults = () =>
      results.map((result) =>
        typeof result === "string"
          ? renderOptionHeading(result, searchQuery)
          : renderOption(result, onPickOption, searchQuery)
      );
  }

  return (
    <PopoverMenu
      HeaderComponent={renderHeader({
        hasOptions,
        hasResults,
        searchQuery,
        onSearch,
        ...headerProps,
      })}
      FooterComponent={renderFooter && renderFooter(hasResults)}
      TransitionProps={{ ...TransitionProps, onExited }}
      {...props}
    >
      {renderResults()}
    </PopoverMenu>
  );
}

function defaultRenderOption(
  option: SearchOption,
  onPick: (key: TEntityId) => void,
  query: string
) {
  return (
    <div
      key={option.key}
      className={searchMenuItem}
      onClick={() => onPick(option.key)}
    >
      <Highlight text={option.value} query={query} />
    </div>
  );
}

function defaultRenderOptionHeading(heading: string, query: string) {
  return (
    <div key={heading} className={searchMenuItemHeading}>
      <Highlight text={heading} query={query} />
    </div>
  );
}
