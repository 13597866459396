import { SavedSegmentAccessTypeEnum } from "@streamtimefe/entities";
import clsx from "clsx";
import type { MouseEventHandler } from "react";
import { useState } from "react";
import {
  Button,
  Icon,
  IconSize,
  MdExpandMore,
  RTooltip,
} from "st-shared/components";
import type { TEntityId } from "st-shared/entities/Entity";
import {
  useSavedSegmentEntityCrudState,
  useSavedSegmentUserAccessType,
} from "st-shared/stores";
import { EntityCrudState } from "st-shared/stores/entities/crudStore";

import { useCanSaveExistingReport } from "../../hooks/useCanSaveExistingReport";
import { useCanSaveNewReport } from "../../hooks/useCanSaveNewReport";
import { useLoadedReport } from "../../hooks/useLoadedReport";
import { reportingStore } from "../../state/stores/reportingStore";
import { useReportingSavedSegmentEntityId } from "../../state/stores/savedSegmentSelectors";
import {
  revertReportCss,
  saveReportButtonCss,
  saveReportButtonMainActionCss,
  secondaryButtonCss,
  secondaryIconCss,
  secondaryTooltipArrowCss,
  secondaryTooltipCss,
} from "./SaveReport.css";

export function SaveReport() {
  const loaded = useLoadedReport();
  const entityId = useReportingSavedSegmentEntityId();

  if (!loaded) return null;

  if (!entityId) return <SaveBlankReport />;

  return <Save entityId={entityId} />;
}

function SaveBlankReport() {
  const canSaveNewReport = useCanSaveNewReport();

  if (!canSaveNewReport) return null;

  function save() {
    reportingStore().actions.saveNewSavedSegment();
  }

  return <SaveButton text="Save Report" action={save} main />;
}

type Props = { entityId: TEntityId };

function Save({ entityId }: Props) {
  const canSaveExistingReport = useCanSaveExistingReport(entityId);
  const crudState = useSavedSegmentEntityCrudState(entityId);
  const canSaveNewReport = useCanSaveNewReport();
  const userAccesType = useSavedSegmentUserAccessType(entityId);

  if (!canSaveExistingReport) return null;

  function revert() {
    reportingStore().actions.revertSavedSegment();
  }

  function save() {
    reportingStore().actions.saveSavedSegment();
  }

  function saveAsNew() {
    reportingStore().actions.saveNewSavedSegment();
  }

  return (
    <>
      <Button variant="link" onClick={revert} className={revertReportCss}>
        Revert
      </Button>
      {userAccesType === SavedSegmentAccessTypeEnum.Edit && (
        <SaveButton
          text={
            crudState === EntityCrudState.Saving ? "Saving..." : "Save Changes"
          }
          main
          action={save}
          secondary={
            canSaveNewReport
              ? [{ text: "Save as new", action: saveAsNew }]
              : undefined
          }
        />
      )}
      {userAccesType === SavedSegmentAccessTypeEnum.View &&
        canSaveNewReport && (
          <SaveButton text="Save as new" action={saveAsNew} main />
        )}
    </>
  );
}

type SaveButtonProps = {
  text: string;
  action: MouseEventHandler<HTMLButtonElement>;
  secondary?: {
    text: string;
    action: MouseEventHandler<HTMLButtonElement>;
  }[];
  main?: boolean;
};

function SaveButton({ text, action, secondary, main }: SaveButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  if (!secondary || secondary.length === 0) {
    return (
      <Button
        className={clsx(
          saveReportButtonCss,
          main && saveReportButtonMainActionCss
        )}
        variant="primary"
        onClick={action}
      >
        {text}
      </Button>
    );
  }

  return (
    <>
      <Button
        className={clsx(
          saveReportButtonCss,
          main && saveReportButtonMainActionCss
        )}
        variant="primary"
        onClick={action}
        data-tooltip-id="button-secondary-actions"
      >
        {text}
        <Icon
          icon={MdExpandMore}
          size={IconSize.XLarge}
          className={secondaryIconCss}
        />
      </Button>
      <RTooltip
        id="button-secondary-actions"
        place="top"
        className={secondaryTooltipCss}
        classNameArrow={secondaryTooltipArrowCss}
        render={() => (
          <>
            {secondary.map((s) => (
              <Button
                key={s.text}
                className={secondaryButtonCss}
                variant="link"
                onClick={(event) => {
                  setIsOpen(false);
                  s.action(event);
                }}
              >
                {s.text}
              </Button>
            ))}
          </>
        )}
        positionStrategy="fixed"
        clickable
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}
