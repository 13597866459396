import type { TColumnTooltipEnum } from "@streamtimefe/entities";
import { ColumnTooltip as ColumnTooltipUtils } from "@streamtimefe/entities";
import type { ReactElement } from "react";

import { Tooltip } from "../../components";
import {
  columnTooltipCss,
  columnTooltipSecondTitle,
  columnTooltipTitle,
} from "./ColumnTooltip.css";

interface Props {
  type: TColumnTooltipEnum;
  children: ReactElement;
  title: string;
}

export function ColumnTooltip({ type, title, children }: Props) {
  const description = ColumnTooltipUtils.getDescription(type);
  const calculation = ColumnTooltipUtils.getCalculation(type);

  return (
    <Tooltip
      title={
        <>
          <div className={columnTooltipTitle}>{title}</div>
          {description}
          {calculation && (
            <>
              <div className={columnTooltipSecondTitle}>
                How it&apos;s Calculated
              </div>
              {calculation}
            </>
          )}
        </>
      }
      tooltipClassName={columnTooltipCss}
      placement="left"
    >
      {children}
    </Tooltip>
  );
}
