// deprecated

import type { TRateCard } from "@streamtimefe/entities";

import type { SearchOption } from "./Options/SearchOption";

export function rateCardObj(entity: TRateCard) {
  const obj = {
    entity,
    getSearchString: function () {
      return entity.name;
    },
    getAsOption: function (): SearchOption<TRateCard> {
      return {
        entity,
        key: entity.id,
        value: entity.name,
        searchString: obj.getSearchString(),
      };
    },
  };
  return obj;
}
