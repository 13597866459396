import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataInvoiceName,
  getMetaDataInvoiceNumber,
  getMetaDataInvoiceTypeName,
  getParsedMetaData,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobInvoiceCreated = ({ activityEntry, canEditJobs }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {` created ${getMetaDataInvoiceTypeName(activityEntry)} `}
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getParsedMetaData(activityEntry).invoiceNumber !== undefined
        ? getMetaDataInvoiceNumber(activityEntry)
        : getMetaDataInvoiceName(activityEntry)}
    </LinkToActivityEntryEntity>
  </SystemMessage>
);

JobInvoiceCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobInvoiceCreated;
