import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const ChecklistIcon = props => (
  <SvgIcon {...props} viewBox="0 0 23 17">
    <path d="M4.0825 17L0 12.8664L1.2075 11.6438L4.0825 14.5257L9.22875 9.31507L10.4362 10.5668L4.0825 17ZM4.0825 7.68493L0 3.55137L1.2075 2.32877L4.0825 5.21062L9.22875 0L10.4362 1.25171L4.0825 7.68493ZM12.65 14.7877V13.0411H23V14.7877H12.65ZM12.65 5.4726V3.72603H23V5.4726H12.65Z" />
  </SvgIcon>
);

export default StyledIcon(ChecklistIcon);
