import { createModelSetParser } from "../API/modelSets";
import { WebAppApiException } from "../exceptions/WebAppApiException";
import WebAppAPI, { SEND_OPEN_CREATE_TAX_RATE_MODAL } from "./index";

export const sendOpenCreateTaxRateModal = branchId =>
  new Promise((resolve, reject) => {
    const jQueryDeferred = WebAppAPI.sendAction(
      SEND_OPEN_CREATE_TAX_RATE_MODAL,
      branchId
    );

    if (jQueryDeferred)
      jQueryDeferred
        .then(({ taxRate, response }) => {
          return {
            taxRate,
            ...createModelSetParser(response)
          };
        })
        .then(resolve)
        .fail(reject);
    else
      reject(
        new WebAppApiException(
          `Result undefined for action ${SEND_OPEN_CREATE_TAX_RATE_MODAL}`
        )
      );
  });
