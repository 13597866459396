import { call, put, select, takeLatest } from "redux-saga/effects";
import { createPurchaseOrderLineItemAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  PURCHASE_ORDER_LINE_ITEM_CREATE,
  PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_CREATED,
  PURCHASE_ORDER_LINE_ITEM_CREATING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectPurchaseOrderLineItemById } from "../../selectors/purchaseOrderLineItemSelectors";

function* createLine(action) {
  const { id } = action.payload;

  try {
    yield put(createAction(PURCHASE_ORDER_LINE_ITEM_CREATING, { id }));

    const purchaseOrderLineItem = yield select(
      selectPurchaseOrderLineItemById,
      { id }
    );

    const { purchaseOrderLineItems, ...entities } = yield call(
      createPurchaseOrderLineItemAPI,
      purchaseOrderLineItem
    );

    yield put(createAction(ENTITIES_RECEIVED, entities));

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_CREATED, {
        id,
        purchaseOrderLineItems
      })
    );

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_CREATE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchPurchaseOrderLineItemCreate() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_CREATE, createLine);
}
