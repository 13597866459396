import { produce } from "immer";
import { forEach, isEmpty, orderBy, reduce, setWith } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_USERS,
  JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY,
  JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY,
} from "../../../lib/constants";
import { isDeleted as isJobItemDeleted } from "../../../lib/entities/jobItemEntity";
import {
  getJobItemUserStatusId,
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
  isDeleted as isJobItemUserDeleted,
} from "../../../lib/entities/jobItemUserEntity";
import { selectJobItemRolesByJobId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRolesByJobId";
import { EntityNames } from "../../../state/entities/types";
import createCrossReducer from "../../helpers/createCrossReducer";
import { selectJobPageJobId } from "../../selectors/jobDetails/ui/selectJobPageJobId";
import { selectUnplannedTimeSummary } from "../../selectors/jobDetails/ui/unplannedTimeSummary";
import { selectJobItem } from "../../selectors/jobItem";
import { selectJobItemUsersByJobId } from "../../selectors/jobItemUser/selectJobItemUsersByJobId";
import { selectRole } from "../../selectors/role";
import { selectUser } from "../../selectors/user";

function recalculateSummary(state) {
  const jobId = selectJobPageJobId(state);
  if (!jobId) {
    return state;
  }

  return produce(state, (draft) => {
    const users = new Map();
    const roles = new Map();

    addJobItemUsers(state, users);
    addUnplannedTime(state, users);

    addJobItemRoles(state, roles);

    users.forEach((user) => {
      user.items = orderBy(
        user.items,
        ["logged", "planned", "name"],
        ["desc", "desc", "asc"]
      );
    });

    roles.forEach((role) => {
      role.items = orderBy(role.items, ["planned", "name"], ["desc", "asc"]);
    });

    setWith(
      draft,
      `ui.jobDetails.teamSummary.phaseUsers`,
      getPhaseUsers(state, users),
      Object
    );
    setWith(
      draft,
      `ui.jobDetails.teamSummary.jobUsers`,
      getJobUsers(state, users),
      Object
    );
    setWith(
      draft,
      `ui.jobDetails.teamSummary.phaseRoles`,
      getPhaseRoles(state, roles),
      Object
    );
    setWith(
      draft,
      `ui.jobDetails.teamSummary.jobRoles`,
      getJobRoles(state, roles),
      Object
    );
  });
}

function addJobItemUsers(state, users) {
  const jobId = selectJobPageJobId(state);
  if (jobId === null) return;
  const jobItemsUsers = selectJobItemUsersByJobId(state, { jobId });

  forEach(jobItemsUsers, (jobItemUser) => {
    const userId = jobItemUser.userId;

    const jobItem = selectJobItem(state, {
      jobItemId: jobItemUser.jobItemId,
    });

    if (
      isJobItemUserDeleted(jobItemUser) ||
      !jobItem ||
      isJobItemDeleted(jobItem)
    )
      return;

    if (!users.has(userId)) {
      const user = selectUser(state, { userId });
      users.set(userId, {
        id: userId,
        name: user.displayName,
        items: [],
        unplannedTime: 0,
      });
    }

    users.get(userId).items.push({
      id: jobItemUser.id,
      name: jobItem.name,
      jobPhaseId: jobItem.jobPhaseId,
      logged: getTotalUsedMinutes(jobItemUser),
      planned: getTotalPlannedMinutes(jobItemUser),
      statusId: getJobItemUserStatusId(jobItemUser),
    });
  });
}

function addUnplannedTime(state, users) {
  const unplannedTimeSummary = selectUnplannedTimeSummary(state);

  unplannedTimeSummary.forEach((unplannedTime) => {
    const userId = unplannedTime.userId;

    if (!users.has(userId)) {
      const user = selectUser(state, { userId });
      users.set(userId, {
        id: userId,
        name: user.displayName,
        items: [],
        unplannedTime: Number(unplannedTime.totalMinutes),
      });
    } else {
      users.get(userId).unplannedTime = Number(unplannedTime.totalMinutes);
    }
  });
}

function addJobItemRoles(state, roles) {
  const jobId = selectJobPageJobId(state);
  if (jobId === null) return;
  const jobItemRoles = selectJobItemRolesByJobId(state, { jobId });

  forEach(jobItemRoles, (jobItemRole) => {
    const jobItem = selectJobItem(state, {
      jobItemId: jobItemRole.jobItemId,
    });

    if (!jobItemRole.active || !jobItem || isJobItemDeleted(jobItem)) return;

    if (!roles.has(jobItemRole.roleId)) {
      const role = selectRole(state, { roleId: jobItemRole.roleId });
      roles.set(jobItemRole.roleId, {
        id: jobItemRole.roleId,
        name: role.name,
        items: [],
      });
    }

    roles.get(jobItemRole.roleId).items.push({
      id: jobItemRole.id,
      name: jobItem.name,
      jobPhaseId: jobItem.jobPhaseId,
      planned: jobItemRole.totalPlannedMinutes,
    });
  });
}

function getPhaseUsers(state, users) {
  const phaseUsers = {};

  users.forEach((user, userId) => {
    user.items.forEach((item) => {
      if (!item.jobPhaseId) return;

      const jobPhaseId = item.jobPhaseId;

      if (!phaseUsers[jobPhaseId]) {
        phaseUsers[jobPhaseId] = {};
      }

      if (phaseUsers[jobPhaseId][userId]) {
        phaseUsers[jobPhaseId][userId].logged += item.logged;
        phaseUsers[jobPhaseId][userId].planned += item.planned;
      } else {
        phaseUsers[jobPhaseId][userId] = {
          id: user.id,
          name: user.name,
          logged: item.logged,
          planned: item.planned,
          items: [],
        };
      }

      phaseUsers[jobPhaseId][userId].items.push({
        id: item.id,
        name: item.name,
        logged: item.logged,
        planned: item.planned,
        statusId: item.statusId,
      });
    });
  });

  forEach(phaseUsers, (phase, phaseId) => {
    phaseUsers[phaseId] = orderBy(
      phaseUsers[phaseId],
      ["logged", "planned", "name"],
      ["desc", "desc", "asc"]
    );
  });

  return phaseUsers;
}

function getJobUsers(state, users) {
  const jobUsers = [];

  users.forEach((user, userId) => {
    jobUsers.push({
      ...user,
      logged:
        user.unplannedTime +
        reduce(user.items, (total, item) => total + item.logged, 0),
      planned: reduce(user.items, (total, item) => total + item.planned, 0),
    });
  });
  return orderBy(
    jobUsers,
    ["logged", "unplannedTime", "planned", "name"],
    ["desc", "desc", "desc", "asc"]
  );
}

function getPhaseRoles(state, roles) {
  const phaseRoles = {};

  roles.forEach((role, roleId) => {
    role.items.forEach((item) => {
      if (!item.jobPhaseId) return;

      const jobPhaseId = item.jobPhaseId;

      if (!phaseRoles[jobPhaseId]) {
        phaseRoles[jobPhaseId] = {};
      }

      if (phaseRoles[jobPhaseId][roleId]) {
        phaseRoles[jobPhaseId][roleId].planned += item.planned;
      } else {
        phaseRoles[jobPhaseId][roleId] = {
          id: role.id,
          name: role.name,
          planned: item.planned,
          items: [],
        };
      }

      phaseRoles[jobPhaseId][roleId].items.push({
        id: item.id,
        name: item.name,
        planned: item.planned,
      });
    });
  });

  forEach(phaseRoles, (phase, phaseId) => {
    phaseRoles[phaseId] = orderBy(
      phaseRoles[phaseId],
      ["planned", "name"],
      ["desc", "asc"]
    );
  });

  return phaseRoles;
}

function getJobRoles(state, roles) {
  const jobRoles = [];

  roles.forEach((role) => {
    jobRoles.push({
      ...role,
      planned: reduce(role.items, (total, item) => total + item.planned, 0),
    });
  });
  return orderBy(jobRoles, ["planned", "name"], ["desc", "asc"]);
}

function receiveEntities(state, action) {
  const recheck =
    !isEmpty(action.payload[EntityNames.JobItemRoles]) ||
    !isEmpty(action.payload[ENTITY_NAME_JOB_ITEM_USERS]) ||
    !isEmpty(action.payload[ENTITY_NAME_JOB_ITEMS]) ||
    !isEmpty(action.payload[ENTITY_NAME_USERS]);

  return updateTeamSummary(state, action, recheck);
}

function removeEntities(state, action) {
  const recheck =
    action.payload.entityName === EntityNames.JobItemRoles ||
    action.payload.entityName === ENTITY_NAME_JOB_ITEM_USERS ||
    action.payload.entityName === ENTITY_NAME_JOB_ITEMS ||
    action.payload.entityName === ENTITY_NAME_USERS;

  return updateTeamSummary(state, action, recheck);
}

function updateTeamSummary(state, action, recheck = true) {
  if (recheck) {
    return recalculateSummary(state);
  }

  return state;
}

export default createCrossReducer({
  [ENTITIES_RECEIVED]: receiveEntities,
  [ENTITIES_REMOVED]: removeEntities,
  [JOB_DETAILS_SET_UNPLANNED_TIME_SUMMARY]: updateTeamSummary,
  [JOB_DETAILS_UPDATE_UNPLANNED_TIME_SUMMARY]: updateTeamSummary,
});
