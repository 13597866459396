import styled from "styled-components";
import { mapPropsToClassName } from "../../../../../lib/objects";
import SegmentPart from "./index";

export const Line = styled(SegmentPart).attrs(
  mapPropsToClassName([
    "conflict",
    "curve",
    "snakeTop",
    "snakeBottom",
    "selected",
    "float"
  ])
)`
  position: absolute;
  height: 2px;
  border-bottom-width: 2px;
  &.curve {
    top: 16px;
  }
  &.snakeBottom {
    bottom: 4px;
  }
  &.snakeTop {
    top: 4px;
  }
`;

export const LineLeft = styled(Line)`
  left: 16px;
  right: 5px;
`;

export const LineRight = styled(Line)`
  left: 5px;
  right: 16px;
`;
