import type { ElementProps } from "@floating-ui/react";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import clsx from "clsx";
import type { ChangeEvent, ComponentPropsWithRef, ReactNode, Ref } from "react";
import { forwardRef, useRef, useState } from "react";

import type { SearchOption } from "../../entities";
import { defaultExcludeOptions, defaultSearchOptions } from "../../entities";
import type { Entity } from "../../entities/Entity";
import { Highlight } from "../../module/Highlight";
import {
  autoCompleteListCss,
  autoCompleteListItemCss,
  autoCompleteListItemTextCss,
} from "./AutoComplete.css";
import type { InputProps } from "./Input";
import { Input } from "./Input";

export interface AutoCompleteProps<
  E extends Entity = Entity,
  SO extends SearchOption<E> = SearchOption<E>,
> extends Omit<InputProps, "value" | "onChange"> {
  inputRef?: Ref<HTMLInputElement>;
  options: SO[];
  excludeIds?: number[];
  excludeOptions?: typeof defaultExcludeOptions;
  searchOptions?: typeof defaultSearchOptions;
  pickOption?: (option: SO) => void;
  value: string;
  onInputValueChange: (value: string) => void;
  listClassName?: string;
  listItemClassName?: string;
  highlight?: boolean;
  maxHeight?: number | null;
  openInitial?: boolean;
  resetOnPick?: boolean;
}

export function AutoComplete<
  E extends Entity = Entity,
  SO extends SearchOption<E> = SearchOption<E>,
>({
  inputRef,
  options,
  excludeIds,
  excludeOptions = defaultExcludeOptions,
  searchOptions = defaultSearchOptions,
  pickOption,
  value,
  onInputValueChange,
  listClassName,
  listItemClassName,
  highlight = true,
  maxHeight = 174,
  openInitial = false,
  resetOnPick = false,
  ...props
}: AutoCompleteProps<E, SO>) {
  const [open, setOpen] = useState(openInitial);
  const [inputValue, setInputValue] = useState<string>(value || "");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { context, refs, floatingStyles } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: (value) => setTimeout(() => setOpen(value), 300),
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          let max = availableHeight;
          if (typeof maxHeight === "number") {
            max = Math.min(availableHeight, maxHeight);
          }
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${max}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
    allowEscape: true,
  });
  const inputProps = useInput(props);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav, inputProps]
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);
    onInputValueChange(value);

    setActiveIndex(null);
    setOpen(true);
  }

  function onOptionPick(option: SO) {
    if (!resetOnPick) {
      setInputValue(option.value);
      onInputValueChange(option.value);
    } else {
      setInputValue("");
      onInputValueChange("");
    }

    pickOption?.(option);
  }

  const availableOptions = excludeIds
    ? excludeOptions(options, excludeIds)
    : options;
  const searchResults =
    inputValue.length > 0
      ? searchOptions(availableOptions, inputValue)
      : availableOptions;

  return (
    <>
      <Input
        ref={useMergeRefs([inputRef, refs.setReference])}
        {...getReferenceProps({
          value: inputValue,
          onChange,
          "aria-autocomplete": "list",
          onKeyDown(event) {
            if (
              event.key === "Enter" &&
              activeIndex != null &&
              searchResults[activeIndex]
            ) {
              onOptionPick(searchResults[activeIndex]);
              setActiveIndex(null);
              setOpen(false);
            }
          },
        })}
      />
      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
            closeOnFocusOut
          >
            <ul
              ref={refs.setFloating}
              {...getFloatingProps({
                className: clsx(autoCompleteListCss, listClassName),
                style: { ...floatingStyles },
              })}
            >
              {searchResults.map((option, index) => (
                <AutoCompleteItem
                  key={option.key}
                  {...getItemProps({
                    className: listItemClassName,
                    ref(node) {
                      listRef.current[index] = node;
                    },
                    onClick() {
                      onOptionPick(option);
                      refs.domReference.current?.focus();
                      setOpen(false);
                    },
                  })}
                  active={activeIndex === index}
                >
                  <span className={autoCompleteListItemTextCss}>
                    {highlight && (
                      <Highlight text={option.value} query={inputValue} />
                    )}
                    {!highlight && option.value}
                  </span>
                </AutoCompleteItem>
              ))}
            </ul>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}

function useInput(props: InputProps): ElementProps {
  return {
    reference: props,
  };
}

export interface AutoCompleteItemProps extends ComponentPropsWithRef<"li"> {
  children: ReactNode;
  active: boolean;
}

export const AutoCompleteItem = forwardRef<
  HTMLLIElement,
  AutoCompleteItemProps
>(({ children, active, className, ...props }, ref) => {
  const id = useId();
  return (
    <li
      ref={ref}
      className={clsx(autoCompleteListItemCss, className)}
      role="option"
      id={id}
      aria-selected={active}
      {...props}
    >
      {children}
    </li>
  );
});

AutoCompleteItem.displayName = "AutoCompleteItem";
