import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";

export const useRoleEntity = createUseEntityStoreEntity(EntityClass.Role);

export const getRoleEntity = createGetEntityStoreEntity(EntityClass.Role);
