import { debounce, values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";

import { currencyEntityType } from "../../../../lib/entities/currencyEntity";
import { domNodeType } from "../../../../lib/types/domTypes";
import {
  entityFieldDecimalType,
  entityIdType,
} from "../../../../lib/types/entityTypes";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import AddHeadingIcon from "../../../elements/Icons/AddHeadingIcon";
import AddItemIcon from "../../../elements/Icons/AddItemIcon";
import ListItemIcon from "../../Menu/ListItemIcon";
import ListItemText from "../../Menu/ListItemText";
import MenuItem from "../../Menu/MenuItem";
import PopoverMenu from "../../Menu/PopoverMenu";
import { ICON_SIZE } from "../../StyledIcon";
import AddExpenseSubMenu from "./AddExpenseSubMenu";
import AddFromJobPlanSubMenu from "./AddFromJobPlanSubMenu";
import AddItemSubMenu from "./AddItemSubMenu";

class AddLineMenu extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
      .isRequired,
    jobId: entityIdType.isRequired,
    rateCardId: entityIdType.isRequired,
    currency: currencyEntityType.isRequired,
    exchangeRate: entityFieldDecimalType.isRequired,
    negativeValue: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPickHeading: PropTypes.func.isRequired,
    onPickNewLine: PropTypes.func.isRequired,
    onPickItem: PropTypes.func.isRequired,
    onPickExpense: PropTypes.func.isRequired,
    onPickFromJobPlan: PropTypes.func.isRequired,
    PopoverProps: PropTypes.shape({
      anchorOrigin: PropTypes.shape({
        vertical: PropTypes.string,
        horizontal: PropTypes.string,
      }),
      transformOrigin: PropTypes.shape({
        vertical: PropTypes.string,
        horizontal: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    anchorEl: null,
    PopoverProps: null,
  };

  state = {
    activeSubMenuAnchorEl: null,
  };

  constructor(props) {
    super(props);

    this.toggleSubMenu = debounce((e, menuItemSelector) => {
      const { anchorEl } = this.props;
      const { activeSubMenuAnchorEl } = this.state;

      if (!anchorEl || !e || !menuItemSelector) {
        this.setState({
          activeSubMenuAnchorEl: null,
        });

        return;
      }

      if (
        !activeSubMenuAnchorEl ||
        !activeSubMenuAnchorEl.closest(menuItemSelector)
      ) {
        this.setState({
          activeSubMenuAnchorEl: e.target.closest(menuItemSelector),
        });
      }
    }, 200);
  }

  componentWillUnmount() {
    this.toggleSubMenu.cancel();
  }

  get itemSubMenuAnchorEl() {
    const { anchorEl } = this.props;
    const { activeSubMenuAnchorEl } = this.state;

    return (
      anchorEl &&
      activeSubMenuAnchorEl &&
      activeSubMenuAnchorEl.closest(".MenuItem-Item")
    );
  }

  get expenseSubMenuAnchorEl() {
    const { anchorEl } = this.props;
    const { activeSubMenuAnchorEl } = this.state;

    return (
      anchorEl &&
      activeSubMenuAnchorEl &&
      activeSubMenuAnchorEl.closest(".MenuItem-Expense")
    );
  }

  get jobPlanSubMenuAnchorEl() {
    const { anchorEl } = this.props;
    const { activeSubMenuAnchorEl } = this.state;

    return (
      anchorEl &&
      activeSubMenuAnchorEl &&
      activeSubMenuAnchorEl.closest(".MenuItem-JobPlan")
    );
  }

  close = () => {
    const { onClose } = this.props;

    this.setState({
      activeSubMenuAnchorEl: null,
    });

    onClose();
  };

  closeSubMenus = () => this.toggleSubMenu();

  openItemSubMenu = (e) => this.toggleSubMenu({ ...e }, ".MenuItem-Item");

  openExpenseSubMenu = (e) => this.toggleSubMenu({ ...e }, ".MenuItem-Expense");

  openJobPlanSubMenu = (e) => this.toggleSubMenu({ ...e }, ".MenuItem-JobPlan");

  handlePickHeading = () => {
    const { onPickHeading } = this.props;

    onPickHeading();

    this.close();
  };

  handlePickNewLine = () => {
    const { onPickNewLine } = this.props;

    onPickNewLine();

    this.close();
  };

  handlePickItem = (...args) => {
    const { onPickItem } = this.props;

    onPickItem(...args);

    this.close();
  };

  handlePickExpense = (...args) => {
    const { onPickExpense } = this.props;

    onPickExpense(...args);

    this.close();
  };

  handlePickFromJobPlan = (...args) => {
    const { onPickFromJobPlan } = this.props;

    onPickFromJobPlan(...args);

    this.close();
  };

  render() {
    const {
      anchorEl,
      commercialDocumentType,
      jobId,
      rateCardId,
      currency,
      exchangeRate,
      negativeValue,
      PopoverProps,
    } = this.props;

    return (
      <>
        <PopoverMenu anchorEl={anchorEl} onClose={this.close} {...PopoverProps}>
          <MenuItem
            onClick={this.handlePickHeading}
            onMouseEnter={this.closeSubMenus}
          >
            <ListItemIcon>
              <AddHeadingIcon size={ICON_SIZE.X_LARGE} />
            </ListItemIcon>
            <ListItemText>Add heading</ListItemText>
          </MenuItem>
          {commercialDocumentType !==
            COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && (
            <MenuItem
              hasSubMenu
              isSubMenuOpen={Boolean(this.jobPlanSubMenuAnchorEl)}
              className="MenuItem-JobPlan"
              onMouseEnter={this.openJobPlanSubMenu}
            >
              <ListItemIcon>
                <AddItemIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Add from job plan</ListItemText>
            </MenuItem>
          )}
          {commercialDocumentType !==
            COMMERCIAL_DOCUMENT_TYPES.PURCHASE_ORDER && (
            <MenuItem
              hasSubMenu
              isSubMenuOpen={Boolean(this.itemSubMenuAnchorEl)}
              className="MenuItem-Item"
              onMouseEnter={this.openItemSubMenu}
            >
              <ListItemIcon>
                <AddItemIcon size={ICON_SIZE.X_LARGE} />
              </ListItemIcon>
              <ListItemText>Add item</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            hasSubMenu
            isSubMenuOpen={Boolean(this.expenseSubMenuAnchorEl)}
            className="MenuItem-Expense"
            onMouseEnter={this.openExpenseSubMenu}
          >
            <ListItemIcon>
              <AddItemIcon size={ICON_SIZE.X_LARGE} />
            </ListItemIcon>
            <ListItemText>Add expense</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={this.handlePickNewLine}
            onMouseEnter={this.closeSubMenus}
          >
            <ListItemIcon>
              <AddItemIcon size={ICON_SIZE.X_LARGE} />
            </ListItemIcon>
            <ListItemText>Add blank item</ListItemText>
          </MenuItem>
        </PopoverMenu>
        {this.itemSubMenuAnchorEl && (
          <AddItemSubMenu
            anchorEl={this.itemSubMenuAnchorEl}
            rateCardId={rateCardId}
            currency={currency}
            exchangeRate={exchangeRate}
            negativeValue={negativeValue}
            onPick={this.handlePickItem}
            onMouseEnter={this.openItemSubMenu}
          />
        )}
        {this.expenseSubMenuAnchorEl && (
          <AddExpenseSubMenu
            anchorEl={this.expenseSubMenuAnchorEl}
            jobId={jobId}
            currency={currency}
            exchangeRate={exchangeRate}
            negativeValue={negativeValue}
            commercialDocumentType={commercialDocumentType}
            onPick={this.handlePickExpense}
            onMouseEnter={this.openExpenseSubMenu}
          />
        )}
        {this.jobPlanSubMenuAnchorEl && (
          <AddFromJobPlanSubMenu
            anchorEl={this.jobPlanSubMenuAnchorEl}
            jobId={jobId}
            currency={currency}
            exchangeRate={exchangeRate}
            onPick={this.handlePickFromJobPlan}
            onMouseEnter={this.openJobPlanSubMenu}
          />
        )}
      </>
    );
  }
}

export default AddLineMenu;
