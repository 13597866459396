import { isEmpty, mapValues, reduce, sum, toPairs, values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  getBlockHeight,
  getJobFullName,
  getJobId,
  getJobItemName,
  getMinutes,
  isComplete,
  isIncomplete,
  isPaused,
  scheduleLoggedTimeType,
} from "../../../lib/entities/scheduleLoggedTimeEntity";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectBaseScheduleLoggedTimesByUserIdDate } from "../../../redux/selectors/scheduleBlockSelectors";
import { selectJobListModelsFilteredIdsByUserId } from "../../../state/entities/jobListModel/selectors/selectJobListModelsFilteredIdsByUserId";
import { getZoomValue } from "../../../state/ui/schedule/lib";
import {
  selectScheduleUiViewPausedLoggedTimes,
  selectScheduleUiZoomLevel,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import Flex from "../../elements/Flex";
import Spacer from "../../elements/Spacer";
import Tooltip from "../../modules/Tooltip";
import TooltipContentTable from "../../modules/Tooltip/TooltipContentTable";

const mapState = (state, props) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
  includePaused: selectScheduleUiViewPausedLoggedTimes(state),
  baseScheduleLoggedTimes: selectBaseScheduleLoggedTimesByUserIdDate(
    state,
    props
  ),
  filteredJobIds: selectJobListModelsFilteredIdsByUserId(state, {
    userId: props.id,
  }),
});

class ScheduleUtilisationCells extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    date: PropTypes.string.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    filterMode: PropTypes.oneOf(values(ScheduleUiBlockFilterModes)).isRequired,
    blockFilter: PropTypes.func,
    zoomLevel: PropTypes.number.isRequired,
    includePaused: PropTypes.bool.isRequired,
    baseScheduleLoggedTimes: PropTypes.arrayOf(scheduleLoggedTimeType)
      .isRequired,
    filteredJobIds: entityIdListType.isRequired,
  };

  static defaultProps = {
    blockFilter: () => true,
  };

  get compact() {
    const { filterMode } = this.props;
    return filterMode !== ScheduleUiBlockFilterModes.UserTodos;
  }

  get filteredScheduleLoggedTimes() {
    const { blockFilter, baseScheduleLoggedTimes } = this.props;
    return baseScheduleLoggedTimes.filter(blockFilter);
  }

  get compactUtilisationMinutes() {
    return reduce(
      this.filteredScheduleLoggedTimes,
      (acc, scheduleLoggedTime) => {
        const paused = isPaused(scheduleLoggedTime);
        const minutes = getMinutes(scheduleLoggedTime);
        if (paused) acc.compactPausedMinutes += minutes;
        else acc.compactMinutes += minutes;
        return acc;
      },
      {
        compactMinutes: 0,
        compactPausedMinutes: 0,
      }
    );
  }

  get utilisationHeights() {
    const { filteredJobIds } = this.props;
    return reduce(
      this.filteredScheduleLoggedTimes,
      (acc, scheduleLoggedTime) => {
        const height = getBlockHeight(scheduleLoggedTime);
        const paused = isPaused(scheduleLoggedTime);
        const complete = isComplete(scheduleLoggedTime);
        const incomplete = isIncomplete(scheduleLoggedTime);
        const filtered = filteredJobIds.includes(getJobId(scheduleLoggedTime));

        if (filtered) {
          if (complete) acc.filteredCompleteHeight += height;
          else if (paused) acc.filteredPausedHeight += height;
          else if (incomplete) acc.filteredIncompleteHeight += height;
        } else if (complete) acc.unfilteredCompleteHeight += height;
        else if (paused) acc.unfilteredPausedHeight += height;
        else if (incomplete) acc.unfilteredIncompleteHeight += height;

        return acc;
      },
      {
        filteredCompleteHeight: 0,
        filteredIncompleteHeight: 0,
        filteredPausedHeight: 0,
        unfilteredCompleteHeight: 0,
        unfilteredIncompleteHeight: 0,
        unfilteredPausedHeight: 0,
      }
    );
  }

  renderTooltipTitle() {
    const { filterMode } = this.props;

    switch (filterMode) {
      case ScheduleUiBlockFilterModes.JobItemUserTodos:
      case ScheduleUiBlockFilterModes.UserJobNoItemTodos: {
        let todo = 0;
        let done = 0;

        this.filteredScheduleLoggedTimes.forEach((scheduleLoggedTime) => {
          if (isComplete(scheduleLoggedTime))
            done += getMinutes(scheduleLoggedTime);
          else if (isIncomplete(scheduleLoggedTime))
            todo += getMinutes(scheduleLoggedTime);
        });

        return (
          <TooltipContentTable
            contentArray={[
              ["To Do", convertMinutesToTimeHM(todo)],
              ["Done", convertMinutesToTimeHM(done)],
            ]}
          />
        );
      }
      case ScheduleUiBlockFilterModes.UserJobTodos: {
        const todo = {};
        const paused = {};
        const done = {};

        this.filteredScheduleLoggedTimes.forEach((scheduleLoggedTime) => {
          const jobItemName = getJobItemName(scheduleLoggedTime);
          const minutes = getMinutes(scheduleLoggedTime);
          const text = jobItemName || "no item";

          if (isComplete(scheduleLoggedTime))
            done[text] = (done[text] || 0) + minutes;
          else if (isPaused(scheduleLoggedTime))
            paused[text] = (paused[text] || 0) + minutes;
          else if (isIncomplete(scheduleLoggedTime))
            todo[text] = (todo[text] || 0) + minutes;
        });

        const contentArray = [];

        if (!isEmpty(todo))
          contentArray.push(
            ["To Do"],
            ...toPairs(mapValues(todo, convertMinutesToTimeHM))
          );

        if (!isEmpty(paused)) {
          if (contentArray.length) contentArray.push([<Spacer h={10} />]);
          contentArray.push(
            ["Paused"],
            ...toPairs(mapValues(paused, convertMinutesToTimeHM))
          );
        }

        if (!isEmpty(done)) {
          if (contentArray.length) contentArray.push([<Spacer h={10} />]);
          contentArray.push(
            ["Done"],
            ...toPairs(mapValues(done, convertMinutesToTimeHM))
          );
        }

        return <TooltipContentTable contentArray={contentArray} />;
      }
      case ScheduleUiBlockFilterModes.UserTodos:
      default: {
        const todo = {};
        const paused = {};
        const done = {};

        this.filteredScheduleLoggedTimes.forEach((scheduleLoggedTime) => {
          const jobName = getJobFullName(scheduleLoggedTime) || "Personal";
          const minutes = getMinutes(scheduleLoggedTime);

          if (isComplete(scheduleLoggedTime))
            done[jobName] = (done[jobName] || 0) + minutes;
          else if (isPaused(scheduleLoggedTime))
            paused[jobName] = (paused[jobName] || 0) + minutes;
          else if (isIncomplete(scheduleLoggedTime))
            todo[jobName] = (todo[jobName] || 0) + minutes;
        });

        const contentArray = [];

        if (!isEmpty(todo))
          contentArray.push(
            ["To Do"],
            ...toPairs(mapValues(todo, convertMinutesToTimeHM))
          );

        if (!isEmpty(paused)) {
          if (contentArray.length) contentArray.push([<Spacer h={10} />]);
          contentArray.push(
            ["Paused"],
            ...toPairs(mapValues(paused, convertMinutesToTimeHM))
          );
        }

        if (!isEmpty(done)) {
          if (contentArray.length) contentArray.push([<Spacer h={10} />]);
          contentArray.push(
            ["Done"],
            ...toPairs(mapValues(done, convertMinutesToTimeHM))
          );
        }

        return <TooltipContentTable contentArray={contentArray} />;
      }
    }
  }

  renderCompactCells() {
    const { compactUtilisationMinutes } = this;

    if (sum(values(compactUtilisationMinutes)) === 0) return null;

    const { compactMinutes, compactPausedMinutes } = compactUtilisationMinutes;

    return (
      <>
        {Boolean(compactMinutes) && (
          <Cell className="incomplete compact">
            {convertMinutesToTimeHM(compactMinutes, false, "h", false)}
          </Cell>
        )}
        {Boolean(compactPausedMinutes) && (
          <Cell className="incomplete paused compact">
            {convertMinutesToTimeHM(compactPausedMinutes, false, "h", false)}
          </Cell>
        )}
      </>
    );
  }

  renderHeightCells() {
    const { utilisationHeights } = this;
    const { zoomLevel, includePaused } = this.props;

    if (sum(values(utilisationHeights)) === 0) return null;

    const {
      filteredCompleteHeight,
      filteredIncompleteHeight,
      filteredPausedHeight,
      unfilteredCompleteHeight,
      unfilteredIncompleteHeight,
      unfilteredPausedHeight,
    } = utilisationHeights;

    return (
      <>
        {Boolean(filteredCompleteHeight) && (
          <Cell
            className="complete"
            style={{
              height: getZoomValue(filteredCompleteHeight, zoomLevel),
            }}
          />
        )}
        {Boolean(unfilteredCompleteHeight) && (
          <Cell
            className="complete unfiltered"
            style={{
              height: getZoomValue(unfilteredCompleteHeight, zoomLevel),
            }}
          />
        )}
        {Boolean(filteredIncompleteHeight) && (
          <Cell
            className="incomplete"
            style={{
              height: getZoomValue(filteredIncompleteHeight, zoomLevel),
            }}
          />
        )}
        {Boolean(unfilteredIncompleteHeight) && (
          <Cell
            className="incomplete unfiltered"
            style={{
              height: getZoomValue(unfilteredIncompleteHeight, zoomLevel),
            }}
          />
        )}
        {includePaused && Boolean(filteredPausedHeight) && (
          <Cell
            className="incomplete paused"
            style={{ height: getZoomValue(filteredPausedHeight, zoomLevel) }}
          />
        )}
        {includePaused && Boolean(unfilteredPausedHeight) && (
          <Cell
            className="incomplete paused unfiltered"
            style={{
              height: getZoomValue(unfilteredPausedHeight, zoomLevel),
            }}
          />
        )}
      </>
    );
  }

  render() {
    const { left, width } = this.props;

    if (this.filteredScheduleLoggedTimes.length === 0) return null;

    return (
      <Tooltip title={this.renderTooltipTitle()} placement="bottom">
        <CellWrapper
          style={{ left, width }}
          className={this.compact && "compact"}
        >
          {this.compact && this.renderCompactCells()}
          {!this.compact && this.renderHeightCells()}
        </CellWrapper>
      </Tooltip>
    );
  }
}

export default connect(mapState)(ScheduleUtilisationCells);

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  height: 100%;
`;

const Cell = styled(Flex)`
  margin-left: 1px;
  &.unfiltered {
    opacity: 0.6;
  }
  &.complete {
    background: var(--color-gray);
  }
  &.incomplete {
    background: white;
  }
  &.paused {
    border-top: 1px dashed var(--color-gray);
    border-bottom: 1px dashed var(--color-gray);
    opacity: 0.8;
    &.unfiltered {
      opacity: 0.4;
    }
  }
  &.compact {
    height: 28px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    align-items: center;
    padding: 7px 5px 5px;
    margin-bottom: 8px;
    &.paused {
      color: var(--color-gray-dark);
    }
  }
`;
