import { createIcon } from "../../icon/Icon";

export const IconStatusClosedGroupRoundFilled = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3494 9.24552L8.46249 7.83035H9.17007V5H14.8308V7.83035H19.0763V15.7907L17.6611 14.7293L17.647 14.7205L10.3596 9.25495L10.3494 9.24552ZM10.5852 7.83035H13.4156V6.41517H10.5852V7.83035Z"
    />
    <path d="M5.72498 7.54618L5.3491 7.26428L4.5 8.39642L5.5959 9.21834L5.59676 9.21719L18.6526 19.0091L19.5 17.8792L5.72413 7.54731L5.72498 7.54618Z" />
    <path d="M6.35769 11.5569L13.6323 17.0128L13.6515 17.029L15.5384 18.4442H4.92455V10.4838L6.33973 11.5452L6.35769 11.5569Z" />
  </>,
  "IconStatusClosedGroupRoundFilled"
);
