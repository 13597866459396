import styled from "styled-components";

import { JOB_TIMELINE_ITEM_ROW_HEIGHT } from "../../../lib/constants";
import { useJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import EmptyMessage from "../ScheduleLayout/EmptyMessage";
import JobTeamMemberRowsConsumer from "../ScheduleScrollProvider/JobTeamMemberRowsConsumer";
import ScheduleJobTeamMemberRoleGroup from "./ScheduleJobTeamMemberRoleGroup";
import ScheduleJobTeamMemberUserGroup from "./ScheduleJobTeamMemberUserGroup";

interface ScheduleJobTeamMembersGroupProps {
  id: number;
  offsetTop: number;
}

function ScheduleJobTeamMembersGroup({
  id,
  offsetTop,
}: ScheduleJobTeamMembersGroupProps) {
  const jobListModel = useJobListModel(id)!;

  if (jobListModel.users.length === 0 && jobListModel.roles.length === 0)
    return (
      <NoTeamMembersMessage>
        There are no users or roles assigned to this job
      </NoTeamMembersMessage>
    );

  return (
    <Wrapper>
      <JobTeamMemberRowsConsumer
        id={id}
        offsetTop={offsetTop}
        renderUser={(userId) => <ScheduleJobTeamMemberUserGroup id={userId} />}
        renderRole={(roleId, roleName) => (
          <ScheduleJobTeamMemberRoleGroup
            jobId={id}
            roleId={roleId}
            roleName={roleName}
          />
        )}
      />
    </Wrapper>
  );
}

export default ScheduleJobTeamMembersGroup;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - ${ScheduleUi.Job.RowHeight}px);
  padding-left: 30px;
  background: white;
  overflow: hidden;
`;

const NoTeamMembersMessage = styled(EmptyMessage)`
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  margin-left: 30px;
  padding: 7px 0;
`;
