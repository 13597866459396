import MuiListItemIcon, {
  ListItemIconProps as MuiListItemIconProps,
} from "@material-ui/core/ListItemIcon";
import MuiListItemText, {
  ListItemTextProps as MuiListItemTextProps,
} from "@material-ui/core/ListItemText";
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";
import clsx from "clsx";

import { Icon, IconProps, MdChevronRight } from "..";
import {
  listItemIconCss,
  listItemTextCss,
  menuItemCss,
  subMenuIconCss,
} from "./MenuItem.css";

export interface MenuItemProps extends Omit<MuiMenuItemProps, "button"> {
  hasSubMenu?: boolean;
  subMenuIconSize?: IconProps["size"];
  isSubMenuOpen?: boolean;
}

export function MenuItem({
  className,
  children,
  hasSubMenu = false,
  isSubMenuOpen = false,
  subMenuIconSize = 22,
  ...props
}: MenuItemProps) {
  return (
    <MuiMenuItem
      className={clsx(menuItemCss, className, isSubMenuOpen && "subMenuOpen")}
      {...props}
    >
      {children}
      {hasSubMenu && (
        <ListItemIcon className={subMenuIconCss}>
          <Icon icon={MdChevronRight} size={subMenuIconSize} />
        </ListItemIcon>
      )}
    </MuiMenuItem>
  );
}

export interface ListItemIconProps extends MuiListItemIconProps {}

export function ListItemIcon({ className, ...props }: ListItemIconProps) {
  return (
    <MuiListItemIcon className={clsx(listItemIconCss, className)} {...props} />
  );
}

export interface ListItemTextProps extends MuiListItemTextProps {}

export function ListItemText({ className, ...props }: ListItemTextProps) {
  return (
    <MuiListItemText
      className={clsx(listItemTextCss, className)}
      disableTypography={true}
      {...props}
    />
  );
}
