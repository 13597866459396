import { produce } from "immer";
import { put, select } from "redux-saga/effects";

import {
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_SET_TIME_ALLOCATION_METHOD,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";

function* setTimeAllocationMethod(action) {
  try {
    const { timeAllocationMethodId, jobItemId } = action.payload;

    const jobItem = yield select(selectJobItemById, { id: jobItemId });

    if (jobItem.timeAllocationMethod.id === timeAllocationMethodId) return;

    const newJobItem = produce(jobItem, (draft) => {
      draft.timeAllocationMethod.id = timeAllocationMethodId;
    });

    yield put(
      createAction(JOB_ITEM_EDIT_ITEM_CARD, {
        jobItemId,
        jobItem: newJobItem,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemUserSetTimeAllocationMethod() {
  yield takeLatestBy(
    [JOB_ITEM_SET_TIME_ALLOCATION_METHOD],
    setTimeAllocationMethod,
    (action) => action.payload.jobItemId
  );
}
