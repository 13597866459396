import { useRef } from "react";
import { useHover } from "st-shared/external/usehooks";

import { FocusProgressContainer } from "../components/FocusProgressContainer";
import { ItemPricingMethod } from "./ItemPricingMethod";
import ItemStatus from "./ItemStatus";
import { ItemTotal } from "./ItemTotal";
import {
  itemTotalContainerCss,
  itemTotalContainerInnerCss,
} from "./ItemTotalContainer.css";

export function ItemTotalContainer() {
  const ref = useRef<HTMLDivElement>(null);
  const isHovered = useHover(ref);

  return (
    <div ref={ref} className={itemTotalContainerCss}>
      <FocusProgressContainer className={itemTotalContainerInnerCss}>
        <ItemPricingMethod containerHovered={isHovered} />
        <ItemTotal />
      </FocusProgressContainer>
      <ItemStatus />
    </div>
  );
}
