import { put, select, takeLatest } from "redux-saga/effects";
import { INVOICE_SET_CONTACT, INVOICE_SAVE } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectContactById } from "../../selectors/contactSelectors";
import { selectInvoiceById } from "../../selectors/invoiceSelectors";

function* setContact(action) {
  try {
    const { id, contactId } = action.payload;

    const invoice = yield select(selectInvoiceById, { id });

    const contact = yield select(selectContactById, { id: contactId });

    yield put(
      createAction(INVOICE_SAVE, {
        id,
        prevInvoice: invoice,
        invoice: {
          ...invoice,
          contact
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchInvoiceSetContact() {
  yield takeLatest(INVOICE_SET_CONTACT, setContact);
}
