import { call, put, select } from "redux-saga/effects";
import { fetchJobBootstrapAPI } from "../../../lib/API/jobAPI";
import {
  ENTITIES_RECEIVED,
  SCHEDULE_JOB_BOOTSTRAP_FAILED,
  SCHEDULE_JOB_BOOTSTRAP_REQUEST,
  SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED,
  SCHEDULE_JOB_EXPAND
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectScheduleJobUi } from "../../selectors/scheduleSelectors";

function* fetchJobBootstrap(action) {
  try {
    const { jobId } = action.payload;

    const { isFetched = false } = yield select(
      selectScheduleJobUi,
      action.payload
    );

    if (!isFetched) {
      yield put(createAction(SCHEDULE_JOB_BOOTSTRAP_REQUEST, action.payload));

      const { data } = yield call(fetchJobBootstrapAPI, jobId);

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));
    }

    yield put(createAction(SCHEDULE_JOB_BOOTSTRAP_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(SCHEDULE_JOB_BOOTSTRAP_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchExpandJob() {
  yield takeLatestBy(
    [SCHEDULE_JOB_EXPAND],
    fetchJobBootstrap,
    action => action.payload.jobId
  );
}
