import React from "react";
import styled from "styled-components";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import withForwardedRef from "../../modules/withForwardedRef";

export default withForwardedRef(({ forwardedRef, ...props }) => (
  <JobTimelineScrollContext.Consumer>
    {context => (
      <Anchor
        style={{ left: context.anchorPositionX }}
        {...(forwardedRef && { ref: forwardedRef })}
        {...props}
      />
    )}
  </JobTimelineScrollContext.Consumer>
));

const Anchor = styled.div`
  position: absolute;
  height: 100%;
  width: 0;
`;
