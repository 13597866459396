import { values } from "lodash-es";
import * as PropTypes from "prop-types";

export const LINE_ITEM_ELEMENTS = {
  HEADING: "HEADING",
  ITEM: "ITEM",
  SUBTOTAL: "SUBTOTAL",
  ADD_LINE: "ADD_LINE"
};

export const lineItemElementShape = {
  type: PropTypes.oneOf(values(LINE_ITEM_ELEMENTS)).isRequired,
  id: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  isDraggable: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export const lineItemElementType = PropTypes.shape(lineItemElementShape);

export const getLineItemElementType = ({ type }) => type;

export const getLineItemElementKey = ({ type, id }) => `${type}-${id}`;
