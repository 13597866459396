import * as PropTypes from "prop-types";
import {
  INTERVAL_UNIT_ID_FORTNIGHTLY,
  INTERVAL_UNIT_ID_MONTHLY,
  INTERVAL_UNIT_ID_WEEKLY
} from "../constants";
import {
  getTodayDate,
  isAfter,
  maxDate,
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  getRelativeTimeText
} from "../dates";
import { FrameworkException } from "../exceptions/FrameworkException";
import { entityIdType } from "../types/entityTypes";

export const repeatingLoggedTimeShape = {
  id: entityIdType.isRequired,
  createdByUserId: entityIdType.isRequired,
  createdDatetime: PropTypes.string.isRequired,
  lastModifiedByUserId: entityIdType,
  lastModifiedDatetime: PropTypes.string,
  jobId: entityIdType,
  jobItemId: entityIdType,
  notes: PropTypes.string,
  title: PropTypes.string.isRequired,
  minutes: PropTypes.number.isRequired,
  intervalUnitId: entityIdType.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};

export const repeatingLoggedTimeType = PropTypes.shape(
  repeatingLoggedTimeShape
);

export const getJobId = ({ jobId }) => jobId;

export const getEndDate = ({ endDate }) => endDate;

export const getTitle = ({ title }) => title;

export const getRemainingInstances = ({
  startDate,
  endDate,
  intervalUnitId
}) => {
  const todayOrStartDate = maxDate([startDate, getTodayDate()]);
  switch (intervalUnitId) {
    case INTERVAL_UNIT_ID_WEEKLY:
      return differenceInCalendarWeeks(endDate, todayOrStartDate) + 1;
    case INTERVAL_UNIT_ID_FORTNIGHTLY:
      return differenceInCalendarWeeks(endDate, todayOrStartDate) / 2 + 1;
    case INTERVAL_UNIT_ID_MONTHLY:
      return differenceInCalendarMonths(endDate, todayOrStartDate) + 1;
    default:
      throw new FrameworkException(
        `Invalid Interval Unit Id: ${intervalUnitId}`
      );
  }
};

export const hasEnded = ({ endDate }) => isAfter(getTodayDate(), endDate);

export const hasBeenModified = ({ lastModifiedDatetime }) =>
  Boolean(lastModifiedDatetime);

export const getRelativeLastModifiedTime = ({ lastModifiedDatetime }) =>
  getRelativeTimeText(lastModifiedDatetime);

export const getRelativeCreatedTime = ({ createdDatetime }) =>
  getRelativeTimeText(createdDatetime);

export const mergeScheduleLoggedTimeWithRepeatingLoggedTime = (
  scheduleLoggedTime,
  repeatingLoggedTime
) => ({
  ...scheduleLoggedTime,
  repeatingLoggedTimeTitle: getTitle(repeatingLoggedTime)
});
