import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobPhaseStore } from "./JobPhase.store";

export * from "./selectors";

export const useJobPhaseEntity = createUseEntityStoreEntity(
  EntityClass.JobPhase
);

export const getJobPhaseEntity = createGetEntityStoreEntity(
  EntityClass.JobPhase
);

export const useJobPhase = createUseSingleEntity(JobPhaseStore);

export function useJobPhaseStore() {
  return JobPhaseStore.useStore();
}

export function useJobPhaseActions() {
  return JobPhaseStore.useActions();
}
