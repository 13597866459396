import type { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";

export function useRichTextEditorFocus(editor: Editor | null) {
  const [show, setShow] = useState(editor?.view?.hasFocus());

  useEffect(() => {
    let blurDebounceTimer: ReturnType<typeof setTimeout> | undefined;

    function onFocus() {
      clearTimeout(blurDebounceTimer);
      setShow(true);
    }

    function onBlur() {
      blurDebounceTimer = setTimeout(() => {
        setShow(false);
      }, 100);
    }

    editor?.on("focus", onFocus);
    editor?.on("blur", onBlur);

    return () => {
      clearTimeout(blurDebounceTimer);
      editor?.off("focus", onFocus);
      editor?.off("blur", onBlur);
    };
  }, [editor]);

  return show;
}
