import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { EmptyUserIcon } from "st-shared/module";

import { JOB_DETAILS_SET_CURRENT_FOCUS } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { getAvailabilityDates } from "../../../lib/dates";
import {
  getEstimatedEndDate,
  getEstimatedStartDate,
} from "../../../lib/entities/jobItemEntity";
import createAction from "../../../redux/helpers/createAction";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useRole } from "../../../redux/selectors/role";
import { actionJobItemRoleAssignUsers } from "../../../state/entities/jobItemRole/actions";
import { useJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { InlineTextButton } from "../../elements/Button";
import { AssignRoleMenu } from "../../modules/UserRoleMenus/AssignRoleMenu";
import { useJobItemRoleId } from "../context/JobItemRoleIdContext";
import * as styles from "./JobItemRoleName.css";

export function JobItemRoleName() {
  const jobItemRoleId = useJobItemRoleId();
  const jobItemRole = useJobItemRole(jobItemRoleId)!;
  const role = useRole(jobItemRole.roleId);

  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const jobItem = useJobItem(jobItemRole.jobItemId);
  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    getEstimatedStartDate(jobItem),
    getEstimatedEndDate(jobItem)
  );

  const dispatch = useDispatch();

  function openMenu() {
    setMenuOpen(true);
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: jobItemRole.jobItemId,
          jobItemRoleId: jobItemRole.id,
          key: FOCUS_KEYS.ITEM_ROLE_ASSIGN,
        },
      })
    );
  }

  function closeMenu() {
    setMenuOpen(false);
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: jobItemRole.jobItemId,
          key: FOCUS_KEYS.ITEM_GENERAL,
        },
      })
    );
  }

  function assignUsers(users: { userId: number; minutes: number }[]) {
    dispatch(actionJobItemRoleAssignUsers(jobItemRoleId, users, "jobItem"));
  }

  return (
    <>
      <div ref={ref} className={styles.container}>
        <EmptyUserIcon size={25} className={styles.icon} />
        <div className={styles.assign}>
          <InlineTextButton className={styles.button} onClick={openMenu}>
            Click to assign team members
          </InlineTextButton>
        </div>
        <div className={styles.blankIcon} />
        <div className={styles.name}>{role.name}</div>
      </div>
      {menuOpen && (
        <AssignRoleMenu
          anchorEl={ref.current}
          assignUsers={assignUsers}
          close={closeMenu}
          jobItemRoleId={jobItemRoleId}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      )}
    </>
  );
}
