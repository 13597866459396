import { isFunction } from "lodash-es";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { saveActivityEntryAPI } from "../../../lib/API/activityEntryAPI";
import {
  ACTIVITY_ENTRY_SAVE,
  ACTIVITY_ENTRY_SAVE_ERROR,
  ACTIVITY_ENTRY_SAVED,
  ACTIVITY_ENTRY_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectActivityEntryUi } from "../../selectors/activityEntrySelectors";

function* save(action) {
  const { activityEntry, mentionedUserIds, onSuccess } = action.payload;
  const { id } = activityEntry;

  try {
    yield put(createAction(ACTIVITY_ENTRY_SAVING, { activityEntry }));

    const { data } = yield call(
      saveActivityEntryAPI,
      activityEntry,
      mentionedUserIds
    );

    if (id > 0) {
      const { isDeleting } = yield select(selectActivityEntryUi, { id });

      if (isDeleting) return;
    }

    yield put(
      createAction(ACTIVITY_ENTRY_SAVED, {
        activityEntry,
        data
      })
    );

    if (isFunction(onSuccess)) yield call(onSuccess);
  } catch (error) {
    error.displayMessage = "Message could not be saved";
    error.action = ACTIVITY_ENTRY_SAVE_ERROR;
    error.mentionedUserIds = mentionedUserIds;

    yield put(
      createAction(ACTIVITY_ENTRY_SAVE_ERROR, {
        activityEntry,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchSave() {
  yield takeEvery(ACTIVITY_ENTRY_SAVE, save);
}
