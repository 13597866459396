import clsx from "clsx";

import {
  centerScreenCss,
  loader1Css,
  loader2Css,
  loadingSpinnerCss,
} from "./LoadingSpinner.css";

interface Props {
  className?: string;
  center?: boolean;
}

export function LoadingSpinner({ center, className }: Props) {
  return (
    <div
      className={clsx(loadingSpinnerCss, center && centerScreenCss, className)}
    >
      <div className={loader1Css} />
      <div className={loader2Css} />
    </div>
  );
}
