import clsx from "clsx";
import { IconBaseProps } from "react-icons";
import { MdStorage } from "react-icons/md";

import { itemIconCss } from "./styles.css";

export function StItem(props: IconBaseProps) {
  return (
    <MdStorage {...props} className={clsx(itemIconCss, props.className)} />
  );
}
