import clsx from "clsx";
import type { ReactNode } from "react";
import { RoleIconName } from "st-shared/module";
import { theme } from "st-shared/theme";

import * as styles from "./RoleOption.css";

type Props = {
  roleId: number;
  onPickRoles: (roleIds: number) => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  isSelected?: boolean;
  className?: string;
};

export function RoleOption({
  roleId,
  onPickRoles,
  children,
  isSelected,
  style,
  className,
}: Props) {
  function onClick() {
    onPickRoles(roleId);
  }

  return (
    <div
      onClick={onClick}
      className={clsx("rainbowHover", styles.container, className)}
      style={style}
    >
      <RoleIconName
        id={roleId}
        size={26}
        fontSize={12}
        color={theme.color.charcoal}
        className={styles.roleIcon}
        isSelected={isSelected}
      />
      {children}
    </div>
  );
}
