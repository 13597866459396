import * as PropTypes from "prop-types";
import { Icon } from "st-shared/components";
import { CostingMethods } from "st-shared/entities";
import styled from "styled-components";

import { domNodeType } from "../../../lib/types/domTypes";
import Menu from "../../modules/Menu";
import ListItemIcon from "../../modules/Menu/ListItemIcon";
import ListItemText from "../../modules/Menu/ListItemText";
import MenuItem from "../../modules/Menu/MenuItem";
import { getCostingMethodUiProperties } from "../consts/CostingMethodUiProperties";

const AddNewBlankItemSubMenu = ({ anchorEl, onSetItem }) => {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: -10,
      }}
    >
      {[
        CostingMethods.Item,
        CostingMethods.People,
        CostingMethods.ValueCalculatedSell,
      ].map((costingMethodId) => {
        const properties = getCostingMethodUiProperties(costingMethodId);
        return (
          <StyledMenuItem
            key={costingMethodId}
            onClick={() => onSetItem(costingMethodId)}
          >
            <StyledListItemIcon>
              <Icon icon={properties.icon} size={properties.size}></Icon>
            </StyledListItemIcon>
            <ListItemText>{properties.text}</ListItemText>
          </StyledMenuItem>
        );
      })}
    </StyledMenu>
  );
};

AddNewBlankItemSubMenu.propTypes = {
  anchorEl: domNodeType,
  onSetItem: PropTypes.func.isRequired,
};

AddNewBlankItemSubMenu.defaultProps = {
  anchorEl: null,
};

export default AddNewBlankItemSubMenu;

const StyledMenu = styled(Menu)`
  pointer-events: none;
  .MuiPaper-root {
    min-width: 200px;
    max-width: 500px;
    max-height: 300px;
    pointer-events: auto;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 12px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`;
