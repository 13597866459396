import { get } from "lodash-es";
import { createSelector } from "reselect";
import { ENTITY_NAME_PURCHASE_ORDERS } from "../../lib/constants";
import { selectPropsId, selectPropsPurchaseOrderId } from "./index";

export const selectPurchaseOrders = state =>
  state.entities[ENTITY_NAME_PURCHASE_ORDERS];

export const selectPurchaseOrderById = createSelector(
  selectPurchaseOrders,
  selectPropsId,
  (purchaseOrders, id) => get(purchaseOrders, `byId.${id}`)
);

export const selectPurchaseOrderByPurchaseOrderId = (state, props) =>
  selectPurchaseOrderById(state, {
    id: selectPropsPurchaseOrderId(state, props)
  });
