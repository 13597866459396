import { intersection } from "lodash-es";
import { useSelector } from "react-redux";

import { selectJobItemIdsByJobPhaseId } from "../jobItem/selectJobItemIdsByJobPhaseId";
import { selectUserIdsByJobItemId } from "../jobItemUser/selectUserIdsByJobItemId";

export function selectUserIdsInAllJobItemsByPhaseId(state, { jobPhaseId }) {
  const jobItemIds = selectJobItemIdsByJobPhaseId(state, { jobPhaseId });
  const userIdsByJobItemId = jobItemIds.map((jobItemId) =>
    selectUserIdsByJobItemId(state, { jobItemId })
  );
  return intersection(...userIdsByJobItemId);
}

export const useUserIdsInAllJobItemsByPhaseId = (jobPhaseId) =>
  useSelector((state) =>
    selectUserIdsInAllJobItemsByPhaseId(state, { jobPhaseId })
  );
