import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  JOB_DETAILS_ITEM_REORDER,
  JOB_DETAILS_PHASE_REORDER
} from "../../../../lib/constants";
import { extractId, isItem, isPhase } from "../../../../lib/sortingHelpers";
import createAction from "../../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPlanSortedIds } from "../../../../redux/selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";
import Spacer from "../../../elements/Spacer";
import Menu from "../../../modules/Menu";
import { useJobId } from "../../context/JobIdContext";
import ReorderItemMenuItem from "./ReorderItemMenuItem";
import ReorderPhaseMenuItem from "./ReorderPhaseMenuItem";

const ReorderItemsMenu = ({ anchorEl, closeMenu }) => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);

  const [sortedIds, , fullOrderJobPhases] = useJobPlanSortedIds(jobId);

  const dispatch = useDispatch();

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (isJobEditable && active && over && active.id !== over.id) {
      dispatch(
        createAction(
          isItem(activeId)
            ? JOB_DETAILS_ITEM_REORDER
            : JOB_DETAILS_PHASE_REORDER,
          {
            jobId,
            activeId: active.id,
            overId: over.id,
            sortedIds
          }
        )
      );
    }

    setActiveId(null);
  };

  let activePhaseItemCount = 0;
  let activeIds = sortedIds;
  if (activeId !== null && isPhase(activeId)) {
    for (let i = 0; i < fullOrderJobPhases.length; i += 1) {
      const { jobPhaseId, jobItemIds } = fullOrderJobPhases[i];
      if (extractId(activeId) === jobPhaseId) {
        activePhaseItemCount = jobItemIds.length;
        break;
      }
    }
    activeIds = [];
    let foundPhase = false;
    sortedIds.forEach(id => {
      if (isItem(id) && !foundPhase) activeIds.push(id);
      if (isPhase(id)) {
        activeIds.push(id);
        foundPhase = true;
      }
    });
  }

  return (
    <StyledMenu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 0
      }}
    >
      <MenuHeader>REORDER ITEMS AND PHASES</MenuHeader>
      <MenuItems>
        <DndContext
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={activeIds}
            strategy={verticalListSortingStrategy}
          >
            {fullOrderJobPhases.map(({ jobPhaseId, jobItemIds }, index) => {
              return (
                <React.Fragment key={`p_${jobPhaseId}`}>
                  {jobPhaseId !== 0 && (
                    <ReorderPhaseMenuItem
                      id={jobPhaseId}
                      jobItemIds={jobItemIds}
                      activeIsPhase={activeId !== null && isPhase(activeId)}
                    />
                  )}
                  {(activeId === null || isItem(activeId)) &&
                    jobItemIds.map(jobItemId => {
                      return (
                        <ReorderItemMenuItem
                          key={`i_${jobItemId}`}
                          id={jobItemId}
                        />
                      );
                    })}
                  {jobPhaseId === 0 &&
                    activeId !== null &&
                    isPhase(activeId) &&
                    jobItemIds.map(jobItemId => {
                      return (
                        <ReorderItemMenuItem
                          key={`i_${jobItemId}`}
                          id={jobItemId}
                        />
                      );
                    })}
                </React.Fragment>
              );
            })}
            <Spacer h={40 * activePhaseItemCount}></Spacer>
          </SortableContext>
        </DndContext>
      </MenuItems>
    </StyledMenu>
  );
};

ReorderItemsMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func.isRequired
};

ReorderItemsMenu.defaultProps = {
  anchorEl: null
};

export default ReorderItemsMenu;

const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    padding-right: 0 !important;
    width: 100% !important;
  }
`;

const MenuHeader = styled.div`
  width: 100%;
  height: 30px;
  line-height: 31px;
  padding-left: 15px;
  background-color: var(--color-gray-bright);
  color: var(--color-gray-dark);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  min-width: 250px;
  max-width: 400px;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;
