import { TEntityId } from "../../entities/Entity";
import { useUserEntity } from "../../stores";

interface Props {
  userId: TEntityId;
}

// TODO: to get rid of, this should be used where ever it needs doesnt need to be a function component
export function UserDisplayName({ userId }: Props) {
  const user = useUserEntity(userId);
  if (user) {
    return user.displayName;
  }
  return null;
}
