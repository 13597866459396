import type { Dictionary } from "@streamtimefe/types";
import type { AriaKey } from "@streamtimefe/ui/types";
import { uuidAlphaMedium } from "@streamtimefe/utils";
import { produce } from "immer";
import { isNumber, isObjectType } from "remeda";

import type { TEntity, TEntityId, TNullableEntity } from "./Entity";

export function apiSafeTransform<T extends TEntity, K extends keyof T>(
  entity: T,
  objectToIdKeys?: K[]
): TNullableEntity<T> {
  return produce(entity as TNullableEntity<TEntity>, (draft) => {
    if (isNumber(draft.id) && draft.id < 0) draft.id = null;
    objectToIdKeys?.forEach((key) => {
      if (key in entity && isObjectType(entity[key]) && "id" in entity[key]) {
        // @ts-expect-error deliberately setting the object as the id
        draft[key] = entity[key].id;
      }
    });
  }) as TNullableEntity<T>;
}

export function isNew<T extends TEntity>(entity: T) {
  return entity.id < 0;
}

export function arrayToMap<T extends TEntity>(
  entities: T[]
): Dictionary<TEntityId, T> {
  const map = new Map(entities.map((entity) => [entity.id, entity]));
  return Object.fromEntries(map);
}

export function id(id: number): TEntityId {
  return Number(id) as TEntityId;
}

export function idFromString(id: string): TEntityId {
  return Number(id) as TEntityId;
}

export function idFromKey(key: AriaKey): TEntityId {
  return Number(key) as TEntityId;
}

let currentTemporaryEntityId = -1;

export function temporaryId() {
  const id = currentTemporaryEntityId;
  currentTemporaryEntityId -= 1;
  return id as TEntityId;
}

export function create<T extends TEntity>(
  entity: Omit<T, "id" | "meta_data_sync_key">
) {
  return {
    ...entity,
    id: temporaryId(),
    meta_data_sync_key: uuidAlphaMedium(),
  } as T;
}
