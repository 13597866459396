import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { reactNodesType } from "../../../lib/types/reactTypes";
import { IconButton } from "../../elements/Button";
import Flex from "../../elements/Flex";
import CloseIcon from "../../elements/Icons/CloseIcon";

const DefaultWrapper = ({ hidden, onClose, children }) => {
  return (
    <Wrapper hidden={hidden}>
      {children}
      <CloseAction onClick={onClose}>
        <CloseIcon />
      </CloseAction>
    </Wrapper>
  );
};

DefaultWrapper.propTypes = {
  children: reactNodesType.isRequired,
  hidden: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DefaultWrapper;

const Wrapper = styled(Flex)`
  visibility: ${props => (props.hidden ? "hidden" : "visible")};
  overflow: "hidden";
  justify-content: space-between;
`;

const CloseAction = styled(IconButton)`
  justify-content: flex-end;
`;
