import type { TRuntimeEnv } from "./types";
import { preRuntimeEnvSchema, runtimeEnvSchema } from "./types";
import { formatEnvParsingErrors } from "./utils";

let env: TRuntimeEnv | undefined;

let parsed = false;

export function runtimeEnv() {
  if (typeof env === "undefined" && !parsed) {
    if (typeof window === "undefined") {
      // eslint-disable-next-line no-console
      console.error("Error: Cannot use runtimeEnv in the browser...");
    } else {
      let VITE_WEB_API_ROOT = import.meta.env.PROD ? "" : "https://localhost";
      let VITE_CLIENT_API_ROOT = import.meta.env.PROD
        ? ""
        : "https://localhost";

      const preParse = preRuntimeEnvSchema.safeParse(import.meta.env);
      if (!preParse.success) {
        // eslint-disable-next-line no-console
        console.error(
          "❌ Invalid runtime environment variables:\n\n",
          ...formatEnvParsingErrors(preParse.error)
        );
      } else {
        if (
          !import.meta.env.VITE_BUILD_ENV &&
          preParse.data.VITE_DEV_WEB_API_OVERRIDE
        ) {
          switch (preParse.data.VITE_DEV_WEB_API_OVERRIDE) {
            case "demo":
              VITE_WEB_API_ROOT = `https://app.demo.streamtime.net`;
              break;
            case "dev":
              VITE_WEB_API_ROOT = `https://app.dev.streamtime.net`;
              break;
          }
        }

        if (
          !import.meta.env.VITE_BUILD_ENV &&
          preParse.data.VITE_DEV_CLIENT_API_OVERRIDE
        ) {
          switch (preParse.data.VITE_DEV_CLIENT_API_OVERRIDE) {
            case "demo":
              VITE_CLIENT_API_ROOT = `https://client.demo.streamtime.net`;
              break;
            case "dev":
              VITE_CLIENT_API_ROOT = `https://client.dev.streamtime.net`;
              break;
          }
        }
      }

      const parse = runtimeEnvSchema.safeParse({
        ...import.meta.env,
        VITE_WEB_API_ROOT,
        VITE_CLIENT_API_ROOT,
      });
      if (!parse.success) {
        // eslint-disable-next-line no-console
        console.error(
          "❌ Invalid runtime environment variables:\n\n",
          ...formatEnvParsingErrors(parse.error)
        );
      } else {
        env = parse.data;
      }

      parsed = true;
    }
  }
  return env;
}
