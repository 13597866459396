import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectIsDependancyConflictById } from "../../../redux/selectors/jobItemDependancySelectors";

const mapState = (state, props) => ({
  isConflict: selectIsDependancyConflictById(state, props)
});

const IsJobItemDependancyConflictConnector = ({ isConflict, children }) =>
  children(isConflict);

IsJobItemDependancyConflictConnector.propTypes = {
  isConflict: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired
};

export default connect(mapState)(IsJobItemDependancyConflictConnector);
