// deprecated

import type { TUser } from "@streamtimefe/entities";

import { asString } from "../lib";
import type { SearchOption } from ".";

export function userObj(entity: TUser) {
  const obj = {
    entity,
    getAsOption(): SearchOption<TUser> {
      return {
        entity,
        key: entity.id,
        value: entity.displayName,
        searchString: obj.getSearchString(),
      };
    },
    getSearchString() {
      return [
        asString(entity.displayName),
        asString(entity.firstName),
        asString(entity.lastName),
        obj.getInitials(),
      ].join(" ");
    },
    getInitials() {
      return asString(entity.firstName)
        .substring(0, 1)
        .concat(asString(entity.lastName).substring(0, 1));
    },
    getWorkingHours() {
      return [
        Number(entity.hoursWorkedSunday),
        Number(entity.hoursWorkedMonday),
        Number(entity.hoursWorkedTuesday),
        Number(entity.hoursWorkedWednesday),
        Number(entity.hoursWorkedThursday),
        Number(entity.hoursWorkedFriday),
        Number(entity.hoursWorkedSaturday),
      ];
    },
    getWeekAvailableMinutes() {
      return obj.getWorkingHours().reduce((a, b) => a + b) * 60;
    },
  };
  return obj;
}
