import { produce } from "immer";
import { get, set, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { simpleCompareAsc } from "../../../../lib/dates";
import { getUserIdDateKeyPath } from "../../../../lib/entities/scheduleLoggedTimeEntity";

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath = prevEntity && getUserIdDateKeyPath(prevEntity);
      const newPath = newEntity && getUserIdDateKeyPath(newEntity);

      if (prevPath && prevPath !== newPath) pushOnce(touchedPaths, prevPath);

      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((path) => {
      const blockKeys = get(nextState.blockKeysByUserIdDate, path);

      if (!blockKeys) {
        unset(draft, `sortedBlockKeysByUserIdDate${path}`);
        return;
      }

      const nextSortedBlockKeys = blockKeys.slice().sort((keyA, keyB) => {
        const leftSort = simpleCompareAsc(
          nextState.byId[keyA].date,
          nextState.byId[keyB].date
        );
        if (leftSort !== 0) return leftSort;

        // Wider to shorter blocks
        const daysSort =
          nextState.daysByBlockKey[keyB] - nextState.daysByBlockKey[keyA];
        if (daysSort !== 0) return daysSort;

        // Taller to shorter blocks
        const minutesSort =
          nextState.byId[keyB].minutes - nextState.byId[keyA].minutes;
        if (minutesSort !== 0) return minutesSort;

        // key
        if (keyA < 0 && keyB < 0) return keyB - keyA;
        if (keyA < 0) return 1;
        if (keyB < 0) return -1;
        return keyA - keyB;
      });

      set(draft, `sortedBlockKeysByUserIdDate${path}`, nextSortedBlockKeys);
    });
  });
