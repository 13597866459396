import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsActivitySectionTab = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.activitySectionTab
);

export const useJobDetailsActivitySectionTab = () =>
  useSelector(selectJobDetailsActivitySectionTab);
