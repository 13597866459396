import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const DashboardIcon = props => (
  <SvgIcon {...props} viewBox="0 0 27.52 21.51">
    <path d="M25.29,6.07l-.21-.22-.22.23L23.11,8.64l.07.13A10,10,0,0,1,23,18.38H4.51A10,10,0,0,1,6,6.32,10.74,10.74,0,0,1,18.47,4.18l.12.06,2.95-1.9-.33-.21A14,14,0,0,0,4.29,3.69,13.12,13.12,0,0,0,1.83,20.07l.87,1.44H24.84l.82-1.44A13.05,13.05,0,0,0,25.29,6.07Z" />
    <path d="M10.79,13.36a2.87,2.87,0,0,0,.86,2,3,3,0,0,0,4.21,0l8.36-12.2L11.65,11.32A2.81,2.81,0,0,0,10.79,13.36Z" />
  </SvgIcon>
);

export default StyledIcon(DashboardIcon);
