import { JobItemRole } from "st-shared/entities";
import type { TEntityList } from "st-shared/types";

import API from "../../../lib/api";
import { getJobItemUserAsMessage } from "../../../lib/API/messages";
import { createModelSetParser } from "../../../lib/modelParser";
import { sendApplyModelData, sendRemoveModel } from "../../../lib/WebAppAPI";
import type { ModelSet } from "../types";
import { EntityClasses } from "../types";

async function create(jobItemRole: JobItemRole.Type) {
  const response = await API.post<ModelSet>(
    `/job_items/${jobItemRole.jobItemId}/job_item_roles`,
    JobItemRole.asApiPayload(jobItemRole)
  );
  sendApplyModelData(EntityClasses.JobItemRole, response.data);
  return createModelSetParser(EntityClasses.JobItemRole)(response.data);
}

async function _delete(jobItemRoleId: number) {
  if (jobItemRoleId <= 0) return Promise.resolve({} as TEntityList);

  const response = await API.delete<ModelSet>(
    `/job_item_roles/${jobItemRoleId}`
  );
  sendRemoveModel(EntityClasses.JobItemRole, jobItemRoleId);
  sendApplyModelData(EntityClasses.JobItemRole, response.data);
  return createModelSetParser(EntityClasses.JobItemRole)(response.data);
}

async function assignUsers(jobItemRoleId: number, jobItemUsers: any[]) {
  const response = await API.post<ModelSet>(
    `/job_item_roles/${jobItemRoleId}/job_item_users`,
    {
      job_item_users: jobItemUsers.map((jobItemUser) =>
        getJobItemUserAsMessage(jobItemUser)
      ),
    }
  );
  sendApplyModelData(EntityClasses.JobItemRole, response.data);
  return createModelSetParser(EntityClasses.JobItemRole)(response.data);
}

const JobItemRoleAPI = {
  create,
  delete: _delete,
  assignUsers,
};

export default JobItemRoleAPI;
