import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { costingMethodObj } from "st-shared/entities";

import { NUMERAL_OPTIONAL_CENTS } from "../../../lib/constants";
import {
  getSellRateNullable,
  getTotalPlannedMinutes,
  getTotalPlannedMoney,
} from "../../../lib/entities/jobItemEntity";
import { selectPropsJobItemId } from "../index";
import { selectJobItem } from "./index";

export const selectCalculatedSellRate = createCachedSelector(
  [selectJobItem],
  (jobItem) => {
    if (costingMethodObj(jobItem.costingMethod).isBasedByValue()) {
      if (getTotalPlannedMinutes(jobItem) > 0) {
        return numeral(getTotalPlannedMoney(jobItem))
          .divide(numeral(getTotalPlannedMinutes(jobItem)).divide(60).value())
          .format(NUMERAL_OPTIONAL_CENTS);
      }
      return null;
    }

    return getSellRateNullable(jobItem);
  }
)(selectPropsJobItemId);
