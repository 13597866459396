import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { asString } from "st-shared/lib";

import { INVOICE_ASSIGN_NUMBER } from "../../lib/constants";
import { entityIdType } from "../../lib/types/entityTypes";
import { reactNodesType } from "../../lib/types/reactTypes";
import { commercialDocumentEntityType } from "../../lib/ui/commercialDocument";
import createAction from "../../redux/helpers/createAction";
import consumeCommercialDocument from "../modules/CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";
import LanguageOption from "../modules/CommercialDocuments/LanguageOption";
import DetailTableField from "../modules/CommercialDocuments/Layout/DetailTableField";
import DetailTableFieldButton from "../modules/CommercialDocuments/Layout/DetailTableFieldButton";
import DetailTableLabel from "../modules/CommercialDocuments/Layout/DetailTableLabel";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doSaveDocument },
}) => ({
  id,
  document,
  getValue: documentMethods.getNumber,
  setValue: documentMethods.setNumber,
  labelKey: "number",
  doSaveDocument,
});

const mapDispatch = (dispatch) => ({
  doAssignInvoiceNumber: (invoiceId) =>
    dispatch(createAction(INVOICE_ASSIGN_NUMBER, { invoiceId })),
});

class InvoiceNumberRow extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    isDisplayed: PropTypes.bool,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    labelKey: reactNodesType.isRequired,
    doSaveDocument: PropTypes.func.isRequired,
    doAssignInvoiceNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDisplayed: true,
  };

  static getDerivedStateFromProps(props, state) {
    const { document, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: asString(getValue(document)),
    };
  }

  state = {
    value: "",
    hasFocus: false,
  };

  save = (value) => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;

    if (value !== getValue(document))
      doSaveDocument(id, setValue(document, value), document);
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleBlur = () => {
    const { value } = this.state;

    this.save(value);

    this.setState({
      hasFocus: false,
    });
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  handleAssignNumber = (e) => {
    const { id, doAssignInvoiceNumber } = this.props;
    doAssignInvoiceNumber(id);
  };

  render() {
    const { isDisplayed, labelKey, getValue, document } = this.props;
    const { value } = this.state;

    const showAssignButton =
      getValue(document) === null || getValue(document) === "";

    return (
      <tr className={isDisplayed ? "" : "hiddenField"}>
        <th>
          <DetailTableLabel>
            <LanguageOption optionKey={labelKey} />
          </DetailTableLabel>
        </th>
        <td>
          {showAssignButton ? (
            <DetailTableFieldButton onClick={this.handleAssignNumber}>
              Assign
            </DetailTableFieldButton>
          ) : (
            <DetailTableField
              value={value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            />
          )}
        </td>
      </tr>
    );
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(null, mapDispatch)(InvoiceNumberRow)
);
