import { ThemeProvider } from "@material-ui/core";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";

import { getStartOfWeek, getTodayDate, toDate } from "../../lib";
import { Button } from "../buttons/Button";
import { pickerTheme } from "./pickerTheme";
import { Popover, PopoverProps } from "./Popover";
import { actionButtonCss, actionsCss } from "./PopoverDatePicker.css";

export interface PopoverDatePicker {
  DatePickerProps: DatePickerProps;
  PopoverProps: PopoverProps;
  actionButton?: "clear" | "today" | "thisWeek";
}

export function PopoverDatePicker({
  DatePickerProps,
  PopoverProps,
  actionButton,
}: PopoverDatePicker) {
  const { onChange, ...datePickerProps } = DatePickerProps;

  function onDateChange(date: string) {
    onChange(toDate(date));
  }

  function onClear() {
    onChange(null);
  }

  function goToThisWeek() {
    onChange(getStartOfWeek());
  }

  function todaysDate() {
    onChange(getTodayDate());
  }

  return (
    <Popover {...PopoverProps}>
      <ThemeProvider theme={pickerTheme}>
        <DatePicker
          disableToolbar
          variant="static"
          leftArrowButtonProps={{ size: "small" }}
          rightArrowButtonProps={{ size: "small" }}
          onChange={onDateChange}
          {...datePickerProps}
        />
      </ThemeProvider>
      <div className={actionsCss}>
        {actionButton === "thisWeek" && (
          <Button className={actionButtonCss} onClick={goToThisWeek}>
            Show this Week
          </Button>
        )}
        {actionButton === "clear" && (
          <Button className={actionButtonCss} onClick={onClear}>
            Clear
          </Button>
        )}
        {actionButton === "today" && (
          <Button className={actionButtonCss} onClick={todaysDate}>
            Today
          </Button>
        )}
      </div>
    </Popover>
  );
}
