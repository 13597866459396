import { Entity } from "@streamtimefe/entities";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { costingMethodObj, JobItemRole } from "st-shared/entities";

import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { selectJob } from "../../../../redux/selectors/job";
import { selectJobItem } from "../../../../redux/selectors/jobItem";
import { selectRoleRateByRoleIdRateCardId } from "../../../../redux/selectors/rate/selectRoleRateByRoleIdRateCardId";
import { actionEntitiesReceived, actionEntitiesRemoved } from "../../actions";
import { EntityNames } from "../../types";
import type { JobItemRoleCreateAction } from "../actions";
import { actionJobItemRoleEdit, JOB_ITEM_ROLE_CREATE } from "../actions";
import JobItemRoleAPI from "../api";

function* create(action: JobItemRoleCreateAction) {
  const createId = Entity.temporaryId();

  try {
    const jobItem: any = yield select(selectJobItem, {
      jobItemId: action.jobItemId,
    });

    let jobCurrencySellRate = "0.00";

    if (costingMethodObj(jobItem.costingMethod).isBasedByUser()) {
      const job: any = yield select(selectJob, { jobId: jobItem.jobId });

      const rate = yield select(selectRoleRateByRoleIdRateCardId, {
        roleId: action.roleId,
        rateCardId: job.rateCardId,
      });

      if (rate) jobCurrencySellRate = rate;
    } else {
      jobCurrencySellRate = jobItem.jobCurrencySellRate;
    }

    const newJobItemRole = JobItemRole.createJobItemRole(
      createId,
      action.jobItemId,
      action.roleId,
      jobCurrencySellRate
    );

    yield put(
      actionEntitiesReceived({
        [EntityNames.JobItemRoles]: [newJobItemRole],
      })
    );

    if (action.save === "jobItem") {
      yield jobItemSave(action.jobItemId, newJobItemRole);
    } else if (action.save === "api") {
      yield apiSave(newJobItemRole);
    }
  } catch (error) {
    sagaError(error);
  }
}
export const createJobItemRoleGenerator = create;

function* jobItemSave(jobItemId: number, jobItemRole: JobItemRole.Type) {
  yield put(actionJobItemRoleEdit(jobItemId, jobItemRole));
}

function* apiSave(jobItemRole: JobItemRole.Type) {
  try {
    const data: Awaited<ReturnType<typeof JobItemRoleAPI.create>> = yield call(
      JobItemRoleAPI.create,
      jobItemRole
    );

    yield put(actionEntitiesReceived(data));

    yield put(
      actionEntitiesRemoved({
        entityName: EntityNames.JobItemRoles,
        ids: [jobItemRole.id],
      })
    );
  } catch (error) {
    yield put(
      actionEntitiesRemoved({
        entityName: EntityNames.JobItemRoles,
        ids: [jobItemRole.id],
      })
    );

    sagaError(error);
  }
}

export default function* watchJobItemRoleCreate() {
  yield takeEvery(JOB_ITEM_ROLE_CREATE, create);
}
