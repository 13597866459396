import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const MoneyIcon = props => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 16.2C5.04 16.2 1.8 12.96 1.8 9C1.8 5.04 5.04 1.8 9 1.8C12.96 1.8 16.2 5.04 16.2 9C16.2 12.96 12.96 16.2 9 16.2ZM12.2166 10.98C12.2166 9.3042 10.8054 8.5986 9.5454 8.1828L9.3564 8.1198V5.1714C10.0872 5.2344 10.7046 5.5242 11.259 6.0534L11.8512 5.121C11.1771 4.47478 10.2895 4.0982 9.3564 4.0626V3.672H8.739V4.0626C7.101 4.1886 6.03 5.2344 6.03 6.7338C6.03 8.2458 7.0632 8.8506 8.3862 9.279L8.739 9.3924V12.5046C7.94824 12.4677 7.19894 12.1401 6.6348 11.5848L6.1182 12.5046C6.7734 13.185 7.7184 13.563 8.739 13.6134V14.0292H9.3564V13.6134C11.0826 13.5 12.2166 12.4794 12.2166 10.98V10.98ZM7.3656 6.5322C7.3656 5.8014 7.8948 5.2974 8.739 5.184V7.9434C7.983 7.6788 7.3656 7.3512 7.3656 6.5322ZM9.3564 12.492V9.5562C10.3896 9.9468 10.881 10.3752 10.881 11.106C10.881 11.8746 10.314 12.3786 9.3564 12.492Z" />
  </SvgIcon>
);

export default StyledIcon(MoneyIcon);
