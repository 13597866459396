import { reduce } from "lodash-es";
import numeral from "numeral";
import { selectJobItemUserCalculatedTotalLoggedTimeExTax } from "../jobItemUser/selectJobItemUserCalculatedTotalLoggedTimeExTax";
import { selectJobItemUserIdsByJobItemId } from "../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectJobItem } from "./index";

export const selectJobItemCalculatedTotalLoggedTimeExTax = (
  state,
  { jobItemId, includeNonBillable = true }
) => {
  const jobItem = selectJobItem(state, { jobItemId });
  const jobItemUsers = selectJobItemUserIdsByJobItemId(state, { jobItemId });

  if (!includeNonBillable && !jobItem.isBillable) return 0;

  return reduce(
    jobItemUsers,
    (total, jobItemUserId) =>
      numeral(total)
        .add(
          numeral(
            selectJobItemUserCalculatedTotalLoggedTimeExTax(state, {
              jobItemId,
              jobItemUserId
            })
          ).value()
        )
        .value(),
    0
  );
};
