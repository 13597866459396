import clsx from "clsx";
import { Icon, IconType, MdChevronRight } from "st-shared/components";
import { pluralise } from "st-shared/lib";
import { theme } from "st-shared/theme";

import {
  expanderIconCss,
  filtersAndColumnsExpanderCss,
  filtersAndColumnsText,
} from "./FiltersAndColumnsExpander.css";

type Props = {
  length: number;
  open: boolean;
  toggleOpen: () => void;
  openAddMenu: () => void;
  text: string;
  icon: IconType;
  emptyTextOverride?: string;
};

export function FiltersAndColumnsExpander({
  length,
  open,
  toggleOpen,
  openAddMenu,
  text,
  icon,
  emptyTextOverride,
}: Props) {
  const displayText =
    length === 0
      ? emptyTextOverride || `No ${pluralise(0, text)}`
      : `${length} ${pluralise(length, text)}`;

  return (
    <div
      className={clsx(filtersAndColumnsExpanderCss)}
      onClick={length > 0 ? toggleOpen : openAddMenu}
    >
      <Icon icon={icon} color={theme.color.graydark} />
      <span className={filtersAndColumnsText}>{displayText}</span>
      {length > 0 && (
        <Icon
          className={open ? expanderIconCss["open"] : expanderIconCss["close"]}
          icon={MdChevronRight}
        />
      )}
    </div>
  );
}
