import { find } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  accountingPlatformStatusType,
  getAccountName,
  accountType
} from "../../../lib/ui/accountingPlatform";
import {
  selectAccountingPlatformRevenueAccounts,
  selectAccountingPlatformStatus
} from "../../../redux/selectors/accountingPlatformSelectors";
import { InlineDotPulseIcon } from "../../elements/Icons/DotPulseIcon";
import consumeCommercialDocument from "../CommercialDocuments/CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({ branchId }) => ({
  branchId
});

const mapState = (state, props) => ({
  accountingPlatformStatus: selectAccountingPlatformStatus(state, props),
  revenueAccounts: selectAccountingPlatformRevenueAccounts(state, props)
});

class RevenueAccountName extends React.PureComponent {
  static propTypes = {
    accountCode: PropTypes.string,
    accountingPlatformStatus: accountingPlatformStatusType.isRequired,
    revenueAccounts: PropTypes.arrayOf(accountType).isRequired
  };

  static defaultProps = {
    accountCode: ""
  };

  get isActive() {
    const { accountingPlatformStatus } = this.props;
    const { isActive } = accountingPlatformStatus;
    return isActive;
  }

  render() {
    if (!this.isActive) return null;

    const {
      accountingPlatformStatus,
      accountCode,
      revenueAccounts
    } = this.props;

    if (!accountCode) return null;

    const { isFetching, isDisconnected } = accountingPlatformStatus;

    if (isFetching) return <InlineDotPulseIcon />;

    if (isDisconnected) return null;

    const revenueAccount = find(revenueAccounts, { id: accountCode });

    if (!revenueAccount) return null;

    return getAccountName(revenueAccount);
  }
}

export default consumeCommercialDocument(mapContext)(
  connect(mapState)(RevenueAccountName)
);
