import clsx from "clsx";

import type { TEntityId } from "../../entities/Entity";
import { useUserEntity } from "../../stores";
import { UserIcon } from ".";
import { PrimaryUserDisplayName } from "./PrimaryUserDisplayName";
import { PrimaryUserRoleName } from "./PrimaryUserRoleName";
import * as styles from "./UserIconName.css";

interface Props {
  id: TEntityId;
  size?: number;
  fontSize?: number;
  color?: string;
  gap?: number;
  showDisplayName?: boolean;
  showRole?: boolean;
  nameWidth?: string | number;
  className?: string;
}

export function UserIconName({
  id,
  size = 30,
  fontSize = 18,
  color = "black",
  gap = 10,
  showDisplayName = true,
  showRole,
  nameWidth = `calc(100% - ${size + gap}px)`,
  className,
}: Props) {
  const user = useUserEntity(id);
  return (
    <div
      className={clsx(styles.userIconNameContainer, className)}
      style={{ height: size }}
    >
      <UserIcon
        id={id}
        name={user.displayName}
        size={size}
        disableTooltip={!showDisplayName}
      />
      <div
        className={styles.userIconNameTextContainer}
        style={{ marginLeft: gap, width: nameWidth }}
      >
        {showDisplayName && (
          <PrimaryUserDisplayName
            userId={id}
            fontSize={fontSize}
            color={color}
          />
        )}
        {showRole && (
          <PrimaryUserRoleName
            userId={id}
            fontSize={fontSize - 2}
            color={color}
          />
        )}
      </div>
    </div>
  );
}
