import { produce } from "immer";
import { get, reduce, setWith } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import {
  getBlockHeight,
  getJobItemUserIdIfBaseLoggedTime,
  getJobItemUserIdKeyPath,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getMaxBlockHeight = (state, blockKeys) =>
  reduce(
    blockKeys,
    (max, key) => {
      const scheduleLoggedTime = state.byId[key];
      const height = getBlockHeight(scheduleLoggedTime);
      const offsetY = get(state, `offsetYByBlockKeyForJobItemUsers.${key}`, 0);
      return Math.max(max, height + offsetY);
    },
    0
  );

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath =
        getJobItemUserIdIfBaseLoggedTime(prevEntity) &&
        getJobItemUserIdKeyPath(prevEntity);
      const newPath =
        getJobItemUserIdIfBaseLoggedTime(newEntity) &&
        getJobItemUserIdKeyPath(newEntity);

      if (prevPath) pushOnce(touchedPaths, prevPath);
      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((keyPath) => {
      const blockKeys = get(nextState.blockKeysByJobItemUserId, keyPath, []);

      setWith(
        draft,
        `maxBlockHeightByJobItemUserId.${keyPath}`,
        getMaxBlockHeight(nextState, blockKeys, false),
        Object
      );
    });
  });
