import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";
import { each, find, isNumber } from "lodash-es";
import moment from "moment/moment";
import type { ReactNode } from "react";
import type { ChartTooltipProps } from "st-shared/components/charts/renderChartTooltip";
import type { Currency } from "st-shared/entities/Currency";
import { formatForeignCurrency } from "st-shared/lib";

import {
  DATE_FORMAT_DATE_MONTH_YEAR,
  NUMBER_FORMAT_NO_DECIMAL,
} from "../../../../lib/constants";
import { JOB_BURN_UP_MODE } from "../../../../lib/constants/jobDetails";
import {
  getEstimatedEndDate,
  getTotalPlannedMinutes,
} from "../../../../lib/entities/jobEntity";
import { preciseDecimals } from "../../../../lib/math";
import { convertMinutesToTimeHM } from "../../../../lib/time";

interface Props extends ChartTooltipProps<"line"> {
  highestUsedDataPoint: number;
  job: any;
  burnUpMode: any;
  customerCurrency: Currency;
  jobCurrency: Currency;
}

function JumbotronChartTooltip({
  tooltip,
  highestUsedDataPoint,
  job,
  burnUpMode,
  customerCurrency,
  jobCurrency,
}: Props) {
  if (!tooltip.body) return null;

  function formatValue(value: number) {
    if (burnUpMode === JOB_BURN_UP_MODE.USED_PLANNED_HOURS) {
      return convertMinutesToTimeHM(convertFlexibleDurationToMinutes(value)!);
    }
    return formatForeignCurrency(customerCurrency, {
      value,
      currency: jobCurrency,
      format: NUMBER_FORMAT_NO_DECIMAL,
    });
  }

  function generateLine(
    label: string,
    value: number,
    iconClass: string,
    extraText: ReactNode = null
  ) {
    return (
      <div>
        <div className={`icon ${iconClass}`}></div>
        <span className="label">{label}</span>
        <span>
          {" "}
          {formatValue(value)}
          {extraText}
        </span>
      </div>
    );
  }

  const lines: ReactNode[] = [];

  const tooltipDate = moment(tooltip.title[0], false);

  // Let's add the most relevant used data point to the tooltip
  const usedDataPoint = find(tooltip.dataPoints, {
    datasetIndex: 0,
  });
  const optimalDataPoint = find(tooltip.dataPoints, {
    datasetIndex: 2,
  });

  let usedDataValue: number | undefined;
  let optimalDataValue: number | undefined;

  if (usedDataPoint) {
    usedDataValue = usedDataPoint.raw as number;
  } else if (tooltipDate.isAfter(getEstimatedEndDate(job))) {
    usedDataValue = highestUsedDataPoint;
  }

  if (optimalDataPoint) {
    optimalDataValue = optimalDataPoint.raw as number;
  } else if (tooltipDate.isAfter(getEstimatedEndDate(job))) {
    optimalDataValue = preciseDecimals(getTotalPlannedMinutes(job) / 60);
  }

  if (usedDataValue) {
    let extraText = null;
    if (optimalDataValue) {
      const displayValue = Math.round(
        Math.abs(usedDataValue - optimalDataValue)
      );

      if (usedDataValue > optimalDataValue) {
        extraText = (
          <span className="warning"> ({formatValue(displayValue)} over)</span>
        );
      } else if (optimalDataValue > usedDataValue) {
        extraText = (
          <span className="success"> ({formatValue(displayValue)} under)</span>
        );
      }
    }

    let tooltipLabel = "";
    switch (burnUpMode) {
      case JOB_BURN_UP_MODE.USED_PLANNED_HOURS:
      case JOB_BURN_UP_MODE.USED_PLANNED_SELL:
        tooltipLabel = "Used";
        break;
      case JOB_BURN_UP_MODE.USED_PLANNED_COST:
        tooltipLabel = "Cost";
        break;
    }

    lines.push(generateLine(tooltipLabel, usedDataValue, "used", extraText));
  }

  each(tooltip.dataPoints, (dataPoint) => {
    if (dataPoint.datasetIndex === 1) {
      if (dataPoint.raw !== highestUsedDataPoint) {
        lines.push(
          generateLine(
            "To Do",
            Number(dataPoint.raw) - highestUsedDataPoint,
            "planned"
          )
        );
      }
    }
  });

  if (isNumber(optimalDataValue)) {
    let tooltipLabel = "";
    switch (burnUpMode) {
      case JOB_BURN_UP_MODE.USED_PLANNED_HOURS:
      case JOB_BURN_UP_MODE.USED_PLANNED_SELL:
        tooltipLabel = "Planned";
        break;
      case JOB_BURN_UP_MODE.USED_PLANNED_COST:
        tooltipLabel = "Planned Sell";
        break;
    }

    lines.push(generateLine(tooltipLabel, optimalDataValue, "optimal"));
  } else if (getTotalPlannedMinutes(job) > 0) {
    lines.push(
      generateLine(
        "Planned",
        preciseDecimals(getTotalPlannedMinutes(job) / 60),
        "optimal"
      )
    );
  }

  return (
    <>
      <div className="title">
        Cumulative to {tooltipDate.format(DATE_FORMAT_DATE_MONTH_YEAR)}
      </div>
      {lines}
    </>
  );
}

export default JumbotronChartTooltip;
