import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobPhaseId } from "../../index";

export const selectStateJobPhasesUi = state => state.ui.jobPhases;

export const selectJobPhaseUi = createCachedSelector(
  [selectStateJobPhasesUi, selectPropsJobPhaseId],
  (jobPhases, jobPhaseId) => jobPhases[jobPhaseId]
)(selectPropsJobPhaseId);

export const useJobPhaseUi = jobPhaseId =>
  useSelector(state => selectJobPhaseUi(state, { jobPhaseId }));
