import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE,
  DATA_ATTRIBUTE_USER,
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectBlockContainerHeightByUserId } from "../../../redux/selectors/scheduleSelectors";
import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";
import { isMaxZoomLevel } from "../../../state/ui/schedule/lib";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { ScheduleUiBlockFilterModes } from "../../../state/ui/schedule/types";
import ScheduleBlock from "../ScheduleBlocks/ScheduleBlock";
import ScheduleBlocksContainer from "../ScheduleLayout/ScheduleBlocksContainer";
import ScheduleBlocksWrapper from "../ScheduleLayout/ScheduleBlocksWrapper";
import ScheduleUtilisation from "../ScheduleLayout/ScheduleUtilisation";
import Anchor from "../ScheduleScrollProvider/Anchor";
import { BlocksByUserIdConsumer } from "../ScheduleScrollProvider/BlockConsumer";

const attrUserId = getAttrPropString(DATA_ATTRIBUTE_USER);
const attrScheduleFilterMode = getAttrPropString(
  DATA_ATTRIBUTE_SCHEDULE_FILTER_MODE
);

const mapState = (state, props) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
  isCollapsed: selectIsScheduleUserCollapsed(state, props),
  blocksHeight: selectBlockContainerHeightByUserId(state, props),
});

const mapDispatch = null;

class ScheduleUserTodos extends React.PureComponent {
  filterMode = ScheduleUiBlockFilterModes.UserTodos;

  static propTypes = {
    id: entityIdType.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    blocksHeight: PropTypes.number.isRequired,
  };

  get overflowStyles() {
    const { isCollapsed } = this.props;
    if (isCollapsed) return { overflow: "hidden" };
    return { animation: "overflow-visible 1s" };
  }

  render() {
    const { id, zoomLevel, blocksHeight, isCollapsed } = this.props;

    if (isCollapsed) return null;

    const pointerEventAttrs = {
      [attrUserId]: id,
      [attrScheduleFilterMode]: this.filterMode,
    };

    return (
      <ScheduleBlocksWrapper
        style={{ height: blocksHeight, ...this.overflowStyles }}
      >
        {isMaxZoomLevel(zoomLevel) ? (
          <ScheduleBlocksContainer {...pointerEventAttrs}>
            <Anchor>
              <BlocksByUserIdConsumer id={id}>
                {(blockKey) => (
                  <ScheduleBlock key={blockKey} blockKey={blockKey} />
                )}
              </BlocksByUserIdConsumer>
            </Anchor>
          </ScheduleBlocksContainer>
        ) : (
          <ScheduleUtilisation
            id={id}
            {...pointerEventAttrs}
            filterMode={this.filterMode}
          />
        )}
      </ScheduleBlocksWrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleUserTodos);
