import { useSelector } from "react-redux";

import { selectJobItemUser } from "./index";
import { selectJobItemUserIdsByJobItemId } from "./selectJobItemUserIdsByJobItemId";

export const selectJobItemUsersByJobItemId = (state, { jobItemId }) => {
  const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, { jobItemId });
  if (!jobItemUserIds) return null;
  return jobItemUserIds.map((jobItemUserId) =>
    selectJobItemUser(state, { jobItemUserId })
  );
};

export function useJobItemUsersByJobItemId(jobItemId) {
  return useSelector((state) =>
    selectJobItemUsersByJobItemId(state, { jobItemId })
  );
}
