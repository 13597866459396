import styled from "styled-components";

import { convertMinutesToTimeHM } from "../../../../lib/time";
import { ScheduleUi } from "../../../../state/ui/schedule/types";
import { JobCurrencyConversionInfo } from "../../../modules/Currency/JobCurrencyConversionInfo";

export const TotalText = styled.div`
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 2px 0 10px;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.over &&
    `
    color: var(--color-red-dark);
  `}
`;

export const formatTotalHours = (value, round = false) =>
  convertMinutesToTimeHM(value, false, round ? "h" : null);

export const getTooltipExchangeRateInfo = (jobId) => (
  <JobCurrencyConversionInfo
    id={jobId}
    prefix={
      <>
        <br />
        Using exchange rate{" "}
      </>
    }
  />
);

export default styled.div`
  width: ${ScheduleUi.View.GroupTotalsWidth}px;
  height: 100%;
  border-left: var(--border-thick-bright);
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
`;
