import { intersection } from "lodash-es";
import { useSelector } from "react-redux";

import { selectJobItemIdsByJobId } from "../../../../redux/selectors/jobItemSelectors";
import { AppState } from "../../../AppState";
import { selectRoleIdsByJobItemId } from "./selectRoleIdsByJobItemId";

export function selectRoleIdsInAllJobItemsByJobId(
  state: AppState,
  { jobId }: { jobId: number }
) {
  const jobItemIds: number[] = selectJobItemIdsByJobId(state, {
    jobId,
  });

  const roleIdsByJobItemId = jobItemIds.map((jobItemId) =>
    selectRoleIdsByJobItemId(state, { jobItemId })
  );

  return intersection(...roleIdsByJobItemId);
}

export function useRoleIdsInAllJobItemsByJobId(jobId: number) {
  return useSelector((state: AppState) =>
    selectRoleIdsInAllJobItemsByJobId(state, { jobId })
  );
}
