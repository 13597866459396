import { difference, get } from "lodash-es";

import { EMPTY_ARRAY } from "../../../../../lib/constants";
import { notActive } from "../../../../../lib/entities/scheduleLoggedTimeEntity";
import { getId } from "../../../../../lib/objects";
import parseEntityPayload from "../../../../helpers/parseEntityPayload";
import reduceChangedEntities from "../reduceChangedEntities";

const savedBlockReducer = (state, action) => {
  const { blockKey, scheduleLoggedTimes } = action.payload;
  const newScheduleLoggedTimeIds = scheduleLoggedTimes.map(getId);
  const prevScheduleLoggedTimeIds = get(
    state,
    `idsByBlockKey.${blockKey}`,
    EMPTY_ARRAY
  );

  const changedEntities = difference(
    prevScheduleLoggedTimeIds,
    newScheduleLoggedTimeIds
  )
    .map((id) => state.byId[id])
    .map((prevEntity) => ({ prevEntity }))
    .concat(
      parseEntityPayload(state, scheduleLoggedTimes, notActive, {
        _prevBaseLoggedTimeId: blockKey,
      })
    );

  return reduceChangedEntities(state, changedEntities);
};

export default savedBlockReducer;
