import type { ModalBridgeClient } from "./ModalBridgeClient";

let modalBridgeClient: ModalBridgeClient | undefined;

export function setModalBridgeClient(client: ModalBridgeClient) {
  modalBridgeClient = client;
}

export function getModalBridgeClient() {
  if (!modalBridgeClient) {
    // eslint-disable-next-line no-console
    console.warn(`"[ModalBridge] client used before initialisation."`);
  }
  return modalBridgeClient;
}
