import {
  Entity,
  type TEncodedRichText,
  type TEntityId,
} from "@streamtimefe/entities";
import type { Content, Editor, JSONContent } from "@tiptap/core";
import type { RefObject } from "react";
import { useImperativeHandle, useRef } from "react";

export type RichTextEditorRef = {
  getHTML: () => TEncodedRichText;
  getText: () => string;
  setContent: (content: Content) => void;
  getMentionedUserIds: () => TEntityId[];
  focus: () => void;
};

export function useRichTextEditorRef() {
  return useRef<RichTextEditorRef>(null);
}

export function useRichTextEditorRefHandle(
  editor: Editor | null,
  editorRef?: RefObject<RichTextEditorRef>
) {
  useImperativeHandle<RichTextEditorRef, RichTextEditorRef>(
    editorRef,
    () => getRichTextEditorRef(editor),
    [editor]
  );
}

export function getRichTextEditorRef(editor: Editor | null): RichTextEditorRef {
  return {
    getHTML: () => {
      if (editor) return editor.getHTML() as TEncodedRichText;
      return null;
    },
    getText: () => {
      if (editor) return editor.getText();
      return "";
    },
    setContent: (content: Content) => {
      editor?.commands.setContent(content);
    },
    getMentionedUserIds() {
      if (!editor) return [];

      function parseMentions(data: JSONContent): string[] {
        const mentions = (data.content || []).flatMap(parseMentions);
        if (data.type === "mention") {
          if (data.attrs?.id) {
            mentions.push(data.attrs.id as string);
          }
        }
        return [...new Set(mentions)];
      }

      return parseMentions(editor.getJSON()).map(Entity.idFromString);
    },
    focus() {
      editor?.commands.focus();
    },
  };
}
