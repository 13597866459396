import { useEffect, useRef, useState } from "react";
import { useFocusWithin as useFocusWithinAria } from "react-aria";

import { useDebounceValue } from "./useDebounceValue";

export const useFocusWithin = useFocusWithinAria;

export function useFocusWithinElement({
  onBlur,
  onFocus,
  delay = 0,
}: {
  onBlur?: () => void;
  onFocus?: () => void;
  delay?: number;
}) {
  const firstRender = useRef(true);
  const [focused, setFocused] = useState(false);
  const debouncedFocus = useDebounceValue(focused, delay);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (debouncedFocus) onFocus?.();
    else onBlur?.();
  }, [debouncedFocus]);

  return useFocusWithin({
    onBlurWithin: () => setFocused(false),
    onFocusWithin: () => setFocused(true),
  });
}
