import { get } from "lodash-es";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectJobItem } from "../jobItem";
import { selectUser } from "../user";
import { selectJobItemUser, selectStateJobItemUsers } from "./index";

export const selectOrderedJobItemUserIdsByJobId = (state, { jobId }) => {
  const jobItemUsersState = selectStateJobItemUsers(state);
  const idsByJobItemId = get(jobItemUsersState, `idsByJobItemId`, EMPTY_ARRAY);

  const jobItemUserIds = [];

  Object.keys(idsByJobItemId).forEach(jobItemId => {
    const jobItem = selectJobItem(state, { jobItemId });
    if (jobItem && jobItem.jobId === jobId) {
      jobItemUserIds.push(...get(idsByJobItemId, `${jobItemId}`, EMPTY_ARRAY));
    }
  });

  jobItemUserIds.sort((jobItemUserIdA, jobItemUserIdB) => {
    const jobItemUserA = selectJobItemUser(state, {
      jobItemUserId: jobItemUserIdA
    });
    const jobItemUserB = selectJobItemUser(state, {
      jobItemUserId: jobItemUserIdB
    });

    const userA = selectUser(state, { userId: jobItemUserA.userId });
    const userB = selectUser(state, { userId: jobItemUserB.userId });

    return userA.displayName.localeCompare(userB.displayName);
  });

  return jobItemUserIds;
};

export const useOrderedJobItemUserIdsByJobId = jobId =>
  useSelector(state => selectOrderedJobItemUserIdsByJobId(state, { jobId }));
