import {
  setUserPreference,
  UserPreferenceKeys,
  useUserPreference,
} from "st-shared/stores";

const minWidth = 250;
const maxWidth = 800;

export function useScheduleSideMenuWidth(): number {
  const userPreference = useUserPreference(
    UserPreferenceKeys.ScheduleLeftSidebarWidth
  );
  const width = Number(userPreference);

  if (isNaN(width) || width < minWidth) return minWidth;
  if (width > maxWidth) return maxWidth;

  return width;
}

export function setScheduleSideMenuWidth(width: number) {
  if (width < minWidth) {
    width = minWidth;
  } else if (width > maxWidth) {
    width = maxWidth;
  }

  setUserPreference(UserPreferenceKeys.ScheduleLeftSidebarWidth, width);
}
