import {
  StatisticField,
  type TEntityDescriptorEnum,
  type TStatisticFieldEnum,
} from "@streamtimefe/entities";
import { useMemo, useRef, useState } from "react";
import { DropdownButton } from "st-shared/components";
import {
  type SearchOption,
  transformToOldSearchOptions,
} from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import {
  ColumnTooltip,
  Highlight,
  SearchMenu,
  searchMenuItem,
} from "st-shared/module";
import { usePermissions } from "st-shared/stores";

import { columnSearchDropdownButton } from "./ColumnSearchDropdown.css";

interface Props {
  dataSetEntityDescriptorId: TEntityDescriptorEnum;
  columnId: TStatisticFieldEnum | null;
  columns: TStatisticFieldEnum[];
  onPick: (key: TStatisticFieldEnum) => void;
}

export function ColumnSearchDropdown({
  dataSetEntityDescriptorId,
  columnId,
  columns,
  onPick,
}: Props) {
  const { canAccessCostRates, canViewJobFinancials } = usePermissions();

  const groupedColumns = useMemo(() => {
    const items = StatisticField.getGroupedSearchItems(
      columns,
      dataSetEntityDescriptorId,
      { canAccessCostRates, canViewJobFinancials }
    );

    const options: Record<string, SearchOption<TStatisticFieldEnum>[]> = {};
    Object.keys(items).forEach((key) => {
      options[key] = transformToOldSearchOptions(items[key]);
    });
    return options;
  }, [
    columns,
    dataSetEntityDescriptorId,
    canAccessCostRates,
    canViewJobFinancials,
  ]);

  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  function openMenu() {
    setAnchorEl(ref.current);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onColumnPick(key: TEntityId) {
    closeMenu();
    onPick(key as TStatisticFieldEnum);
  }

  function getWidth() {
    try {
      return ref.current?.getClientRects()[0].width;
    } catch (error) {
      return undefined;
    }
  }

  const width = getWidth();

  function RenderOption(
    option: SearchOption<TStatisticFieldEnum>,
    onPick: (key: TEntityId) => void,
    query: string
  ) {
    function renderRow() {
      return (
        <div
          key={option.key}
          className={searchMenuItem}
          onClick={() => onPick(option.key)}
        >
          <Highlight text={option.value} query={query} />
        </div>
      );
    }

    const columnTooltipType = StatisticField.getTooltip(option.entity);

    if (columnTooltipType) {
      return (
        <ColumnTooltip
          key={option.key}
          type={columnTooltipType}
          title={option.value}
        >
          {renderRow()}
        </ColumnTooltip>
      );
    }

    return renderRow();
  }

  return (
    <>
      <DropdownButton
        ref={ref}
        value={
          columnId
            ? StatisticField.getName(
                columnId,
                undefined,
                dataSetEntityDescriptorId
              )
            : null
        }
        defaultValue="Choose Field..."
        onClick={openMenu}
        className={columnSearchDropdownButton}
      />
      <SearchMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        options={groupedColumns}
        onClose={closeMenu}
        headerProps={{ placeholder: "Start typing..." }}
        onPickOption={onColumnPick}
        PaperProps={{ style: { width, minWidth: width } }}
        renderOption={RenderOption}
      />
    </>
  );
}
