import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { invoiceLineItemEntityType } from "../../../lib/entities/invoiceLineItemEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectInvoiceLineItemById,
  selectInvoiceLineItemUiIsCreating
} from "../../../redux/selectors/invoiceLineItemSelectors";
import LoadingOverlay from "../../elements/LoadingOverlay";
import Spacer from "../../elements/Spacer";
import DocumentLineDescription from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineDescription";
import DocumentLineName from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineName";
import DocumentLineRightMargin from "../../modules/CommercialDocuments/DocumentLineItems/DocumentLineRightMargin";

const mapState = (state, props) => ({
  invoiceLineItem: selectInvoiceLineItemById(state, props),
  isCreating: selectInvoiceLineItemUiIsCreating(state, props)
});

const InvoiceLineHeading = ({
  id,
  isSortMode,
  isCreating,
  invoiceLineItem,
  displayHeadingDescriptions
}) => (
  <Wrapper className={isSortMode ? "sorting" : ""}>
    <DocumentLineName
      documentLineItem={invoiceLineItem}
      isHeading
      isDisabled={isSortMode}
      isCreating={isCreating}
    />
    <Spacer h={5} />
    <HeadingLine />
    {displayHeadingDescriptions && (
      <>
        <Spacer h={12} />
        <DocumentLineDescription
          isDisabled={isSortMode}
          documentLineItem={invoiceLineItem}
          placeholder="Add an introduction..."
        />
      </>
    )}
    {!isCreating && !isSortMode && (
      <DocumentLineRightMargin documentLineItem={invoiceLineItem} />
    )}
    {isCreating && <LoadingOverlay />}
  </Wrapper>
);

InvoiceLineHeading.propTypes = {
  id: entityIdType.isRequired,
  isSortMode: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  invoiceLineItem: invoiceLineItemEntityType.isRequired,
  displayHeadingDescriptions: PropTypes.bool.isRequired
};

export default connect(mapState)(InvoiceLineHeading);

const HeadingLine = styled.div`
  height: 1px;
  border: none;
  border-bottom: var(--border-thin-black);
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  &.sorting {
    background-color: white;
    border: 1px dashed var(--color-gray-medium);
    padding-bottom: 10px;
    ${HeadingLine} {
      margin: 0 10px;
    }
  }
  &:hover {
    .DocumentLineDeleteButton {
      opacity: 1;
    }
  }
`;
