import * as PropTypes from "prop-types";
import { asDecimal } from "../math";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType
} from "../types/entityTypes";

export const invoicePaymentShape = {
  id: entityIdType.isRequired,
  amountPaidIncTax: entityFieldDecimalType,
  invoiceId: entityIdType.isRequired,
  otherInvoice: entityIdType,
  notes: PropTypes.string,
  paymentDate: PropTypes.string,
  paymentStatus: typeEntityType.isRequired
};

export const getPaymentDate = ({ paymentDate }) => paymentDate;

export const getNotes = ({ notes }) => notes || "";

export const getAmountPaidIncTax = ({ amountPaidIncTax }) =>
  asDecimal(amountPaidIncTax);
