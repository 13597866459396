import React from "react";
import styled from "styled-components";
import Flex from "../../elements/Flex";

const GenericContent = () => {
  return (
    <Content>
      <span>
        Something went wrong here...{" "}
        <span role="img" aria-label="thinking">
          🤔
        </span>
      </span>
    </Content>
  );
};

export default GenericContent;

const Content = styled(Flex)`
  white-space: pre-wrap;
  align-items: center;
  font-size: 12px;
  color: var(--color-red-bright);
  font-weight: 500;
  [role="img"] {
    font-size: 15px;
  }
`;
