import { get, isEmpty } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_QUOTE_LINE_ITEMS,
  QUOTE_LINE_ITEM_CREATE,
  QUOTE_LINE_ITEM_CREATE_ERROR,
  QUOTE_LINE_ITEM_CREATED,
  QUOTE_LINE_ITEM_DELETE,
  QUOTE_LINE_ITEM_DELETE_ERROR,
  QUOTE_LINE_ITEM_EDIT,
  QUOTE_LINE_ITEM_MERGE_SAVE_ERROR,
  QUOTE_LINE_ITEM_SAVE,
  QUOTE_LINE_ITEM_SAVE_ERROR,
  QUOTE_LINE_ITEM_SORT_CANCEL,
  QUOTE_LINE_ITEM_SORT_MOVE,
  QUOTE_LINE_ITEM_SORT_SAVE_ERROR,
  QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR
} from "../../../../lib/constants";
import { getQuoteId } from "../../../../lib/entities/quoteLineItemEntity";
import {
  getOrderId,
  isDeleted,
  moveLineItemOrder
} from "../../../../lib/ui/commercialDocumentLineItems";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import replaceTemporaryEntity from "../../../helpers/replaceTemporaryEntity";
import lineItemElementsByQuoteIdReducer from "./lineItemElementsByQuoteIdReducer";
import reorderByQuoteIdReducer from "./reorderByQuoteIdReducer";
import sortedIdsByQuoteIdReducer from "./sortedIdsByQuoteIdReducer";
import subTotalsExTaxByQuoteIdReducer from "./subTotalsExTaxByQuoteIdReducer";
import subTotalsIncTaxByQuoteIdReducer from "./subTotalsIncTaxByQuoteIdReducer";
import totalsByQuoteIdReducer from "./totalsByQuoteIdReducer";

const idsByQuoteIdIndexer = createEntityIndexedArrayReducer(
  entity => entity.quoteId
);

const getTouchedQuoteIds = changedEntities => {
  const touchedQuoteIds = [];

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    const prevPath = prevEntity && getQuoteId(prevEntity);
    const newPath = newEntity && getQuoteId(newEntity);

    if (prevPath && prevPath !== newPath) pushOnce(touchedQuoteIds, prevPath);

    if (newPath) pushOnce(touchedQuoteIds, newPath);
  });

  return touchedQuoteIds;
};

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByQuoteId: idsByQuoteIdIndexer(state.idsByQuoteId, changedEntities)
  };

  const touchedQuoteIds = getTouchedQuoteIds(changedEntities);

  nextState = reorderByQuoteIdReducer(nextState, touchedQuoteIds);

  nextState = sortedIdsByQuoteIdReducer(nextState, touchedQuoteIds);

  nextState = lineItemElementsByQuoteIdReducer(
    state,
    nextState,
    touchedQuoteIds
  );

  nextState = subTotalsExTaxByQuoteIdReducer(nextState, touchedQuoteIds);

  nextState = subTotalsIncTaxByQuoteIdReducer(nextState, touchedQuoteIds);

  nextState = totalsByQuoteIdReducer(nextState, touchedQuoteIds);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_QUOTE_LINE_ITEMS],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_QUOTE_LINE_ITEMS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const createQuoteLineItemReducer = (state, { payload: { quoteLineItem } }) =>
  reduceChangedEntities(state, parseEntityPayload(state, [quoteLineItem]));

const createdQuoteLineItemReducer = (
  state,
  { payload: { id, quoteLineItems } }
) =>
  reduceChangedEntities(
    state,
    replaceTemporaryEntity(state, id, quoteLineItems)
  );

const createQuoteLineItemErrorReducer = (
  state,
  { payload: { quoteLineItem } }
) => reduceChangedEntities(state, [{ prevEntity: quoteLineItem }]);

const deleteQuoteLineItemReducer = (state, { payload: { id } }) =>
  reduceChangedEntities(state, parseRemovedEntities(state, [id]));

const deleteQuoteLineItemErrorReducer = (
  state,
  { payload: { id, quoteLineItem } }
) =>
  reduceChangedEntities(state, [
    { prevEntity: state.byId[id], newEntity: state.byId[id] || quoteLineItem }
  ]);

const editQuoteLineItemReducer = (state, { payload: { quoteLineItem } }) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [quoteLineItem], isDeleted)
  );

const saveQuoteLineItemErrorReducer = (
  state,
  { payload: { id, prevQuoteLineItem } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [prevQuoteLineItem], isDeleted)
  );

const saveMergeQuoteLineItemErrorReducer = (
  state,
  { payload: { prevQuoteLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, prevQuoteLineItems, isDeleted)
  );

const saveUndoMergeQuoteLineItemErrorReducer = (
  state,
  { payload: { prevQuoteLineItems, mergedQuoteLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(
      state,
      [
        ...prevQuoteLineItems,
        ...mergedQuoteLineItems.map(lineItem => ({
          ...lineItem,
          active: false
        }))
      ],
      isDeleted
    )
  );

const sortQuoteLineItemMoveReducer = (state, { payload }) => {
  const { quoteId, sourceOrderId, destinationOrderId } = payload;
  const sortedQuoteLineItems = get(
    state,
    `sortedIdsByQuoteId.${quoteId}`,
    []
  ).map(id => state.byId[id]);

  const quoteLinesItems = moveLineItemOrder(
    sortedQuoteLineItems,
    sourceOrderId,
    destinationOrderId
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, quoteLinesItems)
  );
};

const undoSortQuoteLineItemMoveReducer = (state, { payload }) => {
  const { quoteId, snapshot } = payload;

  const ids = get(state, `idsByQuoteId.${quoteId}`, []);
  const quoteLineItems = ids.map(id => state.byId[id]);

  const prevQuoteLineItems = quoteLineItems.map(quoteLineItem =>
    snapshot[quoteLineItem.id]
      ? {
          ...quoteLineItem,
          orderId: getOrderId(snapshot[quoteLineItem.id])
        }
      : quoteLineItem
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, prevQuoteLineItems)
  );
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [QUOTE_LINE_ITEM_CREATE]: createQuoteLineItemReducer,
    [QUOTE_LINE_ITEM_DELETE]: deleteQuoteLineItemReducer,
    [QUOTE_LINE_ITEM_DELETE_ERROR]: deleteQuoteLineItemErrorReducer,
    [QUOTE_LINE_ITEM_EDIT]: editQuoteLineItemReducer,
    [QUOTE_LINE_ITEM_SAVE]: editQuoteLineItemReducer,
    [QUOTE_LINE_ITEM_CREATED]: createdQuoteLineItemReducer,
    [QUOTE_LINE_ITEM_CREATE_ERROR]: createQuoteLineItemErrorReducer,
    [QUOTE_LINE_ITEM_SAVE_ERROR]: saveQuoteLineItemErrorReducer,
    [QUOTE_LINE_ITEM_MERGE_SAVE_ERROR]: saveMergeQuoteLineItemErrorReducer,
    [QUOTE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR]: saveUndoMergeQuoteLineItemErrorReducer,
    [QUOTE_LINE_ITEM_SORT_MOVE]: sortQuoteLineItemMoveReducer,
    [QUOTE_LINE_ITEM_SORT_CANCEL]: undoSortQuoteLineItemMoveReducer,
    [QUOTE_LINE_ITEM_SORT_SAVE_ERROR]: undoSortQuoteLineItemMoveReducer
  }
);
