import { put, select, takeLatest } from "redux-saga/effects";
import {
  LINE_ITEMS_SORT_CANCEL,
  PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL
} from "../../../lib/constants";
import {
  getPurchaseOrderId,
  isPurchaseOrder
} from "../../../lib/entities/loggedExpenseEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectLoggedExpenseByLoggedExpenseId,
  selectLoggedExpenseUi
} from "../../selectors/loggedExpenseSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* cancelSortLineItems(action) {
  const { loggedExpenseId, sortModeSnapshotId: snapshotId } = yield select(
    selectLoggedExpenseUi
  );

  if (!loggedExpenseId) return;

  const loggedExpense = yield select(selectLoggedExpenseByLoggedExpenseId, {
    loggedExpenseId
  });

  if (!isPurchaseOrder(loggedExpense)) return;

  const purchaseOrderId = getPurchaseOrderId(loggedExpense);

  if (!purchaseOrderId) return;

  try {
    const snapshot = yield select(selectSnapshotBySnapshotId, {
      snapshotId
    });

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL, {
        purchaseOrderId,
        snapshotId,
        snapshot
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLineItemsSortCancel() {
  yield takeLatest(LINE_ITEMS_SORT_CANCEL, cancelSortLineItems);
}
