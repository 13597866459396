import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { DATA_ATTRIBUTE_RESIZE_HANDLE } from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import {
  isComplete,
  scheduleLoggedTimeType,
} from "../../../lib/entities/scheduleLoggedTimeEntity";
import { snapTrunc } from "../../../lib/math";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectBaseScheduleLoggedTimeByBlockKey,
  selectIsActiveBlock,
  selectIsBlockInBulkSavingSelection,
  selectIsBulkSavingBlock,
  selectIsDraggingBlock,
  selectIsFilteredBlock,
  selectIsPaintingBlock,
  selectIsRelatedBlock,
  selectIsResizingBlock,
  selectIsSavingBlock,
  selectIsScheduleBlockBilled,
  selectIsScheduleBlockPaused,
  selectIsScheduleBlockPrivate,
  selectIsSelectedBlock,
  selectIsSplittingBlock,
  selectScheduleBlockHeight,
  selectSplittingBlockDays,
} from "../../../redux/selectors/scheduleBlockSelectors";
import { selectScheduleDayWidth } from "../../../redux/selectors/scheduleSelectors";
import {
  selectScheduleUiIsSearching,
  selectScheduleUiTodoVisibilityOptions,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import {
  ScheduleUi,
  ScheduleUiTodoVisibilityOptions,
} from "../../../state/ui/schedule/types";
import RepeatIcon from "../../elements/Icons/RepeatIcon";
import ResizeHandles from "./ResizeHandles";
import ScheduleBlockTooltip from "./ScheduleBlockTooltip";
import CalEventIcon from "../../elements/Icons/CalEventIcon.jsx";

const mapState = (state, props) => ({
  baseScheduleLoggedTime: selectBaseScheduleLoggedTimeByBlockKey(state, props),
  dayWidth: selectScheduleDayWidth(state, props),
  blockHeight: selectScheduleBlockHeight(state, props),
  todoVisibilityOptions: selectScheduleUiTodoVisibilityOptions(state),
  isBilled: selectIsScheduleBlockBilled(state, props),
  isPrivate: selectIsScheduleBlockPrivate(state, props),
  isPaused: selectIsScheduleBlockPaused(state, props),
  isDragging: selectIsDraggingBlock(state, props),
  isResizing: selectIsResizingBlock(state, props),
  isPainting: selectIsPaintingBlock(state, props),
  isActive: selectIsActiveBlock(state, props),
  isSplitting: selectIsSplittingBlock(state, props),
  isRelated: selectIsRelatedBlock(state, props),
  isSelected: selectIsSelectedBlock(state, props),
  isSaving: selectIsSavingBlock(state, props),
  isBulkSaving: selectIsBulkSavingBlock(state, props),
  isInBulkSavingSelection: selectIsBlockInBulkSavingSelection(state, props),
  isFiltered: selectIsFilteredBlock(state, props),
  isSearching: selectScheduleUiIsSearching(state),
  splitBlockDays: selectSplittingBlockDays(state, props),
});

const hasShownCache = {};

const generateCssFadeInAnimation = (isFiltered) =>
  `${isFiltered ? "fade-in-100" : "fade-in-30"} 0.25s ${
    Math.random() / 4
  }s ease-in-out forwards`;

const getContent = (
  baseScheduleLoggedTime,
  todoVisibilityOptions,
  isPrivate
) => {
  const {
    notes,
    repeatingLoggedTimeTitle,
    scheduleNotes,
    jobId,
    jobFullName,
    jobItemName,
    jobPhaseName,
    companyName,
    repeatingLoggedTimeId,
    googleCalendarEventId,
  } = baseScheduleLoggedTime;

  if (isPrivate) return ["Personal To Do"];

  const content = [];

  if (repeatingLoggedTimeId) {
    content.push(
      <>
        <RepeatIcon inline size={15} />
        {` ${repeatingLoggedTimeTitle || ""}`}
      </>
    );
  }

  todoVisibilityOptions.forEach((visibilityOption) => {
    switch (visibilityOption) {
      case ScheduleUiTodoVisibilityOptions.Notes:
        if (scheduleNotes || notes) content.push(scheduleNotes || notes);
        break;
      case ScheduleUiTodoVisibilityOptions.JobItemName:
        if (jobId && jobItemName) content.push(jobItemName);
        break;
      case ScheduleUiTodoVisibilityOptions.JobName:
        if (jobId) content.push(`${jobFullName}, ${companyName}`);
        break;
      case ScheduleUiTodoVisibilityOptions.JobPhaseName:
        if (jobId && jobPhaseName) content.push(jobPhaseName);
        break;
      default:
    }
  });

  if (!content.length) content.push("");

  //If we need to add a calendar icon, prepend it to the start of the first piece of content
  if (googleCalendarEventId) {
    content[0] = (
      <>
        <CalEventIcon inline size={15} />
        {content[0]}
      </>
    );
  }

  return content;
};

class ScheduleBlock extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    blockKey: entityIdType.isRequired,
    baseScheduleLoggedTime: scheduleLoggedTimeType.isRequired,
    dayWidth: PropTypes.number.isRequired,
    blockHeight: PropTypes.number.isRequired,
    todoVisibilityOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    isBilled: PropTypes.bool.isRequired,
    isPrivate: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isResizing: PropTypes.bool.isRequired,
    isPainting: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    isSplitting: PropTypes.bool.isRequired,
    isRelated: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isBulkSaving: PropTypes.bool.isRequired,
    isInBulkSavingSelection: PropTypes.bool.isRequired,
    isFiltered: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool.isRequired,
    splitBlockDays: PropTypes.number,
  };

  static defaultProps = {
    splitBlockDays: null,
  };

  componentDidMount() {
    const { baseScheduleLoggedTime } = this.props;

    hasShownCache[baseScheduleLoggedTime.id] = true;
  }

  get blockContent() {
    const { baseScheduleLoggedTime, todoVisibilityOptions, isPrivate } =
      this.props;

    return getContent(baseScheduleLoggedTime, todoVisibilityOptions, isPrivate);
  }

  get contentStyle() {
    const { blockHeight } = this.props;
    const truncatedHeight = snapTrunc(
      blockHeight - ScheduleUi.Block.ContentPaddingY * 2,
      ScheduleUi.Block.ContentLineHeight
    );
    return {
      height: truncatedHeight || ScheduleUi.Block.ContentLineHeight,
    };
  }

  get isComplete() {
    const { baseScheduleLoggedTime } = this.props;
    return isComplete(baseScheduleLoggedTime);
  }

  get isCreating() {
    const { isPainting } = this.props;
    const { blockKey } = this.props;
    return Boolean(blockKey < 0 && !isPainting);
  }

  get showPulsing() {
    const { isBulkSaving } = this.props;
    return this.isCreating || isBulkSaving;
  }

  get isReadOnly() {
    const { isPrivate, isBilled, isInBulkSavingSelection } = this.props;
    return (
      isPrivate ||
      (isBilled && this.isComplete) ||
      this.showPulsing ||
      isInBulkSavingSelection
    );
  }

  get className() {
    const {
      isResizing,
      isPainting,
      isDragging,
      isActive,
      isSplitting,
      isRelated,
      isSelected,
      isPaused,
      isPrivate,
      isSaving,
      isFiltered,
      isSearching,
    } = this.props;
    const classes = ["scheduleBlock"];

    if (isResizing) classes.push("resizing");
    if (isPainting) classes.push("painting");
    if (isDragging) classes.push("dragging");
    if (isActive) classes.push("active");
    if (isSplitting) classes.push("splitting");
    if (isRelated) classes.push("related");
    if (isSelected) classes.push("selected");
    if (isPaused) classes.push("paused");
    if (isPrivate) classes.push("private");
    if (isSaving) classes.push("saving");
    if (!isSearching && isFiltered) classes.push("filtered");
    if (this.showPulsing) classes.push("pulsing");
    if (this.isComplete) classes.push("complete");

    return classes.join(" ");
  }

  get shouldFadeIn() {
    const { baseScheduleLoggedTime } = this.props;
    const key =
      baseScheduleLoggedTime._prevBaseLoggedTimeId || baseScheduleLoggedTime.id;

    if (key < 0) return false;

    if (this.isReadOnly) return false;

    return !hasShownCache[key];
  }

  get style() {
    const { isFiltered } = this.props;
    return {
      ...(this.shouldFadeIn && {
        animation: generateCssFadeInAnimation(isFiltered),
        opacity: 0,
      }),
    };
  }

  get canSplit() {
    const { splitBlockDays } = this.props;
    return Boolean(splitBlockDays);
  }

  get splitWidth() {
    const { splitBlockDays, dayWidth } = this.props;

    return this.canSplit ? splitBlockDays * dayWidth : 0;
  }

  render() {
    const { blockKey } = this.props;

    return (
      <>
        <Block
          ref={this.ref}
          className={this.className}
          style={this.style}
          disabled={this.isReadOnly}
        >
          <BlockContent style={this.contentStyle}>
            {this.blockContent.map((content, index) => (
              <BlockContentText key={index}>{content}</BlockContentText>
            ))}
          </BlockContent>
          <ResizeHandles />
        </Block>
        {this.canSplit && <SplitLine style={{ width: this.splitWidth }} />}
        <ScheduleBlockTooltip blockKey={blockKey} />
      </>
    );
  }
}

export default connect(mapState)(ScheduleBlock);

const Block = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: ${ScheduleUi.Block.ContentPaddingY}px 5px;
  font-size: 12px;
  line-height: ${ScheduleUi.Block.ContentLineHeight}px;
  transition: background-color 0.2s;
  cursor: grab;

  background-color: white;
  border: 1px solid var(--color-gray);

  &:not(.filtered) {
    opacity: 0.3;
  }

  &.dragging.filtered {
    opacity: 0.75;
  }

  &.pulsing {
    animation: var(--animation-pulsate);
  }

  &.complete {
    background-color: var(--color-gray);
    border: 1px solid var(--color-gray-dark);
  }

  &.paused {
    color: var(--color-gray-dark);
    border-style: dashed;
  }

  &.selected {
    background-color: var(--color-green-light);
    &.complete {
      background-color: var(--color-green-bright);
    }
  }

  &:not(.pulsing) {
    &.painting,
    &.active,
    &:hover {
      + .block-tooltip {
        opacity: 1;
      }
    }
  }

  &:not([disabled]):not(.selected) {
    &.active {
      background-color: var(--color-yellow);
    }

    &:not(.active).related {
      background-color: var(--color-yellow-light);
      &.complete {
        background-color: var(--color-yellow-dark);
      }
    }
  }

  &[disabled] {
    cursor: default;
    &.filtered {
      opacity: 0.75;
    }
    &:not(.filtered) {
      opacity: 0.2;
    }
    [${getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE)}] {
      display: none;
    }
  }
`;

const BlockContent = styled.div`
  pointer-events: none;
  overflow: hidden;
`;

const BlockContentText = styled.div`
  :first-of-type {
    font-weight: 700;
  }
`;

const SplitLine = styled.div`
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 2px dashed var(--color-gray);
`;
