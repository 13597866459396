import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import {
  EntityDescriptorEnum,
  FilterGroupTypeEnum,
} from "@streamtimefe/entities";
import { useMemo } from "react";

import { useHasSubFilter } from "../../hooks/useHasSubFilter";
import {
  useReportingSavedSegmentColumnTypeDataOnly,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentDates,
  useReportingSavedSegmentFilterGroupType,
  useReportingSavedSegmentSubFilterGroupType,
} from "../../state/stores/savedSegmentSelectors";
import {
  useReportingSelectedSeriesData,
  useReportingSeriesFetching,
} from "../../state/stores/searchSelectors";
import { TableCellTooltip } from "./TableCellTooltip";
import { TableRow, TableRowLoading } from "./TableRow";
import {
  emptySearchCss,
  emptySearchHeaderCss,
  emptyStateCss,
} from "./TableRows.css";

function getFilterGroupTypeLinkGetter(
  filterGroupType: TFilterGroupTypeEnum | null
): null | ((id: string) => string) {
  switch (filterGroupType) {
    case FilterGroupTypeEnum.CompanyId:
      return (id: string) => `/#company/${id}`;
    case FilterGroupTypeEnum.JobId:
      return (id: string) => `/#job/${id}`;
    case FilterGroupTypeEnum.InvoiceId:
      return (id: string) => `/#invoices/${id}`;
    case FilterGroupTypeEnum.QuoteId:
      return (id: string) => `/#quotes/${id}`;
    default:
      return null;
  }
}

export function TableRows() {
  const isFetching = useReportingSeriesFetching();
  const selectedSeriesData = useReportingSelectedSeriesData();

  const hasSubFilter = useHasSubFilter();
  const filterGroupType = useReportingSavedSegmentFilterGroupType();
  const subFilterGroupType = useReportingSavedSegmentSubFilterGroupType();
  const getEnitityId = useMemo(
    () => getFilterGroupTypeLinkGetter(filterGroupType),
    [filterGroupType]
  );
  const getSubEnitityId = useMemo(
    () => getFilterGroupTypeLinkGetter(subFilterGroupType),
    [subFilterGroupType]
  );

  return (
    <>
      {!isFetching && (
        <>
          {selectedSeriesData.length > 0 && (
            <>
              {selectedSeriesData.map((seriesData, index) => (
                <TableRow
                  key={seriesData.id}
                  index={index}
                  seriesData={seriesData}
                  hasSubFilter={hasSubFilter}
                  getEnitityId={getEnitityId}
                  getSubEnitityId={getSubEnitityId}
                />
              ))}
              <TableCellTooltip />
            </>
          )}
          {selectedSeriesData.length === 0 && <EmptySearch />}
        </>
      )}
      {isFetching && <TableRowLoading />}
    </>
  );
}

function EmptySearch() {
  const dataColumns = useReportingSavedSegmentColumnTypeDataOnly();

  const dataSets = useReportingSavedSegmentDataSets();
  const dates = useReportingSavedSegmentDates();

  const availabilityOnly = useMemo(() => {
    const dataSetArray = Object.values(dataSets);

    if (dataSetArray.length === 0) {
      return false;
    }

    const availibilityDataSetArray = dataSetArray.filter(
      (dataSet) => dataSet.entityDescriptorId === EntityDescriptorEnum.User
    );
    return (
      dataSetArray.length === availibilityDataSetArray.length && dates === null
    );
  }, [dataSets, dates]);

  function renderInfo() {
    if (availabilityOnly) {
      return (
        <span>
          To view Availability hours,{" "}
          <span className={emptyStateCss}>a date range must be set above.</span>
        </span>
      );
    }

    if (dataColumns.length > 0) {
      return (
        <span>
          Try editing your filter sets or{" "}
          <span className={emptyStateCss}>changing your date range.</span>
        </span>
      );
    }

    return (
      <span>
        None of your <b>existing</b> columns can be plotted over time. Try
        adding columns from a different data source.
      </span>
    );
  }

  return (
    <div className={emptySearchCss}>
      <div>
        <div className={emptySearchHeaderCss}>Nothing to see here, folks.</div>
        {renderInfo()}
      </div>
    </div>
  );
}
