import type { ConstEnum } from "@streamtimefe/types";

export const FilterGroupTypeEnum = {
  CompanyName: 1,
  JobName: 2,
  JobStatus: 3,
  TeamMember: 4,
  Date: 5,
  Minutes: 6,
  Amount: 7,
  ItemName: 8,
  ExpenseName: 9,
  JobItemStatus: 10,
  Time: 11,
  ContactName: 12,
  JobStartDate: 13,
  JobDueDate: 14,
  JobTotalLoggedExTax: 15,
  JobTotalPlannedTimeExTax: 16,
  JobTotalLoggedMinutes: 17,
  JobTotalPlannedMinutes: 18,
  JobBudget: 19,
  JobTotalInvoicedExTax: 20,
  JobDaysPastDueDate: 22,
  JobDaysUntilDueDate: 23,
  JobBudgetVariance: 24,
  JobNumber: 25,
  InvoiceStatus: 26,
  InvoiceNumber: 27,
  InvoicePaidDate: 28,
  InvoiceTotalAmountExTax: 29,
  InvoiceTotalAmountIncTax: 30,
  InvoiceAmountPaidIncTax: 31,
  InvoiceReference: 32,
  InvoiceDueDate: 33,
  InvoiceBalance: 34,
  InvoiceInvoiceDate: 35,
  QuoteStatus: 36,
  QuoteNumber: 37,
  QuoteQuoteDate: 38,
  QuoteTotalAmountExTax: 39,
  CreatedUser: 40,
  ExpenseCompany: 41,
  ExpenseReference: 42,
  ExpenseQuantity: 43,
  ExpenseContactName: 44,
  ExpenseContactEmail: 45,
  ExpenseDescription: 46,
  ExpenseTotalCost: 47,
  ExpenseTotalSell: 48,
  BilledStatus: 49,
  JobLabel: 50,
  JobCreatedDate: 51,
  JobTotalLoggedCostExTax: 52,
  JobTotalLoggedTimeExTax: 54,
  JobTotalLoggedTimeCostExTax: 55,
  JobTotalLoggedExpensesCostExTax: 56,
  JobTotalLoggedExpensesExTax: 57,
  JobTotalLoggedProfitExTax: 58,
  JobTotalLoggedTimeProfitExTax: 59,
  JobTotalLoggedExpensesProfitExTax: 60,
  QuoteTotalAmountIncTax: 61,
  LoggedExpenseLabel: 62,
  QuoteLabel: 63,
  ExpenseType: 64,
  ExpenseStatus: 65,
  PurchaseOrderRequiredDate: 66,
  PurchaseOrderNumber: 67,
  CurrencyCode: 68,
  PurchaseOrderAmountPaidIncTax: 69,
  PurchaseOrderBalance: 70,
  PurchaseOrderCurrencyAmountPaidIncTax: 71,
  PurchaseOrderCurrencyBalance: 72,
  InvoiceCurrencyAmountPaidIncTax: 73,
  InvoiceCurrencyBalance: 74,
  JobTotalLoggedProfitMargin: 75,
  JobId: 76,
  ContactEmail: 77,
  ContactPhoneNumber: 78,
  ContactPosition: 79,
  CompanyTaxNumber: 80,
  WebsiteAddress: 81,
  JobItemEstimatedStartDate: 82,
  JobItemEstimatedEndDate: 83,
  JobItemUserStatus: 84,
  LoggedTimeStatus: 85,
  JobItemId: 86,
  JobItemUserId: 87,
  InvoiceSeenStatus: 88,
  InvoiceLabel: 89,
  JobPhaseName: 90,
  JobItemCompletedDate: 91,
  RateCardName: 92,
  JobPurchaseOrderNumber: 93,
  JobBranchName: 94,
  TotalDraftInvoicesExTax: 95,
  TotalAwaitingPaymentInvoicesExTax: 96,
  TotalPaidInvoicesExTax: 97,
  TotalIssuedInvoicesExTax: 98,
  JobMilestoneId: 99,
  JobMilestoneDate: 100,
  JobMilestoneName: 101,
  JobItemDueDate: 102,
  LoggedTimeScheduled: 103,
  LoggedTimeCompleted: 104,
  JobCompletedDate: 105,
  QuoteApprovedDate: 106,
  QuoteDeclinedDate: 107,
  SentToExternalPlatform: 108,
  CommercialDocumentSent: 109,
  CommercialDocumentSentDate: 110,
  ContactFullName: 111,
  ContactFirstName: 112,
  ContactLastName: 113,
  CompanyStatus: 114,
  ContactStatus: 115,
  JobBudgetVariancePercentage: 116,
  JobTotalPlannedExpensesCostExTax: 117,
  JobTotalPlannedExpensesExTax: 118,
  CompanyLabel: 120,
  ContactLabel: 121,
  JobTotalPlannedExTax: 122,
  UserDisplayName: 123,
  UserEmail: 124,
  UserBranchName: 125,
  UserRoleName: 126,
  UserStatus: 127,
  UserLabel: 128,
  ExchangeRate: 129,
  JobLeadUserDisplayName: 130,
  CompanyLeadUserDisplayName: 131,
  InvoiceType: 132,
  Billable: 133,
  JobArchivedDate: 134,
  NonBillablePlannedMinutes: 135,
  NonBillableLoggedMinutes: 136,
  NonBillablePlannedTimeExTax: 137,
  NonBillableLoggedTimeExTax: 138,
  CompanyId: 139,
  UserBranchId: 140,
  HappinessPillarId: 141,
  HappinessResponseDate: 142,
  LoggedTimePersonal: 143,
  HappinessScore: 144,
  JobItemSubItemId: 145,
  RoleId: 146,
  JobBranchId: 147,
  RateCardId: 148,
  JobLeadUserId: 149,
  CompanyLeadUserId: 150,
  JobItemOverBudget: 151,
  QuoteId: 154,
  InvoiceId: 155,
  UserId: 156,
  InvoicedExTax: 159,
  InvoicedPercentage: 160,
  InvoicedBalanceRemaining: 161,
  LoggedExpenseId: 162,
  JobUsedExceedsPlannedMinutes: 166,
  JobUsedExceedsPlannedDollars: 167,
  JobItemUsedExceedsPlannedMinutes: 168,
  JobItemUsedExceedsPlannedDollars: 169,
  JobItemUserUsedExceedsPlannedMinutes: 170,
  JobItemUserUsedExceedsPlannedDollars: 171,
  JobHasInvoiceIssuedWithinDate: 172,
  JobHasInvoiceDueWithinDate: 173,
  JobHasItemDueWithinDate: 174,
  Reference: 175,
  IssueDate: 176,
} as const;

export type TFilterGroupTypeEnum = ConstEnum<typeof FilterGroupTypeEnum>;
