import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { entityIdType } from "../../../lib/types/entityTypes";
import { commercialDocumentEntityType } from "../../../lib/ui/commercialDocument";
import Textarea from "../../elements/Textarea";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({
  id,
  document,
  documentMethods,
  actions: { doSaveDocument }
}) => ({
  id,
  document,
  getValue: documentMethods.getName,
  setValue: documentMethods.setName,
  doSaveDocument
});

class DocumentName extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    doSaveDocument: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { document, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: getValue(document)
    };
  }

  state = {
    value: "",
    hasFocus: false
  };

  save = value => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;

    if (value !== getValue(document))
      doSaveDocument(id, setValue(document, value), document);
  };

  handleChange = e => {
    this.setState({
      value: String(e.target.value)
    });
  };

  handleBlur = () => {
    const { value } = this.state;

    this.save(value);

    this.setState({
      hasFocus: false
    });
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true
    });
  };

  render() {
    const { value } = this.state;

    return (
      <DocumentNameField
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    );
  }
}

export default consumeCommercialDocument(mapContext)(DocumentName);

const DocumentNameField = styled(Textarea)`
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  min-height: 60px;
`;
