import type { TEntityId, TUser } from "@streamtimefe/entities";
import { Entity, EntityClass, User } from "@streamtimefe/entities";
import { defaultSortName, type TSearchSection } from "@streamtimefe/utils";
import { useMemo } from "react";
import { groupBy, isDefined, map, pipe, prop, sort, sortBy } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import { getBranchEntity } from "../Branch";
import { getRoleEntity } from "../Role";

export * from "./selectors";

export const useUserEntity = createUseEntityStoreEntity(EntityClass.User);

export const getUserEntity = createGetEntityStoreEntity(EntityClass.User);

function useUserEntities() {
  return useEntityStoreEntities(EntityClass.User);
}

export function useNonSystemUsers() {
  const users = useUserEntities();
  return useMemo(
    () => Object.values(users).filter(isDefined).filter(User.isNonSystem),
    [users]
  );
}

export function useActiveUsers() {
  const users = useNonSystemUsers();
  return useMemo(
    () => Object.values(users).filter(isDefined).filter(User.isActive),
    [users]
  );
}

export function useActiveUserSearchItems() {
  const users = useActiveUsers();
  return useMemo(
    () =>
      User.getSearchItems(users).map((searchItem) => ({
        ...searchItem,
        search: `${searchItem.search} ${searchItem.item.roleId ? getRoleEntity(searchItem.item.roleId)?.name : ""} ${getBranchEntity(searchItem.item.branchId)?.name}`,
      })),
    [users]
  );
}

export function useActiveUserSearchItemsOrderedByBranch(
  mainBranchId?: TEntityId
) {
  const users = useActiveUsers();
  return useMemo(
    () =>
      pipe(
        User.getSearchItems(users),
        map((searchItem) => ({
          ...searchItem,
          search: `${searchItem.search} ${searchItem.item.roleId ? getRoleEntity(searchItem.item.roleId)?.name : ""} ${getBranchEntity(searchItem.item.branchId)?.name}`,
          branchName: getBranchEntity(searchItem.item.branchId)?.name ?? "",
        })),
        sortBy(
          (item) => (item.item.branchId === mainBranchId ? -1 : 1),
          [prop("branchName"), "asc"]
        )
      ),
    [users, mainBranchId]
  );
}

export function useActiveOrHibernatedUsers() {
  const users = useNonSystemUsers();
  return useMemo(
    () =>
      Object.values(users).filter(isDefined).filter(User.isActiveOrHibernated),
    [users]
  );
}

export function useActiveOrHibernatedUserSearchItems() {
  const users = useActiveOrHibernatedUsers();
  return useMemo(() => User.getSearchItems(users), [users]);
}

export function useActiveUserSearchItemsByBranch() {
  const users = useActiveUserSearchItems();
  return useMemo(() => {
    const userSections: TSearchSection<TUser>[] = [];
    const byBranch = groupBy(users, ({ item }) => item.branchId);
    Object.entries(byBranch).forEach(([branchId, branchUsers]) => {
      const branchName =
        getBranchEntity(Entity.idFromString(branchId))?.name ?? "";
      userSections.push({
        name: branchName,
        items: branchUsers.map((searchItem) => ({
          ...searchItem,
          search: `${searchItem.search} ${branchName}`,
        })),
      });
    });
    return sort(userSections, defaultSortName);
  }, [users]);
}
