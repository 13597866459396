import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataCompanyName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobCompanyChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" changed the job’s company to "}
    <b>{getMetaDataCompanyName(activityEntry)}</b>
  </SystemMessage>
);

JobCompanyChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobCompanyChanged;
