import { all } from "redux-saga/effects";
import watchLineItemsMergeConfirm from "./watchLineItemsMergeConfirm";
import watchLineItemsSortCancel from "./watchLineItemsSortCancel";
import watchLineItemsSortConfirm from "./watchLineItemsSortConfirm";
import watchLineItemsSortStart from "./watchLineItemsSortStart";
import watchQuoteLineItemAdd from "./watchQuoteLineItemAdd";
import watchQuoteLineItemCreate from "./watchQuoteLineItemCreate";
import watchQuoteLineItemCreateTaxRate from "./watchQuoteLineItemCreateTaxRate";
import watchQuoteLineItemDelete from "./watchQuoteLineItemDelete";
import watchQuoteLineItemSave from "./watchQuoteLineItemSave";
import watchSetIsMerging from "./watchSetIsMerging";
import watchUndoMergeLineItems from "./watchUndoMergeLineItems";

export default function* quoteLineItemSaga() {
  yield all([
    watchQuoteLineItemCreateTaxRate(),
    watchQuoteLineItemAdd(),
    watchQuoteLineItemCreate(),
    watchQuoteLineItemDelete(),
    watchQuoteLineItemSave(),
    watchSetIsMerging(),
    watchLineItemsMergeConfirm(),
    watchUndoMergeLineItems(),
    watchLineItemsSortStart(),
    watchLineItemsSortCancel(),
    watchLineItemsSortConfirm()
  ]);
}
