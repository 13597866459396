import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { SCHEDULE_BLOCK_SET_ACTIVE } from "../../../lib/constants";
import { getTargetBlockKey } from "../../../lib/eventTargets";
import { entityIdType } from "../../../lib/types/entityTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleUiSplittingBlockKey } from "../../../state/ui/schedule/selectors/selectScheduleUi";

const mapState = (state) => ({
  splittingBlockKey: selectScheduleUiSplittingBlockKey(state),
});

const mapDispatch = (dispatch) => ({
  doSetHoveredBlock: (payload) =>
    dispatch(createAction(SCHEDULE_BLOCK_SET_ACTIVE, payload)),
});

export default (WrappedComponent) => {
  class BlockHoverHandlers extends React.PureComponent {
    static propTypes = {
      isDragging: PropTypes.bool.isRequired,
      isResizing: PropTypes.bool.isRequired,
      isPainting: PropTypes.bool.isRequired,
      splittingBlockKey: entityIdType,
      getDayWidth: PropTypes.func.isRequired,
      doSetHoveredBlock: PropTypes.func.isRequired,
      onPointerMove: PropTypes.func,
      className: PropTypes.string,
    };

    static defaultProps = {
      onPointerMove: null,
      splittingBlockKey: null,
      className: "",
    };

    state = {
      hoveredBlockKey: null,
    };

    onPointerMove = (e) => {
      const { hoveredBlockKey } = this.state;
      const {
        isDragging,
        isResizing,
        isPainting,
        doSetHoveredBlock,
        onPointerMove,
      } = this.props;

      const isInteracting = isDragging || isResizing || isPainting;

      const blockKey = getTargetBlockKey(e);

      if (!isInteracting && hoveredBlockKey !== blockKey) {
        this.setState({ hoveredBlockKey: blockKey });
        doSetHoveredBlock({ blockKey });
      }

      return onPointerMove && onPointerMove(e);
    };

    get handlers() {
      return {
        onPointerMove: this.onPointerMove,
      };
    }

    render() {
      const { props, handlers } = this;
      const { className } = this.props;

      return (
        <>
          <WrappedComponent {...{ ...props, className, ...handlers }} />
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(BlockHoverHandlers);
};
