import { type TUser, UserStatus } from "@streamtimefe/entities";
import { values } from "lodash-es";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateUsers } from "./selectStateUsers";

export const selectActiveUsers = createSelector([selectStateUsers], (users) =>
  (values(users.byId) as TUser[]).filter(
    (user) => !user.isSystemUser && UserStatus.isActive(user.userStatus)
  )
);

export function useActiveUsers() {
  return useSelector(selectActiveUsers);
}
