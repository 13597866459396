import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectJobPageJobId = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.jobId
);

export const useJobPageJobId = () => useSelector(selectJobPageJobId);
