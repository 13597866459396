import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";

import { getJobItemName } from "../../../../lib/entities/jobItemEntity";
import { getItemId } from "../../../../lib/sortingHelpers";
import { entityIdType } from "../../../../lib/types/entityTypes";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { ellipsisElement } from "../../../elements/cssElements";
import { IconButton } from "../../../elements/Button";
import DragIndicatorIcon from "../../../elements/Icons/custom/DragIndicatorIcon";
import Spacer from "../../../elements/Spacer";

const ReorderItemMenuItem = ({ id }) => {
  const jobItem = useJobItem(id);

  const {
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    attributes,
    listeners,
  } = useSortable({
    id: getItemId(id),
  });

  const showDragHandle = !isSorting || isDragging;

  return (
    <Container
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      $dragging={isDragging}
    >
      <Name>{getJobItemName(jobItem)}</Name>
      {showDragHandle && (
        <DragHandle {...listeners} {...attributes}>
          <DragIndicatorIcon />
        </DragHandle>
      )}
      {!showDragHandle && <Spacer w={46} />}
    </Container>
  );
};

ReorderItemMenuItem.propTypes = {
  id: entityIdType.isRequired,
};

export default ReorderItemMenuItem;

const FakeReorderItemMenuItem = ({ id }) => {
  const jobItem = useJobItem(id);

  return (
    <Container>
      <Name>{getJobItemName(jobItem)}</Name>
      <Spacer w={46} />
    </Container>
  );
};

FakeReorderItemMenuItem.propTypes = {
  id: entityIdType.isRequired,
};

export { FakeReorderItemMenuItem };

const Container = styled.div`
  background-color: var(--bg-color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 41px;
  padding-left: 16px;
  border-bottom: 1px dashed var(--color-gray);
  min-width: 250px;
  max-width: 400px;

  ${(props) =>
    props.$dragging &&
    `
    border-bottom: unset;
    background-color: var(--color-green-light);
    z-index: 10000;
  `};
`;

const Name = styled.div`
  color: var(--font-color);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  ${ellipsisElement}
`;

const DragHandle = styled(IconButton)`
  align-self: center;
  margin: 0 8px;
  color: var(--color-gray-medium);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
