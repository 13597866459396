import type { TUserPreference } from "@streamtimefe/entities";
import { UserPreference } from "@streamtimefe/entities";
import { produce } from "immer";
import { isEmpty, isEqual } from "lodash-es";
import { runSaga } from "redux-saga";
import { call, delay } from "redux-saga/effects";
import { create } from "zustand";

import type { TEntityId } from "../../../entities/Entity";
import { WebAPI } from "../../../lib";
import type { TEntityList, TEntityListKey } from "../../../types";
import { EntityListKey } from "../../../types";
import {
  addGlobalStore,
  authenticationStore,
  storeActionAddListener,
} from "../..";
import type { SagaEffect } from "../../sagaHelpers";
import { sagaError, takeLatestBy } from "../../sagaHelpers";
import { sagaGlobalChannel } from "../../sagaHelpers/sagaGlobalChannel";
import type { ChangedEntity, KeyEntityStoreState } from "..";
import {
  byIdReducer,
  byKeyReducer,
  parseEntityPayload,
  parseRemovedEntities,
} from "..";
import type { TUserPreferenceKeys } from ".";

export const UserPreferenceSagaActions = {
  save: "userPreference/save",
} as const;

export type UserPreferenceSagaEffects = {
  save: SagaEffect<(typeof UserPreferenceSagaActions)["save"], string>;
};

interface UserPreferenceEntityStoreState
  extends KeyEntityStoreState<TUserPreference> {
  setUserPreference: (key: TUserPreferenceKeys, value: any) => void;
  getUserPreference: (key: TUserPreferenceKeys) => string | undefined;
  saveUserPreference: (key: TUserPreferenceKeys) => void;
  sagas: {
    save: (
      effect: UserPreferenceSagaEffects["save"]
    ) => Generator<any, void, any>;
  };
}

export const useUserPreferenceEntityStore =
  create<UserPreferenceEntityStoreState>()(function (set, get) {
    return {
      entities: {},
      entitiesByKey: {},
      receiveEntities({ entityData }: { entityData: TEntityList }) {
        if (isEmpty(entityData[EntityListKey.UserPreference])) return;

        const changedEntities = parseEntityPayload(
          get().entities,
          entityData[EntityListKey.UserPreference]
        );

        get().reduceChangedEntities(changedEntities);
      },
      removeEntities({
        entityName,
        ids,
      }: {
        entityName: TEntityListKey;
        ids: TEntityId[];
      }) {
        if (isEmpty(ids) || entityName !== EntityListKey.UserPreference) return;

        const changedEntities = parseRemovedEntities(get().entities, ids);

        get().reduceChangedEntities(changedEntities);
      },
      reduceChangedEntities(changedEntities: ChangedEntity<TUserPreference>[]) {
        if (isEmpty(changedEntities)) return;

        const entities = byIdReducer(get().entities, changedEntities);
        const entitiesByKey = byKeyReducer(
          get().entitiesByKey,
          changedEntities
        );

        set({
          entities,
          entitiesByKey,
        });
      },
      setUserPreference(key: TUserPreferenceKeys, value: any) {
        const prevEntity = get().entitiesByKey[key];

        if (prevEntity && isEqual(prevEntity.value, value)) {
          return;
        }

        let newEntity: TUserPreference;

        if (prevEntity) {
          newEntity = produce<TUserPreference>(prevEntity, (draft) => {
            draft.value = value;
          });
        } else {
          newEntity = UserPreference.create(key, value);
        }

        get().saveUserPreference(key);

        get().reduceChangedEntities([{ prevEntity, newEntity }]);
      },
      getUserPreference(key: TUserPreferenceKeys) {
        return key in get().entitiesByKey
          ? get().entitiesByKey[key].value
          : undefined;
      },
      saveUserPreference(key: TUserPreferenceKeys) {
        if (authenticationStore().helpers.isProdAdminUser()) return;

        sagaGlobalChannel.put({
          type: UserPreferenceSagaActions.save,
          payload: key,
        } as UserPreferenceSagaEffects["save"]);
      },
      sagas: {
        save: function* (effect: UserPreferenceSagaEffects["save"]) {
          yield delay(500);
          try {
            const userPreference = get().entitiesByKey[effect.payload];

            yield call(WebAPI.UserPererences.save, userPreference);
          } catch (error) {
            sagaError(error);
          }
        },
      },
    };
  });

export function userPreferenceEntityStoreInit() {
  addGlobalStore(
    ["entities", EntityListKey.UserPreference],
    userPreferenceEntityStore
  );
  storeActionAddListener(
    "entitiesReceived",
    userPreferenceEntityStore().receiveEntities
  );
  storeActionAddListener(
    "entitiesRemoved",
    userPreferenceEntityStore().removeEntities
  );

  runSaga({ channel: sagaGlobalChannel }, function* () {
    yield takeLatestBy<UserPreferenceSagaEffects["save"]>(
      UserPreferenceSagaActions.save,
      userPreferenceEntityStore().sagas.save,
      (effect) => {
        return effect.payload;
      }
    );
  });
}

export function userPreferenceEntityStore() {
  return useUserPreferenceEntityStore.getState();
}

export function setUserPreference(key: TUserPreferenceKeys, value: any) {
  userPreferenceEntityStore().setUserPreference(key, value);
}

export function getUserPreference(key: TUserPreferenceKeys) {
  return userPreferenceEntityStore().getUserPreference(key);
}

export function useUserPreferenceEntity(entityId: TEntityId) {
  return useUserPreferenceEntityStore((s) => s.entities[entityId]);
}

export function useUserPreferenceEntityByKey(key: TUserPreferenceKeys) {
  return useUserPreferenceEntityStore((s) => s.entitiesByKey[key]);
}

export function useUserPreference(key: TUserPreferenceKeys) {
  return useUserPreferenceEntityStore((s) =>
    key in s.entitiesByKey ? s.entitiesByKey[key].value : undefined
  );
}
