import { isEmpty } from "lodash-es";
import { takeEvery, call, put, select } from "redux-saga/effects";
import { createJobItemDependancyAPI } from "../../../lib/API/jobItemDependancyAPI";
import {
  ENTITIES_RECEIVED,
  JOB_ITEM_DEPENDANCY_CREATE_SAVE,
  JOB_ITEM_DEPENDANCY_CREATE_SAVING,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  JOB_ITEM_DEPENDANCY_CREATE_SAVED,
  JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR
} from "../../../lib/constants";
import { calculateDependancyLagDays } from "../../../lib/entities/jobItemDependancyEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItemById } from "../../selectors/jobItemSelectors";

function* createJobItemDependancy(action) {
  try {
    const {
      id,
      jobId,
      parentJobItemId,
      childJobItemId,
      dependancyTypeId,
      isFloat
    } = action.payload;

    yield put(createAction(JOB_ITEM_DEPENDANCY_CREATE_SAVING, action.payload));

    const parentJobItem = yield select(selectJobItemById, {
      id: parentJobItemId
    });

    const childJobItem = yield select(selectJobItemById, {
      id: childJobItemId
    });

    const lagDays = calculateDependancyLagDays(
      dependancyTypeId,
      parentJobItem,
      childJobItem
    );

    const jobItemDependancy = {
      id,
      jobId,
      parentJobItemId,
      childJobItemId,
      dependancyTypeId,
      lagDays,
      isFloat
    };

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEM_DEPENDANCIES]: [jobItemDependancy]
      })
    );

    const {
      data: { jobItemDependancies, ...entities }
    } = yield call(createJobItemDependancyAPI, jobItemDependancy);

    yield put(
      createAction(JOB_ITEM_DEPENDANCY_CREATE_SAVED, {
        ...action.payload,
        jobItemDependancies
      })
    );

    if (!isEmpty(entities))
      yield put(createAction(ENTITIES_RECEIVED, entities));
  } catch (error) {
    yield put(
      createAction(JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR, {
        ...action.payload,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchCreateJobItemDependancy() {
  yield takeEvery([JOB_ITEM_DEPENDANCY_CREATE_SAVE], createJobItemDependancy);
}
