import { bugsnagClient } from "@streamtimefe/error";

export const handleWarning = (...args) => {
  console.warn(...args);
};

export const handleError = (error, ...args) => {
  if (error.message === "Network Error") return;

  console.error(error, ...args);

  if (args.length > 0) bugsnagClient?.notify(String([error.message, ...args]));
  else bugsnagClient?.notify(error);
};
