import clsx from "clsx";
import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";

import { anchorCss, buttonCss } from "./Button.css";

type Props = {
  variant?:
    | "base"
    | "primary"
    | "secondary"
    | "link"
    | "inlineLink"
    | "text"
    | "inlineText";
};

export type ButtonProps = Props &
  (
    | (ComponentPropsWithRef<"button"> & { as?: "button" })
    | (ComponentPropsWithRef<"a"> & { as: "a" })
  );

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      className,
      variant = "base",
      as: Component = "button",
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <Component
        ref={ref as any}
        className={clsx(
          buttonCss[variant],
          className,
          Component === "a" &&
            (variant === "base" || variant === "primary") &&
            anchorCss
        )}
        {...(props as any)}
      />
    );
  }
);

Button.displayName = "Button";
