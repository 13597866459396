import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_PHASES,
  JOB_PHASE_DELETE_ERROR,
  JOB_PHASE_DELETING,
  JOB_PHASE_EDIT,
  JOB_PHASE_SAVE,
  JOB_PHASE_SAVE_ERROR,
  JOB_PHASE_SAVED
} from "../../../../lib/constants";
import { getJobId } from "../../../../lib/entities/jobPhaseEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import jobPhaseOrderByJobIdReducer from "./jobPhaseOrderByJobIdReducer";

const idsByJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities)
  };

  nextState = jobPhaseOrderByJobIdReducer(state, nextState, changedEntities);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_PHASES]
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_PHASES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const editJobPhaseReducer = (state, action) => {
  const { jobPhaseId, jobPhase } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: state.byId[jobPhaseId],
      newEntity: jobPhase
    }
  ]);
};

const saveJobPhaseSuccessReducer = (state, action) => {
  const { jobPhase } = action.payload;

  if (jobPhase.id >= 0) return state;

  const changedEntities = [
    {
      prevEntity: state.byId[jobPhase.id]
    }
  ];

  return reduceChangedEntities(state, changedEntities);
};

const undoSaveJobPhaseReducer = (state, action) => {
  const { jobPhaseId, previousJobPhase } = action.payload;
  const prevEntity = state.byId[jobPhaseId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: previousJobPhase
    }
  ]);
};

const saveJobPhaseErrorReducer = (state, action) => {
  if (action.payload.sagaType === JOB_PHASE_SAVE)
    return undoSaveJobPhaseReducer(state, action);

  return state;
};

const deleteJobPhaseReducer = (state, action) => {
  const { jobPhase } = action.payload;
  const prevEntity = state.byId[jobPhase.id];

  return reduceChangedEntities(state, [
    {
      prevEntity
    }
  ]);
};

const undoDeleteJobPhaseReducer = (state, action) => {
  const { jobPhaseId, jobPhase } = action.payload;
  const prevEntity = state.byId[jobPhaseId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: jobPhase
    }
  ]);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_PHASE_EDIT]: editJobPhaseReducer,
    [JOB_PHASE_SAVE]: editJobPhaseReducer,
    [JOB_PHASE_SAVED]: saveJobPhaseSuccessReducer,
    [JOB_PHASE_SAVE_ERROR]: saveJobPhaseErrorReducer,
    [JOB_PHASE_DELETING]: deleteJobPhaseReducer,
    [JOB_PHASE_DELETE_ERROR]: undoDeleteJobPhaseReducer
  }
);
