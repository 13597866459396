import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_REPEATING_LOGGED_TIMES
} from "../../../../lib/constants";
import { getJobId } from "../../../../lib/entities/jobItemEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import sortedIdsByJobIdReducer from "./sortedIdsByJobIdReducer";

const idsByJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities)
  };

  nextState = sortedIdsByJobIdReducer(state, nextState, changedEntities);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_REPEATING_LOGGED_TIMES]
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_REPEATING_LOGGED_TIMES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer
  }
);
