import numeral from "numeral";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { NUMBER_FORMAT_FULL_DEFAULT } from "../../../../lib/constants";
import { currencyEntityType } from "../../../../lib/entities/currencyEntity";
import { entityFieldDecimalType } from "../../../../lib/types/entityTypes";
import {
  formatCurrencyForCommercialDocuments,
  getCurrency
} from "../../../../lib/ui/commercialDocument";
import {
  commercialDocumentLineItemEntityType,
  getLineItemName
} from "../../../../lib/ui/commercialDocumentLineItems";
import Flex from "../../../elements/Flex";
import consumeCommercialDocument from "../CommercialDocumentContext/Consumers/consumeCommercialDocument";

const mapContext = ({ document }) => ({
  documentCurrency: getCurrency(document)
});

const DocumentHeadingSubtotal = ({
  documentLineItem,
  subTotal,
  documentCurrency,
  displayCurrencySymbols
}) => (
  <Wrapper>
    <Label>{getLineItemName(documentLineItem)} Subtotal</Label>
    <Value>
      {displayCurrencySymbols
        ? formatCurrencyForCommercialDocuments(
            subTotal,
            documentCurrency.symbol
          )
        : numeral(subTotal).format(NUMBER_FORMAT_FULL_DEFAULT)}
    </Value>
  </Wrapper>
);

DocumentHeadingSubtotal.propTypes = {
  documentLineItem: commercialDocumentLineItemEntityType.isRequired,
  subTotal: entityFieldDecimalType.isRequired,
  displayCurrencySymbols: PropTypes.bool.isRequired,
  documentCurrency: currencyEntityType.isRequired
};

export default consumeCommercialDocument(mapContext)(DocumentHeadingSubtotal);

export const Wrapper = styled(Flex).attrs({
  className: "HeadingSubtotal"
})`
  width: 100%;
  height: 32px;
  background-color: var(--color-gray-dark);
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
`;

const Label = styled(Flex)`
  padding: 3px 5px 0;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const Value = styled(Flex)`
  width: calc(240px);
  padding: 3px 10px 0;
  color: white;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;
