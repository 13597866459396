import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectJobItemIdsByJobId } from "./jobItemIdsByJobId";

export const selectHasJobItemIdsByJobId = createCachedSelector(
  [selectJobItemIdsByJobId],
  jobItems => jobItems.length > 0
)(selectPropsJobId);

export const useHasJobItemIdsByJobId = jobId =>
  useSelector(state => selectHasJobItemIdsByJobId(state, { jobId }));
