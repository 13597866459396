import * as PropTypes from "prop-types";
import React from "react";
import {
  commercialDocumentLineItemEntityType,
  getLineItemDescription
} from "../../../../lib/ui/commercialDocumentLineItems";
import RichTextFieldEditor from "../../DraftJsEditor/RichTextFieldEditor";
import DocumentLineItemEditConsumer from "../CommercialDocumentContext/Consumers/DocumentLineItemEditConsumer";

class DocumentLineDescription extends React.PureComponent {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    documentLineItem: commercialDocumentLineItemEntityType.isRequired,
    isDisabled: PropTypes.bool,
    doSaveDocumentLineItem: PropTypes.func.isRequired
  };

  static defaultProps = {
    isDisabled: false
  };

  static getDerivedStateFromProps(props, state) {
    const { documentLineItem } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: getLineItemDescription(documentLineItem)
    };
  }

  state = {
    value: "",
    hasFocus: false
  };

  save = () => {
    const { documentLineItem, doSaveDocumentLineItem } = this.props;
    const { value } = this.state;

    if (getLineItemDescription(documentLineItem) !== value)
      doSaveDocumentLineItem(
        documentLineItem.id,
        {
          ...documentLineItem,
          description: value
        },
        documentLineItem
      );
  };

  handleChange = newValue => {
    const { hasFocus, value } = this.state;

    if (hasFocus && value !== newValue) {
      this.setState({
        value: newValue
      });
    }
  };

  handleBlur = () => {
    this.save();

    this.setState({
      hasFocus: false
    });
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true
    });
  };

  render() {
    const { documentLineItem, isDisabled, placeholder } = this.props;
    const { value } = this.state;

    if (isDisabled && value.trim().length === 0) return null;

    return (
      <RichTextFieldEditor
        placeholder={placeholder}
        defaultHtml={getLineItemDescription(documentLineItem)}
        isDisabled={isDisabled}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      />
    );
  }
}

export default DocumentLineItemEditConsumer(DocumentLineDescription);
