import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataJobItemName,
  getMetaDataPreviousJobItemName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobItemNameChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" changed an item name from "}
    {getMetaDataPreviousJobItemName(activityEntry)}
    {" to "}
    <b>{getMetaDataJobItemName(activityEntry)}</b>
  </SystemMessage>
);

JobItemNameChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemNameChanged;
