import { get } from "lodash-es";
import { DataException } from "st-shared/lib";

import { ENTITY_NAME_JOB_ITEM_DEPENDANCIES } from "../../../../lib/constants";
import {
  getChildJobItemId,
  getParentJobItemId,
} from "../../../../lib/entities/jobItemDependancyEntity";
import parseEntityPayload from "../../../helpers/parseEntityPayload";

export default (state, action) => {
  parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEM_DEPENDANCIES]
  ).forEach(({ newEntity }) => {
    const parentJobItemId = newEntity && getParentJobItemId(newEntity);
    const parentJobItem =
      parentJobItemId &&
      get(state, `entities.jobItems.byId.${parentJobItemId}`);

    if (parentJobItemId && !parentJobItem)
      throw new DataException(
        `Parent JobItem (${parentJobItemId}) for JobItemDependancy (${newEntity.id}) not found`
      );

    const childJobItemId = newEntity && getChildJobItemId(newEntity);
    const childJobItem =
      childJobItemId && get(state, `entities.jobItems.byId.${childJobItemId}`);

    if (childJobItemId && !childJobItem)
      throw new DataException(
        `Child JobItem (${childJobItemId}) for JobItemDependancy (${newEntity.id}) not found`
      );
  });
};
