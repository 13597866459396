import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  JOB_CONTACT_CREATE_EDIT,
  JOB_SET_CONTACT
} from "../../../../lib/constants";
import {
  getContactFullName,
  getJobCompany,
  getJobCompanyId,
  getJobContact
} from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJob } from "../../../../redux/selectors/job";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import CompanyContactsSearchMenu from "../../../modules/Contact/CompanyContactsSearchMenu";
import ClearableSettingsField from "../../components/ClearableSettingsField";
import JumbotronSettingsField from "../../components/JumbotronSettingsField";
import { useJobId } from "../../context/JobIdContext";

const JobContact = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const dispatch = useDispatch();

  const contact = getJobContact(job);
  const contactName = getContactFullName(job);
  const companyId = getJobCompanyId(job);

  const ref = useRef();
  const [contactAnchorEl, setContactAnchorEl] = useState(null);

  const editContact = () => {
    if (!isJobEditable) return;
    setContactAnchorEl(ref.current);
  };

  const closeContactMenu = () => {
    setContactAnchorEl(null);
  };

  const handlePickContact = contactId => {
    if (!isJobEditable) return;
    dispatch(createAction(JOB_SET_CONTACT, { jobId, contactId }));
    closeContactMenu();
  };

  const createNewContact = () => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_CONTACT_CREATE_EDIT, {
        jobId,
        company: getJobCompany(job)
      })
    );
    closeContactMenu();
  };

  const openEditContactModal = () => {
    if (!isJobEditable) return;
    dispatch(createAction(JOB_CONTACT_CREATE_EDIT, { jobId, contact }));
  };

  const clearContact = () => {
    if (!isJobEditable) return;
    handlePickContact(null);
  };

  return (
    <JumbotronSettingsField
      title="CONTACT"
      onClick={openEditContactModal}
      disabled={!isJobEditable || contact === null}
      isContact
    >
      <ClearableSettingsField
        ref={ref}
        placeholder={isJobEditable ? contactName || "Add a contact" : ""}
        disabled={!isJobEditable}
        onClick={editContact}
        onClear={clearContact}
        value={contactName}
      />
      <CompanyContactsSearchMenu
        companyId={companyId}
        anchorEl={contactAnchorEl}
        onPickOption={handlePickContact}
        onClose={closeContactMenu}
        onCreate={createNewContact}
        excludeIds={contact && [contact.id]}
      />
    </JumbotronSettingsField>
  );
};

export default JobContact;
