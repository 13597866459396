import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { keySelectorNullable } from "../../../../redux/selectors";
import { AppState } from "../../../AppState";
import { selectPropsId } from "../../../helpers/selectors";
import { selectStateMasterJobItems } from "./selectStateMasterJobItems";

export const selectMasterJobItem = createCachedSelector(
  [selectStateMasterJobItems, selectPropsId],
  (masterJobItems, id) => masterJobItems.byId[id]
)(keySelectorNullable(selectPropsId));

export function useMasterJobItem(id: number) {
  return useSelector((state: AppState) => selectMasterJobItem(state, { id }));
}
