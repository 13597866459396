import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { EmitterSubscriber } from "st-shared/lib";
import styled from "styled-components";

import {
  JOB_TIMELINE_DATE_HEADER_HEIGHT,
  JOB_TIMELINE_ZOOM_IN,
  JOB_TIMELINE_ZOOM_MAX_LEVEL,
  JOB_TIMELINE_ZOOM_MIN_LEVEL,
  JOB_TIMELINE_ZOOM_OUT,
} from "../../../lib/constants";
import { getTodayDate } from "../../../lib/dates";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import Button, { IconButton } from "../../elements/Button";
import ButtonGroup from "../../elements/ButtonGroup";
import Flex from "../../elements/Flex";
import AddIcon from "../../elements/Icons/AddIcon";
import ChevronLeftIcon from "../../elements/Icons/ChevronLeftIcon";
import ChevronRightIcon from "../../elements/Icons/ChevronRightIcon";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import RemoveIcon from "../../elements/Icons/RemoveIcon";
import PopoverDatePicker from "../../modules/Pickers/PopoverDatePicker";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import CallBackConsumer from "../../modules/ScrollContexts/CallBackConsumer";
import { ICON_SIZE } from "../../modules/StyledIcon";

const mapState = (state) => ({
  zoomLevel: selectScheduleUiZoomLevel(state),
});

const mapDispatch = (dispatch) => ({
  doZoomIn: () => {
    dispatch(createAction(JOB_TIMELINE_ZOOM_IN));
  },
  doZoomOut: () => {
    dispatch(createAction(JOB_TIMELINE_ZOOM_OUT));
  },
});

class JobTimelineNavigation extends React.PureComponent {
  ref = React.createRef();

  static propTypes = {
    zoomLevel: PropTypes.number.isRequired,
    doZoomIn: PropTypes.func.isRequired,
    doZoomOut: PropTypes.func.isRequired,
  };

  state = {
    anchorEl: null,
    selectedDate: getTodayDate(),
  };

  get isMaxZoom() {
    const { zoomLevel } = this.props;
    return zoomLevel === JOB_TIMELINE_ZOOM_MAX_LEVEL;
  }

  get isMinZoom() {
    const { zoomLevel } = this.props;
    return zoomLevel === JOB_TIMELINE_ZOOM_MIN_LEVEL;
  }

  open = () => {
    this.setState({ anchorEl: this.ref.current });
  };

  onClose = () => {
    this.setState({ anchorEl: null });
  };

  selectDate = (date) => {
    this.setState({ selectedDate: date });
  };

  render() {
    const { doZoomIn, doZoomOut } = this.props;
    const { anchorEl, selectedDate } = this.state;
    return (
      <CallBackConsumer Context={JobTimelineScrollContext}>
        {({ goToDate, goBackward, goForward, beforeZoom }) => (
          <Wrapper ref={this.ref}>
            <DateNav>
              <Action onClick={goBackward}>
                <ChevronLeftIcon size={ICON_SIZE.XXX_LARGE} />
              </Action>

              <ButtonGroup>
                <ZoomOut
                  disabled={this.isMinZoom}
                  onClick={() => {
                    beforeZoom();
                    doZoomOut();
                  }}
                >
                  <RemoveIcon />
                </ZoomOut>
                <DatePickerButton onClick={this.open}>
                  <DatePickerIcon size={ICON_SIZE.MEDIUM} />
                </DatePickerButton>
                <ZoomIn
                  disabled={this.isMaxZoom}
                  onClick={() => {
                    beforeZoom();
                    doZoomIn();
                  }}
                >
                  <AddIcon />
                </ZoomIn>
              </ButtonGroup>

              <Action onClick={goForward}>
                <ChevronRightIcon size={ICON_SIZE.XXX_LARGE} />
              </Action>
            </DateNav>
            <EmitterSubscriber
              event="jobTimelineSelectDate"
              callback={this.selectDate}
            />
            <PopoverDatePicker
              popoverProps={{
                open: !!anchorEl,
                anchorEl,
                onClose: this.onClose,
                style: { marginTop: -10 },
              }}
              onChange={(date) => {
                this.onClose();
                this.selectDate(date);
                goToDate(date);
              }}
              value={selectedDate}
            />
          </Wrapper>
        )}
      </CallBackConsumer>
    );
  }
}

export default connect(mapState, mapDispatch)(JobTimelineNavigation);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${JOB_TIMELINE_DATE_HEADER_HEIGHT}px;
  border-bottom: 0.5px solid var(--color-gray);
  box-shadow: 0 5px 5px -8px rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-job-timeline-dates-header);
  overflow: hidden;
  padding: 0 15px;
`;

export const DateNav = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  flex-grow: 0;
`;

const ZoomOut = styled(Button)`
  width: 35px;
  padding: 4px;
  &:hover {
    svg {
      color: black;
    }
  }
`;

const Action = styled(IconButton)`
  color: var(--color-gray-dark);
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
`;

const DatePickerButton = styled(Button)`
  width: 60px;
  &:hover {
    svg {
      color: black;
    }
  }
`;

const ZoomIn = styled(Button)`
  width: 35px;
  padding: 4px;
  &:hover {
    svg {
      color: black;
    }
  }
`;
