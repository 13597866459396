import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { entityIdType } from "../../../../lib/types/entityTypes";
import { COMMERCIAL_DOCUMENT_TYPES } from "../../../../lib/ui/commercialDocument";
import {
  getLineItemElementKey,
  lineItemElementType
} from "../../../../lib/ui/lineItemElements";
import LineItemsEmptyState from "../LineItemsEmptyState";

class DocumentLineItems extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    id: entityIdType.isRequired,
    commercialDocumentType: PropTypes.oneOf(values(COMMERCIAL_DOCUMENT_TYPES))
      .isRequired,
    isSortMode: PropTypes.bool.isRequired,
    isMergeMode: PropTypes.bool.isRequired,
    sortedLineItemElements: PropTypes.arrayOf(lineItemElementType).isRequired,
    doSortLineItemsMove: PropTypes.func.isRequired
  };

  get className() {
    const { isSortMode, isMergeMode } = this.props;
    const classes = [];

    if (isSortMode) classes.push("sorting");
    if (isMergeMode) classes.push("merging");

    return classes.join(" ");
  }

  dragEnd = ({ source, destination }) => {
    const { id, doSortLineItemsMove } = this.props;

    if (source && destination)
      doSortLineItemsMove(id, source.index, destination.index);
  };

  render() {
    const {
      children,
      commercialDocumentType,
      isSortMode,
      sortedLineItemElements
    } = this.props;

    if (!sortedLineItemElements.length)
      return (
        <LineItemsEmptyState commercialDocumentType={commercialDocumentType} />
      );

    if (!isSortMode)
      return (
        <Wrapper className={this.className}>
          {sortedLineItemElements.map(lineItemElement => (
            <LineItemContainer key={getLineItemElementKey(lineItemElement)}>
              {children(lineItemElement)}
            </LineItemContainer>
          ))}
        </Wrapper>
      );

    return (
      <DragDropContext onDragEnd={this.dragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <Wrapper
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className={this.className}
            >
              {sortedLineItemElements.map(
                (lineItemElement, index) =>
                  lineItemElement.isDraggable && (
                    <Draggable
                      key={getLineItemElementKey(lineItemElement)}
                      draggableId={getLineItemElementKey(lineItemElement)}
                      index={lineItemElement.orderId}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <LineItemContainer
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          {children(lineItemElement)}
                        </LineItemContainer>
                      )}
                    </Draggable>
                  )
              )}
              {droppableProvided.placeholder}
            </Wrapper>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DocumentLineItems;

const LineItemContainer = styled.div`
  &:last-child .HeadingSubtotal {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: 15px;
  &.sorting {
    padding: 15px 0;
    ${LineItemContainer} {
      > * {
        pointer-events: none;
      }
      margin-top: 15px;
    }
  }
  &.merging {
    .AddLineButton {
      opacity: 0;
      pointer-events: none;
    }
  }
`;
