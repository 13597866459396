import { all, cancel, fork, take, takeEvery } from "redux-saga/effects";

export const takeLatestBy = (pattern, saga, iteratee) =>
  fork(function* effect() {
    const concurrentTasks = {};

    while (true) {
      const action = yield take(pattern);
      const concurrencyKey = iteratee(action);
      const lastTask = concurrentTasks[concurrencyKey];

      if (lastTask) yield cancel(lastTask);

      concurrentTasks[concurrencyKey] = yield fork(saga, action);
    }
  });

export const takeEveryMap = (pattern, forEachSaga, getIterator) =>
  takeEvery(pattern, function* mapEffect(action) {
    yield all(
      getIterator(action).map(value => fork(forEachSaga, action, value))
    );
  });
