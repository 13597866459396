import { Label } from "react-aria-components";

import { BaseButton } from "../button";
import { IconInformation } from "../icons/action";
import { Tooltip, TooltipTrigger } from "../tooltip";
import { fieldLabelStyle } from "./styles.css";

type FieldLabelProps = {
  text: string;
  tooltip?: string;
  isRequired?: boolean;
  isOptional?: boolean;
};

export function FieldLabel({
  text,
  tooltip,
  isRequired,
  isOptional,
}: FieldLabelProps) {
  return (
    <Label className={fieldLabelStyle}>
      {text} {isRequired && <span className="required-indicator">*</span>}
      {!isRequired && isOptional && (
        <span className={"optional-indicator"}>(optional)</span>
      )}
      {tooltip && (
        <TooltipTrigger>
          <BaseButton aria-label="widget group info">
            <IconInformation size={14} />
          </BaseButton>
          <Tooltip>{tooltip}</Tooltip>
        </TooltipTrigger>
      )}
    </Label>
  );
}
