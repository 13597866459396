import { produce } from "immer";
import { get, isEmpty, setWith, uniq, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { EMPTY_ARRAY } from "../../../../lib/constants";
import { simpleCompareDesc } from "../../../../lib/dates";
import {
  getActivityEntryCreatedDate,
  getJobActivityEntryIdPath,
} from "../../../../lib/entities/activityEntryEntity";

export default (prevState, nextState, changedEntities) =>
  produce({ ...nextState }, (draft) => {
    const touchedPaths = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevPath = prevEntity && getJobActivityEntryIdPath(prevEntity);
      const newPath = newEntity && getJobActivityEntryIdPath(newEntity);

      if (prevPath) pushOnce(touchedPaths, prevPath);
      if (newPath) pushOnce(touchedPaths, newPath);
    });

    touchedPaths.forEach((path) => {
      const datesByJobId = uniq(
        get(nextState, `idsByJobId.${path}`, EMPTY_ARRAY)
          .map((id) => get(nextState, `byId.${id}`))
          .filter(Boolean)
          .map(getActivityEntryCreatedDate)
      ).sort(simpleCompareDesc);

      if (!isEmpty(datesByJobId))
        setWith(draft, `datesByJobId.${path}`, datesByJobId, Object);
      else unset(draft, `datesByJobId.${path}`);
    });
  });
