import { isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_ROLE_RATES
} from "../../../lib/constants";
import {
  getRate,
  getRoleIdRateCardIdKeyPath,
  isDeleted
} from "../../../lib/entities/roleRateEntity";
import byIdReducer from "../../helpers/byIdReducer";
import createKeyValueReducer from "../../helpers/createKeyValueReducer";
import createReducer from "../../helpers/createReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";

const rateByRoleIdRateCardIdReducer = createKeyValueReducer(
  getRoleIdRateCardIdKeyPath,
  entity => getRate(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    rateByRoleIdRateCardId: rateByRoleIdRateCardIdReducer(
      state.rateByRoleIdRateCardId,
      changedEntities
    )
  };
};

const receiveEntitiesReducer = (state, action) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, action.payload[ENTITY_NAME_ROLE_RATES]),
    isDeleted
  );

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_ROLE_RATES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer
  }
);
