import { images } from "@streamtimefe/assets";
import { Icon, MdLock } from "st-shared/components";
import { useOrganisationSubscriberUser } from "st-shared/stores";
import { theme } from "st-shared/theme";

import {
  castleImageCss,
  emptyChartCss,
  emptyChartNoPermissionCss,
  emptyChartNoPermissionTitleCss,
  emptyGraphImageCss,
} from "./Chart.css";

export function EmptyChart() {
  return (
    <div className={emptyChartCss}>
      <img className={emptyGraphImageCss} alt="" src={images.emptyStateGraph} />
      <img className={castleImageCss} alt="" src={images.castleBackground} />
    </div>
  );
}

export function EmptyChartNoPermissions() {
  const subscriber = useOrganisationSubscriberUser();

  return (
    <div className={emptyChartCss}>
      <div className={emptyChartNoPermissionCss}>
        <Icon icon={MdLock} size={30} color={theme.color.black} />
        <span className={emptyChartNoPermissionTitleCss}>
          Sorry, you don’t have permission to see this report.
        </span>
        <span>
          If you believe there’s a problem, have a chat to{" "}
          <span style={{ fontWeight: theme.font.weight.bold }}>
            {subscriber.displayName}.
          </span>
        </span>
      </div>
    </div>
  );
}
