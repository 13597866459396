import { EntityClass, RoleRate } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";

import { createStore } from "../../../core";
import { EntityStore } from "../../EntityStore";

export type TRoleRateIndexStore = {
  roleIdRateCardIdIndex: RoleRate.calculate.TRoleIdRateCardIdIndex;
};

export const useRoleRateIndexStore = createStore<TRoleRateIndexStore>(() => ({
  roleIdRateCardIdIndex: {},
}));

EntityStore.store.subscribe(
  (store) => store[EntityClass.RoleRate].byId,
  (roleRates) => {
    const roleIdRateCardIdIndex =
      RoleRate.calculate.createRoleIdRateCardIdIndex(
        dictionaryToArray(roleRates)
      );

    useRoleRateIndexStore.setState({ roleIdRateCardIdIndex });
  }
);
