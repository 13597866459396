import { put, call } from "redux-saga/effects";
import { deleteLabelAPI } from "../../../lib/API/labelsAPI";
import {
  LABELS_DELETE,
  LABELS_DELETING,
  LABELS_DELETE_ERROR,
  ENTITIES_RECEIVED,
  LABELS_DELETED
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* deleteLabel(action) {
  const { labelId } = action.payload;

  try {
    yield put(
      createAction(LABELS_DELETING, {
        labelId
      })
    );

    const { data } = yield call(deleteLabelAPI, labelId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(LABELS_DELETED, {
        labelId
      })
    );
  } catch (error) {
    yield put(
      createAction(LABELS_DELETE_ERROR, {
        labelId,
        sagaType: action.type,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchDeleteLabel() {
  yield takeLatestBy(
    [LABELS_DELETE],
    deleteLabel,
    action => action.payload.labelId
  );
}
