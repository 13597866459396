import { EnumOption } from "../../core";
import type { TStatusType } from "../../other";
import { StatusType } from "../../other";
import type { TJobGroupStatus, TJobGroupStatusEnum } from "./JobGroupStatus";
import { JobGroupStatusEnum } from "./JobGroupStatus";

export function create(id: TJobGroupStatusEnum): TJobGroupStatus {
  return { id, name: getName(id) };
}

export function isOpen(jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum) {
  return EnumOption.getId(jobGroupStatus) === JobGroupStatusEnum.Open;
}

export function isClosed(
  jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum
) {
  return EnumOption.getId(jobGroupStatus) === JobGroupStatusEnum.Closed;
}

export function isDeleted(
  jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum
) {
  return EnumOption.getId(jobGroupStatus) === JobGroupStatusEnum.Deleted;
}

export function getName(jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum) {
  const id = EnumOption.getId(jobGroupStatus);
  switch (id) {
    case JobGroupStatusEnum.Open:
      return "Open";
    case JobGroupStatusEnum.Closed:
      return "Closed";
    case JobGroupStatusEnum.Deleted:
      return "Deleted";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown Job Group Status Id: ${id}`);
      return "";
  }
}

export function getNameAction(
  jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum
) {
  const id = EnumOption.getId(jobGroupStatus);
  switch (id) {
    case JobGroupStatusEnum.Open:
      return "Open";
    case JobGroupStatusEnum.Closed:
      return "Close";
    case JobGroupStatusEnum.Deleted:
      return "Delete";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown Job Group Status Id: ${id}`);
      return "";
  }
}

export function mapStatusTypeToJobGroupStatus(status: TStatusType) {
  switch (status) {
    case StatusType.Pause:
      return JobGroupStatusEnum.Open;
    case StatusType.Play:
      return JobGroupStatusEnum.Open;
    case StatusType.Done:
      return JobGroupStatusEnum.Closed;
    default:
      return JobGroupStatusEnum.Closed;
  }
}

export function mapJobGroupStatusToStatusType(
  jobGroupStatus: TJobGroupStatus | TJobGroupStatusEnum
) {
  switch (EnumOption.getId(jobGroupStatus)) {
    case JobGroupStatusEnum.Open:
      return StatusType.Play;
    case JobGroupStatusEnum.Closed:
      return StatusType.Done;
    default:
      return StatusType.Done;
  }
}
