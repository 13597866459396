import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  DATA_VALUE_HANDLE_RIGHT,
  JOB_ITEM_RESIZE,
  JOB_ITEM_RESIZE_CANCEL,
  JOB_ITEM_RESIZE_END,
  JOB_ITEM_RESIZE_START
} from "../../../lib/constants";
import { getPointerClientOffset, subtractCoords } from "../../../lib/dom";
import {
  getTargetJobItemBarDays,
  getTargetJobItemBarId,
  getTargetJobItemBarJobId,
  getTargetResizeHandle,
  getTargetResizeHandleType
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import { createSnapshotId } from "../../../redux/helpers/snapshotIds";

const mapState = null;

const mapDispatch = dispatch => ({
  doStartResizeBar: payload =>
    dispatch(createAction(JOB_ITEM_RESIZE_START, payload)),
  doResizeBar: payload => dispatch(createAction(JOB_ITEM_RESIZE, payload)),
  doEndResizeBar: payload =>
    dispatch(createAction(JOB_ITEM_RESIZE_END, payload)),
  doCancelResizeBar: payload =>
    dispatch(createAction(JOB_ITEM_RESIZE_CANCEL, payload))
});

export default WrappedComponent => {
  class JobItemBarResizeHandlers extends React.PureComponent {
    resizeItem = null;

    isResizing = false;

    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      dayWidth: PropTypes.number.isRequired,
      getScrollOffsetX: PropTypes.func.isRequired,
      doStartResizeBar: PropTypes.func.isRequired,
      doResizeBar: PropTypes.func.isRequired,
      doEndResizeBar: PropTypes.func.isRequired,
      doCancelResizeBar: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      className: PropTypes.string
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: ""
    };

    getPointerDistance(e) {
      const { origin } = this.resizeItem;
      const pointerPosition = getPointerClientOffset(e);

      return subtractCoords(pointerPosition, origin.pointerPosition);
    }

    getTargetDays(e) {
      const { getScrollOffsetX, dayWidth } = this.props;
      const { resizeType, origin } = this.resizeItem;
      const pointerDistance = this.getPointerDistance(e);
      const scrollDistanceX = getScrollOffsetX() - origin.scrollOffsetX;
      const width = pointerDistance.x + scrollDistanceX;
      const dayDiff = Math.round(width / dayWidth);

      if (resizeType === DATA_VALUE_HANDLE_RIGHT)
        return Math.max(origin.days + dayDiff, 0);
      return Math.max(origin.days - dayDiff, 0);
    }

    onPointerDown = e => {
      const {
        readOnly,
        getScrollOffsetX,
        doStartResizeBar,
        onPointerDown
      } = this.props;
      const jobId = getTargetJobItemBarJobId(e);
      const jobItemId = getTargetJobItemBarId(e);
      const isResizeEvent = jobItemId && !!getTargetResizeHandle(e);
      const days = jobItemId && getTargetJobItemBarDays(e);

      if (!readOnly && jobItemId && isResizeEvent) {
        const resizeType = getTargetResizeHandleType(e);
        const pointerPosition = getPointerClientOffset(e);
        const scrollOffsetX = getScrollOffsetX();

        this.resizeItem = {
          resizeType,
          jobItemId,
          days,
          jobId,
          snapshotId: createSnapshotId(),
          origin: {
            scrollOffsetX,
            pointerPosition,
            days
          }
        };

        doStartResizeBar(this.resizeItem);

        this.isResizing = true;
        this.forceUpdate();
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = e => {
      const { props, resizeItem: prevResizeItem } = this;
      const { onPointerMove, doResizeBar } = props;

      if (this.isResizing) {
        const days = this.getTargetDays(e);

        if (prevResizeItem.days !== days) {
          this.resizeItem = {
            ...prevResizeItem,
            days,
            daysChanged: days - prevResizeItem.days
          };

          doResizeBar(this.resizeItem);
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = e => {
      const { resizeItem } = this;
      const { doEndResizeBar, onPointerUp } = this.props;

      if (this.isResizing) {
        doEndResizeBar({
          ...resizeItem,
          hasChanged: resizeItem.origin.days !== resizeItem.days
        });

        this.resizeItem = null;
        this.isResizing = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = e => {
      const { doCancelResizeBar, onLostPointerCapture } = this.props;

      if (this.isResizing) {
        doCancelResizeBar(this.resizeItem);

        this.resizeItem = null;
        this.isResizing = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const classes = className ? [className] : [];

      if (this.isResizing) {
        const { resizeType } = this.resizeItem;
        classes.push("resizing");
        classes.push(resizeType);
      }

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp
        // onLostPointerCapture: this.onLostPointerCapture
      };
    }

    render() {
      const { props, className, handlers } = this;
      return <WrappedComponent {...{ ...props, className, ...handlers }} />;
    }
  }

  return connect(mapState, mapDispatch)(JobItemBarResizeHandlers);
};
