import { plate, slate } from "st-shared/external";

import { TColumnInputElement } from "../types";

export const removeColumnInput = <V extends plate.Value>(
  editor: plate.PlateEditor<V>,
  path: slate.Path
) =>
  plate.withoutNormalizing(editor, () => {
    const node = plate.getNode<TColumnInputElement>(editor, path);
    if (!node) return;
    plate.removeNodes(editor, {
      at: path,
    });
  });
