import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Input } from "st-shared/components";

import { reportingStore } from "../../state/stores/reportingStore";
import { useReportingSavedSegmentName } from "../../state/stores/savedSegmentSelectors";
import { titleInputCss } from "./NameInput.css";

export function NameInput() {
  const name = useReportingSavedSegmentName();
  const [inputName, setInputName] = useState(name);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputName(name);
  }, [name]);

  function onFocus() {
    setInputName(inputName);
  }

  function onBlur() {
    if (inputName.length > 0) {
      reportingStore().savedSegment.actions.setName(inputName);
    } else {
      setInputName(name);
    }
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setInputName(e.target.value);
  }

  return (
    <Input
      ref={ref}
      className={titleInputCss}
      value={inputName}
      onChange={onChange}
      placeholder="Report Name..."
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
