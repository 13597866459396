import { createIcon } from "../../icon/Icon";

export const IconPersonStar = createIcon(
  <g>
    <path d="m16.9075 10.4118-.5687 2.5882 2.1612-1.3724 2.1612 1.3724-.5687-2.5882 1.9075-1.74075-2.52-.23026-.98-2.44079-.98 2.44079-2.52.23026z" />
    <g>
      <path d="m7.175 10.825c.78333.7833 1.725 1.175 2.825 1.175s2.0417-.3917 2.825-1.175 1.175-1.725 1.175-2.825-.3917-2.04167-1.175-2.825-1.725-1.175-2.825-1.175-2.04167.39167-2.825 1.175-1.175 1.725-1.175 2.825.39167 2.0417 1.175 2.825zm4.2375-1.4125c-.3917.39167-.8625.5875-1.4125.5875s-1.0208-.19583-1.4125-.5875-.5875-.8625-.5875-1.4125.1958-1.02083.5875-1.4125.8625-.5875 1.4125-.5875 1.0208.19583 1.4125.5875.5875.8625.5875 1.4125-.1958 1.02083-.5875 1.4125z" />
      <path d="m2 17.2v2.8h16v-2.8c0-.5667-.1458-1.0875-.4375-1.5625s-.6792-.8375-1.1625-1.0875c-1.0333-.5167-2.0833-.9042-3.15-1.1625s-2.15-.3875-3.25-.3875-2.18333.1292-3.25.3875-2.11667.6458-3.15 1.1625c-.48333.25-.87083.6125-1.1625 1.0875s-.4375.9958-.4375 1.5625zm14 0v.8h-12v-.8c0-.1833.04583-.35.1375-.5s.2125-.2667.3625-.35c.9-.45 1.80833-.7875 2.725-1.0125.9167-.225 1.8417-.3375 2.775-.3375s1.8583.1125 2.775.3375 1.825.5625 2.725 1.0125c.15.0833.2708.2.3625.35s.1375.3167.1375.5z" />
    </g>
  </g>,
  "IconPersonStar"
);
