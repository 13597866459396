import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { getJobCurrencyTotalLoggedTimeExTax } from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectJobCalculatedTotalLoggedTimeExTax } from "./selectJobCalculatedTotalLoggedTimeExTax";
import { selectJob } from "./index";

export const selectJobCalculatedUnplannedLoggedTimeExTax = createCachedSelector(
  [selectJob, selectJobCalculatedTotalLoggedTimeExTax],
  (job, calculatedTotalLoggedTimeExTax) => {
    if (!job.isBillable) return 0;

    return numeral(getJobCurrencyTotalLoggedTimeExTax(job))
      .subtract(numeral(calculatedTotalLoggedTimeExTax).value())
      .value();
  }
)(selectPropsJobId);

export const useCalculatedUnplannedLoggedTimeExTax = jobId =>
  useSelector(state =>
    selectJobCalculatedUnplannedLoggedTimeExTax(state, { jobId })
  );
