import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateJobDetailsUi } from "./index";

export const selectJobDetailsBurnUpData = createSelector(
  [selectStateJobDetailsUi],
  (jobDetails) => jobDetails.burnUpData
);

export const useJobDetailsBurnUpData = () =>
  useSelector(selectJobDetailsBurnUpData);
