import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectActiveUsersWithRoleId } from "../../user/selectors/selectActiveUsersWithRoleId";

export function selectRoleCostRate(
  state: AppState,
  { roleId }: { roleId: number }
) {
  const users = selectActiveUsersWithRoleId(state, { roleId });
  if (users.length === 0) {
    return 0;
  }

  const totalCostRate = users
    .map((user) => user.costRate)
    .map(Number)
    .reduce((value, total) => total + value, 0);

  return Math.round(totalCostRate / users.length);
}

export function useRoleCostRate(roleId: number) {
  return useSelector((state: AppState) =>
    selectRoleCostRate(state, { roleId })
  );
}
