import clsx from "clsx";
import { useState } from "react";

import { IconButton, IconType, StExpandCircleOutline } from "../../components";
import { TStatusType } from "../../types";
import { getColor, getIcon } from "./lib";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  statusType: TStatusType;
  size: number;
  className?: string;
  hoveredClassName?: string;
}

export function OpenSetStatusButton({
  onClick,
  disabled,
  statusType,
  size,
  className,
  hoveredClassName,
}: Props) {
  const [hovered, setHovered] = useState(false);

  let icon: IconType = StExpandCircleOutline;
  let color: string | undefined = undefined;

  if (!hovered) {
    icon = getIcon(statusType);
    color = getColor(statusType);
  }

  return (
    <IconButton
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={disabled}
      iconProps={{ icon, size, color }}
      className={clsx(className, hovered && hoveredClassName)}
    />
  );
}
