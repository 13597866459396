import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_RESIZE_HANDLE,
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import Flex from "../../elements/Flex";
import { SCHEDULE_BAR_BACKGROUND_STYLES } from "./ScheduleBars";

const resizeHandleAttrName = getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE);

class ScheduleBarResizeHandle extends React.PureComponent {
  static propTypes = {
    left: PropTypes.bool,
    right: PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    left: false,
    right: false,
    className: ""
  };

  get resizeHandleValue() {
    const { left } = this.props;
    if (left) return DATA_VALUE_HANDLE_LEFT;
    return DATA_VALUE_HANDLE_RIGHT;
  }

  get className() {
    const { left, right, className } = this.props;
    const classes = className ? [className] : [];

    if (left) classes.push("left");
    else if (right) classes.push("right");

    return classes.join(" ");
  }

  render() {
    const { className, resizeHandleValue } = this;
    return (
      <ResizeHandleWrapper
        {...{ className, [resizeHandleAttrName]: resizeHandleValue }}
      >
        |||
      </ResizeHandleWrapper>
    );
  }
}

export default ScheduleBarResizeHandle;

export const ResizeHandleWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  margin: 10px 0;
  height: 14px;
  width: 30px;
  align-items: center;
  color: black;
  z-index: 2;
  opacity: 0;
  font-size: 7.2px;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 1.2;
  transition: opacity 0.2s, margin 0.2s;
  cursor: ew-resize;
  ${SCHEDULE_BAR_BACKGROUND_STYLES}
  &.left {
    left: 0;
    border-right: 1px solid var(--color-gray-light);
    border-radius: 15px 0 0 15px;
    padding-right: 5px;
    justify-content: flex-end;
  }
  &.right {
    right: 0;
    border-left: 1px solid var(--color-gray-light);
    border-radius: 0 15px 15px 0;
    padding-left: 5px;
    justify-content: flex-start;
  }
  &.complete {
    color: white;
  }
`;
