import * as PropTypes from "prop-types";
import { useRef, useState } from "react";
import { searchWordMatches } from "st-shared/lib";
import styled from "styled-components";

import { REGEX_HTML_EMPTY_TAGS } from "../../../lib/constants";
import { useOnce } from "../../../lib/hooks/useOnce";
import { useMentions } from "../../../redux/selectors/userSelectors";
import { IconButton } from "../../elements/Button";
import ClearIcon from "../../elements/Icons/ClearIcon";
import SendIcon from "../../elements/Icons/SendIcon";
import Popper from "../Popper";
import { ICON_SIZE } from "../StyledIcon";
import Tooltip from "../Tooltip";
import ValidationMessages from "../ValidationMessages";
import DraftJsContent from "./DraftJsContent";
import DraftJsEditor, { defaultHtmlParser } from "./index";
import { createCommentPlugins } from "./plugins";

const htmlParser = (html) =>
  defaultHtmlParser(html).replace(REGEX_HTML_EMPTY_TAGS, "");

const suggestionsFilter = (searchQuery = "", suggestions = []) => {
  const queryString = searchQuery.toLowerCase();
  const filteredSuggestions = suggestions.filter(
    (suggestion) =>
      !queryString || searchWordMatches(suggestion.searchString, queryString)
  );
  const length =
    filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
  return filteredSuggestions.slice(0, length);
};

const CommentEditor = ({
  onSave,
  onCancel,
  className,
  formValidationMessages,
  ...props
}) => {
  const mentions = useMentions();

  const draftPlugins = useOnce(() => {
    const { StaticToolbar, MentionSuggestions, plugins } =
      createCommentPlugins();
    return { StaticToolbar, MentionSuggestions, plugins };
  });

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [toolbarAnchorEl, setToolbarAnchorEl] = useState(null);
  const toolbarRef = useRef();

  const onSearchChange = ({ value }) =>
    setSuggestions(suggestionsFilter(value, mentions));

  const setSuggestionOpen = (open) => setSuggestionsOpen(open);

  const onFocus = () => setToolbarAnchorEl(toolbarRef.current);

  const onBlur = () => setToolbarAnchorEl(null);

  return (
    <CommentWrapper className={className}>
      <EditorContainer ref={toolbarRef}>
        <DraftJsEditor
          plugins={draftPlugins.plugins}
          htmlParser={htmlParser}
          placeholder="Write a comment..."
          onFocus={onFocus}
          onBlur={onBlur}
          saveOnEnter={onSave}
          {...props}
        />
      </EditorContainer>
      <CommentActions>
        {onCancel && (
          <Tooltip title={<TooltipTitle>Cancel</TooltipTitle>} placement="top">
            <IconButton onClick={onCancel}>
              <ClearIcon size={ICON_SIZE.X_LARGE} />
            </IconButton>
          </Tooltip>
        )}
        <ValidationMessages messages={formValidationMessages}>
          <IconButton
            onClick={onSave}
            disabled={Boolean(formValidationMessages.length)}
          >
            <SendIcon size={ICON_SIZE.X_LARGE} />
          </IconButton>
        </ValidationMessages>
      </CommentActions>
      <Popper anchorEl={toolbarAnchorEl} placement="top-start">
        <draftPlugins.StaticToolbar />
      </Popper>
      <draftPlugins.MentionSuggestions
        onSearchChange={onSearchChange}
        suggestions={suggestions}
        open={suggestionsOpen}
        onOpenChange={setSuggestionOpen}
      />
    </CommentWrapper>
  );
};

CommentEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  formValidationMessages: PropTypes.arrayOf(PropTypes.string),
};

CommentEditor.defaultProps = {
  onCancel: null,
  className: "",
  formValidationMessages: [],
};

export default CommentEditor;

const CommentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

const EditorContainer = styled.div`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  ${DraftJsContent} {
    background: var(--color-jonsnow);
    padding: 6px 8px;
    overflow: auto;

    ul {
      margin: 0;
    }

    ol {
      margin: 0;
    }
  }
`;

const CommentActions = styled.div`
  display: flex;
  align-items: end;

  margin: 0 3px;
  & > *:not(:last-child) {
    margin-right: 3px;
  }
`;

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray);
  font-size: 10px;
  font-weight: bold;
`;
