import { Entity, type TEntityClassMap } from "@streamtimefe/entities";
import type { Prettify, ValueOrSelector } from "@streamtimefe/types";
import { type Draft, produce } from "immer";
import { isFunction, isNonNullish } from "remeda";

import { storeSyncRemoveModel } from "../../../sync";
import type { TStoreGetter, TStoreSetter } from "../../core";
import { EntityStore, type TEntityStoreClass } from "../EntityStore";
import type { TSingleEntityStore } from "./SingleEntityStore";

function kv<K extends PropertyKey, V>(
  k: K,
  v: V
): { [P in K]: { [Q in P]: V } }[K] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { [k]: v } as any;
}

export type TSingleEntityActions<C extends TEntityStoreClass> = Prettify<
  ReturnType<typeof createSingleEntityActions<C>>
>;

export function createSingleEntityActions<C extends TEntityStoreClass>(
  entityClass: C,
  set: TStoreSetter<TSingleEntityStore<C>>,
  get: TStoreGetter<TSingleEntityStore<C>>
) {
  function setInitials(entity: TEntityClassMap[C], updateGlobal: boolean) {
    set((s) => {
      s.entity = entity as Draft<TEntityClassMap[C]>;
      s.modified = null;
      s.updateGlobal = updateGlobal;
    });
  }

  function getEntity() {
    return get().modified ?? get().entity;
  }

  function hasModifiedEntity() {
    return isNonNullish(get().modified);
  }

  function unsetModifiedEntity() {
    set((s) => {
      s.modified = null;
    });
  }

  function updateEntity(entityOrSelector: ValueOrSelector<TEntityClassMap[C]>) {
    if (isFunction(entityOrSelector)) {
      entityOrSelector = entityOrSelector(get().modified ?? get().entity);
    }
    const newEntity = produce(entityOrSelector, (s) => {
      s.meta_data_is_local = true;
    });
    set((s) => {
      s.modified = newEntity as Draft<TEntityClassMap[C]>;
    });
    if (get().updateGlobal) {
      EntityStore.actions.entityUpdate(entityClass, newEntity, true);
    }
  }

  function updateFromStore(entity: TEntityClassMap[C]) {
    if (entity && !entity.meta_data_is_local) {
      set((s) => {
        s.entity = entity as Draft<TEntityClassMap[C]>;

        if (s.modified && Entity.isNew(s.modified) && !Entity.isNew(s.entity)) {
          s.modified.id = entity.id;
        }
      });
    }
  }

  function removeEntity() {
    storeSyncRemoveModel(entityClass, getEntity().id);
  }

  return {
    setInitials,
    updateFromStore,
    ...kv(`get${entityClass}`, getEntity),
    ...kv(`hasModified${entityClass}`, hasModifiedEntity),
    ...kv(`unsetModified${entityClass}`, unsetModifiedEntity),
    ...kv(`update${entityClass}`, updateEntity),
    ...kv(`remove${entityClass}`, removeEntity),
  };
}
