import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsUserId } from "../../../helpers/selectors";
import { selectStateUsers } from "./selectStateUsers";

export const selectUser = createCachedSelector(
  [selectStateUsers, selectPropsUserId],
  (users, id) => users.byId[id]
)(selectPropsUserId);

export function useUser(userId: number) {
  return useSelector((state: AppState) => selectUser(state, { userId }));
}
