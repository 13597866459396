import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { INVOICE_FETCH_REQUEST } from "../../lib/constants";
import { isInvoiceTypeCreditNote } from "../../lib/entities/invoiceEntity";
import createAction from "../../redux/helpers/createAction";
import {
  selectInvoiceByInvoiceId,
  selectInvoiceUi,
} from "../../redux/selectors/invoiceSelectors";
import LoadingSpinner from "../elements/LoadingSpinner";
import PageWrapper from "../elements/PageWrapper";
import BranchDetails from "../modules/CommercialDocuments/BranchDetails";
import InvoiceContextProvider from "../modules/CommercialDocuments/CommercialDocumentContext/InvoiceContextProvider";
import CompanyDetails from "../modules/CommercialDocuments/CompanyDetails";
import DetailTable from "../modules/CommercialDocuments/DetailTable";
import DocumentIntro from "../modules/CommercialDocuments/DocumentIntro";
import DocumentJobNumberRow from "../modules/CommercialDocuments/DocumentJobNumberRow";
import DocumentName from "../modules/CommercialDocuments/DocumentName";
import DocumentPurchaseOrderNumberRow from "../modules/CommercialDocuments/DocumentPurchaseOrderNumberRow";
import DocumentTerms from "../modules/CommercialDocuments/DocumentTerms";
import { useCommercialDocumentEditing } from "../modules/CommercialDocuments/hooks/useCommercialDocumentEditing";
import LanguageOption from "../modules/CommercialDocuments/LanguageOption";
import DocumentLabel from "../modules/CommercialDocuments/Layout/DocumentLabel";
import PageBody from "../modules/CommercialDocuments/Layout/PageBody";
import PageContent from "../modules/CommercialDocuments/Layout/PageContent";
import Section from "../modules/CommercialDocuments/Layout/Section";
import UndoMerge from "../modules/CommercialDocuments/UndoMerge";
import { useParamEntityId } from "../router/useParamEntityId";
import InvoiceDateRow from "./InvoiceDateRow";
import InvoiceDueDateRow from "./InvoiceDueDateRow";
import InvoiceLineItems from "./InvoiceLineItems";
import InvoiceNumberRow from "./InvoiceNumberRow";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import InvoicePaymentSummary from "./InvoicePaymentSummary";
import InvoicePreviousInvoiceSummary from "./InvoicePaymentSummary/InvoicePreviousInvoiceSummary";
import InvoiceReferenceRow from "./InvoiceReferenceRow";
import InvoiceTotals from "./InvoiceTotals";

function InvoicePage() {
  const entityId = useParamEntityId();

  const pending = useSelector(selectInvoiceUi).pending;
  const invoice = useSelector((state) =>
    selectInvoiceByInvoiceId(state, { invoiceId: entityId })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction(INVOICE_FETCH_REQUEST, { invoiceId: entityId }));
  }, [entityId]);

  const { focusWithinProps } = useCommercialDocumentEditing();

  return (
    <PageWrapper {...focusWithinProps}>
      {pending && <LoadingSpinner />}
      {!pending && invoice && (
        <PageBody>
          <PageContent>
            <InvoiceContextProvider key={entityId} id={entityId}>
              <UndoMerge />
              <Section>
                <DocumentLabel>
                  <LanguageOption
                    optionKey={
                      isInvoiceTypeCreditNote(invoice)
                        ? "creditNote"
                        : "invoice"
                    }
                  />
                </DocumentLabel>
              </Section>
              <Section justify="space-between">
                <BranchDetails />
                <CompanyDetails />
              </Section>
              <Section>
                <DetailTable>
                  <DocumentJobNumberRow />
                  <InvoiceNumberRow />
                  <DocumentPurchaseOrderNumberRow />
                  <InvoiceDateRow />
                  {!isInvoiceTypeCreditNote(invoice) && <InvoiceDueDateRow />}
                  <InvoiceReferenceRow />
                </DetailTable>
              </Section>
              <Section direction="column">
                <DocumentName />
                <DocumentIntro />
              </Section>
              <InvoiceLineItems />
              <InvoiceTotals invoiceId={entityId} />
              <InvoicePaymentSummary invoiceId={entityId} />
              <InvoicePreviousInvoiceSummary invoiceId={entityId} />
              <InvoicePaymentDetails />
              <DocumentTerms />
            </InvoiceContextProvider>
          </PageContent>
        </PageBody>
      )}
    </PageWrapper>
  );
}

export default InvoicePage;
