import {
  EntityClass,
  JobGroupStatistic,
  type TEntityId,
} from "@streamtimefe/entities";
import { useMemo } from "react";
import { isDefined } from "remeda";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { JobGroupBreakdownStore } from "./JobGroupBreakdown.store";

export const useJobGroupBreakdownEntity = createUseEntityStoreEntity(
  EntityClass.JobGroupBreakdown
);

export const getJobGroupBreakdownEntity = createGetEntityStoreEntity(
  EntityClass.JobGroupBreakdown
);

export const useJobGroupBreakdown = createUseSingleEntity(
  JobGroupBreakdownStore
);

export function useJobGroupBreakdownStore() {
  return JobGroupBreakdownStore.useStore();
}

export function useJobGroupBreakdownActions() {
  return JobGroupBreakdownStore.useActions();
}

export function useJobGroupBreakdownEntitiesByJobId(jobId: TEntityId) {
  const jobGroupBreakdowns = useEntityStoreEntities(
    EntityClass.JobGroupBreakdown
  );

  return useMemo(
    () =>
      Object.values(jobGroupBreakdowns)
        .filter(isDefined)
        .filter((breakdown) => breakdown.jobId === jobId),
    [jobGroupBreakdowns, jobId]
  );
}

export function useJobGroupBreakdownSummaryByJobId(jobId: TEntityId) {
  const jobGroupBreakdowns = useJobGroupBreakdownEntitiesByJobId(jobId);

  return useMemo(
    () => JobGroupStatistic.createSummary(jobGroupBreakdowns),
    [jobGroupBreakdowns]
  );
}
