import { useDispatch } from "react-redux";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";

import { JOB_ITEM_USER_STATUS_CHANGE } from "../../../lib/constants";
import {
  createJobItemUserStatusType,
  getJobItemUserNormalizedStatus,
} from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import { StatusComponent } from "../components/StatusComponent";
import { useJobId } from "../context/JobIdContext";
import { useJobItemUserId } from "../context/JobItemUserIdContext";

const ItemUserStatus = () => {
  const jobId = useJobId();
  const jobItemUserId = useJobItemUserId();
  const jobItemUser = useJobItemUser(jobItemUserId);
  const isJobEditable = useIsJobEditable(jobId);
  const statusId = getJobItemUserNormalizedStatus(jobItemUser);

  const dispatch = useDispatch();

  const doStatusChange = (status) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_ITEM_USER_STATUS_CHANGE, {
        id: jobItemUserId,
        jobItemUser,
        jobItemUserStatus: createJobItemUserStatusType(status),
      })
    );
  };

  const setPlanning = () => doStatusChange(JobItemUserStatuses.Planning);

  const setSchedule = () => doStatusChange(JobItemUserStatuses.Scheduled);

  const setComplete = () => doStatusChange(JobItemUserStatuses.Complete);

  return (
    <StatusComponent
      statusId={statusId}
      setPlanning={setPlanning}
      setSchedule={setSchedule}
      setComplete={setComplete}
      disabled={!isJobEditable}
    />
  );
};

export default ItemUserStatus;
