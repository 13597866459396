import { get, isEqual } from "lodash-es";
import { EMPTY_ARRAY } from "../../lib/constants";

export default (
  state,
  mergeEntities = [],
  shouldRemove,
  lookupPath,
  mergeFn
) => {
  const changedEntities = [];

  mergeEntities.forEach(mergeEntity => {
    if (!shouldRemove(mergeEntity)) {
      get(state, `${lookupPath}.${mergeEntity.id}`, EMPTY_ARRAY).forEach(id => {
        const prevEntity = state.byId[id];
        const newEntity = mergeFn(prevEntity, mergeEntity);
        if (!isEqual(prevEntity, newEntity))
          changedEntities.push({ prevEntity, newEntity });
      });
    }
  });

  return changedEntities;
};
