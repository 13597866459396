import { webapi } from "@streamtimefe/state/api";
import { VitallyEnum } from "@streamtimefe/entities";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_DETAILS_SET_BOTTOM_SECTION_TAB } from "../../lib/constants";
import { JOB_BOTTOM_SECTION_TABS } from "../../lib/constants/jobDetails";
import createAction from "../../redux/helpers/createAction";
import { useJobDetailsBottomSectionTab } from "../../redux/selectors/jobDetails/ui/bottomSectionTab";
import { useIsJobEditable } from "../../redux/selectors/jobDetails/ui/isJobEditable";
import { useFullOrderedJobPhaseJobItemIdsByJobId } from "../../redux/selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";
import { useJobId } from "./context/JobIdContext";
import JobPlan from "./JobPlan";
import ExpandCollapsePhaseButton from "./JobPlan/ExpandCollapsePhaseButton";
import ReorderItems from "./JobPlan/ReorderItems";
import ViewScheduleButton from "./JobPlan/ViewScheduleButton";
import JobTimeline from "./JobTimeline";

const BottomSection = () => {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const tab = useJobDetailsBottomSectionTab();
  const dispatch = useDispatch();
  const orderedJobPhaseJobItemIds =
    useFullOrderedJobPhaseJobItemIdsByJobId(jobId);

  const setTab = (newTab) =>
    dispatch(createAction(JOB_DETAILS_SET_BOTTOM_SECTION_TAB, newTab));

  if (
    !isJobEditable &&
    orderedJobPhaseJobItemIds.length === 1 &&
    orderedJobPhaseJobItemIds[0].jobItemIds.length === 0
  )
    return null;

  return (
    <Container id="BottomSection" className="uiHelperJobPlan">
      <Header>
        <Tabs>
          <TabButton
            onClick={() => setTab(JOB_BOTTOM_SECTION_TABS.JOB_PLAN)}
            $active={tab === JOB_BOTTOM_SECTION_TABS.JOB_PLAN}
            tabIndex={-1}
          >
            Job Plan
          </TabButton>
          <TabButton
            onClick={() => {
              setTab(JOB_BOTTOM_SECTION_TABS.TIMELINE);
              webapi.vitally.events({ event: VitallyEnum.ViewedJobTimeline });
            }}
            $active={tab === JOB_BOTTOM_SECTION_TABS.TIMELINE}
            tabIndex={-1}
          >
            Timeline
          </TabButton>
        </Tabs>
        <Actions>
          {tab === JOB_BOTTOM_SECTION_TABS.JOB_PLAN && (
            <ExpandCollapsePhaseButton />
          )}
          <ViewScheduleButton />
          <ReorderItems />
        </Actions>
      </Header>
      {tab === JOB_BOTTOM_SECTION_TABS.JOB_PLAN && <JobPlan />}
      {tab === JOB_BOTTOM_SECTION_TABS.TIMELINE && <JobTimeline />}
    </Container>
  );
};

export default BottomSection;

const Container = styled.div`
  padding: 5px 60px 300px 60px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 15px 0;
`;

const Tabs = styled.div`
  position: relative;
  left: -10px;
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const TabButton = styled.button`
  color: black;
  color: ${(props) => (props.$active ? "black" : "var(--color-gray-medium)")};
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  padding: 0 10px;
`;

const Actions = styled.div`
  display: grid;
  gap: 5px;
  & > * {
    grid-row: 1;
  }
`;
