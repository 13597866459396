import { ReactNode, useContext } from "react";
import styled from "styled-components";

import { ScheduleScrollContext } from "../../modules/ScrollContexts";

interface AnchorProps {
  children?: ReactNode;
}

function Anchor({ children }: AnchorProps) {
  const context = useContext(ScheduleScrollContext);
  return (
    <Container style={{ left: context.anchorPositionX }}>{children}</Container>
  );
}

export default Anchor;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 0;
`;
