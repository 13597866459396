import { filter, first, get, set } from "lodash-es";
import { createSelector } from "reselect";

import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
} from "../../lib/constants";
import { invoiceDocumentMethods } from "../../lib/entities/invoiceEntity";
import { asDecimal, round } from "../../lib/math";
import { calculateEffectiveTotalWithInstalment } from "../../lib/ui/commercialDocument";
import {
  getIsHeading,
  getTaxAmount,
  getTaxName,
  getTaxRate,
  getTotalAmountExTax,
  getTotalsTaxKey,
} from "../../lib/ui/commercialDocumentLineItems";
import { selectPropsId, selectPropsInvoiceId } from "./index";
import { selectInvoiceByInvoiceId, selectInvoices } from "./invoiceSelectors";

export const selectInvoiceLineItems = (state) =>
  state.entities[ENTITY_NAME_INVOICE_LINE_ITEMS];

export const selectInvoiceLineItemById = createSelector(
  selectInvoiceLineItems,
  selectPropsId,
  (invoiceLineItems, id) => get(invoiceLineItems, `byId.${id}`)
);

export const selectInvoiceLineItemInvoiceIdById = createSelector(
  selectInvoiceLineItemById,
  (invoiceLineItem) => get(invoiceLineItem, `invoiceId`)
);

export const selectInvoiceLineItemIdsByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  (invoiceLineItems, invoiceId) =>
    get(invoiceLineItems, `idsByInvoiceId.${invoiceId}`, EMPTY_ARRAY)
);

export const selectInvoiceLineItemsByInvoiceId = (state, props) => {
  const invoiceLineItemIds = selectInvoiceLineItemIdsByInvoiceId(state, props);
  return invoiceLineItemIds.map((id) =>
    selectInvoiceLineItemById(state, { id })
  );
};

export const selectSortedInvoiceLineItemIdsByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  (invoiceLineItems, invoiceId) =>
    get(invoiceLineItems, `sortedIdsByInvoiceId.${invoiceId}`, EMPTY_ARRAY)
);

export const selectSortedInvoiceLineItemsByInvoiceId = (state, props) => {
  const invoiceLineItemIds = selectSortedInvoiceLineItemIdsByInvoiceId(
    state,
    props
  );
  return invoiceLineItemIds.map((id) =>
    selectInvoiceLineItemById(state, { id })
  );
};

export const selectLineItemElementsByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  (invoiceLineItems, invoiceId) =>
    get(
      invoiceLineItems,
      `lineItemElementsByInvoiceId.${invoiceId}`,
      EMPTY_ARRAY
    )
);

export const selectInvoiceLineItemElementsById = createSelector(
  selectInvoiceLineItems,
  selectPropsId,
  (invoiceLineItems, id) =>
    get(invoiceLineItems, `lineItemElementsByInvoiceId.${id}`, EMPTY_ARRAY)
);

export const selectSubTotalsExTaxByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  (invoiceLineItems, invoiceId) =>
    get(
      invoiceLineItems,
      `subTotalsExTaxByInvoiceId.${invoiceId}`,
      EMPTY_OBJECT
    )
);

export const selectSubTotalsIncTaxByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  (invoiceLineItems, invoiceId) =>
    get(
      invoiceLineItems,
      `subTotalsIncTaxByInvoiceId.${invoiceId}`,
      EMPTY_OBJECT
    )
);

export const selectSubTotalsByInvoiceId = createSelector(
  selectSubTotalsExTaxByInvoiceId,
  selectSubTotalsIncTaxByInvoiceId,
  selectInvoiceByInvoiceId,
  (subTotalsExTax, subTotalsIncTax, invoice) =>
    invoice.displayLineItemsInclusiveOfTax ? subTotalsIncTax : subTotalsExTax
);

export const selectSubTotalByInvoiceIdInvoiceLineItemId = createSelector(
  selectSubTotalsByInvoiceId,
  selectPropsId,
  (subTotals, id) => get(subTotals, id)
);

export const selectTotalsByInvoiceId = createSelector(
  selectInvoiceLineItems,
  selectPropsInvoiceId,
  selectInvoices,
  (invoiceLineItems, invoiceId, invoices) => {
    const invoice = get(invoices.byId, invoiceId, null);
    const instalment = invoiceDocumentMethods.getInstalment(invoice);
    const instalmentPc = instalment === "" ? null : asDecimal(instalment);

    let totalExTax = 0;
    let totalExTaxLineItems = [];
    const totalTaxes = {};

    get(invoiceLineItems.idsByInvoiceId, invoiceId, [])
      .map((id) => invoiceLineItems.byId[id])
      .forEach((lineItem) => {
        if (getIsHeading(lineItem)) return;

        const amountExTax = asDecimal(getTotalAmountExTax(lineItem));
        const taxName = getTaxName(lineItem);
        const taxRate = getTaxRate(lineItem);
        const taxKey = getTotalsTaxKey(taxName, taxRate);
        const taxAmount = getTaxAmount(lineItem);

        totalExTax += amountExTax;
        totalExTaxLineItems.push(amountExTax);
        if (taxAmount > 0 || taxAmount < 0) {
          const calcTaxAmount = round(
            calculateEffectiveTotalWithInstalment(instalmentPc, taxAmount),
            2
          );

          set(totalTaxes, taxKey, {
            taxName,
            taxRate,
            taxAmount:
              get(totalTaxes, `${taxKey}.taxAmount`, 0) + calcTaxAmount,
          });
        }
      });

    const totals = {
      totalExTax,
      totalExTaxLineItems,
      totalTaxes,
      invoiceCurrencyAmountPaidIncTax: asDecimal(
        invoiceDocumentMethods.getInvoiceCurrencyAmountPaidIncTax(invoice)
      ),
    };

    return totals;
  }
);

export const selectInvoiceLineItemsUi = (state) =>
  state.ui[ENTITY_NAME_INVOICE_LINE_ITEMS];

export const selectInvoiceLineItemUi = createSelector(
  selectInvoiceLineItemsUi,
  selectPropsId,
  (invoiceLineItemsUi, id) => get(invoiceLineItemsUi, id, EMPTY_OBJECT)
);

export const selectInvoiceLineItemUiIsCreating = createSelector(
  selectInvoiceLineItemUi,
  (invoiceLineItemUi) => get(invoiceLineItemUi, "isCreating", false)
);

export const selectInvoiceLineItemUiIsMerging = createSelector(
  selectInvoiceLineItemUi,
  (invoiceLineItemUi) => get(invoiceLineItemUi, "isMerging", false)
);

export const selectSortedInvoiceLineItemIdsMergingByInvoiceId = (
  state,
  props
) => {
  const invoiceLineItemIds = selectSortedInvoiceLineItemIdsByInvoiceId(
    state,
    props
  );

  return filter(invoiceLineItemIds, (id) =>
    get(selectInvoiceLineItemUi(state, { id }), "isMerging", false)
  );
};

export const selectCountIsMergingInvoiceLineItemsByInvoiceId = createSelector(
  selectSortedInvoiceLineItemIdsMergingByInvoiceId,
  (value) => value.length
);

export const selectFirstIsMergingInvoiceLineItemIdByInvoiceId = createSelector(
  selectSortedInvoiceLineItemIdsMergingByInvoiceId,
  first
);

export const selectMergeParentInvoiceLineItem = (state, props) => {
  const { invoiceId } = selectInvoiceLineItemById(state, props);
  const invoiceLineItemId = selectFirstIsMergingInvoiceLineItemIdByInvoiceId(
    state,
    {
      invoiceId,
    }
  );

  return (
    invoiceLineItemId &&
    selectInvoiceLineItemById(state, { id: invoiceLineItemId })
  );
};

export const selectInvoiceLineItemIsMergeParent = (state, props) => {
  const { id, invoiceId } = selectInvoiceLineItemById(state, props);
  const firstIsMergingId = selectFirstIsMergingInvoiceLineItemIdByInvoiceId(
    state,
    {
      invoiceId,
    }
  );

  return Boolean(firstIsMergingId && firstIsMergingId === id);
};

export const selectSortedInvoiceLineItemsMergingByInvoiceId = (
  state,
  props
) => {
  const invoiceLineItemIds = selectSortedInvoiceLineItemIdsMergingByInvoiceId(
    state,
    props
  );

  return invoiceLineItemIds.map((id) =>
    selectInvoiceLineItemById(state, { id })
  );
};
