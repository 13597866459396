import numeral from "numeral";

import { NumberFormats } from "./constants";

export function snapRound(x: number, snap: number) {
  return Math.round(x / snap) * snap;
}

export function snapTrunc(x: number, snap: number) {
  return Math.trunc(x / snap) * snap;
}

export function snapFloor(x: number, snap: number) {
  return Math.floor(x / snap) * snap;
}

export function snapCeil(x: number, snap: number) {
  return Math.ceil(x / snap) * snap;
}

export function minMax(x: number, min: number, max: number) {
  return Math.min(Math.max(x, min), max);
}

export function asDecimal(x: any) {
  return numeral(x).value() || 0;
}

export function round(num: any, decimals = 0) {
  return Number(numeral(num).format(`0[.]${"0".repeat(decimals)}`));
}

type Point = { x: number; y: number };

export function findAngle(p1: Point, p2: Point) {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
}

export function findDistance(p1: Point, p2: Point) {
  return Math.hypot(p2.y - p1.y, p2.x - p1.x);
}

export function countDecimalPlaces(value: number) {
  if (value && Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0;
  }
  return 0;
}

export function preciseDecimals(value: number, decimals = 1) {
  if (value % 1 !== 0) {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
  return value;
}

export function preciseAdd(a: any, b: any) {
  const aDecimal = countDecimalPlaces(numeral(a).value()!);
  const bDecimal = countDecimalPlaces(numeral(b).value()!);

  const decimals = aDecimal > bDecimal ? aDecimal : bDecimal;

  const aInteger = preciseDecimals(
    numeral(a).multiply(Math.pow(10, decimals)).value()!,
    0
  );
  const bInteger = preciseDecimals(
    numeral(b).multiply(Math.pow(10, decimals)).value()!,
    0
  );

  const result = aInteger + bInteger;

  return numeral(result).divide(Math.pow(10, decimals)).value();
}

export function round2dp(value: any) {
  return numeral(value).format(NumberFormats.TwoDecimals);
}

export function round4dp(value: any) {
  return numeral(value).format(NumberFormats.FourDecimals);
}

export function round6dp(value: any) {
  return numeral(value).format(NumberFormats.SixDecimals);
}

export function incrementAlphabeticNumber(value: string, defaultNext = "1") {
  const number = value.match(/\d+$/);
  if (number) {
    return value.replace(
      /\d+$/,
      String(Number(number[0]) + 1).padStart(number[0].length, "0")
    );
  }
  return `${value}${defaultNext}`;
}

export function roundDecimalPlaces(value: string, precision = 2) {
  return numeral(value).format(`0.[${"0".repeat(precision)}]`);
}

export function limitDecimalPlaces(value: string, precision = 2) {
  if (value.indexOf(".") >= 0) {
    return value.slice(0, value.indexOf(".") + 1 + precision);
  }
  return value;
}
