import { produce } from "immer";
import { isEqual } from "lodash-es";
import { put, call, select } from "redux-saga/effects";
import { updateJobPhaseAPI } from "../../../lib/API/jobPhaseAPI";
import {
  ENTITIES_RECEIVED,
  JOB_PHASE_SAVE,
  JOB_PHASE_SAVE_ERROR,
  JOB_PHASE_SAVED,
  JOB_PHASE_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";

export function* saveJobPhase(action) {
  const {
    jobPhaseId,
    jobPhase,
    previousJobPhase,
    addJobItemsToPhase = null
  } = action.payload;

  try {
    if (jobPhaseId < 0) return;

    if (
      isEqual(jobPhase, previousJobPhase) &&
      (addJobItemsToPhase === null || addJobItemsToPhase.length === 0)
    )
      return;

    yield put(
      createAction(JOB_PHASE_SAVING, {
        jobPhaseId,
        jobPhase
      })
    );

    yield updateJobItemsWithJobPhaseId(addJobItemsToPhase, jobPhaseId);

    const { data } = yield call(
      updateJobPhaseAPI,
      jobPhase,
      addJobItemsToPhase
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(
      createAction(JOB_PHASE_SAVED, {
        jobPhaseId,
        jobPhase,
        data
      })
    );
  } catch (error) {
    yield put(
      createAction(JOB_PHASE_SAVE_ERROR, {
        jobPhaseId,
        jobPhase,
        previousJobPhase,
        addJobItemsToPhase,
        sagaType: action.type,
        error
      })
    );

    yield updateJobItemsWithJobPhaseId(addJobItemsToPhase, null);

    sagaError(error);
  }
}

function* updateJobItemsWithJobPhaseId(addJobItemsToPhase, jobPhaseId) {
  if (addJobItemsToPhase && addJobItemsToPhase.length > 0) {
    const jobItems = [];

    for (let i = 0; i < addJobItemsToPhase.length; i += 1) {
      const jobItem = yield select(selectJobItem, {
        jobItemId: addJobItemsToPhase[i]
      });
      const newJobItem = produce(jobItem, draft => {
        draft.jobPhaseId = jobPhaseId;
      });
      jobItems.push(newJobItem);
    }

    yield put(createAction(ENTITIES_RECEIVED, { jobItems }));
  }
}

export default function* watchJobPhaseSave() {
  yield takeLatestBy(
    [JOB_PHASE_SAVE],
    saveJobPhase,
    action => action.payload.jobPhaseId
  );
}
