import type {
  TNumericStateComparatorEnum,
  TNumericStateEnum,
  TValueFormatEnum,
} from "@streamtimefe/entities";
import {
  NumericState,
  NumericStateComparatorEnum,
  NumericStateEnum,
  ValueFormatEnum,
} from "@streamtimefe/entities";
import { convertFlexibleDurationToMinutes, uuid } from "@streamtimefe/utils";
import { produce } from "immer";
import { isEqual } from "lodash-es";
import type { ChangeEvent } from "react";
import { useMemo, useRef, useState } from "react";
import type { SelectKey } from "st-shared/components";
import {
  Button,
  Icon,
  IconButton,
  Input,
  MdAdd,
  MdDelete,
  PopoverMenu,
  Select,
  SelectItem,
  StEdit,
} from "st-shared/components";
import type { ReportingDisplayRule } from "st-shared/entities";
import { CReportingSavedSegment } from "st-shared/entities";
import { useOnClickOutside } from "st-shared/external/usehooks";
import { convertMinutesToTimeHM } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";
import { formatStatisticValue } from "st-shared/types";

import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsOrdered,
  useReportingSavedSegmentDataSets,
} from "../../state/stores/savedSegmentSelectors";
import * as styles from "./DisplayRulesMenu.css";

type Props = {
  anchorElement: Element;
  close: () => void;
  displayRules?: ReportingDisplayRule[];
  defaultDisplayRules: ReportingDisplayRule[];
  setDisplayRules: (displayRules?: ReportingDisplayRule[]) => void;
  columnId?: string;
  format: TValueFormatEnum;
};

export function DisplayRulesMenu({
  anchorElement,
  close,
  displayRules,
  defaultDisplayRules,
  setDisplayRules,
  columnId,
  format,
}: Props) {
  function getDefaultState(rules: ReportingDisplayRule[]) {
    const s: Record<
      string,
      {
        editing: boolean;
        displayRule?: ReportingDisplayRule;
      }
    > = {};
    rules.forEach((displayRule) => {
      s[uuid()] = {
        editing: false,
        displayRule,
      };
    });
    return s;
  }

  const [rowState, setEditingState] = useState(() =>
    getDefaultState(displayRules || defaultDisplayRules)
  );

  const newDisplayRules = useMemo(() => {
    return Object.values(rowState)
      .map((row) => row.displayRule)
      .filter(
        (displayRule) => displayRule !== undefined
      ) as ReportingDisplayRule[];
  }, [rowState]);

  function onClose() {
    setDisplayRules(newDisplayRules);
    close();
  }

  function editRow(uuid: string, editing: boolean) {
    setEditingState((s) =>
      produce(s, (draft) => {
        draft[uuid].editing = editing;

        if (
          draft[uuid].editing === false &&
          draft[uuid].displayRule === undefined
        ) {
          delete draft[uuid];
        }
      })
    );
  }

  function deleteRow(uuid: string) {
    setEditingState((s) =>
      produce(s, (draft) => {
        delete draft[uuid];
      })
    );
  }

  function updateRule(uuid: string, displayRule: ReportingDisplayRule) {
    setEditingState((s) =>
      produce(s, (draft) => {
        draft[uuid].displayRule = displayRule;
        draft[uuid].editing = false;
      })
    );
  }

  function addNewRow() {
    setEditingState((s) =>
      produce(s, (draft) => {
        draft[uuid()] = {
          editing: true,
        };
      })
    );
  }

  function onReset() {
    setEditingState(() => getDefaultState(defaultDisplayRules));
  }

  const isDefault = useMemo(
    () => isEqual(newDisplayRules, defaultDisplayRules),
    [newDisplayRules, defaultDisplayRules]
  );

  return (
    <PopoverMenu
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: -10,
      }}
      onClose={onClose}
      classes={{
        paper: styles.popover,
      }}
    >
      <div className={styles.header}>Display Rules</div>
      {Object.keys(rowState).map((key) => {
        const row = rowState[key];
        if (row.editing) {
          return (
            <DisplayRuleRowEditing
              key={key}
              displayRule={row.displayRule}
              columnId={columnId}
              format={format}
              onCancel={() => editRow(key, false)}
              onSave={(displayRule) => updateRule(key, displayRule)}
            />
          );
        }
        if (row.displayRule) {
          return (
            <DisplayRuleRow
              key={key}
              format={format}
              displayRule={row.displayRule}
              onEdit={() => editRow(key, true)}
              onDelete={() => deleteRow(key)}
            />
          );
        }
        return null;
      })}
      <div className={styles.addRule}>
        <div className={styles.addRuleButton} onClick={addNewRow}>
          <Icon icon={MdAdd} size={18} />
          <span style={{ marginTop: 2 }}>Add display rule</span>
        </div>
        {!isDefault && (
          <div className={styles.resetButton} onClick={onReset}>
            reset
          </div>
        )}
      </div>
    </PopoverMenu>
  );
}

type DisplayRuleRowProps = {
  format: TValueFormatEnum;
  displayRule: ReportingDisplayRule;
  onEdit: () => void;
  onDelete: () => void;
};

function DisplayRuleRow({
  format,
  displayRule,
  onEdit,
  onDelete,
}: DisplayRuleRowProps) {
  const customerCurrency = useCustomerCurrency();

  function renderDisplayState() {
    switch (displayRule.state) {
      case NumericStateEnum.Blank:
        return <span className={styles.ruleStateBlank}>grey</span>;
      case NumericStateEnum.Success:
        return <span className={styles.ruleStateSuccess}>green</span>;
      case NumericStateEnum.Warning:
        return <span className={styles.ruleStateWarning}>red</span>;
    }
    return null;
  }

  function formatThreshold(threshold: number) {
    return formatStatisticValue(threshold, format, customerCurrency);
  }

  return (
    <div className={styles.ruleRowOuter}>
      <div className={styles.ruleRowInner}>
        <div>
          Display in {renderDisplayState()} if{" "}
          <span className={styles.boldText}>
            {NumericState.getComparatorText(displayRule.comparator)}
          </span>{" "}
          {typeof displayRule.threshold === "number" && (
            <span className={styles.boldText}>
              {formatThreshold(displayRule.threshold)}
            </span>
          )}
        </div>
        {typeof displayRule.threshold === "string" && (
          <DisplayColumnPill id={displayRule.threshold} />
        )}
      </div>
      <div className={styles.ruleRowActions}>
        <IconButton onClick={onEdit} iconProps={{ icon: StEdit, size: 16 }} />
        <IconButton
          onClick={onDelete}
          iconProps={{ icon: MdDelete, size: 16 }}
        />
      </div>
    </div>
  );
}

type DisplayColumnPillProps = {
  id: string;
};

function DisplayColumnPill({ id }: DisplayColumnPillProps) {
  const columns = useReportingSavedSegmentColumns();
  const dataSets = useReportingSavedSegmentDataSets();

  const column = columns[id];

  if (column) {
    return (
      <div className={styles.columnPill}>
        <Icon
          icon={CReportingSavedSegment.getColumnIcon(column, dataSets)}
          size={14}
        />
        <span style={{ marginTop: 2 }}>{column.name}</span>
      </div>
    );
  }

  return (
    <div className={styles.columnPill} data-deleted-column={true}>
      Deleted Column
    </div>
  );
}

type DisplayRuleRowEditingProps = {
  displayRule?: ReportingDisplayRule;
  columnId?: string;
  format: TValueFormatEnum;
  onCancel: () => void;
  onSave: (displayRule: ReportingDisplayRule) => void;
};

function DisplayRuleRowEditing({
  displayRule,
  columnId,
  format,
  onCancel,
  onSave,
}: DisplayRuleRowEditingProps) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onCancel);

  const [rule, setRule] = useState<ReportingDisplayRule>(() => {
    return displayRule || NumericState.Calculators.DefaultSingle;
  });

  const [thresholdInput, setThresholdInput] = useState(() => {
    if (typeof rule.threshold === "number") {
      if (format === ValueFormatEnum.Minutes) {
        return convertMinutesToTimeHM(rule.threshold);
      }
      return String(rule.threshold);
    }
    return "0";
  });

  const columns = useReportingSavedSegmentColumnsOrdered();
  const filteredColumns = useMemo(() => {
    return columns.filter((column) => column.id !== columnId);
  }, [columns, columnId]);

  function onStateSelectionChange(value: SelectKey) {
    setRule((s) =>
      produce(s, (draft) => {
        draft.state = value as TNumericStateEnum;
      })
    );
  }

  function onComparatorSelectionChange(value: SelectKey) {
    setRule((s) =>
      produce(s, (draft) => {
        draft.comparator = value as TNumericStateEnum;
      })
    );
  }

  function onThresholdSelectionChange(value: SelectKey) {
    setRule((s) =>
      produce(s, (draft) => {
        if (value === "custom") {
          draft.threshold = 0;
          setThresholdInput("0");
        } else {
          draft.threshold = value;
        }
      })
    );
  }

  function onThresholdInputChange(e: ChangeEvent<HTMLInputElement>) {
    setThresholdInput(e.target.value);
    setRule((s) =>
      produce(s, (draft) => {
        if (format === ValueFormatEnum.Minutes) {
          draft.threshold = Number(
            convertFlexibleDurationToMinutes(e.target.value)
          );
        } else {
          draft.threshold = Number(e.target.value);
        }
      })
    );
  }

  return (
    <div className={styles.ruleRowEditOuter} ref={ref}>
      <div className={styles.ruleRowEditInner}>
        <span>Display in</span>
        <Select
          aria-label="Display Rules Color State"
          items={displayRuleStateItems}
          selectedKey={rule.state}
          onSelectionChange={onStateSelectionChange}
          buttonProps={{
            className: styles.selectButton,
            iconClassName: styles.selectButtonIcon,
          }}
        >
          {(item) => <SelectItem id={item.type}>{item.name}</SelectItem>}
        </Select>
        <span>if</span>
        <Select
          aria-label="Display Rules Comparator"
          items={displayRuleComparatorItems}
          selectedKey={rule.comparator}
          onSelectionChange={onComparatorSelectionChange}
          buttonProps={{
            className: styles.selectButton,
            iconClassName: styles.selectButtonIcon,
          }}
        >
          {(item) => <SelectItem id={item.type}>{item.name}</SelectItem>}
        </Select>
      </div>
      <div className={styles.ruleRowEditInner}>
        <Select
          aria-label="Display Rules Threshold"
          selectedKey={
            typeof rule.threshold === "number" ? "custom" : rule.threshold
          }
          onSelectionChange={onThresholdSelectionChange}
          buttonProps={{
            className: styles.selectButton,
            iconClassName: styles.selectButtonIcon,
          }}
        >
          <SelectItem id={"custom"}>custom value of</SelectItem>
          {filteredColumns.map((column) => (
            <SelectItem key={column.id} id={column.id}>
              {column.name}
            </SelectItem>
          ))}
        </Select>
        {typeof rule.threshold === "number" && (
          <Input
            variant="secondary"
            as={format === ValueFormatEnum.Minutes ? "time" : "numeric"}
            className={styles.thresholdInput}
            value={thresholdInput}
            onChange={onThresholdInputChange}
          />
        )}
      </div>
      <div className={styles.ruleRowEditActions}>
        <Button className={styles.actionCancel} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className={styles.actionSave}
          onClick={() => onSave(rule)}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

const displayRuleStateItems: {
  type: TNumericStateEnum;
  name: string;
}[] = [
  {
    type: NumericStateEnum.Blank,
    name: "grey",
  },
  {
    type: NumericStateEnum.Success,
    name: "green",
  },
  {
    type: NumericStateEnum.Warning,
    name: "red",
  },
];

const displayRuleComparatorItems: {
  type: TNumericStateComparatorEnum;
  name: string;
}[] = Object.values(NumericStateComparatorEnum).map((type) => ({
  type,
  name: NumericState.getComparatorText(type),
}));
