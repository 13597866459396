import { get } from "lodash-es";
import { select, put } from "redux-saga/effects";
import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_PHASE_SAVE,
  JOB_PHASE_SET_PREVIOUS_JOB_PHASE
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectJobDetailsUiCurrentFocusJobPhaseId,
  selectJobDetailsUiCurrentFocusKey
} from "../../selectors/jobDetails/ui/currentFocus";
import {
  selectJobDetailsUiPreviousFocusKey,
  selectJobDetailsUiPreviousFocusJobPhaseId
} from "../../selectors/jobDetails/ui/previousFocus";
import { selectJobPhasePreviousJobPhase } from "../../selectors/jobPhase/ui/previousJobPhase";
import { selectJobPhaseById } from "../../selectors/jobPhaseSelectors";

function* checkFocusSetPreviousJobPhase() {
  try {
    const currentFocusKey = yield select(selectJobDetailsUiCurrentFocusKey);
    const currentFocusJobPhaseId = yield select(
      selectJobDetailsUiCurrentFocusJobPhaseId
    );

    if (currentFocusKey === FOCUS_KEYS.PHASE_NAME) {
      const previousJobPhase = yield select(selectJobPhasePreviousJobPhase, {
        jobPhaseId: currentFocusJobPhaseId
      });

      if (previousJobPhase === null) {
        const jobPhase = yield select(selectJobPhaseById, {
          id: currentFocusJobPhaseId
        });

        yield put(
          createAction(JOB_PHASE_SET_PREVIOUS_JOB_PHASE, {
            jobPhaseId: currentFocusJobPhaseId,
            jobPhase
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

function* checkFocusSaveJobPhase() {
  try {
    const currentFocusKey = yield select(selectJobDetailsUiCurrentFocusKey);
    const currentFocusJobPhaseId = yield select(
      selectJobDetailsUiCurrentFocusJobPhaseId
    );
    const previousFocusKey = yield select(selectJobDetailsUiPreviousFocusKey);
    const previousFocusJobPhaseId = yield select(
      selectJobDetailsUiPreviousFocusJobPhaseId
    );

    if (
      previousFocusKey === FOCUS_KEYS.PHASE_NAME &&
      (currentFocusKey !== FOCUS_KEYS.PHASE_NAME ||
        previousFocusJobPhaseId !== currentFocusJobPhaseId)
    ) {
      const previousJobPhase = yield select(selectJobPhasePreviousJobPhase, {
        jobPhaseId: previousFocusJobPhaseId
      });

      if (previousJobPhase !== null) {
        const jobPhase = yield select(selectJobPhaseById, {
          id: previousFocusJobPhaseId
        });

        yield put(
          createAction(JOB_PHASE_SAVE, {
            jobPhaseId: previousFocusJobPhaseId,
            jobPhase,
            previousJobPhase
          })
        );

        yield put(
          createAction(JOB_PHASE_SET_PREVIOUS_JOB_PHASE, {
            jobPhaseId: previousFocusJobPhaseId,
            jobPhase: null
          })
        );
      }
    }
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobPhaseCheckFocus() {
  yield takeLatestBy(
    [JOB_DETAILS_SET_CURRENT_FOCUS],
    checkFocusSetPreviousJobPhase,
    action => get(action.payload, "currentFocus.jobPhaseId", "default")
  );
  yield takeLatestBy(
    [JOB_DETAILS_SET_CURRENT_FOCUS],
    checkFocusSaveJobPhase,
    action => get(action.payload, "currentFocus.jobPhaseId", "default")
  );
}
