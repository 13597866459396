import styled from "styled-components";

import UserIconName from "../../modules/User/UserIconName";

interface ScheduleJobTeamMemberUserGroupProps {
  id: number;
}

function ScheduleJobTeamMemberUserGroup({
  id,
}: ScheduleJobTeamMemberUserGroupProps) {
  return (
    <Wrapper>
      <UserIconName id={id} fontSize={14} />
    </Wrapper>
  );
}

export default ScheduleJobTeamMemberUserGroup;

const Wrapper = styled.div`
  padding: 10px 30px 10px 0;
`;
