import { useSelector } from "react-redux";
import { TEntityId } from "st-shared/entities/Entity";

import { JOB_TIMELINE_DATE_HEADER_HEIGHT } from "../../../lib/constants";
import {
  selectJobTimelineExportWidth,
  selectJobTimelineHeight,
} from "../../../redux/selectors/jobTimelineSelectors";

export function useJobTimelineExportSize(jobId: TEntityId) {
  const contentHeight = useSelector((state) =>
    selectJobTimelineHeight(state, { jobId })
  );
  const exportWidth = useSelector((state) =>
    selectJobTimelineExportWidth(state, { jobId })
  );

  return {
    width: exportWidth,
    height: contentHeight + JOB_TIMELINE_DATE_HEADER_HEIGHT,
  };
}
