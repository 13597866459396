import MuiButton from "@material-ui/core/Button";
import MuiIconButton from "@material-ui/core/IconButton";
import styled from "styled-components";

export const BUTTON_VARIANTS = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

// TODO: use the new Buttons/Button file
const Button = styled(MuiButton).attrs(() => ({
  variant: BUTTON_VARIANTS.TEXT,
}))`
  border: none;
  border-radius: 15px;
  color: var(--color-charcoal);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  height: 30px;
  padding: 0 15px;
  text-transform: none;
  transition: var(--transition-mui-color),
    var(--tranisition-mui-background-color);
  &:disabled {
    background-color: var(--color-jonsnow);
    color: var(--color-gray);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .MuiButton-label {
    padding-top: 3px;
  }
  .MuiButton-startIcon {
    margin-bottom: 3px;
  }
`;

export default Button;

// TODO: use the new Buttons/Button file
export const PrimaryButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANTS.CONTAINED,
}))`
  color: white;
  background-color: var(--color-charcoal);
  box-shadow: none;
  min-width: 100px;
  &:hover {
    background-color: var(--color-gray-dark);
    box-shadow: none;
  }
`;

// TODO: use the new Buttons/IconButton file
export const IconButton = styled(MuiIconButton).attrs({
  disableRipple: true,
  disableFocusRipple: true,
})`
  color: var(--color-gray-dark);
  border-radius: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  transition: var(--transition-mui-color),
    var(--tranisition-mui-background-color), var(--transition-mui-opacity);
  &:hover {
    background-color: transparent;
    color: var(--color-charcoal);
  }
  &.reverseHoverColors {
    color: var(--color-charcoal);
    &:hover {
      color: var(--color-gray-dark);
    }
  }
`;

// TODO: use the new Buttons/IconButton file
export const IconButtonLight = styled(IconButton)`
  color: var(--color-gray-dark);
  &:hover {
    color: var(--color-charcoal);
  }
`;

// TODO: use the new Buttons/TextButton file
export const TextButton = styled(MuiButton).attrs(() => ({
  variant: BUTTON_VARIANTS.TEXT,
  disableRipple: true,
  disableFocusRipple: true,
}))`
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  text-transform: none;
  transition: var(--transition-mui-color),
    var(--tranisition-mui-background-color);
  &:disabled {
    background-color: none;
    color: var(--color-gray);
  }
  &:hover {
    background-color: transparent;
    text-decoration: underline;
    color: inherit;
  }
  .MuiButton-label {
    padding-top: 3px;
  }
  .MuiButton-startIcon {
    margin-bottom: 3px;
  }
  &.underline {
    text-decoration: underline;
  }
`;

// TODO: use the new Buttons/TextButton file
export const InlineTextButton = styled(TextButton)`
  text-decoration: underline;

  .MuiButton-label {
    padding: 0;
  }
`;
