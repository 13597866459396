import MuiIcon from "@material-ui/core/Icon";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";
import CircleIcon from "./CircleIcon";

const Icon = StyledIcon(MuiIcon);

const RhombusCircleIcon = ({ color, fill, ...props }) => (
  <Icon {...props}>
    <StyledCircleIcon {...{ color, fill, ...props }} className="rhombus">
      <Svg fill={color} version="1.1" viewBox="0 0 24 24">
        <polygon points="12,4 4,12 12,20 20,12" />
      </Svg>
    </StyledCircleIcon>
  </Icon>
);

RhombusCircleIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  fill: PropTypes.string
};

RhombusCircleIcon.defaultProps = {
  size: ICON_SIZE.X_LARGE,
  color: "transparent",
  fill: "black"
};

const StyledCircleIcon = styled(CircleIcon)`
  padding: 1px;
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color};
`;

export default styled(RhombusCircleIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
