import type { AriaKey } from "@streamtimefe/ui/aria";
import { type ReactElement, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { create } from "zustand";

export const useDevToolsComponentStore = create<{
  contentElement: HTMLElement | null;
  defaultTab: AriaKey | null;
  currentTab: AriaKey | null;
  customTabs: AriaKey[];
  setContentElement: (contentElement: HTMLElement | null) => void;
  setCurrentTab: (tab: AriaKey) => void;
  addCustomTab: (tab: AriaKey, isDefaultTab?: boolean) => void;
  removeCustomTab: (tab: AriaKey) => void;
}>((set) => ({
  contentElement: null,
  currentTab: null,
  defaultTab: null,
  customTabs: [],
  setContentElement: (contentElement: HTMLElement | null) =>
    set({ contentElement }),
  setCurrentTab: (tab: AriaKey) => set({ currentTab: tab }),
  addCustomTab: (tab: AriaKey, isDefaultTab?: boolean) =>
    set((state) => ({
      customTabs: [...state.customTabs, tab],
      defaultTab: isDefaultTab ? tab : state.defaultTab,
    })),
  removeCustomTab: (tab: AriaKey) =>
    set((state) => ({
      customTabs: state.customTabs.filter((customTab) => customTab != tab),
      defaultTab: state.defaultTab === tab ? null : state.defaultTab,
    })),
}));

type Props = {
  tab: AriaKey;
  isDefaultTab?: boolean;
  component: ReactElement;
};

export function DevToolsComponent({ tab, component, isDefaultTab }: Props) {
  const addCustomTab = useDevToolsComponentStore((s) => s.addCustomTab);
  const removeCustomTab = useDevToolsComponentStore((s) => s.removeCustomTab);
  const defaultTab = useDevToolsComponentStore((s) => s.defaultTab);
  const currentTab = useDevToolsComponentStore((s) => s.currentTab);
  const contentElement = useDevToolsComponentStore((s) => s.contentElement);

  useEffect(() => {
    addCustomTab(tab, isDefaultTab);
    return () => {
      removeCustomTab(tab);
    };
  }, [addCustomTab, removeCustomTab, tab, isDefaultTab]);

  const calculatedTab = useMemo(() => {
    if (!currentTab && defaultTab) {
      return defaultTab;
    }
    return currentTab;
  }, [currentTab, defaultTab]);

  if (calculatedTab !== tab || !contentElement) {
    return null;
  }
  return createPortal(component, contentElement);
}
