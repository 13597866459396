import { LabelTypeEnum, type TLabelTypeEnum } from "@streamtimefe/entities";
import { produce } from "immer";
import { sortBy } from "lodash-es";
import { create } from "zustand";

import type { MasterLabel, SearchOption } from "../../entities";
import { getLabelOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface LabelOptionsStoreState {
  loaded: Record<TLabelTypeEnum, boolean>;
  loading: Record<TLabelTypeEnum, boolean>;
  options: Record<TLabelTypeEnum, MasterLabel[]>;
  searchOptions: Record<TLabelTypeEnum, SearchOption[]>;
  load: (labelType: TLabelTypeEnum, force?: boolean) => void;
  setOptions: (labelType: TLabelTypeEnum, options: MasterLabel[]) => void;
}

export const useLabelOptionsStore = create<LabelOptionsStoreState>(
  function (set, get) {
    return {
      loaded: {
        [LabelTypeEnum.Job]: false,
        [LabelTypeEnum.Quote]: false,
        [LabelTypeEnum.Invoice]: false,
        [LabelTypeEnum.LoggedExpense]: false,
        [LabelTypeEnum.Company]: false,
        [LabelTypeEnum.Contact]: false,
        [LabelTypeEnum.User]: false,
      },
      loading: {
        [LabelTypeEnum.Job]: false,
        [LabelTypeEnum.Quote]: false,
        [LabelTypeEnum.Invoice]: false,
        [LabelTypeEnum.LoggedExpense]: false,
        [LabelTypeEnum.Company]: false,
        [LabelTypeEnum.Contact]: false,
        [LabelTypeEnum.User]: false,
      },
      options: {
        [LabelTypeEnum.Job]: [],
        [LabelTypeEnum.Quote]: [],
        [LabelTypeEnum.Invoice]: [],
        [LabelTypeEnum.LoggedExpense]: [],
        [LabelTypeEnum.Company]: [],
        [LabelTypeEnum.Contact]: [],
        [LabelTypeEnum.User]: [],
      },
      searchOptions: {
        [LabelTypeEnum.Job]: [],
        [LabelTypeEnum.Quote]: [],
        [LabelTypeEnum.Invoice]: [],
        [LabelTypeEnum.LoggedExpense]: [],
        [LabelTypeEnum.Company]: [],
        [LabelTypeEnum.Contact]: [],
        [LabelTypeEnum.User]: [],
      },
      load: async function (labelType: TLabelTypeEnum, force = false) {
        if ((!force && get().loaded[labelType]) || get().loading[labelType])
          return;

        set((s) =>
          produce(s, (draft) => {
            draft.loaded[labelType] = false;
            draft.loading[labelType] = true;
          })
        );

        try {
          const response = await getLabelOptions(labelType);
          get().setOptions(labelType, response.data);
        } catch (e: unknown) {
          console.error("loading label options error: ", e);
        } finally {
          set((s) =>
            produce(s, (draft) => {
              draft.loaded[labelType] = true;
              draft.loading[labelType] = false;
            })
          );
        }
      },
      setOptions(labelType: TLabelTTLabelTypeEnumypes, options: MasterLabel[]) {
        options = sortBy(options, (option) => option.name.toLowerCase());

        const searchOptions: SearchOption[] = options.map((option) => ({
          key: option.id,
          value: option.name,
          searchString: option.name,
          entity: option,
        }));

        set((s) =>
          produce(s, (draft) => {
            draft.loaded[labelType] = true;
            draft.loading[labelType] = false;
            draft.options[labelType] = options;
            draft.searchOptions[labelType] = searchOptions;
          })
        );
      },
    };
  }
);

export function labelOptionsStoreInit() {
  addGlobalStore(["options", "labels"], labelOptionsStore);
}

export function labelOptionsStore() {
  return useLabelOptionsStore.getState();
}

export function useLabelOptionsLoaded(labelType: TLabelTypeEnum) {
  return useLabelOptionsStore((s) => s.loaded[labelType]);
}

export function useLabelOptionsLoading(labelType: TLabelTypeEnum) {
  return useLabelOptionsStore((s) => s.loading[labelType]);
}

export function useLabelOptions(labelType: TLabelTypeEnum) {
  return useLabelOptionsStore((s) => s.options[labelType]);
}

export function useLabelSearchOptions(labelType: TLabelTypeEnum) {
  return useLabelOptionsStore((s) => s.searchOptions[labelType]);
}
