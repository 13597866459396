import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectStateJobDetailsUi } from "./index";

export const selectUnplannedTimeSummary = createSelector(
  [selectStateJobDetailsUi],
  jobDetails => jobDetails.unplannedTimeSummary
);

export const useUnplannedTimeSummary = () =>
  useSelector(selectUnplannedTimeSummary);
