import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../../index";
import { selectJobUi } from "./index";

export const selectIsSavingJob = createCachedSelector(
  [selectJobUi],
  job => !!(job && job.isSaving)
)(selectPropsJobId);

export const useIsSavingJob = jobId =>
  useSelector(state => selectIsSavingJob(state, { jobId }));
