import { Entity } from "st-shared/entities/Entity";

import { ChangedEntity, EntityState } from "../entities/types";

function parseRemovedEntities<T extends Entity>(
  state: EntityState<T>,
  ids: number[]
): ChangedEntity<T>[] {
  return ids
    .map((id) => state.byId[id])
    .filter(Boolean)
    .map((prevEntity) => ({ prevEntity }));
}

export default parseRemovedEntities;
