import type { THappinessScoreEnum } from "./HappinessScore";
import { HappinessScoreEnum } from "./HappinessScore";

export function format(happinessScore: THappinessScoreEnum) {
  switch (happinessScore) {
    case HappinessScoreEnum.One:
      return "😱";
    case HappinessScoreEnum.Two:
      return "🙁";
    case HappinessScoreEnum.Three:
      return "😐";
    case HappinessScoreEnum.Four:
      return "😊";
    case HappinessScoreEnum.Five:
      return "😍";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown Happiness Score: ${happinessScore}`);
      return "";
  }
}

export function getDescription(happinessScore: THappinessScoreEnum) {
  switch (happinessScore) {
    case HappinessScoreEnum.One:
      return "I've been deeply unhappy this week.";
    case HappinessScoreEnum.Two:
      return "I've been unhappy this week.";
    case HappinessScoreEnum.Three:
      return "I've had an ok week.";
    case HappinessScoreEnum.Four:
      return "I'm happy. This week has been great.";
    case HappinessScoreEnum.Five:
      return "I've been really happy this week. Work is fantastic.";
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unknown Happiness Score: ${happinessScore}`);
      return "";
  }
}
