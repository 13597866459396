import { StatisticModeEnum, type TStatisticModeEnum } from "./StatisticMode";

type TProperty = { name: string; columnPrefix?: string };

const properties: Record<TStatisticModeEnum, TProperty> = {
  [StatisticModeEnum.Sum]: { name: "Sum" },
  [StatisticModeEnum.Average]: { name: "Average", columnPrefix: "Avg" },
  [StatisticModeEnum.Count]: { name: "Count" },
} as const;

export function getName(statisticMode: TStatisticModeEnum) {
  return properties[statisticMode].name;
}

export function getColumnPrefix(statisticMode: TStatisticModeEnum) {
  return properties[statisticMode].columnPrefix;
}
