import { produce } from "immer";
import { get, setWith } from "lodash-es";

import { asDecimal } from "../../../../lib/math";
import {
  getIsHeading,
  getTaxAmount,
  getTaxName,
  getTaxRate,
  getTotalAmountExTax,
  getTotalsTaxKey,
} from "../../../../lib/ui/commercialDocumentLineItems";

export default (nextState, touchedQuoteIds) =>
  produce(nextState, (draft) => {
    const getQuoteLineItem = (id) => nextState.byId[id];

    touchedQuoteIds.forEach((quoteId) => {
      let totalExTax = 0;
      const totalTaxes = {};

      get(nextState.idsByQuoteId, quoteId, [])
        .map(getQuoteLineItem)
        .forEach((lineItem) => {
          if (getIsHeading(lineItem)) return;

          const amountExTax = asDecimal(getTotalAmountExTax(lineItem));
          const taxName = getTaxName(lineItem);
          const taxRate = getTaxRate(lineItem);
          const taxKey = getTotalsTaxKey(taxName, taxRate);
          const taxAmount = getTaxAmount(lineItem);

          totalExTax += amountExTax;
          if (taxAmount > 0 || taxAmount < 0) {
            totalTaxes[taxKey] = {
              taxName,
              taxRate,
              taxAmount: get(totalTaxes, `${taxKey}.taxAmount`, 0) + taxAmount,
            };
          }
        });

      const totals = {
        totalExTax,
        totalTaxes,
      };

      setWith(draft, `totalsByQuoteId.${quoteId}`, totals, Object);
    });
  });
