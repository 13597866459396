import MuiLinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";

const LinearProgress = (props) => (
  <MuiLinearProgress variant="indeterminate" {...props} />
);

export const HorizontalLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: var(--color-gray);
  }
  &.MuiLinearProgress-root {
    border-radius: 4px;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: var(--color-charcoal);
  }
  .MuiLinearProgress-bar {
    border-radius: 4px;
  }
`;
