import { alertHandler } from "@streamtimefe/utils";

import { bugsnagClient } from "../bugsnag";
import {
  AccountingPlatformError,
  ApiError,
  DataError,
  FormInputError,
  VitallyError,
  WebBridgeError,
} from "../types";

function onWarning(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.warn(...args);
}

function onError(error: unknown, ...args: unknown[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message = (error as any)?.message ?? "";

  if (message === "Network Error") return;

  // eslint-disable-next-line no-console
  console.error(error, ...args);

  if (args.length > 0) {
    bugsnagClient?.notify(String([message, ...args]));
  } else {
    bugsnagClient?.notify(String(error));
  }
}

export function errorHandler(error: unknown, suppressAlert: boolean = false) {
  if (!error) return;

  const alertError = suppressAlert
    ? undefined
    : (message: string) => alertHandler("error", message);

  switch (error.constructor) {
    case ApiError: {
      const apiError = error as ApiError;
      onWarning("[ApiError]", apiError.response);
      alertError?.(apiError.message);
      break;
    }
    case DataError: {
      const dataError = error as DataError;
      onWarning("[DataError]", dataError.message);
      bugsnagClient?.notify(dataError);
      break;
    }
    case FormInputError: {
      const formInputError = error as FormInputError;
      alertError?.(formInputError.message);
      break;
    }
    case WebBridgeError: {
      const webBridgeError = error as WebBridgeError;
      onWarning("[WebBridgeError]", webBridgeError.message);
      bugsnagClient?.notify(webBridgeError.message);
      break;
    }
    case VitallyError: {
      const vitallyError = error as VitallyError;
      onWarning("[VitallyError]", vitallyError.message);
      bugsnagClient?.notify(vitallyError.message);
      break;
    }
    case AccountingPlatformError: {
      const accountingPlatformError = error as AccountingPlatformError;
      onWarning("[AccountingPlatformError]", accountingPlatformError.message);
      break;
    }
    default: {
      alertError?.(
        "Oops. Something went wrong. This is not the droid you’re looking for. Don’t worry though, we've notified the Streamtime Jedis."
      );
      onError(error);
    }
  }
}
