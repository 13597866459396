import type { Dictionary } from "@streamtimefe/types";

import type { TEntityId } from "../../core";
import type { TRoleRate } from "./RoleRate";

export type TRoleIdRateCardIdIndex = Dictionary<
  `${TEntityId}_${TEntityId}`,
  TEntityId
>;
export function createRoleIdRateCardIdIndex(roleRates: TRoleRate[]) {
  const index: TRoleIdRateCardIdIndex = {};

  for (const roleRate of roleRates) {
    index[`${roleRate.roleId}_${roleRate.rateCardId}`] = roleRate.id;
  }

  return index;
}
