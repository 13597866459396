import type { TCompanyOption } from "@streamtimefe/entities";
import { CompanyStatusEnum } from "@streamtimefe/entities";
import { sortBy } from "lodash-es";
import { create } from "zustand";

import type { SearchOption } from "../../entities";
import { getCompanyOptions } from "../../lib/webapi/options";
import { addGlobalStore } from "..";

interface CompanyOptionsStoreState {
  loaded: boolean;
  loading: boolean;
  options: TCompanyOption[];
  searchOptions: SearchOption<TCompanyOption>[];
  load: (force?: boolean) => void;
  setOptions: (options: TCompanyOption[]) => void;
}

export const useCompanyOptionsStore = create<CompanyOptionsStoreState>(
  function (set, get) {
    return {
      loaded: false,
      loading: false,
      options: [],
      searchOptions: [],
      load: async function (force = false) {
        if ((!force && get().loaded) || get().loading) return;

        set({ loaded: false, loading: true });

        try {
          const response = await getCompanyOptions();
          get().setOptions(response.data);
        } catch (e: unknown) {
          console.error("loading company options error: ", e);
        } finally {
          set({ loaded: true, loading: false });
        }
      },
      setOptions(options: TCompanyOption[]) {
        options = sortBy(options, (option) => option.name.toLowerCase());

        const searchOptions: SearchOption<TCompanyOption>[] = options.map(
          (option) => ({
            key: option.id,
            value: option.name,
            searchString: option.name,
            entity: option,
          })
        );

        set({ loaded: true, loading: false, options, searchOptions });
      },
    };
  }
);

export function companyOptionsStoreInit() {
  addGlobalStore(["options", "company"], companyOptionsStore);
}

export function companyOptionsStore() {
  return useCompanyOptionsStore.getState();
}

export function useCompanyOptionsLoaded() {
  return useCompanyOptionsStore((s) => s.loaded);
}

export function useCompanyOptionsLoading() {
  return useCompanyOptionsStore((s) => s.loading);
}

export function useCompanyOptions() {
  return useCompanyOptionsStore((s) => s.options);
}

export function useCompanySearchOptions(includeArchived = false) {
  return useCompanyOptionsStore((s) => {
    if (includeArchived) return s.searchOptions;

    return s.searchOptions.filter(
      (searchOption) =>
        searchOption.entity.companyStatusId !== CompanyStatusEnum.Archived
    );
  });
}
