import { some } from "lodash-es";
import { put, select, takeEvery } from "redux-saga/effects";

import { SCHEDULE_BLOCK_SAVED } from "../../../lib/constants";
import { isPaused } from "../../../lib/entities/scheduleLoggedTimeEntity";
import { actionScheduleSetViewPausedLoggedTimes } from "../../../state/ui/schedule/actions";
import { isItemsByJobs } from "../../../state/ui/schedule/lib";
import {
  selectScheduleUiViewMode,
  selectScheduleUiViewPausedLoggedTimes,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* savedBlock(action) {
  try {
    const { scheduleLoggedTimes } = action.payload;
    const viewMode = yield select(selectScheduleUiViewMode);
    const viewPausedLoggedTimes = yield select(
      selectScheduleUiViewPausedLoggedTimes
    );

    if (
      !isItemsByJobs(viewMode) &&
      !viewPausedLoggedTimes &&
      some(scheduleLoggedTimes, isPaused)
    )
      yield put(actionScheduleSetViewPausedLoggedTimes(true));
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchSavedBlock() {
  yield takeEvery([SCHEDULE_BLOCK_SAVED], savedBlock);
}
