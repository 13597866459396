import type { TFilterGroupTypeEnum } from "@streamtimefe/entities";
import { useMemo, useRef, useState } from "react";
import { Button, Icon, MdAdd } from "st-shared/components";
import { getReportingFilterSearchOptions } from "st-shared/entities";
import { useUniqueKey } from "st-shared/hooks";
import {
  addFilterGroup,
  createFilterEvent,
  FilterSearchMenu,
} from "st-shared/module";

import { reportingStore } from "../../../state/stores/reportingStore";
import { useReportingSavedSegmentDataSet } from "../../../state/stores/savedSegmentSelectors";
import { EditDataColumn } from "../../menus/EditDataColumn";
import {
  addButtonsContainer,
  container,
  content,
  textButton,
  textButtonIcon,
} from "./DataSet.css";
import { DataSetColumns } from "./DataSetColumns";
import { DataSetFilters } from "./DataSetFilters";
import { DataSetHeader } from "./DataSetHeader";

interface Props {
  dataSetId: string;
  showHeader: boolean;
}

export function DataSet({ dataSetId, showHeader }: Props) {
  const dataSet = useReportingSavedSegmentDataSet(dataSetId);

  const filterOptions = useMemo(
    () => getReportingFilterSearchOptions(dataSet.entityDescriptorId),
    [dataSet.entityDescriptorId]
  );

  const ref = useRef<HTMLDivElement>(null);
  const [addFilterAnchorEl, setAddFilterAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const [addColumnAnchorEl, setAddColumnAnchorEl] =
    useState<HTMLDivElement | null>(null);

  function openAddFilterMenu() {
    setAddFilterAnchorEl(ref.current);
  }

  function closeAddFilterMenu() {
    setAddFilterAnchorEl(null);
  }

  function openAddColumnMenu() {
    setAddColumnAnchorEl(ref.current);
  }

  function closeAddColumnMenu() {
    setAddColumnAnchorEl(null);
  }

  const [filterListenerUuid] = useUniqueKey();

  function onFilterSearchPick(filterGroupTypeId: TFilterGroupTypeEnum) {
    closeAddFilterMenu();

    const filterGroup = addFilterGroup(
      filterGroupTypeId,
      reportingStore()
        .savedSegment.helpers.getReportingSavedSegment()
        .generateId()
    );

    reportingStore().savedSegment.actions.addDatasetFilterGroup(
      dataSet.id,
      filterGroup
    );

    reportingStore().ui.actions.setDatasetFiltersOpen(dataSet.id, true);

    requestAnimationFrame(() => {
      createFilterEvent(filterListenerUuid, filterGroup.id, "open");
    });
  }

  return (
    <div className={container} ref={ref}>
      {showHeader && <DataSetHeader dataSet={dataSet} />}
      <div className={content}>
        <DataSetFilters
          dataSet={dataSet}
          uuid={filterListenerUuid}
          openAddFilterMenu={openAddFilterMenu}
        />
        <DataSetColumns
          dataSetId={dataSetId}
          openAddColumnMenu={openAddColumnMenu}
        />
        <div className={addButtonsContainer}>
          <Button
            variant="link"
            className={textButton}
            onClick={openAddFilterMenu}
          >
            <Icon icon={MdAdd} size={18} className={textButtonIcon} />
            Add filter
          </Button>
          <Button
            variant="link"
            className={textButton}
            onClick={openAddColumnMenu}
          >
            <Icon icon={MdAdd} size={18} className={textButtonIcon} />
            Add column
          </Button>
        </div>
        {addFilterAnchorEl && (
          <FilterSearchMenu
            anchorEl={addFilterAnchorEl}
            onClose={closeAddFilterMenu}
            options={filterOptions}
            onPick={onFilterSearchPick}
          />
        )}
        {addColumnAnchorEl && (
          <EditDataColumn
            anchorEl={addColumnAnchorEl}
            onClose={closeAddColumnMenu}
            init={{
              type: "add",
              dataSetId: dataSet.id,
              entityDescriptorId: dataSet.entityDescriptorId,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: -6,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        )}
      </div>
    </div>
  );
}
