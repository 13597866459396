import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import JoinJsx from "../../../../modules/JoinJsx";
import Tooltip from "../../../../modules/Tooltip";

const ListRecipients = ({ recipients }) => (
  <JoinJsx separator=", ">
    {recipients.map(({ name, email }) => (
      <Tooltip title={email} placement="bottom" key={email}>
        <Name>{name}</Name>
      </Tooltip>
    ))}
  </JoinJsx>
);

ListRecipients.propTypes = {
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ListRecipients;

const Name = styled.span`
  font-weight: bold;
  text-decoration: underline dotted;
`;
