import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { COMPANY_GROUPED_OPTION_TYPE } from "../../../lib/entities/companyOptionsEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import { entityIdListType } from "../../../lib/types/entityTypes";
import { useCompanyOptionsGroupedByStatus } from "../../../redux/selectors/options/companyOptionsGroupedByStatus";
import Flex from "../../elements/Flex";
import SearchMenu from "../SearchMenu";

const getNoResultsMessage = searchQuery =>
  `Hmm, looks like no companies match ‘${searchQuery}’. Perhaps try a different search term.`;

const CompaniesSearchMenu = ({
  anchorEl,
  onPickOption,
  onClose,
  onCreate,
  excludeIds
}) => {
  const companyGroupedOptions = useCompanyOptionsGroupedByStatus();

  return (
    <SearchMenu
      anchorEl={anchorEl}
      options={companyGroupedOptions}
      excludeIds={excludeIds}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      HeaderComponentProps={{
        title: "Companies",
        noOptionsMessage: "There are no other companies",
        getNoResultsMessage
      }}
      // FooterComponent={
      //   onCreate && (
      //     <SearchMenuCreateFooter
      //       text="Create new company"
      //       onClick={onCreate}
      //     />
      //   )
      // }
      onClose={onClose}
    >
      {({ key, value, optionType }) => {
        const classes = ["rainbowHover"];
        if (optionType === COMPANY_GROUPED_OPTION_TYPE.OPTIONS_HEADING)
          classes.push("heading");
        if (optionType === COMPANY_GROUPED_OPTION_TYPE.ARCHIVED)
          classes.push("archived");
        return (
          <CompanyOption
            className={classes.join(" ")}
            key={key}
            onClick={() => onPickOption(key)}
          >
            {value}
          </CompanyOption>
        );
      }}
    </SearchMenu>
  );
};

CompaniesSearchMenu.propTypes = {
  anchorEl: domNodeType,
  onPickOption: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  excludeIds: entityIdListType
};

CompaniesSearchMenu.defaultProps = {
  anchorEl: null,
  excludeIds: [],
  onClose: null,
  onCreate: null
};

export default CompaniesSearchMenu;

const CompanyOption = styled(Flex)`
  width: 100%;
  padding: 6px 12px;
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  user-select: none;
  align-items: center;
  cursor: pointer;
  &.heading {
    background-color: var(--color-ygrittesnow);
    color: var(--color-gray-dark);
    pointer-events: none;
    text-transform: uppercase;
    font-size: 12px;
  }
  &.archived {
    color: var(--tooltip-title-color);
  }
`;
