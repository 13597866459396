import isError from "lodash-es/isError";
import { call, put } from "redux-saga/effects";

import {
  feToWebConfirmJobAssignRoleTime,
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../../lib/WebAppAPI/fePages/genericWeb";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { actionEntitiesReceived } from "../../actions";
import {
  JOB_PHASE_ASSIGN_ROLE_TIME,
  JobPhaseAssignRoleTimeAction,
} from "../actions";
import JobPhaseAPI from "../api";

function* assignRoleTime(action: JobPhaseAssignRoleTimeAction) {
  try {
    yield call(feToWebConfirmJobAssignRoleTime, {
      jobPhaseId: action.jobPhaseId,
      roleId: action.roleId,
      userId: action.userId,
    });

    feToWebShowPageLoadingMask(11);

    const data: Awaited<ReturnType<typeof JobPhaseAPI.assignRoleTime>> =
      yield call(
        JobPhaseAPI.assignRoleTime,
        action.jobPhaseId,
        action.roleId,
        action.userId
      );

    yield put(actionEntitiesReceived(data));
  } catch (error) {
    if (isError(error)) sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchJobPhaseAssignRoleTime() {
  yield takeLatestBy(
    [JOB_PHASE_ASSIGN_ROLE_TIME],
    assignRoleTime,
    (action: JobPhaseAssignRoleTimeAction) => action.jobPhaseId
  );
}
