// deprecated

import type { Option } from "./Option";

export const TimeAllocationMethods = {
  Item: 1,
  People: 2,
} as const;

export type TTimeAllocationMethods =
  (typeof TimeAllocationMethods)[keyof typeof TimeAllocationMethods];

export interface TimeAllocationMethod extends Option {
  id: TTimeAllocationMethods;
}

export function timeAllocationMethodObj(entity: TimeAllocationMethod) {
  const obj = {
    entity,
    isItem: function () {
      return entity.id === TimeAllocationMethods.Item;
    },
    isPeople: function () {
      return entity.id === TimeAllocationMethods.People;
    },
    getUnknownIdError: function () {
      return `Unknown Time Allocation Method: ${entity.id} - ${entity.name}`;
    },
  };
  return obj;
}

export function createTimeAllocationMethod(
  id: TTimeAllocationMethods
): TimeAllocationMethod {
  return { id, name: "" };
}
