import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { costingMethodObj } from "st-shared/entities";

import { JOB_ITEM_SET_TOTAL } from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import {
  getInitialItemTotalInput,
  useJobItemTotalInput,
} from "../../../redux/selectors/jobItem/selectJobItemTotalInput";
import { ItemInputField } from "../components/ItemInputField";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import { itemTotalCss, itemTotalFieldCss } from "./ItemTotal.css";

export function ItemTotal() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemId,
    key: FOCUS_KEYS.ITEM_TOTAL,
  });

  const [total, setTotal] = useState("");

  const [value, placeholder] = useJobItemTotalInput(
    jobId,
    jobItemId,
    focused,
    total
  );

  const dispatch = useDispatch();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!isJobEditable) return;

    setTotal(event.target.value);

    dispatch(
      createAction(JOB_ITEM_SET_TOTAL, {
        jobItemId,
        value: event.target.value,
      })
    );
  }

  function onBlur() {
    setFocused(false);
  }

  function onFocus() {
    setTotal(getInitialItemTotalInput(jobItem));
    setFocused(true);
  }

  return (
    <div className={itemTotalCss}>
      <ItemInputField
        ref={ref}
        containerClassName={itemTotalFieldCss}
        as="numeric"
        label="Total"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        largeText
        doFocusedBar={false}
        disabled={
          !isJobEditable ||
          !costingMethodObj(jobItem.costingMethod).isBasedByValue()
        }
        fallbackLook={!jobItem.isBillable}
      />
    </div>
  );
}
