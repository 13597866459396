import React from "react";
import styled from "styled-components";
import { useInit } from "../../../lib/hooks/useInit";
import DraftJsContent from "./DraftJsContent";
import { createPostPlugins } from "./plugins";
import DraftJsEditor from "./index";

const getInitValues = () => {
  const { InlineToolbar, plugins } = createPostPlugins();
  return [InlineToolbar, plugins];
};

const PostEditor = (props) => {
  const [InlineToolbar, plugins] = useInit(getInitValues);

  return (
    <Wrapper>
      <DraftJsEditor plugins={plugins} {...props}>
        <InlineToolbar />
      </DraftJsEditor>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px 20px;
  width: calc(100% - 40px);
  flex-grow: 1;
  overflow-y: auto;
  ${DraftJsContent} {
    padding: 50px 20px 20px;
    min-height: 30vh;
    background: var(--color-jonsnow);
    color: var(--color-charcoal);
    .public-DraftEditorPlaceholder-root {
      color: var(--color-gray-dark);
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 140% !important;
    }
  }
`;

export default PostEditor;
