export const LOGGED_TIME_STATUS_ID_INCOMPLETE = 1;
export const LOGGED_TIME_STATUS_ID_COMPLETE = 2;
export const LOGGED_TIME_STATUS_ID_DELETED = 3;

export const JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START = 1;
export const JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END = 2;
export const JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START = 3;
export const JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END = 4;

export const JOB_ITEM_USER_SNOOZE_STATUS_ID_NONE = 1;
export const JOB_ITEM_USER_SNOOZE_STATUS_ID_FOREVER = 2;
export const JOB_ITEM_USER_SNOOZE_STATUS_ID_JOB_ITEM_START_DATE = 3;
export const JOB_ITEM_USER_SNOOZE_STATUS_ID_BEFORE_JOB_ITEM_END_DATE = 4;
export const JOB_ITEM_USER_SNOOZE_STATUS_ID_CUSTOM = 5;

export const JOB_ITEM_STATUS_ID_PLANNING = 1;
export const JOB_ITEM_STATUS_ID_COMPLETE = 2;
export const JOB_ITEM_STATUS_ID_DELETED = 3;
export const JOB_ITEM_STATUS_ID_SCHEDULED = 4;

export const JOB_ITEM_SUB_ITEM_STATUS_ID_INCOMPLETE = 1;
export const JOB_ITEM_SUB_ITEM_STATUS_ID_COMPLETE = 2;
export const JOB_ITEM_SUB_ITEM_STATUS_ID_DELETED = 3;

export const INVOICE_STATUS_ID_DRAFT = 1;
export const INVOICE_STATUS_ID_AWAITING_PAYMENT = 2;
export const INVOICE_STATUS_ID_PAID = 3;
export const INVOICE_STATUS_ID_DELETED = 4;
export const INVOICE_STATUS_ID_VOIDED = 5;

export const INVOICE_TYPE_INVOICE = 1;
export const INVOICE_TYPE_CREDIT_NOTE = 2;

export const INVOICE_PAYMENT_TERM_ID_UPON_RECEIPT = 1;
export const INVOICE_PAYMENT_TERM_ID_DAYS_15 = 2;
export const INVOICE_PAYMENT_TERM_ID_DAYS_30 = 3;
export const INVOICE_PAYMENT_TERM_ID_DAYS_45 = 4;
export const INVOICE_PAYMENT_TERM_ID_DAYS_60 = 5;
export const INVOICE_PAYMENT_TERM_ID_CUSTOM = 6;
export const INVOICE_PAYMENT_TERM_ID__20TH_NEXT_MONTH = 7;

export const ITEM_PRICING_METHOD_TYPE_ID_UNIT = 1;
export const ITEM_PRICING_METHOD_TYPE_ID_MARKUP = 2;
export const ITEM_PRICING_METHOD_TYPE_ID_FIXED = 3;

export const INTERVAL_UNIT_ID_WEEKLY = 1;
export const INTERVAL_UNIT_ID_FORTNIGHTLY = 2;
export const INTERVAL_UNIT_ID_MONTHLY = 3;

export const QUOTE_STATUS_ID_PENDING = 1;
export const QUOTE_STATUS_ID_APPROVED = 2;
export const QUOTE_STATUS_ID_DECLINED = 3;
export const QUOTE_STATUS_ID_DELETED = 4;
export const QUOTE_STATUS_ID_DRAFT = 5;

export const LOGGED_EXPENSE_STATUS_ID_DRAFT = 1;
export const LOGGED_EXPENSE_STATUS_ID_APPROVED = 2;
export const LOGGED_EXPENSE_STATUS_ID_PAID = 3;
export const LOGGED_EXPENSE_STATUS_ID_DELETED = 4;
export const LOGGED_EXPENSE_STATUS_ID_EXTERNAL = 5;
