import { get, isEmpty } from "lodash-es";
import { pushOnce } from "../../../../lib/arrays";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_INVOICE_LINE_ITEMS,
  INVOICE_LINE_ITEM_CREATE,
  INVOICE_LINE_ITEM_CREATE_ERROR,
  INVOICE_LINE_ITEM_CREATED,
  INVOICE_LINE_ITEM_DELETE,
  INVOICE_LINE_ITEM_DELETE_ERROR,
  INVOICE_LINE_ITEM_EDIT,
  INVOICE_LINE_ITEM_MERGE_SAVE_ERROR,
  INVOICE_LINE_ITEM_SAVE,
  INVOICE_LINE_ITEM_SAVE_ERROR,
  INVOICE_LINE_ITEM_SORT_CANCEL,
  INVOICE_LINE_ITEM_SORT_MOVE,
  INVOICE_LINE_ITEM_SORT_SAVE_ERROR,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR
} from "../../../../lib/constants";
import { getInvoiceId } from "../../../../lib/entities/invoiceLineItemEntity";
import {
  getOrderId,
  isDeleted,
  moveLineItemOrder
} from "../../../../lib/ui/commercialDocumentLineItems";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import replaceTemporaryEntity from "../../../helpers/replaceTemporaryEntity";
import lineItemElementsByInvoiceIdReducer from "./lineItemElementsByInvoiceIdReducer";
import reorderByInvoiceIdReducer from "./reorderByInvoiceIdReducer";
import sortedIdsByInvoiceIdReducer from "./sortedIdsByInvoiceIdReducer";
import subTotalsExTaxByInvoiceIdReducer from "./subTotalsExTaxByInvoiceIdReducer";
import subTotalsIncTaxByInvoiceIdReducer from "./subTotalsIncTaxByInvoiceIdReducer";
import totalsByInvoiceIdReducer from "./totalsByInvoiceIdReducer";

const idsByInvoiceIdIndexer = createEntityIndexedArrayReducer(
  entity => entity.invoiceId
);

const getTouchedInvoiceIds = changedEntities => {
  const touchedInvoiceIds = [];

  changedEntities.forEach(({ prevEntity, newEntity }) => {
    const prevPath = prevEntity && getInvoiceId(prevEntity);
    const newPath = newEntity && getInvoiceId(newEntity);

    if (prevPath && prevPath !== newPath) pushOnce(touchedInvoiceIds, prevPath);

    if (newPath) pushOnce(touchedInvoiceIds, newPath);
  });

  return touchedInvoiceIds;
};

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  let nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByInvoiceId: idsByInvoiceIdIndexer(state.idsByInvoiceId, changedEntities)
  };

  const touchedInvoiceIds = getTouchedInvoiceIds(changedEntities);

  nextState = reorderByInvoiceIdReducer(nextState, touchedInvoiceIds);

  nextState = sortedIdsByInvoiceIdReducer(nextState, touchedInvoiceIds);

  nextState = lineItemElementsByInvoiceIdReducer(
    state,
    nextState,
    touchedInvoiceIds
  );

  nextState = subTotalsExTaxByInvoiceIdReducer(nextState, touchedInvoiceIds);

  nextState = subTotalsIncTaxByInvoiceIdReducer(nextState, touchedInvoiceIds);

  nextState = totalsByInvoiceIdReducer(nextState, touchedInvoiceIds);

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_INVOICE_LINE_ITEMS],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_INVOICE_LINE_ITEMS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const createInvoiceLineItemReducer = (
  state,
  { payload: { invoiceLineItem } }
) => reduceChangedEntities(state, parseEntityPayload(state, [invoiceLineItem]));

const createdInvoiceLineItemReducer = (
  state,
  { payload: { id, invoiceLineItems } }
) =>
  reduceChangedEntities(
    state,
    replaceTemporaryEntity(state, id, invoiceLineItems)
  );

const createInvoiceLineItemErrorReducer = (
  state,
  { payload: { invoiceLineItem } }
) => reduceChangedEntities(state, [{ prevEntity: invoiceLineItem }]);

const deleteInvoiceLineItemReducer = (state, { payload: { id } }) =>
  reduceChangedEntities(state, parseRemovedEntities(state, [id]));

const deleteInvoiceLineItemErrorReducer = (
  state,
  { payload: { id, invoiceLineItem } }
) =>
  reduceChangedEntities(state, [
    { prevEntity: state.byId[id], newEntity: state.byId[id] || invoiceLineItem }
  ]);

const editInvoiceLineItemReducer = (state, { payload: { invoiceLineItem } }) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [invoiceLineItem], isDeleted)
  );

const saveInvoiceLineItemErrorReducer = (
  state,
  { payload: { id, prevInvoiceLineItem } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, [prevInvoiceLineItem], isDeleted)
  );

const saveMergeInvoiceLineItemErrorReducer = (
  state,
  { payload: { prevInvoiceLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, prevInvoiceLineItems, isDeleted)
  );

const saveUndoMergeInvoiceLineItemErrorReducer = (
  state,
  { payload: { prevInvoiceLineItems, mergedInvoiceLineItems } }
) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(
      state,
      [
        ...prevInvoiceLineItems,
        ...mergedInvoiceLineItems.map(lineItem => ({
          ...lineItem,
          active: false
        }))
      ],
      isDeleted
    )
  );

const sortInvoiceLineItemMoveReducer = (state, { payload }) => {
  const { invoiceId, sourceOrderId, destinationOrderId } = payload;
  const sortedInvoiceLineItems = get(
    state,
    `sortedIdsByInvoiceId.${invoiceId}`,
    []
  ).map(id => state.byId[id]);

  const invoiceLinesItems = moveLineItemOrder(
    sortedInvoiceLineItems,
    sourceOrderId,
    destinationOrderId
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, invoiceLinesItems)
  );
};

const undoSortInvoiceLineItemMoveReducer = (state, { payload }) => {
  const { invoiceId, snapshot } = payload;

  const ids = get(state, `idsByInvoiceId.${invoiceId}`, []);
  const invoiceLineItems = ids.map(id => state.byId[id]);

  const prevInvoiceLineItems = invoiceLineItems.map(invoiceLineItem =>
    snapshot[invoiceLineItem.id]
      ? {
          ...invoiceLineItem,
          orderId: getOrderId(snapshot[invoiceLineItem.id])
        }
      : invoiceLineItem
  );

  return reduceChangedEntities(
    state,
    parseEntityPayload(state, prevInvoiceLineItems)
  );
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [INVOICE_LINE_ITEM_CREATE]: createInvoiceLineItemReducer,
    [INVOICE_LINE_ITEM_DELETE]: deleteInvoiceLineItemReducer,
    [INVOICE_LINE_ITEM_DELETE_ERROR]: deleteInvoiceLineItemErrorReducer,
    [INVOICE_LINE_ITEM_EDIT]: editInvoiceLineItemReducer,
    [INVOICE_LINE_ITEM_SAVE]: editInvoiceLineItemReducer,
    [INVOICE_LINE_ITEM_CREATED]: createdInvoiceLineItemReducer,
    [INVOICE_LINE_ITEM_CREATE_ERROR]: createInvoiceLineItemErrorReducer,
    [INVOICE_LINE_ITEM_SAVE_ERROR]: saveInvoiceLineItemErrorReducer,
    [INVOICE_LINE_ITEM_MERGE_SAVE_ERROR]: saveMergeInvoiceLineItemErrorReducer,
    [INVOICE_LINE_ITEM_UNDO_MERGE_SAVE_ERROR]: saveUndoMergeInvoiceLineItemErrorReducer,
    [INVOICE_LINE_ITEM_SORT_MOVE]: sortInvoiceLineItemMoveReducer,
    [INVOICE_LINE_ITEM_SORT_CANCEL]: undoSortInvoiceLineItemMoveReducer,
    [INVOICE_LINE_ITEM_SORT_SAVE_ERROR]: undoSortInvoiceLineItemMoveReducer
  }
);
