import { CatergorisedSegments } from "./catergorised/CatergorisedSegents";
import { SavedSegmentSidebarProps } from "./context/SavedSegmentSidebarContext";
import { SavedSegmentSidebarContextProvider } from "./context/SavedSegmentSidebarContextProvider";
import { savedSegmentSidebarCss } from "./SavedSegmentSidebar.css";

export function SavedSegmentSidebar({ ...props }: SavedSegmentSidebarProps) {
  return (
    <SavedSegmentSidebarContextProvider {...props}>
      <div className={savedSegmentSidebarCss}>
        <CatergorisedSegments />
      </div>
    </SavedSegmentSidebarContextProvider>
  );
}
