import clsx from "clsx";

import { Select, SelectItem, SelectProps } from "../../../components";
import { selectButtonCss, selectCss } from "..";

export interface FilterSelectProps
  extends Omit<SelectProps<{ id: number; name: string }>, "children"> {}

export function FilterSelect({
  items,
  className,
  ...props
}: FilterSelectProps) {
  return (
    <Select
      items={items}
      {...props}
      buttonProps={{ className: clsx(selectButtonCss, className) }}
      className={selectCss}
    >
      {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
    </Select>
  );
}
