import { useEffect, useRef } from "react";

const stringIncludes = (text, values) =>
  new RegExp(values.join("|")).test(text);

const omittedClassNames = ["MuiAutocomplete-option"];

export const useOutsideClick = (prerequisite, callback, deps = []) => {
  const ref = useRef();
  useEffect(() => {
    const handleClick = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !stringIncludes(event.target.className, omittedClassNames)
      ) {
        callback();
      }
    };
    if (prerequisite) document.addEventListener("click", handleClick, true);
    return () => document.removeEventListener("click", handleClick, true);
  }, [prerequisite, ref, ...deps]);
  return ref;
};
