import numeral from "numeral";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import {
  JOB_SET_BUDGET,
  NUMBER_FORMAT_NO_DECIMAL,
  NUMBER_FORMAT_TWO_DECIMALS,
} from "../../../../lib/constants";
import { FOCUS_KEYS } from "../../../../lib/constants/jobDetails";
import {
  getJobCurrencyBudget,
  getJobCurrencyTotalApprovedQuoteExTax,
  isArchived,
} from "../../../../lib/entities/jobEntity";
import createAction from "../../../../redux/helpers/createAction";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobCurrencyFinalBudget } from "../../../../redux/selectors/job/selectJobCurrencyFinalBudget";
import { useIsJobEditable } from "../../../../redux/selectors/jobDetails/ui/isJobEditable";
import JumbotronStatField from "../../components/JumbotronStatField";
import { useJobId } from "../../context/JobIdContext";
import { useFocusStateRef } from "../../hooks/useFocusStateInputRef";
import { BudgetInput, InlineTextSpan } from "../styles";

const JumbotronBudget = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isJobEditable = useIsJobEditable(jobId);
  const jobCurrencyFinalBudget = useJobCurrencyFinalBudget(jobId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);

  const dispatch = useDispatch();

  const [budget, setBudget] = useState("");

  const [ref, focused, setFocused] = useFocusStateRef({
    jobId,
    key: FOCUS_KEYS.JOB_BUDGET,
  });

  const inputValue = focused
    ? budget
    : formatForeignCurrency(customerCurrency, {
        value: getJobCurrencyBudget(job),
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      });

  const handleChange = (e) => {
    if (!isJobEditable) return;
    const newValue = String(e.target.value).replace(/[^0-9.]/g, "");

    setBudget(newValue);

    dispatch(
      createAction(JOB_SET_BUDGET, {
        jobId,
        value: newValue,
      })
    );
  };

  const handleBlur = (e) => {
    setFocused(false);
  };

  const handleFocus = (e) => {
    setBudget(
      numeral(getJobCurrencyBudget(job)).format(NUMBER_FORMAT_TWO_DECIMALS)
    );
    setFocused(true);
  };

  if (isArchived(job) && Number(getJobCurrencyBudget(job)) === 0) return null;

  return (
    <JumbotronStatField
      largeText
      title={`BUDGET${
        getJobCurrencyTotalApprovedQuoteExTax(job) > 0 ? " (FROM QUOTE)" : ""
      }`}
    >
      {getJobCurrencyTotalApprovedQuoteExTax(job) > 0 && (
        <InlineTextSpan $bold>
          {formatForeignCurrency(customerCurrency, {
            value: jobCurrencyFinalBudget,
            currency: jobCurrency,
            hideLongSymbols: true,
            format: NUMBER_FORMAT_NO_DECIMAL,
          })}
        </InlineTextSpan>
      )}
      {!(getJobCurrencyTotalApprovedQuoteExTax(job) > 0) && (
        <BudgetInput
          ref={ref}
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={!isJobEditable}
        />
      )}
    </JumbotronStatField>
  );
};

export default JumbotronBudget;
