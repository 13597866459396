import * as PropTypes from "prop-types";
import React from "react";
import { reactNodesType } from "../../lib/types/reactTypes";

const JoinJsx = ({ children, separator }) =>
  children.reduce(
    (acc, x) =>
      acc === null ? (
        x
      ) : (
        <>
          {acc}
          {separator}
          {x}
        </>
      ),
    null
  );

JoinJsx.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  separator: reactNodesType.isRequired
};

export default JoinJsx;
