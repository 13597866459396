import PropTypes from "prop-types";
import { forwardRef } from "react";
import styled from "styled-components";

import { reactNodesType } from "../../../lib/types/reactTypes";
import ExpandMoreIcon from "../../elements/Icons/ExpandMoreIcon";
import ProgressBar from "../../elements/ProgressBar";
import { InlineText } from "../Jumbotron/styles";

const JumbotronProgressField = forwardRef((props, ref) => {
  const {
    name,
    onClick,
    children,
    progressPercentage,
    progressColor,
    secondaryProgressPercentage,
    secondaryProgressColor,
    noOverrun,
    secondaryNoOverrun,
  } = props;

  return (
    <Container
      onClick={onClick || undefined}
      ref={ref}
      {...props}
      $isClickable={!!onClick}
    >
      <TitleContainer>
        <Title>{name}</Title>{" "}
        {onClick && <StyledExpandIcon start={270} end={270} size={24} />}
        <TitleFigure>{children}</TitleFigure>
      </TitleContainer>
      <ProgressBar
        progressColor={progressColor}
        progressPercentage={progressPercentage}
        secondaryProgressPercentage={secondaryProgressPercentage}
        secondaryProgressColor={secondaryProgressColor}
        noOverrun={noOverrun}
        secondaryNoOverrun={secondaryNoOverrun}
      ></ProgressBar>
    </Container>
  );
});

JumbotronProgressField.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: reactNodesType,
  progressPercentage: PropTypes.number.isRequired,
  progressColor: PropTypes.string.isRequired,
  secondaryProgressPercentage: PropTypes.number,
  secondaryProgressColor: PropTypes.string,
  noOverrun: PropTypes.bool,
  secondaryNoOverrun: PropTypes.bool,
};

JumbotronProgressField.defaultProps = {
  onClick: null,
  children: null,
  secondaryProgressPercentage: 0,
  secondaryProgressColor: "var(--color-gray-bright)",
  noOverrun: false,
  secondaryNoOverrun: false,
};

export default JumbotronProgressField;

const Container = styled.div.attrs({ className: "JumbotronProgressField" })`
  color: black;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  ${(props) => props.$isClickable && "cursor: pointer;"}

  &:hover {
    .StyledExpandIcon {
      color: var(--color-charcoal);
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
`;

const Title = styled.div`
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-dark);
  line-height: 26px;
  height: 24px;
  margin-right: 5px;
  white-space: nowrap;
`;

const StyledExpandIcon = styled(ExpandMoreIcon).attrs({
  className: "StyledExpandIcon",
})`
  color: var(--color-gray);
`;

const TitleFigure = styled.div`
  flex-grow: 1;
  text-align: right;
`;

export const JumbotronProgressFieldValue = styled(InlineText)`
  color: var(--color-gray-dark);
  font-weight: var(--font-weight-medium);

  .bold {
    color: var(--color-charcoal);
    font-weight: var(--font-weight-bold);
  }
`;
