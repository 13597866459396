import { isArray, memoize } from "lodash-es";
import moment from "moment";
import { JobStatuses, JobStatusesType } from "st-shared/entities/JobStatus";

import { memoizeArgs, memoizeWithTodayDate } from "./memoizers";

export const parseDate = memoize((date: moment.MomentInput = new Date()) =>
  moment(date)
);

export const formatDate = memoizeArgs(
  (date?: moment.MomentInput, pattern?: string) =>
    parseDate(date).format(pattern)
);

export function toDate(momentDate: moment.MomentInput, pattern?: string) {
  return moment(momentDate).format(pattern);
}

export const isDateEqual = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).isSame(date2)
);

export const isBefore = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).isBefore(date2)
);

export const isAfter = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).isAfter(date2)
);

export const isSameOrBefore = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).isSameOrBefore(date2)
);

export const isSameOrAfter = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).isSameOrAfter(date2)
);

export const getDaysInMonth = memoize((date: moment.MomentInput) =>
  parseDate(date).daysInMonth()
);

export const getDaysInYear = memoize((date: moment.MomentInput) =>
  parseDate(date).isLeapYear() ? 366 : 365
);

export const differenceInCalendarDays = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).diff(parseDate(date2), "days")
);

export const differenceInCalendarWeeks = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).diff(parseDate(date2), "weeks")
);

export const differenceInCalendarMonths = memoizeArgs(
  (date1: moment.MomentInput, date2: moment.MomentInput) =>
    parseDate(date1).diff(parseDate(date2), "months")
);

export const getDay = memoize((date: moment.MomentInput) =>
  parseDate(date).day()
);

export const isWeekend = memoize(
  (date: moment.MomentInput) => getDay(date) === 0 || getDay(date) === 6
);

export const isMonday = memoize(
  (date: moment.MomentInput) => getDay(date) === 1
);

export const getMonth = memoize((date: moment.MomentInput) =>
  parseDate(date).month()
);

export const getYear = memoize((date: moment.MomentInput) =>
  parseDate(date).year()
);

export const getTodayDate = memoizeWithTodayDate(formatDate);

export function isToday(date: moment.MomentInput) {
  return formatDate(date) === getTodayDate();
}

export const getEachDay = memoizeArgs(
  (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    const dates: string[] = [];
    const currDateMoment = moment(parseDate(startDate));

    while (currDateMoment.isSameOrBefore(endDate)) {
      dates.push(currDateMoment.format());
      currDateMoment.add(1, "days");
    }

    return dates;
  }
);

export const getStartOfWeek = memoize(
  (date: moment.MomentInput = getTodayDate()) =>
    parseDate(date).day() === 0
      ? moment(parseDate(date)).day(-6).format()
      : moment(parseDate(date)).day(1).format()
);

export const getEndOfWeek = memoize(
  (date: moment.MomentInput = getTodayDate()) =>
    parseDate(date).day() === 0
      ? formatDate(date)
      : moment(parseDate(date)).day(7).format()
);

export const getStartOfMonth = memoize(
  (date: moment.MomentInput = getTodayDate()) =>
    moment(parseDate(date)).date(1).format()
);

export const getStartOfYear = memoize(
  (date: moment.MomentInput = getTodayDate()) =>
    moment(parseDate(date)).dayOfYear(1).format()
);

export const isThisWeek = (date: moment.MomentInput) =>
  getStartOfWeek(date) === getStartOfWeek();

export const isThisMonth = (date: moment.MomentInput) =>
  getStartOfMonth(date) === getStartOfMonth();

export const getDaysFromMonday = memoize(
  (date: moment.MomentInput) => (getDay(date) + 6) % 7
);

export const addDays = (date: moment.MomentInput, days: number) =>
  moment(parseDate(date)).add(days, "days").format();

export const subDays = (date: moment.MomentInput, days: number) =>
  moment(parseDate(date)).subtract(days, "days").format();

export const addWeeks = (date: moment.MomentInput, weeks: number) =>
  moment(parseDate(date)).add(weeks, "weeks").format();

export const subWeeks = (date: moment.MomentInput, weeks: number) =>
  moment(parseDate(date)).subtract(weeks, "weeks").format();

export const addMonths = (date: moment.MomentInput, months: number) =>
  moment(parseDate(date)).add(months, "months").format();

export const subMonths = (date: moment.MomentInput, months: number) =>
  moment(parseDate(date)).subtract(months, "months").format();

export const expandDateToWeek = memoize((date: moment.MomentInput) =>
  getEachDay(getStartOfWeek(date), getEndOfWeek(date))
);

export const getISODateStringAsTimestamp = (dateString = getTodayDate()) =>
  String(dateString).length > 10
    ? Date.parse(String(dateString))
    : Date.parse(`${String(dateString)}T00:00:00.000Z`);

export const simpleCompareAsc = (date1: string, date2: string) =>
  getISODateStringAsTimestamp(date1) - getISODateStringAsTimestamp(date2);

export const simpleCompareDesc = (date1: string, date2: string) =>
  getISODateStringAsTimestamp(date2) - getISODateStringAsTimestamp(date1);

export const minDate = (date1: string | string, ...dates: string[]) => {
  const definedDates = isArray(date1)
    ? date1.filter(Boolean)
    : [date1, ...dates].filter(Boolean);
  return definedDates.length
    ? moment.min(definedDates.map((date) => parseDate(date))).format()
    : null;
};

export const maxDate = (date1: string | string[], ...dates: string[]) => {
  const definedDates = isArray(date1)
    ? date1.filter(Boolean)
    : [date1, ...dates].filter(Boolean);
  return definedDates.length
    ? moment.max(definedDates.map((date) => parseDate(date))).format()
    : null;
};

export const getStartOfWeekDatesInRange = memoizeArgs(
  (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    const weeks: string[] = [];
    const currentMondayMoment = moment(getStartOfWeek(startDate));

    while (currentMondayMoment.isSameOrBefore(endDate)) {
      weeks.push(currentMondayMoment.format());
      currentMondayMoment.add(1, "weeks");
    }

    return weeks;
  }
);

export const getStartOfMonthDatesInRange = memoizeArgs(
  (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    const months: string[] = [];
    const currentMonthMoment = moment(getStartOfMonth(startDate));

    while (currentMonthMoment.isSameOrBefore(endDate)) {
      months.push(currentMonthMoment.format());
      currentMonthMoment.add(1, "months");
    }

    return months;
  }
);

export const getStartOfYearDatesInRange = memoizeArgs(
  (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    const months: string[] = [];
    const currentYearMoment = moment(getStartOfYear(startDate));

    while (currentYearMoment.isSameOrBefore(endDate)) {
      months.push(currentYearMoment.format());
      currentYearMoment.add(1, "year");
    }

    return months;
  }
);

export const getWeekendsInRange = memoizeArgs(
  (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
    getEachDay(startDate, endDate).filter((date) => isWeekend(date))
);

export const getFuzzyRelativeDateText = (
  date?: moment.MomentInput,
  anchorDate = getTodayDate()
) => {
  if (!date) return "";

  const diff = Math.abs(differenceInCalendarDays(anchorDate, date));

  if (diff < 7) return moment(date).calendar(anchorDate);

  const weekDiff = Math.abs(differenceInCalendarWeeks(anchorDate, date));

  if (weekDiff > 1 && weekDiff < 4)
    return isBefore(anchorDate, date)
      ? `in ${weekDiff} weeks`
      : `${weekDiff} weeks ago`;

  return moment(date).from(anchorDate);
};

export const getSpecificRelativeDateText = (
  date: moment.MomentInput,
  anchorDate = getTodayDate()
) => moment(date).calendar(anchorDate);

export const getRelativeTimeText = (datetimeString: string) =>
  moment(datetimeString).fromNow();

export const getFormattedDateRange = (
  startDate?: moment.MomentInput,
  endDate?: moment.MomentInput,
  format?: string,
  emptyState = ""
) => {
  if (!startDate && !endDate) return emptyState;
  if (startDate && !endDate)
    return `Starts on ${formatDate(startDate, format)}`;
  if (!startDate && endDate) return `Due on ${formatDate(endDate, format)}`;
  return `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;
};

export const dateDiff = (
  date1: moment.MomentInput,
  date2: moment.MomentInput,
  absolute = false,
  inclusive = false
) => {
  date1 = moment(date1).format();
  date2 = moment(date2).format();
  let diff = moment(date2).diff(date1, "days");
  if (inclusive && diff >= 0) diff += 1;
  if (absolute) diff = Math.abs(diff);
  return diff;
};

export const getRelativeDueDate = (date: moment.MomentInput) => {
  if (!date) return "";
  const relativeDate = moment(date);
  const today = moment(getTodayDate());
  const dayDiff = dateDiff(today, relativeDate, true);
  const weekDiff = Math.abs(relativeDate.diff(today, "weeks"));

  if (dayDiff < 7) {
    return relativeDate.calendar(today);
  }

  if (weekDiff >= 2 && weekDiff < 4) {
    if (today.isBefore(relativeDate)) {
      return `in ${weekDiff} weeks`;
    }
    return `${weekDiff} weeks ago`;
  }

  return relativeDate.from(today);
};

export const getRelativeJobEstimatedEndDate = (
  estimatedEndDate: moment.MomentInput,
  jobStatusId: JobStatusesType
) => {
  if (estimatedEndDate) {
    const relativeDate = moment(estimatedEndDate);
    const today = moment().startOf("day");
    if (
      relativeDate.isBefore(today, "day") &&
      jobStatusId === JobStatuses.Complete
    ) {
      if (dateDiff(today, relativeDate) === 1) {
        return "1 day ago";
      }
      return `${dateDiff(today, relativeDate, true)} days ago`;
    }
    return getRelativeDueDate(estimatedEndDate);
  }
  return "No Due Date";
};

export const getAvailabilityDates = (
  estimatedStartDate: string | null,
  estimatedEndDate: string | null
) => {
  let startDate = getTodayDate();
  let endDate = addWeeks(getTodayDate(), 2);

  if (estimatedStartDate !== null) {
    startDate = estimatedStartDate;
    if (estimatedEndDate === null) {
      endDate = addWeeks(estimatedStartDate, 2);
    }
  }

  if (estimatedEndDate !== null) {
    endDate = estimatedEndDate;
    if (estimatedStartDate === null) {
      startDate = subWeeks(endDate, 2);
    }
  }

  return [startDate, endDate];
};

export function getTimeOfDayGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "morning";
  } else if (currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
}
