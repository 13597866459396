import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";

import { catchResponse } from "./catchResponse";
import { getRequestHeaders } from "./getRequestHeaders";

export type ApiConfig = Omit<AxiosRequestConfig<any>, "headers" | "params">;

export class APIController {
  rootUrl: string;

  getHeaders: () => RawAxiosRequestHeaders;

  constructor(
    rootUrl: string,
    getHeaders: () => RawAxiosRequestHeaders = getRequestHeaders
  ) {
    this.rootUrl = rootUrl;
    this.getHeaders = getHeaders;
  }

  setGetHeaders(getHeaders: () => RawAxiosRequestHeaders) {
    this.getHeaders = getHeaders;
  }

  makeURL(url: string) {
    return this.rootUrl + url;
  }

  async get<T>(endpoint: string, params?: any, config?: ApiConfig) {
    return catchResponse<T>(
      axios.get<T>(this.rootUrl + endpoint, {
        headers: this.getHeaders(),
        params,
        ...config,
      })
    );
  }

  async post<T>(
    endpoint: string,
    data?: any,
    params?: any,
    config?: ApiConfig
  ) {
    return catchResponse<T>(
      axios.post<T>(this.rootUrl + endpoint, data, {
        headers: this.getHeaders(),
        params,
        ...config,
      })
    );
  }

  async put<T>(endpoint: string, data: any, params?: any, config?: ApiConfig) {
    return catchResponse<T>(
      axios.put<T>(this.rootUrl + endpoint, data, {
        headers: this.getHeaders(),
        params,
        ...config,
      })
    );
  }

  async delete<T>(endpoint: string, params?: any, config?: ApiConfig) {
    return catchResponse<T>(
      axios.delete<T>(this.rootUrl + endpoint, {
        headers: this.getHeaders(),
        params,
        ...config,
      })
    );
  }
}
