import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { EMPTY_ARRAY } from "../../../lib/constants";
import { selectPropsJobItemId } from "../index";
import { selectStateJobItemUsers } from "./index";

export const selectUserIdsByJobItemId = createCachedSelector(
  [selectStateJobItemUsers, selectPropsJobItemId],
  (jobItemUsers, jobItemId) =>
    get(jobItemUsers, `userIdsByJobItemId.${jobItemId}`, EMPTY_ARRAY)
)(selectPropsJobItemId);

export const useUserIdsByJobItemId = jobItemId =>
  useSelector(state => selectUserIdsByJobItemId(state, { jobItemId }));
