import type {
  TBranch,
  TCurrency,
  THappinessResponse,
  TOrganisation,
  TRateCard,
  TRole,
  TSavedSegment,
  TSavedSegmentTemplate,
  TUser,
  TUserPreference,
} from "@streamtimefe/entities";
import { EntityClass } from "@streamtimefe/entities";
import type { ConstEnum } from "@streamtimefe/types";

export const EntityListKey = {
  [EntityClass.User]: "users",
  Currency: "currencies",
  [EntityClass.Role]: "roles",
  [EntityClass.Organisation]: "organisation",
  [EntityClass.UserPreference]: "userPreferences",
  [EntityClass.Branch]: "branches",
  [EntityClass.RateCard]: "rateCards",
  [EntityClass.HappinessResponse]: "happinessResponses",
  [EntityClass.SavedSegment]: "savedSegments",
  [EntityClass.SavedSegmentTemplate]: "savedSegmentTemplates",
} as const;

export type TEntityListKey = ConstEnum<typeof EntityListKey>;

export type TEntityList = {
  [EntityListKey.User]?: TUser[];
  [EntityListKey.Currency]?: TCurrency[];
  [EntityListKey.Role]?: TRole[];
  [EntityListKey.UserPreference]?: TUserPreference[];
  [EntityListKey.Organisation]?: TOrganisation[] | TOrganisation;
  [EntityListKey.Branch]?: TBranch[];
  [EntityListKey.RateCard]?: TRateCard[];
  [EntityListKey.HappinessResponse]?: THappinessResponse[];
  [EntityListKey.SavedSegment]?: TSavedSegment[];
  [EntityListKey.SavedSegmentTemplate]?: TSavedSegmentTemplate[];
};
