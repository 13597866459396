import styled from "styled-components";

import { JOB_TIMELINE_ITEM_ROW_HEIGHT } from "../../../lib/constants";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import Spacer from "../../elements/Spacer";
import { ScheduleGroupUnderline } from "./ScheduleGroupUnderline";

const JobGroupLoadingPulser = () => (
  <div>
    <Pulser>
      <Spacer w={30} />
      <ScheduleGroupUnderline flex>
        <PulserCell />
        <PulserCell />
      </ScheduleGroupUnderline>
    </Pulser>
  </div>
);

export default JobGroupLoadingPulser;

const Pulser = styled.div`
  height: ${JOB_TIMELINE_ITEM_ROW_HEIGHT}px;
  width: 100%;
  display: flex;
  cursor: pointer;
  .ScheduleGroupUnderline {
    border-bottom: none;
  }
`;

const PulserCell = styled.div`
  padding: 7px;
  height: 100%;
  &:before {
    content: "";
    display: block;
    animation: var(--animation-pulsate);
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-light);
  }

  &:first-child {
    padding-left: 0;
    width: 80%;
  }
  &:last-child {
    width: ${ScheduleUi.View.GroupTotalsWidth}px;
    flex-shrink: 0;
    flex-grow: 0;
    border-left: var(--border-thick-bright);
  }
`;
