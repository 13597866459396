import { timeAllocationMethodObj } from "st-shared/entities";
import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import { getTotalUsedMinutes } from "../../../lib/entities/jobItemEntity";
import { convertMinutesToTimeHM } from "../../../lib/time";
import { useJobCurrency } from "../../../redux/selectors/currency/jobCurrency";
import { useJobItem } from "../../../redux/selectors/jobItem";
import Spacer from "../../elements/Spacer";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import {
  GroupedJobItemUsers,
  useGroupedJobItemUsersDollars,
  useGroupedJobItemUsersHours,
} from "../hooks/useGroupedJobItemUsers";

type Props = {
  info: GroupedJobItemUsers;
};

export function JobItemUserGroupHeader({ info }: Props) {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const { canViewJobFinancials } = usePermissions();
  const jobItem = useJobItem(jobItemId);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const timeAllocationMethod = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  );

  const jobItemUsersHours = useGroupedJobItemUsersHours(info.jobItemUserIds);
  const jobItemUsersDollars = useGroupedJobItemUsersDollars(
    info.jobItemUserIds
  );

  const hoursWarning =
    timeAllocationMethod.isPeople() &&
    jobItemUsersHours.totalPlannedMinutes > 0 &&
    jobItemUsersHours.totalRemainingMinutes < 0;

  const hours = (() => {
    if (timeAllocationMethod.isItem()) {
      if (getTotalUsedMinutes(jobItem) > 0) {
        return convertMinutesToTimeHM(jobItemUsersHours.totalLoggedMinutes);
      }
    } else {
      return `${
        getTotalUsedMinutes(jobItem) > 0
          ? `${convertMinutesToTimeHM(jobItemUsersHours.totalLoggedMinutes)} / `
          : ""
      }${convertMinutesToTimeHM(jobItemUsersHours.totalPlannedMinutes)}`;
    }
    return "";
  })();

  const formattedTotalLoggedTimeExTax = formatForeignCurrency(
    customerCurrency,
    {
      value: jobItemUsersDollars.jobCurrencyTotalLoggedTimeExTax,
      hideLongSymbols: true,
      currency: jobCurrency,
    }
  );

  const formattedTotalPlannedTimeExTax = formatForeignCurrency(
    customerCurrency,
    {
      value: jobItemUsersDollars.jobCurrencyTotalPlannedTimeExTax,
      hideLongSymbols: true,
      currency: jobCurrency,
    }
  );

  const total = (() => {
    if (!timeAllocationMethod.isPeople()) {
      return `${
        getTotalUsedMinutes(jobItem) > 0
          ? `${formattedTotalLoggedTimeExTax} / `
          : ""
      }${formattedTotalPlannedTimeExTax}`;
    }
    return "";
  })();

  return (
    <Container>
      <Name>{info.roleName}</Name>
      <Hours style={hoursWarning ? { color: "var(--color-error)" } : undefined}>
        {hours}
      </Hours>
      {canViewJobFinancials && (
        <>
          <Spacer w={ItemColumnWidths.Rate}></Spacer>
          <Total>{total}</Total>
        </>
      )}
      <Spacer w={ItemColumnWidths.Status}></Spacer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  line-height: 32px;

  & > *:not(:first-child) {
    margin-left: 2px;
  }

  background-color: var(--color-gray-bright);
  color: var(--color-charcoal);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-nromal);
`;

const Name = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 33%;
  padding-left: 46px;
`;

const Hours = styled.div`
  width: ${ItemColumnWidths.Hours}px;
  text-align: right;
  padding-right: 15px;
`;

const Total = styled.div`
  width: ${ItemColumnWidths.Total}px;
  text-align: right;
  padding-right: 15px;

  @media (max-width: 1200px) {
    width: ${ItemColumnWidths.Total - ItemColumnWidths.TotalSmallScreenDiff}px;
  }
`;
