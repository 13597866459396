import { createIcon } from "../../icon/Icon";

export const IconMoneyDocument = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 2V8.58152C5.8807 8.32622 6.55136 8.14901 7.25 8.06189V4H14.25V10.5H19.75V19H15.6685C15.3747 19.7257 14.9776 20.3984 14.4954 21H21.75V8.66667L16.75 2H5.25ZM16.25 4.66667L19.125 8.5H16.25V4.66667Z"
    />
    <path d="M8.87791 19.7535H7.60814V19.1559C7.25465 19.0706 6.91744 18.8698 6.59651 18.5535C6.27558 18.2372 6.07326 17.8372 5.98953 17.3535L7.16163 16.8791C7.21744 17.2512 7.35493 17.5395 7.57409 17.7442C7.79335 17.9488 8.05381 18.0512 8.35549 18.0512C8.65726 18.0512 8.87791 17.9811 9.01744 17.841C9.15698 17.7008 9.22674 17.5377 9.22674 17.3517C9.22674 17.1648 9.14302 16.9998 8.97558 16.8566C8.80814 16.7134 8.57558 16.5953 8.27791 16.5023C7.55233 16.2605 7.02907 15.9721 6.70814 15.6372C6.38721 15.3023 6.22674 14.9044 6.22674 14.4433C6.22674 14.0725 6.34535 13.7415 6.58256 13.4506C6.81977 13.1595 7.16628 12.9552 7.62209 12.8376V12.2465H8.87791V12.8171C9.2407 12.9018 9.5407 13.0581 9.77791 13.286C10.0151 13.514 10.1942 13.8279 10.3151 14.2279L9.18488 14.7023C9.12907 14.4512 9.00228 14.2605 8.80451 14.1302C8.60665 14 8.37995 13.9349 8.12442 13.9349C7.95298 13.9349 7.80312 13.9767 7.67484 14.0605C7.54665 14.1442 7.48256 14.2431 7.48256 14.3573C7.48256 14.5564 7.5686 14.7265 7.7407 14.8675C7.91279 15.0086 8.27791 15.1814 8.83605 15.386C9.39419 15.5721 9.80581 15.8338 10.0709 16.1712C10.336 16.5086 10.4686 16.9034 10.4686 17.3556C10.4686 17.8379 10.3174 18.2395 10.0151 18.5605C9.71279 18.8814 9.33372 19.0884 8.87791 19.1814V19.7535Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.91307 21.5393C6.64646 21.8464 7.42544 22 8.25 22C9.08628 22 9.86749 21.8461 10.5936 21.5384C11.3199 21.2307 11.9572 20.802 12.5057 20.2525C13.0541 19.7029 13.482 19.0637 13.7891 18.335C14.0964 17.6062 14.25 16.8283 14.25 16.0011C14.25 15.1647 14.0965 14.3832 13.7894 13.6564C13.4823 12.9296 13.0547 12.292 12.5064 11.7436C11.958 11.1953 11.3206 10.7677 10.594 10.4606C9.86753 10.1535 9.08619 10 8.25 10C7.42656 10 6.64935 10.1536 5.91837 10.4609C5.18739 10.768 4.54712 11.1959 3.99753 11.7443C3.44795 12.2928 3.0193 12.9301 2.71158 13.6564C2.40386 14.3825 2.25 15.1637 2.25 16C2.25 16.8246 2.40358 17.6035 2.71074 18.3369C3.01791 19.0703 3.44507 19.7106 3.99223 20.2578C4.5394 20.8049 5.17967 21.2321 5.91307 21.5393ZM11.2397 18.9929C10.4234 19.8069 9.42577 20.214 8.24679 20.214C7.06781 20.214 6.07126 19.8059 5.25712 18.9897C4.44307 18.1734 4.03605 17.1758 4.03605 15.9968C4.03605 14.8178 4.44414 13.8213 5.26033 13.0071C6.0766 12.1931 7.07423 11.786 8.25321 11.786C9.43219 11.786 10.4287 12.1941 11.2429 13.0103C12.0569 13.8266 12.464 14.8242 12.464 16.0032C12.464 17.1822 12.0559 18.1787 11.2397 18.9929Z"
    />
  </>,
  "IconMoneyDocument"
);
