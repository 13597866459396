import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import type { ComponentPropsWithoutRef } from "react";
import { useMemo, useRef } from "react";
import { IconButton, IconSize, MdSave } from "st-shared/components";
import {
  CReportingSavedSegment,
  getSubFilterGroupTypes,
} from "st-shared/entities";
import { ReportingSearchType } from "st-shared/lib/webapi/reporting";

import { getOrderedMap } from "../../lib/orderedMap";
import { reportingExportCsv } from "../../lib/reportingExportCsv";
import type { SeriesDataValue } from "../../lib/VectorMatrixSeriesData";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentColumnsOrdered,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentFilterGroupType,
  useReportingSavedSegmentSearchType,
  useReportingSavedSegmentSelectedTimeId,
} from "../../state/stores/savedSegmentSelectors";
import {
  useReportingSelectedSeriesData,
  useReportingSeriesFetching,
  useReportingSeriesTotalsColumnVector,
  useReportingSeriesTotalsTimeMatrix,
} from "../../state/stores/searchSelectors";
import { loadingBlock, loadingColumns } from "./Table.css";
import { TableColumn } from "./TableColumn";
import {
  footerColumnContainer,
  footerRowContainer,
  footerStartColumnContainer,
} from "./TableFooter.css";

type Props = {
  style?: ComponentPropsWithoutRef<"div">["style"];
};

export function TableFooter({ style }: Props) {
  const isFetching = useReportingSeriesFetching();
  const searchType = useReportingSavedSegmentSearchType();
  const selectedSeriesData = useReportingSelectedSeriesData();

  const rowsLength = selectedSeriesData.length;

  return (
    <div className={footerRowContainer} style={style}>
      <div className={footerStartColumnContainer}>
        <span>GRAND TOTAL{rowsLength > 0 && ` (${rowsLength})`}</span>
        {!isFetching && rowsLength > 0 && <ExportCSVButton />}
      </div>
      {!isFetching && (
        <>
          {searchType === ReportingSearchType.ColumnSeries && <ColumnTotals />}
          {searchType === ReportingSearchType.TimeSeries && <TimeTotals />}
        </>
      )}
      {isFetching && <TotalsLoading />}
    </div>
  );
}

function ColumnTotals() {
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);
  const columnTotalVector = useReportingSeriesTotalsColumnVector();

  return (
    <>
      {orderedColumns.map((column) => {
        return (
          <TableColumn
            key={column.id}
            column={column}
            value={columnTotalVector[column.id]}
            className={footerColumnContainer}
            rowVectorValues={columnTotalVector}
          />
        );
      })}
      <div className={footerColumnContainer} />
    </>
  );
}

function TimeTotals() {
  const columns = useReportingSavedSegmentColumns();
  const selectedTimeId = useReportingSavedSegmentSelectedTimeId();
  const timeTotalsMatrix = useReportingSeriesTotalsTimeMatrix();

  const rowMartixValues = useMemo(() => {
    const matrix: Record<string, Record<string, SeriesDataValue>> = {};
    Object.keys(columns).forEach((columnKey) => {
      if (columnKey in timeTotalsMatrix) {
        Object.keys(timeTotalsMatrix[columnKey]).forEach((timeKey) => {
          if (!matrix[timeKey]) {
            matrix[timeKey] = {};
          }
          matrix[timeKey][columnKey] = timeTotalsMatrix[columnKey][timeKey];
        });
      }
    });
    return matrix;
  }, [columns, timeTotalsMatrix]);

  if (!selectedTimeId) return null;

  const selectedTotalsTime = timeTotalsMatrix[selectedTimeId];

  if (!selectedTotalsTime) return null;

  return (
    <>
      {Object.entries(selectedTotalsTime).map(([timeKey, value]) => {
        return (
          <TableColumn
            key={timeKey}
            column={columns[selectedTimeId]}
            value={
              CReportingSavedSegment.canTimeSeriesColumnShowTotal(
                columns[selectedTimeId]
              )
                ? value
                : null
            }
            className={footerColumnContainer}
            rowVectorValues={rowMartixValues[timeKey]}
          />
        );
      })}
    </>
  );
}

function TotalsLoading() {
  const searchType = useReportingSavedSegmentSearchType();
  const orderedColumns = useReportingSavedSegmentColumnsOrdered(true);

  const fakeColumns =
    searchType === ReportingSearchType.ColumnSeries
      ? orderedColumns
      : loadingColumns;

  return (
    <>
      {fakeColumns.map((_, i) => (
        <div key={i} className={footerColumnContainer}>
          <div className={loadingBlock} />
        </div>
      ))}
      {searchType === ReportingSearchType.ColumnSeries && (
        <div className={footerColumnContainer} />
      )}
    </>
  );
}

function ExportCSVButton() {
  const ref = useRef<HTMLButtonElement>(null);
  const showSecondaryOption = useShowSecondaryOption();

  function openExportCsvModal() {
    getModalBridgeClient()?.openModal(
      ModalTypes.ExportCsv,
      {
        showSecondaryOption,
      },
      {
        success(_, { isFormatted, isSecondaryGrouping }) {
          reportingExportCsv(isFormatted, isSecondaryGrouping);
        },
      }
    );
  }

  return (
    <IconButton
      text={"Export CSV"}
      iconProps={{ icon: MdSave, size: IconSize.OffSizing20 }}
      onClick={openExportCsvModal}
      ref={ref}
    />
  );
}

function useShowSecondaryOption() {
  const filterGroupType = useReportingSavedSegmentFilterGroupType();
  const dataSets = useReportingSavedSegmentDataSets();
  const orderedDataSets = getOrderedMap(dataSets);
  const subFilterGroupTypes = getSubFilterGroupTypes(
    orderedDataSets,
    filterGroupType
  );

  return subFilterGroupTypes.length > 0;
}
