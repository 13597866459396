import { ReactNode } from "react";
import styled from "styled-components";

import {
  ScheduleRow,
  useJobScheduleRows,
  useUserScheduleRows,
} from "../../../state/ui/schedule/selectors/useScheduleRows";
import { ScheduleUi } from "../../../state/ui/schedule/types";

type RenderFunction = (id: number, top: number) => ReactNode;

interface RenderRowsProps {
  rows: ScheduleRow[];
  renderRow: RenderFunction;
}

export function RenderRows({ rows, renderRow }: RenderRowsProps) {
  return (
    <>
      {rows.map(({ id, height, top }) => (
        <RowCell key={id} style={{ height, top }}>
          {renderRow(id, top)}
        </RowCell>
      ))}
    </>
  );
}

interface RowConsumerProps {
  children: RenderFunction;
}

export function UserRowsConsumer({ children }: RowConsumerProps) {
  const rows = useUserScheduleRows();
  return <RenderRows rows={rows} renderRow={children} />;
}

export function JobRowsConsumer({ children }: RowConsumerProps) {
  const rows = useJobScheduleRows();
  return <RenderRows rows={rows} renderRow={children} />;
}

export function JobGroupSearchingPulser() {
  return (
    <RowCell style={{ height: ScheduleUi.Job.RowHeight + 2, top: 0 }}>
      <div style={{ padding: "10px 10px 10px 30px" }}>
        <div
          style={{
            width: "80%",
            height: 18,
            animation: "var(--animation-pulsate)",
            backgroundColor: "var(--color-gray-bright)",
            marginTop: 1,
            marginBottom: 4,
          }}
        />
        <div
          style={{
            width: "30%",
            height: 18,
            animation: "var(--animation-pulsate)",
            backgroundColor: "var(--color-gray-bright)",
          }}
        />
      </div>
    </RowCell>
  );
}

export function JobSearchingPulser() {
  return (
    <RowCell style={{ height: ScheduleUi.Job.RowHeight + 2, top: 0 }}>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            left: -100,
            top: 10,
            width: 900,
            height: 20,
            borderRadius: 10,
            animation: "var(--animation-pulsate)",
            backgroundColor: "var(--color-gray)",
          }}
        />
      </div>
    </RowCell>
  );
}

const RowCell = styled.div`
  width: 100%;
  border-bottom: var(--border-thick-bright);
  position: absolute;
`;
