import reduceChangedEntities from "../reduceChangedEntities";

const undoClearRepeatingLoggedTimeIdReducer = (state, action) => {
  const { baseScheduleLoggedTime } = action.payload;
  const prevBaseScheduleLoggedTime = state.byId[baseScheduleLoggedTime.id];
  const prevScheduleLoggedTimeIds =
    state.idsByBlockKey[baseScheduleLoggedTime.id];

  if (!prevBaseScheduleLoggedTime) return state;

  const { repeatingLoggedTimeId } = baseScheduleLoggedTime;

  const changedEntities = prevScheduleLoggedTimeIds
    .map(id => state.byId[id])
    .map(entity => ({
      prevEntity: entity,
      newEntity: {
        ...entity,
        repeatingLoggedTimeId
      }
    }));

  return reduceChangedEntities(state, changedEntities);
};

export default undoClearRepeatingLoggedTimeIdReducer;
