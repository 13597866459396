import { DateRangeSelector } from "st-shared/module";
import type { TCustomDateRange, TDateRange } from "st-shared/types";

import { reportingStore } from "../../state/stores/reportingStore";
import {
  useReportingSavedSegmentColumnTypeDataOnly,
  useReportingSavedSegmentDates,
} from "../../state/stores/savedSegmentSelectors";
import { dateSelectorCss } from "./DateSelector.css";

export function DateSelector() {
  const dates = useReportingSavedSegmentDates();
  const dataColumns = useReportingSavedSegmentColumnTypeDataOnly();

  if (dataColumns.length === 0) return null;

  function setRange(dateRange: TCustomDateRange | TDateRange | null) {
    reportingStore().savedSegment.actions.setDates(dateRange);
  }

  return (
    <DateRangeSelector
      range={dates}
      setRange={setRange}
      buttonClassName={dateSelectorCss}
      includeAllTime={true}
    />
  );
}
