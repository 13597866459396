import type { Dictionary } from "@streamtimefe/types";
import { groupBy } from "lodash-es";
import { useCallback, useMemo } from "react";
import type { ReportingColumn } from "st-shared/entities";
import {
  CReportingSavedSegment,
  ReportingColumnType,
} from "st-shared/entities";
import { ConditionMatchType } from "st-shared/types";

import { getOrderedMap } from "../../lib/orderedMap";
import type { TTimeSeriesFormats } from "../../ui/charts/time/types";
import { TimeSeriesFormats } from "../../ui/charts/time/types";
import { useReportingStore } from "./reportingStore";
import {
  useReportingSeriesCalculatedColumn,
  useReportingSeriesCalculatedTime,
} from "./searchSelectors";

export function useReportingSavedSegmentEntityId() {
  return useReportingStore((s) => s.savedSegment.entityId);
}

export function useReportingSavedSegmentValue() {
  return useReportingStore((s) => s.savedSegment.value);
}

export function useReportingSavedSegmentName() {
  return useReportingStore((s) => s.savedSegment.name);
}

export function useReportingSavedSegmentFetching() {
  return useReportingStore((s) => s.savedSegment.fetching);
}

export function useReportingSavedSegmentColumns() {
  return useReportingStore((s) => s.savedSegment.value.columns);
}

export function useReportingSavedSegmentColumn(id: string) {
  return useReportingStore((s) => s.savedSegment.value.columns[id]);
}

export function useReportingSavedSegmentColumnMetadata() {
  return useReportingStore((s) => s.savedSegment.columnMetadata);
}

export function useReportingSavedSegmentColumnMetadataById(id: string) {
  return useReportingStore((s) => s.savedSegment.columnMetadata[id]);
}

export function useReportingSavedSegmentDataSets() {
  return useReportingStore((s) => s.savedSegment.value.dataSets);
}

export function useReportingSavedSegmentDataSet(id: string) {
  return useReportingStore((s) => s.savedSegment.value.dataSets[id]);
}

export function useReportingSavedSegmentDates() {
  return useReportingStore((s) => s.savedSegment.value.dates);
}

export function useReportingSavedSegmentSearchType() {
  return useReportingStore((s) => s.savedSegment.value.searchType);
}

export function useReportingSavedSegmentTimeChartType() {
  return useReportingStore((s) => s.savedSegment.value.timeChartType);
}

export function useReportingSavedSegmentFilterGroupType() {
  return useReportingStore((s) => s.savedSegment.value.filterGroupType);
}

export function useReportingSavedSegmentSubFilterGroupType() {
  return useReportingStore((s) => s.savedSegment.value.subFilterGroupType);
}

export function useReportingSavedSegmentColumnsOrdered(
  filterOutHidden: boolean = false
) {
  return useReportingStore((s) => {
    let columns = getOrderedMap(s.savedSegment.value.columns);

    if (filterOutHidden) {
      columns = columns.filter((column) => !column.hidden);
    }

    return columns;
  });
}

export function useReportingSavedSegmentColumnTypeDataOnly() {
  const columns = useReportingSavedSegmentColumnsOrdered();
  return columns.filter((column) => column.type === ReportingColumnType.Data);
}

export function useReportingSavedSegmentDatasetColumns(dataSetId: string) {
  const orderedColumns = useReportingSavedSegmentColumnsOrdered();
  return CReportingSavedSegment.getDataColumns(orderedColumns).filter(
    (column) => column.dataSetId === dataSetId
  );
}

export function useReportingSavedSegmentColumnsAllowed() {
  const allowFilter = useAllowColumnSeries();
  return useReportingStore((s) =>
    getOrderedMap(s.savedSegment.value.columns).filter((column) =>
      allowFilter(column.id)
    )
  );
}

export function useReportingSavedSegmentColumnsPermission() {
  const allowFilter = usePermissionsColumnSeries();
  return useReportingStore((s) =>
    getOrderedMap(s.savedSegment.value.columns).filter((column) =>
      allowFilter(column.id)
    )
  );
}

export function useReportingSavedSegmentColumnsAllNoPermission() {
  const columnsPermission = useReportingSavedSegmentColumnsPermission();
  const orderColumns = useReportingSavedSegmentColumnsOrdered();

  return columnsPermission.length === 0 && orderColumns.length > 0;
}

export function useReportingSavedSegmentColumnsAllowedTimeTable() {
  const allowFilter = useAllowTimeSeriesTableFilter();
  return useReportingStore((s) =>
    getOrderedMap(s.savedSegment.value.columns).filter((column) =>
      allowFilter(column.id)
    )
  );
}

export function useReportingSavedSegmentSelectedColumnId() {
  const selectedColumnId = useReportingStore(
    (s) => s.savedSegment.value.selectedColumnId
  );
  const columns = useReportingSavedSegmentColumnsAllowed();

  const selectedColumn = columns.find(
    (column) => column.id === selectedColumnId
  );

  if (selectedColumn) {
    return selectedColumn.id;
  }

  if (columns.length > 0) {
    return columns[0].id;
  }

  return null;
}

export function useReportingSavedSegmentSelectedTimeId() {
  const selectedTimeId = useReportingStore(
    (s) => s.savedSegment.value.selectedTimeId
  );
  const timeTableColumns = useReportingSavedSegmentColumnsAllowedTimeTable();

  const selectedColumn = timeTableColumns.find(
    (column) => column.id === selectedTimeId
  );

  if (selectedColumn) {
    return selectedColumn.id;
  }

  if (timeTableColumns.length > 0) {
    return timeTableColumns[0].id;
  }

  return null;
}

function useAllowTimeSeriesChartFilter() {
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  const columns = useReportingSavedSegmentColumns();
  return useCallback(
    (id: string) => {
      const metadata = columnMetadata[id];
      const column = columns[id];
      return (
        metadata &&
        metadata.show &&
        TimeSeriesFormats.includes(metadata.format as TTimeSeriesFormats) &&
        CReportingSavedSegment.canTimeSeriesColumnShowTotal(column)
      );
    },
    [columnMetadata]
  );
}

function useAllowTimeSeriesTableFilter() {
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  return useCallback(
    (id: string) => {
      const metadata = columnMetadata[id];
      return (
        metadata &&
        metadata.show &&
        metadata.baseType !== ReportingColumnType.Total
      );
    },
    [columnMetadata]
  );
}

function useAllowColumnSeries() {
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  return useCallback(
    (id: string) => {
      const metadata = columnMetadata[id];
      return metadata && metadata.show;
    },
    [columnMetadata]
  );
}

function usePermissionsColumnSeries() {
  const columnMetadata = useReportingSavedSegmentColumnMetadata();
  return useCallback(
    (id: string) => {
      const metadata = columnMetadata[id];
      return metadata && metadata.permission;
    },
    [columnMetadata]
  );
}

export function useReportingSavedSegmentColumnsTimeSeries() {
  const orderedColumns = useReportingSavedSegmentColumnsOrdered();

  const allowFilter = useAllowTimeSeriesChartFilter();

  const filteredColumns = useMemo(
    () => orderedColumns.filter((column) => allowFilter(column.id)),
    [orderedColumns, allowFilter]
  );
  const groupedColumns: Dictionary<TTimeSeriesFormats, ReportingColumn[]> =
    useMemo(
      () =>
        groupBy(filteredColumns, (column) =>
          CReportingSavedSegment.getColumnFormat(column)
        ),
      [filteredColumns]
    );

  return { filteredColumns, groupedColumns };
}

export function useReportingSavedSegmentSelectedTimeIds() {
  const allowFilter = useAllowTimeSeriesChartFilter();
  return useReportingStore((s) => {
    let ids = s.savedSegment.value.selectedTimeIds.filter(allowFilter);

    if (ids.length === 0) {
      ids = getOrderedMap(s.savedSegment.value.columns)
        .map((column) => column.id)
        .filter(allowFilter);
    }
    return ids;
  });
}

export function useReportingSavedSegmentSortColumn() {
  const sortColumn = useReportingStore((s) => s.savedSegment.value.sortColumn);
  const seriesData = useReportingSeriesCalculatedColumn();
  if (
    seriesData.length > 0 &&
    seriesData[0].vector &&
    sortColumn.id &&
    sortColumn.id in seriesData[0].vector
  ) {
    return sortColumn;
  }
  return sortColumn;
}

export function useReportingSavedSegmentSortTime() {
  const sortTime = useReportingStore((s) => s.savedSegment.value.sortTime);
  const seriesData = useReportingSeriesCalculatedTime();
  if (
    seriesData.length > 0 &&
    seriesData[0].matrix &&
    sortTime.id &&
    sortTime.id in seriesData[0].matrix
  ) {
    return sortTime;
  }
  return { id: null, order: sortTime.order };
}

export function useReportingSavedSegmentGlobalFilters() {
  return useReportingStore(
    (s) => s.savedSegment.value.globalFilters?.filterGroups || []
  );
}

export function useReportingSavedSegmentGlobalFiltersConditionMatchType() {
  return useReportingStore(
    (s) =>
      s.savedSegment.value.globalFilters?.conditionMatchTypeId ||
      ConditionMatchType.And
  );
}
