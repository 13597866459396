import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_QUOTES } from "../../../lib/constants";
import { selectPropsQuoteId } from "../index";

export const selectStateQuotes = state => state.entities[ENTITY_NAME_QUOTES];

export const selectQuote = createCachedSelector(
  [selectStateQuotes, selectPropsQuoteId],
  (quotes, quoteId) => get(quotes, `byId.${quoteId}`)
)(selectPropsQuoteId);

export const useQuote = quoteId =>
  useSelector(state => selectQuote(state, { quoteId }));
