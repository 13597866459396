import { useEffect } from "react";
import type { TEntityId } from "st-shared/entities/Entity";
import { WebAPI } from "st-shared/lib";

import { hotkeyEmitter, Hotkeys } from "../../lib/WebAppAPI/fePages/hotkey";
import {
  jobPageCreateExpense,
  jobPageCreateInvoice,
  jobPageCreateQuote,
  jobPageOpenInvoicesModal,
  jobPageOpenLoggedExpensesModal,
  jobPageOpenLoggedItemsModal,
  jobPageOpenQuotesModal,
} from "../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../redux/selectors/job";
import { useCreateNewJobItem } from "./hooks/useCreateNewJobItem";
import { useCreateNewJobPhase } from "./hooks/useCreateNewJobPhase";
import { useGoToTimeline } from "./hooks/useGoToTimelime";

export function useJobPageHotkeys(jobId: TEntityId) {
  const job = useJob(jobId);
  const createJobItem = useCreateNewJobItem(jobId);
  const createJobPhase = useCreateNewJobPhase(jobId);
  const goToTimeline = useGoToTimeline();

  useEffect(() => {
    function createItem() {
      createJobItem();
    }

    function createPhase() {
      createJobPhase();
    }

    function createExpense() {
      jobPageCreateExpense(job);
    }

    function createJCR() {
      WebAPI.Jobs.openDownloadJobCostReportLink(jobId);
    }

    function openQuoteWizard() {
      jobPageCreateQuote(job);
    }

    function openInvoiceWizard() {
      jobPageCreateInvoice(job);
    }

    function hotkeyTimeline() {
      goToTimeline();
    }

    function openExpenses() {
      jobPageOpenLoggedExpensesModal(job);
    }

    function openTotalUsed() {
      jobPageOpenLoggedItemsModal(job);
    }

    function openInvoices() {
      jobPageOpenInvoicesModal(job);
    }

    function openQuotes() {
      jobPageOpenQuotesModal(job);
    }

    hotkeyEmitter.on(Hotkeys.New, createItem);
    hotkeyEmitter.on(Hotkeys.P, createPhase);
    hotkeyEmitter.on(Hotkeys.Edit, createExpense);
    hotkeyEmitter.on(Hotkeys.R, createJCR);
    hotkeyEmitter.on(Hotkeys.Q, openQuoteWizard);
    hotkeyEmitter.on(Hotkeys.I, openInvoiceWizard);
    hotkeyEmitter.on(Hotkeys.ShiftT, hotkeyTimeline);
    hotkeyEmitter.on(Hotkeys.ShiftE, openExpenses);
    hotkeyEmitter.on(Hotkeys.ShiftU, openTotalUsed);
    hotkeyEmitter.on(Hotkeys.ShiftI, openInvoices);
    hotkeyEmitter.on(Hotkeys.ShiftQ, openQuotes);

    function removeListeners() {
      hotkeyEmitter.off(Hotkeys.New, createItem);
      hotkeyEmitter.off(Hotkeys.P, createPhase);
      hotkeyEmitter.off(Hotkeys.Edit, createExpense);
      hotkeyEmitter.off(Hotkeys.R, createJCR);
      hotkeyEmitter.off(Hotkeys.Q, openQuoteWizard);
      hotkeyEmitter.off(Hotkeys.I, openInvoiceWizard);
      hotkeyEmitter.off(Hotkeys.ShiftT, hotkeyTimeline);
      hotkeyEmitter.off(Hotkeys.ShiftE, openExpenses);
      hotkeyEmitter.off(Hotkeys.ShiftU, openTotalUsed);
      hotkeyEmitter.off(Hotkeys.ShiftI, openInvoices);
      hotkeyEmitter.off(Hotkeys.ShiftQ, openQuotes);
    }

    function unload() {
      removeListeners();
    }

    window.addEventListener("unload", unload);

    return () => {
      removeListeners();
      window.removeEventListener("unload", unload);
    };
  }, [jobId, job]);
}
