import * as PropTypes from "prop-types";

import {
  activityEntryEntityType,
  getMetaDataLoggedExpenseName,
} from "../../../../../lib/entities/activityEntryEntity";
import LinkToActivityEntryEntity from "./LinkToActivityEntryEntity.jsx";
import SystemMessage from "./SystemMessage";

const JobLoggedExpenseDeclined = ({
  activityEntry,

  canEditJobs,
}) => (
  <SystemMessage>
    <LinkToActivityEntryEntity
      activityEntry={activityEntry}
      disabled={!canEditJobs}
    >
      {getMetaDataLoggedExpenseName(activityEntry)}
    </LinkToActivityEntryEntity>
    {" has been declined"}
  </SystemMessage>
);

JobLoggedExpenseDeclined.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  canEditJobs: PropTypes.bool.isRequired,
};

export default JobLoggedExpenseDeclined;
