import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { selectPropsJobId } from "../index";
import { selectLoggedExpenseIdsByJobId } from "./loggedExpenseIdsByJobId";

export const selectHasLoggedExpenses = createCachedSelector(
  [selectLoggedExpenseIdsByJobId],
  loggedExpenseIds => loggedExpenseIds.length > 0
)(selectPropsJobId);

export const useHasLoggedExpenses = jobId =>
  useSelector(state => selectHasLoggedExpenses(state, { jobId }));
