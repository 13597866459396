import isError from "lodash-es/isError";
import { takeLatest, call } from "redux-saga/effects";
import { openRepeatingLoggedTimeModal } from "../../../lib/WebAppAPI/repeatingLoggedTimes";
import { REPEATING_LOGGED_TIME_OPEN_EDIT } from "../../../lib/constants";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* editRepeatingLoggedTime(action) {
  try {
    yield call(openRepeatingLoggedTimeModal, action.payload);
  } catch (error) {
    if (isError(error)) sagaError(error);
  }
}

export default function* watchEditRepeatingLoggedTime() {
  yield takeLatest([REPEATING_LOGGED_TIME_OPEN_EDIT], editRepeatingLoggedTime);
}
