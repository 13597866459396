import { get, isEmpty } from "lodash-es";
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  JOB_ITEM_DELETE,
  JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR,
  JOB_ITEM_DEPENDANCY_CREATE_SAVED,
  JOB_ITEM_DEPENDANCY_DELETE,
  JOB_ITEM_DEPENDANCY_DELETE_ERROR,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVE_ERROR
} from "../../../../lib/constants";
import {
  getChildJobItemId,
  getJobId,
  getParentJobItemId
} from "../../../../lib/entities/jobItemDependancyEntity";
import { getId } from "../../../../lib/objects";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";

const byJobIdReducer = createEntityIndexedArrayReducer(entity =>
  getJobId(entity)
);

const byParentJobItemIdReducer = createEntityIndexedArrayReducer(entity =>
  getParentJobItemId(entity)
);

const byChildJobItemIdReducer = createEntityIndexedArrayReducer(entity =>
  getChildJobItemId(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    byJobId: byJobIdReducer(state.byJobId, changedEntities),
    byParentJobItemId: byParentJobItemIdReducer(
      state.byParentJobItemId,
      changedEntities
    ),
    byChildJobItemId: byChildJobItemIdReducer(
      state.byChildJobItemId,
      changedEntities
    )
  };
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEM_DEPENDANCIES]
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_ITEM_DEPENDANCIES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const savedCreateJobItemDependancyReducer = (state, action) => {
  const { id, jobItemDependancies } = action.payload;
  const prevEntity = state.byId[id];

  const changedEntities = [{ prevEntity }].concat(
    parseEntityPayload(state, jobItemDependancies)
  );

  return reduceChangedEntities(state, changedEntities);
};

const undoCreateJobItemDependancyReducer = (state, action) => {
  const { id } = action.payload;
  const prevEntity = get(state, `byId.${id}`);

  return prevEntity ? reduceChangedEntities(state, [{ prevEntity }]) : state;
};

const deleteJobItemDependencyReducer = (state, action) => {
  const jobItemDependancy = action.payload;

  return removeEntitiesReducer(state, {
    payload: {
      entityName: ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
      ids: [jobItemDependancy.id]
    }
  });
};

const undoDeleteJobItemDependencyReducer = (state, action) => {
  const { jobItemDependancy } = action.payload;

  return receiveEntitiesReducer(state, {
    payload: {
      ENTITY_NAME_JOB_ITEM_DEPENDANCIES: [jobItemDependancy]
    }
  });
};

const editJobItemReducer = (state, action) => {
  const {
    deletedJobItemDependancies = [],
    modifiedJobItemDependancies = []
  } = action.payload;

  const changedEntities = parseRemovedEntities(
    state,
    deletedJobItemDependancies.map(getId)
  ).concat(parseEntityPayload(state, modifiedJobItemDependancies));

  return reduceChangedEntities(state, changedEntities);
};

const saveJobItemErrorReducer = (state, action) => {
  const { sagaType, prevJobItemDependancies = [] } = action.payload;

  if (sagaType === JOB_ITEM_SAVE_EDIT)
    return receiveEntitiesReducer(state, {
      payload: {
        ENTITY_NAME_JOB_ITEM_DEPENDANCIES: prevJobItemDependancies
      }
    });

  return state;
};

const deleteJobItemReducer = (state, action) => {
  const { jobItemDependancies = [] } = action.payload;

  if (jobItemDependancies.length > 0) {
    return removeEntitiesReducer(state, {
      payload: {
        entityName: ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
        ids: jobItemDependancies.map(getId)
      }
    });
  }
  return state;
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_ITEM_DEPENDANCY_CREATE_SAVED]: savedCreateJobItemDependancyReducer,
    [JOB_ITEM_DEPENDANCY_CREATE_SAVE_ERROR]: undoCreateJobItemDependancyReducer,
    [JOB_ITEM_DEPENDANCY_DELETE]: deleteJobItemDependencyReducer,
    [JOB_ITEM_DEPENDANCY_DELETE_ERROR]: undoDeleteJobItemDependencyReducer,
    [JOB_ITEM_SAVE_EDIT]: editJobItemReducer,
    [JOB_ITEM_SAVE_ERROR]: saveJobItemErrorReducer,
    [JOB_ITEM_DELETE]: deleteJobItemReducer
  }
);
