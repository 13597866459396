import { Entity } from "@streamtimefe/entities";
import {
  useIsJobEditable,
  useJobItemActions,
  useJobItemPlanActions,
} from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { useMutation } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type TJobItemSaveContext = {
  saveJobItem: () => void;
  isJobItemSaving: boolean;
};

export const JobItemSaveContext = createContext<TJobItemSaveContext>(
  undefined as unknown as TJobItemSaveContext
);

export function useJobItemSave() {
  const context = useContext(JobItemSaveContext);
  if (!context) {
    throw new Error(`Missing JobItemSaveContext`);
  }
  return context;
}

export function JobItemSaveProvider({ children }: PropsWithChildren) {
  const { getJobItem, unsetModifiedJobItem } = useJobItemActions();
  const {
    getModifiedJobItemUsers,
    getModifiedJobItemRoles,
    getModifiedJobItemSubItems,
    unsetModifiedEntities,
    getRemoveJobItemDependancyIds,
    getRemoveOverdueIncompleteTodos,
  } = useJobItemPlanActions();

  const isJobEditable = useIsJobEditable();

  const [queueUpdate, setQueueUpdate] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState(false);

  function onSuccess() {
    if (!queueUpdate) {
      unsetModifiedJobItem();
      unsetModifiedEntities();
    } else {
      setQueueUpdate(false);
      setTimeout(() => {
        setSaveTimeout(true);
      }, 50);
    }
  }

  const { mutate: mutateCreate, isPending: isCreatePending } = useMutation({
    mutationFn: webapi.jobs.createJobItem,
    onSuccess,
  });

  const { mutate: mutateUpdate, isPending: isUpdatePending } = useMutation({
    mutationFn: webapi.jobItems.update,
    onSuccess,
  });

  const save = useCallback(() => {
    const jobItem = getJobItem();
    if (!jobItem) return;
    if (!isJobEditable) return;

    if (isCreatePending || isUpdatePending) {
      setQueueUpdate(true);
      return;
    }

    if (Entity.isNew(jobItem)) {
      mutateCreate({
        jobItem,
        jobItemUsers: getModifiedJobItemUsers(),
        jobItemRoles: getModifiedJobItemRoles(),
        jobItemSubItems: getModifiedJobItemSubItems(),
      });
    } else {
      mutateUpdate({
        jobItem,
        removeJobItemDependancyIds: getRemoveJobItemDependancyIds(),
        removeOverdueIncompleteTodos: getRemoveOverdueIncompleteTodos(),
        updatedJobItemUsers: getModifiedJobItemUsers(),
        updatedJobItemRoles: getModifiedJobItemRoles(),
        updatedJobItemSubItems: getModifiedJobItemSubItems(),
      });
    }
  }, [
    getJobItem,
    getModifiedJobItemRoles,
    getModifiedJobItemSubItems,
    getModifiedJobItemUsers,
    getRemoveJobItemDependancyIds,
    getRemoveOverdueIncompleteTodos,
    isCreatePending,
    isJobEditable,
    isUpdatePending,
    mutateCreate,
    mutateUpdate,
  ]);

  useEffect(() => {
    if (saveTimeout) {
      setSaveTimeout(false);
      save();
    }
  }, [save, saveTimeout]);

  const context = useMemo(() => {
    return {
      saveJobItem: save,
      isJobItemSaving: isCreatePending || isUpdatePending,
    };
  }, [isCreatePending, isUpdatePending, save]);

  return (
    <JobItemSaveContext.Provider value={context}>
      {children}
    </JobItemSaveContext.Provider>
  );
}
