import { intersection } from "lodash-es";
import { useSelector } from "react-redux";

import { selectJobItemIdsByJobId } from "../jobItemSelectors";
import { selectUserIdsByJobItemId } from "../jobItemUser/selectUserIdsByJobItemId";

export function selectUserIdsInAllJobItemsByJobId(state, { jobId }) {
  const jobItemIds = selectJobItemIdsByJobId(state, { jobId });
  const userIdsByJobItemId = jobItemIds.map((jobItemId) =>
    selectUserIdsByJobItemId(state, { jobItemId })
  );
  return intersection(...userIdsByJobItemId);
}

export const useUserIdsInAllJobItemsByJobId = (jobId) =>
  useSelector((state) => selectUserIdsInAllJobItemsByJobId(state, { jobId }));
