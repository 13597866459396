import type { ConstEnum } from "@streamtimefe/types";

export const ValueFormatEnum = {
  Value: 1,
  Number: 2,
  Days: 3,
  Currency: 4,
  Minutes: 5,
  Percentage: 6,
  Happiness: 7,
} as const;

export type TValueFormatEnum = ConstEnum<typeof ValueFormatEnum>;
