import { isEqual } from "lodash-es";
import { call, put, select } from "redux-saga/effects";
import { savePurchaseOrderLineItemAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  PURCHASE_ORDER_LINE_ITEM_SAVE,
  PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SAVED,
  PURCHASE_ORDER_LINE_ITEM_SAVING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectPurchaseOrderLineItemUi } from "../../selectors/purchaseOrderLineItemSelectors";

function* save(action) {
  try {
    const {
      id,
      purchaseOrderLineItem,
      prevPurchaseOrderLineItem
    } = action.payload;

    if (isEqual(purchaseOrderLineItem, prevPurchaseOrderLineItem)) return;

    yield put(createAction(PURCHASE_ORDER_LINE_ITEM_SAVING, { id }));

    const data = yield call(
      savePurchaseOrderLineItemAPI,
      purchaseOrderLineItem
    );

    const {
      isDeleting,
      isEditing
    } = yield select(selectPurchaseOrderLineItemUi, { id });

    if (!isDeleting && !isEditing)
      yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(createAction(PURCHASE_ORDER_LINE_ITEM_SAVED, { id }));

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SAVE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchPurchaseOrderLineItemSave() {
  yield takeLatestBy(
    PURCHASE_ORDER_LINE_ITEM_SAVE,
    save,
    action => action.payload.id
  );
}
