import { storeActionEmit } from "st-shared/stores";

import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from "../../lib/constants";
import { EntityAction } from "./actions";

// This is a hack to add the stores to the entity system before actual implementation
export function entityStoreListenerReducers(
  state: object = {},
  action: EntityAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      storeActionEmit("entitiesReceived", {
        entityData: action.payload,
      });
      break;
    case ENTITIES_REMOVED:
      storeActionEmit("entitiesRemoved", action.payload);
      break;
  }
  return state;
}
