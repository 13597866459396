import { ThemeProvider } from "@material-ui/core";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getTodayDate, toDate } from "../../../lib/dates";
import pickersTheme from "../../../lib/themes/pickers";
import Flex from "../../elements/Flex";
import Popover from "../Popover";

export const SELECTED_VALUE = {
  START: "START",
  END: "END"
};

class PopoverDateRangePicker extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    startValue: PropTypes.string,
    endValue: PropTypes.string,
    startTitle: PropTypes.string,
    endTitle: PropTypes.string,
    popoverProps: PropTypes.shape({
      open: PropTypes.bool.isRequired
    }).isRequired
  };

  static defaultProps = {
    startValue: "",
    endValue: "",
    startTitle: "Start",
    endTitle: "End"
  };

  onChangeStart = moment => {
    const { endValue, onChange } = this.props;
    const selectedStartDate = toDate(moment);

    onChange({
      selectedStartDate,
      selectedEndDate: moment.isAfter(endValue) ? selectedStartDate : endValue,
      selectedValue: SELECTED_VALUE.START
    });
  };

  onChangeEnd = moment => {
    const { startValue, onChange } = this.props;
    const selectedEndDate = toDate(moment);

    onChange({
      selectedStartDate: startValue,
      selectedEndDate,
      selectedValue: SELECTED_VALUE.END
    });
  };

  render() {
    const {
      popoverProps,
      startValue,
      endValue,
      startTitle,
      endTitle
    } = this.props;
    return (
      <Popover {...popoverProps}>
        <ThemeProvider theme={pickersTheme}>
          <Flex>
            <div>
              <Title>{startTitle}</Title>
              <MuiDatePicker
                disableToolbar
                variant="static"
                onChange={this.onChangeStart}
                value={startValue}
                minDate={getTodayDate()}
                leftArrowButtonProps={{ size: "small" }}
                rightArrowButtonProps={{ size: "small" }}
              />
            </div>
            <Separator />
            <div>
              <Title>{endTitle}</Title>
              <MuiDatePicker
                disableToolbar
                variant="static"
                onChange={this.onChangeEnd}
                value={endValue}
                minDate={startValue}
                leftArrowButtonProps={{ size: "small" }}
                rightArrowButtonProps={{ size: "small" }}
              />
            </div>
          </Flex>
        </ThemeProvider>
      </Popover>
    );
  }
}

export default PopoverDateRangePicker;

const Title = styled.div`
  width: 100%;
  height: 30px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--color-blue-active);
`;

const Separator = styled.div`
  width: 10px;
  border: 1px solid var(--color-jonsnow);
  margin-right: -1px;
`;
