import { get, isObjectLike } from "lodash-es";
import { createSelector } from "reselect";
import {
  EMPTY_ARRAY,
  ENTITY_NAME_JOB_ITEM_DEPENDANCIES,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END,
  JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START
} from "../../lib/constants";
import {
  calculateDependancyLagDays,
  getChildJobItemId,
  getDependancyTypeId,
  getIsFloat,
  getLagDays,
  getParentJobItemId
} from "../../lib/entities/jobItemDependancyEntity";
import { selectJobItemById } from "./jobItemSelectors";
import {
  selectPropsId,
  selectPropsJobId,
  selectPropsJobItemId,
  selectPropsJobPhaseId
} from "./index";

export const selectJobItemDependancies = state =>
  state.entities[ENTITY_NAME_JOB_ITEM_DEPENDANCIES];

export const selectJobItemDependancyById = createSelector(
  selectJobItemDependancies,
  selectPropsId,
  (jobItemDependancies, id) => get(jobItemDependancies, `byId.${id}`)
);

export const selectJobItemDependancyIdsByJobId = createSelector(
  selectJobItemDependancies,
  selectPropsJobId,
  (jobItemDependancies, jobId) =>
    get(jobItemDependancies, `byJobId.${jobId}`, EMPTY_ARRAY)
);

export const selectJobItemDependancyIdsByParentJobItemId = createSelector(
  selectJobItemDependancies,
  selectPropsJobItemId,
  (jobItemDependancies, id) =>
    get(jobItemDependancies, `byParentJobItemId.${id}`, EMPTY_ARRAY)
);

export const selectJobItemDependancyIdsByChildJobItemId = createSelector(
  selectJobItemDependancies,
  selectPropsJobItemId,
  (jobItemDependancies, id) =>
    get(jobItemDependancies, `byChildJobItemId.${id}`, EMPTY_ARRAY)
);

export const selectAllJobItemDependanciesByJobItemId = (state, props) =>
  selectJobItemDependancyIdsByChildJobItemId(state, props)
    .concat(selectJobItemDependancyIdsByParentJobItemId(state, props))
    .map(id => selectJobItemDependancyById(state, { id }));

export const selectJobItemDependancyParentJobItemById = (state, props) => {
  const jobItemDependancy = selectJobItemDependancyById(state, props);
  return selectJobItemById(state, {
    id: getParentJobItemId(jobItemDependancy)
  });
};

export const selectJobItemDependancyChildJobItemById = (state, props) => {
  const jobItemDependancy = selectJobItemDependancyById(state, props);
  return selectJobItemById(state, { id: getChildJobItemId(jobItemDependancy) });
};

export const selectIsDependancyConflictById = createSelector(
  selectJobItemDependancyById,
  selectJobItemDependancyParentJobItemById,
  selectJobItemDependancyChildJobItemById,
  (jobItemDependancy, parentJobItem, childJobItem) => {
    const currentLagDays = calculateDependancyLagDays(
      getDependancyTypeId(jobItemDependancy),
      parentJobItem,
      childJobItem
    );

    if (getIsFloat(jobItemDependancy))
      return currentLagDays < getLagDays(jobItemDependancy);

    return currentLagDays !== getLagDays(jobItemDependancy);
  }
);

export const selectJobItemDependancySegments = createSelector(
  selectJobItemDependancies,
  jobItemDependancies => get(jobItemDependancies, "jobItemDependancySegments")
);

export const selectJobItemDependancySegmentsByJobId = createSelector(
  selectJobItemDependancySegments,
  selectPropsJobId,
  (jobItemDependancySegments, jobId) =>
    get(jobItemDependancySegments, `byJobId.${jobId}`)
);

export const selectJobItemDependancySegmentsByJobIdJobPhaseId = createSelector(
  selectJobItemDependancySegmentsByJobId,
  selectPropsJobPhaseId,
  (jobItemDependancySegmentsByJobId, jobPhaseId) =>
    get(
      jobItemDependancySegmentsByJobId,
      `byJobPhaseId.${jobPhaseId}`,
      EMPTY_ARRAY
    )
);

export const selectJobItemDependancySegmentsByJobIdJobItemId = createSelector(
  selectJobItemDependancySegmentsByJobId,
  selectPropsJobItemId,
  (jobItemDependancySegmentsByJobId, jobItemId) =>
    get(
      jobItemDependancySegmentsByJobId,
      `byJobItemId.${jobItemId}`,
      EMPTY_ARRAY
    )
);

export const selectJobItemDependancyIdsSelected = createSelector(
  selectJobItemDependancies,
  jobItemDependancies =>
    get(jobItemDependancies, "jobItemDependancyIdsSelected", EMPTY_ARRAY)
);

export const selectJobItemDependanciesSelected = state =>
  selectJobItemDependancyIdsSelected(state)
    .map(id => selectJobItemDependancyById(state, { id }))
    .filter(isObjectLike);

export const selectIsJobItemDependancySelectedById = createSelector(
  selectJobItemDependancyIdsSelected,
  selectPropsId,
  (ids, id) => ids.includes(id)
);

export const selectJobItemDependanciesByJobItemIdStartDate = createSelector(
  [
    selectJobItemDependancies,
    selectJobItemDependancyIdsByParentJobItemId,
    selectJobItemDependancyIdsByChildJobItemId
  ],
  (jobItemDependancies, parentIds, childIds) => [
    ...parentIds.filter(parentId => {
      const dependancy = get(jobItemDependancies, `byId.${parentId}`);
      return (
        dependancy.dependancyTypeId ===
          JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START ||
        dependancy.dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END
      );
    }),
    ...childIds.filter(childId => {
      const dependancy = get(jobItemDependancies, `byId.${childId}`);
      return (
        dependancy.dependancyTypeId ===
          JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_START ||
        dependancy.dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START
      );
    })
  ]
);

export const selectJobItemDependanciesByJobItemIdEndDate = createSelector(
  [
    selectJobItemDependancies,
    selectJobItemDependancyIdsByParentJobItemId,
    selectJobItemDependancyIdsByChildJobItemId
  ],
  (jobItemDependancies, parentIds, childIds) => [
    ...parentIds.filter(parentId => {
      const dependancy = get(jobItemDependancies, `byId.${parentId}`);
      return (
        dependancy.dependancyTypeId ===
          JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END ||
        dependancy.dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_START
      );
    }),
    ...childIds.filter(childId => {
      const dependancy = get(jobItemDependancies, `byId.${childId}`);
      return (
        dependancy.dependancyTypeId ===
          JOB_ITEM_DEPENDANCY_TYPE_ID_END_TO_END ||
        dependancy.dependancyTypeId === JOB_ITEM_DEPENDANCY_TYPE_ID_START_TO_END
      );
    })
  ]
);
