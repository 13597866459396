import { Entity } from "@streamtimefe/entities";
import { delay, put, select } from "redux-saga/effects";
import { CostingMethods, TimeAllocationMethods } from "st-shared/entities";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEMS,
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_CREATE,
  JOB_ITEM_SET_EXPANDED,
  JOB_ITEM_SET_MASTER_JOB_ITEM,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  JOB_PHASE_SET_EXPANDED,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import { isPlanning } from "../../../lib/entities/jobEntity";
import {
  createNewJobItem,
  getOrderId,
} from "../../../lib/entities/jobItemEntity";
import { selectMasterJobItem } from "../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobItem } from "../../selectors/jobItem";
import {
  selectPreviousJobItemId,
  selectPreviousJobPhaseId,
} from "../../selectors/jobPhase/selectFullOrderedJobPhaseJobItemIdsByJobId";
import { selectJobById } from "../../selectors/jobSelectors";
import { selectNewJobItemCostingMethod } from "../../selectors/userPreferenceSelectors";

function* create(action) {
  const createId = Entity.temporaryId();

  try {
    const userPreferenceCostingMethodId = yield select(
      selectNewJobItemCostingMethod
    );

    const {
      jobId,
      fromJobItemId = null,
      fromJobPhaseId = null,
      masterJobItemId = null,
      costingMethodId = userPreferenceCostingMethodId,
    } = action.payload;

    const timeAllocationMethodId =
      (costingMethodId === CostingMethods.People ||
        costingMethodId === CostingMethods.ValueUserSell) &&
      masterJobItemId === null
        ? TimeAllocationMethods.People
        : TimeAllocationMethods.Item;

    const previousJobItemId = yield select(selectPreviousJobItemId, {
      jobId,
      fromJobItemId,
      fromJobPhaseId,
    });

    const previousJobPhaseId = yield select(selectPreviousJobPhaseId, {
      jobId,
      fromJobItemId,
      fromJobPhaseId,
    });

    const jobPhaseId =
      previousJobPhaseId && previousJobPhaseId > 0 ? previousJobPhaseId : null;
    let orderId = 0.5;

    if (previousJobItemId !== null) {
      const previousJobItem = yield select(selectJobItem, {
        jobItemId: previousJobItemId,
      });

      orderId = getOrderId(previousJobItem) + (previousJobItemId > 0 ? 0.5 : 1);
    }

    const job = yield select(selectJobById, { id: jobId });

    if (jobPhaseId !== null) {
      yield put(
        createAction(JOB_PHASE_SET_EXPANDED, {
          jobPhaseId,
          isExpanded: true,
        })
      );
    }

    const newJobItem = createNewJobItem({
      id: createId,
      jobId,
      jobPhaseId,
      orderId,
      jobItemStatusId: isPlanning(job)
        ? JOB_ITEM_STATUS_ID_PLANNING
        : JOB_ITEM_STATUS_ID_SCHEDULED,
      costingMethodId,
      timeAllocationMethodId,
      isBillable: job.isBillable,
    });

    if (job.isBillable && masterJobItemId !== null) {
      const masterJobItem = yield select(selectMasterJobItem, {
        id: masterJobItemId,
      });
      newJobItem.isBillable = masterJobItem.isBillable;
    }

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEMS]: [newJobItem],
      })
    );

    if (masterJobItemId !== null) {
      yield put(
        createAction(JOB_ITEM_SET_MASTER_JOB_ITEM, {
          jobItemId: createId,
          jobItem: newJobItem,
          masterJobItemId,
        })
      );
    }

    if (costingMethodId !== userPreferenceCostingMethodId) {
      yield put(
        createAction(SET_USER_PREFERENCE, {
          key: UserPreferenceKeys.USER_PREFERENCE_NEW_JOB_ITEM_COSTING_METHOD,
          value: costingMethodId,
        })
      );
    }

    yield delay(200);

    yield put(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId: createId,
          key: FOCUS_KEYS.ITEM_NAME,
        },
      })
    );
    yield put(
      createAction(JOB_ITEM_SET_EXPANDED, {
        jobItemId: createId,
        isExpanded: true,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchCreateJobItem() {
  yield takeLatestBy(
    [JOB_ITEM_CREATE],
    create,
    (action) => action.payload.jobId
  );
}
