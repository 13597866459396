import { produce } from "immer";
import { get, setWith, unset } from "lodash-es";

import { pushOnce } from "../../../../lib/arrays";
import { EMPTY_ARRAY } from "../../../../lib/constants";
import {
  getScheduleBlockKey,
  isBaseLoggedTime,
} from "../../../../lib/entities/scheduleLoggedTimeEntity";

const getPath = (blockKey) => `daysByBlockKey.${blockKey}`;

export default (nextState, changedEntities) =>
  produce(nextState, (draft) => {
    const touchedBlockKeys = [];

    changedEntities.forEach(({ prevEntity, newEntity }) => {
      const prevBlockKey =
        isBaseLoggedTime(prevEntity) && getScheduleBlockKey(prevEntity);
      const newBlockKey =
        isBaseLoggedTime(newEntity) && getScheduleBlockKey(newEntity);

      if (prevBlockKey) pushOnce(touchedBlockKeys, prevBlockKey);
      if (newBlockKey) pushOnce(touchedBlockKeys, newBlockKey);
    });

    touchedBlockKeys.forEach((blockKey) => {
      const days = get(
        nextState,
        `idsByBlockKey.${blockKey}`,
        EMPTY_ARRAY
      ).length;

      if (days > 0) setWith(draft, getPath(blockKey), days, Object);
      else unset(draft, getPath(blockKey));
    });
  });
