import { images } from "@streamtimefe/assets";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { SET_USER_PREFERENCE } from "../../../../lib/constants";
import createAction from "../../../../redux/helpers/createAction";
import { UserPreferenceKeys } from "../../../../state/entities/userPreferences/types";
import { InlineTextButton } from "../../../elements/Button";

const JobActivityTooManyFilters = () => {
  const dispatch = useDispatch();

  const turnAllFiltersOn = () => {
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER,
        value: true,
      })
    );
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_POSTS_FILTER,
        value: true,
      })
    );
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_SYSTEM_MESSAGES_FILTER,
        value: true,
      })
    );
  };

  return (
    <Container>
      <strong className="title">Hmm… Can’t see a thing.</strong>
      <br />
      <StyledImage alt="watering can man" src={images.wateringCan} />
      <br />
      <span>
        Have I left my glasses somewhere, or do you have too many filters on?
      </span>
      <br />
      <InlineTextButton onClick={turnAllFiltersOn}>
        Show Me Everything
      </InlineTextButton>
    </Container>
  );
};

export default JobActivityTooManyFilters;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 30px;
  margin-bottom: 20px;

  color: var(--color-gray-dark);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 100x;
  height: 100px;
  margin-bottom: 10px;
`;
