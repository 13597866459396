import clsx from "clsx";

import { IconButton } from "../../components";
import { TStatusType } from "../../types";
import { getColor, getIcon } from "./lib";
import { setStatusButtonCss } from "./SetStatusButton.css";

interface Props {
  onClick?: () => void;
  statusType: TStatusType;
  currentStatusType: TStatusType;
  size: number;
  className?: string;
}

export function SetStatusButton({
  onClick,
  statusType,
  currentStatusType,
  size,
  className,
  ...props
}: Props) {
  const isCurrent = currentStatusType === statusType;

  return (
    <IconButton
      onClick={onClick}
      disabled={isCurrent}
      className={clsx(setStatusButtonCss, className)}
      style={{ width: size, height: size }}
      iconProps={{
        icon: getIcon(statusType),
        size,
        color: isCurrent ? getColor(statusType) : undefined,
      }}
      {...props}
    />
  );
}
