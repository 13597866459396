import * as PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN } from "../../../../../lib/constants";
import { activityEntryEntityType } from "../../../../../lib/entities/activityEntryEntity";
import { reactNodesType } from "../../../../../lib/types/reactTypes";
import { feToWebActivityEntryClick } from "../../../../../lib/WebAppAPI/fePages/genericWeb";
import createAction from "../../../../../redux/helpers/createAction";
import { useIsDrawer } from "../../../context/isDrawerContext";

const LinkToActivityEntryEntity = ({ children, disabled, activityEntry }) => {
  const dispatch = useDispatch();
  const isDrawer = useIsDrawer();

  const onClick = () => {
    if (!disabled) {
      if (isDrawer) {
        dispatch(createAction(JOB_PAGE_SET_ACTIVITY_DRAWER_OPEN, false));
      }
      feToWebActivityEntryClick(activityEntry);
    }
  };

  return (
    <Span onClick={onClick} disabled={disabled}>
      {children}
    </Span>
  );
};

LinkToActivityEntryEntity.propTypes = {
  children: reactNodesType,
  disabled: PropTypes.bool,
  activityEntry: activityEntryEntityType.isRequired,
};

LinkToActivityEntryEntity.defaultProps = {
  children: null,
  disabled: false,
};

export default LinkToActivityEntryEntity;

const Span = styled.span`
  font-weight: bold;
  &:not([disabled]) {
    text-decoration: underline;
    cursor: pointer;
  }
`;
