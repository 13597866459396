import {
  isItemsByJobs,
  isJobs,
  isJobsByPeople,
  isPeopleByJobs,
  isTodos,
} from "../../../state/ui/schedule/lib";
import {
  useScheduleUiIsSearching,
  useScheduleUiViewMode,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import ScheduleUserAvailability from "../ScheduleLayout/ScheduleUserAvailability";
import ScheduleUserNonWorkingDays from "../ScheduleLayout/ScheduleUserNonWorkingDays";
import {
  JobRowsConsumer,
  JobSearchingPulser,
  UserRowsConsumer,
} from "../ScheduleScrollProvider/ScheduleRowConsumer";
import ScheduleJob from "./ScheduleJob";
import ScheduleJobPhases from "./ScheduleJobPhases";
import ScheduleJobTeamMembers from "./ScheduleJobTeamMembers";
import ScheduleUserJobs from "./ScheduleUserJobs";
import ScheduleUserTodos from "./ScheduleUserTodos";

function ScheduleBodyColumns() {
  const viewMode = useScheduleUiViewMode();
  const isSearching = useScheduleUiIsSearching();

  if (isJobs(viewMode)) {
    return isSearching ? (
      <JobSearchingPulser />
    ) : (
      <JobRowsConsumer>
        {(jobId, offsetTop) => (
          <ScheduleJob id={jobId} showLoader={isItemsByJobs(viewMode)}>
            {isItemsByJobs(viewMode) && <ScheduleJobPhases jobId={jobId} />}
            {isPeopleByJobs(viewMode) && (
              <ScheduleJobTeamMembers id={jobId} offsetTop={offsetTop} />
            )}
          </ScheduleJob>
        )}
      </JobRowsConsumer>
    );
  }

  return (
    <UserRowsConsumer>
      {(userId) => (
        <>
          <ScheduleUserNonWorkingDays id={userId} />
          <ScheduleUserAvailability id={userId} />
          {isTodos(viewMode) && <ScheduleUserTodos id={userId} />}
          {isJobsByPeople(viewMode) && <ScheduleUserJobs id={userId} />}
        </>
      )}
    </UserRowsConsumer>
  );
}

export default ScheduleBodyColumns;
