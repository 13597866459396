import { call, put } from "redux-saga/effects";
import { deletePurchaseOrderLineItemAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  PURCHASE_ORDER_LINE_ITEM_DELETE,
  PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_DELETED,
  PURCHASE_ORDER_LINE_ITEM_DELETING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* remove(action) {
  try {
    const { id } = action.payload;

    if (id < 0) return;

    yield put(createAction(PURCHASE_ORDER_LINE_ITEM_DELETING, { id }));

    const data = yield call(deletePurchaseOrderLineItemAPI, id);

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(createAction(PURCHASE_ORDER_LINE_ITEM_DELETED, { id }));

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_DELETE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchPurchaseOrderLineItemDelete() {
  yield takeLatestBy(
    PURCHASE_ORDER_LINE_ITEM_DELETE,
    remove,
    action => action.payload.id
  );
}
