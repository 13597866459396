import { ValueMatchTypeEnum } from "@streamtimefe/entities";
import { find, forEach, values } from "lodash-es";
import { Fragment, useMemo, useRef, useState } from "react";

import type { SelectKey, SelectProps } from "../../../components";
import {
  IconButton,
  MdAdd,
  Popover,
  Select,
  SelectHeader,
  SelectionCollection,
  SelectItem,
  SelectSection,
} from "../../../components";
import type { SearchOption } from "../../../entities";
import type { TEntityId } from "../../../entities/Entity";
import { useFocusWithinElement } from "../../../hooks";
import {
  useCurrencyGroupedOptions,
  useCustomerCurrency,
  useGroupedUserOptions,
} from "../../../stores";
import type { FilterTypeDropdown } from "../../../types";
import { FilterGroupTypeProperties, getFilterName } from "../../../types";
import type {
  FilterDisplayTextProps,
  FilterGroupFilterValue,
  FilterProps,
} from "..";
import {
  addButtonCss,
  boldText,
  dropdownEditCss,
  selectButtonCss,
  useFilterListener,
} from "..";
import { FilterDisplay } from "./FilterDisplay";
import { FilterElementDisplay } from "./FilterElementDisplay";

export interface DropdownFilterProps extends FilterProps {}

export function DropdownFilter({
  uuid,
  filterGroup,
  onDelete,
  addFilter,
  setFilter,
  deleteFilters,
}: DropdownFilterProps) {
  const displayRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { filters } = filterGroup;
  const allValues = filters.map((filter) => filter.value);

  const properties = FilterGroupTypeProperties[
    filterGroup.filterGroupTypeId
  ] as FilterTypeDropdown;

  useFilterListener(uuid, filterGroup.id, "open", openEditMenu);

  function openEditMenu() {
    setAnchorEl(displayRef.current);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        addEmptyFilter();
      }
    });
  }

  function closeEditMenu() {
    setAnchorEl(null);
    requestAnimationFrame(() => {
      if (
        filters.length === 0 ||
        (filters.length === 1 && filters[0].value === "")
      ) {
        onDelete(filterGroup.id);
      }
      if (filters.length > 1 && filters[filters.length - 1].value === "") {
        deleteFilters(filterGroup.id, [filters.length - 1]);
      }
    });
  }

  function onSelectChange(value: SelectKey, filterIndex: number) {
    setFilter(filterGroup.id, filterIndex, {
      value: value as FilterGroupFilterValue,
      valueMatchTypeId: filters[filterIndex].valueMatchTypeId,
    });
  }

  function onInputBlur(filterIndex: number) {
    if (filters.length > 1 && filters[filterIndex].value === "") {
      deleteFilters(filterGroup.id, [filterIndex]);
    }
  }

  function addEmptyFilter() {
    addFilter(filterGroup.id, {
      value: "",
      valueMatchTypeId: ValueMatchTypeEnum.Equals,
    });
  }

  const [options] = useOptions(properties.optionsType);

  return (
    <div>
      <FilterDisplay
        ref={displayRef}
        onClick={openEditMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <DropdownFilterDisplayText filterGroup={filterGroup} />
      </FilterDisplay>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: -36, horizontal: "center" }}
        PaperProps={{ className: dropdownEditCss.root }}
      >
        {filters.map((filter, index) => {
          return (
            <Fragment key={index}>
              <FilterElement
                options={options}
                allValues={allValues}
                value={filter.value}
                onSelectChange={(value) => onSelectChange(value, index)}
                placeholder={properties.placeholder}
                onBlur={() => onInputBlur(index)}
                onDelete={
                  filters.length > 1
                    ? () => deleteFilters(filterGroup.id, [index])
                    : null
                }
              />
              {index !== filters.length - 1 && (
                <div className={dropdownEditCss.divider} />
              )}
            </Fragment>
          );
        })}
        {filters.length > 0 && filters[filters.length - 1].value !== "" && (
          <IconButton
            className={addButtonCss}
            iconProps={{ icon: MdAdd }}
            onClick={addEmptyFilter}
          />
        )}
      </Popover>
    </div>
  );
}

interface FilterElementProps {
  options: SearchOption[] | Record<string, SearchOption[]>;
  allValues: FilterGroupFilterValue[];
  value: FilterGroupFilterValue;
  onSelectChange: SelectProps["onSelectionChange"];
  placeholder: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onDelete?: (() => void) | null;
}

export function FilterElement({
  options,
  allValues,
  value,
  onSelectChange,
  placeholder,
  onBlur,
  onFocus,
  onDelete,
}: FilterElementProps) {
  const { focusWithinProps } = useFocusWithinElement({
    onBlur,
    onFocus,
    delay: 50,
  });

  const filteredOptions: SearchOption[] | null = useMemo(() => {
    if (Array.isArray(options)) {
      const hideValues = allValues.filter((v) => v !== value);
      return options.filter((v) => !hideValues.includes(v.key));
    }
    return null;
  }, [options, value, allValues]);

  const filteredGroupOptions:
    | { name: string; options: SearchOption[] }[]
    | null = useMemo(() => {
    if (!Array.isArray(options)) {
      const hideValues = allValues.filter((v) => v !== value);
      const list: { name: string; options: SearchOption[] }[] = [];
      forEach(options, (optionList, key) => {
        const groupedList = optionList.filter(
          (v) => !hideValues.includes(v.key)
        );
        if (groupedList.length > 0) {
          list.push({ name: key, options: groupedList });
        }
      });
      return list;
    }
    return null;
  }, [options, value, allValues]);

  return (
    <FilterElementDisplay
      onDelete={onDelete}
      className={dropdownEditCss.filter}
      {...focusWithinProps}
    >
      {filteredOptions && (
        <Select
          aria-label="Dropdown Filter Selector"
          selectedKey={value ?? null}
          onSelectionChange={onSelectChange}
          placeholder={placeholder}
          buttonProps={{ className: selectButtonCss }}
          items={filteredOptions}
        >
          {(item) => <SelectItem id={item.key}>{item.value}</SelectItem>}
        </Select>
      )}
      {filteredGroupOptions && (
        <Select
          aria-label="Dropdown Filter Selector"
          selectedKey={value ?? null}
          onSelectionChange={onSelectChange}
          placeholder={placeholder}
          buttonProps={{ className: selectButtonCss }}
          items={filteredGroupOptions}
        >
          {(section) => (
            <SelectSection id={section.name}>
              <SelectHeader>{section.name}</SelectHeader>
              <SelectionCollection items={section.options}>
                {(item) => <SelectItem id={item.key}>{item.value}</SelectItem>}
              </SelectionCollection>
            </SelectSection>
          )}
        </Select>
      )}
    </FilterElementDisplay>
  );
}

function useOptions(
  optionsType: FilterTypeDropdown["optionsType"]
): [SearchOption[] | Record<string, SearchOption[]>, SearchOption[]] {
  const userOptions = useGroupedUserOptions();
  const currencyOptions = useCurrencyGroupedOptions();

  let options: SearchOption[] | Record<string, SearchOption[]> = [];

  switch (optionsType) {
    case "users":
      options = userOptions;
      break;
    case "currencies":
      options = currencyOptions;
      break;
  }

  const optionsArray = Array.isArray(options)
    ? options
    : values(options).flat();

  return [options, optionsArray];
}

export function DropdownFilterDisplayText({
  filterGroup,
}: FilterDisplayTextProps) {
  const customerCurrency = useCustomerCurrency();

  const { filterGroupTypeId, filters } = filterGroup;

  const properties = FilterGroupTypeProperties[
    filterGroupTypeId
  ] as FilterTypeDropdown;

  const [, optionsArray] = useOptions(properties.optionsType);

  function getOptionText(key: TEntityId) {
    const option = find(optionsArray, { key });
    if (option) {
      return option.value;
    }
    return key;
  }

  function getDisplayText() {
    return (
      <>
        {filters.length > 0 && " is "}
        {filters.map((filter, index) => (
          <Fragment key={index}>
            {index !== 0 && " or "}
            <span className={boldText}>
              {getOptionText(filter.value as TEntityId)}
            </span>
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      <span className={boldText}>
        {getFilterName(filterGroupTypeId, customerCurrency)}
      </span>
      {getDisplayText()}
    </>
  );
}
