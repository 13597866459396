import {
  DATA_ATTRIBUTE_BLOCK,
  DATA_ATTRIBUTE_DEPENDANCY_HANDLE,
  DATA_ATTRIBUTE_JOB,
  DATA_ATTRIBUTE_JOB_BAR,
  DATA_ATTRIBUTE_JOB_ITEM,
  DATA_ATTRIBUTE_JOB_ITEM_BAR,
  DATA_ATTRIBUTE_JOB_ITEM_DAYS,
  DATA_ATTRIBUTE_JOB_ITEM_USER,
  DATA_ATTRIBUTE_JOB_PHASE_BAR,
  DATA_ATTRIBUTE_RESIZE_HANDLE,
  DATA_ATTRIBUTE_USER_AVAILABILITY
} from "./constants";
import {
  getAttrPropString,
  getClosestElementFromPoint,
  getDataIntValue,
  getDataValue
} from "./dom";

export const isCapturePointerElement = e =>
  Boolean(e.target.closest(`[capturepointer]`));

export const isBody = e => Boolean(e.target.matches("body"));

export const isInRoot = e => Boolean(e.target.closest("#root"));

export const isPortal = e => Boolean(!isBody(e) && !isInRoot(e));

export const isFormElement = e => Boolean(e.target.closest("input, form"));

export const getTargetBlocksContainerElement = e =>
  getClosestElementFromPoint(e, `.scheduleBlocksContainer`);

export const getTargetUtilisationContainerElement = e =>
  getClosestElementFromPoint(e, `.scheduleUtilisationContainer`);

export const getTargetBlockElement = e =>
  getClosestElementFromPoint(e, `[${getAttrPropString(DATA_ATTRIBUTE_BLOCK)}]`);

export const getTargetBlockKey = e =>
  getDataIntValue(getTargetBlockElement(e), DATA_ATTRIBUTE_BLOCK);

export const getTargetResizeHandle = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE)}]`
  );

export const getTargetResizeHandleType = e =>
  getDataValue(getTargetResizeHandle(e), DATA_ATTRIBUTE_RESIZE_HANDLE);

export const getTargetDependancyHandle = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_DEPENDANCY_HANDLE)}]`
  );

export const getTargetDependancyHandleType = e =>
  getDataValue(getTargetDependancyHandle(e), DATA_ATTRIBUTE_DEPENDANCY_HANDLE);

export const getTargetUserAvailabilityElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_USER_AVAILABILITY)}]`
  );

export const getTargetUserAvailabilityId = e =>
  getDataIntValue(
    getTargetUserAvailabilityElement(e),
    DATA_ATTRIBUTE_USER_AVAILABILITY
  );

export const getTargetJobItemBarElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_BAR)}]`
  );

export const getTargetJobItemBarJobId = e =>
  getDataIntValue(getTargetJobItemBarElement(e), DATA_ATTRIBUTE_JOB);

export const getTargetJobItemBarId = e =>
  getDataIntValue(getTargetJobItemBarElement(e), DATA_ATTRIBUTE_JOB_ITEM_BAR);

export const getTargetJobItemBarDays = e =>
  getDataIntValue(getTargetJobItemBarElement(e), DATA_ATTRIBUTE_JOB_ITEM_DAYS);

export const getTargetJobBarElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_JOB_BAR)}]`
  );

export const getTargetJobBarId = e =>
  getDataIntValue(getTargetJobBarElement(e), DATA_ATTRIBUTE_JOB_BAR);

export const getTargetJobItemElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM)}]`
  );

export const getTargetJobItemUserElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_JOB_ITEM_USER)}]`
  );

export const getTargetJobItemId = e =>
  getDataIntValue(getTargetJobItemElement(e), DATA_ATTRIBUTE_JOB_ITEM);

export const getTargetJobPhaseBarElement = e =>
  getClosestElementFromPoint(
    e,
    `[${getAttrPropString(DATA_ATTRIBUTE_JOB_PHASE_BAR)}]`
  );
