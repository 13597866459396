import { produce } from "immer";
import { get, isEmpty, isEqual, reject, set, unset } from "lodash-es";
import { defaultSortOptions } from "st-shared/lib";

import { EMPTY_ARRAY } from "../../lib/constants";
import { isNilOrFalse } from "../../lib/lang";

/**
 * @param {function(object)} getKeyPath
 * @param {function(object)} getOption
 * @param {function(object)} sortOptions
 * @returns {Function}
 */
export default (getKeyPath, getOption, sortOptions = defaultSortOptions) =>
  (state = {}, changedEntities) =>
    produce(state, (draft) => {
      const pathsChanged = new Set();

      changedEntities.forEach(({ prevEntity, newEntity }) => {
        const prevPath = prevEntity && getKeyPath(prevEntity);
        const prevOption = prevEntity && getOption(prevEntity);
        const newPath = newEntity && getKeyPath(newEntity);
        const newOption = newEntity && getOption(newEntity);
        const hasChanged =
          !isEqual(prevPath, newPath) || !isEqual(prevOption, newOption);

        if (hasChanged) {
          if (!isNilOrFalse(prevPath) && !isNilOrFalse(prevOption)) {
            const prevOptions = get(draft, prevPath, []);
            const newOptions = reject(
              prevOptions,
              ({ key }) => key === prevOption.key
            );

            if (isEmpty(newOptions)) unset(draft, prevPath);
            else set(draft, prevPath, newOptions);

            pathsChanged.add(prevPath);
          }

          if (!isNilOrFalse(newPath) && !isNilOrFalse(newOption)) {
            const prevOptions = get(draft, newPath, []);
            const newOptions = reject(
              prevOptions,
              ({ key }) => key === newOption.key
            ).concat(newOption);

            set(draft, newPath, newOptions);

            pathsChanged.add(newPath);
          }
        }
      });

      pathsChanged.forEach((path) => {
        const options = get(draft, path, EMPTY_ARRAY);
        if (!isEmpty(options)) set(draft, path, options.sort(sortOptions));
      });
    });
