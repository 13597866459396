import {
  DisabledBoundary,
  useDisabledBoundary,
} from "@streamtimefe/ui/components";
import type { Content, UseEditorOptions } from "@tiptap/react";
import { EditorContent, useEditor } from "@tiptap/react";
import clsx from "clsx";
import type { ReactNode, RefObject } from "react";
import { memo, useCallback, useEffect, useMemo } from "react";

import {
  getRichTextEditorRef,
  type RichTextEditorRef,
  useRichTextEditorRefHandle,
} from "../hooks";
import {
  RichTextEvents,
  useRichTextEmitter,
  useRichTextEventListener,
} from "../hooks/useRichTextEmitter";
import { useRichTextExtensions } from "../hooks/useRichTextExtensions";
import type { ExtensionType } from "../types/ExtensionType";
import type { ToolbarType } from "../types/ToolbarType";
import * as styles from "./BaseRichTextEditor.css";
import { PopoverContainer } from "./PopoverContainer";
import {
  RichTextBubbleMenu,
  RichTextFixedMenu,
  RichTextFloatingMenu,
} from "./RichTextMenu";

export type BaseRichTextEditorProps = {
  extensionType: ExtensionType;
  editorRef?: RefObject<RichTextEditorRef>;
  content?: Content;
  toolbar?: ToolbarType;
  placeholder?: string;
  saveOnEnter?: (editorRef: RichTextEditorRef) => void;
  saveOnBlur?: (editorRef: RichTextEditorRef) => void;
  height?: number;
  classNames?: {
    container?: string;
    content?: string;
  };
  isDisabled?: boolean;
  editorOptions?: Omit<UseEditorOptions, "extensions" | "content">;
  headerNode?: ReactNode;
  footerNode?: ReactNode;
};

export const BaseRichTextEditor = memo(function RichTextEditor({
  extensionType,
  editorRef,
  content,
  toolbar = "fixed",
  height,
  classNames,
  isDisabled = false,
  editorOptions,
  headerNode,
  footerNode,
  placeholder,
  saveOnBlur,
  saveOnEnter,
}: BaseRichTextEditorProps) {
  const richTextEmitter = useRichTextEmitter();

  const extensions = useRichTextExtensions({
    extensionType,
    richTextEmitter,
    placeholder,
    saveOnBlur,
    saveOnEnter,
  });

  // this component should have minimal rerenders
  const editor = useEditor({
    extensions,
    content,
    ...editorOptions,
    editorProps: {
      ...editorOptions?.editorProps,
      attributes: {
        ...editorOptions?.editorProps?.attributes,
        class: clsx(styles.editor, classNames?.content),
      },
    },
  });

  useRichTextEditorRefHandle(editor, editorRef);

  useEffect(() => {
    if (content) editor?.commands.setContent(content);
  }, [editor, content]);

  useRichTextEventListener(
    richTextEmitter,
    RichTextEvents.SaveOnBlur,
    useCallback(() => {
      if (editor) saveOnBlur?.(getRichTextEditorRef(editor));
    }, [editor, saveOnBlur])
  );

  useRichTextEventListener(
    richTextEmitter,
    RichTextEvents.SaveOnEnter,
    useCallback(() => {
      if (editor) saveOnEnter?.(getRichTextEditorRef(editor));
    }, [editor, saveOnEnter])
  );

  const isDisabledBoundary = useDisabledBoundary();

  const isEditable = useMemo(
    () => !(isDisabled || isDisabledBoundary),
    [isDisabled, isDisabledBoundary]
  );

  useEffect(() => {
    editor?.setEditable(isEditable);
  }, [editor, isEditable]);

  return (
    <DisabledBoundary isDisabled={!isEditable}>
      <PopoverContainer
        height={height}
        className={clsx(styles.container, classNames?.container)}
      >
        {headerNode}
        {!isDisabled && toolbar === "fixed" && (
          <RichTextFixedMenu editor={editor} extensionType={extensionType} />
        )}
        <EditorContent editor={editor} className={styles.editorContent} />
        {!isDisabled && toolbar === "bubble" && (
          <RichTextBubbleMenu editor={editor} extensionType={extensionType} />
        )}
        {!isDisabled && toolbar === "floating" && (
          <RichTextFloatingMenu editor={editor} extensionType={extensionType} />
        )}
        {footerNode}
      </PopoverContainer>
    </DisabledBoundary>
  );
});
