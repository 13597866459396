import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataCompanyName,
  getMetaDataJobName,
  getMetaDataSourceJobName,
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobDuplicated = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" duplicated the job from "}
    <b>{getMetaDataSourceJobName(activityEntry)}</b>
  </SystemMessage>
);

JobDuplicated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
};

export default JobDuplicated;
