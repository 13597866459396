import styled, { css } from "styled-components";

import Checkbox from "../elements/Checkbox";
import Textarea from "../elements/Textarea";
import { ItemColumnWidths } from "./consts/ItemColumnWidths";

export const BaseItem = styled.div`
  position: relative;
  background-color: white;
  color: black;
`;

export const BaseItemExtended = styled(BaseItem)`
  display: flex;
  flex-direction: row;
  height: 40px;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 42px;
`;

export const FullWidthBaseItem = styled(BaseItemExtended)`
  width: 100%;
`;

export const BaseItemButton = styled.button`
  cursor: pointer;
  position: relative;
  background-color: white;
  color: black;
`;

export const FullWidthBaseItemButton = styled(BaseItemButton)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 42px;
`;

export const ItemTextArea = styled(Textarea)`
  color: black;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 11px 20px 9px 0;
  resize: none;
  &::placeholder {
    color: var(--color-gray-medium);
  }
`;

export const SubItemCheckedBox = styled(Checkbox)`
  align-self: center;
  width: 40px;
  height: 30px;
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

export const SubItemTextArea = styled(ItemTextArea)`
  flex-grow: 1;
  line-height: 17px;
  padding: 8px 20px 6px 0;
  background-color: transparent;
  ${(props) => props.checked && `text-decoration: line-through`}
`;

export const LargeItemText = styled.div`
  width: ${(props) => props.width}px;
  height: 46px;
  padding: 3px 15px 0 5px;
  text-align: right;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-normal);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => props.warning && `color: var(--color-red-dark);`};
`;

export const TotalLargeItemText = styled(LargeItemText)`
  @media (max-width: 1200px) {
    width: ${(props) => props.width - ItemColumnWidths.TotalSmallScreenDiff}px;
  }
`;

export const LoadingPulse = styled.div`
  font-size: 40px;
  color: var(--color-gray-dark);
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const hiddenElement = css`
  ${(props) =>
    props.hidden &&
    `
      visibility: hidden;
      position: absolute; 
      overflow: hidden; 
      clip: rect(0 0 0 0); 
      height: 1px; width: 1px; 
      margin: -1px; padding: 0; border: 0; 
    `}
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
