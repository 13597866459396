import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_BLUE_BRIGHT,
  COLOR_TEAL_BRIGHT,
  COLOR_TEAL_LIGHT,
  JOB_DETAILS_SET_ACTIVITY_SECTION_TAB,
} from "../../lib/constants";
import { JOB_ACTIVITY_SECTION_TABS } from "../../lib/constants/jobDetails";
import createAction from "../../redux/helpers/createAction";
import { useJobDetailsActivitySectionTab } from "../../redux/selectors/jobDetails/ui/activitySectionTab";
import ActivityIcon from "../elements/Icons/custom/ActivityIcon";
import DatePickerIcon from "../elements/Icons/DatePickerIcon";
import { ICON_SIZE } from "../modules/StyledIcon";
import JobTabs from "./components/JobTabs";
import JobActivity from "./JobActivity";
import JobDates from "./JobDates";

const ActivitySection = () => {
  const dispatch = useDispatch();

  const tab = useJobDetailsActivitySectionTab();

  const setTab = (newTab) =>
    dispatch(createAction(JOB_DETAILS_SET_ACTIVITY_SECTION_TAB, newTab));

  return (
    <Container>
      <TabHeader>
        <JobTabs
          selected={tab}
          setTab={setTab}
          tabs={[
            {
              name: "Dates",
              value: JOB_ACTIVITY_SECTION_TABS.DATES,
              activeColor: COLOR_TEAL_LIGHT,
              borderColor: COLOR_TEAL_BRIGHT,
              icon: DatePickerIcon,
              iconSize: ICON_SIZE.LARGE,
            },
            {
              name: "Activity",
              value: JOB_ACTIVITY_SECTION_TABS.ACTIVITY,
              activeColor: COLOR_BLUE_BRIGHT,
              borderColor: COLOR_BLUE,
              icon: ActivityIcon,
              iconSize: ICON_SIZE.MEDIUM,
            },
          ]}
        />
      </TabHeader>
      <Display>
        {tab === JOB_ACTIVITY_SECTION_TABS.ACTIVITY && <JobActivity />}
        {tab === JOB_ACTIVITY_SECTION_TABS.DATES && <JobDates />}
      </Display>
    </Container>
  );
};

export default ActivitySection;

const Container = styled.div`
  max-width: 360px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TabHeader = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 2px solid var(--color-gray);

  .JobTabs {
    width: 100%;
  }
`;

const Display = styled.div`
  background-color: var(--color-snow);
  width: 100%;
  height: 400px;
`;
