import { useSearchItemsFilterControlled } from "@streamtimefe/hooks";
import { useActiveUserSearchItems } from "@streamtimefe/state";
import { webapi } from "@streamtimefe/state/api";
import { Avatar, BaseButton, PickerPopover } from "@streamtimefe/ui/components";
import type { MentionNodeAttrs } from "@tiptap/extension-mention";
import { Mention } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from "@tiptap/suggestion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import scrollIntoView from "scroll-into-view-if-needed";

import { usePopoverContainerContext } from "../components/PopoverContainer";
import * as styles from "./UserMention.css";

export const UserMention = Mention.extend({
  parseHTML() {
    return [{ tag: `span[class="mention"]` }];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => {
          const idElement = element.querySelector(".mention-user-id");
          const id = idElement?.innerHTML;
          if (id) {
            return id.replace("[", "").replace("]", "");
          }
        },
      },
      label: {
        default: null,
        parseHTML: (element) => {
          const idElement = element.querySelector(".mention-user-id");
          return idElement?.nextSibling?.textContent;
        },
      },
    };
  },
  renderHTML({ node }) {
    return [
      "span",
      this.options.HTMLAttributes,
      this.options.suggestion.char,
      ["span", { class: "mention-user-id" }, `[${node.attrs.id}]`],
      node.attrs.label ?? "",
    ];
  },
}).configure({
  HTMLAttributes: {
    class: "mention",
  },
  suggestion: {
    render: () => {
      let component:
        | ReactRenderer<MentionPopoverRef, MentionPopoverProps>
        | undefined;

      return {
        onStart: (props) => {
          component = new ReactRenderer<MentionPopoverRef, MentionPopoverProps>(
            MentionPopover,
            {
              props,
              editor: props.editor,
            }
          );

          component.ref?.setPopoverOpen(true);
        },

        onUpdate(props) {
          component?.updateProps(props);
          component?.ref?.setPopoverOpen(true);
        },

        onKeyDown(props) {
          return component?.ref?.onKeyDown(props) ?? false;
        },

        onExit() {
          component?.ref?.setPopoverOpen(false);
          component?.destroy();
        },
      };
    },
  },
});

type MentionPopoverRef = {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean;
  setPopoverOpen: (open: boolean) => void;
};
type MentionPopoverProps = SuggestionProps<unknown, MentionNodeAttrs>;

const MentionPopover = forwardRef<MentionPopoverRef, MentionPopoverProps>(
  ({ clientRect, query, command }, ref) => {
    const users = useActiveUserSearchItems();
    const items = useSearchItemsFilterControlled(users, query);

    const { attachRef, isOpen, setIsOpen } = usePopoverContainerContext();

    const triggerRef = useRef<HTMLDivElement>(null);

    const childRect = clientRect?.();
    const parentRect = attachRef.current?.getBoundingClientRect();

    const rect = useMemo(() => {
      if (!childRect) return new DOMRect(0, 0, 0, 0);
      if (!parentRect)
        return new DOMRect(0, 0, childRect.width, childRect.height);

      return new DOMRect(
        childRect.x - parentRect.x,
        childRect.y - parentRect.y,
        childRect.width,
        childRect.height
      );
    }, [childRect, parentRect]);

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => setSelectedIndex(0), [items]);

    useLayoutEffect(() => {
      const mentionItem = document.querySelector(
        `#mention_item_${selectedIndex}`
      );

      if (mentionItem) {
        scrollIntoView(mentionItem, {
          scrollMode: "if-needed",
          behavior: "instant",
          block: "nearest",
        });
      }
    }, [selectedIndex]);

    function selectItem(index: number) {
      const item = items[index];

      if (item) {
        command({ id: String(item.id), label: item.value });
      }
    }

    useImperativeHandle<MentionPopoverRef, MentionPopoverRef>(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === "ArrowUp") {
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);
          return true;
        }

        if (event.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % items.length);
          return true;
        }

        if (event.key === "Enter") {
          selectItem(selectedIndex);
          return true;
        }

        if (event.key === "Escape") {
          setIsOpen(false);
          return true;
        }

        return false;
      },
      setPopoverOpen: (open: boolean) => {
        setIsOpen(open);
      },
    }));

    return createPortal(
      <>
        <div
          ref={triggerRef}
          className={styles.positioner}
          style={{
            top: rect.y,
            left: rect.x,
            height: rect.height,
            width: rect.width,
          }}
        />
        <PickerPopover
          triggerRef={triggerRef}
          placement="top left"
          isNonModal
          isOpen={isOpen}
          onOpenChange={setIsOpen}
        >
          <div className={styles.popover}>
            {items.map((item, index) => {
              return (
                <BaseButton
                  aria-label="mention item"
                  id={`mention_item_${index}`}
                  key={item.id}
                  className={styles.listItem({
                    selected: index === selectedIndex,
                  })}
                  onPress={() => selectItem(index)}
                >
                  <Avatar
                    url={webapi.users.getProfileImagesUrl({
                      userId: item.item.id,
                    })}
                    alt={item.value}
                    size="mini"
                  />
                  <span>{item.value}</span>
                </BaseButton>
              );
            })}
          </div>
        </PickerPopover>
      </>,
      attachRef.current!
    );
  }
);

MentionPopover.displayName = "MentionPopover";
