/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";

import { getEndOfWeek, getStartOfWeek } from "../../../lib/dates";

export interface ScrollContext {
  scrollOffsetX: number;
  scrollOffsetY: number;
  dayWidth: number;
  anchorPositionX: number;
  viewportHeight: number;
  contentHeight: number;
  viewportOffsetX: number;
  viewportOffsetRightX: number;
  viewportStartDate: string;
  viewportEndDate: string;
  getScrollOffsetX: () => number;
  getScrollOffsetY: () => number;
  getDateAtOffsetX: () => number;
  getDateOnPosition: () => string | null;
  getOffsetXAtDate: (date: string) => number;
  getDayWidth: () => number;
  getDaysFromWidth: () => number;
  goToToday: () => void;
  goToDate: (date: string) => void;
  goToRHSDate: (date: string) => void;
  goForward: () => void;
  goBackward: () => void;
  beforeZoom: () => void;
}

export function createScrollContext() {
  return createContext<ScrollContext>({
    scrollOffsetX: 0,
    scrollOffsetY: 0,
    dayWidth: 0,
    anchorPositionX: 0,
    viewportHeight: 0,
    contentHeight: 0,
    viewportOffsetX: 0,
    viewportOffsetRightX: 0,
    viewportStartDate: getStartOfWeek(),
    viewportEndDate: getEndOfWeek(),
    getScrollOffsetX: () => 0,
    getScrollOffsetY: () => 0,
    getDateAtOffsetX: () => 0,
    getDateOnPosition: () => null,
    getOffsetXAtDate: () => 0,
    getDayWidth: () => 0,
    getDaysFromWidth: () => 0,
    goToToday: () => {},
    goToDate: () => {},
    goToRHSDate: () => {},
    goForward: () => {},
    goBackward: () => {},
    beforeZoom: () => {},
  });
}
