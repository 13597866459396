import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";

import { inputCss, labelCss, showCss } from "./Input.css";

export interface BaseInputProps extends ComponentPropsWithRef<"input"> {
  className?: string;
  labelClassName?: string;
  variant?: "base" | "primary" | "secondary";
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  ({ className, labelClassName, variant = "base", ...props }, ref) => {
    return (
      <>
        {variant === "primary" && props.placeholder && (
          <label
            className={clsx(labelCss, props.value && showCss, labelClassName)}
          >
            {props.placeholder}
          </label>
        )}
        <input
          ref={ref}
          className={clsx(inputCss[variant], className)}
          {...props}
        />
      </>
    );
  }
);

BaseInput.displayName = "BaseInput";
