import { all } from "redux-saga/effects";

import watchJobPhaseCheckFocus from "./watchJobPhaseCheckFocus";
import watchJobPhaseCreate from "./watchJobPhaseCreate";
import {
  watchJobPhaseDelete,
  watchJobPhaseDeleteNew,
} from "./watchJobPhaseDelete";
import watchJobPhaseDuplicate from "./watchJobPhaseDuplicate";
import watchJobPhaseReorder from "./watchJobPhaseReorder";
import watchJobPhaseSave from "./watchJobPhaseSave";
import watchJobPhaseSaveNew from "./watchJobPhaseSaveNew";
import watchJobPhaseSetAllExpanded from "./watchJobPhaseSetAllExpanded";
import watchJobPhaseSetIsBillable from "./watchJobPhaseSetIsBillable";
import watchJobPhaseSetName from "./watchJobPhaseSetName";
import watchJobPhaseStatusChange from "./watchJobPhaseStatusChange";

export default function* jobPhaseSaga() {
  yield all([
    watchJobPhaseSetName(),
    watchJobPhaseCheckFocus(),
    watchJobPhaseSave(),
    watchJobPhaseSaveNew(),
    watchJobPhaseCreate(),
    watchJobPhaseDelete(),
    watchJobPhaseDeleteNew(),
    watchJobPhaseReorder(),
    watchJobPhaseSetAllExpanded(),
    watchJobPhaseDuplicate(),
    watchJobPhaseStatusChange(),
    watchJobPhaseSetIsBillable(),
  ]);
}
