import type { ConstEnum } from "@streamtimefe/types";

import type { TEnumOption } from "../../core";

export const SavedSegmentTypeEnum = {
  GroupedLoggedTime: 1,
  Jobs: 2,
  LoggedExpenses: 3,
  Quotes: 4,
  Invoices: 5,
  Schedule: 6,
  Wip: 7,
  Billing: 8,
  Companies: 9,
  Contacts: 10,
  Users: 11,
  LoggedTime: 12,
  Reporting: 13,
} as const;

export type TSavedSegmentTypeEnum = ConstEnum<typeof SavedSegmentTypeEnum>;

export type TSavedSegmentType = TEnumOption<TSavedSegmentTypeEnum>;
