import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getPermissionsSnapshot } from "st-shared/stores";
import styled from "styled-components";

import {
  DATA_ATTRIBUTE_JOB_BAR,
  DATE_FORMAT_DAY_ORDINAL_DATE_MONTH,
  SCHEDULE_JOB_COLLAPSE,
  SCHEDULE_JOB_EXPAND,
} from "../../../lib/constants";
import { formatDate } from "../../../lib/dates";
import { getAttrPropString } from "../../../lib/dom";
import { getJobStatusIconClass } from "../../../lib/entities/jobEntity";
import { scheduleSearchResultEntityType } from "../../../lib/entities/scheduleSearchResultEntity";
import { entityIdType } from "../../../lib/types/entityTypes";
import { reactNodesType } from "../../../lib/types/reactTypes";
import createAction from "../../../redux/helpers/createAction";
import { selectScheduleJobUi } from "../../../redux/selectors/scheduleSelectors";
import { selectJobListModel } from "../../../state/entities/jobListModel/selectors/selectJobListModel";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import JobMilestones from "../../modules/JobMilestone";
import Tooltip from "../../modules/Tooltip";
import TooltipContentList from "../../modules/Tooltip/TooltipContentList";
import MilestonesTooltip from "../ScheduleLayout/MilestonesTooltip";
import ScheduleJobMilestoneGroup from "../ScheduleLayout/ScheduleJobMilestoneGroup";
import Anchor from "../ScheduleScrollProvider/Anchor";
import ScheduleBarConsumer from "../ScheduleScrollProvider/ScheduleBarConsumer";
import ScheduleJobMilestoneDatesConsumer from "../ScheduleScrollProvider/ScheduleJobMilestoneDatesConsumer";
import JobLoadingPulser from "./JobLoadingPulser";
import { ScheduleBarRect, ScheduleJobBar } from "./ScheduleBars";

const mapState = (state, props) => {
  const {
    isExpanded = false,
    isDragging = false,
    isFetching = false,
    isFetched = false,
  } = selectScheduleJobUi(state, {
    jobId: props.id,
  });

  return {
    scheduleSearchResult: selectJobListModel(state, { jobId: props.id }),
    canEditJobs: getPermissionsSnapshot().canEditJobs,
    isExpanded,
    isDragging,
    isFetching,
    isFetched,
  };
};

const mapDispatch = (dispatch) => ({
  doExpand: (jobId) => dispatch(createAction(SCHEDULE_JOB_EXPAND, { jobId })),
  doCollapse: (jobId) =>
    dispatch(createAction(SCHEDULE_JOB_COLLAPSE, { jobId })),
});

const TooltipTitle = ({ scheduleSearchResult, canMove }) => (
  <TooltipContentList
    contentArray={[
      [
        "Start",
        scheduleSearchResult.estimatedStartDate
          ? formatDate(
              scheduleSearchResult.estimatedStartDate,
              DATE_FORMAT_DAY_ORDINAL_DATE_MONTH
            )
          : "no date set",
      ],
      [
        "End",
        scheduleSearchResult.estimatedEndDate
          ? formatDate(
              scheduleSearchResult.estimatedEndDate,
              DATE_FORMAT_DAY_ORDINAL_DATE_MONTH
            )
          : "no date set",
      ],
      ...(canMove ? [[<em>Drag to move item dates</em>]] : []),
    ]}
  />
);

TooltipTitle.propTypes = {
  scheduleSearchResult: scheduleSearchResultEntityType.isRequired,
  canMove: PropTypes.bool.isRequired,
};

class ScheduleJob extends React.PureComponent {
  tooltipAnchorRef = React.createRef();

  static propTypes = {
    id: entityIdType.isRequired,
    children: reactNodesType.isRequired,
    scheduleSearchResult: scheduleSearchResultEntityType.isRequired,
    canEditJobs: PropTypes.bool.isRequired,
    showLoader: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetched: PropTypes.bool.isRequired,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
  };

  get isDragging() {
    const { isDragging } = this.props;
    return isDragging;
  }

  toggleExpand = (e) => {
    const { id, isExpanded, doExpand, doCollapse } = this.props;

    if (e.target.closest(".jobMilestone")) return;

    if (isExpanded) doCollapse(id);
    else doExpand(id);
  };

  render() {
    const {
      id,
      children,
      scheduleSearchResult,
      canEditJobs,
      showLoader,
      isExpanded,
      isFetching,
      isFetched,
    } = this.props;

    const {
      estimatedStartDate,
      estimatedEndDate,
      earliestStartDate,
      latestEndDate,
      jobStatusId,
    } = scheduleSearchResult;

    const jobStatusClassName = getJobStatusIconClass(jobStatusId);
    const jobInnerBarAttrName = getAttrPropString(DATA_ATTRIBUTE_JOB_BAR);

    return (
      <>
        <Tooltip
          title={
            <TooltipTitle
              scheduleSearchResult={scheduleSearchResult}
              canMove={canEditJobs}
            />
          }
          placement="top"
          useCursorPosition={{
            x: true,
            y: false,
            anchorRef: this.tooltipAnchorRef,
          }}
          open={this.isDragging}
          enabled={false}
        >
          <JobContainer
            className={isExpanded ? "expanded" : ""}
            onClick={this.toggleExpand}
            ref={this.tooltipAnchorRef}
          >
            <ScheduleBarConsumer
              barStartDate={earliestStartDate}
              barEndDate={latestEndDate}
              startDate={estimatedStartDate}
              dueDate={estimatedEndDate}
            >
              {({ inner, outer }) => (
                <Anchor>
                  {outer && (
                    <ScheduleBarRect
                      style={{ left: outer.left, width: outer.width }}
                      disabled={!canEditJobs}
                    >
                      <ScheduleJobBar className={jobStatusClassName} />
                    </ScheduleBarRect>
                  )}
                  {inner && (
                    <ScheduleBarRect
                      {...{ [jobInnerBarAttrName]: id }}
                      style={{ left: inner.left, width: inner.width }}
                      disabled={!canEditJobs}
                    >
                      <ScheduleJobBar
                        inner
                        canEdit={canEditJobs}
                        className={[jobStatusClassName]
                          .concat(inner.classes)
                          .join(" ")}
                      />
                    </ScheduleBarRect>
                  )}
                </Anchor>
              )}
            </ScheduleBarConsumer>
            <Anchor>
              <ScheduleJobMilestoneDatesConsumer jobId={id}>
                {({ date, left, width, jobMilestoneIds }) => (
                  <MilestonesTooltip
                    key={date}
                    jobMilestoneIds={jobMilestoneIds}
                  >
                    <ScheduleJobMilestoneGroup style={{ left, width }}>
                      <JobMilestones
                        jobMilestoneIds={jobMilestoneIds}
                        date={date}
                        readOnly
                        placement="right"
                      />
                    </ScheduleJobMilestoneGroup>
                  </MilestonesTooltip>
                )}
              </ScheduleJobMilestoneDatesConsumer>
            </Anchor>
          </JobContainer>
        </Tooltip>
        {isExpanded && showLoader && isFetching && <JobLoadingPulser />}
        {isExpanded && (showLoader ? isFetched : true) && children}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleJob);

const JobContainer = styled.div`
  height: ${ScheduleUi.Job.RowHeight}px;
  width: 100%;
  position: relative;
  cursor: pointer;
  &.expanded {
    border-bottom: var(--border-thick-bright);
  }
`;
