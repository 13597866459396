import { memoize } from "lodash-es";
import styled from "styled-components";

import {
  DATE_FORMAT_DATE_FULL_MONTH,
  DATE_FORMAT_DATE_MONTH_FULL_YEAR,
  DATE_FORMAT_DATE_OF_MONTH,
  DATE_FORMAT_DAY_DATE,
  DATE_FORMAT_DAY_OF_WEEK_LETTER,
  DATE_FORMAT_FULL_MONTH,
  DATE_FORMAT_FULL_MONTH_YEAR,
} from "../../../lib/constants";
import {
  formatDate,
  getEndOfWeek,
  getMonth,
  getTodayDate,
  getYear,
  isMonday,
} from "../../../lib/dates";
import { memoizeArgsWithTodayDate } from "../../../lib/memoizers";
import { getWeekWidth, isMaxZoomLevel } from "../../../state/ui/schedule/lib";
import {
  useScheduleUiFetchedLoggedTimes,
  useScheduleUiZoomLevel,
} from "../../../state/ui/schedule/selectors/selectScheduleUi";
import { useVisibleUserIds } from "../../../state/ui/schedule/selectors/useVisibleUserIds";
import { ScheduleUi } from "../../../state/ui/schedule/types";
import { HorizontalLinearProgress } from "../../elements/LinearProgress";
import Ellipsis from "../../elements/Typography/Ellipsis";
import { ScheduleBodyColumnCell } from "../ScheduleLayout/ScheduleBodyColumnCell";
import Anchor from "../ScheduleScrollProvider/Anchor";
import DatesConsumer from "../ScheduleScrollProvider/DatesConsumer";
import MonthsConsumer from "../ScheduleScrollProvider/MonthsConsumer";
import WeeksConsumer from "../ScheduleScrollProvider/WeeksConsumer";

function getDateFormat(zoomLevel: number) {
  return isMaxZoomLevel(zoomLevel)
    ? DATE_FORMAT_DAY_DATE
    : DATE_FORMAT_DAY_OF_WEEK_LETTER;
}

function getDayText(date: string, zoomLevel: number) {
  return memoizeArgsWithTodayDate((date: string, zoomLevel: number) =>
    date === getTodayDate()
      ? "Today"
      : formatDate(date, getDateFormat(zoomLevel))
  )(date, zoomLevel);
}

function getWeekText(startOfWeek: string) {
  return memoize((startOfWeek: string) => {
    const endOfWeek = getEndOfWeek(startOfWeek);

    const startOfWeekText =
      getMonth(startOfWeek) !== getMonth(endOfWeek)
        ? formatDate(startOfWeek, DATE_FORMAT_DATE_FULL_MONTH)
        : formatDate(startOfWeek, DATE_FORMAT_DATE_OF_MONTH);

    const endOfWeekText =
      getYear(endOfWeek) === getYear(getTodayDate())
        ? formatDate(endOfWeek, DATE_FORMAT_DATE_FULL_MONTH)
        : formatDate(endOfWeek, DATE_FORMAT_DATE_MONTH_FULL_YEAR);

    return `${startOfWeekText} - ${endOfWeekText}`;
  })(startOfWeek);
}

function getMonthText(date: string): string {
  return memoize((date: string) =>
    formatDate(
      date,
      getYear(date) === getYear(getTodayDate())
        ? DATE_FORMAT_FULL_MONTH
        : DATE_FORMAT_FULL_MONTH_YEAR
    )
  )(date);
}

function Days({ zoomLevel }: { zoomLevel: number }) {
  return (
    <Anchor>
      <DatesConsumer>
        {({ date, offsetX, dayWidth, isToday }) => (
          <ScheduleDateCell
            key={date}
            isToday={isToday}
            isMonday={!isMaxZoomLevel(zoomLevel) && isMonday(date)}
            style={{ left: offsetX, width: isToday ? dayWidth + 1 : dayWidth }}
          >
            {getDayText(date, zoomLevel)}
          </ScheduleDateCell>
        )}
      </DatesConsumer>
    </Anchor>
  );
}

function Weeks() {
  return (
    <Anchor>
      <WeeksConsumer>
        {({ date, left, dayWidth }) => (
          <ScheduleWeekCell
            key={date}
            style={{ left, width: getWeekWidth(dayWidth) }}
          >
            {getWeekText(date)}
          </ScheduleWeekCell>
        )}
      </WeeksConsumer>
    </Anchor>
  );
}

function Months() {
  return (
    <Anchor>
      <MonthsConsumer>
        {({ date, left, width }) => (
          <ScheduleMonthCell key={date} style={{ left, width }}>
            <Ellipsis>{getMonthText(date)}</Ellipsis>
          </ScheduleMonthCell>
        )}
      </MonthsConsumer>
    </Anchor>
  );
}

function ScheduleLoadingProgress() {
  const fetchedLoggedTimes = useScheduleUiFetchedLoggedTimes();
  const visibleUserIds = useVisibleUserIds();

  return (
    <WeeksConsumer>
      {({ date, left, dayWidth }) => {
        if (
          visibleUserIds.length === 0 ||
          (fetchedLoggedTimes.usersByDate[date] &&
            visibleUserIds.every((userId) =>
              fetchedLoggedTimes.usersByDate[date].includes(userId)
            ))
        ) {
          return null;
        }
        return (
          <LoadingProgressRepeater
            key={date}
            style={{ left, width: getWeekWidth(dayWidth) }}
          >
            <HorizontalLinearProgress />
          </LoadingProgressRepeater>
        );
      }}
    </WeeksConsumer>
  );
}

function ScheduleDates() {
  const zoomLevel = useScheduleUiZoomLevel();

  return (
    <Wrapper>
      <Row>{isMaxZoomLevel(zoomLevel) ? <Months /> : <Weeks />}</Row>
      <Row>
        <Days zoomLevel={zoomLevel} />
      </Row>
      <Anchor>
        <ScheduleLoadingProgress />
      </Anchor>
    </Wrapper>
  );
}

export default ScheduleDates;

const Wrapper = styled(ScheduleBodyColumnCell)`
  position: relative;
  height: ${ScheduleUi.View.HeaderHeight}px;
`;

const Row = styled.div`
  position: relative;
  height: ${ScheduleUi.View.DatesRowHeight}px;
`;

const ScheduleDateCell = styled.div<{ isMonday?: boolean; isToday?: boolean }>`
  display: flex;
  position: absolute;
  height: 100%;
  background-color: white;
  color: var(--color-charcoal);
  padding: 2px 0 0;
  font-size: 10px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  will-change: left;

  ${(props) =>
    props.isMonday &&
    `
    border-left: 1px solid var(--color-gray-medium);
  `}

  ${(props) =>
    props.isToday &&
    `
    background-color: black;
    color: white;
    font-weight: bold;
  `}
`;

const ScheduleWeekCell = styled(ScheduleDateCell)`
  background-color: var(--color-charcoal);
  color: white;
  font-weight: bold;
  justify-content: flex-start;
  padding: 0 0 0 10px;
  border-left: 1px solid var(--color-gray-medium);
`;

const ScheduleMonthCell = styled(ScheduleDateCell)`
  background-color: var(--color-charcoal);
  color: white;
  font-weight: bold;
  justify-content: flex-start;
  padding: 0 0 0 20px;
`;

const LoadingProgressRepeater = styled.div`
  position: absolute;
  top: -2px;
`;
