import * as PropTypes from "prop-types";
import React from "react";
import {
  POINTER_CLICK_OFFSET_LIMIT,
  POINTER_CLICK_TIME_LIMIT_MS
} from "../../../lib/constants";
import { getPointerClientOffset, subtractCoords } from "../../../lib/dom";
import {
  getTargetDependancyHandle,
  getTargetJobItemElement,
  getTargetJobItemId,
  getTargetResizeHandle
} from "../../../lib/eventTargets";
import JobItemPopover from "../../modules/JobItem/JobItemPopover";

export default WrappedComponent =>
  class ClickHandlers extends React.PureComponent {
    clickItem = null;

    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      onClick: PropTypes.func,
      onPointerDown: PropTypes.func
    };

    static defaultProps = {
      onClick: null,
      onPointerDown: null
    };

    state = {
      selectedJobItemId: null,
      selectedJobItemAnchorEl: null,
      isEditModalOpen: false
    };

    onClick = e => {
      const { onClick } = this.props;

      if (!this.shouldCancelClick(e)) {
        this.openEditModal(this.clickItem);
      }

      this.clickItem = null;

      return onClick && onClick(e);
    };

    onPointerDown = e => {
      const { onPointerDown } = this.props;

      const jobItemId = getTargetJobItemId(e);
      const jobItemContainerNode = jobItemId && getTargetJobItemElement(e);
      const isOtherEvent =
        getTargetResizeHandle(e) || getTargetDependancyHandle(e);

      if (jobItemContainerNode && !isOtherEvent)
        this.clickItem = {
          jobItemId,
          jobItemContainerNode,
          origin: {
            pointerOffset: getPointerClientOffset(e),
            startTime: Date.now()
          }
        };

      return onPointerDown && onPointerDown(e);
    };

    onCloseEditModal = () => {
      this.closeEditModal();
    };

    get handlers() {
      return {
        onClick: this.onClick,
        onPointerDown: this.onPointerDown
      };
    }

    openEditModal = ({ jobItemId, jobItemContainerNode }) => {
      this.setState({
        isEditModalOpen: true,
        selectedJobItemId: jobItemId,
        selectedJobItemAnchorEl: jobItemContainerNode
      });
    };

    closeEditModal = () => {
      this.setState({
        isEditModalOpen: false,
        selectedJobItemAnchorEl: null
      });
    };

    shouldCancelClick(e) {
      const { clickItem } = this;
      if (!clickItem) return true;

      const deltaT = Date.now() - clickItem.origin.startTime;
      if (deltaT >= POINTER_CLICK_TIME_LIMIT_MS) return true;

      const pointerOffset = getPointerClientOffset(e);
      const deltaXY = subtractCoords(
        pointerOffset,
        clickItem.origin.pointerOffset
      );

      // noinspection JSSuspiciousNameCombination
      return (
        Math.abs(deltaXY.x) >= POINTER_CLICK_OFFSET_LIMIT ||
        Math.abs(deltaXY.y) >= POINTER_CLICK_OFFSET_LIMIT
      );
    }

    render() {
      const { props, handlers } = this;
      const {
        isEditModalOpen,
        selectedJobItemId,
        selectedJobItemAnchorEl
      } = this.state;

      return (
        <>
          <WrappedComponent {...{ ...props, ...handlers, isEditModalOpen }} />
          <JobItemPopover
            anchorEl={selectedJobItemAnchorEl}
            id={selectedJobItemId}
            open={isEditModalOpen}
            onClose={this.onCloseEditModal}
            readOnly={props.readOnly}
          />
        </>
      );
    }
  };
