import {
  EntityClass,
  JobGroup,
  JobGroupPeriodInterval,
  JobGroupTypeEnum,
} from "@streamtimefe/entities";
import type { FormatCurrencyOptions } from "@streamtimefe/utils";
import { formatCurrency } from "@streamtimefe/utils";
import { useCallback, useState } from "react";

import { PreferenceJobGroupDefaults } from "../../../../preferences";
import { useAuthOrganisation } from "../../../auth";
import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import {
  createUsePreviousSingleEntity,
  createUseSingleEntity,
} from "../../SingleEntityStore";
import { JobGroupStore } from "./JobGroup.store";

export const useJobGroupEntity = createUseEntityStoreEntity(
  EntityClass.JobGroup
);

export const getJobGroupEntity = createGetEntityStoreEntity(
  EntityClass.JobGroup
);

export const useJobGroup = createUseSingleEntity(JobGroupStore);

export const usePreviousJobGroup = createUsePreviousSingleEntity(JobGroupStore);

export function useJobGroupStore() {
  return JobGroupStore.useStore();
}

export function useJobGroupActions() {
  return JobGroupStore.useActions();
}

export function useNewJobGroup() {
  const organisation = useAuthOrganisation();

  return useState(() => {
    const jobGroup = JobGroup.create({ organisation });

    const preference = PreferenceJobGroupDefaults.get();

    if (preference.jobGroupType === JobGroupTypeEnum.RepeatingGroup) {
      jobGroup.jobGroupPeriodInterval = JobGroupPeriodInterval.create(
        preference.jobGroupPeriodIntervalId
      );
      jobGroup.totalJobGroupPeriodIntervals =
        preference.totalJobGroupPeriodIntervals;
    }

    return jobGroup;
  })[0];
}

// other selectors

export function useJobGroupCurrencyFormatter(
  options?: Omit<FormatCurrencyOptions, "symbol">
) {
  const symbol = useJobGroup((s) => s.currency.symbol);

  return useCallback(
    (value: number) => {
      return formatCurrency(value, {
        ...options,
        symbol,
      });
    },
    [symbol, options]
  );
}
