import { TEntityId } from "../../entities/Entity";
import { UserDisplayName } from ".";
import { primaryUserDisplayNameCss } from "./PrimaryUserDisplayName.css";

interface Props {
  userId: TEntityId;
  color?: string;
  fontSize?: number;
  isSelected?: boolean;
}

export function PrimaryUserDisplayName({
  userId,
  color = "black",
  fontSize = 18,
  isSelected,
}: Props) {
  return (
    <div
      className={primaryUserDisplayNameCss}
      style={{ color, fontSize }}
      data-selected={isSelected}
    >
      <UserDisplayName userId={userId} />
    </div>
  );
}
