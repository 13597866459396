import * as PropTypes from "prop-types";
import React from "react";
import { entityIdListType } from "../../../lib/types/entityTypes";
import RhombusCircleIcon from "../../elements/Icons/RhombusCircleIcon";
import Milestone from "./Milestone";

class JobMilestones extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired,
    jobMilestoneIds: entityIdListType.isRequired
  };

  render() {
    const { jobMilestoneIds } = this.props;
    return jobMilestoneIds.map(id => (
      <Milestone key={id} className="jobMilestone">
        <RhombusCircleIcon color="var(--color-gray-light)" />
      </Milestone>
    ));
  }
}

export default JobMilestones;
