import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { convertMinutesToTimeHM } from "../../../lib/time";
import { selectUnplannedTimeTotalMinutes } from "./ui/unplannedTimeTotalMinutes";

export const selectExtraHoursTrackedHours = createSelector(
  [selectUnplannedTimeTotalMinutes],
  (totalMinutes) => convertMinutesToTimeHM(totalMinutes)
);

export const useExtraHoursTrackedHours = () =>
  useSelector(selectExtraHoursTrackedHours);
