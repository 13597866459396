import { all, put, select, takeEvery } from "redux-saga/effects";

import {
  SCHEDULE_BLOCK_BULK_SAVE,
  SCHEDULE_BLOCK_REASSIGN,
  SCHEDULE_BLOCK_REASSIGN_MULTIPLE,
  SCHEDULE_BLOCK_REASSIGN_SAGA,
} from "../../../lib/constants";
import { getJobItemUserStatusId } from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectIdByJobItemIdUserId,
  selectJobItemUserById,
} from "../../selectors/jobItemUserSelectors";
import { selectBaseScheduleLoggedTimeByBlockKey } from "../../selectors/scheduleBlockSelectors";

function* reassignBlock(action) {
  const { blockKey, userId } = action.payload;

  const baseScheduleLoggedTime = yield select(
    selectBaseScheduleLoggedTimeByBlockKey,
    { blockKey }
  );

  try {
    const { jobItemId } = baseScheduleLoggedTime;
    let jobItemUserId = null;
    let jobItemUserStatusId = null;

    if (userId !== baseScheduleLoggedTime.userId) {
      jobItemUserId = yield select(selectIdByJobItemIdUserId, {
        id: jobItemId,
        userId,
      });

      if (jobItemUserId) {
        const jobItemUser = yield select(selectJobItemUserById, {
          id: jobItemUserId,
        });

        jobItemUserStatusId = getJobItemUserStatusId(jobItemUser);
      } else {
        jobItemUserId = null;
      }
    }

    yield put(
      createAction(SCHEDULE_BLOCK_REASSIGN, {
        ...action.payload,
        baseScheduleLoggedTime,
        jobItemUserId,
        jobItemUserStatusId,
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

function* watchReassignBlockSaga() {
  yield takeLatestBy(
    [SCHEDULE_BLOCK_REASSIGN_SAGA],
    reassignBlock,
    (action) => action.payload.blockKey
  );
}

function* reassignBlocks(action) {
  const { selectedBlockKeys } = action.payload;
  for (let i = 0; i < selectedBlockKeys.length; i++) {
    const blockKey = selectedBlockKeys[i];
    yield reassignBlock(
      createAction(SCHEDULE_BLOCK_REASSIGN_SAGA, {
        ...action.payload,
        blockKey,
        isBulkAction: true,
      })
    );
  }
  yield put(
    createAction(SCHEDULE_BLOCK_BULK_SAVE, {
      ...action.payload,
      sagaType: SCHEDULE_BLOCK_REASSIGN,
    })
  );
}

function* watchReassignMultipleBlocks() {
  yield takeEvery([SCHEDULE_BLOCK_REASSIGN_MULTIPLE], reassignBlocks);
}

export default function* watchReassignBlock() {
  yield all([watchReassignMultipleBlocks(), watchReassignBlockSaga()]);
}
