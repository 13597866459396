import clsx from "clsx";
import { useState } from "react";

import { Icon, IconSize, MdChevronRight } from "../../../components";
import { pluralise } from "../../../lib";
import { useSavedSegmentsEntityIdsByType } from "../../../stores";
import { UncategorisedSegment } from "../catergorised/Segment";
import { useSavedSegmentSidebarContext } from "../context/SavedSegmentSidebarContext";
import {
  allSegmentsHeaderCss,
  expanderIconCss,
  expanderIconOpenCss,
} from "./AllSegments.css";

export function AllSegments() {
  const { savedSegmentType, name } = useSavedSegmentSidebarContext();
  const segmentIds = useSavedSegmentsEntityIdsByType(savedSegmentType);

  const [showSegments, setShowSegments] = useState(true);

  function toggleOpen() {
    setShowSegments((s) => !s);
  }

  return (
    <>
      <div className={allSegmentsHeaderCss} onClick={toggleOpen}>
        <Icon
          icon={MdChevronRight}
          size={IconSize.XLarge}
          className={clsx(expanderIconCss, showSegments && expanderIconOpenCss)}
        />
        <span style={{ marginTop: 2 }}>All {pluralise(2, name)}</span>
      </div>
      {showSegments &&
        segmentIds.length > 0 &&
        segmentIds.map((segmentId) => (
          <UncategorisedSegment key={segmentId} segmentId={segmentId} />
        ))}
    </>
  );
}
