import clsx from "clsx";
import { cloneElement, type ReactElement } from "react";

import type { IconProps } from "../icon";
import * as styles from "./styles.css";

type AvatarType =
  | { url: string; alt: string; icon?: never }
  | { url?: never; alt?: string; icon: ReactElement<IconProps> };

export type AvatarProps = AvatarType & {
  size?: "mini" | "small" | "medium" | "large";
  noticationBadge?: number;
  className?: string;
};

const iconSizes: Record<string, IconProps["size"]> = {
  mini: 12,
  small: 16,
  medium: 20,
  large: 64,
};

export function Avatar({
  url,
  icon,
  size = "medium",
  alt,
  noticationBadge,
  className,
}: AvatarProps) {
  const iconElement = icon && cloneElement(icon, { size: iconSizes[size] });

  return (
    <span className={clsx(styles.avatar({ size }), className)}>
      {url ? <img src={url} alt={alt} className={styles.image} /> : iconElement}
      {noticationBadge && (
        <span className={styles.notifcationBadge({ size })}>
          {noticationBadge}
        </span>
      )}
    </span>
  );
}
