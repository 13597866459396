import isError from "lodash-es/isError";
import { call, select, takeLatest } from "redux-saga/effects";
import { sendLineItemMergeCount } from "../../../lib/WebAppAPI/commercialDocuments";
import { QUOTE_LINE_ITEM_SET_IS_MERGING } from "../../../lib/constants";
import { getQuoteId } from "../../../lib/entities/quoteLineItemEntity";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectCountIsMergingQuoteLineItemsByQuoteId,
  selectQuoteLineItemById
} from "../../selectors/quoteLineItemSelectors";

function* sendMergeItemCount(action) {
  try {
    const { id } = action.payload;

    const quoteLineItem = yield select(selectQuoteLineItemById, { id });

    const lineItemMergeCount = yield select(
      selectCountIsMergingQuoteLineItemsByQuoteId,
      { quoteId: getQuoteId(quoteLineItem) }
    );

    yield call(sendLineItemMergeCount, lineItemMergeCount);
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchSetIsMerging() {
  yield takeLatest(QUOTE_LINE_ITEM_SET_IS_MERGING, sendMergeItemCount);
}
