import { isNil } from "lodash-es";
import numeral from "numeral";
import { Tooltip } from "st-shared/components";
import { costingMethodObj } from "st-shared/entities";
import { formatForeignCurrency, NumberFormats } from "st-shared/lib";
import { VarianceIcon } from "st-shared/module";
import { useCustomerCurrency } from "st-shared/stores";

import { getRateCardId, isArchived } from "../../../../lib/entities/jobEntity";
import {
  getMasterJobItemId,
  getSellRate,
} from "../../../../lib/entities/jobItemEntity";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobItem } from "../../../../redux/selectors/jobItem";
import { useRateCard } from "../../../../redux/selectors/rateCard";
import { useMasterJobItemRate } from "../../../../state/entities/itemRate/selectors/selectMasterJobItemRate";
import { useMasterJobItem } from "../../../../state/entities/masterJobItem/selectors/selectMasterJobItem";
import { useJobId } from "../../context/JobIdContext";
import { useJobItemId } from "../../context/JobItemIdContext";
import { tooltipTitleCss, varianceIconAbsoluteCss } from "./styles.css";

export function MasterItemVariance() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItem = useJobItem(jobItemId);
  const job = useJob(jobId);
  const masterJobItemId = getMasterJobItemId(jobItem);
  const masterJobItem = useMasterJobItem(masterJobItemId);
  const itemRate = useMasterJobItemRate(masterJobItemId, getRateCardId(job));
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const formattedRate = formatForeignCurrency(customerCurrency, {
    value: itemRate,
    hideLongSymbols: true,
    currency: jobCurrency,
  });
  const rateCard = useRateCard(getRateCardId(job));
  const sellRate = getSellRate(jobItem);

  if (
    isArchived(job) ||
    !costingMethodObj(jobItem.costingMethod).isItem() ||
    itemRate === 0 ||
    sellRate === 0 ||
    isNil(masterJobItem)
  ) {
    return null;
  }

  const variance =
    numeral(sellRate)
      .subtract(numeral(itemRate).value())
      .divide(numeral(itemRate).value())
      .value() || 0;

  if (variance === 0) return null;

  const formattedVariance = numeral(Math.abs(variance)).format(
    NumberFormats.Percentage
  );

  const down = variance < 0;

  return (
    <Tooltip
      title={
        <div className={tooltipTitleCss}>
          The rate you’ve set is <strong>{formattedVariance}</strong>{" "}
          {down ? "lower" : "higher"} than the <strong>{formattedRate}</strong>{" "}
          rate set on the {rateCard.name} rate card
        </div>
      }
      placement="top"
    >
      <div className={varianceIconAbsoluteCss}>
        <VarianceIcon direction={down ? "down" : "up"} size={9} />
      </div>
    </Tooltip>
  );
}
