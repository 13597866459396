import { get, isEmpty } from "lodash-es";

import {
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT,
  EMPTY_ARRAY,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEMS,
  JOB_ITEM_DELETE,
  JOB_ITEM_DELETE_ERROR,
  JOB_ITEM_DRAG,
  JOB_ITEM_DRAG_CANCEL,
  JOB_ITEM_DRAG_END,
  JOB_ITEM_EDIT_ITEM_CARD,
  JOB_ITEM_NEW_ITEM_DELETE,
  JOB_ITEM_PAINT,
  JOB_ITEM_PAINT_CANCEL,
  JOB_ITEM_PAINT_END,
  JOB_ITEM_PAINT_START,
  JOB_ITEM_RESIZE,
  JOB_ITEM_RESIZE_CANCEL,
  JOB_ITEM_RESIZE_END,
  JOB_ITEM_REVERT,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVE_ERROR,
  JOB_ITEM_SAVE_ITEM_CARD,
  JOB_ITEM_SAVED,
  SCHEDULE_JOB_BAR_DRAG,
  SCHEDULE_JOB_BAR_DRAG_CANCEL,
  SCHEDULE_JOB_SHIFT_DURATION_ERROR,
} from "../../../../lib/constants";
import {
  differenceInCalendarDays,
  maxDate,
  minDate,
} from "../../../../lib/dates";
import {
  getJobId,
  getJobIdPhaseIdPath,
  getJobPhaseId,
  getUpcomingDates,
  isDeleted,
  moveDays,
  resizeLeftDays,
  resizeRightDays,
} from "../../../../lib/entities/jobItemEntity";
import byIdReducer from "../../../helpers/byIdReducer";
import createEntityIndexedArrayReducer from "../../../helpers/createEntityIndexedArrayReducer";
import createEntityVariationReducer from "../../../helpers/createEntityVariationReducer";
import createReducer from "../../../helpers/createReducer";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import jobItemOrderByJobIdPhaseIdReducer from "./jobItemOrderByJobIdPhaseIdReducer";
import jobPhaseIdsByJobIdReducer from "./jobPhaseIdsByJobIdReducer";
import phaseStatusByJobPhaseIdReducer from "./phaseStatusByJobPhaseIdReducer";

const idsByJobIdReducer = createEntityIndexedArrayReducer((entity) =>
  getJobId(entity)
);

const idsByJobPhaseIdReducer = createEntityIndexedArrayReducer((entity) =>
  getJobPhaseId(entity)
);

const idsByJobIdJobPhaseIdReducer = createEntityIndexedArrayReducer((entity) =>
  getJobIdPhaseIdPath(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  const nextState = {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    idsByJobId: idsByJobIdReducer(state.idsByJobId, changedEntities),
    idsByJobPhaseId: idsByJobPhaseIdReducer(
      state.idsByJobPhaseId,
      changedEntities
    ),
    idsByJobIdJobPhaseId: idsByJobIdJobPhaseIdReducer(
      state.idsByJobIdJobPhaseId,
      changedEntities
    ),
    upcomingDatesById: createEntityVariationReducer(
      state.upcomingDatesById,
      changedEntities,
      getUpcomingDates
    ),
  };

  Object.assign(
    nextState,
    jobPhaseIdsByJobIdReducer(state, nextState, changedEntities)
  );

  Object.assign(
    nextState,
    jobItemOrderByJobIdPhaseIdReducer(state, nextState, changedEntities)
  );

  Object.assign(
    nextState,
    phaseStatusByJobPhaseIdReducer(state, nextState, changedEntities)
  );

  return nextState;
};

const receiveEntitiesReducer = (state, action) => {
  const changedEntities = parseEntityPayload(
    state,
    action.payload[ENTITY_NAME_JOB_ITEMS],
    isDeleted
  );
  return reduceChangedEntities(state, changedEntities);
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_JOB_ITEMS) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const dragJobItemReducer = (state, action) => {
  const { jobItemId, daysMoved } = action.payload;
  const prevEntity = state.byId[jobItemId];
  const newEntity = moveDays(prevEntity, daysMoved);

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity,
    },
  ]);
};

const undoDragJobItemReducer = (state, action) => {
  const { jobItemId, date, origin } = action.payload;
  const prevEntity = state.byId[jobItemId];
  const daysMoved = differenceInCalendarDays(date, origin.date);
  const newEntity = moveDays(prevEntity, -daysMoved);

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity,
    },
  ]);
};

const resizeJobItemReducer = (state, action) => {
  const { jobItemId, days, resizeType } = action.payload;
  const prevEntity = state.byId[jobItemId];

  switch (resizeType) {
    case DATA_VALUE_HANDLE_RIGHT:
      return reduceChangedEntities(state, [
        {
          prevEntity,
          newEntity: resizeRightDays(prevEntity, days),
        },
      ]);
    case DATA_VALUE_HANDLE_LEFT:
    default:
      return reduceChangedEntities(state, [
        {
          prevEntity,
          newEntity: resizeLeftDays(prevEntity, days),
        },
      ]);
  }
};

const editJobItemReducer = (state, action) => {
  const { jobItemId, jobItem } = action.payload;

  return reduceChangedEntities(state, [
    {
      prevEntity: state.byId[jobItemId],
      newEntity: jobItem,
    },
  ]);
};

const deleteJobItemReducer = (state, action) => {
  const { jobItem } = action.payload;
  const prevEntity = state.byId[jobItem.id];

  return reduceChangedEntities(state, [
    {
      prevEntity,
    },
  ]);
};

const undoResizeJobItemReducer = (state, action) => {
  const { jobItemId, origin, resizeType } = action.payload;
  const prevEntity = state.byId[jobItemId];

  switch (resizeType) {
    case DATA_VALUE_HANDLE_RIGHT:
      return reduceChangedEntities(state, [
        {
          prevEntity,
          newEntity: resizeRightDays(prevEntity, origin.days),
        },
      ]);
    case DATA_VALUE_HANDLE_LEFT:
    default:
      return reduceChangedEntities(state, [
        {
          prevEntity,
          newEntity: resizeLeftDays(prevEntity, origin.days),
        },
      ]);
  }
};

const undoSaveEditJobItemReducer = (state, action) => {
  const { jobItemId, prevJobItem } = action.payload;
  const prevEntity = state.byId[jobItemId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: prevJobItem,
    },
  ]);
};

const saveJobItemErrorReducer = (state, action) => {
  if (action.payload.sagaType === JOB_ITEM_DRAG_END)
    return undoDragJobItemReducer(state, action);

  if (action.payload.sagaType === JOB_ITEM_RESIZE_END)
    return undoResizeJobItemReducer(state, action);

  if (
    action.payload.sagaType === JOB_ITEM_SAVE_EDIT ||
    action.payload.sagaType === JOB_ITEM_SAVE_ITEM_CARD
  )
    return undoSaveEditJobItemReducer(state, action);

  if (action.payload.sagaType === JOB_ITEM_DRAG_END)
    return undoDragJobItemReducer(state, action);

  if (action.payload.sagaType === JOB_ITEM_RESIZE_END)
    return undoResizeJobItemReducer(state, action);

  if (action.payload.sagaType === JOB_ITEM_PAINT_END)
    return undoPaintJobItemReducer(state, action);

  return state;
};

const undoDeleteJobReducer = (state, action) => {
  const { jobItemId, jobItem } = action.payload;
  const prevEntity = state.byId[jobItemId];

  return reduceChangedEntities(state, [
    {
      prevEntity,
      newEntity: jobItem,
    },
  ]);
};

const dragJobBarReducer = (state, action) => {
  const { jobId, daysMoved } = action.payload;
  const jobItemIds = get(state, `idsByJobId.${jobId}`, EMPTY_ARRAY);

  const changedEntities = jobItemIds.map((jobItemId) => {
    const prevEntity = get(state, `byId.${jobItemId}`, null);
    return {
      prevEntity,
      newEntity: moveDays(prevEntity, daysMoved),
    };
  });

  return reduceChangedEntities(state, changedEntities);
};

const undoDragJobBarReducer = (state, action) => {
  const { jobId, daysMoved, hasChanged } = action.payload;

  if (!hasChanged) return state;

  const jobItemIds = get(state, `idsByJobId.${jobId}`, EMPTY_ARRAY);

  const changedEntities = jobItemIds.map((jobItemId) => {
    const prevEntity = get(state, `byId.${jobItemId}`, null);
    return {
      prevEntity,
      newEntity: moveDays(prevEntity, -daysMoved),
    };
  });

  return reduceChangedEntities(state, changedEntities);
};

const paintJobItemReducer = (state, action) => {
  const { jobItemId, startDate, endDate } = action.payload;

  const prevEntity = state.byId[jobItemId];

  const earliestStartDate = prevEntity.earliestStartDate
    ? minDate(prevEntity.earliestStartDate, startDate)
    : startDate;

  const latestEndDate = prevEntity.latestEndDate
    ? maxDate(prevEntity.latestEndDate, endDate)
    : endDate;

  const changedEntities = [
    {
      prevEntity,
      newEntity: {
        ...prevEntity,
        estimatedStartDate: startDate,
        estimatedEndDate: endDate,
        earliestStartDate,
        latestEndDate,
      },
    },
  ];

  return reduceChangedEntities(state, changedEntities);
};

const undoPaintJobItemReducer = (state, action) => {
  const { jobItemId, hasChanged } = action.payload;

  if (!hasChanged) return state;

  const prevEntity = state.byId[jobItemId];
  const changedEntities = [
    {
      prevEntity,
      newEntity: {
        ...prevEntity,
        estimatedStartDate: null,
        estimatedEndDate: null,
      },
    },
  ];

  return reduceChangedEntities(state, changedEntities);
};

const saveJobItemSuccessReducer = (state, action) => {
  const { jobItemId } = action.payload;

  if (jobItemId >= 0) return state;

  const changedEntities = [
    {
      prevEntity: state.byId[jobItemId],
    },
  ];

  return reduceChangedEntities(state, changedEntities);
};

const newItemDeleteReducer = (state, action) => {
  const { jobItem } = action.payload;

  const changedEntities = [
    {
      prevEntity: state.byId[jobItem.id],
    },
  ];

  return reduceChangedEntities(state, changedEntities);
};

const revertJobItemReducer = (state, action) => {
  const { jobItem } = action.payload;

  const changedEntities = [
    {
      prevEntity: state.byId[jobItem.id],
      newEntity: jobItem,
    },
  ];

  return reduceChangedEntities(state, changedEntities);
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [JOB_ITEM_DRAG]: dragJobItemReducer,
    [JOB_ITEM_RESIZE]: resizeJobItemReducer,
    [JOB_ITEM_DRAG_CANCEL]: undoDragJobItemReducer,
    [JOB_ITEM_RESIZE_CANCEL]: undoResizeJobItemReducer,
    [JOB_ITEM_DELETE]: deleteJobItemReducer,
    [JOB_ITEM_DELETE_ERROR]: undoDeleteJobReducer,
    [JOB_ITEM_PAINT]: paintJobItemReducer,
    [JOB_ITEM_PAINT_START]: paintJobItemReducer,
    [JOB_ITEM_PAINT_CANCEL]: undoPaintJobItemReducer,
    [SCHEDULE_JOB_BAR_DRAG]: dragJobBarReducer,
    [SCHEDULE_JOB_BAR_DRAG_CANCEL]: undoDragJobBarReducer,
    [SCHEDULE_JOB_SHIFT_DURATION_ERROR]: undoDragJobBarReducer,
    [JOB_ITEM_SAVED]: saveJobItemSuccessReducer,
    [JOB_ITEM_SAVE_ERROR]: saveJobItemErrorReducer,
    [JOB_ITEM_SAVE_EDIT]: editJobItemReducer,
    [JOB_ITEM_EDIT_ITEM_CARD]: editJobItemReducer,
    [JOB_ITEM_SAVE_ITEM_CARD]: editJobItemReducer,
    [JOB_ITEM_NEW_ITEM_DELETE]: newItemDeleteReducer,
    [JOB_ITEM_REVERT]: revertJobItemReducer,
  }
);
