import { useEffect } from "react";

export function useUnload(showMessage) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const onUnload = (e) => {
      if (showMessage) {
        e.preventDefault();
        e.returnValue = "Are you sure?";
      }
    };

    if (import.meta.env.PROD) {
      window.addEventListener("beforeunload", onUnload);
      return () => window.removeEventListener("beforeunload", onUnload);
    }
  }, [showMessage]);
}
