import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const DragIndicatorIcon = props => (
  <SvgIcon {...props} viewBox="0 0 18 6">
    <rect x="2" y="1" width="2" height="2" />
    <rect x="2" y="5" width="2" height="2" />
    <rect x="10" y="1" width="2" height="2" />
    <rect x="10" y="5" width="2" height="2" />
    <rect x="6" y="1" width="2" height="2" />
    <rect x="6" y="5" width="2" height="2" />
    <rect x="14" y="1" width="2" height="2" />
    <rect x="14" y="5" width="2" height="2" />
  </SvgIcon>
);

export default StyledIcon(DragIndicatorIcon);
