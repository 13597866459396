import { produce } from "immer";

import type { TEntityId } from "../Entity";
import type { TEntityClass } from "../EntityClass";
import type { TModelSet, TSyncKeyReplacementSet } from "./ModelSet";

export function addSyncKeyReplacement<
  T extends TEntityClass | undefined = undefined,
>(
  modelSet: TModelSet<T>,
  entityClass: TEntityClass,
  syncKey: string,
  entityId: TEntityId
) {
  return produce(modelSet, (s) => {
    s.__syncKeyReplacementSet = s.__syncKeyReplacementSet ?? {};
    s.__syncKeyReplacementSet[entityClass] =
      s.__syncKeyReplacementSet[entityClass] ?? {};
    s.__syncKeyReplacementSet[entityClass][entityId] = syncKey;
  });
}

export function createSyncKeyReplacement(
  entityClass: TEntityClass,
  syncKey: string,
  entityId: TEntityId
): TSyncKeyReplacementSet {
  return {
    [entityClass]: {
      [entityId]: syncKey,
    },
  };
}
