import styled from "styled-components";

const DetailTableFieldButton = styled.div`
  min-width: 90px;
  color: black;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  line-height: 1;
  padding: 3px 0 0 4px;
  &:hover {
    color: var(--color-gray);
    cursor: pointer;
  }
`;

export default DetailTableFieldButton;
