import { AxiosError, AxiosResponse } from "axios";

import { ApiException } from "../exception";

export function catchResponse<T>(promise: Promise<AxiosResponse<T>>) {
  return promise.catch((error: AxiosError) => {
    if (error.response)
      switch (error.response.status) {
        case 400:
        case 401:
        case 422:
        case 403:
        case 500:
          throw new ApiException(error.response.data as string, error.response);
        case 404:
          throw new ApiException("404 resource not found", error.response);
        default:
          throw error;
      }

    throw error;
  });
}
