import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataIsBillable,
  getMetaDataJobName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobIsBillableChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" marked job "}
    <b>{getMetaDataJobName(activityEntry)}</b>
    {" as "}
    {getMetaDataIsBillable(activityEntry) ? "billable" : "non-billable"}
  </SystemMessage>
);

JobIsBillableChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobIsBillableChanged;
