import * as PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { IconButton } from "../elements/Button";
import ExpandMoreIcon from "../elements/Icons/ExpandMoreIcon";
import { ICON_SIZE } from "./StyledIcon";

const Expander = forwardRef(
  ({ isExpanded, onToggle, start, end, size, ...props }, ref) => {
    return (
      <IconButton ref={ref} onClick={onToggle} {...props}>
        <ExpandMoreIcon
          start={start}
          end={end}
          className={isExpanded ? "expanded" : ""}
          size={size}
        />
      </IconButton>
    );
  }
);

Expander.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  start: PropTypes.number,
  end: PropTypes.number,
  size: PropTypes.number
};

Expander.defaultProps = {
  onToggle: null,
  start: 0,
  end: 180,
  size: ICON_SIZE.X_LARGE
};

export default styled(Expander)`
  color: var(--color-gray);
`;
