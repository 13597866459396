import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectBlockContainerHeightByUserIdJobIdNoItem } from "../../../redux/selectors/scheduleSelectors";
import UserIconName from "../../modules/User/UserIconName";
import ScheduleGroupTotals from "./ScheduleGroupTotals";
import { ScheduleGroupUnderline } from "./ScheduleGroupUnderline";

const mapState = (state, props) => ({
  height: selectBlockContainerHeightByUserIdJobIdNoItem(state, {
    id: props.userId,
    jobId: props.id
  })
});

const mapDispatch = null;

class ScheduleExtraHoursUserGroup extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    userId: entityIdType.isRequired,
    height: PropTypes.number.isRequired
  };

  static defaultProps = null;

  render() {
    const { userId, height } = this.props;

    return (
      <Wrapper style={{ height }} flex>
        <UserIconName id={userId} fontSize={14} style={{ marginTop: 10 }} />
        <ScheduleGroupTotals />
      </Wrapper>
    );
  }
}

export default connect(mapState, mapDispatch)(ScheduleExtraHoursUserGroup);

export const Wrapper = styled(ScheduleGroupUnderline)`
  background: white;
  overflow: hidden;
  padding-left: 15px;
  align-items: flex-start;
  &:last-child {
    border-bottom: none;
  }
`;
