import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { selectStateScheduleUi } from "./selectStateScheduleUi";

export const selectScheduleUiPending = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.pending
);

export function useScheduleUiPending() {
  return useSelector(selectScheduleUiPending);
}

export const selectScheduleUiError = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.error
);

export function useScheduleUiError() {
  return useSelector(selectScheduleUiError);
}

export const selectScheduleUiIsSearching = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.isSearching
);

export function useScheduleUiIsSearching() {
  return useSelector(selectScheduleUiIsSearching);
}

export const selectScheduleUiIsFetching = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.isFetching
);

export function useScheduleUiIsFetching() {
  return useSelector(selectScheduleUiIsFetching);
}

export const selectScheduleUiFetchedLoggedTimes = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.fetchedLoggedTimes
);

export function useScheduleUiFetchedLoggedTimes() {
  return useSelector(selectScheduleUiFetchedLoggedTimes);
}

export const selectScheduleUiFetchingLoggedTimes = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.fetchingLoggedTimes
);

export function useScheduleUiFetchingLoggedTimes() {
  return useSelector(selectScheduleUiFetchingLoggedTimes);
}

export const selectScheduleUiRequestedLoggedTimes = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.requestedLoggedTimes
);

export function useScheduleUiRequestedLoggedTimes() {
  return useSelector(selectScheduleUiRequestedLoggedTimes);
}

export const selectScheduleUiActiveBlockKey = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.activeBlockKey
);

export function useScheduleUiActiveBlockKey() {
  return useSelector(selectScheduleUiActiveBlockKey);
}

export const selectScheduleUiSplittingBlockKey = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.splittingBlockKey
);

export function useScheduleUiSplittingBlockKey() {
  return useSelector(selectScheduleUiSplittingBlockKey);
}

export const selectScheduleUiSplitBlockDays = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.splitBlockDays
);

export function useScheduleUiSplitBlockDays() {
  return useSelector(selectScheduleUiSplitBlockDays);
}

export const selectScheduleUiViewMode = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.viewMode
);

export function useScheduleUiViewMode() {
  return useSelector(selectScheduleUiViewMode);
}

export const selectScheduleUiAvailabilityView = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.availabilityView
);

export function useScheduleUiAvailabilityView() {
  return useSelector(selectScheduleUiAvailabilityView);
}

export const selectScheduleUiViewPausedLoggedTimes = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.viewPausedLoggedTimes
);

export function useScheduleUiViewPausedLoggedTimes() {
  return useSelector(selectScheduleUiViewPausedLoggedTimes);
}

export const selectScheduleUiFilteredTeamMembers = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.filteredTeamMembers
);

export function useScheduleUiFilteredTeamMembers() {
  return useSelector(selectScheduleUiFilteredTeamMembers);
}

export const selectScheduleUiFilterCount = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.filterCount
);

export function useScheduleUiFilterCount() {
  return useSelector(selectScheduleUiFilterCount);
}

export const selectScheduleUiHasNoActiveJobs = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.hasNoActiveJobs && !scheduleUi.isSearching
);

export function useScheduleUiHasNoActiveJobs() {
  return useSelector(selectScheduleUiHasNoActiveJobs);
}

export const selectScheduleUiTodoVisibilityOptions = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.todoVisibilityOptions
);

export function useScheduleUiTodoVisibilityOptions() {
  return useSelector(selectScheduleUiTodoVisibilityOptions);
}

export const selectScheduleUiScheduleTotalsMode = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.scheduleTotalsMode
);

export function useScheduleUiScheduleTotalsMode() {
  return useSelector(selectScheduleUiScheduleTotalsMode);
}

export const selectScheduleUiZoomLevel = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.zoomLevel
);

export function useScheduleUiZoomLevel() {
  return useSelector(selectScheduleUiZoomLevel);
}

export const selectScheduleUiSearchQuery = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.searchQuery
);

export function useScheduleUiSearchQuery() {
  return useSelector(selectScheduleUiSearchQuery);
}

export const selectScheduleSidebarHover = createSelector(
  [selectStateScheduleUi],
  (scheduleUi) => scheduleUi.sidebarHover
);

export function useScheduleSidebarHover() {
  return useSelector(selectScheduleSidebarHover);
}
