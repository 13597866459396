import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";
import clsx from "clsx";
import type { ChangeEvent } from "react";
import { useState } from "react";
import {
  IconButton,
  IconSize,
  Input,
  MdClear,
  MdDelete,
} from "st-shared/components";
import { convertMinutesToHours, convertMinutesToTimeHM } from "st-shared/lib";
import { UserIconName } from "st-shared/module";
import { theme } from "st-shared/theme";

import {
  clearIconCss,
  containerCss,
  deleteIconCss,
  inputContainerCss,
  inputCss,
  rightContainerCss,
  userNameCss,
} from "./AssignUserOption.css";

interface Props {
  userId: number;
  minutes: number;
  removeUser: (userId: number) => void;
  updateMinutes: (userId: number, minutes: number) => void;
}

export function AssignUserOption({
  userId,
  minutes,
  removeUser,
  updateMinutes,
}: Props) {
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const value = focused ? inputValue : convertMinutesToTimeHM(minutes);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    setInputValue(newValue);
    updateMinutes(userId, convertFlexibleDurationToMinutes(newValue) || 0);
  }

  function onBlur() {
    setFocused(false);
  }

  function onFocus() {
    setInputValue(convertMinutesToHours(minutes));
    setFocused(true);
  }

  return (
    <div className={containerCss}>
      <UserIconName
        id={userId}
        size={26}
        showRole
        fontSize={12}
        color={theme.color.charcoal}
        className={userNameCss}
      />
      <div className={rightContainerCss}>
        {!focused && (
          <IconButton
            className={clsx(deleteIconCss)}
            tabIndex={-1}
            onClick={() => removeUser(userId)}
            iconProps={{ icon: MdDelete }}
          />
        )}
        <div className={inputContainerCss}>
          <Input
            as="time"
            className={inputCss}
            value={value}
            placeholder={"0h"}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {!focused && minutes > 0 && (
            <IconButton
              className={clsx(clearIconCss)}
              tabIndex={-1}
              onClick={() => updateMinutes(userId, 0)}
              iconProps={{ icon: MdClear, size: IconSize.Medium }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
