import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { LOGGED_EXPENSE_FETCH_REQUEST } from "../../lib/constants";
import {
  getPurchaseOrderId,
  isPurchaseOrder,
} from "../../lib/entities/loggedExpenseEntity";
import createAction from "../../redux/helpers/createAction";
import {
  selectLoggedExpenseByLoggedExpenseId,
  selectLoggedExpenseUi,
} from "../../redux/selectors/loggedExpenseSelectors";
import LoadingSpinner from "../elements/LoadingSpinner";
import PageWrapper from "../elements/PageWrapper";
import BranchDetails from "../modules/CommercialDocuments/BranchDetails";
import PurchaseOrderContextProvider from "../modules/CommercialDocuments/CommercialDocumentContext/PurchaseOrderContextProvider";
import DetailTable from "../modules/CommercialDocuments/DetailTable";
import DocumentIntro from "../modules/CommercialDocuments/DocumentIntro";
import DocumentJobNumberRow from "../modules/CommercialDocuments/DocumentJobNumberRow";
import DocumentName from "../modules/CommercialDocuments/DocumentName";
import DocumentTerms from "../modules/CommercialDocuments/DocumentTerms";
import { useCommercialDocumentEditing } from "../modules/CommercialDocuments/hooks/useCommercialDocumentEditing";
import LanguageOption from "../modules/CommercialDocuments/LanguageOption";
import DocumentLabel from "../modules/CommercialDocuments/Layout/DocumentLabel";
import PageBody from "../modules/CommercialDocuments/Layout/PageBody";
import PageContent from "../modules/CommercialDocuments/Layout/PageContent";
import Section from "../modules/CommercialDocuments/Layout/Section";
import UndoMerge from "../modules/CommercialDocuments/UndoMerge";
import { useParamEntityId } from "../router/useParamEntityId";
import PurchaseOrderDateRow from "./PurchaseOrderDateRow";
import PurchaseOrderLineItems from "./PurchaseOrderLineItems";
import PurchaseOrderNumberRow from "./PurchaseOrderNumberRow";
import PurchaseOrderReferenceRow from "./PurchaseOrderReferenceRow";
import PurchaseOrderRequiredDateRow from "./PurchaseOrderRequiredDateRow";
import PurchaseOrderTotals from "./PurchaseOrderTotals";
import SupplierDetails from "./SupplierDetails";

function PurchaseOrderPage() {
  const entityId = useParamEntityId();

  const pending = useSelector(selectLoggedExpenseUi).pending;
  const loggedExpense = useSelector((state) =>
    selectLoggedExpenseByLoggedExpenseId(state, { loggedExpenseId: entityId })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      createAction(LOGGED_EXPENSE_FETCH_REQUEST, { loggedExpenseId: entityId })
    );
  }, [entityId]);

  const { focusWithinProps } = useCommercialDocumentEditing();

  return (
    <PageWrapper {...focusWithinProps}>
      {pending && <LoadingSpinner />}
      {!pending && loggedExpense && isPurchaseOrder(loggedExpense) && (
        <PageBody>
          <PageContent>
            <PurchaseOrderContextProvider key={entityId} id={entityId}>
              <UndoMerge />
              <PurchaseOrderSection>
                <DocumentLabel>
                  <LanguageOption optionKey="purchaseOrder" />
                </DocumentLabel>
              </PurchaseOrderSection>
              <PurchaseOrderSection justify="space-between">
                <BranchDetails />
                <SupplierDetails />
              </PurchaseOrderSection>
              <PurchaseOrderSection>
                <DetailTable>
                  <DocumentJobNumberRow />
                  <PurchaseOrderNumberRow />
                  <PurchaseOrderDateRow />
                  <PurchaseOrderRequiredDateRow />
                  <PurchaseOrderReferenceRow />
                </DetailTable>
              </PurchaseOrderSection>
              <PurchaseOrderSection direction="column">
                <DocumentName />
                <DocumentIntro />
              </PurchaseOrderSection>
              <PurchaseOrderLineItems />
              <PurchaseOrderTotals
                purchaseOrderId={getPurchaseOrderId(loggedExpense)}
              />
              <DocumentTerms />
            </PurchaseOrderContextProvider>
          </PageContent>
        </PageBody>
      )}
    </PageWrapper>
  );
}

export default PurchaseOrderPage;

const PurchaseOrderSection = styled(Section)`
  width: calc(100% - 240px);
`;
