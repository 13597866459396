import styled from "styled-components";
import Flex from "../elements/Flex";

export const DateCell = styled(Flex)`
  width: 60px;
  height: 100%;
  font-size: 12px;
  color: var(--color-charcoal);
  border-left: var(--border-thin-bright);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
