import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../../lib/constants";
import { JOB_COST_VS_SELL_MODE } from "../../../../lib/constants/jobDetails";
import { jobPageOpenLoggedItemsModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJumbotronUsedPlannedTimeDollarsDisplay } from "../../../../redux/selectors/jobDetails/jumbotronUsedPlannedTimeDollarsDisplay";
import { useJobDetailsCostVsSellMode } from "../../../../redux/selectors/jobDetails/ui/costVsSellMode";
import { useJobUsedPlannedTimeDollarsSummary } from "../../../../redux/selectors/jobDetails/ui/selectJobUsedPlannedTimeDollarsSummary";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import { JumbotronTooltipTable, LegendDisplay, TooltipTitle } from "../styles";

const JumbotronTimeDollars = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const jobCurrency = useJobCurrency(jobId);
  const costVsSellMode = useJobDetailsCostVsSellMode();
  const customerCurrency = useCustomerCurrency();
  const usedPlannedTimeDollarsDisplay =
    useJumbotronUsedPlannedTimeDollarsDisplay(jobId);
  const [usersSummary, nonBillableSummary] =
    useJobUsedPlannedTimeDollarsSummary(jobId);

  const tooltipsContents = (
    <JumbotronTooltipTable>
      <tbody>
        <tr>
          <td></td>
          <TooltipTitle>
            Used{" "}
            {costVsSellMode === JOB_COST_VS_SELL_MODE.COST ? "Cost" : "Sell"}
            <LegendDisplay $color="var(--color-blue-bright)"></LegendDisplay>
          </TooltipTitle>
          <TooltipTitle>
            Planned
            <LegendDisplay $color="var(--color-gray)"></LegendDisplay>
          </TooltipTitle>
        </tr>
        <tr className="divider">
          <td colSpan="3"></td>
        </tr>
        <tr className="dividerBlank">
          <td colSpan="3"></td>
        </tr>
        <tr>
          <TooltipTitle>
            Total {nonBillableSummary ? "Billable" : ""}
          </TooltipTitle>
          <TooltipTitle $padded>
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? usedPlannedTimeDollarsDisplay.jobCurrencyTotalLoggedTimeCostExTax
                  : usedPlannedTimeDollarsDisplay.jobCurrencyTotalLoggedTimeExTax,
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <TooltipTitle $padded>
            {formatForeignCurrency(customerCurrency, {
              value:
                usedPlannedTimeDollarsDisplay.jobCurrencyTotalPlannedTimeExTax,
              currency: jobCurrency,
            })}
          </TooltipTitle>
        </tr>
        {usersSummary.map((data) => (
          <tr key={data.userId}>
            <td>{data.userDisplayName}</td>
            <td>
              {formatForeignCurrency(customerCurrency, {
                value:
                  costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                    ? data.totalLoggedCostDollars
                    : data.totalLoggedDollars,
                currency: jobCurrency,
              })}
            </td>
            <td>
              {formatForeignCurrency(customerCurrency, {
                value: data.totalPlannedDollars,
                currency: jobCurrency,
              })}
            </td>
          </tr>
        ))}

        {nonBillableSummary && (
          <>
            <tr className="dividerBlank">
              <td colSpan="3"></td>
            </tr>
            <tr className="divider">
              <td colSpan="3"></td>
            </tr>
            <tr className="dividerBlank">
              <td colSpan="3"></td>
            </tr>
            <tr className="nonBillable">
              <td>Total Non-Billable</td>
              <td>
                {formatForeignCurrency(customerCurrency, {
                  value:
                    costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                      ? nonBillableSummary.totalLoggedCostDollars
                      : nonBillableSummary.totalLoggedDollars,
                  currency: jobCurrency,
                })}
              </td>
              <td>
                {formatForeignCurrency(customerCurrency, {
                  value: nonBillableSummary.totalPlannedDollars,
                  currency: jobCurrency,
                })}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </JumbotronTooltipTable>
  );

  return (
    <Tooltip
      title={tooltipsContents}
      enabled={usersSummary.length > 0}
      placement="right"
      className="time"
    >
      <JumbotronProgressField
        name={`TIME (${jobCurrency.symbol})`}
        onClick={() => jobPageOpenLoggedItemsModal(job, "time")}
        progressColor="var(--color-blue-bright)"
        progressPercentage={getProgressPercentage(
          usedPlannedTimeDollarsDisplay,
          costVsSellMode
        )}
      >
        <JumbotronProgressFieldValue>
          <span className="bold">
            {formatForeignCurrency(customerCurrency, {
              value:
                costVsSellMode === JOB_COST_VS_SELL_MODE.COST
                  ? usedPlannedTimeDollarsDisplay.jobCurrencyTotalLoggedTimeCostExTax
                  : usedPlannedTimeDollarsDisplay.jobCurrencyTotalLoggedTimeExTax,
              currency: jobCurrency,
              format: NUMBER_FORMAT_NO_DECIMAL,
            })}
          </span>

          {usedPlannedTimeDollarsDisplay.jobCurrencyTotalPlannedTimeExTax >
            0 && (
            <span>
              {" "}
              /{" "}
              {formatForeignCurrency(customerCurrency, {
                value:
                  usedPlannedTimeDollarsDisplay.jobCurrencyTotalPlannedTimeExTax,
                currency: jobCurrency,
                format: NUMBER_FORMAT_NO_DECIMAL,
              })}
            </span>
          )}
        </JumbotronProgressFieldValue>
      </JumbotronProgressField>
    </Tooltip>
  );
};

export default JumbotronTimeDollars;

const getProgressPercentage = (usedPlannedTimeDollarsData, costVsSellMode) => {
  let numerator = "";

  if (costVsSellMode === JOB_COST_VS_SELL_MODE.COST) {
    numerator = usedPlannedTimeDollarsData.jobCurrencyTotalLoggedTimeCostExTax;
  } else {
    numerator = usedPlannedTimeDollarsData.jobCurrencyTotalLoggedTimeExTax;
  }

  if (usedPlannedTimeDollarsData.jobCurrencyTotalPlannedTimeExTax > 0) {
    return Math.round(
      (numerator /
        usedPlannedTimeDollarsData.jobCurrencyTotalPlannedTimeExTax) *
        100
    );
  }

  return 0;
};
