import type { TEntityId } from "../../entities/Entity";
import { createEmitter } from "../../lib";
import type { TEntityList, TEntityListKey } from "../../types";

export type TStoreEmitterActions = {
  entitiesReceived: { entityData: TEntityList };
  entitiesRemoved: {
    entityName: TEntityListKey;
    ids: TEntityId[];
  };
};

const storeEmitter = createEmitter();

export function storeActionEmit<TAction extends keyof TStoreEmitterActions>(
  action: TAction,
  payload: TStoreEmitterActions[TAction]
) {
  storeEmitter.emit(action, payload);
}

export function storeActionAddListener<
  TAction extends keyof TStoreEmitterActions,
>(action: TAction, fn: (payload: TStoreEmitterActions[TAction]) => void) {
  storeEmitter.addListener(action, fn);
}
