export const DAYS_IN_WEEK = 7;
export const DAY_OF_WEEK_START = 1;
export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD";
export const DATE_FORMAT_MONTH_YEAR_ISO = "YYYY-MM";
export const DATE_FORMAT_DAY_DATE = "ddd D";
export const DATE_FORMAT_DAY_DATE_MONTH = "ddd D MMM";
export const DATE_FORMAT_ORDINAL_DATE_MONTH = "Do MMM";
export const DATE_FORMAT_DAY_ORDINAL_DATE_MONTH = "ddd Do MMM";
export const DATE_FORMAT_DATE_MONTH_YEAR = "D MMM YY";
export const DATE_FORMAT_DATE_MONTH_FULL_YEAR = "D MMM YYYY";
export const DATE_FORMAT_DATE_SHORT_MONTH_YEAR = "DD MMM YY";
export const DATE_FORMAT_DATE_SHORT_MONTH = "DD MMM";
export const DATE_FORMAT_DATE_FULL_MONTH = "DD MMMM";
export const DATE_FORMAT_SHORT_DATE_FULL_MONTH = "D MMMM";
export const DATE_FORMAT_DATE_DAY = "DD ddd";
export const DATE_FORMAT_DATE_FULL_DAY = "DD dddd";
export const DATE_FORMAT_DATE_FULL_MONTH_FULL_YEAR = "DD MMMM YYYY";
export const DATE_FORMAT_FULL_MONTH = "MMMM";
export const DATE_FORMAT_FULL_MONTH_YEAR = "MMMM YYYY";
export const DATE_FORMAT_YEAR = "YYYY";
export const DATE_FORMAT_DAY_OF_WEEK_LETTER = "dd";
export const DATE_FORMAT_DATE_OF_MONTH = "DD";
export const DATE_FORMAT_INVOICE = "DD MMM, YY";
export const DATE_FORMAT_INVOICE_PAYMENT = "D MMM, YYYY";
export const DATETIME_FORMAT_TIME_DOT_DATE = "H:mm • D MMM YY";
export const DATETIME_FORMAT_TIME_ONLY = "h:mma";
