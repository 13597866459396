import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemRoleId } from "../../../helpers/selectors";
import { selectStateJobItemRoles } from "./selectStateJobItemRoles";

export const selectJobItemRole = createCachedSelector(
  [selectStateJobItemRoles, selectPropsJobItemRoleId],
  (jobItemRoles, jobItemRoleId) => jobItemRoles.byId[jobItemRoleId]
)(selectPropsJobItemRoleId);

export function useJobItemRole(jobItemRoleId: number) {
  return useSelector((state: AppState) =>
    selectJobItemRole(state, { jobItemRoleId })
  );
}
