import { plate } from "st-shared/external";

import { ELEMENT_COLUMN_INPUT } from "../createColumnPlugin";
import { TColumnInputElement } from "../types";

export const findColumnInput = <V extends plate.Value>(
  editor: plate.PlateEditor<V>,
  options?: Omit<plate.FindNodeOptions<V>, "match">
) =>
  plate.findNode<TColumnInputElement>(editor, {
    ...options,
    match: { type: plate.getPluginType(editor, ELEMENT_COLUMN_INPUT) },
  });
