import { call, put, select } from "redux-saga/effects";
import { createQuoteLineItemAPI } from "../../../lib/API/quoteLineItemAPI";
import { sendRefreshQuoteHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  QUOTE_LINE_ITEM_CREATE,
  QUOTE_LINE_ITEM_CREATE_ERROR,
  QUOTE_LINE_ITEM_CREATED,
  QUOTE_LINE_ITEM_CREATING
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectQuoteLineItemById } from "../../selectors/quoteLineItemSelectors";

function* createLine(action) {
  const { id } = action.payload;

  try {
    yield put(createAction(QUOTE_LINE_ITEM_CREATING, { id }));

    const quoteLineItem = yield select(selectQuoteLineItemById, { id });

    const { quoteLineItems, ...entities } = yield call(
      createQuoteLineItemAPI,
      quoteLineItem
    );

    yield put(createAction(ENTITIES_RECEIVED, entities));

    yield put(createAction(QUOTE_LINE_ITEM_CREATED, { id, quoteLineItems }));

    yield call(sendRefreshQuoteHtml);
  } catch (error) {
    yield put(
      createAction(QUOTE_LINE_ITEM_CREATE_ERROR, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchQuoteLineItemCreate() {
  yield takeLatestBy(
    QUOTE_LINE_ITEM_CREATE,
    createLine,
    action => action.payload.id
  );
}
