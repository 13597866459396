import React from "react";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_RESIZE_HANDLE,
  DATA_VALUE_HANDLE_TOP,
  DATA_VALUE_HANDLE_RIGHT,
  DATA_VALUE_HANDLE_TOP_RIGHT
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";

const attrName = getAttrPropString(DATA_ATTRIBUTE_RESIZE_HANDLE);

const ResizeHandles = () => (
  <div>
    <TopHandle {...{ [attrName]: DATA_VALUE_HANDLE_TOP }} />
    <RightHandle {...{ [attrName]: DATA_VALUE_HANDLE_RIGHT }} />
    <TopRightHandle {...{ [attrName]: DATA_VALUE_HANDLE_TOP_RIGHT }} />
  </div>
);

export default ResizeHandles;

const Handle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
`;

const TopHandle = styled(Handle)`
  height: 7px;
  width: 100%;
  cursor: ns-resize;
`;

const RightHandle = styled(Handle)`
  width: 7px;
  height: 100%;
  cursor: ew-resize;
`;

const TopRightHandle = styled(Handle)`
  width: 7px;
  height: 7px;
  z-index: 1;
  cursor: nesw-resize;
`;
