import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const HomeIcon = props => (
  <SvgIcon {...props} viewBox="0 0 16 18">
    <path d="M0 18V5.99793L8.04973 0L16 5.99793V18H10.1507V10.8646H5.79953V18H0Z" />
  </SvgIcon>
);

export default StyledIcon(HomeIcon);
