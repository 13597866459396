import { Entity } from "@streamtimefe/entities";
import { put, select } from "redux-saga/effects";

import {
  ENTITIES_RECEIVED,
  ENTITY_NAME_JOB_ITEM_SUB_ITEMS,
  JOB_ITEM_SUB_ITEM_CREATE,
  JOB_ITEM_SUB_ITEM_EDIT,
} from "../../../lib/constants";
import { createNewJobItemSubItem } from "../../../lib/entities/jobItemSubItemEntity";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectNextOrderIdByJobItemId } from "../../selectors/jobItemSubItem/selectNextOrderIdByJobItemId";

function* create(action) {
  const createId = Entity.temporaryId();

  try {
    const { jobItemId, value } = action.payload;

    const description = value.trim();

    if (description.length === 0) return;

    const orderId = yield select(selectNextOrderIdByJobItemId, { jobItemId });

    const newJobItemSubItem = createNewJobItemSubItem({
      id: createId,
      jobItemId,
      description,
      orderId,
    });

    yield put(
      createAction(ENTITIES_RECEIVED, {
        [ENTITY_NAME_JOB_ITEM_SUB_ITEMS]: [newJobItemSubItem],
      })
    );

    yield put(
      createAction(JOB_ITEM_SUB_ITEM_EDIT, {
        jobItemId,
        jobItemSubItems: [{ new: newJobItemSubItem }],
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchJobItemSubItemCreate() {
  yield takeLatestBy(
    [JOB_ITEM_SUB_ITEM_CREATE],
    create,
    (action) => action.payload.jobItemId
  );
}
