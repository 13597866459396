import * as PropTypes from "prop-types";
import { entityIdType } from "../types/entityTypes";
import {
  commercialDocumentLineItemShape,
  documentLineItemMethods
} from "../ui/commercialDocumentLineItems";

export const quoteLineItemShape = {
  ...commercialDocumentLineItemShape,
  quoteId: entityIdType.isRequired
};

export const quoteLineItemEntityType = PropTypes.shape(quoteLineItemShape);

export const getQuoteId = ({ quoteId }) => quoteId;

export const quoteLineItemMethods = {
  ...documentLineItemMethods
};
