import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_OBJECT } from "../../lib/constants";
import { selectPropsId } from "./index";

export const selectBranchLogos = state => state.ui.branchLogos;

export const selectBranchLogoById = createSelector(
  selectBranchLogos,
  selectPropsId,
  (branchLogos, id) => {
    const { isUploading = false, isUploaded = false } = get(
      branchLogos,
      id,
      EMPTY_OBJECT
    );

    return {
      isUploading,
      isUploaded
    };
  }
);
