import type { TEntityClassMap, TEntityId } from "@streamtimefe/entities";
import { type PropsWithChildren, useEffect, useMemo } from "react";
import { isDefined, isFunction, isNumber } from "remeda";

import type { TStoreCreator } from "../../core";
import { useCreateActionStore } from "../../core";
import {
  EntityStore,
  getEntityStoreEntity,
  getEntityStoreEntityBySyncKey,
  type TEntityStoreClass,
} from "../EntityStore";
import type {
  TSingleEntityStore,
  TSingleEntityStoreContext,
} from "./SingleEntityStore";
import type { TSingleEntityActions } from "./SingleEntityStore.actions";
import { createSingleEntityActions } from "./SingleEntityStore.actions";

function useCreateSingleEntityActionStore<C extends TEntityStoreClass>(
  entityClass: C,
  initializer: TStoreCreator<TSingleEntityStore<C>>
) {
  return useCreateActionStore<TSingleEntityStore<C>, TSingleEntityActions<C>>(
    initializer,
    (set, get) => createSingleEntityActions(entityClass, set, get)
  );
}

export type SingleEntityStoreProviderProps<C extends TEntityStoreClass> =
  PropsWithChildren &
    (
      | {
          entity: TEntityId | TEntityClassMap[C] | (() => TEntityClassMap[C]);
          isLocal?: boolean;
          syncKey?: never;
        }
      | {
          entity?: never;
          isLocal?: never;
          syncKey: string;
        }
    );

export function createSingleEntityStoreProvider<C extends TEntityStoreClass>(
  entityClass: C,
  StoreContext: TSingleEntityStoreContext<C>
) {
  const SingleEntityStoreProvider = function SingleEntityStoreProvider({
    children,
    entity: entityOrSelectorOrId,
    syncKey,
    isLocal = false,
  }: SingleEntityStoreProviderProps<C>) {
    const entity = useMemo(() => {
      if (isDefined(syncKey)) {
        return getEntityStoreEntityBySyncKey(entityClass, syncKey)!;
      } else if (isNumber(entityOrSelectorOrId)) {
        return getEntityStoreEntity(entityClass, entityOrSelectorOrId)!;
      } else if (isFunction(entityOrSelectorOrId)) {
        return entityOrSelectorOrId();
      }
      return entityOrSelectorOrId!;
    }, [entityOrSelectorOrId, syncKey]);

    const actionStore = useCreateSingleEntityActionStore(entityClass, () => {
      return {
        entity,
        modified: null,
        updateGlobal: !isLocal,
      };
    });

    useEffect(() => {
      actionStore.actions.setInitials(entity, !isLocal);
      if (!isDefined(entity)) return;

      if (isDefined(syncKey)) {
        let unsubscribeEntity: (() => void) | undefined;

        unsubscribeEntity = EntityStore.store.subscribe(
          (store) => store[entityClass].byId[entity.id],
          (entity) => actionStore.actions.updateFromStore(entity!)
        );

        const unsubscribeSyncKey = EntityStore.store.subscribe(
          (store) => store[entityClass].syncKeyIndex[syncKey],
          (entityId) => {
            unsubscribeEntity?.();
            if (entityId) {
              // force update on key change
              const entity =
                EntityStore.store.getState()[entityClass].byId[entityId];
              actionStore.actions.updateFromStore(entity!);

              unsubscribeEntity = EntityStore.store.subscribe(
                (store) => store[entityClass].byId[entityId],
                (entity) => actionStore.actions.updateFromStore(entity!)
              );
            }
          }
        );

        return () => {
          unsubscribeSyncKey();
          unsubscribeEntity?.();
        };
      } else {
        const unsubscribeEntity = EntityStore.store.subscribe(
          (store) => store[entityClass].byId[entity.id],
          (entity) => actionStore.actions.updateFromStore(entity!)
        );

        return () => {
          unsubscribeEntity();
        };
      }
    }, [actionStore, entity, isLocal, syncKey]);

    const storeEntity = actionStore.store((s) => s.entity);
    if (!isDefined(storeEntity)) return null;

    return (
      <StoreContext.Provider value={actionStore}>
        {children}
      </StoreContext.Provider>
    );
  };

  SingleEntityStoreProvider.displayName = `${entityClass}SingleEntityStoreProvider`;
  return SingleEntityStoreProvider;
}
