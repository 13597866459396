import {
  type TValueMatchTypeEnum,
  ValueMatchTypeEnum,
} from "@streamtimefe/entities";
import { values } from "lodash-es";
import type { ChangeEvent } from "react";
import { Fragment, useRef, useState } from "react";

import type { InputProps, SelectKey } from "../../../components";
import { IconButton, Input, MdAdd, Popover } from "../../../components";
import { convertMinutesToTimeHM } from "../../../lib";
import { useCustomerCurrency } from "../../../stores";
import {
  getConditionMatchName,
  getFilterName,
  getNextCondition,
} from "../../../types";
import { useFilterListener } from "..";
import { addButtonCss, boldText, inputCss, numberEditCss } from "../Filter.css";
import { OperatorButton } from "../OperatorButton";
import type {
  FilterDisplayTextProps,
  FilterGroupFilterValue,
  FilterProps,
} from "../types";
import { FilterDisplay } from "./FilterDisplay";
import { FilterElementDisplay } from "./FilterElementDisplay";
import type { FilterSelectProps } from "./FilterSelect";
import { FilterSelect } from "./FilterSelect";
import { convertFlexibleDurationToMinutes } from "@streamtimefe/utils";

const ValueMatchTypes = [
  { id: ValueMatchTypeEnum.GreaterThan, name: "is greater than" },
  { id: ValueMatchTypeEnum.LessThan, name: "is less than" },
  { id: ValueMatchTypeEnum.Equals, name: "is equal to" },
];

export interface NumberFilterProps extends FilterProps {
  type: "number" | "minutes";
}

export function NumberFilter({
  uuid,
  filterGroup,
  onDelete,
  addFilter,
  setFilterGroupConditionMatch,
  setFilter,
  deleteFilters,
  type,
}: NumberFilterProps) {
  const displayRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { filters, conditionMatchTypeId } = filterGroup;

  useFilterListener(uuid, filterGroup.id, "open", openEditMenu);

  function openEditMenu() {
    setAnchorEl(displayRef.current);
    requestAnimationFrame(() => {
      if (filters.length === 0) {
        addEmptyFilter();
      }
    });
  }

  function closeEditMenu() {
    setAnchorEl(null);
    requestAnimationFrame(() => {
      const deleteFilterIndexes: number[] = [];
      filters.forEach((filter, index) => {
        if (filter.value === "") {
          deleteFilterIndexes.push(index);
        }
      });

      if (
        filters.length === 0 ||
        filters.length === deleteFilterIndexes.length
      ) {
        onDelete(filterGroup.id);
      } else if (deleteFilterIndexes.length > 0) {
        deleteFilters(filterGroup.id, deleteFilterIndexes);
      }
    });
  }

  function onSetConditionMatch() {
    setFilterGroupConditionMatch(
      filterGroup.id,
      getNextCondition(conditionMatchTypeId)
    );
  }

  function onSelectChange(value: SelectKey, filterIndex: number) {
    setFilter(filterGroup.id, filterIndex, {
      value: filters[filterIndex].value,
      valueMatchTypeId: value as TValueMatchTypeEnum,
    });

    requestAnimationFrame(() => {
      const el = document.querySelector<HTMLInputElement>(
        `#filterElement${filterIndex}`
      );
      el?.focus();
    });
  }

  function onInputChange(
    event: ChangeEvent<HTMLInputElement>,
    filterIndex: number
  ) {
    setFilter(filterGroup.id, filterIndex, {
      value: convertInputValue(event.target.value),
      valueMatchTypeId: filters[filterIndex].valueMatchTypeId,
    });
  }

  function addEmptyFilter() {
    addFilter(filterGroup.id, {
      value: "",
      valueMatchTypeId: ValueMatchTypes[0].id,
    });
    const nextFilterIndex = filters.length;
    requestAnimationFrame(() => {
      const el = document.querySelector<HTMLInputElement>(
        `#filterElement${nextFilterIndex}`
      );
      el?.focus();
    });
  }

  function convertInputValue(value: string) {
    return type === "minutes"
      ? String(convertFlexibleDurationToMinutes(value))
      : String(value);
  }

  return (
    <div>
      <FilterDisplay
        ref={displayRef}
        onClick={openEditMenu}
        onDelete={onDelete}
        filterGroup={filterGroup}
      >
        <NumberFilterDisplayText type={type} filterGroup={filterGroup} />
      </FilterDisplay>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditMenu}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: -36, horizontal: "center" }}
        PaperProps={{ className: numberEditCss.root }}
      >
        {filters.map((filter, index) => {
          return (
            <Fragment key={index}>
              <FilterElement
                selectValue={filter.valueMatchTypeId}
                selectOnChange={(value) => onSelectChange(value, index)}
                inputId={`filterElement${index}`}
                inputType={type}
                inputValue={String(filter.value)}
                inputOnChange={(event) => onInputChange(event, index)}
                onDelete={
                  filters.length > 1
                    ? () => deleteFilters(filterGroup.id, [index])
                    : null
                }
              />
              {index !== filters.length - 1 && (
                <OperatorButton
                  onClick={onSetConditionMatch}
                  conditionMatchTypeId={conditionMatchTypeId}
                />
              )}
            </Fragment>
          );
        })}
        {filters.length > 0 && filters[filters.length - 1].value !== "" && (
          <IconButton
            className={addButtonCss}
            iconProps={{ icon: MdAdd }}
            onClick={addEmptyFilter}
          />
        )}
      </Popover>
    </div>
  );
}

interface FilterElementProps {
  selectValue: TValueMatchTypeEnum;
  selectOnChange: FilterSelectProps["onSelectionChange"];
  inputId: string;
  inputType: NumberFilterProps["type"];
  inputValue: InputProps["value"];
  inputOnChange: InputProps["onChange"];
  onDelete?: (() => void) | null;
}

export function FilterElement({
  selectValue,
  selectOnChange,
  inputId,
  inputType,
  inputValue,
  inputOnChange,
  onDelete,
}: FilterElementProps) {
  const [value, setValue] = useState(
    inputType === "number"
      ? inputValue
      : convertMinutesToTimeHM(Number(inputValue))
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    inputOnChange?.(event);
  }

  return (
    <FilterElementDisplay onDelete={onDelete} className={numberEditCss.filter}>
      <FilterSelect
        aria-label="Number Filter Selector"
        items={ValueMatchTypes}
        selectedKey={selectValue}
        onSelectionChange={selectOnChange}
      />
      <Input
        id={inputId}
        variant="secondary"
        type={inputType === "number" ? "number" : "text"}
        className={inputCss}
        value={value}
        onChange={onChange}
      />
    </FilterElementDisplay>
  );
}

export function NumberFilterDisplayText({
  filterGroup,
  type,
}: FilterDisplayTextProps & Pick<NumberFilterProps, "type">) {
  const customerCurrency = useCustomerCurrency();

  function getDisplayText() {
    const filterValues = values(ValueMatchTypes)
      .map((valueMatchType) => ({
        ...valueMatchType,
        values: filterGroup.filters
          .filter(
            ({ valueMatchTypeId }) => valueMatchTypeId === valueMatchType.id
          )
          .map(({ value }) => value),
      }))
      .filter(({ values }) => values.length);

    return (
      <>
        {filterValues.map((filterValue, filterValueIndex) => {
          return (
            <Fragment key={filterValue.name}>
              {" "}
              {filterValue.name}
              {filterValue.values.map((value, index) => {
                return (
                  <Fragment key={`${filterValue.name}${index}`}>
                    {" "}
                    <span className={boldText}>{formatInputValue(value)}</span>
                    {index !== filterValue.values.length - 1 &&
                      ` ${getConditionMatchName(
                        filterGroup.conditionMatchTypeId
                      )}`}
                  </Fragment>
                );
              })}
              {filterValueIndex !== filterValues.length - 1 &&
                ` ${getConditionMatchName(filterGroup.conditionMatchTypeId)}`}
            </Fragment>
          );
        })}
      </>
    );
  }

  function formatInputValue(value: FilterGroupFilterValue) {
    return type === "minutes"
      ? convertMinutesToTimeHM(Number(value))
      : String(value);
  }

  return (
    <>
      <span className={boldText}>
        {getFilterName(filterGroup.filterGroupTypeId, customerCurrency)}
      </span>
      {getDisplayText()}
    </>
  );
}
