import createMentionPlugin from "@draft-js-plugins/mention";
import "./mentionStyles.css";
import { each, keys, values } from "lodash-es";
import { REGEX_TEXT_MENTION } from "../../../../../lib/constants";
import { userOptionToMention } from "../../../../../lib/entities/userEntity";

export default () => {
  const mentionPlugin = createMentionPlugin({
    mentionPrefix: "@",
    theme: {
      mention: "draftJsMentionPlugin__mention__29BEd",
      mentionSuggestionsEntry:
        "draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm",
      mentionSuggestionsEntryFocused:
        "draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd",
      mentionSuggestionsEntryText:
        "draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq",
      mentionSuggestionsEntryAvatar:
        "draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9",
      mentionSuggestions: "draftJsMentionPlugin__mentionSuggestions__2DWjA"
    },
    popperOptions: {
      placement: "top-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  });
  const { MentionSuggestions } = mentionPlugin;

  return {
    mentionPlugin,
    MentionSuggestions
  };
};

export const transformMentionEntityToHtml = entity =>
  `<span class="mention">@<span class="mention-user-id">[${entity.data.mention.key}]</span>${entity.data.mention.value}</span>`;

export const getMentionUserIdsFromRawContent = ({ entityMap }) =>
  values(entityMap)
    .filter(({ type }) => type === "mention")
    .map(({ data }) => data.mention.key)
    .join(",");

export const parseMentionEntities = rawContentState => {
  const { blocks, entityMap } = rawContentState;

  each(blocks, block => {
    let matchArr;
    // eslint-disable-next-line no-cond-assign
    while ((matchArr = block.text.match(REGEX_TEXT_MENTION)) !== null) {
      const key = keys(entityMap).length;
      const offset = matchArr.index;
      const [matchString, userId, userName] = matchArr;

      entityMap[key] = {
        type: "mention",
        mutability: "SEGMENTED",
        data: {
          mention: userOptionToMention({
            key: Number(userId),
            value: userName,
            searchString: userName
          })
        }
      };

      const mentionString = matchString.replace(REGEX_TEXT_MENTION, "@$2");

      block.text = block.text.replace(matchString, mentionString);

      block.entityRanges.push({
        key,
        length: mentionString.length,
        offset
      });
    }
  });
};
