export const BuildEnvironment = {
  Production: "production",
  Demo: "demo",
  Dev: "dev",
} as const;

export type TBuildEnvironment =
  (typeof BuildEnvironment)[keyof typeof BuildEnvironment];

export const buildEnvironments = [
  BuildEnvironment.Production,
  BuildEnvironment.Demo,
  BuildEnvironment.Dev,
];
