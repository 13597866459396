import {
  getTotalLoggedMinutes,
  getTotalPlannedMinutes,
} from "../../../../lib/entities/jobEntity";
import { convertMinutesToTimeHM } from "../../../../lib/time";
import { jobPageOpenLoggedItemsModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJob } from "../../../../redux/selectors/job";
import { useJumbotronUsedPlannedTimeHoursDisplay } from "../../../../redux/selectors/jobDetails/jumbotronUsedPlannedTimeHoursDisplay";
import { useJobUsedPlannedTimeHoursSummary } from "../../../../redux/selectors/jobDetails/ui/selectJobUsedPlannedTimeHoursSummary";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import { JumbotronTooltipTable, LegendDisplay, TooltipTitle } from "../styles";

const JumbotronTimeHours = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const usedPlannedTimeHoursDisplay =
    useJumbotronUsedPlannedTimeHoursDisplay(jobId);
  const usedPlannedTimeHoursSummary = useJobUsedPlannedTimeHoursSummary(jobId);

  const tooltipContents = (
    <JumbotronTooltipTable>
      <tbody>
        <tr>
          <td></td>
          <TooltipTitle>
            Used
            <LegendDisplay $color="var(--color-blue-bright)"></LegendDisplay>
          </TooltipTitle>
          <TooltipTitle>
            Planned
            <LegendDisplay $color="var(--color-gray)"></LegendDisplay>
          </TooltipTitle>
        </tr>
        <tr className="divider">
          <td colSpan="3"></td>
        </tr>
        <tr className="dividerBlank">
          <td colSpan="3"></td>
        </tr>
        <tr>
          <TooltipTitle $padded>Total</TooltipTitle>
          <TooltipTitle $padded>
            {convertMinutesToTimeHM(getTotalLoggedMinutes(job))}
          </TooltipTitle>
          <TooltipTitle $padded>
            {convertMinutesToTimeHM(getTotalPlannedMinutes(job))}
          </TooltipTitle>
        </tr>
        {usedPlannedTimeHoursSummary.map((data) => (
          <tr key={data.userId}>
            <td>{data.userDisplayName}</td>
            <td>{convertMinutesToTimeHM(data.totalLoggedMinutes)}</td>
            <td>{convertMinutesToTimeHM(data.totalPlannedMinutes)}</td>
          </tr>
        ))}
      </tbody>
    </JumbotronTooltipTable>
  );

  return (
    <Tooltip
      title={tooltipContents}
      enabled={usedPlannedTimeHoursSummary.length > 0}
      placement="right"
      className="time"
    >
      <JumbotronProgressField
        name="TIME (H)"
        onClick={() => jobPageOpenLoggedItemsModal(job, "time")}
        progressColor="var(--color-blue-bright)"
        progressPercentage={getProgressPercentage(
          job,
          usedPlannedTimeHoursDisplay.plannedMinutes
        )}
        noOverrun={usedPlannedTimeHoursDisplay.plannedMinutes === 0}
      >
        <JumbotronProgressFieldValue>
          <span className="bold">
            {usedPlannedTimeHoursDisplay.loggedMinutesDisplay}
          </span>

          {usedPlannedTimeHoursDisplay.plannedMinutes > 0 && (
            <span> / {usedPlannedTimeHoursDisplay.plannedMinutesDisplay}</span>
          )}
        </JumbotronProgressFieldValue>
      </JumbotronProgressField>
    </Tooltip>
  );
};

export default JumbotronTimeHours;

const getProgressPercentage = (job, calculatedTotalPlannedMinutes) => {
  const totalLoggedMinutes = getTotalLoggedMinutes(job);
  if (totalLoggedMinutes > 0) {
    return Math.round(
      (totalLoggedMinutes / calculatedTotalPlannedMinutes) * 100
    );
  }
  return 0;
};
