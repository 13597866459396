export function getSearchParam(
  name: string,
  location: Location = window.location
) {
  return new URLSearchParams(location.search).get(name);
}

export function encodeSearchParam(data: object) {
  return btoa(JSON.stringify(data));
}
