import { call, put, select, takeEvery } from "redux-saga/effects";

import { fetchJobBootstrapAPI } from "../../../lib/API/jobAPI";
import {
  ENTITIES_RECEIVED,
  JOB_TIMELINE_FETCH_FAILED,
  JOB_TIMELINE_FETCH_REQUEST,
  JOB_TIMELINE_FETCH_SUCCEEDED,
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectJobTimelineUi } from "../../selectors/jobTimelineSelectors";

function* fetch(action) {
  try {
    const { jobId } = action.payload;

    const { fetchedIds } = yield select(selectJobTimelineUi);

    if (!fetchedIds.includes(jobId)) {
      const { data } = yield call(fetchJobBootstrapAPI, jobId);

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));
    }

    yield put(createAction(JOB_TIMELINE_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(JOB_TIMELINE_FETCH_FAILED, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(JOB_TIMELINE_FETCH_REQUEST, fetch);
}
