import SvgIcon from "@material-ui/core/SvgIcon";
import { values } from "lodash-es";
import * as PropTypes from "prop-types";
import React from "react";
import StyledIcon, { ICON_SIZE } from "../../modules/StyledIcon";

const GroupByItemIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M10.1 19v-4h12.1v4H10.1zm9.1-1h2v-2h-2v2zm-9.1-4.15V9.96h12.1v3.89H10.1zm9.1-.96h2v-1.93h-2v1.93zM10.1 9V5h12.1v4H10.1zm9.1-1h2V6h-2v2z" />
    <path d="M4.94 9.67l1.76 1.67H4.28c-.66 0-1.25-.5-1.25-1.16V6.7H2v3.41c0 1.16 1.03 2.18 2.28 2.18H6.7l-1.76 1.67.74.72 2.93-2.83-2.93-2.9c-.15.14-.59.58-.74.72z" />
  </SvgIcon>
);

GroupByItemIcon.propTypes = {
  size: PropTypes.oneOf(values(ICON_SIZE))
};

GroupByItemIcon.defaultProps = {
  size: ICON_SIZE.LARGE
};

export default StyledIcon(GroupByItemIcon);
