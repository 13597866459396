import numeral from "numeral";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import {
  getJobCurrencyBudget,
  getJobCurrencyTotalApprovedQuoteExTax
} from "../../../lib/entities/jobEntity";
import { selectPropsJobId } from "../index";
import { selectJob } from "./index";

export const selectJobCurrencyFinalBudget = createCachedSelector(
  [selectJob],
  job => {
    if (numeral(getJobCurrencyTotalApprovedQuoteExTax(job)).value() > 0)
      return getJobCurrencyTotalApprovedQuoteExTax(job);

    return getJobCurrencyBudget(job);
  }
)(selectPropsJobId);

export const useJobCurrencyFinalBudget = jobId =>
  useSelector(state => selectJobCurrencyFinalBudget(state, { jobId }));
