import reduceChangedEntities from "../reduceChangedEntities";

const undoDuplicateBlockReducer = (state, action) => {
  const { newBlockKey } = action.payload;
  const prevScheduleLoggedTimeIds = state.idsByBlockKey[newBlockKey];
  const changedEntities = prevScheduleLoggedTimeIds.map(id => ({
    prevEntity: state.byId[id]
  }));

  return reduceChangedEntities(state, changedEntities);
};

export default undoDuplicateBlockReducer;
