import { useHasMultipleBranches, useOrganisation } from "st-shared/stores";
import styled from "styled-components";

import { getBranchId } from "../../../../lib/entities/jobEntity";
import { useIsForeignCurrency } from "../../../../redux/selectors/currency/isForeignCurrency";
import { useJob } from "../../../../redux/selectors/job";
import { useJobId } from "../../context/JobIdContext";
import JobBranch from "./JobBranch";
import JobCompany from "./JobCompany";
import JobContact from "./JobContact";
import JobExchangeRate from "./JobExchangeRate";
import JobNumber from "./JobNumber";
import JobPONumber from "./JobPONumber";
import JobRateCard from "./JobRateCard";

const JumbotronSettings = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const isForeignCurrency = useIsForeignCurrency(jobId);
  const organisation = useOrganisation();
  const branchIsDefault = organisation.defaultBranchId === getBranchId(job);
  const hasMultipleBranches = useHasMultipleBranches();

  return (
    <Container>
      <JobNumber></JobNumber>
      {(!branchIsDefault || hasMultipleBranches) && <JobBranch></JobBranch>}
      <JobCompany></JobCompany>
      <JobRateCard></JobRateCard>
      <JobContact></JobContact>
      {isForeignCurrency && <JobExchangeRate></JobExchangeRate>}
      <JobPONumber></JobPONumber>
    </Container>
  );
};

export default JumbotronSettings;

const Container = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 30px;
`;
