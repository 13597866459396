import { SvgIcon } from "@material-ui/core";
import React from "react";
import StyledIcon from "../../../modules/StyledIcon";

const SettingsIcon = props => (
  <SvgIcon {...props} viewBox="0 0 28.11 28.75">
    <path d="M14.05,9.2a5.18,5.18,0,1,0,5.11,5.18A5.15,5.15,0,0,0,14.05,9.2Zm2.13,5.18a2.13,2.13,0,1,1-2.13-2.2A2.17,2.17,0,0,1,16.18,14.38Z" />
    <path d="M24.94,13.7l3.17-3.33L24.47,4,20,5.1,19,4.45,17.71,0H10.39L9.15,4.47l-1.09.65L3.64,4,0,10.38l3.17,3.34v1.33L0,18.38l3.64,6.41,4.42-1.14,1.09.65,1.24,4.45h7.32L19,24.28,20,23.63l4.43,1.14,3.64-6.41L24.94,15ZM15.45,25.77h-2.8l-1-3.42-3.18-1.9-3.42.89L3.68,18.83l2.47-2.59V12.53L3.68,9.94,5.1,7.43l3.42.89,3.18-1.9,1-3.44h2.79l1,3.43L19.59,8.3,23,7.42l1.42,2.5L22,12.51v3.71l2.46,2.6L23,21.32l-3.41-.88-3.18,1.89Z" />
  </SvgIcon>
);

export default StyledIcon(SettingsIcon);
