import { produce } from "immer";
import { isEmpty } from "lodash-es";
import {
  MasterLoggedExpense,
  masterLoggedExpenseObj,
  MasterLoggedExpenseOption,
} from "st-shared/entities/MasterLoggedExpense";

import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from "../../../lib/constants";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityOptionsReducer from "../../helpers/createEntityOptionsReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";
import { ChangedEntity, EntityNames } from "../types";
import { MasterLoggedExpensesAction } from "./actions";
import { MasterLoggedExpensesState } from "./types";

const initialState: MasterLoggedExpensesState = {
  byId: {},
  options: [],
};

const optionsReducer = createEntityOptionsReducer<
  MasterLoggedExpense,
  MasterLoggedExpenseOption
>(
  (entity) =>
    entity && entity.active && masterLoggedExpenseObj(entity).getAsOption()
);

function reduceChangedEntities(
  state: MasterLoggedExpensesState,
  changedEntities: ChangedEntity<MasterLoggedExpense>[]
) {
  if (isEmpty(changedEntities)) return state;

  return produce(state, (draft) => {
    draft.byId = byIdReducer(state.byId, changedEntities);
    draft.options = optionsReducer(state.options, changedEntities);
  });
}

function masterLoggedExpensesReducer(
  state: MasterLoggedExpensesState = initialState,
  action: MasterLoggedExpensesAction
) {
  switch (action.type) {
    case ENTITIES_RECEIVED:
      return reduceChangedEntities(
        state,
        parseEntityPayload<MasterLoggedExpense>(
          state,
          action.payload.masterLoggedExpenses,
          (entity) => masterLoggedExpenseObj(entity).isDeleted()
        )
      );
    case ENTITIES_REMOVED:
      if (action.payload.entityName !== EntityNames.MasterLoggedExpenses) {
        return state;
      }
      return reduceChangedEntities(
        state,
        parseRemovedEntities(state, action.payload.ids)
      );
    default:
      return state;
  }
}

export default masterLoggedExpensesReducer;
