import API from "../../../lib/api";
import { createModelSetParser } from "../../../lib/modelParser";
import { sendApplyModelData } from "../../../lib/WebAppAPI";
import { EntityClasses, ModelSet } from "../types";

async function assignRoleTime(
  jobPhaseId: number,
  roleId: number,
  userId: number
) {
  const response = await API.post<ModelSet>(
    `/job_phases/${jobPhaseId}/assign_role_time`,
    {
      roleId,
      userId,
    }
  );
  sendApplyModelData(EntityClasses.JobPhase, response.data);
  return createModelSetParser(EntityClasses.JobPhase)(response.data);
}

async function addUsersAndRoles(
  jobPhaseId: number,
  userIds: number[],
  roleIds: number[]
) {
  const response = await API.post<ModelSet>(
    `/job_phases/${jobPhaseId}/assign_users_and_roles`,
    {
      userIds,
      roleIds,
    }
  );

  sendApplyModelData(EntityClasses.JobPhase, response.data);
  return createModelSetParser(EntityClasses.JobPhase)(response.data);
}

const JobPhaseAPI = {
  assignRoleTime,
  addUsersAndRoles,
};

export default JobPhaseAPI;
