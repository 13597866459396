import { isEmpty } from "lodash-es";

import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_ROLES,
} from "../../../lib/constants";
import { getAsOption, isActive } from "../../../lib/entities/roleEntity";
import byIdReducer from "../../helpers/byIdReducer";
import createEntityOptionsReducer from "../../helpers/createEntityOptionsReducer";
import createReducer from "../../helpers/createReducer";
import parseEntityPayload from "../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../helpers/parseRemovedEntities";

const activeOptionsReducer = createEntityOptionsReducer(
  (entity) => entity && isActive(entity) && getAsOption(entity)
);

const reduceChangedEntities = (state, changedEntities) => {
  if (isEmpty(changedEntities)) return state;

  return {
    ...state,
    byId: byIdReducer(state.byId, changedEntities),
    activeOptions: activeOptionsReducer(state.activeOptions, changedEntities),
  };
};

const receiveEntitiesReducer = (state, action) =>
  reduceChangedEntities(
    state,
    parseEntityPayload(state, action.payload[ENTITY_NAME_ROLES])
  );

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== ENTITY_NAME_ROLES) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
  }
);
