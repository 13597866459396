import { arrayMove } from "@dnd-kit/sortable";
import type { TSavedSegmentTypeEnum } from "@streamtimefe/entities";
import { uuidAlphaShort } from "@streamtimefe/utils";
import { produce } from "immer";
import { isEqual, pullAt } from "lodash-es";
import { useMemo } from "react";
import { create } from "zustand";

import type { TEntityId } from "../../../entities/Entity";
import { addGlobalStore, savedSegmentEntityStore } from "../../../stores";
import { useSavedSegmentSidebarContext } from "../context/SavedSegmentSidebarContext";
import type {
  SavedSegmentSidebarUserPreference,
  TSegmentFolder,
} from "./savedSegmentSidebarUserPreference";
import { setSavedSegmentSidebarUserPreference } from "./savedSegmentSidebarUserPreference";

export type SavedSegmentSidebarState = {
  segmentIds: TEntityId[];
  folders: TSegmentFolder[];
  helpers: {
    folderNameExists: (name: string) => boolean;
    generateId: (...extraIds: string[]) => string;
  };
  actions: {
    recheckSavedSegments: () => void;
    loadUserPreference: (
      userPreference?: SavedSegmentSidebarUserPreference
    ) => void;
    saveUserPreference: () => void;
    addSegment: (segmentId: TEntityId) => void;
    removeSegment: (segmentId: TEntityId, save?: boolean) => void;
    swapSegments: (segmentIdA: TEntityId, segmentIdB: TEntityId) => void;
    addFolder: (name: string) => void;
    removeFolder: (id: string) => void;
    setFolderName: (id: string, name: string) => void;
    swapFolders: (idA: string, idB: string) => void;
    moveSegmentToFolder: (folderId: string, segmentId: TEntityId) => void;
    setFolderOpen: (folderId: string, open: boolean) => void;
    swapFolderSegments: (
      folderId: string,
      segmentIdA: TEntityId,
      segmentIdB: TEntityId
    ) => void;
  };
};

export type TSavedSegmentSidebarStore = ReturnType<
  typeof createSavedSegmentSidebarStore
>;

export function createSavedSegmentSidebarStore(
  savedSegmentType: TSavedSegmentTypeEnum
) {
  const useStore = create<SavedSegmentSidebarState>()(function (set, get) {
    return {
      segmentIds: [],
      folders: [],
      helpers: {
        folderNameExists(name: string) {
          name = name.toUpperCase();
          const folder = get().folders.find((folder) => folder.name === name);
          return Boolean(folder);
        },
        generateId(...extraIds: string[]): string {
          const folderIds = get().folders.map((folder) => folder.id);

          const ids = [...folderIds, ...extraIds];

          let id = uuidAlphaShort();

          while (ids.includes(id)) {
            id = uuidAlphaShort();
          }

          return id;
        },
      },
      actions: {
        recheckSavedSegments() {
          const availableSegmentIds =
            savedSegmentEntityStore().helpers.getEntityIdsByType(
              savedSegmentType
            );

          set((s) =>
            produce(s, (draft) => {
              draft.segmentIds = draft.segmentIds.filter((id) =>
                availableSegmentIds.includes(id)
              );

              draft.folders.forEach((folder) => {
                folder.segmentIds = folder.segmentIds.filter((id) =>
                  availableSegmentIds.includes(id)
                );
              });
            })
          );
        },

        loadUserPreference(userPreference?: SavedSegmentSidebarUserPreference) {
          if (userPreference) {
            if (
              "segmentIds" in userPreference &&
              !isEqual(get().segmentIds, userPreference.segmentIds)
            ) {
              set({ segmentIds: userPreference.segmentIds });
            }
            if (
              "folders" in userPreference &&
              !isEqual(get().folders, userPreference.folders)
            ) {
              set({ folders: userPreference.folders });
            }
          } else {
            set({ segmentIds: [], folders: [] });
          }

          get().actions.recheckSavedSegments();
        },

        saveUserPreference() {
          setSavedSegmentSidebarUserPreference(savedSegmentType, {
            segmentIds: get().segmentIds,
            folders: get().folders,
          });
        },

        addSegment(segmentId: TEntityId) {
          get().actions.removeSegment(segmentId, false);

          set((s) =>
            produce(s, (draft) => {
              draft.segmentIds.push(segmentId);
            })
          );

          get().actions.saveUserPreference();
        },

        removeSegment(segmentId: TEntityId, save = true) {
          set((s) =>
            produce(s, (draft) => {
              draft.segmentIds = draft.segmentIds.filter(
                (id) => id !== segmentId
              );

              draft.folders.forEach((folder) => {
                folder.segmentIds = folder.segmentIds.filter(
                  (id) => id !== segmentId
                );

                if (folder.segmentIds.length === 0) {
                  folder.open = false;
                }
              });
            })
          );

          if (save) {
            get().actions.saveUserPreference();
          }
        },

        swapSegments(segmentIdA: TEntityId, segmentIdB: TEntityId) {
          const from = get().segmentIds.findIndex((id) => id === segmentIdA);
          const to = get().segmentIds.findIndex((id) => id === segmentIdB);

          if (from !== -1 && to !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.segmentIds = arrayMove(draft.segmentIds, from, to);
              })
            );

            get().actions.saveUserPreference();
          }
        },

        addFolder(name: string) {
          name = name.toUpperCase();
          if (name.length > 0 && !get().helpers.folderNameExists(name)) {
            set((s) =>
              produce(s, (draft) => {
                draft.folders.push({
                  id: get().helpers.generateId(),
                  name,
                  segmentIds: [],
                  open: false,
                });
              })
            );
            get().actions.saveUserPreference();
          }
        },

        removeFolder(id: string) {
          const index = get().folders.findIndex((folder) => folder.id === id);
          if (index !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.segmentIds.push(...draft.folders[index].segmentIds);
                pullAt(draft.folders, index);
              })
            );
            get().actions.saveUserPreference();
          }
        },

        setFolderName(id: string, name: string) {
          const index = get().folders.findIndex((folder) => folder.id === id);
          if (index !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.folders[index].name = name;
              })
            );
            get().actions.saveUserPreference();
          }
        },

        swapFolders(idA: string, idB: string) {
          const from = get().folders.findIndex((folder) => folder.id === idA);
          const to = get().folders.findIndex((folder) => folder.id === idB);

          if (from !== -1 && to !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.folders = arrayMove(draft.folders, from, to);
              })
            );

            get().actions.saveUserPreference();
          }
        },

        setFolderOpen(folderId: string, open: boolean) {
          const folderIndex = get().folders.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.folders[folderIndex].open = open;
              })
            );

            get().actions.saveUserPreference();
          }
        },

        moveSegmentToFolder(folderId: string, segmentId: TEntityId) {
          const folderIndex = get().folders.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            set((s) =>
              produce(s, (draft) => {
                draft.segmentIds = draft.segmentIds.filter(
                  (id) => id !== segmentId
                );

                draft.folders.forEach((folder) => {
                  folder.segmentIds = folder.segmentIds.filter(
                    (id) => id !== segmentId
                  );
                });

                draft.folders[folderIndex].segmentIds.push(segmentId);
              })
            );
            get().actions.saveUserPreference();
          }
        },

        swapFolderSegments(
          folderId: string,
          segmentIdA: TEntityId,
          segmentIdB: TEntityId
        ) {
          const folderIndex = get().folders.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            const from = get().folders[folderIndex].segmentIds.findIndex(
              (id) => id === segmentIdA
            );
            const to = get().folders[folderIndex].segmentIds.findIndex(
              (id) => id === segmentIdB
            );

            if (from !== -1 && to !== -1) {
              set((s) =>
                produce(s, (draft) => {
                  draft.folders[folderIndex].segmentIds = arrayMove(
                    draft.folders[folderIndex].segmentIds,
                    from,
                    to
                  );
                })
              );

              get().actions.saveUserPreference();
            }
          }
        },
      },
    };
  });

  addGlobalStore(
    ["dynamic", `savedSegmentSidebar_${savedSegmentType}`],
    useStore.getState
  );

  return useStore;
}

export function useSavedSegmentSidebarStore() {
  return useSavedSegmentSidebarContext().useStore;
}

export function useSavedSegmentSidebarSegmentIds() {
  return useSavedSegmentSidebarContext().useStore((s) => s.segmentIds);
}

export function useSavedSegmentSidebarFolders() {
  return useSavedSegmentSidebarContext().useStore((s) => s.folders);
}

export function useSavedSegmentSidebarFolder(id: string) {
  const folders = useSavedSegmentSidebarFolders();

  return useMemo(() => {
    const index = folders.findIndex((folder) => folder.id === id);
    if (index !== -1) {
      return folders[index];
    }
    return undefined;
  }, [folders, id]);
}

export function useSavedSegmentSidebarFolderOpen(id: string) {
  return useSavedSegmentSidebarContext().useStore((s) => {
    const folderIndex = s.folders.findIndex((folder) => folder.id === id);
    if (folderIndex !== -1) {
      return s.folders[folderIndex].open;
    }
    return false;
  });
}

export function useSavedSegmentSidebarActions() {
  return useSavedSegmentSidebarContext().useStore((s) => s.actions);
}

export function useSavedSegmentSidebarHelpers() {
  return useSavedSegmentSidebarContext().useStore((s) => s.helpers);
}

export function useSavedSegmentSidebarSegmentPinned(segmentId: TEntityId) {
  const segmentIds = useSavedSegmentSidebarSegmentIds();
  const folderSegmentIds = useSavedSegmentSidebarFolders().flatMap(
    (folder) => folder.segmentIds
  );

  return useMemo(
    () => [...segmentIds, ...folderSegmentIds].includes(segmentId),
    [segmentIds, folderSegmentIds, segmentId]
  );
}
