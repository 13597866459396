import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchQuoteBootstrapAPI } from "../../../lib/API/quoteAPI";
import WebAppAPI from "../../../lib/WebAppAPI";
import { webAppQuoteBootstrap } from "../../../lib/WebAppAPI/bootstrap";
import {
  ENTITIES_RECEIVED,
  QUOTE_FETCH_SUCCEEDED,
  QUOTE_FETCH_FAILED,
  QUOTE_FETCH_REQUEST
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectQuoteUi } from "../../selectors/quoteSelectors";

function* fetch(action) {
  try {
    const { quoteId } = action.payload;

    const { fetchedIds } = yield select(selectQuoteUi);

    if (!fetchedIds.includes(quoteId)) {
      const { data } = yield call(
        WebAppAPI.isConnected() ? webAppQuoteBootstrap : fetchQuoteBootstrapAPI,
        quoteId
      );

      yield put(createAction(ENTITIES_RECEIVED, { ...data }));
    }

    yield put(createAction(QUOTE_FETCH_SUCCEEDED, action.payload));
  } catch (error) {
    yield put(
      createAction(QUOTE_FETCH_FAILED, {
        ...action.payload,
        error
      })
    );

    sagaError(error);
  }
}

export default function* watchFetch() {
  yield takeEvery(QUOTE_FETCH_REQUEST, fetch);
}
