import { EntityClass } from "@streamtimefe/entities";

import {
  createSingleEntityStoreContext,
  createSingleEntityStoreProvider,
} from "../../SingleEntityStore";

export const GoogleCalendarStore = createSingleEntityStoreContext(
  EntityClass.GoogleCalendar
);

export const GoogleCalendarStoreProvider = createSingleEntityStoreProvider(
  EntityClass.GoogleCalendar,
  GoogleCalendarStore
);
