import { get, keys } from "lodash-es";

import {
  EMPTY_OBJECT,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  ENTITY_NAME_JOB_ITEM_USERS,
  ENTITY_NAME_JOB_ITEMS,
  ENTITY_NAME_JOBS,
  ENTITY_NAME_REPEATING_LOGGED_TIMES,
  SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID,
  SCHEDULE_BLOCK_DELETE,
  SCHEDULE_BLOCK_DELETE_ERROR,
  SCHEDULE_BLOCK_DRAG_CANCEL,
  SCHEDULE_BLOCK_DRAG_END,
  SCHEDULE_BLOCK_DRAG_MULTIPLE,
  SCHEDULE_BLOCK_DUPLICATE,
  SCHEDULE_BLOCK_LOG,
  SCHEDULE_BLOCK_PAINT,
  SCHEDULE_BLOCK_PAINT_CANCEL,
  SCHEDULE_BLOCK_PAINT_START,
  SCHEDULE_BLOCK_REASSIGN,
  SCHEDULE_BLOCK_RESIZE,
  SCHEDULE_BLOCK_RESIZE_CANCEL,
  SCHEDULE_BLOCK_RESIZE_END,
  SCHEDULE_BLOCK_SAVE_ERROR,
  SCHEDULE_BLOCK_SAVED,
  SCHEDULE_BLOCK_SPLIT_SAVE_ERROR,
  SCHEDULE_BLOCK_SPLIT_SAVING,
  SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID,
  SCHEDULE_BLOCK_UNLOG,
} from "../../../../lib/constants";
import {
  isDeleted as isJobDeleted,
  mergeScheduleLoggedTimeWithJob,
} from "../../../../lib/entities/jobEntity";
import {
  isDeleted as isJobItemDeleted,
  mergeScheduleLoggedTimeWithJobItem,
} from "../../../../lib/entities/jobItemEntity";
import {
  isDeleted as isJobItemUserDeleted,
  mergeScheduleLoggedTimeWithJobItemUser,
} from "../../../../lib/entities/jobItemUserEntity";
import { mergeScheduleLoggedTimeWithRepeatingLoggedTime } from "../../../../lib/entities/repeatingLoggedTimeEntity";
import { notActive } from "../../../../lib/entities/scheduleLoggedTimeEntity";
import { EntityNames } from "../../../../state/entities/types";
import { SCHEDULE_LOGGED_TIMES_PURGE } from "../../../../state/ui/schedule/actions";
import createReducer from "../../../helpers/createReducer";
import mergeEntityPayload from "../../../helpers/mergeEntityPayload";
import parseEntityPayload from "../../../helpers/parseEntityPayload";
import parseRemovedEntities from "../../../helpers/parseRemovedEntities";
import reduceChangedEntities from "./reduceChangedEntities";
import clearRepeatingLoggedTimeIdReducer from "./scheduleBlockReducers/clearRepeatingLoggedTimeIdReducer";
import deleteBlockReducer from "./scheduleBlockReducers/deleteBlockReducer";
import dragBlockReducer from "./scheduleBlockReducers/dragBlockReducer";
import dragMultipleBlockReducer from "./scheduleBlockReducers/dragMultipleBlockReducer";
import duplicateBlockReducer from "./scheduleBlockReducers/duplicateBlockReducer";
import logBlockReducer from "./scheduleBlockReducers/logBlockReducer";
import paintBlockReducer from "./scheduleBlockReducers/paintBlockReducer";
import reassignBlockReducer from "./scheduleBlockReducers/reassignBlockReducer";
import resizeBlockReducer from "./scheduleBlockReducers/resizeBlockReducer";
import savedBlockReducer from "./scheduleBlockReducers/savedBlockReducer";
import splitBlockReducer from "./scheduleBlockReducers/splitBlockReducer";
import undoClearRepeatingLoggedTimeIdReducer from "./scheduleBlockReducers/undoClearRepeatingLoggedTimeIdReducer";
import undoDeleteBlockReducer from "./scheduleBlockReducers/undoDeleteBlockReducer";
import undoDragBlockReducer from "./scheduleBlockReducers/undoDragBlockReducer";
import undoDuplicateBlockReducer from "./scheduleBlockReducers/undoDuplicateBlockReducer";
import undoPaintBlockReducer from "./scheduleBlockReducers/undoPaintBlockReducer";
import undoReassignBlockReducer from "./scheduleBlockReducers/undoReassignBlockReducer";
import undoResizeBlockReducer from "./scheduleBlockReducers/undoResizeBlockReducer";
import undoSplitBlockReducer from "./scheduleBlockReducers/undoSplitBlockReducer";
import unlogBlockReducer from "./scheduleBlockReducers/unlogBlockReducer";

const receiveEntitiesReducer = (state, action) => {
  let nextState = state;

  nextState = reduceChangedEntities(
    nextState,
    parseEntityPayload(
      nextState,
      action.payload[EntityNames.ScheduleLoggedTimes],
      notActive
    )
  );

  nextState = reduceChangedEntities(
    nextState,
    mergeEntityPayload(
      nextState,
      action.payload[ENTITY_NAME_JOBS],
      isJobDeleted,
      "idsByJobId",
      mergeScheduleLoggedTimeWithJob
    )
  );

  nextState = reduceChangedEntities(
    nextState,
    mergeEntityPayload(
      nextState,
      action.payload[ENTITY_NAME_JOB_ITEMS],
      isJobItemDeleted,
      "idsByJobItemId",
      mergeScheduleLoggedTimeWithJobItem
    )
  );

  nextState = reduceChangedEntities(
    nextState,
    mergeEntityPayload(
      nextState,
      action.payload[ENTITY_NAME_JOB_ITEM_USERS],
      isJobItemUserDeleted,
      "idsByJobItemUserId",
      mergeScheduleLoggedTimeWithJobItemUser
    )
  );

  nextState = reduceChangedEntities(
    nextState,
    mergeEntityPayload(
      nextState,
      action.payload[ENTITY_NAME_REPEATING_LOGGED_TIMES],
      () => false,
      "idsByRepeatingLoggedTimeId",
      mergeScheduleLoggedTimeWithRepeatingLoggedTime
    )
  );

  return nextState;
};

const removeEntitiesReducer = (state, { payload: { entityName, ids } }) => {
  if (entityName !== EntityNames.ScheduleLoggedTimes) return state;
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
};

const saveBlockErrorReducer = (state, action) => {
  if (action.payload.sagaType === SCHEDULE_BLOCK_DRAG_END)
    return undoDragBlockReducer(state, action);

  if (action.payload.sagaType === SCHEDULE_BLOCK_RESIZE_END)
    return undoResizeBlockReducer(state, action);

  if (action.payload.sagaType === SCHEDULE_BLOCK_REASSIGN)
    return undoReassignBlockReducer(state, action);

  if (action.payload.sagaType === SCHEDULE_BLOCK_DUPLICATE)
    return undoDuplicateBlockReducer(state, action);

  if (action.payload.sagaType === SCHEDULE_BLOCK_LOG)
    return unlogBlockReducer(state, action);

  if (action.payload.sagaType === SCHEDULE_BLOCK_UNLOG)
    return logBlockReducer(state, action);

  return state;
};

function purgeScheduleLoggedTimes(state) {
  const ids = keys(get(state, "byId", EMPTY_OBJECT));
  return reduceChangedEntities(state, parseRemovedEntities(state, ids));
}

export default createReducer(
  {},
  {
    [ENTITIES_RECEIVED]: receiveEntitiesReducer,
    [ENTITIES_REMOVED]: removeEntitiesReducer,
    [SCHEDULE_BLOCK_DRAG_CANCEL]: undoDragBlockReducer,
    [SCHEDULE_BLOCK_DRAG_END]: dragBlockReducer,
    [SCHEDULE_BLOCK_DRAG_MULTIPLE]: dragMultipleBlockReducer,
    [SCHEDULE_BLOCK_RESIZE]: resizeBlockReducer,
    [SCHEDULE_BLOCK_RESIZE_CANCEL]: undoResizeBlockReducer,
    [SCHEDULE_BLOCK_PAINT_START]: paintBlockReducer,
    [SCHEDULE_BLOCK_PAINT]: resizeBlockReducer,
    [SCHEDULE_BLOCK_PAINT_CANCEL]: undoPaintBlockReducer,
    [SCHEDULE_BLOCK_REASSIGN]: reassignBlockReducer,
    [SCHEDULE_BLOCK_DUPLICATE]: duplicateBlockReducer,
    [SCHEDULE_BLOCK_LOG]: logBlockReducer,
    [SCHEDULE_BLOCK_UNLOG]: unlogBlockReducer,
    [SCHEDULE_BLOCK_DELETE]: deleteBlockReducer,
    [SCHEDULE_BLOCK_DELETE_ERROR]: undoDeleteBlockReducer,
    [SCHEDULE_BLOCK_SAVED]: savedBlockReducer,
    [SCHEDULE_BLOCK_SAVE_ERROR]: saveBlockErrorReducer,
    [SCHEDULE_BLOCK_CLEAR_REPEATING_LOGGED_TIME_ID]:
      clearRepeatingLoggedTimeIdReducer,
    [SCHEDULE_BLOCK_UNDO_CLEAR_REPEATING_LOGGED_TIME_ID]:
      undoClearRepeatingLoggedTimeIdReducer,
    [SCHEDULE_BLOCK_SPLIT_SAVING]: splitBlockReducer,
    [SCHEDULE_BLOCK_SPLIT_SAVE_ERROR]: undoSplitBlockReducer,
    [SCHEDULE_LOGGED_TIMES_PURGE]: purgeScheduleLoggedTimes,
  }
);
