import { findIndex } from "lodash-es";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { getAvailabilityDates } from "../../../lib/dates";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPhaseTeamSummary } from "../../../redux/selectors/jobDetails/ui/selectJobPhaseTeamSummary";
import { useJobPhaseEstimateStartEndDates } from "../../../redux/selectors/jobPhase/selectJobPhaseEstimatedStartEndDates";
import { actionJobPhaseAssignRoleTime } from "../../../state/entities/jobPhase/actions";
import { AssignAllRoleMenu } from "../../modules/UserRoleMenus/AssignAllRoleMenu";
import { SelectRoleMenu } from "../../modules/UserRoleMenus/SelectRoleMenu";
import { JobRoleIcon } from "../components/JobRoleIcon";
import { JobUserIcon, OtherUsersIcon } from "../components/JobUserIcons";
import { useJobId } from "../context/JobIdContext";
import { useJobPhaseId } from "../context/JobPhaseIdContext";
import { useObjectWithCount } from "../hooks/useObjectWithCount";
import { BaseItem } from "../styles";
import * as styles from "./PhaseTeamMembers.css";

const leftPadding = 30;
const iconSize = 30;
const iconPadding = -8;

const PhaseTeamMembers = () => {
  const jobPhaseId = useJobPhaseId();
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const { users, roles } = useJobPhaseTeamSummary(jobPhaseId);

  const [ref, count] = useObjectWithCount(iconSize, leftPadding, iconPadding);

  const topUsers = users.slice(0, count - 1);
  const otherUsers = users.slice(count - 1, users.length);

  const roleRef = useRef(null);
  const [selectRoleMenuOpen, setSelectRoleMenuOpen] = useState(false);
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const dispatch = useDispatch();

  const [estimatedStartDate, estimatedEndDate] =
    useJobPhaseEstimateStartEndDates(jobPhaseId);

  const [defaultStartDate, defaultEndDate] = getAvailabilityDates(
    estimatedStartDate,
    estimatedEndDate
  );

  function openRoleMenu() {
    if (!isJobEditable) return;
    setSelectRoleMenuOpen(true);
  }

  function closeRoleMenu() {
    setSelectedRole(null);
    setSelectRoleMenuOpen(false);
  }

  function selectRole(roleId) {
    closeRoleMenu();
    const index = findIndex(roles, ["id", roleId]);
    if (index !== -1) {
      setSelectedRole(roles[index]);
      setAssignMenuOpen(true);
    }
  }

  function closeAssignMenu() {
    setSelectedRole(null);
    setAssignMenuOpen(false);
  }

  function assignUser(userId) {
    dispatch(actionJobPhaseAssignRoleTime(jobPhaseId, selectedRole.id, userId));
    closeAssignMenu();
  }

  return (
    <Container ref={ref}>
      {roles.length > 0 && (
        <>
          <div className={styles.roleIcon} ref={roleRef}>
            <JobRoleIcon
              roles={roles}
              size={iconSize}
              onClick={openRoleMenu}
              onRoleSelect={selectRole}
              disabled={!isJobEditable}
            />
          </div>
          {selectRoleMenuOpen && (
            <SelectRoleMenu
              anchorEl={roleRef.current}
              close={closeRoleMenu}
              roles={roles}
              selectRole={selectRole}
            />
          )}
          {assignMenuOpen && (
            <AssignAllRoleMenu
              anchorEl={roleRef.current}
              assignUser={assignUser}
              close={closeAssignMenu}
              role={selectedRole}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          )}
        </>
      )}
      <UsersContainer>
        {topUsers.map((user) => (
          <JobUserIcon key={user.id} user={user} size={iconSize} />
        ))}
        <OtherUsersIcon users={otherUsers} size={iconSize} />
      </UsersContainer>
    </Container>
  );
};

export default PhaseTeamMembers;

const Container = styled(BaseItem)`
  min-width: 90px;
  flex: 1;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(:first-child) {
    margin-left: ${iconPadding}px;
  }
`;
