import * as PropTypes from "prop-types";
import React from "react";

import { entityIdType } from "../../../lib/types/entityTypes";
import {
  commercialDocumentEntityType,
  getCommercialDocumentTypeName,
} from "../../../lib/ui/commercialDocument";
import RichTextFieldEditor from "../DraftJsEditor/RichTextFieldEditor";
import consumeCommercialDocument from "./CommercialDocumentContext/Consumers/consumeCommercialDocument";
import Section from "./Layout/Section";

const mapContext = ({
  id,
  document,
  commercialDocumentType,
  documentMethods,
  actions: { doSaveDocument },
}) => ({
  id,
  document,
  getValue: documentMethods.getIntroduction,
  setValue: documentMethods.setIntroduction,
  isDisplayed: documentMethods.getDisplayOptions(document).displayIntro,
  documentTypeName: getCommercialDocumentTypeName(commercialDocumentType),
  doSaveDocument,
});

class DocumentIntro extends React.PureComponent {
  static propTypes = {
    id: entityIdType.isRequired,
    document: commercialDocumentEntityType.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    isDisplayed: PropTypes.bool.isRequired,
    documentTypeName: PropTypes.string.isRequired,
    doSaveDocument: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const { document, getValue } = props;
    const { hasFocus } = state;

    if (hasFocus) return state;

    return {
      ...state,
      value: getValue(document),
    };
  }

  state = {
    value: "",
    hasFocus: false,
  };

  save = (value) => {
    const { id, document, getValue, setValue, doSaveDocument } = this.props;

    if (value !== getValue(document))
      doSaveDocument(id, setValue(document, value), document);
  };

  handleChange = (newValue) => {
    const { hasFocus, value } = this.state;

    if (hasFocus && value !== newValue) {
      this.setState({ value: newValue });
    }
  };

  handleBlur = () => {
    const { value } = this.state;

    this.save(value);

    this.setState({
      hasFocus: false,
    });
  };

  handleFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  render() {
    const { document, getValue, isDisplayed, documentTypeName } = this.props;

    if (!isDisplayed) return null;

    return (
      <Section margin={5}>
        <RichTextFieldEditor
          placeholder={`Add an introduction to this ${documentTypeName.toLocaleLowerCase()}...`}
          defaultHtml={getValue(document)}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
      </Section>
    );
  }
}

export default consumeCommercialDocument(mapContext)(DocumentIntro);
