import { put, select, takeLatest } from "redux-saga/effects";
import {
  INVOICE_LINE_ITEM_SAVE,
  INVOICE_LINE_ITEM_SET_ACCOUNT_CODE
} from "../../../lib/constants";
import {
  getExternalTaxRateName,
  getExternalTaxRateRate
} from "../../../lib/ui/accountingPlatform";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectRevenueAccountDefaultTaxRateByAccountCode } from "../../selectors/accountingPlatformSelectors";
import { selectInvoiceLineItemById } from "../../selectors/invoiceLineItemSelectors";

function* setAccountCode(action) {
  try {
    const { id, accountCode } = action.payload;

    const prevInvoiceLineItem = yield select(selectInvoiceLineItemById, { id });

    const invoiceLineItem = {
      ...prevInvoiceLineItem,
      accountCode
    };

    const externalTaxRate = yield select(
      selectRevenueAccountDefaultTaxRateByAccountCode,
      { accountCode }
    );

    if (externalTaxRate) {
      const taxName = getExternalTaxRateName(externalTaxRate);
      const taxRate = getExternalTaxRateRate(externalTaxRate);
      const externalTaxRateId = externalTaxRate.id;

      Object.assign(invoiceLineItem, {
        taxName,
        taxRate,
        externalTaxRateId
      });
    }

    yield put(
      createAction(INVOICE_LINE_ITEM_SAVE, {
        id,
        invoiceLineItem,
        prevInvoiceLineItem
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchInvoiceLineItemSetAccountCode() {
  yield takeLatest(INVOICE_LINE_ITEM_SET_ACCOUNT_CODE, setAccountCode);
}
