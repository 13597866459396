import clsx from "clsx";
import type { ComponentPropsWithoutRef, RefObject } from "react";
import { createContext, useContext, useRef } from "react";

import { IntercomPage } from "./IntercomPage";
import * as styles from "./styles.css";

export type PageProps = ComponentPropsWithoutRef<"div"> & {
  /** Used to send to intercom if visited */
  pageId?: string;
  backgroundVariant?: "secondary" | "tertiary";
};

export function Page({
  pageId,
  backgroundVariant,
  className,
  ...rest
}: PageProps) {
  const pageRef = useRef<HTMLDivElement>(null);

  return (
    <IntercomPage pageId={pageId}>
      <PageRefContext.Provider value={pageRef}>
        <div
          ref={pageRef}
          className={clsx(styles.page({ backgroundVariant }), className)}
          {...rest}
        />
      </PageRefContext.Provider>
    </IntercomPage>
  );
}

const PageRefContext = createContext<RefObject<HTMLDivElement>>(
  null as unknown as RefObject<HTMLDivElement>
);

export function usePageRef() {
  const context = useContext(PageRefContext);
  if (!context) {
    throw new Error(`Missing PageRefContext`);
  }
  return context;
}
