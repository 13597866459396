import { useDispatch } from "react-redux";
import { JobItemUserStatuses } from "st-shared/entities/JobItemUserStatus";

import {
  JOB_ITEM_STATUS_ID_COMPLETE,
  JOB_ITEM_STATUS_ID_PLANNING,
  JOB_ITEM_STATUS_ID_SCHEDULED,
  JOB_PHASE_STATUS_CHANGE,
} from "../../../lib/constants";
import { createJobItemStatusType } from "../../../lib/entities/jobItemEntity";
import { createJobItemUserStatusType } from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobPhaseStatusClass } from "../../../redux/selectors/jobPhase/selectJobPhaseStatusClass";
import Spacer from "../../elements/Spacer";
import { StatusComponent } from "../components/StatusComponent";
import { ItemColumnWidths } from "../consts/ItemColumnWidths";
import { useJobId } from "../context/JobIdContext";
import { useJobPhaseId } from "../context/JobPhaseIdContext";

function PhaseStatus() {
  const jobId = useJobId();
  const isJobEditable = useIsJobEditable(jobId);
  const jobPhaseId = useJobPhaseId();
  const statusId = useJobPhaseStatusClass(jobPhaseId);

  const dispatch = useDispatch();

  const doStatusChange = (status, userStatus) => {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_PHASE_STATUS_CHANGE, {
        jobPhaseId,
        jobItemStatus: createJobItemStatusType(status),
        jobItemUserStatus: createJobItemUserStatusType(userStatus),
      })
    );
  };

  const setPlanning = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_PLANNING, JobItemUserStatuses.Planning);

  const setSchedule = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_SCHEDULED, JobItemUserStatuses.Scheduled);

  const setComplete = () =>
    doStatusChange(JOB_ITEM_STATUS_ID_COMPLETE, JobItemUserStatuses.Complete);

  if (statusId === "") return <Spacer h={46} w={ItemColumnWidths.Status} />;

  return (
    <StatusComponent
      statusId={statusId}
      setPlanning={setPlanning}
      setSchedule={setSchedule}
      setComplete={setComplete}
      disabled={!isJobEditable}
      locked={statusId === "complete"}
      lockedTooltipText="Want to keep working on this phase? Change the status of an item or team member."
    />
  );
}

export default PhaseStatus;
