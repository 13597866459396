import React from "react";
import { entityIdType } from "../../../lib/types/entityTypes";
import { useInvoicePaymentIdsByInvoice } from "../../../redux/selectors/invoicePayment/invoicePaymentIdsByInvoice";
import { useCommercialDocument } from "../../modules/CommercialDocuments/CommercialDocumentContext";
import InvoicePaymentRow from "./InvoicePaymentRow";
import { PaymentSummaryContainer } from "./styles";

const InvoicePaymentSummary = ({ invoiceId }) => {
  const invoicePaymentIds = useInvoicePaymentIdsByInvoice(invoiceId);

  const { document, documentMethods } = useCommercialDocument();
  const { displayPaymentSummary } = documentMethods.getDisplayOptions(document);

  if (!displayPaymentSummary || invoicePaymentIds.length === 0) return null;

  return (
    <PaymentSummaryContainer>
      <thead>
        <tr>
          <th colSpan="3">Payment Summary</th>
        </tr>
      </thead>
      <tbody>
        {invoicePaymentIds.map(invoicePaymentId => (
          <InvoicePaymentRow
            key={invoicePaymentId}
            invoicePaymentId={invoicePaymentId}
          />
        ))}
      </tbody>
    </PaymentSummaryContainer>
  );
};

InvoicePaymentSummary.propTypes = {
  invoiceId: entityIdType.isRequired
};

export default InvoicePaymentSummary;
