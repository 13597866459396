import { debounce } from "lodash-es";
import { useEffect, useMemo, useRef } from "react";

function useDebounce(callback: (...args: any) => any, delay = 200) {
  const ref = useRef<(...args: any) => any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: any) => {
      ref.current?.(...args);
    };

    return debounce(func, delay);
  }, []);

  return debouncedCallback;
}

export default useDebounce;
