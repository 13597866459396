import { Entity } from "@streamtimefe/entities";
import { produce } from "immer";
import { findIndex } from "lodash-es";
import { call, delay, put, select } from "redux-saga/effects";
import type { JobItemRole } from "st-shared/entities/JobItemRole";

import {
  ENTITY_NAME_JOB_ITEM_USERS,
  JOB_ITEM_USER_EDIT,
} from "../../../../lib/constants";
import { getJobItemStatusId } from "../../../../lib/entities/jobItemEntity";
import {
  createNewJobItemUser,
  getJobItemUserStatusFromJobItemStatus,
} from "../../../../lib/entities/jobItemUserEntity";
import createAction from "../../../../redux/helpers/createAction";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { selectJobItem } from "../../../../redux/selectors/jobItem";
import { selectJobItemUser } from "../../../../redux/selectors/jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../../../../redux/selectors/jobItemUser/selectJobItemUserIdsByJobItemId";
import { actionEntitiesReceived, actionEntitiesRemoved } from "../../actions";
import { actionJobItemRoleRecalculateHours } from "../../jobItem/actions";
import { EntityNames } from "../../types";
import type { JobItemRoleAssignUsersAction } from "../actions";
import { actionJobItemRoleEdit, JOB_ITEM_ROLE_ASSIGN_USERS } from "../actions";
import JobItemRoleAPI from "../api";
import { selectJobItemRole } from "../selectors/selectJobItemRole";

function* assignUsers(action: JobItemRoleAssignUsersAction) {
  try {
    const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
      jobItemRoleId: action.jobItemRoleId,
    });
    const jobItem = yield select(selectJobItem, {
      jobItemId: jobItemRole.jobItemId,
    });

    const jobItemUserIds = yield select(selectJobItemUserIdsByJobItemId, {
      jobItemId: jobItemRole.jobItemId,
    });
    const jobItemUsers = [];

    for (let i = 0; i < jobItemUserIds.length; i++) {
      const jobItemUser = yield select(selectJobItemUser, {
        jobItemUserId: jobItemUserIds[i],
      });
      jobItemUsers.push(jobItemUser);
    }

    const editedJobItemUsers: { new: any; prev?: any }[] = [];

    for (let i = 0; i < action.users.length; i++) {
      const jobItemUserIndex = findIndex(
        jobItemUsers,
        (jobItemUser) => jobItemUser.userId === action.users[i].userId
      );

      if (jobItemUserIndex !== -1) {
        // adjust users minutes
        const jobItemUser = jobItemUsers[jobItemUserIndex];
        const newJobItemUser = produce(jobItemUser, (draft: any) => {
          draft.totalPlannedMinutes += action.users[i].minutes;
        });
        editedJobItemUsers.push({ new: newJobItemUser, prev: jobItemUser });
      } else {
        // create new job item user
        const jobItemUserStatus = getJobItemUserStatusFromJobItemStatus(
          getJobItemStatusId(jobItem)
        );

        const newJobItemUser = createNewJobItemUser({
          id: Entity.temporaryId(),
          jobId: jobItem.jobId,
          jobItemId: jobItem.id,
          userId: action.users[i].userId,
          jobCurrencySellRate: jobItemRole.jobCurrencySellRate,
          jobItemUserStatus,
          totalPlannedMinutes: action.users[i].minutes,
        });

        editedJobItemUsers.push({ new: newJobItemUser });
      }
    }

    const newJobItemRole = produce(jobItemRole, (draft) => {
      draft.totalPlannedMinutes -= action.users.reduce(
        (total, user) => total + user.minutes,
        0
      );
      if (draft.totalPlannedMinutes <= 0) {
        draft.totalPlannedMinutes = 0;
        draft.active = false;
      }
    });

    if (action.save === "jobItem") {
      yield jobItemAssign(newJobItemRole, editedJobItemUsers);
    } else if (action.save === "api") {
      yield apiAssign(jobItemRole, newJobItemRole, editedJobItemUsers);
    }
  } catch (error) {
    sagaError(error);
  }
}

function* jobItemAssign(
  jobItemRole: JobItemRole,
  editedJobItemUsers: { new: any; prev?: any }[]
) {
  const newJobItemUsers = editedJobItemUsers
    .filter((val) => "prev" in val === false)
    .map((val) => val.new);
  if (newJobItemUsers.length > 0) {
    yield put(
      actionEntitiesReceived({
        [ENTITY_NAME_JOB_ITEM_USERS]: newJobItemUsers,
      })
    );
  }

  if (editedJobItemUsers.length > 0) {
    yield put(
      createAction(JOB_ITEM_USER_EDIT, {
        jobItemId: jobItemRole.jobItemId,
        jobItemUsers: editedJobItemUsers,
      })
    );
  }

  yield put(actionJobItemRoleEdit(jobItemRole.jobItemId, jobItemRole));
  yield put(actionJobItemRoleRecalculateHours(jobItemRole.jobItemId));
}

function* apiAssign(
  jobItemRole: JobItemRole,
  newJobItemRole: JobItemRole,
  editedJobItemUsers: { new: any; prev?: any }[]
) {
  try {
    yield delay(20);

    yield put(
      actionEntitiesReceived({
        [EntityNames.JobItemRoles]: [newJobItemRole],
        [ENTITY_NAME_JOB_ITEM_USERS]: editedJobItemUsers.map((val) => val.new),
      })
    );

    const data: Awaited<ReturnType<typeof JobItemRoleAPI.assignUsers>> =
      yield call(
        JobItemRoleAPI.assignUsers,
        jobItemRole.id,
        editedJobItemUsers.map((val) => val.new)
      );

    yield put(actionEntitiesReceived(data));
  } catch (error) {
    const previousJobItemUsers = editedJobItemUsers
      .filter((val) => "prev" in val)
      .map((val) => val.prev);

    yield put(
      actionEntitiesReceived({
        [EntityNames.JobItemRoles]: [jobItemRole],
        [ENTITY_NAME_JOB_ITEM_USERS]: previousJobItemUsers,
      })
    );

    sagaError(error);
  } finally {
    const newJobItemUserIds = editedJobItemUsers
      .filter((val) => "prev" in val === false)
      .map((val) => val.new.id);

    yield put(
      actionEntitiesRemoved({
        entityName: ENTITY_NAME_JOB_ITEM_USERS,
        ids: newJobItemUserIds,
      })
    );
  }
}

export default function* watchJobItemRoleAssignUsers() {
  yield takeLatestBy(
    [JOB_ITEM_ROLE_ASSIGN_USERS],
    assignUsers,
    (action: JobItemRoleAssignUsersAction) => action.jobItemRoleId
  );
}
