import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ActivityEntryParentTypes,
  createActivityEntryParentType,
} from "st-shared/entities/ActivityEntryParentType";
import {
  ActivityEntryTypes,
  createActivityEntryType,
} from "st-shared/entities/ActivityEntryType";
import { useLoggedInUserId, usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT,
  ACTIVITY_ENTRY_SAVE,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { createActivityEntry } from "../../../lib/entities/activityEntryEntity";
import createAction from "../../../redux/helpers/createAction";
import { useJobActivityCommentsFilter } from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import CommentEditor from "../../modules/DraftJsEditor/CommentEditor";
import { useJobId } from "../context/JobIdContext";
import JobActivityCreatePostButton from "./JobActivityCreatePostButton";

const JobActivityFooter = ({ onScrollToBottom }) => {
  const jobId = useJobId();
  const { canAccessCollaboration } = usePermissions();
  const loggedInUserId = useLoggedInUserId();
  const dispatch = useDispatch();

  const isCommentsFilterActive = useJobActivityCommentsFilter();

  const [id, setId] = useState(Entity.temporaryId());
  const [message, setMessage] = useState("");
  const [mentionedUserIds, setMentionedUserIds] = useState("");

  const isValidMetaDataSize = () => {
    const metaDataSize = JSON.stringify({ message }).length;
    return metaDataSize <= ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT;
  };

  const formValidationMessages = () => {
    const messages = [];

    if (!message) messages.push("Enter a comment");

    if (!isValidMetaDataSize()) messages.push("This comment is too long");

    return messages;
  };

  const onChangeHtml = (html, userIds) => {
    setMessage(html);
    setMentionedUserIds(userIds);
  };

  const saveComment = () => {
    dispatch(
      createAction(ACTIVITY_ENTRY_SAVE, {
        activityEntry: createActivityEntry({
          id,
          activityEntryType: createActivityEntryType(
            ActivityEntryTypes.Comment
          ),
          activityEntryParentType: createActivityEntryParentType(
            ActivityEntryParentTypes.Job
          ),
          parentEntityId: jobId,
          metaData: JSON.stringify({ message }),
          createdUserId: loggedInUserId,
        }),
        mentionedUserIds,
      })
    );

    setId(Entity.temporaryId());
    setMessage("");
    setMentionedUserIds("");

    if (onScrollToBottom) onScrollToBottom();

    if (!isCommentsFilterActive)
      dispatch(
        createAction(SET_USER_PREFERENCE, {
          key: UserPreferenceKeys.USER_PREFERENCE_JOB_ACTIVITY_COMMENTS_FILTER,
          value: true,
        })
      );
  };

  if (!canAccessCollaboration) return null;

  return (
    <Wrapper>
      <JobActivityCreatePostButton />
      <CommentEditor
        key={id}
        onChange={onChangeHtml}
        onSave={saveComment}
        formValidationMessages={formValidationMessages()}
      />
    </Wrapper>
  );
};

JobActivityFooter.propTypes = {
  onScrollToBottom: PropTypes.func,
};

JobActivityFooter.defaultProps = {
  onScrollToBottom: null,
};

export default JobActivityFooter;

const Wrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  border-top: var(--border-thin-bright);
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: end;
  background-color: white;
`;
