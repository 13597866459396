import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  timeAllocationMethodObj,
  TimeAllocationMethods,
} from "st-shared/entities";

import {
  JOB_DETAILS_SET_CURRENT_FOCUS,
  JOB_ITEM_SET_TIME_ALLOCATION_METHOD,
  JOB_ITEM_USER_SET_HOURS,
} from "../../../lib/constants";
import { FOCUS_KEYS } from "../../../lib/constants/jobDetails";
import {
  getTotalPlannedMinutes,
  getTotalUsedMinutes,
} from "../../../lib/entities/jobItemUserEntity";
import createAction from "../../../redux/helpers/createAction";
import { useIsJobEditable } from "../../../redux/selectors/jobDetails/ui/isJobEditable";
import { useJobItem } from "../../../redux/selectors/jobItem";
import { useJobItemUser } from "../../../redux/selectors/jobItemUser";
import {
  getInitialItemUserHoursInput,
  useJobItemUserHoursInput,
} from "../../../redux/selectors/jobItemUser/selectJobItemUserHoursInput";
import { ItemInputField } from "../components/ItemInputField";
import { MethodLock } from "../components/MethodLock";
import { methodLockIcon } from "../components/MethodLock.css";
import { useJobId } from "../context/JobIdContext";
import { useJobItemId } from "../context/JobItemIdContext";
import { useJobItemUserId } from "../context/JobItemUserIdContext";
import { useFocusStateRef } from "../hooks/useFocusStateInputRef";
import {
  itemHoursContainerCss,
  itemHoursFieldCss,
} from "../JobItem/ItemHours.css";

export function ItemUserHours() {
  const jobId = useJobId();
  const jobItemId = useJobItemId();
  const jobItemUserId = useJobItemUserId();
  const jobItemUser = useJobItemUser(jobItemUserId);
  const jobItem = useJobItem(jobItemId);
  const isJobEditable = useIsJobEditable(jobId);
  const timeAllocationMethod = timeAllocationMethodObj(
    jobItem.timeAllocationMethod
  );

  const [ref, focused, setFocused] = useFocusStateRef<HTMLInputElement>({
    jobItemUserId,
    jobItemId,
    key: FOCUS_KEYS.ITEM_USER_HOURS,
  });

  const [hours, setHours] = useState("");

  const [value, placeholder, warning] = useJobItemUserHoursInput(
    jobItemUserId,
    focused,
    hours
  );

  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isJobEditable) return;
    const newValue = event.target.value;

    setHours(newValue);

    dispatch(
      createAction(JOB_ITEM_USER_SET_HOURS, {
        jobItemUserId,
        value: newValue,
      })
    );

    if (!timeAllocationMethod.isPeople())
      dispatch(
        createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
          jobItemId,
          timeAllocationMethodId: TimeAllocationMethods.People,
        })
      );
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setHours(getInitialItemUserHoursInput(jobItemUser));
    setFocused(true);
  };

  const usedPlannedProgress =
    getTotalPlannedMinutes(jobItemUser) > 0 &&
    getTotalUsedMinutes(jobItemUser) <= getTotalPlannedMinutes(jobItemUser)
      ? Math.min(
          1,
          getTotalUsedMinutes(jobItemUser) / getTotalPlannedMinutes(jobItemUser)
        )
      : 0;

  function setTimeAllocationMethod() {
    if (!isJobEditable) return;
    dispatch(
      createAction(JOB_DETAILS_SET_CURRENT_FOCUS, {
        currentFocus: {
          jobItemId,
          jobItemUserId,
          key: FOCUS_KEYS.ITEM_USER_HOURS,
        },
      })
    );
    dispatch(
      createAction(JOB_ITEM_SET_TIME_ALLOCATION_METHOD, {
        jobItemId,
        timeAllocationMethodId: TimeAllocationMethods.People,
      })
    );
  }

  return (
    <div className={itemHoursContainerCss}>
      <ItemInputField
        ref={ref}
        as="time"
        containerClassName={itemHoursFieldCss}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabledLook={!timeAllocationMethod.isPeople()}
        disabled={!isJobEditable}
        warning={warning}
        progress={usedPlannedProgress}
      />
      {isJobEditable && !timeAllocationMethod.isPeople() && (
        <MethodLock
          tooltip={
            <>
              Planned time for this item is a{" "}
              <strong>
                shared total of hours for all assigned team members.
              </strong>{" "}
              Click to change and plan hours by each team member.
            </>
          }
          className={methodLockIcon}
          onClick={setTimeAllocationMethod}
          isTime
        />
      )}
    </div>
  );
}
