import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
} from "@material-ui/core";
import clsx from "clsx";

import {
  radioButonCss,
  radioButtonLabelCss,
  radioButtonLabelSpanCss,
} from "./RadioButton.css";

export interface RadioButtonProps extends RadioProps {
  labelProps?: Omit<FormControlLabelProps, "control" | "onChange">;
}

export function RadioButton({ labelProps, ...props }: RadioButtonProps) {
  function renderRadio() {
    return (
      <Radio
        {...props}
        className={clsx(radioButonCss, props.className)}
        disableRipple
        disableTouchRipple
        disableFocusRipple
      />
    );
  }

  if (labelProps) {
    return (
      <FormControlLabel
        control={renderRadio()}
        {...labelProps}
        className={clsx(radioButtonLabelCss, labelProps.className)}
        classes={{
          ...labelProps.classes,
          label: clsx(radioButtonLabelSpanCss, labelProps.classes?.label),
        }}
      />
    );
  }

  return renderRadio();
}
