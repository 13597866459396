import clsx from "clsx";
import { forwardRef } from "react";

import { chartTooltipContainer } from "./ChartTooltip.css";

interface Props {
  className?: string;
}

export const ChartTooltip = forwardRef<HTMLDivElement, Props>(
  ({ className }, ref) => {
    return <div ref={ref} className={clsx(chartTooltipContainer, className)} />;
  }
);

ChartTooltip.displayName = "ChartTooltip";
