let count = 0;

const getEnum = () => {
  const id = count;
  count += 1;
  return id;
};

export const FOCUS_KEYS = Object.freeze({
  // ITEM
  ITEM_GENERAL: getEnum(),
  ITEM_NAME: getEnum(),
  ITEM_HOURS: getEnum(),
  ITEM_RATE: getEnum(),
  ITEM_TOTAL: getEnum(),
  ITEM_USER_HOURS: getEnum(),
  ITEM_USER_RATE: getEnum(),
  ITEM_NEW_USER: getEnum(),
  ITEM_START_DATE: getEnum(),
  ITEM_END_DATE: getEnum(),
  ITEM_SUB_ITEM: getEnum(),
  ITEM_NEW_SUB_ITEM: getEnum(),
  ITEM_ADDITIONAL_INFORMATION: getEnum(),

  ITEM_ROLE_HOURS: getEnum(),
  ITEM_ROLE_RATE: getEnum(),
  ITEM_ROLE_ASSIGN: getEnum(),

  ACTION_BUTTON_TEAM_MEMBER: getEnum(),
  ACTION_BUTTON_CHECKLIST: getEnum(),
  ACTION_BUTTON_ADDITIONAL_INFORMATION: getEnum(),

  // PHASE
  PHASE_NAME: getEnum(),

  // JOB
  JOB_NAME: getEnum(),
  JOB_BUDGET: getEnum(),
  JOB_JOB_NUMBER: getEnum(),
  JOB_PO_NUMBER: getEnum(),
});

export const ITEM_FOCUS_KEYS = [
  FOCUS_KEYS.ITEM_GENERAL,
  FOCUS_KEYS.ITEM_NAME,
  FOCUS_KEYS.ITEM_HOURS,
  FOCUS_KEYS.ITEM_RATE,
  FOCUS_KEYS.ITEM_TOTAL,
  FOCUS_KEYS.ITEM_USER_HOURS,
  FOCUS_KEYS.ITEM_USER_RATE,
  FOCUS_KEYS.ITEM_NEW_USER,
  FOCUS_KEYS.ITEM_START_DATE,
  FOCUS_KEYS.ITEM_END_DATE,
  FOCUS_KEYS.ITEM_SUB_ITEM,
  FOCUS_KEYS.ITEM_NEW_SUB_ITEM,
  FOCUS_KEYS.ITEM_ADDITIONAL_INFORMATION,
  FOCUS_KEYS.ACTION_BUTTON_TEAM_MEMBER,
  FOCUS_KEYS.ACTION_BUTTON_CHECKLIST,
  FOCUS_KEYS.ACTION_BUTTON_ADDITIONAL_INFORMATION,
  FOCUS_KEYS.ITEM_ROLE_HOURS,
  FOCUS_KEYS.ITEM_ROLE_RATE,
  FOCUS_KEYS.ITEM_ROLE_ASSIGN,
];

export const JOB_FOCUS_KEYS = [
  FOCUS_KEYS.JOB_NAME,
  FOCUS_KEYS.JOB_BUDGET,
  FOCUS_KEYS.JOB_JOB_NUMBER,
  FOCUS_KEYS.JOB_PO_NUMBER,
];

export const JOB_COST_VS_SELL_MODE = Object.freeze({
  SELL: 0,
  COST: 1,
});

export const JOB_BURN_UP_MODE = Object.freeze({
  USED_PLANNED_HOURS: 0,
  USED_PLANNED_COST: 1,
  USED_PLANNED_SELL: 2,
});

export const JOB_BOTTOM_SECTION_TABS = Object.freeze({
  JOB_PLAN: 0,
  TIMELINE: 1,
});

export const JOB_JUMBOTRON_SECTION_TABS = Object.freeze({
  OVERVIEW: 0,
  BURN_UP: 1,
  SETTINGS: 2,
});

export const JOB_ACTIVITY_SECTION_TABS = Object.freeze({
  DATES: 0,
  ACTIVITY: 1,
});
