import { isEqual } from "lodash-es";
import * as PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  ACTIVITY_ENTRY_DELETE,
  ACTIVITY_ENTRY_DELETE_ERROR,
  ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT,
  ACTIVITY_ENTRY_SAVE,
  ACTIVITY_ENTRY_SAVE_ERROR,
} from "../../../../../lib/constants";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getCreatedTimeFormatted,
  getMetaDataMessage,
} from "../../../../../lib/entities/activityEntryEntity";
import { getDisplayName } from "../../../../../lib/entities/userEntity";
import createAction from "../../../../../redux/helpers/createAction";
import {
  useActivityEntryError,
  useIsActivityEntryCreatedByLoggedInUser,
} from "../../../../../redux/selectors/activityEntrySelectors";
import { useUser } from "../../../../../redux/selectors/user";
import { IconButton } from "../../../../elements/Button";
import Flex from "../../../../elements/Flex";
import CancelIcon from "../../../../elements/Icons/CancelIcon";
import DeleteIcon from "../../../../elements/Icons/DeleteIcon";
import EditIcon from "../../../../elements/Icons/EditIcon";
import RetryIcon from "../../../../elements/Icons/RetryIcon";
import SystemMessageText from "../../../../elements/Typography/SystemMessageText";
import ConfirmDialog from "../../../../modules/ConfirmDialog";
import CommentEditor from "../../../../modules/DraftJsEditor/CommentEditor";
import { CommentViewer } from "../../../../modules/DraftJsEditor/CommentViewer";
import Popper from "../../../../modules/Popper";
import UserIconName from "../../../../modules/User/UserIconName";

const Comment = ({ activityEntry, dateTimeFormatter }) => {
  const createdByUser = useUser(activityEntry.createdUserId);
  const isCreatedByLoggedInUser = useIsActivityEntryCreatedByLoggedInUser(
    activityEntry.id
  );
  const error = useActivityEntryError(activityEntry.id);

  const actionsAnchorRef = useRef();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [mentionedUserIds, setMentionedUserIds] = useState("");

  const dispatch = useDispatch();
  const doSaveComment = (newActivityEntry, newMentionedUserIds) =>
    dispatch(
      createAction(ACTIVITY_ENTRY_SAVE, {
        activityEntry: newActivityEntry,
        mentionedUserIds: newMentionedUserIds,
      })
    );
  const doCancelSend = (id) =>
    dispatch(createAction(ACTIVITY_ENTRY_DELETE, { id }));
  const doDeleteJobActivity = (id) =>
    dispatch(createAction(ACTIVITY_ENTRY_DELETE, { id }));

  const isValidMetaDataSize = () => {
    const metaDataSize = JSON.stringify({ message }).length;
    return metaDataSize <= ACTIVITY_ENTRY_MAXIMUM_META_DATA_CHARACTER_LIMIT;
  };

  const formValidationMessages = () => {
    const messages = [];

    if (!message) messages.push("Enter a comment");

    if (!isValidMetaDataSize()) messages.push("This comment is too long");

    return messages;
  };

  const retryAction = () => {
    if (error.action === ACTIVITY_ENTRY_SAVE_ERROR)
      doSaveComment(activityEntry, error.mentionedUserIds);

    if (error.action === ACTIVITY_ENTRY_DELETE_ERROR)
      doDeleteJobActivity(activityEntry.id);
  };

  const cancelAction = () => {
    if (error.action === ACTIVITY_ENTRY_SAVE_ERROR)
      doCancelSend(activityEntry.id);
  };

  const deleteComment = () => {
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    doDeleteJobActivity(activityEntry.id);
    setIsConfirmDeleteOpen(false);
  };

  const cancelDelete = () => {
    setIsConfirmDeleteOpen(false);
  };

  const editComment = () => {
    setIsEditMode(true);
    setActionsAnchorEl(null);
    setMessage(getMetaDataMessage(activityEntry));
  };

  const cancelEdit = () => {
    setIsEditMode(false);
  };

  const onChangeHtml = (newMessage, newMentionedUserIds) => {
    if (
      newMessage !== message ||
      !isEqual(newMentionedUserIds, mentionedUserIds)
    ) {
      setMessage(newMessage);
      setMentionedUserIds(newMentionedUserIds);
    }
  };

  const saveEdit = () => {
    doSaveComment(
      {
        ...activityEntry,
        metaData: JSON.stringify({ message }),
      },
      mentionedUserIds
    );

    setIsEditMode(false);
  };

  const onMouseEnter = () => {
    setActionsAnchorEl(actionsAnchorRef.current);
  };

  const onMouseLeave = () => {
    setActionsAnchorEl(null);
  };

  return (
    <CommentWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <CommentHeader>
        <Author>
          <UserIconName
            id={getCreatedByUserId(activityEntry)}
            showDisplayName={false}
            size={25}
            nameWidth="0"
            style={{ width: "unset" }}
          />
          <AuthorName>{getDisplayName(createdByUser)}</AuthorName>
          <CommentDateTime>{dateTimeFormatter(activityEntry)}</CommentDateTime>
        </Author>
        {error && (
          <ErrorMessage>
            {error.displayMessage}
            <IconButton onClick={retryAction}>
              <RetryIcon />
            </IconButton>
            {error.action === ACTIVITY_ENTRY_SAVE_ERROR &&
              activityEntry.id < 0 && (
                <IconButton onClick={cancelAction}>
                  <CancelIcon />
                </IconButton>
              )}
          </ErrorMessage>
        )}
      </CommentHeader>
      {!isEditMode && (
        <ViewCommentContainer>
          <div ref={actionsAnchorRef}>
            <CommentBody>
              <Arrow>
                <svg width="20" height="8" viewBox="0 0 20 8" fill="none">
                  <path
                    d="M10.1979 0.830078L0.716797 7.33008H19.8499L10.1979 0.830078Z"
                    style={{
                      fill: isCreatedByLoggedInUser
                        ? "#C3E9F0"
                        : "var(--color-gray-bright)",
                    }}
                  />
                </svg>
              </Arrow>
              <CommentViewer
                style={{
                  backgroundColor: isCreatedByLoggedInUser
                    ? "#C3E9F0"
                    : "var(--color-gray-bright)",
                }}
                html={getMetaDataMessage(activityEntry)}
              />
            </CommentBody>
          </div>
          {isCreatedByLoggedInUser && (
            <>
              <StyledPopper anchorEl={actionsAnchorEl} placement="top-end">
                <Flex>
                  <IconButton onClick={editComment}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={deleteComment}>
                    <DeleteIcon />
                  </IconButton>
                </Flex>
              </StyledPopper>
              <ConfirmDialog
                open={isConfirmDeleteOpen}
                title="Delete Comment"
                confirmText="Delete"
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
              >
                This can’t be undone. Are you sure?
              </ConfirmDialog>
            </>
          )}
        </ViewCommentContainer>
      )}
      {isEditMode && (
        <CommentEditorWrapper>
          <CommentEditor
            key={activityEntry.id}
            defaultHtml={getMetaDataMessage(activityEntry)}
            onChange={onChangeHtml}
            onSave={saveEdit}
            onCancel={cancelEdit}
            formValidationMessages={formValidationMessages()}
          />
        </CommentEditorWrapper>
      )}
    </CommentWrapper>
  );
};

Comment.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
  dateTimeFormatter: PropTypes.func,
};

Comment.defaultProps = {
  dateTimeFormatter: getCreatedTimeFormatted,
};

export default Comment;

export const CommentWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;

const ViewCommentContainer = styled.div`
  overflow: hidden;
`;

const CommentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AuthorName = styled.span`
  color: var(--color-charcoal);
  font-size: 12px;
  font-weight: 500;
`;

const CommentDateTime = styled(SystemMessageText)`
  padding-top: 1px;
  margin: 0 10px;
  font-weight: 600;
  align-self: center;
`;

const CommentBody = styled.div`
  margin-top: 5px;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
`;

const Arrow = styled.div`
  margin: -4px 0 -9px 35px;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-weight: normal;
  white-space: nowrap;
  color: var(--color-red-dark);
  ${IconButton} {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  ${RetryIcon},
  ${CancelIcon} {
    font-size: 15px;
    color: var(--color-charcoal);
    &:hover {
      color: var(--color-gray);
    }
  }
`;

const StyledPopper = styled(Popper)`
  .MuiPaper-root {
    margin-bottom: -5px;
  }
`;

const CommentEditorWrapper = styled.div`
  background-color: white;
  margin-top: 10px;
  padding: 7px 0 7px 7px;
`;
