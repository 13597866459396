import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { selectPropsJobItemId } from "../../index";

export const selectStateJobItemUsersUi = state => state.ui.jobItemUsers;

export const selectJobItemUsersFromJobItemId = createCachedSelector(
  [selectStateJobItemUsersUi, selectPropsJobItemId],
  (jobItemUsersUi, jobItemId) => get(jobItemUsersUi, `${jobItemId}`)
)(selectPropsJobItemId);
