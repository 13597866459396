import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { usePermissions } from "st-shared/stores";
import styled from "styled-components";

import {
  JOB_DETAILS_SET_COST_VS_SELL_MODE,
  SET_USER_PREFERENCE,
} from "../../../lib/constants";
import { JOB_COST_VS_SELL_MODE } from "../../../lib/constants/jobDetails";
import createAction from "../../../redux/helpers/createAction";
import { useJobDetailsCostVsSellMode } from "../../../redux/selectors/jobDetails/ui/costVsSellMode";
import { useUserPreferenceJobPageCostVsSellMode } from "../../../redux/selectors/userPreferenceSelectors";
import { UserPreferenceKeys } from "../../../state/entities/userPreferences/types";
import Switch from "../../elements/Switch";
import Tooltip from "../../modules/Tooltip";

const CostVsSellSelector = () => {
  const costVsSellMode = useJobDetailsCostVsSellMode();
  const userPreferenceCostVsSellMode = useUserPreferenceJobPageCostVsSellMode();
  const { canAccessCostRates, canViewJobFinancials } = usePermissions();
  const dispatch = useDispatch();

  const switchCostVsSellMode = (mode) => {
    if (mode === costVsSellMode) return;
    dispatch(createAction(JOB_DETAILS_SET_COST_VS_SELL_MODE, mode));
    dispatch(
      createAction(SET_USER_PREFERENCE, {
        key: UserPreferenceKeys.USER_PREFERENCE_JOB_PAGE_COST_VS_SELL_MODE,
        value: mode,
      })
    );
  };

  useEffect(() => {
    if (canViewJobFinancials && canAccessCostRates) {
      if (costVsSellMode !== userPreferenceCostVsSellMode) {
        dispatch(
          createAction(
            JOB_DETAILS_SET_COST_VS_SELL_MODE,
            userPreferenceCostVsSellMode
          )
        );
      }
    }
  }, []);

  if (!canViewJobFinancials || !canAccessCostRates) return null;

  return (
    <Tooltip
      title="Switch between viewing your used financial values here as cost or sell"
      placement="top"
    >
      <Container>
        <SelectedText $selected={costVsSellMode === JOB_COST_VS_SELL_MODE.COST}>
          Cost
        </SelectedText>
        <Switch
          checked={costVsSellMode === JOB_COST_VS_SELL_MODE.SELL}
          onChange={() =>
            switchCostVsSellMode(
              costVsSellMode === JOB_COST_VS_SELL_MODE.SELL
                ? JOB_COST_VS_SELL_MODE.COST
                : JOB_COST_VS_SELL_MODE.SELL
            )
          }
        />
        <SelectedText $selected={costVsSellMode === JOB_COST_VS_SELL_MODE.SELL}>
          Sell
        </SelectedText>
      </Container>
    </Tooltip>
  );
};

export default CostVsSellSelector;

const Container = styled.div`
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: black;

  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding: 0 20px;
`;

const SelectedText = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 2px;
  color: ${(props) => (props.$selected ? "black" : "var(--color-charcoal)")};
`;
