import { produce } from "immer";
import { call, put, select } from "redux-saga/effects";

import { JobItemRole } from "st-shared/entities/JobItemRole";
import { JOB_ITEM_SAVE_ITEM_CARD } from "../../../../lib/constants";
import createAction from "../../../../redux/helpers/createAction";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { selectJobDetailsUiCurrentFocusJobItemId } from "../../../../redux/selectors/jobDetails/ui/currentFocus";
import { actionEntitiesReceived, actionEntitiesRemoved } from "../../actions";
import { actionJobItemRoleRecalculateHours } from "../../jobItem/actions";
import { getRecalculateDeletedHoursJobItem } from "../../jobItem/sagas/watchJobItemRecalculateHours";
import { EntityNames } from "../../types";
import {
  actionJobItemRoleEdit,
  JOB_ITEM_ROLE_DELETE,
  JobItemRoleDeleteAction,
} from "../actions";
import JobItemRoleAPI from "../api";
import { selectJobItemRole } from "../selectors/selectJobItemRole";

function* deleteJobItemRole(action: JobItemRoleDeleteAction) {
  try {
    const jobItemRole: JobItemRole = yield select(selectJobItemRole, {
      jobItemRoleId: action.jobItemRoleId,
    });

    if (action.save === "jobItem") {
      const deletedJobItemRole = produce(jobItemRole, (draft) => {
        draft.active = false;
      });
      yield jobItemDelete(deletedJobItemRole);
    } else if (action.save === "api") {
      yield apiDelete(jobItemRole);
    }
  } catch (error) {
    sagaError(error);
  }
}

function* jobItemDelete(jobItemRole: JobItemRole) {
  yield put(actionJobItemRoleEdit(jobItemRole.jobItemId, jobItemRole));
  const jobItem = yield getRecalculateDeletedHoursJobItem(
    actionJobItemRoleRecalculateHours(jobItemRole.jobItemId),
    jobItemRole
  );

  const currentFocusJobItemId = yield select(
    selectJobDetailsUiCurrentFocusJobItemId
  );

  if (currentFocusJobItemId !== jobItemRole.jobItemId) {
    yield put(
      createAction(JOB_ITEM_SAVE_ITEM_CARD, {
        jobItemId: jobItemRole.jobItemId,
        jobItem,
        prevJobItem: jobItem,
      })
    );
  }
}

function* apiDelete(jobItemRole: JobItemRole) {
  try {
    yield put(
      actionEntitiesRemoved({
        entityName: EntityNames.JobItemRoles,
        ids: [jobItemRole.id],
      })
    );

    const data: Awaited<ReturnType<typeof JobItemRoleAPI.delete>> = yield call(
      JobItemRoleAPI.delete,
      jobItemRole.id
    );

    yield put(actionEntitiesReceived(data));
  } catch (error) {
    yield put(
      actionEntitiesReceived({
        [EntityNames.JobItemRoles]: [jobItemRole],
      })
    );

    sagaError(error);
  }
}

export default function* watchJobItemRoleDelete() {
  yield takeLatestBy(
    [JOB_ITEM_ROLE_DELETE],
    deleteJobItemRole,
    (action: JobItemRoleDeleteAction) => action.jobItemRoleId
  );
}
