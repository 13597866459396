import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobItemId } from "../../../helpers/selectors";
import { selectStateJobItemRoleUi } from "./selectStateJobItemRolesUi";

export const selectJobItemRoleUi = createCachedSelector(
  [selectStateJobItemRoleUi, selectPropsJobItemId],
  (jobItemRolesUi, jobItemId) => get(jobItemRolesUi, `${jobItemId}`)
)(selectPropsJobItemId);

export function useJobItemRoleUi(jobItemId: number) {
  return useSelector((state: AppState) =>
    selectJobItemRoleUi(state, { jobItemId })
  );
}
