import { plate } from "st-shared/external";

import { ELEMENT_COLUMN } from "../createColumnPlugin";
import { TColumnElement } from "../types";

export function insertColumn(editor: plate.PlateEditor, value: string) {
  plate.withoutNormalizing(editor, () => {
    plate.insertNodes(editor, [
      {
        type: ELEMENT_COLUMN,
        children: [{ text: "" }],
        value,
      } as TColumnElement,
      { text: " " },
    ]);
    plate.focusEditor(editor, plate.getPointFromLocation(editor));
  });
}
