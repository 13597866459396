import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";

import { AppState } from "../../../AppState";
import { selectPropsJobId } from "../../../helpers/selectors";
import { selectStateJobListModels } from "./selectStateJobListModels";

export const selectJobListModel = createCachedSelector(
  [selectStateJobListModels, selectPropsJobId],
  (jobListModels, jobId) => jobListModels.byId[jobId]
)(selectPropsJobId);

export function useJobListModel(jobId: number) {
  return useSelector((state: AppState) => selectJobListModel(state, { jobId }));
}
