import numeral from "numeral";
import * as PropTypes from "prop-types";
import { LineItemOptionTypes } from "st-shared/entities/LineItemOptionType";
import { applyInverseExchangeRate, asString } from "st-shared/lib";

import {
  DATE_FORMAT_INVOICE,
  LOGGED_EXPENSE_STATUS_ID_DELETED,
} from "../constants";
import { formatDate } from "../dates";
import {
  entityFieldDecimalType,
  entityIdType,
  typeEntityType,
} from "../types/entityTypes";
import { companyEntityType } from "./companyEntity";
import { contactEntityType } from "./contactEntity";
import { currencyEntityType } from "./currencyEntity";
import { labelsSubEntityType } from "./labelEntity";
import {
  getDisplayOptions,
  getLanguageOptions,
  purchaseOrderEntityType,
} from "./purchaseOrderEntity";

export const loggedExpenseShape = {
  id: entityIdType.isRequired,
  company: companyEntityType,
  contact: contactEntityType,
  currency: currencyEntityType,
  date: PropTypes.string,
  exchangeRate: entityFieldDecimalType,
  masterLoggedExpenseId: entityIdType,
  name: PropTypes.string,
  description: PropTypes.string,
  jobCurrencySellRate: entityFieldDecimalType,
  itemPricingMethodId: entityIdType.isRequired,
  jobCurrencyTotalAmountInvoicedExTax: entityFieldDecimalType,
  jobCurrencyTotalExTax: entityFieldDecimalType,
  jobId: entityIdType.isRequired,
  loggedExpenseCurrencyCostRate: entityFieldDecimalType,
  loggedExpenseCurrencyTotalCostExTax: entityFieldDecimalType,
  loggedExpenseStatus: typeEntityType.isRequired,
  markup: entityFieldDecimalType,
  purchaseOrder: purchaseOrderEntityType,
  quantity: entityFieldDecimalType,
  reference: PropTypes.string,
  totalCostExTax: entityFieldDecimalType,
  userId: entityIdType.isRequired,
  labels: labelsSubEntityType,
};

export const loggedExpenseEntityType = PropTypes.shape(loggedExpenseShape);

export const getJobId = (entity) => entity.jobId;

export const isPurchaseOrder = ({ purchaseOrder }) => Boolean(purchaseOrder);

export const getPurchaseOrder = ({ purchaseOrder }) => purchaseOrder;

export const getPurchaseOrderId = ({ purchaseOrder }) =>
  purchaseOrder && purchaseOrder.id;

export const getCompany = ({ company }) => company;

export const getJobCurrencyTotalExTax = ({ jobCurrencyTotalExTax }) =>
  jobCurrencyTotalExTax;

export const getLoggedExpenseDate = ({ date }) => date;

export const getLoggedExpenseDateFormatted = ({ date }) =>
  formatDate(date, DATE_FORMAT_INVOICE);

export const getMasterLoggedExpenseId = (entity) =>
  entity.masterLoggedExpenseId;

export const getLoggedExpenseName = (entity) => entity.name;

export const getLoggedExpenseDescription = (entity) => entity.description;

export const getExchangeRate = ({ exchangeRate }) => exchangeRate;

export const getLoggedExpenseCurrencyCostRate = ({
  loggedExpenseCurrencyCostRate,
}) => loggedExpenseCurrencyCostRate;

export const getQuantity = ({ quantity }) => quantity;

export const isDeleted = ({ loggedExpenseStatus }) =>
  loggedExpenseStatus.id === LOGGED_EXPENSE_STATUS_ID_DELETED;

export const getLoggedExpenseStatusId = ({ loggedExpenseStatus }) =>
  loggedExpenseStatus.id;

export const getLoggedExpenseStatus = ({ loggedExpenseStatus }) =>
  loggedExpenseStatus;

export const getItemPricingMethodId = ({ itemPricingMethodId }) =>
  itemPricingMethodId;

export const getSearchString = (entity) =>
  asString(getLoggedExpenseName(entity));

export const getAsLoggedExpenseOption = (entity) =>
  entity && {
    key: entity.id,
    value: getLoggedExpenseName(entity),
    optionType: LineItemOptionTypes.LoggedExpense,
    rate: getJobCurrencyTotalExTax(entity),
    searchString: getSearchString(entity),
    status: getLoggedExpenseStatus(entity),
  };

export const purchaseOrderDocumentMethods = {
  getLineItemParentId: ({ purchaseOrder }) => purchaseOrder.id,
  getCompany: ({ company }) => company,
  getAddress: ({ purchaseOrder }) => purchaseOrder.address,
  getDate: getLoggedExpenseDate,
  setDate: (document, value) => ({ ...document, date: value }),
  getReference: ({ reference }) => reference,
  setReference: (document, value) => ({ ...document, reference: value }),
  getNumber: ({ purchaseOrder }) => purchaseOrder.number,
  setNumber: (document, value) => ({
    ...document,
    purchaseOrder: { ...document.purchaseOrder, number: value },
  }),
  getName: getLoggedExpenseName,
  setName: (document, value) => ({
    ...document,
    name: value,
  }),
  getIntroduction: (document) => document.description || "",
  setIntroduction: (document, value) => ({
    ...document,
    description: value,
  }),
  getTerms: ({ purchaseOrder }) => purchaseOrder.terms || "",
  setTerms: (document, value) => ({
    ...document,
    purchaseOrder: { ...document.purchaseOrder, terms: value },
  }),
  getRequiredDate: ({ purchaseOrder }) => purchaseOrder.requiredDate,
  setRequiredDate: (document, value) => ({
    ...document,
    purchaseOrder: { ...document.purchaseOrder, requiredDate: value },
  }),
  getDisplayOptions: (loggedExpense) =>
    getDisplayOptions(getPurchaseOrder(loggedExpense)),
  getLanguageOptions: (loggedExpense) =>
    getLanguageOptions(getPurchaseOrder(loggedExpense)),
  setDisplayAndLanguageOptions: (document, loggedExpense) => ({
    ...document,
    purchaseOrder: {
      ...getPurchaseOrder(loggedExpense),
      languageOptions: getPurchaseOrder(loggedExpense).languageOptions,
      ...getDisplayOptions(getPurchaseOrder(loggedExpense)),
    },
  }),
};

export const getJobCurrencyCostRate = (loggedExpense) =>
  applyInverseExchangeRate(
    getLoggedExpenseCurrencyCostRate(loggedExpense),
    getExchangeRate(loggedExpense)
  );

export const getJobCurrencyTotalCostExTax = (loggedExpense) =>
  numeral(getJobCurrencyCostRate(loggedExpense))
    .multiply(getQuantity(loggedExpense))
    .value();
