import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataCompanyName,
  getMetaDataJobName,
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobCreated = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" created the job"}
  </SystemMessage>
);

JobCreated.propTypes = {
  activityEntry: activityEntryEntityType.isRequired,
};

export default JobCreated;
