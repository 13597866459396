import * as PropTypes from "prop-types";
import { asDecimal } from "../math";
import { entityFieldDecimalType, entityIdType } from "../types/entityTypes";

export const roleRateEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  rateCardId: entityIdType.isRequired,
  roleId: entityIdType.isRequired,
  archived: PropTypes.bool.isRequired,
  rate: entityFieldDecimalType
});

export const getRoleIdRateCardIdKeyPath = ({ roleId, rateCardId }) =>
  `["${roleId}.${rateCardId}"]`;

export const isDeleted = ({ archived }) => archived;

export const getRate = ({ rate }) => asDecimal(rate);
