import { put, select, takeLatest } from "redux-saga/effects";
import { QUOTE_SAVE, QUOTE_SET_ADDRESS } from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import { selectAddressById } from "../../selectors/addressSelectors";
import { selectQuoteById } from "../../selectors/quoteSelectors";

function* setAddress(action) {
  try {
    const { id, addressId } = action.payload;

    const quote = yield select(selectQuoteById, { id });

    const address = yield select(selectAddressById, { id: addressId });

    yield put(
      createAction(QUOTE_SAVE, {
        id,
        prevQuote: quote,
        quote: {
          ...quote,
          address
        }
      })
    );
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchQuoteSetAddress() {
  yield takeLatest(QUOTE_SET_ADDRESS, setAddress);
}
