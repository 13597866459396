import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  DATA_ATTRIBUTE_DEPENDANCY_HANDLE,
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT
} from "../../../lib/constants";
import { getAttrPropString } from "../../../lib/dom";
import Flex from "../../elements/Flex";
import Tooltip from "../../modules/Tooltip";

const handleAttrName = getAttrPropString(DATA_ATTRIBUTE_DEPENDANCY_HANDLE);

const TooltipTitle = () => (
  <div
    style={{
      color: "white",
      fontSize: 12,
      fontWeight: 500,
      textAlign: "center",
      lineHeight: "normal",
      width: 160
    }}
  >
    <b>Click and drag</b> the circle <br />
    to add a dependency. <br />
    <b>Right-click</b> to unlink dependencies.
  </div>
);

class JobItemBarDependancyHandle extends React.PureComponent {
  static propTypes = {
    left: PropTypes.bool,
    right: PropTypes.bool
  };

  static defaultProps = {
    left: false,
    right: false
  };

  get handleValue() {
    const { left } = this.props;
    if (left) return DATA_VALUE_HANDLE_LEFT;
    return DATA_VALUE_HANDLE_RIGHT;
  }

  get className() {
    const { left, right } = this.props;
    if (left) return "left";
    if (right) return "right";
    return "";
  }

  render() {
    const { className, handleValue } = this;
    return (
      <Tooltip title={TooltipTitle()}>
        <DependancyHandle {...{ className, [handleAttrName]: handleValue }} />
      </Tooltip>
    );
  }
}

export default JobItemBarDependancyHandle;

export const DependancyHandle = styled(Flex)`
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-radius: 50%;
  border: 2px solid var(--dependancy-active-border-color);
  background-color: white;
  z-index: 4;
  opacity: 0;
  cursor: cell;
  &.left {
    left: -5px;
  }
  &.right {
    right: -5px;
  }
`;
