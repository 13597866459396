import { call, put, select, takeLatest } from "redux-saga/effects";
import { sortPurchaseOrderLineItemsAPI } from "../../../lib/API/purchaseOrderLineItemAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import {
  ENTITIES_RECEIVED,
  LINE_ITEMS_SORT_CONFIRM,
  PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVED,
  PURCHASE_ORDER_LINE_ITEM_SORT_SAVING
} from "../../../lib/constants";
import {
  getPurchaseOrderId,
  isPurchaseOrder
} from "../../../lib/entities/loggedExpenseEntity";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectLoggedExpenseByLoggedExpenseId,
  selectLoggedExpenseUi
} from "../../selectors/loggedExpenseSelectors";
import { selectSortedPurchaseOrderLineItemIdsByPurchaseOrderId } from "../../selectors/purchaseOrderLineItemSelectors";
import { selectSnapshotBySnapshotId } from "../../selectors/snapshotSelectors";

function* confirmSortLineItems(action) {
  const { loggedExpenseId, sortModeSnapshotId: snapshotId } = yield select(
    selectLoggedExpenseUi
  );

  if (!loggedExpenseId) return;

  const loggedExpense = yield select(selectLoggedExpenseByLoggedExpenseId, {
    loggedExpenseId
  });

  if (!isPurchaseOrder(loggedExpense)) return;

  const purchaseOrderId = getPurchaseOrderId(loggedExpense);

  if (!purchaseOrderId) return;

  const snapshot = yield select(selectSnapshotBySnapshotId, {
    snapshotId
  });

  try {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM, {
        loggedExpenseId,
        purchaseOrderId,
        snapshotId
      })
    );

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_SAVING, {
        id: loggedExpenseId,
        purchaseOrderId
      })
    );

    const purchaseOrderLineItemIds = yield select(
      selectSortedPurchaseOrderLineItemIdsByPurchaseOrderId,
      { purchaseOrderId }
    );

    const data = yield call(
      sortPurchaseOrderLineItemsAPI,
      purchaseOrderId,
      purchaseOrderLineItemIds
    );

    yield put(createAction(ENTITIES_RECEIVED, data));

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_SAVED, {
        id: loggedExpenseId,
        purchaseOrderId
      })
    );

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SORT_SAVE_ERROR, {
        id: loggedExpenseId,
        purchaseOrderId,
        snapshot
      })
    );

    sagaError(error);
  }
}

export default function* watchLineItemsSortConfirm() {
  yield takeLatest(LINE_ITEMS_SORT_CONFIRM, confirmSortLineItems);
}
