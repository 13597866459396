import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { JOB_TIMELINE_DATE_HEADER_HEIGHT } from "../../../lib/constants";
import { entityIdType } from "../../../lib/types/entityTypes";
import {
  selectJobTimelineDayWidth,
  selectJobTimelineHeight,
  selectJobTimelineExportDateRange,
  selectJobTimelineExportWidth
} from "../../../redux/selectors/jobTimelineSelectors";
import Flex from "../../elements/Flex";
import GridBackground from "../../elements/GridBackground";
import { withPDFPageWrapper } from "../../modules/PDFPageWrapper";
import { JobTimelineScrollContext } from "../../modules/ScrollContexts";
import ScrollContextProvider from "../../modules/ScrollContexts/ScrollContextProvider";
import JobTimelineBackground from "../JobTimelineBackground";
import JobTimelineBodyColumnCell from "../JobTimelineBodyColumnCell";
import JobTimelineGroupColumnCell from "../JobTimelineGroupColumnCell";
import JobTimelineHeader, {
  JobTimelineHeaderWrapper
} from "../JobTimelineHeader";
import { JobTimelineInfoWrapper } from "../JobTimelineHeader/JobTimelineInfo";
import { DateNav } from "../JobTimelineHeader/JobTimelineNavigation";
import JobTimelinePhaseGroupRow from "../JobTimelinePhaseGroupRow";
import { JobItemName } from "../JobTimelinePhaseGroupRow/JobTimelineItemGroup";
import { JobPhaseName } from "../JobTimelinePhaseGroupRow/JobTimelinePhaseGroup";
import JobTimelinePhaseRow from "../JobTimelinePhaseRow/index";
import PhaseRowsConsumer from "../JobTimelineScrollProvider/PhaseRowsConsumer";

const mapState = (state, props) => ({
  dayWidth: selectJobTimelineDayWidth(state),
  contentHeight: selectJobTimelineHeight(state, props),
  contentWidth: selectJobTimelineExportWidth(state, props),
  contentDateRange: selectJobTimelineExportDateRange(state, props)
});

const mapDispatch = null;

class JobTimelineExportContextProvider extends React.PureComponent {
  static propTypes = {
    jobId: entityIdType.isRequired,
    dayWidth: PropTypes.number.isRequired,
    contentHeight: PropTypes.number.isRequired,
    contentWidth: PropTypes.number.isRequired,
    contentDateRange: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      jobId,
      contentWidth,
      dayWidth,
      contentHeight,
      contentDateRange
    } = this.props;
    const viewportHeight = contentHeight + JOB_TIMELINE_DATE_HEADER_HEIGHT;
    const style = {
      width: contentWidth,
      height: viewportHeight
    };

    return (
      <ScrollContextProvider
        Context={JobTimelineScrollContext}
        contentHeight={contentHeight}
        viewportWidth={contentWidth}
        viewportHeight={viewportHeight}
        viewportOffset={{
          x: 0,
          y: JOB_TIMELINE_DATE_HEADER_HEIGHT
        }}
        dayWidth={dayWidth}
        initialDate={contentDateRange.startDate}
        noScroll
      >
        <Wrapper style={style}>
          <JobTimelineHeader jobId={jobId} readOnly />
          <Body dayWidth={dayWidth}>
            <JobTimelineGroupColumnCell>
              <PhaseRowsConsumer jobId={jobId}>
                {({ jobPhaseId, top, height }) => (
                  <JobTimelinePhaseGroupRow
                    key={jobPhaseId}
                    id={jobPhaseId}
                    jobId={jobId}
                    top={top}
                    height={height}
                    readOnly
                  />
                )}
              </PhaseRowsConsumer>
            </JobTimelineGroupColumnCell>
            <JobTimelineBodyColumnCell>
              <JobTimelineBackground jobId={jobId} />
              <PhaseRowsConsumer jobId={jobId}>
                {({ jobPhaseId, top, height }) => (
                  <JobTimelinePhaseRow
                    key={jobPhaseId}
                    id={jobPhaseId}
                    jobId={jobId}
                    top={top}
                    height={height}
                    readOnly
                  />
                )}
              </PhaseRowsConsumer>
            </JobTimelineBodyColumnCell>
          </Body>
        </Wrapper>
      </ScrollContextProvider>
    );
  }
}

export default withPDFPageWrapper(
  connect(mapState, mapDispatch)(JobTimelineExportContextProvider)
);

const Wrapper = styled.div`
  pointer-events: none;
  overflow: hidden;

  ${JobTimelineInfoWrapper} {
    display: none;
  }

  ${JobTimelineHeaderWrapper} {
    height: auto;
  }

  ${DateNav} {
    display: none;
  }

  ${GridBackground} {
    display: none;
  }

  ${JobPhaseName}, ${JobItemName} {
    width: 193px;
  }
`;

const Body = styled(Flex)`
  position: relative;
  width: 100%;
  height: calc(100% - ${JOB_TIMELINE_DATE_HEADER_HEIGHT}px);
`;
