import { call, put, takeEvery } from "redux-saga/effects";

import { saveLoggedExpenseAPI } from "../../../lib/API/loggedExpenseAPI";
import {
  ENTITIES_RECEIVED,
  ENTITY_CLASS_LOGGED_EXPENSE,
  LOGGED_EXPENSE_SAVE,
  LOGGED_EXPENSE_SAVE_FAILED,
  LOGGED_EXPENSE_SAVED,
  LOGGED_EXPENSE_SAVING,
} from "../../../lib/constants";
import { sendApplyModelData } from "../../../lib/WebAppAPI";
import { sendRefreshPurchaseOrderHtml } from "../../../lib/WebAppAPI/commercialDocuments";
import { entitySaving } from "../../../lib/WebAppAPI/entity";
import { EntityClasses } from "../../../state/entities/types";
import createAction from "../../helpers/createAction";
import { takeLatestBy } from "../../helpers/sagaEffects";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* save(action) {
  const { id, loggedExpense } = action.payload;

  try {
    entitySaving(EntityClasses.LoggedExpense, id, true);

    yield put(
      createAction(LOGGED_EXPENSE_SAVING, {
        id,
      })
    );

    const data = yield call(saveLoggedExpenseAPI, {
      ...loggedExpense,
      labels: null,
    });

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    yield put(createAction(LOGGED_EXPENSE_SAVED, { id }));

    yield call(sendRefreshPurchaseOrderHtml);
  } catch (error) {
    yield put(
      createAction(LOGGED_EXPENSE_SAVE_FAILED, {
        ...action.payload,
        error,
      })
    );

    sagaError(error);
  } finally {
    entitySaving(EntityClasses.LoggedExpense, id, false);
  }
}

function sendModelData(action) {
  try {
    const { loggedExpense } = action.payload;

    sendApplyModelData(ENTITY_CLASS_LOGGED_EXPENSE, loggedExpense);
  } catch (error) {
    sagaError(error);
  }
}

export default function* watchLoggedExpenseSave() {
  yield takeLatestBy(LOGGED_EXPENSE_SAVE, save, (action) => action.payload.id);
  yield takeEvery(LOGGED_EXPENSE_SAVE, sendModelData);
}
