import { Entity, type TEntityId } from "@streamtimefe/entities";
import { Api404Error } from "@streamtimefe/error";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router";

export const ParamEntityIdContext = createContext<TEntityId | undefined>(
  undefined
);

export function useParamEntityId() {
  const entityId = useContext(ParamEntityIdContext);
  if (typeof entityId === "undefined")
    throw new Error(`Missing ParamEntityIdContext Provider`);
  return entityId;
}

export function ParamEntityIdProvider({ children }: PropsWithChildren) {
  const params = useParams();

  const entityId = useMemo<TEntityId>(() => {
    if (
      typeof params.entityId !== "undefined" &&
      params.entityId.length > 0 &&
      Number.isInteger(Number(params.entityId))
    ) {
      return Entity.idFromString(params.entityId);
    }
    throw new Api404Error();
  }, [params.entityId]);

  return (
    <ParamEntityIdContext.Provider value={entityId}>
      {children}
    </ParamEntityIdContext.Provider>
  );
}
