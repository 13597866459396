import { get } from "lodash-es";
import { createSelector } from "reselect";
import { EMPTY_ARRAY, ENTITY_NAME_JOB_ITEM_USERS } from "../../lib/constants";
import { getUserId } from "../../lib/entities/jobItemUserEntity";
import { getDisplayName } from "../../lib/entities/userEntity";
import { selectJobItemById } from "./jobItemSelectors";
import { selectJobById } from "./jobSelectors";
import { selectUserById } from "./userSelectors";
import { selectPropsId, selectPropsUserId } from "./index";

export const selectJobItemUsers = state =>
  state.entities[ENTITY_NAME_JOB_ITEM_USERS];

export const selectJobItemUserById = createSelector(
  selectJobItemUsers,
  selectPropsId,
  (jobItemUsers, jobItemId) => get(jobItemUsers, `byId.${jobItemId}`)
);

export const selectJobItemUsersByIds = createSelector(
  selectJobItemUsers,
  (state, props) => props.ids,
  (jobItemUsers, jobItemUserIds) =>
    jobItemUserIds.map(id => get(jobItemUsers, `byId.${id}`))
);

export const selectIdByJobItemIdUserId = createSelector(
  selectJobItemUsers,
  selectPropsId,
  selectPropsUserId,
  (jobItemUsers, jobItemId, userId) =>
    get(jobItemUsers, `idByJobItemIdUserId["${jobItemId}.${userId}"]`)
);

export const selectUserIdsByJobItemId = createSelector(
  selectJobItemUsers,
  selectPropsId,
  (jobItemUsers, jobItemId) =>
    get(jobItemUsers, `userIdsByJobItemId.${jobItemId}`, EMPTY_ARRAY)
);

export const selectJobItemUserIdsByJobItemId = createSelector(
  selectJobItemUsers,
  selectPropsId,
  (jobItemUsers, jobItemId) =>
    get(jobItemUsers, `idsByJobItemId.${jobItemId}`, EMPTY_ARRAY)
);

export const selectUserNameByJobItemUserId = (state, props) => {
  const userId = getUserId(selectJobItemUserById(state, props));
  const user = selectUserById(state, { id: userId });
  return getDisplayName(user);
};

export const selectJobIdByJobItemUserId = (state, props) => {
  const jobItemUser = selectJobItemUserById(state, props);
  const jobItem = selectJobItemById(state, { id: jobItemUser.jobItemId });
  const job = selectJobById(state, { id: jobItem.jobId });
  return job.id;
};

export const selectJobItemUsersByJobItemId = (state, props) => {
  const jobItemUserIds = selectJobItemUserIdsByJobItemId(state, props);
  if (!jobItemUserIds) return null;
  return jobItemUserIds.map(jobItemUserId =>
    selectJobItemUserById(state, { id: jobItemUserId })
  );
};
