import { getModalBridgeClient, ModalTypes } from "@streamtimefe/modal-bridge";
import type { IconBaseProps } from "st-shared/components/icons";
import {
  StChatOutline,
  StDatepickerOutline,
  StGraduationCapOutline,
  StKeyboardOutline,
  StRocketshipOutline,
} from "st-shared/components/icons";

import {
  feToWebOpenIntercomChat,
  feToWebStartProductOnboarding,
  ProductOnboardingMode,
} from "../../../../lib/WebAppAPI/fePages/genericWeb";

type HelpOptionItem = {
  title: string;
  options: HelpOption[];
};

export type HelpOption = {
  id: number;
  icon?: (props: IconBaseProps) => JSX.Element;
  url?: string;
  onClickHandler?: (job: unknown) => void;
  label: string;
};

export const helpOptionItems: HelpOptionItem[] = [
  {
    title: "Streamtime Help",
    options: [
      {
        id: 1,
        icon: StRocketshipOutline,
        onClickHandler: () =>
          feToWebStartProductOnboarding(ProductOnboardingMode.Job),
        label: "What can I do in Streamtime?",
      },
      {
        id: 2,
        icon: StKeyboardOutline,
        onClickHandler: () => {
          getModalBridgeClient()?.openModal(
            ModalTypes.KeyboardShortcuts,
            { initialTab: "job" },
            {}
          );
        },
        label: "Keyboard shortcuts",
      },
      {
        id: 3,
        icon: StGraduationCapOutline,
        url: "https://help.streamtime.net/",
        label: "Help Centre",
      },
      {
        id: 4,
        icon: StChatOutline,
        onClickHandler: feToWebOpenIntercomChat,
        label: "Get in touch with support",
      },
      {
        id: 5,
        icon: StDatepickerOutline,
        label: "Book a One-on-One",
        url: "https://calendly.com/d/cmkg-rgb-jhn",
      },
    ],
  },
  {
    title: "FAQ'S",
    options: [
      {
        id: 1,
        url: "https://help.streamtime.net/en/articles/21756-how-does-streamtime-work",
        label: "How does Streamtime work?",
      },
      {
        id: 2,
        url: "https://help.streamtime.net/en/articles/3884-how-does-the-to-do-screen-work",
        label: "How does the To Do screen work?",
      },
      {
        id: 3,
        url: "https://help.streamtime.net/en/articles/8405-create-custom-reports-with-favourites",
        label: "Creating custom reports",
      },
      {
        id: 4,
        url: "https://help.streamtime.net/en/collections/2883493-scheduling",
        label: "Scheduling and team capacity",
      },
      {
        id: 5,
        url: "https://help.streamtime.net/en/articles/451106-streamtime-integrations",
        label: "Integrations in Streamtime",
      },
    ],
  },
];
