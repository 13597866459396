import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { JOB_ITEM_DEPENDANCY_SEGMENT_TYPE } from "../../../lib/constants";
import { jobItemDependancySegmentType } from "../../../lib/entities/jobItemDependancyEntity";
import { FrameworkException } from "../../../lib/exceptions/FrameworkException";
import { entityIdType } from "../../../lib/types/entityTypes";
import { selectVisibleJobItemDependancySegmentsByJobIdJobPhaseId } from "../../../redux/selectors/jobTimelineSelectors";
import { SCROLL_CONTEXTS } from "../ScrollContexts";
import SegmentBridgeLeft from "./SegmentTypes/SegmentBridgeLeft";
import SegmentBridgeRight from "./SegmentTypes/SegmentBridgeRight";

const { BRIDGE_LEFT, BRIDGE_RIGHT } = JOB_ITEM_DEPENDANCY_SEGMENT_TYPE;

const mapState = (state, props) => ({
  jobItemDependancySegments: selectVisibleJobItemDependancySegmentsByJobIdJobPhaseId(
    state,
    props
  )
});

const SegmentsByJobPhase = ({
  Context,
  jobPhaseId,
  jobItemDependancySegments
}) =>
  jobItemDependancySegments.map(jobItemDependancySegment => {
    const { jobItemDependancyId, segmentType } = jobItemDependancySegment;
    const componentProps = {
      key: jobItemDependancyId,
      Context,
      jobItemDependancySegment
    };

    switch (segmentType) {
      case BRIDGE_LEFT:
        return <SegmentBridgeLeft {...componentProps} />;
      case BRIDGE_RIGHT:
        return <SegmentBridgeRight {...componentProps} />;
      default:
        throw new FrameworkException(
          `Invalid Job Item Dependancy Segment Type ${segmentType} for Job Phase ${jobPhaseId}`
        );
    }
  });

SegmentsByJobPhase.propTypes = {
  Context: PropTypes.oneOf(SCROLL_CONTEXTS).isRequired,
  jobId: entityIdType.isRequired,
  jobPhaseId: entityIdType.isRequired,
  jobItemDependancySegments: PropTypes.arrayOf(jobItemDependancySegmentType)
    .isRequired
};

export default connect(mapState)(SegmentsByJobPhase);
