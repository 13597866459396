import isError from "lodash-es/isError";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { sendOpenCreateTaxRateModal } from "../../../lib/WebAppAPI/taxRate";
import {
  ENTITIES_RECEIVED,
  PURCHASE_ORDER_LINE_ITEM_SAVE,
  PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";
import {
  selectLoggedExpenseJobBranchIdById,
  selectLoggedExpenseUi
} from "../../selectors/loggedExpenseSelectors";
import {
  selectPurchaseOrderLineItemById,
  selectPurchaseOrderLineItemUi
} from "../../selectors/purchaseOrderLineItemSelectors";

function* openCreate(action) {
  try {
    const { id } = action.payload;

    const { loggedExpenseId } = yield select(selectLoggedExpenseUi);

    const branchId = yield select(selectLoggedExpenseJobBranchIdById, {
      id: loggedExpenseId
    });

    const { taxRate, data } = yield call(sendOpenCreateTaxRateModal, branchId);

    yield put(createAction(ENTITIES_RECEIVED, { ...data }));

    if (id > 0) {
      const { isDeleting } = yield select(selectPurchaseOrderLineItemUi, {
        id
      });

      if (isDeleting) return;
    }

    const prevPurchaseOrderLineItem = yield select(
      selectPurchaseOrderLineItemById,
      { id }
    );

    const purchaseOrderLineItem = {
      ...prevPurchaseOrderLineItem,
      taxName: taxRate.name,
      taxRate: taxRate.rate
    };

    yield put(
      createAction(PURCHASE_ORDER_LINE_ITEM_SAVE, {
        id,
        purchaseOrderLineItem,
        prevPurchaseOrderLineItem
      })
    );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchPurchaseOrderLineItemCreateTaxRate() {
  yield takeLatest(PURCHASE_ORDER_LINE_ITEM_TAX_RATE_CREATE, openCreate);
}
