import { isObjectType } from "remeda";

import type { TEnumOption } from "./EnumOption";

export function getId<T>(optionOrEnum: TEnumOption<T> | T) {
  if (isObjectType(optionOrEnum)) {
    return optionOrEnum.id;
  } else {
    return optionOrEnum;
  }
}
