import { get } from "lodash-es";
import * as PropTypes from "prop-types";
import { useActiveJobPhaseIdsByJobId } from "../../../redux/selectors/jobItemSelectors";
import { usePhaseRowHeightsByJobId } from "../../../redux/selectors/jobTimelineSelectors";
import { useJobId } from "../context/JobIdContext";

const CustomPhaseRowsConsumer = ({ children }) => {
  const jobId = useJobId();
  const orderedJobPhaseIds = useActiveJobPhaseIdsByJobId(jobId);
  const phaseRowHeights = usePhaseRowHeightsByJobId(jobId);

  const rows = [];
  let currentOffsetY = 0;

  orderedJobPhaseIds.forEach(jobPhaseId => {
    const height = get(phaseRowHeights, `${jobPhaseId}.height`, 0);
    const top = currentOffsetY;
    const bottom = top + height;

    rows.push({
      jobPhaseId,
      top,
      height
    });

    currentOffsetY = bottom;
  });

  return rows.map(children);
};

CustomPhaseRowsConsumer.propTypes = {
  children: PropTypes.func.isRequired
};

export default CustomPhaseRowsConsumer;
