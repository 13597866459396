import { omit } from "lodash-es";
import {
  INVOICE_LINE_ITEM_MERGE_SAVED,
  INVOICE_LINE_ITEM_SORT_CANCEL,
  INVOICE_LINE_ITEM_SORT_CONFIRM,
  INVOICE_LINE_ITEM_SORT_START,
  INVOICE_LINE_ITEM_UNDO_MERGE_SAVED,
  JOB_ITEM_DRAG_END,
  JOB_ITEM_RESIZE_END,
  JOB_ITEM_SAVE_EDIT,
  JOB_ITEM_SAVED,
  PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED,
  PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL,
  PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM,
  PURCHASE_ORDER_LINE_ITEM_SORT_START,
  PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED,
  QUOTE_LINE_ITEM_MERGE_SAVED,
  QUOTE_LINE_ITEM_SORT_CANCEL,
  QUOTE_LINE_ITEM_SORT_CONFIRM,
  QUOTE_LINE_ITEM_SORT_START,
  QUOTE_LINE_ITEM_UNDO_MERGE_SAVED
} from "../../../lib/constants";
import createReducer from "../../helpers/createReducer";

const addSnapshot = (state, { payload: { snapshotId, data } }) => ({
  ...state,
  [snapshotId]: data
});

const removeSnapshot = (state, { payload: { snapshotId } }) =>
  omit(state, snapshotId);

const removeIfNotChanged = (state, { payload: { snapshotId, hasChanged } }) =>
  hasChanged ? state : omit(state, snapshotId);

export default createReducer(
  {},
  {
    [JOB_ITEM_DRAG_END]: removeIfNotChanged,
    [JOB_ITEM_RESIZE_END]: removeIfNotChanged,
    [JOB_ITEM_SAVE_EDIT]: removeIfNotChanged,
    [JOB_ITEM_SAVED]: removeSnapshot,
    [QUOTE_LINE_ITEM_MERGE_SAVED]: addSnapshot,
    [QUOTE_LINE_ITEM_UNDO_MERGE_SAVED]: removeSnapshot,
    [QUOTE_LINE_ITEM_SORT_START]: addSnapshot,
    [QUOTE_LINE_ITEM_SORT_CANCEL]: removeSnapshot,
    [QUOTE_LINE_ITEM_SORT_CONFIRM]: removeSnapshot,
    [INVOICE_LINE_ITEM_MERGE_SAVED]: addSnapshot,
    [INVOICE_LINE_ITEM_UNDO_MERGE_SAVED]: removeSnapshot,
    [INVOICE_LINE_ITEM_SORT_START]: addSnapshot,
    [INVOICE_LINE_ITEM_SORT_CANCEL]: removeSnapshot,
    [INVOICE_LINE_ITEM_SORT_CONFIRM]: removeSnapshot,
    [PURCHASE_ORDER_LINE_ITEM_MERGE_SAVED]: addSnapshot,
    [PURCHASE_ORDER_LINE_ITEM_UNDO_MERGE_SAVED]: removeSnapshot,
    [PURCHASE_ORDER_LINE_ITEM_SORT_START]: addSnapshot,
    [PURCHASE_ORDER_LINE_ITEM_SORT_CANCEL]: removeSnapshot,
    [PURCHASE_ORDER_LINE_ITEM_SORT_CONFIRM]: removeSnapshot
  }
);
