import clsx from "clsx";

import { useReportingSavedSegmentColumns } from "../../../state/stores/savedSegmentSelectors";
import { SortableColumnList } from "../../column/SortableColumnList";
import { AddColumnFooter } from "../AddColumnFooter";
import { ColumnsEmptyState } from "../ColumnsEmptyState";
import * as sidebarStyles from "../sidebar.css";
import * as styles from "./Columns.css";

export function Columns() {
  const columns = useReportingSavedSegmentColumns();

  return (
    <div className={sidebarStyles.container}>
      <div className={clsx(sidebarStyles.header, styles.header)}>Columns</div>
      {Object.keys(columns).length === 0 && (
        <ColumnsEmptyState variant="columns" />
      )}
      <SortableColumnList variant="sidebar" />
      <AddColumnFooter />
    </div>
  );
}
