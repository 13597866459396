import { formatForeignCurrency } from "st-shared/lib";
import { useCustomerCurrency } from "st-shared/stores";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../../lib/constants";
import {
  getJobCurrencyTotalAwaitingPaymentInvoicesExTax,
  getJobCurrencyTotalDraftInvoicesExTax,
  getJobCurrencyTotalInvoicedExTax,
  getJobCurrencyTotalPaidInvoicesExTax,
} from "../../../../lib/entities/jobEntity";
import { jobPageOpenInvoicesModal } from "../../../../lib/WebAppAPI/fePages/jobPage";
import { useJobCurrency } from "../../../../redux/selectors/currency/jobCurrency";
import { useInvoiceStats } from "../../../../redux/selectors/invoices/invoiceStats";
import { useJob } from "../../../../redux/selectors/job";
import { useJobCurrencyFinalBudget } from "../../../../redux/selectors/job/selectJobCurrencyFinalBudget";
import Tooltip from "../../../modules/Tooltip";
import JumbotronProgressField, {
  JumbotronProgressFieldValue,
} from "../../components/JumbotronProgressField";
import { useJobId } from "../../context/JobIdContext";
import { JumbotronTooltipTable, LegendDisplay, TooltipTitle } from "../styles";

const JumbotronInvoices = () => {
  const jobId = useJobId();
  const job = useJob(jobId);
  const jobCurrencyFinalBudget = useJobCurrencyFinalBudget(job.id);
  const customerCurrency = useCustomerCurrency();
  const jobCurrency = useJobCurrency(jobId);
  const [counts, issuedTotal] = useInvoiceStats(jobId);

  const text = (() => {
    let value = null;
    let description = null;

    if (counts.paid > 0) {
      value = getJobCurrencyTotalPaidInvoicesExTax(job);
      description = "paid";
    } else if (counts.issued > 0) {
      value = issuedTotal;
      description = "issued";
    } else if (counts.draft > 0) {
      value = getJobCurrencyTotalDraftInvoicesExTax(job);
      description = "draft";
    }

    return {
      value: formatForeignCurrency(customerCurrency, {
        value,
        currency: jobCurrency,
        hideLongSymbols: true,
        format: NUMBER_FORMAT_NO_DECIMAL,
      }),
      description,
    };
  })();

  return (
    <Tooltip
      title={getInvoicesTooltip(job, counts, customerCurrency, jobCurrency)}
      placement="right"
    >
      <JumbotronProgressField
        name="INVOICED (EX TAX)"
        onClick={() => jobPageOpenInvoicesModal(job)}
        progressColor="var(--color-teal)"
        progressPercentage={getProgressPercentage(job, jobCurrencyFinalBudget)}
        secondaryProgressPercentage={getSecondaryProgressPercentage(
          job,
          jobCurrencyFinalBudget
        )}
        secondaryProgressColor="var(--color-teal-light)"
      >
        <div>
          <JumbotronProgressFieldValue>
            <span className="bold">{text.value}</span>
            <span> {text.description}</span>
          </JumbotronProgressFieldValue>
        </div>
      </JumbotronProgressField>
    </Tooltip>
  );
};

const getInvoicesTooltip = (job, counts, customerCurrency, jobCurrency) => (
  <JumbotronTooltipTable>
    <tbody>
      {counts.draft > 0 && (
        <tr>
          <td>Draft</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalDraftInvoicesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell"></td>
        </tr>
      )}
      {counts.awaitingPayment > 0 && (
        <tr>
          <td>Awaiting Payment</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalAwaitingPaymentInvoicesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell">
            <LegendDisplay $color="var(--color-teal-light)"></LegendDisplay>
          </td>
        </tr>
      )}
      {counts.paid > 0 && (
        <tr>
          <td>Paid</td>
          <TooltipTitle>
            {formatForeignCurrency(customerCurrency, {
              value: getJobCurrencyTotalPaidInvoicesExTax(job),
              currency: jobCurrency,
            })}
          </TooltipTitle>
          <td className="legend-display-cell">
            <LegendDisplay $color="var(--color-teal)"></LegendDisplay>
          </td>
        </tr>
      )}
      <tr className="divider">
        <td colSpan="3"></td>
      </tr>
      <tr className="dividerBlank">
        <td colSpan="3"></td>
      </tr>
      <tr>
        <td>Issued</td>
        <TooltipTitle>
          {formatForeignCurrency(customerCurrency, {
            value:
              getJobCurrencyTotalPaidInvoicesExTax(job) +
              getJobCurrencyTotalAwaitingPaymentInvoicesExTax(job),
            currency: jobCurrency,
          })}
        </TooltipTitle>
        <td className="legend-display-cell">
          <LegendDisplay $color="var(--color-gray-bright)"></LegendDisplay>
        </td>
      </tr>
    </tbody>
  </JumbotronTooltipTable>
);

const getProgressTotal = (job, jobCurrencyFinalBudget) => {
  const totalInvoicedExTax = getJobCurrencyTotalInvoicedExTax(job);

  return Math.max(jobCurrencyFinalBudget, totalInvoicedExTax);
};

const getProgressPercentage = (job, jobCurrencyFinalBudget) => {
  const paidInvoicesExTax = getJobCurrencyTotalPaidInvoicesExTax(job);
  const denominator = getProgressTotal(job, jobCurrencyFinalBudget);

  if (paidInvoicesExTax > 0) {
    return Math.round((paidInvoicesExTax / denominator) * 100);
  }

  return 0;
};

const getSecondaryProgressPercentage = (job, jobCurrencyFinalBudget) => {
  const issuedInvoicesExTax =
    getJobCurrencyTotalPaidInvoicesExTax(job) +
    getJobCurrencyTotalAwaitingPaymentInvoicesExTax(job);
  const denominator = getProgressTotal(job, jobCurrencyFinalBudget);

  if (issuedInvoicesExTax > 0) {
    return Math.round((issuedInvoicesExTax / denominator) * 100);
  }

  return 0;
};

export default JumbotronInvoices;
