import { useEffect, useRef, useState } from "react";
import { getChildElement } from "../dom";

export function useFocusRef(focusElementPath = null) {
  const ref = useRef();
  const [focused, setFocused] = useState(false);

  const focusInListener = e => {
    if (ref.current) setFocused(true);
  };

  const focusOutListener = e => {
    if (ref.current) setFocused(false);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("focusin", focusInListener);
      ref.current.addEventListener("focusout", focusOutListener);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("focusin", focusInListener);
        ref.current.removeEventListener("focusout", focusOutListener);
      }
    };
  }, [focusInListener, focusOutListener]);

  useEffect(() => {
    const element = getChildElement(ref.current, focusElementPath);
    if (focused && element !== document.activeElement) {
      element.focus();
    } else if (!focused && element === document.activeElement) {
      element.blur();
    }
  }, [focused]);

  return [ref, focused, setFocused];
}
