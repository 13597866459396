import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import { ActivityEntryParentTypes } from "st-shared/entities/ActivityEntryParentType";
import { ActivityEntryTypes } from "st-shared/entities/ActivityEntryType";
import { InvoiceTypes } from "st-shared/entities/InvoiceType";

import {
  DATETIME_FORMAT_TIME_DOT_DATE,
  DATETIME_FORMAT_TIME_ONLY,
} from "../constants";
import { formatDate, getRelativeTimeText } from "../dates";
import { FrameworkException } from "../exceptions/FrameworkException";
import { entityIdType, typeEntityType } from "../types/entityTypes";

export const activityEntryShape = {
  id: entityIdType.isRequired,
  active: PropTypes.bool.isRequired,
  activityEntryType: typeEntityType.isRequired,
  createdUserId: entityIdType.isRequired,
  createdDatetime: PropTypes.string.isRequired,
  lastModifiedDatetime: PropTypes.string,
  lastModifiedByUserId: entityIdType,
  activityEntryParentType: typeEntityType.isRequired,
  parentEntityId: entityIdType,
  entityId: entityIdType,
  threadActivityEntryCount: PropTypes.number,
  metaData: PropTypes.string,
};

export const activityEntryEntityType = PropTypes.shape(activityEntryShape);

export const createActivityEntry = ({
  id = Entity.temporaryId(),
  activityEntryType,
  createdUserId,
  activityEntryParentType,
  parentEntityId = null,
  entityId = null,
  metaData = "",
}) => ({
  id,
  active: true,
  activityEntryType,
  createdUserId,
  createdDatetime: new Date().toISOString(),
  lastModifiedDatetime: "",
  lastModifiedByUserId: null,
  activityEntryParentType,
  parentEntityId,
  entityId,
  threadActivityEntryCount: 0,
  metaData,
});

export const isActive = ({ active }) => active;

export const notActive = (activityEntry) => !isActive(activityEntry);

export const isJobActivityEntry = ({ activityEntryParentType }) =>
  activityEntryParentType.id === ActivityEntryParentTypes.Job;

export const isThreadActivityEntry = ({ activityEntryParentType }) =>
  activityEntryParentType.id === ActivityEntryParentTypes.ActivityEntryThread;

export const getActivityEntryTypeId = ({ activityEntryType }) =>
  activityEntryType.id;

export const getActivityEntryParentTypeId = ({ activityEntryParentType }) =>
  activityEntryParentType.id;

export const getActivityEntryTypeName = ({ activityEntryType }) =>
  activityEntryType.name;

export const getActivityEntryCreatedDatetime = ({ createdDatetime }) =>
  createdDatetime;

export const getActivityEntryCreatedDate = (entity) =>
  formatDate(getActivityEntryCreatedDatetime(entity));

export const getActivityEntryParentEntityId = ({ parentEntityId }) =>
  parentEntityId;

export const getJobActivityEntryIdPath = (entity) =>
  isJobActivityEntry(entity) ? getActivityEntryParentEntityId(entity) : null;

export const getJobActivityEntryIdDatePath = (entity) =>
  isJobActivityEntry(entity)
    ? `["${getActivityEntryParentEntityId(
        entity
      )}.${getActivityEntryCreatedDate(entity)}"]`
    : null;

export const getThreadActivityEntryIdPath = (entity) =>
  isThreadActivityEntry(entity) ? getActivityEntryParentEntityId(entity) : null;

export const getCreatedByUserId = ({ createdUserId }) => createdUserId;

export const getCreatedDateTime = ({ createdDatetime }) => createdDatetime;

export const getCreatedTimeFormatted = ({ createdDatetime }) =>
  formatDate(createdDatetime, DATETIME_FORMAT_TIME_ONLY);

export const getCreatedDateTimeFormatted = ({ createdDatetime }) =>
  formatDate(createdDatetime, DATETIME_FORMAT_TIME_DOT_DATE);

export const getRelativeLastModifiedTime = ({ lastModifiedDatetime }) =>
  getRelativeTimeText(lastModifiedDatetime);

export const getLastModifiedByUserId = ({ lastModifiedByUserId }) =>
  lastModifiedByUserId;

export const getThreadActivityEntryCount = ({ threadActivityEntryCount }) =>
  threadActivityEntryCount;

export const hasViewPermission = (activityEntry, permissions) => {
  switch (getActivityEntryTypeId(activityEntry)) {
    case ActivityEntryTypes.JobItemOverBudget:
    case ActivityEntryTypes.JobOverBudget:
    case ActivityEntryTypes.QuoteCreated:
    case ActivityEntryTypes.QuoteApproved:
    case ActivityEntryTypes.QuoteDeclined:
    case ActivityEntryTypes.InvoiceCreated:
    case ActivityEntryTypes.InvoicePaid:
    case ActivityEntryTypes.LoggedExpenseCreated:
    case ActivityEntryTypes.PurchaseOrderSent:
    case ActivityEntryTypes.InvoiceSent:
    case ActivityEntryTypes.QuoteSent:
    case ActivityEntryTypes.QuoteDeleted:
    case ActivityEntryTypes.InvoiceDeleted:
    case ActivityEntryTypes.LoggedExpenseDeleted:
      return permissions.canViewJobFinancials;
    default:
      return true;
  }
};

export const matchesFilter = (
  activityEntry,
  isCommentsFilterActive,
  isPostsFilterActive,
  isSystemMessagesFilterActive
) => {
  const activityEntryTypeId = getActivityEntryTypeId(activityEntry);

  switch (activityEntryTypeId) {
    case ActivityEntryTypes.Comment:
      return isCommentsFilterActive;
    case ActivityEntryTypes.Post:
      return isPostsFilterActive;
    default:
      return isSystemMessagesFilterActive;
  }
};

export const getParsedMetaData = ({ metaData }) => JSON.parse(metaData) || {};

export const getMetaDataProperty = (
  activityEntry,
  propertyName,
  mustExist = true
) => {
  const value = getParsedMetaData(activityEntry)[propertyName];

  if (typeof value !== "undefined") return value;

  if (mustExist) {
    const activityEntryName = getActivityEntryTypeName(activityEntry);

    throw new FrameworkException(
      `Unknown meta data property '${propertyName}' for activity entry type '${activityEntryName}'`
    );
  } else return null;
};

export const getMetaDataTitle = (activityEntry) =>
  getMetaDataProperty(activityEntry, "title");

export const getMetaDataHtml = (activityEntry) =>
  getMetaDataProperty(activityEntry, "html");

export const getMetaDataQuoteApprovedBy = (activityEntry) =>
  getMetaDataProperty(activityEntry, "approvedBy");

export const getMetaDataCompanyName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "companyName");

export const getMetaDataCompletedDatetime = (activityEntry) =>
  getMetaDataProperty(activityEntry, "completedDatetime");

export const getMetaDataDate = (activityEntry) =>
  getMetaDataProperty(activityEntry, "date");

export const getMetaDataQuoteDeclinedBy = (activityEntry) =>
  getMetaDataProperty(activityEntry, "declinedBy");

export const getMetaDataEstimatedEndDate = (activityEntry) =>
  getMetaDataProperty(activityEntry, "estimatedEndDate");

export const getMetaDataEstimatedStartDate = (activityEntry) =>
  getMetaDataProperty(activityEntry, "estimatedStartDate");

export const getMetaDataInvoiceTypeName = (activityEntry) => {
  // noinspection EqualityComparisonWithCoercionJS
  if (
    getMetaDataProperty(activityEntry, "invoiceTypeId", false) ==
    InvoiceTypes.CreditNote
  )
    return "credit note";
  else return "invoice";
};

export const getMetaDataInvoiceNumber = (activityEntry) =>
  getMetaDataProperty(activityEntry, "invoiceNumber");

export const getMetaDataInvoiceName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "invoiceName");

export const getMetaDataInvoiceTypeId = (activityEntry) =>
  getMetaDataProperty(activityEntry, "invoiceTypeId");

export const getMetaDataInvoiceRecipients = (activityEntry) =>
  getMetaDataProperty(activityEntry, "invoiceRecipients");

export const getMetaDataJobItemName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "jobItemName");

export const getMetaDataJobItemStatusId = (activityEntry) =>
  Number(getMetaDataProperty(activityEntry, "jobItemStatusId"));

export const getMetaDataJobMilestoneName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "jobMilestoneName");

export const getMetaDataJobName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "jobName");

export const getMetaDataSourceJobName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "sourceJobName");

export const getMetaDataJobPhaseName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "jobPhaseName");

export const getMetaDataJobStatusId = (activityEntry) =>
  Number(getMetaDataProperty(activityEntry, "jobStatusId"));

export const getMetaDataLoggedExpenseName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "loggedExpenseName");

export const getMetaDataMessage = (activityEntry) =>
  getMetaDataProperty(activityEntry, "message");

export const getMetaDataPreviousDate = (activityEntry) =>
  getMetaDataProperty(activityEntry, "previousDate");

export const getMetaDataPreviousJobItemName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "previousJobItemName");

export const getMetaDataPurchaseOrderRecipients = (activityEntry) =>
  getMetaDataProperty(activityEntry, "purchaseOrderRecipients");

export const getMetaDataQuoteNumber = (activityEntry) =>
  getMetaDataProperty(activityEntry, "quoteNumber");

export const getMetaDataQuoteRecipients = (activityEntry) =>
  getMetaDataProperty(activityEntry, "quoteRecipients");

export const getMetaDataJobCurrencyTotalPlannedTimeExTax = (activityEntry) => {
  try {
    return getMetaDataProperty(
      activityEntry,
      "jobCurrencyTotalPlannedTimeExTax"
    );
  } catch {
    return getMetaDataProperty(activityEntry, "totalPlannedTimeExTax");
  }
};

export const getMetaDataRateCardId = (activityEntry) => {
  try {
    return getMetaDataProperty(activityEntry, "rateCardId");
  } catch {
    return null;
  }
};

export const getMetaDataUserDisplayName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "userDisplayName");

export const getMetaDataExternalAccountingPlatformName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "externalAccountingPlatformName");

export const getMetaDataName = (activityEntry) =>
  getMetaDataProperty(activityEntry, "name");

export const getMetaDataPaymentAmountIncTax = (activityEntry) =>
  getMetaDataProperty(activityEntry, "paymentAmountIncTax");

export const getMetaDataCurrencyId = (activityEntry) =>
  getMetaDataProperty(activityEntry, "currencyId");

export const getMetaDataIsBillable = (activityEntry) =>
  getMetaDataProperty(activityEntry, "isBillable");

export const getMetaDataOtherInvoiceId = (activityEntry) =>
  getMetaDataProperty(activityEntry, "otherInvoiceId", false);

export const getMetaDataOtherInvoiceNumber = (activityEntry) =>
  getMetaDataProperty(activityEntry, "otherInvoiceNumber", false);
