import { reduce } from "lodash-es";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { costingMethodObj, timeAllocationMethodObj } from "st-shared/entities";

import {
  getJobId,
  getSellRate,
  getTotalPlannedMoney,
  hasSellRate,
} from "../../../lib/entities/jobItemEntity";
import {
  getJobCurrencySellRate,
  getTotalPlannedMinutes as getJobItemUserTotalPlannedMinutes,
  getUserId,
} from "../../../lib/entities/jobItemUserEntity";
import { asDecimal } from "../../../lib/math";
import { selectJobItemRole } from "../../../state/entities/jobItemRole/selectors/selectJobItemRole";
import { selectJobItemRoleIdsByJobItemId } from "../../../state/entities/jobItemRole/selectors/selectJobItemRoleIdsByJobItemId";
import { selectJob } from "../job";
import { selectJobItemUser } from "../jobItemUser";
import { selectJobItemUserIdsByJobItemId } from "../jobItemUser/selectJobItemUserIdsByJobItemId";
import { selectRoleRateByRoleIdRateCardId } from "../rateSelectors";
import { selectUserSellRate } from "../user/selectUserSellRate";
import { selectJobItem } from "./index";
import { selectCalculatedPlannedCost } from "./selectCalculatedPlannedCost";
import { selectIsEditingJobItem } from "./ui/isEditing";
import { selectIsSavingJobItem } from "./ui/isSaving";

export const selectJobItemCalculatedTotalPlannedTimeExTax = (
  state,
  { jobItemId, includeNonBillable = true }
) => {
  const jobItem = selectJobItem(state, { jobItemId });
  const isSaving = selectIsSavingJobItem(state, { jobItemId });
  const isEditing = selectIsEditingJobItem(state, { jobItemId });
  const costingMethod = costingMethodObj(jobItem.costingMethod);

  if (!includeNonBillable && !jobItem.isBillable) return 0;

  // Cost by value or View Mode
  if (
    (!isEditing && !isSaving) ||
    costingMethodObj(jobItem.costingMethod).isBasedByValue()
  ) {
    return getTotalPlannedMoney(jobItem);
  }

  // Fallback Rate
  if (
    costingMethodObj(jobItem.costingMethod).isItem() &&
    !hasSellRate(jobItem)
  ) {
    return 0;
  }

  // time by item
  if (timeAllocationMethodObj(jobItem.timeAllocationMethod).isItem()) {
    return selectCalculatedPlannedCost(state, { jobItemId });
  }

  // time by people
  const jobItemUsersTotal = reduce(
    selectJobItemUserIdsByJobItemId(state, { jobItemId }),
    (totalPlannedExTax, jobItemUserId) => {
      const jobItemUser = selectJobItemUser(state, { jobItemUserId });

      let jobCurrencySellRate;
      if (costingMethod.isBasedByUser()) {
        jobCurrencySellRate = getJobCurrencySellRate(jobItemUser);
      } else if (hasSellRate(jobItem)) {
        jobCurrencySellRate = getSellRate(jobItem);
      } else {
        jobCurrencySellRate = selectUserSellRate(state, {
          userId: getUserId(jobItemUser),
          jobId: getJobId(jobItem),
        });
      }
      const jobItemUserTotalPlannedExTax = numeral(jobCurrencySellRate)
        .multiply(
          numeral(getJobItemUserTotalPlannedMinutes(jobItemUser))
            .divide(60)
            .value()
        )
        .value();

      return numeral(totalPlannedExTax)
        .add(jobItemUserTotalPlannedExTax)
        .value();
    },
    0
  );

  const jobItemRolesTotal = reduce(
    selectJobItemRoleIdsByJobItemId(state, { jobItemId }),
    (totalPlannedExTax, jobItemRoleId) => {
      const jobItemRole = selectJobItemRole(state, { jobItemRoleId });

      let jobCurrencySellRate;
      if (costingMethod.isBasedByUser()) {
        jobCurrencySellRate = asDecimal(jobItemRole.jobCurrencySellRate);
      } else if (hasSellRate(jobItem)) {
        jobCurrencySellRate = getSellRate(jobItem);
      } else {
        const job = selectJob(state, { jobId: jobItem.jobId });

        const rate = selectRoleRateByRoleIdRateCardId(state, {
          roleId: jobItemRole.roleId,
          rateCardId: job.rateCardId,
        });

        if (rate) jobCurrencySellRate = rate;
      }

      const jobItemRoleTotalPlannedExTax = numeral(jobCurrencySellRate)
        .multiply(numeral(jobItemRole.totalPlannedMinutes).divide(60).value())
        .value();

      return numeral(totalPlannedExTax)
        .add(jobItemRoleTotalPlannedExTax)
        .value();
    },
    0
  );

  return jobItemUsersTotal + jobItemRolesTotal;
};

export function selectJobItemUserCalcaulatedTotal(state, { jobItemId }) {
  const jobItem = selectJobItem(state, { jobItemId });

  const costingMethod = costingMethodObj(jobItem.costingMethod);

  // time by people
  const jobItemUsersTotal = reduce(
    selectJobItemUserIdsByJobItemId(state, { jobItemId }),
    (totalPlannedExTax, jobItemUserId) => {
      const jobItemUser = selectJobItemUser(state, { jobItemUserId });

      let jobCurrencySellRate;
      if (costingMethod.isBasedByUser()) {
        jobCurrencySellRate = getJobCurrencySellRate(jobItemUser);
      } else if (hasSellRate(jobItem)) {
        jobCurrencySellRate = getSellRate(jobItem);
      } else {
        jobCurrencySellRate = selectUserSellRate(state, {
          userId: getUserId(jobItemUser),
          jobId: getJobId(jobItem),
        });
      }
      const jobItemUserTotalPlannedExTax = numeral(jobCurrencySellRate)
        .multiply(
          numeral(getJobItemUserTotalPlannedMinutes(jobItemUser))
            .divide(60)
            .value()
        )
        .value();

      return numeral(totalPlannedExTax)
        .add(jobItemUserTotalPlannedExTax)
        .value();
    },
    0
  );

  const jobItemRolesTotal = reduce(
    selectJobItemRoleIdsByJobItemId(state, { jobItemId }),
    (totalPlannedExTax, jobItemRoleId) => {
      const jobItemRole = selectJobItemRole(state, { jobItemRoleId });

      let jobCurrencySellRate;
      if (costingMethod.isBasedByUser()) {
        jobCurrencySellRate = asDecimal(jobItemRole.jobCurrencySellRate);
      } else if (hasSellRate(jobItem)) {
        jobCurrencySellRate = getSellRate(jobItem);
      } else {
        const job = selectJob(state, { jobId: jobItem.jobId });

        const rate = selectRoleRateByRoleIdRateCardId(state, {
          roleId: jobItemRole.roleId,
          rateCardId: job.rateCardId,
        });

        if (rate) jobCurrencySellRate = rate;
      }

      const jobItemRoleTotalPlannedExTax = numeral(jobCurrencySellRate)
        .multiply(numeral(jobItemRole.totalPlannedMinutes).divide(60).value())
        .value();

      return numeral(totalPlannedExTax)
        .add(jobItemRoleTotalPlannedExTax)
        .value();
    },
    0
  );

  return jobItemUsersTotal + jobItemRolesTotal;
}

export function useJobItemUserCalcaulatedTotal(jobItemId) {
  return useSelector((state) =>
    asDecimal(
      selectJobItemUserCalcaulatedTotal(state, {
        jobItemId,
      })
    )
  );
}
