import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  useEntityStoreEntities,
} from "../../EntityStore";
import {
  createUsePreviousSingleEntity,
  createUseSingleEntity,
} from "../../SingleEntityStore";
import { GoogleCalendarStore } from "./GoogleCalendar.store";

export const useGoogleCalendarEntity = createUseEntityStoreEntity(
  EntityClass.GoogleCalendar
);

export const getGoogleCalendarEntity = createGetEntityStoreEntity(
  EntityClass.GoogleCalendar
);

export const useGoogleCalendar = createUseSingleEntity(GoogleCalendarStore);

export const usePreviousGoogleCalendar =
  createUsePreviousSingleEntity(GoogleCalendarStore);

export function useGoogleCalendarStore() {
  return GoogleCalendarStore.useStore();
}

export function useGoogleCalendarActions() {
  return GoogleCalendarStore.useActions();
}

export function useGoogleCalendarEntities() {
  return useEntityStoreEntities(EntityClass.GoogleCalendar);
}
