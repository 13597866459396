import { Entity } from "@streamtimefe/entities";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  DATA_VALUE_HANDLE_LEFT,
  DATA_VALUE_HANDLE_RIGHT,
  DEFAULT_COORDS,
  JOB_ITEM_DEPENDANCY_CREATE_END,
  JOB_ITEM_DEPENDANCY_CREATE_SAVE,
  JOB_ITEM_DEPENDANCY_CREATE_START,
  KEY_SHIFT_LEFT,
  KEY_SHIFT_RIGHT,
} from "../../../lib/constants";
import {
  getElementClientCenterOffset,
  getElementClientOffset,
  getElementHasClass,
  getElementSize,
  getPointerClientOffset,
} from "../../../lib/dom";
import { getJobItemDependancyTypeFromHandles } from "../../../lib/entities/jobItemDependancyEntity";
import {
  addKeyEventListener,
  removeKeyEventListener,
} from "../../../lib/events";
import {
  getTargetDependancyHandle,
  getTargetDependancyHandleType,
  getTargetJobBarElement,
  getTargetJobItemBarElement,
  getTargetJobItemBarId,
  getTargetJobItemBarJobId,
  getTargetJobPhaseBarElement,
} from "../../../lib/eventTargets";
import createAction from "../../../redux/helpers/createAction";
import NewDependency from "./NewDependancy";

const mapState = null;

const mapDispatch = (dispatch) => ({
  doStartCreatingDependancy: (jobItemId) =>
    dispatch(createAction(JOB_ITEM_DEPENDANCY_CREATE_START, { jobItemId })),
  doEndCreatingDependancy: (jobItemId) =>
    dispatch(createAction(JOB_ITEM_DEPENDANCY_CREATE_END, { jobItemId })),
  doCreateDependancy: (payload) =>
    dispatch(createAction(JOB_ITEM_DEPENDANCY_CREATE_SAVE, payload)),
});

export default (WrappedComponent) => {
  class DependancyHandlers extends React.PureComponent {
    dependancyItem = null;

    isCreating = false;

    static propTypes = {
      readOnly: PropTypes.bool.isRequired,
      doStartCreatingDependancy: PropTypes.func.isRequired,
      doEndCreatingDependancy: PropTypes.func.isRequired,
      doCreateDependancy: PropTypes.func.isRequired,
      getScrollOffsetX: PropTypes.func.isRequired,
      getScrollOffsetY: PropTypes.func.isRequired,
      onPointerDown: PropTypes.func,
      onPointerMove: PropTypes.func,
      onPointerUp: PropTypes.func,
      onLostPointerCapture: PropTypes.func,
      className: PropTypes.string,
    };

    static defaultProps = {
      onPointerDown: null,
      onPointerMove: null,
      onPointerUp: null,
      onLostPointerCapture: null,
      className: "",
    };

    state = {
      isInvalid: false,
      startPosition: DEFAULT_COORDS,
      endPosition: DEFAULT_COORDS,
      isFloatMode: false,
    };

    componentDidMount() {
      addKeyEventListener("keydown", this.onKeyDown);
      addKeyEventListener("keyup", this.onKeyUp);
    }

    componentWillUnmount() {
      removeKeyEventListener("keydown", this.onKeyDown);
      removeKeyEventListener("keyup", this.onKeyUp);
    }

    onKeyDown = (e) => {
      switch (e.code) {
        case KEY_SHIFT_LEFT:
        case KEY_SHIFT_RIGHT:
          this.setState({ isFloatMode: true });
          break;
        default:
      }
    };

    onKeyUp = (e) => {
      switch (e.code) {
        case KEY_SHIFT_LEFT:
        case KEY_SHIFT_RIGHT:
          this.setState({ isFloatMode: false });
          break;
        default:
      }
    };

    onPointerDown = (e) => {
      const {
        readOnly,
        getScrollOffsetX,
        getScrollOffsetY,
        onPointerDown,
        doStartCreatingDependancy,
      } = this.props;
      const jobItemId = getTargetJobItemBarId(e);
      const isDependancyEvent = jobItemId && !!getTargetDependancyHandle(e);

      if (!readOnly && jobItemId && isDependancyEvent) {
        const jobId = getTargetJobItemBarJobId(e);
        const dependancyStartType = getTargetDependancyHandleType(e);
        const startPosition = getElementClientCenterOffset(
          getTargetDependancyHandle(e)
        );

        this.dependancyItem = {
          dependancyStartType,
          dependancyEndType: null,
          jobItemId,
          jobId,
          origin: {
            jobItemId,
            scrollOffsetX: getScrollOffsetX(),
            scrollOffsetY: getScrollOffsetY(),
          },
        };

        doStartCreatingDependancy(jobItemId);

        this.isCreating = true;
        this.setState({
          isInvalid: false,
          startPosition,
          endPosition: startPosition,
        });
      }

      return onPointerDown && onPointerDown(e);
    };

    onPointerMove = (e) => {
      const { props, dependancyItem } = this;
      const { onPointerMove } = props;

      if (this.isCreating) {
        const pointerPosition = getPointerClientOffset(e);
        const isJobBar = Boolean(getTargetJobBarElement(e));
        const isJobPhaseBar = Boolean(getTargetJobPhaseBarElement(e));
        const isJobItemBar = Boolean(getTargetJobItemBarElement(e));
        const jobId = getTargetJobItemBarJobId(e);
        const jobItemId = getTargetJobItemBarId(e);

        if (
          isJobItemBar &&
          jobId === dependancyItem.jobId &&
          jobItemId !== dependancyItem.origin.jobItemId
        ) {
          let dependancyEndType;
          let endPosition;

          const barNode = getTargetJobItemBarElement(e);
          const barSize = getElementSize(barNode);
          const barOffset = getElementClientOffset(barNode);
          const barCenter = getElementClientCenterOffset(barNode);
          const startOnly = getElementHasClass(barNode, "startOnly");
          const endOnly = getElementHasClass(barNode, "endOnly");

          if (!endOnly && (startOnly || pointerPosition.x <= barCenter.x)) {
            dependancyEndType = DATA_VALUE_HANDLE_LEFT;
            endPosition = { x: barOffset.x, y: barCenter.y };
          } else {
            dependancyEndType = DATA_VALUE_HANDLE_RIGHT;
            endPosition = { x: barOffset.x + barSize.w, y: barCenter.y };
          }

          this.dependancyItem = {
            ...dependancyItem,
            dependancyEndType,
            jobItemId,
          };

          this.setState({ endPosition, isInvalid: false });
        } else {
          let isInvalid = false;

          if (isJobBar) isInvalid = true;
          else if (isJobPhaseBar) isInvalid = true;
          else if (isJobItemBar && jobId !== dependancyItem.jobId)
            isInvalid = true;

          this.dependancyItem = {
            ...dependancyItem,
            dependancyEndType: null,
            jobItemId: null,
          };

          this.setState({
            endPosition: pointerPosition,
            isInvalid,
          });
        }
      }

      return onPointerMove && onPointerMove(e);
    };

    onPointerUp = (e) => {
      const { dependancyItem } = this;
      const { doEndCreatingDependancy, doCreateDependancy, onPointerUp } =
        this.props;
      const { isFloatMode } = this.state;

      if (this.isCreating) {
        if (
          dependancyItem.jobItemId &&
          dependancyItem.jobItemId !== dependancyItem.origin.jobItemId
        ) {
          doCreateDependancy({
            id: Entity.temporaryId(),
            jobId: dependancyItem.jobId,
            parentJobItemId: dependancyItem.origin.jobItemId,
            childJobItemId: dependancyItem.jobItemId,
            dependancyTypeId: getJobItemDependancyTypeFromHandles(
              dependancyItem.dependancyStartType,
              dependancyItem.dependancyEndType
            ),
            isFloat: isFloatMode,
          });
        }

        doEndCreatingDependancy(dependancyItem.origin.jobItemId);

        this.dependancyItem = null;
        this.isCreating = false;
        this.forceUpdate();
      }

      return onPointerUp && onPointerUp(e);
    };

    onLostPointerCapture = (e) => {
      const { dependancyItem } = this;
      const { onLostPointerCapture, doEndCreatingDependancy } = this.props;

      if (this.isCreating) {
        doEndCreatingDependancy(dependancyItem.origin.jobItemId);

        this.dependancyItem = null;
        this.isCreating = false;
        this.forceUpdate();
      }

      return onLostPointerCapture && onLostPointerCapture(e);
    };

    get className() {
      const { className } = this.props;
      const { isInvalid } = this.state;
      const classes = className ? [className] : [];

      if (this.isCreating) {
        if (isInvalid) classes.push("invalidDependancy");
        else classes.push("creatingDependancy");
      }

      return classes.join(" ");
    }

    get handlers() {
      return {
        onPointerDown: this.onPointerDown,
        onPointerMove: this.onPointerMove,
        onPointerUp: this.onPointerUp,
        onLostPointerCapture: this.onLostPointerCapture,
      };
    }

    get scrollDistance() {
      const { dependancyItem } = this;
      const { getScrollOffsetX, getScrollOffsetY } = this.props;
      return dependancyItem
        ? {
            x: getScrollOffsetX() - dependancyItem.origin.scrollOffsetX,
            y: getScrollOffsetY() - dependancyItem.origin.scrollOffsetY,
          }
        : DEFAULT_COORDS;
    }

    render() {
      const { props, className, handlers, dependancyItem } = this;
      const { startPosition, endPosition, isFloatMode } = this.state;

      return (
        <>
          <WrappedComponent
            {...{
              ...props,
              className,
              ...handlers,
              isCreatingDependancy: this.isCreating,
            }}
          />
          {dependancyItem && (
            <NewDependency
              startPosition={startPosition}
              endPosition={endPosition}
              scrollDistance={this.scrollDistance}
              isFloatMode={isFloatMode}
            />
          )}
        </>
      );
    }
  }

  return connect(mapState, mapDispatch)(DependancyHandlers);
};
