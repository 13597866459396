import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getParsedMetaData
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const UserAssignedToJob = ({ activityEntry }) => {
  const { createdUserId, entityId } = activityEntry;
  const { userDisplayName } = getParsedMetaData(activityEntry);
  return (
    <SystemMessage userId={getCreatedByUserId(activityEntry)}>
      {" assigned "}
      {createdUserId === entityId ? "themself" : <b>{userDisplayName}</b>}
      {" to this job"}
    </SystemMessage>
  );
};

UserAssignedToJob.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default UserAssignedToJob;
