import * as PropTypes from "prop-types";
import { asString } from "st-shared/lib";

import { entityIdType } from "../types/entityTypes";

export const roleEntityType = PropTypes.shape({
  id: entityIdType.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired,
});

export const isActive = ({ active }) => active;

export const isDeleted = ({ active }) => !active;

export const getName = ({ name }) => name;

export const getSearchString = (entity) => asString(getName(entity));

export const getAsOption = (entity) => ({
  key: entity.id,
  value: entity.name,
  orderId: entity.orderId,
  searchString: getSearchString(entity),
});
