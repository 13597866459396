export const ENTITY_CLASS_ACTIVITY_ENTRY = "ActivityEntry";
export const ENTITY_CLASS_ADDRESS = "Address";
export const ENTITY_CLASS_COMPANY = "Company";
export const ENTITY_CLASS_CONTACT = "Contact";
export const ENTITY_CLASS_INVOICE = "Invoice";
export const ENTITY_CLASS_INVOICE_LINE_ITEM = "InvoiceLineItem";
export const ENTITY_CLASS_INVOICE_PAYMENT = "InvoicePayment";
export const ENTITY_CLASS_JOB = "Job";
export const ENTITY_CLASS_JOB_ITEM = "JobItem";
export const ENTITY_CLASS_JOB_ITEM_DEPENDANCY = "JobItemDependancy";
export const ENTITY_CLASS_JOB_ITEM_USER = "JobItemUser";
export const ENTITY_CLASS_JOB_ITEM_SUB_ITEM = "JobItemSubItem";
export const ENTITY_CLASS_JOB_PHASE = "JobPhase";
export const ENTITY_CLASS_JOB_MILESTONE = "JobMilestone";
export const ENTITY_CLASS_LOGGED_EXPENSE = "LoggedExpense";
export const ENTITY_CLASS_ORGANISATION_ACCOUNTING_PREFERENCES =
  "OrganisationAccountingPreferences";
export const ENTITY_CLASS_PURCHASE_ORDER = "PurchaseOrder";
export const ENTITY_CLASS_PURCHASE_ORDER_LINE_ITEM = "PurchaseOrderLineItem";
export const ENTITY_CLASS_QUOTE_LINE_ITEM = "QuoteLineItem";
export const ENTITY_CLASS_QUOTE = "Quote";
export const ENTITY_CLASS_REPEATING_LOGGED_TIME = "RepeatingLoggedTime";
export const ENTITY_CLASS_TEAM = "Team";
export const ENTITY_CLASS_USER = "User";
export const ENTITY_CLASS_ROLE = "Role";
export const ENTITY_CLASS_ROLE_RATE = "RoleRate";
export const ENTITY_CLASS_RATE_CARD = "RateCard";
export const ENTITY_CLASS_TAX_RATE = "TaxRate";
export const ENTITY_CLASS_LABEL = "Label";
