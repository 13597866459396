import isError from "lodash-es/isError";
import { call, put } from "redux-saga/effects";

import {
  feToWebHidePageLoadingMask,
  feToWebShowPageLoadingMask,
} from "../../../../lib/WebAppAPI/fePages/genericWeb";
import { takeLatestBy } from "../../../../redux/helpers/sagaEffects";
import { sagaError } from "../../../../redux/helpers/sagaErrorHandlers";
import { actionEntitiesReceived } from "../../actions";
import { JOB_ADD_USERS_AND_ROLES, JobAddUsersAndRolesAction } from "../actions";
import JobAPI from "../api";

function* addUsersAndRoles(action: JobAddUsersAndRolesAction) {
  try {
    feToWebShowPageLoadingMask(11);

    const data: Awaited<ReturnType<typeof JobAPI.addUsersAndRoles>> =
      yield call(
        JobAPI.addUsersAndRoles,
        action.jobId,
        action.userIds,
        action.roleIds
      );

    yield put(actionEntitiesReceived(data));
  } catch (error) {
    if (isError(error)) sagaError(error);
  } finally {
    feToWebHidePageLoadingMask();
  }
}

export default function* watchJobAddTeamMemberUser() {
  yield takeLatestBy(
    [JOB_ADD_USERS_AND_ROLES],
    addUsersAndRoles,
    (action: JobAddUsersAndRolesAction) => action.jobId
  );
}
