import React from "react";
import {
  activityEntryEntityType,
  getCreatedByUserId,
  getMetaDataIsBillable,
  getMetaDataJobItemName
} from "../../../../../lib/entities/activityEntryEntity";
import SystemMessage from "./SystemMessage";

const JobItemIsBillableChanged = ({ activityEntry }) => (
  <SystemMessage userId={getCreatedByUserId(activityEntry)}>
    {" marked item "}
    <b>{getMetaDataJobItemName(activityEntry)}</b>
    {" as "}
    {getMetaDataIsBillable(activityEntry) ? "billable" : "non-billable"}
  </SystemMessage>
);

JobItemIsBillableChanged.propTypes = {
  activityEntry: activityEntryEntityType.isRequired
};

export default JobItemIsBillableChanged;
