import {
  IconButton,
  IconSize,
  MdTimeline,
  MdViewColumn,
  RTooltip,
} from "st-shared/components";
import {
  ReportingSearchType,
  TReportingSearchType,
} from "st-shared/lib/webapi/reporting";

import { reportingStore } from "../../state/stores/reportingStore";
import { useReportingSavedSegmentSearchType } from "../../state/stores/savedSegmentSelectors";
import { buttonCss, container } from "./SearchTypeSelector.css";

export function SearchTypeSelector() {
  const searchType = useReportingSavedSegmentSearchType();

  function setSearchType(newSearchType: TReportingSearchType) {
    if (newSearchType !== searchType) {
      reportingStore().savedSegment.actions.setSearchType(newSearchType);
    }
  }

  return (
    <div className={container}>
      <IconButton
        className={
          searchType === ReportingSearchType.ColumnSeries
            ? buttonCss.selected
            : buttonCss.base
        }
        iconProps={{
          icon: MdViewColumn,
          size: IconSize.XLarge,
        }}
        onClick={() => setSearchType(ReportingSearchType.ColumnSeries)}
        data-tooltip-id="search-type-tooltip"
        data-tooltip-content="View all totals as columns"
      />
      <IconButton
        className={
          searchType === ReportingSearchType.TimeSeries
            ? buttonCss.selected
            : buttonCss.base
        }
        iconProps={{
          icon: MdTimeline,
          size: IconSize.XLarge,
        }}
        onClick={() => setSearchType(ReportingSearchType.TimeSeries)}
        data-tooltip-id="search-type-tooltip"
        data-tooltip-content="View selected columns over time"
      />
      <RTooltip id="search-type-tooltip" place="top" />
    </div>
  );
}
