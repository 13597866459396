import clsx from "clsx";
import { plate, slate } from "st-shared/external";

import { useReportingSavedSegmentColumns } from "../../../../state/stores/savedSegmentSelectors";
import { TColumnElement } from "../plugins/column/types";
import { columnCss } from "./ColumnElement.css";

export const ColumnElement = plate.withRef<
  typeof plate.PlateElement,
  {
    prefix?: string;
    onClick?: (columnNode: any) => void;
    renderLabel?: (columnable: TColumnElement) => string;
  }
>(({ children, className, onClick, ...props }, ref) => {
  const element = plate.useElement<TColumnElement>();
  const selected = slate.useSelected();
  const focused = slate.useFocused();

  const columns = useReportingSavedSegmentColumns();

  const value = columns[element.value] ? columns[element.value].name : null;

  return (
    <plate.PlateElement
      ref={ref}
      className={clsx(columnCss, className)}
      data-deleted-column={value === null}
      data-selected={selected && focused}
      data-slate-value={element.value}
      contentEditable={false}
      onClick={plate.getHandler(onClick, element)}
      {...props}
    >
      {value || "Deleted Column"}
      {children}
    </plate.PlateElement>
  );
});
