import type { ConstEnum } from "@streamtimefe/types";
import EventEmitter from "eventemitter3";
import { useEffect, useState } from "react";

export const RichTextEvents = {
  SaveOnBlur: "saveOnBlur",
  SaveOnEnter: "saveOnEnter",
} as const;

export type TRichTextEvents = ConstEnum<typeof RichTextEvents>;

export type TRichTextEmitter = EventEmitter<TRichTextEvents>;

export function useRichTextEmitter(): TRichTextEmitter {
  return useState(() => new EventEmitter<TRichTextEvents>())[0];
}

export function useRichTextEventListener(
  richTextEmitter: TRichTextEmitter,
  event: TRichTextEvents,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => void
) {
  useEffect(() => {
    richTextEmitter.on(event, callback);
    return () => {
      richTextEmitter.off(event, callback);
    };
  }, [richTextEmitter, event, callback]);
}
