import { get } from "lodash-es";
import { createCachedSelector } from "re-reselect";
import { useSelector } from "react-redux";
import { ENTITY_NAME_LABELS } from "../../../lib/constants";
import { selectPropsLabelId } from "../index";

export const selectStateLabels = state => state.entities[ENTITY_NAME_LABELS];

export const selectLabel = createCachedSelector(
  [selectStateLabels, selectPropsLabelId],
  (labels, labelId) => get(labels, `byId.${labelId}`)
)(selectPropsLabelId);

export const useLabel = labelId =>
  useSelector(state => selectLabel(state, { labelId }));
