import type { TValueFormatEnum } from "@streamtimefe/entities";
import clsx from "clsx";
import type { ChartJS } from "st-shared/lib";

import { annotationDividerCss, chartTooltipValueCss } from "../Chart.css";
import type {
  ReportingChartAnnotation,
  ReportingChartDataset,
  ReportingChartType,
} from "../ReportingChartData";

export function renderReportingTooltip<
  T extends ReportingChartType = ReportingChartType,
>(
  valueFormatter: (
    value: string | number,
    format: TValueFormatEnum,
    looseFormat?: boolean
  ) => string | number,
  annotations?: ReportingChartAnnotation[]
) {
  function renderTooltip(tooltip: ChartJS.TooltipModel<T>) {
    return (
      <>
        <div className="title">{tooltip.title[0]}</div>
        {tooltip.dataPoints.map((dataPoint) => {
          const dataset = dataPoint.dataset as ReportingChartDataset<T>;
          return (
            <div key={dataset.id} className="line">
              <span
                className="icon"
                style={{
                  backgroundColor: dataset.borderColor as string,
                }}
              ></span>
              <span className="label">{dataset.label}</span>
              <span className={clsx("label", chartTooltipValueCss)}>
                {valueFormatter(dataPoint.raw as number, dataset.format)}
              </span>
            </div>
          );
        })}
        {annotations &&
          annotations?.length > 0 &&
          tooltip.dataPoints.length > 0 && (
            <div className={annotationDividerCss} />
          )}
        {annotations?.map((annotation) => {
          return (
            <div key={annotation.id} className="line">
              <span
                className="icon"
                style={{
                  backgroundColor: annotation.borderColor as string,
                }}
              ></span>
              <span className="label">{annotation.name}</span>
              <span className={clsx("label", chartTooltipValueCss)}>
                {valueFormatter(annotation.value as number, annotation.format)}
              </span>
            </div>
          );
        })}
      </>
    );
  }
  return renderTooltip;
}
