import { SavedSegmentTypeEnum } from "@streamtimefe/entities";
import type { ReportingSavedSegment } from "st-shared/entities";
import type { TEntityId } from "st-shared/entities/Entity";
import {
  getUserPreference,
  setUserPreference,
  UserPreferenceKeys,
  useUserPreference,
} from "st-shared/stores";

const key =
  UserPreferenceKeys[`EncodedSearch_${SavedSegmentTypeEnum.Reporting}`];

export type ReportingEncodedSearch = {
  entityId?: TEntityId;
  value: Partial<ReportingSavedSegment>;
  name: string;
};

function transformValue(
  value: string | undefined
): ReportingEncodedSearch | undefined {
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
}

export function useReportingEncodedSearch() {
  const userPreference = useUserPreference(key);
  return transformValue(userPreference);
}

export function getReportingEncodedSearch() {
  const userPreference = getUserPreference(key);
  return transformValue(userPreference);
}

export function setReportingEncodedSearch(
  encodedSearch?: ReportingEncodedSearch
) {
  setUserPreference(key, encodedSearch ? JSON.stringify(encodedSearch) : "");
}
