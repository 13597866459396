import { EntityClass } from "@streamtimefe/entities";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
} from "../../EntityStore";
import { createUseSingleEntity } from "../../SingleEntityStore";
import { RoleRateStore } from "./RoleRateStore";

export * from "./selectors";

export const useRoleRateEntity = createUseEntityStoreEntity(
  EntityClass.RoleRate
);

export const getRoleRateEntity = createGetEntityStoreEntity(
  EntityClass.RoleRate
);

export const useRoleRate = createUseSingleEntity(RoleRateStore);

export function useRoleRateStore() {
  return RoleRateStore.useStore();
}

export function useRoleRateActions() {
  return RoleRateStore.useActions();
}
