import { ReportingColumn, ReportingColumnType } from "st-shared/entities";

import { EditDataColumn } from "../menus/EditDataColumn";
import { EditFormulaColumn } from "../menus/EditFormulaColumn";

type Props = {
  anchorEl: Element | null;
  column: ReportingColumn;
  onClose: () => void;
};

export function ColumnEditMenu({ anchorEl, column, onClose }: Props) {
  if (!anchorEl) {
    return null;
  }

  switch (column.type) {
    case ReportingColumnType.Data:
    case ReportingColumnType.Total:
      return (
        <EditDataColumn
          anchorEl={anchorEl}
          onClose={onClose}
          init={{ type: "edit", columnId: column.id }}
        />
      );
    case ReportingColumnType.Formula:
      return (
        <EditFormulaColumn
          anchorEl={anchorEl}
          onClose={onClose}
          init={{ type: "edit", column }}
        />
      );
    default:
      return null;
  }
}
