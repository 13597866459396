import clsx from "clsx";
import { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { EmitterSubscriber } from "st-shared/lib";

import { getTodayDate } from "../../../lib/dates";
import {
  actionScheduleZoomIn,
  actionScheduleZoomOut,
} from "../../../state/ui/schedule/actions";
import { isMaxZoomLevel, isMinZoomLevel } from "../../../state/ui/schedule/lib";
import { useScheduleUiZoomLevel } from "../../../state/ui/schedule/selectors/selectScheduleUi";
import Button from "../../elements/Button";
import ButtonGroup from "../../elements/ButtonGroup";
import AddIcon from "../../elements/Icons/AddIcon";
import ChevronLeftIcon from "../../elements/Icons/ChevronLeftIcon";
import ChevronRightIcon from "../../elements/Icons/ChevronRightIcon";
import DatePickerIcon from "../../elements/Icons/DatePickerIcon";
import RemoveIcon from "../../elements/Icons/RemoveIcon";
import ShortcutButton from "../../elements/ShortcutButton";
import PopoverDatePicker from "../../modules/Pickers/PopoverDatePicker";
import { ScheduleScrollContext } from "../../modules/ScrollContexts";
import { ICON_SIZE } from "../../modules/StyledIcon";
import { useScheduleSideMenuWidth } from "../UserPreference/sideBarUserPreferences";
import {
  DatePickerButton,
  scheduleNavigationAction,
  scheduleNavigationContainer,
  ZoomButton,
} from "./ScheduleNavigation.css";

function ScheduleNavigation() {
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(getTodayDate());
  const zoomLevel = useScheduleUiZoomLevel();
  const { goToDate, goBackward, goForward, beforeZoom } = useContext(
    ScheduleScrollContext
  );

  const width = useScheduleSideMenuWidth();

  const dispatch = useDispatch();

  function doZoomIn() {
    dispatch(actionScheduleZoomIn());
  }

  function doZoomOut() {
    dispatch(actionScheduleZoomOut());
  }

  function isMaxZoom() {
    return isMaxZoomLevel(zoomLevel);
  }

  function isMinZoom() {
    return isMinZoomLevel(zoomLevel);
  }

  function open() {
    setAnchorEl(ref.current);
  }

  function close() {
    setAnchorEl(null);
  }

  function selectDate(date: string) {
    setSelectedDate(date);
  }

  return (
    <div
      className={clsx("scheduleDatePicker", scheduleNavigationContainer)}
      ref={ref}
      style={{ justifyContent: width > 400 ? "center" : "space-between" }}
    >
      <div onClick={goBackward} className={scheduleNavigationAction}>
        <ChevronLeftIcon size={ICON_SIZE.XXX_LARGE} />
      </div>

      <ButtonGroup>
        <ShortcutButton
          className={ZoomButton}
          disabled={isMinZoom()}
          onClick={() => {
            beforeZoom();
            doZoomOut();
          }}
          keycode={189}
        >
          <RemoveIcon />
        </ShortcutButton>
        <Button onClick={open} className={DatePickerButton}>
          <DatePickerIcon />
        </Button>
        <ShortcutButton
          className={ZoomButton}
          disabled={isMaxZoom()}
          onClick={() => {
            beforeZoom();
            doZoomIn();
          }}
          keycode={187}
        >
          <AddIcon />
        </ShortcutButton>
      </ButtonGroup>

      <div onClick={goForward} className={scheduleNavigationAction}>
        <ChevronRightIcon size={ICON_SIZE.XXX_LARGE} />
      </div>

      <EmitterSubscriber event="scheduleSelectDate" callback={selectDate} />
      <PopoverDatePicker
        popoverProps={{
          open: !!anchorEl,
          anchorEl,
          onClose: close,
          style: { marginTop: -10 },
        }}
        onChange={(date: string) => {
          close();
          selectDate(date);
          goToDate(date);
        }}
        value={selectedDate}
      />
    </div>
  );
}

export default ScheduleNavigation;
