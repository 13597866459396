import { lotties } from "@streamtimefe/assets";
import Lottie from "lottie-react";
import React from "react";
import styled from "styled-components";

import { sendClearScheduleFilters } from "../../../lib/WebAppAPI/schedule";
import { PrimaryButton, TextButton } from "../../elements/Button";
import Flex from "../../elements/Flex";

class EmptySearchResultsHeroMessage extends React.PureComponent {
  clearFilters = () => sendClearScheduleFilters();

  render() {
    return (
      <Wrapper>
        <Container>
          <Title>Hmm, I know you have jobs, but none are appearing here.</Title>
          <Hero>
            <Lottie animationData={lotties.questionMarkGuy} loop={true} />
          </Hero>
          <Body>
            It might be because you have too many search filters on. Why don’t
            we try{" "}
            <TextButton onClick={this.clearFilters}>
              clearing all search filters?
            </TextButton>
          </Body>
          <Action>
            <PrimaryButton onClick={this.clearFilters} style={{ width: 140 }}>
              Clear filters
            </PrimaryButton>
          </Action>
        </Container>
      </Wrapper>
    );
  }
}

export default EmptySearchResultsHeroMessage;

const Wrapper = styled(Flex)`
  background: white;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  max-height: 500px;
  overflow: auto;
`;

const Title = styled.div`
  width: 180px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: black;
`;

const Hero = styled.div`
  margin: 40px 0;
  div {
    height: 150px;
  }
`;

const Body = styled.div`
  width: 185px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray-dark);
  line-height: 1.3;
`;

const Action = styled.div`
  margin-top: 25px;
`;
