export function createPostStateMiddleware(listeners = []) {
  const postStateMiddleware = store => next => action => {
    const previousState = store.getState();
    const result = next(action);
    const nextState = store.getState();
    listeners.forEach(listener => listener(action, previousState, nextState));
    return result;
  };

  return postStateMiddleware;
}
