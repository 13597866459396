import isError from "lodash-es/isError";
import { call, put, takeLatest } from "redux-saga/effects";
import { sendOpenCreateEditContactModal } from "../../../lib/WebAppAPI/contact";
import {
  LOGGED_EXPENSE_CONTACT_CREATE_EDIT,
  ENTITIES_RECEIVED,
  LOGGED_EXPENSE_SET_CONTACT
} from "../../../lib/constants";
import createAction from "../../helpers/createAction";
import { sagaError } from "../../helpers/sagaErrorHandlers";

function* openCreateEdit(action) {
  try {
    const { id, company, contact } = action.payload;

    const response = yield call(
      sendOpenCreateEditContactModal,
      company,
      contact
    );

    yield put(createAction(ENTITIES_RECEIVED, { ...response.data }));

    yield put(
      createAction(LOGGED_EXPENSE_SET_CONTACT, {
        id,
        contactId: response.contact.id
      })
    );
  } catch (error) {
    if (isError(error)) {
      sagaError(error);
    }
  }
}

export default function* watchOpenCreateEditContact() {
  yield takeLatest(LOGGED_EXPENSE_CONTACT_CREATE_EDIT, openCreateEdit);
}
