import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { addressOptionType } from "../../../lib/entities/addressEntity";
import { domNodeType } from "../../../lib/types/domTypes";
import { entityIdListType, entityIdType } from "../../../lib/types/entityTypes";
import { selectActiveAddressOptionsByCompanyId } from "../../../redux/selectors/addressSelectors";
import SearchMenu from "../SearchMenu";
import SearchMenuCreateFooter from "../SearchMenu/SearchMenuCreateFooter";

const mapState = (state, props) => ({
  activeAddressOptions: selectActiveAddressOptionsByCompanyId(state, props)
});

const mapDispatch = null;

class CompanyAddressesSearchMenu extends React.PureComponent {
  static propTypes = {
    anchorEl: domNodeType,
    companyId: entityIdType.isRequired,
    onPickOption: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    activeAddressOptions: PropTypes.arrayOf(addressOptionType).isRequired,
    excludeIds: entityIdListType
  };

  static defaultProps = {
    anchorEl: null,
    excludeIds: [],
    onClose: null,
    onCreate: null
  };

  getNoResultsMessage = searchQuery =>
    `Hmm, looks like no addresses match ‘${searchQuery}’. Perhaps try a different search term.`;

  render() {
    const {
      anchorEl,
      onPickOption,
      onClose,
      onCreate,
      activeAddressOptions,
      excludeIds
    } = this.props;

    return (
      <SearchMenu
        anchorEl={anchorEl}
        options={activeAddressOptions}
        excludeIds={excludeIds}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        HeaderComponentProps={{
          title: "Company Addresses",
          noOptionsMessage: "There are no other addresses for this company",
          getNoResultsMessage: this.getNoResultsMessage
        }}
        FooterComponent={
          onCreate && (
            <SearchMenuCreateFooter
              text="Create new address"
              onClick={onCreate}
            />
          )
        }
        onClose={onClose}
      >
        {({ key, value, nickname }) => (
          <AddressOption
            key={key}
            onClick={() => onPickOption(key)}
            className="rainbowHover"
          >
            <Nickname>{nickname}</Nickname>
            <Address>{value}</Address>
          </AddressOption>
        )}
      </SearchMenu>
    );
  }
}

export default connect(mapState, mapDispatch)(CompanyAddressesSearchMenu);

const AddressOption = styled.div`
  width: 100%;
  padding: 3px 10px;
  text-align: left;
  user-select: none;
  cursor: pointer;
`;

const Nickname = styled.div`
  line-height: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
`;

const Address = styled.div`
  white-space: pre-wrap;
  margin-left: 10px;
  font-size: 12px;
  color: var(--color-gray-dark);
`;
