import * as PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Flex from "../../elements/Flex";
import Tooltip from "../Tooltip";

class TaxRateTooltip extends React.PureComponent {
  static propTypes = {
    taxName: PropTypes.string,
    taxRate: PropTypes.number,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    taxName: "",
    taxRate: 0
  };

  get title() {
    const { taxName, taxRate } = this.props;

    if (!taxName) return null;

    return (
      <Content>
        <div>{taxName}</div>
        <div>{taxRate}%</div>
      </Content>
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Tooltip title={this.title} placement="top">
        {children}
      </Tooltip>
    );
  }
}

export default TaxRateTooltip;

const Content = styled(Flex)`
  font-size: 12px;
  > :first-child {
    color: white;
    font-weight: 500;
  }
  > :last-child {
    color: var(--color-gray);
    font-weight: bold;
    margin-left: 10px;
  }
`;
