import { images } from "@streamtimefe/assets";
import styled from "styled-components";

import JobActivityCreatePostButton from "../JobActivityCreatePostButton";

const JobActivityNoPosts = () => (
  <Container>
    <strong className="title">Your posts are here… where?</strong>
    <br />
    <StyledImage alt="paper plane" src={images.paperPlane} />
    <br />
    <span>
      Keep documents, briefs and meeting notes all in one place. Right here.
    </span>
    <br />
    <JobActivityCreatePostButton />
  </Container>
);

export default JobActivityNoPosts;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 30px;
  margin-bottom: 20px;

  color: var(--color-gray-dark);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
`;
