import {
  EntityDescriptorEnum,
  StatisticField,
  StatisticMode,
  StatisticModeEnum,
} from "@streamtimefe/entities";
import type { ReactElement } from "react";
import { useMemo, useState } from "react";
import { Tooltip } from "st-shared/components";
import type {
  ReportingColumn,
  ReportingDataColumn,
  ReportingFormulaColumn,
} from "st-shared/entities";
import {
  ReportingColumnType,
  ReportingFiltersDefaultDescriptionsOverride,
} from "st-shared/entities";
import { FilterDisplayText } from "st-shared/module";
import { theme } from "st-shared/theme";

import { ReportingFormula } from "../../lib/ReportingFormula";
import {
  useReportingSavedSegmentColumns,
  useReportingSavedSegmentDataSets,
  useReportingSavedSegmentDates,
} from "../../state/stores/savedSegmentSelectors";
import * as styles from "./ColumnTooltip.css";
import type { ColumnError } from "./types";

type Props = {
  isDragging: boolean;
  column: ReportingColumn;
  error?: ColumnError;
  children: ReactElement;
};

export function ColumnTooltip({ isDragging, column, error, children }: Props) {
  const [open, setOpen] = useState(false);

  function getTooltip() {
    switch (column.type) {
      case ReportingColumnType.Formula:
        return <FormulaTooltipTitle column={column} error={error} />;
      case ReportingColumnType.Data:
      case ReportingColumnType.Total:
        return <DataTooltipTitle column={column} error={error} />;
    }
  }

  return (
    <Tooltip
      tooltipClassName={styles.tooltip}
      title={getTooltip()}
      open={open && !isDragging}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {children}
    </Tooltip>
  );
}

type FormulaTooltipTitleProps = {
  column: ReportingFormulaColumn;
  error?: ColumnError;
};

function FormulaTooltipTitle({ column, error }: FormulaTooltipTitleProps) {
  const columns = useReportingSavedSegmentColumns();

  const formula = useMemo(() => {
    if (column.type === ReportingColumnType.Formula) {
      return ReportingFormula.fromString(column.formula).toJSX(
        (columnId: string) =>
          columns[columnId] ? (
            columns[columnId].name
          ) : (
            <span style={{ color: theme.color.red }}>Deleted Column</span>
          )
      );
    }
    return null;
  }, [column, columns]);

  return (
    <>
      {error && <div className={styles.error}>{error.text}</div>}
      <div className={styles.heading}>{column.name}</div>
      <div>{formula}</div>
    </>
  );
}

type DataTooltipTitleProps = {
  column: ReportingDataColumn;
  error?: ColumnError;
};

function DataTooltipTitle({ column, error }: DataTooltipTitleProps) {
  const dates = useReportingSavedSegmentDates();
  const dataSets = useReportingSavedSegmentDataSets();
  const dataSet = dataSets[column.dataSetId];

  function renderDescription() {
    if (dataSet.entityDescriptorId === EntityDescriptorEnum.User) {
      return (
        <>
          <div>
            <span className={styles.bold}>Sum</span> of{" "}
            <span className={styles.bold}>Available Hours</span> for all
            included team members.{" "}
            {dates === null && (
              <span>
                To view availability, a global date range must be set.
              </span>
            )}
          </div>
        </>
      );
    }
    return (
      <div>
        {column.modeId !== StatisticModeEnum.Count && (
          <>
            <span className={styles.bold}>
              {StatisticMode.getName(column.modeId)}
            </span>
            {" of "}
          </>
        )}
        <span className={styles.bold}>
          {StatisticField.getName(
            column.columnId,
            undefined,
            dataSet.entityDescriptorId
          )}
        </span>
        {" from "}
        <span className={styles.bold}>{dataSet.name}</span>
      </div>
    );
  }

  return (
    <>
      {error && <div className={styles.error}>{error.text}</div>}
      <div className={styles.heading}>{column.name}</div>
      {renderDescription()}
      {dataSet.filterGroups.length > 0 && <div style={{ height: 6 }} />}
      {dataSet.filterGroups.map((filterGroup) => (
        <div key={filterGroup.id}>
          <FilterDisplayText
            filterGroup={filterGroup}
            defaultDescriptions={ReportingFiltersDefaultDescriptionsOverride}
          />
        </div>
      ))}
    </>
  );
}
