import { useSelector } from "react-redux";
import { PrimaryUserRoleName, primaryUserRoleNameCss } from "st-shared/module";
import styled from "styled-components";

import { selectIsScheduleUserCollapsed } from "../../../redux/selectors/userPreferenceSelectors";

interface ScheduleUserRoleProps {
  userId: number;
}

function ScheduleUserRole({ userId }: ScheduleUserRoleProps) {
  const isCollapsed = useSelector((state) =>
    selectIsScheduleUserCollapsed(state, { id: userId })
  );

  if (isCollapsed) return null;

  return (
    <Wrapper>
      <PrimaryUserRoleName userId={userId} fontSize={14} />
    </Wrapper>
  );
}

export default ScheduleUserRole;

const Wrapper = styled.div`
  padding: 12px 5px 12px 50px;
  .${primaryUserRoleNameCss} {
    font-weight: 500;
  }
`;
