import clsx from "clsx";

import { Icon, MdPerson } from "../../components";
import { theme } from "../../theme";
import {
  emptyUserIconCircle,
  emptyUserIconContainer,
} from "./EmptyUserIcon.css";

interface EmptyUserIconProps {
  size?: number;
  color?: string;
  backgroundColor?: string;
  className?: string;
}

export function EmptyUserIcon({
  size = 30,
  color = theme.color.graydark,
  backgroundColor = theme.color.gray,
  className,
}: EmptyUserIconProps) {
  return (
    <div className={clsx(emptyUserIconContainer, className)}>
      <div
        className={emptyUserIconCircle}
        style={{
          width: size,
          height: size,
          color,
          backgroundColor,
        }}
      >
        <Icon icon={MdPerson} size={size * 0.75} />
      </div>
    </div>
  );
}
