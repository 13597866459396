import reduceChangedEntities from "../reduceChangedEntities";

const deleteBlockReducer = (state, action) => {
  const { blockKey, blockKeys } = action.payload;

  const prevScheduleLoggedTimeIds = [];

  if (blockKey) {
    const prevBaseScheduleLoggedTime = state.byId[blockKey];
    if (!prevBaseScheduleLoggedTime) return state;
    prevScheduleLoggedTimeIds.push(...state.idsByBlockKey[blockKey]);
  } else {
    blockKeys.forEach((blockKey) => {
      const prevBaseScheduleLoggedTime = state.byId[blockKey];
      if (prevBaseScheduleLoggedTime) {
        prevScheduleLoggedTimeIds.push(...state.idsByBlockKey[blockKey]);
      }
    });
  }

  if (prevScheduleLoggedTimeIds.length === 0) return;

  const changedEntities = prevScheduleLoggedTimeIds
    .map((id) => state.byId[id])
    .map((prevEntity) => ({ prevEntity }));

  return reduceChangedEntities(state, changedEntities);
};

export default deleteBlockReducer;
