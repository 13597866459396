import type { TEntityId, TLabelTypeEnum } from "@streamtimefe/entities";
import { EntityClass, Label } from "@streamtimefe/entities";
import { dictionaryToArray } from "@streamtimefe/types";
import { useMemo } from "react";

import {
  createGetEntityStoreEntity,
  createUseEntityStoreEntity,
  getEntityStoreEntities,
  useEntityStoreEntities,
} from "../../EntityStore";

export const useLabelEntity = createUseEntityStoreEntity(EntityClass.Label);

export const getLabelEntity = createGetEntityStoreEntity(EntityClass.Label);

export function useLabelEntitiesByLabelTypeByEntityId(
  labelTypeId: TLabelTypeEnum,
  entityId: TEntityId
) {
  const labels = useEntityStoreEntities(EntityClass.Label);

  return useMemo(
    () =>
      Label.get.filterByLabelTypeByEntityId(
        dictionaryToArray(labels),
        labelTypeId,
        entityId
      ),
    [labels, labelTypeId, entityId]
  );
}

export function getLabelEntitiesByLabelTypeByEntityId(
  labelTypeId: TLabelTypeEnum,
  entityId: TEntityId
) {
  const labels = getEntityStoreEntities(EntityClass.Label);

  return Label.get.filterByLabelTypeByEntityId(
    dictionaryToArray(labels),
    labelTypeId,
    entityId
  );
}
