import numeral from "numeral";
import { useSelector } from "react-redux";
import { costingMethodObj } from "st-shared/entities";

import { NUMBER_FORMAT_NO_DECIMAL } from "../../../lib/constants";
import { getJobId, hasSellRate } from "../../../lib/entities/jobItemEntity";
import {
  getTotalUsedMinutes,
  getTotalUsedMoney,
  getUserId,
} from "../../../lib/entities/jobItemUserEntity";
import { selectJobItem } from "../jobItem";
import { selectUserSellRate } from "../user/selectUserSellRate";
import { selectJobItemUser } from "./index";

export const selectLoggedSellRateIsNotConsistent = (
  state,
  { jobItemUserId }
) => {
  const jobItemUser = selectJobItemUser(state, { jobItemUserId });
  const jobItem = selectJobItem(state, { jobItemId: jobItemUser.jobItemId });

  const costingMethod = costingMethodObj(jobItem.costingMethod);

  if (
    (costingMethod.isBasedByValue() || costingMethod.isItem()) &&
    !hasSellRate(jobItem)
  ) {
    const roleRate = selectUserSellRate(state, {
      userId: getUserId(jobItemUser),
      jobId: getJobId(jobItem),
    });

    const expectedLoggedTotal = numeral(roleRate)
      .multiply(numeral(getTotalUsedMinutes(jobItemUser)).value())
      .divide(60)
      .format(NUMBER_FORMAT_NO_DECIMAL);

    const loggedTotal = numeral(getTotalUsedMoney(jobItemUser)).format(
      NUMBER_FORMAT_NO_DECIMAL
    );

    return expectedLoggedTotal !== loggedTotal;
  }

  return false;
};

export const useLoggedSellRateIsNotConsistent = (jobItemUserId) =>
  useSelector((state) =>
    selectLoggedSellRateIsNotConsistent(state, { jobItemUserId })
  );
